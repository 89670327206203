import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { BiSearch } from "react-icons/bi";
import Progress from "../Progress/Progress";
import { ClickAwayListener, Input, InputAdornment } from "@material-ui/core";

function SearchBar({ onChange, minimal, loading }) {
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = useState("");
    const ref = useRef();
    useEffect(() => {
        if (expanded) ref?.current?.focus();
    }, [expanded]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setExpanded(false);
            }}
        >
            <Input
                disableUnderline
                onClick={() => setExpanded(true)}
                type="search"
                className={`${styles.searchBar} ${
                    value || expanded || !minimal ? styles.expanded : ""
                }`}
                startAdornment={
                    <InputAdornment position="start">
                        {loading ? (
                            <Progress
                                height={20}
                                width={20}
                                color={"#ACB3BF"}
                                circleSize={20}
                            />
                        ) : (
                            <BiSearch color="#ACB3BF" size={20} />
                        )}
                    </InputAdornment>
                }
                placeholder="Search page URL"
                onChange={(e) => {
                    e.target.value = e.target.value.toLowerCase();
                    onChange(e);
                    setValue(e.target.value);
                }}
                inputRef={ref}
            />
        </ClickAwayListener>
    );
}

export default SearchBar;
