import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import ReactDOM from "react-dom";
import { useRef } from "react";
import pluralize from "pluralize";

function TextHighlighter({ keyTerms }) {
    const [html, setHtml] = useState("");

    const ref = useRef();

    useEffect(() => {
        if (document.quill?.editor?.scroll?.domNode) {
            setTimeout(() => {
                if (ref.current)
                    ref.current.scrollTop =
                        document.quill.editor?.scroll?.domNode.scrollTop;
            }, 500);

            document.quill.editor.scroll.domNode.onscroll = () => {
                if (document.quill?.editor?.scroll?.domNode && ref.current)
                    ref.current.scrollTop =
                        document.quill?.editor?.scroll?.domNode?.scrollTop;
            };
        }

        return () => {
            if (document?.quill?.editor?.scroll?.domNode)
                document.quill.editor.scroll.domNode.onscroll = null;
        };
    }, []);
    const markArgs = {
        separateWordSearch: false,
        accuracy: {
            value: "exactly",
            limiters: [
                ",",
                "?",
                ".",
                '"',
                "'",
                "!",
                ")",
                "(",
                "{",
                "}",
                "[",
                "]",
                ":",
                ";",
                "-",
            ],
        },
    };

    useEffect(() => {
        var obj = new Mark(ref.current);
        if (html) {
            keyTerms
                .sort((a, b) => b?.keyword?.length - a?.keyword?.length)
                .forEach((term) => {
                    setImmediate(() => {
                        obj.mark(term.keyword, markArgs);
                        obj.mark(pluralize.plural(term.keyword), markArgs);
                        obj.mark(pluralize.singular(term.keyword), markArgs);
                        obj.mark(term?.keyword_variations || "", markArgs);
                    });
                });
        }
        return () => {
            obj.unmark();
        };
    }, [html, keyTerms]);

    useEffect(() => {
        const hightlight = () => {
            setHtml(document.quill.editor.root.innerHTML);
        };
        hightlight();

        document.addEventListener("editorSelectionChange", hightlight);
        return () =>
            document.removeEventListener("editorSelectionChange", hightlight);
    }, []);

    return !!document.quill.editor.container
        ? ReactDOM.createPortal(
              <div
                  ref={ref}
                  dangerouslySetInnerHTML={{ __html: html }}
                  className={"ql-editor " + styles.highlightedKeyTerms}
              ></div>,
              document.quill.editor.container,
          )
        : null;
}

export default TextHighlighter;
