import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from "./styles.module.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const SimpleDonutChart = ({
    data = [],
    labels = [],
    customLegends = [],
}: {
    data?: any[];
    labels?: any[];
    customLegends?: any[];
}) => {
    const options: any = {
        aspectRatio: 1 / 1,
        cutout: 50,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    // @ts-ignore
                    label: (context) => {
                        return " " + context.label + " " + context.raw + "%";
                    },
                },
            },
        },

        scales: {
            xAxes: [{ display: false }], // <-- add this
        },
    };

    return (
        <div className={styles.dounutWrapper}>
            <div className={styles.dounutWrapper_donut}>
                {!!data.length ? (
                    <Doughnut
                        data={{
                            labels: ["Mobile", "Tablet", "Desktop"],
                            datasets: [
                                {
                                    data: [...data],
                                    backgroundColor: [
                                        "#10CFFC",
                                        "#FFBE57",
                                        "#014DC5",
                                    ],
                                    borderColor: [
                                        "#10CFFC",
                                        "#FFBE57",
                                        "#014DC5",
                                    ],
                                    borderWidth: 2,
                                },
                            ],
                        }}
                        options={options}
                    />
                ) : (
                    <Doughnut
                        data={{
                            labels: ["Desktop", "Mobile", "Tablet"],
                            datasets: [
                                {
                                    data: [33, 33, 33],
                                    backgroundColor: [
                                        "#10CFFC",
                                        "#FFBE57",
                                        "#014DC5",
                                    ],
                                    borderColor: [
                                        "#10CFFC",
                                        "#FFBE57",
                                        "#014DC5",
                                    ],
                                    borderWidth: 1,
                                },
                            ],
                        }}
                        options={options}
                    />
                )}
            </div>
            {!!customLegends.length && !!data.length ? (
                <div className={styles.donutLegendsWrapper}>
                    {customLegends.map((item, index) => {
                        return (
                            <div className={styles.donutLegend}>
                                <div className={styles.legendIcon}>
                                    {item.icon()}
                                </div>
                                <div className={styles.legendLabel}>
                                    <span className={styles.legendLabel_text}>
                                        {item.label}
                                    </span>
                                    <span
                                        style={{ background: item.color }}
                                        className={styles.legendLabel_color}
                                    ></span>
                                </div>
                                <div className={styles.legendValue}>
                                    {data[index]} %
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={styles.donutLegendsWrapper}>
                    {customLegends.map((item, index) => {
                        return (
                            <div className={styles.donutLegend}>
                                <div className={styles.legendIcon}>
                                    {item.icon()}
                                </div>
                                <div className={styles.legendLabel}>
                                    <span className={styles.legendLabel_text}>
                                        {item.label}
                                    </span>
                                    <span
                                        style={{ background: item.color }}
                                        className={styles.legendLabel_color}
                                    ></span>
                                </div>
                                <div className={styles.legendValue}>
                                    {Math.round((33 / 10) * 100) / 10} %
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SimpleDonutChart;
