import React from 'react';
import styles from './styles.module.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function WordCounter({ maxLength = 200, length = 127 }) {
  const percentage = ((length / maxLength) * 100).toFixed(0);

  return (
    <CircularProgressbar
      className={styles.circular}
      value={percentage}
      background
      backgroundPadding={6}
      styles={buildStyles({
        backgroundColor: 'transparent',
        textColor: '#fff',
        pathColor: '#597ef7',
        trailColor: '#f1f1f5',
      })}
    />
  );
}
