import * as React from "react";
import { useState, useEffect } from "react";
import styles from "./KeywordInput.module.css";
import { VscClose } from "react-icons/vsc";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import Button from "../../../atoms/Button/Button";
import { AiFillStar } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import useHelperFunctions from "../../../helperFunctions";
import { customLoadStripe } from "../../../atoms/Stripe";
import { useSelector } from "react-redux";
import { STRIPE_TOKEN1, STRIPE_TOKEN2 } from "../../../../utility/config";

export interface ICreditsModalProps {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CreditsModal(props: ICreditsModalProps) {
    const [quantity, setQuantity] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [wrongInput, setWrongInput] = useState<boolean>(false);
    const { postData } = useHelperFunctions();
    const otherInfo = useSelector((state: any) => state.otherInfo);
    let baseURL = window.location.origin;

    useEffect(() => {
        if (wrongInput) {
            setTimeout(() => {
                setWrongInput(false);
            }, 2000);
        }
    }, [wrongInput]);

    let stripeID =
        otherInfo?.subscriptionInfo?.stripe_region == 1
            ? STRIPE_TOKEN1
            : STRIPE_TOKEN2;
    const stripePromise = customLoadStripe(stripeID);
    const checkout = async () => {
        setLoading(true);
        const stripe: any = await stripePromise;
        const res = await postData({
            url: "/api/ai-credit",
            payload: {
                currency: "USD",
                amount: quantity * 5,
                domain: baseURL,
            },
        });
        if (res.status == 200) {
            const session = res?.data;
            const result = await stripe.redirectToCheckout({
                sessionId: session?.paymentTransactionID,
            });
            if (result.error) {
                setLoading(false);
            }
        }
        setLoading(false);
    };
    return (
        <div className={styles.creditsModalWrapper}>
            <div className={styles.creditsModalHeader}>
                <div className={styles.creditsModalHeaderText}>
                    Recharge Credits
                </div>
                <VscClose
                    style={{ cursor: "pointer" }}
                    onClick={() => props.closeModal(false)}
                    size={18}
                />
            </div>
            <div className={styles.creditsModalBody}>
                <Textfield
                    onChange={(x: any) => {
                        if (
                            x.target.value.match(/^[1-9][0-9]*$/) ||
                            x.target.value === ""
                        ) {
                            setQuantity(x.target.value);
                        } else {
                            setWrongInput(true);
                        }
                    }}
                    props={{
                        style: {
                            border: wrongInput
                                ? "1px solid red"
                                : "solid 1px #acb3bf",
                        },
                    }}
                    placeholder="Enter the no. of credits you want to purchase"
                    label="Quantity"
                    value={quantity > 0 ? String(quantity) : ""}
                />
                <div className={styles.creditsText}>
                    1 Cruise Credit ={" "}
                    <span className={styles.originalPrice}>10</span> 5 USD
                </div>
                {/* <div className={styles.creditsOffer}>
                    <div className={styles.creditsOfferTop}>
                        <AiFillStar fill="#fff" />
                        <span className={styles.creditsOfferTopText}>
                            Save Big!
                        </span>
                    </div>
                    <div className={styles.creditsOfferText}>
                        Buy 10 Cruise credits 10% off
                    </div>
                    <div className={styles.creditsDivider}></div>
                    <div className={styles.creditsOfferText}>
                        Buy 20 Cruise credits 10% off
                    </div>
                </div> */}
            </div>
            <div className={styles.creditsModalFooter}>
                <Button
                    handler={() => props.closeModal(false)}
                    secondary={true}
                    text="Cancel"
                />
                <Button
                    handler={() => checkout()}
                    Icon={() => <BsArrowRight />}
                    text="Pay"
                    loading={loading}
                />
            </div>
        </div>
    );
}
