import React, { useEffect, useRef, useState } from "react";
import styles from "./SpinWheel.module.css";
import useHelperFunctions from "../../helperFunctions";
import { useSelector } from "react-redux";

function SpinWheel({ spin, setReward, reward, setIsRewardDone }) {
    const otherInfo = useSelector((state) => state.otherInfo);
    console.log(otherInfo, "otherInfo");
    const wheelRef = useRef();
    const { getData } = useHelperFunctions();

    useEffect(() => {
        if (spin) {
            getSpinOffer();
        }
    }, [spin]);

    const getSpinOffer = async () => {
        try {
            const res = await getData({ url: "/api/spin/getSpinOffer" });
            if (res.status === 200) {
                setTimeout(() => {
                    setReward(res.data);
                }, 5000);
                setTimeout(() => {
                    setIsRewardDone(true);
                }, 7000);
                const angle = (5 - res.data?.reward_id) * 72 + 135 + 1800;
                wheelRef.current.style.transform = `rotate(${angle}deg)`;
            }
        } catch (error) {
            console.error("Error fetching spin offer:", error);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.spinBtn}></div>
            <div ref={wheelRef} className={`${styles.wheel}`}>
                {otherInfo?.spinInfo?.rewards?.map((ele, i) => (
                    <div
                        className={styles.offer}
                        style={{
                            background: reward?.reward_id
                                ? ele.id == reward?.reward_id
                                    ? "#FCE876"
                                    : "#0750ff"
                                : "#0750ff",
                            boxShadow: reward?.reward_id
                                ? ele.id == reward?.reward_id
                                    ? "0px 0px 30px 0px #FFB800 inset"
                                    : "none"
                                : "none",
                            opacity: reward?.reward_id
                                ? ele.id == reward?.reward_id
                                    ? 1
                                    : 0.5
                                : 1,
                            "--i": i + 1,
                            "--clr": (i + 1) % 2 == 0 ? "#0750ff" : "#0750ff",
                            // "--angle": 7890,
                        }}
                    >
                        <div
                            style={{
                                color: reward?.reward_id
                                    ? ele.id == reward?.reward_id
                                        ? "#000"
                                        : ""
                                    : "",
                            }}
                            dangerouslySetInnerHTML={{ __html: ele?.offer }}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SpinWheel;
