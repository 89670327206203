import { DISPLAY_ALERT } from "../actiontypes";

const initialState = {
    alertMessage: "",
    alertType: "error",
    timeout: 3000,
    isUpgrade: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DISPLAY_ALERT:
            return {
                ...state,
                ...action.data,
            };

        default:
            return state;
    }
}
