export const locationData = [
  {
    country: "Afghanistan",
    code: "AF",
    reach: 315000,
  },
  {
    country: "Albania",
    code: "AL",
    reach: 549000,
  },
  {
    country: "Algeria",
    code: "DZ",
    reach: 4180000,
  },
  {
    country: "Angola",
    code: "AO",
    reach: 360000,
  },
  {
    country: "Argentina",
    code: "AR",
    reach: 24200000,
  },
  {
    country: "Armenia",
    code: "AM",
    reach: 532000,
  },
  {
    country: "Australia",
    code: "AU",
    reach: 13600000,
  },
  {
    country: "Austria",
    code: "AT",
    reach: 5520000,
  },
  {
    country: "Azerbaijan",
    code: "AZ",
    reach: 1300000,
  },
  {
    country: "Bahamas",
    code: "BS",
    reach: 229000,
  },
  {
    country: "Bahrain",
    code: "BH",
    reach: 858000,
  },
  {
    country: "Bangladesh",
    code: "BD",
    reach: 3730000,
  },
  {
    country: "Belarus",
    code: "BY",
    reach: 2770000,
  },
  {
    country: "Belgium",
    code: "BE",
    reach: 6690000,
  },
  {
    country: "Belize",
    code: "BZ",
    reach: 112000,
  },
  {
    country: "Bolivia",
    code: "BO",
    reach: 2820000,
  },
  {
    country: "Bosnia and Herzegovina",
    code: "BA",
    reach: 1340000,
  },
  {
    country: "Botswana",
    code: "BW",
    reach: 190000,
  },
  {
    country: "Brazil",
    code: "BR",
    reach: 94800000,
  },
  {
    country: "Brunei",
    code: "BN",
    reach: 185000,
  },
  {
    country: "Bulgaria",
    code: "BG",
    reach: 2860000,
  },
  {
    country: "Cabo Verde",
    code: "CV",
    reach: 72000,
  },
  {
    country: "Cambodia",
    code: "KH",
    reach: 967000,
  },
  {
    country: "Cameroon",
    code: "CM",
    reach: 581000,
  },
  {
    country: "Canada",
    code: "CA",
    reach: 22700000,
  },
  {
    country: "Chile",
    code: "CL",
    reach: 10800000,
  },
  {
    country: "Colombia",
    code: "CO",
    reach: 19100000,
  },
  {
    country: "Congo",
    code: "CG",
    reach: 54000,
  },
  {
    country: "Costa Rica",
    code: "CR",
    reach: 2550000,
  },
  {
    country: "Croatia",
    code: "HR",
    reach: 2650000,
  },
  {
    country: "Cyprus",
    code: "CY",
    reach: 525000,
  },
  {
    country: "Czech Republic",
    code: "CZ",
    reach: 4470000,
  },
  {
    country: "Denmark",
    code: "DK",
    reach: 3510000,
  },
  {
    country: "Dominican Republic",
    code: "DO",
    reach: 3340000,
  },
  {
    country: "Ecuador",
    code: "EC",
    reach: 5090000,
  },
  {
    country: "Egypt",
    code: "EG",
    reach: 12800000,
  },
  {
    country: "El Salvador",
    code: "SV",
    reach: 1680000,
  },
  {
    country: "Estonia",
    code: "EE",
    reach: 786000,
  },
  {
    country: "Ethiopia",
    code: "ET",
    reach: 314000,
  },
  {
    country: "Finland",
    code: "FI",
    reach: 3310000,
  },
  {
    country: "France",
    code: "FR",
    reach: 38100000,
  },
  {
    country: "Georgia",
    code: "GE",
    reach: 19900000,
  },
  {
    country: "Germany",
    code: "DE",
    reach: 42200000,
  },
  {
    country: "Ghana",
    code: "GH",
    reach: 1370000,
  },
  {
    country: "Greece",
    code: "GR",
    reach: 5400000,
  },
  {
    country: "Guatemala",
    code: "GT",
    reach: 3020000,
  },
  {
    country: "Guyana",
    code: "GY",
    reach: 141000,
  },
  {
    country: "Haiti",
    code: "HT",
    reach: 424000,
  },
  {
    country: "Honduras",
    code: "HN",
    reach: 1510000,
  },
  {
    country: "Hong Kong",
    code: "HK",
    reach: 5010000,
  },
  {
    country: "Hungary",
    code: "HU",
    reach: 5300000,
  },
  {
    country: "Iceland",
    code: "IS",
    reach: 285000,
  },
  {
    country: "India",
    code: "IN",
    reach: 34620000,
  },
  {
    country: "Indonesia",
    code: "ID",
    reach: 47000000,
  },
  {
    country: "Ireland",
    code: "IE",
    reach: 3310000,
  },
  {
    country: "Israel",
    code: "IL",
    reach: 5020000,
  },
  {
    country: "Italy",
    code: "IT",
    reach: 34300000,
  },
  {
    country: "Jamaica",
    code: "JM",
    reach: 792000,
  },
  {
    country: "Japan",
    code: "JP",
    reach: 47200000,
  },
  {
    country: "Jordan",
    code: "JO",
    reach: 2620000,
  },
  {
    country: "Kazakhstan",
    code: "KZ",
    reach: 4500000,
  },
  {
    country: "Kuwait",
    code: "KW",
    reach: 1900000,
  },
  {
    country: "Latvia",
    code: "LV",
    reach: 930000,
  },
  {
    country: "Lebanon",
    code: "LB",
    reach: 1370000,
  },
  {
    country: "Libya",
    code: "LY",
    reach: 581000,
  },
  {
    country: "Lithuania",
    code: "LT",
    reach: 1690000,
  },
  {
    country: "Luxembourg",
    code: "LU",
    reach: 474000,
  },
  {
    country: "Madagascar",
    code: "MG",
    reach: 151000,
  },
  {
    country: "Malaysia",
    code: "MY",
    reach: 15200000,
  },
  {
    country: "Malta",
    code: "MT",
    reach: 331000,
  },
  {
    country: "Mauritius",
    code: "MU",
    reach: 448000,
  },
  {
    country: "Mexico",
    code: "MX",
    reach: 50800000,
  },
  {
    country: "Moldova",
    code: "MD",
    reach: 868000,
  },
  {
    country: "Mongolia",
    code: "MN",
    reach: 286000,
  },
  {
    country: "Montenegro",
    code: "ME",
    reach: 331000,
  },
  {
    country: "Morocco",
    code: "MA",
    reach: 6560000,
  },
  {
    country: "Mozambique",
    code: "MZ",
    reach: 552000,
  },
  {
    country: "Namibia",
    code: "NA",
    reach: 284000,
  },
  {
    country: "Nepal",
    code: "NP",
    reach: 1280000,
  },
  {
    country: "Netherlands",
    code: "NL",
    reach: 13900000,
  },
  {
    country: "New Zealand",
    code: "NZ",
    reach: 2860000,
  },
  {
    country: "Nicaragua",
    code: "NI",
    reach: 998000,
  },
  {
    country: "Nigeria",
    code: "NG",
    reach: 3590000,
  },
  {
    country: "Norway",
    code: "NO",
    reach: 3110000,
  },
  {
    country: "Oman",
    code: "OM",
    reach: 1740000,
  },
  {
    country: "Pakistan",
    code: "PK",
    reach: 9730000,
  },
  {
    country: "Paraguay",
    code: "PY",
    reach: 2320000,
  },
  {
    country: "Peru",
    code: "PE",
    reach: 9610000,
  },
  {
    country: "Philippines",
    code: "PH",
    reach: 14400000,
  },
  {
    country: "Poland",
    code: "PL",
    reach: 15700000,
  },
  {
    country: "Portugal",
    code: "PT",
    reach: 6130000,
  },
  {
    country: "Romania",
    code: "RO",
    reach: 7270000,
  },
  {
    country: "Russia",
    code: "RU",
    reach: 43300000,
  },
  {
    country: "Saudi Arabia",
    code: "SA",
    reach: 15800000,
  },
  {
    country: "Senegal",
    code: "SN",
    reach: 615000,
  },
  {
    country: "Serbia",
    code: "RS",
    reach: 3050000,
  },
  {
    country: "Singapore",
    code: "SG",
    reach: 4910000,
  },
  {
    country: "Sri Lanka",
    code: "LK",
    reach: 2560000,
  },
  {
    country: "Slovakia",
    code: "SK",
    reach: 2600000,
  },
  {
    country: "Slovenia",
    code: "SI",
    reach: 1440000,
  },
  {
    country: "South Africa",
    code: "ZA",
    reach: 9300000,
  },
  {
    country: "South Korea",
    code: "KR",
    reach: 22100000,
  },
  {
    country: "Spain",
    code: "ES",
    reach: 33900000,
  },
  {
    country: "Sweden",
    code: "SE",
    reach: 6190000,
  },
  {
    country: "Thailand",
    code: "TH",
    reach: 28100000,
  },
  {
    country: "Trinidad and Tobago",
    code: "TT",
    reach: 667000,
  },
  {
    country: "Tunisia",
    code: "TN",
    reach: 1910000,
  },
  {
    country: "Turkey",
    code: "TR",
    reach: 32200000,
  },
  {
    country: "Ukraine",
    code: "UA",
    reach: 11100000,
  },
  {
    country: "United Arab Emirates",
    code: "AE",
    reach: 7350000,
  },
  {
    country: "United Kingdom",
    code: "UK",
    reach: "227000000",
  },
  {
    country: "United States",
    code: "US",
    reach: 227000000,
  },
  {
    country: "Uruguay",
    code: "UY",
    reach: 2120000,
  },
  {
    country: "Venezuela",
    code: "VE",
    reach: 6870000,
  },
  {
    country: "Vietnam",
    code: "VN",
    reach: 23500000,
  },
  {
    country: "Zambia",
    code: "ZM",
    reach: 303000,
  },
  {
    country: "Zimbabwe",
    code: "ZW",
    reach: 388000,
  },
];

export const priorityLocations = [
  {
    country: "United States",
    code: "US",
    reach: 227000000,
  },
  {
    country: "Brazil",
    code: "BR",
    reach: 94800000,
  },
  {
    country: "France",
    code: "FR",
    reach: 38100000,
  },
  {
    country: "United Kingdom",
    code: "UK",
    reach: "227000000",
  },
  {
    country: "Canada",
    code: "CA",
    reach: 22700000,
  },
  {
    country: "Australia",
    code: "AU",
    reach: 13600000,
  },
  {
    country: "Spain",
    code: "ES",
    reach: 33900000,
  },
  {
    country: "Germany",
    code: "DE",
    reach: 42200000,
  },
  {
    country: "Italy",
    code: "IT",
    reach: 34300000,
  },
  {
    country: "Poland",
    code: "PL",
    reach: 15700000,
  },
];
