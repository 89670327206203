export const REPORT_EDITOR = "REPORT_EDITOR";
export const SMART_EDITOR = "SMART_EDITOR";
export const CLICKED_ON_VIEW_ONLY = "CLICKED_ON_VIEW_ONLY";
export const clickedOnViewOnly = new Event(CLICKED_ON_VIEW_ONLY);

export const getPosValueV = (value) => {
    let top = value + 30;
    let reverse = false;

    return {
        top,
        reverse,
    };
};

export const getPosValueH = (value) => {
    const width = document.getElementById("editor-bound").offsetWidth;
    let left = value;
    let right = 0;

    if (value + 600 > width) {
        left = "unset";
        right = 0;
    } else {
        right = "unset";
    }

    return {
        left,
        right,
    };
};

export function link(value) {
    if (value) {
        var range = this.quill.getSelection();
        if (range == null || range?.length == 0) return;
        var preview = this.quill.getText(range);
        if (
            /^\S+@\S+\.\S+$/.test(preview) &&
            preview.indexOf("mailto:") !== 0
        ) {
            preview = "https:" + preview;
        }
        var tooltip = this.quill.theme.tooltip;
        tooltip.edit("link", preview);
    } else {
        this.quill.format("link", false);
    }
}

export const scrapeImageSrc =
    "https://storage.googleapis.com/scalenut/9c6ddcd1-3fda-4f77-b4a1-2bf830e4453b.svg?width=450";

export const getAllParasFromEditor = (innerHTML) => {
    let parsed = new DOMParser().parseFromString(innerHTML, "text/html");
    let allH2s = parsed.getElementsByTagName("h2");
    let index = 0;
    for (let i = allH2s?.length - 1; i >= 0; i--) {
        if (allH2s[i].innerText.toLowerCase() == "conclusion") {
            index = i;
            break;
        } else {
            index = null;
        }
    }
    do {
        allH2s[index]?.nextSibling?.remove();
    } while (allH2s[index]?.nextSibling != null);
    let payload = [];
    let ids = 1;
    let header = "";
    let headerTag = "";
    const headings = ["H2", "H3", "H4", "H5", "H6"];

    const allElements = parsed.getElementsByTagName("*");
    for (const element of allElements) {
        if (headings.includes(element.tagName)) {
            header = element.innerText;
            headerTag = element.tagName;
        } else if (element.tagName == "P") {
            if (element.innerText?.length > 80) {
                payload.push({
                    id: ids,
                    text: element.innerText,
                    header: header ? `${headerTag}: ${header}` : "",
                });
            }
            ++ids;
        }
    }
    return payload;
};
