import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { SEO_EDITOR } from "../../../Pages/NewDashboard/constants";

function useSeoScoreRefresh({ saving, getSeoScore, editorRef, attachedTo }) {
    useEffect(() => {
        if (editorRef && !saving && attachedTo == SEO_EDITOR) getSeoScore();
    }, [saving]);

    return <></>;
}

export default useSeoScoreRefresh;
