import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import Button from "../../../atoms/Button/Button";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../../atoms/SVGIcon";
import NewUIDropDown, {
    TYPES,
} from "../../../molecules/NewUIDropdown/NewUIDropDown";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import ClusterCard from "./ClusterCard";
import styles from "./ClusterReports.module.css";
import sampleCR from "../sampleData";
import useHelperFunctions from "../../../helperFunctions";
import { CLUSTER_REPORT_GET } from "../../../../componentsV2/api.json";
import { useHistory } from "react-router-dom";
import {
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    Link,
    MenuItem,
    Select,
    Tab,
    Tabs,
    makeStyles,
} from "@material-ui/core";
import { Export2Word } from "../../../organisms/TextEditor/Options/utils";
import { CSVLink } from "react-csv";
import { socket } from "../../../../Sockets/useSockets";
import { socketEvents } from "../../../../Sockets/constants";

import NotOptimal from "../../../molecules/Creator/Overview/atoms/NotOptimal";
import { debounceWrapper } from "../../../../utility/helperFun";
import ClusterKeywordPlanner from "../clusterKeywordPlanner/ClusterKeywordPlanner";
import RenderInChucks from "../../../atoms/RenderInChucks";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import styles3 from "./ClusterRow.module.css";

import { countries } from "../../../../dataV2/countryConstant";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import ClusterGraph from "./ClusterGraph";
import ClusterFilter, { initialState } from "./ClusterFilter";
import ClusterCatsFilter from "./ClusterCatsFilter";
import { ClusterModal } from "./ClusterModal";
import Modal from "../../../atoms/Modal/Modal";
import ToggleButton from "../../../atoms/CustomToggle/ToggleButtonModified";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { clusterMixpanelEvent } from "../clusterMixpanelEvents";
import UserFeedback from "../../../organisms/UserFeedback/UserFeedback";
import { VscClose } from "react-icons/vsc";
import ClusterRow from "./ClusterRow";
import useGscHook from "../../GoogleSearchConsole/useGscHook";
import { is_completed } from "../../../organisms/DocCard/constants";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";
import { PLANS_CATEGORIES } from "../../../../constants";
import { useMixpanelCluster } from "../useMixpanelCluster";
import { BiChevronDown } from "react-icons/bi";
import { HiSparkles } from "react-icons/hi2";

const sortByOptions = [
    // { title: "Potential Score", id: 6 },
    { title: "Relevance ", id: 3 },
    { title: "Search Volume", id: 1 },
    { title: "#Keywords in Cluster", id: 2 },
    // { title: "Cluster Difficulty", id: 4 },
];

const sortByOptions4 = [
    { title: "Relevance ", id: 3 },
    { title: "Search Volume", id: 1 },
    { title: "CPC", id: 5 },
    // { title: "Potential Score", id: 6 },
];

const sortByOptions5 = [
    { title: "Paid Competition", id: 4 },
    { title: "Search Volume", id: 1 },
    { title: "CPC", id: 5 },
    // { title: "Potential Score", id: 6 },
];

let sortByOptions3 = [
    // { title: "Recommended", id: 6 },
    { title: "Relevance", id: 10 },
    { title: "#Keywords in Cluster", id: 12 },
    { title: "Keywords Ranked", id: 3 },
    { title: "Search Volume", id: 5 },
    { title: "Keyword Difficulty", id: 6 },
];
const filteredSortByOptions3_excel = sortByOptions3.filter(
    (x) => x.title.toLowerCase() !== "relevance",
);

const sortByOptions2 = [
    { title: "Relevance ", id: 3 },
    { title: "#Keywords in Cluster", id: 2 },
];

const sortByClusterTable = [
    { title: "keyword", id: 1 },
    { title: "status", id: 2 },
    { title: "Keywords Ranked", id: 3 },
    { title: "intent", id: 4 },
    { title: "Search Volume", id: 5 },
    { title: "Keyword Difficulty", id: 6 },
];

const StatusFilterOptions_Tooltips = {
    shared: "Clusters for which each of the entered domains has rankings",
    unique: "Clusters for which the User domain has rankings but other entered competitors do not.",
    missing:
        "Clusters for which the User domain has no rankings but at least one of the other entered competitors do.",
    untapped: "Clusters for which all the provided domain are not ranking.",
    ranked: "Clusters for which the provided domain has rankings",
};

const headers = [
    { label: "Cluster Name", key: "clusterName" },
    { label: "Keyword", key: "keyword" },
    { label: "CPC", key: "cpc" },
    { label: "Relevancy", key: "relevancy" },
    { label: "Search Volume", key: "searchVolume" },
    { label: "Total Cluster Search Volume", key: "totalVolume" },
    { label: "Potential Score", key: "potentialScore" },
    { label: "Recommended", key: "isRecommonded" },
];

const headers_list_view = [
    { label: "Keyword", key: "keyword" },
    { label: "CPC", key: "cpc" },
    { label: "Relevancy", key: "relevancy" },
    { label: "Search Volume", key: "searchVolume" },
    // { label: "Potential Score", key: "potentialScore" },
    // { label: "Recommended", key: "isRecommonded" },
];

const useStylesTab = makeStyles((theme) => ({
    root: {
        minHeight: 25,
        margin: "0 15px 0 0 !important",
        width: "120px",
        fontSize: 14,
        fontWeight: 400,
        textTransform: "none",
        padding: "5px 2px",
        "min-width": "max-content",
        color: "#696974",
        opacity: 1,

        "&:hover": {
            color: "#1f4cbe",
        },
        "& .MuiTab-wrapper": {
            flexDirection: "row-reverse",
            justifyContent: "center",
            gap: "4px",
            alignItems: "center",
        },
        "& .MuiTab-wrapper > *:first-child ": {
            marginBottom: "0px",
        },
    },
    icon: {
        color: "red",
    },
    selected: {
        fontWeight: 500,
        color: "#1f4cbe",
    },
}));

const useStylesTab2 = makeStyles((theme) => ({
    root: {
        minHeight: 25,
        margin: "0 15px 0 0 !important",
        width: "220px",
        fontSize: 14,
        fontWeight: 400,
        textTransform: "none",
        padding: "5px 2px",
        "min-width": "max-content",
        color: "#696974",
        opacity: 1,

        "&:hover": {
            color: "#1f4cbe",
        },
        "& .MuiTab-wrapper": {
            flexDirection: "row-reverse",
            justifyContent: "center",
            gap: "4px",
            alignItems: "center",
        },
        "& .MuiTab-wrapper > *:first-child ": {
            marginBottom: "0px",
        },
    },
    icon: {
        color: "red",
    },
    selected: {
        fontWeight: 500,
        color: "#1f4cbe",
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#1f4cbe",
        height: 3,
        width: "max-content",
    },
    root: {
        // borderBottom: "solid #d6d6d6 0.1px",
        minHeight: 30,
    },
}));

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: 8,
        height: 8,
        // margin: "9px",
        marginRight: "9px",
        color: "#b0b8c3",
        transform: "scale(.7)",
        zIndex: 0,
    },

    disabled: {
        color: "#b0b8c3 !important",
    },
}));

const RankFilterOptions = [
    "All Clusters",
    "Ranked Clusters",
    "Unranked Clusters",
];

// let isSubmit = false;

const MoreFilters = ({
    isCompetitorGap,
    setOrder,
    order,
    sortBy,
    setSortBy,
    setShowMoreFilters = () => {},
    showMoreFilters,
    filteredSortByOptions3,
}) => {
    const { kpTrackSortBy } = useMixpanelCluster();
    return (
        <div
            className={styles.moreFiltersBar}
            style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                marginLeft: "20px",
                // ...(isCompetitorGap && {
                //     position: "sticky",
                //     top: "66px",
                // }),
            }}
        >
            Sort By:{" "}
            <div className={styles.clusterSortByFiltersWrapper}>
                <div
                    className={`${styles.clusterSortByFilters} ${styles.active}`}
                    onClick={() => {
                        setShowMoreFilters(!showMoreFilters);
                    }}
                >
                    <div className={`${styles.activeSortOption} `}>
                        {sortBy.title}
                    </div>
                    <div
                        className={styles.sortIconWrapper}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSortBy(sortBy);
                            setOrder((ps) => (ps == 0 ? 1 : 0));
                            kpTrackSortBy({
                                type: "Sort By",
                                value: sortBy.title,
                                order: order == 0 ? "Descending" : "Ascending",
                            });
                        }}
                    >
                        <SVGIcon
                            size={20}
                            style={{
                                cursor: "pointer",
                                color: "inherit",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                            }}
                            src={`/New UI/SVG/sort${
                                order == 0 ? "A" : "D"
                            }.svg`}
                        />
                    </div>
                </div>
                {showMoreFilters && (
                    <ClickAwayListener
                        onClickAway={() => {
                            setShowMoreFilters(false);
                        }}
                    >
                        <div className={styles.clusterSortByFilters_dropdown}>
                            {filteredSortByOptions3.map((opt) => {
                                return (
                                    <div
                                        className={styles.opt_item}
                                        onClick={() => {
                                            setSortBy(opt);
                                            kpTrackSortBy({
                                                type: "Sort By",
                                                value: opt.title,
                                                order:
                                                    order == 0
                                                        ? "Ascending"
                                                        : "Descending",
                                            });
                                            setShowMoreFilters(false);
                                        }}
                                    >
                                        {opt.title}
                                    </div>
                                );
                            })}
                        </div>
                    </ClickAwayListener>
                )}
            </div>
        </div>
    );
};

const ClusterReport = ({ title = "Benefits of Green Tea" }) => {
    const [filteredSortByOptions3, setFilteredSortByOptions3] =
        useState(sortByOptions3);
    const [filterTagList, setFilterTagsList] = useState([]);
    const [sortingOption, setSortingOption] = useState(sortByOptions);
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState(filteredSortByOptions3[0]);
    const [order, setOrder] = useState(1);
    const [listViewOrder, setListViewOrder] = useState(0);
    const [clusterKeywords, setClusterKeywords] = useState([]);
    const [keywordsInCluster, setKeywordsInCluster] = useState([]);
    const [keywordCountOfClusters, setKeywordCountOfClusters] = useState(0);
    const [clusterInfo, setClusterInfo] = useState({});
    const [statusFilterCount, setStatusFilterCount] = useState(0);
    const [StatusFilterOptions, setStatusFilterOptions] = useState([
        "All",
        "Shared",
        "Unique",
        "Missing",
        "Untapped",
    ]);
    const [semrushLimitCode, setSemrushLimitCode] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const goTo = useNavigation();
    const [all, setAll] = useState(true);
    const [expandIdx, setExpandIdx] = useState(0);
    const [csvData1, setCsvData1] = useState([]);
    const [csvData2, setCsvData2] = useState([]);
    const [graphOn, setGraphOn] = useState(false);
    const [clusterBooster, setClusterBooster] = useState(false);
    const { getData, postData } = useHelperFunctions();
    const history = useHistory();
    const otherInfo = useSelector((store) => store.otherInfo);
    const [isClusterView, setIsClusterView] = useState(true);
    const [isCompetitorGap, setIsCompetitorGap] = useState(false);
    const [parentClubbed, setParentClubbed] = useState([]);
    const [validRangeState, setValidRangeState] = useState(initialState);
    const [catsData, setCatsData] = useState(null);
    const { mpTrackBreadCrumb } = useMixpanelHook();
    const {
        kpUserFeedbackClosed,
        kpTrackTabSwitch,
        kpTrackExportCta,
        kpTrackFilter,
        kpTrackSortBy,
    } = useMixpanelCluster();
    const [isClusterModal, setIsClusterModal] = useState(false);
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [hideFilters, setHideFilters] = useState(false);
    const [isKDAvailable, setIsKDAvailable] = useState(0);
    const [isCompetitorAvailable, setIsCompetitorAvailable] = useState(0);
    const [isTitleAvailable, setIsTitleAvailable] = useState(0);
    const [isGSCAvailable, setIsGscAvailable] = useState(0);
    const [close, setClose] = useState(true);
    const [hasSatisfactionScore, setHasSatisfactionScore] = useState(false);
    const [value, setValue] = useState(0);
    const classesTab = useStylesTab();
    const classesTab2 = useStylesTab2();
    const classesTabs = useStylesTabs();
    const [isSubmit, setIsSubmit] = useState(false);
    const [filterByStatus, setFilterByStatus] = useState(
        StatusFilterOptions[0],
    );
    const { getTrimmedDomainURL } = useGscHook();
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const { isLTD } = useUserAccessHook();
    const [showIntent, setShowIntent] = useState(false);
    const [viewTitles, setViewTitles] = useState(true);
    const [dropdown, setDropdown] = useState(false);
    const checkBoxClasses = checkBoxCustom();
    useEffect(() => {
        if (
            [PLANS_CATEGORIES.PRO || PLANS_CATEGORIES.PRO_MAX].includes(
                otherInfo?.subscriptionInfo?.plan_category,
            ) ||
            isLTD
        ) {
            setShowIntent(true);
        } else {
            setShowIntent(false);
        }
    }, [isLTD, otherInfo?.subscriptionInfo?.plan_category]);
    useEffect(() => {
        if (clusterInfo.mode == 3) {
            setValue(1);
            setIsClusterView(false);
        }

        if (clusterInfo?.is_excel_sheet === 1) {
            setFilteredSortByOptions3(filteredSortByOptions3_excel);
            setSortBy(filteredSortByOptions3_excel[0]);
        }
    }, [clusterInfo]);

    useEffect(() => {
        setExpandIdx(-1);
        console.log("kpTrack sortby", sortBy, order);
    }, [sortBy.id, order]);
    useEffect(() => {
        if (isClusterView) {
            setSortBy(filteredSortByOptions3[0]);
        }
    }, [isClusterView]);

    // useEffect(() => {
    //     if (isClusterView) {
    //         if (clusterInfo.source == "semrush") {
    //             setSortingOption(sortByOptions);
    //             setSortBy(sortByOptions[0]);
    //             setOrder(0);
    //         } else {
    //             if (isKDAvailable) {
    //                 setSortingOption(sortByOptions3);
    //                 setSortBy(sortByOptions3[0]);
    //                 setOrder(0);
    //             } else {
    //                 setSortingOption(sortByOptions);
    //                 setSortBy(sortByOptions[0]);
    //                 setOrder(0);
    //             }
    //         }
    //     } else {
    //         if (clusterInfo.source == "semrush") {
    //             setSortingOption(sortByOptions5);
    //             setSortBy(sortByOptions5[0]);
    //         } else {
    //             setSortingOption(sortByOptions4);
    //             setSortBy(sortByOptions4[0]);
    //         }
    //     }
    // }, [isClusterView, isKDAvailable]);

    useEffect(() => {
        if (otherInfo.userIntegrationInfo.length > 0) {
            for (let i = 0; i < otherInfo.userIntegrationInfo.length; i++) {
                if (otherInfo.userIntegrationInfo[i].app_id === 3) {
                    if (otherInfo.userIntegrationInfo[i].app_linked === 1) {
                        setClusterBooster(true);
                    }
                }
            }
        }
    }, [otherInfo]);

    // setStatus filter options
    useEffect(() => {
        if (statusFilterCount == 1) {
            setStatusFilterOptions(["All", "Ranked", "Untapped"]);
        } else if (statusFilterCount == 2) {
            setStatusFilterOptions([
                "All",
                "Shared",
                "Unique",
                "Missing",
                "Untapped",
            ]);
        }
    }, [statusFilterCount]);

    useEffect(() => {
        if (clusterKeywords.length) {
            let temp = [];
            console.log("incom", temp);
            clusterKeywords.map((cl) => {
                temp = [...temp, ...cl.data];
            });
            setKeywordCountOfClusters(temp.length || 0);
        }
    }, [clusterKeywords]);

    useEffect(() => {
        console.log(
            "keywordsInCluster clusterKeywords",
            clusterKeywords.length,
        );
        console.log(
            "keywordsInCluster keywordsInCluster",
            keywordsInCluster.length,
        );
        setParentClubbed([...keywordsInCluster, ...clusterKeywords]);
    }, [clusterKeywords, keywordsInCluster]);

    const updateClusterKeyword = async (cluster) => {
        let temp = { ...cluster };
        temp.data = temp.data?.map((item) => {
            let obj = { ...item };
            delete obj.cluster_id;
            return obj;
        });
        let payload = {
            cluster_id: cluster.id,
            data: JSON.stringify({
                ...temp.rawData,
                clusters: temp.data,
            }),
        };
        console.log("updateClusterKeyword payload", payload);
        const res = await postData({
            url: "/api/clustering/update",
            payload,
        });
        if (res.status == 200) {
            console.log("res.data", res.data);
            return true;
        }
        return false;
    };

    useEffect(() => {
        const successCallback = (data) => {
            // goTo("SEO_REPORT", [], data.report_id);
            if (data.reportType == "keyword") {
                setKeywordsInCluster((ps) => {
                    const arr = [...ps];
                    const i = arr.findIndex(
                        (keyword) => document.loadingKeywordId == keyword.id,
                    );
                    arr[i] = { ...arr[i], report_id: data.report_id };
                    return arr;
                });
            } else {
                setClusterKeywords((ps) => {
                    const arr = [...ps];
                    const i = arr.findIndex(
                        (cluster) => cluster.report_id == data?.report_id,
                    );
                    arr[i] = {
                        ...arr[i],
                        report_id: data.report_id,
                        is_completed: 1,
                        failed: false,
                    };
                    return arr;
                });
            }
        };
        const failedCallback = (data) => {
            if (data.reportType == "keyword") {
                setKeywordsInCluster((ps) => {
                    const arr = [...ps];
                    const i = arr.findIndex(
                        (keyword) => document.loadingKeywordId == keyword.id,
                    );
                    arr[i] = { ...arr[i], report_id: null, failed: true };
                    return arr;
                });
            } else {
                setClusterKeywords((ps) => {
                    const arr = [...ps];

                    const i = arr.findIndex(
                        (cluster) => cluster.report_id == data?.report_id,
                    );
                    arr[i] = { ...arr[i], report_id: null, failed: true };

                    return arr;
                });
            }
        };

        const attachSockets = () => {
            socket.on(socketEvents.REPORT_CREATED, successCallback);
            socket.on(socketEvents.REPORT_FAILED, failedCallback);
        };

        const removeSockets = () => {
            socket.off(socketEvents.REPORT_CREATED, successCallback);
            socket.off(socketEvents.REPORT_FAILED, failedCallback);
        };
        getClusterReport(history.location.pathname.split("/")[4]);
        attachSockets();
        return () => {
            removeSockets();
        };
    }, []);

    useEffect(() => {
        clearTimeout();
        if (!isLoading) {
            setTimeout(() => {
                if (!isSubmit) {
                    setClose(false);
                }
            }, 10000);
        }
    }, [isLoading]);

    useEffect(() => {
        if (!close) {
            setIsSubmit(true);
        }
    }, [close]);

    const makeSingleKeywordReport = async (id, keyword, topic_id) => {
        const res = await postData({
            url: "/api/keywords/create",
            payload: {
                country: clusterInfo.search_country,
                keyword_id: id,
                keyword: [keyword],
                ...(topic_id && { topic_id: topic_id }),
            },
        });

        if (res.status == 200) {
            document.loadingKeywordId = id;

            setKeywordsInCluster((ps) => {
                let arr = [...ps];
                arr = arr.map((item) => {
                    if (item.id == id) {
                        item.report_id = 0;
                        return { ...item };
                    } else {
                        return item;
                    }
                });
                return arr;
            });
        }

        return res;
    };

    useEffect(() => {
        let exportData = [];
        let exportData2 = [];
        let userDomain =
            getTrimmedDomainURL(clusterInfo?.domain_name)?.split(".")[0] || "";
        let c1 = "";
        let c2 = "";
        let c3 = "";
        if (clusterInfo?.competitor_domain?.length >= 1) {
            c1 =
                getTrimmedDomainURL(clusterInfo?.competitor_domain[0])?.split(
                    ".",
                )[0] || "";
        }
        if (clusterInfo?.competitor_domain?.length >= 2) {
            c2 =
                getTrimmedDomainURL(clusterInfo?.competitor_domain[1])?.split(
                    ".",
                )[0] || "";
        }
        if (clusterInfo?.competitor_domain?.length >= 3) {
            c3 =
                getTrimmedDomainURL(clusterInfo?.competitor_domain[2])?.split(
                    ".",
                )[0] || "";
        }
        clusterKeywords.map((ele) => {
            if (ele.data.length > 0) {
                ele.data.map((childEle) => {
                    exportData.push({
                        clusterName: ele.data[0].keyword,
                        keyword: childEle.keyword,
                        cpc: childEle.cpc,
                        relevancy: childEle.similarity,
                        searchVolume: childEle.volume,
                        totalVolume: ele.totalVol,
                        keywordDifficulty: childEle.keyword_difficulty,
                        ...(isGSCAvailable && {
                            position: childEle.position || "-",
                        }),
                        ...(userDomain.length && {
                            [userDomain]: ele.position || "-",
                        }),
                        ...(c1.length && {
                            [c1]:
                                childEle.competitor_position[0].position || "-",
                        }),
                        ...(c2.length && {
                            [c2]:
                                childEle.competitor_position[1].position || "-",
                        }),
                        ...(c3.length && {
                            [c3]:
                                childEle.competitor_position[2].position || "-",
                        }),
                        // potentialScore: childEle.potential_score,
                        // isRecommonded:
                        //     childEle.is_recommended_keyword == 1 ? "Yes" : "No",
                    });
                });
            }
        });

        keywordsInCluster.map((ele) => {
            exportData2.push({
                keyword: ele.keyword,
                cpc: ele.cpc,
                relevancy: ele.similarity,
                searchVolume: ele.volume,
                // potentialScore: ele.potential_score,
                // isRecommonded: ele.is_recommended_keyword == 1 ? "Yes" : "No",
            });
        });
        setCsvData1(exportData);
        setCsvData2(exportData2);
    }, [clusterKeywords]);

    async function getClusterReport(id) {
        setIsLoading(true);
        const res = await getData({
            url: CLUSTER_REPORT_GET + "?topic_id=" + id,
        });
        if (res.status == 200) {
            setHasSatisfactionScore(res.data?.hasSatisfactionScore);
            const recommendedClusterView =
                res.data?.recommendedValues?.clusterView;
            const recommendedListView = res.data?.recommendedValues?.listView;
            if (recommendedClusterView && recommendedListView) {
                setCatsData({
                    clusterView: {
                        ...recommendedClusterView,
                        default: {
                            search_volume: recommendedClusterView.default.sv,
                            cpc: recommendedClusterView.default.cpc,
                            relevance: recommendedClusterView.default.rel,
                            potential_score: {
                                min: 0,
                                max: 100,
                            },
                            all: true,
                        },
                    },
                    listView: {
                        ...recommendedListView,
                        default: {
                            search_volume: recommendedListView.default.sv,
                            cpc: recommendedListView.default.cpc,
                            relevance: recommendedListView.default.rel,
                            potential_score: {
                                min: 0,
                                max: 100,
                            },
                            all: true,
                        },
                    },
                });
            }
            let statusFilter = 0; // 0-no filter | 1 - Ranked,Untapped | 2 - All Filters
            setClusterInfo({ ...res.data.topic });
            if (res.data.topic.domain_name?.length) {
                statusFilter = statusFilter + 1;
            }
            if (res.data.topic.competitor_domain?.length > 0) {
                statusFilter = statusFilter + 1;
            }
            setStatusFilterCount(statusFilter);
            if (
                res.data.topic.domain_name?.length ||
                res.data.topic.competitor_domain?.length
            ) {
                setIsCompetitorGap(true);
            } else {
                setIsCompetitorGap(false);
            }
            setSemrushLimitCode(res.data.semrushLimitCode);
            setIsKDAvailable(res.data.isKDAvailable);
            setIsCompetitorAvailable(res.data.isCompetitorAvailable);
            setIsTitleAvailable(res.data.isTitleAvailable);
            setIsGscAvailable(res.data.isGSCAvailable);
            let tempCluster = res.data.cluster;
            let templistViewKeywords = res.data.listViewKeywords;
            let hideFilters = false;
            tempCluster.forEach((item) => {
                item.rawData = JSON.parse(item.data);
                item.cluster_keywords = 0;
                let count = 0;
                let cpcCount = 0;
                let kdCount = 0;
                let similarity = 0;
                let relevancyCount = 0;
                let keywords = 0;
                let tempKeyword = [];
                item.cd = item.cd ? item.cd : 0;
                item.is_cluster = true;
                console.log("sorting cluster", item);
                item.relevancy = item.rel ? item.rel : 0;
                tempKeyword = item.rawData.clusters
                    ? item.rawData.clusters
                    : item.rawData;
                tempKeyword.map((_item) => {
                    cpcCount += _item.cpc * _item.volume || 0;
                    kdCount += _item.competition || 0;
                    count += _item.volume || 0;
                    similarity += _item.similarity || 0;
                    keywords++;
                    _item.cluster_id = item.id;

                    if (
                        _item.cpc === null ||
                        _item.similarity === null ||
                        _item.volume === null
                    ) {
                        hideFilters = true;
                    }
                    return _item;
                });

                item.totalSimilarity = similarity;
                item.totalCPC = isNaN(cpcCount / count)
                    ? cpcCount / keywords
                    : cpcCount / count;
                item.totalKD = kdCount;
                item.totalVol = count;
                item.cluster_keywords = keywords;

                item.data = tempKeyword.clusters
                    ? tempKeyword.clusters
                    : tempKeyword;

                if (item.report_id && item?.is_completed == 2) {
                    item.report_id = null;
                    item.failed = true;
                } else if (item.report_id && item?.is_completed == 3) {
                    item.report_id = null;
                    item.failed = true;
                }
            });
            setHideFilters(hideFilters);

            if (res.data.topic.source != "semrush") {
                if (isClusterView) {
                    if (res.data.isKDAvailable) {
                        setSortingOption(filteredSortByOptions3);
                    } else {
                        setSortingOption(sortByOptions);
                    }
                } else {
                    setSortingOption(sortByOptions4);
                }
            } else if (res.data.topic.source == "semrush") {
                setSortingOption(sortByOptions);
                setSortBy(sortByOptions[0]);
            }

            if (tempCluster.length > 0) {
                if (res.data.topic.mode == 2 && isClusterView) {
                    setSortingOption(sortByOptions2);
                    setSortBy(sortByOptions2[0]);
                }
            }
            setKeywordsInCluster(templistViewKeywords);
            setClusterKeywords(tempCluster);
            setParentClubbed([...templistViewKeywords, ...tempCluster]);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            goTo("CLUSTER");
        }
    }

    const handleColumnSort = (index) => {
        setSortBy((ps) => {
            if (ps?.id == sortByClusterTable[index].id) {
                setOrder((ps) => (ps == 0 ? 1 : 0));
                return sortByClusterTable[index];
            } else {
                setOrder(1);
                return sortByClusterTable[index];
            }
        });
    };
    const sortingFunction = (a, b) => {
        if (order == 1) {
            const temp = b;
            b = a;
            a = temp;
        }
        if (sortBy.id == 1) {
            return a?.rawData[0]?.keyword?.localeCompare(
                b?.rawData[0]?.keyword,
            );
        }
        // else if (sortBy.id == 2) {
        //     return a.ranking_keywords - b.ranking_keywords; // status
        // }
        else if (sortBy.id == 3) {
            return a?.ranking_keywords - b?.ranking_keywords;
        }
        // else if (sortBy.id == 4) {
        //     return a.totalVol - b.totalVol; // intent
        // }
        else if (sortBy.id == 5) {
            return a?.totalVol - b?.totalVol;
        } else if (sortBy.id == 6) {
            return a?.kd - b?.kd;
        } else if (sortBy.id == 10) {
            return a?.relevancy - b?.relevancy;
        } else if (sortBy.id == 12) {
            return a?.data?.length - b?.data?.length;
        }
    };

    //all vs Seo articles in Progress
    const filterFunction = (item) => {
        if (all) {
            return true;
        } else {
            return item.report_id || (item.report_id === 0 && !item.failed)
                ? true
                : false;
        }
    };

    const filterByRanking = (item) => {
        if (filterByStatus == StatusFilterOptions[0]) {
            return true;
        } else if (
            filterByStatus?.toLowerCase() == item?.ranking_status?.toLowerCase()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleExport = () => {
        if (window.XLSX) {
            let obj = {
                otherDomains: [],
                topicId: clusterInfo?.topic_id,
            };
            if (
                clusterInfo.hasOwnProperty("domain_name") &&
                clusterInfo?.domain_name?.length
            ) {
                obj.userDomain = clusterInfo?.domain_name;
            }
            if (
                clusterInfo.hasOwnProperty("competitor_domain") &&
                clusterInfo?.competitor_domain?.length > 0
            ) {
                obj.otherDomains = [...clusterInfo.competitor_domain];
            }
            if (obj?.domain_name || obj?.otherDomains.length > 0) {
                obj.isCompetitorGap = true;
            }
            kpTrackExportCta(obj);
            const workbook = XLSX.utils.book_new();

            const sheet1 = XLSX.utils.json_to_sheet(csvData1, {
                header: headers.slice(0, 6).label,
            });
            XLSX.utils.book_append_sheet(
                workbook,
                sheet1,
                "Extracted Cluster List",
            );

            const sheet2 = XLSX.utils.json_to_sheet(csvData2, {
                header: headers_list_view.label,
            });
            XLSX.utils.book_append_sheet(workbook, sheet2, "Full Keyword List");

            XLSX.writeFile(
                workbook,
                `${clusterInfo.search_keyword}_cluster_report.xlsx`,
            );
        }
    };

    const filterClusterKeywords = (cluster) => {
        if (!isClusterView) return;
        if (search.trim()) {
            for (let j = 0; j < cluster.data.length; j++) {
                if (
                    cluster.data[j].keyword
                        .toLowerCase()
                        .includes(search.toLowerCase())
                ) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        kpTrackTabSwitch({
            tabName:
                newValue == 0
                    ? "Clusters"
                    : newValue == 1
                    ? "Keywords"
                    : "SEO Articles In Progress",
        });
        if (newValue == 0) {
            setAll(true);
            setIsClusterView(true);
        }
        if (newValue == 1) {
            setAll(true);
            setIsClusterView(false);
        }
        if (newValue == 2) {
            setAll(false);
            setIsClusterView(true);
        }
    };

    const sortingKeywords = (a, b) => {
        if (listViewOrder == 0) {
            const temp = b;
            b = a;
            a = temp;
        }
        if (sortBy.id == 5) {
            return a?.cpc - b?.cpc;
        } else if (sortBy.id == 1) {
            return a?.volume - b?.volume;
        } else if (sortBy.id == 3) {
            return a?.similarity - b?.similarity;
        } else if (sortBy.id == 4) {
            return (
                Math.round(a?.competition * 10) -
                Math.round(b?.competition * 10)
            );
        } else if (sortBy.id == 6) {
            return a?.potential_score - b?.potential_score;
        }
    };

    function parseToKeyword(item) {
        if (item.is_cluster) {
            let obj = {
                cpc: item.totalCPC,
                competition: 0,
                report_id: item.report_id,
                volume: item.totalVol,
                similarity: item?.totalSimilarity || 0,
                keyword: item.data[0].keyword,
                ...item,
            };
            item = obj;
        }
        return item;
    }

    function parseToCluster(item) {
        if (!item.is_cluster) {
            let obj = {
                cd: 1,

                totalCPC: item.cpc,
                competition: item?.competition,
                report_id: item.report_id,
                totalVol: item.volume,
                data: [{ ...item }],
                ...item,
            };
            item = obj;
        }
        return item;
    }

    const filterForKeyword = (item) => {
        if (all) {
            return true;
        } else {
            if (item.is_cluster) {
                let obj = {
                    ...item,
                    cluster_id: item?.id,
                    cpc: item.totalCPC,
                    competition: item?.competition,
                    report_id: item.report_id,
                    volume: item.totalVol,
                    similarity: 1.6,
                    keyword: item.data[0].keyword,
                };
                item = obj;
            }
            return item.report_id || (item.report_id === 0 && !item.failed)
                ? true
                : false;
        }
    };

    const searchKeywords = (item) => {
        if (isClusterView) return item;
        if (search.trim()) {
            if (
                item.keyword.toLowerCase().includes(search.trim().toLowerCase())
            ) {
                return item;
            } else {
                return false;
            }
        } else {
            return item;
        }
    };

    const rangeFilter = (item) => {
        if (validRangeState.all) return true;
        if (isClusterView) {
            return (
                validRangeState.search_volume.max >= item.totalVol &&
                item.totalVol >= validRangeState.search_volume.min &&
                validRangeState.cpc.max >= item.totalCPC &&
                item.totalCPC >= validRangeState.cpc.min &&
                validRangeState.relevance.max >= item.relevancy &&
                item.relevancy >= validRangeState.relevance.min &&
                validRangeState.potential_score.max >=
                    item.data[0].potential_score &&
                item.data[0].potential_score >=
                    validRangeState.potential_score.min
            );
        } else {
            return (
                validRangeState.search_volume.max >= item.volume &&
                item.volume >= validRangeState.search_volume.min &&
                validRangeState.cpc.max >= item.cpc &&
                item.cpc >= validRangeState.cpc.min &&
                validRangeState.relevance.max >= item.similarity &&
                item.similarity >= validRangeState.relevance.min &&
                validRangeState.potential_score.max >= item.potential_score &&
                item.potential_score >= validRangeState.potential_score.min
            );
        }
    };

    const clusterKeywordsArray = [
        ...(all ? clusterKeywords : parentClubbed?.map(parseToCluster))
            .filter(filterClusterKeywords)
            .filter(filterFunction)
            .filter(rangeFilter)
            .filter(filterByRanking)
            .sort(sortingFunction),
    ];

    const keywordsFromCluster = [
        ...(all ? keywordsInCluster : parentClubbed?.map(parseToKeyword))
            .filter(searchKeywords)
            .filter(filterForKeyword)
            .filter(rangeFilter)
            .sort(sortingKeywords),
    ];

    const [minRelevance, maxRelevance] = useMemo(() => {
        let max = 0;
        let min = Number.MAX_VALUE;
        const data = isClusterView ? clusterKeywords : keywordsInCluster;

        for (let index = 0; index < data.length; index++) {
            const element = data[index];

            if (isClusterView) {
                if (element.relevancy < min) {
                    min = element.relevancy;
                }
                if (element.relevancy > max) {
                    max = element.relevancy;
                }
            } else {
                if (element.similarity < min) {
                    min = element.similarity;
                }
                if (element.similarity > max) {
                    max = element.similarity;
                }
            }
        }
        return [min, max];
    }, [isClusterView, isLoading]);

    let timeoutRef = useRef(null);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <div className={styles.reportWrap}>
                {isLoading ? (
                    <div
                        style={{
                            height: "100vh",
                            width: "100vw",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={styles.clusterReport__container}>
                        <div
                            style={{
                                display: "block",
                                padding: 0,
                                margin: "10px",
                                marginBottom: "0px",
                                borderTopLeftRadius: "3px",
                                borderTopRightRadius: "3px",
                                position: "sticky",
                                top: "-70px",
                                zIndex: "100",
                            }}
                        >
                            {clusterInfo.mode == 2 && (
                                <NotOptimal
                                    issue={2}
                                    isIcon={true}
                                    title={`The input may be too narrow to find keywords with search volume. Try with a relatively broad keyword for better Keyword Reports.`}
                                />
                            )}
                            {clusterInfo.mode == 3 && (
                                <NotOptimal
                                    issue={1}
                                    isIcon={true}
                                    title={`The input provided is too specific to generate additional keywords. Retry using a broader keyword.
                                    Usage credits will not be consumed for this report.`}
                                />
                            )}
                            <div className={styles.reportTopSection}>
                                <div className={styles.reportDetails}>
                                    <div
                                        className={styles.reportDetails__title}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "baseline",
                                            }}
                                        >
                                            <span
                                                className={styles.clusterLink}
                                                onClick={() => {
                                                    mpTrackBreadCrumb({
                                                        type: "CLUSTER",
                                                    });
                                                    goTo("CLUSTER");
                                                }}
                                            >
                                                All Reports
                                            </span>
                                            &nbsp;/&nbsp;
                                            <span
                                                style={{
                                                    marginLeft: "5px",
                                                    fontSize: "24px",
                                                    color: "#3F5575",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {clusterInfo.search_keyword}
                                            </span>
                                            <LightTooltip
                                                title={
                                                    clusterInfo.search_country
                                                }
                                                arrow
                                            >
                                                <img
                                                    loading="lazy"
                                                    width="18px"
                                                    height="12px"
                                                    style={{
                                                        marginLeft: "20px",
                                                    }}
                                                    src={`https://flagcdn.com/w20/${
                                                        clusterInfo.country_code?.toLowerCase() ===
                                                        "uk"
                                                            ? "gb"
                                                            : clusterInfo.country_code?.toLowerCase() ||
                                                              "us"
                                                    }.png`}
                                                    srcSet={`https://flagcdn.com/w40/${
                                                        clusterInfo.country_code?.toLowerCase() ||
                                                        "us"
                                                    }.png 2x`}
                                                    alt=""
                                                />
                                            </LightTooltip>
                                            <div
                                                className={
                                                    styles.reportDetails__createdAt
                                                }
                                            >
                                                {clusterInfo.is_sample ? (
                                                    <>Sample Cluster Report</>
                                                ) : (
                                                    <>
                                                        <SVGIcon src="/New UI/SVG/AiOutlineClockCircle.svg" />{" "}
                                                        {clusterInfo.created_at}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            {semrushLimitCode == 1 ? (
                                                <div
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "grey",
                                                        marginLeft: "1rem",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginRight: "15px",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            margin: "0",
                                                            paddingBottom:
                                                                "4px",
                                                            paddingRight: "2px",
                                                        }}
                                                    >
                                                        Keywords data powered by
                                                    </p>
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/Semrush_logo.svg"
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.subHeading}>
                                        {!all ? (
                                            <>
                                                <span className={styles.bold}>
                                                    {
                                                        parentClubbed?.filter(
                                                            (item) =>
                                                                item.report_id ||
                                                                (item.report_id ===
                                                                    0 &&
                                                                    !item.failed),
                                                        )?.length
                                                    }{" "}
                                                    SEO{" "}
                                                    {parentClubbed?.filter(
                                                        (item) =>
                                                            item.report_id ||
                                                            (item.report_id ===
                                                                0 &&
                                                                !item.failed),
                                                    )?.length > 1
                                                        ? "articles"
                                                        : "article"}
                                                </span>{" "}
                                                created in total
                                            </>
                                        ) : isClusterView ? (
                                            <>
                                                <span className={styles.bold}>
                                                    {
                                                        clusterKeywordsArray.length
                                                    }{" "}
                                                    keyword clusters
                                                </span>{" "}
                                                from{" "}
                                                <span className={styles.bold}>
                                                    {/* {keywordCountOfClusters} */}
                                                    {keywordsInCluster?.length}{" "}
                                                    high-impact
                                                </span>{" "}
                                                keywords
                                                {isGSCAvailable == 1 && (
                                                    <span>
                                                        {" "}
                                                        with the positions of{" "}
                                                        <a
                                                            href={
                                                                "https://" +
                                                                clusterInfo?.domain_name?.split(
                                                                    ":",
                                                                )[1]
                                                            }
                                                            target="_blank"
                                                        >
                                                            {
                                                                clusterInfo?.domain_name?.split(
                                                                    ":",
                                                                )[1]
                                                            }
                                                        </a>
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                Complete list of{" "}
                                                <span className={styles.bold}>
                                                    {keywordsInCluster.length}{" "}
                                                    related keywords
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div
                                        className={styles.reportDetails__bottom}
                                    >
                                        <div
                                            className={
                                                styles.reportDetails__clusterCount
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Tabs
                                                    classes={classesTabs}
                                                    value={value}
                                                    onChange={handleChange}
                                                >
                                                    <Tab
                                                        disableRipple={true}
                                                        disabled={
                                                            clusterKeywords.length ==
                                                            0
                                                        }
                                                        classes={classesTab}
                                                        label={
                                                            <div
                                                                className={
                                                                    styles.sectionCount
                                                                }
                                                            >
                                                                Clusters
                                                                <span>
                                                                    {
                                                                        clusterKeywords?.length
                                                                    }
                                                                </span>
                                                            </div>
                                                        }
                                                        {...a11yProps(0)}
                                                    />
                                                    <Tab
                                                        disableRipple={true}
                                                        classes={classesTab}
                                                        label={
                                                            <div
                                                                className={
                                                                    styles.sectionCount
                                                                }
                                                            >
                                                                Keywords
                                                                <span>
                                                                    {
                                                                        keywordsInCluster?.length
                                                                    }
                                                                </span>
                                                            </div>
                                                        }
                                                        {...a11yProps(1)}
                                                    />
                                                    <Tab
                                                        disableRipple={true}
                                                        classes={classesTab2}
                                                        label={
                                                            <div
                                                                className={
                                                                    styles.sectionCount
                                                                }
                                                            >
                                                                SEO Articles In
                                                                Progress
                                                                <span>
                                                                    {
                                                                        parentClubbed?.filter(
                                                                            (
                                                                                item,
                                                                            ) =>
                                                                                item.report_id ||
                                                                                (item.report_id ===
                                                                                    0 &&
                                                                                    !item.failed),
                                                                        )
                                                                            ?.length
                                                                    }
                                                                </span>
                                                            </div>
                                                        }
                                                        {...a11yProps(2)}
                                                    />
                                                </Tabs>
                                            </div>
                                        </div>
                                        <Button
                                            width={"110px"}
                                            height={"36px"}
                                            customClass={styles.exportCta}
                                            style={{
                                                float: "right",
                                                backgroundColor: "#e9edf8",
                                                color: "#1f4cbe",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginRight: "15px",
                                                marginBottom: "10px",
                                            }}
                                            text={"Export"}
                                            handler={handleExport}
                                            Icon={() => (
                                                <SVGIcon
                                                    size={20}
                                                    src={
                                                        "/New UI/SVG/AiOutlineExport.svg"
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                        </div>

                        <div className={styles.reportClusters}>
                            <div
                                className={styles.reportClusters__options}
                                // style={{
                                //     marginBottom: isClusterView
                                //         ? "0px"
                                //         : "10px",
                                // }}
                            >
                                {/* {!all && (
                                    <div className={styles.categoryBtns}>
                                        <Button
                                            height={"36px"}
                                            width={"100px"}
                                            borderRadius={"3px"}
                                            style={{
                                                backgroundColor: isClusterView
                                                    ? "#1f4cbe"
                                                    : "#fafafb",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: isClusterView
                                                    ? "white"
                                                    : "#3f5575",
                                            }}
                                            text={"Clusters"}
                                            handler={() => {
                                                setIsClusterView(true);
                                            }}
                                        />

                                        <Button
                                            height={"36px"}
                                            width={"100px"}
                                            borderRadius={"3px"}
                                            text={"Keywords"}
                                            style={{
                                                backgroundColor: isClusterView
                                                    ? "#fafafb"
                                                    : "#1f4cbe",
                                                color: isClusterView
                                                    ? "#3f5575"
                                                    : "white",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                border: "1px solid #e5e5e5",
                                            }}
                                            handler={() => {
                                                setIsClusterView(false);
                                            }}
                                        />
                                    </div>
                                )} */}
                                {/* <div className={styles.categoryBtns}>
                                    <Button
                                        height={"36px"}
                                        width={"56px"}
                                        borderRadius={"3px"}
                                        style={{
                                            backgroundColor: all
                                                ? "#1f4cbe"
                                                : "#fafafb",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            color: all ? "white" : "#3f5575",
                                        }}
                                        text={"All"}
                                        handler={() => {
                                            setAll(true);
                                        }}
                                    />

                                    <Button
                                        height={"36px"}
                                        width={"160px"}
                                        borderRadius={"3px"}
                                        text={"SEO Articles in Progress"}
                                        style={{
                                            backgroundColor: all
                                                ? "#fafafb"
                                                : "#1f4cbe",
                                            color: all ? "#3f5575" : "white",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            border: "1px solid #e5e5e5",
                                        }}
                                        handler={() => {
                                            setAll(false);
                                        }}
                                    />
                                </div> */}
                                <div className={styles.rrFilters}>
                                    {/*Search Filter*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "240px",
                                            }}
                                            className={styles.search}
                                        >
                                            <Textfield
                                                height={36}
                                                containerStyle={{
                                                    marginBottom: "0",
                                                }}
                                                width={"240px"}
                                                props={{
                                                    onChange: (e) => {
                                                        const value =
                                                            e.target.value.trim();
                                                        debounceWrapper(
                                                            () =>
                                                                setSearch(
                                                                    value,
                                                                ),
                                                            500,
                                                            timeoutRef,
                                                        );
                                                    },
                                                    style: {
                                                        borderRadius: 3,
                                                        border: `solid 1px ${
                                                            search
                                                                ? "var(--primary-blue)"
                                                                : "rgba(75, 75, 85, 0.16)"
                                                        }`,
                                                        backgroundColor: "#fff",
                                                        paddingLeft: "40px",
                                                        borderRadius: 3,
                                                    },
                                                }}
                                                placeholder="Search by keyword"
                                                label=""
                                                Icon={() => (
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/searchIcon.svg"
                                                        }
                                                    />
                                                )}
                                                iconStyle={{
                                                    left: "10px",
                                                    right: "unset",
                                                    top: "19px",
                                                }}
                                            />
                                        </div>
                                        {value == 0 && isTitleAvailable && (
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={
                                                    "You can enable this to get title ideas for your clusters. Titles are generated by AI using the search intent and top ranking competitors for the cluster."
                                                }
                                            >
                                                <FormControlLabel
                                                    style={{
                                                        // background: "#6e6e6e0a",
                                                        padding: "0px 15px",
                                                        height: 36,
                                                        borderRadius: "3px",
                                                        border: "1px solid rgba(75, 75, 85, 0.16)",
                                                        marginLeft: "15px",
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            color={"primary"}
                                                            checked={viewTitles}
                                                            classes={
                                                                checkBoxClasses
                                                            }
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                setViewTitles(
                                                                    e.target
                                                                        .checked,
                                                                );
                                                            }}
                                                            name="checkedB"
                                                        />
                                                    }
                                                    label={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    color: "#2c3948c2",
                                                                    marginLeft:
                                                                        "-5px",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                View titles
                                                                <div
                                                                    className={
                                                                        styles.aiTag
                                                                    }
                                                                >
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                "4px",
                                                                        }}
                                                                    >
                                                                        <HiSparkles />
                                                                    </span>
                                                                    AI Powered
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </LightTooltip>
                                        )}
                                    </div>
                                    {isClusterView &&
                                        (isCompetitorGap ? (
                                            <ClickAwayListener
                                                onClickAway={() =>
                                                    setDropdown(false)
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#3f5575",
                                                            fontSize: 14,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        Ranking Status
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.statusFiltersWrapper
                                                        }
                                                        style={{
                                                            position:
                                                                "relative",
                                                        }}
                                                        onClick={() =>
                                                            setDropdown(
                                                                !dropdown,
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                width: "100%",
                                                                color: "#2c3948c2",
                                                                fontSize:
                                                                    "13px",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <span>
                                                                {filterByStatus}
                                                            </span>
                                                            <SVGIcon
                                                                src={
                                                                    "/New UI/SVG/chevronDown.svg"
                                                                }
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            />
                                                        </div>
                                                        {dropdown && (
                                                            <div
                                                                className={
                                                                    styles.rankOptions
                                                                }
                                                            >
                                                                <ClickAwayListener
                                                                    onClickAway={() =>
                                                                        setDropdown(
                                                                            false,
                                                                        )
                                                                    }
                                                                >
                                                                    <>
                                                                        {StatusFilterOptions.map(
                                                                            (
                                                                                opt,
                                                                                i,
                                                                            ) => (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        setFilterByStatus(
                                                                                            opt,
                                                                                        );
                                                                                    }}
                                                                                    key={
                                                                                        opt +
                                                                                        i
                                                                                    }
                                                                                    style={{
                                                                                        marginBlock: 3,
                                                                                        borderRadius: 4,
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "flex-start",
                                                                                        alignItems:
                                                                                            "center",
                                                                                        gap: "5px",
                                                                                    }}
                                                                                    className={
                                                                                        opt?.toLowerCase() ==
                                                                                        "ranked"
                                                                                            ? styles.ranked
                                                                                            : opt?.toLowerCase() ==
                                                                                              "untapped"
                                                                                            ? styles.untapped
                                                                                            : opt?.toLowerCase() ==
                                                                                              "shared"
                                                                                            ? styles.shared
                                                                                            : opt?.toLowerCase() ==
                                                                                              "unique"
                                                                                            ? styles.unique
                                                                                            : opt?.toLowerCase() ==
                                                                                              "missing"
                                                                                            ? styles.missing
                                                                                            : styles.default
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        opt
                                                                                    }
                                                                                </div>
                                                                            ),
                                                                        )}
                                                                    </>
                                                                </ClickAwayListener>
                                                            </div>
                                                        )}
                                                        {/* <select
                                                    value={filterByStatus}
                                                    onChange={(e) => {
                                                        setFilterByStatus(
                                                            e.target.value,
                                                        );
                                                        kpTrackFilter({
                                                            type: "Ranking Status",
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                    // displayEmpty
                                                    // inputProps={{
                                                    //     "aria-label":
                                                    //         "Without label",
                                                    // }}
                                                >
                                                    {StatusFilterOptions.map(
                                                        (opt, i) => (
                                                            <option
                                                                value={opt}
                                                                key={i + 1}
                                                                className={
                                                                    styles.rankOptions
                                                                }
                                                                style={{
                                                                    color: "#6b6b6b",
                                                                }}
                                                            >
                                                                {opt}
                                                            </option>
                                                        ),
                                                    )}
                                                </select> */}
                                                        {/* {StatusFilterOptions &&
                                                    StatusFilterOptions.map(
                                                        (opt, i) => {
                                                            return (
                                                                <LightTooltip
                                                                    title={
                                                                        opt !=
                                                                        StatusFilterOptions[0]
                                                                            ? StatusFilterOptions_Tooltips[
                                                                                  opt?.toLowerCase()
                                                                              ]
                                                                            : ""
                                                                    }
                                                                    placement={
                                                                        "top"
                                                                    }
                                                                    arrow
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.statusFilterOpt
                                                                        }
                                                                        onClick={() => {
                                                                            setFilterByStatus(
                                                                                opt,
                                                                            );
                                                                            kpTrackFilter(
                                                                                {
                                                                                    type: "Ranking Status",
                                                                                    value: opt,
                                                                                },
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={`${
                                                                                styles.statusFilterOpt_text
                                                                            } ${
                                                                                opt ==
                                                                                    filterByStatus &&
                                                                                styles.active
                                                                            }`}
                                                                        >
                                                                            {
                                                                                opt
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </LightTooltip>
                                                            );
                                                        },
                                                    )} */}
                                                    </div>
                                                    {isClusterView &&
                                                        isCompetitorGap && (
                                                            <MoreFilters
                                                                isCompetitorGap={
                                                                    isCompetitorGap
                                                                }
                                                                setOrder={
                                                                    setOrder
                                                                }
                                                                order={order}
                                                                setSortBy={
                                                                    setSortBy
                                                                }
                                                                sortBy={sortBy}
                                                                setShowMoreFilters={
                                                                    setShowMoreFilters
                                                                }
                                                                showMoreFilters={
                                                                    showMoreFilters
                                                                }
                                                                filteredSortByOptions3={
                                                                    filteredSortByOptions3
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </ClickAwayListener>
                                        ) : (
                                            <MoreFilters
                                                isCompetitorGap={
                                                    isCompetitorGap
                                                }
                                                setOrder={setOrder}
                                                order={order}
                                                setSortBy={setSortBy}
                                                sortBy={sortBy}
                                                setShowMoreFilters={
                                                    setShowMoreFilters
                                                }
                                                showMoreFilters={
                                                    showMoreFilters
                                                }
                                                filteredSortByOptions3={
                                                    filteredSortByOptions3
                                                }
                                            />
                                        ))}

                                    {/* Ranking Filter Ends */}
                                    {/* <div
                                        className={
                                            styles.ClusterToggler_Wrapper
                                        }
                                    >
                                        {isClusterView ? (
                                               <NewUIDropDown
                                        options={sortingOption}
                                        setOrder={(val) => {
                                            setOrder(val);
                                            
                                        }}
                                        order={order}
                                        style={{
                                            textTransform: "capitalize",
                                            height: 36,
                                            color: "#6b6b6b",
                                            width: 170,
                                            minWidth: "280px",
                                            textAlign: "left",
                                        }}
                                        type={TYPES.SORT}
                                        selectedOption={sortBy}
                                        handler={(option) => {
                                            setSortBy(option);
                                            
                                        }}
                                    />
                                        ) : (
                                            <></>
                                        )}
                                    </div> */}

                                    {!isClusterView &&
                                        catsData &&
                                        !hideFilters && (
                                            <ClusterCatsFilter
                                                setValidRangeState={
                                                    setValidRangeState
                                                }
                                                catsData={catsData}
                                                isClusterView={isClusterView}
                                                validRangeState={
                                                    validRangeState
                                                }
                                                all={all}
                                                search={search}
                                            />
                                        )}
                                    {!isClusterView &&
                                        catsData &&
                                        !hideFilters && (
                                            <ClusterFilter
                                                validState={validRangeState}
                                                setValidState={
                                                    setValidRangeState
                                                }
                                                catsData={catsData}
                                                isClusterView={isClusterView}
                                            />
                                        )}
                                </div>
                            </div>
                            {(isClusterView
                                ? clusterKeywordsArray
                                : keywordsFromCluster
                            ).length ? (
                                <>
                                    {graphOn && !isClusterView ? (
                                        <div
                                            className={
                                                styles.clusterGraphContainer
                                            }
                                        >
                                            <h3>Search volume vs CPC</h3>

                                            <ClusterGraph
                                                data={
                                                    isClusterView
                                                        ? clusterKeywordsArray
                                                        : keywordsFromCluster
                                                }
                                                isClusterView={isClusterView}
                                                minRelevance={minRelevance}
                                                maxRelevance={maxRelevance}
                                                makeSingleKeywordReport={
                                                    makeSingleKeywordReport
                                                }
                                                _setIsClusterModal={
                                                    setIsClusterModal
                                                }
                                                setSelectedCluster={
                                                    setSelectedCluster
                                                }
                                                clusterInfo={clusterInfo}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div
                                        className={styles3.clusterTableWrapper}
                                        style={{
                                            // ...(!isCompetitorGap && {
                                            //     maxHeight:
                                            //         "calc(100vh - 222px)",
                                            // }),
                                            ...(isClusterView && {
                                                border: "1px solid #e1e4ed",
                                            }),
                                        }}
                                    >
                                        {isClusterView && (
                                            <div
                                                className={
                                                    styles3.clusterHeadRowWrapper
                                                }
                                                style={{
                                                    ...(isClusterView &&
                                                        {
                                                            // top: isCompetitorGap
                                                            //     ? "118px"
                                                            //     : "65px",
                                                        }),
                                                }}
                                            >
                                                <div
                                                    className={`${
                                                        styles3.clusterHead_keyword
                                                    } ${
                                                        sortBy.id ==
                                                            sortByClusterTable[0]
                                                                .id &&
                                                        styles3.enable
                                                    }`}
                                                    onClick={() => {
                                                        handleColumnSort(0);
                                                    }}
                                                >
                                                    {all
                                                        ? "Clustered Keywords"
                                                        : "Keywords"}
                                                    <SVGIcon
                                                        size={20}
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "#2c3948c2",
                                                            paddingLeft: "4px",
                                                            paddingRight: "4px",
                                                        }}
                                                        src={`/New UI/SVG/sort${
                                                            order == 0 &&
                                                            sortByClusterTable[0]
                                                                .id == sortBy.id
                                                                ? "A"
                                                                : "D"
                                                        }.svg`}
                                                    />
                                                </div>
                                                {isCompetitorGap && (
                                                    <LightTooltip
                                                        title={
                                                            "This column categorizes each cluster based on the competitiveness of keyword in cluster. It helps identify whether competitors are ranking, if you are ranking, or if the cluster represents untapped opportunities."
                                                        }
                                                        arrow
                                                        placement={"top"}
                                                    >
                                                        <div
                                                            className={`${
                                                                styles3.clusterHead_status
                                                            } ${
                                                                sortBy.id ==
                                                                    sortByClusterTable[1]
                                                                        .id &&
                                                                styles3.enable
                                                            }`}
                                                            // onClick={() => {
                                                            //     handleColumnSort(
                                                            //         1,
                                                            //     );
                                                            // }}
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            Ranking
                                                            <br />
                                                            Status
                                                            {/* <SVGIcon
                                                                size={20}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: "#2c3948c2",
                                                                    paddingLeft:
                                                                        "4px",
                                                                    paddingRight:
                                                                        "4px",
                                                                }}
                                                                src={`/New UI/SVG/sort${
                                                                    order ==
                                                                        0 &&
                                                                    sortByClusterTable[1]
                                                                        .id ==
                                                                        sortBy.id
                                                                        ? "A"
                                                                        : "D"
                                                                }.svg`}
                                                            /> */}
                                                        </div>
                                                    </LightTooltip>
                                                )}
                                                {isCompetitorGap && (
                                                    <LightTooltip
                                                        title={
                                                            "Number of keywords your domain is ranking for out-of the total keywords present in the cluster. It measures your website's performance for the cluster."
                                                        }
                                                        placement={"top"}
                                                        arrow
                                                    >
                                                        <div
                                                            className={`${
                                                                styles3.clusterHead_ranks
                                                            } ${
                                                                sortBy.id ==
                                                                    sortByClusterTable[2]
                                                                        .id &&
                                                                styles3.enable
                                                            }`}
                                                            onClick={() => {
                                                                handleColumnSort(
                                                                    2,
                                                                );
                                                            }}
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            Keywords
                                                            <br />
                                                            Ranked
                                                            <SVGIcon
                                                                size={20}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: "#2c3948c2",
                                                                    paddingLeft:
                                                                        "4px",
                                                                    paddingRight:
                                                                        "4px",
                                                                }}
                                                                src={`/New UI/SVG/sort${
                                                                    order ==
                                                                        0 &&
                                                                    sortByClusterTable[2]
                                                                        .id ==
                                                                        sortBy.id
                                                                        ? "A"
                                                                        : "D"
                                                                }.svg`}
                                                            />
                                                        </div>
                                                    </LightTooltip>
                                                )}
                                                {showIntent && (
                                                    <LightTooltip
                                                        title={
                                                            "Intent refers to type of search intent—i.e., the general purpose behind the user’s search. It helps to understand what a person wants when they enter a search query. Knowing intent is essential for creating content that matches their needs and interests."
                                                        }
                                                        placement={"top"}
                                                        arrow
                                                    >
                                                        <div
                                                            className={`${
                                                                styles3.clusterHead_intent
                                                            } ${
                                                                sortBy.id ==
                                                                    sortByClusterTable[3]
                                                                        .id &&
                                                                styles3.enable
                                                            }`}
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                color: "#252733",
                                                                fontWeight:
                                                                    "400",
                                                            }}
                                                            // onClick={() => {
                                                            //     handleColumnSort(3);
                                                            // }}
                                                        >
                                                            Intent
                                                            {/* <SVGIcon
                                                            size={20}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#2c3948c2",
                                                                paddingLeft:
                                                                    "4px",
                                                                paddingRight:
                                                                    "4px",
                                                            }}
                                                            src={`/New UI/SVG/sort${
                                                                order == 0 &&
                                                                sortByClusterTable[3]
                                                                    .id ==
                                                                    sortBy.id
                                                                    ? "A"
                                                                    : "D"
                                                            }.svg`}
                                                        /> */}
                                                        </div>
                                                    </LightTooltip>
                                                )}

                                                <LightTooltip
                                                    title={
                                                        "The average monthly search volume for the keyword during the past 12 months."
                                                    }
                                                    placement={"top"}
                                                    arrow
                                                >
                                                    <div
                                                        className={`${
                                                            styles3.clusterHead_volume
                                                        } ${
                                                            sortBy.id ==
                                                                sortByClusterTable[4]
                                                                    .id &&
                                                            styles3.enable
                                                        }`}
                                                        onClick={() => {
                                                            handleColumnSort(4);
                                                        }}
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Search
                                                        <br />
                                                        Volume
                                                        <SVGIcon
                                                            size={20}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#2c3948c2",
                                                                paddingLeft:
                                                                    "4px",
                                                                paddingRight:
                                                                    "4px",
                                                            }}
                                                            src={`/New UI/SVG/sort${
                                                                order == 0 &&
                                                                sortByClusterTable[4]
                                                                    .id ==
                                                                    sortBy.id
                                                                    ? "A"
                                                                    : "D"
                                                            }.svg`}
                                                        />
                                                    </div>
                                                </LightTooltip>

                                                <LightTooltip
                                                    title={
                                                        <>
                                                            Weighted Average
                                                            Keyword Difficulty
                                                            indicates how
                                                            challenging it would
                                                            be for a website to
                                                            rank organically in
                                                            the Google top 10
                                                            for the entire set
                                                            of analyzed keywords
                                                            in the cluster.
                                                            <br /> A higher KD
                                                            percentage implies
                                                            greater difficulty
                                                            in achieving high
                                                            rankings for the
                                                            keywords within the
                                                            cluster.
                                                        </>
                                                    }
                                                    placement={"top"}
                                                    arrow
                                                >
                                                    <div
                                                        className={`${
                                                            styles3.clusterHead_kd
                                                        } ${
                                                            sortBy.id ==
                                                                sortByClusterTable[5]
                                                                    .id &&
                                                            styles3.enable
                                                        }`}
                                                        onClick={() => {
                                                            handleColumnSort(5);
                                                        }}
                                                    >
                                                        KD
                                                        <SVGIcon
                                                            size={20}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#2c3948c2",
                                                                paddingLeft:
                                                                    "4px",
                                                                paddingRight:
                                                                    "4px",
                                                            }}
                                                            src={`/New UI/SVG/sort${
                                                                order == 0 &&
                                                                sortByClusterTable[5]
                                                                    .id ==
                                                                    sortBy.id
                                                                    ? "A"
                                                                    : "D"
                                                            }.svg`}
                                                        />
                                                    </div>
                                                </LightTooltip>
                                                {isCompetitorAvailable && (
                                                    <LightTooltip
                                                        title={
                                                            <>
                                                                Top ranking
                                                                domains for the
                                                                cluster
                                                            </>
                                                        }
                                                        placement={"top"}
                                                        arrow
                                                    >
                                                        <div
                                                            className={`${
                                                                styles3.clusterHead_competition
                                                            } ${
                                                                sortBy.id ==
                                                                    sortByClusterTable[5]
                                                                        .id &&
                                                                styles3.enable
                                                            }`}
                                                            // onClick={() => {
                                                            //     handleColumnSort(5);
                                                            // }}
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                paddingLeft: 20,
                                                            }}
                                                        >
                                                            Top
                                                            <br />
                                                            Domains
                                                        </div>
                                                    </LightTooltip>
                                                )}
                                                <div
                                                    className={
                                                        styles3.clusterHead_progress
                                                    }
                                                ></div>
                                                <div
                                                    className={
                                                        styles3.clusterHead_actions
                                                    }
                                                ></div>
                                            </div>
                                        )}
                                        <div
                                            className={styles.clusterRows}
                                            style={{
                                                ...(isClusterView && {
                                                    // border: "1px solid #e1e4ed",
                                                    borderTop: "none",
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                }),
                                            }}
                                            id="clusterCards"
                                        >
                                            {isClusterView ? (
                                                <RenderInChucks
                                                    list={clusterKeywordsArray}
                                                >
                                                    {(cluster, i) => {
                                                        if (
                                                            !cluster.hasOwnProperty(
                                                                "show",
                                                            ) ||
                                                            (cluster.hasOwnProperty(
                                                                "show",
                                                            ) &&
                                                                cluster.show)
                                                        )
                                                            return (
                                                                <ClusterRow
                                                                    all={all}
                                                                    _setClusterKeywords={
                                                                        all
                                                                            ? setClusterKeywords
                                                                            : setParentClubbed
                                                                    }
                                                                    cluster={
                                                                        cluster
                                                                    }
                                                                    index={i}
                                                                    expandIdx={
                                                                        expandIdx
                                                                    }
                                                                    setExpandIdx={
                                                                        setExpandIdx
                                                                    }
                                                                    clusterInfo={
                                                                        clusterInfo
                                                                    }
                                                                    key={
                                                                        cluster.id
                                                                    }
                                                                    _setIsClusterModal={
                                                                        setIsClusterModal
                                                                    }
                                                                    setSelectedCluster={
                                                                        setSelectedCluster
                                                                    }
                                                                    isKDAvailable={
                                                                        isKDAvailable
                                                                    }
                                                                    isCompetitorAvailable={
                                                                        isCompetitorAvailable
                                                                    }
                                                                    isGSCAvailable={
                                                                        isGSCAvailable
                                                                    }
                                                                    isCompetitorGap={
                                                                        isCompetitorGap
                                                                    }
                                                                    showIntent={
                                                                        showIntent
                                                                    }
                                                                    viewTitles={
                                                                        viewTitles
                                                                    }
                                                                />
                                                            );
                                                        else {
                                                            return <></>;
                                                        }
                                                    }}
                                                </RenderInChucks>
                                            ) : (
                                                <ClusterKeywordPlanner
                                                    all={all}
                                                    _setClusterKeywords={
                                                        setKeywordsInCluster
                                                    }
                                                    clusterInfo={clusterInfo}
                                                    keywordsInCluster={
                                                        keywordsFromCluster
                                                    }
                                                    setOrder={setListViewOrder}
                                                    order={listViewOrder}
                                                    setSortBy={setSortBy}
                                                    sortBy={sortBy}
                                                    sortingOption={
                                                        sortingOption
                                                    }
                                                    sortByOptions4={
                                                        sortByOptions4
                                                    }
                                                    sortByOptions5={
                                                        sortByOptions5
                                                    }
                                                    makeSingleKeywordReport={
                                                        makeSingleKeywordReport
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className={styles.noItems}>
                                    {
                                        <>
                                            <SVGIcon
                                                style={{ margin: "auto" }}
                                                src={
                                                    "/New UI/SVG/Emptydocument.svg"
                                                }
                                                size={300}
                                            />
                                            <h3
                                                style={{
                                                    marginTop: -50,
                                                    fontWeight: "500",
                                                }}
                                            >
                                                No{" "}
                                                {all
                                                    ? isClusterView
                                                        ? "Clusters "
                                                        : "Keywords "
                                                    : "Articles "}
                                                Found
                                            </h3>
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                        {!close && !hasSatisfactionScore && (
                            <UserFeedback
                                setClose={() => {
                                    kpUserFeedbackClosed();
                                    setClose(true);
                                }}
                                section="keywordPlanner"
                                topic_id={clusterInfo?.topic_id}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ClusterReport;
