import React from "react";
import styles from "./ModalComponent.module.css";
import CopyText from "../../../organisms/Research/CopyText";
import CustomHighlighter from "../../../atoms/CustomHighlighter";

function minimizeText(textContent, text, text2) {
    if (textContent.length < 350) return textContent;

    const regex = new RegExp(`\\b${text}\\b`, "gi");
    // console.log("reg ex", regex)
    let strIndex = textContent.toLowerCase().search(regex);

    if (strIndex == -1) {
        const regex = new RegExp(`\\b${text2}\\b`, "gi");
        strIndex = textContent.toLowerCase().search(regex);
    }

    let postText = "";
    let prevText = "";
    text = text.toLowerCase();
    textContent = textContent.toLowerCase();

    postText = textContent.toLowerCase().substring(strIndex, strIndex + 120);
    prevText = textContent.toLowerCase().substring(strIndex - 120, strIndex);
    let fullStr = (prevText + postText).split(" ")[0].includes(text)
        ? (prevText + postText).split(" ").slice(0, -1).join(" ")
        : (prevText + postText).split(" ").slice(1, -1).join(" ");
    return "..." + fullStr + "...";
}

const DataComponent = ({ data, keyWord, keyWordVariation, heading }) => {
    console.log(data);

    return (
        <div className={styles.dataItemContainer}>
            {data &&
                data.length > 0 &&
                data.map((item) => {
                    if (heading && item.type == 1) return;
                    return (
                        <div className={styles.dataItem}>
                            <div className={styles.rank}>
                                <span>#{item.url_rank}</span>
                                <div className={styles.url}>
                                    <a href={item.url} target="_blank">
                                        {new URL(item.url).origin}
                                    </a>
                                </div>
                                {!heading && (
                                    <span
                                        style={{
                                            whiteSpace: "nowrap",
                                            marginLeft: "auto",
                                            color: "#92929d",
                                        }}
                                    >
                                        Used {item.occurence_frequency} times
                                    </span>
                                )}
                            </div>
                            <div>
                                {heading ? (
                                    <CopyText text={item.headingUsage}>
                                        {item.headingUsage}
                                    </CopyText>
                                ) : (
                                    <CustomHighlighter
                                        highlightClassName={styles.highlight}
                                        searchWords={[
                                            new RegExp(`\\b${keyWord}\\b`, "g"),
                                            new RegExp(
                                                `\\b${keyWordVariation}\\b`,
                                                "g",
                                            ),
                                        ]}
                                        // searchWords={[/(?:^|\W)in(?:$|\W)/g]}
                                        // searchWords={[
                                        //     ...getArr(keyWord),
                                        //     ...getArr(keyWordVariation),
                                        // ]}
                                        textToHighlight={minimizeText(
                                            item?.textUsage,
                                            keyWord,
                                            keyWordVariation,
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}

            {data && data.length === 0 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        fontSize: "1rem",
                        fontFamily: "Inter",
                        color: "#29394a",
                    }}
                >
                    {heading
                        ? "NLP Term not found in any heading."
                        : "NLP Term data not found."}
                </div>
            )}
        </div>
    );
};

export default DataComponent;
