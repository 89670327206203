import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import styles from "./styles.module.css";
import Confetti from "react-dom-confetti";
import { ConfettiConfig } from "../WebTune/constants";
import { GoRocket } from "react-icons/go";
import { BsArrowLeft, BsArrowRight, BsCheckCircleFill } from "react-icons/bs";
import useHelperFunctions from "../../helperFunctions";
import Button from "../../atoms/Button/Button";
import { BASE_URL, endPoints } from "./constants";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";
import Feedback from "../../Feedback/Feedback";

function PublishStrip({
    setReview,
    review,
    isVerified = false,
    totalApprovedCount,
}: {
    review: boolean;
    isVerified: boolean;
    totalApprovedCount: number;
    setReview: Dispatch<SetStateAction<boolean>>;
}) {
    const { lmReview, lmPublish } = useLinkManagerMixpanel();
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const [loading, setLoading] = useState(false);
    const [startConfetti, setStartConfetti] = useState(false);
    const { postData } = useHelperFunctions();
    const [showFeedback, setShowFeedback] = useState(false);
    useEffect(() => {
        if (startConfetti)
            setTimeout(() => {
                setStartConfetti(false);
            }, 5000);
    }, [startConfetti]);

    useEffect(() => {
        setShowFeedback(!!selectedDomainInfo?.feedbackShow);
    }, [selectedDomainInfo?.feedbackShow]);

    const publishChanges = async () => {
        setLoading(true);
        await postData({
            completeUrl: BASE_URL + endPoints.publishInterlinking,
            payload: { domain_id: selectedDomainInfo?.id },
        });
        setReview(false);
        setStartConfetti(true);
        setLoading(false);
    };
    const handleFeedback = async (
        feedback = {
            imgUrl: "",
            comment: "",
            quality_score: 0,
            satisfaction_score: 0,
            connection_difficulty: "",
        },
    ) => {
        console.log("handleFeedback e", feedback);
        let payload = {
            domain_id: selectedDomainInfo?.id,
            quality_score: feedback?.quality_score || 0,
            connection_difficulty: feedback.connection_difficulty || "",
            satisfaction_score: feedback?.satisfaction_score || 0,
            comment: feedback.comment,
            image_url: feedback.imgUrl,
        };
        const res = await postData({
            completeUrl: BASE_URL + endPoints.feedbackLM,
            payload,
        });

        if (res.status == 200 || res.status == 201) {
            setShowFeedback(false);
        }
    };

    return (
        <div className={styles.publishStrip}>
            {review ? (
                <Button
                    handler={() => {
                        setReview(!true);
                        lmReview({
                            ctaType: "Review",
                            ctaUsed: "Review Changes",
                        });
                    }}
                    text={"Back"}
                    width={200}
                    loading={loading}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        textDecoration: "underline",
                    }}
                    Icon={BsArrowLeft}
                    iconReverse={true}
                />
            ) : (
                <Feedback
                    handler={handleFeedback}
                    showFeedback={showFeedback}
                    setShowFeedback={setShowFeedback}
                />
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: "80px",
                }}
            >
                <span className={styles.issuesCount}>
                    <BsCheckCircleFill />
                    &nbsp; {totalApprovedCount}
                    &nbsp; suggestions approved
                </span>
                <LightTooltip
                    title={
                        !isVerified
                            ? "Connect your domain to review and push suggestions"
                            : totalApprovedCount == 0
                            ? "Approve atleast one suggestion to review"
                            : ""
                    }
                    arrow
                    placement={"top"}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            handler={() => {
                                if (review) {
                                    lmPublish({
                                        ctaType: "Publish",
                                        ctaUsed: "Push Changes Live",
                                    });
                                    publishChanges();
                                } else {
                                    lmReview({
                                        ctaType: "Review",
                                        ctaUsed: "Review Changes",
                                    });
                                    setReview(true);
                                }
                            }}
                            text={
                                review ? "Push Changes Live" : "Review Changes"
                            }
                            width={200}
                            loading={loading}
                            style={
                                review
                                    ? {
                                          backgroundColor:
                                              "rgba(29, 193, 152, 1)",
                                      }
                                    : {}
                            }
                            disabled={!isVerified || totalApprovedCount == 0}
                            Icon={review ? GoRocket : BsArrowRight}
                        />
                    </div>
                </LightTooltip>
            </div>

            <div
                style={{
                    zIndex: 100,
                    position: "fixed",
                    right: 80,
                    bottom: 100,
                }}
            >
                <Confetti
                    active={startConfetti}
                    config={ConfettiConfig as any}
                />
            </div>
        </div>
    );
}

export default PublishStrip;
