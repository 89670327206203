import React, { useContext, useEffect, useState } from "react";
import styles from "./CitationTableNew.module.css";
import CitationTableRow from "./CitationTableRow";
import NumberDropdown from "./NumberDropdown";
import Pagination from "./Pagination";
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import CitationItem from "./CitationItem";
import TabPanelHeading from "./atoms/TabHeading/TabPanelHeading";
import ExpandAll from "./atoms/ExpandAll/ExpandAll";
import { Link } from "@material-ui/core";
import ReportContext from "../../../organisms/Research/ReportContext";

const START_WITH = 7;
function CitationTableNew({
    list = [1, 2, 3, 5, 6, 7],
    innerRef,
    contentBrief = false,
    isSharedReport,
    access,
}) {
    const [listData, setListData] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(START_WITH);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [minimizeAll, setMinimizeAll] = useState(true);
    const { reportInfo } = useContext(ReportContext);

    const formatData = (list) => {
        const listData = [];
        let set = new Set();
        list.forEach((item) => set.add(item.url_host));

        for (let item of set) {
            const urlList = list
                .filter((listItem) => listItem.url_host === item)
                .sort((a, b) => a.source_rank - b.source_rank);
            listData.push({
                url_host: item,
                countHost: urlList[0].countHost,
                links: urlList,
            });
        }
        console.log(list, "CITATIONS DATA");
        console.log(listData, "CITATIONS DATA");
        setListData(listData);
    };

    useEffect(() => {
        formatData(list);
    }, [list]);

    useEffect(() => {
        setCurrentPage(1);
    }, [recordsPerPage]);

    useEffect(() => {
        setNumberOfPages(Math.ceil(list.length / START_WITH));
    }, [list.length]);

    useEffect(() => {
        setNumberOfPages(Math.ceil(list.length / recordsPerPage));
    }, [recordsPerPage]);

    return (
        <>
            <TabPanelHeading
                heading={<div style={{ display: "flex" }}>Top Cited URLs</div>}
                children={
                    <ExpandAll
                        minimize={minimizeAll}
                        setMinimize={setMinimizeAll}
                    />
                }
            />

            <div id="citations" ref={innerRef} className={styles.mainContainer}>
                <div className={styles.citationItemContainer}>
                    <div className={styles.citationMainheader}>
                        <div>CITATION WEBSITES</div>
                        <div>
                            COUNT{" "}
                            <span className={styles.headerSubscript}>
                                (Total occurrences in top{" "}
                                {reportInfo.competitors_count} competitors)
                            </span>
                        </div>
                    </div>
                    {listData.map((item) => (
                        <CitationItem
                            data={item}
                            expandAll={!minimizeAll}
                            contentBrief={contentBrief}
                            isSharedReport={isSharedReport}
                            access={access}
                        />
                    ))}
                </div>

                {list.length > 0 ? (
                    <></>
                ) : (
                    <div className={styles.nodataFound}>
                        No Common Citations found in Top Ranking URLs
                    </div>
                )}
            </div>
        </>
    );
}

export default React.forwardRef((props, ref) => (
    <CitationTableNew innerRef={ref} {...props} />
));
