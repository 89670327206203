import React, { useEffect } from "react";
import Button from "../../../../componentsV2/atoms/Button/Button";
import SubCards from "../../../subsCards/index";
import styles from "./BillingPlans.module.css";
import { RiVisaFill } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function BillingPlans({ aside, setIsActive }) {
    const { mpTrackCampaignOfferPlanLeave, mixpanelTrack } = useMixpanelHook();
    useEffect(() => {
        setIsActive("BILLING_PLANS");
        mixpanelTrack("Subscription Page", {
            action: "Landing on pricing page",
        });
        return () => {
            mpTrackCampaignOfferPlanLeave();
            mixpanelTrack("Subscription Page", {
                action: "Exiting pricing page",
            });
        };
    }, []);
    const otherInfo = useSelector((state) => state.otherInfo);
    return (
        <div className={styles.layout}>
            {otherInfo?.subscriptionInfo?.hasOwnProperty("plan_category") ? (
                <SubCards aside={aside} />
            ) : (
                <div
                    style={{
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            )}
            <div className={styles.right}>
                <div className={styles.nextPayment}>
                    <h3 className={styles.groupHeading}>Next Payment</h3>
                    <p>
                        DATE:{" "}
                        <span className={styles.blueText}>09/05/2021</span>
                    </p>
                    <p>
                        AMOUNT: <span className={styles.blueText}>$299</span>
                    </p>
                    <Button
                        text="Cancel Subscription"
                        fontSize="10px"
                        width="140px"
                        height="32px"
                        tertiary
                    />
                </div>
                <div className={styles.paymentMethod}>
                    <h3 className={styles.groupHeading}>PAYMENT METHOD</h3>
                    <div className={styles.cardDetails}>
                        <div className={styles.cardInfo}>
                            <RiVisaFill
                                style={{ fontSize: "1.3rem", color: "#597ef7" }}
                            />
                            <div>
                                <p className={styles.cardNumber}>
                                    **** **** **** 4512
                                </p>
                                <p className={styles.expires}>Expires 02/19</p>
                            </div>
                        </div>
                        <div className={styles.editIcon}>
                            <MdEdit
                                style={{
                                    color: "black",
                                    opacity: 0.2,
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
