import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import Modal from "../../atoms/Modal/Modal";
import styles from "./styles.module.css";
import SVGIcon from "../../../componentsV2/atoms/SVGIcon";

const VideoGuide = ({ setShowInfoModal }) => {
    const [videoModal, setVideoModal] = useState("");

    const videos = [
        {
            title: () => {
                return (
                    <>
                        Explore
                        <span
                            className={styles.highlight}
                            // onClick={() =>
                            //   setVideoModal("https://www.youtube.com/embed/U6Ippd8IIe0")
                            // }
                        >
                            {" "}
                            AI Tools
                        </span>
                    </>
                );
            },
            subtext:
                "Discover the full potential of the AI tools, from SERP Ideas to AI Connectors.",
            duration: "1:38",

            src: "https://www.youtube.com/embed/U6Ippd8IIe0?autoplay=1",
            imgSrc: "/thumbs/ai-toolbar-thumb.jpg",
            alt: "AI Toolbar",
        },
        {
            title: () => {
                return (
                    <>
                        Improve Ranking with
                        <span
                            className={styles.highlight}
                            // onClick={() =>
                            //   setVideoModal("https://www.youtube.com/embed/AZIfqjhMS3Y")
                            // }
                        >
                            {" "}
                            SEO Score
                        </span>
                    </>
                );
            },
            subtext:
                "Understand the role of SEO score to develop high-quality content.",
            duration: "2:11",
            src: "https://www.youtube.com/embed/AZIfqjhMS3Y?autoplay=1",
            imgSrc: "/thumbs/content-grade-thumb.jpg",
            alt: "Content Grade",
        },
    ];

    // <iframe
    //   width="1280"
    //   height="720"
    //   src="https://www.youtube.com/embed/U6Ippd8IIe0"
    //   title="Keep exploring with AI toolbar"
    //   frameborder="0"
    //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //   allowfullscreen
    // ></iframe>;
    return (
        <>
            <Modal setModal={setShowInfoModal}>
                <div className={styles.videoListContainer}>
                    <CloseButton
                        style={{ right: 10, top: 10 }}
                        setClose={setShowInfoModal}
                    />
                    <div className={styles.videoListContainer__heading}>
                        Help Videos
                    </div>
                    <div className={styles.videoList}>
                        {videos.map((video, item) => {
                            return (
                                <div className={styles.videoRow} key={item + 1}>
                                    <div
                                        className={styles.videoRow__thumbnail}
                                        onClick={() => setVideoModal(video.src)}
                                    >
                                        <div className={styles.duration}>
                                            {video.duration}
                                        </div>

                                        <img
                                            src={video.imgSrc}
                                            alt={video.alt}
                                        />
                                        <div className={styles.playButton}>
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/BsFillPlayFill.svg"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.videoRow__Info}>
                                        <div
                                            className={styles.videoRow__title}
                                            onClick={() =>
                                                setVideoModal(video.src)
                                            }
                                        >
                                            {video.title()}
                                        </div>
                                        <div className={styles.subtext}>
                                            {video.subtext}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
            {!!videoModal.length && (
                <Modal setModal={setVideoModal}>
                    <div className={styles.videoModalContainer}>
                        <div
                            className={styles.videoModalClose}
                            onClick={() => setVideoModal("")}
                        >
                            <VscClose size={30} />
                        </div>
                        <iframe
                            width="1100"
                            height="619"
                            src={videoModal}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default VideoGuide;
