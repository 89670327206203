import React, {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import useHelperFunctions from "../../../helperFunctions";
import ReportContext from "../../Research/ReportContext";
import { Outline, WP } from "./classes";
import useCruiseBriefReducers from "./useCruiseBriefReducers";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { cruiseMixPanelEvents } from "./cruiseConstant";
import { useSelector } from "react-redux";

export const CRUISE_TYPES = {
    //server actions
    SET_CRUISE_DATA: "set_cruise_data",
    SET_WPS: "set_wps",
    SET_PARA: "set_para",
    //automated outlines
    SET_AUTOMATED_OUTLINES: "automated_outlines",
    //show outlines
    SET_SHOW_OUTLINES: "show_outlines",
    //User actions
    HANDLE_INTRODUCTION: "handle_introduction",
    HANDLE_CONCLUSION: "handle_conclusion",
    EDIT_CONTEXT_TITLE: "edit_context",

    //outlines
    ADD_OUTLINES: "add_outlines",
    ADD_ON_INDEX_OUTLINES: "add_on_index_outlines",
    EDIT_OUTLINES: "edit_outlines",
    DELETE_OUTLINES: "delete_outlines",
    HEADINGS_ORDER: "headings_order",
    QUESTIONS_ORDER: "questions_order",
    DELETE_ALL_OUTLINES: "DELETE_ALL_OUTLINES",
    //writing points
    ADD_WP: "add_wp",
    ADD_ON_INDEX_WP: "add_on_index_wp",
    DELETE_WP: "delete_wp",
    EDIT_WP: "edit_wp",
    WP_ORDER: "wp_order",
    //
    HANDLE_DRAG_OUTLINE: "handle_drag_outline",
    HANDLE_DRAG_WP: "handle_drag_wp",
    //para
    EDIT_PARA: "edit_para",
    //replace uuid with BE id
    UPDATE_ID: "update_id",
    //set laoding
    SET_LOADING: "set_loading",
    SET_CRUISE_RETURN: "set_cruise_return",
    SET_IMAGE_TYPE: "set_image_type",
    SET_FEATURED_IMAGE: "set_featured_image",
    DELETE_FEATURED_IMAGE: "delete_featured_image",
    //invalid action
    SET_INVALID_ACTION: "invalid_action",
    //handle outline inter change
    OUTLINE_INTER_CHANGE: "outline_interchange",
    //sync ids from BE
    SYNC_IDS: "sync_ids",
    //GEN_WP_TYPE
    GEN_WP_TYPE: "GEN_WP_TYPE",
    //SHOW_STATS
    SHOW_STATS: "SHOW_STATS",
    UPDATE_META: "UPDATE_META",
    UPDATE_TONE: "UPDATE_TONE",
    UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
    HAS_SATISFACTION_SCORE: "HAS_SATISFACTION_SCORE",
    ORGANIC_COMPETITORS: "ORGANIC_COMPETITORS",
    AI_CONTENT_DETECTION: "AI_CONTENT_DETECTION",
    //FIRST DRAFT PARA

    SET_SCROLL: "SET_SCROLL",
};

export const initialStepsData = {
    id: "",
    user_id: "",
    report_id: "",
    blog_context: "",
    title: "",
    headings_order: [],
    questions_order: [],
    additional_keywords: "",
    introduction: "",
    conclusion: "",
    step_status: 0,
    completed: 0,
    max_step: 0,
    outlines: {
        headings: [{ data: "sadsa", outlineH3s: [{}, {}, {}] }],
        questions: [],
    },
    isLoader: false,
    isCruiseReturn: false,
    premiumLoader: false,
    isShimmer: false,
    isScroll: true,
    invalidAction: "",
    cta: "",
    automated_outlines: false,
    show_outlines: false,
    gen_wp_type: WP.types.ai,
    show_stats: false,
    meta: null,
    tone_id: null,
    active_template_id: null,
    recommend_template_id: "",
    hasSatisfactionScore: false,
    ranks: [],
    aiContentDetection: 1,
};

export const cruiseCache = new Map();
window.cruiseCache = cruiseCache;

export function setOutlinesInPriorityOrder(
    outlines = [],
    h3_outlines = [],
    hOrder = [],
    qOrder = [],
    wpArr = [],
) {
    /**
     * @type {Array<Outline>}
     *  */
    let headings = [];
    let questions = [];
    let outlineMap = new Map();
    let wpMap = new Map();
    let h3Map = new Map();

    wpArr.forEach((ele) => {
        const element = new WP({ ...ele });
        element.citations = element.citations
            ? JSON.parse(element.citations)
            : [];
        wpMap.set(element.id, element);
    });

    h3_outlines.forEach((ele) => {
        const element = new Outline({
            ...ele,

            tag: Outline.tags.h3,
        });
        element.wp_order.forEach((val) => {
            const v = wpMap.get(val);
            if (v) element.wp.push(v);
            wpMap.set(val, null);
        });
        h3Map.set(element.id, element);
    });

    outlines.forEach((ele) => {
        const h2 = ele;
        const element = new Outline({
            ...ele,
        });

        if (element.tag == Outline.tags.h2)
            element.h3_order = h2.h3_order
                ? typeof h2.h3_order == "string"
                    ? JSON.parse(h2.h3_order)
                    : h2.h3_order
                : [];

        element.wp_order.forEach((val) => {
            const v = wpMap.get(val);
            if (v) element.wp.push(v);
            wpMap.set(val, null);
        });
        outlineMap.set(element.id, element);
    });

    wpMap.forEach((value) => {
        if (value) {
            const v = outlineMap.get(value.outline_id);
            if (v) {
                if (!v.wp) v.wp = [];
                v.wp.push(value);
                outlineMap.set(value.outline_id, v);
            }
        }
    });

    wpMap.forEach((value) => {
        if (value) {
            const v = h3Map.get(value.outline_id);
            if (v) {
                if (!v.wp) v.wp = [];
                v.wp.push(value);
                h3Map.set(value.outline_id, v);
            }
        }
    });

    if (hOrder?.length) {
        hOrder.forEach((val) => {
            const v = outlineMap.get(val);
            if (v) headings.push(v);
            outlineMap.set(val, null);
        });
    }

    if (qOrder?.length) {
        qOrder.forEach((val) => {
            const v = outlineMap.get(val);
            if (v) questions.push(v);
            outlineMap.set(val, null);
        });
    }

    outlineMap.forEach((value) => {
        if (value) {
            if (value.tag == Outline.tags.h2) {
                headings.push(value);
            } else if (value.tag == Outline.type.question) {
                questions.push(value);
            }
        }
    });

    /**
     * @type {Array<Outline>}
     *  */
    const parsedArr = [];

    for (let index = 0; index < headings.length; index++) {
        const heading = headings[index];

        for (let j = 0; j < heading.h3_order.length; j++) {
            const id = heading.h3_order[j];
            const h3 = h3Map.get(id);
            if (h3) {
                heading.outlineH3s.push(h3);
                h3Map.set(id, null);
            }
        }
        h3Map.forEach((value) => {
            if (value) {
                if (heading.id == value.h2_id) {
                    heading.outlineH3s.push(value);
                }
            }
        });

        parsedArr.push(heading);
    }

    console.log(parsedArr);

    return { headings: parsedArr, questions };
}

export const useCruiseHook = ({
    tempGuideLineData,
    reportInfo,
    setRealtimeRatings,
}) => {
    const { postData, getData } = useHelperFunctions();
    const { competetion } = useContext(ReportContext);
    const [topRankedTitles, setTopRankedTitles] = useState([]);
    const [topRankedOutlines, setTopRankedOutlines] = useState([]);
    const [aiGenerated, setAiGenerated] = useState([]);
    const [preGeneratedFor, setPreGeneratedFor] = useState("");
    const [oldTemplateId, setOldTemplateId] = useState(null);
    const [isGenTitleLoaded, setIsGenTitleLoaded] = useState(false);
    const [titleSessionId, setTitleSessionId] = useState(-1);

    const [isShimmer, setIsShimmer] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const { stepsDataReducer } = useCruiseBriefReducers();
    const [maxStep, setMaxStep] = useState();
    const [stepsData, dispatchStepsData] = useReducer(
        stepsDataReducer,
        initialStepsData,
    );
    const auth = useSelector((state) => state.auth);
    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        if (stepsData.invalidAction)
            setTimeout(() => {
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_INVALID_ACTION,
                    data: "",
                });
            }, 3000);
    }, [stepsData.invalidAction]);

    useEffect(() => {
        if (reportInfo?.report_id && !reportInfo?.reportLoading)
            getDraftInfo(reportInfo.report_id);
    }, [reportInfo?.report_id, reportInfo?.reportLoading]);

    useEffect(() => {
        if (competetion?.length) {
            setTopRankedOutlines(competetion);
            setTopRankedTitles(
                competetion.map((item) => ({
                    title: item.url_title,
                    rank: item.rank,
                })),
            );
        }
    }, [competetion]);

    async function getDraftInfo(report_id) {
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: true,
        });

        const res = await getData({
            url: `/api/contentDraft/v2/draft?report_id=${report_id}`,
        });
        if (res.status == 200) {
            dispatchStepsData({
                type: CRUISE_TYPES.SET_CRUISE_RETURN,
                state: true,
            });
            if (res?.data?.draftInfo?.meta_data)
                dispatchStepsData({
                    type: CRUISE_TYPES.UPDATE_META,
                    meta: {
                        ...JSON.parse(res.data.draftInfo.meta_data),
                        fromAPI: true,
                    },
                });
            setPreGeneratedFor(res.data?.draftInfo?.blog_context);
            setOldTemplateId(res.data?.draftInfo?.active_template_id);

            dispatchStepsData({
                type: CRUISE_TYPES.SET_CRUISE_DATA,
                data: {
                    ...res.data?.draftInfo,
                    aiContentDetection:
                        res.data?.draftInfo?.is_content_detection,
                    outlines: setOutlinesInPriorityOrder(
                        res?.data?.draftInfo?.outlines,
                        res?.data?.draftInfo?.h3_outlines,
                        res?.data?.draftInfo?.headings_order,
                        res?.data?.draftInfo?.questions_order,
                        res?.data?.draftInfo?.writing_points,
                    ),
                    report_id: reportInfo.report_id,
                },
            });
        }
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: false,
        });
        if (
            (auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6 ||
                reportInfo.is_premium) &&
            parseInt(res.data?.draftInfo.step_status) == 3
        ) {
            setActiveStep(2);
        } else {
            setActiveStep(
                res.data?.draftInfo?.step_status
                    ? parseInt(res.data?.draftInfo.step_status)
                    : 0,
            );
        }
        setMaxStep(
            res.data?.draftInfo?.max_step
                ? parseInt(res.data?.draftInfo.max_step)
                : 0,
        );
    }

    useEffect(() => {
        if (activeStep == 1) {
            if (preGeneratedFor != stepsData.blog_context) {
                AiGenTitlesFacts(stepsData.blog_context, activeStep);
                setPreGeneratedFor(stepsData.blog_context);
            } else if (oldTemplateId != stepsData?.active_template_id) {
                AiGenTitlesFacts(stepsData.blog_context, activeStep);
                setOldTemplateId(stepsData?.active_template_id);
            } else if (aiGenerated.length == 0) {
                AiGetTitles();
            }
        }
        if (activeStep == 2) {
            if (!stepsData.outlines.headings.length) {
                generateOutlines();
            } else {
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_SHOW_OUTLINES,
                    state: true,
                });
            }
        }
        if (!stepsData?.isScroll) {
            dispatchStepsData({
                type: CRUISE_TYPES.SET_SCROLL,
                state: true,
            });
        }
    }, [activeStep]);

    async function AiGetTitles(value = "") {
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: true,
        });
        setIsGenTitleLoaded(true);
        setAiGenerated([]);
        const res = await getData({
            url: `/api/generates/getLastSessionRecords?report_id=${reportInfo.report_id}`,
        });

        if (res.status == 200) {
            // setAiGenerated(res.data.output ? res.data.output : []);

            res.data = {
                output: res.data.map((item) => ({
                    content: item.text,
                    session: item.session ? item.session : "",
                    isSensi: false,
                    is_excluded: 0,
                    uniqueId: +`${Math.random() * Math.random()}${
                        Math.random() * Math.random()
                    }`,
                    wordCount: item.text.length,
                })),
            };
            setAiGenerated(res.data.output ? res.data.output : []);
            if (res?.data?.output?.length) {
                for (let i = 0; i < res?.data?.output?.length; i++) {
                    if (res.data?.output[i].session) {
                        setTitleSessionId(res.data?.output[i].session);
                        break;
                    }
                }
            }
            if (!res?.data?.output?.length) {
                await AiGenTitlesFacts(stepsData.blog_context, 1);
            }
        } else {
            await AiGenTitlesFacts(stepsData.blog_context, 1);
        }
        setIsGenTitleLoaded(false);
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: false,
        });
    }

    async function AiGenTitlesFacts(value = "", onStep = -1) {
        if (onStep != 1) {
            return;
        }
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: true,
        });
        setIsGenTitleLoaded(true);
        let payload = {
            blog_context: value,
            report_id: reportInfo.report_id,
            keyword: reportInfo.title,
        };

        if (stepsData?.active_template_id) {
            payload.active_template_id = stepsData.active_template_id;
        }

        const res = await postData({
            url: "/api/contentDraft/generate-titles",
            payload,
        });
        if (res.status == 200) {
            // setAiGenerated(res.data.output ? res.data.output : []);

            setAiGenerated(res.data.output ? res.data.output : []);
            setTitleSessionId(res?.data?.session ? res?.data?.session : -1);
        } else {
            setAiGenerated([]);
        }
        setIsGenTitleLoaded(false);
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: false,
        });
    }

    async function generateOutlines() {
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: true,
            text: (
                <>
                    We're extracting insights from top competitors to craft an
                    SEO-optimized outline for you.
                    <br />
                    This may take up to 30 seconds. We appreciate your patience.
                </>
            ),
        });

        dispatchStepsData({
            type: CRUISE_TYPES.DELETE_ALL_OUTLINES,
        });
        setRealtimeRatings((ps) => {
            return {
                ...ps,
                seoScore: 0,
            };
        });
        if (stepsData.featured_image?.url) {
            dispatchStepsData({
                type: CRUISE_TYPES.DELETE_FEATURED_IMAGE,
            });
        }
        let organicComp = [];
        if (stepsData.ranks?.length > 0) {
            organicComp = stepsData.ranks;
        } else {
            const filteredComp = competetion.filter(
                (ele) => ele.word_count >= 200 && ele.word_count <= 3500,
            );
            if (filteredComp.length) {
                const finalComp = filteredComp.filter(
                    (ele) => ele.htags_count >= 3 && ele.htags_count <= 100,
                );
                if (finalComp.length) {
                    organicComp = finalComp
                        .sort((a, b) => b.grade - a.grade)
                        .slice(
                            0,
                            auth.segment == 3 ||
                                auth.segment == 4 ||
                                auth.segment == 5 ||
                                auth.segment == 6
                                ? 2
                                : 3,
                        )
                        .map((i) => i.rank);
                } else {
                    organicComp = filteredComp
                        .sort((a, b) => b.grade - a.grade)
                        .slice(
                            0,
                            auth.segment == 3 ||
                                auth.segment == 4 ||
                                auth.segment == 5 ||
                                auth.segment == 6
                                ? 2
                                : 3,
                        )
                        .map((i) => i.rank);
                }
            } else {
                organicComp = competetion
                    .sort((a, b) => b.grade - a.grade)
                    .slice(
                        0,
                        auth.segment == 3 ||
                            auth.segment == 4 ||
                            auth.segment == 5 ||
                            auth.segment == 6
                            ? 2
                            : 3,
                    )
                    .map((i) => i.rank);
            }
            dispatchStepsData({
                type: CRUISE_TYPES.ORGANIC_COMPETITORS,
                ranks: organicComp,
            });
        }

        setTimeout(async () => {
            const res = await postData({
                url: "/api/contentDraft/v2/generate-outlines",
                payload: {
                    report_id: reportInfo.report_id,
                    ranks: organicComp,
                },
            });

            if (res.status == 200) {
                const generatedOutline = setOutlinesInPriorityOrder(
                    res?.data?.outlines || [],
                    res?.data?.h3_outlines || [],
                    res?.data?.headings_order || [],
                    res?.data?.questions_order || [],
                    [],
                );
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_CRUISE_DATA,
                    data: {
                        outlines: {
                            headings: generatedOutline.headings,
                            questions: generatedOutline.questions,
                        },
                    },
                });
                if (!res?.data?.headings_order?.length) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.SET_SHOW_OUTLINES,
                        state: true,
                    });
                } else {
                    try {
                        const [min, max] =
                            tempGuideLineData?.heading_count?.split("-");
                        mixpanelTrack(cruiseMixPanelEvents.generatedOutlines, {
                            generated:
                                res?.data?.outlines?.length +
                                res?.data?.h3_outlines?.length,
                            suggestedRange: tempGuideLineData?.heading_count,
                            suggested: (+min + +max) / 2,
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_AUTOMATED_OUTLINES,
                    state: true,
                });
                setTimeout(() => {
                    dispatchStepsData({
                        type: CRUISE_TYPES.SET_AUTOMATED_OUTLINES,
                        state: false,
                    });
                }, 4000);
            }
            dispatchStepsData({
                type: CRUISE_TYPES.SET_LOADING,
                state: false,
            });
        }, 1000);
    }

    const checkEmptyWritingPoint = (
        headings = [],
        questions = [],
        errorSource = "",
        statusCode = 0,
    ) => {
        if (!headings.length && !questions.length) {
            return;
        }
        const emptyHeadings = headings.filter((h) => {
            if (!h.hasOwnProperty("tp") || h?.tp?.length === 0) {
                return h;
            }
        });
        const emptyQuestions = questions.filter((q) => {
            if (!q.hasOwnProperty("tp") || q?.tp?.length == 0) {
                return q;
            }
        });

        if (emptyHeadings?.length > 0 || emptyQuestions?.length > 0) {
            sendAlert(emptyHeadings, emptyQuestions, {
                errorSource,
                statusCode,
            });
        }
    };

    const emptyParaAlert = async (data = []) => {
        if (data?.length) {
            let payload = {
                type: "noShowUrl",
                message: { report_id: reportInfo.report_id, emptyPara: data },
            };
            const res = await postData({ url: "/api/alert", payload });
        }
    };

    async function sendAlert(
        emptyTPHeadings = [],
        emptyTPQuestions = [],
        { errorSource = "", statusCode = 0 },
    ) {
        let alertObj = {
            report_id: reportInfo.report_id,
            emptyTPHeadings,
            emptyTPQuestions,
            statusCode,
            errorSource: "",
        };

        if (errorSource?.length) {
            alertObj.errorSource = errorSource;
        }

        let payload = {
            type: "noShowUrl",
            message: alertObj,
        };
        const res = await postData({ url: "/api/alert", payload });
    }

    return {
        stepsData,
        dispatchStepsData,
        checkEmptyWritingPoint,
        emptyParaAlert,
        topRankedTitles,
        topRankedOutlines,
        aiGenerated,
        setAiGenerated,
        isGenTitleLoaded,
        setIsGenTitleLoaded,
        activeStep,
        setActiveStep,
        titleSessionId,
        setTitleSessionId,

        setIsShimmer,
        isShimmer,
        maxStep,
        generateOutlines,
    };
};
