import React, { useContext, useEffect, useRef, useState } from "react";
import ReportEditor from "./ReportEditor";
import useEditorMethods from "./EditorMethods";
import EditorContext from "./EditorContext";
import ReportContext from "../Research/ReportContext";
import { SEO_EDITOR } from "../../Pages/NewDashboard/constants";

const menuList = [<span>File</span>, <span>Edit</span>, <span>Help</span>];
export default function SEOEditor({
    reportInfo,
    keyTerms,
    overview,
    guestInfo,
    isShared,
    wordCountRange,
    isFixItEnabled,
    setIsFixItEnabled,
    enableFixItEditor,
    setEnableFixItEditor,
    metaFixIt,
    setMetaFixIt,
    enableFixIt,
    setEnableFixIt,
    isLoadingFixIt,
    setIsLoadingFixIt,
}) {
    const [connectorCases, setConnectorCases] = useState([]);
    const [versionData, setVersionData] = useState({ current: true, html: "" });
    const { setReportData, rating, setCruiseNudge, auditMode } =
        useContext(ReportContext);

    const {
        getEditorData,
        saveEditorData,
        metaData,
        setMetaData,
        writeForMe,
        saving,
        writingState,
        aiRepeatModal,
        noContentAiModal,
        setAiRepeatModal,
        setNoContentAiModal,
        canReWrite,
        lastAIcase,
        realtimeRating,
        setCanReWrite,
        setAiWordLimitSetting,
        aiWordLimitSetting,
        saved,
        writeWithAnimation,
        setWritingState,
        setRealtimeRatings,
        getSeoScore,
        optimiserData,
        seoScore,
        optimiserLoading,
        isOptimiserEnable,
    } = useEditorMethods({
        reportInfo,
        setConnectorCases,
        keyTerms,
        setVersionData,
        versionData,
        guestId: isShared?.guest_id,
    });

    useEffect(() => {
        setReportData((ps) => ({
            ...ps,
            aiWordLimitSetting,
            setAiWordLimitSetting,
        }));
    }, [aiWordLimitSetting]);

    useEffect(() => {
        setReportData((ps) => ({
            ...ps,
            getSeoScore,
            setMetaData,
            metaData,
            seoScore,
            isOptimiserEnable,
        }));
    }, [realtimeRating, metaData, seoScore]);

    useEffect(() => {
        //to get initial word count
        if (
            setReportData &&
            realtimeRating.fromApi == 1 &&
            rating == undefined
        ) {
            setReportData((pd) => ({ ...pd, rating: realtimeRating }));
        }
    }, [realtimeRating]);

    useEffect(() => {
        //to get initial word count
        if (setCruiseNudge) {
            setCruiseNudge(() => false);
        }
    }, [saving]);

    useEffect(() => {
        if (overview?.overviewRange?.firstRange) {
            const avgQuality = overview.overviewRange.firstRange.Max_grade;
            const top_score = overview.overviewRange.thirdRange?.Max_score;
            const avg_score = overview.overviewRange.thirdRange?.SEO_Score;
            const suggested_keyword =
                overview.overviewRange.firstRange?.Max_keyword_count;

            const avgSEOScore =
                overview.overviewRange.firstRange?.Max_score > 95
                    ? overview.overviewRange.firstRange?.Max_score
                    : Math.ceil(
                          overview.overviewRange.firstRange?.Max_score / 5,
                      ) * 5;
            const suggestedReadability =
                overview.overviewRange.firstRange.Readability;
            setRealtimeRatings((ps) => {
                return {
                    ...ps,
                    wordRange: wordCountRange,
                    suggestedQuality: avgQuality,
                    suggestedSEOScore: avgSEOScore,
                    topScore: top_score,
                    avgScore: avg_score,
                    suggestedReadability: suggestedReadability,
                    fromApi: 0,
                    suggestedKeywordCount: suggested_keyword,
                };
            });
        }
    }, []);

    useEffect(() => {
        setAiWordLimitSetting((prev) => {
            return {
                ...prev,
                context: reportInfo.context,
                keywords: reportInfo.keywords,
                nlpToggle: reportInfo.nlp_toggle,
            };
        });
        if (document?.quill?.editor) document.quill.editor.isCurrVersion = true;
        // return () => {
        //     getSeoScore();
        // };
    }, []);

    function rewrite() {
        if (!canReWrite) return false;

        document.quill.editor.cursor = document.quill.editor.lastCursor;
        document.quill.editor.deleteText(
            document.quill.editor.lastCursor,
            document.quill.editor.lastLength,
            "api",
        );
        writeForMe(lastAIcase.id, lastAIcase.useCase, lastAIcase.isTool);
    }

    return (
        <EditorContext.Provider
            value={{
                writeForMe,
                aiWordLimitSetting,
                writingState,
                setWritingState,
                writeWithAnimation,
                saving,
                isShared,
                guestInfo,
                versionData,
                setVersionData,
                auditMode,
                docInfo: {
                    reportId: reportInfo.report_id,
                    title: reportInfo.title,
                },
                metaData,
                attachedTo: SEO_EDITOR,
            }}
        >
            <ReportEditor
                saved={saved}
                saveEditorData={saveEditorData}
                getEditorData={getEditorData}
                metaData={metaData}
                setMetaData={setMetaData}
                writingState={writingState}
                saving={saving}
                connectorCases={connectorCases}
                writeForMe={writeForMe}
                rewrite={rewrite}
                canReWrite={canReWrite}
                aiWordLimitSetting={aiWordLimitSetting}
                setAiWordLimitSetting={setAiWordLimitSetting}
                reportInfo={reportInfo}
                keyTerms={keyTerms}
                aiRepeatModal={aiRepeatModal}
                noContentAiModal={noContentAiModal}
                realtimeRating={realtimeRating}
                setAiRepeatModal={setAiRepeatModal}
                setCanReWrite={setCanReWrite}
                setNoContentAiModal={setNoContentAiModal}
                setRealtimeRatings={setRealtimeRatings}
                optimiserData={optimiserData}
                seoScore={seoScore}
                optimiserLoading={optimiserLoading}
                isOptimiserEnable={isOptimiserEnable}
                getSeoScore={getSeoScore}
                attachedTo={SEO_EDITOR}
                isFixItEnabled={isFixItEnabled}
                setIsFixItEnabled={setIsFixItEnabled}
                enableFixItEditor={enableFixItEditor}
                setEnableFixItEditor={setEnableFixItEditor}
                isLoadingFixIt={isLoadingFixIt}
                setIsLoadingFixIt={setIsLoadingFixIt}
                metaFixIt={metaFixIt}
                setMetaFixIt={setMetaFixIt}
                enableFixIt={enableFixIt}
                setEnableFixIt={setEnableFixIt}
            />
        </EditorContext.Provider>
    );
}
