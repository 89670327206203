import { TextareaAutosize } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { STEPS_META_INFO } from "./cruiseConstant";
import styles from "./stepCard.module.css";
import { CruiseContext, NextStepBtnTexts } from "./StepsWizard";
import { CRUISE_TYPES, initialStepsData } from "./useCruiseHook";

/**
 * @param {{stepsData:initialStepsData}} stepsCard
 *
 */

function TitleStep({ stepsData, handleDataOnBlur, aiGenerated }) {
    const [value, setValue] = useState("");
    const [focus, setFocus] = useState(false);
    const titleOnMount = useMemo(() => stepsData.title, []);
    const dispatchStepsData = useContext(CruiseContext);
    useEffect(() => {
        setValue(stepsData.title);

        if (stepsData.title != titleOnMount && stepsData.conclusion) {
            dispatchStepsData({
                type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                data: "",
                edit: true,
            });
            dispatchStepsData({
                type: CRUISE_TYPES.HANDLE_CONCLUSION,
                data: "",
                edit: true,
            });
        }
    }, [stepsData.title]);

    useEffect(() => {
        if (aiGenerated?.length > 0 && !stepsData.title) {
            setValue(aiGenerated[0].content || "");
        }
    }, [aiGenerated]);

    return (
        <>
            <div className={styles.contextContaienr}>
                <TextareaAutosize
                    rows={6}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    rowsMax={10}
                    autoFocus={true}
                    className={styles.contextInput}
                    placeholder={stepsData[STEPS_META_INFO[1].placeholder]}
                    value={value}
                    onBlur={(e) => {
                        setFocus(false);
                        handleDataOnBlur(
                            e.target.value,
                            STEPS_META_INFO[1].field,
                        );
                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                />

                <p className={styles.wordCount}>
                    {stepsData.title?.length}/ 500
                </p>
                {value.length ? (
                    <p className={styles.nextNudgeInfo}>
                        To proceed, click on{" "}
                        <span
                            style={{
                                fontWeight: "500",
                            }}
                        >
                            {NextStepBtnTexts[1]}
                        </span>{" "}
                        from the bottom bar
                    </p>
                ) : (
                    ""
                )}
            </div>
            {!stepsData.title.trim() && !focus && !stepsData.isLoader && (
                <div className={styles.errMsg + " " + styles.titleErr}>
                    Please choose the title to proceed
                </div>
            )}
        </>
    );
}

export default TitleStep;
