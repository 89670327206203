import React, { useEffect, useState } from "react";
import styles from "./NewDashboard.module.css";
import Modal from "../../atoms/Modal/Modal";
import { VscClose } from "react-icons/vsc";
import useHelperFunctions from "../../helperFunctions";
import { useDispatch } from "react-redux";
import { setOverlayVideo, updateUserVideo } from "../../../store/actions/video";
import { useMemo } from "react";
import Button from "../../atoms/Button/Button";
import { FaYoutube } from "react-icons/fa";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { getTrailText } from "./constants";
import useUserAccessHook from "../../../components/account/useUserAccessHook";

const VideoModal = ({
    link,
    zoomVal = 1,
    modal = true,
    aspectWidth = 1280,
    aspectHeight = 720,
    initiateClose = false,
    sourceWhere = "",
    mptrackDashboardHandler = () => {},
    buttonForm = false,
    customClass = "",
    location = "",
    buttonProps = {},
    isDash = false,
    featureId = 0,
}) => {
    const [videoModal, setVideoModal] = useState(false);
    const [YTplayer, SetYTPlayer] = useState();
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const { mixpanelTrack } = useMixpanelHook();
    const { planExpiresDate, planTrial } = useUserAccessHook();

    const videoId = useMemo(() => {
        if (link) {
            const arr = link.split("/");
            return arr[arr.length - 1];
        }
    }, [link]);

    useEffect(() => {}, [initiateClose]);

    async function handleVideoClose(permanent) {
        dispatch(updateUserVideo(sourceWhere));
        if (permanent) {
            const res = await postData({
                url: "/api/user/updateVideoPreferences",
                payload: { type: sourceWhere },
            });
        }
    }

    useEffect(() => {
        return () =>
            YTplayer?.getPlayerState() == 1 &&
            !modal &&
            dispatch(
                setOverlayVideo({
                    videoId,
                    duration: YTplayer?.getCurrentTime(),
                    state: 1,
                }),
            );
    }, [YTplayer, videoId, videoModal]);

    useEffect(() => {
        if (modal && YTplayer?.getPlayerState() == 1 && !videoModal) {
            dispatch(
                setOverlayVideo({
                    videoId,
                    duration: YTplayer?.getCurrentTime(),
                    state: 1,
                }),
            );
        }
    }, [YTplayer, videoModal]);

    useEffect(() => {
        const onPlayerReady = (e) => {
            SetYTPlayer(e.target);
        };

        const onPlayerStateChange = (e) => {
            if (e.target.getPlayerState()) {
                if (e.target.getPlayerState() == 1 && location?.length > 0) {
                    mixpanelTrack("Video played", {
                        location: location,
                    });
                }
                console.log(e.target.getPlayerState(), "Rahul Mehta");
                dispatch(
                    setOverlayVideo({
                        videoId,
                        duration: YTplayer?.getCurrentTime(),
                        state: 0,
                    }),
                );
            }
        };

        function onYouTubeIframeAPIReady() {
            try {
                const player = new YT.Player(
                    videoModal ? videoId + "modal" : videoId,
                    {
                        videoId: videoId,

                        playerVars: {
                            playsinline: 1,
                        },
                        events: {
                            onReady: onPlayerReady,
                            onStateChange: onPlayerStateChange,
                        },
                        ...(videoModal
                            ? {
                                  height: "619",
                                  width: "1100",
                              }
                            : aspectHeight
                            ? { height: aspectHeight, width: aspectWidth }
                            : {}),
                    },
                );
            } catch (error) {
                console.log(error);
            }
        }

        if (window.YT && videoId)
            setImmediate(() =>
                window.YT.ready(() => onYouTubeIframeAPIReady()),
            );
    }, [window.YT, videoId, videoModal]);

    return (
        <>
            {buttonForm ? (
                <Button
                    style={{
                        background: "#FFEEEE",
                        border: "none",
                        borderRadius: "5.30612px",
                        width: 120,
                        height: 34,
                        fontSize: 11,
                        color: "red",
                    }}
                    handler={() => {
                        if (isDash) {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `How it works - ${
                                        featureId == 1
                                            ? "Article Writer"
                                            : featureId == 2
                                            ? "Optimizer"
                                            : featureId == 3
                                            ? "Keyword Planner"
                                            : "Traffic Analyzer Bar"
                                    }`,
                                });
                            }
                        }
                        setVideoModal(true);
                    }}
                    Icon={() => (
                        <FaYoutube
                            color="#ff0000d1"
                            fontWeight={400}
                            size={18}
                        />
                    )}
                    iconReverse
                    text={"How it works"}
                    {...buttonProps}
                />
            ) : (
                <div
                    className={
                        customClass ? customClass : styles.videoContainer
                    }
                    style={{
                        aspectRatio: aspectWidth + "/" + aspectHeight,
                    }}
                >
                    <div
                        className={modal && styles.overLapThumbnail}
                        onClick={() => {
                            mptrackDashboardHandler();
                            setVideoModal(true);
                        }}
                    ></div>

                    <div
                        style={{ height: "100%", width: "100%" }}
                        id={videoId}
                    ></div>

                    {initiateClose && (
                        <div className={styles.closeVideoOption}>
                            <div className={styles.dropdown}>
                                <div
                                    onClick={() => handleVideoClose()}
                                    className={styles.option}
                                >
                                    Remove for now
                                </div>
                                <hr className={styles.separator} />
                                <div
                                    onClick={() => handleVideoClose(true)}
                                    className={styles.option}
                                >
                                    Remove permanently
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {videoModal && (
                <Modal setModal={setVideoModal}>
                    <div className={styles.videoModalContainer}>
                        <div
                            className={styles.videoModalClose}
                            onClick={() => setVideoModal(false)}
                        >
                            <VscClose size={30} />
                        </div>
                        <div id={videoId + "modal"}></div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default VideoModal;
