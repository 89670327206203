import {
    SET_OVERLAY_VIDEO,
    SET_USER_VIDEO,
    UPDATE_USER_VIDEO,
} from "../actiontypes";

export const updateUserVideo = (data) => {
    return {
        type: UPDATE_USER_VIDEO,
        data,
    };
};

export const setUserVideo = (data) => {
    return {
        type: SET_USER_VIDEO,
        data,
    };
};

export const setOverlayVideo = (data) => {
    return {
        type: SET_OVERLAY_VIDEO,
        data,
    };
};
