import React, { useEffect, useState } from "react";
import styles from "../UserDocs/styles.module.css";
import DocCard from "../../organisms/DocCard/DocCard";
import TagsFilter from "../../organisms/Account/TagsFilter";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import NewUIDropdown, {
    TYPES,
} from "../../molecules/NewUIDropdown/NewUIDropDown";
import SVGIcon from "../../atoms/SVGIcon";
import { BinTabs } from "../UserReports/UserReports";
import NewUIDropDown from "../../molecules/NewUIDropdown/NewUIDropDown";
import Button from "../../atoms/Button/Button";
import { useSelector } from "react-redux";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import UpgradeModal from "./UpgradeModal";

const sortByOptionsHumanizer = [
    { title: "Created At", id: 1 },
    { title: "Updated At", id: 2 },
    { title: "Original AI %", id: 3 },
    { title: "Humanized AI %", id: 4 },
];

function ScanDocs({
    bin,
    newDoc,
    loading,
    createDoc,
    hasMore,
    requestData,
    openDoc,
    docs,
    setLoading,
    setRequestData,
    restoreDoc,
    deleteDoc,
    creatingDoc,
    setTabWiseStickyHeader,
}) {
    const [sortBy, setSortBy] = useState(sortByOptionsHumanizer[0]);

    const [order, setOrder] = useState(0);
    const { mixpanelTrack } = useMixpanelHook();
    const [upgradeModal, setUpgradeModal] = useState(false);
    const { isFreeForever } = useUserAccessHook();

    const userVideo = useSelector((state) => state.video.docs);
    useEffect(() => {}, [userVideo]);
    useEffect(() => {
        setLoading(true);

        setRequestData((ps) => ({
            ...ps,
            page: 1,
            created: sortBy.id == 1 ? true : false,
            updated: sortBy.id == 2 ? true : false,
            is_deleted: bin ? true : false,
            order,
            ai_score: sortBy.id == 3 ? true : false,
            humanizer_ai_score: sortBy.id == 4 ? true : false,
        }));
    }, [sortBy, bin, order]);

    useEffect(() => {
        setTabWiseStickyHeader(
            <div className={styles.rrFilters}>
                <Button
                    fontWeight={400}
                    text={"+ New Scan"}
                    width={120}
                    fontSize={14}
                    handler={() => {
                        if (isFreeForever) {
                            setUpgradeModal(true);
                            return;
                        }
                        createDoc({ name: "", openDoc: true });
                        mixpanelTrack("AI Humanizer", {
                            action: "New Scan",
                        });
                    }}
                    loadingText={creatingDoc}
                    height={40}
                ></Button>

                <div style={{ padding: 0 }} className={styles.rrFilters}>
                    <div style={{ width: "280px" }}>
                        <Textfield
                            height={40}
                            containerStyle={{ marginBottom: "0" }}
                            props={{
                                onChange: (e) => {
                                    const value = e.target.value.trim();
                                    setRequestData((ps) => ({
                                        ...ps,
                                        search: value,
                                        page: 1,
                                    }));
                                },
                                style: {
                                    borderRadius: 3,
                                    border: "solid 1px rgba(75, 75, 85, 0.16)",
                                    backgroundColor: "#fff",
                                    paddingLeft: "40px",
                                    borderRadius: 3,
                                },
                            }}
                            placeholder={"Search Documents"}
                            label=""
                            Icon={() => (
                                <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />
                            )}
                            iconStyle={{
                                left: "10px",
                                right: "unset",
                                top: "20px",
                            }}
                        />
                    </div>

                    {bin ? (
                        <BinTabs page="SMART_DOCS" />
                    ) : (
                        <>
                            <NewUIDropDown
                                setOrder={setOrder}
                                order={order}
                                options={sortByOptionsHumanizer}
                                style={{
                                    textTransform: "capitalize",
                                    height: 40,
                                    color: "#4b4b55",
                                    width: 170,
                                }}
                                type={TYPES.SORT}
                                selectedOption={sortBy}
                                handler={setSortBy}
                            />
                        </>
                    )}
                </div>
            </div>,
        );
    }, [sortBy, order, sortByOptionsHumanizer]);

    return (
        <>
            <div>
                {loading ? (
                    <>
                        <ReportsSkeleton type={true} />
                        <ReportsSkeleton type={true} />
                        <ReportsSkeleton type={true} />
                        <ReportsSkeleton type={true} />
                    </>
                ) : (
                    <InfiniteScroll
                        dataLength={docs.length}
                        next={() => {
                            setRequestData((ps) => ({
                                ...ps,
                                page: requestData.page + 1,
                            }));
                        }}
                        scrollableTarget="scrollableDiv"
                        style={{ paddingBottom: 50 }}
                        hasMore={hasMore}
                        loader={
                            <>
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                            </>
                        }
                    >
                        {docs.length > 0 ? (
                            <table>
                                <tr className={styles.reportHeader}>
                                    <td>
                                        <span>Name</span>
                                    </td>

                                    <td
                                        style={{
                                            textAlign: "center",
                                            width: 150,
                                        }}
                                    >
                                        <span>Original Content AI %</span>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <span>Humanized Content AI %</span>
                                    </td>

                                    <td style={{ width: 250 }}></td>
                                </tr>
                                {docs.map((doc) => {
                                    return (
                                        <DocCard
                                            openDoc={() => openDoc(doc.scan_id)}
                                            className={styles.doc}
                                            doc={doc}
                                            archived={bin}
                                            restoreDoc={restoreDoc}
                                            deleteDoc={deleteDoc}
                                            key={doc.document_id}
                                            aiHumanizer={true}
                                        />
                                    );
                                })}
                            </table>
                        ) : (
                            <div className={styles.noItems}>
                                <SVGIcon
                                    size={300}
                                    src={"/New UI/SVG/Emptydocument.svg"}
                                />
                                <h3 style={{ marginTop: -50 }}>
                                    No Documents Found
                                </h3>
                            </div>
                        )}
                    </InfiniteScroll>
                )}
            </div>
            {upgradeModal && <UpgradeModal setUpgradeModal={setUpgradeModal} />}
        </>
    );
}

export default ScanDocs;
