import React, { useEffect } from "react";
import { useState } from "react";
import { useTable, useSortBy } from "react-table";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import TextField from "../../componentsV2/atoms/CustomTextfield/Textfield";
import Button from "../../componentsV2/atoms/Button/Button";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./superAdmin.module.css";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import DateTimePicker from "react-datetime";
import Label from "../../componentsV2/atoms/Label/Label";
import { download } from "./csvCoverter";
import { BsCloudDownload } from "react-icons/bs";

let fileName = "worklog";
const statuses = [
    "NEW",
    "ACTIVE",
    "SUBMITTED",
    "REVISION REQUESTED",
    "ACCEPTED",
    "REJECTED",
    "DEADLINE PASSED",
    "ASSIGNED",
];

function WorkLog({ setActiveAction }) {
    const { getData } = useHelperFunctions();
    const [data1, setData] = useState([]);

    const [id, setId] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [text, setText] = useState(null);
    const [email, setEmail] = useState(null);
    const [userId, setUserId] = useState({});
    const [loading, setLoading] = useState(false);
    const [option, setOptions] = useState("project");
    const [range, setRange] = useState({
        from: new Date(+new Date() - 1000 * 60 * 60 * 24 * 7),
        to: new Date(),
    });

    fileName =
        option == "project"
            ? "project_" + id
            : "user_" +
              email +
              "_" +
              range.from.toDateString() +
              "_to_" +
              range.to.toDateString();

    useEffect(() => {
        console.log(userId);
        if (id || userId?.id)
            (async () => {
                setLoading(true);
                const res = await getData({
                    url:
                        "/api/freeLancer/fetchWorklog?" +
                        (option == "project"
                            ? `projectId=${id}`
                            : `userId=${
                                  userId.id
                              }&fromDate=${range.from.toISOString()}&toDate=${range.to.toISOString()}`),
                });
                setLoading(false);

                if (res.status == 200) {
                    for (const i in res.data.data) {
                        res.data.data[i].status =
                            statuses[res.data.data[i].status - 1];
                    }

                    setData(res.data.data);
                } else {
                    setData([]);
                }
            })();
    }, [refresh]);

    useEffect(() => {
        setEmail(null);
        setData([]);
        setUserId({});
        setEmail(null);
        setRange({
            from: new Date(+new Date() - 1000 * 60 * 60 * 24 * 7),
            to: new Date(),
        });
        setId(null);
    }, [option]);

    const data = React.useMemo(() => data1);

    const columns = React.useMemo(
        () => [
            {
                Header: "Email",
                accessor: "email", // accessor is the "key" in the data
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Request ID",
                accessor: "request_id",
            },
            {
                Header: "Stage",
                accessor: "stage",
            },
            {
                Header: "Status",
                accessor: "status",
            },

            {
                Header: "No. of Words",
                accessor: "words_count",
            },
            {
                Header: "Role",
                accessor: "role",
            },
            {
                Header: "FreeLancer Type",
                accessor: "freelancer_type",
            },
        ],
        [],
    );
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy);

    return (
        <div className={styles.worklogContainer}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <div className={styles.row}>
                <div className={styles.row1}>
                    <input
                        checked={option == "project"}
                        onClick={(e) => {
                            if (e.target.checked) {
                                setOptions("project");
                            }
                        }}
                        type="radio"
                        name="option"
                        id="r1"
                    />
                    <Label label="By Request ID"></Label>
                </div>

                <div className={styles.row1}>
                    <input
                        onClick={(e) => {
                            if (e.target.checked) {
                                setOptions("user");
                            }
                        }}
                        type="radio"
                        name="option"
                        id="r2"
                    />
                    <Label label="By User ID"></Label>
                </div>
            </div>

            {option == "project" ? (
                <div className={styles.row}>
                    <TextField
                        type="number"
                        props={{
                            onChange: (e) => {
                                setText(e.target.value);
                            },
                        }}
                        label="Request ID"
                    />{" "}
                    <Button
                        loading={loading}
                        disabled={loading}
                        handler={() => {
                            setId(text);
                            setRefresh((s) => s + 1);
                        }}
                        style={{
                            marginBottom: 10,
                        }}
                        text=""
                        width={50}
                        Icon={AiOutlineSearch}
                    />
                </div>
            ) : (
                <div className={styles.row2}>
                    <AutoComplete
                        filter={["admin", "free_lancer"]}
                        email={email}
                        setEmail={setEmail}
                        setUserId={setUserId}
                    />
                    <div>
                        <Label label="From"></Label>
                        <DateTimePicker
                            strictParsing
                            closeOnClickOutside
                            closeOnSelect
                            onChange={(date) => {
                                if (date._d) {
                                    setRange((p) => {
                                        return {
                                            from: new Date(date._d),
                                            to: p.to,
                                        };
                                    });
                                }
                            }}
                            value={range.from}
                            isValidDate={(c, s) => {
                                return true;
                            }}
                        />
                    </div>

                    <div>
                        <Label label="To"></Label>
                        <DateTimePicker
                            strictParsing
                            closeOnSelect
                            closeOnClickOutside
                            onChange={(date) => {
                                if (date._d && range.from < date._d) {
                                    setRange((p) => {
                                        return {
                                            from: p.from,
                                            to: new Date(date._d),
                                        };
                                    });
                                }
                            }}
                            value={range.to}
                            isValidDate={(c, s) => {
                                return true;
                            }}
                        />
                    </div>

                    <Button
                        loading={loading}
                        disabled={loading}
                        handler={() => {
                            setRefresh((a) => 1 + a);
                        }}
                        text=""
                        width={"100%"}
                        Icon={AiOutlineSearch}
                    />
                </div>
            )}

            {data1.length > 0 && (
                <>
                    <table
                        className={styles.table}
                        {...getTableProps()}
                        style={{ border: "solid 1px blue" }}
                    >
                        <div className={styles.csvButton}>
                            <Button
                                Icon={BsCloudDownload}
                                iconReverse={true}
                                text=".CSV"
                                width={"80px"}
                                fontSize={12}
                                height={38}
                                handler={() => {
                                    download(data, fileName);
                                }}
                            ></Button>
                        </div>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                            style={{
                                                borderBottom: "solid 3px red",
                                                background: "aliceblue",
                                                color: "black",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? "🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        padding: "10px",
                                                        border: "solid 1px gray",
                                                        background:
                                                            "papayawhip",
                                                    }}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
}

export default WorkLog;
