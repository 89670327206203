import React, { useEffect, useState } from "react";
import {
    CircularProgressbar,
    buildStyles,
    CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { easeQuadInOut } from "d3-ease";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "./AnimationProgressProvider";
import RadialSeparators from "./RadialSeparator";
let interval = null;

export default function CircularProgressBar({
    progress = 50,
    startProgress = 0,
    parentProgress = 75,
    color = "#eaecf7",
    bgColor = "green",
    size = 0.5,
    _percentage = true,
    total = 8,
    radialSeparators = true,
    duration = 1.4,
}) {
    const rotation = -size / 2;
    const [percentageValue, setValue] = useState(startProgress);

    useEffect(() => {
        interval = setInterval(() => {
            setValue((ps) => {
                if (ps >= 90) return ps;
                else return ps + 1;
            });
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <AnimatedProgressProvider
            valueStart={startProgress}
            valueEnd={progress}
            duration={duration}
            easingFunction={easeQuadInOut}
        >
            {(value) => {
                let percentage = value;
                if (!_percentage) {
                    percentage = (value / total) * 100;
                }

                let roundedPercentage = Math.round(percentage);
                let roundedValue = Math.round(value * 2) / 2;
                return (
                    <div style={{ position: "relative" }}>
                        <CircularProgressbarWithChildren
                            value={parentProgress}
                            maxValue={10}
                            strokeWidth={8}
                            circleRatio={size}
                            counterClockwise={false}
                            styles={buildStyles({
                                rotation: 0.25,
                                strokeLinecap: "round",
                                pathColor: `transparent`,
                                pathTransitionDuration: 0.5,
                                rotation: rotation,
                                trailColor: "#eaecf7",
                            })}
                        >
                            {radialSeparators && (
                                <>
                                    <RadialSeparators
                                        angle={parentProgress}
                                        style={{
                                            background: "black",
                                            width: "2px",

                                            // This needs to be equal to props.strokeWidth
                                            height: `${12}px`,
                                        }}
                                    />
                                    <RadialSeparators
                                        count={8}
                                        style={{
                                            background: "white",
                                            width: "3px",
                                            strokeLinecap: "butt",
                                            // This needs to be equal to props.strokeWidth
                                            height: `${8}px`,
                                        }}
                                    />
                                </>
                            )}
                            {/* <div style={{ position: "relative" }}> */}
                            <CircularProgressbar
                                strokeWidth={8}
                                circleRatio={size}
                                value={percentageValue}
                                counterClockwise={false}
                                // text={`${
                                //     _percentage
                                //         ? roundedPercentage + "%"
                                //         : roundedValue
                                // }`}
                                styles={{
                                    ...buildStyles({
                                        backgroundColor: "transparent",
                                        textColor: "#29394a",
                                        textSize: "24px",

                                        strokeLinecap: "round",
                                        rotation: rotation,
                                        trailColor: bgColor,

                                        pathColor: color,
                                        pathTransition: "none",
                                    }),
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    zoom: "7",
                                    color: "#2c3948",
                                    fontWeight: 700,
                                }}
                            >
                                {parseInt(percentageValue)}
                                <span style={{ zoom: "0.5" }}>%</span>
                            </div>
                            {/* </div> */}
                        </CircularProgressbarWithChildren>
                    </div>
                );
            }}
        </AnimatedProgressProvider>
    );
}
