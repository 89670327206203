import React, { Dispatch, SetStateAction, useContext } from "react";
import styles from "./styles.module.css";
import { GoArrowRight } from "react-icons/go";
import { INTERLINKING_DOMAIN_STATUS, PAGE_CATEGORY } from "./constants";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import Button from "../../atoms/Button/Button";
import { Skeleton } from "@material-ui/lab";
import { PageInfo } from "./LinkingSuggestionModal";
import useGscHook from "../GoogleSearchConsole/useGscHook";
import SVGIcon from "../../atoms/SVGIcon";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";

type PageRow = {
    id: number;
    page_url: string;
    page_hash: string;
    scraping_version: number;
    category: number;
    internal_outbound_count: number;
    internal_inbound_count: number;
    suggested_inbound: number;
    suggested_outbound: number;
    opportunities_count: number;
    new_suggestion: number;
};

function IssueTable({
    setLinkingModal,
    pages,
    getIssueCount,
}: {
    setLinkingModal: Dispatch<SetStateAction<boolean>>;
    pages: any;
    getIssueCount: () => Promise<void>;
}) {
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <th>Page URL</th>
                    <th
                        className={styles.head_inbound}
                        style={{ minWidth: 135 }}
                    >
                        Current Inbound
                        {selectedDomainInfo &&
                            selectedDomainInfo?.stable_version_total_crawled_pages >
                                selectedDomainInfo?.stable_version_total_pages && (
                                <span style={{ color: "#FC5A5A" }}> *</span>
                            )}
                    </th>
                    <th
                        className={styles.head_outbound}
                        style={{ minWidth: 145 }}
                    >
                        Current Outbound
                    </th>
                    <th className={styles.head_linkStrength}>
                        Link Status
                        {selectedDomainInfo &&
                            selectedDomainInfo?.stable_version_total_crawled_pages >
                                selectedDomainInfo?.stable_version_total_pages && (
                                <span style={{ color: "#FC5A5A" }}> *</span>
                            )}
                    </th>
                    <th className={styles.head_actions} style={{ width: 288 }}>
                        Recommended Actions
                    </th>
                </thead>
                {!!pages ? (
                    pages.length ? (
                        <tbody>
                            {pages.map((page: any) => (
                                <IssueTableRow
                                    setLinkingModal={setLinkingModal}
                                    page={page}
                                    key={page.id}
                                    getIssueCount={getIssueCount}
                                />
                            ))}
                        </tbody>
                    ) : (
                        <tbody style={{ height: 300 }}>
                            <div className={styles.noResult}>
                                <SVGIcon
                                    src={"/New UI/SVG/webtuneLogsEmpty.svg"}
                                    style={{ margin: "auto" }}
                                />
                                No Results Found
                            </div>
                        </tbody>
                    )
                ) : (
                    <IssueRowsLoading />
                )}
            </table>
        </div>
    );
}

function IssueTableRow({
    setLinkingModal,
    page,
    getIssueCount,
}: {
    page: PageRow;
    setLinkingModal: any;
    getIssueCount: () => Promise<void>;
}) {
    const { lmManager } = useLinkManagerMixpanel();
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const { removeDomainFromURL } = useGscHook();
    const getColorForCount = (
        type: string,
        current: number,
        suggested: number,
    ) => {
        let colorStr = "rgba(252, 90, 90, 1)";
        if (current == 0 || current == null) {
            return colorStr;
        } else if (current >= 1 && current < suggested) {
            colorStr = "#FFBE57";
        } else {
            colorStr = "#1DC198";
        }
        return colorStr;
    };
    return (
        <tr>
            <td>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        justifyContent: "flex-start",
                    }}
                >
                    <a
                        href={page.page_url}
                        style={{
                            color: "rgb(1, 77, 197)",
                            fontWeight: 400,
                            background: "rgba(1,77,197,0.1)",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            fontSize: 13,
                        }}
                        onClick={() => {
                            lmManager({
                                ctaType: "Link Manager",
                                ctaUsed: "URL Clicked",
                            });
                        }}
                        target="_blank"
                    >
                        {removeDomainFromURL(page.page_url)}
                    </a>
                    {page.new_suggestion === 1 && (
                        <span
                            style={{
                                marginLeft: "5px",
                                borderRadius: "3px",
                                backgroundColor: "rgb(31, 76, 190)",
                                color: "white",
                                fontSize: "8px",
                                padding: "3px 5px",
                                display: "inline-flex",
                                justifyContent: "center",
                            }}
                        >
                            New
                        </span>
                    )}
                </div>
            </td>
            <td
                style={{
                    color: getColorForCount(
                        "inbound",
                        page.internal_inbound_count,
                        page.suggested_inbound,
                    ),
                }}
            >
                {page.internal_inbound_count}
            </td>
            <td
                style={{
                    color: getColorForCount(
                        "outbound",
                        page.internal_outbound_count,
                        page.suggested_outbound,
                    ),
                }}
            >
                {page.internal_outbound_count}
            </td>
            <td>
                <Tag suggestions={page} type={page.category} />
            </td>
            <td>
                <Button
                    width={220}
                    style={{
                        backgroundColor: "rgba(1, 77, 197, 0.1)",
                        color: "rgba(1, 77, 197, 1)",
                        fontWeight: 400,
                        fontSize: 13,
                    }}
                    text={
                        selectedDomainInfo?.interlinking_status ===
                        INTERLINKING_DOMAIN_STATUS.IN_PROGRESS
                            ? "Finding opportunities"
                            : selectedDomainInfo?.gsc_id
                            ? page.opportunities_count > 0
                                ? `Add from ${
                                      page.opportunities_count
                                  } opportunit${
                                      page.opportunities_count > 1 ? "ies" : "y"
                                  }`
                                : "No Opportunities Found"
                            : "Find opportunities"
                    }
                    Icon={() =>
                        selectedDomainInfo?.interlinking_status ===
                        INTERLINKING_DOMAIN_STATUS.IN_PROGRESS ? (
                            <></>
                        ) : (
                            <GoArrowRight size={20} />
                        )
                    }
                    handler={() => {
                        lmManager({
                            ctaType: "Link Manager",
                            ctaUsed: "Add Opportunities",
                            // @ts-ignore
                            issueCount: page.opportunities_count || "-",
                        });
                        selectedDomainInfo?.interlinking_status !==
                            INTERLINKING_DOMAIN_STATUS.IN_PROGRESS &&
                            setLinkingModal(page.id);
                    }}
                    disabled={
                        page.opportunities_count == 0 &&
                        selectedDomainInfo?.gsc_id
                    }
                    height={36}
                    loadingText={
                        selectedDomainInfo?.interlinking_status ===
                        INTERLINKING_DOMAIN_STATUS.IN_PROGRESS
                    }
                />
            </td>
        </tr>
    );
}

export function Tag({ type, suggestions }: { type: number; suggestions: any }) {
    const tg = {} as any;

    switch (type) {
        case PAGE_CATEGORY.ALL_GOOD:
            tg.bg = "rgba(16, 207, 252, 0.1)";
            tg.color = "rgba(33, 178, 212, 1)";
            tg.text = "All Good";
            break;
        case PAGE_CATEGORY.POTENTIAL_OPPORTUNITY:
            tg.bg = "rgba(255, 172, 95, 0.1)";
            tg.color = "rgba(223, 151, 85, 1)";
            tg.text = "Opportunity";
            break;
        case PAGE_CATEGORY.ACTION_REQUIRED:
            tg.bg = "rgba(252, 90, 90, 0.1)";
            tg.color = "rgba(239, 81, 81, 1)";
            tg.text = "Action Required";
            break;
    }

    return (
        <LightTooltip
            title={
                tg.text == "All Good" ? (
                    ""
                ) : suggestions ? (
                    <>
                        <ul style={{ paddingInlineStart: 15 }}>
                            {suggestions.internal_inbound_count <
                                suggestions?.suggested_inbound && (
                                <li>
                                    <small
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                        }}
                                    >
                                        Suggested number of inbound links: More
                                        than{" "}
                                        {suggestions?.suggested_inbound || "-"}
                                    </small>
                                </li>
                            )}
                            {suggestions?.internal_outbound_count <
                                suggestions?.suggested_outbound && (
                                <li>
                                    <small
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                        }}
                                    >
                                        Suggested number of outbound links: More
                                        than{" "}
                                        {suggestions?.suggested_outbound || "-"}
                                    </small>
                                </li>
                            )}
                        </ul>
                    </>
                ) : (
                    ""
                )
            }
            placement="right"
            // @ts-ignore
            tooltipStyle={{
                maxWidth: "520px",
            }}
            arrow
        >
            <div
                style={{ backgroundColor: tg.bg, color: tg.color }}
                className={styles.tag}
            >
                {tg.text}
            </div>
        </LightTooltip>
    );
}

function IssueRowsLoading() {
    return new Array(20).fill(1).map(() => (
        <tr>
            <td>
                <Skeleton width={150} />
            </td>
            <td>
                <Skeleton width={30} style={{ margin: "auto" }} />
            </td>
            <td>
                <Skeleton width={30} style={{ margin: "auto" }} />
            </td>
            <td>
                <Skeleton width={80} />
            </td>
            <td>
                <Skeleton width={280} height={36} />
            </td>
        </tr>
    ));
}

export default IssueTable;
