import React, { useEffect, useState } from "react";
import styles from "./GenerateResults.module.css";
import {
    RiFileCopyLine,
    RiAddCircleFill,
    RiStarLine,
    RiStarSFill,
} from "react-icons/ri";
import { TextareaAutosize, withStyles } from "@material-ui/core";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import Tooltip from "@material-ui/core/Tooltip";
import useHelperFunctions from "../../../helperFunctions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import WriteToEditor from "../../../organisms/TextEditor/Toolbar/WriteToEditor";
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import { MARK_FAVORITE_GENERATE } from "./../../../api.json";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

let likeColor = "rgb(1, 77, 197,0.8)" || "rgba(16, 207, 252,0.4)";
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: "none",
    },
}))(Tooltip);

function GenerateResult({
    result,
    isSensitive,
    id,
    small,
    addButton,
    setValue,
    uniqueId,
    isFav,
    favIcon,
    setLocalData,
    history = false,
    favourite = false,
    newResult,
    selectedCase = {},
}) {
    const [editableResult, setEditableResult] = useState(result);
    const [isCopied, setIsCopied] = useState(false);
    const [isFavorite, setisFavorite] = useState(isFav);
    const { postData } = useHelperFunctions();
    const { mpTrackAddToEditor } = useMixpanelHook();
    const goTo = useNavigation();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(editableResult);
        setIsCopied(true);
    };

    const markFavorite = async (fav) => {
        let payload = {
            isFavorite: fav,
            uniqueId: uniqueId,
        };
        console.log(payload, "oopop");

        setisFavorite(fav);
        if (setLocalData) {
            setLocalData((ps) => {
                let arr = ps.map((item) => {
                    if (item.uniqueId == uniqueId) {
                        item.isFavorite = fav;
                    }

                    return item;
                });

                return arr;
            });
        }
        const response = await postData({
            url: MARK_FAVORITE_GENERATE,
            headers: {
                "Content-Type": "application/json",
            },
            payload: payload,
        });
    };

    const addToEditor = () => {
        WriteToEditor({
            type: "text",
            data: !document.quill.editor.getText(
                document.quill.editor.cursor,
                1,
            )
                ? editableResult
                : document.quill.editor.getText(
                      document.quill.editor.cursor,
                      1,
                  ) != "\n"
                ? "\n" + editableResult
                : "\n" + editableResult,
            length: editableResult.length + 1,
            source: "user",
        });
        mpTrackAddToEditor({
            useCase: selectedCase,
            editorType: window.location.pathname,
        });
        setIsCopied(true);
    };

    const seamlessGenerate = () => {
        goTo("VARIANT_DESCRIPTION", [`reqTitle=${editableResult}`], 1);
    };

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    return (
        <div
            className={
                styles.generateResult +
                " " +
                (newResult ? styles.newResult : "")
            }
        >
            <TextareaAutosize
                value={editableResult}
                spellCheck={false}
                className={styles.generateResult__textbox}
                onKeyPress={(e) => {
                    // if (e.key === "Enter") {
                    //   e.currentTarget.blur();
                    // }
                }}
                onChange={(e) => setEditableResult(e.target.value)}
                aria-label="minimum height"
            />

            <div
                className={styles.toolBox}
                style={{
                    justifyContent:
                        Number(id) === 1 ? "space-between" : "flex-end",
                }}
            >
                <div className={styles.flexCenter} style={{ gap: 5 }}>
                    {favIcon && (
                        // <LightTooltip
                        //   title={!isFavorite ? "Add to Favourite" : "Remove from Favourite"}
                        //   placement="right"
                        // >
                        <div className={styles.likeBtnContainer}>
                            {/* {!isFavorite ? (
                  <AiOutlineHeart
                    className={styles.heart}
                    onClick={() => markFavorite(true)}
                  />
                ) : (
                  <AiFillHeart
                    className={styles.heartFill}
                    onClick={() => markFavorite(false)}
                  />
                )} */}
                            <LightTooltip
                                title={"Like it, Save for Later!"}
                                placement="top"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IoMdThumbsUp
                                        onClick={() =>
                                            markFavorite(
                                                isFavorite == 1 ? 0 : 1,
                                            )
                                        }
                                        size={20}
                                        color={
                                            isFavorite == 1
                                                ? likeColor
                                                : "#e7e7e7"
                                        }
                                        cursor={"pointer"}
                                    />
                                </div>
                            </LightTooltip>
                            <LightTooltip
                                title={"Didn't like it!"}
                                placement="top"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IoMdThumbsDown
                                        onClick={() =>
                                            markFavorite(
                                                isFavorite == -1 ? 0 : -1,
                                            )
                                        }
                                        size={20}
                                        color={
                                            isFavorite == -1
                                                ? likeColor
                                                : "#e7e7e7"
                                        }
                                        cursor={"pointer"}
                                    />
                                </div>
                            </LightTooltip>
                            {isSensitive && (
                                <LightTooltip
                                    title="Possibility of Sensitive content. Use with caution."
                                    placement="top"
                                >
                                    <div className={styles.sensitiveWarning}>
                                        !
                                    </div>
                                </LightTooltip>
                            )}
                        </div>
                        // </LightTooltip>
                    )}

                    {!(history || favourite) && (
                        // <LightTooltip title={"Save for Later"} placement="right">
                        //   <div className={styles.likeBtnContainer}>
                        //     {/* {isFavorite ? (
                        //       <AiFillHeart
                        //         className={styles.heartFill}
                        //         onClick={() => markFavorite(false)}
                        //       />
                        //     ) : (
                        //       <AiOutlineHeart
                        //         className={styles.heart}
                        //         onClick={() => markFavorite(true)}
                        //       /> */}
                        //     <IoMdThumbsUp
                        //       onClick={() => markFavorite(isFavorite == 1 ? 0 : 1)}
                        //       size={20}
                        //       color={
                        //         isFavorite == 1 ? "rgba(16, 207, 252, 0.4)" : "#e7e7e7"
                        //       }
                        //       cursor={"pointer"}
                        //     />
                        //     <IoMdThumbsDown
                        //       onClick={() => markFavorite(isFavorite == -1 ? 0 : -1)}
                        //       size={20}
                        //       color={
                        //         isFavorite == -1 ? "rgba(16, 207, 252, 0.4)" : "#e7e7e7"
                        //       }
                        //       cursor={"pointer"}
                        //     />
                        //     {/* )} */}
                        //   </div>
                        // </LightTooltip>
                        <div className={styles.likeBtnContainer}>
                            {/* {!isFavorite ? (
                  <AiOutlineHeart
                    className={styles.heart}
                    onClick={() => markFavorite(true)}
                  />
                ) : (
                  <AiFillHeart
                    className={styles.heartFill}
                    onClick={() => markFavorite(false)}
                  />
                )} */}
                            <LightTooltip
                                title={"Like it, Save for Later!"}
                                placement="top"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IoMdThumbsUp
                                        onClick={() =>
                                            markFavorite(
                                                isFavorite == 1 ? 0 : 1,
                                            )
                                        }
                                        size={20}
                                        color={
                                            isFavorite == 1
                                                ? likeColor
                                                : "#e7e7e7"
                                        }
                                        cursor={"pointer"}
                                    />
                                </div>
                            </LightTooltip>
                            <LightTooltip
                                title={"Didn't like it!"}
                                placement="top"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IoMdThumbsDown
                                        onClick={() =>
                                            markFavorite(
                                                isFavorite == -1 ? 0 : -1,
                                            )
                                        }
                                        size={20}
                                        color={
                                            isFavorite == -1
                                                ? likeColor
                                                : "#e7e7e7"
                                        }
                                        cursor={"pointer"}
                                    />
                                </div>
                            </LightTooltip>
                            {isSensitive && (
                                <LightTooltip
                                    title="Possibility of Sensitive content. Use with caution."
                                    placement="top"
                                >
                                    <div className={styles.sensitiveWarning}>
                                        !
                                    </div>
                                </LightTooltip>
                            )}
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        {addButton && (
                            <span
                                onClick={addToEditor}
                                style={{
                                    marginRight: "3px",
                                    color: "var(--primary-blue)",
                                    fontSize: 12,
                                    cursor: "pointer",
                                    fontWeight: 500,
                                }}
                            >
                                Add to Editor
                            </span>
                        )}
                        {/* {Number(id) === 1 && (
              <LightTooltip title="Liked it? Request a blog for this idea in our Marketplace now.">
                <div onClick={seamlessGenerate} className={styles.flexCenter}>
                  <span className={styles.placeLink}>Place a Request</span>
                </div>
              </LightTooltip>
            )} */}
                        <LightTooltip
                            title={isCopied ? "Copied!" : "Copy"}
                            placement="top"
                        >
                            <span style={{ height: "1.3rem" }}>
                                <RiFileCopyLine
                                    className={styles.copyIcon}
                                    onClick={copyToClipboard}
                                />
                            </span>
                        </LightTooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenerateResult;
