// import React from "react";
import React, { useState } from "react";
import styles from "./card.module.css";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useHistory } from "react-router-dom";

import ProjectDetailsModal from "../../dashboard/UI/ProjectDetailsModal/ProjectDetailsModal";
import { getTime } from "../../../utility/validateFunctions";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import Modal from "../../../componentsV2/atoms/Modal/Modal";

function Card({
  created,
  heading,
  message,
  notify_message,
  isRead,
  updateNotification,
  id,
  name,
  projectId,
  type,
  icon,
  variant_name,
  moreOption,
  setMoreOption,
}) {
  const [_isRead, setIsRead] = useState(isRead);
  const [projectName, setProjectName] = useState("");
  const [statusName, setStatusName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState(false);
  const history = useHistory();

  function readOrRemove(e, action) {
    updateNotification(id, action);
    setIsRead(action);
    e.stopPropagation();
    setOptions(false);
  }

  function showOptions(e) {
    e.stopPropagation();
    setOptions(!options);
    setMoreOption(!moreOption);
  }
  function putIcon() {
    if (type == 1) {
      return <img src="/admin/scalenut/notifications/status.svg"></img>;
    } else if (type == 2) {
      return <img src="/admin/scalenut/notifications/file.svg"></img>;
    } else if (type == 3) {
      return <img src="/admin/scalenut/notifications/list.svg"></img>;
    } else if (type == 4) {
      return <img src="/admin/scalenut/notifications/comment.svg"></img>;
    } else if (type == 5) {
      return <img src="/admin/scalenut/notifications/heart.svg"></img>;
    } else if (type == 6) {
      return <img src="/admin/scalenut/notifications/abc.svg"></img>;
    } else {
      return <IoIosNotificationsOutline> </IoIosNotificationsOutline>;
    }
  }

  function read() {
    return _isRead == "2" ? styles.isReadYes : styles.isReadNo;
  }

  if (_isRead == "0") return null;

  return (
    // let name=`&{}`
    <div
      onMouseLeave={() => {
        setOptions(false);
      }}
      onClick={(e) => {
        if (isRead == "1") readOrRemove(e, "2");
        if (type == 7) history.push(`/admin/creator`);
        else setIsModalOpen(true);
        setIsRead("2");
      }}
      className={styles.cardContainer + " " + read()}
    >
      {isModalOpen ? (
        <Modal setModal={setIsModalOpen}>
          <ProjectDetailsModal
            projectName={projectName}
            setProjectName={setProjectName}
            statusName={statusName}
            setStatusName={setStatusName}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            icon={icon ? icon : null}
            id={projectId}
            variant_name={variant_name ? variant_name : null}
          />
        </Modal>
      ) : null}
      <div className={styles.card}>
        <div className={styles.statusIcon}>{putIcon()}</div>

        <div className={styles.cardContent}>
          <div
            className={styles.cardInfo}
            dangerouslySetInnerHTML={{
              __html:
                `<span class=${styles.cardInfoB}>${name}</span>` +
                " " +
                heading,
            }}
          ></div>
          <div className={styles.timeInfo}>{getTime(created)} </div>
        </div>
        <div onClick={(e) => showOptions(e)} className={styles.options}>
          <HiOutlineDotsHorizontal />
          {options && (
            <div className={styles.optionsMenu}>
              {_isRead == "1" ? (
                <div onClick={(e) => readOrRemove(e, "2")}>
                  <img src={"/admin/scalenut/tick.svg"}></img> Mark as read
                </div>
              ) : (
                <div onClick={(e) => readOrRemove(e, "1")}>
                  <img src={"/admin/scalenut/tick.svg"}></img> Mark as unread
                </div>
              )}

              <div onClick={(e) => readOrRemove(e, "0")}>
                <img src={"/admin/scalenut/trash.svg"}></img> Remove
                Notification
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Card;
