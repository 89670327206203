import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { url } from "../../../../utility/config";
import CreatorCore from "../CreatorCore/CreatorCore";
import { v4 as uuidv4 } from "uuid";
import Progress from "../../../atoms/Progress/Progress";
import Aside from "../../Aside/Aside";

const config = {
    apiKey: "AIzaSyDuHi9fFm54_mrvQNZxYP3jySswDPyPkXk",
    authDomain: "scalenut-efdb9.firebaseapp.com",
    databaseURL: "https://scalenut-efdb9.firebaseio.com",
    projectId: "scalenut-efdb9",
    storageBucket: "scalenut-efdb9.appspot.com",
    messagingSenderId: "198270812079",
    appId: "1:198270812079:web:744f0a951c832c0593f95a",
    measurementId: "G-6E5WQ926XZ",
};
function IndividualReport({ auditMode }) {
    let { id, access } = useParams();
    const [loadingGuest, setLoadingGuest] = useState(true);
    const guestData = localStorage.getItem("guestUser");
    const deviceId = localStorage.getItem("deviceId");
    const [guestUser, setGuestUser] = useState(
        guestData ? JSON.parse(guestData) : {},
    );

    useEffect(() => {
        const getGuestUser = async (alreadyPresent) => {
            setLoadingGuest(true);

            if (alreadyPresent) {
                let urlVal = `${url}/api/creator/guest?access=${access}&report_hash=${id}&guest_id=${deviceId}`;
                try {
                    const res = await axios({
                        method: "get",
                        url: urlVal,
                    });

                    // console.log(res.data);
                    // console.log(typeof res.data);
                    localStorage.setItem("guestUser", JSON.stringify(res.data));
                    setGuestUser(res.data);
                    setLoadingGuest(false);
                } catch (error) {
                    setLoadingGuest(false);
                }
            } else {
                try {
                    let deviceIdToken;
                    // firebase.initializeApp(config);
                    if (!deviceId) {
                        localStorage.setItem("deviceId", uuidv4());
                    }

                    let urlVal = `${url}/api/creator/guest?access=${access}&report_hash=${id}`;
                    if (deviceIdToken) {
                        urlVal += `&guest_id=${deviceIdToken}`;
                    } else if (guestUser && guestUser.guest_id) {
                        urlVal += `&guest_id=${guestUser.guest_id}`;
                    }

                    const res = await axios({
                        method: "get",
                        url: urlVal,
                    });
                    localStorage.setItem("guestUser", JSON.stringify(res.data));
                    setGuestUser(res.data);
                    setLoadingGuest(false);
                } catch (error) {
                    setLoadingGuest(false);
                }
            }
        };

        if (deviceId) {
            getGuestUser(true);
        } else {
            if (guestData) {
                setGuestUser(JSON.parse(guestData));
            }
            getGuestUser(false);
        }
    }, []);
    return (
        <>
            {loadingGuest ? (
                <Progress height="100vh" width={"100vw"} />
            ) : (
                <div
                    style={{
                        height: "100vh",
                        width: "100vw",
                        overflow: "hidden",
                        display: "flex",
                    }}
                >
                    <Aside noAuth={true} auditMode={auditMode} />
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                            flexGrow: 1,
                            flexDirection: "column",
                        }}
                    >
                        <CreatorCore
                            isShared={{
                                ...guestUser,
                                id,
                                access:
                                    guestUser.is_full_access === 1
                                        ? true
                                        : false,
                            }}
                            auditMode={auditMode}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default IndividualReport;
