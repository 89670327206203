export const data = [
    {
        heading: "Marketplace",
        sub_heading: "FREE FOREVER",
        id: 2,
        options: [
            {
                name: "Talent Network",
                sub1: "Over 5000+ Content Creators",
                sub2: "Top 2% Talent",
            },
            {
                name: "Scalenut Assured",
                sub1: "100% Quality Assurance",
                sub2: "‍Transparent & Fixed Pricing",
            },
            {
                name: "Seamless Execution",
                sub1: "Unlimited Revisions",
                sub2: "Pre-Defined Workflows",
            },
        ],
        price: 0,
        img: "/admin/scalenut/subscription/Challenge_Medal.svg",
        priceId: 1,
        productId: 1,
    },

    {
        heading: "Scale Suite",
        sub_heading: "PER MONTH  /  PAID MONTHLY",
        id: 2,
        options: [
            {
                name: "GenerateAI",
                sub1: "‍Unlimited Content Generation using AI",
                sub2: "Blog Ideas, Ad Copies, Captions & more",
            },
            {
                name: "QualityX",
                sub1: "‍Auto-Quality Checks for SEO compliance",
                sub2: "‍Plagiarism, Readability, Tonality & more",
            },
        ],
        price: 49,
        img: "/admin/scalenut/subscription/Badge_Onfire.svg",
        priceId: 2,
        productId: "prod_IxiZnwYoi2n4ey",
    },

    {
        heading: "Power Plus",
        sub_heading: "PER MONTH  /  PAID MONTHLY",
        id: 3,
        options: [
            {
                name: "Most Powerful Subscription",
                sub1: "‍Powerful Software and Top Talent",
            },
            {
                name: "Unlimited access to ScaleSuite",
                sub1: "‍GenerateAI",
                sub2: "‍QualityX",
            },
            {
                name: "Unlimited marketplace access",
                sub1: "$199 non expiring Marketplace credits",
                sub2: "Dedicated Account Manager",
            },
        ],
        price: 199,
        img: "/admin/scalenut/subscription/Badge_Onfire.svg",
        priceId: 3,
        productId: "3",
    },

    // {
    //   heading: "Growth",
    //   sub_heading: "Best for agencies & medium to large businesses",
    //   id: 4,
    //   options: [
    //     {
    //       name: "Unlimited Free Requests",
    //       check: true,
    //     },
    //     {
    //       name: "Unlimited Free Revisions",
    //       check: true,
    //     },
    //     {
    //       name: "Dedicated Account Manager",
    //       check: true,
    //     },
    //     {
    //       name: "Content Writing",
    //       check: true,
    //     },
    //     {
    //       name: "Graphic Design",
    //       check: true,
    //     },
    //     {
    //       name: "Motion Graphics",
    //       check: true,
    //     },
    //     {
    //       name: "GIFs",
    //       check: true,
    //     },

    //     {
    //       name: "Source Files Included",
    //       check: true,
    //     },

    //     {
    //       name: "Unlimited Brand Profiles",
    //       check: true,
    //     },
    //     {
    //       name: "AI Quality checks",
    //       check: true,
    //     },
    //     {
    //       name: "14 Days Satisfaction Guarantee",
    //       check: true,
    //     },
    //     {
    //       name: "3 Request at a Time",
    //       check: true,
    //     },
    //     {
    //       name: "1-3 days delivery",
    //       check: true,
    //     },
    //   ],

    //   price: 299,
    //   img: "/admin/scalenut/subscription/Top_Student.svg",
    //   priceId: "4",
    //   productId: "4",
    // },
];

export const dataINR = [
    {
        heading: "Marketplace",
        sub_heading: "FREE FOREVER",
        id: 2,
        options: [
            {
                name: "Talent Network",
                sub1: "Over 5000+ Content Creators",
                sub2: "Top 2% Talent",
            },
            {
                name: "Scalenut Assured",
                sub1: "100% Quality Assurance",
                sub2: "‍Transparent & Fixed Pricing",
            },
            {
                name: "Seamless Execution",
                sub1: "Unlimited Revisions",
                sub2: "Pre-Defined Workflows",
            },
        ],
        price: 0,
        img: "/admin/scalenut/subscription/Challenge_Medal.svg",
        priceId: 2,
        productId: 1,
    },

    {
        heading: "Scale Suite",
        sub_heading: "PER MONTH  /  PAID MONTHLY",
        id: 2,
        options: [
            {
                name: "GenerateAI",
                sub1: "‍Unlimited Content Generation using AI",
                sub2: "Blog Ideas, Ad Copies, Captions & more",
            },
            {
                name: "QualityX",
                sub1: "‍Auto-Quality Checks for SEO compliance",
                sub2: "‍Plagiarism, Readability, Tonality & more",
            },
        ],
        price: 3500,
        img: "/admin/scalenut/subscription/Badge_Onfire.svg",
        priceId: 3,
        productId: "prod_IxiZnwYoi2n4ey",
    },

    {
        heading: "Power Plus",
        sub_heading: "PER MONTH  /  PAID MONTHLY",
        id: 3,
        options: [
            {
                name: "Most Powerful Subscription",
                sub1: "‍Powerful Software and Top Talent",
            },
            {
                name: "Unlimited access to ScaleSuite",
                sub1: "‍GenerateAI",
                sub2: "‍QualityX",
            },
            {
                name: "Unlimited marketplace access",
                sub1: "₹15,000 non expiring Marketplace credits",
                sub2: "Dedicated Account Manager",
            },
        ],
        price: 15000,
        img: "/admin/scalenut/subscription/Badge_Onfire.svg",
        priceId: 4,
        productId: "3",
    },

    // {
    //   heading: "Growth",
    //   sub_heading: "Best for agencies & medium to large businesses",
    //   id: 4,
    //   options: [
    //     {
    //       name: "Unlimited Free Requests",
    //       check: true,
    //     },
    //     {
    //       name: "Unlimited Free Revisions",
    //       check: true,
    //     },
    //     {
    //       name: "Dedicated Account Manager",
    //       check: true,
    //     },
    //     {
    //       name: "Content Writing",
    //       check: true,
    //     },
    //     {
    //       name: "Graphic Design",
    //       check: true,
    //     },
    //     {
    //       name: "Motion Graphics",
    //       check: true,
    //     },
    //     {
    //       name: "GIFs",
    //       check: true,
    //     },

    //     {
    //       name: "Source Files Included",
    //       check: true,
    //     },

    //     {
    //       name: "Unlimited Brand Profiles",
    //       check: true,
    //     },
    //     {
    //       name: "AI Quality checks",
    //       check: true,
    //     },
    //     {
    //       name: "14 Days Satisfaction Guarantee",
    //       check: true,
    //     },
    //     {
    //       name: "3 Request at a Time",
    //       check: true,
    //     },
    //     {
    //       name: "1-3 days delivery",
    //       check: true,
    //     },
    //   ],

    //   price: 299,
    //   img: "/admin/scalenut/subscription/Top_Student.svg",
    //   priceId: "4",
    //   productId: "4",
    // },
];

export const OptionsTypes = {
    input: 1,
    contactUs: 2,
    inputCheck: 3,
    none: 4,
};

export const cancellationData = {
    opts: [
        {
            title: "I do not like the quality of outputs generated.",
            type: OptionsTypes.inputCheck,
            text: "Let us know the output of which functionality you did not like.",
            options: [
                "SEO Research",
                "SEO Content/Cruise Mode",
                "AI Copywriter",
            ],
            placeholder:
                "Enter the use case for which you think the output quality was not good",
        },
        {
            title: "I do not require this service anymore.",
            type: OptionsTypes.none,
            text: "You will lose the access to the plan and be transferred to a free plan on your next renewal date.",
        },
        {
            title: "I find it expensive.",
            type: OptionsTypes.contactUs,
            text: "",
        },
        {
            title: "I am moving to another platform.",
            type: OptionsTypes.input,
            text: "Let us know how we can improve your experience.",
            placeholder: "We read every answer",
        },
        {
            title: "The features I need are missing.",
            type: OptionsTypes.input,
            text: "Let us know what feature you think was missing.",
            placeholder: "We read every answer",
        },
        {
            title: "Other",
            type: OptionsTypes.input,
            text: "Let us know how we can improve your experience.",
            placeholder: "Your feedback is valuable to us",
        },
    ],
};
