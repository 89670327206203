import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.css";
import moment from "moment";
import "./styles.css";

const CustomDatePicker = ({
    date = null,
    setDate = () => {},
    startDate = null,
    endDate = null,
    minDate = null,
    label = "Date",
    toggler = false,
    setToggler = () => {},
    readOnly = false,
    dateFormat = "dd/MM/yyyy",
}) => {
    const [defaultMinDate, setDefaultMinDate] = useState(
        moment().subtract(1, "day"),
    );
    const [ele, setEle] = useState(null);
    const [defaultMaxDate, setDefaultMaxDate] = useState(moment().format());
    const [defaultStartDate, setDefaultStartDate] = useState(moment().format());
    const [defaultEndDate, setDefaultEndDate] = useState(
        moment().subtract(1, "month"),
    );
    const selectStartDate = (d) => {
        setDate(d);
        setToggler(false);
    };

    useEffect(() => {}, []);

    return (
        <div className={styles.container}>
            <div
                className={"datePickerInput_Wrapper"}
                style={{ cursor: readOnly ? "pointer" : "default" }}
            >
                <DatePicker
                    style={{ background: "red" }}
                    dateFormat={dateFormat}
                    selectsStart
                    selected={date}
                    maxDate={defaultMaxDate}
                    onChange={selectStartDate}
                    popperPlacement={"bottom"}
                    renderYearContent={2024}
                    open={toggler}
                    readOnly={readOnly}
                />
            </div>
            {/* <div className={styles.datePicker_right}>
                    <DatePicker
                        selectsEnd
                        selected={rangeEnd}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        onChange={selectEndDate}
                    />
                </div> */}
        </div>
    );
};

export default CustomDatePicker;
