import React from "react";
import styles from "../UserReports/styles.module.css";
import Button from "../../atoms/Button/Button";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../atoms/SVGIcon";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import GenerateFavourite from "../../organisms/Generate/GenerateFavourite/GenerateFavourite";
import GenerateHistory from "../../organisms/Generate/GenerateHistory/GenerateHistory";
import { useState } from "react";
import { ALL_GENERATE_FOCUS } from "../../Context/OnBoardingContext/constant";
import Generate from "../Generate/Generate";
import Modal from "../../atoms/Modal/Modal";

const OUTPUT_TYPES = { FAVORITE: "favorite", HISTORY: "history" };

function AIOutputs() {
    const params = useParams();
    const [allUseCases, setAllUseCases] = useState(false);

    const { type } = params;

    // if (type != OUTPUT_TYPES.FAVORITE && OUTPUT_TYPES.HISTORY !== type)
    //   return <Redirect to={OUTPUT_TYPES.FAVORITE} />;
    console.log(allUseCases, "????????/");

    return (
        <div style={{ width: "calc(11/14 * 100vw)", margin: "0 auto" }}>
            <div style={{ margin: "10px 0" }}>
                {/* <div style={{ width: "60%" }} className={styles.search}>
          <Textfield
            height={40}
            containerStyle={{ marginBottom: "0" }}
            props={{
              onChange: (e) => {
                const value = e.target.value.trim();
              },
              style: {
                borderRadius: 3,
                border: "solid 1px rgba(75, 75, 85, 0.16)",
                backgroundColor: "#fff",
                paddingLeft: "40px",
                borderRadius: 3,
              },
            }}
            placeholder="Search AI Outputs"
            label=""
            Icon={() => <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />}
            iconStyle={{
              left: "10px",
              right: "unset",
              top: "20px",
            }}
          />
        </div> */}

                <Tabs page={type} />
            </div>
            {type == OUTPUT_TYPES.FAVORITE ? (
                <GenerateFavourite setAllUseCases={setAllUseCases} />
            ) : (
                <GenerateHistory setAllUseCases={setAllUseCases} />
            )}

            {allUseCases && (
                <Modal setModal={setAllUseCases}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "auto",
                            height: "80vh",
                            width: "70vw",
                            backgroundColor: "#f6f8fc",
                            padding: "0px 30px 30px 30px",
                            borderRadius: "10px",
                        }}
                    >
                        <Generate aside={{}} setModal={setAllUseCases} />
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default AIOutputs;

export function Tabs({ page }) {
    const goTo = useNavigation();

    const active = {
        borderRadius: 3,
        backgroundColor: "#1f4cbe",
        fontWeight: 400,
    };
    const inActive = {
        borderRadius: 3,
        backgroundColor: "white",
        color: "#6b6b6b",
        fontWeight: 400,
    };

    return (
        <div className={styles.binTabs}>
            <Button
                text="Favorites"
                width={150}
                handler={() => {
                    goTo("AI_OUTPUTS_FAV");
                }}
                borderRadius="3"
                height={"100%"}
                style={page == OUTPUT_TYPES.FAVORITE ? active : inActive}
            />
            <Button
                text="History"
                width={150}
                handler={() => {
                    goTo("AI_OUTPUTS_HIS");
                }}
                borderRadius="3"
                height={"100%"}
                style={page == OUTPUT_TYPES.HISTORY ? active : inActive}
            />
        </div>
    );
}
