import axios from "axios";
import * as actionTypes from "../actiontypes";
import { PROD, url, USER_WIDGET_KEY } from "../../utility/config";
import qs from "qs";
import { updateProjects } from "./dashboard";
import { REGISTER } from "../../screens.json";
import * as Sentry from "@sentry/react";
import { device_token } from "../../App";
import { emitEvent, pageHit } from "../../UserDotComEvents";
import useMixpanelHook from "../../utility/useMixpanelHook";
import mixpanel from "mixpanel-browser";
import { valueFromCookie } from "../../componentsV2/templates/Auth/Auth";
import TagManager from "react-gtm-module";

export const validationError = (errorMsg) => {
    return {
        type: actionTypes.VALIDATION_ERROR,
        errorMessage: errorMsg,
    };
};

export const VerificationEmail = ({ email, password }) => {
    return {
        type: actionTypes.VERIFICATION_MAIL,
        verifyEmail: true,
        email,
        password,
    };
};

const getBrowserName = () => {
    let userAgent = window.navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else if (userAgent.match(/edge/i)) {
        browserName = "edge";
    } else {
        browserName = "No browser detection";
    }

    return browserName;
};
export const showRegisterPage = (
    email,
    googleToken,
    lifetime,
    queryEntries,
) => {
    return {
        type: actionTypes.SHOW_REGISTER_PAGE,
        email: email,
        googleToken: googleToken ? googleToken : null,
        context: queryEntries["context"] ? queryEntries["context"] : null,
        utmMedium: queryEntries["utm_medium"]
            ? queryEntries["utm_medium"]
            : null,
        utmSource: queryEntries["utm_source"]
            ? queryEntries["utm_source"]
            : null,
        utmCampaign: queryEntries["utm_campaign"]
            ? queryEntries["utm_campaign"]
            : null,
        lifetime: lifetime ? lifetime : null,
        ...queryEntries,
    };
};
export const showLoginPage = (email) => {
    return {
        type: actionTypes.SHOW_LOGIN_PAGE,
        email: email,
    };
};

export const updateUserInfo = (term, value) => {
    return {
        type: actionTypes.UPDATE_INFO,
        term: term,
        value: value,
    };
};

export const updateUserInfoV2 = (data = {}) => {
    return {
        type: actionTypes.UPDATE_INFO_V2,
        data,
    };
};

export const update_plan = (plan_id) => {
    return {
        type: actionTypes.UPDATE_PLAN,
        plan_id: plan_id,
    };
};

export const clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR,
    };
};

export const authStart = (googleLogin, googleToken) => {
    return {
        type: actionTypes.AUTH_START,
        googleLogin,
        googleToken,
    };
};

export const authSuccess = (
    token,
    {
        id,
        email,
        name,
        role,
        currency,
        plan_id,
        phone,
        profile_picture,
        stripe_id,
        logo,
        gst_number,
        isCreator,
        isGenerate,
        ai_tour,
        seo_tour,
        company_user_type,
        is_company_email,
        company_name,
        company_id,
        first_subscription_status,
        signup_mode,
        user_created_at,
        userGuiding,
        show_lm_landing,
        show_humanizer_landing,
        segment,
        on_boarding,
        country_name,
        seo_surge_user,
    },
    path,
    loading = false,
) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: id,
        email: email,
        profile_picture: profile_picture,
        logo: logo,
        name: name,
        phone: phone,
        role: role,
        path: path,
        plan_id: plan_id,
        segment: segment,
        currency,
        validatingToken: loading,
        stripe_id: stripe_id,
        gstNo: gst_number,
        isCreator: isCreator,
        isGenerate: isGenerate,
        ai_tour,
        seo_tour,
        company_user_type,
        company_name,
        is_company_email,
        company_id,
        first_subscription_status,
        show_lm_landing,
        show_humanizer_landing,
        signup_mode,
        user_created_at,
        userGuiding,
        on_boarding,
        country_name,
        seo_surge_user,
    };
};

export const authUnauthorized = (errorMsg) => {
    return {
        type: actionTypes.AUTH_INCORRECT_LOGIN_DETAILS,
        error: errorMsg,
    };
};

export const authFail = (errorMsg) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: errorMsg,
    };
};

export const userLogout = (errrr) => {
    let docsData = localStorage.getItem("docsData");
    localStorage.clear();

    localStorage.setItem("docsData", docsData);
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

//CHECK EMAIL
export const continueWithEmail =
    (email = "", queryEntries = {}) =>
    async (dispatch, getState) => {
        const { country: country_name } = getState().auth;

        let browserName = getBrowserName();
        const userInfo = {
            country_name,
            browser: browserName,
            os: window.navigator.platform,
            device_type: getIsMobile(),
        };

        var data = {
            email,
            ...userInfo,
            ...queryEntries,
        };

        var config = {
            method: "post",
            url: url + "/api/user/checkEmail",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: qs.stringify(data),
        };
        dispatch(authStart(false, null));
        axios(config)
            .then(({ data, status }) => {
                if (status === 200) {
                    dispatch(showLoginPage(email));
                }
                if (status === 201) {
                    dispatch(showRegisterPage(email, null, null, queryEntries));
                }
            })

            .catch(function (error) {
                if (error) {
                    if (
                        error &&
                        error.response &&
                        error.response.status === 400
                    ) {
                        dispatch(authFail(error.response.data.message));
                    }
                } else {
                    dispatch(
                        authFail(
                            error &&
                                error.response &&
                                error.response.statusText,
                        ),
                    );
                }
            });
    };

//USER LOGIN
export const loginUser =
    ({ email, password }, verify) =>
    (dispatch) => {
        if (!verify) dispatch(authStart(false, null));

        axios({
            method: "post",
            url: url + "/api/user/login",
            data: qs.stringify({
                email: email,
                password: password,
            }),
            headers: {
                "content-type":
                    "application/x-www-form-urlencoded;charset=utf-8",
                ...device_token,
            },
        })
            //.post(`${url}/api/login`, signInData)
            .then(({ data, status }) => {
                if (status === 200) {
                    let obj = {
                        Name: data.data.user_info?.name,
                        $email: data.data.user_info?.email,
                        "Last Login": Date(),
                    };
                    mixpanel.register({
                        ...obj,
                        "First login": data.data.user_info?.user_created_at,
                        "Signup mode":
                            data.data.user_info.signup_mode == 1
                                ? "Mode 1"
                                : "Mode 2",
                        from_google: false,
                    });
                    mixpanel.identify(data.data.user_info?.email);
                    mixpanel.people.set({
                        $name: data.data.user_info?.name,
                        $email: data.data.user_info?.email,
                        "First Login": data.data.user_info?.user_created_at,
                        "Plan Type": "-",
                        "Signup Mode":
                            data.data.user_info.signup_mode == 1
                                ? "Mode 1"
                                : "Mode 2",
                    });
                    TagManager.dataLayer({
                        dataLayer: {
                            user_id: data?.data?.user_info?.id,
                            signup_mode:
                                data?.data?.user_info?.signup_mode == 1
                                    ? "Mode 1"
                                    : "Mode 2",
                            login_source: "login_form",
                            event: "login_successfull",
                        },
                    });
                    mixpanel.track("Login", { "Login Type": "Manual" });
                    if (
                        !data.data.user_info.ai_tour ||
                        !data.data.user_info.seo_tour
                    )
                        dispatch(
                            updateUserInfo("tour", {
                                enable: true,
                                step: 2,
                                new_user: false,
                            }),
                        );
                    dispatch(
                        authSuccess(
                            data.data.access_token,
                            data.data.user_info,
                            false,
                        ),
                    );
                    localStorage.setItem("token", data.data.access_token);
                }
                if (status === 201) {
                    dispatch(authUnauthorized(data?.message));
                }
                if (status == 202) {
                    dispatch(VerificationEmail({ email, password }));
                }
            })
            .catch((err) => {
                if (err) {
                    if (
                        (err && err.response && err.response.status === 401) ||
                        (err && err.response && err.response.status === 429)
                    ) {
                        dispatch(authUnauthorized(err.response?.data?.message));
                        dispatch(showLoginPage(email));
                    }
                    if (err && err.response && err.response.status === 400) {
                        dispatch(authFail(""));
                    }
                } else {
                    dispatch(authFail(""));
                }
            });
    };

// export const updateData = (token) => (dispatch) => {};

export const checkLoggedIn = (set) => (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
        dispatch(authSuccess(token, {}, false, true));
        var config = {
            method: "get",
            url: url + "/api/user/loginViaToken",
            headers: {
                Authorization: token,
            },
        };

        axios(config)
            .then(({ data, status }) => {
                if (status === 200) {
                    dispatch(
                        authSuccess(token, data.data.user_info, false, false),
                    );
                    set(false);
                }
            })
            .catch((err) => {
                dispatch(userLogout(err));
                set(false);
            });
    } else {
        set(false);
    }
};

const getIsMobile = () => {
    let mobile = false;
    if (
        window.navigator.userAgentData &&
        window.navigator?.userAgentData?.mobile
    ) {
        mobile = true;
    } else if (window.outerHeight > window.outerWidth) {
        mobile = true;
    }

    return mobile ? "mobile" : "laptop";
};

//REGISTER
export const registerUser = (req, id) => async (dispatch, getState) => {
    dispatch(authStart(false, null));

    const { country: country_name } = getState().auth;

    let browserName = getBrowserName();
    const userInfo = {
        country_name,
        browser: browserName,
        os: window.navigator.platform,
        device_type: getIsMobile(),
    };

    var data = qs.stringify({
        email: req.email,
        name: req.name,
        country: req.country ? req.country : null,
        phone: req.phone,
        password: req.password,
        coupon_code: req.coupon,
        signup_mode: req.signup_mode,
        referCode: req.referCode,
        ...userInfo,
        ...req.queryEntries,
    });
    let register_url = url + "/api/user/register/";

    var config = {
        method: "put",
        url: register_url,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            ...(req.tokenFrom201 ? { Authorization: req.tokenFrom201 } : {}),
            ...device_token,
        },
        data: data,
    };
    dispatch(authStart(false, null));
    return await axios(config)
        .then(({ data, status }) => {
            dispatch(authFail(""));
            dispatch(clearError());
            if (status === 200) {
                Sentry.setTag("new-user", "1");
                if (req.coupon) {
                    req.context = req.context + "-lifetime";
                }
                dispatch(
                    authSuccess(
                        data.data.access_token,
                        { ...data.data.user_info },
                        true,
                    ),
                );
                dispatch(
                    updateUserInfo("tour", {
                        enable: true,
                        step: 1,
                        new_user: true,
                    }),
                );
                localStorage.setItem("token", data.data.access_token);
            } else if (status == 202) {
                dispatch(
                    VerificationEmail({
                        email: req.email,
                        password: req.password,
                    }),
                );
            } else if (status == 203) {
                return { ...data.data, status };
            }

            trackRegister({
                email: req.email,
                name: req.name,
                phone: req.phone,
                queryEntries: req.queryEntries,
                user_id: data?.data?.user_info?.id,
                fromGoogle: req.fromGoogle,
                googleOneTap: req?.googleOneTap,
                mode: data?.data?.user_info?.signup_mode == 2 ? 2 : 1,
                segment: data?.data?.user_info?.segment || 2,
            });
        })
        .catch((error) => {
            dispatch(clearError());
            if (error) {
                if (
                    (error &&
                        error.response &&
                        error.response.status === 400) ||
                    (error && error.response && error.response.status === 429)
                ) {
                    dispatch(authFail(error.response.data.message));
                } else {
                    dispatch(
                        authFail(
                            error &&
                                error.response &&
                                error.response.statusText,
                        ),
                    );
                }
            }
        });
};

//VERIFY TEAM REGISTER
export const verifyTeamRegister = (req, id) => (dispatch) => {
    dispatch(authStart(false, null));
    // console.log(req, id);
    var data = qs.stringify({
        email: req.email,
        emailToken: req.emailToken,
        companyId: req.companyId,
        password: req.password,
        name: req.name,
    });
    let register_url = url + "/api/invite/verify";

    var config = {
        method: "post",
        url: register_url,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
    };
    dispatch(authStart(false, null));
    axios(config)
        .then(({ data, status }) => {
            if (status === 200) {
                dispatch(
                    authSuccess(
                        data.data.access_token,
                        { ...data.data.user_info },
                        true,
                    ),
                );
                dispatch(
                    updateUserInfo("tour", {
                        enable: true,
                        step: 1,
                        new_user: true,
                    }),
                );
                localStorage.setItem("token", data.data.access_token);
            }
        })
        .catch((error) => {
            dispatch(clearError());
            if (error) {
                if (error && error.response && error.response.status === 400) {
                    dispatch(authFail(error.response.data.message));
                } else {
                    dispatch(
                        authFail(
                            error &&
                                error.response &&
                                error.response.statusText,
                        ),
                    );
                }
            }
        });
};

export const googleLogin =
    (e, signup_mode, referCode, queryEntries) => async (dispatch, getState) => {
        dispatch(authStart(true, null));
        const { country: country_name } = getState().auth;

        let browserName = getBrowserName();
        const userInfo = {
            country_name,
            browser: browserName,
            os: window.navigator.platform,
            device_type: getIsMobile(),
        };
        return await axios({
            method: "post",
            url: url + "/api/user/loginGoogle",
            data: {
                tokenId: e.tokenId,
                queryContext: queryEntries["context"],
                queryUtmMedium: queryEntries["utm_medium"],
                queryUtmSource: queryEntries["utm_source"],
                queryUtmCampaign: queryEntries["utm_campaign"],
                ...userInfo,
                ...queryEntries,
            },
        })
            .then(async ({ data, status }) => {
                if (data.statusCode === 200) {
                    let obj = {
                        Name: data.data.user_info?.name,
                        $email: data.data.user_info?.email,
                        "Last Login": Date(),
                    };
                    mixpanel.register({
                        ...obj,
                        "First login": data.data.user_info?.user_created_at,
                        "Signup mode":
                            data?.data.user_info?.signup_mode == 1
                                ? "Mode 1"
                                : "Mode 2",
                        from_google: true,
                    });
                    mixpanel.identify(data.data.user_info?.email);
                    mixpanel.people.set({
                        $name: data.data.user_info?.name,
                        $email: data.data.user_info?.email,
                        "First Login": data.data.user_info?.user_created_at,
                        "Plan Type": "-",
                        "Signup Mode":
                            data?.data.user_info?.signup_mode == 1
                                ? "Mode 1"
                                : "Mode 2",
                    });
                    mixpanel.track("Login", {
                        "Login Type": e.googleOneTap
                            ? "Google One Tap"
                            : "Google Button",
                    });
                    dispatch(authFail(""));
                    dispatch(
                        authSuccess(
                            data.data.access_token,
                            data.data.user_info,
                            false,
                        ),
                    );
                    localStorage.setItem("token", data.data.access_token);
                    TagManager.dataLayer({
                        dataLayer: {
                            user_id: data?.data?.user_info?.id,
                            signup_mode:
                                data?.data?.user_info?.signup_mode == 1
                                    ? "Mode 1"
                                    : "Mode 2",
                            login_source: "google",
                            event: "login_successfull",
                        },
                    });
                } else if (data.statusCode === 201) {
                    if (e.googleOneTap)
                        mixpanel.track("Signup", {
                            "Signup Type": e.googleOneTap
                                ? "Google One Tap"
                                : "Google",
                            "UTM Source": queryEntries["utm_source"]
                                ? queryEntries["utm_source"]
                                : "Manual",
                        });
                    await dispatch(
                        showRegisterPage(
                            "",
                            data.data.access_token,
                            null,
                            queryEntries,
                        ),
                    );
                    return await dispatch(
                        registerUser({
                            ...data.data.user_info,
                            tokenFrom201: data.data.access_token,
                            signup_mode: signup_mode,
                            fromGoogle: true,
                            googleOneTap: e.googleOneTap,
                            referCode,
                            queryEntries,
                        }),
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                if (error) {
                    if (
                        error &&
                        error.response &&
                        error.response.status === 400
                    ) {
                        dispatch(authFail(error.response.data.message));
                    } else {
                        dispatch(
                            authFail(
                                error &&
                                    error.response &&
                                    error.response.statusText,
                            ),
                        );
                    }
                }
            });
    };

function trackRegister(req) {
    TagManager.dataLayer({
        dataLayer: {
            user_id: req.user_id,
            signup_mode: req.signup_mode == 2 ? "Mode 2" : "Mode 1",
            login_source: req.fromGoogle ? "google" : "login_form",
            event: "signup_successfull",
        },
    });

    if (req.fromGoogle) {
        TagManager.dataLayer({
            dataLayer: {
                user_id: req.user_id,
                event: "verified_signup_successful",
            },
        });
    }

    mixpanel.reset();
    mixpanel.register({
        $email: req.email,
        "UTM Source": req?.queryEntries?.utm_source
            ? req.queryEntries.utm_source
            : "Manual",
        "Signup mode": req.signup_mode == 2 ? "Mode 2" : "Mode 1",
        $name: req.name,
        "Signup date": Date(),
    });
    mixpanel.alias(req.email);
    mixpanel.track("Signup", {
        ...(req.fromGoogle
            ? {
                  from_google: true,
                  "Signup Type": req.googleOneTap
                      ? "Google One Tap"
                      : "Google Button",
              }
            : {}),
    });

    if (req.fromGoogle) {
        mixpanel.track("Verify", { from_google: true, "User ID": req.user_id });
    }

    if (PROD) {
        if (window.fpr) window.fpr("referral", { email: req.email });
        window.gr && window.gr("track", "conversion", { email: req.email });
        let userComData = {
            apiKey: USER_WIDGET_KEY,
            user_id: req.user_id,
            name: req.name, // Full name
            email: req.email, // Email address
            created_at: +new Date() / 1000,
            phone: req.phone,
            ...(req?.queryEntries || {}),
        };

        pageHit({
            ...userComData,
        });

        emitEvent("new_user", req.email, req.mode, req.segment);
        if (req.fromGoogle) {
            emitEvent("signed_up", req.email, req.mode, req.segment);
        }
    }
}
