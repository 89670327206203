import React, { useState } from "react";
import styles from "./styles.module.css";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import Label from "../Label/Label";
import WordCounter from "../WordCounter/WordCounter";
import { useSelector } from "react-redux";
import { isMobile } from "../../../utility/helperFun";

export function errorMessage(errMsg) {
    return <div className={styles.errMsg}>{errMsg}</div>;
}

function successMessage(errMsg) {
    return (
        <div style={{ color: "green" }} className={styles.errMsg}>
            {errMsg}
        </div>
    );
}

function showIcon(Icon, type, passwordViewer, visible, iconStyle) {
    if (type == "password")
        return (
            <span onClick={passwordViewer} className={styles.rightIcon}>
                {visible ? (
                    <AiFillEyeInvisible size={20} />
                ) : (
                    <AiFillEye size={20} />
                )}
            </span>
        );

    if (Icon) {
        return (
            <span className={styles.rightIcon} style={{ ...iconStyle }}>
                <Icon />
            </span>
        );
    }
}

function showCounter(counter, _counter, maxLength) {
    if (counter) return <WordCounter maxLength={maxLength} length={_counter} />;
    else return null;
}

/**
 * @param {import("react").InputHTMLAttributes}
 
 *  */

function Textfield({
    height = 40,
    placeholder = "Type here",
    autoFocus = false,
    errMsg = null,
    successMsg = null,
    validatorFun = () => {
        error: false;
    },
    Icon = () => {},
    type = "text",
    width = "100%",
    label = "Label",
    counter = false,
    maxLength = 1000,
    props = {},
    border = "",
    noItalics = false,
    customClass = "",
    iconStyle = {},
    containerStyle = {},
    IconRight = null,
    onKeyPress = () => {},
    customLabelClass = "",
    pattern = "",
    ...rest
}) {
    const [visible, setVisible] = useState(false);
    const [_counter, setCounter] = useState(0);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    function passwordViewer() {
        setVisible(!visible);
    }
    function getType(type) {
        if (type != "password") return type;
        else {
            return visible ? "text" : "password";
        }
    }

    autoFocus = isLoggedIn && isMobile() ? false : autoFocus;
    return (
        <div
            style={{
                width,
                position: "relative",
                marginBottom: "10px",
                ...containerStyle,
            }}
        >
            {label && (
                <Label
                    label={label}
                    className={styles.label + " " + customLabelClass}
                ></Label>
            )}
            <div
                style={{ height }}
                className={styles.inputContainer + " " + customClass}
            >
                {showIcon(Icon, type, passwordViewer, visible, iconStyle)}
                <input
                    spellCheck={false}
                    className={noItalics && styles.noItalics}
                    {...props}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    onInput={(e) => setCounter(e.target.value.length)}
                    type={getType(type, visible)}
                    placeholder={placeholder}
                    style={{ border, ...props.style }}
                    onKeyPress={onKeyPress}
                    {...(pattern ? { pattern } : {})}
                    {...rest}
                />
                {showCounter(counter, _counter, maxLength)}
                {showIcon(IconRight, type, passwordViewer, visible, {
                    right: 5,
                    left: "unset",
                })}
            </div>
            {!!successMsg && successMessage(successMsg)}
            {!!errMsg && errorMessage(errMsg)}
        </div>
    );
}

export default Textfield;
