import React from "react";
import { Redirect, useParams } from "react-router-dom";

function IndividualReportRedirect({ auditMode }) {
    const { id, access } = useParams();

    return auditMode ? (
        <Redirect to={`/audit/editor/${id}/${access}`} />
    ) : (
        <Redirect to={`/creator/editor/${id}/${access}`} />
    );
}

export default IndividualReportRedirect;
