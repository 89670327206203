import React, { useCallback, useEffect, useRef, useState } from "react";
import useHelperFunctions from "../../helperFunctions";

import {
    GET_SEO_DOCUMENTS,
    DELETE_REPORT,
    RESTORE_REPORT,
} from "../../api.json";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { displayAlert } from "../../../store/actions/alert";
import { debounceWrapper, formatURL } from "../../../utility/helperFun";
import { is_completed } from "../../organisms/DocCard/constants";
import { getPastAsTime } from "../../../utility/validateFunctions";
import { socket } from "../../../Sockets/useSockets";
import { socketEvents } from "../../../Sockets/constants";
import { useDispatch } from "react-redux";
let apiCalls = 0;
const initialLoad = {
    page: 1,
    updated: false,
    created: true,
    tags: [],
    allTags: false,
    search: "",
    is_deleted: false,
    grade: 0,
    seo_score: 0,
    order: 0,
    activity_status: 0,
};

function userDocsHook({ bin, doc_type }) {
    const [loading, setLoading] = useState(false);
    const [loadingCreation, setLoadingCreation] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [docs, setDocs] = useState([]);
    const [requestData, setRequestData] = useState({
        ...initialLoad,
        doc_type: doc_type,
    });
    const [polling, setPolling] = useState(false);
    const goto = useNavigation();
    const { getData, postData } = useHelperFunctions();
    let [interval] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        const successCallback = (data) => {
            setDocs((docs) => {
                let updatedDocs = docs.map((doc) => {
                    window.localStorage.removeItem("dm");
                    if (data.report_id == doc.report_id || doc?.is_audit) {
                        doc.report_id = data.report_id;
                        doc.status = 1;
                        doc.is_completed = 1;
                        doc.seo_score = parseInt(data.seo_score, 10);
                        doc.word_count = data.word_count;
                        doc.activity_status = data.activity_status;
                        if (doc?.is_audit) {
                            delete doc?.is_audit;
                        }
                    }
                    setLoadingCreation(false);
                    return doc;
                });

                return updatedDocs;
            });
        };
        const failedCallback = (data) => {
            setDocs((docs) => {
                let updatedDocs = docs.map((doc) => {
                    if (data.report_id == doc.report_id) {
                        doc.status = 1;
                        doc.is_completed = 2;
                    }
                    setLoadingCreation(false);
                    return doc;
                });

                return updatedDocs;
            });
        };

        const attachSockets = () => {
            socket.on(socketEvents.REPORT_CREATED, successCallback);
            socket.on(socketEvents.REPORT_FAILED, failedCallback);
        };

        const removeSockets = () => {
            socket.off(socketEvents.REPORT_CREATED, successCallback);
            socket.off(socketEvents.REPORT_FAILED, failedCallback);
        };

        attachSockets();

        return () => {
            apiCalls = 0;
            removeSockets();
        };
    }, []);

    const getReports = useCallback(async (requestData) => {
        const {
            page,
            updated,
            created,
            tags,
            allTags,
            search,
            is_deleted,
            order,
            grade,
            seo_score,
            doc_type,
            activity_status,
            activity_status_filter,
        } = requestData;

        if (page == 1) {
            setLoading(true);
        }
        apiCalls += 1;
        const res = await getData({
            url: `${GET_SEO_DOCUMENTS}?page=${page}&order=${order}&doc_type=${doc_type}&activity_status=${activity_status}&seo_score=${seo_score}&created_at=${
                created ? 1 : 0
            }&tags=${JSON.stringify(
                tags,
            )}&activity_status_filter=${JSON.stringify(
                activity_status_filter || [],
            )}&search_text=${search}&updated_at=${updated ? 1 : 0}&all_tags=${
                allTags ? 1 : 0
            }&is_deleted=${is_deleted ? 1 : 0}`,
        });

        if (res.status == 200) {
            if (res.data.data.length < 20) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
            if (requestData.page == 1) {
                let arr = [...res.data.data];
                const dmReport = JSON.parse(window.localStorage.getItem("dm"));
                if (
                    dmReport?.is_audit &&
                    res.data.data[0]?.is_completed != 0 &&
                    res.data.data[0]?.audit_url
                ) {
                    arr = [{ ...dmReport }, ...arr];
                    window.localStorage.removeItem("dm");
                }
                setDocs([...arr]);
                if (
                    res.data.data[0] &&
                    res.data.data[0].is_completed == is_completed.inProgress
                ) {
                    setLoadingCreation(true);

                    setPolling(true);
                }
            } else setDocs((ps) => ps.concat(res.data.data));

            setLoading(false);
        } else {
            setLoading(false);
        }
    }, []);
    let timeoutRef = useRef(null);

    useEffect(() => {
        function req() {
            getReports(requestData);
        }

        debounceWrapper(req, 400, timeoutRef);
    }, [requestData]);

    function openDoc(id, editor, auditMode = false) {
        console.log("cluster id", id);
        if (id)
            goto(
                auditMode
                    ? "AUDIT_EDITOR"
                    : editor
                    ? "SEO_EDITOR"
                    : "SEO_REPORT",
                [],
                id,
            );
        else {
            dispatch(displayAlert({ alertMessage: "Report id not found" }));
        }
    }

    async function deleteReport(id) {
        const res = await postData({
            url: DELETE_REPORT,
            payload: {
                report_id: id,
            },
        });

        if (res.status == 200) {
            return true;
        } else {
            return false;
        }
    }

    async function restoreReport(id) {
        await postData({
            url: RESTORE_REPORT,
            payload: {
                report_id: id,
            },
        });
    }

    const makeReport = async ({
        e,
        inputValue,
        keywords,
        country,
        setInputValue,
        setKeywords,
        url,
        audit,
        competitors_count,
        is_premium,
    }) => {
        console.log("======...creport", inputValue, keywords);
        if ((!inputValue && !keywords.length) || !country) {
            return;
        }

        e.preventDefault();
        setLoadingCreation(true);
        const theDate = new Date(+new Date() - 1000 * 30);
        let tempKeyArr = [];
        if (keywords.length > 1) {
            for (let i = 1; i < keywords.length; i++) {
                tempKeyArr.push(keywords[i]);
            }
        }
        const dummyReport = {
            search_keyword: keywords?.length ? keywords[0] : inputValue,
            is_completed: 0,
            is_premium: is_premium,
            is_audit: audit ? true : false,
            content_updated_at: getPastAsTime(theDate),
            created_at: getPastAsTime(theDate),

            search_country: country.label,
            dummyReport: true,
            seo_score: 0,
            activity_status: 0,
            ...(audit && tempKeyArr?.length > 0
                ? {
                      secondary_keywords: JSON.stringify(tempKeyArr),
                  }
                : {}),
            ...(audit && {
                audit_url:
                    url.indexOf("https://") == 0 || url.indexOf("http://") == 0
                        ? url
                        : url.length > 0
                        ? `https://${url}`
                        : "",
            }),
        };
        if (audit)
            window.localStorage.setItem("dm", JSON.stringify(dummyReport));

        if (audit) {
            setDocs((docs) => [
                {
                    ...dummyReport,
                    is_completed: 0,
                },
                ...docs,
            ]);
        }
        const res = await postData({
            url: `/api/keywords/create`,

            payload: {
                keyword: keywords?.length ? keywords : inputValue,
                country: country.label,
                ...(audit && {
                    url: url.length > 0 ? formatURL(url) : "",
                }),
                ...(audit && { doc_type: 2 }),
                competitors_count,
                is_premium,
            },
        });

        if (res.status == 200) {
            window.localStorage.removeItem("dm");
            if (!audit) {
                setDocs((docs) => [
                    {
                        ...dummyReport,
                        is_completed: 0,
                        report_id: res?.data?.report_id,
                        secondary_keywords: res.data?.secondaryKeywords,
                    },
                    ...docs,
                ]);
            }
        }
        if (res.status != 200) {
            window.localStorage.removeItem("dm");
            if (audit) {
                setDocs((docs) => {
                    const temp = docs.map((doc) => {
                        if (doc?.is_audit && doc?.is_completed == 0) {
                            doc.is_completed = 2;
                            doc.report_id = -1;
                            doc.msg = res.response.data.message;
                        }
                        return doc;
                    });
                    return temp;
                });
                setTimeout(() => {
                    setDocs((docs) => {
                        let temp = [...docs];
                        const index = docs.findIndex((doc) => doc?.is_audit);
                        temp.splice(index, 1);
                        return temp;
                    });
                }, 5000);
            }
            setLoadingCreation(false);
        }
        setInputValue("");
        setKeywords([]);
    };

    // const pollingRequest = async () => {
    //   // const res = await getData({ url: "/api/scrape/getInProgressReports" });

    //   if (res.status == 200) {
    //     setPolling(false);

    //     let idsInProgress = res.data.data.inProgressReports.map((item) => {
    //       item.report_id;
    //     });
    //   }
    // };

    return {
        loading,
        docs,
        openDoc,
        getReports,
        setRequestData,
        requestData,
        hasMore,
        deleteReport,
        restoreReport,
        setLoading,
        makeReport,
        loadingCreation,
        apiCalls,
    };
}

export default userDocsHook;
