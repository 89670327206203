import React from "react";
import { GoCheck } from "react-icons/go";
import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";
import Spacer from "../../atoms/Spacer/Spacer";
import styles from "./FirstTimeBanner.module.css";

export default function FirstTimeBanner({
  title,
  coloredTitle,
  subheading,
  list,
  buttonText,
  buttonIcon,
  handler,
  image,
  buttonDisable = false,
  buttonWidth = "200px",
}) {
  return (
    <div className={styles.firstTimeBannerContainer}>
      <div className={styles.firstTimeBanner}>
        <div className={styles.left}>
          <h1 className={styles.title}>
            {title}
            <span className={styles.coloredTitle}> {coloredTitle}</span>
          </h1>
          <p className={styles.subheading}>{subheading}</p>
          <li className={styles.listItems}>
            {list.map((item) => (
              <ul className={styles.listItem}>
                <GoCheck
                  style={{
                    color: "#597ef7",
                    fontSize: "1rem",
                    marginRight: "7px",
                  }}
                />{" "}
                {item}
              </ul>
            ))}
          </li>
          <Spacer spacing="30px" />
          <Button
            Icon={buttonIcon}
            handler={handler}
            text={buttonText}
            width={buttonWidth}
            disabled={buttonDisable}
          />
        </div>
        <div className={styles.mainImg}>
          <Image src={image} />
        </div>
      </div>
    </div>
  );
}
