import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Button from "../../atoms/Button/Button";
import InviteMembers from "./InviteMembers";
import ListMembers from "./ListMembers";
import styles from "./styles.module.css";
import useTeam from "./useTeam";
import { MdAdd } from "react-icons/md";
import Modal from "../../atoms/Modal/Modal";
import InviteLimit from "./InviteLimit";
import DeleteMember from "./DeleteMember";
import { emailValidatorS } from "../../../utility/validateFunctions";
import InviteSuccess from "./InviteSuccess";
import AddNewTag from "./AddNewTag";
import { BsFillPersonPlusFill, BsPersonPlus } from "react-icons/bs";
import { RiUserAddFill } from "react-icons/ri";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { useSelector } from "react-redux";
import { PLANS_CATEGORIES } from "../../../constants";
import { useRef } from "react";
import { TagsContext } from "../../Pages/TagsContext";

function AccountTeam() {
    const {
        members,
        sendInvite,
        setInvited,
        invited,
        loading,
        removeMember,
        removeTag,
        addTag,
        inviteErr,
        setInviteErr,
        inviteLimitErr,
        setInviteLimitErr,
        allDomains,
        setAllDomains,
    } = useTeam({ onlyTags: false });

    const { tags, createTag } = useContext(TagsContext);

    const [inviteModal, setInviteModal] = useState(false);
    const ref = useRef();
    const max_members = useSelector(
        (state) => state.otherInfo?.subscriptionInfo?.total_seats,
    );
    const planCatetgory = useSelector(
        (state) => state.otherInfo?.subscriptionInfo?.plan_category,
    );

    const planType = useSelector(
        (state) => state.otherInfo?.subscriptionInfo?.type,
    );
    const [deleteModal, setDeleteModal] = useState(false);
    const [inviteSuccessModal, setInviteSuccessModal] = useState(false);
    const [userToBeRemoved, setUserToBeRemoved] = useState({});
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const validMembers = members.filter((member) => member.status <= 2);
    function openInviteModal() {
        if (
            (planCatetgory != PLANS_CATEGORIES.PRO &&
                planCatetgory != PLANS_CATEGORIES.PRO_MAX &&
                planType != 3) ||
            planCatetgory == PLANS_CATEGORIES.FREE_FOREVER
        ) {
            if (planCatetgory == PLANS_CATEGORIES.GROWTH && max_members == 0) {
                return setInviteLimitErr("upgrade");
            }
            if (
                planCatetgory == PLANS_CATEGORIES.GROWTH &&
                max_members > validMembers.length
            ) {
                return setInviteModal(true);
            }
            return setInviteLimitErr("upgrade");
        }

        if (max_members == validMembers.length && planType == 3)
            return setInviteLimitErr("buySeats");

        if (max_members == validMembers.length)
            return setInviteLimitErr("buySeat");

        setInviteModal(true);
        console.log("aa");
    }

    useEffect(() => {
        if (inviteSuccessModal || inviteLimitErr) setInviteModal(false);
    }, [inviteSuccessModal, inviteLimitErr]);

    useEffect(() => {
        setInviteLimitErr(false);
    }, [inviteModal]);

    const available_seats = max_members - validMembers.length;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>Team</div>

                <div className={styles.addicon}>
                    <LightTooltip
                        placement="bottom"
                        arrow
                        title="Invite team members"
                    >
                        <div
                            className={styles.userProfile__editIconContainer}
                            onClick={() => openInviteModal()}
                        >
                            <span className={styles.inviteText}>
                                Invite More
                            </span>
                            <RiUserAddFill color={"#014dc5"} size={"20px"} />
                        </div>
                    </LightTooltip>
                    {!loading &&
                        !!max_members &&
                        (planCatetgory == PLANS_CATEGORIES.PRO ||
                            planCatetgory == PLANS_CATEGORIES.PRO_MAX) &&
                        planType != 3 && (
                            <span className={styles.available_seats}>
                                (
                                {`Available addtional ${"seats "}: ${available_seats}`}
                                )
                            </span>
                        )}
                </div>
            </div>
            {inviteModal && (
                <Modal setModal={loading ? () => {} : setInviteModal}>
                    <InviteMembers
                        loading={loading}
                        inviteErr={inviteErr}
                        setInviteErr={setInviteErr}
                        sendInvite={sendInvite}
                        invited={invited}
                        email={email}
                        setEmail={setEmail}
                        setInvited={setInvited}
                        members={validMembers}
                        setInviteModal={setInviteModal}
                        setInviteSuccessModal={setInviteSuccessModal}
                        tags={tags}
                        createTag={createTag}
                        name={name}
                        setName={setName}
                        allDomains={allDomains}
                        setAllDomains={setAllDomains}
                    />
                </Modal>
            )}
            {deleteModal && (
                <Modal setModal={loading ? () => {} : setDeleteModal}>
                    <DeleteMember
                        loading={loading}
                        emailToBeRemoved={userToBeRemoved.email}
                        nameToBeRemoved={userToBeRemoved.name}
                        removeMember={() =>
                            removeMember(userToBeRemoved.user_id)
                        }
                        setdeleteModal={setDeleteModal}
                    />
                </Modal>
            )}

            {inviteLimitErr && (
                <Modal setModal={setInviteLimitErr}>
                    <InviteLimit
                        inviteLimitErr={inviteLimitErr}
                        setInviteLimitErr={setInviteLimitErr}
                        setInviteModal={setInviteModal}
                    />
                </Modal>
            )}

            {inviteSuccessModal && (
                <Modal setModal={setInviteSuccessModal}>
                    <InviteSuccess
                        name={name}
                        setName={setName}
                        email={email}
                        setEmail={setEmail}
                        setInviteSuccessModal={setInviteSuccessModal}
                    />
                </Modal>
            )}

            <ListMembers
                removeMember={(member) => {
                    setDeleteModal(true);
                    setUserToBeRemoved(member);
                }}
                members={members}
                loading={loading}
                addTag={addTag}
                removeTag={removeTag}
                tags={tags}
                createTag={createTag}
                allDomains={allDomains}
                setAllDomains={setAllDomains}
            />
            <div ref={ref}></div>
        </div>
    );
}

export default AccountTeam;
