import React from "react";
import { useSelector } from "react-redux";
import { PLANS_CATEGORIES } from "../../constants";

export const FEATURE_TYPES = {
    cruiseModeLock: "cruise-mode",
    instructLock: "instruct",
    aiSettingLock: "ai-settings",
    serpIdeaLock: "serp-ideas",
    rephraseMiniToolLock: "rephrase-mini-tool",
    appIntegrationLock: "app-integration",
    topicClusterLock: "topic-cluster",
    contentAuditLock: "content-audit",
    inventoryLock: "traffic-inventory",
    cannibalizationLock: "cannibalizationLock",
    competitorGap: "competitorGap",
};

function useUserAccessHook() {
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );
    const { removeAllLocks } = useSelector((state) => state.appConfig);

    const otherInfo = useSelector((state) => state.otherInfo);

    const planTrial = otherInfo?.subscriptionInfo?.trial_status == 1;

    const isLTD =
        otherInfo?.subscriptionInfo?.type == 3 &&
        otherInfo?.subscriptionInfo?.plan_category !=
            PLANS_CATEGORIES.FREE_FOREVER;

    const isTeamMember = company_user_type == 2;

    const isFreeForever =
        otherInfo?.subscriptionInfo?.plan_category ==
        PLANS_CATEGORIES.FREE_FOREVER;

    const isEssential =
        otherInfo?.subscriptionInfo?.plan_category == PLANS_CATEGORIES.EXPERT;

    const trialUser =
        otherInfo?.subscriptionInfo?.plan_category == PLANS_CATEGORIES.TRAIL;

    const freeUser = isFreeForever || trialUser;

    const individualUser =
        PLANS_CATEGORIES.INDIVIDUAL ==
            otherInfo?.subscriptionInfo?.plan_category && !isLTD;

    const planExpiresDate = otherInfo?.subscriptionInfo?.expiration_at;

    //method to return true or false for respective feature
    function LockConfigurator(feature) {
        //false : locks off
        //true : locks on

        if (isTeamMember || isLTD || trialUser) {
            return false;
        }
        if (!!+removeAllLocks) {
            //if config says remove all locks
            return false;
        }
        switch (feature) {
            case FEATURE_TYPES.cruiseModeLock:
            case FEATURE_TYPES.instructLock:
            case FEATURE_TYPES.aiSettingLock:
            case FEATURE_TYPES.serpIdeaLock:
            case FEATURE_TYPES.rephraseMiniToolLock:
                //if plan_category doesn't includes, then return true for lock
                return ![
                    PLANS_CATEGORIES.EXPERT,
                    PLANS_CATEGORIES.GROWTH,
                    PLANS_CATEGORIES.PRO,
                    PLANS_CATEGORIES.PRO_MAX,
                    PLANS_CATEGORIES.GROWTH_MAX,
                    PLANS_CATEGORIES.INDIVIDUAL,
                ].includes(otherInfo?.subscriptionInfo?.plan_category);
            case FEATURE_TYPES.inventoryLock:
                return ![
                    PLANS_CATEGORIES.GROWTH,
                    PLANS_CATEGORIES.PRO,
                    PLANS_CATEGORIES.GROWTH_MAX,
                    PLANS_CATEGORIES.PRO_MAX,
                    PLANS_CATEGORIES.TRAIL,
                ].includes(otherInfo?.subscriptionInfo?.plan_category);
            case FEATURE_TYPES.cannibalizationLock:
                return ![
                    PLANS_CATEGORIES.PRO,
                    PLANS_CATEGORIES.PRO_MAX,
                    PLANS_CATEGORIES.TRAIL,
                ].includes(otherInfo?.subscriptionInfo?.plan_category);
            case FEATURE_TYPES.appIntegrationLock:
            case FEATURE_TYPES.topicClusterLock:
            case FEATURE_TYPES.contentAuditLock:
                return ![
                    PLANS_CATEGORIES.GROWTH,
                    PLANS_CATEGORIES.PRO,
                    PLANS_CATEGORIES.GROWTH_MAX,
                    PLANS_CATEGORIES.PRO_MAX,
                ].includes(otherInfo?.subscriptionInfo?.plan_category);
            default:
                return false;
        }
    }

    return {
        isLTD,
        isFreeForever,
        isEssential,
        isTeamMember,
        trialUser,
        freeUser,
        individualUser,
        planExpiresDate,
        plan_category: otherInfo?.subscriptionInfo?.plan_category,
        plan_type: otherInfo?.subscriptionInfo?.type,
        isLoaded: otherInfo.isLoaded,
        LockConfigurator,
        subscriptionInfo: otherInfo?.subscriptionInfo,
        planTrial,
    };
}

export default useUserAccessHook;
