import React, { useState, useEffect, useContext } from "react";
import styles from "./FixItModule.module.css";
import { CircularProgress } from "@material-ui/core";
import SVGIcon from "../../../atoms/SVGIcon";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

import { FixItSections } from "./fixitConstants";
import { AiOutlineFileSearch, AiOutlineInfoCircle } from "react-icons/ai";
import { VscClose } from "react-icons/vsc";
import LoadingEllipsis from "../../../atoms/LoadingEllipsis/LoadingEllipsis";
import FixItCards from "./FixItCards";
import { Skeleton } from "@material-ui/lab";
import { BsDownload, BsPatchCheckFill } from "react-icons/bs";
import Button from "../../../atoms/Button/Button";
import UserFeedback from "../../UserFeedback/UserFeedback";
import ReportContext from "../../Research/ReportContext";
import Quill from "quill";
import GscLoginBar from "../../../Pages/NewDashboard/GscLoginBar";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { exportCSVFile } from "../../../../components/superAdmin/csvCoverter";
const Delta = Quill.import("delta");
let isSubmit = false;

const FixItModule = ({
    setIsFixItEnabled = () => {},
    isOn = false,
    section = {},
    sectionData = {},
    seoScore = 0,
    isLoadingFixIt = false,
    setIsLoadingFixIt = () => {},
    isLoadingKTAssistant = false,
    suggestions = [],
    fixItEditorRef = null,
    setEnableFixItEditor = () => {},
    optimiserLoading = false,
    setSuggestions = () => {},
    setMetaFixIt = () => {},
    setMetaData = () => {},
    optimiserData,
    setEnableFixIt = () => {},
    realtimeRating,
    interlinkingMixpanelData,
}) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isDoneDisabled, setIsDoneDisabled] = useState(false);
    const [titleH1, setTitleH1] = useState("");
    const [newH1, setNewH1] = useState("");
    const [isH1Fixed, setIsH1Fixed] = useState(false);
    const [originalPara, setOriginalPara] = useState("");
    const [optimiserSectionData, setOptimiserSectionData] = useState({});
    const [fixedCount, setFixedCount] = useState(0);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [feedbackClose, setFeedbackClose] = useState(true);
    const reportData = useContext(ReportContext);
    const [currentDiffText, setCurrentDiffText] = useState("");
    const [numberofInterlinks, setNumberOfInterlinks] = useState(0);
    const { reportInfo } = useContext(ReportContext);

    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        setShowEditorButton(false);
        if (fixedCount === suggestions?.length && fixedCount > 0) {
            setShowEditorButton(true);
        }
        if (
            section.panelName == FixItSections.LINKS &&
            (numberofInterlinks >= realtimeRating.wordCount / 500 ||
                (fixedCount === suggestions?.length && fixedCount > 0))
        ) {
            setShowEditorButton(true);
        }
    }, [fixedCount, suggestions, numberofInterlinks]);

    useEffect(() => {
        isSubmit = false;
        setTimeout(
            () => {
                if (!isSubmit) {
                    setFeedbackClose(false);
                }
            },
            section.panelName == FixItSections.LINKS ? 5000 : 20000,
        );
        setNumberOfInterlinks(
            document.quill.editor?.root?.getElementsByTagName("a")?.length,
        );
    }, []);

    useEffect(() => {
        if (!isLoadingFixIt && section.panelName == FixItSections.LINKS) {
            mixpanelTrack("Interlinking suggestions appeared", {
                "Number of Seggestions": suggestions.length,
                report_id: reportInfo.report_id,
                word_count: realtimeRating.wordCount,
                "Current & Suggested Links": `${numberofInterlinks} / ${parseInt(
                    realtimeRating.wordCount / 500,
                )}`,
                ...interlinkingMixpanelData,
            });
        }
    }, [isLoadingFixIt]);

    useEffect(() => {
        if (
            (section.panelName == FixItSections.KEY_TERMS ||
                section.panelName == FixItSections.LINKS) &&
            suggestions.length > 2 &&
            currentIndex == suggestions.length
        ) {
            clearTimeout();
            setTimeout(() => {
                if (!isSubmit) {
                    setFeedbackClose(false);
                }
            }, 2000);
        }
    }, [currentIndex]);

    useEffect(() => {
        if (!feedbackClose) {
            isSubmit = true;
        }
    }, [feedbackClose]);

    useEffect(() => {
        switch (section.panelName) {
            case FixItSections.KEY_TERMS:
                setOptimiserSectionData(optimiserData[0]?.value);
                break;
            case FixItSections.META_TAGS:
                setOptimiserSectionData(optimiserData[1]?.value);
                break;
            case FixItSections.URL:
                setOptimiserSectionData(optimiserData[2]?.value);
                break;
            case FixItSections.FEATURED_SNIPPET:
                setOptimiserSectionData(optimiserData[3]?.value);
                break;
            case FixItSections.H1_HEADING:
                setOptimiserSectionData(optimiserData[4]?.value);
                break;
            case FixItSections.LINKS:
                setOptimiserSectionData(optimiserData[8]?.value);
                break;
            default:
                setOptimiserSectionData({});
        }
    }, [optimiserData]);
    // useEffect(() => {
    //     setOptimiserSectionData(sectionData);
    // }, [sectionData]);

    const findNewPara = (newPara = false) => {
        document?.quill?.editor?.enable();
        let cursor = document?.quill?.editor?.cursor;
        let editor = document?.quill?.editor;
        let text = document?.quill?.editor?.getText(cursor, 1);
        let node = "";
        if (text == "\n" && !newPara) {
            node = editor?.getLeaf(cursor);
            if (node[0]?.domNode?.parentNode?.innerText?.length < 2) {
                node[0]?.domNode?.parentNode?.classList?.add(styles.fixItPara);
            } else {
                document.quill.editor.cursor =
                    document?.quill?.editor?.cursor + 1;
                document.quill.editor.updateContents(
                    new Delta()
                        .retain(cursor + 1)
                        .delete(0)
                        .insert("\n"),
                    "user",
                );
                node = editor?.getLeaf(cursor + 1);
                node[0]?.domNode?.parentNode?.classList?.add(styles.fixItPara);
            }
        } else {
            for (let index = cursor; index < editor.getLength(); index++) {
                if (editor.getText(index, 1) == "\n") break;
                cursor++;
            }

            document.quill.editor.updateContents(
                new Delta()
                    .retain(cursor + 1)
                    .delete(0)
                    .insert("\n"),
                "user",
            );
            node = editor?.getLeaf(cursor + 1);
            node[0]?.domNode?.parentNode?.classList?.add(styles.fixItPara);
            document.quill.editor.cursor = cursor + 1;
        }

        document.quill.editor.setSelection(document.quill.editor.cursor);
        setTimeout(() => {
            document?.quill?.editor?.disable();
        }, 2100);
    };

    useEffect(() => {
        if (section.panelName == FixItSections.META_TAGS) {
            const data = document.getElementsByClassName("ql-editor");
            data[0].style.height = "0px";
            data[0].style.padding = "0px";
            data[0].style.overflow = "hidden";
            // const toolbar = document.getElementById("toolbar");
            // toolbar.style.display = "none";
            document.getElementById("editorBoundsContainer").scrollTop = 0;
            return () => {
                if (document.getElementsByClassName("ql-editor")?.length) {
                    const data = document.getElementsByClassName("ql-editor");
                    if (data) {
                        data[0].style.removeProperty("font-family");
                        data[0].style.removeProperty("height");
                        data[0].style.removeProperty("padding");
                        data[0].style.removeProperty("overflow");
                    }

                    // const toolbar = document.getElementById("toolbar");
                    // if (toolbar) {
                    //     toolbar.style.display = "flex";
                    // }
                }
            };
        } else if (section.panelName == FixItSections.LINKS) {
        } else if (
            section.panelName == FixItSections.FEATURED_SNIPPET &&
            section.panelName != FixItSections.LINKS
        ) {
            findNewPara();
            const toolbar = document.getElementById("toolbar");
            toolbar.style.display = "none";
            return () => {
                // document?.quill?.editor?.enable();
                let highlightPara =
                    document?.quill?.editor?.root?.getElementsByClassName(
                        styles.fixItPara,
                    )[0];
                if (highlightPara?.innerText.length > 5) {
                    highlightPara?.remove();
                } else {
                    highlightPara?.classList?.remove(styles.fixItPara);
                }
                const toolbar = document.getElementById("toolbar");
                if (toolbar) {
                    toolbar.style.display = "flex";
                }
            };
        } else if (section.panelName != FixItSections.FEATURED_SNIPPET) {
            if (document.getElementsByClassName("ql-editor")?.length) {
                const data = document.getElementsByClassName("ql-editor");
                data[0].style.height = "0px";
                data[0].style.padding = "0px";
                data[0].style.overflow = "hidden";

                const toolbar = document.getElementById("toolbar");
                toolbar.style.display = "none";
                // document?.quill?.editor?.disable();
            }
            return () => {
                if (document.getElementsByClassName("ql-editor")?.length) {
                    const data = document.getElementsByClassName("ql-editor");
                    if (data) {
                        data[0].style.removeProperty("font-family");
                        data[0].style.removeProperty("height");
                        data[0].style.removeProperty("padding");
                        data[0].style.removeProperty("overflow");
                    }

                    const toolbar = document.getElementById("toolbar");
                    if (toolbar) {
                        toolbar.style.display = "flex";
                    }
                }
                // document?.quill?.editor?.enable();
            };
        }
    }, [section]);

    useEffect(() => {
        if (
            section.panelName == FixItSections.H1_HEADING &&
            suggestions[0]?.headings
        ) {
            setNewH1(suggestions[0]?.headings[0]);
        }
    }, [suggestions]);

    useEffect(() => {
        if (
            section.panelName == FixItSections.META_TAGS ||
            section.panelName == FixItSections.URL
        ) {
            if (
                suggestions[currentIndex - 1]?.keyterm
                    ?.toLowerCase()
                    ?.includes("title")
            ) {
                setMetaFixIt((ps) => ({ ...ps, section: "title" }));
            } else if (
                fixedCount < 2 &&
                suggestions[currentIndex - 1]?.keyterm
                    ?.toLowerCase()
                    ?.includes("description")
            ) {
                setMetaFixIt((ps) => ({ ...ps, section: "desc" }));
            } else if (
                suggestions[currentIndex - 1]?.keyterm
                    ?.toLowerCase()
                    ?.includes("url")
            ) {
                setMetaFixIt((ps) => ({ ...ps, section: "url" }));
            } else {
                setMetaFixIt((ps) => ({ ...ps, section: "" }));
            }
        }
    }, [currentIndex, suggestions]);

    const handleActivePara = (itemIndex, _item, isOpen, fixed) => {
        console.log(_item);
        if (fixed) return;
        if (_item?.keyterm?.toLowerCase()?.includes("title") && isOpen) {
            setMetaFixIt((ps) => ({ ...ps, section: "title" }));
        } else if (
            _item?.keyterm?.toLowerCase()?.includes("description") &&
            isOpen
        ) {
            setMetaFixIt((ps) => ({ ...ps, section: "desc" }));
        } else if (_item?.keyterm?.toLowerCase()?.includes("url") && isOpen) {
            setMetaFixIt((ps) => ({ ...ps, section: "url" }));
        }
        if (section.panelName == FixItSections.H1_HEADING) {
            const para = document.getElementsByClassName(styles.fixItPara);
            if (para?.length > 0) {
                para[0].innerHTML = originalPara;
                setOriginalPara("");
            }
        }

        // if (!_item?.id) {
        //     setCurrentIndex((ps) => (ps == itemIndex + 1 ? -1 : itemIndex + 1));
        //     return;
        // }
        if (!fixed) {
            setCurrentIndex((ps) => (ps == itemIndex + 1 ? -1 : itemIndex + 1));
        }
    };

    const addFeaturedSnippet = (h2Text, paraText) => {
        document?.quill?.editor?.enable();
        document.quill.editor.updateContents(
            new Delta()
                .retain(document?.quill?.editor?.cursor)
                .delete(1)
                .insert("\n", { header: 2 }),
            "user",
        );
        document.quill.editor.updateContents(
            new Delta()
                .retain(document?.quill?.editor?.cursor)
                .delete(0)
                .insert(h2Text),
            "user",
        );
        document.quill.editor.cursor =
            document?.quill?.editor?.cursor + h2Text.length;
        document.quill.editor.updateContents(
            new Delta()
                .retain(document?.quill?.editor?.cursor + 1)
                .delete(0)
                .insert((paraText || "") + "\n"),
            "user",
        );
        document.quill.editor.cursor =
            document?.quill?.editor?.cursor + (paraText?.length || 0) + 1;
        document.quill.editor.setSelection(document.quill.editor.cursor);
        // findNewPara(true);
        setTimeout(() => {
            document?.quill?.editor?.disable();
        }, 2100);
    };

    const fixItToQuill = () => {
        document.quill.editor.enable();
        const value = fixItEditorRef.current.innerHTML;
        const delta = document.quill.editor.clipboard.convert({ html: value });
        console.log(delta, "deltaaaaaaaaaa", value);
        document.quill.editor.setContents(delta);
    };

    useEffect(() => {
        if (fixItEditorRef?.current?.innerHTML) {
            let h1s = document.quill.editor.root.getElementsByTagName("h1");
            if (h1s.length > 0) {
                setTitleH1(h1s[0]);
            }
        }
        setMetaFixIt((ps) => ({ ...ps, isOn: true }));
        return () => {
            setEnableFixIt(false);
            setEnableFixItEditor(false);
            setMetaFixIt((ps) => ({ ...ps, isOn: false }));
            setIsFixItEnabled((ps) => ({
                ...ps,
                isOn: false,
                section: {},
                sectionData: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (
            section.panelName == FixItSections.META_TAGS &&
            currentIndex == -1
        ) {
            setMetaFixIt((ps) => ({ ...ps, section: "", isOn: true }));
        } else if (
            currentIndex != -1 &&
            fixItEditorRef?.current?.innerHTML &&
            currentIndex - 1 < suggestions.length &&
            !(
                section.panelName == FixItSections.META_TAGS ||
                section.panelName == FixItSections.LINKS
            )
        ) {
            setIsDoneDisabled(true);
            const prevPara = document.getElementsByClassName(styles.fixItPara);
            if (prevPara?.length > 0) {
                prevPara[0].innerHTML = originalPara;
                prevPara[0].classList.remove(styles.fixItPara);
            }
            // if (originalPara) {
            //     prevPara.innerHTML = originalPara;
            //     setOriginalPara("");
            // }
            const prevH1 = document.getElementsByClassName(styles.fixItH1);
            const prevNewTitle = document.getElementsByClassName("newTitle");
            prevNewTitle[0]?.remove();
            if (prevH1.length > 0) {
                prevH1[0].classList.remove(styles.fixItH1);
            }
            const currentPara = document.getElementById(
                suggestions[currentIndex - 1]?.id,
            );
            let allH1 = fixItEditorRef?.current?.getElementsByTagName("h1");

            // If no H1 is there
            if (
                currentIndex == 1 &&
                (document?.quill?.editor?.root?.getElementsByTagName("h1")
                    .length == 0 ||
                    document?.quill?.editor?.root
                        ?.getElementsByTagName("h1")[0]
                        ?.innerText?.trim().length == 0) &&
                section.panelName == FixItSections.H1_HEADING
            ) {
                // If empty H1 is present
                if (
                    document?.quill?.editor?.root
                        ?.getElementsByTagName("h1")[0]
                        ?.innerText?.trim().length == 0
                ) {
                    fixItEditorRef.current
                        .getElementsByTagName("h1")[0]
                        ?.remove();
                }
                let newH1Title = document.createElement("h1");
                newH1Title.innerHTML = `<span style="color:#014dc5">${newH1}</span>`;
                fixItEditorRef.current.insertBefore(
                    newH1Title,
                    fixItEditorRef.current.firstChild,
                );
                newH1Title.classList.add(styles.fixItH1Title);
                newH1Title.classList.add("newTitle");
            }
            // If H1 is already present
            if (currentPara?.tagName == "H1" && allH1?.length > 0) {
                if (currentPara.isEqualNode(allH1[0])) {
                    if (!isH1Fixed) {
                        if (!newH1?.length > 0) {
                            setNewH1(suggestions[0]?.headings[0]);
                        }
                        currentPara.innerHTML = `<span style="color:red; text-decoration:line-through;">${titleH1?.innerText}</span><span style="color:#014dc5"> ${newH1}</span>`;
                    }
                }
                currentPara.classList.add(styles.fixItH1Title);
            } else if (currentPara?.tagName == "P") {
                currentPara.classList.add(styles.fixItPara);
                setOriginalPara(`${currentPara.innerHTML}`);
                if (
                    suggestions[currentIndex - 1]?.prompts &&
                    suggestions[currentIndex - 1]?.prompts[0]
                ) {
                    // currentPara.innerHTML =
                    //     suggestions[currentIndex - 1]?.prompts[0]?.diff;
                    currentPara.innerHTML = currentDiffText;
                }
            }
            document.getElementById("editorBoundsContainer").scrollTop =
                currentPara?.offsetTop - 80;
            setIsDoneDisabled(false);
        } else if (
            fixItEditorRef?.current &&
            section.panelName != FixItSections.LINKS
        ) {
            if (
                document?.quill?.editor?.root
                    ?.getElementsByTagName("h1")[0]
                    ?.innerText?.trim().length == 0
            ) {
                fixItEditorRef.current.getElementsByTagName("h1")[0]?.remove();
            }
            setIsDoneDisabled(true);
            const currentPara = document.getElementsByClassName(
                styles.fixItPara,
            );
            const currentH1 = document.getElementsByClassName(
                styles.fixItH1Title,
            );
            if (!!currentH1.length) {
                currentH1[0].classList.remove(styles.fixItH1Title);
            }
            if (!!currentPara.length) {
                currentPara[0].innerHTML = originalPara;
                currentPara[0].classList.remove(styles.fixItPara);
            }
            setIsDoneDisabled(false);
            // setNewH1("");
        }
    }, [
        currentIndex,
        fixItEditorRef?.current?.innerHTML,
        newH1,
        suggestions,
        currentDiffText,
    ]);

    const addH1 = (text) => {
        let tempH1s = fixItEditorRef?.current?.getElementsByTagName("h1");
        if (tempH1s.length > 0) {
            tempH1s[0].innerHTML = text;
            setIsH1Fixed(true);
        }
        setCurrentIndex((ps) => ps + 1);
    };

    const dismissH1 = () => {
        let tempH1s = fixItEditorRef?.current?.getElementsByTagName("h1");
        if (tempH1s.length > 0) {
            if (titleH1?.innerHTML) {
                tempH1s[0].innerHTML = titleH1.innerHTML;
                // setNewH1("");
            } else {
                tempH1s[0].remove();
            }
            setCurrentIndex((ps) => ps + 1);
        }
    };

    const downloadInterlinks = () => {
        let headers = {
            keyword: "Keyword",
            link: "Link",
        };
        let dataFormat = [];

        suggestions.forEach((item) => {
            dataFormat.push({
                keyword: item.keyword,
                link: item.page,
            });
        });
        exportCSVFile(
            headers,
            dataFormat,
            `${reportInfo.title}_internal_links`,
        );
    };

    return (
        <div className={styles.fixIt_wrapper}>
            <div className={styles.fixIt_head}>
                <div className={styles.sectionName}>
                    {section?.panelName == "LINKS" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <div>Internal Linking</div>{" "}
                            <LightTooltip
                                arrow
                                title={"Download all suggestions"}
                            >
                                <span>
                                    <Button
                                        handler={() => {
                                            if (suggestions.length > 0) {
                                                downloadInterlinks();
                                            }
                                        }}
                                        Icon={() => (
                                            <BsDownload
                                                color="#014dc5"
                                                size={"15"}
                                            />
                                        )}
                                        iconReverse={true}
                                        text=""
                                        style={{
                                            color: "var(--primary-blue)",
                                            backgroundColor: "#f1f4fd",
                                            borderRadius: 3,
                                            fontSize: 13,
                                            width: 35,
                                            height: 28,
                                            marginRight: 10,
                                        }}
                                    />
                                </span>
                            </LightTooltip>
                        </div>
                    ) : (
                        section?.panelName
                    )}
                </div>
                <div style={{ display: "flex" }}>
                    {section.panelName == FixItSections.LINKS ? (
                        <></>
                    ) : (
                        <div className={styles.seoScore}>
                            {" "}
                            SEO Score :{" "}
                            {optimiserLoading ? (
                                <span
                                    style={{
                                        display: "inline-block",
                                        width: "50px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress size={13} />
                                </span>
                            ) : (
                                <>
                                    <score
                                        style={{
                                            color:
                                                seoScore > 85
                                                    ? "#42c134"
                                                    : seoScore > 60
                                                    ? "#F1A126"
                                                    : "#fc1024",
                                        }}
                                    >
                                        {seoScore}
                                    </score>
                                    <max>/100</max>
                                </>
                            )}
                        </div>
                    )}
                    <div className={styles.bottomSection}>
                        <VscClose
                            onClick={() => {
                                setIsLoadingFixIt(false);
                                setEnableFixItEditor(false);
                                setIsFixItEnabled({
                                    isOn: false,
                                    section: {},
                                    sectionData: {},
                                    isLoading: false,
                                });
                                document?.quill?.editor?.enable();
                                if (!isLoadingFixIt) {
                                    setCurrentIndex(-1);
                                    setSuggestions([]);
                                    setEnableFixIt(false);
                                    setMetaFixIt({});
                                }
                            }}
                            color={"#808080"}
                        />
                    </div>
                </div>
            </div>
            {!isLoadingFixIt ? (
                <>
                    <div className={styles.fixIt_body}>
                        <div className={styles.optimiserPoints}>
                            {section.panelName == FixItSections.LINKS ? (
                                <>
                                    <div className={styles.statements}>
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title={"Major issue"}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "8px",
                                                }}
                                            >
                                                {numberofInterlinks >=
                                                Math.ceil(
                                                    realtimeRating.wordCount /
                                                        500,
                                                ) ? (
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/green-tick.svg"
                                                        }
                                                    />
                                                ) : (
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/red-cross.svg"
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </LightTooltip>
                                        <div>
                                            <span
                                                style={{ margin: "0" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: `Current number of interlinks are ${numberofInterlinks}. Suggested is ${
                                                        Math.ceil(
                                                            realtimeRating.wordCount /
                                                                500,
                                                        ) || 1
                                                    }.`,
                                                }}
                                            ></span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                optimiserSectionData?.data?.map((para, i) => (
                                    <>
                                        <div className={styles.statements}>
                                            {para.status == "RED" ? (
                                                <LightTooltip
                                                    arrow
                                                    placement="bottom"
                                                    title={"Major issue"}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        <SVGIcon
                                                            src={
                                                                "/New UI/SVG/red-cross.svg"
                                                            }
                                                        />
                                                    </div>
                                                </LightTooltip>
                                            ) : para.status == "YELLOW" ? (
                                                <LightTooltip
                                                    arrow
                                                    placement="bottom"
                                                    title={"Minor issue"}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        <SVGIcon
                                                            src={
                                                                "/New UI/SVG/yellow-tick.svg"
                                                            }
                                                        />
                                                    </div>
                                                </LightTooltip>
                                            ) : (
                                                <LightTooltip
                                                    arrow
                                                    placement="bottom"
                                                    title={"All good"}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        <SVGIcon
                                                            src={
                                                                "/New UI/SVG/green-tick.svg"
                                                            }
                                                        />
                                                    </div>
                                                </LightTooltip>
                                            )}
                                            <div>
                                                <span
                                                    style={{ margin: "0" }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: para?.statement,
                                                    }}
                                                ></span>
                                                {para?.tooltip && (
                                                    <LightTooltip
                                                        arrow
                                                        placement="top"
                                                        title={para?.tooltip}
                                                    >
                                                        <span>
                                                            <AiOutlineInfoCircle
                                                                className={
                                                                    styles.infoIcon
                                                                }
                                                                fill={"#a3a3a3"}
                                                            />
                                                        </span>
                                                    </LightTooltip>
                                                )}{" "}
                                            </div>
                                        </div>
                                    </>
                                ))
                            )}
                        </div>
                        <div
                            style={{
                                height: suggestions?.length > 0 ? "" : "100%",
                            }}
                            className={styles.suggestions}
                        >
                            {suggestions?.length > 0 &&
                                suggestions.map((item, itemIndex) => {
                                    return (
                                        <FixItCards
                                            item={item}
                                            setFixedCount={setFixedCount}
                                            keyterm={
                                                item.keyterm
                                                    ? item.keyterm
                                                    : item.keyword
                                            }
                                            link={item.page ? item.page : ""}
                                            itemIndex={itemIndex + 1}
                                            key={itemIndex + 1}
                                            setCurrentIndex={setCurrentIndex}
                                            handler={(_item, isOpen, fixed) => {
                                                handleActivePara(
                                                    itemIndex,
                                                    _item,
                                                    isOpen,
                                                    fixed,
                                                );
                                            }}
                                            fixItToQuill={fixItToQuill}
                                            sectionName={section?.panelName}
                                            expanded={
                                                itemIndex + 1 == currentIndex
                                            }
                                            addH1={addH1}
                                            setIsDoneDisabled={
                                                setIsDoneDisabled
                                            }
                                            setNewH1={setNewH1}
                                            dismissH1={dismissH1}
                                            setOriginalPara={setOriginalPara}
                                            originalPara={originalPara}
                                            addFeaturedSnippet={
                                                addFeaturedSnippet
                                            }
                                            setMetaFixIt={setMetaFixIt}
                                            setMetaData={setMetaData}
                                            currentIndex={currentIndex}
                                            setCurrentDiffText={
                                                setCurrentDiffText
                                            }
                                            suggestions={suggestions}
                                            setNumberOfInterlinks={
                                                setNumberOfInterlinks
                                            }
                                        />
                                    );
                                })}
                            {!suggestions?.length && (
                                <div className={styles.noMoreSuggestion}>
                                    <div>
                                        <AiOutlineFileSearch size={32} />
                                    </div>
                                    <div>
                                        <span style={{ position: "relative" }}>
                                            No suggestions found.
                                            {section.panelName ==
                                                FixItSections.LINKS && (
                                                <LightTooltip
                                                    arrow
                                                    title={
                                                        " This usually occurs when the content does not align with the selected domain."
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            right: -14,
                                                            top: 1,
                                                        }}
                                                    >
                                                        <SVGIcon
                                                            color="#2196f3"
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/info.svg"
                                                            }
                                                        />
                                                    </div>
                                                </LightTooltip>
                                            )}
                                        </span>
                                        <br />
                                        <span>
                                            Please try to fix the issues listed
                                            above manually.
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className={styles.allGoodSection}>
                                {showEditorButton && (
                                    <>
                                        {section.panelName ==
                                            FixItSections.KEY_TERMS &&
                                            optimiserSectionData?.data[0]
                                                ?.status == "GREEN" && (
                                                <>
                                                    <BsPatchCheckFill
                                                        size={40}
                                                        color="#60D69F"
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        All Good
                                                    </div>
                                                </>
                                            )}
                                        {section.panelName !=
                                            FixItSections.KEY_TERMS && (
                                            <>
                                                <BsPatchCheckFill
                                                    size={40}
                                                    color="#60D69F"
                                                />
                                                <div
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    All Good
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                {!isLoadingFixIt && (
                                    <Button
                                        handler={() => {
                                            if (isLoadingFixIt) return;
                                            setCurrentIndex(-1);
                                            setEnableFixItEditor(false);
                                            setSuggestions([]);
                                            setIsFixItEnabled({
                                                isOn: false,
                                                section: {},
                                                sectionData: {},
                                            });
                                            setEnableFixIt(false);
                                            setMetaFixIt({});
                                            document?.quill?.editor?.enable();
                                        }}
                                        text="Resume Editing"
                                        width="100px"
                                        height={32}
                                        style={{
                                            fontSize: "11px",
                                            marginTop: "20px",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        {(section.panelName == FixItSections.KEY_TERMS ||
                            section.panelName == FixItSections.LINKS) &&
                            suggestions?.length > 2 &&
                            !feedbackClose && (
                                <UserFeedback
                                    setClose={setFeedbackClose}
                                    section={
                                        section.panelName == FixItSections.LINKS
                                            ? "Interlinking"
                                            : "FixIt"
                                    }
                                />
                            )}
                    </div>
                </>
            ) : (
                <div style={{ width: "100%" }}>
                    <div>
                        <Skeleton
                            variant="text"
                            width="100%"
                            height="200px"
                            style={{
                                marginTop: "-20px",
                                marginBottom: "-50px",
                            }}
                        />
                    </div>
                    <div>
                        <Skeleton
                            variant="text"
                            width="100%"
                            height="130px"
                            style={{
                                marginBottom: "-20px",
                            }}
                        />
                    </div>
                    <div>
                        <Skeleton variant="text" width="100%" height="60px" />
                    </div>
                    <div>
                        <Skeleton variant="text" width="100%" height="60px" />
                    </div>
                    <div>
                        <Skeleton variant="text" width="100%" height="60px" />
                    </div>
                    <div>
                        <Skeleton variant="text" width="100%" height="60px" />
                    </div>
                    <div>
                        <Skeleton variant="text" width="100%" height="60px" />
                    </div>
                    <div>
                        <Skeleton variant="text" width="100%" height="60px" />
                    </div>
                </div>
                // <div className={styles.loadingContainer}>
                //     <CircularProgress />
                //     <div style={{ display: "flex", marginTop: "15px" }}>
                //         <div>Your content is getting optimised</div>
                //         <LoadingEllipsis />
                //     </div>
                // </div>
            )}
        </div>
    );
};

export default FixItModule;
