import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ClusterDocs } from "../ClusterDocs/ClusterDocs";
import ClusterReport from "../ClusterDocs/clusters/ClusterReport";
export default function Cluster() {
    return (
        <Switch>
            <Route exact path={`/admin/cluster/report/:id`}>
                <ClusterReport />
            </Route>
            <Route exact path={"/admin/cluster"}>
                <ClusterDocs />
            </Route>

            <Redirect to={"/admin/cluster"} />
        </Switch>
    );
}
