import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./ShopifyApp.module.css";
import { VscClose } from "react-icons/vsc";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import SVGIcon from "../../../atoms/SVGIcon";
import DropdownUI from "./DropdownUI";
import Modal from "../../../atoms/Modal/Modal";
import { UPLOAD_FILE } from "../../../api.json";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import Progress from "../../../atoms/Progress/Progress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Confetti from "react-dom-confetti";
import {
    Box,
    MenuItem,
    FormControl,
    Select,
    makeStyles,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import "./dropdown.css";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { SMART_EDITOR } from "../../TextEditor/editorConstants";
import Calendar from "./Calendar";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import ReactSelect from "./ReactSelect";
import slugify from "slugify";
import { IoMdClose } from "react-icons/io";
import { HiOutlineExternalLink } from "react-icons/hi";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const slugifyOption = {
    replacement: "-",
    remove: /[*%&#^;$<>+~.()'"!:@]/g,
};

const useStyles = makeStyles((theme) => ({
    select: {
        border: "1px solid #f1f1f1",
        display: "flex",
    },
}));

const visibilityOption = [
    {
        id: 1,
        value: "Public",
        desc: "Visible to everyone",
    },
    {
        id: 2,
        value: "Private",
        desc: "Only visible to site admins and editors",
    },
];

const statusOption = [
    {
        id: 0,
        value: "Visible",
    },
    {
        id: 1,
        value: "Hidden",
    },
];

const exportTypeOption = [
    {
        id: 1,
        value: "Create New Post",
    },
    // {
    //     id: 2,
    //     value: "Update",
    // },
];

const config = {
    angle: "176",
    spread: 360,
    startVelocity: 40,
    elementCount: "100",
    dragFriction: "0.07",
    duration: "4000",
    stagger: "2",
    width: "20px",
    height: "20px",
    perspective: "400px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

export default function ShopifyApp({
    reportInfo,
    docInfo,
    shopifyApp,
    setShopifyApp,
    attachedTo,
    realtimeRating,
    metaData,
}) {
    const [domainsList, setDomainsList] = useState([]);
    const [domain, setDomain] = useState(null);
    const [authorsData, setAuthorsData] = useState([]);
    const [author, setAuthor] = useState(null);
    const [visibility, setVisibility] = useState(visibilityOption[0]);
    const [status, setStatus] = useState(statusOption[0]);
    const [exportType, setExportType] = useState(exportTypeOption[0]);
    const [postId, setPostId] = useState("");
    const [categories, setCategories] = useState([
        {
            label: "+ Create a new blog",
            id: -1,
            value: "Create a new blog",
            title: "Create a new blog",
        },
    ]);
    const [category, setCategory] = useState([]);
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState([]);
    const [title, setTitle] = useState("");
    const [scheduleTime, setScheduleTime] = useState(new Date());
    // const [featuredImage, setFeaturedImage] = useState({});
    const [featuredImageLocal, setFeaturedImageLocal] = useState({});
    const [analyzing, setAnalyzing] = useState(false);
    const [removeDomainName, setRemoveDomainName] = useState("");
    const [removeDomainModal, setRemoveDomainModal] = useState(false);
    const [addAccountModal, setAddAccountModal] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [isExportDraft, setIsExportDraft] = useState(false);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [success, setSuccess] = useState(false);
    const [immediate, setImmediately] = useState(true);
    const [postLink, setPostLink] = useState("");
    // const [finalPublish, setFinalPublish] = useState(false);
    const [publishStatus, setPublishStatus] = useState(1);
    const [totalExports, setTotalExports] = useState(0);
    const [publishing, setPublishing] = useState(false);
    const [successfulPublish, setSuccessfulPublished] = useState(false);
    const [publishModalCheck, setPublishModalCheck] = useState(false);
    const [confirmationScreen, setConfirmationScreen] = useState(false);
    const [startConfetti, setStartConfetti] = useState(false);
    const [wordpressConnectionError, setWordpressConnectionError] =
        useState(false);
    const [notAllowedError, setNotAllowedError] = useState(false);
    const [replace, setReplace] = useState(false);
    const [slug, setSlug] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [viewAllExports, setViewAllExports] = useState(false);
    const [minDate, setMinDate] = useState(
        new Date().setDate(new Date().getDate() - 1),
    );

    const { getData, postData } = useHelperFunctions();
    const ref = useRef();
    const params = useParams();
    const classes = useStyles();
    const { mixpanelTrack } = useMixpanelHook();

    const getAllAuthors = async (prev = "") => {
        const res = await getData({
            url: "/api/integrations/shopify/articles/authors",
        });
        if (res.status == 200) {
            if (res.data.authors.length > 0) {
                setAuthorsData([
                    ...res.data.authors.map((au) => {
                        let active = false;
                        let at = {
                            name: au,
                            slug: au,
                            avatar: {},
                        };
                        if (prev == au && !active) {
                            active = true;
                            setAuthor(at);
                        }
                        if (!prev && !active) {
                            active = true;
                            setAuthor(at);
                        }
                        return at;
                    }),
                ]);
            }
        } else {
        }
    };
    const getAllTags = async (prev = []) => {
        const res = await getData({
            url: "/api/integrations/shopify/articles/tags",
        });
        if (res.status == 200) {
            console.log("ALL!@@  getAllTags", res.data.tags);
            let temp = [];
            if (res.data.tags.length > 0) {
                temp = res.data.tags.map((tg) => ({
                    label: tg,
                    value: tg,
                }));
                console.log("ALL!@@  getAllBlogs", temp);
            }
            setTags(temp);
            if (prev.length > 0) {
                setTag([...temp.filter((t) => prev.includes(t.label))]);
            } else {
                setTag([]);
            }
        }
    };
    const getAllBlogs = async (prevBlog = "") => {
        const res = await getData({
            url: "/api/integrations/shopify/blogs",
        });
        if (res.status == 200) {
            if (res.data.blogs.length > 0) {
                let active = false;
                setCategories([
                    ...categories,
                    ...res.data.blogs.map((bg) => {
                        let cat = { ...bg, label: bg.handle, value: bg.handle };
                        if (prevBlog == bg.handle && !active) {
                            active = true;
                            setCategory(cat);
                        }
                        if (!prevBlog && !active) {
                            active = true;
                            setCategories({});
                        }
                        return cat;
                    }),
                ]);
            } else {
                setCategories([]);
            }
        }
    };

    useEffect(() => {
        getShopifyDataSnap();
    }, []);

    useEffect(() => {}, [title]);
    useEffect(() => {
        if (publishModal) {
            setPublishModalCheck(true);
        }
    }, [publishModal]);

    useEffect(() => {
        if (!success) {
            let titleText = document.quill.editor.root.querySelectorAll("h1");
            for (let i = 0; i < titleText.length; i++) {
                if (titleText[i]?.innerText.trim().length > 0) {
                    setTitle(titleText[i]?.innerText);
                    setSlug(
                        slugify(
                            metaData?.url || titleText[i]?.innerText,
                            slugifyOption,
                        ),
                    );
                    break;
                } else {
                    setTitle("");
                }
            }
        }
    }, [document?.quill?.editor.root.querySelectorAll("h1")]);

    useEffect(() => {
        if (!publishModal) {
            setIsExportDraft(false);
        }
    }, [publishModal]);

    const getShopifyDataSnap = async () => {
        setLoading(true);
        const res = await postData({
            url: "/api/integrations/shopify/get",
            payload: {
                app_id: 4,
                ...(attachedTo == SMART_EDITOR && {
                    doc_id: params.documentId,
                }),
                ...(attachedTo != SMART_EDITOR && {
                    report_id: reportInfo.report_id,
                }),
            },
        });
        if (res.status == 200) {
            setExportType(exportTypeOption[0]);
            console.log("getShopifyDataSnap data", res.data);
            if (res.data.data.shopifyData?.publish_id?.length) {
                if (res.data.data.shopifyData?.publish_status == 0) {
                    setStatus(statusOption[1]);
                } else if (res.data.data.shopifyData?.publish_status == 1) {
                    setStatus(statusOption[0]);
                }
                setPostLink(res.data.data.shopifyData?.publish_link);
                res.data.data.shopifyData?.settings?.published_at &&
                    setScheduleTime(
                        res.data.data.shopifyData?.settings?.published_at,
                    );
            }
            let allAccn = [];
            if (res.data.data.shopify_linked.length > 0) {
                allAccn = [...res.data.data.shopify_linked];
            } else {
                const res2 = await getData({
                    url: "/api/integrations/shopify/shopifyAccounts",
                });
                if (res2.status == 200) {
                    allAccn = [...res2.data?.data];
                }
            }
            setDomain({
                ...allAccn[0],
                domain: allAccn[0].shop,
            });
            setDomainsList(
                allAccn?.length > 0
                    ? allAccn.map((dm) => ({
                          ...dm,
                          domain: dm.shop,
                      }))
                    : [],
            );
            let tempAuthor = res.data.data.shopifyData?.settings?.author || "";
            let tempBlogHandle =
                res.data.data.shopifyData?.settings?.blog_handle || "";
            let tempAllTags = res.data.data.shopifyData?.settings?.tags || [];
            let tempSlug = res.data.data.shopifyData?.settings?.slug || "";
            let tempTitle = res.data.data.shopifyData?.settings?.title || "";

            await getAllAuthors(tempAuthor || "");
            await getAllBlogs(tempBlogHandle || "");

            await getAllTags(tempAllTags.length > 0 ? tempAllTags : []);
            if (tempTitle.length) {
                setTitle(tempTitle);
            }

            if (res.data?.data?.shopifyData?.settings) {
                setFeaturedImageLocal({
                    id: "",
                    image: res.data?.data?.shopifyData?.settings?.featured_media
                        ?.src,
                });
            } else if (
                document.quill.editor.root.getElementsByTagName("img").length >
                0
            ) {
                setFeaturedImageLocal({
                    id: "",
                    image: document.quill.editor.root.getElementsByTagName(
                        "img",
                    )[0].src,
                });
            }

            if (tempSlug.length) {
                setSlug(tempSlug);
                setSuccess(true);
            } else {
                const h1Text =
                    document?.quill?.editor?.root?.querySelectorAll("h1")[0]
                        ?.innerText;

                if (h1Text?.length) {
                    setSlug(slugify(metaData?.url || h1Text, slugifyOption));
                    if (!tempTitle) {
                        setTitle(h1Text);
                    }
                } else {
                    let reportTitle =
                        attachedTo == SMART_EDITOR
                            ? docInfo.title
                            : reportInfo.title;
                    if (reportTitle.length) {
                        setSlug(
                            slugify(
                                metaData?.url || reportTitle,
                                slugifyOption,
                            ),
                        );
                        setTitle(reportTitle);
                    }
                }
            }
        }
        setLoading(false);
    };

    const handleAuthorChange = (event) => {
        setAuthor(event.target.value);
    };

    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    const uploadImage = async (image) => {
        setAnalyzing(true);
        const formdata = new FormData();
        formdata.append("image", image);
        const res = await postData({
            url: UPLOAD_FILE,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            payload: formdata,
        });
        if (res.status == 200) {
            setFeaturedImageLocal({ id: "", image: res.data.data.attachment });
        }
        setAnalyzing(false);
    };

    const imageHandler = () => {
        ref.current.click();
    };

    const publish = async () => {
        // setFinalPublish(true);
        setPublishing(true);
        let featuredMedia = featuredImageLocal?.image;
        let temp = document.quill.editor.root;
        let content = temp.cloneNode(true);

        let overlays = content.querySelectorAll(
            "[class*=td-quill-video-overlay]",
        );

        if (overlays.length > 0) {
            for (let i = 0; i < overlays.length; i++) {
                overlays[i].remove();
            }
        }

        let quillClasses = content.querySelectorAll("[class*=td-quill-video]");

        if (quillClasses.length > 0) {
            for (let i = 0; i < quillClasses.length; i++) {
                quillClasses[i].removeAttribute("class");
            }
        }
        let videoClasses = content.querySelectorAll("[class*=td-video]");

        if (videoClasses.length > 0) {
            for (let i = 0; i < videoClasses.length; i++) {
                videoClasses[i].removeAttribute("class");
            }
        }

        let contentEditable = content.querySelectorAll(
            "[contenteditable*=false]",
        );

        if (contentEditable.length > 0) {
            for (let i = 0; i < contentEditable.length; i++) {
                contentEditable[i].removeAttribute("contenteditable");
            }
        }
        // Removing indent class and adding indent style
        let indentParas = content.querySelectorAll("[class*=ql-indent]");

        for (let i = 0; i < indentParas.length; i++) {
            indentParas[i].style.paddingLeft = `${
                Number(indentParas[i].className.slice(-1)) * 40
            }px`;
            indentParas[i].classList.remove(indentParas[i].className);
        }

        if (isExportDraft || immediate) {
            setScheduleTime(new Date());
        }
        let payloadObj = {
            publish_type: "post",
            title: title
                ? title
                : attachedTo == SMART_EDITOR
                ? docInfo.title
                : reportInfo.title,
            post_id: exportType.id == 2 ? postId : "",
            publish_status:
                status?.value?.toLowerCase() == "hidden" ? "hidden" : "publish", // 0-Publish, 1-hidden
            author: author?.name || "",
            publish_type: "posts",
            ...(!immediate && {
                date: scheduleTime.toISOString().split(".")[0],
                published_at: scheduleTime.toISOString(),
            }),
            ...(!immediate && status.id == 1 && { published_at: null }),

            // tags: tag,
            //blog_id: category?.id || 0,
            //blog_handle: category.value || "",
            tags: tag.length ? [...tag.map((tg) => tg.value)] : [],
            image: featuredMedia?.length ? { src: featuredMedia } : {},
        };

        let imgArr = content.querySelectorAll("img");
        if (imgArr.length > 0) {
            let sourceImgList = [];
            for (let i = 0; i < imgArr.length; i++) {
                sourceImgList.push(imgArr[i].src);
                let oldHtml = imgArr[i].outerHTML;
                // let newHtml =
                //     "<figure loading='lazy' class='wp-block-image size-large'>" +
                //     oldHtml +
                //     "</figure>";
                imgArr[i].outerHTML = oldHtml;
            }
            const res = await postData({
                url: "/api/integrations/shopify/uploadFileShopify",
                payload: {
                    source_url: sourceImgList,
                    app_id: 5,
                },
            });
            if (res.status == 200) {
                console.log("wordpress", res);
                for (let j = 0; j < res?.data?.data?.links?.length; j++) {
                    content.querySelectorAll("img")[j].src =
                        res?.data?.data?.links[j];
                }
                if (content.querySelector("h1")) {
                    content.querySelector("h1").remove();
                }
                if (category?.id == -1) {
                    const response = await postData({
                        url: "/api/integrations/shopify/blog/create",
                        payload: {
                            title: title
                                ? title
                                : attachedTo == SMART_EDITOR
                                ? docInfo.title
                                : reportInfo.title,
                        },
                    });
                    if (response.status == 200) {
                        payloadObj["blog_id"] = response.data?.blog?.id;
                        payloadObj["blog_handle"] = response.data?.blog?.handle;
                        payloadObj["article_handle"] =
                            slug?.toLowerCase() || "";
                    }
                } else {
                    payloadObj["blog_id"] = category?.id || 0;
                    payloadObj["blog_handle"] = category?.value;
                    payloadObj["article_handle"] = slug?.toLowerCase() || "";
                }
                const result = await postData({
                    url: "/api/integrations/shopify/article/publish",
                    headers: "text/xml",
                    payload: {
                        ...(attachedTo == SMART_EDITOR && {
                            doc_id: params.documentId,
                        }),
                        ...(attachedTo != SMART_EDITOR && {
                            report_id: reportInfo.report_id,
                        }),
                        article: {
                            ...payloadObj,
                            body_html: content.innerHTML,
                        },
                    },
                });
                if (result.status == 200) {
                    setSuccess(true);
                    setSuccessfulPublished(true);
                    setStartConfetti(false);
                    setPublishing(false);
                    setStartConfetti(true);
                    setTimeout(() => {
                        setSuccessfulPublished(false);
                        setConfirmationScreen(true);
                    }, "4000");
                    setPostLink(result.data?.articleResp?.publish_link);
                    getShopifyDataSnap();
                    setTag(result.data?.articleResp?.tags?.split(","));
                    mixpanelTrack("Shopify App", {
                        action: "Publish Successful",
                        post_link: result.data?.articleResp?.publish_link,
                    });
                }
                console.log(content, "rrrrrrrrrrrr");
            }
        } else {
            if (content.querySelector("h1")) {
                content.querySelector("h1").remove();
            }
            if (category?.id == -1) {
                const response = await postData({
                    url: "/api/integrations/shopify/blog/create",
                    payload: {
                        title: title
                            ? title
                            : attachedTo == SMART_EDITOR
                            ? docInfo.title
                            : reportInfo.title,
                    },
                });
                if (response.status == 200) {
                    payloadObj["blog_id"] = response.data?.blog?.id;
                    payloadObj["blog_handle"] = response.data?.blog?.handle;
                    payloadObj["article_handle"] = slug?.toLowerCase() || "";
                }
            } else {
                payloadObj["blog_id"] = category?.id || 0;
                payloadObj["blog_handle"] = category?.value;
                payloadObj["article_handle"] = slug?.toLowerCase() || "";
            }
            const result = await postData({
                url: "/api/integrations/shopify/article/publish",
                headers: "text/xml",
                payload: {
                    ...(attachedTo == SMART_EDITOR && {
                        doc_id: params.documentId,
                    }),
                    ...(attachedTo != SMART_EDITOR && {
                        report_id: reportInfo.report_id,
                    }),
                    article: {
                        ...payloadObj,
                        body_html: content.innerHTML,
                    },
                },
            });
            if (result.status == 200) {
                setSuccess(true);
                setSuccessfulPublished(true);
                setStartConfetti(false);
                setPublishing(false);
                setStartConfetti(true);
                setTimeout(() => {
                    setSuccessfulPublished(false);
                    setConfirmationScreen(true);
                }, "4000");
                setPostLink(result.data?.articleResp?.publish_link);
                setTag(result.data?.articleResp?.tags?.split(","));
                getShopifyDataSnap();
                mixpanelTrack("Shopify App", {
                    action: "Publish Successful",
                    post_link: result.data?.articleResp?.publish_link,
                });
            }
        }
        setPublishing(false);
    };

    const removeAccount = async () => {
        setLoad(true);
        const res = await postData({
            method: "post",
            url: "/api/integrations/disconnect",
            payload: {
                app_id: 5,
                // isIndividualDisconnect: 1,
            },
        });
        setLoad(false);
        if (res.status == 200) {
            setRemoveDomainModal(false);
            // getAccounts();
        }
    };

    const addAccount = async (domain, username, password) => {
        setLoad(true);
        if (domain.slice(-1) == "/") {
            domain = domain.slice(0, -1);
        }
        let creds = {
            username: username,
            application_password: password,
            domain: domain,
        };
        const res = await postData({
            method: "post",
            url: "/api/integrations",
            payload: {
                app_id: 4,
                credentials: creds,
            },
        });
        setLoad(false);
        if (res.status == 200) {
            setAddAccountModal(false);
            // getAccounts();
        } else {
            if (
                res?.response?.data?.message ==
                "we are unable to connect with your Shopify domain"
            ) {
                setWordpressConnectionError(true);
            } else if (
                res?.response?.data?.message ==
                "you are not allowed to login as this user type"
            ) {
                setNotAllowedError(true);
            }
        }
    };

    return (
        <div className="wordpressContainer">
            {totalExports?.length && viewAllExports && (
                <AllExports
                    allPosts={totalExports}
                    setViewAllExports={setViewAllExports}
                />
            )}
            <div className={shopifyApp ? styles.appContainer : styles.hide}>
                {loading ? (
                    <>
                        <div className={styles.loadingHeading}>
                            <VscClose
                                fontWeight={800}
                                color="#acb3bf"
                                onClick={() => {
                                    setShopifyApp(false);
                                }}
                                className={styles.closeIcon}
                            />
                        </div>
                        <Progress
                            height={"93%"}
                            width={"auto"}
                            style={{ zIndex: "99", position: "absolute" }}
                        />
                    </>
                ) : successfulPublish ? (
                    <>
                        <div className={styles.heading}>
                            <h2>Shopify Publisher</h2>
                            <VscClose
                                fontWeight={800}
                                color="#acb3bf"
                                onClick={() => {
                                    setShopifyApp(false);
                                }}
                                className={styles.closeIcon}
                            />
                        </div>
                        <div className={styles.loadingContainer}>
                            <Confetti
                                className={styles.confetti}
                                active={startConfetti}
                                config={config}
                            />
                            <SVGIcon
                                width={60}
                                height={60}
                                size={60}
                                src={"/New UI/SVG/successful.svg"}
                            />

                            <p className={styles.congratsText}>
                                {status.id == 3
                                    ? "Successfully exported"
                                    : status.id == 1
                                    ? "Successfully exported"
                                    : "Successfully exported"}
                            </p>
                        </div>
                    </>
                ) : publishing ? (
                    <>
                        <div className={styles.heading}>
                            <h2>
                                {success
                                    ? "Shopify Publisher"
                                    : status.id == 3
                                    ? "Are you ready to export as Draft?"
                                    : status.id == 1
                                    ? "Are you ready to export?"
                                    : "Are you ready to export?"}
                            </h2>
                            <VscClose
                                fontWeight={800}
                                color="#acb3bf"
                                onClick={() => {
                                    setShopifyApp(false);
                                }}
                                className={styles.closeIcon}
                            />
                        </div>
                        <div className={styles.loadingContainer}>
                            <CircularProgress style={{ zIndex: "99" }} />
                            <p className={styles.loadingText}>
                                {`We are ${
                                    status.id == 3
                                        ? "exporting"
                                        : status.id == 1
                                        ? "exporting"
                                        : "exporting"
                                } your article. Please wait...`}
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        {publishModal || success ? (
                            <div
                                className={
                                    confirmationScreen
                                        ? styles.mainScreen
                                        : success
                                        ? ""
                                        : styles.confirmationScreen
                                }
                            >
                                <div className={styles.heading}>
                                    <h2>
                                        {success
                                            ? "Shopify Publisher"
                                            : status.id == 3
                                            ? "Are you ready to export as draft?"
                                            : status.id == 1
                                            ? "Are you ready to publish?"
                                            : "Are you ready to schedule?"}
                                    </h2>
                                    <VscClose
                                        fontWeight={800}
                                        color="#acb3bf"
                                        onClick={() => {
                                            setShopifyApp(false);
                                        }}
                                        className={styles.closeIcon}
                                    />
                                </div>
                                <p className={styles.tagline}>
                                    {confirmationScreen || success
                                        ? "Export article to Shopify directly from Scalenut."
                                        : status.id == 3
                                        ? "Please review your settings before exporting as draft."
                                        : status.id == 1
                                        ? "Please review your settings before exporting the post."
                                        : "Please review your settings before exporting the post."}
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{ width: "calc(48%)" }}
                                        className={styles.domainRow}
                                    >
                                        <div className={styles.siteIcon}>
                                            <img
                                                loading="lazy"
                                                width="28px"
                                                height="28px"
                                                src={
                                                    domain?.site_icon
                                                        ? domain?.site_icon
                                                        : "/New UI/SVG/web.svg"
                                                }
                                                alt=""
                                            />{" "}
                                        </div>
                                        <div className={styles.ellipsis}>
                                            <div
                                                className={styles.dropDownTitle}
                                            >
                                                {domain?.title}
                                            </div>
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={domain?.domain}
                                                onOpen={(e) => {
                                                    if (
                                                        e.target.scrollWidth <=
                                                        e.target.offsetWidth
                                                    ) {
                                                        setImmediate(() => {
                                                            document
                                                                .querySelectorAll(
                                                                    "[role=tooltip]",
                                                                )
                                                                .forEach(
                                                                    (e) =>
                                                                        (e.style.display =
                                                                            "none"),
                                                                );
                                                        });
                                                    }
                                                }}
                                            >
                                                <div
                                                    className={
                                                        styles.domainName
                                                    }
                                                >
                                                    {domain?.domain}
                                                </div>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "calc(48%)",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p className={styles.label2}>Author:</p>
                                        <div className={styles.domainRow}>
                                            {author ? (
                                                <>
                                                    <img
                                                        loading="lazy"
                                                        width="24px"
                                                        height={"24px"}
                                                        style={{
                                                            borderRadius: "50%",
                                                            marginRight: "10px",
                                                        }}
                                                        src={
                                                            author?.avatar.hasOwnProperty(
                                                                "24",
                                                            )
                                                                ? author
                                                                      ?.avatar[
                                                                      "24"
                                                                  ]
                                                                : "/New UI/Images/user.png"
                                                        }
                                                        alt=""
                                                    />{" "}
                                                    <LightTooltip
                                                        arrow
                                                        placement="bottom"
                                                        title={author?.name}
                                                        onOpen={(e) => {
                                                            if (
                                                                e.target
                                                                    .scrollWidth <=
                                                                e.target
                                                                    .offsetWidth
                                                            ) {
                                                                setImmediate(
                                                                    () => {
                                                                        document
                                                                            .querySelectorAll(
                                                                                "[role=tooltip]",
                                                                            )
                                                                            .forEach(
                                                                                (
                                                                                    e,
                                                                                ) =>
                                                                                    (e.style.display =
                                                                                        "none"),
                                                                            );
                                                                    },
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.authorName2
                                                            }
                                                        >
                                                            {author?.name}
                                                        </div>
                                                    </LightTooltip>
                                                </>
                                            ) : (
                                                <LightTooltip
                                                    arrow
                                                    placement="bottom"
                                                    title={
                                                        "We are unable to fetch authors from Shopify. Your article will be exported with your default user which you used for connecting the app."
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles.publishText
                                                        }
                                                    >
                                                        Default
                                                    </p>
                                                </LightTooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sep2}></div>
                                {/* <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "48%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p className={styles.label2}>
                                            Export Type:
                                        </p>
                                        <p className={styles.publishText}>
                                            {exportType.id == 1
                                                ? "New Post"
                                                : "Update"}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: "48%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p className={styles.label2}>
                                            Post ID:
                                        </p>
                                        <p className={styles.publishText}>
                                            {exportType.id == 1 ? "" : postId}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.sep2}></div> */}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "48%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p className={styles.label2}>
                                            Visibility:
                                        </p>
                                        <p className={styles.publishText}>
                                            {status.value}
                                        </p>
                                    </div>
                                    {/* <div
                                        style={{
                                            width: "48%",
                                            display: "flex",
                                            alignItems: "center",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <p className={styles.label2}>Date:</p>
                                        <p className={styles.publishText}>
                                            {immediate
                                                ? "Immediately"
                                                : `
                                                  ${new Date(
                                                      scheduleTime,
                                                  ).toLocaleDateString()}  ${
                                                      new Date(
                                                          scheduleTime,
                                                      ).getHours() == 12
                                                          ? 12
                                                          : (
                                                                new Date(
                                                                    scheduleTime,
                                                                ).getHours() %
                                                                12
                                                            ).toLocaleString(
                                                                "en-US",
                                                                {
                                                                    minimumIntegerDigits: 2,
                                                                    useGrouping: false,
                                                                },
                                                            )
                                                  }:${new Date(scheduleTime)
                                                      .getMinutes()
                                                      .toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      })} ${
                                                      new Date(
                                                          scheduleTime,
                                                      ).getHours() >= 12
                                                          ? "PM"
                                                          : "AM"
                                                  }`}
                                        </p>
                                    </div> */}
                                </div>
                                <div className={styles.sep2}></div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                >
                                    <p className={styles.label2}>Title:</p>
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={
                                            title?.trim().length > 0
                                                ? title
                                                : attachedTo == SMART_EDITOR
                                                ? docInfo.title
                                                : reportInfo.title
                                        }
                                        onOpen={(e) => {
                                            if (
                                                e.target.scrollWidth <=
                                                e.target.offsetWidth
                                            ) {
                                                setImmediate(() => {
                                                    document
                                                        .querySelectorAll(
                                                            "[role=tooltip]",
                                                        )
                                                        .forEach(
                                                            (e) =>
                                                                (e.style.display =
                                                                    "none"),
                                                        );
                                                });
                                            }
                                        }}
                                    >
                                        <p className={styles.publishText}>
                                            {title?.trim().length > 0
                                                ? title
                                                : attachedTo == SMART_EDITOR
                                                ? docInfo.title
                                                : reportInfo.title}
                                        </p>
                                    </LightTooltip>
                                </div>
                                <div className={styles.sep2}></div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className={styles.label2}>URL Slug:</p>
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={slug?.toLowerCase()}
                                        onOpen={(e) => {
                                            if (
                                                e.target.scrollWidth <=
                                                e.target.offsetWidth
                                            ) {
                                                setImmediate(() => {
                                                    document
                                                        .querySelectorAll(
                                                            "[role=tooltip]",
                                                        )
                                                        .forEach(
                                                            (e) =>
                                                                (e.style.display =
                                                                    "none"),
                                                        );
                                                });
                                            }
                                        }}
                                    >
                                        <p className={styles.publishText}>
                                            {slug?.toLowerCase()}
                                        </p>
                                    </LightTooltip>
                                </div>
                                {featuredImageLocal?.image && (
                                    <>
                                        <div className={styles.sep2}></div>
                                        <div>
                                            <p className={styles.label2}>
                                                Featured Image
                                            </p>
                                            <div
                                                className={
                                                    styles.featuredImageContainer
                                                }
                                            >
                                                {analyzing ? (
                                                    <>
                                                        Uploading...{" "}
                                                        <LoadingDots />
                                                    </>
                                                ) : featuredImageLocal?.image ? (
                                                    <img
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        src={
                                                            featuredImageLocal?.image
                                                        }
                                                    />
                                                ) : (
                                                    "Set Featured Image"
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}

                                {category?.label && (
                                    <>
                                        <div className={styles.sep2}></div>
                                        <div>
                                            <p className={styles.label2}>
                                                Blog Type
                                            </p>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <div className={styles.capsule}>
                                                    {category.title}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {tag.length > 0 && (
                                    <>
                                        <div className={styles.sep2}></div>
                                        <div>
                                            <p className={styles.label2}>
                                                Tags
                                            </p>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                {tag.map((ele) => (
                                                    <div
                                                        className={
                                                            styles.capsule
                                                        }
                                                    >
                                                        {ele.value}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div
                                className={
                                    publishModalCheck ? styles.mainScreen : ""
                                }
                            >
                                <div className={styles.heading}>
                                    <h2>Shopify Publisher</h2>
                                    <VscClose
                                        fontWeight={800}
                                        color="#acb3bf"
                                        onClick={() => {
                                            if (publishModal) {
                                                setPublishModal(false);
                                                setIsExportDraft(false);
                                            } else {
                                                setShopifyApp(false);
                                            }
                                        }}
                                        className={styles.closeIcon}
                                    />
                                </div>
                                <p className={styles.tagline}>
                                    Export article to Shopify directly from
                                    Scalenut.
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ width: "48%" }}>
                                        <p className={styles.label}>Store</p>
                                        <LightTooltip
                                            arrow
                                            title={domain?.domain}
                                        >
                                            <div
                                                className={
                                                    styles.storeNameWrapper
                                                }
                                                style={{ padding: "12px" }}
                                            >
                                                <div
                                                    className={styles.storeName}
                                                >
                                                    {domain?.domain}
                                                </div>
                                            </div>
                                        </LightTooltip>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <p className={styles.label}>Author</p>

                                        <Box
                                            style={{
                                                width: "100%",
                                                boxShadow:
                                                    "0 2px 1px 0 rgba(225, 228, 237, 0.4)",
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <LightTooltip
                                                    arrow
                                                    placement="bottom"
                                                    title={
                                                        authorsData.length == 0
                                                            ? "We are unable to fetch authors from Shopify. Your article will be exported with your default user which you used for connecting the app."
                                                            : ""
                                                    }
                                                >
                                                    <Select
                                                        disabled={
                                                            authorsData.length ==
                                                            0
                                                        }
                                                        classes={{
                                                            select: classes.select,
                                                        }}
                                                        value={author}
                                                        label="Author"
                                                        onChange={
                                                            handleAuthorChange
                                                        }
                                                    >
                                                        {authorsData &&
                                                            authorsData.map(
                                                                (author) => (
                                                                    <MenuItem
                                                                        key={
                                                                            author?.id
                                                                        }
                                                                        value={
                                                                            author
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                whiteSpace:
                                                                                    "nowrap",
                                                                                width: "80%",
                                                                                overflow:
                                                                                    "hidden",
                                                                                textOverflow:
                                                                                    "ellipsis",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.dropdown
                                                                                }
                                                                            >
                                                                                <img
                                                                                    loading="lazy"
                                                                                    width="24px"
                                                                                    height={
                                                                                        "24px"
                                                                                    }
                                                                                    style={{
                                                                                        borderRadius:
                                                                                            "50%",
                                                                                        marginRight:
                                                                                            "10px",
                                                                                    }}
                                                                                    src={
                                                                                        author?.avatar.hasOwnProperty(
                                                                                            "24",
                                                                                        )
                                                                                            ? author
                                                                                                  ?.avatar[
                                                                                                  "24"
                                                                                              ]
                                                                                            : "/New UI/Images/user.png"
                                                                                    }
                                                                                    alt=""
                                                                                />{" "}
                                                                                <div
                                                                                    className={
                                                                                        styles.authorName
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        author?.name
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </MenuItem>
                                                                ),
                                                            )}
                                                    </Select>
                                                </LightTooltip>
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                                <div
                                    className={styles.sep}
                                    style={{ marginTop: "12px" }}
                                ></div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "end",
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: "48%",
                                            boxShadow:
                                                "0 2px 1px 0 rgba(225, 228, 237, 0.4)",
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <p className={styles.label}>
                                                Visibility
                                            </p>
                                            <Select
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                value={status}
                                                label="Status"
                                                onChange={handleStatus}
                                                renderValue={(selected) => (
                                                    <div
                                                        className={
                                                            styles.dropdown
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.authorName
                                                            }
                                                        >
                                                            {selected.value}
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {statusOption &&
                                                    statusOption.map(
                                                        (option) => (
                                                            <MenuItem
                                                                key={
                                                                    option.value
                                                                }
                                                                value={option}
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.dropdown2
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.authorName
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={
                                                                                styles.visibilityOption
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        ),
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {/* <Box style={{ width: "48%" }}>
                                        <p className={styles.label}>
                                            Schedule Date
                                        </p>
                                        <Calendar
                                            immediate={immediate}
                                            setImmediate={setImmediately}
                                            setScheduleTime={setScheduleTime}
                                            scheduleTime={scheduleTime}
                                            period={period}
                                            setPeriod={setPeriod}
                                            index={0}
                                            hours={hours}
                                            setHours={setHours}
                                            minutes={minutes}
                                            setMinutes={setMinutes}
                                            minDate={minDate}
                                            disable={true}
                                        />
                                    </Box> */}
                                </div>
                                <div className={styles.sep}></div>
                                {/* <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "end",
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: "48%",
                                            boxShadow:
                                                "0 2px 1px 0 rgba(225, 228, 237, 0.4)",
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <p className={styles.label}>
                                                Export Type
                                            </p>
                                            <Select
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                value={exportType}
                                                label="Export Type"
                                                onChange={handleExportType}
                                                renderValue={(selected) => (
                                                    <div
                                                        className={
                                                            styles.dropdown
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.authorName
                                                            }
                                                        >
                                                            {selected.value}
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {exportTypeOption &&
                                                    exportTypeOption.map(
                                                        (option) => (
                                                            <MenuItem
                                                                key={
                                                                    option.value
                                                                }
                                                                value={option}
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.dropdown2
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.authorName
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={
                                                                                styles.visibilityOption
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        ),
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box style={{ width: "48%" }}>
                                        <p className={styles.label}>Post ID</p>
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title={
                                                exportType.id == 1
                                                    ? "Not required when creating a new post"
                                                    : ""
                                            }
                                        >
                                            <div>
                                                <Textfield
                                                    errMsg={
                                                        exportType.id == 2
                                                            ? !postId.length > 0
                                                                ? "can not be empty"
                                                                : ""
                                                            : ""
                                                    }
                                                    disabled={
                                                        exportType.id == 1
                                                    }
                                                    border={"1px solid #f1f1f1"}
                                                    label=""
                                                    placeholder=""
                                                    containerStyle={{
                                                        marginBottom: "0px",
                                                        filter: "drop-shadow(0px 2px 1px rgba(225, 228, 237, 0.40))",
                                                    }}
                                                    props={{
                                                        value:
                                                            exportType.id == 1
                                                                ? ""
                                                                : postId,
                                                        onChange: (e) => {
                                                            setPostId(
                                                                e.target.value,
                                                            );
                                                        },
                                                    }}
                                                    customClass={
                                                        styles.postIdField
                                                    }
                                                />
                                            </div>
                                        </LightTooltip>
                                    </Box>
                                </div> */}
                                {/* <div className={styles.sep}></div> */}
                                <div>
                                    <p
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                        className={styles.label}
                                    >
                                        Title{" "}
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title="Title is picked from this document's first H1 heading text. In case there is no H1 heading, title will be your primary keyword."
                                        >
                                            <span style={{ height: "18px" }}>
                                                <SVGIcon
                                                    size={18}
                                                    src={"/New UI/SVG/info.svg"}
                                                />
                                            </span>
                                        </LightTooltip>
                                    </p>
                                    <p className={styles.titleText}>
                                        {title?.trim().length > 0
                                            ? title
                                            : attachedTo == SMART_EDITOR
                                            ? docInfo.title
                                            : reportInfo.title}
                                    </p>
                                </div>
                                <div className={styles.sep}></div>
                                <div>
                                    <p
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                        className={styles.label}
                                    >
                                        URL Slug{" "}
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title="Custom slug creation will not work for the Plain and Numeric permalink type. Slug will be picked from URL slug."
                                        >
                                            <span style={{ height: "18px" }}>
                                                <SVGIcon
                                                    size={18}
                                                    src={"/New UI/SVG/info.svg"}
                                                />
                                            </span>
                                        </LightTooltip>
                                    </p>
                                    <div className={styles.publishText}>
                                        {slug?.toLowerCase()}
                                    </div>
                                    {/* <Textfield
                                        label=""
                                        props={{
                                            style: {
                                                border: "1px solid #f1f1f1",
                                                boxShadow:
                                                    "0 2px 1px 0 rgba(225, 228, 237, 0.4)",
                                                color: "#3f5575",
                                                marginTop: "6px",
                                            },
                                            value: slug?.toLowerCase(),
                                            onChange: (e) => {
                                                setSlug(e.target.value);
                                            },
                                        }}
                                    /> */}
                                    {/* {slug == slugify(slug, slugifyOption) ? (
                                        <div className={styles.generateDisable}>
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title="Slug is already search friendly"
                                            >
                                                <p style={{ margin: "0" }}>
                                                    generate slug
                                                </p>
                                            </LightTooltip>
                                        </div>
                                    ) : (
                                        <div className={styles.generateDisable}>
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title="Click to generate search friendly slug"
                                            >
                                                <p
                                                    className={styles.generate}
                                                    onClick={() =>
                                                        generateSlug()
                                                    }
                                                >
                                                    generate slug
                                                </p>
                                            </LightTooltip>
                                        </div>
                                    )} */}
                                </div>
                                <div className={styles.sep}></div>
                                <div>
                                    <input
                                        ref={ref}
                                        onChange={async (e) => {
                                            if (e.target.files[0]) {
                                                uploadImage(e.target.files[0]);
                                            }
                                        }}
                                        hidden
                                        type="file"
                                        id="img"
                                        name="img"
                                        accept="image/*"
                                    />
                                    <p className={styles.label}>
                                        Featured Image
                                    </p>
                                    <div
                                        onMouseOver={() => setReplace(true)}
                                        onMouseLeave={() => setReplace(false)}
                                        className={
                                            styles.featuredImageContainer
                                        }
                                        onClick={() =>
                                            featuredImageLocal?.image
                                                ? ""
                                                : imageHandler(true)
                                        }
                                    >
                                        {analyzing ? (
                                            <>
                                                Uploading... <LoadingDots />
                                            </>
                                        ) : featuredImageLocal?.image ? (
                                            <img
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                                src={featuredImageLocal?.image}
                                            />
                                        ) : (
                                            "Set Featured Image"
                                        )}
                                        {replace &&
                                            featuredImageLocal?.image && (
                                                <div
                                                    className={
                                                        styles.replaceButton
                                                    }
                                                >
                                                    <Button
                                                        text="Replace"
                                                        width={108}
                                                        style={{
                                                            backgroundColor:
                                                                "transparent",
                                                            border: "solid white 0.1px",
                                                            fontSize: 12,
                                                            height: 40,
                                                        }}
                                                        handler={() => {
                                                            imageHandler(true);
                                                        }}
                                                    ></Button>
                                                </div>
                                            )}
                                    </div>
                                    {Object.keys(featuredImageLocal).length !=
                                        0 && (
                                        <p
                                            onClick={() => {
                                                setFeaturedImageLocal({});
                                            }}
                                            className={styles.removeImage}
                                        >
                                            remove featured image
                                        </p>
                                    )}
                                </div>
                                <div className={styles.sep}></div>
                                <div>
                                    <p className={styles.label}>Blog Type</p>
                                    <ReactSelect
                                        selected={category}
                                        defaultValues={category}
                                        setSelected={setCategory}
                                        setTags={setTags}
                                        closeMenuOnSelect={true}
                                        is_Multi={false}
                                        options={categories}
                                        name={"categories"}
                                    />
                                </div>
                                <div className={styles.sep}></div>
                                <div style={{ marginBottom: "80px" }}>
                                    <p className={styles.label}>Tags</p>

                                    <ReactSelect
                                        selected={[...tag]}
                                        defaultValues={[...tag]}
                                        setSelected={setTag}
                                        setTags={setTags}
                                        options={tags}
                                        name={"tags"}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}

                {removeDomainModal && (
                    <Modal setModal={setRemoveDomainModal}>
                        <RemoveDomainModal
                            setRemoveDomainModal={setRemoveDomainModal}
                            removeDomainName={removeDomainName}
                            removeAccount={removeAccount}
                            load={load}
                            setLoad={setLoad}
                        />
                    </Modal>
                )}
                {addAccountModal && (
                    <Modal setModal={setAddAccountModal}>
                        <AddAccountModal
                            setAddAccountModal={setAddAccountModal}
                            addAccount={addAccount}
                            load={load}
                            setLoad={setLoad}
                            wordpressConnectionError={wordpressConnectionError}
                            setWordpressConnectionError={
                                setWordpressConnectionError
                            }
                            notAllowedError={notAllowedError}
                            setNotAllowedError={setNotAllowedError}
                        />
                    </Modal>
                )}
            </div>
            {!loading && !publishing && (
                <div className={styles.footer}>
                    {success ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <div className={styles.alreadyPublishedText}>
                                    <SVGIcon
                                        width={16}
                                        height={16}
                                        size={16}
                                        style={{ marginRight: "10px" }}
                                        src={"/New UI/SVG/successful.svg"}
                                    />
                                    {
                                        publishStatus == 3
                                            ? `Exported as draft on ${new Date(
                                                  scheduleTime,
                                              ).toLocaleDateString()} ${
                                                  new Date(
                                                      scheduleTime,
                                                  ).getHours() == 12
                                                      ? 12
                                                      : (
                                                            new Date(
                                                                scheduleTime,
                                                            ).getHours() % 12
                                                        ).toLocaleString(
                                                            "en-US",
                                                            {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false,
                                                            },
                                                        )
                                              }:${new Date(scheduleTime)
                                                  .getMinutes()
                                                  .toLocaleString("en-US", {
                                                      minimumIntegerDigits: 2,
                                                      useGrouping: false,
                                                  })} ${
                                                  new Date(
                                                      scheduleTime,
                                                  ).getHours() >= 12
                                                      ? "PM"
                                                      : "AM"
                                              }`
                                            : publishStatus == 2
                                            ? `Scheduled for ${new Date(
                                                  scheduleTime,
                                              ).toLocaleDateString()}  ${
                                                  new Date(
                                                      scheduleTime,
                                                  ).getHours() == 12
                                                      ? 12
                                                      : (
                                                            new Date(
                                                                scheduleTime,
                                                            ).getHours() % 12
                                                        ).toLocaleString(
                                                            "en-US",
                                                            {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false,
                                                            },
                                                        )
                                              }:${new Date(scheduleTime)
                                                  .getMinutes()
                                                  .toLocaleString("en-US", {
                                                      minimumIntegerDigits: 2,
                                                      useGrouping: false,
                                                  })} ${
                                                  new Date(
                                                      scheduleTime,
                                                  ).getHours() >= 12
                                                      ? "PM"
                                                      : "AM"
                                              }`
                                            : `Published on ${new Date(
                                                  scheduleTime,
                                              ).toLocaleDateString()}  `
                                        //   ${
                                        //       new Date(scheduleTime).getHours() ==
                                        //       12
                                        //           ? 12
                                        //           : (
                                        //                 new Date(
                                        //                     scheduleTime,
                                        //                 ).getHours() % 12
                                        //             ).toLocaleString("en-US", {
                                        //                 minimumIntegerDigits: 2,
                                        //                 useGrouping: false,
                                        //             })
                                        //   }:${new Date(scheduleTime)
                                        //       .getMinutes()
                                        //       .toLocaleString("en-US", {
                                        //           minimumIntegerDigits: 2,
                                        //           useGrouping: false,
                                        //       })} ${
                                        //       new Date(scheduleTime).getHours() >=
                                        //       12
                                        //           ? "PM"
                                        //           : "AM"
                                        //   }`
                                    }
                                </div>
                                {totalExports > 0 && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span className={styles.totalExports}>
                                            Total Exports: {totalExports.length}
                                        </span>
                                        <span
                                            onClick={() =>
                                                setViewAllExports(true)
                                            }
                                            className={styles.viewAllPosts}
                                        >
                                            View All
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <a href={postLink} target="_blank">
                                    <HiOutlineExternalLink
                                        color="#2A4266"
                                        style={{ marginRight: "10px" }}
                                        size={14}
                                    />
                                </a>
                                {/* <Button
                                    style={{
                                        background: "rgba(31, 76, 190, 0.1)",
                                        color: "#014dc5",
                                        fontSize: 14,
                                    }}
                                    text={"Edit Again"}
                                    width={100}
                                    handler={() => {
                                        if (!featuredImageLocal?.image) {
                                            if (
                                                document.quill.editor.root.getElementsByTagName(
                                                    "img",
                                                ).length > 0
                                            ) {
                                                setFeaturedImageLocal({
                                                    id: "",
                                                    image: document.quill.editor.root.getElementsByTagName(
                                                        "img",
                                                    )[0].src,
                                                });
                                            }
                                        }
                                        setSuccess(false);
                                        setPublishModal(false);
                                        setIsUpdate(true);
                                        setExportType(exportTypeOption[0]);
                                    }}
                                /> */}
                            </div>
                            {/* <a
                                href={postLink}
                                target="_blank"
                                className={styles.viewButton}
                            >
                                View Post
                            </a> */}
                        </div>
                    ) : publishModal ? (
                        <>
                            <Button
                                disabled={
                                    loading || successfulPublish || publishing
                                }
                                handler={() => {
                                    setPublishModal(false);
                                    setIsExportDraft(false);
                                }}
                                style={{
                                    background: "#fff",
                                    border: "solid 1px #6b6b6b",
                                    color: "#6b6b6b",
                                    marginRight: "15px",
                                }}
                                width={140}
                                height={40}
                                text="Cancel"
                            />
                            <Button
                                disabled={
                                    loading || realtimeRating.wordCount < 1
                                }
                                handler={() => {
                                    publish();
                                    mixpanelTrack("Shopify App", {
                                        action: "Export(final)",
                                    });
                                }}
                                loading={publishing}
                                width={160}
                                height={40}
                                text={"Export"}
                            />
                        </>
                    ) : (
                        <>
                            {isUpdate && (
                                <LightTooltip arrow placement="top" title={""}>
                                    <div>
                                        <Button
                                            customClass={styles.draftButton}
                                            disabled={
                                                loading ||
                                                minDate -
                                                    new Date(
                                                        scheduleTime,
                                                    ).getTime() >=
                                                    0
                                            }
                                            style={{
                                                opacity:
                                                    realtimeRating.wordCount < 1
                                                        ? "0.3"
                                                        : "1",
                                            }}
                                            width={160}
                                            height={40}
                                            text="Cancel"
                                            handler={() => {
                                                getShopifyDataSnap();
                                            }}
                                        />
                                    </div>
                                </LightTooltip>
                            )}
                            <LightTooltip
                                arrow
                                placement="top"
                                title={
                                    realtimeRating.wordCount < 1
                                        ? "Empty content can not be published."
                                        : ""
                                }
                            >
                                <div>
                                    <Button
                                        disabled={
                                            loading ||
                                            minDate -
                                                new Date(
                                                    scheduleTime,
                                                ).getTime() >=
                                                0 ||
                                            category.length == 0
                                        }
                                        handler={() => {
                                            if (
                                                exportType.id == 2 &&
                                                !postId.length > 0
                                            ) {
                                                return;
                                            }
                                            if (realtimeRating.wordCount > 0) {
                                                setSlug(
                                                    slugify(
                                                        slug,
                                                        slugifyOption,
                                                    ),
                                                );
                                                setPublishModal(true);
                                                setIsExportDraft(false);
                                            }
                                            mixpanelTrack("Shopify App", {
                                                action: "Export(1st)",
                                            });
                                        }}
                                        width={160}
                                        height={40}
                                        style={{
                                            fontWeight: "400 !important",
                                            opacity:
                                                realtimeRating.wordCount < 1 ||
                                                category.length == 0
                                                    ? "0.3"
                                                    : "1",
                                        }}
                                        text={
                                            // exportType.id == 2
                                            //     ? "Update"
                                            //     : status.id == 1
                                            // ?
                                            "Export"
                                            // : status.id == 2
                                            // ? "Schedule"
                                            // : "Export as Draft"
                                        }
                                    />
                                </div>
                            </LightTooltip>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

const RemoveDomainModal = ({
    setRemoveDomainModal,
    removeDomainName,
    removeAccount,
    load,
    setLoad,
}) => {
    return (
        <div className={styles.removeDomainModal}>
            <CloseButton
                style={{ color: "#acb3bf", right: 10, top: 10 }}
                setClose={setRemoveDomainModal}
            />
            <p>
                Are you sure you want to remove “{removeDomainName}” account
                from the Shopify App?
            </p>
            <div className={styles.actions}>
                <Button
                    handler={() => setRemoveDomainModal(false)}
                    text="Cancel"
                    style={{
                        width: "120px",
                        height: "44px",
                        background: "#f1f1f1",
                        borderRadius: "3px",
                        color: "#acb3bf",
                        fontWeight: "600",
                        marginRight: "5px",
                    }}
                />
                <Button
                    loading={load}
                    handler={() => {
                        setLoad(true);
                        removeAccount();
                    }}
                    text="Delete"
                    style={{
                        width: "120px",
                        height: "44px",
                        background: "#fc5a5a",
                        borderRadius: "3px",
                        color: "#fff",
                        fontWeight: "600",
                        marginLeft: "5px",
                    }}
                />
            </div>
        </div>
    );
};

const AllExports = ({ allPosts, setViewAllExports }) => {
    return (
        <div className={styles.allPostsWrapper}>
            <div className={styles.allPostsContainer}>
                <div className={styles.allPostHeading}>
                    <span>Exported Links</span>
                    <VscClose
                        style={{ cursor: "pointer" }}
                        onClick={() => setViewAllExports(false)}
                    />
                </div>
                <div className={styles.sep3}></div>
                <div
                    style={{ maxHeight: "calc(100% - 37px)", overflow: "auto" }}
                >
                    {allPosts.map((post, i) => (
                        <>
                            <div className={styles.postRow}>
                                <div style={{ width: "40%" }}>
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={post.publish_link}
                                    >
                                        <div className={styles.postName}>
                                            {post.publish_link}
                                        </div>
                                    </LightTooltip>
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={"Created at"}
                                    >
                                        <div className={styles.postDate}>
                                            {new Date(
                                                post.created_at,
                                            ).toLocaleDateString(undefined, {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                            })}{" "}
                                            |{" "}
                                            {new Date(
                                                post.created_at,
                                            ).getHours() == 12
                                                ? 12
                                                : (
                                                      new Date(
                                                          post.created_at,
                                                      ).getHours() % 12
                                                  ).toLocaleString("en-US", {
                                                      minimumIntegerDigits: 2,
                                                      useGrouping: false,
                                                  })}
                                            :
                                            {new Date(post.created_at)
                                                .getMinutes()
                                                .toLocaleString("en-US", {
                                                    minimumIntegerDigits: 2,
                                                    useGrouping: false,
                                                })}
                                            {new Date(
                                                post.created_at,
                                            ).getHours() >= 12
                                                ? " PM"
                                                : " AM"}
                                            {/* date | time */}
                                        </div>
                                    </LightTooltip>
                                </div>
                                <div
                                    style={{
                                        width: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className={
                                            JSON.parse(post.settings)
                                                .post_status == 2
                                                ? styles.updatedPost
                                                : styles.newPost
                                        }
                                    >
                                        {JSON.parse(post.settings)
                                            .post_status == 2
                                            ? "Update"
                                            : "New"}
                                    </div>
                                </div>
                                <a href={post.publish_link} target="_blank">
                                    <HiOutlineExternalLink
                                        color="#2A4266"
                                        size={14}
                                        style={{ cursor: "pointer" }}
                                    />
                                </a>
                            </div>
                            {allPosts.length - 1 !== i ? (
                                <div className={styles.sep3}></div>
                            ) : (
                                <></>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

const AddAccountModal = ({
    setAddAccountModal,
    addAccount,
    load,
    setLoad,
    wordpressConnectionError,
    setWordpressConnectionError,
    notAllowedError,
    setNotAllowedError,
}) => {
    const [username, setUsername] = useState("");
    const [wordpressPassword, setWordpressPassword] = useState("");
    const [domain, setDomain] = useState("");
    return (
        <div style={{ alignItems: "center" }} className={styles.accountModal}>
            {/* <CloseButton
                style={{ color: "#acb3bf", right: 10, top: 10 }}
                setClose={setAddAccountModal}
            /> */}
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setWordpressConnectionError(false);
                    setNotAllowedError(false);
                    setAddAccountModal(false);
                }}
                style={{
                    position: "absolute",
                    color: "#acb3bf",
                    right: 10,
                    top: 10,
                }}
                className={styles.modalClose}
            />

            <p
                style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#3f5575",
                    textAlign: "left",
                    marginTop: "10px",
                    marginBottom: "24px",
                }}
            >
                Add Account
            </p>

            <div style={{ textAlign: "left" }}>
                <label className={styles.mandatoryLabel}>Shopify Domain</label>
                <Textfield
                    containerStyle={{
                        marginBottom: "12px",
                    }}
                    placeholder="https://www.scalenut.com"
                    label=""
                    props={{
                        style: {
                            fontWeight: "400",
                        },
                        value: domain,
                        onChange: (e) => {
                            setDomain(e.target.value);
                        },
                        autoComplete: "off",
                    }}
                    errMsg={
                        domain?.length > 0 &&
                        domain.toLowerCase().indexOf("https://") !== 0
                            ? "Domain should start with https://"
                            : ""
                    }
                />

                <label className={styles.mandatoryLabel}>Username</label>
                <Textfield
                    containerStyle={{
                        marginBottom: "12px",
                    }}
                    placeholder="Enter username"
                    label=""
                    props={{
                        style: {
                            fontWeight: "400",
                        },
                        value: username,
                        onChange: (e) => {
                            setUsername(e.target.value);
                        },
                        autoComplete: "off",
                    }}
                />

                <label className={styles.mandatoryLabel}>
                    Application Password{" "}
                    <LightTooltip
                        arrow
                        placement="bottom"
                        title="Application Password is a 24-characters long key which can be generated via your Shopify admin panel for 3rd party applications. You can not use your website password here."
                    >
                        <div
                            style={{ marginBottom: "-3px", marginLeft: "3px" }}
                        >
                            <SVGIcon
                                // style={{ marginLeft: "4px" }}
                                size={18}
                                src={"/New UI/SVG/info.svg"}
                            />
                        </div>
                    </LightTooltip>
                </label>
                <div>
                    <Textfield
                        containerStyle={{
                            marginBottom: "18px",
                        }}
                        label=""
                        type={"password"}
                        placeholder={"Enter Password"}
                        id="newPassword"
                        name="newPassword"
                        props={{
                            style: {
                                fontWeight: "400",
                            },
                            value: wordpressPassword,
                            onChange: (e) => {
                                setWordpressPassword(e.target.value);
                            },
                            autoComplete: "off",
                        }}
                    />
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                }}
            >
                <Button
                    handler={() => {
                        window.open(
                            "https://help.scalenut.com/wordpress-x-scalenut-how-to-publish-an-article-on-wordpress-from-scalenut/",
                        );
                    }}
                    width="130px"
                    height="44px"
                    text={"Need Help"}
                    style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        background: "rgba(1, 77, 197, 0.1)",
                        color: "#014DC5",
                    }}
                    Icon={() => <SVGIcon src="/New UI/SVG/need_help.svg" />}
                    iconReverse
                />
                <Button
                    loading={load}
                    handler={() => {
                        setLoad(true);
                        addAccount(domain, username, wordpressPassword);
                    }}
                    style={{ fontSize: "14px", fontWeight: "500", margin: "0" }}
                    width="130px"
                    height="44px"
                    text={"Connect"}
                    disabled={
                        !domain ||
                        domain.toLowerCase().indexOf("https://") !== 0 ||
                        !username ||
                        !wordpressPassword
                    }
                />
            </div>
            {wordpressConnectionError && (
                <div className={styles.errorContainer}>
                    We are unable to connect with your Shopify domain. For more
                    information, please visit this{" "}
                    <a
                        href="https://help.scalenut.com/unable-to-connect-your-wordpress-to-scalenut-follow-these-steps/"
                        target="_blank"
                    >
                        help article
                    </a>{" "}
                </div>
            )}
            {notAllowedError && (
                <div className={styles.errorContainer}>
                    You are not allowed to log in as this user type. For more
                    information, please visit this{" "}
                    <a
                        href="https://help.scalenut.com/unable-to-connect-your-wordpress-to-scalenut-follow-these-steps/"
                        target="_blank"
                    >
                        help article
                    </a>
                </div>
            )}
        </div>
    );
};
