import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  forgotPass,
} from "../../../../store/actions/resetPassword";
import ValidationError from "../../../../components/common/validationError";
import validationSuccess from "../../../../components/common/validationSuccess";
import ValidationSuccess from "../../../../components/common/validationSuccess";
import TextField from "../../../atoms/CustomTextfield/Textfield";
import Button from "../../../atoms/Button/Button";
import styles from "../styles.module.css";
import Spacer from "../../../atoms/Spacer/Spacer";
import { GoCheck } from "react-icons/go";
import { emailValidatorS } from "../../../../utility/validateFunctions";

function ForgotPassword() {
  const error = useSelector((state) => state.reset.message_fail);
  const message = useSelector((state) => state.reset.message_success);
  const loading = useSelector((state) => state.reset.loading);
  const email = useSelector((state) => state.auth.email);
  const [_email, setEmail] = useState(email);

  const [validationError, setValidationError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  function sendRecoveryLink(e) {
    e.preventDefault();
    dispatch(clearMessage());
    if (emailValidatorS(_email ? _email : email)) {
      setValidationError(null);

      dispatch(forgotPass({ email: _email ? _email : email }));
    }
  }

  return (
    <>
      {validationError ? <ValidationError msg={validationError} /> : null}
      {message ? <ValidationSuccess msg={message} /> : null}
      {error ? <ValidationError msg={error} /> : null}
      {error || validationError || message ? <Spacer spacing={15} /> : null}

      <TextField
        props={{
          value: _email,
          onChange: (x) => setEmail(x.target.value),
          disabled: message,
          name: "email",
        }}
        height={44}
        placeholder={"Enter your email"}
        label="Enter your email"
        type="email"
      />
      <Spacer spacing={15} />

      <Button
        loading={loading}
        handler={sendRecoveryLink}
        height={44}
        text={message ? null : "Submit"}
        Icon={message ? GoCheck : null}
        disabled={message}
      />
    </>
  );
}

export default ForgotPassword;
