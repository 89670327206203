import {
  UPDATE_PROJECTS_DATA,
  LOAD_PROJECTS,
  OPEN_PROJECT,
  ADD_PROJECT,
  REMOVE_PROJECT,
  AUTH_LOGOUT,
  AUTH_LOGOUT_DASHBOARD,
} from "../actiontypes";

const initialState = {
  projects: [],
  openProject: false,
  projectId: 0,
};

function updateProject(state, action) {
  try {
    return state.projects.map((project) => {
      if (action.id == project.project_id) {
        project = { ...project, ...action };
      }
      return project;
    });
  } catch {
    return state.projects;
  }
}

function addProject(state, project) {
  project.project_id = project.id;
  project.display = "no";

  try {
    if (state.openProject) state.projects.push(project);
    return state.projects;
  } catch {
    return state.projects;
  }
}

// function removeProject(state) {

//   try {
//     if (state.projects[state.projects.length - 1].id) state.projects.pop();
//     return state.projects;
//   } catch {
//     return state.projects;
//   }
// }

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROJECTS_DATA:
      return {
        ...state,
        projects: updateProject(state, action),
      };
    case LOAD_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case OPEN_PROJECT:
      return {
        ...state,
        projectId: action.projectId,
        openProject: action.openProject,
      };

    case ADD_PROJECT:
      return {
        ...state,
        projects: addProject(state, action.project),
      };

    case REMOVE_PROJECT:
      return {
        ...state,
        projects: removeProject(state),
      };
    case AUTH_LOGOUT_DASHBOARD:
      return initialState;
    default:
      return state;
  }
}
