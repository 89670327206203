import React, { useEffect, useState } from "react";
import styles from "./UserPlanUpdate.module.css";
import { url } from "../../utility/config";
import moment from "moment";

import EmailSuggestion from "./EmailSuggestion";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { PLANS_TYPE } from "../../constants";

const UserPlanUpdate = ({ setActiveAction }) => {
    const [userEmail, setUserEmail] = useState();
    const [currentInfo, setCurrentInfo] = useState();
    const [updatePlan, setUpdatePlan] = useState();
    const [plans, setPlans] = useState([]);
    const [random, setRandom] = useState(1);
    const [loading, setLoading] = useState(false);
    const [expiry, setExpiry] = useState(moment().add(1, "months").toDate());
    const [userNotRegisteredError, setUserNotRegisteredError] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [cdrError, setCDRError] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState("");

    const { getData, postData } = useHelperFunctions();

    const planPeriods = [
        {
            periodName: "Monthly",
            periodValue: "1",
        },
        {
            periodName: "Annual",
            periodValue: "2",
        },
    ];
    const subscriptionTypes = [
        {
            type: "Paid",
        },
        {
            type: "Promotional",
        },
    ];

    useEffect(() => {
        if (userEmail) getPlans(userEmail);
    }, [userEmail, selectedPeriod]);

    useEffect(() => {
        setSelectedPeriod(0);
        setSubscriptionType(0);
        setUpdatePlan(-1);
        setExpiry(moment().add(1, "months").toDate());
    }, [userEmail]);

    async function getPlans(email) {
        setLoading(true);

        const res = await getData({
            url: `/api/superAdmin/getPlans?userEmail=${email}`,
        });
        if (res.status == 200) {
            setPlans(
                res.data?.data?.subsPlans ? res.data?.data?.subsPlans : [],
            );
            let temp = res.data.data.subscriptionInfo;
            setCurrentInfo({
                ...temp,
                fullName: getPlanFullName(temp),
            });
            setLoading(false);
        } else {
            setLoading(false);
        }
    }
    function getPlanFullName(temp) {
        let fname = temp.plan_name;
        if (temp.type == PLANS_TYPE.MONTHLY) {
            fname = fname + " Monthly";
        } else if (temp.type == PLANS_TYPE.YEARLY) {
            fname = fname + " Yearly";
        }
        return fname;
    }
    // function handleEmail() {
    //   getPlans(userEmail)
    //   // setUserEmail(e.target.value);
    // }

    function handlePlans(e) {
        setUpdatePlan(e.target.value);
    }

    async function handleSubmit(e) {
        // handleUpdate();
        if (!userEmail) {
            setCDRError("Email is Required");
        } else if (selectedPeriod == 0) {
            setCDRError("Please select a valid plan period");
        } else if (!updatePlan) {
            setCDRError("Select a valid Plan");
        } else if (subscriptionType == 0) {
            setCDRError("Select a valid Subscription type");
        } else if (!expiry) {
            setCDRError("Plan Expiration Date Required");
        } else {
            axios({
                method: "post",
                url: `${url}/api/user/checkEmail`,
                data: { email: userEmail },
            })
                .then((res) => {
                    if (res.status === 201) {
                        setLoading(false);
                        setUserNotRegisteredError(true);
                        setTimeout(
                            () => setUserNotRegisteredError(false),
                            5000,
                        );
                    } else if (res.status === 200) {
                        handleUpdate();
                    }
                })
                .catch((err) => {
                    setCDRError(err);
                    setLoading(false);
                    setTimeout(() => setCDRError(false), 5000);
                });
        }
        setLoading(false);
        setTimeout(() => setCDRError(false), 3000);
    }

    async function handleUpdate() {
        let payload = {
            userEmail,
            plan_id: updatePlan,
            expiration_date: expiry,
            subscriptionType,
        };
        const res = await postData({
            url: "/api/superAdmin/updatePlan",
            payload: payload,
        });
        if (res.status == 200) {
            setSuccessMessage(true);
            setTimeout(() => setSuccessMessage(false), 5000);
            setUserEmail("");
            setCurrentInfo("");
            setUpdatePlan(-1);
            setExpiry(moment().add(1, "months").toDate());
            setLoading(false);
            setSelectedPeriod(0);
            setSubscriptionType(0);
            setRandom(Math.random());
        } else {
            setCDRError(res);
            setLoading(false);
            setTimeout(() => setCDRError(false), 5000);
        }
    }

    return (
        <div className={styles.container}>
            <p></p>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <div className={styles.userContainer}>
                <div className={styles.inputGroup}>
                    <EmailSuggestion
                        email={userEmail}
                        setEmail={setUserEmail}
                        random={random}
                    />
                </div>
                {currentInfo && (
                    <div className={styles.inputGroup}>
                        <label>Current Plan</label>
                        <input
                            name=""
                            value={currentInfo?.fullName}
                            disabled={true}
                        />
                    </div>
                )}
                {currentInfo && (
                    <div className={styles.inputGroup}>
                        <label>Current Expiry Date : </label>
                        <input
                            name=""
                            value={moment(currentInfo.expiration_at).format(
                                "YYYY/MM/DD HH:mm:ss",
                            )}
                            disabled={true}
                        />
                    </div>
                )}
                {/* select plan period */}
                <div className={styles.inputGroup}>
                    <label>Select Plan Period : </label>

                    <select
                        name="plan_period"
                        key="1"
                        onChange={(e) => setSelectedPeriod(e.target.value)}
                        className={styles.dropPlans}
                    >
                        <option selected={selectedPeriod == 0} value={0}>
                            Select Plan Period
                        </option>
                        {planPeriods.map((item) => {
                            return (
                                <option
                                    key={item.periodName}
                                    className={styles.dropitem}
                                    value={item.periodValue}
                                >
                                    {item.periodName}
                                </option>
                            );
                        })}
                    </select>
                </div>

                {/* select new plan */}
                <div className={styles.inputGroup}>
                    <label>Select New Plan : </label>
                    {plans.length == 0 ? (
                        <select
                            name="demoPlan"
                            key="2"
                            onChange={(e) => handlePlans(e)}
                            className={styles.dropPlans}
                        >
                            <option selected={updatePlan == -1} value={-1}>
                                Select Plan
                            </option>
                        </select>
                    ) : (
                        <select
                            name="realPlan"
                            key="22"
                            onChange={(e) => handlePlans(e)}
                            className={styles.dropPlans}
                        >
                            <option selected={updatePlan == -1} value={-1}>
                                Select Plan
                            </option>
                            {plans.map((item) => {
                                if (item.plan_type == selectedPeriod)
                                    return (
                                        <option
                                            key={item.plan_id}
                                            className={styles.dropitem}
                                            value={item.plan_id}
                                        >
                                            {item.plan_name}
                                        </option>
                                    );
                            })}
                        </select>
                    )}
                </div>

                {/* select subscriptionType */}
                <div className={styles.inputGroup}>
                    <label>Select Subscription Type: </label>
                    {subscriptionTypes.length == 0 ? (
                        <select
                            name="demoType"
                            key="3"
                            onChange={(e) => handlePlans(e)}
                            className={styles.dropPlans}
                        >
                            <option selected={updatePlan == -1} value={-1}>
                                Select Subscription Type
                            </option>
                        </select>
                    ) : (
                        <select
                            name="realType"
                            key="33"
                            onChange={(e) =>
                                setSubscriptionType(e.target.value)
                            }
                            className={styles.dropPlans}
                        >
                            <option
                                selected={subscriptionType == ""}
                                value={""}
                            >
                                Select Subscription Type
                            </option>
                            {subscriptionTypes.map((item) => {
                                return (
                                    <option
                                        key={item.type}
                                        className={styles.dropitem}
                                        value={item.type}
                                    >
                                        {item.type}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                </div>

                <div className={styles.marginBox}>
                    <label htmlFor="cdrExpiry">Choose New Expiry Date:</label>
                    <DateTimePicker
                        value={expiry}
                        minDate={new Date()}
                        onChange={setExpiry}
                    />
                </div>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <button
                        className={styles.submitBtn}
                        type={"button"}
                        onClick={(e) => handleSubmit(e)}
                    >
                        Update
                    </button>
                )}
            </div>
            {userNotRegisteredError && <p>User not registered</p>}
            {successMessage && <p>Plan updated successfully</p>}
            {cdrError && <p>{cdrError}</p>}
        </div>
    );
};

export default UserPlanUpdate;
