import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../componentsV2/atoms/Button/Button";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { displayAlert } from "../../store/actions/alert";
import styles from "./BFDealsUpdates.module.css";

const BFDealsUpdates = ({ setActiveAction }) => {
    const [payload, setPayload] = useState({ name: "", value: "" });
    const [bfDeals, setBfDeals] = useState(
        useSelector((state) => JSON.parse(state.appConfig.deals)),
    );
    const [currentDetails, setCurrentDetails] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { postData } = useHelperFunctions();

    const catType = [
        {
            name: "Annual Plan Discount",
            key: "annualPlanDiscount",
        },
        {
            name: "Semi Annual Plan Discount",
            key: "semiAnnualPlanDiscount",
        },
        {
            name: "Deal Credit Used",
            key: "dealCreditsUsd",
        },
        {
            name: "Deal Limit Multiplier",
            key: "dealLimitsMultiplier",
        },
        {
            name: "Slots Left",
            key: "slotsLeft",
        },
    ];

    useEffect(() => {
        if (payload.name) {
            setCurrentDetails(bfDeals[payload.name]);
        }
    }, [payload.name]);

    async function updateBFDeals() {
        if (!payload.name || !payload.value) {
            setPayload({ name: "", value: "" });
            dispatch(
                displayAlert({
                    alertMessage: "Filed are mandatory",
                    alertType: "error",
                }),
            );
            return;
        }
        setIsLoading(true);
        let pyld = {
            name: "deals",
            value: { ...bfDeals, [payload.name]: payload.value },
        };
        const res = await postData({
            url: "/api/superAdmin/updateConstant",
            payload: pyld,
        });
        if (res.status == 200) {
            setPayload({ name: "", value: "" });
            setBfDeals(pyld.value);
            dispatch(
                displayAlert({
                    alertMessage: "BF Deal Updated",
                    alertType: "success",
                }),
            );
        }
        setIsLoading(false);
    }

    return (
        <div className={styles.container}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <div>
                <div className={styles.currentValues}>
                    <table className={styles.currentvaluesTable}>
                        <thead className={styles.currentvaluesTable_head}>
                            <th>Filed Type</th>
                            <th>Current Value</th>
                        </thead>
                        <tbody className={styles.currentvaluesTable_body}>
                            {Object.entries(bfDeals).map(([key, value]) => {
                                if (key == "name" || key == "dealCreditInr")
                                    return;
                                return (
                                    <tr>
                                        <td style={{ textAlign: "left" }}>
                                            {key}
                                        </td>
                                        <td>{value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={styles.UpdateContainer}>
                    {/* select value type */}
                    <div className={styles.inputGroup}>
                        <label>Select Filed Type *: </label>

                        <select
                            name="realType"
                            key="33"
                            onChange={(e) =>
                                setPayload({ name: e.target.value, value: "" })
                            }
                            className={styles.dropPlans}
                        >
                            <option selected={payload.name == ""} value={""}>
                                Select Filed Type
                            </option>
                            {catType.map((item) => {
                                return (
                                    <option
                                        key={item.key}
                                        className={styles.dropitem}
                                        value={item.key}
                                    >
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {/* Show updated value */}
                    {/* <div className={styles.inputGroup}>
                        <label>Current Value: </label>
                        <input
                            placeholder="Select Value Type"
                            value={currentDetails}
                            disabled={true}
                        />
                    </div> */}
                    {/* enter input value */}
                    <div className={styles.inputGroup}>
                        <label>Enter New Value* : </label>
                        <input
                            placeholder="Enter New Value"
                            value={payload.value}
                            onChange={(e) => {
                                setPayload({
                                    ...payload,
                                    value: e.target.value,
                                });
                            }}
                        />
                    </div>

                    <Button
                        text={"Update"}
                        handler={() => updateBFDeals()}
                        loading={isLoading}
                        width={300}
                    />
                </div>
            </div>
        </div>
    );
};

export default BFDealsUpdates;
