import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { useDispatch } from "react-redux";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { displayAlert } from "../../store/actions/alert";
import styles from "./UserUsage.module.css";

function Row({ item, updateInfo }) {
  const [date, setDate] = useState(item.value);

  return (
    <tr className={item.isEdit ? styles.edit : ""}>
      <td>
        <div>{item.label}</div>
      </td>
      <td>
        {item.label.includes("date") && item.isEdit ? (
          <div className={styles.editableDiv}>
            <DateTimePicker
              onChange={(date) => {
                setDate(new Date(date));
                updateInfo(item.key, new Date(date).toISOString());
              }}
              value={new Date(date)}
            />
          </div>
        ) : (
          <div
            className={styles.editableDiv}
            onBlur={(e) => {
              updateInfo(item.key, e.target.innerText);
            }}
            contentEditable={item.isEdit ? true : false}
          >
            {item.value}
          </div>
        )}
      </td>
    </tr>
  );
}

function UserUsage({ setActiveAction }) {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState({ id: "", email: "" });
  const [loading, setLoading] = useState(false);
  const { getData, postData } = useHelperFunctions();
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId.id) getInfo();
  }, [userId]);

  const getInfo = async () => {
    setLoading(true);
    const res = await getData({
      url: "/api/user/getUserInfoAdmin?userId=" + userId.id,
    });
    console.log(res);
    if (res.data) {
      setUserData(res.data);
    }
    setLoading(false);
  };

  const updateInfo = async (field, value) => {
    setLoading(true);
    const payload = {
      userId: userId.id,
    };
    payload[field] = value;
    const res = await postData({
      url: "/api/user/updateUserInfoAdmin",
      payload,
    });
    console.log(res);
    if (res.status == 200) {
      if (userId.id) await getInfo();
      displayAlert({
        alertMessage: "Updated successfully",
        alertType: "success",
      });
    }
    setLoading(false);
  };

  return (
    <div className={styles.userUsageMain}>
      <p
        style={{
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
          display: "inline-block",
          marginRight: "auto",
        }}
        onClick={() => setActiveAction(null)}
      >
        {"< Go back"}
      </p>
      <div className={styles.userUsageInput}>
        <AutoComplete email={email} setEmail={setEmail} setUserId={setUserId} />
      </div>
      {!loading && userId.id && (
        <div className={styles.userUsageTableContainer}>
          <table className={styles.table}>
            <tr>
              <th></th>
              <th></th>
            </tr>
            {userData.map((item) => (
              <Row item={item} updateInfo={updateInfo} />
            ))}
          </table>
        </div>
      )}
      {loading && <CircularProgress size={18} style={{ marginTop: "50px" }} />}
    </div>
  );
}

export default UserUsage;
