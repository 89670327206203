import React, { useEffect, useState } from "react";
import Modal from "../../atoms/Modal/Modal";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import styles from "./InterLinkingExitIntent.module.css";
import useHelperFunctions from "../../helperFunctions";
import mixpanel from "mixpanel-browser";

const InterLinkingExitIntent = ({ handler = () => {} }) => {
    const [open, setOpen] = useState(true);
    const { postData } = useHelperFunctions();
    useEffect(() => {
        if (mixpanel) {
            mixpanel.track("LM Promotional Popup", {
                "CTA Type": "Popup",
                "CTA Used": "Popup",
            });
        }
    }, []);
    // useEffect(() => {
    //     document.onmouseleave = () => {
    //         setOpen((ps) => (ps ? ps - 1 : ps));
    //     };

    //     setTimeout(() => {
    //         setOpen((ps) => (ps ? ps - 1 : ps));
    //     }, 30000);

    //     document.onbeforeunload = () => {
    //         return "are you sure?";
    //     };
    //     return () => {
    //         document.removeEventListener("mouseout", setOpen);
    //         document.onmouseleave = () => {};
    //     };
    // }, []);
    const updatePromotionalModal = async () => {
        const res = await postData({ url: "/api/user/updatePromotionalModal" });
    };

    return (
        <>
            {open && (
                <Modal
                    setModal={() => {
                        setOpen(false);
                        updatePromotionalModal();
                    }}
                >
                    <div className={styles.lmIntentPopup}>
                        <span
                            onClick={() => {
                                setOpen(false);
                                mixpanel.track("LM Promotional Popup", {
                                    "CTA Type": "Close",
                                    "CTA Used": "Close",
                                });
                                updatePromotionalModal();
                            }}
                            className={styles.cross}
                        >
                            <SVGIcon
                                color="white"
                                src="/New UI/SVG/cross.svg"
                            />
                        </span>
                        <div className={styles.upperTextContainer}>
                            <div className={styles.metaBox}>
                                <div className={styles.metaBox_line1}>
                                    Build Internal Links, Automatically!
                                    <div className={styles.underline}>
                                        <SVGIcon
                                            src={
                                                "/New UI/SVG/inter_underline.svg"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={styles.metaBox_line2}>
                                    <div className={styles.logoWrapper}>
                                        <SVGIcon
                                            src={"/New UI/SVG/link.svg"}
                                            size={30}
                                            style={{
                                                color: "#014DC5",
                                            }}
                                        />
                                    </div>
                                    Link Manager
                                </div>
                                <Button
                                    style={{
                                        background: "#FFFFFF",
                                        color: "#1A5BF0",
                                        width: "fit-content",
                                        padding: "20px 18px",
                                        borderRadius: "30px",
                                        fontSize: "20px",
                                        position: "absolute",
                                        bottom: "32px",
                                        margin: "auto",
                                    }}
                                    text={"Try For Free"}
                                    handler={() => {
                                        handler();
                                        setOpen(false);
                                        mixpanel.track("LM Promotional Popup", {
                                            "CTA Type": "Try For Free",
                                            "CTA Used": "Try For Free",
                                        });
                                        updatePromotionalModal();
                                    }}
                                    Icon={() => (
                                        <SVGIcon
                                            src={
                                                "/New UI/SVG/tilt-bold-arrow.svg"
                                            }
                                            size={16}
                                            style={{
                                                color: "#1A5BF0",
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {/*   <div className={styles.bottomBlueContainer}>
                          <div className={styles.dataBox}>
                                <div className={styles.dataBox_line1}>
                                    Special Launch Offer!
                                </div>
                                <div className={styles.dataBox_line2}>
                                    <strong>50%</strong> across Scalenut plans
                                    and AI Link Manager
                                </div>
                            </div>
                            </div> */}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default InterLinkingExitIntent;
