import {
    Box,
    CircularProgress,
    LinearProgress,
    Typography,
    makeStyles,
} from "@material-ui/core";
import React from "react";
import styles from "./AIHumanizer.module.css";
import CountUp from "react-countup";

function AIHumanizerMeter({ value, variant, loading }) {
    const aiPercent = value;
    const useStylesFacebook = makeStyles((theme) => ({
        root: {
            position: "relative",
            borderRadius: 5,
            overflow: "hidden",
            backgroundColor: loading ? "lightgrey" : "rgb(105 209 63)",
        },

        bar: {
            backgroundColor: "#ff6355eb",
        },
    }));

    const classes = useStylesFacebook();
    return (
        <div className={styles.meter}>
            <Box position={"relative"} top={10}>
                <LinearProgress
                    style={{ width: 100, height: 10 }}
                    variant="determinate"
                    value={aiPercent}
                    classes={classes}
                />

                {!loading && (
                    <Box
                        display="flex"
                        flexDirection={"column"}
                        alignItems="center"
                        justifyContent="center"
                        fontWeight={600}
                        fontSize={16}
                        position={"absolute"}
                        whiteSpace={"nowrap"}
                        top={-23}
                        width={100}
                        textAlign={"center"}
                    >
                        <span>
                            <CountUp end={aiPercent} />% AI
                        </span>
                    </Box>
                )}
            </Box>

            {!loading && (
                <span>
                    {aiPercent < 50 ? (
                        <>
                            The content is likely{" "}
                            <span style={{ fontWeight: 500 }}>
                                Human written
                            </span>
                        </>
                    ) : (
                        <>
                            The content is likely{" "}
                            <span style={{ fontWeight: 500 }}>
                                AI generated
                            </span>
                        </>
                    )}
                </span>
            )}
        </div>
    );
}

export default AIHumanizerMeter;
