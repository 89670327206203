import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import ReactStars from "react-rating-stars-component";
import { VscClose } from "react-icons/vsc";
import Modal from "../atoms/Modal/Modal";
import Button from "../atoms/Button/Button";
import SVGIcon from "../atoms/SVGIcon";
import axios from "axios";
import { url } from "../../utility/config";
import TextArea from "../atoms/TextArea/TextArea";
import { useSelector } from "react-redux";

const Options = ["Very easy", "Easy", "Neutral", "Difficult", "Very difficult"];

const Feedback = ({
    handler = () => {},
    showFeedback = false,
    setShowFeedback,
}) => {
    const token = useSelector((state) => state.auth.token);
    const [value, setValue] = useState(0);
    const [feedback, setFeedback] = useState({
        quality_score: 0,
        connection_difficulty: "",
        satisfaction_score: 0,
        comment: "",
        image_url: "",
    });
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [text, setText] = useState("");
    const [thankYou, setThankYou] = useState("");
    const [que, setQue] = useState(1);
    const fileInputRef = useRef(null);

    const [fileUploading, setFileUploading] = useState(false);

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fd = new FormData();
            fd.append("image", file);
            axios({
                method: "POST",
                url: `${url}/api/project/uploadFile`,
                headers: {
                    Authorization: token,
                },
                data: fd,
            }).then((res) => {
                setFeedback((ps) => ({
                    ...ps,
                    image_url: res.data.data.attachment,
                }));
                setFileUploading(false);
            });
        }
    };
    function handleClose() {
        setFeedbackModal(false);
        setValue(0);
        setText("");
        setThankYou("");
        setShowFeedback(false);
    }
    return (
        <div className={styles.placeholder}>
            {showFeedback && (
                <div className={styles.feedbackWrapper}>
                    {!thankYou ? (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#ACB3BF",
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    {que}/3
                                </div>
                                <div
                                    onClick={() => {
                                        setValue(0);
                                        setFeedbackModal(false);
                                        handler({ ...feedback });
                                        handleClose();
                                    }}
                                >
                                    <VscClose size={24} color="#D9D9D9" />
                                </div>
                            </div>
                            {que == 1 ? (
                                <>
                                    <div className={styles.starsWrap}>
                                        <div className={styles.starsLabel}>
                                            How satisfied are you with our link
                                            manager tool overall?
                                        </div>
                                        <div className={styles.starWrapper}>
                                            <ReactStars
                                                classNames={styles.starry}
                                                size={35}
                                                count={5}
                                                value={value}
                                                onChange={(newVal) => {
                                                    setFeedback((ps) => ({
                                                        ...ps,
                                                        satisfaction_score:
                                                            newVal,
                                                    }));
                                                    setQue(2);
                                                }}
                                                color={"#bbbbbb"}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : que == 2 ? (
                                <>
                                    <div className={styles.starsWrap}>
                                        <div className={styles.starsLabel}>
                                            How easy was it to connect your
                                            domain with Link Manager?
                                        </div>
                                        <div
                                            className={styles.starWrapper}
                                            style={{
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                marginBottom: "10px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            {!!Options.length &&
                                                Options.map((opt) => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                setFeedback(
                                                                    (ps) => ({
                                                                        ...ps,
                                                                        connection_difficulty:
                                                                            opt,
                                                                    }),
                                                                );
                                                            }}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <SVGIcon
                                                                src={`/New UI/SVG/radio-${
                                                                    feedback.connection_difficulty ==
                                                                    opt
                                                                        ? "checked"
                                                                        : "unchecked"
                                                                }.svg`}
                                                            />{" "}
                                                            &nbsp;
                                                            {opt}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.starsWrap}>
                                        <div className={styles.starsLabel}>
                                            How do you like the quality of our
                                            suggestions?
                                        </div>
                                        <div className={styles.starWrapper}>
                                            <ReactStars
                                                classNames={styles.starry}
                                                size={35}
                                                count={5}
                                                value={value}
                                                onChange={(newVal) => {
                                                    setFeedback((ps) => ({
                                                        ...ps,
                                                        quality_score: newVal,
                                                    }));
                                                }}
                                                color={"#bbbbbb"}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.boxWrap}>
                                        <TextArea
                                            width="100%"
                                            props={{
                                                value: text || "",
                                                placeholder:
                                                    "Comment & Suggestions...",
                                                onChange: (e) => {
                                                    let val =
                                                        e?.target?.value || "";
                                                    if (val.length > 200) {
                                                        return;
                                                    }
                                                    setText(val);
                                                },
                                                style: {
                                                    outline: "none",
                                                    flexGrow: 1,
                                                    fontSize: "13px",
                                                    color: "var(--secondary-color3)",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    boxShadow: "none",
                                                    padding: "3px 8px",
                                                    minHeight: "80px",
                                                    maxHeight: "80px",
                                                    marginTop: "10px",
                                                },
                                            }}
                                            containerStyle={{
                                                width: "100%",
                                            }}
                                            showLabel={false}
                                            rowsMin={1}
                                            rowsMax={3}
                                        />
                                    </div>
                                </>
                            )}
                            {que == 2 && (
                                <div className={styles.bottomWrap}>
                                    <div
                                        onClick={handleDivClick}
                                        style={{ cursor: "pointer" }}
                                    ></div>
                                    <Button
                                        text={"Next"}
                                        handler={() => {
                                            setQue(3);
                                        }}
                                        style={{
                                            fontWeight: 500,
                                        }}
                                        disabled={
                                            !feedback.connection_difficulty
                                                .length
                                        }
                                    />
                                </div>
                            )}
                            {que == 3 && (
                                <div className={styles.bottomWrap}>
                                    <div
                                        onClick={handleDivClick}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {!feedback.image_url && (
                                            <>
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/add-photo.svg"
                                                    }
                                                />
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    accept="image/*"
                                                />
                                            </>
                                        )}
                                        {feedback.image_url && (
                                            <div className={styles.uploadedImg}>
                                                <img
                                                    src={feedback.image_url}
                                                    alt={"image"}
                                                />
                                                <div
                                                    className={styles.closeCta}
                                                    onClick={() => {
                                                        setFeedback((ps) => ({
                                                            ...ps,
                                                            image_url: "",
                                                        }));
                                                    }}
                                                >
                                                    <VscClose
                                                        size={30}
                                                        color="#D9D9D9"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Button
                                        text={"Submit"}
                                        handler={() => {
                                            setTimeout(() => {
                                                handleClose();
                                            }, 2000);
                                            handler({ ...feedback });
                                            setThankYou(
                                                "Thank you for your feedback!",
                                            );
                                        }}
                                        style={{
                                            fontWeight: 500,
                                            width: "240px",
                                            maxWidth: "80%",
                                        }}
                                        disabled={feedback.quality_score == 0}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <div className={styles.thankYouWrap}>
                            <SVGIcon
                                src={"/New UI/SVG/feedback.svg"}
                                size={80}
                            />
                            <div>{thankYou}</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Feedback;
