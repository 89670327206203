import { LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "../../../atoms/Button/Button";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import Modal from "../../../atoms/Modal/Modal";
import SVGIcon from "../../../atoms/SVGIcon";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useHelperFunctions from "../../../helperFunctions";
import { ClusterModal } from "./ClusterModal";
import styles from "./ClusterReports.module.css";
import screens from "../../../../screens.json";
import { numberWithCommas } from "../../../../utility/helperFun";
import { Link } from "react-router-dom";
import { RatingsV2 } from "../../../molecules/Creator/Overview/overallRatings";
import { AiFillCheckCircle } from "react-icons/ai";
import { RecommendedTag } from "../clusterKeywordPlanner/KeywordPlannerRow";
import { BsDot } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
const useStyles = makeStyles(() => ({
    bar: {
        backgroundColor: "#60d69f",
    },
}));
const ClusterCard = React.memo(
    ({
        cluster,
        clusterInfo,
        all,
        _setIsClusterModal,
        setSelectedCluster,
        isKDAvailable,
        isGSCAvailable,
    }) => {
        console.log("card******", cluster);
        const goTo = useNavigation();
        const [progress, setProgress] = useState(0);

        const [load, setLoad] = useState(false);

        const setIsClusterModal = (bool) => {
            setSelectedCluster(cluster);
            _setIsClusterModal(bool);
        };

        useEffect(() => {
            if (cluster.report_id == 0) {
                setProgress(50);
            }
        }, []);

        useEffect(() => {
            if (cluster.report_id == 0) {
                setLoad(true);
                document.loadingClusterId = cluster.id;
            }
        }, [cluster.report_id]);

        useEffect(() => {
            if (cluster.report_id) {
                setLoad(false);
            }
        }, [cluster]);

        useEffect(() => {
            let i = null;
            if (load) {
                i = setInterval(() => {
                    setProgress((p) => {
                        if (p < 80) {
                            return p + Math.random() * 5;
                        } else if (p < 95) {
                            {
                                return p + Math.random();
                            }
                        } else {
                            clearInterval(i);
                            return p;
                        }
                    });
                }, 1500);
            } else {
                clearInterval(i);
            }
        }, [load]);

        const classes = useStyles();
        return (
            <>
                <div
                    onClick={() => setIsClusterModal(true)}
                    className={styles.clusterCard}
                >
                    {!!cluster.failed && (
                        <div className={styles.ribbon}>FAILED</div>
                    )}

                    {load ? (
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    ) : (
                        ""
                    )}
                    <div className={styles.clusterTitle}>
                        {!!cluster.report_id && (
                            <AiFillCheckCircle
                                style={{ marginTop: 1 }}
                                color="#1DC198"
                            />
                        )}
                        <LightTooltip
                            title={cluster.data[0]?.keyword}
                            placement="bottom"
                            arrow={true}
                            // PopperProps={{ disablePortal: true }}
                            onOpen={(e) => {
                                console.log(
                                    e,
                                    document.querySelector("[role=tooltip]"),
                                );
                                if (
                                    e.target.scrollWidth <= e.target.offsetWidth
                                ) {
                                    setImmediate(() => {
                                        document
                                            .querySelectorAll("[role=tooltip]")
                                            .forEach(
                                                (e) =>
                                                    (e.style.display = "none"),
                                            );
                                    });
                                }
                            }}
                        >
                            <span className={styles.clusterTitle__title}>
                                {cluster.data[0]?.keyword}
                            </span>
                        </LightTooltip>
                        {cluster.is_cluster ? (
                            <span className={styles.moreCount}>
                                +
                                {cluster.data.length - 1 < 1
                                    ? 0
                                    : cluster.data.length - 1}{" "}
                                more
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className={styles.tsvcdWrapper}>
                        {/* {clusterInfo.source === "semrush" ? (
                            <TSVCD val={numberWithCommas(cluster.cd)} />
                        ) : (
                            <span></span>
                        )} */}

                        <div
                            style={{
                                marginRight: "auto",
                                fontSize: "12px",
                                marginLeft: "10px",
                                color: "#7188AA",
                            }}
                        >
                            {isGSCAvailable == 1 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* {!cluster.ranking_keywords > 0 && (
                                        <GoDotFill
                                            color={
                                                cluster.ranking_keywords > 0
                                                    ? "rgb(113 136 170 / 62%)"
                                                    : "#0063ff"
                                            }
                                        />
                                    )} */}
                                    {cluster.ranking_keywords ? (
                                        <span>
                                            {cluster.ranking_keywords || 0} of{" "}
                                            {cluster.data &&
                                                cluster.data.length}{" "}
                                            keywords ranking
                                        </span>
                                    ) : (
                                        <span
                                            className={styles.noRankingKeywords}
                                        >
                                            No Keywords Ranking
                                        </span>
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        {/* <SSCD val={numberWithCommas(cluster.ss)} ss={true} /> */}
                        {isKDAvailable == 1 && (
                            <LightTooltip
                                title="Average Keyword Difficulty indicates how challenging it would be for a website to rank organically in the Google top 10 for the entire set of analyzed keywords in the cluster.
                                A higher KD percentage implies greater difficulty in achieving high rankings for the keywords within the cluster."
                                arrow
                            >
                                <div className={`${styles.sv} ${styles.ss}`}>
                                    Avg KD :{" "}
                                    {cluster.kd != null
                                        ? parseInt(cluster.kd)
                                        : "-"}
                                </div>
                            </LightTooltip>
                        )}
                        <LightTooltip
                            title="This is combined search volume of all the related keywords"
                            arrow
                        >
                            <div className={`${styles.sv} ${styles.tsv}`}>
                                Volume : {numberWithCommas(cluster.totalVol)}
                            </div>
                            {/* <TSVCD
                                    val={numberWithCommas(cluster.totalVol)}
                                    tsv={true}
                                /> */}
                        </LightTooltip>
                    </div>

                    {/* <div className={styles.clusterType}>
        <span className={styles.clusterType__wrap}>Informational</span>
      </div> */}
                    <div className={styles.keywords}>
                        KEYWORDS
                        <span className={styles.selectedKeywords}>
                            &nbsp; ({" "}
                            {
                                cluster.data.filter((edx) => edx.is_selected)
                                    .length
                            }{" "}
                            / {cluster.data && cluster.data.length} )
                        </span>
                        <ul className={styles.keywords__container}>
                            {cluster.data.length
                                ? cluster.data
                                      .filter((ele) => ele.is_selected)
                                      .map((item, i) => {
                                          // if (i != 0) {
                                          return (
                                              <li
                                                  className={
                                                      styles.keyword__row
                                                  }
                                              >
                                                  {item.keyword}
                                              </li>
                                          );
                                          // }
                                      })
                                : ""}
                        </ul>
                    </div>

                    <Button
                        text={""}
                        height={"20px"}
                        width={"20px"}
                        borderRadius={"2px"}
                        customClass={styles.expandBtn}
                        handler={() => setIsClusterModal(true)}
                        style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "black",
                            backgroundColor: "transparent",
                            position: "absolute",
                            top: 16,
                            right: 10,
                        }}
                        iconReverse
                        Icon={() => (
                            <SVGIcon
                                size={"16"}
                                src={"/New UI/SVG/expand_two_arrow.svg"}
                            />
                        )}
                    />

                    <div
                        style={load ? { opacity: 1 } : {}}
                        className={styles.bottomSection}
                    >
                        {!cluster.report_id ? (
                            <Button
                                // loading={load}
                                text={
                                    load || cluster.report_id == 0
                                        ? "Creating SEO Article..."
                                        : all && !cluster.report_id
                                        ? "Create SEO Article"
                                        : "Open SEO Article"
                                }
                                height={"34px"}
                                width={"180px"}
                                borderRadius={"2px"}
                                handler={() =>
                                    !all && cluster.report_id == 0
                                        ? setIsClusterModal(true)
                                        : all && !cluster.report_id
                                        ? setIsClusterModal(true)
                                        : goTo(
                                              "CREATOR_REPORT",
                                              [],
                                              cluster.report_id,
                                          )
                                }
                                disabled={
                                    !cluster.data.filter(
                                        (edx) => edx.is_selected,
                                    ).length || load
                                }
                                loadingText={load}
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    backgroundColor: " var(--primary-blue)",
                                    color: "#f6f7fb",
                                    border: "solid 1px #e9ecf4",
                                    borderRadius: 3,
                                }}
                                iconReverse
                                Icon={() => (
                                    <SVGIcon
                                        size={"16"}
                                        src={"/New UI/SVG/create_seo_doc.svg"}
                                    />
                                )}
                                lessDots={true}
                            />
                        ) : (
                            <>
                                <Link
                                    target={"_blank"}
                                    className={styles.openLink}
                                    to={
                                        screens.SEO_EDITOR +
                                        "/" +
                                        cluster.report_id
                                    }
                                >
                                    <SVGIcon
                                        size={"16"}
                                        src={"/New UI/SVG/doc.svg"}
                                    />
                                    &nbsp; Open SEO Article
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    },
);

const TSVCD = ({ val, tsv }) => {
    return (
        <div className={`${styles.sv} ${tsv ? styles.tsv : styles.cd} `}>
            {tsv ? `TSV: ${val}` : `CD: ${val}`}
        </div>
    );
};

// const KD = ({ val, kd }) => {
//     return (
//         <div className={`${styles.sv} ${ss ? styles.ss : styles.cd} `}>
//             {kd ? `CD: ${val}` : ""}
//         </div>
//     );
// };

export default ClusterCard;
