import React, { useState, useEffect, useContext, useRef } from "react";
import styles from "../Creator/GoNuts/FirstDraft/FirstDraft.module.css";
import { CruiseContext } from "../Creator/GoNuts/StepsWizard";
import { CruiseState } from "../Creator/GoNuts/StepsWizard";
import TextArea from "../../atoms/TextArea/TextArea";
import { BiCheckCircle, BiImageAdd } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { UPLOAD_FILE } from "../../api.json";
import useHelperFunctions from "../../helperFunctions";
import { CRUISE_TYPES } from "../Creator/GoNuts/useCruiseHook";
import ReportContext from "../Research/ReportContext";
import { CircularProgress } from "@material-ui/core";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import Button from "../../atoms/Button/Button";
import { Rating } from "@material-ui/lab";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const labels = {
    1: "Useless",
    2: "Poor",
    3: "Average",
    4: "Good",
    5: "Excellent",
};

const mainText = {
    cruiseMode: "Are you satisfied with the quality of output generated?",
    Editor: "Are you satisfied with the quality of output generated?",
    FixIt: "Are you satisfied with the quality of suggestions?",
    contentGap: "Are you satisfied with the quality of suggestions?",
    Interlinking: "Are you satisfied with the quality of suggestions?",
    keywordPlanner: "How would you rate the quality of the clusters?",
    trafficAnalyzer:
        "How would you rate your experience with Traffic Analyzer?",
    aiHumanizer: "How would you rate your experience with Detect and Humanize?",
};

const url = {
    cruiseMode: "/api/contentDraft/v2/satisfaction-score",
    Editor: "/api/contentDraft/v2/satisfaction-score",
    FixIt: "/api/contentDraft/v2/satisfaction-score/fix-it",
    contentGap: "/api/contentDraft/v2/satisfaction-score/content-gap",
    Interlinking: "/api/contentDraft/v2/satisfaction-score/interlinking",
    keywordPlanner: "/api/contentDraft/v2/satisfaction-score/keyword-planner",
    trafficAnalyzer: "/api/contentDraft/v2/satisfaction-score/traffic-analyser",
    aiHumanizer: "/api/contentDraft/v2/satisfaction-score/humanizer",
};
const UserFeedback = ({
    setClose,
    realtimeRating = {},
    section = "",
    topic_id = "",
    setReportData = () => {},
    isFixItEnabled = {},
}) => {
    const dispatchStepsData = useContext(CruiseContext);
    const stepsData = useContext(CruiseState);
    const { mixpanelTrack } = useMixpanelHook();
    const { reportInfo } = useContext(ReportContext);
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const { postData } = useHelperFunctions();
    const ref = useRef(null);
    const [formData, setFormdata] = useState({
        feedback: "",
        file: "",
        uploading: false,
        submitted: false,
    });
    const [respId, setRespId] = useState(null);
    const dataRef = useRef({ rating, ...formData });
    let heading_count = 0;
    for (const h2 of stepsData.outlines.headings) {
        heading_count++;
        for (const h3 of h2.outlineH3s) {
            heading_count++;
        }
    }
    const mixpanelData = {
        Rating: rating,
        "Comments added": formData.feedback ? "Yes" : "No",
        "Attachment added": formData.file ? "Yes" : "No",
        "SEO Score Targeted": realtimeRating.suggestedSEOScore,
        "SEO Score Actual": realtimeRating.seoScore,
        "Word Count Targeted": realtimeRating.wordRange,
        "Word Count Actual": realtimeRating.wordCount,
        "Heading Count Suggested": stepsData.heading_count,
        "Heading Count Actual": heading_count,
    };

    const uploadImage = async (image) => {
        if (!image) return;
        setFormdata((ps) => ({ ...ps, uploading: true }));
        const formdata = new FormData();
        formdata.append("image", image);
        const res = await postData({
            url: UPLOAD_FILE,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            payload: formdata,
        });
        setFormdata((ps) => ({ ...ps, uploading: false }));
        if (res.status == 200) {
            setFormdata((ps) => ({ ...ps, file: res.data.data.attachment }));
        }
    };

    const submitFeedback = async (_rating = rating, _formData = formData) => {
        let payloadObj = {};
        if (section == "keywordPlanner") {
            payloadObj.topic_id = topic_id;
        } else if (section == "aiHumanizer") {
            payloadObj.scan_id = topic_id;
        } else {
            payloadObj.report_id = reportInfo.report_id;
        }
        if (dataRef.current.id) {
            payloadObj.id = dataRef.current.id;
        }
        const res = await postData({
            url: url[section],
            payload: {
                ...payloadObj,
                rating: _rating,
                comment: _formData.feedback,
                attachment: _formData.file,
            },
        });
        if (res.status == 200) {
            setRespId(res.data?.id);
            if (section == "contentGap") {
                setTimeout(() => {
                    setReportData((ps) => ({
                        ...ps,
                        contentGapRating: true,
                    }));
                }, 2000);
            }
        }
        if (_rating > 3) {
            setFormdata((ps) => ({ ...ps, submitted: true }));
        }
    };

    useEffect(() => {
        if (section == "cruiseMode") {
            setReportData((ps) => ({
                ...ps,
                satisfactionRating: rating,
            }));
        }
        // if (section == "Editor") {
        //     setReportData((ps) => ({ ...ps, showRatingComponent: false }));
        // }
    }, [rating]);

    useEffect(() => {
        if (formData.submitted) {
            setTimeout(() => setClose(true), 3000);
        }
    }, [formData.submitted]);

    dataRef.current = { rating, ...formData, id: respId };
    useEffect(() => {
        if (section == "cruiseMode") {
            mixpanelTrack("Satisfaction score popup appeared", mixpanelData);
        }
        return () => {
            if (
                !dataRef.current.submitted &&
                section != "cruiseMode" &&
                section != "Editor" &&
                section != "contentGap"
            ) {
                submitFeedback(dataRef.current.rating, { ...dataRef.current });
            }

            if (dataRef.current.rating) {
                mixpanelTrack("Satisfaction Score Submitted", {
                    ...mixpanelData,
                    Rating: dataRef.current.rating,
                    "Comments added": dataRef.current.feedback ? "Yes" : "No",
                    "Attachment added": dataRef.current.file ? "Yes" : "No",
                    section: section,
                });
            }
        };
    }, []);

    return (
        <div
            className={styles.feedbackContainer}
            style={{
                display:
                    isFixItEnabled.isOn && section == "Editor" ? "none" : "",
                position:
                    section == "trafficAnalyzer" ||
                    section == "Editor" ||
                    section == "aiHumanizer"
                        ? "fixed"
                        : "absolute",
                left:
                    section == "keywordPlanner"
                        ? "210px"
                        : section == "trafficAnalyzer"
                        ? "290px"
                        : section == "Editor"
                        ? "calc(100% - 200px)"
                        : section == "aiHumanizer"
                        ? "190px"
                        : "50%",
                bottom:
                    section == "keywordPlanner" || section == "trafficAnalyzer"
                        ? "20px"
                        : section == "aiHumanizer"
                        ? "50px"
                        : "0px",
            }}
        >
            <CgClose
                onClick={() => {
                    if (section == "cruiseMode" || section == "Editor") {
                        submitFeedback(dataRef.current.rating, {
                            ...dataRef.current,
                        });
                        if (section == "cruiseMode") {
                            dispatchStepsData({
                                type: CRUISE_TYPES.HAS_SATISFACTION_SCORE,
                                hasSatisfactionScore: true,
                            });
                        }
                    }
                    setClose(true);
                    if (rating == 0 && section == "cruiseMode")
                        mixpanelTrack(
                            "Satisfaction Score popup Closed",
                            mixpanelData,
                        );
                }}
                className={styles.cross}
            />

            {!formData.submitted ? (
                <>
                    <h3>{mainText[section]}</h3>

                    <div className={styles.ratings}>
                        <Rating
                            name="simple-controlled"
                            value={rating}
                            onChange={(_, val) => {
                                setRating(val);

                                submitFeedback(val);
                            }}
                            onChangeActive={(event, newHover) => {
                                setHoverRating(newHover == -1 ? 0 : newHover);
                            }}
                            size="large"
                            style={{ fontSize: 34 }}
                            emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        />
                        <span className={styles.ratingsLbl}>
                            {labels[hoverRating || rating]}
                        </span>
                    </div>
                    {rating < 4 && rating > 0 && (
                        <div style={{ width: "100%", marginTop: 5 }}>
                            <TextArea
                                props={{
                                    style: {},
                                    onChange: (e) =>
                                        setFormdata((ps) => ({
                                            ...ps,
                                            feedback: e.target.value,
                                        })),
                                }}
                                placeholder="Let us know what went wrong"
                                label=" "
                                rowsMax={10}
                            />
                            <div className={styles.ctaRow}>
                                {formData.uploading ? (
                                    <CircularProgress size={30} />
                                ) : formData.file ? (
                                    <BiCheckCircle
                                        onClick={() => ref.current.click()}
                                        color="green"
                                        size={30}
                                    />
                                ) : (
                                    <BiImageAdd
                                        onClick={() => ref.current.click()}
                                        size={30}
                                        color="#808080"
                                    />
                                )}
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => {
                                        uploadImage(e.target.files[0]);
                                    }}
                                    ref={ref}
                                />
                                <Button
                                    handler={() => {
                                        submitFeedback();
                                        setFormdata((ps) => ({
                                            ...ps,
                                            submitted: true,
                                        }));
                                    }}
                                    width={"60%"}
                                    text="Submit"
                                    height={30}
                                    fontSize={14}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <img
                        height={30}
                        width={30}
                        src="/New UI/Images/kudos.png"
                    />
                    <h3 style={{ color: "#FFA522" }}>
                        Thank you for your valuable feedback!
                    </h3>
                    <h3 style={{ fontWeight: 400 }}>
                        Our team consistently tries to improve the quality based
                        on your feedback.
                    </h3>
                </>
            )}
        </div>
    );
};

export default UserFeedback;
