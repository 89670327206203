import React, { useEffect, useState } from "react";
import { useRef } from "react";
import useOnClickOutside from "../../../atoms/Modal/useOnClickOutside";
import styles from "./styles.module.css";
import { VscChevronRight } from "react-icons/vsc";
function AiConnector({ list = {}, setAiDropdown, writeForMe }) {
  const [_list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const [_item, setItem] = useState("");
  const ref = useRef();

  useEffect(() => {
    const arr = [];
    for (const item in list) {
      arr.push(item);
    }
    setList(arr);
  }, []);

  useOnClickOutside(ref, () => setAiDropdown(false));

  return (
    <div ref={ref} className={styles.list}>
      <div>
        {_list.map((item) => {
          return (
            <div
              onMouseOver={() => {
                setOptions(list[item]);
                setItem(item);
              }}
              onClick={() => {
                setOptions(list[item]);
                setItem(item);
              }}
              className={
                styles.listItem + " " + (item == _item ? styles.selected : "")
              }
            >
              {item} <VscChevronRight />
              {item === _item && options.length > 0 && (
                <div className={styles.list2}>
                  {options.map((item1) => {
                    return (
                      <div
                        onClick={(e) => {
                          writeForMe(65, item1);
                          setAiDropdown(false);
                        }}
                        className={styles.listItem}
                      >
                        {item1}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AiConnector;
