import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./account.module.css";

import axios from "axios";
import {
    STRIPE_TOKEN,
    STRIPE_TOKEN1,
    STRIPE_TOKEN2,
    url,
} from "../../utility/config";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
} from "react-router-dom";
import BillingPlans from "./components/BillingPlans/BillingPlans";
import Transactions from "./components/Transactions/Transactions.jsx";
import BrandProfile from "./components/BrandProfile/BrandProfile";
import UserAccount from "./components/UserAccount/UserAccount";
import SectionHeader from "./SectionHeader";
import { CircularProgress } from "@material-ui/core";
import GSTPopUp from "../molecule/popUp/PopUp";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import Recharge from "../../componentsV2/organisms/Modals/Recharge/Recharge";
import { getPlan } from "../../utility/validateFunctions";

import EditorHeader from "../../componentsV2/organisms/TextEditor/EditorHeader";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { PLANS_CATEGORIES, PLANS_TYPE } from "../../constants";
import useUserAccessHook from "./useUserAccessHook";
import { customLoadStripe } from "../../componentsV2/atoms/Stripe";
import NavHeader from "./NavHeader";

export default function AccountSettings({ aside }) {
    const { path } = useRouteMatch();
    const id = useSelector((state) => state.auth.userId);
    const token = useSelector((state) => state.auth.token);
    const plan = useSelector((state) => state.auth.plan_id);
    const otherInfo = useSelector((state) => state.otherInfo);
    const [userData, setUserData] = useState({});
    const [showSubsTab, setShowSubsTab] = useState(false);
    const [userUsage, setUserUsage] = useState([]);
    const [userDataLoading, setUserDataLoading] = useState(false);
    const [rechargeDialog, setDialog] = React.useState(false);
    const [paymentView, setPaymentView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState("");
    const currency = useSelector((state) => state.auth.currency);
    const [invalidAmount, setInvalidAmount] = useState(null);

    const [gstPopUp, setGstPopUp] = useState(false);
    const gst_no = useSelector((state) => state.auth.gst_no);
    const [currencySign, setCurrencySign] = useState("");
    const [update, setUpdate] = useState(0);
    const [isReferralAllowed, setIsReferralAllowed] = useState(false);
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );
    console.log(path + "");
    const { isLTD, isTeamMember } = useUserAccessHook();

    const { getData } = useHelperFunctions();

    const [activePage, setActivePage] = useState(false);
    const [isActive, setIsActive] = useState();

    useEffect(() => {}, [activePage]);

    useEffect(() => {
        if (window.location.pathname.includes("plans")) {
            setActivePage(true);
        } else {
            setActivePage(false);
        }
    }, [window.location.pathname]);

    const location = useLocation();
    const ref = useRef();
    useEffect(() => {
        ref?.current?.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        setUserDataLoading(true);
        axios({
            method: "get",
            url: `${url}/api/user/${id}`,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                setUserDataLoading(false);
                setUserData(res.data.user_info);
            })
            .catch((err) => console.log(err));
    }, [id, update]);

    let stripeID =
        otherInfo?.subscriptionInfo?.stripe_region == 2
            ? STRIPE_TOKEN2
            : STRIPE_TOKEN1;
    const stripePromise = customLoadStripe(stripeID);
    let baseURL = window.location.origin;
    async function getlocationDetail() {
        let response = await fetch(
            `https://api.ipregistry.co/?key=ixrwi6l31kiqyx`,
        )
            .then((res) => res.json())
            .then((response) => {
                return response;
                // setCurrency(response.currency.code);
            });
        return response;
    }

    const handleClick = async (event) => {
        event && event.preventDefault();

        if (!amount || amount < 1) {
            setInvalidAmount(styles.amountEnter);
            setTimeout(() => {
                setInvalidAmount(null);
            }, 500);

            return;
        }

        if (loading) return;

        setLoading(true);

        // When the customer clicks on the button, redirect them to Checkout.
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        const stripe = await stripePromise;
        const response = await fetch(
            url + "/api/payment/create-wallet-session",
            {
                method: "POST",
                body: JSON.stringify({
                    currency: currency,
                    recharge_amount: amount,
                    domain: baseURL,
                }),
                headers: myHeaders,
            },
        );

        const session = await response.json();
        console.log(session);
        if (gst_no == null && currency == "INR")
            window.localStorage.setItem("qazwsxedc", "109plmokn");
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.paymentTransactionID,
        });

        if (result.error) {
            setLoading(false);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
        // const session = await response.json();
        // transactionId=session.transactionId;
        // clientSecret=session.clientSecret;

        // setPaymentView(true)

        // When the customer clicks on the button, redirect them to Checkout.
    };
    function gstNumberCollector() {
        handleClick();
    }

    // useEffect(() => {
    //     checkReferralAllowed();
    // }, []);

    // async function checkReferralAllowed() {
    //     setLoading(true);

    //     const res = await getData({ url: "/api/refer/is-referral-allowed" });
    //     if (res.status == 200) {
    //         setIsReferralAllowed(res.data?.isReferralAllowed);
    //     }
    //     setLoading(false);
    // }

    return (
        <div
            id="accountSettings"
            style={{
                scrollBehavior: "initial",
                backgroundColor: "#f6f8fc" /* */,
            }}
            ref={ref}
            className={styles.accountSettings}
        >
            {gstPopUp && (
                <GSTPopUp
                    buttonText={"Confirm"}
                    setPopUp={setGstPopUp}
                    headingText={"GST Number"}
                    description={"Please Enter your GST Number."}
                    handler={gstNumberCollector}
                />
            )}

            {rechargeDialog && (
                <Modal setModal={setDialog}>
                    <Recharge setModal={setDialog} />
                </Modal>
            )}

            {/* {!location.pathname.includes("plans") && (
          <SectionHeader
            userCurrency={userCurrency}
            setUserCurrency={setUserCurrency}
            setEmail={setEmail}
            setUserId={setUserId}
            userId={userId}
            email={email}
            rechargeDialog={rechargeDialog}
            setDialog={setDialog}
            setCompanyId={setCompanyId}
            userCompanyId={userCompanyId}
          />
        )} */}
            <div className={styles.stickyHead}>
                <NavHeader userDropDown={true} />
            </div>
            <div
                style={
                    location.pathname?.includes("plans") ? { marginTop: 0 } : {}
                }
                className={styles.component}
            >
                <Switch>
                    <Route path={`${path}/userProfile`}>
                        {
                            <UserAccount
                                loading={loading}
                                userData={userData}
                                setUpdate={setUpdate}
                                userUsage={userUsage}
                                currencySign={currencySign}
                                setIsReferralAllowed={setIsReferralAllowed}
                                isReferralAllowed={isReferralAllowed}
                                setIsActive={setIsActive}
                                setDialog={setDialog}
                            />
                        }
                    </Route>

                    {!(isLTD || isTeamMember) && (
                        <Route path={`${path}/plans`}>
                            <BillingPlans
                                aside={aside}
                                setIsActive={setIsActive}
                            />
                        </Route>
                    )}

                    <Route path={path}>
                        <Redirect to={`${path}/userProfile`} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
