import { head } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateUserInfo } from "../../../store/actions/auth";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import Modal from "../../atoms/Modal/Modal";
import SVGIcon from "../../atoms/SVGIcon";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import useHelperFunctions from "../../helperFunctions";
import useHookReferalConstant from "../../Pages/NewDashboard/ReferalConstant";
import CopyText from "../Research/CopyText";

import styles from "./styles.module.css";
import { jumbleArray } from "../../utlities";
import { InlineWidget } from "react-calendly";
import { VscClose } from "react-icons/vsc";

const tools = jumbleArray([
    "Article Writer",
    "Content Optimizer",
    "AI Copywriter",
    "Keyword Planner",
    "Automated Outline",
    "Auto Optimizer (Fix-it)",
    "Traffic Analyzer",
]);

const scale8 = {
    transform: "scale(0.8)",
    height: 800,
    marginBlock: -140,
    marginBottom: -120,
    marginLeft: -380,
};

const scale5 = {
    transform: "scale(0.55)",
    height: 800,
    marginBlock: -220,
    marginBottom: -220,
    marginLeft: -580,
};

const scale6 = {
    transform: "scale(0.7)",
    height: 800,
    marginBlock: -160,
    marginBottom: -150,
    marginLeft: -460,
};

function PostPaymentModal() {
    const [open, setOpen] = useState(false);
    const { payment_done, email, user_name, planDowngraded } = useSelector(
        (state) => state.auth,
    );
    const { subscriptionInfo, isLoaded } = useSelector(
        (state) => state.otherInfo,
    );
    const signupMode = useSelector((state) => state.auth.signup_mode);
    const isMode2Trial = signupMode == 2 && subscriptionInfo?.trial_status == 1;

    const { referCode } = useContext(OnBoardingContext);
    const dispatch = useDispatch();
    const { postData } = useHelperFunctions();
    const [feedback, setFeedback] = useState([]);
    const { mixpanelTrack } = useMixpanelHook();
    const userId = useSelector((state) => state.auth.userId);
    const [otherInput, setOtherInput] = useState("");
    const { REFERR_EARN_VALUE, REFERR_SHARE_URL, REFERR_SHARE_TEXT } =
        useHookReferalConstant({
            referCode,
        });

    const sendFeedback = async () => {
        if (feedback.length) {
            postData({
                url: "/api/subscriptionChurn/createChurnInfo",
                payload: {
                    plan_id: subscriptionInfo.plan_id,
                    email: email,
                    feedback: "",
                    type: 3,
                    software_type: feedback,
                },
            });
        }
    };

    const ref = useRef();
    const containerRef = useRef();

    useEffect(() => {
        if (payment_done) {
            dispatch(updateUserInfo("payment_done", false));
            setOpen(true);
        }
    }, [payment_done]);

    useEffect(() => {
        if (otherInput.trim().length) setFeedback([otherInput]);
    }, [otherInput]);

    const handleCheck = (e) => {
        e.persist();

        if (e.target.checked) {
            setOtherInput("");
            setFeedback([e.target.value]);
        }
    };

    const SocialLinks_Refer = [
        {
            name: "Facebook",
            src: "/New UI/SVG/fbIcon.svg",
            link: `https://facebook.com/`,
        },
        {
            name: "Linkedin",
            src: "/New UI/SVG/lkIcon.svg",
            link: `https://www.linkedin.com/shareArticle?mini=true&url=${REFERR_SHARE_URL}`,
        },
        {
            name: "Twitter",
            src: "/New UI/SVG/twIcon.svg",
            link: `https://twitter.com/intent/tweet?text=${REFERR_SHARE_TEXT}`,
        },

        // {
        //     name: "Instagram",
        //     src: "/New UI/SVG/Instagram.svg",
        //     link: "https://www.instagram.com/direct/",
        // },
        {
            name: "Whatsapp",
            src: "/New UI/SVG/wtIcon.svg",
            link: `https://web.whatsapp.com/send?text=${REFERR_SHARE_TEXT}`,
        },
    ];

    console.log(feedback);

    async function openSocialShare(e, referPostText, link) {
        e.preventDefault();
        await navigator.clipboard.writeText(referPostText);
        window.open(link, "_blank");
    }

    return isMode2Trial && open && userId % 5 > 2 ? (
        <>
            <Modal>
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "relative",
                        background: "#fff",
                        overflow: "auto",
                    }}
                >
                    <div className={styles.logoM}>
                        <SVGIcon src="/New UI/SVG/Scalenut.svg" size={28} />

                        <h2>&nbsp;Scalenut</h2>
                    </div>
                    <div style={{ width: "1024px", marginLeft: "300px" }}>
                        <h1 style={{ display: "flex" }}>
                            Unlock SEO Success: Free 1-1 Strategy Call
                        </h1>
                        <InlineWidget
                            iframeTitle="Unlock SEO Success: Free 1-1 Strategy Call"
                            styles={
                                document?.body?.offsetHeight > 900
                                    ? scale8
                                    : document?.body?.offsetHeight > 700
                                    ? scale6
                                    : scale5
                            }
                            url="https://calendly.com/d/5gx-7jm-fw2/scalenut-demo?utm_source=product&utm_medium=onboardm2"
                        />
                        <br />

                        <div
                            onClick={() => {
                                setOpen(false);
                                mixpanelTrack("Call Schedule Skipped(Mode 2)");
                            }}
                            style={{
                                backgroundColor: "transparent",
                                color: "var(--primary-blue)",
                                fontSize: 22,
                                fontWeight: 400,
                                cursor: "pointer",
                                marginTop: "20px",
                            }}
                        >
                            Explore on your own →
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    ) : open && isLoaded && !isMode2Trial && !planDowngraded ? (
        <Modal setModal={setOpen}>
            <div ref={containerRef} className={styles.container}>
                <CloseButton
                    setClose={() => {
                        sendFeedback();
                        setOpen(false);
                    }}
                    buttonSize={24}
                />
                <div className={styles.headSection}>
                    <h2>Thank you for choosing</h2>
                    <h1>Scalenut! 😊</h1>
                </div>
                <div className={styles.optionSection}>
                    <div
                        style={{
                            width: "600px",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "20px",
                            fontWeight: 500,
                            marginTop: "20px",
                        }}
                    >
                        {user_name}, Also check out our newly launched product
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "800px",
                            justifyContent: "center",
                            gap: "20px",
                            marginTop: "30px",
                        }}
                    >
                        <div
                            onClick={() => {
                                window.open(
                                    "https://www.scalenut.com/seo-surge",
                                    "_blank",
                                );
                            }}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#00634B0F",
                                width: "320px",
                                height: "160px",
                                borderRadius: "45px",
                            }}
                        >
                            <SVGIcon src={"/New UI/SVG/SEOSurgeText.svg"} />
                            <div
                                style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    marginTop: "15px",
                                }}
                            >
                                The world's first AI-powered full-stack
                                <br />
                                SEO subscription service.
                            </div>
                        </div>
                    </div>
                    {/* <h3>
                        <span style={{ textTransform: "capitalize" }}>
                            {"user_name"
                                ? user_name.split(" ")[0] || "Hey"
                                : "Hey"}
                        </span>
                        , which feature you loved the most?
                    </h3>
                    <i style={{ marginTop: 5 }}> Select 1 option </i>
                    <div className={styles.options}>
                        {tools.map((tool) => (
                            <div>
                                <input
                                    onChange={handleCheck}
                                    value={tool}
                                    type="checkbox"
                                    checked={feedback.includes(tool)}
                                />
                                <label>{tool}</label>
                            </div>
                        ))}
                    </div>

                    <div className={styles.otherInput}>
                        <label>Other</label>
                        <input
                            maxLength={300}
                            onChange={(e) => setOtherInput(e.target.value)}
                            value={otherInput}
                        />
                    </div> */}

                    {/* <small
                        style={{
                            visibility:
                                feedback.length != 2 ? "hidden" : "visible",
                        }}
                    >
                        You can select up to 2 options*
                    </small> */}
                </div>
                {/* <div className={styles.referSection}>
                    <h2>Refer & Earn</h2>
                    <h3>You and your referred friend both get $19</h3>
                    <div className={styles.linksContainer}>
                        <div className={styles.links}>
                            {SocialLinks_Refer.map((link, i) => {
                                return i == 0 ? (
                                    <a
                                        target="_blank"
                                        onClick={(e) => {
                                            mpTrackDashboard(
                                                "Refer & Earn : Social Share",
                                            );
                                            openSocialShare(
                                                e,
                                                REFERR_SHARE_TEXT,
                                                "https://www.facebook.com/sharer/sharer.php?u=" +
                                                    encodeURIComponent(
                                                        REFERR_SHARE_URL,
                                                    ),
                                            );
                                        }}
                                        class="fb-xfbml-parse-ignore"
                                    >
                                        <SVGIcon src={link.src} />
                                    </a>
                                ) : (
                                    <a
                                        target="_blank"
                                        onClick={(e) => {
                                            mpTrackDashboard(
                                                "Refer & Earn : Social Share",
                                            );
                                            openSocialShare(
                                                e,
                                                REFERR_SHARE_TEXT,
                                                link.link,
                                            );
                                        }}
                                    >
                                        <SVGIcon src={link.src} />
                                    </a>
                                );
                            })}

                            <div className={styles.referLinkContainer}>
                                <div className={styles.referLinkLabel}>
                                    link
                                </div>
                                <div className={styles.referLink}>
                                    https://www.scalenut.com?refer={referCode}
                                </div>

                                <div>
                                    <CopyText
                                        text={`https://www.scalenut.com?refer=${referCode}`}
                                    >
                                        <BiCopy
                                            style={{ cursor: "pointer" }}
                                            size={18}
                                        />
                                    </CopyText>
                                </div>
                            </div>
                        </div>
                        <small>
                            NOTE: These credits can be used to purchase any of
                            the paid plans, upgrades & renewals
                        </small>
                    </div>
                </div> */}
                <video autoPlay muted ref={ref} controls={false} loop>
                    <source
                        src="/New UI/Clips/golden-ribbon.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
        </Modal>
    ) : (
        <></>
    );
}

export default PostPaymentModal;
