import React,{useState} from 'react'
import styles from "./styles.module.css";
import { IoMdClose } from "react-icons/io"
function File({guide,truncate,isEditable,assets,setAssets,setGuide,index}) {
   
    const [remove,setRemove]=useState(false);
    function deleteItem(){
     
        let newArr=[];
        if(setAssets){
        assets.splice(index,1);
  
        for(let v of assets){
            newArr.push(v);
        }
        
  
        setAssets(newArr);}

        if(setGuide){
            
      
            
      
            setGuide([]);}

        
     }



    return (<>
       <div onMouseEnter={()=>setRemove(true)} onMouseLeave={()=>setRemove(false)}  className={styles.file}>
            <a href={guide["link"]} target="blank"> <div className={styles.image}> <img src="/admin/scalenut/profile/doc.svg"></img></div></a>
           
             <div className={styles.fileInfo} > <div className={styles.fileName}>{truncate(guide.name,15)}</div>
              <div className={styles.size}>
              {Math.round(guide.size/1024)+ " KB"}
               
               
                </div>
               
               

            
</div>  
{isEditable&&<div onClick={deleteItem} className={styles.removeFile}>
<IoMdClose />


</div>}
                </div>



</>
                
            
    )
}

export default File;
