import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styles from "./KeywordCannibalization.module.css";
import {
    CircularProgress,
    CircularProgressProps,
    ClickAwayListener,
    makeStyles,
} from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import SVGIcon from "../../../atoms/SVGIcon";
import CircleWithNumber from "../../../atoms/CircleWithNumber/CircleWithNumber";
import CircularProgressBar from "../../../atoms/CircularProgressBar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useGscHook, {
    CannibalizationKeywordObject,
    CannibalizationOtherInfoObject,
    CannibalizationPageObject,
    CannibalizationRequestPayload,
    CountryObject,
} from "../useGscHook";
import RenderInChucks from "../../../atoms/RenderInChucks";
import KCPageSubTable from "./KCPageSubTable";
import useHelperFunctions from "../../../helperFunctions";
import { Skeleton } from "@material-ui/lab";
import InventoryBottomOptions from "../Inventory/InventoryBottomOptions";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import CannibalizationStatCard from "./CannibalizationStatCard";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { debounceWrapper } from "../../../../utility/helperFun";
import CannibalizationTopFilters from "./CannibalizationTopFilters";
import { useDispatch } from "react-redux";
import { updateCannibalizationFilters } from "../../../../store/actions/userGscInfo";

type Props_KeywordCannibalization = {
    cannibalizationData: CannibalizationKeywordObject[];
    cannibalizationOtherInfo: CannibalizationOtherInfoObject;
    requestedData: CannibalizationRequestPayload;
    dateRange: { startDate: string; endDate: string };
    isShimmerCannibs: boolean;
    cannibsFilterShimmer: boolean;
    isOpenDefault: boolean;
    setIsOpenDefault: Dispatch<SetStateAction<boolean>>;
    setCannibalizationData: Dispatch<
        SetStateAction<CannibalizationKeywordObject[]>
    >;
};

type Props_ItemRow = {
    keywordItem: CannibalizationKeywordObject;
    index: number;
    dateRange: {
        startDate: string;
        endDate: string;
    };
    search: string;
    expandIdx: number;
    setExpandIdx: Dispatch<SetStateAction<number>>;
};

const ColorSet: {
    POOR: string;
    AVERAGE: string;
    GOOD: string;
} = {
    POOR: "#FC5A5A",
    AVERAGE: "#FFBE57",
    GOOD: "#1DC198",
};

const DefaultRow = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
];

const SortByOptions: { title: string; id: number }[] = [
    {
        title: "cannibalization_rate",
        id: 1,
    },
    {
        title: "totalImpression",
        id: 2,
    },
    {
        title: "totalTraffic",
        id: 3,
    },
    {
        title: "trafficShare",
        id: 4,
    },
    {
        title: "ctr",
        id: 5,
    },
    {
        title: "affectedPages",
        id: 6,
    },
    {
        title: "keyword",
        id: 8,
    },
];

const ItemRowShimmer = () => {
    return (
        <div style={{ width: "100%" }}>
            {DefaultRow.map((i) => (
                <div className={styles.rowShimmer}>
                    <Skeleton width={"99%"} height={"40px"} key={i + 1} />
                </div>
            ))}
        </div>
    );
};

const ItemRow = ({
    keywordItem,
    index,
    dateRange,
    search,
    expandIdx = -1,
    setExpandIdx,
}: Props_ItemRow) => {
    const { getValueWithUnit, getTrendsDashed, getFilteredGraphData } =
        useGscHook();
    const [subPages, setSubPages] = useState<CannibalizationPageObject[]>([]);
    const [copied, setCopied] = useState(false);
    const rowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (subPages.length == 0 && keywordItem?.allPages?.length > 0) {
            let temp: CannibalizationPageObject[] = [...keywordItem.allPages];
            let count = 0;
            for (let i = 0; i < temp.length; i++) {
                let dataArr = temp[i].position_list.reverse();
                temp[i].dashed_position_list = getTrendsDashed([...dataArr]);
                temp[i].filtred_position_list = getFilteredGraphData([
                    ...dataArr,
                ]);
                if (count < 5) {
                    temp[i].isChecked = true;
                    count++;
                } else {
                    temp[i].isChecked = false;
                }
            }
            setSubPages([...temp]);
        }
    }, [keywordItem.allPages, subPages]);

    const getTypeClass: (type: number) => string = (type = -1) => {
        if (type == 1) {
            return styles.high;
        } else if (type == 2) {
            return styles.med;
        } else if (type == 3) {
            return styles.low;
        } else {
            return styles.low;
        }
    };

    const handleCopy: (page: string) => void = (page) => {
        setCopied(true);
        window.navigator.clipboard.writeText(page);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    const handleExpand: (idx: number) => void = (idx) => {
        setExpandIdx((ps) => (ps == idx ? -1 : idx));
        if (expandIdx != idx && rowRef?.current) {
            setTimeout(() => {
                rowRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                });
            }, 200);
        }
    };

    return (
        <div
            className={
                styles.rowDropContainer +
                " " +
                (expandIdx == index && styles.expandedStyles)
            }
            ref={rowRef}
        >
            <div
                className={styles.itemRowWrapper}
                onClick={() => {
                    handleExpand(index);
                }}
            >
                <div className={styles.itemRow_keyword}>
                    <div className={styles.data}>
                        <LightTooltip
                            title={keywordItem.keyword}
                            placement={"bottom"}
                            arrow
                            // PopperProps={{ disablePortal: true }}
                            onOpen={(e: any) => {
                                if (
                                    e.target.scrollWidth <= e.target.offsetWidth
                                ) {
                                    setImmediate(() => {
                                        document
                                            .querySelectorAll("[role=tooltip]")
                                            .forEach(
                                                (e: any) =>
                                                    (e.style.display = "none"),
                                            );
                                    });
                                }
                            }}
                        >
                            <span className={styles.keywordData}>
                                {keywordItem.keyword}
                            </span>
                        </LightTooltip>
                        <LightTooltip
                            title={copied ? "Copied!" : "Click to Copy"}
                            placement={"bottom"}
                            arrow
                        >
                            <span
                                className={styles.externalLink}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleCopy(keywordItem.keyword);
                                }}
                            >
                                <SVGIcon
                                    src={"/New UI/SVG/copy.svg"}
                                    size={"14"}
                                />
                            </span>
                        </LightTooltip>
                    </div>
                </div>
                <div className={styles.itemRow_rate}>
                    {keywordItem?.cannibalization_rate != null
                        ? keywordItem?.cannibalization_rate?.toLocaleString()
                        : "-"}
                </div>
                <div className={styles.itemRow_impressions}>
                    {keywordItem?.totalImpression != null
                        ? getValueWithUnit(keywordItem.totalImpression)
                        : "-"}
                </div>
                <div className={styles.itemRow_traffic}>
                    {keywordItem?.totalTraffic != null
                        ? getValueWithUnit(keywordItem.totalTraffic)
                        : "-"}
                </div>
                <div className={styles.itemRow_trafficShare}>
                    {keywordItem?.trafficShare != null
                        ? getValueWithUnit(keywordItem.trafficShare) + "%"
                        : "-"}
                </div>
                <div className={styles.itemRow_ctr}>
                    {keywordItem?.ctr != null
                        ? getValueWithUnit(keywordItem.ctr) + "%"
                        : "-"}
                </div>
                <div className={styles.itemRow_pagesCount}>
                    {keywordItem.affectedPages != null
                        ? keywordItem.affectedPages
                        : "-"}
                </div>
                <div className={styles.itemRow_actions}>
                    <LightTooltip
                        title={expandIdx == index ? "Collapse" : "Expand"}
                        placement={"bottom"}
                        arrow
                    >
                        <div
                            className={styles.cta_expand}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setExpandIdx((ps) =>
                                    ps == index ? -1 : index,
                                );
                            }}
                        >
                            <SVGIcon
                                src={`/New UI/SVG/arrow-head-${
                                    expandIdx == index ? "up" : "down"
                                }.svg`}
                                size={15}
                                style={{ color: "inherit" }}
                            />
                        </div>
                    </LightTooltip>
                </div>
            </div>
            {expandIdx == index && (
                <KCPageSubTable
                    _pages={subPages}
                    parentKeyword={keywordItem.keyword}
                    dateRange={dateRange}
                />
            )}
        </div>
    );
};

const KeywordCannibalization = ({
    cannibalizationData,
    cannibalizationOtherInfo,
    requestedData,
    dateRange,
    isShimmerCannibs,
    cannibsFilterShimmer,
    isOpenDefault,
    setIsOpenDefault,
    setCannibalizationData,
}: Props_KeywordCannibalization) => {
    const { getMappedValue, parseCountries } = useGscHook();
    const [expandIdx, setExpandIdx] = useState(-1);
    const [itemsPerPage, setItemsPerPage] = useState(
        requestedData.itemsPerPage || 25,
    );
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const dispatch = useDispatch();
    const [order, setOrder] = useState<0 | 1>(1); //0 = asec, 1= desc
    const [sortBy, setSortBy] = useState<{
        id: number;
        title: string | object;
    }>(SortByOptions[1]);
    const [search, setSearch] = useState("");

    const [progress, setProgress] = useState({ value: 0, color: "" });
    const { postData } = useHelperFunctions();
    const handleSortByColumns: (index: number) => void = (index) => {
        let _ord = 1;
        setSortBy((ps) => {
            if (ps?.id == SortByOptions[index].id) {
                setOrder((ps) => {
                    _ord = ps == 0 ? 1 : 0;
                    return ps == 0 ? 1 : 0;
                });
                return SortByOptions[index];
            } else {
                setOrder(1);
                return SortByOptions[index];
            }
        });
        dispatch(
            updateCannibalizationFilters({
                currentPage: 0,
                sort: { key: SortByOptions[index].title, order: _ord },
            }),
        );
    };

    const divRef = useRef<any>(null);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpenDefault) {
            // setExpandIdx(0);
            setIsOpenDefault(true);
        }
    }, [isOpenDefault]);

    useEffect(() => {
        setCurrentPage(requestedData?.currentPage || 0);
    }, [requestedData?.currentPage]);

    useMemo(() => {
        setExpandIdx(-1);
    }, [requestedData]);
    useMemo(() => {
        if (requestedData?.sort?.key) {
            const spData = SortByOptions.filter((sp, idx) => {
                if (sp.title == requestedData?.sort?.key) {
                    return sp;
                }
            });
            setSortBy(spData.length > 0 ? spData[0] : SortByOptions[1]);
            setOrder(requestedData?.sort?.order);
        }
    }, [requestedData?.sort?.key]);

    useEffect(() => {
        let handleResize: any, handleMutation: any, observer: any;
        // Initial check
        if (divRef.current) {
            handleResize = () => {
                const divElement = divRef?.current;

                if (divElement) {
                    setIsOverflowing(
                        divElement?.scrollWidth - 10 > divElement?.clientWidth,
                    );
                }
            };

            handleMutation = () => {
                setIsOverflowing(
                    divRef?.current?.scrollWidth - 10 >
                        divRef?.current?.clientWidth,
                );
            };

            observer = new MutationObserver(handleMutation);
            observer?.observe(divRef.current, {
                childList: true,
                subtree: true,
            });
            window.addEventListener("resize", handleResize);
            handleResize();
        }
        return () => {
            observer?.disconnect();
            window.removeEventListener("resize", handleResize);
        };
    }, [divRef.current]);

    const filterBySearch: (item: CannibalizationKeywordObject) => boolean = (
        item,
    ) => {
        let searchQuery = search?.trim()?.toLowerCase();
        if (
            searchQuery.length &&
            item.keyword.toLowerCase().includes(searchQuery)
        ) {
            return true;
        } else if (searchQuery.length) {
            let subPages = [
                ...item.allPages.filter((pg: CannibalizationPageObject) => {
                    if (pg.page.toLowerCase()?.includes(searchQuery)) {
                        return true;
                    } else {
                        return false;
                    }
                }),
            ];
            if (subPages.length > 0) {
                return true;
            } else {
                return false;
            }
        } else if (!searchQuery.length) {
            return true;
        } else {
            return false;
        }
    };

    const sortKeywords: (
        a: CannibalizationKeywordObject,
        b: CannibalizationKeywordObject,
    ) => number = (a, b) => {
        if (order === 0) {
            // Swap a and b when sorting in ascending order
            const temp = b;
            b = a;
            a = temp;
        }
        if (sortBy.id == SortByOptions[0].id) {
            return b.cannibalization_rate - a.cannibalization_rate;
        } else if (sortBy.id == SortByOptions[1].id) {
            return b.totalImpression - a.totalImpression;
        } else if (sortBy.id == SortByOptions[2].id) {
            return b.totalTraffic - a.totalTraffic;
        } else if (sortBy.id == SortByOptions[3].id) {
            return b.trafficShare - a.trafficShare;
        } else if (sortBy.id == SortByOptions[4].id) {
            return b.ctr - a.ctr;
        } else if (sortBy.id == SortByOptions[5].id) {
            return b.affectedPages - a.affectedPages;
        } else {
            return a.affectedPages - b.affectedPages;
        }
    };

    useEffect(() => {
        setPageCount(
            Math.ceil(cannibalizationOtherInfo?.affectedKeywordsCount || 1) /
                itemsPerPage,
        );
    }, [cannibalizationOtherInfo]);
    let timeoutRef = useRef(null);

    return (
        <div className={styles.cannibalizationWrapper}>
            {/* <div className={styles.cannibStats}>
                <CannibalizationStatCard
                    title={"Cannibalization Health"}
                    isShimmer={isShimmerCannibs}
                    progress={progress}
                    health={cannibalizationOtherInfo.cannibalizationHealth}
                    svgIcon={
                        <div style={{ paddingTop: "0.5px" }}>
                            <SVGIcon
                                src={"/New UI/SVG/cannibalization_health.svg"}
                                style={{ color: "#2A4266" }}
                                size={16}
                            />
                        </div>
                    }
                />
                <div className={styles.statCardSeparatorWrapper}>
                    <div className={styles.statCardSeparator}></div>
                </div>
                <CannibalizationStatCard
                    title={"Affected Keywords"}
                    isShimmer={isShimmerCannibs}
                    value={cannibalizationOtherInfo?.affectedKeywordsCount}
                    max={cannibalizationOtherInfo?.totalKeywordsCount}
                    svgIcon={
                        <SVGIcon
                            src={"/New UI/SVG/keywordResearcher.svg"}
                            style={{ color: "#2A4266" }}
                            size={16}
                        />
                    }
                />
                <div className={styles.statCardSeparatorWrapper}>
                    <div className={styles.statCardSeparator}></div>
                </div>
                <CannibalizationStatCard
                    title={"Affected pages"}
                    isShimmer={isShimmerCannibs}
                    value={cannibalizationOtherInfo?.affectedPageCount}
                    max={cannibalizationOtherInfo?.totalPagesCount}
                    svgIcon={
                        <SVGIcon
                            src={"/New UI/SVG/ta_pages.svg"}
                            style={{ color: "#2A4266" }}
                            size={16}
                        />
                    }
                />
            </div> */}
            <div className={styles.tableOptionsWrapper}>
                <div className={styles.tableTopOptions_left}>
                    <Textfield
                        // @ts-ignore
                        customClass={styles.searchPageUrl}
                        height={34}
                        label={""}
                        containerStyle={{
                            marginBottom: "0",
                        }}
                        props={{
                            onChange: (e: any) => {
                                const value = e.target.value.trim();
                                debounceWrapper(
                                    () => {
                                        setSearch(value);
                                        dispatch(
                                            updateCannibalizationFilters({
                                                search: value,
                                            }),
                                        );
                                    },
                                    700,
                                    timeoutRef,
                                );
                            },
                            style: {
                                borderRadius: 6,
                                border: `solid 1px ${"#e1e4ed"}`,
                                backgroundColor: "#fff",
                                paddingLeft: "40px",
                            },
                        }}
                        placeholder={"Search Keyword, URL"}
                        Icon={() => (
                            <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />
                        )}
                        iconStyle={{
                            left: "10px",
                            right: "unset",
                        }}
                    />
                </div>
                <div className={styles.tableTopOptions_right}>
                    {requestedData && !cannibsFilterShimmer ? (
                        <CannibalizationTopFilters
                            requestedData={requestedData}
                            setCannibalizationData={setCannibalizationData}
                        />
                    ) : (
                        <div
                            className={styles.cannibalizationTopFiltersShimmer}
                        >
                            <Skeleton height={33} width={"16%"} />
                            <Skeleton height={33} width={"12%"} />
                            <Skeleton height={33} width={"12%"} />
                            <Skeleton height={33} width={"9%"} />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer} ref={divRef}>
                    <div className={styles.tableTopInfo}>
                        <div className={styles.tableTitle}>
                            Affected Keywords
                        </div>
                        <div className={styles.totalKeywordsCount}>
                            {cannibsFilterShimmer || isShimmerCannibs ? (
                                <Skeleton width={"90px"} />
                            ) : (
                                <>
                                    (
                                    {cannibalizationOtherInfo?.affectedKeywordsCount ||
                                        0}
                                    /
                                    {cannibalizationOtherInfo?.totalKeywordsCount ||
                                        0}
                                    )
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.tableHeadRowWrapper}>
                        <div className={styles.tableHead_keywordWrapper}>
                            <div
                                className={
                                    styles.tableHead_keyword +
                                    " " +
                                    (sortBy.id == SortByOptions[6].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(6);
                                }}
                            >
                                Keyword
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[6].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </div>
                        <LightTooltip
                            title={
                                "Measures the ratio of impressions on the top-page versus the second-top page for a given keyword. A higher ratio indicates greater cannibalization. (0 = lowest, 1 = highest)"
                            }
                            arrow
                            placement={"top"}
                        >
                            <div
                                className={
                                    styles.tableHead_rate +
                                    " " +
                                    (sortBy.id == SortByOptions[0].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(0);
                                }}
                            >
                                Cannibalization Index
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[0].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </LightTooltip>
                        <LightTooltip
                            title={
                                "Combined impressions of all pages for the keyword"
                            }
                            arrow
                            placement={"top"}
                        >
                            <div
                                className={
                                    styles.tableHead_impressions +
                                    " " +
                                    (sortBy.id == SortByOptions[1].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(1);
                                }}
                            >
                                Total Impressions
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[1].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </LightTooltip>
                        <LightTooltip
                            title={
                                "Combined traffic of all pages for the keyword"
                            }
                            arrow
                            placement={"top"}
                        >
                            <div
                                className={
                                    styles.tableHead_traffic +
                                    " " +
                                    (sortBy.id == SortByOptions[2].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(2);
                                }}
                            >
                                Total Traffic
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[2].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </LightTooltip>
                        <LightTooltip
                            title={
                                "Keyword's contribution to total site traffic"
                            }
                            arrow
                            placement={"top"}
                        >
                            <div
                                className={
                                    styles.tableHead_trafficShare +
                                    " " +
                                    (sortBy.id == SortByOptions[3].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(3);
                                }}
                            >
                                Traffic Share
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[3].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </LightTooltip>
                        <LightTooltip
                            title={"Click-through rate for the keyword"}
                            arrow
                            placement={"top"}
                        >
                            <div
                                className={
                                    styles.tableHead_ctr +
                                    " " +
                                    (sortBy.id == SortByOptions[4].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(4);
                                }}
                            >
                                CTR{" "}
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[4].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </LightTooltip>
                        <LightTooltip
                            title={"Number of pages ranking for the keyword"}
                            arrow
                            placement={"top"}
                        >
                            <div
                                className={
                                    styles.tableHead_pagesCount +
                                    " " +
                                    (sortBy.id == SortByOptions[5].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(5);
                                }}
                            >
                                Affected Pages{" "}
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[5].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </LightTooltip>

                        <div className={styles.tableHead_actions}></div>
                    </div>
                    <div className={styles.itemRowsContainer}>
                        {isShimmerCannibs ? (
                            <ItemRowShimmer />
                        ) : cannibalizationData.length > 0 ? (
                            cannibalizationData.map((item, index) => {
                                return (
                                    <ItemRow
                                        keywordItem={item}
                                        expandIdx={expandIdx}
                                        setExpandIdx={setExpandIdx}
                                        index={index}
                                        key={item.keyword}
                                        dateRange={dateRange}
                                        search={search}
                                    />
                                );
                            })
                        ) : (
                            <>
                                <div className={styles.noItems}>
                                    <SVGIcon
                                        size={280}
                                        src={
                                            "/New UI/SVG/no_cannibalization_found.svg"
                                        }
                                    />
                                    <h3 className={styles.text}>
                                        No Affected Keywords Found
                                    </h3>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <InventoryBottomOptions
                customClass={styles}
                pageCount={pageCount}
                currentPage={currentPage}
                handleCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
};

export default KeywordCannibalization;
