import React, { useState, useEffect, useContext } from "react";
import qs from "qs";

import {
    CREATOR_ARTICLE_SAVE,
    CREATOR_ARTICLE_SAVED,
    WRITE_FOR_ME,
    GET_DATA_FROM_URL,
    GET_CONTENT_BRIEF,
} from "../../api.json";
import useHelperFunctions from "../../helperFunctions";
import WriteToEditor from "./Toolbar/WriteToEditor";
import RatingHook from "../../molecules/Creator/Overview/RatingHook";
import { SMART_EDITOR } from "./editorConstants";
import { doc } from "prettier";
import { useRef } from "react";
import { debounce } from "@material-ui/core";
import { debounceWrapper } from "../../../utility/helperFun";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";

import slugify from "slugify";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { cruiseMixPanelEvents } from "../Creator/GoNuts/cruiseConstant";
import Quill from "quill";
const Delta = Quill.import("delta");
const slugifyOption = {
    replacement: "",
    remove: /[]/g,
};

const IDEAL_WRITE_TIMEOUT = 5;

function getPayloadText(cursor, useCase, id, lengthToSend) {
    const editor = document.quill.editor;
    for (let index = cursor; index < editor.getLength(); index++) {
        if (editor.getText(index, 1) == " " || editor.getText(index, 1) == "\n")
            break;
        cursor++;
    }
    if (lengthToSend) {
        editor.cursor = editor.cursor + lengthToSend;
        return editor.getText(cursor, lengthToSend);
    }

    if (id == 65) {
        if (!noComma.includes(useCase.toLowerCase()) && useCase) useCase += ",";
        if (editor.getText(cursor, 1))
            return editor.getText(cursor, 1) == " " ||
                editor.getText(cursor, 1) == "\n"
                ? editor.getText(0, cursor) + useCase
                : editor.getText(0, cursor) + " " + useCase;
    }

    return editor.getText(0, cursor);
}

function getTextForm(apiText, id, useCase) {
    const regex = /[\n]+/gi;
    apiText = apiText.replace(regex, "\n");
    const editor = document.quill.editor;
    let cursor = editor.cursor;
    for (let index = cursor; index < editor.getLength(); index++) {
        if (editor.getText(index, 1) == " " || editor.getText(index, 1) == "\n")
            break;
        cursor++;
    }

    if (id == 63 || id == 64 || id == 75) {
        if (editor.getText(cursor - 1, 1))
            return editor.getText(cursor - 1, 1) == " " ||
                editor.getText(cursor - 1, 1) == "\n"
                ? apiText
                : " " + apiText;
        else return apiText;
    } else if (id > 64) {
        if (editor.getText(cursor - 1, 1))
            return editor.getText(cursor - 1, 1) == "\n"
                ? apiText
                : "\n" + apiText;
        else return apiText;
    } else return apiText;
}

export function dataToEditor(content) {
    try {
        let data;
        if (content.startsWith("ops")) {
            data = qs.parse(content).ops;

            if (!Array.isArray(data)) {
                data.length = Object.keys(data).length;
                data = Array.from(data);
            }
        } else {
            data = JSON.parse(content).ops;
        }

        document.quill.editor.setContents(new Delta(data), "silent");
        document.quill.editor.history.clear();
    } catch (error) {
        console.log(error);
        // document.quill.editor.setContents(new Delta(qs.parse(content)), "silent");
    }
}

const noComma = [
    "but",
    "while",
    "notwithstanding",
    "neverthless",
    "whereas",
    "after",
    "if",
    "unless",
    "as long as",
    "provided that",
];

function EditorMethods({
    reportInfo,
    setConnectorCases,
    keyTerms = [],
    docId,
    guestId,
    attachedTo,
    versionData,
}) {
    const [saving, setSaving] = useState(false);
    const [noContentAiModal, setNoContentAiModal] = useState(false);
    const [canReWrite, setCanReWrite] = useState(false);
    const [writingState, setWritingState] = useState({
        state: null,
        text: "",
        useCase: 1,
    });
    const [saved, setSaved] = useState("");
    const [metaData, setMetaData] = useState(null);
    const unMounted = useRef(true);
    const [docInfo, setDocInfo] = useState({ title: "", id: "" });
    const [lastAIcase, setLastAIcase] = useState(null);
    const [aiWordLimitSetting, setAiWordLimitSetting] = useState(
        localStorage.getItem("AIWordLimitSetting")
            ? JSON.parse(localStorage.getItem("AIWordLimitSetting"))
            : {
                  outputLength: 3,
                  creativity: 3,
                  contextToggle: false,
                  context: "",
                  toneOfVoice: "",
                  keywords: "",
                  nlpToggle: false,
              },
    );
    const [optimiserData, setOptimiserData] = useState([
        { key: "Key Terms", value: {} },
        { key: "Meta Tags", value: {} },
        { key: "URL", value: {} },
        { key: "Featured Snippet", value: {} },
        { key: "H1 Heading", value: {} },
        { key: "Links", value: {} },
        { key: "H2-H6 Heading", value: {} },
        { key: "Content Depth", value: {} },
        { key: "Keyword Density", value: {} },
    ]);
    const [seoScore, setSeoScore] = useState(0);
    const [isOptimiserEnable, setIsOptimiserEnable] = useState(false);
    const [optimiserLoading, setOptimiserLoading] = useState(false);
    const [aiRepeatModal, setAiRepeatModal] = useState(false);
    const { mixpanelTrack } = useMixpanelHook();
    const [realtimeRating, setRealtimeRatings] = useState({
        readability: "-",
        quality: 0,
        wordCount: 0,
        wordRange: "0 - 0",
        isUp: true,
        suggestedQuality: 0,
        suggestedSEOScore: 0,
        suggestedReadability: 60,
        topScore: 0,
        avgScore: 0,
    });

    const { setState } = useContext(OnBoardingContext);

    const getSeoScore = async (props) => {
        const {
            isMeta = false,
            meta_data,
            html = "",
            stringData = "",
        } = props || {};
        let str = document.quill.editor.getText();
        if (!html) setOptimiserLoading(true);
        const rating = await RatingHook(
            stringData ? stringData : str,
            keyTerms,
            "seoScore",
        );
        const res = await postData({
            url: "/api/optimizer/score",
            payload: {
                report_id: reportInfo.report_id,
                meta_data: isMeta ? meta_data : metaData,
                check_optimiser_achievement_status: 1,
                word_count: rating.wordCount,
                html_content: html
                    ? html
                    : document.quill.editor.getSemanticHTML(),
                custom: html ? true : false,
            },
        });
        setOptimiserLoading(false);
        if (res.status == 200) {
            if (html) return parseInt(res.data.total, 10);

            setState((ps) => ({
                ...ps,
                currentStepCompleted: res?.data?.achievement_unlocked,
            }));
            setIsOptimiserEnable(res?.data?.hasContent);
            setSeoScore(parseInt(res.data.total, 10));
            if (document.trackSeoScoreOnCruiseExport) {
                mixpanelTrack(cruiseMixPanelEvents.generatedSeoScore, {
                    seoScore: parseInt(res.data.total, 10),
                });

                document.trackSeoScoreOnCruiseExport = false;
            }

            setOptimiserData([
                { key: "Key Terms", value: res?.data?.keyTerm },
                { key: "Meta Tags", value: res?.data?.metaTag },
                { key: "URL", value: res?.data?.url },
                {
                    key: "Featured Snippet",
                    value: res?.data?.featuredSnippet,
                },
                { key: "H1 Heading", value: res?.data?.h1 },
                {
                    key: "Links",
                    value: res?.data?.citations,
                },
                { key: "H2-H6 Heading", value: res?.data?.h2ToH6 },
                { key: "Content Depth", value: res?.data?.contentDepth },
                { key: "Keyword Density", value: res?.data?.keywordDensity },
            ]);
        }
    };

    const fetchScore = async () => {
        const res = await getData({
            url: `/api/optimizer/score/${reportInfo.report_id}`,
        });
        if (res.status == 200) {
            setSeoScore(parseInt(res.data.total, 10));
            setOptimiserData([
                { key: "Key Terms", value: res?.data?.keyTerm },
                { key: "Meta Tags", value: res?.data?.metaTag },
                { key: "URL", value: res?.data?.url },
                {
                    key: "Featured Snippet",
                    value: res?.data?.featuredSnippet,
                },
                { key: "H1 Heading", value: res?.data?.h1 },
                {
                    key: "Links",
                    value: res?.data?.citations,
                },
                { key: "H2-H6 Heading", value: res?.data?.h2ToH6 },
                { key: "Content Depth", value: res?.data?.contentDepth },
                { key: "Keyword Density", value: res?.data?.keywordDensity },
            ]);
        }
    };

    useEffect(() => {
        // if (attachedTo != SMART_EDITOR) {
        //     getSeoScore();
        // }
        unMounted.current = false;
        return () => (unMounted.current = true);
    }, []);

    useEffect(() => {
        localStorage.setItem(
            "AIWordLimitSetting",
            JSON.stringify(aiWordLimitSetting),
        );
    }, [aiWordLimitSetting]);

    function ShowDisclaimerAlert() {
        setAiRepeatModal(true);
        setTimeout(() => {
            setAiRepeatModal(false);
        }, 10000);
    }

    const { postData, getData } = useHelperFunctions();

    async function getDataFromUrl(url, closeModal = () => {}) {
        const editor = document.quill.editor;

        const response = await getData({
            url: GET_DATA_FROM_URL + "?url=" + url,
        });
        try {
            if (response.status == 200 && !unMounted.current) {
                if (response.data.data && response.data.data.delta) {
                    editor.setContents(response?.data?.data?.delta, "api");
                } else if (response.data.data && response.data.data.content) {
                    editor.deleteText(0, editor.getLength());
                    await editor.insertEmbed(
                        editor.cursor,
                        "text",
                        response?.data?.data?.content + " ",
                        "api",
                    );
                }
            }
        } catch (error) {
            console.log(error);
        }
        closeModal(false);
    }

    async function writeForMe(id, useCase = "", isTool = false, index, length) {
        // editor check enabled or not

        if (document.quill.editor.isEnabled()) {
            setLastAIcase({ id, useCase, isTool });
            if (!length) {
                document.quill.editor.lastCursor = document.quill.editor.cursor;
                document.quill.editor.lastLength = 0;
            } else {
                document.quill.editor.lastCursor =
                    document.quill.editor.cursor + length;
                document.quill.editor.lastLength = length;
            }
            // not enough content for AI

            if (
                !length &&
                document?.quill?.editor
                    ?.getText(0, document.quill.editor.cursor)
                    .trim().length < 16
            ) {
                setNoContentAiModal(true);
                return false;
            }

            setWritingState({ state: true, text: "Thinking", useCase: id });
            document.quill.editor.disable();
            const textToSend = getPayloadText(
                document.quill.editor.cursor,
                useCase,
                id,
                length,
            );

            const optionalObj = {
                context: aiWordLimitSetting.context,
                nlp_toggle:
                    attachedTo == SMART_EDITOR
                        ? 0
                        : aiWordLimitSetting.nlpToggle
                        ? 1
                        : 0,
                keywords: aiWordLimitSetting.keywords,
                title: reportInfo.title,
            };

            const response = await postData({
                url: WRITE_FOR_ME,
                payload: {
                    text:
                        textToSend.length > 2500
                            ? textToSend.slice(-2500).replace(/^\S+\s+/, "")
                            : textToSend,
                    id: id,
                    report_id: reportInfo.report_id,
                    isTool,
                    tokens: aiWordLimitSetting.outputLength,
                    creativity: aiWordLimitSetting.creativity,
                    ...optionalObj,
                },
                optionalToken: true,
            });

            setWritingState({ state: true, text: "Writing", useCase: id });
            try {
                if (response.status == 200) {
                    console.log("-------USECASE", useCase);
                    if (id == 65) {
                        if (
                            !noComma.includes(useCase.toLowerCase()) &&
                            useCase
                        ) {
                            useCase += ",";
                        }
                        response.data.text = useCase + " " + response.data.text;
                    }

                    // if ((id > 64 && id < 70) || id == 74) useCase = "";
                    // else if (id != 63 && useCase) {
                    //   response.data.text = useCase + " " + response.data.text;
                    // }

                    if (response.data.isDisclaimer) ShowDisclaimerAlert();

                    const textForm = getTextForm(
                        response.data.text,
                        id,
                        useCase,
                    );
                    document.quill.editor.lastLength = textForm?.length;

                    if (
                        document.quill.editor.getFormat(
                            document.quill.editor.cursor,
                        ).header &&
                        id == 64 &&
                        textForm.split("\n").length > 0
                    ) {
                        let arr = textForm.split("\n");
                        await writeWithAnimation(arr[0] + "\n", true);
                        document.quill.editor.removeFormat(
                            document.quill.editor.cursor,
                        );
                        writeWithAnimation(arr.splice(1).join("\n", true));
                    } else await writeWithAnimation(textForm, true);
                } else {
                    setWritingState({ state: false, text: "", useCase: -1 });
                    document.quill.editor.enable();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    async function getEditorData() {
        const response = await getData({
            url:
                CREATOR_ARTICLE_SAVED +
                (attachedTo == SMART_EDITOR
                    ? "?doc_id=" + docId
                    : "?report_id=" + reportInfo.report_id),
            optionalToken: true,
        });

        try {
            if (response.status == 200 && !unMounted.current) {
                setRealtimeRatings((ps) => {
                    return {
                        ...ps,
                        readability:
                            response?.data?.creatorData?.readability || {},
                        quality: response?.data?.creatorData?.score || 0,
                        wordCount: response?.data?.creatorData?.totalWords || 0,
                        fromApi: 1,
                    };
                });
                setDocInfo({ title: response.data.creatorData.title });
                if (response?.data?.aiSettings && attachedTo == SMART_EDITOR) {
                    setAiWordLimitSetting((prev) => {
                        return {
                            ...prev,
                            context: response?.data?.aiSettings?.context,
                            keywords: response?.data?.aiSettings?.keywords,
                        };
                    });
                }
                if (response?.data?.creatorData?.html_content) {
                    dataToEditor(response?.data?.creatorData?.html_content);

                    setSaved(response.data.creatorData.updated_at);
                }
                if (response?.data?.creatorData?.meta_data) {
                    setMetaData({
                        ...JSON.parse(response?.data?.creatorData?.meta_data),
                        fromAPI: true,
                    });
                }
                setConnectorCases(response.data.connectorCases);
                let meta = {
                    ...(response?.data?.creatorData?.meta_data
                        ? JSON.parse(response?.data?.creatorData?.meta_data)
                        : metaData),
                    fromAPI: true,
                };
                if (attachedTo != SMART_EDITOR) {
                    getSeoScore({ isMeta: true, meta_data: meta });
                }
                return response?.data?.creatorData?.html_content;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function saveEditorData(_meta) {
        if (!document?.quill?.editor?.isEnabled()) return;
        setSaved(new Date());
        let str = document.quill.editor.getText();

        const html = document.quill.editor.getContents();

        const rating = await RatingHook(str, keyTerms, "editor");

        setRealtimeRatings((ps) => {
            return {
                ...ps,

                wordCount: rating.wordCount,
                quality: rating.score,
                fromApi: 0,
            };
        });

        const ratingData = {
            quality: {
                actual: rating.score,
                suggested: realtimeRating.suggestedQuality,
            },
            wordCount: {
                totalWords: rating.wordCount,
                wordRange: realtimeRating.wordRange,
                isUp: true,
            },
            score: rating.score,
        };
        setSaving(true);

        const idPayload =
            attachedTo == SMART_EDITOR
                ? { doc_id: docId }
                : { report_id: reportInfo.report_id };

        str = str?.replace(
            /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
            "",
        );

        const response = await postData({
            url: CREATOR_ARTICLE_SAVE,
            payload: {
                ...idPayload,
                html_content: JSON.stringify(html),
                ...(metaData && { meta_data: metaData }),
                ...(_meta && { meta_data: _meta }),
                text_content: str,
                guest_id: guestId,
                ...ratingData,
            },
            optionalToken: true,
        });

        if (response.status == 200) {
            setRealtimeRatings((ps) => {
                return {
                    ...ps,

                    readability: response?.data?.data?.readability,
                };
            });
            setSaving(false);
        }
    }

    async function writeWithAnimation(str = "", canReWrite = false) {
        document.aioutput = str;
        document.aioutputCursor = document.quill.editor.cursor;
        document?.quill?.editor?.disable();
        setCanReWrite(false);
        for (
            let index = document.quill.editor.cursor;
            index < document.quill.editor.getLength();
            index++
        ) {
            if (
                document.quill.editor.getText(index, 1) == " " ||
                document.quill.editor.getText(index, 1) == "\n"
            )
                break;
            document.quill.editor.cursor++;
        }

        let increment = 1;

        for (let i = 0; i < str.length; ) {
            if (!document.current) {
                return setWritingState({
                    state: false,
                    text: "Writing",
                    useCase: 1,
                });
            }

            if (unMounted.current) return;
            const a = performance.now();
            await new Promise((resolve, reject) => {
                const a = setImmediate(() => {
                    resolve();
                });
            }).then(() => {
                const chuck = str.slice(i, i + increment);
                WriteToEditor({
                    data: chuck,
                    length: chuck.length,
                    type: "text",
                    allowed: true,
                    source: "api",
                });

                const b = performance.now();
                i = i + increment;
                if (i == 5) {
                    if (b - a > IDEAL_WRITE_TIMEOUT)
                        increment = Math.round((b - a) / IDEAL_WRITE_TIMEOUT);
                }
            });
        }
        document?.quill?.editor?.enable();
        setCanReWrite(canReWrite);

        return setWritingState({ state: false, text: "Writing", useCase: 1 });
    }

    return {
        getDataFromUrl,
        getEditorData,
        saveEditorData,
        metaData,
        setMetaData,
        writeForMe,
        saving,
        writeWithAnimation,
        writingState,
        aiRepeatModal,
        noContentAiModal,
        setAiRepeatModal,
        setNoContentAiModal,
        canReWrite,
        lastAIcase,
        realtimeRating,
        setCanReWrite,
        setAiWordLimitSetting,
        aiWordLimitSetting,
        saved,
        setWritingState,
        setRealtimeRatings,
        docInfo,
        getSeoScore,
        optimiserData,
        seoScore,
        optimiserLoading,
        isOptimiserEnable,
    };
}

export default EditorMethods;
