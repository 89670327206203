import React, { useCallback } from "react";
import { checkForUUID, getOrder } from "./cruiseConstant";
import { Outline, WP } from "./classes";
import { CRUISE_TYPES } from "./useCruiseHook";
import useHelperFunctions from "../../../helperFunctions";
import update from "immutability-helper";
import { BRIEF_TYPES } from "../EditorBrief/useEditorBriefHook";

export const replaceIDs = (data) =>
    new Promise((res, rej) => {
        let recur = 0;
        const replace = () => {
            recur++;
            if (recur > 5) {
                res();
            }
            if (data.operation == 3) {
                if (data.h2_id && checkForUUID(data.h2_id)) {
                    const id = cruiseCache.get(data.h2_id);
                    if (id) {
                        data.h2_id = id;
                        res();
                    } else {
                        setTimeout(() => {
                            replace();
                        }, 200);
                        return;
                    }
                }
            }

            if (data.operation != 3) {
                if (data.outline_id && checkForUUID(data.outline_id)) {
                    data.outline_id = cruiseCache.get(data.outline_id);
                }

                if (data.h2_id && checkForUUID(data.h2_id)) {
                    data.h2_id = cruiseCache.get(data.h2_id);
                }

                if (data.h3_id && checkForUUID(data.h3_id)) {
                    data.h3_id = cruiseCache.get(h3_id);
                }

                if (data.wp_id && checkForUUID(data.wp_id)) {
                    data.wp_id = cruiseCache.get(data.wp_id);
                }

                if (data.wp_ids) {
                    data.wp_ids = data.wp_ids.map((wp_id) => {
                        if (checkForUUID(wp_id)) {
                            wp_id = cruiseCache.get(wp_id);
                        }
                        return wp_id;
                    });
                }

                if (data.outline_ids) {
                    data.outline_ids = data.outline_ids.map((outline_id) => {
                        if (checkForUUID(outline_id)) {
                            outline_id = cruiseCache.get(outline_id);
                        }
                        return outline_id;
                    });
                }
                if (data.order) {
                    data.order = data.order.map((id) => {
                        if (checkForUUID(id)) {
                            id = cruiseCache.get(id);
                        }
                        return id;
                    });
                }
                res();
                return;
            }
            res();
        };

        replace();
    });

const useCruiseBriefReducers = () => {
    const { postData } = useHelperFunctions();

    /**
     * @param {{type:"",data:Outline|WP}} payload
     *  * @param {initialStepsData} state
     *  */
    const SaveOutline = async (payload, state, newState) => {
        if (!payload.report_id) return;

        if (payload.type == CRUISE_TYPES.EDIT_CONTEXT_TITLE) {
            return postData({
                url: `/api/contentDraft/v2/save`,
                payload: { ...payload, report_id: payload.report_id },
            });
        } else if (payload.type == CRUISE_TYPES.UPDATE_META) {
            if (payload?.meta_data?.fromAPI === false) {
                delete payload.meta_data.fromAPI;
                postData({
                    url: `/api/contentDraft/v2/update`,
                    payload: { ...payload, report_id: payload.report_id },
                });
            }
            return;
        } else if (payload.type == CRUISE_TYPES.UPDATE_TONE) {
            postData({
                url: `/api/contentDraft/v2/update`,
                payload: { ...payload, report_id: payload.report_id },
            });

            return;
        }

        const send = async (data) => {
            await replaceIDs(data);
            return postData({
                url: `/api/contentDraft/v2/update`,
                payload: { ...data, report_id: payload.report_id },
            })
                .then((res) => {
                    if (data.operation == 3) {
                        if (data.field == "outlines") {
                            if (data.outline_id)
                                cruiseCache.set(data.outline_id, res.data.data);
                            else if (data.data) {
                                for (
                                    let index = 0;
                                    index < data.data.length;
                                    index++
                                ) {
                                    const outline = data.data[index];
                                    cruiseCache.set(
                                        outline.outline_id,
                                        res.data.data[index],
                                    );
                                }
                            } else if (data.wp_id) {
                                cruiseCache.set(data.wp_id, res.data.data);
                            }
                            const meta = {
                                h2Index: payload.h2Index,
                                tag: data.tag,
                            };
                            sendOrder({ meta }, newState);
                        } else if (data.field == "writing_points") {
                            if (data.wp_id)
                                cruiseCache.set(data.wp_id, res.data.data);
                            const meta = {
                                h2Index: payload.h2Index,
                                tag: data.tag,
                                parentIndex: payload.parentIndex,
                                isWp: true,
                            };

                            sendOrder({ meta }, newState);
                        }
                    } else if (data.operation == 2) {
                        if (data.field == "writing_points") {
                            const meta = {
                                h2Index: payload.h2Index,
                                tag: data.tag,
                                parentIndex: payload.parentIndex,
                                isWp: true,
                            };

                            let parentOutline;

                            switch (meta.tag) {
                                case Outline.tags.h2:
                                    parentOutline =
                                        newState.outlines.headings[
                                            payload.parentIndex
                                        ];
                                    break;
                                case Outline.tags.h3:
                                    parentOutline =
                                        newState.outlines.headings[
                                            payload.h2Index
                                        ].outlineH3s[payload.parentIndex];
                                    break;
                                case Outline.tags.question:
                                    parentOutline =
                                        newState.outlines.questions[
                                            payload.parentIndex
                                        ];
                                    break;
                                default:
                                    break;
                            }

                            // if no wp left
                            if (!parentOutline.wp.length)
                                sendOrder({ meta }, newState);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        if (payload.type == CRUISE_TYPES.EDIT_OUTLINES) {
            if (payload.field == "outlines") {
                //delete current outline
                send({
                    outline_ids: [payload.outline_id],
                    tag: payload.tag,
                    operation: 2,
                    field: "outlines",
                });
                //add new outline
                if (payload.tag == Outline.tags.h2) {
                    await send({
                        outline_id: payload.data.id,
                        data: payload.data.data,

                        tag: payload.tag,
                        operation: 3,
                        field: "outlines",
                    });

                    //adding all h3s

                    payload.h2Index = payload.index;
                    payload.index = 0;
                    send({
                        data: payload.data.outlineH3s.map((h3) => ({
                            outline_id: h3.id,
                            data: h3.data,
                        })),

                        tag: Outline.tags.h3,
                        operation: 3,
                        field: "outlines",
                        h2_id: payload.data.id,
                    });
                } else if (payload.tag == Outline.tags.h3) {
                    send({
                        data: [
                            {
                                outline_id: payload.data.id,
                                data: payload.data.data,
                            },
                        ],
                        tag: payload.tag,
                        operation: 3,
                        field: "outlines",
                        h2_id: payload.h2_id,
                    });
                } else {
                    send({
                        outline_id: payload.data.id,
                        data: payload.data.data,
                        tag: payload.tag,
                        operation: 3,
                        field: "outlines",
                    });
                }
            }
        } else if (payload.type == CRUISE_TYPES.OUTLINE_INTER_CHANGE) {
            if (payload.field == "outlines") {
                //delete current outline
                send({
                    outline_ids: [payload.outline_id],
                    tag: payload.tag,
                    operation: 2,
                    field: "outlines",
                });
                //add new outline
                if (payload.tag == Outline.tags.h2) {
                    //adding all h3s
                    payload.h2Index = payload.index - 1;
                    payload.index = payload.preH2.outlineH3s.length;
                    send({
                        data: payload.data.outlineH3s
                            .slice(
                                payload.preH2.outlineH3s.length,
                                payload.data.outlineH3s.length,
                            )
                            .map((h3) => ({
                                outline_id: h3.id,
                                data: h3.data,
                            })),

                        tag: Outline.tags.h3,
                        operation: 3,
                        field: "outlines",
                        h2_id: payload.data.id,
                    });
                } else if (payload.tag == Outline.tags.h3) {
                    //delete parent h2's h3 till index
                    send({
                        outline_ids: payload.parentH2.outlineH3s
                            .slice(
                                payload.h3_index,
                                payload.parentH2.outlineH3s.length,
                            )
                            .map((h3) => h3.id),

                        tag: Outline.tags.h3,
                        operation: 2,
                        field: "outlines",
                    });

                    //add as h2
                    payload.index = payload.h2Index + 1;
                    await send({
                        outline_id: payload.data.id,
                        data: payload.data.data,

                        tag: Outline.tags.h2,
                        operation: 3,
                        field: "outlines",
                    });

                    //adding all h3s
                    payload.h2Index = payload.index;
                    payload.index = 0;
                    send({
                        data: payload.data.outlineH3s.map((h3) => ({
                            outline_id: h3.id,
                            data: h3.data,
                        })),

                        tag: Outline.tags.h3,
                        operation: 3,
                        field: "outlines",
                        h2_id: payload.data.id,
                    });
                }
            }
        } else {
            send(payload);
        }
    };
    /**
     * @param {{type:"",data:Outline|WP}} action
     *  * @param {initialStepsData} state
     *  */

    const preparePayload = (state, action, newState) => {
        let payload;

        switch (action.type) {
            case CRUISE_TYPES.ADD_OUTLINES:
            case CRUISE_TYPES.ADD_ON_INDEX_OUTLINES:
                if (!action?.data?.data?.trim()) return;
                if (action.data.tag == Outline.tags.h3) {
                    if (!action.h2_id) {
                        action.h2_id =
                            state.outlines.headings[
                                state.outlines.headings.length - 1
                            ].id;
                    }

                    payload = {
                        tag: action.data.tag,
                        data: [
                            {
                                outline_id: action.data.id,
                                data: action.data.data,
                                para: action.data.para || "",
                            },
                        ],
                        field: "outlines",
                        operation: 3,
                        h2_id: action.h2_id,
                    };
                } else {
                    payload = {
                        data: action.data.data,
                        field: "outlines",
                        operation: 3,
                        tag: action.data.tag,
                        outline_id: action.data.id,
                    };
                }
                break;
            case CRUISE_TYPES.EDIT_OUTLINES:
                {
                    payload = {
                        tag: action.data.tag,
                        data: action.data,
                        field: "outlines",
                        operation: 1,
                        outline_id: action.id,
                        type: CRUISE_TYPES.EDIT_OUTLINES,
                    };

                    if (action.data.tag == Outline.tags.h3) {
                        payload.h2_id =
                            state.outlines.headings[action.h2Index].id;
                    }
                }
                break;
            case CRUISE_TYPES.OUTLINE_INTER_CHANGE:
                {
                    payload = {
                        tag: action.tag,
                        data: action.data,
                        field: "outlines",
                        outline_id: action.id,
                        type: CRUISE_TYPES.OUTLINE_INTER_CHANGE,
                    };

                    if (action.tag == Outline.tags.h2) {
                        payload.preH2 =
                            state.outlines.headings[action.index - 1];
                    }

                    if (action.tag == Outline.tags.h3) {
                        payload.h2_id =
                            state.outlines.headings[action.h2Index].id;
                        payload.parentH2 =
                            state.outlines.headings[action.h2Index];
                        payload.h3_index = action.index;
                    }
                }
                break;

            case CRUISE_TYPES.DELETE_OUTLINES:
                {
                    payload = {
                        outline_ids: [action.id],
                        field: "outlines",
                        operation: 2,
                        tag: action.tag,
                    };
                }
                break;
            case CRUISE_TYPES.DELETE_ALL_OUTLINES:
                {
                    payload = {
                        outline_ids: state.outlines.headings.map(
                            (outline) => outline.id,
                        ),
                        outline_question_ids: state.outlines?.questions?.map(
                            (outline) => outline.id,
                        ),

                        field: "outlines",
                        operation: 2,
                        tag: Outline.tags.h2,
                    };
                }
                break;
            case CRUISE_TYPES.DELETE_FEATURED_IMAGE:
                {
                    payload = {
                        featured_image: {},
                        field: "paragraph",
                    };
                }
                break;
            case CRUISE_TYPES.ADD_WP:
            case CRUISE_TYPES.ADD_ON_INDEX_WP:
                {
                    payload = {
                        tag: action.parentTag,
                        field: "writing_points",
                        operation: 3,
                        wp_id: action.data.id,
                        ...action.data,
                    };
                    action.isWp = true;
                }
                break;
            case CRUISE_TYPES.EDIT_WP:
                {
                    payload = {
                        field: "writing_points",
                        operation: 1,
                        wp_id: action.id,
                        ...action.data,
                    };
                }
                break;
            case CRUISE_TYPES.DELETE_WP:
                {
                    payload = {
                        field: "writing_points",
                        operation: 2,
                        wp_ids: [action.id],
                        tag: action.parentTag,
                    };
                }
                break;
            case CRUISE_TYPES.EDIT_PARA:
                {
                    payload = {
                        outline_id:
                            action.parentTag == Outline.tags.h2
                                ? state.outlines.headings[action.index].id
                                : action.parentTag == Outline.tags.h3
                                ? state.outlines.headings[action.h2Index]
                                      .outlineH3s[action.index].id
                                : state.outlines.questions[action.index].id,
                        para: action.para,
                        tag: action.parentTag,
                        field: "paragraph",
                    };
                }
                break;

            case CRUISE_TYPES.HANDLE_INTRODUCTION:
                {
                    if (action.edit)
                        payload = {
                            introduction: action.data,

                            field: "paragraph",
                        };
                }
                break;
            case CRUISE_TYPES.HANDLE_CONCLUSION:
                {
                    if (action.edit)
                        payload = {
                            conclusion: action.data,

                            field: "paragraph",
                        };
                }
                break;
            case CRUISE_TYPES.EDIT_CONTEXT_TITLE:
                {
                    payload = {
                        [action.field]: action.data,
                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                    };
                }
                break;

            case CRUISE_TYPES.UPDATE_META:
                {
                    payload = {
                        meta_data: action.meta,
                        type: CRUISE_TYPES.UPDATE_META,
                        field: "paragraph",
                    };
                }
                break;
            case CRUISE_TYPES.UPDATE_TONE: {
                payload = {
                    tone_id: action.tone_id,
                    field: "paragraph",
                };
            }
        }

        if (payload)
            SaveOutline(
                {
                    ...payload,
                    report_id: state.report_id,
                    index: action.index,
                    h2Index: action.h2Index,
                    parentIndex: action.parentIndex,
                },
                state,
                newState,
            );
    };

    function sendOrder({ meta }, state) {
        const { h2Index, tag, isWp, parentIndex } = meta;
        let orderPayload = {
            operation: 4,
            field: isWp ? "writing_points" : "outlines",
            tag: tag,
        };
        try {
            if (!isWp)
                switch (tag) {
                    case Outline.tags.h2:
                        {
                            const order = getOrder(state.outlines.headings);
                            orderPayload.order = order;
                        }
                        break;
                    case Outline.tags.h3:
                        {
                            const _h2Index =
                                h2Index != undefined
                                    ? +h2Index
                                    : state.outlines.headings.length - 1;
                            const order = getOrder(
                                state.outlines.headings[_h2Index].outlineH3s,
                            );

                            orderPayload.order = order;
                            orderPayload.h2_id =
                                state.outlines.headings[_h2Index].id;
                        }
                        break;
                    case Outline.tags.question:
                        {
                            const order = getOrder(state.outlines.questions);

                            orderPayload.order = order;
                        }
                        break;
                }
            else {
                switch (tag) {
                    case Outline.tags.h2:
                        {
                            const order = getOrder(
                                state.outlines.headings[parentIndex].wp,
                            );
                            orderPayload.order = order;
                            orderPayload.outline_id =
                                state.outlines.headings[parentIndex].id;
                        }
                        break;
                    case Outline.tags.h3:
                        {
                            const order = getOrder(
                                state.outlines.headings[h2Index].outlineH3s[
                                    parentIndex
                                ].wp,
                            );

                            orderPayload.order = order;
                            orderPayload.outline_id =
                                state.outlines.headings[h2Index].outlineH3s[
                                    parentIndex
                                ].id;
                        }
                        break;
                    case Outline.tags.question:
                        {
                            const order = getOrder(
                                state.outlines.questions[parentIndex].wp,
                            );

                            orderPayload.order = order;
                            orderPayload.outline_id =
                                state.outlines.questions[parentIndex].id;
                        }
                        break;
                }
            }

            //send(orderPayload);
            if (Array.isArray(orderPayload?.order)) {
                replaceIDs(orderPayload);
                postData({
                    url: `/api/contentDraft/v2/update`,
                    payload: {
                        ...orderPayload,
                        report_id: state.report_id,
                    },
                });
            }
        } catch (error) {
            console.log(error);
        }

        console.log(orderPayload);
    }

    /**
     * @param {{type:"",data:Outline|WP}} action
     *  * @param {initialStepsData} state
     *  */
    const stepsDataReducer = useCallback((state, action) => {
        try {
            if (
                action.type == CRUISE_TYPES.ADD_OUTLINES ||
                action.type == CRUISE_TYPES.ADD_ON_INDEX_OUTLINES
            ) {
                if (state.outlines.headings.length == 0) {
                    if (action.data.tag == Outline.tags.h3) {
                        return update(state, {
                            invalidAction: {
                                $set: "Please add atleast one H2",
                            },
                        });
                    }
                }
            } else if (
                action.type == CRUISE_TYPES.ADD_WP ||
                action.type == CRUISE_TYPES.ADD_ON_INDEX_WP
            ) {
                const parent =
                    action.parentTag == Outline.tags.h2
                        ? state.outlines.headings[action.parentIndex]
                        : action.parentTag == Outline.tags.h3
                        ? state.outlines.headings[action.h2Index].outlineH3s[
                              action.parentIndex
                          ]
                        : state.outlines.questions[action.parentIndex];
                if (parent.wp.length >= 10) {
                    return update(state, {
                        invalidAction: {
                            $set: "Only 10 Writing points are allowed",
                        },
                    });
                }
            }

            let newState = state;
            switch (action.type) {
                case CRUISE_TYPES.UPDATE_META:
                    newState = update(state, {
                        meta: {
                            $set: action.meta,
                        },
                    });
                    break;
                case CRUISE_TYPES.HAS_SATISFACTION_SCORE:
                    newState = update(state, {
                        hasSatisfactionScore: {
                            $set: action.hasSatisfactionScore,
                        },
                    });
                    break;

                case CRUISE_TYPES.UPDATE_TONE:
                    newState = update(state, {
                        tone_id: {
                            $set: action.tone_id,
                        },
                    });
                    break;

                case CRUISE_TYPES.UPDATE_TEMPLATE:
                    newState = update(state, {
                        active_template_id: {
                            $set: action.active_template_id,
                        },
                    });
                    break;

                case CRUISE_TYPES.SET_AUTOMATED_OUTLINES:
                    newState = {
                        ...state,
                        automated_outlines: action.state,
                    };
                    break;
                case CRUISE_TYPES.GEN_WP_TYPE:
                    newState = {
                        ...state,
                        gen_wp_type: action.tpType,
                    };
                    break;
                case CRUISE_TYPES.ORGANIC_COMPETITORS:
                    newState = {
                        ...state,
                        ranks: action.ranks,
                    };
                    break;
                case CRUISE_TYPES.AI_CONTENT_DETECTION:
                    newState = {
                        ...state,
                        aiContentDetection: action.aiContentDetection,
                    };
                    break;
                case CRUISE_TYPES.SHOW_STATS:
                    newState = {
                        ...state,
                        show_stats: action.show_stats,
                    };
                    break;

                case CRUISE_TYPES.SET_SHOW_OUTLINES:
                    newState = {
                        ...state,
                        show_outlines: action.state,
                    };
                    break;

                case CRUISE_TYPES.SET_LOADING:
                    newState = {
                        ...state,
                        isLoader: action.state,
                        text: action.text ? action.text : "",
                    };
                    break;
                case CRUISE_TYPES.SET_IMAGE_TYPE:
                    newState = {
                        ...state,
                        image_type: action.state,
                    };
                    break;
                case CRUISE_TYPES.SET_FEATURED_IMAGE:
                    newState = {
                        ...state,
                        featured_image: action.state,
                    };
                    break;
                case CRUISE_TYPES.DELETE_FEATURED_IMAGE:
                    newState = {
                        ...state,
                        featured_image: {},
                    };
                    break;
                case CRUISE_TYPES.SET_SCROLL:
                    newState = {
                        ...state,
                        isScroll: action.state,
                    };
                    break;
                case CRUISE_TYPES.SET_CRUISE_RETURN:
                    newState = {
                        ...state,
                        isCruiseReturn: action.state,
                    };
                    break;
                case CRUISE_TYPES.SET_CRUISE_DATA:
                    newState = {
                        ...state,
                        ...action.data,
                    };
                    break;
                case CRUISE_TYPES.EDIT_CONTEXT_TITLE:
                    newState = {
                        ...state,
                        [action.field]: action.data,
                        meta: null,
                    };
                    break;
                case CRUISE_TYPES.ADD_OUTLINES: {
                    if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    $push: [action.data],
                                },
                            },
                        });
                    } else {
                        if (action.data.tag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        $push: [action.data],
                                    },
                                },
                            });
                        } else {
                            const h2Index =
                                typeof action.h2Index == "number"
                                    ? action.h2Index
                                    : state.outlines.headings.length - 1;
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [h2Index]: {
                                            outlineH3s: {
                                                $push: [action.data],
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    }

                    break;
                }
                case CRUISE_TYPES.ADD_ON_INDEX_OUTLINES: {
                    if (action.field == Outline.type.heading) {
                        if (action.data.tag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        $splice: [
                                            [action.index, 0, action.data],
                                        ],
                                    },
                                },
                            });
                        } else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                $splice: [
                                                    [
                                                        action.index,
                                                        0,
                                                        action.data,
                                                    ],
                                                ],
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    $splice: [[action.index, 0, action.data]],
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }

                    break;
                }

                case CRUISE_TYPES.EDIT_OUTLINES: {
                    if (action.field == Outline.type.heading) {
                        if (action.data.tag == Outline.tags.h2)
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.index]: { $set: action.data },
                                    },
                                },
                            });
                        else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                [action.index]: {
                                                    $set: action.data,
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.index]: { $set: action.data },
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }

                    break;
                }
                case CRUISE_TYPES.DELETE_ALL_OUTLINES: {
                    newState = update(state, {
                        outlines: {
                            headings: { $set: [] },
                            questions: { $set: [] },
                        },
                    });
                    break;
                }

                case CRUISE_TYPES.DELETE_OUTLINES: {
                    if (
                        state?.outlines?.headings?.length == 1 &&
                        action.step > 3
                    ) {
                        return update(state, {
                            invalidAction: {
                                $set: "Atleast one H2 is Required",
                            },
                        });
                    }

                    if (action.field == Outline.type.heading) {
                        if (action.tag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        $splice: [[action.index, 1]],
                                    },
                                },
                            });
                        } else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                $splice: [[action.index, 1]],
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    $splice: [[action.index, 1]],
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }
                    break;
                }

                case CRUISE_TYPES.UPDATE_ID: {
                    if (action.field == Outline.type.heading) {
                        if (action.isWp) {
                            try {
                                if (action.parentTag != Outline.tags.h2) {
                                    const h2Index =
                                        state.outlines.headings.findIndex(
                                            ({ id }) => id == action.H2id,
                                        );
                                    const index = state.outlines.headings[
                                        h2Index
                                    ].outlineH3s.findIndex(
                                        ({ id }) => id == action.parent_id,
                                    );
                                    const arr =
                                        state.outlines.headings[index].wp;
                                    const wpIndex = arr.findIndex(
                                        ({ id }) => id == action.data.id,
                                    );
                                    newState = update(state, {
                                        outlines: {
                                            headings: {
                                                [h2Index]: {
                                                    outlineH3s: {
                                                        [index]: {
                                                            wp: {
                                                                [wpIndex]: {
                                                                    $set: {
                                                                        ...action.data,
                                                                        id: action.id,
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    });
                                } else {
                                    const index =
                                        state.outlines.headings.findIndex(
                                            ({ id }) => id == action.parent_id,
                                        );
                                    const arr =
                                        state.outlines.headings[index].wp;
                                    const wpIndex = arr.findIndex(
                                        ({ id }) => id == action.data.id,
                                    );

                                    arr[wpIndex].id = action.id;

                                    newState = update(state, {
                                        outlines: {
                                            headings: {
                                                [index]: {
                                                    wp: {
                                                        [wpIndex]: {
                                                            $set: {
                                                                ...action.data,
                                                                id: action.id,
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    });
                                }
                            } catch (error) {
                                newState = state;
                            }
                        } else {
                            try {
                                const index = state.outlines.headings.findIndex(
                                    ({ id }) => id == action.data.id,
                                );

                                if (action.tag == Outline.tags.h2) {
                                    newState = update(state, {
                                        outlines: {
                                            headings: {
                                                [index]: {
                                                    id: { $set: action.id },
                                                },
                                            },
                                        },
                                    });
                                } else {
                                    newState = update(state, {
                                        outlines: {
                                            headings: {
                                                [action.h2Index]: {
                                                    [index]: {
                                                        id: { $set: action.id },
                                                    },
                                                },
                                            },
                                        },
                                    });
                                }
                            } catch (error) {
                                newState = state;
                            }
                        }
                    } else {
                        if (action.isWp) {
                            try {
                                const index =
                                    state.outlines.questions.findIndex(
                                        ({ id }) => id == action.parent_id,
                                    );
                                const arr = state.outlines.questions[index].wp;
                                const wpIndex = arr.findIndex(
                                    ({ id }) => id == action.data.id,
                                );

                                arr[wpIndex].id = action.id;

                                newState = update(state, {
                                    outlines: {
                                        questions: {
                                            [index]: {
                                                wp: {
                                                    [wpIndex]: {
                                                        $set: {
                                                            ...action.data,
                                                            id: action.id,
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    },
                                });
                            } catch (error) {
                                newState = state;
                            }
                        } else {
                            const index = state.outlines.questions.findIndex(
                                ({ id }) => id == action.data.id,
                            );

                            if (index == -1) newState = state;

                            newState = update(state, {
                                outlines: {
                                    questions: {
                                        [index]: {
                                            id: { $set: action.id },
                                        },
                                    },
                                },
                            });
                        }
                    }
                    break;
                }
                case CRUISE_TYPES.SET_WPS: {
                    const list =
                        action.parentTag == Outline.tags.question
                            ? state.outlines.questions
                            : state.outlines.headings;

                    let index = -1;
                    //for h3
                    let h2Index = -1;

                    if (action.parentTag == Outline.tags.h3) {
                        for (let i = 0; i < list.length; i++) {
                            for (
                                let j = 0;
                                j < list[i].outlineH3s.length;
                                j++
                            ) {
                                if (
                                    list[i].outlineH3s[j].id ==
                                    action.outline_id
                                ) {
                                    h2Index = i;
                                    index = j;

                                    break;
                                }
                            }
                        }
                    } else {
                        for (let i = 0; i < list.length; i++) {
                            if (action.outline_id == list[i].id) {
                                index = i;
                                break;
                            }
                        }
                    }

                    if (index >= 0) {
                        if (action.parentTag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [index]: {
                                            wp: {
                                                $set: action.wp,
                                            },
                                            wp_order: {
                                                $set: action.wp_order,
                                            },
                                        },
                                    },
                                },
                            });
                        } else if (action.parentTag == Outline.tags.h3) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [h2Index]: {
                                            outlineH3s: {
                                                [index]: {
                                                    wp: {
                                                        $set: action.wp,
                                                    },
                                                    wp_order: {
                                                        $set: action.wp_order,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        } else if (action.parentTag == Outline.tags.question) {
                            newState = update(state, {
                                outlines: {
                                    questions: {
                                        [index]: {
                                            wp: {
                                                $set: action.wp,
                                            },
                                            wp_order: {
                                                $set: action.wp_order,
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else {
                        newState = state;
                    }
                    break;
                }
                case CRUISE_TYPES.ADD_WP: {
                    if (action.field == Outline.type.heading) {
                        if (action.parentTag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.parentIndex]: {
                                            wp: {
                                                $push: [action.data],
                                            },

                                            para: {
                                                $set: "",
                                            },
                                        },
                                    },
                                },
                            });
                        } else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                [action.parentIndex]: {
                                                    wp: {
                                                        $push: [action.data],
                                                    },
                                                    para: {
                                                        $set: "",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.parentIndex]: {
                                        wp: {
                                            $push: [action.data],
                                        },
                                        para: {
                                            $set: "",
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }
                    break;
                }
                case CRUISE_TYPES.ADD_ON_INDEX_WP: {
                    if (action.field == Outline.type.heading) {
                        if (action.parentTag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.parentIndex]: {
                                            wp: {
                                                $splice: [
                                                    [
                                                        action.index,
                                                        0,
                                                        action.data,
                                                    ],
                                                ],
                                            },
                                            para: {
                                                $set: "",
                                            },
                                        },
                                    },
                                },
                            });
                        } else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                [action.parentIndex]: {
                                                    wp: {
                                                        $splice: [
                                                            [
                                                                action.index,
                                                                0,
                                                                action.data,
                                                            ],
                                                        ],
                                                    },
                                                    para: {
                                                        $set: "",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.parentIndex]: {
                                        wp: {
                                            $splice: [
                                                [action.index, 0, action.data],
                                            ],
                                        },
                                        para: {
                                            $set: "",
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }

                    break;
                }
                case CRUISE_TYPES.EDIT_WP: {
                    if (action.field == Outline.type.heading) {
                        if (action.parentTag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.parentIndex]: {
                                            wp: {
                                                [action.wpIndex]: {
                                                    $set: action.data,
                                                },
                                            },
                                            para: {
                                                $set: "",
                                            },
                                        },
                                    },
                                },
                            });
                        } else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                [action.parentIndex]: {
                                                    wp: {
                                                        [action.wpIndex]: {
                                                            $set: action.data,
                                                        },
                                                    },
                                                    para: {
                                                        $set: "",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.parentIndex]: {
                                        wp: {
                                            [action.wpIndex]: {
                                                $set: action.data,
                                            },
                                        },
                                        para: {
                                            $set: "",
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }
                    break;
                }
                case CRUISE_TYPES.DELETE_WP: {
                    if (action.field == Outline.type.heading) {
                        if (action.parentTag == Outline.tags.h2) {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.parentIndex]: {
                                            wp: {
                                                $splice: [[action.wpIndex, 1]],
                                            },
                                            para: {
                                                $set: "",
                                            },
                                        },
                                    },
                                },
                            });
                        } else {
                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                [action.parentIndex]: {
                                                    wp: {
                                                        $splice: [
                                                            [action.wpIndex, 1],
                                                        ],
                                                    },
                                                    para: {
                                                        $set: "",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else if (action.field == Outline.type.question) {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.parentIndex]: {
                                        wp: {
                                            $splice: [[action.wpIndex, 1]],
                                        },
                                        para: {
                                            $set: "",
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState = state;
                    }
                    break;
                }

                case CRUISE_TYPES.HANDLE_DRAG_OUTLINE: {
                    if (action.field == Outline.type.heading) {
                        if (action.tag == Outline.tags.h2) {
                            const item =
                                state.outlines.headings[action.oldIndex];

                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        $splice: [
                                            [action.oldIndex, 1],
                                            [action.newIndex, 0, item],
                                        ],
                                    },
                                },
                            });
                            const meta = { tag: Outline.tags.h2 };
                            setImmediate(() => sendOrder({ meta }, newState));
                        } else {
                            const item =
                                state.outlines.headings[action.h2Index]
                                    .outlineH3s[action.oldIndex];

                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                $splice: [
                                                    [action.oldIndex, 1],
                                                    [action.newIndex, 0, item],
                                                ],
                                            },
                                        },
                                    },
                                },
                            });
                            const meta = {
                                h2Index: action.h2Index,
                                tag: Outline.tags.h3,
                            };
                            setImmediate(() => sendOrder({ meta }, newState));
                        }
                    } else if (action.field == Outline.type.question) {
                        const item = state.outlines.questions[action.oldIndex];

                        newState = update(state, {
                            outlines: {
                                questions: {
                                    $splice: [
                                        [action.oldIndex, 1],
                                        [action.newIndex, 0, item],
                                    ],
                                },
                            },
                        });
                        const meta = { tag: Outline.tags.question };
                        setImmediate(() => sendOrder({ meta }, newState));
                    } else {
                        newState = state;
                    }
                    break;
                }
                case CRUISE_TYPES.HANDLE_DRAG_WP: {
                    if (action.field == Outline.type.heading) {
                        if (action.parentTag == Outline.tags.h2) {
                            const item =
                                state.outlines.headings[action.parentIndex].wp[
                                    action.oldIndex
                                ];

                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.parentIndex]: {
                                            wp: {
                                                $splice: [
                                                    [action.oldIndex, 1],
                                                    [action.newIndex, 0, item],
                                                ],
                                            },
                                            para: {
                                                $set: "",
                                            },
                                        },
                                    },
                                },
                            });
                            const meta = {
                                tag: Outline.tags.h2,
                                isWp: true,
                                parentIndex: action.parentIndex,
                            };
                            setImmediate(() => sendOrder({ meta }, newState));
                        } else {
                            const item =
                                state.outlines.headings[action.h2Index]
                                    .outlineH3s[action.parentIndex].wp[
                                    action.oldIndex
                                ];

                            newState = update(state, {
                                outlines: {
                                    headings: {
                                        [action.h2Index]: {
                                            outlineH3s: {
                                                [action.parentIndex]: {
                                                    wp: {
                                                        $splice: [
                                                            [
                                                                action.oldIndex,
                                                                1,
                                                            ],
                                                            [
                                                                action.newIndex,
                                                                0,
                                                                item,
                                                            ],
                                                        ],
                                                    },
                                                    para: {
                                                        $set: "",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                            const meta = {
                                tag: Outline.tags.h3,
                                isWp: true,
                                parentIndex: action.parentIndex,
                                h2Index: action.h2Index,
                            };
                            setImmediate(() => sendOrder({ meta }, newState));
                        }
                    } else if (action.field == Outline.type.question) {
                        const item =
                            state.outlines.questions[action.parentIndex].wp[
                                action.oldIndex
                            ];

                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.parentIndex]: {
                                        wp: {
                                            $splice: [
                                                [action.oldIndex, 1],
                                                [action.newIndex, 0, item],
                                            ],
                                        },
                                        para: {
                                            $set: "",
                                        },
                                    },
                                },
                            },
                        });
                        const meta = {
                            tag: Outline.tags.question,
                            isWp: true,
                            parentIndex: action.parentIndex,
                        };
                        setImmediate(() => sendOrder({ meta }, newState));
                    } else {
                        newState = state;
                    }
                    break;
                }

                case CRUISE_TYPES.OUTLINE_INTER_CHANGE: {
                    if (action.tag == Outline.tags.h2) {
                        newState = update(state, {
                            outlines: {
                                headings: {
                                    [action.index - 1]: { $set: action.data },
                                    $splice: [[action.index, 1]],
                                },
                            },
                        });
                    } else {
                        const parentH2 =
                            state.outlines.headings[action.h2Index];
                        newState = update(state, {
                            outlines: {
                                headings: {
                                    $splice: [
                                        [action.h2Index + 1, 0, action.data],
                                    ],
                                    [action.h2Index]: {
                                        outlineH3s: {
                                            $splice: [
                                                [
                                                    action.index,
                                                    parentH2.outlineH3s.length,
                                                ],
                                            ],
                                        },
                                    },
                                },
                            },
                        });
                    }
                    break;
                }
                case CRUISE_TYPES.EDIT_PARA:
                case CRUISE_TYPES.SET_PARA: {
                    if (action.parentTag == Outline.tags.h2) {
                        newState = update(state, {
                            outlines: {
                                headings: {
                                    [action.index]: {
                                        para: { $set: action.para },
                                    },
                                },
                            },
                        });
                    } else if (action.parentTag == Outline.tags.h3) {
                        newState = update(state, {
                            outlines: {
                                headings: {
                                    [action.h2Index]: {
                                        outlineH3s: {
                                            [action.index]: {
                                                para: { $set: action.para },
                                            },
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.index]: {
                                        para: { $set: action.para },
                                    },
                                },
                            },
                        });
                    }
                    break;
                }
                case CRUISE_TYPES.SYNC_IDS: {
                    const headings = state.outlines.headings;
                    const questions = state.outlines.questions;

                    for (const h2Outline of headings) {
                        if (checkForUUID(h2Outline.id)) {
                            const id = cruiseCache.get(h2Outline.id);
                            if (id) h2Outline.id = id;
                        }

                        for (const h3outline of h2Outline.outlineH3s) {
                            if (checkForUUID(h3outline.id)) {
                                const id = cruiseCache.get(h3outline.id);
                                if (id) h3outline.id = id;
                            }
                        }
                    }

                    for (const outline of questions) {
                        if (checkForUUID(outline.id)) {
                            const id = cruiseCache.get(outline.id);
                            if (id) outline.id = id;
                        }
                    }

                    newState = update(state, {
                        outlines: {
                            questions: {
                                $set: [...questions],
                            },
                            headings: {
                                $set: [...headings],
                            },
                        },
                    });
                    break;
                }
                case CRUISE_TYPES.HANDLE_INTRODUCTION: {
                    newState = update(state, {
                        introduction: { $set: action.data },
                    });
                    break;
                }
                case CRUISE_TYPES.HANDLE_CONCLUSION: {
                    newState = update(state, {
                        conclusion: { $set: action.data },
                    });
                    break;
                }
                case CRUISE_TYPES.SET_INVALID_ACTION: {
                    newState = update(state, {
                        invalidAction: {
                            $set: action.data,
                        },
                    });
                    break;
                }

                default:
                    newState = state;
            }
            preparePayload(state, action, newState);
            return newState;
        } catch (error) {
            console.log(error, action, state);
            return state;
        }
    }, []);

    const saveBriefData = async (state, action, newState) => {
        let payload;
        switch (action.type) {
            case BRIEF_TYPES.ADD_REFERENCE:
            case BRIEF_TYPES.EDIT_REFERENCE:
            case BRIEF_TYPES.DELETE_REFERENCE:
            case BRIEF_TYPES.ORDER_REFERENCE:
                payload = {
                    refs: newState.referencesEB,
                };
                break;
            case BRIEF_TYPES.ADD_ADDITIONAL_NOTES:
                payload = {
                    notes: newState.additionalNotesEB,
                };
                break;
            case BRIEF_TYPES.EDIT_SUBDATA:
                {
                    const outline =
                        action.tag == Outline.tags.h2
                            ? newState.outlines.headings[action.index]
                            : action.tag == Outline.tags.question
                            ? newState.outlines.questions[action.index]
                            : newState.outlines.headings[action.h2Index]
                                  .outlineH3s[action.index];
                    payload = {
                        subData: action.data,
                        field: "paragraph",
                        tag: outline.tag,
                        outline_id: outline.id,
                    };
                    await replaceIDs(payload);
                }

                return postData({
                    url: `/api/contentDraft/v2/update`,
                    payload: { ...payload, report_id: newState.report_id },
                });
        }

        return postData({
            url: `/api/contentDraft/v2/save`,
            payload: { ...payload, report_id: newState.report_id },
        });
    };

    const editorBriefReducer = useCallback((state, action) => {
        let newState = state;
        switch (action.type) {
            case BRIEF_TYPES.EDIT_SUBDATA:
                {
                    if (action.tag == Outline.tags.h2) {
                        newState = update(state, {
                            outlines: {
                                headings: {
                                    [action.index]: {
                                        subData: { $set: [action.data] },
                                    },
                                },
                            },
                        });
                    } else if (action.tag == Outline.tags.h3) {
                        newState = update(state, {
                            outlines: {
                                headings: {
                                    [action.h2Index]: {
                                        outlineH3s: {
                                            [action.index]: {
                                                subData: {
                                                    $set: [action.data],
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState = update(state, {
                            outlines: {
                                questions: {
                                    [action.index]: {
                                        subData: { $set: [action.data] },
                                    },
                                },
                            },
                        });
                    }
                }

                break;

            case BRIEF_TYPES.ADD_REFERENCE:
                newState = update(state, {
                    referencesEB: {
                        $push: [action.data],
                    },
                });
                break;
            case BRIEF_TYPES.DELETE_REFERENCE:
                newState = update(state, {
                    referencesEB: {
                        $splice: [[action.index, 1]],
                    },
                });
                break;

            case BRIEF_TYPES.ORDER_REFERENCE:
                {
                    const item = state.referencesEB[action.pickIndex];
                    newState = update(state, {
                        referencesEB: {
                            $splice: [
                                [action.pickIndex, 1],
                                [action.dropIndex, 0, item],
                            ],
                        },
                    });
                }

                break;

            case BRIEF_TYPES.EDIT_REFERENCE:
                newState = update(state, {
                    referencesEB: {
                        [action.index]: { data: { $set: [action.data] } },
                    },
                });
                break;
            case BRIEF_TYPES.ADD_ADDITIONAL_NOTES:
                newState = update(state, {
                    additionalNotesEB: {
                        $set: action.data,
                    },
                });
                break;

            default:
                return stepsDataReducer(state, action);
        }

        saveBriefData(state, action, newState);
        return newState;
    }, []);

    return { editorBriefReducer, stepsDataReducer };
};

export default useCruiseBriefReducers;
