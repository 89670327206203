import React, { useContext, useEffect, useRef, useState } from "react";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import styles from "./ContentBrief.module.css";

import { CgExternal } from "react-icons/cg";
import { VscChromeMinimize } from "react-icons/vsc";
import { v4 as uuidv4, v4 } from "uuid";
import SVGIcon from "../../../atoms/SVGIcon";
import CopyH2H3 from "../../../organisms/Research/Competiton/CopyH2H3";
import CopyText from "../../../organisms/Research/CopyText";
import { debounceWrapper } from "../../../../utility/helperFun";
import HighLighter from "../../../atoms/HightLighter/HighLighter";
import NewUIDropDown, { TYPES } from "../../NewUIDropdown/NewUIDropDown";
import ExpandAll from "./atoms/ExpandAll/ExpandAll";
import TabPanelHeading from "./atoms/TabHeading/TabPanelHeading";
import { MdKeyboardArrowDown } from "react-icons/md";

import { EditorBriefContext } from "../../../organisms/Creator/EditorBrief/EditorBriefContext";
import { BRIEF_TYPES } from "../../../organisms/Creator/EditorBrief/useEditorBriefHook";
import { Outline } from "../../../organisms/Creator/GoNuts/classes";
import { CRUISE_TYPES } from "../../../organisms/Creator/GoNuts/useCruiseHook";
import { Link } from "@material-ui/core";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ReportContext from "../../../organisms/Research/ReportContext";

const CustomItem = ({ item, contentBrief, isSharedReport, access }) => {
    const { dispatchEditorBrief, setSuccessMsg } =
        useContext(EditorBriefContext);
    const [added, setAdded] = useState(false);

    useEffect(() => {
        if (added) {
            setTimeout(() => {
                setAdded(false);
            }, 2000);
        }
    }, [added]);

    return (
        <div
            className={
                styles.customItem +
                " " +
                (contentBrief && (!isSharedReport || (isSharedReport && access))
                    ? styles.pointer
                    : "")
            }
            onClick={() => {
                if (
                    contentBrief &&
                    (!isSharedReport || (isSharedReport && access))
                ) {
                    if (item.tag_name == "h1") {
                        dispatchEditorBrief({
                            type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                            data: item.text,
                            field: "title",
                        });
                        return;
                    }
                    dispatchEditorBrief({
                        type: CRUISE_TYPES.ADD_OUTLINES,
                        field: Outline.type.heading,

                        data: new Outline({
                            tag:
                                item.tag_name == Outline.tags.h2
                                    ? Outline.tags.h2
                                    : Outline.tags.h3,
                            data: item.text,
                            id: v4(),
                        }),
                    });
                    setSuccessMsg({ show: true, msg: item.text });
                    setAdded(true);
                }
            }}
        >
            <div
                className={
                    styles.customItemHeading +
                    " " +
                    styles[item.tag_name + "head"]
                }
            >
                {added ? (
                    <SVGIcon
                        size={14}
                        tooltip={"Added to brief"}
                        src={"/New UI/SVG/check.svg"}
                    />
                ) : (
                    item.tag_name
                )}
            </div>
            <div className={styles.customItemText}>{item.text}</div>
        </div>
    );
};

const sortByOptions = [
    { title: "Rank", id: 1 },
    { title: "SEO Score", id: 2 },
];

const sortOrder = {
    asc: 1,
    desc: 0,
};
/**
 *   .sort((item1, item2) => {
            return item1.tag_name.substring(1) - item2.tag_name.substring(1);
          })
 */
const ContentBriefBox = ({ item, isSharedReport, access, minimizeAll }) => {
    const ref = useRef();
    const [minimize, setMinimize] = useState(false);

    useEffect(() => {
        setMinimize(minimizeAll);
    }, [minimizeAll]);

    const headings =
        item.h_tags &&
        item.h_tags.h.filter((item) => item.text.trim().length > 0);

    return (
        <div className={styles.cbbMainContainer}>
            <div className={styles.cbbTop}>
                <div className={styles.cbbTitle}>
                    <div className={styles.cbbRank}>#{item.rank}</div>
                    <div className={styles.cbbTitleMain}>
                        {/* {item.url_title
              ? item.url_title.length <= 32
                ? item.url_title
                : item.url_title.slice(0, 32) + "..."
              : ""} */}
                        <div>{item.url_title}</div>

                        <div className={styles.options}>
                            {minimize ? (
                                <MdKeyboardArrowDown
                                    onClick={() => setMinimize(false)}
                                    size={20}
                                />
                            ) : (
                                // <SVGIcon
                                //     onClick={() => setMinimize(false)}
                                //     tooltip={"Expand"}
                                //     src={"/New UI/SVG/expand.svg"}
                                //     size={20}
                                // />
                                <MdKeyboardArrowDown
                                    onClick={() => setMinimize(true)}
                                    size={20}
                                    style={{ transform: "rotate(180deg)" }}
                                />
                                // <SVGIcon
                                //     onClick={() => setMinimize(true)}
                                //     tooltip={"Minimize"}
                                //     src={"/New UI/SVG/minimize.svg"}
                                //     size={20}
                                // />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.cbbLink}>
                    <a href={item.url} className={styles.url} target="_blank">
                        {new URL(item.url).origin}
                    </a>

                    <HighLighter
                        style={{ fontSize: 10 }}
                        text={"SEO Score:"}
                        quality={item?.optimiser_score}
                    />
                </div>
            </div>
            {
                <div
                    ref={ref}
                    className={`${styles.cssBottom}  ${
                        minimize ? styles.hide : ""
                    }`}
                >
                    {headings && headings.length > 0
                        ? headings?.map((item) => {
                              return (
                                  <CustomItem
                                      item={item}
                                      contentBrief={true}
                                      isSharedReport={isSharedReport}
                                      access={access}
                                  />
                              );
                          })
                        : "No Headings Found"}
                </div>
            }
        </div>
    );
};

function ContentBriefTable({ list, innerRef, isSharedReport, access }) {
    const [order, setOrder] = useState(sortOrder.asc);
    const [sortBy, setSortBy] = useState(sortByOptions[0]);
    const [minimizeAll, setMinimizeAll] = useState(false);
    const { reportInfo } = useContext(ReportContext);
    useEffect(() => {
        if (sortBy == sortByOptions[1]) {
            setOrder(sortOrder.desc);
        } else if (sortBy == sortByOptions[0]) {
            setOrder(sortOrder.asc);
        }
    }, [sortBy]);
    console.log(list, "?????????????");
    list = list.sort((x, y) => {
        if (order == sortOrder.desc) {
            let temp = x;
            x = y;
            y = temp;
        }
        if (sortBy.title == "SEO Score") {
            return x["optimiser_score"] - y["optimiser_score"];
        } else {
            return x["rank"] - y["rank"];
        }
    });

    return (
        <>
            <TabPanelHeading
                heading={
                    <div style={{ display: "flex" }}>
                        Outlines of Top {reportInfo.competitors_count} Ranking
                        URLs
                    </div>
                }
                children={
                    <div style={{ display: "flex" }}>
                        <NewUIDropDown
                            options={sortByOptions}
                            setOrder={setOrder}
                            order={order}
                            style={{
                                textTransform: "capitalize",
                                height: 32,
                                color: "#4b4b55",
                                // marginTop: 3,
                                minWidth: 180,
                                borderRadius: 3,
                            }}
                            type={TYPES.SORT}
                            selectedOption={sortBy}
                            handler={setSortBy}
                        />
                        <ExpandAll
                            minimize={minimizeAll}
                            setMinimize={setMinimizeAll}
                        />
                    </div>
                }
            />

            <div id="outlines" ref={innerRef} className={styles.whiteTable}>
                <div className={styles.flexContainer}>
                    <div className={styles.flexItem}>
                        {list.map((item, i) => {
                            if ((i + 1) % 2 == 1)
                                return (
                                    <ContentBriefBox
                                        item={item}
                                        key={item.rank}
                                        isSharedReport={isSharedReport}
                                        access={access}
                                        minimizeAll={minimizeAll}
                                    />
                                );
                        })}
                    </div>
                    <div className={styles.flexItem}>
                        {list.map((item, i) => {
                            if ((i + 1) % 2 == 0)
                                return (
                                    <ContentBriefBox
                                        minimizeAll={minimizeAll}
                                        item={item}
                                        key={item.rank}
                                        isSharedReport={isSharedReport}
                                        access={access}
                                    />
                                );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.forwardRef((props, ref) => (
    <ContentBriefTable innerRef={ref} {...props} />
));
