import React, { useEffect, useRef, useState } from "react";
import useHelperFunctions from "../../helperFunctions";
import {
    GET_USER_DOCUMENTS,
    CREATE_USER_DOCUMENT,
    DELETE_DOC,
    RESTORE_DOC,
} from "../../api.json";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { debounceWrapper } from "../../../utility/helperFun";
const initialLoad = {
    page: 1,
    updated: false,
    created: true,
    tags: [],
    allTags: false,
    search: "",
    is_deleted: false,
    order: 0,
};
function userDocsHook() {
    const [loading, setLoading] = useState(false);
    const [creatingDoc, setCreatingDoc] = useState(false);
    const [docs, setDocs] = useState([]);
    const goto = useNavigation();
    const { postData, getData } = useHelperFunctions();
    const [requestData, setRequestData] = useState(initialLoad);
    const [hasMore, setHasMore] = useState(true);

    const getDocs = React.useCallback(async function (requestData) {
        const {
            page,
            updated,
            created,
            tags,
            allTags,
            search,
            order,
            is_deleted,
        } = requestData;
        if (requestData.page == 1) setLoading(true);
        const res = await getData({
            url: `${GET_USER_DOCUMENTS}?page=${page}&created_at=${
                created ? 1 : 0
            }&tags=${JSON.stringify(tags)}&search_text=${search}&updated_at=${
                updated ? 1 : 0
            }&all_tags=${allTags ? 1 : 0}&is_deleted=${
                is_deleted ? 1 : 0
            }&order=${order}`,
        });

        if (res.status == 200) {
            if (res.data.data.length < 20) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
            if (requestData.page == 1) setDocs([...res.data.data]);
            else setDocs((ps) => ps.concat([...res.data.data]));
            setLoading(false);
        } else {
            setHasMore(false);
            setLoading(false);
        }
    }, []);
    let timeoutRef = useRef(null);

    useEffect(() => {
        function req() {
            getDocs(requestData);
        }

        debounceWrapper(req, 400, timeoutRef);
    }, [requestData]);

    const createDoc = React.useCallback(async function (
        urlParams,
        name,
        withRoute,
    ) {
        setCreatingDoc(true);

        const res = await postData({
            url: CREATE_USER_DOCUMENT,
            payload: {
                title: name ? name : "",
            },
        });

        if (res.status == 200) {
            openDoc(res.data.doc_id, urlParams, withRoute);
        }
    },
    []);

    async function deleteDoc(id) {
        let res = await postData({
            url: DELETE_DOC,
            payload: {
                doc_id: id,
            },
        });
        if (res.status == 200) {
            return true;
        } else {
            return false;
        }
    }

    async function restoreDoc(id) {
        await postData({
            url: RESTORE_DOC,
            payload: {
                doc_id: id,
            },
        });
    }

    function openDoc(id, urlParams, withRoute) {
        goto(
            "SMART_EDITOR",
            [],
            `${id}${urlParams ? urlParams : ""}`,
            withRoute,
        );
        setCreatingDoc(false);
    }

    return {
        loading,
        docs,
        createDoc,
        openDoc,
        setRequestData,
        hasMore,
        requestData,
        setLoading,
        deleteDoc,
        restoreDoc,
        creatingDoc,
    };
}

export default userDocsHook;
