import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import styles from "./styles.module.css";
import Button from "../../atoms/Button/Button";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { ADD_ONS_TYPE, PLANS_CATEGORIES, PLANS_TYPE } from "../../../constants";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { useDispatch, useSelector } from "react-redux";
import useHelperFunctions from "../../helperFunctions";

import SVGIcon from "../../atoms/SVGIcon";

import { UPDATE_PLAN } from "../../../componentsV2/api.json";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import { FaCheck } from "react-icons/fa6";
import { PopupModal } from "react-calendly";
import { updateUserInfo } from "../../../store/actions/auth";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";
import { paymentPopUpDimension } from "../../../components/subsCards/PriceCardBF";
import usePriceDiscounts, {
    getMonths,
    getYearSave,
} from "../../templates/Dashboard/usePriceDiscounts";
import { OfflinePaymentModal } from "../../../components/subsCards/PriceCard";

const HeaderFeatures = [
    {
        icon: (
            <SVGIcon
                src={"/New UI/SVG/gear-window.svg"}
                size={26}
                style={{
                    color: "#014DC5",
                }}
            />
        ),
        key: 1,
        text: "Scan your website for internal linking opportunities",
        desc: "Optimise link equity and enhance contextual relationships between pages with our advanced internal linking module.",
    },
    {
        icon: (
            <SVGIcon
                src={"/New UI/SVG/rocket.svg"}
                size={26}
                style={{
                    color: "#014DC5",
                }}
            />
        ),
        key: 2,
        text: "Deploy changes without engineering intervention",
        desc: "Empower seamless updates with our internal linking module's autonomous deployment capability, eliminating the need for engineering involvement.",
    },
    {
        icon: (
            <SVGIcon
                src={"/New UI/SVG/in-prog.svg"}
                size={26}
                style={{
                    color: "#014DC5",
                }}
            />
        ),
        key: 3,
        text: "Get new suggestions every 14 days",
        desc: "Stay dynamic with new internal linking suggestions every 14 days for optimal website navigation and SEO enhancement",
    },
];

const PricingKeyPoints = [
    "Get 1000 pages limit",
    "Get inbound and outbound suggestions for a content in page",
    "Get stats on the current domain status",
];

const parsePrice = (price, currency, type, discount = null, isNum = false) => {
    const CalcPrice = parseFloat(
        (
            (discount
                ? discount
                    ? price - (price * discount) / 100
                    : price
                : // @ts-ignore
                  price) / getMonths(type)
        )?.toFixed(2),
    );
    if (isNum) {
        return CalcPrice;
    } else {
        return `${currency == "USD" ? "$" : "₹"} ${CalcPrice}`;
    }
};

const InterlinkingLanding = ({
    setFirstLanding,
    firstLanding,
}: {
    firstLanding: boolean;
    setFirstLanding: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { setState } = useContext(InterlinkingContext);
    const [initiateClose, setInitiateClose] = useState(false);
    const userAccessObj = useUserAccessHook();
    const userVideo = useSelector((state: any) => state.video.audit);
    const [domainName, setDomainName] = useState("");
    const [loading, setLoading] = useState(false);
    const goTo = useNavigation();

    const show_lm_landing = useSelector(
        (state: any) => state.auth.show_lm_landing,
    );
    let url =
        "https://calendly.com/d/5gx-7jm-fw2/scalenut-demo?utm_source=product&utm_medium=interlinking";
    const dispatch = useDispatch();
    console.log("show_lm_landing", show_lm_landing);
    const [clyModal, setClyModal] = useState(false);

    const goto = useNavigation();
    const { lMLanding } = useLinkManagerMixpanel();
    const { postData } = useHelperFunctions();
    const currency = useSelector((state: any) => state.auth.currency);
    const { plans, isLoaded, isOpenChat, getDiscount } = usePriceDiscounts();
    const [isOfflinePaymentWarning, setIsOfflinePaymentWarning] =
        useState(false);
    const currencySymbol = currency == "INR" ? "₹" : "$";
    const [validPlan, setValidPlan] = useState({});
    const [validPlanType, setValidPlanType] = useState(2);
    const [validPlanCost, setValidPlanCost] = useState(0);

    useEffect(() => {
        // set Valid Lm Plan for Prices

        if (plans.length) {
            let tempType =
                userAccessObj?.subscriptionInfo?.type &&
                userAccessObj?.subscriptionInfo?.type <= 2
                    ? userAccessObj?.subscriptionInfo?.type
                    : PLANS_TYPE.YEARLY;
            setValidPlanType(tempType);
            let tempValidPlan: any = plans.find(
                (vp: any) =>
                    vp?.plan_cost > 0 &&
                    vp?.plan_type == tempType &&
                    vp.plan_category == PLANS_CATEGORIES.INTER_LINKING,
            );
            if (
                (userAccessObj?.plan_category == PLANS_CATEGORIES.PRO ||
                    userAccessObj?.plan_category == PLANS_CATEGORIES.PRO_MAX) &&
                userAccessObj?.plan_type == PLANS_TYPE.YEARLY
            ) {
                setValidPlanCost(0);
            } else {
                setValidPlanCost(tempValidPlan?.plan_cost || 0);
            }
            setValidPlan(tempValidPlan || {});
        }
    }, [plans, userAccessObj?.subscriptionInfo]);

    const getCtaText = (obj: any) => {
        console.table(obj);
        if (
            (userAccessObj?.plan_category == PLANS_CATEGORIES.PRO ||
                userAccessObj?.plan_category == PLANS_CATEGORIES.PRO_MAX) &&
            userAccessObj?.plan_type == PLANS_TYPE.YEARLY
        ) {
            return "Start your trial now";
        } else if (obj?.isFreeForever) {
            return "Buy Plan to Activate";
        } else if (
            obj?.isLTD ||
            userAccessObj?.plan_category == PLANS_CATEGORIES.PRO_MAX ||
            userAccessObj?.plan_category == PLANS_CATEGORIES.GROWTH_MAX
        ) {
            return "Start Now";
        } else if (
            obj?.trialUser ||
            [
                PLANS_CATEGORIES.GROWTH,
                PLANS_CATEGORIES.PRO,
                PLANS_CATEGORIES.PRO_MAX,
                PLANS_CATEGORIES.GROWTH_MAX,
                PLANS_CATEGORIES.EXPERT,
            ].includes(obj.plan_category)
        ) {
            return "Start your trial now";
        }
    };

    const startLM = async (skipUpdate = false) => {
        setFirstLanding(false);
        const res = await postData({ url: "/api/user/disable-lm" });
        if (res.status === 200) {
            if (!skipUpdate) {
                dispatch(updateUserInfo("show_lm_landing", 0));

                setFirstLanding(false);
            }
        }
    };

    const handleCtaAction = (obj: any) => {
        console.table(obj);
        lMLanding({
            ctaType: "LM Start",
            source: "Bottom",
            ctaUsed: getCtaText(obj),
        });
        if (
            (userAccessObj?.plan_category == PLANS_CATEGORIES.PRO ||
                userAccessObj?.plan_category == PLANS_CATEGORIES.PRO_MAX) &&
            userAccessObj?.plan_type == PLANS_TYPE.YEARLY &&
            userAccessObj?.subscriptionInfo
                ?.interlinking_purchased_pages_quantity == 0
        ) {
            let lm: any = plans.find(
                (itm: any) =>
                    itm.plan_id == userAccessObj?.subscriptionInfo?.plan_id,
            );
            if (!lm) {
                lm = plans.find(
                    (itm: any) =>
                        itm.plan_category ==
                            userAccessObj?.subscriptionInfo?.plan_category &&
                        itm.plan_type == userAccessObj?.subscriptionInfo?.type,
                );
            }
            let _lmPlanId = null;
            try {
                if (!lm?.add_on_plan_ids) {
                    startLM();
                    return;
                }

                _lmPlanId = JSON.parse(lm?.add_on_plan_ids)?.interlinking;
            } catch (err) {
                console.log("Invalid LM plan id");
            }
            let _addBundleQuantity = 2;
            createSession(
                userAccessObj?.subscriptionInfo?.plan_id,
                _lmPlanId,
                _addBundleQuantity,
                startLM,
            );
        } else if (obj?.isFreeForever) {
            goto("BILLING_PLANS");
        } else if (
            obj?.isLTD ||
            obj?.trialUser ||
            [
                PLANS_CATEGORIES.GROWTH,
                PLANS_CATEGORIES.PRO,
                PLANS_CATEGORIES.EXPERT,
                PLANS_CATEGORIES.PRO_MAX,
                PLANS_CATEGORIES.GROWTH_MAX,
            ].includes(obj.plan_category)
        ) {
            startLM();
        }
    };

    async function createSession(
        planId,
        lmId,
        newQuantity,
        startLM = () => {},
    ) {
        if (isOpenChat) {
            setIsOfflinePaymentWarning(true);
            return;
        }
        let baseURL = window.location.origin;
        let successURL = `${baseURL}/admin/transaction568894547/98797984111237/?session_id={CHECKOUT_SESSION_ID}`;
        let cancelURL = `${baseURL}/admin/transactions/failed/`;
        let criteria = {
            successURL,
            cancelURL,
            mode: "subscription",
            tid: localStorage.getItem("FPROM_ID"),
            type: 1,
            planID: planId,
        };

        // let StripeInvoicePayment = null;
        // @ts-ignore
        document.StripeInvoicePayment = window.open(
            "",
            "",
            paymentPopUpDimension,
        );
        // @ts-ignore

        document.StripeInvoicePayment.document.write(
            "<html><head></head><body>Please wait while we redirect you</body></html>",
        );
        const res = await postData({
            url: UPDATE_PLAN,
            payload: {
                planID: planId,
                type: 1,
                from: planId,
                quantity: 1,
                seat_type: 1,
                add_on: ADD_ONS_TYPE.LM_PAGES,
                additional_quantity: newQuantity,
                add_on_plan_id: lmId,
            },
        });
        if (res.status == 200) {
            // @ts-ignore
            document?.StripeInvoicePayment?.close();
            startLM();
            return setTimeout(() => goTo("LINK_MANAGER", [], "", true), 5000);
        }
        if (res.status == 201) {
            dispatch(updateUserInfo("planDowngraded", false));
            startLM();

            // @ts-ignore
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            goTo("DUMMY_TRANSACTION");
        }
        setLoading(false);
    }

    return (
        <>
            <div className={styles.landingHeader}>
                <div className={styles.innerHeader}>
                    <div className={styles.LandingHeaderTitle}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <h1>Link Manager</h1>
                            <p>Your one stop solution for internal linking</p>
                        </div>
                        <br />
                    </div>
                    {userAccessObj?.isFreeForever ? (
                        <Button
                            text={getCtaText(userAccessObj)}
                            width={262}
                            handler={() => {
                                handleCtaAction(userAccessObj);
                            }}
                            loading={loading}
                            disabled={loading}
                            style={{
                                borderRadius: 8,
                                paddingInline: 10,
                                background: "#132f72",
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 10,
                            }}
                        >
                            <Button
                                text={getCtaText(userAccessObj)}
                                handler={() => {
                                    handleCtaAction(userAccessObj);
                                }}
                                width={250}
                                height={38}
                                loading={loading}
                                disabled={loading}
                                style={{
                                    background: "white",
                                    color: "rgba(31, 76, 190, 1)",
                                    borderRadius: 8,
                                    paddingInline: 10,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.lSection}>
                <div className={styles.lSectionTitle}>Key Features</div>
                <div className={styles.cardsWrapper}>
                    {HeaderFeatures.map((ft, idx) => {
                        return (
                            <div className={styles.cardWrapp}>
                                <div className={styles.ftIcon}>{ft.icon}</div>
                                <div className={styles.ftTitle}>{ft.text}</div>
                                <div className={styles.ftDescp}>{ft.desc}</div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {!userAccessObj?.isLTD && (
                <div className={styles.lSection}>
                    <div className={styles.lSectionTitle}>Pricing</div>
                    <div className={styles.cardsWrapper2}>
                        <div className={styles.cardsWrapper2_left}>
                            {!!validPlanCost && (
                                <div className={styles.price}>
                                    {getDiscount(validPlanType || 2) > 0 && (
                                        <>
                                            <div
                                                className={styles.strike}
                                                style={{
                                                    // @ts-ignore
                                                    "--bgColor":
                                                        "rgba(255, 232, 102, 1)",
                                                }}
                                            >
                                                {parsePrice(
                                                    validPlanCost,
                                                    currency,
                                                    validPlanType || 2,
                                                )}
                                            </div>
                                            <h3
                                                className={styles.offerDiscount}
                                            >
                                                <div>
                                                    {getDiscount(
                                                        validPlanType || 2,
                                                    )}
                                                    % OFF Launch Offer
                                                </div>
                                            </h3>
                                        </>
                                    )}
                                </div>
                            )}
                            <div style={{ fontSize: 24, fontWeight: 600 }}>
                                {parsePrice(
                                    validPlanCost,
                                    currency,
                                    validPlanType || 2,
                                    getDiscount(validPlanType || 2),
                                )}
                                <span style={{ fontSize: 16 }}>/mo</span>
                            </div>
                            {!!validPlanCost && (
                                <div
                                    className={styles.youSave}
                                    style={{
                                        visibility: !getDiscount(
                                            validPlanType || 2,
                                        )
                                            ? "hidden"
                                            : "unset",
                                    }}
                                >
                                    Billed annually{" "}
                                    <strong>
                                        (Save{" "}
                                        {getYearSave(
                                            validPlanCost,
                                            currency,
                                            validPlanType || 2,
                                            getDiscount(validPlanType || 2),
                                        )}
                                        /Year)
                                    </strong>
                                </div>
                            )}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 15,
                                }}
                            >
                                {PricingKeyPoints.map((p) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: 5,
                                        }}
                                    >
                                        <FaCheck
                                            style={{
                                                color: "rgba(52, 186, 130, 1)",
                                            }}
                                        />
                                        {p}
                                    </div>
                                ))}
                            </div>
                            <Button
                                text={getCtaText(userAccessObj)}
                                width={262}
                                handler={() => {
                                    lMLanding({
                                        ctaType: "LM Start",
                                        source: "Bottom",
                                        ctaUsed: getCtaText(userAccessObj),
                                    });
                                    setDomainName("");
                                    handleCtaAction(userAccessObj);
                                }}
                                loading={loading}
                                disabled={loading}
                                style={{
                                    background: "white",
                                    color: "rgba(31, 76, 190, 1)",
                                    borderRadius: 8,
                                    paddingInline: 10,
                                }}
                            />
                        </div>
                        <div className={styles.cardsWrapper2_right}>
                            <div
                                style={{
                                    color: "rgba(35, 35, 35, 1)",
                                    fontSize: "24px",
                                    fontWeight: 600,
                                }}
                            >
                                Book a free SEO strategy call with our product
                                consultant
                            </div>
                            <div
                                style={{
                                    color: "rgba(51, 54, 63, 1)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                }}
                            >
                                Get inbound and outbound suggestions for a
                                content in page
                            </div>
                            <Button
                                text={"Book Demo"}
                                style={{
                                    paddingInline: 10,
                                    borderRadius: 8,
                                }}
                                loading={loading}
                                disabled={loading}
                                handler={() => {
                                    lMLanding({
                                        ctaType: "LM Book Demo",
                                        source: "Bottom",
                                        ctaUsed: "Book Demo",
                                    });
                                    setClyModal(true);
                                }}
                                width={129}
                            />
                            <PopupModal
                                open={clyModal}
                                onModalClose={() => setClyModal(false)}
                                url={url}
                                rootElement={
                                    document.getElementById("root") ||
                                    document.body
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
            {isOfflinePaymentWarning && (
                <OfflinePaymentModal
                    // @ts-ignore
                    setModal={setIsOfflinePaymentWarning}
                />
            )}
        </>
    );
};

export default InterlinkingLanding;
