import React, { useState, useEffect, useRef } from "react";
import { Chart as AreaChart } from "react-google-charts";
// import { Bar, Chart } from "react-chartjs-2";
// import { GoGraph } from "react-icons/go";
import styles from "./styles.module.css";
import useHelperFunctions from "../../helperFunctions";
import { LOCATION_SUGGESTIONS } from "../../api.json";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

const chartOptions = {
    hAxis: {
        textPosition: "none",
        gridlines: { count: 0 },
        titleTextStyle: { color: "#333" },
    },
    vAxis: { textPosition: "none", gridlines: { count: 0 }, minValue: 0 },
    chartArea: { width: "50%", height: "50%" },
    legend: { position: "none" },
    baselineColor: "#ffffff",
};
// import KeywordInput from "./KeywordInput";
import Button from "../../atoms/Button/Button";
import SVGIcon from "../../atoms/SVGIcon";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import TextfieldV2 from "../../atoms/CustomTextfield/Textfield";
import LocationList from "../../molecules/Creator/KeywordInput/LocationList";
import { Autocomplete } from "@material-ui/lab";
import { debounceWrapper, numberWithCommas } from "../../../utility/helperFun";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import "./TableCustomStyle.css";
import { IoMdClose } from "react-icons/io";
// import NewUIDropDown, {
//   TYPES,
// } from "../../molecules/NewUIDropdown/NewUIDropDown";
import InfiniteScroll from "react-infinite-scroll-component";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import SemrushLocationList from "../../molecules/Creator/KeywordInput/SemrushLocationList";
import { locationData, priorityLocations } from "./SemrushLocations";
import { useSelector } from "react-redux";
let timeout = null;
let timeout2 = null;
let script;
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: "18px",
        height: "18px",
        margin: "9px",
        color: "#b0b8c3",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

const sortByOptions = [
    { title: "Search Volume ", id: 1, asc: 0 },
    { title: "Competition", id: 2, asc: 0 },
    { title: "CPC", id: 3, asc: 0 },
    { title: "Related Relevance", id: 4, asc: 0 },
];

function SemrushKeywordsModal({ option, setModal }) {
    const [data, setData] = useState([]);
    const [semrushKeywords, setSemrushKeywords] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [disableCheckboxes, setDisableCheckboxes] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [input, setInput] = useState("");
    const checkBoxClasses = checkBoxCustom();
    const [country, setCountry] = useState({
        country: "United States",
        code: "US",
    });
    const [order, setOrder] = useState(0);
    const [cpcOrder, setCpcOrder] = useState(0);
    const [searchVolumeOrder, setSearchVolumeOrder] = useState(0);
    const [competitionOrder, setCompetitionOrder] = useState(0);
    const [relevanceOrder, setRelevanceOrder] = useState(0);
    const [sortBy, setSortBy] = useState(sortByOptions[3]);
    const [suggestedLocations, setSuggestedLocations] = useState(locationData);
    const [suggestedKeywords, setSuggestedKeyword] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [location, setLocation] = useState("");
    const { postData, getData } = useHelperFunctions();
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [activeClass, setActiveClass] = useState(0);
    const userSettings = useSelector((state) => state?.otherInfo?.userSettings);
    const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    useEffect(() => {
        let temp = [];
        data.forEach((item) => {
            item.chartData = [["", ""]];
            item.Trends.split(",").map((val) => {
                item.chartData.push(["", Number(val)]);
            });

            if (!item.hasOwnProperty("is_selected")) item.is_selected = false;
            temp.push(item);
        });
        setSemrushKeywords(temp);
    }, [data]);

    useEffect(() => {
        suggestLoaction();
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //   suggestLoaction();
        // }, 200);
    }, [location]);

    useEffect(() => {
        clearTimeout(timeout2);
        timeout2 = setTimeout(() => {
            suggestKeyword();
        }, 100);
    }, [input, location]);

    const suggestKeyword = async () => {
        if (script) script.remove();
        const completeUrl = `https://www.google.com/complete/search?output=search&client=chrome&q=${input}&hl=en-US&gl=${
            country.code
        }&cp=${
            document.getElementById("keywordSuggestionInput")?.firstChild
                ? document.getElementById("keywordSuggestionInput").firstChild
                      .selectionEnd
                : input.length
        }&callback=s12s12&_=${+new Date()}`;

        script = document.createElement("script");

        script.src = completeUrl;
        document.head.append(script);
    };

    const createMultiKeywordReport = async (keywords) => {
        const res = await postData({
            url: "/api/keywords/create",
            payload: {
                keyword: keywords,
                country: country.country,
                competitors_count: userSettings.competitors_count,
            },
        });
        if (res.status == 200) {
            setModal(false);
        }
    };

    function makePrimary(keyword) {
        let temp = [...semrushKeywords];
        let tempKeywords = [...selectedKeywords];
        let i = tempKeywords.indexOf(keyword.Keyword);
        [tempKeywords[0], tempKeywords[i]] = [tempKeywords[i], tempKeywords[0]];
        setSelectedKeywords(tempKeywords);
        let newData = [];
        newData = temp.filter((obj) => {
            if (obj.Keyword == keyword.Keyword) {
                obj.is_primary = true;
            } else {
                obj.is_primary = false;
            }
            return obj;
        });
        setSemrushKeywords(newData);
    }

    function handleCheckboxes(item, checked) {
        let temp = [...semrushKeywords];
        let tempKeywords = [...selectedKeywords];
        console.log("selection temp", temp);
        console.log("selection item", item);
        let newData = [];
        if (!checked) {
            setDisableCheckboxes(false);
            let index = tempKeywords.indexOf(item.Keyword);
            tempKeywords.splice(index, 1);
            setSelectedKeywords(tempKeywords);
            newData = temp.filter((obj) => {
                if (obj.Keyword == item.Keyword) {
                    obj.is_selected = false;
                }
                return obj;
            });
            if (item.is_primary) {
                newData = temp.filter((obj) => {
                    if (obj.Keyword == tempKeywords[0]) {
                        obj.is_primary = true;
                    } else {
                        obj.is_primary = false;
                    }
                    return obj;
                });
            }
        } else {
            if (tempKeywords.length == 0) {
                newData = temp.filter((obj) => {
                    if (obj.Keyword == item.Keyword) {
                        obj.is_primary = true;
                    } else {
                        obj.is_primary = false;
                    }
                    return obj;
                });
            }
            newData = temp.filter((obj) => {
                if (obj.Keyword == item.Keyword) {
                    obj.is_selected = true;
                }
                return obj;
            });
            tempKeywords.push(item.Keyword);
            setSelectedKeywords(tempKeywords);
            if (tempKeywords.length >= 21) {
                setDisableCheckboxes(true);
            }
        }
        console.log("selection data", newData);
        setSemrushKeywords(newData);
        console.log("selection keyword", tempKeywords);
    }

    const suggestLoaction = () => {
        // let temp = suggestedLocations.filter((data) =>
        //   data.country.toLowerCase().includes(location.toLowerCase())
        // );
        // console.log("lllllllllllllll", location);
        // console.log("lllllllllllllll", temp);
        // setSuggestedLocations(temp);
        // if (typeof location == "string") {
        //   const res = await getData({
        //     url: `${LOCATION_SUGGESTIONS}?query=${location}`,
        //   });
        //   if (res.status == 200) {
        //     setSuggestedLocations(res.data.locations);
        //   }
        // }
    };

    async function semrushFullPhrase(isPagination) {
        // e.preventDefault();
        if (isPagination) {
            setPageLoading(true);
        } else {
            setLoading(true);
            setSemrushKeywords([]);
            setData([]);
        }
        const res = await postData({
            method: "post",
            url: "/api/integrations/semrush/related-keywords",
            payload: {
                country: country.country,
                keyword: input,
                page: page,
            },
        });
        setLoading(false);
        setPageLoading(false);
        if (res.status === 200) {
            console.log("semrush res.data", res.data);
            if (res.data.data.keywords.length < 20) {
                setHasMore(false);
            }
            setData((ps) => ps.concat(res.data.data.keywords));
        } else {
            if (res?.response?.data?.message == "Total Limit exceeded") {
                setLimitExceeded(true);
            }
        }
    }

    useEffect(() => {
        if (page > 1) {
            semrushFullPhrase(true);
        }
    }, [page]);

    const sortingFunction = (a, b) => {
        // if (order == 0) {
        //   const temp = b;
        //   b = a;
        //   a = temp;
        // }
        if (sortBy.id == 1) {
            if (searchVolumeOrder == 0) {
                const temp = b;
                b = a;
                a = temp;
            }
            return a["Search Volume"] - b["Search Volume"];
        } else if (sortBy.id == 2) {
            if (competitionOrder == 0) {
                const temp = b;
                b = a;
                a = temp;
            }
            return a.Competition - b.Competition;
        } else if (sortBy.id == 3) {
            if (cpcOrder == 0) {
                const temp = b;
                b = a;
                a = temp;
            }
            return a.CPC - b.CPC;
        } else if (sortBy.id == 4) {
            if (relevanceOrder == 0) {
                const temp = b;
                b = a;
                a = temp;
            }
            return a["Related Relevance"] - b["Related Relevance"];
        }
    };

    useEffect(() => {
        window.s12s12 = (e) => {
            console.log(e);
            if (script) script.remove();
            setSuggestedKeyword(e[1]);
        };
    }, []);

    const dataArray = [...semrushKeywords.sort(sortingFunction)];

    return (
        <div className={styles.keywordsModal}>
            <div>
                <IoMdClose
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal(false);
                    }}
                    className={styles.modalClose}
                />
            </div>
            <div className={styles.headingContainer}>
                <h2 className={styles.modalHeading}>Research Keywords</h2>
                <div className={styles.subHeading}>
                    <p>Powered by</p>
                    <SVGIcon src={"/New UI/SVG/Semrush_logo.svg"} />
                </div>
            </div>
            <div className={styles.keywordInputContainer}>
                <div className={styles.keywordInput}>
                    <div
                        style={{
                            flexGrow: 1,
                            position: "relative",
                        }}
                        className={styles.textFieldDiv}
                    >
                        <Autocomplete
                            inputValue={input}
                            style={{ flexGrow: 1 }}
                            className={styles.inputMedia}
                            freeSolo
                            onInputChange={(a, b) => {
                                setInput(b.substring(0, 45));
                            }}
                            options={(() => {
                                suggestedKeywords.filter = () =>
                                    suggestedKeywords;
                                return [
                                    ...(input.trim() ? [input] : []),
                                    ...suggestedKeywords,
                                ];
                            })()}
                            onChange={(a, b) => {
                                if (b) setInput(b);
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option}
                            renderOption={(option) => {
                                return (
                                    <React.Fragment>
                                        <div style={{ width: "100%" }}>
                                            <div className={styles.location}>
                                                <div>{option}</div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                            renderInput={(params) => {
                                return (
                                    <div
                                        id="keywordSuggestionInput"
                                        ref={params.InputProps.ref}
                                    >
                                        <TextfieldV2
                                            label=""
                                            placeholder={
                                                "Enter topic to start with keyword research"
                                            }
                                            type="text"
                                            props={{
                                                autoComplete: "off",
                                                style: {
                                                    borderRadius: 3,

                                                    backgroundColor: "#FFF",
                                                },
                                                ...params.inputProps,
                                            }}
                                            autoFocus={true}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    semrushFullPhrase(false);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        />
                        {/* <Textfield
              label=""
              placeholder="Enter topic to start with keyword research"
              props={{
                value: input,
                onChange: (e) => setInput(e.target.value),
                style: {
                  borderRadius: 3,
                },
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  semrushFullPhrase(false);
                }
              }}
              customClass={styles.inputMedia}
            /> */}
                    </div>

                    <SemrushLocationList
                        location={location}
                        country={country}
                        priorityLocations={priorityLocations}
                        setCountry={setCountry}
                        setLocation={setLocation}
                        suggestedLocations={suggestedLocations}
                    />

                    <Button
                        text="Search"
                        width="180px"
                        disabled={loading || !input}
                        handler={() => {
                            semrushFullPhrase(false);
                        }}
                        Icon={() => (
                            <SVGIcon src={"/New UI/SVG/Search-icon.svg"} />
                        )}
                        iconReverse
                        loading={loading}
                        height={40}
                        style={{
                            borderRadius: 3,
                        }}
                        customClass={styles.buttonMedia}
                    />
                </div>
            </div>
            {/* {data.length > 0 && (
        <div className={styles.sortByDiv}>
          <NewUIDropDown
            options={sortByOptions}
            setOrder={setOrder}
            order={order}
            style={{
              textTransform: "capitalize",
              height: "34px",
              color: "#6b6b6b",
              width: 170,
              minWidth: "280px",
              textAlign: "left",
              zIndex: "10",
            }}
            type={TYPES.SORT}
            selectedOption={sortBy}
            handler={setSortBy}
          />
        </div>
      )} */}
            <div className={styles.keywordsTable}>
                {loading ? (
                    <div className={styles.circularLoad}>
                        <CircularProgress size={40} />
                    </div>
                ) : limitExceeded ? (
                    <div className={styles.limitExceeded}>
                        You have exhausted your Semrush account credits
                    </div>
                ) : dataArray.length > 0 ? (
                    <InfiniteScroll
                        dataLength={dataArray.length}
                        next={() => {
                            setPage((ps) => ps + 1);
                        }}
                        height={`calc(100vh - 330px)`}
                        hasMore={hasMore}
                        loader={
                            page > 1 ? (
                                <>
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                </>
                            ) : (
                                <></>
                            )
                        }
                    >
                        <table>
                            {data.length > 0 && (
                                <thead style={{ position: "sticky", top: 0 }}>
                                    <th className={styles.tableHead}>
                                        Related Keywords
                                    </th>
                                    <LightTooltip
                                        arrow
                                        placement="top"
                                        title="The interest levels of users in a given keyword over a period of 12 months. The graph is a representation of changes in the number of queries per month."
                                    >
                                        <th
                                            style={{
                                                textAlign: "right",
                                                paddingRight: "30px",
                                            }}
                                            className={styles.tableHead}
                                        >
                                            Trends
                                        </th>
                                    </LightTooltip>
                                    <LightTooltip
                                        arrow
                                        placement="top"
                                        title=""
                                    >
                                        <th
                                            onClick={() => {
                                                setActiveClass(4);
                                                if (sortBy.id === 4) {
                                                    setRelevanceOrder(
                                                        relevanceOrder == 0
                                                            ? 1
                                                            : 0,
                                                    );
                                                } else {
                                                    setSortBy(sortByOptions[3]);
                                                }
                                            }}
                                            className={styles.tableHead}
                                        >
                                            <div
                                                className={
                                                    activeClass == 4
                                                        ? styles.activeTh
                                                        : styles.tableHeading
                                                }
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <span>Relevance</span>
                                                <div>
                                                    {relevanceOrder == 0 ? (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortD.svg"
                                                            }
                                                        />
                                                    ) : (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortA.svg"
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    </LightTooltip>
                                    <LightTooltip
                                        arrow
                                        placement="top"
                                        title="Cost per click refers to the actual price you pay to bid on keywords for a chance to show ads in Google search results."
                                    >
                                        <th
                                            onClick={() => {
                                                setActiveClass(1);
                                                if (sortBy.id === 3) {
                                                    setCpcOrder(
                                                        cpcOrder == 0 ? 1 : 0,
                                                    );
                                                } else {
                                                    setSortBy(sortByOptions[2]);
                                                }
                                            }}
                                            className={styles.tableHead}
                                        >
                                            <div
                                                className={
                                                    activeClass == 1
                                                        ? styles.activeTh
                                                        : styles.tableHeading
                                                }
                                            >
                                                <span>CPC</span>
                                                <div>
                                                    {cpcOrder == 0 ? (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortD.svg"
                                                            }
                                                        />
                                                    ) : (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortA.svg"
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    </LightTooltip>
                                    <LightTooltip
                                        arrow
                                        placement="top"
                                        title="It refers to the degree of competition for the keyword in paid search on a scale of 0 to 1. The higher the score, the more difficult it will be to stand out in the search results, and vice versa."
                                    >
                                        <th
                                            onClick={() => {
                                                setActiveClass(2);
                                                if (sortBy.id === 2) {
                                                    setCompetitionOrder(
                                                        competitionOrder == 0
                                                            ? 1
                                                            : 0,
                                                    );
                                                } else {
                                                    setSortBy(sortByOptions[1]);
                                                }
                                            }}
                                            className={styles.tableHead}
                                        >
                                            <div
                                                className={
                                                    activeClass == 2
                                                        ? styles.activeTh
                                                        : styles.tableHeading
                                                }
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <span>Paid Competition</span>
                                                <div>
                                                    {competitionOrder == 0 ? (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortD.svg"
                                                            }
                                                        />
                                                    ) : (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortA.svg"
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    </LightTooltip>
                                    <LightTooltip
                                        arrow
                                        placement="top"
                                        title="It indicted the number of queries for a specific keyword on Google over the last 12 months."
                                    >
                                        <th
                                            onClick={() => {
                                                setActiveClass(3);
                                                if (sortBy.id === 1) {
                                                    setSearchVolumeOrder(
                                                        searchVolumeOrder == 0
                                                            ? 1
                                                            : 0,
                                                    );
                                                } else {
                                                    setSortBy(sortByOptions[0]);
                                                }
                                            }}
                                            className={styles.tableHead}
                                        >
                                            <div
                                                className={
                                                    activeClass == 3
                                                        ? styles.activeTh
                                                        : styles.tableHeading
                                                }
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <span>Search Volume</span>
                                                <div>
                                                    {searchVolumeOrder == 0 ? (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortD.svg"
                                                            }
                                                        />
                                                    ) : (
                                                        <SVGIcon
                                                            size={14}
                                                            src={
                                                                "/New UI/SVG/sortA.svg"
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    </LightTooltip>
                                </thead>
                            )}
                            {data &&
                                dataArray.map((keyword, i) => {
                                    return (
                                        <tr key={keyword.Keyword}>
                                            <td className={styles.tableCell}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color={"default"}
                                                            disabled={
                                                                disableCheckboxes &&
                                                                !semrushKeywords[
                                                                    i
                                                                ]?.is_selected
                                                            }
                                                            checked={
                                                                semrushKeywords[
                                                                    i
                                                                ]?.is_selected
                                                            }
                                                            classes={
                                                                checkBoxClasses
                                                            }
                                                            value={
                                                                keyword.Keyword
                                                            }
                                                            onChange={(e) => {
                                                                handleCheckboxes(
                                                                    keyword,
                                                                    e.target
                                                                        .checked,
                                                                );
                                                            }}
                                                            name="checkedB"
                                                        />
                                                    }
                                                    label={keyword.Keyword}
                                                />
                                                {semrushKeywords[i]
                                                    ?.is_selected &&
                                                    !semrushKeywords[i]
                                                        ?.is_primary && (
                                                        <Button
                                                            handler={() =>
                                                                makePrimary(
                                                                    semrushKeywords[
                                                                        i
                                                                    ],
                                                                )
                                                            }
                                                            width="170px"
                                                            height="25px"
                                                            fontSize="12px"
                                                            customClass={
                                                                styles.primaryButton
                                                            }
                                                            text="Make this Primary Keyword"
                                                        />
                                                    )}
                                                {semrushKeywords[i]
                                                    ?.is_selected &&
                                                    semrushKeywords[i]
                                                        ?.is_primary && (
                                                        <Button
                                                            width="70px"
                                                            height="25px"
                                                            fontSize="12px"
                                                            customClass={
                                                                styles.primary
                                                            }
                                                            text="Primary"
                                                        />
                                                    )}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <AreaChart
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        float: "right",
                                                        alignItems: "center",
                                                    }}
                                                    chartType="AreaChart"
                                                    width="100px"
                                                    height="40px"
                                                    data={keyword.chartData}
                                                    options={chartOptions}
                                                />
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {Number(
                                                    keyword[
                                                        "Related Relevance"
                                                    ],
                                                ).toFixed(2)}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                {Number(keyword.CPC).toFixed(2)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {Math.round(
                                                    keyword.Competition,
                                                )}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {numberWithCommas(
                                                    keyword["Search Volume"],
                                                )}
                                            </td>
                                            {/* <TableCell>{keyword[4]}</TableCell> */}

                                            {/* <TableCell>
                      <FcPlus
                        onClick={() => {
                          makeReport({
                            input: keyword.keyword,
                            _country: country,
                          });
                          setInputValue("");
                          setModal(false);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </TableCell> */}
                                        </tr>
                                    );
                                })}
                        </table>
                    </InfiniteScroll>
                ) : (
                    <div className={styles.noDataBG}>
                        <SVGIcon
                            size={200}
                            src="/New UI/SVG/keyword_research_bg.svg"
                        />
                        <p>
                            Search for a keyword to get related keywords with
                            their performance data
                        </p>
                    </div>
                )}
            </div>
            <div className={styles.modalBottom}>
                {data.length > 0 && (
                    <p className={styles.infoText}>
                        <span style={{ color: "#2A4266", marginRight: "5px" }}>
                            Selected Keywords : {selectedKeywords.length}{" "}
                        </span>{" "}
                        (Max 21 keywords)
                    </p>
                )}
                {data.length > 0 && (
                    <Button
                        // loading={loading}
                        text="Create SEO Article"
                        height={"40px"}
                        width={"220px"}
                        borderRadius={"2px"}
                        handler={() =>
                            createMultiKeywordReport(selectedKeywords)
                        }
                        disabled={selectedKeywords.length < 1}
                        loadingText={loading}
                        style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            backgroundColor: "var(--primary-blue)",
                            color: "white",
                            border: "solid 1px #e9ecf4",
                        }}
                        iconReverse
                        Icon={() => (
                            <SVGIcon size={"20"} src={"/New UI/SVG/doc.svg"} />
                        )}
                        lessDots={true}
                    />
                )}
            </div>
        </div>

        // <React.Fragment>
        //   <div
        //     style={{
        //       width: "100%",
        //       display: "flex",
        //       justifyContent: "space-between",
        //       position: "relative",
        //     }}
        //   >
        //     <div className={styles.location}>{option}</div>
        //
        //   </div>
        // </React.Fragment>
    );
}

export default SemrushKeywordsModal;
