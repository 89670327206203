import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../store/actions/auth";
import Axios from "axios";
import { url } from "../../utility/config";
import {
    AUTH_LOGOUT_DASHBOARD,
    AUTH_LOGOUT_OTHERINFO,
    AUTH_LOGOUT_VARIANT,
} from "../../store/actiontypes";

const Logout = ({ render }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const BASE_URL = url;
    const logoutHandler = async (explicitLogout) => {
        if (explicitLogout) {
            await Axios({
                url: BASE_URL + "/api/user/logout",
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                    "X-Frame-Options": "DENY",
                },
                data: {},
            });
        }
        dispatch(userLogout());
        dispatch({ type: AUTH_LOGOUT_VARIANT });
        dispatch({ type: AUTH_LOGOUT_DASHBOARD });
        dispatch({ type: AUTH_LOGOUT_OTHERINFO });

        history.push("/auth");
    };

    return render(logoutHandler);
};

export default Logout;
