import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import styles from "./styles.module.css";
import GSCSimpleLogin from "../../organisms/GSCSimpleLogin/GSCSimpleLogin";
import useHelperFunctions from "../../helperFunctions";
import SVGIcon from "../../atoms/SVGIcon";
import { BASE_URL } from "./constants";
import { displayAlert } from "../../../store/actions/alert";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";

const InterLinkingGscBarInfo = ({
    isFindingOpr,
    isGscConnected,
    domainId,
    isReAuth,
}: {
    isReAuth: boolean;
    isFindingOpr: boolean;
    isGscConnected: boolean;
    domainId: number;
}) => {
    const { setState, selectedDomainInfo } = useContext(InterlinkingContext);
    const { userEmail } = useSelector((state: any) => state.auth);
    const [googleLoading, setGoogleLoading] = useState(false);
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const { lMManageDomainCta } = useLinkManagerMixpanel();
    const googleCallback: (response: any) => void = async (response) => {
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    ...(domainId && { domain_id: domainId }),
                    webTuneInternLinking: 1,
                },
            });
            if (res.status == 200) {
                let gscId = res.data?.resp?.userInfo.gsc_id;
                setState((ps: any) => {
                    const _domainList = ps.domainsList.map((domain: any) => {
                        if (domain?.id === domainId) {
                            return {
                                ...domain,
                                gsc_id: gscId,
                            };
                        }
                        return domain;
                    });
                    const _selectedDomainInfo = {
                        ...ps.selectedDomainInfo,
                        gsc_id: gscId,
                    };
                    return {
                        ...ps,
                        selectedDomainInfo: _selectedDomainInfo,
                        domainsList: _domainList,
                    };
                });
                setGoogleLoading(false);
            } else {
                setGoogleLoading(false);

                dispatch(
                    displayAlert({
                        alertType: "error",
                        alertMessage: `${res?.response?.data?.message}`,
                    }),
                );
            }
        } else {
            setGoogleLoading(false);
        }
    };

    const responseGoogleReAuth = async (response) => {
        lMManageDomainCta({
            ctaType: "Reconnect GSC",
            screen: "Link Manager",
            ctaUsed: "Reconnect GSC",
            // @ts-ignore
            domainStatus: selectedDomainInfo?.is_verified || "-",
            // @ts-ignore

            pCrawled: selectedDomainInfo?.stable_version_total_pages,
            // @ts-ignore

            pTotal: selectedDomainInfo?.stable_version_total_crawled_pages,
        });
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/re-authenticate",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    gsc_id: selectedDomainInfo?.gsc_id,
                    domain_name: selectedDomainInfo?.gsc_domain_name,
                },
            });
            if (res.status == 200) {
                setState((ps: any) => {
                    const _domainList = ps.domainsList.map((domain: any) => {
                        if (domain?.id === domainId) {
                            return {
                                ...domain,
                                re_auth: 0,
                            };
                        }
                        return domain;
                    });

                    const _selectedDomainInfo = {
                        ...ps.selectedDomainInfo,
                        re_auth: 0,
                    };
                    return {
                        ...ps,
                        selectedDomainInfo: _selectedDomainInfo,
                        domainsList: _domainList,
                    };
                });
                setGoogleLoading(false);
            } else {
                setGoogleLoading(false);

                dispatch(
                    displayAlert({
                        alertType: "error",
                        alertMessage: `${res?.response?.data?.message}`,
                    }),
                );
            }
        } else {
            setGoogleLoading(false);
        }
    };

    return (
        <div
            className={`${styles.interlinkingGscBar} ${
                !isGscConnected && styles.noGscConnected
            }`}
        >
            <div className={styles.barText}>
                {isFindingOpr ? (
                    <>
                        <SVGIcon
                            src={"/New UI/SVG/hour-glass.svg"}
                            size={18}
                            style={{ marginRight: 5 }}
                            color="#1f4cbe"
                        />
                        Finding Internal Linking opportunity .... We will notify
                        you at email{" "}
                        <span className={styles.userEm}>{userEmail}</span>
                        when done.
                    </>
                ) : (
                    <>
                        <SVGIcon
                            src={"/New UI/SVG/exclamation-circle.svg"}
                            size={18}
                            style={{ marginRight: 5 }}
                            color="#FC5A5A"
                        />
                        {isReAuth
                            ? "Connect your Google search console to get new internal linking opportunity."
                            : "No Linking suggestions. Connect your Google Search Console to get customized linking opportunity."}
                    </>
                )}
            </div>
            {!isGscConnected && (
                <div className={styles.barCta}>
                    <GSCSimpleLogin
                        height={36}
                        btnText={isReAuth ? "Reconnect Gsc" : "Connect GSC"}
                        width={"160px"}
                        googleCallback={
                            isReAuth ? responseGoogleReAuth : googleCallback
                        }
                        loading={googleLoading}
                        setLoading={setGoogleLoading}
                    />
                </div>
            )}
        </div>
    );
};

export default InterLinkingGscBarInfo;
