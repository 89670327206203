import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import moment from "moment";
import { VscClose, VscTrash } from "react-icons/vsc";

// import { FREE_PLAN_ID, PLAN_CATEGORIES } from "../Pricing/constants";
import { useDispatch, useSelector } from "react-redux";

import UpgradeModal from "./UpgradeModal";
import { FcGoogle } from "react-icons/fc";
import { SelectList } from "../InterLinking/LinkingOnBoardFlow";
import { DomainChangeCases } from "./ManageDomainsWrapper";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import SVGIcon from "../../atoms/SVGIcon";
import Modal from "../../atoms/Modal/Modal";
import Button from "../../atoms/Button/Button";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import GSCSimpleLogin from "../../organisms/GSCSimpleLogin/GSCSimpleLogin";
import { CircularProgress, colors } from "@material-ui/core";
import useHelperFunctions from "../../helperFunctions";
import useGscHook from "../GoogleSearchConsole/useGscHook";
import { displayAlert } from "../../../store/actions/alert";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { PLANS_CATEGORIES } from "../../../constants";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useLinkManagerMixpanel } from "../InterLinking/useLinkManagerMixpanel";

const DummyRows = () => {
    const DummyRows = [1, 2, 3];
    return (
        <div className={styles.rowContainer} style={{ paddingInline: 20 }}>
            {DummyRows.map(() => {
                return <Skeleton width={"100%"} height={"76px"} />;
            })}
        </div>
    );
};

const ManageDomains = ({
    arr = [],
    setModal = () => {},
    setRemoveDomain = () => {},
    setBuyPagesModal = () => {},
    addFromUrl = false,
    onChange = () => {},
    getDomains = () => {},
    isFetchingAnyDomain = false,
    setIsFetchingAnyDomain = () => {},
    domain_id = null,
    otherDomains = [],
    isAdding,
    insideLM = false,
    fetchingDomain = false,
    screen = "",
}) => {
    const { lMManageDomainCta, lMAddDomain } = useLinkManagerMixpanel();

    const [expandOtherDomains, setExpandOtherDomains] = useState(false);
    const [domainName, setDomainName] = useState();
    const [steps, setSteps] = useState(addFromUrl ? 1 : 0);
    const [isAddDomain, setIsAddDomain] = useState(addFromUrl);
    const [upgradeModal, setUpgradeModal] = useState({ isShow: false });
    const [removingGsc, setRemovingGsc] = useState(null);
    const otherInfo = useSelector((state) => state.otherInfo);

    const [isDomainsModal, setIsDomainsModal] = useState(false);
    const dispatch = useDispatch();
    // otherInfo?.subscriptionInfo?.plan_category == PLAN_CATEGORIES.elite ||
    // false;
    const { plan_category, isLTD, trialUser, freeUser } = useUserAccessHook();

    const showManageCta =
        ([
            PLANS_CATEGORIES.PRO,
            PLANS_CATEGORIES.PRO_MAX,
            PLANS_CATEGORIES.GROWTH_MAX,
            PLANS_CATEGORIES.GROWTH,
            PLANS_CATEGORIES.EXPERT,
        ].includes(plan_category) ||
            trialUser) &&
        !isLTD &&
        !otherInfo.subscriptionInfo?.terminate;

    const [userGscInfo, setUserGscInfo] = useState({});
    const [tempGscId, setTempGscId] = useState(0);
    const [gscDomains, setGscDomains] = useState([]);

    const [selectedDomain, setSelectedDomain] = useState("");
    const [googleLoading, setGoogleLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [domainForGscUpdate, setDomainForGscUpdate] = useState({});
    const [isAddingGsc, setIsAddingGsc] = useState(false);
    const [addingGscLoading, setAddingGscLoading] = useState(false);
    const [reAuthBegin, setReAuthBegin] = useState(false);

    const { postData, getData } = useHelperFunctions();
    const { getTrimmedDomainURL } = useGscHook();
    useEffect(() => {
        setIsLoading(isLoading);
        if (!isDomainsModal) {
            setIsLoading(false);
        }
    }, [isLoading, isDomainsModal]);
    useEffect(() => {
        if (!isDomainsModal) {
            setDomainName("");
        }
    }, [isDomainsModal]);

    useEffect(() => {}, [isAdding]);
    const isValidDomain = () => {
        const regex = new RegExp(
            /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        );
        return regex.test(domainName);
    };

    const getGscDomains = (obj = { isAddingGsc: false, cDomain: {} }) => {
        return new Promise((res1, rej) => {
            (async () => {
                try {
                    const res = await getData({
                        url: `/api/analyse/getDomainList?gsc_id=${obj?.gsc_id}`,
                    });
                    if (res.status == 200) {
                        const data =
                            res.data.domainList?.map((dm) => ({
                                ...dm,
                                used: 0,
                            })) || [];
                        setIsDomainsModal(true);

                        if (data.length == 0) {
                            dispatch(
                                displayAlert({
                                    alertType: "error",
                                    alertMessage: `No domains detected for this GSC account.`,
                                }),
                            );
                            setGscDomains([]);
                            setIsDomainsModal(true);
                            setGoogleLoading(false);
                            rej();
                            return;
                        }
                        setGscDomains([...data]);
                        setTempGscId(res.data?.gsc_id || 0);

                        res1("");
                    } else {
                        setGscDomains([]);
                        setIsDomainsModal(true);
                        setGoogleLoading(false);
                    }
                } catch (err) {
                    rej();
                } finally {
                    rej();
                }
            })();
        });
    };

    const googleCallback = async (response, dmId = null) => {
        setAddingGscLoading(true);
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    ...(dmId && { domain_id: dmId }),
                    webTuneInternLinking: 1,
                },
            });
            if (res.status == 200) {
                setAddingGscLoading(false);

                setUserGscInfo({
                    ...res.data?.resp?.userInfo,
                });
                if (!dmId) {
                    const data = await getGscDomains(res.data?.resp?.userInfo);
                } else {
                    onChange({
                        type: DomainChangeCases.addGsc,
                        gscId: res.data?.resp?.userInfo.gsc_id,
                        dName: "",
                        id: dmId,
                    });
                }
                setGoogleLoading(false);
            } else {
                setAddingGscLoading(false);

                setGoogleLoading(false);
                dispatch(
                    displayAlert({
                        alertType: "error",
                        alertMessage: `${res.response?.data?.message}`,
                    }),
                );
            }
        }
        setGoogleLoading(false);
    };

    const handleLogin = () => {
        setGoogleLoading(true);
        if (!isLTD && !!arr.length) {
            if (
                freeUser &&
                otherInfo?.subscriptionInfo?.interlinking_status == 2
            ) {
                lMAddDomain({
                    screen: screen,
                    ctaUsed: "Add Domain",
                    popUp: "Upgrade Modal",
                });
                setUpgradeModal({ isShow: true });
                return;
            } else if (otherInfo?.subscriptionInfo?.interlinking_status == 2) {
                lMAddDomain({
                    screen: screen,
                    ctaUsed: "Add Domain",
                    popUp: "Buy Pages",
                });
                setBuyPagesModal({ isShow: true });
                return;
            }
        }
        lMAddDomain({
            screen: screen,
            ctaUsed: "Add Domain",
            popUp: "-",
        });

        let iLink = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: googleCallback,
            error_callback: () => {
                setGoogleLoading(false);
            },
        });
        iLink.requestCode();
    };

    const handleGscReAuth = (info = {}) => {
        let iLink = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: (rs) => {
                responseGoogleReAuth(rs, info);
            },
            error_callback: () => {
                setReAuthBegin(false);

                setGoogleLoading(false);
            },
        });
        iLink.requestCode();
    };

    const responseGoogleReAuth = async (response, connectionInfo) => {
        if (response.error) {
            setReAuthBegin(false);

            return;
        }
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/re-authenticate",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    gsc_id: connectionInfo?.gsc_id,
                    domain_name: connectionInfo?.gsc_domain_name,
                },
            });
            if (res.status == 200) {
                onChange({
                    type: DomainChangeCases.addGsc,
                    gscId: connectionInfo?.gsc_id,
                    id: connectionInfo.id,
                });
            } else {
                dispatch(
                    displayAlert({
                        alertMessage: `${res.response.data.message}`,
                        alertType: "error",
                        timeout: 5000,
                    }),
                );
            }
        }
    };

    return (
        <div
            className={`${styles.manageDomainModal} ${
                !insideLM && styles._overRide
            }`}
        >
            {insideLM && steps == 0 && (
                <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>Manage Domains</div>
                    <Button
                        className={styles.addCtaTop}
                        handler={() => {
                            handleLogin();
                        }}
                        text={"+ Add Domain"}
                        width={138}
                        style={{
                            marginRight: 36,
                            fontWeight: 500,
                            fontSize: 14,
                        }}
                        height={28}
                    />

                    <div
                        className={styles.closeModal}
                        onClick={() => !isAdding && setModal(false)}
                    >
                        <VscClose size={26} />
                    </div>
                </div>
            )}

            <div className={styles.tableWrapper}>
                <div className={styles.headWrapper}>
                    <div className={styles.head_domainName}>Domain Name</div>
                    <div className={styles.head_doc}>Last Refresh Date</div>
                    <div className={styles.head_npages}>
                        No of Pages Crawled
                    </div>
                    <div className={styles.head_cta}></div>
                </div>
                <div className={styles.rowContainer}>
                    {fetchingDomain ? (
                        <DummyRows />
                    ) : (
                        arr?.length > 0 &&
                        arr.map((rw, idx) => {
                            return (
                                <div className={`${styles.rowWrapper}`}>
                                    <div
                                        className={styles.row_domainNameWrap}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className={styles.row_domainName}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (domain_id != rw.id)
                                                    lMManageDomainCta({
                                                        ctaType:
                                                            "Domain Switch",
                                                        screen: screen,
                                                        ctaUsed: "Buy Plan",
                                                        domainStatus:
                                                            rw?.is_verified ||
                                                            "-",
                                                        pCrawled:
                                                            rw?.stable_version_total_pages,
                                                        pTotal: rw?.stable_version_total_crawled_pages,
                                                    });
                                                onChange({
                                                    type: DomainChangeCases.setCurrentDomain,
                                                    domain: {
                                                        ...rw,
                                                        domain_id: rw.id,
                                                    },
                                                });
                                                setModal(false);
                                            }}
                                        >
                                            <div className={styles.icon}>
                                                <img
                                                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${getTrimmedDomainURL(
                                                        rw.domain,
                                                    )}&sz=32`}
                                                    alt=""
                                                    height={20}
                                                />
                                            </div>
                                            <LightTooltip
                                                title={rw.domain}
                                                placement={"top"}
                                                arrow
                                                onOpen={(e, setTitle) => {
                                                    if (
                                                        e.target.scrollWidth <=
                                                        e.target.offsetWidth
                                                    ) {
                                                        setTitle &&
                                                            setTitle("");
                                                    }
                                                }}
                                            >
                                                <div className={styles._name}>
                                                    {getTrimmedDomainURL(
                                                        rw.domain,
                                                    )}
                                                </div>
                                            </LightTooltip>
                                        </div>
                                        <div>
                                            {removingGsc != null &&
                                            removingGsc == rw.id ? (
                                                <CircularProgress
                                                    size={18}
                                                    style={{
                                                        marginInline: 10,
                                                    }}
                                                    color="info"
                                                />
                                            ) : (
                                                (rw.gsc_id ||
                                                    rw?.is_verified == 1) && (
                                                    <div
                                                        className={
                                                            styles.connectSource
                                                        }
                                                    >
                                                        {!!rw.gsc_id &&
                                                        !rw.re_auth ? (
                                                            <span
                                                                className={
                                                                    styles.sourceGsc
                                                                }
                                                            >
                                                                <FcGoogle />{" "}
                                                                Connected
                                                            </span>
                                                        ) : (
                                                            <SVGIcon
                                                                src={
                                                                    "/New UI/SVG/check-circle-fill.svg"
                                                                }
                                                                size={14}
                                                                color="inherit"
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.row_doc}>
                                        {rw.status == 3 || rw.status == null ? (
                                            <>
                                                {isAdding
                                                    ? "   Adding..."
                                                    : "    Crawling in Progress..."}
                                            </>
                                        ) : (
                                            <>
                                                {moment(rw.created_at).format(
                                                    "DD MMM, YYYY",
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.row_npages}>
                                        {rw.status == 3 || rw.status == null ? (
                                            <Skeleton width={"40px"} />
                                        ) : (
                                            <>
                                                <span className={styles.totalP}>
                                                    {rw?.stable_version_total_pages ||
                                                        0}
                                                    &nbsp;
                                                </span>
                                                /&nbsp;
                                                <span
                                                    className={styles.crawledP}
                                                >
                                                    {rw?.stable_version_total_crawled_pages ||
                                                        0}
                                                </span>
                                            </>
                                        )}
                                    </div>

                                    <div className={styles.row_cta}>
                                        {rw.status == 4 && (
                                            <>
                                                {!!rw.re_auth ? (
                                                    <div
                                                        className={
                                                            styles.reAuthGsc
                                                        }
                                                        onClick={(e) => {
                                                            lMManageDomainCta({
                                                                ctaType:
                                                                    "Reconnect GSC",
                                                                screen: screen,
                                                                ctaUsed:
                                                                    "Reconnect GSC",
                                                                domainStatus:
                                                                    rw?.is_verified ||
                                                                    "-",
                                                                pCrawled:
                                                                    rw?.stable_version_total_pages,
                                                                pTotal: rw?.stable_version_total_crawled_pages,
                                                            });
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setReAuthBegin(
                                                                true,
                                                            );

                                                            handleGscReAuth(rw);
                                                        }}
                                                    >
                                                        {reAuthBegin ? (
                                                            <CircularProgress
                                                                size={18}
                                                                style={{
                                                                    marginInline: 10,
                                                                }}
                                                                color="info"
                                                            />
                                                        ) : (
                                                            <>
                                                                <FcGoogle />{" "}
                                                                Reconnect GSC
                                                            </>
                                                        )}
                                                    </div>
                                                ) : trialUser ? (
                                                    <div
                                                        className={
                                                            styles.upgradeCta
                                                        }
                                                        onClick={() => {
                                                            setUpgradeModal({
                                                                isShow: true,
                                                                isElite: false,
                                                            });
                                                            // setBuyPagesModal({
                                                            //     isShow: true,
                                                            //     domainId: rw.id,
                                                            // });
                                                            lMManageDomainCta({
                                                                ctaType:
                                                                    "Buy Plan",
                                                                screen: screen,
                                                                ctaUsed:
                                                                    "Buy Plan",
                                                                domainStatus:
                                                                    rw?.is_verified ||
                                                                    "-",
                                                                pCrawled:
                                                                    rw?.stable_version_total_pages,
                                                                pTotal: rw?.stable_version_total_crawled_pages,
                                                            });
                                                        }}
                                                        style={{
                                                            padding: "4px 10px",
                                                        }}
                                                    >
                                                        Buy Plan
                                                    </div>
                                                ) : (
                                                    showManageCta &&
                                                    rw.stable_version_total_pages <
                                                        rw.stable_version_total_crawled_pages && (
                                                        <div
                                                            className={
                                                                styles.manageCta
                                                            }
                                                            onClick={() => {
                                                                lMManageDomainCta(
                                                                    {
                                                                        ctaType:
                                                                            "Buy Pages",
                                                                        screen: screen,
                                                                        ctaUsed:
                                                                            "Buy Pages",
                                                                        domainStatus:
                                                                            rw?.is_verified ||
                                                                            "-",
                                                                        pCrawled:
                                                                            rw?.stable_version_total_pages,
                                                                        pTotal: rw?.stable_version_total_crawled_pages,
                                                                    },
                                                                );
                                                                setBuyPagesModal(
                                                                    {
                                                                        isShow: true,
                                                                        domainId:
                                                                            rw.id,
                                                                    },
                                                                );
                                                                // setModal(false);
                                                            }}
                                                            style={{
                                                                padding:
                                                                    "4px 10px",
                                                                position:
                                                                    "absolute",
                                                                left: "0px",
                                                            }}
                                                        >
                                                            Buy Pages
                                                        </div>
                                                    )
                                                )}

                                                <div
                                                    className={styles.deleteCta}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (
                                                            !isAdding &&
                                                            rw.status >= 4
                                                        ) {
                                                            lMManageDomainCta({
                                                                ctaType:
                                                                    "Delete Domain",
                                                                screen: screen,
                                                                ctaUsed:
                                                                    "Delete",
                                                                domainStatus:
                                                                    rw?.is_verified ||
                                                                    "-",
                                                                pCrawled:
                                                                    rw?.stable_version_total_pages,
                                                                pTotal: rw?.stable_version_total_crawled_pages,
                                                            });
                                                            setRemoveDomain(
                                                                rw.id,
                                                                rw?.gsc_id,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <RiDeleteBin6Line
                                                        color="#FC5A5A"
                                                        size={"15"}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}

                    {arr.length > 0 && !isAdding && false && (
                        <div
                            className={`${styles.rowWrapper} ${styles.dummyAddRow}`}
                        ></div>
                    )}
                </div>
            </div>

            {upgradeModal?.isShow && (
                <UpgradeModal
                    setModal={setUpgradeModal}
                    forMulti={upgradeModal?.isElite}
                    screen={"Manage Domains"}
                />
            )}
            {isDomainsModal && (
                <Modal
                    setModal={() => {
                        setIsDomainsModal(false);
                    }}
                >
                    <div className={styles.domainsListWrapper}>
                        <div className={styles.listWrapper}>
                            {gscDomains.length ? (
                                <SelectList
                                    selectedDomain={selectedDomain}
                                    setSelectedDomain={setSelectedDomain}
                                    label={"Select Domain"}
                                    otherDomains={gscDomains}
                                    setDomainName={setDomainName}
                                />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100px",
                                        textAlign: "center",
                                    }}
                                >
                                    No Matched Domain Found in GSC for{" "}
                                    {userGscInfo.email}
                                </div>
                            )}
                        </div>
                        {gscDomains.length ? (
                            <Button
                                text={
                                    isAddingGsc ? "Update GSC" : "Get Started"
                                }
                                width={200}
                                style={{ margin: "auto" }}
                                handler={() => {
                                    setIsLoading(true);
                                    if (isAddingGsc) {
                                        onChange({
                                            type: DomainChangeCases.addGsc,
                                            gscId: userGscInfo.gsc_id,
                                            dName: selectedDomain,
                                            id: domainForGscUpdate.id,
                                        });
                                    } else {
                                        onChange({
                                            type: DomainChangeCases.addDomain,
                                            gscId: userGscInfo?.gsc_id,
                                            dName: selectedDomain,
                                            source: "gsc",
                                        });
                                    }
                                    setTimeout(() => {
                                        setIsDomainsModal(false);
                                    }, 1000);
                                }}
                                loading={isLoading}
                            />
                        ) : (
                            <Button
                                handler={() => {
                                    setIsDomainsModal(false);
                                }}
                                text={"Close"}
                                width={200}
                                style={{
                                    margin: "auto",
                                    backgroundColor: "transparent",
                                    textDecoration: "underline",
                                    color: "#6b6b6b",
                                }}
                            />
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ManageDomains;
