import React from "react";
import styles from "./transactions.module.css";
import { transactionData } from "./TransactionData";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { thousands_separators } from "../../../../utility/validateFunctions";
import { openProject } from "../../../../store/actions/dashboard";
import SVGIcon from "../../../../componentsV2/atoms/SVGIcon";

function renderSubtext(transaction, dispatch) {
    switch (transaction.software_tool_type) {
        case 1:
            return (
                transaction.request_id && (
                    <span
                        onClick={() => {
                            dispatch(
                                openProject({
                                    openProject: true,
                                    projectId: transaction.request_id,
                                }),
                            );
                        }}
                    >
                        #{transaction.request_id}
                    </span>
                )
            );

        default:
            return (
                transaction.report_or_word_count && (
                    <div>
                        {`${transaction.report_or_word_count} ${
                            transaction.software_tool_type == 6
                                ? "Cluster Report"
                                : transaction.software_tool_type == 2 ||
                                  transaction.software_tool_type == 7
                                ? "Report"
                                : "Word"
                        }${transaction.report_or_word_count > 1 ? "s" : ""}`}
                    </div>
                )
            );
    }
}

export function getTransactionData(type, toolType) {
    let searchedData = { type };

    transactionData.map((data) => {
        if (data.type === type) {
            searchedData = data;
        }
        if (toolType == 3) {
            if (type == "deduction")
                searchedData.icon = (
                    <SVGIcon
                        size={30}
                        color="#38e5e1"
                        src="/New UI/SVG/longform-deduction.svg"
                    />
                );
            else if (type == "monthly quota")
                searchedData.icon = (
                    <SVGIcon
                        size={30}
                        color="#38e5e1"
                        src="/New UI/SVG/longform-ai-monthly-quota-icon.svg"
                    />
                );
            searchedData.color = "#38e5e1";
            searchedData.pillColor = "#38e5e11c";
        } else if (toolType == 4) {
            if (type == "deduction")
                searchedData.icon = (
                    <SVGIcon
                        size={30}
                        color="#bf85e1"
                        src="/New UI/SVG/shortform-deduction.svg"
                    />
                );
            else if (type == "monthly quota")
                searchedData.icon = (
                    <SVGIcon
                        size={30}
                        color="#bf85e1"
                        src="/New UI/SVG/shortform-monthly-quota-icon.svg"
                    />
                );
            searchedData.color = "#bf85e1";
            searchedData.pillColor = "#bf85e11c";
        } else if (toolType == 5) {
            if (type == "deduction")
                searchedData.icon = (
                    <SVGIcon
                        size={30}
                        color="#014dc5"
                        src="/New UI/SVG/shortform-deduction.svg"
                    />
                );
            else if (type == "monthly quota")
                searchedData.icon = (
                    <SVGIcon
                        size={30}
                        color="#014dc5"
                        src="/New UI/SVG/shortform-monthly-quota-icon.svg"
                    />
                );
            searchedData.color = "#014dc5";
            searchedData.pillColor = "#014dc51c";
        }
    });

    return searchedData;
}

export function addSign(amount, used, type, currency) {
    if (type == 1) {
        return (
            <span
                style={{
                    fontFamily: "Inter",
                    color: "#3dd598",
                    display: "block",
                }}
            >
                + {currency}
                {amount}
            </span>
        );
    }
    if (type == 0) {
        return (
            <span
                style={{
                    fontFamily: "Inter",
                    color: "black",
                    display: "block",
                }}
            >
                - {currency}
                {amount}
            </span>
        );
    }
    if (type == 3) {
        if (amount < 0)
            return (
                <span
                    style={{
                        fontFamily: "Inter",
                        color: "black",
                        display: "block",
                    }}
                >
                    - {currency}
                    {-amount}
                </span>
            );
        else
            return (
                <span
                    style={{
                        fontFamily: "Inter",
                        color: "#3dd598",
                        display: "block",
                    }}
                >
                    + {currency}
                    {amount}
                </span>
            );
    }

    return (
        <span style={{ fontFamily: "Inter", color: "black", display: "block" }}>
            {" "}
            {currency}
            {amount}
        </span>
    );
}

export function truncateFun(str) {
    if (str.length > 20) {
        return str.substring(0, 20) + "...";
    } else {
        return str;
    }
}

export function getType(reqType, software_tool_type) {
    if (reqType === 0 && software_tool_type == 1) {
        return "Request";
    } else if (reqType === 1) {
        return "Recharge";
    } else if (reqType === 3) {
        return "Adjustment";
    } else if (reqType === 4) {
        return "Upgrade";
    } else if (reqType == 5) {
        return "Failed";
    } else if (reqType == 0) {
        return "deduction";
    } else if (reqType == 6) {
        return "monthly quota";
    } else if (reqType == 7) {
        return "credited";
    }

    return "invalid";
}

const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

const timeOptions = {
    hour: "numeric",
    minute: "2-digit",
};

export function getCurrency(role, userCurrency, currency, userId) {
    if (role === "admin" || role === "account_manager") {
        if (userId) {
            if (userCurrency == "USD") {
                return "$";
            } else return "₹";
        }
    }

    if (currency == "USD") {
        return "$";
    } else return "₹";
}

export default function TransactionListItem({
    transaction,
    userCurrency,
    userId,
    type,
    transactionTypes,
}) {
    const rowData = getTransactionData(
        getType(transaction.request_type, transaction.software_tool_type),
        transaction.software_tool_type,
    );
    const history = useHistory();
    const date = new Date(transaction.created_at);
    const currency = useSelector((state) => state.auth.currency);
    const role = useSelector((state) => state.auth.role);
    const dispatch = useDispatch();

    return (
        <tr className={styles.tableRow}>
            <td>
                <div
                    className={styles.rowIcon}
                    style={{ backgroundColor: rowData.color }}
                >
                    {rowData.icon}
                </div>
            </td>
            <td className={styles.toBeLeftAligned}>
                <div className={styles.rowTitle}>
                    <p style={{ textTransform: "capitalize" }}>
                        {transaction.message &&
                        transaction.software_tool_type == 1
                            ? truncateFun(transaction.message)
                            : transactionTypes[
                                  transaction.software_tool_type
                              ] || "No Message"}
                    </p>
                    {renderSubtext(transaction, dispatch)}
                </div>
            </td>
            <td className={styles.containerRowType}>
                <div
                    className={styles.rowType}
                    style={{ backgroundColor: rowData.pillColor }}
                >
                    <span style={{ color: rowData.color }}>{rowData.type}</span>
                </div>
            </td>
            <td className={styles.rowDate}>
                {date.toLocaleDateString("en-US", dateOptions)}

                {transaction.software_tool_type == 1 &&
                    ". " + date.toLocaleTimeString("en-US", timeOptions)}
            </td>
            <td className={styles.rowCost}>
                {addSign(
                    transaction.amount_payed,
                    transaction.used_balance,
                    transaction.request_type,
                    getCurrency(role, userCurrency, currency, userId),
                )}
                <span className={styles.closingBalance}>
                    Closing balance:{" "}
                    {transaction.user_credits === null ? (
                        "---"
                    ) : (
                        <>
                            <span style={{ fontFamily: "Inter" }}>
                                {transaction.user_credits
                                    ? transaction.user_credits >= 0
                                        ? `${getCurrency(
                                              role,
                                              userCurrency,
                                              currency,
                                              userId,
                                          )}${thousands_separators(
                                              +transaction.user_credits,
                                          )}`
                                        : `- ${getCurrency(
                                              role,
                                              userCurrency,
                                              currency,
                                              userId,
                                          )}${thousands_separators(
                                              +Math.abs(
                                                  transaction.user_credits,
                                              ),
                                          )}`
                                    : `${getCurrency(
                                          role,
                                          userCurrency,
                                          currency,
                                          userId,
                                      )} 0`}
                            </span>
                        </>
                    )}
                </span>
            </td>
        </tr>
    );
}
