import React, { useEffect } from "react";
import { VscClose } from "react-icons/vsc";
import styles from "./VersionList.module.css";
import VersionList from "./VersionList";

function VersionContainer({
  setVersionModal,

  versionModal,
}) {
  return (
    <div
      className={
        styles.versionModalContainer +
        " " +
        (!versionModal ? styles.versionModalContainerHide : "")
      }
    >
      <div className={styles.heading}>
        <h2> Version History </h2>

        <VscClose
          onClick={() => setVersionModal(false)}
          className={styles.closeIcon}
        />
      </div>

      <div className={styles.listContainer}>
        <VersionList />
      </div>
    </div>
  );
}

export default VersionContainer;
