import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import styles from "./morekeywords.module.css";

export const _Tooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "transparent",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: "none",
        fontSize: 11,
        maxWidth: "none",
        position: "relative",
        top: "5px",
        padding: 0,
    },
    arrow: { color: "white" },
}))(Tooltip);

const Morekeywords = ({
    keywords = [],
    title = "",
    isEditor = false,
    countryName = "",
    countryCode = "",
    placement = "bottom",
    moreCount = 0,
}) => {
    return (
        <_Tooltip
            style={{ background: "transparent" }}
            interactive
            title={
                isEditor ? (
                    <div className={styles.keywordsConainer}>
                        <p className={styles.keywordHeading}>Primary Keyword</p>
                        <div
                            className={styles.keyword}
                            // style={{ marginBottom: "8px" }}
                        >
                            {title}
                        </div>
                        <p className={styles.keywordHeading}>
                            Secondary Keywords
                        </p>
                        <div className={styles.secondaryKeywords}>
                            {Array.isArray(keywords)
                                ? keywords
                                : JSON.parse(keywords).map((key) => (
                                      <div className={styles.keyword}>
                                          {key}
                                      </div>
                                  ))}
                        </div>
                        <p
                            className={styles.keywordHeading}
                            // style={{ marginTop: "8px" }}
                        >
                            Location
                        </p>
                        <div
                            className={`${styles.secondaryKeywords} ${styles.locationContainer}`}
                        >
                            <img
                                loading="lazy"
                                width="18px"
                                height="12px"
                                style={{ marginRight: "16px" }}
                                src={`https://flagcdn.com/w20/${
                                    countryCode?.toLowerCase() === "uk"
                                        ? "gb"
                                        : countryCode?.toLowerCase()
                                }.png`}
                                srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {countryName}
                        </div>
                    </div>
                ) : (
                    <div className={styles.keywordsConainer}>
                        <p>
                            Keywords(
                            {(Array.isArray(keywords)
                                ? keywords
                                : JSON.parse(keywords)
                            ).length + (title ? 1 : 0)}
                            )
                        </p>
                        <hr />
                        <ul>
                            {title && (
                                <li>
                                    <strong>{title}</strong>
                                </li>
                            )}
                            {(Array.isArray(keywords)
                                ? keywords
                                : JSON.parse(keywords)
                            ).map((key) => (
                                <li>{key}</li>
                            ))}
                        </ul>
                    </div>
                )
            }
            arrow
            placement={placement}
        >
            <span className={styles.moreCount}>
                {" "}
                (+
                {moreCount || 0} more)
            </span>
        </_Tooltip>
    );
};

export default Morekeywords;
