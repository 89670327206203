import React, { useState } from "react";
import axios from "axios";
import { ConfirmationNumber } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import styles from "./styles.module.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { updateProjects } from "../../../../../../store/actions/dashboard";
import { url } from "../../../../../../utility/config";
import Button from "../../../../../../componentsV2/atoms/Button/Button";
import {
  PROJECT_DESCRIPTION,
  BRAND_PROFILE,
  GET_HISTORY,
} from "../../../../../../componentsV2/api.json";
import useHelperFunctions from "../../../../../../componentsV2/helperFunctions";

function Confirmation({
  setStatus,
  submit,
  setDialog,
  userID,
  projectID,
  setStatusName,
  confirmationAction,
  activeTab,
  checkedFiles,
  updateFreelancerStatus,
  role,
  setData,
}) {
  const [message, setMessage] = useState("");
  const token = useSelector((state) => state.auth.token);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { getData } = useHelperFunctions();

  function updateProjectInfo() {
    setData(null);
    (async () => {
      const response = await getData({ url: PROJECT_DESCRIPTION + projectID });

      if (response?.status == 200) {
        setData(response?.data?.data);
      }
    })();
  }

  function confirm() {
    setLoader(true);

    axios({
      method: "put",
      url: `${url}/api/project/updateStatusOnRevision`,
      data: {
        projectId: projectID,
        status: "ACCEPTED",
      },
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        updateProjectInfo();
        setDialog(false);
        dispatch(
          updateProjects({
            id: projectID,
            data: { status: "ACCEPTED" },
          })
        );
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  function sendRequest() {
    setLoader(true);
    axios({
      method: "post",
      url: `${url}/api/project/requestRevision`,
      data: {
        userID: userID,
        projectID: projectID,
        message: message,
      },
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        updateProjectInfo();
        setDialog(false);
        dispatch(
          updateProjects({
            id: projectID,
            data: { status: "REVISION REQUESTED" },
          })
        );

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  function pushToOtherSide() {
    setLoader(true);
    if (activeTab === "client") {
      let dataToPushToFreelancer = {
        project_id: projectID,
        client_attachments: checkedFiles,
      };
      axios({
        method: "post",
        url: `${url}/api/freeLancer/update`,
        headers: {
          Authorization: token,
        },
        data: dataToPushToFreelancer,
      })
        .then((res) => {
          setDialog(false);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    } else if (activeTab === "freelancer") {
      let dataToPushToClient = {
        project_id: projectID,
        freelancer_attachments: checkedFiles,
      };
      axios({
        method: "post",
        url: `${url}/api/freeLancer/update`,
        headers: {
          Authorization: token,
        },
        data: dataToPushToClient,
      })
        .then((res) => {
          setDialog(false);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }

  function pullback() {
    setLoader(true);
    updateFreelancerStatus(7, setLoader).then((res) => {
      setDialog(false);
    });
  }

  function closeDialog() {
    setDialog(false);
  }

  function getAction() {
    if (confirmationAction === "push") {
      return pushToOtherSide(null, setLoader);
    } else if (confirmationAction === "accept") {
      return confirm(null, setLoader);
    } else if (confirmationAction === "pullback") {
      return updateFreelancerStatus(7, setLoader);
    } else if (confirmationAction === "revision") {
      return updateFreelancerStatus(4, setLoader, message);
    } else if (confirmationAction === "userRevision") {
      return sendRequest();
    }
  }

  function getModalText() {
    if (confirmationAction === "push") {
      if (activeTab === "freelancer") {
        return "Are you sure you want to push file(s) to client?";
      } else return "Are you sure you want to push file(s) to freelancer?";
    } else if (confirmationAction === "accept") {
      return "Please click on confirm to accept";
    } else if (confirmationAction === "revision") {
      return "Please enter your query and submit";
    } else if (confirmationAction === "pullback") {
      return "Are you sure you want to pullback?";
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.dialog}>
        {submit ? (
          <div className={styles.textArea}>
            <div className={styles.message}>
              Please enter your query and submit
            </div>
            <TextareaAutosize
              onChange={(e) => setMessage(e.target.value)}
              aria-label="minimum height"
              rowsMin={5}
              placeholder="Type here...."
              autoFocus={true}
            />
          </div>
        ) : (
          <div className={styles.message}>{getModalText()}</div>
        )}

        <div className={styles.buttons}>
          <Button
            fontSize="12px"
            text="Cancel"
            handler={closeDialog}
            secondary
          />

          {submit ? (
            <Button
              fontSize="12px"
              handler={() => getAction()}
              text="Submit"
              loading={loader}
            />
          ) : (
            <Button
              handler={() => getAction()}
              fontSize="12px"
              text="Confirm"
              loading={loader}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
