import { max } from "moment";
import { Bar } from "react-chartjs-2";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ScriptableContext,
} from "chart.js";
import { useEffect } from "react";
import useGscHook from "../../Pages/GoogleSearchConsole/useGscHook";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);

function BarChartHorizontal({
    data,
    color,
    label,
    isHorizontal = false,
    min = 0,
    max = 100,
    valueType = "number",
    barThickness = 45,
}: {
    data?: number[] | string[];
    color?: string;
    label?: string[] | number[];
    isHorizontal?: boolean;
    units?: number[] | string[];
    min?: number;
    max?: number;
    barThickness?: number;
    valueType?: string;
}) {
    var options = {};
    const { getValueWithUnit } = useGscHook();

    useEffect(() => {}, [valueType]);

    const barData = {
        labels: !!label?.length ? [...label] : [],
        datasets: [
            {
                label: "%",
                data: !!data?.length ? [...data] : [],
                borderColor: "#10CFFC",
                backgroundColor: "#10CFFC",
                borderWidth: 0,
                borderRadius: 4,
            },
        ],
    };

    const getOrCreateTooltip = (chart: any) => {
        let tooltipEl = chart.canvas.parentNode.querySelector("div");

        if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.style.background = "#fff";
            tooltipEl.style.borderRadius = "18px";
            tooltipEl.style.color = "black";
            tooltipEl.style.boxShadow = "1px 1px 6px rgba(0, 0, 0, 0.25)";

            tooltipEl.style.opacity = 1;
            tooltipEl.style.width = "max-content";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.transform = "translate(-50%, 0)";
            tooltipEl.style.transition = "all .1s ease";

            const table = document.createElement("table");
            table.style.margin = "0px";

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context: any) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b: any) => b.lines);
            const index = tooltip._tooltipItems.map((a: any) => a.parsed.x + 1);
            const raw = tooltip._tooltipItems[0].raw;
            console.log(index);
            const tooltipDiv = document.createElement("div");

            titleLines.forEach((title: string, i: number) => {
                const itemRow = document.createElement("div");
                itemRow.style.borderWidth = "0";
                itemRow.style.display = "flex";
                itemRow.style.justifyContent = "center";
                itemRow.style.alignItems = "center";
                itemRow.style.gap = "6px";
                itemRow.style.padding = "2px 6px";

                const meta = document.createElement("div");
                meta.style.borderWidth = "0";
                meta.style.color = "#3F5575";
                meta.style.fontWeight = "600";
                meta.style.fontSize = "12px";
                if (valueType == "percentage") {
                    meta.innerText = title;
                } else {
                    meta.innerText = "";
                }
                const value = document.createElement("div");
                value.style.borderWidth = "0";
                value.style.color = "#014DC5";
                value.style.fontWeight = "600";
                value.style.fontSize = "12px";
                if (valueType == "percentage") {
                    value.innerText = (
                        Math.round((raw / 10) * 100) / 10 +
                        "%"
                    ).toString();
                } else {
                    value.innerText = raw;
                }

                if (valueType == "percentage") {
                    itemRow.appendChild(meta);
                } else {
                    //
                }
                itemRow.appendChild(value);
                tooltipDiv.appendChild(itemRow);
            });

            // const tableBody = document.createElement("tbody");
            // bodyLines.forEach((body: string, i: number) => {
            //     console.log("body", body);
            //     console.log("i", i);
            //     const colors = tooltip.labelColors[i];

            //     const tr = document.createElement("tr");
            //     tr.style.backgroundColor = "#fff";
            //     tr.style.borderWidth = "0";

            //     const td = document.createElement("td");
            //     td.style.borderWidth = "0";

            //     const text = document.createTextNode(body);
            //     td.appendChild(text);
            //     tr.appendChild(td);
            //     tableBody.appendChild(tr);
            // });

            const tableRoot = tooltipEl.querySelector("table");

            // Remove old children
            while (tableRoot?.firstChild) {
                tableRoot?.firstChild?.remove();
            }

            // Add new children
            tableRoot?.appendChild(tooltipDiv);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        let left = positionX + tooltip.caretX;
        let top = positionY + (tooltip.caretY - 40);
        console.log("toooooltop", left);
        if (valueType == "percentage") {
            tooltipEl.offsetWidth;
            if (tooltipEl.offsetWidth > 100) {
                left = 200;
            }
        }

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = left + "px";
        tooltipEl.style.top = top + "px";
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.fontSize = "10px";
        tooltipEl.style.padding =
            tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    return (
        <Bar
            data={barData}
            width={"400px"}
            height={"290px"}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                ...(isHorizontal ? { indexAxis: "y" as const } : {}),
                elements: {
                    bar: {
                        borderWidth: 5,
                    },
                },
                scales: {
                    // @ts-ignore
                    xAxis: {
                        min,
                        max,
                        ...(isHorizontal && {
                            ticks: {
                                callback: function (context) {
                                    return context + "%";
                                },
                                color: "#3F5575",
                                font: {
                                    size: 12,
                                    weight: 400,
                                },
                            },
                        }),
                        grid: {
                            drawBorder: false, // Remove the x-axis line
                        },
                        ...(!isHorizontal && {
                            ticks: {
                                color: "#ACB3BF",
                            },
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                        }),
                    },
                    yAxis: {
                        beginAtZero: true,
                        ...(!isHorizontal && {
                            ticks: {
                                //@ts-ignore
                                min: 0,
                                color: "#ACB3BF",
                                stepSize:
                                    max < 99 ? 10 : max < 999 ? 100 : 1000,
                                callback: function (value) {
                                    return `${getValueWithUnit(value)}`;
                                },
                            },
                        }),
                        grid: {
                            drawBorder: false, // Remove the x-axis line
                        },
                        ...(isHorizontal && {
                            grid: {
                                display: false,
                                drawBorder: false, // Remove the x-axis line
                                drawOnChartArea: false,
                            },
                        }),
                        ...(isHorizontal && {
                            ticks: {
                                color: "#3F5575",
                                crossAlign: "far",
                                font: {
                                    size: 13,
                                    weight: 400,
                                    textAlign: "left",
                                },
                                callback: function (context, index) {
                                    if (label?.length) {
                                        if (
                                            label[index].toString().length > 16
                                        ) {
                                            return (
                                                label[index]
                                                    .toString()
                                                    ?.substring(0, 16) + "..."
                                            );
                                        } else {
                                            return label[index];
                                        }
                                    }
                                },
                            },
                        }),
                    },
                },
                datasets: {
                    bar: {
                        barThickness: barThickness, // Change this value to adjust the thickness
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        position: "nearest",
                        external: externalTooltipHandler,
                    },
                    title: {
                        display: false,
                        text: "",
                    },
                },
            }}
        />
    );
}

export default BarChartHorizontal;
