import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { PLANS_CATEGORIES } from "../../constants";
import { parsePrice } from "../../componentsV2/templates/Dashboard/usePriceDiscounts";
import { useSelector } from "react-redux";
import useInViewPort from "../../componentsV2/CommonHooks/useInViewport";
import useMixpanelHook from "../../utility/useMixpanelHook";
const headerData = [
    "Features",
    // "Essential",
    "Growth",
    "Growth Max",
    "Pro",
    "Pro Max",
];

const featuresData = [
    { feature: "Humanized SEO Articles", values: ["✔", "✔", "✔", "✔"] },
    { feature: "AI images", values: ["✔", "✔", "✔", "✔"] },
    { feature: "NLP key terms", values: ["✔", "✔", "✔", "✔"] },
    { feature: "Factual Content", values: ["✔", "✔", "✔", "✔"] },
    { feature: "Auto-optimization", values: ["✔", "✔", "✔", "✔"] },
    { feature: "Topic gaps", values: ["✔", "✔", "✔", "✔"] },
    { feature: "New topic ideas", values: ["✔", "✔", "✔", "✔"] },
    { feature: "Tone of voice", values: ["✔", "✔", "✔", "✔"] },
    { feature: "Integrations", values: ["✔", "✔", "✔", "✔"] },
    { feature: "Internal linking", values: ["×", "×", "✔", "✔"] },
    { feature: "Cannibalization", values: ["×", "×", "✔", "✔"] },
    { feature: "Advanced keyword planner", values: ["×", "×", "✔", "✔"] },
];

const options = {
    root: null, // Use the viewport as the root
    rootMargin: "0px", // No margin around the root
    threshold: 0.2, // Trigger when 50% of the element is visible
};

function CompareTable({ cards }) {
    const [plansData, setPlansData] = useState(cards);
    const currency = useSelector((state) => state.auth.currency);
    const currencySymbol = currency == "INR" ? "₹" : "$";
    const compareTableRef = useRef(null);
    const { mixpanelTrack } = useMixpanelHook();

    const isCompareTableVisible = useInViewPort(compareTableRef, options);

    useEffect(() => {
        if (isCompareTableVisible) {
            mixpanelTrack("Subscription Page", {
                action: "Compare Table Visible(Max Plans)",
            });
        }
    }, [isCompareTableVisible]);

    const getPlansList = (planType) => {
        return cards
            .sort((card1, card2) => card1.plan_cost - card2.plan_cost)
            .filter(
                (card) =>
                    (planType === card.plan_type &&
                        card.plan_category != PLANS_CATEGORIES.INTER_LINKING &&
                        card.plan_category != PLANS_CATEGORIES.HUMANIZER) ||
                    card.plan_category == PLANS_CATEGORIES.FREE_FOREVER,
            );
    };
    useEffect(() => {
        setPlansData(getPlansList(2));
    }, [cards]);
    console.log(plansData, "ooooooo");
    const pricingData = [
        {
            feature: "Price",
            values: [
                `<div><r>-50%</r> <s>${parsePrice(
                    plansData[1]?.plan_cost,
                    currency,
                    2,
                )}/mo</s> <br />Price: ${parsePrice(
                    plansData[1]?.plan_cost,
                    currency,
                    2,
                    50,
                )}/mo</div>`,
                `<div><r>-60%</r> <s>${parsePrice(
                    plansData[2]?.plan_cost,
                    currency,
                    2,
                )}/mo</s> <br />Price: ${parsePrice(
                    plansData[2]?.plan_cost,
                    currency,
                    2,
                    60,
                )}/mo</div>`,
                `<div><r>-50%</r> <s>${parsePrice(
                    plansData[3]?.plan_cost,
                    currency,
                    2,
                )}/mo</s> <br />Price: ${parsePrice(
                    plansData[3]?.plan_cost,
                    currency,
                    2,
                    50,
                )}/mo</div>`,
                `<div><r>-60%</r> <s>${parsePrice(
                    plansData[4]?.plan_cost,
                    currency,
                    2,
                )}/mo</s> <br />Price: ${parsePrice(
                    plansData[4]?.plan_cost,
                    currency,
                    2,
                    60,
                )}/mo</div>`,
            ],
        },
        {
            feature: "Create Humanized SEO Articles",
            values: ["30", "90", "75", "225"],
        },
        {
            feature: "Audit & Optimize Pages",
            values: ["30", "90", "75", "225"],
        },
        {
            feature: "Build Keyword Clusters",
            values: ["30", "90", "75", "225"],
        },
        {
            feature: "Analyze Web Pages",
            values: ["200", "600", "500", "1500"],
        },
        { feature: "User Seats", values: ["×", "×", "1", "3"] },
    ];

    const linkManagerOriginalPrice = parseFloat(
        currency == "INR" ? 19 * 85 : 19,
    ).toFixed(0);
    const humanizerOriginalPrice = parseFloat(
        currency == "INR" ? 9.99 * 85 : 9.99,
    ).toFixed(0);

    const addonsData = [
        {
            feature: "AI Link Manager",
            values: [
                <div>
                    {currencySymbol}
                    {parseFloat(currency == "INR" ? 9.5 * 85 : 9.5).toFixed(0)}
                    /mo
                    <br />
                    <div style={{ fontSize: "12px" }}>
                        (Billed annually or {currencySymbol}
                        {linkManagerOriginalPrice}/mo)
                    </div>
                </div>,
                "Free",
                <div>
                    {currencySymbol}
                    {parseFloat(currency == "INR" ? 9.5 * 85 : 9.5).toFixed(0)}
                    /mo
                    <br />
                    <div style={{ fontSize: "12px" }}>
                        (Billed annually or {currencySymbol}
                        {linkManagerOriginalPrice}/mo)
                    </div>
                </div>,
                "Free",
            ],
        },
        {
            feature: "AI Detector & AI Humanizer",
            values: [
                <div>
                    {currencySymbol}
                    {parseFloat(currency == "INR" ? 4.99 * 85 : 4.99).toFixed(
                        0,
                    )}
                    /mo
                    <br />
                    <div style={{ fontSize: "12px" }}>
                        (Billed annually or {currencySymbol}
                        {humanizerOriginalPrice}/mo)
                    </div>
                </div>,
                "Free",
                <div>
                    {currencySymbol}
                    {parseFloat(currency == "INR" ? 4.99 * 85 : 4.99).toFixed(
                        0,
                    )}
                    /mo
                    <br />
                    <div style={{ fontSize: "12px" }}>
                        (Billed annually or {currencySymbol}
                        {humanizerOriginalPrice}/mo)
                    </div>
                </div>,
                "Free",
            ],
        },
    ];
    return (
        <div ref={compareTableRef} className={styles.compareTableWrapper}>
            <div className={`${styles.row} ${styles.header}`}>
                {headerData.map((item, index) => (
                    <div
                        style={
                            index == 2 || index == 4
                                ? {
                                      background:
                                          "linear-gradient(79.76deg, #010101 -0.1%, #463924 99.8%)",
                                      color: "#FFD644",
                                  }
                                : {}
                        }
                        className={`${styles.rowItem} ${styles.headRow}`}
                        key={index}
                    >
                        {item}
                    </div>
                ))}
            </div>

            {pricingData.map((row, rowIndex) => (
                <div className={styles.row} key={rowIndex}>
                    <div className={`${styles.rowItem} ${styles.firstItem}`}>
                        {row.feature}
                    </div>
                    {row.values.map((value, valueIndex) => (
                        <div
                            className={`${styles.rowItem} ${
                                valueIndex == 1 || valueIndex == 3
                                    ? styles.maxRowItem
                                    : ""
                            }`}
                            key={valueIndex}
                            dangerouslySetInnerHTML={{ __html: value }}
                        ></div>
                    ))}
                </div>
            ))}

            <div className={`${styles.row} ${styles.header}`}>
                <div className={`${styles.rowItem} ${styles.firstItem}`}>
                    Add-ons
                </div>
                {headerData.slice(1).map((item, index) => (
                    <div
                        className={`${styles.rowItem} ${
                            index == 1 || index == 3 ? styles.maxRowItem : ""
                        }`}
                        key={index}
                    ></div>
                ))}
            </div>

            {addonsData.map((row, rowIndex) => (
                <div className={styles.row} key={rowIndex}>
                    <div className={`${styles.rowItem} ${styles.firstItem}`}>
                        {row.feature}
                    </div>
                    {row.values.map((value, valueIndex) => (
                        <div
                            className={`${styles.rowItem} ${
                                valueIndex == 1 || valueIndex == 3
                                    ? styles.maxRowItem
                                    : ""
                            }`}
                            key={valueIndex}
                        >
                            {value}
                        </div>
                    ))}
                </div>
            ))}

            <div className={`${styles.row} ${styles.header}`}>
                <div className={`${styles.rowItem} ${styles.firstItem}`}>
                    Features
                </div>
                {headerData.slice(1).map((item, index) => (
                    <div
                        className={`${styles.rowItem} ${
                            index == 1 || index == 3 ? styles.maxRowItem : ""
                        }`}
                        key={index}
                    ></div>
                ))}
            </div>

            {featuresData.map((row, rowIndex) => (
                <div className={styles.row} key={rowIndex}>
                    <div className={`${styles.rowItem} ${styles.firstItem}`}>
                        {row.feature}
                    </div>
                    {row.values.map((value, valueIndex) => (
                        <div
                            className={`${styles.rowItem} ${
                                valueIndex == 1 || valueIndex == 3
                                    ? styles.maxRowItem
                                    : ""
                            }`}
                            key={valueIndex}
                        >
                            {value}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default CompareTable;
