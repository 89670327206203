import React from "react";
import { useState } from "react";
import { version as APP_VERSION } from "../../../package.json";
import build_number from "../../version.json";
import Button from "../../componentsV2/atoms/Button/Button";
import Confirmation from "../../componentsV2/atoms/ConfirmationDialog/confirmation";
import styles from "./platformUpdate.module.css";

import { useSelector } from "react-redux";
import { isAppVersionOutdated } from "../../utility/helperFun";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { SoftUpdateModal } from "../../componentsV2/organisms/PlatformUpdate/UpdatePlaform";
import { useEffect } from "react";
import { Close } from "@material-ui/icons";

function PlatformUpdates({ setActiveAction }) {
    const [_version, setVersion] = useState(APP_VERSION);

    const [major, minor, revision] = _version.split(".");

    const [previewed, setPreviewed] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);

    const [preview, setPreview] = useState(false);

    const [description, setDescription] = useState("<h1></h1>");
    const [updateType, setUpdateType] = useState("BAR");

    const { latest_version, update_type, minimum_version } = useSelector(
        (state) => state.appConfig,
    );

    const [curr_major, curr_minor, curr_revision] = latest_version.split(".");

    const [curr_major_min, curr_minor_min, curr_revision_min] =
        minimum_version.split(".");

    useEffect(() => {
        setPreviewed(false);
    }, [description, _version, updateType]);
    useEffect(() => {
        if (preview) {
            setPreviewed(true);
        }
    }, [preview]);

    useEffect(() => {
        if (updateType == "HARD") {
            setVersion(minimum_version);
        } else {
            setVersion(APP_VERSION);
        }
    }, [updateType]);

    const { postData } = useHelperFunctions();

    const changeVersion = (e) => {
        switch (e.target.name) {
            case "major":
                setVersion(`${e.target.value}.${minor}.${revision}`);
                break;
            case "minor":
                setVersion(`${major}.${e.target.value}.${revision}`);
                break;

            case "revision":
                setVersion(`${major}.${minor}.${e.target.value}`);
                break;
        }
    };

    const postLatestVersionData = async () => {
        if (updateType == "HARD") {
            const res = await postData({
                url: "/api/superAdmin/updateConstant",
                payload: {
                    name: "minimum_version",
                    value: _version,
                },
            });

            if (res.status == 200) {
                setConfirmPopup(false);
            }
            return;
        }

        let res1, res2, res3;
        res1 = await postData({
            url: "/api/superAdmin/updateConstant",
            payload: {
                name: "update_type",
                value: updateType,
            },
        });

        if (res1.status == 200)
            res2 = await postData({
                url: "/api/superAdmin/updateConstant",
                payload: {
                    name: "update_description",
                    value: description,
                },
            });
        if (res2.status == 200)
            res3 = await postData({
                url: "/api/superAdmin/updateConstant",
                payload: {
                    name: "latest_version",
                    value: _version,
                },
            });

        if (res1.status == 200 && res2.status == 200 && res3.status == 200) {
            setConfirmPopup(false);
        }
    };

    return (
        <div className={styles.section}>
            {isAppVersionOutdated({
                currVersion: APP_VERSION,
                minVersion: latest_version,
            }) && (
                <Modal setModal={() => {}}>
                    <div className={styles.sectionError}>
                        <h1 style={{ color: "red" }}>
                            Please update the application first.
                        </h1>
                    </div>
                </Modal>
            )}

            <h1>Set Platform Version</h1>
            <span
                onClick={() => setActiveAction(null)}
                className={styles.sectionCross}
            >
                <Close />
            </span>
            {confirmPopup && (
                <Confirmation
                    handler={() => {
                        postLatestVersionData();
                    }}
                    setDialog={setConfirmPopup}
                    modalHeading={
                        updateType == "HARD" ? (
                            <>
                                Are you sure? <br /> <br /> You want to set
                                minimum version from <b>{minimum_version}</b> to{" "}
                                <b>
                                    {
                                        <MarkChangedVersion
                                            curr={curr_major_min}
                                            latest={major}
                                        />
                                    }
                                    .
                                    {
                                        <MarkChangedVersion
                                            curr={curr_minor_min}
                                            latest={minor}
                                        />
                                    }
                                    .
                                    {
                                        <MarkChangedVersion
                                            latest={revision}
                                            curr={curr_revision_min}
                                        />
                                    }
                                </b>
                                <br />
                                <br />
                                <div>
                                    Update Type:{" "}
                                    <b>
                                        {
                                            <MarkChangedVersion
                                                curr={update_type}
                                                latest={updateType}
                                            />
                                        }
                                    </b>
                                </div>
                            </>
                        ) : (
                            <>
                                Are you sure? <br /> <br /> You want to set
                                latest version from <b>{latest_version}</b> to{" "}
                                <b>
                                    {
                                        <MarkChangedVersion
                                            curr={curr_major}
                                            latest={major}
                                        />
                                    }
                                    .
                                    {
                                        <MarkChangedVersion
                                            curr={curr_minor}
                                            latest={minor}
                                        />
                                    }
                                    .
                                    {
                                        <MarkChangedVersion
                                            latest={revision}
                                            curr={curr_revision}
                                        />
                                    }
                                </b>
                                <br />
                                <br />
                                <div>
                                    Update Type:{" "}
                                    <b>
                                        {
                                            <MarkChangedVersion
                                                curr={update_type}
                                                latest={updateType}
                                            />
                                        }
                                    </b>
                                </div>
                            </>
                        )
                    }
                />
            )}
            <label htmlFor="Update Type">Update Type</label>
            <select
                onChange={(e) => setUpdateType(e.target.value)}
                name="Update Type"
                id=""
                defaultValue={updateType}
            >
                <option value="POPUP">POPUP MODAL</option>
                <option value="BAR">BOTTOM BAR</option>
                <option value="HARD">HARD UPDATE</option>
            </select>

            <label htmlFor="Version">Latest Version</label>
            <div className={styles.versionSection}>
                <div className={styles.row}>
                    <label>Major</label> <label>Minor</label>
                    <label>Revision</label>
                </div>
                <div className={styles.row}>
                    <input
                        name="major"
                        type="number"
                        onChange={changeVersion}
                        value={major}
                    />
                    <input
                        name="minor"
                        type="number"
                        onChange={changeVersion}
                        value={minor}
                    />
                    <input
                        name="revision"
                        type="number"
                        onChange={changeVersion}
                        value={revision}
                    />
                </div>
                <small style={{ color: "red" }}>
                    *Please enter the latest version
                </small>
            </div>

            {updateType == "POPUP" && (
                <>
                    <label htmlFor="Description">Description Html</label>
                    <div>
                        <textarea
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </>
            )}

            {preview && updateType == "POPUP" && (
                <SoftUpdateModal
                    update_description={description}
                    update_type={updateType}
                    onClose={() => setPreview(false)}
                    preview={true}
                />
            )}

            {updateType == "POPUP" && (
                <Button text="Preview" handler={() => setPreview(true)} />
            )}

            {(previewed || updateType != "POPUP") && (
                <div className={styles.row}>
                    <Button
                        handler={() => setActiveAction(null)}
                        style={{
                            backgroundColor: "white",
                            border: "1px solid gray",
                            color: "gray",
                        }}
                        text="Close"
                        width="40%"
                    />
                    <Button
                        width="40%"
                        handler={() => setConfirmPopup(true)}
                        text="Submit"
                    />
                </div>
            )}
            <br />
            <br />
            <br />
            <small style={{ marginRight: 50 }}>
                *Your app build number: {build_number.version}
            </small>

            {build_number.version.split(".")[0] == new Date().getDate() ? (
                <mark
                    style={{ color: "#03da03", backgroundColor: "transparent" }}
                >
                    Today's Build
                </mark>
            ) : (
                <mark style={{ color: "red", backgroundColor: "transparent" }}>
                    Older Build
                </mark>
            )}
            <br />

            <small>*Your app version: {APP_VERSION}</small>
        </div>
    );
}

export default PlatformUpdates;

const MarkChangedVersion = ({ curr, latest }) => {
    if (curr == latest) return latest;
    else
        return (
            <mark style={{ color: "#03da03", backgroundColor: "transparent" }}>
                {latest}
            </mark>
        );
};
