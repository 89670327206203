import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./userProfile.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getInitials } from "../../SettingsSidebar";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { url } from "../../../../utility/config";
import {
    Tab,
    Tabs,
    Box,
    Typography,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import { authSuccess } from "../../../../store/actions/auth";
import { updateUserInfo } from "../../../../store/actions/auth";
import Button from "../../../../componentsV2/atoms/Button/Button";
import AccountTeam from "../../../../componentsV2/organisms/Account/AccountTeam";
import Usage from "./Usage";
import { userLogout } from "../../../../store/actions/auth";
import Modal from "../../../../componentsV2/atoms/Modal/Modal";
import Textfield from "../../../../componentsV2/atoms/CustomTextfield/Textfield";
import useHelperFunctions from "../../../../componentsV2/helperFunctions";
import useUserAccessHook from "../../useUserAccessHook";
import { IoMdClose } from "react-icons/io";
import UserAiOutputs from "./UserAiOutputs";
import UserArchiveContainer from "./UserArchives";
import { VscClose } from "react-icons/vsc";
import SVGIcon from "../../../../componentsV2/atoms/SVGIcon";
import { displayAlert } from "../../../../store/actions/alert";
import Referrals from "./Referrals";
import Transactions from "../Transactions/Transactions";
import { RiProfileLine, RiTeamLine } from "react-icons/ri";
import { BiBot, BiCreditCard, BiTone, BiUserCircle } from "react-icons/bi";
import { IoArchiveOutline, IoMegaphoneOutline } from "react-icons/io5";
import { BsCardImage, BsSpeedometer2 } from "react-icons/bs";
import {
    AUTH_LOGOUT_DASHBOARD,
    AUTH_LOGOUT_OTHERINFO,
    AUTH_LOGOUT_VARIANT,
    FLUSH_USER_GSC_INFO,
} from "../../../../store/actiontypes";
import BrandTone from "../BrandTone/BrandTone";
import GscLogout from "../../../../componentsV2/Pages/NewDashboard/GscLogout";
import { TbWorldWww } from "react-icons/tb";
import mixpanel from "mixpanel-browser";
import { CiViewTimeline } from "react-icons/ci";
import HistoryWindow from "../../../../componentsV2/Pages/InterLinking/HistoryWindow";
import DomainsTab from "./DomainsTab";
import Usage2 from "./Usage2";
import { PLANS_CATEGORIES } from "../../../../constants";

const GSTregex = /^[0-9]{2}[0-9A-Z]{13}$/;

const aisdeTabs = makeStyles((theme) => {
    root: {
        color: "green";
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                margin: "0",
                marginLeft: "0",
                marginRight: "0",
                borderRadius: 8,
                overflow: value == 5 ? "" : "hidden",
            }}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        value: index,
    };
}

const useStylesTabs = makeStyles((theme) => ({
    indicator: {
        display: "none",
    },
    root: {
        // borderBottom: "solid #d6d6d6 0.1px",
        width: "100%",
        padding: "10px",
        "& .MuiTabs-flexContainerVertical": {
            display: "flex",
            width: "100%",
        },
    },
}));

const useStylesTab = makeStyles((theme) => ({
    root: {
        minHeight: 38,
        margin: "0",
        fontSize: 16,
        textTransform: "none",

        flexGrow: 1,
        color: "#29394adb",
        opacity: 1,
        borderRadius: 8,
        marginBlock: 5,
        fontWeight: 400,

        "&:hover": {
            color: "#1f4cbe",
        },
        "& .Mui-selected": {},
        "& .MuiTab-wrapper": {
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        "& .MuiTab-wrapper > *:first-child ": {
            marginRight: "10px",
        },
    },
    icon: {
        color: "red",
    },
    selected: {
        color: "#29394adb",
        backgroundColor: "#CDDCFE",
        // background: "linear-gradient(to right, #1f4cbe -99%, rgba(255, 255, 255, 0) 15%)",
    },
}));

export default function UserAccount({
    userData,
    setUpdate,
    currencySign,
    isReferralAllowed = false,
    setIsReferralAllowed = () => {},
    loading,
    setIsActive,
    setDialog,
}) {
    const [editMode, setEditMode] = useState(false);
    const [editPass, setEditPass] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [appId, setAppId] = useState(null);
    const gst_no = useSelector((state) => state.auth.gst_no);
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );
    const [GST, setGST] = useState(gst_no);
    const [companyName, setCompanyName] = useState("");
    const [telNum, setTelNum] = useState("");
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateLoadingP, setUpdateLoadingP] = useState(false);
    const [file, setFile] = useState(null);
    const reduxUserData = useSelector((state) => state.auth);
    const [newPicLink, setNewPicLink] = useState("");
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const [fileUploading, setFileUploading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const current_pass = useRef();
    const history = useHistory();
    //for Usage

    const [passwords, setPasswords] = useState({
        current: "",
        new: "",
        confirm: "",
    });
    const token = useSelector((state) => state.auth.token);
    const currency = useSelector((state) => state.auth.currency);
    const country = useSelector((state) => state.auth.country);
    const id = useSelector((state) => state.auth.userId);
    const role = useSelector((state) => state.auth.role);
    const [isReferralModal, setIsReferralModal] = useState(false);
    const [referCode, setReferCode] = useState("");
    const [refStatus, setRefStatus] = useState(0);
    const otherInfo = useSelector((state) => state.otherInfo);

    const [profileColor, setProfileColor] = useState(1);

    const { putData, postData } = useHelperFunctions();
    const { isLTD, isTeamMember } = useUserAccessHook();

    const TabsClasses = useStylesTabs();
    const TabClasses = useStylesTab();

    useEffect(() => {
        setRefStatus(0);
        setIsActive("USER_PROFILE");
    }, [referCode]);

    const profileColors = [
        { id: 0, hex: "#FF5151" },
        { id: 1, hex: "#cfee13" },
        { id: 2, hex: "#4dda36" },
        { id: 3, hex: "#5681d5" },
        { id: 4, hex: "#df9cff" },
        { id: 5, hex: "#e33cb4" },
    ];

    useEffect(() => {
        current_pass.current?.focus();
        current_pass.current?.scrollIntoView();
    }, [editPass]);

    useEffect(() => {
        setName(userData.name);
        setCompanyName(userData.company_name);
        setTelNum(userData.phone);
        setEmail(userData.email);
    }, [userData.name]);

    useEffect(() => {
        if (file) {
            setFileUploading(true);
            const fd = new FormData();
            fd.append("image", file);
            axios({
                method: "POST",
                url: `${url}/api/project/uploadFile`,
                headers: {
                    Authorization: token,
                },
                data: fd,
            }).then((res) => {
                setNewPicLink(res.data.data.attachment);
                setFileUploading(false);
            });
        }
    }, [file]);

    const selectFile = (e) => {
        setFile(e.target.files[0]);
    };

    async function updateAccount(password) {
        const newUserData = {};
        if (name !== userData.name) newUserData.name = name;
        if (companyName !== userData.company_name)
            newUserData.company_name = companyName;
        if (email !== userData.email) newUserData.email = email;
        if (telNum !== userData.phone) newUserData.phone = telNum;
        if (
            passwords.current &&
            passwords.new &&
            passwords.new === passwords.confirm
        ) {
            newUserData.current_password = passwords.current;
            newUserData.password = passwords.new;
        }
        if (newPicLink) newUserData.profile_picture = newPicLink;
        if (GSTregex.test(GST) && GST != gst_no)
            newUserData["gst_number"] = GST;

        console.log(GSTregex.test(GST), GST, newUserData);

        if (Object.keys(newUserData).length > 0) {
            password ? setUpdateLoadingP(true) : setUpdateLoading(true);
            const res = await putData({
                url: `/api/user/${id}/update`,
                payload: newUserData,
            });
            if (res.status == 200) {
                setUpdate((prev) => prev + 1);
                setEditPass(false);
                password ? setUpdateLoadingP(false) : setUpdateLoading(false);
                setPasswords({ current: "", new: "", confirm: "" });
                setEditMode(false);
                setSuccessMsg(true);
                if (newUserData.gst_number)
                    dispatch(updateUserInfo("gst_no", newUserData.gst_number));
                else {
                    setGST(gst_no);
                }
                setTimeout(() => setSuccessMsg(false), 3000);

                if (name !== userData.name || newPicLink) {
                    dispatch(
                        authSuccess(
                            token,
                            {
                                ...reduxUserData,
                                id: reduxUserData.userId,
                                name: name ? name : reduxUserData.user_name,
                                profile_picture: newPicLink
                                    ? newPicLink
                                    : reduxUserData.profilePicture,
                            },
                            false,
                            false,
                        ),
                    );
                }
            } else {
                password ? setUpdateLoadingP(false) : setUpdateLoading(false);
            }
        } else {
            setGST(gst_no);
            setEditMode(false);
        }
    }

    function logout(params) {
        dispatch(userLogout());
        dispatch({ type: AUTH_LOGOUT_VARIANT });
        dispatch({ type: AUTH_LOGOUT_DASHBOARD });
        dispatch({ type: AUTH_LOGOUT_OTHERINFO });
        dispatch({ type: FLUSH_USER_GSC_INFO });
        history.push("/auth");
    }

    function referalStatus(temp) {
        switch (temp) {
            case 1:
                return (
                    <span className={styles.correctReferral}>
                        <SVGIcon
                            src={"/New UI/SVG/IoMdCheckmark.svg"}
                            size={"13"}
                            style={{ color: "#fff" }}
                        />
                    </span>
                );
            case 2:
                return (
                    <span className={styles.inProgressReferral}>
                        <CircularProgress size={18} />
                    </span>
                );
            case 3:
                return (
                    <span className={styles.wrongReferral}>
                        <SVGIcon
                            src={"/New UI/SVG/IoAlertSharp.svg"}
                            size={"14"}
                            style={{ color: "#fff" }}
                        />
                    </span>
                );
            default:
                return <SVGIcon />;
        }
    }

    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (window.location.pathname.includes("/integrations")) {
            setValue(2);
            setAppId(null);
            history.replace("/admin/account/userProfile");
        } else if (window.location.pathname.includes("/appId")) {
            window.location.pathname.split("=")[1];
            setAppId(window.location.pathname.split("=")[1]);
            history.replace("/admin/account/userProfile");
        } else if (window.location.pathname.includes("/domain")) {
            setValue(6);
            const fromMail = window.location.pathname.includes("mail=true");
            history.replace("/admin/account/userProfile");
            mixpanel.track("User Account Opened", {
                "From Mail": fromMail ? 1 : 0,
            });
        } else if (window.location.pathname.includes("/usage")) {
            // if (
            //     company_user_type != 2 &&
            //     role != "free_lancer" &&
            //     otherInfo?.subscriptionInfo?.plan_category !=
            //         PLANS_CATEGORIES.TRAIL
            // )
            //     setValue(8);
            // history.replace("/admin/account/userProfile");
        }
    }, [window.location.pathname]);

    const handleChange = (event, newValue) => {
        console.log("newVal", newValue);
        setValue(newValue);
        console.log("newVal", event);
    };

    function handleCancel() {
        setEditMode(false);
        setName(userData.name);
        setCompanyName(userData.company_name);
        setTelNum(userData.phone);
        setEditMode(false);
        setNewPicLink("");
        setGST(gst_no);
    }

    async function checkReferralCode() {
        setRefStatus(2);
        const res = await postData({
            url: "/api/refer/add-code",
            payload: { referCode },
        });
        if (res.status == 200) {
            setRefStatus(1);
            dispatch(
                displayAlert({
                    alertMessage: `Congratulations! Your account has been credited with ${
                        currency == "INR" ? "₹1500" : "$19"
                    }. Get your subscription now`,
                    alertType: "success",
                    timeout: 4000,
                }),
            );
            setIsReferralModal(false);
            setIsReferralAllowed(false);
            console.log("refcode Done");
        } else {
            console.log("refcode No");
            setRefStatus(3);
        }
    }

    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.leftTabs}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        classes={TabsClasses}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            classes={TabClasses}
                            label={
                                <>
                                    <BiUserCircle size={18} /> Profile
                                </>
                            }
                            {...a11yProps(0)}
                        />
                        {/* {company_user_type != 2 &&
                            role != "free_lancer" &&
                            otherInfo?.subscriptionInfo?.plan_category !=
                                PLANS_CATEGORIES.TRAIL && (
                                <Tab
                                    classes={TabClasses}
                                    label={
                                        <>
                                            <BsSpeedometer2 size={18} /> Usage
                                        </>
                                    }
                                    {...a11yProps(8)}
                                />
                            )} */}
                        <Tab
                            classes={TabClasses}
                            label={
                                <>
                                    <IoMegaphoneOutline
                                        size={18}
                                        style={{
                                            transform: "rotate(-30deg)",
                                        }}
                                    />
                                    Tone of Voice
                                </>
                            }
                            {...a11yProps(1)}
                        />

                        {company_user_type != 2 && role != "free_lancer" && (
                            <Tab
                                classes={TabClasses}
                                tabIndex={1}
                                label={
                                    <>
                                        <RiTeamLine size={18} /> Team
                                    </>
                                }
                                {...a11yProps(2)}
                            />
                        )}
                        {/* {company_user_type != 2 && role != "free_lancer" && (
              <Tab
                classes={TabClasses}
                onClick={() => appId && setAppId(null)}
                label={
                  <span className={styles.superText}>App Integrations</span>
                }
                {...a11yProps(2)}
              />
            )} */}

                        {company_user_type != 2 && role != "free_lancer" && (
                            <Tab
                                classes={TabClasses}
                                label={
                                    <>
                                        <IoArchiveOutline size={18} /> Archives
                                    </>
                                }
                                {...a11yProps(3)}
                            />
                        )}
                        <Tab
                            classes={TabClasses}
                            label={
                                <>
                                    <BiBot size={18} /> AI Outputs
                                </>
                            }
                            {...a11yProps(4)}
                        />
                        {company_user_type != 2 && role != "free_lancer" && (
                            <Tab
                                classes={TabClasses}
                                label={
                                    <>
                                        <BiCreditCard size={18} /> Transactions
                                    </>
                                }
                                {...a11yProps(5)}
                            />
                        )}
                        {!isTeamMember && (
                            <Tab
                                classes={TabClasses}
                                label={
                                    <>
                                        <TbWorldWww size={18} /> Domains
                                    </>
                                }
                                {...a11yProps(6)}
                            />
                        )}
                        {!isTeamMember && (
                            <Tab
                                classes={TabClasses}
                                label={
                                    <>
                                        <CiViewTimeline size={18} /> Change Logs
                                    </>
                                }
                                {...a11yProps(7)}
                            />
                        )}
                    </Tabs>
                </div>
                <div className={styles.rightPanels}>
                    <TabPanel value={value} index={0}>
                        <div className={styles.userProfile}>
                            <div className={styles.sectionTop}>
                                {!editMode ? (
                                    <div
                                        className={styles.textUnderLine}
                                        onClick={() => {
                                            setEditPass(true);
                                        }}
                                    >
                                        Change Password
                                    </div>
                                ) : (
                                    <div
                                        className={styles.textUnderLine}
                                        onClick={() => {
                                            handleCancel();
                                        }}
                                    >
                                        Cancel
                                    </div>
                                )}
                                {!editMode ? (
                                    <div
                                        className={styles.textUnderLine}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit Profile
                                    </div>
                                ) : (
                                    <div
                                        className={styles.textUnderLine}
                                        onClick={() => {
                                            updateAccount(false);
                                        }}
                                    >
                                        Update Profile
                                    </div>
                                )}

                                <div
                                    className={styles.textUnderLine}
                                    onClick={() => logout()}
                                    style={{
                                        color: "#718096",
                                    }}
                                >
                                    Logout
                                </div>
                            </div>
                            <div className={styles.profileSection}>
                                <div
                                    className={styles.userProfile__imageSection}
                                >
                                    <div className={styles.userProfile__image}>
                                        {userData.profile_picture ||
                                        newPicLink ? (
                                            fileUploading ? (
                                                <CircularProgress />
                                            ) : (
                                                <img
                                                    src={
                                                        newPicLink
                                                            ? newPicLink
                                                            : userData.profile_picture
                                                    }
                                                />
                                            )
                                        ) : (
                                            <span>
                                                {userData.name &&
                                                    getInitials(userData.name)}
                                            </span>
                                        )}
                                        {editMode && (
                                            <>
                                                <div
                                                    className={styles.imgEdit}
                                                    onClick={() =>
                                                        fileInputRef.current.click()
                                                    }
                                                >
                                                    <span>Edit</span>
                                                </div>
                                                <input
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={selectFile}
                                                    ref={fileInputRef}
                                                />
                                            </>
                                        )}
                                    </div>
                                    {false && (
                                        <div
                                            className={`${styles.userProfile__buttonGroup} ${styles.topRight}`}
                                        >
                                            {/* {error && <span className={styles.errorMsg}>Wrong Password!</span>} */}
                                            <button
                                                className={
                                                    styles.userProfile__cancelButton
                                                }
                                                onClick={() => {}}
                                            >
                                                Discard
                                            </button>
                                            <Button
                                                loading={updateLoading}
                                                text="Save"
                                                width="175px"
                                                fontSize={"16px"}
                                                height={"38px"}
                                                fontWeight={"400"}
                                                handler={() =>
                                                    updateAccount(false)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className={styles.profileSectionContainer}>
                                    <div
                                        className={styles.profileSection__row1}
                                    >
                                        <Textfield
                                            className={styles.userName}
                                            type={"text"}
                                            placeholder={"Enter Your Name"}
                                            label={"Full Name"}
                                            height={38}
                                            props={{
                                                value: name,
                                                onChange: (e) => {
                                                    setName(e.target.value);
                                                },
                                                autoComplete: "off",
                                                disabled: !editMode,
                                            }}
                                        />
                                        <Textfield
                                            className={styles.companyName}
                                            type={"text"}
                                            height={38}
                                            placeholder={
                                                "Enter Your Company Name"
                                            }
                                            label={"Company Name"}
                                            props={{
                                                value: companyName,
                                                onChange: (e) => {
                                                    setCompanyName(
                                                        e.target.value,
                                                    );
                                                },
                                                autoComplete: "off",
                                                disabled: !editMode,
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.profileSection__row2 +
                                            " " +
                                            styles.jab +
                                            " " +
                                            styles.jab +
                                            " " +
                                            styles.jab
                                        }
                                    >
                                        <div className={styles.userPhone}>
                                            <label
                                                className={styles.phoneLabel}
                                            >
                                                Phone Number
                                            </label>
                                            {/* <PhoneInput
                        className={styles.userProfile__fieldPasswordAnswer}
                        defaultCountry="US"
                        value={telNum}
                        onChange={setTelNum}
                        disabled={!editMode}
                      /> */}
                                            <PhoneInput
                                                className={
                                                    editMode
                                                        ? styles.userPhone__input
                                                        : styles.userPhone__inputDisabled
                                                }
                                                international
                                                key={1}
                                                id={1}
                                                country={"us"}
                                                value={telNum ? telNum : ""}
                                                onChange={setTelNum}
                                                disabled={!editMode}
                                                inputStyle={{ width: "100%" }}
                                            />
                                        </div>
                                        {/* {currency == "INR" &&
                                            role == "user" && (
                                                <Textfield
                                                    className={
                                                        styles.companyGST
                                                    }
                                                    type={"text"}
                                                    placeholder={
                                                        "Enter GST No."
                                                    }
                                                    label={"GST No."}
                                                    width={"50%"}
                                                    height={38}
                                                    props={{
                                                        value: GST,
                                                        onChange: (e) => {
                                                            e.target.value.trim()
                                                                .length <= 15 &&
                                                                setGST(
                                                                    e.target.value
                                                                        .trim()
                                                                        .toUpperCase(),
                                                                );
                                                        },
                                                        autoComplete: "off",
                                                        disabled: !editMode,
                                                    }}
                                                    errMsg={
                                                        GST != "N/A" &&
                                                        GST != "" &&
                                                        GST != null &&
                                                        !GSTregex.test(GST) &&
                                                        "Enter a Valid GST No."
                                                    }
                                                />
                                            )} */}
                                    </div>
                                    <div
                                        className={styles.profileSection__row3}
                                    >
                                        <Textfield
                                            className={styles.userEmail}
                                            type={"text"}
                                            height={38}
                                            placeholder={"Enter Your Email"}
                                            label={"Email ID"}
                                            props={{
                                                value: email,
                                                onChange: (e) => {
                                                    setEmail(e.target.value);
                                                },
                                                autoComplete: "off",
                                                disabled: true,
                                            }}
                                        />
                                    </div>
                                    {/* <div className={styles.referralWrapper}>
                                        {company_user_type != 2 &&
                                        role != "free_lancer" &&
                                        isReferralAllowed ? (
                                            <>
                                                <p
                                                    className={
                                                        styles.referralCta
                                                    }
                                                    onClick={() =>
                                                        setIsReferralModal(true)
                                                    }
                                                >
                                                    Do you have a referral code?
                                                </p>
                                                {isReferralModal && (
                                                    <Modal
                                                        setModal={
                                                            setIsReferralModal
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.referralCard
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.closeReferral
                                                                }
                                                                onClick={() =>
                                                                    setIsReferralModal(
                                                                        false,
                                                                    )
                                                                }
                                                            >
                                                                <VscClose
                                                                    size={30}
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.referral__Text
                                                                }
                                                            >
                                                                Please Enter
                                                                Your Referral
                                                                Code{" "}
                                                                <span
                                                                    className={
                                                                        styles.asterisk
                                                                    }
                                                                >
                                                                    *
                                                                </span>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.referral__input
                                                                }
                                                            >
                                                                <Textfield
                                                                    label=""
                                                                    props={{
                                                                        value: referCode,
                                                                        onChange:
                                                                            (
                                                                                e,
                                                                            ) => {
                                                                                setReferCode(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                );
                                                                            },
                                                                        autoComplete:
                                                                            "off",
                                                                        disabled:
                                                                            refStatus ==
                                                                            2,
                                                                    }}
                                                                    value={
                                                                        referCode
                                                                    }
                                                                    IconRight={() =>
                                                                        referalStatus(
                                                                            refStatus,
                                                                        )
                                                                    }
                                                                    containerStyle={{
                                                                        margin: 0,
                                                                    }}
                                                                    placeholder={
                                                                        "Enter referral code here"
                                                                    }
                                                                    width={
                                                                        "320px"
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.referral__Btn
                                                                }
                                                            >
                                                                <Button
                                                                    style={{
                                                                        backgroundColor:
                                                                            referCode
                                                                                ? ""
                                                                                : "#acb3bf",
                                                                    }}
                                                                    text={
                                                                        "Claim Now"
                                                                    }
                                                                    width={
                                                                        "320px"
                                                                    }
                                                                    handler={() =>
                                                                        checkReferralCode()
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div> */}
                                </div>
                            </div>
                            {company_user_type != 2 &&
                                role != "free_lancer" && <Usage />}
                        </div>
                        {editPass && (
                            <Modal setModal={setEditPass}>
                                <PasswordModal
                                    passwords={passwords}
                                    setPasswords={setPasswords}
                                    updateAccount={updateAccount}
                                    updateLoadingP={updateLoadingP}
                                    setEditPass={setEditPass}
                                />
                            </Modal>
                        )}

                        {/* {company_user_type != 2 &&
                            role != "free_lancer" &&
                            !isLTD &&
                            (email?.length > 0 ? (
                                <Referrals userEmail={email} />
                            ) : (
                                ""
                            ))} */}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {<BrandTone />}
                    </TabPanel>
                    {company_user_type != 2 && role != "free_lancer" && (
                        <TabPanel value={value} index={2}>
                            {<AccountTeam />}
                        </TabPanel>
                    )}

                    {company_user_type != 2 && role != "free_lancer" && (
                        <TabPanel value={value} index={3}>
                            <UserArchiveContainer bin={true} />
                        </TabPanel>
                    )}
                    <TabPanel value={value} index={4}>
                        <UserAiOutputs />
                    </TabPanel>
                    {!isTeamMember && (
                        <TabPanel value={value} index={5}>
                            <Transactions
                                setDialog={setDialog}
                                setIsActive={() => {}}
                            />
                        </TabPanel>
                    )}
                    {!isTeamMember && (
                        <TabPanel value={value} index={6}>
                            <DomainsTab />
                        </TabPanel>
                    )}
                    {!isTeamMember && (
                        <TabPanel value={value} index={7}>
                            <HistoryWindow />
                        </TabPanel>
                    )}
                    {/* {company_user_type != 2 &&
                        role != "free_lancer" &&
                        otherInfo?.subscriptionInfo?.plan_category !=
                            PLANS_CATEGORIES.TRAIL && (
                            <TabPanel value={value} index={8}>
                                <Usage2 />
                            </TabPanel>
                        )} */}
                </div>
            </div>
        </>
    );
}

const PasswordModal = ({
    updateAccount,
    updateLoadingP,
    passwords,
    setPasswords,
    setEditPass,
}) => {
    const [blurCurrPass, setBlurCurrPass] = useState(false);
    const [blurNewPass, setBlurNewPass] = useState(false);
    const [blurConfirmPass, setBlurConfirmPass] = useState(false);

    function handleUpdate() {
        if (
            passwords.current &&
            passwords.new.length >= 6 &&
            passwords.confirm.length >= 6 &&
            passwords.confirm === passwords.new
        ) {
            updateAccount(true);
            setBlurNewPass(true);
            setBlurConfirmPass(true);
        }
    }

    useEffect(() => {
        return () => {
            setPasswords(() => ({ current: "", confirm: "", new: "" }));
        };
    }, []);

    return (
        <div className={styles.changePasswordModal}>
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setEditPass(false);
                }}
                className={styles.userProfile__passwordSection__Close}
            />

            <div>
                <h3 className={styles.modalHeading}>Change Password</h3>
            </div>
            <div className={styles.changePassFields}>
                <div className={styles.changePassField}>
                    <div>
                        <Textfield
                            className={styles.changePassField__CurrPass}
                            type={"password"}
                            placeholder={"Enter Current Password"}
                            autoFocus={true}
                            label="Enter Current Password"
                            props={{
                                value: passwords.current,
                                onChange: (e) =>
                                    setPasswords({
                                        ...passwords,
                                        current: e.target.value.trim(),
                                    }),
                                onBlur: () => setBlurCurrPass(true),
                                autoComplete: "off",
                            }}
                            errMsg={
                                !passwords.current.trim() && blurCurrPass
                                    ? "Please enter your current password"
                                    : ""
                            }
                        />
                    </div>
                </div>
                <div className={styles.changePassField}>
                    <div>
                        <Textfield
                            label="Enter New Password"
                            className={styles.changePassField__NewPass}
                            type={"password"}
                            placeholder={"Enter New Password"}
                            props={{
                                value: passwords.new,
                                onChange: (e) =>
                                    setPasswords({
                                        ...passwords,
                                        new: e.target.value.trim(),
                                    }),
                                onBlur: () => {
                                    setBlurNewPass(true);
                                },
                                autoComplete: "off",
                            }}
                            errMsg={
                                !passwords.new.trim() && blurNewPass
                                    ? "Please enter new password"
                                    : passwords.new.trim().length == 0 ||
                                      passwords.new.trim().length >= 6
                                    ? ""
                                    : "Password must be of at least 6 characters"
                            }
                        />
                    </div>
                </div>
                <div className={styles.changePassField}>
                    <div>
                        <Textfield
                            label="Re-Enter New Password"
                            className={styles.changePassField__ReNewPass}
                            type={"password"}
                            placeholder={"Re-enter New Password"}
                            props={{
                                value: passwords.confirm,
                                onChange: (e) =>
                                    setPasswords({
                                        ...passwords,
                                        confirm: e.target.value.trim(),
                                    }),
                                onBlur: () => setBlurConfirmPass(true),

                                autoComplete: "off",
                            }}
                            successMsg={
                                passwords.confirm &&
                                passwords.confirm.length >= 6 &&
                                passwords.new === passwords.confirm &&
                                "Passwords match!"
                            }
                            errMsg={
                                !passwords.confirm.trim() && blurConfirmPass
                                    ? "Please confirm your new password"
                                    : passwords.new !== passwords.confirm &&
                                      blurConfirmPass &&
                                      "Passwords do not match!"
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={styles.btnsDiv}>
                <button
                    className={`${styles.btn} ${styles.btnDiscard}`}
                    onClick={() => {
                        setEditPass(false);
                    }}
                    height={"40px"}
                >
                    Discard
                </button>
                <Button
                    loading={updateLoadingP}
                    handler={() => {
                        handleUpdate();
                    }}
                    text="Change"
                    width="150px"
                    fontSize={"16px"}
                    height={"42px"}
                ></Button>
            </div>
        </div>
    );
};
