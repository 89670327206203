import React from "react";
import styles from "./styles.module.css";
import { useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import Button from "../../../atoms/Button/Button";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";

function URL({
    getDataFromUrl,
    setImportFromUrlModal,
    isLoadingTextFromUrl,
    setIsLoadingTextFromUrl,
}) {
    const [url, setUrl] = useState("");
    const [error, setError] = useState(false);

    return (
        <div
            className={
                styles.importDiv +
                " " +
                (isLoadingTextFromUrl && styles.loadingText)
            }
        >
            {!isLoadingTextFromUrl ? (
                <>
                    <span className={styles.seoModalCross}>
                        <SVGIcon
                            onClick={() => setImportFromUrlModal(false)}
                            src="/New UI/SVG/cross.svg"
                        ></SVGIcon>
                    </span>
                    <h3>
                        <SVGIcon src="/New UI/SVG/link.svg"></SVGIcon>
                        &nbsp;&nbsp; Import from URL
                    </h3>
                    <div className={styles.importDivInput}>
                        <Textfield
                            variant="filled"
                            value={url}
                            width={"100%"}
                            label=""
                            placeholder="Enter URL"
                            errMsg={error ? "Enter a valid URL" : ""}
                            props={{
                                style: {
                                    margin: 0,
                                    padding: "0px 10px",
                                },
                                onBlur: (e) => {
                                    !e.target.value.trim() && setError(true);
                                },
                                onKeyDown: (e) => {
                                    if (e.key == "Enter") {
                                        getDataFromUrl(
                                            url,
                                            setImportFromUrlModal,
                                        );
                                        e.target.blur();
                                    }
                                },
                                onChange: (e) => {
                                    setError(false);
                                    setUrl(e.target.value.trim());
                                },
                            }}
                        ></Textfield>
                        <div className={styles.btnContainer}>
                            <Button
                                text={
                                    !isLoadingTextFromUrl ? (
                                        "Import"
                                    ) : (
                                        <>
                                            Importing <LoadingDots />
                                        </>
                                    )
                                }
                                width={"130px"}
                                handler={(e) => {
                                    setIsLoadingTextFromUrl(true);
                                    getDataFromUrl(url, setImportFromUrlModal);
                                }}
                                disabled={isLoadingTextFromUrl}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.inProgRefresh}>
                        <span>
                            Please wait while we are importing your content...{" "}
                        </span>
                        <LoadingDots
                            customStyle={{
                                height: "24px",
                                width: "24px",
                                color: "inherit",
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default URL;
