function comparePlansTableHTML({ subs = true, plansType = 1, dealsLimit = 1 }) {
    const compareTablePlans = `
    <div class="comparison-row sub-header">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22bd-3a27a5ca"
            class="comparison-feature sub-header"
        >
            <div class="text-bold"><strong>Limits/mo</strong></div>
        </div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22c2-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Words</div>
        </div>
        <div class="comparison-item"><div>${
            plansType == 1 ? "1,00,000" : "1,00,000"
        }</div></div>
        <div class="comparison-item"><div>Unlimited</div></div>
        <div class="comparison-item"><div>Unlimited</div></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22cf-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Article Writer</div>
        </div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 5 : dealsLimit * 5
        }</div></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 30 : dealsLimit * 30
        }</div></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 75 : dealsLimit * 75
        }</div></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22dc-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Keyword Planner</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 30 : dealsLimit * 30
        }</div></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 75 : dealsLimit * 75
        }</div></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22e7-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Page Optimizer</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 30 : dealsLimit * 30
        }</div></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 75 : dealsLimit * 75
        }</div></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22e7-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Tone of Voice</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item"><div>Unlimited</div></div>
        <div class="comparison-item"><div>Unlimited</div></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22e7-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Content Inventory</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 200 : 200
        }</div></div>
        <div class="comparison-item"><div>${
            plansType == 1 ? 500 : 500
        }</div></div>
    </div>
       <div class="comparison-row dark-row">
    <div
        id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
        class="comparison-feature"
    >
        <div class="text-bold">Domains</div>
    </div>
    <div class="comparison-item">${plansType == 1 ? 1 : 1 * 1}</div>
<div class="comparison-item ">${plansType == 1 ? 1 : 1 * 1}</div>
    <div class="comparison-item">${plansType == 1 ? 5 : 1 * 5}</div>
     </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22e7-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Detection (words)</div>
        </div>
        <div class="comparison-item"><div>20,000</div></div>
        <div class="comparison-item"><div>20,000</div></div>
        <div class="comparison-item"><div>20,000</div></div>
</div>
<div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22e7-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Humanization (words)</div>
        </div>
        <div class="comparison-item"><div>5,000</div></div>
        <div class="comparison-item"><div>5,000</div></div>
        <div class="comparison-item"><div>5,000</div></div>
    </div>
    <div class="comparison-row sub-header">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc233b-3a27a5ca"
            class="comparison-feature sub-header"
        >
            <div class="text-bold"><strong>Writing </strong></div>
        </div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2340-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">SEO Editor for Article Writing</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2347-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Long-form Editor for Copywriting</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc234e-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Write & Instruct Commands</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2355-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Paraphrasing</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2355-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Factual Content</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2355-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">SEO Article Templates</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2355-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Images</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc235c-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Copywriter with 40+ Templates</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2363-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Writing Chrome Extension&nbsp;</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc236a-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI Documents</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2371-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">AI History</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2378-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Favorite Outputs</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc237f-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Auto-Save</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row sub-header">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22f2-3a27a5ca"
            class="comparison-feature sub-header"
        >
            <div class="text-bold"><strong>SEO</strong></div>
        </div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22f7-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Cruise Mode (5 min article writer)</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22fe-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">SERP Analysis</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2305-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">SERP Statistics</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc230c-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Social Listening</div>
            
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2318-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Automated Outlines</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc231f-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Create Briefs</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc231f-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">NLP Entities</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2326-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">SEO Documents</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc232d-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Keyword Planner</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>

    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Page Optimizer</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>

    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Fix-it (Auto-optimizer)</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>

    </div>
    <div class="comparison-row">
    <div
        id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
        class="comparison-feature"
    >
        <div class="text-bold">Traffic Insights</div>
    </div>
    <div class="comparison-item check-edit"></div>
    <div class="comparison-item check-edit"></div>
    <div class="comparison-item check-edit"></div>
</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">Weekly Digest</div>
</div>
<div class="comparison-item check-edit"></div>
<div class="comparison-item check-edit"></div>
<div class="comparison-item check-edit"></div>
</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">Content Inventory Analysis</div>
</div>
<div class="comparison-item x"></div>
<div class="comparison-item check-edit"></div>
<div class="comparison-item check-edit"></div>

</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">Cannibalization</div>
</div>
<div class="comparison-item x"></div>
<div class="comparison-item x"></div>
<div class="comparison-item check-edit"></div>

</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">Advanced Keyword Planner (Keyword Gap)</div>
</div>
<div class="comparison-item x"></div>
<div class="comparison-item x"></div>
<div class="comparison-item check-edit"></div>

</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">Internal Linking</div>
</div>
<div class="comparison-item x"></div>
<div class="comparison-item x"></div>
<div class="comparison-item check-edit"></div>

</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">Topic Gap</div>
</div>
<div class="comparison-item x"></div>
<div class="comparison-item check-edit"></div>
<div class="comparison-item check-edit"></div>

</div>
<div class="comparison-row">
<div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2334-3a27a5ca"
    class="comparison-feature"
>
    <div class="text-bold">
    New Topic Ideas
    </div>
</div>
<div class="comparison-item x"></div>
<div class="comparison-item check-edit"></div>
<div class="comparison-item check-edit"></div>

</div>
    <div class="comparison-row sub-header">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2386-3a27a5ca"
            class="comparison-feature sub-header"
        >
            <div class="text-bold"><strong>Collaboration</strong></div>
        </div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc238b-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Sharing Documents</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2392-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Team Members</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row sub-header">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2399-3a27a5ca"
            class="comparison-feature sub-header"
        >
            <div class="text-bold"><strong>App Integrations</strong></div>
        </div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc239e-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Semrush</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23a5-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">WordPress</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23ac-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Copyscape</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
    <div
    id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc2399-3a27a5ca"
    class="comparison-feature sub-header"
>
    <div class="text-bold"><strong>Support</strong></div>
</div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23b8-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Additional SaaS Credits</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23bf-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Email</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23c6-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Live Chat</div>
        </div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
        <div class="comparison-item check-edit"></div>
    </div>
    <div class="comparison-row dark-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23cd-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Onboarding Training</div>
        </div>
        <div class="comparison-item"><div>Videos &amp; Live Webinars</div></div>
        <div class="comparison-item"><div>Videos &amp; Live Webinars</div></div>
        <div class="comparison-item"><div>Videos &amp; Live Webinars</div></div>
    </div>
    <div class="comparison-row">
        <div
            id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc23da-3a27a5ca"
            class="comparison-feature"
        >
            <div class="text-bold">Dedicated CS</div>
        </div>
        <div class="comparison-item x"></div>
        <div class="comparison-item x"></div>
        <div class="comparison-item check-edit"></div>
    </div>
`;

    return {
        compareTablePlans,
    };
}

export default comparePlansTableHTML;
