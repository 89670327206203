import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import styles from "./TrafficAnalyzer.module.css";
import useHelperFunctions from "../../helperFunctions";
import moment from "moment";
import SVGIcon from "../../atoms/SVGIcon";
import useGscHook, {
    GscInsightsDataObject,
    InventoryKeywordObject,
    InventoryV2Object,
    TotalDeltaValues,
    CountryObject,
    CannibalizationOtherInfoObject,
    CannibalizationKeywordObject,
    CannibalizationRequestPayload,
    DomainObject,
} from "./useGscHook";

import { inflate } from "pako";

import {
    CircularProgress,
    ClickAwayListener,
    Tab,
    Tabs,
    Tooltip,
    makeStyles,
    withStyles,
} from "@material-ui/core";
import GeneralInsights from "./Insights/GeneralInsights";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { GSC_Countries } from "./GscCountries";
import { socket } from "../../../Sockets/useSockets";
import { socketEvents } from "../../../Sockets/constants";
import { Skeleton } from "@material-ui/lab";
import Modal from "../../atoms/Modal/Modal";
import { VscClose } from "react-icons/vsc";
import ReactSelect from "../../organisms/Research/Wordpress/ReactSelect";
import Button from "../../atoms/Button/Button";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import {
    getPastAsTime,
    getRemainingTime,
} from "../../../utility/validateFunctions";
import {
    flushUserGscInfo,
    resetUserGscInfoMonthlyInsights,
    setUserGscInfo,
    setUserGscInfoMonthlyInsights,
} from "../../../store/actions/userGscInfo";
import { useHistory } from "react-router-dom";
import { RiLock2Fill } from "react-icons/ri";
import { displayAlert } from "../../../store/actions/alert";
import UserFeedback from "../../organisms/UserFeedback/UserFeedback";
import InventoryV2 from "./Inventory/InventoryV2";
import KeywordCannibalization from "./Cannibalization/KeywordCannibalization";
import { debounceWrapper } from "../../../utility/helperFun";
import { PLANS_CATEGORIES } from "../../../constants";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import ExpiryPopup from "../../templates/Dashboard/ExpiryPopup";
import expiryStyles from "../../templates/Dashboard/styles.module.css";
import { MdClose } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import GscLogout from "../NewDashboard/GscLogout";
import { useMixpanelTA } from "./useMixpanelTA";

let timeInterval: any = null;

let initialLoad = true;

export const TabsName = {
    inventory: "Inventory",
    cannibalization: "Cannibalization",
    insights: "Insights",
};

type Props_TrafficAnalyzer = {
    setIsShowData: Dispatch<SetStateAction<boolean>>;
    isShowData: boolean;
    value: number;
    setValue: Dispatch<SetStateAction<number>>;
    selectedDomains: [] | DomainObject[];
    setSelectedDomains: Dispatch<SetStateAction<[] | DomainObject[]>>;
    activeDomain: DomainObject | undefined;
    setActiveDomain: Dispatch<SetStateAction<DomainObject | undefined>>;
    setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
    setShowGscLogout: Dispatch<SetStateAction<boolean>>;
    getDetails: Function;
};
const customStyles = {
    valueContainer: (base: any) => ({
        ...base,
    }),
    control: (base: any) => ({
        ...base,
        minHeight: 52,
        border: "1px solid #e1e4ed",
        boxShadow: "0px 2px 1px rgba(225, 228, 237, 0.4)",
        "&:hover": {
            borderColor: "#e1e4ed !important",
        },
    }),
    menu: (base: any) => ({
        ...base,
        zIndex: 100,
    }),
    menuList: (styles: any) => ({
        ...styles,
        maxHeight: "200px",
        fontSize: "14px",
    }),
    indicatorSeparator: (styles: any) => ({
        ...styles,
        display: "none",
    }),
    placeholder: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: "#3f5575",
            fontWeight: "400",
            fontSize: "14px",
        };
    },
};

const useStylesTab = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        color: "#3F5575",
        opacity: 1,
        height: 33,
        minHeight: 40,
        fontSize: 14,
        fontWeight: 500,
        textDecoration: "none",
        textTransform: "none",
        borderBottom: "2px solid transparent",
        padding: 0,
        paddingRight: 4,
        flexGrow: 1,
        display: "flex",
        marginRight: "32px",
        minWidth: "max-content",
        maxWidth: "140px",
        overflow: "visible",
        "&.Mui-disabled": {
            opacity: 1,
            color: "#3F5575",
        },
    },
    selected: {
        color: "#014dc5",
        fontWeight: 500,
        backgroundColor: "#fff",
        borderBottom: "4px solid #014dc5",
    },
    disabled: {
        color: "green",
        opacity: "none !important",
        pointerEvents: "none",
        "&::Mui-disabled": {
            opacity: "none !important",
        },
    },
    indicator: {
        display: "none",
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    root: {
        "& .MuiTabScrollButton-root.Mui-disabled": {
            display: "none",
        },
        width: "50%",
        padding: 0,
        overflow: "auto",
        backgroundColor: "transparent",
        marginBottom: "-3px",
    },
    indicator: {
        display: "none",
    },
}));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        value: index,
    };
}

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={
                value == index
                    ? {
                          position: "relative",
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          padding: index == 2 ? "0px" : "0px",
                      }
                    : { position: "relative" }
            }
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export const LightTooltipGraph = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #F1F1F1",
        },
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: "white",
        color: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        fontSize: 11,
        position: "relative",
        maxWidth: "700px",
        border: "1px solid #F1F1F1",
        borderRadius: "4px",
        padding: "10px",
        paddingBottom: "0px",
    },
}))(Tooltip);

const TrafficAnalyzerHeader = ({
    otherInfoV2,
    isShimmer,
    activeDomain,
    selectedDomains,
    activeCountry,
    setActiveDomain,
    selectedCountries,
    oldSelectedCountries,
    isCannibsLocked,
    handleActiveCountryDomain,
    setIsManageCountry,
    setIsManageDomains,
    setValue,
    value,
    isQuotaOver,
    inventoryPagesMax,
    inventoryPagesProcessed,
}: {
    value: number;
    setValue: Dispatch<SetStateAction<number>>;
    handleActiveCountryDomain: (cn: any, dm: DomainObject | undefined) => void;
    selectedCountries: CountryObject[];
    activeCountry: CountryObject | undefined;
    oldSelectedCountries: CountryObject[];
    selectedDomains: DomainObject[];
    isCannibsLocked: boolean;
    activeDomain: DomainObject | undefined;
    setActiveDomain: Dispatch<SetStateAction<DomainObject | undefined>>;
    isShimmer: boolean;
    setIsManageCountry: Dispatch<SetStateAction<boolean>>;
    setIsManageDomains: Dispatch<SetStateAction<boolean>>;
    otherInfoV2: {
        next_refresh: number;
        next_refresh_date: string;
        last_refresh: number;
        last_refresh_date: string;
        startDate: string;
        endDate: string;
    };
    isQuotaOver: boolean;
    inventoryPagesMax: number;
    inventoryPagesProcessed: number;
}) => {
    const [countryDropDown, setCountryDropDown] = useState(false);
    const [domainDropDown, setDomainDropDown] = useState(false);
    const goto = useNavigation();
    const { getDomainStatus, getTrimmedDomainURL } = useGscHook();
    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();
    const [activeTabName, setActiveTabName] = useState("");
    const { LockConfigurator, isTeamMember, plan_category, isLTD } =
        useUserAccessHook();
    const { taTrackDomainsCountry } = useMixpanelTA();
    const { mixpanelTrack } = useMixpanelHook();

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        let tName = "";
        switch (newValue) {
            case 0:
                tName = TabsName.insights;
                break;
            case 1:
                tName = TabsName.inventory;
                break;
            case 2:
                tName = TabsName.cannibalization;
                break;
            default:
                tName = "";
        }
        setValue(newValue);
    };
    useEffect(() => {
        switch (value) {
            case 0:
                setActiveTabName(TabsName.insights);
                break;
            case 1:
                setActiveTabName(TabsName.inventory);
                break;
            case 2:
                setActiveTabName(TabsName.cannibalization);
                break;
            default:
                setActiveTabName("");
        }
    }, [value]);

    return (
        <div className={styles.topWrapper}>
            <div className={styles.topSection}>
                <div className={styles.topSection_left}>
                    <div className={styles.titleSection}>
                        <div className={styles.main}>Traffic Analyzer</div>
                        <div className={styles.subTextWrapper}>
                            <div className={styles.sub}>
                                Unlock powerful insights and maximize
                                performance
                            </div>
                            <div>
                                {otherInfoV2?.last_refresh_date?.length > 0 && (
                                    <div className={styles.lastRefreshTime}>
                                        <SVGIcon
                                            src={"/New UI/SVG/refresh-dots.svg"}
                                            size={14}
                                            style={{ color: "#1F4CBE" }}
                                        />
                                        Last Refreshed{" "}
                                        <span className={styles.daysAgo}>
                                            {getPastAsTime(
                                                otherInfoV2.last_refresh_date,
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.infoSection}>
                        <div className={styles.userSelection}>
                            <div className={styles.domainInfo}>
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setDomainDropDown(false);
                                    }}
                                >
                                    <div
                                        className={styles.dropDownDomainWrapper}
                                    >
                                        <div
                                            className={styles.domainCol}
                                            onClick={() => {
                                                if (isShimmer) {
                                                } else {
                                                    setDomainDropDown(true);
                                                }
                                            }}
                                        >
                                            Domain:{" "}
                                            <span className={styles.domainName}>
                                                {!activeDomain?.domain_name ? (
                                                    <Skeleton width={"100px"} />
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.domainNameLink
                                                        }
                                                    >
                                                        {getTrimmedDomainURL(
                                                            activeDomain?.domain_name,
                                                        )}{" "}
                                                    </div>
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginLeft: "5px",
                                                }}
                                            >
                                                {(inventoryPagesMax !=
                                                    inventoryPagesProcessed ||
                                                    isQuotaOver) &&
                                                    !isLTD &&
                                                    !isShimmer &&
                                                    getDomainStatus(
                                                        plan_category ==
                                                            PLANS_CATEGORIES.PRO ||
                                                            PLANS_CATEGORIES.PRO_MAX ||
                                                            isLTD
                                                            ? true
                                                            : false,
                                                        activeDomain?.status,
                                                        17,
                                                    )}
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginLeft: "5px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setDomainDropDown(true);
                                                }}
                                            >
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/chevronDown.svg"
                                                    }
                                                    size={12}
                                                    style={{
                                                        color: "#3f5575",
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        {domainDropDown &&
                                            selectedDomains?.length > 0 && (
                                                <div
                                                    className={
                                                        styles.domainList
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.domainListItems
                                                        }
                                                    >
                                                        {selectedDomains?.map(
                                                            (
                                                                dm: DomainObject,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            styles.domainItem
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                (dm?.re_auth ==
                                                                                    1 ||
                                                                                    dm?.is_disconnect ==
                                                                                        1) &&
                                                                                isTeamMember
                                                                            ) {
                                                                                return;
                                                                            }
                                                                            if (
                                                                                (dm?.re_auth ==
                                                                                    1 ||
                                                                                    dm?.is_disconnect ==
                                                                                        1) &&
                                                                                !isTeamMember
                                                                            ) {
                                                                                taTrackDomainsCountry(
                                                                                    {
                                                                                        tabName:
                                                                                            activeTabName,
                                                                                        cta: "Selection (Manage)",
                                                                                        type: "Domain",
                                                                                    },
                                                                                );
                                                                                setIsManageDomains(
                                                                                    true,
                                                                                );
                                                                            } else {
                                                                                taTrackDomainsCountry(
                                                                                    {
                                                                                        tabName:
                                                                                            activeTabName,
                                                                                        cta: "Selection",
                                                                                        type: "Domain",
                                                                                    },
                                                                                );
                                                                                setActiveDomain(
                                                                                    dm,
                                                                                );
                                                                                handleActiveCountryDomain(
                                                                                    {},
                                                                                    dm,
                                                                                );
                                                                            }

                                                                            setDomainDropDown(
                                                                                false,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "center",
                                                                                alignItems:
                                                                                    "center",
                                                                                gap: "5px",
                                                                                width: "max-content",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                                                    dm?.domain_name?.split(
                                                                                        ":",
                                                                                    )[1]
                                                                                }&sz=32`}
                                                                                width={
                                                                                    "24px"
                                                                                }
                                                                            />
                                                                            {getTrimmedDomainURL(
                                                                                dm.domain_name,
                                                                            )}
                                                                        </span>
                                                                        {dm.is_disconnect ==
                                                                        1 ? (
                                                                            <LightTooltip
                                                                                title={
                                                                                    isTeamMember
                                                                                        ? "Please reach out to your administrator to  re-authorize your domain(s) to access Traffic Analyzer."
                                                                                        : "Re-Authorize for SEO Insights"
                                                                                }
                                                                                placement={
                                                                                    "top"
                                                                                }
                                                                                arrow
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "center",
                                                                                        alignItems:
                                                                                            "center",
                                                                                    }}
                                                                                >
                                                                                    <SVGIcon
                                                                                        src={
                                                                                            "/New UI/SVG/triangle_exclamation-fill.svg"
                                                                                        }
                                                                                        style={{
                                                                                            color: "#fc5a5a",
                                                                                        }}
                                                                                        size={
                                                                                            16
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </LightTooltip>
                                                                        ) : dm?.re_auth ==
                                                                          1 ? (
                                                                            <LightTooltip
                                                                                title={
                                                                                    isTeamMember
                                                                                        ? "Please reach out to your administrator to  reconnect your domain(s) to access Traffic Analyzer."
                                                                                        : "Reconnect for SEO Insights"
                                                                                }
                                                                                placement={
                                                                                    "top"
                                                                                }
                                                                                arrow
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "center",
                                                                                        alignItems:
                                                                                            "center",
                                                                                    }}
                                                                                >
                                                                                    <SVGIcon
                                                                                        src={
                                                                                            "/New UI/SVG/triangle_exclamation-fill.svg"
                                                                                        }
                                                                                        style={{
                                                                                            color: "#fc5a5a",
                                                                                        }}
                                                                                        size={
                                                                                            16
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </LightTooltip>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                    {!isTeamMember && (
                                                        <div
                                                            className={
                                                                styles.manageCountryCta
                                                            }
                                                            onClick={() => {
                                                                taTrackDomainsCountry(
                                                                    {
                                                                        tabName:
                                                                            activeTabName,
                                                                        cta: "Manage Domains",
                                                                        type: "Domain",
                                                                    },
                                                                );
                                                                setIsManageDomains(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            Manage Domains
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                </ClickAwayListener>

                                <ClickAwayListener
                                    onClickAway={() => {
                                        setCountryDropDown(false);
                                    }}
                                >
                                    <div
                                        className={
                                            styles.dropDownCountryWrapper
                                        }
                                    >
                                        <div
                                            className={styles.locationCol}
                                            onClick={() => {
                                                if (isShimmer) {
                                                } else {
                                                    setCountryDropDown(true);
                                                }
                                            }}
                                        >
                                            {isShimmer ? (
                                                <Skeleton width={"50px"} />
                                            ) : (
                                                <>
                                                    <div
                                                        className={
                                                            styles.selectableCountries
                                                        }
                                                    >
                                                        <img
                                                            src={`https://flagcdn.com/w20/${activeCountry?.flagCode?.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${activeCountry?.flagCode?.toLowerCase()}.png 2x`}
                                                            style={{
                                                                width: "24px",
                                                            }}
                                                        />
                                                        {activeCountry?.country?.toUpperCase()}
                                                    </div>
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/chevronDown.svg"
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                        {countryDropDown &&
                                            selectedCountries?.length > 0 && (
                                                <div
                                                    className={
                                                        styles.countryList
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.countryListItems
                                                        }
                                                    >
                                                        {oldSelectedCountries?.map(
                                                            (cn) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            styles.countryItem
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                activeCountry?.country !=
                                                                                cn?.country
                                                                            ) {
                                                                                taTrackDomainsCountry(
                                                                                    {
                                                                                        tabName:
                                                                                            activeTabName,
                                                                                        cta: "Selection",
                                                                                        type: "Country",
                                                                                    },
                                                                                );
                                                                                handleActiveCountryDomain(
                                                                                    cn,
                                                                                    activeDomain,
                                                                                );
                                                                            }
                                                                            setCountryDropDown(
                                                                                false,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={`https://flagcdn.com/w20/${cn?.flagCode?.toLowerCase()}.png`}
                                                                            srcSet={`https://flagcdn.com/w40/${cn?.flagCode?.toLowerCase()}.png 2x`}
                                                                        />{" "}
                                                                        {cn.country?.toUpperCase()}
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                    {!isTeamMember && (
                                                        <div
                                                            className={
                                                                styles.manageCountryCta
                                                            }
                                                            onClick={() => {
                                                                taTrackDomainsCountry(
                                                                    {
                                                                        tabName:
                                                                            activeTabName,
                                                                        cta: "Manage Countries",
                                                                        type: "Country",
                                                                    },
                                                                );
                                                                setCountryDropDown(
                                                                    false,
                                                                );
                                                                setIsManageCountry(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            Manage Countries
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* tabs */}
            <div className={styles.tabsContainer}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    classes={classesTabs}
                    value={value}
                    onChange={handleChange}
                >
                    <Tab
                        disableRipple={true}
                        classes={classesTab}
                        disabled={isQuotaOver}
                        label={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <SVGIcon
                                    src={"/New UI/SVG/insights-icon.svg"}
                                    size={20}
                                    style={{
                                        color:
                                            value == 0 ? "#014dc5" : "#3F5575",
                                    }}
                                />
                                Insights
                            </div>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        classes={classesTab}
                        disabled={
                            LockConfigurator(FEATURE_TYPES.inventoryLock) ||
                            isQuotaOver
                        }
                        label={
                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                        position: "relative",
                                        color: LockConfigurator(
                                            FEATURE_TYPES.inventoryLock,
                                        )
                                            ? "#acb3bf"
                                            : "inherit",
                                    }}
                                >
                                    <SVGIcon
                                        src={"/New UI/SVG/folder.svg"}
                                        style={{
                                            color: LockConfigurator(
                                                FEATURE_TYPES.inventoryLock,
                                            )
                                                ? "#acb3bf"
                                                : value == 1
                                                ? "#014dc5"
                                                : "#3F5575",
                                        }}
                                        size={20}
                                    />
                                    Inventory
                                </div>
                                {LockConfigurator(
                                    FEATURE_TYPES.inventoryLock,
                                ) && (
                                    <div className={styles.lockIcon}>
                                        <RiLock2Fill
                                            className={styles.RiLock2Fill}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                        {...a11yProps(1)}
                    />
                    <Tab
                        classes={classesTab}
                        disabled={isCannibsLocked || isQuotaOver}
                        label={
                            <div
                                onClick={() => {
                                    mixpanelTrack("Cannibalization");
                                }}
                                style={{ display: "flex" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                        position: "relative",
                                        color: isCannibsLocked
                                            ? "#acb3bf"
                                            : "inherit",
                                    }}
                                >
                                    <SVGIcon
                                        src={
                                            "/New UI/SVG/cannibalization_icon.svg"
                                        }
                                        size={20}
                                        style={{
                                            color: isCannibsLocked
                                                ? "#acb3bf"
                                                : value == 2
                                                ? "#014dc5"
                                                : "#3F5575",
                                        }}
                                    />
                                    Cannibalization
                                </div>
                                <div className={styles.NewTag}>
                                    <span className={styles.NewTag_Text}>
                                        New
                                    </span>
                                </div>
                                {isCannibsLocked && (
                                    <div className={styles.lockIconCannibs}>
                                        <RiLock2Fill
                                            className={styles.RiLock2Fill}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                        {...a11yProps(2)}
                    />
                </Tabs>

                <div className={styles.nextRefreshWrapper}>
                    {otherInfoV2.next_refresh_date.length > 0 && (
                        <div className={styles.next_refresh_at}>
                            <SVGIcon
                                src={"/New UI/SVG/timer-next.svg"}
                                size={14}
                                style={{ color: "#ACB3BF" }}
                            />
                            Next Refresh in{" "}
                            <span className={styles.daysAgo}>
                                {getRemainingTime(
                                    otherInfoV2.next_refresh_date,
                                )}
                            </span>
                        </div>
                    )}
                    {otherInfoV2.next_refresh_date.length > 0 && (
                        <LightTooltip
                            onOpen={(e: any) => {
                                if (!otherInfoV2?.startDate) {
                                    setImmediate(() => {
                                        document
                                            .querySelectorAll("[role=tooltip]")
                                            .forEach(
                                                (e: any) =>
                                                    (e.style.display = "none"),
                                            );
                                    });
                                }
                            }}
                            title={""}
                            placement={"bottom"}
                            arrow
                        >
                            <div className={styles.dataDaysCount}>
                                {`${moment(otherInfoV2?.startDate).format(
                                    "DD MMM YYYY",
                                )} - ${moment(otherInfoV2?.endDate).format(
                                    "DD MMM YYYY",
                                )} `}
                            </div>
                        </LightTooltip>
                    )}
                </div>
            </div>
        </div>
    );
};

const TrafficAnalyzer = ({
    setIsShowData,
    isShowData,
    value,
    setValue,
    selectedDomains,
    setSelectedDomains,
    activeDomain,
    setActiveDomain,
    setIsLoggedIn,
    setShowGscLogout,
    getDetails,
}: Props_TrafficAnalyzer) => {
    const { getDomainStatus, parseCountries } = useGscHook();
    const [isShowNextRefresh, setIsShowNextRefresh] = useState<boolean>(false);
    const [isRefreshAllowed, setIsRefreshAllowed] = useState<boolean>(false);
    const [isShimmerInsights, setIsShimmerInsights] = useState(false);
    const [isShimmerV2, setIsShimmerV2] = useState(false);
    const [isShimmerCannibs, setIsShimmerCannibs] = useState(false);
    const [cannibsFilterShimmer, setCannibsFilterShimmer] = useState(false);
    const [isProcessingCannibs, setIsProcessingCannibs] = useState(false);
    const [isProcessingInsights, setIsProcessingInsights] = useState(false);
    const [apiCall, setApiCall] = useState(0);
    const [isProcessingV2, setIsProcessingV2] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const userEmail = useSelector((state: any) => state.auth.email);
    const { subscriptionInfo } = useSelector((state: any) => state.otherInfo);
    const [isCannibsLocked, setIsCannibsLocked] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isQuotaOver, setIsQuotaOver] = useState(false);
    const [isManageCountry, setIsManageCountry] = useState(false);
    const [isManageDomains, setIsManageDomains] = useState(false);
    const { LockConfigurator } = useUserAccessHook();
    const dispatch = useDispatch();
    const [isOpenDefaultInventory, setIsOpenDefaultInventory] =
        useState<boolean>(false);
    const [isOpenDefaultCannibs, setIsOpenDefaultCannibs] =
        useState<boolean>(false);
    const [recommendationDataV2, setRecommendationDataV2] = useState<
        InventoryV2Object[]
    >([]);
    const [cannibalizationData, setCannibalizationData] = useState<
        CannibalizationKeywordObject[]
    >([]);
    const [cannibalizationOtherInfo, setCannibalizationOtherInfo] =
        useState<CannibalizationOtherInfoObject>({});
    const [hasSatisfactionScore, setHasSatisfactionScore] = useState(true);
    const [checkSatisfaction, setCheckSatisfaction] = useState(false);
    const [closeSatisfaction, setCloseSatisfaction] = useState(true);
    const [nonProcessedPages, setNonProcessedPages] = useState<string[]>([]);
    const [activeCountry, setActiveCountry] = useState<CountryObject>();
    const [oldSelectedCountries, setOldSelectedCountries] = useState<any[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [otherInfoV2, setOtherInfoV2] = useState<{
        next_refresh: number;
        next_refresh_date: string;
        last_refresh: number;
        last_refresh_date: string;
        startDate: string;
        endDate: string;
    }>({
        next_refresh: 0,
        last_refresh: 0,
        startDate: "",
        endDate: "",
        next_refresh_date: "",
        last_refresh_date: "",
    });
    const [gscInsightsData, setGscInsightsData] =
        useState<GscInsightsDataObject>({
            organicCTR: {},
            countries_info: [],
            devices_info: {},
            positionInfo: {},
            lost_pages_info: [],
            next_refresh_at: "",
            status: 0,
        });
    const [isLoading, setIsLoading] = useState(false);
    const { cannibalization_filters } = useSelector(
        (state: any) => state.userGscInfo,
    );
    const {
        getFilteredGraphData,
        getTrendsDashed,
        getMappedValue,
        getAvgRank,
        getMappedStringTA,
        lastNonZeroValue,
        getTrimmedDomainURL,
        getTrendsLabel,
        getRandomTrend,
    } = useGscHook();

    const { taTrackDataLoadTime } = useMixpanelTA();

    const { postData, getData } = useHelperFunctions();
    useEffect(() => {
        if (!isRefreshAllowed) {
            setTimeout(() => {
                setIsRefreshAllowed(true);
            }, 10000);
        }
    }, [isRefreshAllowed]);

    useEffect(() => {
        //@ts-ignore
        clearTimeout(timeInterval);
        if (!hasSatisfactionScore && !checkSatisfaction) {
            timeInterval = setTimeout(() => {
                setCloseSatisfaction(false);
            }, 5000);
        }
    }, [hasSatisfactionScore, checkSatisfaction]);

    useEffect(() => {
        if (!isManageCountry) {
            setSelectedCountries(oldSelectedCountries);
        }
    }, [isManageCountry]);

    useEffect(() => {
        if (recommendationDataV2.length <= 0 && value == 1 && !isShimmerV2) {
            getToneDownedInventory(activeCountry, activeDomain);
        } else if (
            value == 0 &&
            gscInsightsData?.status != 1 &&
            !isShimmerInsights
        ) {
            getInsightsDetails(activeCountry, activeDomain);
        }
    }, [value]);

    useEffect(() => {
        if (subscriptionInfo?.plan_category) {
            if (!LockConfigurator(FEATURE_TYPES.cannibalizationLock)) {
                setIsCannibsLocked(false);
            } else {
                setIsCannibsLocked(true);
            }
        }
    }, [subscriptionInfo?.plan_category]);

    const syncDomainsEdit: (obj: {
        isAdded?: boolean;
        isDeleted?: boolean;
        dmObj?: { is_selected?: 1 | 0; domain_name: string };
    }) => void = ({
        isAdded = false,
        isDeleted = false,
        dmObj = { domain_name: "" },
    }) => {
        let temp = [...selectedDomains];
        if (isDeleted) {
            let updateActive = dmObj.domain_name == activeDomain?.domain_name;
            temp = temp.filter((itm) => itm?.domain_name != dmObj.domain_name);
            if (updateActive && temp.length > 0) {
                setActiveDomain(temp[0]);
                getToneDownedInventory(null, temp[0]);
                getInsightsDetails(null, temp[0]);
                getCannibalizationData(cannibalization_filters, null, temp[0]);
            }
        } else if (isAdded) {
            if (temp.length == 0) {
                temp.push({
                    domain_name: dmObj.domain_name,
                    is_selected: 1,
                    status: undefined,
                });
                dispatch(resetUserGscInfoMonthlyInsights({}));
            } else {
                temp.push({
                    domain_name: dmObj.domain_name,
                    is_selected: 0,
                    status: undefined,
                });
            }
        }
        if (temp.length == 0) {
            setSelectedDomains([]);
            setIsShowData(false);
            setIsLoggedIn(false);
            dispatch(resetUserGscInfoMonthlyInsights({}));
        }
        // @ts-ignore
        setSelectedDomains([...temp]);
    };

    const checkProcessing: (
        handler: Dispatch<SetStateAction<boolean>>,
        country?: CountryObject | null,
    ) => void = async (handler = () => {}, country = null) => {
        let payload = {
            ...(country?.country?.length
                ? { country: [country?.country] }
                : {}),
        };
        const res = await postData({
            url: "/api/analyse/traffic-analyser-status",
            payload,
        });
        if (res.status == 200) {
            if (res.data.status == 1) {
                handler(true);
            } else {
                handler(false);
            }
        } else {
            handler(true);
        }
    };

    const getToneDownedInventory: (
        country?: CountryObject | null,
        _activeDomain?: any,
        setOld?: boolean,
    ) => void = async (country = null, _activeDomain = {}, setOld = true) => {
        checkProcessing(setIsProcessingV2, country);
        setIsShimmerV2(true);
        setIsShowData(true);
        setCheckSatisfaction(true);
        setHasSatisfactionScore(true);
        let initialTime = performance.now();
        let payload = {
            ...(_activeDomain?.domain_name
                ? {
                      domain_name: _activeDomain?.domain_name,
                  }
                : {}),
            ...(country?.country &&
                country?.country?.length > 0 && {
                    country: [country?.country],
                    search_country: country?.label,
                }),
        };
        const response = await postData({
            url: `/api/analyse/post-traffic-analyser-v2/`,
            payload: payload,
        });
        if (response.status == 200) {
            setCheckSatisfaction(false);
            setHasSatisfactionScore(response.data?.hasSatisfactionScore);
            setIsProcessingV2(false);
            let endTime = performance.now();
            const totalTimeTaken = (endTime - initialTime) / 1000;
            let mpObject: any = {
                tabName: TabsName.inventory,
                timeTaken: totalTimeTaken || 0,
                nextRefresh: response?.data?.nextRefreshDate || "-",
                lastRefresh: response?.data?.lastRefreshDate || "-",
                totalPages: response?.data?.total_pages || 0,
                qExhausted: response?.data.hasOwnProperty("quota")
                    ? "Yes"
                    : "No",
                processedPages: 0,
            };
            let processedLength = 0;
            if (response?.data) {
                if (response?.data?.data) {
                    const buffer = response.data?.data?.data;
                    const uint8Array = new Uint8Array(buffer);

                    const inflatedData = inflate(uint8Array);

                    const decompressedString = new TextDecoder().decode(
                        inflatedData as any,
                    );

                    const data = JSON.parse(decompressedString);
                    mpObject.processedPages = data.length;
                    taTrackDataLoadTime({ ...mpObject });
                    setRecommendationDataV2(() => {
                        console.log("inventory data", data);
                        if (response?.data?.domainInfo?.domain_name) {
                            const index = GSC_Countries.findIndex((item) =>
                                response?.data?.domainInfo?.country?.includes(
                                    item.code,
                                ),
                            );
                        }
                        let trendsDateLabel = getTrendsLabel(
                            response.data.weeksDateList,
                        ).reverse();
                        let temp = data.map((item: InventoryV2Object) => {
                            let volume = 0;
                            item.avg_position_trend =
                                item.avg_position_trend.map((item) =>
                                    Math.round(item),
                                );
                            let temp3 = [...item.avg_position_trend];
                            let KeywordTrendsStatus = 0;
                            let PageTrendsStatus = 0;
                            if (item.avg_position_trend?.length > 0) {
                                PageTrendsStatus = Math.floor(
                                    Math.abs(
                                        item.avg_position_trend[0] -
                                            item.avg_position_trend[
                                                item.avg_position_trend
                                                    ?.length - 1
                                            ],
                                    ),
                                );
                            }
                            item.keywords.forEach(
                                (keyword: InventoryKeywordObject) => {
                                    volume = keyword.volume + volume;
                                },
                            );
                            item.keywords = item.keywords.map(
                                (item: InventoryKeywordObject) => {
                                    item.position_list = item.position_list.map(
                                        (item) => Math.round(item),
                                    );
                                    let temp2 = [...item.position_list];
                                    if (item.position_list?.length > 0) {
                                        KeywordTrendsStatus = Math.floor(
                                            Math.abs(
                                                item.position_list[0] -
                                                    item.position_list[
                                                        item.position_list
                                                            ?.length - 1
                                                    ],
                                            ),
                                        );
                                    }
                                    return {
                                        ...item,
                                        position_list: getFilteredGraphData(
                                            item.position_list,
                                        ).reverse(),
                                        avg_position: getAvgRank(
                                            item.position_list,
                                        ),
                                        dashedPosition:
                                            getTrendsDashed(temp2).reverse(),
                                        classificationColor: getMappedValue(
                                            item.position_classification?.toLowerCase(),
                                        ),
                                        trendsStatus: KeywordTrendsStatus,
                                        trendsDateLabel: trendsDateLabel,
                                    };
                                },
                            );
                            return {
                                ...item,
                                volume,
                                avg_position_trend: getFilteredGraphData(
                                    item.avg_position_trend,
                                ).reverse(),
                                dashedPosition:
                                    getTrendsDashed(temp3).reverse(),
                                color: getMappedValue(
                                    item.avg_position_classification?.toLowerCase(),
                                ),
                                trendsDateLabel: trendsDateLabel,
                                trendsStatus: PageTrendsStatus,
                            };
                        });
                        return temp;
                    });
                } else {
                    setRecommendationDataV2([]);
                }
                if (response?.data.hasOwnProperty("quota")) {
                    setIsQuotaOver(true);
                } else {
                    setIsQuotaOver(false);
                }
                console.log("mpObject Inventory", processedLength);
                setNonProcessedPages(
                    response?.data?.unprocessedPages?.length
                        ? response?.data?.unprocessedPages.map((pg: any) => {
                              let luck = Math.floor(Math.random() * 4);
                              return {
                                  dummy: true,
                                  avg_clicks_trend: [],
                                  avg_difficulty:
                                      Math.floor(Math.random() * 99) + 1,
                                  keywords: [],
                                  total_keywords_count:
                                      Math.floor(Math.random() * 499) + 1,
                                  delta_keywords_count:
                                      Math.floor(Math.random() * (50 + 50)) -
                                      50,
                                  avg_position:
                                      Math.floor(Math.random() * 20) + 1,
                                  page: pg,
                                  total_traffic:
                                      Math.floor(Math.random() * 50) + 1,
                                  traffic_share:
                                      Math.floor(Math.random() * 50) + 1,
                                  impressions: [],
                                  volume: Math.floor(Math.random() * 10000) + 1,
                                  avg_rank: [],
                                  id: null,
                                  total_impressions:
                                      Math.floor(Math.random() * 10000) + 1,

                                  delta_traffic:
                                      Math.floor(Math.random() * (50 + 50)) -
                                      50,
                                  page_filter: null,
                                  avg_position_trend: getRandomTrend(
                                      luck,
                                      "arr",
                                  ),
                                  dashedPosition: [],
                                  color: getRandomTrend(luck, "color"),
                                  trendsStatus: null,
                                  trendsDateLabel: [
                                      "1,",
                                      "2",
                                      "3",
                                      "4",
                                      "5,",
                                      "6",
                                      "7",
                                      "8",
                                  ],
                              };
                          })
                        : [],
                );

                setOtherInfoV2({
                    next_refresh: response?.data?.next_refresh_date || 0,
                    next_refresh_date: response?.data?.nextRefreshDate || "",
                    last_refresh: response?.data?.last_refresh_date || 0,
                    last_refresh_date: response?.data?.lastRefreshDate || "",
                    startDate: response.data?.startDate || "",
                    endDate: response.data?.endDate || "",
                });
                setActiveDomain(
                    response.data?.domainInfo?.domain_name.length > 0 &&
                        response.data?.domainInfo?.domain_name.filter(
                            (dm: DomainObject) => dm?.is_selected == 1,
                        )[0],
                );
                setSelectedDomains(
                    response.data?.domainInfo?.domain_name.length > 0
                        ? response.data?.domainInfo?.domain_name
                        : [],
                );
                if (response.data.hasOwnProperty("total_pages")) {
                    setTotalPages(response.data.total_pages || 0);
                }
                setSelectedCountries(
                    parseCountries(
                        response.data?.domainInfo?.active_countries_info,
                    ) || [],
                );
                if (setOld) {
                    setOldSelectedCountries(
                        parseCountries(
                            response.data?.domainInfo?.active_countries_info,
                        ) || [],
                    );
                    dispatch(
                        setUserGscInfo({
                            active_countries_info:
                                parseCountries(
                                    response.data?.domainInfo
                                        ?.active_countries_info,
                                ) || [],
                            connectedDomains: response.data?.domainInfo
                                ?.domain_name || [
                                {
                                    domain_name: "dummy:whalenut.com",
                                    is_selected: 1,
                                    status: "Yellow",
                                },
                            ],
                        }),
                    );
                    setActiveCountry(
                        parseCountries(
                            response.data?.domainInfo?.active_countries_info,
                        ).filter((item) => item?.is_selected == 1)[0] || {},
                    );
                }
                setIsShowData(true);
                setIsShimmerV2(false);

                return;
            } else if (response.data.status == 2) {
                setIsShowData(true);
                setIsFailed(true);
                setIsShowData(true);
            } else if (response?.data?.data == null) {
                setIsShowData(true);
                setIsShimmerV2(false);
            }
        } else {
            setIsShowData(true);
            setIsFailed(true);
            if (
                response?.response?.data?.message == "Domain list is empty" ||
                response?.response?.data?.message ==
                    "Domain is not Present in list" ||
                response?.response?.data?.message ==
                    "Insufficient Permission for Domain"
            ) {
                if (selectedDomains?.length <= 1) {
                    setShowGscLogout(true);
                } else {
                    dispatch(flushUserGscInfo());
                    getDetails(true);
                }
            }
        }
    };

    const getInsightsDetails: (
        country?: CountryObject | null,
        _activeDomain?: any,
        setOld?: boolean,
    ) => void = async (country = null, _activeDomain = {}, setOld = true) => {
        checkProcessing(setIsProcessingInsights, country);

        setIsShowData(true);
        setIsShimmerInsights(true);
        let initialTime = performance.now();
        setCheckSatisfaction(true);
        setHasSatisfactionScore(true);

        const res = await postData({
            url: `/api/analyse/post-insights-v2`,
            payload: {
                ...(country?.country && country?.country?.length > 0
                    ? { country: [country?.country] }
                    : {}),
                ...(_activeDomain?.domain_name
                    ? {
                          domain_name: _activeDomain?.domain_name,
                      }
                    : {}),
            },
        });
        if (res.status == 200) {
            const endTime = performance.now();
            const totalTimeTaken = (endTime - initialTime) / 1000;
            let mpObject: any = {
                tabName: TabsName.insights,
                timeTaken: totalTimeTaken || 0,
                nextRefresh: res?.data?.nextRefreshDate || "-",
                lastRefresh: res?.data?.lastRefreshDate || "-",
            };
            setCheckSatisfaction(false);
            setHasSatisfactionScore(res?.data?.hasSatisfactionScore);
            setIsProcessingInsights(false);
            if (res.data.status == 0) {
                setIsShowData(false);
            } else if (res.data.status == 2) {
                setIsShowData(false);
                setIsFailed(true);
            } else if (res.data) {
                setIsQuotaOver(
                    res?.data.hasOwnProperty("quota") ? true : false,
                );

                if (res.data?.data?.data) {
                    const buffer = res.data?.data?.data;
                    const uint8Array = new Uint8Array(buffer);

                    const inflatedData = inflate(uint8Array);

                    const decompressedString = new TextDecoder().decode(
                        inflatedData as any,
                    );

                    const data = JSON.parse(decompressedString);

                    setGscInsightsData({ ...data, status: 1 });
                    dispatch(
                        setUserGscInfoMonthlyInsights({
                            ...data.monthly_insights_info,
                            color: data.monthly_insights_info
                                ?.clicks_trend_classification,
                            activeDomain:
                                res.data?.domainInfo?.domain_name.length > 0 &&
                                res.data?.domainInfo?.domain_name.filter(
                                    (dm: DomainObject) => dm?.is_selected == 1,
                                )[0].domain_name,
                        }),
                    );
                }

                setIsShowData(true);
                setIsFailed(false);
                setOtherInfoV2({
                    next_refresh: res?.data?.next_refresh_date || 0,
                    next_refresh_date: res?.data?.nextRefreshDate || 0,
                    last_refresh: res?.data?.last_refresh_date || 0,
                    last_refresh_date: res?.data?.lastRefreshDate || "",
                    startDate: res.data?.startDate || "",
                    endDate: res.data?.endDate || "",
                });
                setActiveDomain(
                    res.data?.domainInfo?.domain_name.length > 0 &&
                        res.data?.domainInfo?.domain_name.filter(
                            (dm: DomainObject) => dm?.is_selected == 1,
                        )[0],
                );
                setSelectedDomains(
                    res.data?.domainInfo?.domain_name.length > 0
                        ? res.data?.domainInfo?.domain_name
                        : [],
                );
                setSelectedCountries(
                    parseCountries(
                        res.data?.domainInfo?.active_countries_info,
                    ) || [],
                );
                if (setOld) {
                    setOldSelectedCountries(
                        parseCountries(
                            res.data?.domainInfo?.active_countries_info,
                        ) || [],
                    );
                    dispatch(
                        setUserGscInfo({
                            active_countries_info:
                                parseCountries(
                                    res.data?.domainInfo?.active_countries_info,
                                ) || [],
                            connectedDomains:
                                res.data?.domainInfo?.domain_name || [],
                        }),
                    );
                    setActiveCountry(
                        parseCountries(
                            res.data?.domainInfo?.active_countries_info,
                        ).filter((item) => item?.is_selected == 1)[0] || [],
                    );
                }
                setIsShimmerInsights(false);
            } else {
                setGscInsightsData({
                    organicCTR: {},
                    countries_info: [],
                    devices_info: {},
                    positionInfo: {},
                    lost_pages_info: [],
                    next_refresh_at: "",
                    status: 0,
                });
                setIsShowData(true);
                setIsShimmerInsights(false);
            }
            taTrackDataLoadTime(mpObject);
            setIsProcessingInsights(false);
        } else {
            if (
                res?.response?.data?.message == "Domain list is empty" ||
                res?.response?.data?.message ==
                    "Domain is not Present in list" ||
                res?.response?.data?.message ==
                    "Insufficient Permission for Domain"
            ) {
                if (selectedDomains?.length <= 1) {
                    setShowGscLogout(true);
                } else {
                    dispatch(flushUserGscInfo());
                    getDetails(true);
                }
            }
        }
    };

    const getCannibalizationData: (
        requestedFilters: CannibalizationRequestPayload,
        country?: CountryObject | null,
        _activeDomain?: any,
        setOld?: boolean,
    ) => void = async (
        requestedFilters,
        country = null,
        _activeDomain = {},
        setOld = true,
    ) => {
        setApiCall(1);
        checkProcessing(setIsProcessingCannibs, country);

        if (initialLoad) {
            setCannibsFilterShimmer(true);
            initialLoad = false;
        }
        setIsShimmerCannibs(true);
        const initialTime = performance.now();
        let payload = {
            ...(country?.country && country?.country?.length > 0
                ? { country: [country?.country] }
                : {}),
            ...(_activeDomain?.domain_name
                ? {
                      domain_name: _activeDomain?.domain_name,
                  }
                : {}),
            ...requestedFilters,
        };
        setCheckSatisfaction(true);
        setHasSatisfactionScore(true);
        const res = await postData({
            url: "/api/analyse/post-traffic-analyser-cannibalization",
            payload,
        });

        if (res.status == 200) {
            setCheckSatisfaction(false);
            setHasSatisfactionScore(res.data?.hasSatisfactionScore);
            const endTime = performance.now();
            const totalTimeTaken = (endTime - initialTime) / 1000;
            setIsQuotaOver(res?.data.hasOwnProperty("quota") ? true : false);
            let mpObject: any = {
                tabName: TabsName.cannibalization,
                timeTaken: totalTimeTaken || 0,
                nextRefresh: res?.data?.nextRefreshDate || "-",
                lastRefresh: res?.data?.lastRefreshDate || "-",
                qExhausted: res?.data.hasOwnProperty("quota") ? "Yes" : "No",
            };
            if (res.data?.data?.data) {
                setCannibsFilterShimmer(false);
                const buffer = res.data?.data?.data;
                const uint8Array = new Uint8Array(buffer);

                const inflatedData = inflate(uint8Array);

                const decompressedString = new TextDecoder().decode(
                    inflatedData as any,
                );

                const data = JSON.parse(decompressedString);

                console.log("canni", data);
                setCannibalizationData([...data?.cannibalizationKeywords]);
                setCannibalizationOtherInfo({
                    ...data?.overallCannibalization,
                });

                setOtherInfoV2({
                    next_refresh: res?.data?.next_refresh_date || 0,
                    next_refresh_date: res?.data?.nextRefreshDate || 0,
                    last_refresh: res?.data?.last_refresh_date || 0,
                    last_refresh_date: res?.data?.lastRefreshDate || "",
                    startDate: res.data?.startDate || "",
                    endDate: res.data?.endDate || "",
                });
                setActiveDomain(
                    res.data?.domainInfo?.domain_name.length > 0 &&
                        res.data?.domainInfo?.domain_name.filter(
                            (dm: DomainObject) => dm?.is_selected == 1,
                        )[0],
                );
                setSelectedDomains(
                    res.data?.domainInfo?.domain_name.length > 0
                        ? res.data?.domainInfo?.domain_name
                        : [],
                );
                setSelectedCountries(
                    parseCountries(
                        res.data?.domainInfo?.active_countries_info,
                    ) || [],
                );
                if (setOld) {
                    setOldSelectedCountries(
                        parseCountries(
                            res.data?.domainInfo?.active_countries_info,
                        ) || [],
                    );
                    dispatch(
                        setUserGscInfo({
                            active_countries_info:
                                parseCountries(
                                    res.data?.domainInfo?.active_countries_info,
                                ) || [],
                            connectedDomains:
                                res.data?.domainInfo?.domain_name || [],
                        }),
                    );
                    setActiveCountry(
                        parseCountries(
                            res.data?.domainInfo?.active_countries_info,
                        ).filter((item) => item?.is_selected == 1)[0] || [],
                    );
                }
                setIsShimmerCannibs(false);
            }
            taTrackDataLoadTime(mpObject);
        } else {
            setCannibalizationData([]);
            setIsShimmerCannibs(false);
            setCannibsFilterShimmer(false);
            if (
                res?.response?.data?.message == "Domain list is empty" ||
                res?.response?.data?.message ==
                    "Domain is not Present in list" ||
                res?.response?.data?.message ==
                    "Insufficient Permission for Domain"
            ) {
                if (selectedDomains?.length <= 1) {
                    setShowGscLogout(true);
                } else {
                    dispatch(flushUserGscInfo());
                    getDetails(true);
                }
            }
        }

        setTimeout(() => {
            setApiCall(0);
        }, 300);
        setIsProcessingCannibs(false);
        setCannibsFilterShimmer(false);
    };

    const memoRequest = useMemo(() => {
        if (apiCall == 0 && value == 2) {
            cannibalization_filters &&
                getCannibalizationData &&
                getCannibalizationData(
                    cannibalization_filters,
                    activeCountry,
                    activeDomain,
                );
        }
    }, [cannibalization_filters, value]);

    const handleRefresh: () => void = async () => {
        setIsRefreshing(true);
        await getToneDownedInventory();
        setTimeout(() => {
            setIsRefreshing(false);
            setIsRefreshAllowed(false);
        }, 600);
    };

    const updateSelectedCountry: (newCountries: any[]) => void = async (
        newCountries = [],
    ) => {
        if (newCountries.length == 0) {
            return;
        }
        newCountries = newCountries.map((item) => ({
            ...item,
            type: 1,
        }));
        oldSelectedCountries.forEach((oldCn) => {
            let idxInNewArr = newCountries.findIndex(
                (newCn) => newCn.value == oldCn.value,
            );
            if (idxInNewArr < 0) {
                newCountries.push({ ...oldCn, type: 0 });
            } else {
                newCountries[idxInNewArr].type = 1;
            }
        });
        const res = await postData({
            url: "/api/analyse/updateCountryInfo/",
            payload: {
                updatedCountriesInfo: newCountries.map((item, idx) => ({
                    type: item?.type == 0 ? 0 : 1,
                    country: item.country ? item.country : item?.code,
                    is_selected: item?.type == 1 && idx == 0 ? 1 : 0,
                })),
            },
        });
        if (res.status == 200) {
            let newData: any = newCountries.map((item, idx) => {
                return item?.type == 1
                    ? {
                          type: item?.type == 0 ? 0 : 1,
                          country: item.country ? item.country : item?.code,
                          flagCode: item.flagCode,
                          label: item.label,
                          is_selected: idx == 0 ? 1 : 0,
                          value: item.flagCode,
                      }
                    : null;
            });
            newData = newData.filter((item: any) =>
                item?.value ? true : false,
            );
            setOldSelectedCountries(newData);
            dispatch(
                setUserGscInfo({
                    active_countries_info: newData,
                }),
            );
            setSelectedCountries(newData);
            if (newData[0]?.country != activeCountry?.country) {
                setActiveCountry(newData[0]);
                if (value == 0) {
                    getInsightsDetails(newData[0], activeDomain, false);
                } else if (value == 1) {
                    getToneDownedInventory(newData[0], activeDomain, false);
                } else {
                    getCannibalizationData(
                        cannibalization_filters,
                        newData[0],
                        activeDomain,
                        false,
                    );
                }
            }
            dispatch(
                displayAlert({
                    alertMessage: `${
                        newData?.length > 1 ? "Countries" : "Country"
                    } Updated Successfully`,
                    alertType: "success",
                }),
            );
            setIsManageCountry(false);
        } else {
            setSelectedCountries(oldSelectedCountries);
        }
    };

    const createOptimizerReport: (
        page: string,
        keyword: string,
    ) => any = async (page = "", keyword = "") => {
        return new Promise(async (resolve, reject) => {
            const pageIndex = recommendationDataV2.findIndex(
                (pgObj: InventoryV2Object) => pgObj.page === page,
            );
            const keywordIndex = recommendationDataV2[
                pageIndex
            ].keywords.findIndex(
                (keywordObj: InventoryKeywordObject) =>
                    keywordObj.keyword == keyword,
            );
            let _keyword: InventoryKeywordObject =
                recommendationDataV2[pageIndex].keywords[keywordIndex];
            let payload = {
                keyword: [_keyword.keyword],
                country_code: activeCountry?.country,
                country: activeCountry?.label,
                url: recommendationDataV2[pageIndex].page,
                doc_type: 2,
                competitors_count: 10, // static 10 for Inventory Optimizer report
                is_inventory: 1,
            };
            const res = await postData({
                url: "/api/keywords/create",
                payload,
            });
            try {
                if (res.status == 200) {
                    _keyword.report_info.report_id = res.data.report_id;
                    setRecommendationDataV2((ps) => {
                        let temp = [...ps];
                        temp[pageIndex].keywords[
                            keywordIndex
                        ].report_info.report_id = res.data.report_id;
                        temp[pageIndex].keywords[
                            keywordIndex
                        ].report_info.is_completed = res.data.status = 0;
                        return temp;
                    });
                    return resolve(res);
                } else {
                    resolve(res);
                }
            } catch (err) {
                resolve(res);
            } finally {
                resolve(res);
            }
        });
    };

    useEffect(() => {
        const successCallback = (data: any) => {
            if (data?.report_id) {
                setRecommendationDataV2((ps) => {
                    let temp = [...ps];
                    let pageIdx = temp.findIndex(
                        (pageObj) => pageObj.page == data.url,
                    );
                    if (pageIdx == -1) return temp;
                    let keywordIdx = temp[pageIdx].keywords.findIndex(
                        (keywordObj) =>
                            keywordObj?.report_info?.report_id ==
                            data.report_id,
                    );
                    temp[pageIdx].keywords[keywordIdx] = {
                        ...temp[pageIdx].keywords[keywordIdx],
                        report_info: {
                            report_id: data.report_id,
                            is_completed: 1,
                        },
                    };
                    return temp;
                });
            }
        };
        const failedCallback = (data: any) => {
            if (data?.report_id) {
                setRecommendationDataV2((ps) => {
                    let temp = [...ps];
                    let pageIdx = temp.findIndex(
                        (pageObj) => pageObj.page == data.url,
                    );
                    if (pageIdx == -1) return temp;
                    let keywordIdx = temp[pageIdx].keywords.findIndex(
                        (keywordObj) =>
                            keywordObj?.report_info?.report_id ==
                            data.report_id,
                    );
                    temp[pageIdx].keywords[keywordIdx] = {
                        ...temp[pageIdx].keywords[keywordIdx],
                        report_info: {
                            report_id: null,
                            is_completed: null,
                        },
                    };
                    return temp;
                });
            }
        };

        const attachSockets = () => {
            socket.on(socketEvents.REPORT_CREATED, successCallback);
            socket.on(socketEvents.REPORT_FAILED, failedCallback);
        };

        const removeSockets = () => {
            socket.off(socketEvents.REPORT_CREATED, successCallback);
            socket.off(socketEvents.REPORT_FAILED, failedCallback);
        };

        attachSockets();

        return () => {
            removeSockets();
        };
    }, []);

    const handleActiveCountryDomain: (
        cn: any, //country
        dm: DomainObject | undefined, //domain
    ) => void = (cn, dm) => {
        dispatch(resetUserGscInfoMonthlyInsights({}));
        if (value == 0) {
            getInsightsDetails(cn?.country ? cn : {}, dm); // for insights
            setRecommendationDataV2([]);
            setCannibalizationData([]);
        } else if (value == 1 || value == 2) {
            setGscInsightsData({
                organicCTR: {},
                countries_info: [],
                devices_info: {},
                positionInfo: {},
                lost_pages_info: [],
                next_refresh_at: "",
                status: 0,
            });
            getToneDownedInventory(cn?.country ? cn : {}, dm); // for inventory
            if (!LockConfigurator(FEATURE_TYPES.cannibalizationLock)) {
                getCannibalizationData(
                    cannibalization_filters,
                    cn?.country ? cn : {},
                    dm,
                );
            }
            // for Cannibalization
            setGscInsightsData({
                organicCTR: {},
                countries_info: [],
                devices_info: {},
                positionInfo: {},
                lost_pages_info: [],
                next_refresh_at: "",
                status: 0,
            });
        }

        setActiveCountry(cn);
    };

    return isLoading ? (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <div className={styles.trafficAnalyzerContainer}>
            <TrafficAnalyzerHeader
                otherInfoV2={otherInfoV2}
                isShimmer={isShimmerV2 || isShimmerInsights || isShimmerCannibs}
                activeDomain={activeDomain}
                selectedDomains={selectedDomains}
                activeCountry={activeCountry}
                selectedCountries={selectedCountries}
                isCannibsLocked={isCannibsLocked}
                handleActiveCountryDomain={handleActiveCountryDomain}
                oldSelectedCountries={oldSelectedCountries}
                setIsManageCountry={setIsManageCountry}
                setIsManageDomains={setIsManageDomains}
                value={value}
                setValue={setValue}
                isQuotaOver={isQuotaOver}
                setActiveDomain={setActiveDomain}
                inventoryPagesMax={recommendationDataV2.length || 0}
                inventoryPagesProcessed={totalPages || 0}
            />
            {!isQuotaOver ? (
                <div className={`${styles.tabsPanelContainer}`}>
                    <TabPanel value={value?.toString()} index={"0"}>
                        <div className={`${styles.insightsWrapper} `}>
                            <GeneralInsights
                                isShimmer={isShimmerInsights}
                                isProcessingInsights={isProcessingInsights}
                                countryDistribution={
                                    gscInsightsData?.countries_info
                                }
                                delta_keywords_count={
                                    gscInsightsData?.delta_keywords_count
                                }
                                deviceDistribution={
                                    gscInsightsData?.devices_info
                                }
                                ctrGraphData={gscInsightsData?.organicCTR}
                                positionDistribution={
                                    gscInsightsData?.positionInfo
                                }
                                lost_pages_info={
                                    gscInsightsData?.lost_pages_info
                                }
                                crawlInfo={gscInsightsData?.web_crawl_info}
                                nextRefresh={gscInsightsData?.next_refresh_at}
                                top_movers_losers_keywords={
                                    gscInsightsData?.top_movers_losers_keywords
                                }
                                new_pages_info={gscInsightsData?.new_pages_info}
                                top_movers_losers_pages={
                                    gscInsightsData?.top_movers_losers_pages
                                }
                                monthlyInsights={
                                    gscInsightsData?.monthly_insights_info
                                }
                                top_pages_info={gscInsightsData.top_pages_info}
                                top_keywords_info={
                                    gscInsightsData.top_keywords_info
                                }
                                activeCountry={activeCountry}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value?.toString()} index={"1"}>
                        <InventoryV2
                            isLoading={isLoading}
                            recommendationDataV2={recommendationDataV2}
                            createOptimizerReport={createOptimizerReport}
                            isShimmer={isShimmerV2}
                            isProcessingV2={isProcessingV2}
                            activeCountry={activeCountry}
                            totalPage={totalPages}
                            activeDomain={activeDomain}
                            nonProcessedPages={nonProcessedPages}
                            isOpenDefault={isOpenDefaultInventory}
                            setIsOpenDefault={setIsOpenDefaultInventory}
                        />
                    </TabPanel>

                    <TabPanel value={value?.toString()} index={"2"}>
                        <div className={`${styles.insightsWrapper} `}>
                            <KeywordCannibalization
                                cannibalizationData={cannibalizationData}
                                setCannibalizationData={setCannibalizationData}
                                cannibalizationOtherInfo={
                                    cannibalizationOtherInfo
                                }
                                requestedData={cannibalization_filters}
                                dateRange={{
                                    startDate: otherInfoV2?.startDate,
                                    endDate: otherInfoV2?.endDate,
                                }}
                                isShimmerCannibs={isShimmerCannibs}
                                cannibsFilterShimmer={cannibsFilterShimmer}
                                isOpenDefault={isOpenDefaultCannibs}
                                setIsOpenDefault={setIsOpenDefaultCannibs}
                            />
                        </div>
                    </TabPanel>
                </div>
            ) : (
                <div className={styles.quotaExhaustWrapper_container}>
                    <div
                        className={expiryStyles.exContainer}
                        style={{ transform: "translateY(-10%)" }}
                    >
                        <img src="/AssetsV2/plan-exceeded-icon.svg" alt="" />
                        <div>
                            You've hit your data quota for the domain. To view
                            more data, connect with our support team for options
                            on purchasing additional data. We're here to help
                            enhance your experience!
                        </div>
                        <div style={{ display: "flex", gap: 30 }}>
                            <Button
                                text="Talk to Support"
                                handler={() => {
                                    window.userengage &&
                                        window.userengage("widget.show");
                                    window.userengage &&
                                        window.userengage("widget.open");
                                }}
                                Icon={BiSupport}
                                iconReverse={true}
                                style={{
                                    width: "170px",
                                    height: "40px",
                                    borderRadius: 3,
                                    backgroundColor: "rgba(1, 77, 197, 0.1)",
                                    color: "#014dc5",
                                }}
                            ></Button>
                        </div>{" "}
                    </div>
                </div>
            )}

            {isManageCountry && (
                // @ts-ignore
                <Modal
                    setModal={() => {
                        setIsManageCountry(!isManageCountry);
                    }}
                >
                    <div className={styles.manageCountryModal}>
                        <div
                            className={styles.closeModal}
                            onClick={() => setIsManageCountry(false)}
                        >
                            <VscClose size={20} />
                        </div>
                        <div className={styles.modalTile}>
                            Manage Target Countries for{" "}
                            <span style={{ color: "#014dc5" }}>
                                {getTrimmedDomainURL(activeDomain?.domain_name)}
                            </span>
                        </div>
                        <div className={styles.countries}>
                            <div className={styles.countries_label}>
                                Select Countries:
                            </div>
                            <div className={styles.countries_input}>
                                <ReactSelect
                                    isCountryList={true}
                                    selected={selectedCountries}
                                    defaultValues={selectedCountries}
                                    setSelected={(val: any) => {
                                        // @ts-ignore
                                        setSelectedCountries(val);
                                    }}
                                    options={GSC_Countries}
                                    name={"Country"}
                                    _customStyles={customStyles}
                                />
                            </div>
                            <div
                                style={{
                                    color: "#6B6B6B",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    marginTop: "10px",
                                    marginLeft: "2px",
                                }}
                            >
                                Choose upto 5 countries
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                text="Done"
                                disabled={
                                    selectedCountries.length == 0 ? true : false
                                }
                                customClass={styles.mngCnDoneCta}
                                handler={() => {
                                    updateSelectedCountry(selectedCountries);
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {isManageDomains && (
                <Modal
                    setModal={() => {
                        setIsManageDomains(!isManageDomains);
                    }}
                >
                    <div className={styles.domainsManageWrapper}>
                        <span
                            className={styles.closeManageDomains}
                            onClick={() => {
                                setIsManageDomains(false);
                            }}
                        >
                            <VscClose />
                        </span>
                        <GscLogout
                            syncDomainsEdit={syncDomainsEdit}
                            reAuthSuccess={() => {
                                getInsightsDetails();
                                //cannibalization data for access allowed
                                if (
                                    !LockConfigurator(
                                        FEATURE_TYPES.cannibalizationLock,
                                    )
                                ) {
                                    getCannibalizationData(
                                        cannibalization_filters,
                                    );
                                }
                                // inventory data for access allowed
                                if (
                                    !LockConfigurator(
                                        FEATURE_TYPES.inventoryLock,
                                    )
                                ) {
                                    getToneDownedInventory();
                                }
                            }}
                            insideTA={true}
                        />
                    </div>
                </Modal>
            )}
            {!closeSatisfaction && !hasSatisfactionScore && !isQuotaOver && (
                <UserFeedback
                    section="trafficAnalyzer"
                    setClose={setCloseSatisfaction}
                />
            )}
        </div>
    );
};

export default TrafficAnalyzer;
