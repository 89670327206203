import {
    CircularProgress,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import axios from "axios";
import { updateUserInfo } from "../../../../store/actions/auth";
import useHelperFunctions from "../../../helperFunctions";
import { UPDATE_COMPANY_DETAILS } from "../../../api.json";
import Button from "../../../atoms/Button/Button";
import Label from "../../../atoms/Label/Label";

function PopUp() {
    const [loader, setLoader] = useState(false);
    const [GST, setGST] = useState("");
    const [error, setError] = useState(false);
    const [show, setShow] = useState(true);
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const GSTregex = /^[0-9]{2}[0-9A-Z]{13}$/;
    async function gstHandler() {
        if (!GSTregex.test(GST) && GST != "N/A") {
            setError(true);
            return;
        } else {
            setLoader(true);
            const response = await postData({
                url: UPDATE_COMPANY_DETAILS,
                payload: {
                    gst_number: GST,
                },
            });
            setLoader(false);
            if (response?.status == 200) {
                dispatch(updateUserInfo("gst_no", GST));
            }
        }
        setShow(false);
        window.localStorage.setItem("qazwsxedc", "12121DSadd33");
        return;
    }

    React.useEffect(() => {
        const listener1 = (e) => {
            if (e.key === "Escape") {
                setShow(false);
                window.localStorage.setItem("qazwsxedc", "12121DSadd33");
            }
        };

        document.addEventListener("keydown", listener1);

        return () => {
            document.removeEventListener("keydown", listener1);
        };
    }, []);

    if (!show) return null;

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                setShow(false);
                window.localStorage.setItem("qazwsxedc", "12121DSadd33");
            }}
            className={styles.popUpContainer}
        >
            <div onClick={(e) => e.stopPropagation()} className={styles.popUp}>
                <h3 className={styles.heading}>{"GST Number"}</h3>
                <Label label={"Please Enter your GST Number"}></Label>
                <TextField
                    autoFocus
                    spellCheck={false}
                    disabled={GST === "N/A"}
                    fullWidth
                    label={"GST 15 Digit No."}
                    variant="outlined"
                    helperText={
                        error == false ? "" : "Enter a valid GST Number"
                    }
                    value={GST}
                    error={error}
                    onChange={(e) => {
                        setError(false);
                        e.target.value.trim().length <= 15 &&
                            setGST(e.target.value.trim().toUpperCase());
                    }}
                ></TextField>
                <div
                    style={{
                        fontSize: "10px",
                        color: "#2c3948",
                        marginTop: 3,
                        paddingRight: 10,
                        paddingLeft: 2,
                    }}
                >
                    Please note, you won't be able to include your GST No. at a
                    later time.
                </div>

                <Checkbox
                    size="small"
                    onChange={(e) => {
                        e.target.checked
                            ? (() => {
                                  setGST("N/A");
                                  setError(false);
                              })()
                            : (() => {
                                  setGST("");
                                  setError(false);
                              })();
                    }}
                    name="checkedB"
                    style={{ paddingLeft: 0 }}
                    color="#597ef7"
                />

                <Label label={"No, I Don't Have GST Number"}></Label>
                <div className={styles.buttons}>
                    <Button
                        width={"120px"}
                        handler={() => {
                            gstHandler();
                        }}
                        text="Confirm"
                        loading={loader}
                    ></Button>
                </div>
            </div>
        </div>
    );
}

export default PopUp;
