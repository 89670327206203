import React, { useState } from "react";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import Select from "react-select";
import Button from "../../componentsV2/atoms/Button/Button";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../store/actions/alert";
import styles from "./superAdmin.module.css";

const optionsData = [
    { value: 0, label: "Disable" },
    { value: 1, label: "Active" },
];
export default function UpdateUserStatus({ setActiveAction }) {
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState({ id: "", email: "" });
    const [status, setStatus] = useState(optionsData[0]);
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();

    async function updateStatus() {
        const res = await postData({
            url: "/api/superAdmin/updateUserStatus",
            payload: {
                userEmail: email,
                statusValue: status.value,
            },
        });
        if (res.status == 200) {
            dispatch(
                displayAlert({
                    alertMessage: "Updated successfully",
                    alertType: "success",
                }),
            );
        }
    }

    return (
        <div style={{ margin: "30px" }}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <div style={{ display: "flex", alignItems: "end" }}>
                <div style={{ width: "30%", borderBottom: "1px solid black" }}>
                    <AutoComplete
                        email={email}
                        className={styles.emailField}
                        setEmail={setEmail}
                        setUserId={setUserId}
                    />
                </div>
                <div
                    style={{
                        width: "200px",
                        marginLeft: "10px",
                        marginRight: "10px",
                    }}
                >
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(val) => {
                            setStatus(val);
                        }}
                        defaultValue={optionsData[0]}
                        options={optionsData}
                    />
                </div>
                <Button
                    text="Submit"
                    handler={() => updateStatus()}
                    width={200}
                    height={38}
                />
            </div>
        </div>
    );
}
