import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { RiCheckboxCircleFill } from "react-icons/ri";
import StageInfo from "./StageInfo";
import { useSelector } from "react-redux";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";

export default function Stage({
  stage,
  current_stage = 1,
  lastStage,
  projectId,
  listOfFreelancerTypes,
  setConfirmationAction,
  openDialog,
  setStages,
  setCurrentStage,
  i,
  _stages,
  setData,
  data,
  stages,
}) {
  const role = useSelector((state) => state.auth.role);
  const [info, setInfo] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setInfo(false));
  return (
    <>
      <div ref={ref} onClick={() => setInfo(true)} className={styles.stage}>
        {role == "admin" && info && (
          <StageInfo
            listOfFreelancerTypes={listOfFreelancerTypes}
            projectId={projectId}
            stage={stage}
            setConfirmationAction={setConfirmationAction}
            openDialog={openDialog}
            setStages={setStages}
            setCurrentStage={setCurrentStage}
            current_stage={current_stage}
            i={i}
            _stages={_stages}
            setData={setData}
            data={data}
            stages={stages}
          />
        )}
        <div className={styles.check}>
          {i == current_stage ? (
            <RiCheckboxCircleLine />
          ) : (
            <RiCheckboxCircleFill />
          )}
        </div>

        <div
          className={
            styles.name + " " + (i == current_stage && styles.nameBlack)
          }
        >
          {stage?.name}
        </div>
      </div>
      {!lastStage && (
        <div
          className={styles.path + " " + (i < current_stage && styles.pathDone)}
        ></div>
      )}
    </>
  );
}
