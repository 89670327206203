import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import AIHumanizerEditor from "./AIHumanizerEditor";
import AIHumanizer from "./AIHumanizer";

function AIHumanizerParent() {
    const location = useLocation();
    console.log("location", location);
    return (
        <Switch>
            <Route exact path="/admin/ai-humanizer/editor/:documentId">
                <AIHumanizerEditor key={location.pathname} />
            </Route>

            <Route exact path="/admin/ai-humanizer">
                <AIHumanizer />
            </Route>

            <Redirect to={"/admin/ai-humanizer"} />
        </Switch>
    );
}

export default AIHumanizerParent;
