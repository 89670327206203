import React, { useRef } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import DocCard, { InProgressDoc } from "../../organisms/DocCard/DocCard";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";
import UserReportsHook from "./UserReportsHook";
import styles from "./styles.module.css";
import { AiFillPlayCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import TagsFilter from "../../organisms/Account/TagsFilter";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { useState } from "react";
import { useEffect } from "react";
import NewUIDropDown, {
    TYPES,
} from "../../molecules/NewUIDropdown/NewUIDropDown";
import SVGIcon from "../../atoms/SVGIcon";
import { ClickAwayListener, Link, Portal } from "@material-ui/core";
import Button from "../../atoms/Button/Button";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Modal from "../../atoms/Modal/Modal";
import { useHistory } from "react-router-dom";
import KeywordInput from "../../molecules/Creator/KeywordInput/KeywordInput";
import SemrushKeywordsModal from "./SemrushKeywordsModal";
import VideoModal from "../NewDashboard/VideoModal";
import { VscClose } from "react-icons/vsc";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import ModuleTemplate from "../../templates/ModuleTemplate/ModuleTemplate";

const sortByOptions = [
    { title: "Created At", id: 1 },
    { title: "Updated At", id: 2 },
    { title: "SEO Score", id: 3 },
    { title: "Status", id: 4 },
];
function UserReports({ bin, newDoc }) {
    const [sortBy, setSortBy] = useState(sortByOptions[0]);
    const [order, setOrder] = useState(0);
    const [modal, _setModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [filterTagList, setFilterTagsList] = useState([]);
    const [filterStatusList, setFilterStatusList] = useState([]);
    const [initiateClose, setInitiateClose] = useState(false);
    const goTo = useNavigation();
    const otherInfo = useSelector((store) => store.otherInfo);
    const [keywordResearcher, setKeywordResearcher] = useState(false);
    const userVideo = useSelector((state) => state.video.seo);
    useEffect(() => {}, [userVideo]);
    const {
        loading,
        docs,
        openDoc,
        getReports,
        setRequestData,
        requestData,
        hasMore,
        deleteReport,
        restoreReport,
        setLoading,
        makeReport,
        apiCalls,
        loadingCreation,
    } = UserReportsHook({ bin, doc_type: 1 });

    function setModal(param) {
        if (!param) getReports(requestData);
        _setModal(param);
    }

    useEffect(() => {
        setLoading(true);
        setRequestData((ps) => ({
            ...ps,
            page: 1,
            created: sortBy.id == 1 ? true : false,
            updated: sortBy.id == 2 ? true : false,
            seo_score: sortBy.id == 3 ? 1 : 0,
            activity_status: sortBy.id == 4 ? 1 : 0,
            is_deleted: bin ? true : false,
            order,
            tags: filterTagList.map((tag) => tag.id),
            activity_status_filter: filterStatusList.map((ele) => ele.id),
        }));
    }, [sortBy, bin, order, filterTagList, filterStatusList]);

    useEffect(() => {
        if (newDoc) {
            setReportModal(true);
            goTo("CREATOR");
        }
    }, [newDoc]);

    useEffect(() => {
        if (otherInfo.userIntegrationInfo.length > 0) {
            for (let i = 0; i < otherInfo.userIntegrationInfo.length; i++) {
                if (otherInfo.userIntegrationInfo[i].app_id === 2) {
                    if (otherInfo.userIntegrationInfo[i].app_linked === 1) {
                        setKeywordResearcher(true);
                    }
                }
            }
        }
    }, [otherInfo]);

    return (
        <>
            <ModuleTemplate
                HeaderChild={
                    <KeywordInput
                        loadingCreation={loadingCreation}
                        makeReport={makeReport}
                        docs={docs}
                        apiCalls={apiCalls}
                        setModal={setModal}
                        keywordResearcher={keywordResearcher}
                    />
                }
                initiateClose={initiateClose}
                userVideo={userVideo}
                setInitiateClose={setInitiateClose}
                heading="AI-Powered Article Writer"
                subHeading="Research and create SEO optimized top ranking articles"
                sourceWhere={"seo"}
                stickyHeaderChild={
                    <>
                        <div
                            style={{ padding: 20 }}
                            className={styles.rrFilters}
                        >
                            {!bin ? (
                                // <h4> All SEO Articles</h4>
                                <></>
                            ) : (
                                <h4 style={{ color: "red" }}>
                                    {" "}
                                    Deleted SEO Articles{" "}
                                </h4>
                            )}
                            <div
                                className={styles.rrFilters}
                                style={{ flexDirection: "row" }}
                            >
                                <div
                                    style={{ width: "280px" }}
                                    className={styles.search}
                                >
                                    <Textfield
                                        height={40}
                                        containerStyle={{ marginBottom: "0" }}
                                        props={{
                                            onChange: (e) => {
                                                const value =
                                                    e.target.value.trim();
                                                setRequestData((ps) => ({
                                                    ...ps,
                                                    search: value,
                                                    page: 1,
                                                }));
                                            },
                                            style: {
                                                borderRadius: 3,
                                                border: "solid 1px rgba(75, 75, 85, 0.16)",
                                                backgroundColor: "#fff",
                                                paddingLeft: "40px",
                                                borderRadius: 3,
                                            },
                                        }}
                                        placeholder="Search SEO Articles"
                                        label=""
                                        Icon={() => (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/searchIcon.svg"
                                                }
                                            />
                                        )}
                                        iconStyle={{
                                            left: "10px",
                                            right: "unset",
                                            top: "20px",
                                        }}
                                    />
                                </div>
                                {bin ? (
                                    <BinTabs page="CREATOR" />
                                ) : (
                                    <>
                                        <TagsFilter
                                            setFilterTagsList={
                                                setFilterTagsList
                                            }
                                            setFilterStatusList={
                                                setFilterStatusList
                                            }
                                            section={"articleWriter"}
                                            style={{ with: "145px" }}
                                        />

                                        <NewUIDropDown
                                            options={sortByOptions}
                                            setOrder={setOrder}
                                            order={order}
                                            style={{
                                                textTransform: "capitalize",
                                                height: 40,
                                                color: "#4b4b55",
                                                width: 170,
                                            }}
                                            type={TYPES.SORT}
                                            selectedOption={sortBy}
                                            handler={setSortBy}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                }
                content={
                    <div>
                        {loading ? (
                            <>
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                            </>
                        ) : (
                            <InfiniteScroll
                                dataLength={docs.length}
                                scrollableTarget="scrollableDiv"
                                next={() => {
                                    setRequestData((ps) => ({
                                        ...ps,
                                        page: requestData.page + 1,
                                    }));
                                }}
                                hasMore={hasMore}
                                loader={
                                    <>
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                    </>
                                }
                            >
                                {
                                    <>
                                        <table style={{ width: "100%" }}>
                                            <tr className={styles.reportHeader}>
                                                <td>
                                                    <div>
                                                        <LightTooltip
                                                            arrow
                                                            title="Targeted Primary Keyword"
                                                        >
                                                            <span>
                                                                Keyword(s)
                                                            </span>
                                                        </LightTooltip>
                                                    </div>
                                                </td>

                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    <span>Created at</span>
                                                </td>

                                                <td>
                                                    <div>
                                                        <span>Word Count </span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div>
                                                        <LightTooltip
                                                            title="SEO Score (0-100) indicates the optimization level of your content for search engines. A higher score increases the chances of achieving top search engine rankings."
                                                            arrow
                                                        >
                                                            <span>
                                                                SEO Score
                                                            </span>
                                                        </LightTooltip>
                                                    </div>
                                                </td>

                                                <td
                                                    style={{
                                                        width: !bin ? 250 : 150,
                                                        textAlign: "left",
                                                        paddingLeft: 40,
                                                    }}
                                                >
                                                    <span>Status</span>
                                                </td>

                                                {/* <td
                                                style={{ width: !bin ? 250 : 150 }}
                                            ></td> */}
                                            </tr>

                                            {docs.map((doc) => {
                                                return (
                                                    <DocCard
                                                        doc={doc}
                                                        openDoc={(editor) =>
                                                            openDoc(
                                                                doc.report_id,
                                                                editor,
                                                            )
                                                        }
                                                        seoDoc={true}
                                                        deleteDoc={deleteReport}
                                                        archived={bin}
                                                        restoreDoc={
                                                            restoreReport
                                                        }
                                                        key={doc.report_id}
                                                    />
                                                );
                                            })}
                                        </table>
                                        {docs.length == 0 && (
                                            <div className={styles.noItems}>
                                                {
                                                    <>
                                                        <SVGIcon
                                                            style={{
                                                                margin: "auto",
                                                            }}
                                                            src={
                                                                "/New UI/SVG/Emptydocument.svg"
                                                            }
                                                            size={300}
                                                        />
                                                        <h3
                                                            style={{
                                                                marginTop: -50,
                                                            }}
                                                        >
                                                            No SEO Articles
                                                            Found
                                                        </h3>
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </>
                                }
                            </InfiniteScroll>
                        )}
                    </div>
                }
            />

            {modal && (
                <Modal setModal={setModal}>
                    <SemrushKeywordsModal setModal={setModal} />
                </Modal>
            )}
        </>
    );
}

export default UserReports;

export function BinTabs({ page = "CREATOR" }) {
    const goTo = useNavigation();

    const active = {
        borderRadius: 3,
        backgroundColor: "#1f4cbe",
        fontWeight: 400,
    };
    const inActive = {
        borderRadius: 3,
        backgroundColor: "white",
        color: "#6b6b6b",
        fontWeight: 400,
    };

    return (
        <div className={styles.binTabs}>
            <Button
                text="SEO Articles"
                width="150px"
                handler={() => {
                    // goTo("CREATOR_BIN");
                }}
                borderRadius="3"
                height={"100%"}
                style={page == "CREATOR" ? active : inActive}
            />
            <Button
                text="AI Docs"
                width="150px"
                handler={() => {
                    // goTo("SMART_DOCS_BIN");
                }}
                borderRadius="3"
                height={"100%"}
                style={page == "SMART_DOCS" ? active : inActive}
            />
        </div>
    );
}
