import React from 'react';
import styles from './StatusBanner.module.css';
function getClassName(text) {
  if (text === 'NEW') {
    return styles.new;
  } else if (text === 'QUEUED') {
    return styles.queued;
  } else if (text === 'SUBMITTED FOR REVIEW') {
    return styles.review;
  } else if (text === 'REVISION REQUESTED') {
    return styles.revision;
  } else if (text === '*CANCELLED*') {
    return styles.cancelled;
  } else if (text === 'IN PROGRESS') {
    return styles.progress;
  }
  return styles.progress;
}

function getText(text) {
  if (text === 'SUBMITTED FOR REVIEW') {
    return 'FOR REVIEW';
  } else return text;
}

export default function StatusBanner({ icon, text, secondary }) {
  return (
    <div
      className={`${styles.statusBanner} ${secondary ? styles.secondary : ''} ${
        !secondary ? getClassName(text) : ''
      }`}
    >
      {icon}{' '}
      <span className={styles.statusBannerText}>
        {getText(text).toLowerCase()}
      </span>
    </div>
  );
}
