import { CONFIG_LOADING, LOAD_CONFIG, UPDATE_CONFIG } from "../actiontypes";

const initialState = {
    loading: true,
    sessionValidityTimeout: 3600,
    deals: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CONFIG:
            return {
                ...state,
                ...action.data,
                loading: false,
            };
        case LOAD_CONFIG:
            return {
                ...state,
                sessionValidityTimeout: +action.data.refresh_value,
                ...action.data,
                loading: false,
            };
        case CONFIG_LOADING:
            return {
                ...state,
                loading: true,
            };

        default:
            return state;
    }
}
