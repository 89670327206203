import React, { useState } from "react";
import { LightTooltip } from "../Tooltip/tooltip";
import SVGIcon from "../SVGIcon";

interface CopyToClipboardProps {
    textToCopy: string;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
    textToCopy,
}) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        navigator.clipboard.writeText(textToCopy);
    };

    return (
        <LightTooltip
            title={isCopied ? "Copied!" : "Click to Copy"}
            placement={"top"}
            arrow
        >
            <span
                style={{ height: "1.3rem" }}
                onClick={(e: any) => {
                    e.stopPropagation();
                    handleCopy();
                }}
            >
                <SVGIcon
                    src={"/New UI/SVG/copy.svg"}
                    size={"14"}
                    color="#014dc5"
                />
            </span>
        </LightTooltip>
    );
};
