import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Typography, makeStyles } from "@material-ui/core";
import ResearchGenerate from "../../Research/ResearchGenerate/ResearchGenerate";
import useHelperFunctions from "../../../helperFunctions";
import { useSelector } from "react-redux";

const useStylesTab = makeStyles((theme) => ({
    root: {
        minHeight: 38,
        margin: "0 5px",
        fontSize: 16,
        textTransform: "none",
        opacity: 0.8,
        color: "#2c3948",
        flexGrow: 1,
        color: "#29394a",
        opacity: 1,
        "&:hover": {
            color: "#1f4cbe",
        },
    },
    selected: {
        color: "#1f4cbe",
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#1f4cbe",
        height: 1,
    },
    root: {
        borderBottom: "solid #d6d6d6 0.1px",
        margin: "0 20px",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ margin: 0 }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function AIAssistant({
    aiWordLimitSetting,
    setAiWordLimitSetting,
    reportInfo,
    keyTerms,
    attachedTo,
}) {
    const generateCases = useSelector((state) => state.variants.generateData);

    return (
        <>
            <ResearchGenerate
                attachedTo={attachedTo}
                generateCases={generateCases}
                reportInfo={reportInfo}
            />
        </>
    );
}

export default AIAssistant;
