import React from "react";
import { useRef } from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";
import { LightTooltip } from "./Tooltip/tooltip";
import styles from "./styles.module.css";
import { TooltipProps } from "@material-ui/core";

const StyledIcon = styled(ReactSVG)`
    svg {
        ${({ size }: { size?: number }) =>
            size &&
            css`
                width: ${size}px;
                height: ${size}px;
            `}

        [fill="#000000"] {
            fill: currentColor;
        }

        [stroke="#000000"] {
            stroke: currentColor;
        }
        [fill="black"] {
            fill: currentColor;
        }

        [stroke="black"] {
            stroke: currentColor;
        }
    }
`;
type SVGIconProps = {
    tooltip?: string;
    tooltipPlacement?: TooltipProps["placement"];
    color?: string;
    size?: number | string;
    hover?: string;
    transform?: string;
    src: string;
    outerRef?: any;
    style?: React.CSSProperties;
    props?: object;
};
const SVGIcon = React.memo(
    ({
        tooltip,
        tooltipPlacement,
        color,
        size,
        hover,
        transform,
        src = "",
        outerRef,
        style,
        ...props
    }: SVGIconProps) => {
        const ref = useRef();

        return (
            <LightTooltip
                placement={tooltipPlacement}
                title={tooltip ? tooltip : ""}
                arrow
            >
                <div
                    style={{ height: size, width: size, color, ...style }}
                    className={styles.wrapper}
                    ref={outerRef}
                >
                    <StyledIcon
                        ref={ref}
                        src={src}
                        size={size}
                        hover={hover}
                        transform={transform}
                        style={{
                            ...style,
                        }}
                        wrapper="div"
                        {...props}
                    />
                </div>
            </LightTooltip>
        );
    },
);

export default SVGIcon;
