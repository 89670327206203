import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    Chip,
    ClickAwayListener,
    TextField,
    makeStyles,
    withStyles,
} from "@material-ui/core";
import styles from "./KeywordInput.module.css";
import { VscChevronDown, VscClose } from "react-icons/vsc";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { formatURL, isValidUrl } from "../../../../utility/helperFun";
import { Link } from "@material-ui/icons";
import { BsLink45Deg } from "react-icons/bs";
import { Autocomplete } from "@material-ui/lab";
import { RiCloseLine, RiLock2Fill } from "react-icons/ri";
import { errorMessage } from "../../../atoms/CustomTextfield/Textfield";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import SVGIcon from "../../../atoms/SVGIcon";
import VideoModal from "../../../Pages/NewDashboard/VideoModal";

/* @ts-ignore */
const autoCustom = makeStyles((theme) => ({
    root: {
        border: "solid 1px #d2d2d2",
        margin: "0",
        padding: "0px",
        borderRadius: 3,
        background: "#fff",
        minHeight: 40,
        maxHeight: 120,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        width: "100% !important",
        paddingRight: 10,
        overflow: "auto",
        backgroundColor: "transparent",
    },
    tag: {
        border: "1px solid transparent",
        background: "#e8edf8cc",
        margin: "0",
        padding: "0",
        borderRadius: 3,
    },
}));
/* @ts-ignore */
const inputCustom = makeStyles((theme) => ({
    root: {
        " & >  .MuiInput-root": { padding: "3px 7px" },
        "& > div::before": {
            borderBottom: "none",
        },
        "& > div:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&.Mui-focused::before": {
            borderBottom: "none !important",
        },
        "&.Mui-underline": {
            background: "white",
        },
        "& input::placeholder": {
            fontSize: 14,
            color: "#6b6b6b",
            fontWeight: 300,
            opacity: 1,
            fontFamily: "inter",
        },
        "& input": {
            fontSize: 14,
            fontWeight: 400,
            opacity: 1,
            fontFamily: "inter",
            maxHeight: "40px",
            width: "100% !important",
            backgroundColor: "transparent",
            color: "rgba(31, 76, 190, 0.8)",
        },
    },
}));

const DomainInput = ({
    value,
    setValue,
    placeholder = "Add Competitor",
    handleOtherCompetitor = () => {},
    cCount = null,
    isEnabled = false,
}: {
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    placeholder?: string;
    handleOtherCompetitor?: (cCount: number, value: string) => void;
    cCount?: number | null;
    isEnabled?: boolean;
}) => {
    return (
        <Textfield
            placeholder={placeholder}
            containerStyle={{
                marginBottom: "0px",
            }}
            customClass={styles.DomainInputField}
            label=""
            errMsg={
                value.length > 0 && !isValidUrl(value)
                    ? "Invalid URL"
                    : value.indexOf("docs.google.com") != -1
                    ? "We do not support Google Docs yet"
                    : ""
            }
            disabled={!isEnabled}
            props={{
                value: value,
                onChange: (e: any) => {
                    setValue(e.target.value);
                },
                style: {
                    boxShadow: "none",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                    background: "white",
                    color: "rgba(31, 76, 190, 0.80)",

                    ...(!isEnabled && {
                        background: "#F6F7FB",
                        color: "#9e9e9e",
                    }),
                },
                onBlur: (e: any) => {
                    if (cCount != null) handleOtherCompetitor(cCount, value);
                },
            }}
            Icon={() => <BsLink45Deg size={18} />}
        />
    );
};

const _LightTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 410,
        padding: "30px 30px 30px 30px !important",
    },
}))(LightTooltip);

function GscDomainsKP({
    value = "",
    setValue = () => {},
    setCompetitorsDomain = () => {},
    isDash = false,
    competitorsDomain = [],
    isEnabled = false,
    hasUsedKeywordGap,
}: {
    value: string;
    setValue: any;
    setCompetitorsDomain: Dispatch<SetStateAction<string[]>>;
    competitorsDomain: string[];
    isDash?: boolean;
    isEnabled?: boolean;
    hasUsedKeywordGap: boolean;
}) {
    const [show, setShow] = useState(false);
    const [urlSecondary, setUrlSecondary] = useState("");
    const classes = autoCustom();
    const inputClasses = inputCustom();
    const alreadyData = isEnabled && !!(value || competitorsDomain.length);

    useEffect(() => {
        if (isEnabled) {
            setShow(hasUsedKeywordGap ? false : true);
        }
    }, [hasUsedKeywordGap]);

    useEffect(() => {
        if (alreadyData) {
            setShow(true);
        }
    }, [alreadyData]);

    return (
        <div
            style={{
                display: "inline-block",
                flexGrow: 1,
                textAlign: "left",
                position: "relative",
                zIndex: 12,
                padding: isDash ? 0 : "0 20px 0px 0",
                width: isDash ? "100%" : show ? "100%" : "unset",
            }}
        >
            <div
                className={styles.gscDomainDropDownWrapper}
                onClick={() => {
                    setShow(!show);
                }}
                style={{ height: 40, paddingLeft: isDash ? undefined : "5px" }}
            >
                Keyword gap {!alreadyData ? "settings" : "enabled"}
                {!isEnabled ? (
                    <>
                        <LightTooltip title="Upgrade to Pro plan to access keyword gap">
                            <span style={{ marginTop: 3, marginLeft: 3 }}>
                                <RiLock2Fill />
                            </span>
                        </LightTooltip>
                    </>
                ) : (
                    ""
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <VscChevronDown
                        size={16}
                        color="#3F5575"
                        style={{
                            marginBottom: show ? -3 : -1.5,
                            transform: show ? "rotate(180deg)" : "rotate(0deg)",
                            marginLeft: 2,
                        }}
                    />
                </div>
                <div className={styles.keyGapInfo}>
                    <_LightTooltip
                        interactive
                        placement={isDash ? "right" : "right-start"}
                        arrow
                        title={
                            <div className={styles.tooltipWrapper}>
                                Uncover the keywords your competitors are
                                ranking for, but you're missing out on. Gain a
                                powerful edge by strategically targeting these
                                gaps in your content.
                                <br />
                                Boost your visibility in relevant searches and
                                attract more qualified traffic
                                <br />
                                <br />
                                <strong>Example</strong> <br /> <br />
                                You want to get keyword oppurtunities for your
                                domain www.healthline.com.
                                <br /> Add www.healthline.com in your domain and
                                your competitors like www.webmd.com,
                                www.mayoclinic.org in competitors domain, and
                                click plan to see how you rank for keywords
                                against your competition
                                <br />
                                <br />
                                <VideoModal
                                    link={`https://www.youtube.com/embed/29kHgerzqT0?si=PY6bdUzmfLCJXoPT?modestbranding=1&rel=0&loop=1`}
                                    zoomVal={1.2}
                                    modal={false}
                                    aspectWidth={380}
                                    aspectHeight={200}
                                    customClass={styles.tooltipVideo}
                                    location="Keyword Gap"
                                />
                            </div>
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <SVGIcon src={"/New UI/SVG/info.svg"} size={16} />
                        </div>
                    </_LightTooltip>
                </div>
            </div>

            {show && (
                <div
                    style={
                        isDash
                            ? {
                                  flexDirection: "column",
                                  gap: 5,
                                  borderBottom: "1px solid lightgray",
                                  paddingBottom: 10,
                              }
                            : { flexDirection: "row" }
                    }
                    className={styles.adv_settings}
                >
                    <div
                        style={
                            isDash
                                ? {
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }
                                : { flexGrow: 1, flexBasis: 0 }
                        }
                    >
                        <div>
                            <div className={styles.inputTitle1}>
                                Your Domain
                            </div>

                            <div className={styles.cg_example}>
                                Ex: amazon.com
                            </div>
                        </div>

                        <div
                            style={isDash ? { width: 320 } : {}}
                            className={`${styles.domainsInputRow2} `}
                        >
                            <div
                                className={`${styles.domain} ${
                                    urlSecondary.length && styles.hasText
                                }`}
                            >
                                <DomainInput
                                    isEnabled={isEnabled}
                                    placeholder={"Add your domain"}
                                    value={value}
                                    setValue={setValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={
                            isDash
                                ? {
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }
                                : {
                                      flexGrow: 1,
                                      flexBasis: 0,
                                      maxWidth: "50%",
                                  }
                        }
                    >
                        <div>
                            <div className={styles.inputTitle2}>
                                Competition
                            </div>

                            <div className={styles.cg_example}>
                                Ex: ebay.com
                            </div>
                        </div>

                        <div
                            style={
                                isDash
                                    ? {
                                          width: 320,
                                          backgroundColor: isEnabled
                                              ? ""
                                              : "rgb(246, 247, 251)",
                                      }
                                    : {
                                          backgroundColor: isEnabled
                                              ? ""
                                              : "rgb(246, 247, 251)",
                                      }
                            }
                            className={`${styles.domainsInputRow2} `}
                        >
                            <Autocomplete
                                classes={classes}
                                disableClearable
                                autoSelect
                                noOptionsText
                                multiple
                                id="tags-standard"
                                options={[]}
                                disabled={!isEnabled}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder={
                                            competitorsDomain.length > 2
                                                ? ""
                                                : "Add Competitors"
                                        }
                                        classes={inputClasses}
                                        value={urlSecondary}
                                        onChange={(e) =>
                                            setUrlSecondary(e.target.value)
                                        }
                                        inputProps={{
                                            ...params.inputProps,

                                            ...(competitorsDomain.length > 2
                                                ? {
                                                      disabled: true,
                                                      style: {
                                                          maxHeight: 0,
                                                          overflow: "hidden",
                                                          padding: 0,
                                                      },
                                                  }
                                                : {}),
                                        }}
                                    />
                                )}
                                popupIcon={<span style={{ width: 30 }}></span>}
                                forcePopupIcon={true}
                                freeSolo
                                value={competitorsDomain}
                                inputValue={urlSecondary}
                                onChange={(e: any, value: any) => {
                                    if (
                                        e.target.value &&
                                        !isValidUrl(e.target.value)
                                    )
                                        return;

                                    setCompetitorsDomain(value);
                                    setUrlSecondary("");
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index) => (
                                        <CustomChip
                                            option={option}
                                            setCompetitorsDomain={
                                                setCompetitorsDomain
                                            }
                                            getTagProps={getTagProps}
                                            index={index}
                                            key={option}
                                            setUrlSecondary={setUrlSecondary}
                                            urlSecondary={urlSecondary}
                                        />
                                    ))
                                }
                            />
                            {errorMessage(
                                urlSecondary.length > 0 &&
                                    !isValidUrl(urlSecondary)
                                    ? "Invalid URL"
                                    : urlSecondary.indexOf("docs.google.com") !=
                                      -1
                                    ? "We do not support Google Docs yet"
                                    : "",
                            )}

                            <span className={styles.competitorCount}>
                                {competitorsDomain.length}/3
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const DomainChip = ({
    item,
    setCompetitorsDomain,
}: {
    item: string;
    setCompetitorsDomain: any;
}) => {
    const onDelete = () => {
        setCompetitorsDomain((ps: any) => {
            const i = ps.findIndex((i: string) => i == item);

            ps.splice(i, 1);

            return [...ps];
        });
    };

    return (
        <div className={styles.domainChip}>
            {item} <VscClose size={14} onClick={onDelete} />
        </div>
    );
};

/* @ts-ignore */
const chipCustom = makeStyles(() => ({
    root: {
        padding: "0",
        borderRadius: 4,
        margin: "3px",
        marginRight: "3px",
        marginLeft: "3px",
        height: "26px",
        cursor: "text",
        maxWidth: "280px",
        backgroundColor: "#7188AA1A",
        "&:hover": {
            backgroundColor: "#014DC5",
        },
        "& input": {
            backgroundColor: "white",
        },
        "& input:focus": {
            backgroundColor: "white",
        },
        "&:hover .MuiChip-label": {
            color: "white",
        },
        "&:hover .MuiChip-deleteIcon": {
            color: "white",
        },
    },
    label: {
        fontSize: "14px",
        fontWeight: "400",
        color: "#014DC5",
    },
    deleteIcon: {
        backgroundColor: "transparent",
        width: 16,
    },
}));

const CustomChip = ({
    option,
    setCompetitorsDomain,
    getTagProps,
    index,
    urlSecondary,
    setUrlSecondary,
}: {
    option: any;
    setCompetitorsDomain: any;
    getTagProps: any;
    index: any;
    urlSecondary: any;
    setUrlSecondary: any;
}) => {
    const chipClasses = chipCustom();
    const [edit, setEdit] = useState(false);
    const [val, setVal] = useState(option);

    const handleBlur = (e: any) => {
        e.persist();
        if (!isValidUrl(e.target.value)) return;
        setEdit(false);
        setCompetitorsDomain((ps: any) => {
            ps[index] = e?.target?.value?.substring(0, 100) || "";
            return [...ps];
        });
    };

    return (
        <Chip
            classes={chipClasses}
            onClick={(e: any) => {
                e.stopPropagation();

                setEdit(true);
            }}
            deleteIcon={<RiCloseLine />}
            label={
                edit ? (
                    <input
                        style={{
                            border: "none",
                            outline: "none",
                        }}
                        onClick={(e: any) => {
                            e.stopPropagation();
                        }}
                        onKeyDown={(e: any) => {
                            e.stopPropagation();
                        }}
                        autoFocus
                        onMouseDown={(e) => e.stopPropagation()}
                        onBlur={(e) => handleBlur(e)}
                        onChange={(e: any) => {
                            setUrlSecondary(e.target.value);
                            setVal(e.target.value.substring(0, 100));
                        }}
                        value={val}
                    />
                ) : (
                    option
                )
            }
            {...getTagProps({ index })}
        />
    );
};

export default GscDomainsKP;
