import { TextareaAutosize } from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import SVGIcon from "../../../../atoms/SVGIcon";
import { showCounter } from "../../../../atoms/TextArea/TextArea";
import { Outline, WP } from "../classes";

import { CruiseContext } from "../StepsWizard";
import { CRUISE_TYPES } from "../useCruiseHook";
import styles from "./TalkingPointsStep.module.css";
import { LightTooltip } from "../../../../atoms/Tooltip/tooltip";
import { VscTrash } from "react-icons/vsc";
import { cruiseMixPanelEvents } from "../cruiseConstant";
import useMixpanelHook from "../../../../../utility/useMixpanelHook";
const maxLength = 150;
export const CitationHover = ({ citations }) => (
    <div className={styles.citationsWrapper}>
        <div className={styles.citationHead}>Source</div>
        {citations.map((citation) => {
            return (
                <div className={styles.citationPoint_Wrapper}>
                    <a
                        href={citation}
                        target={"_blank"}
                        className={styles.citationPoint}
                    >
                        {citation}
                    </a>
                </div>
            );
        })}
    </div>
);

const TalkPoint = ({
    wp,
    index,
    parentIndex,
    provided,
    field,
    h2Index,
    isH3,
    isH2,
}) => {
    console.log("talkpoint*******");
    const [pointInput, setPointInput] = useState(wp?.tp);
    const [focus, setFocus] = useState(false);
    const dispatchStepsData = useContext(CruiseContext);
    const { mixpanelTrack } = useMixpanelHook();

    const deleteWp = () => {
        mixpanelTrack(cruiseMixPanelEvents.delete, {
            "Screen Name": "Writing Points",
            "Deleted Section": "Wp",
        });
        dispatchStepsData({
            type: CRUISE_TYPES.DELETE_WP,
            parentIndex: parentIndex,
            index: parentIndex,
            wpIndex: index,
            field: field,
            id: wp.id,
            parentTag: isH2
                ? Outline.tags.h2
                : isH3
                ? Outline.tags.h3
                : Outline.tags.question,
            h2Index,
        });
    };
    const editWp = () => {
        if (pointInput.trim() == wp?.tp.trim()) return;
        if (pointInput.trim()) {
            const newWp = new WP({
                outline_id: wp.outline_id,
                tpType: WP.types.manual,
                tp: pointInput,
                citations: [],
                id: wp.id,
            });

            dispatchStepsData({
                type: CRUISE_TYPES.EDIT_WP,
                parentIndex: parentIndex,
                wpIndex: index,
                field: field,
                data: newWp,
                id: wp.id,
                h2Index,
                parentTag: isH2
                    ? Outline.tags.h2
                    : isH3
                    ? Outline.tags.h3
                    : Outline.tags.question,
            });
        } else {
            deleteWp();
        }
    };

    return (
        <LightTooltip
            style={{}}
            interactive
            placement={"right"}
            arrow
            title={
                wp.citations.length > 0 ? (
                    <CitationHover citations={wp.citations} />
                ) : (
                    ""
                )
            }
        >
            <div className={styles.talkingPointRow}>
                <div className={styles.talkingPointRow__left}>
                    <SVGIcon
                        size={18}
                        src={"/New UI/SVG/RxDotFilled.svg"}
                        color={"#014dc5"}
                    />
                    <div
                        {...provided.dragHandleProps}
                        className={styles.dragIcon}
                    >
                        <SVGIcon
                            color="lightgray"
                            size={16}
                            src="/New UI/SVG/drag.svg"
                        />
                    </div>
                    <div
                        style={{
                            ...(focus && pointInput?.length >= maxLength
                                ? { borderColor: "rgb(244 98 98 / 73%)" }
                                : {}),
                        }}
                        className={styles.talkingPointText}
                    >
                        <TextareaAutosize
                            disabled={false}
                            type="text"
                            rowsMin={0}
                            placeholder={"Add writing points here"}
                            value={pointInput}
                            onChange={(e) => setPointInput(e.target.value)}
                            style={{
                                width: "100%",
                            }}
                            // autoFocus={value == ""}
                            onKeyPress={(e) => {
                                if (e.key == "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    e.target.blur();
                                }
                            }}
                            onBlur={(e) => {
                                editWp();
                            }}
                            maxLength={maxLength}
                            autoFocus={!pointInput}
                            onFocus={() => setFocus(true)}
                        />

                        {focus &&
                            showCounter(
                                false,
                                true,
                                pointInput?.length,
                                maxLength,
                                {
                                    textAlign: "right",
                                    display: "block",
                                    paddingLeft: 5,
                                },
                            )}
                    </div>
                </div>
                <div className={styles.actionsBtn}>
                    <span
                        className={styles.deleteBtn}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            deleteWp();
                        }}
                    >
                        <VscTrash color="#fc5a5a" cursor={"pointer"} />
                    </span>
                </div>
            </div>
        </LightTooltip>
    );
};

export default TalkPoint;
