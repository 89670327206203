import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import Image from "../../atoms/Image/Image";
import { useSelector } from "react-redux";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { useLocation } from "react-router-dom";
import { Tooltip, withStyles } from "@material-ui/core";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import AsideSkeleton from "./AsideSkeleton";
import { useAsideOptions } from "./sidebarOptions";
import SVGIcon from "../../atoms/SVGIcon";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { ASIDE_OPTIONS_ID } from "./constants";
import { useParams } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";

import mixpanel from "mixpanel-browser";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import PlanInfoAside from "./PlanInfoAside";
import { AiOutlineMenu } from "react-icons/ai";
import { VscClose } from "react-icons/vsc";
import { getTrailText } from "../../Pages/NewDashboard/constants";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#5fccf7",
        color: "white",
        fontSize: 11,
    },
    arrow: {
        color: "#5fccf7",
    },
}))(Tooltip);

const SidebarOption = ({
    setAside,
    aside,
    title,
    screen,
    icon,
    paths,
    separator,
    marginTop,
    id,
    gtag,
    goTo,
    redirect,
    subTitle,
    subOptions,
    superText,
    superTextComp,
}) => {
    const ref = useRef();
    const params = useParams();
    const { planExpiresDate, planTrial } = useUserAccessHook();
    const { mpTrackDashboard, mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        if (ref.current) {
            ref.current.setAttribute("skip", true);
        }
    }, [ref]);

    return (
        <>
            {marginTop && <div style={{ flexGrow: 1 }}></div>}

            <div
                onClick={() => {
                    mpTrackDashboard(title);
                    if (getTrailText(planExpiresDate) == 7 && planTrial) {
                        mixpanelTrack("Day 0 Dashboard", {
                            action: `Side Menu - ${title}`,
                        });
                    }
                    redirect ? window.open(redirect, "_blank") : goTo(screen);
                    if (ASIDE_OPTIONS_ID.EARN_CREDITS == id) {
                        mixpanel.track("Earn Credits Clicked");
                    }
                    setAside(false);
                }}
                id={gtag}
                className={`${styles.creatorBox} ${
                    !!subOptions && styles.hasSubOptions
                }  ${
                    paths.includes(location.pathname) && styles.activeHeading
                } `}
            >
                <div className={styles.optionIcon}>{icon}</div>

                <p className={`${styles.creatorHeading}   `}>
                    {title} <br /> {subTitle ? <span>{subTitle}</span> : ""}
                </p>
                {!!superText && superTextComp}
            </div>

            {!!subOptions &&
                subOptions.map((option) => {
                    return (
                        <div
                            onClick={() => {
                                mpTrackDashboard(option.title);
                                goTo(option.screen);
                                setAside(false);
                            }}
                            id={option.gtag}
                            className={`${styles.creatorBox} ${
                                styles.creatorBoxSub
                            } ${!!option.superText && styles.superTextOption} ${
                                (option.paths.includes(location.pathname) ||
                                    (option.paths.includes(
                                        "/admin/cluster/report",
                                    ) &&
                                        location.pathname.includes(
                                            "/admin/cluster/report",
                                        ))) &&
                                styles.activeHeading
                            } `}
                        >
                            <div className={styles.optionIcon}>
                                {option.icon}
                            </div>
                            <p className={`${styles.creatorHeading}`}>
                                {option.title}{" "}
                                {!!option.superText && option.superTextComp}
                            </p>
                        </div>
                    );
                })}

            {separator && <div className={styles.separator}> </div>}
        </>
    );
};

export default function ({ setAside = () => {}, aside, noAuth, auditMode }) {
    const ref = useRef();
    useOnClickOutside(ref, () => setAside(false), true);
    const location = useLocation();
    const goTo = useNavigation();
    const { isTeamMember, freeUser, isLTD } = useUserAccessHook();
    useEffect(() => {
        removeEventListener("click", document.closeAside);
        if (aside) addEventListener("click", document.closeAside);
    }, [aside]);
    const isLoaded = useSelector((state) => state.otherInfo.isLoaded);
    const options = useAsideOptions();
    const [showMobileAside, setShowMobileAside] = useState(false);
    const { isMobile } = useSelector((state) => state.state);
    const userInfo = useSelector((state) => state.auth);

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    isMobile &&
                        ref?.current?.classList?.remove(
                            styles.asideMobileExpanded,
                        );
                    setShowMobileAside(false);
                }}
            >
                <div
                    style={{
                        position: "relative",
                    }}
                    onClick={() => {
                        isMobile &&
                            ref?.current?.classList?.toggle(
                                styles.asideMobileExpanded,
                            );
                        if (
                            ref?.current?.classList?.contains(
                                styles.asideMobileExpanded,
                            )
                        ) {
                            setShowMobileAside(true);
                        } else {
                            setShowMobileAside(false);
                        }
                    }}
                    className={`${styles.aside} 
          ${
              isMobile && location.pathname.includes("dashboard")
                  ? styles.asideMobile
                  : location.pathname.includes("editor") ||
                    location.pathname.includes("creator/report") ||
                    location.pathname.includes("audit/report") ||
                    noAuth ||
                    auditMode
                  ? styles.width0
                  : navigator.userAgent.includes("Mobile")
                  ? styles.width80Mobile
                  : location.pathname.includes("dashboard") ||
                    location.pathname.includes("integration")
                  ? styles.width260
                  : location.pathname.includes("cluster")
                  ? styles.width80
                  : styles.width80
          } `}
                    ref={ref}
                >
                    <div
                        id="aside"
                        style={{
                            height: isMobile ? window.innerHeight : "100%",
                        }}
                        className={`${styles.container}`}
                    >
                        {
                            <>
                                <div
                                    onClick={() => goTo("DASHBOARD")}
                                    className={`${
                                        isMobile
                                            ? styles.mobileLogo
                                            : styles.logo
                                    } ${styles.blueLogo}`}
                                >
                                    {" "}
                                    {!isMobile ? (
                                        <SVGIcon
                                            src="/New UI/SVG/Scalenut.svg"
                                            size={26}
                                        />
                                    ) : isMobile &&
                                      location.pathname.includes(
                                          "dashboard",
                                      ) ? (
                                        <>
                                            <div className={styles.menuIcon}>
                                                {!showMobileAside ? (
                                                    <AiOutlineMenu size={26} />
                                                ) : (
                                                    <VscClose size={26} />
                                                )}
                                            </div>
                                            <span className={styles.logoText}>
                                                Scalenut
                                            </span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div
                                    onClick={() => goTo("DASHBOARD")}
                                    className={`${styles.logo} ${styles.whiteLogo} `}
                                    style={{
                                        boxShadow:
                                            window.location.pathname.includes(
                                                "/creator",
                                            )
                                                ? "0px 4px 25px rgba(181, 181, 190, 0.2)"
                                                : "0 4px 25px 0 rgb(137 137 141 / 20%)",
                                    }}
                                >
                                    <SVGIcon
                                        src="/New UI/SVG/Scalenut.svg"
                                        size={26}
                                    />
                                </div>
                            </>
                        }

                        <h2 className={styles.creatorHeading}>Scalenut</h2>
                        {noAuth ? (
                            <></>
                        ) : isLoaded ? (
                            <>
                                {options.map((option) =>
                                    !(
                                        (isTeamMember &&
                                            option.id ==
                                                ASIDE_OPTIONS_ID.EARN_CREDITS) ||
                                        ((freeUser ||
                                            userInfo.seo_surge_user ||
                                            isLTD) &&
                                            option.id ==
                                                ASIDE_OPTIONS_ID.SEO_SURGE)
                                    ) ? (
                                        option?.title?.length ? (
                                            <SidebarOption
                                                aside={aside}
                                                setAside={setAside}
                                                {...option}
                                                key={option.id}
                                                goTo={goTo}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <></>
                                    ),
                                )}
                            </>
                        ) : (
                            <AsideSkeleton />
                        )}
                        <div style={{ flexGrow: 1 }}></div>

                        {!isTeamMember &&
                            (location.pathname.includes("dashboard") ||
                                !isMobile) && <PlanInfoAside />}
                    </div>
                </div>
            </ClickAwayListener>
        </>
    );
}
