import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    loadGenerateVariants,
    loadVariants,
} from "../../../../store/actions/variants";

import { VARIANTS, GENERATE_VARIANTS } from "../../../api.json";
import useHelperFunctions from "../../../helperFunctions";
import { seoUseCases } from "../../NewDashboard/constants";

function useVariants() {
    const { getData } = useHelperFunctions();
    const dispatch = useDispatch();
    useEffect(() => {
        (async function () {
            const res = await getData({
                url: GENERATE_VARIANTS,
            });
            if (res.status == 200) {
                console.log(res.data.data, "Temp Rahul");
                let variants = [];
                if (res.data) {
                    variants = [...seoUseCases, ...res.data.data];
                }

                const categoriesSet = new Set();
                const modifiedVariants = {};
                modifiedVariants.all = variants;

                variants.map((variant) => {
                    if (!categoriesSet.has(variant.cat_name)) {
                        categoriesSet.add(variant.cat_name);
                        modifiedVariants[variant.cat_name] = [];
                    }

                    modifiedVariants[variant.cat_name].push(variant);
                });

                dispatch(loadGenerateVariants(modifiedVariants));
            }
        })();
    }, []);
}

export default useVariants;
