import React, { useEffect, useState } from "react";
import styles from "./GenerateVariants.module.css";
import GenerateVariantCard from "../../../molecules/GenerateVariantCard/GenerateVariantCard";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import { BiSearch } from "react-icons/bi";
import SVGIcon from "../../../atoms/SVGIcon";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";
import useHelperFunctions from "../../../helperFunctions";
export default function GenerateVariants({
  generateVariants,
  filterId,
  filterTerm,
  setSeoModal,
}) {
  const { cruiseLocked } = useUserAccessHook();
  const goTo = useNavigation();
  console.log('variants generate', generateVariants);
  const isInFilter = (item, value) => {
    if (value == "") return true;
    if (item.trim().toLowerCase().includes(value.trim().toLowerCase()))
      return true;
    return false;
  };

  const GetSEOuseCaseCard = ({ variant }) => {
    const [click, setClick] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setClick(false);
      }, 1000);
    }, [click]);

    return (
      <GenerateVariantCard
        iconSrc={variant.icons}
        title={
          variant.useCase == -3 ? (
            <>
              {variant.name}{" "}
              <span
                className={
                  styles.comingSoon + " " + (click ? styles.comingSoonGrow : "")
                }
              >
                Coming soon
              </span>
            </>
          ) : (
            <>{variant.name}</>
          )
        }
        key={variant.name}
        handler={() => {
          variant.useCase == -3
            ? setClick(true)
            : setSeoModal({ open: true, id: variant.useCase });
        }}
        subText={variant.description}
      />
    );
  };

  const getVariants = () => {
    return generateVariants.map((variant) => {
      return variant.cat_id == -1 ? (
        <GetSEOuseCaseCard variant={variant} />
      ) : (
        <GenerateVariantCard
          iconSrc={variant.icons}
          title={variant.name}
          key={variant.name}
          handler={() => {
            goTo("NEW_SMART_DOCS", [`useCase=${variant.useCase}`]);
          }}
          subText={variant.description}
        />
      );
    });
  };

  return (
    <div>
      {generateVariants.length === 0 ? (
        <p
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
          }}
        >
          <SVGIcon src="/New UI/SVG/no-search-result.svg" size={200}></SVGIcon>{" "}
          No Results Found
        </p>
      ) : (
        <div className={styles.cards}>{getVariants()}</div>
      )}
    </div>
  );
}
