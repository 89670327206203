import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Tab, Tabs, Box, Typography, makeStyles } from "@material-ui/core";
import ResearchForm from "../../Research/ResearchForm/ResearchForm";
import { useContext } from "react";
import ReportContext from "../../Research/ReportContext";
import EditorContext from "../EditorContext";
import PeopleQuestions from "../../Research/PeopleQuestions/PeopleQuestions";
import EditorBrief from "../../../molecules/Creator/Overview/EditorBrief";
import KeyTerms from "../../Research/KeyTerms/KeyTerms";
import SVGIcon from "../../../atoms/SVGIcon";
import StatsEditorAssistant from "./StatsEditorAssistant";
import screens from "../../../../screens.json";
import { FiExternalLink } from "react-icons/fi";
import styles from "./styles.module.css";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

const useStylesTab = makeStyles((theme) => ({
    root: {
        minHeight: 38,
        margin: "0px",
        fontSize: 14,
        textTransform: "none",
        opacity: 0.8,
        fontWeight: 500,
        color: "#2c3948",
        flexGrow: 1,
        minWidth: "max-content",
        color: "#6b6b6b",
        display: "flex",
        justifyContent: "column",
        alignItems: "column",
        border: ".4px solid #c9e3ff",
        opacity: 1,
        overflow: "hidden",
        "&:hover": {
            color: "#1f4cbe",
        },
        "& > .MuiTab-wrapper": {
            display: "flex",
            justifyContent: "center",
            gap: "2px",
            flexDirection: "column",
            alignItems: "center",
        },
        maxWidth: 400,
    },
    selected: {
        color: "#1f4cbe",
        background: "#e5e9f6",
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    root: {
        "& .MuiTabScrollButton-root.Mui-disabled": {
            display: "none",
        },
        border: ".5px solid #c9e3ff",
        borderRadius: "5px",
        margin: "0px",
        display: "flex",
        flexDirection: "row",
    },
    selected: {
        backgroundColor: "#f5f9ff",
    },
    indicator: {
        backgroundColor: "transparent",
        border: "none",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                margin: 0,
                // maxHeight: "calc(100vh - 220px)",
                height: "0vh",
                flexGrow: 1,
                overflow: "auto",
            }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function AITools({ keyTerms, selection }) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const params = useParams();
    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();
    const reportData = useContext(ReportContext);
    const { auditMode } = useContext(ReportContext);
    const editorData = useContext(EditorContext);

    function getUrl(urlFor, auditMode) {
        if (urlFor == "report") {
            if (reportData?.isShared) {
                return auditMode
                    ? screens.AUDIT_REPORT_SHARED +
                          "/" +
                          params.id +
                          "/" +
                          params.access
                    : screens.SEO_REPORT_SHARED +
                          "/" +
                          params.id +
                          "/" +
                          params.access;
            }
            return auditMode
                ? screens.AUDIT_REPORT + "/" + params.id
                : screens.SEO_REPORT + "/" + params.id;
        }
    }

    return (
        <>
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                classes={classesTabs}
                value={value}
                onChange={handleChange}
            >
                {/* <Tab
                            disableRipple={true}
                            icon={
                                <SVGIcon
                                    src={"/New UI/SVG/brief.svg"}
                                    size={22}
                                />
                            }
                            classes={classesTab}
                            label="Content Brief"
                            {...a11yProps(1)}
                        /> */}
                <LightTooltip
                    title="NLP powered list of key terms and phrases associated with search term"
                    arrow
                >
                    <Tab
                        disableRipple={true}
                        icon={
                            <SVGIcon
                                size={22}
                                src={"/New UI/SVG/nlptopics.svg"}
                            />
                        }
                        classes={classesTab}
                        label="Key Terms"
                        {...a11yProps(1)}
                    />
                </LightTooltip>

                <LightTooltip
                    title={`Get Competitive Insights from top ${reportData.reportInfo.competitors_count} ranking URLs`}
                    arrow
                >
                    <Tab
                        disableRipple={true}
                        icon={
                            <SVGIcon
                                src={"/New UI/SVG/competition_tab_icon.svg"}
                                size={22}
                            />
                        }
                        classes={classesTab}
                        label="Competition"
                        {...a11yProps(0)}
                    />
                </LightTooltip>

                <LightTooltip
                    title="Social listening+ AI suggestions for common questions on your primary search phrase"
                    arrow
                >
                    <Tab
                        disableRipple={true}
                        icon={
                            <SVGIcon
                                src={"/New UI/SVG/questions.svg"}
                                size={22}
                            />
                        }
                        classes={classesTab}
                        label="Questions"
                        {...a11yProps(0)}
                    />
                </LightTooltip>

                {reportData.isStatsAllowed ? (
                    <LightTooltip
                        title="Add credibility to your articles by inluding statistics for a richer content experience"
                        arrow
                    >
                        <Tab
                            disableRipple={true}
                            icon={
                                <SVGIcon
                                    src={"/New UI/SVG/stats_icons.svg"}
                                    size={22}
                                />
                            }
                            classes={classesTab}
                            label="Statistics"
                            {...a11yProps(3)}
                        />
                    </LightTooltip>
                ) : (
                    ""
                )}

                <Tab
                    disableRipple={true}
                    classes={classesTab}
                    style={{ padding: "0px" }}
                    label={
                        <NavLink
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                                padding: "6px 12px",
                            }}
                            to={getUrl("report", auditMode)}
                        >
                            <div className={styles.navlink}>
                                <FiExternalLink
                                    style={{ marginBottom: "4px" }}
                                    size={22}
                                />
                                <div>Detailed Report</div>
                            </div>
                        </NavLink>
                    }
                    {...a11yProps(4)}
                ></Tab>
            </Tabs>
            {/* <TabPanel value={value} index={0}>
                        <EditorBrief
                            setContentBrief={reportData.setContentBrief}
                            title={reportData.reportInfo.title}
                            report_id={reportData.reportInfo.report_id}
                            isContentBriefAlready={
                                reportData.reportInfo.isContentBrief
                            }
                            nudgeStep={0}
                            setNudgeStep={() => {}}
                            access={false}
                            editor={true}
                        />
                    </TabPanel> */}
            <TabPanel value={value} index={0}>
                <KeyTerms
                    editor={true}
                    list={keyTerms}
                    aiWordLimitSetting={editorData.aiWordLimitSetting}
                    reportInfo={reportData.reportInfo}
                    writingState={editorData.writingState}
                    setWritingState={editorData.setWritingState}
                    writeWithAnimation={editorData.writeWithAnimation}
                    selection={selection}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ResearchForm
                    competetion={reportData.competetion}
                    aiWordLimitSetting={editorData.aiWordLimitSetting}
                    reportInfo={reportData.reportInfo}
                    writingState={editorData.writingState}
                    setWritingState={editorData.setWritingState}
                    writeWithAnimation={editorData.writeWithAnimation}
                ></ResearchForm>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PeopleQuestions
                    list={reportData.peopleQuestions}
                    aiWordLimitSetting={editorData.aiWordLimitSetting}
                    reportInfo={reportData.reportInfo}
                    writingState={editorData.writingState}
                    setWritingState={editorData.setWritingState}
                    writeWithAnimation={editorData.writeWithAnimation}
                ></PeopleQuestions>
            </TabPanel>
            {reportData.isStatsAllowed ? (
                <TabPanel value={value} index={3}>
                    <StatsEditorAssistant
                        aiWordLimitSetting={editorData.aiWordLimitSetting}
                        reportInfo={reportData.reportInfo}
                        writingState={editorData.writingState}
                        setWritingState={editorData.setWritingState}
                        writeWithAnimation={editorData.writeWithAnimation}
                        subLevel={true}
                    />
                </TabPanel>
            ) : (
                ""
            )}
        </>
    );
}

export default AITools;
