import React, { useContext, useMemo } from "react";
import styles from "./styles.module.css";
import { BsChevronDown, BsBrush, BsChevronUp } from "react-icons/bs";
import { BiBold } from "react-icons/bi";
import { CgFormatItalic } from "react-icons/cg";
import { BiUnderline, BiLinkAlt } from "react-icons/bi";
import { AiOutlineCopy } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import Color from "./color";
import AiDropdown, { items } from "./AiDropdown";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import { ClickAwayListener, Popover, makeStyles } from "@material-ui/core";
import { LinearProgress, withStyles, createStyles } from "@material-ui/core";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import { displayAlert } from "../../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import { SMART_EDITOR, link } from "../editorConstants";
import ReportContext from "../../Research/ReportContext";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../../components/account/useUserAccessHook";
import { countWords } from "../../../utlities";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import SVGIcon from "../../../atoms/SVGIcon";
import { HiOutlinePencil } from "react-icons/hi";
import EditorContext from "../EditorContext";
import { SEO_EDITOR } from "../../../Pages/NewDashboard/constants";

import Quill from "quill";
import Showdown from "../../../../lib/showdownmin";
const Delta = Quill.import("delta");

let interval = null;
export const getSelectedText = (selection) => {
    if (document?.quill?.editor) {
        return document?.quill?.editor.getText(
            selection.index,
            selection.length,
        );
    }
};

const RephraseWord = ({ word, alts }) => {
    const [_word, setWord] = useState(word);

    return (
        <span className={styles.rephrasedWord}>
            {" "}
            <div className={styles.rephrasedWordsList}>
                {alts
                    .filter((item) => item != _word)
                    .map((alt) => (
                        <span
                            onClick={() => {
                                setWord(alt);
                            }}
                        >
                            {alt}
                        </span>
                    ))}
            </div>{" "}
            {_word}
        </span>
    );
};

const getRephrasedText = (data, text, setRepara) => {
    const arr = [];

    // for (const itemdata of data) {
    //   let textIndex=0;
    //   for (const [index,item] of itemdata.flipped.tokens.sort((item1,item2)=>item1.start_char-item2.start_char).entries()) {
    //     console.log(textIndex)
    //        arr.push(itemdata.paraphrased.substring(textIndex,item.start_char))
    //        textIndex=item.end_char;
    //        arr.push(<RephraseWord alts={item.alts} word={item.alts[0]}/>)
    //        if(itemdata.flipped.tokens.length-1==index){
    //         arr.push(itemdata.paraphrased.substring(textIndex,itemdata.paraphrased.length))
    //        }
    //   }
    //   arr.push(" ");
    // }

    // const theme = createTheme({
    //   components: {
    //     // Name of the component
    //     LinearProgress: {
    //       styleOverrides: {
    //         // Name of the slot
    //       },
    //     },
    //   },
    // });

    for (const itemData of data) {
        for (const item of itemData.flipped) {
            if (typeof item == "string" || item.length == 1)
                arr.push(typeof item == "string" ? item : item[0]);
            else
                arr.push(<RephraseWord alts={item.splice(1)} word={item[0]} />);
        }
        arr.push(" ");
    }

    return arr;
};
const LinearProgressModified = withStyles((theme) =>
    createStyles({
        bar: {
            backgroundColor: "#014dc5",
        },
    }),
)(LinearProgress);

/**
 * @param {{item:items[0]}}
 *  */
const RephraseModal = ({
    selection,
    setRephraseModal,
    reportInfo,
    item,
    setMiniTool,
    isEnable,
    customInput,
    setCustomInput,
    customUseCase,
}) => {
    const [loading, setLoading] = useState(true);

    const [editIns, setEditIns] = useState(false);
    const [textComponent, setTextComponent] = useState(null);
    const [textComponentHtml, setTextComponentHtml] = useState("");

    const { postData } = useHelperFunctions();
    const [progress, setProgress] = useState(0);
    const ref = useRef({});

    const [isCopied, setIsCopied] = useState(false);

    const { metaData, attachedTo } = useContext(EditorContext);

    useEffect(() => {
        if (loading) {
            interval = setInterval(() => {
                setProgress((ps) => {
                    if (ps >= 80) return 80;
                    else return ps + 10;
                });
            }, 1500);
        } else {
            clearInterval(interval);
        }

        // return () => {
        //   clearInterval(interval);
        // };
    }, [loading]);

    useEffect(() => {
        const converter = new Showdown.Converter({
            tables: true,
            simpleLineBreaks: true,
        });
        const html = converter.makeHtml(textComponent || "");
        setTextComponentHtml(html);
    }, [textComponent]);

    useEffect(() => {
        let timer = null;
        if (isCopied) {
            timer = setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);

    const text = useMemo(() => getSelectedText(selection), []);

    const useCase = useMemo(() => item.useCase, []);

    const generate = async () => {
        setEditIns(false);
        setProgress(0);
        setLoading(true);
        setTextComponent("");
        let titleText =
            document?.quill?.editor?.root?.querySelector("h1")?.innerText || "";
        const res = await postData({
            url:
                customUseCase.useCase == item.useCase
                    ? "/api/ai-generate/regenerate/custom-instruction-editor"
                    : "/api/generate",
            payload: {
                text: text,
                useCase: item.useCase,
                ...(attachedTo == SEO_EDITOR
                    ? {
                          report_id: reportInfo?.report_id,
                          title:
                              titleText || metaData?.title || reportInfo?.title,
                      }
                    : { copywriter: true }),
                ...(customUseCase.useCase == item.useCase && {
                    customInstruction: customInput,
                }),
            },
        });

        if (res.status == 200 && res.data?.output?.length) {
            setTextComponent(res.data?.output[0]?.content);
            setProgress(100);
            setLoading(false);
        } else {
            setRephraseModal((ps) => ({ isEnable: 0, item: {} }));
        }
    };

    useEffect(() => {
        if (isEnable) generate();
    }, [isEnable]);

    return (
        <div
            style={{
                width: `calc(${document?.quill?.editor?.root.offsetWidth}px - ${
                    getComputedStyle(document?.quill?.editor?.root).paddingLeft
                } - ${
                    getComputedStyle(document?.quill?.editor?.root).paddingRight
                })`,
                marginLeft: `calc(${
                    getComputedStyle(document?.quill?.editor?.root).paddingLeft
                } - 20px)`,
            }}
            className={styles.rephraseContainerWrapper}
        >
            <div
                style={{ paddingBottom: 2 }}
                className={styles.rephraseContainer}
            >
                <CloseButton
                    style={{ right: 10, top: 10 }}
                    setClose={() => {
                        setMiniTool(false);

                        setRephraseModal({ isEnable: 0, item: {} });
                    }}
                />

                <div
                    style={{
                        marginBottom: 20,
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    <h3 className={styles.rephraseHeading}>
                        Original Text&nbsp;
                        <small>
                            ({countWords(getSelectedText(selection))} Words)
                        </small>
                    </h3>
                    <pre
                        style={{ color: "#000000a6" }}
                        className={styles.rephraseText}
                    >
                        {getSelectedText(selection)}
                    </pre>
                </div>
            </div>
            {!editIns ? (
                <div className={styles.rephraseContainer}>
                    <div
                        className={styles.rephrasedTextContainer}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                        {!loading && (
                            <h3 className={styles.rephraseHeading}>
                                {item?.resultTitle}&nbsp;
                                <small>
                                    ({countWords(textComponent)} Words){" "}
                                </small>
                            </h3>
                        )}
                        {loading && (
                            <>
                                <div className={styles.loading}>
                                    {item.loadingTextData}
                                    <LoadingDots
                                        className={styles.loadingDots}
                                    />
                                </div>
                            </>
                        )}

                        {loading ? (
                            <>
                                {
                                    <LinearProgressModified
                                        // color="#014dc5"
                                        variant="determinate"
                                        value={progress}
                                    />
                                }
                            </>
                        ) : (
                            <>
                                <pre ref={ref} className={styles.rephraseText}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: textComponentHtml,
                                        }}
                                    ></div>
                                </pre>

                                <div className={styles.buttonsContainer}>
                                    <div className={styles.ctasWrapper}>
                                        <Button
                                            text="Accept"
                                            handler={() => {
                                                const lastChar =
                                                    document?.quill?.editor?.getText(
                                                        selection.index +
                                                            selection.length -
                                                            1,
                                                        1,
                                                    );
                                                document?.quill?.editor?.deleteText(
                                                    selection.index,
                                                    lastChar == "\n"
                                                        ? selection.length - 1
                                                        : selection.length,
                                                    "user",
                                                );
                                                const newDelta =
                                                    document.quill.editor.clipboard.convert(
                                                        {
                                                            html: textComponentHtml,
                                                        },
                                                    );
                                                document.quill.editor.updateContents(
                                                    new Delta()
                                                        .retain(selection.index)
                                                        .concat(newDelta),
                                                );

                                                setRephraseModal({
                                                    isEnable: 0,
                                                    item: {},
                                                });
                                                const length =
                                                    ref.current?.innerText
                                                        ?.length;
                                                const index = selection.index;
                                                setTimeout(() => {
                                                    document.quill.editor.setSelection(
                                                        {
                                                            length,
                                                            index,
                                                        },
                                                    );
                                                }, 200);
                                            }}
                                            style={{
                                                width: "80px",
                                                height: "30px",
                                                // marginLeft: "5px",
                                                padding: "4px 8px 4px 7px",
                                                borderRadius: "3px",
                                                color: "#36c8a3",
                                                fontSize: "12px",
                                                backgroundColor: "#e9f9f5",
                                            }}
                                            iconReverse={true}
                                        />
                                        <Button
                                            text="Discard"
                                            handler={() => {
                                                setRephraseModal({
                                                    isEnable: 0,
                                                    item: {},
                                                });
                                            }}
                                            style={{
                                                width: "80px",
                                                height: "30px",
                                                // marginLeft: "5px",
                                                padding: "4px 8px 4px 7px",
                                                borderRadius: "3px",
                                                color: "#fc5a5a",
                                                fontSize: "12px",
                                                backgroundColor: "#ffefef",
                                            }}
                                            iconReverse={true}
                                        />
                                        <Button
                                            text=""
                                            handler={() => {
                                                generate();
                                            }}
                                            style={{
                                                width: "max-content",
                                                height: "30px",
                                                // marginLeft: "5px",
                                                padding: "4px 8px 4px 7px",
                                                borderRadius: "3px",
                                                color: "#014dc5",
                                                fontSize: "12px",
                                                backgroundColor: "#eaeefa",
                                            }}
                                            iconReverse={true}
                                            Icon={() => (
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/new-refresh.svg"
                                                    }
                                                    size="20px"
                                                />
                                            )}
                                        />
                                        <Button
                                            text="Edit Instruction"
                                            handler={() => {
                                                setEditIns(true);
                                            }}
                                            style={{
                                                width: "145px",
                                                height: "30px",
                                                // marginLeft: "5px",
                                                padding: "4px 8px 4px 7px",
                                                borderRadius: "3px",
                                                color: "var(--primary-blue)",
                                                fontSize: "12px",
                                                backgroundColor: "#eaeefa",
                                            }}
                                            iconReverse={true}
                                            Icon={() => <HiOutlinePencil />}
                                        />
                                    </div>

                                    {
                                        <LightTooltip
                                            arrow
                                            placement="top"
                                            title={
                                                isCopied
                                                    ? "Copied"
                                                    : "Click to copy"
                                            }
                                        >
                                            <span
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        ref?.current?.innerText,
                                                    );
                                                    setIsCopied(true);
                                                }}
                                            >
                                                <AiOutlineCopy
                                                    size="20px"
                                                    color="#014dc5"
                                                    cursor={"pointer"}
                                                />
                                            </span>
                                        </LightTooltip>
                                    }
                                </div>
                            </>
                        )}

                        {/* <span className={}>
           <IoIosClose/>
         </span> */}
                    </div>
                </div>
            ) : (
                <AiDropdown
                    selection={selection}
                    setDropdown={() => {}}
                    toolPos={null}
                    setRephraseModal={setRephraseModal}
                    customInput={customInput}
                    setCustomInput={setCustomInput}
                    customUseCase={customUseCase}
                />
            )}
        </div>
    );
};

const getPos = (value) => {
    const width = document.getElementById("editor-bound").offsetWidth;
    let left = 0;

    if (value + 450 > width) {
        left = value + 450 - width;
    }

    return left + 5;
};

const popoverStyles = makeStyles(() => {
    return {
        root: {
            "& .MuiPaper-root": {
                padding: 10,
                backgroundColor: "transparent",
                boxShadow: "none",
                overflow: "visible",
            },
        },
    };
});

export default function App({
    style,
    toolPos,
    quill,
    setMiniTool,
    reverse,
    selection,
    writer,
}) {
    const [customInput, setCustomInput] = useState("");
    const [format, setFormat] = useState({});
    const [dropdown, setDropdown] = useState(false);
    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);
    const [isCustomModule, setIsCustomModule] = useState(false);
    const [rephraseModal, setRephraseModal] = useState({
        isEnable: 0,
        item: {},
    });
    const [input, setInput] = useState({ value: "", state: false });
    const { LockConfigurator } = useUserAccessHook();

    const { isShared, reportInfo } = useContext(ReportContext);
    const otherInfo = useSelector((state) => state.otherInfo);

    const getTextFormat = () => {
        try {
            return quill.getFormat();
        } catch (error) {
            return {};
        }
    };
    const dispatch = useDispatch();

    const openUpgradeDialog = () => {
        dispatch(
            displayAlert({
                alertMessage: "",
                alertType: "popup",
                errorData: {
                    status: "upgrade",
                },
            }),
        );
    };

    const boldText = () => {
        const { bold } = format;

        quill.format("bold", bold ? null : true);
    };

    const underLineText = () => {
        const { underline } = format;

        quill.format("underline", underline ? null : true);
    };

    const italicText = () => {
        const { italic } = format;
        quill.format("italic", italic ? null : true);
    };

    const h2Text = () => {
        const { header } = format;
        quill.format("header", header == 2 ? null : 2);
    };

    const h3Text = () => {
        const { header } = format;
        quill.format("header", header == 3 ? null : 3);
    };

    const colorText = (color) => {
        if (color == "#000000") return quill.format("color", null);
        quill.format("color", color);
    };

    const colorTextBG = (color) => {
        if (color == "#ffffff") return quill.format("background", null);
        quill.format("background", color);
    };

    const textLink = (e) => {
        setMiniTool(false);
        setTimeout(() => {
            link.call({ quill: document.quill.editor }, true);
        }, 200);
    };

    const addTextLink = () => {
        if (input.value) quill.format("link", input.value);
        else quill.format("link", false);
    };

    const getIcon = (value) => {
        return value ? (
            <BsChevronUp size={10} strokeWidth={1} color="#ababab" />
        ) : (
            <BsChevronDown size={10} strokeWidth={1} color="#014dc5" />
        );
    };

    useEffect(() => {
        setFormat(getTextFormat());
    }, []);

    const popoverClasses = popoverStyles();

    const { attachedTo } = useContext(EditorContext);

    const customUseCase = {
        text: "Custom",
        useCase: attachedTo == SMART_EDITOR ? 258 : 257,
        title: "",
        resultTitle: "Result",
        loadingTextData: "Generating",
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (!rephraseModal.isEnable) setMiniTool(false);
            }}
        >
            <div
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                style={{
                    ...style,

                    zIndex: 7,
                    ...(isCustomModule ? { left: 0, right: 0 } : {}),
                }}
                className={isCustomModule ? styles.customModuleWrapper : ""}
            >
                {isCustomModule ? (
                    <>
                        <Popover
                            open={rephraseModal.isEnable}
                            anchorEl={document.getElementById(
                                "aiCruiseContainer",
                            )}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                            }}
                            classes={popoverClasses}
                            onClose={() => {
                                setRephraseModal({ isEnable: 0, item: {} });
                            }}
                        >
                            <RephraseModal
                                setRephraseModal={setRephraseModal}
                                setMiniTool={setMiniTool}
                                selection={selection}
                                reportInfo={reportInfo}
                                item={rephraseModal.item}
                                isEnable={rephraseModal.isEnable}
                                customInput={customInput}
                                setCustomInput={setCustomInput}
                                customUseCase={customUseCase}
                            />
                        </Popover>

                        <div
                            className={styles.otherOptionsWrapper}
                            style={{
                                visibility: rephraseModal?.isEnable
                                    ? "hidden"
                                    : "visible",
                            }}
                        >
                            {selection.length >= 4 && (
                                <AiDropdown
                                    selection={selection}
                                    quill={quill}
                                    setDropdown={setDropdown2}
                                    toolPos={toolPos}
                                    writer={writer}
                                    setRephraseModal={setRephraseModal}
                                    customInput={customInput}
                                    setCustomInput={setCustomInput}
                                    setIsCustomModule={setIsCustomModule}
                                    customUseCase={customUseCase}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            onClick={(e) => {
                                setFormat(getTextFormat());
                            }}
                            className={styles.toolbar}
                        >
                            <BiBold
                                className={format.bold && styles.active}
                                onClick={boldText}
                                size={20}
                            />
                            <CgFormatItalic
                                className={format.italic && styles.active}
                                onClick={italicText}
                                size={20}
                            />
                            <BiUnderline
                                className={format.underline && styles.active}
                                onClick={underLineText}
                                size={20}
                            />
                            <BiLinkAlt
                                onClick={textLink}
                                className={
                                    (format.link || input.state) &&
                                    styles.active
                                }
                                size={20}
                            />
                            <div className={styles.line}></div>
                            <div
                                className={
                                    styles.heading +
                                    " " +
                                    (format.header == 2 ? styles.active : "")
                                }
                                onClick={h2Text}
                            >
                                H2
                            </div>
                            <div
                                className={
                                    styles.heading +
                                    " " +
                                    (format.header == 3 ? styles.active : "")
                                }
                                onClick={h3Text}
                            >
                                H3
                            </div>
                            <div className={styles.line}></div>
                            <div
                                onClick={(e) => {
                                    setDropdown(!dropdown);
                                }}
                                className={styles.color}
                            >
                                <div className={styles.colorInner}>
                                    A
                                    <div
                                        style={{
                                            backgroundColor: format.color,
                                        }}
                                        className={styles.bg}
                                    ></div>
                                </div>

                                {getIcon(dropdown)}
                                {dropdown && (
                                    <Color
                                        onClick={(color) => colorText(color)}
                                        setDropdown={setDropdown}
                                    />
                                )}
                            </div>
                            <div
                                onClick={(e) => {
                                    setDropdown1(!dropdown1);
                                }}
                                className={styles.color}
                            >
                                <div className={styles.colorInner}>
                                    <BsBrush />
                                    <div
                                        style={{
                                            backgroundColor: format.background,
                                        }}
                                        className={styles.bg}
                                    ></div>
                                </div>

                                {getIcon(dropdown1)}
                                {dropdown1 && (
                                    <Color
                                        onClick={(color) => colorTextBG(color)}
                                        setDropdown={setDropdown1}
                                    />
                                )}
                            </div>

                            <div className={styles.line}></div>
                            <div style={{ paddingLeft: "5px" }}>
                                {getSelectedText(selection).length} Char(s)
                            </div>
                            <div className={styles.line}></div>
                            <div style={{ paddingLeft: "5px" }}>
                                {countWords(getSelectedText(selection))} Word(s)
                            </div>
                            {!isShared && (
                                <>
                                    <div
                                        onClick={(e) => {
                                            setIsCustomModule(true);
                                        }}
                                        className={
                                            styles.color2 +
                                            " " +
                                            (selection.length < 4
                                                ? styles.disabled
                                                : "")
                                        }
                                    >
                                        <Button
                                            text="Cruise AI"
                                            iconReverse={true}
                                            Icon={() => (
                                                <img
                                                    autoSave={true}
                                                    src="https://storage.googleapis.com/scalenut/bc5cc80f-e891-4b2d-bf40-8eed7aeacc0c.svg"
                                                ></img>
                                            )}
                                            style={{
                                                backgroundColor:
                                                    "rgb(201, 227, 255)",
                                                color: "var(--primary-blue)",
                                            }}
                                            fontSize={12}
                                            height={30}
                                            width={100}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div
                            style={{ left: getPos(toolPos.left) + 1 }}
                            className={`${styles.icon}  ${
                                reverse ? styles.icon1R : styles.icon1
                            }`}
                        ></div>
                    </>
                )}
            </div>
        </ClickAwayListener>
    );
}
