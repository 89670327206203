import React from "react";
import { TiTick } from "react-icons/ti";
import { RiArrowGoBackLine } from "react-icons/ri";
import { FiCreditCard } from "react-icons/fi";
import { GrUpgrade } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import SVGIcon from "../../../../componentsV2/atoms/SVGIcon";

export const transactionData = [
  {
    type: "Request",
    icon: <TiTick style={{ color: "white" }} size={18} />,
    color: "#3dd598",
    pillColor: "rgba(61, 213, 152, 0.1)",
  },
  {
    type: "Recharge",
    icon: <RiArrowGoBackLine style={{ color: "white" }} size={18} />,
    color: "#ff974a",
    pillColor: "rgba(255, 151, 74, 0.1)",
  },
  {
    type: "Adjustment",
    icon: <FiCreditCard style={{ color: "white" }} size={18} />,
    color: "#50b5ff",
    pillColor: "rgba(80, 181, 255, 0.1)",
  },
  {
    type: "Upgrade",
    icon: <GrUpgrade style={{ color: "white" }} size={18} />,
    color: "#Adff2f",
    pillColor: "rgba(173, 255, 47, 0.1)",
  },
  {
    type: "Failed",
    icon: <IoMdClose style={{ color: "white" }} size={18} />,
    color: "#fc5a5a",
    pillColor: "rgba(252, 90, 90, 0.1)",
  },
  {
    type: "InValid",
    icon: <IoMdClose style={{ color: "white" }} size={18} />,
    color: "black",
    pillColor: "rgba(0,0,0, 0.1)",
  },

  {
    type: "monthly quota",
    icon: <SVGIcon src="/AssetsV2/seo-reports-icon.svg" size={30} />,
    color: "#d938e5",
    pillColor: "rgba(217, 56, 229, 0.1)",
  },
  {
    type: "deduction",
    icon: <SVGIcon src="/AssetsV2/seo-reports-deduction.svg" size={30} />,
    color: "#863bb0",
    pillColor: "rgba(134, 59, 176, 0.1)",
  },
  {
    type: "credited",
    icon: <TiTick style={{ color: "white" }} size={18} />,
    color: "#3dd598",
    pillColor: "rgba(61,213,152, 0.1)",
  },
];
