import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.css";
import IssueTable, { Tag } from "./IssueTable";
import { BASE_URL, INTERLINKING_DOMAIN_STATUS, endPoints } from "./constants";

import {
    PAGES_TYPE,
    PAGE_CATEGORY,
    ROWS_PER_PAGE,
    WEBTUNE_DOMAIN_STATUS,
} from "./constants";
import { FiFilter } from "react-icons/fi";
import { CategoryBtn } from "./LinkingSuggestionModal";
import Button from "../../atoms/Button/Button";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import LocalPagination from "../../atoms/LocalPagination/LocalPagination";
import SearchBar from "../../atoms/SearchBar/SearchBar";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import useHelperFunctions from "../../helperFunctions";
import { debounce } from "@material-ui/core";
import CrossSVG from "../../../components/variants/UI/crossSvg";
import { BiCross } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";
import { InterlinkingContext } from "./InterLinkingContextProvider";

function Issues({
    setLinkingModal,
    selectedDomainInfo,
    processingModalContainerRef,
    getIssueCount = () => {},
    children,
    showLoadingModal = false,
    historyModal,
}) {
    const [filters, setFilters] = useState({
        search: "",
        type: PAGES_TYPE.all,
        linkStatusTypes: [],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const { lmManager } = useLinkManagerMixpanel();
    const [filteredPages, setFilteredPages] = useState(null);
    const sectionRef = useRef(null);

    const pages = useInterLinkingPages(
        selectedDomainInfo.id,
        selectedDomainInfo?.interlinking_status,
        selectedDomainInfo.status,
        historyModal,
    );

    const [selectedPagePages, setSelectedPagePages] = useState(null);

    useEffect(() => {
        if (pages) {
            setFilteredPages((ps) => {
                return pages.filter((page) => {
                    if (
                        !page.page_url.includes(filters.search) ||
                        (filters.linkStatusTypes.length &&
                            !filters.linkStatusTypes.includes(page.category))
                    )
                        return false;
                    else return true;
                });
            });
            setCurrentPage(1);
        }
    }, [pages, filters]);

    useEffect(() => {
        if (pages && filteredPages) {
            setSelectedPagePages(filteredPages.slice(0, ROWS_PER_PAGE));
        }
    }, [pages, filteredPages]);

    useEffect(() => {
        if (filteredPages) {
            setSelectedPagePages(
                filteredPages.slice(
                    (currentPage - 1) * ROWS_PER_PAGE,
                    currentPage * ROWS_PER_PAGE,
                ),
            );

            document
                .getElementsByClassName(styles.container)[0]
                .scrollTo(0, sectionRef.current.offsetTop);
        }
    }, [currentPage]);

    const handleSearchChange = useCallback(
        debounce((t) => {
            lmManager({
                ctaType: "Link Manager",
                ctaUsed: "Search",
            });
            setFilters((ps) => ({
                ...ps,
                search: t.target.value,
            }));
        }, 300),
        [],
    );

    // useEffect(() => {
    //     setFilters((ps) => ({
    //         ...ps,
    //         type: selected == 0 ? PAGES_TYPE.all : PAGES_TYPE.orphan,
    //     }));
    // }, [selected]);

    return (
        <div
            style={{
                padding: 20,
                paddingBottom: 50,
                paddingTop: 0,
                position: "relative",
                ...(showLoadingModal && {
                    height: "100%",
                }),
            }}
            className={`${styles.section}`}
            ref={sectionRef}
        >
            <div
                ref={processingModalContainerRef}
                className={styles.processingModalContainer}
                style={{ display: children ? "flex" : "none" }}
            >
                {children}
            </div>

            <div className={styles.filtersContainer}>
                <div
                    className={styles.filters}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                    <div style={{ display: "flex" }}>
                        <h2
                            style={{
                                fontWeight: "500",
                                display: "flex",
                                alignItems: "center",
                                marginTop: 20,
                                fontSize: 20,
                                color: "#2c3948",
                            }}
                        >
                            Internal Linking{" "}
                            {/* <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "4px 7px",
                                    fontSize: "13px",
                                    marginLeft: "8px",
                                    background: "#3f557510",
                                    fontWeight: 500,
                                    borderRadius: 8,
                                }}
                            >
                                {cats[0].count}
                            </span> */}
                        </h2>
                        {/* {cats.map((cat, index) => (
                            <CategoryBtn
                                sectionId={index}
                                title={cat.title}
                                count={cat.count || 0}
                                selected={index === selected}
                                catKey={index}
                                setSelectedCat={setSelected}
                                isFilterActive={() => {}}
                                setDisableAutoScroll={() => {}}
                                scanningPages={!pages}
                            />
                        ))} */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <SearchBar
                            onChange={handleSearchChange}
                            minimal={true}
                            loading={false}
                        />
                        <FilterMenu setFilters={setFilters} filters={filters} />
                    </div>
                </div>
                {!!filteredPages && !!filteredPages.length && (
                    <div className={styles.resultsCount}>
                        Showing {(currentPage - 1) * 30 + 1} to{" "}
                        {filteredPages.length < currentPage * 30
                            ? filteredPages.length
                            : currentPage * 30}{" "}
                        results
                    </div>
                )}
            </div>

            <IssueTable
                setLinkingModal={setLinkingModal}
                pages={pages ? selectedPagePages : null}
                getIssueCount={getIssueCount}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "10px 42px",
                }}
            >
                {!!filteredPages && filteredPages.length > ROWS_PER_PAGE && (
                    <LocalPagination
                        pageCount={Math.ceil(
                            filteredPages.length / ROWS_PER_PAGE,
                        )}
                        currentPage={currentPage - 1}
                        onPageChange={(e) => {
                            setCurrentPage(e.selected + 1);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

const useInterLinkingPages = (id, status, webtuneStatus, historyModal) => {
    const [pages, setPages] = useState(null);
    const { postData } = useHelperFunctions();
    const { setState } = useContext(InterlinkingContext);
    useEffect(() => {
        if (status === INTERLINKING_DOMAIN_STATUS.SUCCESS && !historyModal)
            (async () => {
                setPages(null);
                const res = await postData({
                    completeUrl: BASE_URL + endPoints.interlinkPages,
                    payload: { domain_id: id },
                });
                if (res.status == 200) {
                    setState((ps) => {
                        return {
                            ...ps,
                            selectedDomainInfo: {
                                ...ps.selectedDomainInfo,
                                feedbackShow: res.data.feedbackShow,
                            },
                        };
                    });
                    setPages(
                        res.data.formatData.sort(
                            (a, b) =>
                                // b.opportunities_count - a.opportunities_count,
                                b.new_suggestion - a.new_suggestion,
                        ),
                    );
                }
            })();
    }, [status, historyModal]);

    return pages;
};

const FilterMenu = ({ setFilters, filters }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const callback = () => setOpen(false);
        document.addEventListener("click", callback);

        return () => document.removeEventListener("click", callback);
    }, []);

    const isFilterActive = filters.linkStatusTypes.length;

    return (
        <>
            <LightTooltip
                placement="bottom-end"
                tooltipStyle={{
                    border: "none",
                    backgroundColor: "white",
                    padding: 0,
                }}
                interactive
                disableFocusListener
                title={
                    <Menu
                        setFilters={setFilters}
                        types={filters.linkStatusTypes}
                        setOpen={setOpen}
                    ></Menu>
                }
                open={open}
            >
                <div onClick={(e) => e.stopPropagation()}>
                    <Button
                        Icon={() => <FiFilter size={18} />}
                        style={{
                            // backgroundColor: isFilterActive
                            //     ? "#014dc51a"
                            //     : "#ACB3BF1A",
                            fontWeight: 400,

                            color: isFilterActive
                                ? "#1f4cbe"
                                : !open
                                ? "#ACB3BF"
                                : "#1f4cbe",
                            backgroundColor: "white",
                            borderRadius: "var(--border-radius-8x)",
                            border: `1px solid ${
                                isFilterActive || open ? "#1f4cbe" : "#e1e4ed"
                            }`,
                        }}
                        width={40}
                        text=""
                        handler={() => setOpen(!open)}
                        height={36}
                        customClass={styles.filterMenuBtn}
                    ></Button>
                </div>
            </LightTooltip>
        </>
    );
};

const Menu = ({ types, setFilters, setOpen }) => {
    const handleCheck = (checked, value) => {
        if (checked)
            setFilters((ps) => ({
                ...ps,
                linkStatusTypes: Array.from(
                    new Set([...ps.linkStatusTypes, value]),
                ),
            }));
        else {
            setFilters((ps) => ({
                ...ps,
                linkStatusTypes: ps.linkStatusTypes.filter((i) => i != value),
            }));
        }
    };
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            style={{
                padding: 10,
                boxShadow: "0 0 5px 0px gray",
                borderRadius: 3,
            }}
        >
            <span
                onClick={() => setOpen(false)}
                style={{
                    float: "right",
                    cursor: "pointer",
                }}
            >
                <IoClose size={16} color="grey" />
            </span>
            <h3
                style={{
                    marginInline: 10,
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#2a4266",
                }}
            >
                Link Status
            </h3>

            {Object.entries(PAGE_CATEGORY).map(([key, value], i) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",

                            margin: 10,
                        }}
                    >
                        <CheckBox
                            props={{ checked: types.includes(value) }}
                            label={""}
                            handler={(e) =>
                                handleCheck(e.target.checked, value)
                            }
                        ></CheckBox>{" "}
                        <Tag suggestions={false} type={value} />
                    </div>
                );
            })}
        </div>
    );
};

export default Issues;
