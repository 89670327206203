import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import WriteToEditor from "./Toolbar/WriteToEditor";

export const Emoji = ({ setEmoji, emojiIconRef }) => {
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => setEmoji(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        style={{
          position: "fixed",
          zIndex: 111,
          left: emojiIconRef?.current?.getBoundingClientRect().x - 333,
          top: emojiIconRef?.current?.getBoundingClientRect().y + 30,
        }}
      >
        <Picker
          native
          showPreview={false}
          onSelect={(e) => {
            try {
              WriteToEditor({
                data: e.native,
                type: "text",
                source: "api",
                length: e.native.length,
              });
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </div>
    </ClickAwayListener>
  );
};
