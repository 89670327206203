import { Skeleton } from "@material-ui/lab";
import React from "react";
import styles from "./LoaderGoNuts.module.css";

function CruiseSkeleton() {
    const header = (
        <Skeleton
            style={{
                height: 60,
                marginBottom: 50,
                width: "70%",
                opacity: 0.5,
            }}
        ></Skeleton>
    );
    return (
        <div className={styles.cruiseSkeleton}>
            <div>
                {header}
                {new Array(25).fill(1).map((_, i) => (
                    <>
                        <Skeleton
                            style={{
                                height: 40,
                                marginLeft: i % 3 == 0 ? 0 : 30,
                                opacity: 0.5,
                            }}
                        ></Skeleton>
                    </>
                ))}
            </div>
            <div>
                {header}
                {new Array(20).fill(1).map(() => (
                    <Skeleton
                        style={{
                            height: 40,
                            opacity: 0.5,
                        }}
                    ></Skeleton>
                ))}
            </div>
        </div>
    );
}

export default CruiseSkeleton;
