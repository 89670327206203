import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import ReactDOM from "react-dom";
import Header from "./Header";
import Metrics from "./Metrics";
import Issues from "./Issues";
// @ts-ignore
import styles from "./styles.module.css";
import LinkingSuggestionModal from "./LinkingSuggestionModal";
import InterLinkingContextProvider, {
    DomainInfo,
    InterlinkingContext,
} from "./InterLinkingContextProvider";
import LinkingOnBoardFlow from "./LinkingOnBoardFlow";
import { ProcessingPagesModal } from "../WebTune/LoginWebtune";
import {
    BASE_URL,
    INTERLINKING_DOMAIN_STATUS,
    TRAIL_INTERLINKING_LIMIT,
    WEBTUNE_DOMAIN_STATUS,
    endPoints,
} from "./constants";
import PublishStrip from "./PublishStrip";
import { useSelector } from "react-redux";

import DomainVerification from "../WebTune/DomainVerification";
import ReviewTable from "./ReviewTable";
import InterLinkingGscBarInfo from "./InterLinkingGscBarInfo";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Button from "../../atoms/Button/Button";
import { socketEvents } from "../../../Sockets/constants";
import { socket } from "../../../Sockets/useSockets";
import useHelperFunctions from "../../helperFunctions";
import Progress from "../../atoms/Progress/Progress";
import InterlinkingLanding from "./InterlinkingLanding";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import NoActiveLMSubs from "./NoActiveLMSubs";
import SitemapModal from "./SitemapModal";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";
import { useHistory } from "react-router-dom";

function Interlinking() {
    const [linkingModal, setLinkingModal] = useState(false);
    const [review, setReview] = useState(false);
    const [firstLanding, setFirstLanding] = useState(true);

    const processingModalContainerRef = useRef(null);
    const { loading, selectedDomainInfo, setState, updateContext } =
        useContext(InterlinkingContext);
    const { isFreeForever } = useUserAccessHook();
    const [sitemapModal, setSitemapModal] = useState({
        isShow: "",
        domainName: "",
    });
    const [historyModal, setHistoryModal] = useState(false);
    const [totalApprovedCount, setTotalApprovedCount] = useState(0);
    const { postData } = useHelperFunctions();
    const getUserDomains = useDomains();
    const processState = useHandleProcessing({
        setSitemapModal,
        getUserDomains,
    });
    const { lmManagerReport } = useLinkManagerMixpanel();
    const otherInfo = useSelector((state: any) => state.otherInfo);

    const show_lm_landing = useSelector(
        (state: any) => state.auth.show_lm_landing,
    );

    const isElite = true;
    // otherInfo?.subscriptionInfo?.plan_category == PLANS_CATEGORIES?.elite ||
    // false;

    useEffect(() => {
        if (selectedDomainInfo) {
            setReview(false);
        }
    }, [selectedDomainInfo]);
    const history = useHistory();
    useEffect(() => {
        if (selectedDomainInfo) {
            const queryParams = new URLSearchParams(window.location.search);
            const source = queryParams.get("email");
            const process = queryParams.get("process");
            const verify = queryParams.get("verify");
            const refresh = queryParams.get("refresh");
            console.log("*** source", source);
            console.log("*** process", process);
            console.log("*** verify", verify);
            console.log("*** refresh", refresh);
            lmManagerReport({
                source: source ? "Email" : "-",
                mailType: process
                    ? "Process"
                    : verify
                    ? "Verify"
                    : refresh
                    ? "Refresh"
                    : "-",
                typeStatus: process
                    ? process?.toUpperCase()
                    : verify
                    ? verify?.toUpperCase()
                    : refresh
                    ? refresh?.toUpperCase()
                    : "-",
                dmName: selectedDomainInfo?.domain,
                dmId: selectedDomainInfo?.id,
            });
            history.replace("/admin/link-manager");
        }
    }, [window.location.pathname, selectedDomainInfo?.id]);

    useEffect(() => {}, [selectedDomainInfo?.re_auth]);

    useEffect(() => {
        if (show_lm_landing == 1) {
            setFirstLanding(true);
        } else {
            setFirstLanding(false);
        }
    }, [show_lm_landing]);

    useEffect(() => {
        if (
            selectedDomainInfo?.id &&
            selectedDomainInfo?.status === 4 &&
            !review
        ) {
            getIssueCount();
        }
    }, [selectedDomainInfo?.id, review]);

    const getIssueCount = async () => {
        const res = await postData({
            completeUrl: BASE_URL + endPoints.issuesCount,
            payload: {
                status: 0,
                domain_id: selectedDomainInfo?.id,
            },
        });
        if (res.status === 200) {
            setTotalApprovedCount(+res.data.issue_count || 0);
        }
    };

    const showLoadingModal =
        selectedDomainInfo?.status == WEBTUNE_DOMAIN_STATUS.IN_PROGRESS ||
        selectedDomainInfo?.status == WEBTUNE_DOMAIN_STATUS.NOT_STARTED ||
        selectedDomainInfo?.interlinking_status ==
            INTERLINKING_DOMAIN_STATUS.NOT_STARTED ||
        selectedDomainInfo?.interlinking_status ==
            INTERLINKING_DOMAIN_STATUS.IN_PROGRESS;

    if (otherInfo?.subscriptionInfo?.interlinking_status == 0 && !isFreeForever)
        return <NoActiveLMSubs />;
    if (loading) return <Progress height={"100%"} width={"100%"} />;
    return (
        <div className={styles.container}>
            {firstLanding || isFreeForever ? (
                <InterlinkingLanding
                    firstLanding={firstLanding}
                    setFirstLanding={setFirstLanding}
                />
            ) : selectedDomainInfo ? (
                <>
                    <Fragment key={selectedDomainInfo?.id}>
                        <Header
                            // @ts-ignore
                            setSitemapModal={setSitemapModal}
                        />
                        {review ? (
                            <ReviewTable getIssueCount={getIssueCount} />
                        ) : (
                            <>
                                {!!selectedDomainInfo.gsc_id &&
                                    !selectedDomainInfo.re_auth &&
                                    selectedDomainInfo.is_verified !== 1 &&
                                    selectedDomainInfo.interlinking_status ==
                                        INTERLINKING_DOMAIN_STATUS.SUCCESS && (
                                        <div
                                            style={{
                                                margin: "10px 20px 0 20px",
                                            }}
                                        >
                                            <DomainVerification
                                                screen={"Link Manager"}
                                                connectDomainRef={null}
                                                domainContext={{
                                                    setDomainVerification: (
                                                        is_verified,
                                                    ) => {
                                                        updateContext(
                                                            {
                                                                is_verified,
                                                            },
                                                            selectedDomainInfo.id,
                                                        );
                                                    },
                                                    setIsDomainConnected:
                                                        () => {},
                                                    ...selectedDomainInfo,
                                                    domain_id:
                                                        selectedDomainInfo.id,
                                                    getUserDomains: () => {},
                                                }}
                                            />
                                        </div>
                                    )}
                                {(!selectedDomainInfo.gsc_id ||
                                    !!selectedDomainInfo?.re_auth) &&
                                    selectedDomainInfo.status ==
                                        WEBTUNE_DOMAIN_STATUS.SUCCESS && (
                                        <InterLinkingGscBarInfo
                                            isFindingOpr={false}
                                            isGscConnected={
                                                !!selectedDomainInfo.gsc_id &&
                                                !selectedDomainInfo.re_auth
                                            }
                                            isReAuth={
                                                !!selectedDomainInfo.re_auth
                                            }
                                            domainId={selectedDomainInfo.id}
                                        />
                                    )}
                                <Metrics
                                    historyModal={historyModal}
                                    setHistoryModal={setHistoryModal}
                                />
                                <Issues
                                    historyModal={historyModal}
                                    setLinkingModal={setLinkingModal}
                                    selectedDomainInfo={selectedDomainInfo}
                                    processingModalContainerRef={
                                        processingModalContainerRef
                                    }
                                    showLoadingModal={showLoadingModal}
                                >
                                    {showLoadingModal ? (
                                        <ProcessingPagesModal
                                            scanningPages={
                                                processState.isScanningPages
                                            }
                                            pages={processState.pages}
                                            domainName={
                                                selectedDomainInfo.domain
                                            }
                                            totalCrawledPages={{
                                                ...processState,
                                            }}
                                            isLoadingPages={
                                                processState.isLoadingPages
                                            }
                                            domain={selectedDomainInfo.domain}
                                            stable_version={
                                                selectedDomainInfo.stable_version
                                            }
                                            findingOpportunities={
                                                selectedDomainInfo.interlinking_status ==
                                                INTERLINKING_DOMAIN_STATUS.IN_PROGRESS
                                            }
                                        />
                                    ) : null}
                                </Issues>
                            </>
                        )}
                        {!!linkingModal && (
                            <LinkingSuggestionModal
                                open={linkingModal}
                                setOpen={setLinkingModal}
                                getIssueCount={getIssueCount}
                            />
                        )}
                        <PublishStrip
                            review={review}
                            setReview={setReview}
                            isVerified={
                                !!(selectedDomainInfo?.is_verified === 1)
                            }
                            totalApprovedCount={totalApprovedCount}
                        />
                    </Fragment>
                </>
            ) : (
                <LinkingOnBoardFlow
                    // @ts-ignore
                    setSitemapModal={setSitemapModal}
                />
            )}

            {sitemapModal?.isShow && (
                <SitemapModal
                    // @ts-ignore
                    setModal={setSitemapModal}
                    sitemapModal={sitemapModal}
                />
            )}
        </div>
    );
}

export const useDomains = () => {
    const { setState, selectedDomainInfo } = useContext(InterlinkingContext);
    const selectedDomainInfoRef = useRef(null as any);
    selectedDomainInfoRef.current = selectedDomainInfo;
    const { getData } = useHelperFunctions();
    const getUserDomains = async (loadingRequired?: true) => {
        if (loadingRequired)
            setState((ps) => ({
                ...ps,
                loading: true,
            }));
        const res = await getData({
            completeUrl: BASE_URL + endPoints.userDomains,
            skipErrorMsg: true,
        });

        if (res.status === 200) {
            const validDomains = res.data.filter(
                (domain: DomainInfo) => domain,
            );
            if (!!validDomains.length) {
                setState((ps) => ({
                    ...ps,
                    domainsList: validDomains,
                    selectedDomainInfo:
                        validDomains.find(
                            (domain) =>
                                domain.id == selectedDomainInfoRef.current?.id,
                        ) ||
                        validDomains.find((domain) => domain.is_active) ||
                        validDomains[0],
                }));
            }
        }
        setState((ps) => ({
            ...ps,
            loading: false,
        }));
    };

    useEffect(() => {
        getUserDomains();
    }, []);

    return getUserDomains;
};

const useHandleProcessing = ({ setSitemapModal, getUserDomains }) => {
    const { selectedDomainInfo, updateContext, domainsList } =
        useContext(InterlinkingContext);
    const [processState, setProcessState] = useState({
        pages: 0,
        isLoadingPages: true,
        isScanningPages: true,
        limit: 0,
        crawled: 0,
        processPercent: 0,
    });

    const otherInfo = useSelector((state: any) => state?.otherInfo);

    useEffect(() => {
        if (
            !selectedDomainInfo ||
            !(
                selectedDomainInfo?.status ==
                    WEBTUNE_DOMAIN_STATUS.IN_PROGRESS ||
                selectedDomainInfo?.status ==
                    WEBTUNE_DOMAIN_STATUS.NOT_STARTED ||
                selectedDomainInfo?.interlinking_status ==
                    INTERLINKING_DOMAIN_STATUS.NOT_STARTED ||
                selectedDomainInfo?.interlinking_status ==
                    INTERLINKING_DOMAIN_STATUS.IN_PROGRESS
            )
        ) {
            setProcessState({
                pages: 0,
                isLoadingPages: true,
                isScanningPages: true,
                limit: 0,
                crawled: 0,
                processPercent: 0,
            });
        } else if (
            selectedDomainInfo?.status == WEBTUNE_DOMAIN_STATUS.SUCCESS &&
            selectedDomainInfo?.interlinking_status ==
                INTERLINKING_DOMAIN_STATUS.IN_PROGRESS
        ) {
            setProcessState((ps) => ({
                ...ps,
                pages: selectedDomainInfo.stable_version_total_pages,
                isLoadingPages: false,
                isScanningPages: false,
                limit: selectedDomainInfo.stable_version_total_pages,
                crawled: selectedDomainInfo.stable_version_total_crawled_pages,
            }));
        }
    }, [selectedDomainInfo]);
    const domainsListRef = useRef(null as any);
    domainsListRef.current = domainsList;
    const { lmManagerReport } = useLinkManagerMixpanel();
    const selectedDomainInfoRef = useRef(null as any);
    selectedDomainInfoRef.current = selectedDomainInfo;
    const otherInfoRef = useRef({} as any);
    otherInfoRef.current = otherInfo;

    const scrapeSuccess = (obj: any) => {
        if (obj?.domain_id == selectedDomainInfoRef?.current?.id) {
            lmManagerReport({
                source: "-",
                mailType: "-",
                typeStatus: "-",
                dmName: selectedDomainInfoRef?.current?.domain,
                dmId: obj?.domain_id,
            });
        }
        setProcessState((ps) => ({
            ...ps,
            isLoadingPages: false,
            isScanningPages: false,
        }));
        getUserDomains(false);
    };
    const scrapeProcess = (obj: any) => {
        updateContext(
            {
                status: WEBTUNE_DOMAIN_STATUS.IN_PROGRESS,
            },
            obj.domain_id,
        );

        setProcessState((ps) => ({
            ...ps,
            pages: obj.count,
            limit: obj.processed_count,
            crawled: obj.crawled_count,
            isLoadingPages: false,
        }));
    };
    const scrapeFail = (obj: any) => {
        if (obj.domain_id != selectedDomainInfoRef.current?.id) return;
        setSitemapModal((ps) => ({ ...ps, isShow: true }));
        setProcessState((ps) => ({
            ...ps,
            isLoadingPages: false,
            isScanningPages: false,
        }));
    };

    const scrapeSuccessLinking = (obj: any) => {
        updateContext(
            {
                interlinking_status: INTERLINKING_DOMAIN_STATUS.SUCCESS,
            },
            obj.domain_id,
        );
    };

    const scrapeProcessLinking = (obj: any) => {
        updateContext(
            {
                interlinking_status: INTERLINKING_DOMAIN_STATUS.IN_PROGRESS,
            },
            obj.domain_id,
        );
        setProcessState((ps) => ({
            ...ps,
            processPercent: obj?.processedPercent || 0,
        }));
    };

    const scrapeFailLinking = (obj: any) => {
        updateContext(
            {
                interlinking_status: INTERLINKING_DOMAIN_STATUS.FAILED,
            },
            obj.domain_id,
        );
    };

    const removeSockets = useCallback(() => {
        socket.off(socketEvents.WEBTUNE_SCRAPE_SUCCESS, scrapeSuccess);
        socket.off(socketEvents.INTERLINKING_SCRAPE_PROCESS, scrapeProcess);
        socket.off(socketEvents.WEBTUNE_SCRAPE_FAIL, scrapeFail);
        socket.off(
            socketEvents.INTERLINKING_SCRAPE_SUCCESS,
            scrapeSuccessLinking,
        );
        socket.off(
            socketEvents.INTERLINKING_SCRAPE_PROCESS,
            scrapeProcessLinking,
        );
        socket.off(socketEvents.INTERLINKING_SCRAPE_FAIL, scrapeFailLinking);
    }, []);

    const attachSockets = useCallback(() => {
        socket.on(socketEvents.WEBTUNE_SCRAPE_SUCCESS, scrapeSuccess);
        socket.on(socketEvents.WEBTUNE_SCRAPE_PROCESS, scrapeProcess);
        socket.on(socketEvents.WEBTUNE_SCRAPE_FAIL, scrapeFail);
        socket.on(
            socketEvents.INTERLINKING_SCRAPE_SUCCESS,
            scrapeSuccessLinking,
        );
        socket.on(
            socketEvents.INTERLINKING_SCRAPE_PROCESS,
            scrapeProcessLinking,
        );
        socket.on(socketEvents.INTERLINKING_SCRAPE_FAIL, scrapeFailLinking);
    }, []);

    useEffect(() => {
        if (selectedDomainInfo) {
            attachSockets();
            return removeSockets;
        }
    }, [selectedDomainInfo]);

    return processState;
};

const RedirectToPlans = () => {
    const goto = useNavigation();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: " center",
                width: "100%",
            }}
        >
            <div className={styles.redirectToPlansWrapper}>
                <div className={styles.infoForElite}>
                    <div className={styles._line1}>
                        Internal Linking is available on{" "}
                        <span
                            onClick={() => {
                                goto("BILLING_PLANS");
                            }}
                            style={{
                                color: "#1f4cbe",
                                cursor: "pointer",
                            }}
                        >
                            Elite
                        </span>{" "}
                        Plan.
                    </div>
                    <div className={styles._line2}>
                        Upgrade now to enjoy you AI-powered Linking
                        Opportunities
                    </div>
                </div>
                <Button
                    text={"Upgrade Now"}
                    handler={() => {
                        goto("BILLING_PLANS");
                    }}
                    width={180}
                />
            </div>
        </div>
    );
};

export default () => (
    <InterLinkingContextProvider>
        <Interlinking />
    </InterLinkingContextProvider>
);
