import React, { useState, useRef } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import styles from "./WordpressApp.module.css";
import "./dropdown.css";

import { Box, MenuItem, FormControl, Select } from "@material-ui/core";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

export default function DropdownUI({
    optionsData,
    selectedOption,
    setSelectedOption,
    setAddAccountModal,
    setRemoveDomainModal,
    setRemoveDomainName,
    setCurrentAccount,
    company_user_type,
    role,
}) {
    // const [addAccountModal, setAddAccountModal] = useState(false);

    const handleChange = (event) => {
        if (event.target.value == -1) {
            setAddAccountModal(true);
        } else {
            setSelectedOption(event.target.value);
            setCurrentAccount(event.target.value);
        }
    };
    return (
        <Box style={{ width: "100%" }} className={styles.parentContainer}>
            <FormControl fullWidth>
                <Select
                    value={selectedOption}
                    label="Domain"
                    // key={selectedOption.id}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <div
                            style={{
                                whiteSpace: "nowrap",
                                width: "90%",
                                marginLeft: "-10px",
                                overflow: "hidden",
                                marginTop: "-4px",
                                height: "40px",
                            }}
                        >
                            <div className={styles.dropdown}>
                                <div className={styles.siteIcon}>
                                    <img
                                        loading="lazy"
                                        width="28px"
                                        height="28px"
                                        src={
                                            selected?.site_icon
                                                ? selected?.site_icon
                                                : "/New UI/SVG/web.svg"
                                        }
                                        alt=""
                                    />{" "}
                                </div>
                                <div>
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={selected?.title}
                                    >
                                        <div className={styles.dropDownTitle}>
                                            {selected?.title}
                                        </div>
                                    </LightTooltip>
                                    <div className={styles.domain}>
                                        {" "}
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title={selected?.domain}
                                        >
                                            <span className={styles.domainName}>
                                                {selected?.domain}
                                            </span>
                                        </LightTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                >
                    {optionsData &&
                        optionsData.map((option) => (
                            <MenuItem key={option.crd_id} value={option}>
                                <div
                                    style={{
                                        whiteSpace: "nowrap",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className={styles.dropdown}
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className={styles.siteIcon}>
                                                <img
                                                    loading="lazy"
                                                    width="28px"
                                                    height="28px"
                                                    src={
                                                        option?.site_icon
                                                            ? option?.site_icon
                                                            : "/New UI/SVG/web.svg"
                                                    }
                                                    alt=""
                                                />{" "}
                                            </div>

                                            <div>
                                                <div
                                                    className={
                                                        styles.dropDownTitle
                                                    }
                                                >
                                                    {option?.title}
                                                </div>
                                                <div className={styles.domain}>
                                                    {" "}
                                                    <span
                                                        className={
                                                            styles.domainName
                                                        }
                                                    >
                                                        {option?.domain}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {optionsData.length > 1 &&
                                            company_user_type != 2 &&
                                            role != "free_lancer" && (
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setRemoveDomainModal(
                                                            true,
                                                        );
                                                        setRemoveDomainName(
                                                            option?.title,
                                                        );
                                                        e.stopPropagation();
                                                    }}
                                                    className={styles.removeBtn}
                                                >
                                                    -
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </MenuItem>
                        ))}
                    {company_user_type != 2 && role != "free_lancer" && (
                        <MenuItem value={-1}>
                            <div className={styles.addAccountBtn}>
                                <span
                                    style={{
                                        fontSize: "18px",
                                        padding: "4px",
                                        paddingBottom: "6px",
                                    }}
                                >
                                    +
                                </span>{" "}
                                Add Account
                            </div>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}
