import Quill from "quill";
import Showdown from "../../../../lib/showdownmin";

const Delta = Quill.import("delta");

let timeout = null;
export default function (params) {
    try {
        if (document.quill.editor.isEnabled() || params.allowed) {
            if (params.type == "text")
                document.quill.editor.insertText(
                    params.dummyCursor != undefined
                        ? params.dummyCursor
                        : document.quill.editor.cursor,
                    params.data,
                    params.source,
                );
            else {
                document.quill.editor.insertEmbed(
                    params.dummyCursor
                        ? params.dummyCursor
                        : document.quill.editor.cursor,
                    params.type,
                    params.data,
                    params.source,
                );
            }
            document.quill.editor.cursor += params?.length;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                try {
                    document.quill.editor.setSelection(
                        params.dummyCursor
                            ? params.dummyCursor
                            : document.quill.editor.cursor,
                    );

                    document.quill.editor.focus();
                } catch (err) {
                    console.log(err);
                }
            }, 200);
        }
    } catch (err) {
        console.log(err);
    }
}

export const HtmlTagToDelta = (
    text = "",
    tag = "",
    format = {},
    altText = "",
    imgSrc = "",
) => {
    switch (tag) {
        case "img":
            return [
                {
                    insert: {
                        image: imgSrc,
                    },
                    attributes: {
                        alt: altText,
                    },
                },
                {
                    insert: "\n",
                },
            ];
        case "h3":
            return [
                {
                    insert: text,
                    attributes: format,
                },
                {
                    attributes: {
                        header: 3,
                    },
                    insert: "\n",
                },
            ];
        case "h2":
            return [
                {
                    insert: text,
                    attributes: format,
                },
                {
                    attributes: {
                        header: 2,
                    },
                    insert: "\n",
                },
            ];
        case "h1":
            return [
                {
                    insert: text,
                    attributes: format,
                },
                {
                    attributes: {
                        header: 1,
                    },
                    insert: "\n",
                },
            ];

        case "p": {
            return [
                {
                    insert: text,
                    attributes: format,
                },
                {
                    insert: "\n",
                },
            ];
        }

        case "text": {
            return [
                {
                    insert: text,
                },
            ];
        }

        case "br":
            return [
                {
                    insert: "\n",
                },
            ];

        case "a":
            return [
                {
                    attributes: {
                        link: text,
                        ...format,
                    },
                    insert: text,
                },
            ];
        case "ul":
            return [
                ...HtmlTagToDelta(text, "text"),
                {
                    attributes: {
                        list: "bullet",
                    },
                    insert: "\n",
                },
            ];
        case "ol":
            return [
                ...HtmlTagToDelta(text, "text"),
                {
                    attributes: {
                        list: "ordered",
                    },
                    insert: "\n",
                },
            ];
        case "bold":
            return [
                {
                    insert: text,
                    attributes: { bold: true },
                },
            ];
        default:
            return [];
    }
};

export const markdownToDelta = (text) => {
    const converter = new Showdown.Converter({
        tables: true,
        simpleLineBreaks: true,
    });
    if (document.quill) {
        const delta = document.quill.editor.clipboard.convert({
            html: converter.makeHtml(text),
        });

        return delta;
    }
    return new Delta();
};
