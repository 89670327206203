import React, { useEffect, useState } from "react";
import styles from "../TrafficAnalyzer.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import LineChart from "../../../atoms/Chart/LineChart";
import Button from "../../../atoms/Button/Button";
import useGscHook, {
    InventoryKeywordObject,
    InventoryV2Object,
} from "../useGscHook";
import useHelperFunctions from "../../../helperFunctions";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { LightTooltipGraph } from "./InventoryV2";
import { useMixpanelTA } from "../useMixpanelTA";

const GraphLabel = [
    "19 May",
    "26 May",
    "02 Jun",
    "09 Jun",
    "16 Jun",
    "23 Jun",
    "30 Jun",
    "07 Jul",
];

const GraphData = [85, 10, 76, 70, 10, 53, 48, 10];
const GraphDataDashed = [85, 10, 76, 70, 10, 53, 48, 48];
const GraphOptions = [
    { icons: "", name: "Total Impressions", key: "impressions" },
    { icons: "", name: "Total Clicks", key: "clicks" },
    { icons: "", name: "Average Rank", key: "average" },
];

type Props_KeyboardItemRow = {
    index: number;
    parentId: string;
    row: InventoryKeywordObject;
    createOptimizerReport: Props_AnalyzerSubCards["createOptimizerReport"];
};

type Props_AnalyzerSubCards = {
    item: InventoryV2Object;
    createOptimizerReport: (page: string, keyword: string) => any;
    parentId: string;
};

const SortByOptions: { title: string; id: number }[] = [
    {
        title: "Avg. Rank",
        id: 1,
    },
    {
        title: "Rank Trend",
        id: 2,
    },

    {
        title: "Traffic",
        id: 3,
    },

    {
        title: "Impressions",
        id: 4,
    },
    {
        title: "CTR ",
        id: 5,
    },
    {
        title: "Volume",
        id: 6,
    },
    {
        title: "KD",
        id: 7,
    },
    {
        title: "CPC",
        id: 8,
    },
    {
        title: "Rank Change",
        id: 9,
    },
];

const KeyboardItemRow = ({
    row,
    createOptimizerReport,
    index,
    parentId,
}: Props_KeyboardItemRow) => {
    const goto = useNavigation();
    const {
        getGradientValues,
        getLineColor,
        trendsSD,
        lastNonZeroValue,
        firstNonZeroValue,
        getValueWithUnit,
        getKDColorTooltip,
    } = useGscHook();
    const { taTrackInventoryOptimizeCta } = useMixpanelTA();
    const [isCreating, setIsCreating] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (row?.report_info.is_completed == 0) {
            setIsCreating(true);
        } else if (row?.report_info.is_completed == 1) {
            setIsCreating(false);
        } else {
            setIsCreating(false);
        }
    }, [row?.report_info?.report_id, row?.report_info?.is_completed]);

    useEffect(() => {
        let timer: any = null;
        if (isCopied) {
            timer = setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);

    const handleCreation = async () => {
        setIsCreating(true);
        const _res = await createOptimizerReport(parentId, row.keyword);
        if (_res.status != 200) {
            setIsCreating(false);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    };

    return (
        <div key={index} className={`${styles.tableRow} ${styles.subTableRow}`}>
            <LightTooltip
                title={row.keyword}
                placement={"bottom"}
                arrow
                onOpen={(e: any) => {
                    if (e.target.scrollWidth <= e.target.offsetWidth) {
                        setImmediate(() => {
                            document
                                .querySelectorAll("[role=tooltip]")
                                .forEach(
                                    (e: any) => (e.style.display = "none"),
                                );
                        });
                    }
                }}
            >
                <div className={styles.row_keyword}>
                    <div className={` ${styles.row_keyword_data}`}>
                        {row.keyword}
                    </div>
                    <LightTooltip
                        placement={"right"}
                        title={isCopied ? "Copied!" : "Click to Copy"}
                        arrow
                    >
                        <div
                            className={styles.copyKeyword}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                copyToClipboard(row.keyword);
                            }}
                        >
                            <SVGIcon src={"/New UI/SVG/copy.svg"} size={"14"} />
                        </div>
                    </LightTooltip>
                </div>
            </LightTooltip>

            <div className={styles.row_avg_rank}>{row.avg_position}</div>
            <div className={styles.row_rank}>
                <LightTooltipGraph
                    interactive
                    title={
                        <div
                            className={styles.tooltipGraphWrapper}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className={styles.tooltipGraph}>
                                <div className={styles.tooltipGraph_title}>
                                    Rank Trend
                                </div>
                                <div
                                    className={styles.tooltipGraph_value}
                                    style={{
                                        background: getLineColor(
                                            +row.classificationColor,
                                        ),
                                    }}
                                >
                                    {firstNonZeroValue(row.position_list)}
                                    <SVGIcon
                                        src={"/New UI/SVG/arrowRight.svg"}
                                    />{" "}
                                    {lastNonZeroValue(row.position_list)}
                                </div>
                            </div>
                            <div className={styles.graphWrapper}>
                                <div className={styles.graphLegend_left}>
                                    Rank
                                </div>
                                <div className={styles.graphWrapperSub}>
                                    <LineChart
                                        isReverseY={true}
                                        data1={row.position_list}
                                        data2={row.dashedPosition}
                                        label={row.trendsDateLabel}
                                        min={0}
                                        max={trendsSD(row.position_list)}
                                        noTick={true}
                                        customTooltip={true}
                                        showAllLabels={true}
                                        tooltipLabel={"Rank #"}
                                        lineHeight={"15px"}
                                        dashedBorder={[6, 6]}
                                        pointRadius={2}
                                        lineColor={getLineColor(
                                            +row.classificationColor,
                                        )}
                                        lineThickness={2}
                                        gradientRange={200}
                                        gradientValues={getGradientValues(
                                            +row.classificationColor,
                                        )}
                                        gradientRange2={200}
                                        gradientValues2={getGradientValues(
                                            +row.classificationColor,
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={styles.graphLegend_bottom}>
                                Date
                            </div>
                        </div>
                    }
                    placement={"bottom"}
                    arrow
                >
                    <div className={styles.row_rankTrendGraph}>
                        <LineChart
                            isReverseY={true}
                            data1={row.position_list}
                            data2={row.dashedPosition}
                            label={row.trendsDateLabel}
                            width={"10px"}
                            height={"60px"}
                            dashedBorder={[4, 5]}
                            min={0}
                            max={trendsSD(row.position_list)}
                            noTick={true}
                            noLabels={true}
                            pointRadius={0}
                            noGrid={true}
                            noTooltip={true}
                            customTooltip={false}
                            lineColor={getLineColor(+row.classificationColor)}
                            lineThickness={2}
                            gradientRange={50}
                            gradientValues={getGradientValues(
                                +row.classificationColor,
                            )}
                            gradientRange2={50}
                            gradientValues2={getGradientValues(
                                +row.classificationColor,
                            )}
                        />
                    </div>
                </LightTooltipGraph>
            </div>
            <div className={styles.row_deltaRank}>
                <div
                    style={{
                        padding: "5px",
                        borderRadius: "3px",
                        minWidth: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    className={`${styles._value} ${
                        Number(firstNonZeroValue(row.position_list)) -
                            Number(lastNonZeroValue(row.position_list)) <
                        0
                            ? styles.lose
                            : Number(firstNonZeroValue(row.position_list)) -
                                  Number(lastNonZeroValue(row.position_list)) >
                              0
                            ? styles.gain
                            : styles.neutral
                    }`}
                >
                    {Number(firstNonZeroValue(row.position_list)) -
                        Number(lastNonZeroValue(row.position_list)) !=
                        0 && (
                        <SVGIcon
                            src="/New UI/SVG/arrowUp.svg"
                            size={12}
                            style={
                                Number(firstNonZeroValue(row.position_list)) -
                                    Number(
                                        lastNonZeroValue(row.position_list),
                                    ) <
                                0
                                    ? {
                                          transform: "rotate(90deg)",
                                          zIndex: 1,
                                      }
                                    : {}
                            }
                        />
                    )}
                    {Math.abs(
                        Number(firstNonZeroValue(row.position_list)) -
                            Number(lastNonZeroValue(row.position_list)),
                    )}
                </div>
            </div>
            <div
                className={`${styles.row_traffic} ${
                    row.delta_clicks == 0 && styles.nullBox
                }`}
            >
                <div className={`${styles.row_font} `}>
                    {getValueWithUnit(row.clicks)}
                    <div
                        className={`${styles.row_change} ${
                            row.delta_clicks < 0 ? styles.lose : styles.gain
                        }`}
                    >
                        <div className={styles._value}>
                            <SVGIcon
                                src="/New UI/SVG/arrowUp.svg"
                                size={9}
                                style={
                                    row.delta_clicks < 0
                                        ? {
                                              transform: "rotate(90deg)",
                                          }
                                        : {}
                                }
                            />
                            {Math.abs(row.delta_clicks)}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.row_impression} ${
                    row.delta_impressions == 0 && styles.nullBox
                }`}
            >
                <div className={styles.row_font}>
                    {getValueWithUnit(row.impressions)}

                    <div
                        className={`${styles.row_change} ${
                            row.delta_impressions < 0
                                ? styles.lose
                                : styles.gain
                        }`}
                    >
                        <div className={styles._value}>
                            <SVGIcon
                                src="/New UI/SVG/arrowUp.svg"
                                size={9}
                                style={
                                    row.delta_impressions < 0
                                        ? {
                                              transform: "rotate(90deg)",
                                          }
                                        : {}
                                }
                            />
                            {getValueWithUnit(Math.abs(row.delta_impressions))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.row_ctr}>
                <div className={styles.row_font}>
                    {Math.round(row.ctr * 100) / 100 + "%"}
                </div>
            </div>
            <div className={styles.row_volume}>
                <div className={styles.row_font}>
                    {getValueWithUnit(row.volume)}
                </div>
            </div>
            {/* <div className={styles.row_cpc}>
                <div className={styles.row_font}>
                    {row.cpc ? Math.round(row.cpc * 100) / 100 : "-"}
                </div>
            </div> */}
            <div className={styles.row_kd}>
                {row.difficulty == null ? (
                    "-"
                ) : (
                    <>
                        <div className={styles.row_font_kd}>
                            <div
                                style={{
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: getKDColorTooltip(
                                        row.difficulty,
                                    ).color,
                                    borderRadius: "50%",
                                }}
                            ></div>{" "}
                            {row.difficulty}
                        </div>
                    </>
                )}
            </div>

            <div
                className={styles.row_button}
                style={{
                    ...((isCreating || row?.report_info?.report_id) && {
                        visibility: "visible",
                    }),
                }}
            >
                <Button
                    fontSize={12}
                    width={110}
                    height={32}
                    text={isCreating ? "Creating..." : "Optimize"}
                    iconReverse={isCreating ? false : true}
                    Icon={() => {
                        if (!isCreating) {
                            return (
                                <SVGIcon
                                    src={`/New UI/SVG/${
                                        row?.report_info?.report_id
                                            ? "linkBoxArrowShort"
                                            : "fixit_wandFill"
                                    }.svg`}
                                    size={14}
                                    style={{
                                        color: "white",
                                        marginRight: "4px",
                                    }}
                                />
                            );
                        }
                    }}
                    loadingText={isCreating ? true : false}
                    handler={() => {
                        let mpObj: any = {
                            page: row?.page || "-",
                            keyword: row.keyword || "-",
                        };
                        if (!row?.report_info?.report_id) {
                            handleCreation();
                            mpObj.status = "Creating";
                        } else if (row?.report_info?.is_completed == 0) {
                            return;
                        } else if (row?.report_info?.is_completed == 1) {
                            mpObj.status = "Optimize";
                            window.open(
                                `/admin/audit/editor/${row?.report_info?.report_id}`,
                                "_blank",
                            );
                        }
                        taTrackInventoryOptimizeCta(mpObj);
                    }}
                />
            </div>
        </div>
    );
};

const AnalyzerSubCards = ({
    item,
    createOptimizerReport,
    parentId,
}: Props_AnalyzerSubCards) => {
    const [order, setOrder] = useState<0 | 1>(1); //0 = asec, 1= desc
    const [sortBy, setSortBy] = useState<{
        id: number;
        title: string | object;
    }>(SortByOptions[2]);

    useEffect(() => {}, []);

    const sortKeywords: (
        a: InventoryKeywordObject,
        b: InventoryKeywordObject,
    ) => number = (a, b) => {
        const lastNonZeroValue = (arr: number[]): string => {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (arr[i] !== 0 && arr[i] !== null) {
                    return Math.round(arr[i]).toString();
                }
            }
            return "-";
        };

        const firstNonZeroValue = (arr: any) => {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] !== 0 && arr[i] !== null) {
                    return Math.round(arr[i]).toString();
                }
            }
            return "-";
        };
        if (order === 1) {
            // Swap a and b when sorting in ascending order
            const temp = b;
            b = a;
            a = temp;
        }
        switch (sortBy.id) {
            case 1:
                return +a.avg_position - +b.avg_position;
            case 2:
                const classificationOrder = [11, 12, 13];
                const classificationA = classificationOrder.indexOf(
                    +a.classificationColor,
                );
                const classificationB = classificationOrder.indexOf(
                    +b.classificationColor,
                );

                if (classificationA === classificationB) {
                    // Same classification, sort by trendsStatus
                    if (a.trendsStatus === b.trendsStatus) {
                        return 0; // Same trendsStatus, maintain original order
                    } else {
                        if (classificationA == 0 && classificationB == 0) {
                            return (
                                Math.abs(b.trendsStatus) -
                                Math.abs(a.trendsStatus)
                            );
                        } else {
                            return (
                                Math.abs(a.trendsStatus) -
                                Math.abs(b.trendsStatus)
                            );
                        }
                    }
                } else {
                    // Different classification, sort by classification order
                    return classificationA - classificationB;
                }
            case 3:
                return +a.clicks - +b.clicks;
            case 4:
                return +a.impressions - +b.impressions;
            case 5:
                return +a.ctr - +b.ctr;
            case 6:
                return +a.volume - +b.volume;
            case 7:
                return +a.difficulty - +b.difficulty;
            case 8:
                return +a.cpc - +b.cpc;
            case 9:
                return (
                    Number(firstNonZeroValue(a.position_list)) -
                    Number(lastNonZeroValue(a.position_list)) -
                    (Number(firstNonZeroValue(b.position_list)) -
                        Number(lastNonZeroValue(b.position_list)))
                );
            default:
                return +a.avg_position - +b.avg_position;
        }
        return 1;
    };
    const handleSortByColumns: (index: number) => void = (index) => {
        setSortBy((ps) => {
            if (ps?.id == SortByOptions[index].id) {
                setOrder((ps) => (ps == 0 ? 1 : 0));
                return SortByOptions[index];
            } else {
                setOrder(1);
                return SortByOptions[index];
            }
        });
    };

    let _keywords = [...item.keywords.sort(sortKeywords)];
    return (
        <div className={styles.subCardsWrapper}>
            <div className={styles.keywordsContainer}>
                <div className={styles.tableContainer}>
                    <div className={styles.tableMeta}>
                        <div className={styles.tableTitle}>
                            Showing Top <span>{item.keywords.length}</span>{" "}
                            Keywords
                        </div>
                    </div>
                    <div className={styles.tableHeaders}>
                        <div className={styles.head_keyword}>Keyword</div>
                        <div
                            className={
                                styles.head_avg_rank +
                                " " +
                                (sortBy.id == SortByOptions[0].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(0);
                            }}
                        >
                            {SortByOptions[0].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[0].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.head_rank +
                                " " +
                                (sortBy.id == SortByOptions[1].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(1);
                            }}
                        >
                            {SortByOptions[1].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[1].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.head_rank +
                                " " +
                                (sortBy.id == SortByOptions[8].id
                                    ? styles.enable
                                    : "")
                            }
                            style={{ justifyContent: "center" }}
                            onClick={() => {
                                handleSortByColumns(8);
                            }}
                        >
                            {SortByOptions[8].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[8].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.head_traffic +
                                " " +
                                (sortBy.id == SortByOptions[2].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(2);
                            }}
                        >
                            {SortByOptions[2].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[2].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.head_impression +
                                " " +
                                (sortBy.id == SortByOptions[3].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(3);
                            }}
                        >
                            {SortByOptions[3].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[3].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>

                        <div
                            className={
                                styles.head_ctr +
                                " " +
                                (sortBy.id == SortByOptions[4].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(4);
                            }}
                        >
                            {SortByOptions[4].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[4].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.head_volume +
                                " " +
                                (sortBy.id == SortByOptions[5].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(5);
                            }}
                        >
                            {SortByOptions[5].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[5].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        {/* <div
                            className={
                                styles.head_cpc +
                                " " +
                                (sortBy.id == SortByOptions[7].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(7);
                            }}
                        >
                            {SortByOptions[7].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[7].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div> */}
                        <div
                            className={
                                styles.head_kd +
                                " " +
                                (sortBy.id == SortByOptions[6].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(6);
                            }}
                        >
                            {SortByOptions[6].title}
                            <SVGIcon
                                size={14}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[6].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div className={styles.head_cta}></div>
                    </div>
                    <div className={styles.tableBody}>
                        {_keywords.map((row: any, index) => (
                            <KeyboardItemRow
                                row={row}
                                createOptimizerReport={createOptimizerReport}
                                index={index}
                                parentId={parentId}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyzerSubCards;
