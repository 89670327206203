import React, { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
    useLocation,
    useHistory,
} from "react-router-dom";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Image from "../../atoms/Image/Image";
import ForgotPassword from "../../Pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "../../Pages/Auth/ResetPassword/ResetPassword";
import SignIn from "../../Pages/Auth/SignIn/SignIn";
import SignUp from "../../Pages/Auth/SignUp/SignUp";
import Email from "../../Pages/Auth/AuthHome/Email";
import styles from "./styles.module.css";
import {
    googleLogin,
    updateUserInfo,
    VerificationEmail,
} from "../../../store/actions/auth";
import VericationBanner from "../../Pages/Auth/VerificationBanner/VericationBanner";
import SVGIcon from "../../atoms/SVGIcon";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import { useState } from "react";
import { SvgIcon } from "@material-ui/core";
import { badges } from "../Dashboard/constants";
function scrollToTop(element) {
    console.log("SCROLL HELO BVEA", element);
    if (element) element.scroll(0, -1000);
}
export function valueFromCookie(key) {
    let token = null;
    const temp = decodeURIComponent(document.cookie).split(";");
    for (let i = 0; i < temp.length; i++) {
        if (token) break;
        if (temp[i].split("=")[0]?.trim() == key) {
            token = temp[i];
        }
    }
    return token;
}
const stars = new Array(5).fill("123");

let setTeamMemberFun = () => {};
let queryEntries = {};
const urlParams = new URLSearchParams(location.search);
for (const [key, value] of urlParams.entries()) {
    queryEntries[key] = value;
}

if (!queryEntries.landing_page) {
    try {
        if (!document.referrer) {
            queryEntries.landing_page = "direct";
        } else {
            queryEntries.landing_page = document.referrer;
        }
    } catch (e) {
        console.log(e);
    }
}

const lifetime = urlParams.get("lifetime");
const queryContext = urlParams.get("context") || null;
const queryUtmSource = urlParams.get("utm_source") || null;
const queryUtmMedium = urlParams.get("utm_medium") || null;
const queryUtmCampaign = urlParams.get("utm_campaign") || null;
const referCode = urlParams.get("refer") || null;
const signup_mode = urlParams.get("signup_mode") || null;
const ccPlan = urlParams.get("plan_id") || null;
function Auth() {
    const goTo = useNavigation();
    const ref = useRef();
    const ref1 = useRef();
    const dispatch = useDispatch();
    const verifyEmail = useSelector((state) => state.auth.verifyEmail);
    let error = useSelector((state) => state.auth.error);

    const ScalenutToken = valueFromCookie("ScalenutToken");

    useEffect(() => {
        if (ScalenutToken) {
            try {
                responseGoogle({
                    tokenId: ScalenutToken.split("=")[1],
                    googleOneTap: true,
                });
                document.cookie =
                    ScalenutToken + ";domain=.scalenut.com;path=/;max-age=0";
            } catch (error) {
                console.log(error);
            }
        }

        document.cookie =
            "ScalenutSyncToken='';domain=scalenut.com;path=/;max-age=0";
    }, []);

    const redirectPath = useSelector(
        (state) => state.auth.continueWithEmailRedirectPath,
    );

    const { setState } = useContext(OnBoardingContext);

    const oneTapGoogle = (res) => {
        console.log("Google res", res);
        responseGoogle({ ...res, tokenId: res.credential, googleOneTap: true });
    };

    useEffect(() => {
        if (!ScalenutToken && window?.google) {
            window?.google?.accounts?.id?.initialize({
                client_id:
                    "466601860416-thij5f8b08j5nslathiom9t0fneoe8t4.apps.googleusercontent.com",
                callback: oneTapGoogle,
                cancel_on_tap_outside: false,
            });
            window?.google?.accounts?.id?.prompt((notification) => {
                console.log("Google notification", notification);
            });
        }
    }, []);

    useEffect(() => {
        window.userengage && window.userengage("widget.hide");
        setState((ps) => ({
            ...ps,
            ccPlan,
        }));
    }, []);

    function initSetTeamMemberFun(callback) {
        setTeamMemberFun = callback;
    }

    function responseGoogle(e) {
        console.log(e);
        if (e.error) {
            console.log(e);
            return;
        }
        dispatch(googleLogin(e, signup_mode, referCode, queryEntries)).then(
            (e) => {
                e?.status == 203 && setTeamMemberFun(e);
            },
        );
    }

    const history = useHistory();

    useEffect(() => {
        console.log(redirectPath);
        if (redirectPath) {
            history.push(redirectPath);
            dispatch(updateUserInfo("continueWithEmailRedirectPath", null));
        }
    }, [redirectPath]);

    useEffect(() => {
        if (ref1.current && error) {
            ref1.current.scrollIntoView(true);
        }
    }, [error, ref]);

    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (verifyEmail) {
            dispatch(updateUserInfo("verifyEmail", false));
        }
    }, [location.pathname]);

    return (
        <div ref={ref} className={styles.container}>
            <div className={styles.logoM}>
                <SVGIcon src="/New UI/SVG/Scalenut.svg" size={36} />

                <div>&nbsp;Scalenut</div>
            </div>
            <div className={styles.container2}>
                <div className={styles.socialInfo}>
                    <div className={styles.header}>
                        <div
                            className={styles.logoD}
                            style={{ display: "flex" }}
                        >
                            <SVGIcon src="/New UI/SVG/Scalenut.svg" size={36} />

                            <div>&nbsp;Scalenut</div>
                        </div>
                    </div>
                    <div className={styles.gradeCards}>
                        {badges.map((badge) => (
                            <img
                                style={{ margin: "0 10px 0 0" }}
                                height={80}
                                width={60}
                                src={badge}
                            />
                        ))}
                        <a
                            target="_blank"
                            href="https://www.producthunt.com/products/scalenut#scalenut"
                        >
                            <img src="/New UI/SVG/ProductHunt.svg" />
                        </a>
                    </div>

                    <div className={styles.stars}>
                        {stars.map(() => (
                            <SVGIcon
                                src="/New UI/SVG/star.svg"
                                color="#fc5a5a"
                                size={18}
                            />
                        ))}
                    </div>
                    <p className={styles.commentMain}>
                        "Superb Long-form AI and SEO research assistant"
                    </p>
                    <p className={styles.commentText}>
                        Scalenut has helped me to streamline my content research
                        for the writing of blog articles and producing YouTube
                        videos. I have been able to identify specific topics
                        that my ideal customers are asking through using the SEO
                        assistant. Then I have developed my content briefs by
                        identifying relevant headings, common questions to
                        answer, as well as valuable citations to include in the
                        articles.
                    </p>

                    <div className={styles.userInfo}>
                        <div className={styles.commentUserImage}>
                            <img
                                src="https://storage.googleapis.com/scalenut/webflow assests/62052294ec0081cf3ce237f0_thumb_square_9ee5cc61bca3cb4e9281b639b248fdc1.jpeg"
                                alt=""
                                srcset=""
                            />
                        </div>

                        <div className={styles.commentUserInfo}>
                            <span className={styles.commentUserName}>
                                Rachael W
                            </span>
                            <span className={styles.commentUserPost}>
                                Founder/ CEO/ Owner/ Coach
                            </span>
                        </div>
                    </div>
                </div>

                <div ref={ref1} className={styles.formSection}>
                    <>
                        <Switch>
                            <Route path={"/auth/login"}>
                                <div className={styles.formHeading}>
                                    Log in to Scalenut
                                </div>
                                <span className={styles.link}>
                                    Don't have account?
                                    <span onClick={() => goTo("REGISTER")}>
                                        &nbsp;Sign up
                                    </span>
                                </span>
                            </Route>
                            <Route path={"/auth/register"}>
                                <div className={styles.formHeading}>
                                    Create your account
                                </div>
                                {!lifetime && (
                                    <span className={styles.link}>
                                        Already have account?
                                        <span onClick={() => goTo("LOGIN")}>
                                            &nbsp;Sign In
                                        </span>
                                    </span>
                                )}
                            </Route>

                            <Route path={"/auth/reset"}>
                                <div className={styles.formHeading}>
                                    Reset Password
                                </div>
                                <span className={styles.link}>
                                    Return to
                                    <span onClick={() => goTo("AUTH")}>
                                        &nbsp;Home
                                    </span>
                                </span>
                            </Route>

                            <Route path={"/auth/forgot"}>
                                <div className={styles.formHeading}>
                                    Forgot Password
                                </div>
                                <span className={styles.link}>
                                    Return to
                                    <span onClick={() => goTo("AUTH")}>
                                        &nbsp;Home
                                    </span>
                                </span>
                            </Route>

                            <Route path={"/"}>
                                <div className={styles.formHeading}>
                                    Welcome to Scalenut
                                </div>
                            </Route>
                        </Switch>
                        {verifyEmail ? (
                            <VericationBanner />
                        ) : (
                            <Switch>
                                <Route exact path={"/auth"}>
                                    <Email
                                        responseGoogle={responseGoogle}
                                        queryEntries={queryEntries}
                                    />
                                </Route>
                                <Route path={"/auth/login"}>
                                    <SignIn responseGoogle={responseGoogle} />
                                </Route>
                                <Route path={"/auth/register"}>
                                    <SignUp
                                        setTeamMemberFun={initSetTeamMemberFun}
                                        responseGoogle={responseGoogle}
                                        referCode={referCode}
                                        queryEntries={queryEntries}
                                    />
                                </Route>
                                <Route path={"/auth/forgot"}>
                                    <ForgotPassword />
                                </Route>
                                <Route path={"/auth/reset/:resetPasswordToken"}>
                                    <ResetPassword />
                                </Route>
                                <Route path={"/"}>
                                    <Redirect to={"/auth/login"} />
                                </Route>
                            </Switch>
                        )}
                    </>
                </div>
            </div>
        </div>
    );
}

export default Auth;
