import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";
import useOnClickOutside from "./useOn";

const Color = ({ setDropdown, onClick }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setDropdown(false));
  return (
    <div ref={ref}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClick(e.target.getAttribute("data-value"));
          setDropdown(false);
        }}
        className={styles.colorContainer}
      >
        <span
          data-value="#000000"
          style={{ backgroundColor: "rgb(0, 0, 0)" }}
        ></span>
        <span
          data-value="#e60000"
          style={{ backgroundColor: "rgb(230, 0, 0)" }}
        ></span>
        <span
          data-value="#ff9900"
          style={{ backgroundColor: "rgb(255, 153, 0)" }}
        ></span>
        <span
          data-value="#ffff00"
          style={{ backgroundColor: "rgb(255, 255, 0)" }}
        ></span>
        <span
          data-value="#008a00"
          style={{ backgroundColor: "rgb(0, 138, 0)" }}
        ></span>
        <span
          data-value="#0066cc"
          style={{ backgroundColor: "rgb(0, 102, 204)" }}
        ></span>
        <span
          data-value="#9933ff"
          style={{ backgroundColor: "rgb(153, 51, 255)" }}
        ></span>
        <span
          data-value="#ffffff"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></span>
        <span
          data-value="#facccc"
          style={{ backgroundColor: "rgb(250, 204, 204)" }}
        ></span>
        <span
          data-value="#ffebcc"
          style={{ backgroundColor: "rgb(255, 235, 204)" }}
        ></span>
        <span
          data-value="#ffffcc"
          style={{ backgroundColor: "rgb(255, 255, 204)" }}
        ></span>
        <span
          data-value="#cce8cc"
          style={{ backgroundColor: "rgb(204, 232, 204)" }}
        ></span>
        <span
          data-value="#cce0f5"
          style={{ backgroundColor: "rgb(204, 224, 245)" }}
        ></span>
        <span
          data-value="#ebd6ff"
          style={{ backgroundColor: "rgb(235, 214, 255)" }}
        ></span>
        <span
          data-value="#bbbbbb"
          style={{ backgroundColor: "rgb(187, 187, 187)" }}
        ></span>
        <span
          data-value="#f06666"
          style={{ backgroundColor: "rgb(240, 102, 102)" }}
        ></span>
        <span
          data-value="#ffc266"
          style={{ backgroundColor: "rgb(255, 194, 102)" }}
        ></span>
        <span
          data-value="#ffff66"
          style={{ backgroundColor: "rgb(255, 255, 102)" }}
        ></span>
        <span
          data-value="#66b966"
          style={{ backgroundColor: "rgb(102, 185, 102)" }}
        ></span>
        <span
          data-value="#66a3e0"
          style={{ backgroundColor: "rgb(102, 163, 224)" }}
        ></span>
        <span
          data-value="#c285ff"
          style={{ backgroundColor: "rgb(194, 133, 255)" }}
        ></span>
        <span
          data-value="#888888"
          style={{ backgroundColor: "rgb(136, 136, 136)" }}
        ></span>
        <span
          data-value="#a10000"
          style={{ backgroundColor: "rgb(161, 0, 0)" }}
        ></span>
        <span
          data-value="#b26b00"
          style={{ backgroundColor: "rgb(178, 107, 0)" }}
        ></span>
        <span
          data-value="#b2b200"
          style={{ backgroundColor: "rgb(178, 178, 0)" }}
        ></span>
        <span
          data-value="#006100"
          style={{ backgroundColor: "rgb(0, 97, 0)" }}
        ></span>
        <span
          data-value="#0047b2"
          style={{ backgroundColor: "rgb(0, 71, 178)" }}
        ></span>
        <span
          data-value="#6b24b2"
          style={{ backgroundColor: "rgb(107, 36, 178)" }}
        ></span>
        <span
          data-value="#444444"
          style={{ backgroundColor: "rgb(68, 68, 68)" }}
        ></span>
        <span
          data-value="#5c0000"
          style={{ backgroundColor: "rgb(92, 0, 0)" }}
        ></span>
        <span
          data-value="#663d00"
          style={{ backgroundColor: "rgb(102, 61, 0)" }}
        ></span>
        <span
          data-value="#666600"
          style={{ backgroundColor: "rgb(102, 102, 0)" }}
        ></span>
        <span
          data-value="#003700"
          style={{ backgroundColor: "rgb(0, 55, 0)" }}
        ></span>
        <span
          data-value="#002966"
          style={{ backgroundColor: "rgb(0, 41, 102)" }}
        ></span>
        <span
          data-value="#3d1466"
          style={{ backgroundColor: "rgb(61, 20, 102)" }}
        ></span>
      </div>
    </div>
  );
};

export default Color;
