import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./GscLogout.module.css";
import { useDispatch, useSelector } from "react-redux";
import useHelperFunctions from "../../helperFunctions";
import moment from "moment";
import { AvatarGroup, Skeleton } from "@material-ui/lab";
import {
    resetUserGscInfoMonthlyInsights,
    setUserGscInfo,
} from "../../../store/actions/userGscInfo";
import { FcGoogle } from "react-icons/fc";
import Button from "../../atoms/Button/Button";
import Modal from "../../atoms/Modal/Modal";
import GscLoginFlow from "../GoogleSearchConsole/GscLoginFlow";
import useGscHook, {
    CountryObject,
    SelectedDomain,
} from "../GoogleSearchConsole/useGscHook";
import { displayAlert } from "../../../store/actions/alert";
import { FLUSH_USER_GSC_INFO } from "../../../store/actiontypes";
import { GSC_Countries } from "../GoogleSearchConsole/GscCountries";
import SVGIcon from "../../atoms/SVGIcon";
import { Avatar } from "@material-ui/core";
import AvatarListing from "../../molecules/AvatarListing/AvatarListing";
import { VscTrash } from "react-icons/vsc";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { IoMdClose } from "react-icons/io";
import DomainLimitPopUp from "./DomainLimitPopUp";
import { PLANS_CATEGORIES } from "../../../constants";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import GscReAuth from "../GoogleSearchConsole/GscReAuth";
import { useMixpanelTA } from "../GoogleSearchConsole/useMixpanelTA";

export interface ConnectionObject {
    country: any[];
    created_at: string;
    domain_name: string;
    gsc_id: number;
    page_count: number;
    team_member: any[];
    re_auth?: 0 | 1;
    is_disconnect?: 0 | 1;
}

export interface ReAuthObject {
    reAuth?: boolean;
    domainName?: string;
    countryArr?: any[];
    gscId?: number;
}

const DummyRows = () => {
    const DummyRows = [1, 2, 3];
    return (
        <div className={styles.rowContainer}>
            {DummyRows.map(() => {
                return <Skeleton width={"100%"} height={"76px"} />;
            })}
        </div>
    );
};

const GscLogout = ({
    syncDomainsEdit = () => {},
    reAuthSuccess = null,
    insideTA = false,
    screen = "User Account - Domains",
    addTaDomainRef = { current: null },
    setTaDomainsLength = () => {},
}: {
    screen?: string;
    insideTA?: boolean;
    reAuthSuccess?: null | (() => void);
    syncDomainsEdit?: (obj: {
        isAdded?: boolean;
        isDeleted?: boolean;
        dmObj?: {
            is_selected?: 1 | 0;
            domain_name: string;
        };
    }) => void | (() => {});
    setTaDomainsLength: any;
    addTaDomainRef?: React.MutableRefObject<null | (() => void)>;
}) => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [tempGscId, setTempGscId] = useState(0);
    const [selectedDomain, setSelectedDomain] = useState<SelectedDomain>({
        siteUrl: "",
    });
    const [selectedPagesCount, setSelectedPagesCount] = useState(0);

    const [multiDomain, setMultiDomain] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [gscLoginModal, setGscLoginModal] = useState(false);
    const [isDeletePrompt, setIsDeletePrompt] = useState<{
        show?: boolean;
        domain: ConnectionObject | null;
    } | null>({
        show: false,
        domain: null,
    });

    const [gscConnectedInfo, setGscConnectedInfo] = useState<
        ConnectionObject[] | []
    >([]);
    const [reAuthText, setReAuthText] = useState("");
    const [showConnectionLimit, setShowConnectionLimit] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [disconnectLoading, setDisconnectLoading] = useState(false);
    const [domains, setDomains] = useState<{ siteUrl: string }[] | []>([]);
    const [availableConnections, setAvailableConnections] = useState<number>(0);
    const { postData, getData } = useHelperFunctions();
    const { email, step, monthly_insights_info, showLoginBar } = useSelector(
        (state: any) => state.userGscInfo,
    );
    const max_domain_connection = useSelector(
        (state: any) => state.otherInfo?.subscriptionInfo?.multi_domain,
    );

    const { plan_category, isLTD, isTeamMember } = useUserAccessHook();
    const { getTrimmedDomainURL } = useGscHook();
    const { taTrackGscConnect, taTrackReAuthFlow } = useMixpanelTA();
    useEffect(() => {
        if (max_domain_connection == 0) {
            setAvailableConnections(5);
        } else if (max_domain_connection && gscConnectedInfo.length >= 0) {
            setAvailableConnections(
                max_domain_connection - gscConnectedInfo.length,
            );
        }
        setTaDomainsLength(gscConnectedInfo?.length || 0);

        return () => {
            if (gscConnectedInfo.length > 0) {
                console.log("gscConnectedInfo", gscConnectedInfo);
                setTaDomainsLength(gscConnectedInfo?.length || 0);
            }
        };
    }, [max_domain_connection, gscConnectedInfo]);

    const getDomainList: () => void = async () => {
        return new Promise(async (res, rej) => {
            try {
                setInitialLoading(true);
                const res = await getData({
                    url: `/api/analyse/getDomainList`,
                });
                if (res.status == 200) {
                    const data = res.data.domainList || [];
                    if (data.length == 0) {
                        setIsLoggedIn(false);
                        dispatch(
                            displayAlert({
                                alertMessage:
                                    "No domains detected for this GSC account.",
                                alertType: "error",
                            }),
                        );
                        setIsLoading(false);
                        rej();
                        return;
                    }
                    setTempGscId(res.data?.gsc_id || 0);
                    let tempData = data.map((td: any) => {
                        let idx = gscConnectedInfo?.findIndex(
                            (it) => it?.domain_name == td?.siteUrl,
                        );
                        return {
                            ...td,
                            domain_name: td.siteUrl,
                            used: idx != -1 ? 1 : 0,
                        };
                    });
                    tempData = tempData.sort(
                        (a: any, b: any) => a.used - b.used,
                    );
                    console.log("tempData", tempData);
                    setDomains(tempData);
                    setInitialLoading(false);
                    res();
                } else {
                    setIsLoading(false);
                    setIsLoggedIn(false);
                    setDomains([]);
                }
            } catch (err) {
                setIsLoading(false);
                setIsLoggedIn(false);
                rej();
            } finally {
                setIsLoading(false);
                setInitialLoading(false);
                rej();
            }
        });
    };
    const getDetails = async () => {
        setIsLoading(true);
        const res = await getData({ url: "/api/analyse/getGscDetails" });
        if (res.status == 200) {
            if (res.data?.resp?.gscInfo?.is_connected != 1) {
                // check whether google connected or not
                setIsLoading(false);
                return;
            } else {
                dispatch(
                    setUserGscInfo({
                        email: res.data?.resp?.gscInfo?.email,
                        // domain_name: res.data?.resp?.domainInfo?.domain_name||[],
                    }),
                );
            }

            setActiveStep(res.data?.resp?.gscInfo?.step < 4 ? 2 : 1);
            if (res.data?.resp?.gscInfo?.step == 4) {
                setIsLoading(false);
                return;
            }

            if (res.data.resp?.gscInfo?.step == 2) {
                // await getDomainList();
            }
            if (res.data.resp?.domainInfo?.domain_name) {
            } else {
                // await getDomainList();
            }
            setIsLoading(false);
        } else {
            setActiveStep(1);
            setGscLoginModal(false);
        }

        setInitialLoading(false);
        setIsLoading(false);
    };

    useEffect(() => {
        if (gscConnectedInfo.length == 0) {
            getGscDataForAccount();
        }
    }, []);

    const handleGSCLogout: (
        domain_name: string,
        gsc_id: number,
        isDisconnect?: boolean,
    ) => void = async (domain_name, gsc_id, isDisconnect) => {
        const res = await postData({
            url: "/api/analyse/logoutGsc",
            payload: {
                domain_name,
                gsc_id,
            },
        });
        if (res.status == 200) {
            syncDomainsEdit({ isDeleted: true, dmObj: { domain_name } });
            if (isDisconnect) {
                handleAddDomain();
            }
            dispatch(
                displayAlert({
                    alertMessage: "Domain Successfully Deleted",
                    alertType: "success",
                }),
            );
        } else {
            setIsLoading(false);
        }
    };

    const responseGoogle: (
        response: any,
        _multiDomain: boolean,
    ) => void = async (response, _multiDomain) => {
        setLoading(true);
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    ...(_multiDomain && { multi_domain: true }),
                },
            });
            if (res.status == 200) {
                dispatch(
                    setUserGscInfo({
                        email: res.data?.resp?.userInfo?.email,
                    }),
                );
                setActiveStep(2);
                getDomainList();
                setGscLoginModal(true);
            } else {
                dispatch(
                    displayAlert({
                        alertMessage: `${res.response.data.message}`,
                        alertType: "error",
                        timeout: 5000,
                    }),
                );
            }
        }
        setLoading(false);
    };

    const getGscDataForAccount: (callInsight?: boolean) => void = async (
        callInsight = false,
    ) => {
        setIsLoading(true);
        setGscLoginModal(false);
        const res = await getData({
            url: "/api/analyse/gsc-account-info",
        });
        if (res.status == 200) {
            if (callInsight && reAuthSuccess) {
                reAuthSuccess();
            }
            if (callInsight && !insideTA) {
                getInsight();
            }
            if (res.data?.length > 0) {
                setIsLoggedIn(true);
                let temp: ConnectionObject[] = [];
                res.data.map((obj: ConnectionObject) => {
                    if (obj?.re_auth == 1) {
                        setReAuthText("");
                    }
                    let arrCountry = obj.country.map((cn) => {
                        let idx = GSC_Countries.findIndex(
                            (item) => cn == item.code,
                        );
                        return { ...GSC_Countries[idx] };
                    });
                    temp.push({
                        ...obj,
                        country: [...arrCountry],
                        page_count:
                            tempGscId > 0 && obj?.gsc_id == tempGscId
                                ? -1
                                : obj.page_count,
                    });
                });
                setGscConnectedInfo([...temp]);
                getDetails();
            } else {
                getDetails();
                setMultiDomain(false);
                setGscConnectedInfo([]);
                setIsLoggedIn(false);
            }

            setGscLoginModal(false);
            setIsLoading(false);
        } else {
            setIsLoggedIn(false);
            dispatch(
                setUserGscInfo({
                    showLoginBar: 1,
                }),
            );
            setGscLoginModal(false);
            setIsLoading(false);
        }
    };
    const handleLogin: (_multiDomain?: boolean) => void = (
        _multiDomain = false,
    ) => {
        setLoading(true);
        // @ts-ignore
        let gsc = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: (rs: any) => {
                responseGoogle(rs, _multiDomain);
            },
            error_callback: () => {
                setLoading(false);
            },
        });
        gsc.requestCode();
    };

    const handleDeleteDomain: (
        obj: ConnectionObject,
        isDisconnect?: boolean,
    ) => void = async (obj, isDisconnect = false) => {
        if (obj.hasOwnProperty("domain_name")) {
            let temp = gscConnectedInfo.filter((_obj) =>
                obj?.gsc_id != _obj.gsc_id ? true : false,
            );
            if (temp.length == 0) {
                dispatch(resetUserGscInfoMonthlyInsights({}));
                setIsLoggedIn(false);
            }
            setGscConnectedInfo([...temp]);
            setAvailableConnections(max_domain_connection - temp.length);
            // @ts-ignore
            handleGSCLogout(obj?.domain_name, obj?.gsc_id, isDisconnect);
        } else {
            dispatch(
                displayAlert({
                    alertMessage: "Invalid Domain",
                    alertType: "error",
                }),
            );
            return;
        }
        dispatch(setUserGscInfo({ connectedDomains: [] }));
    };

    const getInsight = async (domainName = "") => {
        const res = await postData({
            url: `/api/analyse/post-insights-v2`,
            payload: {
                domain_name: domainName,
            },
        });
    };

    const responseGoogleReAuth: (
        response: any,
        connectionInfo: ReAuthObject,
    ) => void = async (response, connectionInfo) => {
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/re-authenticate",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    gsc_id: connectionInfo?.gscId,
                    domain_name: connectionInfo?.domainName,
                },
            });
            if (res.status == 200) {
                getGscDataForAccount(true);
            } else {
                dispatch(
                    displayAlert({
                        alertMessage: `${res.response.data.message}`,
                        alertType: "error",
                        timeout: 5000,
                    }),
                );
            }
        }
    };

    const handleReAuth: (info: ReAuthObject) => void = (info = {}) => {
        // @ts-ignore
        let gsc = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: (rs: any) => {
                responseGoogleReAuth(rs, info);
            },
            error_callback: () => {},
        });
        gsc.requestCode();
    };
    const handleAddDomain = () => {
        if (availableConnections <= 0) {
            setShowConnectionLimit(true);
            return;
        }

        setMultiDomain(true);
        if (activeStep > 1) {
            setGscLoginModal(true);
        } else {
            handleLogin(true);
        }
    };

    const handleDisconnect: (obj: ConnectionObject) => void = async (rw) => {
        setDisconnectLoading(true);
        await handleDeleteDomain(rw, true);
        setDisconnectLoading(false);
    };

    useEffect(() => {
        addTaDomainRef.current = handleAddDomain;
    }, [availableConnections, activeStep]);

    return (
        <div
            className={styles.container}
            style={{
                ...(!insideTA && {
                    padding: 0,
                    paddingTop: 20,
                }),
            }}
        >
            {insideTA && (
                <div
                    className={styles.containerTop}
                    style={{ ...(insideTA && { paddingTop: "30px" }) }}
                >
                    <div className={styles.sectionTitle}>Manage Domains</div>
                    {isLoggedIn && !isLoading && !isTeamMember && (
                        <div className={styles.connectionInfoWrapper}>
                            {/* <div className={styles.availableConnection}>
                            (Available addtional domains :{" "}
                            {availableConnections >= 0
                                ? availableConnections
                                : 0}
                            )
                        </div> */}
                            <Button
                                height={30}
                                width={"100%"}
                                handler={() => {
                                    if (availableConnections <= 0) {
                                        setShowConnectionLimit(true);
                                        return;
                                    }

                                    setMultiDomain(true);
                                    if (activeStep > 1) {
                                        setGscLoginModal(true);
                                    } else {
                                        handleLogin(true);
                                    }
                                }}
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    padding: "4px 16px",
                                    width: "max-content",
                                    borderRadius: "6px",
                                    marginRight: insideTA ? "30px" : "2px",
                                }}
                                text={`Add New Domain (${
                                    gscConnectedInfo?.length || 0
                                }/${max_domain_connection})`}
                                Icon={() => (
                                    <SVGIcon
                                        src={"/New UI/SVG/globePlus.svg"}
                                        style={{ color: "inherit" }}
                                        size={18}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.mainContainer}>
                {isLoggedIn || isLoading ? (
                    <div className={styles.tableWrapper}>
                        <div className={styles.headWrapper}>
                            <div className={styles.head_domainName}>
                                Domain Name
                            </div>
                            <div className={styles.head_countries}>
                                Countries
                            </div>
                            <div className={styles.head_doc}>
                                Date of Connect
                            </div>
                            <div className={styles.head_teamMember}>
                                Team Member
                            </div>
                            <div className={styles.head_pagesCount}>
                                No. of Pages
                            </div>
                            {!isTeamMember && (
                                <div className={styles.head_cta}></div>
                            )}
                        </div>
                        {!isLoading ? (
                            <div className={styles.rowContainer}>
                                {gscConnectedInfo?.length > 0 &&
                                    gscConnectedInfo.map((rw, idx) => {
                                        return (
                                            <div
                                                className={`${
                                                    styles.rowWrapper
                                                } ${
                                                    rw?.re_auth == 1 &&
                                                    styles.redRow
                                                }`}
                                            >
                                                <div
                                                    className={
                                                        styles.row_domainName
                                                    }
                                                >
                                                    <div
                                                        className={styles.icon}
                                                    >
                                                        {" "}
                                                        <img
                                                            src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                                rw?.domain_name?.split(
                                                                    ":",
                                                                )[1]
                                                            }&sz=32`}
                                                            width={"24px"}
                                                        />
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        {getTrimmedDomainURL(
                                                            rw?.domain_name,
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.row_countries
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            styles.flagIcon
                                                        }
                                                    >
                                                        <img
                                                            src={`https://flagcdn.com/w20/${rw.country[0]?.flagCode?.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${rw.country[0]?.flagCode?.toLowerCase()}.png 2x`}
                                                        />
                                                    </span>
                                                    {rw.country[1]?.code
                                                        ?.length && (
                                                        <span
                                                            className={
                                                                styles.flagIcon
                                                            }
                                                        >
                                                            <img
                                                                src={`https://flagcdn.com/w20/${rw.country[1]?.flagCode?.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${rw.country[1]?.flagCode?.toLowerCase()}.png 2x`}
                                                            />
                                                        </span>
                                                    )}
                                                    <span
                                                        className={
                                                            styles.moreText
                                                        }
                                                    >
                                                        {rw.country.length > 2
                                                            ? `+${
                                                                  rw.country
                                                                      .length -
                                                                  2
                                                              } more`
                                                            : ""}
                                                    </span>
                                                </div>
                                                <div className={styles.row_doc}>
                                                    {moment(
                                                        rw.created_at,
                                                    ).format("DD/MM/YYYY")}
                                                </div>
                                                <div
                                                    className={
                                                        styles.row_teamMember
                                                    }
                                                >
                                                    {rw?.team_member?.length >
                                                    0 ? (
                                                        <AvatarListing
                                                            max={4}
                                                            list={
                                                                rw.team_member
                                                            }
                                                        />
                                                    ) : (
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                paddingLeft:
                                                                    "17px",
                                                            }}
                                                        >
                                                            -
                                                        </span>
                                                    )}
                                                </div>
                                                {rw?.page_count == -1 ? (
                                                    <div
                                                        className={
                                                            styles.row_pagesCount
                                                        }
                                                    >
                                                        <Skeleton
                                                            width={"50%"}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.row_pagesCount
                                                        }
                                                    >
                                                        {rw?.page_count != null
                                                            ? rw?.page_count
                                                            : "-"}
                                                    </div>
                                                )}
                                                {!isTeamMember && (
                                                    <div
                                                        className={
                                                            styles.row_cta
                                                        }
                                                    >
                                                        {rw?.re_auth == 1 && (
                                                            <LightTooltip
                                                                title={
                                                                    "Reconnect"
                                                                }
                                                                placement={
                                                                    "top"
                                                                }
                                                                arrow
                                                            >
                                                                <Button
                                                                    handler={() => {
                                                                        taTrackReAuthFlow(
                                                                            {
                                                                                screen: screen,
                                                                                cta: "Reconnect",
                                                                            },
                                                                        );
                                                                        handleReAuth(
                                                                            {
                                                                                reAuth: true,
                                                                                domainName:
                                                                                    rw.domain_name,
                                                                                countryArr:
                                                                                    rw.country,
                                                                                gscId: rw.gsc_id,
                                                                            },
                                                                        );
                                                                    }}
                                                                    height={30}
                                                                    style={{
                                                                        width: "max-content",
                                                                        padding:
                                                                            "4px 16px",
                                                                        fontSize:
                                                                            "14px",
                                                                        borderRadius:
                                                                            "6px",
                                                                        fontWeight: 400,
                                                                    }}
                                                                    text="Reconnect"
                                                                />
                                                            </LightTooltip>
                                                        )}
                                                        {rw?.is_disconnect ==
                                                            1 && (
                                                            <LightTooltip
                                                                title={
                                                                    "Re-Authorize"
                                                                }
                                                                placement={
                                                                    "top"
                                                                }
                                                                arrow
                                                            >
                                                                <Button
                                                                    handler={() => {
                                                                        taTrackReAuthFlow(
                                                                            {
                                                                                screen: screen,
                                                                                cta: "Re-Authorize",
                                                                            },
                                                                        );
                                                                        handleDisconnect(
                                                                            rw,
                                                                        );
                                                                    }}
                                                                    loading={
                                                                        disconnectLoading
                                                                    }
                                                                    height={30}
                                                                    width={120}
                                                                    style={{
                                                                        fontSize:
                                                                            "14px",
                                                                        borderRadius:
                                                                            "6px",
                                                                        fontWeight: 400,
                                                                    }}
                                                                    text="Re-Authorize"
                                                                />
                                                            </LightTooltip>
                                                        )}
                                                        <LightTooltip
                                                            title={"Delete"}
                                                            placement={"top"}
                                                            arrow
                                                        >
                                                            <div
                                                                className={
                                                                    styles.deleteCta
                                                                }
                                                                onClick={() => {
                                                                    setIsDeletePrompt(
                                                                        {
                                                                            show: true,
                                                                            domain: rw,
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                <VscTrash
                                                                    size={18}
                                                                    color="red"
                                                                />
                                                            </div>
                                                        </LightTooltip>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <DummyRows />
                        )}
                    </div>
                ) : (
                    <div className={styles.loginContainer}>
                        <div className={styles.loginContainer_left}>
                            No Domains Found
                        </div>
                        <div className={styles.horizontalSpacer}></div>

                        <div className={styles.loginContainer_right}>
                            <Button
                                loading={loading || gscLoginModal}
                                disabled={isLoading}
                                handler={() => {
                                    setMultiDomain(false);
                                    taTrackGscConnect({
                                        screen: screen,
                                        isReAuth: "No",
                                    });
                                    if (activeStep == 1) {
                                        handleLogin();
                                    } else {
                                        setGscLoginModal(true);
                                    }
                                }}
                                height={"44"}
                                Icon={() => (
                                    <div className={styles.iconWrapper}>
                                        <FcGoogle size={18} />
                                    </div>
                                )}
                                secondary={true}
                                text={"Continue with Google"}
                                iconReverse={true}
                                width={"100%"}
                                customClass={styles.customGoogleLogin}
                            />
                        </div>
                    </div>
                )}
            </div>

            {gscLoginModal && (
                <Modal
                    setModal={() => {
                        setGscLoginModal(!gscLoginModal);
                    }}
                >
                    <div className={styles.gscLoginModal}>
                        <GscLoginFlow
                            userInfo={{ email }}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            setSelectedDomain={setSelectedDomain}
                            selectedDomain={selectedDomain}
                            domains={domains}
                            getDomainList={getDomainList}
                            source={"dashboard"}
                            getGscDataForDashboard={getGscDataForAccount}
                            setGscLoginModal={setGscLoginModal}
                            multiDomain={multiDomain}
                            setTempGscId={setTempGscId}
                            tempGscId={tempGscId}
                            setSelectedPagesCount={setSelectedPagesCount}
                            selectedPagesCount={selectedPagesCount}
                            syncDomainsEdit={syncDomainsEdit}
                        />
                    </div>
                </Modal>
            )}
            {isDeletePrompt?.show && (
                <Modal
                    setModal={() => {
                        setIsDeletePrompt({ show: false, domain: null });
                    }}
                >
                    <div className={styles.deletePromptWrapper}>
                        <div className={styles.promptTitle}>Remove Domain</div>
                        <div
                            className={styles.cross}
                            onClick={(e) => {
                                e.preventDefault();
                                setIsDeletePrompt({
                                    show: false,
                                    domain: null,
                                });
                            }}
                        >
                            <IoMdClose size={14} />
                        </div>
                        <div className={styles.deletePromptWrapper_text}>
                            Are you sure you want to disconnect{" "}
                            <span style={{ color: "#014dc5" }}>
                                {getTrimmedDomainURL(
                                    isDeletePrompt?.domain?.domain_name,
                                )}
                            </span>
                            ?
                        </div>
                        <div className={styles.deleteLabel}>
                            Please enter the text DELETE in the text box below
                            to confirm.
                        </div>

                        <input
                            onChange={(e) => {
                                setText(e?.target?.value?.trim());
                            }}
                            className={styles.editableDiv}
                        />
                        <div className={styles.ctaRow}>
                            <Button
                                customClass={styles.cancelCta}
                                width={"100px"}
                                text="Cancel"
                                handler={() => {
                                    setIsDeletePrompt({
                                        show: false,
                                        domain: null,
                                    });
                                }}
                            />
                            <Button
                                customClass={styles.confirmCta}
                                width={"100px"}
                                disabled={text?.toLowerCase() != "delete"}
                                style={{
                                    background: "rgb(249, 79, 44)",
                                }}
                                text="Remove"
                                handler={() => {
                                    setIsDeletePrompt({
                                        show: false,
                                        domain: null,
                                    });
                                    // @ts-ignore
                                    handleDeleteDomain(isDeletePrompt?.domain);
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {showConnectionLimit && (
                <Modal
                    setModal={() => {
                        setShowConnectionLimit(!showConnectionLimit);
                    }}
                >
                    <DomainLimitPopUp
                        isPro={
                            PLANS_CATEGORIES.PRO == plan_category ||
                            PLANS_CATEGORIES.PRO_MAX == plan_category ||
                            isLTD
                        }
                        setShow={setShowConnectionLimit}
                        show={showConnectionLimit}
                    />
                </Modal>
            )}
        </div>
    );
};

export default GscLogout;
