import React, { useCallback, useContext, useRef } from "react";
import styles from "./KeyTerms.module.css";
import ProgressBar from "../../../atoms/ProgressBar";
import { useState } from "react";
import { useEffect } from "react";
import ModalComponent from "../../../molecules/Creator/Overview/ModalComponent";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import useHelperFunctions from "../../../helperFunctions";
import { Checkbox, CircularProgress } from "@material-ui/core";
import WriteToEditor from "../../../organisms/TextEditor/Toolbar/WriteToEditor";
import HeadingItem from "./HeadingItem";
import SVGIcon from "../../../atoms/SVGIcon";
import ReportContext from "../ReportContext";
import EditorContext from "../../TextEditor/EditorContext";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import { Skeleton } from "@material-ui/lab";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

function KeyTermsRow({
    item,
    list,
    reportInfo,
    writingState,
    setWritingState,
    writeWithAnimation,
    index,
    _setUsed,
    isDeleting,
    setIsDeleting,
    keytermLoading,
}) {
    const [showModal, setShowModal] = useState(false);

    const [showHeadings, setShowHeadings] = useState(false);
    const [loading, setLoading] = useState(false);
    const [headingList, setHeadingList] = useState([]);
    const [loadingMoreAlHeadings, setLoadingMoreAIHeadings] = useState(false);
    const { postData, getData } = useHelperFunctions();
    const { setReportData, competetion } = useContext(ReportContext);

    const [expanding, setExpanding] = useState(false);
    const [clickToAdd, setClickToAdd] = useState(false);
    const { isShared } = useContext(ReportContext);
    const { versionData } = useContext(EditorContext);
    const [loadingKeyterm, setLoadingKeyterm] = useState(false);

    const { mixpanelTrack } = useMixpanelHook();

    const dispatch = useDispatch();

    const ref = useRef();

    useEffect(() => {
        if (showHeadings) ref.current.scrollIntoView(true);
        if (showModal && showHeadings) {
            setShowModal(false);
        }
    }, [showHeadings]);

    useEffect(() => {
        if (showHeadings && showModal) {
            setShowHeadings(false);
        }
    }, [showModal]);

    useEffect(() => {
        let timer = null;
        if (clickToAdd) {
            timer = setTimeout(() => {
                setClickToAdd(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [clickToAdd]);

    ///////
    const expandHeading = async (mainTerm, relatedTerm) => {
        document?.quill?.editor?.enable(false);
        setExpanding(true);
        setWritingState({ state: true, text: "", useCase: -1 });
        try {
            const resHeadings = await postData({
                url: "/api/scrape/getKeywordUsage",
                payload: {
                    report_id: reportInfo.report_id,
                    keyword: item.keyword,
                },
            });
            const headingArr = resHeadings.data.data.keywordTextUsage;
            let content = [];
            for (let i = 0; i < headingArr.length && i <= 2; i++) {
                // console.log("This is length of context ", content.length + headingArr[i].textUsage.length);
                // if (content.length + headingArr[i].textUsage.length < 3000)
                // content += i + 1 + "." + headingArr[i].textUsage + "\n";
                content.push(headingArr[i].textUsage);
            }

            const res = await postData({
                url: "/api/generate",
                payload: {
                    Term: relatedTerm,
                    Keyword: `${mainTerm}, ${relatedTerm}`,
                    Context: content,
                    useCase: 56,
                    report_id: reportInfo.report_id,
                },
            });
            let answer = `\n${res?.data?.output[0]?.content?.trim()}`;
            if (res.status == 200) {
                await writeWithAnimation(answer);
            }
        } catch (error) {
            console.log(error);
        }
        setExpanding(false);
        document?.quill?.editor?.enable(true);
        setWritingState({ state: false, text: "", useCase: -1 });
    };

    /////////
    const getDataAcctoKeyWord = useCallback(async () => {
        if (headingList.length > 0) return;
        setLoading(true);
        const res = await postData({
            url: "/api/scrape/getKeywordUsage",
            payload: {
                report_id: reportInfo.report_id,
                keyword: item.keyword,
            },
        });
        // console.log(res.data.data.keywordHeadingUsage);
        setHeadingList(res.data?.data?.keywordHeadingUsage || []);
        setLoading(false);
    }, [headingList]);
    //////////

    /////////////////
    const getMoreAiHeadings = useCallback(
        async (mainTerm, relatedTerm) => {
            setLoadingMoreAIHeadings(true);
            const serpHeadingList = headingList
                .filter((item) => item.type == 0)
                .map((item) => item.headingUsage);
            const res = await postData({
                url: "/api/creator/generate-heading",
                payload: {
                    keyTerm: mainTerm,
                    related: relatedTerm,
                    useCase: 55,
                    reportId: reportInfo.report_id,
                    headingData:
                        serpHeadingList.length <= 3
                            ? serpHeadingList
                            : serpHeadingList.slice(0, 3),
                },
            });

            if (res.status == 200) {
                setHeadingList((prev) => [...prev, ...res.data]);
            }

            setLoadingMoreAIHeadings(false);
        },
        [headingList],
    );
    //////////////////

    //////////
    const addToEditor = (str) => {
        if (writingState.state) return;
        WriteToEditor({
            data: !document.quill.editor.getText(
                document.quill.editor.cursor - 1,
                1,
            )
                ? str
                : document.quill.editor.getText(
                      document.quill.editor.cursor - 1,
                      1,
                  ) != "\n"
                ? "\n" + str
                : str,
            type: "text",
            length: str.length + 1,
            source: "user",
        });
        setClickToAdd(true);
    };
    /////////////

    const deleteKeyterm = async ({ id, term, status }) => {
        if (!status && list.filter((ele) => ele.status).length <= 5) {
            dispatch(
                displayAlert({
                    alertMessage: "minimum 5 key terms are required",
                    alertType: "error",
                }),
            );
            return;
        }
        setLoadingKeyterm(true);
        setIsDeleting(true);
        const res = await postData({
            url: "/api/scrape/unwantedNLP",
            payload: {
                report_id: reportInfo.report_id,
                terms: [{ id: id, term: term, isDeleted: status }],
            },
        });
        setLoadingKeyterm(false);
        setIsDeleting(false);
        if (res.status == 200) {
            // const arr = competetion?.map((item) => ({
            //     ...item,
            //     optimiser_score: res.data?.responseData[item?.url_id],
            //     grade: res.data?.responseData[item?.url_id],
            // }));
            setReportData((ps) => ({
                ...ps,
                relevantTerms: res.data?.relevantTerms,
            }));
        }
    };

    return (
        <>
            <div className={styles.containerDiv}>
                <span
                    style={{ position: "absolute", top: -65 }}
                    ref={ref}
                ></span>
                <div
                    style={{
                        opacity: item?.status == 0 || loadingKeyterm ? 0.3 : 1,
                    }}
                    className={styles.mainDiv}
                >
                    {loadingKeyterm ? (
                        <Skeleton
                            style={{
                                background: "#3f5575",
                                marginLeft: "10px",
                            }}
                            width={20}
                            height={14}
                        />
                    ) : (
                        <LightTooltip
                            arrow
                            title={isDeleting ? "please wait" : ""}
                        >
                            <Checkbox
                                checked={item.status}
                                onChange={(e) => {
                                    if (
                                        isDeleting ||
                                        reportInfo.is_sample ||
                                        keytermLoading
                                    ) {
                                        return;
                                    }
                                    if (e.target.checked) {
                                        deleteKeyterm({
                                            id: item?.id,
                                            term: item?.keyword,
                                            status: 1,
                                        });
                                        mixpanelTrack(
                                            "Key Terms List(Right Pane)",
                                            {
                                                action: "Checked",
                                            },
                                        );
                                    } else {
                                        deleteKeyterm({
                                            id: item?.id,
                                            term: item?.keyword,
                                            status: 0,
                                        });
                                        mixpanelTrack(
                                            "Key Terms List(Right Pane)",
                                            {
                                                action: "Uncheck",
                                            },
                                        );
                                    }
                                }}
                                size="small"
                            />
                        </LightTooltip>
                    )}

                    <div className={styles.leftContainer}>
                        {loadingKeyterm ? (
                            <Skeleton
                                style={{
                                    background: "#3f5575",
                                    marginLeft: "10px",
                                }}
                                width={40}
                                height={50}
                            />
                        ) : (
                            <LightTooltip
                                title={"View NLP Term frequency/range in SERP"}
                                placement="top"
                                arrow
                            >
                                <UsageBox
                                    key={item.id}
                                    item={item}
                                    keywordUsage={item.keywordUsage}
                                />
                            </LightTooltip>
                        )}
                    </div>

                    <div
                        className={styles.leftInfoContainer}
                        onClick={() => {
                            if (item.status) {
                                addToEditor(item.keyword);
                            }
                        }}
                    >
                        {loadingKeyterm ? (
                            <Skeleton
                                style={{
                                    background: "#3f5575",
                                    marginLeft: "10px",
                                }}
                                width={45}
                                height={14}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <LightTooltip
                                    title={
                                        (isShared && isShared.access) ||
                                        !isShared
                                            ? item?.status == 0
                                                ? ""
                                                : clickToAdd
                                                ? "Added!"
                                                : "Click to Add"
                                            : ""
                                    }
                                    placement="top"
                                >
                                    <span
                                        style={{
                                            textDecoration:
                                                item?.status == 0
                                                    ? "line-through"
                                                    : "",
                                        }}
                                        className={styles.leftHeading}
                                    >
                                        {item.keyword}
                                    </span>
                                </LightTooltip>
                                {!!item.is_user_added && (
                                    <LightTooltip
                                        arrow
                                        title={"User added Key Term"}
                                    >
                                        <div className={styles.userKeyterm}>
                                            U
                                        </div>
                                    </LightTooltip>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.leftCommonInfo}>
                        {loadingKeyterm ? (
                            <Skeleton
                                style={{
                                    background: "#3f5575",
                                    marginLeft: "10px",
                                }}
                                width={40}
                                height={14}
                            />
                        ) : (
                            <>
                                <span
                                    className={
                                        styles.commonInfoNumber +
                                        " " +
                                        styles.spaceLeft
                                    }
                                >
                                    {item.importance}/10
                                </span>
                                <ProgressBar
                                    className={styles.importanceAfterNew}
                                    value={item.importance}
                                    total={10}
                                />
                            </>
                        )}
                    </div>
                    <div className={styles.rightContainer}>
                        {loadingKeyterm ? (
                            <Skeleton
                                style={{
                                    background: "#3f5575",
                                    marginLeft: "10px",
                                }}
                                width={40}
                                height={14}
                            />
                        ) : (
                            !isShared && (
                                <div className={styles.rightBtns}>
                                    <div
                                        style={{
                                            width: "100%",
                                            minWidth: "80px",
                                        }}
                                    >
                                        <LightTooltip
                                            title={
                                                item.status == 0
                                                    ? ""
                                                    : "View NLP Term usage in SERP"
                                            }
                                            placement="top"
                                            arrow
                                        >
                                            <span
                                                style={{
                                                    marginRight: 5,
                                                    flexGrow: 1,
                                                }}
                                                className={`${
                                                    styles.leftBtnsBG
                                                } ${
                                                    showModal
                                                        ? styles.headingsActive
                                                        : ""
                                                } `}
                                                onClick={() => {
                                                    if (item.status) {
                                                        setShowModal(
                                                            !showModal,
                                                        );
                                                    }
                                                }}
                                            >
                                                <SVGIcon
                                                    size={12}
                                                    src="/New UI/SVG/Quote-icon.svg"
                                                />
                                                {!showModal ? (
                                                    <BsChevronDown size={14} />
                                                ) : (
                                                    <BsChevronUp size={14} />
                                                )}
                                            </span>
                                        </LightTooltip>
                                        {!isShared && (
                                            <LightTooltip
                                                title={
                                                    item.status == 0
                                                        ? ""
                                                        : "Generate text for this term"
                                                }
                                                placement="top"
                                                arrow
                                            >
                                                <span
                                                    className={
                                                        styles.leftBtnsBG
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#E8EDF8",
                                                    }}
                                                >
                                                    {!expanding ? (
                                                        <SVGIcon
                                                            src="/New UI/SVG/Ai-icon.svg"
                                                            size={24}
                                                            onClick={() => {
                                                                if (
                                                                    item.status
                                                                ) {
                                                                    writingState?.state ||
                                                                    isShared ||
                                                                    !versionData.current
                                                                        ? () => {}
                                                                        : expandHeading(
                                                                              reportInfo.title,
                                                                              item.keyword,
                                                                          );
                                                                }
                                                            }}
                                                            className={
                                                                styles.copyIcon
                                                            }
                                                            style={
                                                                writingState?.state ||
                                                                isShared ||
                                                                !versionData.current
                                                                    ? {
                                                                          cursor: "not-allowed",
                                                                      }
                                                                    : {}
                                                            }
                                                        />
                                                    ) : (
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                    )}
                                                </span>
                                            </LightTooltip>
                                        )}
                                    </div>
                                    {item.heading_presence > 0 && (
                                        <LightTooltip
                                            title={
                                                item.status
                                                    ? "View NLP Tags in SERP"
                                                    : ""
                                            }
                                            placement="top"
                                            arrow
                                        >
                                            <span
                                                onClick={() => {
                                                    if (item.status) {
                                                        setShowHeadings(
                                                            (prev) => !prev,
                                                        );
                                                        getDataAcctoKeyWord();
                                                    }
                                                }}
                                                className={`${
                                                    styles.leftBtnsBG
                                                } ${styles.headings} ${
                                                    showHeadings
                                                        ? styles.headingsActive
                                                        : ""
                                                } `}
                                            >
                                                <span>Headings</span>
                                                {!showHeadings ? (
                                                    <BsChevronDown size={12} />
                                                ) : (
                                                    <BsChevronUp size={12} />
                                                )}
                                            </span>
                                        </LightTooltip>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>

            {showModal && (
                <ModalComponent
                    keyWord={item.keyword}
                    keyWordVariation={item.keyword_variations}
                    report_info={reportInfo}
                    onEditorAssistant={true}
                />
            )}

            {showHeadings && (
                <div className={styles.bottomDiv}>
                    {loading ? (
                        <CircularProgress
                            size={20}
                            className={styles.centered}
                        />
                    ) : (
                        <>
                            {headingList.map((item, i) => (
                                <HeadingItem
                                    item={item}
                                    writingState={writingState}
                                    setWritingState={setWritingState}
                                    heading={item.keyword}
                                    key={item.headingUsage + i}
                                    list={headingList}
                                    reportInfo={reportInfo}
                                    writeWithAnimation={writeWithAnimation}
                                />
                            ))}
                            {!isShared &&
                                (loadingMoreAlHeadings ? (
                                    <div className={styles.headingItemAiBtn}>
                                        <CircularProgress
                                            color="white"
                                            size={16}
                                        />
                                    </div>
                                ) : (
                                    <LightTooltip
                                        title={"Generate More AI Headings"}
                                        placement="top"
                                        arrow
                                    >
                                        <div
                                            className={styles.headingItemAiBtn}
                                            onClick={() =>
                                                getMoreAiHeadings(
                                                    reportInfo.title,
                                                    item.keyword,
                                                )
                                            }
                                        >
                                            Generate More
                                        </div>
                                    </LightTooltip>
                                ))}
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default KeyTermsRow;

function UsageBox({ keywordUsage, item }) {
    const style = !keywordUsage
        ? ""
        : keywordUsage < item.min_times
        ? styles.typeLow
        : keywordUsage > item.max_times
        ? styles.typeHigh
        : styles.typeMid;

    return (
        <div className={` ${style} ${styles.usageBox}`}>
            <span style={{ fontWeight: 600 }}>{keywordUsage}</span>
            <span style={{ fontWeight: 400, fontSize: 10 }}>
                {item.min_times}-{item.max_times}
            </span>
        </div>
    );
}
