import React, { useEffect, useState } from "react";
import styles from "./InsightsTopGains.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { FilterGraphData, NoGraphData } from "./GeneralInsights";
import useGscHook, {
    GscInsightsDataObject,
    TopPagesKeywordsObject,
} from "../useGscHook";
import { Skeleton } from "@material-ui/lab";
import Button from "../../../atoms/Button/Button";
import { useMixpanelTA } from "../useMixpanelTA";
const defaultRow = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const ColumnHeadings = [
    { key: "clicks", label: "Traffic" },
    { key: "ctr", label: "CTR" },
    { key: "impressions", label: "Impressions" },
    { key: "rank", label: "Rank" },
];

const TopPagesKeywordsRowShimmer = () => {
    return (
        <div>
            {defaultRow.map((i) => (
                <div className={styles.rowItem_shimmer}>
                    <Skeleton width={"60%"} height={"34px"} />
                    <Skeleton width={"13%"} height={"34px"} />
                    <Skeleton width={"13%"} height={"34px"} />
                    <Skeleton width={"11%"} height={"34px"} />
                </div>
            ))}
        </div>
    );
};

export const TopPagesKeywordsTable = ({
    tableTitle,
    data = [],
    tooltip = { isShow: false, text: "" },
    isToneDown = false,
    isShimmer = true,
}: {
    tableTitle: string;
    data?: TopPagesKeywordsObject[];
    tooltip: { isShow: boolean; text: string };
    isToneDown?: boolean;
    isShimmer?: boolean;
}) => {
    const _rows = [...data];
    useEffect(() => {
        console.log("_rows", _rows);
    }, [_rows]);
    const [isCopied, setIsCopied] = useState(false);
    const [eventTriggered, setEventTriggered] = useState(false);

    const { removeDomainFromURL, getValueWithUnit } = useGscHook();
    const { taTrackInsightSection } = useMixpanelTA();
    useEffect(() => {
        let timer: any = null;
        if (isCopied) {
            timer = setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    };

    return (
        <div
            className={styles.TopPagesKeywordsTableContainer}
            onMouseEnter={() => {
                if (!eventTriggered) {
                    taTrackInsightSection({ sectionName: tableTitle });
                    setEventTriggered(true);
                }
            }}
        >
            <div className={styles.tableWrapper}>
                <div className={styles.tableMeta}>
                    <div className={styles.tableTitle}>
                        {tableTitle}{" "}
                        <SVGIcon
                            tooltip={tooltip?.text}
                            src={"/New UI/SVG/info.svg"}
                            size={17}
                        />
                    </div>
                </div>

                <div className={styles.table}>
                    <div className={styles.tableHead}>
                        <div className={styles.column_data}>
                            {tableTitle?.toLowerCase().includes("keywords")
                                ? "Keywords"
                                : "Pages"}
                        </div>

                        <div className={styles.column_rank}>Rank</div>
                        <div className={styles.column_volume}>Impressions</div>
                        <div className={styles.column_traffic}>Traffic</div>
                    </div>
                    {isShimmer ? (
                        <TopPagesKeywordsRowShimmer />
                    ) : _rows.length > 0 ? (
                        <div className={styles.tableBody}>
                            {_rows.map((row, index) => {
                                return (
                                    <div className={styles.rowItem}>
                                        <div className={styles.rowItem_data}>
                                            <LightTooltip
                                                title={
                                                    row?.query
                                                        ? isCopied
                                                            ? "Copied!"
                                                            : "Click to Copy"
                                                        : row?.page
                                                }
                                                placement={
                                                    row?.query
                                                        ? "right"
                                                        : "bottom"
                                                }
                                                arrow
                                                // PopperProps={{ disablePortal: true }}
                                                onOpen={(e: any) => {
                                                    console.log(
                                                        e,
                                                        document.querySelector(
                                                            "[role=tooltip]",
                                                        ),
                                                    );
                                                    if (
                                                        !row?.query &&
                                                        e.target.scrollWidth <=
                                                            e.target.offsetWidth
                                                    ) {
                                                        setImmediate(() => {
                                                            document
                                                                .querySelectorAll(
                                                                    "[role=tooltip]",
                                                                )
                                                                .forEach(
                                                                    (e: any) =>
                                                                        (e.style.display =
                                                                            "none"),
                                                                );
                                                        });
                                                    }
                                                }}
                                            >
                                                <div
                                                    className={`${
                                                        styles.rowItem_data_value
                                                    } ${
                                                        row?.query
                                                            ? styles.keyword
                                                            : styles.page
                                                    }`}
                                                    onClick={(e) => {
                                                        if (!row?.query) {
                                                            e.stopPropagation();
                                                            window.open(
                                                                row.page,
                                                            );
                                                        } else {
                                                            copyToClipboard(
                                                                row.query,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {row?.query
                                                        ? row?.query
                                                        : removeDomainFromURL(
                                                              row.page,
                                                          )}
                                                </div>
                                            </LightTooltip>
                                            {!row.query && (
                                                <LightTooltip
                                                    placement={"right"}
                                                    title={
                                                        isCopied
                                                            ? "Copied!"
                                                            : "Click to Copy"
                                                    }
                                                    arrow
                                                >
                                                    <span
                                                        className={
                                                            styles.externalLink
                                                        }
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(
                                                                row.page,
                                                            );
                                                        }}
                                                    >
                                                        <SVGIcon
                                                            src={
                                                                "/New UI/SVG/copy.svg"
                                                            }
                                                            size={"14"}
                                                        />
                                                    </span>
                                                </LightTooltip>
                                            )}
                                        </div>
                                        <div
                                            className={`${styles.rowItem_rank} `}
                                        >
                                            <div style={{ minWidth: "33px" }}>
                                                {Math.round(row.position)}
                                            </div>
                                            {Math.round(row.delta_position) !=
                                            0 ? (
                                                <div
                                                    className={`${
                                                        styles.change
                                                    } ${
                                                        row.delta_position < 0
                                                            ? styles.loss
                                                            : styles.gain
                                                    }`}
                                                >
                                                    <SVGIcon
                                                        src="/New UI/SVG/arrowUp.svg"
                                                        size={9}
                                                        style={
                                                            row.delta_position <
                                                            0
                                                                ? {
                                                                      transform:
                                                                          "rotate(90deg)",
                                                                  }
                                                                : {}
                                                        }
                                                    />
                                                    {Math.round(
                                                        Math.abs(
                                                            row.delta_position,
                                                        ),
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    className={styles.nullBox}
                                                ></div>
                                            )}
                                        </div>
                                        <div className={styles.rowItem_volume}>
                                            {getValueWithUnit(row.impressions)}
                                        </div>
                                        <div className={styles.rowItem_traffic}>
                                            {row.clicks}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <NoGraphData />
                    )}
                </div>
            </div>
        </div>
    );
};

const TopGainsTableRowShimmer = () => {
    return (
        <div>
            {defaultRow.map((i) => (
                <div className={styles.rowItem_shimmer}>
                    <Skeleton width={"73%"} height={"34px"} />
                    <Skeleton width={"15%"} height={"34px"} />
                    <Skeleton width={"12%"} height={"34px"} />
                </div>
            ))}
        </div>
    );
};

const TableToggles = [
    { label: "Keywords", key: "keyword" },
    { label: "Pages", key: "page" },
];

const TopGainsTable = ({
    tableHeading,
    page = {},
    keyword = {},
    isLoser = false,
    isPage = true,
    isShimmer = true,
    tooltip = "",
}: {
    tableHeading: string;
    page?: any;
    keyword?: any;
    isLoser?: boolean;
    isPage?: boolean;
    isShimmer?: boolean;
    tooltip?: string;
}) => {
    const [selectedTab, setSelectedTab] = useState(ColumnHeadings[0]);
    const { removeDomainFromURL, getValueWithUnit } = useGscHook();
    const { taTrackInsightSection } = useMixpanelTA();
    const [tableToggle, setTableToggle] = useState(TableToggles[0]);
    const [eventTriggered, setEventTriggered] = useState(false);
    const handleTabClick = (key: any) => {
        setSelectedTab(key);
    };

    useEffect(() => {
        setSelectedTab(ColumnHeadings[0]);
    }, [tableToggle]);

    const [isCopied, setIsCopied] = useState(false);
    const data =
        tableToggle.label?.toLowerCase() == "pages"
            ? { ...page }
            : { ...keyword };
    useEffect(() => {
        let timer: any = null;
        if (isCopied) {
            timer = setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    };

    return (
        <div
            className={`${styles.tableContainer} ${styles.tableContainerMain}`}
            onMouseEnter={() => {
                if (!eventTriggered) {
                    taTrackInsightSection({ sectionName: tableHeading });
                    setEventTriggered(true);
                }
            }}
        >
            <div className={styles.tableMeta}>
                <div className={styles.tableTitle}>
                    {tableHeading}
                    <SVGIcon
                        tooltip={tooltip}
                        src={"/New UI/SVG/info.svg"}
                        size={17}
                    />
                </div>
                <div className={styles.tableToggle}>
                    <FilterGraphData
                        handleFilter={setTableToggle}
                        filterOptions={TableToggles}
                        selectedFilter={tableToggle}
                    />
                </div>
            </div>

            <div className={styles.categoryContainer}>
                {ColumnHeadings &&
                    ColumnHeadings.map((heading, index) => {
                        return (
                            <>
                                <Button
                                    handler={() => handleTabClick(heading)}
                                    props={{
                                        style: {
                                            borderRadius: "inherit",
                                            margin: "2px",
                                        },
                                    }}
                                    key={heading.label}
                                    // style={top == 20 && onStyle}
                                    text={heading.label}
                                    customClass={
                                        styles.catTile +
                                        " " +
                                        (heading.label === selectedTab?.label &&
                                            styles.activeCat)
                                    }
                                />
                                {ColumnHeadings.length - 1 != index && (
                                    <div className={styles.separator}> </div>
                                )}
                            </>
                        );
                    })}
            </div>

            <div
                className={`${
                    tableHeading.toLowerCase().includes("mover")
                        ? styles.tableWrapper
                        : styles.temp
                }`}
            >
                <div className={styles.tableHeaders}>
                    <div className={styles.head_pageUrl}>
                        {tableToggle.label?.toLowerCase() == "keywords"
                            ? "Keywords"
                            : "Pages"}
                    </div>

                    <div className={styles.head_change}>Change</div>
                    <div className={styles.head_currentValue}>
                        {selectedTab.label}
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {isShimmer ? (
                        <TopGainsTableRowShimmer />
                    ) : data[selectedTab.key]?.length ? (
                        data[selectedTab.key].map((row: any, index: number) => {
                            return (
                                <div key={index} className={styles.tableRow}>
                                    <div className={styles.row_pageUrl}>
                                        <LightTooltip
                                            title={
                                                tableToggle.label?.toLowerCase() ==
                                                "pages"
                                                    ? row?.key
                                                    : isCopied
                                                    ? "Copied!"
                                                    : "Click to Copy"
                                            }
                                            placement={
                                                tableToggle.label?.toLowerCase() ==
                                                "pages"
                                                    ? "bottom"
                                                    : "right"
                                            }
                                            arrow
                                            // PopperProps={{ disablePortal: true }}
                                            onOpen={(e: any) => {
                                                console.log(
                                                    e,
                                                    document.querySelector(
                                                        "[role=tooltip]",
                                                    ),
                                                );
                                                if (
                                                    tableToggle.label?.toLowerCase() ==
                                                        "pages" &&
                                                    e.target.scrollWidth <=
                                                        e.target.offsetWidth
                                                ) {
                                                    setImmediate(() => {
                                                        document
                                                            .querySelectorAll(
                                                                "[role=tooltip]",
                                                            )
                                                            .forEach(
                                                                (e: any) =>
                                                                    (e.style.display =
                                                                        "none"),
                                                            );
                                                    });
                                                }
                                            }}
                                        >
                                            <div
                                                className={`${styles._data} ${
                                                    tableToggle.label?.toLowerCase() ==
                                                    "pages"
                                                        ? styles.page
                                                        : styles.keyword
                                                }`}
                                                onClick={(e) => {
                                                    if (
                                                        tableToggle.label?.toLowerCase() ==
                                                        "pages"
                                                    ) {
                                                        e.stopPropagation();
                                                        window.open(row.key);
                                                    } else {
                                                        copyToClipboard(
                                                            row.key,
                                                        );
                                                    }
                                                }}
                                            >
                                                {tableToggle.label?.toLowerCase() ==
                                                "pages"
                                                    ? removeDomainFromURL(
                                                          row.key,
                                                      )
                                                    : row?.key}
                                            </div>
                                        </LightTooltip>
                                        {tableToggle.label?.toLowerCase() ==
                                            "pages" && (
                                            <LightTooltip
                                                placement={"right"}
                                                title={
                                                    isCopied
                                                        ? "Copied!"
                                                        : "Click to Copy"
                                                }
                                                arrow
                                            >
                                                <span
                                                    className={
                                                        styles.externalLink
                                                    }
                                                    onClick={(e: any) => {
                                                        e.stopPropagation();
                                                        copyToClipboard(
                                                            row.key,
                                                        );
                                                    }}
                                                >
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/copy.svg"
                                                        }
                                                        size={"14"}
                                                    />
                                                </span>
                                            </LightTooltip>
                                        )}
                                    </div>
                                    <div
                                        className={`${styles.row_change} ${
                                            isLoser ? styles.lose : styles.gain
                                        } ${
                                            Math.round(row.delta.toFixed(2)) ==
                                                0 && styles.noDataBox
                                        }`}
                                    >
                                        <div className={styles._value}>
                                            <SVGIcon
                                                src="/New UI/SVG/arrowUp.svg"
                                                size={11}
                                                style={
                                                    isLoser
                                                        ? {
                                                              transform:
                                                                  "rotate(90deg)",
                                                          }
                                                        : {}
                                                }
                                            />
                                            {getValueWithUnit(
                                                Math.abs(
                                                    Math.round(
                                                        row.delta.toFixed(2),
                                                    ),
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.row_currentValue}>
                                        {row.currentValue == null
                                            ? "- "
                                            : Math.round(
                                                  row.currentValue.toFixed(2),
                                              ) == 0
                                            ? "0"
                                            : getValueWithUnit(
                                                  Math.abs(
                                                      Math.round(
                                                          row.currentValue.toFixed(
                                                              2,
                                                          ),
                                                      ),
                                                  ),
                                              )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <NoGraphData />
                    )}
                </div>
            </div>
        </div>
    );
};
export default TopGainsTable;
