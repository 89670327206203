import React, { useState } from "react";
import Button from "../../componentsV2/atoms/Button/Button";
import styles from "./priceCard.module.css";
import styles2 from "./PriceCardBF.module.css";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { CREATE_PAYMENT, UPDATE_PLAN } from "../../componentsV2/api.json";
import Confirmation from "../../componentsV2/atoms/ConfirmationDialog/confirmation";
import useNavigation from "../../componentsV2/atoms/GoToRoute/useNavigation";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import InviteLimit from "../../componentsV2/organisms/Account/InviteLimit";
import {
    ADD_ONS_TYPE,
    PLANS_ACTION,
    PLANS_CATEGORIES,
    PLANS_TYPE,
} from "../../constants";
import { STRIPE_TOKEN1, STRIPE_TOKEN2 } from "../../utility/config";
import useUserAccessHook from "../account/useUserAccessHook";
import { useHistory } from "react-router-dom";
import { planCatInfo } from "./lists";
import SVGIcon from "../../componentsV2/atoms/SVGIcon";
import { VscClose } from "react-icons/vsc";
import useMixpanelHook from "../../utility/useMixpanelHook";
import {
    getYearSave,
    parsePrice,
} from "../../componentsV2/templates/Dashboard/usePriceDiscounts";
import { customLoadStripe } from "../../componentsV2/atoms/Stripe";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import { updateUserInfo } from "../../store/actions/auth";
import { LmAddOnCheck } from "./LmAddOnCard";
import HumanizerAddOn from "./HumanizerAddOn";
const individualImg = "/AssetsV2/individual-icon.svg";
const growthImg = "/AssetsV2/Growth-icon@1.5x.svg";
const proImg = "/AssetsV2/pro-icon.svg";

function getPrice(data = [], type) {
    const plan = data.filter(
        (item) => item.plan_category == 6 && item.plan_type == type,
    )[0];

    return (
        !!plan &&
        `${plan.currency == "INR" ? "₹" : "$"}${
            type == PLANS_TYPE.YEARLY
                ? (plan.plan_cost / 12).toFixed(0)
                : plan.plan_cost
        }`
    );
}
//category ie individual,growthy or  pro

const dataAcctoType = {};
export const paymentPopUpDimension = `left=${window.outerWidth / 2 - 300},top=${
    window.outerHeight / 2 - 300
},width=${screen.width / 3},height=${screen.height / 1.5}`;

export const OfflinePaymentModal = ({ setModal = () => {}, state }) => {
    return (
        <Modal setModal={setModal}>
            <div className={styles.warningModal}>
                <span className={styles.close} onClick={() => setModal(false)}>
                    <VscClose />
                </span>

                <div className={styles.warningText}>
                    Your last payment was done outside the system, please
                    contact chat support to complete this action.
                </div>
                <div className={styles.warning__cta}>
                    <Button text={"OK"} handler={() => setModal(false)} />
                </div>
            </div>
        </Modal>
    );
};

function PriceCardBF({
    maxPlans,
    item,
    planType,
    prevVal,
    otherInfo,
    currentPlanId,
    hidePrice = false,
    details,
    data,
    isOpenChat = false,
    getDiscount,
    signupMode = null,
    lmPlan = {},
    validHumanizerPlans = {},
    defaultAddOnChecked = false,
    setDefaultAddOnChecked = () => {},
    humanizerChecked = false,
    setHumanizerChecked = () => {},
}) {
    console.log("iiiiiiii", item);
    const freeuser = true;
    const dispatch = useDispatch();
    const infoTextAccToCatogory = {
        2: {
            subheading: "Perfect for individual creators and consultants.",
            icon: individualImg,
            offer: freeuser
                ? "Save 16% + 50% on annual plans"
                : "Save 16% on annual plans",
            keyPoints: [
                {
                    enabled: true,
                    text: "100000 AI words",
                },
                { enabled: true, text: "5 SEO Articles" },

                {
                    enabled: true,
                    text: "40+ AI tools",
                },
                {
                    enabled: true,
                    text: "Write",
                },
                {
                    enabled: true,
                    text: "24x7 live chat support",
                },
                {
                    enabled: true,
                    text: "24x7 email support",
                },
                {
                    enabled: false,
                    text: "Cruise Mode",
                },
                {
                    enabled: false,
                    text: "Keyword Planner",
                },
                {
                    enabled: false,
                    text: "Instruct",
                },

                {
                    enabled: false,
                    text: "AI Settings",
                },
            ],
        },

        3: {
            subheading: "Ideal for budding startups and growing businesses",
            icon: growthImg,
            offer: freeuser
                ? "Save 16% + 50% on annual plans"
                : "Save 16% on annual plans",
            keyPoints: [
                {
                    enabled: true,
                    text: "Unlimited AI words",
                },
                { enabled: true, text: "30 SEO Articles" },

                {
                    enabled: true,
                    text: "40+ AI tools",
                },
                {
                    enabled: true,
                    text: "Write",
                },
                {
                    enabled: true,
                    text: "24x7 live chat support",
                },
                {
                    enabled: true,
                    text: "24x7 email support",
                },
                {
                    enabled: true,
                    text: "Cruise Mode",
                },
                {
                    enabled: true,
                    text: "30 - Keyword Reports",
                },
                {
                    enabled: true,
                    text: "Instruct",
                },

                {
                    enabled: true,
                    text: "AI Settings",
                },
                {
                    enabled: true,
                    text: "Paraphrasing",
                },
                {
                    enabled: true,
                    text: "SERP Facts",
                },
                {
                    enabled: true,
                    text: "Collaboration",
                },
                {
                    enabled: true,
                    text: "Buy additional SaaS credits",
                },
                {
                    enabled: false,
                    text: `Additional team Members`,
                },
                {
                    enabled: false,
                    text: "Dedicated customer success manager",
                },
            ],
        },
        4: {
            subheading: "Ideal for large teams, businesses and agencies",
            icon: proImg,
            offer: freeuser
                ? "Save 16% + 50% on annual plans"
                : "Save 16% on annual plans",
            keyPoints: [
                {
                    enabled: true,
                    text: "Unlimited AI words",
                },
                { enabled: true, text: "Unlimited SEO Articles" },

                {
                    enabled: true,
                    text: "40+ AI tools",
                },
                {
                    enabled: true,
                    text: "Write",
                },
                {
                    enabled: true,
                    text: "24x7 live chat support",
                },
                {
                    enabled: true,
                    text: "24x7 email support",
                },
                {
                    enabled: true,
                    text: "Cruise Mode",
                },
                {
                    enabled: true,
                    text: "100 - Keyword Reports",
                },
                {
                    enabled: true,
                    text: "Instruct",
                },

                {
                    enabled: true,
                    text: "AI Settings",
                },
                {
                    enabled: true,
                    text: "Paraphrasing",
                },
                {
                    enabled: true,
                    text: "SERP Facts",
                },
                {
                    enabled: true,
                    text: "Collaboration",
                },
                {
                    enabled: true,
                    text: "Buy additional SaaS credits",
                },
                {
                    enabled: true,
                    text: `2 users; add more @ ${getPrice(
                        data,
                        planType,
                    )}/mo per user`,
                },
                {
                    enabled: true,
                    text: "Dedicated customer success manager",
                },
            ],
        },
        5: {
            subheading: "Keep creating content, free forever",
            icon: individualImg,
            offer: "Free Forever",
            keyPoints: [
                {
                    enabled: true,
                    text: "2000 AI words",
                },
                { enabled: true, text: "2 SEO Articles" },

                {
                    enabled: true,
                    text: "40+ AI tools",
                },
                {
                    enabled: true,
                    text: "Write",
                },
                {
                    enabled: false,
                    text: "24x7 live chat support",
                },
                {
                    enabled: false,
                    text: "24x7 email support",
                },
                {
                    enabled: false,
                    text: "Cruise Mode",
                },
                {
                    enabled: false,
                    text: "Keyword Planner",
                },
                {
                    enabled: false,
                    text: "Instruct",
                },

                {
                    enabled: false,
                    text: "AI Settings",
                },
                {
                    enabled: false,
                    text: "Paraphrasing",
                },
                {
                    enabled: false,
                    text: "SERP Facts",
                },
                {
                    enabled: false,
                    text: "Collaboration",
                },
                {
                    enabled: false,
                    text: "Buy additional SaaS credits",
                },
                {
                    enabled: false,
                    text: `Additional team Members`,
                },
                {
                    enabled: false,
                    text: "Dedicated customer success manager",
                },
            ],
        },
        10: {
            subheading: "Ideal for budding startups and growing businesses",
            icon: growthImg,
            offer: freeuser
                ? "Save 16% + 50% on annual plans"
                : "Save 16% on annual plans",
            keyPoints: [
                {
                    enabled: true,
                    text: "Unlimited AI words",
                },
                { enabled: true, text: "30 SEO Articles" },

                {
                    enabled: true,
                    text: "40+ AI tools",
                },
                {
                    enabled: true,
                    text: "Write",
                },
                {
                    enabled: true,
                    text: "24x7 live chat support",
                },
                {
                    enabled: true,
                    text: "24x7 email support",
                },
                {
                    enabled: true,
                    text: "Cruise Mode",
                },
                {
                    enabled: true,
                    text: "30 - Keyword Reports",
                },
                {
                    enabled: true,
                    text: "Instruct",
                },

                {
                    enabled: true,
                    text: "AI Settings",
                },
                {
                    enabled: true,
                    text: "Paraphrasing",
                },
                {
                    enabled: true,
                    text: "SERP Facts",
                },
                {
                    enabled: true,
                    text: "Collaboration",
                },
                {
                    enabled: true,
                    text: "Buy additional SaaS credits",
                },
                {
                    enabled: false,
                    text: `Additional team Members`,
                },
                {
                    enabled: false,
                    text: "Dedicated customer success manager",
                },
            ],
        },
        11: {
            subheading: "Ideal for large teams, businesses and agencies",
            icon: proImg,
            offer: freeuser
                ? "Save 16% + 50% on annual plans"
                : "Save 16% on annual plans",
            keyPoints: [
                {
                    enabled: true,
                    text: "Unlimited AI words",
                },
                { enabled: true, text: "Unlimited SEO Articles" },

                {
                    enabled: true,
                    text: "40+ AI tools",
                },
                {
                    enabled: true,
                    text: "Write",
                },
                {
                    enabled: true,
                    text: "24x7 live chat support",
                },
                {
                    enabled: true,
                    text: "24x7 email support",
                },
                {
                    enabled: true,
                    text: "Cruise Mode",
                },
                {
                    enabled: true,
                    text: "100 - Keyword Reports",
                },
                {
                    enabled: true,
                    text: "Instruct",
                },

                {
                    enabled: true,
                    text: "AI Settings",
                },
                {
                    enabled: true,
                    text: "Paraphrasing",
                },
                {
                    enabled: true,
                    text: "SERP Facts",
                },
                {
                    enabled: true,
                    text: "Collaboration",
                },
                {
                    enabled: true,
                    text: "Buy additional SaaS credits",
                },
                {
                    enabled: true,
                    text: `2 users; add more @ ${getPrice(
                        data,
                        planType,
                    )}/mo per user`,
                },
                {
                    enabled: true,
                    text: "Dedicated customer success manager",
                },
            ],
        },
    };

    const [upgradeModal, setUpgradeModal] = useState({
        state: false,
        type: "upgrade",
        planId: 0,
    });
    const teamMembers = otherInfo?.subscriptionInfo?.current_team_members;

    const goTo = useNavigation();
    const [categoryVal, setCategoryVal] = useState(
        infoTextAccToCatogory[item.plan_category] || infoTextAccToCatogory[2],
    );
    const [loader, setLoader] = useState(false);
    const user_id = useSelector((state) => state.auth.userId);
    const isThisCurr =
        details.type == item.plan_type &&
        details.plan_category == item.plan_category;
    const [inviteLimitErr, setInviteLimitErr] = useState(false);
    let stripeID =
        otherInfo?.subscriptionInfo?.stripe_region == 2
            ? STRIPE_TOKEN2
            : STRIPE_TOKEN1;
    const stripePromise = customLoadStripe(stripeID);
    const { isFreeForever, trialUser, freeUser, isLTD } = useUserAccessHook();
    const { postData, getData } = useHelperFunctions();
    const history = useHistory();
    const [isOfflinePaymentWarning, setIsOfflinePaymentWarning] =
        useState(false);
    const [isMode2Trial, setIsMode2Trial] = useState(
        signupMode == 2 &&
            otherInfo?.subscriptionInfo?.plan_category != 1 &&
            otherInfo?.subscriptionInfo?.trial_status == 1,
    );
    // Sale Revert
    const isDeal = false;
    const {
        mpTrackCampaignOfferPlanUpgrade,
        mpTrackPaymentStatus,
        mixpanelTrack,
    } = useMixpanelHook();
    const handleClick = async (planId, plan_category) => {
        mixpanelTrack("Subscription Page", {
            action: "Max Plan CTA clicked",
        });
        if (isOpenChat) {
            setIsOfflinePaymentWarning(true);
            window.userengage && window.userengage("widget.show");
            window.userengage && window.userengage("widget.open");
            return;
        }
        if (teamMembers > item.max_team_members) {
            return setInviteLimitErr("downgrade");
        }
        let tempAddOnIdsObj = item.add_on_plan_ids
            ? JSON.parse(item?.add_on_plan_ids)
            : {};
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^", tempAddOnIdsObj);
        if (
            item.action == PLANS_ACTION.CHOOSE ||
            item.action == PLANS_ACTION.UPGRADE
        ) {
            setLoader(true);
            mpTrackCampaignOfferPlanUpgrade(item);
            // let tempAddOnIdsObj = item.add_on_plan_ids
            //     ? JSON.parse(item?.add_on_plan_ids)
            //     : {};
            // console.log("^^^^^^^^^^^^^^^^^^^^^^^^", tempAddOnIdsObj);
            await createSession(
                planId,
                defaultAddOnChecked
                    ? {
                          new: tempAddOnIdsObj,
                          prev: otherInfo?.subscriptionInfo
                              ?.interlinking_plan_id,
                      }
                    : null,
                tempAddOnIdsObj,
                () => {},
                plan_category,
            );
        } else {
            if (item.action == PLANS_ACTION.DOWNGRADE)
                return setUpgradeModal({
                    state: true,
                    type: "downgrade",
                    planId,
                    tempAddOnIdsObj,
                });
        }
    };

    const startHumanizer = async (skipUpdate = false) => {
        const res = await postData({ url: "/api/user/disable-humanizer" });
        if (res.status === 200) {
            if (!skipUpdate) {
                dispatch(updateUserInfo("show_humanizer_landing", 0));
            }
        }
    };

    const startLM = async (skipUpdate = false) => {
        setFirstLanding(false);
        const res = await postData({ url: "/api/user/disable-lm" });
        if (res.status === 200) {
            if (!skipUpdate) {
                dispatch(updateUserInfo("show_lm_landing", 0));

                setFirstLanding(false);
            }
        }
    };

    async function createSession(
        planId,
        addOnIdsObj = null,
        addOnObj,
        closeDialog = () => {},
        plan_category,
    ) {
        // if (currency === "INR" && upgradeModal.type == "upgrade" && closeDialog) {
        //   closeDialog();
        //   window.Intercom("show");
        //   return;
        // }
        const humanizerQuantity =
            otherInfo.subscriptionInfo.plan_category ==
                PLANS_CATEGORIES.GROWTH_MAX ||
            otherInfo.subscriptionInfo.plan_category == PLANS_CATEGORIES.PRO_MAX
                ? (otherInfo.subscriptionInfo?.humanizer_purchased_quantity ||
                      2) - 2
                : otherInfo.subscriptionInfo?.humanizer_purchased_quantity || 2;

        let humanizerAddOn = {
            humanizer_purchased_quantity: humanizerQuantity,
            humanizer_plan_id: addOnObj.humanizer,
            add_on: ADD_ONS_TYPE.AI_HUMANIZER,
            prev_humanizer_plan_id:
                otherInfo.subscriptionInfo?.humanizer_plan_id || null,
        };
        let baseURL = window.location.origin;
        let successURL = `${baseURL}/admin/transaction568894547/98797984111237/?session_id={CHECKOUT_SESSION_ID}`;
        let cancelURL = `${baseURL}/admin/transactions/failed/`;

        let criteria = {
            successURL,
            cancelURL,
            mode: "subscription",
            tid: localStorage.getItem("FPROM_ID"),
            type: 1,
            planID: planId,
            ...((humanizerChecked ||
                otherInfo.subscriptionInfo?.humanizer_status == 1) &&
                humanizerQuantity > 0 && {
                    add_on_info: [humanizerAddOn],
                }),
            ...(defaultAddOnChecked &&
                !!addOnIdsObj?.new?.interlinking && {
                    add_on: ADD_ONS_TYPE.LM_PAGES,
                    additional_quantity: 2,
                    add_on_plan_id: addOnIdsObj?.new?.interlinking,
                }),
        };
        if (
            item.action == PLANS_ACTION.CHOOSE ||
            details.plan_category == PLANS_CATEGORIES.FREE_FOREVER
        ) {
            const stripe = await stripePromise;

            const response = await postData({
                url: CREATE_PAYMENT,
                payload: criteria,
            });

            stripe.redirectToCheckout({
                sessionId: response?.data?.paymentTransactionID,
            });
        } else {
            // let StripeInvoicePayment = null;
            document.StripeInvoicePayment = window.open(
                "",
                "",
                paymentPopUpDimension,
            );
            document.StripeInvoicePayment.document.write(
                "<html><head></head><body>Please wait while we redirect you</body></html>",
            );

            let LM_Param = {};
            if (addOnIdsObj?.new?.interlinking) {
                LM_Param.additional_quantity =
                    otherInfo.subscriptionInfo
                        ?.interlinking_purchased_pages_quantity || 2;
                LM_Param.add_on_plan_id = addOnIdsObj?.new?.interlinking;
                LM_Param.prev_add_on_plan_id = addOnIdsObj?.prev || null;
                LM_Param.add_on = ADD_ONS_TYPE.LM_PAGES;
            }
            const res = await postData({
                url: UPDATE_PLAN,
                payload: {
                    planID: planId,
                    type: 1,
                    from: currentPlanId,
                    // add_on: ADD_ONS_TYPE.LM_PAGES,
                    ...((humanizerChecked ||
                        otherInfo.subscriptionInfo?.humanizer_status) &&
                        humanizerQuantity > 0 && {
                            add_on_info: [humanizerAddOn],
                        }),
                    ...(defaultAddOnChecked && { ...LM_Param }),
                },
            });
            if (res.status == 200) {
                if (
                    plan_category == PLANS_CATEGORIES.GROWTH_MAX ||
                    plan_category == PLANS_CATEGORIES.PRO_MAX
                ) {
                    startHumanizer();
                    startLM();
                }
                console.log("LM_Param", LM_Param);
                if (item.action == PLANS_ACTION.DOWNGRADE) {
                    dispatch(updateUserInfo("planDowngraded", true));
                }
                if (item.action == PLANS_ACTION.UPGRADE) {
                    mpTrackPaymentStatus({
                        campaign: "BF Deals",
                        status: "Success",
                    });
                    dispatch(updateUserInfo("planDowngraded", false));
                }
                document?.StripeInvoicePayment?.close();
                goTo("DUMMY_TRANSACTION");

                return setTimeout(
                    () => goTo("BILLING_PLANS", [], "", true),
                    5000,
                );
            }
            if (res.status == 201) {
                if (item.action == PLANS_ACTION.DOWNGRADE) {
                    dispatch(updateUserInfo("planDowngraded", true));
                }
                if (item.action == PLANS_ACTION.UPGRADE) {
                    dispatch(updateUserInfo("planDowngraded", false));
                }
                document.StripeInvoicePayment.location =
                    res.data.invoice.hosted_invoice_url;
                goTo("DUMMY_TRANSACTION");
            }
        }
        setLoader(false);
        if (closeDialog) closeDialog();
    }

    const getCurrencySign = (currency) => {
        if (currency === "USD") return "$";
        else if (currency === "INR") return "₹";
    };

    if (item.plan_category == 6) {
        return null;
    }

    function setGTagId(name) {
        console.log("name", name);
        if (name.indexOf("Downgrade") != -1) {
            return "gt-account-degrade";
        } else if (name.indexOf("Upgrade") != -1) {
            return "gt-account-upgrade";
        } else if (name.indexOf("Choose") != -1) {
            return "gt-account-choose-plan";
        }

        return "";
    }

    const endTrialBuyNow = async (planId) => {
        document.StripeInvoicePayment = window.open(
            "",
            "",
            paymentPopUpDimension,
        );
        setLoader(true);
        document.StripeInvoicePayment.document?.write(
            "<html><head></head><body>Please wait while we redirect you</body></html>",
        );

        const res = await getData({ url: "/api/pricing/cancelTrail" });
        if (res.status == 200) {
            console.log("endTrialBuyNow", res.data);
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            goTo("DUMMY_TRANSACTION");
            setLoader(false);
        }
    };

    function isAsterikShow(text) {
        if (text.length) {
            let temp = text.toLowerCase();

            if (temp.includes("words")) {
                return true;
            } else if (temp.includes("reports")) {
                return true;
            } else if (temp.includes("clusters")) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div className={styles.priceCardWrapper}>
            <div
                className={`${styles.priceCard} ${styles.priceCardBF} ${
                    isThisCurr && !details?.terminate
                        ? styles.selectedBorderBF
                        : ""
                } ${hidePrice ? styles.hideCard : ""}`}
            >
                <div className={styles.cardTopDiv}>
                    <div className={styles.categoryHeading}>
                        {item.plan_name}
                        {isThisCurr &&
                            isMode2Trial &&
                            !maxPlans &&
                            details?.terminate != 1 && (
                                <div className={styles.currentTag}>
                                    Current Plan
                                </div>
                            )}
                    </div>
                    <div className={styles2.categoryInfoBF}>
                        {categoryVal.subheading}
                    </div>
                </div>

                <div className={styles.cardPricingDiv}>
                    <div className={styles.cardPriceOriginaldivBF}>
                        {freeuser && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "20px",
                                }}
                            >
                                <span
                                    style={{
                                        visibility: !getDiscount(
                                            maxPlans ? 5 : item.plan_type,
                                        )
                                            ? "hidden"
                                            : "unset",
                                    }}
                                    className={styles.cardPriceOriginalDisBF}
                                >
                                    {parsePrice(
                                        item.plan_cost,
                                        item.currency,
                                        item.plan_type,
                                    )}
                                </span>
                                {/* <span
                                    className={styles.offerBF}
                                    style={{
                                        visibility: !getDiscount(item.plan_type)
                                            ? "hidden"
                                            : "unset",
                                    }}
                                >
                                    <strong style={{ fontWeight: 600 }}>
                                        {getDiscount(item.plan_type)}% OFF
                                    </strong>{" "}
                                    ScaleDays Offer
                                </span> */}
                            </div>
                        )}
                        <span className={styles2.cardPriceOriginalBF}>
                            {parsePrice(
                                item.plan_cost,
                                item.currency,
                                item.plan_type,
                                getDiscount(maxPlans ? 5 : item.plan_type),
                            )}
                        </span>
                        {"/mo"}
                        {freeuser && (
                            <div
                                className={styles.youSaveBF}
                                style={{
                                    visibility: !getDiscount(
                                        maxPlans ? 5 : item.plan_type,
                                    )
                                        ? "hidden"
                                        : "unset",
                                }}
                            >
                                Billed annually{" "}
                                <strong>
                                    (Save{" "}
                                    {getYearSave(
                                        item.plan_cost,
                                        item.currency,
                                        item.plan_type,
                                        getDiscount(
                                            maxPlans ? 5 : item.plan_type,
                                        ),
                                    )}
                                    /Year)
                                </strong>
                            </div>
                        )}
                    </div>
                </div>
                {item.action == PLANS_ACTION.PLANTOFREE ||
                item.action == PLANS_ACTION.TRAILTOFREE ? (
                    <Button
                        text={item.action}
                        style={{
                            borderRadius: "26px",
                            backgroundColor: `#F3C975`,
                            minHeight: "50px",
                            color: `${"#000"}`,
                        }}
                        height="50px"
                        width="100%"
                        id={setGTagId(item.action)}
                    />
                ) : (
                    <Button
                        text={
                            isMode2Trial &&
                            details?.terminate != 1 &&
                            isThisCurr
                                ? "End Trial and Buy Now"
                                : item.action
                        }
                        handler={() => {
                            if (
                                isMode2Trial &&
                                details?.terminate != 1 &&
                                isThisCurr
                            ) {
                                endTrialBuyNow(item.plan_id);
                            } else {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: "plan_purchase_click",
                                        user_id: user_id,
                                        plan_type: item?.plan_name,
                                    },
                                });
                                handleClick(item.plan_id, item.plan_category);
                            }
                        }}
                        Icon={() =>
                            item.action?.startsWith("Downgrade") ? (
                                <FiArrowDownRight />
                            ) : item.action?.startsWith("Upgrade") ? (
                                <FiArrowUpRight />
                            ) : (
                                ""
                            )
                        }
                        loading={loader}
                        id={setGTagId(item.action)}
                        style={{
                            borderRadius: "26px",
                            backgroundColor: `${
                                hidePrice
                                    ? "rgba(1,77,197,0.1)"
                                    : isThisCurr && details?.terminate != 1
                                    ? "rgba(1,77,197,0.1)"
                                    : "#F3C975"
                            }`,

                            minHeight: "50px",
                            color: `${
                                hidePrice
                                    ? "#014dc5"
                                    : isThisCurr && details?.terminate != 1
                                    ? "#014dc5"
                                    : "#000"
                            }`,
                        }}
                        height="50px"
                        width="100%"
                        disabled={
                            isMode2Trial &&
                            details?.terminate != 1 &&
                            isThisCurr
                                ? false
                                : hidePrice
                                ? true
                                : isThisCurr && details?.terminate != 1
                        }
                    />
                )}

                <ul className={styles.checksListBF}>
                    <h4
                        style={{
                            visibility:
                                planCatInfo(planType)[item.plan_category]
                                    ?.includes == "none"
                                    ? "hidden"
                                    : "visible",
                        }}
                        className={styles.includeText}
                    >
                        {planCatInfo(planType)[item.plan_category]?.includes}
                    </h4>
                    {planCatInfo(planType, true, 2)[
                        item.plan_category
                    ]?.checks?.map((text) => (
                        <li>
                            <div className={styles2.check}>
                                <SVGIcon
                                    size={13}
                                    src="/New UI/SVG/SingleTick.svg"
                                ></SVGIcon>
                            </div>
                            {text?.text
                                ? text.text
                                : text +
                                  `${
                                      isMode2Trial &&
                                      details?.terminate != 1 &&
                                      isThisCurr &&
                                      isAsterikShow(text)
                                          ? " *"
                                          : ""
                                  }`}
                        </li>
                    ))}
                </ul>
                {planCatInfo(planType, true, 2)[item.plan_category]
                    ?.sectionHeading && (
                    <div className={styles.sectionHeadingBF}>
                        {
                            planCatInfo(planType, true, 2)[item.plan_category]
                                ?.sectionHeading
                        }
                    </div>
                )}
                {planCatInfo(planType, true, 2)[item.plan_category]
                    ?.keyFeatures && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "10px",
                        }}
                    >
                        <div>
                            {planCatInfo(planType, true, 2)[
                                item.plan_category
                            ]?.keyFeatures?.firstRow?.map((ele) => (
                                <div className={styles.keyFeaturesChecks}>
                                    <div className={styles2.check}>
                                        <SVGIcon
                                            size={11}
                                            src="/New UI/SVG/SingleTick.svg"
                                        ></SVGIcon>
                                    </div>
                                    <div>{ele}</div>
                                </div>
                            ))}
                        </div>
                        <div>
                            {planCatInfo(planType, true, 2)[
                                item.plan_category
                            ]?.keyFeatures?.secondRow?.map((ele) => (
                                <div className={styles.keyFeaturesChecks}>
                                    <div className={styles2.check}>
                                        <SVGIcon
                                            size={11}
                                            src="/New UI/SVG/SingleTick.svg"
                                        ></SVGIcon>
                                    </div>
                                    <div>{ele}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {!maxPlans && (
                    <div className={styles.lmAddOnCheckWrapper}>
                        <LmAddOnCheck
                            plansType={item.plan_type}
                            isDeal={true}
                            customClass={styles}
                            price={parsePrice(
                                lmPlan.plan_cost,
                                lmPlan.currency,
                                lmPlan.plan_type,
                            )}
                            lmPlan={lmPlan}
                            getDiscount={getDiscount}
                            isThisCurr={isThisCurr}
                            handleChecked={setDefaultAddOnChecked}
                            checked={defaultAddOnChecked}
                            setDefaultAddOnChecked={
                                trialUser || isFreeForever
                                    ? setDefaultAddOnChecked
                                    : () => {}
                            }
                            details={details}
                        />
                        <HumanizerAddOn
                            plansType={item.plan_type}
                            isDeal={true}
                            customClass={styles}
                            price={parsePrice(
                                validHumanizerPlans.plan_cost,
                                validHumanizerPlans.currency,
                                validHumanizerPlans.plan_type,
                            )}
                            validHumanizerPlans={validHumanizerPlans}
                            getDiscount={getDiscount}
                            isThisCurr={isThisCurr}
                            handleChecked={setHumanizerChecked}
                            checked={humanizerChecked}
                            humanizer_status={
                                otherInfo.subscriptionInfo.humanizer_status
                            }
                            details={details}
                        />
                    </div>
                )}
                {upgradeModal.state && (
                    <Confirmation
                        handler={(closeDialog) => {
                            createSession(
                                upgradeModal.planId,
                                {
                                    new: upgradeModal.tempAddOnIdsObj,
                                    prev: otherInfo?.subscriptionInfo
                                        ?.interlinking_plan_id,
                                },
                                upgradeModal.tempAddOnIdsObj,
                                closeDialog,
                            );
                        }}
                        submit={false}
                        setDialog={() =>
                            setUpgradeModal((pstate) => {
                                return { ...pstate, state: false };
                            })
                        }
                        modalHeading={
                            // currency == "INR" && upgradeModal.type == "upgrade"
                            //   ? "Please contact support to upgrade your planType"
                            //   :
                            <>
                                {upgradeModal.planId == 24 ||
                                upgradeModal.planId == 26 ? (
                                    <>
                                        You will be downgraded to the 'Free
                                        Forever' plan after the current billing
                                        period ends. <br /> <br />
                                    </>
                                ) : (
                                    ""
                                )}
                                Are you sure, you want to {upgradeModal.type}?
                            </>
                        }
                    />
                )}
                {inviteLimitErr && (
                    <Modal setModal={setInviteLimitErr}>
                        <InviteLimit
                            inviteLimitErr={inviteLimitErr}
                            setInviteLimitErr={setInviteLimitErr}
                        />
                    </Modal>
                )}
                {isOfflinePaymentWarning && (
                    <OfflinePaymentModal
                        setModal={setIsOfflinePaymentWarning}
                    />
                )}
                {isThisCurr && isMode2Trial && details?.terminate != 1 && (
                    <div className={styles.mode2LimitText}>
                        <span>* </span>
                        <span>Usage limits apply during free trial period</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PriceCardBF;
