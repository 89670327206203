import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { VscClose } from "react-icons/vsc";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import mixpanel from "mixpanel-browser";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Modal from "../../atoms/Modal/Modal";
import Button from "../../atoms/Button/Button";
import { CircularProgress, ClickAwayListener } from "@material-ui/core";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { ADD_ONS_TYPE, PLANS_CATEGORIES, PLANS_TYPE } from "../../../constants";
import useHelperFunctions from "../../helperFunctions";
import { UPDATE_PLAN } from "../../../componentsV2/api.json";

import { paymentPopUpDimension } from "../../../components/subsCards/PriceCardBF";
import { updateUserInfo } from "../../../store/actions/auth";
import { displayAlert } from "../../../store/actions/alert";
import usePriceDiscounts, {
    getMonths,
    getYearSave,
} from "../../templates/Dashboard/usePriceDiscounts";
import { useLinkManagerMixpanel } from "../InterLinking/useLinkManagerMixpanel";
import { OfflinePaymentModal } from "../../../components/subsCards/PriceCard";
const BUNDLE_SIZE = 500;
const INITIAL_PAGES = 1000;
const ADDITIONAL_USD_PRICE = 5;
const ADDITIONAL_INR_PRICE = 425;

const parsePrice = (price, currency, type, discount, isNum = false) => {
    const CalcPrice = parseFloat(
        (
            (discount
                ? discount
                    ? price - (price * discount) / 100
                    : price
                : price) / getMonths(type)
        )?.toFixed(2),
    );
    if (isNum) {
        return CalcPrice;
    } else {
        return `${currency == "USD" ? "$" : "₹"} ${CalcPrice}`;
    }
};

const PagesSelection = ({ pages, setPages, basePages, disabled, bundle }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.bmPagesSelection}>
            {!disabled ? (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div
                        className={styles.menuWrapper}
                        onClick={() => {
                            mixpanel.track("Buy More Pages Modal", {
                                Screen: "Link Manager",
                                "CTA Type": "Additional Pages Dropdown",
                            });
                            setOpen(!open);
                        }}
                    >
                        {pages}{" "}
                        {open ? (
                            <FaChevronUp size={14} />
                        ) : (
                            <FaChevronDown size={14} />
                        )}
                        {open && (
                            <div className={styles.menu}>
                                {new Array(15).fill(1).map((_, i) => {
                                    return (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpen(false);
                                                mixpanel.track(
                                                    "Buy More Pages Modal",
                                                    {
                                                        Screen: "Link Manager",
                                                        "CTA Type":
                                                            i == 14
                                                                ? "Chat Open"
                                                                : "Additional Pages Set",
                                                    },
                                                );
                                                if (i === 14) {
                                                    window.userengage &&
                                                        window.userengage(
                                                            "widget.open",
                                                        );
                                                } else
                                                    setPages(
                                                        i < 6
                                                            ? basePages +
                                                                  bundle * i
                                                            : basePages +
                                                                  bundle * 6 +
                                                                  bundle *
                                                                      2 *
                                                                      (i - 6),
                                                    );
                                            }}
                                        >
                                            {i < 6
                                                ? basePages + bundle * i
                                                : basePages +
                                                  bundle * 6 +
                                                  bundle * 2 * (i - 6)}
                                            {i === 14 ? "+" : ""}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            ) : (
                <div>{pages}&nbsp;&nbsp;</div>
            )}
        </div>
    );
};

const BuyMore = ({
    setBuyPagesModal = () => {},
    domainId = null,
    screen = "-",
    maxPlans,
}) => {
    const { lMBuyPages } = useLinkManagerMixpanel();
    const { plans, getDiscount, isLoading, isOpenChat } = usePriceDiscounts();
    const otherInfo = useSelector((state) => state.otherInfo);
    const { plan_type, plan_category, isLoaded } = useUserAccessHook();
    const dispatch = useDispatch();
    const currency = useSelector((state) => state.auth.currency);
    const currencySymbol = currency == "INR" ? "₹" : "$";
    const [basePages, setBasePages] = useState(
        otherInfo?.subscriptionInfo?.interlinking_purchased_pages || 100,
    );
    const [isOfflinePaymentWarning, setIsOfflinePaymentWarning] =
        useState(false);
    const [bundleSize, setBundleSize] = useState(BUNDLE_SIZE); //increment by
    const [pagesCount, setPagesCount] = useState(basePages);
    const [bundleQuantity, setBundleQuantity] = useState(
        otherInfo?.subscriptionInfo.interlinking_purchased_pages_quantity || 2,
    ); // pagesCount /bundleSize
    const [additionalPrice, setAdditionalPrice] = useState(1);
    const [loading, setLoading] = useState(false);
    const [validPlanType, setValidPlanType] = useState(
        plan_type || PLANS_TYPE.YEARLY,
    );
    const [validPlanCost, setValidPlanCost] = useState(0);
    const [currentAvailablePages, setCurrentAvailablePages] = useState(
        otherInfo?.subscriptionInfo?.interlinking_purchased_pages || 100,
    );
    const [validLmPlan, setValidLmPlan] = useState({});
    const [currentLmPlanId, setCurrentLmPlanId] = useState(
        otherInfo?.subscriptionInfo?.interlinking_plan_id,
    );

    const goTo = useNavigation();
    const { getData, postData } = useHelperFunctions();

    useEffect(() => {
        if (isLoaded) {
            console.log("bbpbp base pages", basePages);
            console.log("bbpbp pagesCount", pagesCount);
            // set Valid Lm Plan for Prices
            let tempType = plan_type || PLANS_TYPE.YEARLY;
            setValidPlanType(tempType);
            if (plans.length) {
                let tempValidPlan = plans.find(
                    (vp) =>
                        vp.plan_type == tempType &&
                        vp.plan_category == PLANS_CATEGORIES.INTER_LINKING,
                );
                setAdditionalPrice(
                    currency == "INR"
                        ? tempType == PLANS_TYPE.YEARLY
                            ? ADDITIONAL_INR_PRICE * 12
                            : ADDITIONAL_INR_PRICE
                        : tempType == PLANS_TYPE.YEARLY
                        ? ADDITIONAL_USD_PRICE * 12
                        : ADDITIONAL_USD_PRICE,
                );
                if (
                    (plan_category == PLANS_CATEGORIES.PRO ||
                        plan_category == PLANS_CATEGORIES.PRO_MAX) &&
                    plan_type == PLANS_TYPE.YEARLY
                ) {
                    let freeLmPlan = plans.find(
                        (vp) =>
                            vp.plan_cost == 0 &&
                            vp.plan_category == PLANS_CATEGORIES.INTER_LINKING,
                    );
                    if (freeLmPlan?.plan_id) {
                        tempValidPlan = {
                            ...tempValidPlan,
                            plan_id: freeLmPlan.plan_id,
                            plan_cost: freeLmPlan.plan_cost,
                        };
                    }
                }
                setValidLmPlan(tempValidPlan || {});
                if (!tempValidPlan) {
                    setBuyPagesModal({ isShow: false });
                }
            }
        }
    }, [isLoaded, plans]);

    useEffect(() => {
        if (otherInfo?.subscriptionInfo) {
            // setCurrent LM plan Id
            setCurrentLmPlanId(
                otherInfo?.subscriptionInfo?.interlinking_plan_id,
            );
            if (
                otherInfo?.subscriptionInfo?.interlinking_purchased_pages <= 100
            ) {
                setPagesCount(INITIAL_PAGES);
                setBasePages(INITIAL_PAGES);
            } else {
                setPagesCount(
                    otherInfo?.subscriptionInfo?.interlinking_purchased_pages +
                        BUNDLE_SIZE,
                );
                setBasePages(
                    otherInfo?.subscriptionInfo?.interlinking_purchased_pages +
                        BUNDLE_SIZE,
                );
            }
            setBundleQuantity(
                otherInfo?.subscriptionInfo
                    .interlinking_purchased_pages_quantity || 2,
            );
            // set Current Available pages
            setCurrentAvailablePages(
                otherInfo?.subscriptionInfo?.interlinking_purchased_pages ||
                    100,
            );
        }
    }, [otherInfo?.subscriptionInfo]);

    useEffect(() => {
        if (pagesCount) {
            setBundleQuantity(pagesCount / BUNDLE_SIZE);
            let minPages =
                otherInfo?.subscriptionInfo?.interlinking_purchased_pages <
                INITIAL_PAGES
                    ? INITIAL_PAGES
                    : otherInfo?.subscriptionInfo?.interlinking_purchased_pages;
            setValidPlanCost(
                pagesCount <= INITIAL_PAGES
                    ? validLmPlan?.plan_cost
                    : getPrice(pagesCount - minPages),
            );
        }
    }, [
        pagesCount,
        validLmPlan,
        otherInfo?.subscriptionInfo?.interlinking_purchased_pages,
    ]);

    const handlerBuyPages = async (_pages, _addBundleQuantity) => {
        if (currentLmPlanId || validLmPlan?.plan_id) {
            let _id = currentLmPlanId || validLmPlan?.plan_id;
            createSession(
                otherInfo?.subscriptionInfo?.plan_id,
                _id,
                _addBundleQuantity,
            );
        } else {
            dispatch(
                displayAlert({
                    alertMessage: "Something went wrong!!! ",
                    alertType: "error",
                }),
            );
        }
    };

    const getAdditionalYearSave = (
        _price = 1,
        discountedPrice = 1,
        currencySymbol,
    ) => {
        let price = _price;
        let tp = price * 12 - discountedPrice * 12;
        return currencySymbol + parseFloat(tp.toFixed(2));
    };

    async function createSession(planId, lmId, newQuantity) {
        if (isOpenChat) {
            setIsOfflinePaymentWarning(true);
            return;
        }
        let baseURL = window.location.origin;
        let successURL = `${baseURL}/admin/transaction568894547/98797984111237/?session_id={CHECKOUT_SESSION_ID}`;
        let cancelURL = `${baseURL}/admin/transactions/failed/`;
        let criteria = {
            successURL,
            cancelURL,
            mode: "subscription",
            tid: localStorage.getItem("FPROM_ID"),
            type: 1,
            planID: planId,
        };
        // let StripeInvoicePayment = null;
        document.StripeInvoicePayment = window.open(
            "",
            "",
            paymentPopUpDimension,
        );
        document.StripeInvoicePayment.document.write(
            "<html><head></head><body>Please wait while we redirect you</body></html>",
        );
        const res = await postData({
            url: UPDATE_PLAN,
            payload: {
                planID: planId,
                type: 1,
                from: planId,
                quantity: 1,
                seat_type: 1,
                add_on: ADD_ONS_TYPE.LM_PAGES,
                additional_quantity: newQuantity,
                add_on_plan_id: lmId,
            },
        });
        if (res.status == 200) {
            document?.StripeInvoicePayment?.close();
            goTo("DUMMY_TRANSACTION");
            return setTimeout(() => goTo("LINK_MANAGER", [], "", true), 5000);
        }
        if (res.status == 201) {
            dispatch(updateUserInfo("planDowngraded", false));
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            goTo("DUMMY_TRANSACTION");
        }
        setLoading(false);
    }

    const getPrice = (remainingPages) => {
        if (currentAvailablePages < INITIAL_PAGES) {
            return (
                validLmPlan?.plan_cost +
                (remainingPages / BUNDLE_SIZE) * additionalPrice
            );
        } else {
            return (remainingPages / BUNDLE_SIZE) * additionalPrice;
        }
    };
    const parseAdditionalPrice = (price, currencySymbol = "") => {
        let dis = getDiscount(maxPlans ? 5 : validPlanType);
        return dis
            ? currencySymbol +
                  parseFloat(
                      (
                          Math.round((price - (price * dis) / 100) * 100) / 100
                      ).toFixed(2),
                  )
            : currencySymbol + price;
    };

    return (
        <>
            <Modal setModal={() => {}}>
                <div className={styles.buyMoreModal}>
                    <div
                        className={styles.closeCta}
                        onClick={() => {
                            lMBuyPages({
                                screen: screen,
                                ctaUsed: "Close",
                            });
                            setBuyPagesModal({ isShow: false, domainId: null });
                        }}
                    >
                        <VscClose size={22} color="#6b6b6b" />
                    </div>
                    {!isLoading ? (
                        <>
                            <div className={styles.modalTitle}>
                                Buy More Pages
                            </div>
                            <div className={styles.perMonthPrice}>
                                {!!validPlanCost && (
                                    <div className={styles.price}>
                                        {getDiscount(
                                            maxPlans
                                                ? 5
                                                : validLmPlan?.plan_type,
                                        ) > 0 && (
                                            <>
                                                <div
                                                    className={styles.strike}
                                                    style={{
                                                        "--bgColor":
                                                            "rgba(255, 232, 102, 1)",
                                                    }}
                                                >
                                                    {parsePrice(
                                                        validPlanCost,
                                                        currency,
                                                        validPlanType,
                                                    )}
                                                </div>
                                                <h3
                                                    className={
                                                        styles.offerDiscount
                                                    }
                                                >
                                                    <div>
                                                        {getDiscount(
                                                            maxPlans
                                                                ? 5
                                                                : validPlanType,
                                                        )}
                                                        % OFF Launch Offer
                                                    </div>
                                                </h3>
                                            </>
                                        )}
                                    </div>
                                )}

                                <h1>
                                    {parsePrice(
                                        validPlanCost,
                                        currency,
                                        validPlanType,
                                        getDiscount(
                                            maxPlans ? 5 : validPlanType,
                                        ),
                                    )}
                                    <small>/mo</small>
                                </h1>
                                {!!validPlanCost && (
                                    <div
                                        className={styles.youSave}
                                        style={{
                                            visibility: !getDiscount(
                                                maxPlans ? 5 : validPlanType,
                                            )
                                                ? "hidden"
                                                : "unset",
                                        }}
                                    >
                                        Billed annually{" "}
                                        <strong>
                                            (Save{" "}
                                            {getYearSave(
                                                validPlanCost,
                                                currency,
                                                validPlanType,
                                                getDiscount(
                                                    maxPlans
                                                        ? 5
                                                        : validPlanType,
                                                ),
                                            )}
                                            /Year)
                                        </strong>
                                    </div>
                                )}
                            </div>
                            <div className={styles.additionalPagesWrap}>
                                <div style={{ fontWeight: "500" }}>
                                    No. of Pages
                                </div>

                                <PagesSelection
                                    pages={pagesCount}
                                    setPages={setPagesCount}
                                    disabled={loading}
                                    basePages={basePages}
                                    bundle={BUNDLE_SIZE}
                                />
                            </div>
                            <div className={styles.currentStats}>
                                <div className={styles.stat1}>
                                    Current Plan Limit:
                                    <div>{currentAvailablePages || "-"}</div>
                                </div>
                            </div>
                            <div className={styles.buyMoreBottomRow}>
                                <Button
                                    text={"Cancel"}
                                    width={"max-content"}
                                    handler={() => {
                                        mixpanel.track("Buy More Pages Modal", {
                                            Screen: "Link Manager",
                                            "CTA Type": "Close Modal",
                                        });

                                        lMBuyPages({
                                            screen: screen,
                                            ctaUsed: "Cancel",
                                        });
                                        setBuyPagesModal({
                                            isShow: false,
                                            domainId: null,
                                        });
                                    }}
                                    style={{
                                        fontWeight: 600,
                                        borderRadius: "20px",
                                        padding: "4px 20px",
                                        color: "#09060D",
                                        background: "white",
                                    }}
                                    disabled={loading}
                                />
                                <Button
                                    text={"Buy Now"}
                                    width={"100px"}
                                    style={{
                                        fontWeight: 600,
                                        borderRadius: "20px",
                                        padding: "4px 20px",
                                    }}
                                    handler={(e) => {
                                        e.preventDefault();
                                        lMBuyPages({
                                            screen: screen,
                                            ctaUsed:
                                                pagesCount > BUNDLE_SIZE * 22
                                                    ? "Open Chat"
                                                    : "Buy Now",
                                        });
                                        pagesCount > BUNDLE_SIZE * 22
                                            ? window.userengage &&
                                              window.userengage("widget.open")
                                            : handlerBuyPages(
                                                  pagesCount,
                                                  bundleQuantity,
                                              );
                                    }}
                                    loading={loading}
                                />
                            </div>
                        </>
                    ) : (
                        <CircularProgress
                            style={{ color: "var(--primary-color1)" }}
                        />
                    )}
                </div>
            </Modal>
            {isOfflinePaymentWarning && (
                <OfflinePaymentModal
                    // @ts-ignore
                    setModal={setIsOfflinePaymentWarning}
                />
            )}
        </>
    );
};

export default BuyMore;
