import React, { useState } from "react";
import EnterpriseForm from "./EnterpriseForm";
import CdrForm from "./CdrForm";
import WalletRecharge from "./WalletRecharge";
import styles from "./superAdmin.module.css";
import WorkLog from "./WorkLog";
import LtdWorks from "./LtdWorks";
import UserUsage from "./UserUsage";
import FrontendLogs from "./FrontendLogs";
import SurveyForm from "./SurveyForm";
import PromptDetails from "./PromptDetails";
import UserPlanUpdate from "./UserPlanUpdate";
import VerifyUserEmail from "./VerifyUserEmail";
import DisposableEmails from "./DisposableEmails";
import UpdateUserStatus from "./UpdateUserStatus";
import BFDealsUpdates from "./BFDealsUpdates";
import PlatformUpdates from "./PlatformVersionSetter";
import DeleteUser from "./DeleteUser";
import PremiumCruise from "./PemiumCruise";

const actions = [
    // {
    //   title: 'Activate Enterprise Plan',
    //   component: EnterpriseForm,
    // },
    {
        title: "Wallet Recharge",
        component: WalletRecharge,
    },
    {
        title: "Update CDR",
        component: CdrForm,
    },
    {
        title: "View Work Log",
        component: WorkLog,
    },
    {
        title: "LTD Users",
        component: LtdWorks,
    },
    {
        title: "User Usage",
        component: UserUsage,
    },
    {
        title: "Frontend logs",
        component: FrontendLogs,
    },
    {
        title: "Create Survey Form",
        component: SurveyForm,
    },
    {
        title: "Update Prompt Details",
        component: PromptDetails,
    },
    {
        title: "Update User Plan",
        component: UserPlanUpdate,
    },
    {
        title: "Verify User Email",
        component: VerifyUserEmail,
    },
    {
        title: "Disposable Emails",
        component: DisposableEmails,
    },
    {
        title: "Update User Status",
        component: UpdateUserStatus,
    },
    {
        title: "Update BF Deals",
        component: BFDealsUpdates,
    },
    {
        title: "Platform Update",
        component: PlatformUpdates,
    },
    {
        title: "Delete User",
        component: DeleteUser,
    },
    {
        title: "Premium Cruise Update",
        component: PremiumCruise,
    },
];
export default function ChooseAction() {
    const [activeAction, setActiveAction] = useState(null);

    return (
        <div>
            {activeAction ? (
                <activeAction.component setActiveAction={setActiveAction} />
            ) : (
                <div className={styles.container}>
                    {actions.map((action) => (
                        <div
                            key={action.title}
                            className={styles.box}
                            onClick={() => setActiveAction(action)}
                        >
                            {action.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
