import React, { useContext, useEffect, useState } from "react";
import styles from "./competitors.module.css";
import { GoBook } from "react-icons/go";
import HighLighter from "../../../atoms/HightLighter/HighLighter";
import CompetetionModal from "../../../molecules/Creator/Overview/CompetetionModal";

import { v4 as uuidv4 } from "uuid";
import SVGIcon from "../../../atoms/SVGIcon";

import { EditorBriefContext } from "../../Creator/EditorBrief/EditorBriefContext";
import { BRIEF_TYPES } from "../../Creator/EditorBrief/useEditorBriefHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { Checkbox, Switch, styled } from "@material-ui/core";
import { displayAlert } from "../../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import ReportContext from "../ReportContext";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { RxCrossCircled } from "react-icons/rx";
import { IoCheckmarkCircleSharp, IoCloseCircle } from "react-icons/io5";
import useHelperFunctions from "../../../helperFunctions";
import { Skeleton } from "@material-ui/lab";
import { FaSquareCheck } from "react-icons/fa6";
import { isValidUrl } from "../../../../utility/helperFun";
import { RiDeleteBin6Line } from "react-icons/ri";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        color: "#fff",
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));
const DataRow = ({
    list,
    setList,
    setSelectedRow,
    selectedRow,
    cruiseMode,
    organicCompList = [],
    setOrganicCompList,
    setCompetitorsRank,
    auditMode,
}) => {
    const dispatch = useDispatch();
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);
    const [data, setData] = useState({});
    const [previewRowId, setPreviewRowId] = useState();
    const [showCompetetionModal, setshowCompetetionModal] = useState(false);
    const { setSuccessMsg, dispatchEditorBrief } =
        useContext(EditorBriefContext);
    const { reportInfo, setReportData } = useContext(ReportContext);
    const [scraping, setScraping] = useState(false);
    const [inputUrl, setInputUrl] = useState("");

    const { mixpanelTrack } = useMixpanelHook();
    const { postData } = useHelperFunctions();

    const maxUrls = reportInfo.is_premium ? 5 : 3;

    const deleteCustomUrl = async (rank) => {
        const res = await postData({
            url: "/api/scrape-custom/delete-competitor",
            payload: { rank: rank, report_id: reportInfo.report_id },
        });
        if (res.status == 200) {
            const deletedUrl = list.filter((ele) => ele.rank == rank);
            mixpanelTrack("Custom URL Deleted", { url: deletedUrl[0]?.url });
            setList && setList(list.filter((ele) => ele.rank != rank));
            setOrganicCompList(organicCompList.filter((ele) => ele != rank));
            setCompetitorsRank(organicCompList.filter((ele) => ele != rank));
        }
    };

    const addCustomUrl = async () => {
        setScraping(true);
        const res = await postData({
            url: "/api/scrape-custom/post-url",
            payload: {
                url:
                    inputUrl.indexOf("https://") == 0 ||
                    inputUrl.indexOf("http://") == 0
                        ? inputUrl
                        : inputUrl.length > 0
                        ? `https://${inputUrl}`
                        : "",
                report_id: reportInfo.report_id,
            },
        });
        setScraping(false);
        if (res.status == 200) {
            mixpanelTrack("Custom URL Added", { url: inputUrl });
            setInputUrl("");
            let newList = list;
            let responseArr = res.data?.data;
            responseArr?.map((val) => (val.grade = val.optimiser_score));
            newList.push(responseArr[0]);
            setList && setList(newList.filter((ele) => ele.rank != "custom"));
            if (organicCompList.length < maxUrls) {
                setOrganicCompList((ps) => [...ps, responseArr[0]?.rank]);
                setCompetitorsRank((ps) => [...ps, responseArr[0]?.rank]);
            }
        }
    };

    console.log(list);
    return (
        <>
            {showCompetetionModal && Object.keys(data).length && (
                <CompetetionModal
                    toggleModal={setshowCompetetionModal}
                    data={data}
                />
            )}
            {list &&
                list?.map((data, i) => {
                    return (
                        <>
                            <tr
                                key={data.url_id}
                                className={`${styles.dataRow}`}
                                onClick={() => {
                                    if (data.rank != "custom") {
                                        setList &&
                                            setList((ps) =>
                                                ps?.filter(
                                                    (ele) =>
                                                        ele?.rank != "custom",
                                                ),
                                            );
                                    }
                                    setSelectedRow(data);
                                }}
                            >
                                <td>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: cruiseMode
                                                ? "flex-start"
                                                : "center",
                                            // gap: 10,
                                            // marginLeft: cruiseMode ? 10 : 0,
                                        }}
                                    >
                                        {" "}
                                        {cruiseMode &&
                                            data.rank != "custom" && (
                                                <Checkbox
                                                    checked={organicCompList.includes(
                                                        data.rank,
                                                    )}
                                                    onChange={(e) => {
                                                        if (scraping) {
                                                            return;
                                                        }
                                                        if (
                                                            organicCompList.length >=
                                                            maxUrls
                                                        ) {
                                                            if (
                                                                organicCompList.includes(
                                                                    data.rank,
                                                                )
                                                            ) {
                                                                mixpanelTrack(
                                                                    "Reference Article",
                                                                    {
                                                                        action: e
                                                                            .target
                                                                            .checked
                                                                            ? "Check"
                                                                            : "Uncheck",
                                                                        competitor:
                                                                            {
                                                                                word_count:
                                                                                    data.word_count,
                                                                                seo_score:
                                                                                    data.grade,
                                                                            },
                                                                    },
                                                                );
                                                                setOrganicCompList(
                                                                    (ps) =>
                                                                        ps.filter(
                                                                            (
                                                                                i,
                                                                            ) =>
                                                                                i !=
                                                                                data.rank,
                                                                        ),
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    displayAlert(
                                                                        {
                                                                            alertMessage:
                                                                                reportInfo.is_premium
                                                                                    ? "You can select up to 5 URLs"
                                                                                    : "You can select up to 3 URLs",
                                                                            alertType:
                                                                                "error",
                                                                        },
                                                                    ),
                                                                );
                                                                return;
                                                            }
                                                        } else {
                                                            mixpanelTrack(
                                                                "Reference Article",
                                                                {
                                                                    action: e
                                                                        .target
                                                                        .checked
                                                                        ? "Check"
                                                                        : "Uncheck",
                                                                    competitor:
                                                                        {
                                                                            word_count:
                                                                                data.word_count,
                                                                            seo_score:
                                                                                data.grade,
                                                                        },
                                                                },
                                                            );
                                                            if (
                                                                organicCompList.includes(
                                                                    data.rank,
                                                                ) &&
                                                                organicCompList.length >
                                                                    1
                                                            ) {
                                                                setOrganicCompList(
                                                                    (ps) =>
                                                                        ps.filter(
                                                                            (
                                                                                i,
                                                                            ) =>
                                                                                i !=
                                                                                data.rank,
                                                                        ),
                                                                );
                                                            }
                                                            e.target.checked
                                                                ? setOrganicCompList(
                                                                      (ps) => [
                                                                          ...ps,
                                                                          data.rank,
                                                                      ],
                                                                  )
                                                                : {};
                                                        }
                                                    }}
                                                    size="small"
                                                />
                                            )}{" "}
                                        {data.rank == "custom" ? (
                                            <span
                                                style={{ marginLeft: "10px" }}
                                            >
                                                URL
                                            </span>
                                        ) : Number(data.rank) < 0 ? (
                                            <LightTooltip
                                                arrow
                                                title={"Manually added URL"}
                                            >
                                                <span>-</span>
                                            </LightTooltip>
                                        ) : (
                                            <span>{data.rank}</span>
                                        )}
                                    </div>
                                </td>
                                <td className={styles.mainDataCol}>
                                    {data.rank == "custom" ? (
                                        <div
                                            style={{
                                                marginLeft: "16px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                width: "100%",
                                            }}
                                        >
                                            {scraping ? (
                                                <Skeleton
                                                    animation="wave"
                                                    width={"80%"}
                                                    height={32}
                                                />
                                            ) : (
                                                <Textfield
                                                    containerStyle={{
                                                        // display: "flex",
                                                        // justifyContent:
                                                        //     "flex-start",
                                                        // alignItems: "center",
                                                        margin: "0px",
                                                        width: "80%",
                                                    }}
                                                    value={inputUrl}
                                                    onChange={(e) => {
                                                        setInputUrl(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    errMsg={
                                                        inputUrl.length > 0 &&
                                                        !isValidUrl(inputUrl)
                                                            ? "Invalid URL"
                                                            : inputUrl.indexOf(
                                                                  "docs.google.com",
                                                              ) != -1
                                                            ? "We do not support Google Docs yet"
                                                            : ""
                                                    }
                                                    placeholder="Enter URL"
                                                    height={32}
                                                    label=""
                                                    props={{
                                                        style: {
                                                            color: "#014dc5",
                                                            width: "100%",
                                                        },
                                                    }}
                                                    customClass={
                                                        styles.urlContainer
                                                    }
                                                />
                                            )}
                                            {/* <IoCloseCircle
                                                style={{ marginInline: "10px" }}
                                                size={28}
                                                color="rgba(255,99,85,.9215686274509803)"
                                            /> */}
                                            {!scraping && (
                                                <FaSquareCheck
                                                    style={{
                                                        marginInline: "10px",
                                                    }}
                                                    size={32}
                                                    color="#014dc5"
                                                    onClick={() => {
                                                        if (
                                                            inputUrl?.length > 0
                                                        ) {
                                                            if (
                                                                !isValidUrl(
                                                                    inputUrl,
                                                                )
                                                            ) {
                                                                return;
                                                            } else {
                                                                addCustomUrl();
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className={styles.mainDataInfo}>
                                            <LightTooltip
                                                title={data?.url_title}
                                                arrow
                                            >
                                                <p
                                                    className={
                                                        styles.mainDataTitle
                                                    }
                                                >
                                                    {data?.url_title?.length >
                                                    60
                                                        ? data?.url_title?.slice(
                                                              0,
                                                              60,
                                                          ) + "..."
                                                        : data?.url_title}
                                                </p>
                                            </LightTooltip>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    marginTop: 1,
                                                }}
                                            >
                                                <a
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                    className={
                                                        styles.mainDataUrl
                                                    }
                                                    style={{ marginRight: 0 }}
                                                    href={data.url}
                                                    target="_blank"
                                                >
                                                    {!cruiseMode && (
                                                        <div
                                                            className={
                                                                styles.mainDataImg
                                                            }
                                                        >
                                                            {data.favicon ? (
                                                                <img
                                                                    src={
                                                                        data.favicon
                                                                    }
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <SVGIcon src="/New UI/SVG/globe.svg" />
                                                            )}
                                                        </div>
                                                    )}
                                                    {new URL(data.url)?.origin +
                                                        "/..."}
                                                </a>
                                                <span
                                                    className={styles.actions}
                                                >
                                                    {data.rank > 0 && (
                                                        <a
                                                            onClick={(e) => {
                                                                setshowCompetetionModal(
                                                                    true,
                                                                );
                                                                setData(data);
                                                                e.preventDefault();
                                                            }}
                                                            className={
                                                                styles.mainDataUrl
                                                            }
                                                        >
                                                            {"View"}
                                                        </a>
                                                    )}
                                                    {!!!cruiseMode &&
                                                        !auditMode && (
                                                            <a
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    dispatchEditorBrief(
                                                                        {
                                                                            type: BRIEF_TYPES.ADD_REFERENCE,
                                                                            data: {
                                                                                id: uuidv4(),
                                                                                data: data.url,
                                                                                subData:
                                                                                    "",
                                                                            },
                                                                        },
                                                                    );
                                                                    setSuccessMsg(
                                                                        {
                                                                            show: true,
                                                                            msg: data.url,
                                                                        },
                                                                    );
                                                                }}
                                                                className={
                                                                    styles.mainDataUrl
                                                                }
                                                                style={{
                                                                    marginRight: 0,
                                                                }}
                                                            >
                                                                {
                                                                    "Insert as a Reference ->"
                                                                }
                                                            </a>
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {/* {previewRowId === i && previewRow &&

									<span span className={styles.previewRow}><GoBook /> Preview</span>
								} */}
                                </td>

                                <td>
                                    {data.rank == "custom" ? (
                                        scraping ? (
                                            <Skeleton
                                                width={"60px"}
                                                height={32}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        data.word_count
                                    )}
                                </td>
                                <td>
                                    {data.rank == "custom" ? (
                                        scraping ? (
                                            <Skeleton
                                                width={"60px"}
                                                height={32}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <HighLighter
                                            style={{ margin: "auto" }}
                                            quality={data.grade}
                                        />
                                    )}
                                </td>
                                <td>
                                    {data.rank == "custom" ? (
                                        scraping ? (
                                            <Skeleton
                                                width={"60px"}
                                                height={32}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        data.htags_count
                                    )}
                                </td>
                                <td>
                                    {data.rank == "custom" ? (
                                        scraping ? (
                                            <Skeleton
                                                width={"60px"}
                                                height={32}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        data.no_of_images
                                    )}
                                </td>
                                {cruiseMode && (
                                    <td>
                                        {data.rank < 0 && (
                                            <div
                                                onClick={() => {
                                                    if (scraping) {
                                                        return;
                                                    }
                                                    if (
                                                        organicCompList.length ==
                                                            1 &&
                                                        organicCompList[0] ==
                                                            data.rank
                                                    ) {
                                                        dispatch(
                                                            displayAlert({
                                                                alertMessage:
                                                                    "At least one URL is required",
                                                                alertType:
                                                                    "error",
                                                            }),
                                                        );
                                                        return;
                                                    }
                                                    deleteCustomUrl(data.rank);
                                                }}
                                                className={styles.deleteIcon}
                                            >
                                                <RiDeleteBin6Line color="red" />
                                            </div>
                                        )}
                                    </td>
                                )}
                            </tr>
                        </>
                    );
                })}
        </>
    );
};

export default DataRow;
