import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { GoInfo } from "react-icons/go";
import { PieChart } from "react-minimal-pie-chart";
import { BASE_URL, endPoints } from "./constants";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import { INTERLINKING_DOMAIN_STATUS, WEBTUNE_DOMAIN_STATUS } from "./constants";
import { useSelector } from "react-redux";
import ManageTooltip from "./ManageTooltip";
import useHelperFunctions from "../../helperFunctions";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { Skeleton } from "@material-ui/lab";
import SVGIcon from "../../atoms/SVGIcon";
import { VscChevronRight } from "react-icons/vsc";
import mixpanel from "mixpanel-browser";
import HistoryWindow from "./HistoryWindow";
import Modal from "../../atoms/Modal/Modal";
import { ClickAwayListener } from "@material-ui/core";
import { PLANS_CATEGORIES } from "../../../constants";

type MetricsType = {
    id: number;
    domain_id: number;
    domain_strength: number;
    pages_crawled: number;
    suggestions_count: number;
    orphan_pages_count: number;
    scraping_version: number;
    action_required_pages: number;
    potential_opportunity_pages: number;
    all_good_pages: number;
    created_at: Date;
    updated_at: Date;
};

const stopColors = [
    { offset: 0, stopColor: "red" },
    { offset: 25, stopColor: "#ffb961" },
    { offset: 35, stopColor: "#ffb961" },
    { offset: 100, stopColor: "#4CAF50" },
];

function Metrics({ historyModal, setHistoryModal }) {
    const [selected, setSelected] = useState<number>();
    const [hovered, setHovered] = useState<number>();
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const [buyPagesModal, setBuyPagesModal] = useState(false);
    const [upgradeModal, setUpgradeModal] = useState({ isShow: false });
    const [splitTooltipShow, setSplitTooltipShow] = useState(false);
    const otherInfo = useSelector((state: any) => state.otherInfo);
    const TooltipInfo = ({
        text = "",
        width = null,
    }: {
        text: string | Element;
        width?: null | number | string;
    }) => {
        return (
            <LightTooltip
                // @ts-ignore
                title={text}
                placement={"top"}
                // @ts-ignore
                tooltipStyle={{
                    ...(width && { maxWidth: width }),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <SVGIcon
                        src={"/New UI/SVG/info.svg"}
                        size={14}
                        style={{ color: "#ACB3BF" }}
                    />
                </div>
            </LightTooltip>
        );
    };
    const { isFreeForever } = useUserAccessHook();
    // const isPremium = otherInfo?.subscriptionInfo?.plan_id != FREE_PLAN_ID;
    const isPremium = false;
    const { plan_category, isLTD, trialUser } = useUserAccessHook();

    const showManageCta =
        ([
            PLANS_CATEGORIES.PRO,
            PLANS_CATEGORIES.PRO_MAX,
            PLANS_CATEGORIES.GROWTH_MAX,
            PLANS_CATEGORIES.GROWTH,
            PLANS_CATEGORIES.EXPERT,
        ].includes(plan_category) ||
            trialUser) &&
        !isLTD &&
        !otherInfo.subscriptionInfo?.terminate;
    const metrics = useMetrics(
        selectedDomainInfo?.id,
        selectedDomainInfo?.interlinking_status,
        selectedDomainInfo?.status,
    );

    const insights = [
        {
            //@ts-ignore
            icon: <SVGIcon size={36} src={"/New UI/SVG/metricsLens.svg"} />,
            title: (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 4,
                    }}
                >
                    Pages Crawled{" "}
                    <TooltipInfo
                        width={"500px"}
                        // @ts-ignore
                        text={
                            <>
                                Number of pages crawled for Internal Linking
                                Opportunities:{" "}
                                <strong>
                                    {
                                        selectedDomainInfo?.stable_version_total_pages
                                    }
                                </strong>
                                <br />
                                Total pages:{" "}
                                <strong>
                                    {
                                        selectedDomainInfo?.stable_version_total_crawled_pages
                                    }
                                </strong>
                            </>
                        }
                    />
                </div>
            ),
            stats: metrics ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    <span>
                        <strong>
                            {selectedDomainInfo?.stable_version_total_pages}
                        </strong>{" "}
                        <small style={{ color: "rgba(172, 179, 191, 1)" }}>
                            /{" "}
                            {
                                selectedDomainInfo?.stable_version_total_crawled_pages
                            }
                        </small>
                        &nbsp;&nbsp;
                    </span>
                    {/* {!otherInfo.subscriptionInfo?.terminate && */}
                    {showManageCta &&
                        selectedDomainInfo &&
                        selectedDomainInfo?.stable_version_total_crawled_pages >
                            selectedDomainInfo?.stable_version_total_pages && (
                            <ManageTooltip
                                screen={"Link Manager"}
                                domainId={
                                    selectedDomainInfo && selectedDomainInfo?.id
                                }
                            />
                        )}
                </div>
            ) : (
                <Skeleton width={80} />
            ),
        },
        {
            //@ts-ignore
            icon: <SVGIcon size={36} src={"/New UI/SVG/metricsAtoB.svg"} />,
            title: (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 4,
                    }}
                >
                    New Linking Opportunities
                    <TooltipInfo
                        text={"Number of unique internal linking opportunities"}
                    />
                </div>
            ),
            stats: metrics ? (
                selectedDomainInfo?.interlinking_status ===
                INTERLINKING_DOMAIN_STATUS.IN_PROGRESS ? (
                    <Skeleton width={80} />
                ) : (
                    <strong>
                        {metrics?.suggestions_count?.toLocaleString() || "-"}
                    </strong>
                )
            ) : (
                <Skeleton width={80} />
            ),
        },
        // {
        //     //@ts-ignore
        //     icon: <SVGIcon size={28} src={"/New UI/SVG/metricsFix.svg"} />,
        //     title: (
        //         <div
        //             style={{
        //                 display: "flex",
        //                 justifyContent: "flex-start",
        //                 alignItems: "center",
        //                 gap: 4,
        //             }}
        //         >
        //             Orphan Pages Detected{" "}
        //             <TooltipInfo text={"Orphan Pages Detected"} />
        //         </div>
        //     ),
        //     stats: metrics ? (
        //         <span>
        //             <strong>{metrics.orphan_pages_count}</strong>
        //         </span>
        //     ) : (
        //         <Skeleton width={80} />
        //     ),
        // },
    ];

    const pieChartData = metrics
        ? [
              {
                  title: "All Good",
                  value: metrics?.all_good_pages || 0,
                  color: "rgba(62, 237, 193, 1)",
              },
              {
                  title: "Opportunity",
                  value: metrics?.potential_opportunity_pages || 0,
                  color: "rgba(244, 218, 114, 1)",
              },
              {
                  title: "Action required",
                  value: metrics?.action_required_pages || 0,
                  color: "rgba(248, 128, 128, 1)",
              },
          ]
        : [
              {
                  title: "Processing",
                  value: 1,
                  color: "rgba(244, 244, 244, 1)",
              },
          ];
    const data = pieChartData.map((entry, i) => {
        if (hovered === i) {
            return {
                ...entry,
                color: "grey",
            };
        }
        return entry;
    });
    return (
        <div
            className={`${styles.section} `}
            style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                position: "relative",
            }}
        >
            <div
                style={{ padding: "0px 30px", width: "100%" }}
                className={` ${styles.metrics}`}
            >
                <>
                    <div className={styles.metricsStrengthContainer}>
                        <div
                            style={{
                                width: 150,
                                height: 100,
                            }}
                        >
                            <PieChart
                                data={[{ value: 10, color: "url(#gradient1)" }]}
                                startAngle={-180}
                                lengthAngle={180}
                                lineWidth={15}
                                viewBoxSize={[100, 50]}
                            >
                                <defs>
                                    <linearGradient id="gradient1">
                                        {metrics ? (
                                            stopColors.map((stopColor) =>
                                                metrics.domain_strength >=
                                                stopColor.offset ? (
                                                    <stop
                                                        offset={`${stopColor.offset}%`}
                                                        stopColor={
                                                            stopColor.stopColor
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <stop
                                                            offset={`${metrics.domain_strength}%`}
                                                            stopColor={
                                                                stopColor.stopColor
                                                            }
                                                        />
                                                        <stop
                                                            offset={`${metrics.domain_strength}%`}
                                                            stopColor="rgba(244, 244, 244, 1)"
                                                        />
                                                    </>
                                                ),
                                            )
                                        ) : (
                                            <stop
                                                offset={`${100}%`}
                                                stopColor="rgba(244, 244, 244, 1)"
                                            />
                                        )}
                                    </linearGradient>
                                </defs>
                            </PieChart>
                        </div>

                        <div className={styles.metricsStrengthInfo}>
                            <div className={styles.metricsStrength}>
                                {metrics ? (
                                    <>
                                        <big
                                            style={{
                                                fontSize: 24,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {metrics.domain_strength}
                                        </big>

                                        <small> / 100</small>
                                    </>
                                ) : (
                                    <Skeleton width={80} />
                                )}{" "}
                            </div>

                            <div
                                className={styles.insightTitle}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 5,
                                    marginTop: 6,
                                }}
                            >
                                Domain Strength
                                {selectedDomainInfo &&
                                    selectedDomainInfo?.stable_version_total_crawled_pages >
                                        selectedDomainInfo?.stable_version_total_pages && (
                                        <span style={{ color: "#FC5A5A" }}>
                                            *
                                        </span>
                                    )}
                                <TooltipInfo
                                    text={
                                        "Evaluate your site's internal linking strategy with Domain Strength. Improve it by linking pages marked as Action Required and Opportunities. A higher score indicates better internal linking."
                                    }
                                />
                            </div>
                            {/* <div
                                style={{
                                    color: "rgba(113, 136, 170, 1)",
                                    fontSize: 12,
                                }}
                            >
                                {metrics ? (
                                    <>
                                        Score without fixed:
                                        {" " + metrics?.domain_strength_fixed ||
                                            "-"}
                                    </>
                                ) : (
                                    <Skeleton width={100} />
                                )}
                            </div> */}
                        </div>
                    </div>

                    <div className={styles.divider}></div>
                    {insights.map((insight, i) => (
                        <>
                            <div className={styles.insight}>
                                <div className={styles.insightIcon}>
                                    {insight.icon}
                                </div>
                                <div className={styles.insightInfo}>
                                    <div className={styles.insightTitle}>
                                        {insight.title}
                                    </div>

                                    {insight.stats}
                                </div>
                            </div>

                            {insights.length - 1 !== i && (
                                <div
                                    className={styles.divider}
                                    style={{ height: 50 }}
                                ></div>
                            )}
                        </>
                    ))}

                    <div className={styles.divider}></div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 10,
                        }}
                    >
                        <div
                            style={{
                                width: 140,
                                transform: "scale(1)",
                            }}
                            onMouseEnter={() => {
                                setSplitTooltipShow(true);
                            }}
                            onMouseLeave={() => {
                                setSplitTooltipShow(false);
                            }}
                        >
                            <PieChart
                                data={data}
                                radius={35}
                                segmentsStyle={{
                                    transition: "stroke .3s",
                                    cursor: "pointer",
                                }}
                                segmentsShift={(index: number) => {
                                    return metrics
                                        ? [
                                              metrics.all_good_pages,
                                              metrics.action_required_pages,
                                              metrics.potential_opportunity_pages,
                                          ].filter((i) => i === 0).length == 2
                                            ? 0
                                            : index === selected
                                            ? 4
                                            : 1
                                        : 0;
                                }}
                                // onClick={(event, index) => {

                                // }}
                                onMouseOver={(_: any, index: any) => {
                                    // setSelected(index);
                                }}
                                onMouseOut={() => {
                                    setSelected(undefined);
                                }}
                            />

                            {/* {metrics && (
                            <LightTooltip
                                // @ts-ignore
                                tooltipStyle={{
                                    width: "200px",
                                    display: "flex",
                                }}
                                arrowStyle={{
                                    marginTop: "-24px !important",
                                    width: "3em",
                                    height: "2em",
                                    "&::before": {
                                        border: "1px solid #cacaca !important",
                                        color: "white !important",
                                    },
                                }}
                                interactive
                                open={splitTooltipShow}
                                title={
                                    <ClickAwayListener
                                        onClickAway={() =>
                                            setSplitTooltipShow(false)
                                        }
                                    >
                                        <div
                                            className={styles.pieChart_Legends}
                                            onMouseEnter={() => {
                                                setSplitTooltipShow(true);
                                            }}
                                            onMouseLeave={() => {
                                                setSplitTooltipShow(false);
                                            }}
                                        >
                                            <div>
                                                <span
                                                    className={styles.indicator}
                                                    style={{
                                                        background:
                                                            pieChartData[0]
                                                                .color,
                                                    }}
                                                ></span>
                                                <span
                                                    style={{
                                                        flexGrow: 1,
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    All Good
                                                </span>
                                                <span>
                                                    {pieChartData[0].value}
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={styles.indicator}
                                                    style={{
                                                        background:
                                                            pieChartData[1]
                                                                .color,
                                                    }}
                                                ></span>
                                                <span
                                                    style={{
                                                        flexGrow: 1,
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    Potential Opportunity
                                                </span>
                                                <span>
                                                    {pieChartData[1].value}
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={styles.indicator}
                                                    style={{
                                                        background:
                                                            pieChartData[2]
                                                                .color,
                                                    }}
                                                ></span>
                                                <span style={{ flexGrow: 1 }}>
                                                    Action required
                                                </span>
                                                <span>
                                                    {pieChartData[2].value}
                                                </span>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                }
                                arrow
                                placement={"bottom"}
                            >
                                <div
                                    onMouseEnter={() => {
                                        setSplitTooltipShow(true);
                                    }}
                                    onMouseLeave={() => {
                                        setSplitTooltipShow(false);
                                    }}
                                    style={{
                                        color: "#3F5575",
                                        fontSize: 13,
                                        fontWeight: 600,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 5,
                                        marginTop: "-15px",
                                        marginRight: "-15px",
                                    }}
                                >
                                    Link Split{" "}
                                    <SVGIcon
                                        src={"/New UI/SVG/info.svg"}
                                        size={14}
                                        style={{ color: "#ACB3BF" }}
                                    />
                                </div>
                            </LightTooltip>
                        )} */}
                        </div>
                        {metrics ? (
                            <div
                                className={styles.pieChart_Legends}
                                style={{ marginTop: "-10px" }}
                                onMouseEnter={() => {
                                    setSplitTooltipShow(true);
                                }}
                                onMouseLeave={() => {
                                    setSplitTooltipShow(false);
                                }}
                            >
                                <div>
                                    Link Status Split{" "}
                                    {selectedDomainInfo &&
                                        selectedDomainInfo?.stable_version_total_crawled_pages >
                                            selectedDomainInfo?.stable_version_total_pages && (
                                            <span style={{ color: "#FC5A5A" }}>
                                                *{" "}
                                            </span>
                                        )}
                                    <TooltipInfo
                                        // @ts-ignore
                                        text={
                                            <>
                                                Category wise count of link
                                                status{" "}
                                                <div
                                                    className={styles.lssPoint}
                                                >
                                                    <div>-</div>{" "}
                                                    <div>
                                                        All Good: Internal links
                                                        are in range.
                                                    </div>
                                                </div>
                                                <div
                                                    className={styles.lssPoint}
                                                >
                                                    <div>-</div>
                                                    <div>
                                                        Opportunity: Internal
                                                        links are not in range.
                                                    </div>
                                                </div>
                                                <div
                                                    className={styles.lssPoint}
                                                >
                                                    {" "}
                                                    <div>-</div>{" "}
                                                    <div>
                                                        Action Required: Inbound
                                                        or Outbound links are 0.
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            flexGrow: 1,
                                            paddingRight: "10px",
                                            color: pieChartData[0]?.color,
                                        }}
                                    >
                                        All Good
                                    </span>
                                    <span>{pieChartData[0]?.value}</span>
                                </div>
                                <div>
                                    <span
                                        style={{
                                            flexGrow: 1,
                                            paddingRight: "10px",
                                            color: pieChartData[1]?.color,
                                        }}
                                    >
                                        Opportunity
                                    </span>
                                    <span>{pieChartData[1]?.value}</span>
                                </div>
                                <div>
                                    <span
                                        style={{
                                            flexGrow: 1,
                                            color: pieChartData[2]?.color,
                                        }}
                                    >
                                        Action required
                                    </span>
                                    <span>{pieChartData[2]?.value}</span>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Skeleton width={"100px"} />
                                <Skeleton width={"100px"} />
                                <Skeleton width={"100px"} />
                            </div>
                        )}
                    </div>
                    <LightTooltip
                        title={
                            selectedDomainInfo?.is_verified !== 1
                                ? "Please verify your domain before viewing past fixes"
                                : ""
                        }
                        arrow
                    >
                        <div
                            className={`${styles.viewPastFixes} ${
                                selectedDomainInfo?.is_verified !== 1 &&
                                styles.disabled
                            }`}
                            onClick={() => {
                                mixpanel.track("View Past Fixes", {
                                    Verified: selectedDomainInfo?.is_verified
                                        ? "Yes"
                                        : "No",
                                });
                                if (selectedDomainInfo?.is_verified) {
                                    setHistoryModal(true);
                                }
                            }}
                        >
                            View Past Fixes{" "}
                            <VscChevronRight
                                size={16}
                                style={{ color: "inherit" }}
                            />
                        </div>
                    </LightTooltip>
                </>
            </div>
            {historyModal && (
                <Modal
                    setModal={() => {
                        setHistoryModal(false);
                    }}
                >
                    <div className={styles.historyModalWrapper}>
                        <HistoryWindow
                            isModal={true}
                            closeModal={setHistoryModal}
                        />
                    </div>
                </Modal>
            )}
            {selectedDomainInfo &&
                selectedDomainInfo?.stable_version_total_crawled_pages >
                    selectedDomainInfo?.stable_version_total_pages && (
                    <div
                        style={{
                            color: "rgba(113, 136, 170, 1)",
                            fontSize: 12,
                            fontWeight: 500,
                            position: "absolute",
                            bottom: 20,
                        }}
                    >
                        <span style={{ color: "#FC5A5A" }}>* </span>
                        Note: The corresponding data highlighted may not be
                        entirely accurate, as not all pages within the domain
                        have been crawled.
                    </div>
                )}
        </div>
    );
}

function useMetrics(
    id: number | undefined,
    status: number | null | undefined,
    webtuneStatus: number | undefined,
) {
    const [metrics, setMetrics] = useState(null);
    const { postData } = useHelperFunctions();
    useEffect(() => {
        if (status === INTERLINKING_DOMAIN_STATUS.SUCCESS) {
            (async () => {
                const res = await postData({
                    completeUrl: BASE_URL + endPoints.interlinkMetrics,
                    payload: { domain_id: id },
                });

                if (res.status == 200) {
                    setMetrics(res.data.domainMetrics);
                }
            })();
        }
    }, [status]);

    return metrics as unknown as MetricsType;
}

function MetricsLoading() {
    return (
        <>
            <div
                style={{
                    width: 150,
                    height: 100,
                }}
            >
                <PieChart
                    data={[{ value: 10, color: "url(#gradient1)" }]}
                    startAngle={-180}
                    lengthAngle={180}
                    lineWidth={17}
                    viewBoxSize={[100, 50]}
                >
                    <defs>
                        <linearGradient id="gradient1">
                            <stop
                                offset={`100%`}
                                stopColor="rgba(230, 230, 230, 1)"
                            />
                        </linearGradient>
                    </defs>
                </PieChart>
            </div>

            <div className={styles.divider}></div>
            <Skeleton height={50} width={50} variant="circle"></Skeleton>
            <div className={styles.divider}></div>
            <Skeleton height={50} width={50} variant="circle"></Skeleton>
            <div className={styles.divider}></div>
            <Skeleton height={50} width={50} variant="circle"></Skeleton>
            <div className={styles.divider}></div>
            <Skeleton height={90} width={90} variant="circle"></Skeleton>
        </>
    );
}

export default Metrics;
