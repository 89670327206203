import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useContext,
} from "react";
import Button from "../../atoms/Button/Button";

import styles from "./styles.module.css";

import { Tooltip, withStyles } from "@material-ui/core";

import TextArea from "../../atoms/TextArea/TextArea";

import useHelperFunctions from "../../helperFunctions";
import Modal from "../../../componentsV2/atoms/Modal/Modal";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { PLANS_CATEGORIES } from "../../../constants";
import { displayAlert } from "../../../store/actions/alert";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import SVGIcon from "../../atoms/SVGIcon";
import ToggleButton from "../../atoms/CustomToggle/ToggleButtonModified";
import { SMART_EDITOR } from "./editorConstants";
import EditorContext from "./EditorContext";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import VideoModal from "../../Pages/NewDashboard/VideoModal";
import useMixpanelHook from "../../../utility/useMixpanelHook";

const getFrequency = (keyTerms) => {
    for (let e of keyTerms) {
        let freq =
            document.quill.editor
                .getText()
                .toLowerCase()
                .split(new RegExp(`\\b${e.keyword}\\b`, "g")).length - 1;
        e.curr_freq = freq;
    }

    console.log(keyTerms, "ARAYY OF TERMS");
};

const CustomSlider = ({
    min,
    max,
    value,
    step,
    setValue,
    showTooltip = false,
}) => {
    const ref = useRef();
    function getPercent(value) {
        try {
            return ((value - min) / (max - min)) * 100;
        } catch {
            return 33.33;
        }
    }
    // useEffect(() => {
    //   // localStorage.setItem("AIWordLimitSetting", value ? value : 2);
    //   localStorage.setItem(
    //     "AIWordLimitSetting",
    //     JSON.stringify({
    //       ...aiSettings,
    //       [keyToUpdate]: value,
    //     })
    //   );
    // }, [value, keyToUpdate]);
    const getPosForTip = (val, min, max) => {
        const pos = (val - min) / (max - min);
        let thumbCorrect = 13 * (pos - 0.5) * -1;
        let titlepos = Math.round(
            pos * ref?.current?.offsetWidth - 13 / 4 + thumbCorrect,
        );
        return isNaN(titlepos - 5) ? 0 : titlepos - 13;
    };
    return (
        <div style={{ position: "relative" }} ref={ref}>
            <input
                min={+min}
                max={+max}
                value={+value}
                step={step}
                onInput={(e) => {
                    setValue(+e.target.value);
                }}
                style={{
                    backgroundImage: `linear-gradient(90deg,#014dc5 ${getPercent(
                        value,
                    )}%,rgba(1,77,197,0.1) ${getPercent(value)}%)`,
                }}
                type="range"
                className={styles.slider}
            />
            {showTooltip && (
                <div
                    style={{
                        left: getPosForTip(value, min, max),
                    }}
                    className={styles.customSliderToolTip}
                >
                    {value}/10
                </div>
            )}
        </div>
    );
};
const NlpSettingItem = ({ item, index, setNlpSettingList, id }) => {
    const [sliderValue, setSliderValue] = useState(0);
    function checkboxChecked(item) {
        try {
            const bool =
                new RegExp(`\\b${item.keyword}\\b`, "g").test(
                    document.quill.editor.getText().toLowerCase(),
                ) ||
                new RegExp(`\\b${item.keyword_variations}\\b`, "g").test(
                    document.quill.editor.getText().toLowerCase(),
                );
            return bool;
        } catch {
            return false;
        }
    }

    return (
        <div className={styles.nlpSettingItem}>
            <div className={styles.nlpSettingItemLeft}>
                {/* <div className={styles.checkbox}>
          {" "}
          {checkboxChecked(item) ? (
            <FiCheckSquare color="#5779e6" />
          ) : (
            <div className={styles.emptyBoxNew} />
          )}
        </div> */}
                <div className={styles.nlpTermContainer}>
                    <div className={styles.nlpTerm}>{item.keyword}</div>
                    <div className={styles.usage}>
                        Typical Usage:{" "}
                        <span className={styles.maxminUsage}>
                            {item.min_times}-{item.max_times}
                        </span>
                    </div>
                    <div className={styles.usage}>
                        Current Usage:{" "}
                        <span className={styles.maxminUsage}>
                            {item.curr_freq || "-"}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.nlpSettingItemRight}>
                <CustomSlider
                    max={10}
                    min={0}
                    step={1}
                    setValue={(value) =>
                        setNlpSettingList((prev) => {
                            const item = prev.filter((i) => i.id == id);
                            item[0].user_importance = value;
                            return [...prev];
                        })
                    }
                    showTooltip={true}
                    value={item.user_importance || 0}
                />
            </div>
        </div>
    );
};
export const NlpSettingModal = ({
    setShowNlpSetModal,
    reportInfo,
    keyTerms,
    isCruise,
}) => {
    const [nlpSettingList, setNlpSettingList] = useState(() =>
        JSON.parse(JSON.stringify(keyTerms)),
    );

    const { postData } = useHelperFunctions();
    const [sortingMenu, setSortingMenu] = useState(false);
    const [sortBy, setSortBy] = useState("Importance");
    const dispatch = useDispatch();
    const sortingFunction = (item1, item2) => {
        item1.importance = item1.importance || 0;
        item2.importance = item2.importance || 0;
        if (sortBy == "Importance" && item1.importance == item2.importance) {
            return item2.heading_presence - item1.heading_presence == 0
                ? item1.keyword - item2.keyword
                : item2.heading_presence - item1.heading_presence;
        }

        if (
            sortBy == "Heading Presence" &&
            item1.heading_presence == item2.heading_presence
        ) {
            return item2.importance - item1.importance == 0
                ? item1.keyword - item2.keyword
                : item2.importance - item1.importance;
        }

        if (sortBy == "Importance") {
            return item1.importance < item2.importance ? 1 : -1;
        } else if (sortBy == "Unused") {
            return item1.used < item2.used ? -1 : 1;
        } else {
            return item1.heading_presence < item2.heading_presence ? 1 : -1;
        }
    };

    useEffect(() => {
        setNlpSettingList((prev) => [...prev.sort(sortingFunction)]);
        getFrequency(nlpSettingList);
    }, [sortBy]);

    const updateData = async () => {
        const importances = nlpSettingList.map((item) => {
            const obj = {};
            obj.id = item.id;
            obj.importance = item.user_importance || 0;
            return obj;
        });
        dispatch(
            displayAlert({
                alertMessage: "Updated Successfully",
                alertType: "",
                timeout: 2000,
            }),
        );
        setShowNlpSetModal(false);

        await postData({
            url: "/api/scrape/updateImportance",
            payload: {
                importances,
            },
        });

        keyTerms.map((item) => {
            item.user_importance = nlpSettingList.filter(
                (i) => i.id == item.id,
            )[0]?.user_importance;
        });
    };

    const doReset = async () => {
        await postData({
            url: "/api/scrape/updateImportance",
            payload: {
                doReset: true,
                report_id: reportInfo.report_id,
            },
        });

        keyTerms.map((item) => {
            item.user_importance = nlpSettingList.filter(
                (i) => i.id == item.id,
            )[0]?.importance;
        });

        setNlpSettingList(keyTerms);
        // setShowNlpSetModal(false);
    };
    return (
        <div
            className={isCruise ? styles.nlpSetModalCruise : styles.nlpSetModal}
        >
            <div className={styles.nlpSetModalheader}>
                Configure Key Terms Priority
                <div className={styles.nlpSetModalSortContainer}>
                    {/* <NewDropDown
            list={["Importance", "Unused", "Heading Presence"]}
            sortType={sortBy}
            setSortType={setSortBy}
            title="SORT:"
            state={sortingMenu}
          /> */}
                    <div className={styles.nlpSetModalRevert} onClick={doReset}>
                        Reset to Default
                    </div>
                </div>
                {<CloseButton setClose={setShowNlpSetModal} />}
            </div>
            <div className={styles.nlpSetLabelContainer}>
                <div className={styles.nlpSetModalLabel}>KEY TERMS</div>
                <div className={styles.nlpSetModalLabel}>PRIORITY</div>
            </div>
            <div className={styles.nlpSetModalItemsContainer}>
                {nlpSettingList.map((item, index) => (
                    <NlpSettingItem
                        item={item}
                        index={index}
                        setNlpSettingList={setNlpSettingList}
                        key={index + item.keyword}
                        id={item.id}
                    />
                ))}
            </div>
            <div className={styles.nlpSetModalBottomContainer}>
                <div className={styles.disclaimer}>
                    Disclaimer: Enabling Key terms preferences will simply raise
                    the possibility of these terms appearing in our AI-generated
                    outputs, not guarantee it.
                </div>
                <Button
                    text="Update"
                    style={{
                        backgroundColor: "#014dc5",
                        color: "#fff",
                        borderRadius: 3,
                    }}
                    width="150px"
                    handler={updateData}
                />
            </div>
        </div>
    );
};

export const NLPConfigure = ({
    aiWordLimitSetting = {},
    reportInfo = {},
    nlpToggle,
    setNlpToggle,
    isCruise,
    container,
    setSwitchConfigure = () => {},
}) => {
    const [showNlpSetModal, setShowNlpSetModal] = useState(false);
    const { postData } = useHelperFunctions();
    const updateAiSettings = async (on) => {
        if (!isCruise) return;
        await postData({
            url: "/api/creator/settings",
            payload: {
                report_id: reportInfo.report_id,

                nlp_toggle: on ? 1 : 0,
            },
        });
        reportInfo.setAiWordLimitSetting((ps) => ({
            ...ps,
            nlpToggle: on ? 1 : 0,
        }));
    };

    useEffect(() => {
        setSwitchConfigure(showNlpSetModal);
    }, [showNlpSetModal]);

    useEffect(() => {
        setNlpToggle(aiWordLimitSetting.nlpToggle);
    }, [aiWordLimitSetting]);

    return (
        <>
            <div
                style={
                    isCruise
                        ? {
                              justifyContent: "flex-start",
                              gap: 18,
                          }
                        : {}
                }
                className={styles.settingModalExtended}
            >
                <div className={styles.settingModalHeading}>
                    Prioritize Key Terms{" "}
                    {isCruise ? (
                        <SVGIcon
                            size={18}
                            tooltip={
                                "NLP-powered keywords & phrases associated with the search term. Prioritizing these terms will result in an increase in content grade"
                            }
                            src={"/New UI/SVG/info.svg"}
                            style={{
                                color: "#2196f3",
                                marginLeft: 2,
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>

                <div
                    className={
                        (isCruise && reportInfo.reportLoading
                            ? styles.disabled
                            : "") +
                        " " +
                        styles.settingExtendedToggle
                    }
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <ToggleButton
                            on={
                                isCruise && reportInfo.reportLoading
                                    ? true
                                    : nlpToggle
                            }
                            setOn={() => {
                                if (!reportInfo.reportLoading)
                                    setNlpToggle((on) => {
                                        if (isCruise) updateAiSettings(!on);
                                        return !on;
                                    });
                            }}
                        />
                    </div>

                    {
                        <div className={styles.settingExtendedToggle_info}>
                            {isCruise && reportInfo.reportLoading
                                ? "(Settings will be activated once the SEO Data is ready)"
                                : ""}
                        </div>
                    }
                </div>
            </div>
            {!isCruise && (
                <div
                    className={
                        styles.settingModalConfigure +
                        " " +
                        (nlpToggle ? styles.enablesettingModalConfigure : "")
                    }
                    style={{
                        marginRight: isCruise ? "auto" : "0",
                    }}
                    onClick={() => {
                        if (nlpToggle) {
                            setShowNlpSetModal(true);
                        }
                    }}
                >
                    Configure
                </div>
            )}

            {showNlpSetModal &&
                (isCruise ? (
                    container ? (
                        ReactDOM.createPortal(
                            <NlpSettingModal
                                keyTerms={reportInfo.relevantTerms}
                                setShowNlpSetModal={setShowNlpSetModal}
                                reportInfo={reportInfo}
                                isCruise={true}
                            />,
                            container,
                        )
                    ) : (
                        <></>
                    )
                ) : (
                    <Modal setModal={setShowNlpSetModal}>
                        <NlpSettingModal
                            keyTerms={reportInfo.relevantTerms}
                            setShowNlpSetModal={setShowNlpSetModal}
                            reportInfo={reportInfo}
                        />
                    </Modal>
                ))}
        </>
    );
};

function Configure({
    aiWordLimitSetting,
    setAiWordLimitSetting,
    reportInfo,
    keyTerms,
    AISettings,
    toggleAISettings,
    attachedTo,
}) {
    const [showSettingModal, setShowSettingModal] = useState(false);
    const { postData } = useHelperFunctions();
    const [nlpToggle, setNlpToggle] = useState(false);
    const ref = useRef(null);

    const [context, setContext] = useState("");
    const [tone, setTone] = useState(aiWordLimitSetting.toneOfVoice);
    const [keywords, setKeywords] = useState("");
    const otherInfo = useSelector((state) => state.otherInfo);
    const [outputLength, setOutputLength] = useState(3);
    const [creativity, setCreativity] = useState(aiWordLimitSetting.creativity);
    const [contextToggle, setContextToggle] = useState(
        aiWordLimitSetting.contextToggle,
    );
    const { mpTrackInfoIconClicked } = useMixpanelHook();

    const { docInfo } = useContext(EditorContext);
    const dispatch = useDispatch();
    const updateAiSettings = async () => {
        toggleAISettings(false);
        await postData({
            url: "/api/creator/settings",
            payload: {
                [attachedTo != SMART_EDITOR ? "report_id" : "doc_id"]:
                    docInfo.reportId,
                context: context,
                keywords: keywords,
                nlp_toggle: nlpToggle ? 1 : 0,
            },
        });
        setAiWordLimitSetting({
            outputLength,
            creativity,
            context,
            contextToggle,
            toneOfVoice: tone,
            keywords,
            nlpToggle,
        });
        setShowSettingModal(false);
    };

    useEffect(() => {
        setContextToggle(!aiWordLimitSetting.contextToggle);
        setContext(aiWordLimitSetting.context);
        setTone(aiWordLimitSetting.toneOfVoice);
        setKeywords(aiWordLimitSetting.keywords);
        setOutputLength(aiWordLimitSetting.outputLength);
        setCreativity(aiWordLimitSetting.creativity);
    }, [aiWordLimitSetting]);

    const openUpgradeDialog = () => {
        dispatch(
            displayAlert({
                alertMessage: "",
                alertType: "popup",
                errorData: {
                    status: "upgrade",
                },
            }),
        );
    };

    return (
        <>
            {
                <div
                    className={`${styles.configureContainer} ${
                        !AISettings ? styles.hideConfigureContainer : ""
                    } `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h3>
                        <div style={{ display: "flex" }}>
                            AI Configuration&nbsp;
                            <LightTooltip
                                title={
                                    <>
                                        Utilize the Scalenut AI settings tool to
                                        adjust your preferences and guide our
                                        powerful AI in crafting personalized
                                        content that caters to your specific
                                        needs.
                                        <br />
                                        <br />
                                        <strong>Example</strong> <br /> <br />
                                        You want content on why maintaining a
                                        healthy lifestyle is beneficial in the
                                        longer run. Add the context,keywords,
                                        output length, creativity and prioritise
                                        key terms. Once you are done filling in
                                        the prompts, click "Update".
                                        <br />
                                        <br />
                                        <VideoModal
                                            link={`https://www.youtube.com/embed/aJHCjdZyQLw?modestbranding=1&rel=0&loop=1`}
                                            zoomVal={1.2}
                                            modal={false}
                                            aspectWidth={380}
                                            aspectHeight={200}
                                            customClass={styles.videoInfo}
                                            location="AI Configuration"
                                        />
                                    </>
                                }
                                arrow
                                click
                                tooltipStyle={{
                                    maxWidth: 440,
                                    padding: "30px 30px 30px 30px !important",
                                }}
                            >
                                <div
                                    style={{ height: 19, marginTop: "3px" }}
                                    onClick={() => {
                                        mpTrackInfoIconClicked({
                                            location: "AI Configuration",
                                        });
                                    }}
                                >
                                    <SVGIcon
                                        color="#2196f3"
                                        size={18}
                                        src={"/New UI/SVG/info.svg"}
                                    />
                                </div>
                            </LightTooltip>
                        </div>
                        <SVGIcon
                            onClick={() => toggleAISettings(false)}
                            src={"/New UI/SVG/cross.svg"}
                        />
                    </h3>

                    <div className={styles.settingModalExtendedItem}>
                        {/* <div className={styles.settingModalCommonheading}>Context</div> */}
                        <TextArea
                            label="Context"
                            maxLength={600}
                            labelSize={14}
                            placeholder="Please provide a short background context related to your topic"
                            props={{
                                value: context,
                                // onBlur: () => {
                                //   setAiWordLimitSetting((settings) => {
                                //     return { ...settings, context: context };
                                //   });
                                // },

                                onChange: (e) => {
                                    setContext(e.target.value);
                                },

                                spellCheck: false,
                            }}
                            rowsMin={4}
                            rowsMax={6}
                        />
                    </div>

                    <div
                        className={styles.settingModalExtendedItem}
                        style={{ paddingBottom: 0 }}
                    >
                        {/* <div className={styles.settingModalCommonheading}>
            Keywords to include
          </div> */}
                        <TextArea
                            label="Keywords"
                            labelSize={14}
                            maxLength={100}
                            placeholder="Please provide a list of comma separated keywords to include in the context. For Example- Green tea, Black tea"
                            props={{
                                value: keywords,
                                // onBlur: () => {
                                //   setAiWordLimitSetting((settings) => {
                                //     return { ...settings, keywords: keywords };
                                //   });
                                // },
                                onChange: (e) => {
                                    setKeywords(e.target.value);
                                },

                                spellCheck: false,
                            }}
                            rowsMin={3}
                            rowsMax={4}
                        />
                    </div>

                    <div className={styles.settingModal}>
                        <SliderItem
                            label="Output Length"
                            valueSetting={outputLength}
                            setValueSetting={setOutputLength}
                        />
                        <SliderItem
                            label="Creativity"
                            valueSetting={creativity}
                            setValueSetting={setCreativity}
                            labelArr={["Low", "Medium", "High"]}
                        />
                        {/* <div className={styles.settingModalExtended}>
            {/* {!contextToggle && (
      <div
        className={styles.settingModalDisabled}
        onClick={() => {}}
      ></div>
    )} */}

                        {/* <div className={styles.settingModalExtendedItem}>
      <div className={styles.settingModalCommonheading}>
        Tone of Voice
      </div>
      <TextArea
        showLabel={false}
        placeholder="Witty, Friendly, Professional"
        props={{
          value: tone,
          // onBlur: () => {
          //   setAiWordLimitSetting((settings) => {
          //     return { ...settings, toneOfVoice: tone };
          //   });
          // },
          onChange: (e) => {
            setTone(e.target.value);
          },
          style: {
            padding: "10px",
            borderRadius: "2.5px",
            border: "solid 1px #e3e3e3",
            backgroundColor: "#fcfcfc",
          },
          spellCheck: false,
        }}
        rowsMin={1}
      />
    </div>

            <div className={styles.settingExtendedToggle}>
              <ToggleButtonModified
                on={contextToggle}
                setOn={() => {
                  // setAiWordLimitSetting((settings) => {
                  //   return {
                  //     ...settings,
                  //     contextToggle: !aiWordLimitSetting.contextToggle,
                  //   };

                  setContextToggle((contextToggle) => !contextToggle);
                }}
              />
            </div>
          </div> */}
                        {attachedTo != SMART_EDITOR && (
                            <>
                                <hr />
                                <NLPConfigure
                                    aiWordLimitSetting={aiWordLimitSetting}
                                    reportInfo={{
                                        ...reportInfo,
                                        relevantTerms: keyTerms,
                                    }}
                                    nlpToggle={nlpToggle}
                                    setNlpToggle={setNlpToggle}
                                />
                            </>
                        )}

                        <div className={styles.settingModalActionBtns}>
                            <Button
                                text="Cancel"
                                style={{
                                    width: "120px",
                                    height: "38px",
                                    backgroundColor: "#fff",
                                    color: "#ababab",
                                    borderRadius: 3,
                                    fontSize: "14px",
                                }}
                                handler={() => toggleAISettings(false)}
                            />
                            <Button
                                text="Update"
                                style={{
                                    width: "120px",
                                    height: "38px",
                                    backgroundColor: "#1f4cbe",
                                    color: "#fff",
                                    borderRadius: 3,
                                    fontSize: "14px",
                                }}
                                handler={() => updateAiSettings()}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export const SliderItem = ({
    label,
    valueSetting,
    setValueSetting,
    labelArr = ["Short", "Medium", "Long"],
    sliderSettings: { min, max, step, start } = {
        min: 1,
        max: 3,
        step: 1,
        start: 2,
    },
    labelClass = {},
    optionsClass = {},
}) => {
    // console.log(valueSetting, setValueSetting, "HELLO");
    return (
        <>
            <div className={`${styles.settingModalHeading} ${labelClass}`}>
                {label}
            </div>
            <div
                className={`${styles.settingModalTextLimitDiv} ${optionsClass}`}
            >
                <button
                    className={
                        styles.configureBtn +
                        " " +
                        (valueSetting == 1 ? styles.activeSetting : "")
                    }
                    onClick={() => setValueSetting(1)}
                >
                    <div>{labelArr[0]}</div>
                </button>
                <button
                    className={
                        styles.configureBtn +
                        " " +
                        (valueSetting == 2 ? styles.activeSetting : "")
                    }
                    onClick={() => setValueSetting(2)}
                >
                    <div>{labelArr[1]}</div>
                </button>
                <button
                    className={
                        styles.configureBtn +
                        " " +
                        (valueSetting == 3 ? styles.activeSetting : "")
                    }
                    onClick={() => setValueSetting(3)}
                >
                    <div>{labelArr[2]}</div>
                </button>
            </div>
        </>
    );
};

export default Configure;
