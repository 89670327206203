import { useHistory } from "react-router";
import allScreens from "../../../screens.json";

function getFinalPath(screen, params = [], slug = "") {
    let path = allScreens[screen];

    if (slug) {
        path = path + "/" + slug;
    }

    if (params.length === 0) return path;

    for (let i = 0; i < params.length; i++) {
        if (i === 0) {
            path = path + "?";
        }
        if (i > 0) {
            path = path + "&";
        }
        path = path + params[i];
    }

    return path;
}

export default function useNavigation() {
    const history = useHistory();
    /**
     * @param {string=}  screen
     * @param {Array<string>=} params
     * @param {string=}  slug
     * @param {boolean=}  replace
     * @param {string=} path
     * @returns {Function}
     */
    function goTo(screen, params, slug, replace, path) {
        if (path)
            history.push(path, {
                from: window.location.pathname,
            });
        else if (replace) {
            history.replace(getFinalPath(screen, params, slug), {
                from: window.location.pathname,
            });
        } else {
            history.push(getFinalPath(screen, params, slug), {
                from: window.location.pathname,
            });
        }
    }

    return goTo;
}
