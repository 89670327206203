import { customLoadStripe } from "../../atoms/Stripe";
import React, { useContext, useEffect, useState } from "react";
import {
    STRIPE_TOKEN,
    STRIPE_TOKEN1,
    STRIPE_TOKEN2,
} from "../../../utility/config";
import Button from "../../atoms/Button/Button";
import ToggleButton from "../../atoms/CustomToggle/ToggleButtonModified";
import SVGIcon from "../../atoms/SVGIcon";
import useHelperFunctions from "../../helperFunctions";
import styles from "./styles.module.css";

import { CREATE_PAYMENT } from "../../api.json";
import Logout from "../../../components/authentication/logout";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import WebsiteHtml from "./WebsiteHtml";
import Modal from "../../atoms/Modal/Modal";
import PlanTypeToggleButton from "../../atoms/CustomToggle/PlanTypeToggleButton";
import usePriceDiscounts, {
    getMonths,
    getYearSave,
    parsePrice,
} from "./usePriceDiscounts";
import { PLANS_CATEGORIES, PLANS_TYPE } from "../../../constants";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { planCatInfo } from "../../../components/subsCards/lists";
import DealTop from "../../../components/subsCards/DealTop";
import DealTopBF, {
    calculateResultantDiscount,
} from "../../../components/subsCards/DealTopBF";
import PostPaymentModalBF from "../../organisms/PostPaymentModal/PostPaymentModalBF";
import {
    AnniversaryText,
    MaxPlansToggle,
    SubscriptionToggle,
} from "../../../components/subsCards";
import PostPaymentModal from "../../organisms/PostPaymentModal/PostPaymentModal";
import PriceCardBF from "../../../components/subsCards/PriceCardBF";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import CompareTable from "../../../components/subsCards/CompareTable";
const exitIntentSubCards = [
    { src: "eip_cm.svg", id: 1, name: "Cruise Mode" },
    { src: "eip_aw.svg", id: 2, name: "Article Writer" },
    { src: "eip_research.svg", id: 3, name: "SEO Research" },
    { src: "eip_co.svg", id: 4, name: "Content Optimizer" },
    { src: "eip_integration.svg", id: 5, name: "Integrations" },
    { src: "eip_kp.svg", id: 6, name: "Keyword Planner" },
];

function CCUser() {
    const { postData } = useHelperFunctions();
    const [loading, setLoading] = useState(false);
    const otherInfo = useSelector((state) => state.otherInfo);
    const email = useSelector((state) => state.auth.email);
    const { ccPlan } = useContext(OnBoardingContext);
    const { deals } = useSelector((state) => state.appConfig);
    const [maxPlans, setMaxPlans] = useState(false);
    const signupMode = useSelector((state) => state.auth.signup_mode);
    // Sale Revert
    const [isDeal, setIsDeal] = useState(false);

    const {
        plans,
        planTypesWithDiscount,
        plansType,
        setPlansType,
        getDiscount,
    } = usePriceDiscounts();
    const { top, bottom } = WebsiteHtml({
        subs: false,
        plansType,
        cards: plans,
        isDeal,
        setPlansType,
        getDiscount,
        // dealsLimit: deals ? JSON.parse(deals)?.dealLimitsMultiplier : 1,
        dealsLimit: isDeal ? 2 : 1,
        maxPlans: maxPlans,
    });

    //plan type -> monthly yearly
    //plan cat -> indvi growth pro
    useEffect(() => {
        if (ccPlan) {
            purchaseHandler(ccPlan);
        }
    }, []);

    const purchaseHandler = async (planId) => {
        setLoading(true);
        let stripeID =
            otherInfo?.subscriptionInfo?.stripe_region == 2
                ? STRIPE_TOKEN2
                : STRIPE_TOKEN1;
        const stripe = await customLoadStripe(stripeID);
        let baseURL = window.location.origin;
        let successURL = `${baseURL}/admin/transaction568894547/98797984111237/?session_id={CHECKOUT_SESSION_ID}&dashboard=true`;
        let cancelURL = `${baseURL}/admin/account/plans`;
        let criteria = {
            successURL,
            cancelURL,
            mode: "subscription",
            tid: localStorage.getItem("FPROM_ID"),
            type: 1,
            planID: planId,
        };

        const response = await postData({
            url: CREATE_PAYMENT,
            payload: criteria,
        });
        if (response.status == 200)
            stripe.redirectToCheckout({
                sessionId: response?.data?.paymentTransactionID,
            });
        else {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (maxPlans) {
            setPlansType(2);
        }
    }, [maxPlans]);

    const sortCards = (card1, card2) => card1.plan_cost - card2.plan_cost;

    return (
        <div className={styles.ccPlansContainer}>
            {/* {<ExitIntentModal setMaxPlan={setMaxPlans} />} */}
            <div className={styles.header} style={{ marginBottom: "10px" }}>
                <div className={styles.logo}>
                    <SVGIcon size={32} src="/New UI/SVG/Scalenut.svg"></SVGIcon>{" "}
                    Scalenut
                </div>
                <div>
                    <Logout
                        render={(logout) => (
                            <LightTooltip
                                placement="bottom"
                                title={
                                    <>
                                        <div>Logged In as:</div>
                                        <div>{email}</div>
                                    </>
                                }
                                arrow
                            >
                                <div>
                                    <Button
                                        width="100px"
                                        height={30}
                                        handler={() => logout()}
                                        iconReverse
                                        Icon={RiLogoutCircleLine}
                                        text="Logout"
                                        style={{
                                            fontSize: 14,
                                            backgroundColor: "rgba(0,0,0,0.05)",
                                            color: "var(--secondary-color)",
                                        }}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                    />
                </div>
            </div>
            {isDeal && <DealTopBF isCC={true} deals={deals} />}
            {/* <PostPaymentModal plansType={plansType} /> */}
            <div className={styles.body}>
                {!maxPlans && (
                    <header>
                        <h2>
                            Select a plan to start your 7 days risk free trial.
                        </h2>
                        <h3>
                            We<u>don't charge</u>until your trial expires. You
                            can cancel anytime.
                        </h3>
                    </header>
                )}
                <div className={maxPlans ? styles.maxPlanContainer : ""}>
                    {maxPlans && <AnniversaryText />}
                    {/* <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: maxPlans ? "20px" : "",
                            marginTop: maxPlans ? "20px" : "",
                        }}
                    >
                        <MaxPlansToggle
                            setMaxPlans={setMaxPlans}
                            maxPlans={maxPlans}
                        />
                    </div> */}
                    {!maxPlans && (
                        <div className={styles.toggleWrapper}>
                            <SubscriptionToggle
                                plansType={plansType}
                                setPlansType={setPlansType}
                                isDeal={isDeal}
                                getDiscount={getDiscount}
                            />
                        </div>
                    )}
                    {/* <PlanTypeToggleButton
                    options={planTypesWithDiscount}
                    callback={(value) => {
                        setPlansType(value);
                    }}
                    defaultValue={PLANS_TYPE.YEARLY}
                ></PlanTypeToggleButton> */}

                    {plans.length ? (
                        <section className={styles.cardsContainer}>
                            {!maxPlans
                                ? plans
                                      .sort(sortCards)
                                      .filter(
                                          (plan) =>
                                              plan.plan_type == plansType &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.INTER_LINKING &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.HUMANIZER &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.GROWTH_MAX &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.PRO_MAX,
                                      )
                                      .map((plan, i) => (
                                          <PlanCard
                                              fav={i == 1}
                                              purchaseHandler={purchaseHandler}
                                              plan={plan}
                                              loading={loading}
                                              getDiscount={getDiscount}
                                              plansType={plansType}
                                              isDeal={isDeal}
                                          />
                                      ))
                                : plans
                                      .sort(sortCards)
                                      .filter(
                                          (plan) =>
                                              plan.plan_type == plansType &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.INTER_LINKING &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.HUMANIZER &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.GROWTH &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.EXPERT &&
                                              plan.plan_category !=
                                                  PLANS_CATEGORIES.PRO,
                                      )
                                      .map((plan, i) => (
                                          <PriceCardBF
                                              otherInfo={otherInfo}
                                              item={plan}
                                              planType={2}
                                              data={plans}
                                              currentPlanId={null}
                                              details={{}}
                                              getDiscount={getDiscount}
                                              signupMode={signupMode}
                                              lmPlan={{}}
                                              maxPlans={maxPlans}
                                          />
                                      ))}
                        </section>
                    ) : (
                        <></>
                    )}
                </div>
                {maxPlans && <CompareTable cards={plans} />}

                <footer></footer>

                {top}
                <DealTop isCC={true} />

                {bottom}
            </div>
        </div>
    );
}

export default CCUser;

const PlanCard = ({
    plan,
    purchaseHandler,
    fav,
    loading,
    getDiscount,
    plansType,
    isDeal,
}) => {
    const [_loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) setLoading(false);
    }, [loading]);
    const freeuser = true;

    return (
        <div className={`${styles.planCard} ${fav ? styles.favPlan : ""}`}>
            {fav && (
                <div
                    style={{ background: isDeal ? "#6cd1db" : "" }}
                    className={styles.popular}
                >
                    Most Popular
                </div>
            )}
            <h2>{plan.plan_name}</h2>
            <p style={{ minHeight: "40px" }}>
                {planCatInfo(plansType)[plan.plan_category]?.subTitle}
            </p>

            <div className={styles.cardPricingDiv}>
                <div className={styles.cardPriceOriginaldiv}>
                    {freeuser && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <span
                                style={{
                                    visibility: !getDiscount(plan.plan_type)
                                        ? "hidden"
                                        : "unset",
                                }}
                                className={styles.cardPriceOriginalDis}
                            >
                                {parsePrice(
                                    plan.plan_cost,
                                    plan.currency,
                                    plan.plan_type,
                                )}
                            </span>
                            <span
                                className={styles.offer}
                                style={{
                                    visibility: !getDiscount(plan.plan_type)
                                        ? "hidden"
                                        : "unset",
                                    background: isDeal ? "#FDFF44" : "",
                                }}
                            >
                                <strong style={{ fontWeight: 500 }}>
                                    {getDiscount(plan.plan_type)}% OFF
                                </strong>{" "}
                                Limited Time Offer
                            </span>
                        </div>
                    )}
                    <span className={styles.cardPriceOriginal}>
                        {parsePrice(
                            plan.plan_cost,
                            plan.currency,
                            plan.plan_type,
                            getDiscount(plan.plan_type),
                        )}
                    </span>
                    {"/mo"}
                    {freeuser && (
                        <div
                            className={styles.youSave}
                            style={{
                                visibility: !getDiscount(plan.plan_type)
                                    ? "hidden"
                                    : "unset",
                            }}
                        >
                            Billed annually{" "}
                            <strong>
                                (Save{" "}
                                {getYearSave(
                                    plan.plan_cost,
                                    plan.currency,
                                    plan.plan_type,
                                    getDiscount(plan.plan_type),
                                )}
                                /Year)
                            </strong>
                        </div>
                    )}
                </div>
            </div>

            <Button
                disabled={loading}
                style={{
                    background: isDeal ? "#1A5BF0" : "#0079e4",
                    fontSize: 14,
                }}
                handler={() => {
                    setLoading(true);
                    purchaseHandler(plan.plan_id);
                }}
                Icon={() => <SVGIcon src="/New UI/SVG/bsarrowright.svg" />}
                text="Start your 7 day Free Trial!"
                loading={_loading}
            ></Button>

            <ul className={styles.checksList}>
                <h4
                    style={{
                        visibility:
                            planCatInfo(plansType, isDeal)[plan.plan_category]
                                ?.includes == "none"
                                ? "hidden"
                                : "visible",
                    }}
                >
                    {
                        planCatInfo(plansType, isDeal)[plan.plan_category]
                            ?.includes
                    }
                </h4>
                {planCatInfo(plansType, isDeal)[
                    plan.plan_category
                ]?.checks?.map((text) => (
                    <li>
                        {text.isAddOn ? (
                            <div style={{ marginRight: "20px" }}>+</div>
                        ) : (
                            !text.noCheck && (
                                <div className={styles.check}>
                                    <SVGIcon
                                        size={13}
                                        src="/New UI/SVG/check.svg"
                                    ></SVGIcon>
                                </div>
                            )
                        )}
                        {text?.text ? text?.text : text}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const PlanCardBF = ({
    plan,
    purchaseHandler,
    fav,
    loading,
    getDiscount,
    plansType,
}) => {
    const [_loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) setLoading(false);
    }, [loading]);
    const freeuser = true;

    return (
        <div
            className={`${styles.planCard} ${styles.planCardBF}  ${
                fav ? styles.favPlan : ""
            }`}
        >
            {fav && <div className={styles.popular}>Most Popular</div>}
            <h2>{plan.plan_name}</h2>
            <p
                style={{ minHeight: "40px" }}
                className={styles.PlanCardBF_subtitle}
            >
                {planCatInfo(plansType)[plan.plan_category]?.subTitle}
            </p>

            <div className={styles.cardPricingDiv}>
                <div className={styles.cardPriceOriginaldiv}>
                    {freeuser && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "20px",
                                marginBottom: 10,
                            }}
                        >
                            <span
                                style={{
                                    visibility: !getDiscount(plan.plan_type)
                                        ? "hidden"
                                        : "unset",
                                }}
                                className={styles.cardPriceOriginalDis}
                            >
                                {parsePrice(
                                    plan.plan_cost,
                                    plan.currency,
                                    plan.plan_type,
                                )}
                            </span>
                            <span
                                className={styles.offer}
                                style={{
                                    visibility: !getDiscount(plan.plan_type)
                                        ? "hidden"
                                        : "unset",
                                }}
                            >
                                <strong style={{ fontWeight: 500 }}>
                                    {20}% OFF
                                </strong>{" "}
                                Regular Offer
                            </span>
                        </div>
                    )}
                    {freeuser && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <span
                                style={{
                                    visibility: !getDiscount(plan.plan_type)
                                        ? "hidden"
                                        : "unset",
                                }}
                                className={styles.cardPriceOriginalDis}
                            >
                                {parsePrice(
                                    plan.plan_cost,
                                    plan.currency,
                                    plan.plan_type,
                                    20,
                                )}
                            </span>
                            <span
                                className={styles.offer}
                                style={{
                                    visibility: !getDiscount(plan.plan_type)
                                        ? "hidden"
                                        : "unset",
                                }}
                            >
                                <strong style={{ fontWeight: 500 }}>
                                    {getDiscount(plan.plan_type)}% OFF
                                </strong>{" "}
                                BF Discount
                            </span>
                        </div>
                    )}
                    <span className={styles.cardPriceOriginal}>
                        {parsePrice(
                            plan.plan_cost,
                            plan.currency,
                            plan.plan_type,
                            getDiscount(plan.plan_type),
                        )}
                    </span>
                    {"/mo"}
                    {freeuser && (
                        <div
                            className={styles.youSave}
                            style={{
                                visibility: !getDiscount(plan.plan_type)
                                    ? "hidden"
                                    : "unset",
                            }}
                        >
                            Billed annually{" "}
                            <strong>
                                (Save{" "}
                                {getYearSave(
                                    plan.plan_cost,
                                    plan.currency,
                                    plan.plan_type,
                                    getDiscount(plan.plan_type),
                                )}
                                /Year)
                            </strong>
                        </div>
                    )}
                </div>
            </div>

            <Button
                disabled={loading}
                style={{
                    backgroundColor: "#f3c975",
                    fontSize: 14,
                    color: "#000",
                }}
                handler={() => {
                    setLoading(true);
                    purchaseHandler(plan.plan_id);
                }}
                Icon={() => <SVGIcon src="/New UI/SVG/bsarrowright.svg" />}
                text="Start your 7 day Free Trial!"
                loading={_loading}
            ></Button>

            <ul className={styles.checksList}>
                <h4
                    style={{
                        visibility:
                            planCatInfo(plansType)[plan.plan_category]
                                ?.includes == "none"
                                ? "hidden"
                                : "visible",
                    }}
                >
                    {planCatInfo(plansType)[plan.plan_category]?.includes}
                </h4>
                {planCatInfo(plansType)[plan.plan_category]?.checks?.map(
                    (text) => (
                        <li>
                            <div className={styles.checkBF}>
                                <SVGIcon
                                    size={13}
                                    src="/New UI/SVG/SingleTick.svg"
                                ></SVGIcon>
                            </div>
                            {text?.text ? text?.text : text}
                        </li>
                    ),
                )}
            </ul>
        </div>
    );
};

export const ExitIntentModal = ({ handler = () => {}, setMaxPlan }) => {
    const [open, setOpen] = useState(
        Number(sessionStorage.getItem("exitIntent") || 1),
    );
    const name = useSelector((state) => state.auth.user_name);
    const { deals } = useSelector((state) => state.appConfig);
    const [_deals, setDeals] = useState({});
    const goTo = useNavigation();
    useEffect(() => {
        if (deals) {
            setDeals(JSON.parse(deals));
        }
    }, [deals]);

    useEffect(() => {
        document.onmouseleave = () => {
            setOpen((ps) => (ps ? ps - 1 : ps));
        };

        setTimeout(() => {
            setOpen((ps) => (ps ? ps - 1 : ps));
        }, 30000);

        document.onbeforeunload = () => {
            return "are you sure?";
        };
        return () => {
            document.removeEventListener("mouseout", setOpen);
            document.onmouseleave = () => {};
        };
    }, []);

    useEffect(() => {
        if (open >= -1) {
            sessionStorage.setItem("exitIntent", open);
        }
    }, [open]);

    return (
        <>
            {!open && (
                <Modal setModal={() => setOpen((ps) => ps - 1)}>
                    <div className={styles.intentPopup}>
                        <span
                            onClick={() => setOpen((ps) => ps - 1)}
                            className={styles.cross}
                        >
                            <SVGIcon
                                color="white"
                                src="/New UI/SVG/cross.svg"
                            />
                        </span>
                        <div
                            style={{
                                fontSize: 50,
                                fontWeight: 600,
                                position: "relative",
                                zIndex: 2,
                                color: "#fff",
                            }}
                        >
                            Celebrating
                        </div>
                        <div
                            style={{
                                fontSize: 50,
                                fontWeight: 600,
                                color: "#fff",
                                background:
                                    "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                                backgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            4th Anniversary
                        </div>

                        <div
                            style={{
                                background:
                                    "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.97)), linear-gradient(16.01deg, rgba(1, 1, 1, 0.2) 53.05%, rgba(255, 192, 85, 0.2) 78.87%, rgba(36, 27, 12, 0.2) 100%)",
                                padding: "15px 40px",
                                borderRadius: "10px",
                                width: "100%",
                                margin: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "20px",
                                marginTop: "40px",
                                marginBottom: "20px",
                                border: "0.8px solid" /* This sets the border width */,
                                borderImageSource:
                                    "linear-gradient(90.82deg, rgba(191, 149, 63, 0.4) -2.17%, rgba(252, 246, 186, 0.4) 29.43%, rgba(179, 135, 40, 0.4) 101.5%)",
                            }}
                        >
                            <span
                                style={{
                                    background:
                                        "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                                    backgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    position: "relative",
                                    fontSize: 28,
                                    fontWeight: 600,
                                }}
                            >
                                <span
                                    style={{
                                        background:
                                            "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.97)), linear-gradient(16.01deg, rgba(1, 1, 1, 0.2) 53.05%, rgba(255, 192, 85, 0.2) 78.87%, rgba(36, 27, 12, 0.2) 100%)",
                                        padding: "5px 10px",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                        border: "0.34px solid",
                                        borderImageSource:
                                            "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                                        position: "absolute",
                                        top: "-46px",
                                        height: "30px",
                                        left: "24%",
                                        display: "flex",
                                        paddingInline: "10px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            display: "inline-block",
                                            background:
                                                "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                                            backgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                            fontSize: 12,
                                            fontWeight: 500,
                                        }}
                                    >
                                        100 LICENSES ONLY
                                    </span>
                                </span>
                                {_deals.maxPlanDiscount}% with Max Plans
                                <span
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "absolute",
                                        zIndex: 1000,
                                        right: "-5px",
                                        bottom: "-76px",
                                    }}
                                >
                                    <SVGIcon
                                        src={"/New UI/SVG/underline.svg"}
                                        size={150}
                                    />
                                </span>
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "6px",
                                }}
                            >
                                {[
                                    "Free AI Link Manager",
                                    "Free AI Humanizer",
                                    "Higher limits",
                                ].map((text, idx) => {
                                    return (
                                        <>
                                            {idx > 0 && (
                                                <span
                                                    style={{
                                                        color: "#373120",
                                                        fontSize: "25px",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    +
                                                </span>
                                            )}
                                            <div
                                                style={{
                                                    borderRadius: "18px",
                                                    border: "3.06px solid #373120",
                                                    color: "#fff",
                                                    fontSize: "15px",
                                                    fontWeight: 500,
                                                    boxShadow:
                                                        "0px 12.64px 10.53px 0px #0000001C",
                                                    padding: "8px 15px",
                                                    display: "inline-block",
                                                    width: "max-content",
                                                }}
                                            >
                                                {text}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <div
                                style={{
                                    margin: "auto",
                                    position: "relative",
                                    minWidth: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        display: "inline-block",
                                        background:
                                            "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                                        backgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        fontSize: 15,
                                        fontWeight: 500,
                                        padding: "4px 10px",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                        position: "absolute",
                                        border: "3.06px solid #373120",
                                        width: "max-content",
                                        bottom: "-20px",
                                    }}
                                >
                                    Limited Edition
                                </span>
                            </div>
                        </div>
                        <Button
                            text="Grab The Deal"
                            style={{
                                backgroundColor: "white",
                                borderRadius: 20,
                                background: "#F3C975",
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: 18,
                            }}
                            Icon={() => (
                                <SVGIcon
                                    src={"/New UI/SVG/tilt-bold-arrow.svg"}
                                    style={{ color: "#00001CE5" }}
                                    size={16}
                                />
                            )}
                            height={46}
                            width={200}
                            handler={() => {
                                setMaxPlan(true);
                                setOpen((ps) => ps - 1);
                            }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
