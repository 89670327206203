export const PLANS_CATEGORIES = {
    TRAIL: 1,
    INDIVIDUAL: 2,
    GROWTH: 3,
    PRO: 4,
    ENTERPRISE: null,
    FREE_FOREVER: 5,
    EXPERT: 7,
    INTER_LINKING: 8,
    HUMANIZER: 9,
    GROWTH_MAX: 10,
    PRO_MAX: 11,
};

export const PLANS_CATEGORIES_COST_SORT = {
    [PLANS_CATEGORIES.FREE_FOREVER]: 0,
    [PLANS_CATEGORIES.INDIVIDUAL]: 1,
    [PLANS_CATEGORIES.EXPERT]: 2,
    [PLANS_CATEGORIES.GROWTH]: 3,
    [PLANS_CATEGORIES.PRO]: 4,
};

export const PLANS_ACTION = {
    CHOOSE: "Choose plan",
    CURRENT: "Current plan",
    UPGRADE: "Upgrade plan",
    DOWNGRADE: "Downgrade plan",
    TRAILTOFREE: "Automatically activated at trial expiry.",
    PLANTOFREE: "Automatically activated after current plan ends.",
};

export const PLANS_TYPE = {
    MONTHLY: 1,
    YEARLY: 2,
    LIFETIME: 3,
    SEMI: 4,
};

export const ADD_ONS_TYPE = {
    TEAM_MEMBER: 2,
    LM_PAGES: 3,
    AI_HUMANIZER: 4,
};

export const INTERLINKING_STATUS = {
    DISCONNECTED: 0,
    CONNECTED: 1,
    TRIAL: 2,
};

export const TERMINATED = 1;

//Events

export const scalenutAPIEvent = "scalenutAPIEvent";
