import React, { useCallback, useRef, useState, useEffect } from "react";
import styles from "./styles.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClickAwayListener, debounce } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import VideoModal from "../../Pages/NewDashboard/VideoModal";
import { scrollToTop } from "../../utlities";
import { ChevronLeft } from "@material-ui/icons";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";

function ModuleTemplate({
    infiniteScrollProps = null,
    infiniteScroll,
    stickyHeaderChild = <></>,
    HeaderChild = <></>,
    HeaderSubChild = <></>,
    heading = "heading",
    subHeading = "subHeading",
    setInitiateClose,
    initiateClose,
    userVideo,
    content = <></>,
    sourceWhere = "",
}) {
    const headerRef = useRef(null);
    const containerRef = useRef(null);
    const parentContainerNode = containerRef?.current?.parentNode;

    const ScrollToTop = () => {
        parentContainerNode.scrollTop = 0;
    };

    const [scrollToTopEnable, setScrollToTopEnable] = useState(false);

    const callback = useCallback((e) => {
        if (e.target.scrollTop > 200) {
            setScrollToTopEnable(true);
        } else {
            setScrollToTopEnable(!true);
        }
    }, []);
    useEffect(() => {
        if (parentContainerNode) {
            parentContainerNode.style.scrollBehavior = "smooth";
            parentContainerNode.addEventListener("scroll", callback);
            return () => {
                parentContainerNode.style.scrollBehavior = "unset";
                parentContainerNode.removeEventListener("scroll", callback);
            };
        }
    }, [parentContainerNode]);

    return (
        <div ref={containerRef} className={styles.container}>
            <div
                ref={headerRef}
                style={{
                    top: -(
                        headerRef?.current?.offsetHeight -
                        (sourceWhere == "docs" ? 136 : 100)
                    ),
                }}
                className={styles.header}
            >
                <div className={styles.innerHeader}>
                    <div className={styles.headerTitle}>
                        <div>
                            <h1>{heading}</h1>
                            <p>{subHeading}</p>
                        </div>
                        <br />
                        {HeaderChild}
                        {/* {HeaderSubChild} */}
                    </div>
                    {userVideo?.status ? (
                        <div className={styles.videoContainer}>
                            <ClickAwayListener
                                onClickAway={() => setInitiateClose(false)}
                            >
                                <span
                                    className={styles.videoFrameClose}
                                    style={{
                                        display: initiateClose ? "flex" : "",
                                        background: "none",
                                    }}
                                    onClick={() => {
                                        setInitiateClose(!initiateClose);
                                    }}
                                >
                                    <VscClose />
                                </span>
                            </ClickAwayListener>
                            <VideoModal
                                link={`${userVideo.link}?modestbranding=1&rel=0&loop=1`}
                                zoomVal={1.2}
                                modal={false}
                                initiateClose={initiateClose}
                                sourceWhere={sourceWhere}
                                customClass={
                                    sourceWhere == "audit"
                                        ? styles.largeVideo
                                        : styles.video
                                }
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                <div className={styles.stickyHeader}>{stickyHeaderChild}</div>
            </div>

            <div className={styles.scrollable}>
                {infiniteScroll && (
                    <InfiniteScroll {...infiniteScrollProps}></InfiniteScroll>
                )}
                {content}
            </div>
            {scrollToTopEnable && (
                <div className={styles.scrollTopContainer}>
                    <div
                        onClick={() => ScrollToTop()}
                        className={styles.scrollTop}
                    >
                        <ChevronLeft />
                        <ChevronLeft />
                    </div>
                    <div style={{ marginTop: 7 }}> Scroll to top</div>
                </div>
            )}
        </div>
    );
}

export default ModuleTemplate;
