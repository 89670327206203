import React, { useState, useEffect, useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import Modal from "../../../atoms/Modal/Modal";
import NewUIDropDown, {
    TYPES,
} from "../../../molecules/NewUIDropdown/NewUIDropDown";
import EditorContext from "../../TextEditor/EditorContext";
import TextHighlighter from "../../TextEditor/TextHighlighter";

import styles from "./KeyTerms.module.css";
import KeyTermsRow from "./KeyTermsRow";
import pluralize from "pluralize";
import RatingHook from "../../../molecules/Creator/Overview/RatingHook";
import MultiInputWithChip from "../../../atoms/MultiInputWithChip/MultiInputWithChip";
import Button from "../../../atoms/Button/Button";
import { FaCheck, FaXmark } from "react-icons/fa6";
import useHelperFunctions from "../../../helperFunctions";
import ReportContext from "../ReportContext";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { Skeleton } from "@material-ui/lab";

const options = [
    { title: "Importance", value: "Importance" },
    { title: "Unused", value: "Unused" },
    { title: "Heading Presence", value: "Heading Presence" },
];

const orderOptions = {
    asc: 1,
    desc: 2,
};

export default function KeyTerms({
    // list = [],
    editorText,
    toggleModal,
    setKeyWord,
    reportInfo,
    keyWord,
    writingState,
    setWritingState,
    aiWordLimitSetting,
    setAiWordLimitSetting,
    writeWithAnimation,
    selection,
}) {
    const { setReportData, keyTerms } = useContext(ReportContext);
    const [order, setOrder] = useState(orderOptions.desc);
    const [sortBy, setSortBy] = useState(options[0]);
    const [highlight, setHighlight] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [customKeyTerm, setCustomKeyTerm] = useState(false);
    const [inputKeyTerms, setInputKeyTerms] = useState([]);
    const [keytermLoading, setKeytermLoading] = useState(false);
    const [_list, setLIst] = useState(keyTerms);
    console.log(keyTerms, "eeeeeee");
    console.log(_list, "eeeeeeefffffffff");
    const { saving, versionData } = useContext(EditorContext);
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const { mixpanelTrack } = useMixpanelHook();

    const updateKeyTermsList = async () => {
        if (inputKeyTerms.length) {
            const userAddedTerms = inputKeyTerms.map((ele) => {
                return { term: ele };
            });
            setKeytermLoading(true);
            const res = await postData({
                url: "/api/scrape/unwantedNLP",
                payload: {
                    report_id: reportInfo.report_id,
                    terms: [],
                    userAdded: userAddedTerms,
                },
            });
            setKeytermLoading(false);
            setCustomKeyTerm(false);
            setInputKeyTerms([]);
            if (res.status == 200) {
                setReportData((ps) => ({
                    ...ps,
                    keyTerms: res.data?.relevantTerms,
                }));
            }
            dispatch(
                displayAlert({
                    alertMessage: "Keyterm(s) have been added successfully!",
                    alertType: "success",
                }),
            );
        } else {
            return;
        }
    };

    useEffect(() => {
        setHighlight(false);
    }, [versionData]);

    const _setUsed = (bool, index) => {
        setLIst((prev) => {
            prev[index].used = bool;
            return prev;
        });
    };

    useEffect(() => {
        setHighlight(false);
    }, [versionData]);

    useEffect(() => {
        (async () => {
            const rating = await RatingHook(
                document.quill?.editor?.getText(),
                keyTerms.map((item) => ({
                    ...item,
                    singular: pluralize.singular(item?.keyword),
                    plural: pluralize.plural(item?.keyword),
                })),
                "keyTerms",
            );

            setLIst(rating.keyTermslist);
        })();
    }, [saving, keyTerms]);
    const sortingFunction = (item1, item2) => {
        if (order == orderOptions.asc) {
            const temp = item2;
            item2 = item1;
            item1 = temp;
        }
        if (sortBy.value == "Importance") {
            return item2.importance - item1.importance;
        } else if (sortBy.value == "Unused") {
            return item1.used - item2.used;
        } else {
            return item2.heading_presence - item1.heading_presence;
        }
    };

    return (
        <div className={styles.tableContainer}>
            {/* <ImSortAmountDesc
        onClick={() => setSortingMenu(!sortingMenu)}
        className={styles.sortIcon}
        color="#29394a"
      /> */}

            {highlight && (
                <TextHighlighter keyTerms={_list.filter((ele) => ele.status)} />
            )}

            <div className={styles.sortOptionsButton}>
                <div
                    onClick={() => setHighlight((ps) => !ps)}
                    className={styles.highlight}
                >
                    <input checked={highlight} type="checkbox" />
                    <span>Highlight Usage</span>
                </div>
                <NewUIDropDown
                    setOrder={setOrder}
                    order={order}
                    options={options}
                    type={TYPES.SORT}
                    handler={setSortBy}
                    selectedOption={sortBy}
                    style={{
                        borderRadius: 3,
                        border: "1px solid #D9D9D9",
                        borderColor: "#D9D9D9",
                        color: "#6b6b6b",
                    }}
                />
            </div>

            {/* <table className={styles.table} id="key-terms"> */}

            {keytermLoading ? (
                <div>
                    <Skeleton height={40} />
                </div>
            ) : customKeyTerm ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        paddingBlock: 10,
                    }}
                >
                    <MultiInputWithChip
                        chips={inputKeyTerms}
                        setChips={setInputKeyTerms}
                        parentStyle={{ maxHeight: "70px", overflow: "auto" }}
                    />
                    <LightTooltip arrow title={"Update Key Terms"}>
                        <div>
                            <Button
                                handler={updateKeyTermsList}
                                text=""
                                width={30}
                                height={30}
                                Icon={FaCheck}
                                style={{
                                    background: "#014dc51a",
                                    color: "#014dc5",
                                }}
                            />
                        </div>
                    </LightTooltip>
                    <LightTooltip arrow title={"Close"}>
                        <div>
                            <Button
                                text=""
                                width={30}
                                height={30}
                                Icon={FaXmark}
                                style={{
                                    background: "rgb(255, 243, 242)",
                                    color: "rgb(252, 16, 36)",
                                }}
                                handler={() => {
                                    setCustomKeyTerm(false);
                                    setInputKeyTerms([]);
                                }}
                            />
                        </div>
                    </LightTooltip>
                </div>
            ) : (
                <Button
                    style={{
                        background: "rgba(1,77,197,.1)",
                        color: "#014dc5",
                        fontSize: "12px",
                        marginTop: "10px",
                        fontWeight: 500,
                    }}
                    width={115}
                    height={36}
                    text="+ Add Keyterms"
                    handler={() => {
                        setCustomKeyTerm(true);
                        mixpanelTrack("Add Custom Key Terms CTA", {
                            action: "clicked",
                            section: "Editor",
                        });
                    }}
                />
            )}
            <div className={`${styles.containerDiv} ${styles.keyTermsHeader}`}>
                <span style={{ position: "absolute", top: -65 }}></span>
                <div className={`${styles.mainDiv} ${styles.headerMainDiv}`}>
                    <div
                        style={{ width: "30px" }}
                        className={`${styles.leftContainer} ${styles.keytermHeading}`}
                    ></div>
                    <div
                        className={`${styles.leftContainer} ${styles.keytermHeading}`}
                    >
                        Usage
                    </div>
                    <div
                        className={`${styles.leftInfoContainer} ${styles.keytermHeading}`}
                    >
                        Keyterm
                    </div>
                    <div
                        className={`${styles.leftCommonInfo} ${styles.keytermHeading}`}
                    >
                        Importance
                    </div>

                    <div className={styles.rightContainer}></div>
                </div>
            </div>
            {_list.sort(sortingFunction).map((item, i) => {
                return (
                    <KeyTermsRow
                        index={i}
                        list={_list}
                        _setUsed={_setUsed}
                        item={item}
                        editorText={editorText}
                        toggleModal={toggleModal}
                        setKeyWord={setKeyWord}
                        reportInfo={reportInfo}
                        keyWord={keyWord}
                        writingState={writingState}
                        setWritingState={setWritingState}
                        key={item.id}
                        isDeleting={isDeleting}
                        setIsDeleting={setIsDeleting}
                        writeWithAnimation={writeWithAnimation}
                        keytermLoading={keytermLoading}
                    />
                );
            })}
            {/* </table> */}
        </div>
    );
}
