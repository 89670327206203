import React, { useState, useEffect, useRef } from "react";
import LoginWebtune from "./LoginWebtune";
import styles from "./styles.module.css";
import WebtuneDashboard from "./WebtuneDashboard";
import { Modal } from "@material-ui/core";
import HistoryWindow from "./HistoryWindow";
import Context from "../../../components/context/context";
import useHelperFunctions from "../../helperFunctions";
import { webtuneEndPoints } from "./constants";
import Progress from "../../atoms/Progress/Progress";
import { useSelector } from "react-redux";
import useNavigation from "../../atoms/GoToRoute/useNavigation";

const defaultInfo = {
    domain: "",
    is_verified: 0,
    status: -1,
    last_crawl_date: new Date(),
    last_publish_date: null,

    domain_id: null,
    isReview: false,

    verification_key: "",
    issueSummary: {},
};

export const WebtuneContext = React.createContext({
    setDomainInfo: () => {},
    setIsReview: () => {},
    setIsDomainConnected: () => {},
    isDomainConnected: false,
    ...defaultInfo,
});

function Webtune() {
    const [historyWindow, setHistoryWindow] = useState(false);
    const [isDomainConnected, setIsDomainConnected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isReview, setIsReview] = useState(false);
    const [domainInfo, setDomainInfo] = useState(defaultInfo);
    const getIssuesRequestRef = useRef(null);
    const getIssuesRequestLoaderRef = useRef(null);
    const getWebtuneDetailsRef = useRef(null);
    const { getData } = useHelperFunctions();
    const { isLoaded, webtune } = useSelector((state) => state.otherInfo);
    const goTo = useNavigation();

    const getUserDomains = async () => {
        setLoading(true);
        const res = await getData({ url: webtuneEndPoints.userDomains });

        if (res.status == 200) {
            if (res.data.length) {
                if (res.data[0].status == 4) setIsDomainConnected(true);
                setDomainInfo((ps) => ({ ...ps, ...res.data[0] }));
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isLoaded && webtune == 0) {
            goTo("DASHBOARD");
        } else if (isLoaded && webtune == 1) {
            getUserDomains();
        }
    }, [webtune]);

    return !isLoaded ? (
        <div className={styles.loginContainerWrapper}>
            <Progress />
        </div>
    ) : (
        <WebtuneContext.Provider
            value={{
                ...domainInfo,
                isDomainConnected,
                isReview,
                setIsReview,
                setDomainInfo,
                setIsDomainConnected,
            }}
        >
            <div className={styles.container}>
                <WebtuneDashboard
                    getIssuesRequestRef={getIssuesRequestRef}
                    getIssuesRequestLoaderRef={getIssuesRequestLoaderRef}
                    getWebtuneDetailsRef={getWebtuneDetailsRef}
                    setHistoryWindow={setHistoryWindow}
                />
                {!loading ? (
                    <>
                        {!isDomainConnected && (
                            <LoginWebtune
                                setIsDomainConnected={setIsDomainConnected}
                            />
                        )}
                    </>
                ) : (
                    <div className={styles.loginContainerWrapper}>
                        <Progress />
                    </div>
                )}

                <HistoryWindow
                    getIssuesRequestRef={getIssuesRequestRef}
                    getIssuesRequestLoaderRef={getIssuesRequestLoaderRef}
                    getWebtuneDetailsRef={getWebtuneDetailsRef}
                    setHistoryWindow={setHistoryWindow}
                    historyWindow={historyWindow}
                />
            </div>
        </WebtuneContext.Provider>
    );
}

export default Webtune;
