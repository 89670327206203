import { Skeleton } from '@material-ui/lab';
import React from 'react';
import styles from './ProjectDetailModalSkeleton.module.css';

export default function ProjectDetailModalSkeleton() {
  return (
    <div className={styles.projectDetailModalSkeleton}>
      <div className={styles.projectDetailModalSkeletonTop}>
        <div>
          <Skeleton variant='text' width={260} height={25} />
          <div className={styles.info}>
            <Skeleton variant='text' width={70} height={15} />
            <Skeleton variant='text' width={70} height={15} />
          </div>
        </div>
        <div class={styles.statusBanner}>
          <Skeleton variant='rect' width={160} height={40} />
        </div>
      </div>
      <div className={styles.qnaGroup}>
        <div className={styles.heading}>
          <Skeleton variant='text' width={112} height={22} />
        </div>
        <div className={styles.question}>
          <Skeleton variant='text' width={360} height={19} />
        </div>
        <div className={styles.answer}>
          <Skeleton variant='text' width={240} height={19} />
        </div>
        <div className={styles.question}>
          <Skeleton variant='text' width={360} height={19} />
        </div>
        <div className={styles.answer}>
          <Skeleton variant='text' width={240} height={19} />
        </div>
        <div className={styles.question}>
          <Skeleton variant='text' width={360} height={19} />
        </div>
        <div className={styles.answer}>
          <Skeleton variant='text' width={240} height={19} />
        </div>
      </div>
      <div className={styles.qnaGroup}>
        <div className={styles.heading}>
          <Skeleton variant='text' width={112} height={22} />
        </div>
        <div className={styles.question}>
          <Skeleton variant='text' width={360} height={19} />
        </div>
        <div className={styles.answer}>
          <Skeleton variant='text' width={240} height={19} />
        </div>
        <div className={styles.question}>
          <Skeleton variant='text' width={360} height={19} />
        </div>
        <div className={styles.answer}>
          <Skeleton variant='text' width={240} height={19} />
        </div>
        <div className={styles.question}>
          <Skeleton variant='text' width={360} height={19} />
        </div>
        <div className={styles.answer}>
          <Skeleton variant='text' width={240} height={19} />
        </div>
      </div>
      <div>
        <div className={styles.heading}>
          <Skeleton variant='text' width={112} height={22} />
        </div>
        <Skeleton variant='rect' width={560} height={120} />
      </div>
    </div>
  );
}
