import React, { useState } from "react";
import { useSelector } from "react-redux";

import { BsArrowRight } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import styles from "./styles.module.css";
import Button from "../../../atoms/Button/Button";
import BackLink from "../../../atoms/BackLink/BackLink";
import Separator from "../../../atoms/Separator/Separator";
import Spacer from "../../../atoms/Spacer/Spacer";
import Label from "../../../atoms/Label/Label";
import useHelperFunctions from "../../../helperFunctions";
import { WALLET_RECHARGE } from "../../../api.json";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import {
    STRIPE_TOKEN,
    STRIPE_TOKEN1,
    STRIPE_TOKEN2,
} from "../../../../utility/config";
import { customLoadStripe } from "../../../atoms/Stripe";
function Recharge({ setModal }) {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState("");
    const [invalidAmount, setInvalidAmount] = useState(null);
    const currency = useSelector((state) => state.auth.currency);
    const otherInfo = useSelector((state) => state.otherInfo);
    const { postData } = useHelperFunctions();
    let stripeID =
        otherInfo?.subscriptionInfo?.stripe_region == 2
            ? STRIPE_TOKEN2
            : STRIPE_TOKEN1;
    const stripePromise = customLoadStripe(stripeID);
    let baseURL = window.location.origin;
    const handleClick = async (event) => {
        event && event.preventDefault();

        if (!amount || amount < 1) {
            setInvalidAmount(styles.amountEnter);
            setTimeout(() => {
                setInvalidAmount(null);
            }, 500);

            return;
        }

        if (loading) return;
        setLoading(true);
        const stripe = await stripePromise;
        const response = await postData({
            url: WALLET_RECHARGE,
            payload: {
                currency: currency,
                recharge_amount: amount,
                domain: baseURL,
            },
        });

        const session = response?.data;

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session?.paymentTransactionID,
        });

        if (result.error) {
            setLoading(false);
        }
    };
    return (
        <div className={styles.rechargeDialog}>
            <div
                // style={{ borderBottom: "solid 0.5px lightgray " }}
                className={styles.headerDialog}
            >
                <h3>Recharge Credits</h3>
            </div>
            <CloseButton
                setClose={setModal}
                style={{ right: 30, top: 20, padding: "unset" }}
            />
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    handleClick(e);
                }}
            >
                <h4>
                    <Label label={"Amount"}></Label>
                    <div
                        className={invalidAmount}
                        style={{ paddingTop: "5px" }}
                    >
                        <Textfield
                            label=""
                            placeholder={`Enter Amount(${currency})`}
                            props={{
                                autoFocus: true,
                                focused: !loading,
                                onChange: (x) => {
                                    if (
                                        x.target.value.match(/^[1-9][0-9]*$/) ||
                                        x.target.value === ""
                                    ) {
                                        setAmount(x.target.value);
                                    }
                                },
                                value: amount,
                                disabled: loading,
                            }}
                        />
                    </div>
                </h4>
                <div>
                    <div className={styles.offerHeader}>
                        <AiFillStar /> &nbsp; Save Big!
                    </div>
                    <div className={styles.offer}>
                        {currency == "USD" ? (
                            <div>
                                <div className={styles.offer1}>
                                    Recharge for $399 or more to get 10% extra
                                    credits
                                </div>
                                {/* <Separator color="rgb(255, 151, 129, 0.2)" /> */}
                                <div className={styles.creditsDivider}></div>
                                <div className={styles.offer1}>
                                    Recharge for $799 or more to get 20% extra
                                    credits
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className={styles.offer1}>
                                    Recharge for ₹20,000 or more to get 10%
                                    extra credits
                                </div>
                                {/* <Separator color="rgb(255, 151, 129, 0.2)" /> */}
                                <div className={styles.creditsDivider}></div>
                                <div className={styles.offer1}>
                                    Recharge for ₹50,000 or more to get 20%
                                    extra credits
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Spacer spacing={10} />
                {/* <Separator /> */}
                <Spacer spacing={10} />
                <div className={styles.buttons}>
                    <div className={styles.linkContainer}>
                        <BackLink
                            handler={() => setModal(false)}
                            text="Cancel"
                        ></BackLink>
                    </div>
                    <Button
                        width="50%"
                        loading={loading}
                        Icon={BsArrowRight}
                        text="Pay"
                    />
                </div>
            </form>
        </div>
    );
}

export default Recharge;
