import * as actionTypes from "../actiontypes";

export const updateProjects = ({ id, data }) => {
  return {
    type: actionTypes.UPDATE_PROJECTS_DATA,
    id,
    ...data,
  };
};

export const loadProjects = (projects) => {
  return {
    type: actionTypes.LOAD_PROJECTS,
    projects,
  };
};

export const addProjects = (project) => {
  return {
    type: actionTypes.ADD_PROJECT,
    project,
  };
};

export const openProject = ({ openProject, projectId }) => {
  return {
    type: actionTypes.OPEN_PROJECT,
    openProject,
    projectId,
  };
};

export const removeProject = () => {
  return {
    type: actionTypes.REMOVE_PROJECT,
  };
};
