import TextArea from "../../../componentsV2/atoms/TextArea/TextArea";
import React, { useEffect, useState } from "react";
import Label from "../../atoms/Label/Label";
import styles from "./styles.module.css";
import Button from "../../atoms/Button/Button";
import { Slider } from "@material-ui/core";
const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

function FeedbackForm({ setFeedbackData, handler, review, data = {} }) {
  const [state, setState] = useState({
    comment: null,
    content_quality: 1,
    language_quality: 1,
    guidelines_followed: 1,
    score: 1,
    feedback: null,
  });

  useEffect(() => {
    if (setFeedbackData) setFeedbackData(state);
  }, [state]);

  return (
    <div className={styles.container}>
      <h2>{review ? "Feedback" : "Feedback Form"}</h2>
      {review ? (
        <>
          <Label label="1. Article comments by Editor"></Label>
          <div className={styles.ans}>{data.comment}</div>
        </>
      ) : (
        <TextArea
          props={{
            onChange: (e) => {
              setState({ ...state, comment: e.target.value });
            },
          }}
          label="1. Article comments by Editor"
        />
      )}
      <Label label="2. Content quality (Lack of fluff, Relevance, Attention to references, Inclusion of keywords) " />
      <Slider
        defaultValue={review ? data.content_quality : 1}
        onChange={(v, e) => {
          setState({ ...state, content_quality: e });
        }}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={1}
        max={10}
        className={styles.slider}
        disabled={review}
      />

      <Label label="3. Language quality (Grammar, Structure, Proofreading)" />
      <Slider
        defaultValue={review ? data.language_quality : 1}
        onChange={(v, e) => {
          setState({ ...state, language_quality: e });
        }}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={1}
        max={10}
        className={styles.slider}
        disabled={review}
      />

      <Label label="4. Guidelines Followed (Brief, Tonality, Company-appropriate)" />
      <Slider
        defaultValue={review ? data.guidelines_followed : 1}
        onChange={(v, e) => {
          setState({ ...state, guidelines_followed: e });
        }}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={1}
        max={10}
        className={styles.slider}
        disabled={review}
      />

      <Label label="5. Overall Article Score" />
      <Slider
        defaultValue={review ? data.score : 1}
        onChange={(v, e) => {
          setState({ ...state, score: e });
        }}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={1}
        max={10}
        className={styles.slider}
        disabled={review}
      />

      {review ? (
        <>
          <Label color={"red"} label="6. Writer Feedback"></Label>
          <div className={styles.ans}>{data.feedback}</div>
        </>
      ) : (
        <TextArea
          props={{
            onChange: (e) => {
              setState({ ...state, feedback: e.target.value });
            },
          }}
          label="6. Writer Feedback"
        />
      )}

      {!review && (
        <Button
          handler={handler}
          text={"SUBMIT"}
          fontSize={"12px"}
          height={36}
        />
      )}
    </div>
  );
}

export default FeedbackForm;
