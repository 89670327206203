import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./StatsFact.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { debounceWrapper, parseForRegex } from "../../../../utility/helperFun";

import { Tooltip, withStyles } from "@material-ui/core";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { dndDroppable } from "./TalkingPointsSteps/constantForDND";
import CustomHighlighter from "../../../atoms/CustomHighlighter";

import { droppableIds } from "./cruiseConstant";
import ReportContext from "../../Research/ReportContext";
import Button from "../../../atoms/Button/Button";
import { BsEyeFill } from "react-icons/bs";
import { CRUISE_TYPES } from "./useCruiseHook";
import { CruiseContext, CruiseState } from "./StepsWizard";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const LightTooltip1 = withStyles((theme) => ({
    arrow: {
        color: "white",
        scale: 1.5,
    },
    tooltip: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: " 2px 1px 12px rgba(14, 14, 15, 0.23)",
        fontSize: 11,
        maxWidth: "400px",
        position: "relative",
        border: "solid 1px rgba(172, 179, 191, 0.28)",
        borderRadius: "6px",
    },
}))(Tooltip);
let itemIndex = 0;

export function HoverComponent({ item = {} }) {
    return (
        <div className={styles.hoverContainer}>
            <div className={styles.hoverContainer_head}>Source</div>
            <div className={styles.head}>
                <span className={styles.outlineRank}>#{item.rank}</span>
                <div className={styles.outlineTitle}>
                    <a
                        className={styles.url}
                        href={item.url}
                        target="_blank"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {item.url}
                    </a>

                    {item.url_title}
                    <div className={styles.para}>
                        {item?.paragraph?.length ? (
                            <CustomHighlighter
                                highlightClassName={styles.sourceHighlight}
                                searchWords={[
                                    new RegExp(
                                        parseForRegex(item.statistic),
                                        "g",
                                    ),
                                ]}
                                textToHighlight={item.paragraph}
                            />
                        ) : (
                            "No Text Found"
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const StatsFact = React.memo(({ data, stepsData = {} }) => {
    const [loading, setLoading] = useState(true);
    const [statsData, setStatsData] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [search, setSearch] = useState("");
    const [tooltipIndex, setTooltipIndex] = useState(-1);

    const dispatchStepsData = useContext(CruiseContext);
    const { show_stats } = useContext(CruiseState);
    const [showStats, setShowStats] = useState(show_stats);

    useEffect(() => {
        dispatchStepsData({
            type: CRUISE_TYPES.SHOW_STATS,
            show_stats: showStats,
        });
    }, [showStats]);

    useEffect(() => {
        setStatsData(data);
    }, [data]);

    useEffect(() => {
        if (!search.length) {
            setStatsData(data);
        }
        if (search.length) {
            if (search.length) {
                const searchRegEx = new RegExp(
                    parseForRegex(search)
                        .toLocaleLowerCase()
                        .split(" ")
                        .join("|"),
                    "g",
                );
                setStatsData(() => {
                    const temp = data.filter((stat) => {
                        if (
                            stat.statistic.toLowerCase().match(searchRegEx)
                                ?.length > 0
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    return temp;
                });
            }
        }
    }, [search.length]);

    const copyToClipboard = useCallback((text) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);
    const { mixpanelTrack } = useMixpanelHook();
    let timeoutRef = useRef(null);

    return (
        <div className={styles.rightContainerStats}>
            {showStats ? (
                <>
                    <div className={styles.statsHeader}>
                        <div className={styles.statsHeader__right}>
                            <SVGIcon
                                src={"/New UI/SVG/stats_icons.svg"}
                                size={25}
                                color={"#1F4DBF"}
                            />
                            <div className={styles.stats__title}>
                                SERP Statistics
                            </div>

                            <LightTooltip
                                arrow
                                title={
                                    "Statistics are extracted from the top ranking URLs. Insert them within your blog to increase the credibility quotient of your content."
                                }
                            >
                                <div>
                                    <SVGIcon
                                        src={"/New UI/SVG/info.svg"}
                                        size={18}
                                        color={"#2196f3"}
                                    />
                                </div>
                            </LightTooltip>
                        </div>
                        <Textfield
                            customClass={styles.searchOutlines}
                            height={32}
                            label={""}
                            placeholder={"Search Statistics"}
                            containerStyle={{
                                padding: "0px",
                                margin: "0px",
                                background: "white",
                                width: "40%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            props={{
                                onChange: (e) => {
                                    const value = e.target.value.trim();
                                    debounceWrapper(
                                        () => setSearch(value),
                                        500,
                                        timeoutRef,
                                    );
                                },
                            }}
                            Icon={() => (
                                <SVGIcon
                                    src={"/New UI/SVG/AiOutlineSearch.svg"}
                                    size={14}
                                    style={{ color: "#c9e3ff" }}
                                />
                            )}
                            iconStyle={{
                                left: "10px",
                                color: "#c9e3ff",
                                width: "min-content",
                            }}
                        />
                    </div>

                    <Droppable
                        isDropDisabled={true}
                        droppableId={droppableIds.stats}
                    >
                        {(provided) => {
                            return (
                                <div
                                    {...provided.droppableProps}
                                    className={styles.statsBody}
                                    ref={provided.innerRef}
                                >
                                    {statsData.length ? (
                                        statsData.map((statItem, j) => (
                                            <Stat
                                                {...{
                                                    statItem,

                                                    search,
                                                    copyToClipboard,
                                                    tooltipIndex,
                                                    setTooltipIndex,
                                                    j,
                                                }}
                                                key={statItem.id}
                                            />
                                        ))
                                    ) : (
                                        <div
                                            style={{
                                                width: "100%",
                                                textAlign: "center",
                                            }}
                                        >
                                            No Statistics Found
                                        </div>
                                    )}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </>
            ) : (
                <>
                    {!stepsData.isLoader ? (
                        <div
                            onClick={() => {
                                setShowStats(true);
                            }}
                            style={{ margin: "auto" }}
                        >
                            <div style={{ width: "200px" }}>
                                <lottie-player
                                    player={"anime"}
                                    id={"anime"}
                                    src={
                                        "https://storage.googleapis.com/scalenut/icons/Writing+Points.json"
                                    }
                                    background="transparent"
                                    speed="1"
                                    autoplay
                                ></lottie-player>
                            </div>
                            To Explore SERP Statistics
                            <div
                                className={styles.exploreStatsCtaWrapper}
                                onClick={() => {
                                    setShowStats(true);
                                    mixpanelTrack("Explore Statistics Clicked");
                                }}
                            >
                                <SVGIcon
                                    src={"/New UI/SVG/add-four.svg"}
                                    color={"#014dc5"}
                                />
                                <bb>Click here</bb>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
});

export default StatsFact;

const Stat = React.memo(
    ({
        statItem,

        search,
        copyToClipboard = () => {},

        j,
    }) => {
        console.log("****stat");
        const [tooltip, setTooltip] = useState(false);
        return (
            <Draggable
                key={statItem.id}
                draggableId={statItem.id + ""}
                index={statItem.id}
            >
                {(provided, snap) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                            ...provided.draggableProps?.style,

                            backgroundColor: snap.isDragging
                                ? "rgba(232, 237, 248, 0.9)"
                                : "white",
                        }}
                    >
                        <LightTooltip1
                            placement={"left"}
                            interactive
                            arrow={true}
                            title={<HoverComponent item={statItem} />}
                            open={tooltip}
                        >
                            <div
                                className={styles.statRow}
                                onClick={() =>
                                    copyToClipboard(statItem.statistic)
                                }
                            >
                                <span className={styles.headingType}>
                                    <SVGIcon
                                        size={18}
                                        src={"/New UI/SVG/RxDotFilled.svg"}
                                    />
                                    <div
                                        className={styles.row}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <SVGIcon
                                            src={"/New UI/SVG/drag.svg"}
                                            size={16}
                                            style={{
                                                cursor: "drag",
                                            }}
                                            color={"lightgray"}
                                        />
                                    </div>
                                </span>
                                <span
                                    style={{
                                        width: "90%",
                                    }}
                                >
                                    <CustomHighlighter
                                        highlightClassName={
                                            search
                                                ? styles.highlightOutlineSearch
                                                : styles.highlightOutlineKeyTerm
                                        }
                                        searchWords={[
                                            new RegExp(
                                                parseForRegex(search)
                                                    .split(" ")
                                                    .sort(
                                                        (a, b) =>
                                                            b.length - a.length,
                                                    )
                                                    .join("|"),
                                                "g",
                                            ),
                                        ]}
                                        textToHighlight={statItem.statistic}
                                    />
                                </span>
                                <div className={styles.btnContainer}>
                                    <Tooltip
                                        title=""
                                        onOpen={() => setTooltip(true)}
                                        onClose={() => setTooltip(false)}
                                        key={statItem.id}
                                    >
                                        <div className={styles.eyeIcon}>
                                            <BsEyeFill color="var(--primary-blue)" />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </LightTooltip1>
                    </div>
                )}
            </Draggable>
        );
    },
);
