import React, { useEffect } from "react";
import styles from "./TransactionProgress.module.css";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import useHelperFunctions from "../../helperFunctions";
import { TRANSACTION_STATUS } from "../../api.json";
import { useLocation } from "react-router";
import Image from "../Image/Image";
import { useDispatch, useSelector } from "react-redux";
import { socketEvents } from "../../../Sockets/constants";
import { socket } from "../../../Sockets/useSockets";
import { updateUserInfo } from "../../../store/actions/auth";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import TagManager from "react-gtm-module";
function TransactionProgress() {
    const goTo = useNavigation();
    const dispatch = useDispatch();
    const location = useLocation();

    const session_id = new URLSearchParams(location.search).get("session_id");
    const dashboard = new URLSearchParams(location.search).get("dashboard");
    const user_id = useSelector((state) => state.auth.userId);
    const otherInfo = useSelector((state) => state.otherInfo);

    const redirectToPlans = () => {
        setTimeout(() => {
            goTo("BILLING_PLANS", [], "", true);
            dispatch(updateUserInfo("payment_done", true));
            TagManager.dataLayer({
                dataLayer: {
                    event: "plan_purchase_successfull",
                    user_id: user_id,
                    plan_type: otherInfo?.subscriptionInfo?.plan_name,
                },
            });
        }, 4000);
    };

    const redirectToDashboard = () => {
        goTo("DASHBOARD", [], "", true);
    };

    useEffect(() => {
        let timeout;
        let interval;
        const updateUser = (data) => {
            dispatch(
                updateUserInfo(
                    "first_subscription_status",
                    data.subscription_payment_status,
                ),
            );
            // if (dashboard) {
            //     redirectToDashboard();
            // } else {
            //     redirectToPlans();
            // }
            redirectToPlans();
            document?.StripeInvoicePayment?.close();
        };
        if (session_id) {
            timeout = setTimeout(() => {
                // if (dashboard) {
                //     redirectToDashboard();
                // } else {
                //     redirectToPlans();
                // }
                redirectToPlans();
            }, 30000);
        }
        socket.on(socketEvents.USER_FIRST_SUBSCRIPTION, updateUser);
        if (document.StripeInvoicePayment) {
            console.log(document.StripeInvoicePayment);

            interval = setInterval(() => {
                if (document.StripeInvoicePayment?.closed) {
                    redirectToPlans();
                    document.StripeInvoicePayment = null;
                }
            }, 2000);
        } else if (!document.StripeInvoicePayment && !session_id) {
            redirectToPlans();
        }

        return () => {
            socket.off(socketEvents.USER_FIRST_SUBSCRIPTION, updateUser);
            clearTimeout(timeout);
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={styles.main}>
            <img src="/AssetsV2/DualRingLoader.gif" className={styles.img} />
            <div className={styles.text}>Transaction In Progress</div>
        </div>
    );
}

export default TransactionProgress;
