import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./CustomCircularProgress.module.css";

// This converts a fraction of a turn around the unit circle
// to cartesian coordinates representing where that turn would
// end. fractionToRectangular(0) is equal to 1, 0 because the
// origin of the polar coordinate system is on the right side of
// the circle
const fractionToRectangular = (fraction) => {
    const x = Math.cos(2 * Math.PI * fraction);
    const y = Math.sin(2 * Math.PI * fraction);

    return [x, y];
};

// Creates path data for an arc with provided start and end
// coordinates. largeArcFlag controls whether the arc is drawn the "short way"
// or the "long way." More info in createPathDataForProgress.
const createPathData = ({
    start: [startX, startY],
    largeArc,
    end: [endX, endY],
}) => {
    const radius = 1;
    return `M ${startX} ${startY} A ${radius} ${radius} 0 ${
        largeArc ? 1 : 0
    } 1 ${endX} ${endY}`;
};

// Creates path data for a progress fraction in the circular progress bar.
const createPathDataForProgress = ({ fullArcFraction, progress }) => {
    // We calculate the start and end points in terms of a fraction
    // of a turn around the circle, starting from the origin (right side).
    // Because the arc is centered around the top of the circle,
    // we add -1 / 4 because we start -1 / 4 of a turn around the circle.
    const startFraction = -1 / 4 - fullArcFraction / 2;
    const endFraction = startFraction + (progress / 100) * fullArcFraction;

    // Convert the start and end points to rectangular coordinates.
    const start = fractionToRectangular(startFraction);
    const end = fractionToRectangular(endFraction);

    // Determine whether it's a "large arc" or not-
    // because there are multiple arcs that can go through the two points,
    // we must specify whether it's the large or small arc.
    // If the progress amount ends up being smaller than 180° then it is not a large arc.
    const largeArc = endFraction - startFraction >= 0.5;

    // Create path data for the start coordinates, largeArc value, and end coordinates
    return createPathData({ start, largeArc, end });
};

// strokeWidth: the width of the progress bar. This is not measured
// in pixels—it is a fraction of the radius of the circle.
//
// fullArcFraction: the fraction of the circle that the arc would fill
// if progress is 1
//
// progress: a value from 0 to 1 representing the progress towards 100%
const CustomCircularProgress = ({
    strokeWidth,
    fullArcFraction,
    progress,
    height = "40",
    width = "40",
    bgStroke = "#D9D9D9",
    progressStroke = "#284FB7",
    customClasses = "",
}) => {
    return (
        <div className={`${styles.circularProgress_wrapper} ${customClasses}`}>
            <div className={styles.progressValue}>{progress}</div>
            <svg
                id="my-svg"
                width={width}
                height={height}
                // The viewBox for this SVG is manipulated so that we can use
                // the unit circle to calculate positions on the circle, which
                // makes things a lot easier than worrying about the radius.
                // Normally this would give it dimensions of 2x2 units but
                // we've added some extra to account for the stroke width
                viewBox={`${-1 - strokeWidth / 2} ${-1 - strokeWidth / 2} ${
                    2 + strokeWidth
                } ${2 + strokeWidth}`}
                style={{ backgroundColor: "transparent" }}
            >
                <path
                    style={{ stroke: bgStroke }}
                    strokeWidth={`${strokeWidth}px`}
                    d={createPathDataForProgress({
                        fullArcFraction,
                        progress: 100,
                    })}
                    fill="transparent"
                    stroke-linecap="line"
                />
                <path
                    style={{ stroke: progressStroke }}
                    strokeWidth={`${strokeWidth}px`}
                    d={createPathDataForProgress({ fullArcFraction, progress })}
                    fill="transparent"
                    stroke-linecap="line"
                />
            </svg>
        </div>
    );
};

export default CustomCircularProgress;
