import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./ClusterRow.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import Button from "../../../atoms/Button/Button";
import { Link } from "react-router-dom";
import { formatURL, numberWithCommas } from "../../../../utility/helperFun";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useGscHook, {
    CannibalizationKeywordObject,
} from "../../GoogleSearchConsole/useGscHook";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import { RatingsV2 } from "../../../molecules/Creator/Overview/overallRatings";
import CustomCircularProgress from "../../../atoms/CircularProgress/CustomCircularProgress";
import {
    Avatar,
    Checkbox,
    FormControlLabel,
    makeStyles,
} from "@material-ui/core";
import useHelperFunctions from "../../../helperFunctions";
import { JSXElement } from "@babel/types";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { clusterMixpanelEvent } from "../clusterMixpanelEvents";
import screens from "../../../../screens.json";
import Morekeywords from "../../../atoms/MoreKeywords/Morekeywords";
import { AvatarGroup } from "@material-ui/lab";
import "./Avatar.css";
import { AiOutlineCopy } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { RiFileCopyLine } from "react-icons/ri";
import { CopyToClipboard } from "../../../atoms/CopyToClipboard/CopyToClipboard";

export interface ClusterKeywordObject {
    keyword: string;
    cluster_id: number;
    competition: any;
    cpc: string;
    id: number;
    is_selected: boolean;
    intent: string[];
    keyword_difficulty: string;
    position: string;
    quality: any;
    similarity: string;
    volume: number;
    wordCount: number;

    "new entry": string;
    competitor_position: {
        competitor: string;
        position: null | number;
    }[];
}
export interface ClusterObject {
    avg_rank: number;
    cd: number;
    cluster_keywords: number;
    ranking_status: string;
    max_intent: string[];
    data: ClusterKeywordObject[];
    id: number;
    is_cluster: boolean;
    kd: string;
    quality: any;
    ranking_keywords: number;
    relevancy: number;
    report_id: number | string;
    failed?: any;
    is_completed?: 0 | 1 | 2 | 3;
    totalCPC: number;
    totalKD: number;
    totalSimilarity: number;
    totalVol: number;
    wordCount: number;
    competitors: any;
    competitor_title: any;
}

const SortKeywordsOptions = [
    { title: "keyword", id: 1 },
    { title: "intent", id: 2 },
    { title: "kd", id: 3 },
    { title: "rel", id: 4 },
    { title: "cpc", id: 5 },
    { title: "vol", id: 6 },
    { title: "comp1", id: 7 }, // scalenut
    { title: "comp2", id: 8 }, // surferseo
    { title: "comp3", id: 9 }, //frase
    { title: "comp4", id: 10 }, //clearscope
];

type Props_ClusterRow = {
    cluster: ClusterObject;
    clusterInfo: any;
    all: boolean;
    setSelectedCluster: Dispatch<SetStateAction<null>>;
    isKDAvailable: boolean;
    isGSCAvailable: boolean;
    isCompetitorAvailable: boolean;
    expandIdx: number;
    setExpandIdx: Dispatch<SetStateAction<number>>;
    index: any;
    isCompetitorGap: boolean;
    _setClusterKeywords: Dispatch<SetStateAction<ClusterObject[]>>;
    showIntent: boolean;
    viewTitles: boolean;
};

type Props_SubCards = {
    cluster: Props_ClusterRow["cluster"];
    all: Props_ClusterRow["all"];
    clusterInfo: Props_ClusterRow["clusterInfo"];
    isCompetitorGap: boolean;
    createMultiKeywordReport: any;
    load: boolean;
};

type Props_SubTableRow = {
    idx: number;
    keyword: ClusterKeywordObject;
    clusterInfo: Props_ClusterRow["clusterInfo"];
    cluster: Props_ClusterRow["cluster"];
    isAllSelected: boolean | undefined;
    isCompetitorGap: boolean;
    setClusterKeywords: React.Dispatch<
        React.SetStateAction<ClusterKeywordObject[] | undefined>
    >;
};

const getIntentTags: (arr: string[]) => ReactNode = (arr = []) => {
    const LTB: any = {
        Informational: {
            tag: "I",
            color: "#1F95D7",
            bg: "rgba(31, 149, 215, 0.20)",
            tooltipText: (
                <>
                    <span style={{ color: "#1F95D7" }}>Informational: </span>
                    Searchers want to find information
                </>
            ),
        },
        Navigational: {
            tag: "N",
            color: "#4CAF50",
            bg: "rgba(140, 224, 126, 0.20)",
            tooltipText: (
                <>
                    <span style={{ color: "#4CAF50" }}>Navigational: </span>
                    Searchers want to find a specific site or page
                </>
            ),
        },
        Commercial: {
            tag: "C",
            color: "#CBB708",
            bg: "rgba(255, 247, 177, 0.40)",
            tooltipText: (
                <>
                    {" "}
                    <span style={{ color: "#CBB708" }}>Commercial: </span>
                    Searchers want to investigate brands, products, or services
                </>
            ),
        },
        Transactional: {
            tag: "T",
            color: "#ED8031",
            bg: "rgba(237, 128, 49, 0.20)",
            tooltipText: (
                <>
                    <span style={{ color: "#ED8031" }}>Transactional: </span>
                    Searchers want to complete an action (e.g., make a purchase)
                </>
            ),
        },
    };
    let tagRef = null;
    return (tagRef = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
            }}
        >
            {arr.map((intentType) => {
                if (!intentType || intentType.trim() === "") {
                    return <>-</>;
                }
                let tg = LTB[intentType.trim()] || {};

                return (
                    tg && (
                        <LightTooltip
                            title={tg?.tooltipText}
                            placement={"top"}
                            arrow
                        >
                            <div
                                style={{
                                    color: tg?.color,
                                    background: tg?.bg,
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    padding: "3px 6.2px",
                                    minWidth: "22px",
                                    borderRadius: "4px",
                                    textAlign: "center",
                                }}
                            >
                                {tg?.tag}
                            </div>
                        </LightTooltip>
                    )
                );
            })}
        </div>
    ));

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        ></div>
    );
};

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: 10,
        height: 10,
        margin: "9px",
        marginRight: "9px",
        // color: "#b0b8c3",
        transform: "scale(.8)",
        zIndex: 0,
    },
}));

const labelCustom = makeStyles((theme) => ({
    root: {
        color: "#2a4266",
    },
}));

const avatarStyles = makeStyles((theme) => ({
    root: {
        height: "26px !important",
        width: "26px !important",
        fontSize: "10px !important",
        border: "1px solid #fafafa !important",
    },
}));

const SubTableRow = ({
    idx,
    keyword,
    clusterInfo,
    cluster,
    isAllSelected = true,
    isCompetitorGap = false,
    setClusterKeywords,
}: Props_SubTableRow) => {
    const [loading, setLoading] = useState(false);

    const checkBoxClasses = checkBoxCustom();
    const labelClasses = labelCustom();
    const { postData } = useHelperFunctions();
    const { getKDColorTooltip } = useGscHook();
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        let temp: ClusterKeywordObject[] = [];
        cluster.data.forEach((item) => {
            temp.push(item);
        });

        setClusterKeywords(temp);
    }, [cluster.data]);

    const saveChecked: (id: number, is_selected: boolean) => void = async (
        id,
        is_selected,
    ) => {
        let payload = {
            id: id,
            is_selected,
        };
        const res = await postData({ url: "/api/clustering/update", payload });
        if (res.status == 200) {
        }
    };

    const handleCheckboxes: (
        item: ClusterKeywordObject,
        checked: boolean,
    ) => void = (item, checked) => {
        let temp = [...cluster.data];

        let data = [];
        if (!checked) {
            data = temp.filter((obj) => {
                if (obj.keyword == item.keyword) {
                    obj.is_selected = false;
                }
                return obj;
            });
        } else {
            data = temp.filter((obj) => {
                if (obj.keyword == item.keyword) obj.is_selected = true;

                return obj;
            });
        }
        console.log("selection data", data);
        setClusterKeywords(data);
    };

    return (
        <div
            className={styles.keywordsRowWrapper}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className={styles.keywordsRow_keyword}>
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={
                        idx == 0
                            ? "Primary keywords cannot be unchecked. You need to include it to create article"
                            : ""
                    }
                >
                    <FormControlLabel
                        style={{
                            color: "#2a4266",
                        }}
                        control={
                            <Checkbox
                                color={"default"}
                                disabled={!!cluster?.report_id || loading}
                                checked={
                                    (!!keyword?.is_selected && isAllSelected) ||
                                    idx == 0
                                }
                                classes={checkBoxClasses}
                                style={{
                                    pointerEvents: "auto",
                                    cursor:
                                        idx == 0 ||
                                        !!cluster?.report_id ||
                                        loading
                                            ? "not-allowed"
                                            : "pointer",
                                    opacity:
                                        idx == 0 || !!cluster?.report_id
                                            ? 0.5
                                            : 1,
                                }}
                                value={keyword?.keyword}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    if (idx == 0) {
                                        return;
                                    }
                                    saveChecked(keyword.id, e.target.checked);
                                    handleCheckboxes(keyword, e.target.checked);
                                }}
                                name="checkedB"
                            />
                        }
                        label={
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#2a4266",
                                }}
                            >
                                <div style={{ fontSize: "14px" }}>
                                    {keyword.keyword}
                                </div>
                                {idx == 0 && (
                                    <div className={styles.primary}>
                                        Primary
                                    </div>
                                )}
                            </div>
                        }
                    />
                </LightTooltip>

                <div className={styles.copyIconContainer}>
                    <CopyToClipboard textToCopy={keyword?.keyword} />
                </div>
            </div>
            <div className={styles.keywordsRow_intent}>
                {keyword.intent?.length > 0
                    ? getIntentTags(keyword?.intent || [])
                    : "-"}
            </div>

            {clusterInfo.is_excel_sheet !== 1 && (
                <div className={styles.keywordsRow_rel}>
                    {keyword?.similarity != null ? (
                        <CustomCircularProgress
                            strokeWidth={0.2}
                            fullArcFraction={5 / 7}
                            progress={parseInt(keyword?.similarity)}
                            width={"35"}
                            height={"35"}
                            customClasses={styles.similarity}
                        />
                    ) : (
                        "-"
                    )}
                </div>
            )}
            <div className={styles.keywordsRow_cpc}>
                {keyword?.cpc != undefined
                    ? parseFloat(keyword?.cpc).toFixed(2)
                    : "-"}
            </div>
            <div className={styles.keywordsRow_vol}>
                {cluster.totalVol != 0 ? numberWithCommas(keyword.volume) : "-"}
            </div>
            <div className={styles.keywordsRow_kd}>
                {keyword?.keyword_difficulty != null ? (
                    <LightTooltip
                        arrow
                        title={
                            getKDColorTooltip(+keyword?.keyword_difficulty)
                                ?.tooltip
                        }
                        placement={"bottom"}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    width: "6px",
                                    height: "6px",
                                    backgroundColor: getKDColorTooltip(
                                        +keyword?.keyword_difficulty,
                                    ).color,
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                }}
                            ></div>
                            <span
                                style={{ minWidth: "24px", textAlign: "right" }}
                            >
                                {keyword?.keyword_difficulty != null
                                    ? parseInt(keyword?.keyword_difficulty)
                                    : "-"}
                            </span>
                        </div>
                    </LightTooltip>
                ) : (
                    <>-</>
                )}
            </div>

            {isCompetitorGap ? (
                <div
                    className={styles.domainsDataRowWrapper}
                    style={{
                        width: `${
                            clusterInfo.domain_name.length
                                ? 8 +
                                  (clusterInfo?.competitor_domain?.length ||
                                      0) *
                                      8
                                : clusterInfo.competitor_domain.length > 1
                                ? clusterInfo.competitor_domain.length * 8
                                : 20
                        }%`,
                    }}
                >
                    {clusterInfo?.domain_name && (
                        <div className={styles.keywordsRow_competitor}>
                            {keyword.position || "-"}
                        </div>
                    )}

                    {clusterInfo?.competitor_domain?.length >= 1 && (
                        <div className={styles.keywordsRow_competitor}>
                            {keyword.competitor_position[0]?.position || "-"}
                        </div>
                    )}
                    {clusterInfo?.competitor_domain?.length >= 2 && (
                        <div className={styles.keywordsRow_competitor}>
                            {keyword.competitor_position[1]?.position || "-"}
                        </div>
                    )}
                    {clusterInfo?.competitor_domain?.length >= 3 && (
                        <div className={styles.keywordsRow_competitor}>
                            {keyword.competitor_position[2]?.position || "-"}
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

const SubCards = ({
    cluster,
    all,
    clusterInfo,
    isCompetitorGap,
    createMultiKeywordReport,
    load,
}: Props_SubCards) => {
    const [order, setOrder] = useState<0 | 1>(1);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(true);
    const [clusterKeywords, setClusterKeywords] =
        useState<ClusterKeywordObject[]>();
    const checkBoxClasses = checkBoxCustom();
    const [sortBy, setSortBy] = useState<{
        id: number;
        title: string;
    }>(SortKeywordsOptions[5]);
    const { getTrimmedDomainURL } = useGscHook();
    useEffect(() => {
        let count = 0;
        clusterKeywords?.map((kywrd) => {
            if (kywrd?.is_selected) {
                count = count + 1;
                console.log(count);
            }
        });
        setIsAllSelected(true);
        setSelectedCount(count);
    }, [clusterKeywords]);

    useEffect(() => {
        console.log("selectedCount", selectedCount);
    }, [selectedCount]);

    const sortKeywords: (
        a: ClusterKeywordObject,
        b: ClusterKeywordObject,
    ) => number = (a, b) => {
        return 1;
        if (order == 1) {
            const temp = b;
            b = a;
            a = temp;
        }
        if (sortBy.id == SortKeywordsOptions[0].id) {
            return a.keyword.localeCompare(b.keyword);
        } else if (sortBy.id == SortKeywordsOptions[1].id) {
            return a.keyword.localeCompare(b.keyword);
        } else if (sortBy.id == SortKeywordsOptions[2].id) {
            return +a.keyword_difficulty - +b.keyword_difficulty;
        } else if (sortBy.id == SortKeywordsOptions[3].id) {
            return +a?.similarity - +b?.similarity;
        } else if (sortBy.id == SortKeywordsOptions[4].id) {
            return +a?.cpc - +b?.cpc;
        } else if (sortBy.id == SortKeywordsOptions[5].id) {
            return +a?.volume - +b?.volume;
        } else {
            return +a?.volume - +b?.volume;
        }
    };

    const handleColumnSort: (index: number) => void = (index) => {
        setSortBy((ps) => {
            if (ps?.id == SortKeywordsOptions[index].id) {
                setOrder((ps) => (ps == 0 ? 1 : 0));
                return SortKeywordsOptions[index];
            } else {
                setOrder(1);
                return SortKeywordsOptions[index];
            }
        });
    };
    return (
        <div
            className={styles.subCardsWrapper}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className={styles.keywordTable}>
                <div className={styles.keywordTableRows}>
                    <div className={styles.subTableTopBar}>
                        <div></div>
                        {isCompetitorGap && (
                            <div
                                className={styles.subTableTopBar_right}
                                style={{
                                    width: `${
                                        clusterInfo.domain_name.length
                                            ? 8 +
                                              (clusterInfo?.competitor_domain
                                                  ?.length || 0) *
                                                  8
                                            : clusterInfo.competitor_domain
                                                  .length > 1
                                            ? clusterInfo.competitor_domain
                                                  .length * 8
                                            : 20
                                    }%`,
                                }}
                            >
                                Domain Positions
                            </div>
                        )}
                    </div>
                    <div className={styles.keywordsHeadWrapper}>
                        <div
                            className={`${styles.keywordsHead_keyword} ${
                                sortBy.id == SortKeywordsOptions[0].id &&
                                styles.enable
                            }`}
                        >
                            {/* <FormControlLabel
                            style={{
                                color: isAllSelected ? "#2a4166" : "#b0b8c3",
                            }}
                            control={
                                <Checkbox
                                    color={"default"}
                                    checked={isAllSelected}
                                    classes={checkBoxClasses}
                                    // value={isAllSelected}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setIsAllSelected(!isAllSelected);
                                    }}
                                    name="checkedB"
                                />
                            }
                            label={ */}
                            <span
                                className={styles.keywordsRow_keyword}
                                style={{
                                    // marginRight: "-10px",
                                    border: "none",
                                    marginLeft: "0px",
                                }}
                            >
                                All Keywords ({cluster?.data?.length})
                            </span>
                            {/* }
                        /> */}
                        </div>
                        <div
                            className={`${styles.keywordsHead_intent} ${
                                sortBy.id == SortKeywordsOptions[1].id &&
                                styles.enable
                            }`}
                            onClick={() => {
                                handleColumnSort(1);
                            }}
                        >
                            Intent
                            {/* <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortKeywordsOptions[1].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            /> */}
                        </div>

                        {clusterInfo.is_excel_sheet !== 1 && (
                            <div
                                className={`${styles.keywordsHead_rel} ${
                                    sortBy.id == SortKeywordsOptions[3].id &&
                                    styles.enable
                                }`}
                                onClick={() => {
                                    handleColumnSort(3);
                                }}
                            >
                                Relevance
                                {/* <SVGIcon
                            size={20}
                            style={{
                                cursor: "pointer",
                                color: "#2c3948c2",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                            }}
                            src={`/New UI/SVG/sort${
                                order == 0 &&
                                SortKeywordsOptions[3].id == sortBy.id
                                    ? "A"
                                    : "D"
                            }.svg`}
                        /> */}
                            </div>
                        )}
                        <div
                            className={`${styles.keywordsHead_cpc} ${
                                sortBy.id == SortKeywordsOptions[4].id &&
                                styles.enable
                            }`}
                            onClick={() => {
                                handleColumnSort(4);
                            }}
                        >
                            CPC
                            {/* <SVGIcon
                            size={20}
                            style={{
                                cursor: "pointer",
                                color: "#2c3948c2",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                            }}
                            src={`/New UI/SVG/sort${
                                order == 0 &&
                                SortKeywordsOptions[4].id == sortBy.id
                                    ? "A"
                                    : "D"
                            }.svg`}
                        /> */}
                        </div>
                        <div
                            className={`${styles.keywordsHead_vol} ${
                                sortBy.id == SortKeywordsOptions[5].id &&
                                styles.enable
                            }`}
                            onClick={() => {
                                handleColumnSort(5);
                            }}
                        >
                            Search Volume
                            {/* <SVGIcon
                            size={20}
                            style={{
                                cursor: "pointer",
                                color: "#2c3948c2",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                            }}
                            src={`/New UI/SVG/sort${
                                order == 0 &&
                                SortKeywordsOptions[5].id == sortBy.id
                                    ? "A"
                                    : "D"
                            }.svg`}
                        /> */}
                        </div>
                        <div
                            className={`${styles.keywordsHead_kd} ${
                                sortBy.id == SortKeywordsOptions[2].id &&
                                styles.enable
                            }`}
                            onClick={() => {
                                handleColumnSort(2);
                            }}
                        >
                            KD
                            {/* <SVGIcon
                            size={20}
                            style={{
                                cursor: "pointer",
                                color: "#2c3948c2",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                            }}
                            src={`/New UI/SVG/sort${
                                order == 0 &&
                                SortKeywordsOptions[2].id == sortBy.id
                                    ? "A"
                                    : "D"
                            }.svg`}
                        /> */}
                        </div>
                        {/* Competitor & Domains Data */}
                        {isCompetitorGap ? (
                            <div
                                className={styles.domainsDataHeadWrapper}
                                style={{
                                    width: `${
                                        clusterInfo.domain_name.length
                                            ? 8 +
                                              (clusterInfo?.competitor_domain
                                                  ?.length || 0) *
                                                  8
                                            : clusterInfo.competitor_domain
                                                  .length > 1
                                            ? clusterInfo.competitor_domain
                                                  .length * 8
                                            : 20
                                    }%`,
                                }}
                            >
                                {clusterInfo?.domain_name && (
                                    <div
                                        className={
                                            styles.keywordsHead_competitor
                                        }
                                    >
                                        <LightTooltip
                                            placement={"top"}
                                            arrow
                                            title={
                                                <div
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                    onClick={() => {
                                                        window.navigator.clipboard.writeText(
                                                            formatURL(
                                                                getTrimmedDomainURL(
                                                                    clusterInfo?.domain_name,
                                                                ),
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    {formatURL(
                                                        getTrimmedDomainURL(
                                                            clusterInfo?.domain_name,
                                                        ),
                                                    )}
                                                </div>
                                            }
                                            interactive
                                        >
                                            <span>
                                                {
                                                    getTrimmedDomainURL(
                                                        clusterInfo?.domain_name,
                                                    )?.split(".")[0]
                                                }
                                            </span>
                                        </LightTooltip>
                                    </div>
                                )}
                                {clusterInfo?.competitor_domain?.length >= 0 &&
                                    clusterInfo?.competitor_domain[0] && (
                                        <div
                                            className={
                                                styles.keywordsHead_competitor
                                            }
                                        >
                                            <LightTooltip
                                                placement={"top"}
                                                arrow
                                                title={
                                                    <div
                                                        style={{
                                                            cursor: "default",
                                                        }}
                                                        onClick={() => {
                                                            window.navigator.clipboard.writeText(
                                                                formatURL(
                                                                    getTrimmedDomainURL(
                                                                        clusterInfo
                                                                            ?.competitor_domain[0],
                                                                    ),
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        {formatURL(
                                                            getTrimmedDomainURL(
                                                                clusterInfo
                                                                    ?.competitor_domain[0],
                                                            ),
                                                        )}
                                                    </div>
                                                }
                                                interactive
                                            >
                                                <span>
                                                    {
                                                        getTrimmedDomainURL(
                                                            clusterInfo
                                                                ?.competitor_domain[0],
                                                        )?.split(".")[0]
                                                    }
                                                </span>
                                            </LightTooltip>
                                        </div>
                                    )}
                                {clusterInfo?.competitor_domain?.length >= 1 &&
                                    clusterInfo?.competitor_domain[1] && (
                                        <div
                                            className={
                                                styles.keywordsHead_competitor
                                            }
                                        >
                                            <LightTooltip
                                                placement={"top"}
                                                arrow
                                                title={
                                                    <div
                                                        style={{
                                                            cursor: "default",
                                                        }}
                                                        onClick={() => {
                                                            window.navigator.clipboard.writeText(
                                                                formatURL(
                                                                    getTrimmedDomainURL(
                                                                        clusterInfo
                                                                            ?.competitor_domain[1],
                                                                    ),
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        {formatURL(
                                                            getTrimmedDomainURL(
                                                                clusterInfo
                                                                    ?.competitor_domain[1],
                                                            ),
                                                        )}
                                                    </div>
                                                }
                                                interactive
                                            >
                                                <span>
                                                    {
                                                        getTrimmedDomainURL(
                                                            clusterInfo
                                                                ?.competitor_domain[1],
                                                        )?.split(".")[0]
                                                    }
                                                </span>
                                            </LightTooltip>
                                        </div>
                                    )}
                                {clusterInfo?.competitor_domain?.length >= 2 &&
                                    clusterInfo?.competitor_domain[2] && (
                                        <div
                                            className={
                                                styles.keywordsHead_competitor
                                            }
                                        >
                                            <LightTooltip
                                                placement={"top"}
                                                arrow
                                                title={
                                                    <div
                                                        style={{
                                                            cursor: "default",
                                                        }}
                                                        onClick={() => {
                                                            window.navigator.clipboard.writeText(
                                                                formatURL(
                                                                    getTrimmedDomainURL(
                                                                        clusterInfo
                                                                            ?.competitor_domain[2],
                                                                    ),
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        {formatURL(
                                                            getTrimmedDomainURL(
                                                                clusterInfo
                                                                    ?.competitor_domain[2],
                                                            ),
                                                        )}
                                                    </div>
                                                }
                                                interactive
                                            >
                                                <span>
                                                    {
                                                        getTrimmedDomainURL(
                                                            clusterInfo
                                                                ?.competitor_domain[2],
                                                        )?.split(".")[0]
                                                    }
                                                </span>
                                            </LightTooltip>
                                        </div>
                                    )}
                            </div>
                        ) : null}
                    </div>

                    {cluster.data
                        .sort(sortKeywords)
                        .map((keyword: any, idx: number) => {
                            return (
                                <SubTableRow
                                    idx={idx}
                                    keyword={keyword}
                                    clusterInfo={clusterInfo}
                                    cluster={cluster}
                                    isAllSelected={isAllSelected}
                                    isCompetitorGap={isCompetitorGap}
                                    setClusterKeywords={setClusterKeywords}
                                />
                            );
                        })}
                </div>
                <div className={styles.bottomStats}>
                    {cluster?.report_id != null && cluster?.report_id != 0 && (
                        <div className={styles.bottomStats_left}>
                            <RatingsV2
                                addSeparator={":"}
                                quality={cluster?.quality}
                                wordCount={cluster?.wordCount}
                                style={{
                                    background: "none",
                                    boxShadow: "none",
                                    color: "#6B6B6B",
                                    gap: "100px",
                                }}
                                seoScoreStyle={{
                                    backgroundColor: "none",
                                    marginLeft: "-13px",
                                }}
                                wordCountStyle={{
                                    marginLeft: "0px",
                                }}
                                highlightScore={false}
                            />
                        </div>
                    )}
                    <div className={styles.bottomStats_right}>
                        <div>
                            Selected {selectedCount}/{cluster.data.length}{" "}
                            keywords
                        </div>{" "}
                        {!cluster.report_id ||
                        cluster?.is_completed == 0 ||
                        load ? (
                            <Button
                                // loading={load}
                                text={
                                    load
                                        ? "Creating SEO Article..."
                                        : "Create SEO Article"
                                }
                                height={"34px"}
                                width={"160px"}
                                borderRadius={2}
                                handler={
                                    clusterInfo?.is_sample
                                        ? () => {}
                                        : () => {
                                              createMultiKeywordReport(
                                                  clusterKeywords,
                                              );
                                          }
                                }
                                disabled={
                                    !cluster.data.filter(
                                        (edx: any) => edx.is_selected,
                                    ).length || load
                                }
                                loadingText={load}
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    backgroundColor: " var(--primary-blue)",
                                    color: "#f6f7fb",
                                    border: "solid 1px #e9ecf4",
                                    borderRadius: 3,
                                }}
                                iconReverse
                                Icon={() =>
                                    !load ? (
                                        <SVGIcon
                                            size={"16"}
                                            src={
                                                "/New UI/SVG/create_seo_doc.svg"
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )
                                }
                                lessDots={true}
                            />
                        ) : (
                            <>
                                <Link
                                    target={"_blank"}
                                    className={styles.openLink}
                                    to={
                                        screens.SEO_EDITOR +
                                        "/" +
                                        cluster.report_id
                                    }
                                >
                                    <SVGIcon
                                        size={"13"}
                                        style={{
                                            color: "var(--primary-blue)",
                                        }}
                                        src={"/New UI/SVG/Open-Article.svg"}
                                    />
                                    &nbsp; Open Article
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ClusterRow = ({
    cluster,
    clusterInfo,
    all,
    _setClusterKeywords,
    setSelectedCluster,
    isKDAvailable,
    isGSCAvailable,
    isCompetitorAvailable,
    expandIdx,
    setExpandIdx,
    index,
    isCompetitorGap = false,
    showIntent = false,
    viewTitles,
}: Props_ClusterRow) => {
    const [load, setLoad] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const { getKDColorTooltip } = useGscHook();
    const goTo = useNavigation();
    const rowRef = useRef<HTMLDivElement>(null);
    const { mixpanelTrack } = useMixpanelHook();
    const { postData } = useHelperFunctions();
    const avatarClasses = avatarStyles();
    const getRankingStatusClass: (type: string) => string = (type) => {
        if (type?.toLowerCase() == "untapped") {
            return styles.untapped;
        } else if (type?.toLowerCase() == "missing") {
            return styles.missing;
        } else if (type?.toLowerCase() == "ranked") {
            return styles.ranked;
        } else if (type?.toLowerCase() == "unique") {
            return styles.unique;
        } else if (type?.toLowerCase() == "shared") {
            return styles.shared;
        } else {
            return styles.default;
        }
    };
    useEffect(() => {
        if (cluster?.report_id && cluster.is_completed == 0) {
            // @ts-ignore
            setLoad(true);
        } else {
            if (cluster.report_id == 0) {
                return;
            } else {
                setLoad(false);
            }
        }
    }, [cluster]);

    useEffect(() => {
        if (cluster.report_id == 0) {
            setLoad(true);
        }
    }, [cluster.report_id]);

    const handleExpand: (idx: number) => void = (idx) => {
        setExpandIdx((ps) => (ps == idx ? -1 : idx));
        if (expandIdx != idx && rowRef?.current) {
            setTimeout(() => {
                rowRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                });
            }, 200);
        }
    };

    const createMultiKeywordReport = async (
        keywords: ClusterKeywordObject[],
    ) => {
        setLoad(true);

        let temp: string[] = [];
        keywords.forEach((item) => {
            if (item.is_selected) temp.push(item.keyword);
        });
        mixpanelTrack(clusterMixpanelEvent.CREATE_SEO_ARTICLE, {
            "Total Keywords": keywords?.length,
            "Selected Keywords": temp?.length,
            TSV: cluster.totalVol,
            "Average KD": cluster.kd,
        });
        const res = await postData({
            url: "/api/keywords/create",
            payload: {
                keyword: temp,
                country: clusterInfo.search_country,
                cluster_id: cluster.id,
                isKeywordPlanner: 0,
            },
        });

        if (res.status != 200) {
            setLoad(false);
        } else if (res.status == 200) {
            // @ts-ignore
            console.log("createa", res.data);
            let loadingClusterId = cluster.id;
            _setClusterKeywords((ps) => {
                const arr = [...ps];

                const i = arr.findIndex(
                    // @ts-ignore
                    (cluster) => cluster.id == loadingClusterId,
                );

                arr[i] = {
                    ...arr[i],
                    report_id: res.data?.report_id || null,
                    is_completed: 0,
                    failed: false,
                };

                return arr;
            });
        }
    };

    return (
        <div
            ref={rowRef}
            className={`${styles.clusterRowWrapper} ${
                expandIdx == index && styles.expandedClusterRow
            }`}
            onClick={() => {
                handleExpand(index);
            }}
        >
            <div className={styles.clusterRow}>
                <div className={styles.clusterRow_keyword}>
                    <div className={styles.mainKeyword}>
                        {cluster.data[0]?.keyword}
                        {viewTitles &&
                            cluster.data.map((data) => data.keyword)?.length -
                                1 >
                                0 && (
                                <span>
                                    <Morekeywords
                                        placement={"right-end"}
                                        keywords={cluster.data.map(
                                            (data) => data.keyword,
                                        )}
                                        moreCount={
                                            cluster.data.map(
                                                (data) => data.keyword,
                                            )?.length - 1
                                        }
                                    />
                                </span>
                            )}
                    </div>
                    {viewTitles ? (
                        <LightTooltip
                            arrow
                            title={cluster.competitor_title || ""}
                        >
                            <div
                                style={{
                                    // background: "#ACB3BF1A",
                                    borderRadius: "3px",
                                    padding: "1px 0px",
                                    fontWeight: 400,
                                    marginTop: "5px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "350px",
                                    overflow: "hidden",
                                }}
                                className={styles.subKeywords}
                            >
                                {cluster.competitor_title || "-"}
                            </div>
                        </LightTooltip>
                    ) : (
                        <div className={styles.subKeywords}>
                            <span>
                                {cluster.data.length >= 2 &&
                                    cluster.data[1].keyword}
                            </span>
                            <span>
                                {cluster.data.length >= 3 &&
                                    ", " + cluster.data[2].keyword}
                            </span>
                            <span>
                                {cluster.data.length >= 4 && (
                                    <>
                                        {" "}
                                        <Morekeywords
                                            placement={"right-end"}
                                            keywords={cluster.data.map(
                                                (data) => data.keyword,
                                            )}
                                            moreCount={
                                                cluster.data
                                                    .slice(3)
                                                    .map((data) => data.keyword)
                                                    ?.length
                                            }
                                        />
                                    </>
                                )}
                            </span>
                        </div>
                    )}

                    {/* <div className={styles.subKeywords}>
                        <span>
                            {cluster.data.length >= 2 &&
                                cluster.data[1].keyword}
                        </span>
                        <span>
                            {cluster.data.length >= 3 &&
                                ", " + cluster.data[2].keyword}
                        </span>

                        <span>
                            {cluster.data.length >= 4 && (
                                <>
                                    {" "}
                                    <Morekeywords
                                        placement={"right-end"}
                                        keywords={cluster.data.map(
                                            (data) => data.keyword,
                                        )}
                                        moreCount={
                                            cluster.data
                                                .slice(3)
                                                .map((data) => data.keyword)
                                                ?.length
                                        }
                                    />
                                </>
                            )}
                        </span>
                    </div> */}
                </div>
                {isCompetitorGap && (
                    <div className={`${styles.clusterRow_status} `}>
                        <div
                            className={`${
                                styles.keywordStatus
                            } ${getRankingStatusClass(cluster.ranking_status)}`}
                        >
                            {cluster.ranking_status || "-"}
                        </div>
                    </div>
                )}
                {isCompetitorGap && (
                    <div className={styles.clusterRow_ranks}>
                        {cluster.ranking_keywords || 0}
                        {"/"}
                        {cluster.data && cluster.data.length}
                    </div>
                )}

                {showIntent && (
                    <div className={styles.clusterRow_intent}>
                        {cluster?.max_intent?.length > 0
                            ? getIntentTags(cluster?.max_intent)
                            : "-"}
                    </div>
                )}

                <div className={styles.clusterRow_volume}>
                    {numberWithCommas(cluster.totalVol)}
                </div>
                <div className={`${styles.clusterRow_kd}`}>
                    {cluster.kd != null ? (
                        <LightTooltip
                            arrow
                            title={getKDColorTooltip(+cluster.kd)?.tooltip}
                            placement={"bottom"}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "6px",
                                        height: "6px",
                                        backgroundColor: getKDColorTooltip(
                                            +cluster.kd,
                                        ).color,
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                    }}
                                ></div>
                                <span
                                    style={{
                                        minWidth: "24px",
                                        textAlign: "right",
                                    }}
                                >
                                    {cluster.kd != null
                                        ? parseInt(cluster.kd)
                                        : "-"}
                                </span>
                            </div>
                        </LightTooltip>
                    ) : (
                        <>-</>
                    )}
                </div>
                {isCompetitorAvailable &&
                    (cluster?.competitors ? (
                        <LightTooltip
                            placement="left"
                            interactive
                            //@ts-ignore
                            tooltipStyle={{
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 2px 2px 0px #0000000D",
                                border: "1px solid #F1F1F1",
                                borderRadius: "4px",
                            }}
                            title={
                                cluster.competitors ? (
                                    <div
                                        style={{
                                            width: "230px",
                                            background: "#fff",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                                marginTop: "10px",
                                                marginInline: "5px",
                                                fontWeight: 500,
                                                color: "#3f5575",
                                            }}
                                        >
                                            <div>Domain</div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                Coverage
                                                <span
                                                    onMouseOver={() =>
                                                        setShowTooltip(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        setShowTooltip(false)
                                                    }
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <SVGIcon
                                                        size={14}
                                                        style={{
                                                            marginLeft: "2px",
                                                        }}
                                                        src={
                                                            "/New UI/SVG/info.svg"
                                                        }
                                                    />
                                                </span>
                                                {showTooltip && (
                                                    <div
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            bottom: -130,
                                                            left: -20,
                                                            width: "200px",
                                                            background: "#fff",
                                                            borderRadius: "3px",
                                                            border: "1px solid #e1e4ed",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        Percentage of keywords
                                                        on which the domain is
                                                        ranking. Higher
                                                        percentage means that
                                                        domain has covered more
                                                        keywords from the
                                                        cluster.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {cluster.competitors &&
                                            JSON.parse(
                                                cluster?.competitors,
                                            )?.map((item: any) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                        padding: "5px",
                                                        marginBottom: "3px",
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            styles.compLinkWrapper
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "flex-start",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                height: "24px",
                                                                borderRadius:
                                                                    "3px",
                                                                marginRight:
                                                                    "5px",
                                                            }}
                                                            src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${
                                                                new URL(
                                                                    item.url,
                                                                )?.origin
                                                            }&size=48`}
                                                        />
                                                        <a
                                                            className={
                                                                styles.copmLink
                                                            }
                                                            href={item.url}
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                            target="_blank"
                                                        >
                                                            {new URL(item.url)
                                                                ?.host?.length >
                                                            15
                                                                ? new URL(
                                                                      item.url,
                                                                  )?.host.slice(
                                                                      0,
                                                                      13,
                                                                  ) + "..."
                                                                : new URL(
                                                                      item.url,
                                                                  )?.host}
                                                        </a>
                                                        <div
                                                            className={
                                                                styles.linkTooltip
                                                            }
                                                        >
                                                            {item.url}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "#7188AA",
                                                            background:
                                                                "#7188AA1A",
                                                            padding: "3px 8px",
                                                            borderRadius: "3px",
                                                        }}
                                                    >
                                                        {parseInt(
                                                            item.coverage,
                                                        )}
                                                        %
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    ""
                                )
                            }
                        >
                            <div
                                className={`${styles.clusterRow_competition} compAvatars`}
                            >
                                <AvatarGroup
                                    //@ts-ignore
                                    // renderSurplus={(surplus) => (
                                    //     <span>{surplus}</span>
                                    // )}
                                    classes={avatarClasses}
                                    spacing={4}
                                    max={4}
                                >
                                    {cluster?.competitors &&
                                        JSON.parse(cluster?.competitors)
                                            ?.slice(0, 3)
                                            .map((obj: any) => (
                                                <Avatar
                                                    classes={avatarClasses}
                                                    style={{
                                                        background: "#fff",
                                                        width: "26px",
                                                        height: "26px",
                                                        boxShadow:
                                                            "0px 2px 2px 0px #0000001a",
                                                    }}
                                                    src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${
                                                        new URL(obj.url)?.origin
                                                    }&size=48`}
                                                />
                                            ))}
                                </AvatarGroup>
                                {cluster?.competitors && (
                                    <div
                                        style={{
                                            marginLeft:
                                                JSON.parse(cluster?.competitors)
                                                    ?.length == 2
                                                    ? "20px"
                                                    : JSON.parse(
                                                          cluster?.competitors,
                                                      )?.length == 1
                                                    ? "0px"
                                                    : "45px",
                                        }}
                                        className={styles.extraAvatar}
                                    >
                                        ...
                                    </div>
                                )}
                            </div>
                        </LightTooltip>
                    ) : (
                        <div
                            style={{
                                fontSize: "13px",
                                color: "#6b6b6b",
                                fontWeight: 500,
                                justifyContent: "center",
                                paddingRight: 20,
                            }}
                            className={`${styles.clusterRow_competition} compAvatars`}
                        >
                            -
                        </div>
                    ))}
                <div className={styles.clusterRow_progress}>
                    {!cluster.report_id ||
                    cluster?.is_completed == 0 ||
                    load ? (
                        <div
                            className={styles.createCta_parentRow}
                            style={{
                                ...(load && { visibility: "visible" }),
                            }}
                        >
                            {expandIdx != index && (
                                <Button
                                    // loading={load}
                                    text={
                                        load ? "In Progress" : "Create Article"
                                    }
                                    height={"34px"}
                                    width={"130px"}
                                    borderRadius={2}
                                    handler={(e: any) => {
                                        e.stopPropagation();
                                        handleExpand(index);
                                    }}
                                    disabled={
                                        !cluster.data.filter(
                                            (edx: any) => edx.is_selected,
                                        ).length || load
                                    }
                                    loadingText={load}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        backgroundColor:
                                            all && !cluster.report_id
                                                ? "var(--primary-blue)"
                                                : "white",
                                        color:
                                            all && !cluster.report_id
                                                ? "white"
                                                : "var(--primary-blue)",
                                        border: "solid 1px var(--primary-blue)",
                                        borderRadius: 3,
                                    }}
                                    iconReverse
                                    Icon={() =>
                                        load ? (
                                            <SVGIcon
                                                size={"13"}
                                                src={
                                                    "/New UI/SVG/Inprogress.svg"
                                                }
                                                style={{
                                                    color: "white",
                                                }}
                                            />
                                        ) : (
                                            <SVGIcon
                                                size={"12"}
                                                src={"/New UI/SVG/BsPlusLg.svg"}
                                            />
                                        )
                                    }
                                    lessDots={true}
                                />
                            )}
                        </div>
                    ) : (
                        expandIdx != index && (
                            <>
                                <Link
                                    target={"_blank"}
                                    className={styles.openLink}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    to={
                                        screens.SEO_EDITOR +
                                        "/" +
                                        cluster.report_id
                                    }
                                >
                                    <SVGIcon
                                        size={"13"}
                                        style={{
                                            color: "var(--primary-blue)",
                                        }}
                                        src={"/New UI/SVG/Open-Article.svg"}
                                    />
                                    &nbsp; Open Article
                                </Link>
                            </>
                        )
                    )}
                </div>
                <div className={styles.clusterRow_actions}>
                    <LightTooltip
                        title={expandIdx == index ? "Collapse" : "Expand"}
                        placement={"bottom"}
                        arrow
                    >
                        <div
                            className={styles.cta_expand}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                mixpanelTrack(
                                    clusterMixpanelEvent.OPEN_CLUSTER,
                                    {
                                        "Total Keywords":
                                            cluster?.cluster_keywords,
                                        "Cluster ID": cluster?.id,
                                        "Average KD": cluster?.kd,
                                        TSV: cluster.totalVol,
                                    },
                                );
                                setExpandIdx((ps) =>
                                    ps == index ? -1 : index,
                                );
                            }}
                        >
                            <SVGIcon
                                src={`/New UI/SVG/arrow-head-${
                                    expandIdx == index ? "up" : "down"
                                }.svg`}
                                size={13}
                                style={{ color: "inherit" }}
                            />
                        </div>
                    </LightTooltip>
                </div>
            </div>
            {index == expandIdx && (
                <SubCards
                    cluster={cluster}
                    all={all}
                    clusterInfo={clusterInfo}
                    isCompetitorGap={isCompetitorGap}
                    createMultiKeywordReport={createMultiKeywordReport}
                    load={load}
                />
            )}
        </div>
    );
};

export default ClusterRow;
