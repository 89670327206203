import React from "react";
import styles from "../styles.module.css";

const TabPanelHeading = ({ heading, children }) => {
  return (
    <h4 className={styles.tabPanelHeading}>
      {heading}
      {children}
    </h4>
  );
};

export default TabPanelHeading;
