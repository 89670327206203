import React, { useContext, useEffect, useState } from "react";
import styles from "./Placeholder.module.css";
import { CgArrowLeft } from "react-icons/cg";
import SVGIcon from "../../../atoms/SVGIcon";
import useHelperFunctions from "../../../helperFunctions";
import ReportContext from "../../Research/ReportContext";
import { CircularProgress } from "@material-ui/core";
import { BiMenuAltLeft } from "react-icons/bi";
import LoaderGoNuts from "./LoaderGoNuts";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

const Placeholder = ({
    ctaText,
    texts = [],
    setDataText = () => {},
    key,
    setAiGenerated = () => {},
}) => {
    const { reportInfo } = useContext(ReportContext);
    const [activeRow, setActiveRow] = useState(-1);
    const [isRephrasing, setIsRephrasing] = useState(false);
    const { postData } = useHelperFunctions();
    const [isLoader, setIsLoader] = useState(false);
    const { mpTrackCruiseAddition } = useMixpanelHook();
    const [rephrased, setRephrased] = useState([]);

    const [content, setContent] = useState(texts);

    useEffect(() => {
        setRephrased([]);
    }, [activeRow]);

    async function rePhraseSend(text, i) {
        setActiveRow(i);
        setIsRephrasing(true);
        setIsLoader(true);
        const payload = {
            text,
            report_id: reportInfo.report_id,
            title: reportInfo.title,
            useCase: 225, //rephrase case
        };

        const res = await postData({
            url: "/api/generate",
            payload,
        });
        if (res.status == 200) {
            setRephrased([...rephrased, res.data?.output[0]?.content]);
            setIsLoader(false);

            let t = texts;
            if (t[i].rephrased)
                t[i].rephrased.push(res.data?.output[0]?.content);
            else t[i].rephrased = [res.data?.output[0]?.content];
            setAiGenerated(t);
            setContent(t);
            setIsRephrasing(false);
            // content = t
        }
    }

    return (
        <div
            className={`${styles.parent} ${
                reportInfo?.is_premium && styles.premiumParent
            }`}
        >
            {texts?.length ? (
                texts.map((text, i) => {
                    return (
                        <div
                            className={
                                styles.container +
                                " " +
                                (i == activeRow &&
                                    isRephrasing &&
                                    styles.active)
                            }
                        >
                            <LightTooltip
                                title={"Click to Add"}
                                placement={"left"}
                            >
                                <div
                                    onClick={() => {
                                        text?.title
                                            ? setDataText(text?.title)
                                            : setDataText(text?.content);
                                        mpTrackCruiseAddition({
                                            type: "Title",
                                            origin: text?.title
                                                ? "Competition"
                                                : text.type == 1
                                                ? "Factual"
                                                : "Ai",
                                        });
                                    }}
                                    className={styles.dataRow}
                                >
                                    {text?.title ? (
                                        <span className={styles.rank}>
                                            {"#" + text.rank}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    <p
                                        className={
                                            styles.TextContainer +
                                            " " +
                                            (text?.title && styles.cursorText)
                                        }
                                    >
                                        {text?.content
                                            ? text?.content
                                            : text.title}
                                    </p>

                                    <span className={styles.buttonContainer}>
                                        <button
                                            onMouseEnter={(e) =>
                                                e.stopPropagation()
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                rePhraseSend(
                                                    text.title
                                                        ? text.title
                                                        : text?.content,
                                                    i,
                                                );
                                            }}
                                            className={styles.rephraseBtn}
                                        >
                                            {isLoader && activeRow == i ? (
                                                <CircularProgress
                                                    color="#fff"
                                                    size={"10px"}
                                                />
                                            ) : (
                                                "Rephrase"
                                            )}
                                        </button>
                                    </span>
                                </div>
                            </LightTooltip>

                            {/* <h3>{content.rephrased}</h3> */}
                            {/* {text?.title && isRephrasing && activeRow == i &&  */}
                            <div className={styles.rephraseRows}>
                                {/* {content.re} */}
                                {text.rephrased &&
                                    text.rephrased.map((item) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setDataText(item);
                                                    setActiveRow(-1);
                                                    mpTrackCruiseAddition({
                                                        type: "Title",
                                                        origin: "Competition (Rephrased)",
                                                    });
                                                    setIsRephrasing(false);
                                                }}
                                                className={styles.rephraseRow}
                                            >
                                                <BiMenuAltLeft color="#014dc5" />
                                                <input
                                                    type={"text"}
                                                    placeholder={
                                                        "Rephrasing..."
                                                    }
                                                    disabled={true}
                                                    value={item}
                                                    className={styles.noBorder}
                                                />
                                                <button
                                                    disabled={!rephrased}
                                                    className={styles.ctaBtn}
                                                >
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/arrowLeft.svg"
                                                        }
                                                    />
                                                </button>
                                            </div>
                                        );
                                    })}
                            </div>
                            {/* } */}
                        </div>
                    );
                })
            ) : (
                <div className={styles.loader}></div>
            )}
        </div>
    );
};

export default Placeholder;
