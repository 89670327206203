import React, { useContext, useEffect, useState } from "react";
import ProgressBar from "../../../atoms/ProgressBar";
import styles from "./styles.module.css";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRestore } from "react-icons/md";
import ReportContext from "../ReportContext";
import useHelperFunctions from "../../../helperFunctions";
import { parseOutlines } from "../../../../utility/helperFun";
import { Skeleton } from "@material-ui/lab";
import { Checkbox } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const DataRow = ({
    item,
    list,
    setKeyword,
    keyword,
    isDeleting,
    setIsDeleting,
    keytermLoading,
}) => {
    const { setReportData, reportInfo, competetion } =
        useContext(ReportContext);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { mixpanelTrack } = useMixpanelHook();

    const { postData } = useHelperFunctions();

    const deleteKeyterm = async ({ id, term, status }) => {
        if (!status && list.filter((ele) => ele.status).length <= 5) {
            dispatch(
                displayAlert({
                    alertMessage: "minimum 5 key terms are required",
                    alertType: "error",
                }),
            );
            return;
        }
        setLoading(true);
        setIsDeleting(true);
        const res = await postData({
            url: "/api/scrape/unwantedNLP",
            payload: {
                report_id: reportInfo.report_id,
                terms: [{ id: id, term: term, isDeleted: status }],
            },
        });
        setLoading(false);
        setIsDeleting(false);
        if (res.status == 200) {
            // const arr = competetion?.map((item) => ({
            //     ...item,
            //     optimiser_score: res.data?.responseData[item?.url_id],
            //     grade: res.data?.responseData[item?.url_id],
            // }));
            setReportData((ps) => ({
                ...ps,
                relevantTerms: res.data?.relevantTerms,
                // competetion: arr,
            }));
        }
    };

    return (
        <tr
            onClick={() => setKeyword(item)}
            className={`${styles.rowTile} ${
                item.id == keyword.id ? styles.activeRow : ""
            }`}
        >
            <td
                style={{
                    opacity: item.status == 0 || loading ? 0.3 : 1,
                }}
            >
                {loading ? (
                    <Skeleton
                        style={{ background: "#3f5575", marginLeft: "10px" }}
                        width={20}
                        height={14}
                    />
                ) : (
                    <LightTooltip arrow title={isDeleting ? "please wait" : ""}>
                        <Checkbox
                            checked={item.status}
                            onChange={(e) => {
                                if (
                                    isDeleting ||
                                    reportInfo.is_sample ||
                                    keytermLoading
                                ) {
                                    return;
                                }
                                if (e.target.checked) {
                                    deleteKeyterm({
                                        id: item?.id,
                                        term: item?.keyword,
                                        status: 1,
                                    });
                                    mixpanelTrack(
                                        "Key Terms List(Detailed Report)",
                                        {
                                            action: "Checked",
                                        },
                                    );
                                } else {
                                    deleteKeyterm({
                                        id: item?.id,
                                        term: item?.keyword,
                                        status: 0,
                                    });
                                    mixpanelTrack(
                                        "Key Terms List(Detailed Report)",
                                        {
                                            action: "Uncheck",
                                        },
                                    );
                                }
                            }}
                            size="small"
                        />
                    </LightTooltip>
                )}
            </td>
            <td
                style={{
                    opacity: item.status == 0 || loading ? 0.3 : 1,
                    textDecoration: item.status == 0 ? "line-through" : "",
                }}
                className={styles.textData}
            >
                {loading ? (
                    <Skeleton
                        style={{ background: "#3f5575" }}
                        width={50}
                        height={14}
                    />
                ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <span className={styles.mainText}>
                                {item.keyword}
                            </span>
                            <span className={styles.subText}>
                                {item.keyword_variations}
                            </span>
                        </div>
                        {!!item.is_user_added && (
                            <LightTooltip arrow title={"User added Key Term"}>
                                <div className={styles.userKeyterm}>U</div>
                            </LightTooltip>
                        )}
                    </div>
                )}
            </td>
            <td
                style={{ opacity: item.status == 0 || loading ? 0.3 : 1 }}
                className={styles.mainText}
            >
                {loading ? (
                    <Skeleton
                        style={{ background: "#3f5575" }}
                        width={50}
                        height={14}
                    />
                ) : (
                    `${item.min_times}-${item.max_times}`
                )}
            </td>
            <td
                style={{ opacity: item.status == 0 || loading ? 0.3 : 1 }}
                className={styles.mainText}
            >
                <div className={styles.importance}>
                    {loading ? (
                        <Skeleton
                            style={{ background: "#3f5575" }}
                            width={50}
                            height={14}
                        />
                    ) : (
                        <>
                            {item.importance} / 10
                            <ProgressBar
                                className={styles.importanceBar}
                                value={item.importance}
                                total={10}
                            />
                        </>
                    )}
                </div>
            </td>
            <td
                style={{ opacity: item.status == 0 || loading ? 0.3 : 1 }}
                className={`${styles.tag} ${styles.mainText}`}
            >
                {loading ? (
                    <Skeleton
                        style={{ background: "#3f5575" }}
                        width={50}
                        height={14}
                    />
                ) : item.heading_presence > 2 ? (
                    <span className={styles.tagTypeHigh}>
                        <span>HIGH</span>
                    </span>
                ) : item.heading_presence >= 1 ? (
                    <span className={styles.tagTypeLow}>
                        <span>LOW</span>
                    </span>
                ) : (
                    <span className={styles.tagTypeNone}>
                        <span>-</span>
                    </span>
                )}
            </td>
        </tr>
    );
};

export default DataRow;
