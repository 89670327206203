import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    useCallback,
    useMemo,
} from "react";
import { HiOutlineExclamationCircle, HiPencil } from "react-icons/hi";
import Button from "../../atoms/Button/Button";
import styles from "./styles.module.css";
import styles2 from "../GoogleSearchConsole/TrafficAnalyzer.module.css";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ClickAwayListener,
    Input,
    InputAdornment,
    TextField,
    makeStyles,
} from "@material-ui/core";
import {
    BiCheck,
    BiChevronDown,
    BiChevronRight,
    BiChevronUp,
    BiCopy,
    BiPlus,
    BiSearch,
} from "react-icons/bi";
import { VscChevronRight, VscClose, VscDebugDisconnect } from "react-icons/vsc";
import {
    BsArrowLeft,
    BsArrowRight,
    BsCheckCircleFill,
    BsClock,
} from "react-icons/bs";
import SVGIcon from "../../atoms/SVGIcon";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { AccountCircle } from "@material-ui/icons";
import { FiFilter } from "react-icons/fi";
import { GoCheckCircle, GoClock, GoRocket } from "react-icons/go";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import {
    PAGE_LIMIT,
    categories,
    categoryMap,
    dummyData,
    findIssueTags,
    fixActions,
    genScript,
    getRecommendationsFix,
    issueFilterDefault,
    issueLogsFilterDefault,
    issueStatus,
    issueTagsMap,
    publishStatuses,
    sortWithCategory,
    statues,
    tags,
    webtuneEndPoints,
} from "./constants";
import useHelperFunctions from "../../helperFunctions";
import { WebtuneContext } from "./WebTune";
import Progress from "../../atoms/Progress/Progress";
import { IoMdCloseCircle } from "react-icons/io";
import bcrypt from "bcryptjs";
import {
    RiArrowDownSFill,
    RiArrowRightSFill,
    RiDeleteBin6Line,
} from "react-icons/ri";
import { GiPencil } from "react-icons/gi";
import ViewportList from "react-viewport-list";
import { MdClose } from "react-icons/md";
import moment from "moment";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { displayAlert } from "../../../store/actions/alert";
import { useDispatch } from "react-redux";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import { NavigatingText } from "./LoginWebtune";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import Modal from "../../atoms/Modal/Modal";
import Pagination from "../../molecules/Creator/Overview/Pagination";
import LocalPagination from "../../atoms/LocalPagination/LocalPagination";
import TextArea from "../../atoms/TextArea/TextArea";
import Confetti from "react-dom-confetti";
import { debounceWrapper } from "../../../utility/helperFun";
import { Skeleton } from "@material-ui/lab";
import RenderInChucks from "../../atoms/RenderInChucks";
import { v4 } from "uuid";
import useGscHook from "../GoogleSearchConsole/useGscHook";

const config = {
    angle: "180",
    spread: 500,
    startVelocity: 40,
    elementCount: "200",
    dragFriction: "0.07",
    duration: "5000",
    stagger: "2",
    width: "20px",
    height: "20px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const useAccordionCompleteStyle = makeStyles(() => {
    return {
        root: {
            backgroundColor: "none",
            boxShadow: "none",
            padding: 0,
            margin: "0 !important",
            "& .MuiAccordionSummary-root": {
                paddingInline: 0,
                minHeight: 40,
                borderBottom: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: " 0",
            },
            "& .MuiAccordionDetails-root": {
                padding: "10px 20px 10px 20px",
                fontSize: 14,
            },
        },
    };
});
const useAccordionExternalLinkStyle = makeStyles(() => {
    return {
        root: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
            margin: "0 !important",
            "& .MuiAccordionSummary-root": {
                paddingInline: 0,
                minHeight: 40,
                borderBottom: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: " 0",
                display: "block",
                padding: 0,
            },
            "& .MuiAccordionDetails-root": {
                padding: 0,
                display: "block",
            },
        },
    };
});

function WebtuneDashboard({
    setHistoryWindow,
    getIssuesRequestRef,
    getWebtuneDetailsRef,
    getIssuesRequestLoaderRef,
}) {
    const {
        is_verified,
        domain,
        setIsReview,
        isReview,
        setIsDomainConnected,
        last_crawl_date,
        setDomainInfo,
    } = useContext(WebtuneContext);
    const [loading, setLoading] = useState(false);
    const [allIssues, setAllIssues] = useState([]);
    const [totalApprovedCount, setTotalApprovedCount] = useState(0);
    const [filteredIssues, setFilteredIssues] = useState([]);
    const [publishModal, setPublishModal] = useState(0);
    const connectDomainRef = useRef(null);
    const [logoutModal, setLogoutModal] = useState(false);
    const [filters, setFilters] = useState(issueFilterDefault);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);
    const [startConfetti, setStartConfetti] = useState(false);
    const { postData } = useHelperFunctions();

    useEffect(() => {
        if (startConfetti) {
            setTimeout(() => {
                setStartConfetti(false);
            }, 3000);
        }
    }, [startConfetti]);
    const publishFixes = async () => {
        setPublishModal(true);
        setLoading(true);
        await new Promise((res) =>
            setTimeout(() => {
                res();
            }, 3000),
        );
        const res = await postData({
            url: webtuneEndPoints.publish,
            payload: {},
        });
        if (res.status == 200) {
            setLoading(false);
            setStartConfetti(true);
        }
    };

    useEffect(() => {
        if (publishModal === false) {
            getIssuesRequestRef.current && getIssuesRequestRef.current();
            getWebtuneDetailsRef?.current && getWebtuneDetailsRef.current();
            getIssueCount && getIssueCount();
            setIsReview(false);
        }
    }, [publishModal]);

    useEffect(() => {
        getIssuesRequestLoaderRef?.current &&
            getIssuesRequestLoaderRef?.current(true);
        if (isReview) {
            setFilters((ps) => ({
                search: "",
                status: 1,
                categories: [],
                isReview: true,
            }));
        } else {
            setFilters((ps) => ({
                ...issueFilterDefault,
                isReview: false,
                status: [],
            }));
        }
    }, [isReview]);

    const logoutReq = async () => {
        setLogoutLoading(true);
        const res = await postData({
            url: webtuneEndPoints.logout,
            payload: {},
        });
        if (res.status == 200) {
            setDomainInfo((ps) => ({
                ...ps,
                is_verified: 0,
                total_pages: 0,
                is_connected: 0,
            }));
            setIsDomainConnected(false);
            setLogoutModal(false);
        }
        setLogoutLoading(false);
    };

    const getIssueCount = async () => {
        const res = await postData({
            url: webtuneEndPoints.issuesCount,
            payload: {
                status: 0,
            },
        });
        if (res.status == 200) {
            setTotalApprovedCount(+res.data.issue_count || 0);
        }
    };

    return (
        <div
            //     onScroll={() => {
            //         let current = "";
            //         const tables = document.getElementsByClassName(
            //             styles.issuesTableContainer,
            //         );
            //         const tabs = document.getElementsByClassName(
            //             styles.categoryBtn,
            //         );
            //         console.log(tables, "bindbind");
            //         console.log(tabs, "bindbind");
            //         if (tables.length > 0 && tabs.length > 0) {
            //             for(let i=0; i<tables.length; i++){
            //                 if (tables[i].getBoundingClientRect().top < 150) {
            //                     current = tables[i].getAttribute("id");
            //                 }
            //             }
            //             tables.forEach((table) => {

            //             });
            //             tabs.forEach((tab) => {
            //                 tab.classList.remove(styles.active);
            //                 if (tab.getAttribute("href")[1] == current) {
            //                     tab.classList.add(styles.active);
            //                 }
            //             });
            //         }
            // }}
            className={styles.dashboard}
        >
            <Confetti
                className={styles.confetti}
                active={startConfetti}
                config={config}
            />
            {logoutModal && (
                <Modal setModal={setLogoutModal}>
                    <div className={styles.logoutContainer}>
                        <h2 style={{ fontWeight: "600" }}>Are You Sure?</h2>
                        <div>
                            Disconnecting the domain will remove all the fixes
                            you have done with Webtune AI. Are you sure?
                        </div>
                        <div className={styles.logoutBtns}>
                            <Button
                                handler={() => setLogoutModal(false)}
                                text="Cancel"
                                width={150}
                                secondary={true}
                                style={{
                                    fontWeight: "300",
                                }}
                            />
                            <Button
                                handler={() => {
                                    logoutReq();
                                }}
                                text="Disconnect"
                                width={150}
                                loading={logoutLoading}
                                style={{
                                    fontWeight: "300",
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {!!publishModal && (
                <Modal setModal={setPublishModal}>
                    <PublishModal
                        loading={loading}
                        setPublishModal={setPublishModal}
                        noOfIssues={totalApprovedCount}
                    />
                </Modal>
            )}

            {/* <Modal setModal={() => {}}>
                <div className={styles.logoutContainer}>
                    <h2 style={{ fontWeight: "600" }}>Are You Sure?</h2>
                    <div>some information will go here</div>
                    <div className={styles.logoutBtns}>
                        <Button
                            // handler={() => setRevertModal(false)}
                            text="Cancel"
                            width={150}
                            secondary={true}
                            style={{
                                fontWeight: "300",
                            }}
                        />
                        <Button
                            handler={() => {
                                // revertRequest();
                            }}
                            text="Revert"
                            width={150}
                            loading={false}
                            style={{
                                fontWeight: "300",
                            }}
                        />
                    </div>
                </div>
            </Modal> */}
            {!isReview && (
                <header>
                    <div>
                        <h2>WebTune AI</h2>
                        <div>
                            <b style={{ fontWeight: 500, fontSize: 14 }}>
                                Launch SEO changes blazingly fast
                            </b>
                            <span className={styles.lastRefresh}>
                                Last Refreshed{" "}
                                {moment(last_crawl_date).fromNow()}
                            </span>
                        </div>
                    </div>

                    <div>
                        <LightTooltip
                            title={
                                <>
                                    <div>
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            }}
                                            onClick={() => setLogoutModal(true)}
                                        >
                                            Disconnect Domain
                                        </span>
                                    </div>
                                </>
                            }
                            arrow
                            interactive
                        >
                            <div className={styles.connectedDomain}>
                                Domain:&nbsp;
                                <img
                                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${domain}`}
                                    alt=""
                                    height={12}
                                />
                                &nbsp;&nbsp;
                                <span className={styles.blue}>
                                    {domain}
                                </span>{" "}
                                &nbsp;&nbsp;
                                {is_verified ? (
                                    <BsCheckCircleFill color="#2fc5a0" />
                                ) : (
                                    <IoMdCloseCircle
                                        size={14}
                                        color="#FC5A5A"
                                    />
                                )}
                            </div>
                        </LightTooltip>
                        <div>
                            <span className={styles.nextRefresh}>
                                <GoClock size={18} /> Next refresh in 1 week
                            </span>
                        </div>
                    </div>
                </header>
            )}
            {is_verified == 0 && (
                <ConnectDomain connectDomainRef={connectDomainRef} />
            )}
            {!isReview && <OverallInfo setHistoryWindow={setHistoryWindow} />}
            <IssuesContainer
                allIssues={allIssues}
                setAllIssues={setAllIssues}
                filteredIssues={filteredIssues}
                setFilteredIssues={setFilteredIssues}
                setFilters={setFilters}
                filters={filters}
                connectDomainRef={connectDomainRef}
                getIssuesRequestRef={getIssuesRequestRef}
                getIssuesRequestLoaderRef={getIssuesRequestLoaderRef}
                getWebtuneDetailsRef={getWebtuneDetailsRef}
                publishModal={publishModal}
                getIssueCount={getIssueCount}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
            />

            {is_verified == 1 && (
                <div
                    className={styles.bottomBar}
                    style={{
                        justifyContent: isReview ? "space-between" : "flex-end",
                    }}
                >
                    {!!isReview && (
                        <Button
                            handler={() => {
                                setIsReview(false);
                            }}
                            text="Back"
                            width={100}
                            customClass={styles.backBtn}
                            Icon={BsArrowLeft}
                            iconReverse={true}
                            style={{
                                fontWeight: 400,
                            }}
                        />
                    )}
                    <div className={styles.reviewBtnWrapper}>
                        <span>
                            <BsCheckCircleFill />
                            &nbsp; {totalApprovedCount}
                            &nbsp; suggestions approved
                        </span>
                        <Button
                            loading={loading}
                            disabled={!totalApprovedCount}
                            width={200}
                            fontSize={14}
                            text={
                                isReview
                                    ? "Push Changes Live"
                                    : "Review & Push Live"
                            }
                            Icon={isReview ? GoRocket : BsArrowRight}
                            handler={() => {
                                setIsReview(true);
                                if (isReview) {
                                    publishFixes();
                                }
                            }}
                            style={{
                                fontWeight: 400,
                            }}
                            customClass={isReview ? styles.pushLiveBtn : ""}
                        ></Button>
                    </div>
                </div>
            )}
        </div>
    );
}

const ConnectDomain = ({ connectDomainRef }) => {
    const [loading, setLoading] = useState(false);
    const classes = useAccordionCompleteStyle();
    const [expanded, setExpanded] = useState(false);
    const [copied, setCopied] = useState(false);
    const { domain, setDomainInfo, setIsDomainConnected, verification_key } =
        useContext(WebtuneContext);
    const { getTrimmedDomainURL } = useGscHook();
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();

    const verifyReq = async () => {
        setLoading(true);
        const res = await postData({ url: webtuneEndPoints.verify });
        setLoading(false);
        if (res.status == 200) {
            if (res.data.is_verified == 1) {
                setDomainInfo((ps) => ({ ...ps, is_verified: 1, status: 3 }));
                dispatch(
                    displayAlert({
                        alertMessage: "Domain Verified Successfully",
                        alertType: "success",
                    }),
                );
                setIsDomainConnected(false);
            } else {
                dispatch(
                    displayAlert({
                        alertMessage: res.data.message,
                        alertType: "error",
                    }),
                );
            }
        }
    };

    const ref = useRef(null);

    useEffect(() => {
        connectDomainRef.current = () => {
            setExpanded(true);
            ref.current.scrollIntoView({ behavior: "smooth" });
        };
    }, []);

    return (
        <div ref={ref}>
            <div className={styles.connectDomainContainer}>
                <Accordion
                    onChange={(e, state) => setExpanded(state)}
                    classes={classes}
                    expanded={expanded}
                >
                    <AccordionSummary>
                        <div
                            style={{ background: expanded ? "none" : "" }}
                            className={styles.notConnected}
                        >
                            <div style={{ color: "#2c3948" }}>
                                <HiOutlineExclamationCircle
                                    style={{ marginRight: 5 }}
                                    size={20}
                                    color="red"
                                />
                                Your domain{" "}
                                <span
                                    style={{ display: "contents" }}
                                    className={styles.blue}
                                >
                                    {getTrimmedDomainURL(domain)}
                                </span>{" "}
                                is not connected to Webtune AI. Please connect
                                domain to push changes.
                            </div>
                            {!expanded ? (
                                <Button
                                    width={150}
                                    handler={() => {}}
                                    text="Connect Domain"
                                    fontSize={14}
                                    height={36}
                                    style={{
                                        fontWeight: 400,
                                    }}
                                ></Button>
                            ) : (
                                <Button
                                    width={80}
                                    handler={() => {
                                        setExpanded(false);
                                    }}
                                    text="Hide"
                                    fontSize={10}
                                    height={30}
                                    style={{
                                        backgroundColor: "#7188AA1A",
                                        color: "#7188AA",
                                        fontWeight: 400,
                                    }}
                                    Icon={() => <BiChevronUp size={18} />}
                                ></Button>
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: "100%", color: "#6b6b6b" }}>
                            Below is the Javascript code you should include in
                            the <span className={styles.blue}>{"<head> "}</span>
                            tag of your site. For best results, paste this on
                            every page, or in the site template, right before
                            the closing{" "}
                            <span className={styles.blue}>{"<head/> "}</span>
                            tag. Use google tag manager for using the script on
                            all pages.
                            <div className={styles.scriptContainer}>
                                <div className={styles.script}>
                                    {genScript(verification_key)}
                                </div>
                                <Button
                                    width={100}
                                    handler={() => {
                                        navigator.clipboard.writeText(
                                            genScript(verification_key),
                                        );
                                        setCopied(true);
                                    }}
                                    text={copied ? "Copied" : "Copy code"}
                                    fontSize={10}
                                    height={30}
                                    style={
                                        copied
                                            ? {
                                                  backgroundColor:
                                                      "rgb(1 77 197 / 7%)",
                                                  color: "var(--primary-blue)",
                                              }
                                            : {
                                                  backgroundColor: "#7188AA1A",
                                                  color: "#7188AA",
                                              }
                                    }
                                    Icon={() => <BiCopy size={18} />}
                                    iconReverse
                                ></Button>
                            </div>
                            <div className={styles.checkConnection}>
                                Check connection if done with above procedure
                                <Button
                                    width={180}
                                    handler={() => {
                                        verifyReq();
                                    }}
                                    text={
                                        loading
                                            ? "Checking"
                                            : "Check Connection"
                                    }
                                    fontSize={14}
                                    height={36}
                                    iconReverse
                                    Icon={() => (
                                        <VscDebugDisconnect size={20} />
                                    )}
                                    style={{ marginLeft: 20, fontWeight: 400 }}
                                    loading={loading}
                                    loadingText={loading}
                                ></Button>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

const OverallInfo = ({ setHistoryWindow }) => {
    const { issueSummary, is_verified } = useContext(WebtuneContext);
    const items = [
        {
            icon: "/New UI/SVG/overallPages.svg",
            title: "Pages Crawled",
            value: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {issueSummary.totalPages}{" "}
                    <div
                        style={{
                            color: "#ACB3BF",
                            fontSize: 13,
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "5px",
                        }}
                    >
                        / {issueSummary.planLimit} &nbsp;
                        <LightTooltip
                            placement="bottom"
                            title={`Your page limit is currently ${issueSummary.planLimit}`}
                            arrow
                        >
                            <span style={{ display: "flex" }}>
                                <AiOutlineExclamationCircle size={14} />
                            </span>
                        </LightTooltip>
                    </div>
                </div>
            ),
        },
        {
            icon: "/New UI/SVG/overallIssues.svg",
            title: "Current Issues Found",
            value: issueSummary.totalIssues - issueSummary.fixedIssues || 0,
        },
        {
            icon: "/New UI/SVG/overallFixes.svg",
            title: "Issues Fixed",
            value: issueSummary.fixedIssues,
            tag: <span className={styles.webtuneTag}>Webtune AI</span>,
        },
    ];

    return (
        <div className={styles.overallInfo}>
            {items.map((item, i) => (
                <>
                    <div>
                        <SVGIcon size={54} src={item.icon} />
                        <div className={styles.overallInfoText}>
                            <div>
                                {item.title} {item.tag}
                            </div>

                            <b>{item.value}</b>
                        </div>
                    </div>
                </>
            ))}
            <LightTooltip title={!is_verified ? "Not Verified" : ""} arrow>
                <button
                    disabled={!is_verified}
                    onClick={() => setHistoryWindow(true)}
                >
                    View Past Fixes <VscChevronRight size={16} />
                </button>
            </LightTooltip>
        </div>
    );
};

const IssuesContainer = ({
    allIssues,
    setAllIssues,
    filteredIssues,
    setFilteredIssues,
    filters,
    setFilters,
    connectDomainRef,
    getIssuesRequestRef,
    getIssuesRequestLoaderRef,
    publishModal,
    getIssueCount,
    getWebtuneDetailsRef,
    isFilterActive,
    setIsFilterActive,
}) => {
    const [loading, setLoading] = useState(false);
    const [apiCall, setApiCall] = useState(1);
    const { postData } = useHelperFunctions();
    const [cats, setCats] = useState([]);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [selectedCat, setSelectedCat] = useState("");
    const { isDomainConnected, isReview, domain, setDomainInfo } =
        useContext(WebtuneContext);
    const { getTrimmedDomainURL } = useGscHook();
    const [totalIssues, setTotalIssues] = useState(0);
    const [getIssuesCount, setGetIssuesCount] = useState(0);
    const [totalCategoryIssues, setTotalCategoryIssues] = useState({});
    const [renderedIssues, setRenderedIssues] = useState(0);
    const [fetchedPageKey, setFetchedPageKey] = useState("");
    const getWebtuneDetails = async () => {
        const res = await postData({ url: webtuneEndPoints.webtuneDetails });
        if (res.status == 200) {
            console.log("getWebtuneDetails", res.data);
            setDomainInfo((ps) => ({
                ...res.data.domainInfo,
                issueSummary: res.data.issueSummary,
            }));
        }
    };
    const getIssuesRequest = async (
        pageKey = "",
        pageNumber = 1,
        showLoader = true,
        _filters = [],
        _search = "",
    ) => {
        setFetchedPageKey(pageKey);
        setApiCall(1);
        // setLoading(showLoader);
        const res = await postData({
            url: webtuneEndPoints.getIssues,
            payload: {
                status: [],
                categories: pageKey?.length
                    ? [
                          filters?.categories.filter((fItm) =>
                              fItm.key == pageKey ? true : false,
                          )[0],
                      ]
                    : _filters,
                pageNumber: pageNumber,
                search: _search,
                status:
                    filters?.status == 1
                        ? [0]
                        : filters?.status == 2
                        ? [-1]
                        : [],
                limit: PAGE_LIMIT,
            },
        });

        if (res.status == 200) {
            if (getIssuesCount == 0) {
                setTotalCategoryIssues(res.data?.filteredCountByCategory);
            }
            setGetIssuesCount((ps) => ps + 1);
            const arr = parseIssues(res.data.data);
            if (!pageKey.length) {
                setCategoryCounts(res.data.filteredCountByCategory);
            }
            setAllIssues(arr);
            const _arr = parseIssuesCategoryWise(arr);
            if (pageKey.length) {
                setCats((ps) => {
                    let temp = [...ps];
                    let idx = temp.findIndex((_itm) => _itm.key == pageKey);
                    let tempIssues = _arr.filter((itm) => itm.key == pageKey);

                    temp[idx] = {
                        ...temp[idx],
                        issues: [...tempIssues[0]?.issues],
                    };
                    return temp;
                });
            } else {
                setCats(_arr);
            }
            setLoading(false);
        }
        setFetchedPageKey("");
        setApiCall(0);
    };

    const parseIssues = (arr) => {
        const newArray = [];

        const processIssue = (page, issue) => {
            findIssueTags(issue);
            issue.fix =
                getRecommendationsFix({ ...page, ...issue }) || issue.fix;
            issue.meta = {
                page: page.page,
                category: issue.category,
                issueTags: issue.issueTags,
            };
            newArray.push({ ...page, ...issue });
        };

        arr.forEach((page) => {
            const pageIssues = page.page_issues;

            pageIssues.forEach((issue) => {
                // if (issue.category === categoryMap.EXTERNAL_LINK.key) {
                //     issue.selectorsLevel.forEach((selector) => {
                //         const newIssue = {
                //             ...issue,
                //             selectorsLevel: [selector],
                //         };
                //         processIssue(page, newIssue);
                //     });
                // } else
                {
                    processIssue(page, issue);
                }
            });
        });

        return newArray;
    };

    const parseIssuesCategoryWise = (issues) => {
        const defaultMap = {
            META_TITLE: {
                count: 0,
                key: "META_TITLE",
                issues: [],
            },
            META_DESCRIPTION: {
                count: 0,
                key: "META_DESCRIPTION",
                issues: [],
            },
            EXTERNAL_LINK: {
                count: 0,
                key: "EXTERNAL_LINK",
                issues: [],
            },
            CANONICAL_LINK: {
                count: 0,
                key: "CANONICAL_LINK",
                issues: [],
            },
        };

        const catsMap = new Map(Object.entries(defaultMap));
        issues.forEach((issue) => {
            if (catsMap.has(issue.category)) {
                catsMap.get(issue.category).count += 1;
                catsMap.get(issue.category).issues.push({ ...issue });
            } else {
                catsMap.set(issue.category, {
                    count: 1,
                    issues: [{ ...issue }],
                });
            }
        });
        let newCatsArr = Array.from(catsMap, ([key, cat]) => ({
            count: cat.count,
            key: key,
            issues: cat.issues,
        }));
        newCatsArr = newCatsArr.sort(sortWithCategory);

        return newCatsArr;
    };

    useEffect(() => {
        getIssuesRequestRef.current = getIssuesRequest;
        getIssuesRequestLoaderRef.current = setLoading;
        getWebtuneDetailsRef.current = getWebtuneDetails;
    }, []);

    useEffect(() => {
        //
        if (apiCall == 0) {
            let _filters = filters.categories.filter((ftm) =>
                ftm?.issueTags.length > 0 ? true : false,
            );
            if (!!_filters.length) {
                setIsFilterActive(true);
            } else {
                setIsFilterActive(false);
            }

            getIssuesRequest(
                "",
                1,
                false,
                _filters?.length > 0 ? _filters : [],
                filters.search || "",
            );
        }
    }, [filters]);

    useEffect(() => {
        if (cats.length) {
            setSelectedCat(cats[0].key);
            let _renderCount = 0;
            let _totalCount = 0;
            cats.forEach((itm) => {
                console.log("TotalIssues", itm);
                _renderCount = _renderCount + itm.count;
                _totalCount = _totalCount + categoryCounts[itm.key];
            });
            for (let i = 0; i < cats.length; i++) {
                if (cats[i]?.issues?.length > 0 && isReview) {
                    setSelectedCat(cats[i].key);
                    break;
                }
                if (!isReview) {
                    setSelectedCat(cats[0].key);
                }
            }
            setRenderedIssues(_renderCount);
            setTotalIssues(_totalCount);
        } else {
            setRenderedIssues(0);
        }
    }, [cats]);

    useEffect(() => {
        if (isDomainConnected) {
            getIssuesRequest();
            getWebtuneDetails();
            getIssueCount();
        } else {
            const arr = parseIssues(dummyData.issues);
            setAllIssues(arr);
        }
    }, [isDomainConnected]);

    const updateMultipleIssue = useCallback((issuesArray, catKey) => {
        setCats((ps) => {
            let temp = [...ps];
            let idx = temp.findIndex((_itm) => _itm.key == catKey);

            temp[idx].issues = issuesArray;
            return temp;
        });
    }, []);

    const updateIssue = useCallback((issueId, issue) => {
        setCats((ps) => {
            let temp = [...ps];
            let idx = temp.findIndex((_itm) => _itm.key == issue.category);
            const issueIdx = temp[idx].issues.findIndex(
                (_itm) => _itm.page_issue_id == issueId,
            );
            if (issueIdx == -1) return ps;
            temp[idx].issues[issueIdx] = issue;
            temp[idx].issues = [...temp[idx].issues];
            return temp;
        });
    }, []);

    const timeoutRef = useRef(null);

    return (
        <>
            {loading && !publishModal && (
                <div className={styles.loginContainerWrapper}>
                    <Progress />
                </div>
            )}
            <div className={styles.issuesContainer}>
                {isReview && (
                    <div className={styles.reviewHeader}>
                        <h2>Review Final Changes</h2>

                        <p>
                            Please review your changes before making them live
                            on{" "}
                            <span className={styles.blue}>
                                {getTrimmedDomainURL(domain)}
                            </span>
                        </p>
                    </div>
                )}
                <div className={styles.issuesFilters}>
                    <div>
                        {cats.map((cat, index) => (
                            <CategoryBtn
                                sectionId={index}
                                title={categoryMap[cat.key]?.title}
                                count={categoryCounts[cat.key] || 0}
                                selected={selectedCat == cat.key}
                                catKey={cat.key}
                                setSelectedCat={setSelectedCat}
                                isFilterActive={isFilterActive}
                            />
                        ))}
                    </div>
                    {!isReview && (
                        <div>
                            {filters.search.length > 0 && (
                                <span className={styles.issuesFiltersNumber}>
                                    Displaying: &nbsp;
                                    <b>
                                        {renderedIssues || 0}/{totalIssues || 0}
                                    </b>
                                </span>
                            )}
                            <Input
                                type="search"
                                className={styles.searchBar}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <BiSearch color="#ACB3BF" size={20} />
                                    </InputAdornment>
                                }
                                placeholder="Search page URL"
                                onChange={(e) => {
                                    debounceWrapper(
                                        () => {
                                            setFilters((ps) => ({
                                                ...ps,
                                                search: e?.target?.value?.trim(),
                                            }));
                                        },
                                        500,
                                        timeoutRef,
                                    );
                                }}
                            />
                            <FilterMenu
                                setFilters={setFilters}
                                filters={filters}
                                isFilterActive={isFilterActive}
                            />
                        </div>
                    )}
                </div>

                {!!cats.length ? (
                    cats.map((cat, idx) => {
                        return (
                            <IssuesTable
                                totalCategoryIssues={
                                    totalCategoryIssues[cat.key]
                                }
                                updateMultipleIssue={updateMultipleIssue}
                                title={categoryMap[cat.key]?.title}
                                count={categoryCounts[cat.key] || 0}
                                issues={cat.issues}
                                selectedCat={selectedCat}
                                catKey={cat.key}
                                setCats={setCats}
                                catIndex={idx}
                                updateIssue={updateIssue}
                                connectDomainRef={connectDomainRef}
                                getIssuesRequest={getIssuesRequest}
                                isPageFetching={
                                    fetchedPageKey?.length &&
                                    fetchedPageKey == cat.key
                                        ? true
                                        : false
                                }
                                filters={filters}
                                getIssueCount={getIssueCount}
                                isFilterActive={isFilterActive}
                            />
                        );
                    })
                ) : (
                    <NoResultFound />
                )}
            </div>
        </>
    );
};

const CategoryBtn = ({
    sectionId,
    title,
    count,
    selected,
    setSelectedCat,
    catKey,
    isFilterActive,
}) => {
    const [active, setActive] = useState(false);
    const { isReview } = useContext(WebtuneContext);
    useEffect(() => {
        setActive(selected);
    }, [selected]);
    return count == 0 && isReview ? (
        <></>
    ) : (
        <a
            href={`#${sectionId + 1}`}
            onClick={() => setSelectedCat(catKey)}
            className={`${styles.categoryBtn}  ${active ? styles.active : ""}`}
        >
            {title} <span>{count}</span>
        </a>
    );
};

const IssuesTable = ({
    issues,
    title,
    count,
    selectedCat,
    catKey,
    setCats,
    catIndex,
    updateIssue,
    connectDomainRef,
    updateMultipleIssue,
    getIssuesRequest = () => {},
    isPageFetching = false,
    getIssueCount,
    filters,
    isFilterActive,
    totalCategoryIssues,
}) => {
    const ref = useRef();
    const { domain_id, isReview, is_verified } = useContext(WebtuneContext);
    const [loading, setLoading] = useState(false);
    const [recordsPerPage, setRecordsPerPage] = useState(PAGE_LIMIT);
    const [numberOfPages, setNumberOfPages] = useState(
        Math.ceil(count / recordsPerPage),
    );
    const [isAllFixedTemp, setIsAllFixedTemp] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const { postData } = useHelperFunctions();
    const [approveAllModal, setApproveAllModal] = useState({
        isShow: false,
        category: "",
        count: 0,
    });
    const [showEmptyFixModal, setShowEmptyFixModal] = useState(false);
    const [emptyFixModal, setEmptyFixModal] = useState({
        empty_count: 0,
        count: totalCategoryIssues,
    });

    useEffect(() => {
        setNumberOfPages(Math.ceil(count / recordsPerPage));
    }, [count]);

    useEffect(() => {
        if (issues.length > 0) {
            let ttl = 0;
            issues.map((_itm) => {
                if (!!_itm?.fix_id) {
                    ttl = ttl + 1;
                }
            });
            if (issues.length == ttl) {
                setIsAllFixedTemp(true);
            } else {
                setIsAllFixedTemp(false);
            }
        } else {
            setIsAllFixedTemp(true);
        }
    }, [issues]);

    const fixMultipleRequest = async (isSave = true) => {
        setIsAllFixedTemp(false);
        setLoading(true);
        let page_ids = [];
        let page_issue_ids = [];
        let meta_datas = [];
        let fixes = [];
        issues.forEach((issue) => {
            page_ids.push(issue.page_id);
            page_issue_ids.push(issue.page_issue_id);
            meta_datas.push({ ...issue.meta });
            fixes.push(issue.fix);
        });

        const res = await postData({
            url: webtuneEndPoints.updateFix,
            payload: {
                isAll: 1,
                category: catKey,
                domain_id: domain_id,
                pageIssueIds: page_issue_ids,
                action: isSave ? fixActions.SAVE : fixActions.UNSAVE,
            },
        });
        if (res.status == 200) {
            setApproveAllModal({
                isShow: false,
                count: 0,
                category: "",
            });
            if (res.data?.resp?.empty_count) {
                setShowEmptyFixModal(true);
                setEmptyFixModal((ps) => ({
                    ...ps,
                    empty_count: res.data?.resp?.empty_count,
                }));
            }
            getIssuesRequest(catKey, currentPage, false);
            getIssueCount();
            setIsAllFixedTemp(true);
            if (res.data?.resp?.fixMap) {
                updateMultipleIssue(
                    issues.map((issue, i) => ({
                        ...issue,
                        fix_id: isSave
                            ? res.data?.resp?.fixMap[issue.page_issue_id] ||
                              issue.fix_id
                            : null,
                    })),
                    catKey,
                );
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectedCat == catKey)
            ref?.current?.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
    }, [selectedCat]);

    const handlePageChange = (e) => {
        setCurrentPage(e.selected);
        getIssuesRequest(catKey, e.selected + 1, false);
    };

    return isReview && issues.length == 0 ? (
        <></>
    ) : (
        <div id={catIndex + 1} className={styles.issuesTableContainer}>
            <h2 ref={ref}>
                {title} Issues <span>{count}</span>
            </h2>
            <div className={styles.tableConatiner}>
                <table className={styles.issuesTable}>
                    <thead>
                        <tr>
                            <th>Page URL</th> <th>Issues</th>
                            <th>Recommended Actions</th>
                            <th>
                                {!isReview && issues.length > 0 && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",
                                            color: "#7188AA",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <Button
                                            handler={() =>
                                                setApproveAllModal({
                                                    isShow: true,
                                                    category: title,
                                                    count: count,
                                                    isApprove: false,
                                                })
                                            }
                                            loading={loading}
                                            text={`${"Unapprove"} All`}
                                            // Icon={() => (
                                            //     <GoCheckCircle size={14} />
                                            // )}
                                            iconReverse
                                            style={{
                                                color: "#9e9e9e",
                                                backgroundColor: "transparent",
                                                fontWeight: "400",
                                            }}
                                            fontSize={12}
                                            width={"max-content"}
                                            height={36}
                                            borderRadius={0}
                                            disabled={!is_verified}
                                            customClass={styles.noShadow}
                                        ></Button>
                                        |
                                        <Button
                                            handler={() =>
                                                setApproveAllModal({
                                                    isShow: true,
                                                    category: title,
                                                    count: count,
                                                    isApprove: true,
                                                })
                                            }
                                            loading={loading}
                                            text={`${"Approve"} All`}
                                            // Icon={() => (
                                            //     <GoCheckCircle size={14} />
                                            // )}
                                            iconReverse
                                            style={{
                                                color: "#7188AA",
                                                backgroundColor: "transparent",
                                                fontWeight: "400",
                                            }}
                                            fontSize={12}
                                            width={"max-content"}
                                            height={36}
                                            borderRadius={0}
                                            disabled={!is_verified}
                                            customClass={styles.noShadow}
                                        ></Button>{" "}
                                    </div>
                                )}
                                <></>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!issues.length ? (
                            <RenderInChucks
                                list={
                                    is_verified
                                        ? issues
                                        : issues.slice(0, PAGE_LIMIT)
                                }
                            >
                                {(issue, index) => (
                                    <IssueRow
                                        disableRow={loading}
                                        key={issue.page_issue_id}
                                        issue={issue}
                                        isFixed={issue?.fix_id}
                                        setCats={setCats}
                                        index={index}
                                        catIndex={catIndex}
                                        isReview={isReview}
                                        domain_id={domain_id}
                                        updateIssue={updateIssue}
                                        is_verified={is_verified}
                                        isPageFetching={isPageFetching}
                                        setIsAllFixedTemp={setIsAllFixedTemp}
                                        getIssueCount={getIssueCount}
                                    />
                                )}
                            </RenderInChucks>
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    {isFilterActive || !!filters?.search ? (
                                        <div className={styles.noIssues_row}>
                                            No issues found for search/filter
                                        </div>
                                    ) : (
                                        <div className={styles.allGood_row}>
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/tick-circle.svg"
                                                }
                                                size={20}
                                            />
                                            All good! No issues found
                                        </div>
                                    )}
                                </td>
                            </tr>
                        )}
                        {!is_verified && (
                            <div className={styles.not_verified_strip}>
                                <Button
                                    Icon={() => <BsArrowRight size={18} />}
                                    width={"max-content"}
                                    style={{
                                        paddingInline: 20,
                                        fontWeight: 500,
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}
                                    text="Connect Your Domain To View All Issues & Corresponding Recommendations"
                                    handler={() => {
                                        connectDomainRef.current();
                                    }}
                                ></Button>
                            </div>
                        )}
                    </tbody>
                </table>
                {is_verified == 1 && numberOfPages > 1 && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "20px",
                        }}
                    >
                        <LocalPagination
                            pageCount={numberOfPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            styles2={styles2}
                        />
                    </div>
                )}
            </div>
            {showEmptyFixModal && (
                <Modal setModal={setShowEmptyFixModal}>
                    <div
                        style={{ position: "relative" }}
                        className={styles.logoutContainer}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setShowEmptyFixModal(false);
                            }}
                        >
                            <VscClose />
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                            <SVGIcon
                                color={"#1dc198"}
                                src={"/New UI/SVG/tick-circle.svg"}
                                size={50}
                            />
                        </div>
                        <div
                            style={{
                                paddingBottom: "20px",
                                lineHeight: "20px",
                                fontSize: "14px",
                                fontWeight: 400,
                            }}
                        >
                            Successfully approved{" "}
                            {emptyFixModal.count - emptyFixModal.empty_count}{" "}
                            out of {emptyFixModal.count} fixes.
                            <br /> Unfortunately, {
                                emptyFixModal.empty_count
                            }{" "}
                            recommendation were identified as empty and cannot
                            be approved.
                        </div>
                    </div>
                </Modal>
            )}
            {approveAllModal.isShow && (
                <Modal>
                    <div className={styles.logoutContainer}>
                        <h2 style={{ fontWeight: "600" }}>Are You Sure?</h2>
                        <div>
                            {approveAllModal?.isApprove
                                ? "Approving"
                                : "Unapproving"}{" "}
                            all will{" "}
                            {approveAllModal?.isApprove
                                ? "approve"
                                : "Unapprove"}{" "}
                            all {totalCategoryIssues} changes in <br />
                            {approveAllModal.category}. Are you sure?
                        </div>
                        <div className={styles.logoutBtns}>
                            <Button
                                handler={() =>
                                    setApproveAllModal({
                                        isShow: false,
                                        category: "",
                                        count: "",
                                    })
                                }
                                text="Cancel"
                                width={150}
                                secondary={true}
                                style={{
                                    fontWeight: "300",
                                }}
                            />
                            <Button
                                handler={() => {
                                    fixMultipleRequest(
                                        approveAllModal.isApprove,
                                    );
                                }}
                                text={`${
                                    approveAllModal.isApprove
                                        ? "Approve"
                                        : "Unapprove"
                                } All`}
                                width={150}
                                loading={loading}
                                style={{
                                    fontWeight: "300",
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};
const RowShimmer = () => {
    return (
        <tr>
            <td>
                <Skeleton height={"40px"} width={"50%"} />
            </td>
            <td>
                <Skeleton height={"30px"} width={"50%"} />
            </td>
            <td>
                <Skeleton height={"20px"} width={"20%"} />
                <Skeleton height={"40px"} width={"70%"} />
            </td>
            <td
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                }}
            >
                <Skeleton height={"46px"} width={"40%"} />
            </td>
        </tr>
    );
};

const IssueRow = React.memo(
    ({
        disableRow,
        issue,
        isFixed,
        domain_id,
        isReview,
        updateIssue,
        is_verified,
        isPageFetching = false,
        setIsAllFixedTemp = () => {},
        getIssueCount = () => {},
    }) => {
        const [fixed, setFixed] = useState(false);
        const [loading, setLoading] = useState(false);
        const { postData } = useHelperFunctions();
        const [edit, setEdit] = useState(is_verified ? !fixed : false);
        const [emptyFix, setEmptyFix] = useState(false);

        useEffect(() => {
            if (issue.category == categoryMap.EXTERNAL_LINK.key) {
                for (let i = 0; i < issue.fix.length; i++) {
                    if (issue.fix[i].value) {
                        setEmptyFix(false);
                        return;
                    }
                }
                setEmptyFix(true);
            } else {
                // For Categories other than EXTERNAL_LINKS which has single fix element
                if (!issue.fix[0].value) {
                    setEmptyFix(true);
                } else {
                    setEmptyFix(false);
                }
            }
        }, [issue.fix]);

        useEffect(() => {
            setFixed(!!isFixed);
        }, [isFixed]);

        useEffect(() => {
            setEdit(is_verified ? !fixed : false);
        }, [fixed]);

        useEffect(() => {
            if (isReview) {
                setEdit(false);
            }
        }, [isReview]);

        const fixRequest = async (action, toDelete) => {
            setLoading(true);

            const res = await postData({
                url: webtuneEndPoints.updateFix,
                payload:
                    action == fixActions.SAVE
                        ? {
                              action,
                              domain_id: domain_id,
                              page_id: [issue.page_id],
                              page_issue_id: [issue.page_issue_id],
                              meta_data: [{ ...issue.meta }],
                              fix: [issue.fix],
                          }
                        : {
                              action,
                              fix_id: [issue.fix_id],
                              domain_id: domain_id,
                          },
            });
            if (res.status == 200) {
                getIssueCount();
                if (action == fixActions.SAVE) {
                    updateIssue(issue.page_issue_id, {
                        ...issue,
                        fix_id: res.data?.resp?.fix_id[0],
                    });
                    setFixed(true);
                    setEdit(false);
                } else {
                    setFixed(false);
                    setEdit(true);
                    if (toDelete)
                        updateIssue(issue.page_issue_id, {
                            ...issue,
                            fix_id: null,
                        });
                }
            }
            setLoading(false);
        };

        const modifyFix = ({ action, i }) => {
            issue.fix[i] = action;
            updateIssue(issue.page_issue_id, {
                ...issue,
                fix: [...issue.fix],
            });
        };

        return isPageFetching ? (
            <RowShimmer />
        ) : (
            <tr>
                <td>
                    <LightTooltip
                        arrow
                        placement="bottom"
                        title={new URL(issue.page).pathname || ""}
                    >
                        <a
                            className={styles.pageUrl}
                            target="_blank"
                            href={new URL(issue.page)}
                        >
                            {new URL(issue.page).pathname.slice(0, 30) || "-"}
                            {new URL(issue.page).pathname.length > 30
                                ? "..."
                                : ""}
                        </a>
                    </LightTooltip>
                </td>
                <td>
                    <div className={styles.issueTagsContainer}>
                        {issue.issueTags.map((tag) => (
                            <IssueTag tag={tag} />
                        ))}
                    </div>
                </td>
                <td>
                    <RecommendedFix
                        modifyFix={modifyFix}
                        issue={issue}
                        isReview={isReview}
                        edit={edit}
                    />
                </td>
                <td>
                    <div className={styles.actionBtns}>
                        {isReview ? (
                            edit ? (
                                <Button
                                    Icon={() => <BiCheck size={18} />}
                                    iconReverse
                                    style={{
                                        color: "#014DC5",
                                        backgroundColor: "#014DC51A",
                                        fontWeight: 400,
                                    }}
                                    width={80}
                                    height={32}
                                    fontSize={13}
                                    handler={() => {
                                        if (emptyFix) {
                                            return;
                                        }
                                        fixRequest(fixActions.SAVE);
                                        setEdit(false);
                                    }}
                                    text="Done"
                                    disabled={emptyFix}
                                ></Button>
                            ) : (
                                <>
                                    <Button
                                        Icon={() => <HiPencil />}
                                        iconReverse
                                        style={{
                                            color: "#C0C7DB",
                                            backgroundColor: "#C0C7DB33",
                                            marginRight: 10,
                                        }}
                                        width={32}
                                        height={32}
                                        handler={() => {
                                            fixRequest(fixActions.UNSAVE);
                                        }}
                                        text=""
                                    ></Button>
                                    <Button
                                        text=""
                                        Icon={() => (
                                            <RiDeleteBin6Line color="#FC5A5A" />
                                        )}
                                        iconReverse
                                        style={{
                                            color: "#FC5A5A",
                                            backgroundColor: "#FC5A5A1A",
                                        }}
                                        width={32}
                                        height={32}
                                        handler={() => {
                                            fixRequest(fixActions.UNSAVE, true);
                                        }}
                                        loading={loading}
                                    ></Button>
                                </>
                            )
                        ) : (
                            <LightTooltip
                                arrow
                                placement="bottom"
                                title={
                                    is_verified
                                        ? fixed
                                            ? "Click to Un-Approve"
                                            : emptyFix
                                            ? "Cannot approve empty value"
                                            : "Click to Approve"
                                        : "Not Verified"
                                }
                            >
                                <div>
                                    <Button
                                        text={fixed ? "Approved" : "Approve"}
                                        Icon={() => <GoCheckCircle size={14} />}
                                        iconReverse
                                        style={
                                            fixed
                                                ? {
                                                      color: "#1DC198",
                                                      backgroundColor:
                                                          "#1DC1981A",
                                                      borderRadius: "4px",
                                                  }
                                                : {
                                                      color: "#7188AA",
                                                      backgroundColor:
                                                          "#7188AA1A",
                                                      borderRadius: "4px",
                                                  }
                                        }
                                        fontSize={12}
                                        width={120}
                                        height={32}
                                        handler={() => {
                                            if (emptyFix) {
                                                return;
                                            }
                                            if (fixed) {
                                                setIsAllFixedTemp(false);
                                                fixRequest(
                                                    fixActions.UNSAVE,
                                                    true,
                                                );
                                            } else {
                                                fixRequest(fixActions.SAVE);
                                            }
                                        }}
                                        loading={loading}
                                        disabled={
                                            !is_verified ||
                                            disableRow ||
                                            emptyFix
                                        }
                                    ></Button>
                                </div>
                            </LightTooltip>
                        )}
                    </div>
                </td>
            </tr>
        );
    },
);

const RecommendedFix = ({ edit, issue, modifyFix, isReview }) => {
    const [expanded, setExpanded] = useState(false);

    const getUI = (action, i) => {
        switch (action.action) {
            case "ADD":
                return (
                    <>
                        <div className={styles.recommendationCheck}>
                            <BiCheck color="#014dc5" size={20} />
                            &nbsp; Add new tag
                        </div>

                        <AIRecommended
                            modifyFix={modifyFix}
                            action={action}
                            index={i}
                            edit={edit}
                            category={issue.category}
                            page_hash={issue.page_hash}
                            isReview={isReview}
                        />
                    </>
                );
            case "UPDATE_VALUE":
                return (
                    <>
                        <div className={styles.recommendationCheck}>
                            <BiCheck color="#014dc5" size={20} />
                            &nbsp; Update value to
                        </div>

                        <AIRecommended
                            modifyFix={modifyFix}
                            action={action}
                            index={i}
                            edit={edit}
                            category={issue.category}
                            page_hash={issue.page_hash}
                            isReview={isReview}
                        />
                    </>
                );
            case "CHOOSE_FROM":
                return (
                    <>
                        <div className={styles.recommendationCheck}>
                            <BiCheck color="#014dc5" size={20} />
                            &nbsp; Update value to
                        </div>

                        <MultipleOptions
                            modifyFix={modifyFix}
                            index={i}
                            issue={issue}
                            action={action}
                            edit={edit}
                        />
                    </>
                );
            case "OPEN_IN_NEW_TAB":
                return (
                    <>
                        <div
                            style={{ marginLeft: 25 }}
                            className={styles.recommendationCheck}
                            key={action.link + i + 1}
                        >
                            {edit ? (
                                <input
                                    defaultChecked={action.value}
                                    onChange={(e) =>
                                        modifyFix({
                                            action: {
                                                ...action,
                                                value: e.target.checked,
                                            },
                                            i,
                                        })
                                    }
                                    type="checkbox"
                                />
                            ) : action.value ? (
                                <BiCheck color="#014dc5" size={20} />
                            ) : (
                                <MdClose size={18} />
                            )}
                            &nbsp;
                            <LightTooltip
                                arrow
                                placement="bottom"
                                title={action.link}
                            >
                                <div className={`${styles.approvedChange}`}>
                                    {action.link
                                        ? action.link.length > 50
                                            ? `${action.link.slice(0, 50)}...`
                                            : action.link
                                        : ""}
                                </div>
                            </LightTooltip>
                        </div>
                    </>
                );
            case "INSIDE_HEAD":
                return (
                    <>
                        <div className={styles.recommendationCheck}>
                            <BiCheck color="#014dc5" size={20} />
                            &nbsp; Move inside head tag
                        </div>
                    </>
                );

            default:
                break;
        }
    };

    const classes = useAccordionExternalLinkStyle();

    return (
        <>
            {issue.category == categoryMap.EXTERNAL_LINK.key ? (
                <>
                    <Accordion classes={classes} expanded={expanded}>
                        <AccordionSummary>
                            {issue.fix
                                .slice(0, 2)

                                .map((action, i) => {
                                    return (
                                        <>
                                            {action.action ==
                                                "OPEN_IN_NEW_TAB" &&
                                                i == 0 && (
                                                    <div
                                                        className={
                                                            styles.recommendationCheck
                                                        }
                                                    >
                                                        <BiCheck
                                                            color="#014dc5"
                                                            size={20}
                                                        />
                                                        &nbsp; Enable link to
                                                        open in new tab
                                                    </div>
                                                )}
                                            {getUI(action, i)}
                                        </>
                                    );
                                })}
                        </AccordionSummary>
                        <AccordionDetails>
                            {issue.fix
                                .slice(3, issue.fix.length)

                                .map((action, i) => {
                                    return <>{getUI(action, i + 3)}</>;
                                })}
                        </AccordionDetails>
                    </Accordion>
                    {issue.fix.length > 3 &&
                        issue.category == categoryMap.EXTERNAL_LINK.key && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    paddingLeft: "66px",
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: "#014DC51A",
                                        color: "#014DC5",
                                        fontSize: 12,
                                        margin: "10px 0 0 0px",
                                        fontWeight: 400,
                                        paddingInline: 10,
                                        width: "fit-content",
                                    }}
                                    text={
                                        expanded
                                            ? "View less"
                                            : `+ ${issue.fix.length - 3} more`
                                    }
                                    handler={() => setExpanded(!expanded)}
                                    height={26}
                                    customClass={styles.noShadow}
                                />
                            </div>
                        )}
                </>
            ) : (
                issue.fix.map((action, i) => {
                    return (
                        <>
                            {action.action == "OPEN_IN_NEW_TAB" && i == 0 && (
                                <div className={styles.recommendationCheck}>
                                    <BiCheck color="#014dc5" size={20} />
                                    &nbsp; Enable link to open in new tab
                                </div>
                            )}
                            {getUI(action, i)}
                        </>
                    );
                })
            )}
        </>
    );
};

const AIRecommended = ({
    edit,
    action,
    modifyFix,
    index,
    category,
    page_hash,
    isReview,
}) => {
    const [list, setList] = useState([]);
    const [isGen, setIsGen] = useState(false);
    const [prevItems, setPrevItems] = useState({ length: list.length });
    const [isGenerating, setIsGenerating] = useState(false);
    const [value, setValue] = useState(action.value);
    const initialValue = useMemo(() => action.value, []);
    const [btnText, setBtnText] = useState("");

    const ref = useRef();

    useEffect(() => {
        if (!isGenerating && prevItems.length) {
            setTimeout(() => {
                ref.current && ref.current.scrollIntoView();
            }, 100);
        } else {
            setPrevItems({ length: list.length });
        }
    }, [isGenerating]);

    const { postData } = useHelperFunctions();

    async function generate() {
        setIsGenerating(true);

        const payload = {
            page_hash: page_hash,
            category: category,
        };
        const res = await postData({
            url: webtuneEndPoints.generate,
            payload,
        });

        if (res.status == 200) {
            if (res.data) {
                setIsGen(true);
                setList((ps) => [
                    ...ps.map((i) => ({ ...i, new: false })),
                    ...res?.data?.map((i) => ({
                        ...{ content: i, uniqueId: v4() },
                        new: true,
                    })),
                ]);
                setTimeout(() => {
                    setList((ps) => [...ps.map((i) => ({ ...i, new: false }))]);
                }, 2000);
            }
        }
        setIsGenerating(false);
    }

    useEffect(() => {
        modifyFix({
            action: {
                ...action,
                value: value,
            },
            i: index,
        });
    }, [value]);

    return (
        <>
            {!edit ? (
                <LightTooltip arrow placement="bottom" title={value || ""}>
                    <div className={`${styles.approvedChange}`}>
                        {/* {value} */}
                        {value.length > 210
                            ? value.slice(0, 210) + "..."
                            : value || ""}
                    </div>
                </LightTooltip>
            ) : (
                <div>
                    <div className={styles.recommendation}>
                        <TextArea
                            width="100%"
                            props={{
                                value: value || "",
                                onChange: (e) => {
                                    setValue(e.target.value);
                                },
                                style: {
                                    border: "none",
                                    outline: "none",
                                    flexGrow: 1,
                                    fontSize: "13px",
                                    color: "#2c3948",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    overflowY: "hidden",
                                    boxShadow: "none",
                                    padding: "3px 8px",
                                },
                            }}
                            containerStyle={{
                                width: "100%",
                                height: 10,
                            }}
                            showLabel={false}
                            rowsMin={1}
                            rowsMax={3}
                        />

                        {category != categoryMap.CANONICAL_LINK.key && (
                            <LightTooltip
                                tooltipStyle={{ maxWidth: "unset" }}
                                // PopperProps={{ disablePortal: true }}
                                placement="bottom-end"
                                title={
                                    <ClickAwayListener
                                        onClickAway={() => setIsGen(false)}
                                    >
                                        <div className={styles.aiSuggestions}>
                                            <VscClose
                                                className={
                                                    styles.aiSuggestionsClose
                                                }
                                                onClick={() => setIsGen(false)}
                                            />
                                            <h3>AI Generated Ideas</h3>
                                            <div
                                                className={
                                                    styles.aiSuggestionsList
                                                }
                                            >
                                                {list.map((item, i) => (
                                                    <>
                                                        {prevItems.length - 1 ==
                                                            i && (
                                                            <span
                                                                ref={ref}
                                                            ></span>
                                                        )}
                                                        <AIGeneratedItem
                                                            key={item.uniqueId}
                                                            item={item}
                                                            setValue={setValue}
                                                        />
                                                    </>
                                                ))}
                                            </div>

                                            <div
                                                className={
                                                    styles.aiSuggestionsMore
                                                }
                                            >
                                                <Button
                                                    style={{
                                                        backgroundColor:
                                                            "rgba(1, 77, 197, 1)",
                                                        color: "#fff",
                                                        fontSize: 10,
                                                        fontWeight: 400,
                                                        display: "flex",
                                                        justifyContent:
                                                            "Center",
                                                        alignItems: "center",
                                                    }}
                                                    height={20}
                                                    width={100}
                                                    borderRadius={2}
                                                    text={
                                                        isGenerating
                                                            ? "Generating"
                                                            : "Generate More"
                                                    }
                                                    handler={() => {
                                                        generate();
                                                    }}
                                                    iconReverse
                                                    loadingText={isGenerating}
                                                    customClass={
                                                        styles.noShadow
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                }
                                interactive
                                open={isGen}
                                arrow
                            >
                                <div>
                                    <Button
                                        style={{
                                            backgroundColor:
                                                "rgba(1, 77, 197, 1)",
                                            color: "#fff",
                                            fontSize: 10,
                                            paddingInline: "5px",
                                            fontWeight: 400,
                                            width: "fit-content",

                                            ...(isGen
                                                ? { display: "flex" }
                                                : {}),
                                        }}
                                        props={{
                                            onMouseEnter: () =>
                                                setBtnText("AI Suggestions"),
                                            onMouseLeave: () => setBtnText(""),
                                        }}
                                        height={25}
                                        // width={100}
                                        borderRadius={2}
                                        text={
                                            !list.length && isGenerating
                                                ? "Generating"
                                                : btnText
                                        }
                                        handler={() => {
                                            !list.length
                                                ? generate()
                                                : setIsGen(true);
                                        }}
                                        Icon={() =>
                                            list.length ? (
                                                !isGen ? (
                                                    <RiArrowRightSFill
                                                        style={{
                                                            color: "inherit",
                                                        }}
                                                        size={16}
                                                    />
                                                ) : (
                                                    <RiArrowDownSFill
                                                        style={{
                                                            color: "inherit",
                                                        }}
                                                        size={16}
                                                    />
                                                )
                                            ) : !list.length && isGenerating ? (
                                                ""
                                            ) : (
                                                <BiPlus />
                                            )
                                        }
                                        iconReverse
                                        loadingText={
                                            !list.length && isGenerating
                                        }
                                        customClass={styles.aiSuggestionsBtn}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                    </div>
                    {!isReview &&
                    (category == categoryMap.META_TITLE.key ||
                        category == categoryMap.META_DESCRIPTION.key) &&
                    !(initialValue || value) ? (
                        <div className={styles.emptySuggestion}>
                            * Unable to generate recommendation. Please use AI
                            Suggestions to add one.
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </>
    );
};

const AIGeneratedItem = ({ item, setValue }) => {
    return (
        <>
            <div
                style={
                    item.new
                        ? { backgroundColor: "rgba(1, 77, 197, 0.04)" }
                        : {}
                }
                className={styles.aIGeneratedItem}
                key={item.uniqueId}
                onClick={() => setValue(item.content)}
            >
                {item.content}
            </div>
        </>
    );
};

const MultipleOptions = ({ action, issue, modifyFix, edit, index }) => {
    return (
        <fieldset>
            <div className={styles.options}>
                {action.options.map((opt) => (
                    <div>
                        <input
                            type="radio"
                            defaultChecked={action.value == opt.value}
                            name={issue.page_issue_id}
                            onChange={(e) => {
                                if (e.target.checked)
                                    modifyFix({
                                        action: { ...action, value: opt.value },
                                        i: index,
                                    });
                            }}
                            disabled={!edit}
                        />
                        {opt.value}
                    </div>
                ))}
            </div>
        </fieldset>
    );
};

export const IssueTag = ({ tag }) => {
    const getColors = () => {
        switch (tag.key) {
            case "MISSING": {
                return {
                    backgroundColor: "rgba(245 152 34 / 14%)",
                    color: "#efa951",
                };
            }
            case "MULTIPLE": {
                return {
                    backgroundColor: "#FFAC5F1A",
                    color: "#DF9755",
                };
            }
            case "OUTSIDE_HEAD": {
                return {
                    color: "#EF5151",
                    background: "#FC5A5A1A",
                };
            }
            case "EMPTY_LINK": {
                return {
                    color: "#21B2D4",
                    background: "#10CFFC1A",
                };
            }
            case "TARGET_MISSING": {
                return {
                    color: "#6636CB",
                    background: "rgba(102, 54, 203, 0.1)",
                };
            }
            case "EMPTY": {
                return {
                    color: "#21B2D4",

                    background: "rgba(16, 207, 252, 0.1)",
                };
            }
            case "NOT_IN_NEW_TAB": {
                return {
                    color: "#498fb1",
                    background: "rgba(14 118 168 / 15%)",
                };
            }
            default: {
                return {
                    backgroundColor: "#7188AA1A",
                    color: "#7188AA",
                };
            }
        }
    };

    return (
        <div style={getColors()} className={styles.issueTag}>
            {tag.title}
        </div>
    );
};

export const FilterMenu = ({ setFilters, filters, logs, isFilterActive }) => {
    const [open, setOpen] = useState(false);
    const [localState, setLocalState] = useState(
        JSON.parse(JSON.stringify({ ...filters })),
    );
    useEffect(() => {
        const callback = () => setOpen(false);
        document.addEventListener("click", callback);

        return () => document.removeEventListener("click", callback);
    }, []);

    return (
        <>
            <LightTooltip
                placement="bottom-end"
                tooltipStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: 0,
                }}
                interactive
                disableFocusListener
                title={
                    <_FilterMenu
                        setLocalState={setLocalState}
                        localState={localState}
                        filters={filters}
                        setFilters={setFilters}
                        setOpen={setOpen}
                        logs={logs}
                    />
                }
                open={open}
            >
                <div onClick={(e) => e.stopPropagation()}>
                    <Button
                        Icon={() => (
                            <FiFilter
                                size={18}
                                color={
                                    isFilterActive
                                        ? "#014DC5"
                                        : !open
                                        ? "#ACB3BF"
                                        : "#014DC5"
                                }
                            />
                        )}
                        style={{
                            borderRadius: 6,
                            backgroundColor: isFilterActive
                                ? "#014dc51a"
                                : "#ACB3BF1A",
                        }}
                        width={40}
                        text=""
                        handler={() => setOpen(!open)}
                    ></Button>
                </div>
            </LightTooltip>
        </>
    );
};
const TagsMenu = ({ setLocalState, issueTags, index, cat }) => {
    return (
        <div onClick={(e) => e.stopPropagation()} className={styles.tagsMenu}>
            {tags[
                cat.key == categoryMap.EXTERNAL_LINK.key ? cat.key : "META"
            ].map((tag) => {
                return (
                    <div key={tag}>
                        <input
                            type="checkbox"
                            checked={issueTags.includes(tag)}
                            onChange={(e) =>
                                setLocalState((ps) => {
                                    if (e.target.checked) {
                                        ps.categories[index].issueTags.push(
                                            tag,
                                        );
                                    } else {
                                        const i = ps.categories[
                                            index
                                        ].issueTags.findIndex(
                                            (_tag) => _tag == tag,
                                        );
                                        ps.categories[index].issueTags.splice(
                                            i,
                                            1,
                                        );
                                    }

                                    return { ...ps };
                                })
                            }
                        />
                        <IssueTag tag={issueTagsMap[tag]} />
                    </div>
                );
            })}
        </div>
    );
};

const _FilterMenu = ({
    setLocalState,
    localState,
    setFilters,
    filters,
    setOpen,
    logs,
}) => {
    useEffect(() => {
        setLocalState(JSON.parse(JSON.stringify({ ...filters })));
    }, []);
    return (
        <div onClick={(e) => e.stopPropagation()} className={styles.filterMenu}>
            <CloseButton setClose={setOpen} />
            <h3>Type</h3>

            {localState.categories.map((cat, i) => (
                <LightTooltip
                    tooltipStyle={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: 0,
                    }}
                    interactive
                    disableFocusListener
                    placement="left"
                    title={
                        <TagsMenu
                            setLocalState={setLocalState}
                            issueTags={cat.issueTags}
                            index={i}
                            cat={cat}
                        />
                    }
                >
                    <div key={cat.key} className={styles.filterMenuRow}>
                        {cat.title}
                        {cat.issueTags.length ? (
                            <span
                                style={{
                                    color: "#014DC5",
                                    backgroundColor: "#014DC51A",
                                    marginLeft: "auto",
                                    marginRight: 10,
                                }}
                                className={styles.issueTag}
                            >
                                {cat.issueTags.length} Selected
                            </span>
                        ) : (
                            ""
                        )}
                        <VscChevronRight size={16} />
                    </div>
                </LightTooltip>
            ))}

            <h3>Status</h3>
            <fieldset>
                <div className={styles.filterMenuStatuses}>
                    {(!logs ? statues : publishStatuses).map((status) => (
                        <div>
                            <input
                                value={status.value}
                                checked={status.value == localState.status}
                                type="radio"
                                name="status"
                                id=""
                                onChange={(e) =>
                                    e.target.checked &&
                                    setLocalState((ps) => ({
                                        ...ps,
                                        status: status.value,
                                    }))
                                }
                            />
                            <div>{status.title}</div>
                        </div>
                    ))}
                </div>
            </fieldset>

            <div className={styles.btmRow}>
                <Button
                    style={{
                        backgroundColor: "#F7F7F7",
                        color: "inherit",
                        fontSize: 13,
                        fontWeight: 400,
                    }}
                    text="Reset"
                    handler={() => {
                        if (logs) {
                            setFilters(
                                JSON.parse(
                                    JSON.stringify(issueLogsFilterDefault),
                                ),
                            );
                            setLocalState(
                                JSON.parse(
                                    JSON.stringify(issueLogsFilterDefault),
                                ),
                            );
                            console.log(issueLogsFilterDefault, "localState1");
                        } else {
                            setFilters(
                                JSON.parse(JSON.stringify(issueFilterDefault)),
                            );
                            setLocalState(
                                JSON.parse(JSON.stringify(issueFilterDefault)),
                            );
                            console.log(issueFilterDefault, "localState2");
                        }
                    }}
                    height={36}
                    width={"45%"}
                ></Button>
                <Button
                    style={{
                        backgroundColor: "var(--primary-blue)",
                        fontSize: 13,
                        fontWeight: 400,
                    }}
                    text="Apply"
                    handler={() => {
                        setFilters(localState);
                        setOpen(false);
                        console.log(localState, "localState");
                    }}
                    height={36}
                    width={"45%"}
                ></Button>
            </div>
        </div>
    );
};

const PublishModal = ({ loading, setPublishModal, noOfIssues }) => {
    const { domain } = useContext(WebtuneContext);
    const { getTrimmedDomainURL } = useGscHook();
    const [published, setPublished] = useState(false);

    useEffect(() => {
        if (!loading) {
            setPublished(true);
        }
    }, [loading]);

    const _noOfIssues = useMemo(() => noOfIssues, []);

    return (
        // <div style={{ zIndex: 11 }} className={styles.loginContainerWrapper}>
        <div className={styles.publishModal}>
            {!published ? (
                <>
                    <lottie-player
                        src="https://assets1.lottiefiles.com/packages/lf20_enrpmvws.json"
                        background="transparent"
                        speed="1.5"
                        style={{
                            width: `${100}px`,
                            height: `${100}px`,
                        }}
                        autoplay
                        loop
                    ></lottie-player>
                    <p>
                        We are pushing the fixes to{" "}
                        <span className={styles.blue}>
                            {" "}
                            {getTrimmedDomainURL(domain)}
                        </span>
                        <p>
                            Please wait
                            <LoadingDots dots={true} />
                        </p>
                    </p>
                    <br />
                </>
            ) : (
                <>
                    <GoCheckCircle
                        style={{ marginTop: 10 }}
                        color="#2fc5a0"
                        size={40}
                    />
                    <h3 style={{ color: "#2fc5a0" }}>Success!</h3>
                    <p>
                        We have successfully pushed
                        {_noOfIssues == 1
                            ? ` a fix `
                            : ` all ${_noOfIssues} fixes `}
                        on
                        <span className={styles.blue}> {domain}</span>
                    </p>
                    <p>All the changes will be visible on Logs screen.</p>
                </>
            )}
            {published && (
                <NavigatingText
                    onEnd={() => {
                        setPublishModal(false);
                    }}
                    text={"Closing popup in"}
                />
            )}
        </div>
        // </div>
    );
};

const VerificationModal = ({ loading, setPublishModal }) => {
    const { domain } = useContext(WebtuneContext);

    const [published, setPublished] = useState(false);

    useEffect(() => {
        if (!loading) {
            setPublished(true);
        }
    }, [loading]);

    return (
        <div style={{ zIndex: 11 }} className={styles.loginContainerWrapper}>
            <div className={styles.publishModal}>
                {!published ? (
                    <>
                        <lottie-player
                            src="https://assets1.lottiefiles.com/packages/lf20_enrpmvws.json"
                            background="transparent"
                            speed="1.5"
                            style={{
                                width: `${100}px`,
                                height: `${100}px`,
                            }}
                            autoplay
                            loop
                        ></lottie-player>
                        <p>
                            We are pushing the fixes to{" "}
                            <span className={styles.blue}> {domain}</span>
                            <p>
                                Please wait
                                <LoadingDots />
                            </p>
                        </p>
                        <br />
                    </>
                ) : (
                    <>
                        <GoCheckCircle
                            style={{ marginTop: 10 }}
                            color="#2fc5a0"
                            size={40}
                        />
                        <h3 style={{ color: "#2fc5a0" }}>Success!</h3>
                        <p>
                            We have successfully pushed all 120 fixes on
                            <span className={styles.blue}> {domain}</span>
                        </p>
                        <p>All the changes will be visible on Logs screen.</p>
                    </>
                )}
                {published && (
                    <NavigatingText
                        onEnd={() => {
                            setPublishModal(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export const NoResultFound = () => (
    <div className={styles.noResults}>
        <SVGIcon size={180} src={"/New UI/SVG/Emptydocument.svg"} />
        No Results found
    </div>
);

export default WebtuneDashboard;
