import { Skeleton } from "@material-ui/lab";
import React from "react";
import styles from "./styles.module.css";

function ReportsSkeleton({ type }) {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeleton1}>
        <Skeleton variant="text" width={150} height={20} />
        <Skeleton variant="text" width={200} height={15} />
        <Skeleton variant="text" width={120} height={10} />
      </div>
      <div style={{ width: "150px" }}>
        <Skeleton
          style={{ margin: "auto" }}
          variant="text"
          width={50}
          height={20}
        />
      </div>
      {window.innerWidth > 650 && (
        <>
          <div style={{ width: "100px" }}>
            <Skeleton
              style={{ margin: "auto" }}
              variant="text"
              width={50}
              height={20}
            />
          </div>

          <div className={styles.skeleton2}>
            <Skeleton variant="text" width={250} height={30} />
          </div>
        </>
      )}
    </div>
  );
}

export default ReportsSkeleton;
