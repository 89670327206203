import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

import "./style.css";
const ValidationError = ({ msg, width = "100%" }) => {
  return (
    <>
      {msg &&
        (msg.toLowerCase().includes("already") &&
        msg.toLowerCase().includes("google") ? (
          <Alert style={{ width }} severity="success">
            {" "}
            Welcome Back! Use SSO option -{" "}
            <Link to="/auth/login">
              <u>Continue with Google</u>
            </Link>{" "}
            to access your Scalenut Dashboard.{" "}
          </Alert>
        ) : msg.toLowerCase().includes("already") ? (
          <Alert style={{ width }} severity="success">
            {msg} Please{" "}
            <Link to="/auth/login">
              <u>LOGIN</u>
            </Link>{" "}
            to continue
          </Alert>
        ) : msg.toLowerCase().includes("google") ? (
          <Alert style={{ width }} severity="success">
            {" "}
            Please login with google or{" "}
            <Link to="/auth/forgot">
              <u>RESET</u>
            </Link>{" "}
            your password
          </Alert>
        ) : msg.toLowerCase().includes("complete") ? (
          <Alert style={{ width }} severity="success">
            Please complete your{" "}
            <Link to="/auth/register">
              <u>REGISTRATION</u>
            </Link>{" "}
            with us
          </Alert>
        ) : (
          <Alert style={{ width }} severity="error">
            {msg}{" "}
          </Alert>
        ))}
    </>
  );
};

export default ValidationError;
