import selfEmp from "./assets/self-emp.svg";
import agency from "./assets/agency.svg";
import smBusi from "./assets/sm-busi.svg";
import enterprise from "./assets/enterprise.svg";

export const TOTAL_STEPS = 2;

export const userEnvironments = {
    selfEmp: "Self-employed",
    agency: "Agency",
};

export const stepWiseData = {
    1: {
        questions: [
            {
                text: ", What are your key SEO challenges?",
                options: [
                    "New Content Ideas",
                    "Content/Topic Research",
                    "SEO Content Writing",
                    "Content optimization",
                    "Technical SEO",
                    "Measurement & analysis",
                ],

                id: 5,
            },
        ],
    },
    2: {
        questions: [
            {
                text: "How would you describe your work environment?",
                options: [
                    { src: selfEmp, text: userEnvironments.selfEmp },
                    { src: agency, text: userEnvironments.agency },
                    {
                        src: smBusi,
                        text: "Small & Medium Business",
                    },
                    { src: enterprise, text: "Enterprise" },
                ],
                id: 2,
            },
            {
                text: "What’s your team size?",
                options: ["0-5", "6-50", "51-100", "100-500", "500+"],
                id: 3,
            },
        ],
    },
    3: {
        questions: [
            {
                text: "What describes you the best?",
                options: [
                    "Affiliate website Owner",
                    "SEO Strategist",
                    "SEO Writer",
                    "Blogger",
                    "Copywriter",
                ],
                options1: [
                    "Agency owner",
                    "Marketing leader",
                    "SEO Strategist",
                    "SEO Writer",
                    "Copywriter",
                ],
                options2: [
                    "Founder",
                    "Marketing leader",
                    "SEO Strategist",
                    "SEO Writer",
                    "Copywriter",
                ],

                id: 4,
            },
        ],
    },
    4: {
        questions: [
            {
                text: "Last, how did you hear about us :)",
                options: [
                    "Online Ads",
                    "Search Engine",
                    "Youtube",
                    "Social Media",
                    "Blogs or Publications",
                    "Events/Webinars",
                    "Friends",
                    "Third-party review",
                ],

                id: 6,
            },
        ],
    },
};
