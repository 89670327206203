import {
    SET_CHAT_WIDGET,
    SET_CRUISE_MODE_POPUP,
    SET_IS_MOBILE,
} from "../actiontypes";

const initialState = {
    isChatWidgetEnable: false,
    isMobile: document.body.clientWidth > 600 ? false : true,
    cruiseModePopup: {
        value: false,
        isClose: false,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CHAT_WIDGET:
            return {
                ...state,
                isChatWidgetEnable: action.data,
            };
        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.data,
            };
        case SET_CRUISE_MODE_POPUP:
            return {
                ...state,
                cruiseModePopup: action.data,
            };
        default:
            return state;
    }
}
