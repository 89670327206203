import { Skeleton } from "@material-ui/lab";
import React from "react";
import styles from "./NotySkeleton.module.css";

export default function ProjectCardSkeleton() {
  return (
    <>
      <div className={styles.projectCardSkeletonMobile}>
        <div className={styles.left}>
          <Skeleton variant="circle" width={50} height={50} />
          <div className={styles.row}>
            <Skeleton variant="text" width={90} height={14} />
            <Skeleton variant="text" width={60} height={10} />
          </div>
        </div>
        <div className={styles.right}>
          <Skeleton variant="text" width={50} height={10} />
        </div>
      </div>
      <div className={styles.projectCardSkeleton}>
        <div className={styles.left}>
          <Skeleton variant="circle" width={50} height={50} />
          <div className={styles.row}>
            <Skeleton variant="text" width={418} height={14} />
            <Skeleton variant="text" width={190} height={10} />
          </div>
        </div>
        <div className={styles.right}>
          <Skeleton variant="text" width={50} height={10} />
        </div>
      </div>
    </>
  );
}
