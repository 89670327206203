//Auth action
export const UPDATE_PLAN = "UPDATE_PLAN";
export const SHOW_REGISTER_PAGE = "SHOW_REGISTER_PAGE";
export const SHOW_LOGIN_PAGE = "SHOW_LOGIN_PAGE";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_INCORRECT_LOGIN_DETAILS = "AUTH_INCORRECT_LOGIN_DETAILS";
export const REDIRECT_TO_BUYNOW = "REDIRECT_TO_BUYNOW";
export const SIGNED_UP = "SIGNED_UP";
export const UPDATE_INFO = "UPDATE_INFO";
export const UPDATE_INFO_V2 = "UPDATE_INFO_V2";
export const VERIFICATION_MAIL = "VERIFICATION_MAIL";
//GENERIC FETCH ACTIONS

export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAIL = "FETCH_FAIL";
export const UPDATE_VARIANT_FILTER = "UPDATE_VARIANT_FILTER";
export const FETCH_SUCCESS_QUES = "FETCH_SUCCESS_QUES";

// Dashboard Actions
export const UPDATE_PROJECTS_DATA = "UPDATE_PROJECTS_DATA";
export const PUT_QUESTIONS = "PUT_QUESTIONS";
export const PROJECT_DETAILS = "PROJECT_DETAILS";
export const LOAD_PROJECTS = "LOADING_PROJECTS";
export const OPEN_PROJECT = "OPEN_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const SET_CRUISE_MODE_POPUP = "SET_CRUISE_MODE_POPUP";

//VARIANT DIALOG ACTIONS
// export const VARIANT_OPEN_DIALOG = 'VARIANT_OPEN_DIALOG';
// export const VARIANT_CLOSE_DIALOG = 'VARIANT_CLOSE_DIALOG';

//RESET PASSWORD

export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAIL = "FORGOT_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const PROGRESS = "PROGRESS";

// VARIANT

export const LOAD_VARIANTS = "LOAD_VARIANTS";
export const NO_VARIANT = "NO_VARIANT";
export const LOAD_USER_VARIANTS = "LOAD_USER_VARIANTS";
export const LOAD_GENERATE_VARIANTS = "LOAD_GENERATE_VARIANTS";
export const OPEN_VARIANT = "OPEN_VARIANT";

//OTHERINFO

export const UPDATE_OTHER_INFO = "UPDATE_OTHER_INFO";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";

//ALERTS

export const DISPLAY_ALERT = "DISPLAY_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//LOGOUT
export const AUTH_LOGOUT_VARIANT = "AUTH_LOGOUT_VARIANT";
export const AUTH_LOGOUT_DASHBOARD = "AUTH_LOGOUT_DASHBOARD";
export const AUTH_LOGOUT_OTHERINFO = "AUTH_LOGOUT_OTHERINFO";

//Video Cards
export const UPDATE_USER_VIDEO = "UPDATE_USER_VIDEO";
export const SET_USER_VIDEO = "SET_USER_VIDEO";
export const SET_OVERLAY_VIDEO = "SET_OVERLAY_VIDEO";

//App Config

export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const CONFIG_LOADING = "CONFIG_LOADING";
export const LOAD_CONFIG = "LOAD_CONFIG";

export const SET_CHAT_WIDGET = "SET_CHAT_WIDGET";
export const SET_IS_MOBILE = "SET_IS_MOBILE";

// Google Search Console

export const SET_USER_GSC_INFO = "SET_USER_GSC_INFO";
export const UPDATE_CONNECTED_DOMAINS = "UPDATE_CONNECTED_DOMAINS";
export const SET_USER_GSC_INFO_MONTHLY_INSIGHTS =
    "SET_USER_GSC_INFO_MONTHLY_INSIGHTS";
export const RESET_USER_GSC_INFO_MONTHLY_INSIGHTS =
    "RESET_USER_GSC_INFO_MONTHLY_INSIGHTS";
export const FLUSH_USER_GSC_INFO = "FLUSH_USER_GSC_INFO";
export const UPDATE_CF = "UPDATE_CF";
export const UPDATE_DEFAULT_CF = "UPDATE_DEFAULT_CF";
export const RESET_TO_DEFAULT_CF = "RESET_TO_DEFAULT_CF";
