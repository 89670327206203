import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import styles from "./styles.module.css";
import userDocsHook from "./userDocsHook";
import DocCard from "../../organisms/DocCard/DocCard";
import TagsFilter from "../../organisms/Account/TagsFilter";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import NewUIDropdown, {
    TYPES,
} from "../../molecules/NewUIDropdown/NewUIDropDown";
import SVGIcon from "../../atoms/SVGIcon";
import { BinTabs } from "../UserReports/UserReports";
import { useHistory } from "react-router-dom";
import NewUIDropDown from "../../molecules/NewUIDropdown/NewUIDropDown";
import Button from "../../atoms/Button/Button";
import { useSelector } from "react-redux";
import { BiPlus } from "react-icons/bi";

const sortByOptions = [
    { title: "Created At", id: 1 },
    { title: "Updated At", id: 2 },
];

function UserDocs({
    bin,
    newDoc,
    loading,
    createDoc,
    hasMore,
    requestData,
    openDoc,
    docs,
    setLoading,
    setRequestData,
    restoreDoc,
    deleteDoc,
    creatingDoc,
    setTabWiseStickyHeader,
}) {
    const [sortBy, setSortBy] = useState(sortByOptions[0]);
    const [filterTagList, setFilterTagsList] = useState([]);
    const [newDocName, SetNewDocName] = useState("");
    const [order, setOrder] = useState(0);
    const [initiateClose, setInitiateClose] = useState(false);
    const history = useHistory();

    const userVideo = useSelector((state) => state.video.docs);
    useEffect(() => {}, [userVideo]);
    useEffect(() => {
        setLoading(true);

        setRequestData((ps) => ({
            ...ps,
            page: 1,
            created: sortBy.id == 1 ? true : false,
            updated: sortBy.id == 2 ? true : false,
            is_deleted: bin ? true : false,
            tags: filterTagList.map((tag) => tag.id),
            order,
        }));
    }, [sortBy, bin, filterTagList, order]);

    useEffect(() => {
        setTabWiseStickyHeader(
            <div className={styles.rrFilters}>
                <Button
                    fontWeight={400}
                    text={"+ Create New Document"}
                    width={210}
                    fontSize={14}
                    handler={() => createDoc()}
                    loadingText={creatingDoc}
                    height={40}
                ></Button>

                <div style={{ padding: 0 }} className={styles.rrFilters}>
                    <div style={{ width: "280px" }}>
                        <Textfield
                            height={40}
                            containerStyle={{ marginBottom: "0" }}
                            props={{
                                onChange: (e) => {
                                    const value = e.target.value.trim();
                                    setRequestData((ps) => ({
                                        ...ps,
                                        search: value,
                                        page: 1,
                                    }));
                                },
                                style: {
                                    borderRadius: 3,
                                    border: "solid 1px rgba(75, 75, 85, 0.16)",
                                    backgroundColor: "#fff",
                                    paddingLeft: "40px",
                                    borderRadius: 3,
                                },
                            }}
                            placeholder={"Search AI Docs"}
                            label=""
                            Icon={() => (
                                <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />
                            )}
                            iconStyle={{
                                left: "10px",
                                right: "unset",
                                top: "20px",
                            }}
                        />
                    </div>

                    {bin ? (
                        <BinTabs page="SMART_DOCS" />
                    ) : (
                        <>
                            <TagsFilter
                                setFilterTagsList={setFilterTagsList}
                                style={{ with: "145px" }}
                            />

                            <NewUIDropDown
                                setOrder={setOrder}
                                order={order}
                                options={sortByOptions}
                                style={{
                                    textTransform: "capitalize",
                                    height: 40,
                                    color: "#4b4b55",
                                    width: 170,
                                }}
                                type={TYPES.SORT}
                                selectedOption={sortBy}
                                handler={setSortBy}
                            />
                        </>
                    )}
                </div>
            </div>,
        );
    }, [sortBy, order, sortByOptions]);

    return (
        <>
            <div>
                {loading ? (
                    <>
                        <ReportsSkeleton type={true} />
                        <ReportsSkeleton type={true} />
                        <ReportsSkeleton type={true} />
                        <ReportsSkeleton type={true} />
                    </>
                ) : (
                    <InfiniteScroll
                        dataLength={docs.length}
                        next={() => {
                            setRequestData((ps) => ({
                                ...ps,
                                page: requestData.page + 1,
                            }));
                        }}
                        scrollableTarget="scrollableDiv"
                        style={{ paddingBottom: 50 }}
                        hasMore={hasMore}
                        loader={
                            <>
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                            </>
                        }
                    >
                        {docs.length > 0 ? (
                            <table>
                                <tr className={styles.reportHeader}>
                                    <td>
                                        <span>Name</span>
                                    </td>

                                    <td
                                        style={{
                                            textAlign: "left",
                                            width: 150,
                                        }}
                                    >
                                        <span>{"Created at"}</span>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <span>{"Word Count"}</span>
                                    </td>

                                    <td style={{ width: 250 }}></td>
                                </tr>
                                {docs.map((doc) => {
                                    return (
                                        <DocCard
                                            openDoc={() => openDoc(doc.doc_id)}
                                            className={styles.doc}
                                            doc={doc}
                                            archived={bin}
                                            restoreDoc={restoreDoc}
                                            deleteDoc={deleteDoc}
                                            key={doc.document_id}
                                            aiHumanizer={false}
                                        />
                                    );
                                })}
                            </table>
                        ) : (
                            <div className={styles.noItems}>
                                <SVGIcon
                                    size={300}
                                    src={"/New UI/SVG/Emptydocument.svg"}
                                />
                                <h3 style={{ marginTop: -50 }}>
                                    No AI Docs found
                                </h3>
                            </div>
                        )}
                    </InfiniteScroll>
                )}
            </div>
        </>
    );
}

export default UserDocs;
