//export const url = "http://b1fd093ec129.ngrok.io";
export const PROD = true;

export const TALENT_DOMAIN = PROD
    ? "https://talentnetwork.scalenut.com"
    : "https://talent-scalenut.web.app";

export const WEBTUNE_DOMAIN = PROD
    ? "https://api.webtune.ai"
    : "https://non-prod.webtune.ai:3000";

// ******PORT**********
const TEST_PORT = 3001;

const ENV = import.meta.env;

const LIVE_API_URL = ENV.VITE_APP_LIVE_URL;

const TEST_API_URL = window.location.host.includes("staging")
    ? ENV.VITE_APP_STAGING_URL
    : `${ENV.VITE_APP_TEST_URL}:${TEST_PORT}`;

export const STRIPE_TOKEN = PROD
    ? ENV.VITE_APP_STRIPE_LIVE_TOKEN
    : ENV.VITE_APP_STRIPE_TEST_TOKEN1;

export const STRIPE_TOKEN1 = PROD
    ? ENV.VITE_APP_STRIPE_LIVE_TOKEN1
    : ENV.VITE_APP_STRIPE_TEST_TOKEN1;
export const STRIPE_TOKEN2 = PROD
    ? ENV.VITE_APP_STRIPE_LIVE_TOKEN2
    : ENV.VITE_APP_STRIPE_TEST_TOKEN2;

export const url = "https://beta-live-api.scalenut.com";

export const SMARTLOOK_KEY = "120763e5c9c2bb7fc6ac0eac807566e8f1ee1fe5";

// export const url = PROD
//     ? LIVE_API_URL
//     : "https://staging-beta-api.scalenut.com"; // for beta

//Cruisemode Phase1 : e282b054e46339820548f81e25e2e6ea
//Sample for Raman : 4f9c73bcb2c012bb95bdb3a2879f5e9f

export const mixPanelKey = PROD
    ? "83f36271f474b950a2a04ce0632d0282"
    : "e282b054e46339820548f81e25e2e6ea";

export const SAMPLE_REPORT_ID1 = PROD
    ? "9fae1440-bc7f-46b4-a7dd-542427d8a771"
    : "2c2e6733-d760-4e63-8ed9-fef80b85411e";
export const SAMPLE_REPORT_ID2 = PROD
    ? "9fae1440-bc7f-46b4-a7dd-542427d8a771"
    : "2c2e6733-d760-4e63-8ed9-fef80b85411e";
export const SAMPLE_REPORT_ID3 = PROD
    ? "9fae1440-bc7f-46b4-a7dd-542427d8a771"
    : "2c2e6733-d760-4e63-8ed9-fef80b85411e";

export const SAMPLE_CLUSTER_ID1 = PROD
    ? "fd310a9f-2583-40ff-ac3f-c977a20eb15c"
    : "fd310a9f-2583-40ff-ac3f-c977a20eb15c";
export const SAMPLE_CLUSTER_ID2 = PROD
    ? "fd310a9f-2583-40ff-ac3f-c977a20eb15c"
    : "fd310a9f-2583-40ff-ac3f-c977a20eb15c";
export const SAMPLE_CLUSTER_ID3 = PROD
    ? "fd310a9f-2583-40ff-ac3f-c977a20eb15c"
    : "fd310a9f-2583-40ff-ac3f-c977a20eb15c";

export const SENTRY_KEY =
    "https://2193e363953045e2acda65f56a4e7ad6@o1295381.ingest.sentry.io/6538470";
// export const url = "http://34.207.234.23:3006"; // Anshaj
// export const url = "https://api.scalenut.com"; //live
// export const url = "http://localhost:3003"; //local
export const EDIT_ACCESS_CODE = "56vge87e348";
export const READ_ACCESS_CODE = "kj8jd9r9do";
export const LOCATION_API_KEY_PROD = "08f3a6e6e72a4edda5b3717073c30fea";
export const LOCATION_API_KEY_TEST = "bc2f16a1d88449b08ce35ed147438aec";
export const CLARITY_KEY_TEST = "b0we78bxvx";
export const CLARITY_KEY_LIVE = "b1fj0d950b";
//export const VARIANTS_API_PATH = '/variantDetails';
export const VARIANTS_API_PATH = "/api/project/fetchAllVariants";
export const PROJECTS_API_PATH = "/api/project/priority/fetchSortedProjects";

// export const PROJECTS_API_PATH = url + "/api/project/create";
export const DESCRIPTION_API_PATH = "/api/project/variantDescription";
export const PROJECT_REQUIREMENTS = "/api/questionaire/getQuestions?variantId=";
export const PROJECT_DETAILS = "/api/project/projectDetail?projectId=";
//testing
export const scalenutItems = [
    {
        title: "Marketplace",
        image: "/admin/scalenut/dashboard/marketplace-icon.svg",
        subTitle: "Content & Design services from the top 1% freelancer talent",
        link: "dashboard",
    },
    null,
    {
        title: "Quality Assistant",
        image: "/admin/scalenut/dashboard/quality-icon.svg",
        subTitle: "Most elaborate content QA tool",
        link: "form",
    },
    {
        title: "Social",
        image: "/admin/scalenut/dashboard/social-icon.svg",
        subTitle: "Publish and schedule social media posts",
        link: "",
    },
    {
        title: "GenerateAI",
        image: "/admin/scalenut/dashboard/generate-icon.svg",
        subTitle: "AI generated content and ideas for your business",
        link: "generate",
    },
];

export const USER_WIDGET_KEY = "fpdmq0";
