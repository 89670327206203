import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./Overview.module.css";
import Table from "../general/Table";
import PeopleQuestionsOverview from "./PeopleQuestionsOverview";
import CitationTableNew from "./CitationTableNew";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import Highlighter from "../../../atoms/HightLighter/HighLighter";
import EditorBrief from "./EditorBrief";
import ContentBriefTable from "./ContentBriefTable";
import { useParams } from "react-router-dom";
import {
    Tab,
    Tabs,
    Box,
    Typography,
    makeStyles,
    Link,
} from "@material-ui/core";
import CompetitionTable from "../../../organisms/Research/CompetitionTable";
import NlpTopics from "../../../organisms/Research/npl-terms-table";
import SVGIcon from "../../../atoms/SVGIcon";
import TabPanelHeading from "./atoms/TabHeading/TabPanelHeading";
import NotOptimal from "./atoms/NotOptimal";
import StatsOverview from "./StatsOverview";
import ReportContext from "../../../organisms/Research/ReportContext";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const useStylesTabPanel = makeStyles((theme) => ({
    root: {
        "&>div": { margin: "0" },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStylesTabPanel();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography classes={classes}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStylesTab = makeStyles((theme) => ({
    root: {
        height: 38,
        padding: 0,
        minHeight: 38,
        fontSize: 16,
        textTransform: "none",
        display: "flex",
        color: "#29394a",
        fontWeight: 400,
        minWidth: "max-content",
        margin: " 0 20px ",
        opacity: 1,
        "&:hover": {
            color: "#1f4cbe",
        },
    },

    selected: {
        color: "#1f4cbe",
        fontWeight: 500,
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    root: {
        height: 38,
        padding: 0,
        backgroundColor: theme.palette.background.paper,
        minHeight: 38,
        paddingLeft: 0,
        // boxShadow: "0 4px 4px 0 rgba(196, 196, 196, 0.5)",
        position: "sticky",
        zIndex: 6,
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    indicator: {
        backgroundColor: "#1f4cbe",
    },
}));

let timeout = null;
function getEdStatus(readabilityScore) {
    if (readabilityScore === 0) {
        return "";
    } else if (readabilityScore <= 20) {
        return "University Graduates";
    } else if (readabilityScore >= 21 && readabilityScore <= 40) {
        return "College Graduates";
    } else if (readabilityScore >= 41 && readabilityScore <= 60) {
        return "High School";
    } else if (readabilityScore >= 61 && readabilityScore <= 70) {
        return "Mid School";
    } else return "4th Grader";
}

const tabs = [
    "Competition Overview",
    "Outlines",
    "Questions",
    "Statistics",
    "Citations",
    "Key Terms",
];

function getHoverText(name) {
    name = name.toLowerCase();
    if (name.includes("seo"))
        return "Average SEO score for the top ranking URLs";
    else if (name.includes("word"))
        return "Average number of words in the top ranking URLs";
    else if (name.includes("tag"))
        return "Average number of H-tags in the top ranking URL's";
    else if (name.includes("read"))
        return "Average readability score of top Ranking URLs";
    else if (name.includes("image"))
        return "Average number of images in the top ranking URL's";
}

export default function Overview({
    setAside,
    overview,
    relevantTerms,
    peopleQuestions,
    competetion,
    reportInfo,
    citations,
    isSharedReport,
    access,
    auditMode,
}) {
    const ref = useRef();

    const [contentBrief, setContentBrief] = useState(true);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { isStatsAllowed, isStatsLoading, stats } = useContext(ReportContext);

    const hoverText = {
        "Competition Overview": `Get competitive insights from Top ${reportInfo.competitors_count} ranking URLs`,
        "search-volume": "Search Volume",
        "Key Terms":
            "NLP powered list of key terms and phrases associated with search term",
        // "search-volume-analysis":
        //   "Keywords related to input along with their last 12 month search volume history and cost per clicks",
        competition: `Top ${reportInfo.competitors_count} Ranking URLs along with Qualily Scores and other parameters`,
        // references: "Themes, Questions asked by People, Citations etc.",
        themes: "Common Writing Themes similar to your search term",
        Questions:
            "Social listening+ AI suggestions for common questions on your primary search phrase",
        Statistics:
            "Add credibility to your articles by inluding statistics for a richer content experience",
        Citations: "Most commonly cited URL's in top ranking competition",
        Outlines: `Detailed outline view of top ${reportInfo.competitors_count} competitors`,
    };

    const getOverviewRows = (mobile = 0) => {
        let modifiedOverviewData = [];

        for (const key in overview.overviewRange.firstRange) {
            modifiedOverviewData.push({ name: key });
        }

        let i = 1;
        for (const property in overview.overviewRange) {
            for (const key in overview.overviewRange[property]) {
                modifiedOverviewData = modifiedOverviewData.map((item) => {
                    if (item.name === key) {
                        return {
                            ...item,
                            [i]: overview.overviewRange[property][key],
                        };
                    } else return item;
                });
            }
            i++;
        }
        if (mobile == 1) return modifiedOverviewData.reverse();
        console.log(modifiedOverviewData, "modified data");
        return (
            <>
                <thead className={styles.normalStatisticsOverview}>
                    <th> Top Page Statistics </th>
                    <th>
                        1-
                        {reportInfo.competitors_count == 30
                            ? 10
                            : reportInfo.competitors_count == 20
                            ? 5
                            : 3}
                    </th>
                    <th>
                        1-
                        {reportInfo.competitors_count == 30
                            ? 20
                            : reportInfo.competitors_count == 20
                            ? 10
                            : 5}
                    </th>
                    <th>1-{reportInfo.competitors_count}</th>
                </thead>
                {modifiedOverviewData.reverse().map((item) => {
                    if (item.name.includes("Max")) return <></>;
                    return item.name.includes("SEO") ? (
                        <tr>
                            <LightTooltip title={getHoverText(item.name)} arrow>
                                <td>{item.name.split("_").join(" ")} </td>
                            </LightTooltip>

                            <td>
                                {item.name === "Readability" ? (
                                    getEdStatus(Number(item[1]))
                                ) : (
                                    <Highlighter
                                        style={{ margin: "auto" }}
                                        quality={item[1]}
                                    />
                                )}
                            </td>
                            <td>
                                {item.name === "Readability" ? (
                                    getEdStatus(Number(item[2]))
                                ) : (
                                    <Highlighter
                                        style={{ margin: "auto" }}
                                        quality={item[2]}
                                    />
                                )}
                            </td>
                            <td>
                                {item.name === "Readability" ? (
                                    getEdStatus(Number(item[3]))
                                ) : (
                                    <Highlighter
                                        style={{ margin: "auto" }}
                                        quality={item[3]}
                                    />
                                )}
                            </td>
                        </tr>
                    ) : (
                        <tr>
                            <td>
                                {item.name.split("_").join(" ")}{" "}
                                <SVGIcon
                                    tooltip={getHoverText(item.name)}
                                    size={18}
                                    src={"/New UI/SVG/info.svg"}
                                />
                            </td>

                            <td>
                                {item.name === "Readability"
                                    ? getEdStatus(Number(item[1]))
                                    : item[1]}
                            </td>
                            <td>
                                {item.name === "Readability"
                                    ? getEdStatus(Number(item[2]))
                                    : item[2]}
                            </td>
                            <td>
                                {item.name === "Readability"
                                    ? getEdStatus(Number(item[3]))
                                    : item[3]}
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };
    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();

    return (
        <div className={styles.overview}>
            <div
                style={{ width: auditMode ? "100%" : "calc(9.5 / 14 * 100%)" }}
                className={styles.mainContainer}
            >
                <Tabs
                    classes={classesTabs}
                    value={value}
                    onChange={handleChange}
                >
                    {tabs.map((tab, i) => {
                        if (
                            (isStatsAllowed && tab == "Statistics") ||
                            tab != "Statistics"
                        ) {
                            return (
                                <LightTooltip
                                    title={hoverText[tab]}
                                    arrow
                                    placement="bottom"
                                >
                                    <Tab
                                        disableRipple={true}
                                        classes={classesTab}
                                        label={tab.split("-").join(" ")}
                                    />
                                </LightTooltip>
                            );
                        }
                    })}
                </Tabs>
                {reportInfo.is_completed == 3 && (
                    <NotOptimal
                        title={`We are unable to process a few URLs, due to which the report data might be sub-optimal. There is a high possibility that 'article / written content' is not the preferred user intent for this keyword.
You can still use editor and long-form Al tools.`}
                    />
                )}
                <div ref={ref} className={styles.bottom}>
                    <TabPanel value={value} index={0}>
                        <TabPanelHeading
                            heading={
                                <div style={{ display: "flex" }}>
                                    Overview of Top{" "}
                                    {reportInfo.competitors_count} Ranking URLs
                                </div>
                            }
                        />

                        <Table
                            tableType="1"
                            heading=""
                            className={styles.normalStatistics}
                            id=""
                        >
                            {getOverviewRows()}
                        </Table>

                        <CompetitionTable
                            list={competetion || []}
                            getEdStatus={getEdStatus}
                            contentBrief={contentBrief}
                            overview={overview}
                            auditMode={auditMode}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <ContentBriefTable
                            list={competetion || []}
                            isSharedReport={isSharedReport}
                            access={access}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <PeopleQuestionsOverview
                            list={peopleQuestions || []}
                            contentBrief={contentBrief}
                            isSharedReport={isSharedReport}
                            access={access}
                            auditMode={auditMode}
                        />
                    </TabPanel>
                    {isStatsAllowed ? (
                        <TabPanel value={value} index={3}>
                            <StatsOverview
                                list={stats}
                                contentBrief={contentBrief}
                                isSharedReport={isSharedReport}
                                access={access}
                                isLoading={isStatsLoading}
                            />
                        </TabPanel>
                    ) : (
                        ""
                    )}

                    <TabPanel value={value} index={!isStatsAllowed ? 3 : 4}>
                        <CitationTableNew
                            list={citations || []}
                            contentBrief={contentBrief}
                            isSharedReport={isSharedReport}
                            access={access}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={!isStatsAllowed ? 4 : 5}>
                        <TabPanelHeading
                            heading={
                                <div style={{ display: "flex" }}>
                                    NLP Extracted Key Terms to be included in
                                    content
                                </div>
                            }
                        />
                        <NlpTopics
                            reportInfo={reportInfo}
                            list={relevantTerms || []}
                        />
                    </TabPanel>
                </div>
            </div>

            {!auditMode && (
                <div className={styles.editorBriefBox}>
                    {contentBrief && <EditorBrief list={relevantTerms} />}
                </div>
            )}
        </div>
    );
}
