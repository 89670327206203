import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { url } from '../../utility/config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CircularProgress, TextField } from '@material-ui/core';
import styles from './superAdmin.module.css';
import EmailSuggestion from './EmailSuggestion';

export default function EnterpriseForm({ setActiveAction }) {
  const [email, setEmail] = useState('');
  const [CDR, setCDR] = useState(0);
  const [CDRLimitError, setCDRLimitError] = useState(false);
  const [expiry, setExpiry] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [userNotRegisteredError, setUserNotRegisteredError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [cdrError, setCDRError] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [random, setRandom] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !(CDR >= 0 && CDR <= 100)) return;

    setLoading(true);
    axios({
      method: 'post',
      url: `${url}/api/user/checkEmail`,
      data: { email },
    }).then((res) => {
      if (res.status === 201) {
        setLoading(false);
        setUserNotRegisteredError(true);
        setTimeout(() => setUserNotRegisteredError(false), 5000);
      } else if (res.status === 200) {
        axios({
          method: 'post',
          url: `${url}/api/user/cdrUpdateAdmin`,
          headers: {
            Authorization: token,
          },
          data: {
            userEmail: email,
            cdr: CDR,
            cdr_date: expiry,
          },
        })
          .then((res) => {
            setSuccessMessage(true);
            setTimeout(() => setSuccessMessage(false), 5000);
            setEmail('');
            setCDR(0);
            setExpiry(new Date());
            setLoading(false);
            setRandom(Math.random());
          })
          .catch((err) => {
            setCDRError(err);
            setLoading(false);
            setTimeout(() => setCDRError(false), 5000);
          });
      }
    });
  };

  return (
    <>
      <p
        style={{
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer',
          display: 'inline-block',
        }}
        onClick={() => setActiveAction(null)}
      >
        Go back
      </p>
      <form>
        <EmailSuggestion email={email} setEmail={setEmail} random={random} />
        <br />
        <div className={styles.marginBox}>
          <label htmlFor='cdr'>CDR(in %):</label>
          <input
            value={CDR}
            onChange={(e) => {
              setCDR(e.target.value);
              if (!(e.target.value >= 0 && e.target.value <= 100)) {
                setCDRLimitError(true);
              } else {
                setCDRLimitError(false);
              }
            }}
            id='cdr'
            name='cdr'
            min='0'
            max='100'
            type='number'
            className={styles.inputField}
          />
          {CDRLimitError && <span>Enter a number between 0 and 100</span>}
        </div>
        <br />
        <div className={styles.marginBox}>
          <label htmlFor='cdrExpiry'>CDR Expiry Date:</label>
          <DateTimePicker
            value={expiry}
            minDate={new Date()}
            onChange={setExpiry}
          />
        </div>
        <br />
        {loading ? (
          <CircularProgress />
        ) : (
          <button type='submit' onClick={handleSubmit}>
            Update CDR
          </button>
        )}
      </form>
      {userNotRegisteredError && <p>User not registered</p>}
      {successMessage && <p>CDR updated successfully</p>}
      {cdrError && <p>Error in updating CDR</p>}
    </>
  );
}
