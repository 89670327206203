import React, { useEffect, useState } from "react";
import styles from "./DealTopBF.module.css";
import SVGIcon from "../../componentsV2/atoms/SVGIcon";
import { LightTooltip } from "../../componentsV2/atoms/Tooltip/tooltip";
import { withStyles, Tooltip, useScrollTrigger } from "@material-ui/core";
import { IoIosInformationCircleOutline } from "react-icons/io";

const OfferTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #F1F1F1",
        },
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: "white",
        color: "black",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        position: "relative",
        maxWidth: "max-content",
        borderRadius: "7px",
        padding: "0",
    },
}))(Tooltip);

export function calculateResultantDiscount(
    oldDiscount: number,
    newDiscount: number,
) {
    // Convert percentages to decimal form for calculations
    // const oldDiscountDecimal = oldDiscount / 100;
    // const newDiscountDecimal = newDiscount / 100;

    // // Calculate the resultant discount
    // const resultantDiscount =
    //     1 - (1 - oldDiscountDecimal) * (1 - newDiscountDecimal);

    // // Convert back to percentage form for output
    // const resultantDiscountPercent = Math.floor(resultantDiscount * 100);

    return newDiscount;
}

const RewardsList = () => {
    return (
        <div className={styles.offerListWrapper}>
            <div className={styles.offerText}>
                Choose your add on from these exciting offers post annual
                subscription:
            </div>
            <div className={styles.offerList}>
                <div className={styles._left}>
                    <div className={`${styles.offer} ${styles.offer1}`}>
                        1) +10 SEO Articles
                    </div>
                    <div className={`${styles.offer} ${styles.offer2}`}>
                        2) +10 Optimizer report
                    </div>
                </div>
                <div className={styles._right}>
                    <div className={`${styles.offer} ${styles.offer3}`}>
                        3) +SEO Course for free
                    </div>
                    <div className={`${styles.offer} ${styles.offer4}`}>
                        4) +1 Additional User seat.
                    </div>
                </div>
            </div>
        </div>
    );
};

const DealTopBF = ({
    isCC = false,
    deals,
}: {
    isCC: boolean;
    deals: string;
}) => {
    const [_deals, setDeals] = useState<{
        annualPlanDiscount: number;
        dealCreditInr?: string;
        dealCreditsUsd?: string;
        dealLimitsMultiplier: number;
        name?: string;
        semiAnnualPlanDiscount?: string;
        slotsLeft?: string;
    }>({ annualPlanDiscount: 50, dealLimitsMultiplier: 2 });
    const [slotsLeft, setSlotsLeft] = useState([]);
    useEffect(() => {
        if (deals) {
            setDeals(JSON.parse(deals));
        }
    }, [deals]);
    const formattedValue: (n: any) => any = (n) => {
        return n?.toString()?.padStart(3, "0");
    };
    return (
        <div
            className={styles.bfDealWrapper}
            style={{ marginTop: isCC ? 0 : -20 }}
        >
            <div className={styles.bfDeal__left}>
                <div>
                    <div className={styles.dealText1}>AI-SEO ScaleDays</div>
                    <div className={styles.dealText2}>
                        Get{" "}
                        <span style={{ color: "#65f6ff" }}>
                            {_deals.annualPlanDiscount}% Off + 2X limits
                        </span>
                    </div>
                    <div className={styles.dealText3}>
                        For Life on all Annual Plans
                    </div>
                </div>
            </div>
            {/* <div className={styles.bfDeal__right}>
                <div className={styles.addOnWrapper}>
                    <div className={styles.addOnContainer}>
                        <div style={{ marginBottom: "15px", fontSize: "20px" }}>
                            Plus these{" "}
                            <span style={{ fontWeight: 700 }}>Four</span>{" "}
                            guaranteed add-ons:
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                            }}
                        >
                            <div className={styles.addOnItem1}>
                                • 4X Limits on All Features
                            </div>
                            <div className={styles.addOnItem}>
                                • 55% Off + 4X Limits on Link Manager
                            </div>
                            <div className={styles.addOnItem1}>
                                • 4 Additional User Seats
                            </div>
                            <div className={styles.addOnItem}>
                                • 55% Off + 4X Limits on Webtune
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default DealTopBF;
