let timeout = null;
export default function (params) {
    try {
        if (document.quill.editor.isEnabled() || params.allowed) {
            if (params.type == "text")
                document.quill.editor.insertText(
                    document.quill.editor.cursor,
                    params.data,
                    params.source,
                );
            else {
                document.quill.editor.insertEmbed(
                    document.quill.editor.cursor,
                    params.type,
                    params.data,
                    params.source,
                );
            }
            document.quill.editor.cursor += params.length;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                try {
                    document.quill.editor.setSelection(
                        params.dummyCursor
                            ? params.dummyCursor
                            : document.quill.editor.cursor,
                    );

                    document.quill.editor.focus();
                } catch (err) {
                    console.log(err);
                }
            }, 500);
        }
    } catch (err) {
        console.log(err);
    }
}
