import React from 'react';
import StatusBanner from '../../../../atoms/StatusBanner/StatusBanner';
import { BsClockHistory } from 'react-icons/bs';
import styles from './ProjectDetailHeader.module.css';
import { TextareaAutosize } from '@material-ui/core';
import { getTime } from '../../../../../utility/validateFunctions';

export default function ProjectDetailHeader({
  heading,
  variant,
  projectId,
  created_at,
  status,
  onBlur,
  onChange,
  value,
  onKeyPress,
  lastUpdatedBy,
  updated_at,
}) {
  console.log('DSA', updated_at);
  return (
    <div className={styles.projectDetailHeader}>
      <div className={styles.projectDetailHeaderInfo}>
        <TextareaAutosize
          className={styles.heading + ' ' + styles.inputProject}
          spellCheck={false}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          aria-label='minimum height'
          rowsMin={1}
        />
        <div className={styles.bottomInfo}>
          <span className={styles.variant}>{variant}</span>
          <span className={styles.reqId}>Request ID# {projectId}</span>
          <span className={styles.createdAt}>
            Last updated by{' '}
            <span className={styles.blueText}>{lastUpdatedBy}</span>,{' '}
            {getTime(updated_at)}
          </span>
        </div>
      </div>
      <StatusBanner
        text={status}
        icon={<BsClockHistory style={{ fontSize: '0.9rem' }} />}
        secondary
      />
    </div>
  );
}
