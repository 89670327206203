import { Tooltip, withStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: "none",
    },
}))(Tooltip);

const copyToClipboard = (text, getText) => {
    if (getText) text = getText();
    navigator.clipboard.writeText(text);
};

export default function CopyText({
    text,
    children,
    fBasis,
    addToEditor,
    getText,
    onCopy,
}) {
    const [isCopied, setIsCopied] = useState(false);
    console.log(text);

    // const copyToClipboard = () => {
    //   //changed to add to editor for time. Can revert easilty
    //   // addToEditor(text);
    //   // navigator.clipboard.writeText(" " + text);
    //   setIsCopied(true);
    // };

    useEffect(() => {
        let timer = null;
        if (isCopied) {
            timer = setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);

    return (
        <LightTooltip
            title={isCopied ? "Copied!" : "Click to Copy"}
            placement="top"
        >
            <div
                style={{
                    flexBasis: fBasis ? fBasis : "auto",
                    cursor: "pointer",
                }}
                onClick={() => {
                    if (text || getText) copyToClipboard(text, getText);
                    if (onCopy) onCopy();
                    setIsCopied(true);
                }}
            >
                {children}
            </div>
        </LightTooltip>
    );
}
