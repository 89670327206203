import React from "react";
import { Skeleton } from "@material-ui/lab";
import styles from "./styles.module.css";
export default function AsideSkeleton() {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.row}>
                <Skeleton variant="text" width="80%" height="40px" />
            </div>
            <div className={`${styles.row} ${styles.thinRow}`}>
                <Skeleton variant="text" width="50%" height="20px" />
            </div>
            <div className={styles.row}>
                <Skeleton variant="text" width="80%" height="40px" />
            </div>
            <div className={`${styles.row} ${styles.thinRow}`}>
                <Skeleton variant="text" width="50%" height="20px" />
            </div>
            <div className={styles.row}>
                <Skeleton variant="text" width="80%" height="40px" />
            </div>
            <div className={`${styles.row} ${styles.thinRow}`}>
                <Skeleton variant="text" width="50%" height="20px" />
            </div>
            <div className={styles.row}>
                <Skeleton variant="text" width="80%" height="40px" />
            </div>
            <div className={`${styles.row} ${styles.thinRow}`}>
                <Skeleton variant="text" width="50%" height="20px" />
            </div>
        </div>
    );
}
