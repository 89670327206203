import {
    FLUSH_USER_GSC_INFO,
    RESET_TO_DEFAULT_CF,
    RESET_USER_GSC_INFO_MONTHLY_INSIGHTS,
    SET_USER_GSC_INFO,
    SET_USER_GSC_INFO_MONTHLY_INSIGHTS,
    UPDATE_CF,
    UPDATE_DEFAULT_CF,
} from "../actiontypes";

export const setUserGscInfo = (data) => {
    return {
        type: SET_USER_GSC_INFO,
        data,
    };
};

export const setUserGscInfoMonthlyInsights = (data) => {
    return {
        type: SET_USER_GSC_INFO_MONTHLY_INSIGHTS,
        data,
    };
};

export const resetUserGscInfoMonthlyInsights = (data) => {
    return {
        type: RESET_USER_GSC_INFO_MONTHLY_INSIGHTS,
        data,
    };
};

export const flushUserGscInfo = (data) => {
    return {
        type: FLUSH_USER_GSC_INFO,
        data,
    };
};

export const updateCannibalizationFilters = (data) => {
    return {
        type: UPDATE_CF,
        data,
    };
};

export const updateDefaultCannibalizationFilters = (data) => {
    return {
        type: UPDATE_DEFAULT_CF,
        data,
    };
};

export const resetDefaultCannibalizationFilters = (data) => {
    return {
        type: RESET_TO_DEFAULT_CF,
        data,
    };
};
