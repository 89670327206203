import React from "react";

export default class ErrorBoundaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {}

    async componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, errorInfo, error });
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <this.props.fallback
                    error={this.state.error}
                    componentStack={this.state.errorInfo.componentStack}
                />
            );
        }

        return this.props.children;
    }
}
