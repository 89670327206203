import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { VscTrash } from "react-icons/vsc";
import { GiPencil } from "react-icons/gi";
import { IoIosClose } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import Modal from "../../atoms/Modal/Modal";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { Skeleton } from "@material-ui/lab";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import Button from "../../atoms/Button/Button";
import InviteMembers from "./InviteMembers";
import { useSelector } from "react-redux";
import SVGIcon from "../../atoms/SVGIcon";
import { ClickAwayListener } from "@material-ui/core";

const getInitials = (username) => {
    let initials = "";
    if (!username) return " ";
    const usernameArray = username.split(" ");
    initials += usernameArray[0].charAt(0);
    if (usernameArray.length > 1)
        initials += usernameArray[usernameArray.length - 1].charAt(0);

    return initials;
};

function getStatus(code) {
    switch (code) {
        case 1:
            return "Team Member";
        case 2:
            return "Invited";
        case 3:
            return "Deleted";
        case 4:
            return "Declined";
        case 5:
            return "Expired";
    }
}

function DummyRow() {
    return (
        <tr className={styles.memberRow}>
            <td>
                {" "}
                <Skeleton width={100}></Skeleton>{" "}
            </td>
            <td>
                <Skeleton width={120}></Skeleton>
            </td>
            <td className={styles.status}>
                <Skeleton width={80}></Skeleton>
            </td>
            <td className={styles.status}>
                <Skeleton width={80}></Skeleton>
            </td>
            <td className={styles.actionsTd}>
                <Skeleton width={50}></Skeleton>
            </td>
        </tr>
    );
}

function Row({
    member,
    setEditAccessModal,
    setEditAccessMember,
    removeMember,
}) {
    const [options, setOptions] = useState(false);

    return (
        <tr
            onMouseLeave={() => setOptions(false)}
            key={member.email}
            className={styles.memberRow}
        >
            <td style={{ textTransform: "capitalize" }}>
                <div className={styles.nameCol}>
                    <div className={styles.tMemberAvatar}>
                        {getInitials(member.name)}
                    </div>
                    {member.name}
                </div>
            </td>
            <td>{member.email}</td>

            <td>
                <div className={styles.status}>
                    {getStatus(member.status)}
                    <div
                        onClick={() => setOptions(true)}
                        className={styles.options}
                    >
                        <SVGIcon
                            color="lightgray"
                            size={24}
                            src="/New UI/SVG/dots.svg"
                        ></SVGIcon>

                        {options && (
                            <ClickAwayListener
                                onClickAway={() => setOptions(false)}
                            >
                                <div className={styles.actions}>
                                    {member.status <= 2 && (
                                        <LightTooltip
                                            onClick={() => {
                                                setEditAccessMember(member);
                                                setEditAccessModal(true);
                                            }}
                                            arrow
                                            title="Edit Tags"
                                            placement="right"
                                        >
                                            <span>
                                                <GiPencil
                                                    color="var(--primary-blue)"
                                                    className={styles.icon2}
                                                />{" "}
                                                Edit
                                            </span>
                                        </LightTooltip>
                                    )}
                                    <LightTooltip
                                        arrow
                                        title="Remove member"
                                        placement="right"
                                    >
                                        <span
                                            onClick={() => removeMember(member)}
                                        >
                                            <VscTrash
                                                color="red"
                                                className={styles.icon1}
                                            />{" "}
                                            Delete
                                        </span>
                                    </LightTooltip>
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
}

function ListMembers({
    members,
    removeMember,
    addTag,
    removeTag,
    tags,
    createTag,
    loading,
    allDomains,
    setAllDomains,
}) {
    const [editAccessModal, setEditAccessModal] = useState(false);
    const [editAccessMember, setEditAccessMember] = useState(null);
    const userInfo = useSelector((state) => state.auth);

    return (
        <div className={styles.tableParent}>
            <table style={{ minWidth: "500px" }}>
                <tr className={styles.tableHeading}>
                    <th>Name</th>
                    <th>Email</th>

                    <th style={{ width: 160 }}>Status</th>
                    {/* <th style={{ width: 30 }}></th> */}
                </tr>
                {loading ? (
                    <>
                        <DummyRow /> <DummyRow />
                        <DummyRow />
                        <DummyRow />
                    </>
                ) : (
                    <>
                        <tr key={userInfo.email} className={styles.memberRow}>
                            <td style={{ textTransform: "capitalize" }}>
                                <div className={styles.nameCol}>
                                    <div className={styles.tMemberAvatar}>
                                        {getInitials(userInfo.user_name)}
                                    </div>
                                    {userInfo.user_name}
                                </div>
                            </td>
                            <td>{userInfo.email}</td>

                            <td> Admin </td>
                            {/* <td className={styles.actionsTd}></td> */}
                        </tr>
                        {members.map((member) => {
                            return (
                                <Row
                                    member={member}
                                    addTag={addTag}
                                    createTag={createTag}
                                    tags={tags}
                                    loading={loading}
                                    removeMember={removeMember}
                                    setEditAccessModal={setEditAccessModal}
                                    setEditAccessMember={setEditAccessMember}
                                />
                            );
                        })}
                    </>
                )}
            </table>
            {editAccessModal && editAccessMember && (
                <Modal setModal={loading ? () => {} : setEditAccessModal}>
                    <InviteMembers
                        addTag={addTag}
                        editAccessInfo={{
                            email: editAccessMember.email,
                            name: editAccessMember.name,
                            tags: editAccessMember.tags,
                            user_id: editAccessMember.user_id,
                            domain_list: editAccessMember.domain_list || [],
                        }}
                        editAccessModal={true}
                        createTag={createTag}
                        tags={tags}
                        loading={loading}
                        setInviteModal={setEditAccessModal}
                        setEditAccessMember={setEditAccessMember}
                        allDomains={allDomains}
                        setAllDomains={setAllDomains}
                    ></InviteMembers>
                </Modal>
            )}
        </div>
    );
}

export default ListMembers;
