import React from "react";
import styles from "./styles.module.css";
import UserDropdown from "../../molecules/UserDropdown/UserDropdown";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import Button from "../../atoms/Button/Button";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { AiOutlineSchedule } from "react-icons/ai";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { useSelector } from "react-redux";
import { useState } from "react";
import { getTrailText } from "../../Pages/NewDashboard/constants";

export default function ({ style }: { style?: React.CSSProperties }) {
    const [clyModal, setClyModal] = useState(false);
    const { freeUser, planExpiresDate, planTrial } = useUserAccessHook();
    const { mixpanelTrack } = useMixpanelHook();
    const userInfo = useSelector((state: any) => state.auth);
    const { focus_countries } = useSelector((state: any) => state.appConfig);
    let is_focused_country = false;
    useCalendlyEventListener({
        onEventScheduled: (e) => {
            mixpanelTrack("Free forever - call scheduled");
        },
    });

    try {
        if (
            focus_countries &&
            (userInfo.country_name || userInfo.country) &&
            JSON.parse(focus_countries)
                .map((i: string) => i.toLowerCase())
                .includes(
                    (
                        userInfo.country_name ||
                        userInfo.country ||
                        ""
                    ).toLowerCase(),
                )
        ) {
            is_focused_country = true;
        }
    } catch (error) {
        console.log(error);
    }

    return (
        <div style={style} className={styles.items}>
            {freeUser && is_focused_country && (
                <Button
                    handler={() => {
                        setClyModal(true);
                        if (getTrailText(planExpiresDate) == 7 && planTrial) {
                            mixpanelTrack("Day 0 Dashboard", {
                                action: `Schedule a demo`,
                            });
                        }
                        mixpanelTrack("demo_clicked");
                    }}
                    text={"Schedule a demo"}
                    Icon={() => <AiOutlineSchedule size={20} />}
                    style={{
                        borderRadius: 34,
                        fontSize: 12,
                        fontWeight: 400,
                        marginRight: 3,
                    }}
                    iconReverse={true}
                    height={36}
                    width={150}
                ></Button>
            )}
            <PopupModal
                open={clyModal}
                onModalClose={() => setClyModal(false)}
                url="https://calendly.com/d/5gx-7jm-fw2/scalenut-demo?utm_source=product&utm_medium=dashb"
                rootElement={document.getElementById("root") || document.body}
            />
            <UserDropdown />
        </div>
    );
}
