import mixpanel from "mixpanel-browser";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { grade } from "../componentsV2/molecules/Creator/Overview/overallRatings";
import ReportContext from "../componentsV2/organisms/Research/ReportContext";
import { PLANS_CATEGORIES, PLANS_TYPE } from "../constants";
import { transactionTypes } from "../components/account/components/Transactions/Transactions";

export const incrementer = function (property) {
    let value = mixpanel?.get_property(property);
    let update = {};
    if (value && typeof value == "number") {
        update[property] = value + 1;
    } else {
        update[property] = 1;
    }
    x;

    mixpanel.register(update);
};

export const getEditorType = (pathname = "") => {
    if (pathname.includes("/docs")) {
        return "AI Doc";
    } else if (pathname.includes("/creator")) {
        return "Article Writer";
    } else if (pathname.includes("/audit")) {
        return "Content Optimizer";
    } else {
        return "-";
    }
};

export const getPlanInfo = ({ category = "", name = "", type = "" }) => {
    let plan = "";
    if (category == PLANS_CATEGORIES.TRAIL) {
        return "Trial Period";
    }

    switch (category) {
        case PLANS_CATEGORIES.PRO:
            plan = "Pro";
            break;
        case PLANS_CATEGORIES.PRO_MAX:
            plan = "Pro Max";
            break;
        case PLANS_CATEGORIES.INDIVIDUAL:
            plan = "Individual";
            break;
        case PLANS_CATEGORIES.GROWTH:
            plan = "Growth";
            break;
        case PLANS_CATEGORIES.GROWTH_MAX:
            plan = "Growth Max";
            break;
        case PLANS_CATEGORIES.ENTERPRISE:
            plan = "Enterprise";
            break;
        case PLANS_CATEGORIES.FREE_FOREVER:
            plan = "Free Forever";
            break;
        default:
            plan;
    }

    switch (type) {
        case PLANS_TYPE.MONTHLY:
            plan = plan + " " + "Monthly";
            break;
        case PLANS_TYPE.YEARLY:
            plan = plan + " " + "Yearly";
            break;
        case PLANS_TYPE.LIFETIME:
            plan = plan + " " + "Lifetime";
            break;
        case PLANS_TYPE.SEMI:
            plan = plan + " " + "Semi Annual";
            break;
        default:
            plan;
    }

    return plan;
};

const CruiseStepsMapping = [
    {
        stepNo: 1,
        stepName: "Context",
    },
    {
        stepNo: 2,
        stepName: "Title",
    },
    {
        stepNo: 3,
        stepName: "Outlines",
    },
    {
        stepNo: 4,
        stepName: "Writing Points",
    },
    {
        stepNo: 5,
        stepName: "First Draft",
    },
    {
        stepNo: 6,
        stepName: "Preview",
    },
];

const getCruiseRegenerateType = (type) => {
    switch (type) {
        case "Q":
            return "FAQ";
        default:
            return type;
    }
};

const useMixpanelHook = () => {
    const userInfo = useSelector((state) => state.auth);
    const { reportInfo } = useContext(ReportContext);

    function mpTrackChatWidget(eventName) {
        mixpanel.track(eventName);
    }

    function mpTrackDashboardVisit() {}

    function mpTrackCruiseEntryInProgress(path, doc) {
        const secondary_keywords = doc?.secondary_keywords
            ? JSON.parse(doc?.secondary_keywords)
            : [];
        let temp = null;
        temp = {
            "Entry Path": path,
            "Report ID": doc?.report_id,
            Keywords: secondary_keywords?.length
                ? [doc?.title, ...secondary_keywords]
                : [doc?.title],
            "Report In Progress": true,
            Location: doc?.search_country,
            "Current Step": CruiseStepsMapping[0].stepNo,
            "Current Step Name": CruiseStepsMapping[0].stepName,
            "Max Step": CruiseStepsMapping[0].stepNo,
        };
        mixpanel.track("Cruise Mode Entrance", temp);
        return;
    }

    function mpTrackCruiseEntry(path = "", doc = {}) {
        let temp = null;
        const secondary_keywords = doc?.secondary_keywords
            ? JSON.parse(doc?.secondary_keywords)
            : [];
        if (doc?.hasOwnProperty("hash")) {
            temp = {
                "Entry Path": path,
                "Report ID": doc?.hash,
                "Report Name": doc?.name,
                Location: doc?.search_country,
            };
        } else {
            temp = {
                "Entry Path": path,
                "Created at": doc?.created_at,
                "Report ID": doc?.report_id,
                Location: doc?.search_country,
                "Current Step": CruiseStepsMapping[doc?.maxStep]?.stepNo,
                "Current Step Name": CruiseStepsMapping[doc?.maxStep]?.stepName,
                "Max Step": CruiseStepsMapping[doc?.maxStep]?.stepNo,
                Keywords: secondary_keywords?.length
                    ? [doc?.title, ...secondary_keywords]
                    : [doc?.title],
                "Report In Progress": false,
            };
        }
        mixpanel.track("Cruise Mode Entrance", temp);
        return;
    }

    function mpTrackCruiseAdditionalKeywordsClicked() {
        let temp = {
            "Report ID": reportInfo?.report_id,
        };
        mixpanel.track("Add Additional Keywords Clicked", temp);
    }

    function mpTrackCruiseAdditionalKeywordsAdded() {
        let temp = {
            "Report ID": reportInfo?.report_id,
        };
        mixpanel.track("Additional Keyword Added", temp);
    }

    function mpTrackCruiseWatchDemo() {
        let temp = {
            "Report ID": reportInfo?.report_id,
        };
        mixpanel.track("Cruise Watch Demo", temp);
    }

    function mpTrackCruiseNext({
        activeStep,
        maxStep,
        stepName,
        realtimeRating,
        stepsData,
        timeTaken,
    }) {
        let secondary_keywords = reportInfo?.secondary_keywords?.length
            ? JSON.parse(reportInfo?.secondary_keywords)
            : [];

        let temp = {
            Keywords: secondary_keywords?.length
                ? [reportInfo?.title, ...secondary_keywords]
                : [reportInfo?.title],
            "Report ID": reportInfo.report_id,
            "Current Step": CruiseStepsMapping[activeStep]?.stepNo,
            "Max Step":
                maxStep < activeStep
                    ? CruiseStepsMapping[activeStep]?.stepNo
                    : maxStep + 1,
            "Current Step Name": CruiseStepsMapping[activeStep]?.stepName,
            "# Headings Presence": stepsData?.outlines?.hasOwnProperty(
                "headings",
            )
                ? stepsData?.outlines?.headings?.length
                : 0,
            "Current Word Count": realtimeRating.wordCount,
            "Current Content Grade": grade(realtimeRating.quality),
            "Time Spent": timeTaken,
        };
        mixpanel.track("Cruise Next", temp);
    }

    function mpTrackCruisePrevious({
        activeStep,
        maxStep,
        stepName,
        timeTaken,
    }) {
        let secondary_keywords = reportInfo?.secondary_keywords?.length
            ? JSON.parse(reportInfo?.secondary_keywords)
            : [];

        let temp = {
            Keywords: secondary_keywords?.length
                ? [reportInfo?.title, ...secondary_keywords]
                : [reportInfo?.title],
            "Report ID": reportInfo.report_id,
            "Current Step": CruiseStepsMapping[activeStep]?.stepNo,
            "Max Step":
                maxStep < activeStep
                    ? CruiseStepsMapping[activeStep]?.stepNo
                    : maxStep + 1,
            "Current Step Name": CruiseStepsMapping[activeStep]?.stepName,
            "Time Spent": timeTaken,
        };
        mixpanel.track("Cruise Previous", temp);
        return;
    }

    function mpTrackCruiseInitialScreenLoad({
        screen,
        countH,
        countFaq,
        timeTaken,
    }) {
        let temp = {
            Screen: screen,
            "No. of Headings": countH,
            "No. of Questions": countFaq,
            "Time Taken": timeTaken,
        };

        mixpanel.track("Initial Screen Load", temp);
    }

    function mpTrackCruiseAddition({ type, origin, platform }) {
        let temp = {
            "Additon Type": type,
            "Addition Origin": origin,
            "Report ID": reportInfo?.report_id,
            "Competion source": platform ? platform : "-",
        };
        mixpanel.track("Cruise Addition", temp);
    }

    function mpTrackCruiseDeletion({ delType, wpType }) {
        let temp = {
            "Delete Type": delType ? delType : "-",
            "WP Type": wpType ? wpType : "-",
        };
        mixpanel.track("Cruise Deletion", temp);
    }

    function mpTrackCruiseHighlightKeyTerms({ status }) {
        let temp = {
            State: status ? "On" : "Off",
            "Report ID": reportInfo.report_id,
        };
        mixpanel.track("CruiseMode Highlight KeyTerms", temp);
    }

    function mpTrackCruiseRegenerate({ type, timeTaken }) {
        let temp = {
            "Regeneration Type": getCruiseRegenerateType(type),
            "Time Taken": timeTaken,
            "Report ID": reportInfo?.report_id,
        };
        mixpanel.track("Cruise Regenerate", temp);
    }

    function mpTrackCruisePreview({ activeStep, stepName, maxStep }) {
        let secondary_keywords = reportInfo?.secondary_keywords?.length
            ? JSON.parse(reportInfo?.secondary_keywords)
            : [];

        let temp = {
            Keywords: secondary_keywords?.length
                ? [reportInfo?.title, ...secondary_keywords]
                : [reportInfo?.title],
            Location: reportInfo?.search_country,
            "Report ID": reportInfo?.report_id,
            "Current Step": CruiseStepsMapping[activeStep]?.stepNo,
            "Max Step":
                maxStep < activeStep
                    ? CruiseStepsMapping[activeStep]?.stepNo
                    : maxStep + 1,
            "Current Step Name": CruiseStepsMapping[activeStep]?.stepName,
        };
        mixpanel.track("Cruise Preview", temp);
    }

    function mpTrackCruiseExport(timeTaken) {
        let secondary_keywords = reportInfo?.secondary_keywords?.length
            ? JSON.parse(reportInfo?.secondary_keywords)
            : [];
        let temp = {
            Keywords: secondary_keywords?.length
                ? [reportInfo?.title, ...secondary_keywords]
                : [reportInfo?.title],
            "Report ID": reportInfo.report_id,
            timeTaken,
        };
        mixpanel.track("Cruise Export", temp);
    }

    function mpTrackCruiseDownload(type) {
        let temp = {
            "Type of Download": type,
        };
        mixpanel.track("Cruise Download", temp);
    }

    function mpTrackCruiseExit({
        reportInfo,
        stepsData,
        activeStep,
        stepName,
        realtimeRating,
        maxStep,
        path,
        maxTime,
        ctaUsed,
    }) {
        let secondary_keywords = reportInfo?.secondary_keywords?.length
            ? JSON.parse(reportInfo?.secondary_keywords)
            : [];

        let temp = {
            Keywords: secondary_keywords?.length
                ? [reportInfo?.title, ...secondary_keywords]
                : [reportInfo?.title],
            "CTA Used": ctaUsed,
            Location: reportInfo?.search_country,
            "Report ID": reportInfo?.report_id,
            "Current Step": CruiseStepsMapping[activeStep]?.stepNo,
            "Current Step Name": CruiseStepsMapping[activeStep]?.stepName,
            "Max Step":
                maxStep < activeStep
                    ? CruiseStepsMapping[activeStep]?.stepNo
                    : maxStep + 1,
            "# Headings Presence": stepsData?.outlines?.hasOwnProperty(
                "headings",
            )
                ? stepsData?.outlines?.headings?.length
                : 0,
            "Word Count": realtimeRating?.wordCount,
            "Content Grade": grade(realtimeRating?.quality),
            "Report In Progress": reportInfo.reportLoading,
            "Total Time Spent": maxTime,
            "Entry Path": path,
        };
        mixpanel.track("Cruise Exit", temp);
    }

    // AI Generate Use-cases
    function mpTrackAiGenerate(useCase) {
        let temp = {
            "Use Case Category": useCase.cat_name,
            "Use Case Name": useCase.name,
        };
        mixpanel.track("Short Form Generate", temp);
    }

    function mpTrackAiRegenerate(useCase) {
        let temp = {
            "Use Case Category": useCase.cat_name,
            "Use Case Name": useCase.name,
        };
        mixpanel.track("Short Form Regenerate", temp);
    }

    function mpTrackCampaignOfferPopupPopped({ platform, type = "" }) {
        let temp = {
            Platform: transactionTypes[platform],
            Type: type,
        };
        mixpanel.track("Offer Popup Open", temp);
    }

    function mpTrackExitIntentOfferPopupPopped(Campaign = "") {
        // let temp = {
        //     Campaign: Campaign,
        // };
        mixpanel.track("Exit Intent Offer Popup Open");
    }

    function mpTrackExitIntentOfferGrabbed(Campaign = "") {
        // let temp = {
        //     Campaign: Campaign,
        // };
        mixpanel.track("Exit Intent Offer Grabbed");
    }
    function mpTrackCampaignOfferPopupClosed({ cta, isCreditUsed }) {
        let temp = {
            "Cta Used": cta,
            isCreditUsed: isCreditUsed,
            // Campaign: "BF Deals",
        };
        mixpanel.track("Offer Popup Closed", temp);
    }
    function mpTrackCampaignOfferPopupUpgrade({ ctaUsed }) {
        let temp = {
            "Cta Used": ctaUsed,
            // Campaign: "BF Deals",
        };
        mixpanel.track("Plan Page Visited", temp);
    }

    function mpTrackCampaignOfferPlanUpgrade(item) {
        let temp = {
            Action: item.action,
            "Plan Id": item.plan_id,
            "Plan Name": item.plan_name,
            // Campaign: "BF Deals",
        };
        mixpanel.track("Subscription Plan Upgrade", temp);
    }
    function mpTrackCampaignOfferPlanCompared(state) {
        mixpanel.track("Plan Compared", {
            "Comparison Table": state,
        });
    }

    function mpTrackCampaignOfferPlanLeave(state) {
        mixpanel.track("Plan Page Leave");
    }

    function mpTrackPaymentStatus({ campaign, status }) {
        let temp = {
            Campaign: campaign,
            Status: status,
        };
        mixpanel.track("Payment Status", temp);
    }

    function mpTrackDashboard(eventName) {
        mixpanel.track(eventName + " Dashboard");
    }

    //   for Share report
    function mpTrackShareReportCta({ from = "" }) {
        let temp = {
            "Shared From": from.length ? from : "-",
            "Report ID": reportInfo.report_id,
        };

        mixpanel.track("Report Share CTA", temp);
    }

    function mpTrackShareAccessType({ type = "" }) {
        let temp = {
            "Access Type": type.length ? type : "-",
            "Report ID": reportInfo.report_id,
        };

        mixpanel.track("Report Share Access Type", temp);
    }

    function mpTrackBreadCrumb({ type }) {
        let temp = {
            "Type of Bread Crumb": type,
        };
        mixpanel.track("Bread Crumb Clicked", temp);
    }

    function mpTrackReportCreateCta({
        reportType = "",
        keywords = "",
        url = "",
        search_country,
        cta_screen = "",
    }) {
        let temp = {
            "Report Type": reportType ? reportType : "-",
            Keywords: keywords,
            URL: url ? url : "-",
            Location: search_country,
            "CTA Screen": cta_screen,
        };
        mixpanel.track("Report Create CTA", temp);
    }

    function mpTrackOpenReportCta({
        reportType = "",
        ctaUsed = "",
        report_id = "",
    }) {
        let temp = {
            "Report Type": reportType ? reportType : "-",
            "CTA Used": ctaUsed ? ctaUsed : "-",
            "Report ID": report_id ? report_id : "-",
        };

        mixpanel.track("Open Report Cta", temp);
    }

    function mpTrackRefreshSEOScore({ position = "" }) {
        let temp = {
            Position: position?.length ? position : "-",
        };
        mixpanel.track("Refresh SEO Score CTA", temp);
    }

    function mpTrackStatsOpened({ screen = "" }) {
        let temp = {
            Screen: screen ? screen : "-",
            "Report ID": reportInfo?.report_id,
        };
        mixpanel.track("Statistic Opened", temp);
    }

    function mpTrackStatsUsed({ cta = "", droppedIn = "" }) {
        let temp = {
            "CTA Used": cta ? cta : "-",
            "Dropped To": droppedIn ? droppedIn : "-",
            "Report ID": reportInfo?.report_id,
        };
        mixpanel.track("Statistic Used", temp);
    }

    function mpTrackAddToEditor({ useCase, editorType = "" }) {
        let temp = {
            "Use Case Category": useCase?.cat_name ? useCase?.cat_name : "-",
            "Use Case Name": useCase?.name ? useCase?.name : "-",
            "Editor Type": editorType ? getEditorType(editorType) : "-",
        };
        mixpanel.track("Add To Editor", temp);
    }

    function mixpanelTrack(eventName = "", data = {}) {
        try {
            return mixpanel.track(eventName, data);
        } catch (error) {
            console.log(error);
        }
    }

    function mpTrackFixItCTA({ panelName }) {
        let temp = {
            "Fix it Section": panelName ? panelName : "-",
        };
        console.log(temp);
        mixpanel.track("Fix it Button Clicked", temp);
    }

    function mpTrackFixItActions({ panelName, userAction }) {
        let temp = {
            "Fix it Section": panelName ? panelName : "-",
            "User Action": userAction ? userAction : "-",
        };
        console.log(temp);
        mixpanel.track("Fix it Action", temp);
    }

    function mpTrackSkipToEditor({ currentStep, maxStep }) {
        let temp = {
            clicked: true,
            "Current Step": currentStep + 1,
            "Max Step": maxStep + 1,
        };
        mixpanel.track("Skip to editor", temp);
    }

    function mpTrackMarkAsComplete({ location, isCompleted }) {
        let temp = {
            location: location ? location : "",
            "mark as completed": isCompleted ? true : false,
        };
        mixpanel.track("Mark as complete", temp);
    }

    function mpTrackMarkAsComplete({ location, isCompleted }) {
        let temp = {
            location: location ? location : "",
            "mark as completed": isCompleted ? true : false,
        };
        mixpanel.track("Mark as complete", temp);
    }

    function mpTrackRegenerationClicked({ type }) {
        let temp = {
            "Regeneration Type": type ? type : "",
        };
        console.log(temp, "mpTrackRegenerationClicked");
        mixpanel.track("Regeneration Clicked", temp);
    }

    function mpTrackAddNewToneClicked() {
        mixpanel.track("Add New Tone Clicked(Cruise Mode)");
    }

    function mpTrackToneAdded({ location, source }) {
        let temp = {
            location: location ? location : "",
            source: source ? source : "",
        };
        console.log(temp, "mpTrackToneAdded");
        mixpanel.track("Add New Tone", temp);
    }

    function mpTrackInfoIconClicked({ location }) {
        let temp = {
            location: location ? location : "",
        };
        console.log(temp, "mpTrackInfoIconClicked");
        mixpanel.track("Info Icon Clicked", temp);
    }

    function mpTrackToneAdded({ location, source }) {
        let temp = {
            location: location ? location : "",
            source: source ? source : "",
        };
        console.log(temp, "mpTrackToneAdded");
        mixpanel.track("Add New Tone", temp);
    }

    function mpTrackGenerateMore({ screen, section }) {
        let temp = {
            "Screen Name": screen ? screen : "",
            Section: section ? section : "",
        };
        console.log(temp, "mpTrackToneAdded");
        mixpanel.track("Generate more clicked", temp);
    }

    return {
        mpTrackDashboardVisit,
        mpTrackCruiseEntry,
        mpTrackReportCreateCta,
        mpTrackOpenReportCta,
        mpTrackCruiseAdditionalKeywordsClicked,
        mpTrackCruiseAdditionalKeywordsAdded,
        mpTrackCruiseWatchDemo,
        mpTrackCruiseNext,
        mpTrackCruisePrevious,
        mpTrackCruiseInitialScreenLoad,
        mpTrackCruiseAddition,
        mpTrackCruiseRegenerate,
        mpTrackCruisePreview,
        mpTrackCruiseExport,
        mpTrackCruiseDownload,
        mpTrackCruiseExit,
        mpTrackCruiseEntryInProgress,
        mpTrackCruiseHighlightKeyTerms,
        mpTrackCruiseDeletion,
        mpTrackAiGenerate,
        mpTrackAiRegenerate,
        mpTrackCampaignOfferPopupPopped,
        mpTrackCampaignOfferPopupClosed,
        mpTrackCampaignOfferPopupUpgrade,
        mpTrackCampaignOfferPlanUpgrade,
        mpTrackCampaignOfferPlanCompared,
        mpTrackCampaignOfferPlanLeave,
        mpTrackExitIntentOfferPopupPopped,
        mpTrackExitIntentOfferGrabbed,
        mpTrackPaymentStatus,
        mpTrackDashboard,
        mpTrackShareReportCta,
        mpTrackShareAccessType,
        mpTrackShareReportCta,
        mpTrackShareAccessType,
        mpTrackBreadCrumb,
        mpTrackRefreshSEOScore,
        mpTrackStatsOpened,
        mpTrackStatsUsed,
        mpTrackAddToEditor,
        mpTrackChatWidget,
        mixpanelTrack,
        mpTrackFixItCTA,
        mpTrackFixItActions,
        mpTrackSkipToEditor,
        mpTrackMarkAsComplete,
        mpTrackRegenerationClicked,
        mpTrackToneAdded,
        mpTrackAddNewToneClicked,
        mpTrackInfoIconClicked,
        mpTrackGenerateMore,
    };
};

export default useMixpanelHook;
