import React from "react";
import styles from "./HighLighter.module.css";

function HighLighter(props) {
    const { text, quality = "", style = {} } = props;
    let colorVal;
    let backgroundColorVal;
    if (quality <= 40) {
        colorVal = "#fc1024";
        backgroundColorVal = "#fff3f2";
    } else if (quality > 40 && quality <= 80) {
        colorVal = "#F1A126";
        backgroundColorVal = "#FEF8E6";
    } else if (quality > 80) {
        colorVal = "#179309";
        backgroundColorVal = "#eef5e1";
    }
    return (
        <div
            className={styles.highlight}
            style={{
                color: colorVal,
                backgroundColor: backgroundColorVal,
                ...style,
            }}
        >
            {text ? <span style={{ fontWeight: 400 }}>{text}&nbsp;</span> : ""}
            {quality ? quality : "-"}
        </div>
    );
}

export default HighLighter;
