import React from 'react';
import styles from './../../assets/styles/zeroCard.module.css';
import useNavigation from '../../componentsV2/atoms/GoToRoute/useNavigation';

function ZeroCard({ text, dashboard }) {
  const goTo = useNavigation();
  return (
    <div className={styles.container}>
      <div>
        {!dashboard ? (
          <img src='/admin/scalenut/new.svg' />
        ) : (
          <img width={250} src='/AssetsV2/norequest1.svg' />
        )}
      </div>

      {dashboard ? (
        <div>
          <div className={styles.text2}>
            No New Requests currently, place a new one
            <span
              style={{
                color: 'var(--primary-blue)',
                cursor: 'pointer',
              }}
              onClick={() => {
                goTo('VARIANTS_SCREEN');
              }}
            >
              {' '}
              here{' '}
            </span>
            .
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.text1}>{"You're all caught up!"}</div>
          <div className={styles.text2}>{'No New ' + text + '.'}</div>
        </div>
      )}
    </div>
  );
}

export default ZeroCard;
