import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "./FixItModule.module.css";
import ReportContext from "../../Research/ReportContext";
import SVGIcon from "../../../atoms/SVGIcon";
import { FixItSections } from "./fixitConstants";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
} from "@material-ui/core";
import { MdNavigateNext } from "react-icons/md";
import KeyTermFixIt from "./KeytermFixIt";
import FeaturedSnippetFixIt from "./FeaturedSnippetFixIt";
import CardActions from "./CardActions";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { escapeRegExp } from "../../../../utility/helperFun";

const customAccordion = makeStyles((theme) => ({
    root: {
        background: "transparent",
        boxShadow: "none  !important",
        minHeight: "0px !important",
        color: "#29394a",
    },
    expanded: {
        maxHeight: "max-content",
    },
}));

const customAccordionSummary = makeStyles((theme) => ({
    root: {
        minHeight: 36,
        padding: "0px 16px !important",
        display: "block",
    },
    expanded: {
        border: "none !important",
        maxHeight: "64px !important",
        minHeight: "48px !important",
        margin: "0px !important",
    },
    expandIcon: {
        display: "none",
    },
    content: {
        margin: "0px",
    },
}));

const customAccordionDetails = makeStyles((theme) => ({
    root: {
        padding: "0px 16px 16px !important",
    },
}));

const FixItCards = ({
    item = {},
    setFixedCount = () => {},
    keyterm = "",
    link = "",
    itemIndex = -1,
    handler = () => {},
    expanded = false,
    sectionName = "",
    setCurrentIndex = () => {},
    addH1 = () => {},
    fixItToQuill = () => {},
    setIsDoneDisabled = () => {},
    setNewH1,
    dismissH1 = () => {},
    setOriginalPara = () => {},
    originalPara,
    addFeaturedSnippet,
    setMetaFixIt = () => {},
    setMetaData = () => {},
    currentIndex,
    setCurrentDiffText,
    suggestions,
    setNumberOfInterlinks,
}) => {
    const accordionClasses = customAccordion();
    const accordionSummaryClasses = customAccordionSummary();
    const accordionDetailsClasses = customAccordionDetails();
    const [showAns, setShowAns] = useState(false);
    const { reportInfo, metaData, getSeoScore } = useContext(ReportContext);
    const [fixed, setFixed] = useState(false);
    const [headingIndex, setHeadingIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const cardRef = useRef();
    const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0);
    const [answer, setAnswer] = useState([]);
    const [keywordIndexes, setKeywordIndexes] = useState(0);
    const { mpTrackFixItActions, mixpanelTrack } = useMixpanelHook();
    const flagRef = useRef(null);
    useEffect(() => {
        if (
            sectionName == FixItSections.KEY_TERMS &&
            itemIndex == currentIndex
        ) {
            setCurrentDiffText(
                suggestions[currentIndex - 1]?.prompts[0]?.diff[
                    currentHeadingIndex
                ],
            );
        } else if (
            sectionName == FixItSections.LINKS &&
            keywordIndexes.length &&
            expanded
        ) {
            if (suggestions[currentIndex - 1]?.keyword) {
                const node = document.quill.editor.getLeaf(
                    keywordIndexes[currentHeadingIndex] + 1,
                );
                document.quill.editor.formatText(
                    keywordIndexes[currentHeadingIndex],
                    suggestions[currentIndex - 1]?.keyword?.length,
                    {
                        background: "#5454df38",
                        link: suggestions[currentIndex - 1]?.page,
                    },
                );
                node[0]?.domNode?.parentNode?.classList?.add(styles.fixItPara);
                setTimeout(() => {
                    node[0]?.domNode?.parentNode?.scrollIntoView({
                        block: "center",
                        behavior: "instant",
                    });
                }, 100);
            }
        }
        return () => {
            if (
                sectionName == FixItSections.LINKS &&
                keywordIndexes.length &&
                expanded &&
                !flagRef.current
            ) {
                let highlightPara =
                    document?.quill?.editor?.root?.getElementsByClassName(
                        styles.fixItPara,
                    )[0];
                highlightPara?.classList?.remove(styles.fixItPara);

                if (suggestions[currentIndex - 1]?.keyword) {
                    document.quill.editor.deleteText(
                        keywordIndexes[currentHeadingIndex],
                        suggestions[currentIndex - 1]?.keyword?.length,
                    );
                    document.quill.editor.insertText(
                        keywordIndexes[currentHeadingIndex],
                        suggestions[currentIndex - 1]?.keyword,
                    );
                }
            }
            flagRef.current = false;
        };
    }, [currentHeadingIndex, currentIndex, keywordIndexes]);

    useEffect(() => {
        if (sectionName == FixItSections.LINKS) {
            let indexArr = [];
            const re = new RegExp(`${escapeRegExp(keyterm)}\\b`, "gi");
            const matches = document.quill.editor.getText().matchAll(re);
            for (let search of matches) {
                let formatObj = document.quill?.editor?.getFormat(
                    search.index,
                    keyterm.length,
                );
                if (formatObj?.link || formatObj?.bold || formatObj?.header) {
                    continue;
                } else {
                    indexArr.push(search.index);
                }
            }
            setKeywordIndexes(indexArr);
        }
    }, [suggestions]);

    const cardScrollTop = () => {
        cardRef.current.parentNode.scrollTop =
            cardRef.current.offsetTop - cardRef.current.clientHeight - 90;
    };
    const metaCardScrollTop = () => {
        cardRef.current.parentNode.scrollTop =
            cardRef.current.offsetTop - cardRef.current.clientHeight - 110;
    };

    const convertH1 = (id) => {
        let newH2 = document.createElement("h2");
        let extraH1 = document.getElementById(id);
        newH2.innerText = `${extraH1.innerText}`;
        extraH1.parentNode.insertBefore(newH2, extraH1);
        extraH1?.remove();
    };

    return sectionName == FixItSections.LINKS && keywordIndexes.length < 1 ? (
        <></>
    ) : (
        <div
            onClick={() => setShowAns(!showAns)}
            className={styles.suggestion_item}
            style={{
                background: expanded && !fixed ? "#014DC5" : "#ffffff",
            }}
            ref={cardRef}
        >
            <Accordion
                expanded={expanded}
                onChange={(e) => {
                    if (sectionName == FixItSections.META_TAGS && !fixed) {
                        handler(item, !expanded, fixed);
                    } else {
                        handler(item, !expanded, fixed);
                    }
                }}
                classes={accordionClasses}
            >
                <AccordionSummary
                    expandIcon={<></>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={accordionSummaryClasses}
                >
                    <div className={styles._head}>
                        <div
                            style={{
                                maxWidth: expanded || fixed ? "70%" : "100%",
                                color:
                                    expanded && !fixed ? "#ffffff" : "inherit",
                            }}
                            className={styles.keyterm}
                        >
                            {sectionName == FixItSections.LINKS
                                ? "Keyword: "
                                : ""}
                            {keyterm}
                            {sectionName == FixItSections.LINKS && (
                                <div
                                    style={{
                                        width: "100%",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        marginTop: "5px",
                                    }}
                                >
                                    Link:{" "}
                                    <LightTooltip arrow title={link}>
                                        {expanded ? (
                                            <a
                                                style={{
                                                    color: expanded
                                                        ? "#fff"
                                                        : "#29394a",
                                                    textDecoration: "none",
                                                }}
                                                target="_blank"
                                                href={link}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                className={styles.interlink}
                                            >
                                                {link
                                                    .toLowerCase()
                                                    .indexOf("https://") == 0
                                                    ? link.slice(8)
                                                    : link}
                                            </a>
                                        ) : (
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={link}
                                            >
                                                <span>
                                                    {link
                                                        .toLowerCase()
                                                        .indexOf("https://") ==
                                                    0
                                                        ? link.slice(8)
                                                        : link}
                                                </span>
                                            </LightTooltip>
                                        )}
                                    </LightTooltip>
                                </div>
                            )}
                        </div>
                        {fixed ? (
                            <div className={styles.status}>
                                <SVGIcon
                                    src={"/New UI/SVG/fixIt_check.svg"}
                                    size={"12"}
                                    style={{ color: "#1DC198" }}
                                />
                                Fixed
                            </div>
                        ) : ((sectionName == FixItSections.H1_HEADING &&
                              keyterm != "Fix Extra H1") ||
                              sectionName == FixItSections.FEATURED_SNIPPET ||
                              sectionName == FixItSections.META_TAGS ||
                              sectionName == FixItSections.URL ||
                              sectionName == FixItSections.KEY_TERMS ||
                              sectionName == FixItSections.LINKS) &&
                          expanded ? (
                            <Navigation
                                maxLength={
                                    sectionName == FixItSections.KEY_TERMS
                                        ? item?.prompts[0]?.diff?.length
                                        : sectionName ==
                                          FixItSections.H1_HEADING
                                        ? item?.headings?.length
                                        : sectionName == FixItSections.LINKS
                                        ? keywordIndexes.length
                                        : item?.question?.length
                                }
                                currentHeadingIndex={currentHeadingIndex}
                                setCurrentHeadingIndex={setCurrentHeadingIndex}
                                setNewH1={setNewH1}
                                item={item}
                                sectionName={sectionName}
                                setMetaFixIt={setMetaFixIt}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </AccordionSummary>
                {!fixed && (
                    <AccordionDetails classes={accordionDetailsClasses}>
                        {sectionName == FixItSections.KEY_TERMS && (
                            <KeyTermFixIt
                                item={item}
                                setCurrentIndex={setCurrentIndex}
                                currentHeadingIndex={currentHeadingIndex}
                                fixItToQuill={fixItToQuill}
                                setFixed={setFixed}
                                fixed={fixed}
                                setIsDoneDisabled={setIsDoneDisabled}
                                cardRef={cardRef}
                                setOriginalPara={setOriginalPara}
                                originalPara={originalPara}
                                setFixedCount={setFixedCount}
                                sectionName={sectionName}
                            />
                        )}
                        {sectionName == FixItSections.FEATURED_SNIPPET && (
                            <div className={styles.paaContainer}>
                                <FeaturedSnippetFixIt
                                    q={item.question[currentHeadingIndex]}
                                    reportInfo={reportInfo}
                                    setFixed={setFixed}
                                    answer={answer}
                                    setAnswer={setAnswer}
                                    currentHeadingIndex={currentHeadingIndex}
                                />
                                <CardActions
                                    handleFunction={() => {
                                        mpTrackFixItActions({
                                            panelName: sectionName,
                                            userAction: "Accept",
                                        });
                                        addFeaturedSnippet(
                                            item.question[currentHeadingIndex]
                                                ?.question,
                                            answer[currentHeadingIndex],
                                        );
                                        setFixed(true);
                                        setCurrentIndex((ps) => ps + 1);
                                        cardScrollTop();
                                        setFixedCount((ps) => ps + 1);
                                    }}
                                    firstButtontext={"Add"}
                                    dismissFunction={() => {
                                        mpTrackFixItActions({
                                            panelName: sectionName,
                                            userAction: "Dismiss",
                                        });
                                        setCurrentIndex((ps) => ps + 1);
                                        cardScrollTop();
                                    }}
                                />
                            </div>
                        )}
                        {sectionName == FixItSections.H1_HEADING && (
                            <>
                                {keyterm == "Fix Extra H1" ? (
                                    <div className={styles.actions}>
                                        {!fixed ? (
                                            <CardActions
                                                handleFunction={() => {
                                                    mpTrackFixItActions({
                                                        panelName: sectionName,
                                                        userAction: "Accept",
                                                    });
                                                    convertH1(item.id);
                                                    setFixed(true);
                                                    fixItToQuill();
                                                    setCurrentIndex(
                                                        (ps) => ps + 1,
                                                    );
                                                    cardScrollTop();
                                                    setFixedCount(
                                                        (ps) => ps + 1,
                                                    );
                                                }}
                                                firstButtontext={
                                                    "Convert to H2"
                                                }
                                                dismissFunction={() => {
                                                    setCurrentIndex(
                                                        (ps) => ps + 1,
                                                    );
                                                    cardScrollTop();
                                                    mpTrackFixItActions({
                                                        panelName: sectionName,
                                                        userAction: "Dismiss",
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        <div className={styles.actions}>
                                            <CardActions
                                                handleFunction={() => {
                                                    mpTrackFixItActions({
                                                        panelName: sectionName,
                                                        userAction: "Accept",
                                                    });
                                                    addH1(
                                                        item?.headings[
                                                            currentHeadingIndex
                                                        ],
                                                    );
                                                    setFixed(true);
                                                    fixItToQuill();
                                                    cardScrollTop();
                                                    setFixedCount(
                                                        (ps) => ps + 1,
                                                    );
                                                }}
                                                firstButtontext={
                                                    keyterm == "Add New H1"
                                                        ? "Add"
                                                        : "Replace"
                                                }
                                                dismissFunction={() => {
                                                    dismissH1();
                                                    setCurrentHeadingIndex(0);
                                                    cardScrollTop();
                                                    mpTrackFixItActions({
                                                        panelName: sectionName,
                                                        userAction: "Dismiss",
                                                    });
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {sectionName == FixItSections.META_TAGS && (
                            <div className={styles.actions}>
                                <CardActions
                                    handleFunction={() => {
                                        mpTrackFixItActions({
                                            panelName: sectionName,
                                            userAction: "Accept",
                                        });
                                        document?.quill?.editor?.enable();
                                        keyterm
                                            ?.toLowerCase()
                                            ?.includes("title")
                                            ? setMetaData((ps) => ({
                                                  ...ps,
                                                  title: item?.question[
                                                      currentHeadingIndex
                                                  ],
                                                  fromAPI: false,
                                              }))
                                            : setMetaData((ps) => ({
                                                  ...ps,
                                                  description:
                                                      item?.question[
                                                          currentHeadingIndex
                                                      ],
                                                  fromAPI: false,
                                              }));
                                        // setTimeout(() => {
                                        //     document?.quill?.editor?.disable();
                                        // }, 2100);
                                        setFixed(true);
                                        setCurrentIndex((ps) => ps + 1);
                                        metaCardScrollTop();
                                        setFixedCount((ps) => ps + 1);
                                    }}
                                    firstButtontext={
                                        keyterm.includes("Add new Meta")
                                            ? "Add"
                                            : "Replace"
                                    }
                                    dismissFunction={() => {
                                        setCurrentIndex((ps) => ps + 1);
                                        setCurrentHeadingIndex(0);
                                        metaCardScrollTop();
                                        mpTrackFixItActions({
                                            panelName: sectionName,
                                            userAction: "Dismiss",
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {sectionName == FixItSections.URL && (
                            <div className={styles.actions}>
                                <CardActions
                                    handleFunction={() => {
                                        mpTrackFixItActions({
                                            panelName: sectionName,
                                            userAction: "Accept",
                                        });
                                        document?.quill?.editor?.enable();
                                        keyterm
                                            ?.toLowerCase()
                                            ?.includes("url") &&
                                            setMetaData((ps) => ({
                                                ...ps,
                                                url: item?.question[
                                                    currentHeadingIndex
                                                ],
                                                fromAPI: false,
                                            }));

                                        // setTimeout(() => {
                                        //     document?.quill?.editor?.disable();
                                        // }, 2100);
                                        setFixed(true);
                                        setCurrentIndex((ps) => ps + 1);
                                        cardScrollTop();
                                        setFixedCount((ps) => ps + 1);
                                    }}
                                    firstButtontext={
                                        keyterm.includes("Add new")
                                            ? "Add"
                                            : "Replace"
                                    }
                                    dismissFunction={() => {
                                        setCurrentIndex((ps) => ps + 1);
                                        setCurrentHeadingIndex(0);
                                        cardScrollTop();
                                        mpTrackFixItActions({
                                            panelName: sectionName,
                                            userAction: "Dismiss",
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {sectionName == FixItSections.LINKS && (
                            <div className={styles.actions}>
                                <CardActions
                                    handleFunction={() => {
                                        flagRef.current = true;
                                        mixpanelTrack("Interlinking Uses", {
                                            action: "Accept",
                                            keyterm: keyterm,
                                            link: link,
                                        });
                                        mpTrackFixItActions({
                                            panelName: "Interlinking",
                                            userAction: "Accept",
                                        });
                                        let formatting =
                                            document.quill.editor.getFormat(
                                                keywordIndexes[
                                                    currentHeadingIndex
                                                ],
                                            );
                                        if (!formatting.row) {
                                            document.quill.editor.removeFormat(
                                                keywordIndexes[
                                                    currentHeadingIndex
                                                ],
                                                suggestions[currentIndex - 1]
                                                    ?.keyword?.length,
                                            );
                                            document.quill.editor.formatText(
                                                keywordIndexes[
                                                    currentHeadingIndex
                                                ],
                                                keyterm.length,
                                                "link",
                                                link,
                                            );
                                        } else {
                                            document.quill.editor.formatText(
                                                keywordIndexes[
                                                    currentHeadingIndex
                                                ],
                                                keyterm.length,
                                                {
                                                    ...formatting,
                                                    background: "",
                                                    link: link,
                                                },
                                            );
                                        }

                                        let highlightPara =
                                            document?.quill?.editor?.root?.getElementsByClassName(
                                                styles.fixItPara,
                                            )[0];
                                        highlightPara?.classList?.remove(
                                            styles.fixItPara,
                                        );
                                        // document?.quill?.editor?.enable();

                                        // setTimeout(() => {
                                        //     document?.quill?.editor?.disable();
                                        // }, 2200);
                                        setFixed(true);
                                        setCurrentIndex((ps) => ps + 1);
                                        metaCardScrollTop();
                                        setFixedCount((ps) => ps + 1);
                                        setNumberOfInterlinks((ps) => ps + 1);
                                    }}
                                    firstButtontext={"Add"}
                                    dismissFunction={() => {
                                        setCurrentIndex((ps) => ps + 1);
                                        setCurrentHeadingIndex(0);
                                        cardScrollTop();
                                        mixpanelTrack("Interlinking Uses", {
                                            action: "Dismiss",
                                            keyterm: keyterm,
                                            link: link,
                                        });
                                        mpTrackFixItActions({
                                            panelName: "Interlinking",
                                            userAction: "Dismiss",
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </AccordionDetails>
                )}
            </Accordion>
        </div>
    );
};

const Navigation = ({
    maxLength,
    currentHeadingIndex,
    setCurrentHeadingIndex,
    sectionName,
    setNewH1,
    item,
    setMetaFixIt,
}) => {
    useEffect(() => {
        console.log("cccccccc", currentHeadingIndex);
    }, [currentHeadingIndex]);
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
            }}
        >
            <MdNavigateNext
                onClick={(e) => {
                    if (currentHeadingIndex == 0) {
                        e.stopPropagation();
                    } else {
                        setCurrentHeadingIndex((ps) =>
                            ps !== 0 ? (ps - 1) % maxLength : ps,
                        );
                        if (sectionName == FixItSections.H1_HEADING) {
                            setNewH1(
                                item?.headings[
                                    (currentHeadingIndex - 1) % maxLength
                                ],
                            );
                        } else if (sectionName == FixItSections.META_TAGS) {
                            if (
                                item?.keyterm?.toLowerCase()?.includes("title")
                            ) {
                                setMetaFixIt((ps) => ({
                                    ...ps,
                                    newMetaTitle:
                                        item?.question[
                                            (currentHeadingIndex - 1) %
                                                maxLength
                                        ],
                                }));
                            } else if (
                                item?.keyterm?.toLowerCase()?.includes("desc")
                            ) {
                                setMetaFixIt((ps) => ({
                                    ...ps,
                                    newMetaDesc:
                                        item?.question[
                                            (currentHeadingIndex - 1) %
                                                maxLength
                                        ],
                                }));
                            } else if (
                                item?.keyterm?.toLowerCase()?.includes("url")
                            ) {
                                setMetaFixIt((ps) => ({
                                    ...ps,
                                    newMetaUrl:
                                        item?.question[
                                            (currentHeadingIndex - 1) %
                                                maxLength
                                        ],
                                }));
                            }
                        }
                        e.stopPropagation();
                    }
                }}
                size={26}
                color={"#ffffff"}
                style={{ rotate: "180deg" }}
            />
            <div style={{ color: "#fff", fontWeight: "300" }}>
                {currentHeadingIndex + 1} of {maxLength}
            </div>
            <MdNavigateNext
                onClick={(e) => {
                    setCurrentHeadingIndex((ps) => (ps + 1) % maxLength);
                    if (sectionName == FixItSections.H1_HEADING) {
                        setNewH1(
                            item?.headings[
                                (currentHeadingIndex + 1) % maxLength
                            ],
                        );
                    } else if (sectionName == FixItSections.URL) {
                        if (item?.keyterm?.toLowerCase()?.includes("url")) {
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaUrl:
                                    item?.question[
                                        (currentHeadingIndex + 1) % maxLength
                                    ],
                            }));
                        }
                    } else if (sectionName == FixItSections.META_TAGS) {
                        if (item?.keyterm?.toLowerCase()?.includes("title")) {
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaTitle:
                                    item?.question[
                                        (currentHeadingIndex + 1) % maxLength
                                    ],
                            }));
                        } else if (
                            item?.keyterm?.toLowerCase()?.includes("desc")
                        ) {
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaDesc:
                                    item?.question[
                                        (currentHeadingIndex + 1) % maxLength
                                    ],
                            }));
                        }
                    }
                    e.stopPropagation();
                }}
                size={26}
                color={"#ffffff"}
            />
        </div>
    );
};

export default FixItCards;
