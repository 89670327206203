import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import useHelperFunctions from "../../helperFunctions";
import styles from "./styles.module.css";
import { SEARCH_EMAIL } from "../../api.json";
import { TextField } from "@material-ui/core";
import Label from "../Label/Label";
import { CircularProgress } from "@material-ui/core";

let execute = null;
function AutoComplete({ setUserId, setEmail, email, filter = [] }) {
    const { getData } = useHelperFunctions();
    const [allDbEmails, setAllDbEmails] = useState([]);
    const [loading, setLoading] = useState(false);

    async function searchEmails(id) {
        if (id.length >= 3) {
            setLoading(true);
            const response = await getData({
                url: SEARCH_EMAIL + encodeURIComponent(id),
            });

            if (response?.status === 200) {
                if (typeof filter == "object" && filter.length > 0) {
                    response.data.data = response.data.data.filter((item) =>
                        filter.includes(item.role),
                    );
                }

                setAllDbEmails(response.data.data);
                setLoading(false);
            }
        } else if (id.length == 0) {
            setAllDbEmails([]);
            setLoading(false);
        }
    }

    const dbounce = (key) => {
        clearTimeout(execute);
        execute = setTimeout(() => {
            searchEmails(key);
        }, 500);
    };

    return (
        <>
            <Label label="Email" />

            <Autocomplete
                className={styles.auto}
                id="users"
                freeSolo
                onChange={(event, newValue) => {
                    if (newValue) {
                        setEmail(newValue);
                        if (
                            allDbEmails.filter(
                                (item) => item.email === newValue,
                            )[0]
                        )
                            setUserId(
                                allDbEmails.filter(
                                    (item) => item.email === newValue,
                                )[0],
                            );
                    } else {
                        setEmail("");
                        setUserId({
                            id: "",
                            email: "",
                        });
                    }
                }}
                value={email}
                options={allDbEmails.map((item) => item.email)}
                renderInput={(params) => (
                    <>
                        {loading && (
                            <CircularProgress
                                className={styles.progress}
                                size={15}
                            />
                        )}
                        <TextField
                            className={styles.field}
                            {...params}
                            margin="normal"
                            onChange={(e) => dbounce(e.target.value)}
                        />
                    </>
                )}
            />
        </>
    );
}

export default AutoComplete;
