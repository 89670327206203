import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { ImAttachment } from "react-icons/im";
import { AiOutlineHistory } from "react-icons/ai";
import { useSelector } from "react-redux";
import { BsFillMicFill } from "react-icons/bs";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { FaStop } from "react-icons/fa";
import styles from "./activity.module.css";
import { RecordingContext } from "../../../../RecordingContext";
import Comment from "./Comment";
import { url } from "../../../../../utility/config";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import fileSaver from "file-saver";
import Button from "../../../../../componentsV2/atoms/Button/Button";
import Dropdown from "../../../../../componentsV2/atoms/Dropdown/Dropdown";
import Confirmation from "../../../../../componentsV2/atoms/ConfirmationDialog/confirmation";

export default function Activity({
  projectID,
  timelineData,
  setTempComments,
  tempComments,
  activeTab,
  freelancerStatus,
  didAssign,
  setNoFreelancerAssignedError,
  data,
  userOptions,
}) {
  const [comment, setComment] = useState("");
  let [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [timer, setTimer] = useState(0);
  const [stage, setStage] = useState(0);
  const [dialog, setDialog] = useState(false);
  const userID = useSelector((state) => state.auth.userId);
  const role = useSelector((state) => state.auth.role);
  const token = useSelector((state) => state.auth.token);
  const [freelancer, setFreelancer] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    heading: "Are you sure, You want to text",
    textType: false,
    handler: () => {},
  });
  console.log(freelancer);

  const ref = useRef();
  const {
    isRecording,
    audioRecord,
    screenRecord,
    setIsRecording,
    recTimer,
    stopRecording,
  } = useContext(RecordingContext);

  const [recTimer1, setRecTimer] = useState(recTimer.current);

  function toBase(blob) {
    return URL.createObjectURL(blob);
  }

  const getInitials = (username) => {
    let initials = "";
    if (!username) username = "Test User";
    const usernameArray = username.split(" ");
    initials += usernameArray[0].charAt(0);
    initials += usernameArray[usernameArray.length - 1].charAt(0);

    return initials;
  };

  const selectFile = (e) => {
    if (false) {
      const img = document.createElement("img");
      document.body.append(img);

      img.src = URL.createObjectURL(e.target.files[0]);

      showMarkerArea(img, setFile, true, null, e.target.files[0].name);
      fileInputRef.current.value = "";
    } else {
      setFile(e.target.files[0]);
    }
  };

  let abc = useRef(0);
  useEffect(() => {
    if (isRecording.on) {
      abc.current = setInterval(() => {
        setRecTimer((a) => a + 1);
      }, 1000);

      return () => {
        clearInterval(abc.current);
      };
    }
  }, []);

  useEffect(() => {
    if (role != "free_lancer") setStage(userOptions[0]?.id);
    else {
      if (userOptions.length > 0) {
        for (const stage of data.stages) {
          if (stage.stage_assinee_id == userID) {
            return setStage(stage.id);
          }
        }
      }
    }
  }, [userOptions]);

  // console.log(file);
  const postComment = async () => {
    try {
      if (!file && !comment && !isRecording.audioData && !isRecording.videoData)
        return;

      let link = "";

      if (isRecording.audioData || isRecording.videoData) {
        setLoading(true);
        const mediaFd = new FormData();
        mediaFd.append(
          "image",
          isRecording.audioData ? isRecording.audioData : isRecording.videoData
        );

        const result = await axios({
          method: "post",
          url: url + "/api/project/uploadFile",
          data: mediaFd,

          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        });

        link = result.data.data.attachment;
        setIsRecording({
          ...isRecording,
          audioData: null,
          videoData: null,
          projectID: null,
        });
      }

      const fd = new FormData();
      fd.append("userID", userID);
      fd.append("stage", stage ? stage : 0);
      fd.append("projectID", projectID);
      fd.append(
        "isFreelancer",
        data.stages
          ? stage == userOptions[0]?.id
            ? false
            : true
          : freelancer
          ? true
          : false
      );
      fd.append("message", comment);
      if (file) {
        fd.append("image", file, file.name);
      }

      if ((isRecording.audioData || isRecording.videoData) && link) {
        fd.append("media", link);
        // 1 for audio and 2 for video
        fd.append("mediaType", isRecording.audioData ? 1 : 2);
      } else {
        // 3 for file and 0 for message
        fd.append("mediaType", file ? 3 : 0);
      }

      setComment("");
      setFile(null);

      setLoading(true);

      const res = await axios({
        method: "post",
        url: `${url}/api/dbApi/project/history/create`,
        data: fd,
        headers: {
          Authorization: token,
        },
      });
      let tempArray = [...tempComments];
      if (res.data.data) tempArray.unshift(res.data.data);
      setTempComments(tempArray);
      setLoading(false);
      setDialog(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDialog(false);
    }
  };
  console.log(isRecording.videoData);
  function convertMS(milliseconds) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;

    if (seconds < 10) seconds = "0" + seconds;
    if (minute < 10) minute = "0" + minute;

    return `${minute}:${seconds}`;
  }

  function getUserName(id) {
    try {
      if (userOptions[0]?.id == id) {
        return "Client";
      } else
        for (const stage of data.stages) {
          if (stage.id == id) {
            return stage.stage_assinee_name;
          }
        }
    } catch {
      return "----";
    }
  }
  return (
    <div className={styles.activity}>
      {dialog && (
        <Confirmation
          setDialog={setDialog}
          submit={dialogConfig.textType}
          handler={dialogConfig.handler}
          modalHeading={dialogConfig.heading}
        ></Confirmation>
      )}
      {timer > 0 && (
        <div className={styles.timer}>
          Recording starts in {timer}
          {timer == 3 ? "." : timer == 2 ? ".." : "..."}{" "}
        </div>
      )}
      <div ref={ref} className={styles.activityHeading}>
        <AiOutlineHistory style={{ color: "#92929d", fontSize: "1.2rem" }} />
        <p>Activity</p>
      </div>
      <div className={styles.commentBox}>
        {/* <span className={styles.profileAvatar}>{getInitials(username)}</span> */}
        <div className={styles.inputBox}>
          <div>
            <TextareaAutosize
              spellCheck={false}
              onChange={(event) => setComment(event.target.value)}
              value={comment}
              aria-label="minimum height"
              rowsMin={4}
              placeholder="Add a comment or upload a file..."
              className={styles.textAreaMui}
            />
          </div>

          <div className={styles.buttonGroup}>
            <Button
              // className={`${styles.saveButton} ${
              //   activeTab === 'client' ? styles.showClientShadow : ''
              // }`}
              width="145px"
              fontSize="11px"
              disabled={!comment}
              text={"Send"}
              handler={() => {
                if (role == "admin") {
                  setDialogConfig({
                    heading:
                      "Are you sure, You want to text " +
                      (data.stages
                        ? getUserName(stage)
                        : freelancer
                        ? "Freelancer"
                        : "Client"),
                    textType: false,
                    handler: postComment,
                  });
                  setDialog(true);
                } else {
                  postComment();
                }
              }}
              // style={{
              //   backgroundColor:
              //     activeTab === 'freelance' ? '#ff974a' : '#3dd598',
              // }}
            ></Button>
            {role == "admin" && data.stages && (
              <Dropdown handler={setStage} options={userOptions} label="" />
            )}

            {role == "admin" && !data.stages && (
              <Dropdown
                handler={setFreelancer}
                options={[
                  { id: false, name: "Client" },
                  { id: true, name: "FreeLancer" },
                ]}
                label=""
              />
            )}

            <input
              type="file"
              style={{ display: "none" }}
              onChange={selectFile}
              ref={fileInputRef}
            />
            <div className={styles.fileUpload}>
              <ImAttachment
                style={{ fontSize: "2rem", color: "#92929d" }}
                className={styles.iconStyle}
                onClick={() => fileInputRef.current.click()}
              />
              {file ? <span>{file.name}</span> : null}
            </div>
            {isRecording.on ? (
              isRecording.projectID === projectID && (
                <div
                  className={styles.stopRecording}
                  onClick={() => {
                    stopRecording();
                    clearInterval(abc.current);
                    recTimer.current = 0;
                    setRecTimer(0);
                  }}
                >
                  Stop Recording
                </div>
              )
            ) : (
              <div className={styles.mediaBox}>
                <div>
                  {isRecording.audio ? (
                    isRecording.projectID === projectID && (
                      <FaStop
                        onClick={stopRecording}
                        className={styles.iconStyle}
                      />
                    )
                  ) : (
                    <BsFillMicFill
                      style={{ fontSize: "2rem" }}
                      title={
                        isRecording.audioData &&
                        isRecording.projectID === projectID
                          ? "Discard and record again"
                          : "Record"
                      }
                      onClick={() => {
                        if (!navigator?.mediaDevices?.getUserMedia) {
                          alert(
                            "This feature is supported on modern browsers!!!"
                          );

                          return;
                        }
                        audioRecord(projectID, setRecTimer);
                      }}
                      className={styles.iconStyle}
                    />
                  )}

                  <AiOutlineVideoCameraAdd
                    style={{ fontSize: "2rem" }}
                    title={
                      isRecording.videoData &&
                      isRecording.projectID === projectID
                        ? "Discard and record again"
                        : "Record"
                    }
                    className={styles.iconStyle}
                    onClick={() => {
                      if (!navigator?.mediaDevices?.getDisplayMedia) {
                        alert(
                          "This feature is supported on desktop browsers!!!"
                        );

                        return;
                      }
                      screenRecord(projectID, setTimer);
                    }}
                  />
                </div>
              </div>
            )}
            {isRecording.audio && isRecording.projectID === projectID && (
              <span className={styles.recTimer}>
                {convertMS(recTimer1 * 1000)}
              </span>
            )}
          </div>
          {file && (
            <div className={styles.imgContainer}>
              {file && file.type.includes("image") && (
                <>
                  <p className={styles.previewText}>Preview:</p>
                  <img src={URL.createObjectURL(file)}></img>
                  <div style={{ display: "flex" }}>
                    <button
                      className={styles.discardButton}
                      onClick={() => setFile(false)}
                    >
                      Discard
                    </button>
                    <button
                      className={styles.sendButton}
                      onClick={(e) => {
                        if (role == "admin") {
                          setDialogConfig({
                            heading:
                              "Are you sure, You want to text " +
                              (data.stages
                                ? getUserName(stage)
                                : freelancer
                                ? "Freelancer"
                                : "Client"),
                            textType: false,
                            handler: postComment,
                          });
                          setDialog(true);
                        } else {
                          postComment();
                        }
                      }}
                    >
                      Send
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {isRecording.projectID === projectID && (
            <div className={styles.previewBox}>
              {isRecording.audioData && !isRecording.audio && (
                <>
                  <p className={styles.previewText}>Preview:</p>
                  <audio controls>
                    <source
                      src={toBase(isRecording.audioData)}
                      type="audio/mp3"
                    ></source>
                  </audio>
                </>
              )}
              {isRecording.videoData && !isRecording.video && (
                <>
                  <p className={styles.previewText}>Preview:</p>
                  <video
                    controls={true}
                    width="320"
                    height="240"
                    src={URL.createObjectURL(isRecording.videoData)}
                  />
                </>
              )}
              {(isRecording.videoData || isRecording.audioData) &&
                !isRecording.audio &&
                !isRecording.video && (
                  <div style={{ display: "flex" }}>
                    <button
                      className={styles.discardButton}
                      onClick={() =>
                        setIsRecording({
                          ...isRecording,
                          audioData: null,
                          videoData: null,
                          projectID: null,
                        })
                      }
                    >
                      Discard
                    </button>
                    <button
                      className={styles.sendButton}
                      onClick={(e) => {
                        if (role == "admin") {
                          setDialogConfig({
                            heading:
                              "Are you sure, You want to text " +
                              (data.stages
                                ? getUserName(stage)
                                : freelancer
                                ? "Freelancer"
                                : "Client"),
                            textType: false,
                            handler: postComment,
                          });
                          setDialog(true);
                        } else {
                          postComment();
                        }
                        setIsRecording({
                          ...isRecording,
                          audioData: null,
                          videoData: null,
                          projectID: null,
                        });
                      }}
                    >
                      Send
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : null}
      <div className={styles.userActivity}>
        {tempComments.map((comment) => (
          <Comment
            ref1={ref}
            setFile={setFile}
            postComment={postComment}
            comment={comment}
            key={comment._id}
            projectID={projectID}
            image={
              comment.attachmentName &&
              !comment.attachmentName.match(/.*\.pdf$/) &&
              !comment.attachmentName.match(/.*\.docx$/)
                ? true
                : false
            }
            pdf={
              comment.attachmentName && comment.attachmentName.match(/.*\.pdf$/)
                ? true
                : false
            }
            docx={
              comment.attachmentName &&
              comment.attachmentName.match(/.*\.docx$/)
                ? true
                : false
            }
            audio={comment.mediaType === 1 && comment.media}
            video={comment.mediaType === 2 && comment.media}
          />
        ))}
        {timelineData.map((comment) => (
          <Comment
            ref1={ref}
            setFile={setFile}
            postComment={postComment}
            comment={comment}
            key={comment._id}
            projectID={projectID}
            image={
              comment.attachmentName &&
              !comment.attachmentName.match(/.*\.pdf$/) &&
              !comment.attachmentName.match(/.*\.docx$/)
                ? true
                : false
            }
            pdf={
              comment.attachmentName && comment.attachmentName.match(/.*\.pdf$/)
                ? true
                : false
            }
            docx={
              comment.attachmentName &&
              comment.attachmentName.match(/.*\.docx$/)
                ? true
                : false
            }
            audio={comment.mediaType === 1 && comment.media}
            video={comment.mediaType === 2 && comment.media}
          />
        ))}
      </div>
    </div>
  );
}
