import React, { useEffect, useState } from "react";
import { data, defaultValue } from "./constant";
import ReactDOM from "react-dom";
import styles from "./styles.module.css";
import { setUserVideo } from "../../../store/actions/video";
import { useDispatch, useSelector } from "react-redux";
import useHelperFunctions from "../../helperFunctions";
import { setUserGscInfo } from "../../../store/actions/userGscInfo";

export const OnBoardingContext = React.createContext({
    ...defaultValue.state,
    ccPlan: null,
    setState: defaultValue.setState,
});

function OnBoardingContextProvider({ children }) {
    const [state, setState] = useState(defaultValue.state);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { getData } = useHelperFunctions();
    useEffect(() => {
        if (isLoggedIn)
            (async () => {
                const res = await getData({
                    url: "/api/user/onboardingDetails",
                });
                if (res.status == 200) {
                    dispatch(setUserVideo(res.data.videoPrefs));
                    dispatch(
                        setUserGscInfo({
                            showLoginBar: res.data.show_gsc == 1 ? 1 : 0,
                        }),
                    );
                    setState((ps) => ({
                        ...ps,
                        sampleData: {
                            clusters: res?.data?.sampleReports?.clusters,
                            seo: res.data?.sampleReports?.seo,
                            audit: res.data?.sampleReports?.seo,
                        },
                        referCode: res?.data?.referCode,
                        chapters: res.data.tourValues[0],
                        questions: res.data.questions || [],
                        onBoardingStep: res.data.onBoarding?.step || 0,
                        onBoardingStatus: res.data.onBoarding?.status || 1,
                        showConnectGsc: true,
                    }));
                }
            })();
    }, [isLoggedIn]);

    useEffect(() => {
        setTimeout(() => {
            if (state.currentStepCompleted) {
                setState((ps) => {
                    return {
                        ...ps,
                        currentStepCompleted: null,
                    };
                });
            }
        }, 10000);
    }, [state]);

    return (
        <OnBoardingContext.Provider value={{ ...state, setState }}>
            {children}
            {ReactDOM.createPortal(
                <div
                    className={
                        styles.achievement +
                        " " +
                        (state.currentStepCompleted ? styles.show : "")
                    }
                >
                    {state.currentStepCompleted && (
                        <lottie-player
                            src={data.steps[state.currentStepCompleted]}
                            background="transparent"
                            speed="1"
                            style={{ width: 500, height: 300 }}
                            autoplay
                            loop
                        ></lottie-player>
                    )}
                </div>,
                document.body,
            )}
        </OnBoardingContext.Provider>
    );
}

export default OnBoardingContextProvider;
