import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    loginUser,
    updateUserInfo,
    VerificationEmail,
} from "../../../../store/actions/auth";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import styles from "../styles.module.css";
let mountedLocation = null;
function VericationBanner({ teamMember }) {
    const [canResend, setCanResend] = useState(false);
    const email = useSelector((state) => state.auth.email);
    const [timer, setTimer] = useState(180);
    const ref = useRef(null);
    const dispatch = useDispatch();
    console.log(location);
    useEffect(() => {
        mountedLocation = window.location.pathname;
    }, []);

    useEffect(() => {
        if (location.pathname != mountedLocation) {
            dispatch(updateUserInfo("email", email));
        }
    }, [location.pathname]);

    const auth = useSelector((state) => state.auth);
    useEffect(() => {
        if (!teamMember) {
            let interval;
            interval = setInterval(() => {
                setTimer((prev) => (prev == 0 ? 0 : prev - 1));
            }, 1000);
            setCanResend(false);
            setTimeout(() => {
                setCanResend(true);
                clearInterval(interval);
            }, 180 * 1000);
        }
    }, []);

    const resendEmail = () => {
        dispatch(
            loginUser({ email: auth.email, password: auth?.password }, true),
        );
        dispatch(
            VerificationEmail({ email: auth.email, password: auth?.password }),
        );
        setTimer(180);
        let interval;
        interval = setInterval(() => {
            setTimer((prev) => (prev == 0 ? 0 : prev - 1));
        }, 1000);
        setCanResend(false);
        setTimeout(() => {
            setCanResend(true);
            clearInterval(interval);
        }, 180 * 1000);
    };
    const { postData } = useHelperFunctions();
    const normalRegister = async () => {
        const res = await postData({
            url: "/api/invite/invitationConfirmation",
            payload: { userEmail: teamMember.user_email },
        });

        if (res.status == 200) {
            teamMember.register({ tokenFrom201: teamMember.googleToken });
        }
    };

    useEffect(() => {
        const parent = ref.current?.parentNode;
        parent.classList.toggle(styles.vbParentClass);

        return () => {
            // const parent = ref.current?.parentNode;
            parent.classList.toggle(styles.vbParentClass);
        };
    }, []);

    //     console.log(ref, "bahra wala", ref.current);
    //   console.log("bahra wala",);
    return (
        <>
            <div className={styles.vbContainer} ref={ref}>
                {!teamMember ? (
                    <div className={styles.vbTextContainer}>
                        <div className={styles.vbTCTitle}>
                            <span style={{ fontSize: 30, marginRight: 5 }}>
                                💌
                            </span>{" "}
                            One Last Step!!
                        </div>
                        <div className={styles.vbTCSubtitle}>
                            We have{" "}
                            <b>{auth.password ? "sent" : "resent"}&nbsp;</b>
                            a confirmation link to your email address. <br />
                            Please check and confirm your email address.
                        </div>
                        {auth.password && (
                            <Button
                                text={
                                    canResend
                                        ? "Resend Email"
                                        : `Resend in ${timer} seconds`
                                }
                                disabled={!canResend}
                                handler={resendEmail}
                                width="200px"
                            />
                        )}
                        <div className={styles.vbTCFooter}>
                            If the email hasn't arrived after a few minutes,
                            please check your spam folder or request a new
                            confirmation email. Confirming your email address
                            helps us prevent spam and fraud. Thank you!
                        </div>
                    </div>
                ) : (
                    <div className={styles.vbTextContainer}>
                        <div
                            style={{
                                lineHeight: 2,
                                fontWeight: 500,
                                fontSize: 18,
                            }}
                            className={styles.vbTCSubtitle}
                        >
                            You have already been invited by {teamMember.name}{" "}
                            to be a part of {teamMember.company_name}. Would you
                            like to proceed to being part of the team?
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "100%",
                            }}
                        >
                            <Button
                                text={" Yes, add me to the team"}
                                handler={() =>
                                    (window.location = teamMember.url)
                                }
                                width="220px"
                            />
                            <Button
                                text={"No, create a new account"}
                                handler={normalRegister}
                                width="220px"
                                secondary={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default VericationBanner;
