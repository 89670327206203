import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button/Button";
import SVGIcon from "../../atoms/SVGIcon";
import DocScore from "./DocScore";
import DocWords from "./DocWords";
import styles from "./styles.module.css";
import styles2 from "../../organisms/TextEditor/EditorAssistant/styles.module.css";

import { VscTrash } from "react-icons/vsc";
import { BsCheck2, BsLink45Deg } from "react-icons/bs";
import { ClickAwayListener, TextareaAutosize } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { is_completed, loadingText } from "./constants";
import InProgressTimeline from "./InProgressTimeline/InProgressTimeline";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import AllTags from "../Account/AllTags";
import useTeam from "../Account/useTeam";
import TagLIst from "./TagLIst";
import { TagsContext } from "../../Pages/TagsContext";

import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../store/actions/alert";
import useHelperFunctions from "../../helperFunctions";
import { RENAME_DOC } from "../../api.json";
import { ShareModal } from "../Creator/CreatorCore/EditorTitle";
import Modal from "../../atoms/Modal/Modal";
import Morekeywords from "../../atoms/MoreKeywords/Morekeywords";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { MdTextFields } from "react-icons/md";
import { RiText } from "react-icons/ri";
import { RxText } from "react-icons/rx";

// function name(str="") {
//    if(str.length>30){
//      return str.substring(0,30)+"...";
//    }else{
//      return str;
//    }
// }

const buttonStyle = {
    height: 34,
    width: 120,
    color: "var(--primary-blue)",
    backgroundColor: "#f6f7fb",
    fontSize: 12,
    border: "solid 1px #e9ecf4",
    borderRadius: 3,
};

const deletionState = {
    deleted: "deleted",
    removed: "removed",
    none: "none",
    restore: "restored",
};

function DocCard({
    seoDoc,
    doc,
    openDoc = (boolean) => {},
    archived,
    restoreDoc = () => {},
    deleteDoc = () => {},
    auditMode = false,
    aiHumanizer,
}) {
    const [deleted, setDeleted] = useState(deletionState.none);
    const [status, setStatus] = useState(
        doc.dummyReport ? 0 : doc.is_completed,
    );
    const [docTags, setDocTags] = useState(null);
    const [tagsModal, setTagsModal] = useState(false);
    const [optionsModal, setOptionsModal] = useState(false);
    const [rename, setRename] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [renameError, setRenameError] = useState(false);
    const [activityStatus, setActivityStatus] = useState(doc.activity_status);
    const nameChangerRef = useRef();
    const goTo = useNavigation();
    const { mpTrackOpenReportCta, mpTrackMarkAsComplete } = useMixpanelHook();
    const { isFreeForever } = useUserAccessHook();

    const [docTitle, setDocTitle] = useState(
        aiHumanizer
            ? doc.scan_name
            : seoDoc
            ? doc.search_keyword
            : doc.title
            ? doc.title
            : "",
    );
    const [title, setTitle] = useState(
        aiHumanizer
            ? doc.scan_name
            : seoDoc
            ? doc.search_keyword
            : doc.title
            ? doc.title
            : "",
    );
    const { tagsMap, addTag } = useContext(TagsContext);
    const dispatch = useDispatch();

    const { postData, putData } = useHelperFunctions();

    const getShareUrl = () => {
        setShareModal(true);
    };

    const markAsComplete = async (isComplete) => {
        const res = await putData({
            url: "/api/keywords/user-activity",
            payload: {
                report_id: doc.report_id,
                activity_status: isComplete ? 3 : 2,
            },
        });
        if (res.status == 200) {
            if (isComplete) {
                setActivityStatus(3);
                // setIsCompleted(true);
                // setIsEditingInProgress(false);
                setOptionsModal(false);
            } else {
                setActivityStatus(2);
                // setIsEditingInProgress(true);
                // setIsCompleted(false);
                setOptionsModal(false);
            }
        }
    };

    const changeTitle = async (e) => {
        if (e.target.value.length > 2) {
            setRenameError(false);
            setDocTitle(e.target.value);
            if (aiHumanizer) {
                const res = postData({
                    url: "/api/ai-humanizer/update-scan-name",
                    payload: {
                        scan_name: e.target.value,
                        scan_id: doc.scan_id,
                    },
                });
            } else {
                const res = postData({
                    url: RENAME_DOC,
                    payload: { title: e.target.value, doc_id: doc.doc_id },
                });
            }
            setRename(false);
        } else {
            setRenameError(true);
            e.target.focus();
        }
    };

    useEffect(() => {
        try {
            setDocTags((_tags) => {
                console.log("??????0", doc, _tags);
                if (!doc.tags && !_tags) {
                    return [];
                }
                if (doc.tags && !_tags) {
                    const tags = JSON.parse(doc.tags).filter(
                        (tag) => tagsMap[tag],
                    );
                    return tags.map((id) => tagsMap[id]);
                } else if (_tags) {
                    return _tags
                        .map((tag) => tagsMap[tag.id])
                        .filter((tag) => tag);
                }
            });
        } catch (error) {}
    }, [tagsMap]);

    useEffect(() => {
        if (doc.is_completed)
            setTimeout(() => {
                setStatus(doc.is_completed);
            }, 2000);
    }, [doc.is_completed]);

    useEffect(() => {
        if (archived) {
            if (deleted == deletionState.deleted) {
                restoreDoc(
                    aiHumanizer ? doc.scan_id : doc.report_id || doc.doc_id,
                );
            }
            if (deleted == deletionState.restore) {
                deleteDoc(
                    aiHumanizer ? doc.scan_id : doc.report_id || doc.doc_id,
                );
            }
        } else {
            if (deleted == deletionState.deleted) {
            }
            if (deleted == deletionState.restore) {
                restoreDoc(
                    aiHumanizer ? doc.scan_id : doc.report_id || doc.doc_id,
                );
            }
        }
    }, [deleted]);

    async function handleDelete() {
        const isAllowed = await deleteDoc(
            aiHumanizer ? doc.scan_id : doc.report_id || doc.doc_id,
        );
        console.log("isAllowed", isAllowed);
        if (isAllowed) {
            setDeleted(deletionState.deleted);
        } else {
        }
    }

    const openReport = (e) => {
        console.log("111111111111111111");
        e.stopPropagation();
        mpTrackOpenReportCta({
            reportType: auditMode
                ? "Content Optimizer "
                : seoDoc
                ? "Article Writer"
                : "AI Doc",
            ctaUsed: "Title",
            report_id: seoDoc ? doc?.report_id : doc?.doc_id,
        });
        if (archived || doc?.is_completed == 2) {
            return;
        } else if (isFreeForever) {
            openDoc(true);
            return;
        } else if (!auditMode && activityStatus < 2) {
            goTo("SEO_EDITOR", [], doc.report_id);
        } else {
            openDoc(true);
        }
    };

    return (
        <>
            {shareModal && (
                <Modal setModal={setShareModal}>
                    <ShareModal
                        setShareModal={setShareModal}
                        access={true}
                        id={doc.report_id}
                        auditMode={auditMode}
                    />
                </Modal>
            )}
            <tr
                style={
                    deleted == deletionState.removed
                        ? { display: "none" }
                        : auditMode
                        ? { height: 105 }
                        : {}
                }
                className={styles.docContainer}
                onMouseLeave={() => setOptionsModal(false)}
                onClick={(e) => {
                    if (
                        doc.is_completed != 2 &&
                        !doc.error_message &&
                        deleted != deletionState.deleted
                    ) {
                        e.stopPropagation();
                        archived
                            ? setDeleted(deletionState.deleted)
                            : openDoc(true);
                    }
                }}
            >
                {deleted == deletionState.deleted ? (
                    <RecentlyDeletedCard
                        archived={archived}
                        setDeleted={setDeleted}
                        title={docTitle}
                        seoDoc={seoDoc}
                    />
                ) : (
                    <>
                        <td className={styles.leftContainer}>
                            <div className={styles.title}>
                                {seoDoc ? (
                                    <SVGIcon
                                        size={16}
                                        src="/New UI/SVG/seoDoc.svg"
                                    ></SVGIcon>
                                ) : (
                                    <SVGIcon
                                        size={16}
                                        src="/New UI/SVG/doc.svg"
                                    ></SVGIcon>
                                )}
                                {!status && seoDoc && (
                                    <>
                                        {doc?.is_audit &&
                                        doc?.is_completed == 2 ? (
                                            <InProgressDoc
                                                auditMode={auditMode}
                                                doc={doc}
                                                auditUrlInvalid={doc?.msg}
                                                failedAudit={true}
                                            />
                                        ) : (
                                            <InProgressDoc
                                                auditMode={auditMode}
                                                doc={doc}
                                            />
                                        )}
                                    </>
                                )}
                                <p className={"overflowText"}>
                                    {rename ? (
                                        <TextareaAutosize
                                            spellCheck={false}
                                            onKeyPress={(e) => {
                                                e.persist();
                                                if (
                                                    e.nativeEvent.key == "Enter"
                                                ) {
                                                    e.preventDefault();
                                                    changeTitle(e);
                                                }
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                            rowsMin={1}
                                            ref={nameChangerRef}
                                            autoFocus
                                            onChange={(e) =>
                                                setTitle(e.target.value)
                                            }
                                            onBlur={(e) => {
                                                changeTitle(e);
                                            }}
                                            value={title}
                                        ></TextareaAutosize>
                                    ) : (
                                        <LightTooltip
                                            arrow
                                            title={
                                                docTitle?.length > 30
                                                    ? docTitle
                                                    : ""
                                            }
                                            placement="bottom"
                                        >
                                            <span
                                                onClick={(e) => {
                                                    openReport(e);
                                                }}
                                                className={
                                                    archived ||
                                                    doc?.is_completed == 2
                                                        ? ""
                                                        : styles.titleText
                                                }
                                                id={
                                                    seoDoc
                                                        ? "seo-report-name"
                                                        : ""
                                                }
                                            >
                                                {docTitle?.slice(0, 30)}
                                                {docTitle?.length > 30
                                                    ? "..."
                                                    : ""}
                                            </span>
                                        </LightTooltip>
                                    )}
                                    {renameError && (
                                        <div className={styles.renameError}>
                                            Please enter minimum 3 characters*
                                        </div>
                                    )}
                                    {doc.is_sample && (
                                        <span className={styles.sample}>
                                            Sample Report
                                        </span>
                                    )}
                                </p>

                                {doc &&
                                doc.secondary_keywords &&
                                JSON.parse(doc.secondary_keywords).length >
                                    0 ? (
                                    <Morekeywords
                                        keywords={doc.secondary_keywords}
                                        moreCount={
                                            JSON.parse(doc?.secondary_keywords)
                                                ?.length
                                        }
                                        title={docTitle}
                                    />
                                ) : (
                                    ""
                                )}
                                {!auditMode && doc.is_premium ? (
                                    <SVGIcon
                                        style={{ marginLeft: "5px" }}
                                        src={
                                            "/New UI/SVG/premium-text-blue.svg"
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className={styles.infoContainer}>
                                {seoDoc && (
                                    <>
                                        {auditMode && (
                                            <span
                                                style={{
                                                    width: "90%",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    display: "flex",
                                                    marginBottom: 5,
                                                }}
                                            >
                                                {" "}
                                                {doc.audit_url ? (
                                                    <BsLink45Deg
                                                        style={{
                                                            minWidth: 14,
                                                            minHeight: 14,
                                                        }}
                                                    />
                                                ) : (
                                                    <RxText
                                                        style={{
                                                            minWidth: 14,
                                                            minHeight: 14,
                                                        }}
                                                    />
                                                )}
                                                <LightTooltip
                                                    arrow
                                                    placement="bottom"
                                                    title={doc.audit_url}
                                                >
                                                    <span
                                                        className={
                                                            styles.countryName
                                                        }
                                                        style={{
                                                            paddingLeft: 4,
                                                            width: "20vw",
                                                        }}
                                                    >
                                                        {doc.audit_url
                                                            ? doc.audit_url
                                                            : "Content Added Manually"}
                                                    </span>
                                                </LightTooltip>
                                            </span>
                                        )}
                                        <span
                                            style={{
                                                width: "90%",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "flex",
                                            }}
                                        >
                                            <SVGIcon
                                                size={12}
                                                src="/New UI/SVG/locationPin.svg"
                                            />
                                            <span
                                                className={styles.countryName}
                                            >
                                                {doc?.search_country}
                                            </span>
                                        </span>{" "}
                                    </>
                                )}
                                {aiHumanizer && (
                                    <div
                                        style={{
                                            fontSize: "10px",
                                            color: "#92929d",
                                        }}
                                    >
                                        Created {doc.created_at}
                                    </div>
                                )}

                                {docTags && tagsModal && !aiHumanizer && (
                                    <AllTags
                                        report_id={doc.report_id}
                                        doc_id={doc.doc_id}
                                        _tags={docTags}
                                        setTags={setDocTags}
                                        setTagsModal={setTagsModal}
                                        setReports={() => {}}
                                    ></AllTags>
                                )}
                            </div>
                            {docTags &&
                            !archived &&
                            doc.is_completed != 2 &&
                            !aiHumanizer ? (
                                <TagLIst
                                    docId={doc.report_id || doc.doc_id}
                                    seoDoc={seoDoc}
                                    addTag={addTag}
                                    setDocTags={setDocTags}
                                    setTagsModal={setTagsModal}
                                    list={docTags}
                                />
                            ) : (
                                <div style={{ height: 18 }}></div>
                            )}
                        </td>
                        <td>
                            <LightTooltip
                                title={
                                    aiHumanizer && doc.ai_score !== null
                                        ? `${parseInt(
                                              doc.ai_score * 100,
                                          )}% of the content is likely to be AI-generated`
                                        : ""
                                }
                            >
                                <span
                                    style={
                                        aiHumanizer
                                            ? {
                                                  textAlign: "center",
                                                  display: "block",
                                              }
                                            : {}
                                    }
                                    className={styles.time}
                                >
                                    {aiHumanizer
                                        ? doc.ai_score !== null
                                            ? parseInt(doc.ai_score * 100) + "%"
                                            : "-"
                                        : doc.created_at}
                                </span>
                            </LightTooltip>
                        </td>

                        {aiHumanizer ? (
                            <td>
                                <LightTooltip
                                    title={
                                        doc.humanizer_ai_score !== null
                                            ? `${parseInt(
                                                  doc.humanizer_ai_score * 100,
                                              )}% of the content is likely to be AI-generated`
                                            : ""
                                    }
                                >
                                    <span
                                        style={
                                            aiHumanizer
                                                ? {
                                                      textAlign: "center",
                                                      display: "block",
                                                  }
                                                : {}
                                        }
                                        className={styles.time}
                                    >
                                        {doc.humanizer_ai_score !== null
                                            ? parseInt(
                                                  doc.humanizer_ai_score * 100,
                                              ) + "%"
                                            : "-"}
                                    </span>
                                </LightTooltip>
                            </td>
                        ) : null}

                        {!auditMode && !aiHumanizer && (
                            <td>
                                {aiHumanizer ? (
                                    doc.ai_score !== null ? (
                                        parseInt(doc.ai_score * 100) + "%"
                                    ) : (
                                        "-"
                                    )
                                ) : doc.is_completed != 2 ? (
                                    <DocWords words={doc.word_count}></DocWords>
                                ) : (
                                    "-"
                                )}
                            </td>
                        )}
                        {seoDoc && (
                            <td>
                                {doc.is_completed != 2 &&
                                doc?.seo_score == null ? (
                                    "-"
                                ) : doc.is_completed != 2 ? (
                                    <DocScore
                                        // words={doc.word_count}
                                        // score={doc.score}
                                        seoScore={doc?.seo_score}
                                    ></DocScore>
                                ) : (
                                    "-"
                                )}
                            </td>
                        )}

                        <td>
                            {doc.is_completed != 2 && !doc.error_message ? (
                                <div className={styles.editorLink}>
                                    {(auditMode || !seoDoc) && (
                                        <Button
                                            id={
                                                !archived &&
                                                seoDoc &&
                                                "seo-open-editor"
                                            }
                                            text={
                                                archived
                                                    ? "Restore"
                                                    : seoDoc
                                                    ? "Open Editor"
                                                    : "Open Document"
                                            }
                                            handler={(e) => {
                                                e.stopPropagation();
                                                archived
                                                    ? setDeleted(
                                                          deletionState.deleted,
                                                      )
                                                    : openDoc(true);
                                                mpTrackOpenReportCta({
                                                    reportType: auditMode
                                                        ? "Content Optimizer "
                                                        : seoDoc
                                                        ? "Article Writer"
                                                        : "AI Doc",
                                                    ctaUsed: seoDoc
                                                        ? "Open Editor"
                                                        : "Open Document",
                                                    report_id: seoDoc
                                                        ? doc.report_id
                                                        : doc.doc_id,
                                                });
                                            }}
                                            style={{ ...buttonStyle }}
                                        />
                                    )}

                                    {seoDoc && archived && !auditMode && (
                                        <Button
                                            text={"Restore"}
                                            handler={(e) => {
                                                e.stopPropagation();
                                                setDeleted(
                                                    deletionState.deleted,
                                                );
                                                mpTrackOpenReportCta({
                                                    reportType: auditMode
                                                        ? "Content Optimizer "
                                                        : seoDoc
                                                        ? "Article Writer"
                                                        : "AI Doc",
                                                    ctaUsed: seoDoc
                                                        ? "Open Editor"
                                                        : "Open Document",
                                                    report_id: seoDoc
                                                        ? doc.report_id
                                                        : doc.doc_id,
                                                });
                                            }}
                                            style={{ ...buttonStyle }}
                                        />
                                    )}

                                    {!auditMode && seoDoc && !archived && (
                                        <div onClick={(e) => openReport(e)}>
                                            {activityStatus == 0 ? (
                                                <NotStarted />
                                            ) : activityStatus == 1 ? (
                                                <WritingInProgress />
                                            ) : activityStatus == 2 ? (
                                                <EditingInProgress />
                                            ) : activityStatus == 3 ? (
                                                <Completed />
                                            ) : (
                                                <></>
                                            )}{" "}
                                        </div>
                                    )}

                                    {!archived && !doc.is_sample ? (
                                        <span className={styles.options}>
                                            <SVGIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setOptionsModal(true);
                                                }}
                                                color="lightgray"
                                                size={24}
                                                src="/New UI/SVG/dots.svg"
                                            ></SVGIcon>
                                            {optionsModal && (
                                                <ClickAwayListener
                                                    onClickAway={() =>
                                                        setOptionsModal(false)
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.optionModal
                                                        }
                                                    >
                                                        {seoDoc &&
                                                            !auditMode && (
                                                                <span
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        if (
                                                                            activityStatus ==
                                                                            3
                                                                        ) {
                                                                            mpTrackMarkAsComplete(
                                                                                {
                                                                                    location:
                                                                                        "Landing page",
                                                                                    isCompleted: false,
                                                                                },
                                                                            );
                                                                            markAsComplete(
                                                                                false,
                                                                            );
                                                                        } else {
                                                                            mpTrackMarkAsComplete(
                                                                                {
                                                                                    location:
                                                                                        "Landing page",
                                                                                    isCompleted: true,
                                                                                },
                                                                            );
                                                                            markAsComplete(
                                                                                true,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <BsCheck2
                                                                        size={
                                                                            18
                                                                        }
                                                                        color="var(--primary-blue)"
                                                                    />
                                                                    {activityStatus ==
                                                                    3
                                                                        ? "Unmark as complete"
                                                                        : "Mark as complete"}
                                                                </span>
                                                            )}
                                                        {seoDoc && (
                                                            <span
                                                                id="seo-share-report"
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    getShareUrl(
                                                                        doc.report_id,
                                                                    );
                                                                }}
                                                            >
                                                                <SVGIcon
                                                                    size={18}
                                                                    color="var(--primary-blue)"
                                                                    src="/New UI/SVG/share.svg"
                                                                ></SVGIcon>
                                                                Share
                                                            </span>
                                                        )}
                                                        {!seoDoc && (
                                                            <span
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setRename(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                <SVGIcon
                                                                    size={18}
                                                                    color="var(--primary-blue)"
                                                                    src="/New UI/SVG/pencil.svg"
                                                                ></SVGIcon>
                                                                Rename
                                                            </span>
                                                        )}

                                                        <span
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDelete();
                                                            }}
                                                            id={
                                                                "seo-delete-report"
                                                            }
                                                        >
                                                            <VscTrash
                                                                size={16}
                                                                color="red"
                                                            />
                                                            Delete
                                                        </span>
                                                    </div>
                                                </ClickAwayListener>
                                            )}
                                        </span>
                                    ) : (
                                        <span
                                            className={styles.optionsDummy}
                                        ></span>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className={styles.editorLink}>
                                        {doc?.msg?.length ? (
                                            <div className={styles.failed}>
                                                <b>Could Not Process</b>
                                                <br />
                                                <span
                                                    className={
                                                        styles.auditURLfailText
                                                    }
                                                >
                                                    {doc?.msg}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className={styles.failed}>
                                                <b>Could Not Process</b>
                                                <br />
                                                The keyword you entered is too
                                                narrow for our engine to provide
                                                high-quality results. Try using
                                                another keyword.
                                            </div>
                                        )}
                                        {!archived && !doc.is_sample && (
                                            <span className={styles.options}>
                                                <SVGIcon
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setOptionsModal(true);
                                                    }}
                                                    color="lightgray"
                                                    size={24}
                                                    src="/New UI/SVG/dots.svg"
                                                ></SVGIcon>
                                                {optionsModal && (
                                                    <ClickAwayListener
                                                        onClickAway={() =>
                                                            setOptionsModal(
                                                                false,
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.optionModal
                                                            }
                                                        >
                                                            <span
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleDelete();
                                                                }}
                                                            >
                                                                <VscTrash
                                                                    size={16}
                                                                    color="red"
                                                                />
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </ClickAwayListener>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </>
                            )}
                            {doc.is_completed != 2 && (
                                <div className={styles.updated}>
                                    Last updated{" "}
                                    {seoDoc
                                        ? doc.content_updated_at
                                        : doc.updated_at}
                                </div>
                            )}
                        </td>
                    </>
                )}
            </tr>
        </>
    );
}

export function RecentlyDeletedCard({ title, setDeleted, archived }) {
    const deleteDocRef = useRef(null);

    useEffect(() => {
        if (deleteDocRef.current) {
            deleteDocRef.current.style.width =
                deleteDocRef.current.parentElement.parentElement.offsetWidth +
                "px";
        }
        function setWidthDeleteDoc() {
            if (deleteDocRef.current) {
                deleteDocRef.current.style.width =
                    deleteDocRef.current.parentElement.parentElement
                        .offsetWidth + "px";
            }
        }

        addEventListener("resize", setWidthDeleteDoc);

        return () => removeEventListener("resize", setWidthDeleteDoc);
    }, [deleteDocRef]);

    return (
        <td style={{ position: "relative" }}>
            <div className={styles.deleteDoc} ref={deleteDocRef}>
                <div className={styles.title}>
                    <p className={"overflowText"}>
                        <LightTooltip
                            arrow
                            title={title.length > 30 ? title : ""}
                            placement="bottom"
                        >
                            <span>
                                <u>
                                    {title.slice(0, 30)}
                                    {title.length > 30 ? "..." : ""}
                                </u>
                            </span>
                        </LightTooltip>
                        &nbsp;has been {archived ? "restored" : "archived"}.
                    </p>
                </div>

                <div style={{ display: "flex" }}>
                    <Button
                        text="Dismiss"
                        Icon="/New UI/SVG/dismiss.svg"
                        iconType={"JSX"}
                        width={100}
                        style={{
                            backgroundColor: "white",
                            color: "#1F4CBE",
                            fontWeight: 400,
                            marginRight: 20,
                        }}
                        handler={(e) => {
                            e.stopPropagation();
                            setDeleted(deletionState.removed);
                        }}
                    ></Button>

                    <Button
                        text="Undo"
                        width={100}
                        Icon={"/New UI/SVG/undo.svg"}
                        iconType="JSX"
                        style={{
                            backgroundColor: "white",
                            color: "#1F4CBE",
                            fontWeight: 400,
                        }}
                        handler={(e) => {
                            e.stopPropagation();
                            setDeleted(deletionState.restore);
                        }}
                    ></Button>
                </div>
            </div>
        </td>
    );
}

export function InProgressDoc({
    doc,
    style = {},
    onCruise,
    isCluster,
    auditMode = false,
    auditUrlInvalid = "",
    failedAudit = false,
}) {
    function initialStep() {
        if (!doc.dummyReport) {
            return loadingText(0, isCluster).length - 1;
        }
        return 0;
    }

    doc.is_completed = doc.is_completed || 0;

    const [step, setStep] = useState(initialStep());
    const [subTextStep, setSubTextStep] = useState(0);
    const _step = useRef(step);
    useEffect(() => {
        const interval = setInterval(() => {
            if (_step.current == loadingText(0, isCluster).length - 1) {
                setSubTextStep((ps) => {
                    if (
                        ps <
                        loadingText(0, isCluster)[_step.current].subText
                            .length -
                            1
                    )
                        return ps + 1;
                    return ps;
                });
            }

            setStep((s) => {
                if (s < loadingText(0, isCluster).length - 1) return s + 1;
                else {
                    return s;
                }
            });
        }, 6000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (doc.is_completed != 0) {
            setStep(loadingText(0, isCluster).length - 1);
        }
    }, [doc.is_completed]);

    return (
        <div
            style={{ ...style, height: auditMode ? "105px" : "95px" }}
            className={`${styles.docContainerP} ${
                isCluster && styles.docContainerX
            } `}
            onClick={(e) => e.stopPropagation()}
        >
            {!onCruise && (
                <div className={styles.leftContainer}>
                    <div className={styles.title}>
                        <SVGIcon
                            size={16}
                            src="/New UI/SVG/seoDoc.svg"
                        ></SVGIcon>

                        <p className={"overflowText"}>{doc.search_keyword}</p>
                        {doc &&
                        doc.secondary_keywords &&
                        JSON.parse(doc.secondary_keywords).length > 0 ? (
                            <span className={styles.moreCount}>
                                (+ {JSON.parse(doc.secondary_keywords).length}{" "}
                                more)
                                <div className={styles.keywordsConainer}>
                                    <p>
                                        Keywords(
                                        {JSON.parse(doc.secondary_keywords)
                                            .length + 1}
                                        )
                                    </p>
                                    <hr />
                                    <ul>
                                        <li>
                                            <strong>
                                                {doc.search_keyword}
                                            </strong>
                                        </li>
                                        {JSON.parse(doc.secondary_keywords).map(
                                            (key) => (
                                                <li>{key}</li>
                                            ),
                                        )}
                                    </ul>
                                </div>
                            </span>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className={styles.infoContainer}>
                        <Skeleton variant="text" width={150} height={20} />
                    </div>
                </div>
            )}
            <InProgressTimeline
                status={doc.is_completed}
                step={step}
                style={{ width: "40%", minWidth: 350 }}
                duration={8}
                isCluster={isCluster}
            />

            <div className={styles.loadingText}>
                {failedAudit ? (
                    <>
                        <div>Failed</div>
                        <div
                            className={styles.auditURLfailText}
                            style={{ marginTop: 3 }}
                        >
                            {auditUrlInvalid}
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            {
                                loadingText(doc.is_completed, isCluster)[step]
                                    ?.text
                            }{" "}
                            {doc.is_completed == 0 && <LoadingDots />}
                        </div>
                        <div style={{ marginTop: 3 }}>
                            {
                                loadingText(doc.is_completed, isCluster)[step]
                                    ?.subText[subTextStep]
                            }
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const NotStarted = ({}) => {
    return (
        <div className={`${styles.notStarted} ${styles.statusCard}`}>
            Ready for Writing
        </div>
    );
};
const WritingInProgress = ({}) => {
    return (
        <div className={`${styles.writingInProgress} ${styles.statusCard}`}>
            Writing in progress
        </div>
    );
};
const EditingInProgress = ({}) => {
    return (
        <div className={`${styles.editingInProgress} ${styles.statusCard}`}>
            Editing in progress
        </div>
    );
};
const Completed = ({}) => {
    return (
        <div className={`${styles.completed} ${styles.statusCard}`}>
            Completed
        </div>
    );
};

export default DocCard;
