import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import CircleImage from "../../atoms/CircleImage/CircleImage";
import UserName from "../../atoms/Username/Username";
import { Link } from "react-router-dom";
import { getPlan } from "../../../utility/validateFunctions";
import { PLANS_CATEGORIES } from "../../../constants";
import SVGIcon from "../../atoms/SVGIcon";
import { ClickAwayListener } from "@material-ui/core";
import { FiChrome } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { setChatWidget } from "../../../store/actions/state";
import { getTrailText } from "../../Pages/NewDashboard/constants";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import useMixpanelHook from "../../../utility/useMixpanelHook";

function UserDropdown() {
    const userInfo = useSelector((state) => state.auth);
    const [dropDown, setDropDown] = useState(false);
    return (
        <div className={styles.container}>
            <div className={styles.containerWeb}>
                <CircleImage
                    type="profile"
                    name={userInfo.user_name}
                    src={userInfo.profilePicture}
                ></CircleImage>
            </div>
            <UserDropDownOptions />
        </div>
    );
}

function UserDropDownOptions() {
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );
    const { planExpiresDate, planTrial } = useUserAccessHook();
    const { mixpanelTrack } = useMixpanelHook();
    const otherInfo = useSelector((state) => state.otherInfo);

    const dispatch = useDispatch();

    return (
        <div className={styles.dropDown}>
            <Link
                onClick={() => {
                    if (getTrailText(planExpiresDate) == 7 && planTrial) {
                        mixpanelTrack("Day 0 Dashboard", {
                            action: `Profile Menu - My Account`,
                        });
                    }
                }}
                to={"/admin/account/userProfile"}
            >
                <div className={styles.navBox}>
                    <SVGIcon src={"/New UI/user-dropdown/myAccount.svg"} />
                    <div>My Account</div>
                </div>
            </Link>
            {company_user_type != 2 ? (
                <>
                    <Link
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - Subscription`,
                                });
                            }
                        }}
                        to={
                            otherInfo?.subscriptionInfo?.type != 3 ||
                            otherInfo?.subscriptionInfo?.plan_category ==
                                PLANS_CATEGORIES.FREE_FOREVER
                                ? "/admin/account/plans"
                                : "/admin/account/userProfile/usage"
                        }
                    >
                        <div className={styles.navBox}>
                            <SVGIcon
                                src={"/New UI/user-dropdown/subscription.svg"}
                                size={30}
                            />
                            <div>
                                {otherInfo?.subscriptionInfo?.type != 3 ||
                                otherInfo?.subscriptionInfo?.plan_category ==
                                    PLANS_CATEGORIES.FREE_FOREVER
                                    ? "Subscriptions"
                                    : "Usage Details"}
                            </div>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - App Integrations`,
                                });
                            }
                        }}
                        to="/admin/integration"
                    >
                        <div className={styles.navBox}>
                            <SVGIcon
                                src={"/New UI/user-dropdown/AppInti.svg"}
                                size={30}
                            />
                            <div>App Integrations</div>
                        </div>
                    </Link>
                    <a
                        href="https://www.scalenut.com/earn-credits"
                        target="_blank"
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - Earn Credits`,
                                });
                            }
                        }}
                    >
                        <div className={styles.navBox}>
                            <SVGIcon src={"/New UI/user-dropdown/earnCr.svg"} />
                            <div>Earn Credits</div>
                        </div>
                    </a>
                    <a
                        href="https://talentnetwork.scalenut.com"
                        target="_blank"
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - Talent Network`,
                                });
                            }
                        }}
                    >
                        <div className={styles.navBox}>
                            <SVGIcon
                                style={{
                                    width: 30,
                                    height: 22,
                                }}
                                src={"/New UI/user-dropdown/talent.svg"}
                            />
                            <div>Talent Network</div>
                        </div>
                    </a>
                    <Link
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - Marketing Copywriter`,
                                });
                            }
                        }}
                        to="/admin/copywriter"
                    >
                        <div className={styles.navBox}>
                            <SVGIcon
                                src={"/New UI/aside-section/copywriter.svg"}
                                size={30}
                            />
                            <div>Marketing Copywriter</div>
                        </div>
                    </Link>
                    <a
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - Chrome Extension`,
                                });
                            }
                        }}
                        target="_blank"
                        href="https://chrome.google.com/webstore/detail/scalenut-ai-powered-write/hihmdflioglieahhkcllmngdmpijboae"
                    >
                        <div className={styles.navBox}>
                            <FiChrome size={20} />
                            <div>Chrome Extension</div>
                        </div>
                    </a>
                </>
            ) : (
                <>
                    <Link
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Profile Menu - Marketing Copywriter`,
                                });
                            }
                        }}
                        to="/admin/copywriter"
                    >
                        <div className={styles.navBox}>
                            <SVGIcon
                                src={"/New UI/aside-section/copywriter.svg"}
                            />
                            <div>Marketing Copywriter</div>
                        </div>
                    </Link>
                </>
            )}

            <div
                onClick={() => {
                    dispatch(setChatWidget(true));
                    if (getTrailText(planExpiresDate) == 7 && planTrial) {
                        mixpanelTrack("Day 0 Dashboard", {
                            action: `Profile Menu - Contact Support`,
                        });
                    }
                }}
                className={styles.navBox}
            >
                <BiSupport size={20} />
                <div>Contact Support</div>
            </div>
        </div>
    );
}

export default UserDropdown;
