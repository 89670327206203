import React, { useContext, useEffect, useRef } from "react";
import styles from "./Overview.module.css";
import styles2 from "../../../organisms/TextEditor/EditorAssistant/styles.module.css";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { GoCheck } from "react-icons/go";
import { SMART_EDITOR } from "../../../organisms/TextEditor/editorConstants";
import { OnBoardingContext } from "../../../Context/OnBoardingContext/OnBoardingContextProvider";
import HighlightComponent from "../../../atoms/HighlightComponent";
import { GRADE_RATING_FOCUS } from "../../../Context/OnBoardingContext/constant";
import HighLighter from "../../../atoms/HightLighter/HighLighter";
import CountUp from "react-countup";
import { useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import { debounceWrapper } from "../../../../utility/helperFun";
import ReportContext from "../../../organisms/Research/ReportContext";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import RatingHook from "./RatingHook";
import { useSelector } from "react-redux";

function readabilityNote(readabilityScore) {
    if (readabilityScore <= 0 || readabilityScore >= 100) {
        return "-";
    } else if (readabilityScore <= 10) {
        return "Professional, extremely challenging to read";
    } else if (readabilityScore <= 30) {
        return "College graduate, thought provoking and challenging to read";
    } else if (readabilityScore <= 50) {
        return "College grade, moderately thought provoking to read";
    } else if (readabilityScore <= 60) {
        return "10th to 12th grade, best understood by high school graduates";
    } else if (readabilityScore <= 70) {
        return "8th & 9th grade, plain english";
    } else if (readabilityScore <= 80) {
        return "7th grade, fairly easy to read";
    } else if (readabilityScore <= 90) {
        return "6th grade, easy to read";
    } else if (readabilityScore <= 100) {
        return "5th grade, very easy to read";
    } else return "";
}

export function getEdStatus(readabilityScore) {
    if (readabilityScore <= 0 || readabilityScore >= 100) {
        return "-";
    } else if (readabilityScore <= 20) {
        return "University";
    } else if (readabilityScore >= 21 && readabilityScore <= 40) {
        return "College";
    } else if (readabilityScore >= 41 && readabilityScore <= 60) {
        return "High School";
    } else if (readabilityScore >= 61 && readabilityScore <= 80) {
        return "Mid School";
    } else return "4th Grader";
}

export function grade(quality) {
    if (quality >= 7) {
        return "A";
    } else if (quality >= 5) {
        return "B";
    } else if (quality >= 3.5) {
        return "C";
    } else if (quality >= 2.5) {
        return "D";
    } else if (quality >= 1.5) {
        return "E";
    } else if (quality > 0) {
        return "F";
    } else return "-";
}

export default function Ratings({
    keyTermsLength,
    realtimeRating,
    contentLength,
    attachedTo,
    optimiserLoading,
    isCruiseMode = false,
    content,
    cruiseActiveStep,
}) {
    const onBoarding = useContext(OnBoardingContext);
    const [counter, setCounter] = useState(realtimeRating.wordCount);
    const { getSeoScore, seoScore, isShared, keyTerms, reportInfo } =
        useContext(ReportContext);
    const [loading, setLoading] = useState(false);
    const [wordCountColor, setWordCountColor] = useState("red");
    const { mpTrackRefreshSEOScore } = useMixpanelHook();
    let _seoScore = seoScore;

    if (isCruiseMode) {
        _seoScore = realtimeRating.seoScore;
    }

    let lastStep = 4;

    const getSeoScore_optimiser = async (pos) => {
        setLoading(true);
        mpTrackRefreshSEOScore({ position: pos });
        await getSeoScore();
        setLoading(false);
    };
    let timeoutRef = useRef(null);
    useEffect(() => {
        if (counter != realtimeRating.wordCount) {
            debounceWrapper(
                () => {
                    setCounter((ps) => {
                        return realtimeRating.wordCount;
                    });
                    getWordColor();
                },
                200,
                timeoutRef,
            );
        }
    }, [realtimeRating.wordCount]);

    useEffect(() => {
        getWordColor();
    }, []);

    const hoverText = {
        "quality-score": isCruiseMode
            ? "Import Content in Editor and include as many Key Terms as possible to improve grade further."
            : "Include as many Key Terms as possible to improve Content Grade.",
        "word-count": (
            <>
                Current total word count is{" "}
                <b style={{ fontWeight: 500 }}>{realtimeRating.wordCount}</b>.
                Suggested is{" "}
                <b style={{ fontWeight: 500 }}>{realtimeRating.wordRange}</b>
            </>
        ),
        "readability-score": readabilityNote(realtimeRating.readability.score),
        "optimize-score": (
            <>
                Current SEO Score is{" "}
                <b style={{ fontWeight: 500 }}>{_seoScore}</b>. Suggested is{" "}
                <b style={{ fontWeight: 500 }}>
                    {realtimeRating.suggestedSEOScore}+
                </b>
            </>
        ),
    };

    function getWordColor() {
        const num = realtimeRating.wordRange.split("+");

        if (+realtimeRating.wordCount > +num[0]) {
            setWordCountColor("green");
            return;
        } else if (
            +realtimeRating.wordCount > +num[0] &&
            +realtimeRating.wordCount < +num[0]
        ) {
            setWordCountColor("green");
            return;
        } else if (
            +realtimeRating.wordCount < +num[0] &&
            +realtimeRating.wordCount > +num[0] * 0.7
        ) {
            setWordCountColor("yellow");
        } else {
            setWordCountColor("red");
        }
    }

    function wordCountRate(count) {
        const num = realtimeRating.wordRange.split("+");

        if (+count > +num[0]) {
            return false;
        } else if (+count < +num[0]) return true;
    }

    const _grade = grade(realtimeRating.quality);

    const ratingOptions = [
        {
            title: "SEO Score",
            value: _seoScore,
            suggested: realtimeRating.suggestedSEOScore + "+",
            refresh: () => {
                getSeoScore_optimiser("Bottom");
            },
        },
        {
            title: "Key Terms Usage",
            value: realtimeRating.quality,
            suggested: realtimeRating.suggestedKeywordCount + "+",
        },
        {
            title: "Readability",
            value: Math.round(realtimeRating?.readability?.score) || "-",
            hoverText: realtimeRating?.readability?.notes,
        },
        {
            title: "Word Count",
            value: realtimeRating.wordCount,
            suggested: realtimeRating.wordRange,
        },
    ];

    return (
        <HighlightComponent
            highlight={onBoarding.focusOn == GRADE_RATING_FOCUS}
        >
            <div
                style={{
                    paddingTop: isCruiseMode ? 0 : 10,
                    zIndex: 5,
                    ...(isCruiseMode
                        ? {}
                        : {
                              bottom: 0,
                              position: "absolute",
                              left: "50%",
                              transform: "translateX(-50%)",
                          }),
                }}
            >
                <div
                    className={
                        isCruiseMode
                            ? styles.containerInsideCruise
                            : styles.container
                    }
                >
                    {((attachedTo != SMART_EDITOR && !isCruiseMode) ||
                        (isCruiseMode && cruiseActiveStep == lastStep)) && (
                        <>
                            <LightTooltip
                                arrow
                                title={hoverText["optimize-score"]}
                                placement="bottom"
                            >
                                <div className={styles.overallRate}>
                                    <div>
                                        <div className={styles.left}>
                                            <div className={styles.heading}>
                                                {" "}
                                                SEO Score{" "}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.right}>
                                        {_seoScore == 0 || isNaN(_seoScore) ? (
                                            "-"
                                        ) : (
                                            <span
                                                style={{
                                                    color:
                                                        _seoScore > 85
                                                            ? "#42c134"
                                                            : _seoScore > 60
                                                            ? "#F1A126"
                                                            : "#fc1024",
                                                }}
                                            >
                                                {_seoScore}
                                            </span>
                                        )}
                                        {(!isShared || isShared.access) &&
                                            !isCruiseMode && (
                                                <div
                                                    onClick={() =>
                                                        getSeoScore_optimiser(
                                                            "Bottom",
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <SVGIcon
                                                        className={
                                                            optimiserLoading
                                                                ? styles2.rotate
                                                                : ""
                                                        }
                                                        color={"#014DC5"}
                                                        src={
                                                            "/New UI/SVG/refresh-icon.svg"
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </LightTooltip>

                            <div
                                className={
                                    isCruiseMode
                                        ? styles.separatorCruise
                                        : styles.seperatorNew
                                }
                            ></div>
                        </>
                    )}
                    {(!isCruiseMode ||
                        (isCruiseMode && cruiseActiveStep == lastStep)) && (
                        <>
                            <LightTooltip
                                arrow
                                title={hoverText["word-count"]}
                                placement="bottom"
                                tooltipStyle={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "500px",
                                }}
                            >
                                <div className={styles.overallRate}>
                                    <div>
                                        <div className={styles.left}>
                                            <div className={styles.heading}>
                                                {" "}
                                                Word Count{" "}
                                            </div>
                                        </div>
                                        {attachedTo != SMART_EDITOR && (
                                            <div
                                                className={styles.suggested}
                                            ></div>
                                        )}
                                    </div>
                                    <div
                                        className={`${styles.right} ${
                                            contentLength != 0 &&
                                            (wordCountColor == "green"
                                                ? styles.green
                                                : wordCountColor == "yellow"
                                                ? styles.yellow
                                                : styles.red)
                                        }`}
                                    >
                                        {contentLength == 0 ? (
                                            "-"
                                        ) : (
                                            <CountUp
                                                // className={
                                                //     // styles.animatedCounter +
                                                //     //     " " +
                                                // }
                                                start={counter}
                                                end={realtimeRating.wordCount}
                                            />
                                        )}

                                        {contentLength > 0 &&
                                            attachedTo != SMART_EDITOR && (
                                                <>
                                                    {" "}
                                                    {wordCountRate(
                                                        realtimeRating.wordCount,
                                                    ) ? (
                                                        "↑"
                                                    ) : (
                                                        <GoCheck />
                                                    )}
                                                    {/* {typeof wordCountRate(
                                                realtimeRating.wordCount,
                                            ) == "boolean" ? (
                                                wordCountRate(
                                                    realtimeRating.wordCount,
                                                ) ? (
                                                    "↑"
                                                ) : (
                                                    "↓"
                                                )
                                            ) : (
                                                <GoCheck />
                                            )} */}
                                                </>
                                            )}
                                    </div>
                                </div>
                            </LightTooltip>
                            <div
                                className={
                                    isCruiseMode
                                        ? styles.separatorCruise
                                        : styles.seperatorNew
                                }
                            ></div>
                        </>
                    )}

                    {isCruiseMode && cruiseActiveStep == lastStep ? (
                        <LightTooltip
                            arrow
                            title={
                                isCruiseMode ? (
                                    <KeyTermsUsagePopup
                                        keyTerms={keyTerms}
                                        content={content}
                                    />
                                ) : (
                                    ""
                                )
                            }
                            placement="top"
                            tooltipStyle={{
                                backgroundColor: "white",
                            }}
                            arrowStyle={{ color: "white" }}
                            interactive
                        >
                            <div className={styles.overallRate}>
                                <LightTooltip
                                    title="Suggested no. of key terms to be used"
                                    arrow
                                >
                                    <div>
                                        <div className={styles.left}>
                                            <div className={styles.heading}>
                                                {" "}
                                                Key Terms Usage{" "}
                                            </div>
                                        </div>
                                        {attachedTo != SMART_EDITOR && (
                                            <div className={styles.suggested}>
                                                Suggested:{" "}
                                                {
                                                    realtimeRating.suggestedKeywordCount
                                                }
                                                {"+"}
                                            </div>
                                        )}
                                    </div>
                                </LightTooltip>

                                <div className={styles.right}>
                                    {contentLength == 0 ? (
                                        "-"
                                    ) : (
                                        <span
                                            style={{
                                                color:
                                                    realtimeRating.quality >
                                                    realtimeRating.suggestedKeywordCount *
                                                        0.99
                                                        ? "#42c134"
                                                        : realtimeRating.quality >
                                                          realtimeRating.suggestedKeywordCount *
                                                              0.49
                                                        ? "#F1A126"
                                                        : "#fc1024",
                                            }}
                                        >
                                            {realtimeRating.quality}
                                            {realtimeRating.quality <
                                            realtimeRating.suggestedKeywordCount ? (
                                                " ↑"
                                            ) : (
                                                <GoCheck />
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </LightTooltip>
                    ) : !isCruiseMode ? (
                        <LightTooltip
                            arrow
                            title={hoverText["readability-score"]}
                            placement="top"
                            tooltipStyle={{
                                backgroundColor: "white",
                            }}
                            arrowStyle={{ color: "white" }}
                        >
                            <div className={styles.overallRate}>
                                <div>
                                    <div className={styles.left}>
                                        <div className={styles.heading}>
                                            Readability
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.right}>
                                    <span>
                                        {Math.round(
                                            realtimeRating?.readability?.score,
                                        ) || "-"}
                                    </span>
                                </div>
                            </div>
                        </LightTooltip>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </HighlightComponent>
    );
}

export function RatingsV2({
    quality,
    wordCount,
    style = {},
    highlightScore = true,
    seoScoreStyle = {},
    wordCountStyle = {},
    addSeparator = "",
}) {
    const [counter, setCounter] = useState(wordCount);

    useEffect(() => {
        setTimeout(() => {
            setCounter(wordCount);
        }, 200);
    }, [wordCount]);

    const hoverText = {
        "quality-score": "SEO Score of the document",
        "word-count": "No. of words present in the document",
        "readability-score": "As measured by the Flesch reading-ease test",
    };
    return (
        <div
            style={{
                minWidth: 340,
                zoom: 1,
                transform: "scale(0.9)",
                margin: "unset",
                ...style,
            }}
            className={styles.container}
        >
            {
                <>
                    <LightTooltip
                        arrow
                        title={hoverText["quality-score"]}
                        placement="bottom"
                    >
                        <div className={styles.overallRate}>
                            <div>
                                <div className={styles.left}>
                                    <div className={styles.heading2}>
                                        {" "}
                                        SEO Score {addSeparator || ""}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <HighLighter
                                    quality={quality}
                                    style={{
                                        ...(!!Object.keys(seoScoreStyle)
                                            .length && {
                                            backgroundColor: "none",
                                            marginLeft: "-13px",
                                        }),
                                        // padding: "0px",
                                        fontSize: "inherit",
                                        minWidth: "unset",
                                    }}
                                />
                            </div>
                        </div>
                    </LightTooltip>
                </>
            }
            {wordCount === false ? (
                <></>
            ) : (
                <LightTooltip
                    arrow
                    title={hoverText["word-count"]}
                    placement="bottom"
                >
                    <div className={styles.overallRate}>
                        <div>
                            <div className={styles.left}>
                                <div className={styles.heading2}>
                                    {" "}
                                    Word Count {addSeparator || ""}
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.right}
                            style={{
                                ...(wordCountStyle && {
                                    marginLeft: "0px",
                                }),
                            }}
                        >
                            {
                                <>
                                    <CountUp
                                        // className={
                                        //     // styles.animatedCounter +
                                        //     //     " " +
                                        // }
                                        start={counter}
                                        end={wordCount}
                                    />
                                    {/* {typeof wordCountRate(realtimeRating.wordCount) ==
            "boolean" ? (
              wordCountRate(realtimeRating.wordCount) ? (
                "↑"
              ) : (
                "↓"
              )
            ) : (
              <GoCheck />
            )} */}
                                </>
                            }
                        </div>
                    </div>
                </LightTooltip>
            )}
        </div>
    );
}

function KeyTermsUsagePopup({ keyTerms, content }) {
    const [list, setList] = useState([...keyTerms]);
    console.log(content, keyTerms, list, "Rahul");

    useEffect(() => {
        (async () => {
            const rating = await RatingHook(
                content,
                keyTerms?.filter((ele) => ele?.status),
                "usagePopup",
            );
            setList([
                ...rating?.keyTermslist?.sort((a, b) => a?.used - b?.used),
            ]);
        })();
    }, []);
    return (
        <div className={styles.keyTermsPopupContainer}>
            <h3>Key Terms Usage</h3>
            <div className={styles.keyTermsPopup}>
                {list.map((keyTerm) => {
                    return (
                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={
                                !keyTerm.used
                                    ? "Unused Key Term"
                                    : "This Key Term is already used"
                            }
                        >
                            <span
                                className={`${styles.keyword} ${
                                    !keyTerm.used ? styles.unused : ""
                                }`}
                            >
                                {keyTerm.keyword}
                            </span>
                        </LightTooltip>
                    );
                })}
            </div>
        </div>
    );
}
