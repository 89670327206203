import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { CREATE_PAYMENT, PAYMENT, WALLET_RECHARGE } from "./api.json";
import { displayAlert } from "../store/actions/alert";
import Logout from "../components/authentication/logout";
import { url } from "../utility/config";
import { OnBoardingContext } from "./Context/OnBoardingContext/OnBoardingContextProvider";
import { useContext } from "react";
import { device_token } from "../App";
import { scalenutAPIEvent } from "../constants";
import * as Sentry from "@sentry/react";

const controller = new AbortController();
const ScalenutAPIEvent = new Event(scalenutAPIEvent);

export function getMsg(msg) {
    if (
        msg &&
        (msg.includes("undefined") ||
            msg.includes("syntax") ||
            msg.includes("json") ||
            msg.includes("error"))
    ) {
        msg = "Something went wrong";
    }

    return msg;
}

const retry = async (fn, retries = 2, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
        try {
            return await fn();
        } catch (err) {
            if (err?.toJSON()?.message === "Network Error") {
                Sentry.captureException(err);
                if (i < retries - 1) {
                    console.log(`Retrying request... Attempt ${i + 1}`);
                    await new Promise((resolve) => setTimeout(resolve, delay));
                } else {
                    throw err;
                }
            } else {
                throw err;
            }
        }
    }
};

function handleError(logout, dispatch, err, showError = true, data = "") {
    try {
        if (+err?.response?.status >= 500)
            axios.post(
                "https://chat-api.fuguchat.com/api/webhook?token=6f7882a9624973c2ecf5dfd219ef8f096a93a8ee3920ff102bc43664c3cadeee",
                {
                    data: {
                        message: `
          ${data}           
          *Error*: ${JSON.stringify(err)}
          `,
                    },
                },
            );

        if (err?.toJSON()?.message === "Network Error") {
            // dispatch(
            //     displayAlert({
            //         alertMessage: "Please Check your Connection",
            //         alertType: "error",
            //     }),
            // );
        } else {
            if (err?.response?.status == "503") {
                return (window.location =
                    window.location.origin + "/" + "maintenance");
            }

            if (showError) {
                if (err?.response?.status == "401") {
                    logout();
                    controller.abort();
                }
                dispatch(
                    err?.response?.status == "403" ||
                        err?.response?.status == "402" ||
                        err?.response?.status == "405"
                        ? displayAlert({
                              alertMessage: "",
                              alertType: "popup",
                              errorData: {
                                  ...err?.response?.data,
                                  status: err.response.status,
                              },
                          })
                        : displayAlert({
                              alertMessage: getMsg(
                                  err?.response?.data?.message,
                              ),
                              alertType: "error",
                          }),
                );
            }
        }
    } catch (error) {
        console.log(error);
    }
}

const useHelperFunctions = () => {
    let token = useSelector((state) => state.auth.token);
    let _token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjE0MDA0LCJlbWFpbCI6InRlc3RAeW9wbWFpbC5jb20iLCJyb2xlIjoidXNlciJ9LCJleHAiOjE3MTMxNzc3NDA5ODgsImlhdCI6MTcxMzE3Nzc0MH0.Bs0jDqxCPk54STNo2DM7oZRhDvueLrpTcGdArAO0DoU";
    const email = useSelector((state) => state.auth.email);
    const gst_no = useSelector((state) => state.auth.gst_no);
    const currency = useSelector((state) => state.auth.currency);

    const onBoarding = useContext(OnBoardingContext);

    const BASE_URL = url;
    const dispatch = useDispatch();

    const logout = Logout({
        render: (logoutHandler) => {
            return logoutHandler;
        },
    });

    async function getData({
        url = "",
        completeUrl = "",
        optionalToken = false,
        header = { "X-Frame-Options": "DENY" },
        skipErrorMsg = false,
    }) {
        const fetchData = async () => {
            dispatchEvent(ScalenutAPIEvent);
            if (url) {
                if (url?.includes("?")) {
                    url = `${url}&client_time_offset=${new Date().getTimezoneOffset()}`;
                } else {
                    url = `${url}?client_time_offset=${new Date().getTimezoneOffset()}`;
                }
            }

            if (optionalToken) {
                optionalToken = token ? false : true;
            }

            return await axios({
                signal: controller.signal,
                url: completeUrl ? completeUrl : BASE_URL + url,
                method: "get",
                skipErrorMsg: skipErrorMsg,
                headers: optionalToken
                    ? {
                          "Cache-Control": "no-cache",
                          Pragma: "no-cache",
                          Expires: "0",
                          ...header,
                      }
                    : {
                          ...device_token,
                          Authorization: token,
                          "Cache-Control": "no-cache",
                          Pragma: "no-cache",
                          Expires: "0",
                          ...header,
                      },
            });
        };

        try {
            const res = await retry(fetchData);
            if (res.data.achievement_unlocked) {
                onBoarding.setState((ps) => ({
                    ...ps,
                    currentStepCompleted: res.data.achievement_unlocked,
                }));
            }
            return res;
        } catch (err) {
            handleError(
                logout,
                dispatch,
                err,
                true,
                `
          *Email*: ${email}
          *Type*: get
          *Url*: ${url}
        `,
            );
            return err;
        }
    }

    async function postData({
        method = "post",
        url = "",
        headers = {
            "Content-Type": "application/json",
            "X-Frame-Options": "DENY",
        },
        payload = {},
        optionalToken = false,
        showError = true,
        completeUrl = "",
    }) {
        const sendData = async () => {
            dispatchEvent(ScalenutAPIEvent);
            if (optionalToken) {
                optionalToken = token ? false : true;
            }

            return await axios({
                signal: controller.signal,
                url: completeUrl ? completeUrl : BASE_URL + url,
                method,
                headers: optionalToken
                    ? { ...headers }
                    : { ...device_token, Authorization: token, ...headers },
                data: payload,
            });
        };

        try {
            const res = await retry(sendData);
            if (
                gst_no == null &&
                currency == "INR" &&
                (url == CREATE_PAYMENT ||
                    url == PAYMENT ||
                    url == WALLET_RECHARGE)
            )
                window.localStorage.setItem("qazwsxedc", "109plmokn");
            if (res.data.achievement_unlocked) {
                onBoarding.setState((ps) => ({
                    ...ps,
                    currentStepCompleted: res.data.achievement_unlocked,
                }));
            }
            return res;
        } catch (err) {
            handleError(
                logout,
                dispatch,
                err,
                showError,
                `
          *Email*: ${email}
          *Type*: post
          *Url*: ${url}
          *Payload*: ${JSON.stringify(payload)}
        `,
            );
            return err;
        }
    }

    async function putData({
        method = "put",
        url,
        headers = { "Content-Type": "application/json" },
        payload,
    }) {
        const updateData = async () => {
            return await axios({
                url: BASE_URL + url,
                method,
                headers: { ...device_token, Authorization: token, ...headers },
                data: payload,
            });
        };

        try {
            const res = await retry(updateData);
            return res;
        } catch (err) {
            handleError(
                logout,
                dispatch,
                err,
                true,
                `
        *Email*: ${email}
        *Type*: put
        *Url*: ${url}
        *Payload*: ${JSON.stringify(payload)}
      `,
            );
            return err;
        }
    }

    async function deleteData({
        url,
        completeUrl,
        optionalToken = false,
        header = { "X-Frame-Options": "DENY" },
    }) {
        const removeData = async () => {
            dispatchEvent(ScalenutAPIEvent);
            if (url) {
                if (url?.includes("?")) {
                    url = `${url}&client_time_offset=${new Date().getTimezoneOffset()}`;
                } else {
                    url = `${url}?client_time_offset=${new Date().getTimezoneOffset()}`;
                }
            }

            if (optionalToken) {
                optionalToken = token ? false : true;
            }

            return await axios({
                signal: controller.signal,
                url: completeUrl ? completeUrl : BASE_URL + url,
                method: "delete",
                headers: optionalToken
                    ? {
                          "Cache-Control": "no-cache",
                          Pragma: "no-cache",
                          Expires: "0",
                          ...header,
                      }
                    : {
                          ...device_token,
                          Authorization: token,
                          "Cache-Control": "no-cache",
                          Pragma: "no-cache",
                          Expires: "0",
                          ...header,
                      },
            });
        };

        try {
            const res = await retry(removeData);
            return res;
        } catch (err) {
            handleError(
                logout,
                dispatch,
                err,
                true,
                `
          *Email*: ${email}
          *Type*: delete
          *Url*: ${url}
        `,
            );
            return err;
        }
    }

    async function verifyToken({ url }) {
        const verify = async () => {
            return await axios({
                url: BASE_URL + url,
                method: "get",
                headers: {
                    ...device_token,
                    Authorization: localStorage.getItem("token"),
                },
            });
        };

        try {
            const res = await retry(verify);
            return res;
        } catch (err) {
            handleError(logout, dispatch, err);
            return err;
        }
    }

    return {
        getData,
        postData,
        verifyToken,
        putData,
        deleteData,
    };
};

export default useHelperFunctions;
