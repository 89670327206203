import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import styles2 from "./ExitIntentSubscription.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import PriceCard from "./PriceCard";
import Button from "../../componentsV2/atoms/Button/Button";
import { PLANS_CATEGORIES, PLANS_TYPE } from "../../constants";
import Progress from "../../componentsV2/atoms/Progress/Progress";
import useOnClickOutside from "../../componentsV2/atoms/Modal/useOnClickOutside";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
} from "@material-ui/core";
import { MdKeyboardArrowDown } from "react-icons/md";
import { features, partnerImages, WhyScalenut } from "./lists";
import { OnBoardingContext } from "../../componentsV2/Context/OnBoardingContext/OnBoardingContextProvider";
import WebsiteHtml from "../../componentsV2/templates/Dashboard/WebsiteHtml";

import { STRIPE_TOKEN1, STRIPE_TOKEN2 } from "../../utility/config";
import { CREATE_PAYMENT } from "../../componentsV2/api.json";

import usePriceDiscounts from "../../componentsV2/templates/Dashboard/usePriceDiscounts";
import { socket } from "../../Sockets/useSockets";
import { socketEvents } from "../../Sockets/constants";
import { displayAlert } from "../../store/actions/alert";
import { customLoadStripe } from "../../componentsV2/atoms/Stripe";
import SVGIcon from "../../componentsV2/atoms/SVGIcon";
import PostPaymentModal from "../../componentsV2/organisms/PostPaymentModal/PostPaymentModal";
import DealTop from "./DealTop";
import CustomCard from "./CustomCard";
import { ExitIntentModal } from "../../componentsV2/templates/Dashboard/CCUser";
import useUserAccessHook from "../account/useUserAccessHook";
import PriceCardBF from "./PriceCardBF";
import DealTopBF, { calculateResultantDiscount } from "./DealTopBF";
import PostPaymentModalBF from "../../componentsV2/organisms/PostPaymentModal/PostPaymentModalBF";
import LmAddOnCard from "./LmAddOnCard";
import { useHistory } from "react-router-dom";
import CompareTable from "./CompareTable";
import useMixpanelHook from "../../utility/useMixpanelHook";

const customAccordion = makeStyles((theme) => ({
    root: {
        background: "transparent",
        boxShadow: "none  !important",
        color: "#29394a",
        "& :hover": {
            color: "#0079e4",
        },
    },
    expanded: {
        "& :hover": {
            color: "#29394a",
        },
    },
}));

const pricingPageFaqs = [
    {
        ques: "How does Free trial work ?",
        ans: "You can opt for our 7-day free trial anytime to check out our AI tools and see for yourself how it helps you create both short copy and SEO ranking long form content. In case you decide to discontinue the selected plan, you will be automatically moved to a free plan. ",
    },

    {
        ques: "What payment methods do you offer?",
        ans: "Scalenut accepts all major credit and debit cards. We use Stripe for secure payment processing, including 3D secure authentication for your privacy and protection.",
    },
    {
        ques: "Can I change the plan anytime?",
        ans: (
            <div>
                Yes, you can easily switch between plans anytime as per your
                needs. If there are unused credits from your current plan then
                we’ll automatically deduct an equivalent amount from your new
                bill. The subsequent billing cycle will then reflect the
                original price for the plan
                <br />
                <br />
                For instance, if you are only 15 days into using your monthly
                plan and wish to upgrade, then you get a discount of half of the
                initial plan’s monthly subscription fee while upgrading.
            </div>
        ),
    },
    {
        ques: "Can I cancel the plan anytime?",
        ans: "Yes, you are completely free to cancel your subscription at any time. Upon cancellation, you’ll still be able to use our services till the end of the billing cycle.",
    },

    {
        ques: "How do I get additional credits for SEO tools or AI writing?",
        ans: `Yes, Scalenut pricing is flexible and you can easily switch between plans anytime as per your needs. Option of buying additional credits is available in Growth and Pro plans. If you are on an Essential plan, you can upgrade to Growth or Pro plan anytime.`,
    },
    {
        ques: "Can I add my team members?",
        ans: `Yes, you can add additional team members in the Pro plan. In the pro plan, you can add 1 team member free of cost. You can always buy additional seats at an additional cost.`,
    },
    {
        ques: "What’s the refund policy?",
        ans: (
            <div>
                Scalenut offers a 7-day risk-free trial. We encourage users to
                test out the platform before making an informed decision to
                subscribe to a paid plan. Scalenut doesn’t offer refund post the
                trial period. However, feel free to reach out to us at{" "}
                <a href="mailto:wecare@scalenut.com?subject=Mail%20from%20Pricing%20Page%20FAQs">
                    wecare@scalenut.com
                </a>{" "}
                or chat for any queries about your plans.
            </div>
        ),
    },
    {
        ques: "I have more questions. Who can help?",
        ans: (
            <div>
                Feel free to chat with us or send us an email at{" "}
                <a href="mailto:wecare@scalenut.com">wecare@scalenut.com</a>.
                Our support executives will be more than happy to answer all
                your queries.
            </div>
        ),
    },
    {
        ques: "Who is the Enterprise plan for and how does it work?",
        ans: "The Enterprise plan is tailored to cater to the needs of large SEO teams that require extensive usage and advanced features. Our pricing and feature offerings are designed with the flexibility to accommodate your specific requirements. Whether you're a big SEO team in an organization or a large agency, this plan equips you to excel in SEO and content marketing.",
    },
];

const maxPlansFAQs = [
    {
        ques: "What are Max Plans?",
        ans: "Max Plans are an exclusive opportunity to subscribe to our fully loaded software suite. These plans not only come with much higher limits, and better discounts but also with free AI Link Manager and AI Humanizer add-ons. This is a one-time offer with only 100 slots available.",
    },
    {
        ques: "Will the discount be applicable for life?",
        ans: "Yes, the discounts will be applicable as long as you are subscribed to our service.",
    },
    {
        ques: "When are the Max Plans available?",
        ans: "Our fantastic offers for the Max Plans will be available from Aug 8, 2024 with only 100 slots available. Grab your deal before the licenses run out!",
    },
    {
        ques: "Will the add-ons be free for my subscription for life?",
        ans: "Yes, the add-ons will remain free as long as you are subscribed to our service. As you scale your business, you can add more limits by paying nominal charges as applicable.",
    },
    {
        ques: "Who can opt for the Max Plans?",
        ans: "All registered users can opt for these plans.",
    },
    {
        ques: "Are the licenses refundable?",
        ans: "No. These are special licenses with a limited number of slots. If you are not sure, you can try the product for free. Also, see what users across the world are saying about us on G2 and Capterra.",
    },
    {
        ques: "Which add-ons are included in the Max Plans?",
        ans: "AI Link Manager, AI Detector, and AI Humanizer are included for free.",
    },
    {
        ques: "Help - My queries are not resolved yet. What should I do?",
        ans: "Get in contact with our friendly support team via live chat, or email us as wecare@scalenut.com.",
    },
];

export const Faq = ({ item }) => {
    const accordionClasses = customAccordion();
    const [showAns, setShowAns] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowAns(false));

    return (
        <div onClick={() => setShowAns(!showAns)} className={styles.faqItem}>
            <Accordion classes={accordionClasses}>
                <AccordionSummary
                    expandIcon={<MdKeyboardArrowDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={styles.faqQues}>{item.ques}</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.faqAns}>{item.ans}</div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

function SubsCards({ aside }) {
    const otherInfo = useSelector((state) => state.otherInfo);
    // const currency = useSelector((state) => state.auth.currency);
    const { freeUser, trialUser, isLTD, isFreeForever, plan_category } =
        useUserAccessHook();
    const signupMode = useSelector((state) => state.auth.signup_mode);
    const [defaultAddOnCheckedProp, setDefaultAddOnCheckedProp] =
        useState(false);
    const [isDeal, setIsDeal] = useState(false);
    const [maxPlans, setMaxPlans] = useState(false);
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const { deals } = useSelector((state) => state.appConfig);
    const { plan_type } = useUserAccessHook();
    const history = useHistory();
    const { mixpanelTrack } = useMixpanelHook();
    const {
        plans: cards,
        planTypesWithDiscount,
        plansType,
        setPlansType,
        getDiscount,
        typesOfPlans,
        planDetails,
        isOpenChat,
    } = usePriceDiscounts();
    const ref = useRef();

    // useEffect(() => {
    //     if (
    //         plan_category != PLANS_CATEGORIES.PRO &&
    //         plan_category != PLANS_CATEGORIES.GROWTH &&
    //         plan_category != PLANS_CATEGORIES.EXPERT
    //     ) {
    //         setMaxPlans(true);
    //     }
    // }, [plan_category]);

    // useEffect(() => {
    //     if (freeUser || trialUser) {
    //         setIsDeal(true);
    //     } else {
    //         setIsDeal(false);
    //     }
    // }, [freeUser, trialUser]);

    // useEffect(() => {
    //     setIsDeal(
    //         (otherInfo?.subscriptionInfo?.plan_category ==
    //             PLANS_CATEGORIES.PRO &&
    //             otherInfo?.subscriptionInfo?.type == 2) ||
    //             isLTD
    //             ? false
    //             : true,
    //     );
    // }, [otherInfo?.subscriptionInfo?.plan_id]);

    const { ccPlan } = useContext(OnBoardingContext);
    const { top, bottom } = WebsiteHtml({
        subs: true,
        plansType,
        cards: cards,
        isDeal: isDeal,
        setPlansType,
        getDiscount,
        // dealsLimit: deals ? JSON.parse(deals)?.dealLimitsMultiplier : 1,
        dealsLimit: isDeal ? 2 : 1,
        maxPlans: maxPlans,
    });

    useEffect(() => {
        if (ccPlan) {
            purchaseHandler(ccPlan);
        }
        const displayError = (data) => {
            setTimeout(() => {
                dispatch(
                    displayAlert({
                        alertMessage: data.message,
                        alertType: "error",
                        timeout: 10000,
                    }),
                );
            }, 5000);
        };

        socket.on(socketEvents.EARLY_BUY_FAILED, displayError);

        return () => {
            socket.off(socketEvents.EARLY_BUY_FAILED, displayError);
        };
    }, []);

    useEffect(() => {
        mixpanelTrack("Subscription Page", {
            action: maxPlans ? "Max Plan View" : "Base Plan View",
        });
    }, [maxPlans]);

    const getPriceList = (planType) => {
        return cards
            .sort((card1, card2) => card1.plan_cost - card2.plan_cost)
            .filter(
                (card) =>
                    planType === card.plan_type ||
                    card.plan_category == PLANS_CATEGORIES.FREE_FOREVER,
            );
    };

    useEffect(() => {
        if (ref.current) {
            const slider = ref.current;

            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;

                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener("mouseleave", () => {
                isDown = false;
            });
            slider.addEventListener("mouseup", () => {
                isDown = false;
            });
            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                console.log(walk);
            });
        }
    }, [ref]);

    const PriceCardList = ({
        data,
        prevData,
        currentPlanDetails,
        getDiscount,
        plans = [],
    }) => {
        const otherInfo = useSelector((state) => state.otherInfo);
        const { plan_type, isLoaded, trialUser } = useUserAccessHook();
        const [humanizerChecked, setHumanizerChecked] = useState(false);
        const [validLmPlan, setValidLmPlan] = useState({});
        const [validHumanizerPlans, setValidHumanizerPlans] = useState({});
        const { getData } = useHelperFunctions();
        const [defaultAddOnChecked, setDefaultAddOnChecked] = useState(
            trialUser
                ? false
                : otherInfo?.subscriptionInfo
                      ?.interlinking_purchased_pages_quantity > 0
                ? true
                : false,
        );

        useEffect(() => {
            if (window.location.pathname.includes("/addon")) {
                setDefaultAddOnChecked(true);
                history.replace("/admin/account/plans");
            } else if (
                otherInfo?.subscriptionInfo
                    ?.interlinking_purchased_pages_quantity
            ) {
                setDefaultAddOnChecked(
                    trialUser
                        ? false
                        : otherInfo?.subscriptionInfo
                              ?.interlinking_purchased_pages_quantity > 0 &&
                          otherInfo?.subscriptionInfo?.interlinking_status != 0
                        ? true
                        : false,
                );
            }
        }, [
            window.location.pathname,
            otherInfo?.subscriptionInfo?.interlinking_purchased_pages_quantity,
        ]);
        // const [currentAvailable, setCurrentAvailable] = useState();

        useEffect(() => {
            if (data.length) {
                let tempValidPlan = plans.find(
                    (vp) =>
                        vp.plan_cost > 1 &&
                        vp.plan_category == PLANS_CATEGORIES.INTER_LINKING,
                );
                setValidLmPlan(tempValidPlan || {});
            }
            if (data.length) {
                let tempPlans = plans.find(
                    (vp) =>
                        vp.plan_cost > 1 &&
                        vp.plan_category == PLANS_CATEGORIES.HUMANIZER,
                );
                setValidHumanizerPlans(tempPlans || {});
            }
        }, [data, plansType]);

        if (maxPlans) {
            return plans
                .filter(
                    (pCard) =>
                        pCard.plan_category == PLANS_CATEGORIES.GROWTH_MAX ||
                        pCard.plan_category == PLANS_CATEGORIES.PRO_MAX,
                )
                .map((icard, i) => (
                    <PriceCardBF
                        maxPlans={maxPlans}
                        otherInfo={otherInfo}
                        item={icard}
                        planType={plansType}
                        data={data}
                        prevVal={prevData[i]}
                        currentPlanId={currentPlanDetails?.plan_id}
                        details={currentPlanDetails}
                        isOpenChat={isOpenChat}
                        getDiscount={getDiscount}
                        signupMode={signupMode}
                        lmPlan={validLmPlan}
                        defaultAddOnChecked={defaultAddOnChecked}
                        validHumanizerPlans={validHumanizerPlans}
                        humanizerChecked={humanizerChecked}
                        setHumanizerChecked={setHumanizerChecked}
                        setValidHumanizerPlans={setValidHumanizerPlans}
                        setDefaultAddOnChecked={
                            trialUser || isFreeForever
                                ? setDefaultAddOnChecked
                                : () => {}
                        }
                    />
                ));
        }
        return data
            .filter(
                (pCard) =>
                    pCard.plan_category != PLANS_CATEGORIES.INTER_LINKING &&
                    pCard.plan_category != PLANS_CATEGORIES.HUMANIZER &&
                    pCard.plan_category != PLANS_CATEGORIES.GROWTH_MAX &&
                    pCard.plan_category != PLANS_CATEGORIES.PRO_MAX,
            )
            .map((icard, i) =>
                isDeal ? (
                    <PriceCardBF
                        otherInfo={otherInfo}
                        item={icard}
                        planType={plansType}
                        data={data}
                        prevVal={prevData[i]}
                        currentPlanId={currentPlanDetails?.plan_id}
                        details={currentPlanDetails}
                        isOpenChat={isOpenChat}
                        getDiscount={getDiscount}
                        signupMode={signupMode}
                        lmPlan={validLmPlan}
                        defaultAddOnChecked={defaultAddOnChecked}
                        validHumanizerPlans={validHumanizerPlans}
                        humanizerChecked={humanizerChecked}
                        setHumanizerChecked={setHumanizerChecked}
                        setValidHumanizerPlans={setValidHumanizerPlans}
                        setDefaultAddOnChecked={
                            trialUser || isFreeForever
                                ? setDefaultAddOnChecked
                                : () => {}
                        }
                    />
                ) : (
                    <PriceCard
                        otherInfo={otherInfo}
                        item={icard}
                        planType={plansType}
                        data={data}
                        prevVal={prevData[i]}
                        currentPlanId={currentPlanDetails?.plan_id}
                        details={currentPlanDetails}
                        isOpenChat={isOpenChat}
                        getDiscount={getDiscount}
                        signupMode={signupMode}
                        lmPlan={validLmPlan}
                        defaultAddOnChecked={defaultAddOnChecked}
                        validHumanizerPlans={validHumanizerPlans}
                        humanizerChecked={humanizerChecked}
                        setHumanizerChecked={setHumanizerChecked}
                        setValidHumanizerPlans={setValidHumanizerPlans}
                        setDefaultAddOnChecked={setDefaultAddOnChecked}
                    />
                ),
            );
    };

    useEffect(() => {
        if (maxPlans) {
            setPlansType(2);
        }
    }, [maxPlans]);

    useEffect(() => {
        if (typesOfPlans.length) {
            setPlansType(
                typesOfPlans.includes(otherInfo.subscriptionInfo?.type)
                    ? otherInfo.subscriptionInfo?.type
                    : PLANS_TYPE.YEARLY,
            );
            // setIsDeal(
            //     (otherInfo?.subscriptionInfo?.plan_category ==
            //         PLANS_CATEGORIES.PRO &&
            //         otherInfo?.subscriptionInfo?.type == 2) ||
            //         isLTD
            //         ? false
            //         : true,
            // );
        }
    }, [otherInfo?.subscriptionInfo?.plan_id, typesOfPlans]);

    const purchaseHandler = async (planId) => {
        const stripeID =
            otherInfo?.subscriptionInfo?.stripe_region == 2
                ? STRIPE_TOKEN2
                : STRIPE_TOKEN1;
        const stripe = customLoadStripe(stripeID);
        let baseURL = window.location.origin;
        let successURL = `${baseURL}/admin/transaction568894547/98797984111237/?session_id={CHECKOUT_SESSION_ID}&dashboard=true`;
        let cancelURL = `${baseURL}/admin/account/plans`;
        let criteria = {
            successURL,
            cancelURL,
            mode: "subscription",
            tid: localStorage.getItem("FPROM_ID"),
            type: 1,
            planID: planId,
        };

        const response = await postData({
            url: CREATE_PAYMENT,
            payload: criteria,
        });
        if (response.status == 200)
            stripe.redirectToCheckout({
                sessionId: response?.data?.paymentTransactionID,
            });
    };

    console.log(plansType, "plansTypeplansType");
    return (
        <>
            <div className={styles.container}>
                {/* {(freeUser || trialUser) && <ExitIntentModal />} */}
                {(otherInfo?.subscriptionInfo?.type != PLANS_TYPE.LIFETIME ||
                    otherInfo?.subscriptionInfo?.plan_category ==
                        PLANS_CATEGORIES.FREE_FOREVER) && (
                    <>
                        {/* {<ExitIntentModal setMaxPlan={setMaxPlans} />} */}

                        {maxPlans ? <></> : <DealTop />}
                        {/* {isDeal ? <DealTopBF deals={deals} /> : <DealTop />} */}
                        <div
                            className={styles.cardContainer}
                            id={"planContainer"}
                        >
                            <div className={maxPlans ? styles.maxPlansBG : ""}>
                                {maxPlans && (
                                    <>
                                        <AnniversaryText />
                                        <div className={styles.licenseBar}>
                                            <div className={styles.licenseTop}>
                                                <span
                                                    className={
                                                        styles.licenseTopText
                                                    }
                                                >
                                                    100 LICENSES ONLY
                                                </span>
                                            </div>
                                            <div className={styles.licenseItem}>
                                                Free AI Link
                                                <br />
                                                Manager
                                            </div>
                                            +
                                            <div className={styles.licenseItem}>
                                                Free AI
                                                <br />
                                                Humanizer
                                            </div>
                                            +
                                            <div className={styles.licenseItem}>
                                                Higher
                                                <br />
                                                limits
                                            </div>
                                            +
                                            <div className={styles.licenseItem}>
                                                Price lock-in
                                                <br />
                                                for Life
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: maxPlans ? "20px" : "",
                                    }}
                                >
                                    <MaxPlansToggle
                                        maxPlans={maxPlans}
                                        setMaxPlans={setMaxPlans}
                                    />
                                </div> */}
                                {!maxPlans && (
                                    <div className={styles.headerSection3}>
                                        <SubscriptionToggle
                                            plansType={plansType}
                                            setPlansType={setPlansType}
                                            isDeal={isDeal}
                                            getDiscount={getDiscount}
                                        />
                                    </div>
                                )}
                                <div
                                    ref={ref}
                                    className={
                                        styles.cards +
                                        " " +
                                        (!aside ? styles.cards2 : "")
                                    }
                                >
                                    {cards.length > 0 && planDetails ? (
                                        <>
                                            <PriceCardList
                                                otherInfo={otherInfo}
                                                data={getPriceList(plansType)}
                                                prevData={getPriceList(
                                                    plansType - 1,
                                                )}
                                                currentPlanDetails={planDetails}
                                                getDiscount={getDiscount}
                                                defaultAddOnCheckedProp={
                                                    defaultAddOnCheckedProp
                                                }
                                                setDefaultAddOnCheckedProp={
                                                    setDefaultAddOnCheckedProp
                                                }
                                                plans={cards}
                                                // maxPlans={maxPlans}
                                            />
                                            {/* <CustomCard
                                            plansType={plansType}
                                            isDeal={isDeal}
                                        /> */}
                                        </>
                                    ) : (
                                        <>
                                            <Progress height="100vh" />
                                        </>
                                    )}
                                </div>
                                {/* Optional Add-on Link Manager */}
                                {maxPlans && (
                                    <div className={styles.freeAddOnText}>
                                        Free Add-ons only with Max plans
                                    </div>
                                )}
                                <div
                                    style={
                                        maxPlans ? { marginBottom: "20px" } : {}
                                    }
                                >
                                    <LmAddOnCard
                                        setDefaultAddOnChecked={
                                            trialUser || isFreeForever
                                                ? setDefaultAddOnCheckedProp
                                                : () => {}
                                        }
                                        plansToggler={plansType}
                                        plans={getPriceList(plansType)}
                                        allPlans={cards}
                                        getDiscount={getDiscount}
                                        isOpenChat={isOpenChat}
                                        isDeal={isDeal}
                                        maxPlans={maxPlans}
                                    />
                                </div>
                                {maxPlans && (
                                    <div className={styles.freeAddOnText}>
                                        Why Max plans are best compared to all
                                        plans
                                    </div>
                                )}
                            </div>
                            {maxPlans && <CompareTable cards={cards} />}
                            {top}
                            {bottom}
                        </div>
                        <div className={styles.partnerContainer}>
                            <div className={styles.reviewContainerHeading}>
                                And Trusted by{" "}
                                <span className={styles.highlight}>
                                    200+ Businesses
                                </span>
                            </div>
                            <div className={styles.partners}>
                                {partnerImages.map((src, i) => (
                                    <img
                                        key={i}
                                        src={src}
                                        alt={"partner"}
                                        style={{
                                            filter: "grayscale(100%)",
                                        }}
                                        className={styles.partner}
                                    />
                                ))}
                            </div>
                        </div>
                        <div></div>
                        <div
                            style={{ marginBottom: 15 }}
                            className={styles.headerSection1}
                        >
                            Trusted Choice of{" "}
                            <span className={styles.mark}>100,000+</span>{" "}
                            Marketers
                            <br /> Spanning{" "}
                            <span className={styles.mark}>150+</span> Countries
                        </div>
                        <div className={styles.headerSection1_child}>
                            <div className={styles.headerSection2}>
                                <div className={styles.gradeCards}>
                                    <SVGIcon src="/New UI/SVG/G2-Banners.svg" />
                                </div>
                            </div>
                        </div>
                        {
                            <div className={styles.specificationContainer}>
                                <div className={styles.spLeft}>
                                    <div className={styles.spLeftHeading}>
                                        The only
                                        <br />
                                        platform with
                                    </div>
                                    <div className={styles.spLeftBottom}>
                                        <div className={styles.spec}>
                                            <FiCheck color="#fff" size={25} />
                                            Entire SEO content lifecycle
                                        </div>
                                        <div className={styles.spec}>
                                            <FiCheck color="#fff" size={25} />
                                            AI that reads latest information
                                        </div>
                                        <div className={styles.spec}>
                                            <FiCheck size={25} />
                                            World class customer support
                                        </div>
                                        <div className={styles.spec}>
                                            <FiCheck size={25} />
                                            Assured Quality
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.spRight}>
                                    {features.map((item, i) => (
                                        <div className={styles.feature} key={i}>
                                            <div
                                                className={
                                                    styles.feature__imgContainer
                                                }
                                            >
                                                <img
                                                    src={item.imgSrc}
                                                    alt=""
                                                    class="icon tab"
                                                    style={{
                                                        width: "100% ",
                                                        height: "100%",
                                                    }}
                                                />
                                            </div>
                                            <h3 className={styles.featureText}>
                                                {item.text}{" "}
                                            </h3>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        {/* Why  begin */}
                        <div className={styles.whyWrapper}>
                            <div className={styles.whyTitle}>
                                Why Scalenut ?
                            </div>
                            <div className={styles.whyCards}>
                                {WhyScalenut.map((item) => {
                                    return (
                                        <div
                                            key={item.heading}
                                            className={styles.whyCard}
                                        >
                                            <img
                                                src={item.imgSrc}
                                                alt={item.heading}
                                                className={styles.whyImg}
                                            />
                                            <div className={styles.whyHeading}>
                                                {item.heading}
                                            </div>
                                            <div className={styles.whyText}>
                                                {item.text}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* Why  ends */}
                        <div className={styles.faqContainer}>
                            <div className={styles.faqHeading}>
                                {maxPlans
                                    ? "Frequently Asked Questions"
                                    : "Product FAQ’s"}
                            </div>
                            <div className={styles.fqas}>
                                {maxPlans ? (
                                    <MaxPlansFAQ />
                                ) : (
                                    pricingPageFaqs.map((item, i) => (
                                        <Faq item={item} key={i} />
                                    ))
                                )}
                            </div>
                        </div>
                        {/* Contact us starts */}
                        <div className={styles.talkContainer}>
                            <div className={styles.talkText}>
                                Not sure which plan is <br />
                                right for you ?
                            </div>
                            <div className={styles.talkCta}>
                                <a
                                    href="https://calendly.com/d/5gx-7jm-fw2/scalenut-demo"
                                    target="_blank"
                                >
                                    Talk To Us{" "}
                                </a>
                            </div>
                        </div>
                        {/* Contact us ends */}
                    </>
                )}
            </div>
            <PostPaymentModal />
            {/* <PostPaymentModalBF plansType={plansType} /> */}
        </>
    );
}

export const SubscriptionToggle = ({
    plansType,
    setPlansType,
    isDeal,
    getDiscount,
}) => {
    return (
        <>
            <div style={{ marginBottom: "10px" }}>Monthly</div>
            <label className={styles.switch}>
                <input
                    onChange={(e) => setPlansType(e.target.checked ? 2 : 1)}
                    type="checkbox"
                    checked={plansType == 2 ? true : false}
                />
                <span
                    className={`${styles.slider} ${
                        isDeal ? styles.sliderBF : ""
                    } ${styles.round}`}
                ></span>
                <div className={isDeal ? styles.saveBF : styles.save}>
                    Save {getDiscount(2)}%
                </div>
            </label>
            <div style={{ marginBottom: "10px" }}>Annual</div>
        </>
    );
};

export const MaxPlansToggle = ({ setMaxPlans, maxPlans }) => {
    return (
        <div className={styles.maxToggleContainer}>
            <div
                style={
                    maxPlans ? { background: "none", color: "#EAECF0B2" } : {}
                }
                onClick={() => {
                    setMaxPlans(false);
                }}
                className={styles.basePlanBtn}
            >
                Base Plans
            </div>
            <div
                style={maxPlans ? { background: "#000" } : {}}
                onClick={() => {
                    setMaxPlans(true);
                }}
                className={styles.maxPlanBtn}
            >
                <div className={styles.maxPlanBigText}>Max Plans</div>
                <div className={styles.maxPlanBtnSubText}>LIMITED EDITION</div>
            </div>
        </div>
    );
};

export const AnniversaryText = () => {
    return (
        <div className={styles.DealTopText}>
            <div>
                Celebrating 4th Anniversary with
                <br />
                the{" "}
                <span>
                    Limited Edition Max plans{" "}
                    <div
                        style={{
                            position: "absolute",
                            bottom: "-10px",
                            right: "-10px",
                        }}
                    >
                        <SVGIcon
                            src={
                                "/src/components/subsCards/saleIcons/textUnderline.svg"
                            }
                        />
                    </div>
                </span>
            </div>
        </div>
    );
};

export const MaxPlansFAQ = () => {
    return (
        <div
            style={{
                flexDirection: "row",
                flexWrap: "wrap",
            }}
            className={styles.fqas}
        >
            {maxPlansFAQs.map((faq) => (
                <div className={styles.maxPlanFaqContainer}>
                    <div className={styles.maxPlanQuestion}>{faq.ques}</div>
                    <div className={styles.maxPlanAnswer}>{faq.ans}</div>
                </div>
            ))}
        </div>
    );
};

export default SubsCards;
