import mixpanel from "mixpanel-browser";
import React, { useContext } from "react";
import styles from "./styles.module.css";
import { useState } from "react";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { withStyles } from "@material-ui/core";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import UpgradeModal from "../WebTune/UpgradeModal";
import BuyMore from "../WebTune/BuyMore";
import InterLinkingContextProvider, {
    InterlinkingContext,
} from "./InterLinkingContextProvider";
import useUserAccessHook from "../../../components/account/useUserAccessHook";

type Props_ManageTooltip = {
    screen: string;
    domainId: number;
};

const CustomLightTooltip = withStyles((theme) => ({
    tooltip: {
        minWidth: 440,
        border: "1px solid #e6e6e6 !important",
        background: "white !important",
    },
    arrow: {
        marginTop: "-24px !important",
        width: "3em",
        height: "2em",
        "&::before": {
            border: "1px solid #e6e6e6 !important",
            color: "white !important",
        },
    },
}))(LightTooltip);

const ManageTooltip = ({ screen = "", domainId }: Props_ManageTooltip) => {
    const [buyPagesModal, setBuyPagesModal] = useState<{
        isShow: boolean;
        domainId: null | number;
    }>({ isShow: false, domainId: null });
    const [upgradeModal, setUpgradeModal] = useState({ isShow: false });
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const { trialUser } = useUserAccessHook();
    return (
        <>
            {!trialUser ? (
                // <CustomLightTooltip
                //     placement="bottom"
                //     interactive
                //     title={
                //         <div className={styles.limitExceed}>
                //             <div className={styles.icoWrap}>
                //                 <SVGIcon
                //                     src={"/svg/exclamation-triangle.svg"}
                //                     style={{
                //                         color: "#1f4cbe",
                //                     }}
                //                 />
                //             </div>
                //             <div className={styles.limitExceedTagline}>
                //                 Plan Limit Exceeded
                //             </div>
                //             <div className={styles.limitExceedSubText}>
                //                 {stable_version_total_crawled_pages -
                //                     issueSummary?.stableVersionTotalPages}{" "}
                //                 pages have not been processed due to plan
                //                 limits.
                //                 <br />
                //                 Buy more pages to find all issues in the domain.
                //             </div>
                //             <div className={styles.bottomCta}>
                //                 <Button
                //                     text={"Buy More Pages"}
                //                     handler={() => {
                //                         mixpanel.track("Buy More Pages", {
                //                             Screen: "On Page Issues",
                //                         });
                //                         setBuyPagesModal({
                //                             isShow: true,
                //                             domainId: domainId,
                //                         });
                //                     }}
                //                     style={{
                //                         width: 140,
                //                     }}
                //                 />
                //             </div>
                //         </div>
                //     }
                //     arrow
                // >
                <div
                    className={styles.manageCta}
                    onClick={(e) => {
                        mixpanel.track("Buy More Pages", {
                            Screen: "On Page Issues",
                        });
                        setBuyPagesModal({
                            isShow: true,
                            domainId: domainId,
                        });
                    }}
                >
                    Buy Pages
                </div>
            ) : (
                // </CustomLightTooltip>
                <div
                    className={styles.upgradeCta}
                    onClick={(e) => {
                        mixpanel.track("Buy Plan", {
                            Screen: "On Page Issues",
                        });
                        e.preventDefault();
                        e.stopPropagation();
                        setUpgradeModal({
                            isShow: true,
                        });
                    }}
                >
                    Buy Plan
                </div>
            )}

            {buyPagesModal?.isShow && (
                <BuyMore
                    setBuyPagesModal={setBuyPagesModal}
                    domainId={buyPagesModal.domainId}
                    screen={"Link Manager Header"}
                />
            )}
            {upgradeModal?.isShow && (
                <UpgradeModal
                    setModal={setUpgradeModal}
                    screen={"Link Manager Header"}
                />
            )}
        </>
    );
};
export default ManageTooltip;
