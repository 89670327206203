import { days, hours } from "../../../lib/dash/trialNoti";

export const AI_BAR = "ai_bar";

export const SEO_BLOG = "seo_blog";
export const REFER_CARD = "refer_card";

export const GRADE_RATING = "grade_rating";

export const SEO_EDITOR = "SEO_EDITOR";
export const AUDIT = "AUDIT";
export const CREATOR = "CREATOR";
export const CLUSTER = "CLUSTER";
export const DASHBOARD_TEMPLATES = "DASHBOARD_TEMPLATES";

export const DASHBOARD = "DASHBOARD";

export const buttonLinks = {
    1: { screen: DASHBOARD_TEMPLATES, comp: "" },
    2: { screen: SEO_EDITOR, comp: AI_BAR },
    3: { screen: CREATOR, comp: "" },
    4: { screen: DASHBOARD, comp: SEO_BLOG },
    5: { screen: SEO_EDITOR, comp: GRADE_RATING },
    6: { screen: CLUSTER, comp: "" },
    7: { screen: SEO_EDITOR, comp: GRADE_RATING },
    7: { screen: AUDIT, comp: "" },
};

export const seoUseCases = [
    {
        cat_id: -1,
        cat_name: "SEO",
        category_icons:
            "https://storage.googleapis.com/scalenut/96b93744-0d2c-45bb-bc3a-2b762445d053.svg",
        description:
            "Research and Create Rankable content with AI-Powered SEO Assistant",
        fields: "",
        icons: "/New UI/SVG/Create Content Brief.svg",
        id: 28,
        isTitleRequired: 1,
        name: `Create Content Brief`,
        priority: 1,
        useCase: -1,
    },
    {
        cat_id: -1,
        cat_name: "SEO",
        category_icons:
            "https://storage.googleapis.com/scalenut/96b93744-0d2c-45bb-bc3a-2b762445d053.svg",
        description:
            "Power through blog writing with our carefully created workflow",
        fields: "",
        icons: "/New UI/SVG/Cruise Mode.svg",
        id: 28,
        isTitleRequired: 1,
        name: `Create Blog in less than 5 mins`,
        priority: 1,
        useCase: -2,
    },
    {
        cat_id: -1,
        cat_name: "SEO",
        category_icons:
            "https://storage.googleapis.com/scalenut/96b93744-0d2c-45bb-bc3a-2b762445d053.svg",
        description:
            "Generate clusters for you primary keyword in just a few clicks.",
        fields: "",
        icons: "/New UI/SVG/Plan Content.svg",
        id: 28,
        isTitleRequired: 1,
        name: `Plan Content (Keyword Reports)`,
        priority: 1,
        useCase: -3,
    },
];

export const getTrailText = (planExpiresDate) => {
    const diffInHrs = (+new Date(planExpiresDate) - +new Date()) / 3600000;
    const _days = days(planExpiresDate) + 1;
    return _days;
    // if (diffInHrs <= 0) return `Trial expired: Upgrade now!`;
    // else if (diffInHrs > 24)
    //     return `Trial expires in ${_days} days: Upgrade now!`;
    // else if (diffInHrs > 1)
    //     return `Trial expires in ${
    //         hours(planExpiresDate) + 1
    //     } hours: Upgrade now!`;
    // else return `Trial is about to expire: Upgrade now!`;
};
export const FEATURES = {
    cruise_mode: 1,
    optimizer: 2,
    keyword_planner: 3,
};
