import React, { useState, useEffect, useContext } from "react";
import styles from "./KeyTerms.module.css";
import useHelperFunctions from "../../../helperFunctions";
import { CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import WriteToEditor from "../../../organisms/TextEditor/Toolbar/WriteToEditor";
import ReportContext from "../ReportContext";
import EditorContext from "../../TextEditor/EditorContext";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

const HeadingItem = ({
    heading,
    item,
    writingState,
    setWritingState,

    list,
    reportInfo,
    writeWithAnimation,
}) => {
    const [clickToAdd, setClickToAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const { postData } = useHelperFunctions();
    const { isShared } = useContext(ReportContext);
    const { versionData } = useContext(EditorContext);

    useEffect(() => {
        let timer = null;
        if (clickToAdd) {
            timer = setTimeout(() => {
                setClickToAdd(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [clickToAdd]);

    const writeParagraphOnHeading = async (keyword, subheading) => {
        document?.quill?.editor?.enable(false);
        setLoading(true);
        setWritingState({ state: true, text: "", useCase: -1 });

        const res = await postData({
            url: "/api/generate",
            payload: {
                Keyword: keyword,
                SubHeading: subheading,
                useCase: 57,
                title: reportInfo.title,
                report_id: reportInfo.report_id,
            },
        });
        let answer = `\n${subheading}\n\n${res?.data?.output[0]?.content?.trim()}`;
        if (res.status == 200) {
            await writeWithAnimation(answer);
        }

        document?.quill?.editor?.enable(true);
        setLoading(false);
        setWritingState({ state: false, text: "", useCase: -1 });
    };
    const addToEditor = (str) => {
        if (writingState.state) return;
        WriteToEditor({
            data: !document.quill.editor.getText(
                document.quill.editor.cursor,
                1,
            )
                ? str
                : document.quill.editor.getText(
                      document.quill.editor.cursor,
                      1,
                  ) != "\n"
                ? "\n" + str
                : str,
            type: "text",
            length: str.length + 1,
            source: "user",
        });
        setClickToAdd(true);
    };
    return (
        <div className={styles.headingItemContainer}>
            <div className={styles.headingItemMain}>
                <LightTooltip
                    title={
                        item.type == 0 ? "SERP Heading" : "AI Suggested Heading"
                    }
                    placement="top"
                    arrow
                >
                    {item.type == 0 ? (
                        <img
                            src={"/AssetsV2/serp-icon.svg"}
                            className={styles.normalIconSize}
                        />
                    ) : (
                        <img
                            src={"/AssetsV2/AIiconheadingicon.svg"}
                            className={styles.normalIconSize}
                        />
                    )}
                </LightTooltip>
                <div
                    className={styles.headingItemText}
                    onClick={() => addToEditor(item.headingUsage)}
                >
                    {/* <TextShortner data={item.headingUsage} limit={70} /> */}
                    <LightTooltip
                        title={clickToAdd ? "Added!" : "Click to Add"}
                        placement="top"
                    >
                        <span>{item.headingUsage}</span>
                    </LightTooltip>
                </div>
            </div>
            {!isShared &&
                (!loading ? (
                    <LightTooltip
                        title={"Generate Text for this heading"}
                        placement="top"
                        arrow
                    >
                        <img
                            src={"/AssetsV2/AI-icon@1.5x.svg"}
                            onClick={() => {
                                if (
                                    writingState?.state ||
                                    isShared ||
                                    !versionData.current
                                )
                                    return;
                                writeParagraphOnHeading(
                                    heading,
                                    item.headingUsage,
                                );
                            }}
                            className={styles.subheadingAi}
                            style={
                                writingState?.state ||
                                isShared ||
                                !versionData.current
                                    ? { cursor: "not-allowed" }
                                    : {}
                            }
                        />
                    </LightTooltip>
                ) : (
                    <div style={{ height: 20, width: 20 }}>
                        <CircularProgress size={15} />
                    </div>
                ))}
        </div>
    );
};

export default HeadingItem;
