import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./GeneralInsights.module.css";
import { GSC_Countries } from "../GscCountries";
import {
    CircularProgress,
    ClickAwayListener,
    TextareaAutosize,
    makeStyles,
} from "@material-ui/core";
import Button from "../../../atoms/Button/Button";
import { Skeleton } from "@material-ui/lab";
import useGscHook, {
    CountryObject,
    GscInsightsDataObject,
} from "../useGscHook";
import SVGIcon from "../../../atoms/SVGIcon";
import BarChartHorizontal from "../../../atoms/Chart/BarChartHorizontal";
import LineChart from "../../../atoms/Chart/LineChart";
import SimpleDonutChart from "../../../atoms/Chart/SimpleDonutChart";
import Modal from "../../../atoms/Modal/Modal";
import useHelperFunctions from "../../../helperFunctions";
import moment from "moment";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { IoMdRefresh } from "react-icons/io";
import TopGainsTable, { TopPagesKeywordsTable } from "./InsightsTopGains";
import { useMixpanelTA } from "../useMixpanelTA";
const SortByOptions = [
    { title: "Clicks", id: 1 },
    { title: "Impressions", id: 2 },
];

type Props_GscInsights = {
    selectedDomain: {
        country?: string;
        siteUrl?: string;
        flagCode?: string;
        country_code?: string;
    };
    gscInsightsData: GscInsightsDataObject;
    isFetchingInsights: boolean;
    insightsOtherInfo: { dateRange: { start: string; end: string } };
};

type Props_GeneralInsights = {
    countryDistribution: Props_GscInsights["gscInsightsData"]["countries_info"];
    deviceDistribution: Props_GscInsights["gscInsightsData"]["devices_info"];
    ctrGraphData: any;
    positionDistribution: { all: Object; branded: Object; nonBranded: Object };
    lost_pages_info: [];
    crawlInfo: Props_GscInsights["gscInsightsData"]["web_crawl_info"];
    nextRefresh: Props_GscInsights["gscInsightsData"]["next_refresh_at"];
    top_movers_losers_keywords: Props_GscInsights["gscInsightsData"]["top_movers_losers_keywords"];
    top_movers_losers_pages: Props_GscInsights["gscInsightsData"]["top_movers_losers_pages"];
    isToneDown?: boolean;
    monthlyInsights?: Props_GscInsights["gscInsightsData"]["monthly_insights_info"];
    delta_keywords_count: Props_GscInsights["gscInsightsData"]["delta_keywords_count"];
    top_pages_info?:
        | Props_GscInsights["gscInsightsData"]["top_pages_info"]
        | [];
    top_keywords_info?:
        | Props_GscInsights["gscInsightsData"]["top_keywords_info"]
        | [];
    new_pages_info: Props_GscInsights["gscInsightsData"]["new_pages_info"];
    isShimmer: boolean;
    activeCountry: CountryObject | undefined;
    isProcessingInsights: boolean;
};

const useStylesTab = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        color: "#3F5575",

        opacity: 1,
        height: 30,
        minHeight: 40,
        fontSize: 14,
        fontWeight: 400,
        textDecoration: "none",
        textTransform: "none",
        borderBottom: "2px solid transparent",
        padding: 4,
        flexGrow: 1,
        width: "max-content",
        maxWidth: "max-content",
        "&.Mui-disabled": {
            opacity: 1,
            color: "#3F5575",
        },
    },
    selected: {
        color: "#014dc5",
        fontWeight: 500,
        backgroundColor: "#fff",
        borderBottom: "4px solid #014dc5",
    },
    disabled: {
        color: "green",
        opacity: "none !important",
        pointerEvents: "none",
        "&::Mui-disabled": {
            opacity: "none !important",
        },
    },
    indicator: {
        display: "none",
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    root: {
        "& .MuiTabScrollButton-root.Mui-disabled": {
            display: "none",
        },
        width: "50%",
        padding: 0,
        marginRight: "auto",
        overflow: "auto",
        backgroundColor: "transparent",
    },
    indicator: {
        display: "none",
    },
}));

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={
                value == index
                    ? {
                          position: "relative",
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          padding: index == 2 ? "0px" : "0px",
                      }
                    : { position: "relative" }
            }
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export const options = {
    indexAxis: "y" as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    scales: {
        xAxis: {
            min: 0,
            max: 100,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "right" as const,
        },
        title: {
            display: false,
            text: "",
        },
    },
};

export const data = {
    labels: [
        "India",
        "United States",
        "United Kingdom",
        "Singapore",
        "Australia",
        "Others",
    ],
    datasets: [
        {
            label: "%",
            data: [20, 30, 45, 40, 24, 10],
            borderColor: "#10CFFC",
            backgroundColor: "#10CFFC",
        },
    ],
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        value: index,
    };
}

const useStylesTabSub = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        borderRadius: "6px",
        color: "#3F5575",

        opacity: 1,
        width: 30,
        minWidth: 70,
        fontSize: 13,
        fontWeight: 500,
        textDecoration: "none",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        height: 42,
        minHeight: 40,
    },

    selected: {
        color: "#014dc5",
        fontWeight: 500,
        background: "#014dc51a",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },

    indicator: {
        display: "none",
    },
}));

const useStylesTabsSub = makeStyles((theme) => ({
    root: {
        "& .MuiTabScrollButton-root.Mui-disabled": {
            display: "none",
        },
        width: "100%",
        padding: 0,
        margin: "auto",
        overflow: "auto",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "row",
    },
    indicator: {
        display: "none",
    },
}));

function a11yPropsSubPanel(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        value: index,
    };
}

const FilterOptions: { label: string; key: string }[] = [
    { label: "All ", key: "all" },
    { label: "Branded", key: "branded" },
    { label: "Non Branded", key: "nonBranded" },
];

export const NoGraphData = ({ text = "Pages" }: { text?: string }) => {
    return (
        <div className={styles.noGraphDataWrapper}>
            <SVGIcon size={180} src={"/New UI/SVG/Emptydocument.svg"} />
            <div className={styles.text}>No {text} found</div>
        </div>
    );
};

const GraphRowShimmer = () => {
    return (
        <div className={styles.gicStatsRow1Shimmer}>
            <div className={styles.graphBox}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "20%",
                    }}
                >
                    <Skeleton width={"100%"} height={"50%"} />
                </div>
                <Skeleton
                    width={"100%"}
                    height={400}
                    style={{ marginTop: "-70px" }}
                />
            </div>
            <div className={styles.graphBox}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "20%",
                    }}
                >
                    <Skeleton width={"100%"} height={"50%"} />
                    <Skeleton width={"100%"} height={"50%"} />
                </div>

                <Skeleton
                    width={"100%"}
                    height={360}
                    style={{ marginTop: "-50px" }}
                />
            </div>
            <div className={styles.graphBox}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "20%",
                    }}
                >
                    <Skeleton width={"100%"} height={"50%"} />
                </div>
                <Skeleton
                    width={"100%"}
                    height={400}
                    style={{ marginTop: "-70px" }}
                />
            </div>
        </div>
    );
};

const InsightStatCard = ({
    title = "Traffic",
    value = 0,
    change = 0,
    subText = "Month-on-month change",
    isShimmer = true,
    tooltipText = "",
}: {
    title: string;
    value?: number;
    change?: number;
    subText?: string;
    tooltipText: string;
    isShimmer: boolean;
}) => {
    return isShimmer ? (
        <div className={styles.statCard_Shimmer}>
            <Skeleton width={"30%"} height={"30px"} />
            <Skeleton width={"50%"} height={"40px"} />
            <Skeleton width={"90%"} height={"30px"} />
        </div>
    ) : (
        <div className={styles.statCard}>
            <LightTooltip title={tooltipText} arrow placement={"top"}>
                <div className={styles.cardTitle}>{title}</div>
            </LightTooltip>
            <div className={styles.infoWrapper}>
                <div className={styles.statRow}>
                    <div className={styles.statRow_value}>
                        {title?.toLowerCase() == "ctr"
                            ? Math.round(value * 100) / 100 + "%"
                            : (Math.round(value * 100) / 100)?.toLocaleString()}
                    </div>
                    <div
                        className={`${styles.statRow_change} ${
                            change < 0 ? styles.dec : styles.inc
                        }`}
                    >
                        <SVGIcon
                            src="/New UI/SVG/arrowUp.svg"
                            size={11}
                            style={
                                change < 0 ? { transform: "rotate(90deg)" } : {}
                            }
                        />
                        {title?.toLowerCase() == "ctr"
                            ? Math.abs(Math.round(change * 100) / 100) + ""
                            : Math.abs(
                                  Math.round(change * 100) / 100,
                              ).toLocaleString()}
                    </div>
                </div>

                <div className={styles.infoWrapper_subtext}></div>
            </div>
        </div>
    );
};

export const FilterGraphData = ({
    filterOptions,
    handleFilter,
    selectedFilter,
}: {
    filterOptions: any[];
    handleFilter: (filter: any) => void;
    selectedFilter: any;
}) => {
    const [isDropDown, setIsDropDown] = useState<boolean>(false);
    return (
        <ClickAwayListener onClickAway={() => setIsDropDown(false)}>
            <div
                className={styles.filterDropDownWrapper}
                onClick={() => {
                    setIsDropDown(!isDropDown);
                }}
            >
                <div className={styles.selectedFilter}>
                    <div className={styles.selectedFilter_text}>
                        {selectedFilter.label}
                    </div>{" "}
                    <SVGIcon
                        src={`/New UI/SVG/${
                            isDropDown ? "arrow-head-up" : "arrow-head-down"
                        }.svg`}
                        size={isDropDown ? 12 : 11}
                        style={{ color: "#3F5575" }}
                    />
                </div>
                {isDropDown && (
                    <div className={styles.filterOptions}>
                        {filterOptions.map((item: any, index: number) => {
                            return (
                                <div
                                    className={`${styles.filterOption} ${
                                        selectedFilter.label == item.label &&
                                        styles.selectedOption
                                    }`}
                                    onClick={() => {
                                        handleFilter(item);
                                        setIsDropDown(false);
                                    }}
                                >
                                    {item.label}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

const ItemRow = ({
    row,
    index,
    isRankRow = false,
}: {
    row: {
        page: string;
        impressions?: number;
        indexation?: { indexingState?: string };
        class?: number;
        position?: number;
        delta: number;
        clicks: number;
    };
    index: number;
    isRankRow?: boolean;
}) => {
    const { removeDomainFromURL } = useGscHook();

    return (
        <div className={styles.tableRow}>
            <div className={styles.rowPageUrl}>
                <LightTooltip
                    title={row.page}
                    placement={"bottom"}
                    arrow
                    onOpen={(e: any) => {
                        if (e.target.scrollWidth <= e.target.offsetWidth) {
                            setImmediate(() => {
                                document
                                    .querySelectorAll("[role=tooltip]")
                                    .forEach(
                                        (e: any) => (e.style.display = "none"),
                                    );
                            });
                        }
                    }}
                >
                    <div
                        className={styles.rowPageUrl_data}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(row.page);
                        }}
                    >
                        {removeDomainFromURL(row.page)}
                    </div>
                </LightTooltip>

                {isRankRow ? (
                    row.class != 0 ? (
                        <div
                            className={`${styles.newOutIndicator} ${
                                row.class == 1
                                    ? styles.new
                                    : row.class == -1
                                    ? styles.out
                                    : ""
                            }`}
                        >
                            {row.class == 1
                                ? "NEW"
                                : row.class == -1
                                ? "OUT"
                                : ""}
                        </div>
                    ) : (
                        <></>
                    )
                ) : (
                    row?.indexation?.indexingState && (
                        <div className={styles.rowPageUrl_status}>
                            {row?.indexation?.indexingState}
                        </div>
                    )
                )}
            </div>
            {!isRankRow && (
                <div className={styles.rowClicks}>
                    <div className={styles.rowClicks_value}>{row.clicks}</div>
                </div>
            )}
            {!isRankRow ? (
                <div className={styles.rowTrafficGained}>
                    <div className={styles.rowTrafficGained_value}>
                        {row.impressions}
                    </div>
                </div>
            ) : (
                <div className={styles.rowTrafficGainedRank}>
                    <div className={styles.rowTrafficGained_value}>
                        {row.hasOwnProperty("position")
                            ? Math.round(row?.position ? row?.position : 0)
                            : "-"}
                    </div>
                    {Math.round(row.delta) != 0 ? (
                        <div
                            className={`${styles.rowTrafficGained_change} ${
                                row?.delta && row?.delta < 0
                                    ? styles.dec
                                    : styles.inc
                            }`}
                        >
                            <SVGIcon
                                src="/New UI/SVG/arrowUp.svg"
                                size={10}
                                style={
                                    row?.delta && row?.delta < 0
                                        ? { transform: "rotate(90deg)" }
                                        : {}
                                }
                            />
                            {row?.delta
                                ? Math.abs(Math.round(row?.delta))
                                : "0"}
                        </div>
                    ) : (
                        <div className={styles.nullBox}></div>
                    )}
                </div>
            )}
        </div>
    );
};

const defaultRow = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const PagesTableRowShimmer = () => {
    return (
        <div>
            {defaultRow.map((i) => (
                <div className={styles.LostPageRowShimmer}>
                    <Skeleton width={"82%"} height={"34px"} />
                    <Skeleton width={"15%"} height={"34px"} />
                </div>
            ))}
        </div>
    );
};

const PagesTable = ({
    tableTitle,
    isLost = false,
    data = [],
    tooltip = { isShow: false, text: "" },
    isRankRow = false,
    isShimmer = true,
}: {
    tableTitle: string;
    isLost?: boolean;
    data?: any[];
    tooltip: { isShow: boolean; text: string };
    isRankRow?: boolean;
    isShimmer: boolean;
}) => {
    const [order, setOrder] = useState(1);
    const [count, setCount] = useState(0);
    const [sortBy, setSortBy] = useState<{
        id: number;
        title: string | object;
    }>(SortByOptions[1]);
    const [eventTriggered, setEventTriggered] = useState(false);

    const { taTrackInsightSection } = useMixpanelTA();
    useEffect(() => {
        if (data?.length) {
            let _count = 0;
            data.map((item: any) => {
                if (item?.hasOwnProperty("impressions")) {
                    _count = _count + item.impressions;
                }
            });
            setCount(_count);
        }
    }, [data]);

    const sortData: (a: any, b: any) => number = (a, b) => {
        if (order == 1) {
            const temp = b;
            b = a;
            a = temp;
        }
        if (sortBy.id == 2) {
            return +a.impressions - +b.impressions;
        } else if (sortBy.id == 1) {
            return +a.clicks - +b.clicks;
        } else {
            return +a.impressions - +b.impressions;
        }
    };

    //@ts-ignore
    const _rows = [...data?.sort(sortData)];

    const handleSortByColumns: (index: number) => void = (index) => {
        setSortBy((ps) => {
            if (ps?.id == SortByOptions[index].id) {
                setOrder((ps) => (ps == 0 ? 1 : 0));
                return SortByOptions[index];
            } else {
                setOrder(1);
                return SortByOptions[index];
            }
        });
    };

    return (
        <div
            className={styles.tableWrapper}
            onMouseEnter={() => {
                if (!eventTriggered) {
                    taTrackInsightSection({ sectionName: tableTitle });
                    setEventTriggered(true);
                }
            }}
        >
            <div className={styles.tableMeta}>
                <div className={styles.tableTitle}>
                    {tableTitle}{" "}
                    <SVGIcon
                        tooltip={tooltip?.text}
                        src={"/New UI/SVG/info.svg"}
                        size={17}
                    />
                </div>
                {isLost ? (
                    <></>
                ) : (
                    // <div className={styles.totalLost}>
                    //     Total Impressions:{count}
                    // </div>
                    <div className={styles.dropDownContainer}></div>
                )}
            </div>
            <div className={styles.table}>
                <div className={styles.tableHead}>
                    <div className={styles.headColumn}>Page URL</div>
                    {isRankRow ? (
                        <div
                            className={styles.sortColumnLostPages_rank}
                            onClick={() => {
                                // handleSortByColumns(0);
                            }}
                        >
                            Rank
                        </div>
                    ) : (
                        <>
                            <div
                                className={
                                    styles.sortColumnLostPagesClick +
                                    " " +
                                    (sortBy.id == SortByOptions[0].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(0);
                                }}
                            >
                                Clicks
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#9E9E9E",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[0].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                            <div
                                className={
                                    styles.sortColumnLostPages +
                                    " " +
                                    (sortBy.id == SortByOptions[1].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(1);
                                }}
                            >
                                Impressions
                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#9E9E9E",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[1].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        </>
                    )}
                </div>
                {isShimmer ? (
                    <PagesTableRowShimmer />
                ) : _rows.length > 0 ? (
                    <div className={styles.tableBody}>
                        {_rows.map((row, index) => {
                            return (
                                <ItemRow
                                    row={row}
                                    index={index + 1}
                                    isRankRow={isRankRow}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <NoGraphData />
                )}
            </div>
        </div>
    );
};

const StatsSubCard = ({ statType }: { statType: string }) => {
    return (
        <div className={styles.statsSubContainer}>
            <div className={styles.statsSubContainerWrapper}>
                <div className={styles.statsSubContainer_top}>
                    <div className={styles.val}>366</div>
                    <div className={styles.hiLoContainer}>
                        <div className={styles.highLow}>
                            <span className={styles.icon}>
                                <SVGIcon
                                    src={`/New UI/SVG/arrowUp.svg`}
                                    style={{
                                        color: "#ffffff",
                                    }}
                                />
                            </span>{" "}
                            <span>90%</span>
                        </div>
                    </div>
                </div>
                <LightTooltip title={statType} placement="bottom" arrow>
                    <div className={styles.statTitle}>{statType}</div>
                </LightTooltip>
            </div>
        </div>
    );
};

const CrawlSummary = ({
    crawlTitle,
    crawlInfo,
}: {
    crawlTitle: string;
    crawlInfo?: Props_GeneralInsights["crawlInfo"];
}) => {
    const [isUptoDate, setIsUptoDate] = useState(true);
    const [isCrawlUnspecified, setIsCrawlUnspecified] = useState("");
    const [_crawlInfo, setCrawlInfo] = useState<
        Props_GeneralInsights["crawlInfo"] | undefined
    >();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { getData } = useHelperFunctions();
    useEffect(() => {
        if (crawlInfo?.lastCrawledDate || crawlInfo?.lastUpdatedDate) {
            setCrawlInfo({
                lastCrawledDate: crawlInfo?.lastCrawledDate
                    ? crawlInfo?.lastCrawledDate
                    : "-",
                lastUpdatedDate: crawlInfo?.lastUpdatedDate
                    ? crawlInfo?.lastUpdatedDate
                    : "-",
            });
            if (!crawlInfo?.lastUpdatedDate) {
                setIsCrawlUnspecified("Crawl is unspecified");
            } else {
                setIsCrawlUnspecified("");
            }
        }
    }, [crawlInfo]);

    useEffect(() => {
        if (_crawlInfo?.lastCrawledDate && _crawlInfo.lastUpdatedDate) {
            let a = moment(_crawlInfo.lastCrawledDate);
            let b = moment(_crawlInfo.lastUpdatedDate);
            let status = a.diff(b);
            status;
            if (status >= 0) {
                setIsUptoDate(true);
            } else {
                setIsUptoDate(false);
            }
        }
    }, [_crawlInfo?.lastCrawledDate]);

    const refreshCrawlData: () => void = async () => {
        setIsRefreshing(true);
        setCrawlInfo(undefined);
        const res = await getData({
            url: "/api/analyse/get-insights/webCrawl",
        });
        if (res.status == 200) {
            setCrawlInfo({
                lastCrawledDate: res?.data?.data?.lastCrawledDate
                    ? res?.data?.data?.lastCrawledDate
                    : "-",
                lastUpdatedDate: res?.data?.data?.lastUpdatedDate
                    ? res?.data?.data?.lastUpdatedDate
                    : "-",
            });
        }
        setIsRefreshing(false);
    };

    return (
        <div className={styles.crawlSummaryBox}>
            <div className={styles.crawlSummaryBox_top}>
                <div className={styles.crawlTitle}>{crawlTitle}</div>

                <LightTooltip
                    title={isRefreshing ? "Refreshing" : "Refresh"}
                    placement={"bottom"}
                    arrow
                >
                    <div
                        className={styles.crawlRefresh}
                        onClick={() => {
                            refreshCrawlData();
                        }}
                    >
                        {isRefreshing ? (
                            <CircularProgress
                                style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "#6b6b6b",
                                }}
                            />
                        ) : (
                            <IoMdRefresh
                                style={{ width: "18px", height: "18px" }}
                            />
                        )}
                    </div>
                </LightTooltip>
            </div>
            <div className={styles.crawlInfo}>
                <div className={styles.crawlInfo__left}>
                    <div className={styles.icon}>
                        <SVGIcon
                            src={"/New UI/SVG/search-icon-long.svg"}
                            size={21}
                            style={{ color: "#014DC5" }}
                        />
                    </div>
                    <div className={styles.crawlMetaBox}>
                        <LightTooltip
                            title={
                                _crawlInfo?.lastCrawledDate != "-" ? (
                                    <>
                                        {moment(
                                            _crawlInfo?.lastCrawledDate,
                                        ).format("D MMM, YYYY hh:mm a")}
                                    </>
                                ) : (
                                    ""
                                )
                            }
                            arrow
                            placement={"top"}
                        >
                            <div className={styles.date}>
                                {" "}
                                {_crawlInfo?.lastCrawledDate ? (
                                    _crawlInfo?.lastCrawledDate != "-" ? (
                                        moment(
                                            _crawlInfo?.lastCrawledDate,
                                        ).format("D MMM, YYYY")
                                    ) : (
                                        "-"
                                    )
                                ) : (
                                    <Skeleton animation="wave" />
                                )}
                            </div>
                        </LightTooltip>
                        <LightTooltip
                            arrow
                            title={
                                "Latest date on which Google crawled your website"
                            }
                            placement={"bottom"}
                        >
                            <div className={styles.dateMeta}>
                                Last Crawled ON
                            </div>
                        </LightTooltip>
                    </div>
                </div>
                <div className={styles.crawlInfo__right}>
                    <div className={styles.icon}>
                        <SVGIcon
                            src={"/New UI/SVG/pencil-line.svg"}
                            size={21}
                            style={{ color: "#014DC5" }}
                        />
                    </div>
                    <div className={styles.crawlMetaBox}>
                        <LightTooltip
                            title={
                                _crawlInfo?.lastUpdatedDate != "-" ? (
                                    <>
                                        {moment(
                                            _crawlInfo?.lastUpdatedDate,
                                        ).format("D MMM, YYYY hh:mm a")}
                                    </>
                                ) : (
                                    ""
                                )
                            }
                            arrow
                            placement={"top"}
                        >
                            <div className={styles.date}>
                                {_crawlInfo?.lastUpdatedDate ? (
                                    _crawlInfo?.lastUpdatedDate != "-" ? (
                                        moment(
                                            _crawlInfo?.lastUpdatedDate,
                                        ).format("D MMM, YYYY")
                                    ) : (
                                        "-"
                                    )
                                ) : (
                                    <Skeleton animation="wave" />
                                )}
                            </div>
                        </LightTooltip>

                        <LightTooltip
                            arrow
                            title={
                                "Latest date on which a page was marked as done in Inventory"
                            }
                            placement={"bottom"}
                        >
                            <div className={styles.dateMeta}>
                                WEBSITE UPDATED ON
                            </div>
                        </LightTooltip>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.crawlStatus} ${
                    isCrawlUnspecified
                        ? styles.red
                        : isUptoDate
                        ? styles.green
                        : styles.red
                }`}
            >
                {isCrawlUnspecified
                    ? isCrawlUnspecified
                    : isUptoDate
                    ? "Crawling is Upto Date"
                    : "Crawling is not Upto Date"}
            </div>
        </div>
    );
};

const RequestCard = () => {
    const [isRequestModal, setIsRequestModal] = useState<boolean>(false);
    const [featDesc, setFeatDesc] = useState("");
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { postData } = useHelperFunctions();

    async function requestFeature() {
        setIsLoading(true);
        setIsRequestSuccess(false);
        const res = await postData({
            method: "post",
            url: "/api/integrations/appRequest",
            payload: {
                app_name: "Copilot",
                type: "Copilot-Insights",
                reason: featDesc,
            },
        });
        if (res.status === 200) {
            setIsLoading(false);
            setFeatDesc("");
            setIsRequestSuccess(true);
            setTimeout(() => {
                setIsRequestModal(false);
                setIsRequestSuccess(false);
            }, 2000);
        }
        setIsLoading(false);
    }
    return (
        <div className={styles.requestBox}>
            <div className={styles.requestBox_top}>
                <div className={styles.requestBox_text}>
                    Want some more Insights?
                </div>
                <div className={styles.requestBox_subText}>
                    You can tell us!
                </div>
            </div>
            <Button
                customClass={styles.requestCta}
                text="Request Insights"
                style={{ fontWeight: 400 }}
                handler={() => {
                    setIsRequestModal(true);
                }}
            />
            {isRequestModal && (
                <Modal
                    // @ts-ignore
                    setModal={setIsRequestModal}
                    customClass={styles.requestModalWrapper}
                >
                    {!isRequestSuccess ? (
                        <div className={styles.requestModal}>
                            <div className={styles.modalHeading}>
                                Request New Insight
                                <div
                                    className={styles.modalClose}
                                    onClick={() => {
                                        setIsRequestModal(false);
                                    }}
                                >
                                    <SVGIcon
                                        src={"/New UI/SVG/cross.svg"}
                                        size={16}
                                    />
                                </div>
                            </div>

                            <div style={{ textAlign: "left" }}>
                                <label className={styles.mandatoryLabel}>
                                    Please describe your usecase
                                </label>
                                <TextareaAutosize
                                    placeholder="Type here..."
                                    minRows={3}
                                    className={styles.textarea}
                                    style={{ height: "80px" }}
                                    value={featDesc}
                                    onChange={(e) => {
                                        setFeatDesc(e.target.value);
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    handler={() => {
                                        requestFeature();
                                    }}
                                    loading={isLoading}
                                    style={{ marginTop: "1rem" }}
                                    text={"Submit Request"}
                                    disabled={!featDesc.length}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.requestModal}>
                            <SVGIcon
                                src={"/New UI/SVG/TickWithAura.svg"}
                                style={{ color: "#1DC198" }}
                            />
                            <p className={styles.thankYouText}>
                                Thanks for the suggestion!
                            </p>
                            <p className={styles.subText}>
                                We’re always fine-tuning our services to make
                                the experience better for you.
                            </p>
                        </div>
                    )}
                </Modal>
            )}
        </div>
    );
};

const GainWithCopilot = () => {
    const { GraphBoxSkeleton } = useGscHook();

    return (
        <div className={styles.copilotGainContainer}>
            <div className={styles.cgcStatsRow1}>
                <StatsSubCard statType={"Gained Clicks With Copilot"} />{" "}
                <StatsSubCard statType={"Gained Impressions with Copilot"} />{" "}
                <StatsSubCard statType={"Optimized Pages"} />{" "}
                <StatsSubCard statType={"Gained $ with Copilot"} />
            </div>
            <div className={styles.cgcStatsRow2}>
                <div className={styles.trendGraphContainer}>
                    <div className={styles.trendGraph_Options}>
                        <div>
                            <div className={styles.trendGraphBtn}>
                                Impression Trend
                            </div>
                            <div className={styles.trendGraphBtn}>
                                Click Trend
                            </div>
                            <div className={styles.trendGraphBtn}>
                                Position Trend
                            </div>
                        </div>
                        <div className={styles.trendGraph_legends}>
                            <div className={styles.trendGraph_legends1}>
                                <span className={styles.indicator}></span>
                                Without CoPilot
                            </div>
                            <div className={styles.trendGraph_legends2}>
                                <span className={styles.indicator}></span>With
                                CoPilot
                            </div>
                        </div>
                    </div>
                    <div className={styles.trendGraphBox}>
                        <LineChart
                            noTick={true}
                            data1={[
                                0, 6, 12, 10, 18, 15, 19, 21, 19, 20, 22, 24,
                            ]}
                            data2={[0, 2, 3, 4, 5, 6, 7, 7, 6, 5, 6, 8]}
                            label={[
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ]}
                            height={"20px"}
                        />
                    </div>
                </div>
                <PagesTable
                    tableTitle={"Optimized Pages"}
                    tooltip={{ isShow: false, text: "" }}
                    isShimmer={true}
                />
            </div>
            <div className={styles.cgcStatsRow3}>
                <CrawlSummary crawlTitle={"Optimized Pages Crawling"} />
                <RequestCard />
            </div>
        </div>
    );
};

const GeneralInsights = ({
    countryDistribution,
    deviceDistribution,
    ctrGraphData,
    positionDistribution,
    delta_keywords_count,
    lost_pages_info,
    crawlInfo,
    nextRefresh,
    top_movers_losers_keywords,
    top_movers_losers_pages,
    isToneDown = true,
    monthlyInsights,
    top_pages_info,
    new_pages_info,
    top_keywords_info,
    isShimmer = true,
    activeCountry,
    isProcessingInsights = false,
}: Props_GeneralInsights) => {
    const [valueSub, setValueSub] = useState<number>(0);
    const [_countryDistribution, setCountryDistribution] = useState<{
        max: number;
        data: any[];
    }>({
        max: 0,
        data: [],
    });
    const [_deviceDistributionLabels, setDeviceDistributionLabels] =
        useState<string[]>();
    const [_deviceDistribution, setDeviceDistribution] = useState<number[]>([]);
    const [_countryDistributionLabels, setCountryDistributionLabels] = useState<
        string[]
    >([]);
    const [_ctrGraphData, setCtrGraphData] = useState<{
        max: number;
        data: any[];
    }>({
        max: 0,
        data: [],
    });
    const [ctrFilterBy, setCtrFilterBy] = useState(FilterOptions[0]);

    const [_positionsDistribution, setPositionsDistribution] = useState<{
        max: number;
        data: number[];
    }>({ max: 0, data: [] });
    const [positionFilterBy, setPositionFilterBy] = useState(FilterOptions[0]);
    const [totalKeywordCount, setTotalKeywordCount] = useState(0);
    const [isPagePerformance, setIsPagePerformance] = useState(false);
    const { GraphBoxSkeleton } = useGscHook();
    const classesSubTabs = useStylesTabsSub();
    const classesSubTab = useStylesTabSub();
    const [value, setValue] = useState<number>(0);
    const { taTrackInsightSection } = useMixpanelTA();
    const [eventTriggeredDD, setEventTriggeredDD] = useState(false);
    const [eventTriggeredPD, setEventTriggeredPD] = useState(false);
    const [eventTriggeredCtr, setEventTriggeredCtr] = useState(false);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        if (newValue == 1) {
            setIsPagePerformance(true);
        } else {
            setIsPagePerformance(false);
        }
        setValue(newValue);
    };
    const handleChangeSubs = (event: ChangeEvent<{}>, newValue: number) => {
        setValueSub(newValue);
    };

    useEffect(() => {
        if (countryDistribution?.length) {
            makeGDataCountryDis(countryDistribution);
        }
    }, [countryDistribution]);

    const makeGDataCountryDis: (data: [string, number][]) => void = (data) => {
        let otherCount = 0;
        let tempData: number[] = [];
        let tempLabels: any[] = [];
        let max = 0;
        data.forEach((item, index) => {
            max = max + item[1];
            if (index < 5) {
                tempData.push(item[1]);
                let idx = GSC_Countries.findIndex(
                    (country) =>
                        country.code?.toLowerCase() == item[0].toLowerCase(),
                );
                if (idx >= 0) {
                    tempLabels.push(GSC_Countries[idx].label);
                }
            } else {
                otherCount = otherCount + item[1];
            }
        });
        tempData.push(otherCount);
        tempLabels.push("Others");
        let d = tempData.map((item) => {
            return Number((item / max) * 100);
        });
        setCountryDistribution({
            max,
            data: d,
        });
        setCountryDistributionLabels(tempLabels);
    };

    useEffect(() => {
        if (deviceDistribution) {
            makeGDataDeviceDis(deviceDistribution);
        }
    }, [deviceDistribution]);

    const makeGDataDeviceDis: (data: {}) => void = (data) => {
        let temp = [];
    };

    useEffect(() => {
        if (
            ctrGraphData?.hasOwnProperty("nonBranded") &&
            ctrFilterBy.label.toLowerCase() == "non branded"
        ) {
            makeGDataCTR(ctrGraphData.nonBranded);
        } else if (
            ctrGraphData?.hasOwnProperty("branded") &&
            ctrFilterBy.label.toLowerCase() == "branded"
        ) {
            makeGDataCTR(ctrGraphData.branded);
        } else if (ctrGraphData?.hasOwnProperty("all")) {
            makeGDataCTR(ctrGraphData?.all);
        }
    }, [ctrGraphData, ctrFilterBy]);

    const makeGDataCTR: (data: object) => void = (data) => {
        let temp = [];

        for (let i: any = 1; +i <= 20; i++) {
            if (data?.hasOwnProperty(i.toString())) {
                //@ts-ignore
                temp.push(data[i.toString()]);
            } else {
                temp.push(0);
            }
        }

        temp = temp.splice(0, 20);
        setCtrGraphData({
            max: Math.ceil(Math.max(...temp) / 10) * 10,
            data: temp,
        });
    };

    useEffect(() => {
        if (
            positionDistribution?.hasOwnProperty("nonBranded") &&
            positionFilterBy.label.toLowerCase() == "non branded"
        ) {
            makeGDataPosition(positionDistribution.nonBranded);
        } else if (
            positionDistribution?.hasOwnProperty("branded") &&
            positionFilterBy.label.toLowerCase() == "branded"
        ) {
            makeGDataPosition(positionDistribution.branded);
        } else if (positionDistribution?.hasOwnProperty("all")) {
            makeGDataPosition(positionDistribution?.all);
        } else {
            makeGDataPosition([]);
        }
    }, [positionDistribution, positionFilterBy]);

    useEffect(() => {
        if (deviceDistribution?.hasOwnProperty("DESKTOP")) {
            let tempData: number[] = [
                Math.round((deviceDistribution["MOBILE"] / 10) * 100) / 10,
                Math.round((deviceDistribution["TABLET"] / 10) * 100) / 10,
                Math.round((deviceDistribution["DESKTOP"] / 10) * 100) / 10,
            ];

            setDeviceDistribution(tempData);
        }
    }, [deviceDistribution]);

    const makeGDataPosition: (data: object) => void = (data) => {
        let temp = [];
        let _count = 0;
        for (const [key, value] of Object.entries(data)) {
            temp.push(value ? value : 0);
            _count = _count + value;
        }
        setTotalKeywordCount(_count);
        setPositionsDistribution({
            max: Math.ceil(Math.max(...temp) / 10) * 10,

            data: [...temp],
        });
    };

    const CUSTOM_LEGENDS = [
        {
            label: "Mobile",
            color: "#10CFFC",
            icon: () => (
                <SVGIcon
                    src={"/New UI/SVG/mobile.svg"}
                    style={{ color: "#3F5575" }}
                    size={22}
                />
            ),
        },
        {
            label: "Tablet",
            color: "#FFBE57",
            icon: () => (
                <SVGIcon
                    src={"/New UI/SVG/tablet.svg"}
                    style={{ color: "#3F5575" }}
                    size={22}
                />
            ),
        },
        {
            label: "Desktop",
            color: "#014DC5",
            icon: () => (
                <SVGIcon
                    src={"/New UI/SVG/desktop.svg"}
                    style={{ color: "#3F5575" }}
                    size={22}
                />
            ),
        },
    ];

    return (
        <div
            className={`${styles.genInsightsContainer} ${
                isProcessingInsights && styles.backdropOnPanel
            }`}
        >
            {isProcessingInsights && (
                <div className={styles.backdrop}>
                    <div className={styles.processWrapper}>
                        <lottie-player
                            src="https://assets1.lottiefiles.com/packages/lf20_enrpmvws.json"
                            background="transparent"
                            speed="1.5"
                            style={{
                                width: `${180}px`,
                                height: `${180}px`,
                            }}
                            autoplay
                            loop
                        ></lottie-player>
                        <div className={styles.processTitle}>
                            Processing Data
                        </div>
                        <div className={styles.processTitle_subText}>
                            Please wait while we are analysing your search
                            console data {activeCountry?.label && "for"}
                        </div>
                        {activeCountry?.label && (
                            <div className={styles.processTitle_country}>
                                {activeCountry?.label}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={styles.insightStats}>
                <InsightStatCard
                    title="Traffic"
                    value={monthlyInsights?.clicks}
                    change={monthlyInsights?.delta_clicks}
                    isShimmer={isShimmer}
                    tooltipText={
                        "Web traffic refers to the number of clicks received from Google Search engine."
                    }
                />
                <InsightStatCard
                    title="Impressions"
                    value={monthlyInsights?.impressions}
                    change={monthlyInsights?.delta_impressions}
                    isShimmer={isShimmer}
                    tooltipText={
                        "Impressions refer to the number of times a content, such as a web page is displayed to users."
                    }
                />
                <InsightStatCard
                    title="Avg. Position"
                    value={monthlyInsights?.position}
                    change={monthlyInsights?.delta_position}
                    isShimmer={isShimmer}
                    tooltipText={
                        "It is a website's weighted average ranking in search engine results pages (SERPs) for all pages or queries."
                    }
                />
                <InsightStatCard
                    title="CTR"
                    value={monthlyInsights?.ctr}
                    change={monthlyInsights?.delta_ctr}
                    isShimmer={isShimmer}
                    tooltipText={
                        "It measures the number of clicks received in comparison to the number of people who viewed it."
                    }
                />
            </div>

            <div className={`${styles.gicStatsRow4}`}>
                <div className={styles.gicStatsRow4_layout}>
                    <TopGainsTable
                        tableHeading="Top Gainers"
                        page={top_movers_losers_pages?.topMovers}
                        keyword={top_movers_losers_keywords?.topMovers}
                        isPage={isPagePerformance}
                        isShimmer={isShimmer}
                        tooltip={
                            "Find out which pages made the biggest leaps last month."
                        }
                    />
                    <TopGainsTable
                        tableHeading="Top Losers"
                        page={top_movers_losers_pages?.topLosers}
                        keyword={top_movers_losers_keywords?.topLosers}
                        isLoser={true}
                        isPage={isPagePerformance}
                        isShimmer={isShimmer}
                        tooltip={
                            "Find top underperforming pages month-to-month"
                        }
                    />
                </div>
            </div>
            <div className={`${styles.gicStatsRow3}`}>
                {/* Top Keywords/Pages */}
                <TopPagesKeywordsTable
                    tableTitle={"Top Keywords"}
                    data={top_keywords_info}
                    tooltip={{
                        isShow: true,
                        text: "These keywords drive the highest traffic to the website.",
                    }}
                    isShimmer={isShimmer}
                />
                <TopPagesKeywordsTable
                    tableTitle={"Top Pages"}
                    data={top_pages_info}
                    tooltip={{
                        isShow: true,
                        text: "These pages drive the highest traffic to the website.",
                    }}
                    isShimmer={isShimmer}
                />
            </div>
            <div className={`${styles.gicStatsRow6}`}>
                {/* New/Lost pages */}
                <PagesTable
                    tableTitle={"New Pages"}
                    isLost={true}
                    data={new_pages_info}
                    tooltip={{
                        isShow: true,
                        text: "These web pages were previously absent from search engine rankings but have now gained visibility.",
                    }}
                    isShimmer={isShimmer}
                />
                <PagesTable
                    tableTitle={"Lost Impressions"}
                    isLost={true}
                    data={lost_pages_info}
                    tooltip={{
                        isShow: true,
                        text: "These pages received impressions in the last to last month but have had no engagement within the past month",
                    }}
                    isRankRow={false}
                    isShimmer={isShimmer}
                />
            </div>
            {isShimmer ? (
                <GraphRowShimmer />
            ) : (
                <div className={`${styles.gicStatsRow1}`}>
                    {/* Graph */}
                    <div
                        className={styles.countryDisBox}
                        onMouseEnter={() => {
                            if (!eventTriggeredDD) {
                                taTrackInsightSection({
                                    sectionName: "Device Distribution",
                                });
                                setEventTriggeredDD(true);
                            }
                        }}
                    >
                        <div className={styles.boxTop}>
                            <div className={styles.boxTitle}>
                                Device Distribution
                            </div>
                        </div>
                        <div className={styles.countryDisBox_PanelContainer}>
                            <div className={styles.graphBox1}>
                                {_deviceDistribution.length > 0 ? (
                                    <SimpleDonutChart
                                        data={_deviceDistribution}
                                        labels={_deviceDistributionLabels}
                                        customLegends={CUSTOM_LEGENDS}
                                    />
                                ) : (
                                    <NoGraphData text={"Statistics"} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.positionDisBox}
                        onMouseEnter={() => {
                            if (!eventTriggeredPD) {
                                taTrackInsightSection({
                                    sectionName: "Position Distribution",
                                });
                                setEventTriggeredPD(true);
                            }
                        }}
                    >
                        <div className={styles.boxTop}>
                            <div className={styles.positionDisBox_meta}>
                                <div className={styles.boxTitle}>
                                    Position Distribution
                                </div>

                                <FilterGraphData
                                    filterOptions={FilterOptions}
                                    selectedFilter={positionFilterBy}
                                    handleFilter={setPositionFilterBy}
                                />
                            </div>

                            <div className={styles.positionDisBox_stats}>
                                <div className={styles.ttlKeywords}>
                                    Total Keywords:{" "}
                                    {totalKeywordCount?.toLocaleString()}
                                </div>
                                {delta_keywords_count &&
                                delta_keywords_count.hasOwnProperty(
                                    positionFilterBy.key,
                                ) ? (
                                    <div
                                        className={`${styles.delta} ${
                                            delta_keywords_count[
                                                positionFilterBy?.key as keyof typeof delta_keywords_count
                                            ] < 0
                                                ? styles.dec
                                                : styles.inc
                                        }`}
                                    >
                                        <SVGIcon
                                            src="/New UI/SVG/arrowUp.svg"
                                            size={11}
                                            style={
                                                delta_keywords_count[
                                                    positionFilterBy?.key as keyof typeof delta_keywords_count
                                                ] < 0
                                                    ? {
                                                          transform:
                                                              "rotate(90deg)",
                                                      }
                                                    : {}
                                            }
                                        />
                                        {Math.abs(
                                            delta_keywords_count[
                                                positionFilterBy?.key as keyof typeof delta_keywords_count
                                            ],
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        {_positionsDistribution?.data?.length > 0 ? (
                            <>
                                <div className={styles.graphBox_Position}>
                                    <BarChartHorizontal
                                        data={_positionsDistribution?.data}
                                        label={[
                                            "1-3",
                                            "4-10",
                                            "11-20",
                                            "21-50",
                                            "51-100",
                                        ]}
                                        min={0}
                                        max={_positionsDistribution?.max}
                                        isHorizontal={false}
                                    />
                                </div>
                                <div className={styles.bottomLabel}>
                                    Position Count
                                </div>
                            </>
                        ) : (
                            <NoGraphData text={"Statistics"} />
                        )}
                    </div>
                    <div
                        className={styles.ctrBox}
                        onMouseEnter={() => {
                            if (!eventTriggeredCtr) {
                                taTrackInsightSection({
                                    sectionName: "Click Through Rate",
                                });
                                setEventTriggeredCtr(true);
                            }
                        }}
                    >
                        <div className={styles.boxTop}>
                            <div className={styles.boxTitle}>
                                Click Through Rate
                            </div>
                            <FilterGraphData
                                filterOptions={FilterOptions}
                                selectedFilter={ctrFilterBy}
                                handleFilter={setCtrFilterBy}
                            />
                        </div>
                        {_ctrGraphData?.data?.length > 0 ? (
                            <>
                                <div className={styles.graphBox_ctr}>
                                    <LineChart
                                        data1={_ctrGraphData?.data}
                                        data2={[]}
                                        yType={"%"}
                                        customTooltip={true}
                                        label={[
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                            "8",
                                            "9",
                                            "10",
                                            "11",
                                            "12",
                                            "13",
                                            "14",
                                            "15",
                                            "16",
                                            "17",
                                            "18",
                                            "19",
                                            "20",
                                        ]}
                                        lineHeight={"15px"}
                                        min={0}
                                        max={
                                            _ctrGraphData?.max
                                                ? _ctrGraphData?.max
                                                : 100
                                        }
                                    />
                                </div>
                                <div className={styles.bottomLabel_ctr}>
                                    Search Rank Position
                                </div>
                            </>
                        ) : (
                            <NoGraphData text={"Statistics"} />
                        )}
                    </div>
                </div>
            )}
            <div className={styles.gicBottomRow}></div>
        </div>
    );
};

export default GeneralInsights;
