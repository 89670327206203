import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Faq, MaxPlansFAQ } from "../../../components/subsCards";
import { features } from "../../../components/subsCards/lists";
import { mode2Faqs, otherTestimonials, badges, ratings } from "./constants";
import styles from "./styles.module.css";
import { FiCheck } from "react-icons/fi";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { HiOutlineArrowRight } from "react-icons/hi";
import useCompareTablePlans from "./useCompareTablePlans";
import { BsChevronDown } from "react-icons/bs";
import { PLANS_CATEGORIES } from "../../../constants";
import { parsePrice } from "./usePriceDiscounts";

function WebsiteHtml({
    subs = false,
    plansType = 1,
    cards,
    isDeal = false,
    setPlansType = () => {},
    getDiscount = () => {},
    dealsLimit = 1,
    maxPlans,
}) {
    const { compareTablePlans } = useCompareTablePlans({
        subs,
        plansType,
        dealsLimit,
    });
    const [table, setTable] = useState(false);

    const [plansData, setPlansData] = useState([]);
    const tableRef = useRef();
    const getPriceList = (planType) => {
        return cards
            .sort((card1, card2) => card1.plan_cost - card2.plan_cost)
            .filter(
                (card) =>
                    (planType === card.plan_type &&
                        card.plan_category != PLANS_CATEGORIES.INTER_LINKING &&
                        card.plan_category != PLANS_CATEGORIES.HUMANIZER) ||
                    card.plan_category == PLANS_CATEGORIES.FREE_FOREVER,
            );
    };

    useEffect(() => {
        setPlansData(getPriceList(plansType));
    }, [plansType, cards]);

    useEffect(() => {
        if (table) {
            tableRef.current.parentNode.scrollBy(0, 500);
        }
    }, [table]);

    const { mpTrackCampaignOfferPlanCompared } = useMixpanelHook();

    const top = (
        <>
            <h2 class="heading h5 lower-case bold no-letter-spacing">
                Every Plan Includes
            </h2>
            <div class="included-every-plan-grid">
                <div
                    id="w-node-_6a1e7eca-4b4a-1d18-419d-63b775b4b956-cae47ca1"
                    class="text-box center-align"
                >
                    <img
                        alt="content writing icon"
                        src="https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d8c8c27da01f2415a5ebce_Crusie%20Mode%20Icon.svg"
                        class="icon round hs"
                    />
                    <h3 class="paragraph-small medium">Cruise Mode</h3>
                </div>
                <div
                    id="w-node-_6a1e7eca-4b4a-1d18-419d-63b775b4b95a-cae47ca1"
                    class="text-box center-align relative"
                >
                    <img
                        alt="content grading icon"
                        src="https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d8c8c249fdb3348eaccdf4_SEO%20Score.svg"
                        class="icon round hs"
                    />
                    <h3 class="paragraph-small medium">SEO Score</h3>
                </div>
                <div
                    id="w-node-_6a1e7eca-4b4a-1d18-419d-63b775b4b95f-cae47ca1"
                    class="text-box center-align"
                >
                    <img
                        alt="content research icon"
                        src="https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d8c8c2444302d9818a160a_Competitive%20Research.svg"
                        class="icon round hs"
                    />
                    <h3 class="paragraph-small medium">Competitive Research</h3>
                </div>
                <div
                    id="w-node-_6a1e7eca-4b4a-1d18-419d-63b775b4b965-cae47ca1"
                    class="text-box center-align"
                >
                    <img
                        alt="social listening icon"
                        src="https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d8c8c28b967119414ff62d_Social%20Listening.svg"
                        class="icon round hs"
                    />
                    <h3 class="paragraph-small medium">Social Listening</h3>
                </div>
                <div
                    id="w-node-_6a1e7eca-4b4a-1d18-419d-63b775b4b96b-cae47ca1"
                    class="text-box center-align"
                >
                    <img
                        alt="content brief icon"
                        src="https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d8c8c2f3446976598a22e3_Content%20Brief.svg"
                        class="icon round hs"
                    />
                    <h3 class="paragraph-small medium">Automated Outlines</h3>
                </div>
                <div
                    id="w-node-_6a1e7eca-4b4a-1d18-419d-63b775b4b96f-cae47ca1"
                    class="text-box center-align"
                >
                    <img
                        alt="chrome extension icon"
                        src="https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d8c8c17de2b77f17d25c95_40%2B%20AI%20Templates.svg"
                        class="icon round hs"
                    />
                    <h3 class="paragraph-small medium">40+ AI Templates</h3>
                </div>
            </div>
        </>
    );
    const bottom = (
        <>
            {!maxPlans && (
                <>
                    <div class="comparison-button-container">
                        <div
                            className={styles.comparisonTable}
                            style={{
                                borderRadius: "25px",
                                margin: "auto",
                                marginBottom: "10px",
                            }}
                            id="individual-plan-yearly"
                            rel="me"
                            data-w-id="68165b77-b0d3-e2b5-7d9e-ef78d45559a9"
                            class="button white"
                            onClick={() => {
                                mpTrackCampaignOfferPlanCompared(
                                    !table ? "Opened" : "Closed",
                                );
                                setTable(!table);
                            }}
                        >
                            <div
                                class="button-text-block"
                                style={{
                                    paddingRight: "10px",
                                    fontWeight: "500",
                                    color: isDeal ? "#2360ED" : "#014dc5",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                Compare Plans{" "}
                                <BsChevronDown
                                    style={{
                                        transform: table
                                            ? "rotate(180deg)"
                                            : "",
                                    }}
                                    strokeWidth={1}
                                    fontWeight={700}
                                    fontSize={20}
                                    color={"inherit"}
                                />
                            </div>
                        </div>
                    </div>

                    {table && (
                        <>
                            <div className="pricing-comparison-wrap">
                                <div
                                    className={`comparison-row header ${
                                        subs ? "" : "headerMode2"
                                    }`}
                                >
                                    <div>
                                        <h4 className="comparison-header-title feature">
                                            Feature
                                        </h4>
                                        <div className={styles.plansToggle}>
                                            <div
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Monthly
                                            </div>
                                            {isDeal ? (
                                                <label
                                                    className={styles.switch}
                                                >
                                                    <input
                                                        onChange={(e) =>
                                                            setPlansType(
                                                                e.target.checked
                                                                    ? 2
                                                                    : 1,
                                                            )
                                                        }
                                                        type="checkbox"
                                                        checked={
                                                            plansType == 2
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <span
                                                        className={`${styles.sliderDeal} ${styles.round}`}
                                                    ></span>
                                                </label>
                                            ) : (
                                                <label
                                                    className={styles.switch}
                                                >
                                                    <input
                                                        onChange={(e) =>
                                                            setPlansType(
                                                                e.target.checked
                                                                    ? 2
                                                                    : 1,
                                                            )
                                                        }
                                                        type="checkbox"
                                                        checked={
                                                            plansType == 2
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <span
                                                        className={`${styles.slider} ${styles.round}`}
                                                    ></span>
                                                </label>
                                            )}
                                            <div
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Annual
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22a7-3a27a5ca">
                                        <h4 className="comparison-header-title">
                                            Essential
                                        </h4>
                                        <p style={{ textAlign: "center" }}>
                                            {parsePrice(
                                                plansData[0]?.plan_cost,
                                                plansData[0]?.currency,
                                                plansData[0]?.plan_type,
                                                getDiscount(
                                                    plansData[0]?.plan_type,
                                                ),
                                            )}
                                            /mo
                                        </p>
                                    </div>
                                    <div id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22ae-3a27a5ca">
                                        <h4 className="comparison-header-title">
                                            Growth
                                        </h4>
                                        <p style={{ textAlign: "center" }}>
                                            {parsePrice(
                                                plansData[1]?.plan_cost,
                                                plansData[1]?.currency,
                                                plansData[1]?.plan_type,

                                                getDiscount(
                                                    plansData[1]?.plan_type,
                                                ),
                                            )}
                                            /mo
                                        </p>
                                    </div>
                                    <div id="w-node-e6c21fc5-2bfa-f3b8-d65a-16f5b7fc22b5-3a27a5ca">
                                        <h4 className="comparison-header-title">
                                            Pro
                                        </h4>
                                        <p style={{ textAlign: "center" }}>
                                            {parsePrice(
                                                plansData[
                                                    plansType == 2 ? 3 : 2
                                                ]?.plan_cost,
                                                plansData[
                                                    plansType == 2 ? 3 : 2
                                                ]?.currency,
                                                plansData[
                                                    plansType == 2 ? 3 : 2
                                                ]?.plan_type,

                                                getDiscount(
                                                    plansData[3]?.plan_type,
                                                ),
                                            )}
                                            /mo
                                        </p>
                                    </div>
                                </div>
                                <div
                                    ref={tableRef}
                                    style={{
                                        margin: "auto",
                                        backgroundColor: "#fff",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: compareTablePlans,
                                    }}
                                ></div>
                            </div>
                        </>
                    )}
                </>
            )}

            <div
                style={{
                    padding: subs ? "10px 60px" : "",
                    borderRadius: "4.3px",
                }}
            >
                <h2
                    class="heading h2 center"
                    style={{
                        color: subs ? "#101113" : "black",
                        fontSize: subs ? "26px" : "1.5em",
                    }}
                >
                    Testimonials
                </h2>
                <div
                    class="grid desk-3 tab-2 mob-1"
                    style={{ padding: subs ? "auto 40px auto 40px" : "none" }}
                >
                    <div class="quote-card">
                        <img
                            src="https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png"
                            loading="lazy"
                            alt=""
                            class="review-from"
                        />
                        <div>
                            <p class="heading h4">
                                "Why You Should Choose Scalenut For Your SEO
                                Long-Form AI Needs"
                            </p>
                            <p class="paragraph">
                                When generating reports it breaks it down in NLP
                                terms (natural language processing), top ten
                                competition, writing themes &amp; Citations from
                                all the competitor's sites. Scalenut reports
                                also provide commonly asked questions from
                                Quora, Reddit, Google's frequently asked
                                questions and provide ai suggestions.Now for the
                                fun stuff!. This is great for setting up the
                                outline for long-form content.
                            </p>
                        </div>
                        <div class="quote-from">
                            <div class="avatar">
                                <img
                                    src="https://storage.googleapis.com/scalenut/webflow assests/62a1dc3cd1e51cf2654102c3_Terencio%20W.png"
                                    loading="lazy"
                                    data-sizes='[{"max":10000,"size":"100vw"}]'
                                    alt=""
                                    class="avatar-image"
                                />
                            </div>
                            <div class="quote-name">
                                <p class="no-margin bold">Terencio W</p>
                                <div>Founder</div>
                            </div>
                        </div>
                    </div>
                    <div class="quote-card">
                        <img
                            src="https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png"
                            loading="lazy"
                            alt=""
                            class="review-from"
                        />
                        <div>
                            <p class="heading h4">
                                "A complete ecosystem for content creators"
                            </p>
                            <p class="paragraph">
                                Scalenut's SEO assistant is the differentiator
                                from other AI writers available in the market.
                                Scalenut doesn't just help in content
                                generation, but also the suitability of content
                                with regards to SEO.
                            </p>
                        </div>
                        <div class="quote-from">
                            <div class="avatar">
                                <img
                                    src="https://storage.googleapis.com/scalenut/webflow assests/62a1dc3ab5c6a6231ae97b58_Rahul%20M.png"
                                    loading="lazy"
                                    data-sizes='[{"max":10000,"size":"100vw"}]'
                                    alt=""
                                    class="avatar-image"
                                />
                            </div>
                            <div class="quote-name">
                                <p class="no-margin bold">Rahul M</p>
                                <div>Co-Founder</div>
                            </div>
                        </div>
                    </div>
                    <div class="quote-card">
                        <img
                            src="https://storage.googleapis.com/scalenut/webflow assests/61c42fb7f1194927e69793fa_trustpilot.png"
                            loading="lazy"
                            alt=""
                            class="review-from"
                        />
                        <div>
                            <p class="heading h4">
                                “The best AI-writer currently on the market”
                            </p>
                            <p class="paragraph">
                                I've tried a few other AI writers, but ScaleNut
                                stands out for quality. The text generated is
                                profound and factual. I use it mainly for its
                                "quora answer" module and its long-form SEO
                                assistant. I'm now able to create long-form
                                content in a few minutes.
                            </p>
                        </div>
                        <div class="quote-from">
                            <div class="avatar">
                                <img
                                    src="https://storage.googleapis.com/scalenut/webflow assests/62a1dc3c714b1ed1ce6cc780_Benedict%20T.png"
                                    loading="lazy"
                                    data-sizes='[{"max":10000,"size":"100vw"}]'
                                    alt=""
                                    class="avatar-image"
                                />
                            </div>
                            <div class="quote-name">
                                <p class="no-margin bold">Benedict T</p>
                                <div>SEO Consultant</div>
                            </div>
                        </div>
                    </div>
                    {!subs
                        ? otherTestimonials.map((testimony) => {
                              return (
                                  <div class="quote-card">
                                      <img
                                          src={testimony.src}
                                          loading="lazy"
                                          alt=""
                                          class="review-from"
                                      />
                                      <div>
                                          <p class="heading h4">
                                              “{testimony.mainText}”
                                          </p>
                                          <p class="paragraph">
                                              {testimony.subText}
                                          </p>
                                      </div>
                                      <div class="quote-from">
                                          {testimony.avatar.length ? (
                                              <div class="avatar">
                                                  <img
                                                      src={testimony.avatar}
                                                      loading="lazy"
                                                      data-sizes='[{"max":10000,"size":"100vw"}]'
                                                      alt=""
                                                      class="avatar-image"
                                                  />
                                              </div>
                                          ) : (
                                              ""
                                          )}
                                          <div class="quote-name">
                                              <p class="no-margin bold">
                                                  {testimony.name}
                                              </p>
                                              {testimony.post.length ? (
                                                  <div>{testimony.post}</div>
                                              ) : (
                                                  ""
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              );
                          })
                        : ""}
                </div>
            </div>

            {!subs && (
                <>
                    {" "}
                    <div className={styles.headerSection1}>
                        {/* Loved by <span className={styles.mark}>20000+</span> users across{" "}
                <span className={styles.mark}>50+</span> countries */}
                        Users love us on G2.{" "}
                        <span style={{ color: "#ff0314" }}>Find out why!</span>
                    </div>
                    <div className={styles.headerSection1_child}>
                        <div className={styles.industryRatings_Wrapper}>
                            {ratings.map((item) => {
                                return (
                                    <div
                                        className={styles.industryRatings_Item}
                                    >
                                        <div
                                            className={
                                                styles.industryRatings_left
                                            }
                                        >
                                            <img
                                                style={{ margin: "0 5px" }}
                                                height={85}
                                                width={85}
                                                src={item.src}
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.industryRatings_right
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.industryRatings_tag
                                                }
                                            >
                                                {item.tag}
                                            </div>
                                            <div
                                                className={
                                                    styles.industryRatings_avg
                                                }
                                            >
                                                Industry Average:
                                                {" " + item.avg}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.headerSection2}>
                            <div className={styles.gradeCards}>
                                {badges.map((badge) => (
                                    <img
                                        style={{ margin: "0 5px", zoom: "1.3" }}
                                        height={80}
                                        width={60}
                                        src={badge}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>{" "}
                </>
            )}
            {!subs && (
                <div className={styles.specificationContainer}>
                    <div className={styles.spLeft}>
                        <div className={styles.spLeftHeading}>
                            The only
                            <br />
                            platform with
                        </div>
                        <div className={styles.spLeftBottom}>
                            <div className={styles.spec}>
                                <FiCheck color="#fff" size={25} />
                                Entire SEO content lifecycle
                            </div>
                            <div className={styles.spec}>
                                <FiCheck color="#fff" size={25} />
                                AI that reads latest information
                            </div>
                            <div className={styles.spec}>
                                <FiCheck size={25} />
                                World class customer support
                            </div>
                        </div>
                    </div>
                    <div className={styles.spRight}>
                        {features.map((item, i) => (
                            <div className={styles.feature} key={i}>
                                <div className={styles.feature__imgContainer}>
                                    <img
                                        src={item.imgSrc}
                                        alt=""
                                        class="icon tab"
                                        style={{
                                            width: "100% ",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                                <h3 className={styles.featureText}>
                                    {item.text}{" "}
                                </h3>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {!subs && !maxPlans && (
                <div>
                    <div className={styles.faqContainer}>
                        <div className={styles.faqHeading}>Product FAQ’s</div>
                        <div className={styles.fqas}>
                            {mode2Faqs.map((item, i) => (
                                <Faq item={item} key={i} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {!subs && maxPlans && (
                <>
                    <div className={styles.faqHeading}>
                        Frequently Asked Questions
                    </div>
                    <MaxPlansFAQ />
                </>
            )}
        </>
    );
    return {
        top,
        bottom,
    };
}

export default WebsiteHtml;
