import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import Store from "./store/Store";
import { Provider } from "react-redux";
import { mixPanelKey, PROD, SENTRY_KEY } from "./utility/config";
import * as Sentry from "@sentry/react";
import { version } from "./version.json";
import mixpanel from "mixpanel-browser";
import { version as APP_VERSION } from "./../package.json";
import UpdatePlatform from "./componentsV2/organisms/PlatformUpdate/UpdatePlaform";
import "./loadThirdPartyScripts";
import "./componentsV2/Pages/AIHumanizer/customShowdown";

window.global ||= window;

window.setImmediate = function (callback) {
    setTimeout(callback, 0);
};

console.log(version, "version");

if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            if (console) {
                console.warn(
                    "Cannot remove a child from a different parent",
                    child,
                    this,
                );
            }
            return child;
        }
        return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.warn(
                    "Cannot insert before a reference node from a different parent",
                    referenceNode,
                    this,
                );
            }
            return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
    };
}

if (process.env.NODE_ENV != "development")
    Sentry.init({
        dsn: SENTRY_KEY,
        release: `Scalenut-FE@${APP_VERSION}`,
        initialScope: {
            tags: { version: version },
        },
    });

mixpanel.init(mixPanelKey);

const rootDiv = document.getElementById("root");

window.addEventListener("resize", () => {
    rootDiv.style.height = window.innerHeight + "px";
});
rootDiv.style.height = window.innerHeight + "px";

const root = createRoot(rootDiv);

root.render(
    <Provider store={Store}>
        <App />
        <UpdatePlatform />
    </Provider>,
);

if (navigator.serviceWorker)
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            if (
                registration?.active?.scriptURL ===
                window.location.origin + "/service-worker.js"
            )
                registration.unregister();
        }
    });

window.heavyDutyWorker = new Worker("/worker.js");
window.heavyDutyWorkerAI = new Worker("/AIOutputWorker.js");

window.metaTagViewport = document.createElement("meta");
window.metaTagViewport.setAttribute("name", "viewport");
