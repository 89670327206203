import React, { useState, useEffect } from 'react';
import { url } from '../../utility/config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CircularProgress, TextField } from '@material-ui/core';
import styles from './superAdmin.module.css';
import EmailSuggestion from './EmailSuggestion';

export default function EnterpriseForm({ setActiveAction }) {
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [message, setMessage] = useState('Recharge');
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [userNotRegisteredError, setUserNotRegisteredError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [random, setRandom] = useState(2);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return;

    setLoading(true);
    axios({
      method: 'post',
      url: `${url}/api/user/checkEmail`,
      data: { email },
    }).then((res) => {
      if (res.status === 201) {
        setLoading(false);
        setUserNotRegisteredError(true);
        setTimeout(() => setUserNotRegisteredError(false), 5000);
      } else if (res.status === 200) {
      
        let data={
          userEmail: email,
         
        }
        if(message=="Promo"){
          data.promotional_credits = rechargeAmount
          
        }else{
         
          data.recharge_amount= rechargeAmount
        }

        axios({
          method: 'post',
          url: `${url}/api/user/walletRechargeAdmin`,
          headers: {
            Authorization: token,
          },
          data:data ,
        })
          .then((res) => {
            setSuccessMessage(true);
            setTimeout(() => setSuccessMessage(false), 5000);
            setEmail('');
            setUserDetail({});
            setUserId(null);
            setRandom(Math.random());
            setRechargeAmount(0);
            setMessage('Recharge');
            setLoading(false);
          })
          .catch((err) => {
            setError(err);
            setLoading(false);
            setTimeout(() => setError(false), 5000);
          });
      }
    });
  };

  useEffect(() => {
    if (userId) {
      axios({
        method: 'get',
        url: `${url}/api/project/priority/getUserDetail?userId=${userId}`,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setUserDetail(res.data.data);
        })
        .catch(console.error);
    }
  }, [userId]);

  return (
    <>
      <p
        style={{
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer',
          display: 'inline-block',
        }}
        onClick={() => setActiveAction(null)}
      >
        Go back
      </p>
      <form>
        <EmailSuggestion
          email={email}
          setEmail={setEmail}
          setUserId={setUserId}
          uidReq={true}
          random={random}
        />
        <br />
        <div className={styles.userDataBox}>
          <h2>User Data</h2>
          <p>
            <span className={styles.medHeading}>Currency: </span>
            {userDetail.currency ? userDetail.currency : 'Select email first'}
          </p>
          <p>
            <span className={styles.medHeading}>Current Balance: </span>
            {userDetail.current_balance || userDetail.current_balance === 0
              ? userDetail.current_balance
              : 'Select email first'}
          </p>
        </div>
        <div className={styles.marginBox}>
          <label htmlFor='message'>Type: </label>
          <select
            name='message'
            id='message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          > 
            
            <option value='Recharge'>Recharge</option>
            <option value='Promo'>Promotional Credits</option>
            <option value='Refund'>Refund</option>
            
          </select>
        </div>
        <br />
        <div className={styles.marginBox}>
          <label htmlFor='rechargeAmount'>{message} Amount: </label>
          <input
            value={rechargeAmount}
            onChange={(e) => setRechargeAmount(e.target.value)}
            id='rechargeAmount'
            name='rechargeAmount'
            type='number'
            required
            className={styles.inputField}
          />
        </div>
        <br />
        {loading ? (
          <CircularProgress />
        ) : (
          <button type='submit' onClick={handleSubmit}>
           Confirm
          </button>
        )}
      </form>
      {userNotRegisteredError && <p>User not registered</p>}
      {successMessage && <p>User wallet recharged successfully.</p>}
      {error && <p>Error in recharging</p>}
    </>
  );
}
