import React from "react";
import SVGIcon from "../../atoms/SVGIcon";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import styles from "./AIHumanizer.module.css";
import Modal from "../../atoms/Modal/Modal";
import Button from "../../atoms/Button/Button";

function UpgradeModal({ setUpgradeModal }) {
    const goTo = useNavigation();
    return (
        <Modal setModal={() => setUpgradeModal(false)}>
            <div className={styles.seoModal}>
                <span className={styles.seoModalCross}>
                    <SVGIcon
                        onClick={() => setUpgradeModal(false)}
                        src="/New UI/SVG/cross.svg"
                    ></SVGIcon>
                </span>
                <h3>
                    This Feature is not available on your current plan. Please
                    upgrade to use this feature.
                </h3>
                <Button
                    width="40%"
                    handler={() => {
                        goTo("BILLING_PLANS");
                    }}
                    text="Upgrade Plan"
                    style={{
                        fontSize: 14,
                    }}
                ></Button>
            </div>
        </Modal>
    );
}

export default UpgradeModal;
