import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ResearchGenerate.module.css";
import { HiArrowNarrowRight, HiCheckCircle } from "react-icons/hi";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Spacer from "../../../atoms/Spacer/Spacer";
import TextArea from "../../../atoms/TextArea/TextArea";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import { GENERATE_CONTENT } from "../../../api.json";
import GenerateResults from "../../Generate/GenerateResults/GenerateResults";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import SVGIcon from "../../../atoms/SVGIcon";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ClickAwayListener,
    makeStyles,
} from "@material-ui/core";
import { SMART_EDITOR } from "../../TextEditor/editorConstants";
import { isMobile } from "../../../../utility/helperFun";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const useStyles = makeStyles({
    root: {
        flexDirection: "row-reverse",
        padding: 0,
        margin: "0 !important",
        boxShadow: "none",
        minHeight: 36,
    },

    accRoot: {
        boxShadow: "none",
    },
    accSummRoot: {
        padding: 0,
        minHeight: "0 !important",
    },
    accSummContent: {
        margin: "0 !important",
    },
    expandedIcon: {
        padding: 0,
    },
    content: {
        margin: "0 !important",
    },
    expanded: {
        minHeight: "40px !important",
    },
});

const useStyles1 = makeStyles({
    root: {
        padding: "0 16px !important",
    },
});

const btnStyles = {
    all: {
        border: "solid 1px #1f4cbe",
        backgroundColor: "rgba(31, 76, 190, 0.1)",
        color: "rgb(31, 76, 190)",
    },
    favorites: {
        color: "rgba(226, 60, 60, 1)",
        backgroundColor: "rgba(226, 60, 60, 0.1)",
    },
    popular: {
        color: "rgba(246, 193, 71, 1)",
        backgroundColor: "rgba(246, 193, 71, 0.1)",
    },
    common: {
        padding: "6px 34px 6px 35px",
        height: 30,
        borderRadius: 3,
        fontWeight: 500,
        margin: "2px",
        flexGrow: 1,
    },
};
let handleCaseChange = () => {};
const AddInSetAndRender = ({ data }) => {
    const {
        id,
        name,
        set,
        variants,
        SetOpenStateAll,
        openStateAll,
        filterTerm,
    } = data;
    const ref = useRef();
    const calcInitial = () => {
        if (window.location.search.includes("cat")) {
            if (window.location.search.includes(encodeURI(name))) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    const [expand, setExpand] = useState(calcInitial());

    useEffect(() => {
        if (!openStateAll.initial && openStateAll.expand) {
            setExpand(true);
        } else if (!openStateAll.initial && openStateAll.collapse) {
            setExpand(false);
        }
    }, [openStateAll]);

    useEffect(() => {
        if (expand) {
        }
    }, [expand]);

    useEffect(() => {
        if (expand && ref.current) {
            if (window.location.search.includes("cat")) {
                if (window.location.search.includes(encodeURI(name))) {
                    setTimeout(() => {
                        ref?.current?.scrollIntoView();
                    }, 500);
                }
            }
        }
    }, [ref]);

    const classes = useStyles();
    const classes1 = useStyles1();
    if (variants.length == 0) {
        return null;
    }

    return (
        <Accordion key={id} expanded={expand} classes={classes}>
            <AccordionSummary
                classes={classes}
                onClick={() => setExpand(!expand)}
            >
                <div
                    style={{
                        fontWeight: 500,
                        color: "#263238",
                    }}
                    className={styles.categoryName}
                >
                    {expand ? (
                        <IoMdArrowDropdown size={20} />
                    ) : (
                        <IoMdArrowDropright size={20} />
                    )}
                    {name} &nbsp;{" "}
                    <span style={{ color: "gray" }}>({variants.length})</span>
                    <div
                        ref={ref}
                        style={{ position: "absolute", top: -110 }}
                    ></div>
                </div>
            </AccordionSummary>

            <AccordionDetails classes={classes1} style={{ display: "block" }}>
                {variants.map((variant) => {
                    return <VariantItem key={variant.id} item={variant} />;
                })}
            </AccordionDetails>
        </Accordion>
    );
};

function VariantsCards({
    variants,
    filterTerm = "",
    SetOpenStateAll,
    openStateAll,
}) {
    const cats = Object.keys(variants);

    return cats
        .filter((item) => !(item == "all" || item == "SEO"))
        .map((cat) => {
            return (
                <div key={cat}>
                    <AddInSetAndRender
                        key={cat}
                        data={{
                            id: cat,
                            name: cat,
                            variants: variants[cat].filter((item) =>
                                isInFilter(item, filterTerm),
                            ),
                            SetOpenStateAll,
                            openStateAll,
                            filterTerm,
                        }}
                    />
                </div>
            );
        });
}

const isInFilter = (item, value) => {
    if (value == "") return true;
    if (
        item.name.trim().toLowerCase().includes(value.trim().toLowerCase()) ||
        item.cat_name.trim().toLowerCase().includes(value.trim().toLowerCase())
    )
        return true;
    return false;
};

const VariantItem = ({ style, item, imgProps }) => {
    return (
        <div
            onClick={() => handleCaseChange(item)}
            className={styles.itemMainContainer}
        >
            <img
                src={item.icons}
                alt={""}
                height="30px"
                width="30px"
                {...imgProps}
            />

            <div style={{ ...style }} className={styles.itemName}>
                {item.name}
            </div>
        </div>
    );
};

const genTicks = [
    "All outputs are generated via AI",
    "It is encouraged to have human intervention before using the outputs elsewhere",
    "The tools ahead are aimed at improving efficiency of your work and not replace human intervention at all",
    "We await your feedback, use our chat option to share any feedback/suggestion/constructive criticism",
    "Most importantly, have fun Generating!",
];

export default function ResearchGenerate({
    setValue,
    setTemplateButton,
    reportInfo,
    attachedTo,
    generateCases,
}) {
    const [showDropdown, setShowDropdown] = useState(true);
    const [openStateAll, SetOpenStateAll] = useState({
        expand: true,
        collapse: false,
        initial: true,
    });
    const [generateLoading, setGenerateLoading] = useState(false);
    const [sensitiveContent, setSensitiveContent] = useState(false);
    const [CharLimitError, setCharLimitError] = useState(false);
    const [responseText, setResponseText] = useState([]);
    const [payload, setPayload] = useState({});
    const [filterTerm, setFilterTerm] = useState("");
    const [selectedCase, setSelectedCase] = useState(null);
    const { postData } = useHelperFunctions();
    const scrollRef = useRef();
    const inRef = useRef();
    const firstTextBoxRef = useRef();
    const [errInField, setErrField] = useState(10);
    const [sampleBrand, setSampleBrand] = useState(true);
    const [errList, setErrList] = useState([]);
    const [generateMeta, setGenerateMeta] = useState(false);
    const SESSION_ID = useRef("");
    const { mpTrackAiGenerate, mpTrackAiRegenerate } = useMixpanelHook();

    useEffect(() => {
        setTimeout(() => {
            if (!isMobile()) inRef?.current?.focus();
            setErrField(0);
        }, 500);
    }, [inRef, firstTextBoxRef]);

    useEffect(() => {
        const generateHandler = (e) => {
            if (e.ctrlKey && e.key.toLowerCase() == "g" && !e.shiftKey) {
                e.preventDefault();
                generate(responseText.length > 0 ? true : false);
            }
            if (e.metaKey && e.key.toLowerCase() == "g" && !e.shiftKey) {
                e.preventDefault();
                generate(responseText.length > 0 ? true : false);
            }
        };
        document.addEventListener("keydown", generateHandler);

        return () => document.removeEventListener("keydown", generateHandler);
    }, [payload, responseText]);

    handleCaseChange = (useCase) => {
        setSelectedCase(useCase);
        setSampleBrand(true);
        setShowDropdown(false);
    };

    useEffect(() => {
        if (reportInfo.title) payload.topic = reportInfo.title;
        setPayload((payload) => {
            return {
                topic: payload.topic ? payload.topic : "",
            };
        });
        setResponseText([]);
        setErrList([]);
        setFilterTerm("");

        if (!selectedCase?.useCase) {
            setErrField(10);
        }
    }, [selectedCase?.useCase]);

    useEffect(() => {
        if (filterTerm)
            SetOpenStateAll({
                collapse: false,
                expand: true,
            });
    }, [filterTerm]);

    useEffect(() => {
        if (window.location.search.includes("useCase")) {
            const paramUseCaseId = window.location.search?.split("=")[1];
            if (paramUseCaseId) {
                const useCase = generateCases?.all?.find(
                    (item) => item.useCase == paramUseCaseId,
                );
                if (useCase) handleCaseChange(useCase);
            }
        }

        return () => {
            setSelectedCase(null);
        };
    }, [generateCases]);
    function checkCharaterLimits(params) {
        setErrList([]);
        const limit = 5;
        let hasError = false;
        let index = 0;
        let temp = 10;
        let tempErrList = [];
        setErrField(10);

        for (let edx of JSON.parse(selectedCase.fields)) {
            const key = edx.key;
            const label = edx.label;
            const isReq = edx.isRequired;
            const minLength = edx.minlength; //wrong speling in backend
            const maxLength = edx.maxLength;
            /**
       * check for the not required but length checks
       * ||
        (!isReq &&
          params[label] &&
          (params[label].length > maxLength ||
            params[label].length < minLength))
       * 
       */ let obj = {
                index,
                msg: "",
            };

            console.log("indexErr ps and index", index, errInField);

            let tempLable = label.charAt(0).toUpperCase() + label.slice(1);
            if (isReq && !params[key ? key : label]) {
                obj.msg = `Please enter ${tempLable}`;
                hasError = true;
                temp = temp > index ? index : temp;
            } else if (isReq && params[key ? key : label].length < minLength) {
                obj.msg = `Please provide more content for our AI to generate quality outputs`;
                hasError = true;
                temp = temp > index ? index : temp;
            }
            tempErrList.push(obj);

            // if (
            //   (isReq && !params[key ? key : label]) ||
            //   (isReq &&
            //     (params[key ? key : label].length > maxLength ||
            //       params[key ? key : label].length < minLength))
            // ) {
            //   setCharLimitError(
            //     `Please provide more content for our AI to generate quality outputs`
            //   );
            // return true;
            // }
            index++;
        }
        setTimeout(() => {
            setErrField(temp);
        }, 200);
        setErrList(tempErrList);
        return hasError;
    }

    async function generate(more = false) {
        try {
            if (checkCharaterLimits(payload)) return false;
            setSensitiveContent(false);
            setGenerateLoading(true);

            if (!more) {
                setResponseText([]);
            }

            const res = await postData({
                url: GENERATE_CONTENT,
                payload: {
                    ...payload,
                    useCase:
                        selectedCase.useCase == 2 && generateMeta
                            ? 256
                            : selectedCase.useCase,
                    report_id:
                        attachedTo == SMART_EDITOR ? -2 : reportInfo.report_id,

                    ...(more ? { session: SESSION_ID.current } : {}),
                },
            });

            if (res.data.type === 2 || !res.data.output) {
                setSensitiveContent(true);
            } else {
                if (more) {
                    setResponseText([
                        ...res.data.output.map((item) => ({
                            ...item,
                            new: true,
                        })),
                        ...responseText.map((item) => ({
                            ...item,
                            new: false,
                        })),
                    ]);
                    mpTrackAiRegenerate({ ...selectedCase });
                } else {
                    SESSION_ID.current = res.data.session;
                    setResponseText(res.data.output);
                    // setTimeout(() => {
                    //   scrollButton.current.click();
                    // }, 100);
                    mpTrackAiGenerate({ ...selectedCase });
                }
                if (res.data.output.length > 0) {
                    scrollRef.current.scrollIntoView(true);
                }
            }
            setGenerateLoading(false);
        } catch (error) {
            setGenerateLoading(false);

            console.log(error);
        }
    }
    const fillWithSample = () => {
        const obj = {};
        JSON.parse(selectedCase?.fields).map((item, i) => {
            // payload [item.key ? item.key : item.label]
            obj[item.key ? item.key : item.label] = item.sample;
        });
        setPayload(obj);
        setSampleBrand(false);
    };
    return (
        <div className={styles.researchGenerate}>
            <>
                {/* <p className={styles.dropdownLabel}>SELECT TYPE</p> */}
                {attachedTo == SMART_EDITOR && (
                    <h1
                        style={{
                            padding: "10px 20px",
                            fontSize: selectedCase ? 14 : 18,
                            fontWeight: 500,
                            color: "var(--secondary-color)",
                            cursor: selectedCase ? "pointer" : "default",
                            zIndex: 1,
                            background: "white",
                            top: 0,
                            margin: 0,
                            position: selectedCase ? "sticky" : "unset",
                        }}
                        className={selectedCase ? styles.backToAll : ""}
                        onClick={() => {
                            setSelectedCase(null);
                        }}
                    >
                        {selectedCase
                            ? "< Back to All AI Templates"
                            : "AI Templates"}
                    </h1>
                )}

                {selectedCase && (
                    <>
                        <div
                            style={attachedTo == SMART_EDITOR ? {} : { top: 0 }}
                            className={styles.selectedCase}
                        >
                            <VariantItem
                                style={{ fontSize: 16, fontWeight: 500 }}
                                imgProps={{ height: 34, width: 34 }}
                                item={selectedCase}
                            />

                            {attachedTo != SMART_EDITOR && (
                                <Button
                                    text="All Templates"
                                    handler={() => setSelectedCase(null)}
                                    width={140}
                                    height={30}
                                    iconReverse
                                    Icon={() => (
                                        <SVGIcon
                                            color={"gray"}
                                            className={styles.crossIcon}
                                            size={16}
                                            src={"/New UI/SVG/backArrow.svg"}
                                        />
                                    )}
                                    style={{
                                        color: "var(--secondary-color)",
                                        backgroundColor: "white",
                                        border: "none",
                                        fontSize: 12,
                                        minWidth: 110,
                                    }}
                                />
                            )}
                        </div>
                        <div className={styles.fieldsContainer}>
                            <Spacer spacing={10} />
                            {JSON.parse(selectedCase?.fields).map((item, i) => (
                                <>
                                    <TextArea
                                        errInField={errInField == i}
                                        errList={errList}
                                        index={i}
                                        key={item.label}
                                        labelSize={14}
                                        autoFocus={!isMobile() && i == 0}
                                        label={(() => {
                                            let label = "";
                                            item?.label
                                                ?.split("_")
                                                .forEach((e) => {
                                                    label = label
                                                        ? label + " " + e
                                                        : e;
                                                });
                                            return label;
                                        })()}
                                        placeholder={item.placeholder}
                                        rowsMin={item.maxLength <= 100 ? 2 : 5}
                                        minLength={item.minlength}
                                        maxLength={item.maxLength}
                                        counter={true}
                                        props={{
                                            disabled: generateLoading,
                                            value: payload[
                                                item.key ? item.key : item.label
                                            ]
                                                ? payload[
                                                      item.key
                                                          ? item.key
                                                          : item.label
                                                  ]
                                                : "",
                                            ...(i == 0
                                                ? { ref: firstTextBoxRef }
                                                : {}),
                                            onChange: (e) => {
                                                setCharLimitError("");
                                                setErrList(
                                                    errList.filter(
                                                        (item, index) => {
                                                            if (index == i) {
                                                                item.msg = "";
                                                                return item;
                                                            } else return item;
                                                        },
                                                    ),
                                                );
                                                const value = e.target.value;
                                                if (!value) {
                                                    setPayload((ps) => {
                                                        delete ps[
                                                            item.key
                                                                ? item.key
                                                                : item.label
                                                        ];
                                                        return {
                                                            ...ps,
                                                        };
                                                    });
                                                } else
                                                    setPayload((ps) => {
                                                        return {
                                                            ...ps,
                                                            [item.key
                                                                ? item.key
                                                                : item.label]:
                                                                value,
                                                        };
                                                    });
                                            },
                                            required: item.isRequired,
                                        }}
                                        errMsg={
                                            errList.length
                                                ? errList[i]?.index == i
                                                    ? errList[i]?.msg
                                                    : ""
                                                : ""
                                        }
                                        required={item.isRequired}
                                        noItalics
                                    />
                                    {i == 0 && item.sample && sampleBrand && (
                                        <div className={styles.tryFor}>
                                            Try for{" "}
                                            <span
                                                className={styles.sampleBrand}
                                                onClick={() =>
                                                    fillWithSample(item)
                                                }
                                            >
                                                {item.sample}
                                            </span>
                                        </div>
                                    )}
                                    <Spacer spacing={10} />
                                </>
                            ))}

                            {CharLimitError && (
                                <span className={styles.charLimitError}>
                                    {CharLimitError}
                                </span>
                            )}
                        </div>
                        {selectedCase.useCase == 2 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginInline: "20px",
                                    marginTop: "10px",
                                }}
                            >
                                <div className={styles.metaGenerate}>
                                    Generate Meta Title and Meta Description
                                </div>
                                <div>
                                    <label className={styles.switch}>
                                        <input
                                            onChange={(e) =>
                                                setGenerateMeta(
                                                    e.target.checked
                                                        ? true
                                                        : false,
                                                )
                                            }
                                            type="checkbox"
                                            checked={generateMeta}
                                        />
                                        <span
                                            className={`${styles.slider} ${styles.round}`}
                                        ></span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {selectedCase && isMobile() && (
                            <div
                                style={{ position: "relative" }}
                                className={styles.generateMore}
                            >
                                {generateLoading ? (
                                    <div className={styles.generateLoader}>
                                        <p>
                                            Please wait, <br /> Our AI can take
                                            up to 30 seconds!
                                        </p>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                <Button
                                    text={
                                        (responseText.length > 0
                                            ? "Generate more"
                                            : "Generate") +
                                        ` (${
                                            navigator.userAgent.includes("Mac")
                                                ? "⌘+ G"
                                                : "Ctrl+ G"
                                        })`
                                    }
                                    Icon={
                                        responseText.length > 0
                                            ? HiArrowNarrowRight
                                            : () => <></>
                                    }
                                    width={
                                        responseText.length > 0
                                            ? "220px"
                                            : "160px"
                                    }
                                    height="40px"
                                    fontSize="14px"
                                    handler={(e) =>
                                        generate(
                                            responseText.length > 0
                                                ? true
                                                : false,
                                        )
                                    }
                                    loading={generateLoading}
                                ></Button>
                            </div>
                        )}
                        {sensitiveContent ? (
                            <>
                                <Spacer spacing={10} />
                                <p className={styles.sensitiveContentLine}>
                                    Inappropriate content detected! Please try
                                    with some other input.
                                </p>
                            </>
                        ) : null}
                        {responseText.length > 0 && (
                            <>
                                <div className={styles.resultsBox}>
                                    <h3> Results </h3>
                                    <span ref={scrollRef}></span>
                                </div>
                                {responseText.map((res, i) => {
                                    if (res.content.length > 0) {
                                        return (
                                            <GenerateResults
                                                result={res.content}
                                                isSensitive={res.isSensi}
                                                key={res.uniqueId}
                                                id={res?.id || 0}
                                                small={true}
                                                addButton={true}
                                                setValue={setValue}
                                                uniqueId={res.uniqueId}
                                                newResult={res.new}
                                                selectedCase={selectedCase}
                                            />
                                        );
                                    }
                                })}
                            </>
                        )}
                    </>
                )}
                <>
                    <div
                        style={selectedCase ? { display: "none" } : {}}
                        className={styles.dropdownContainer}
                    >
                        <Textfield
                            height={38}
                            containerStyle={{ marginBottom: "0" }}
                            props={{
                                onChange: (e) => {
                                    const value = e.target.value;
                                    setFilterTerm(value);
                                    if (!openStateAll.expand) {
                                        SetOpenStateAll({
                                            collapse: false,
                                            expand: true,
                                        });
                                    }
                                },
                                style: {
                                    borderRadius: 3,
                                    border: "solid 1px rgba(75, 75, 85, 0.16)",
                                    backgroundColor: "#fff",
                                    paddingLeft: "40px",
                                    borderRadius: 3,
                                },
                                value: filterTerm,
                                ref: inRef,
                            }}
                            placeholder="Search Catalog"
                            label=""
                            Icon={() => (
                                <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />
                            )}
                            iconStyle={{
                                left: "10px",
                                right: "unset",
                                top: "20px",
                            }}
                        />
                        {
                            <div
                                onClick={() =>
                                    SetOpenStateAll((ps) => ({
                                        expand: openStateAll.collapse,
                                        collapse: openStateAll.expand,
                                    }))
                                }
                                className={styles.collapseAll}
                            >
                                {openStateAll.collapse
                                    ? "Expand All"
                                    : "Collapse all"}
                            </div>
                        }
                    </div>
                    {
                        <>
                            <div
                                style={selectedCase ? { display: "none" } : {}}
                                className={styles.dropdown}
                            >
                                <VariantsCards
                                    variants={generateCases}
                                    filterTerm={filterTerm}
                                    SetOpenStateAll={SetOpenStateAll}
                                    openStateAll={openStateAll}
                                />
                            </div>
                        </>
                    }
                </>
            </>
            {selectedCase && !isMobile() && (
                <div className={styles.generateMore}>
                    {generateLoading ? (
                        <div className={styles.generateLoader}>
                            <p>
                                Please wait, <br /> Our AI can take up to 30
                                seconds!
                            </p>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <Button
                        text={
                            (responseText.length > 0
                                ? "Generate more"
                                : "Generate") +
                            ` (${
                                navigator.userAgent.includes("Mac")
                                    ? "⌘+ G"
                                    : "Ctrl+ G"
                            })`
                        }
                        Icon={
                            responseText.length > 0
                                ? HiArrowNarrowRight
                                : () => <></>
                        }
                        width={responseText.length > 0 ? "220px" : "160px"}
                        height="40px"
                        fontSize="14px"
                        handler={(e) => {
                            generate(responseText.length > 0 ? true : false);
                        }}
                        loading={generateLoading}
                    ></Button>
                </div>
            )}
        </div>
    );
}
