import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
    AUTH_INCORRECT_LOGIN_DETAILS,
    VALIDATION_ERROR,
    SHOW_LOGIN_PAGE,
    SHOW_REGISTER_PAGE,
    CLEAR_ERROR,
    UPDATE_PLAN,
    SIGNED_UP,
    UPDATE_INFO,
    UPDATE_INFO_V2,
    VERIFICATION_MAIL,
} from "../actiontypes";

const initialState = {
    token: null,
    isLoggedIn: false,
    userId: null,
    email: "",
    user_name: "",
    profilePicture: "",
    brandProfileLogo: "",
    country: "",
    phone: "",
    error: false,
    validatingToken: false,
    role: "user",
    currency: "USD",
    errorMessage: "",
    loading: false,
    authRedirectPath: null,
    continueWithEmailRedirectPath: null,
    googleLogin: false,
    googleToken: null,
    plan_id: null,
    stripe_id: "",
    gst_no: null,
    tour: {
        step: 1,
        enable: false,
        new_user: false,
    },
    notification_count: null,
    request_count: null,
    drafts: null,
    isCreator: 0,
    isGenerate: 0,
    seo_tour: 0,
    ai_tour: 0,
    company_user_type: 2,
    company_name: "",
    is_company_email: null,
    verifyEmail: false,
    created_at: new Date("10/10/2020"),
    on_boarding: false,
    planDowngraded: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_PLAN:
            return {
                ...state,
                plan_id: action.plan_id,
            };
        case SHOW_REGISTER_PAGE:
            // console.log(action.context);
            return {
                ...state,
                email: action.email,
                continueWithEmailRedirectPath: `/auth/register/?${
                    action.lifetime ? "lifetime=true" : ""
                }${
                    action.context
                        ? (action.lifetime ? "&" : "") +
                          "context=" +
                          action.context
                        : ""
                }${
                    action.utmMedium
                        ? `${action.context ? "&" : ""}utm_medium=${
                              action.utmMedium
                          }&utm_source=${action.utmSource}&utm_campaign=${
                              action.utmCampaign
                          }`
                        : ""
                }`,
                loading: false,
                googleToken: action.googleToken,
            };
        case SHOW_LOGIN_PAGE:
            return {
                ...state,
                email: action.email,
                continueWithEmailRedirectPath: "/auth/login",
                loading: false,
            };
        case VERIFICATION_MAIL:
            return {
                ...state,
                verifyEmail: action.verifyEmail,
                email: action.email,
                password: action.password,
                loading: false,
            };
        // case CONTINUE_EMAIL:
        //   return {
        //     ...state,
        //     email:action.email,
        //     continueWithEmailRedirectPath: '/login'
        //   };
        case AUTH_START:
            return {
                ...state,
                loading: true,
                authRedirectPath: null,
                googleLogin: action.googleLogin,
            };
        case AUTH_SUCCESS: {
            return {
                ...state,
                token: action.token,
                userId: action.userId,
                email: action.email,
                user_name: action.name,
                profilePicture: action.profile_picture,
                brandProfileLogo: action.logo,
                role: action.role,
                currency: action.currency,
                phone: action.phone,
                isLoggedIn: true,
                error: false,
                loading: false,
                authRedirectPath: action.path,
                plan_id: action.plan_id,
                validatingToken: false,
                stripe_id: action.stripe_id,
                gst_no: action.gstNo,
                isCreator: action.isCreator,
                isGenerate: action.isGenerate,
                ai_tour: action.ai_tour,
                seo_tour: action.seo_tour,
                company_user_type: action.company_user_type,
                company_name: action.company_name,
                is_company_email: action.is_company_email,
                company_id: action.company_id,
                first_subscription_status: action.first_subscription_status,
                show_lm_landing: action.show_lm_landing,
                show_humanizer_landing: action.show_humanizer_landing,
                signup_mode: action.signup_mode,
                created_at: action.user_created_at,
                userGuiding: action.userGuiding,
                segment: action?.segment || null,
                on_boarding: action?.on_boarding || false,
                country_name: action?.country_name || "",
                seo_surge_user: action.seo_surge_user,
            };
        }
        case AUTH_INCORRECT_LOGIN_DETAILS:
            return {
                ...state,
                error: true,
                errorMessage: action.error,
                loading: false,
            };
        case AUTH_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.error,
                loading: false,
            };
        case SIGNED_UP:
            return {
                ...state,
                authRedirectPath: false,
            };

        case VALIDATION_ERROR:
            return {
                ...state,
                error: true,
                errorMessage: action.errorMessage,
                loading: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: false,
                errorMessage: "",
                continueWithEmailRedirectPath: null,
                authRedirectPath: null,
            };
        case AUTH_LOGOUT:
            return {
                ...initialState,
                country: state.country,
            };
        case UPDATE_INFO:
            return {
                ...state,
                [action.term]: action.value,
            };
        case UPDATE_INFO_V2:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
}
