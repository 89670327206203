import Axios from "axios";
import { url } from "../../utility/config";
import { CONFIG_LOADING, LOAD_CONFIG, UPDATE_CONFIG } from "../actiontypes";
import { updateUserInfo } from "./auth";

export const updateAppConfig = (data) => (dispatch) => {
    dispatch(appConfigLoading());

    return {
        data,
        type: UPDATE_CONFIG,
    };
};

export const loadAppConfig = () => async (dispatch) => {
    dispatch(appConfigLoading());
    try {
        const res = await Axios({
            url: `${url}/api/config`,
        });

        if (res.status == 200) {
            dispatch({
                data: res.data,
                type: LOAD_CONFIG,
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const appConfigLoading = () => {
    return {
        type: CONFIG_LOADING,
    };
};
