import React from "react";
import _ from "lodash";

function Separator(props) {
    try {
        let angle = props.angle;
        angle = (180 * angle) / 10 - 90;

        return (
            <div
                style={{
                    position: "absolute",
                    height: "100%",
                    transform: `rotate(${angle + 2}deg)`,
                    zIndex: props.count ? 0 : 1,
                }}
            >
                <div style={props.style} />
            </div>
        );
    } catch {
        return null;
    }
}

function RadialSeparators(props) {
    let arr = [];
    if (props.count) {
        for (let i = 0; i < props.count; i++) {
            arr.push(
                <Separator
                    count={props.count}
                    angle={(10 / props.count) * (i + 1)}
                    style={props.style}
                />,
            );
        }
        return arr;
    }

    return null;
}

export default RadialSeparators;
