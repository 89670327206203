import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import SVGIcon from "../../atoms/SVGIcon";
import moment from "moment";
import { GSC_Countries } from "./GscCountries";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
export interface RecommendationsDataObject {
    currentTraffic: number | string;
    deltaCTR: number | string;
    deltaTraffic: number | string;
    id: number;
    page: string;
    query: string;
    report_id: number;
    status: 1 | 2 | 3 | undefined | number;
    volume: number | string;
    contentOptimizations: contentOptimizationsObject;
    technicalIssues: { type: string; issue: string }[];
    currentRank: number | string;
    effort_required: string;
    monthly_impression: number;
    needs_attention: number;
    optimization_scope: string | number;
    issues_found: string | number;
    issues_major: string | number;
    issues_minor: string | number;
    page_no: number;
    seo_score: number;
    trends: { date: string; rank: number }[];
    trendsGraph: any[];
    trendsLabel: string[];
    trendsGraphDashed: any[];
    classification: string;
    trendsStatus: number;
}

export interface InventoryV2Object {
    dummy?: boolean;
    avg_clicks_trend: number[];
    avg_difficulty: number;
    avg_impressions_trend: number[];
    avg_position_trend: number[];
    delta_avg_position: number;
    keywords: InventoryKeywordObject[];
    total_keywords_count: number;
    avg_position: number;
    page: string;
    total_traffic: number;
    traffic_share: number;
    impressions: number[];
    volume: number | string;
    avg_rank: number[];
    id: number;
    total_impressions: number | string;
    dashedPosition: number[];
    delta_keywords_count: number;
    delta_impressions: number;
    delta_traffic: number;
    avg_position_classification: string;
    color: string;
    trendsStatus: number;
    page_filter: number;
    trendsDateLabel: string[];
}

export interface InventoryKeywordObject {
    avg_position: number;
    clicks: number;
    cpc: number;
    id: number;
    ctr: number;
    difficulty: number;
    intents: number[];
    keyword: string;
    position_list: number[];
    dashedPosition: number[];
    total_clicks: number;
    impressions: number;
    delta_clicks: number;
    trend: number[];
    volume: number;
    delta_impressions: number;
    position_classification: string;
    classificationColor: number;
    trendsStatus: number;
    trendsDateLabel: string[];
    report_info: { report_id: string | null; is_completed: number | null };
    page?: string;
}

export interface DomainObject {
    domain_name: string;
    is_selected?: 0 | 1;
    gsc_id?: number;
    status?: 0 | 1 | string;
    re_auth?: 1;
    is_disconnect?: 0 | 1;
}
export interface CountryObject {
    flagCode?: string; //2 letter, for flag
    country: string; //3 letter, for UI
    label?: string; // for UI
    is_selected?: number; // 0,1
    type?: number; // 0 delete, 1 add
}

export interface TopMoversObject {
    page: string;
    delta: number;
    currentValue: number;
}

export interface TopPagesKeywordsObject {
    keys: string[];
    clicks: number;
    impressions: number;
    ctr: number;
    position: number;
    page: string;
    totalMergeRows: number;
    delta_position: number;
    query?: string;
}

export interface TopMoversLosers {
    clicks: TopMoversObject[];
    rank: TopMoversObject[];
    ctr: TopMoversObject[];
    impression: TopMoversObject[];
}
export interface GscInsightsDataObject {
    organicCTR: object;
    top_movers_losers_pages?: {
        topMovers?: TopMoversLosers;
        topLosers?: TopMoversLosers;
    };
    top_movers_losers_keywords?: {
        topMovers?: TopMoversLosers;
        topLosers?: TopMoversLosers;
    };
    countries_info: [string, number][];
    devices_info: any;
    positionInfo: any;
    lost_pages_info: [];
    new_pages_info?: [];
    web_crawl_info?: {
        lastCrawledDate: any;
        lastUpdatedDate: any;
    };
    monthly_insights_info?: {
        clicks?: number;
        impressions?: number;
        ctr?: number;
        position?: number;
        delta_clicks?: number;
        delta_impressions?: number;
        delta_ctr?: number;
        delta_position?: number;
        clicks_trend?: number[];
        weeksDateList?: string[];
        color?: string;
    };
    delta_keywords_count?: {
        all: number;
        branded: number;
        nonBranded: number;
    };

    top_pages_info?: TopPagesKeywordsObject[];
    top_keywords_info?: TopPagesKeywordsObject[];
    next_refresh_at: string;
    status: 0 | 1 | 2;
}

export interface InsightsOtherInfoObject {
    dateRange: { start: string; end: string };
    status?: number;
}

export interface SelectedDomain {
    siteUrl?: string;
}

export interface contentOptimizationsObject {
    issues:
        | {
              status: string;
              statement: string;
              isShowCurrentKeyterm?: boolean;
              isShowH2H6Keyterm?: boolean;
          }[]
        | [];
    headingKeyTerms: any[];
    unusedKeyTerms: string[];
    isShowH2H6Keyterm?: boolean;
    isShowCurrentKeyterm?: boolean;
}

export interface ColumnsNameObject {
    name: string;
    isChecked: boolean;
    isDisabled: boolean;
}

export interface TotalDeltaValues {
    totalDeltaCTR: number;
    totalDeltaTraffic: number;
    totalCurrentTraffic: number;
    status?: number;
    created_at?: string;
    high_optimization_total: number;
    next_refresh_at?: string;
    tDT: number | string;
    isTrendsShow: boolean;
}

export interface Props_ItemSubCards {}

export interface CannibalizationPageObject {
    avg_position: number;
    clicks: number;
    ctr: number;
    impressions: number;
    keyword: string;
    page: string;
    position: number;
    position_list: number[];
    dashed_position_list: number[];
    filtred_position_list: number[];
    isChecked?: boolean;
    trafficShare: number;
    impressionsShare: number;
}

export interface CannibalizationKeywordObject {
    affectedPages: number;
    allPages: CannibalizationPageObject[];
    cannibalizationPercentage: number;
    keyword: string;
    cannibalization_rate: number;
    totalImpression: number;
    totalTraffic: number;
    trafficShare: number;
    ctr: number;
}
export interface CannibalizationOtherInfoObject {
    affectedKeywordsCount?: number | undefined;
    totalKeywordsCount?: number | undefined;
}

export interface CannibalizationRequestPayload {
    cr: {
        min: number;
        max?: number;
    };
    impression: {
        min: number;
        max?: number;
    };
    avg_pos: {
        min: number;
        max?: number;
    };
    included_terms: string[];
    excluded_terms: string[];
    sort: {
        key: string;
        order: 0 | 1;
    };
    currentPage?: number;
    itemsPerPage?: number;
    search?: string;
}

const useGscHook = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);

    const GraphBoxSkeleton: (classes: {
        topRow: string;
        graphBox: string;
        topRowLeftWidth?: string;
        topRowRightWidth?: string;
        graphHeight?: string;
    }) => JSX.Element = ({
        topRow,
        graphBox,
        topRowLeftWidth = "50%",
        topRowRightWidth = "50%",
        graphHeight = "100%",
    }) => {
        return (
            <>
                <div className={topRow}>
                    <Skeleton
                        animation="wave"
                        width={topRowLeftWidth}
                        height={"50%"}
                        style={{
                            transform: "scale(1)",
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                        }}
                    />
                    <Skeleton
                        animation="wave"
                        width={topRowRightWidth}
                        height={"50%"}
                        style={{
                            transform: "scale(1)",
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                        }}
                    />
                </div>
                <div className={graphBox}>
                    <Skeleton
                        animation="wave"
                        height={graphHeight}
                        width={"90%"}
                        style={{
                            transform: "scale(1)",
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                        }}
                    />
                </div>
            </>
        );
    };
    function removeDomainFromURL(url: string) {
        const domainRemovedURL = url.replace(/^https?:\/\/[^/]+/, "");
        if (domainRemovedURL == "/" || domainRemovedURL == "") {
            const parsedUrl = new URL(url);
            let domain = parsedUrl.hostname;

            if (domain.startsWith("www.")) {
                domain = domain.slice(4); // Remove 'www.' if present
            }

            return domain;
        }
        return domainRemovedURL;
    }
    const getMappedValue = (stringValue: string) => {
        let _stringValue = stringValue?.toLowerCase();
        switch (_stringValue) {
            case "not_required":
                return 9;

            case "high":
                return 1;
            case "medium":
                return 2;

            case "low":
                return 3;

            case "1":
                return 4;
            case "0":
                return 5;
            case "positive":
                return 11;
            case "neutral":
                return 12;
            case "negative":
                return 13;
            default:
                return -1;
        }
    };

    const getMappedStringTA = (
        stringValue: number = -1,
        showIcon: boolean = false,
        reverseIcon: boolean = false,
    ) => {
        switch (stringValue) {
            case 9:
                return "Not Required";
            case 1:
                return (
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <SVGIcon
                            src={"/New UI/SVG/high_arrow.svg"}
                            style={{
                                display: showIcon ? "flex" : "none",
                                color: "inherit",
                            }}
                            size={13}
                        />
                        High
                    </span>
                );
            case 2:
                return "Medium";
            case 3:
                return "Low";
            case 4:
                return (
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: reverseIcon ? "row-reverse" : "row",
                            gap: "10px",
                        }}
                    >
                        Yes
                        <SVGIcon
                            src={"/New UI/SVG/clock_hurry.svg"}
                            style={{ display: showIcon ? "flex" : "none" }}
                        />
                    </span>
                );
            case 5:
                return "No";

            default:
                return "-";
        }
    };

    function getValueWithUnit(val: string | number) {
        if (val == null) {
            return "-";
        }
        if (+val < 999) {
            return val;
        } else if (+val <= 9999) {
            //k
            return Math.round((+val / 1000) * 100) / 100 + "K";
        } else if (+val <= 99999) {
            //10k
            return Math.round((+val / 1000) * 100) / 100 + "K";
        } else if (+val <= 999999) {
            //100k
            return Math.round((+val / 1000) * 100) / 100 + "K";
        } else if (+val <= 9999999) {
            //1Mn
            return Math.round((+val / 1000000) * 100) / 100 + "Mn";
        } else if (+val <= 99999999) {
            //10Mn
            return Math.round((+val / 1000000) * 100) / 100 + "Mn";
        } else if (+val <= 999999999) {
            //100Mn
            return Math.round((+val / 1000000) * 100) / 100 + "Mn";
        }
    }

    const getRemainingDays = (nxtDate: any) => {
        let a = moment(nxtDate);
        let b = moment();
        let rd = a.diff(b, "days");

        if (rd == 0) {
            return `1 day`;
        } else if (rd == 1) {
            return `${rd} day`;
        } else {
            return `${rd} days`;
        }
    };
    const lastNonZeroValue = (arr: number[]): string => {
        for (let i = arr.length - 1; i >= 0; i--) {
            if (arr[i] !== 0 && arr[i] !== null) {
                return Math.round(arr[i]).toString();
            }
        }
        return "-";
    };

    const firstNonZeroValue = (arr: any) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] !== 0 && arr[i] !== null) {
                return Math.round(arr[i]).toString();
            }
        }
        return "-";
    };

    const getKDColorTooltip: (difficulty: number) => {
        color: string;
        tooltip: string;
    } = (difficulty) => {
        if (difficulty <= 14) {
            return {
                color: "#009f81",
                tooltip: "Your chance to start ranking new pages is very easy",
            };
        } else if (difficulty <= 29) {
            return {
                color: "#59ddaa",
                tooltip: "Your chance to start ranking new pages is very easy",
            };
        } else if (difficulty <= 49) {
            return {
                color: "#fdc23c",
                tooltip: "You will need well-structured and unique content.",
            };
        } else if (difficulty <= 69) {
            return {
                color: "#ff8c43",
                tooltip:
                    "You will need lots of ref. domains and optimized content.",
            };
        } else if (difficulty <= 85) {
            return {
                color: "#ff4953",
                tooltip:
                    "You will need lots of high-quality ref. domains and optimized content.",
            };
        } else {
            return {
                color: "#d1002f",
                tooltip:
                    "It will take a lot of on-page SEO, link building, and content promotion efforts.",
            };
        }
    };

    const getLineColor: (type: number) => string = (type) => {
        if (type == 11) {
            //green
            return "#1DC198";
        } else if (type == 12) {
            //yellow
            return "#FFBE57";
        } else {
            //red
            return "#FC5A5A";
        }
    };

    const getGradientValues: (type: number) => {
        start: string;
        end: string;
    } = (type) => {
        let value = { start: "", end: "" };
        if (type == 11) {
            //green

            value.start = "rgba(29, 193, 152, .4)";
            value.end = "rgba(29, 193, 152, 0)";
            return value;
        } else if (type == 12) {
            //yellow
            value.start = "rgba(255, 190, 87, .4)";
            value.end = "rgba(255, 190, 87, 0)";
            return value;
        } else {
            //red
            value.start = "rgba(252, 90, 90, .4)";
            value.end = "rgba(252, 90, 90, 0)";
            return value;
        }
    };

    const getTrimmedDomainURL: (siteUrl?: string) => string = (
        siteUrl = "",
    ) => {
        if (!siteUrl || !siteUrl.length) {
            return "";
        } else if (siteUrl?.includes("sc-domain")) {
            return siteUrl.split(":")[1];
        } else {
            try {
                const parsedUrl = new URL(siteUrl);
                return parsedUrl.hostname.replace("www.", "");
            } catch (err) {
                return siteUrl;
            }
        }
    };

    const getBrandKeyword = (siteUrl: string): string => {
        let domain = siteUrl;
        let result = null;

        if (domain?.includes("sc-domain:")) {
            const regex = /:(.*?)\./;
            const match = regex.exec(domain);
            result = match ? match[1] : null;
        } else if (domain?.includes("http")) {
            result = new URL(domain).hostname;
            result = result.split(".")[1];
        }
        return result || ""; // Return an empty string if result is null or undefined
    };

    const getFilteredGraphData: (trendsGraph: any[]) => any[] = (
        trendsGraph,
    ) => {
        let temp = [];
        for (let i = 0; i < trendsGraph.length; i++) {
            if (trendsGraph[i] > 0) {
                temp.push(trendsGraph[i]);
            } else {
                if (i + 1 < trendsGraph.length) {
                    temp.push(null);
                }
            }
        }
        if (temp.length == 7) {
            temp.push(null);
        }

        return temp;
    };

    const getTrendsDashed: (trendsGraph: any[]) => any[] = (trendsGraph) => {
        let temp = [];
        let lCount = 0;
        let isMaxFromLeft = 0;
        let isMaxFromRight = 0;
        if (Math.max(...trendsGraph) == 0) {
            Array.from({ length: trendsGraph.length }, (_, i) => 5);
        }
        for (let i = 0; i < trendsGraph.length; i++) {
            if (isMaxFromLeft == 0 && trendsGraph[i] == 0) {
            } else if (isMaxFromLeft == 0) {
                isMaxFromLeft = trendsGraph[i];
            }
            if (trendsGraph[i] == 0) {
                lCount++;
                temp.push(null);
            } else {
                temp.push(trendsGraph[i]);
            }
        }
        if (lCount == trendsGraph.length) {
            return ["0", "0", "0", "0", "0", "0", "0", "0"];
        } else if (isMaxFromLeft != 0) {
            let i = 0;
            while (lCount > 0) {
                temp[i] = isMaxFromLeft;
                lCount--;
            }
        }
        let rCount = 0;
        if (trendsGraph[trendsGraph.length - 1] == 0) {
            for (let i = trendsGraph.length - 1; i >= 0; i--) {
                if (isMaxFromRight == 0 && trendsGraph[i] == 0) {
                    rCount++;
                } else {
                    isMaxFromRight = trendsGraph[i];
                    break;
                }
            }
            temp[temp.length - 1] = isMaxFromRight;
        }
        return temp;
    };

    const addForwardSlashUrl: (_url: string) => string = (_url = "") => {
        let url = _url;
        if (!_url?.length) {
            return "/";
        }
        let temp = _url.split("");
        if (temp[0] != "/") {
            url = "/" + url;
        }
        if (temp[temp.length - 1] != "/") {
            url = url + "/";
        }
        return url;
    };

    function trendsSD(arr: number[]) {
        const n = arr.length;
        const mean = arr.reduce((acc, num) => acc + num, 0) / n;
        const squaredDifferences = arr.map((num) => (num - mean) ** 2);
        const meanSquaredDifference =
            squaredDifferences.reduce((acc, diff) => acc + diff, 0) / n;
        const sd = Math.sqrt(meanSquaredDifference);
        // return max value based on Standard deviation
        let _max = Math.max(...arr);
        _max = _max % 5 > 0 ? _max + (5 - (_max % 5)) : _max;
        if (sd > 10) {
            return _max + 5;
        }
        if (sd < 0.9) {
            return _max + 5;
        }
        return +_max;
    }

    const getAvgRank: (arr: number[]) => number = (arr) => {
        let n = 0;
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] > 0) {
                total = total + arr[i];
                n++;
            }
        }
        if (n == 0) {
            return 0;
        }
        return Math.round(total / n);
    };

    const parseCountries: (arr: any[]) => any[] = (arr = []) => {
        if (!arr?.length) {
            return [];
        } else {
            let newArr = arr.map((item) => {
                let idx = GSC_Countries.findIndex(
                    (cn) => cn.code == item.country,
                );
                return {
                    ...item,
                    flagCode: GSC_Countries[idx]?.flagCode || "",
                    label: GSC_Countries[idx]?.label || "",
                    value: GSC_Countries[idx]?.flagCode || "",
                };
            });
            return newArr;
        }
    };

    const getTrendsLabel: (
        data: { startDate: string; endDate: string }[],
    ) => string[] = (data = []) => {
        if (data.length == 0) {
            return [];
        }
        let arr: string[] = [];

        data.forEach((date) => {
            arr.push(moment(date.startDate).format("DD MMM"));
        });

        return arr;
    };

    const getRandomTrend: (luck: number, type: string) => any = (
        luck,
        type,
    ) => {
        let data = [
            {
                arr: [4, 4, 3, 3, 3, 3, 4, 4],
                color: 11,
            },
            {
                arr: [30, 30, 34, 34, 34, 34, 34, 25],
                color: 13,
            },
            {
                arr: [4, 4, 4, 4, 4, 4, 4, 4],
                color: 12,
            },
            {
                arr: [1, 1, 10, 10, 10, 10, 17, 18],
                color: 11,
            },
        ];

        if (type?.toLowerCase() == "color") {
            return data[luck].color;
        } else {
            return data[luck].arr;
        }
    };

    const getDomainStatus: (
        isPro: boolean,
        status: string | 0 | 1 | undefined,
        size?: number,
        color?: string,
    ) => JSX.Element | undefined = (
        isPro = false,
        status,
        size = 22,
        color = "",
    ) => {
        let st = status?.toString()?.toLowerCase();
        switch (st) {
            case "yellow":
                return (
                    <LightTooltip
                        title={
                            isPro
                                ? "All pages have not been processed due to plan limits. Please reach out to Chat Support for assistance and buying options."
                                : "All pages have not been processed due to plan limits. Please upgrade to a higher plan or Reach out to Chat Support"
                        }
                        arrow
                        placement={"bottom"}
                        interactive
                    >
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <SVGIcon
                                src={
                                    "/New UI/SVG/triangle_exclamation-fill.svg"
                                }
                                style={{
                                    color: color.length ? color : "#FBB245",
                                }}
                                size={size ? size : 22}
                            />
                        </span>
                    </LightTooltip>
                );
            case "red":
                return (
                    <LightTooltip
                        title={
                            isPro
                                ? "Page quota exhausted. Please reach out to support for assistance and options."
                                : "Page quota exhausted. Consider a plan upgrade to access more pages or reach out to support for assistance and options."
                        }
                        arrow
                        placement={"bottom"}
                        interactive
                    >
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <SVGIcon
                                src={
                                    "/New UI/SVG/triangle_exclamation-fill.svg"
                                }
                                style={{
                                    color: color.length ? color : "#FC5A5A",
                                }}
                                size={size ? size : 22}
                            />
                        </span>
                    </LightTooltip>
                );
            default:
                <></>;
        }
    };

    return {
        GraphBoxSkeleton,
        getMappedStringTA,
        getMappedValue,
        getValueWithUnit,
        getRemainingDays,
        getLineColor,
        getGradientValues,
        getTrimmedDomainURL,
        getTrendsDashed,
        getFilteredGraphData,
        addForwardSlashUrl,
        lastNonZeroValue,
        firstNonZeroValue,
        getKDColorTooltip,
        removeDomainFromURL,
        trendsSD,
        getAvgRank,
        parseCountries,
        getTrendsLabel,
        getRandomTrend,
        getBrandKeyword,
        getDomainStatus,
    };
};

export default useGscHook;
