import React, { useRef, useState } from "react";
import styles from "./activity.module.css";
import { BsThreeDots } from "react-icons/bs";
import { BiCheckboxChecked } from "react-icons/bi";
import { BsPencilSquare } from "react-icons/bs";
import { useSelector } from "react-redux";
import BigImageModal from "./BigImageModal";
import axios from "axios";
import { url } from "../../../../../utility/config";
import {
  getTime,
  replaceURLWithHTMLLinks,
} from "../../../../../utility/validateFunctions";
import { FaFilePdf } from "react-icons/fa";
import { GrDocumentWord, GrDocumentTxt } from "react-icons/gr";
import { RiExternalLinkLine, RiVideoFill } from "react-icons/ri";
import { MdAudiotrack } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import Modal from "../../../../../componentsV2/atoms/Modal/Modal";
import FeedbackForm from "../../../../../componentsV2/organisms/FeedbackForm/feedbackForm";

const getInitials = (username = "Test User") => {
  let initials = "";
  if (username) {
    const usernameArray = username?.split(" ");
    initials += usernameArray[0].charAt(0);
    if (usernameArray.length <= 1) return initials;
    initials += usernameArray[usernameArray.length - 1].charAt(0);
  }
  return initials;
};

const formatTime = (dateString) => {
  let myDate = new Date(dateString);
  let minutes = myDate.getMinutes();
  let hours = myDate.getHours();
  let date = myDate.getDate();
  let month = myDate.getMonth() + 1;
  let year = myDate.getFullYear();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  let time = hours + ":" + minutes + " on " + date + "/" + month + "/" + year;

  return time;
};

export default function Comment({
  comment,
  image,
  pdf,
  docx,
  projectID,
  audio,
  video,
  postComment,
  setFile,
  ref1,
}) {
  const [editableMessage, setEditableMessage] = useState(comment.message);
  const [editMode, setEditMode] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [lastMessage, setLastMessage] = useState("");
  const [showEditMenuDropdown, setShowEditMenuDropdown] = useState(false);
  const userID = useSelector((state) => state.auth.userId);
  const [showVideo, setShowVideo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [bigImgModalOpen, setBigImgModalOpen] = useState(false);
  const ref = useRef({});

  const cancelEditMode = () => {
    setEditableMessage(lastMessage);
    setEditMode(false);
  };

  const startEditMode = () => {
    setEditMode(true);
    setShowEditMenuDropdown(false);
    setLastMessage(editableMessage);
  };

  const editComment = () => {
    const editedMessageData = {
      id: comment._id,
      message: editableMessage,
      projectID: projectID,
    };

    setEditMode(false);

    axios({
      method: "post",
      url: `${url}/api/dbApi/project/history/change`,
      data: editedMessageData,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setEditableMessage(res.data.data.message);
      })
      .catch((err) => console.log(err));
  };

  function putIcon(icon) {
    if (!icon) return null;
    if (icon.match(/.*\.pdf$/))
      return (
        <a
          href={comment.attachmentName}
          target="_blank"
          style={{ display: "inline-block" }}
        >
          <FaFilePdf />{" "}
        </a>
      );
    else if (icon.match(/\.(?:blob)$/i))
      return comment.mediaType == 1 ? (
        <MdAudiotrack onClick={() => setShowAudio(true)} />
      ) : (
        <RiVideoFill
          onClick={() => setShowVideo(true)}
          className={styles.videoIcon}
        />
      );
    else if (icon.match(/\.(?:doc|docx)$/i))
      return (
        <a
          href={comment.attachmentName}
          target="_blank"
          style={{ display: "inline-block" }}
        >
          <GrDocumentWord />
        </a>
      );
    else if (icon.match(/.*\.txt$/))
      return (
        <a
          href={comment.attachmentName}
          target="_blank"
          style={{ display: "inline-block" }}
        >
          {" "}
          <GrDocumentTxt />
        </a>
      );
    else if (icon.match(/\.(?:wav|mp3)$/i))
      return <MdAudiotrack onClick={() => setShowAudio(true)} />;
    else if (icon.match(/\.(?:webm|mp4|mkv|wmv)$/i))
      return (
        <RiVideoFill
          onClick={() => setShowVideo(true)}
          className={styles.videoIcon}
        />
      );
    else if (icon.match(/\.(?:jpeg|png|jpg|gif|svg|webp)$/i))
      return (
        <img
          ref={ref}
          src={icon}
          alt="sample"
          style={{ cursor: "pointer" }}
          onClick={() => setBigImgModalOpen(true)}
        />
      );
    else
      return (
        <a
          href={comment.attachmentName}
          target="_blank"
          style={{ display: "inline-block" }}
        >
          <FaFileAlt />
        </a>
      );
  }
  if (comment.type === 2 || comment.type === 3) {
    return (
      <div className={styles.adminComment}>
        <span className={styles.profileAvatar}>
          {comment.type === 3 ? "S" : getInitials(comment.userName)}
        </span>
        <p className={styles.user}>
          <div className={styles.nameAndDate}>
            <span className={styles.commentUsername}>
              {comment.type === 3
                ? null
                : comment.userName &&
                  !comment.message.includes("Congratulations")
                ? comment.userName
                : null}{" "}
              {comment.message}
              {/* {comment.role === 'admin' ? '(admin)' : ''} */}
            </span>
            <br />
            <span className={styles.time}>{getTime(comment.created_at)}</span>
          </div>
        </p>
      </div>
    );
  }

  if (comment.type === 4) {
    return (
      <div className={styles.adminComment}>
        {feedback && (
          <Modal setModal={setFeedback}>
            {" "}
            <FeedbackForm review={true} data={comment.feedback} />
          </Modal>
        )}
        <span className={styles.profileAvatar}>
          {getInitials(comment.userName)}
        </span>
        <p className={styles.user}>
          <div className={styles.nameAndDate}>
            <span className={styles.commentUsername}>
              {comment.userName} {comment.message}
              <span
                onClick={() => setFeedback(!feedback)}
                className={styles.feedback}
              >
                {" "}
                Feedback {">"}{" "}
              </span>
              {/* {comment.role === 'admin' ? '(admin)' : ''} */}
            </span>
            <br />
            <span className={styles.time}>{getTime(comment.created_at)}</span>
          </div>
        </p>
      </div>
    );
  }

  return (
    <div className={styles.comment}>
      <div className={styles.showComment}>
        <div className={styles.topOfComment}>
          <span className={styles.profileAvatar}>
            {comment.userName ? getInitials(comment.userName) : null}
          </span>
          <p className={styles.user}>
            <div className={styles.nameAndDate}>
              <span className={styles.commentUsername}>
                {comment.userName ? comment.userName : null}
                {/* {comment.role === 'admin' ? '(admin)' : ''} */}
              </span>{" "}
              {/* {comment.fileName
                ? `attached ${comment.fileName} `
                : 'commented '} */}
              <span className={styles.time}>{getTime(comment.created_at)}</span>
            </div>
            {comment.message && comment.userID == userID ? (
              <div
                className={styles.threeDots}
                style={{ paddingBottom: 5, paddingRight: 0, paddingLeft: 13 }}
                onMouseLeave={() => setShowEditMenuDropdown(false)}
              >
                <BsThreeDots
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setShowEditMenuDropdown((prevState) => !prevState)
                  }
                />
                {showEditMenuDropdown ? (
                  <div className={styles.editMenuDropdown}>
                    <div
                      className={styles.editMenuDropdownOption}
                      onClick={startEditMode}
                    >
                      <BiCheckboxChecked
                        style={{
                          fontSize: "1.4rem",
                          color: "#92929d",
                          marginRight: "8px",
                        }}
                      />
                      <span>Edit comment</span>
                    </div>
                    {/* <div className={styles.editMenuDropdownOption}>
                      <FiTrash2
                        style={{
                          fontSize: '1rem',
                          color: '#92929d',
                          marginLeft: '3.5px',
                          marginRight: '11px',
                        }}
                      />
                      <span>Remove comment</span>
                    </div> */}
                  </div>
                ) : null}
              </div>
            ) : null}
          </p>
        </div>

        <div className={styles.commentContainer}>
          {comment.message ? (
            <>
              {!editMode ? (
                <p
                  className={styles.commentMessage}
                  dangerouslySetInnerHTML={{
                    __html: replaceURLWithHTMLLinks(editableMessage),
                  }}
                />
              ) : (
                <textarea
                  type="text"
                  value={editableMessage}
                  className={styles.editableTextInput}
                  onChange={(e) => setEditableMessage(e.target.value)}
                />
              )}
              {comment.userID == userID ? (
                <div className={styles.lowerButtonGroup}>
                  {!editMode ? null : (
                    <>
                      <button
                        className={styles.lowerButton}
                        style={{ color: "#0062ff" }}
                        onClick={editComment}
                      >
                        Save
                      </button>
                      <button
                        onClick={cancelEditMode}
                        className={styles.lowerButton}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              ) : null}
            </>
          ) : (
            ""
          )}

          {showVideo ? (
            <div onClick={(e) => e.stopPropagation()}>
              <video
                src={
                  comment.media
                    ? comment.media
                    : comment.imageAverage
                    ? comment.imageAverage
                    : comment.attachmentName
                }
                autoPlay={false}
                controls={true}
                width="320"
                height="220"
              >
                {" "}
              </video>
              <span
                className={styles.cross}
                onClick={() => setShowVideo(false)}
              >
                <IoIosCloseCircle />
              </span>
            </div>
          ) : showAudio ? (
            <div onClick={(e) => e.stopPropagation()}>
              <audio
                src={
                  comment.media
                    ? comment.media
                    : comment.imageAverage
                    ? comment.imageAverage
                    : comment.attachmentName
                }
                autoPlay={false}
                controls={true}
              ></audio>
              <span
                className={styles.cross}
                onClick={() => setShowAudio(false)}
              >
                <IoIosCloseCircle />
              </span>
            </div>
          ) : (
            <>
              <div className={styles.imgContainer}>
                {putIcon(comment.media ? comment.media : null)}
                {putIcon(
                  comment.imageAverage
                    ? comment.imageAverage
                    : comment.attachmentName
                )}

                {/* <span
                  onClick={() => {
                    if (!navigator?.mediaDevices?.getDisplayMedia) {
                      alert('This feature is supported on desktop browsers!!!');

                      return;
                    }
                    showMarkerArea(
                      ref.current,
                      setFile,
                      false,
                      ref1.current,
                      comment?.fileName
                    );
                  }}
                  className={styles.edit}
                  style={{
                    float: 'right',
                    fontSize: '1.3rem',
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  <BsPencilSquare />
                </span> */}
              </div>
              <BigImageModal
                setBigImgModalOpen={setBigImgModalOpen}
                bigImgModalOpen={bigImgModalOpen}
                imgSrc={comment.attachmentName}
              />
            </>
          )}
          {/* {pdf ? (
            <>
              <a
                href={comment.attachmentName}
                target='_blank'
                style={{ display: 'inline-block' }}
              >
                <FaFilePdf
                  style={{
                    fontSize: '4rem',
                    color: '#4e4d4d',
                    display: 'block',
                  }}
                />
              </a>
            </>
          ) : (
            ''
          )}
          {docx ? (
            <>
              <a
                href={comment.attachmentName}
                target='_blank'
                style={{ display: 'inline-block' }}
              >
                <GrDocumentWord
                  style={{
                    fontSize: '4rem',
                    color: '#4e4d4d',
                    display: 'block',
                  }}
                />
              </a>
            </>
          ) : (
            ''
          )}
          {audio && <audio autoPlay={false} src={audio} controls={true} />}
          {video ? (
            !showVideo ? (
              <RiVideoFill
                onClick={() => setShowVideo(true)}
                className={styles.videoIcon}
              />
            ) : (
              <video
                autoPlay={false}
                src={video}
                controls={true}
                width='320'
                height='220'
              />
            )
          ) : null} */}
        </div>
      </div>
    </div>
  );
}
