import React, { useState } from "react";
import { useEffect } from "react";
import { VscCircle } from "react-icons/vsc";
import {
    formatTime,
    getShortDateTime,
    getTime,
} from "../../../../utility/validateFunctions";
import styles from "./GenerateHistory.module.css";

function HistoryCard({ selected, onClick, item, i, setdisplayMobileModal }) {
    const [localData, setLocalData] = useState(item.text);

    useEffect(() => {
        if (i == 0) {
            onClick(localData, setLocalData);
        }
    }, []);

    return (
        <div
            className={
                styles.card + " " + (!selected ? styles.cardUnselected : null)
            }
            onClick={() => {
                onClick(localData, setLocalData);
                setdisplayMobileModal(true);
            }}
        >
            <VscCircle color="#597ef7" className={styles.cardCircle} />
            <img
                src={
                    item.useCaseIcon
                        ? item.useCaseIcon !== "null"
                            ? item.useCaseIcon
                            : "https://storage.googleapis.com/scalenut/7af93ebf-c06a-494b-b479-ca75ded812a6.svg"
                        : "https://storage.googleapis.com/scalenut/7af93ebf-c06a-494b-b479-ca75ded812a6.svg"
                }
                alt=""
                width={30}
                height={30}
            />
            <div>
                <span>
                    {item.count} outputs generated for{" "}
                    <span className={styles.cardKeyword}>
                        {item?.useCaseName.split("_").join(" ").toLowerCase()}
                    </span>{" "}
                    {/* <span className={styles.cardKeyword}> {item.brand} </span>{" "} */}
                </span>
                <span className={styles.cardTime}>
                    {/* {new Date(item.created_at).toLocaleTimeString([], {
            timeStyle: "short",
          })} */}
                    {getShortDateTime(item.created_at)}{" "}
                    {item.username ? ` - ${item.username}` : null}
                </span>
            </div>
        </div>
    );
}

export default HistoryCard;
