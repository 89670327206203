import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Textfield from "../../../../componentsV2/atoms/CustomTextfield/Textfield";
import TextArea from "../../../../componentsV2/atoms/TextArea/TextArea";
import Button from "../../../../componentsV2/atoms/Button/Button";
import { isValidUrl } from "../../../../utility/helperFun";
import Label from "../../../../componentsV2/atoms/Label/Label";
import useHelperFunctions from "../../../../componentsV2/helperFunctions";

import { Skeleton } from "@material-ui/lab";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlinePencil } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import Modal from "../../../../componentsV2/atoms/Modal/Modal";
import { CgClose } from "react-icons/cg";
import { ToneType } from "./constants";
import useUserAccessHook from "../../useUserAccessHook";
import { BsArrowUpCircle } from "react-icons/bs";
import { GrAnnounce } from "react-icons/gr";
import { IoMegaphoneOutline } from "react-icons/io5";
import useNavigation from "../../../../componentsV2/atoms/GoToRoute/useNavigation";
import { VscTrash } from "react-icons/vsc";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

function BrandTone({
    setToneModal = (arg: any) => {},
    modalType = false,
    onTonesChange = (arg: any) => {},
    onToneAdd = (tone: ToneType) => {},
    toneName = "",
}) {
    const [formdata, setFormdata] = useState({
        name: "",
        url: "",
        text: "",
        tone: "",
    });
    const [loading, setLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [switchUrl, setSwitchUrl] = useState(true);
    const [userTone, setUserTone] = useState(true);
    const { postData, getData } = useHelperFunctions();
    const [tones, setTones] = useState([]);
    const [loadedTones, setTonesLoaded] = useState(false);
    const dispatch = useDispatch();
    const { mpTrackToneAdded } = useMixpanelHook();
    const { isFreeForever, isEssential, individualUser } = useUserAccessHook();
    const goTo = useNavigation();
    const getTones = async (callback?: Function) => {
        setGetLoading(true);
        const res = await getData({
            url: "/api/tone/get",
        });
        if (res.status == 200) {
            const tones = res.data.data.reverse();
            setTones(tones);
            //return latest tone
            callback && callback(tones[0]);

            setGetLoading(false);
            setTonesLoaded(true);
        }
    };

    useEffect(() => {
        setFormdata((ps) => ({
            ...ps,
            tone: toneName,
        }));
    }, []);

    const createTone = async () => {
        if (isFreeForever || isEssential || individualUser) {
            return;
        }
        mpTrackToneAdded({
            location: modalType ? "Cruise Mode" : "Account Section",
            source: userTone
                ? "Manual"
                : switchUrl
                ? "Scalenut-url"
                : "Scalenut-text",
        });
        setLoading(true);
        const res = await postData({
            url: "/api/tone/save",
            payload: userTone
                ? { brand_name: formdata.tone, tone: formdata.tone }
                : {
                      brand_name: formdata.name,

                      ...(switchUrl
                          ? {
                                url:
                                    formdata.url.indexOf("https://") == 0 ||
                                    formdata.url.indexOf("http://") == 0
                                        ? formdata.url
                                        : `https://${formdata.url}`,
                            }
                          : { text: formdata.text }),
                  },
        });
        if (res.status == 200) {
            dispatch(
                displayAlert({
                    alertType: "success",
                    alertMessage: (
                        <>
                            The tone of voice{" "}
                            <b style={{ color: "black" }}>
                                {userTone ? formdata.tone : formdata.name}
                            </b>{" "}
                            has been added.
                        </>
                    ),
                }),
            );

            setFormdata({ name: "", url: "", text: "", tone: "" });

            getTones(onToneAdd);
            if (userTone) {
                setToneModal(false);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getTones();
    }, []);

    useEffect(() => {
        onTonesChange(tones);
    }, [tones]);

    return (
        <div
            className={`${styles.container} ${
                modalType ? styles.containerInModal : ""
            }`}
        >
            <h2>Tone of Voice</h2>
            <p>
                Enable Scalenut to create content that is aligned with your tone
                of voice by using specific writing styles and tonality. <br />{" "}
                To set this up, provide information related to your desired
                content type.
            </p>

            <section>
                <div>
                    <header>
                        <h4>Add a New Tone</h4>
                        <p>
                            Let Scalenut detect your tone of voice or input a
                            specific tone of voice to generate personalized
                            content effortlessly. Begin by pasting your content
                            or tone of voice below.
                        </p>
                    </header>

                    <div>
                        <div className={styles.radioWrapper}>
                            <input
                                onChange={(e) => setUserTone(e.target.checked)}
                                name="toneType"
                                type="radio"
                                defaultChecked
                                disabled={loading}
                            />
                            <Label label="Enter your tone manually" />
                            <input
                                onChange={(e) => setUserTone(!e.target.checked)}
                                name="toneType"
                                type="radio"
                                disabled={loading}
                            />
                            <Label label="Let Scalenut identify your tone" />
                        </div>
                        {userTone ? (
                            <Textfield
                                onChange={(e: any) =>
                                    setFormdata((ps) => ({
                                        ...ps,
                                        tone: e.target.value,
                                    }))
                                }
                                // @ts-ignore
                                label={""}
                                required
                                value={formdata.tone}
                                placeholder="E.g. Friendly"
                                maxLength={75}
                                // @ts-ignore
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        createTone();
                                    }
                                }}
                            ></Textfield>
                        ) : (
                            ""
                        )}
                    </div>

                    {!userTone ? (
                        <>
                            <Textfield
                                onChange={(e: any) =>
                                    setFormdata((ps) => ({
                                        ...ps,
                                        name: e.target.value,
                                    }))
                                }
                                // @ts-ignore
                                label={"Tone Name"}
                                required
                                value={formdata.name}
                                placeholder="Enter the name you want to give to this tone E.g. Q&A Blogs"
                                maxLength={75}
                                disabled={loading}
                            ></Textfield>
                            <div>
                                <div className={styles.radioWrapper}>
                                    <input
                                        onChange={(e) =>
                                            setSwitchUrl(e.target.checked)
                                        }
                                        name="toneSource"
                                        type="radio"
                                        defaultChecked
                                        disabled={loading}
                                    />
                                    <Label label="Paste URL" />
                                    <input
                                        onChange={(e) =>
                                            setSwitchUrl(!e.target.checked)
                                        }
                                        name="toneSource"
                                        type="radio"
                                        disabled={loading}
                                    />
                                    <Label label="Paste brand text" />
                                </div>

                                {switchUrl ? (
                                    <Textfield
                                        onChange={(e: any) =>
                                            setFormdata((ps) => ({
                                                ...ps,
                                                url: e.target.value,
                                            }))
                                        }
                                        // @ts-ignore
                                        label={""}
                                        errMsg={
                                            formdata.url.length > 0 &&
                                            !isValidUrl(formdata.url)
                                                ? "Invalid URL"
                                                : ""
                                        }
                                        placeholder="https://scalenut.com"
                                        required
                                        value={formdata.url}
                                        disabled={loading}
                                    ></Textfield>
                                ) : (
                                    <TextArea
                                        props={{
                                            onChange: (e: any) => {
                                                setFormdata((ps) => ({
                                                    ...ps,
                                                    text: e.target.value,
                                                }));
                                            },
                                            value: formdata.text,
                                            disabled: loading,
                                        }}
                                        maxLength={2000}
                                        noItalics={undefined}
                                        rowsMax={8}
                                        labelSize={undefined}
                                        errInField={undefined}
                                        label=""
                                        placeholder="Write and paste any content of your brand"
                                        counter={true}
                                        rowsMin={6}
                                        containerStyle={{}}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    <Button
                        style={{
                            marginLeft: "auto",
                            width: 150,
                            fontSize: 14,
                        }}
                        text={"Add Tone"}
                        disabled={
                            userTone
                                ? !formdata.tone
                                : !formdata.name ||
                                  (switchUrl
                                      ? !isValidUrl(formdata.url)
                                      : !formdata.text)
                        }
                        handler={createTone}
                        loading={loading}
                    ></Button>
                </div>

                <div>
                    <header>
                        <h4>Your Tones</h4>
                        <p>Your existing tones</p>
                    </header>

                    {getLoading || loading ? (
                        <div>
                            {new Array(loadedTones ? 1 : 3).fill(4).map((i) => (
                                <Skeleton
                                    height={75}
                                    width={"100%"}
                                    style={{
                                        marginBottom: "0px !important",
                                    }}
                                />
                            ))}
                        </div>
                    ) : !tones.length ? (
                        <>
                            <p
                                style={{
                                    marginBlock: "auto",
                                }}
                            >
                                You haven’t created any tones yet.
                            </p>
                        </>
                    ) : (
                        ""
                    )}
                    {tones.length
                        ? tones.map((tone: ToneType, index: number) => (
                              <Tone
                                  tone={tone}
                                  setTones={setTones}
                                  index={index}
                              />
                          ))
                        : ""}
                </div>
            </section>
            {(isFreeForever || isEssential || individualUser) && (
                <div className={styles.upgradeLayover}>
                    <IoMegaphoneOutline
                        size={40}
                        style={{
                            transform: "rotate(-30deg)",
                        }}
                        strokeWidth={"2px"}
                    />
                    <div className={styles.layoverHeading}>Tone of Voice</div>
                    <div className={styles.layoverSubHeading}>
                        You can enable Scalenut to create personalized content
                        that matches your tone of voice by providing details
                        about your desired content type
                    </div>
                    <Button
                        handler={() => {
                            goTo("BILLING_PLANS");
                        }}
                        text={"Upgrade"}
                        width={130}
                        Icon={() => (
                            <BsArrowUpCircle
                                color="#fff"
                                size={18}
                                style={{ marginRight: "5px" }}
                                strokeWidth={"0.4px"}
                            />
                        )}
                        style={{
                            marginTop: "10px",
                        }}
                        iconReverse={true}
                    />
                </div>
            )}
        </div>
    );
}

const Tone = ({
    tone,
    setTones,
    index,
}: {
    tone: ToneType;
    setTones: Function;
    index: number;
}) => {
    const options = {
        delete: "delete",
        edit: "edit",
    };

    const [editModal, setEditModal] = useState({
        enable: false,
        option: options.edit,
    });
    const { postData, getData } = useHelperFunctions();
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(tone.text);
    const dispatch = useDispatch();
    const updateTone = async () => {
        setLoading(true);
        const res = await postData({
            url: "/api/tone/update",
            payload: {
                tone_id: tone.id,
                text: text,
            },
        });
        if (res.status == 200) {
            setEditModal((ps) => ({
                ...ps,
                enable: false,
            }));
            dispatch(
                displayAlert({
                    alertType: "success",
                    alertMessage: (
                        <>
                            The tone of voice{" "}
                            <b style={{ color: "black" }}>{tone.brand_name}</b>{" "}
                            has been updated.
                        </>
                    ),
                }),
            );
            setTones((ps: Array<ToneType>) => {
                ps[index].text = text;
                return [...ps];
            });
        }
        setLoading(false);
    };

    const deleteTone = async () => {
        setLoading(true);
        const res = await postData({
            url: "/api/tone/delete",
            payload: {
                tone_id: tone.id,
            },
        });
        if (res.status == 200) {
            setEditModal((ps) => ({
                ...ps,
                enable: false,
            }));
            dispatch(
                displayAlert({
                    alertType: "success",
                    alertMessage: (
                        <>
                            The tone of voice{" "}
                            <b style={{ color: "black" }}>{tone.brand_name}</b>{" "}
                            has been deleted.
                        </>
                    ),
                }),
            );
            setTones((ps: Array<ToneType>) => {
                ps.splice(index, 1);
                return [...ps];
            });
        }
        setLoading(false);
    };

    return (
        <>
            <div className={styles.toneCard}>
                <header>
                    {tone.brand_name}
                    <div className={styles.toneOption}>
                        <HiOutlinePencil
                            onClick={() =>
                                setEditModal({
                                    enable: true,
                                    option: options.edit,
                                })
                            }
                            color="gray"
                        />
                        <VscTrash
                            style={{
                                marginLeft: 5,
                            }}
                            onClick={() =>
                                setEditModal({
                                    enable: true,
                                    option: options.delete,
                                })
                            }
                            color="red"
                        />
                    </div>
                </header>
                <TextArea
                    props={{
                        value: tone.text,
                        disabled: true,
                    }}
                    maxLength={undefined}
                    noItalics={undefined}
                    rowsMax={10}
                    labelSize={undefined}
                    errInField={undefined}
                    label=""
                    placeholder=""
                    rowsMin={1}
                    containerStyle={{}}
                />
            </div>
            {editModal.enable ? (
                <Modal
                    setModal={() => {
                        setEditModal((ps) => ({
                            ...ps,
                            enable: false,
                        }));
                    }}
                >
                    <div className={styles.confirmDialog}>
                        <div className={styles.closeIcon}>
                            <CgClose
                                onClick={() =>
                                    setEditModal((ps) => ({
                                        ...ps,
                                        enable: false,
                                    }))
                                }
                                size={14}
                                color="lightgray"
                                strokeWidth={2}
                            />
                        </div>

                        {editModal.option == options.edit ? (
                            <header
                                style={{
                                    fontWeight: 500,
                                    marginBlock: 5,
                                }}
                            >
                                {tone.brand_name}
                            </header>
                        ) : (
                            ""
                        )}
                        {editModal.option == options.edit ? (
                            <TextArea
                                props={{
                                    onChange: (e: any) => {
                                        setText(e.target.value);
                                    },
                                    defaultValue: tone.text,
                                    disabled: loading,
                                }}
                                maxLength={2000}
                                noItalics={undefined}
                                rowsMax={6}
                                labelSize={undefined}
                                errInField={undefined}
                                label=""
                                placeholder="Type here"
                                counter={true}
                                containerStyle={{}}
                            />
                        ) : (
                            <h4
                                style={{
                                    textAlign: "center",
                                    margin: 12,
                                }}
                            >
                                Are you sure, you want to delete?
                            </h4>
                        )}

                        <div className={styles.actionBtns}>
                            <Button
                                style={{
                                    width: 170,
                                    fontSize: 14,
                                    backgroundColor: "#d3d3d37d",
                                    color: "gray",
                                }}
                                text={"Cancel"}
                                handler={() =>
                                    setEditModal((ps) => ({
                                        ...ps,
                                        enable: false,
                                    }))
                                }
                            ></Button>
                            <Button
                                style={{
                                    width: 170,
                                    fontSize: 14,
                                }}
                                text={
                                    editModal.option == options.edit
                                        ? "Update"
                                        : "Delete"
                                }
                                handler={
                                    editModal.option == options.edit
                                        ? updateTone
                                        : deleteTone
                                }
                                loading={loading}
                            ></Button>
                        </div>
                    </div>
                </Modal>
            ) : (
                ""
            )}
        </>
    );
};

export default BrandTone;
