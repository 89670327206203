import {
    Close,
    CloseRounded,
    DragHandle,
    DragHandleTwoTone,
    DragIndicator,
} from "@material-ui/icons";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { AiOutlineDrag } from "react-icons/ai";
import { RiDragMove2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setOverlayVideo } from "../../../store/actions/video";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import styles from "./styles.module.css";

export function OverlayVideoContianer() {
    const { overlay } = useSelector((state) => state.video);

    return overlay?.state == 1 ? <OverlayVideo /> : "";
}

export function OverlayVideo() {
    const { overlay } = useSelector((state) => state.video);
    const ref = useRef();
    const dispatch = useDispatch();
    useEffect(() => {
        const onPlayerReady = (e) => {
            console.log(e);
        };

        const onPlayerStateChange = (e) => {
            console.log(e);
        };

        function onYouTubeIframeAPIReady() {
            const player = new YT.Player("overlayVideo", {
                videoId: overlay.videoId,

                playerVars: {
                    playsinline: 1,
                    start: Math.floor(overlay.duration),
                    autoplay: 1,
                },
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange,
                },
            });
        }
        try {
            if (window.YT) onYouTubeIframeAPIReady();
        } catch (error) {
            console.log(error);
        }
    }, [window.YT]);

    return (
        <div ref={ref} className={styles.overlayVideoContiner}>
            <div id={"overlayVideo"} className={styles.overlayVideo}></div>
            <CloseRounded
                onClick={() => {
                    dispatch(setOverlayVideo({}));
                }}
                className={styles.close}
            />
            <div
                onMouseDown={() => {
                    document.onmousemove = (e) => {
                        if (
                            e.x > 20 &&
                            e.y > 20 &&
                            e.y < innerHeight - 50 &&
                            e.x < innerWidth - 50
                        ) {
                            ref.current.style.left = e.x - 10 + "px";
                            ref.current.style.top = e.y - 10 + "px";
                        }

                        ref.current.style.right = "unset";
                        ref.current.style.bottom = "unset";
                    };
                    document.onmouseup = () => {
                        document.onmousemove = () => {};
                    };
                }}
                className={styles.dragIcon}
            >
                <RiDragMove2Fill />
            </div>
        </div>
    );
}
