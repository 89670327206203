import React, { useState } from "react";
import Button from "../../../atoms/Button/Button";
import styles from "./styles.module.css";

function Image({ image, sendImage }) {
  const [options, setOptions] = useState(false);

  return (
    <div
      onMouseOver={() => setOptions(true)}
      onMouseLeave={() => setOptions(false)}
      className={styles.image}
    >
      <img src={image.urls.small} width="200" alt="" />
      {options && (
        <div className={styles.options}>
          <Button
            text="Add to Editor"
            width={108}
            style={{
              backgroundColor: "transparent",
              border: "solid white 0.1px",
              fontSize: 12,
              height: 40,
            }}
            handler={() => {
              sendImage(image.urls.small);
            }}
          ></Button>
        </div>
      )}
    </div>
  );
}

export default Image;
