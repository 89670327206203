import React, { useContext, useState } from "react";
import DataRow from "./DataRow";
import HeadRow from "./HeadRow";
import styles from "./styles.module.css";
import { GoGraph } from "react-icons/go";
import { FiPercent } from "react-icons/fi";
import { BsFillTagFill } from "react-icons/bs";
import ModalComponent from "../../../molecules/Creator/Overview/ModalComponent";
import { SvgIcon } from "@material-ui/core";
import SVGIcon from "../../../atoms/SVGIcon";
import useSortingBy from "../../../CommonHooks/useSortingBy";
import { FaCheck, FaXmark } from "react-icons/fa6";
import Button from "../../../atoms/Button/Button";
import MultiInputWithChip from "../../../atoms/MultiInputWithChip/MultiInputWithChip";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import ReportContext from "../ReportContext";
import useHelperFunctions from "../../../helperFunctions";
import { Skeleton } from "@material-ui/lab";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const HeadColumns = [
    { title: "Terms", isSort: false, isMain: true },
    {
        title: "Frequency",
        isSort: "max_times",
        isMain: false,
    },
    {
        title: "Importance",
        isSort: "importance",
        isMain: false,
    },
    {
        title: "Heading",
        isSort: "heading_presence",
        isMain: false,
    },
];

const NlpTopics = ({ reportInfo, editor }) => {
    // console.log(list, "******************");

    const { setReportData, keyTerms } = useContext(ReportContext);
    const [keyword, setKeyword] = useState(keyTerms[0]);
    const [sortBy, setSortBy] = useState({ colID: null, asc: true });
    const [isDeleting, setIsDeleting] = useState(false);
    const [inputKeyTerms, setInputKeyTerms] = useState([]);
    const [keytermLoading, setKeytermLoading] = useState(false);
    const [customKeyTerm, setCustomKeyTerm] = useState(false);
    const dispatch = useDispatch();
    const { postData } = useHelperFunctions();
    const { mixpanelTrack } = useMixpanelHook();

    const updateKeyTermsList = async () => {
        if (inputKeyTerms.length) {
            const userAddedTerms = inputKeyTerms.map((ele) => {
                return { term: ele };
            });
            setKeytermLoading(true);
            const res = await postData({
                url: "/api/scrape/unwantedNLP",
                payload: {
                    report_id: reportInfo.report_id,
                    terms: [],
                    userAdded: userAddedTerms,
                },
            });
            setKeytermLoading(false);
            setCustomKeyTerm(false);
            setInputKeyTerms([]);
            if (res.status == 200) {
                setReportData((ps) => ({
                    ...ps,
                    keyTerms: res.data?.relevantTerms,
                }));
            }
            dispatch(
                displayAlert({
                    alertMessage: "Keyterm(s) have been added successfully!",
                    alertType: "success",
                }),
            );
        } else {
            return;
        }
    };

    const style = editor ? { width: "100%" } : {};

    const list = useSortingBy({
        items: keyTerms,
        asc: sortBy.asc,
        term: sortBy.colID == null ? "id" : HeadColumns[sortBy.colID].isSort,
    });

    return (
        <div className={styles.nlpContainer}>
            <div style={style} className={styles.tableContainer}>
                {keytermLoading ? (
                    <div>
                        <Skeleton height={40} />
                    </div>
                ) : customKeyTerm ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            paddingBlock: 10,
                        }}
                    >
                        <MultiInputWithChip
                            chips={inputKeyTerms}
                            setChips={setInputKeyTerms}
                            parentStyle={{
                                maxHeight: "70px",
                                overflow: "auto",
                            }}
                        />
                        <LightTooltip arrow title={"Update Key Terms"}>
                            <div>
                                <Button
                                    handler={updateKeyTermsList}
                                    text=""
                                    width={30}
                                    height={30}
                                    Icon={FaCheck}
                                    style={{
                                        background: "#014dc51a",
                                        color: "#014dc5",
                                    }}
                                />
                            </div>
                        </LightTooltip>
                        <LightTooltip arrow title={"Close"}>
                            <div>
                                <Button
                                    text=""
                                    width={30}
                                    height={30}
                                    Icon={FaXmark}
                                    style={{
                                        background: "rgb(255, 243, 242)",
                                        color: "rgb(252, 16, 36)",
                                    }}
                                    handler={() => {
                                        setCustomKeyTerm(false);
                                        setInputKeyTerms([]);
                                    }}
                                />
                            </div>
                        </LightTooltip>
                    </div>
                ) : (
                    <Button
                        style={{
                            background: "rgba(1,77,197,.1)",
                            color: "#014dc5",
                            fontSize: "12px",
                            marginTop: "10px",
                            fontWeight: 500,
                        }}
                        width={115}
                        height={36}
                        text="+ Add Keyterms"
                        handler={() => {
                            setCustomKeyTerm(true);
                            mixpanelTrack("Add Custom Key Terms CTA", {
                                action: "clicked",
                                section: "Report Details",
                            });
                        }}
                    />
                )}
                <table>
                    <thead className={styles.tableHead}>
                        <HeadRow
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            columns={HeadColumns}
                        />
                    </thead>

                    <tbody className={styles.tableBody}>
                        {list.length > 0 ? (
                            list.map((item) => (
                                <DataRow
                                    setKeyword={setKeyword}
                                    keyword={keyword}
                                    item={item}
                                    isDeleting={isDeleting}
                                    list={list}
                                    setIsDeleting={setIsDeleting}
                                    keytermLoading={keytermLoading}
                                />
                            ))
                        ) : (
                            <p>No Key terms Found.</p>
                        )}
                    </tbody>
                </table>
            </div>
            {!editor && keyword && (
                <ModalComponent
                    keyWord={keyword.keyword}
                    keyWordVariation={keyword.keyword_variations}
                    report_info={reportInfo}
                />
            )}
        </div>
    );
};

export default NlpTopics;
