import React, { useState } from "react";
import Button from "../../componentsV2/atoms/Button/Button";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { displayAlert } from "../../store/actions/alert";
import EmailSuggestion from "./EmailSuggestion";

function DeleteUser({ setActiveAction }) {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [random, setRandom] = useState(1);
    const [isSuccess, setIsSuccess] = useState(false);
    const { deleteData } = useHelperFunctions();
    const deleteUser = async () => {
        setLoading(true);
        setIsSuccess(false);
        const res = await deleteData({
            url: `/api/superAdmin/user/email/${encodeURIComponent(email)}`,
        });
        setLoading(false);
        if (res.status == 200) {
            setIsSuccess(true);
            setRandom(Math.random());
            displayAlert({
                alertMessage: res?.data?.message,
                alertType: "success",
            });
        } else {
            displayAlert({
                alertMessage: res?.data?.message,
                alertType: "error",
            });
        }
    };
    return (
        <div style={{ width: "60%", margin: "auto", marginTop: "100px" }}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <EmailSuggestion
                email={email}
                setEmail={setEmail}
                random={random}
            />
            <Button
                loading={loading}
                handler={() => deleteUser()}
                text="Delete User"
                props={{
                    style: {
                        marginTop: "20px",
                    },
                }}
            />
            {isSuccess && <p>User deleted successfully!!</p>}
        </div>
    );
}

export default DeleteUser;
