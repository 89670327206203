import React, { useRef } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import DocCard, { InProgressDoc } from "../../organisms/DocCard/DocCard";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";
import UserReportsHook from "../UserReports/UserReportsHook";
import styles from "./styles.module.css";
import { AiFillPlayCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import TagsFilter from "../../organisms/Account/TagsFilter";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { useState } from "react";
import { useEffect } from "react";
import NewUIDropDown, {
    TYPES,
} from "../../molecules/NewUIDropdown/NewUIDropDown";
import SVGIcon from "../../atoms/SVGIcon";
import { ClickAwayListener, Link, Portal } from "@material-ui/core";
import Button from "../../atoms/Button/Button";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Modal from "../../atoms/Modal/Modal";
import { useHistory } from "react-router-dom";
import KeywordInput from "../../molecules/Creator/KeywordInput/KeywordInput";
import SemrushKeywordsModal from "../UserReports/SemrushKeywordsModal";
import VideoModal from "../NewDashboard/VideoModal";
import { VscClose } from "react-icons/vsc";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import ModuleTemplate from "../../templates/ModuleTemplate/ModuleTemplate";

const sortByOptions = [
    { title: "Created At", id: 1 },
    { title: "Updated At", id: 2 },
    { title: "SEO Score", id: 3 },
];
function UserAudit({ bin, newDoc }) {
    const [sortBy, setSortBy] = useState(sortByOptions[0]);
    const [order, setOrder] = useState(0);
    const [modal, _setModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [filterTagList, setFilterTagsList] = useState([]);
    const [initiateClose, setInitiateClose] = useState(false);
    const goTo = useNavigation();
    const otherInfo = useSelector((store) => store.otherInfo);
    const [keywordResearcher, setKeywordResearcher] = useState(false);
    const userVideo = useSelector((state) => state.video.audit);
    useEffect(() => {}, [userVideo]);
    const {
        loading,
        docs,
        openDoc,
        getReports,
        setRequestData,
        requestData,
        hasMore,
        deleteReport,
        restoreReport,
        setLoading,
        makeReport,
        apiCalls,
        loadingCreation,
    } = UserReportsHook({ bin, doc_type: 2 });

    function setModal(param) {
        if (!param) getReports(requestData);
        _setModal(param);
    }

    useEffect(() => {
        console.log("ththththththththt", requestData);
        setLoading(true);
        setRequestData((ps) => ({
            ...ps,
            page: 1,
            created: sortBy.id == 1 ? true : false,
            updated: sortBy.id == 2 ? true : false,
            // grade: sortBy.id == 3 ? 1 : 0,
            seo_score: sortBy.id == 3 ? 1 : 0,
            is_deleted: bin ? true : false,
            order,
            tags: filterTagList.map((tag) => tag.id),
        }));
    }, [sortBy, bin, order, filterTagList]);
    const history = useHistory();
    const videoRef = useRef();

    useEffect(() => {
        if (newDoc) {
            setReportModal(true);
            goTo("AUDIT");
        }
    }, [newDoc]);

    useEffect(() => {
        if (otherInfo.userIntegrationInfo.length > 0) {
            for (let i = 0; i < otherInfo.userIntegrationInfo.length; i++) {
                if (otherInfo.userIntegrationInfo[i].app_id === 2) {
                    if (otherInfo.userIntegrationInfo[i].app_linked === 1) {
                        setKeywordResearcher(true);
                    }
                }
            }
        }
    }, [otherInfo]);

    return (
        <>
            <ModuleTemplate
                HeaderChild={
                    <>
                        <KeywordInput
                            loadingCreation={loadingCreation}
                            makeReport={makeReport}
                            docs={docs}
                            apiCalls={apiCalls}
                            setModal={setModal}
                            keywordResearcher={keywordResearcher}
                            audit={true}
                            buttonText={"Optimize"}
                        />
                    </>
                }
                sourceWhere={"audit"}
                initiateClose={initiateClose}
                userVideo={userVideo}
                setInitiateClose={setInitiateClose}
                heading="Content Optimizer"
                subHeading="Optimize content for Search with on-page & NLP recommendations"
                stickyHeaderChild={
                    <>
                        <div
                            style={{ padding: 20 }}
                            className={styles.rrFilters}
                        >
                            {!bin ? (
                                // <h4> All SEO Articles</h4>
                                <></>
                            ) : (
                                <h4 style={{ color: "red" }}>
                                    {" "}
                                    Deleted SEO Articles{" "}
                                </h4>
                            )}
                            <div
                                className={styles.rrFilters}
                                style={{ flexDirection: "row" }}
                            >
                                <div
                                    style={{ width: "280px" }}
                                    className={styles.search}
                                >
                                    <Textfield
                                        height={40}
                                        containerStyle={{ marginBottom: "0" }}
                                        props={{
                                            onChange: (e) => {
                                                const value =
                                                    e.target.value.trim();
                                                setRequestData((ps) => ({
                                                    ...ps,
                                                    search: value,
                                                    page: 1,
                                                }));
                                            },
                                            style: {
                                                borderRadius: 3,
                                                border: "solid 1px rgba(75, 75, 85, 0.16)",
                                                backgroundColor: "#fff",
                                                paddingLeft: "40px",
                                                borderRadius: 3,
                                            },
                                        }}
                                        placeholder="Search Optimizer Report"
                                        label=""
                                        Icon={() => (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/searchIcon.svg"
                                                }
                                            />
                                        )}
                                        iconStyle={{
                                            left: "10px",
                                            right: "unset",
                                            top: "20px",
                                        }}
                                    />
                                </div>
                                {bin ? (
                                    <BinTabs page="AUDIT" />
                                ) : (
                                    <>
                                        <TagsFilter
                                            setFilterTagsList={
                                                setFilterTagsList
                                            }
                                            style={{ with: "145px" }}
                                        />

                                        <NewUIDropDown
                                            options={sortByOptions}
                                            setOrder={setOrder}
                                            order={order}
                                            style={{
                                                textTransform: "capitalize",
                                                height: 40,
                                                color: "#4b4b55",
                                                width: 170,
                                            }}
                                            type={TYPES.SORT}
                                            selectedOption={sortBy}
                                            handler={setSortBy}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                }
                content={
                    <div>
                        {loading ? (
                            <>
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                            </>
                        ) : (
                            <InfiniteScroll
                                dataLength={docs.length}
                                next={() => {
                                    setRequestData((ps) => ({
                                        ...ps,
                                        page: requestData.page + 1,
                                    }));
                                }}
                                scrollableTarget="scrollableDiv"
                                hasMore={hasMore}
                                loader={
                                    <>
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                    </>
                                }
                            >
                                {
                                    <>
                                        <table>
                                            <tr className={styles.reportHeader}>
                                                <td style={{ width: "40%" }}>
                                                    <div>
                                                        <LightTooltip
                                                            arrow
                                                            title="Targeted Primary Keyword and URL"
                                                        >
                                                            <span>
                                                                Keyword(s) / URL
                                                            </span>
                                                        </LightTooltip>
                                                    </div>
                                                </td>

                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                        width: "10%",
                                                    }}
                                                >
                                                    <span>Created at</span>
                                                </td>

                                                {/* <td>
                                            <div>
                                                <span>Word Count </span> &nbsp;
                                                <SVGIcon
                                                    color="gray"
                                                    size={12}
                                                    tooltip={
                                                        "Document Word Count"
                                                    }
                                                    src={"/New UI/SVG/info.svg"}
                                                />
                                            </div>
                                        </td> */}

                                                <td
                                                    style={{
                                                        minWidth: "300px",
                                                        width: "25%",
                                                    }}
                                                >
                                                    <div>
                                                        <LightTooltip
                                                            arrow
                                                            title="SEO Score (0-100) indicates the optimization level of your content for search engines. A higher score increases the chances of achieving top search engine rankings."
                                                        >
                                                            <span>
                                                                SEO Score
                                                            </span>
                                                        </LightTooltip>
                                                    </div>
                                                </td>

                                                <td></td>
                                            </tr>

                                            {docs.map((doc) => {
                                                return (
                                                    <DocCard
                                                        doc={doc}
                                                        openDoc={(editor) =>
                                                            openDoc(
                                                                doc.report_id,
                                                                editor,
                                                                true,
                                                            )
                                                        }
                                                        seoDoc={true}
                                                        deleteDoc={deleteReport}
                                                        archived={bin}
                                                        restoreDoc={
                                                            restoreReport
                                                        }
                                                        key={doc.report_id}
                                                        auditMode={true}
                                                    />
                                                );
                                            })}
                                        </table>
                                        {docs.length == 0 && (
                                            <div className={styles.noItems}>
                                                {
                                                    <>
                                                        <SVGIcon
                                                            style={{
                                                                margin: "auto",
                                                            }}
                                                            src={
                                                                "/New UI/SVG/Emptydocument.svg"
                                                            }
                                                            size={300}
                                                        />
                                                        <h3
                                                            style={{
                                                                marginTop: -50,
                                                            }}
                                                        >
                                                            No Optimizer Reports
                                                            Found
                                                        </h3>
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </>
                                }
                            </InfiniteScroll>
                        )}
                    </div>
                }
            />

            {modal && (
                <Modal setModal={setModal}>
                    <SemrushKeywordsModal setModal={setModal} />
                </Modal>
            )}
        </>
    );
}

export default UserAudit;

export function BinTabs({ page = "AUDIT" }) {
    const goTo = useNavigation();

    const active = {
        borderRadius: 3,
        backgroundColor: "#1f4cbe",
        fontWeight: 400,
    };
    const inActive = {
        borderRadius: 3,
        backgroundColor: "white",
        color: "#6b6b6b",
        fontWeight: 400,
    };

    return (
        <div className={styles.binTabs}>
            <Button
                text="SEO Articles"
                width="150px"
                handler={() => {
                    // goTo("CREATOR_BIN");
                }}
                borderRadius="3"
                height={"100%"}
                style={page == "AUDIT" ? active : inActive}
            />
            <Button
                text="AI Docs"
                width="150px"
                handler={() => {
                    // goTo("SMART_DOCS_BIN");
                }}
                borderRadius="3"
                height={"100%"}
                style={page == "SMART_DOCS" ? active : inActive}
            />
        </div>
    );
}
