export const GSC_Countries: {
    flagCode?: string;
    value?: string;
    code?: string;
    label?: string;
    phone?: string;
    suggested?: boolean;
}[] = [
    {
        flagCode: "AD",
        label: "Andorra",
        phone: "376",
        code: "and",
        value: "AD",
    },
    {
        flagCode: "AE",
        label: "United Arab Emirates",
        phone: "971",
        code: "are",
        value: "AE",
    },
    {
        flagCode: "AR",
        label: "Argentina",
        phone: "54",
        code: "arg",
        value: "AR",
    },
    {
        flagCode: "AM",
        label: "Armenia",
        phone: "374",
        code: "arm",
        value: "AM",
    },
    {
        flagCode: "AS",
        label: "American Samoa",
        phone: "1-684",
        code: "asm",
        value: "AS",
    },
    {
        flagCode: "AQ",
        label: "Antarctica",
        phone: "672",
        code: "ata",
        value: "AQ",
    },
    {
        flagCode: "TF",
        label: "French Southern Territories",
        phone: "262",
        code: "atf",
        value: "TF",
    },
    {
        flagCode: "AG",
        label: "Antigua and Barbuda",
        phone: "1-268",
        code: "atg",
        value: "AG",
    },
    {
        flagCode: "AU",
        label: "Australia",
        phone: "61",
        suggested: true,
        code: "aus",
        value: "AU",
    },
    {
        flagCode: "AT",
        label: "Austria",
        phone: "43",
        code: "aut",
        value: "AT",
    },
    {
        flagCode: "AZ",
        label: "Azerbaijan",
        phone: "994",
        code: "aze",
        value: "AZ",
    },
    {
        flagCode: "BI",
        label: "Burundi",
        phone: "257",
        code: "bdi",
        value: "BI",
    },
    {
        flagCode: "BE",
        label: "Belgium",
        phone: "32",
        code: "bel",
        value: "BE",
    },
    {
        flagCode: "BJ",
        label: "Benin",
        phone: "229",
        code: "ben",
        value: "BJ",
    },
    {
        flagCode: "BQ",
        code: "bes",
        label: "Bonaire, Sint Eustatius and Saba",
        value: "BQ",
    },
    {
        flagCode: "BF",
        label: "Burkina Faso",
        phone: "226",
        code: "bfa",
        value: "BF",
    },
    {
        flagCode: "BD",
        label: "Bangladesh",
        phone: "880",
        code: "bgd",
        value: "BD",
    },
    {
        flagCode: "BG",
        label: "Bulgaria",
        phone: "359",
        code: "bgr",
        value: "BG",
    },
    {
        flagCode: "BH",
        label: "Bahrain",
        phone: "973",
        code: "bhr",
        value: "BH",
    },
    {
        flagCode: "BS",
        label: "Bahamas",
        phone: "1-242",
        code: "bhs",
        value: "BS",
    },
    {
        flagCode: "BA",
        label: "Bosnia and Herzegovina",
        phone: "387",
        code: "bih",
        value: "BA",
    },
    {
        flagCode: "BL",
        code: "blm",
        label: "Saint Barthélemy",
        value: "BL",
    },
    {
        flagCode: "BY",
        label: "Belarus",
        phone: "375",
        code: "blr",
        value: "BY",
    },
    {
        flagCode: "BZ",
        label: "Belize",
        phone: "501",
        code: "blz",
        value: "BZ",
    },
    {
        flagCode: "BM",
        label: "Bermuda",
        phone: "1-441",
        code: "bmu",
        value: "BM",
    },
    {
        flagCode: "BO",
        code: "bol",
        label: "Bolivia (Plurinational State of)",
        value: "BO",
    },
    {
        flagCode: "BR",
        label: "Brazil",
        phone: "55",
        code: "bra",
        value: "BR",
    },
    {
        flagCode: "BB",
        label: "Barbados",
        phone: "1-246",
        code: "brb",
        value: "BB",
    },
    {
        flagCode: "BN",
        label: "Brunei",
        phone: "673",
        code: "brn",
        value: "BN",
    },
    {
        flagCode: "BT",
        label: "Bhutan",
        phone: "975",
        code: "btn",
        value: "BT",
    },
    {
        flagCode: "BV",
        label: "Bouvet Island",
        phone: "47",
        code: "bvt",
        value: "BV",
    },
    {
        flagCode: "BW",
        label: "Botswana",
        phone: "267",
        code: "bwa",
        value: "BW",
    },
    {
        flagCode: "CF",
        label: "Central African Republic",
        phone: "236",
        code: "caf",
        value: "CF",
    },
    {
        flagCode: "CA",
        label: "Canada",
        phone: "1",
        suggested: true,
        code: "can",
        value: "CA",
    },
    {
        flagCode: "CC",
        label: "Cocos (Keeling) Islands",
        phone: "61",
        code: "cck",
        value: "CC",
    },
    {
        flagCode: "CH",
        label: "Switzerland",
        phone: "41",
        code: "che",
        value: "CH",
    },
    {
        flagCode: "CL",
        label: "Chile",
        phone: "56",
        code: "chl",
        value: "CL",
    },
    {
        flagCode: "CN",
        label: "China",
        phone: "86",
        code: "chn",
        value: "CN",
    },
    {
        flagCode: "CI",
        code: "civ",
        label: "Côte d'Ivoire",
        value: "CI",
    },
    {
        flagCode: "CM",
        label: "Cameroon",
        phone: "237",
        code: "cmr",
        value: "CM",
    },
    {
        flagCode: "CD",
        label: "Congo, Democratic Republic of the",
        phone: "243",
        code: "cod",
        value: "CD",
    },
    {
        flagCode: "CG",
        code: "cog",
        label: "Congo",
        value: "CG",
    },
    {
        flagCode: "CK",
        label: "Cook Islands",
        phone: "682",
        code: "cok",
        value: "CK",
    },
    {
        flagCode: "CO",
        label: "Colombia",
        phone: "57",
        code: "col",
        value: "CO",
    },
    {
        flagCode: "KM",
        label: "Comoros",
        phone: "269",
        code: "com",
        value: "KM",
    },
    {
        flagCode: "CV",
        code: "cpv",
        label: "Cabo Verde",
        value: "CV",
    },
    {
        flagCode: "CR",
        label: "Costa Rica",
        phone: "506",
        code: "cri",
        value: "CR",
    },
    {
        flagCode: "CU",
        label: "Cuba",
        phone: "53",
        code: "cub",
        value: "CU",
    },
    {
        flagCode: "CW",
        code: "cuw",
        label: "Curaçao",
        value: "CW",
    },
    {
        flagCode: "CX",
        label: "Christmas Island",
        phone: "61",
        code: "cxr",
        value: "CX",
    },
    {
        flagCode: "KY",
        label: "Cayman Islands",
        phone: "1-345",
        code: "cym",
        value: "KY",
    },
    {
        flagCode: "CY",
        label: "Cyprus",
        phone: "357",
        code: "cyp",
        value: "CY",
    },
    {
        flagCode: "CZ",
        code: "cze",
        label: "Czechia",
        value: "CZ",
    },
    {
        flagCode: "DE",
        label: "Germany",
        phone: "49",
        suggested: true,
        code: "deu",
        value: "DE",
    },
    {
        flagCode: "DJ",
        label: "Djibouti",
        phone: "253",
        code: "dji",
        value: "DJ",
    },
    {
        flagCode: "DM",
        label: "Dominica",
        phone: "1-767",
        code: "dma",
        value: "DM",
    },
    {
        flagCode: "DK",
        label: "Denmark",
        phone: "45",
        code: "dnk",
        value: "DK",
    },
    {
        flagCode: "DO",
        label: "Dominican Republic",
        phone: "1-809",
        code: "dom",
        value: "DO",
    },
    {
        flagCode: "DZ",
        label: "Algeria",
        phone: "213",
        code: "dza",
        value: "DZ",
    },
    {
        flagCode: "EC",
        label: "Ecuador",
        phone: "593",
        code: "ecu",
        value: "EC",
    },
    {
        flagCode: "EG",
        label: "Egypt",
        phone: "20",
        code: "egy",
        value: "EG",
    },
    {
        flagCode: "ER",
        label: "Eritrea",
        phone: "291",
        code: "eri",
        value: "ER",
    },
    {
        flagCode: "EH",
        label: "Western Sahara",
        phone: "212",
        code: "esh",
        value: "EH",
    },
    {
        flagCode: "ES",
        label: "Spain",
        phone: "34",
        code: "esp",
        value: "ES",
    },
    {
        flagCode: "EE",
        label: "Estonia",
        phone: "372",
        code: "est",
        value: "EE",
    },
    {
        flagCode: "ET",
        label: "Ethiopia",
        phone: "251",
        code: "eth",
        value: "ET",
    },
    {
        flagCode: "FI",
        label: "Finland",
        phone: "358",
        code: "fin",
        value: "FI",
    },
    {
        flagCode: "FJ",
        label: "Fiji",
        phone: "679",
        code: "fji",
        value: "FJ",
    },
    {
        flagCode: "FK",
        label: "Falkland Islands (Malvinas)",
        phone: "500",
        code: "flk",
        value: "FK",
    },
    {
        flagCode: "FR",
        label: "France",
        phone: "33",
        suggested: true,
        code: "fra",
        value: "FR",
    },
    {
        flagCode: "FO",
        label: "Faroe Islands",
        phone: "298",
        code: "fro",
        value: "FO",
    },
    {
        flagCode: "FM",
        code: "fsm",
        label: "Micronesia (Federated States of)",
        value: "FM",
    },
    {
        flagCode: "GA",
        label: "Gabon",
        phone: "241",
        code: "gab",
        value: "GA",
    },
    {
        flagCode: "GB",
        code: "gbr",
        label: "United Kingdom of Great Britain and Northern Ireland",
        value: "GB",
    },
    {
        flagCode: "GE",
        label: "Georgia",
        phone: "995",
        code: "geo",
        value: "GE",
    },
    {
        flagCode: "GG",
        label: "Guernsey",
        phone: "44",
        code: "ggy",
        value: "GG",
    },
    {
        flagCode: "GH",
        label: "Ghana",
        phone: "233",
        code: "gha",
        value: "GH",
    },
    {
        flagCode: "GI",
        label: "Gibraltar",
        phone: "350",
        code: "gib",
        value: "GI",
    },
    {
        flagCode: "GN",
        label: "Guinea",
        phone: "224",
        code: "gin",
        value: "GN",
    },
    {
        flagCode: "GP",
        label: "Guadeloupe",
        phone: "590",
        code: "glp",
        value: "GP",
    },
    {
        flagCode: "GM",
        label: "Gambia",
        phone: "220",
        code: "gmb",
        value: "GM",
    },
    {
        flagCode: "GW",
        label: "Guinea-Bissau",
        phone: "245",
        code: "gnb",
        value: "GW",
    },
    {
        flagCode: "GQ",
        label: "Equatorial Guinea",
        phone: "240",
        code: "gnq",
        value: "GQ",
    },
    {
        flagCode: "GR",
        label: "Greece",
        phone: "30",
        code: "grc",
        value: "GR",
    },
    {
        flagCode: "GD",
        label: "Grenada",
        phone: "1-473",
        code: "grd",
        value: "GD",
    },
    {
        flagCode: "GL",
        label: "Greenland",
        phone: "299",
        code: "grl",
        value: "GL",
    },
    {
        flagCode: "GT",
        label: "Guatemala",
        phone: "502",
        code: "gtm",
        value: "GT",
    },
    {
        flagCode: "GF",
        label: "French Guiana",
        phone: "594",
        code: "guf",
        value: "GF",
    },
    {
        flagCode: "GU",
        label: "Guam",
        phone: "1-671",
        code: "gum",
        value: "GU",
    },
    {
        flagCode: "GY",
        label: "Guyana",
        phone: "592",
        code: "guy",
        value: "GY",
    },
    {
        flagCode: "HK",
        label: "Hong Kong",
        phone: "852",
        code: "hkg",
        value: "HK",
    },
    {
        flagCode: "HM",
        label: "Heard Island and McDonald Islands",
        phone: "672",
        code: "hmd",
        value: "HM",
    },
    {
        flagCode: "HN",
        label: "Honduras",
        phone: "504",
        code: "hnd",
        value: "HN",
    },
    {
        flagCode: "HR",
        label: "Croatia",
        phone: "385",
        code: "hrv",
        value: "HR",
    },
    {
        flagCode: "HT",
        label: "Haiti",
        phone: "509",
        code: "hti",
        value: "HT",
    },
    {
        flagCode: "HU",
        label: "Hungary",
        phone: "36",
        code: "hun",
        value: "HU",
    },
    {
        flagCode: "ID",
        label: "Indonesia",
        phone: "62",
        code: "idn",
        value: "ID",
    },
    {
        flagCode: "IM",
        label: "Isle of Man",
        phone: "44",
        code: "imn",
        value: "IM",
    },
    {
        flagCode: "IN",
        label: "India",
        phone: "91",
        code: "ind",
        value: "IN",
    },
    {
        flagCode: "IO",
        label: "British Indian Ocean Territory",
        phone: "246",
        code: "iot",
        value: "IO",
    },
    {
        flagCode: "IE",
        label: "Ireland",
        phone: "353",
        code: "irl",
        value: "IE",
    },
    {
        flagCode: "IR",
        code: "irn",
        label: "Iran (Islamic Republic of)",
        value: "IR",
    },
    {
        flagCode: "IQ",
        label: "Iraq",
        phone: "964",
        code: "irq",
        value: "IQ",
    },
    {
        flagCode: "IS",
        label: "Iceland",
        phone: "354",
        code: "isl",
        value: "IS",
    },
    {
        flagCode: "IL",
        label: "Israel",
        phone: "972",
        code: "isr",
        value: "IL",
    },
    {
        flagCode: "IT",
        label: "Italy",
        phone: "39",
        code: "ita",
        value: "IT",
    },
    {
        flagCode: "JM",
        label: "Jamaica",
        phone: "1-876",
        code: "jam",
        value: "JM",
    },
    {
        flagCode: "JE",
        label: "Jersey",
        phone: "44",
        code: "jey",
        value: "JE",
    },
    {
        flagCode: "JO",
        label: "Jordan",
        phone: "962",
        code: "jor",
        value: "JO",
    },
    {
        flagCode: "JP",
        label: "Japan",
        phone: "81",
        suggested: true,
        code: "jpn",
        value: "JP",
    },
    {
        flagCode: "KZ",
        label: "Kazakhstan",
        phone: "7",
        code: "kaz",
        value: "KZ",
    },
    {
        flagCode: "KE",
        label: "Kenya",
        phone: "254",
        code: "ken",
        value: "KE",
    },
    {
        flagCode: "KG",
        label: "Kyrgyzstan",
        phone: "996",
        code: "kgz",
        value: "KG",
    },
    {
        flagCode: "KH",
        label: "Cambodia",
        phone: "855",
        code: "khm",
        value: "KH",
    },
    {
        flagCode: "KI",
        label: "Kiribati",
        phone: "686",
        code: "kir",
        value: "KI",
    },
    {
        flagCode: "KN",
        label: "Saint Kitts and Nevis",
        phone: "1-869",
        code: "kna",
        value: "KN",
    },
    {
        flagCode: "KR",
        label: "Korea, Republic of",
        phone: "82",
        code: "kor",
        value: "KR",
    },
    {
        flagCode: "KW",
        label: "Kuwait",
        phone: "965",
        code: "kwt",
        value: "KW",
    },
    {
        flagCode: "LA",
        label: "Lao People's Democratic Republic",
        phone: "856",
        code: "lao",
        value: "LA",
    },
    {
        flagCode: "LB",
        label: "Lebanon",
        phone: "961",
        code: "lbn",
        value: "LB",
    },
    {
        flagCode: "LR",
        label: "Liberia",
        phone: "231",
        code: "lbr",
        value: "LR",
    },
    {
        flagCode: "LY",
        label: "Libya",
        phone: "218",
        code: "lby",
        value: "LY",
    },
    {
        flagCode: "LC",
        label: "Saint Lucia",
        phone: "1-758",
        code: "lca",
        value: "LC",
    },
    {
        flagCode: "LI",
        label: "Liechtenstein",
        phone: "423",
        code: "lie",
        value: "LI",
    },
    {
        flagCode: "LK",
        label: "Sri Lanka",
        phone: "94",
        code: "lka",
        value: "LK",
    },
    {
        flagCode: "LS",
        label: "Lesotho",
        phone: "266",
        code: "lso",
        value: "LS",
    },
    {
        flagCode: "LT",
        label: "Lithuania",
        phone: "370",
        code: "ltu",
        value: "LT",
    },
    {
        flagCode: "LU",
        label: "Luxembourg",
        phone: "352",
        code: "lux",
        value: "LU",
    },
    {
        flagCode: "LV",
        label: "Latvia",
        phone: "371",
        code: "lva",
        value: "LV",
    },
    {
        flagCode: "MO",
        label: "Macao",
        phone: "853",
        code: "mac",
        value: "MO",
    },
    {
        flagCode: "MF",
        label: "Saint Martin (French part)",
        phone: "590",
        code: "maf",
        value: "MF",
    },
    {
        flagCode: "MA",
        label: "Morocco",
        phone: "212",
        code: "mar",
        value: "MA",
    },
    {
        flagCode: "MC",
        label: "Monaco",
        phone: "377",
        code: "mco",
        value: "MC",
    },
    {
        flagCode: "MD",
        label: "Moldova, Republic of",
        phone: "373",
        code: "mda",
        value: "MD",
    },
    {
        flagCode: "MG",
        label: "Madagascar",
        phone: "261",
        code: "mdg",
        value: "MG",
    },
    {
        flagCode: "MV",
        label: "Maldives",
        phone: "960",
        code: "mdv",
        value: "MV",
    },
    {
        flagCode: "MX",
        label: "Mexico",
        phone: "52",
        code: "mex",
        value: "MX",
    },
    {
        flagCode: "MH",
        label: "Marshall Islands",
        phone: "692",
        code: "mhl",
        value: "MH",
    },
    {
        flagCode: "MK",
        code: "mkd",
        label: "North Macedonia",
        value: "MK",
    },
    {
        flagCode: "ML",
        label: "Mali",
        phone: "223",
        code: "mli",
        value: "ML",
    },
    {
        flagCode: "MT",
        label: "Malta",
        phone: "356",
        code: "mlt",
        value: "MT",
    },
    {
        flagCode: "MM",
        label: "Myanmar",
        phone: "95",
        code: "mmr",
        value: "MM",
    },
    {
        flagCode: "ME",
        label: "Montenegro",
        phone: "382",
        code: "mne",
        value: "ME",
    },
    {
        flagCode: "MN",
        label: "Mongolia",
        phone: "976",
        code: "mng",
        value: "MN",
    },
    {
        flagCode: "MP",
        label: "Northern Mariana Islands",
        phone: "1-670",
        code: "mnp",
        value: "MP",
    },
    {
        flagCode: "MZ",
        label: "Mozambique",
        phone: "258",
        code: "moz",
        value: "MZ",
    },
    {
        flagCode: "MR",
        label: "Mauritania",
        phone: "222",
        code: "mrt",
        value: "MR",
    },
    {
        flagCode: "MS",
        label: "Montserrat",
        phone: "1-664",
        code: "msr",
        value: "MS",
    },
    {
        flagCode: "MQ",
        label: "Martinique",
        phone: "596",
        code: "mtq",
        value: "MQ",
    },
    {
        flagCode: "MU",
        label: "Mauritius",
        phone: "230",
        code: "mus",
        value: "MU",
    },
    {
        flagCode: "MW",
        label: "Malawi",
        phone: "265",
        code: "mwi",
        value: "MW",
    },
    {
        flagCode: "MY",
        label: "Malaysia",
        phone: "60",
        code: "mys",
        value: "MY",
    },
    {
        flagCode: "YT",
        label: "Mayotte",
        phone: "262",
        code: "myt",
        value: "YT",
    },
    {
        flagCode: "NA",
        label: "Namibia",
        phone: "264",
        code: "nam",
        value: "NA",
    },
    {
        flagCode: "NC",
        label: "New Caledonia",
        phone: "687",
        code: "ncl",
        value: "NC",
    },
    {
        flagCode: "NE",
        label: "Niger",
        phone: "227",
        code: "ner",
        value: "NE",
    },
    {
        flagCode: "NF",
        label: "Norfolk Island",
        phone: "672",
        code: "nfk",
        value: "NF",
    },
    {
        flagCode: "NG",
        label: "Nigeria",
        phone: "234",
        code: "nga",
        value: "NG",
    },
    {
        flagCode: "NI",
        label: "Nicaragua",
        phone: "505",
        code: "nic",
        value: "NI",
    },
    {
        flagCode: "NU",
        label: "Niue",
        phone: "683",
        code: "niu",
        value: "NU",
    },
    {
        flagCode: "NL",
        code: "nld",
        label: "Netherlands",
        value: "NL",
    },
    {
        flagCode: "NO",
        label: "Norway",
        phone: "47",
        code: "nor",
        value: "NO",
    },
    {
        flagCode: "NP",
        label: "Nepal",
        phone: "977",
        code: "npl",
        value: "NP",
    },
    {
        flagCode: "NR",
        label: "Nauru",
        phone: "674",
        code: "nru",
        value: "NR",
    },
    {
        flagCode: "NZ",
        label: "New Zealand",
        phone: "64",
        code: "nzl",
        value: "NZ",
    },
    {
        flagCode: "OM",
        label: "Oman",
        phone: "968",
        code: "omn",
        value: "OM",
    },
    {
        flagCode: "PK",
        label: "Pakistan",
        phone: "92",
        code: "pak",
        value: "PK",
    },
    {
        flagCode: "PA",
        label: "Panama",
        phone: "507",
        code: "pan",
        value: "PA",
    },
    {
        flagCode: "PN",
        label: "Pitcairn",
        phone: "870",
        code: "pcn",
        value: "PN",
    },
    {
        flagCode: "PE",
        label: "Peru",
        phone: "51",
        code: "per",
        value: "PE",
    },
    {
        flagCode: "PH",
        label: "Philippines",
        phone: "63",
        code: "phl",
        value: "PH",
    },
    {
        flagCode: "PW",
        label: "Palau",
        phone: "680",
        code: "plw",
        value: "PW",
    },
    {
        flagCode: "PG",
        label: "Papua New Guinea",
        phone: "675",
        code: "png",
        value: "PG",
    },
    {
        flagCode: "PL",
        label: "Poland",
        phone: "48",
        code: "pol",
        value: "PL",
    },
    {
        flagCode: "PR",
        label: "Puerto Rico",
        phone: "1",
        code: "pri",
        value: "PR",
    },
    {
        flagCode: "KP",
        code: "prk",
        label: "Korea (Democratic People's Republic of)",
        value: "KP",
    },
    {
        flagCode: "PT",
        label: "Portugal",
        phone: "351",
        code: "prt",
        value: "PT",
    },
    {
        flagCode: "PY",
        label: "Paraguay",
        phone: "595",
        code: "pry",
        value: "PY",
    },
    {
        flagCode: "PS",
        label: "Palestine, State of",
        phone: "970",
        code: "pse",
        value: "PS",
    },
    {
        flagCode: "PF",
        label: "French Polynesia",
        phone: "689",
        code: "pyf",
        value: "PF",
    },
    {
        flagCode: "QA",
        label: "Qatar",
        phone: "974",
        code: "qat",
        value: "QA",
    },
    {
        flagCode: "RE",
        code: "reu",
        label: "Réunion",
        value: "RE",
    },
    {
        flagCode: "RO",
        label: "Romania",
        phone: "40",
        code: "rou",
        value: "RO",
    },
    {
        flagCode: "RU",
        label: "Russia",
        phone: "7",
        code: "rus",
        value: "RU",
    },
    {
        flagCode: "RW",
        label: "Rwanda",
        phone: "250",
        code: "rwa",
        value: "RW",
    },
    {
        flagCode: "SA",
        label: "Saudi Arabia",
        phone: "966",
        code: "sau",
        value: "SA",
    },
    {
        flagCode: "SD",
        label: "Sudan",
        phone: "249",
        code: "sdn",
        value: "SD",
    },
    {
        flagCode: "SN",
        label: "Senegal",
        phone: "221",
        code: "sen",
        value: "SN",
    },
    {
        flagCode: "SG",
        label: "Singapore",
        phone: "65",
        code: "sgp",
        value: "SG",
    },
    {
        flagCode: "GS",
        label: "South Georgia and the South Sandwich Islands",
        phone: "500",
        code: "sgs",
        value: "GS",
    },
    {
        flagCode: "SH",
        code: "shn",
        label: "Saint Helena, Ascension and Tristan da Cunha",
        value: "SH",
    },
    {
        flagCode: "SJ",
        label: "Svalbard and Jan Mayen",
        phone: "47",
        code: "sjm",
        value: "SJ",
    },
    {
        flagCode: "SB",
        label: "Solomon Islands",
        phone: "677",
        code: "slb",
        value: "SB",
    },
    {
        flagCode: "SL",
        label: "Sierra Leone",
        phone: "232",
        code: "sle",
        value: "SL",
    },
    {
        flagCode: "SV",
        label: "El Salvador",
        phone: "503",
        code: "slv",
        value: "SV",
    },
    {
        flagCode: "SM",
        label: "San Marino",
        phone: "378",
        code: "smr",
        value: "SM",
    },
    {
        flagCode: "SO",
        label: "Somalia",
        phone: "252",
        code: "som",
        value: "SO",
    },
    {
        flagCode: "PM",
        label: "Saint Pierre and Miquelon",
        phone: "508",
        code: "spm",
        value: "PM",
    },
    {
        flagCode: "RS",
        label: "Serbia",
        phone: "381",
        code: "srb",
        value: "RS",
    },
    {
        flagCode: "SS",
        label: "South Sudan",
        phone: "211",
        code: "ssd",
        value: "SS",
    },
    {
        flagCode: "ST",
        label: "Sao Tome and Principe",
        phone: "239",
        code: "stp",
        value: "ST",
    },
    {
        flagCode: "SR",
        label: "Suriname",
        phone: "597",
        code: "sur",
        value: "SR",
    },
    {
        flagCode: "SK",
        label: "Slovakia",
        phone: "421",
        code: "svk",
        value: "SK",
    },
    {
        flagCode: "SI",
        label: "Slovenia",
        phone: "386",
        code: "svn",
        value: "SI",
    },
    {
        flagCode: "SE",
        label: "Sweden",
        phone: "46",
        code: "swe",
        value: "SE",
    },
    {
        flagCode: "SZ",
        code: "swz",
        label: "Eswatini",
        value: "SZ",
    },
    {
        flagCode: "SX",
        label: "Sint Maarten (Dutch part)",
        phone: "1-721",
        code: "sxm",
        value: "SX",
    },
    {
        flagCode: "SC",
        label: "Seychelles",
        phone: "248",
        code: "syc",
        value: "SC",
    },
    {
        flagCode: "SY",
        label: "Syrian Arab Republic",
        phone: "963",
        code: "syr",
        value: "SY",
    },
    {
        flagCode: "TC",
        label: "Turks and Caicos Islands",
        phone: "1-649",
        code: "tca",
        value: "TC",
    },
    {
        flagCode: "TD",
        label: "Chad",
        phone: "235",
        code: "tcd",
        value: "TD",
    },
    {
        flagCode: "TG",
        label: "Togo",
        phone: "228",
        code: "tgo",
        value: "TG",
    },
    {
        flagCode: "TH",
        label: "Thailand",
        phone: "66",
        code: "tha",
        value: "TH",
    },
    {
        flagCode: "TJ",
        label: "Tajikistan",
        phone: "992",
        code: "tjk",
        value: "TJ",
    },
    {
        flagCode: "TK",
        label: "Tokelau",
        phone: "690",
        code: "tkl",
        value: "TK",
    },
    {
        flagCode: "TM",
        label: "Turkmenistan",
        phone: "993",
        code: "tkm",
        value: "TM",
    },
    {
        flagCode: "TL",
        label: "Timor-Leste",
        phone: "670",
        code: "tls",
        value: "TL",
    },
    {
        flagCode: "TO",
        label: "Tonga",
        phone: "676",
        code: "ton",
        value: "TO",
    },
    {
        flagCode: "TT",
        label: "Trinidad and Tobago",
        phone: "1-868",
        code: "tto",
        value: "TT",
    },
    {
        flagCode: "TN",
        label: "Tunisia",
        phone: "216",
        code: "tun",
        value: "TN",
    },
    {
        flagCode: "TR",
        code: "tur",
        label: "Türkiye",
        value: "TR",
    },
    {
        flagCode: "TV",
        label: "Tuvalu",
        phone: "688",
        code: "tuv",
        value: "TV",
    },
    {
        flagCode: "TW",
        label: "Taiwan, Province of China",
        phone: "886",
        code: "twn",
        value: "TW",
    },
    {
        flagCode: "TZ",
        code: "tza",
        label: "Tanzania, United Republic of",
        value: "TZ",
    },
    {
        flagCode: "UG",
        label: "Uganda",
        phone: "256",
        code: "uga",
        value: "UG",
    },
    {
        flagCode: "UA",
        label: "Ukraine",
        phone: "380",
        code: "ukr",
        value: "UA",
    },
    {
        flagCode: "UM",
        code: "umi",
        label: "United States Minor Outlying Islands",
        value: "UM",
    },
    {
        flagCode: "UY",
        label: "Uruguay",
        phone: "598",
        code: "ury",
        value: "UY",
    },
    {
        flagCode: "US",
        code: "usa",
        label: "United States",
        value: "US",
    },
    {
        flagCode: "UZ",
        label: "Uzbekistan",
        phone: "998",
        code: "uzb",
        value: "UZ",
    },
    {
        flagCode: "VA",
        code: "vat",
        label: "Holy See",
        value: "VA",
    },
    {
        flagCode: "VC",
        label: "Saint Vincent and the Grenadines",
        phone: "1-784",
        code: "vct",
        value: "VC",
    },
    {
        flagCode: "VE",
        code: "ven",
        label: "Venezuela (Bolivarian Republic of)",
        value: "VE",
    },
    {
        flagCode: "VG",
        code: "vgb",
        label: "Virgin Islands (British)",
        value: "VG",
    },
    {
        flagCode: "VI",
        code: "vir",
        label: "Virgin Islands (U.S.)",
        value: "VI",
    },
    {
        flagCode: "VN",
        code: "vnm",
        label: "Viet Nam",
        value: "VN",
    },
    {
        flagCode: "VU",
        label: "Vanuatu",
        phone: "678",
        code: "vut",
        value: "VU",
    },
    {
        flagCode: "WF",
        label: "Wallis and Futuna",
        phone: "681",
        code: "wlf",
        value: "WF",
    },
    {
        flagCode: "WS",
        label: "Samoa",
        phone: "685",
        code: "wsm",
        value: "WS",
    },
    {
        flagCode: "YE",
        label: "Yemen",
        phone: "967",
        code: "yem",
        value: "YE",
    },
    {
        flagCode: "ZA",
        label: "South Africa",
        phone: "27",
        code: "zaf",
        value: "ZA",
    },
    {
        flagCode: "ZM",
        label: "Zambia",
        phone: "260",
        code: "zmb",
        value: "ZM",
    },
    {
        flagCode: "ZW",
        label: "Zimbabwe",
        phone: "263",
        code: "zwe",
        value: "ZW",
    },
];
