import { TextareaAutosize } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { VscClose } from "react-icons/vsc";
import Button from "../../componentsV2/atoms/Button/Button";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { jumbleArray } from "../../componentsV2/utlities";
import CrossSVG from "../variants/UI/crossSvg";
import {
    cancellationData as _cancellationData,
    OptionsTypes,
} from "./lib/data";
import styles from "./styles.module.css";

function CancellationFlow({
    setCancellation = () => {},
    handle = () => {},
    planId,
}) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [userResponse, setuserResponse] = useState({});
    const formRef = useRef(null);
    const softRef = useRef(null);
    const [cancellationData] = useState({
        opts: [
            ...jumbleArray(
                _cancellationData.opts.slice(
                    0,
                    _cancellationData.opts.length - 1,
                ),
            ),
            _cancellationData.opts[_cancellationData.opts.length - 1],
        ],
    });

    const [software, setSoftware] = useState([]);
    const { postData } = useHelperFunctions();

    const confirmationText = (
        <>
            <h3>Just making sure</h3>
            <p>
                On cancellation you will lose access to your current plan and
                moved to a free plan at the end of your subscription period.
            </p>
            <div className={styles.options}></div>
        </>
    );

    const submitResponse = (e) => {
        e.preventDefault();
        nextStep();
    };

    const neverMind = (e) => {
        setCancellation(false);
    };

    const nextStep = (next) => {
        if (
            step == 2 &&
            selectedOption != null &&
            OptionsTypes.contactUs ==
                cancellationData.opts[selectedOption].type &&
            !next
        ) {
            setCancellation(false);
            window.userengage && window.userengage("widget.show");
            window.userengage && window.userengage("widget.open");
            return;
        }

        let payload = {};
        if (selectedOption == null) {
            setSelectedOption(formRef.current.elements.option.value);
            setStep(2);
        } else if (Object.keys(userResponse).length) {
            terimateIt();
        } else {
            setStep(3);
            payload.options = cancellationData?.opts[selectedOption]?.title;
            payload.step = 3;
            payload.is_terminated = 1;
            payload.feedback = formRef.current.elements[
                cancellationData?.opts[selectedOption]?.title
            ]?.value
                ? formRef.current.elements[
                      cancellationData?.opts[selectedOption]?.title
                  ]?.value
                : "";
            payload.software_type = software;
            payload.plan_id = planId;
            setuserResponse({ ...payload });
            console.log("formdata payload", payload);
        }
    };

    async function terimateIt() {
        setLoading(true);
        const res = await postData({
            url: "/api/subscriptionChurn/createChurnInfo",
            payload: { ...userResponse },
        });
        if (res.status == 200) {
            handle(() => {
                setCancellation(false);
                setLoading(false);
            });
        }

        console.log("apicalls", { ...userResponse });
    }

    function handleSoftWareFeat(e) {
        console.log("payload", e.target.name);
        if (e.target.checked) {
            let checkboxes = document.getElementsByClassName(
                "cancellationCheckbox",
            );
            for (const e of checkboxes) {
                e.setCustomValidity("");
            }
        }

        let temp = [...software];
        if (temp.includes(e.target.name)) {
            temp = temp.filter((item) => item != e.target.name);
        } else {
            temp.push(e.target.name);
        }
        setSoftware(temp);
    }

    const getInput = (type) => {
        switch (type) {
            case OptionsTypes.input:
                return (
                    <TextareaAutosize
                        required
                        placeholder={
                            cancellationData.opts[selectedOption].placeholder
                        }
                        className={styles.textarea}
                        rowsMax={5}
                        rowsMin={3}
                        name={cancellationData.opts[selectedOption].title}
                    />
                );
            case OptionsTypes.none:
                return <></>;

            case OptionsTypes.inputCheck:
                return (
                    <>
                        {cancellationData.opts[selectedOption].options.map(
                            (option, i) => {
                                return (
                                    <>
                                        <div className={styles.option}>
                                            <input
                                                type="checkbox"
                                                className="cancellationCheckbox"
                                                key={i}
                                                required={
                                                    software.length > 0
                                                        ? false
                                                        : true
                                                }
                                                name={option}
                                                onInvalid={(e) =>
                                                    software.length > 0
                                                        ? e.target.setCustomValidity(
                                                              "",
                                                          )
                                                        : e.target.setCustomValidity(
                                                              "Please select at least one option.",
                                                          )
                                                }
                                                // onInput={(e) => }
                                                onChange={(e) => {
                                                    e.target.setCustomValidity(
                                                        "",
                                                    );
                                                    handleSoftWareFeat(e);
                                                }}
                                            />
                                            <label htmlFor="">{option}</label>
                                        </div>
                                    </>
                                );
                            },
                        )}
                        <TextareaAutosize
                            required
                            placeholder={
                                cancellationData.opts[selectedOption]
                                    .placeholder
                            }
                            className={styles.textarea}
                            rowsMax={5}
                            rowsMin={3}
                            name={cancellationData.opts[selectedOption].title}
                        />
                    </>
                );
        }
    };

    return (
        <Modal setModal={() => setCancellation(false)}>
            <form
                onSubmit={(e) => submitResponse(e)}
                className={styles.cancellationFlow}
                ref={formRef}
            >
                <span
                    onClick={() => setCancellation(false)}
                    className={styles.cross}
                >
                    <VscClose size={20} />
                </span>

                {selectedOption != null ? (
                    Object.keys(userResponse).length ? (
                        confirmationText
                    ) : (
                        <>
                            <div
                                className={`${styles.option} ${styles.selectedOption}`}
                            >
                                <input checked type="radio" name="" id="" />{" "}
                                <label htmlFor="">
                                    {
                                        cancellationData.opts[selectedOption]
                                            .title
                                    }
                                </label>{" "}
                            </div>
                            {
                                <p
                                    style={{
                                        color: "#3f5575",
                                        marginTop: 0,
                                        marginBottom: 7.5,
                                    }}
                                >
                                    {cancellationData.opts[selectedOption].text}
                                </p>
                            }
                            {getInput(
                                cancellationData.opts[selectedOption].type,
                            )}
                            <div className={styles.options}></div>
                        </>
                    )
                ) : (
                    <>
                        <h3>We are sorry to see you go.</h3>
                        <p>
                            Can you tell us why you’re discontinuing with our
                            platform.
                        </p>
                        <div className={styles.options}>
                            {cancellationData.opts.map((option, i) => (
                                <div className={styles.option}>
                                    <input
                                        required
                                        type="radio"
                                        name="option"
                                        id=""
                                        value={i}
                                    />{" "}
                                    <label htmlFor="">{option.title}</label>{" "}
                                </div>
                            ))}
                        </div>
                    </>
                )}

                <div className={styles.btns}>
                    <Button
                        disabled={loading}
                        width={"max-content"}
                        style={{
                            backgroundColor: "#e1e4ed",
                            color: "var(--secondary-color)",
                            borderRadius: 3,
                            fontSize: 14,
                            fontWeight: 400,
                            padding: "0 20px",
                        }}
                        type="reset"
                        handler={(e) => {
                            selectedOption != null &&
                            cancellationData.opts[selectedOption].type ==
                                OptionsTypes.contactUs &&
                            step == 2
                                ? nextStep(true)
                                : neverMind(e);
                        }}
                        text={
                            selectedOption != null &&
                            cancellationData.opts[selectedOption].type ==
                                OptionsTypes.contactUs &&
                            step == 2
                                ? "Next"
                                : "Nevermind"
                        }
                    ></Button>
                    <Button
                        width={"max-content"}
                        style={{
                            backgroundColor: "var(--primary-blue)",
                            borderRadius: 3,
                            fontSize: 14,
                            fontWeight: 400,
                            padding: "0 20px",
                            minWidth: 100,
                        }}
                        loading={loading}
                        text={
                            step == 3
                                ? "Confirm & Cancel"
                                : selectedOption != null &&
                                  OptionsTypes.contactUs ==
                                      cancellationData.opts[selectedOption].type
                                ? "Contact Us"
                                : "Next"
                        }
                    ></Button>
                </div>
            </form>
        </Modal>
    );
}

export default CancellationFlow;
