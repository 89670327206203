import React from "react";
import { FiExternalLink } from "react-icons/fi";
import styles from "./Overview.module.css";
import { Tooltip, withStyles } from "@material-ui/core";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

function CitationTableRow({ item }) {
  return (
    <tr>
      <LightTooltip title={item.citation_title} placement="bottom">
        <div className={styles.question1}>
          {item.citation_title
            ? item.citation_title.length <= 30
              ? item.citation_title
              : item.citation_title.slice(0, 30) + "..."
            : ""}
        </div>
      </LightTooltip>
      <div className={styles.link}>
        <a
          onClick={(e) => {
            e.preventDefault();
            window.open(
              item.citation_link,
              "",
              `left=100000,top=0,width=${
                window.screen.width / 2 < 600 ? window.screen.width / 2 : 600
              },height=${window.screen.height}`
            );
          }}
          target="_blank"
          href={item.citation_link}
        >
          {item.citation_link.length <= 30
            ? item.citation_link
            : item.citation_link.slice(0, 30) + "..."}
          <FiExternalLink className={styles.linkIcon} />
        </a>
      </div>
    </tr>
  );
}

export default CitationTableRow;
