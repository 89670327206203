import React, { useState, useEffect, useContext } from "react";
import { useRef } from "react";
// import { CiEdit } from "react-icons/ci";
import { BsExclamationOctagon } from "react-icons/bs";
import styles from "./styles.module.css";
import { v4 as uuidv4 } from "uuid";
import { useCallback } from "react";
import { useMemo } from "react";
import { SMART_EDITOR, scrapeImageSrc } from "./editorConstants";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReportContext from "../Research/ReportContext";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";

const allTags = ["h1", "h2", "h3", "h4", "h5", "h5", "h6", "p", "ul", "ol"];

const getTopRelatedToQuill = (element, attachedTo) => {
    let negHeight = 232;
    if (attachedTo == SMART_EDITOR) {
        negHeight = 232 - 110;
    }
    return (
        document.quill.editor.container.parentNode.scrollTop +
        element.getBoundingClientRect().y -
        negHeight
    );
};

const getLeftRelatedToQuill = (element, showOutline = false) => {
    //310 is the width of outline section of the editor
    if (showOutline) {
        return element.getBoundingClientRect().x - 310;
    }
    return element.getBoundingClientRect().x;
};

function AltTextImage({ editorChange, attachedTo, setUnsplash, showOutline }) {
    console.log("attachedTo", attachedTo);
    let listeners = useMemo(() => [], []);
    const [ele, setEle] = useState({});
    const { reportInfo } = useContext(ReportContext);

    useEffect(() => {
        const mutation = () => {
            listeners.forEach((element) => {
                try {
                    if (typeof element == "function") element();
                } catch (err) {
                    onRemoval(element.id);
                }
            });
        };

        document?.addEventListener("editorSelectionChange", mutation);

        const resizeObserver = new ResizeObserver(mutation);

        resizeObserver.observe(document?.quill?.editor?.root);

        return () => {
            document?.removeEventListener("editorSelectionChange", mutation);
            resizeObserver.disconnect();
        };
    }, []);

    const onRemoval = (id) => {
        for (let i = 0; i < listeners.length; i++) {
            if (listeners[i].id == id) {
                listeners.splice(i, 1);
                break;
            }
        }
        // listeners = listeners.filter((item) => item.id != id);
    };

    return (
        <>
            {(() => {
                let content = document?.quill?.editor?.root;
                const images = content?.querySelectorAll("img");

                return Array.from(images).map((img) => (
                    <UI
                        listeners={listeners}
                        key={img.getAttribute("data-id")}
                        imgRef={img}
                        attachedTo={attachedTo}
                        showOutline={showOutline}
                    />
                ));
            })()}
            {(() => {
                let content = document?.quill?.editor?.root;
                const videos = content?.querySelectorAll("iframe");
                return Array.from(videos).map((vid) => (
                    <VidTag
                        listeners={listeners}
                        vidRef={vid}
                        attachedTo={attachedTo}
                    />
                ));
            })()}
            {/* {(() => {
                let content = document?.quill?.editor?.root;
                const h2s = content?.querySelectorAll("h2");

                return reportInfo?.is_premium
                    ? Array.from(h2s).map((h2) => (
                          <ImagePlaceholder
                              listeners={listeners}
                              key={Math.random()}
                              h2Ref={h2}
                              showPlaceholder={
                                  h2.nextSibling?.getElementsByTagName("img")
                                      ?.length > 0
                                      ? false
                                      : true
                              }
                              attachedTo={attachedTo}
                              setUnsplash={setUnsplash}
                          />
                      ))
                    : "";
            })()} */}
            {/* {(() => {
                let content = document?.quill?.editor?.root;
                for (let i = 0; i < allTags.length; i++) {
                    const pTags = content?.getElementsByTagName(allTags[i]);

                    Array.from(pTags).map((ele) => {
                        ele.onmouseover = (e) => {
                            setEle(ele);
                        };
                        ele.onmouseleave = (e) => {
                            setEle(null);
                        };
                        // ele.addEventListener("mouseover", (e) => {
                        //     setEle(e.target);
                        // });
                        // ele.addEventListener("click", () => {
                        //     setEle(null);
                        // });
                    });
                }
            })()}
            {ele && <Tags listeners={listeners} elementRef={ele} tagName="p" />} */}
        </>
    );
}

// function ImagePlaceholder({
//     h2Ref,
//     listeners,
//     attachedTo,
//     setUnsplash,
//     showPlaceholder,
// }) {
//     const [show, setShow] = useState(showPlaceholder);
//     const styleRef = useRef({});
//     const ref1 = useRef();
//     const tagRef = useRef();

//     const setStyleObj = (style) => {
//         if (show && !h2Ref?.getAttribute("showImagePlaceholder")) {
//             tagRef.current.style.top =
//                 h2Ref.offsetTop + h2Ref.offsetHeight + "px";
//             if (styleRef.current) {
//                 if (typeof style == "function") {
//                     styleRef.current = style(styleRef.current);
//                 } else {
//                     styleRef.current = style;
//                 }
//                 const temp = { ...styleRef.current };
//                 temp.top = temp.top + "px";
//                 temp.left = temp.left + "px";
//                 ref1.current.style.top = temp.top;
//                 ref1.current.style.left = temp.left;
//             }
//         }
//     };

//     const deletePlaceholder = () => {
//         setShow(false);
//         h2Ref.style.marginBottom = "0px";
//         h2Ref.setAttribute("showImagePlaceholder", true);
//         // console.log(h2Ref.style.marginBottom, "h2Ref");
//     };

//     const uploadImage = () => {
//         const selection = window.getSelection();

//         if (selection.rangeCount > 0) {
//             selection.removeAllRanges();
//         }

//         const range = document.createRange();
//         range.selectNode(h2Ref);
//         selection.addRange(range);
//         const editorRange = document?.quill?.editor?.getSelection();
//         if (selection.rangeCount > 0) {
//             selection.removeAllRanges();
//         }
//         const index = editorRange?.index + editorRange?.length;
//         document.quill.editor.setSelection(index);
//         setUnsplash(true);
//     };

//     useEffect(() => {
//         const callback = () => {
//             const tempStyle = h2Ref;
//             setStyleObj({
//                 top: tempStyle?.offsetTop + tempStyle?.offsetHeight + 5,
//                 left: tempStyle?.offsetLeft,
//                 zIndex: 3,
//                 display: "flex",
//                 opacity: "1",
//             });
//             if (show && !h2Ref.getAttribute("showImagePlaceholder")) {
//                 h2Ref.style.marginBottom = "70px";
//             }
//         };
//         callback();
//         h2Ref.onload = callback;
//         callback.id = h2Ref.getAttribute("data-id");
//         listeners.push(callback);
//     }, []);

//     return show && !h2Ref.getAttribute("showImagePlaceholder") ? (
//         <>
//             <span
//                 style={{
//                     top: h2Ref.offsetTop + h2Ref.offsetHeight,
//                     left: 10,
//                     position: "absolute",
//                 }}
//                 className={styles.imgTag}
//                 ref={tagRef}
//             >
//                 img
//             </span>
//             <div
//                 className={`${styles.AltTextContainer} ${styles.imagePlaceholderWrapper}`}
//                 ref={ref1}
//                 style={styleRef.current}
//             >
//                 <div>
//                     <div
//                         className={styles.uploadImageText}
//                         onClick={() => uploadImage()}
//                     >
//                         Replace Image placeholder
//                     </div>
//                 </div>
//                 <RiDeleteBin6Line onClick={() => deletePlaceholder()} />
//             </div>
//         </>
//     ) : (
//         <></>
//     );
// }

function UI({ imgRef, listeners, attachedTo, showOutline }) {
    const [buttonText, setButtonText] = useState("Add image alt text");
    const [altText, setAltText] = useState(imgRef.alt);
    const [isAdding, setIsAdding] = useState(false);

    // const [styleObj, setStyleObj] = useState({});
    const ref1 = useRef();
    const tagRef = useRef();
    const ref = useRef();
    const styleRef = useRef({});
    const inputRef = useRef(null);

    const setStyleObj = (style) => {
        if (tagRef.current) {
            tagRef.current.style.top =
                getTopRelatedToQuill(imgRef, attachedTo) + "px";
            if (styleRef.current) {
                if (typeof style == "function") {
                    styleRef.current = style(styleRef.current);
                } else {
                    styleRef.current = style;
                }
                const temp = { ...styleRef.current };
                temp.top = temp.top + "px";
                temp.left = temp.left + "px";
                ref1.current.style.top = temp.top;
                ref1.current.style.left = temp.left;
            }
        }
    };

    useEffect(() => {
        const callback = () => {
            const tempStyle = imgRef;
            setStyleObj({
                top:
                    getTopRelatedToQuill(tempStyle, attachedTo) +
                    tempStyle?.offsetHeight,
                left: getLeftRelatedToQuill(tempStyle, showOutline),
                zIndex: 5,
                display: "flex",
                opacity: "1",
            });
        };

        const handleFallbackImage = (e) => {
            e.target.src = scrapeImageSrc;
            e.target.style.width = "min-content";
            e.target.style.height = "min-content";
        };
        callback();
        imgRef.onload = callback;
        imgRef.onerror = handleFallbackImage;
        callback.id = imgRef.getAttribute("data-id");
        listeners.push(callback);
    }, [showOutline]);

    useEffect(() => {
        if (isAdding) {
            inputRef.current ? inputRef.current.focus() : "";
        }
    }, [isAdding]);

    useEffect(() => {
        if (altText) {
            setButtonText("remove alt text");
        } else {
            setButtonText("Add image alt text");
        }
    }, [altText]);

    useEffect(() => {
        if (imgRef) {
            imgRef.alt = altText;
        }
    }, [altText, imgRef]);
    return attachedTo != SMART_EDITOR || imgRef.src != scrapeImageSrc ? (
        <>
            <span
                style={{
                    top: getTopRelatedToQuill(imgRef, attachedTo),
                    left: 10,
                    position: "absolute",
                    zIndex: 5,
                }}
                className={styles.imgTag}
                ref={tagRef}
            >
                img
            </span>

            <div
                ref={ref1}
                style={styleRef.current}
                className={styles.AltTextContainer}
                onMouseLeave={() => {
                    setStyleObj((ps) => ({ ...ps, opacity: "1" }));
                }}
                onMouseOver={() => {
                    setStyleObj((ps) => ({ ...ps, opacity: "1" }));
                }}
            >
                {isAdding ? (
                    <>
                        <input
                            value={altText}
                            id={"altInput"}
                            placeholder={"Type alt text..."}
                            ref={inputRef}
                            className={styles.inputAtlText}
                            onChange={(e) => setAltText(e.target.value)}
                            onBlur={() => {
                                setIsAdding(false);
                            }}
                            onKeyUp={(e) => {
                                if (e.key == "Enter") {
                                    setIsAdding(false);
                                }
                            }}
                        />
                        {/* <span
                            onClick={() => setIsAdding(!isAdding)}
                            className={styles.AltTextButton}
                        >
                            Save
                        </span> */}
                    </>
                ) : (
                    <>
                        {/* {!altText && (
                            <BsExclamationOctagon size={10} color="#fc1024" />
                        )} */}
                        {altText?.length > 0 && (
                            <LightTooltip
                                arrow
                                title={altText.length > 50 ? altText : ""}
                            >
                                <p
                                    onClick={() => {
                                        setIsAdding(!isAdding);
                                    }}
                                    className={styles.AtlTextMessage}
                                    style={{
                                        maxWidth: imgRef.width - 100,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {altText
                                        ? altText.length > 50
                                            ? altText.slice(0, 50) + "..."
                                            : altText
                                        : ""}
                                </p>
                            </LightTooltip>
                        )}
                        {imgRef.src == scrapeImageSrc ? (
                            <></>
                        ) : (
                            <span
                                onClick={() => {
                                    if (buttonText == "remove alt text") {
                                        setIsAdding(!isAdding);
                                        setAltText("");
                                    }
                                    setIsAdding(!isAdding);
                                }}
                                className={styles.AltTextButton}
                            >
                                {buttonText}
                            </span>
                        )}
                    </>
                )}
            </div>
        </>
    ) : (
        <></>
    );
}

function VidTag({ vidRef, listeners, attachedTo }) {
    const tagRef = useRef();

    useEffect(() => {
        const callback = () => {
            tagRef.current.style.top =
                getTopRelatedToQuill(vidRef, attachedTo) + "px";
        };
        // document?.quill?.editor.on("editor-change", callback);
        listeners.push(callback);
        return () => {
            document?.quill?.editor?.off("editor-change", callback);
        };
    }, []);

    return attachedTo != SMART_EDITOR ? (
        <span
            style={{
                top: getTopRelatedToQuill(vidRef, attachedTo),
                left: 10,
                position: "absolute",
            }}
            className={styles.imgTag}
            ref={tagRef}
        >
            vid
        </span>
    ) : (
        <></>
    );
}

export default AltTextImage;
