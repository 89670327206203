import React, { useState, useEffect, useContext } from "react";
import styles from "./CitationTableNew.module.css";
import { MdAddCircleOutline, MdExpandLess, MdExpandMore } from "react-icons/md";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import HighLighter from "../../../atoms/HightLighter/HighLighter";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import TextShortner from "../../../atoms/TextShortner/TextShortner";
import { FiExternalLink } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { EditorBriefContext } from "../../../organisms/Creator/EditorBrief/EditorBriefContext";
import { BRIEF_TYPES } from "../../../organisms/Creator/EditorBrief/useEditorBriefHook";

function CitationItem({
    data,
    expandAll = null,
    contentBrief,
    isSharedReport,
    access,
}) {
    const [expand, setExpand] = useState(false);
    const { dispatchEditorBrief, setSuccessMsg } =
        useContext(EditorBriefContext);

    useEffect(() => {
        if (expandAll != null) {
            if (expandAll == true) {
                setExpand(true);
            } else {
                setExpand(false);
            }
        }
        console.log(expandAll, "EXPANSION");
    }, [expandAll]);
    const textShortner = (data, limit) => {
        if (data.length <= limit) {
            return data;
        } else {
            return data.substring(0, limit) + "...";
        }
    };
    const LinkItem = ({ item, isSharedReport, access }) => {
        return (
            <tr className={styles.linkItemRow}>
                <td>
                    {item.anchor_text ? (
                        <LightTooltip
                            title={
                                item.anchor_text.length <= 30
                                    ? ""
                                    : item.anchor_text
                            }
                            placement="bottom"
                            arrow
                        >
                            <span> {textShortner(item.anchor_text, 30)}</span>
                        </LightTooltip>
                    ) : (
                        "-"
                    )}
                </td>
                <td>
                    <div className={styles.citationLink}>
                        {/* {new URL(item.citation_link).origin} */}
                        <div
                            className={
                                contentBrief &&
                                (!isSharedReport || (isSharedReport && access))
                                    ? styles.pointer
                                    : ""
                            }
                            onClick={() => {
                                if (
                                    contentBrief &&
                                    (!isSharedReport ||
                                        (isSharedReport && access))
                                ) {
                                    dispatchEditorBrief({
                                        type: BRIEF_TYPES.ADD_REFERENCE,
                                        data: {
                                            id: uuidv4(),
                                            data: item.citation_link,
                                            subData: "",
                                        },
                                    });
                                    setSuccessMsg({
                                        show: true,
                                        msg: item.citation_link,
                                    });
                                }
                            }}
                        >
                            <TextShortner
                                data={item.citation_link}
                                limit={30}
                                theme="dark"
                                style={{ minWidth: "80px", maxWidth: "120px" }}
                            />
                        </div>

                        <a href={item.citation_link} target="_blank">
                            <FiExternalLink size={14} color={"#29394a"} />
                        </a>
                    </div>
                </td>
                {/* <td>{item.source_url}</td> */}
                <td>
                    <TextShortner
                        data={item.source_url}
                        limit={30}
                        theme="dark"
                    />
                </td>
                <td className={styles.sourceRank}>
                    <div style={{ textAlign: "center" }}>
                        #{item.source_rank}
                    </div>
                </td>
            </tr>
        );
    };
    const LinkItemMobile = ({ item }) => {
        return (
            <div className={styles.mobileItem}>
                <div className={styles.mobileItemAnchor}>
                    {item.anchor_text ? (
                        <TextShortner data={item.anchor_text} limit={30} />
                    ) : (
                        "-"
                    )}
                </div>
                <div className={styles.citationLink}>
                    <TextShortner
                        data={item.citation_link}
                        limit={30}
                        theme="dark"
                    />
                </div>
                <div className={styles.mobileItemInfo}>
                    <div className={styles.sourceRank}>#{item.source_rank}</div>
                    <div className={styles.mobileItemSourceUrl}>
                        <a
                            href={item.source_url}
                            target="_blank"
                            className={styles.mobileItemSourceUrl}
                        >
                            <TextShortner
                                data={item.source_url}
                                limit={15}
                                theme="dark"
                            />
                        </a>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className={styles.citationItem}>
            <div
                className={styles.itemHeader}
                onClick={() => setExpand((prev) => !prev)}
            >
                <div className={styles.itemHeaderUrl}>
                    {/* <RiCheckboxBlankCircleLine
            color={"green"}
            className={styles.icon}
            size={18}
          />{" "} */}
                    <div className={styles.urlHost}>{data.url_host}</div>
                    <div className={styles.urlHostMobile}>
                        <TextShortner data={data.url_host} limit={20} />
                    </div>
                </div>
                <div className={styles.itemHeaderCount}>
                    <HighLighter
                        quality={data.countHost}
                        style={{
                            color: "#014dc5",
                            backgroundColor: "rgba(1,77,197,0.1)",
                        }}
                    />
                </div>
                <div
                    className={styles.expandableIcon}
                    // onClick={() => setExpand((prev) => !prev)}
                >
                    {expand ? (
                        <MdExpandLess color={"#29394a"} size={"25"} />
                    ) : (
                        <MdExpandMore color={"#29394a"} size={"25"} />
                    )}
                </div>
            </div>

            {expand ? (
                <>
                    <div
                        style={{
                            overflowX: "auto",
                        }}
                        className={styles.citationMainTable}
                    >
                        <table>
                            <tr className={styles.tableHeader}>
                                <th>ANCHOR TEXT</th>
                                <th>CITATION URL</th>
                                <th>SOURCE URL</th>
                                <th>SOURCE RANK</th>
                            </tr>
                            {data.links.map((link) => (
                                <LinkItem
                                    item={link}
                                    isSharedReport={isSharedReport}
                                    access={access}
                                />
                            ))}
                        </table>
                    </div>
                    <div className={styles.citationMainTableMobile}>
                        <div className={styles.mobileHeading}>
                            Anchor Text & Citation Url
                        </div>
                        <div className={styles.mobileItemContainer}>
                            {data.links.map((link) => (
                                <LinkItemMobile item={link} />
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

export default CitationItem;
