import mixpanel from "mixpanel-browser";
import React from "react";

export const useMixpanelCluster = () => {
    const kpTrackExportCta = ({
        isCompetitorGap = false,
        topicId = "",
        userDomain = "",
        otherDomains = [],
    }) => {
        let temp = {
            "Competitor Gap": isCompetitorGap ? "Yes" : "No",
            "Topic ID": topicId || "-",
            "User Domain": userDomain || "-",
            "Other Domains": otherDomains?.length > 0 ? otherDomains : [],
        };
        mixpanel.track("KP Export CTA", temp);
    };

    const kpTrackTabSwitch = ({ tabName = "" }) => {
        let temp = {
            "Tab Name": tabName || "-",
        };
        mixpanel.track("KP Tab Switch ", temp);
    };

    const kpTrackFilter = ({ type = "", value = "" }) => {
        let temp = {
            type: type || "-",
            value: value || "-",
        };
        mixpanel.track("KP Filter ", temp);
    };

    const kpTrackSortBy = ({ type = "", value = "", order = "" }) => {
        let temp = {
            type: type || "-",
            value: value || "-",
            order: order || "-",
        };
        mixpanel.track("KP SortBy", temp);
    };

    const kpUserFeedbackClosed = () => {
        mixpanel.track("KP Feedback Closed");
    };

    return {
        kpTrackExportCta,
        kpTrackTabSwitch,
        kpTrackFilter,
        kpTrackSortBy,
        kpUserFeedbackClosed,
    };
};
