import React, { useEffect, useState } from "react";
import styles from "./LmDashboardBar.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Button from "../../atoms/Button/Button";
import { BASE_URL, endPoints } from "../InterLinking/constants";
import useHelperFunctions from "../../helperFunctions";
import mixpanel from "mixpanel-browser";

const LmDashboardBar = ({
    gscConnectStatus = false,
    setIsLmConnected,
    isLmConnected,
}) => {
    const goTo = useNavigation();
    const { getData } = useHelperFunctions();
    useEffect(() => {
        getUserDomains();
    }, []);

    const getUserDomains = async () => {
        const res = await getData({
            completeUrl: BASE_URL + endPoints.userDomains,
            skipErrorMsg: true,
        });

        if (res.status === 200) {
            const validDomains = res.data.filter((domain) => domain);
            setIsLmConnected(!!validDomains.length);
        } else {
            setIsLmConnected(false);
        }
    };

    return isLmConnected ? (
        <></>
    ) : (
        <div
            className={`${styles.lmBarWrapper} ${
                gscConnectStatus && styles.shortBar
            }`}
            onClick={() => {
                mixpanel.track("LM Dashboard Bar", {
                    "CTA Type": "LM Card",
                    "CTA Used": "LM Card",
                });
                goTo("LINK_MANAGER");
            }}
        >
            <div className={`${styles.lmBar}`}>
                <div className={styles.leftGraffiti}>
                    <SVGIcon src={"/New UI/SVG/chain-element.svg"} size={90} />
                </div>
                <div className={styles.leftData}>
                    <div className={styles.title}>Link Manager</div>
                    <div className={styles.tagline}>
                        Your one stop solution for{" "}
                        <span className={styles.boldFont}>
                            internal linking
                            <span className={styles.iconWrap}>
                                <SVGIcon
                                    src={"/New UI/SVG/underline-gradient.svg"}
                                />
                            </span>
                        </span>
                    </div>
                </div>
                <div className={styles.rightData}>
                    <Button
                        text={"Try Now"}
                        handler={() => {
                            mixpanel.track("LM Dashboard Bar", {
                                "CTA Type": "Try Now",
                                "CTA Used": "Try Now",
                            });
                            goTo("LINK_MANAGER");
                        }}
                        height={28}
                        style={{
                            background: "white",
                            color: "rgba(31, 76, 190, 1)",
                            borderRadius: 8,
                            paddingInline: 10,
                            fontSize: 14,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default LmDashboardBar;
