import { TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { v4 } from "uuid";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import SVGIcon from "../../../atoms/SVGIcon";
import { showCounter } from "../../../atoms/TextArea/TextArea";
import ReportContext from "../../Research/ReportContext";
import { Outline } from "./classes";
import { QUESTIONS_EVENT_TYPES, cruiseMixPanelEvents } from "./cruiseConstant";
import styles from "./FAQ.module.css";
import { CruiseContext } from "./StepsWizard";
import { CRUISE_TYPES } from "./useCruiseHook";
import { VscTrash } from "react-icons/vsc";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { getKeytermVariations } from "../../../../utility/helperFun";
const maxLength = 120;
const FAQRow = ({
    faq,
    index,
    provided = { dragHandleProps: {} },
    onPressEnterInOutline,
}) => {
    const [faqInput, setFaqInput] = useState(faq.data);
    const [focus, setFocus] = useState(faq?.data?.length ? false : true);
    const dispatchStepsData = useContext(CruiseContext);
    const { mixpanelTrack } = useMixpanelHook();
    const { keyTerms, highlight_keyTerm, reportInfo } =
        useContext(ReportContext);
    function handleFaqEdit(text, onEnter = false) {
        setFocus(false);
        if (onEnter) {
            onPressEnterInOutline({
                data: "",
                isFaq: true,
                tag: Outline.tags.question,
                index,
            });
        }

        if (!text.trim()) {
            deleteFaq();
            return;
        }
        if (faq.data.trim() == text.trim()) return;
        setFaqInput(faqInput.trim());

        const newFaq = new Outline({
            type: Outline.type.question,

            id: v4(),
            data: text,
            tag: Outline.tags.question,
        });
        dispatchStepsData({
            type: CRUISE_TYPES.EDIT_OUTLINES,
            index: index,
            field: Outline.type.question,
            data: newFaq,
            id: faq.id,
        });
    }

    function deleteFaq() {
        dispatchStepsData({
            type: CRUISE_TYPES.DELETE_OUTLINES,
            index: index,
            field: Outline.type.question,
            id: faq.id,
            tag: Outline.tags.question,
        });
    }

    return (
        <div className={styles.faqRow}>
            <div className={styles.leftFAQ}>
                <div {...provided.dragHandleProps} className={styles.dragIcon}>
                    <SVGIcon
                        color="lightgray"
                        size={16}
                        src="/New UI/SVG/drag.svg"
                    />
                </div>
                <div
                    className={
                        styles.highlightWrapper +
                        " " +
                        (focus && styles.highlightWrapper__active)
                    }
                >
                    <div className={styles.faqType}>H3</div>
                    {/* {!isEdit ? <div className={styles.faqText} onDoubleClick={() => setIsEdit(true)}>{text}</div> : */}

                    {!focus ? (
                        <div
                            className={styles.text}
                            onClick={() => setFocus(true)}
                        >
                            {faqInput}
                        </div>
                    ) : (
                        <TextareaAutosize
                            disabled={false}
                            type="text"
                            rowsMin={1}
                            placeholder={"Add FAQ here"}
                            autoFocus={true}
                            maxLength={maxLength}
                            value={faqInput}
                            onChange={(e) => {
                                console.log("inputtt", e);
                                setFaqInput(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!e.target.value.length) {
                                        e.target.blur();
                                    } else {
                                        handleFaqEdit(e.target.value, true);
                                    }
                                }
                            }}
                            style={{
                                width: "100%",
                                ...(faqInput?.length >= maxLength
                                    ? { borderColor: "rgb(244 98 98 / 73%)" }
                                    : {}),
                            }}
                            // autoFocus={value == ""}
                            onBlur={(e) => handleFaqEdit(e.target.value)}
                            onFocus={(e) =>
                                e.target.setSelectionRange(
                                    e.target.value.length,
                                    e.target.value.length,
                                )
                            }
                        />
                    )}
                    {/* } */}
                </div>
            </div>
            <div
                className={styles.deletIcon}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    mixpanelTrack(cruiseMixPanelEvents.delete, {
                        "Screen Name": "Outline",
                        "Deleted Section": "FAQ",
                    });
                    deleteFaq();
                }}
            >
                <VscTrash color="#f94f2c" />
            </div>
            {focus &&
                showCounter(false, true, faqInput?.length, maxLength, {
                    textAlign: "right",
                    display: "block",
                    position: "absolute",
                    right: "40px",
                })}
        </div>
    );
};

export default FAQRow;
