import {
    Chart as ChartJS,
    LogarithmicScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    LinearScale,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import styles from "./ClusterReports.module.css";
import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    useDeferredValue,
    Suspense,
    ReactNode,
} from "react";
import Button from "../../../atoms/Button/Button";
import SVGIcon from "../../../atoms/SVGIcon";
import { Link } from "react-router-dom";
import screens from "../../../../screens.json";
import Morekeywords from "../../../atoms/MoreKeywords/Morekeywords";
import { RecommendedTag } from "../clusterKeywordPlanner/KeywordPlannerRow";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

ChartJS.register(
    LogarithmicScale,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
);

const ClusterGraph = function ({
    data,
    isClusterView,
    minRelevance,
    maxRelevance,
    makeSingleKeywordReport,
    _setIsClusterModal,
    setSelectedCluster,
    clusterInfo,
}: {
    data: Array<any>;
    isClusterView: boolean;
    minRelevance: number;
    maxRelevance: number;
    makeSingleKeywordReport: Function;
    _setIsClusterModal: Function;
    setSelectedCluster: Function;
    clusterInfo: object;
}) {
    const setToolTipStateRef = useRef((a: object) => {});
    const ref = useRef(null);

    const externalTooltipHandler = useCallback((props: any) => {
        console.log(props.tooltip);
        if (props.tooltip.opacity)
            setToolTipStateRef.current({
                x: props.tooltip.caretX - 15,
                y: props.tooltip.caretY - 12,
                dataPoints: props.tooltip.dataPoints,
            });
        // else {
        //     setToolTipStateRef.current({});
        // }
    }, []);

    const createReport = (item: any) => {
        if (isClusterView) {
            setSelectedCluster(item);
            _setIsClusterModal(true);
        } else makeSingleKeywordReport(item.id, item.keyword, item?.topic_id);
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,

                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value: any) {
                        return value >= 1000
                            ? Math.round(value / 1000) + "k"
                            : value;
                    },
                    color: "#b7b7b7",
                    maxTicksLimit: 5,
                },
                title: {
                    text: isClusterView
                        ? "Total Search Volume"
                        : "Search Volume(Monthly)",
                    display: true,
                    font: {
                        size: 12,
                        weight: 500,
                        family: "inter",
                    },
                },
                type: "logarithmic",
            },
            x: {
                title: {
                    text: isClusterView ? "Weighted Average CPC" : "CPC",
                    display: true,
                    font: {
                        size: 12,
                        weight: 500,
                        family: "inter",
                    },
                },
                ticks: {
                    color: "#b7b7b7",

                    autoSkip: true,
                    maxTicksLimit: 10,
                },
                type: "logarithmic",
            },
        },
        elements: {
            point: {
                radius: 5,
                hoverRadius: 6,
                hoverBackgroundColor: () => {},
                display: false,
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
            legend: {
                display: false,
            },
        },
    };

    const _data = {
        datasets: [
            {
                label: "",
                data: [...data].reverse().map((item) => ({
                    ...item,
                    x: isClusterView ? item.totalCPC : item.cpc,
                    y: isClusterView ? item.totalVol : item.volume,
                })),

                backgroundColor: (data: any) => {
                    const opacityRev =
                        (47 *
                            (maxRelevance -
                                ((isClusterView
                                    ? data?.raw?.relevancy
                                    : data?.raw?.similarity) || 0))) /
                        (maxRelevance - minRelevance || 1);
                    return data?.raw?.is_recommended_keyword == 1 ||
                        data?.raw?.is_recommended_cluster == 1
                        ? `hsl(280, 65%, ${43 + opacityRev}%)`
                        : `hsl(223, 72%, ${43 + opacityRev}%)`;
                },
                borderColor: "transparent",
                // hidden: true,
            },
        ],
    };
    if (data?.length)
        return (
            <div
                onMouseLeave={() => setToolTipStateRef.current({})}
                style={{ position: "relative" }}
                ref={ref}
            >
                <div className={styles.legend}>
                    <span>Low Relevancy</span>
                    <div className={styles.legendGradiant}></div>
                    <span>High Relevancy</span>
                    <div className={styles.legendRecommended}></div>
                    <span>Recommended</span>
                </div>

                <Scatter
                    className={styles.graph}
                    height={100}
                    width={500}
                    //@ts-ignore
                    options={options}
                    data={_data}
                />

                <CustomToolTip
                    setToolTipStateRef={setToolTipStateRef}
                    isClusterView={isClusterView}
                    createReport={createReport}
                    clusterInfo={clusterInfo}
                    containerRef={ref}
                />
            </div>
        );
    else return <></>;
};

const CustomToolTip = ({
    isClusterView,
    setToolTipStateRef,
    createReport,
    clusterInfo,
    containerRef,
}: {
    isClusterView: boolean;
    setToolTipStateRef: any;
    createReport: Function;
    clusterInfo: any;
    containerRef: any;
}) => {
    const [tooltipState, setToolTipState]: [
        tooltipState: any,
        setToolTipState: Function,
    ] = useState({});

    setToolTipStateRef.current = setToolTipState;

    const deferredTooltipState = useDeferredValue(tooltipState);

    if (deferredTooltipState?.dataPoints?.length == 1) {
        const item = deferredTooltipState.dataPoints[0].raw;

        if (isClusterView) {
            if (item.data?.length) {
                item.keyword = item.data[0].keyword;
            } else {
                return <></>;
            }
        }

        return (
            <ToolTipWrapper
                deferredTooltipState={deferredTooltipState}
                setToolTipState={setToolTipState}
                containerRef={containerRef}
            >
                <CustomToolTipBody
                    createReport={createReport}
                    isClusterView={isClusterView}
                    item={item}
                    clusterInfo={clusterInfo}
                />
            </ToolTipWrapper>
        );
    }
    if (deferredTooltipState?.dataPoints?.length > 1) {
        const items: Array<any> = deferredTooltipState.dataPoints;

        return (
            <ToolTipWrapper
                deferredTooltipState={deferredTooltipState}
                setToolTipState={setToolTipState}
                containerRef={containerRef}
            >
                {items.map((item) => {
                    item = item.raw;
                    if (isClusterView) {
                        if (item.data?.length) {
                            item.keyword = item.data[0].keyword;
                        } else {
                            return <></>;
                        }
                    }
                    return (
                        <CustomToolTipBody
                            isClusterView={isClusterView}
                            item={item}
                            createReport={createReport}
                            clusterInfo={clusterInfo}
                        />
                    );
                })}
            </ToolTipWrapper>
        );
    } else return <></>;
};

const CustomToolTipBody = ({
    isClusterView,
    item,
    createReport,
    clusterInfo,
}: {
    isClusterView: boolean;
    item: any;
    createReport: Function;
    clusterInfo: any;
}) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <h4>
                {item.is_recommended_keyword == 1 ||
                item.is_recommended_cluster ? (
                    <RecommendedTag />
                ) : (
                    ""
                )}
                {item.keyword}{" "}
                {isClusterView && (
                    <Morekeywords
                        keywords={item.data
                            .filter((i: any) => i.is_selected)
                            .map((i: any) => i.keyword)}
                        moreCount={
                            item.data
                                .filter((i: any) => i.is_selected)
                                .map((i: any) => i.keyword)?.length
                        }
                    />
                )}{" "}
            </h4>
            <section className={styles.tooltipItems}>
                <div>
                    {isClusterView
                        ? "Total Search Volume"
                        : "Search Volume(Monthly)"}
                    <span>{isClusterView ? item.totalVol : item.volume}</span>
                </div>
                <div>
                    {isClusterView ? "Weighted Avg. CPC" : "CPC"}
                    <span>
                        {Number(
                            isClusterView ? item.totalCPC : item.cpc,
                        ).toPrecision(3)}
                    </span>
                </div>
                <div>
                    Relevance{" "}
                    <span>
                        {Math.floor(
                            isClusterView ? item.relevancy : item.similarity,
                        )}
                        %
                    </span>
                </div>
            </section>
            {loading ||
            item?.report_id === 0 ||
            item.report_id === -1 ||
            item.report_id === null ? (
                <LightTooltip
                    arrow
                    title={
                        clusterInfo?.is_sample
                            ? "This is a sample cluster report. Please create a new Keyword Report to enable this feature."
                            : ""
                    }
                >
                    <div style={{ width: "100%" }}>
                        <Button
                            iconReverse={true}
                            Icon={() =>
                                item?.report_id == -1 ? (
                                    <></>
                                ) : (
                                    <SVGIcon
                                        size={14}
                                        src={"/New UI/SVG/doc.svg"}
                                    />
                                )
                            }
                            text={
                                item?.report_id === 0 || loading
                                    ? "Creating SEO Article..."
                                    : item?.report_id === -1
                                    ? "Failed"
                                    : "Create SEO Article"
                            }
                            loadingText={
                                item?.report_id == 0 || loading ? true : false
                            }
                            handler={() => {
                                if (item.report_id === null) {
                                    setLoading(true);
                                    createReport(item);
                                }
                            }}
                            style={{
                                fontSize: 12,
                                marginTop: 10,
                            }}
                            height={30}
                            disabled={clusterInfo.is_sample}
                        ></Button>
                    </div>
                </LightTooltip>
            ) : clusterInfo?.is_sample ? (
                ""
            ) : (
                <Link
                    target={"_blank"}
                    style={{
                        fontSize: 12,
                        marginTop: 10,
                        width: "100%",
                        minHeight: 30,
                    }}
                    className={styles.openLink}
                    to={screens.SEO_EDITOR + "/" + item?.report_id}
                >
                    <SVGIcon size={14} src={"/New UI/SVG/doc.svg"} />
                    &nbsp; Open SEO Article
                </Link>
            )}
        </>
    );
};

const ToolTipWrapper = ({
    children,
    deferredTooltipState,
    setToolTipState,
    containerRef,
}: {
    children: ReactNode;
    deferredTooltipState: any;
    setToolTipState: Function;
    containerRef: any;
}) => {
    return (
        <>
            <Suspense fallback={<></>}>
                <div
                    style={{
                        top: deferredTooltipState?.y + 55,
                        left:
                            deferredTooltipState?.x - 110 < 0
                                ? 0
                                : deferredTooltipState?.x - 110 >
                                  containerRef.current.offsetWidth - 225
                                ? containerRef.current.offsetWidth - 225
                                : deferredTooltipState?.x - 110,
                    }}
                    className={styles.tooltipContainer}
                    onMouseLeave={() => setToolTipState({})}
                >
                    <div className={styles.tooltip}>{children}</div>
                    <div
                        style={{
                            left:
                                deferredTooltipState?.x - 110 < 0
                                    ? 125 + (deferredTooltipState?.x - 110)
                                    : deferredTooltipState?.x - 110 >
                                      containerRef.current.offsetWidth - 225
                                    ? 125 -
                                      (containerRef.current.offsetWidth - 225) +
                                      (deferredTooltipState?.x - 110)
                                    : "50%",
                        }}
                        className={styles.tooltipArrow}
                    ></div>
                </div>
            </Suspense>
        </>
    );
};

export default ClusterGraph;
