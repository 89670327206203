import React, { useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import TextfieldV2 from "../../../atoms/CustomTextfield/Textfield";
import styles from "./KeywordInput.module.css";
import { ClickAwayListener, makeStyles, Popper } from "@material-ui/core";
import { thousands_separators } from "../../../../utility/validateFunctions";
import { useEffect } from "react";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
const useClasses = makeStyles(() => ({
    listbox: {
        maxHeight: "30vh",
    },
}));
function SemrushLocationList({
    location,
    setLocation,
    setCountry,
    priorityLocations,
    suggestedLocations,
    country,
}) {
    const [show, setShow] = useState(false);
    const ref = useRef();
    const classes = useClasses();
    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <div className={styles.locationWrapper}>
                <div
                    className={styles.locationLabel}
                    ref={ref}
                    onClick={() => setShow(!show)}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <SVGIcon
                            size={14}
                            src={"/New UI/SVG/locationPin.svg"}
                        />
                        &nbsp;&nbsp;
                        <span> {country.country}</span>
                    </div>
                    <SVGIcon
                        size={14}
                        src={"/New UI/SVG/chevronDown.svg"}
                        style={{
                            cursor: "pointer",
                        }}
                    />
                </div>
                {show && (
                    <Autocomplete
                        // onClose={() => setShow(false)}
                        classes={classes}
                        open={show}
                        size="small"
                        style={{
                            width: "100%",
                            background: "white",
                            paddingBottom: 10,
                        }}
                        inputValue={location}
                        className={styles.locationAuto}
                        freeSolo
                        options={
                            location.length === 0
                                ? priorityLocations
                                : suggestedLocations
                        }
                        onChange={(a, b) => {
                            setShow(false);
                            console.log("country set", b);
                            if (b)
                                setCountry({
                                    country: b.country,
                                    code: b.code,
                                });
                        }}
                        openOnFocus
                        autoHighlight
                        getOptionLabel={(option) => option.country}
                        renderOption={(option) => (
                            <React.Fragment>
                                <div style={{ width: "100%" }}>
                                    <div className={styles.location}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${
                                                option.code.toLowerCase() ===
                                                "uk"
                                                    ? "gb"
                                                    : option.code.toLowerCase()
                                            }.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt=""
                                        />{" "}
                                        <div>
                                            {option.country.replace(/,/g, ", ")}
                                        </div>
                                    </div>
                                    <div className={styles.locationReach}>
                                        {" "}
                                        <span className={styles.locationType}>
                                            Country
                                        </span>{" "}
                                        <span>
                                            {thousands_separators(option.reach)}
                                        </span>{" "}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <>
                                <div
                                    style={{ position: "relative" }}
                                    ref={params.InputProps.ref}
                                >
                                    <TextfieldV2
                                        iconStyle={{
                                            left: "10px",
                                            right: "unset",
                                        }}
                                        label=""
                                        autoFocus={true}
                                        placeholder="Search and Select Country"
                                        props={{
                                            style: {
                                                borderRadius: 3,

                                                paddingLeft: 34,
                                                paddingRight: 25,
                                                backgroundColor: "white",
                                            },
                                            ...params.inputProps,
                                            value: location,
                                            onChange: (e) =>
                                                setLocation(e.target.value),
                                        }}
                                        Icon={() => (
                                            <SVGIcon
                                                size={16}
                                                src={
                                                    "/New UI/SVG/searchIcon.svg"
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}
                    />
                )}
            </div>
        </ClickAwayListener>
    );
}

// const Option = ({ option, setCountry, setShow }) => (
//   <React.Fragment>
//     <div
//       onClick={() => {
//         setShow(false);
//         setCountry({ label: option.full_name });
//       }}
//       className={styles.locationOption}
//     >
//       <div className={styles.location}>
//         <img
//           loading="lazy"
//           width="20"
//           src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
//           srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
//           alt=""
//         />{" "}
//         <div>{option.full_name.replace(/,/g, ", ")}</div>
//       </div>
//       <div className={styles.locationReach}>
//         {" "}
//         <span className={styles.locationType}>{option.type}</span>{" "}
//         <span>{thousands_separators(option.reach)}</span>{" "}
//       </div>
//     </div>
//   </React.Fragment>
// );

export default SemrushLocationList;
