import {
    Tab,
    Tabs,
    Box,
    Typography,
    makeStyles,
    Tooltip,
    Snackbar,
    debounce,
} from "@material-ui/core";
import { Resizable } from "re-resizable";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useContext } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import SVGIcon from "../../../atoms/SVGIcon";
import EditorBrief from "../../../molecules/Creator/Overview/EditorBrief";
import ReportContext from "../../Research/ReportContext";
import PlagiarismCopyscape from "../../Research/ResearchGenerate/PlagiarismCopyscape";
import ResearchFacts from "../../Research/ResearchGenerate/ResearchFacts";
import VersionContainer from "../../Research/Versions/VersionContainer";
import WordpressApp from "../../Research/Wordpress/WordpressApp";
import Configure from "../Configure";
import {
    CLICKED_ON_VIEW_ONLY,
    getAllParasFromEditor,
    SMART_EDITOR,
} from "../editorConstants";
import AIAssistant from "./AIAssistant";
import FixItModule from "./FixItModule";
import OptimiserPro from "./OptimiserPro";
import SEOTools from "./SEOTools";
import useHelperFunctions from "../../../helperFunctions";

import styles from "./styles.module.css";
import { FixItSections } from "./fixitConstants";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import { Alert } from "@material-ui/lab";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";
import ShopifyApp from "../../Research/Shopify/ShopifyApp";
import ContentGap from "./ContentGap";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { PLANS_CATEGORIES } from "../../../../constants";
import Modal from "../../../atoms/Modal/Modal";
import Button from "../../../atoms/Button/Button";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={
                value == index
                    ? {
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          height: "0vh",
                          padding: index == 2 ? "10px" : "0px",
                      }
                    : {}
            }
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        value: index,
    };
}

function EditorAssistant({
    aiWordLimitSetting,
    setAiWordLimitSetting,
    reportInfo,
    metaData,
    setMetaData,
    docInfo,
    keyTerms,
    attachedTo,
    writingState,
    setSerp,
    serp,
    plagiarism,
    setPlagiarism,
    wordpress,
    shopifyApp,
    setShopifyApp,
    setWordpress,
    AISettings,
    toggleAISettings,
    versionModal,
    setVersionModal,
    selection,
    copyWriter,
    setCopyWriter,
    realtimeRating,
    saving,
    optimiserData,
    seoScore,
    optimiserLoading,
    isOptimiserEnable,
    setEnableFixItEditor = () => {},
    setEnableFixIt = () => {},
    enableFixIt,
    enableFixItEditor,
    fixItEditorRef = null,
    metaFixIt = {},
    setMetaFixIt = () => {},
    isFixItEnabled = {},
    setIsFixItEnabled = () => {},
    setUpgradeModal,
    setShowInterlinking,
    showInterlinking,
    setInterlinkingModal,
    isLoadingFixIt,
    setIsLoadingFixIt,
}) {
    const [width, setWidth] = useState(
        attachedTo == SMART_EDITOR ? "38%" : "30%",
    );
    const [value, setValue] = React.useState(
        attachedTo != SMART_EDITOR ? 0 : 3,
    );
    const { isFreeForever, plan_category, isLTD } = useUserAccessHook();
    const [internalModal, setInternalModal] = useState(false);
    const { mixpanelTrack } = useMixpanelHook();
    const goTo = useNavigation();
    const handleChange = (event, newValue) => {
        if (newValue == 3) {
            mixpanelTrack("Interlinking CTA", {
                action: "CTA clicked",
            });
            if (
                (plan_category == PLANS_CATEGORIES.PRO ||
                    plan_category == PLANS_CATEGORIES.PRO_MAX ||
                    isLTD) &&
                document.quill?.editor?.getText()?.length >= 500
            ) {
                if (reportInfo.interlinking_domain) {
                    setShowInterlinking(true);
                } else {
                    setInterlinkingModal(true);
                }
            } else {
                if (
                    plan_category != PLANS_CATEGORIES.PRO &&
                    plan_category != PLANS_CATEGORIES.PRO_MAX &&
                    !isLTD
                ) {
                    setInternalModal(true);
                } else if (document.quill?.editor?.getText()?.length < 500) {
                    dispatch(
                        displayAlert({
                            alertMessage:
                                "Please write a little more about the topic to enable this feature.",
                            alertType: "error",
                        }),
                    );
                }
                return;
            }
        } else {
            setValue(newValue);
        }
    };
    // const [isFixItEnabled, setIsFixItEnabled] = useState({
    //     isOn: false,
    //     section: {},
    //     sectionData: {},
    // });
    const dispatch = useDispatch();
    const { auditMode, peopleQuestions } = useContext(ReportContext);
    const [metaTitle, setMetaTitle] = useState(metaData?.title || "");
    const [highlight, setHighlight] = useState(false);
    const { postData } = useHelperFunctions();
    const [suggestions, setSuggestions] = useState([]);
    const [viewOnlyAlert, setViewOnlyAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState(
        "You are currently in view-only mode, kindly exit fix it mode to resume editing.",
    );
    const [interlinkingMixpanelData, setInterlinkingMixpanelData] = useState(
        {},
    );

    // useEffect(() => {
    //     if (showAlert) {
    //         setTimeout(() => {
    //             setShowAlert(false);
    //             setAlertMsg(
    //                 "You are currently in view-only mode, kindly exit fix it mode to resume editing.",
    //             );
    //         }, 4000);
    //     }
    // }, [showAlert]);

    // useEffect(() => {
    //     if (isFixItEnabled?.isKeytermEmpty) {
    //         setTimeout(() => {
    //             setIsFixItEnabled((ps) => ({
    //                 ...ps,
    //                 isKeytermEmpty: false,
    //                 errorMsg: "",
    //             }));
    //         }, 4000);
    //     }
    // }, [isFixItEnabled?.isKeytermEmpty]);

    useEffect(() => {
        const updateSessionStorage = () => {
            sessionStorage.setItem("newGap", true);
        };
        const callback = debounce(updateSessionStorage, 500);

        document.quill.editor.on("text-change", callback);
        document.addEventListener("openVersions", setVersionModal);
        sessionStorage.setItem("hitContentGap", "yes");
        return () => {
            document.quill.editor.off("text-change", callback);
            document.removeEventListener("openVersions", setVersionModal);
        };
    }, []);

    useEffect(() => {
        function offHighlight() {
            setHighlight(false);
        }
        if (copyWriter) {
            setValue(3);
            setHighlight(true);

            document.addEventListener("click", offHighlight);
        }
        return () => document.removeEventListener("click", offHighlight);
    }, [copyWriter]);

    useEffect(() => {
        const handleViewOnly = () => {
            setViewOnlyAlert(true);
            setTimeout(() => {
                setViewOnlyAlert(false);
            }, 4000);
        };
        document.addEventListener(CLICKED_ON_VIEW_ONLY, handleViewOnly);
        return () => {
            document.removeEventListener(CLICKED_ON_VIEW_ONLY, handleViewOnly);
        };
    }, []);

    // useEffect(() => {
    //     if (fixItEditorRef?.current?.innerHTML) {
    //         console.log("fixItEditorRef", fixItEditorRef?.current?.innerHTML);
    //         setIsLoadingFixIt(true);
    //         if (
    //             isFixItEnabled?.section?.panelName == FixItSections?.H1_HEADING
    //         ) {
    //             let tempSugg = [];
    //             let h1Arr = fixItEditorRef?.current?.getElementsByTagName("h1");
    //             if (h1Arr.length > 1) {
    //                 for (let i = 1; i < h1Arr.length; i++) {
    //                     if (h1Arr[i].innerText.trim().length > 0) {
    //                         tempSugg.push({
    //                             keyterm: "Remove Extra H1",
    //                             headings: h1Arr[i],
    //                             id: h1Arr[i].getAttribute("id"),
    //                         });
    //                     }
    //                 }
    //             }
    //             setSuggestions((ps) => [...ps, ...tempSugg]);
    //         }
    //         setIsLoadingFixIt(false);
    //     }
    // }, [fixItEditorRef?.current?.innerHTML, isFixItEnabled?.section]);

    const getMetaDesc = async () => {
        let tempMetaDesc = [];
        const res = await postData({
            url: "/api/fix-it/meta-description",
            payload: {
                report_id: reportInfo?.report_id,
                keyword: reportInfo?.title,
                metaTitle: metaData?.title || "",
                h1:
                    document?.quill?.editor?.root?.getElementsByTagName("h1")[0]
                        ?.innerText?.length > 5
                        ? document?.quill?.editor?.root?.getElementsByTagName(
                              "h1",
                          )[0]?.innerText
                        : "",
            },
        });
        if (res.status == 200) {
            res.data?.map((ele) => {
                tempMetaDesc.push(ele.content);
            });
        }
        return tempMetaDesc;
    };

    const getMetaTitles = async () => {
        let tempMetaTitles = [];
        const res = await postData({
            url: "/api/fix-it/meta-title",
            payload: {
                report_id: reportInfo?.report_id,
                keyword: reportInfo?.title || "",
                h1:
                    document?.quill?.editor?.root?.getElementsByTagName("h1")[0]
                        ?.innerText?.length > 5
                        ? document?.quill?.editor?.root?.getElementsByTagName(
                              "h1",
                          )[0]?.innerText
                        : "",
            },
        });
        if (res.status == 200) {
            res.data?.map((ele) => {
                tempMetaTitles.push(ele.content);
            });
        }
        return tempMetaTitles;
    };

    const getMetaUrls = async () => {
        let tempMetaUrls = [];
        const res = await postData({
            url: "/api/fix-it/url",
            payload: {
                report_id: reportInfo?.report_id,
                keyword: reportInfo?.title,
                h1:
                    document?.quill?.editor?.root?.getElementsByTagName("h1")[0]
                        ?.innerText?.length > 5
                        ? document?.quill?.editor?.root?.getElementsByTagName(
                              "h1",
                          )[0]?.innerText
                        : "",
                metaTitle: metaData?.title || "",
            },
        });
        if (res.status == 200) {
            res.data?.map((ele) => {
                tempMetaUrls.push(ele.content);
            });
        }
        return tempMetaUrls;
    };

    const handleFixItSection = useCallback(
        async (section = {}, sectionData = {}) => {
            if (isFreeForever) {
                setUpgradeModal(true);
                return;
            }
            if (!section?.isEnabled) {
                return;
            }
            setIsLoadingFixIt(true);
            setIsFixItEnabled({
                isOn: true,
                section,
                sectionData,
                isLoading: true,
            });
            if (section.panelName == FixItSections.FEATURED_SNIPPET) {
                let tempSugg = [];
                setEnableFixIt(true);
                if (sectionData.data) {
                    if (sectionData.data[0].status !== "GREEN") {
                        tempSugg = [
                            {
                                keyterm: "Questions",
                                question: peopleQuestions.filter(
                                    (ele) => ele.platform !== "Google",
                                ),
                            },
                        ];
                    }
                }
                setSuggestions(tempSugg);
            } else if (section.panelName == FixItSections.H1_HEADING) {
                let tempSugg = [];
                let tempHeadings = [];
                if (sectionData.data) {
                    if (!sectionData.status.GREEN) {
                        setEnableFixItEditor(true);
                        const res = await postData({
                            url: "/api/fix-it/h1",
                            payload: {
                                report_id: reportInfo?.report_id,
                                keyword: reportInfo.title,
                                metaTitle: metaData?.title,
                            },
                        });
                        if (res.status == 200) {
                            res.data.map((ele) =>
                                tempHeadings.push(ele.content),
                            );
                        }
                        let h1Arr =
                            fixItEditorRef?.current?.getElementsByTagName("h1");
                        let ptags =
                            fixItEditorRef?.current?.getElementsByTagName("p");
                        if (
                            sectionData?.data[1]?.status !== "GREEN" ||
                            sectionData?.data[2]?.status !== "GREEN" ||
                            sectionData?.data[3]?.status !== "GREEN"
                        ) {
                            if (
                                h1Arr?.length < 1 &&
                                ptags?.length < 1 &&
                                document?.quill?.editor?.root?.getElementsByTagName(
                                    "h1",
                                )[0]?.innerText == "\n"
                            ) {
                                let newH1Title = document.createElement("h1");
                                newH1Title.setAttribute("id", 1);
                                fixItEditorRef?.current?.insertBefore(
                                    newH1Title,
                                    fixItEditorRef?.current?.firstChild,
                                );
                            }
                            if (tempHeadings?.length > 0) {
                                tempSugg = [
                                    {
                                        keyterm:
                                            sectionData?.data[0]?.status ==
                                            "RED"
                                                ? "Add New H1"
                                                : "Replace Existing H1",
                                        headings: tempHeadings,
                                        id:
                                            h1Arr?.length > 0
                                                ? h1Arr[0].getAttribute("id")
                                                : "",
                                    },
                                ];
                            }
                        }

                        if (fixItEditorRef?.current?.innerHTML) {
                            let temp = [];
                            if (h1Arr.length > 1) {
                                for (let i = 1; i < h1Arr.length; i++) {
                                    if (h1Arr[i].innerText.trim().length > 0) {
                                        temp.push({
                                            keyterm: "Fix Extra H1",
                                            headings: h1Arr[i],
                                            id: h1Arr[i].getAttribute("id"),
                                        });
                                    }
                                }
                            }
                            setSuggestions([...tempSugg, ...temp]);
                        } else {
                            setSuggestions(tempSugg);
                            // setEnableFixItEditor(false);
                        }
                        setIsLoadingFixIt(false);
                    }
                }
            } else if (section.panelName == FixItSections.KEY_TERMS) {
                if (!sectionData?.data) {
                    setIsFixItEnabled({
                        isOn: false,
                        section,
                        sectionData,
                    });
                    setIsLoadingFixIt(false);
                    return;
                }
                setEnableFixItEditor(true);

                const allPara = getAllParasFromEditor(
                    document?.quill?.editor?.root?.innerHTML,
                );
                let unused = [];
                if (sectionData?.data[0]?.unusedKeyterms) {
                    unused = [...sectionData?.data[0]?.unusedKeyterms];
                }

                const res = await postData({
                    url: "/api/fix-it/key-term",
                    payload: {
                        dismissedKeywords: JSON.parse(
                            localStorage.getItem("dismissedKeywords"),
                        ),
                        paragraphs: [...allPara],
                        keyTerms: [...unused],
                        report_id: reportInfo.report_id,
                        title: document?.quill?.editor?.root?.getElementsByTagName(
                            "h1",
                        )[0]?.innerText
                            ? document?.quill?.editor?.root?.getElementsByTagName(
                                  "h1",
                              )[0]?.innerText
                            : metaData?.title
                            ? metaData?.title
                            : reportInfo?.title,
                    },
                });

                if (res.status == 200) {
                    setSuggestions(res?.data ? res?.data : []);
                } else {
                    setEnableFixItEditor(false);
                    setSuggestions([]);
                    setIsFixItEnabled({
                        isOn: false,
                        section,
                        sectionData,
                    });
                }
            } else if (section.panelName == FixItSections.META_TAGS) {
                setEnableFixItEditor(true);
                let tempSugg = [];
                if (sectionData.data) {
                    if (sectionData.data[0]?.status !== "GREEN") {
                        let newMetaTitles = await getMetaTitles(); // API call for getting meta title
                        if (newMetaTitles?.length > 0) {
                            tempSugg.push({
                                keyterm: "Add new Meta Title",
                                question: newMetaTitles,
                            });
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaTitle: newMetaTitles[0],
                            }));
                        }
                    } else if (sectionData.data[1]?.status !== "GREEN") {
                        let newMetaTitles = await getMetaTitles(); // API call for getting meta title
                        if (newMetaTitles?.length > 0) {
                            tempSugg.push({
                                keyterm: "Replace existing Meta Title",
                                question: newMetaTitles,
                            });
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaTitle: newMetaTitles[0],
                            }));
                        }
                    }

                    if (sectionData.data[2]?.status !== "GREEN") {
                        let newMetaDesc = await getMetaDesc(); // API call for getting meta desc
                        if (newMetaDesc?.length > 0) {
                            tempSugg.push({
                                keyterm: "Add new Meta Description",
                                question: newMetaDesc,
                            });
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaDesc: newMetaDesc[0],
                            }));
                        }
                    } else if (sectionData.data[3]?.status !== "GREEN") {
                        let newMetaDesc = await getMetaDesc(); // API call for getting meta desc
                        if (newMetaDesc?.length > 0) {
                            tempSugg.push({
                                keyterm: "Replace existing Meta Description",
                                question: newMetaDesc,
                            });
                            setMetaFixIt((ps) => ({
                                ...ps,
                                newMetaDesc: newMetaDesc[0],
                            }));
                        }
                    }
                    setSuggestions(tempSugg);
                }
            } else if (section.panelName == FixItSections.URL) {
                setEnableFixItEditor(true);
                let tempSugg = [];
                console.log(sectionData, "SectionData");
                if (sectionData?.status?.issues > 0) {
                    let newUrls = await getMetaUrls();
                    if (newUrls?.length > 0) {
                        tempSugg.push({
                            keyterm: metaData?.url
                                ? "Replace existing URL"
                                : "Add new URL",
                            question: newUrls,
                        });
                        setMetaFixIt((ps) => ({
                            ...ps,
                            newMetaUrl: newUrls[0],
                        }));
                    }
                }
                setSuggestions(tempSugg);
            } else if (section.panelName == FixItSections.LINKS) {
                setEnableFixIt(true);
                document.quill?.editor?.disable();
                const res = await postData({
                    url: "/api/interlinking/contentData",
                    payload: {
                        report_id: reportInfo.report_id,
                    },
                });
                if (res.status == 200) {
                    let tempSuggestions = [];
                    const editorUrls =
                        document.quill?.editor?.root?.getElementsByTagName("a");
                    const urlKeywords = [];
                    for (const ele of editorUrls) {
                        urlKeywords.push(ele.innerText?.toLowerCase());
                    }
                    const semanticSugg = res.data?.filter((ele) =>
                        ele.hasOwnProperty("score"),
                    );
                    res.data?.map((obj) => {
                        if (!urlKeywords.includes(obj.keyword?.toLowerCase())) {
                            tempSuggestions.push(obj);
                        }
                    });
                    setInterlinkingMixpanelData({
                        "Number of Suggestions(BE)": res.data?.length || 0,
                        "Number of Interlinks from Semantic":
                            semanticSugg?.length,
                        "Number of Interlinks from GSC":
                            res.data?.length - semanticSugg?.length,
                    });
                    setSuggestions(tempSuggestions);
                } else {
                    setSuggestions([]);
                    setIsFixItEnabled({
                        isOn: false,
                        section,
                        sectionData,
                    });
                    document.quill?.editor?.enable();
                }
            }
            setIsLoadingFixIt(false);
            setIsFixItEnabled((ps) => ({
                ...ps,
                isLoading: false,
            }));
        },
        [metaData, optimiserData],
    );

    // useEffect(() => {
    //   setCopyWriter(value);
    // }, [value]);

    const resizeRef = useRef();
    const useStylesTab = makeStyles((theme) => ({
        root: {
            backgroundColor: "#fff",
            color: "#1f1f1f",
            opacity: 1,
            width: 70,
            height: 48,
            minHeight: 30,
            textDecoration: "none",
            fontSize: 14,
            fontWeight: 400,
            textTransform: "none",
            borderBottom: "2px solid #c9e3ff",
            // marginTop: 15,
            // paddingTop: 23,
            // paddingBottom: 20,
            minWidth: 120,
            flexGrow: 1,
            ...(auditMode ? { maxWidth: 400 } : {}),
        },

        selected: {
            color: "#014dc5",
            fontWeight: "500",
            backgroundColor: "#fff",
            borderBottom: "4px solid #014dc5",
        },

        indicator: {
            display: "none",
        },
    }));
    const useStylesTab3 = makeStyles((theme) => ({
        root: {
            backgroundColor: "#fff",
            color: "#1f1f1f",
            opacity: 1,
            width: 70,
            height: 48,
            minHeight: 30,
            textDecoration: "none",
            fontSize: 14,
            fontWeight: 400,
            textTransform: "none",
            borderBottom: "2px solid #c9e3ff",
            // marginTop: 15,
            // paddingTop: 23,
            // paddingBottom: 20,
            minWidth: 135,
            flexGrow: 1,
            ...(auditMode ? { maxWidth: 400 } : {}),
        },

        selected: {
            color: "#014dc5",
            fontWeight: "500",
            backgroundColor: "#fff",
            borderBottom: "4px solid #014dc5",
        },

        indicator: {
            display: "none",
        },
    }));

    const useStylesSnackbar = makeStyles((theme) => ({
        root: {
            width: "fit-content",
            position: "fixed",
            top: "70px",
            left: "50%",
            transform: "translate(-50%, 0%)",
        },
    }));

    const useStylesTab1 = makeStyles((theme) => ({
        root: {
            backgroundColor: "#fff",
            color: "#1f1f1f",
            opacity: 1,
            width: 70,
            height: 48,
            minHeight: 30,
            textDecoration: "none",
            fontSize: 14,
            fontWeight: 400,
            textTransform: "none",
            borderBottom: "2px solid #c9e3ff",
            // marginTop: 15,
            // paddingBottom: 20,
            minWidth: 80,
            flexGrow: 1,
            ...(auditMode ? { maxWidth: 400 } : {}),
        },

        selected: {
            color: "#014dc5",
            fontWeight: "500",
            backgroundColor: "#fff",
            borderBottom: "4px solid #014dc5",
        },

        indicator: {
            display: "none",
        },
    }));

    const useStylesTab2 = makeStyles((theme) => ({
        root: {
            backgroundColor: "#fff",
            color: "#1f1f1f",
            opacity: 1,
            width: 70,
            height: 48,
            minHeight: 30,
            textDecoration: "none",
            fontSize: 14,
            fontWeight: 400,
            textTransform: "none",
            borderBottom: "2px solid #c9e3ff",
            // marginTop: 15,
            // paddingBottom: 20,
            minWidth: 90,
            flexGrow: 1,
            ...(auditMode ? { maxWidth: 400 } : {}),
        },

        selected: {
            color: "#014dc5",
            fontWeight: "500",
            backgroundColor: "#fff",
            borderBottom: "4px solid #014dc5",
        },

        indicator: {
            display: "none",
        },
    }));

    const useStylesTabs = makeStyles((theme) => ({
        root: {
            "& .MuiTabScrollButton-root.Mui-disabled": {
                display: "none",
            },
            width: "100%",
            padding: 0,
            margin: "auto",
            overflow: "auto",
            backgroundColor: theme.palette.background.paper,
        },
        indicator: {
            display: "none",
        },
    }));

    useEffect(() => {
        if (showInterlinking) {
            handleFixItSection(
                {
                    isEnabled: true,
                    tagline: "Improve SEO score by fixing Internal Linking",
                    panelName: "LINKS",
                },
                {},
            );
            setShowInterlinking(false);
        }
    }, [showInterlinking]);

    const classesTab = useStylesTab();
    const classesTab3 = useStylesTab3();
    const classesTab1 = useStylesTab1();
    const classesTab2 = useStylesTab2();
    const classesTabs = useStylesTabs();
    const classesSnackbar = useStylesSnackbar();

    const ToastBar = useMemo(() => {
        return (
            <Snackbar
                classes={classesSnackbar}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                open={viewOnlyAlert}
                autoHideDuration={6000}
                // onClose={() => setViewOnlyAlert(false)}
            >
                <Alert severity="error">
                    You are currently in view-only mode, kindly exit fix it mode
                    to resume editing.
                </Alert>
            </Snackbar>
        );
    }, [viewOnlyAlert]);

    return (
        <>
            <Resizable
                defaultSize={{
                    width: width,
                }}
                style={
                    highlight
                        ? {
                              zIndex: 15,
                              boxShadow: "0 0 100vw 100vw rgba(0,0,0,0.8)",
                              marginTop:
                                  enableFixIt || enableFixItEditor
                                      ? "0px"
                                      : "12px",
                          }
                        : {
                              marginTop:
                                  enableFixIt || enableFixItEditor
                                      ? "0px"
                                      : "12px",
                          }
                }
                onResizeStop={() => {
                    setWidth(resizeRef.current.state.width);
                }}
                enable={{ left: true }}
                className={styles.container}
                ref={resizeRef}
                handleWrapperStyle={{ position: "absolute", top: "50%" }}
                handleComponent={{
                    left: (
                        <div className={styles.resizeHandle}>
                            <div>
                                <SVGIcon
                                    size={14}
                                    transform="rotate(180deg)"
                                    src="/New UI/SVG/dragHandle.svg"
                                ></SVGIcon>
                            </div>
                        </div>
                    ),
                }}
                onDoubleClick={() => {
                    resizeRef.current.updateSize({
                        width: attachedTo == SMART_EDITOR ? "48%" : "34%",
                    });
                }}
            >
                {attachedTo == SMART_EDITOR ? (
                    <></>
                ) : isLoadingFixIt || isFixItEnabled.isOn ? (
                    <FixItModule
                        setIsFixItEnabled={setIsFixItEnabled}
                        setMetaFixIt={setMetaFixIt}
                        setEnableFixItEditor={setEnableFixItEditor}
                        setEnableFixIt={setEnableFixIt}
                        isOn={isFixItEnabled?.isOn}
                        section={isFixItEnabled?.section}
                        sectionData={isFixItEnabled?.sectionData}
                        seoScore={seoScore}
                        isLoadingFixIt={isLoadingFixIt}
                        setIsLoadingFixIt={setIsLoadingFixIt}
                        suggestions={suggestions}
                        reportInfo={reportInfo}
                        // realtimeRating={realtimeRating}
                        optimiserData={optimiserData}
                        // writingState={writingState}
                        setMetaData={setMetaData}
                        fixItEditorRef={fixItEditorRef}
                        optimiserLoading={optimiserLoading}
                        setSuggestions={setSuggestions}
                        realtimeRating={realtimeRating}
                        interlinkingMixpanelData={interlinkingMixpanelData}
                    />
                ) : (
                    <div className="rightPanel">
                        <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            classes={classesTabs}
                            value={value}
                            onChange={handleChange}
                        >
                            <Tab
                                disableRipple={true}
                                classes={classesTab2}
                                label="Optimize"
                                {...a11yProps(0)}
                            />
                            {!auditMode && (
                                <Tab
                                    disableRipple={true}
                                    classes={classesTab1}
                                    label="Brief"
                                    {...a11yProps(1)}
                                />
                            )}
                            {auditMode && (
                                <Tab
                                    disableRipple={true}
                                    classes={classesTab}
                                    onClick={() =>
                                        mixpanelTrack("Topic Gap", {
                                            action: "CTA",
                                        })
                                    }
                                    label={
                                        <div
                                            style={{
                                                whiteSpace: "nowrap",
                                                position: "relative",
                                            }}
                                        >
                                            Topic Gap
                                            {/* <div
                                                style={{
                                                    position: "absolute",
                                                    top: "-12px",
                                                    right: 0,
                                                    fontSize: 8,
                                                    background: "#FFBE5733",
                                                    color: "#FFBE57",
                                                    padding: "0px 5px",
                                                    borderRadius: "2px",
                                                    fontWeight: 500,
                                                    paddingTop: "1px",
                                                }}
                                            >
                                                BETA
                                            </div> */}
                                        </div>
                                    }
                                    {...a11yProps(4)}
                                />
                            )}
                            <Tab
                                disableRipple={true}
                                classes={classesTab2}
                                label="Research"
                                {...a11yProps(2)}
                            />
                            <Tab
                                disableRipple={true}
                                classes={classesTab3}
                                label={"Internal Linking"}
                                {...a11yProps(3)}
                            />
                        </Tabs>
                    </div>
                )}

                {/* <TabPanel value={value} index={1}>
                <AIAssistant
                    aiWordLimitSetting={aiWordLimitSetting}
                    setAiWordLimitSetting={setAiWordLimitSetting}
                    reportInfo={reportInfo}
                    keyTerms={keyTerms}
                    attachedTo={attachedTo}
                ></AIAssistant>
            </TabPanel> */}
                {!isFixItEnabled.isOn && (
                    <>
                        <TabPanel value={value} index={0}>
                            <OptimiserPro
                                reportInfo={reportInfo}
                                realtimeRating={realtimeRating}
                                optimiserData={optimiserData}
                                writingState={writingState}
                                seoScore={seoScore}
                                optimiserLoading={optimiserLoading}
                                isOptimiserEnable={isOptimiserEnable}
                                setIsFixItEnabled={setIsFixItEnabled}
                                handleFixItSection={handleFixItSection}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <EditorBrief list={keyTerms} editor={true} />
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <SEOTools
                                selection={selection}
                                reportInfo={reportInfo}
                                keyTerms={keyTerms}
                            ></SEOTools>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <ContentGap reportInfo={reportInfo}></ContentGap>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <AIAssistant
                                aiWordLimitSetting={aiWordLimitSetting}
                                setAiWordLimitSetting={setAiWordLimitSetting}
                                reportInfo={reportInfo}
                                keyTerms={keyTerms}
                                attachedTo={attachedTo}
                            ></AIAssistant>
                        </TabPanel>
                    </>
                )}

                {AISettings && (
                    <Configure
                        aiWordLimitSetting={aiWordLimitSetting}
                        setAiWordLimitSetting={setAiWordLimitSetting}
                        reportInfo={reportInfo}
                        keyTerms={keyTerms}
                        AISettings={AISettings}
                        toggleAISettings={toggleAISettings}
                        attachedTo={attachedTo}
                    />
                )}

                {serp && (
                    <ResearchFacts
                        setFactsButton={setSerp}
                        serp={serp}
                        writingState={writingState}
                        reportInfo={reportInfo}
                        aiWordLimitSetting={aiWordLimitSetting}
                    />
                )}

                {plagiarism && (
                    <PlagiarismCopyscape
                        setPlagiarism={setPlagiarism}
                        plagiarism={plagiarism}
                        writingState={writingState}
                        reportInfo={reportInfo}
                        aiWordLimitSetting={aiWordLimitSetting}
                        realtimeRating={realtimeRating}
                        saving={saving}
                    />
                )}

                {wordpress && (
                    <WordpressApp
                        setWordpress={setWordpress}
                        wordpress={wordpress}
                        reportInfo={reportInfo}
                        attachedTo={attachedTo}
                        realtimeRating={realtimeRating}
                        docInfo={docInfo}
                        metaData={metaData}
                    />
                )}

                {shopifyApp && (
                    <ShopifyApp
                        setShopifyApp={setShopifyApp}
                        shopifyApp={shopifyApp}
                        reportInfo={reportInfo}
                        attachedTo={attachedTo}
                        realtimeRating={realtimeRating}
                        docInfo={docInfo}
                        metaData={metaData}
                    />
                )}

                {!reportInfo?.is_sample && versionModal && (
                    <VersionContainer
                        versionModal={versionModal}
                        setVersionModal={setVersionModal}
                    />
                )}
                {internalModal && (
                    <Modal setModal={setInternalModal}>
                        <div className={styles.internalModal}>
                            <h2>Upgrade Plan</h2>
                            <p>
                                This feature is only available on higher plans.
                                Kindly upgrade to use this feature.
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: 10,
                                }}
                            >
                                <Button
                                    handler={() => {
                                        setInternalModal(false);
                                    }}
                                    text="Cancel"
                                    secondary={true}
                                />
                                <Button
                                    handler={() => {
                                        goTo("BILLING_PLANS");
                                    }}
                                    text="Upgrade"
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            </Resizable>
            {(enableFixIt || enableFixItEditor) && viewOnlyAlert && ToastBar}
        </>
    );
}

export default EditorAssistant;
