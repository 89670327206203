import React from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { Waypoint } from "react-waypoint";

function scroll(ele, left) {
  ele.scrollBy(left, 0);
}

export default function XOverflow({ children, className }) {
  const containerRef = useRef();
  const childRef = useRef();

  const [handle, setHandles] = useState({ left: false, right: true });
  console.log(handle);

  return (
    <div className={styles.App}>
      {handle.left && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            scroll(containerRef.current, -400);
          }}
          className={styles.left}
        >
          <AiFillCaretLeft size={20} />
        </div>
      )}

      <div ref={containerRef} className={styles.scrollContainer}>
        <Waypoint
          horizontal={true}
          onLeave={() => setHandles((ps) => ({ ...ps, left: true }))}
          onEnter={() => setHandles((ps) => ({ ...ps, left: false }))}
        />
        <div
          ref={childRef}
          style={{ transition: "300ms all  ", width: "fit-content" }}
          className={className}
        >
          {children}
        </div>
        <Waypoint
          horizontal={true}
          onLeave={() => setHandles((ps) => ({ ...ps, right: true }))}
          onEnter={() => setHandles((ps) => ({ ...ps, right: false }))}
        >
          <div>&nbsp;</div>
        </Waypoint>
      </div>

      {handle.right && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            scroll(containerRef.current, 400);
          }}
          className={styles.right}
        >
          <AiFillCaretRight size={20} />
        </div>
      )}
    </div>
  );
}
