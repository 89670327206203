import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import SVGIcon from "../../../atoms/SVGIcon";
import ReportContext from "../../Research/ReportContext";
import styles from "./StatsEditorAssistant.module.css";
import { debounceWrapper, parseForRegex } from "../../../../utility/helperFun";
import { Tooltip, withStyles } from "@material-ui/core";
import WriteToEditor from "../Toolbar/WriteToEditor";
import EditorContext from "../EditorContext";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { BsEyeFill } from "react-icons/bs";

const LightTooltip1 = withStyles((theme) => ({
    arrow: {
        color: "white",
        scale: 1.5,
    },
    tooltip: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: " 2px 1px 12px rgba(14, 14, 15, 0.23)",
        fontSize: 11,
        maxWidth: "400px",
        position: "relative",
        top: "1px",
        border: "solid 1px rgba(172, 179, 191, 0.28)",
        borderRadius: "6px",
    },
}))(Tooltip);

function HoverComponent({ item = {} }) {
    return (
        <div className={styles.hoverContainer}>
            <div className={styles.hoverContainer_head}>Source</div>
            <div className={styles.head}>
                <span className={styles.outlineRank}>#{item.rank}</span>
                <div className={styles.outlineTitle}>
                    <a
                        className={styles.url}
                        href={item.url}
                        target="_blank"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {item.url}
                    </a>

                    {item.url_title}
                    <div className={styles.para}>
                        <CustomHighlighter
                            highlightClassName={styles.sourceHighlight}
                            searchWords={[
                                new RegExp(parseForRegex(item.statistic), "g"),
                            ]}
                            textToHighlight={item.paragraph}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const StatsEditorAssistant = ({}) => {
    const { stats } = useContext(ReportContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [statsData, setStatsData] = useState([]);
    const [clickToAdd, setClickToAdd] = useState(false);
    const { writingState } = useContext(EditorContext);
    const [tooltipIndex, setTooltipIndex] = useState(-1);
    const { mpTrackStatsOpened, mpTrackStatsUsed } = useMixpanelHook();

    useEffect(() => {
        mpTrackStatsOpened({ screen: "Statistics Tab (Create Content)" });
    }, []);
    useEffect(() => {
        let timer = null;
        if (clickToAdd) {
            timer = setTimeout(() => {
                setClickToAdd(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [clickToAdd]);

    useEffect(() => {
        if (!search.length) {
            setStatsData(stats);
        }
        if (search.length) {
            if (search.length) {
                const searchRegEx = new RegExp(
                    parseForRegex(search)
                        .toLocaleLowerCase()
                        .split(" ")
                        .join("|"),
                    "g",
                );
                setStatsData(
                    stats.filter((stat) => {
                        if (
                            stat.statistic.toLowerCase().match(searchRegEx)
                                ?.length > 0
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                );
            }
        }
    }, [search.length]);

    const addToEditor = useCallback(
        (str) => {
            mpTrackStatsUsed({
                cta: "Click to Add",
            });
            if (writingState.state) return;
            WriteToEditor({
                data: !document.quill.editor.getText(
                    document.quill.editor.cursor,
                    1,
                )
                    ? str
                    : document.quill.editor.getText(
                          document.quill.editor.cursor,
                          1,
                      ) != "\n"
                    ? "\n" + str
                    : str,
                type: "text",
                length: str.length + 1,
                source: "user",
            });
            setClickToAdd(true);
        },
        [writingState.state],
    );
    let timeoutRef = useRef(null);

    return (
        <div className={styles.parentContainer}>
            <div className={styles.topSection}>
                <Textfield
                    customClass={styles.searchOutlines}
                    height={38}
                    label={""}
                    placeholder={"Search Statistics"}
                    containerStyle={{
                        padding: "0px",
                        margin: "0px",
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "transparent",
                    }}
                    props={{
                        onChange: (e) => {
                            const value = e.target.value.trim();
                            debounceWrapper(
                                () => setSearch(value),
                                500,
                                timeoutRef,
                            );
                        },
                        style: {
                            borderRadius: "2px",
                            border: "solid 1px rgba(75, 75, 85, 0.16)",
                            backgroundColor: "#fff",
                            paddingLeft: "40px",
                            borderRadius: "0",
                        },
                    }}
                    Icon={() => <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />}
                    iconStyle={{
                        left: "10px",
                        right: "unset",
                        top: "20px",
                    }}
                />
            </div>
            <div className={styles.bottomSection}>
                {statsData.length ? (
                    <div className={styles.statsContainer}>
                        {statsData.map((statItem, j) => {
                            return (
                                <Stat
                                    statItem={statItem}
                                    addToEditor={addToEditor}
                                    search={search}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div className={styles.emptyStats}>No Statistics Found</div>
                )}
            </div>
        </div>
    );
};

const Stat = React.memo(({ statItem, addToEditor, search }) => {
    const [tooltip, setTooltip] = useState(false);

    return (
        <LightTooltip1
            placement={"left"}
            interactive
            arrow={true}
            title={
                <HoverComponent
                    item={{
                        ...statItem,
                    }}
                />
            }
            open={tooltip}
        >
            <div
                onClick={() => {
                    addToEditor(statItem.statistic);
                }}
                className={styles.statRow}
                key={statItem.id}
            >
                <SVGIcon
                    src={"/New UI/SVG/RxDotFilled.svg"}
                    size={16}
                    color={"#1950BE"}
                />
                <span className={styles.Stat__Text}>
                    <CustomHighlighter
                        highlightClassName={
                            search
                                ? styles.highlightOutlineSearch
                                : styles.highlightOutlineKeyTerm
                        }
                        searchWords={[
                            new RegExp(
                                parseForRegex(search)
                                    .split(" ")
                                    .sort((a, b) => b.length - a.length)
                                    .join("|"),
                                "g",
                            ),
                        ]}
                        textToHighlight={statItem.statistic}
                    />
                </span>
                <div className={styles.btnContainer}>
                    <div className={styles.eyeIcon}>
                        <Tooltip
                            title=""
                            onOpen={() => setTooltip(true)}
                            onClose={() => setTooltip(false)}
                            key={statItem.id}
                        >
                            <div className={styles.eyeIcon}>
                                <BsEyeFill color="var(--primary-blue)" />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </LightTooltip1>
    );
});

export default StatsEditorAssistant;
