export const data = {
    steps: {
        1: "https://storage.googleapis.com/scalenut/04++AI+Templates.json", //ai temp
        2: "https://assets4.lottiefiles.com/private_files/lf30_gzsqaquv.json",
        3: "https://assets4.lottiefiles.com/private_files/lf30_85uavdoq.json",
        4: "https://storage.googleapis.com/scalenut/webflow assests/01++Cruise+Mode.json", //cruise
        5: "https://assets4.lottiefiles.com/private_files/lf30_4zotwalc.json",
        6: "https://storage.googleapis.com/scalenut/02++Keyword+Planner.json", //key word
        7: "https://storage.googleapis.com/scalenut/03++Content+Optimizer.json", //optimizer
    },
};

export const AI_BAR_FOCUS = "aiBar";
export const CREATE_REPORT_FOCUS = "createReport";
export const ALL_GENERATE_FOCUS = "allGenerate";
export const GRADE_RATING_FOCUS = "gradeRating";
export const SEO_BLOG = "seo_blog";
export const TOPIC_CLUSTER = "topic_cluster";

export const defaultValue = {
    state: {
        currentStep: null,
        currentStepCompleted: false,
        onBoardingCompleted: false,
        focusOn: null,
        sampleData: { seo: [], clusters: [], audit: [] },
        referCode: "",
        chapters: [],
        questions: [],
        onBoardingStep: 0,
        onBoardingStatus: 1,
    },
    setState: () => {},
};
