import React from "react";
import { useState } from "react";
import { getTime } from "../../../../utility/validateFunctions";
import styles from "./Favourite.module.css";
import FavouriteModal from "./FavouriteModal";

function FavouriteCard({ onClick, data }) {
    const [localData, setLocalData] = useState(data);

    return (
        <div
            onClick={() => {
                onClick(localData, setLocalData);
            }}
            className={styles.cardContainer}
        >
            <div className={styles.card + " " + styles.small}></div>
            <div className={styles.card + " " + styles.smaller}></div>
            <div className={styles.card}>
                <div className={styles.cardHeadingContainer}>
                    <h5 className={styles.cardHeading}>
                        {data[0].useCaseName.split("_").join(" ")}
                    </h5>
                    <img
                        width={20}
                        height={20}
                        src={
                            data[0].useCaseIcon
                                ? data[0].useCaseIcon
                                : "https://storage.googleapis.com/scalenut/Icons/SVG/Landing+Page.svg"
                        }
                        alt=""
                    />
                </div>
                <div className={styles.cardContent}>{`${data[0].text.substring(
                    0,
                    150,
                )} ...`}</div>
            </div>
            <span className={styles.savedCopies}>
                {data.length} {data.length > 1 ? "copies" : "copy"} saved{" "}
                {getTime(data[0].created_at)}
            </span>
        </div>
    );
}

export default FavouriteCard;
