import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./GscLoginFlow.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import {
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    makeStyles,
    withStyles,
} from "@material-ui/core";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import useGscHook, { SelectedDomain } from "./useGscHook";
import { GSC_Countries } from "./GscCountries";
import ReactSelect from "../../organisms/Research/Wordpress/ReactSelect";
import useHelperFunctions from "../../helperFunctions";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { setUserGscInfo } from "../../../store/actions/userGscInfo";
import { displayAlert } from "../../../store/actions/alert";
import Modal from "../../atoms/Modal/Modal";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import { useMixpanelTA } from "./useMixpanelTA";

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
        },
        color: "#ACB3BF",
        marginRight: "-10px",
    },
    active: {
        color: "#014DC5",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

const _LightTooltip = withStyles((theme) => ({
    tooltip: {
        width: "100%",
        maxWidth: "100% !important",
    },
}))(LightTooltip);

const FormControlLabelCustom = makeStyles((theme) => ({
    root: {
        padding: "0px 2px 0px 10px",
    },
}));

const customStyles = {
    // valueContainer: (base: any) => ({
    //     ...base,
    //     flexWrap: "no-wrap",
    //     maxWidth: "80%",
    //     overflow: "hidden",
    // }),
    control: (base: any) => ({
        ...base,
        minHeight: 52,
        border: "1px solid #e1e4ed",
        boxShadow: "0px 2px 1px rgba(225, 228, 237, 0.4)",
        "&:hover": {
            borderColor: "#e1e4ed !important",
        },
    }),
    menu: (base: any) => ({
        ...base,
        zIndex: 100,
    }),
    menuList: (styles: any) => ({
        ...styles,
        maxHeight: "200px",
        fontSize: "14px",
    }),
    indicatorSeparator: (styles: any) => ({
        ...styles,
        display: "none",
    }),
    placeholder: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: "#3f5575",
            fontWeight: "400",
            fontSize: "14px",
        };
    },
};

const CustomStepLabel = withStyles((theme) => ({
    root: {
        "& .MuiStepLabel-active": {
            color: "linear-gradient(0deg, rgba(237, 241, 249, 0.50) 0%, rgba(237, 241, 249, 0.50) 100%), #FFF",
            fontSize: "14px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
        },

        "& .MuiStepLabel-completed": {
            color: "rgba(29,193,152,1)",
            fontSize: "14px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
        },
    },
    label: {
        color: theme.palette.text.secondary,
    },
}))(StepLabel);

const CustomStepConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 15,
        left: "calc(-49% + 15px)", // Adjusted value
        right: "calc(49% + 16px)",
    },

    active: {
        "& $line": {
            borderColor: "rgba(42,66,102,1);",
            borderTopWidth: "3px",
        },
        left: "calc(-49% + 13px)", // Adjusted value
        right: "calc(49% + 16px)",
    },
    completed: {
        "& $line": {
            borderColor: "rgba(29,193,152,1)",
            borderTopWidth: "3px",
        },
        left: "calc(-49% + 8px)", // Adjusted value
        right: "calc(49% + 14px)",
    },
    line: {
        borderTopStyle: "solid",
        borderTopWidth: "3px",
    },
}))(StepConnector);

const CustomStepIcon = (props: any) => {
    return (
        <span
            className={
                (props.completed
                    ? styles.completed
                    : props.active
                    ? styles.active
                    : styles.inactive) +
                " " +
                styles.stepIcon
            }
        >
            {props.icon}
        </span>
    );
};

interface SubPageObject {
    is_selected: number;
    subPage: string;
    count: number;
    links: string[];
    subPageIdx: number;
}

interface SubDomainObject {
    subDomain: string;
    pages: SubPageObject[];
    is_selected: number;
    count: number;
    subDomainIdx: number;
}

type Props_SelectList = {
    otherDomains?: { siteUrl?: string }[] | [];
    selectedDomain: SelectedDomain;
    setSelectedDomain: Props_GscLoginFlow["setSelectedDomain"];
    label: string;
};

type Props_MultiSelectList = {
    label: string;
    countries: any[];
    setCountries?: Dispatch<
        SetStateAction<
            {
                country: string;
                label: string;
                value: string;
                code: string;
            }[]
        >
    >;
};

type Props_GscLoginFlow = {
    userInfo: { email: string };
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    selectedDomain: SelectedDomain;
    setSelectedDomain: Dispatch<SetStateAction<SelectedDomain>>;
    domains: any;
    getDomainList: (isSetDefault: boolean) => void;
    source?: string;
    getGscDataForDashboard?: () => void;
    setIsShowData?: Dispatch<SetStateAction<boolean>>;
    setGscLoginModal?: Dispatch<SetStateAction<boolean>>;
    multiDomain?: boolean;
    tempGscId?: number;
    setTempGscId?: Dispatch<SetStateAction<number>>;
    setSelectedPagesCount?: Dispatch<SetStateAction<number>>;
    selectedPagesCount?: number;
    syncDomainsEdit?: (obj: {
        isAdded?: boolean;
        isDeleted?: boolean;
        dmObj?: {
            is_selected?: 1 | 0;
            domain_name: string;
        };
    }) => void;
};

export const CollapsibleDropDown = ({
    subDomain,
    handleSubDomainPages,
    index = -1,
    search = "",
    defaultOpen = false,
}: {
    subDomain: SubDomainObject;
    handleSubDomainPages: (object: {
        subDomainIndex: number;
        pageIndex?: number;
        value: boolean;
    }) => void;
    index: number;
    search: string;
    defaultOpen?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
    const checkBoxClasses = checkBoxCustom();
    const FormControlLabelClasses = FormControlLabelCustom();
    const { addForwardSlashUrl } = useGscHook();

    return (
        <>
            <div className={styles.Collapsible} key={subDomain.subDomain}>
                <div className={styles.CollapsibleContainer}>
                    <div className={styles.collapsibleWrapperdiv}>
                        {isOpen ? (
                            <BiChevronDown
                                onClick={() => setIsOpen(false)}
                                size={22}
                                className={styles.dropdownArrow}
                            />
                        ) : (
                            <BiChevronRight
                                onClick={() => setIsOpen(true)}
                                size={22}
                                className={styles.dropdownArrow}
                            />
                        )}
                        <FormControlLabel
                            label=""
                            control={
                                <Checkbox
                                    size="small"
                                    checked={
                                        subDomain.is_selected == 0
                                            ? false
                                            : true
                                    }
                                    //@ts-ignore
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleSubDomainPages({
                                            subDomainIndex: index,
                                            value: e.target.checked,
                                        });
                                    }}
                                    classes={checkBoxClasses}
                                />
                            }
                        />
                    </div>
                    <div
                        className={`${styles.CollapsibleHeading} ${
                            subDomain.is_selected == 1 && styles.selected
                        }`}
                    >
                        {subDomain.subDomain}
                        <span className={styles.pills}>
                            {subDomain.count}{" "}
                            {subDomain.count > 1 ? "pages" : "page"}
                        </span>
                    </div>
                </div>
                {isOpen && (
                    <div className={styles.CollapsibleContent}>
                        {subDomain.pages
                            .filter((page) =>
                                page.subPage
                                    .toLowerCase()
                                    .includes(search.toLowerCase()),
                            )
                            .map((page: SubPageObject, pageIndex: number) => {
                                return (
                                    <div
                                        className={styles.collapsible2}
                                        key={pageIndex}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    classes={checkBoxClasses}
                                                    size="small"
                                                    checked={
                                                        page?.is_selected == 0
                                                            ? false
                                                            : true
                                                    }
                                                    onChange={(e: any) => {
                                                        e.stopPropagation();
                                                        handleSubDomainPages({
                                                            subDomainIndex:
                                                                index,
                                                            pageIndex:
                                                                page.subPageIdx,
                                                            value: e.target
                                                                .checked,
                                                        });
                                                    }}
                                                />
                                            }
                                            label={
                                                <_LightTooltip
                                                    arrow
                                                    interactive={true}
                                                    title={
                                                        <div
                                                            className={
                                                                styles.linksWrapper
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.tooltipContentTitle
                                                                }
                                                                style={{
                                                                    marginTop:
                                                                        "4px",
                                                                }}
                                                            >
                                                                Link Examples{" "}
                                                            </span>
                                                            <div
                                                                className={
                                                                    styles.links
                                                                }
                                                            >
                                                                {page.links
                                                                    .slice(0, 2)
                                                                    .map(
                                                                        (
                                                                            link,
                                                                            index,
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={
                                                                                    styles.tooltipContent
                                                                                }
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    window.open(
                                                                                        link,
                                                                                        "_blank",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    link
                                                                                }
                                                                            </div>
                                                                        ),
                                                                    )}
                                                            </div>
                                                        </div>
                                                    }
                                                    placement="right"
                                                >
                                                    <div
                                                        className={`${
                                                            styles.subPageItem
                                                        } ${
                                                            page.is_selected ==
                                                                1 &&
                                                            styles.selected
                                                        }`}
                                                    >
                                                        {addForwardSlashUrl(
                                                            page?.subPage,
                                                        )}
                                                    </div>
                                                </_LightTooltip>
                                            }
                                            classes={{
                                                root: FormControlLabelClasses.root,
                                            }}
                                        />

                                        <div className={styles.wrap}>
                                            <span className={styles.rootPill}>
                                                {page.count}
                                                {page.count < 2
                                                    ? " page"
                                                    : " pages"}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </>
    );
};

export const SelectList = ({
    otherDomains = [],
    selectedDomain,
    setSelectedDomain = () => {},
    label = "",
}: Props_SelectList) => {
    const [isDropDownEnabled, setIsDropDownEnabled] = useState<boolean>(false);
    const [searchCountry, setSearchCountry] = useState<string>("");
    const { getTrimmedDomainURL } = useGscHook();

    const filterMethod: (item: any) => boolean = (item) => {
        if (
            searchCountry.length &&
            item.label.toLowerCase().includes(searchCountry.toLowerCase())
        ) {
            return true;
        } else if (searchCountry.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    const filterCountries = [...GSC_Countries.filter(filterMethod)];

    useEffect(() => {
        console.log(otherDomains);
        let temp = otherDomains.filter((dmns: any) => dmns.used == 0);
        if (temp.length == 1) {
            setSelectedDomain(temp[0]);
        } else {
        }
    }, [otherDomains]);

    return (
        <>
            <div className={styles.domainsList}>
                <label>{label}</label>
                <div className={styles.dropDownContainer}>
                    <div
                        className={`${styles.selectedDomain}`}
                        onClick={() => setIsDropDownEnabled(!isDropDownEnabled)}
                    >
                        <div className={styles.selectedDomain_left}>
                            {
                                //@ts-ignore
                                selectedDomain?.siteUrl?.length > 0 ? (
                                    <>
                                        <img
                                            src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                selectedDomain?.siteUrl?.split(
                                                    ":",
                                                )[1]
                                            }&sz=32`}
                                            width={"24px"}
                                        />
                                        <span>
                                            {getTrimmedDomainURL(
                                                selectedDomain?.siteUrl,
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            color: "#3f5575",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Select Domain...
                                    </span>
                                )
                            }
                        </div>
                        <div className={styles.selectedDomain_right}>
                            {/* <SVGIcon
                                src={`/New UI/SVG/${
                                    isDropDownEnabled
                                        ? "BiChevronDown"
                                        : "BiChevronDown"
                                }.svg`}
                                size={22}
                                style={{ color: "#3F5575" }}
                            /> */}
                            <SVGIcon
                                size={20}
                                src={"/New UI/SVG/dropdown.svg"}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                    {isDropDownEnabled && (
                        <ClickAwayListener
                            onClickAway={() => {
                                setIsDropDownEnabled(false);
                            }}
                        >
                            <div className={styles.dropDownList}>
                                {otherDomains.map((d: any, index) => {
                                    return (
                                        <div
                                            className={`${styles.otherDomain} ${
                                                d?.used == 1 && styles.inactive
                                            }`}
                                            onClick={() => {
                                                if (d?.used == 0 || d?.reAuth) {
                                                    setSelectedDomain((ps) => ({
                                                        siteUrl: d.siteUrl,
                                                    }));

                                                    setIsDropDownEnabled(false);
                                                }
                                            }}
                                        >
                                            <div
                                                className={
                                                    styles.selectedDomain_left
                                                }
                                            >
                                                <img
                                                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                        // @ts-ignore
                                                        d?.siteUrl?.split(
                                                            ":",
                                                        )[1]
                                                    }&sz=32`}
                                                />

                                                <span>
                                                    {getTrimmedDomainURL(
                                                        d?.siteUrl,
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
            </div>
        </>
    );
};

export const MultiSelectList = ({
    label,
    countries,
    setCountries = () => {},
}: Props_MultiSelectList) => {
    return (
        <>
            <div className={styles.subDomainsList}>
                <label>{label}</label>
                <div className={styles.dropDownContainer}>
                    <div className={styles.selectedSubDomains}>
                        <ReactSelect
                            isCountryList={true}
                            defaultValues={[...countries]}
                            selected={countries}
                            setSelected={setCountries}
                            options={GSC_Countries}
                            name={"Country"}
                            _customStyles={customStyles}
                        />
                    </div>
                </div>
                <div
                    style={{
                        color: "#6B6B6B",
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                        marginLeft: "2px",
                    }}
                >
                    Choose upto 5 countries
                </div>
            </div>
        </>
    );
};

const GscLoginFlow = ({
    userInfo,
    activeStep,
    setActiveStep,
    selectedDomain,
    setSelectedDomain,
    domains,
    getDomainList,
    source = "",
    getGscDataForDashboard = () => {},
    setIsShowData = () => {},
    setGscLoginModal = () => {},
    multiDomain = false,
    tempGscId = -1,
    setTempGscId = () => {},
    selectedPagesCount = 0,
    setSelectedPagesCount = () => {},
    syncDomainsEdit = () => {},
}: Props_GscLoginFlow) => {
    const [countries, setCountries] = useState<
        { country: string; label: string; value: string; code: string }[]
    >([]);
    const [isLoadingPages, setIsLoadingPages] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isSubPageSelected, setIsSubPageSelected] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [subDomainSearch, setSubDomainSearch] = useState<boolean>(false);
    const [subPages, setSubPages] = useState<SubDomainObject[]>([]);
    const [remainingPages, setRemainingPages] = useState(0);
    const [usedPages, setUsedPages] = useState(0);
    const [isShowPrompt, setIsShowPrompt] = useState({
        show: false,
        isLimitOver: false,
    });
    const { taTrackGscLoginFlow } = useMixpanelTA();
    const [payloadForLogout, setPayloadForLogout] = useState<{
        gsc_id: number;
        domain_name: string;
    }>({
        domain_name: "",
        gsc_id: 0,
    });
    const VALID_SOURCES = ["dashboard", "traffic analyzer"];
    const goto = useNavigation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (domains.length == 1 && domains[0]?.used == 1) {
            setPayloadForLogout({
                domain_name: domains[0]?.siteUrl,
                gsc_id: tempGscId,
            });
        } else if (domains.length > 1) {
            let temp = domains.filter((dmns: any) => dmns?.used == 0);
            if (temp.length == 0) {
                setPayloadForLogout({
                    domain_name: domains[0]?.siteUrl,
                    gsc_id: tempGscId,
                });
            }
        }
    }, [domains]);

    const { postData, getData } = useHelperFunctions();
    const { LockConfigurator, isTeamMember } = useUserAccessHook();

    useEffect(() => {
        if (subPages.length > 0) {
            let selectionCount = 0;
            let totalPagesCount = 0;
            let totalCount = 0;
            subPages.map((item) => {
                totalCount = totalCount + 1;
                if (item.is_selected == 1) {
                    selectionCount = selectionCount + 1;
                }
                item.pages.map((subItem) => {
                    totalCount = totalCount + 1;
                    if (subItem.is_selected == 1) {
                        selectionCount = selectionCount + 1;
                        totalPagesCount = totalPagesCount + subItem?.count;
                    }
                });
            });
            setSelectedPagesCount(totalPagesCount);
            if (selectionCount == 0) {
                setIsSubPageSelected(false);
            } else {
                setIsSubPageSelected(true);
            }
            if (selectionCount == totalCount) {
                setIsAllSelected(true);
            } else {
                setIsAllSelected(false);
            }
        }
    }, [subPages]);

    const getPagesToSelect: (domain_name?: string) => void = async (
        domain_name,
    ) => {
        setIsLoadingPages(true);
        const res = await getData({
            url: `/api/analyse/getPagesToSelect?domain_name=${domain_name}`,
        });
        if (res.status === 200) {
            if (res.data.data?.length > 0) {
                setSubPages(() => {
                    let temp = [...res.data.data];

                    temp = temp.map((subD: SubDomainObject, index) => ({
                        ...subD,
                        subDomainIdx: index + 1,
                        pages: subD.pages
                            .map((item, idx) => ({
                                ...item,
                                subPageIdx: idx + 1,
                            }))
                            .sort((a: SubPageObject, b: SubPageObject) =>
                                a.subPage.localeCompare(b.subPage),
                            ),
                    }));

                    return temp;
                });
            } else {
                setPayloadForLogout({
                    gsc_id: res.data.gsc_id,
                    domain_name: domain_name || "",
                });
                setSubPages([]);
            }
            setRemainingPages(res.data?.quota?.remainingDomainLimit || 0);
            setUsedPages(res.data?.quota?.active_domain_limit || 0);
            setTempGscId(res.data.gsc_id || 0);
        }
        setIsLoadingPages(false);
    };

    useEffect(() => {
        if (subPages.length <= 0 && activeStep == 3 && !isLoadingPages) {
            getPagesToSelect(selectedDomain.siteUrl);
        }
        if (domains.length == 0) {
            getDomainList(true);
        }
    }, [activeStep, selectedDomain.siteUrl]);

    const handleParentSubDomain: (obj: {
        value: boolean;
        pages: SubPageObject[];
    }) => number = ({ value, pages = [] }) => {
        let status = 1;
        let selectedCount = 0;
        pages.forEach((page) => {
            if (page.is_selected == 1) {
                selectedCount = selectedCount + 1;
            }
        });
        if (selectedCount == 0) {
            status = 1;
        } else if (selectedCount == 1) {
            status = value ? 1 : 0;
        }

        return status;
    };

    const handleSubDomainPages: (object: {
        subDomainIndex: number;
        pageIndex?: number;
        value: boolean;
    }) => void = ({ subDomainIndex = -1, pageIndex = -1, value }) => {
        //@ts-ignore
        setSubPages((ps: SubDomainObject[]) => {
            let temp: SubDomainObject[] = [...ps];
            if (subDomainIndex == -1 && pageIndex == -1) {
                return;
            }

            if (pageIndex == -1) {
                //subDomain
                temp = temp.map((subDomain) => {
                    if (subDomain.subDomainIdx == subDomainIndex) {
                        return {
                            ...subDomain,
                            is_selected: value ? 1 : 0,
                            pages: subDomain.pages.map((item) => ({
                                ...item,
                                is_selected: value ? 1 : 0,
                            })),
                        };
                    } else {
                        return subDomain;
                    }
                });
            } else {
                temp = temp.map((subDomain) => {
                    if (subDomain.subDomainIdx == subDomainIndex) {
                        return {
                            ...subDomain,
                            is_selected: handleParentSubDomain({
                                value: value,
                                pages: subDomain.pages,
                            }),
                            pages: subDomain.pages.map((item) => {
                                if (item.subPageIdx == pageIndex) {
                                    return {
                                        ...item,
                                        is_selected: value ? 1 : 0,
                                    };
                                } else return item;
                            }),
                        };
                    } else {
                        return subDomain;
                    }
                });
            }
            if (temp.length) {
                return temp;
            } else {
                return [];
            }
        });
    };

    const filterSubDomains: (item: SubDomainObject) => boolean = (item) => {
        if (!search.length) return true;
        else {
            const subDomain = item?.subDomain?.toLowerCase();
            const searchQuery = search.toLowerCase();

            const filteredPages = item?.pages?.filter((page) => {
                const subPage = page?.subPage?.toLowerCase();
                return subPage.includes(searchQuery); // Check if subPage includes the search query
            });

            return (
                subDomain.includes(searchQuery) ||
                (filteredPages && filteredPages.length > 0)
            );
        }
    };

    const _subPages = [...subPages.filter(filterSubDomains)];

    const handleSubPageUpdate: () => void = async () => {
        setIsLoadingPages(true);
        let payload = {
            domain_name: selectedDomain.siteUrl,
            country: countries.map((item) => item.code),
            data: isAllSelected ? "*" : subPages,
            isAllSelected: isAllSelected ? 1 : 0,
        };
        const res = await postData({
            url: "/api/analyse/updateSelectedPages",
            payload: payload,
        });
        if (res.status == 200) {
            //call inventory api
            if (!LockConfigurator(FEATURE_TYPES.inventoryLock)) {
                setTimeout(async () => {
                    const res = await postData({
                        url: `/api/analyse/post-traffic-analyser-v2/`,
                        payload: {
                            domain_name: payload.domain_name,
                            country: [countries[0].code],
                            search_country: countries[0].label,
                        },
                    });
                }, 1);
            }

            syncDomainsEdit({
                isAdded: true,
                dmObj: {
                    domain_name: selectedDomain.siteUrl || "",
                    is_selected: 0,
                },
            });
            dispatch(
                displayAlert({
                    alertMessage: "Domain Successfully Added",
                    alertType: "success",
                }),
            );
            setActiveStep(1);
            if (source.toLowerCase() == VALID_SOURCES[0]) {
                getGscDataForDashboard();
            } else {
                setIsShowData(true);
                goto("ANALYSIS_INSIGHTS");
                setActiveStep(1);
            }
        }
        setIsLoadingPages(false);
    };

    const updateSelectedDomain: () => void = async () => {
        setIsLoadingPages(true);
        let payload = {
            domain_name: selectedDomain.siteUrl,
            country: countries.map((item) => item.code),
            ...(multiDomain && { multi_domain: true }),
        };
        const res = await postData({
            url: `/api/analyse/updateSelectedDomain`,
            payload,
        });
        if (res.status == 200) {
            getPagesToSelect(payload.domain_name);
            setActiveStep(3);

            // dispatch(
            //     setUserGscInfo({
            //         domain_name: res.data?.resp?.domain_name,
            //     }),
            // );
            setPayloadForLogout({
                domain_name: "",
                gsc_id: 0,
            });
        } else {
            setPayloadForLogout({
                domain_name: payload?.domain_name || "",
                gsc_id: tempGscId,
            });

            setIsLoadingPages(false);
        }
    };

    return (
        <div className={styles.domainsContainer}>
            {activeStep < 4 && (
                <Stepper
                    activeStep={activeStep - 1}
                    alternativeLabel
                    connector={<CustomStepConnector />}
                >
                    <Step completed={true}>
                        <CustomStepLabel
                            StepIconComponent={CustomStepIcon}
                            style={{ color: "green" }}
                        >
                            Connect GSC
                        </CustomStepLabel>
                    </Step>
                    <Step>
                        <CustomStepLabel StepIconComponent={CustomStepIcon}>
                            Select Domain
                        </CustomStepLabel>
                    </Step>
                    <Step>
                        <CustomStepLabel StepIconComponent={CustomStepIcon}>
                            Select Pages
                        </CustomStepLabel>
                    </Step>
                </Stepper>
            )}
            {activeStep == 2 && (
                <div className={styles.domainConnectSuccess}>
                    <SVGIcon
                        src={"/New UI/SVG/Tick.svg"}
                        size={28}
                        style={{ color: "#1DC198" }}
                    />
                    Google Search Console Connected for
                    {" " + userInfo.email}
                </div>
            )}
            <div className={styles.dropDowns}>
                {activeStep == 2 ? (
                    <div>
                        <div className={styles.copilotTagline}>
                            Select a domain and a targeted country
                        </div>

                        {!!domains.length && (
                            <SelectList
                                selectedDomain={selectedDomain}
                                setSelectedDomain={setSelectedDomain}
                                label={"Domain"}
                                otherDomains={domains}
                            />
                        )}
                        <MultiSelectList
                            countries={countries}
                            setCountries={setCountries}
                            label={"Country"}
                        />
                    </div>
                ) : activeStep == 3 ? (
                    <div style={{ position: "relative" }}>
                        <div className={styles.copilotTagline}>
                            Select pages to optimize
                        </div>
                        <div className={styles.subPagesInputWrapper2}>
                            <Textfield
                                placeholder="Search"
                                label={""}
                                props={{
                                    value: search,
                                    onChange: (e: any) =>
                                        setSearch(e.target.value),
                                    onFocus: (e: any) =>
                                        setSubDomainSearch(true),
                                    onBlur: (e: any) => {
                                        if (!search.length)
                                            setSubDomainSearch(false);
                                    },
                                    style: {
                                        borderRadius: 3,
                                        border: `solid 1px ${"rgba(75, 75, 85, 0.16)"}`,
                                        backgroundColor: "#fff",
                                        paddingLeft: "40px",
                                    },
                                }}
                                Icon={() => (
                                    <SVGIcon
                                        src={"/New UI/SVG/searchIcon.svg"}
                                    />
                                )}
                                iconStyle={{
                                    left: "10px",
                                    right: "unset",
                                }}
                            />
                        </div>
                        <div className={styles.collapsibleWrapper}>
                            {isLoadingPages ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) : _subPages.length > 0 ? (
                                _subPages.map((subDomain: SubDomainObject) => {
                                    return (
                                        <CollapsibleDropDown
                                            subDomain={subDomain}
                                            handleSubDomainPages={
                                                handleSubDomainPages
                                            }
                                            index={subDomain.subDomainIdx}
                                            search={search}
                                        />
                                    );
                                })
                            ) : (
                                <div className={styles.noItems}>
                                    <SVGIcon
                                        size={180}
                                        src={"/New UI/SVG/Emptydocument.svg"}
                                    />
                                    <h3 className={styles.text}>
                                        No Pages Found
                                    </h3>
                                </div>
                            )}
                        </div>
                        {isShowPrompt.show &&
                            !LockConfigurator(FEATURE_TYPES.inventoryLock) && (
                                <Modal>
                                    <div className={styles.promptPagesLimit}>
                                        <div
                                            className={
                                                styles.promptPagesLimit_text
                                            }
                                        >
                                            {isShowPrompt?.isLimitOver ? (
                                                "You've reached your quota, and we're unable to process any more pages for your domain. Don't worry, though – you can easily boost your processing capabilities by reaching out to our support team to explore purchasing options."
                                            ) : (
                                                <>
                                                    Please be aware that we'll
                                                    only process some pages on
                                                    your domain, which may
                                                    affect certain features. We
                                                    suggest getting in touch
                                                    with our support team to
                                                    explore options for
                                                    purchasing more pages.
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.ctaRow}>
                                            <div
                                                className={styles.backCta}
                                                onClick={() => {
                                                    taTrackGscLoginFlow({
                                                        step: activeStep + "",
                                                        cta: "Talk to Support",
                                                        isLimitOver:
                                                            isShowPrompt?.isLimitOver ||
                                                            false,
                                                        partialProcess:
                                                            !isShowPrompt?.isLimitOver,
                                                    });
                                                    window.userengage &&
                                                        window.userengage(
                                                            "widget.show",
                                                        );
                                                    window.userengage &&
                                                        window.userengage(
                                                            "widget.open",
                                                        );
                                                }}
                                            >
                                                Talk to Support
                                            </div>
                                            <div
                                                className={
                                                    styles.promptCta_continue
                                                }
                                            >
                                                <Button
                                                    handler={() => {
                                                        taTrackGscLoginFlow({
                                                            step:
                                                                activeStep + "",
                                                            cta: "OK",
                                                            isLimitOver:
                                                                isShowPrompt?.isLimitOver ||
                                                                false,
                                                            partialProcess:
                                                                !isShowPrompt?.isLimitOver,
                                                        });
                                                        if (
                                                            isShowPrompt?.isLimitOver
                                                        ) {
                                                            setTempGscId(0);
                                                            setSelectedPagesCount(
                                                                0,
                                                            );
                                                        }
                                                        setTimeout(() => {
                                                            handleSubPageUpdate();
                                                        }, 100);
                                                    }}
                                                    disabled={
                                                        !isSubPageSelected
                                                    }
                                                    width={"140px"}
                                                    style={{
                                                        background: "#014DC5",
                                                        boxShadow:
                                                            "0px 2px 8px rgba(0, 0, 0, 0.03)",
                                                        borderRadius: "3px",
                                                        fontWeight: "400",
                                                        cursor: "pointer",
                                                    }}
                                                    text={"OK"}
                                                    loading={isLoadingPages}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            )}
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <div className={styles.bottomCta}>
                <>
                    {activeStep == 2 ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "30px",
                                marginLeft: "auto",
                                width: payloadForLogout?.domain_name?.length
                                    ? "100%"
                                    : "",
                            }}
                        >
                            {!!payloadForLogout?.domain_name?.length && (
                                <div
                                    className={styles.logoutGscMidJourney}
                                    onClick={() => {
                                        postData({
                                            url: "/api/analyse/logoutGsc",
                                            payload: {
                                                domain_name:
                                                    payloadForLogout?.domain_name,
                                                gsc_id: payloadForLogout.gsc_id,
                                            },
                                        });
                                        setActiveStep(1);
                                        setGscLoginModal(false);
                                    }}
                                >
                                    Logout
                                </div>
                            )}
                            <Button
                                handler={() => {
                                    taTrackGscLoginFlow({
                                        step: activeStep + "",
                                        cta: "Next",
                                    });
                                    updateSelectedDomain();
                                }}
                                width={"100px"}
                                disabled={
                                    !selectedDomain?.siteUrl ||
                                    countries.length == 0
                                }
                                style={{
                                    background: "#014DC5",
                                    boxShadow:
                                        "0px 2px 8px rgba(0, 0, 0, 0.03)",
                                    borderRadius: "3px",
                                    fontWeight: "400",
                                }}
                                text={"Next"}
                                loading={isLoadingPages}
                            />
                        </div>
                    ) : activeStep == 3 ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "30px",
                                marginLeft: "auto",
                                width: "100%",
                            }}
                        >
                            {!!payloadForLogout?.domain_name?.length && (
                                <div
                                    className={styles.logoutGscMidJourney}
                                    onClick={() => {
                                        postData({
                                            url: "/api/analyse/logoutGsc",
                                            payload: {
                                                domain_name:
                                                    payloadForLogout?.domain_name,
                                                gsc_id: payloadForLogout.gsc_id,
                                            },
                                        });
                                        setActiveStep(1);
                                        setGscLoginModal(false);
                                    }}
                                >
                                    Logout
                                </div>
                            )}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "30px",
                                    marginLeft: "auto",
                                }}
                            >
                                <div
                                    onClick={() => {
                                        taTrackGscLoginFlow({
                                            step: activeStep + "",
                                            cta: "Back",
                                        });
                                        setActiveStep(2);
                                    }}
                                    className={styles.backCta}
                                >
                                    Back
                                </div>
                                <LightTooltip
                                    title={
                                        "Please select at least one page to continue"
                                    }
                                    placement="top"
                                    arrow
                                    onOpen={(e: any) => {
                                        if (isSubPageSelected) {
                                            setImmediate(() => {
                                                document
                                                    .querySelectorAll(
                                                        "[role=tooltip]",
                                                    )
                                                    .forEach(
                                                        (e: any) =>
                                                            (e.style.display =
                                                                "none"),
                                                    );
                                            });
                                        }
                                    }}
                                >
                                    <div>
                                        <Button
                                            handler={() => {
                                                if (
                                                    !LockConfigurator(
                                                        FEATURE_TYPES.inventoryLock,
                                                    ) &&
                                                    (remainingPages == 0 ||
                                                        selectedPagesCount >
                                                            remainingPages)
                                                ) {
                                                    setIsShowPrompt({
                                                        show: true,
                                                        isLimitOver:
                                                            remainingPages ==
                                                                0 &&
                                                            usedPages == 0
                                                                ? true
                                                                : false,
                                                    });
                                                } else {
                                                    setIsShowPrompt({
                                                        show: false,
                                                        isLimitOver: false,
                                                    });
                                                    taTrackGscLoginFlow({
                                                        step: activeStep + "",
                                                        cta: "Let's Go",
                                                        isLimitOver: false,
                                                        partialProcess: false,
                                                    });
                                                    handleSubPageUpdate();
                                                }
                                            }}
                                            disabled={!isSubPageSelected}
                                            width={"100px"}
                                            style={{
                                                background: "#014DC5",
                                                boxShadow:
                                                    "0px 2px 8px rgba(0, 0, 0, 0.03)",
                                                borderRadius: "3px",
                                                fontWeight: "400",
                                            }}
                                            text={"Let's Go"}
                                            loading={isLoadingPages}
                                        />
                                    </div>
                                </LightTooltip>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            </div>
        </div>
    );
};

export default GscLoginFlow;
