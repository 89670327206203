import { ClickAwayListener, TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import ReportContext from "../../Research/ReportContext";
import styles from "./OutlineBuilder.module.css";
import { showCounter } from "../../../atoms/TextArea/TextArea";
import { useRef } from "react";
import H2H3dropdown from "./H2H3dropdown";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import { CruiseContext } from "./StepsWizard";
import { Outline } from "./classes";
import { CRUISE_TYPES } from "./useCruiseHook";
import { v4 } from "uuid";
import { VscTrash } from "react-icons/vsc";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { cruiseMixPanelEvents } from "./cruiseConstant";
import { getKeytermVariations } from "../../../../utility/helperFun";
const maxLength = 120;
const OutlineH3 = ({
    provided,
    index,
    item,
    h2Index,
    handleH3H2Change,
    onPressEnterInOutline,
}) => {
    const [h3Input, setH3Input] = useState(item.data);
    const dispatchStepsData = useContext(CruiseContext);
    const dispatch = useDispatch();
    const { reportInfo, keyTerms, highlight_keyTerm } =
        useContext(ReportContext);
    const [focus, setFocus] = useState(item.data?.length ? false : true);
    const { mixpanelTrack } = useMixpanelHook();

    const ref = useRef();

    function handleEdit(text, onEnter = false) {
        if (onEnter) {
            onPressEnterInOutline({
                data: "",
                tag: Outline.tags.h3,
                index,
                h2Index,
            });
        }

        if (!text.trim()) {
            deleteHeading();
            return;
        }
        if (item.data.trim() == text.trim()) return;
        setH3Input(h3Input.trim());
        setFocus(false);
        const heading = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: text,
            tag: Outline.tags.h3,
        });
        dispatchStepsData({
            type: CRUISE_TYPES.EDIT_OUTLINES,
            index: index,
            field: Outline.type.heading,
            data: heading,
            id: item.id,
            h2Index,
        });
    }

    const _handleH3H2Change = () => {
        handleH3H2Change(index);
    };

    function deleteHeading() {
        dispatchStepsData({
            type: CRUISE_TYPES.DELETE_OUTLINES,
            index: index,
            field: Outline.type.heading,
            id: item.id,
            h2Index,
            tag: Outline.tags.h3,
        });
    }

    return (
        <div
            className={styles.h2Row + " " + (open && styles.noBorder)}
            ref={ref}
        >
            <div className={styles.headingRow} style={{ position: "relative" }}>
                <div {...provided.dragHandleProps} className={styles.dragIcon}>
                    <SVGIcon
                        color="lightgray"
                        size={16}
                        src="/New UI/SVG/drag.svg"
                    />
                </div>

                <div
                    className={
                        styles.highlightWrapper +
                        " " +
                        (focus && styles.highlightWrapper__active)
                    }
                >
                    <H2H3dropdown
                        handler={_handleH3H2Change}
                        index={index}
                        type={item.tag}
                    />

                    {!focus ? (
                        <div
                            className={styles.text}
                            onClick={() => setFocus(true)}
                        >
                            {h3Input}
                        </div>
                    ) : (
                        <TextareaAutosize
                            disabled={false}
                            type="text"
                            rowsMin={1}
                            placeholder={"Add Heading here"}
                            maxLength={maxLength}
                            autoFocus={true}
                            value={h3Input}
                            onChange={(e) => setH3Input(e.target.value)}
                            onFocus={(e) =>
                                e.target.setSelectionRange(
                                    e.target.value.length,
                                    e.target.value.length,
                                )
                            }
                            style={{
                                width: "100%",
                                ...(h3Input?.length >= maxLength
                                    ? {
                                          borderColor: "rgb(244 98 98 / 73%)",
                                      }
                                    : {}),
                            }}
                            // autoFocus={value == ""}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleEdit(e.target.value, true);
                                    return;
                                }
                                if (e.shiftKey && e.key == "Tab") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }

                                if (!e.shiftKey && e.key == "Tab") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    _handleH3H2Change();
                                }
                            }}
                            onBlur={(e) => {
                                handleEdit(e.target.value);
                                setFocus(false);
                            }}
                        />
                    )}
                </div>

                {focus &&
                    showCounter(false, true, h3Input?.length, maxLength, {
                        textAlign: "right",
                        display: "block",
                        position: "absolute",
                        right: "35px",
                    })}
                <div className={styles.actionsBtn}>
                    {/* {!isEdit && <span className={styles.addNote}><SVGIcon src={"/New UI/SVG/MdOutlineSpeakerNotes.svg"} /></span>} */}

                    <VscTrash
                        onClick={(e) => {
                            e.stopPropagation();
                            mixpanelTrack(cruiseMixPanelEvents.delete, {
                                "Screen Name": "Outline",
                                "Deleted Section": "H3",
                            });
                            deleteHeading();
                        }}
                        color="#f94f2c"
                    />
                </div>
            </div>
        </div>
    );
};

export default OutlineH3;
