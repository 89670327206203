import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import TextfieldV2 from "../../../atoms/CustomTextfield/Textfield";
import styles from "./KeywordInput.module.css";
import { AiFillCaretDown } from "react-icons/ai";
import Button from "../../../atoms/Button/Button";
import { BiSearch } from "react-icons/bi";
import CountrySelect from "./CountrySelect";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { SAMPLE_CLUSTER_ID1, url } from "../../../../utility/config";
import useHelperFunctions from "../../../helperFunctions";
import GuideTile from "../../GuideTile/GuideTile";
import { displayAlert } from "../../../../store/actions/alert";
import GuideTileInd from "../../GuideTile/GuideTileInd";
import { Autocomplete } from "@material-ui/lab";
import { LOCATION_SUGGESTIONS } from "../../../api.json";
import { Chip, makeStyles, TextField, Tooltip } from "@material-ui/core";

import { thousands_separators } from "../../../../utility/validateFunctions";
import SVGIcon from "../../../atoms/SVGIcon";
import HighlightComponent from "../../../atoms/HighlightComponent";
import { OnBoardingContext } from "../../../Context/OnBoardingContext/OnBoardingContextProvider";
import {
    CREATE_REPORT_FOCUS,
    TOPIC_CLUSTER,
} from "../../../Context/OnBoardingContext/constant";
import LocationList from "./LocationList";
import { CLUSTER, getTrailText } from "../../../Pages/NewDashboard/constants";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../../components/account/useUserAccessHook";
import { SeoWithCruiseModal } from "../../../Pages/NewDashboard/NewDashboard";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import Modal from "../../../atoms/Modal/Modal";

import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import GscLoginBar from "../../../Pages/NewDashboard/GscLoginBar";
import GscDomainsKP from "./GscDomainsKP";
import { PLANS_CATEGORIES } from "../../../../constants";
import { setUserGscInfo } from "../../../../store/actions/userGscInfo";
import useGscHook from "../../../Pages/GoogleSearchConsole/useGscHook";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { UploadFile } from "./UploadFile";
import { VscClose } from "react-icons/vsc";

const autoCustom = makeStyles((theme) => ({
    root: {
        border: "solid 1px #d2d2d2",
        margin: "0",
        padding: "0px",
        borderRadius: 3,
        background: "#fff",
        minHeight: 38,
        maxHeight: 120,
        minWidth: "calc(100% - 100px)",
        overflow: "auto",
    },
    tag: {
        border: "1px solid transparent",
        background: "#e8edf8cc",
        margin: "0",
        padding: "0",
        borderRadius: 3,
        padding: "0px 13px",
    },
}));
const inputCustom = makeStyles((theme) => ({
    root: {
        " & >  .MuiInput-root": { padding: "3px 7px" },
        "& > div::before": {
            borderBottom: "none",
        },
        "& > div:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&.Mui-focused::before": {
            borderBottom: "none !important",
        },
        "&.Mui-underline": {
            background: "white",
        },
        "& textarea::placeholder": {
            fontSize: 14,
            color: "#6b6b6b",
            fontWeight: 300,
            opacity: 1,
            fontFamily: "inter",
        },
        "& textarea": {
            fontSize: 14,
            fontWeight: 400,
            opacity: 1,
            fontFamily: "inter",
            minWidth: "80px !important",
            maxHeight: 40,
        },
    },
}));

const questionWords = [
    "what",
    "when",
    "how",
    "where",
    "why",
    "who",
    "which",
    "whose",
];

const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
const englishPattern = /^[A-Za-z0-9 `!@#$%^&*()_+-={}[\]\\|;:'"<,>.?/\n]+$/;
const specialPattern = /[^A-Za-z0-9\s ,.']/;
const questionPattern = new RegExp(
    "\\b(" + questionWords.join("|") + ")\\b",
    "i",
);

let timeout = null;
let timeout2 = null;
let script;

export default function KeywordInputSingle({
    makeReport,
    loadingCreation,
    docs,
    apiCalls,
    buttonText,
    buttonIcon,
    cruiseMode,
    placeholderText,
    cluster = false,
    isDash = false,
    sampleClusters = [],
    setShowModal = () => {},
    clusterBooster,
    docsLoading = false,
    hasUsedKeywordGap = true,
    setHasUsedKeywordGap = () => {},
}) {
    const [suggestedLocations, setSuggestedLocations] = useState([]);
    const [suggestedKeywords, setSuggestedKeyword] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [location, setLocation] = useState("");
    const plan_category = useSelector(
        (state) => state.otherInfo.subscriptionInfo?.plan_category,
    );

    const [gscConnectedDomain, setGscConnectedDomain] = useState();
    const [competitorsDomain, setCompetitorsDomain] = useState([]);
    const [gscDomains, setGscDomains] = useState([]);
    const [loadingGsc, setLoadingGsc] = useState(false);
    const [isPrimaryKeyword, setIsPrimaryKeyword] = useState(true);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [showKeywordModal, setShowUploadModal] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [uploadedKeywords, setUploadedKeywords] = useState([]);

    console.log("testing", Object.keys(uploadedKeywords[0] ?? [])[0]);
    const goTo = useNavigation();
    const dispatch = useDispatch();
    const { isLTD, trialUser, LockConfigurator, planExpiresDate, planTrial } =
        useUserAccessHook();
    const { mixpanelTrack } = useMixpanelHook();
    const getGscDataForAccount = async () => {
        setLoadingGsc(true);
        const res = await getData({
            url: "/api/analyse/gsc-account-info",
        });
        if (res.status == 200) {
            if (res.data?.domain?.length) {
                setGscDomains([res.data?.domain, "None"]);
                dispatch(setUserGscInfo({ domain_name: res?.data?.domain }));
            } else {
                setGscDomains([]);
            }
            setLoadingGsc(false);
        } else {
            dispatch(
                displayAlert({
                    alertMessage: ``,
                    alertType: "error",
                    timeout: 5000,
                }),
            );
            setGscDomains([]);
            setLoadingGsc(false);
        }
    };

    const [country, setCountry] = useState({
        label: "United States",
        country_code: "US",
    });
    const { getTrimmedDomainURL } = useGscHook();
    const { getData } = useHelperFunctions();
    const onBoarding = useContext(OnBoardingContext);
    const tour = useSelector((state) => state.auth.tour);
    const seo_tour = useSelector((state) => state.auth.seo_tour);

    const [modal, setModal] = useState({ open: false, id: 0 });
    const [idea, setIdea] = useState("");
    const inputClasses = inputCustom();
    const autoClasses = autoCustom();
    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            suggestLoaction();
        }, 200);
    }, [location]);

    useEffect(() => {
        if (apiCalls == 1 && docs.length) {
            setCountry({
                label: docs[0].search_country,
                country_code: docs[0].country_code,
            });
            if (!loadingCreation) {
                if (!docs[0]?.is_sample) {
                    setGscConnectedDomain(
                        docs[0]?.domain_name?.length > 0
                            ? getTrimmedDomainURL(docs[0]?.domain_name || "")
                            : "",
                    );
                    if (docs[0]?.competitor_domain?.length) {
                        let temp = JSON.parse(docs[0]?.competitor_domain);
                        if (temp.length > 0) setCompetitorsDomain([...temp]);
                    }
                }
            }
        }
    }, [docs]);

    const resetUpload = () => {
        setIsFileUploaded(false);
        setIsPrimaryKeyword(true);
        setUploadedKeywords([]);
    };

    const suggestLoaction = async () => {
        if (typeof location == "string") {
            const res = await getData({
                url: `${LOCATION_SUGGESTIONS}?query=${location}`,
            });
            if (res.status == 200) {
                setSuggestedLocations(res.data.locations);
            }
        }
    };

    const getIdeaText = (input) => {
        setIdea(
            urlPattern.test(input)
                ? "Please avoid entering web addresses (URLs)."
                : questionPattern.test(input)
                ? "Questions are not suitable inputs. Please use broader keyword instead"
                : input?.split(" ").length > 4
                ? "For optimal results, keep your input to fewer than 4 words."
                : !englishPattern.test(input)
                ? "We support only English."
                : specialPattern.test(input)
                ? `Avoid using special characters like !@#$%^&*()+{}:"<>? and others in your input.`
                : "",
        );
    };

    useEffect(() => {
        clearTimeout(timeout2);
        timeout2 = setTimeout(() => {
            suggestKeyword();
        }, 100);
        getIdeaText(inputValue);
    }, [inputValue, location]);

    const suggestKeyword = async () => {
        if (script) script.remove();
        const completeUrl = `https://www.google.com/complete/search?output=search&client=chrome&q=${inputValue}&hl=en-US&gl=${
            country.code
        }&cp=${
            document.getElementById("keywordSuggestionInput")?.firstChild
                ? document.getElementById("keywordSuggestionInput").firstChild
                      .selectionEnd
                : inputValue.length
        }&callback=s12s12&_=${+new Date()}`;

        script = document.createElement("script");

        script.src = completeUrl;
        document.head.append(script);
    };

    const createReportWithEnter = (e) => {
        if (e.key == "Enter") {
            if (!loadingCreation)
                makeReport({ e, inputValue, country, setInputValue });
        }
    };

    useEffect(() => {
        window.s12s12 = (e) => {
            if (script) script.remove();
            setSuggestedKeyword(e[1]);
        };
    }, []);

    const keyWordGapAccess =
        [PLANS_CATEGORIES.PRO, PLANS_CATEGORIES.PRO_MAX].includes(
            plan_category,
        ) ||
        isLTD ||
        trialUser;

    return (
        <div
            className={
                cruiseMode
                    ? styles.keywordInputModalContainer
                    : cluster
                    ? styles.keywordInputContainerCluster
                    : styles.keywordInputContainer
            }
        >
            {clusterBooster && !isDash && (
                <div className={styles.semrushIndicator}>
                    <LightTooltip
                        arrow
                        placement="left"
                        title="Cluster Booster is ON"
                        PopperProps={{
                            disablePortal: true,
                        }}
                    >
                        <div>
                            <SVGIcon src={"/New UI/SVG/Cluster_Booster.svg"} />
                        </div>
                    </LightTooltip>
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    gap: "25px",
                    alignItems: "center",
                    marginTop: "15px",
                    marginBottom: isDash ? "15px" : undefined,
                }}
            >
                <IconRadio
                    label="By Primary Keyword"
                    checked={isPrimaryKeyword}
                    onChange={() => {
                        setIsPrimaryKeyword(true);
                        setIsFileUploaded(false);
                        setUploadedKeywords([]);
                    }}
                    name="keywordOption"
                    disabled={loadingCreation}
                />
                <IconRadio
                    label="By List of Keywords"
                    btn
                    checked={!isPrimaryKeyword}
                    onChange={() => {
                        setIsPrimaryKeyword(false);
                        setIsFileUploaded(false);
                        setUploadedKeywords([]);
                    }}
                    name="keywordOption"
                    disabled={loadingCreation}
                />
            </div>

            <HighlightComponent highlight={onBoarding.focusOn == TOPIC_CLUSTER}>
                <div
                    style={{
                        flexDirection: isDash ? "column" : "row",
                        paddingTop: isDash ? "0px" : "20px",
                    }}
                    className={
                        cruiseMode
                            ? styles.keywordInputModal
                            : styles.keywordInput +
                              " " +
                              (isDash && styles.dashboard)
                    }
                >
                    <div
                        style={{
                            position: "relative",
                        }}
                        className={styles.textFieldDiv}
                    >
                        {idea && inputValue && (
                            <div className={styles.idea}>
                                <SVGIcon
                                    size={14}
                                    src={"/New UI/SVG/Idea.svg"}
                                />
                                <div>{idea}</div>
                            </div>
                        )}

                        {isPrimaryKeyword ? (
                            <>
                                <Autocomplete
                                    inputValue={inputValue}
                                    className={styles.inputMedia}
                                    classes={autoClasses}
                                    freeSolo
                                    onInputChange={(a, b) => {
                                        setInputValue(b.substring(0, 45));
                                    }}
                                    options={(() => {
                                        suggestedKeywords.filter = () =>
                                            suggestedKeywords;
                                        return [
                                            ...(inputValue.trim()
                                                ? [inputValue]
                                                : []),
                                            ...suggestedKeywords,
                                        ];
                                    })()}
                                    onChange={(a, b) => {
                                        if (b) setInputValue(b);
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    renderOption={(option) => {
                                        return (
                                            <React.Fragment>
                                                <div style={{ width: "100%" }}>
                                                    <div
                                                        className={
                                                            styles.location
                                                        }
                                                    >
                                                        <div>{option}</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    }}
                                    disableClearable
                                    renderInput={({ inputProps, ...rest }) => (
                                        <TextField
                                            {...rest}
                                            multiline
                                            variant="standard"
                                            classes={inputClasses}
                                            placeholder={
                                                placeholderText
                                                    ? placeholderText
                                                    : "Enter primary keyword to find related keywords"
                                            }
                                            autoFocus={isDash ? true : false}
                                            style={{ padding: 0 }}
                                            inputProps={{
                                                ...inputProps,
                                                onKeyDown: (e) => {
                                                    if (
                                                        e?.key?.toLowerCase() ==
                                                        "enter"
                                                    ) {
                                                        e?.target?.blur();
                                                    }
                                                },
                                                onChange: (e) => {
                                                    setInputValue(
                                                        e.target.value.substring(
                                                            0,
                                                            100,
                                                        ),
                                                    );
                                                    inputProps?.onChange(e);
                                                },
                                                readOnly: loadingCreation,
                                            }}
                                        />
                                    )}
                                />
                            </>
                        ) : isFileUploaded && uploadedFileName ? (
                            <div className={styles.uploadedFileContainer}>
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        background: "rgba(1, 77, 197, 0.1)",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        gap: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <SVGIcon
                                            size={20}
                                            src={"/New UI/SVG/excel-icon.svg"}
                                        />
                                        <span
                                            style={{
                                                color: "#1f4cbe",
                                                fontWeight: "600px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {uploadedFileName.length > 9
                                                ? `${uploadedFileName.slice(
                                                      0,
                                                      9,
                                                  )}...`
                                                : uploadedFileName}
                                        </span>
                                    </div>
                                    <VscClose
                                        size={20}
                                        onClick={resetUpload}
                                        style={{
                                            color: "#666666",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}
                                >
                                    <IoIosCheckmarkCircle
                                        size={14}
                                        color={"#25a18e"}
                                    />
                                    <span
                                        style={{
                                            color: "#25a18e",
                                            fontSize: "14px",
                                        }}
                                    >
                                        File uploaded successfully.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: isDash ? "10px" : "20px",
                                }}
                            >
                                <Button
                                    text="Upload File"
                                    Icon={MdOutlineFileUpload}
                                    iconReverse
                                    handler={() => setShowUploadModal(true)}
                                    width={isDash ? 120 : 150}
                                    fontSize={14}
                                    style={{
                                        color: "#014dc5",
                                        backgroundColor: "#014dc51a",
                                    }}
                                />
                                <span className={styles.uploadText}>
                                    Please upload a csv or excel containing
                                    keywords.
                                </span>
                            </div>
                        )}
                        <div className={styles.bottomSection}>
                            {isDash ? ( // landing page KP
                                <div
                                    className={styles.left}
                                    style={{ marginTop: "5px" }}
                                >
                                    {!docsLoading ? (
                                        <GscDomainsKP
                                            value={gscConnectedDomain}
                                            setValue={setGscConnectedDomain}
                                            setCompetitorsDomain={
                                                setCompetitorsDomain
                                            }
                                            competitorsDomain={
                                                competitorsDomain
                                            }
                                            isDash={true}
                                            isEnabled={keyWordGapAccess}
                                            hasUsedKeywordGap={
                                                hasUsedKeywordGap
                                            }
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : cluster ? (
                                <>
                                    <div className={styles.right}>
                                        Upload CSV
                                    </div>
                                </> // dashboard modal KP
                            ) : (
                                <div className={styles.exampleText}>
                                    {/* {docsLoading ? "" : "Eg. Keto Diet"} */}
                                </div>
                            )}
                        </div>
                        {showKeywordModal && (
                            <Modal setModal={setShowUploadModal}>
                                <UploadFile
                                    onUploadComplete={(
                                        fileName,
                                        uploadedKeywords,
                                    ) => {
                                        setShowUploadModal(false);
                                        setIsFileUploaded(true);
                                        setUploadedFileName(fileName);
                                        setUploadedKeywords(uploadedKeywords);
                                    }}
                                />
                            </Modal>
                        )}
                    </div>

                    {isDash ? (
                        <p
                            className={styles.tryWithSample}
                            style={{
                                marginTop: isDash ? "1px" : "auto",
                                marginBottom: isDash ? "10px" : "auto",
                            }}
                        >
                            Try for&nbsp;
                            <div className={styles.sampleReportsChips}>
                                {!!sampleClusters.length &&
                                    sampleClusters.map((item) => {
                                        return (
                                            <span
                                                onClick={() => {
                                                    if (
                                                        getTrailText(
                                                            planExpiresDate,
                                                        ) == 7 &&
                                                        planTrial
                                                    ) {
                                                        mixpanelTrack(
                                                            "Day 0 Dashboard",
                                                            {
                                                                action: `Keyword Planner Try For - ${item.name}`,
                                                            },
                                                        );
                                                    }
                                                    goTo(
                                                        "CLUSTER_REPORT",
                                                        [],
                                                        `${item.hash}`,
                                                    );
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                        );
                                    })}
                            </div>
                        </p>
                    ) : (
                        ""
                    )}

                    <LocationList
                        location={location}
                        country={country}
                        setCountry={setCountry}
                        setLocation={setLocation}
                        suggestedLocations={suggestedLocations}
                    />
                    {isDash ? (
                        <Button
                            text={buttonText ? buttonText : "Create"}
                            width={250}
                            Icon={() =>
                                buttonIcon ? (
                                    buttonIcon
                                ) : (
                                    <SVGIcon
                                        size={20}
                                        src={"/New UI/SVG/return.svg"}
                                    />
                                )
                            }
                            handler={(e) => {
                                if (
                                    cluster &&
                                    LockConfigurator(
                                        FEATURE_TYPES.topicClusterLock,
                                    )
                                ) {
                                    return setModal({ open: true, id: -3 });
                                }
                                if (
                                    getTrailText(planExpiresDate) == 7 &&
                                    planTrial
                                ) {
                                    mixpanelTrack("Day 0 Dashboard", {
                                        action: `Report Start${
                                            isDash ? "(Dashboard)" : ""
                                        } - Keyword Planner`,
                                        keywords: inputValue,
                                        url: url ? url : "-",
                                        search_country: country.label
                                            ? country.label
                                            : "-",
                                        ...(keyWordGapAccess
                                            ? {
                                                  gscConnectedDomain,
                                                  competitorsDomain,
                                              }
                                            : {}),
                                    });
                                }
                                if (
                                    inputValue.trim() ||
                                    uploadedKeywords.length > 0
                                ) {
                                    makeReport({
                                        e,
                                        ...(uploadedKeywords.length > 0
                                            ? {
                                                  cluster_keywords:
                                                      uploadedKeywords,
                                              }
                                            : {}),
                                        inputValue:
                                            uploadedKeywords.length > 0
                                                ? Object.keys(
                                                      uploadedKeywords[0],
                                                  )[0]
                                                : inputValue,
                                        country,
                                        setInputValue,

                                        ...(keyWordGapAccess
                                            ? {
                                                  gscConnectedDomain,
                                                  competitorsDomain,
                                              }
                                            : {}),
                                    });
                                    setInputValue("");
                                    resetUpload();
                                    setGscConnectedDomain((ps) => ps);
                                    if (!cruiseMode) {
                                        setShowModal(true);
                                    }
                                }
                            }}
                            borderRadius="3"
                            loading={loadingCreation}
                            height={40}
                            style={{
                                borderRadius: 3,
                                backgroundColor: "#1f4cbe",
                                fontSize: 14,
                                marginTop: isDash ? "20px" : "",
                            }}
                            customClass={styles.buttonMedia}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </HighlightComponent>
            {modal.open && (
                <SeoWithCruiseModal modal={modal} setModal={setModal} />
            )}

            {!isDash && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        paddingBottom: 15,
                        paddingTop: 5,
                        justifyContent: "space-between",
                    }}
                >
                    {!docsLoading ? (
                        <GscDomainsKP
                            value={gscConnectedDomain}
                            setValue={setGscConnectedDomain}
                            setCompetitorsDomain={setCompetitorsDomain}
                            competitorsDomain={competitorsDomain}
                            isEnabled={keyWordGapAccess}
                            hasUsedKeywordGap={hasUsedKeywordGap}
                        />
                    ) : (
                        <div></div>
                    )}

                    <Button
                        text={buttonText ? buttonText : "Create"}
                        width={250}
                        Icon={() =>
                            buttonIcon ? (
                                buttonIcon
                            ) : (
                                <SVGIcon
                                    size={20}
                                    src={"/New UI/SVG/return.svg"}
                                />
                            )
                        }
                        handler={(e) => {
                            if (
                                cluster &&
                                LockConfigurator(FEATURE_TYPES.topicClusterLock)
                            ) {
                                return setModal({ open: true, id: -3 });
                            }
                            if (
                                inputValue.trim() ||
                                uploadedKeywords.length > 0
                            ) {
                                makeReport({
                                    e,
                                    ...(uploadedKeywords.length > 0
                                        ? {
                                              cluster_keywords:
                                                  uploadedKeywords,
                                          }
                                        : {}),
                                    inputValue:
                                        uploadedKeywords.length > 0
                                            ? Object.keys(
                                                  uploadedKeywords[0],
                                              )[0]
                                            : inputValue,
                                    country,
                                    setInputValue,
                                    ...(keyWordGapAccess
                                        ? {
                                              gscConnectedDomain,
                                              competitorsDomain,
                                          }
                                        : {}),
                                });
                                setInputValue("");
                                resetUpload();
                                setGscConnectedDomain((ps) => ps);
                                if (!cruiseMode) {
                                    setShowModal(true);
                                }
                            }
                        }}
                        borderRadius="3"
                        loading={loadingCreation}
                        height={40}
                        style={{
                            borderRadius: 3,
                            backgroundColor: "#1f4cbe",
                            fontSize: 14,
                            marginTop: isDash ? "20px" : "",
                        }}
                        customClass={styles.buttonMedia}
                    />
                </div>
            )}
        </div>
    );
}

const IconRadio = ({ label, checked, onChange, name, disabled }) => {
    return (
        <div className={styles.radioSection}>
            <input
                type="radio"
                checked={checked}
                onChange={onChange}
                name={name}
                disabled={disabled}
                style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    margin: "0px",
                }}
            />
            <label
                style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    fontSize: "12px",
                    marginLeft: "8px",
                    color: "#3f5575",
                    fontWeight: checked ? "600" : "normal",
                }}
                onClick={!disabled ? onChange : null}
            >
                {label}
            </label>
        </div>
    );
};
