import React, { useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import SVGIcon from "../../../atoms/SVGIcon";
import "./dropdown.css";
import useHelperFunctions from "../../../helperFunctions";

// import "react-select/dist/react-select.css";
const customStyles = {
    control: (base) => ({
        ...base,
        minHeight: 40,
        border: "1px solid #f1f1f1",
    }),
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
    menuList: (styles) => ({
        ...styles,
        // marginBottom: "60px",
        maxHeight: "100px",
        // zIndex: 100,
    }),
    indicatorSeparator: (styles) => ({ display: "none" }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#3f5575",
            fontWeight: "400",
            fontSize: "14px",
        };
    },
};
function ReactSelect({
    isDisabled = false,
    selected,
    setSelected,
    setTags,
    options,
    name,
    defaultValues = [],
    crd_id,
    _customStyles,
    onChange,
    isCountryList = false,
}) {
    const { Option, MultiValue, ValueContainer } = components;
    const IconOption = (props) => (
        <Option {...props}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "4px",
                }}
            >
                <img
                    src={`https://flagcdn.com/w20/${props.data.flagCode.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${props.data.flagCode.toLowerCase()}.png 2x`}
                    width={"24px"}
                />
                {props.data.label}
            </div>
        </Option>
    );

    const ValueContainerInput = ({ selectProps, children, ...props }) => {
        let [values, input] = children;
        console.log("ValueContainerInput", values, input);
        if (Array.isArray(values)) {
            values = selectProps.value.map((x) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "4px",
                    }}
                >
                    <img
                        src={`https://flagcdn.com/w20/${x.flagCode.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${x.flagCode.toLowerCase()}.png 2x`}
                        width={"24px"}
                    />
                    {x.flagCode.toUpperCase()}
                </div>
            ));
        }

        return (
            <ValueContainer {...props}>
                <div
                    style={{
                        maxWidth: "60%",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "flex",
                        gap: "10px",
                    }}
                >
                    {values}
                </div>
                {input}
            </ValueContainer>
        );
    };
    const SelectedOption = (props) => (
        <MultiValue {...props}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                }}
            >
                <img
                    src={`https://flagcdn.com/w20/${props.data?.flagCode?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${props.data?.flagCode?.toLowerCase()}.png 2x`}
                    width={"24px"}
                />
                {props?.data?.value?.toUpperCase()}
            </div>
        </MultiValue>
    );
    const DropdownIndicator = (props) => {
        return (
            <>
                <components.DropdownIndicator {...props}>
                    <SVGIcon
                        size={20}
                        src={"/New UI/SVG/dropdown.svg"}
                        style={{
                            cursor: "pointer",
                        }}
                    />
                </components.DropdownIndicator>
                <components.IndicatorSeparator
                    {...props}
                ></components.IndicatorSeparator>
            </>
        );
    };

    const [loading, setLoading] = useState(false);
    const { postData } = useHelperFunctions();

    const filterOptions = (obj, inputValue) => {
        return (
            obj?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
            obj?.data?.code?.toLowerCase()?.includes(inputValue?.toLowerCase())
        );
    };

    const createTag = async (inputVal) => {
        setLoading(true);
        const res = await postData({
            url: "/api/integrations/wordpress/wpTagCreate",
            payload: {
                name: inputVal,
                crd_id: crd_id,
                app_id: 4,
            },
        });
        setLoading(false);
        if (res.status == 200) {
            let temp = res.data?.data;
            temp["value"] = temp?.name;
            temp["label"] = temp?.name;
            setTags([...options, temp]);
        }
    };
    return (
        <div style={{ boxShadow: "0 2px 1px 0 rgba(225, 228, 237, 0.4)" }}>
            {/* {name == "tags" ? (
                <CreatableSelect
                    components={{ DropdownIndicator }}
                    indicatorSeparator={() => null}
                    isDisabled={loading}
                    isLoading={loading}
                    defaultValue={defaultValues}
                    isMulti
                    name={name}
                    onChange={(val) => {
                        setSelected([...val]);
                    }}
                    onCreateOption={(inputVal) => {
                        createTag(inputVal);
                    }}
                    options={options}
                    value={selected}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    minMenuHeight={40}
                    placeholder={`Select ${name}...`}
                    styles={customStyles}
                />
            ) : (
                <Select
                    components={{ DropdownIndicator }}
                    indicatorSeparator={() => null}
                    isDisabled={isDisabled}
                    defaultValue={defaultValues}
                    isMulti
                    name={name}
                    onChange={(val) => {
                        setSelected([...val]);
                    }}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    minMenuHeight={40}
                    placeholder={`Select ${name}...`}
                    styles={customStyles}
                />
            )} */}

            {!isCountryList ? (
                <Select
                    components={{ DropdownIndicator }}
                    indicatorSeparator={() => null}
                    isDisabled={isDisabled}
                    defaultValue={defaultValues}
                    isMulti
                    name={name}
                    onChange={(val) => {
                        setSelected([...val]);
                    }}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    minMenuHeight={40}
                    placeholder={`Select ${name}...`}
                    styles={_customStyles ? _customStyles : customStyles}
                />
            ) : (
                <Select
                    components={{
                        DropdownIndicator,
                        Option: IconOption,
                        MultiValue: SelectedOption,
                    }}
                    indicatorSeparator={() => null}
                    isDisabled={isDisabled}
                    isMulti
                    hideSelectedOptions
                    name={name}
                    isClearable={false}
                    defaultValue={defaultValues}
                    onChange={setSelected}
                    options={options}
                    className="basic-multi-select"
                    filterOption={filterOptions}
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    minMenuHeight={40}
                    placeholder={`Select ${name}...`}
                    isOptionDisabled={(option) =>
                        selected.length >= 5 ? true : false
                    }
                    styles={_customStyles ? _customStyles : customStyles}
                />
            )}
        </div>
    );
}

export default ReactSelect;
