import mixpanel from "mixpanel-browser";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    authSuccess,
    updateUserInfo,
    userLogout,
    VerificationEmail,
} from "../../../store/actions/auth";
import { emitEvent } from "../../../UserDotComEvents";
import useHelperFunctions from "../../helperFunctions";
import * as Sentry from "@sentry/react";
import { setUserVideo } from "../../../store/actions/video";
import { LOGIN_TOKEN } from "./../../../componentsV2/api.json";
import { valueFromCookie } from "./Auth";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { displayAlert } from "../../../store/actions/alert";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { useState } from "react";
import TagManager from "react-gtm-module";

function Verify({ setLoading }) {
    const dispatch = useDispatch();
    const { getData, verifyToken } = useHelperFunctions();
    const ScalenutToken = valueFromCookie("ScalenutToken");
    const { plan_category, plan_type, trialUser, isLoaded } =
        useUserAccessHook();
    const goTo = useNavigation();
    const [quotaExht, setQuotaExht] = useState(0);

    useEffect(() => {
        if (
            location.href.includes("extension/402") ||
            location.href.includes("extension/403") ||
            location.href.includes("extension/405")
        ) {
            setQuotaExht(
                location.href.includes("extension/402")
                    ? 402
                    : location.href.includes("extension/403")
                    ? 403
                    : 405,
            );
            goTo("BILLING_PLANS");
        }
    }, []);

    useEffect(() => {
        if (isLoaded && quotaExht) {
            setQuotaExht(0);
            dispatch(
                displayAlert({
                    alertMessage: "",
                    alertType: "popup",
                    errorData: {
                        status: quotaExht,
                        plan_type: plan_type,
                        plan_category: plan_category,
                        trial_status: trialUser ? 1 : 0,
                        platform: 5,
                    },
                }),
            );
        }
    }, [plan_category, plan_type, trialUser, isLoaded, quotaExht]);

    useEffect(() => {
        (async () => {
            const queryParams = new URLSearchParams(location.search);
            const email = queryParams.get("email");
            const token = queryParams.get("token");
            console.log(email, token);

            if (token && email) {
                dispatch(userLogout(""));
                const res = await getData({
                    url: "/api/verify-email" + location.search,
                });

                if (res.status == 200) {
                    emitEvent(
                        "signed_up",
                        email,
                        res.data.data?.user_info?.signup_mode == 2 ? 2 : 1,
                        res.data.data?.user_info?.segment || 2,
                    );
                    if (res.data.data.access_token) {
                        Sentry.setTag("new-user", "1");
                        localStorage.setItem(
                            "token",
                            res.data?.data?.access_token,
                        );
                        mixpanel.register({
                            "User ID": res.data.data?.user_info?.id,
                            "Signup mode":
                                res.data.data?.user_info?.signup_mode == 2
                                    ? "Mode 2"
                                    : "Mode 1",
                        });
                        mixpanel.identify(res.data?.data?.user_info?.email);
                        mixpanel.people.set({
                            $name: res.data.data?.user_info?.name,
                            $email: res.data.data?.user_info?.email,
                            "First Login":
                                res.data.data?.user_info?.user_created_at,
                            "Plan Type": "-",
                            "Signup Mode":
                                res.data.data?.user_info?.signup_mode == 1
                                    ? "Mode 1"
                                    : "Mode 2",
                            "User Segment": res.data.data?.user_info?.segment
                                ? res.data.data?.user_info?.segment
                                : "-",
                        });
                        mixpanel.track("Verify", { from_google: false });
                        dispatch(
                            authSuccess(
                                res.data?.data?.access_token,
                                res.data?.data?.user_info,
                                false,
                            ),
                        );
                        dispatch(
                            updateUserInfo("tour", {
                                enable: true,
                                step: 1,
                                new_user: true,
                            }),
                        );
                    }

                    TagManager.dataLayer({
                        dataLayer: {
                            user_id: res.data?.data?.user_info?.id || "-",
                            event: "verified_signup_successful",
                        },
                    });
                } else if (res.status == 202) {
                    dispatch(VerificationEmail({ email: "", password: "" }));

                    TagManager.dataLayer({
                        dataLayer: {
                            user_id: res.data?.data?.user_info?.id || "-",
                            event: "verified_signup_successful",
                        },
                    });
                }
            } else {
                if (!localStorage.getItem("token") || ScalenutToken) {
                    setLoading(false);
                    return;
                }
                const response = await verifyToken({
                    url: LOGIN_TOKEN,
                });

                if (response?.status === 200) {
                    if (
                        !response.data.data.user_info.ai_tour ||
                        !response.data.data.user_info.seo_tour
                    )
                        dispatch(
                            updateUserInfo("tour", {
                                enable: true,
                                step: 2,
                                new_user: false,
                            }),
                        );
                    dispatch(setUserVideo(response.data.data.videoPrefs));
                    dispatch(
                        authSuccess(
                            localStorage.getItem("token"),
                            response.data.data.user_info,
                            false,
                        ),
                    );
                } else {
                    // dispatch(userLogout());
                }
            }
            setLoading(false);
        })();
    }, []);
}

export default Verify;
