import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import helperFunctions from "./helperFunctions";
import { OTHER_INFO } from "./api.json";
import { useDispatch, useSelector } from "react-redux";
import {
    updateOtherInfo,
    updateUserSettings,
} from "../store/actions/otherInfo";
import { LOAD_CONFIG } from "../store/actiontypes";
let execute = null;

function useOtherInfo() {
    const userId = useSelector((state) => state.auth.userId);
    const dispatch = useDispatch();
    const { getData } = helperFunctions();
    const [mounted, setMounted] = useState(false);
    const location = useLocation();
    const getOtherInfo = async function () {
        const response = await getData({
            url: OTHER_INFO + userId + "/otherInfo",
        });
        if (response.status === 200) {
            dispatch(
                updateOtherInfo({
                    ...response.data.data,
                    isLoaded: 1,
                }),
            );

            if (response.data.data.config)
                dispatch({
                    data: response.data.data.config,
                    type: LOAD_CONFIG,
                });
        }
    };

    const getUserSettings = async function () {
        const response = await getData({
            url: "/api/user/settings",
        });
        if (response.status === 200) {
            dispatch(
                updateUserSettings({
                    ...response.data.data,
                }),
            );
        }
    };

    const debounce = () => {
        clearTimeout(execute);
        execute = setTimeout(() => {
            getOtherInfo();
        }, 1000);
    };

    useEffect(() => {
        if (mounted) debounce();
    }, [location.pathname]);

    useEffect(() => {
        getOtherInfo();
        getUserSettings();
        setMounted(true);
    }, []);

    return null;
}

export default useOtherInfo;
