import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./superAdmin.module.css";
import { Autocomplete } from "@material-ui/lab";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { TextField, CircularProgress } from "@material-ui/core";
import Select from "react-select";
import Label from "../../componentsV2/atoms/Label/Label";
import Button from "../../componentsV2/atoms/Button/Button";
import { displayAlert } from "../../store/actions/alert";
let execute = null;

const optionsData = [
    { value: 1, label: "Mark as disposable" },
    { value: 0, label: "Mark as safe" },
];
export default function DisposableEmails({ setActiveAction }) {
    const [domain, setDomain] = useState("");
    const [domainObject, setDomainObject] = useState({});
    const [allDomains, setAllDomains] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disposable, setDisposable] = useState(optionsData[0]);
    const { getData, postData } = useHelperFunctions();
    const dispatch = useDispatch();

    async function updateDomain() {
        const res = await postData({
            url: "/api/superAdmin/updateDomain",
            payload: {
                id: domainObject?.id,
                disposableValue: disposable?.value,
            },
        });
        if (res.status == 200) {
            dispatch(
                displayAlert({
                    alertMessage: "Updated successfully",
                    alertType: "success",
                }),
            );
            setDomain("");
            setDomainObject({});
            setAllDomains([]);
            setDisposable(optionsData[0]);
        }
    }

    async function searchDomains(input) {
        if (input.length >= 3) {
            setLoading(true);
            const response = await getData({
                url: "/api/superAdmin/getDomain?name=" + input,
            });
            setLoading(false);
            if (response?.status === 200) {
                setAllDomains(response.data);
            }
        }
    }

    const dbounce = (key) => {
        clearTimeout(execute);
        execute = setTimeout(() => {
            searchDomains(key);
        }, 500);
    };

    return (
        <div className={styles.disposableEmailContainer}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <div style={{ display: "flex", alignItems: "end" }}>
                <div style={{ width: "500px" }}>
                    <Label label="Domain" />
                    <Autocomplete
                        id="domains"
                        className={styles.emailField}
                        freeSolo
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setDomain(newValue);
                                if (
                                    allDomains.filter(
                                        (item) => item.domain === newValue,
                                    )[0]
                                )
                                    setDomainObject(
                                        allDomains.filter(
                                            (item) => item.domain === newValue,
                                        )[0],
                                    );
                            } else {
                                setDomain("");
                                setDomainObject({});
                            }
                        }}
                        value={domain}
                        options={allDomains.map((item) => item?.domain)}
                        renderInput={(params) => (
                            <>
                                {loading && (
                                    <CircularProgress
                                        className={styles.progress}
                                        size={15}
                                    />
                                )}
                                <TextField
                                    className={styles.field}
                                    {...params}
                                    margin="normal"
                                    onChange={(e) => dbounce(e.target.value)}
                                />
                            </>
                        )}
                    />
                </div>
                <div
                    style={{
                        width: "300px",
                        marginRight: "10px",
                        marginLeft: "10px",
                        marginBottom: "5px",
                    }}
                >
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(val) => {
                            setDisposable(val);
                        }}
                        defaultValue={optionsData[0]}
                        // name="color"
                        options={optionsData}
                    />
                </div>
                <Button
                    handler={() => updateDomain()}
                    disabled={!domain}
                    style={{ marginBottom: "5px" }}
                    height={38}
                    width="200px"
                    text="Submit"
                />
            </div>
        </div>
    );
}
