function convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
            if (line != "") line += ",";

            line += array[i][index];
        }

        str += line + "\r\n";
    }

    return str;
}

export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function download(data, fileName) {
    var headers = {
        email: "Email", // remove commas to avoid errors
        name: "Name",
        stage: "Stage",
        status: "Status",
        request_id: "Request ID",
        words_count: "Words Count",
        role: "Role",
        freelancer_type: "FreeLancer Type",
    };

    var itemsFormatted = [];

    // format the data
    data.forEach((item) => {
        itemsFormatted.push({
            email: item.email, // remove commas to avoid errors,
            name: item.name,
            stage: item.stage,
            status: item.status,
            request_id: item.request_id,
            words_count: item.words_count,
            role: item.role,
            freelancer_type: item.freelancer_type,
        });
    });

    exportCSVFile(headers, itemsFormatted, fileName); // call the exportCSVFile() function to process the JSON and trigger the download
}
