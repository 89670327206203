import React from "react";
import ErrorBoundaryComponent from "./ErrorBoundaryComponent";
import Highlighter from "react-highlight-words";

function CustomHighlighter(props) {
    return (
        <ErrorBoundaryComponent
            placeholder={
                <Highlighter {...props} autoEscape={!props.autoEscape} />
            }
        >
            <Highlighter {...props} />
        </ErrorBoundaryComponent>
    );
}

export default CustomHighlighter;
