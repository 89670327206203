import React, { useEffect, useRef } from "react";
import { useState } from "react";
import useHelperFunctions from "../../../helperFunctions";
import Favourite from "./Favourite";
import FavouriteCard from "./FavouriteCard";
import FavouriteLoading from "./FavouriteLoading";
import styles from "./Favourite.module.css";
import NoFavourite from "./NoFavourite";
let setLocalData = null;
const renderItems = (data, setModalTopic, setModalData, ref, setModal) => {
  const arr = [];
  console.log();
  for (const item in data) {
    arr.push(
      <>
        <h3 className={styles.cardListHeader}>
          Saved under <span>{item}</span>
        </h3>
        <div className={styles.cardListContainer}>
          <div ref={ref} className={styles.cardList}>
            <div className={styles.blurEnd}></div>
            {renderItem(
              data[item],
              item,
              setModalTopic,
              setModalData,
              setModal
            )}
          </div>
        </div>
      </>
    );
  }
  return arr;
};

const renderItem = (item, itemTopic, setModalTopic, setModalData, setModal) => {
  const arr = [];

  for (const card in item) {
    arr.push(
      <FavouriteCard
        data={item[card]}
        onClick={(localData, setLocal) => {
          setLocalData = setLocal;
          setModalData(localData);
          setModal(true);
          setModalTopic(itemTopic);
        }}
      ></FavouriteCard>
    );
  }
  return arr;
};

function GenerateFavourite({ setAllUseCases }) {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [modal, setModal] = useState(false);
  const ref = useRef();

  const [modalData, setModalData] = useState([]);
  const [modalTopic, setModalTopic] = useState("");
  const { getData } = useHelperFunctions();

  useEffect(() => {
    getFavs(1, setHasMore);
  }, []);

  const getFavs = async (page, setHasMore) => {
    if (page == 1) setLoader(true);
    const res = await getData({ url: "/api/generates/favorites?page=" + page });
    setLoader(false);

    if (res.status == 200) {
      const dataList = renderItems(
        res.data.data,
        setModalTopic,
        setModalData,
        ref,
        setModal
      );

      if (dataList.length == 0) setHasMore(false);

      setData((ps) => ps.concat(dataList));
    }
  };

  return loader ? (
    <FavouriteLoading />
  ) : Object.keys(data).length == 0 ? (
    <NoFavourite setAllUseCases={setAllUseCases} />
  ) : (
    <Favourite
      getFavs={getFavs}
      setHasMore={setHasMore}
      modalData={modalData}
      page={page}
      setPage={setPage}
      modal={modal}
      setLocalData={setLocalData}
      setModal={setModal}
      modalTopic={modalTopic}
      hasMore={hasMore}
      data={data}
    />
  );
}

export default GenerateFavourite;
