import React from "react";
import styles from "./ModalGoNuts.module.css";
import MUIModal from "@material-ui/core/Modal";

import { useSelector } from "react-redux";

function ModalGoNuts({ setModal, children, isPremium = false }) {
    const { isMobile } = useSelector((state) => state.state);
    return (
        <div
            style={isMobile ? { height: "80vh", overflow: "auto" } : {}}
            className={`${styles.container} ${
                isPremium && styles.premiumWrapper
            }`}
        >
            {children}
        </div>
    );
}

export default ModalGoNuts;
