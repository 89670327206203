import React, { useState } from "react";
import styles from "./../../assets/styles/trialNoti.module.css";
import { IoMdClose } from "react-icons/io";
import { PLANS_CATEGORIES } from "../../constants";
import Button from "../../componentsV2/atoms/Button/Button";
import useNavigation from "../../componentsV2/atoms/GoToRoute/useNavigation";
import { useLocation } from "react-router-dom";

export function days(info) {
  const days = Math.floor(
    (new Date(info) - new Date()) / (1000 * 60 * 60 * 24)
  );
  return days;
}

export function hours(info) {
  const hrs = Math.floor(
    ((new Date(info) - new Date()) / (1000 * 60 * 60)) % 24
  );

  return hrs;
}

function TrialNoti({ otherInfo, expiryDate }) {
  const [enable, setEnable] = useState(true);
  const goTo = useNavigation();

  const location = useLocation();

  return enable &&
    !(
      otherInfo.subscriptionInfo.plan_category == PLANS_CATEGORIES.TRAIL &&
      days(expiryDate) > 5
    ) ? (
    <div className={styles.trialPeriod}>
      {!location.pathname.includes("plans") ? (
        otherInfo.subscriptionInfo.plan_category == PLANS_CATEGORIES.TRAIL ? (
          <>
            <span className={styles.timeLeft}>
              Your trial expires in {days(expiryDate)} days, {hours(expiryDate)}{" "}
              hours.
            </span>{" "}
            Lock-in <b> &nbsp; 60% off &nbsp; </b> on all annual plans, year
            after year!
            {/* <div
              className={styles.couponCode}
              style={{
                width: "unset",

                padding: 0,
                backgroundColor: "transparent",
                color: "inherit",
              }}
            >
              {" "}
              <img src="/dashboard-ticker-couponicon.svg" /> use code:{" "}
              <b>SCALEUP</b>
            </div> */}
          </>
        ) : (
          <>
            <span className={styles.timeLeft}>
              {" "}
              Power up your content in 2022.
            </span>{" "}
            Lock-in <b> &nbsp; 60% off &nbsp; </b> on all annual plans, year
            after year!
            {/* <div
              className={styles.couponCode}
              style={{
                width: "unset",

                padding: 0,
                backgroundColor: "transparent",
                color: "inherit",
              }}
            >
              {" "}
              <img src="/dashboard-ticker-couponicon.svg" /> use code{" "}
              <b>SCALEUP</b>{" "}
            </div> */}
          </>
        )
      ) : (
        <>
          Lock-in <b> &nbsp; 60% off &nbsp; </b> on all annual plans, year after
          year!
          {/* <div className={styles.couponCode}>
            {" "}
            <img src="/pricing-ticker-couponicon.svg" /> use code <b>SCALEUP</b>{" "}
          </div>{" "}
          at checkout.{" "} */}
          {/* <span
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "#014dc5",
              marginLeft: "5px",
            }}
          >
            {" "}
            Limited time deal.
          </span> */}
        </>
      )}

      {!location.pathname.includes("plans") && (
        <Button
          text="Get Special Deal"
          handler={() => goTo("BILLING_PLANS")}
          width="150px"
          style={{
            backgroundColor: "#014dc5",
            color: "white",
            marginLeft: "20px",
            height: "25px",
          }}
        />
      )}
      {/* <IoMdClose
        onClick={() => setEnable(false)}
        className={styles.closeTrial}
        style={{ fontSize: "1.3rem" }}
      /> */}
    </div>
  ) : null;
}

export default TrialNoti;
