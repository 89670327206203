import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { displayAlert } from "../../../store/actions/alert";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Modal from "../../atoms/Modal/Modal";
import styles from "./styles.module.css";
import Button from "../../atoms/Button/Button";
import { BiSupport, BiWallet } from "react-icons/bi";
import useHelperFunctions from "../../helperFunctions";
import { PLANS_CATEGORIES } from "../../../constants";
import { transactionTypes } from "../../../components/account/components/Transactions/Transactions";
const proPlanCategory = 4;
const LTD_PLANS = 3;
const trailPlan = 1;

function ExpiryPopup({ isUpgrade = false }) {
    const goTo = useNavigation();
    const dispatch = useDispatch();
    const { postData } = useHelperFunctions();
    const otherInfo = useSelector((state) => state.otherInfo);
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );

    function removePopup() {
        dispatch(displayAlert({ alertType: "" }));
    }

    const allowCreditsDeduction = async () => {
        const res = await postData({
            url: "/api/pricing/updateCreditPermission",
        });

        if (res.status == 200) {
            removePopup();
        }
    };

    const alert = useSelector((state) => state.alert);

    return (
        <Modal
            setModal={() => {
                removePopup();
            }}
        >
            {
                <div className={styles.exContainer}>
                    <MdClose
                        className={styles.cross}
                        onClick={() => {
                            removePopup();
                        }}
                    />
                    <img src="/AssetsV2/plan-exceeded-icon.svg" alt="" />

                    {alert.errorData.status == 405 ? (
                        <>
                            <div>
                                System detected abnormal usage against this
                                account. <br /> Please contact support on chat
                                to enable usage on your account. Otherwise, the
                                usage quotas will automatically refresh next
                                month.
                            </div>
                            <div style={{ display: "flex", gap: 30 }}>
                                <Button
                                    text="Talk to Support"
                                    handler={() => {
                                        removePopup();
                                        window.userengage &&
                                            window.userengage("widget.show");
                                        window.userengage &&
                                            window.userengage("widget.open");
                                    }}
                                    Icon={BiSupport}
                                    iconReverse={true}
                                    style={{
                                        width: "170px",
                                        height: "40px",
                                        borderRadius: 3,
                                        backgroundColor:
                                            "rgba(1, 77, 197, 0.1)",
                                        color: "#014dc5",
                                    }}
                                ></Button>
                                <Button
                                    text="Ok"
                                    handler={() => {
                                        removePopup();
                                    }}
                                    style={{
                                        width: "170px",
                                        height: "40px",
                                        borderRadius: "2px",
                                        backgroundColor: "#014dc5",
                                    }}
                                ></Button>
                            </div>{" "}
                        </>
                    ) : (
                        <>
                            {company_user_type != 2 ? (
                                alert.errorData.plan_category ==
                                PLANS_CATEGORIES.TRAIL ? (
                                    <>
                                        <div>
                                            Alert! Daily limit for{" "}
                                            {
                                                transactionTypes[
                                                    alert.errorData.platform
                                                ]
                                            }{" "}
                                            has been reached in trial period.{" "}
                                            <br />
                                            To remove daily limits, upgrade to a
                                            plan.
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                            }}
                                        >
                                            <Button
                                                text="Upgrade Plan"
                                                handler={() => {
                                                    removePopup();
                                                    goTo("BILLING_PLANS");
                                                }}
                                                style={{
                                                    width: "170px",
                                                    height: "40px",
                                                    borderRadius: "2px",
                                                    backgroundColor: "#014dc5",
                                                }}
                                            ></Button>

                                            <Button
                                                text="Cancel"
                                                handler={() => {
                                                    removePopup();
                                                }}
                                                style={{
                                                    width: "170px",
                                                    height: "40px",
                                                    borderRadius: 3,
                                                    backgroundColor:
                                                        "rgba(1, 77, 197, 0.1)",
                                                    color: "#014dc5",
                                                }}
                                            ></Button>
                                        </div>
                                    </>
                                ) : alert.errorData.trial_status == 1 ? (
                                    <>
                                        <div>
                                            Daily limit in free trial period
                                            exhausted. In case you would like to
                                            continue using the platform today,
                                            kindly reach out to support.
                                            Otherwise, you may come back
                                            tomorrow to use the platform.
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                            }}
                                        >
                                            <Button
                                                handler={() => {
                                                    window.userengage &&
                                                        window.userengage(
                                                            "widget.show",
                                                        );
                                                    window.userengage &&
                                                        window.userengage(
                                                            "widget.open",
                                                        );
                                                    removePopup();
                                                }}
                                                text="Contact Chat Support"
                                                style={{
                                                    width: "220px",
                                                    height: "40px",
                                                    borderRadius: "2px",
                                                    backgroundColor: "#014dc5",
                                                }}
                                            ></Button>

                                            <Button
                                                text="I will come back tomorrow"
                                                handler={() => {
                                                    removePopup();
                                                }}
                                                style={{
                                                    width: "250px",
                                                    height: "40px",
                                                    borderRadius: 3,
                                                    backgroundColor:
                                                        "rgba(1, 77, 197, 0.1)",
                                                    color: "#014dc5",
                                                }}
                                            ></Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            {`Your monthly quota for ${
                                                transactionTypes[
                                                    alert.errorData.platform
                                                ]
                                            } is exhausted!`}
                                            <br />
                                            {![8, 9].includes(
                                                alert.errorData.platform,
                                            ) && (
                                                <>
                                                    {!(
                                                        alert.errorData
                                                            .plan_category ==
                                                            PLANS_CATEGORIES.FREE_FOREVER ||
                                                        alert.errorData
                                                            .plan_category ==
                                                            PLANS_CATEGORIES.INDIVIDUAL
                                                    ) ||
                                                    (PLANS_CATEGORIES.INDIVIDUAL ==
                                                        otherInfo
                                                            ?.subscriptionInfo
                                                            ?.plan_category &&
                                                        3 ==
                                                            otherInfo
                                                                ?.subscriptionInfo
                                                                ?.type) ? (
                                                        <>
                                                            {alert.errorData
                                                                .status ==
                                                            402 ? (
                                                                <>
                                                                    Any usage
                                                                    henceforth
                                                                    will be
                                                                    accounted
                                                                    against your
                                                                    account
                                                                    credit
                                                                    balance.{" "}
                                                                    <br />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {proPlanCategory !=
                                                                        alert
                                                                            .errorData
                                                                            .plan_category &&
                                                                    otherInfo
                                                                        ?.subscriptionInfo
                                                                        ?.type !=
                                                                        3 &&
                                                                    alert
                                                                        .errorData
                                                                        .plan_category ==
                                                                        PLANS_CATEGORIES.FREE_FOREVER ? (
                                                                        <>
                                                                            Upgrade
                                                                            Plan
                                                                            to
                                                                            get
                                                                            unlimited
                                                                            credits.{" "}
                                                                            <br />{" "}
                                                                            Alternatively,
                                                                            recharge
                                                                            credits
                                                                            to
                                                                            continue
                                                                            using
                                                                            Scalenut
                                                                            until
                                                                            the
                                                                            end
                                                                            of
                                                                            the
                                                                            billing
                                                                            month.
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <br />
                                                                            Recharge
                                                                            credits
                                                                            to
                                                                            continue
                                                                            using
                                                                            Scalenut
                                                                            until
                                                                            the
                                                                            end
                                                                            of
                                                                            the
                                                                            billing
                                                                            month.
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <br />
                                                            {alert.errorData
                                                                .status ==
                                                            402 ? (
                                                                <>
                                                                    Any usage
                                                                    henceforth
                                                                    will be
                                                                    accounted
                                                                    against your
                                                                    account
                                                                    credit
                                                                    balance.
                                                                    Alternatively,{" "}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            Upgrade Plan to get
                                                            unlimited credits.
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {alert.errorData.status == 402 ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                }}
                                            >
                                                {![8, 9].includes(
                                                    alert.errorData.platform,
                                                ) && (
                                                    <Button
                                                        text="Continue"
                                                        handler={() => {
                                                            allowCreditsDeduction();
                                                        }}
                                                        style={{
                                                            width: "170px",
                                                            height: "40px",
                                                            borderRadius: 3,
                                                            backgroundColor:
                                                                "#014dc5",
                                                        }}
                                                    ></Button>
                                                )}
                                                {(!(
                                                    LTD_PLANS ==
                                                        alert.errorData
                                                            .plan_type ||
                                                    proPlanCategory ==
                                                        alert.errorData
                                                            .plan_category
                                                ) ||
                                                    PLANS_CATEGORIES.FREE_FOREVER ==
                                                        alert.errorData
                                                            .plan_category) && (
                                                    <Button
                                                        text="Upgrade Plan"
                                                        handler={() => {
                                                            removePopup();
                                                            goTo(
                                                                "BILLING_PLANS",
                                                            );
                                                        }}
                                                        style={{
                                                            width: "170px",
                                                            height: "40px",
                                                            borderRadius: "2px",
                                                            backgroundColor:
                                                                "#014dc5",
                                                        }}
                                                    ></Button>
                                                )}

                                                <Button
                                                    text="Talk to Support"
                                                    handler={() => {
                                                        removePopup();
                                                        window.userengage &&
                                                            window.userengage(
                                                                "widget.show",
                                                            );
                                                        window.userengage &&
                                                            window.userengage(
                                                                "widget.open",
                                                            );
                                                    }}
                                                    Icon={BiSupport}
                                                    iconReverse={true}
                                                    style={{
                                                        width: "170px",
                                                        height: "40px",
                                                        borderRadius: 3,
                                                        backgroundColor:
                                                            "rgba(1, 77, 197, 0.1)",
                                                        color: "#014dc5",
                                                    }}
                                                ></Button>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                }}
                                            >
                                                {(!(
                                                    alert.errorData
                                                        .plan_category ==
                                                        PLANS_CATEGORIES.FREE_FOREVER ||
                                                    alert.errorData
                                                        .plan_category ==
                                                        PLANS_CATEGORIES.INDIVIDUAL
                                                ) ||
                                                    (PLANS_CATEGORIES.INDIVIDUAL ==
                                                        otherInfo
                                                            ?.subscriptionInfo
                                                            ?.plan_category &&
                                                        3 ==
                                                            otherInfo
                                                                ?.subscriptionInfo
                                                                ?.type)) && (
                                                    <Button
                                                        text="Recharge Credits"
                                                        handler={() => {
                                                            removePopup();
                                                            goTo(
                                                                "TRANSACTIONS",
                                                            );
                                                        }}
                                                        Icon={BiWallet}
                                                        iconReverse={true}
                                                        style={{
                                                            width: "170px",
                                                            height: "40px",
                                                            borderRadius: 3,
                                                            backgroundColor:
                                                                "rgba(1, 77, 197, 0.1)",
                                                            color: "#014dc5",
                                                        }}
                                                    ></Button>
                                                )}

                                                {(!(
                                                    LTD_PLANS ==
                                                        alert.errorData
                                                            .plan_type ||
                                                    proPlanCategory ==
                                                        alert.errorData
                                                            .plan_category
                                                ) ||
                                                    PLANS_CATEGORIES.FREE_FOREVER ==
                                                        alert.errorData
                                                            .plan_category) && (
                                                    <Button
                                                        text="Upgrade Plan"
                                                        handler={() => {
                                                            removePopup();
                                                            goTo(
                                                                "BILLING_PLANS",
                                                            );
                                                        }}
                                                        style={{
                                                            width: "170px",
                                                            height: "40px",
                                                            borderRadius: "2px",
                                                            backgroundColor:
                                                                "#014dc5",
                                                        }}
                                                    ></Button>
                                                )}
                                                <Button
                                                    text="Talk to Support"
                                                    handler={() => {
                                                        removePopup();
                                                        window.userengage &&
                                                            window.userengage(
                                                                "widget.show",
                                                            );
                                                        window.userengage &&
                                                            window.userengage(
                                                                "widget.open",
                                                            );
                                                    }}
                                                    Icon={BiSupport}
                                                    iconReverse={true}
                                                    style={{
                                                        width: "170px",
                                                        height: "40px",
                                                        borderRadius: 3,
                                                        backgroundColor:
                                                            "rgba(1, 77, 197, 0.1)",
                                                        color: "#014dc5",
                                                    }}
                                                ></Button>
                                            </div>
                                        )}
                                    </>
                                )
                            ) : (
                                <>
                                    {" "}
                                    <div>
                                        {alert.errorData.status == 402 ? (
                                            <>
                                                Your monthly quota for{" "}
                                                {
                                                    transactionTypes[
                                                        alert.errorData.platform
                                                    ]
                                                }{" "}
                                                is exhausted! Any usage
                                                henceforth will be accounted
                                                against team account credit
                                                balance.
                                                <br /> Contact Admin for further
                                                details.
                                            </>
                                        ) : (
                                            <>
                                                Your monthly quota for{" "}
                                                {
                                                    transactionTypes[
                                                        alert.errorData.platform
                                                    ]
                                                }{" "}
                                                is exhausted! Contact Admin for
                                                further details.
                                            </>
                                        )}
                                    </div>
                                    {alert.errorData.status == 402 ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                            }}
                                        >
                                            <Button
                                                text="Continue"
                                                handler={() => {
                                                    allowCreditsDeduction();
                                                }}
                                                style={{
                                                    width: "170px",
                                                    height: "40px",
                                                    borderRadius: "2px",
                                                    backgroundColor: "#014dc5",
                                                }}
                                            ></Button>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                            }}
                                        >
                                            <Button
                                                text="Ok"
                                                handler={() => {
                                                    removePopup();
                                                }}
                                                style={{
                                                    width: "170px",
                                                    height: "40px",
                                                    borderRadius: "2px",
                                                    backgroundColor: "#014dc5",
                                                }}
                                            ></Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            }
        </Modal>
    );
}

export default ExpiryPopup;
