import React, {
    Children,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import TextfieldV2 from "../../../atoms/CustomTextfield/Textfield";
import styles from "./KeywordInput.module.css";
import { AiFillCaretDown } from "react-icons/ai";
import Button from "../../../atoms/Button/Button";
import { BiSearch } from "react-icons/bi";
import CountrySelect from "./CountrySelect";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import useHelperFunctions from "../../../helperFunctions";
import GuideTile from "../../GuideTile/GuideTile";
import { displayAlert } from "../../../../store/actions/alert";
import GuideTileInd from "../../GuideTile/GuideTileInd";
import { Autocomplete } from "@material-ui/lab";
import { LOCATION_SUGGESTIONS } from "../../../api.json";
import { thousands_separators } from "../../../../utility/validateFunctions";
import SVGIcon from "../../../atoms/SVGIcon";
import HighlightComponent from "../../../atoms/HighlightComponent";
import { OnBoardingContext } from "../../../Context/OnBoardingContext/OnBoardingContextProvider";
import { CREATE_REPORT_FOCUS } from "../../../Context/OnBoardingContext/constant";
import {
    Chip,
    makeStyles,
    TextField,
    Checkbox,
    ClickAwayListener,
} from "@material-ui/core";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import ToggleButton from "../../../atoms/CustomToggle/ToggleButtonModified";
import { RiCloseLine } from "react-icons/ri";
import { BsLink45Deg } from "react-icons/bs";
import LocationList from "./LocationList";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../../components/account/useUserAccessHook";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { SeoWithCruiseModal } from "../../../Pages/NewDashboard/NewDashboard";
import { escapeRegExp, isValidUrl } from "../../../../utility/helperFun";
import Dropdown from "../../../atoms/Dropdown/Dropdown";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import TopUrlsSelect from "./TopUrlsSelect";
import { CheckBox } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Modal from "../../../atoms/Modal/Modal";
import CreditsModal from "./CreditsModal";
import Select, { components } from "react-select";
import ReactSelect from "../../../organisms/Research/Wordpress/ReactSelect";
import { getTrailText } from "../../../Pages/NewDashboard/constants";
import { setCruiseModePopup } from "../../../../store/actions/state";
import { updateUserSettings } from "../../../../store/actions/otherInfo";
let timeout = null;
let timeout2 = null;
let script;
const topUrls = [
    { value: 10, label: "Top 10" },
    { value: 20, label: "Top 20" },
    { value: 30, label: "Top 30" },
];

const customStyles = {
    container: (base) => ({
        ...base,
        height: 38,
    }),
    control: (base) => ({
        ...base,
        fontSize: "14px",
        color: "#6b6b6b",
        height: 38,
        fontWeight: 300,
        width: 92,
        // border: "1px solid #d2d2d2",
        border: "none",
        boxShadow: "none",
        position: "relative",
        backgroundColor: "transparent",
        "&:hover": {
            // border: "1px solid #d2d2d2",
            borderColor: "#d2d2d2",
            boxShadow: "none",
            border: "none",
            position: "relative",
        },
    }),
    singleValue: (base) => ({
        ...base,
        color: "#6b6b6b",
        fontSize: "14px",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "14px",
        color: "#6b6b6b",
        zIndex: 100,
    }),
    menuList: (styles) => ({
        ...styles,
        marginTop: "-15px",
        fontSize: "14px",
        color: "#6b6b6b",
        // maxHeight: "100px",
        // zIndex: 100,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? "#1f4cbe" : "#fff",
            color: isSelected ? "#FFF" : "#6b6b6b",
            cursor: isDisabled ? "not-allowed" : "default",
        };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
};

const muiSelect = makeStyles((theme) => ({
    root: {
        color: "var(--secondary-color)",
        opacity: 0.8,
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "inter",
    },
}));

const muiSelectWrapper = makeStyles((theme) => ({
    root: {
        "& .MuiInputBase-root": {
            borderRadius: 3,
            marginRight: 10,
            minWidth: 120,
        },
    },
}));

export const autoCustom = makeStyles((theme) => ({
    root: {
        border: "none",
        margin: "0",
        padding: "0px",
        borderRadius: 3,
        background: "#fff",
        minHeight: 38,
        maxHeight: 120,
        minWidth: "calc(100% - 100px)",
        overflow: "auto",
    },
    tag: {
        border: "1px solid transparent",
        background: "#e8edf8cc",
        margin: "0",
        padding: "0",
        borderRadius: 3,
        padding: "0px 13px",
    },
}));

const chipCustom = makeStyles((theme) => ({
    root: {
        border: "1px solid #e5e5e5",
        padding: "0",
        borderRadius: 3,
        margin: "3px",
        marginRight: "3px",
        marginLeft: "3px",
        height: "26px",
        cursor: "text",
    },
    label: {
        fontSize: "14px",
        fontWeight: "500",
    },
    deleteIcon: {
        backgroundColor: "transparent",
    },
}));

export const inputCustom = makeStyles((theme) => ({
    root: {
        " & >  .MuiInput-root": { padding: "3px 7px" },
        "& > div::before": {
            borderBottom: "none",
        },
        "& > div:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "&.Mui-focused::before": {
            borderBottom: "none !important",
        },
        "&.Mui-underline": {
            background: "white",
        },
        "& textarea::placeholder": {
            fontSize: 14,
            color: "#6b6b6b",
            fontWeight: 300,
            opacity: 1,
            fontFamily: "inter",
        },
        "& textarea": {
            fontSize: 14,
            fontWeight: 400,
            opacity: 1,
            fontFamily: "inter",
            minWidth: "80px !important",
            maxHeight: 40,
        },
    },
}));

export default function KeywordInput({
    makeReport,
    loadingCreation,
    docs,
    apiCalls,
    buttonText,
    buttonIcon,
    cruiseMode,
    isCruiseOpen,
    sampleReports = [],
    cluster = false,
    isDash = false,
    isImportUrl = false,
    setModal,
    keywordResearcher,
    audit = false,
    firstTimeUser = false,
}) {
    const dispatch = useDispatch();
    const [suggestedLocations, setSuggestedLocations] = useState([]);
    const [suggestedKeywords, setSuggestedKeyword] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [location, setLocation] = useState("");
    const [showTopUrls, setShowTopUrls] = useState(false);
    const [country, setCountry] = useState({ label: "United States" });
    const { getData } = useHelperFunctions();
    const onBoarding = useContext(OnBoardingContext);
    const userSettings = useSelector((state) => state?.otherInfo?.userSettings);
    const goTo = useNavigation();
    const [keywords, setKeywords] = useState([]);
    const [topCompUrls, setTopCompUrls] = useState(topUrls[0]);
    const [importFromUrl, setImportFromUrl] = useState(false);
    const [url, setUrl] = useState("");
    const autoClasses = autoCustom();
    const [invalidInput, setInvalidInput] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState(false);
    if (invalidInput) {
        setTimeout(() => {
            setInvalidInput(false);
        }, 2000);
    }
    const inputClasses = inputCustom();
    const muiSelectClasses = muiSelect();
    const muiSelectWrapperClasses = muiSelectWrapper();
    const [modal, _setModal] = useState({ open: false, id: 0 });
    const { LockConfigurator, isTeamMember, planExpiresDate, planTrial } =
        useUserAccessHook();
    const { mpTrackReportCreateCta, mixpanelTrack } = useMixpanelHook();
    const [is_premium, setPremiumCruise] = useState(false);
    const [cruiseCreditModal, setCruiseCreditModal] = useState(false);
    const otherInfo = useSelector((state) => state.otherInfo);

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <SVGIcon
                    size={14}
                    src={"/New UI/SVG/chevronDown.svg"}
                    style={{
                        cursor: "pointer",
                        color: "#6b6b6b",
                    }}
                />
            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            suggestLoaction();
        }, 200);
    }, [location]);

    useEffect(() => {
        if (userSettings?.competitors_count) {
            if (userSettings?.competitors_count == 30) {
                setTopCompUrls(topUrls[2]);
            } else if (userSettings?.competitors_count == 20) {
                setTopCompUrls(topUrls[1]);
            } else {
                setTopCompUrls(topUrls[0]);
            }
        }
    }, [userSettings?.competitors_count]);

    useEffect(() => {
        const set = new Set(keywords);
        if (set.size < keywords.length) setKeywords(Array.from(set));
        console.log("keywords", keywords);
    }, [keywords]);

    useEffect(() => {
        if (apiCalls == 1 && docs.length) {
            setCountry({ label: docs[0].search_country });
        }
    }, [docs]);

    const suggestLoaction = async () => {
        if (typeof location == "string") {
            const res = await getData({
                url: `${LOCATION_SUGGESTIONS}?query=${location}`,
            });
            if (res.status == 200) {
                setSuggestedLocations(res.data.locations);
            }
        }
    };

    // function checkEnglish(word) {
    //   let isEnglish = true;
    //   for (let i of word) {
    //     let index = i.charCodeAt(0);
    //     if (index >= 32 && index <= 122) {
    //     } else {
    //       console.log(i, "hello hello", index);
    //       isEnglish = false;
    //     }
    //   }
    //   return isEnglish;
    // }

    useEffect(() => {
        clearTimeout(timeout2);
        timeout2 = setTimeout(() => {
            suggestKeyword();
        }, 100);
    }, [inputValue, location]);

    const suggestKeyword = async () => {
        if (script) script.remove();
        const completeUrl = `https://www.google.com/complete/search?output=search&client=chrome&q=${inputValue}&hl=en-US&gl=${
            country.code
        }&cp=${
            document.getElementById("keywordSuggestionInput")?.firstChild
                ? document.getElementById("keywordSuggestionInput").firstChild
                      .selectionEnd
                : inputValue.length
        }&callback=s12s12&_=${+new Date()}`;

        script = document.createElement("script");

        script.src = completeUrl;
        document.head.append(script);
    };

    useEffect(() => {
        window.s12s12 = (e) => {
            if (script) script.remove();
            setSuggestedKeyword(e[1]);
        };
    }, []);

    // useEffect(() => {
    //   document.removeEventListener("keyup", createReportWithEnter);
    //   document.addEventListener("keyup", createReportWithEnter);

    //   return () => document.removeEventListener("keyup", createReportWithEnter);
    // }, [inputValue, country, loadingCreation]);

    const handleChange = (b) => {
        b = b.map((i) => i?.split(/,|\n/) || []);

        let arr = [];
        for (const item of b) {
            for (const i of item) {
                const s = i;
                if (s.trim().length) {
                    arr.push(s);
                }
            }
        }
        arr = Array.from(new Set(arr));
        setKeywords(arr);
    };

    const handleEditChip = (e) => {
        console.log("editchip", e.target);
    };

    return (
        <HighlightComponent
            highlight={onBoarding.focusOn == CREATE_REPORT_FOCUS}
        >
            <div
                className={
                    cruiseMode
                        ? styles.keywordInputModalContainer
                        : cluster
                        ? styles.keywordInputContainerCluster
                        : styles.keywordInputContainer
                }
                id={"12312"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: cruiseMode ? "column" : "column-reverse",
                    }}
                >
                    {(isImportUrl || audit) && !cruiseMode && (
                        <div>
                            <Textfield
                                Icon={() => <BsLink45Deg size={20} />}
                                iconStyle={{
                                    left: "10px",
                                    right: "unset",
                                    top: "20px",
                                }}
                                placeholder={
                                    "Enter the URL to Optimize (Optional)"
                                }
                                containerStyle={{
                                    marginBottom: "0px",
                                    marginTop: "3px",
                                    width: "100%",
                                }}
                                label=""
                                props={{
                                    value: url,
                                    onChange: (e) => {
                                        setUrl(e.target.value);
                                    },
                                    style: {
                                        boxShadow: "none",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        paddingLeft: "40px",
                                    },
                                }}
                                customClass={
                                    invalidUrl
                                        ? styles.invalidUrl
                                        : styles.urlInput
                                }
                                errMsg={
                                    url.length > 0 && !isValidUrl(url)
                                        ? "Invalid URL"
                                        : url.indexOf("docs.google.com") != -1
                                        ? "We do not support Google Docs yet"
                                        : ""
                                }
                                disabled={loadingCreation}
                                autoFocus={isDash ? true : false}
                            />
                            <div className={styles.instructLine}>
                                <div
                                    style={{
                                        paddingTop: "5px",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        whiteSpace: "nowrap",
                                    }}
                                ></div>
                            </div>
                            <div className={styles.optimizerSubText}>
                                You may skip the URL field to manually add the
                                content later
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            ...(cruiseMode ? { flexDirection: "column" } : {}),
                        }}
                        className={
                            styles.keywordInput +
                            " " +
                            (isDash && styles.dashboard)
                        }
                    >
                        <div
                            style={{
                                flexGrow: 1,
                                position: "relative",
                            }}
                            className={styles.textFieldDiv}
                        >
                            {firstTimeUser && (
                                <div
                                    style={{
                                        color: "#2A4266",
                                        fontSize: "14px",
                                        marginBottom: "3px",
                                    }}
                                >
                                    Tell us your focus keyword.
                                </div>
                            )}
                            <div
                                style={{
                                    display: "flex",
                                    border: "1px solid #d2d2d2",
                                    borderRadius: "3px",
                                    alignItems: "center",
                                }}
                            >
                                <Autocomplete
                                    multiple
                                    inputValue={inputValue}
                                    classes={{
                                        ...autoClasses,
                                        root: invalidInput
                                            ? styles.invalidInput
                                            : autoClasses.root,
                                    }}
                                    // className={styles.inputMedia}
                                    onBlur={() => {
                                        if (inputValue) {
                                            let b = [...keywords, inputValue];
                                            handleChange(b);
                                        }
                                    }}
                                    onChange={(a, b) => {
                                        handleChange(b);
                                    }}
                                    onKeyUp={(e) => {
                                        e.stopPropagation();
                                        if (e.key == ",") {
                                            let b = [
                                                ...keywords,
                                                inputValue,
                                            ].map(
                                                (i) => i?.split(/,|\n/) || [],
                                            );
                                            let arr = [];
                                            for (const item of b) {
                                                for (const i of item) {
                                                    const s = i;
                                                    if (s.trim().length) {
                                                        arr.push(s);
                                                    }
                                                }
                                            }

                                            e.preventDefault();

                                            setKeywords(arr);
                                        }
                                    }}
                                    freeSolo
                                    autoHighlight
                                    onInputChange={(a, b) => {
                                        setInputValue(b);
                                    }}
                                    disableClearable
                                    id="tags-filled"
                                    options={(() => {
                                        if (suggestedKeywords.length)
                                            if (inputValue)
                                                suggestedKeywords.unshift(
                                                    inputValue,
                                                );
                                        const a = suggestedKeywords.filter(
                                            (s) => {
                                                if (!keywords.length)
                                                    return true;
                                                const regex = new RegExp(
                                                    "^(" +
                                                        keywords
                                                            .map((k) =>
                                                                escapeRegExp(k),
                                                            )
                                                            .join("|") +
                                                        ")$",
                                                    "gi",
                                                );
                                                return !regex.test(s);
                                            },
                                        );

                                        a.filter = () => a;

                                        return a;
                                    })()}
                                    value={keywords}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <CustomChip
                                                key={option}
                                                getTagProps={getTagProps}
                                                setKeywords={setKeywords}
                                                option={option}
                                                index={index}
                                                checkEmpty={() => {}}
                                            />
                                        ))
                                    }
                                    renderOption={(option) => {
                                        return (
                                            <React.Fragment>
                                                <div style={{ width: "100%" }}>
                                                    <div
                                                        className={
                                                            styles.location
                                                        }
                                                    >
                                                        <div>{option}</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    }}
                                    renderInput={({ inputProps, ...rest }) => (
                                        <TextField
                                            {...rest}
                                            multiline
                                            variant="standard"
                                            classes={inputClasses}
                                            placeholder={
                                                keywords.length == 0
                                                    ? window.innerWidth > 1430
                                                        ? "Enter Keyword(s) you would like to rank for"
                                                        : "Enter Keyword(s)"
                                                    : "Secondary keywords"
                                            }
                                            autoFocus={isDash ? true : ""}
                                            style={{ padding: 0 }}
                                            inputProps={{
                                                ...inputProps,
                                                onChange: (e) => {
                                                    setInputValue(
                                                        e.target.value.substring(
                                                            0,
                                                            100,
                                                        ),
                                                    );
                                                    inputProps?.onChange(e);
                                                },
                                                readOnly: loadingCreation
                                                    ? true
                                                    : keywords.length > 20
                                                    ? true
                                                    : false,
                                            }}
                                        />
                                    )}
                                />
                                {!firstTimeUser && (
                                    <>
                                        <div
                                            style={{
                                                height: isDash ? 24 : "60%",
                                            }}
                                            className={styles.inputSep}
                                        >
                                            {" "}
                                        </div>

                                        <LightTooltip
                                            arrow
                                            placement="top"
                                            title={
                                                "Top Urls that you want to compete with"
                                            }
                                        >
                                            <div
                                                className={styles.topComp}
                                                style={{ width: "92px" }}
                                            >
                                                <Select
                                                    components={{
                                                        DropdownIndicator,
                                                    }}
                                                    indicatorSeparator={() =>
                                                        null
                                                    }
                                                    onChange={(option) => {
                                                        setTopCompUrls(option);
                                                    }}
                                                    value={topCompUrls}
                                                    options={topUrls}
                                                    className="basic-select"
                                                    classNamePrefix="select"
                                                    closeMenuOnSelect={true}
                                                    minMenuHeight={40}
                                                    // placeholder={`Select ${name}...`}
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </LightTooltip>
                                    </>
                                )}
                            </div>
                            {!firstTimeUser && (
                                <div className={styles.instructLine}>
                                    {/* {isDash && (
                                <div
                                    style={{
                                        paddingTop: "10px",
                                        lineHeight: "16px",
                                    }}
                                >
                                    <TopUrlsSelect
                                        value={topCompUrls}
                                        setValue={setTopCompUrls}
                                        show={showTopUrls}
                                        setShow={setShowTopUrls}
                                    />{" "}
                                    ranking pages will be considered for content
                                    guidelines
                                </div>
                            )} */}
                                    {!audit &&
                                        otherInfo?.premium_cruise == 1 && (
                                            <div
                                                className={styles.cruisePremium}
                                            >
                                                <div
                                                    className={
                                                        styles.premiumCruiseCheck
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setPremiumCruise(
                                                                e.target
                                                                    .checked,
                                                            );
                                                        }}
                                                        checked={is_premium}
                                                    ></input>{" "}
                                                    <span
                                                        className={
                                                            styles.premiumText
                                                        }
                                                    >
                                                        Use Premium Cruise Mode
                                                    </span>
                                                </div>
                                                {is_premium && (
                                                    <>
                                                        <span
                                                            style={{
                                                                marginInline: 10,
                                                                color: "#6b6b6b",
                                                                fontWeight:
                                                                    "500",
                                                            }}
                                                        >
                                                            {otherInfo?.ai_credits ||
                                                                0}{" "}
                                                            credits left
                                                        </span>
                                                        <span
                                                            onClick={() => {
                                                                if (
                                                                    isTeamMember
                                                                ) {
                                                                    dispatch(
                                                                        displayAlert(
                                                                            {
                                                                                alertMessage:
                                                                                    "Unauthorized: Please contact your admin to purchase credits for this account",
                                                                                alertType:
                                                                                    "error",
                                                                            },
                                                                        ),
                                                                    );
                                                                    return;
                                                                }
                                                                setCruiseCreditModal(
                                                                    true,
                                                                );
                                                            }}
                                                            className={
                                                                styles.credits
                                                            }
                                                        >
                                                            Buy Credits
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        )}

                                    {!audit && keywordResearcher ? (
                                        <div
                                            className={
                                                styles.getSemrushKeywords
                                            }
                                        >
                                            Need trending keywords?
                                            <a
                                                className={styles.getKeyword}
                                                onClick={() => setModal(true)}
                                            >
                                                Click here to power-up
                                            </a>
                                            {/* <Button
                      handler={() => setModal(true)}
                      width="120px"
                      height="30px"
                      text="Get Keywords"
                      style={{ marginLeft: "1rem" }}
                    /> */}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )}
                            {cruiseMode && !firstTimeUser && (
                                <p className={styles.tryWithSample}>
                                    <div style={{ width: "max-content" }}>
                                        Try for
                                    </div>
                                    &nbsp;
                                    <span className={styles.sampleReportsChips}>
                                        {!!sampleReports.length &&
                                            sampleReports.map((item) => {
                                                return (
                                                    <span
                                                        onClick={() => {
                                                            if (isCruiseOpen) {
                                                                if (
                                                                    getTrailText(
                                                                        planExpiresDate,
                                                                    ) == 7 &&
                                                                    planTrial
                                                                ) {
                                                                    mixpanelTrack(
                                                                        "Day 0 Dashboard",
                                                                        {
                                                                            action: `Article Writer Try For - ${item.name}`,
                                                                        },
                                                                    );
                                                                }
                                                                goTo(
                                                                    "SEO_EDITOR",
                                                                    [],

                                                                    item.hash,
                                                                );
                                                            } else {
                                                                if (
                                                                    getTrailText(
                                                                        planExpiresDate,
                                                                    ) == 7 &&
                                                                    planTrial
                                                                ) {
                                                                    mixpanelTrack(
                                                                        "Day 0 Dashboard",
                                                                        {
                                                                            action: `${
                                                                                isImportUrl
                                                                                    ? "Optimizer"
                                                                                    : "Keyword Planner"
                                                                            } Try For - ${
                                                                                item.name
                                                                            }`,
                                                                        },
                                                                    );
                                                                }
                                                                goTo(
                                                                    isImportUrl
                                                                        ? "AUDIT_EDITOR"
                                                                        : "SEO_REPORT",
                                                                    [],
                                                                    item.hash,
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                );
                                            })}
                                    </span>
                                </p>
                            )}
                        </div>
                        {(isImportUrl || audit) && cruiseMode && (
                            <div>
                                <Textfield
                                    Icon={() => <BsLink45Deg size={20} />}
                                    iconStyle={{
                                        left: "10px",
                                        right: "unset",
                                        top: "20px",
                                    }}
                                    placeholder={
                                        "Enter the URL to Optimize (Optional)"
                                    }
                                    containerStyle={{
                                        marginBottom: "0px",
                                        width: "100%",
                                    }}
                                    label=""
                                    props={{
                                        value: url,
                                        onChange: (e) => {
                                            setUrl(e.target.value);
                                        },
                                        style: {
                                            boxShadow: "none",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            paddingLeft: "40px",
                                        },
                                    }}
                                    customClass={
                                        invalidUrl
                                            ? styles.invalidUrl
                                            : styles.urlInput
                                    }
                                    errMsg={
                                        url.length > 0 && !isValidUrl(url)
                                            ? "Invalid URL"
                                            : url.indexOf("docs.google.com") !=
                                              -1
                                            ? "We do not support Google Docs yet"
                                            : ""
                                    }
                                    disabled={loadingCreation}
                                />
                                <div className={styles.instructLine}>
                                    <div
                                        style={{
                                            paddingTop: "5px",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            whiteSpace: "nowrap",
                                        }}
                                    ></div>
                                </div>
                                <div className={styles.optimizerSubText}>
                                    You may skip the URL field to manually add
                                    the content later
                                </div>
                            </div>
                        )}
                        {firstTimeUser && (
                            <div
                                style={{
                                    color: "#2A4266",
                                    fontSize: "14px",
                                    marginTop: "15px",
                                    marginBottom: "-5px",
                                }}
                            >
                                Select your target country.
                            </div>
                        )}
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <LocationList
                                location={location}
                                country={country}
                                setCountry={setCountry}
                                setLocation={setLocation}
                                suggestedLocations={suggestedLocations}
                            />
                        </div>
                        <Button
                            text={
                                is_premium
                                    ? "Create for 1 Cruise Credit"
                                    : buttonText
                                    ? buttonText
                                    : "Create"
                            }
                            width={cruiseMode ? "100%" : 220}
                            Icon={() =>
                                buttonIcon ? (
                                    buttonIcon
                                ) : (
                                    <SVGIcon
                                        size={20}
                                        src={"/New UI/SVG/return.svg"}
                                    />
                                )
                            }
                            handler={(e) => {
                                if (
                                    getTrailText(planExpiresDate) == 7 &&
                                    planTrial
                                ) {
                                    mixpanelTrack("Day 0 Dashboard", {
                                        action: `Report Start${
                                            isDash ? "(Dashboard)" : ""
                                        } - ${
                                            audit
                                                ? "Optimizer"
                                                : "Article Writer"
                                        }`,
                                        keywords: keywords?.length
                                            ? keywords
                                            : inputValue,
                                        url: url ? url : "-",
                                        search_country: country.label
                                            ? country.label
                                            : "-",
                                    });
                                }
                                if (audit) {
                                    mpTrackReportCreateCta({
                                        reportType: audit
                                            ? "Content Optimizer"
                                            : "-",
                                        keywords: keywords?.length
                                            ? keywords
                                            : inputValue,
                                        url: url ? url : "-",
                                        search_country: country.label
                                            ? country.label
                                            : "-",
                                        cta_screen:
                                            window.location.pathname?.includes(
                                                "/dashboard",
                                            )
                                                ? "Dashboard"
                                                : "Content Optimizer",
                                    });
                                }
                                if (
                                    audit &&
                                    LockConfigurator(
                                        FEATURE_TYPES.contentAuditLock,
                                    )
                                ) {
                                    return _setModal({ open: true, id: -5 });
                                }
                                if (url.length > 0) {
                                    if (audit && !isValidUrl(url)) {
                                        setInvalidUrl(true);
                                        setTimeout(() => {
                                            setInvalidUrl(false);
                                        }, 2000);
                                        return;
                                    }
                                    if (
                                        audit &&
                                        isValidUrl(url) &&
                                        url.indexOf("docs.google.com") != -1
                                    ) {
                                        setInvalidUrl(true);
                                        setTimeout(() => {
                                            setInvalidUrl(false);
                                        }, 2000);
                                        return;
                                    }
                                }
                                if (!(keywords.length && keywords[0].length))
                                    return;
                                if (
                                    !inputValue.length &&
                                    !keywords.length &&
                                    audit
                                ) {
                                    setInvalidInput(true);
                                    return;
                                }

                                makeReport({
                                    e,
                                    ...(inputValue.length && !keywords.length
                                        ? { inputValue }
                                        : { keywords }),
                                    country,
                                    setInputValue,
                                    setKeywords,
                                    url,
                                    audit,
                                    competitors_count: topCompUrls.value,
                                    is_premium,
                                });
                                if (
                                    userSettings?.competitors_count !=
                                    topCompUrls.value
                                ) {
                                    dispatch(
                                        updateUserSettings({
                                            ...userSettings,
                                            competitors_count:
                                                topCompUrls.value,
                                        }),
                                    );
                                }
                                setUrl("");
                                if (firstTimeUser) {
                                    mixpanelTrack("Cruise Mode Popup", {
                                        action: "Let's Create",
                                        keywords: keywords?.length
                                            ? keywords
                                            : inputValue,
                                        search_country: country.label
                                            ? country.label
                                            : "-",
                                    });
                                }
                                setInputValue("");
                                setKeywords([]);
                                setImportFromUrl(false);
                            }}
                            borderRadius="3"
                            loading={loadingCreation}
                            height={40}
                            style={{
                                borderRadius: "3px",
                                backgroundColor: "#1f4cbe",
                                fontSize: 14,
                                marginTop: firstTimeUser ? "15px" : "",
                            }}
                            customClass={styles.buttonMedia}
                        />
                    </div>
                    {/* <Button
          text="Add Tags"
          width={132}
          Icon={() => <SVGIcon size={16} src={"/New UI/SVG/tagIcon.svg"} />}
          handler={(e) => {}}
          iconReverse
          borderRadius= "3"
          height={40}
          style={{
            borderRadius: 3,
            backgroundColor: "white",
            fontSize: 14,

            color: "var(--primary-blue)",
          }}
          customClass={styles.buttonMedia}
        /> */}
                    {/* <Button
                        text={
                            is_premium
                                ? "Create for 1 Cruise Credit"
                                : buttonText
                                ? buttonText
                                : "Create"
                        }
                        width={cruiseMode ? "100%" : 220}
                        Icon={() =>
                            buttonIcon ? (
                                buttonIcon
                            ) : (
                                <SVGIcon
                                    size={20}
                                    src={"/New UI/SVG/return.svg"}
                                />
                            )
                        }
                        handler={(e) => {
                            if (audit) {
                                mpTrackReportCreateCta({
                                    reportType: audit
                                        ? "Content Optimizer"
                                        : "-",
                                    keywords: keywords?.length
                                        ? keywords
                                        : inputValue,
                                    url: url ? url : "-",
                                    search_country: country.label
                                        ? country.label
                                        : "-",
                                    cta_screen:
                                        window.location.pathname?.includes(
                                            "/dashboard",
                                        )
                                            ? "Dashboard"
                                            : "Content Optimizer",
                                });
                            }
                            if (
                                audit &&
                                LockConfigurator(FEATURE_TYPES.contentAuditLock)
                            ) {
                                return _setModal({ open: true, id: -5 });
                            }
                            if (audit && !isValidUrl(url)) {
                                setInvalidUrl(true);
                                setTimeout(() => {
                                    setInvalidUrl(false);
                                }, 2000);
                                return;
                            }
                            if (
                                audit &&
                                isValidUrl(url) &&
                                url.indexOf("docs.google.com") != -1
                            ) {
                                setInvalidUrl(true);
                                setTimeout(() => {
                                    setInvalidUrl(false);
                                }, 2000);
                                return;
                            }
                            if (!(keywords.length && keywords[0].length))
                                return;
                            if (
                                !inputValue.length &&
                                !keywords.length &&
                                audit
                            ) {
                                setInvalidInput(true);
                                return;
                            }

                            makeReport({
                                e,
                                ...(inputValue.length && !keywords.length
                                    ? { inputValue }
                                    : { keywords }),
                                country,
                                setInputValue,
                                setKeywords,
                                url,
                                audit,
                                competitors_count: topCompUrls,
                                is_premium,
                            });
                            setUrl("");

                            setInputValue("");
                            setKeywords([]);
                            setImportFromUrl(false);
                        }}
                        borderRadius="3"
                        loading={loadingCreation}
                        height={40}
                        style={{
                            borderRadius: "3px",
                            backgroundColor: "#1f4cbe",
                            fontSize: 14,
                        }}
                        customClass={styles.buttonMedia}
                    /> */}
                </div>
            </div>
            {cruiseCreditModal && (
                <Modal setModal={setCruiseCreditModal}>
                    <CreditsModal closeModal={setCruiseCreditModal} />
                </Modal>
            )}
            {modal.open && (
                <SeoWithCruiseModal modal={modal} setModal={_setModal} />
            )}
        </HighlightComponent>
    );
}

export const CustomChip = ({
    checkEmpty,
    getTagProps,
    setKeywords,
    option,
    index,
}) => {
    const chipClasses = chipCustom();
    const [edit, setEdit] = useState(false);
    const [val, setVal] = useState(option);
    const handleEditChip = (e) => {
        e.stopPropagation();
        setKeywords((ps) => {
            ps[index] = e.target.value;
            return [...ps];
        });
    };

    const handleBlur = (e) => {
        e.persist();
        if (index == 0 && !e.target.value) {
            e.target.focus();
            console.log("editchip Error: empty primary");
            checkEmpty(true);
            return;
        } else if (!e.target.value) {
            setKeywords((ps) => {
                ps = ps.filter((item, i) => i != index);
                return [...ps];
            });
            setEdit(false);
        } else if (e.target.value) {
            if (index == 0) checkEmpty(false);
            setEdit(false);
            setKeywords((ps) => {
                ps[index] = e?.target?.value?.substring(0, 100) || "";
                return [...ps];
            });
        }
    };

    return (
        <Chip
            variant="outlined"
            classes={chipClasses}
            onClick={(e) => {
                e.stopPropagation();
                setEdit(true);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            deleteIcon={<RiCloseLine style={{ color: "grey" }} />}
            style={
                index == 0
                    ? {
                          backgroundColor: "#3b4d6a",
                          color: "#fafafb",
                          borderRadius: "15px",
                      }
                    : {
                          backgroundColor: "#e0e3ec",
                          color: "#3b4d6a",
                          borderRadius: "15px",
                      }
            }
            label={
                edit ? (
                    <input
                        style={{
                            border: "none",
                            outline: "none",
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyPress={(e) => {
                            e.stopPropagation();
                            if (e.key == "Enter" || e.key == ",") handleBlur(e);
                        }}
                        onBlur={(e) => handleBlur(e)}
                        autoFocus={true}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.target.focus();
                        }}
                        onChange={(e) =>
                            setVal(e.target.value.substring(0, 100))
                        }
                        value={val}
                    />
                ) : (
                    option
                )
            }
            {...getTagProps({ index })}
        />
    );
};
