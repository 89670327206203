import {
    Checkbox,
    FormControlLabel,
    LinearProgress,
    makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import styles from "./ClusterReports.module.css";
import { MdOutlineClose } from "react-icons/md";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import Button from "../../../atoms/Button/Button";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import screens from "../../../../screens.json";
import { numberWithCommas } from "../../../../utility/helperFun";
import { Link } from "react-router-dom";
import { RatingsV2 } from "../../../molecules/Creator/Overview/overallRatings";
import CustomCircularProgress from "../../../atoms/CircularProgress/CustomCircularProgress";
import useHelperFunctions from "../../../helperFunctions";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { clusterMixpanelEvent } from "../clusterMixpanelEvents";

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: "18px",
        height: "18px",
        margin: "9px",
        color: "#b0b8c3",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

export const ClusterModal = ({
    loading = false,
    setIsClusterModal,
    cluster,
    clusterInfo,
    _setClusterKeywords,
    setLoad = () => {},
    isKDAvailable,
    isGSCAvailable,
}) => {
    const { postData, getData } = useHelperFunctions();
    const [clusterKeywords, setClusterKeywords] = useState([]);
    const checkBoxClasses = checkBoxCustom();
    const tableRef = useRef();
    const goTo = useNavigation();
    const arr = new Array(9).fill("words");
    const [copied, setCopied] = useState(false);
    const { mixpanelTrack } = useMixpanelHook();
    useEffect(() => {
        let temp = [];
        cluster.data.forEach((item) => {
            temp.push(item);
        });

        setClusterKeywords(temp);
    }, [cluster.data]);

    useEffect(() => {
        mixpanelTrack(clusterMixpanelEvent.OPEN_CLUSTER, {
            "Total Keywords": cluster?.cluster_keywords,
            "Cluster ID": cluster?.id,
            "Average KD": cluster?.kd,
            TSV: cluster.totalVol,
        });
    }, []);

    const copyTableContent = () => {
        let text = tableRef.current.innerText;

        text = text.replace(new RegExp(/\t\n/g), "\t");
        text = text.replace(new RegExp(/\n\t/g), "\t");

        navigator.clipboard.writeText(text);

        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
        // navigator.clipboard.writeText(tableRef.current.innerText);
    };
    async function saveChecked(id, is_selected) {
        let payload = {
            id: id,
            is_selected,
        };
        const res = await postData({ url: "/api/clustering/update", payload });
        if (res.status == 200) {
            console.log("res.data", res.data);
        }
    }

    function handleCheckboxes(item, checked) {
        let temp = [...clusterKeywords];

        let data = [];
        if (!checked) {
            data = temp.filter((obj) => {
                if (obj.keyword == item.keyword) {
                    obj.is_selected = false;
                }
                return obj;
            });
        } else {
            data = temp.filter((obj) => {
                if (obj.keyword == item.keyword) obj.is_selected = true;

                return obj;
            });
        }
        console.log("selection data", data);
        setClusterKeywords(data);
    }

    const createMultiKeywordReport = async (keywords) => {
        _setClusterKeywords((ps) => {
            const arr = [...ps];

            const i = arr.findIndex(
                (cluster) => cluster.id == document.loadingClusterId,
            );

            arr[i] = { ...arr[i], failed: false };

            return arr;
        });
        setLoad(true);
        setIsClusterModal(false);

        let temp = [];
        keywords.forEach((item) => {
            if (item.is_selected) temp.push(item.keyword);
        });
        mixpanelTrack(clusterMixpanelEvent.CREATE_SEO_ARTICLE, {
            "Total Keywords": keywords?.length,
            "Selected Keywords": temp?.length,
            TSV: cluster.totalVol,
            "Average KD": cluster.kd,
        });
        console.log("clusterRepo", temp);
        const res = await postData({
            url: "/api/keywords/create",
            payload: {
                keyword: temp,
                country: clusterInfo.search_country,
                cluster_id: cluster.id,
                isKeywordPlanner: 0,
            },
        });

        if (res.status != 200) {
            setLoad(false);
        } else if (res.status == 200) {
            document.loadingClusterId = cluster.id;
            _setClusterKeywords((ps) => {
                const arr = [...ps];

                const i = arr.findIndex(
                    (cluster) => cluster.id == document.loadingClusterId,
                );

                arr[i] = { ...arr[i], report_id: 0, failed: false };

                return arr;
            });
        }
    };

    const updatePopup = async () => {
        const payload = {
            report_id: cluster.report_id,
            cluster_id: cluster.id ? cluster.id : cluster.cluster_id,
            isKeywordPlanner: cluster?.is_cluster ? 0 : 1,
        };
        const res = await getData({
            url: `/api/clustering/update?report_id=${payload.report_id}&cluster_id=${payload.cluster_id}`,
        });
        if (res.status == 200) {
            if (res.data.quality || res.data.wordCount)
                _setClusterKeywords((ps) => {
                    const arr = [...ps];
                    const i = arr.findIndex(
                        (_cluster) => cluster.report_id == _cluster.report_id,
                    );

                    arr[i] = {
                        ...arr[i],
                        quality: res.data.seo_score,
                        seo_score: res.data.seo_score,
                        wordCount: res.data.wordCount,
                    };

                    return arr;
                });
        }
    };
    useEffect(() => {
        if (cluster.report_id && cluster.report_id != -1) {
            updatePopup();
        }
    }, []);
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={styles.clusterModal}
        >
            <div className={styles.modalTop}>
                <div className={styles.clusterTitle}>
                    <LightTooltip
                        title={cluster.data[0]?.keyword}
                        placement="bottom"
                        arrow={true}
                        // PopperProps={{ disablePortal: true }}
                        onOpen={(e) => {
                            console.log(
                                e,
                                document.querySelector("[role=tooltip]"),
                            );
                            if (e.target.scrollWidth <= e.target.offsetWidth) {
                                setImmediate(() => {
                                    document
                                        .querySelectorAll("[role=tooltip]")
                                        .forEach(
                                            (e) => (e.style.display = "none"),
                                        );
                                });
                            }
                        }}
                    >
                        <span
                            style={{ maxWidth: 500, width: "max-content" }}
                            className={styles.clusterTitle__title}
                        >
                            {cluster.data[0]?.keyword}
                        </span>
                    </LightTooltip>
                    <SVGIcon
                        tooltip={copied ? "Copied" : "Copy"}
                        size={14}
                        color="gray"
                        onClick={() => {
                            copyTableContent();
                        }}
                        src="/New UI/SVG/copy.svg"
                    />
                </div>
                {/* <div className={styles.clusterType}>INFORMATIONAL</div> */}
                <div
                    className={styles.modalClose}
                    onClick={() => {
                        setIsClusterModal(false);
                    }}
                >
                    <MdOutlineClose />
                </div>
            </div>
            {cluster.totalVol != 0 && (
                <div
                    className={styles.clusterCount}
                    style={{
                        justifyContent:
                            isGSCAvailable == 0 && isKDAvailable == 0
                                ? "flex-start"
                                : "space-between",
                    }}
                >
                    {isGSCAvailable == 1 && (
                        <>
                            <LightTooltip
                                title={
                                    "The Average Rank is the average position of the domain among the ranked keywords in the cluster. A lower average rank indicates better performance within the cluster"
                                }
                                arrow
                            >
                                <div>
                                    <span
                                        style={{ marginRight: "4px" }}
                                        className={styles.field}
                                    >
                                        Avg. Rank
                                    </span>
                                    : {cluster.avg_rank || "-"}{" "}
                                </div>
                            </LightTooltip>
                            {/* <LightTooltip title="" arrow>
                                <div>
                                    <span
                                        style={{ marginRight: "4px" }}
                                        className={styles.field}
                                    >
                                        %RSV
                                    </span>
                                    : {cluster.rsv || "-"}{" "}
                                </div>
                            </LightTooltip> */}
                        </>
                    )}
                    <LightTooltip
                        title="Total search volume is the combined search volume of related keywords."
                        arrow
                    >
                        <div>
                            <span
                                style={{ marginRight: "4px" }}
                                className={styles.field}
                            >
                                Total Search Volume
                            </span>
                            : {numberWithCommas(cluster.totalVol)}{" "}
                        </div>
                    </LightTooltip>
                    {isKDAvailable == 1 && (
                        <div
                            className={styles.clusterDifficulty}
                            // style={{ width: "50%", display: "flex", justifyContent: "center" }}
                        >
                            <LightTooltip
                                arrow={true}
                                placement="bottom"
                                title="Average Keyword Difficulty indicates how challenging it would be for a website to rank organically in the Google top 10 for the entire set of analyzed keywords in the cluster.
                                A higher KD percentage implies greater difficulty in achieving high rankings for the keywords within the cluster."
                            >
                                <span
                                    style={{ marginRight: "4px" }}
                                    className={styles.field}
                                >
                                    Average KD :{" "}
                                    <span style={{ color: "#3f5575" }}>
                                        {cluster.kd != null
                                            ? parseInt(cluster.kd)
                                            : "-"}{" "}
                                    </span>
                                </span>
                            </LightTooltip>
                        </div>
                    )}
                    {/* <span className={styles.count}>
            {numberWithCommas(cluster.totalVol)}
          </span> */}
                </div>
            )}
            <div className={styles.keywordTableContainer}>
                <table ref={tableRef} className={styles.keywordTable}>
                    <tbody className={styles.tableBody}>
                        <tr className={styles.tableHead}>
                            <th className={styles.tableHead__key}>
                                keywords &nbsp;
                                <span className={styles.selectedKeywords}>
                                    &nbsp;({" "}
                                    {
                                        cluster.data.filter(
                                            (edx) => edx.is_selected,
                                        ).length
                                    }{" "}
                                    / {cluster.data && cluster.data.length} )
                                </span>
                            </th>
                            {isGSCAvailable == 1 && (
                                <LightTooltip
                                    title={
                                        "Position refers to the highest rank of a webpage from the domain in search engine results"
                                    }
                                    placement={"bottom"}
                                    arrow
                                >
                                    <th
                                        className={styles.tableHead__Vol}
                                        style={{ textAlign: "center" }}
                                    >
                                        Position
                                    </th>
                                </LightTooltip>
                            )}
                            {isKDAvailable == 1 && (
                                <LightTooltip
                                    title={
                                        "Keyword Difficulty shows you how hard it would be for a website to rank organically in the Google top 10 for the analyzed keyword. The higher the percentage, the harder it will be to achieve high rankings"
                                    }
                                    placement={"bottom"}
                                    arrow
                                >
                                    <th
                                        className={styles.tableHead__Vol}
                                        style={{ textAlign: "center" }}
                                    >
                                        KD
                                    </th>
                                </LightTooltip>
                            )}

                            <LightTooltip
                                title={
                                    clusterInfo?.source == "semrush"
                                        ? "It refers to the degree of competition for the keyword in paid search on a scale of 0 to 1. The higher the score, the more difficult it will be to stand out in the search results, and vice versa"
                                        : "Semantically related keywords or phrases that have a conceptual relation to primary keyword"
                                }
                                placement={"bottom"}
                                arrow
                            >
                                <th
                                    className={styles.tableHead__Vol}
                                    style={{ textAlign: "right" }}
                                >
                                    {clusterInfo.source == "semrush"
                                        ? "Paid Competition"
                                        : "Relevance"}
                                </th>
                            </LightTooltip>
                            <LightTooltip
                                title={
                                    "Cost per click is the actual price you pay in USD to bid on keywords for a chance to show ads in google search results"
                                }
                                placement={"bottom"}
                                arrow
                            >
                                <th className={styles.tableHead__Vol}>CPC</th>
                            </LightTooltip>
                            {/* <th>KD</th> */}

                            <LightTooltip
                                title={
                                    "The average monthly search volume for the keyword during the past 12 months."
                                }
                                placement={"bottom"}
                                arrow
                            >
                                <th className={styles.tableHead__Vol}>
                                    Search Volume
                                </th>
                            </LightTooltip>
                        </tr>
                        {clusterKeywords.length &&
                            cluster.data.map((item, i) => {
                                return (
                                    <tr className={styles.tableBody_row}>
                                        <td
                                            className={
                                                styles.tableBody__keyword
                                            }
                                        >
                                            <FormControlLabel
                                                style={{
                                                    color: clusterKeywords[i]
                                                        ?.is_selected
                                                        ? "#2a4166"
                                                        : "#b0b8c3",
                                                }}
                                                control={
                                                    <Checkbox
                                                        color={"default"}
                                                        disabled={
                                                            cluster.report_id ||
                                                            loading ||
                                                            i == 0
                                                        }
                                                        checked={
                                                            clusterKeywords[i]
                                                                ?.is_selected
                                                        }
                                                        classes={
                                                            checkBoxClasses
                                                        }
                                                        value={item.keyword}
                                                        onChange={(e) => {
                                                            saveChecked(
                                                                item.id,
                                                                e.target
                                                                    .checked,
                                                            );
                                                            handleCheckboxes(
                                                                item,
                                                                e.target
                                                                    .checked,
                                                            );
                                                        }}
                                                        name="checkedB"
                                                    />
                                                }
                                                label={item.keyword}
                                            />
                                        </td>
                                        {isGSCAvailable == 1 && (
                                            <td style={{ textAlign: "center" }}>
                                                {item?.position != null
                                                    ? parseInt(item?.position)
                                                    : "-"}
                                            </td>
                                        )}
                                        {isKDAvailable == 1 && (
                                            <td style={{ textAlign: "center" }}>
                                                {item?.keyword_difficulty !=
                                                null
                                                    ? parseInt(
                                                          item?.keyword_difficulty,
                                                      )
                                                    : "-"}
                                            </td>
                                        )}

                                        <td
                                            style={{
                                                padding: "8px 10px 0 0",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                            className={styles.similarity}
                                        >
                                            {clusterInfo.source ===
                                            "semrush" ? (
                                                <>
                                                    {item.competition != null
                                                        ? Math.round(
                                                              item.competition *
                                                                  10,
                                                          )
                                                        : "-"}
                                                </>
                                            ) : item?.similarity != null ? (
                                                <CustomCircularProgress
                                                    strokeWidth={0.2}
                                                    fullArcFraction={5 / 7}
                                                    progress={parseInt(
                                                        item?.similarity,
                                                    )}
                                                    width={35}
                                                    height={35}
                                                    customClasses={
                                                        styles.similarity
                                                    }
                                                />
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                        <td className={styles.cpc}>
                                            {item?.cpc != undefined
                                                ? parseFloat(item?.cpc).toFixed(
                                                      2,
                                                  )
                                                : "-"}
                                        </td>
                                        {
                                            <td
                                                className={
                                                    styles.tableBody__volume +
                                                    " " +
                                                    (!clusterKeywords[i]
                                                        ?.is_selected &&
                                                        styles.deselected)
                                                }
                                            >
                                                {cluster.totalVol != 0
                                                    ? numberWithCommas(
                                                          item.volume,
                                                      )
                                                    : "-"}
                                            </td>
                                        }
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className={styles.modalBottom}>
                {!cluster.report_id ? (
                    <LightTooltip
                        arrow
                        title={
                            clusterInfo.is_sample
                                ? "This is a sample cluster report. Please create a new Keyword Report to enable this feature."
                                : ""
                        }
                    >
                        <div>
                            <Button
                                text={
                                    loading
                                        ? "Creating SEO Article..."
                                        : !cluster.report_id
                                        ? "Create SEO Article"
                                        : "Open SEO Article"
                                }
                                height={"44px"}
                                width={"220px"}
                                handler={
                                    clusterInfo.is_sample
                                        ? () => {}
                                        : () => {
                                              createMultiKeywordReport(
                                                  clusterKeywords,
                                              );
                                          }
                                }
                                disabled={
                                    !cluster.data.filter(
                                        (edx) => edx.is_selected,
                                    ).length || loading
                                }
                                loadingText={loading}
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    backgroundColor: "var(--primary-blue)",
                                    color: "white",
                                    opacity: clusterInfo.is_sample ? 0.5 : 1,
                                    border: "solid 1px #e9ecf4",
                                    borderRadius: 3,
                                }}
                                iconReverse
                                Icon={() => (
                                    <SVGIcon
                                        size={"20"}
                                        src={"/New UI/SVG/doc.svg"}
                                    />
                                )}
                                lessDots={true}
                            />
                        </div>
                    </LightTooltip>
                ) : (
                    <>
                        <Link
                            target={"_blank"}
                            className={styles.openLink + " " + styles.openLink2}
                            to={screens.SEO_EDITOR + "/" + cluster.report_id}
                        >
                            <SVGIcon size={"16"} src={"/New UI/SVG/doc.svg"} />
                            &nbsp; Open SEO Article
                        </Link>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "max-content",
                            }}
                        >
                            <RatingsV2
                                quality={cluster?.seo_score}
                                wordCount={cluster.wordCount}
                                cluster={true}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
