import {
    ChangeEvent,
    Dispatch,
    MouseEvent,
    MouseEventHandler,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import useHelperFunctions from "../../helperFunctions";
import {
    CircularProgress,
    ClickAwayListener,
    Stepper,
    Step,
    StepLabel,
    makeStyles,
    withStyles,
    StepConnector,
    StepIcon,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@material-ui/core";

import styles from "./GscLogin.module.css";
import Button from "../../atoms/Button/Button";
import { FcGoogle } from "react-icons/fc";
import SVGIcon from "../../atoms/SVGIcon";
import { GSC_Countries } from "./GscCountries";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { useDispatch, useSelector } from "react-redux";
import useGscHook, { SelectedDomain } from "./useGscHook";
import ReactSelect from "../../organisms/Research/Wordpress/ReactSelect";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import GscLoginFlow from "./GscLoginFlow";
import { setUserGscInfo } from "../../../store/actions/userGscInfo";
import VideoModal from "../NewDashboard/VideoModal";
import { displayAlert } from "../../../store/actions/alert";
import { useMixpanelTA } from "./useMixpanelTA";
const checkBoxCustom = makeStyles((theme) => ({
    root: {
        "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
        },
        color: "#ACB3BF",
        marginRight: "-10px",
    },
    active: {
        color: "#014DC5",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

const _LightTooltip = withStyles((theme) => ({
    tooltip: {
        width: "100%",
        maxWidth: "100% !important",
    },
}))(LightTooltip);

const FormControlLabelCustom = makeStyles((theme) => ({
    root: {
        padding: "0px 2px 0px 10px",
    },
}));
const CustomStepLabel = withStyles((theme) => ({
    root: {
        "& .MuiStepLabel-active": {
            color: "linear-gradient(0deg, rgba(237, 241, 249, 0.50) 0%, rgba(237, 241, 249, 0.50) 100%), #FFF",
            fontSize: "14px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
        },

        "& .MuiStepLabel-completed": {
            color: "rgba(29,193,152,1)",
            fontSize: "14px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
        },
    },
    label: {
        color: theme.palette.text.secondary,
    },
}))(StepLabel);

const CustomStepConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 15,
        left: "calc(-49% + 15px)", // Adjusted value
        right: "calc(49% + 16px)",
    },

    active: {
        "& $line": {
            borderColor: "rgba(42,66,102,1);",
            borderTopWidth: "3px",
        },
        left: "calc(-49% + 13px)", // Adjusted value
        right: "calc(49% + 16px)",
    },
    completed: {
        "& $line": {
            borderColor: "rgba(29,193,152,1)",
            borderTopWidth: "3px",
        },
        left: "calc(-49% + 8px)", // Adjusted value
        right: "calc(49% + 14px)",
    },
    line: {
        borderTopStyle: "solid",
        borderTopWidth: "3px",
    },
}))(StepConnector);

const CustomStepIcon = (props: any) => {
    return (
        <span
            className={
                (props.completed
                    ? styles.completed
                    : props.active
                    ? styles.active
                    : styles.inactive) +
                " " +
                styles.stepIcon
            }
        >
            {props.icon}
        </span>
    );
};

const customStyles = {
    // valueContainer: (base: any) => ({
    //     ...base,
    //     flexWrap: "no-wrap",
    //     maxWidth: "80%",
    //     overflow: "hidden",
    // }),
    control: (base: any) => ({
        ...base,
        minHeight: 52,
        border: "1px solid #e1e4ed",
        boxShadow: "0px 2px 1px rgba(225, 228, 237, 0.4)",
        "&:hover": {
            borderColor: "#e1e4ed !important",
        },
    }),
    menu: (base: any) => ({
        ...base,
        zIndex: 100,
    }),
    menuList: (styles: any) => ({
        ...styles,
        maxHeight: "200px",
        fontSize: "14px",
    }),
    indicatorSeparator: (styles: any) => ({
        ...styles,
        display: "none",
    }),
    placeholder: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: "#3f5575",
            fontWeight: "400",
            fontSize: "14px",
        };
    },
};

type Props_GscLogin = {
    setIsShowData: Dispatch<SetStateAction<boolean>>;
    isFailed: boolean;
    setIsFailed: Dispatch<SetStateAction<boolean>>;
    initialLoading: boolean;
    isLoggedIn: boolean;
    setIsLoggedIn: (boolean: boolean) => void;
    userInfo: { email: string };
    domains: { siteUrl: string }[] | [];
    selectedDomain: SelectedDomain;
    setSelectedDomain: Dispatch<SetStateAction<SelectedDomain>>;
    getDomainList: (isSetDefault: boolean) => void;
    tempGscId: number;
    setTempGscId: (value: React.SetStateAction<number>) => void;
    isFetchingRecommendations: boolean;
    getDetails: () => void;
    setActiveStep: Dispatch<SetStateAction<number>>;
    activeStep: number;
    alertForTeamMember: boolean;
};
type Props_Domain = {
    isFailed: Props_GscLogin["isFailed"];
    domains: Props_GscLogin["domains"];
    selectedDomain: Props_GscLogin["selectedDomain"];
    setIsFailed: Props_GscLogin["setIsFailed"];
    setSelectedDomain: Props_GscLogin["setSelectedDomain"];
    userInfo: Props_GscLogin["userInfo"];
    isFetchingRecommendations: Props_GscLogin["isFetchingRecommendations"];
    setActiveStep: Dispatch<SetStateAction<number>>;
    activeStep: number;
    getDomainList: (id: string | number, isSetDefault: boolean) => void;
    setIsShowData: Dispatch<SetStateAction<boolean>>;
};
type Props_SelectList = {
    otherDomains?: { siteUrl: string }[] | [];
    countries?: object[];
    selectedDomain: SelectedDomain;
    setSelectedDomain: Props_GscLogin["setSelectedDomain"];
    label: string;
    selectedCountires: any;
};

type Props_MultiSelectList = {
    selectedDomain: Props_GscLogin["selectedDomain"];
    setSelectedDomain: Props_GscLogin["setSelectedDomain"];
    label: string;
    setSelectedSubdomains: Dispatch<SetStateAction<string[]>>;
    countries: any[];
    setCountries: Dispatch<
        SetStateAction<
            {
                country: string;
                label: string;
                value: string;
                code: string;
            }[]
        >
    >;
};

interface SubPageObject {
    is_selected: number;
    subPage: string;
    count: number;
    links: string[];
}
interface SubDomainObject {
    subDomain: string;
    pages: SubPageObject[];
    is_selected: number;
    count: number;
}

const MultiSelectList = ({
    label,
    setSelectedSubdomains,
    countries,
    setCountries,
}: Props_MultiSelectList) => {
    const [tags, setTags] = useState([]);
    useEffect(() => {
        setSelectedSubdomains(countries.map((option) => option));
    }, [countries, setSelectedSubdomains]);

    return (
        <>
            <div className={styles.subDomainsList}>
                <label>{label}</label>
                <div className={styles.dropDownContainer}>
                    <div className={styles.selectedSubDomains}>
                        <ReactSelect
                            isCountryList={true}
                            defaultValues={[...countries]}
                            selected={countries}
                            setSelected={setCountries}
                            options={GSC_Countries}
                            name={"Country"}
                            _customStyles={customStyles}
                        />
                    </div>
                </div>
                <div
                    style={{
                        color: "#6B6B6B",
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                        marginLeft: "2px",
                    }}
                >
                    Choose upto 5 countries
                </div>
            </div>
        </>
    );
};

const SelectList = ({
    otherDomains = [],
    countries = [],
    selectedDomain,
    setSelectedDomain = () => {},
    selectedCountires,
    label = "",
}: Props_SelectList) => {
    const [isDropDownEnabled, setIsDropDownEnabled] = useState<boolean>(false);
    const [searchCountry, setSearchCountry] = useState<string>("");
    const { getTrimmedDomainURL } = useGscHook();

    const filterMethod: (item: any) => boolean = (item) => {
        if (
            searchCountry.length &&
            item.label.toLowerCase().includes(searchCountry.toLowerCase())
        ) {
            return true;
        } else if (searchCountry.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    const filterCountries = [...GSC_Countries.filter(filterMethod)];

    return (
        <>
            <div className={styles.domainsList}>
                <label>{label}</label>
                <div className={styles.dropDownContainer}>
                    <div
                        className={styles.selectedDomain}
                        onClick={() => setIsDropDownEnabled(!isDropDownEnabled)}
                    >
                        <div className={styles.selectedDomain_left}>
                            {!!otherDomains.length ? (
                                <>
                                    <img
                                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                            selectedDomain?.siteUrl?.split(
                                                ":",
                                            )[1]
                                        }&sz=32`}
                                        width={"24px"}
                                    />
                                    <span>
                                        {getTrimmedDomainURL(
                                            selectedDomain?.siteUrl,
                                        )}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <img
                                        src={`https://flagcdn.com/w20/${selectedCountires[0]?.value?.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${selectedCountires[0]?.value?.toLowerCase()}.png 2x`}
                                        width={"24px"}
                                    />
                                    <span>{selectedCountires[0]?.code}</span>
                                </>
                            )}
                        </div>
                        <div className={styles.selectedDomain_right}>
                            <SVGIcon
                                src={`/New UI/SVG/${
                                    isDropDownEnabled
                                        ? "BiChevronDown"
                                        : "BiChevronDown"
                                }.svg`}
                                size={22}
                                style={{ color: "#3F5575" }}
                            />
                        </div>
                    </div>
                    {isDropDownEnabled && (
                        <ClickAwayListener
                            onClickAway={() => {
                                setIsDropDownEnabled(false);
                            }}
                        >
                            {!!otherDomains.length ? (
                                <div className={styles.dropDownList}>
                                    {otherDomains.map((d, index) => {
                                        return (
                                            <div
                                                className={styles.otherDomain}
                                                onClick={() => {
                                                    setSelectedDomain(
                                                        (selectedDomain) => ({
                                                            ...selectedDomain,
                                                            siteUrl: d.siteUrl,
                                                        }),
                                                    );

                                                    setIsDropDownEnabled(false);
                                                }}
                                            >
                                                <div
                                                    className={
                                                        styles.selectedDomain_left
                                                    }
                                                >
                                                    <img
                                                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                            // @ts-ignore
                                                            d?.siteUrl?.split(
                                                                ":",
                                                            )[1]
                                                        }&sz=32`}
                                                    />

                                                    <span>
                                                        {getTrimmedDomainURL(
                                                            d?.siteUrl,
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className={styles.dropDownList}>
                                    <div
                                        className={
                                            styles.countrySearchInputWrapper
                                        }
                                    >
                                        <Textfield
                                            customClass={
                                                styles.countrySearchInput
                                            }
                                            props={{
                                                value: searchCountry,
                                                onChange: (e: any) =>
                                                    setSearchCountry(
                                                        e.target.value,
                                                    ),
                                            }}
                                            placeholder="Search country here...."
                                            autoFocus={true}
                                            label={""}
                                        />
                                    </div>
                                    <div className={styles.list}>
                                        {filterCountries.map((d, index) => {
                                            return (
                                                <div
                                                    className={
                                                        styles.otherDomain
                                                    }
                                                    onClick={() => {
                                                        setSelectedDomain(
                                                            (
                                                                ps: SelectedDomain,
                                                            ) => ({
                                                                ...ps,
                                                                country:
                                                                    d?.label
                                                                        ? d?.label
                                                                        : "",
                                                                country_code:
                                                                    d?.code
                                                                        ? d?.code
                                                                        : "",
                                                                flagCode:
                                                                    d?.flagCode
                                                                        ? d?.flagCode
                                                                        : "",
                                                            }),
                                                        );
                                                        setIsDropDownEnabled(
                                                            false,
                                                        );
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            styles.selectedDomain_left
                                                        }
                                                    >
                                                        <img
                                                            src={`https://flagcdn.com/w20/${
                                                                d?.flagCode
                                                                    ? d?.flagCode?.toLowerCase()
                                                                    : "in"
                                                            }.png`}
                                                            srcSet={`https://flagcdn.com/w40/${
                                                                d?.flagCode
                                                                    ? d?.flagCode?.toLowerCase()
                                                                    : "in"
                                                            }.png 2x`}
                                                        />
                                                        <span>{d?.label}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </ClickAwayListener>
                    )}
                </div>
            </div>
        </>
    );
};

const CollapsibleDropDown = ({
    subDomain,
    handleSubDomainPages,
    index = -1,
    search = "",
}: {
    subDomain: SubDomainObject;
    handleSubDomainPages: (object: {
        subDomainIndex: number;
        pageIndex?: number;
        value: boolean;
    }) => void;
    index: number;
    search: string;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const checkBoxClasses = checkBoxCustom();
    const FormControlLabelClasses = FormControlLabelCustom();
    const { addForwardSlashUrl } = useGscHook();

    return (
        <>
            <div className={styles.Collapsible} key={subDomain.subDomain}>
                <div className={styles.CollapsibleContainer}>
                    <div className={styles.collapsibleWrapperdiv}>
                        {isOpen ? (
                            <BiChevronDown
                                onClick={() => setIsOpen(false)}
                                size={20}
                            />
                        ) : (
                            <BiChevronRight
                                onClick={() => setIsOpen(true)}
                                size={20}
                            />
                        )}
                        <FormControlLabel
                            label=""
                            control={
                                <Checkbox
                                    size="small"
                                    checked={
                                        subDomain.is_selected == 0
                                            ? false
                                            : true
                                    }
                                    //@ts-ignore
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleSubDomainPages({
                                            subDomainIndex: index,
                                            value: e.target.checked,
                                        });
                                    }}
                                    classes={checkBoxClasses}
                                />
                            }
                        />
                    </div>
                    <div
                        className={`${styles.CollapsibleHeading} ${
                            subDomain.is_selected == 1 && styles.selected
                        }`}
                    >
                        {subDomain.subDomain}
                        <span className={styles.pills}>
                            {subDomain.count}{" "}
                            {subDomain.count > 1 ? "pages" : "page"}
                        </span>
                    </div>
                </div>
                {isOpen && (
                    <div className={styles.CollapsibleContent}>
                        {subDomain.pages
                            .filter((page) =>
                                page.subPage
                                    .toLowerCase()
                                    .includes(search.toLowerCase()),
                            )
                            .map((page: SubPageObject, pageIndex: number) => {
                                return (
                                    <div
                                        className={styles.collapsible2}
                                        key={pageIndex}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    classes={checkBoxClasses}
                                                    size="small"
                                                    checked={
                                                        page?.is_selected == 0
                                                            ? false
                                                            : true
                                                    }
                                                    onChange={(e: any) => {
                                                        e.stopPropagation();
                                                        handleSubDomainPages({
                                                            subDomainIndex:
                                                                index,
                                                            pageIndex:
                                                                pageIndex,
                                                            value: e.target
                                                                .checked,
                                                        });
                                                    }}
                                                />
                                            }
                                            label={
                                                <_LightTooltip
                                                    arrow
                                                    interactive={true}
                                                    title={
                                                        <div
                                                            className={
                                                                styles.linksWrapper
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.tooltipContentTitle
                                                                }
                                                                style={{
                                                                    marginTop:
                                                                        "4px",
                                                                }}
                                                            >
                                                                Link Examples{" "}
                                                            </span>
                                                            <div
                                                                className={
                                                                    styles.links
                                                                }
                                                            >
                                                                {page.links.map(
                                                                    (
                                                                        link,
                                                                        index,
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={
                                                                                styles.tooltipContent
                                                                            }
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                window.open(
                                                                                    link,
                                                                                    "_blank",
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                link
                                                                            }
                                                                        </div>
                                                                    ),
                                                                )}
                                                            </div>
                                                        </div>
                                                    }
                                                    placement="right"
                                                >
                                                    <div
                                                        className={`${
                                                            styles.subPageItem
                                                        } ${
                                                            page.is_selected ==
                                                                1 &&
                                                            styles.selected
                                                        }`}
                                                    >
                                                        {addForwardSlashUrl(
                                                            page?.subPage,
                                                        )}
                                                    </div>
                                                </_LightTooltip>
                                            }
                                            classes={{
                                                root: FormControlLabelClasses.root,
                                            }}
                                        />

                                        <div className={styles.wrap}>
                                            <span className={styles.rootPill}>
                                                {page.count}
                                                {page.count < 2
                                                    ? " page"
                                                    : " pages"}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </>
    );
};

const GscLogin = ({
    setIsShowData,
    initialLoading,
    isLoggedIn,
    setIsLoggedIn,
    userInfo,
    domains,
    selectedDomain,
    setSelectedDomain,
    getDomainList,
    getDetails,
    setActiveStep,
    activeStep,
    alertForTeamMember = false,
    tempGscId,
    setTempGscId,
}: Props_GscLogin) => {
    const { getData, postData } = useHelperFunctions();
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { taTrackGscConnect } = useMixpanelTA();

    const responseGoogle: (response: any) => void = async (response) => {
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                },
            });
            if (res.status == 200) {
                setIsLoggedIn(true);
                dispatch(
                    setUserGscInfo({
                        email: res.data?.resp?.userInfo?.email,
                    }),
                );
                console.log(res.data, "userInfo response");
                setActiveStep(2);
            } else {
                dispatch(
                    displayAlert({
                        alertMessage: `${res.response.data.message}`,
                        alertType: "error",
                        timeout: 5000,
                    }),
                );
            }
        }
        setLoading(false);
    };

    const handleLogin: () => void = () => {
        setLoading(true);
        let gsc = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: responseGoogle,
            error_callback: () => {
                setLoading(false);
            },
        });
        gsc.requestCode();
    };

    return (
        <div className={styles.GSC_container}>
            {initialLoading ? (
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : !isLoggedIn ? (
                <div className={styles.googleLoginContainer}>
                    <div className={styles.loginCard}>
                        <div className={styles.loginCardTitle}>
                            Welcome to Traffic Analyzer 👋
                        </div>
                        <div className={styles.loginCardSubTitle}>
                            Gain unparalleled insights and turbocharge your
                            online presence with our Traffic Analyzer tool,
                            seamlessly connected to your Google Search Console.
                        </div>

                        <div className={styles.videoContainer}>
                            <VideoModal
                                link={`${"https://www.youtube.com/embed/ef2jWNyx6K0"}?modestbranding=1&rel=0&loop=1`}
                                zoomVal={1.2}
                                modal={false}
                                sourceWhere={"trafficAnalyser"}
                                customClass={styles.video}
                            />
                        </div>

                        {/* <div className={styles.videoContainer}>
                            <iframe
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                src={trafficAnalyzerVideo?.link}
                                title="BlogCreator"
                                allow="autoplay; fullscreen; picture-in-picture"
                                aria-controls="true"
                                //@ts-ignore
                                allowfullscreen
                            ></iframe>
                        </div> */}
                        {alertForTeamMember ? (
                            <div className={styles.ctaContainer}>
                                <div className={styles.ctaText}>
                                    Please reach out to your administrator to
                                    request access and connect domains
                                </div>
                            </div>
                        ) : (
                            <div className={styles.ctaContainer}>
                                <div className={styles.ctaText}>
                                    Let’s start by syncing your Google Search
                                    Console data.
                                </div>
                                <Button
                                    loading={loading}
                                    handler={() => {
                                        taTrackGscConnect({
                                            screen: "Traffic Analyzer (Landing Page)",
                                            isReAuth: "No",
                                        });
                                        handleLogin();
                                    }}
                                    height={"44"}
                                    Icon={() => (
                                        <div className={styles.iconWrapper}>
                                            <FcGoogle />
                                        </div>
                                    )}
                                    secondary={true}
                                    text={"Continue with Google"}
                                    iconReverse={true}
                                    width={"100%"}
                                    customClass={styles.customGoogleLogin}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles.gscLoginModal}>
                    <GscLoginFlow
                        domains={domains}
                        selectedDomain={selectedDomain}
                        setSelectedDomain={setSelectedDomain}
                        userInfo={userInfo}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        getDomainList={getDomainList}
                        source={"traffic analyzer"}
                        setIsShowData={setIsShowData}
                        setTempGscId={setTempGscId}
                        tempGscId={tempGscId}
                        setGscLoginModal={() => {
                            setActiveStep(1);
                            setIsLoggedIn(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default GscLogin;
