import { Skeleton } from "@material-ui/lab";
import React from "react";

function SkeletonTPs() {
    return (
        <>
            <Skeleton variant="text" width="100%" height="25" />
            <Skeleton variant="text" width="100%" height="25" />
            <Skeleton variant="text" width="100%" height="25" />
            <Skeleton variant="text" width="100%" height="25" />
            <Skeleton variant="text" width="100%" height="25" />
        </>
    );
}

export default SkeletonTPs;
