import DefaultAligner from "quill-blot-formatter/dist/actions/align/DefaultAligner";
import BlotFormatter from "quill-blot-formatter/dist/BlotFormatter";

export const allowedMarginStyles = [
    "0px auto 0px 0px",
    "0px 0px 0px auto",
    "auto",
];

class _BlotFormatter extends BlotFormatter {
    constructor(quill) {
        super(quill);
        Object.assign(this.overlay.style || {}, {
            zIndex: 10,
        });
        const resizeObserver = new ResizeObserver(() => this.update());
        resizeObserver.observe(quill.root);
    }
}

DefaultAligner.prototype.setStyle = function (el, display, float, margin) {
    if (this.applyStyle) {
        el.style.setProperty(
            "margin",
            float === "left"
                ? allowedMarginStyles[0]
                : float === "right"
                ? allowedMarginStyles[1]
                : allowedMarginStyles[2],
        );
        document?.quill?.editor?.emitter?.emit("editor-change");
        el.style.setProperty("float", null);
    }
};
export default _BlotFormatter;
