import React, { useState, useEffect } from "react";
import styles from "./GenerateHistory.module.css";
import TextArea from "../../../atoms/TextArea/TextArea";
import useHelperFunctions from "../../../helperFunctions";
import GenerateResults from "../GenerateResults/GenerateResults";
import { GENERATE_HISTORY } from "../../../api.json";
import Button from "../../../atoms/Button/Button";
import HistoryCard from "./HistoryCard";
import HistoryModal from "./HistoryModal";
import HistoryLoading from "./HistoryLoading";
import { getTime } from "../../../../utility/validateFunctions";
import { HiArrowNarrowRight } from "react-icons/hi";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import InfiniteScroll from "react-infinite-scroll-component";
const arr = [1, 2, 3, 4, 5, 6, 7, 8, 7, 7, 7, 7, 7, 7, 7, 77, 4, 4, 7];

let setLocalData = null;

function GenerateHistory({ setAllUseCases }) {
    const [responseText, setResponseText] = useState([]);
    const { getData } = useHelperFunctions();
    const [allResponseText, setAllResponseText] = useState([]);
    const [isFav, setIsFav] = useState(false);
    const [selectedCard, setSelectedCard] = useState(0);
    const [modalData, setModalData] = useState(null);
    const [loader, setLoader] = useState(true);
    const goTo = useNavigation();
    const [displayMobileModal, setdisplayMobileModal] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    let timeArray = [];

    useEffect(() => {
        getAllHistory(page, setHasMore);
    }, []);

    async function getAllHistory(page, setHasMore) {
        setPage(page + 1);
        getData({ url: `${GENERATE_HISTORY}?page=${page}` }).then(
            (histories) => {
                const arr = [];
                if (histories.data) {
                    for (const key in histories.data.data) {
                        histories.data.data[key].id = key;
                        arr.push(histories.data.data[key]);
                    }

                    if (arr.length == 0) setHasMore(false);

                    setResponseText((ps) => ps.concat(arr));
                    setAllResponseText((ps) => ps.concat(arr));
                }
                setLoader(false);
            },
        );
    }

    return (
        <div className={styles.container}>
            {loader || responseText.length > 0 ? (
                <div className={styles.containerCards}>
                    <h3 className={styles.heading}>
                        Refer to all your recent activities
                    </h3>
                    <h4 className={styles.subHeading}>
                        Jump back to your previous generated contents
                    </h4>
                    <div
                        id="aiHistoryList"
                        style={{ height: "calc(100vh - 250px)" }}
                        className={styles.cardsListContainer}
                    >
                        <InfiniteScroll
                            dataLength={allResponseText.length}
                            next={() => {
                                getAllHistory(page, setHasMore);
                            }}
                            hasMore={hasMore}
                            loader={<HistoryLoading />}
                            scrollableTarget="aiHistoryList"
                        >
                            {allResponseText.map((item, i) => {
                                return (
                                    <>
                                        <h6 className={styles.timeAgo}>
                                            {timeArray.includes(
                                                getTime(item.created_at),
                                            )
                                                ? null
                                                : getTime(item.created_at)}{" "}
                                            <span style={{ display: "none" }}>
                                                {timeArray.push(
                                                    getTime(item.created_at),
                                                )}
                                            </span>
                                        </h6>
                                        <HistoryCard
                                            setdisplayMobileModal={
                                                setdisplayMobileModal
                                            }
                                            onClick={(localData, setLocal) => {
                                                setSelectedCard(i);
                                                setLocalData = setLocal;
                                                setModalData(localData);
                                            }}
                                            selected={selectedCard == i}
                                            item={item}
                                            key={item.id}
                                            i={i}
                                        />
                                    </>
                                );
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            ) : (
                <div className={styles.flex}>
                    <img src="/AssetsV2/history-graphic.svg" alt="" />
                    <div className={styles.flex1}>
                        Currently you have not generated any content. <br />
                        Start by generating content
                    </div>
                    <Button
                        width={"100%"}
                        text={"Generate"}
                        Icon={HiArrowNarrowRight}
                        height="60px"
                        fontSize="16px"
                        handler={(e) => goTo("NEW_SMART_DOCS")}
                    ></Button>
                    <div className={styles.flex2}>
                        Come back here to view your history
                    </div>
                </div>
            )}

            {responseText.length > 0 && modalData && (
                <div
                    className={
                        styles.containerModal +
                        " " +
                        (displayMobileModal ? styles.displayMobileModal : "")
                    }
                >
                    <HistoryModal
                        setLocalData={setLocalData}
                        item={allResponseText[selectedCard]}
                        text={modalData}
                        setModalData={setModalData}
                    />
                </div>
            )}
        </div>
    );
}

export default GenerateHistory;
