import React, { useEffect, useState } from "react";
import styles from "./NewDashboard.module.css";
import Button from "../../atoms/Button/Button";
import moment from "moment";
import { ChevronRight, YouTube } from "@material-ui/icons";
import { FaYoutube } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import { Style } from "util";
import { useSelector } from "react-redux";
import VideoModal from "./VideoModal";
import { Link } from "react-router-dom";
import { type } from "os";
import ProgressBar from "../../atoms/ProgressBar";
import { Skeleton } from "@material-ui/lab";
import { FEATURES, getTrailText } from "./constants";
import SVGIcon from "../../atoms/SVGIcon";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { AiOutlinePlus } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { socket } from "../../../Sockets/useSockets";
import { socketEvents } from "../../../Sockets/constants";
import loader from "./loader.gif";

const docStatus = {
    0: "Ready for Writing",
    1: "Writing in progress",
    2: "Editing in progress",
    3: "Completed",
};

type doc = {
    is_sample: any;
    audit_url: string;
    search_keyword: string;
    search_country: string;
    created_at: string;
    id: string;
    report_id: string;
    steps: number;
    step_status: number;
    topic_id: string;
    is_premium: number;
    blog_processing_status: number;
};

function ModuleCard({
    handler,
    firstCard,
    data,
    isSecondary,
    recommended,
    i,
}: {
    handler: Function;
    firstCard?: boolean;
    data: {
        title: React.ReactElement;
        vdLink: string;
        url: string;
        docs: Array<doc>;
        docUrlPrefix: Function;
        id: number;
        ctaText: string;
        subText: string;
    };
    isSecondary?: boolean;
    recommended?: boolean;
    i: number;
}) {
    const { isMobile } = useSelector((state: any) => state?.state);
    const { planExpiresDate, planTrial } = useUserAccessHook();
    const { mixpanelTrack } = useMixpanelHook();
    const user_name = useSelector((state: any) => state?.auth?.user_name);
    return (
        <div className={styles.module_card}>
            <header>
                <div>
                    <h1
                        style={{
                            position: "relative",
                        }}
                    >
                        {data.title} <br />
                    </h1>
                    <small>{data.subText}</small>
                </div>

                <div className={styles.btnTagCont}>
                    <Button
                        text={isMobile ? "" : data.ctaText}
                        style={{
                            width: isMobile ? 40 : 79,
                            height: 34,
                            borderRadius: 6,
                            fontSize: 14,
                            background: isMobile
                                ? "rgba(1, 77, 197, 0.10)"
                                : isSecondary
                                ? "#e0ecff"
                                : "",
                            color: isMobile
                                ? "#014dc5"
                                : isSecondary
                                ? "#014dc5"
                                : "white",
                            transition: "none",
                        }}
                        handler={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `Module Card CTA - ${data.ctaText}`,
                                });
                            }
                            handler();
                        }}
                        disabled={!data.docs.length}
                        Icon={() =>
                            isMobile ? (
                                <FiPlus strokeWidth={"2px"} size={22} />
                            ) : (
                                <></>
                            )
                        }
                    />
                    {recommended && <Tag />}
                </div>
            </header>{" "}
            <div className={styles.docList}>
                {data.docs.length ? (
                    data.docs.map((doc: doc) => {
                        return (
                            <>
                                <DocItem
                                    docUrlPrefix={data.docUrlPrefix}
                                    doc={doc}
                                    featureId={data.id}
                                    data={data}
                                />
                            </>
                        );
                    })
                ) : (
                    <DocsSkeleton />
                )}
            </div>
            {!isMobile && (
                <div className={styles.docBottom}>
                    {data.vdLink ? (
                        <VideoModal
                            buttonForm={true}
                            link={data.vdLink}
                            modal={true}
                            isDash={true}
                            featureId={data.id}
                        />
                    ) : (
                        <Button
                            style={{
                                background: "#FFEEEE",
                                border: "none",
                                borderRadius: "5.30612px",
                                width: 120,
                                height: 34,
                                fontSize: 11,
                                color: "red",
                            }}
                            handler={() => {}}
                            Icon={() => (
                                <FaYoutube
                                    color="#ff0000d1"
                                    fontWeight={400}
                                    size={16}
                                />
                            )}
                            iconReverse
                            text={"How it works"}
                        />
                    )}

                    <Link
                        onClick={() => {
                            if (
                                getTrailText(planExpiresDate) == 7 &&
                                planTrial
                            ) {
                                mixpanelTrack("Day 0 Dashboard", {
                                    action: `See All - ${
                                        data.id == 1
                                            ? "Article Writer"
                                            : data.id == 2
                                            ? "Optimizer"
                                            : "Keyword Planner"
                                    }`,
                                });
                            }
                        }}
                        style={{ textDecoration: "none" }}
                        to={data.url}
                    >
                        See all <BsChevronRight size={13} />
                    </Link>
                </div>
            )}
        </div>
    );
}

const DocItem = ({
    doc,
    docUrlPrefix,
    featureId,
    data,
}: {
    doc: doc;
    docUrlPrefix: Function;
    featureId: number | string;
    data: any;
}) => {
    const { mixpanelTrack } = useMixpanelHook();
    const { planExpiresDate, planTrial } = useUserAccessHook();
    const [isProgress, setIsProgress] = useState(doc.blog_processing_status);
    useEffect(() => {
        const successCallback = (data: any) => {
            if (doc.report_id == data.report_id) {
                setIsProgress(data.blog_processing_status);
            }
        };
        if (featureId == FEATURES.cruise_mode) {
            socket.on(socketEvents.BLOG_PROCESSING_STATUS, successCallback);
        }
        return () => {
            if (featureId == FEATURES.cruise_mode) {
                socket.off(
                    socketEvents.BLOG_PROCESSING_STATUS,
                    successCallback,
                );
            }
        };
    }, []);
    return (
        <Link
            to={docUrlPrefix(doc.report_id || doc.topic_id)}
            className={styles.docItem}
            onClick={() => {
                if (getTrailText(planExpiresDate) == 7 && planTrial) {
                    mixpanelTrack("Day 0 Dashboard", {
                        action: `Open Report - ${
                            featureId == 1
                                ? "Article Writer"
                                : featureId == 2
                                ? "Optimizer"
                                : "Keyword Planner"
                        }`,
                        report_id: doc.report_id || doc.topic_id,
                        report_title: doc.search_keyword,
                    });
                }
            }}
        >
            <div className={styles.docTitle}>
                <div
                    style={{
                        display: "flex",
                        // gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {doc.search_keyword}
                    </div>
                    {FEATURES.cruise_mode == featureId && isProgress == 1 && (
                        <LightTooltip
                            arrow
                            title={
                                "Please wait, your article is being generated."
                            }
                        >
                            <div style={{ height: 28 }}>
                                <img width={28} height={28} src={loader} />
                            </div>
                        </LightTooltip>
                    )}
                </div>
                {FEATURES.optimizer == featureId ? (
                    doc?.audit_url ? (
                        <>
                            {/* <br /> */}
                            <LightTooltip
                                arrow
                                placement="bottom"
                                title={doc?.audit_url}
                            >
                                <span className={styles.auditUrl}>
                                    {doc?.audit_url}
                                </span>
                            </LightTooltip>
                        </>
                    ) : (
                        <span className={styles.auditUrl}>
                            Content Added Manually
                        </span>
                    )
                ) : (
                    ""
                )}
                {FEATURES.cruise_mode == featureId && data.cruiseAccess ? (
                    <>
                        {/* <br /> */}
                        <span className={styles.auditUrl}>
                            {/* @ts-ignore */}
                            {docStatus[doc?.activity_status]}
                        </span>
                    </>
                ) : (
                    ""
                )}
            </div>
            {doc.is_sample ? <SampleTag /> : ""}
            <div className={styles.docDate}>
                {doc.is_premium ? (
                    <div>
                        <SVGIcon
                            style={{
                                marginLeft: "5px",
                            }}
                            src={"/New UI/SVG/premium-text-blue.svg"}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <div>
                    {doc.created_at
                        ? moment(doc.created_at).format("MMMM DD")
                        : ""}
                </div>
            </div>
        </Link>
    );
};

const SampleTag = () => {
    return <span className={styles.sampleTag}>Sample</span>;
};

const Tag = ({
    text = "Recommended",
    style = {},
    i = 0,
}: {
    text?: string;
    style?: React.CSSProperties;
    i?: number;
}) => {
    return (
        <div className={`${styles.tag}`} style={style}>
            {text}
        </div>
    );
};

const DocsSkeleton = () => {
    return (
        <>
            {Array(5)
                .fill(true)
                .map(() => {
                    return (
                        <div className={styles.docItem}>
                            <div className={styles.docTitle}>
                                <Skeleton width={"80%"}></Skeleton>

                                <Skeleton width={"40%"}></Skeleton>
                            </div>
                            <Skeleton
                                width={50}
                                style={{ marginRight: 30 }}
                            ></Skeleton>
                            <div className={styles.docDate}>
                                <Skeleton width={80}></Skeleton>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};
export default ModuleCard;
