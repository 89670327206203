import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import styles from "./OutlineBuilder.module.css";

import styles3 from "./stepCard.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import OutlineBox from "./OutlineBox";

import useHelperFunctions from "../../../helperFunctions";
import ReportContext from "../../Research/ReportContext";
import { v4 } from "uuid";
import { RiDeleteBin6Line } from "react-icons/ri";

import PlaceholderOutlines from "./PlaceholderOutlines";

import { Box, makeStyles, Tab, Typography, Tabs } from "@material-ui/core";
import PlaceholderFAQ from "./PlaceholderFAQ";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import LoaderGoNuts from "./LoaderGoNuts";
import Progress from "../../../atoms/Progress/Progress";
import { cruiseMixPanelEvents } from "./cruiseConstant";
import { CRUISE_TYPES } from "./useCruiseHook";
import { CruiseContext, CruiseState } from "./StepsWizard";
import { Outline } from "./classes";
import { useMemo } from "react";
import Button from "../../../atoms/Button/Button";

import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

import { BsCheck2Circle, BsDownload } from "react-icons/bs";
import {
    CopyOutline,
    ExportOutline2Word,
    saveOutlineAsPDF,
} from "../../TextEditor/Options/utils";
import { AiOutlineCopy } from "react-icons/ai";
import { useSelector } from "react-redux";

const useStylesTab = makeStyles((theme) => ({
    root: {
        minHeight: 30,
        margin: "0 10px !important",
        fontSize: 14,
        textTransform: "none",
        padding: "5px 2px",
        color: "black",
        "min-width": "max-content",
        color: "#29394a",
        opacity: 1,

        "&:hover": {
            color: "#1f4cbe",
        },
        "& .MuiTab-wrapper": {
            flexDirection: "row-reverse",
            justifyContent: "center",
            gap: "4px",
            alignItems: "center",
        },
        "& .MuiTab-wrapper > *:first-child ": {
            marginBottom: "0px",
        },
    },
    icon: {
        color: "red",
    },
    selected: {
        fontWeight: 700,
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#1f4cbe",
        height: 4,
    },
    root: {
        borderBottom: "solid #d6d6d6 0.1px",
        minHeight: 38,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                margin: "15px",
                marginLeft: "10px",
                marginRight: "10px",
                flexGrow: 1,
                height: "50%",
                overflow: "auto",
            }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const OutlineBuilder = ({
    addHeading,
    setAddHeading,
    writeH2FAQ,
    removeH2Faq,
    title,
    setActiveH2Parent,
    setGenH2List,
    genH2List,
    setGenFaqList,
    genFaqList,
    topRankOutlines,
    setConclusionCta = () => {},
    conclusionCta = {},
    setActionMsg = () => {},
    tempGuideLineData = {},
    generateOutlines,
}) => {
    // const [isGeneratingFAQ, setIsGen] = useEffect(false);
    const { mixpanelTrack } = useMixpanelHook();
    const { postData } = useHelperFunctions();
    const [isCollasped, setIsCollasped] = useState(true);
    const ref = useRef();
    const [isCopied, setIsCopied] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [added, setAdded] = useState({ card: -1, index: -1 });
    const auth = useSelector((state) => state.auth);
    const dispatchStepsData = useContext(CruiseContext);
    const stepsData = useContext(CruiseState);
    const { reportInfo } = useContext(ReportContext);
    const isPremiumOrSpecificSegment =
        reportInfo?.is_premium ||
        auth.segment == 3 ||
        auth.segment == 4 ||
        auth.segment == 5 ||
        auth.segment == 6;

    const headingCount = useMemo(() => {
        let count = 0;
        stepsData?.outlines?.headings.forEach(
            /**
             * @param {Outline} h2
             */
            (h2) => {
                count += h2?.outlineH3s?.length || 0;
                count++;
            },
        );
        if (count == 0) {
            dispatchStepsData({
                type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                data: "",
            });
        }
        count += stepsData?.outlines?.questions?.length
            ? stepsData.outlines.questions.length + 1
            : 0;
        return count;
    }, [stepsData?.outlines?.headings, stepsData?.outlines?.questions]);

    let totalHeadingCount = headingCount;
    stepsData?.outlines?.headings?.length > 0 ||
    stepsData?.outlines?.questions?.length > 0
        ? (totalHeadingCount += isPremiumOrSpecificSegment ? 1 : 0)
        : 0;

    function handleDownload(type) {
        mixpanelTrack("OutlinesDownloaded", { type });
        switch (type) {
            case "pdf":
                saveOutlineAsPDF(
                    stepsData?.outlines,
                    stepsData?.title,
                    reportInfo.title,
                );
                break;
            case "copy":
                {
                    setIsCopied(true);
                    CopyOutline(
                        stepsData?.outlines,
                        stepsData?.title,
                        reportInfo.title,
                    );
                    setTimeout(() => {
                        setIsCopied(false);
                    }, 2000);
                }

                break;
            case "doc":
                ExportOutline2Word(
                    stepsData?.outlines,
                    stepsData?.title,
                    reportInfo.title,
                );
                break;
        }
    }

    useEffect(() => {
        return () => dispatchStepsData({ type: CRUISE_TYPES.SYNC_IDS });
    }, []);
    return (
        <>
            <div ref={ref} className={styles.outlineContainer}>
                {isLoader && <LoaderGoNuts />}

                <p className={styles.sectionName}>Title: {stepsData?.title}</p>

                <div className={styles.headingsSection}>
                    <div>
                        Headings &nbsp;
                        <span className={styles.headingCount}>
                            <span
                                className={
                                    styles.less +
                                    // styles.headingCount__current +
                                    " " +
                                    (!(
                                        totalHeadingCount <
                                            +stepsData?.heading_count?.split(
                                                "-",
                                            )[0] ||
                                        totalHeadingCount >
                                            +stepsData?.heading_count?.split(
                                                "-",
                                            )[1]
                                    ) &&
                                        totalHeadingCount > 0 &&
                                        styles.headingCount__current)
                                }
                            >
                                {totalHeadingCount || 0}
                            </span>
                            /
                            <span className={styles.headingCount__total}>
                                {stepsData?.heading_count}
                            </span>
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <LightTooltip
                                    arrow
                                    title={
                                        "Actual count of headings vs suggested range of headings"
                                    }
                                >
                                    <div>
                                        <SVGIcon
                                            size={18}
                                            src={"/New UI/SVG/info.svg"}
                                            style={{
                                                color: "#2196f3",
                                                marginLeft: "1.5px",
                                            }}
                                            color={"#2196f3"}
                                        />
                                    </div>
                                </LightTooltip>
                            </span>
                        </span>
                    </div>
                    <div>
                        {!isLoader ? (
                            <LightTooltip
                                title="Regenerate Outlines"
                                arrow
                                placement="top"
                            >
                                <span style={{ marginRight: 10 }}>
                                    <Button
                                        Icon={() => (
                                            <SVGIcon
                                                src={"/New UI/SVG/ImLoop2.svg"}
                                            />
                                        )}
                                        iconReverse={true}
                                        text=""
                                        style={{
                                            color: "var(--primary-blue)",
                                            backgroundColor: "#f1f4fd",
                                            borderRadius: 3,
                                            fontSize: 13,
                                            width: 35,
                                            height: 28,
                                        }}
                                        handler={() => {
                                            generateOutlines();
                                        }}
                                    />
                                </span>
                            </LightTooltip>
                        ) : (
                            ""
                        )}

                        {headingCount ? (
                            <LightTooltip
                                interactive
                                title={
                                    <div className={styles.downloadDrop}>
                                        <div
                                            className={styles.optionRow}
                                            onClick={() =>
                                                handleDownload("doc")
                                            }
                                        >
                                            <div
                                                className={styles.iconContainer}
                                            >
                                                <SVGIcon src="/New UI/SVG/Doc-icon.svg" />
                                            </div>
                                            <div>Download as Doc</div>
                                        </div>
                                        <div
                                            className={styles.optionRow}
                                            onClick={() =>
                                                handleDownload("copy")
                                            }
                                        >
                                            <div
                                                className={styles.iconContainer}
                                            >
                                                {!isCopied ? (
                                                    <AiOutlineCopy
                                                        size={18}
                                                        color="var(--primary-blue)"
                                                        fillOpacity={0.8}
                                                    />
                                                ) : (
                                                    <BsCheck2Circle
                                                        size={18}
                                                        color="green"
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                {isCopied ? "Copied" : `Copy`}{" "}
                                                to clipboard
                                            </div>
                                        </div>
                                        <div
                                            className={styles.optionRow}
                                            onClick={() =>
                                                handleDownload("pdf")
                                            }
                                        >
                                            <div
                                                className={styles.iconContainer}
                                            >
                                                <SVGIcon src="/New UI/SVG/PDF-icon.svg" />
                                            </div>
                                            <div>Download as PDF</div>
                                        </div>
                                    </div>
                                }
                                tooltipStyle={{
                                    border: "none",
                                    margin: 0,
                                    padding: 0,
                                }}
                                placement="bottom-start"
                            >
                                <span>
                                    <Button
                                        Icon={() => (
                                            <BsDownload
                                                color="#014dc5"
                                                size={"15"}
                                            />
                                        )}
                                        iconReverse={true}
                                        text=""
                                        style={{
                                            color: "var(--primary-blue)",
                                            backgroundColor: "#f1f4fd",
                                            borderRadius: 3,
                                            fontSize: 13,
                                            width: 35,
                                            height: 28,
                                            marginRight: 10,
                                        }}
                                    />
                                </span>
                            </LightTooltip>
                        ) : (
                            ""
                        )}
                        {headingCount ? (
                            <LightTooltip
                                title="Delete All Headings"
                                arrow
                                placement="top"
                            >
                                <span>
                                    <Button
                                        Icon={() => (
                                            <RiDeleteBin6Line
                                                color="#014dc5"
                                                size={"15"}
                                            />
                                        )}
                                        iconReverse={true}
                                        text=""
                                        style={{
                                            color: "var(--primary-blue)",
                                            backgroundColor: "#f1f4fd",
                                            borderRadius: 3,
                                            fontSize: 13,
                                            width: 35,
                                            height: 28,
                                        }}
                                        handler={() => {
                                            mixpanelTrack(
                                                cruiseMixPanelEvents.delete,
                                                {
                                                    "Screen Name": "Outline",
                                                    "Deleted Section":
                                                        "Delete All Headings",
                                                },
                                            );
                                            mixpanelTrack(
                                                cruiseMixPanelEvents.deleteAllOutlines,
                                                {
                                                    headingCount: headingCount,
                                                },
                                            );
                                            dispatchStepsData({
                                                type: CRUISE_TYPES.DELETE_ALL_OUTLINES,
                                            });
                                            dispatchStepsData({
                                                type: CRUISE_TYPES.DELETE_FEATURED_IMAGE,
                                            });
                                        }}
                                    />
                                </span>
                            </LightTooltip>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <OutlineBox
                    isPremiumOrSpecificSegment={isPremiumOrSpecificSegment}
                    headingCount={headingCount}
                    headings={
                        stepsData?.outlines?.headings
                            ? stepsData?.outlines?.headings
                            : []
                    }
                    dispatchStepsData={dispatchStepsData}
                    title={title}
                    setConclusionCta={setConclusionCta}
                    conclusionCta={conclusionCta}
                    addHeading={addHeading}
                    setIsLoader={setIsLoader}
                    setAddHeading={setAddHeading}
                    stepsData={stepsData}
                    setActiveH2Parent={setActiveH2Parent}
                    setGenH2List={setGenH2List}
                    genH2List={genH2List}
                    GeneratedContentOutline={
                        <div
                            className={`${styles3.rightContainer} ${
                                reportInfo?.is_premium &&
                                styles3.premiumRightContainer
                            }`}
                        >
                            <GeneratedContentOutline
                                added={added}
                                topRankOutlines={topRankOutlines}
                                setAddHeading={setAddHeading}
                                addFaqOutline={(text) => {
                                    const faq = new Outline({
                                        type: Outline.type.question,
                                        tag: Outline.tags.question,
                                        id: v4(),

                                        data: text,
                                    });

                                    dispatchStepsData({
                                        type: CRUISE_TYPES.ADD_OUTLINES,
                                        field: Outline.type.question,
                                        data: faq,
                                    });
                                    setActionMsg({
                                        type: "success",
                                        text: `Successfully added: ${text}`,
                                        show: true,
                                    });
                                }}
                                headings={stepsData?.outlines?.headings}
                                questions={stepsData.outlines.questions}
                            />
                        </div>
                    }
                    containerRef={ref}
                    writeH2FAQ={writeH2FAQ}
                    setIsGenCollapseQues={setIsCollasped}
                    removeH2Faq={removeH2Faq}
                    setGenFaqList={setGenFaqList}
                    genFaqList={genFaqList}
                    topRankOutlines={topRankOutlines}
                />
            </div>
        </>
    );
};

const GeneratedContentOutline = ({
    topRankOutlines,

    setAddHeading,
    added,
    addFaqOutline,
    headings,
    questions,
}) => {
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true);
    const userId = useSelector((state) => state.auth.userId);
    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();
    const stepsData = useContext(CruiseState);
    const dispatchStepsData = useContext(CruiseContext);
    const { reportInfo } = useContext(ReportContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        if (stepsData.show_outlines || userId % 2 == 0)
            setTimeout(() => setLoading(false), 1000);
    }, [stepsData.show_outlines]);

    return stepsData.show_outlines || userId % 2 == 0 ? (
        <>
            <Tabs classes={classesTabs} value={value} onChange={handleChange}>
                <Tab
                    disableRipple={true}
                    classes={classesTab}
                    label={
                        <LightTooltip
                            tooltipStyle={{
                                whiteSpace: "nowrap",
                            }}
                            arrow
                            title={`View the blog outlines of your top ${reportInfo.competitors_count} competitors.`}
                        >
                            <div>Top Ranked Outlines</div>
                        </LightTooltip>
                    }
                />
                <Tab
                    disableRipple={true}
                    classes={classesTab}
                    label={
                        <LightTooltip
                            arrow
                            title={
                                "Choose from relevant FAQs from socials or AI-generated engaging questions"
                            }
                        >
                            <div>Questions</div>
                        </LightTooltip>
                    }
                />
            </Tabs>

            {loading ? (
                <TabPanel value={value} index={0}>
                    <Progress height={"100%"} width="100%" />
                </TabPanel>
            ) : (
                <>
                    <TabPanel value={value} index={0}>
                        <PlaceholderOutlines
                            topRankOutlines={topRankOutlines}
                            setAddHeading={setAddHeading}
                            added={added}
                            headings={headings}
                            questions={questions}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <PlaceholderFAQ addFaqOutline={addFaqOutline} />
                    </TabPanel>
                </>
            )}
        </>
    ) : (
        <div
            onClick={() => {
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_SHOW_OUTLINES,
                    state: true,
                });
            }}
            className={styles.container}
            style={{ color: "var(--primary-blue)" }}
        >
            {/* <SVGIcon src="/New UI/SVG/outlines123.svg" /> */}
            {!stepsData.isLoader ? (
                <>
                    <div style={{ color: "var(--primary-blue)" }}>
                        <lottie-player
                            player={"anime"}
                            id={"anime"}
                            src={
                                "https://storage.googleapis.com/scalenut/icons/OutlineAnimation+(2).json"
                            }
                            background="transparent"
                            speed="1"
                            autoplay
                        ></lottie-player>
                    </div>
                    To Explore the Top Ranking Outlines
                    <div
                        className={styles.exploreTROCtaWrapper}
                        onClick={() => {
                            dispatchStepsData({
                                type: CRUISE_TYPES.SET_SHOW_OUTLINES,
                                state: true,
                            });
                            mixpanelTrack(
                                "Explore Top Ranked Outlines Clicked",
                            );
                        }}
                    >
                        <SVGIcon
                            src={"/New UI/SVG/add-four.svg"}
                            color={"#014dc5"}
                        />
                        <bb>Click here</bb>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default OutlineBuilder;
