import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import SVGIcon from "../../../../atoms/SVGIcon";
import DataServices from "../DataServices";

import styles from "./FirstDraft.module.css";
import HeadingBox from "./HeadingBox";
import LeftOutline from "./LeftOutline";
import ParagraphBox, { ParagraphRegens } from "./ParagraphBox";

import useHelperFunctions from "../../../../helperFunctions";
import ReportContext from "../../../Research/ReportContext";
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    LinearProgress,
    Typography,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useMixpanelHook from "../../../../../utility/useMixpanelHook";
import moment from "moment";
import {
    checkParas,
    cruiseMixPanelEvents,
    droppableIds,
    droppableTypes,
} from "../cruiseConstant";
import { CruiseContext, CruiseState } from "../StepsWizard";
import { CRUISE_TYPES } from "../useCruiseHook";
import { Outline, WP } from "../classes";
import { v4 } from "uuid";
import { useCallback } from "react";
import { socket } from "../../../../../Sockets/useSockets";
import { socketEvents } from "../../../../../Sockets/constants";
import MetaData from "../../../TextEditor/MetaData";
import Textfield from "../../../../atoms/CustomTextfield/Textfield";

import { BiCheck, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaListUl } from "react-icons/fa";
import { HiOutlinePencil } from "react-icons/hi";
import { VscClose, VscTrash } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { LightTooltip } from "../../../../atoms/Tooltip/tooltip";
import CircularProgressBar from "../../../../atoms/CircularProgressBar";
import Button from "../../../../atoms/Button/Button";
import { BsArrowRight, BsPencil } from "react-icons/bs";
import useNavigation from "../../../../atoms/GoToRoute/useNavigation";
import { RiDeleteBin6Line } from "react-icons/ri";
const CRUISE_MODE = {
    DEFAULT_HEADING_COUNT: 12,
    DEFAULT_WORD_COUNT: 125,
    AVG_INTRO_CONCLUSION_WORD_COUNT: 250,
};

let startTime = null;

const DID_YOU_KNOW = [
    <span>
        Did you know that 75% of users never scroll past the first page of
        search results? Cruise Mode is your first-class ticket to that exclusive
        club.
    </span>,
    <span>
        Did you know Google loves fresh content more than your grandma loves
        fresh cookies? Pop them in the SEO oven using Cruise Mode and watch your
        rankings rise.
    </span>,
    <span>
        Did you know you're more likely to summit Mount Everest than get a user
        to click on page 2 of Google's search results? Best pack Cruise Mode for
        the climb.
    </span>,
    <span>
        Did you know that long-form content typically gets an average of 77.2%
        more links than short articles? Buckle up for a keyword-rich journey
        with Cruise Mode.
    </span>,
    <span>
        Did you know that a good SEO strategy is like a slow cooked meal — it
        gets better over time? With Cruise Mode, you'll get that mouth-watering,
        top-of-the-page taste.
    </span>,
    <span>
        Did you know organic SEO is just like your eco-friendly shopping bag –
        it’s a long-term investment in the health of our online environment? Go
        green with Cruise Mode!
    </span>,
    <span>
        Did you know 92% of searchers will pick businesses on the first page of
        local search results? Cruise Mode guarantees a prime property in
        Google’s neighborhood.
    </span>,
    <span>
        Did you know search engines are the highway that delivers potential
        customers to your doorstep? Cruise Mode is your dreamy road-movie.
    </span>,
    <span>
        Did you know that using the same keyword too often can hurt your SEO?
        Cruise Mode is the antidote to keyword stuffing.
    </span>,
    <span>
        Did you know SEO is like a marathon, not a sprint? Buckle into Cruise
        Mode for a smooth, long-distance ride.
    </span>,
    <span>
        Did you know that optimal SEO is like preparing a Michelin star meal,
        it's all about quality ingredients? With Cruise Mode, you're the chef of
        high-quality SEO blogs.
    </span>,
];

let interval = null;
let interval2 = null;

const FirstDraft = ({ tempGuideLineData = {}, realtimeRating }) => {
    const [isIntroCollapse, setIsIntroCollapse] = useState(false);
    const [isHeadingCollapse, setIsHeadingCollapse] = useState(false);
    const [isFAQCollapse, setIsFAQCollapse] = useState(false);
    const [isGenIntro, setIsGenIntro] = useState(false);
    const [isGenConclu, setIsGenConclu] = useState(false);
    const [paragraphsIntro, setParagraphsIntro] = useState([]);
    const [paragraphsCon, setParagraphsCon] = useState([]);
    const [didYouKnowIndex, setDidYouKnowIndex] = useState(0);
    const { getData, postData } = useHelperFunctions();
    const introRef = useRef(null);
    const mainRef = useRef(null);
    const concluRef = useRef(null);
    const { mixpanelTrack } = useMixpanelHook();
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);

    const { reportInfo, keyTerms, competetion } = useContext(ReportContext);
    console.log(keyTerms, "kkkkkkkkkkkkk");
    console.log(keyTerms, "kkkkkkkkkkkkkk");
    const { mpTrackCruiseRegenerate } = useMixpanelHook();
    const [expandConHistory, setExpandConHistory] = useState(false);
    const [expandIntroHistory, setExpandIntroHistory] = useState(false);
    const [progressValue, setProgressValue] = useState(true);
    const [isAdding, setIsAdding] = useState(false);

    const dispatchStepsData = useContext(CruiseContext);
    const stepsData = useContext(CruiseState);
    const stepsDataRef = useRef(stepsData);
    stepsDataRef.current = stepsData;
    const isLoader = useMemo(() => stepsData.isLoader, [stepsData.isLoader]);
    const _realtimeRating = useMemo(() => realtimeRating, []);
    const trackRef = useRef(false);
    const goTo = useNavigation();
    const [buttonText, setButtonText] = useState("Add image alt text");
    const inputAltRef = useRef(null);
    const [altText, setAltText] = useState(stepsData?.featured_image?.altText);
    console.log(stepsData, "ssssssss");

    useEffect(() => {
        if (isAdding) {
            inputAltRef.current ? inputAltRef.current.focus() : "";
        }
    }, [isAdding]);

    useEffect(() => {
        if (stepsData.featured_image?.altText) {
            setButtonText("remove alt text");
        } else {
            setButtonText("Add image alt text");
        }
        setAltText(stepsData.featured_image?.altText);
    }, [stepsData.featured_image?.altText]);

    useEffect(() => {
        if (
            !checkParas(stepsData) &&
            (reportInfo?.is_premium ||
                ((auth.segment == 5 || auth.segment == 6) &&
                    stepsData.aiContentDetection))
        ) {
            interval2 = setInterval(() => {
                setDidYouKnowIndex((ps) => {
                    return (ps + 1) % DID_YOU_KNOW.length;
                });
            }, 30000);
            interval = setInterval(() => {
                setProgressValue((ps) => {
                    if (ps >= 90) return ps;
                    else return ps + 1;
                });
            }, 3000);
        } else {
            clearInterval(interval);
            clearInterval(interval2);
        }
        return () => {
            clearInterval(interval);
            clearInterval(interval2);
        };
    }, [stepsData]);

    useEffect(() => {
        if (
            !(_realtimeRating.wordCount == realtimeRating.wordCount) &&
            !trackRef.current
        )
            try {
                trackRef.current = true;
                {
                    const [min, max] = realtimeRating.wordRange.split("-");
                    mixpanelTrack(cruiseMixPanelEvents.generatedWordCount, {
                        generated: realtimeRating.wordCount,
                        suggestedRange: realtimeRating.wordRange,
                        suggested: (+min + +max) / 2,
                    });
                }

                {
                    mixpanelTrack(cruiseMixPanelEvents.generatedKeyTerms, {
                        generated: realtimeRating.quality,
                        suggested: realtimeRating.suggestedKeywordCount,
                    });
                }
            } catch (error) {
                console.log(error);
            }
    }, [realtimeRating]);

    /**
     * @param {import("react-beautiful-dnd").DropResult} res */
    const onDragEnd = (res) => {
        if (!res.source || !res.destination) {
            return;
        }

        switch (res.type) {
            case droppableTypes.outlineH2:
                if (res.source.droppableId == res.destination.droppableId) {
                    if (
                        res.source.droppableId.includes(droppableIds.topRanked)
                    ) {
                        return;
                    }
                    if (res.source.droppableId == droppableIds.headings) {
                        dispatchStepsData({
                            type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                            field: Outline.type.heading,
                            oldIndex: res.source.index,
                            newIndex: res.destination.index,
                            tag: Outline.tags.h2,
                        });
                    } else {
                        dispatchStepsData({
                            type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                            field: Outline.type.question,
                            oldIndex: res.source.index,
                            newIndex: res.destination.index,
                        });
                    }
                }

                break;

            case droppableTypes.outlineH3: {
                if (res.source.droppableId == res.destination.droppableId) {
                    const [h2Index, h2Id] =
                        res.destination.droppableId.split("_");

                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                        field: Outline.type.heading,
                        oldIndex: res.source.index,
                        newIndex: res.destination.index,
                        tag: Outline.tags.h3,
                        h2Index: h2Index,
                        h2_id: h2Id,
                    });
                } else {
                    const [destinationH2Index, destinationH2Id] =
                        res.destination.droppableId.split("_");
                    const [sourceH2Index, sourceH2Id] =
                        res.source.droppableId.split("_");
                    const sourceH3Index = +res.source.index;
                    const destinationH3Index = +res.destination.index;

                    dispatchStepsData({
                        type: CRUISE_TYPES.DELETE_OUTLINES,
                        field: Outline.type.heading,
                        tag: Outline.tags.h3,
                        h2Index: sourceH2Index,
                        index: sourceH3Index,
                        id: stepsData.outlines.headings[sourceH2Index]
                            .outlineH3s[sourceH3Index].id,
                    });

                    const outlineH3 = new Outline({
                        tag: Outline.tags.h3,
                        data: stepsData.outlines.headings[sourceH2Index]
                            .outlineH3s[sourceH3Index].data,
                        id: v4(),
                        type: Outline.type.heading,
                        para: stepsData.outlines.headings[sourceH2Index]
                            .outlineH3s[sourceH3Index].para,
                    });

                    dispatchStepsData({
                        type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                        field: Outline.type.heading,
                        h2Index: destinationH2Index,
                        index: destinationH3Index,
                        data: outlineH3,
                        h2_id: destinationH2Id,
                    });
                }
            }
        }
    };

    const generateParagraphsParent = async () => {
        if (stepsData.ranks?.length > 0) {
            generateParagraphs();
        } else {
            let organicComp = [];

            const filteredComp = competetion.filter(
                (ele) => ele.word_count >= 200 && ele.word_count <= 3500,
            );
            if (filteredComp.length) {
                const finalComp = filteredComp.filter(
                    (ele) => ele.htags_count >= 3 && ele.htags_count <= 100,
                );
                if (finalComp.length) {
                    organicComp = finalComp
                        .sort((a, b) => b.grade - a.grade)
                        .slice(
                            0,
                            auth.segment == 3 ||
                                auth.segment == 4 ||
                                auth.segment == 5 ||
                                auth.segment == 6
                                ? 2
                                : 3,
                        )
                        .map((i) => i.rank);
                } else {
                    organicComp = filteredComp
                        .sort((a, b) => b.grade - a.grade)
                        .slice(
                            0,
                            auth.segment == 3 ||
                                auth.segment == 4 ||
                                auth.segment == 5 ||
                                auth.segment == 6
                                ? 2
                                : 3,
                        )
                        .map((i) => i.rank);
                }
            } else {
                organicComp = competetion
                    .sort((a, b) => b.grade - a.grade)
                    .slice(
                        0,
                        auth.segment == 3 ||
                            auth.segment == 4 ||
                            auth.segment == 5 ||
                            auth.segment == 6
                            ? 2
                            : 3,
                    )
                    .map((i) => i.rank);
            }
            dispatchStepsData({
                type: CRUISE_TYPES.ORGANIC_COMPETITORS,
                ranks: organicComp,
            });
            const res = await postData({
                url: "/api/contentDraft/v2/update",
                payload: {
                    ranks: organicComp,
                    field: "paragraph",
                    report_id: reportInfo.report_id,
                },
            });
            if (res.status == 200) {
                generateParagraphs();
            }
        }
    };

    const generateParagraphs = useCallback(async () => {
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: true,
            text:
                auth.segment == 3 || auth.segment == 4 || auth.segment == 2
                    ? "Your personalized article will be ready in just 1-2 minutes."
                    : "",
        });
        if (auth.segment == 3 || auth.segment == 4) {
            mixpanelTrack("Segment 3 Loading", {
                action: "Start",
                timestamp: new Date().toLocaleTimeString(),
            });
            startTime = new Date();
        }
        const res = await getData({
            url: `/api/contentDraft/v2/generate-paragraphs-v3?report_id=${reportInfo.report_id}`,
        });
        if (res.status == 200) {
            if (
                (reportInfo?.is_premium ||
                    ((auth.segment == 5 || auth.segment == 6) &&
                        stepsData.aiContentDetection)) &&
                res.data?.meta_data
            ) {
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_LOADING,
                    state: false,
                });
            }
            if (
                (auth.segment == 2 ||
                    auth.segment == 3 ||
                    auth.segment == 4 ||
                    ((auth.segment == 5 || auth.segment == 6) &&
                        !stepsData.aiContentDetection)) &&
                !reportInfo?.is_premium
            ) {
                if (res.data?.meta_data)
                    dispatchStepsData({
                        type: CRUISE_TYPES.UPDATE_META,
                        meta: {
                            ...(typeof res.data.meta_data == "string"
                                ? JSON.parse(res.data?.meta_data)
                                : res.data?.meta_data),
                            fromAPI: true,
                        },
                    });
            } else if (
                !reportInfo?.is_premium &&
                auth.segment != 3 &&
                auth.segment != 4 &&
                auth.segment != 5 &&
                auth.segment != 6
            ) {
                //set introduction if empty
                if (
                    res?.data?.introConclusion &&
                    res.data?.introConclusion.hasOwnProperty("introduction") &&
                    !stepsDataRef.current.introduction?.length
                ) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                        set: true,
                        data: res.data?.introConclusion.introduction,
                    });
                }

                //setting intro image for premium cruise
                if (
                    res?.data?.introConclusion &&
                    res.data?.introConclusion.hasOwnProperty("featured_image")
                ) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.SET_FEATURED_IMAGE,
                        state: res.data?.introConclusion?.featured_image,
                    });
                }

                //setting all Headings
                if (res.data?.outlinesParagraphs?.length > 0)
                    res.data.outlinesParagraphs.map((item) => {
                        let index = -1;
                        let h2Index = -1;
                        if (item.tag == Outline.tags.question) {
                            const listQue =
                                stepsDataRef.current?.outlines?.questions;
                            index = listQue?.findIndex(
                                (que) => que.id == item.id,
                            );
                        } else {
                            const listHeadings =
                                stepsDataRef.current?.outlines?.headings;
                            if (item.tag == Outline.tags.h2) {
                                index = listHeadings?.findIndex(
                                    (h2) => h2.id == item.id,
                                );
                            } else {
                                for (let i = 0; i < listHeadings.length; i++) {
                                    for (
                                        let j = 0;
                                        j < listHeadings[i].outlineH3s.length;
                                        j++
                                    ) {
                                        if (
                                            listHeadings[i].outlineH3s[j].id ==
                                            item.id
                                        ) {
                                            h2Index = i;
                                            index = j;
                                            if (index >= 0)
                                                dispatchStepsData({
                                                    type: CRUISE_TYPES.SET_PARA,
                                                    index: index,
                                                    parentTag: item.tag,
                                                    para: item.para,
                                                    h2Index,
                                                });
                                        }
                                    }
                                }
                            }
                        }
                        if (index >= 0)
                            dispatchStepsData({
                                type: CRUISE_TYPES.SET_PARA,
                                index: index,
                                parentTag: item.tag,
                                para: item.para,
                                h2Index,
                            });
                    });
                //setting all Questions

                //set conclusion if empty
                if (
                    res?.data?.introConclusion &&
                    res.data?.introConclusion.hasOwnProperty("conclusion")
                ) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_CONCLUSION,
                        set: true,
                        data: res.data?.introConclusion.conclusion,
                    });
                    if (res.data?.introConclusion?.meta_data)
                        dispatchStepsData({
                            type: CRUISE_TYPES.UPDATE_META,
                            meta: {
                                ...(typeof res.data.introConclusion.meta_data ==
                                "string"
                                    ? JSON.parse(
                                          res.data?.introConclusion?.meta_data,
                                      )
                                    : res.data?.introConclusion?.meta_data),
                                fromAPI: true,
                            },
                        });
                }
                setTimeout(() => {
                    dispatchStepsData({
                        type: CRUISE_TYPES.SET_LOADING,
                        state: false,
                    });
                }, 1000);
            }
        }
    }, []);

    let firstPara = true;
    useEffect(() => {
        /** @param {Outline} outline */
        const successCallback = (outline) => {
            /**
             * @type {Array<Outline>}
             */
            if (firstPara && (auth.segment == 3 || auth.segment == 4)) {
                mixpanelTrack("Segment 3 Loading", {
                    action: "End",
                    timestamp: new Date().toLocaleTimeString(),
                });
                const difference = (new Date() - new Date(startTime)) / 1000;
                mixpanelTrack("Loading time", { "Time spent": difference });
                firstPara = false;
            }
            const list =
                outline?.tag == Outline?.tags?.question
                    ? stepsData.outlines?.questions
                    : stepsData.outlines?.headings;

            let index = -1;
            //for h3
            let h2Index = -1;

            if (outline?.tag == Outline.tags.h3) {
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < list[i].outlineH3s.length; j++) {
                        if (list[i].outlineH3s[j].id == outline?.id) {
                            h2Index = i;
                            index = j;

                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < list.length; i++) {
                    if (outline?.id == list[i].id) {
                        index = i;
                        break;
                    }
                }
            }

            if (index >= 0)
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_PARA,
                    index: index,
                    parentTag: outline?.tag,
                    para: outline?.para,
                    h2Index,
                });
        };
        const conclusionSuccess = (conclusion) => {
            if (conclusion.report_id == reportInfo.report_id) {
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_CONCLUSION,
                    set: true,
                    data: conclusion.conclusion,
                });
            }
        };

        const introSuccess = (intro) => {
            if (intro.report_id == reportInfo.report_id) {
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                    set: true,
                    data: intro.introduction,
                });
            }
        };

        const stopLoader = () => {
            dispatchStepsData({
                type: CRUISE_TYPES.SET_LOADING,
                state: false,
            });
        };

        const premiumCruiseSuccess = (data) => {
            if (data?.introConclusion?.report_id == reportInfo.report_id) {
                console.log(data, "incoming");
                if (
                    data?.introConclusion &&
                    data?.introConclusion.hasOwnProperty("introduction") &&
                    !stepsDataRef.current.introduction?.length
                ) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                        set: true,
                        data: data?.introConclusion?.introduction,
                    });
                }

                //setting intro image for premium cruise
                if (
                    data?.introConclusion &&
                    data?.introConclusion.hasOwnProperty("featured_image")
                ) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.SET_FEATURED_IMAGE,
                        state: data?.introConclusion?.featured_image,
                    });
                }

                //setting all Headings
                if (data?.outlinesParagraphs?.length > 0)
                    data?.outlinesParagraphs.map((item) => {
                        let index = -1;
                        let h2Index = -1;
                        if (item.tag == Outline.tags.question) {
                            const listQue =
                                stepsDataRef.current?.outlines?.questions;
                            index = listQue?.findIndex(
                                (que) => que.id == item.id,
                            );
                        } else {
                            const listHeadings =
                                stepsDataRef.current?.outlines?.headings;
                            if (item.tag == Outline.tags.h2) {
                                index = listHeadings?.findIndex(
                                    (h2) => h2.id == item.id,
                                );
                            } else {
                                for (let i = 0; i < listHeadings.length; i++) {
                                    for (
                                        let j = 0;
                                        j < listHeadings[i].outlineH3s.length;
                                        j++
                                    ) {
                                        if (
                                            listHeadings[i].outlineH3s[j].id ==
                                            item.id
                                        ) {
                                            h2Index = i;
                                            index = j;
                                            if (index >= 0)
                                                dispatchStepsData({
                                                    type: CRUISE_TYPES.SET_PARA,
                                                    index: index,
                                                    parentTag: item.tag,
                                                    para: item.para,
                                                    h2Index,
                                                });
                                        }
                                    }
                                }
                            }
                        }
                        if (index >= 0)
                            dispatchStepsData({
                                type: CRUISE_TYPES.SET_PARA,
                                index: index,
                                parentTag: item.tag,
                                para: item.para,
                                h2Index,
                            });
                    });
                //setting all Questions

                //set conclusion if empty
                if (
                    data?.introConclusion &&
                    data?.introConclusion.hasOwnProperty("conclusion")
                ) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_CONCLUSION,
                        set: true,
                        data: data?.introConclusion.conclusion,
                    });
                    if (data?.introConclusion?.meta_data)
                        dispatchStepsData({
                            type: CRUISE_TYPES.UPDATE_META,
                            meta: {
                                ...(typeof data.introConclusion.meta_data ==
                                "string"
                                    ? JSON.parse(
                                          data?.introConclusion?.meta_data,
                                      )
                                    : data?.introConclusion?.meta_data),
                                fromAPI: true,
                            },
                        });
                }
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_LOADING,
                    state: false,
                });
                console.log(stepsData, "income");
            }
        };

        const featuredImageSuccess = (data) => {
            if (data.report_id == reportInfo.report_id) {
                dispatchStepsData({
                    type: CRUISE_TYPES.SET_FEATURED_IMAGE,
                    state: data,
                });
            }
        };

        const attachSockets = () => {
            socket.on(socketEvents.PARA_SUCCESS, successCallback);
            socket.on(socketEvents.CONCLUSION_PARAGRAPH, conclusionSuccess);
            socket.on(socketEvents.INTRO_PARAGRAPH, introSuccess);
            socket.on(socketEvents.PARA_COMPLETED, stopLoader);
            socket.on(socketEvents.PREMIUM_CRUISE, premiumCruiseSuccess);
            socket.on(socketEvents.SEGMENT5, premiumCruiseSuccess);
            socket.on(socketEvents.SEGMENT6, premiumCruiseSuccess);
            // socket.on(socketEvents.ADVANCE_CRUISE, advanceCruiseSuccess);
            socket.on(socketEvents.FEATURED_IMAGE, featuredImageSuccess);
        };

        const removeSockets = () => {
            socket.off(socketEvents.PARA_SUCCESS, successCallback);
            socket.off(socketEvents.CONCLUSION_PARAGRAPH, conclusionSuccess);
            socket.off(socketEvents.INTRO_PARAGRAPH, introSuccess);
            socket.off(socketEvents.PARA_COMPLETED, stopLoader);
            socket.off(socketEvents.PREMIUM_CRUISE, premiumCruiseSuccess);
            socket.off(socketEvents.SEGMENT5, premiumCruiseSuccess);
            socket.off(socketEvents.SEGMENT6, premiumCruiseSuccess);
            // socket.off(socketEvents.ADVANCE_CRUISE, advanceCruiseSuccess);
            socket.off(socketEvents.FEATURED_IMAGE, featuredImageSuccess);
        };
        generateParagraphsParent();
        attachSockets();

        return () => {
            removeSockets();
        };
    }, []);

    const reGenerateParagraph = useCallback(
        async function (outline, isCustom, customInput) {
            const detectQues = (data) => {
                const str = (data || "").trim();
                if (str[str.length - 1] == "?") {
                    keys.useCase =
                        auth.segment == 3 ||
                        auth.segment == 4 ||
                        auth.segment == 5 ||
                        auth.segment == 6
                            ? 285
                            : 91;
                    keys.question = data;
                }
            };

            let keys = {
                report_id: reportInfo.report_id,
                keyword: reportInfo.title,
                outline: getOuline(),
            };

            let pCount = 2;

            const writingPoints = concatWritingPoints(outline?.wp, true);
            const title = stepsDataRef.current.title;
            keys.cruise_nlp_logit = true;
            keys.talkingPoints = writingPoints;
            console.log(keyTerms, "kkkkkkkkkkjjjjjjjjjjjjjj");
            keys.keywords = keyTerms?.map((i) => i.keyword).join(",");
            keys.title = title;
            keys.promptOutline = getOutlineConcatenation({
                headings: stepsDataRef.current.outlines.headings,
                outline,
            });
            if (outline?.tag == Outline.tags.h2) {
                keys.heading = outline?.data; //
                keys.useCase =
                    auth.segment == 3 ||
                    auth.segment == 4 ||
                    auth.segment == 5 ||
                    auth.segment == 6
                        ? 296
                        : 85;
                detectQues(keys.heading);
            } else if (outline?.tag == Outline.tags.h3) {
                keys.heading =
                    stepsDataRef.current.outlines.headings[
                        outline?.h2Index
                    ].data;
                keys.subHeading = outline?.data;
                keys.useCase =
                    auth.segment == 3 ||
                    auth.segment == 4 ||
                    auth.segment == 5 ||
                    auth.segment == 6
                        ? 297
                        : 86;
                detectQues(keys.subHeading);
            } else {
                keys.question = outline?.data || "";
                keys.useCase =
                    auth.segment == 3 ||
                    auth.segment == 4 ||
                    auth.segment == 5 ||
                    auth.segment == 6
                        ? 298
                        : 91;
            }

            mixpanelTrack(cruiseMixPanelEvents.regenerateOnFirstDraft, {
                type:
                    (keys.useCase == 91
                        ? Outline.tags.question
                        : outline?.tag) + " para",
            });

            let getSuggestedHeadingCount = suggestedHeadingCount(
                tempGuideLineData?.heading_count,
            );
            keys.suggestedWordCount = suggestedWordCount(
                tempGuideLineData?.word_count,
                getSuggestedHeadingCount,
            );

            if (isCustom) {
                keys = {
                    title: title,
                    customInstruction: customInput,
                    ...(outline?.tag == Outline.tags.h3
                        ? {
                              h3: outline?.data,
                              h2: stepsDataRef.current.outlines.headings[
                                  outline?.h2Index
                              ].data,
                          }
                        : { h2: outline?.data }),
                    text: outline?.para,
                };
            }

            const res = isCustom
                ? await postData({
                      url: "/api/ai-generate/regenerate/custom-instruction",
                      payload: keys,
                  })
                : await postData({ url: "/api/generate", payload: keys });

            if (res.status == 200) {
                if (res.data?.output?.length)
                    return res.data?.output[0]?.content;
            }
        },
        [keyTerms],
    );

    function concatWritingPoints(WritingPoints, isHypen = false) {
        if (!WritingPoints || WritingPoints.length == 0) return "";
        let idx = 1;
        let concatWP = "";
        if (isHypen) {
            for (let wp of WritingPoints) {
                if (typeof wp == "string" ? wp : wp.tp)
                    concatWP +=
                        "- " + (typeof wp == "string" ? wp : wp.tp) + "\n";
            }
        } else {
            for (let wp of WritingPoints) {
                if (typeof wp == "string" ? wp : wp.tp)
                    concatWP +=
                        idx++ +
                        ". " +
                        (typeof wp == "string" ? wp : wp.tp) +
                        "\n";
            }
        }
        concatWP = concatWP.trim();
        return concatWP;
    }

    function suggestedHeadingCount(headingsCount) {
        try {
            let suggestedCount;
            let a = headingsCount.split(" - ");
            suggestedCount = parseInt((parseInt(a[0]) + parseInt(a[1])) / 2);
            if (isNaN(suggestedCount) || typeof suggestedCount != "number")
                return CRUISE_MODE.DEFAULT_HEADING_COUNT;
            return suggestedCount;
        } catch (err) {
            console.log(err);
            return CRUISE_MODE.DEFAULT_HEADING_COUNT;
        }
    }

    function suggestedWordCount(wordCount, suggestedHeadingsCount) {
        try {
            if (wordCount?.endsWith("+")) {
                return getSuggestedWordCountFromMin(
                    wordCount,
                    suggestedHeadingsCount,
                );
            }
            let suggestedWordCount;
            let a = wordCount.split(" - ");
            let meanWordCount =
                parseInt((parseInt(a[0]) + parseInt(a[1])) / 2) -
                CRUISE_MODE.AVG_INTRO_CONCLUSION_WORD_COUNT;
            console.log(meanWordCount);
            if (isNaN(meanWordCount) || typeof meanWordCount != "number")
                return CRUISE_MODE.DEFAULT_WORD_COUNT;
            if (meanWordCount < 0) {
                return CRUISE_MODE.DEFAULT_WORD_COUNT;
            }
            suggestedWordCount = parseInt(
                meanWordCount / suggestedHeadingsCount,
            );
            return Math.max(suggestedWordCount, 80);
        } catch (err) {
            console.log(err);
            return CRUISE_MODE.DEFAULT_WORD_COUNT;
        }
    }

    function getSuggestedWordCountFromMin(wordCount, suggestedHeadingsCount) {
        let suggestedWordCount;
        let a = wordCount.split("+");
        let meanWordCount =
            parseInt(parseInt(a[0])) +
            200 -
            CRUISE_MODE.AVG_INTRO_CONCLUSION_WORD_COUNT;
        console.log(meanWordCount);
        if (isNaN(meanWordCount) || typeof meanWordCount != "number")
            return CRUISE_MODE.DEFAULT_WORD_COUNT;
        if (meanWordCount < 0) {
            return CRUISE_MODE.DEFAULT_WORD_COUNT;
        }
        suggestedWordCount = parseInt(meanWordCount / suggestedHeadingsCount);
        return Math.max(suggestedWordCount, 80);
    }

    function getOutlineConcatenation({
        headings = [],
        outline = {},
        index = -1,
    }) {
        let str = "";
        let h2Index = -1;
        let h3Index = -1;
        let arr = [];
        if (outline?.h2Index >= 0) {
            h2Index = outline?.h2Index;
            h3Index = outline?.index;
        } else {
            h2Index = outline?.index;
        }
        console.log("regenaesr ==================================");
        headings.forEach((h2, h2Idx) => {
            arr.push({
                tag: h2.tag,
                data: h2.data,
                para: h2.para,
                isRegen: h2Index == h2Idx && h3Index == -1 ? true : false,
                wp: h2.wp,
            });
            if (!!h2.outlineH3s.length) {
                h2.outlineH3s.forEach((h3, h3Idx) => {
                    arr.push({
                        tag: h3.tag,
                        data: h3.data,
                        para: h3.para,
                        wp: h3.wp,

                        isRegen:
                            h2Index == h2Idx && h3Index == h3Idx ? true : false,
                    });
                });
            }
        });

        let endIndex = arr.findIndex((item) => item.isRegen);
        console.log("createPromptOutline", createPromptOutline(endIndex, arr));
        return createPromptOutline(endIndex, arr);
    }

    const createPromptOutline = (index, outlines) => {
        let outlinesString = "";
        const X = 2;
        if (outlines[index]?.tag == "question") return outlinesString;
        for (let edx = 0; edx < outlines.length; edx++) {
            if (outlines[edx].tag != "question") {
                if ((index < X || edx >= index - X) && edx <= index)
                    outlinesString += `${outlines[edx].tag}: ${
                        outlines[edx].data
                    }\n${concatWritingPoints(outlines[edx].wp, true)}\n`;
                else
                    outlinesString += `${outlines[edx].tag}: ${outlines[edx].data}\n`;
            }
        }
        return outlinesString.trim();
    };

    useEffect(() => {
        if (isGenIntro) {
            setExpandIntroHistory(true);
        }
    }, [isGenIntro]);

    useEffect(() => {
        if (isGenConclu) {
            setExpandConHistory(true);
        }
    }, [isGenConclu]);

    async function regenerateConclusion(callback, isCustom, customInput) {
        const title = stepsDataRef.current.title;
        setParagraphsCon((ps) => [{ text: stepsData.conclusion }, ...ps]);
        setIsGenConclu(true);
        mixpanelTrack(cruiseMixPanelEvents.regenerateOnFirstDraft, {
            type: "conclusion",
        });
        if (callback && !isCustom) {
            const res = await callback(stepsData.conclusion);
            if (res)
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_CONCLUSION,
                    edit: true,
                    data: res,
                });
        } else {
            const res = isCustom
                ? await postData({
                      url: "/api/ai-generate/regenerate/custom-instruction",
                      payload: {
                          title: title,
                          customInstruction: customInput,

                          h2: "conclusion",
                          text: stepsData.conclusion,
                      },
                  })
                : await postData({
                      url: "/api/generate",
                      payload: {
                          title: stepsData.title,
                          context: stepsData.blog_context,
                          useCase: 90,
                          cruise_nlp_logit: true,
                          text: stepsData.conclusion,
                          cta: stepsData.cta,
                          report_id: reportInfo.report_id,
                          outline: getOuline(),
                      },
                  });

            if (res.status == 200) {
                if (res.data?.output?.length) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_CONCLUSION,
                        edit: true,
                        data: res.data?.output[0].content,
                    });
                } else {
                    setParagraphsCon((ps) => ps.slice(1, ps.length));
                }
            } else {
                setParagraphsCon((ps) => ps.slice(1, ps.length));
            }
        }

        setIsGenConclu(false);
    }

    async function regenerateIntro(callback, isCustom, customInput) {
        const title = stepsDataRef.current.title;
        setParagraphsIntro((ps) => [{ text: stepsData.introduction }, ...ps]);
        setIsGenIntro(true);
        mixpanelTrack(cruiseMixPanelEvents.regenerateOnFirstDraft, {
            type: "introduction",
        });
        if (callback && !isCustom) {
            const res = await callback(stepsData.introduction);
            if (res)
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                    edit: true,
                    data: res,
                });
        } else {
            const res = isCustom
                ? await postData({
                      url: "/api/ai-generate/regenerate/custom-instruction",
                      payload: {
                          title: title,
                          customInstruction: customInput,
                          h2: "introduction",
                          text: stepsData.introduction,
                      },
                  })
                : await postData({
                      url: "/api/generate",
                      payload: {
                          outline: getOuline(),
                          title: stepsData.title,
                          context: stepsData.blog_context,
                          useCase: 88,
                          cruise_nlp_logit: true,
                          text: "",
                          report_id: reportInfo.report_id,
                      },
                  });

            if (res.status == 200) {
                if (res.data?.output?.length) {
                    dispatchStepsData({
                        type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                        edit: true,
                        data: res.data?.output[0].content,
                    });
                } else {
                    setParagraphsIntro((ps) => ps.slice(1, ps.length));
                }
            } else {
                setParagraphsIntro((ps) => ps.slice(1, ps.length));
            }
        }

        setIsGenIntro(false);
    }

    function getOuline() {
        try {
            const arr = [];
            for (const h2 of stepsData.outlines.headings) {
                arr.push(h2);
                for (const h3 of h2.outlineH3s) {
                    arr.push(h3);
                }
            }

            let text = "";

            for (const outline of arr.slice(0, 7)) {
                text +=
                    outline?.tag == Outline.tags.h3
                        ? "H3: " + outline?.data + "\n"
                        : "H2: " + outline?.data + "\n";
            }

            if (arr.length > 14)
                for (const outline of arr.slice(arr.length - 7, arr.length)) {
                    text +=
                        outline?.tag == Outline.tags.h3
                            ? "H3: " + outline?.data + "\n"
                            : "H2: " + outline?.data + "\n";
                }
            else {
                for (const outline of arr.slice(7, arr.length)) {
                    text +=
                        outline?.tag == Outline.tags.h3
                            ? "H3: " + outline?.data + "\n"
                            : "H2: " + outline?.data + "\n";
                }
            }

            return text;
        } catch (err) {
            return "";
        }
    }

    const updateFeaturedImage = (isDelete = false, altText = "") => {
        postData({
            url: "/api/contentDraft/v2/update",
            payload: {
                featured_image: isDelete
                    ? {}
                    : {
                          url: stepsData.featured_image?.url,
                          altText: altText,
                      },
                field: "paragraph",
                report_id: reportInfo.report_id,
            },
        });
    };

    return (
        <div className={styles.mainContainer}>
            {!checkParas(stepsData) &&
            (reportInfo?.is_premium ||
                (auth.segment == 5 && stepsData.aiContentDetection) ||
                auth.segment == 6) ? (
                <div className={styles.premiumLoader}>
                    {/* <SVGIcon src={"/New UI/SVG/premiumLoader.svg"} /> */}
                    <div
                        style={{
                            maxWidth: "400px",
                            width: "100%",
                            height: "300px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            zoom: "0.3",
                        }}
                    >
                        <CircularProgressBar
                            progress={90}
                            startProgress={stepsData?.isCruiseReturn ? 75 : 0}
                            size={1}
                            total={1}
                            radialSeparators={false}
                            duration={50}
                            color="#014dc5"
                            bgColor="#eaecf7"
                        />
                    </div>
                    <span className={styles.waitText}>
                        {reportInfo?.is_premium
                            ? "Your personalized article will be ready in just 5-7 minutes."
                            : "Your personalized article will be ready in just 3-4 minutes."}
                    </span>
                    <p
                        style={{
                            maxWidth: "700px",
                            color: "#3F5575",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "34px",
                            marginBottom: "-2px",
                            marginTop: "35px",
                        }}
                    >
                        We'll notify you via email once it's completed.
                        <br />
                        You may go to the dashboard and continue with your work.
                    </p>

                    <Button
                        handler={() => goTo("DASHBOARD")}
                        style={{
                            marginTop: "20px",
                            fontWeight: "400",
                            borderRadius: "7px",
                            fontSize: "14px",
                        }}
                        width={170}
                        height={36}
                        text="Go to Dashboard"
                        Icon={() => <BsArrowRight />}
                    />
                    <p className={styles.didYouKnowText}>
                        {DID_YOU_KNOW[didYouKnowIndex]}
                    </p>
                </div>
            ) : (
                <div className={styles.mainRight} ref={mainRef}>
                    {/* <h1 className={styles.titleH1}>
      <span className={styles.title}>{title ? title : ""}</span>
    </h1> */}
                    {/* Introduction section Starts */}
                    <MetaWrapper
                        title={stepsDataRef.current.title}
                        loading={isLoader}
                    />
                    <ClickAwayListener
                        onClickAway={() => setExpandIntroHistory(false)}
                    >
                        <div
                            className={styles.introSection}
                            ref={introRef}
                            style={{
                                backgroundColor: expandIntroHistory
                                    ? "#F1F4FD"
                                    : "white",
                            }}
                        >
                            <div className={styles.sectionTop}>
                                <div className={styles.titleRow}>
                                    <span
                                        className={styles.icon}
                                        onClick={() =>
                                            setIsIntroCollapse(!isIntroCollapse)
                                        }
                                    >
                                        {/* {!isIntroCollapse ? <SVGIcon src={"/New UI/SVG/BiChevronDown.svg"} size={24} style={{ color: "#014dc5" }} /> : <SVGIcon src={"/New UI/SVG/BiChevronRight.svg"} size={24} style={{ color: "#014dc5" }} />} */}
                                    </span>
                                    <h1 className={styles.titleH1}>
                                        <span className={styles.title}>
                                            {stepsData.title
                                                ? stepsData.title
                                                : ""}
                                        </span>
                                    </h1>
                                </div>

                                <div className={styles.deleteIconWrap}>
                                    {stepsData.introduction.length ? (
                                        <div className={styles.paraWordCount}>
                                            {stepsData.introduction?.trim()
                                                ?.length > 0
                                                ? stepsData.introduction?.split(
                                                      " ",
                                                  ).length
                                                : "0"}{" "}
                                            Words
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {!reportInfo?.is_premium &&
                                        auth.segment != 3 &&
                                        auth.segment != 4 &&
                                        auth.segment != 5 &&
                                        auth.segment != 6 && (
                                            <RegenBtn
                                                loading={isGenIntro}
                                                regenMethod={regenerateIntro}
                                            />
                                        )}
                                    {paragraphsIntro?.length &&
                                    !expandIntroHistory ? (
                                        <div
                                            onClick={() =>
                                                setExpandIntroHistory(true)
                                            }
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={styles.regenHistoryIcon}
                                        >
                                            <SVGIcon
                                                color="darkgray"
                                                src="/New UI/SVG/regenrate Icon.svg"
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            {(reportInfo?.is_premium ||
                                auth.segment == 3 ||
                                auth.segment == 4 ||
                                auth.segment == 5 ||
                                auth.segment == 6) &&
                            stepsData.featured_image?.url ? (
                                <div className={styles.featuredImage}>
                                    <div style={{ position: "relative" }}>
                                        <div style={{ display: "flex" }}>
                                            <img
                                                src={
                                                    stepsData.featured_image
                                                        ?.url
                                                }
                                                alt={
                                                    stepsData.featured_image
                                                        ?.altText
                                                }
                                                className={
                                                    styles.featured_image
                                                }
                                            />
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={"Delete"}
                                            >
                                                <div
                                                    className={
                                                        styles.deleteImage
                                                    }
                                                >
                                                    <VscTrash
                                                        style={{
                                                            marginLeft: "5px",
                                                            cursor: "pointer",
                                                        }}
                                                        color="#ff6355eb"
                                                        onClick={() => {
                                                            dispatchStepsData({
                                                                type: CRUISE_TYPES.DELETE_FEATURED_IMAGE,
                                                            });
                                                            mixpanelTrack(
                                                                "Image delete",
                                                                {
                                                                    type: "featured image",
                                                                    location:
                                                                        "First Draft",
                                                                },
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </LightTooltip>
                                        </div>
                                        <div
                                            className={
                                                styles.featuredImageAltText
                                            }
                                        >
                                            {isAdding ? (
                                                <>
                                                    <input
                                                        value={altText}
                                                        id={"featuredAlt"}
                                                        placeholder={
                                                            "Type alt text..."
                                                        }
                                                        ref={inputAltRef}
                                                        className={
                                                            styles.inputAtlText
                                                        }
                                                        onChange={(e) =>
                                                            setAltText(
                                                                e.target.value,
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (
                                                                altText !=
                                                                stepsData
                                                                    .featured_image
                                                                    ?.altText
                                                            ) {
                                                                mixpanelTrack(
                                                                    "Alt Text Updated",
                                                                );
                                                            }
                                                            dispatchStepsData({
                                                                type: CRUISE_TYPES.SET_FEATURED_IMAGE,
                                                                state: {
                                                                    url: stepsData
                                                                        ?.featured_image
                                                                        ?.url,
                                                                    altText:
                                                                        altText,
                                                                },
                                                            });
                                                            updateFeaturedImage(
                                                                false,
                                                                altText,
                                                            );
                                                            setIsAdding(false);
                                                        }}
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key == "Enter"
                                                            ) {
                                                                setIsAdding(
                                                                    false,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {stepsData.featured_image
                                                        ?.altText?.length >
                                                        0 && (
                                                        <LightTooltip
                                                            arrow
                                                            title={
                                                                stepsData
                                                                    .featured_image
                                                                    ?.altText
                                                                    .length > 50
                                                                    ? stepsData
                                                                          .featured_image
                                                                          ?.altText
                                                                    : ""
                                                            }
                                                        >
                                                            <p
                                                                onClick={() => {
                                                                    setIsAdding(
                                                                        !isAdding,
                                                                    );
                                                                }}
                                                                className={
                                                                    styles.AtlTextMessage
                                                                }
                                                                style={{
                                                                    maxWidth: 300,
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    overflow:
                                                                        "hidden",
                                                                }}
                                                            >
                                                                {stepsData
                                                                    .featured_image
                                                                    ?.altText
                                                                    ? stepsData
                                                                          .featured_image
                                                                          ?.altText
                                                                          ?.length >
                                                                      47
                                                                        ? stepsData.featured_image?.altText?.slice(
                                                                              0,
                                                                              47,
                                                                          ) +
                                                                          "..."
                                                                        : stepsData
                                                                              .featured_image
                                                                              ?.altText
                                                                    : ""}
                                                            </p>
                                                        </LightTooltip>
                                                    )}
                                                    <span
                                                        onClick={() => {
                                                            setIsAdding(
                                                                !isAdding,
                                                            );
                                                        }}
                                                        className={
                                                            styles.AltTextButton
                                                        }
                                                    >
                                                        <SVGIcon
                                                            src={`/New UI/SVG/RegenIcons/6.svg`}
                                                            size={12}
                                                            color="#797979"
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {reportInfo?.is_premium ||
                            auth.segment == 3 ||
                            auth.segment == 4 ||
                            auth.segment == 5 ||
                            auth.segment == 6 ? (
                                <h2
                                    style={{
                                        fontSize: "0.85rem",
                                        fontWeight: 500,
                                        color: "black",
                                        marginLeft: "10px",
                                    }}
                                >
                                    Key Highlights
                                </h2>
                            ) : (
                                <></>
                            )}

                            <ParagraphBox
                                generateParagraph={() => {}}
                                setPara={(text) => {
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                                        edit: true,
                                        data: text,
                                    });
                                }}
                                text={stepsData.introduction}
                                tag={"intro"}
                                isShimmer={
                                    isGenIntro ||
                                    (isLoader &&
                                        !stepsData?.introduction?.length)
                                }
                            />

                            {expandIntroHistory && (
                                <ParagraphRegens
                                    arr={paragraphsIntro}
                                    setHeadingGenPara={(text, regenIndex) => {
                                        dispatchStepsData({
                                            type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                                            edit: true,
                                            data: text,
                                        });

                                        setParagraphsIntro((ps) => {
                                            if (stepsData?.introduction)
                                                ps[regenIndex].text =
                                                    stepsData?.introduction;
                                            else {
                                                ps.splice(regenIndex, 1);
                                            }

                                            return [...ps];
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </ClickAwayListener>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            type={droppableTypes.outlineH2}
                            droppableId={droppableIds.headings}
                        >
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {!isHeadingCollapse &&
                                        stepsData.outlines.headings.map(
                                            (item, i) => {
                                                return (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={String(
                                                            item.id,
                                                        )}
                                                        index={i}
                                                    >
                                                        {(provided, snap) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                style={{
                                                                    ...provided
                                                                        .draggableProps
                                                                        .style,
                                                                    border: snap.isDragging
                                                                        ? "2px solid var(--primary-blue)"
                                                                        : "2px solid white",
                                                                }}
                                                            >
                                                                <HeadingBox
                                                                    item={item}
                                                                    index={i}
                                                                    provided={
                                                                        provided
                                                                    }
                                                                    reGenerateParagraph={
                                                                        reGenerateParagraph
                                                                    }
                                                                    isLoader={
                                                                        isLoader
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            },
                                        )}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                        {/* Heading section Ends */}
                        {/* FAQ section Starts */}
                        {stepsData.outlines.questions?.length > 0 && (
                            <>
                                <div className={styles.titleRow}>
                                    <span
                                        className={styles.icon}
                                        onClick={() =>
                                            setIsFAQCollapse(!isFAQCollapse)
                                        }
                                    >
                                        {!isFAQCollapse ? (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/BiChevronDown.svg"
                                                }
                                                size={24}
                                                style={{ color: "#014dc5" }}
                                            />
                                        ) : (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/BiChevronRight.svg"
                                                }
                                                size={24}
                                                style={{ color: "#014dc5" }}
                                            />
                                        )}
                                    </span>
                                    <span className={styles.title}>
                                        Frequently Asked Questions
                                    </span>
                                </div>

                                <Droppable
                                    type={droppableTypes.outlineH2}
                                    droppableId={droppableIds.questions}
                                >
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {!isFAQCollapse &&
                                                stepsData.outlines.questions.map(
                                                    (item, i) => {
                                                        return (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={String(
                                                                    item.id,
                                                                )}
                                                                index={i}
                                                            >
                                                                {(
                                                                    provided,
                                                                    snap,
                                                                ) => (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        style={{
                                                                            ...provided
                                                                                .draggableProps
                                                                                .style,
                                                                            border: snap.isDragging
                                                                                ? "2px solid var(--primary-blue)"
                                                                                : "2px solid white",
                                                                        }}
                                                                    >
                                                                        <HeadingBox
                                                                            item={
                                                                                item
                                                                            }
                                                                            index={
                                                                                i
                                                                            }
                                                                            provided={
                                                                                provided
                                                                            }
                                                                            questions={
                                                                                true
                                                                            }
                                                                            reGenerateParagraph={
                                                                                reGenerateParagraph
                                                                            }
                                                                            isLoader={
                                                                                isLoader
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    },
                                                )}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </>
                        )}
                    </DragDropContext>
                    {/* FAQ section Ends */}
                    {/* Conclusion section Starts */}
                    {!reportInfo?.is_premium &&
                        auth.segment != 3 &&
                        auth.segment != 4 &&
                        auth.segment != 5 &&
                        auth.segment != 6 && (
                            <ClickAwayListener
                                onClickAway={() => setExpandConHistory(false)}
                            >
                                <div
                                    className={styles.conclusionSection}
                                    ref={concluRef}
                                    style={{
                                        backgroundColor: expandConHistory
                                            ? "#F1F4FD"
                                            : "white",
                                    }}
                                >
                                    <div className={styles.sectionTop}>
                                        <div className={styles.titleRow}>
                                            <span
                                                className={styles.icon}
                                                onClick={() =>
                                                    setIsIntroCollapse(
                                                        !isIntroCollapse,
                                                    )
                                                }
                                            >
                                                {!isIntroCollapse ? (
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/BiChevronDown.svg"
                                                        }
                                                        size={24}
                                                        style={{
                                                            color: "#014dc5",
                                                        }}
                                                    />
                                                ) : (
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/BiChevronRight.svg"
                                                        }
                                                        size={24}
                                                        style={{
                                                            color: "#014dc5",
                                                        }}
                                                    />
                                                )}
                                            </span>
                                            <span className={styles.title}>
                                                Conclusion
                                            </span>
                                        </div>
                                        {/*  */}
                                        <div className={styles.deleteIconWrap}>
                                            {stepsData.conclusion?.length ? (
                                                <div
                                                    className={
                                                        styles.paraWordCount
                                                    }
                                                >
                                                    {stepsData.conclusion?.trim()
                                                        ?.length > 0
                                                        ? stepsData.conclusion?.split(
                                                              " ",
                                                          ).length
                                                        : "0"}{" "}
                                                    Words
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <RegenBtn
                                                loading={isGenConclu}
                                                regenMethod={
                                                    regenerateConclusion
                                                }
                                            />

                                            {paragraphsCon?.length &&
                                            !expandConHistory ? (
                                                <div
                                                    onClick={() =>
                                                        setExpandConHistory(
                                                            true,
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    className={
                                                        styles.regenHistoryIcon
                                                    }
                                                >
                                                    <SVGIcon
                                                        color="darkgray"
                                                        src="/New UI/SVG/regenrate Icon.svg"
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    {!isIntroCollapse && (
                                        <ParagraphBox
                                            generateParagraph={() => {}}
                                            setPara={(text) => {
                                                dispatchStepsData({
                                                    type: CRUISE_TYPES.HANDLE_CONCLUSION,
                                                    edit: true,
                                                    data: text,
                                                });
                                            }}
                                            text={stepsData.conclusion}
                                            tag={"conclusion"}
                                            isShimmer={
                                                isGenConclu ||
                                                (isLoader &&
                                                    !stepsData?.conclusion
                                                        ?.length)
                                            }
                                        />
                                    )}
                                    {expandConHistory && (
                                        <ParagraphRegens
                                            arr={paragraphsCon}
                                            setHeadingGenPara={(
                                                text,
                                                regenIndex,
                                            ) => {
                                                dispatchStepsData({
                                                    type: CRUISE_TYPES.HANDLE_CONCLUSION,
                                                    edit: true,
                                                    data: text,
                                                });
                                                setParagraphsCon((ps) => {
                                                    if (stepsData?.conclusion)
                                                        ps[regenIndex].text =
                                                            stepsData?.conclusion;
                                                    else {
                                                        ps.splice(
                                                            regenIndex,
                                                            1,
                                                        );
                                                    }

                                                    return [...ps];
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </ClickAwayListener>
                        )}
                    {/* Conclusion section Ends */}
                </div>
            )}
        </div>
    );
};

const MetaWrapper = ({ loading, title }) => {
    const dispatchStepsData = useContext(CruiseContext);
    const state = useContext(CruiseState);

    // const [meta, setMeta] = useState();

    const setMeta = (meta) => {
        if (JSON.stringify(state.meta) != JSON.stringify(meta)) {
            dispatchStepsData({
                type: CRUISE_TYPES.UPDATE_META,
                meta,
            });
        }
    };

    return (
        <MetaData
            loading={loading}
            metaData={state.meta}
            setMetaData={setMeta}
            title={title}
            isCruise={true}
        />
    );
};

export const RegenBtn = ({ loading, regenMethod, expandHistory, tag }) => {
    const [open, setOpen] = useState(0);
    const { postData } = useHelperFunctions();
    const { reportInfo } = useContext(ReportContext);
    const [custom, setCustom] = useState(false);
    const [customInput, setCustomInput] = useState("");
    const { mpTrackRegenerationClicked } = useMixpanelHook();
    const { isLoader } = useContext(CruiseState);

    const regen = async (useCase, text) => {
        const res = await postData({
            url: "/api/generate",
            payload: {
                text: text,
                useCase: useCase,
                report_id: reportInfo.report_id,
                customInput,
            },
        });
        if (res.status == 200) {
            return res.data?.output[0]?.content;
        } else {
            return null;
        }
    };

    const optionHandler = (useCase, isCustom) =>
        regenMethod(
            (text) => {
                return regen(useCase, text);
            },
            isCustom,
            customInput,
        );

    const regenOpts = [
        { title: "Regenerate", handler: regenMethod, icon: "1", iconSize: 22 },
        {
            title: "Rephrase",
            handler: optionHandler,
            useCase: 225,
            icon: "2",
        },
        {
            title: "Expand",
            handler: optionHandler,
            useCase: 223,
            icon: "3",
        },
        {
            title: "Shorten",
            handler: optionHandler,
            useCase: 224,
            icon: "4",
        },
        {
            title: "Simplify",
            handler: optionHandler,
            useCase: tag == Outline.tags.h3 ? 254 : 253,
            icon: "5",
            iconSize: 22,
        },
        {
            title: "Bullet Points",
            handler: optionHandler,
            useCase: tag == Outline.tags.h3 ? 249 : 248,
            Icon: FaListUl,
        },
        {
            title: "Cruise AI",
            handler: () => {
                setCustom(true);
            },
            icon: "6",
            iconSize: 14,
        },
    ];

    const callCustomHandler = () => {
        setCustom(false);
        optionHandler(224, true);
    };

    useEffect(() => {
        if (!expandHistory) setOpen(false);
    }, [expandHistory]);

    useEffect(() => {
        if (open) setCustom(false);
    }, [open]);
    return !isLoader ? (
        <>
            <ClickAwayListener
                onClickAway={(e) => {
                    setOpen(false);
                    setCustom(false);
                }}
            >
                <div
                    onClick={(e) => expandHistory && e.stopPropagation()}
                    className={styles.regenBtnWrapper}
                >
                    {loading ? (
                        <button className={styles.regenBtn}>
                            <CircularProgress size={10} />
                        </button>
                    ) : (
                        <button
                            onClick={() => setOpen(!open)}
                            className={styles.regenBtn}
                        >
                            <SVGIcon src={"/New UI/SVG/ImLoop2.svg"} />

                            {!open ? (
                                <BiChevronDown size={20} />
                            ) : (
                                <BiChevronUp size={20} />
                            )}
                        </button>
                    )}
                    {open ? (
                        <div className={styles.regenOptions}>
                            {regenOpts.map((opt) => (
                                <button
                                    className={
                                        opt.title == "Cruise AI"
                                            ? styles.cruiseAIOption
                                            : ""
                                    }
                                    onClick={() => {
                                        mpTrackRegenerationClicked({
                                            type: opt.title,
                                        });
                                        setOpen(false);
                                        opt.handler(opt.useCase);
                                    }}
                                >
                                    <div className={styles.iconWrapper}>
                                        {opt.icon ? (
                                            <SVGIcon
                                                src={`/New UI/SVG/RegenIcons/${opt.icon}.svg`}
                                                size={opt.iconSize || 16}
                                            />
                                        ) : (
                                            <opt.Icon size={16} />
                                        )}
                                    </div>
                                    &nbsp;&nbsp;
                                    {opt.title}
                                </button>
                            ))}
                        </div>
                    ) : (
                        ""
                    )}
                    {custom && (
                        <div className={styles.customDialouge}>
                            <div className={styles.customInputWrapper}>
                                {/* <HiOutlinePencil /> */}
                                <SVGIcon
                                    src={`/New UI/SVG/RegenIcons/6.svg`}
                                    size={14}
                                />
                                <Textfield
                                    label=""
                                    placeholder="Enter Instructions"
                                    autoFocus
                                    onKeyPress={(e) =>
                                        e.code == "Enter"
                                            ? callCustomHandler()
                                            : ""
                                    }
                                    value={customInput}
                                    onChange={(e) =>
                                        setCustomInput(e.target.value)
                                    }
                                    containerStyle={{ marginBottom: 0 }}
                                />
                                <div
                                    style={{
                                        fontSize: 14,
                                        marginRight: 5,
                                        fontWeight: 300,
                                        cursor: "pointer",
                                    }}
                                >
                                    ↩
                                </div>

                                <VscClose
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setCustom(false);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        </>
    ) : (
        ""
    );
};

export default FirstDraft;
