import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import Label from "../Label/Label";
import styles from "./styles.module.css";

function Dropdown({
  options,
  width = "100px",
  label = "Dropdown",
  handler,
  disabled,
  normal = false,
  customOption,
}) {
  const [_options, setOptions] = useState(normal ? options : []);

  useEffect(() => {
    if (options.length > 0 && !normal) {
      const opt = [];
      for (const key in options) {
        opt.push({
          value: options[key].id,
          label:
            options[key].name +
            (options[key].active_count == undefined ||
            options[key].active_count == null
              ? ""
              : "  (" + options[key].active_count + ")"),
        });
      }
      setOptions(opt);
    } else {
      customOption
        ? setOptions(options.concat([customOption]))
        : setOptions(options);
    }
  }, [options?.length]);

  return (
    <div className={styles.container} style={{ width }}>
      {label && <Label label={label}></Label>}
      {_options.length > 0 && (
        <Select
          isDisabled={disabled}
          className={styles.containerInner}
          onChange={(e) => handler(e.value)}
          isSearchable={true}
          defaultValue={_options[0]}
          options={_options}
        ></Select>
      )}
    </div>
  );
}

export default Dropdown;
