import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles2 from "./NewDashboard.module.css";
import styles from "./GscLoginBar.module.css";
import Button from "../../atoms/Button/Button";
import { FcGoogle } from "react-icons/fc";
import { VscClose } from "react-icons/vsc";
import GoogleSearchConsole from "../GoogleSearchConsole/GoogleSearchConsole";
import Modal from "../../atoms/Modal/Modal";
import useHelperFunctions from "../../helperFunctions";
import useGscHook, {
    DomainObject,
    GscInsightsDataObject,
    SelectedDomain,
} from "../GoogleSearchConsole/useGscHook";
import { useDispatch, useSelector } from "react-redux";
import { displayAlert } from "../../../store/actions/alert";
import GscLoginFlow from "../GoogleSearchConsole/GscLoginFlow";
import { Skeleton } from "@material-ui/lab";
import SVGIcon from "../../atoms/SVGIcon";
import { BsChevronRight } from "react-icons/bs";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import {
    setUserGscInfo,
    setUserGscInfoMonthlyInsights,
} from "../../../store/actions/userGscInfo";
import LineChart from "../../atoms/Chart/LineChart";
import { ClickAwayListener } from "@material-ui/core";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { inflate } from "pako";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { useMixpanelTA } from "../GoogleSearchConsole/useMixpanelTA";
import mixpanel from "mixpanel-browser";
import { FaYoutube } from "react-icons/fa";
import VideoModal from "./VideoModal";
import { getTrailText } from "./constants";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import LmDashboardBar from "./LmDashboardBar";

const InsightStatCard = ({
    title = "Traffic",
    value = 0,
    change = 0,
    subText = "Month-on-month change",
    isShimmer = true,
    clickTrends,
    color = "",
    tooltipText = "",
}: {
    title: string;
    value?: number;
    change?: number;
    subText?: string;
    tooltipText?: string;
    isShimmer: boolean;
    clickTrends?: number[];
    color?: string;
}) => {
    const {
        getLineColor,
        trendsSD,
        getGradientValues,
        getTrendsLabel,
        getMappedValue,
    } = useGscHook();

    const [_trend, setTrend] = useState([] as Array<number>);

    useEffect(() => {
        if (!!clickTrends?.length) {
            let temp = [...clickTrends];
            temp.reverse();
            setTrend([...temp]);
        } else {
            setTrend([]);
        }
    }, [clickTrends]);

    return isShimmer ? (
        <div className={styles.statCard_Shimmer}>
            <Skeleton width={"30%"} height={"20px"} />
            <Skeleton width={"50%"} height={"30px"} />
        </div>
    ) : (
        <div className={styles.statCard}>
            <div className={styles.statCard_stat}>
                <LightTooltip placement={"top"} arrow title={tooltipText}>
                    <div className={styles.cardTitle}>{title}</div>
                </LightTooltip>
                <div className={styles.infoWrapper}>
                    <div className={styles.statRow}>
                        <div className={styles.statRow_value}>
                            {title?.toLowerCase() == "ctr"
                                ? Math.round(value * 100) / 100 + "%"
                                : (
                                      Math.round(value * 100) / 100
                                  )?.toLocaleString()}
                        </div>

                        {Math.abs(Math.round(change * 100) / 100) > 0 && (
                            <div
                                className={`${styles.statRow_change} ${
                                    change < 0 ? styles.dec : styles.inc
                                }`}
                            >
                                <SVGIcon
                                    src="/New UI/SVG/arrowUp.svg"
                                    size={11}
                                    style={
                                        change < 0
                                            ? { transform: "rotate(90deg)" }
                                            : {}
                                    }
                                />
                                {title?.toLowerCase() == "ctr"
                                    ? Math.abs(Math.round(change * 100) / 100) +
                                      ""
                                    : Math.abs(
                                          Math.round(change * 100) / 100,
                                      ).toLocaleString()}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {title?.toLowerCase() == "traffic" && _trend?.length > 0 && (
                <div className={styles.clicksTrendGraphWrapper}>
                    <div className={styles.graph}>
                        <LineChart
                            isReverseY={false}
                            data1={_trend}
                            label={new Array(_trend.length).fill(0)}
                            margin-left={"5px"}
                            dashedBorder={[4, 5]}
                            min={
                                Math.min(..._trend) == 0
                                    ? 0
                                    : Math.min(..._trend) > 100
                                    ? Math.min(..._trend) - 90
                                    : Math.min(..._trend)
                            }
                            max={trendsSD(_trend)}
                            noTick={true}
                            noLabels={true}
                            pointRadius={0}
                            noGrid={true}
                            noTooltip={true}
                            customTooltip={false}
                            tooltipLabel={""}
                            lineColor={getLineColor(getMappedValue(color))}
                            lineThickness={2}
                            gradientRange={50}
                            gradientValues={getGradientValues(
                                getMappedValue(color),
                            )}
                            gradientRange2={50}
                            gradientValues2={getGradientValues(
                                getMappedValue(color),
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const GscLoginBar = ({
    removeGscBar,
    setRemoveGscBar,
    isKp = false,
    showOnlyLoginBtn = false,
    setIsLmConnected,
    isLmConnected = true,
    setGscConnectStatus,
}: {
    setRemoveGscBar: Dispatch<SetStateAction<boolean>>;
    removeGscBar: boolean;
    isKp: boolean;
    showOnlyLoginBtn: boolean;
    isLmConnected: boolean;
    setIsLmConnected: Dispatch<SetStateAction<boolean>>;
    setGscConnectStatus: Dispatch<SetStateAction<boolean>>;
}) => {
    const dispatch = useDispatch();
    const { email, step, monthly_insights_info, showLoginBar, loggedOut } =
        useSelector((state: any) => state.userGscInfo);
    const { isTeamMember, planExpiresDate, planTrial } = useUserAccessHook();
    const { getTrimmedDomainURL } = useGscHook();
    const { mixpanelTrack } = useMixpanelHook();
    const [removeOptionShow, setRemoveOptionShow] = useState(false);
    const [gscLoginModal, setGscLoginModal] = useState(false);
    const [tempGscId, setTempGscId] = useState(-1);
    const [isReAuth, setIsReAuth] = useState(false);
    const [isDashDataLoading, setIsDashDataLoading] = useState(false);
    const [quotaExhaust, setQuotaExhaust] = useState(false);
    const [domains, setDomains] = useState<{ siteUrl: string }[] | []>([]);
    const [isTeamMemberAlert, setIsTeamMemberAlert] = useState(false);
    const [isShimmer, setIsShimmer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(2);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeDomain, setActiveDomain] = useState("");
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState<SelectedDomain>({
        siteUrl: "",
    });
    const goto = useNavigation();
    const { taTrackGscConnect } = useMixpanelTA();

    const [monthlyInsights, setMonthlyInsights] = useState<
        GscInsightsDataObject["monthly_insights_info"]
    >({});
    const { postData, getData } = useHelperFunctions();

    const getDomainList: (isSetDefault: boolean) => void = async (
        isSetDefault,
    ) => {
        return new Promise(async (res, rej) => {
            try {
                setInitialLoading(true);
                const res = await getData({
                    url: `/api/analyse/getDomainList`,
                });
                if (res.status == 200) {
                    const data = res.data.domainList || [];
                    if (data.length == 0) {
                        setIsLoggedIn(false);
                        dispatch(
                            displayAlert({
                                alertMessage:
                                    "No domains detected for this GSC account.",
                                alertType: "error",
                            }),
                        );
                        rej();
                        return;
                    }
                    setDomains(data.map((d: any) => ({ ...d, used: 0 })));
                    setTempGscId(res.data?.gsc_id || 0);
                    setTempGscId(res);
                    setInitialLoading(false);
                    res();
                    // if (data.length > 0)
                    //     setSelectedDomain((ps) => ({
                    //         ...ps,
                    //         siteUrl: data[0].siteUrl,
                    //         country: GSC_Countries[234].label,
                    //         flagCode: GSC_Countries[234].flagCode,
                    //         code: GSC_Countries[234].code,
                    //     }));
                } else {
                    setIsLoggedIn(false);
                    setDomains([]);
                }
            } catch (err) {
                setIsLoggedIn(false);

                rej();
            } finally {
                rej();
                setInitialLoading(false);
            }
        });
    };

    useEffect(() => {
        setIsShimmer(true);
        if (monthly_insights_info?.hasOwnProperty("clicks")) {
            setMonthlyInsights({
                ...monthly_insights_info,
                color: monthly_insights_info?.clicks_trend_classification,
            });
            setActiveDomain(monthly_insights_info?.activeDomain || "");
            setIsLoggedIn(true);
            setIsShimmer(false);
        } else if (step == 4) {
            getGscDataForDashboard();
        } else {
            setIsShimmer(false);
            setIsLoggedIn(false);
            getDetails();
        }
    }, [monthly_insights_info]);

    useEffect(() => {
        setGscConnectStatus(
            !!(isLoggedIn && !quotaExhaust && !showOnlyLoginBtn),
        );
    }, [isLoggedIn, quotaExhaust, showOnlyLoginBtn]);

    const handleRemoveGscBar = async (isPermanent = false) => {
        if (isPermanent) {
            setRemoveGscBar(true);
            const res = await postData({ url: "/api/user/updateShowGsc" });
        } else {
            setRemoveGscBar(true);
        }
    };

    const handleLogin: () => void = () => {
        setLoading(true);
        // @ts-ignore
        let gsc = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: responseGoogle,
            error_callback: () => {
                setLoading(false);
            },
        });
        gsc.requestCode();
    };

    const responseGoogle: (response: any) => void = async (response) => {
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                },
            });
            if (res.status == 200) {
                dispatch(
                    setUserGscInfo({
                        email: res.data?.resp?.userInfo?.email,
                    }),
                );
                setActiveStep(2);

                setGscLoginModal(true);
            } else {
                dispatch(
                    displayAlert({
                        alertMessage: `${res.response.data.message}`,
                        alertType: "error",
                        timeout: 5000,
                    }),
                );
            }
        }
        setLoading(false);
    };

    const getDetails = async () => {
        setIsShimmer(true);
        const res = await getData({ url: "/api/analyse/getGscDetails" });
        if (res.status == 200) {
            if (res.data?.resp?.gscInfo?.is_connected != 1) {
                // check whether google connected or not
                setIsLoggedIn(false);
                setIsShimmer(false);

                return;
            } else {
                dispatch(
                    setUserGscInfo({
                        email: res.data?.resp?.gscInfo?.email,
                    }),
                );
            }
            setActiveStep(res.data?.resp?.gscInfo?.step < 4 ? 2 : 1);
            if (res.data?.resp?.gscInfo?.step == 4) {
                setIsLoggedIn(true);
                if (res.data.resp?.domainInfo?.teamMember?.length > 0) {
                    getGscDataForDashboard(
                        res.data.resp?.domainInfo?.teamMember[0],
                    );
                } else {
                    getGscDataForDashboard();
                }

                return;
            } else {
                setIsLoggedIn(false);
                setIsShimmer(false);
            }
            if (res.data.resp?.gscInfo?.step == 2) {
                await getDomainList(true);
            }
            if (res.data.resp?.domainInfo?.domain_name) {
                setSelectedDomain({
                    siteUrl: res.data.resp?.domainInfo.domain_name,
                });
            } else {
                await getDomainList(true);
            }
        } else {
            if (res?.status == 205) {
                setIsReAuth(true);
            }
            setActiveStep(1);
            setIsLoggedIn(false);
            if (isTeamMember) {
                setIsTeamMemberAlert(true);
                dispatch(
                    setUserGscInfo({
                        showLoginBar: 0,
                    }),
                );
            }
            // err msg is required
        }
        setIsShimmer(false);
        setInitialLoading(false);
    };

    const handleLogoutGsc: () => void = async () => {
        const res = await postData({
            url: "/api/analyse/logoutGsc",
        });
        if (res.status == 200) {
        }
    };

    const { isMobile } = useSelector((state: any) => state?.state);

    const getGscDataForDashboard: (domain?: DomainObject) => void = async (
        domain,
    ) => {
        setGscLoginModal(false);
        setIsShimmer(true);
        setIsLoggedIn(true);
        setIsDashDataLoading(true);
        const res = await postData({
            url: "/api/analyse/dashboard-insights",
            payload: {
                ...(domain?.domain_name?.length && {
                    domain_name: domain?.domain_name,
                }),
            },
        });
        if (res.status == 200) {
            setActiveDomain(res.data?.domainInfo?.domain_name || "");
            if (res.data?.hasOwnProperty("quota")) {
                setQuotaExhaust(true);
                return;
            }
            if (res.data?.data?.data) {
                const buffer = res.data?.data?.data;
                const uint8Array = new Uint8Array(buffer);

                const inflatedData = inflate(uint8Array);

                const decompressedString = new TextDecoder().decode(
                    inflatedData as any,
                );

                const data = JSON.parse(decompressedString);
                console.log("getGscDataForDashboard", data);
                setMonthlyInsights({
                    ...data,
                    weeksDateList: res.data?.weeksDateList,
                    color: data?.clicks_trend_classification,
                    activeDomain: res.data?.domainInfo?.domain_name || "",
                });
                if (loggedOut == 0) {
                    // set only when use loggedOut ==0, if 1 shouldn't be set
                    dispatch(
                        setUserGscInfoMonthlyInsights({
                            ...data,
                            weeksDateList: res.data?.weeksDateList,
                            activeDomain:
                                res.data?.domainInfo?.domain_name || "",
                        }),
                    );
                }
            }
        } else {
            if (
                res?.response?.data?.message == "Domain list is empty" ||
                res?.response?.data?.message ==
                    "Domain is not Present in list" ||
                res?.response?.data?.message ==
                    "Insufficient Permission for Domain"
            ) {
                setIsLoggedIn(false);
                dispatch(
                    setUserGscInfo({
                        showLoginBar: 1,
                    }),
                );
            }
        }
        setIsShimmer(false);
        setIsDashDataLoading(false);
    };

    return (
        <div
            style={
                isLoggedIn ? { overflowX: "scroll" } : { overflowX: "visible" }
            }
            className={styles.scrollX}
        >
            {isLoggedIn && !quotaExhaust && !showOnlyLoginBtn ? (
                <div className={styles.trafficAnalyzerCardWrapper}>
                    <div style={{ position: "relative" }}>
                        <div className={styles.insightStats}>
                            <InsightStatCard
                                title="Traffic"
                                value={monthlyInsights?.clicks}
                                change={monthlyInsights?.delta_clicks}
                                isShimmer={isShimmer}
                                clickTrends={monthlyInsights?.clicks_trend}
                                color={monthlyInsights?.color}
                                tooltipText={
                                    "Web traffic refers to the number of clicks received from Google Search engine."
                                }
                            />
                            <InsightStatCard
                                title="Impressions"
                                value={monthlyInsights?.impressions}
                                change={monthlyInsights?.delta_impressions}
                                isShimmer={isShimmer}
                                tooltipText={
                                    "Impressions refer to the number of times a web page is displayed to users."
                                }
                            />
                            <InsightStatCard
                                title="Avg. Position"
                                value={monthlyInsights?.position}
                                change={monthlyInsights?.delta_position}
                                isShimmer={isShimmer}
                                tooltipText={
                                    "It is a website's weighted average ranking in search engine results pages (SERPs) for all pages and queries."
                                }
                            />
                            <InsightStatCard
                                title="CTR"
                                value={monthlyInsights?.ctr}
                                change={monthlyInsights?.delta_ctr}
                                isShimmer={isShimmer}
                                tooltipText={
                                    "It measures the number of clicks received in comparison to the number of people who viewed it."
                                }
                            />
                        </div>
                        {!isShimmer && (
                            <div
                                onClick={() => {
                                    mixpanel.track("TA See All", {
                                        source: "Dashboard (See All)",
                                    });
                                    goto("ANALYSIS_INSIGHTS", [`seeAll=${1}`]);
                                }}
                                className={styles.viewAllInsightsCta}
                            >
                                See all insights <BsChevronRight size={13} />
                            </div>
                        )}
                        {!isShimmer && !!activeDomain?.length && (
                            <LightTooltip
                                title={
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <img
                                            src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                activeDomain?.split(":")[1]
                                            }&sz=32`}
                                            width={"24px"}
                                        />
                                        {getTrimmedDomainURL(activeDomain)}
                                    </div>
                                }
                                arrow
                                placement={"top"}
                            >
                                <div className={styles.activeDomainIcon}>
                                    <img
                                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                            activeDomain?.split(":")[1]
                                        }&sz=32`}
                                        width={"24px"}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                    </div>
                    <LmDashboardBar
                        gscConnectStatus={true}
                        setIsLmConnected={setIsLmConnected}
                        isLmConnected={isLmConnected}
                    />
                </div>
            ) : !isLmConnected ? (
                <></>
            ) : showLoginBar == 1 && !isTeamMemberAlert && !isShimmer ? (
                <>
                    {showOnlyLoginBtn ? (
                        <div
                            style={{
                                boxShadow: "none",
                                background: "none",
                                justifyContent: "center",
                            }}
                            className={styles2.connectWithGSCWrapper}
                        >
                            <Button
                                loading={loading}
                                handler={() => {
                                    if (isReAuth) {
                                        taTrackGscConnect({
                                            screen: "Dashboard",
                                            isReAuth: "Yes",
                                        });
                                        goto("USER_PROFILE", [], "/domain");
                                    } else {
                                        taTrackGscConnect({
                                            screen: "Dashboard",
                                            isReAuth: "No",
                                        });
                                        if (activeStep == 1) {
                                            handleLogin();
                                        } else {
                                            setGscLoginModal(true);
                                        }
                                    }
                                }}
                                height={"44"}
                                Icon={() =>
                                    isReAuth ? (
                                        <></>
                                    ) : (
                                        <div className={styles2.iconWrapper}>
                                            <FcGoogle size={18} />
                                        </div>
                                    )
                                }
                                secondary={true}
                                text={
                                    isReAuth
                                        ? "Rconnect"
                                        : "Continue with Google"
                                }
                                iconReverse={true}
                                width={"100%"}
                                customClass={
                                    isReAuth
                                        ? styles2.reconnect
                                        : styles2.customGoogleLogin
                                }
                            />
                        </div>
                    ) : (
                        <div className={styles2.trafficAnalyzerCardWrapper}>
                            <div className={styles2.connectWithGSCWrapper}>
                                {isMobile ? (
                                    <div className={styles.mobileTACard}>
                                        <div className={styles.mobileTagline}>
                                            <h1>Traffic Analyzer</h1>
                                            <small>
                                                {isTeamMemberAlert
                                                    ? `Please reach out to your administrator to request access and ${
                                                          isReAuth
                                                              ? "reconnect"
                                                              : "connect"
                                                      } the domain for seamless SEO management.`
                                                    : isReAuth
                                                    ? "One or more of your domains has been disconnected. Reconnect now for seamless SEO management."
                                                    : "Connect your Google Search Console."}
                                            </small>
                                        </div>
                                        {!isTeamMemberAlert && (
                                            <Button
                                                loading={loading}
                                                handler={() => {
                                                    if (isReAuth) {
                                                        goto(
                                                            "USER_PROFILE",
                                                            [],
                                                            "/domain",
                                                        );
                                                    } else {
                                                        if (activeStep == 1) {
                                                            handleLogin();
                                                        } else {
                                                            setGscLoginModal(
                                                                true,
                                                            );
                                                        }
                                                    }
                                                }}
                                                height={"44"}
                                                Icon={() =>
                                                    isReAuth ? (
                                                        <></>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles2.iconWrapper
                                                            }
                                                        >
                                                            <FcGoogle
                                                                size={18}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                secondary={true}
                                                text={
                                                    isReAuth ? "Reconnect" : ""
                                                }
                                                iconReverse={true}
                                                width={"40px"}
                                                // customClass={styles2.customGoogleLogin}
                                                style={{
                                                    backgroundColor: isReAuth
                                                        ? "#1f4cbe !important"
                                                        : "rgba(1, 77, 197, 0.1)",
                                                    paddingLeft: "6px",
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        <div className={styles2.tagline}>
                                            {isTeamMemberAlert
                                                ? `Please reach out to your administrator to request access and ${
                                                      isReAuth
                                                          ? "reconnect"
                                                          : "connect"
                                                  } the domain for seamless SEO management.`
                                                : isReAuth
                                                ? "One or more of your domains has been disconnected. Reconnect now for seamless SEO management."
                                                : "Connect your Google Search Console for seamless SEO management."}
                                        </div>
                                        <div
                                            className={
                                                styles2.connectWithGSCWrapper_right
                                            }
                                        >
                                            {!isTeamMemberAlert && (
                                                <>
                                                    {
                                                        // @ts-ignore
                                                        <VideoModal
                                                            buttonForm={true}
                                                            link={
                                                                "https://www.youtube.com/embed/f6IBpMJrPCQ"
                                                            }
                                                            modal={true}
                                                            location="Traffic Analyzer Bar"
                                                            isDash={true}
                                                            featureId={4}
                                                        />
                                                    }
                                                    <Button
                                                        loading={loading}
                                                        handler={() => {
                                                            if (!isLoggedIn) {
                                                                goto(
                                                                    "ANALYSIS_INSIGHTS",
                                                                );
                                                                return;
                                                            }
                                                            if (isReAuth) {
                                                                if (
                                                                    getTrailText(
                                                                        planExpiresDate,
                                                                    ) == 7 &&
                                                                    planTrial
                                                                ) {
                                                                    mixpanelTrack(
                                                                        "Day 0 Dashboard",
                                                                        {
                                                                            action: `Traffic Analyzer Bar - Reconnect`,
                                                                        },
                                                                    );
                                                                }
                                                                taTrackGscConnect(
                                                                    {
                                                                        screen: "Dashboard",
                                                                        isReAuth:
                                                                            "Yes",
                                                                    },
                                                                );
                                                                goto(
                                                                    "USER_PROFILE",
                                                                    [],
                                                                    "/domain",
                                                                );
                                                            } else {
                                                                if (
                                                                    getTrailText(
                                                                        planExpiresDate,
                                                                    ) == 7 &&
                                                                    planTrial
                                                                ) {
                                                                    mixpanelTrack(
                                                                        "Day 0 Dashboard",
                                                                        {
                                                                            action: `Traffic Analyzer Bar - Continue with Google`,
                                                                        },
                                                                    );
                                                                }
                                                                taTrackGscConnect(
                                                                    {
                                                                        screen: "Dashboard",
                                                                        isReAuth:
                                                                            "No",
                                                                    },
                                                                );
                                                                if (
                                                                    activeStep ==
                                                                    1
                                                                ) {
                                                                    handleLogin();
                                                                } else {
                                                                    setGscLoginModal(
                                                                        true,
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        height={"44"}
                                                        Icon={() =>
                                                            isReAuth ? (
                                                                <></>
                                                            ) : (
                                                                <div
                                                                    className={
                                                                        styles2.iconWrapper
                                                                    }
                                                                >
                                                                    <FcGoogle
                                                                        size={
                                                                            18
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        secondary={true}
                                                        text={
                                                            isReAuth
                                                                ? "Rconnect"
                                                                : "Continue with Google"
                                                        }
                                                        iconReverse={true}
                                                        width={"100%"}
                                                        customClass={
                                                            isReAuth
                                                                ? styles2.reconnect
                                                                : styles2.customGoogleLogin
                                                        }
                                                    />
                                                </>
                                            )}
                                            <div
                                                className={
                                                    styles2.trafficAnalyzerCardClose
                                                }
                                            >
                                                <ClickAwayListener
                                                    onClickAway={() => {
                                                        setRemoveOptionShow(
                                                            false,
                                                        );
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            styles2.trafficAnalyzerCardCloseIcon
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                getTrailText(
                                                                    planExpiresDate,
                                                                ) == 7 &&
                                                                planTrial
                                                            ) {
                                                                mixpanelTrack(
                                                                    "Day 0 Dashboard",
                                                                    {
                                                                        action: `Traffic Analyzer Bar - Cross Icon`,
                                                                    },
                                                                );
                                                            }
                                                            setRemoveOptionShow(
                                                                !removeOptionShow,
                                                            );
                                                        }}
                                                    >
                                                        <VscClose size={20} />
                                                        {removeOptionShow && (
                                                            <div
                                                                className={
                                                                    styles2.removeOptionsWrapper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles2.removeOpt
                                                                    }
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        if (
                                                                            getTrailText(
                                                                                planExpiresDate,
                                                                            ) ==
                                                                                7 &&
                                                                            planTrial
                                                                        ) {
                                                                            mixpanelTrack(
                                                                                "Day 0 Dashboard",
                                                                                {
                                                                                    action: `Traffic Analyzer Bar - Remove For Now`,
                                                                                },
                                                                            );
                                                                        }
                                                                        mixpanel.track(
                                                                            "Remove GSC Bar",
                                                                            {
                                                                                Remove: "Temporary",
                                                                            },
                                                                        );
                                                                        handleRemoveGscBar();
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    Remove For
                                                                    Now
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles2.removeOpt
                                                                    }
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        if (
                                                                            getTrailText(
                                                                                planExpiresDate,
                                                                            ) ==
                                                                                7 &&
                                                                            planTrial
                                                                        ) {
                                                                            mixpanelTrack(
                                                                                "Day 0 Dashboard",
                                                                                {
                                                                                    action: `Traffic Analyzer Bar - Remove Permanently`,
                                                                                },
                                                                            );
                                                                        }
                                                                        mixpanel.track(
                                                                            "Remove GSC Bar",
                                                                            {
                                                                                Remove: "Permanent",
                                                                            },
                                                                        );
                                                                        handleRemoveGscBar(
                                                                            true,
                                                                        );
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    Remove
                                                                    Permanently
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </ClickAwayListener>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {gscLoginModal && (
                        <Modal
                            setModal={() => {
                                setGscLoginModal(!gscLoginModal);
                            }}
                        >
                            <div className={styles.gscLoginModal}>
                                <GscLoginFlow
                                    userInfo={{ email }}
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                    setSelectedDomain={setSelectedDomain}
                                    selectedDomain={selectedDomain}
                                    domains={domains}
                                    getDomainList={getDomainList}
                                    source={"dashboard"}
                                    getGscDataForDashboard={
                                        getGscDataForDashboard
                                    }
                                    setGscLoginModal={setGscLoginModal}
                                    setTempGscId={setTempGscId}
                                    tempGscId={tempGscId}
                                />
                            </div>
                        </Modal>
                    )}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default GscLoginBar;
