import React, { useState, useEffect } from "react";
import styles from "./NewDropDown.module.css";
import { RiArrowDownSLine } from "react-icons/ri";
function NewDropDown({
  list = [],
  sortType,
  setSortType,
  title,
  listIcon = [],
  state = null,
}) {
  const [sortValue, setsortValue] = useState(sortType);
  const [sortText, setsortText] = useState(listIcon[0]?.text);
  const [show, setShow] = useState(state ? state : false);
  console.log(sortType, "HELLOOOOOO OK BYE SOTING");

  useEffect(() => {
    if (state !== null) {
      if (state === true) {
        setShow(true);
      } else if (state === false) {
        setShow(false);
      }
    }
  }, [state]);

  useEffect(() => {
    setSortType(sortValue);
  }, [sortValue]);

  useEffect(() => {
    const toggler = () => {
      if (show) {
        setShow(false);
      }
      window.removeEventListener("click", toggler);
    };
    window.addEventListener("click", toggler);
    return () => {
      window.removeEventListener("click", toggler);
    };
  }, [show]);

  return (
    <div className={styles.dropDownContainer}>
      {/* <span className={styles.text}>{title ? title : "SORT:"}</span> */}

      {
        <div onClick={() => setShow(false)} className={styles.sortOptions}>
          {list.length > 0
            ? list.map((item) => (
                <div
                  onClick={() => {
                    setsortValue(item);
                    setShow(false);
                  }}
                  className={`${styles.sortItem} ${
                    item == sortValue ? styles.sourceSelected : ""
                  }`}
                  key={item}
                >
                  {item}
                </div>
              ))
            : listIcon.map((item) => (
                <div
                  onClick={() => {
                    setsortValue(item.value);
                    setsortText(item.text);
                    setShow(false);
                  }}
                  className={`${styles.sortItem} ${
                    item.value == sortValue ? styles.sourceSelected : ""
                  }`}
                >
                  {item.icon}
                  {/* {item.text} */}
                </div>
              ))}
        </div>
      }
    </div>
  );
}

export default NewDropDown;
