export const ASIDE_OPTIONS_ID = {
    DASHBOARD: "dashboard_option",

    SMART_EDITOR: "smart_editor_option",

    SEO_HUB: "seo_hub_option",

    ARCHIVE: "archive_option",

    MY_ACCOUNT: "my_account_option",

    KNOWLEDGE: "knowledge_option",

    TALENT: "talent_network",

    AI_OUTPUTS: "ai_outputs",

    EARN_CREDITS: "earn_credits",

    INTEGRATION_HUB: "integration_hub",
    ARTICLE_WRITER: "ARTICLE_WRITER",
    CLUSTER: "CLUSTER",
    OPTIMIZER: "OPTIMIZER",
    Traffic_Analyzer: "Traffic Analyzer",
    Link_Manager: "Link Manager",
    webtune: "webtune",
    AI_HUMANIZER: "ai-humanizer",
    SEO_SURGE: "seo_surge",
};

export const kFormatNumber = (num) => {
    if (num <= 999) {
        return num;
    } else {
        return (num / 1000).toFixed(0) + "k";
    }
};
