import React, { useContext, useEffect, useState } from "react";
import {
    Tab,
    Tabs,
    Box,
    Typography,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import Placeholder from "./Placeholder";
import PlaceholderOutlines from "./PlaceholderOutlines";
import PlaceholderFAQ from "./PlaceholderFAQ";
import styles from "./Placeholder.module.css";
import ReportContext from "../../Research/ReportContext";
import useHelperFunctions from "../../../helperFunctions";
import SVGIcon from "../../../atoms/SVGIcon";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { debounceWrapper } from "../../../../utility/helperFun";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { useRef } from "react";
import moment from "moment/moment";
import { cruiseMixPanelEvents } from "./cruiseConstant";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

const useStylesTab = makeStyles((theme) => ({
    root: {
        minHeight: 30,
        margin: "0 10px !important",
        fontSize: 14,
        textTransform: "none",
        padding: "5px 2px",
        color: "black",
        "min-width": "max-content",
        color: "#29394a",
        opacity: 1,

        "&:hover": {
            color: "#1f4cbe",
        },
        "& .MuiTab-wrapper": {
            flexDirection: "row-reverse",
            justifyContent: "center",
            gap: "4px",
            alignItems: "center",
        },
        "& .MuiTab-wrapper > *:first-child ": {
            marginBottom: "0px",
        },
    },
    icon: {
        color: "red",
    },
    selected: {
        fontWeight: 600,
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#1f4cbe",
        height: 4,
    },
    root: {
        borderBottom: "solid #d6d6d6 0.1px",
        minHeight: 38,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const ref = useRef();

    useEffect(() => {
        if (ref.current) ref.current.scrollTop = 0;
    }, [value]);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            ref={ref}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{
                margin: "15px",
                marginLeft: "10px",
                marginRight: "10px",
                flexGrow: 1,
                height: "50%",
                overflow: "auto",
            }}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function GeneratedContent({
    topRanked = [],
    aiGenerated = [],
    handleGenTitle = () => {},
    step,
    setAiGenerated = () => {},
    stepsData,
    isGenTitleLoaded = false,
    setIsGenTitleLoaded = () => {},
    titleSessionId = -1,
}) {
    const [value, setValue] = React.useState(0);
    const { mixpanelTrack, mpTrackGenerateMore } = useMixpanelHook();

    // const [generateCases, setGenerateCases] = useState([]);

    useEffect(() => {
        setValue(0);
    }, [step]);

    useEffect(() => {
        console.log("Toprank agye");
    }, [aiGenerated, topRanked]);

    const { reportInfo } = useContext(ReportContext);
    const { postData } = useHelperFunctions();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();
    const [search, setSearch] = useState("");
    const AiTabText = ["", "Titles", "Heading Ideas"];
    const TopRankTabText = ["", "Titles", "Outlines"];
    async function genMoreTitles() {
        const sDate = moment.now();

        setIsGenTitleLoaded(true);
        console.log("huhu title", stepsData.title);
        const payload = {
            keyword: stepsData?.title ? stepsData?.title : reportInfo.title,
            context: stepsData.blog_context,
            useCase: 87,
            end: "",
            session: "",
            report_id: reportInfo.report_id,
        };
        let oldOutputs = [...aiGenerated];
        const res = await postData({ url: "/api/generate", payload });
        if (res.status == 200) {
            setIsGenTitleLoaded(false);
            setAiGenerated(
                res.data.output.length
                    ? [...oldOutputs, ...res.data.output]
                    : [...oldOutputs],
            );
        } else {
            console.log("error", res);
            setIsGenTitleLoaded(false);
        }

        setIsGenTitleLoaded(false);
    }

    async function genMoreTitlesFacts() {
        const sDate = moment.now();
        setIsGenTitleLoaded(true);
        console.log("huhu title", stepsData.title);
        let payload = {
            blog_context: stepsData.blog_context,
            report_id: reportInfo.report_id,
            keyword: reportInfo.title,
        };
        if (titleSessionId != -1) {
            payload.session = titleSessionId;
        }
        let oldOutputs = [...aiGenerated];
        const res = await postData({
            url: "/api/contentDraft/generate-titles",
            payload,
        });
        if (res.status == 200) {
            setIsGenTitleLoaded(false);
            setAiGenerated(
                res.data.output.length
                    ? [...oldOutputs, ...res.data.output]
                    : [...oldOutputs],
            );
            const timeTaken = moment().diff(sDate, "seconds");
            mixpanelTrack(cruiseMixPanelEvents.generateMoreTitles, {
                timeTaken,
                generatedTitles: res?.data?.output?.length,
            });
        } else {
            setIsGenTitleLoaded(false);

            console.log("error", res);
        }

        setIsGenTitleLoaded(false);
    }

    return (
        <>
            <Tabs classes={classesTabs} value={value} onChange={handleChange}>
                <Tab
                    disableRipple={true}
                    classes={classesTab}
                    label={
                        <LightTooltip
                            arrow
                            title={
                                "Choose from top blog titles generated for you by our AI"
                            }
                        >
                            <div>AI Generated Titles</div>
                        </LightTooltip>
                    }
                />
                <Tab
                    disableRipple={true}
                    classes={classesTab}
                    label={
                        <LightTooltip
                            arrow
                            title={`Compare your blog title with those of your top ${reportInfo.competitors_count} competitors`}
                        >
                            <div>Top Ranked Titles</div>
                        </LightTooltip>
                    }
                />
            </Tabs>

            <TabPanel value={value} index={0}>
                <Placeholder
                    ctaText={"Add"}
                    texts={aiGenerated}
                    setTexts={setAiGenerated}
                    setDataText={handleGenTitle}
                />
                <div className={styles.generateMore}>
                    <button
                        className={styles.genMoreBtn}
                        onClick={() => {
                            mpTrackGenerateMore({
                                screen: "Title",
                                section: "Titles",
                            });
                            genMoreTitlesFacts();
                        }}
                    >
                        {isGenTitleLoaded ? (
                            <>
                                Generating <LoadingDots />
                            </>
                        ) : (
                            <>
                                <SVGIcon
                                    src={"/New UI/SVG/BsPlusLg.svg"}
                                    size={8}
                                />{" "}
                                Generate More
                            </>
                        )}
                    </button>
                </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Placeholder
                    ctaText={"Add"}
                    texts={topRanked}
                    setDataText={handleGenTitle}
                    key={1}
                />
            </TabPanel>
        </>
    );
}

export default GeneratedContent;
