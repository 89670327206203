import React, { useEffect, useState } from "react";
import style from "./Integration.module.css";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../atoms/SVGIcon";
import { FiExternalLink } from "react-icons/fi";

function AppCard({ data, setSelectedApp }) {
    const goTo = useNavigation();

    return (
        <>
            {data && (
                <div className={style.cardContainer}>
                    <div
                        onClick={() => setSelectedApp(data.app_id)}
                        className={style.appCard}
                    >
                        {data.app_linked ? (
                            <div className={style.connected}>Activated</div>
                        ) : (
                            <></>
                        )}
                        <img width={50} src={data.icon} />
                        <h3
                            className={style.appCardHeading}
                            style={{
                                marginTop: "8px",
                                fontWeight: "600",
                                fontSize: "20px",
                            }}
                        >
                            {data.name}
                        </h3>
                        <a>{data.developer}</a>
                        <p style={{ marginBottom: "0" }}>{data.description}</p>
                        {/* {data.app_categories &&
              data.app_categories.map((category) => (
                <p className={style.tags}>{category}</p>
              ))} */}
                    </div>
                </div>
            )}
        </>
    );
}

export default AppCard;
