import React from "react";
import styles from "./Table.module.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

function Table({
    onMouseEnter,
    heading,
    children,
    id,
    tableType = "",
    innerRef,
    className = "",
}) {
    return (
        <div
            ref={innerRef}
            onMouseEnter={onMouseEnter}
            className={
                (tableType == 1
                    ? styles.table1Container
                    : styles.tableContainer) +
                " " +
                className
            }
            style={{ margin: "0" }}
            id={id}
        >
            {heading && (
                <h2 className={styles.tableHeading}>
                    {heading}{" "}
                    <LightTooltip
                        placement={"right"}
                        title={
                            "A quick overview of what we feel is most important to understand the anatomy of search results of your main query. This table would give you an high level idea on how to plan a content piece like the length of an article, typical readability scores, quality scores as well as how in depth an article should be (average number of headings)."
                        }
                    >
                        <span>
                            <img src="/AssetsV2/info.svg" />
                        </span>
                    </LightTooltip>
                </h2>
            )}
            <table
                className={
                    styles.table + " " + (tableType == 1 ? styles.table1 : "")
                }
            >
                {children}
            </table>
        </div>
    );
}

export default React.forwardRef((props, ref) => (
    <Table innerRef={ref} {...props} />
));
