import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Label from "../Label/Label";
import WordCounter from "../WordCounter/WordCounter";
import { isMobile } from "../../../utility/helperFun";

function errorMessage(errMsg) {
    return <div className={styles.errMsg}>{errMsg}</div>;
}

function showIcon(Icon, type, passwordViewer, visible) {
    if (type == "password")
        return (
            <span onClick={passwordViewer} className={styles.rightIcon}>
                {visible ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
        );

    if (Icon) {
        return (
            <span className={styles.rightIcon}>
                <Icon />
            </span>
        );
    }
}

export function showCounter(
    focus,
    counter,
    _counter,
    maxLength,
    counterStyle = {},
) {
    if (counter)
        return (
            <span style={counterStyle} className={styles.counter}>
                {_counter}/{maxLength}
            </span>
        );
    // return <WordCounter maxLength={maxLength} length={_counter} />;
    else return null;
}

function TextArea({
    placeholder = "Type here",
    autoFocus = false,
    errMsg = null,
    validatorFun = () => {
        error: false;
    },
    Icon = null,
    type = "text",
    width = "100%",
    label = "Label",
    counter = false,
    maxLength,
    minLength = 0,
    rowsMin = 3,
    props,
    noItalics,
    showLabel = true,
    rowsMax,
    labelSize,
    index = -1,
    errList = [],
    errInField,
    containerStyle,
}) {
    const [visible, setVisible] = useState(false);
    const [focus, setFocus] = useState(false);
    const [_counter, setCounter] = useState(
        props?.value?.length || props?.defaultValue?.length || 0,
    );
    const inputRef = useRef();

    useEffect(() => {
        if (props?.value) {
            setCounter(props?.value?.length);
        }
    }, [props?.value]);

    useEffect(() => {
        console.log("aitemp", props);
        if (errInField && !isMobile()) {
            inputRef.current.focus();
        }
    }, [errInField]);

    function passwordViewer() {
        setVisible(!visible);
    }
    function getType(type) {
        if (type != "password") return type;
        else {
            return visible ? "text" : "password";
        }
    }

    return (
        <div style={{ ...containerStyle }} className={styles.container}>
            <div className={styles.labelContainer}>
                {showLabel && (
                    <Label
                        required={props?.required}
                        label={label}
                        className={styles.label}
                        size={labelSize}
                    ></Label>
                )}
                {showCounter(focus, counter, _counter, maxLength)}
            </div>

            <div style={{ width }} className={styles.inputContainer}>
                <TextareaAutosize
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    rowsMin={rowsMin}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    minLength={minLength}
                    rowsMax={rowsMax}
                    onInput={(e) => setCounter(e.target.value.length)}
                    type={getType(type, visible)}
                    placeholder={placeholder}
                    {...props}
                    className={noItalics && styles.noItalics}
                    style={{
                        borderColor: errMsg?.length ? "#ff0000" : "",
                        ...props.style,
                    }}
                    ref={inputRef}
                />
                {showIcon(Icon, type, passwordViewer, visible)}
            </div>

            {errorMessage(errMsg)}
        </div>
    );
}

export default TextArea;
