import axios from "axios";
import * as actionTypes from "../actiontypes";
import { url } from "../../utility/config";
import qs from "qs";
import { showLoginPage } from "../actions/auth";

export const forgotSuccess = () => {
  return {
    type: actionTypes.FORGOT_SUCCESS,
  };
};

export const forgotFail = () => {
  return {
    type: actionTypes.FORGOT_FAIL,
  };
};

export const resetFail = () => {
  return {
    type: actionTypes.RESET_FAIL,
  };
};

export const resetSuccess = () => {
  return {
    type: actionTypes.RESET_SUCCESS,
  };
};

export const clearMessage = () => {
  return {
    type: actionTypes.CLEAR_MESSAGE,
  };
};

export const progress = () => {
  return {
    type: actionTypes.PROGRESS,
  };
};

//Forgot

export const forgotPass = ({ email }) => (dispatch) => {
  dispatch(progress());
  axios({
    method: "post",
    url: url + "/api/forgotPassword",
    data: qs.stringify({
      email: email,
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  })
    //.post(`${url}/api/login`, signInData)
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch(forgotSuccess());
      }
    })
    .catch((error) => {
      if (error) {
        if (error && error.response && error.response.status === 400) {
          dispatch(forgotFail());
        } else {
          dispatch(forgotFail());
        }
      }
    });
};

// Reset password
export const resetPassword = ({ password, confirmPassword, token }) => (
  dispatch
) => {
  dispatch(progress());
  axios({
    method: "put",
    url: url + "/api/updatePasswordViaEmail",
    data: qs.stringify({
      password,
      confirmPassword,
      resetPasswordToken: token.resetPasswordToken,
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  })
    //.post(`${url}/api/login`, signInData)
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch(resetSuccess());
        dispatch(showLoginPage(data.data.user_info.email));
      }
    })
    .catch((error) => {
      if (error) {
        if (error && error.response && error.response.status === 400) {
          dispatch(resetFail());
        } else {
          dispatch(resetFail());
        }
      }
    });
};
