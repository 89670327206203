import { Chip, makeStyles, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";
import Spacer from "../../../atoms/Spacer/Spacer";
import Themes from "../../../molecules/Creator/Overview/Themes";
import Competetion from "../Competiton/Competetion";
import ExternalReferences from "../ExternalReferences/ExternalReferences";
import InputCard from "../InputCard/InputCard";
import OtherResources from "../OtherResources/OtherResources";
import PeopleQuestions from "../PeopleQuestions/PeopleQuestions";
import Questions from "../Questions/Questions";

import styles from "./ResearchForm.module.css";

export default function ResearchForm({
    competetion,
    loading,
    aiWordLimitSetting,
    setAiWordLimitSetting,
    reportInfo,
    writingState,
    setWritingState,

    writeWithAnimation,
}) {
    const [showAll, setShowAll] = useState(false);
    const list = competetion.sort((i, j) => (i.rank < j.rank ? -1 : 1));

    return (
        <>
            {loading ? (
                <div className={styles.skelContainer}>
                    <Skeleton variant="text" height={50} />
                    <Skeleton variant="text" height={70} />
                    <Skeleton variant="text" height={30} />
                    <Skeleton variant="text" height={70} />
                </div>
            ) : (
                <div className={styles.form}>
                    <>
                        {/* <div className={styles.flexy}>
              <h2 className={styles.bigSearchTerm}>ONLINE RESEARCH</h2>
              <div className={styles.pill}>
                {selectedKeyword}
                <div className={styles.circularCheck}>
                  <FiCheck color='black' />
                </div>
              </div>
            </div>
            <Spacer spacing={25} /> */}

                        <div className={styles.flexHead}></div>
                        <Competetion
                            list={list}
                            showAll={showAll}
                            reportInfo={reportInfo}
                            writingState={writingState}
                            setWritingState={setWritingState}
                            aiWordLimitSetting={aiWordLimitSetting}
                            setAiWordLimitSetting={setAiWordLimitSetting}
                            writeWithAnimation={writeWithAnimation}
                        />
                        {list.length > 10 && (
                            <div className={styles.flexHead}>
                                <h2></h2>
                                <button
                                    className={styles.viewMoreBtn}
                                    onClick={() => {
                                        setShowAll((prevValue) => !prevValue);
                                    }}
                                >
                                    View {showAll ? "less" : "more"}
                                </button>
                            </div>
                        )}

                        <Spacer spacing={25} />

                        <Spacer spacing={25} />

                        {/* <h2 className={styles.resultHeading}>External Links</h2>
            <ExternalReferences list={externalLinks} />
            <Spacer spacing={25} /> */}
                    </>
                </div>
            )}
        </>
    );
}
