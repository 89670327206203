import React from "react";
import styles from "./WordpressApp.module.css";

export default function SetTime({
    clickHandler,
    hours,
    setHours,
    minutes,
    setMinutes,
    period,
    setPeriod,
    scheduleTime,
    setScheduleTime,
}) {
    const handleHours = (e) => {
        setHours(e.target.validity.valid ? e.target.value : hours);
        let newTime = new Date(scheduleTime);
        if (period && e.target.value == 12) {
            newTime.setHours(0);
        } else {
            newTime.setHours(e.target.validity.valid ? e.target.value : hours);
        }
        console.log("rrrrrrrrrrrrrr", newTime);
        setScheduleTime(newTime);
    };
    const handleMinutes = (e) => {
        setMinutes(e.target.validity.valid ? e.target.value : minutes);
        let newTime = new Date(scheduleTime);
        newTime.setMinutes(e.target.validity.valid ? e.target.value : minutes);
        console.log("rrrrrrrrrrrrrr", newTime);
        setScheduleTime(newTime);
    };
    return (
        <>
            <div className={styles.selectTimeContainer}>
                <input
                    type="text"
                    pattern="^(1[0-2]|0?[0-9])"
                    onInput={(e) => handleHours(e)}
                    //   onChange={(e) => handleHours(e)}
                    value={hours}
                    className={styles.timeBox}
                />
                <div>:</div>
                <input
                    type="text"
                    pattern="([0-5]?[0-9])"
                    onInput={(e) => handleMinutes(e)}
                    //   onChange={(e) => handleMinutes(e)}
                    value={minutes}
                    className={styles.timeBox}
                />
                <div className={styles.period}>
                    <div
                        onClick={() => {
                            if (new Date(scheduleTime).getHours() >= 12) {
                                let newTime = new Date(scheduleTime);
                                newTime.setHours(
                                    new Date(scheduleTime).getHours() % 12,
                                );
                                setScheduleTime(newTime);
                            }
                            setPeriod(true);
                        }}
                        className={`${styles.am} ${
                            period ? styles.active : ""
                        }`}
                    >
                        AM
                    </div>
                    <div
                        onClick={() => {
                            if (new Date(scheduleTime).getHours() < 12) {
                                let newTime = new Date(scheduleTime);
                                newTime.setHours(
                                    new Date(scheduleTime).getHours() + 12,
                                );
                                setScheduleTime(newTime);
                            }
                            setPeriod(false);
                        }}
                        className={`${styles.am} ${
                            !period ? styles.active : ""
                        }`}
                    >
                        PM
                    </div>
                </div>
            </div>
            <p className={styles.resetTime} onClick={() => clickHandler()}>
                Reset to Immediate
            </p>
        </>
    );
}
