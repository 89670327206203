import {
    SET_CHAT_WIDGET,
    SET_IS_MOBILE,
    SET_CRUISE_MODE_POPUP,
} from "../actiontypes";

export const setChatWidget = (data) => {
    return {
        type: SET_CHAT_WIDGET,
        data,
    };
};
export const setIsMobile = (data) => {
    return {
        type: SET_IS_MOBILE,
        data,
    };
};

export const setCruiseModePopup = (data) => {
    return {
        type: SET_CRUISE_MODE_POPUP,
        data,
    };
};
