import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { isAppVersionOutdated } from "../../../utility/helperFun";
import Modal from "../../atoms/Modal/Modal";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { version as APP_VERSION } from "../../../../package.json";
import AppUpdateRequired from "../AppUpdateRequired/AppUpdateRequired";
import styles from "./updatePlatform.module.css";
import { CgCross } from "react-icons/cg";
import { Close } from "@material-ui/icons";
import Button from "../../atoms/Button/Button";

export default function UpdatePlatform() {
    const {
        update_type,
        latest_version,
        update_description,
        loading: configLoading,
        minimum_version: appVersionConfig,
    } = useSelector((state) => state.appConfig);

    const [needsSoftUpdate, setNeedsSoftUpdate] = useState(false);
    const [needsHardUpdate, setNeedsHardUpdate] = useState(false);

    useEffect(() => {
        if (!configLoading && appVersionConfig) {
            if (
                isAppVersionOutdated({
                    currVersion: APP_VERSION,
                    minVersion: appVersionConfig,
                })
            ) {
                setNeedsHardUpdate(true);
            } else if (
                isAppVersionOutdated({
                    currVersion: APP_VERSION,
                    minVersion: latest_version,
                })
            ) {
                setNeedsSoftUpdate(true);
            }
        }
    }, [configLoading, appVersionConfig, latest_version]);

    return (
        <>
            {needsHardUpdate ? (
                <Modal children={<AppUpdateRequired />} setModal={() => {}} />
            ) : needsSoftUpdate ? (
                <>
                    <SoftUpdateModal
                        update_type={update_type}
                        update_description={update_description}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export const SoftUpdateModal = ({
    update_description = "<p>Performance and stability improvements </p>",
    update_type = "BAR",
    onClose = () => {},
    preview = false,
}) => {
    const [modal, setModal] = useState(update_type == "BAR" ? false : true);

    useEffect(() => {
        if (!modal) {
            onClose();
        }
    }, [modal]);

    return (
        <>
            {modal ? (
                <Modal
                    customClass={styles.softUpdateContainerModal}
                    setModal={() => setModal(false)}
                >
                    <LightTooltip
                        arrow
                        placement="top"
                        title={preview ? "Preview" : ""}
                    >
                        <div className={styles.softUpdateContainer}>
                            <span
                                onClick={() => setModal(false)}
                                className={styles.softUpdateContainerCross}
                            >
                                <Close />
                            </span>

                            <img
                                style={{ width: "100%" }}
                                src="/New UI/Images/update.webp"
                                alt=""
                            />

                            <h3>A new version is available</h3>

                            <div
                                className={styles.updateDescription}
                                dangerouslySetInnerHTML={{
                                    __html: update_description,
                                }}
                            ></div>

                            <div className={styles.softUpdateContainerActions}>
                                <Button
                                    disabled={preview}
                                    width="50%"
                                    handler={() => window.location.reload()}
                                    text="Update"
                                />
                                <Button
                                    disabled={preview}
                                    handler={() => setModal(false)}
                                    style={{
                                        backgroundColor: "white",
                                        marginTop: 20,
                                        color: "lightgray",
                                    }}
                                    text="Remind me later"
                                    width="40%"
                                />
                            </div>
                        </div>
                    </LightTooltip>
                </Modal>
            ) : (
                <></>
            )}
            <div
                className={`updaterIconClass  ${
                    !modal && "updaterIconClassVisible"
                }`}
            >
                <LightTooltip arrow placement="bottom" title="Update Available">
                    <a onClick={() => window.location.reload()}>Refresh</a>
                </LightTooltip>
                &nbsp; to upgrade to the latest application version
            </div>
        </>
    );
};
