import React from "react";
import styles from "./styles.module.css";

function DocWords({ words }) {
  return (
    <div className={`${styles.insights}  ${styles.words}`}>
      <b>{words ? words : "0"}</b>
      <span>Words</span>
    </div>
  );
}

export default DocWords;
