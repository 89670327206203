import React from "react";
import styles from "./transactions.module.css";
import { useSelector } from "react-redux";
import { thousands_separators } from "../../../../utility/validateFunctions";
import { getCurrency } from "./TransactionListItem";

export default function MobileFixedBottom({
  userCurrency,
  balance,
  setDialog,
  userId,
}) {
  const role = useSelector((state) => state.auth.role);
  const currency = useSelector((state) => state.auth.currency);

  return (
    <div className={styles.mobileFixed}>
      <div>
        <span className={styles.mobileAvailableBalanceHeading}>
          AVAILABLE BALANCE
        </span>
        <p style={{ fontFamily: "Inter" }} className={styles.mobileAmountLeft}>
          {balance
            ? balance >= 0
              ? `${getCurrency(
                  role,
                  userCurrency,
                  currency,
                  userId
                )} ${thousands_separators(+balance)}`
              : `- ${getCurrency(
                  role,
                  userCurrency,
                  currency,
                  userId
                )}${thousands_separators(+Math.abs(balance))}`
            : `${getCurrency(role, userCurrency, currency, userId)} 0`}
        </p>
      </div>
      <button
        onClick={() => setDialog(true)}
        className={styles.rechargeButton + " " + styles.bottomFix}
      >
        Recharge Credits
      </button>
    </div>
  );
}
