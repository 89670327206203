import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import Button from "../../componentsV2/atoms/Button/Button";
import Textfield from "../../componentsV2/atoms/CustomTextfield/Textfield";
import Confirmation from "../../componentsV2/atoms/ConfirmationDialog/confirmation";
import Label from "../../componentsV2/atoms/Label/Label";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { displayAlert } from "../../store/actions/alert";
import styles from "./superAdmin.module.css";

function LtdWorks({ setActiveAction }) {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [coupon, setCoupon] = useState("");
  const [action_type, setActionType] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const { postData } = useHelperFunctions();
  const dispatch = useDispatch();

  const submit = async (e) => {
    e.preventDefault();

    if (coupon && email && action_type) setConfirm(true);
  };

  const sendRequest = async (closeDialog) => {
    const res = await postData({
      url: "/api/pricing/planUpdateSuperAdmin",
      payload: {
        coupon_code: coupon,
        userEmail: email,
        action_type,
      },
    });

    if (res.status == 200) {
      dispatch(
        displayAlert({
          alertMessage: "Subscription Updated",
          alertType: "success",
        })
      );
    }
    closeDialog();
  };

  const setAction = (e) => {
    setActionType(e.target.value);
  };

  return (
    <div className={styles.ltdContainer}>
      <span onClick={() => setActiveAction(null)}>{"< Back"}</span>
      <form onSubmit={submit}>
        <p>Select Action Type:</p> 
        <div>
          <input
            onClick={setAction}
            type="radio"
            id="css"
            name="action_type"
            value="2"
          />
           <label for="css">ADD COUPON</label>
        </div>
        <div>
          <input
            onClick={setAction}
            type="radio"
            id="html"
            name="action_type"
            value="1"
          />
           <label for="html">COUPON REFUND</label>
        </div>
        <div>
          <input
            onClick={setAction}
            type="radio"
            name="action_type"
            value="3"
          />
           <label>UPGRADE COUPON</label>
        </div>
        {
          <div className={styles.ltdDiv}>
            <AutoComplete
              email={email}
              setEmail={setEmail}
              setUserId={setUserId}
            />
          </div>
        }
        <div className={styles.ltdDiv}>
          <Label label="Coupon"></Label>
          <Textfield
            props={{
              onChange: (e) => setCoupon(e.target.value),
            }}
            label=""
          />
        </div>
        <Button text="Submit"></Button>
      </form>

      {confirm && (
        <Confirmation
          modalHeading={"Are you sure ?"}
          submit={false}
          handler={sendRequest}
          setDialog={setConfirm}
        ></Confirmation>
      )}
    </div>
  );
}

export default LtdWorks;
