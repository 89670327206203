import React, { useEffect, useState } from "react";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import styles from "./PromptDetails.module.css";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import { displayAlert } from "../../store/actions/alert";
import { BiCopy } from "react-icons/bi";
import Button from "../../componentsV2/atoms/Button/Button";
import _ from "lodash";
import { LightTooltip } from "../../componentsV2/atoms/Tooltip/tooltip";

function InputCell({
    value,
    field,
    useCase,
    setMsg,
    msg,
    getGeneratesVariants,
    copyRow,
}) {
    const [val, setVal] = useState(value);
    const { postData } = useHelperFunctions();
    const [isModal, setIsModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {}, [field]);

    async function handleChange(e) {
        const req = {
            field: field,
            value: val,
            useCase: useCase,
        };
        console.log("prompt", req);

        const res = await postData({
            url: "/api/generates/updateGeneratesVariants",
            payload: req,
        });

        if (res.status == 200) {
            console.log("message", res.data.message);
            dispatch(
                displayAlert({
                    alertMessage: res.data.message,
                    alertType: "success",
                    timeout: 2000,
                }),
            );

            setMsg([...msg, res.data.message]);
            setIsModal(false);
        }
        setIsModal(false);
    }

    function handleClose() {
        getGeneratesVariants();
        setIsModal(false);
    }

    return (
        <>
            <td className={styles.cellValue}>
                {field == "name" && (
                    <LightTooltip arrow title="Copy and Create">
                        <span className={styles.copyRow}>
                            {" "}
                            <BiCopy onClick={copyRow} />{" "}
                        </span>
                    </LightTooltip>
                )}
                <textarea
                    className={styles.inputField}
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={(e) => setIsModal(true)}
                />
            </td>
            {isModal && (
                <Modal
                    setModal={setIsModal}
                    handleOnClose={getGeneratesVariants}
                >
                    <div className={styles.modalContainer}>
                        <div className={styles.modalHead}>
                            <h3>Are you sure, you want to update </h3>
                            <h4>
                                <span>{field}</span>
                            </h4>
                        </div>
                        <div className={styles.modalBody}>
                            <button
                                className={styles.modalBtn}
                                onClick={() => handleChange()}
                            >
                                Update
                            </button>
                            <button
                                className={styles.modalBtn}
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

function DataRow({ rowData, setMsg, msg, getGeneratesVariants, copyRow }) {
    return (
        <>
            {Array.isArray(rowData) && rowData.length
                ? rowData.map((row) => {
                      const _copyRow = () => copyRow(row);
                      return (
                          <tr className={styles.row}>
                              {Object.entries(row).map(([key, value], i) => {
                                  console.log("prompt key", key);
                                  return (
                                      <InputCell
                                          value={value}
                                          field={key.toString()}
                                          useCase={row["useCase"]}
                                          setMsg={setMsg}
                                          msg={msg}
                                          getGeneratesVariants={
                                              getGeneratesVariants
                                          }
                                          copyRow={_copyRow}
                                      />
                                  );
                              })}
                          </tr>
                      );
                  })
                : "No Data"}
        </>
    );
}

const PromptDetails = ({ setActiveAction }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { getData } = useHelperFunctions();
    const [rowData, setRowData] = useState([]);
    const [resMsg, setResMsg] = useState([]);
    const [copiedRow, setCopiedRow] = useState(null);

    const copyRow = (row) => {
        setCopiedRow(row);
    };

    async function getGeneratesVariants() {
        const res = await getData({
            url: "/api/generates/getGeneratesVariants",
        });
        if (res.status == 200) {
            setRowData([]);
            setRowData(res.data?.generatesData);
        } else {
            setRowData([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {}, [rowData]);

    useEffect(() => {
        // const res = 'Hi';
        getGeneratesVariants();
    }, []);

    function Add(e) {
        const df = "new messgage";
        setResMsg([...resMsg, df]);
    }

    return (
        <div>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>

            {resMsg.length ? (
                <div className={styles.msgContainer}>
                    <ul className={styles.msgList}>
                        {resMsg &&
                            resMsg.map((msg) => {
                                return <li className={styles.msg}>{msg}</li>;
                            })}
                    </ul>
                </div>
            ) : (
                ""
            )}

            {isLoading && (
                <CircularProgress size={18} style={{ marginTop: "50px" }} />
            )}
            <div className={styles.tableContainer}>
                <table className={styles.dataTable}>
                    {
                        <tr className={styles.headRow}>
                            {Array.isArray(rowData)
                                ? rowData.length &&
                                  Object.keys(rowData[0]).map((item) => {
                                      return <th>{item}</th>;
                                  })
                                : ""}
                        </tr>
                    }
                    <tbody>
                        <DataRow
                            rowData={rowData}
                            setMsg={setResMsg}
                            msg={resMsg}
                            getGeneratesVariants={getGeneratesVariants}
                            copyRow={copyRow}
                        />
                    </tbody>
                </table>
            </div>
            {copiedRow && (
                <Modal setModal={() => setCopiedRow(null)}>
                    <CopiedRowComp
                        copiedRow={copiedRow}
                        setCopiedRow={setCopiedRow}
                        getGeneratesVariants={getGeneratesVariants}
                    />
                </Modal>
            )}
        </div>
    );
};

const CopiedRowComp = ({
    copiedRow = [],
    setCopiedRow,
    getGeneratesVariants,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const data = _.cloneDeep(copiedRow);

        delete data.updated_at;
        delete data.created_at;
        delete data.id;
        delete data.useCase;
        data.ability = "disable";

        setData(data);
    }, []);

    const { postData } = useHelperFunctions();

    const [changes, setChanges] = useState(0);
    const dispatch = useDispatch();

    function handleChange(value, key) {
        setChanges((ps) => ps + 1);

        setData((ps) => {
            const obj = { ...ps };

            obj[key] = value;

            return obj;
        });
    }

    async function createVariantReq(e) {
        if (changes == 0) {
            dispatch(
                displayAlert({
                    alertMessage: "No Changes are made!!!",
                    alertType: "error",
                    timeout: 3000,
                }),
            );
            setCopiedRow(null);
            return;
        }

        console.log(data);

        const res = await postData({
            url: "/api/superAdmin/addGenerateUseCase",
            payload: data,
        });

        if (res.status == 200) {
            console.log("message", res.data.message);
            dispatch(
                displayAlert({
                    alertMessage: res.data.message,
                    alertType: "success",
                    timeout: 2000,
                }),
            );
            setCopiedRow(null);
            getGeneratesVariants();
        }
    }
    return (
        <div className={styles.copiedRowContainer}>
            <h3> New Variant Row </h3>
            <div className={styles.copiedRowMainContainer}>
                <table>
                    <tbody>
                        {Object.entries(data).map(([key, value]) => {
                            return (
                                <tr>
                                    {" "}
                                    <td> {key} </td>{" "}
                                    <td>
                                        <TextareaAutosize
                                            defaultValue={value}
                                            onChange={(e) =>
                                                handleChange(
                                                    e.target.value,

                                                    key,
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    margin: 10,
                }}
            >
                <Button
                    width="48%"
                    handler={() => setCopiedRow(null)}
                    text="Close"
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                    }}
                ></Button>
                <Button
                    width="48%"
                    handler={() => createVariantReq()}
                    text="Save"
                ></Button>
            </div>
        </div>
    );
};

export default PromptDetails;
