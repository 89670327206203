import React from "react";
import SVGIcon from "../../atoms/SVGIcon";
import styles from "./styles.module.css";

function TableColumnHeading({
    sortBy = {},
    col,
    i,
    sortingBy = (colID = 0, ascending = false) => {},
    cruiseMode = false,
}) {
    return (
        <th
            onClick={() =>
                col.isSort &&
                sortingBy(i, sortBy.colID == i ? !sortBy.asc : true)
            }
            key={i}
            style={{
                width: cruiseMode ? "30px" : "",
            }}
        >
            <div
                className={`${styles.headCol}  ${
                    sortBy.colID == i ? styles.sortedBy : ""
                } `}
            >
                {!col.isMain && col.title}
                {col.isMain && (
                    <span className={styles.mainTitle}>{col.title}</span>
                )}
                {col.isSort && col.title && (
                    <div className={`${styles.sort}`}>
                        {sortBy.colID != i ? (
                            <SVGIcon size={14} src={"/New UI/SVG/sortA.svg"} />
                        ) : !sortBy.asc ? (
                            <SVGIcon
                                size={14}
                                src={"/New UI/SVG/sortD.svg"}
                                className={`${
                                    !sortBy.asc ? styles.sortedByArrow : ""
                                }`}
                            />
                        ) : (
                            <SVGIcon
                                size={14}
                                src={"/New UI/SVG/sortA.svg"}
                                className={`${
                                    sortBy.asc ? styles.sortedByArrow : ""
                                }`}
                            />
                        )}
                    </div>
                )}
            </div>
        </th>
    );
}

export default TableColumnHeading;
