import React, { useEffect, useState } from "react";
import styles from "./LoaderGoNuts.module.css";
import Modal from "../../../atoms/Modal/Modal";
import { useSelector } from "react-redux";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";

const loadingText = [
    "Crafting up-to-date content for your article",
    "Generating the most relevant featured image",
    "Fetching key highlights for your article",
    "Formulating FAQs to enhance your reader's experience",
    "Almost there!",
];
const loadingText2 = ["Crafting high quality content for your article"];

let interval = null;

const LoaderGoNuts = ({
    width = "400",
    height = "200",
    text = "",
    activeStep,
}) => {
    const auth = useSelector((state) => state.auth);
    const [advanceTextIndex, setAdvanceTextIndex] = useState(0);

    useEffect(() => {
        if (
            activeStep == 4 &&
            (auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6)
        ) {
            interval = setInterval(() => {
                if (advanceTextIndex < 4) {
                    setAdvanceTextIndex((ps) => (ps == 4 ? 4 : ps + 1));
                }
            }, 15000);
        }
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Modal
            props={{
                BackdropComponent: () => <></>,
            }}
            setModal={() => {}}
            customClass={styles.loaderContainerWrapper}
        >
            <div
                className={styles.loaderContainer}
                style={{
                    backgroundColor: text
                        ? "rgba(0, 0, 0, 0.5)"
                        : "rgba(0, 0, 0, 0.1)",
                }}
            >
                <lottie-player
                    src="https://assets1.lottiefiles.com/packages/lf20_enrpmvws.json"
                    background="transparent"
                    speed="1.5"
                    style={{ width: `${width}px`, height: `${height}px` }}
                    autoplay
                    loop
                ></lottie-player>

                {text ? (
                    <p className={styles.loaderContainer_text}>
                        {text}
                        {(auth.segment == 3 ||
                            auth.segment == 4 ||
                            auth.segment == 5 ||
                            auth.segment == 6 ||
                            auth.segment == 2) &&
                            activeStep == 4 && (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p style={{ marginTop: "10px" }}>
                                        {auth.segment == 3 ||
                                        auth.segment == 4 ||
                                        auth.segment == 5 ||
                                        auth.segment == 6
                                            ? loadingText[advanceTextIndex]
                                            : loadingText2[0]}
                                    </p>
                                    <LoadingDots dots={true} />
                                </div>
                            )}
                    </p>
                ) : (
                    <></>
                )}
            </div>
        </Modal>
    );
};

export default LoaderGoNuts;
