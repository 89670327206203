import React, { useEffect, useState } from "react";
import style from "./Integration.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import { Redirect, useParams } from "react-router-dom";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import useHelperFunctions from "../../helperFunctions";
import AppCard from "./AppCard";
import Button from "../../atoms/Button/Button";
import Modal from "../../atoms/Modal/Modal";
import { IoMdClose } from "react-icons/io";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import AppDescription from "./AppDescription";
import { Route } from "react-router-dom";
import NavHeader from "../../../components/account/NavHeader";

const SetAppFromRoute = ({ setSelectedApp, selectedApp }) => {
    const params = useParams();
    useEffect(() => {
        if (selectedApp) {
            setSelectedApp(params.appId);
        }
        return () => setSelectedApp(null);
    }, []);
    return <></>;
};

function IntegrationHub({ moreApps }) {
    const [appsData, setAppsData] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const goTo = useNavigation();
    const { getData, postData } = useHelperFunctions();
    const [requestModal, setRequestModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const params = useParams();

    async function getApps() {
        setLoading(true);
        const res = await getData({ url: "/api/integrations/apps" });
        setLoading(false);

        if (res.status == 200) {
            setAppsData(res.data);
        }
    }

    useEffect(() => {
        getApps();
    }, []);

    const Apps = (
        <div class={style.mainContainer}>
            <NavHeader title="App Integrations" />

            {loading ? (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div
                    style={{
                        marginTop: "0",
                        paddingBottom: "100px",
                    }}
                    className={
                        moreApps
                            ? style.cardContainerOuter1
                            : style.cardContainerOuter
                    }
                >
                    {appsData.length ? (
                        appsData.map((data) => (
                            <AppCard
                                setSelectedApp={(app_id) => {
                                    setSelectedApp(app_id);
                                    !moreApps &&
                                        goTo("INTEGRATION_HUB", "", app_id);
                                }}
                                key={data.app_id}
                                data={data}
                            />
                        ))
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                height: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {appsData.length > 0 && (
                        <div className={style.cardContainer}>
                            <div className={style.requestCard}>
                                <h2>Want some more Integrations?</h2>
                                <p>You can tell us!</p>
                                <Button
                                    handler={() => setRequestModal(true)}
                                    width="160px"
                                    text="Request New App"
                                    style={{
                                        backgroundColor: "#014DC5",
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            {requestModal && (
                <Modal setModal={setRequestModal}>
                    <RequestNewApp
                        postData={postData}
                        setRequestModal={setRequestModal}
                    />
                </Modal>
            )}
        </div>
    );

    return moreApps ? (
        <>
            {selectedApp ? (
                <AppDescription
                    getApps={getApps}
                    setSelectedApp={setSelectedApp}
                    selectedApp={selectedApp}
                    moreApps={moreApps}
                />
            ) : (
                Apps
            )}
        </>
    ) : (
        <>
            <Route exact path={"/admin/integration"}>
                {Apps}
            </Route>
            <Route exact path={"/admin/integration/:appId"}>
                <AppDescription
                    getApps={getApps}
                    setSelectedApp={setSelectedApp}
                    appId={selectedApp}
                />
            </Route>
        </>
    );
}

const RequestNewApp = ({ setRequestModal, postData }) => {
    const [appName, setAppName] = useState("");
    // const [website, setWebsite] = useState("");
    const [reason, setReason] = useState("");
    const [load, setLoad] = useState(false);
    const [requested, setRequested] = useState(false);

    async function requestNewApp() {
        setLoad(true);
        const res = await postData({
            method: "post",
            url: "/api/integrations/appRequest",
            payload: {
                app_name: appName,
                reason: reason,
                type: "App Integration",
            },
        });
        setLoad(false);
        if (res.status === 200) {
            setRequested(true);
            setAppName("");
            // setWebsite("");
            setReason("");
        }
    }
    return (
        <div
            style={{ alignItems: "center" }}
            className={
                requested ? style.requestSuccessModal : style.signupModal
            }
        >
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setRequestModal(false);
                }}
                className={style.modalClose}
            />
            {requested && (
                <div>
                    <SVGIcon
                        width={80}
                        height={80}
                        src={"/New UI/SVG/TickWithAura.svg"}
                        style={{ color: "#1DC198" }}
                    />
                    <p className={style.thankYouText}>
                        Thanks for the suggestion!
                    </p>
                    <p className={style.subText}>
                        We’re always fine-tuning our services to make the
                        experience better for you.
                    </p>
                </div>
            )}

            {!requested && (
                <div>
                    <div className={style.modalHeading}>Request New App</div>
                    <div style={{ textAlign: "left" }}>
                        <label className={style.mandatoryLabel}>App Name</label>
                        <Textfield
                            customLabelClass={style.label}
                            placeholder="Scalenut"
                            label=""
                            props={{
                                style: {
                                    // background: "#e1e4ed",
                                    borderRadius: "3px",
                                    border: "1px solid #E1E4ED",
                                },
                                value: appName,
                                onChange: (e) => {
                                    setAppName(e.target.value);
                                },
                                autoComplete: "off",
                                disabled: load,
                            }}
                        />
                        {/* <label className={style.mandatoryLabel}>App Website</label>
            <Textfield
              customLabelClass={style.label}
              placeholder="http://scalenut.com"
              label=""
              props={{
                style: {
                  // background: "#e1e4ed",
                  borderRadius: "3px",
                  border: "1px solid #E1E4ED",
                },
                value: website,
                onChange: (e) => {
                  setWebsite(e.target.value);
                },
                autoComplete: "off",
                disabled: load,
              }}
            /> */}
                        <label className={style.label}>
                            Please describe your usecase
                        </label>
                        <TextareaAutosize
                            placeholder="Type here..."
                            minRows={3}
                            className={style.textarea}
                            style={{ height: "80px" }}
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                        />
                        {/* <Textfield
              containerStyle={{ height: "100px" }}
              placeholder=""
              label="Reason"
              props={{
                style: {
                  background: "#e1e4ed",
                  borderRadius: "3px",
                  border: "1px solid #E1E4ED",
                  height: "80px",
                },
                value: reason,
                onChange: (e) => {
                  setReason(e.target.value);
                },
                autoComplete: "off",
                disabled: load,
              }}
            /> */}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            handler={() => {
                                requestNewApp();
                            }}
                            loading={load}
                            disabled={!appName}
                            style={{ marginTop: "1rem" }}
                            text={"Submit Request"}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default IntegrationHub;
