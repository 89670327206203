import React from "react";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import Modal from "../../atoms/Modal/Modal";
import styles from "./styles.module.css";

function ProTips({ setShowInfoModal }) {
    return (
        <Modal setModal={setShowInfoModal}>
            <div className={styles.infoContainer}>
                <CloseButton
                    style={{ right: 10, top: 10 }}
                    setClose={setShowInfoModal}
                />
                <div className={styles.infoMainHeading}>Pro Tips</div>
                <div className={styles.infoShortCutContainer}>
                    <div className={styles.infoItem}>
                        <div className={styles.infoHeadingContainer}>
                            <div className={styles.infoHeading1}>
                                Write with AI
                            </div>
                            <div className={styles.infoHeadingOperatingSystem}>
                                {navigator.userAgent.includes("Mac") ? (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            ⌘+ k
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            Ctrl+ k
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.infoItemText}>
                            Place your cursor where you want to complete text in
                            your Editor. It will read the characters behind your
                            cursor and start writing ahead.
                        </div>
                    </div>
                    {/* <div className={styles.infoItem}>
                        <div className={styles.infoHeadingContainer}>
                            <div className={styles.infoHeading1}>
                                Instruct with AI
                            </div>
                            <div className={styles.infoHeadingOperatingSystem}>
                                {navigator.userAgent.includes("Mac") ? (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            ⌘+ Enter
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            Ctrl+ Enter
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.infoItemText}>
                            Place your cursor where you want to give instruction
                            in your Editor. It will read the characters behind
                            your cursor and generate the results based on your
                            Instruction/Command.
                        </div>
                    </div> */}
                    {/* <div className={styles.infoItem}>
                        <div className={styles.infoHeadingContainer}>
                            <div className={styles.infoHeading1}>
                                Rewrite with AI
                            </div>
                            <div className={styles.infoHeadingOperatingSystem}>
                                {navigator.userAgent.includes("Mac") ? (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            ⌘+ Shift+ k
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            Ctrl+ Shift+ k
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.infoItemText}>
                            Use this shortcut to re-run the last AI operation.
                        </div>
                    </div> */}
                    <div className={styles.infoItem}>
                        <div className={styles.infoHeadingContainer}>
                            <div className={styles.infoHeading1}>
                                Shortcut for H-tags
                            </div>
                            <div className={styles.infoOperatingCmd}>
                                {navigator.userAgent.includes("Mac") ? (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            ⌘+ (1,2,3,4,5,6)
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            Ctrl+ (1,2,3,4,5,6)
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.infoItemText}>
                            Select the text and use keyboard shortcuts to change
                            the H-tag. E.g. Use{" "}
                            <span className={styles.infoItemCmd}>
                                {navigator.userAgent.includes("Mac")
                                    ? "⌘"
                                    : "Ctrl"}
                                +2
                            </span>{" "}
                            to make the selected text as H2.
                        </div>
                    </div>

                    <div className={styles.infoItem}>
                        <div className={styles.infoHeadingContainer}>
                            <div className={styles.infoHeading1}>
                                Shortcut for AI Templates
                            </div>
                            <div className={styles.infoOperatingCmd}>
                                {navigator.userAgent.includes("Mac") ? (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            ⌘+ (G)
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.infoOperatingCmd}>
                                        <div className={styles.infoItemCmd}>
                                            Ctrl+ (G)
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.infoItemText}>
                            Simply add the details and use keyboard shortcuts to
                            generate outputs.
                        </div>
                    </div>
                    <div className={styles.infoItem}>
                        <div className={styles.infoHeadingContainer}>
                            <div className={styles.infoHeading1}>
                                Scalenut Blinder
                            </div>
                            <div className={styles.infoOperatingCmd}>
                                <div className={styles.infoItemCmd}>###</div>
                            </div>
                        </div>
                        <div className={styles.infoItemText}>
                            Limit Scalenut's ability to look back beyond a
                            specified point in your content. Use this feature to
                            avoid repeated content and refresh Scalenut's
                            pattern.
                        </div>
                    </div>
                </div>

                <div className={styles.infoItem + " " + styles.noborderBottom}>
                    <div className={styles.infoHeadingContainer}>
                        <div className={styles.infoHeading2}>How to Guides</div>
                    </div>
                    <div className={styles.infoItemText}>
                        Learn how to use Scalenut's Editor in the best possible
                        way to get desired results
                        <a
                            href={
                                "https://help.scalenut.com/category/seo-hub-editor/"
                            }
                            target="_blank"
                            className={styles.highlightLink}
                        >
                            (here)
                        </a>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ProTips;
