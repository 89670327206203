import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./styles.module.css";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Button from "../../../atoms/Button/Button";
import CountUp from "react-countup";
import {
    AiFillCaretRight,
    AiOutlineCaretRight,
    AiOutlineRight,
} from "react-icons/ai";
import { getEdStatus } from "../../../molecules/Creator/Overview/overallRatings";
import HighLighter from "../../../atoms/HightLighter/HighLighter";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const App = React.memo(
    ({ barColor, list, selectedRow, catForGraph, width, top }) => {
        const labels = new Array(list.length);

        for (let i = 0; i < labels.length; i++) {
            labels[i] = i + 1;
        }

        const colors = React.useMemo(() => {
            const arr = new Array(list.length).fill(barColor);
            arr.fill("#5fccf7", 0, top);
            // list.filter((item) => selectedRow.url_id == item.url_id);
            let x = null;

            for (let index = 0; index < list.length; index++) {
                if (selectedRow.url_id == list[index].url_id) {
                    x = index;
                    break;
                }
            }
            if (x != null) {
                arr[x] = "blue";
            }

            return arr;
        }, [selectedRow, list]);

        let arr;
        const _list = React.useMemo(() => {
            const sortedByRank = list.sort((x, y) => x.rank - y.rank);
            arr = sortedByRank;
            switch (catForGraph) {
                case 1:
                    return sortedByRank.map((item) => item.grade);
                case 2:
                    return sortedByRank.map((item) => item.readability);
                case 3:
                    return sortedByRank.map((item) => item.word_count);
                case 4:
                    return sortedByRank.map((item) => item.no_of_images);
                case 5:
                    return sortedByRank.map((item) => item.htags_count);
            }
        }, [list, catForGraph]);

        const options = useMemo(
            () => ({
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        external: function (context) {
                            // Tooltip Element
                            let tooltipEl =
                                document.getElementById("reactchart-tooltip");
                            if (context.tooltip.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }
                            try {
                                const index =
                                    context?.tooltip?.dataPoints[0]?.dataIndex;
                                console.log(index);
                                console.log(arr);

                                ReactDOM.render(
                                    <CustomTooltip
                                        statsOverView={
                                            typeof index == "number"
                                                ? arr[index]
                                                : {}
                                        }
                                    />,
                                    tooltipEl,
                                );
                                const tooltipModel = context.tooltip;

                                const position =
                                    context.chart.canvas.getBoundingClientRect();

                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.position = "fixed";
                                tooltipEl.style.left =
                                    position.left +
                                    20 -
                                    window.pageXOffset +
                                    tooltipModel.caretX +
                                    "px";
                                tooltipEl.style.top =
                                    position.top +
                                    window.pageYOffset +
                                    tooltipModel.caretY +
                                    "px";

                                tooltipEl.style.padding =
                                    tooltipModel.padding +
                                    "px " +
                                    tooltipModel.padding +
                                    "px";
                                tooltipEl.style.pointerEvents = "none";
                            } catch (error) {
                                console.log(error);
                            }
                        },
                    },
                },
                scales: {
                    x: {
                        grid: { display: !false, color: "#e1e4ed7d" },
                        display: true,
                        title: {
                            display: !true,
                        },
                        ticks: {
                            color: "#acb3bf",
                        },
                    },
                    y: {
                        grid: { display: !false, color: "#e1e4ed7d" },
                        display: true,

                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                // if (catForGraph == 1) {
                                //     const arr = [
                                //         "A",
                                //         "B",
                                //         "C",
                                //         "D",
                                //         "E",
                                //         "F",
                                //         " ",
                                //     ];
                                //     return arr[6 - value];
                                if (catForGraph == 2) {
                                    console.log(ticks);
                                    const val = getEdStatus(value);
                                    return ticks
                                        .map((l) => l.label)
                                        .includes(val)
                                        ? ""
                                        : val;
                                } else {
                                    return value;
                                }
                            },
                            color: "#acb3bf",
                            // ...(catForGraph == 1
                            //     ? { suggestedMax: 6, suggestedMin: 0 }
                            //     : {}),
                        },
                    },
                },
                title: false,
            }),
            [list, catForGraph],
        );

        const data = {
            labels,
            datasets: [{ label: "abc", data: _list, backgroundColor: colors }],
        };

        console.log(data.datasets[0].data, "''''''''");

        return (
            width && (
                <Bar
                    height={215}
                    width={width}
                    options={options}
                    aria-hidden
                    data={data}
                />
            )
        );
    },
);

const RepotsGraph = ({
    top,
    setTop,
    list,
    overview,
    selectedRow,
    setGraphHeight,
}) => {
    const [catForGraph, setCatForGraph] = useState(1);
    const [width, setWidth] = useState(0);
    const ref = useRef();

    useEffect(() => {
        let observer;
        if (ref.current) {
            setWidth(ref.current.offsetWidth);

            observer = new ResizeObserver(() =>
                setGraphHeight(ref.current.offsetHeight),
            );

            observer.observe(ref.current);
        }
        return () => observer?.disconnect();
    }, [ref, top, list]);

    useEffect(() => {
        console.log("setCatForGraph", CategoryTiles[catForGraph]);
    }, [catForGraph]);

    const CategoryTiles = [
        {
            id: 1,
            title: "SEO Score",
        },
        // {
        //     id: 2,
        //     title: "Readability",
        // },
        {
            id: 3,
            title: "Word Count",
        },
        { id: 4, title: "Images" },
        { id: 5, title: "H-Tags" },
    ];

    const onStyle = {
        backgroundColor: "#1f4cbe",
        color: "white",
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.categoryContainerHeader}>
                    <p>Metric Trends</p>
                    <div className={styles.categoryContainer}>
                        {CategoryTiles &&
                            CategoryTiles.map((cat, index) => {
                                return (
                                    <>
                                        <Button
                                            handler={() =>
                                                setCatForGraph(cat.id)
                                            }
                                            props={{
                                                style: {
                                                    borderRadius: "inherit",
                                                    margin: "2px",
                                                },
                                            }}
                                            key={cat.id}
                                            style={top == 20 && onStyle}
                                            text={cat.title}
                                            customClass={
                                                styles.catTile +
                                                " " +
                                                (cat.id === catForGraph &&
                                                    styles.activeCat)
                                            }
                                        />
                                        {CategoryTiles.length - 1 != index && (
                                            <div className={styles.separator}>
                                                {" "}
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                    </div>
                </div>
                <div ref={ref} className={styles.graphContainer}>
                    <App
                        selectedRow={selectedRow}
                        width={width}
                        catForGraph={catForGraph}
                        list={list}
                        barColor={"#5fccf7"}
                        top={10}
                    />
                </div>
            </div>
        </>
    );
};

const CustomTooltip = ({ statsOverView }) => {
    const CategoryTiles = [
        {
            id: 1,
            title: "SEO Score",
            stats: <HighLighter quality={statsOverView.grade} />,
        },
        // {
        //     id: 2,
        //     title: "Readability",
        //     stats: getEdStatus(statsOverView.readability),
        // },
        {
            id: 3,
            title: "Word Count",
            stats: statsOverView.word_count,
        },
        { id: 4, title: "Images", stats: statsOverView.no_of_images },
        { id: 5, title: "H-Tags", stats: statsOverView.htags_count },
    ];

    return (
        <div className={styles.TooltipContainer}>
            {CategoryTiles &&
                CategoryTiles.map((cat, index) => {
                    return (
                        <div className={styles.catTile1} key={cat.id}>
                            <span className={styles.catTitle1}>
                                {cat.title}
                            </span>
                            <span className={styles.catStats}>{cat.stats}</span>
                        </div>
                    );
                })}
        </div>
    );
};

export default RepotsGraph;
