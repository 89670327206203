import React from "react";
import styles from "./styles.module.css";
import { AiFillWarning } from "react-icons/ai";

function NotOptimal({ title, issue = 1, isIcon = false }) {
    return (
        <div
            className={
                issue == 1 ? styles.notOptimalBar : styles.notOptimalBarMinor
            }
        >
            {isIcon && (
                <AiFillWarning
                    size={14}
                    color={issue == 1 ? "#d80a30ad" : "#f1a126"}
                />
            )}
            {title}
        </div>
    );
}

export default NotOptimal;
