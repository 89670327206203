import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../../../utility/config";
import EditorContext from "../../TextEditor/EditorContext";
import { SMART_EDITOR } from "../../TextEditor/editorConstants";

export default function useVersions(currPage, selectedVersion) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lists, setLists] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { setVersionData, docInfo } = useContext(EditorContext);

  const reportId = docInfo.reportId;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await axios({
          method: "get",
          url: `${url}/api/creator/versions?${
            docInfo.attachedTo == SMART_EDITOR ? "doc_id" : "report_id"
          }=${reportId}&page=${currPage}`,
        });

        setLists((prevLists) => [...prevLists, ...res.data.data]);
        setHasMore(res.data.data.length > 0);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(error);
      }
    };

    getData();
  }, [currPage]);

  useEffect(() => {
    const getData = async () => {
      setVersionData((ps) => ({
        ...ps,
        loading: true,
        current: false,
        html: JSON.stringify({ ops: [] }),
      }));
      try {
        const res = await axios({
          method: "get",
          url: `${url}/api/creator/versionData?${
            docInfo.attachedTo == SMART_EDITOR ? "doc_id" : "report_id"
          }=${reportId}&version=${selectedVersion.version}`,
        });

        setVersionData((ps) => ({
          ...ps,
          html: res.data.data.html_content,
          updated_at: selectedVersion.updated_at,
          loading: false,
        }));
      } catch (error) {
        setError(error);

        setVersionData((ps) => ({
          ...ps,
          html: JSON.stringify({ ops: [] }),
          updated_at: "",
          loading: false,
        }));
      }
    };

    if (selectedVersion.index != 0) getData();
    else {
      setVersionData({ current: true, html: "" });
    }
  }, [selectedVersion]);

  return { loading, error, lists, hasMore };
}
