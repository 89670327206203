import { CircularProgress } from '@material-ui/core';
import React from 'react';
import classes from './button.module.css';

const Button = ({
  handler,
  text,
  styles,
  icon,
  btnStyle,
  disabled,
  loading,
}) => {
  let clsName = classes.button;
  if (btnStyle === 'submit') {
    clsName += ' ' + classes.submit;
  }
  if (btnStyle === 'formSubmit') {
    clsName += ' ' + classes.formSubmit;
  }
  if (btnStyle === 'formSubmit1') {
    clsName += ' ' + classes.formSubmit1;
  }
  if (btnStyle === 'google') {
    clsName += ' ' + classes.google;
  }

  if (btnStyle === 'basic') {
    clsName += ' ' + classes.basic;
  }
  if (btnStyle === 'pay') {
    clsName += ' ' + classes.pay;
  }
  if (btnStyle === 'basicFillCancel') clsName += ' ' + classes.basicFillCancel;

  if (btnStyle === 'basicFill') {
    clsName += ' ' + classes.basicFill;
  }
  if (btnStyle === 'basicFillThin') {
    clsName += ' ' + classes.basicFillThin;
  }
  if (btnStyle === 'successButtonEmpty') {
    clsName += ' ' + classes.successButtonEmpty;
  }
  if (btnStyle === 'blueColorBtn') {
    clsName += ' ' + classes.blueColorBtn;
  }

  return (
    <button
      onClick={handler}
      style={{ ...styles }}
      className={`${
        disabled
          ? clsName + ' ' + classes.disable
          : clsName + ' ' + classes.enable
      }`}
      disabled={disabled}
    >
      {icon ? (
        <span className={classes.icon}>
          <img src='scalenut\sign-in-with-google.svg' alt=''></img>
        </span>
      ) : null}
      {loading ? (
        <CircularProgress
          style={{ height: '20px', width: '20px' }}
          color='white'
        />
      ) : (
        <span className={classes.buttonText}>{text}</span>
      )}
    </button>
  );
};
export default React.memo(Button);
