const callbacks = [];

export const userGuidingHelper = (userId, params) => {
    try {
        if (window?.userGuiding?.identify) {
            window?.userGuiding?.identify(userId, params);
        } else {
            callbacks.push(() => window?.userGuiding?.identify(userId, params));
        }
        window.userGuidingScript.onload = () => {
            callbacks.forEach((callback) => callback());
        };
    } catch (e) {
        console.log(e);
    }
};
