import React from "react";
import ReactDOM from "react-dom";
import { NavLink, useParams, useRouteMatch } from "react-router-dom";
import Button from "../../../atoms/Button/Button";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import styles from "./styles.module.css";
import screens from "../../../../screens.json";
import SVGIcon from "../../../atoms/SVGIcon";
import VideoModal from "../../../Pages/NewDashboard/VideoModal";
import { Tooltip } from "@material-ui/core";

function AsideOptions({ isShared }) {
    const goTo = useNavigation();
    const params = useParams();

    const route = useRouteMatch();

    function getUrl(urlFor) {
        if (urlFor == "report") {
            if (isShared) {
                return (
                    screens.SEO_REPORT_SHARED +
                    "/" +
                    params.id +
                    "/" +
                    params.access
                );
            }
            return screens.SEO_REPORT + "/" + params.id;
        } else {
            if (isShared) {
                return (
                    screens.SEO_EDITOR_SHARED +
                    "/" +
                    params.id +
                    "/" +
                    params.access
                );
            }
            return screens.SEO_EDITOR + "/" + params.id;
        }
    }

    return (
        <>
            <div className={styles.topHeader}></div>
            <div className={styles.options}>
                {
                    <NavLink
                        to={getUrl("report")}
                        activeClassName={styles.active}
                    >
                        <SVGIcon
                            color={"inherit"}
                            size={24}
                            style={{ paddingBottom: "4px" }}
                            src={"/New UI/SVG/content-brief.svg"}
                        />
                        Create <br />
                        Brief
                    </NavLink>
                }
                {
                    <NavLink
                        to={getUrl("editor")}
                        activeClassName={styles.active}
                    >
                        <SVGIcon
                            size={24}
                            color={"inherit"}
                            style={{ paddingBottom: "4px" }}
                            // props={{ fill: "#5b93ec" }}
                            src={"/New UI/SVG/create-content.svg"}
                        />
                        Create <br />
                        Content
                    </NavLink>
                }
            </div>
        </>
    );
}

export default ({ isShared, seoWithCruise }) =>
    !!document.getElementById("creatorOptions") &&
    ReactDOM.createPortal(
        <AsideOptions isShared={isShared} seoWithCruise={seoWithCruise} />,
        document.getElementById("creatorOptions"),
    );
