import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import {
    clearError,
    loginUser,
    validationError,
} from "../../../../store/actions/auth.js";
import { pwdValidator } from "../../../../utility/validateFunctions";
import ValidationError from "../../../../components/common/validationError";
import { emailValidator } from "../../../../utility/validateFunctions";
import { googleLogin } from "../../../../store/actions/auth";
import Button from "../../../atoms/Button/Button";
import TextField from "../../../atoms/CustomTextfield/Textfield";
import Spacer from "../../../atoms/Spacer/Spacer";
import styles from "../styles.module.css";
import useNavigation from "../../../atoms/GoToRoute/useNavigation.js";

function SignIn({ responseGoogle }) {
    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState("");
    const [emailValid, setEmailValid] = useState("");
    const email = useSelector((state) => state.auth.email);
    const [_email, setEmail] = useState(email);
    const [didUpdate, setDidUpdate] = useState(false);
    const dispatch = useDispatch();
    const _googleLogin = useSelector((state) => state.auth.googleLogin);
    const loading = useSelector((state) => state.auth.loading);
    const error = useSelector((state) => state.auth.error);
    const errorMessage = useSelector((state) => state.auth.errorMessage);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryEmail = urlParams.get("email");

    const queryContext = urlParams.get("context");
    const queryUtmSource = urlParams.get("utm_source");
    const queryUtmMedium = urlParams.get("utm_medium");
    const queryUtmCampaign = urlParams.get("utm_campaign");

    const goTo = useNavigation();
    if (queryEmail && !didUpdate) {
        setEmail(queryEmail);
        setDidUpdate(true);
    }

    useEffect(() => {
        dispatch(clearError());
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        const [isValid, errorMsg] = pwdValidator(password);
        const [isValidE, errorMsgE] = emailValidator(_email);
        if (isValid && isValidE) {
            const signInData = {
                email: _email,
                password: password,
            };
            dispatch(loginUser(signInData));
            setValidPassword("");
            setPassword("");
            setEmailValid("");
        } else {
            setValidPassword(errorMsg);
            setEmailValid(errorMsgE);
            dispatch(clearError());
            setPassword("");
        }
    };

    return (
        <>
            {error ? (
                <>
                    {" "}
                    <ValidationError msg={errorMessage} />{" "}
                    <Spacer spacing={15} />
                </>
            ) : null}

            <GoogleLogin
                clientId="466601860416-thij5f8b08j5nslathiom9t0fneoe8t4.apps.googleusercontent.com"
                render={(renderProps) => (
                    <Button
                        props={{
                            id: "google-sign-in-button",
                        }}
                        loading={loading && _googleLogin}
                        handler={renderProps.onClick}
                        disabled={renderProps.disabled}
                        height={44}
                        Icon={FcGoogle}
                        secondary={true}
                        text={"Login with Google"}
                        iconReverse={true}
                    />
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
            />

            <Spacer spacing={15} />
            <span className={styles.link1}>OR</span>
            <form id="sign_in_form" onSubmit={(e) => e.preventDefault()}>
                <TextField
                    props={{
                        value: _email,
                        onChange: (x) => setEmail(x.target.value),
                        name: "email",
                    }}
                    height={44}
                    placeholder={"Enter your email"}
                    label="Email"
                    errMsg={emailValid}
                    autoFocus={!_email && true}
                    type="email"
                />
                <Spacer spacing={10} />
                <TextField
                    props={{
                        value: password,
                        onChange: (x) => setPassword(x.target.value),
                    }}
                    type="password"
                    height={44}
                    label="Password"
                    placeholder={"Enter your password"}
                    errMsg={validPassword}
                    autoFocus={_email && true}
                />
                <div className={styles.forgotPassLink}>
                    <span
                        onClick={() => goTo("FORGOT")}
                        className={styles.link2}
                    >
                        Forgot your password?
                    </span>
                </div>
                <Spacer spacing={20} />

                <Button
                    props={{
                        id: "sign-in-button",
                    }}
                    loading={loading && !_googleLogin}
                    handler={handleLogin}
                    height={44}
                    text={"Login"}
                />

                <Spacer spacing={20} />
            </form>
        </>
    );
}

export default SignIn;
