import React from "react";
import { VscClose } from "react-icons/vsc";
import styles from "./NoActiveLMSubs.module.css";
import { useDispatch } from "react-redux";
import SVGIcon from "../../atoms/SVGIcon.tsx";
import { LightTooltip } from "../../atoms/Tooltip/tooltip.jsx";
import Button from "../../atoms/Button/Button";
import { userLogout } from "../../../store/actions/auth.js";
import useNavigation from "../../atoms/GoToRoute/useNavigation.js";
const NoActiveLMSubs = () => {
    const dispatch = useDispatch();
    const goTo = useNavigation();
    return (
        <div className={styles.noSubsModalWrapper}>
            <div className={styles.noSubsModal}>
                {/* <LightTooltip placement="right" arrow title="Logout">
                    <div
                        className={styles.closeCta}
                        onClick={() => {
                            goTo("DASHBOARD");
                        }}
                    >
                        <VscClose size={20} color="#6b6b6b" />
                    </div>
                </LightTooltip> */}
                <div className={styles.noSubsModalTitle}>Add-on Terminated</div>
                <SVGIcon src={"/New UI/SVG/warning_big.svg"} />
                <div className={styles.noSubsMeta}>
                    <div className={styles.noSubsMeta_line1}>
                        Your Addon has been terminated!
                    </div>
                    <div className={styles.noSubsMeta_line2}>
                        No Action is allowed at this time.
                    </div>
                </div>
                <div className={styles.noSubsBottom}>
                    <div className={styles.noSubsCtaTitle}>To Reactivate</div>
                    <Button
                        text={"Buy Add-on"}
                        height={34}
                        style={{
                            borderRadius: 20,
                            padding: "2px 14px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                        handler={(e) => {
                            e.preventDefault();
                            goTo("BILLING_PLANS", [], "/addon");
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default NoActiveLMSubs;
