import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./Integration.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import useHelperFunctions from "../../helperFunctions";
import { useParams } from "react-router-dom";
import Modal from "../../atoms/Modal/Modal";
import { IoMdClose } from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./carouselStyle.css";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { CircularProgress, debounce } from "@material-ui/core";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import { SeoWithCruiseModal } from "../NewDashboard/NewDashboard";
import { debounceWrapper } from "../../../utility/helperFun";
// import VideoModal from "../NewDashboard/VideoModal";
import { VscClose } from "react-icons/vsc";

const plagiarismConnected = new Event("PlagiarismConnected");
const plagiarismDisconnected = new Event("PlagiarismDisconnected");

const wordpressConnected = new Event("wordpressConnected");
const wordpressDisconnected = new Event("wordpressDisconnected");
import { v4 as uuidv4 } from "uuid";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { appScopes, clientId } from "./shopifyConstants";
import useMixpanelHook from "../../../utility/useMixpanelHook";
export default function AppDescription({
    setSelectedApp,
    getApps,
    selectedApp,
    moreApps,
}) {
    const searchParams = new URLSearchParams(window.location.search);
    const appId = useParams().appId || selectedApp;
    const [appData, setAppData] = useState({});
    const [isSemrushModal, setIsSemrushModal] = useState(false);
    // const [semrushData, setSemrushData] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isAuthCode, setIsAuthCode] = useState("");
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const [alreadyConnected, setAlreadyConnected] = useState(false);
    const [signupModal, setSignupModal] = useState(false);
    const [disconnectModal, setDisconnectModal] = useState(false);
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [details, setDetails] = useState({});
    const [rightSectionDetails, setRightSectionDetails] = useState({});
    const [load, setLoad] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [videoModal, setVideoModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [planModal, setPlanModal] = useState({ open: false, id: 0 });
    const [wordpressConnectionError, setWordpressConnectionError] =
        useState(false);
    const [notAllowedError, setNotAllowedError] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const { getData, postData } = useHelperFunctions();
    const [videoLink, setVideoLink] = useState("");
    const goTo = useNavigation();
    const { LockConfigurator } = useUserAccessHook();
    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        setSelectedApp(appId);
    }, []);

    async function disconnectApp() {
        const res = await postData({
            method: "post",
            url: "/api/integrations/disconnect",
            payload: {
                app_id: appData.app_id,
            },
        });
        if (res.status === 200) {
            setIsDisconnected(true);
            setAlreadyConnected(false);
            setIsConnected(false);
            setDisconnectModal(false);
            setAppData((ps) => ({ ...ps, is_connected: 0 }));

            if (appData.app_id == 1) {
                document.dispatchEvent(plagiarismDisconnected);
            } else if (appData.app_id == 4) {
                document.dispatchEvent(wordpressDisconnected);
            }
        }
    }

    function sanitizeURL(inputURL) {
        let url = inputURL.trim().replace(/^https?:\/\//, ""); // Remove 'http://' or 'https://'
        url = url.replace(/\/$/, ""); // Remove trailing slash if exists
        const parts = url.split("/");
        const domain = parts[0].startsWith("www.")
            ? parts[0].slice(4)
            : parts[0];
        return domain;
    }

    const openShopifyAuth = (_shName) => {
        setLoad(true);
        let shopUrl = sanitizeURL(_shName);
        const [scopes, shop, client_id, redirect_uri, nonce] = [
            appScopes,
            shopUrl,
            clientId,
            `${window.location.origin}/admin/shopify/auth`,
            uuidv4(),
        ];

        window.location = `https://${shop}/admin/oauth/authorize?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
    };

    function connectApp(e, developer_type, isUpdate, appName) {
        if (developer_type == 1 || developer_type == 3 || developer_type == 4) {
            setSignupModal(true);
            setAlreadyConnected(false);
        } else {
            semrushAuthenication(e, isUpdate);
        }
        mixpanelTrack("App Integration", {
            action: "Connect",
            appName: appName,
        });
    }

    async function integrateApp(
        developer_type,
        username,
        apikey,
        wordpressPassword,
        domain,
        shop,
        access_token,
    ) {
        setLoad(true);
        let creds = {};
        if (developer_type === 3) {
            if (domain.slice(-1) == "/") {
                domain = domain.slice(0, -1);
            }
            creds = {
                username: username,
                application_password: wordpressPassword,
                domain: domain,
            };
        } else if (developer_type === 4) {
            creds = {
                shop: shop.replace(".myshopify.com", ""),
                access_token: access_token,
            };
        } else {
            creds = {
                username: username,
                apiKey: apikey,
            };
        }
        mixpanelTrack("App Integration", {
            action: "Connect after entering details",
            credentials: JSON.stringify(creds),
            appName: appData.name,
        });
        const res = await postData({
            method: "post",
            url: "/api/integrations",
            payload: {
                app_id: appData.app_id,
                credentials: creds,
            },
        });
        if (res.status === 200) {
            setSignupModal(false);
            setIsSuccessModal(true);
            setIsConnected(true);
            setLoad(false);
            developer_type === 1
                ? document.dispatchEvent(plagiarismConnected)
                : document.dispatchEvent(wordpressConnected);
            mixpanelTrack("App Integration", {
                action: "Successfully connected",
                appName: appData.name,
            });
        } else {
            setLoad(false);
            if (
                res?.response?.data?.message ==
                "we are unable to connect with your WordPress domain"
            ) {
                setWordpressConnectionError(true);
            } else if (
                res?.response?.data?.message ==
                "you are not allowed to login as this user type"
            ) {
                setNotAllowedError(true);
            }
            mixpanelTrack("App Integration", {
                action: "Connection failed",
                appName: appData.name,
                message: res?.response?.data?.message,
            });
        }
    }

    async function loginWithSameAccount(e) {
        e.preventDefault();
        const res = await postData({
            method: "post",
            url: "/api/integrations",
            payload: {
                app_id: appData.app_id,
            },
        });
        if (res.status === 200) {
            setAlreadyConnected(false);
            setIsSuccessModal(true);
            setIsConnected(true);
        }
    }
    let timeoutRef = useRef(null);

    async function semrushAuthenication(e, isUpdate) {
        e.preventDefault();
        window.removeEventListener("message", document.handleSemrushAuth);

        if (appData.is_connected && !isUpdate) {
            setAlreadyConnected(true);
            console.log("nooooooooooooooooooo");
            return;
        }

        document.handleSemrushAuth = async (event) =>
            debounceWrapper(
                async () => {
                    if (event.data?.url?.split("=")[1]) {
                        const res = await postData({
                            method: "post",
                            url: isUpdate
                                ? "/api/integrations/update"
                                : "/api/integrations",
                            payload: {
                                app_id: appData.app_id,
                                code: event.data.url.split("=")[1],
                            },
                        });
                        if (res.status === 200) {
                            localStorage.setItem("code", true);
                            setIsAuthCode(true);
                            event.data &&
                                window.removeEventListener(
                                    "message",
                                    document.handleSemrushAuth,
                                );
                            window.newWindow && window.newWindow.close();
                            setAlreadyConnected(false);
                            setIsSuccessModal(true);
                            setIsConnected(true);
                            // setIsSemrushData(true);
                        } else {
                            window.newWindow && window.newWindow.close();
                            // alert("Some Error Occurred");
                            window.removeEventListener(
                                "message",
                                document.handleSemrushAuth,
                            );
                        }
                    }
                },
                500,
                timeoutRef,
            );
        //Semruh Auth Window
        if (!isAuthCode || isUpdate || !isConnected) {
            window.newWindow = window.open(
                "https://oauth.semrush.com/oauth2/authorize?client_id=scalenut&redirect_uri=%2Foauth2%2Fscalenut%2Fsuccess&response_type=code&scope=user.id%2Cdomains.info",
                "",
                `left=${window.screen.width / 2 - 300},top=${
                    window.screen.height / 2 - 300
                },width=${
                    window.screen.width / 2 < 600
                        ? window.screen.width / 2
                        : 600
                },height=${600}`,
            );
            window.addEventListener(
                "message",
                document.handleSemrushAuth,
                false,
            );
        } else {
            setIsSemrushModal(true);
            window.removeEventListener("message", document.handleSemrushAuth);
        }
    }
    useEffect(() => {
        if (appId) {
            (async () => {
                const res = await getData({
                    url: "/api/integrations/app-details?app_id=" + appId,
                });
                if (res.status == 200) {
                    if (!!!res?.data?.details) {
                        setSelectedApp(null);
                        return !moreApps && goTo("INTEGRATION_HUB");
                    }

                    const details = JSON.parse(res.data.details);
                    if (res.data.is_connected) {
                        setIsSuccessModal(searchParams.has("success"));
                    }
                    setAppData(res.data);
                    setRightSectionDetails(details.details);
                    setDetails(details);
                    setVideoLink(details.video_link);
                }
            })();
        } else {
            setSelectedApp(null);
        }
    }, [isDisconnected]);
    return (
        <>
            {appData && appData.app_id ? (
                <div className={style.wrapper}>
                    <div class={style.appDescContainer}>
                        <div
                            style={{
                                position: "sticky",
                                top: "-60px",
                                background: "#fff",
                                zIndex: "10",
                                padding: "30px 26px 0px 26px",
                            }}
                        >
                            <div>
                                <Button
                                    handler={() => {
                                        getApps();
                                        setSelectedApp(null);
                                        !moreApps && goTo("INTEGRATION_HUB");
                                    }}
                                    style={{
                                        background: "#fff",
                                        color: "#3F5575",
                                        width: "130px",
                                        height: "26px",
                                        fontSize: "12px",
                                        marginBottom: "24px",
                                        border: "1px solid #E1E4ED",
                                        borderRadius: "25px",
                                        fontWeight: "400",
                                    }}
                                    Icon={() => (
                                        <SVGIcon src="/New UI/SVG/Arrow_Back.svg" />
                                    )}
                                    text="Back to all apps"
                                    iconReverse
                                />
                            </div>
                            <div className={style.appHeading}>
                                <div style={{ display: "flex" }}>
                                    <img
                                        style={{
                                            marginRight: "20px",
                                            height: "70px",
                                        }}
                                        src={appData.icon}
                                    />
                                    <div className={style.headingDiv}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h1>{appData.name}</h1>
                                            {appData.app_linked ||
                                            isConnected ? (
                                                <div
                                                    className={style.connected}
                                                >
                                                    Activated
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <a onClick={(e) => e.stopPropagation()}>
                                            {appData.developer}
                                        </a>
                                        <p>{appData.description}</p>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        position: "absolute",
                                        right: 0,
                                        width: "40%",
                                        alignItems: "center",
                                    }}
                                >
                                    <a
                                        onClick={() => {
                                            setVideoModal(true);
                                            mixpanelTrack("App Integration", {
                                                action: "View Demo",
                                                appName: appData.name,
                                            });
                                        }}
                                        className={style.viewDemoButton}
                                    >
                                        <SVGIcon
                                            style={{ marginRight: "4px" }}
                                            src="/New UI/SVG/playButton.svg"
                                        />
                                        View Demo
                                    </a>
                                    <Button
                                        handler={(e) => {
                                            if (
                                                LockConfigurator(
                                                    FEATURE_TYPES.appIntegrationLock,
                                                )
                                            ) {
                                                return setPlanModal({
                                                    open: true,
                                                    id: -4,
                                                });
                                            }
                                            (appData.app_linked &&
                                                appData.is_connected) ||
                                            isConnected
                                                ? setDisconnectModal(true)
                                                : appData.is_connected
                                                ? setAlreadyConnected(true)
                                                : connectApp(
                                                      e,
                                                      appData.developer_type,
                                                      false,
                                                      appData.name,
                                                  );
                                        }}
                                        width="125px"
                                        height="44px"
                                        text={
                                            appData.app_linked || isConnected
                                                ? "Disconnect"
                                                : "Connect"
                                        }
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            borderRadius: 3,
                                            marginLeft: "1rem",
                                            background:
                                                appData.app_linked ||
                                                isConnected
                                                    ? "rgba(1, 77, 197, 0.1)"
                                                    : "#014DC5",
                                            color:
                                                appData.app_linked ||
                                                isConnected
                                                    ? "#014DC5"
                                                    : "white",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={style.separator}></div>
                            <div
                                className={style.rightSection}
                                style={{ display: "flex" }}
                            >
                                <div>
                                    <p className={style.requirements}>
                                        Details
                                    </p>
                                    {rightSectionDetails.headings &&
                                        rightSectionDetails.headings.map(
                                            (section) => (
                                                <div>
                                                    <p
                                                        className={
                                                            style.rightSectionSubHeading
                                                        }
                                                    >
                                                        {section.heading}
                                                    </p>
                                                    <p
                                                        className={
                                                            style.rightSectionDetails
                                                        }
                                                    >
                                                        {section.description}
                                                    </p>
                                                </div>
                                            ),
                                        )}
                                    <p className={style.rightSectionSubHeading}>
                                        Integrated Module(s)
                                    </p>
                                    {rightSectionDetails[
                                        "Integrated Module"
                                    ] && (
                                        <div style={{ display: "flex" }}>
                                            {rightSectionDetails[
                                                "Integrated Module"
                                            ].map((module) => (
                                                <p
                                                    className={
                                                        style.integratedModule
                                                    }
                                                >
                                                    {module}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                    <p className={style.rightSectionSubHeading}>
                                        Resources
                                    </p>
                                    <LightTooltip
                                        title={""}
                                        placement={"right"}
                                        arrow
                                    >
                                        <a
                                            target="_blank"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                window.open(
                                                    rightSectionDetails
                                                        ?.Resources[
                                                        "Setup guide"
                                                    ],
                                                    "_blank",
                                                );
                                            }}
                                            style={{ cursor: "pointer" }}
                                            className={
                                                style.rightSectionDetails
                                            }
                                        >
                                            Setup Guide{" "}
                                            <FiExternalLink
                                                className={style.linkIcon}
                                            />
                                        </a>
                                    </LightTooltip>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                paddingInline: "30px",
                            }}
                        >
                            <div className={style.leftSection}>
                                <Carousel
                                    onClickItem={(index, item) =>
                                        setSelectedImageIndex(index)
                                    }
                                    showArrows={true}
                                    className={style.carousel}
                                    renderArrowPrev={(handler, hasPrev) =>
                                        hasPrev && (
                                            <span
                                                onClick={() => handler()}
                                                className={style.previousIcon}
                                            >
                                                <SVGIcon
                                                    color="#1f4bce"
                                                    src={"/New UI/SVG/next.svg"}
                                                />
                                            </span>
                                        )
                                    }
                                    renderArrowNext={(handler, hasNext) =>
                                        hasNext && (
                                            <span
                                                onClick={() => handler()}
                                                className={style.nextIcon}
                                            >
                                                <SVGIcon
                                                    color="#1f4bce"
                                                    src={"/New UI/SVG/next.svg"}
                                                />
                                            </span>
                                        )
                                    }
                                    // onChange={onChange}
                                    // onClickItem={onClickItem}
                                    // onClickThumb={onClickThumb}
                                >
                                    {appData &&
                                        appData.screenshots &&
                                        appData.screenshots.map(
                                            (screenshot) => (
                                                <div
                                                    onClick={() => {
                                                        setImgSrc(screenshot);
                                                        setImageModal(true);
                                                    }}
                                                    className={
                                                        style.imgContainer
                                                    }
                                                >
                                                    <img src={screenshot} />
                                                </div>
                                            ),
                                        )}
                                </Carousel>
                                <div className={style.separator}></div>
                                {details?.Description && (
                                    <p
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            color: "#3F5575",
                                        }}
                                    >
                                        Description
                                    </p>
                                )}
                                {details &&
                                    details.Description &&
                                    details.Description.map((para) => (
                                        <div className={style.descriptionPara}>
                                            <div className={style.textPart}>
                                                <p
                                                    className={
                                                        style.descriptionHeading
                                                    }
                                                >
                                                    {para.heading}
                                                </p>
                                                {para.details.length > 0 &&
                                                    para.details.map(
                                                        (detail) => (
                                                            <p
                                                                className={
                                                                    style.descriptionText
                                                                }
                                                            >
                                                                {detail}
                                                            </p>
                                                        ),
                                                    )}
                                            </div>
                                            {/* <img src={para.screenshot} /> */}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {alreadyConnected && (
                            <Modal setModal={setAlreadyConnected}>
                                <AlreadyConnectedModal
                                    appData={appData}
                                    isConnected={isConnected}
                                    disconnectApp={disconnectApp}
                                    semrushAuthenication={semrushAuthenication}
                                    connectApp={connectApp}
                                    loginWithSameAccount={loginWithSameAccount}
                                    setAlreadyConnected={setAlreadyConnected}
                                />
                            </Modal>
                        )}
                        {isSuccessModal && (
                            <Modal setModal={setIsSuccessModal}>
                                <SuccessModal
                                    appData={appData}
                                    setIsSuccessModal={setIsSuccessModal}
                                />
                            </Modal>
                        )}
                        {signupModal && (
                            <Modal setModal={setSignupModal}>
                                <CopyscapeSignup
                                    openShopifyAuth={openShopifyAuth}
                                    appData={appData}
                                    load={load}
                                    integrateApp={integrateApp}
                                    setSignupModal={setSignupModal}
                                    wordpressConnectionError={
                                        wordpressConnectionError
                                    }
                                    setWordpressConnectionError={
                                        setWordpressConnectionError
                                    }
                                    notAllowedError={notAllowedError}
                                    setNotAllowedError={setNotAllowedError}
                                />
                            </Modal>
                        )}
                        {imageModal && (
                            <Modal
                                customClass={style.modalBack}
                                setModal={setImageModal}
                            >
                                <div className={style.imageModal}>
                                    <div>
                                        <IoMdClose
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                color: "#fff",
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setImageModal(false);
                                            }}
                                            className={style.modalClose}
                                        />
                                    </div>
                                    <Carousel
                                        showThumbs={false}
                                        showArrows={true}
                                        selectedItem={selectedImageIndex}
                                        renderArrowPrev={(handler) => (
                                            <span className={style.leftSlider}>
                                                <SVGIcon
                                                    size={30}
                                                    color="#fff"
                                                    onClick={() => handler()}
                                                    src={
                                                        "/New UI/SVG/ChevronRight.svg"
                                                    }
                                                />
                                            </span>
                                        )}
                                        renderArrowNext={(handler) => (
                                            <span className={style.rightSlider}>
                                                <SVGIcon
                                                    size={30}
                                                    color="#fff"
                                                    onClick={() => handler()}
                                                    src={
                                                        "/New UI/SVG/ChevronRight.svg"
                                                    }
                                                />
                                            </span>
                                        )}
                                        renderThumbs={() => {
                                            <h1>Rahul</h1>;
                                        }}
                                        className={style.carousel2}
                                        // onChange={onChange}
                                        // onClickItem={onClickItem}
                                        // onClickThumb={onClickThumb}
                                    >
                                        {appData &&
                                            appData.screenshots &&
                                            appData.screenshots.map(
                                                (screenshot) => (
                                                    <div
                                                        className={
                                                            style.imgContainer
                                                        }
                                                    >
                                                        <img src={screenshot} />
                                                    </div>
                                                ),
                                            )}
                                    </Carousel>
                                </div>
                            </Modal>
                        )}
                        {disconnectModal && (
                            <Modal setModal={setDisconnectModal}>
                                <DisconnectModal
                                    appData={appData}
                                    disconnectApp={disconnectApp}
                                    setDisconnectModal={setDisconnectModal}
                                />
                            </Modal>
                        )}
                        {videoModal && (
                            <Modal setModal={setVideoModal}>
                                <VideoModal
                                    setVideoModal={setVideoModal}
                                    videoModal={videoModal}
                                    link={videoLink}
                                />
                            </Modal>
                        )}
                        {/* <VideoModal setVideoModal={setVideoModal} link={videoLink} /> */}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        // width: "100%",
                        // height: "100%",
                        // display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "center",
                        // alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            )}
            {planModal.open && (
                <SeoWithCruiseModal modal={planModal} setModal={setPlanModal} />
            )}
        </>
    );
}

const SuccessModal = ({ setIsSuccessModal, appData }) => {
    return (
        <div className={style.successModal}>
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setIsSuccessModal(false);
                }}
                className={style.modalClose}
            />
            <div className={style.connectionLogo}>
                <div className={style.scalenutLogo}>
                    <SVGIcon
                        // style={{ marginRight: "1rem" }}
                        size={30}
                        src="/New UI/SVG/Scalenut.svg"
                    />
                </div>
                <div style={{ marginTop: "10px" }}>
                    <SVGIcon src="/New UI/SVG/connection_success.svg" />
                </div>
                <img style={{ width: "60px" }} src={appData.icon} />
            </div>
            <p
                style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingInline: "20px",
                    color: "#3f5575",
                }}
            >
                Your Scalenut account is successfully connected with your
                provided {appData.developer} account
            </p>
        </div>
    );
};

const CopyscapeSignup = ({
    appData,
    setSignupModal,
    integrateApp,
    load,
    wordpressConnectionError,
    setWordpressConnectionError,
    setNotAllowedError,
    notAllowedError,
    openShopifyAuth = () => {},
}) => {
    const [username, setUsername] = useState("");
    const [shopName, setShopName] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [apikey, setAPIkey] = useState("");
    const [wordpressPassword, setWordpressPassword] = useState("");
    const [domain, setDomain] = useState("");
    return (
        <div style={{ alignItems: "center" }} className={style.signupModal}>
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setSignupModal(false);
                    setWordpressConnectionError(false);
                    setNotAllowedError(false);
                }}
                className={style.modalClose}
            />
            <div className={style.connectionLogo}>
                <div className={style.scalenutLogo}>
                    <SVGIcon
                        // style={{ marginRight: "1rem" }}
                        size={30}
                        src="/New UI/SVG/Scalenut.svg"
                    />
                </div>
                <div style={{ marginTop: "15px", marginInline: "10px" }}>
                    <SVGIcon size={30} src="/New UI/SVG/connection.svg" />
                </div>
                <img style={{ width: "60px" }} src={appData.icon} />
            </div>
            <p className={style.connectToScalenut}>
                Connect Scalenut to{" "}
                <span style={{ color: "#014dc5" }}>{appData.developer}</span>
            </p>

            {appData.developer_type === 1 && (
                <>
                    <div className={style.signupNote}>
                        <span style={{ fontWeight: "600" }}>Note</span>: This
                        app is powered by Copyscape. Please enter your Copyscape
                        account details to activate the app.
                    </div>
                    <p
                        style={{
                            fontSize: "14px",
                            color: "#3f5575",
                            textAlign: "left",
                            margin: "15px 19px 21px 0",
                        }}
                    >
                        Don't have an account?{" "}
                        <a
                            href="https://www.copyscape.com/signup.php?sign_up&pro=1"
                            target="_blank"
                            style={{
                                fontWeight: "600",
                                textDecoration: "none",
                                color: "#014dc5",
                            }}
                        >
                            Create new account
                        </a>
                    </p>
                </>
            )}

            <p
                style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#3f5575",
                    textAlign: "left",
                }}
            >
                Enter Account Details
            </p>

            <div style={{ textAlign: "left" }}>
                {appData.developer_type === 3 ? (
                    <>
                        <label className={style.label}>
                            WordPress site address
                        </label>
                        <Textfield
                            placeholder="https://www.scalenut.com"
                            label=""
                            // pattern="(^h$)|(^ht$)|(^htt$)|(^http$)|(^https$)|(^https:$)|(^https:\/$)|(^https:\/\/.*)"
                            props={{
                                value: domain,
                                onChange: (e) => {
                                    setDomain(e.target.value);
                                },
                                autoComplete: "off",
                                disabled: load,
                            }}
                            errMsg={
                                domain.length > 0 &&
                                domain.toLowerCase().indexOf("https://") !== 0
                                    ? "Domain should start with https://"
                                    : ""
                            }
                        />
                    </>
                ) : (
                    <></>
                )}
                {appData.developer_type == 4 ? (
                    <form
                        style={{
                            marginBottom: "50px",
                        }}
                        onSubmit={(e) => e.preventDefault()}
                        autoComplete="off"
                    >
                        <label className={style.label}>Store Link</label>
                        <Textfield
                            placeholder="Enter Store Link"
                            label=""
                            props={{
                                value: shopName,
                                onChange: (e) => {
                                    setShopName(e.target.value);
                                },
                                autoComplete: "false",
                                disabled: load,
                            }}
                            name="shop_name"
                        />
                        <input
                            style={{ display: "none" }}
                            autoComplete="false"
                        />
                        <label className={style.label}>
                            Access Token{" "}
                            <span style={{ textTransform: "none" }}>
                                (Access scopes:"write_content,write_files")
                            </span>
                        </label>
                        <Textfield
                            placeholder="Enter Access Token"
                            label=""
                            name="access_token"
                            props={{
                                value: accessToken,
                                onChange: (e) => {
                                    setAccessToken(e.target.value);
                                },
                                autoComplete: "false",
                                disabled: load,
                            }}
                        />
                    </form>
                ) : (
                    <>
                        <label className={style.label}>Username</label>
                        <Textfield
                            placeholder="Enter username"
                            label=""
                            props={{
                                value: username,
                                onChange: (e) => {
                                    setUsername(e.target.value);
                                },
                                autoComplete: "off",
                                disabled: load,
                            }}
                        />
                    </>
                )}
                {appData.developer_type === 1 ? (
                    <>
                        <label className={style.label}>API Key</label>
                        <Textfield
                            placeholder="Enter API Key"
                            label=""
                            props={{
                                value: apikey,
                                onChange: (e) => {
                                    setAPIkey(e.target.value);
                                },
                                autoComplete: "off",
                                disabled: load,
                            }}
                        />
                    </>
                ) : appData.developer_type == 4 ? (
                    <></>
                ) : (
                    <>
                        <label className={style.label}>
                            Application Password{" "}
                            <SVGIcon
                                tooltip="Application Password is a 24-characters long key which can be generated via your WordPress admin panel for 3rd party applications. You can not use your website password here."
                                size={18}
                                src={"/New UI/SVG/info.svg"}
                            />
                        </label>
                        <div>
                            <Textfield
                                label=""
                                type={"password"}
                                placeholder={"Enter Password"}
                                id="newPassword"
                                name="newPassword"
                                props={{
                                    value: wordpressPassword,
                                    onChange: (e) => {
                                        setWordpressPassword(e.target.value);
                                    },
                                    autoComplete: "off",
                                    disabled: load,
                                }}
                            />
                        </div>
                    </>
                )}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                }}
            >
                {/* <Button
                    handler={() => {
                        window.open(
                            appData.developer_type !== 1
                                ? "https://help.scalenut.com/wordpress-x-scalenut-how-to-publish-an-article-on-wordpress-from-scalenut/"
                                : "https://help.scalenut.com/scalenut-x-copyscape-how-to-check-plagiarism/",
                        );
                    }}
                    width="130px"
                    height="44px"
                    text={"Need Help"}
                    style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        background: "rgba(1, 77, 197, 0.1)",
                        color: "#014DC5",
                    }}
                    Icon={() => <SVGIcon src="/New UI/SVG/need_help.svg" />}
                    iconReverse
                /> */}
                <Button
                    loading={load}
                    handler={(e) => {
                        integrateApp(
                            appData.developer_type,
                            username,
                            apikey,
                            wordpressPassword,
                            domain,
                            shopName,
                            accessToken,
                        );
                    }}
                    disabled={
                        (appData.developer_type !== 1 &&
                            appData.developer_type !== 4 &&
                            (domain.toLowerCase().indexOf("https://") !== 0 ||
                                !username ||
                                !wordpressPassword)) ||
                        (appData.developer_type == 4 && !shopName)
                    }
                    style={{ fontSize: "14px", fontWeight: "600", margin: "0" }}
                    width="130px"
                    height="44px"
                    text={"Connect"}
                />
            </div>
            {wordpressConnectionError && (
                <div className={style.errorContainer}>
                    We are unable to connect with your WordPress domain. For
                    more information, please visit this{" "}
                    <a
                        href="https://help.scalenut.com/unable-to-connect-your-wordpress-to-scalenut-follow-these-steps/"
                        target="_blank"
                    >
                        help article
                    </a>{" "}
                </div>
            )}
            {notAllowedError && (
                <div className={style.errorContainer}>
                    You are not allowed to log in as this user type. For more
                    information, please visit this{" "}
                    <a
                        href="https://help.scalenut.com/unable-to-connect-your-wordpress-to-scalenut-follow-these-steps/"
                        target="_blank"
                    >
                        help article
                    </a>
                </div>
            )}
        </div>
    );
};

const AlreadyConnectedModal = ({
    appData,
    isConnected,
    disconnectApp,
    semrushAuthenication,
    setAlreadyConnected,
    loginWithSameAccount,
    connectApp,
}) => {
    return (
        <div
            style={{ alignItems: "center" }}
            className={style.alreadyConnectedModal}
        >
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setAlreadyConnected(false);
                }}
                className={style.modalClose}
            />
            <div className={style.connectionLogo}>
                <div className={style.scalenutLogo}>
                    <SVGIcon
                        // style={{ marginRight: "1rem" }}
                        size={30}
                        src="/New UI/SVG/Scalenut.svg"
                    />
                </div>
                <div style={{ marginTop: "15px", marginInline: "10px" }}>
                    <SVGIcon size={30} src="/New UI/SVG/connection.svg" />
                </div>
                <img style={{ width: "60px" }} src={appData.icon} />
            </div>
            <p style={{ fontSize: "16px", color: "#3f5575" }}>
                You are already using a Semrush account in other Semrush powered
                app. Do you want to use the same account in this app?
            </p>
            <div className={style.note}>
                <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    Note
                </span>
                : Changing Semrush account in this tool will also change the
                account in other Semrush apps.
            </div>
            {/* <div className={style.warning}>
        <SVGIcon src="/New UI/SVG/warning.svg" />
        <div style={{ marginLeft: "1rem" }}>
          <p style={{ fontWeight: "bold", margin: "0" }}>Warning</p>
          Changing Semrush account in this tool will also change the account in
          other Semrush apps.
        </div>
      </div> */}
            <div style={{ display: "flex" }}>
                <Button
                    handler={(e) => connectApp(e, appData.developer_type, true)}
                    style={{
                        marginRight: "20px",
                        background: "rgba(1, 77, 197, 0.1)",
                        color: "#014DC5",
                        marginLeft: "0",
                    }}
                    width="220px"
                    text="Use Different Account"
                />
                <Button
                    handler={(e) =>
                        appData.app_linked || isConnected
                            ? disconnectApp()
                            : loginWithSameAccount(e)
                    }
                    style={{ marginLeft: "0", marginRight: "0" }}
                    width="220px"
                    text={
                        appData.app_linked || isConnected
                            ? "Disconnect"
                            : "Use Same Account"
                    }
                />
            </div>
        </div>
    );
};

const DisconnectModal = ({ appData, disconnectApp, setDisconnectModal }) => {
    return (
        <div style={{ alignItems: "center" }} className={style.disconnectModal}>
            <IoMdClose
                onClick={(e) => {
                    e.stopPropagation();
                    setDisconnectModal(false);
                }}
                className={style.modalClose}
            />
            <div className={style.connectionLogo}>
                <div className={style.scalenutLogo}>
                    <SVGIcon
                        // style={{ marginRight: "1rem" }}
                        size={30}
                        src="/New UI/SVG/Scalenut.svg"
                    />
                </div>
                <div style={{ marginTop: "10px" }}>
                    <SVGIcon src="/New UI/SVG/disconnect.svg" />
                </div>
                <img style={{ width: "60px" }} src={appData.icon} />
            </div>
            <p
                style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingInline: "20px",
                    color: "#3f5575",
                    marginTop: "22px",
                    marginBottom: "20px",
                }}
            >
                Are you sure you want to disconnect the App?
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    handler={(e) => setDisconnectModal(false)}
                    style={{
                        marginRight: "20px",
                        background: "#f1f1f1",
                        color: "#ACB3BF",
                        fontWeight: "600",
                        marginLeft: "0",
                    }}
                    width="120px"
                    text="Cancel"
                />
                <Button
                    handler={(e) => disconnectApp()}
                    style={{
                        background: "#FC5A5A",
                        fontWeight: "600",
                        marginLeft: "0",
                        marginRight: "0",
                    }}
                    width="120px"
                    text={"Disconnect"}
                />
            </div>
        </div>
    );
};

const VideoModal = ({ link, zoomVal = 1, setVideoModal, videoModal }) => {
    return (
        <>
            <iframe
                width="800"
                height="450"
                src={link}
                title="Keyword Reports: Your AI-route to Building Topical Authority"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            {/* <iframe
        width="676"
        height="380"
        src="https://www.youtube.com/embed/tqPZIOwWOzw"
        title="Keyword Reports: Your AI-route to Building Topical Authority"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> */}
        </>
    );
};
