import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    withStyles,
    CircularProgress,
    Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { IoIosClose, IoMdPodium } from "react-icons/io";
import {
    RiFileCopyLine,
    RiNumbersLine,
    RiQuestionnaireFill,
} from "react-icons/ri";
import CopyText from "../CopyText";
import ArticleViewer from "./ArticleViewer";
import styles from "./Competetion.module.css";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import CopyH2H3 from "./CopyH2H3";
import { FiExternalLink } from "react-icons/fi";
import { openLinkWindow } from "../../../../utility/validateFunctions";
import CompetetionModal from "../../../molecules/Creator/Overview/CompetetionModal";
import { WbIridescentOutlined } from "@material-ui/icons";
import Button from "../../../atoms/Button/Button";
import HighLighter from "../../../atoms/HightLighter/HighLighter";
import CompetetionAcc from "./CompetetionAcc";
import WriteToEditor, {
    HtmlTagToDelta,
} from "../../../organisms/TextEditor/Toolbar/WriteToEditor";

import useHelperFunctions from "../../../helperFunctions";
import { FcGlobe } from "react-icons/fc";
import SVGIcon from "../../../atoms/SVGIcon";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import Quill from "quill";
import ReportContext from "../ReportContext";
const Delta = Quill.import("delta");

const writeOutline = (item) => {
    const data = [];

    data.push(...HtmlTagToDelta(item.url_title, "h3"));
    data.push(...HtmlTagToDelta(`Source : (${new URL(item.url).origin})`, "p"));
    data.push(...HtmlTagToDelta(item.description, "p"));

    item.h_tags.h?.map((edx) => {
        data.push(...HtmlTagToDelta(edx.text, "ul"));
    });

    if (document.quill.editor.isEnabled())
        document.quill.editor.setContents(
            new Delta()
                .concat(
                    document.quill.editor
                        .getContents()
                        .slice(0, document.quill.editor.cursor),
                )
                .concat(new Delta(data))
                .concat(
                    document.quill.editor
                        .getContents()
                        .slice(document.quill.editor.cursor),
                ),
        );
};

const useStyles = makeStyles({
    root: {
        display: "block",
        padding: 0,
    },

    accRoot: {
        boxShadow: "none",
    },
    accSummRoot: {
        padding: 0,
        minHeight: "0 !important",
    },
    accSummContent: {
        margin: "0 !important",
    },
    expandedIcon: {
        padding: 0,
    },
});

function getEdStatus(readabilityScore) {
    if (readabilityScore === 0) {
        return "-";
    } else if (readabilityScore <= 20) {
        return "University Graduates";
    } else if (readabilityScore >= 21 && readabilityScore <= 40) {
        return "College Graduates";
    } else if (readabilityScore >= 41 && readabilityScore <= 60) {
        return "High School Students";
    } else if (readabilityScore >= 61 && readabilityScore <= 70) {
        return "Mid School Students";
    } else return "4th Grader Students";
}

const CompetetionItem = ({
    reportId,
    reportTitle,
    item,
    writingState,
    setWritingState,
    writeOutline,
    setArticleLink,
    setData,
    writeWithAnimation,
}) => {
    const { postData, getData } = useHelperFunctions();
    const [expand, setExpand] = useState(false);
    const [expanding, setExpanding] = useState(false);
    const [showAiTitles, setShowAiTitles] = useState(false);
    const { isShared } = useContext(ReportContext);
    const [aiTitles, setAiTitles] = useState([
        { text: "hello" },
        { text: "ok bye" },
        { text: "see you soon " },
        { text: "go to hello" },
    ]);
    const [clickToAdd, setClickToAdd] = useState(false);
    useEffect(() => {
        let timer = null;
        if (clickToAdd) {
            timer = setTimeout(() => {
                setClickToAdd(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [clickToAdd]);

    const addToEditor = (str) => {
        if (writingState.state) return;
        WriteToEditor({
            data: !document.quill.editor.getText(
                document.quill.editor.cursor,
                1,
            )
                ? str
                : document.quill.editor.getText(
                      document.quill.editor.cursor,
                      1,
                  ) != "\n"
                ? "\n" + str
                : str,
            type: "text",
            length: str.length + 1,
            source: "user",
        });
        setClickToAdd(true);
    };

    const ShowAccordion = (item) => {
        const arr = item.h_tags.h;
        // if (arr.length == 0) return false;

        let isOldReport =
            arr.filter((item) => item.paragraph == null).length == arr.length;
        if (isOldReport) return true;

        return arr.filter(
            (item) =>
                item.paragraph != null &&
                item.paragraph.length != 0 &&
                item?.text?.length > 0,
        ).length > 0
            ? true
            : false;
    };
    console.log(item, "competetion item");
    const classes = useStyles;
    return (
        <div key={item.url_title} className={styles.competetionLinkContainer}>
            <div style={{ flexGrow: 1 }}>
                <div>
                    <div
                        className={styles.titleContainer}
                        onClick={() => addToEditor(item.url_title)}
                    >
                        <LightTooltip
                            arrow
                            title={
                                (isShared && isShared?.access) || !isShared
                                    ? clickToAdd
                                        ? "Added!"
                                        : "Click to Add"
                                    : ""
                            }
                            placement="top"
                        >
                            {/* <CopyText text={item.url_title}> */}
                            <p className={styles.competetionTitle}>
                                {item.url_title}
                            </p>
                            {/* </CopyText> */}
                        </LightTooltip>
                    </div>
                    <div className={styles.description}>{item.description}</div>

                    {showAiTitles && !expanding && (
                        <div className={styles.aiTitlesContainer}>
                            <div
                                className={styles.closeAiContainer}
                                onClick={() => setShowAiTitles(false)}
                            >
                                <IoIosClose size={20} color="#ababab" />
                            </div>

                            {aiTitles.map((item) => (
                                <div
                                    className={styles.aiTitleItem}
                                    onClick={() => addToEditor(item.content)}
                                    key={item._id}
                                >
                                    <img
                                        src={"/AssetsV2/AIiconheadingicon.svg"}
                                        className={styles.normalIconSize}
                                    />
                                    <LightTooltip
                                        arrow
                                        title={
                                            (isShared && isShared?.access) ||
                                            !isShared
                                                ? clickToAdd
                                                    ? "Added!"
                                                    : "Click to Add"
                                                : ""
                                        }
                                        placement="top"
                                    >
                                        <div className={styles.aiTitleText}>
                                            {item.content}
                                        </div>
                                    </LightTooltip>
                                </div>
                            ))}
                        </div>
                    )}
                    <span
                        onClick={(e) => openLinkWindow(e, item.url)}
                        className={styles.competetionLink}
                    >
                        {item.favicon ? (
                            <img height={14} width={14} src={item.favicon} />
                        ) : (
                            <SVGIcon src="/New UI/SVG/globe.svg" />
                        )}
                        &nbsp; &nbsp;
                        {new URL(item.url).origin}
                    </span>
                </div>

                <div className={styles.lowerContainer}>
                    <div className={styles.leftRank}>#{item.rank}</div>
                    <span className={styles.wordCount}>
                        {!(item.word_count < 50)
                            ? `${item.word_count} words`
                            : "-"}
                    </span>

                    <span className={styles.rank}>
                        {!(item.word_count < 50) ? (
                            <HighLighter quality={item.grade} />
                        ) : (
                            "-"
                        )}
                    </span>

                    {item.word_count >= 50 ? (
                        <LightTooltip
                            arrow
                            title={
                                item.h_tags?.h.length !== 0
                                    ? "Click to expand and check all sub headings"
                                    : "No headings found in the content"
                            }
                        >
                            <div
                                onClick={() =>
                                    (item.h_tags?.h).length !== 0
                                        ? setExpand(!expand)
                                        : ""
                                }
                                className={
                                    (item.h_tags?.h).length !== 0
                                        ? styles.accSum
                                        : styles.accSumDisabled
                                }
                            >
                                {!expand ? (
                                    <>
                                        Expand <MdExpandMore />
                                    </>
                                ) : (
                                    <>
                                        Collapse <MdExpandLess />
                                    </>
                                )}
                            </div>
                        </LightTooltip>
                    ) : (
                        <div className={styles.accSumR}>Not Processed</div>
                    )}

                    {/* )} */}
                </div>

                {item.word_count < 50 ? (
                    <></>
                ) : (
                    item.h_tags.h?.length > 0 && (
                        <CompetetionAcc
                            writeOutline={writeOutline}
                            setExpand={setExpand}
                            expand={expand}
                            setArticleLink={setArticleLink}
                            setData={setData}
                            item={item}
                            writingState={writingState}
                            setWritingState={setWritingState}
                            reportId={reportId}
                            reportTitle={reportTitle}
                            writeWithAnimation={writeWithAnimation}
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default function Competetion({
    list = [],
    showAll,

    writingState,
    setWritingState,
    reportInfo,
    writeWithAnimation,
}) {
    const [articleLink, setArticleLink] = useState(false);
    const [data, setData] = useState({});
    return (
        <>
            {articleLink && (
                <CompetetionModal
                    toggleModal={setArticleLink}
                    data={data}
                    setData={setData}
                />
            )}
            <div className={styles.competetionContainer}>
                {list && list.length
                    ? list
                          .slice(0, showAll ? list.length : 10)
                          .map((item) => (
                              <CompetetionItem
                                  key={item.url}
                                  item={item}
                                  writeOutline={writeOutline}
                                  setArticleLink={setArticleLink}
                                  setData={setData}
                                  writingState={writingState}
                                  setWritingState={setWritingState}
                                  reportTitle={reportInfo.title}
                                  writeWithAnimation={writeWithAnimation}
                                  reportId={reportInfo.report_id}
                              />
                          ))
                    : ""}
            </div>
        </>
    );
}
