import React, { useState, useEffect } from "react";
import ScreenContent from "../../organisms/ScreenContent/ScreenContent";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import GenerateVariants from "../../organisms/Generate/GenerateVariants/GenerateVariants";
import VariantsCatTabs from "../../molecules/VariantsCatTabs/VariantsCatTabs";
import StickyHeader from "../../organisms/StickyHeader/StickyHeader";
import FirstTimeBanner from "../../molecules/FirstTimeBanner/FirstTimeBanner";
import { HiArrowNarrowRight } from "react-icons/hi";
import generateImage from "../../../assets/images/quality-x-graphic.svg";
import { useDispatch, useSelector } from "react-redux";
import useHelperFunctions from "../../helperFunctions";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { BiSearch } from "react-icons/bi";
import { updateUserInfo } from "../../../store/actions/auth";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { CgClose } from "react-icons/cg";
import useUserAccessHook from "../../../components/account/useUserAccessHook";

function filterWithId(v, filterId) {
  if (filterId == 0) {
    return true;
  } else {
    return filterId == v.category_id;
  }
}

export default function ({ setSeoModal, setModal }) {
  const userId = useSelector((state) => state.auth.userId);
  const [filterTerm, setFilterTerm] = useState("");
  const [filterId, setFilterId] = useState("all");
  const { getData, putData } = useHelperFunctions();
  const variants = useSelector((state) => state.variants.generateData);
  const otherInfo = useSelector((state) => state.otherInfo);
  const [request, setRequest] = useState(false);

  const [loading, setLoading] = useState(false);
  const generate = useSelector((state) => state.otherInfo.isGenerate);
  const _generate = useSelector((state) => state.auth.isGenerate);
  const tour = useSelector((state) => state.auth.tour);
  const ai_tour = useSelector((state) => state.auth.ai_tour);
  const goTo = useNavigation();
  const { cruiseLocked } = useUserAccessHook();
  const dispatch = useDispatch();
  const [firstTime, setFirstTime] = useState(
    localStorage.getItem("fTG") || false
  );
  const location = useLocation();

  const handleRequest = async () => {
    setLoading(true);
    const res = await getData({ url: "/api/generates/access" });
    if (res.status == 200) {
      setRequest(true);
    }
    setLoading(false);
  };
  console.log(variants);
  useEffect(() => {
    if (generate == 2 || otherInfo.generate == 2) {
      setRequest(true);
    }
  }, [generate]);

  const showCategories = () => {
    if (location.pathname === "/admin/generate") {
      return 1;
    } else if (location.pathname === "/admin/generate/history") {
      return "history";
    } else if (location.pathname === "/admin/generate/favourite") {
      return "favourite";
    } else {
      return 0;
    }
  };
  useEffect(() => {
    localStorage.setItem("fTG", firstTime);
  }, [firstTime]);

  useEffect(() => {
    setFilterId("all");
  }, [filterTerm]);

  function closeTour(backend) {
    if (backend) {
      return putData({
        url: `/api/user/${userId}/update`,
        payload: {
          ai_tour: 1,
        },
      });
    }

    putData({
      url: `/api/user/${userId}/update`,
      payload: {
        ai_tour: 1,
      },
    });
    dispatch(updateUserInfo("ai_tour", 1));
  }

  if (generate === 1 || _generate === 1) {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.taglineContainer}>
            <h2>What would you like to do today? </h2>

            <Textfield
              props={{
                onInputCapture: (e) => {
                  setFilterTerm(e.target.value);
                },
                style: {
                  border: "0px solid grey",
                  paddingLeft: "35px",
                  borderRadius: "3px",
                  border: "solid 1px #e5e5e5",
                },
              }}
              width={"360px"}
              height={"34px"}
              Icon={() => <BiSearch size={20} />}
              placeholder={"Search Catalog"}
              label={false}
              maxLength={100}
              noItalics
              border="border: solid 0px #e9e9e9"
              iconStyle={{ right: "unset", left: "10px", top: "54%" }}
              style={{
                border: "0px solid grey",
                paddingLeft: "40px",
              }}
              containerStyle={{ marginRight: 50 }}
            />
            <span className={styles.closeBtn} onClick={() => setModal(false)}>
              <CgClose />
            </span>
          </div>
          <div style={{ marginBottom: "-13px" }}>
            <VariantsCatTabs
              filterId={filterId}
              setFilterId={setFilterId}
              redirectTo="GENERATE"
              show={showCategories()}
            />
          </div>
        </div>

        {variants[filterId] && (
          <GenerateVariants
            setSeoModal={setSeoModal}
            setFilterId={setFilterId}
            filterId={filterId}
            generateVariants={variants[filterId].filter(
              (v) =>
                v.name
                  .trim()
                  .toLowerCase()
                  .includes(filterTerm.trim().toLowerCase()) ||
                v.cat_name
                  .trim()
                  .toLowerCase()
                  .includes(filterTerm.trim().toLowerCase())
            )}
            filterTerm={filterTerm}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <FirstTimeBanner
          title="Welcome to"
          coloredTitle="Scalenut AI Copywriter!!"
          subheading="Few disclaimers and tips before you start:"
          list={[
            "All outputs are generated via AI",
            "It is encouraged to have human intervention before using the outputs elsewhere",
            "The tools ahead are aimed at improving efficiency of your work and not replace human intervention at all",
            "We await your feedback, use our chat option to share any feedback/suggestion/constructive criticism",
            "Most importantly, have fun Generating!",
          ]}
          buttonText={
            !request
              ? "Request Access for the AI Copywriter"
              : "Your request has been received !"
          }
          buttonIcon={HiArrowNarrowRight}
          buttonDisable={request === true || generate === 1 ? true : false}
          buttonWidth="400px"
          handler={() => {
            handleRequest();
          }}
          image={generateImage}
        ></FirstTimeBanner>
      </>
    );
  }
}
