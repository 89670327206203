import axios from "axios";
import { useEffect } from "react";

function useNetworkInterceptor() {
    useEffect(() => {
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error?.config?.skipErrorMsg) {
                    error = {};
                }
                return Promise.reject(error);
            },
        );
    }, []);

    return null;
}

export default useNetworkInterceptor;
