import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import ReportContext from "../../Research/ReportContext";
import SVGIcon from "../../../atoms/SVGIcon";
import styles from "./FAQ.module.css";
import styles2 from "./Placeholder.module.css";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import {
    debounceWrapper,
    getKeytermVariations,
} from "../../../../utility/helperFun";
import { NoResults } from "./PlaceholderOutlines";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import { CruiseContext, CruiseState } from "./StepsWizard";
import { Outline } from "./classes";
import { v4 } from "uuid";
import { CRUISE_TYPES } from "./useCruiseHook";

const PlaceholderFAQ = () => {
    const pathIcon = "/New UI/SVG/QuestionSoruces/";

    const { peopleQuestions, keyTerms, highlight_keyTerm } =
        useContext(ReportContext);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("All");
    const [list, setList] = useState([]);
    const { mpTrackCruiseAddition } = useMixpanelHook();
    const dispatchStepsData = useContext(CruiseContext);
    const { reportInfo } = useContext(ReportContext);
    const ref = useRef();

    const addFaqOutline = (text) => {
        const outline = new Outline({
            tag: Outline.tags.question,
            type: Outline.type.question,
            data: text,
            id: v4(),
        });

        dispatchStepsData({
            type: CRUISE_TYPES.ADD_OUTLINES,
            field: Outline.type.question,
            data: outline,
        });
    };
    const { outlines } = useContext(CruiseState);
    let [outlineHashMap, setOutlineHashMap] = useState(new Map());
    useEffect(() => {
        let outlineHashMap = new Map();
        outlines.questions.forEach((question) => {
            outlineHashMap.set(question?.data);
        });
        setOutlineHashMap(outlineHashMap);
    }, [outlines.questions]);

    useEffect(() => {
        setList(() => {
            let temp = peopleQuestions?.filter((item) => {
                if (filter == "All") {
                    if (search)
                        return item.question
                            ?.toLowerCase()
                            .includes(search.toLowerCase());
                    else return true;
                }

                return (
                    item.platform == filter &&
                    item.question?.toLowerCase().includes(search.toLowerCase())
                );
            });

            if (filter == "All") {
                let serpArr = temp.filter(
                    (item) => item.platform == arr[2].value,
                );
                let goggleArr = temp.filter(
                    (item) => item.platform == arr[1].value,
                );
                temp = temp.filter((item) => item.platform != arr[1].value);
                temp = temp.filter((item) => item.platform != arr[2].value);
                temp = [...goggleArr, ...serpArr, ...temp];
            }

            return temp;
        });
    }, [filter, peopleQuestions, search]);

    useEffect(() => {
        if (search.trim()) {
            setFilter("All");
        }
    }, [search]);

    const arr = [
        {
            value: "All",
            text: "All Questions",
            iconSrc: "VscGlobe.svg",
            color: "#79797a",
        },

        {
            value: "Google",
            text: "Questions frequently asked on Google",
            iconSrc: "FcGoogle.svg",
            color: "",
        },
        {
            value: "Serp",
            text: "SERP Questions",
            iconSrc: "serp.svg",
            color: "#014dc5",
        },
        {
            value: "Quora",
            text: "Quora",
            iconSrc: "FaQuora.svg",
            color: "#be4142",
        },
        {
            value: "Reddit",
            text: "Reddit",
            iconSrc: "SiReddit.svg",
            color: "#f04c2b",
        },
        {
            value: "Generate",
            text: "AI Suggestions",
            iconSrc: "SiProbot.svg",
            color: "#014dc5",
        },
    ];

    function handleIcon(platform) {
        switch (platform) {
            case "Quora":
                return arr[3];
            case "Google":
                return arr[1];
            case "Reddit":
                return arr[4];
            case "Serp":
                return arr[2];
            default:
                return arr[5];
        }
    }
    function handleColor(platform) {
        switch (platform) {
            case "Quora":
                return arr[1].color;
            case "Google":
                return arr[2].color;

            case "Reddit":
                return arr[3].color;
            default:
                return arr[4].color;
        }
    }
    const obj = useMemo(() => new Mark(ref.current), [ref.current]);
    useEffect(() => {
        if (search) {
            setImmediate(() => {
                obj.mark([search]);
            });
        } else if (highlight_keyTerm) {
            keyTerms
                .sort((a, b) => b?.keyword?.length - a?.keyword?.length)
                .forEach((term) => {
                    setImmediate(() => {
                        obj.mark(term.keyword, {
                            separateWordSearch: false,
                            accuracy: {
                                value: "exactly",
                                limiters: [
                                    ",",
                                    "?",
                                    ".",
                                    '"',
                                    "'",
                                    "!",
                                    ")",
                                    "(",
                                    "{",
                                    "}",
                                    "[",
                                    "]",
                                    ":",
                                    ";",
                                    "-",
                                ],
                            },
                        });
                    });
                });
        } else {
            obj.unmark();
        }
        return () => {
            obj.unmark();
        };
    }, [highlight_keyTerm, search, list]);
    let timeoutRef = useRef(null);

    return (
        <>
            <div className={styles2.rangeOptions}>
                <div className={styles.allIcon}>
                    {arr.map((item) => {
                        return (
                            <span
                                className={
                                    styles.SingleIcon +
                                    " " +
                                    (filter == item.value &&
                                        styles.activeFilter)
                                }
                                onClick={() => setFilter(item.value)}
                            >
                                <SVGIcon
                                    src={`/New UI/SVG/QuestionSources/${item.iconSrc}`}
                                    style={{ color: item.color }}
                                />
                            </span>
                        );
                    })}
                </div>
            </div>
            <div className={styles2.searchOutlines_wrapper}>
                <Textfield
                    customClass={styles2.searchOutlines}
                    height={32}
                    label={""}
                    placeholder={"Search here..."}
                    containerStyle={{
                        padding: "0px 10px 5px 10px",
                        background: "white",
                    }}
                    props={{
                        onChange: (e) => {
                            const value = e.target.value.trim();
                            debounceWrapper(
                                () => setSearch(value),
                                500,
                                timeoutRef,
                            );
                        },
                    }}
                    Icon={() => (
                        <SVGIcon
                            src={"/New UI/SVG/AiOutlineSearch.svg"}
                            size={14}
                            style={{ color: "#c9e3ff" }}
                        />
                    )}
                    iconStyle={{
                        left: "10px",
                        color: "#c9e3ff",
                        width: "min-content",
                    }}
                />
            </div>
            <div
                ref={ref}
                className={`${styles.questionContainer} ${
                    search ? styles.questionContainerSearch : ""
                } `}
            >
                <div className={styles.questionContainer__que}>
                    {list?.length
                        ? list.map((item) => {
                              return (
                                  <Item
                                      {...{
                                          item,
                                          addFaqOutline,
                                          mpTrackCruiseAddition,
                                          outlineHashMap,
                                          handleIcon,
                                      }}
                                  />
                              );
                          })
                        : search && <NoResults />}
                </div>
            </div>
        </>
    );
};

const Item = ({
    item,
    addFaqOutline,
    mpTrackCruiseAddition,
    outlineHashMap,
    handleIcon,
}) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                addFaqOutline(item?.question?.trim());
                mpTrackCruiseAddition({
                    type: "FAQ",
                    origin: "Competition",
                    platform: item.platform,
                });
            }}
            className={styles.questionRow}
        >
            <div className={styles.left}>
                <span className={styles.icon}>
                    {outlineHashMap.has(item.question) ? (
                        <span className={styles.added}>
                            <SVGIcon
                                size={14}
                                tooltip={"Added to brief"}
                                src={"/New UI/SVG/check.svg"}
                            />
                        </span>
                    ) : (
                        ""
                    )}
                    <SVGIcon
                        tooltip={handleIcon(item.platform).text}
                        style={{
                            color: handleIcon(item.platform).color,
                        }}
                        src={`/New UI/SVG/QuestionSources/${
                            handleIcon(item.platform).iconSrc
                        }`}
                    />
                </span>
                <span className={styles.questionText}>{item.question}</span>
            </div>
            <div
                className={styles.addIcon}
                onClick={(e) => {
                    e.stopPropagation();
                    addFaqOutline(item?.question?.trim());
                }}
            >
                <SVGIcon src={"/New UI/SVG/arrowLeft.svg"} />
            </div>
        </div>
    );
};

export default PlaceholderFAQ;
