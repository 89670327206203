import React, { useCallback, useContext, useEffect, useState } from "react";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import Button from "../../atoms/Button/Button";

import styles from "./styles.module.css";
import { FcGoogle } from "react-icons/fc";
import VideoModal from "../NewDashboard/VideoModal";
import { BsArrowRight } from "react-icons/bs";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    FormControlLabel,
    Chip,
    InputAdornment,
    TextField,
    makeStyles,
} from "@material-ui/core";
import { MdCheckCircleOutline } from "react-icons/md";
import { GoCheckCircle } from "react-icons/go";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import {
    BiCheck,
    BiChevronDown,
    BiChevronRight,
    BiSearch,
} from "react-icons/bi";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import { ChevronRight } from "@material-ui/icons";
import SVGIcon from "../../atoms/SVGIcon";
import useHelperFunctions from "../../helperFunctions";
import { webtuneEndPoints } from "./constants";
import { socket } from "../../../Sockets/useSockets";
import { socketEvents } from "../../../Sockets/constants";
import { WebtuneContext } from "./WebTune";
import { HiOutlineDocumentText } from "react-icons/hi";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import { CollapsibleDropDown } from "../GoogleSearchConsole/GscLoginFlow";
import { FaCircleCheck } from "react-icons/fa6";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import useGscHook from "../GoogleSearchConsole/useGscHook";
import { useSelector } from "react-redux";
import { TRAIL_INTERLINKING_LIMIT } from "../InterLinking/constants";

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
        },
        color: "#ACB3BF",
        marginRight: "-10px",
    },
    active: {
        color: "#014DC5",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

const FormControlLabelCustom = makeStyles((theme) => ({
    root: {
        padding: "0px 2px 0px 10px",
    },
}));

const subdomainCollection = [
    {
        id: 1,
        subDomain: "https://www.scalenut.com",
        directories: [
            {
                //pages ids
                pages: [1, 2, 3, 4, 5, 4, 6, 7, 8, 5],
                path: "/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/blogs/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/solutions/",
            },
        ],
        isHomepage: true,
    },
    {
        id: 2,
        subDomain: "https://www.scalenut.com",
        directories: [
            {
                //pages ids
                pages: [1, 2, 3, 4, 5, 4, 6, 7, 8, 5],
                path: "/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/blogs/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/solutions/",
            },
        ],
    },
    {
        id: 3,
        subDomain: "https://www.scalenut.com",
        directories: [
            {
                //pages ids
                pages: [1, 2, 3, 4, 5, 4, 6, 7, 8, 5],
                path: "/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/blogs/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/solutions/",
            },
        ],
    },
    {
        id: 4,
        subDomain: "https://www.scalenut.com",
        directories: [
            {
                //pages ids
                pages: [1, 2, 3, 4, 5, 4, 6, 7, 8, 5],
                path: "/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/blogs/",
            },
            {
                //pages ids
                pages: [77, 88, 22, 5656, 66, 44, 11, 22],
                path: "/solutions/",
            },
        ],
        isHomepage: true,
    },
];

const useAutoCompleteStyle = makeStyles(() => {
    return {
        root: {
            borderRadius: 5,
            width: "100%",
            padding: "2px 10px",
            background: "#f9f9f9",
            "& input": {
                fontSize: 14,
                padding: 10,
            },
        },
    };
});

const useAccordionCompleteStyle = makeStyles(() => {
    return {
        root: {
            backgroundColor: "none",
            boxShadow: "none",
            padding: 0,
            margin: "0 !important",
            "& .MuiAccordionSummary-root": {
                paddingInline: "0 5px",
                minHeight: 40,
                borderBottom: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: "5px 0",
                alignItems: "center",
            },
            "& .MuiAccordionDetails-root": {
                padding: "0",
            },
        },
    };
});

function LoginWebTune({ setIsDomainConnected }) {
    const {
        isDomainConnected,
        status,
        is_verified,
        is_connected,
        setDomainInfo,
        total_pages,
    } = useContext(WebtuneContext);
    const [isAdded, setIsAdded] = useState(is_verified == 1);
    const [isDomainVerified, setIsDomainVerified] = useState(false);
    const [siteMapChecked, setSiteMapChecked] = useState(false);
    const [adding, setAdding] = useState(false);
    const [scanningPages, setScanningPages] = useState(status == 0);
    const [domainName, setDomain] = useState("");
    const [sitemapUrl, setSitemapUrl] = useState("");
    const [pages, setPages] = useState(0);
    const [subPages, setSubPages] = useState([]);
    const classes = useAutoCompleteStyle();
    const [search, setSearch] = useState("");
    const [isLoadingPages, setIsLoadingPages] = useState(false);
    const [isSubPageSelected, setIsSubPageSelected] = useState(false);
    const [totalSelectedPages, setTotalSelectedPages] = useState(total_pages);
    const [totalCrawledPages, setTotalCrawledPages] = useState(0);
    const [subdomainSelection, setSubdomainSelection] =
        useState(subdomainCollection);

    const { postData } = useHelperFunctions();

    const logoutReq = async () => {
        const res = await postData({
            url: webtuneEndPoints.logout,
            payload: {},
        });
        if (res.status == 200) {
            setDomainInfo((ps) => ({
                ...ps,
                is_verified: 0,
                total_pages: 0,
                is_connected: 0,
            }));
            setIsDomainConnected(false);
        }
    };

    const filterSubDomains = (item) => {
        if (!search.length) return true;
        else {
            const subDomain = item?.subDomain?.toLowerCase();
            const searchQuery = search.toLowerCase();

            const filteredPages = item?.pages?.filter((page) => {
                const subPage = page?.subPage?.toLowerCase();
                return subPage.includes(searchQuery); // Check if subPage includes the search query
            });

            return (
                subDomain.includes(searchQuery) ||
                (filteredPages && filteredPages.length > 0)
            );
        }
    };

    const _subPages = [...subPages?.filter(filterSubDomains)];

    useEffect(() => {
        let count = 0;
        subPages.forEach((subDomain) => {
            subDomain.pages.forEach((page) => {
                if (page.is_selected == 1) {
                    count += page.count;
                }
            });
        });
        setTotalSelectedPages(count > 0 ? count : total_pages);
    }, [subPages]);

    useEffect(() => {
        setTotalSelectedPages(total_pages);
    }, [total_pages]);

    const isValidDomain = () => {
        const regex = new RegExp(
            /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        );
        return regex.test(domainName);
    };
    const isValidSitemapUrl = () => {
        const regex = new RegExp(
            /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        );
        return regex.test(sitemapUrl);
    };

    const handleParentSubDomain = ({ value, pages = [] }) => {
        let status = 1;
        let selectedCount = 0;
        pages.forEach((page) => {
            if (page.is_selected == 1) {
                selectedCount = selectedCount + 1;
            }
        });
        if (selectedCount == 0) {
            status = 1;
        } else if (selectedCount == 1) {
            status = value ? 1 : 0;
        }

        return status;
    };

    const handleSubDomainPages = ({
        subDomainIndex,
        pageIndex = -1,
        value,
    }) => {
        setSubPages((ps) => {
            let temp = [...ps];
            if (subDomainIndex == -1 && pageIndex == -1) {
                return;
            }

            if (pageIndex == -1) {
                //subDomain
                temp = temp.map((subDomain) => {
                    if (subDomain.subDomainIdx == subDomainIndex) {
                        return {
                            ...subDomain,
                            is_selected: value ? 1 : 0,
                            pages: subDomain.pages.map((item) => ({
                                ...item,
                                is_selected: value ? 1 : 0,
                            })),
                        };
                    } else {
                        return subDomain;
                    }
                });
            } else {
                temp = temp.map((subDomain) => {
                    if (subDomain.subDomainIdx == subDomainIndex) {
                        return {
                            ...subDomain,
                            is_selected: handleParentSubDomain({
                                value: value,
                                pages: subDomain.pages,
                            }),
                            pages: subDomain.pages.map((item) => {
                                if (item.subPageIdx == pageIndex) {
                                    return {
                                        ...item,
                                        is_selected: value ? 1 : 0,
                                    };
                                } else return item;
                            }),
                        };
                    } else {
                        return subDomain;
                    }
                });
            }
            if (temp.length) {
                return temp;
            } else {
                return [];
            }
        });
    };

    const crawlDomains = async () => {
        setTotalCrawledPages(0);
        setIsLoadingPages(true);
        const res = await postData({
            url: webtuneEndPoints.crawlDomain,
            payload: {},
        });
        if (res.status == 200) {
            setSubPages(() => {
                let temp = [...res.data.data];
                let totalPages = 0;
                for (let i = 0; i < temp?.length; i++) {
                    totalPages += temp[i].count;
                    temp[i] = {
                        ...temp[i],
                        subDomainIdx: i + 1,
                        pages: temp[i].pages
                            .map((item, idx) => ({
                                ...item,
                                subPageIdx: idx + 1,
                            }))
                            .sort((a, b) => a.subPage.localeCompare(b.subPage)),
                    };
                }
                setTotalCrawledPages(totalPages);

                return temp;
            });
        } else {
            logoutReq();
            setIsDomainVerified(false);
        }
        setIsLoadingPages(false);
    };

    const getSelectedPages = () => {
        let selectedPages = [];
        subdomainSelection.forEach((subDomain) => {
            selectedPages = selectedPages.concat(
                ...subDomain.directories
                    .filter((dir) => dir.checked)
                    .map((dir) => dir.pages),
            );
        });
        console.log(selectedPages);
        return selectedPages;
    };

    const addDomainRequest = async () => {
        setAdding(true);
        const res = await postData({
            url: webtuneEndPoints.addDomain,
            payload: {
                domain: domainName,
            },
        });
        setAdding(false);
        if (res.status == 200) {
            // setIsAdded(true);
            setIsDomainVerified(true);
            setIsLoadingPages(true);
            crawlDomains();
            setDomainInfo((ps) => ({ domain: domainName, ...ps }));
        } else {
            setSiteMapChecked(false);
        }
    };
    const scrapeSuccess = useCallback((obj) => {
        setScanningPages(false);
        setIsDomainConnected(true);
    }, []);
    const scrapeProcess = useCallback((obj) => {
        setPages(obj.count);
    }, []);
    const scrapeFail = useCallback((obj) => {
        setScanningPages(false);
        setIsDomainConnected(true);
    }, []);
    const attachSockets = useCallback(() => {
        socket.on(socketEvents.WEBTUNE_SCRAPE_SUCCESS, scrapeSuccess);
        socket.on(socketEvents.WEBTUNE_SCRAPE_PROCESS, scrapeProcess);
        socket.on(socketEvents.WEBTUNE_SCRAPE_FAIL, scrapeFail);
    }, []);

    const removeSockets = useCallback(() => {
        socket.off(socketEvents.WEBTUNE_SCRAPE_SUCCESS, scrapeSuccess);
        socket.off(socketEvents.WEBTUNE_SCRAPE_PROCESS, scrapeProcess);
        socket.off(socketEvents.WEBTUNE_SCRAPE_FAIL, scrapeFail);
    }, []);

    const findIssues = async () => {
        setScanningPages(true);
        const res = await postData({
            url: webtuneEndPoints.findIssues,
            payload: {
                domain: domainName,
                data: subPages || [],
            },
        });

        if (res.status == 200) {
            setIsDomainVerified(false);
            attachSockets();
            setIsAdded(true);
        }
    };

    useEffect(() => {
        if (!isDomainConnected) {
            setIsDomainVerified(false);
            setIsAdded(false);
        }
    }, [isDomainConnected]);

    useEffect(() => {
        if (subPages.length > 0) {
            let selectionCount = 0;
            let totalCount = 0;
            subPages.map((item) => {
                totalCount = totalCount + 1;
                if (item.is_selected == 1) {
                    selectionCount = selectionCount + 1;
                }
                item.pages.map((subItem) => {
                    totalCount = totalCount + 1;
                    if (subItem.is_selected == 1) {
                        selectionCount = selectionCount + 1;
                    }
                });
            });
            if (selectionCount == 0) {
                setIsSubPageSelected(false);
            } else {
                setIsSubPageSelected(true);
            }
            // if (selectionCount == totalCount) {
            //     setIsAllSelected(true);
            // } else {
            //     setIsAllSelected(false);
            // }
        }
    }, [subPages]);

    useEffect(() => {
        if (is_connected) {
            if (status == 3) {
                setIsAdded(true);
                setScanningPages(true);
                attachSockets();
            } else if (status < 3) {
                setIsDomainVerified(true);
                crawlDomains();
            }
        }
        return () => removeSockets();
    }, []);

    return (
        <div className={styles.loginContainerWrapper}>
            <>
                {isDomainVerified ? (
                    <div className={styles.dropDowns}>
                        <div className={styles.head}>
                            <h2>Webtune AI</h2>
                            <p
                                className={styles.subHeading}
                                style={{ textAlign: "center", marginTop: 0 }}
                            >
                                Bypass the bottleneck and make simple,
                                reversible SEO changes blazingly fast.
                            </p>
                            {!isLoadingPages && (
                                <>
                                    <GoCheckCircle color="#2fc5a0" size={40} />
                                    <div className={styles.crawledPages}>
                                        Found{" "}
                                        <span
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            {totalCrawledPages} total pages
                                        </span>{" "}
                                        in{" "}
                                        <span
                                            style={{
                                                color: "#014dc5",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {domainName}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={styles.pageSelectionContainer}>
                            <div className={styles.pageSelectionTagline}>
                                Select pages to optimize
                            </div>
                            <div className={styles.subPagesInputWrapper2}>
                                <Textfield
                                    placeholder="Search"
                                    label={""}
                                    props={{
                                        value: search,
                                        onChange: (e) =>
                                            setSearch(e.target.value),
                                        style: {
                                            borderRadius: 3,
                                            border: `solid 1px ${"rgba(75, 75, 85, 0.16)"}`,
                                            backgroundColor: "#ffffff",
                                            paddingLeft: "40px",
                                        },
                                    }}
                                    Icon={() => (
                                        <SVGIcon
                                            src={"/New UI/SVG/searchIcon.svg"}
                                        />
                                    )}
                                    iconStyle={{
                                        left: "10px",
                                        right: "unset",
                                    }}
                                />
                            </div>
                            <div className={styles.collapsibleWrapper}>
                                {isLoadingPages ? (
                                    <div
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : _subPages.length > 0 ? (
                                    _subPages.map((subDomain) => {
                                        return (
                                            <CollapsibleDropDown
                                                subDomain={subDomain}
                                                handleSubDomainPages={
                                                    handleSubDomainPages
                                                }
                                                index={subDomain.subDomainIdx}
                                                search={search}
                                                defaultOpen={true}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className={styles.noItems}>
                                        <SVGIcon
                                            size={180}
                                            src={
                                                "/New UI/SVG/Emptydocument.svg"
                                            }
                                        />
                                        <h3 className={styles.text}>
                                            No Pages Found
                                        </h3>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.bottomCta}>
                            <span
                                onClick={() => {
                                    setIsDomainVerified(false);
                                    logoutReq();
                                }}
                                className={styles.cancelPageSelection}
                            >
                                Back
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {totalSelectedPages > 0 ? (
                                    <>
                                        <span
                                            style={{
                                                marginRight: 0,
                                                color:
                                                    totalSelectedPages > 1000
                                                        ? "red"
                                                        : "",
                                            }}
                                            className={styles.selectedPages}
                                        >
                                            {totalSelectedPages}
                                        </span>
                                        <span className={styles.selectedPages}>
                                            /1000 pages selected
                                        </span>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <Button
                                    handler={() => findIssues()}
                                    disabled={!isSubPageSelected}
                                    width={"120px"}
                                    height={40}
                                    style={{
                                        background: "#014DC5",
                                        boxShadow:
                                            "0px 2px 8px rgba(0, 0, 0, 0.03)",
                                        borderRadius: "3px",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                    }}
                                    text={"Let's Go"}
                                    loading={scanningPages}
                                    Icon={BsArrowRight}
                                />
                            </div>
                        </div>
                    </div>
                ) : isAdded ? (
                    <ProcessingPagesModal
                        scanningPages={scanningPages}
                        pages={pages}
                        domainName={domainName}
                        setIsDomainConnected={setIsDomainConnected}
                        totalSelectedPages={totalSelectedPages}
                    />
                ) : (
                    <div className={styles.loginContainer}>
                        <h2 style={{ marginBottom: 10 }}>
                            {!isAdded ? (
                                <>Welcome to Webtune! 👋</>
                            ) : (
                                <>Webtune AI</>
                            )}
                        </h2>
                        <p style={{ textAlign: "center", marginTop: 0 }}>
                            Bypass the bottleneck and make simple, reversible
                            SEO changes
                            <br />
                            blazingly fast.
                        </p>

                        <div className={styles.videoContainer}>
                            <VideoModal
                                link={
                                    "https://www.youtube.com/embed/xK7iKtip2Go"
                                }
                                customClass={styles.videoWrapper}
                            />
                        </div>

                        <div className={styles.formContainer}>
                            <h4 style={{ marginTop: 0 }}>
                                Let's start with analyzing your domain
                            </h4>
                            <Textfield
                                value={domainName}
                                onChange={(e) => {
                                    setDomainInfo((ps) => ({
                                        ...ps,
                                        domain: e.target.value.trim(),
                                    }));
                                    setDomain(e.target.value.trim());
                                }}
                                height={34}
                                label=""
                                errMsg={
                                    !!domainName &&
                                    (isValidDomain()
                                        ? ""
                                        : "Enter valid domain")
                                }
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        e.stopPropagation();
                                        addDomainRequest();
                                    }
                                }}
                                customClass={styles.domainInput}
                                placeholder="Enter your domain"
                            />
                            {/* <div
                                style={{
                                    width: "100%",
                                    paddingInline: 2.5,
                                }}
                            >
                                <CheckBox
                                    handler={(e) =>
                                        setSiteMapChecked(e.target.checked)
                                    }
                                    label={"Use Sitemap.xml to import pages"}
                                />
                            </div> */}

                            <div style={{ marginBottom: 5 }}></div>
                            {siteMapChecked && (
                                <div className={styles.sitemapFieldWrapper}>
                                    <p>
                                        Unable to find sitemap for the provided
                                        domain. Please enter manually
                                    </p>
                                    <div className={styles.sitemapField}>
                                        <div
                                            className={styles.sitemapFieldLabel}
                                        >
                                            Sitemap URL
                                        </div>
                                        <Textfield
                                            label=""
                                            height={34}
                                            placeholder="https://scalenut.com/sitemap.xml"
                                            containerStyle={{ marginBottom: 0 }}
                                            customClass={
                                                styles.sitemapFieldInput
                                            }
                                            value={sitemapUrl}
                                            onChange={(e) =>
                                                setSitemapUrl(
                                                    e.target.value.trim(),
                                                )
                                            }
                                            errMsg={
                                                !!sitemapUrl &&
                                                (isValidSitemapUrl()
                                                    ? ""
                                                    : "Enter valid url")
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                            <div style={{ marginBottom: 7.5 }}></div>
                            <Button
                                width={180}
                                text="Get Started"
                                Icon={BsArrowRight}
                                disabled={!isValidDomain()}
                                handler={addDomainRequest}
                                loading={adding}
                                style={{
                                    fontWeight: 400,
                                }}
                            />
                            {/* <div className={styles.divider}>
                        <span>OR</span>
                    </div>

                    <Button
                        handler={() => {}}
                        height={40}
                        Icon={() => (
                            <div className={styles.iconWrapper}>
                                <FcGoogle />
                            </div>
                        )}
                        secondary={true}
                        text={"Connect Google Search Console"}
                        iconReverse={true}
                        width={"100%"}
                        customClass={styles.customGoogleLogin}
                        fontSize={14}
                    /> */}
                        </div>
                    </div>
                )}

                {/* 
                {isAdded ? (
                    <>
                        <div className={styles.pages}>
                            <div className={styles.search}>
                                <h4>Select pages to optimize</h4>
                                <Autocomplete
                                    classes={classes}
                                    options={["hello"]}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search"
                                        />
                                    )}
                                />
                            </div>
                            {subdomainSelection.map((subDomain, i) => (
                                <SubDomain
                                    subDomain={subDomain}
                                    setSubdomainSelection={
                                        setSubdomainSelection
                                    }
                                    key={subDomain.id}
                                    index={i}
                                />
                            ))}
                        </div>
                        <div className={styles.selectedPagesCountWrapper}>
                            <span>
                                {getSelectedPages().length} Pages selected
                            </span>

                            <Button
                                text="Let's go"
                                Icon={BsArrowRight}
                                width={150}
                                disabled={!getSelectedPages().length}
                                style={{ marginLeft: 20 }}
                            ></Button>
                        </div>
                    </>
                ) : (
                    <>
                    
                    </>
                )} */}
            </>
        </div>
    );
}

const SubDomain = ({ subDomain, setSubdomainSelection, index }) => {
    const [open, setOpen] = useState(false);
    const classes = useAccordionCompleteStyle();

    const MetaTag = ({ info }) => <span className={styles.meta}>{info}</span>;

    const setSubdomain = (checked) => {
        setSubdomainSelection((ps) => {
            ps[index] = {
                ...ps[index],
                checked,
                directories: ps[index].directories.map((directory) => ({
                    ...directory,
                    checked,
                })),
            };

            return [...ps];
        });
    };

    const setDirectory = (checked, dirIndex) => {
        setSubdomainSelection((ps) => {
            ps[index].directories[dirIndex] = {
                ...subDomain.directories[dirIndex],
                checked,
            };

            ps[index] = {
                ...ps[index],
                directories: [...ps[index].directories],
                checked: ps[index].directories.find((dir) => dir.checked)
                    ? true
                    : false,
            };

            return [...ps];
        });
    };

    return (
        <>
            <Accordion onChange={(e, open) => setOpen(open)} classes={classes}>
                <AccordionSummary style={{ cursor: "pointer" }}>
                    {!open ? (
                        <BiChevronRight size={20} />
                    ) : (
                        <BiChevronDown size={20} />
                    )}
                    &nbsp;
                    <div className={styles.subDomainName}>
                        <CheckBox
                            handler={(e) => {
                                setSubdomain(e.target.checked);
                                e.stopPropagation();
                            }}
                            label={subDomain.subDomain}
                            props={{ checked: subDomain.checked }}
                        />
                        <MetaTag
                            info={subDomain.isHomepage ? `Homepage` : "Root"}
                        />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.directories}>
                        {subDomain.directories.map((directory, dirIndex) => {
                            return (
                                <div>
                                    <CheckBox
                                        props={{ checked: directory.checked }}
                                        handler={(e) =>
                                            setDirectory(
                                                e.target.checked,
                                                dirIndex,
                                            )
                                        }
                                        label={directory.path}
                                    />
                                    <MetaTag
                                        info={`${directory.pages.length} pages`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export const NavigatingText = ({ onEnd, text }) => {
    const [secs, setSecs] = useState(3);

    useEffect(() => {
        setInterval(() => {
            setSecs((s) => s - 1);
        }, 1000);
    }, []);

    useEffect(() => {
        if (secs == 0) {
            onEnd(true);
        }
    }, [secs]);

    return (
        <span className={styles.navigatingTxt}>
            {text} {secs} secs...
        </span>
    );
};

export const ProcessingPagesModal = ({
    scanningPages,
    pages,
    domainName,
    totalCrawledPages,
    isLoadingPages,
    domain,
    stable_version,
    findingOpportunities,
}) => {
    const getEstimate = () => {
        const pages =
            totalCrawledPages.limit > totalCrawledPages.crawled
                ? totalCrawledPages.crawled
                : totalCrawledPages.limit;

        if (pages <= 500) return 10;
        else if (pages <= 1000) return 15;
        else return 20;
    };

    const { getTrimmedDomainURL } = useGscHook();

    const otherInfo = useSelector((state) => state.otherInfo);

    const trailUserHavingMorePages =
        otherInfo?.subscriptionInfo?.interlinking_status === 2 &&
        totalCrawledPages.crawled > TRAIL_INTERLINKING_LIMIT;

    return (
        <div className={styles.processingPagesModal}>
            <div className={styles.header}>
                <h2>
                    {isLoadingPages
                        ? "Crawling Domain..."
                        : !isLoadingPages && !scanningPages
                        ? stable_version
                            ? "Refreshing Issues..."
                            : "Finishing Up..."
                        : "Scanning Pages..."}
                </h2>
                <div className={styles.domainInfo}>
                    <div>
                        <img
                            src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                domainName
                                    ? getTrimmedDomainURL(domainName)
                                    : getTrimmedDomainURL(domain)
                            }&sz=32`}
                            alt=""
                            height={16}
                        />

                        {domainName
                            ? getTrimmedDomainURL(domainName)
                            : getTrimmedDomainURL(domain)}
                    </div>
                </div>
            </div>

            <section style={{ color: "#1f4cbe" }}>
                <div>
                    {isLoadingPages ? (
                        <>
                            <div>
                                <CircularProgress color="inherit" size={24} />
                            </div>
                            <span>
                                Please wait while we find pages in your
                                domain...
                            </span>
                        </>
                    ) : (
                        <>
                            <FaCircleCheck color="#1DC198" size={24} />
                            <span style={{ color: "#3f5575" }}>
                                We found {totalCrawledPages.crawled} pages in
                                your domain.
                            </span>
                        </>
                    )}
                </div>

                <div>
                    {!isLoadingPages && !scanningPages ? (
                        <>
                            <FaCircleCheck color="#1DC198" size={24} />
                            <span style={{ color: "#3f5575" }}>
                                Successfully processed{" "}
                                {trailUserHavingMorePages ? 100 : pages} pages.
                            </span>
                        </>
                    ) : scanningPages && !isLoadingPages ? (
                        <>
                            <div>
                                <CircularProgress color="inherit" size={24} />
                            </div>
                            {trailUserHavingMorePages ? (
                                <>
                                    Analyzed
                                    <b>
                                        {pages ? (
                                            <>
                                                &nbsp;
                                                {Math.round(
                                                    (pages /
                                                        (totalCrawledPages.limit >
                                                        totalCrawledPages.crawled
                                                            ? totalCrawledPages.crawled
                                                            : totalCrawledPages.limit)) *
                                                        100,
                                                )}
                                                &nbsp;/&nbsp;100&nbsp;
                                            </>
                                        ) : (
                                            <Skeleton
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginInline: 5,
                                                }}
                                            />
                                        )}
                                    </b>
                                    pages...
                                </>
                            ) : (
                                <>
                                    Analyzed
                                    <b>
                                        {pages ? (
                                            <>
                                                &nbsp;{pages}&nbsp;/&nbsp;
                                                {totalCrawledPages.limit >
                                                totalCrawledPages.crawled
                                                    ? totalCrawledPages.crawled
                                                    : totalCrawledPages.limit}
                                                &nbsp;
                                            </>
                                        ) : (
                                            <Skeleton
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginInline: 5,
                                                }}
                                            />
                                        )}
                                    </b>
                                    pages...
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div>
                                <CircularProgress
                                    size={24}
                                    value={100}
                                    variant="determinate"
                                    style={{ color: "lightgray" }}
                                />
                            </div>
                            <span style={{ color: "lightgray" }}>
                                Scanning not started yet.
                            </span>
                        </>
                    )}
                </div>
                <div>
                    {findingOpportunities ? (
                        <>
                            <div>
                                <CircularProgress color="inherit" size={24} />
                            </div>

                            {!!totalCrawledPages.processPercent ? (
                                <span>
                                    Finding opportunities{" "}
                                    {totalCrawledPages.processPercent}%{" "}
                                    completed
                                </span>
                            ) : (
                                <span>
                                    Finding Internal linking opportunities...
                                </span>
                            )}
                        </>
                    ) : (
                        <>
                            <div>
                                <CircularProgress
                                    size={24}
                                    value={100}
                                    variant="determinate"
                                    style={{ color: "lightgray" }}
                                />
                            </div>
                            <span style={{ color: "lightgray" }}>
                                Waiting to find internal linking opportunities.
                            </span>
                        </>
                    )}
                </div>
            </section>

            {totalCrawledPages.limit < totalCrawledPages.crawled && (
                <div className={styles.planUpgrade}>
                    <AiOutlineExclamationCircle size={24} />

                    <div>
                        We are processing{" "}
                        {trailUserHavingMorePages
                            ? 100
                            : totalCrawledPages.limit}{" "}
                        out of {totalCrawledPages.crawled} found pages.
                    </div>
                </div>
            )}

            <div className={styles.navigatingTxt}>
                <span></span>
                {!!totalCrawledPages.limit && (
                    <div style={{ textAlign: "right" }}>
                        This may take upto {getEstimate()} mins... <br /> We
                        will email you when your domain is processed.
                    </div>
                )}
            </div>
        </div>
    );
};
export default LoginWebTune;
