import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import { useRef } from "react";
import Button from "../../../atoms/Button/Button";
import useOnClickOutside from "./useOn";
import { ClickAwayListener } from "@material-ui/core";
import { displayAlert } from "../../../../store/actions/alert";
import { countWords } from "../../../utlities";
import { getSelectedText } from "./MiniTool";
import { useDispatch } from "react-redux";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import { HiOutlinePencil } from "react-icons/hi";
import { VscClose } from "react-icons/vsc";
import SVGIcon from "../../../atoms/SVGIcon";

export const items = [
    {
        text: "Expand",
        useCase: 223,
        icon: "3",
        title: "Text Expander",
        resultTitle: "Expanded Text",
        loadingTextData: "Expanding Text",
    },
    {
        text: "Shorten",
        useCase: 224,
        icon: "4",
        title: "Text Shortener",
        resultTitle: "Shortened Text",
        loadingTextData: "Shortening Text",
    },
    {
        text: "Rephrase",
        useCase: 225,
        icon: "2",
        title: "Text Rephraser",
        resultTitle: "Rephrased Text",
        loadingTextData: "Rephrasing Text",
    },
    {
        text: "Simplify",
        useCase: 254,
        icon: "5",
        title: "Text Simplifier",
        resultTitle: "Simplified Text",
        loadingTextData: "Simplifying Text",
    },
    {
        text: "US to UK English",
        useCase: 305,
        icon: "8",
        title: "US to UK English converter",
        resultTitle: "Converted Text",
        loadingTextData: "Converting Text",
    },
    {
        text: "UK to US English",
        useCase: 306,
        icon: "8",
        title: "UK to US English converter",
        resultTitle: "Converted Text",
        loadingTextData: "Converting Text",
    },
];

const getPos = (value) => {
    const height = document.getElementById("editor-bound").offsetHeight;
    let top = 26;
    let bottom = "unset";

    if (value.top + 80 > height) {
        (top = "unset"), (bottom = 26);
    }

    return {
        bottom,
        top,
    };
};

function AiDropdown({
    selection,
    setDropdown,
    toolPos,
    setRephraseModal = () => {},
    customInput,
    setCustomInput,
    setIsCustomModule,
    customUseCase,
}) {
    const dispatch = useDispatch();
    const ref = useRef();

    const customInstructionHandler = (customInput) => {
        const text = getSelectedText(selection);
        if (countWords(text) > 200) {
            dispatch(
                displayAlert({
                    alertMessage:
                        "Please reduce the selected text to 200 words for expand, shorten and rephrase to work effectively.",
                    alertType: "error",
                }),
            );
            return;
        }

        if (customInput.trim().length == 0) {
            dispatch(
                displayAlert({
                    alertMessage: "Please Enter some instruction",
                    alertType: "error",
                }),
            );
            return;
        }

        setRephraseModal((ps) => ({
            isEnable: ++ps.isEnable,
            item: customUseCase,
        }));
    };

    useEffect(() => {
        if (ref?.current?.scrollIntoViewIfNeeded) {
            ref.current.scrollIntoViewIfNeeded();
        }
    }, [ref]);

    useEffect(() => {
        const callback1 = (e) => {
            customInstructionHandler(e.value);
        };
        addEventListener("iframeEnterKey", callback1);
        const callback2 = (e) => {
            setCustomInput(e.value);
        };
        addEventListener("iframeInputChange", callback2);
        return () => {
            removeEventListener("iframeEnterKey", callback1);
            removeEventListener("iframeInputChange", callback2);
        };
    }, []);

    const Iframe = useMemo(() => {
        return (
            <iframe
                className={styles.iframe}
                src={"/New UI/AICruiseFrame.htm"}
                autoFocus
                frameborder="0"
                onLoad={(e) => {
                    const input =
                        e?.target?.contentDocument?.getElementById("input");

                    if (input) {
                        input.value = customInput;
                    }
                }}
            ></iframe>
        );
    }, []);

    return (
        <ClickAwayListener onClickAway={() => setDropdown(false)}>
            <div
                style={
                    toolPos
                        ? {
                              width: `calc(100% - ${
                                  getComputedStyle(
                                      document?.quill?.editor?.root,
                                  ).paddingLeft
                              } - ${
                                  getComputedStyle(
                                      document?.quill?.editor?.root,
                                  ).paddingRight
                              })`,
                              marginLeft: getComputedStyle(
                                  document?.quill?.editor?.root,
                              ).paddingLeft,
                          }
                        : {}
                }
                className={styles.aiContainerWrapper}
            >
                <div className={styles.customInputWrapper}>
                    <HiOutlinePencil />
                    {Iframe}

                    <div
                        style={{
                            fontSize: 14,
                            marginRight: 5,
                            fontWeight: 300,
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            customInstructionHandler(customInput);
                        }}
                    >
                        ↩
                    </div>
                    {!!setIsCustomModule && (
                        <VscClose
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setIsCustomModule(false);
                            }}
                        />
                    )}
                </div>
                <div
                    onClick={(e) => e.stopPropagation()}
                    style={
                        toolPos
                            ? {
                                  top: getPos(toolPos).top,
                                  bottom: getPos(toolPos).bottom,
                              }
                            : { marginTop: 0 }
                    }
                    className={styles.aiContainer}
                    ref={ref}
                >
                    {items.map((item) => {
                        return (
                            <button
                                onClick={() => {
                                    const text = getSelectedText(selection);
                                    if (countWords(text) > 500) {
                                        dispatch(
                                            displayAlert({
                                                alertMessage:
                                                    "Please reduce the selected text to 200 words for expand, shorten and rephrase to work effectively.",
                                                alertType: "error",
                                            }),
                                        );
                                        return;
                                    }

                                    setRephraseModal((ps) => ({
                                        isEnable: ++ps.isEnable,
                                        item: item,
                                    }));
                                }}
                                key={item}
                            >
                                <div className={styles.iconWrapper}>
                                    {
                                        <SVGIcon
                                            src={`/New UI/SVG/RegenIcons/${item.icon}.svg`}
                                            size={14}
                                        />
                                    }
                                </div>
                                &nbsp;&nbsp;
                                {item.text}
                            </button>
                        );
                    })}
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default AiDropdown;
