import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import { LightTooltip } from "../Tooltip/tooltip";

function TextShortner({
    data,
    limit,
    tooltip = true,
    theme = "dark",
    arrow = true,
}) {
    const textShortnerFun = (data = "", limit) => {
        if (data.length <= limit) {
            return data;
        } else {
            return data.substring(0, limit) + "...";
        }
    };
    return (
        <>
            {tooltip ? (
                theme == "light" ? (
                    <LightTooltip
                        title={data.length <= limit ? "" : data}
                        placement="bottom"
                        arrow={arrow}
                    >
                        <span> {textShortnerFun(data, limit)}</span>
                    </LightTooltip>
                ) : (
                    <LightTooltip
                        title={data.length <= limit ? "" : data}
                        placement="bottom"
                        arrow={arrow}
                    >
                        <span> {textShortnerFun(data, limit)}</span>
                    </LightTooltip>
                )
            ) : (
                <div>{textShortnerFun(data, limit)}</div>
            )}
        </>
    );
}

export default TextShortner;
