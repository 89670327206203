import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useHistory, useParams } from "react-router-dom";
import GscLogin from "./GscLogin";
import styles from "./GoogleSearchConsole.module.css";
import useHelperFunctions from "../../helperFunctions";
import useGscHook, {
    DomainObject,
    SelectedDomain,
    TotalDeltaValues,
} from "./useGscHook";
import { displayAlert } from "../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import TrafficAnalyzer, { TabsName } from "./TrafficAnalyzer";
import {
    resetUserGscInfoMonthlyInsights,
    setUserGscInfo,
    updateCannibalizationFilters,
    updateDefaultCannibalizationFilters,
} from "../../../store/actions/userGscInfo";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import { PLANS_CATEGORIES } from "../../../constants";
import GscLogout from "../NewDashboard/GscLogout";
import GscReAuth from "./GscReAuth";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { useMixpanelTA } from "./useMixpanelTA";

const SUB_ANCHORS = {
    insights: {
        anchor: "#insights",
        tabName: "insights",
    },

    inventory: {
        anchor: "#inventory",
        tabName: "inventory",
    },
    cannibalization: {
        anchor: "#cannibalization",
        tabName: "cannibalization",
    },
};

const GoogleSearchConsole = ({ tabName }: { tabName: string }) => {
    const dispatch = useDispatch();
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [alertForTeamMember, setAlertForTeamMember] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [domains, setDomains] = useState<{ siteUrl: string }[] | []>([]);
    const [isFailed, setIsFailed] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [showGscLogout, setShowGscLogout] = useState(false);
    const history = useHistory();
    const { getBrandKeyword } = useGscHook();
    const { LockConfigurator, isTeamMember } = useUserAccessHook();
    const [value, setValue] = useState<number>(0);
    const { subscriptionInfo } = useSelector((state: any) => state.otherInfo);
    const { taTrackTabSwitch } = useMixpanelTA();
    const [selectedDomains, setSelectedDomains] = useState<DomainObject[] | []>(
        [],
    );
    const [activeDomain, setActiveDomain] = useState<DomainObject>();
    const { taTrackAccessed } = useMixpanelTA();
    useEffect(() => {
        // to set tab value
        if (subscriptionInfo.hasOwnProperty("plan_category")) {
            const containsMail = tabName?.includes("mail=true");
            let historyStack = [];
            try {
                historyStack = JSON.parse(
                    localStorage.getItem("history") || `[]`,
                );
            } catch (err) {
                console.log(err);
            }
            console.log("historyStack", historyStack);
            taTrackAccessed({
                source:
                    historyStack.length &&
                    historyStack[historyStack.length - 1]?.includes("dashboard")
                        ? "Dashboard"
                        : "Reload",
                fromMail: containsMail || false,
                fromFMC: false,
            });

            const cName = tabName.split("#")[1];
            switch (cName) {
                case SUB_ANCHORS.insights.tabName:
                    setValue(0);
                    break;
                case SUB_ANCHORS.inventory.tabName:
                    if (LockConfigurator(FEATURE_TYPES.inventoryLock)) {
                        setValue(0);
                    } else {
                        setValue(1);
                    }
                    break;
                case SUB_ANCHORS.cannibalization.tabName:
                    if (!LockConfigurator(FEATURE_TYPES.cannibalizationLock)) {
                        setValue(2);
                    } else {
                        setValue(0);
                    }
                    break;
                default:
                    setValue(0);
                    break;
            }
        }
    }, [subscriptionInfo.plan_category]);

    useEffect(() => {
        // to change url with anchor
        if (subscriptionInfo.hasOwnProperty("plan_category")) {
            switch (value) {
                case 0:
                    taTrackTabSwitch({ "Tab Name": TabsName.insights });

                    history.push(SUB_ANCHORS.insights.anchor);
                    break;
                case 1:
                    taTrackTabSwitch({ "Tab Name": TabsName.inventory });

                    history.push(SUB_ANCHORS.inventory.anchor);
                    break;
                case 2:
                    taTrackTabSwitch({ "Tab Name": TabsName.cannibalization });

                    history.push(SUB_ANCHORS.cannibalization.anchor);
                    break;
                default:
                    history.push(SUB_ANCHORS.insights.anchor);
            }
        }
    }, [value, subscriptionInfo.plan_category]);

    const [selectedDomain, setSelectedDomain] = useState<SelectedDomain>({
        siteUrl: "",
    });
    const { email } = useSelector((state: any) => state.userGscInfo);
    const [isFetchingRecommendations, setIsFetchingRecommendations] =
        useState<boolean>(false);
    const [isFetchingInsights, setIsFetchingInsights] =
        useState<boolean>(false);
    const [isShowData, setIsShowData] = useState<boolean>(false);
    const [totalDeltaValues, setTotalDeltaValues] =
        useState<TotalDeltaValues>(Object);
    const { postData, getData } = useHelperFunctions();
    useEffect(() => {
        setIsFetchingInsights(false);
        getDetails();
    }, []);
    const [tempGscId, setTempGscId] = useState(-1);

    const getDomainList: (isSetDefault: boolean) => Promise<""> = (
        isSetDefault,
    ) => {
        return new Promise((res1, rej) => {
            (async () => {
                try {
                    setInitialLoading(true);
                    const res = await getData({
                        url: `/api/analyse/getDomainList`,
                    });
                    if (res.status == 200) {
                        const data = res.data.domainList || [];
                        if (data.length == 0) {
                            setIsLoggedIn(false);
                            dispatch(
                                displayAlert({
                                    alertMessage:
                                        "no domains detected for this GSC account.",
                                    alertType: "error",
                                }),
                            );
                            rej();
                            return;
                        }
                        setTempGscId(res.data?.gsc_id || 0);

                        setDomains(
                            data.map((d: any) => ({
                                ...d,
                                used: 0,
                            })),
                        );

                        setInitialLoading(false);
                        res1("");
                        // if (data.length > 0)
                        //     setSelectedDomain((ps) => ({
                        //         ...ps,
                        //         siteUrl: data[0].siteUrl,
                        //         country: GSC_Countries[234].label,
                        //         flagCode: GSC_Countries[234].flagCode,
                        //         code: GSC_Countries[234].code,
                        //     }));
                    } else {
                        setIsLoggedIn(false);

                        setDomains([]);
                    }
                } catch (err) {
                    setIsLoggedIn(false);

                    rej();
                } finally {
                    rej();
                    setInitialLoading(false);
                }
            })();
        });
    };

    const getDetails: (isTA?: boolean) => void = async (isTA = false) => {
        setInitialLoading(true);
        setIsShowData(false);
        const res = await getData({ url: "/api/analyse/getGscDetails" });
        console.log(res);
        if (res.status == 200) {
            setShowGscLogout(false);
            if (res?.data?.resp?.gscInfo?.email) {
                if (res.data?.resp?.gscInfo?.is_connected != 1) {
                    // check whether google connected or not
                    setIsLoggedIn(false);
                    setInitialLoading(false);
                    return;
                } else {
                    setIsLoggedIn(true);
                    const brandKeyword = getBrandKeyword(
                        res.data?.resp?.domainInfo?.domain_name,
                    );
                    //set to show Cannibalization Tab

                    if (brandKeyword) {
                        dispatch(
                            updateCannibalizationFilters({
                                excluded_terms: [brandKeyword],
                            }),
                        );
                        dispatch(
                            updateDefaultCannibalizationFilters({
                                excluded_terms: [brandKeyword],
                            }),
                        );
                    }
                    if (res.data?.resp?.cannibalization_filters) {
                        dispatch(
                            updateCannibalizationFilters({
                                ...res.data?.resp?.cannibalization_filters,
                            }),
                        );
                    }
                    dispatch(
                        setUserGscInfo({
                            brandKeyword: brandKeyword,
                            email: res.data?.resp?.gscInfo?.email,
                        }),
                    );
                }
                setActiveStep(
                    res.data.resp?.gscInfo?.step
                        ? res.data.resp?.gscInfo?.step
                        : 2,
                );

                if (res.data.resp?.gscInfo?.step == 2) {
                    await getDomainList(true);
                }
                if (
                    isTeamMember &&
                    res.data?.resp?.domainInfo?.teamMember?.length > 0
                ) {
                    setActiveDomain(res.data?.resp?.domainInfo?.teamMember[0]);
                    setSelectedDomains(res.data?.resp?.domainInfo?.teamMember);
                }
                if (res.data.resp?.domainInfo?.domain_name) {
                    setSelectedDomain({
                        siteUrl: res.data.resp?.domainInfo.domain_name,
                    });
                    if (isTA) {
                        setActiveDomain({
                            domain_name: res.data.resp?.domainInfo.domain_name,
                            gsc_id: res.data.resp?.gscInfo?.gsc_id,
                            is_disconnect: 0,
                            is_selected: 1,
                            status: 1,
                        });
                    }

                    if (res.data.resp?.gscInfo?.step == 4) {
                        setIsShowData(true);
                    }
                } else {
                    await getDomainList(true);
                }
            } else {
                setIsLoggedIn(false);
            }
        } else if (res.status == 205) {
            dispatch(resetUserGscInfoMonthlyInsights({}));
            setShowGscLogout(true);
        } else if (+res?.response?.data?.code == 407) {
            setAlertForTeamMember(true);
        }
        setInitialLoading(false);
    };

    const handleLogoutGsc: () => void = async () => {
        const res = await postData({
            url: "/api/analyse/logoutGsc",
        });
        if (res.status == 200) {
        }
    };

    return showGscLogout ? (
        <GscReAuth getDetails={getDetails} />
    ) : (
        <div className={styles.gscContainer}>
            {!isLoggedIn || !isShowData ? (
                <GscLogin
                    setIsShowData={setIsShowData}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    initialLoading={initialLoading}
                    isLoggedIn={isLoggedIn}
                    setIsLoggedIn={setIsLoggedIn}
                    userInfo={{ email }}
                    domains={domains}
                    selectedDomain={selectedDomain}
                    setSelectedDomain={setSelectedDomain}
                    getDomainList={getDomainList}
                    isFetchingRecommendations={isFetchingRecommendations}
                    getDetails={getDetails}
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    alertForTeamMember={alertForTeamMember}
                    tempGscId={tempGscId}
                    setTempGscId={setTempGscId}
                />
            ) : (
                <TrafficAnalyzer
                    setIsShowData={setIsShowData}
                    isShowData={isShowData}
                    value={value}
                    setValue={setValue}
                    selectedDomains={selectedDomains}
                    setSelectedDomains={setSelectedDomains}
                    activeDomain={activeDomain}
                    setActiveDomain={setActiveDomain}
                    setIsLoggedIn={setIsLoggedIn}
                    setShowGscLogout={setShowGscLogout}
                    getDetails={getDetails}
                />
            )}
            {/* {isLoggedIn && !initialLoading && (
                <div
                    onClick={() => handleLogoutGsc()}
                    className={styles.logoutCta}
                >
                    <BiReset size={"20px"} />
                    Logout
                </div>
            )} */}
        </div>
    );
};

export default GoogleSearchConsole;
