import React, { useState, useEffect } from "react";
import styles from "./FixItModule.module.css";
import CardActions from "./CardActions";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const KeyTermFixIt = ({
    item = {},
    setCurrentIndex = () => {},
    currentHeadingIndex,
    fixItToQuill = () => {},
    setFixed = () => {},
    fixed = false,
    setIsDoneDisabled = () => {},
    cardRef,
    setOriginalPara = () => {},
    originalPara,
    setFixedCount = () => {},
    sectionName,
}) => {
    const [index, setIndex] = useState(0);
    const { mpTrackFixItActions } = useMixpanelHook();

    const handleFixItParagraph = (item, index) => {
        setOriginalPara(item.prompts[index].text[currentHeadingIndex]);
        setIsDoneDisabled(true);
        const para = document.getElementById(item.id);
        para.innerText = item.prompts[index].text[currentHeadingIndex];
        setFixed(true);
        fixItToQuill();
        setCurrentIndex((ps) => ps + 1);
        setIsDoneDisabled(false);
        cardRef.current.parentNode.scrollTop =
            cardRef.current.offsetTop - cardRef.current.clientHeight;
    };

    return (
        <>
            <div className={styles._body} key={item.id}>
                {!fixed ? (
                    <div className={styles.actions}>
                        <CardActions
                            handleFunction={() => {
                                mpTrackFixItActions({
                                    panelName: sectionName,
                                    userAction: "Accept",
                                });
                                handleFixItParagraph(item, index);
                                setFixedCount((ps) => ps + 1);
                            }}
                            firstButtontext={"Accept"}
                            dismissFunction={() => {
                                mpTrackFixItActions({
                                    panelName: sectionName,
                                    userAction: "Dismiss",
                                });
                                let dismissedKeywords = JSON.parse(
                                    localStorage.getItem("dismissedKeywords"),
                                );
                                dismissedKeywords[item.keyword] = [
                                    originalPara,
                                ];
                                localStorage.setItem(
                                    "dismissedKeywords",
                                    JSON.stringify(dismissedKeywords),
                                );
                                const para = document.getElementById(item.id);
                                if (para) {
                                    para.innerHTML = originalPara;
                                }
                                setCurrentIndex((ps) => ps + 1);
                                cardRef.current.parentNode.scrollTop =
                                    cardRef.current.offsetTop -
                                    cardRef.current.clientHeight;
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default KeyTermFixIt;
