import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Quill from "./Quill";

import formatStyles from "./Formats/styles.module.css";
import { BsCheckCircle, BsChevronRight } from "react-icons/bs";
import { CircularProgress, Radio } from "@material-ui/core";
import styles from "./styles.module.css";
import { IoClose } from "react-icons/io5";
import Modal from "../../atoms/Modal/Modal";
import Button from "../../atoms/Button/Button";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import EditorAssistant from "./EditorAssistant/EditorAssistant";
import AiBar from "./AiBar";
import ImageModal from "./ImageModal/ImageModal";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import { getTime } from "../../../utility/validateFunctions";
import Ratings from "../../molecules/Creator/Overview/overallRatings";
import { SMART_EDITOR, clickedOnViewOnly } from "./editorConstants";
import HighlightComponent from "../../atoms/HighlightComponent";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import { AI_BAR_FOCUS } from "../../Context/OnBoardingContext/constant";
import EditorContext from "./EditorContext";
import { useDispatch, useSelector } from "react-redux";
import { dataToEditor } from "./EditorMethods";
import RatingHook from "../../molecules/Creator/Overview/RatingHook";
import { debounceWrapper } from "../../../utility/helperFun";
import { PLANS_CATEGORIES } from "../../../constants";
import MetaData from "./MetaData";
import { useCallback } from "react";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import useSeoScoreRefresh from "./EditorAssistant/SeoScoreRefresh";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { Skeleton } from "@material-ui/lab";
import SVGIcon from "../../atoms/SVGIcon";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { SliderItem } from "./Configure";
import { VscClose } from "react-icons/vsc";
import TextArea from "../../atoms/TextArea/TextArea";

import ImageHandler from "./ImageModal/ImageModal";
import BlogOutline from "./BlogOutline/BlogOutline";
import ReportContext from "../Research/ReportContext";
import UserFeedback from "../UserFeedback/UserFeedback";
import { TfiMenuAlt } from "react-icons/tfi";
import useHelperFunctions from "../../helperFunctions";
import useGscHook from "../../Pages/GoogleSearchConsole/useGscHook";
import { setUserGscInfo } from "../../../store/actions/userGscInfo";
import { displayAlert } from "../../../store/actions/alert";
import { IoIosLink } from "react-icons/io";
import GscLogout from "../../Pages/NewDashboard/GscLogout";
import LockedTextComponent from "./LockedTextComponent";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import useAIHumanizerHook from "../../Pages/AIHumanizer/useAIHumanizerHook";
import AIHumanizerEditor from "../../Pages/AIHumanizer/AIHumanizerEditor";
export const attachEditorShortcuts = (
    writeForMe,
    rewrite,
    writingState,
    locked,
) => {
    const onKeyDown = (e) => {
        console.log(e, "Event Handler", document.q);
        // if (e.key == "Backspace" && imageSrc) {
        //   let opsContent = document?.quill?.editor?.getContents()

        //   let t = opsContent.filter((edx) => {
        //     return edx.insert?.image != imageSrc
        //   })

        //   document?.quill?.editor?.setContents(t)

        // }
        if (e.ctrlKey && e.key.toLowerCase() == "k" && !e.shiftKey) {
            e.preventDefault();
            if (document.quill.editor.isEnabled()) writeForMe(64);
        }
        if (e.metaKey && e.key.toLowerCase() == "k" && !e.shiftKey) {
            e.preventDefault();
            if (document.quill.editor.isEnabled()) writeForMe(64);
        }
        if (e.ctrlKey && e.key.toLowerCase() == "enter" && !e.shiftKey) {
            e.preventDefault();
            if (document.quill.editor.isEnabled() && !locked) writeForMe(75);
        }
        if (e.metaKey && e.key.toLowerCase() == "enter" && !e.shiftKey) {
            e.preventDefault();
            if (document.quill.editor.isEnabled() && !locked) writeForMe(75);
        }
        if (
            e.ctrlKey &&
            ["1", "2", "3", "4", "5", "6"].includes(e.key.toLowerCase())
        ) {
            e.preventDefault();
            document.quill.editor.format("header", +e.key.toLowerCase());
        }

        if (
            e.metaKey &&
            ["1", "2", "3", "4", "5", "6"].includes(e.key.toLowerCase())
        ) {
            e.preventDefault();
            document.quill.editor.format("header", +e.key.toLowerCase());
        }

        if (
            e.ctrlKey &&
            e.shiftKey &&
            e.key.toLowerCase() == "k" &&
            !writingState.state
        ) {
            rewrite();
        }
        if (
            e.metaKey &&
            e.shiftKey &&
            e.key.toLowerCase() == "k" &&
            !writingState.state
        ) {
            rewrite();
        }
    };
    document.addEventListener("keydown", onKeyDown);

    return () => {
        document.removeEventListener("keydown", onKeyDown);
    };
};

let timeout = null;
let currentData = "";
let timeout2 = null;

function ReportEditor({
    realtimeRating,
    writeForMe,
    connectorCases,
    saving,
    saveEditorData,
    metaData,
    setMetaData,
    writingState,
    getEditorData,
    rewrite,
    canReWrite,
    isCurrVersion,
    saved,
    aiWordLimitSetting,
    setAiWordLimitSetting,
    setFactsButton,
    reportInfo,
    docInfo,
    editorText,
    keyTerms,
    aiRepeatModal,
    noContentAiModal,
    setNoContentAiModal,
    setAiRepeatModal,
    setCanReWrite,
    attachedTo,
    setRealtimeRatings,
    optimiserData,
    seoScore,
    optimiserLoading,
    isOptimiserEnable,
    getSeoScore,
    isFixItEnabled,
    setIsFixItEnabled,
    enableFixItEditor,
    setEnableFixItEditor,
    metaFixIt,
    setMetaFixIt,
    enableFixIt,
    setEnableFixIt,
    isLoadingFixIt,
    setIsLoadingFixIt,
}) {
    const auth = useSelector((state) => state.auth);
    const [AISettings, toggleAISettings] = useState(false);
    const [serp, setSerp] = useState(false);
    const [plagiarism, setPlagiarism] = useState(false);
    const [wordpress, setWordpress] = useState(false);
    const [shopifyApp, setShopifyApp] = useState(false);
    const [versionModal, setVersionModal] = useState(false);
    const [copyWriter, setCopyWriter] = useState(0);
    const [loading, setLoading] = useState(true);
    const [highlightAI, setHighlightAI] = useState("");
    const [upgradeModal, setUpgradeModal] = useState(false);
    const [isToolbar, setIsToolbar] = useState(true);
    const [showOutline, setShowOutline] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [outputLength, setOutputLength] = useState(3);
    const [creativity, setCreativity] = useState(aiWordLimitSetting.creativity);
    const [interlinkingModal, setInterlinkingModal] = useState(false);
    const {
        LockConfigurator,
        isFreeForever,
        isEssential,
        plan_category,
        isLTD,
        isTeamMember,
    } = useUserAccessHook();
    const { createScan } = useAIHumanizerHook(false);
    const { mixpanelTrack } = useMixpanelHook();
    const goTo = useNavigation();
    const { showRatingComponent, setReportData } = useContext(ReportContext);
    const [close, setClose] = useState(true);
    const [gscDomains, setGscDomains] = useState([]);
    const { getData, postData } = useHelperFunctions();
    const { getTrimmedDomainURL } = useGscHook();
    const [selectedDomain, setSelectedDomain] = useState("");
    const [showInterlinking, setShowInterlinking] = useState(false);
    const [domainLoading, setDomainLoading] = useState(false);
    const dispatch = useDispatch();
    const [gscDomainModal, setGscDomainModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [interlinkingLoading, setInterlinkingLoading] = useState(false);
    const [aiHumanizer, setAiHumanizer] = useState(false);
    const [scanId, setScanId] = useState("");
    const show_humanizer_landing = useSelector(
        (state) => state.auth.show_humanizer_landing,
    );
    // const [showHumanizer, setShowHumanizer] = useState(false);

    const onBoarding = useContext(OnBoardingContext);
    const { isShared, guestInfo, versionData, auditMode } =
        useContext(EditorContext);

    const getGscDomains = async () => {
        const res = await getData({
            url: "/api/analyse/gsc-account-info",
        });
        if (res.status == 200) {
            setGscDomains(res.data);
        }
    };

    useEffect(() => {
        if (showRatingComponent) {
            setTimeout(() => {
                setClose(false);
            }, 2000);
        } else {
            setClose(true);
        }
    }, [showRatingComponent]);
    // const [enableFixItEditor, setEnableFixItEditor] = useState(false);

    // useEffect(() => {
    //   clearTimeout(timeout2);
    //   if (!canReWrite && document?.aioutput?.length) {
    //     document?.quill?.editor?.formatText(
    //       document?.aioutputCursor,
    //       document?.aioutput?.length,
    //       "background",
    //       "white"
    //     );
    //   }
    //   if (canReWrite && document?.aioutput?.length) {
    //     timeout2 = setTimeout(() => {
    //       document?.quill?.editor?.formatText(
    //         document?.aioutputCursor,
    //         document?.aioutput?.length,
    //         "background",
    //         "white"
    //       );
    //     }, 10000);
    //   }
    // }, [canReWrite]);

    useEffect(() => {
        if (showOutline) {
            setIsToolbar(false);
        } else if (!showOutline) {
            setIsToolbar(true);
        }
    }, [showOutline]);

    useEffect(() => {
        if (!aiHumanizer) return;
        const create = async () => {
            const scan_id = await createScan({
                name: reportInfo.title,
                isEditor: true,
            });
            setScanId(scan_id);
        };
        create();
    }, [aiHumanizer]);

    useEffect(() => {
        if (scanId) {
            // setShowHumanizer(true);
            setReportData((ps) => ({
                ...ps,
                reportInfo: { ...ps.reportInfo, isHumanizer: true },
            }));
            setAiHumanizer(false);
        }
    }, [scanId]);

    useEffect(() => {
        document.addEventListener("plagiarismON", setPlagiarism);
        document.addEventListener("wordpressON", setWordpress);
        document.addEventListener("shopifyON", setShopifyApp);
        if (!isShared) {
            getGscDomains();
        }

        return () => {
            document.removeEventListener("plagiarismON", setPlagiarism);
            document.removeEventListener("wordpressON", setWordpress);
            document.addEventListener("shopifyON", setShopifyApp);
        };
    }, []);
    const otherInfo = useSelector((state) => state.otherInfo);
    const [editorRef, setEditorRef] = useState(null);
    const ref1 = useRef();
    const fixItEditorRef = useRef(null);

    // const [enableFixIt, setEnableFixIt] = useState(false);
    // const [metaFixIt, setMetaFixIt] = useState({ section: "", isOn: false });

    useEffect(() => {
        if (versionModal) {
            toggleAISettings(false);
            setPlagiarism(false);
            // setWordpress(false);
            setSerp(false);
        }
    }, [versionModal]);

    useEffect(() => {
        if (serp) {
            toggleAISettings(false);
            setPlagiarism(false);
            // setWordpress(false);
            setVersionModal(false);
        }
    }, [serp]);

    useEffect(() => {
        if (plagiarism) {
            toggleAISettings(false);
            // setWordpress(false);
            setVersionModal(false);
            setSerp(false);
        }
    }, [plagiarism]);

    useEffect(() => {
        if (wordpress) {
            toggleAISettings(false);
            setPlagiarism(false);
            setSerp(false);
            setVersionModal(false);
            setShopifyApp(false);
        }
    }, [wordpress]);

    useEffect(() => {
        if (shopifyApp) {
            toggleAISettings(false);
            setPlagiarism(false);
            setSerp(false);
            setVersionModal(false);
            setWordpress(false);
        }
    }, [shopifyApp]);

    useEffect(() => {
        if (AISettings) {
            setSerp(false);
            setPlagiarism(false);
            // setWordpress(false);
            setVersionModal(false);
        }
    }, [AISettings]);
    let timeoutRef = useRef(null);

    useEffect(() => {
        if (isToolbar) {
            document.getElementById("toolbar").style.display = "flex";
        } else {
            document.getElementById("toolbar").style.display = "none";
        }
    }, [isToolbar]);

    useEffect(() => {
        debounceWrapper(
            async () => {
                if (document?.quill?.editor) {
                    let str = document.quill.editor.getText();

                    const rating = await RatingHook(str, keyTerms, "version");

                    setRealtimeRatings((ps) => {
                        return {
                            ...ps,
                            readability: rating.readability,
                            wordCount: rating.wordCount,
                            quality: rating.score,
                            fromApi: 0,
                        };
                    });
                }
            },
            500,
            timeoutRef,
        );

        if (!versionData.current || !!(isShared && !isShared.access)) {
            dataToEditor(versionData.html);
            document?.quill?.editor?.disable();

            document.current = false;
        } else {
            (!isShared || isShared.access) && document?.quill?.editor?.enable();
            document.current = true;
            !!currentData && dataToEditor(currentData);
        }
    }, [versionData]);

    useEffect(() => {
        if (typeof versionData.loading == "boolean")
            setLoading(versionData.loading);
    }, [versionData]);
    useEffect(() => {
        if (editorRef) {
            document.quill.editor.disable();
            (async () => {
                currentData = await getEditorData();

                setLoading(false);
                if (document.quill.editor) {
                    (!isShared || isShared.access) &&
                        document.quill.editor.enable();
                    document.quill.editor.cursor = 0;
                    document.quill.editor.setSelection(
                        document.quill.editor.cursor,
                    );
                    attachedTo == SMART_EDITOR
                        ? ""
                        : document.quill.editor.focus();
                }
            })();
            return () => {
                clearTimeout(timeout);
                currentData = "";
                document.aioutput = "";
            };
        }
    }, [editorRef]);
    useEffect(() => {
        if (!isShared) {
            return attachEditorShortcuts(
                writeForMe,
                rewrite,
                writingState,
                LockConfigurator(FEATURE_TYPES.instructLock),
            );
        }
    }, [canReWrite, writingState]);

    useEffect(() => {
        if (metaData?.fromAPI === false) {
            saveEditorData();
        }
    }, [metaData]);

    const handleDomainSelect = (e) => {
        setSelectedDomain(e.target.value);
    };

    const saveDomain = async (domain) => {
        setInterlinkingLoading(true);
        const res = await postData({
            url: "/api/interlinking/updateAuditURL",
            payload: {
                interlinking_domain: domain,
                report_id: reportInfo.report_id,
            },
        });
        setInterlinkingLoading(false);
        if (res.status == 200) {
            setReportData((ps) => ({
                ...ps,
                reportInfo: { ...ps.reportInfo, interlinking_domain: domain },
            }));
            setShowInterlinking(true);
            setInterlinkingModal(false);
        }
    };

    // const responseGoogle = async(
    //     response,
    //     _multiDomain,
    // ) => {
    //     setDomainLoading(true);
    //     if (response.code) {
    //         const res = await postData({
    //             url: "/api/analyse/loginToGsc",
    //             payload: {
    //                 code: response.code,
    //                 redirectUri: window?.location?.origin,
    //                 ...(_multiDomain && { multi_domain: true }),
    //             },
    //         });
    //         if (res.status == 200) {
    //             dispatch(
    //                 setUserGscInfo({
    //                     email: res.data?.resp?.userInfo?.email,
    //                 }),
    //             );
    //             setActiveStep(2);
    //             getDomainList();
    //             setGscLoginModal(true);
    //         } else {
    //             dispatch(
    //                 displayAlert({
    //                     alertMessage: `${res.response.data.message}`,
    //                     alertType: "error",
    //                     timeout: 5000,
    //                 }),
    //             );
    //         }
    //     }
    //     setDomainLoading(false);
    // };

    // const addDomain = (_multiDomain) => {
    //     setDomainLoading(true);
    //     let gsc = window?.google?.accounts?.oauth2?.initCodeClient({
    //         client_id:
    //             "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
    //         scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
    //         access_type: "offline",
    //         include_granted_scopes: true,
    //         callback: (rs) => {
    //             responseGoogle(rs, _multiDomain);
    //         },
    //         error_callback: () => {
    //             setDomainLoading(false);
    //         },
    //     });
    //     gsc.requestCode();
    // };

    const clickDown = useCallback(() => {
        document.dispatchEvent(clickedOnViewOnly);
    }, []);

    const saveData = useCallback(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (
                versionData.current &&
                document?.quill?.editor?.isEnabled() &&
                (!isShared || isShared.access)
            ) {
                saveEditorData();
                currentData = JSON.stringify(
                    document?.quill?.editor?.getContents(),
                );
                console.log("editor data", currentData);
            }
        }, 2000);
    }, [versionData, isShared]);

    const interlinkingModalClose = () => {
        mixpanelTrack("Interlinking CTA", {
            action: "Popup closed(clicked outside)",
        });
        setInterlinkingModal(false);
    };
    useSeoScoreRefresh({ getSeoScore, saving, editorRef, attachedTo });

    return (
        <>
            {editorRef && <ImageHandler />}
            {reportInfo.isHumanizer && (
                <div className={styles.humanizeWrapper}>
                    <AIHumanizerEditor
                        report_id={reportInfo?.report_id}
                        scan_id={scanId}
                    />
                </div>
            )}
            {/* {!!highlightAI && <TextHighlighter html={highlightAI}></TextHighlighter>} */}
            <div id="snow-editor" className={styles.container}>
                {aiRepeatModal && (
                    // <Modal setModal={setAiRepeatModal}>

                    <div className={formatStyles.aiRepeatContainer}>
                        <div className={formatStyles.aiRepeatLeft}>
                            <img src="/AssetsV2/aierror.svg" alt="I" />
                            <div className={formatStyles.aiRepeatText}>
                                We Tried! Even after multiple attempts our AI is
                                following a pattern and generating similar
                                content. Try amending the content for better
                                output.
                            </div>
                        </div>
                        <div className={formatStyles.aiRepeatRight}>
                            <div
                                className={formatStyles.aiRepeatKnowMore}
                                onClick={() =>
                                    window.open(
                                        "https://www.scalenut.com/help-article/is-there-a-repeating-pattern-in-your-content",
                                    )
                                }
                            >
                                Know More
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    top: "25px",
                                    right: "35px",
                                }}
                                onClick={() => setAiRepeatModal(false)}
                            >
                                <IoClose
                                    size={20}
                                    color="#ababab"
                                    cursor="pointer"
                                />
                            </div>
                        </div>
                    </div>
                    // </Modal>
                )}
                {noContentAiModal && (
                    <Modal setModal={setNoContentAiModal}>
                        <div
                            style={{
                                width: "400px",
                                height: "250px",
                                backgroundColor: "white",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "30px",
                                padding: "30px 30px",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#4b4b55",
                                borderRadius: 3,
                                textAlign: "center",
                                lineHeight: "1.64",
                                position: "relative",
                            }}
                        >
                            <CloseButton
                                setClose={setNoContentAiModal}
                                style={{}}
                            />
                            <div>
                                <img src="/AssetsV2/ai-icons-error.svg" />
                            </div>
                            <div>
                                Please write a little more about the topic for
                                our AI to understand the context.
                            </div>
                        </div>
                    </Modal>
                )}

                <div
                    style={{
                        marginTop:
                            enableFixItEditor || enableFixIt ? "15px" : "0px",
                    }}
                    className={styles.editorContainer}
                >
                    {showOutline && (
                        <BlogOutline
                            showOutline={showOutline}
                            setShowOutline={setShowOutline}
                        />
                    )}
                    <div className={styles.editorBoundsContainer}>
                        <div
                            style={
                                enableFixItEditor || enableFixIt
                                    ? { display: "none" }
                                    : {}
                            }
                            className={styles.editorTopBarsWrapper}
                        >
                            <div className={styles.topBar}>
                                {!showOutline && (
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={
                                            showOutline
                                                ? "Hide Outlines"
                                                : "Show Outlines"
                                        }
                                    >
                                        <div
                                            className={styles.outlineBtn}
                                            onClick={() => {
                                                mixpanelTrack(
                                                    "Editor Outline",
                                                    { action: "Open" },
                                                );
                                                setShowOutline(!showOutline);
                                            }}
                                        >
                                            <TfiMenuAlt size={18} />
                                        </div>
                                    </LightTooltip>
                                )}
                                {!isShared && (
                                    <div className={styles.writeBtn}>
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title={`Use this for writing ahead with our AI. Keyboard Shortcut: (${
                                                !navigator.userAgent.includes(
                                                    "Mac",
                                                )
                                                    ? "Ctrl +"
                                                    : "⌘ "
                                            } K) `}
                                        >
                                            <div
                                                onClick={() => writeForMe(64)}
                                                className={styles.writeAheadBtn}
                                            >
                                                <SVGIcon src="/New UI/SVG/brain.svg" />
                                                <span
                                                    style={{
                                                        width: "90px",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {writingState.state ? (
                                                        <>
                                                            {writingState.text}
                                                            <span>
                                                                <LoadingDots
                                                                    dots={true}
                                                                    dotsInterval={
                                                                        200
                                                                    }
                                                                />
                                                            </span>
                                                        </>
                                                    ) : (
                                                        "Write Ahead"
                                                    )}
                                                </span>
                                            </div>
                                        </LightTooltip>
                                        {/* <div className={styles.writeBtnSep}></div> */}

                                        {/* <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={`Write Ahead Setting`}
                                    >
                                        <div
                                            className={styles.settingsBtn}
                                            onClick={() =>
                                                setShowSettings(true)
                                            }
                                        >
                                            <SVGIcon src="/New UI/SVG/setting-two.svg" />
                                        </div>
                                    </LightTooltip> */}
                                    </div>
                                )}
                                <div
                                    className={styles.formatBtn}
                                    style={{
                                        padding: isToolbar ? "0px" : "9px",
                                        gap: isToolbar ? "0px" : "10px",
                                    }}
                                >
                                    {!isToolbar && (
                                        <div
                                            onClick={() =>
                                                setIsToolbar(!isToolbar)
                                            }
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <SVGIcon src="/New UI/SVG/text-recognition.svg" />
                                            <span>Format</span>
                                        </div>
                                    )}

                                    <div id="toolbar">
                                        <HighlightComponent
                                            highlight={
                                                onBoarding.focusOn ==
                                                AI_BAR_FOCUS
                                            }
                                        >
                                            <AiBar
                                                writingState={writingState}
                                                writeForMe={writeForMe}
                                                rewrite={rewrite}
                                                canReWrite={canReWrite}
                                                reportInfo={reportInfo}
                                                keyTerms={keyTerms}
                                                toggleAISettings={
                                                    toggleAISettings
                                                }
                                                connectorCases={connectorCases}
                                                attachedTo={attachedTo}
                                                setSerp={setSerp}
                                                enable={!isShared}
                                            />
                                        </HighlightComponent>
                                    </div>

                                    <BsChevronRight
                                        style={{
                                            transform: isToolbar
                                                ? "rotate(180deg)"
                                                : "",
                                            cursor: "pointer",
                                            marginRight: isToolbar
                                                ? "8px"
                                                : "0px",
                                            marginLeft: isToolbar
                                                ? "-10px"
                                                : "0px",
                                            zIndex: 6,
                                        }}
                                        onClick={() => {
                                            mixpanelTrack("Editor Toolbar", {
                                                action: isToolbar
                                                    ? "Close"
                                                    : "Open",
                                            });
                                            setIsToolbar(!isToolbar);
                                        }}
                                        fill="#333853"
                                        strokeWidth={0.4}
                                    />
                                </div>
                                {!isShared && attachedTo != SMART_EDITOR && (
                                    <LightTooltip
                                        arrow
                                        placement="bottom"
                                        title={""}
                                    >
                                        <div
                                            onClick={() => {
                                                if (
                                                    !otherInfo.subscriptionInfo
                                                        ?.humanizer_plan_id &&
                                                    show_humanizer_landing
                                                ) {
                                                    goTo("AI_HUMANIZER");
                                                    return;
                                                }
                                                if (
                                                    document?.quill?.editor?.getText()
                                                        ?.length > 250
                                                ) {
                                                    setAiHumanizer(true);
                                                    mixpanelTrack(
                                                        "AI Humanizer",
                                                        {
                                                            action: `Detect and Humanize(${
                                                                auditMode
                                                                    ? "optimizer"
                                                                    : "article writer"
                                                            })`,
                                                        },
                                                    );
                                                } else {
                                                    dispatch(
                                                        displayAlert({
                                                            alertMessage:
                                                                "Please write a little more about the topic to enable this feature.",
                                                            alertType: "error",
                                                        }),
                                                    );
                                                }
                                            }}
                                            className={styles.interlinkingBtn}
                                        >
                                            {aiHumanizer ? (
                                                <CircularProgress size={14} />
                                            ) : (
                                                <>
                                                    <SVGIcon
                                                        color="#014dc5"
                                                        size={18}
                                                        src={
                                                            "/New UI/aside-section/humanizer.svg"
                                                        }
                                                        style={{
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                    Detect and Humanize
                                                </>
                                            )}
                                        </div>
                                    </LightTooltip>
                                )}
                            </div>

                            <AiBar
                                writingState={writingState}
                                writeForMe={writeForMe}
                                rewrite={rewrite}
                                canReWrite={canReWrite}
                                reportInfo={reportInfo}
                                keyTerms={keyTerms}
                                toggleAISettings={toggleAISettings}
                                connectorCases={connectorCases}
                                attachedTo={attachedTo}
                                setSerp={setSerp}
                                enable={!isShared}
                                editorRef={editorRef}
                            />
                        </div>
                        <div
                            className={styles.editorScrollContainer}
                            id="editorBoundsContainer"
                        >
                            {attachedTo != SMART_EDITOR && (
                                <MetaData
                                    metaData={metaData}
                                    setMetaData={setMetaData}
                                    metaFixIt={metaFixIt}
                                    setMetaFixIt={setMetaFixIt}
                                    loading={loading}
                                />
                            )}
                            {editorRef && (
                                <>
                                    {loading && (
                                        <div className={styles.loading}>
                                            {new Array(20).fill(4).map(() => (
                                                <Skeleton
                                                    width={"100%"}
                                                    height={30}
                                                    style={{
                                                        opacity: "0.8",
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            position: "sticky",
                                            top: 80,
                                            marginLeft:
                                                attachedTo == SMART_EDITOR
                                                    ? 60
                                                    : 0,
                                        }}
                                        id="aiCruiseContainer"
                                    ></div>
                                </>
                            )}
                            <Quill
                                versionData={versionData}
                                setCanReWrite={setCanReWrite}
                                saveData={saveData}
                                containerId={"#editor-bound"}
                                attachedTo={attachedTo}
                                writeForMe={writeForMe}
                                setEditorRef={setEditorRef}
                                saveEditorData={saveEditorData}
                                enableFixItEditor={enableFixItEditor}
                                fixItEditorRef={fixItEditorRef}
                                metaFixIt={metaFixIt}
                                setMetaFixIt={setMetaFixIt}
                                editorRef={editorRef}
                                auditMode={auditMode}
                                showOutline={showOutline}
                            ></Quill>
                        </div>

                        {attachedTo == SMART_EDITOR ? (
                            <EditorWordCounter
                                wordCount={realtimeRating?.wordCount}
                            />
                        ) : !(enableFixItEditor || enableFixIt) ? (
                            <Ratings
                                keyTermsLength={keyTerms?.length}
                                attachedTo={attachedTo}
                                realtimeRating={realtimeRating}
                                optimiserLoading={optimiserLoading}
                                contentLength={
                                    document?.quill?.editor?.getText().trim()
                                        .length
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </div>

                    <EditorAssistant
                        reportInfo={reportInfo}
                        metaData={metaData}
                        setMetaData={setMetaData}
                        docInfo={docInfo}
                        keyTerms={keyTerms}
                        aiWordLimitSetting={aiWordLimitSetting}
                        setAiWordLimitSetting={setAiWordLimitSetting}
                        attachedTo={attachedTo}
                        setSerp={setSerp}
                        setPlagiarism={setPlagiarism}
                        setWordpress={setWordpress}
                        serp={serp}
                        plagiarism={plagiarism}
                        wordpress={wordpress}
                        shopifyApp={shopifyApp}
                        setShopifyApp={setShopifyApp}
                        AISettings={AISettings}
                        toggleAISettings={toggleAISettings}
                        versionModal={versionModal}
                        setVersionModal={setVersionModal}
                        selection={null}
                        copyWriter={copyWriter}
                        setCopyWriter={setCopyWriter}
                        realtimeRating={realtimeRating}
                        saving={saving}
                        optimiserData={optimiserData}
                        seoScore={seoScore}
                        optimiserLoading={optimiserLoading}
                        isOptimiserEnable={isOptimiserEnable}
                        setEnableFixItEditor={setEnableFixItEditor}
                        setEnableFixIt={setEnableFixIt}
                        enableFixIt={enableFixIt}
                        enableFixItEditor={enableFixItEditor}
                        isLoadingFixIt={isLoadingFixIt}
                        setIsLoadingFixIt={setIsLoadingFixIt}
                        fixItEditorRef={fixItEditorRef}
                        metaFixIt={metaFixIt}
                        setMetaFixIt={setMetaFixIt}
                        isFixItEnabled={isFixItEnabled}
                        setIsFixItEnabled={setIsFixItEnabled}
                        setUpgradeModal={setUpgradeModal}
                        showInterlinking={showInterlinking}
                        setShowInterlinking={setShowInterlinking}
                        setInterlinkingModal={setInterlinkingModal}
                    />
                    {upgradeModal && (
                        <Modal setModal={() => setUpgradeModal(false)}>
                            <div className={styles.seoModal}>
                                <span className={styles.seoModalCross}>
                                    <SVGIcon
                                        onClick={() => setUpgradeModal(false)}
                                        src="/New UI/SVG/cross.svg"
                                    ></SVGIcon>
                                </span>
                                <h3>
                                    Fix It is not available on your current
                                    plan. Please upgrade to use this feature.
                                </h3>
                                <Button
                                    width="40%"
                                    handler={() => {
                                        goTo("BILLING_PLANS");
                                    }}
                                    text="Upgrade Plan"
                                    style={{
                                        fontSize: 14,
                                    }}
                                ></Button>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
            {!close && (
                <UserFeedback
                    setClose={setClose}
                    realtimeRating={realtimeRating}
                    section="Editor"
                    setReportData={setReportData}
                    isFixItEnabled={isFixItEnabled}
                />
            )}
            {interlinkingModal && (
                <Modal setModal={interlinkingModalClose}>
                    <div className={styles.interlinkingModalWrapper}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                }}
                            >
                                <h2>Select a Domain</h2>
                                <LightTooltip
                                    arrow
                                    placement="bottom"
                                    title={
                                        "Once a domain is selected, it cannot be changed for this report"
                                    }
                                >
                                    <div style={{ marginTop: "8px" }}>
                                        <SVGIcon
                                            color="#2196f3"
                                            size={18}
                                            src={"/New UI/SVG/info.svg"}
                                        />
                                    </div>
                                </LightTooltip>
                            </div>
                            <VscClose
                                onClick={() => {
                                    mixpanelTrack("Interlinking CTA", {
                                        action: "Cross button clicked",
                                    });
                                    setInterlinkingModal(false);
                                }}
                            />
                        </div>
                        {gscDomains.length > 0 ? (
                            <>
                                <div
                                    style={{
                                        maxHeight: "230px",
                                        overflow: "auto",
                                        marginTop: "20px",
                                    }}
                                >
                                    {gscDomains.map((domain) => (
                                        <div
                                            className={styles.domainRow}
                                            onClick={(e) => {
                                                if (domain.is_disconnect == 1) {
                                                    setGscDomainModal(true);
                                                    setInterlinkingModal(false);
                                                    return;
                                                }
                                                setSelectedDomain(
                                                    domain.domain_name,
                                                );
                                                e.stopPropagation();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "flex-start",
                                                }}
                                            >
                                                <img
                                                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                        domain?.domain_name?.split(
                                                            ":",
                                                        )[1]
                                                    }&sz=32`}
                                                    width={"24px"}
                                                />
                                                <div
                                                    className={
                                                        styles.domainName
                                                    }
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    {getTrimmedDomainURL(
                                                        domain.domain_name,
                                                    )}
                                                    {domain.is_disconnect && (
                                                        <LightTooltip
                                                            arrow
                                                            title={
                                                                "Re-Authorize this domain"
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    height: "16px",
                                                                }}
                                                            >
                                                                <SVGIcon
                                                                    src={
                                                                        "/New UI/SVG/triangle_exclamation-fill.svg"
                                                                    }
                                                                    style={{
                                                                        color: "#fc5a5a",
                                                                    }}
                                                                    size={16}
                                                                />
                                                            </div>
                                                        </LightTooltip>
                                                    )}
                                                </div>
                                            </div>
                                            <Radio
                                                checked={
                                                    selectedDomain ==
                                                    domain.domain_name
                                                }
                                                onChange={handleDomainSelect}
                                                value={domain.domain_name}
                                                color="default"
                                                size="small"
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "30px",
                                        marginTop: "10px",
                                    }}
                                >
                                    {isTeamMember ? (
                                        <span></span>
                                    ) : (
                                        <Button
                                            handler={() => {
                                                mixpanelTrack(
                                                    "Interlinking CTA",
                                                    {
                                                        action: "Add Domain clicked",
                                                    },
                                                );
                                                setGscDomainModal(true);
                                                setInterlinkingModal(false);
                                            }}
                                            loading={domainLoading}
                                            text="Add Domain"
                                            style={{
                                                background:
                                                    "rgba(1, 77, 197, 0.1)",
                                                color: "#014dc5",
                                            }}
                                            Icon={() => (
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/globePlus.svg"
                                                    }
                                                    style={{ color: "inherit" }}
                                                    size={18}
                                                />
                                            )}
                                            iconReverse={true}
                                        />
                                    )}
                                    <Button
                                        handler={() => {
                                            mixpanelTrack("Interlinking CTA", {
                                                action: "Start clicked",
                                            });
                                            if (selectedDomain) {
                                                saveDomain(selectedDomain);
                                            }
                                        }}
                                        style={{
                                            maxWidth: 250,
                                        }}
                                        loading={interlinkingLoading}
                                        text="Start"
                                    />
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    marginTop: "50px",
                                    color: "#2c3948",
                                }}
                            >
                                <div>No Domains Found</div>
                                <Button
                                    handler={() => {
                                        mixpanelTrack("Interlinking CTA", {
                                            action: "Add Domain clicked(No Domains Found)",
                                        });
                                        setGscDomainModal(true);
                                        setInterlinkingModal(false);
                                    }}
                                    width={200}
                                    style={{
                                        marginBottom: "50px",
                                        marginTop: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    text="Add Domain"
                                    Icon={() => (
                                        <SVGIcon
                                            src={"/New UI/SVG/globePlus.svg"}
                                            style={{ color: "inherit" }}
                                            size={18}
                                        />
                                    )}
                                    iconReverse={true}
                                />
                            </div>
                        )}
                    </div>
                </Modal>
            )}
            {gscDomainModal && (
                <Modal setModal={setGscDomainModal}>
                    <div className={styles.addDomainModal}>
                        <VscClose
                            size={16}
                            style={{
                                position: "absolute",
                                right: "15px",
                                top: "15px",
                                cursor: "pointer",
                                zIndex: 12,
                            }}
                            onClick={(e) => {
                                setGscDomainModal(false);
                            }}
                        />
                        <GscLogout
                            insideTA={true}
                            syncDomainsEdit={getGscDomains}
                        />
                    </div>
                </Modal>
            )}
            {showUpgradeModal && <Modal setModal={setShowUpgradeModal}></Modal>}
        </>
    );
}

export const EditorWordCounter = ({ wordCount }) => {
    return (
        <div className={styles.realtimeRating}>
            <span className={styles.categoryValue}>{wordCount}</span>
            <span className={styles.categoryField}>words</span>
        </div>
    );
};

export default ReportEditor;
