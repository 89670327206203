import React from "react";
import styles from "./VersionList.module.css";
import { BsDot } from "react-icons/bs";
import {
    getShortDateTime,
    getTime,
    parseJSON,
} from "../../../../utility/validateFunctions";
function Version({ item, selectedVersion, setSelectedVersion, index }) {
    return (
        <div className={styles.outer}>
            <div
                className={`${styles.listCard} ${
                    selectedVersion.version === item.version
                        ? styles.selectedCard
                        : ""
                }`}
                onClick={() => {
                    setSelectedVersion({
                        version: item.version,
                        index,
                        updated_at: item.updated_at,
                    });
                }}
            >
                <div className={styles.top}>
                    <div className={styles.time}>
                        {getShortDateTime(item.updated_at)}
                    </div>
                    {index == 0 && (
                        <div className={styles.currVersion}>
                            current version
                        </div>
                    )}
                </div>
                <div className={styles.bottom}>
                    <div className={styles.version}>#{item.version} </div>
                    <div
                        className={`${styles.name} ${
                            index === 0 ? styles.dot : ""
                        }`}
                    >
                        <BsDot className={styles.dot} />
                        {item.guest_name ? item.guest_name : item.name}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Version;
