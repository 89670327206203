import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FcGoogle } from "react-icons/fc";
import Button from "../../../atoms/Button/Button";
import TextField from "../../../atoms/CustomTextfield/Textfield";
import Spacer from "../../../atoms/Spacer/Spacer";
import CheckBox from "../../../atoms/CheckBox/CheckBox";
import styles from "../styles.module.css";
import ValidationError from "../../../../components/common/validationError";

import {
    clearError,
    registerUser,
    googleLogin,
    verifyTeamRegister,
} from "../../../../store/actions/auth";
import { GoogleLogin } from "react-google-login";
import {
    formValidator,
    formValidatorGoogle,
} from "../../../../utility/validateFunctions";
import { emailValidatorS } from "../../../../utility/validateFunctions";
import Label from "../../../atoms/Label/Label";
import useHelperFunctions from "../../../../componentsV2/helperFunctions";
import {
    LOCATION_API_KEY_PROD,
    LOCATION_API_KEY_TEST,
} from "../../../../utility/config";
import VericationBanner from "../VerificationBanner/VericationBanner";

function SignUp({
    responseGoogle,
    setTeamMemberFun,
    referCode = null,
    queryEntries,
}) {
    const _googleLogin = useSelector((state) => state.auth.googleLogin);
    const email = useSelector((state) => state.auth.email);
    const googleToken = useSelector((state) => state.auth.googleToken);
    const verifyEmail = useSelector((state) => state.auth.verifyEmail);

    let error = useSelector((state) => state.auth.error);
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const redirectPath = useSelector((state) => state.auth.authRedirectPath);
    const [visibility, setVisibility] = useState(false);
    const loading = useSelector((state) => state.auth.loading);
    // const [email, setEmail] = useState("");
    // Component Internal State
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
    const [coupon, setCoupon] = useState("");
    const [phone, setPhone] = useState("");
    const [_email, setEmail] = useState(email);
    const [didUpdate, setDidUpdate] = useState(false);
    const [country, setCountry] = useState("US");
    const [teamInvite, setTeamInvite] = useState(false);
    const [teamMember, setTeamMember] = useState(null);

    const queryEmail = queryEntries["email"];
    const queryName = queryEntries["name"];
    const lifetime = queryEntries["lifetime"];
    const companyId = queryEntries["companyId"];
    const emailToken = queryEntries["emailToken"];
    const companyName = queryEntries["companyName"];
    const signup_mode = queryEntries["signup_mode"];

    useEffect(() => {
        if (queryEmail && companyId && emailToken) {
            setTeamInvite(true);
        }
        setTeamMemberFun(setTeamMember);
    }, []);

    const { getData } = useHelperFunctions();

    if ((queryEmail || queryName) && !didUpdate) {
        setEmail(queryEmail);
        if (queryName !== "null") {
            setName(queryName);
        }
        setDidUpdate(true);
    }

    const params = useParams();
    //Dispatch Handlers
    const dispatch = useDispatch();
    useEffect(() => {
        if (!verifyEmail) {
            dispatch(clearError());
        }
    }, []);

    const handleSubmit = (e) => {
        e && e.preventDefault && e.preventDefault();
        setTeamMember(null);

        if (teamInvite) {
            return dispatch(
                verifyTeamRegister({
                    email: _email,
                    emailToken: emailToken,
                    companyId: companyId,
                    password: password,
                    name: name,
                }),
            );
        } else {
            dispatch(
                registerUser({
                    email: _email,
                    name,
                    password,
                    coupon,
                    signup_mode,
                    queryEntries,
                }),
            ).then((e) => {
                e?.status == 203 && setTeamMember(e);
            });
        }
    };

    if (teamMember)
        return (
            <VericationBanner
                teamMember={{
                    ...teamMember,
                    register: googleToken
                        ? (data) => dispatch(registerUser(data))
                        : handleSubmit,
                    googleToken: googleToken,
                }}
            />
        );

    return (
        <>
            {error ? (
                <>
                    <ValidationError msg={errorMessage} />{" "}
                    <Spacer spacing={15} />
                </>
            ) : null}

            {!teamInvite && (
                <>
                    <GoogleLogin
                        clientId="466601860416-thij5f8b08j5nslathiom9t0fneoe8t4.apps.googleusercontent.com"
                        render={(renderProps) => (
                            <Button
                                props={{
                                    id: "google-auth-register-button",
                                }}
                                loading={loading && _googleLogin}
                                handler={renderProps.onClick}
                                disabled={renderProps.disabled}
                                height={44}
                                Icon={FcGoogle}
                                secondary={true}
                                text={"Signup with Google"}
                                iconReverse={true}
                            />
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                    />

                    <Spacer spacing={15} />
                    <span className={styles.link1}>OR</span>
                </>
            )}
            <form onSubmit={(e) => e.preventDefault()}>
                <TextField
                    props={{
                        onChange: (e) => setName(e.target.value),
                        value: name,
                        name: "name",
                    }}
                    height={44}
                    placeholder={"Enter your Name"}
                    label="Full name"
                    autoFocus
                />

                <Spacer spacing={5} />

                <TextField
                    props={{
                        onChange: (e) => setEmail(e.target.value),
                        value: _email,
                        disabled: teamInvite,
                        id: "email",
                        autoComplete: "new-email",
                    }}
                    height={44}
                    placeholder={"Enter your email"}
                    label="Email"
                    type="email"
                    errMsg={
                        emailValidatorS(_email ? _email?.trim() : "") ||
                        _email?.length == 0
                            ? null
                            : "Enter a valid email"
                    }
                />

                <Spacer spacing={5} />
                <TextField
                    props={{
                        onChange: (e) => setPassword(e.target.value),
                        value: password,
                        autoComplete: "new-password",
                        id: "password",
                    }}
                    type="password"
                    height={44}
                    label="Password"
                    placeholder={"Enter your password"}
                    errMsg={
                        password.trim().length == 0 ||
                        password.trim().length >= 6
                            ? false
                            : "Password must be of atLeast 6 characters"
                    }
                />
                {lifetime && (
                    <>
                        <Spacer spacing={5} />
                        <TextField
                            props={{
                                onChange: (e) =>
                                    setCoupon(e.target.value.trim()),
                                value: coupon,
                                autoComplete: "off",
                                name: "coupon",
                            }}
                            type="text"
                            height={44}
                            label="Lifetime Promotion Code"
                            placeholder={"Enter code"}
                            errMsg={
                                coupon.trim().length >= 2
                                    ? false
                                    : "Please enter LTD promotion code"
                            }
                        />
                    </>
                )}

                <Spacer spacing={20} />
                <Button
                    props={{
                        id: "sign-up-button",
                    }}
                    disabled={
                        formValidator({
                            error,

                            name,
                            email,
                            _email,

                            password,
                        }) && (lifetime ? coupon?.trim().length != 0 : true)
                            ? false
                            : true
                    }
                    loading={loading && !_googleLogin}
                    handler={handleSubmit}
                    height={44}
                    text={"Create account"}
                />
                <span className={styles.link1}>
                    By creating an account, I accept the content of <br />
                    <a
                        href="https://www.scalenut.com/terms-and-conditions"
                        target="_blank"
                    >
                        &nbsp;{"Regulations"}
                    </a>
                    &nbsp;and{" "}
                    <a
                        href="https://www.scalenut.com/privacy-policy"
                        target="_blank"
                    >
                        &nbsp;{"Privacy Policy."}
                    </a>
                </span>
            </form>
        </>
    );
}

export default SignUp;
