import React, { useState, useEffect, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./styles.module.css";
import Button from "../../atoms/Button/Button";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ClickAwayListener,
    Input,
    InputAdornment,
    makeStyles,
} from "@material-ui/core";
import { BiCheck, BiSearch } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import { IoChevronDown, IoClose, IoReturnUpBack } from "react-icons/io5";
import { FilterMenu, IssueTag, NoResultFound } from "./WebtuneDashboard";
import { Check } from "@material-ui/icons";
import useHelperFunctions from "../../helperFunctions";
import {
    categoryMap,
    fixActions,
    issueLogsFilterDefault,
    webtuneEndPoints,
} from "./constants";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { GrRevert } from "react-icons/gr";
import { WebtuneContext } from "./WebTune";
import ViewportList from "react-viewport-list";
import { MdClose } from "react-icons/md";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import Modal from "../../atoms/Modal/Modal";
import SVGIcon from "../../atoms/SVGIcon";

function HistoryWindow({
    setHistoryWindow,
    getIssuesRequestRef,
    getWebtuneDetailsRef,
    historyWindow,
}) {
    const [open, setOpen] = useState(historyWindow);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const { postData } = useHelperFunctions();
    const [loading, setLoading] = useState(false);
    const [reverting, setReverting] = useState(false);
    const { domain, domain_id } = useContext(WebtuneContext);
    const [filters, setFilters] = useState(issueLogsFilterDefault);
    const [revertModal, setRevertModal] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);

    useEffect(() => {
        //
        let _filters = filters.categories.filter((ftm) =>
            ftm?.issueTags.length > 0 ? true : false,
        );
        if (!!_filters.length) {
            setIsFilterActive(true);
        } else {
            setIsFilterActive(false);
        }
    }, [filters]);

    const revertRequest = async () => {
        setReverting(true);
        const arr = [];
        data?.forEach((item) => {
            item?.fixes?.forEach((fix) => {
                fix?.checked ? arr.push(fix.fix_id) : "";
            });
        });
        const res = await postData({
            url: webtuneEndPoints.updateFix,
            payload: {
                action: fixActions.REVERT,
                domain_id: domain_id,
                fix_id: arr,
            },
        });
        if (res.status == 200) {
            historyRequest();
            setReverting(false);
            setRevertModal(false);
            getIssuesRequestRef.current();
            getWebtuneDetailsRef.current();
        }
    };

    const historyRequest = async () => {
        setLoading(true);
        const res = await postData({
            url: webtuneEndPoints.getLogs,
            payload: { domain: domain },
        });
        setLoading(false);
        if (res.status == 200) {
            const parsedData = dateWiseCategory(res.data);

            setData(parsedData);
            setAllData(res.data.filter((log) => log.status));
        }
    };

    const dateWiseCategory = (arr) => {
        const newArr = [];

        arr.forEach((log) => {
            if (log.status == 0) return;
            if (newArr.length) {
                if (
                    newArr[newArr.length - 1].date ===
                    moment(log.created_at).format("l")
                )
                    newArr[newArr.length - 1].fixes.push(log);
                else {
                    newArr.push({
                        date: moment(log.created_at).format("l"),
                        fixes: [log],
                    });
                }
            } else {
                newArr.push({
                    date: moment(log.created_at).format("l"),
                    fixes: [log],
                });
            }
        });
        return newArr;
    };
    useEffect(() => {
        if (historyWindow) {
            historyRequest();
            setOpen(true);
        } else {
            setTimeout(() => {
                setOpen(false);
            }, 600);
        }
    }, [historyWindow]);

    useEffect(() => {}, []);

    useEffect(() => {
        let _flag = true;

        for (let index = 0; index < filters.categories.length; index++) {
            const cat = filters.categories[index];
            if (cat.issueTags.length) {
                _flag = false;
                break;
            }
        }
        const parsedData = dateWiseCategory(
            allData.filter((i) => {
                let flag = true;
                if (filters.search) {
                    flag =
                        flag && !!i?.meta_data?.page?.includes(filters.search);
                }

                if (filters.status) {
                    if (filters.status == 1) flag = flag && i.status == 1;
                    else {
                        flag = flag && i.status == 2;
                    }
                }
                let __flag = false;
                if (!_flag) {
                    outerLoop: for (
                        let index = 0;
                        index < filters.categories.length;
                        index++
                    ) {
                        const cat = filters.categories[index];

                        if (
                            cat.key == i.meta_data.category &&
                            cat.issueTags.length
                        ) {
                            for (let j = 0; j < cat.issueTags.length; j++) {
                                const tag = cat.issueTags[j];

                                if (
                                    i.meta_data.issueTags
                                        .map((issueTag) => issueTag.key)
                                        .includes(tag)
                                ) {
                                    __flag = true;
                                    break outerLoop;
                                }
                            }
                        }
                    }
                    if (!__flag) {
                        flag = false;
                    } else {
                        flag = flag && __flag;
                    }
                }

                return flag;
            }),
        );

        setData(parsedData);
    }, [filters]);

    const getSelectedFixes = () => {
        let count = 0;
        data?.forEach((item) => {
            item?.fixes?.forEach((fix) => {
                fix?.checked ? ++count : "";
            });
        });
        return count;
    };

    if (!open) return null;

    return (
        <div className={styles.historyContainerWrapper}>
            <ClickAwayListener
                onClickAway={() => {
                    setHistoryWindow(false);
                }}
            >
                <div
                    className={`${styles.historyContainer} ${
                        !historyWindow ? styles.historyContainerClose : ""
                    }`}
                >
                    <header>
                        <h3 style={{ fontWeight: 600 }}>Change History</h3>{" "}
                        <IoClose
                            onClick={() => setHistoryWindow(false)}
                            size={24}
                            color="#ACB3BF"
                            className={styles.closeHistory}
                        />
                    </header>
                    <div className={styles.filters}>
                        <Input
                            type="search"
                            className={styles.searchBar}
                            startAdornment={
                                <InputAdornment position="start">
                                    <BiSearch color="#ACB3BF" size={20} />
                                </InputAdornment>
                            }
                            placeholder="Search page URL"
                            disabled={loading}
                            onChange={(e) =>
                                setFilters((ps) => ({
                                    ...ps,
                                    search: e.target.value.trim(),
                                }))
                            }
                        />

                        <FilterMenu
                            logs={true}
                            setFilters={setFilters}
                            filters={filters}
                            isFilterActive={isFilterActive}
                        />
                    </div>

                    {loading ? (
                        <HistoryLoader />
                    ) : allData.length ? (
                        <>
                            {data.length ? (
                                data.map((item, i) => (
                                    <>
                                        <CheckBox
                                            label={moment(item.date).calendar(
                                                null,
                                                {
                                                    sameDay: "[Today]",
                                                    lastDay: "[Yesterday]",
                                                    sameElse: "DD MMMM YYYY",
                                                    lastWeek: "DD MMMM YYYY",
                                                },
                                            )}
                                            props={{
                                                checked: item.checked,
                                            }}
                                            handler={(e) => {
                                                if (e.target.checked)
                                                    setData((ps) => {
                                                        const newArr = [...ps];
                                                        newArr[
                                                            i
                                                        ].checked = true;
                                                        newArr[i].fixes = ps[
                                                            i
                                                        ].fixes.map((item) => ({
                                                            ...item,
                                                            checked:
                                                                item.status == 2
                                                                    ? false
                                                                    : true,
                                                        }));

                                                        return newArr;
                                                    });
                                                else {
                                                    setData((ps) => {
                                                        const newArr = [...ps];
                                                        newArr[
                                                            i
                                                        ].checked = false;

                                                        newArr[i].fixes = ps[
                                                            i
                                                        ].fixes.map((item) => ({
                                                            ...item,
                                                            checked: false,
                                                        }));

                                                        return newArr;
                                                    });
                                                }
                                            }}
                                        />
                                        <div className={styles.rowsWrapper}>
                                            <ViewportList
                                                items={item.fixes}
                                                itemMinSize={40}
                                                margin={8}
                                            >
                                                {(fix, index) => (
                                                    <Row
                                                        fix={fix}
                                                        key={fix.fix_id}
                                                        setData={setData}
                                                        index={index}
                                                        categoryIndex={i}
                                                    />
                                                )}
                                            </ViewportList>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <NoResultFound />
                            )}
                        </>
                    ) : (
                        <div className={styles.logsEmpty}>
                            <SVGIcon
                                src={"/New UI/SVG/webtuneLogsEmpty.svg"}
                                style={{ margin: "auto" }}
                            />
                            <p>
                                No logs found for{" "}
                                <span className={styles.blue}> {domain}</span>.
                                You will see all your historical fixes on this
                                screen once you start pushing them.
                            </p>
                        </div>
                    )}
                    <div className={styles.historyBottom}>
                        <span className={styles.bottomText}>
                            {getSelectedFixes()} changes selected
                        </span>
                        <Button
                            Icon={() => (
                                <IoReturnUpBack
                                    style={{ marginRight: "10px" }}
                                    color="#fff"
                                    size={18}
                                />
                            )}
                            style={{
                                fontWeight: 400,
                            }}
                            iconReverse={true}
                            width={160}
                            text="Revert"
                            handler={() => {
                                setRevertModal(true);
                                // revertRequest();
                            }}
                            loading={reverting}
                            disabled={!getSelectedFixes()}
                        />
                    </div>
                    {revertModal && (
                        <Modal setModal={setRevertModal}>
                            <div className={styles.logoutContainer}>
                                <h2 style={{ fontWeight: "600" }}>
                                    Are You Sure?
                                </h2>
                                <div>
                                    Reverting changes will remove the selected
                                    changes from your domain. Are you sure?
                                </div>
                                <div className={styles.logoutBtns}>
                                    <Button
                                        handler={() => setRevertModal(false)}
                                        text="Cancel"
                                        width={150}
                                        secondary={true}
                                        style={{
                                            fontWeight: "300",
                                        }}
                                    />
                                    <Button
                                        handler={() => {
                                            revertRequest();
                                        }}
                                        text="Revert"
                                        width={150}
                                        loading={reverting}
                                        style={{
                                            fontWeight: "300",
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
            </ClickAwayListener>
        </div>
    );
}

const Row = ({ fix, setData, index, categoryIndex }) => {
    const [open, setOpen] = useState(false);
    const useAccordionCompleteStyle = makeStyles(() => {
        return {
            root: {
                backgroundColor: "none",
                boxShadow: "none",
                padding: 0,
                margin: "0 !important",
                "& .MuiAccordionSummary-root": {
                    padding: "2px 10px",
                    minHeight: 40,
                    border: "none",
                    borderTop: "1px solid #e1e4ed",
                    backgroundColor: open ? "#F6F7FB" : "white",
                },
                "& .MuiAccordionSummary-root:first-child": {
                    // borderTop: "none",
                },
                "& .MuiAccordionSummary-content": {
                    margin: "0",
                    alignItems: "center",
                },
                "& .MuiAccordionDetails-root": {
                    padding: "0",
                    flexDirection: "column",
                    backgroundColor: open ? "#F6F7FB" : "white",
                },
            },
        };
    });

    const getUI = (action) => {
        switch (action.action) {
            case "ADD":
                return (
                    <>
                        <li>
                            <BiCheck size={16} /> &nbsp; Add new tag
                        </li>
                        <div>{action.value}</div>
                    </>
                );

            case "UPDATE_VALUE":
                return (
                    <>
                        <li>
                            <BiCheck size={16} /> &nbsp; Update value to
                        </li>
                        <div>{action.value}</div>
                    </>
                );
            case "OPEN_IN_NEW_TAB":
                return (
                    <>
                        <div>
                            {action.value ? (
                                <BiCheck style={{ minWidth: 20 }} size={20} />
                            ) : (
                                <MdClose style={{ minWidth: 18 }} size={18} />
                            )}
                            &nbsp;{action.link ? action.link : ""}
                        </div>
                    </>
                );
            case "CHOOSE_FROM":
                return (
                    <>
                        <li>
                            <BiCheck size={16} /> &nbsp; Update value to
                        </li>

                        {action.options.map((opt) => (
                            <div>
                                <input
                                    type="radio"
                                    defaultChecked={action.value == opt.value}
                                    disabled={true}
                                />
                                {opt.value}
                            </div>
                        ))}
                    </>
                );
            case "INSIDE_HEAD":
                return (
                    <li>
                        <BiCheck color="#014dc5" size={20} />
                        &nbsp; Put inside head tag
                    </li>
                );

            default:
                break;
        }
    };

    const classes = useAccordionCompleteStyle();
    return (
        <Accordion
            onChange={(e, expanded) => setOpen(expanded)}
            classes={classes}
        >
            <AccordionSummary
                style={{ cursor: "pointer" }}
                expandIcon={<IoChevronDown size={16} />}
            >
                <div
                    style={{ opacity: fix.status == 2 ? 0.7 : 1 }}
                    className={styles.row}
                >
                    <div>
                        <CheckBox
                            colorLabel={
                                <LightTooltip
                                    arrow
                                    placement="bottom"
                                    title={
                                        new URL(fix?.meta_data?.page).pathname
                                    }
                                >
                                    <a
                                        style={{
                                            color: "var(--primary-blue)",
                                            background: "rgba(1, 77, 197, 0.1)",
                                            padding: "4px 10px",
                                            borderRadius: "4px",
                                        }}
                                        target="_blank"
                                        href={new URL(fix?.meta_data?.page)}
                                    >
                                        {new URL(fix?.meta_data?.page).pathname
                                            .length > 30
                                            ? `${new URL(
                                                  fix?.meta_data?.page,
                                              ).pathname.slice(0, 30)}...`
                                            : new URL(fix?.meta_data?.page)
                                                  .pathname || "-"}
                                    </a>
                                </LightTooltip>
                            }
                            color={"var(--primary-blue)"}
                            label={""}
                            props={{
                                checked: fix.checked,
                                disabled: fix.status == 2,
                            }}
                            handler={(e) => {
                                e.stopPropagation();
                                if (e.target.checked)
                                    setData((ps) => {
                                        const newArr = [...ps];

                                        newArr[categoryIndex].fixes[index] = {
                                            ...fix,
                                            checked: true,
                                        };

                                        return newArr;
                                    });
                                else {
                                    setData((ps) => {
                                        const newArr = [...ps];

                                        newArr[categoryIndex].fixes[index] = {
                                            ...fix,
                                            checked: false,
                                        };

                                        return newArr;
                                    });
                                }
                            }}
                        />
                    </div>
                    <span>{categoryMap[fix.meta_data.category].title}</span>
                    {fix.status !== 2 ? (
                        <div style={{ color: "#ACB3BF" }}>
                            <IssueTag tag={fix.meta_data.issueTags[0]} />
                            {fix.meta_data.issueTags.length > 1
                                ? `+ ${fix.meta_data.issueTags.length - 1} more`
                                : ""}
                        </div>
                    ) : (
                        <div style={{ color: "#ACB3BF" }}>
                            <IssueTag tag={{ title: "Reverted" }} />
                        </div>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.detailRow}>
                    <span>Date - Time:</span>

                    <div className={styles.dateTime}>
                        <span>
                            {moment(fix.created_at).format("DD/MM/YYYY")}
                        </span>

                        <span>{moment(fix.created_at).format("hh:mm A")}</span>
                    </div>
                </div>

                <div className={styles.detailRow}>
                    <span>Issues:</span>

                    <div>
                        {fix.meta_data.issueTags.map((tag) => (
                            <IssueTag tag={tag} />
                        ))}
                    </div>
                </div>
                <div className={styles.detailRow}>
                    <span>Action Taken</span>

                    <ul>
                        {fix.fix.map((action, i) => {
                            return (
                                <>
                                    {action.action == "OPEN_IN_NEW_TAB" &&
                                        i == 0 && (
                                            <li>
                                                <BiCheck size={20} />
                                                &nbsp; Enable link to open in
                                                new tab
                                            </li>
                                        )}
                                    {getUI(action)}
                                </>
                            );
                        })}
                    </ul>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

const HistoryLoader = () => {
    return (
        <>
            {[1, 1, 1, 1, 1, 1].map((i) => (
                <Skeleton height={50} />
            ))}
        </>
    );
};

export default HistoryWindow;
