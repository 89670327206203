import { ClickAwayListener } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import SVGIcon from "../../../atoms/SVGIcon";
import styles from "./OutlineBuilder.module.css";
function H2H3dropdown({
    type,
    handler = () => {},
    index,
    isPremiumOrSpecificSegment,
}) {
    const dispatch = useDispatch();
    const [tag, setTag] = useState(type);
    const [expand, setExpand] = useState(false);
    return (
        <ClickAwayListener
            onClickAway={() => {
                setExpand(false);
            }}
        >
            <div
                onClick={() => {
                    if (!isPremiumOrSpecificSegment) {
                        if (index == 0 && type == "h2") {
                            // dispatch(
                            //     displayAlert({
                            //         alertMessage:
                            //             "You cannot convert the first H2 to H3",
                            //         alertType: "error",
                            //     }),
                            // );
                            return;
                        }
                        setExpand(!expand);
                    }
                }}
                style={
                    index == 0 && type == "h2"
                        ? {
                              padding: "2px 4px",
                              cursor: "not-allowed",
                          }
                        : {}
                }
                className={
                    styles.H2H3dropdown +
                    " " +
                    (tag == "h2" ? styles.heading2 : styles.heading3) +
                    " " +
                    (expand ? styles.expand : "")
                }
            >
                {tag}
                {true && (
                    <>
                        {!expand ? (
                            <SVGIcon
                                src={"/New UI/SVG/RiArrowDownSFill.svg"}
                                size={14}
                            />
                        ) : (
                            <SVGIcon
                                src={"/New UI/SVG/RiArrowUpSFill.svg"}
                                size={14}
                            />
                        )}
                        {expand && (
                            <div className={styles.optContainer}>
                                <div
                                    onClick={() => {
                                        handler(tag == "h2" ? "h3" : "h2");
                                        setTag(tag == "h2" ? "h3" : "h2");
                                    }}
                                >
                                    {tag == "h2" ? "H3" : "H2"}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </ClickAwayListener>
    );
}

export default H2H3dropdown;
