import {
  Button,
  CircularProgress,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import axios from "axios";
import { updateUserInfo } from "../../../store/actions/auth";
import { url } from "../../../utility/config";

function PopUp({ headingText, buttonText, handler, description, setPopUp }) {
  const [loader, setLoader] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [GST, setGST] = useState("");
  const [error, setError] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const GSTregex = /^[0-9]{2}[0-9A-Z]{13}$/;
  function gstHandler() {
    if (!GSTregex.test(GST) && GST != "N/A") {
      setError(true);
      return;
    } else {
      axios({
        method: "post",
        url: url + "/api/user/updateCompanyDetails",
        headers: {
          Authorization: token,
        },
        data: {
          gst_number: GST,
        },
      })
        .then((res) => {
          dispatch(updateUserInfo("gst_no", GST));
        })
        .catch((err) => {
          dispatch(updateUserInfo("gst_no", null));
        });
    }
    setShow(false);
    window.localStorage.setItem("qazwsxedc", "12121DSadd33");
    return;
  }

  React.useEffect(() => {
    const listener1 = (e) => {
      if (e.key === "Escape") {
        setShow(false);
        window.localStorage.setItem("qazwsxedc", "12121DSadd33");
      }
    };

    document.addEventListener("keydown", listener1);

    return () => {
      document.removeEventListener("keydown", listener1);
    };
  }, []);

  if (!show) return null;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setShow(false);
        window.localStorage.setItem("qazwsxedc", "12121DSadd33");
      }}
      className={styles.popUpContainer}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.popUp}>
        <h3 className={styles.heading}>{headingText}</h3>

        <div>{description}</div>
        <TextField
          autoFocus
          spellCheck={false}
          disabled={GST === "N/A"}
          fullWidth
          label={"GST 15 Digit No."}
          variant="outlined"
          helperText={error == false ? "" : "Enter a valid GST Number"}
          value={GST}
          error={error}
          onChange={(e) => {
            setError(false);
            e.target.value.trim().length <= 15 &&
              setGST(e.target.value.trim().toUpperCase());
          }}
        ></TextField>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                e.target.checked
                  ? (() => {
                      setGST("N/A");
                      setError(false);
                    })()
                  : (() => {
                      setGST("");
                      setError(false);
                    })();
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="No, I Don't Have GST Number"
        />
        <div className={styles.buttons}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              gstHandler();
            }}
            style={{
              width: "100px",
              height: "40px",
            }}
          >
            {loader ? (
              <CircularProgress
                color={"white"}
                style={{ height: "20px", width: "20px" }}
              ></CircularProgress>
            ) : (
              buttonText
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
