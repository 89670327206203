import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../atoms/SVGIcon";
import KeywordInputSingle from "../../molecules/Creator/KeywordInput/KeywordInputSingle";
import NewUIDropDown, {
    TYPES,
} from "../../molecules/NewUIDropdown/NewUIDropDown";
import TagsFilter from "../../organisms/Account/TagsFilter";
import ReportsSkeleton from "../../organisms/DocCard/Skeleton";

import VideoModal from "../NewDashboard/VideoModal";
import styles from "./ClusterDoc.module.css";
import ClusterDocRow from "./ClusterDocRow";
import clusterDocsHook from "./ClusterDocsHook";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { VscClose } from "react-icons/vsc";
import { ClickAwayListener, Link } from "@material-ui/core";
import { debounceWrapper } from "../../../utility/helperFun";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Modal from "../../atoms/Modal/Modal";
import ModuleTemplate from "../../templates/ModuleTemplate/ModuleTemplate";
import useHelperFunctions from "../../helperFunctions";

const sortByOptions = [{ title: "created at", id: 1 }];
export const ClusterDocs = () => {
    const {
        isLoading,
        setIsLoading,
        makeReport,
        docs,
        getReports,
        openDoc,
        requestData,
        setRequestData,
        hasMore,
        apiCalls,
        loadingCreation,
        handleArchive,
        hasUsedKeywordGap,
        setHasUsedKeywordGap,
    } = clusterDocsHook({ bin: false });
    const dispatch = useDispatch();
    const [filterTagList, setFilterTagsList] = useState([]);
    const [sortBy, setSortBy] = useState(sortByOptions[0]);
    const [order, setOrder] = useState(0);
    const [clusterBooster, setClusterBooster] = useState(false);
    const [initiateClose, setInitiateClose] = useState(false);
    const [showModal, setShowModal] = useState(loadingCreation);
    const otherInfo = useSelector((store) => store.otherInfo);
    const userVideo = useSelector((state) => state.video.cluster);
    const email = useSelector((state) => state.auth.email);
    const { getData } = useHelperFunctions();
    let timeoutRef = useRef(null);

    useEffect(() => {}, [userVideo]);
    useEffect(() => {
        debounceWrapper(
            () => {
                if (otherInfo.userIntegrationInfo?.length > 0) {
                    for (
                        let i = 0;
                        i < otherInfo.userIntegrationInfo.length;
                        i++
                    ) {
                        if (otherInfo.userIntegrationInfo[i].app_id === 3) {
                            if (
                                otherInfo.userIntegrationInfo[i].app_linked ===
                                1
                            ) {
                                setClusterBooster(true);
                            }
                        }
                    }
                }
            },
            1000,
            timeoutRef,
        );
    }, [otherInfo]);

    useEffect(() => {
        if (loadingCreation) {
            setShowModal(true);
        }
    }, [loadingCreation]);

    useEffect(() => {
        if (showModal) {
            setTimeout(() => {
                setShowModal(false);
            }, 5000);
        }
    }, [showModal]);

    useEffect(() => {
        setIsLoading(true);

        setRequestData((ps) => ({
            ...ps,
            page: 1,
            created: sortBy.id == 1 ? true : false,
            updated: sortBy.id == 2 ? true : false,
            order,
            tags: filterTagList.map((tag) => tag.id),
        }));
    }, [sortBy, order, filterTagList]);

    return (
        <>
            {showModal && (
                <Modal customClass={styles.modal} setModal={setShowModal}>
                    <div className={styles.modalContent}>
                        <SVGIcon
                            src="/New UI/SVG/Scalenut.svg"
                            size={36}
                            className={styles.rotatingIcon}
                        />
                        <p className={styles.modalPara}>
                            Please wait while we build the clusters for you, it
                            usually takes less than 2 minutes. We will notify
                            you once ready on {email}
                        </p>
                    </div>
                </Modal>
            )}
            <ModuleTemplate
                HeaderChild={
                    <>
                        <KeywordInputSingle
                            loadingCreation={loadingCreation}
                            makeReport={makeReport}
                            docs={docs}
                            apiCalls={apiCalls}
                            buttonText={"Plan"}
                            cluster={true}
                            setShowModal={setShowModal}
                            clusterBooster={clusterBooster}
                            docsLoading={isLoading}
                            hasUsedKeywordGap={hasUsedKeywordGap}
                            setHasUsedKeywordGap={setHasUsedKeywordGap}
                        />
                    </>
                }
                initiateClose={initiateClose}
                userVideo={userVideo}
                setInitiateClose={setInitiateClose}
                heading="Keyword Planner"
                subHeading="Get related keyword clusters to build topical authority"
                sourceWhere={"cluster"}
                stickyHeaderChild={
                    <>
                        <div
                            style={{ padding: 20 }}
                            className={styles.rrFilters}
                        >
                            {true ? (
                                // <h4>All Keyword Report</h4>
                                <></>
                            ) : (
                                <h4 style={{ color: "red" }}>
                                    {" "}
                                    Deleted SEO Articles{" "}
                                </h4>
                            )}
                            <div
                                className={styles.rrFilters}
                                style={{ flexDirection: "row" }}
                            >
                                <div
                                    style={{ width: "280px" }}
                                    className={styles.search}
                                >
                                    <Textfield
                                        height={40}
                                        containerStyle={{ marginBottom: "0" }}
                                        props={{
                                            onChange: (e) => {
                                                const value =
                                                    e.target.value.trim();
                                                setRequestData((ps) => ({
                                                    ...ps,
                                                    search: value,
                                                    page: 1,
                                                }));
                                            },
                                            style: {
                                                borderRadius: 3,
                                                border: "solid 1px rgba(75, 75, 85, 0.16)",
                                                backgroundColor: "#fff",
                                                paddingLeft: "40px",
                                                borderRadius: 3,
                                            },
                                        }}
                                        placeholder="Search Keyword Reports"
                                        label=""
                                        Icon={() => (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/searchIcon.svg"
                                                }
                                            />
                                        )}
                                        iconStyle={{
                                            left: "10px",
                                            right: "unset",
                                            top: "20px",
                                        }}
                                    />
                                </div>

                                <TagsFilter
                                    setFilterTagsList={setFilterTagsList}
                                    style={{ with: "145px" }}
                                />
                            </div>
                        </div>
                    </>
                }
                content={
                    <div>
                        {isLoading ? (
                            <>
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                                <ReportsSkeleton type={true} />
                            </>
                        ) : (
                            <InfiniteScroll
                                dataLength={docs.length}
                                next={() => {
                                    setRequestData((ps) => ({
                                        ...ps,
                                        page: requestData.page + 1,
                                    }));
                                }}
                                scrollableTarget="scrollableDiv"
                                hasMore={hasMore}
                                loader={
                                    <>
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                        <ReportsSkeleton type={true} />
                                    </>
                                }
                            >
                                {
                                    <>
                                        <table className={styles.clusterTable}>
                                            <tr className={styles.reportHeader}>
                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                        width: "550px",
                                                    }}
                                                >
                                                    <div>
                                                        <span>Keyword</span>{" "}
                                                        &nbsp;
                                                    </div>
                                                </td>

                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                        width: "200px",
                                                    }}
                                                >
                                                    <div>
                                                        <span>Created at</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div>
                                                        <span> </span>
                                                        &nbsp;
                                                    </div>
                                                </td>
                                            </tr>

                                            {docs.map((item, index) => {
                                                return (
                                                    <ClusterDocRow
                                                        bin={false}
                                                        data={item}
                                                        key={item.topic_id}
                                                        openDoc={openDoc}
                                                        handleArchive={
                                                            handleArchive
                                                        }
                                                    />
                                                );
                                            })}
                                        </table>
                                        {docs.length == 0 && (
                                            <div className={styles.noItems}>
                                                {
                                                    <>
                                                        <SVGIcon
                                                            style={{
                                                                margin: "auto",
                                                            }}
                                                            src={
                                                                "/New UI/SVG/Emptydocument.svg"
                                                            }
                                                            size={300}
                                                        />
                                                        <h3
                                                            style={{
                                                                marginTop: -50,
                                                            }}
                                                        >
                                                            No Keyword Reports
                                                            Found
                                                        </h3>
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </>
                                }
                            </InfiniteScroll>
                        )}
                    </div>
                }
            />
        </>
    );
};
