import React, { useEffect, useState } from "react";
import styles from "./transactions.module.css";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { url } from "../../../../utility/config";
import CheckoutForm from "./checkoutForm";
import { BiWallet } from "react-icons/bi";
import { thousands_separators } from "../../../../utility/validateFunctions";
import qs from "qs";
import { getCurrency } from "./TransactionListItem";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import Button from "../../../../componentsV2/atoms/Button/Button";

let transactionId = null;
let clientSecret = null;
export default function AmountLeft({
    rechargeDialog,
    setDialog,
    email,
    userId,
    userCurrency,
    balance,
    setUserCurrency,
}) {
    const role = useSelector((state) => state.auth.role);
    const currency = useSelector((state) => state.auth.currency);
    const [drop, setDrop] = useState(true);

    return (
        <div className={styles.amountLeftContainer}>
            <div className={styles.amountLeft__right}>
                <div className={styles.amountLeftContainerInner}>
                    <div>
                        <div className={styles.wallet}>
                            <BiWallet
                                style={{
                                    fontSize: "1.9rem",
                                }}
                            />
                        </div>
                        <span className={styles.availableBalanceHeading}>
                            Available Balance
                        </span>
                        <p
                            style={{ fontFamily: "Inter" }}
                            className={styles.availableAmount}
                        >
                            {balance.balance
                                ? balance.balance >= 0
                                    ? `${getCurrency(
                                          role,
                                          userCurrency,
                                          currency,
                                          userId,
                                      )}${thousands_separators(
                                          +balance.balance,
                                      )}`
                                    : `- ${getCurrency(
                                          role,
                                          userCurrency,
                                          currency,
                                          userId,
                                      )}${thousands_separators(
                                          +Math.abs(balance.balance),
                                      )}`
                                : `${getCurrency(
                                      role,
                                      userCurrency,
                                      currency,
                                      userId,
                                  )} 0`}

                            {/* {!drop?<RiArrowDownSLine color="#b9b9b9" size="20px" onClick={()=>setDrop(true)}/>:<RiArrowUpSLine color="#b9b9b9" size="20px" onClick={()=>setDrop(false)} />} */}
                        </p>
                    </div>
                    <Button
                        handler={() => setDialog(true)}
                        id={"gt-account-recharge"}
                        customClass={styles.rechargeButton}
                        height={45}
                        width={170}
                        text={"Recharge Credits"}
                    />
                </div>
                {drop && balance && (
                    <div className={styles.Rectangle}>
                        <div>
                            Purchased SaaS Credits &nbsp;
                            <span>
                                {balance.individualBalance.walletCredits
                                    ? balance.individualBalance.walletCredits >=
                                      0
                                        ? `${getCurrency(
                                              role,
                                              userCurrency,
                                              currency,
                                              userId,
                                          )}${thousands_separators(
                                              +balance.individualBalance
                                                  .walletCredits,
                                          )}`
                                        : `- ${getCurrency(
                                              role,
                                              userCurrency,
                                              currency,
                                              userId,
                                          )}${thousands_separators(
                                              +Math.abs(
                                                  balance.individualBalance
                                                      .walletCredits,
                                              ),
                                          )}`
                                    : `${getCurrency(
                                          role,
                                          userCurrency,
                                          currency,
                                          userId,
                                      )} 0`}
                            </span>
                        </div>
                        <div style={{ borderBottom: "none" }}>
                            Promotional SaaS Credits &nbsp;
                            <span>
                                {balance.individualBalance.promotionalCredits
                                    ? balance.individualBalance
                                          .promotionalCredits >= 0
                                        ? `${getCurrency(
                                              role,
                                              userCurrency,
                                              currency,
                                              userId,
                                          )}${thousands_separators(
                                              +balance.individualBalance
                                                  .promotionalCredits,
                                          )}`
                                        : `- ${getCurrency(
                                              role,
                                              userCurrency,
                                              currency,
                                              userId,
                                          )}${thousands_separators(
                                              +Math.abs(
                                                  balance.individualBalance
                                                      .promotionalCredits,
                                              ),
                                          )}`
                                    : `${getCurrency(
                                          role,
                                          userCurrency,
                                          currency,
                                          userId,
                                      )} 0`}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
