import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import styles from "./KeywordCannibalization.module.css";
import {
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    Tooltip,
    makeStyles,
    withStyles,
} from "@material-ui/core";
import SVGIcon from "../../../atoms/SVGIcon";
import { VscClose } from "react-icons/vsc";
import {
    CannibalizationKeywordObject,
    CannibalizationOtherInfoObject,
    CannibalizationRequestPayload,
} from "../useGscHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import Button from "../../../atoms/Button/Button";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import { debounceWrapper } from "../../../../utility/helperFun";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
    resetDefaultCannibalizationFilters,
    updateCannibalizationFilters,
} from "../../../../store/actions/userGscInfo";
import { useMixpanelTA } from "../useMixpanelTA";
import { TabsName } from "../TrafficAnalyzer";

type Props_CannibalizationTopFilters = {
    requestedData: CannibalizationRequestPayload;
    setCannibalizationData: Dispatch<
        SetStateAction<CannibalizationKeywordObject[]>
    >;
};

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        marginTop: 0,
        marginRight: -20,
    },
}));

const FilterDropDownToolTip = withStyles((theme) => ({
    arrow: {
        fontSize: 24,
        "&::before": {
            border: "2px solid #E1E4ED",
        },

        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
        position: "relative",
        maxWidth: "300px",
        border: "1px solid #E1E4ED",
        borderRadius: "6px",
        padding: "0px",
        right: "10px",
        top: "-3px",
    },
}))(Tooltip);

const MinMaxFilter = ({
    title,
    minMax,
    keyName,
    setCannibalizationData,
}: {
    title: string;
    minMax: { min: number; max?: number };
    keyName: string;
    setCannibalizationData: Props_CannibalizationTopFilters["setCannibalizationData"];
}) => {
    const [isCtaDisabled, setIsCtaDisabled] = useState(true);
    const [_min, setMin] = useState<number>();
    const [_max, setMax] = useState<number>();
    const [isOpen, setIsOpen] = useState(false);
    const [isFocusMin, setIsFocusMin] = useState(false);
    const [isFocusMax, setIsFocusMax] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(true);
    const { taTrackFilters } = useMixpanelTA();
    const dispatch = useDispatch();
    useEffect(() => {
        if (minMax?.max == undefined && minMax?.min == undefined) {
            setIsFilterActive(false);
        } else {
            setIsFilterActive(true);
        }
        if (minMax?.hasOwnProperty("min")) {
            setMin(minMax?.min);
        } else {
            setMin(undefined);
        }
        if (minMax?.hasOwnProperty("max")) {
            setMax(minMax?.max);
        } else {
            setMax(undefined);
        }
    }, [minMax]);

    const handleMinMax: (
        _minVal: number | undefined,
        _maxVal: number | undefined,
    ) => void = (_minVal, _maxVal) => {
        let temp: { min?: number; max?: number } = {};
        if (_maxVal != undefined && _maxVal >= 0) {
            temp.max = Number(_maxVal);
        }
        if (_minVal != undefined && _minVal >= 0) {
            temp.min = Number(_minVal);
        }
        // @ts-ignore
        if (temp?.max >= 0 && temp.min >= 0) {
            setIsFilterActive(true);
        } else {
            setIsFilterActive(false);
            setMax(undefined);
            setMin(undefined);
        }

        dispatch(
            updateCannibalizationFilters({
                currentPage: 0,
                [keyName]: { ...temp },
            }),
        );
        setIsOpen(false);
        setIsFocusMax(false);
        setIsFocusMin(false);
        setIsCtaDisabled(true);
    };

    return (
        <FilterDropDownToolTip
            open={isOpen}
            disableFocusListener
            title={
                <ClickAwayListener
                    onClickAway={() => {
                        setIsOpen(false);
                        setIsFocusMax(false);
                        setIsFocusMin(false);
                    }}
                >
                    <div className={styles.minMaxDropdown}>
                        <div className={styles.minMaxInputs}>
                            <div className={styles.inputWrapper_left}>
                                <div
                                    className={`${styles.inputLabelAnimate} ${
                                        isFocusMin && styles.active
                                    }`}
                                >
                                    Min
                                </div>
                                <Textfield
                                    // @ts-ignore
                                    customClass={styles.minInput}
                                    height={30}
                                    label={""}
                                    containerStyle={{
                                        marginBottom: "0",
                                    }}
                                    props={{
                                        onChange: (e: any) => {
                                            setIsFocusMin(true);
                                            const value = e.target.value.trim();
                                            if (isNaN(value)) {
                                                return;
                                            }
                                            setIsCtaDisabled(false);
                                            setMin(value || undefined);
                                        },
                                        onFocus: () => {
                                            setIsFocusMin(true);
                                        },
                                        onBlur: () => {
                                            setIsFocusMin(false);
                                        },
                                        value:
                                            _min != undefined && _min >= 0
                                                ? _min
                                                : "",
                                        style: {
                                            borderRadius: 6,
                                            border: `solid 1px ${"#e1e4ed"}`,
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    placeholder={isFocusMin ? "" : "Min"}
                                />
                            </div>
                            <div className={styles.inputWrapper_right}>
                                <div
                                    className={`${styles.inputLabelAnimate} ${
                                        isFocusMax && styles.active
                                    }`}
                                >
                                    Max
                                </div>
                                <Textfield
                                    // @ts-ignore
                                    customClass={styles.maxInput}
                                    height={30}
                                    label={""}
                                    containerStyle={{
                                        marginBottom: "0",
                                    }}
                                    props={{
                                        onChange: (e: any) => {
                                            setIsFocusMax(true);
                                            const value = e.target.value.trim();
                                            if (isNaN(value)) {
                                                return;
                                            }
                                            setIsCtaDisabled(false);
                                            setMax(value || undefined);
                                        },
                                        onFocus: () => {
                                            setIsFocusMax(true);
                                        },
                                        onBlur: () => {
                                            setIsFocusMax(false);
                                        },
                                        value:
                                            _max != undefined && _max >= 0
                                                ? _max
                                                : "",
                                        style: {
                                            borderRadius: 6,
                                            border: `solid 1px ${"#e1e4ed"}`,
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    placeholder={isFocusMax ? "" : "Max"}
                                />
                            </div>
                        </div>
                        <div className={styles.minMaxSubtext}>
                            Set min and max {title}, then Apply.
                        </div>
                        <div className={styles.minMaxCta}>
                            <Button
                                text={"Apply"}
                                handler={() => {
                                    taTrackFilters({
                                        tabName: TabsName.cannibalization,
                                        filterType: title,
                                        cta: "Apply",
                                    });
                                    setCannibalizationData([]);
                                    handleMinMax(_min, _max);
                                }}
                                disabled={isCtaDisabled}
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    height: "30px",
                                    padding: "10px",
                                    width: "100%",
                                    borderRadius: "6px",
                                }}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            }
            interactive
            arrow
            placement="bottom"
        >
            <div
                className={`${styles.minMaxTitleWrapper} ${
                    isFilterActive && styles.activeFilters
                }`}
            >
                <div
                    className={`${styles.minMaxTitle} ${
                        isFilterActive && styles.filterActive
                    }`}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    {title}
                    {/* only max value */}
                    {minMax?.min == undefined &&
                        minMax?.max != undefined &&
                        minMax?.max >= 0 &&
                        ": " + 0 + "-" + minMax.max}
                    {/* only min value */}
                    {minMax?.min >= 0 &&
                        !minMax?.max &&
                        ": " + minMax.min + "+"}
                    {/* max & min value */}
                    {minMax?.min >= 0 &&
                        minMax?.max &&
                        minMax?.max > 0 &&
                        ": " + minMax.min + "-" + minMax.max}
                    {!isFilterActive && (
                        <SVGIcon
                            src={`/New UI/SVG/${
                                isOpen ? "arrow-head-up" : "arrow-head-down"
                            }.svg`}
                            size={isOpen ? 11 : 11}
                            style={{
                                color: isFilterActive ? "#014dc5" : "#818ea3",
                            }}
                        />
                    )}
                </div>
                <div
                    className={styles.dropdownIcons}
                    onClick={(e) => {
                        e.preventDefault();
                        taTrackFilters({
                            tabName: TabsName.cannibalization,
                            filterType: title,
                            cta: "Reset",
                        });
                        setCannibalizationData([]);

                        handleMinMax(undefined, undefined);
                    }}
                >
                    {isFilterActive && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <VscClose size={18} style={{ color: "#000000" }} />
                        </div>
                    )}
                </div>
            </div>
        </FilterDropDownToolTip>
    );
};

const InputTerms = ({
    title,
    includedTerms,
    excludedTerms,
    keyIncluded,
    keyExcluded,
    setCannibalizationData,
}: {
    title: string;
    includedTerms: string[];
    excludedTerms: string[];
    keyIncluded: string;
    keyExcluded: string;
    setCannibalizationData: Props_CannibalizationTopFilters["setCannibalizationData"];
}) => {
    const [isCtaDisabled, setIsCtaDisabled] = useState(true);
    const [_inputIncluded, setInputIncluded] = useState("");
    const [_inputExcluded, setInputExcluded] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(true);
    const [count, setCount] = useState(0);
    const { taTrackFilters } = useMixpanelTA();
    const dispatch = useDispatch();
    useEffect(() => {
        if (excludedTerms?.length > 0 || includedTerms?.length > 0) {
            setIsFilterActive(true);
            setCount(excludedTerms?.length + includedTerms.length);
            setInputIncluded(includedTerms.join(","));
            setInputExcluded(excludedTerms.join(","));
        } else {
            setIsFilterActive(false);
            setCount(0);
        }
    }, [excludedTerms, includedTerms]);

    const handleInputTerms: (
        includedKeys: string,
        excludedKeys: string,
    ) => void = (includedKeys, excludedKeys) => {
        let tempArrInc: string[] = [];
        let tempArrExc: string[] = [];

        if (includedKeys?.trim()) {
            let keywords = includedKeys.split(",");
            keywords.forEach((keyword) => {
                let word = keyword.trim();
                if (word.length > 0) {
                    if (word.length > 100) {
                        tempArrInc.push(word?.substring(0, 100));
                    } else {
                        tempArrInc.push(word);
                    }
                }
            });
        }

        if (excludedKeys?.trim()) {
            let keywords = excludedKeys.split(",");
            keywords.forEach((keyword) => {
                let word = keyword.trim();
                if (word.length > 0) {
                    if (word.length > 100) {
                        tempArrExc.push(word?.substring(0, 100));
                    } else {
                        tempArrExc.push(word);
                    }
                }
            });
        }
        setInputIncluded([...tempArrInc].join(","));
        setInputExcluded([...tempArrExc].join(","));
        dispatch(
            updateCannibalizationFilters({
                currentPage: 0,
                excluded_terms: tempArrExc.length > 0 ? [...tempArrExc] : [],
                included_terms: tempArrInc.length > 0 ? [...tempArrInc] : [],
            }),
        );
        setIsOpen(false);

        setIsCtaDisabled(true);
    };
    return (
        <FilterDropDownToolTip
            open={isOpen}
            disableFocusListener
            title={
                <ClickAwayListener
                    onClickAway={() => {
                        setIsOpen(false);
                    }}
                >
                    <div className={styles.inputTermsDropdown}>
                        {/* Included Terms */}
                        <div className={styles.inputBoxWrapper}>
                            <div className={styles.labelForInputTerms}>
                                Include Terms
                            </div>
                            <div className={styles.inputIncluded}>
                                <Textfield
                                    // @ts-ignore
                                    customClass={styles.termsInput}
                                    height={30}
                                    label={""}
                                    containerStyle={{
                                        marginBottom: "0",
                                        width: "100%",
                                    }}
                                    props={{
                                        onChange: (e: any) => {
                                            setIsCtaDisabled(false);
                                            const value = e.target.value;
                                            const reg =
                                                /^[0-9a-zA-Z,  ]+$/.test(value);

                                            if (reg) {
                                                setInputIncluded(value);
                                            }
                                        },
                                        onKeyDown: (e: any) => {
                                            if (
                                                e.key == "Backspace" &&
                                                _inputIncluded.length <= 1
                                            ) {
                                                setInputIncluded("");
                                            } else if (
                                                // @ts-ignore
                                                window
                                                    ?.getSelection()
                                                    ?.toString().length ==
                                                    _inputIncluded.length &&
                                                e.key == "Backspace"
                                            ) {
                                                setInputIncluded("");
                                            }
                                        },
                                        value: _inputIncluded,
                                        style: {
                                            borderRadius: 6,
                                            border: `solid 1px ${"#e1e4ed"}`,
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    placeholder={`Add`}
                                />
                            </div>
                            <div className={styles.inputIncludedSubtext}>
                                Separate multiple terms with a comma.
                            </div>
                        </div>
                        {/* Excluded Terms */}
                        <div className={styles.inputBoxWrapper}>
                            <div className={styles.labelForInputTerms}>
                                Exclude Terms
                            </div>
                            <div className={styles.inputExcluded}>
                                <Textfield
                                    // @ts-ignore
                                    customClass={styles.termsInput}
                                    height={30}
                                    label={""}
                                    containerStyle={{
                                        marginBottom: "0",
                                        width: "100%",
                                    }}
                                    props={{
                                        onChange: (e: any) => {
                                            setIsCtaDisabled(false);
                                            const value = e.target.value;
                                            const reg =
                                                /^[0-9a-zA-Z,  ]+$/.test(value);

                                            if (reg) {
                                                setInputExcluded(value);
                                            }
                                        },
                                        onKeyDown: (e: any) => {
                                            if (
                                                e.key == "Backspace" &&
                                                _inputExcluded.length == 1
                                            ) {
                                                setInputExcluded("");
                                            } else if (
                                                // @ts-ignore
                                                window
                                                    ?.getSelection()
                                                    ?.toString().length ==
                                                    _inputExcluded.length &&
                                                e.key == "Backspace"
                                            ) {
                                                setInputExcluded("");
                                            }
                                        },
                                        value: _inputExcluded,
                                        style: {
                                            borderRadius: 6,
                                            border: `solid 1px ${"#e1e4ed"}`,
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    placeholder={`Add`}
                                />
                            </div>
                            <div className={styles.inputExcludedSubtext}>
                                Separate multiple terms with a comma.
                            </div>
                        </div>

                        <div className={styles.inputTermsCta}>
                            <Button
                                text={"Apply"}
                                disabled={isCtaDisabled}
                                handler={() => {
                                    taTrackFilters({
                                        tabName: TabsName.cannibalization,
                                        filterType: title,
                                        cta: "Apply",
                                    });
                                    setCannibalizationData([]);
                                    handleInputTerms(
                                        _inputIncluded,
                                        _inputExcluded,
                                    );
                                }}
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    height: "30px",
                                    padding: "10px",
                                    width: "100%",
                                    borderRadius: "6px",
                                }}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            }
            interactive
            arrow
            placement="bottom"
        >
            <div
                className={`${styles.minMaxTitleWrapper} ${
                    isFilterActive && styles.activeFilters
                }`}
            >
                <div
                    className={`${styles.minMaxTitle} ${
                        isFilterActive && styles.filterActive
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {`${title}${
                        isFilterActive && count > 0 ? `: ` + count : ""
                    }  ${
                        isFilterActive && count > 0
                            ? count == 1
                                ? "keyword"
                                : "keywords"
                            : ""
                    }`}
                    {!isFilterActive && (
                        <SVGIcon
                            src={`/New UI/SVG/${
                                isOpen ? "arrow-head-up" : "arrow-head-down"
                            }.svg`}
                            size={isOpen ? 11 : 11}
                            style={{
                                color: isFilterActive ? "#014dc5" : "#818ea3",
                            }}
                        />
                    )}
                </div>
                <div
                    className={styles.dropdownIcons}
                    onClick={(e) => {
                        e.preventDefault();
                        taTrackFilters({
                            tabName: TabsName.cannibalization,
                            filterType: title,
                            cta: "Reset",
                        });
                        setInputExcluded("");
                        setCannibalizationData([]);
                        setInputIncluded("");
                        dispatch(
                            updateCannibalizationFilters({
                                excluded_terms: [],
                                included_terms: [],
                            }),
                        );
                    }}
                >
                    {isFilterActive && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <VscClose size={18} style={{ color: "#000000" }} />
                        </div>
                    )}
                </div>
            </div>
        </FilterDropDownToolTip>
    );
};

const CannibalizationTopFilters = ({
    requestedData,
    setCannibalizationData,
}: Props_CannibalizationTopFilters) => {
    const [showReset, setShowReset] = useState(false);
    const { default_cannibalization_filters } = useSelector(
        (state: any) => state.userGscInfo,
    );
    const { taTrackFilters } = useMixpanelTA();
    const dispatch = useDispatch();
    useEffect(() => {
        const isObjectDifferent: (
            defaultObj: CannibalizationRequestPayload,
            obj: CannibalizationRequestPayload,
        ) => boolean = (defaultObj, obj) => {
            for (const key in defaultObj) {
                if (
                    // @ts-ignore
                    JSON.stringify(obj[key]) !== JSON.stringify(defaultObj[key])
                ) {
                    return true;
                }
            }
            return false;
        };
        setShowReset(
            isObjectDifferent(default_cannibalization_filters, requestedData),
        );
    }, [showReset, requestedData]);

    return (
        <div className={styles.cannibalizationTopFilters}>
            <div className={styles.filterOptionsWrapper}>
                <div className={styles.crFilterWrapper}>
                    <MinMaxFilter
                        title={"Cannibalization Index"}
                        minMax={requestedData.cr}
                        keyName={"cr"}
                        setCannibalizationData={setCannibalizationData}
                    />
                </div>
                <div className={styles.impressionFilterWrapper}>
                    <MinMaxFilter
                        title={"Impressions"}
                        minMax={requestedData.impression}
                        keyName={"impression"}
                        setCannibalizationData={setCannibalizationData}
                    />
                </div>
                <div className={styles.avgPosFilterWrapper}>
                    <MinMaxFilter
                        title={"Position"}
                        minMax={requestedData.avg_pos}
                        keyName={"avg_pos"}
                        setCannibalizationData={setCannibalizationData}
                    />
                </div>
                <div className={styles.includedTermsFilterWrapper}>
                    <InputTerms
                        title={"Keywords"}
                        keyIncluded={"included_terms"}
                        keyExcluded={"excluded_terms"}
                        includedTerms={requestedData?.included_terms || []}
                        excludedTerms={requestedData?.excluded_terms || []}
                        setCannibalizationData={setCannibalizationData}
                    />
                </div>
            </div>
            {showReset && (
                <div
                    className={styles.resetDefaultFilters}
                    onClick={() => {
                        taTrackFilters({
                            tabName: TabsName.cannibalization,
                            filterType: "Reset To Default",
                            cta: "Reset",
                        });
                        setShowReset(false);
                        setCannibalizationData([]);
                        dispatch(resetDefaultCannibalizationFilters());
                    }}
                >
                    Reset to Default
                </div>
            )}
        </div>
    );
};

export default CannibalizationTopFilters;
