import { useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import styles from "./LocalPagination.module.css";

const LocalPagination = ({
    pageCount = 1,
    onPageChange = (a) => {},
    currentPage,
    styles2 = styles,
}) => {
    useEffect(() => {}, [currentPage]);

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel={<FaChevronRight />}
            onPageChange={onPageChange}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            pageCount={pageCount}
            previousLabel={<FaChevronLeft />}
            renderOnZeroPageCount={null}
            containerClassName={styles2.pagination}
            activeClassName={styles2.active}
            pageLinkClassName={styles2.pageLink}
            breakLinkClassName={styles2.pageLink}
            nextLinkClassName={styles2.pageLink}
            previousLinkClassName={styles2.pageLink}
            pageClassName={styles2.pageItem}
            breakClassName={styles2.pageItem}
            nextClassName={styles2.ctaNext}
            previousClassName={styles2.ctaPrev}
            disabledClassName={styles2.disabledCta}
        />
    );
};

export default LocalPagination;
