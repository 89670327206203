import React from "react";
import { useSelector } from "react-redux";
import styles from "./UpgradeModal.module.css";
import mixpanel from "mixpanel-browser";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Button from "../../atoms/Button/Button";
import Modal from "../../atoms/Modal/Modal";
import { useLinkManagerMixpanel } from "../InterLinking/useLinkManagerMixpanel";

const UpgradeModal = ({
    setModal = () => {},
    forMulti = false,
    screen = "",
}) => {
    const { lMUpgradePlan } = useLinkManagerMixpanel();

    const otherInfo = useSelector((state) => state.otherInfo);

    const goto = useNavigation();
    return (
        <Modal
            setModal={() => {
                setModal({ isShow: false });
            }}
        >
            <div className={styles.upgradeModalWrapper}>
                <div
                    className={styles.upgradeTitle}
                    style={{ textAlign: "center" }}
                >
                    Want to find Internal Linking opportunities <br /> in more
                    domains?
                </div>
                <div className={styles.upgradeMsg}>
                    Buy any of our base plan and top-up with our Link Manager
                    add-on
                </div>
                <div className={styles.upgradeCtas}>
                    <Button
                        text={"Cancel"}
                        handler={() => {
                            lMUpgradePlan({
                                screen: screen || "-",
                                ctaUsed: "Cancel",
                            });
                            setModal({ isShow: false });
                        }}
                        style={{
                            color: "var( --secondary-color2)",
                            background: "var(--secondary-color2-op01",
                        }}
                        width={120}
                    />
                    <Button
                        text={"Buy Now"}
                        handler={() => {
                            lMUpgradePlan({
                                screen: screen || "-",
                                ctaUsed: "Buy Now",
                            });
                            goto("BILLING_PLANS", [], "/addon");
                        }}
                        width={120}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default UpgradeModal;
