import { CircularProgress } from "@material-ui/core";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Logout from "../authentication/logout";
import styles from "./account.module.css";
import { sidebarFields } from "./AccountData";
import { getPlan } from "../../utility/validateFunctions";
import profileFallback from "../../assets/images/icons/brandprofileicon.svg";
import Context from "../context/context";
import supportImg from "../../assets/images/icons/support-icon.svg";
import logoutImg from "../../assets/images/icons/logout-icon.svg";
import saveImg from "../../assets/images/icons/save-big-icon.svg";

export const getInitials = (username) => {
    let initials = "";
    const usernameArray = username.split(" ");
    initials += usernameArray[0].charAt(0);

    if (usernameArray.length > 1) {
        initials += usernameArray[1].charAt(0);
    }

    return initials;
};

export default function SettingsSidebar({
    userData,
    userDataLoading,
    setDialog,
}) {
    const role = useSelector((state) => state.auth.role);
    const plan = useSelector((state) => state.auth.plan_id);
    const brandProfileLogo = useSelector(
        (state) => state.auth.brandProfileLogo,
    );

    function getFields(role, field, plan, planID) {
        if (
            field.name === "Marketplace Transactions" ||
            field.name === "Billing Plan"
        ) {
            if (getPlan(plan) == "Custom") {
                return null;
            }
        }

        if (field.name === "Billing Plan") {
            if (role == "admin" || role == "account_manager") {
                return null;
            }
        }

        if (planID === 6 && field.name === "Billing Plan") return null;

        return (
            <div className={styles.sidebar__linkContainer}>
                <NavLink
                    className={
                        field.name === "Brand Profile"
                            ? styles.brandProfile__link
                            : styles.sidebar__link
                    }
                    activeClassName={
                        field.name === "Brand Profile"
                            ? `${styles.active} ${styles.brandProfile__link}`
                            : `${styles.active} ${styles.sidebar__link}`
                    }
                    to={
                        (role == "free_lancer"
                            ? "/user/account"
                            : "/admin/account") + `${field.path}`
                    }
                >
                    <img
                        src={
                            field.name === "Brand Profile"
                                ? brandProfileLogo
                                    ? brandProfileLogo
                                    : profileFallback
                                : field.icon
                        }
                        className={
                            field.name === "Brand Profile"
                                ? styles.fieldIcon + " " + styles.fieldIconImage
                                : styles.fieldIcon
                        }
                    />
                    {field.name}
                </NavLink>
            </div>
        );
    }

    const planID = useContext(Context);
    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebar__userInfo}>
                <div className={styles.sidebar__userInfo__imageContainer}>
                    <div
                        className={
                            styles.sidebar__userInfo__imageContainer__image
                        }
                    >
                        {!userDataLoading ? (
                            userData.profile_picture ? (
                                <img src={userData.profile_picture} />
                            ) : (
                                <span>
                                    {userData.name &&
                                        getInitials(userData.name)}
                                </span>
                            )
                        ) : (
                            <CircularProgress />
                        )}
                    </div>
                </div>
                <p className={styles.sidebar__userInfo__username}>
                    {userData.name}
                </p>
                <p className={styles.sidebar__userInfo__email}>
                    {userData.email}
                </p>
            </div>
            <div className={styles.sidebar__links}>
                {sidebarFields.map((field, i) =>
                    role == "free_lancer"
                        ? i == 0 && (
                              <div
                                  key={i}
                                  className={styles.sidebar__linkContainer}
                              >
                                  <NavLink
                                      className={styles.sidebar__link}
                                      activeClassName={`${styles.active} ${styles.sidebar__link}`}
                                      to={
                                          (role == "free_lancer"
                                              ? "/user/account"
                                              : "/admin/account") +
                                          `${field.path}`
                                      }
                                  >
                                      <img
                                          src={field.icon}
                                          className={styles.fieldIcon}
                                      />
                                      {field.name}
                                  </NavLink>
                              </div>
                          )
                        : getFields(role, field, plan, planID.trial.plan),
                )}
                {role == "user" && getPlan(plan) != "Custom" && (
                    <div
                        className={styles.sidebar__linkContainer}
                        onClick={() => {
                            setDialog(true);
                        }}
                    >
                        <div className={styles.rechargeBanner}>
                            Recharge Credits
                            <img src={saveImg} className={styles.saveIcon} />
                        </div>
                    </div>
                )}
                <div className={styles.sidebar__linkContainer}>
                    <div
                        className={styles.sidebar__link}
                        onClick={() => {
                            document
                                .getElementById("iframe_fuguWidget")
                                .contentWindow.document.getElementById(
                                    "fuguLauncherContainer",
                                )
                                .click();
                            window.hippo = "flex";
                        }}
                    >
                        <img src={supportImg} className={styles.fieldIcon} />
                        Support
                    </div>
                </div>
                <Logout
                    render={(logoutHandler) => (
                        <div
                            onClick={logoutHandler}
                            className={`${styles.sidebar__linkContainer} ${styles.sidebar__logout}`}
                        >
                            <img className={styles.fieldIcon} src={logoutImg} />
                            Logout
                        </div>
                    )}
                ></Logout>
            </div>
        </div>
    );
}
