import React, { useContext, useEffect, useRef, useState } from "react";
import { BsTag, BsTagFill } from "react-icons/bs";
import { FiCheckSquare, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IoIosSquareOutline } from "react-icons/io";
import Button from "../../atoms/Button/Button";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import Modal from "../../atoms/Modal/Modal";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import SVGIcon from "../../atoms/SVGIcon";
import { TagsContext } from "../../Pages/TagsContext";
import CheckBox from "./CheckBox";
import styles from "./styles.module.css";
import useTeam from "./useTeam";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { ClickAwayListener } from "@material-ui/core";

const manageStyle = {
    position: "absolute",
    top: "4px",
    right: "6px",
    fontSize: "12px",
    fontWeight: " 600",
    color: "#024dc5",
};

const articleStatus = [
    { id: 0, title: "Ready for Writing" },
    { id: 1, title: "Writing in progress" },
    { id: 2, title: "Editing in progress" },
    { id: 3, title: "Completed" },
];

function TagsFilter({
    setFilterTagsList,
    setFilterStatusList,
    style = {},
    section = "",
}) {
    const [open, setOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const ref = useRef();
    const refBox = useRef();

    const { createTag, tags, getContainerRef } = useContext(TagsContext);

    useEffect(() => {
        if (refBox.current) {
            getContainerRef(refBox.current, manageStyle);
        }
    }, [refBox, open]);

    function getOptions(tags) {
        let options = tags
            .filter((tag) => tag.tag_name.includes(search))
            .map((tag) => (
                <CheckBox
                    key={tag.id}
                    tag={tag}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    term={tag.tag_name}
                ></CheckBox>
            ));

        if (options.length > 0) {
            return options;
        } else {
            return null;
        }
    }

    useEffect(() => {
        setFilterTagsList(selectedTags);
    }, [selectedTags]);

    useEffect(() => {
        if (section == "articleWriter") {
            setFilterStatusList(selectedStatus);
        }
    }, [selectedStatus]);

    const noTagsFound =
        search.trim() &&
        !tags
            .map((tag) => tag.tag_name.toLowerCase())
            .includes(search.trim().toLowerCase());

    return (
        <div style={{ position: "relative" }}>
            {open &&
                (section == "articleWriter" ? (
                    <ClickAwayListener
                        onClickAway={() => {
                            setShowFilter(false);
                            setOpen(false);
                        }}
                    >
                        <div className={styles.dropdownFilterContainer}>
                            {!showFilter ? (
                                <div className={styles.filterByContainer}>
                                    {/* <div>Filter By:</div> */}
                                    <div
                                        onClick={() => {
                                            setShowFilter(true);
                                            setStatusFilter(false);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Tags
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowFilter(true);
                                            setStatusFilter(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Article Status
                                    </div>
                                </div>
                            ) : statusFilter ? (
                                articleStatus.map((item) => (
                                    <StatusFilterItem
                                        setSelectedStatus={setSelectedStatus}
                                        selectedStatus={selectedStatus}
                                        item={item}
                                    />
                                ))
                            ) : (
                                <>
                                    <input
                                        autoFocus={true}
                                        disabled={loading}
                                        value={search}
                                        onChange={(e) =>
                                            e.target.value.length < 21 &&
                                            setSearch(e.target.value)
                                        }
                                        onKeyPress={(e) => {
                                            if (e.key == "Enter") {
                                                noTagsFound &&
                                                    (async () => {
                                                        if (loading) return;

                                                        setLoading(true);
                                                        await createTag(
                                                            e.target.value,
                                                        );
                                                        setLoading(false);
                                                    })();
                                            }
                                        }}
                                        placeholder={
                                            "Search tags or create new (max 20 chars)"
                                        }
                                        type="text"
                                        onClick={() => setOpen(true)}
                                    />
                                    <div
                                        ref={refBox}
                                        className={styles.dropdownFilter}
                                    >
                                        {
                                            <>
                                                {" "}
                                                {noTagsFound && (
                                                    <div
                                                        onClick={() =>
                                                            (async () => {
                                                                if (loading)
                                                                    return;

                                                                setLoading(
                                                                    true,
                                                                );
                                                                await createTag(
                                                                    search,
                                                                );
                                                                setLoading(
                                                                    false,
                                                                );
                                                            })()
                                                        }
                                                        className={
                                                            styles.newTagCreate
                                                        }
                                                    >
                                                        <BsTagFill
                                                            color="gray"
                                                            size={25}
                                                        />{" "}
                                                        <span>
                                                            Create new tag : '
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "600",
                                                                }}
                                                            >
                                                                {search}
                                                            </span>
                                                            '
                                                            {loading ? (
                                                                <LoadingDots />
                                                            ) : null}
                                                        </span>
                                                    </div>
                                                )}{" "}
                                                {!search && (
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            marginBottom: "5px",
                                                            height: "35px",
                                                            color: "gray",
                                                            borderBottom:
                                                                "solid lightgray 1px",
                                                        }}
                                                        className={
                                                            styles.checkbox
                                                        }
                                                    >
                                                        {selectedTags.length !=
                                                        tags.length ? (
                                                            <IoIosSquareOutline
                                                                onClick={() =>
                                                                    setSelectedTags(
                                                                        tags,
                                                                    )
                                                                }
                                                                size={22}
                                                            />
                                                        ) : (
                                                            <FiCheckSquare
                                                                onClick={() =>
                                                                    setSelectedTags(
                                                                        [],
                                                                    )
                                                                }
                                                                color="#014dc5"
                                                            />
                                                        )}{" "}
                                                        All tags
                                                    </span>
                                                )}
                                                {getOptions(tags)}
                                            </>
                                        }
                                    </div>
                                </>
                            )}
                        </div>
                    </ClickAwayListener>
                ) : (
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <div className={styles.dropdownFilterContainer}>
                            <input
                                autoFocus={true}
                                disabled={loading}
                                value={search}
                                onChange={(e) =>
                                    e.target.value.length < 21 &&
                                    setSearch(e.target.value)
                                }
                                onKeyPress={(e) => {
                                    if (e.key == "Enter") {
                                        noTagsFound &&
                                            (async () => {
                                                if (loading) return;

                                                setLoading(true);
                                                await createTag(e.target.value);
                                                setLoading(false);
                                            })();
                                    }
                                }}
                                placeholder={
                                    "Search tags or create new (max 20 chars)"
                                }
                                type="text"
                                onClick={() => setOpen(true)}
                            />
                            <div ref={refBox} className={styles.dropdownFilter}>
                                {
                                    <>
                                        {" "}
                                        {noTagsFound && (
                                            <div
                                                onClick={() =>
                                                    (async () => {
                                                        if (loading) return;

                                                        setLoading(true);
                                                        await createTag(search);
                                                        setLoading(false);
                                                    })()
                                                }
                                                className={styles.newTagCreate}
                                            >
                                                <BsTagFill
                                                    color="gray"
                                                    size={25}
                                                />{" "}
                                                <span>
                                                    Create new tag : '
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {search}
                                                    </span>
                                                    '
                                                    {loading ? (
                                                        <LoadingDots />
                                                    ) : null}
                                                </span>
                                            </div>
                                        )}{" "}
                                        {!search && (
                                            <span
                                                style={{
                                                    width: "100%",
                                                    marginBottom: "5px",
                                                    height: "35px",
                                                    color: "gray",
                                                    borderBottom:
                                                        "solid lightgray 1px",
                                                }}
                                                className={styles.checkbox}
                                            >
                                                {selectedTags.length !=
                                                tags.length ? (
                                                    <IoIosSquareOutline
                                                        onClick={() =>
                                                            setSelectedTags(
                                                                tags,
                                                            )
                                                        }
                                                        size={22}
                                                    />
                                                ) : (
                                                    <FiCheckSquare
                                                        onClick={() =>
                                                            setSelectedTags([])
                                                        }
                                                        color="#014dc5"
                                                    />
                                                )}{" "}
                                                All tags
                                            </span>
                                        )}
                                        {getOptions(tags)}
                                    </>
                                }
                            </div>
                        </div>
                    </ClickAwayListener>
                ))}

            <div
                onClick={() => {
                    setOpen(true);
                }}
                style={style}
                className={styles.filterContainer}
                ref={ref}
            >
                <SVGIcon size={16} src={"/New UI/SVG/tagIcon.svg"} />
                &nbsp;{section == "articleWriter" ? "Filter By" : "Filter Tags"}
            </div>
        </div>
    );
}

const StatusFilterItem = ({ setSelectedStatus, selectedStatus, item }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (selectedStatus.map((ele) => ele?.id).includes(item?.id))
            setChecked(true);
    }, [selectedStatus]);
    return (
        <div className={styles.statusFilterItem}>
            {!checked ? (
                <IoIosSquareOutline
                    color="#abababa8"
                    onClick={() => {
                        setChecked(true);
                        setSelectedStatus((pre) => [...pre, item]);
                    }}
                    size={18}
                />
            ) : (
                <FiCheckSquare
                    onClick={() => {
                        setChecked(false);
                        setSelectedStatus((pre) =>
                            pre.filter((ele) => ele.id != item.id),
                        );
                    }}
                    size={14}
                    color="#014dc5"
                />
            )}{" "}
            <div
                onClick={() => {
                    if (checked) {
                        setChecked(false);
                        setSelectedStatus((pre) =>
                            pre.filter((ele) => ele.id != item.id),
                        );
                    } else {
                        setChecked(!false);
                        setSelectedStatus((pre) => [...pre, item]);
                    }
                }}
            >
                {item.title}
            </div>
        </div>
    );
};

export default TagsFilter;
