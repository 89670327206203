import React from "react";
import styles from "./styles.module.css";

function Separator({ color = "#e2e2ea", height = "1px" }) {
  return (
    <div
      style={{ backgroundColor: color, height }}
      className={styles.separator}
    ></div>
  );
}

export default Separator;
