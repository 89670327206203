import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
    useRef,
    useLayoutEffect,
} from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import useHelperFunctions from "../../../helperFunctions";
import ReportContext from "../../Research/ReportContext";
import OutlineH2 from "./OulineH2";
import styles from "./OutlineBuilder.module.css";
import { v4 as uuidv4, v4 } from "uuid";
import DataServices from "./DataServices";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import LoaderGoNuts from "./LoaderGoNuts";
import FAQRow from "./FAQRow";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import { RiArrowDownSFill, RiArrowRightSFill } from "react-icons/ri";
import Highlighter from "react-highlight-words";

import PrimaryInputTextArea from "./PrimaryInputTextArea";

import Select from "react-select";
import { ConclusionCtaTypes, CruiseContext, CruiseState } from "./StepsWizard";
import { droppableIds, droppableTypes } from "./cruiseConstant";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import moment from "moment";
import { CRUISE_TYPES } from "./useCruiseHook";
import { Outline } from "./classes";
import { useCallback } from "react";
import { resetScroll } from "../../../utlities";
import { CitationHover } from "./TalkingPointsSteps/TalkPoint";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { getKeytermVariations } from "../../../../utility/helperFun";
import { useSelector } from "react-redux";
import H2H3dropdown from "./H2H3dropdown";

/**
 * @param {import("react-beautiful-dnd").DropResult} res */
export const onDragEnd = (
    res,
    dispatchStepsData,
    stepsData,
    topRankOutlines,
) => {
    if (!res.source || !res.destination) {
        return;
    }

    switch (res.type) {
        case droppableTypes.outlineH2:
            {
                if (res.source.droppableId == res.destination.droppableId) {
                    if (
                        res.source.droppableId.includes(droppableIds.topRanked)
                    ) {
                        return;
                    }
                    if (res.source.droppableId == droppableIds.headings) {
                        dispatchStepsData({
                            type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                            field: Outline.type.heading,
                            oldIndex: res.source.index,
                            newIndex: res.destination.index,
                            tag: Outline.tags.h2,
                        });
                    } else {
                        dispatchStepsData({
                            type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                            field: Outline.type.question,
                            oldIndex: res.source.index,
                            newIndex: res.destination.index,
                        });
                    }
                } else if (
                    res.source.droppableId.includes(droppableIds.topRanked)
                ) {
                    const rankIndex = res.source.droppableId.split("_")[1];
                    const hIndex = res.source.index;

                    const item = topRankOutlines[rankIndex].h_tags.h[hIndex];

                    const outline = new Outline({
                        tag: Outline.tags.h2,
                        data: item.text,
                        id: v4(),
                        type: Outline.type.heading,
                    });

                    if (res.destination.droppableId == droppableIds.headings) {
                        dispatchStepsData({
                            type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                            field: Outline.type.heading,
                            data: outline,
                            index: res.destination.index,
                        });
                    } else if (
                        res.destination.droppableId == droppableIds.questions
                    ) {
                        outline.tag = Outline.tags.question;
                        outline.type = Outline.type.question;
                        dispatchStepsData({
                            type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                            field: Outline.type.question,
                            index: res.destination.index,
                            data: outline,
                        });
                    }
                }
            }

            break;

        case droppableTypes.outlineH3: {
            if (res.source.droppableId == res.destination.droppableId) {
                const [h2Index, h2Id] = res.destination.droppableId.split("_");

                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                    field: Outline.type.heading,
                    oldIndex: res.source.index,
                    newIndex: res.destination.index,
                    tag: Outline.tags.h3,
                    h2Index: h2Index,
                    h2_id: h2Id,
                });
            } else if (
                res.source.droppableId.includes(droppableIds.topRanked)
            ) {
                const [h2Index, h2Id] = res.destination.droppableId.split("_");
                const rankIndex = res.source.droppableId.split("_")[1];
                const hIndex = res.source.index;

                const item = topRankOutlines[rankIndex].h_tags.h[hIndex];

                const outline = new Outline({
                    tag: Outline.tags.h3,
                    data: item.text,
                    id: v4(),
                    type: Outline.type.heading,
                });

                dispatchStepsData({
                    type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                    field: Outline.type.heading,
                    data: outline,
                    index: res.destination.index,
                    h2Index,
                    h2_id: h2Id,
                });
            } else {
                const [destinationH2Index, destinationH2Id] =
                    res.destination.droppableId.split("_");
                const [sourceH2Index, sourceH2Id] =
                    res.source.droppableId.split("_");
                const sourceH3Index = +res.source.index;
                const destinationH3Index = +res.destination.index;

                dispatchStepsData({
                    type: CRUISE_TYPES.DELETE_OUTLINES,
                    field: Outline.type.heading,
                    tag: Outline.tags.h3,
                    h2Index: sourceH2Index,
                    index: sourceH3Index,
                    id: stepsData.outlines.headings[sourceH2Index].outlineH3s[
                        sourceH3Index
                    ].id,
                });

                const outlineH3 = new Outline({
                    tag: Outline.tags.h3,
                    data: stepsData.outlines.headings[sourceH2Index].outlineH3s[
                        sourceH3Index
                    ].data,
                    id: v4(),
                    type: Outline.type.heading,
                    para: stepsData.outlines.headings[sourceH2Index].outlineH3s[
                        sourceH3Index
                    ].para,
                });

                dispatchStepsData({
                    type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                    field: Outline.type.heading,
                    h2Index: destinationH2Index,
                    index: destinationH3Index,
                    data: outlineH3,
                    h2_id: destinationH2Id,
                });
            }
        }
    }
};

const OutlineBox = ({
    isPremiumOrSpecificSegment,
    containerRef,
    conclusionCta = {},
    setConclusionCta = () => {},
    GeneratedContentOutline,
    topRankOutlines,
    headingCount,
}) => {
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);
    const { mpTrackCruiseAddition, mpTrackCruiseRegenerate } =
        useMixpanelHook();
    const [isLoader, setIsLoader] = useState(false);

    const [isDropDown, setIsDropDown] = useState(false);
    const dispatchStepsData = useContext(CruiseContext);

    const stepsData = useContext(CruiseState);
    const ref = useRef(null);

    const handleH2H3Change = (h2Index) => {
        /**
             *@type {Outline} 
             
              */
        const preH2 = stepsData.outlines.headings[h2Index - 1];
        /**
         *@type {Outline}
         *   */
        const currH2 = stepsData.outlines.headings[h2Index];

        const outlineH3 = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: currH2.data,
            tag: Outline.tags.h3,
        });

        const outlineH3s = [];

        preH2.outlineH3s.forEach(
            /**
             * @param {Outline} element
             *  */
            (element) => {
                outlineH3s.push(new Outline(element));
            },
        );
        outlineH3s.push(outlineH3);

        currH2.outlineH3s.forEach(
            /**
             * @param {Outline} element
             *  */
            (element) => {
                outlineH3s.push(new Outline({ ...element, id: v4() }));
            },
        );

        const outlineH2 = new Outline({
            ...preH2,
            outlineH3s,
        });

        dispatchStepsData({
            type: CRUISE_TYPES.OUTLINE_INTER_CHANGE,
            index: h2Index,
            tag: Outline.tags.h2,
            data: outlineH2,
            id: currH2.id,
        });
    };

    const onPressEnterInOutline = ({ data, tag, index, h2Index, isFaq }) => {
        const outline = new Outline({
            tag: tag,
            id: v4(),
            type: isFaq ? Outline.type.question : Outline.type.heading,
            data,
        });

        dispatchStepsData({
            field: isFaq ? Outline.type.question : Outline.type.heading,
            data: outline,
            type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
            index: index + 1,
            h2Index,
        });
    };

    const headingCountOnMount = useMemo(() => headingCount, []);

    const { keyTerms, highlight_keyTerm, reportInfo } =
        useContext(ReportContext);
    const obj = useMemo(() => new Mark(ref.current), [ref.current]);
    useEffect(() => {
        if (highlight_keyTerm)
            keyTerms
                .sort((a, b) => b?.keyword?.length - a?.keyword?.length)
                .forEach((term) => {
                    setImmediate(() => {
                        obj.mark(term.keyword, {
                            separateWordSearch: false,
                            accuracy: {
                                value: "exactly",
                                limiters: [
                                    ",",
                                    "?",
                                    ".",
                                    '"',
                                    "'",
                                    "!",
                                    ")",
                                    "(",
                                    "{",
                                    "}",
                                    "[",
                                    "]",
                                    ":",
                                    ";",
                                    "-",
                                ],
                            },
                        });
                    });
                });
        else {
            obj.unmark();
        }
        return () => {
            obj.unmark();
        };
    }, [stepsData, highlight_keyTerm]);
    useEffect(() => {
        if (
            headingCountOnMount != headingCount &&
            !reportInfo.is_premium &&
            auth.segment != 3 &&
            auth.segment != 4 &&
            auth.segment != 5 &&
            auth.segment != 6
        ) {
            if (stepsData.conclusion)
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_CONCLUSION,
                    data: "",
                    edit: true,
                });
            if (stepsData.introduction)
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_INTRODUCTION,
                    data: "",
                    edit: true,
                });
        }
    }, [headingCount]);

    return (
        <>
            {isLoader && <LoaderGoNuts />}
            <DragDropContext
                onDragEnd={(res) =>
                    onDragEnd(
                        res,
                        dispatchStepsData,
                        stepsData,
                        topRankOutlines,
                    )
                }
            >
                <div ref={ref} className={styles.mainOutlineContainer}>
                    <Droppable
                        type={droppableTypes.outlineH2}
                        droppableId={droppableIds.headings}
                    >
                        {(provided) => (
                            <div
                                className={`${styles.headingsDiv} `}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {
                                    <div
                                        className={`${styles.headingListings} ${
                                            stepsData.automated_outlines
                                                ? styles.headingsDivAuto
                                                : ""
                                        }`}
                                    >
                                        {!stepsData.isLoader &&
                                        isPremiumOrSpecificSegment ? (
                                            <div
                                                className={`${styles.headingRow} ${styles.text}`}
                                                style={{
                                                    padding: "10px",
                                                    paddingLeft: "25px",
                                                }}
                                            >
                                                <H2H3dropdown
                                                    handler={() => {}}
                                                    index={0}
                                                    type={"h2"}
                                                    isPremiumOrSpecificSegment={
                                                        isPremiumOrSpecificSegment
                                                    }
                                                />
                                                {/* <div
                                                    className={`${styles.H2H3dropdown} ${styles.heading2}`}
                                                    style={{
                                                        paddingRight: "5px",
                                                    }}
                                                >
                                                    H2
                                                </div> */}
                                                <span
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    Key Highlights
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {Array.isArray(
                                            stepsData.outlines.headings,
                                        ) &&
                                            stepsData.outlines.headings.map(
                                                (heading, index) => (
                                                    <>
                                                        <Draggable
                                                            key={heading.id}
                                                            draggableId={heading.id?.toString()}
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snap,
                                                            ) => (
                                                                <div
                                                                    className={
                                                                        styles.headingItemOuter
                                                                    }
                                                                    key={
                                                                        heading.id
                                                                    }
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    // style={{
                                                                    //   ...provided.draggableProps?.style,
                                                                    //   ...getStyleForHeadingItemOuter(item.tag),
                                                                    // }}
                                                                    style={{
                                                                        ...provided
                                                                            .draggableProps
                                                                            ?.style,

                                                                        backgroundColor:
                                                                            snap.isDragging
                                                                                ? "rgba(232, 237, 248, 0.9)"
                                                                                : "",
                                                                    }}
                                                                >
                                                                    <OutlineH2
                                                                        isPremiumOrSpecificSegment={
                                                                            isPremiumOrSpecificSegment
                                                                        }
                                                                        key={
                                                                            heading.id
                                                                        }
                                                                        provided={
                                                                            provided
                                                                        }
                                                                        index={
                                                                            index
                                                                        }
                                                                        item={
                                                                            heading
                                                                        }
                                                                        handleH2H3Change={
                                                                            handleH2H3Change
                                                                        }
                                                                        onPressEnterInOutline={
                                                                            onPressEnterInOutline
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    </>
                                                ),
                                            )}
                                        {provided.placeholder}

                                        {containerRef?.current?.parentNode
                                            ?.parentNode?.parentNode &&
                                            GeneratedContentOutline &&
                                            ReactDOM.createPortal(
                                                GeneratedContentOutline,
                                                containerRef.current.parentNode
                                                    ?.parentNode?.parentNode,
                                            )}
                                    </div>
                                }

                                <div className={styles.addH3Btns}>
                                    <GenerateOutline isH2={true} />
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <>
                        <div className={styles.outlineBoxHead}>
                            <span>Frequently Asked Questions</span>
                        </div>
                        <Questions
                            onPressEnterInOutline={onPressEnterInOutline}
                        />
                    </>
                    <div
                        className={
                            styles.outlineBoxHead_ConclusionCta +
                            " " +
                            (isDropDown &&
                                styles.outlineBoxHead_ConclusionCta__open)
                        }
                    >
                        <div>Conclusion CTA </div>
                        <ClickAwayListener
                            onClickAway={(e) => setIsDropDown(false)}
                        >
                            <div className={styles.CustomSelectWrapper}>
                                <LightTooltip
                                    interactive
                                    placement={
                                        isDropDown ? "right-end" : "bottom"
                                    }
                                    arrow
                                    className={styles.darkConclusion}
                                    {...(isDropDown ? { open: true } : {})}
                                    title={
                                        !isDropDown ? (
                                            conclusionCta?.tooltipText ? (
                                                conclusionCta?.tooltipText
                                            ) : (
                                                ConclusionCtaTypes[
                                                    ConclusionCtaTypes.length -
                                                        1
                                                ]?.tooltipText
                                            )
                                        ) : (
                                            <div className={styles.OptionList}>
                                                {ConclusionCtaTypes.map(
                                                    (type, index) => {
                                                        return (
                                                            <>
                                                                <LightTooltip
                                                                    title={
                                                                        type.tooltipText
                                                                    }
                                                                    arrow
                                                                >
                                                                    <div
                                                                        key={
                                                                            index +
                                                                            type?.label
                                                                        }
                                                                        onClick={(
                                                                            e,
                                                                        ) => {
                                                                            if (
                                                                                stepsData
                                                                                    ?.conclusion
                                                                                    ?.length
                                                                            )
                                                                                dispatchStepsData(
                                                                                    {
                                                                                        type: CRUISE_TYPES.HANDLE_CONCLUSION,
                                                                                        data: "",
                                                                                        edit: true,
                                                                                    },
                                                                                );
                                                                            if (
                                                                                type?.label
                                                                            ) {
                                                                                dispatchStepsData(
                                                                                    {
                                                                                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                                                                        data: type?.label,
                                                                                        field: "cta",
                                                                                    },
                                                                                );

                                                                                e.target.blur();
                                                                            } else {
                                                                                dispatchStepsData(
                                                                                    {
                                                                                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                                                                        data: "",
                                                                                        field: "cta",
                                                                                    },
                                                                                );
                                                                                e.target.blur();
                                                                            }
                                                                            setIsDropDown(
                                                                                false,
                                                                            );
                                                                        }}
                                                                        className={
                                                                            styles.OptionItem +
                                                                            " " +
                                                                            (type?.label?.toLocaleLowerCase() ==
                                                                                conclusionCta?.label?.toLocaleLowerCase() &&
                                                                                styles.selectedItem)
                                                                        }
                                                                    >
                                                                        {
                                                                            type.label
                                                                        }
                                                                    </div>
                                                                </LightTooltip>
                                                            </>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        )
                                    }
                                >
                                    <div
                                        className={styles.selectedValue}
                                        onClick={(e) => {
                                            setIsDropDown(!isDropDown);
                                            e.target.blur();
                                        }}
                                    >
                                        <div className={styles.left}>
                                            {stepsData.cta
                                                ? stepsData.cta
                                                : ConclusionCtaTypes[
                                                      ConclusionCtaTypes.length -
                                                          1
                                                  ]?.label}
                                        </div>
                                        <div className={styles.right}>
                                            {!isDropDown ? (
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/RiArrowDownSFill.svg"
                                                    }
                                                    color={"#0221AF"}
                                                    size={22}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </LightTooltip>
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
            </DragDropContext>
        </>
    );
};

const Questions = ({ onPressEnterInOutline }) => {
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);
    const stepsData = useContext(CruiseState);
    const { reportInfo } = useContext(ReportContext);
    return (
        <>
            <Droppable
                type={droppableTypes.outlineH2}
                droppableId={droppableIds.questions}
            >
                {(provided) => (
                    <div
                        className={`${styles.headingListings} ${
                            (!!reportInfo.is_premium ||
                                auth.segment == 3 ||
                                auth.segment == 4 ||
                                auth.segment == 5 ||
                                auth.segment == 6) &&
                            stepsData.outlines.questions.length &&
                            stepsData.automated_outlines
                                ? styles.headingsDivAuto
                                : ""
                        }`}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {Array.isArray(stepsData?.outlines?.questions) &&
                            stepsData.outlines.questions?.map((item, i) => (
                                <Draggable
                                    key={item?.id}
                                    draggableId={String(item?.id)}
                                    index={i}
                                >
                                    {(provided, snap) => (
                                        <div
                                            className={styles.outerH2Row}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={{
                                                ...provided.draggableProps
                                                    ?.style,

                                                backgroundColor: snap.isDragging
                                                    ? "rgba(232, 237, 248, 0.9)"
                                                    : "",
                                            }}
                                        >
                                            <FAQRow
                                                key={item?.id}
                                                faq={item}
                                                index={i}
                                                provided={provided}
                                                onPressEnterInOutline={
                                                    onPressEnterInOutline
                                                }
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <div className={styles.addH3Btns}>
                <GenerateOutline isFaq={true} />
            </div>
        </>
    );
};

export const GenerateOutline = ({ isFaq, isH2, isH3, h3Callback, h2Data }) => {
    const { postData } = useHelperFunctions();

    const [isGen, setIsGen] = useState(false);
    const [genList, setGenList] = useState([]);
    const { mpTrackCruiseRegenerate, mixpanelTrack } = useMixpanelHook();
    const { reportInfo } = useContext(ReportContext);
    const dispatchStepsData = useContext(CruiseContext);
    const stepsData = useContext(CruiseState);

    const inputToOutline = function (item) {
        if (isH3) {
            h3Callback(item.content);
        } else {
            const outline = new Outline({
                tag: isFaq ? Outline.tags.question : Outline.tags.h2,
                id: v4(),
                type: isFaq ? Outline.type.question : Outline.type.heading,
                data: item.content,
            });

            dispatchStepsData({
                field: isFaq ? Outline.type.question : Outline.type.heading,
                data: outline,
                type: CRUISE_TYPES.ADD_OUTLINES,
            });
        }
    };

    async function generate() {
        const sDate = moment.now();

        if (genList?.length > 0) {
            mixpanelTrack("Generate more clicked", {
                "Screen Name": "Outline",
                Section: isH2 ? "H2" : isH3 ? "H3" : "FAQ",
            });
        }

        if (genList?.length < 1) {
            mixpanelTrack("Suggestions Clicked", {
                "Screen Name": "Outline",
                Section: isH2 ? "H2" : isH3 ? "H3" : "FAQ",
            });
        }

        setIsGen(true);

        const faqPayload = {
            question: stepsData?.title,
            report_id: reportInfo.report_id,
            isTool: false,
            tokens: 2,
            useCase: 89,
        };

        const payload = isFaq
            ? faqPayload
            : {
                  title: stepsData?.title,
                  blog_context: stepsData?.blog_context?.length
                      ? stepsData.blog_context
                      : "",
                  report_id: reportInfo.report_id,
                  tag: isH2 ? Outline.tags.h2 : Outline.tags.h3,
                  ...(isH3
                      ? {
                            ...(genList.length == 0 ? { type: 1 } : {}),
                            heading: h2Data,
                        }
                      : {}),
              };
        const res = await postData({
            url: isFaq
                ? "/api/generate"
                : "/api/contentDraft/generate-outlines",
            payload,
        });

        if (res.status == 200) {
            let a = [...genList, ...res.data.output];
            setGenList(a);
            const timeTaken = moment().diff(sDate, "seconds");
            mpTrackCruiseRegenerate({
                type: isH2 ? "H2" : isH3 ? "H3" : "FAQ",
                timeTaken,
            });
            setIsGen(false);
        }
        setIsGen(false);
    }

    return (
        <>
            <PrimaryInputTextArea
                placeholder={
                    isFaq
                        ? "Write your own question or simply select from right pane"
                        : "Write your own H2 or simply select from right pane"
                }
                callback={(value) => {
                    inputToOutline({ content: value });
                }}
                maxLength={120}
                type={isFaq ? "FAQ" : isH2 ? "H2" : "H3"}
                isFoucs={isH3}
            />
            <GenerateIdea
                inputToOutline={inputToOutline}
                type={isFaq ? "FAQ" : isH2 ? "H2" : "H3"}
                additionType={isFaq ? "FAQ" : isH2 ? "H2" : "H3"}
                generate={() => {
                    mpTrackCruiseRegenerate("Writing Points");
                    generate();
                }}
                list={genList}
                setList={setGenList}
                isGenerating={isGen}
            />
        </>
    );
};

export const GenerateIdea = ({
    generate = () => {},
    isGenerating = false,
    list = [],
    type = "H3",
    setList,
    inputToOutline = () => {},
    placement = "right",
    additionType = "",
    allowGenMore = true,
    TPSwitch,
    open,
    highlightKeyTerms,
}) => {
    const [isGenCollapse, setIsGenCollapse] = useState(true);
    const [prevItems, setPrevItems] = useState({ length: list.length, type });

    const ref = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const containerRef = useRef();

    useEffect(() => {
        if (!isGenerating && prevItems.length && type == prevItems.type) {
            setTimeout(() => {
                ref.current && ref.current.scrollIntoView();
            }, 100);
        } else {
            setPrevItems({ length: list.length, type });
        }
    }, [isGenerating]);

    useEffect(() => {
        if (containerRef.current) {
            resetScroll(containerRef.current);
        }
    }, [type]);

    const { keyTerms, highlight_keyTerm } = useContext(ReportContext);
    const obj = useMemo(() => new Mark(ref2.current), [ref2.current]);
    useEffect(() => {
        if (highlight_keyTerm) {
            setImmediate(() => {
                keyTerms
                    .sort((a, b) => b?.keyword?.length - a?.keyword?.length)
                    .forEach((term) => {
                        setImmediate(() => {
                            obj.mark(term.keyword, {
                                separateWordSearch: false,
                                accuracy: {
                                    value: "exactly",
                                    limiters: [
                                        ",",
                                        "?",
                                        ".",
                                        '"',
                                        "'",
                                        "!",
                                        ")",
                                        "(",
                                        "{",
                                        "}",
                                        "[",
                                        "]",
                                        ":",
                                        ";",
                                        "-",
                                    ],
                                },
                            });
                        });
                    });
            });
        } else {
            obj?.unmark();
        }
        return () => {
            obj.unmark();
        };
    }, [isGenCollapse, list, highlight_keyTerm, ref2.current]);

    return (
        <>
            <div ref={ref1} className={styles.genContainer}>
                <button
                    className={styles.addH3Btn}
                    onClick={() => {
                        if (!list.length) {
                            generate();
                            setIsGenCollapse(false);
                        } else {
                            setIsGenCollapse(!isGenCollapse);
                        }
                    }}
                >
                    {!isGenerating || list.length ? (
                        <>
                            {list.length == 0 ? (
                                <SVGIcon
                                    src={"/New UI/SVG/BsPlusLg.svg"}
                                    size={8}
                                />
                            ) : (
                                <>
                                    {!isGenCollapse ? (
                                        <RiArrowDownSFill
                                            style={{
                                                color: "inherit",
                                            }}
                                            size={16}
                                        />
                                    ) : (
                                        <RiArrowRightSFill
                                            style={{
                                                color: "inherit",
                                            }}
                                            size={16}
                                        />
                                    )}
                                </>
                            )}
                            &nbsp;{TPSwitch ? "" : "AI "}
                            Suggestions
                        </>
                    ) : (
                        <>
                            Generating <LoadingDots />
                        </>
                    )}
                </button>

                <LightTooltip
                    placement={placement}
                    tooltipStyle={{
                        boxShadow: "0 0 4px 0 lightgrey",
                        maxWidth: 350,
                    }}
                    interactive
                    arrow
                    open={true}
                    title={
                        !isGenCollapse && (list.length || open) ? (
                            <ClickAwayListener
                                onClickAway={() => setIsGenCollapse(true)}
                            >
                                <div
                                    ref={ref2}
                                    className={styles.genResultContainer}
                                >
                                    {TPSwitch ? (
                                        TPSwitch
                                    ) : (
                                        <h3>AI Generated Ideas</h3>
                                    )}
                                    <div>
                                        {
                                            <div
                                                ref={containerRef}
                                                className={
                                                    styles.genH3ListContainer +
                                                    " " +
                                                    (isGenCollapse &&
                                                        styles.H2dropEmpty)
                                                }
                                            >
                                                <VscClose
                                                    className={styles.closeGen}
                                                    onClick={() =>
                                                        setIsGenCollapse(true)
                                                    }
                                                />
                                                {list.map((item, i) => {
                                                    return (
                                                        <>
                                                            {prevItems.length -
                                                                1 ==
                                                                i && (
                                                                <span
                                                                    ref={ref}
                                                                ></span>
                                                            )}
                                                            <GenerateIdeaRow
                                                                ref1={ref1}
                                                                item={item}
                                                                i={i}
                                                                inputToOutline={
                                                                    inputToOutline
                                                                }
                                                                type={type}
                                                                setList={
                                                                    setList
                                                                }
                                                                additionType={
                                                                    additionType
                                                                }
                                                                prevItems={
                                                                    prevItems.length
                                                                }
                                                                highlightKeyTerms={
                                                                    highlightKeyTerms
                                                                }
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        }
                                    </div>

                                    <button
                                        className={styles.addH3Btn}
                                        onClick={() => {
                                            allowGenMore && generate();
                                        }}
                                        style={{
                                            visibility:
                                                allowGenMore || isGenerating
                                                    ? "visible"
                                                    : "hidden",
                                        }}
                                    >
                                        {isGenerating ? (
                                            <>
                                                Generating <LoadingDots />
                                            </>
                                        ) : (
                                            "Generate more..."
                                        )}
                                    </button>
                                </div>
                            </ClickAwayListener>
                        ) : (
                            ""
                        )
                    }
                >
                    <span></span>
                </LightTooltip>
            </div>
        </>
    );
};

export const GenerateIdeaRow = ({
    item,
    ref1,
    i,
    inputToOutline = () => {},
    prevItems = [],
    type = "H3",
    setList = () => {},
    additionType,
    highlightKeyTerms = true,
}) => {
    const { mpTrackCruiseAddition } = useMixpanelHook();
    const { keyTerms, highlight_keyTerm } = useContext(ReportContext);

    const sourceOrigin = (origin) => {
        switch (origin) {
            case 1:
                return "Factual";
            case 3:
                return "Manual";
            default:
                return "Ai";
        }
    };

    return (
        <LightTooltip
            style={{}}
            interactive
            placement={"right"}
            arrow
            title={
                item?.citations?.length > 0 ? (
                    <CitationHover citations={item.citations} />
                ) : (
                    ""
                )
            }
        >
            <div
                onClick={() => {
                    mpTrackCruiseAddition({
                        type: additionType,
                        origin:
                            item?.type == 1
                                ? "Factual"
                                : item?.tpType
                                ? sourceOrigin(item?.tpType)
                                : "Ai",
                    });
                    if (inputToOutline({ ...item }) !== false) {
                        setList((ps) => {
                            return ps.filter((a) => item.content != a.content);
                        });
                    }
                    setImmediate(() => {
                        // ref1?.current?.scrollIntoView(true);
                    });
                }}
                className={
                    styles.addH3Row +
                    " " +
                    (prevItems - 1 < i ? styles.highlightGen : "")
                }
            >
                <div className={styles.addH3__left}>
                    <span className={styles.addH3Type}>{type}</span>

                    <span
                        className={styles.addH3Text}
                        style={{
                            cursor: "pointer",

                            fontWeight: 500,
                        }}
                    >
                        {item.content}
                    </span>
                </div>
            </div>
        </LightTooltip>
    );
};

export default OutlineBox;
