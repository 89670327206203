import {
  LOAD_VARIANTS,
  NO_VARIANT,
  LOAD_USER_VARIANTS,
  LOAD_GENERATE_VARIANTS,
  OPEN_VARIANT,
} from "../actiontypes";

export const loadVariants = (data) => {
  return {
    type: LOAD_VARIANTS,
    data,
  };
};
export const loadUserVariants = (data) => {
  return {
    type: LOAD_USER_VARIANTS,
    data,
  };
};

export const loadGenerateVariants = (data) => {
  return {
    type: LOAD_GENERATE_VARIANTS,
    data,
  };
};

export const openVariant = (info) => {
  return {
    type: OPEN_VARIANT,
    info,
  };
};

export const closeVariant = () => {
  return {
    type: NO_VARIANT,
  };
};
