import React, { useRef, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
} from "@material-ui/core";
import {
    MdKeyboardArrowDown,
    MdOutlineArrowOutward,
    MdOutlineCheck,
} from "react-icons/md";
import styles from "./SeoSurge.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import Button from "../../atoms/Button/Button";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import useMixpanelHook from "../../../utility/useMixpanelHook";

const useAccordionStyles = makeStyles(() => ({
    root: {
        background: "transparent",
        boxShadow: "none !important",
        color: "#29394a",
        "&:hover": {
            color: "#0079e4",
        },
        "& .MuiAccordionSummary-root": {
            borderBottom: "none !important",
            paddingInline: "18px 24px",
        },
        "& .MuiAccordionDetails-root": {
            borderTop: "none !important",
            paddingInline: "18px 24px",
            marginTop: "-10px",
        },
    },
    expanded: {
        "&:hover": {
            color: "#29394a",
        },
    },
}));

const packageDetails = [
    {
        iconPath: "/New UI/SVG/seo-surge-package1.svg",
        description: "Realtime collaboration",
    },
    {
        iconPath: "/New UI/SVG/seo-surge-package2.svg",
        description: "Dedicated project manager",
    },
    {
        iconPath: "/New UI/SVG/seo-surge-package3.svg",
        description: "On time delivery guarantee",
    },
    {
        iconPath: "/New UI/SVG/seo-surge-package4.svg",
        description: (
            <>
                Monthly <br />
                Reports
            </>
        ),
    },
];

const seoSurgeFaq = [
    {
        question: "How long does it take to see results from SEO efforts?",
        answer: "SEO is a long-term strategy, and results can vary depending on your industry, competition, and current website status. Generally, it can take 3-6 months to see noticeable improvements in your website's search engine rankings.",
    },
    {
        question: "How do you measure the success of your SEO campaigns?",
        answer: "We use various KPIs (Key Performance Indicators) to measure the success of our SEO campaigns, including organic traffic growth, keyword rankings, conversion rates, and ROI.",
    },
    {
        question:
            "What tools and techniques do you use for keyword research and analysis?",
        answer: "We use our internal tools, as well as industry-leading tools like Google Keyword Planner, SEMrush, and Ahrefs for keyword research and analysis. Our techniques involve identifying high-volume, low-competition keywords relevant to your business and targeting them in your website's content.",
    },
    {
        question:
            "How often do you report on the progress of our SEO campaign?",
        answer: "We typically provide weekly, monthly, and quarterly reports to our clients, depending on their preferences and campaign scope. Our reports include updates on keyword rankings, organic traffic growth, and other relevant metrics.",
    },
    {
        question: "How do you handle changes in search engine algorithms?",
        answer: "We closely monitor search engine algorithm updates and analyze their impact on our clients' websites. We adjust our strategies accordingly to ensure that our clients' websites remain compliant with search engine guidelines and continue to perform well in search results.",
    },
];

const planDetails = [
    {
        title: "Editorial",
        description: "Perfect for scaling up content creation and optimization",
        features: [
            "High-quality blogs created by humans, augmented by AI",
            "Optimization of existing content by humans, to rank on search engines",
            "Real-time collaboration",
            "Dedicated project manager",
        ],
    },
    {
        title: "Full-stack",
        description:
            "Designed for end to end planning, strategy, creation, optimization, and more.",
        features: [
            "In-depth competitor research, domain analysis",
            "Detailed keyword planning",
            "Building content calendar",
            "New topic ideas",
            "Optimization opportunities",
            "High-quality blogs created by humans, augmented by AI",
            "Optimization of existing content by humans, to rank on search engines",
            "Detailed technical audit",
            "Monthly reports",
            "Real-time collaboration",
            "Dedicated project manager",
        ],
        cardStyle: {
            backgroundColor: "#254AC5",
            color: "#ffffff",
        },
        buttonStyle: {
            backgroundColor: "#ffffff",
            color: "#254AC5",
        },
    },
    {
        title: "Strategy",
        description:
            "Perfect for building content strategy, and finding new & existing content opportunities",
        features: [
            "In-depth competitor research, domain analysis",
            "Detailed keyword planning",
            "Building content calendar",
            "New topic ideas",
            "Optimization opportunities",
            "Monthly reports",
            "Real-time collaboration",
            "Dedicated project manager",
        ],
    },
];

export default function SeoSurge() {
    const [modal, setModal] = useState(false);

    const { mixpanelTrack } = useMixpanelHook();
    useCalendlyEventListener({
        onEventScheduled: (e) => {
            mixpanelTrack("SEO Surge", { action: "Call Scheduled" });
        },
    });

    return (
        <div className={styles.container}>
            <CalendlyModal modal={modal} setModal={setModal} />

            {/* Top Section */}
            <section className={styles.topSection}>
                <div className={styles.textContainer}>
                    <div
                        style={{ marginLeft: "-18px", marginBottom: "-15px" }}
                        className={styles.topTitle}
                    >
                        <SVGIcon src={"/New UI/SVG/seoSurgeLogo.svg"} />
                    </div>
                    <p className={styles.subtitle}>
                        We are your SEO
                        <br />
                        team that drives
                        <br />
                        Organic Traffic
                    </p>
                    <Button
                        text={"Schedule Free Consultation"}
                        Icon={() => <MdOutlineArrowOutward />}
                        width={250}
                        fontSize={14}
                        height={32}
                        fontWeight={600}
                        borderRadius={30}
                        customClass={styles.topConsultationButton}
                        handler={() => {
                            setModal(true);
                            mixpanelTrack("SEO Surge", {
                                action: "Schedule Free Consultation clicked - Top",
                            });
                        }}
                        aria-label="Schedule Free Consultation"
                    />
                </div>
                <div className={styles.reviewCard}>
                    <div className={styles.reviewHeader}>
                        <SVGIcon src={`/New UI/SVG/g2-seeklogo.svg`} />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "3px",
                            }}
                        >
                            {[...Array(5)].map((_, index) => (
                                <SVGIcon
                                    key={index}
                                    src={`/New UI/SVG/star.svg`}
                                    style={{ color: "#ff492c" }}
                                    aria-label={`Star rating ${index + 1}`}
                                />
                            ))}
                        </div>
                    </div>
                    <div style={{ color: "#666666" }}>
                        <h3 className={styles.reviewTitle}>
                            They are by far the best of all SEO firms
                        </h3>
                        <p style={{ fontWeight: "400", fontSize: "14px" }}>
                            “My website traffic grew from 11k to 64k in two
                            months. Their services are by far the best of all
                            SEO firms I have worked with. I can not recommend
                            their services enough, and I am impressed by the
                            entire team of experts. They are affordable"
                        </p>
                    </div>
                    <div className={styles.reviewer}>
                        <img
                            src="/New UI/Images/seo-surge-noelle.png"
                            alt="Noelle Spinosa"
                            style={{ borderRadius: "50%" }}
                        />
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <span className={styles.reviewerName}>
                                Noelle Spinosa
                            </span>
                            <span className={styles.reviewerPosition}>
                                President, Noelle Salon & Spa Inc.
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            {/* Flexible Plan Section */}
            <section style={{ background: "#F4F8FA" }}>
                <h2 className={styles.sectionTitle}>
                    Flexible plans, for every business
                </h2>
                <div className={styles.planCardListContainer}>
                    {planDetails.map((plan, index) => (
                        <PlanCard
                            key={index}
                            title={plan.title}
                            description={plan.description}
                            features={plan.features}
                            cardStyle={plan.cardStyle}
                            buttonStyle={plan.buttonStyle}
                            mixpanelTrack={mixpanelTrack}
                            setModal={setModal}
                        />
                    ))}
                </div>
            </section>

            {/* All Packages Section */}
            <section>
                <h2
                    className={styles.sectionTitle}
                    style={{ marginTop: "10px" }}
                >
                    All Packages Include
                </h2>
                <div className={styles.packageList}>
                    {packageDetails.map((packageItem, index) => (
                        <div key={index} className={styles.packageCard}>
                            <SVGIcon
                                src={packageItem.iconPath}
                                style={{
                                    color: "initial",
                                    marginLeft: "5px",
                                }}
                                size={36}
                                aria-hidden="true"
                            />
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    textAlign: "left",
                                }}
                            >
                                {packageItem.description}
                            </span>
                        </div>
                    ))}
                </div>
            </section>

            {/* How it works Section */}
            <section>
                <h2 className={styles.sectionTitle}>How It Works</h2>
                <div className={styles.howItWorksContainer}>
                    {[
                        "Connect with our product specialist & share requirements",
                        "Finalize the plan & Start with our Weekly deliveries",
                        "Review & Revisions as required",
                    ].map((step, index) => (
                        <div key={index} className={styles.howItWorksCard}>
                            <div className={styles.stepNumber}>
                                {(index + 1).toString().padStart(2, "0")}
                            </div>
                            <span>{step}</span>
                        </div>
                    ))}
                </div>
            </section>

            {/* Case Study Section */}
            <section style={{ backgroundColor: "#F4F8FA" }}>
                <div>
                    <h2 className={styles.sectionTitle}>Case Study</h2>
                    <div
                        style={{
                            height: "305px",
                            borderRadius: "32px",
                            padding: "40px 36px",
                            backgroundColor: "#254ac5",
                            color: "#ffffff",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "start",
                            position: "relative",
                            overflow: "hidden",
                        }}
                    >
                        <h2
                            style={{
                                fontWeight: "600",
                                fontSize: "36px",
                                textAlign: "left",
                                zIndex: 1,
                            }}
                        >
                            Maximizing Visibility:
                            <br />
                            Noelle Salon’s SEO
                            <br />
                            Transformation
                        </h2>
                        <div className={styles.caseStydyIcon}>
                            <SVGIcon src={`/New UI/SVG/case-study-arc.svg`} />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                zIndex: 1,
                            }}
                        >
                            {[
                                {
                                    heading: "482%",
                                    paragraph: "Traffic Increase",
                                },
                                {
                                    heading: "50%",
                                    paragraph: "SERP Ranking Improved",
                                },
                                {
                                    heading: "5x",
                                    paragraph: "Improved keyword strategy",
                                },
                            ].map((el, index) => (
                                <div key={index}>
                                    <h3
                                        style={{
                                            fontWeight: "700",
                                            fontSize: "36px",
                                        }}
                                    >
                                        {el.heading}
                                    </h3>
                                    <p
                                        style={{
                                            fontWeight: "300",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {el.paragraph}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* SEO Growth Section */}
            <section className={styles.seoGrowthSection}>
                <p className={styles.seoGrowthTitle}>
                    Up your SEO game <br />
                    Drive growth
                </p>
                <Button
                    text={"Schedule Free Consultation"}
                    width={307}
                    height={46}
                    fontSize={19}
                    fontWeight={600}
                    borderRadius={10}
                    customClass={styles.faqConsultationButton}
                    handler={() => {
                        setModal(true);
                        mixpanelTrack("SEO Surge", {
                            action: "Schedule Free Consultation clicked - Bottom",
                        });
                    }}
                    aria-label="Schedule Free Consultation"
                />
            </section>

            {/* FAQs Section */}
            <section
                style={{
                    backgroundColor: "#F5F6FF",
                }}
            >
                <div className={styles.faqContainer}>
                    <h4 className={styles.faqTitle}>FAQs</h4>
                    {seoSurgeFaq.map((faq, index) => (
                        <FaqItem faq={faq} key={index} />
                    ))}
                </div>
            </section>
        </div>
    );
}

const PlanCard = ({
    title,
    description,
    features,
    cardStyle = {},
    buttonStyle = {},
    mixpanelTrack,
    setModal,
}) => {
    return (
        <div className={styles.cardContainer} style={cardStyle}>
            <h2
                className={styles.cardTitle}
                style={{
                    color:
                        title.toLowerCase() === "full-stack"
                            ? "#ffffff"
                            : undefined,
                }}
            >
                {title}
            </h2>
            <p style={{ marginTop: "15px" }}>{description}</p>
            <Button
                text={"Talk to Us"}
                Icon={() => (
                    <MdOutlineArrowOutward style={{ marginLeft: "5px" }} />
                )}
                width={155}
                fontSize={16}
                fontWeight={600}
                borderRadius={30}
                style={{
                    ...buttonStyle,
                    marginTop: "25px",
                    // padding: "9px 40px",
                }}
                handler={() => {
                    setModal(true);
                    mixpanelTrack("SEO Surge", {
                        action: `${title} Talk to Us clicked`,
                    });
                }}
                aria-label="Talk to Us"
            />
            <div
                style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    fontWeight: "400",
                    fontSize: "13px",
                }}
            >
                {features.map((feature, index) => (
                    <div key={index} className={styles.featureItem}>
                        <div style={{ width: "16px" }}>
                            <MdOutlineCheck size={16} />
                        </div>
                        <p>{feature}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const FaqItem = ({ faq }) => {
    const accordionClasses = useAccordionStyles();
    const [isOpen, setIsOpen] = useState(true);
    const ref = useRef();
    useOnClickOutside(ref, () => setIsOpen(false));

    return (
        <div
            onClick={() => setIsOpen((prevOpen) => !prevOpen)}
            ref={ref}
            style={{
                borderRadius: "8px",
                backgroundColor: "#ffffff",
            }}
        >
            <Accordion
                classes={accordionClasses}
                // expanded={isOpen}
                // aria-expanded={isOpen}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <AccordionSummary
                    expandIcon={
                        <MdKeyboardArrowDown
                            size={16}
                            style={{ color: "#6f1df4" }}
                        />
                    }
                >
                    <h5
                        style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#000000",
                            margin: 0,
                        }}
                    >
                        {faq.question}
                    </h5>
                </AccordionSummary>
                <AccordionDetails>
                    <p
                        style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#3C3C43",
                            margin: 0,
                        }}
                    >
                        {faq.answer}
                    </p>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const CalendlyModal = ({ modal, setModal }) => {
    return (
        <PopupModal
            open={modal}
            onModalClose={() => setModal(false)}
            url="https://calendly.com/d/3rz-gwx-tcd/seo-surge-demo?month=2024-08"
            rootElement={document.getElementById("root")}
        />
    );
};
