import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import styles2 from "../../../dashboard.module.css";
import { IoMdList } from "react-icons/io";
import { BiGift } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DateTimePicker from "react-datetime-picker";
import ConfirmationDialog from "../../../../../componentsV2/atoms/ConfirmationDialog/confirmation";
import { MdDescription } from "react-icons/md";
import File1 from "./lib/file";
import Button from "../../../../../componentsV2/atoms/Button/Button";
import StatusBanner from "../../../../../lib/dash/statusBanner";
import {
    formatDate,
    getFreelancerTypeFromID,
    getPastAsExpiredTime,
    getTime,
    returnSecondaryTexts,
} from "../../../../../utility/validateFunctions";
import { IoMdClose } from "react-icons/io";
import {
    GrDocumentImage,
    GrDocumentUpload,
    GrDocumentZip,
} from "react-icons/gr";
import { BarChartRounded, ChangeHistory } from "@material-ui/icons";
import { RiFileUploadLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ValidationError from "../../../../common/validationError";
import { url } from "../../../../../utility/config";
import { CircularProgress, MenuItem } from "@material-ui/core";
import Confirmation from "./lib/confirmation";

import { updateProjects } from "../../../../../store/actions/dashboard";
import ProjectDetailHeader from "../../../../../componentsV2/organisms/Modals/ProjectDetail/ProjectDetailHeader/ProjectDetailHeader";
import RequestDetails from "../../../../../componentsV2/organisms/Modals/ProjectDetail/RequstDetails/RequestDetails";
import BrandProfileAnswers from "../../../../../componentsV2/organisms/Modals/ProjectDetail/BrandProfileAnswers/BrandProfileAnswers";
import Separator from "../../../../../componentsV2/atoms/Separator/Separator";
import ProjectTimeline from "../../../../../componentsV2/molecules/ProjectTimeline";
import Dropdown from "../../../../../componentsV2/atoms/Dropdown/Dropdown";
import FeedbackForm from "../../../../../componentsV2/organisms/FeedbackForm/feedbackForm";
import Modal from "../../../../../componentsV2/atoms/Modal/Modal";
import { displayAlert } from "../../../../../store/actions/alert";
import useHelperFunctions from "../../../../../componentsV2/helperFunctions";
import Timer from "./Timer";

const text_truncate = function (str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

function getStatus(projects, pId, statusName) {
    console.log(statusName, "/////////////////////////////////////");
    try {
        for (const project of projects) {
            if (project.project_id == pId) {
                return project.status;
            }
        }
        return statusName;
    } catch (error) {
        return statusName;
    }
}

function getStageIndex(stage, _stages) {
    try {
        for (const i in _stages) {
            if (_stages[i].stage_assinee_id == stage) {
                console.log(i, "-------------------------");
                return +i;
            }
        }
        return 0;
    } catch {
        return 0;
    }
}

function getUserStage(_stages, userId) {
    try {
        for (const i in _stages) {
            if (_stages[i].stage_assinee_id == userId) {
                return _stages[+i];
            }
        }
        return {};
    } catch {
        return {};
    }
}

function Details({
    c_doc_link,
    brandProfile,
    data,
    variant_name,
    icon,
    statusName,
    setStatusName,
    setIsModalOpen,
    setProjectName,
    projectName,
    timelineData,
    tempComments,
    projectID,
    userID,
    activeTab,
    setActiveTab,
    tabSwitchLoading,
    freelancerStatus,
    doc_link,
    didAssign,
    setDidAssign,
    setNoFreelancerAssignedError,
    setData,
    stageOptions,
}) {
    const userId = useSelector((state) => state.auth.userId);
    const current_stage = (() => {
        try {
            for (const i in data.stages) {
                if (data.stages[i].stage_assinee_id == userId) {
                    return data.stages[+i] ? data.stages[+i] : {};
                }
            }
            return {};
        } catch (err) {
            console.log(err);
            return {};
        }
    })();
    const { postData } = useHelperFunctions();
    const token = useSelector((state) => state.auth.token);

    const [feedback, setFeedback] = useState(false);
    const [feedbackData, setFeedbackData] = useState(null);
    const [error, setError] = useState(null);
    const [jumpStage, setJumpStage] = useState(null);
    const [success, setSuccess] = useState(null);
    const [name, setName] = useState(projectName);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [dialogConfig, setDialogConfig] = useState({
        heading: "Click OK to Confirm",
        textType: true,
        handler: () => {},
    });
    const [dialog, setDialog] = useState(false);
    const [edd, setEdd] = useState(() =>
        data.projectDetail.edd ? new Date(data.projectDetail.edd) : null,
    );
    const projects = useSelector((state) => state.dash.projects);
    const STATUS = getStatus(projects, projectID, statusName);
    const dispatch = useDispatch();
    const [fStatus, setFStatus] = useState(current_stage.stage_assinee_status);
    const [listOfFreelancers, setListOfFreelancers] = useState(null);
    const [confirmationAction, setConfirmationAction] = useState("");
    const [adminMarkForReviewLoading, setAdminMarkForReviewLoading] =
        useState(false);
    const [listOfFreelancerTypes, setListOfFreelancerTypes] = useState(null);
    const [freelancerType, setFreelancerType] = useState(
        data.projectDetail.type ? data.projectDetail.type : 0,
    );
    const [freelancer, setFreelancer] = useState(
        data.projectDetail.freelancerName
            ? data.projectDetail.freelancerName
            : null,
    );
    const [freelancerID, setFreelancerID] = useState(
        data.projectDetail.freelancerID
            ? data.projectDetail.freelancerID
            : null,
    );
    const [threshold, setThreshold] = useState(
        data.projectDetail.threshold_text
            ? data.projectDetail.threshold_text
            : "30",
    );
    const reqDate = new Date(new Date().setDate(new Date().getDate() + 1));
    reqDate.setHours(17);
    reqDate.setMinutes(0);

    const [deadline, setDeadline] = useState(
        data.projectDetail.deadline
            ? new Date(data.projectDetail.deadline)
            : new Date(reqDate),
    );
    const [dialogType, setDialogType] = useState(false);

    const [statusChangeLoading, setStatusChangeLoading] = useState(false);
    const [freelancerSubmissionLoader, setFreelancerSubmissionLoader] =
        useState(false);
    const [assignToFreelancerLoading, setAssignToFreelancerLoading] =
        useState(false);
    const [checkedFiles, setCheckedFiles] = useState([]);

    const didMount = useRef(false);

    const role = useSelector((state) => state.auth.role);

    const [status, setStatus] = useState(statusName);

    function openDialog(type) {
        setDialog(true);
        setDialogType(type);
    }

    const didMountForType = useRef(false);

    useEffect(() => {
        dispatch(
            updateProjects({
                id: projectID,
                data: { status: statusName },
            }),
        );
    }, []);

    useEffect(() => {
        if (stageOptions[0]) setJumpStage(stageOptions[0].id);
    }, [stageOptions]);

    useEffect(() => {
        if (
            (role == "admin" || role == "account_manager") &&
            didMountForType.current
        ) {
            setListOfFreelancers(null);
            setFreelancer(null);
            if (freelancerType == "Choose Type") {
                return;
            }

            axios({
                method: "get",
                url: `${url}/api/freeLancer/getList?type=${freelancerType}`,
                headers: {
                    Authorization: token,
                },
            })
                .then((res) => {
                    // console.log(res.data);
                    setListOfFreelancers(res.data.data.freeLancers);
                })
                .catch((err) => console.log(err));
        } else {
            didMountForType.current = true;
        }
    }, [freelancerType]);

    let attachment = false;

    function setAttachment(boolean) {
        attachment = boolean;
    }
    function createMarkup(ans) {
        return { __html: ans };
    }

    async function freeLancerAccept() {
        setStatusChangeLoading(true);
        const response = await postData({
            url: "/api/freeLancer/action",
            payload: {
                request_id: data.projectDetail.request_id,
                project_id: projectID,
                status: 2,
                stage:
                    getCurrentStage(data.projectDetail.current_stage) || null,
            },
        });
        if (response?.status == 200) {
            setStatusName(2);
            setStatusChangeLoading(false);
        } else {
            setStatusChangeLoading(false);
        }
    }

    async function freeLancerReject() {
        setFreelancerSubmissionLoader(true);
        const response = await postData({
            url: "/api/freeLancer/action",
            payload: {
                request_id: data.projectDetail.request_id,
                project_id: projectID,
                status: 6,
                stage:
                    getCurrentStage(data.projectDetail.current_stage) || null,
            },
        });
        if (response?.status == 200) {
            setStatusName(6);
            setFreelancerSubmissionLoader(false);
        } else {
            setFreelancerSubmissionLoader(false);
        }
    }

    function eddHandler(v) {
        axios({
            method: "post",
            url: `${url}/api/project/updateUserRequests`,
            headers: {
                Authorization: token,
            },
            data: {
                edd: v,
                project_id: projectID,
            },
        }).then((data) => {
            setSuccess("Expected Delivery Date Changed Successfully");
            setTimeout(() => setSuccess(null), 2500);
            setEdd(v);
        });
    }

    function changeProjectName(value) {
        if (projectName == value) return;
        if (value.trim().length >= 3 && value.trim().length <= 200) {
            setError(null);
            axios({
                method: "put",
                url: `${url}/api/project/updateProject`,
                headers: {
                    Authorization: token,
                },
                data: {
                    projectName: value,
                    projectId: data?.projectDetail?.id,
                },
            }).then((data) => {
                dispatch(
                    updateProjects({
                        id: projectID,
                        data: { name: value.trim() },
                    }),
                );
            });
        } else {
            setName(projectName.trim());
            setError("Project name must be of 3 to 200 Characters");
        }
    }

    useEffect(() => {
        console.log(deadline);
    }, [deadline]);

    useEffect(() => {
        if (role == "admin" || role == "account_manager") {
            axios({
                method: "get",
                url: `${url}/api/freeLancer/types`,
                headers: {
                    Authorization: token,
                },
            })
                .then((res) => {
                    console.log(res.data);
                    setListOfFreelancerTypes(res.data.data.freeLancersTypes);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    function assignToFreelancer() {
        if ((role == "admin" || role == "account_manager") && freelancerID) {
            setAssignToFreelancerLoading(true);

            const thresholdDate = new Date();
            thresholdDate.setMinutes(
                thresholdDate.getMinutes() + Number(threshold),
            );

            axios({
                method: "post",
                url: `${url}/api/freeLancer/assign`,
                headers: {
                    Authorization: token,
                },
                data: {
                    assignee: freelancerID,
                    project_id: projectID,
                    threshold: thresholdDate,
                    deadline: deadline,
                    threshold_text: threshold,
                    stage: 3,
                },
            })
                .then((res) => {
                    dispatch(
                        updateProjects({
                            id: projectID,
                            data: { status: "IN PROGRESS" },
                        }),
                    );
                    setFStatus(8);
                    setAssignToFreelancerLoading(false);
                    setDidAssign(true);
                })
                .catch((err) => {
                    console.log(err);
                    setAssignToFreelancerLoading(false);
                });
        }
    }

    const freelancerSubmitForReview = () => {
        setFreelancerSubmissionLoader(true);
        setFeedback(false);
        axios({
            method: "post",
            url: `${url}/api/freeLancer/action`,
            headers: {
                Authorization: token,
            },
            data: {
                project_id: projectID,
                status: 3,
                isFreelancer: true,
                stage: getCurrentStage(data.projectDetail.current_stage),
                feedback: feedbackData,
            },
        })
            .then((res) => {
                setStatusName(3);
                setFreelancerSubmissionLoader(false);
                setFeedbackData(null);
                dispatch(
                    displayAlert({
                        alertMessage: "Done",
                        alertType: "",
                    }),
                );
            })
            .catch((err) => {
                setFreelancerSubmissionLoader(false);
                setFeedbackData(null);
            });
        console.log(status);
    };

    function getAssignButton(freelancerStatus) {
        if (didAssign) {
            return null;
        }
        if (
            freelancerStatus == 1 ||
            !freelancerStatus ||
            freelancerStatus == 6 ||
            freelancerStatus == 7
        ) {
            return (
                <Button
                    loading={assignToFreelancerLoading}
                    width="150px"
                    fontSize="12px"
                    text="Assign"
                    handler={assignToFreelancer}
                    disabled={
                        freelancer &&
                        threshold &&
                        deadline &&
                        freelancer !== "Assign Freelancer"
                            ? false
                            : true
                    }
                >
                    Assign
                </Button>
            );
        } else return null;
    }

    function getPullbackButton(freelancerStatus) {
        if (status === "ACCEPTED") return null;

        if (
            freelancerStatus === 5 ||
            freelancerStatus === 4 ||
            freelancerStatus === 8 ||
            freelancerStatus === 2 ||
            freelancerStatus === 3
        ) {
            return (
                <Button
                    width="150px"
                    text="Pull Back"
                    fontSize="12px"
                    handler={() => {
                        setConfirmationAction("pullback");
                        openDialog(false);
                    }}
                ></Button>
            );
        } else return null;
    }

    function toAssignFreelancer(freelancerStatus) {
        if (didAssign) {
            return false;
        }
        if (
            freelancerStatus == 6 ||
            freelancerStatus == 1 ||
            !freelancerStatus ||
            freelancerStatus == 7
        ) {
            return true;
        } else return false;
    }

    function getCurrentStage(stage) {
        try {
            for (const i in data.stages) {
                console.log(i, "-------------------------");
                if (data.stages[i].stage_assinee_id == userId) {
                    return data.stages[+i].id;
                }
            }
            return 0;
        } catch (err) {
            console.log(err);
            return 0;
        }
    }
    console.log(statusName);
    function updateFreelancerStatus(freelancerStatus, setLoader, message = "") {
        setAdminMarkForReviewLoading(true);
        setFeedback(false);
        if (setLoader) setLoader(true);
        axios({
            method: "post",
            url: `${url}/api/freeLancer/action`,
            headers: {
                Authorization: token,
            },
            data: {
                project_id: projectID,
                status: freelancerStatus,
                isFreelancer: true,
                stage: getCurrentStage(data.projectDetail.current_stage),
                new_stage: jumpStage,
                feedback: feedbackData,
            },
        })
            .then((res) => {
                if (freelancerStatus == 4) {
                    setStatusName(3);
                } else setStatusName(freelancerStatus);
                if (freelancerStatus == 7) {
                    setFreelancer(null);
                    setFreelancerID(null);
                    setDidAssign(false);
                    setThreshold("30");
                    setFreelancerType(0);
                }
                setDialog(false);
                if (setLoader) setLoader(false);
                setFreelancerSubmissionLoader(false);
                setAdminMarkForReviewLoading(false);
                setFeedbackData(null);
                dispatch(
                    displayAlert({
                        alertMessage: "Done",
                        alertType: "",
                    }),
                );
            })
            .catch((err) => {
                setFreelancerSubmissionLoader(false);
                setAdminMarkForReviewLoading(false);
                setFeedbackData(null);
            });
    }

    const handleStatusChange = (e, closeDialog) => {
        if (role === "admin" || role == "account_manager") {
            setStatusChangeLoading(true);

            const changeStatusObject = {
                projectId: projectID,
                status: e.target.value,
            };

            axios({
                method: "put",
                url: `${url}/api/project/updateStatus`,
                data: changeStatusObject,
                headers: {
                    Authorization: token,
                },
            })
                .then((res) => {
                    dispatch(
                        updateProjects({
                            id: projectID,
                            data: { status: changeStatusObject.status },
                        }),
                    );
                    setError(null);
                    setStatusChangeLoading(false);
                    closeDialog && closeDialog();
                })
                .catch((err) => {
                    setStatusChangeLoading(false);
                    console.log(err.response.data);
                    closeDialog && closeDialog();

                    if (
                        err.response.data.message ==
                        "request not assigned to freelancer yet."
                    )
                        setError(
                            "Deliverable file not found!!.Please assign a freelancer.",
                        );
                    else {
                        setError("Something went wrong!!!");
                    }
                });
        }
    };

    function getMultiSetData(answer) {
        let element = [];
        for (const property in answer) {
            element.push(
                <span className={styles.answer}>
                    {property}: {answer[property]}
                </span>,
            );
        }
        return element;
    }
    // For counting number of attachments
    let noOfAttachments = 0;

    tempComments.map((item) => {
        if (item?.attachmentName) {
            noOfAttachments = noOfAttachments + 1;
        }
    });
    timelineData.map((item) => {
        if (item.attachmentName) {
            noOfAttachments = noOfAttachments + 1;
        }
    });
    data.answers.map((item) => {
        if (item.attachment) {
            noOfAttachments = noOfAttachments + 1;
        }
    });

    return (
        <div>
            {success ? (
                <div
                    className={
                        styles2.errorMessage + " " + styles2.successMessage
                    }
                >
                    {success}
                </div>
            ) : null}

            {feedback && (
                <Modal setModal={setFeedback}>
                    {" "}
                    <FeedbackForm
                        handler={() => {
                            feedback == "goodToGo"
                                ? freelancerSubmitForReview()
                                : updateFreelancerStatus(4);
                        }}
                        setFeedbackData={setFeedbackData}
                    />
                </Modal>
            )}
            {dialog && (
                <Confirmation
                    setStatusName={setStatusName}
                    setData={setData}
                    setStatus={setStatus}
                    updateFreelancerStatus={updateFreelancerStatus}
                    role={role}
                    projectID={projectID}
                    userID={userID}
                    setDialog={setDialog}
                    modalText=""
                    submit={dialogType}
                    confirmationAction={confirmationAction}
                    checkedFiles={checkedFiles}
                    // modalText={
                    //   !pushModal
                    //     ? undefined
                    //     : activeTab === 'freelancer'
                    //     ? 'Are you sure you want to push file(s) to client?'
                    //     : 'Are you sure you want to push file(s) to freelancer?'
                    // }
                    activeTab={activeTab}
                ></Confirmation>
            )}

            {confirmationDialog && (
                <ConfirmationDialog
                    setDialog={setConfirmationDialog}
                    submit={dialogConfig.textType}
                    handler={dialogConfig.handler}
                    modalHeading={dialogConfig.heading}
                ></ConfirmationDialog>
            )}

            <div className={styles.container}>
                <div className={`${styles.heading} ${styles.headingStatus}`}>
                    {role == "admin" ||
                    role == "account_manager" ||
                    role == "user" ? (
                        <ProjectDetailHeader
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    e.currentTarget.blur();
                                }
                            }}
                            onBlur={() => changeProjectName(name)}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            status={STATUS}
                            projectId={projectID}
                            variant={data?.projectDetail?.variant_name}
                            lastUpdatedBy={
                                timelineData.length > 0 &&
                                timelineData[0].userName
                                    ? timelineData[0].userName
                                    : ""
                            }
                            updated_at={
                                timelineData.length > 0
                                    ? timelineData[0].updated_at
                                    : data?.projectDetail.created_at
                            }
                        />
                    ) : (
                        <p className={styles.projectTitle}>{name}</p>
                    )}{" "}
                    {!(role === "admin" || role == "account_manager") ? (
                        <>{/* <StatusBanner status={status} /> */}</>
                    ) : !data.stages ? (
                        <div>
                            {statusChangeLoading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <p className={styles.bannerGroupTitle}>
                                        REQUEST STATUS
                                    </p>
                                    <label
                                        style={{ display: "none" }}
                                        htmlFor="status"
                                    >
                                        REQUEST STATUS
                                    </label>
                                    <select
                                        style={{ cursor: "pointer" }}
                                        name="status"
                                        id="status"
                                        value={STATUS}
                                        onChange={handleStatusChange}
                                    >
                                        <option value="NEW">NEW</option>
                                        <option value="QUEUED">QUEUED</option>
                                        <option value="IN PROGRESS">
                                            IN PROGRESS
                                        </option>
                                        <option value="SUBMITTED FOR REVIEW">
                                            SUBMITTED FOR REVIEW
                                        </option>
                                        <option value="REVISION REQUESTED">
                                            REVISION REQUESTED
                                        </option>
                                        <option value="*CANCELLED*">
                                            CANCELLED
                                        </option>
                                        <option value="ACCEPTED">
                                            ACCEPTED
                                        </option>
                                    </select>
                                </>
                            )}
                        </div>
                    ) : (
                        STATUS != "*CANCELLED*" && (
                            <Button
                                text="Cancel Request"
                                loading={statusChangeLoading}
                                disabled={statusChangeLoading}
                                handler={() => {
                                    setDialogConfig({
                                        textType: false,
                                        handler: (closeDialog) =>
                                            handleStatusChange(
                                                {
                                                    target: {
                                                        value: "*CANCELLED*",
                                                    },
                                                },
                                                closeDialog,
                                            ),
                                        heading:
                                            "Are you sure you want to Cancel the Request (" +
                                            projectID +
                                            ") ?",
                                    });

                                    setConfirmationDialog(true);
                                }}
                                secondary
                                width={150}
                                marginTop={10}
                            />
                        )
                    )}
                    {edd ? (
                        <div>
                            <p className={styles.bannerGroupTitle}></p>
                            <p className={styles.bannerGroupTitle}>
                                EXPECTED DELIVERY DATE
                            </p>
                            {role == "admin" || role == "account manager" ? (
                                <DateTimePicker
                                    onChange={eddHandler}
                                    value={edd}
                                    minDate={new Date()}
                                />
                            ) : (
                                <div
                                    className={styles.subTitleGroupPart}
                                    style={{ fontWeight: 500 }}
                                >
                                    {new Date(
                                        role == "free_lancer"
                                            ? getUserStage(data.stages, userId)
                                                  .stage_assinee_deadline
                                            : edd,
                                    ).toDateString()}{" "}
                                    &nbsp;
                                    {new Date(
                                        role == "free_lancer"
                                            ? getUserStage(data.stages, userId)
                                                  .stage_assinee_deadline
                                            : edd,
                                    ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            <Separator />
            {error ? (
                <div>
                    <ValidationError msg={error} />
                </div>
            ) : null}

            {/* <div className={styles.container}>
        <div className={styles.left}></div>

        <div className={styles.info}>
          <div>{variant_name ? <span>{variant_name + ' '}</span> : ' '}</div>
          <div>
            <span>Request ID</span> <span>#{data?.projectDetail?.id} </span> |{" "}
            {variant_name ? <span>{variant_name + " "}</span> : " "} {(role == 'admin' || role == 'account_manager') && 
            <span> | {data?.projectDetail.email + " "}</span>}
          </div>
          <div>
            <span>LAST UPDATED</span>{" "}
            <span>{formatDate(data?.projectDetail?.updated_at)}</span>{" "}
            <span>
              {new Date(data.projectDetail.updated_at).getHours()}:
              {new Date(data.projectDetail.updated_at).getMinutes()}
            </span>{' '}
          </div>
        </div>
      </div> */}
            {doc_link && (
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.headings}>
                            <BiGift
                                style={{ color: "#92929d", fontSize: "1.4rem" }}
                            />
                        </div>
                    </div>
                    <div className={styles.details} style={{ width: "100%" }}>
                        <div className={styles.headingDetails}>
                            <div
                                className={styles.headings}
                                style={{ marginTop: "9px" }}
                            >
                                Deliverable
                            </div>
                        </div>
                        <p className={styles.deliverableSubheading}>
                            Click on the link to the deliverable. This will also
                            be accessible from Google drive folder shared by
                            Scalenut.
                        </p>
                    </div>
                </div>
            )}
            {doc_link && (
                <div className={styles.deliverableBox}>
                    <div className={styles.deliverablesLeft}>
                        {timelineData.length > 0 && (
                            <p>
                                Last updated{" "}
                                {timelineData.length > 0 &&
                                    getTime(timelineData[0].updated_at)}
                            </p>
                        )}
                        <a
                            href={
                                activeTab === "freelancer"
                                    ? doc_link
                                    : c_doc_link
                            }
                            target={"_blank"}
                        >
                            {activeTab === "freelancer"
                                ? text_truncate(doc_link, 60)
                                : text_truncate(c_doc_link, 60)}
                        </a>
                    </div>

                    <a
                        href={
                            activeTab === "freelancer" ? doc_link : c_doc_link
                        }
                        target={"_blank"}
                        className={styles.deliverableBoxButtonSide}
                    >
                        <button className={styles.deliverableBoxButton}>
                            Open Link
                        </button>
                    </a>
                </div>
            )}

            <div className={styles.container}>
                <div className={styles.details}>
                    <RequestDetails
                        wordsToDeliver={data?.projectDetail?.words_to_deliver}
                        answers={data.answers}
                    />
                </div>
                {data.stages && role != "free_lancer" && (
                    <ProjectTimeline
                        listOfFreelancerTypes={listOfFreelancerTypes}
                        data={data}
                        _stages={data.stages}
                        setConfirmationAction={setConfirmationAction}
                        openDialog={openDialog}
                        setData={setData}
                    />
                )}
            </div>
            {brandProfile && (
                <div className={styles.container}>
                    <BrandProfileAnswers brandProfile={brandProfile} />
                </div>
            )}
            {/* {role == "admin" || role == "account_manager" ? (
        <div className={styles.tabsContainer}>
          <div
            className={`${styles.tab} ${activeTab === "freelancer" ? styles.active : null
              }`}
            onClick={() => {
              setCheckedFiles([]);
              setActiveTab("freelancer");
            }}
          >
            EXECUTION WINDOW
          </div>
          <div
            className={`${styles.tab} ${activeTab === "client" ? styles.active : null
              }`}
            onClick={() => {
              setCheckedFiles([]);
              setActiveTab("client");
            }}
          >
            CLIENT WINDOW
          </div>
        </div>
      ) : null} */}

            {role === "free_lancer" &&
            current_stage.stage_assinee_id == userId &&
            (statusName == 2 || statusName == 4 || statusName == 7) ? (
                !data?.stages[getStageIndex(userId, data.stages)]
                    ?.can_request_revision ||
                !data?.stages[getStageIndex(userId, data.stages)]
                    ?.freelancer_type == 4 ? (
                    <div className={styles.buttons}>
                        {
                            <Button
                                loading={freelancerSubmissionLoader}
                                width={"150px"}
                                fontSize="12px"
                                text={"Submit For Review"}
                                handler={freelancerSubmitForReview}
                            ></Button>
                        }
                    </div>
                ) : (
                    <div className={styles.buttons}>
                        <Button
                            loading={freelancerSubmissionLoader}
                            width={"150px"}
                            text={"Good To Go"}
                            handler={() => {
                                current_stage.can_submit_form == 1
                                    ? setFeedback("goodToGo")
                                    : freelancerSubmitForReview();
                            }}
                        ></Button>
                        <div className={styles.row}>
                            <Dropdown
                                label="Assign to previous"
                                width="150px"
                                options={stageOptions}
                                handler={setJumpStage}
                                disabled={stageOptions.length <= 1}
                            />

                            <Button
                                loading={adminMarkForReviewLoading}
                                width={"150px"}
                                text={"Needs Rework"}
                                secondary
                                handler={() => {
                                    current_stage.can_submit_form == 1
                                        ? setFeedback(true)
                                        : (() => {
                                              setConfirmationAction("revision");
                                              openDialog(true);
                                          })();
                                }}
                            ></Button>
                        </div>
                    </div>
                )
            ) : null}

            {role === "free_lancer" &&
            data.projectDetail.request_id &&
            current_stage.stage_assinee_id == userId &&
            (statusName == 1 || statusName == 8) ? (
                <div className={styles.buttons2}>
                    <div className={styles.buttons}>
                        <Button
                            loading={statusChangeLoading}
                            width={"150px"}
                            text={"Accept"}
                            handler={() => {
                                freeLancerAccept();
                            }}
                        ></Button>

                        <Button
                            loading={freelancerSubmissionLoader}
                            width={"150px"}
                            text={"Reject"}
                            secondary
                            handler={() => {
                                freeLancerReject();
                            }}
                        ></Button>
                    </div>
                    <div>
                        <Timer
                            threshold={current_stage.stage_assinee_threshold}
                            setStatusName={setStatusName}
                        />
                    </div>
                </div>
            ) : null}

            {(role == "admin" || role == "account_manager") &&
            activeTab === "freelancer" &&
            fStatus == 3 ? (
                <div>
                    {
                        <div
                            style={{ textAlign: "right", paddingRight: "30px" }}
                        >
                            <Button
                                loading={adminMarkForReviewLoading}
                                width={"150px"}
                                fontSize="12px"
                                text={"Mark For Revision"}
                                handler={() => updateFreelancerStatus(4)}
                            ></Button>
                            {/* <Button
                handler={() => {
                  updateFreelancerStatus(5);
                }}
                styles={{ border: '1px solid orange' }}
                text={`ACCEPT`}
                btnStyle={'basic'}
              ></Button> */}
                        </div>
                    }
                </div>
            ) : null}

            {
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div>
                            <ImAttachment
                                style={{ color: "#92929d", fontSize: "1rem" }}
                            />
                        </div>
                    </div>
                    <div className={styles.files}>
                        <div className={styles.heading}>
                            <div
                                style={{ marginTop: 7 }}
                                className={styles.headings}
                            >
                                Attachments ({noOfAttachments})
                                {/* (
                {attachmentsRef.current
                  ? attachmentsRef.current.children.length
                  : ''}
                ) */}
                            </div>
                        </div>
                        {
                            <div className={styles.filesList}>
                                {tempComments.map((item) =>
                                    item?.attachmentName ? (
                                        <File1
                                            checkedFiles={checkedFiles}
                                            setCheckedFiles={setCheckedFiles}
                                            icon={
                                                item.imageSmall
                                                    ? item.imageSmall
                                                    : item.attachmentName
                                            }
                                            origImg={item.attachmentName}
                                            name={item?.fileName}
                                            userName={item.userName}
                                            date={item?.created_at}
                                            imageName={item?.imageName}
                                        >
                                            {setAttachment(true)}
                                        </File1>
                                    ) : null,
                                )}
                                {data?.answers?.map((item) =>
                                    item.attachment ? (
                                        <File1
                                            checkedFiles={checkedFiles}
                                            setCheckedFiles={setCheckedFiles}
                                            icon={
                                                item.imageSmall
                                                    ? item.imageSmall
                                                    : item.attachment
                                            }
                                            origImg={item.attachment}
                                            date={
                                                data?.projectDetail.created_at
                                            }
                                            name={item.fileName}
                                            imageName={
                                                item.imageName
                                                    ? item.imageName
                                                    : item.attachment
                                            }
                                        >
                                            {setAttachment(true)}
                                        </File1>
                                    ) : null,
                                )}
                                {timelineData.map((item) =>
                                    item.attachmentName ? (
                                        <File1
                                            checkedFiles={checkedFiles}
                                            setCheckedFiles={setCheckedFiles}
                                            icon={
                                                item.imageSmall
                                                    ? item.imageSmall
                                                    : item.attachmentName
                                            }
                                            origImg={item.attachmentName}
                                            name={item.fileName}
                                            userName={item.userName}
                                            imageName={item?.imageName}
                                            date={item.created_at}
                                        >
                                            {setAttachment(true)}
                                        </File1>
                                    ) : null,
                                )}
                                {attachment ? null : (
                                    <span
                                        style={{
                                            color: "#92929d",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        No File Attached
                                    </span>
                                )}
                            </div>
                        }
                    </div>
                </div>
            }
            {(role == "admin" || role == "account_manager") &&
            checkedFiles.length > 0 ? (
                <button
                    className={styles.pushButton}
                    onClick={() => {
                        setConfirmationAction("push");
                        if (
                            (role === "admin" || role === "account_manager") &&
                            activeTab === "client" &&
                            (fStatus === 1 || !fStatus) &&
                            !didAssign
                        ) {
                            setNoFreelancerAssignedError(true);
                            setTimeout(
                                () => setNoFreelancerAssignedError(false),
                                3000,
                            );
                            return;
                        }

                        openDialog(false);
                    }}
                >
                    <RiFileUploadLine
                        style={{
                            color: "#92929d",
                            marginRight: "8px",
                            fontSize: "1rem",
                        }}
                    />
                    Push to{" "}
                    {activeTab === "freelancer" ? "client" : "freelancer"}
                </button>
            ) : null}
            {status === "SUBMITTED FOR REVIEW" &&
                (role == "user" ||
                    role == "admin" ||
                    role == "account_manager") && (
                    <div className={styles.buttons}>
                        <Button
                            handler={() => {
                                setConfirmationAction("userRevision");
                                openDialog(true);
                            }}
                            text={`Mark For Revision`}
                            fontSize="12px"
                            secondary
                            width="150px"
                        ></Button>{" "}
                        <Button
                            handler={() => {
                                setConfirmationAction("accept");
                                openDialog(false);
                            }}
                            text={`Accept`}
                            fontSize="12px"
                            btnStyle={"basic"}
                            width="150px"
                        ></Button>
                    </div>
                )}

            {tabSwitchLoading ? (
                <div className={styles.tabSwitchLoading}>
                    <CircularProgress />
                </div>
            ) : null}
        </div>
    );
}

export default Details;
