import React, { useContext, useEffect, useState } from "react";
import useHelperFunctions from "../../../helperFunctions";
import styles from "./ContentGap.module.css";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import SVGIcon from "../../../atoms/SVGIcon";
import {
    IoEyeOutline,
    IoRefreshSharp,
    IoSettingsOutline,
} from "react-icons/io5";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import ReportContext from "../../Research/ReportContext";
import { displayAlert } from "../../../../store/actions/alert";
import {
    Checkbox,
    CircularProgress,
    debounce,
    makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import Button from "../../../atoms/Button/Button";
import EditorContext from "../EditorContext";
import Quill from "quill";
import { HiChevronDown } from "react-icons/hi2";
import { getPara } from "../../Research/Competiton/CompetetionAcc";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import HighLighter from "../../../atoms/HightLighter/HighLighter";
import UserFeedback from "../../UserFeedback/UserFeedback";
const Delta = Quill.import("delta");

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: "18px",
        height: "18px",
        color: "#b0b8c3",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

function ContentGap({ reportInfo }) {
    const [loading, setLoading] = useState(false);
    const [showCompetitors, setShowCompetitors] = useState(false);
    const [competitorRanks, setCompetitorRanks] = useState([]);
    const [addAll, setAddAll] = useState(false);
    const [aiGenerate, setAiGenerate] = useState(false);
    const [feedbackClose, setFeedbackClose] = useState(true);
    const [errorMsg, setErrorMsg] = useState({
        outlineError: false,
        msg: "No Data Found!",
    });
    const checkBoxClasses = checkBoxCustom();
    const { competetion, setReportData, contentGapData, contentGapRating } =
        useContext(ReportContext);
    const { postData } = useHelperFunctions();
    const editorData = useContext(EditorContext);
    const dispatch = useDispatch();
    const { mixpanelTrack } = useMixpanelHook();

    const insertAll = () => {
        document.quill.editor.disable();
        let text = "";
        for (let i = 0; i < contentGapData?.length; i++) {
            text += contentGapData[i]?.sub_topics + "\n";
        }
        clickToAdd(text);
        setAddAll(true);
        document.quill.editor.enable();
    };

    const clickToAdd = async (subTopic) => {
        document.quill.editor.disable();

        for (
            let index = document.quill.editor.cursor;
            index < document.quill.editor.getLength();
            index++
        ) {
            if (
                document.quill.editor.getText(index - 1, 1) == " " ||
                document.quill.editor.getText(index - 1, 1) == "\n" ||
                document.quill.editor.getText(index - 1, 1) == ""
            )
                break;
            document.quill.editor.cursor++;
        }
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor)
                .delete(0)
                .insert("\n", { header: 2 }),
        );
        // document.quill.editor.cursor = document.quill.editor.cursor + 1;
        await editorData.writeWithAnimation(subTopic);
        document.quill.editor.enable();
    };

    const getContentGapData = async ({
        isRegenerate = false,
        isFirstHit = false,
    }) => {
        setLoading(true);
        setReportData((ps) => ({
            ...ps,
            contentGapData: [],
            contentGapRating: true,
        }));
        sessionStorage.setItem("hitContentGap", "yes");
        const allHTags =
            document?.quill?.editor?.root?.querySelectorAll(
                "h2, h3, h4, h5, h6",
            );
        const temp = [];

        for (const heading of allHTags) {
            temp.push(heading);
        }
        const outline = [];
        temp?.map((heading) => {
            if (heading?.innerText?.length) {
                outline.push({
                    text: heading?.innerText,
                    tag_name: heading?.tagName,
                });
            }
        });
        if (outline?.length < 4) {
            setErrorMsg({
                outlineError: true,
                msg: "Insufficient headings detected. Please add more headings or use article writer to generate a new blog",
            });
        } else {
            const res = await postData({
                url: "/api/contentGap/getContentGap",
                payload: {
                    report_id: reportInfo.report_id,
                    title:
                        document?.quill?.editor?.root?.getElementsByTagName(
                            "h1",
                        )[0]?.innerText || "",
                    outlines: outline,
                    isRegenerate: isFirstHit
                        ? false
                        : sessionStorage.getItem("newGap") == "true"
                        ? true
                        : isRegenerate,
                },
            });
            if (res.status == 200) {
                sessionStorage.setItem("newGap", false);
                if (res.data?.contentGapInfo?.length) {
                    setReportData((ps) => ({
                        ...ps,
                        contentGapData: res.data?.contentGapInfo,
                        contentGapRating: res.data?.hasSatisfactionScore,
                    }));
                } else {
                    sessionStorage.setItem("hitContentGap", "no");
                    setErrorMsg({
                        outlineError: false,
                        msg: "No missing topics identified, your outline covers all the topics present in your competitor's outline.",
                    });
                    mixpanelTrack("Topic Gap", {
                        action: "No missing topic found",
                    });
                }
            }
        }
        setLoading(false);
    };

    const findContentGaps = async () => {
        setLoading(true);
        setShowCompetitors(false);
        const res = await postData({
            url: "/api/contentGap/updateRank",
            payload: {
                report_id: reportInfo.report_id,
                rank: competitorRanks,
            },
        });
        if (res.status == 200) {
            getContentGapData({ isRegenerate: true });
        }
    };

    useEffect(() => {
        if (!contentGapRating) {
            setTimeout(() => {
                setFeedbackClose(false);
            }, 5000);
        }
    }, [contentGapRating]);

    useEffect(() => {
        const updateRankAndGetData = async (compRanks) => {
            setLoading(true);
            const res = await postData({
                url: "/api/contentGap/updateRank",
                payload: {
                    report_id: reportInfo.report_id,
                    rank: compRanks,
                },
            });
            if (res.status == 200) {
                getContentGapData({ isRegenerate: false, isFirstHit: true });
            } else {
                setLoading(false);
            }
        };
        if (reportInfo.rank) {
            setCompetitorRanks(reportInfo.rank);
            if (
                !contentGapData?.length &&
                sessionStorage.getItem("hitContentGap") != "no"
            ) {
                getContentGapData({ isRegenerate: false, isFirstHit: true });
            }
        } else {
            const filteredComp = competetion?.filter(
                (ele) => ele?.word_count >= 200 && ele?.word_count <= 3500,
            );

            const finalComp = filteredComp?.filter(
                (ele) => ele?.htags_count >= 3 && ele?.htags_count <= 100,
            );

            let temp = finalComp?.length
                ? finalComp
                : filteredComp?.length
                ? filteredComp
                : competetion;

            temp = temp
                .sort((a, b) => b.grade - a.grade)
                .slice(0, 3)
                .map((i) => i.rank);

            setCompetitorRanks(temp);
            if (
                !contentGapData?.length &&
                sessionStorage.getItem("hitContentGap") != "no"
            ) {
                updateRankAndGetData(temp);
            }
        }
        if (
            !contentGapData?.length &&
            sessionStorage.getItem("hitContentGap") == "no"
        ) {
            setErrorMsg({
                outlineError: false,
                msg: "No missing topics identified, your outline covers all the topics present in your competitor's outline.",
            });
        }
    }, []);
    return loading ? (
        <div className={styles.loadingWrapper}>
            <SVGIcon
                src="/New UI/SVG/Scalenut.svg"
                size={36}
                className={styles.rotatingIcon}
            />
            <div style={{ paddingInline: 20 }}>
                Please wait as we identify topic gaps to enhance your article’s
                depth and coverage. This may take a moment.
            </div>
        </div>
    ) : showCompetitors ? (
        <div className={styles.competitorsWrapper}>
            <div className={styles.heading}>
                Select your competitors
                <LightTooltip arrow title={"Back"}>
                    <div
                        onClick={() => {
                            setShowCompetitors(false);
                            mixpanelTrack("Topic Gap", {
                                action: "Back",
                            });
                        }}
                        className={styles.back}
                    >
                        <FaArrowLeftLong size={12} color="#014dc5" />
                    </div>
                </LightTooltip>
            </div>
            <div className={styles.tableWrapper}>
                {competetion.map((data) => (
                    <>
                        <div className={styles.tableRow}>
                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                    }}
                                    className={styles.tableRow_rank}
                                >
                                    {" "}
                                    <Checkbox
                                        checked={competitorRanks?.includes(
                                            data.rank,
                                        )}
                                        onChange={(e) => {
                                            if (competitorRanks?.length >= 3) {
                                                if (
                                                    competitorRanks?.includes(
                                                        data.rank,
                                                    )
                                                ) {
                                                    setCompetitorRanks((ps) =>
                                                        ps?.filter(
                                                            (i) =>
                                                                i != data.rank,
                                                        ),
                                                    );
                                                    mixpanelTrack("Topic Gap", {
                                                        action: "Competitors Changed",
                                                    });
                                                } else {
                                                    dispatch(
                                                        displayAlert({
                                                            alertMessage:
                                                                "You can select up to 3 URLs",
                                                            alertType: "error",
                                                        }),
                                                    );
                                                    return;
                                                }
                                            } else {
                                                if (
                                                    competitorRanks?.includes(
                                                        data.rank,
                                                    ) &&
                                                    competitorRanks?.length > 1
                                                ) {
                                                    setCompetitorRanks((ps) =>
                                                        ps?.filter(
                                                            (i) =>
                                                                i != data.rank,
                                                        ),
                                                    );
                                                    mixpanelTrack("Topic Gap", {
                                                        action: "Competitors Changed",
                                                    });
                                                }
                                                if (e.target.checked) {
                                                    setCompetitorRanks((ps) => [
                                                        ...ps,
                                                        data.rank,
                                                    ]);
                                                    mixpanelTrack("Topic Gap", {
                                                        action: "Competitors Changed",
                                                    });
                                                }
                                            }
                                        }}
                                        size="small"
                                        classes={checkBoxClasses}
                                    />
                                </div>
                                <div className={styles.tableRow_competitor}>
                                    <LightTooltip title={data?.url_title} arrow>
                                        <p className={styles.mainDataTitle}>
                                            {data?.url_title?.length > 60
                                                ? data?.url_title?.slice(
                                                      0,
                                                      60,
                                                  ) + "..."
                                                : data?.url_title}
                                        </p>
                                    </LightTooltip>
                                    <LightTooltip arrow title={data.url}>
                                        <div
                                            className={
                                                styles.mainDataUrlContainer
                                            }
                                        >
                                            {data.favicon ? (
                                                <img
                                                    height={14}
                                                    width={14}
                                                    src={data.favicon}
                                                />
                                            ) : (
                                                <SVGIcon src="/New UI/SVG/globe.svg" />
                                            )}
                                            &nbsp; &nbsp;
                                            <a
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                className={styles.mainDataUrl}
                                                href={data.url}
                                                target="_blank"
                                            >
                                                {data.url}
                                            </a>
                                        </div>
                                    </LightTooltip>
                                </div>
                            </div>
                            <div className={styles.tableRow_words}>
                                <div className={styles.rankText}>
                                    #{data?.rank}
                                </div>
                                <div className={styles.wordCount}>
                                    {data.word_count} words
                                </div>
                                <div className={styles.rank}>
                                    {!(data.word_count < 50) ? (
                                        <HighLighter quality={data.grade} />
                                    ) : (
                                        "-"
                                    )}
                                </div>

                                <LightTooltip
                                    interactive
                                    arrow
                                    title={
                                        data?.h_tags?.h?.length > 0 ? (
                                            <div
                                                className={styles.compOutlines}
                                            >
                                                {data?.h_tags?.h?.map((ele) => (
                                                    <div>
                                                        {ele?.tag_name?.toUpperCase()}
                                                        : {ele?.text}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <div className={styles.eyeIcon}>
                                        <IoEyeOutline />
                                    </div>
                                </LightTooltip>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    width={180}
                    text={"Find Topic Gap"}
                    handler={() => {
                        findContentGaps();
                        mixpanelTrack("Topic Gap", {
                            action: "Find Topic Gap",
                        });
                    }}
                    style={{
                        marginTop: "20px",
                        fontSize: "14px",
                        fontWeight: 500,
                    }}
                />
            </div>
        </div>
    ) : contentGapData?.length ? (
        <div className={styles.wrapper}>
            <div className={styles.heading}>
                <div>
                    Missing Topics
                    <div className={styles.subText}>
                        The following topics are included in the content of the
                        top competitors but are missing from your content.
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {sessionStorage.getItem("newGap") == "true" && (
                        <div
                            style={{
                                display: "flex",
                                marginRight: "10px",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                onClick={() => {
                                    getContentGapData({ isRegenerate: true });
                                    mixpanelTrack("Topic Gap", {
                                        action: "Regenerate",
                                    });
                                }}
                                className={styles.competitorBtn}
                                style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                                <IoRefreshSharp /> Regenerate
                            </div>
                        </div>
                    )}
                    <LightTooltip arrow title={"Adjust Competitors"}>
                        <div
                            onClick={() => {
                                setShowCompetitors(true);
                                mixpanelTrack("Topic Gap", {
                                    action: "Settings Icon",
                                });
                            }}
                            className={styles.competitorBtn}
                        >
                            <IoSettingsOutline fontWeight={500} />
                        </div>
                    </LightTooltip>
                </div>
            </div>
            <div className={styles.subTopics}>
                {contentGapData.map((data, i) => (
                    <SubTopicRow
                        data={data}
                        i={i}
                        clickToAdd={clickToAdd}
                        reportInfo={reportInfo}
                        competetion={competetion}
                        addAll={addAll}
                        aiGenerate={aiGenerate}
                        setAiGenerate={setAiGenerate}
                    />
                ))}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                    onClick={() => {
                        insertAll();
                        mixpanelTrack("Topic Gap", {
                            action: "Insert All",
                        });
                    }}
                    className={styles.insertAll}
                >
                    <FaArrowLeftLong /> Insert All
                </div>
            </div>
            {!feedbackClose && !contentGapRating && (
                <UserFeedback
                    setClose={setFeedbackClose}
                    section={"contentGap"}
                    setReportData={setReportData}
                />
            )}
        </div>
    ) : (
        <div className={styles.loadingWrapper}>
            <div style={{ paddingInline: 20 }}>
                {errorMsg.msg}
                {errorMsg.outlineError && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            width={150}
                            Icon={() => <FaArrowRightLong />}
                            text="Article Writer"
                            handler={() => {
                                window.open(`/admin/creator`, "_blank");
                            }}
                        />
                    </div>
                )}
                {!errorMsg.outlineError &&
                    sessionStorage.getItem("newGap") == "true" && (
                        <div
                            style={{
                                display: "flex",
                                marginRight: "10px",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <div
                                onClick={() => {
                                    getContentGapData({ isRegenerate: true });
                                    mixpanelTrack("Topic Gap", {
                                        action: "Regenerate",
                                    });
                                }}
                                className={styles.competitorBtn}
                                style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                                <IoRefreshSharp /> Regenerate
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}

const SubTopicRow = ({
    data,
    i,
    clickToAdd,
    reportInfo,
    competetion,
    addAll,
    aiGenerate,
    setAiGenerate,
}) => {
    const [loadingGenerate, setLoadingGenerate] = useState(false);
    const [viewSource, setViewSource] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const { postData } = useHelperFunctions();
    const editorData = useContext(EditorContext);
    const { keyTerms } = useContext(ReportContext);
    const { mixpanelTrack } = useMixpanelHook();

    const getParagraph = (source) => {
        const para = competetion
            .filter((ele) => ele?.rank == source?.split(",", 2)[0]?.slice(6))[0]
            ?.h_tags?.h?.filter((heading) =>
                heading?.text?.includes(
                    source?.split(",", 2)[1]?.split(":", 2)[1]?.trim(),
                ),
            );
        if (para?.length) {
            return getPara(para[0]?.paragraph || "");
        }
    };

    const writeParagraphOnHeading = async (subTopic) => {
        document?.quill?.editor?.enable(false);
        setAiGenerate(true);
        setLoadingGenerate(true);

        const allHTags =
            document?.quill?.editor?.root?.querySelectorAll(
                "h2, h3, h4, h5, h6",
            );

        let outlineText = "";

        for (const heading of allHTags) {
            outlineText += `${heading?.tagName}: ${heading.innerText}` + "\n";
        }

        const res = await postData({
            url: "/api/generate",
            payload: {
                heading: subTopic,
                title:
                    document?.quill?.editor?.root?.getElementsByTagName("h1")[0]
                        ?.innerText || "",
                useCase: 296,
                keyword: reportInfo.title,
                keywords: keyTerms?.map((i) => i.keyword).join(","),
                suggestedWordCount: 80,
                outline: outlineText,
                report_id: reportInfo.report_id,
            },
        });
        let answer = `${res?.data?.output[0]?.content?.trim()}`;
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor + 1)
                .delete(0)
                .insert("\n", { header: 2 }),
        );
        document.quill.editor.cursor = document.quill.editor.cursor + 1;
        await editorData.writeWithAnimation(subTopic);
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor + 1)
                .delete(0)
                .insert("\n", { header: 0 }),
        );
        document.quill.editor.cursor = document.quill.editor.cursor + 1;
        if (res.status == 200) {
            await editorData.writeWithAnimation(answer);
        }
        document?.quill?.editor?.enable(true);
        setLoadingGenerate(false);
        setAiGenerate(false);
        setIsAdded(true);
    };
    return (
        <div className={styles.subTopicRowWrapper}>
            <div
                style={{
                    borderBottom: viewSource ? "1px solid #e1e4ed" : "none",
                    paddingBottom: viewSource ? "5px" : "",
                }}
                className={styles.subTopicRow}
            >
                <div className={styles.subTopicText}>
                    <LightTooltip arrow title={"Click to add"}>
                        <div
                            style={{
                                textDecoration:
                                    isAdded || addAll ? "line-through" : "none",
                            }}
                            onClick={() => {
                                clickToAdd(data.sub_topics);
                                setIsAdded(true);
                                mixpanelTrack("Topic Gap", {
                                    action: "Subtopic Inserted",
                                });
                            }}
                        >
                            {data.sub_topics}
                        </div>
                    </LightTooltip>
                </div>
                <div
                    onClick={() => {
                        setViewSource(!viewSource);
                        mixpanelTrack("Topic Gap", {
                            action: "Sources",
                        });
                    }}
                    className={styles.sourcesBtn}
                >
                    Sources{" "}
                    <HiChevronDown
                        style={{
                            transform: viewSource ? "rotate(180deg)" : "",
                        }}
                        size={12}
                    />
                </div>
                {loadingGenerate ? (
                    <div style={{ marginRight: "15px" }}>
                        <CircularProgress size={15} />
                    </div>
                ) : (
                    <LightTooltip
                        arrow
                        title={"Generate paragraph for this topic"}
                    >
                        <div
                            onClick={() => {
                                if (!aiGenerate) {
                                    writeParagraphOnHeading(data.sub_topics);
                                    mixpanelTrack("Topic Gap", {
                                        action: "Robo Icon",
                                    });
                                }
                            }}
                            style={{
                                cursor: aiGenerate ? "not-allowed" : "pointer",
                            }}
                        >
                            <SVGIcon
                                src="/New UI/SVG/Ai-icon-white.svg"
                                size={24}
                                className={styles.copyIcon}
                            />
                        </div>
                    </LightTooltip>
                )}
            </div>
            {viewSource &&
                JSON.parse(data?.sources || "")?.map((source) => (
                    <>
                        <div className={styles.sourcesWrapperParent}>
                            <div className={styles.sourcesWrapper}>
                                <div style={{ display: "flex" }}>
                                    <a
                                        target="_blank"
                                        href={
                                            competetion.filter(
                                                (ele) =>
                                                    ele?.rank ==
                                                    source
                                                        ?.split(",", 2)[0]
                                                        ?.slice(6),
                                            )[0]?.url
                                        }
                                        className={styles.sourceUrl}
                                        onClick={() => {
                                            mixpanelTrack("Topic Gap", {
                                                action: "Link",
                                            });
                                        }}
                                    >
                                        {
                                            competetion.filter(
                                                (ele) =>
                                                    ele?.rank ==
                                                    source
                                                        ?.split(",", 2)[0]
                                                        ?.slice(6),
                                            )[0]?.url
                                        }
                                    </a>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        color: "#414D5A",
                                        fontWeight: "400",
                                    }}
                                >
                                    <div>
                                        {source
                                            .split(",", 2)[1]
                                            ?.split(":")
                                            ?.join(" ")
                                            ?.slice(3)
                                            ?.trim()}
                                    </div>
                                    {getParagraph(source)?.length > 0 && (
                                        <LightTooltip
                                            interactive
                                            arrow
                                            title={
                                                <div
                                                    className={
                                                        styles.paragraphTooltip
                                                    }
                                                >
                                                    {getParagraph(source)}
                                                </div>
                                            }
                                        >
                                            <div
                                                className={styles.paragraphIcon}
                                            >
                                                <IoEyeOutline
                                                    size={13}
                                                    color="#014dc5"
                                                />
                                            </div>
                                        </LightTooltip>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ))}
        </div>
    );
};

export default ContentGap;
