import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
// @ts-ignore
import styles from "./styles.module.css";
import { MdClose } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BASE_URL, endPoints } from "./constants";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import { EmbedLinkInPara, clubPageWise, generateHash } from "./constants";
import { PiWarningLight } from "react-icons/pi";
import { FcGoogle } from "react-icons/fc";
import update from "immutability-helper";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    ClickAwayListener,
    debounce,
    makeStyles,
    withStyles,
} from "@material-ui/core";
import Button from "../../atoms/Button/Button";
import { Skeleton } from "@material-ui/lab";
import SVGIcon from "../../atoms/SVGIcon";
import GSCSimpleLogin from "../../organisms/GSCSimpleLogin/GSCSimpleLogin";
import SearchBar from "../../atoms/SearchBar/SearchBar";
import LocalPagination from "../../atoms/LocalPagination/LocalPagination";
import useHelperFunctions from "../../helperFunctions";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import mixpanel from "mixpanel-browser";
import { displayAlert } from "../../../store/actions/alert";
import { useDispatch } from "react-redux";
import useGscHook from "../GoogleSearchConsole/useGscHook";
import { extractDomain, removeProtocolsFromURL } from "../WebTune/constants";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";

export const FIX_SAVE_TYPE = {
    SAVE: "SAVE",
    UNSAVE: "UNSAVE",
};

export type PageInfo = {
    id: number;
    domain_id: number;
    page_hash: string;
    page_url: string;
    html_link: string;
    scraping_version: number;
    internal_outbound_count: number;
    internal_inbound_count: number;
    category: number;
    created_at: Date;
    updated_at: Date;
    inboundSuggestionsCount: number;
    outboundSuggestionsCount: number;
};

export type Suggestion = {
    id: number;
    domain_id: number;
    page_id: number;
    page_hash?: string;
    keyword: string;
    fix_id: number;
    paragraph_hash: string;
    paragraph_text: string;
    recommendation: string;
    created_at: Date;
    updated_at: Date;
    page_url: string;
    status: number;
    new_suggestion: number;
};

const CustomLightTooltip2 = withStyles((theme) => ({
    tooltip: {
        minWidth: 332,
        border: "1px solid #e6e6e6 !important",
        background: "white !important",
        marginTop: "4px !important",
    },
}))(LightTooltip);

export const CategoryBtn = ({
    sectionId,
    title,
    count = null,
    selected,
    setSelectedCat,
    catKey,
    isFilterActive,
    isLocked = false,
    setDisableAutoScroll = () => {},
    scanningPages = false,
    loadingWD = false,
    isReview = false,
    lmSuggestionModal = () => {},
}: {
    sectionId: any;
    title: any;
    count: any;
    selected: any;
    setSelectedCat: any;
    catKey: any;
    isFilterActive?: any;
    isLocked?: any;
    setDisableAutoScroll?: any;
    scanningPages?: any;
    loadingWD?: any;
    isReview?: any;
    lmSuggestionModal: any;
}) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
        setActive(selected);
    }, [selected]);

    return count === 0 && isReview ? (
        <></>
    ) : (
        <CustomLightTooltip2
            title={
                isLocked
                    ? "Upgrade to Paid plan to unlock AI generated Alt texts"
                    : ""
            }
            placement="bottom"
            arrow
        >
            <a
                href={`#${sectionId + 1}`}
                onClick={() => {
                    lmSuggestionModal({
                        ctaType: "Suggestion Modal",
                        ctaUsed: "Tab Clicked",
                        tabName: title || "-",
                        issueCount: count || "-",
                    });
                    setDisableAutoScroll(true);
                    setSelectedCat(catKey);
                    setTimeout(() => {
                        setDisableAutoScroll(false);
                    }, 900);
                }}
                className={`${styles.categoryBtn}  ${
                    active ? styles.active : ""
                }`}
            >
                {isLocked && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "5px",
                            marginTop: "-3px",
                        }}
                    >
                        <SVGIcon
                            src={"/svg/lock.svg"}
                            style={{ color: "#3F5575" }}
                        />
                    </div>
                )}
                {title}

                <span
                    style={{
                        display: count == null ? "none" : "flex",
                        background:
                            loadingWD || scanningPages ? "transparent" : "",
                    }}
                >
                    {loadingWD || scanningPages ? (
                        <Skeleton width={28} />
                    ) : (
                        count
                    )}
                </span>
            </a>
        </CustomLightTooltip2>
    );
};

function LinkingSuggestionModal({
    open,
    setOpen,
    getIssueCount,
}: {
    open: any;
    setOpen: any;
    getIssueCount: () => Promise<void>;
}) {
    const [selected, setSelected] = useState(0);
    const [search, setSearch] = useState("");
    const [SLoading, setSLoading] = useState(false);
    const [cats, setCats] = useState([
        { count: 0, title: "Outbound" },
        { count: 0, title: "Inbound" },
    ]);
    const { removeDomainFromURL } = useGscHook();
    const { lmSuggestionModal } = useLinkManagerMixpanel();
    const { postData } = useHelperFunctions();
    useEffect(() => {
        if (open) {
        } else {
            setTimeout(() => {
                setOpen(false);
                setSelected(0);
            }, 600);
        }
    }, [open]);

    const filterBySearch = (arr: any) => {
        if (!arr) return null;
        return arr.filter((item: any) => item.url.includes(search));
    };

    const handleSearchChange = useCallback(
        debounce((t) => {
            setSearch(t);
            // @ts-ignore
            lmSuggestionModal({
                ctaType: "Suggestion Modal",
                ctaUsed: "Search",
            });
        }, 300),
        [],
    );

    const {
        outboundSuggestionsByPage,
        inboundSuggestionsByPage,
        pageInfo,
        setSuggestions,
    } = useSuggestions(open);

    const _outboundSuggestionsByPage = filterBySearch(
        outboundSuggestionsByPage,
    );
    const _inboundSuggestionsByPage = filterBySearch(inboundSuggestionsByPage);

    const { selectedDomainInfo } = useContext(InterlinkingContext);

    useEffect(() => {
        if (pageInfo) {
            setCats((ps) => [
                { count: outboundSuggestionsByPage.length, title: "Outbound" },
                { count: inboundSuggestionsByPage.length, title: "Inbound" },
            ]);
            if (outboundSuggestionsByPage.length === 0) {
                setSelected(1);
            }
        }
    }, [pageInfo]);

    const handleFixSave = async (
        type = "",
        _fs: Array<Suggestion>,
        index: number,
    ) => {
        if (!type) {
            return null;
        }
        let tempSIds: Array<number> = [];
        _fs.map((sid: Suggestion) => {
            tempSIds.push(sid.id);
        });

        let payload: any = {
            action: type,
            isAll: 0,
            domain_id: selectedDomainInfo?.id || "-",
        };
        if (type == FIX_SAVE_TYPE.SAVE) {
            payload.page_hash = _fs.map((ps: Suggestion) => ps?.page_hash);
            payload.suggestionIds = [...tempSIds];
            payload.fix = _fs.map((ps: Suggestion) => ({
                keyword: ps.keyword,
                recommendation: ps.recommendation,
                paragraph_hash: ps.paragraph_hash,
            }));
            payload.meta_data = _fs.map((ps) => ({
                page_url: ps.page_url,
                paragraph: ps.paragraph_text,
            }));
        } else {
            payload.fix_id = _fs
                .filter((ps) => ps.fix_id >= 0)
                .map((ps) => ps.fix_id);
        }

        const res = await postData({
            completeUrl: BASE_URL + endPoints.updateFix,
            payload,
        });
        if (res.status == 200) {
            getIssueCount();
            let isOutboundCall = selected == 0;
            if (type == FIX_SAVE_TYPE.SAVE) {
                setSuggestions((ps) => {
                    let a = ps;

                    _fs.map((f: Suggestion, i: number) => {
                        let idx = ps[
                            isOutboundCall
                                ? "outboundSuggestionsByPage"
                                : "inboundSuggestionsByPage"
                        ][index]?.suggestions?.findIndex(
                            (ol: Suggestion) => ol?.id == f?.id,
                        );
                        if (idx >= 0) {
                            a = update(a, {
                                [isOutboundCall
                                    ? "outboundSuggestionsByPage"
                                    : "inboundSuggestionsByPage"]: {
                                    [index]: {
                                        suggestions: {
                                            [idx]: {
                                                fix_id: {
                                                    $set: res.data?.insertIds[
                                                        i
                                                    ],
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    });
                    return a;
                });
            } else {
                setSuggestions((ps) => {
                    let a = ps;

                    _fs.map((f: Suggestion, i: number) => {
                        let idx = ps[
                            isOutboundCall
                                ? "outboundSuggestionsByPage"
                                : "inboundSuggestionsByPage"
                        ][index]?.suggestions?.findIndex(
                            (ol: Suggestion) => ol?.id == f?.id,
                        );
                        if (idx >= 0) {
                            a = update(a, {
                                [isOutboundCall
                                    ? "outboundSuggestionsByPage"
                                    : "inboundSuggestionsByPage"]: {
                                    [index]: {
                                        suggestions: {
                                            [idx]: {
                                                fix_id: {
                                                    $set: -1,
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    });
                    return a;
                });
            }
        }
    };

    const openInNewTab = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClick = (event) => {
        event.preventDefault(); // Prevent the default behavior of the link
        // @ts-ignore
        lmSuggestionModal({
            ctaType: "Suggestion Modal",
            ctaUsed: "URL Clicked",
        });
        openInNewTab(pageInfo.page_url); // Open the URL in a new tab
    };

    if (!open) return null;

    return (
        <div className={styles.historyContainerWrapper}>
            <ClickAwayListener
                onClickAway={() => {
                    setOpen(false);
                }}
            >
                <div
                    className={`${styles.historyContainer} ${
                        !open ? styles.historyContainerClose : ""
                    }`}
                >
                    <MdClose
                        className={styles.close}
                        onClick={() => {
                            // @ts-ignore
                            lmSuggestionModal({
                                ctaType: "Suggestion Modal",
                                ctaUsed: "Close Modal",
                            });
                            setOpen(false);
                        }}
                    />
                    <header>
                        <h2>Internal Linking Opportunities</h2>
                    </header>

                    <div
                        className={styles.filters}
                        style={{
                            paddingTop: "0px",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            {cats.map((cat, index) => (
                                <CategoryBtn
                                    sectionId={index}
                                    title={cat.title}
                                    count={cat.count as any}
                                    selected={index === selected}
                                    catKey={index}
                                    setSelectedCat={setSelected}
                                    isFilterActive={() => {}}
                                    setDisableAutoScroll={() => {}}
                                    scanningPages={!pageInfo}
                                    // @ts-ignore
                                    lmSuggestionModal={lmSuggestionModal}
                                />
                            ))}
                        </div>

                        <SearchBar
                            onChange={(e: any) => {
                                handleSearchChange(e.target.value);
                            }}
                            minimal={true}
                            loading={false}
                        />
                    </div>

                    {pageInfo ? (
                        <div className={styles.linkingWay}>
                            {selected == 0 ? (
                                <>
                                    <SVGIcon
                                        src={"/New UI/SVG/info.svg"}
                                        size={14}
                                    />
                                    Link anchor text content from
                                    <a
                                        className={styles.path}
                                        href={pageInfo.page_url}
                                        target="_blank"
                                        style={{ color: "#6b2bd9e6" }}
                                        onClick={handleClick}
                                    >
                                        {removeProtocolsFromURL(
                                            pageInfo.page_url,
                                        )}
                                    </a>
                                    to target URL
                                </>
                            ) : (
                                <>
                                    <SVGIcon
                                        src={"/New UI/SVG/info.svg"}
                                        size={14}
                                    />
                                    Link anchor text content from source URL to
                                    <a
                                        className={styles.path}
                                        href={pageInfo.page_url}
                                        target="_blank"
                                        style={{ color: "#6b2bd9e6" }}
                                        onClick={handleClick}
                                    >
                                        {removeProtocolsFromURL(
                                            pageInfo.page_url,
                                        )}
                                    </a>
                                </>
                            )}
                            {/* {selected == 1 && (
                                <>
                                    <SVGIcon
                                        size={20}
                                        src="/New UI/SVG/link.svg"
                                        style={{ color: "#7188AA" }}
                                    />
                                    <div
                                        style={{
                                            backgroundColor:
                                                "rgba(241, 241, 245, 1)",
                                            borderRadius: 8,
                                            padding: "2.5px 10px",
                                        }}
                                    >
                                        Any Inbound link
                                    </div>
                                    <SVGIcon
                                        style={{ height: 30 }}
                                        src="/New UI/SVG/linkArrow.svg"
                                    />
                                </>
                            )} */}
                        </div>
                    ) : (
                        ""
                    )}

                    <div
                        className={styles.oprTableContainer}
                        style={
                            !selectedDomainInfo?.gsc_id
                                ? { overflow: "hidden" }
                                : {}
                        }
                    >
                        {!selectedDomainInfo?.gsc_id && <ConnectGscPopup />}

                        <table className={styles.oprTable}>
                            <thead>
                                <tr>
                                    <th>
                                        {selected == 0
                                            ? "Target URL"
                                            : "Source URL"}
                                    </th>
                                    <th>Anchor Text Content (in source URL)</th>
                                    <th></th>
                                </tr>
                            </thead>

                            {pageInfo ? (
                                <tbody>
                                    {(selected === 0
                                        ? _outboundSuggestionsByPage
                                        : _inboundSuggestionsByPage
                                    ).length ? (
                                        (selected === 0
                                            ? _outboundSuggestionsByPage
                                            : _inboundSuggestionsByPage
                                        )
                                            .sort(
                                                (a: any, b: any) =>
                                                    b.suggestions[0]
                                                        .new_suggestion -
                                                    a.suggestions[0]
                                                        .new_suggestion,
                                            )
                                            .map(
                                                (
                                                    suggestion: {
                                                        url: string;
                                                        suggestions: Suggestion[];
                                                        fix_id?:
                                                            | number
                                                            | null
                                                            | undefined;
                                                        id: any;
                                                    },
                                                    i: number,
                                                ) => (
                                                    <OprTableRow
                                                        suggestion={suggestion}
                                                        handleFixSave={
                                                            handleFixSave
                                                        }
                                                        index={i}
                                                        key={suggestion.id}
                                                    />
                                                ),
                                            )
                                    ) : (
                                        <div className={styles.noResult}>
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/webtuneLogsEmpty.svg"
                                                }
                                                style={{ margin: "auto" }}
                                            />
                                            No Suggestions Found
                                        </div>
                                    )}
                                </tbody>
                            ) : (
                                <OprTableRowsLoading />
                            )}
                        </table>
                    </div>
                    {/* <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "10px",
                        }}
                    >
                        <LocalPagination
                            pageCount={10}
                            currentPage={1}
                            onPageChange={() => {}}
                        />
                    </div>  */}
                </div>
            </ClickAwayListener>
        </div>
    );
}

const useAccordionCompleteStyle = makeStyles(() => {
    return {
        root: {
            backgroundColor: "none",
            boxShadow: "none",
            padding: 0,
            margin: "0 !important",
            "& .MuiAccordionSummary-root": {
                padding: "0px",
                minHeight: 0,
                border: "none",
            },
            "& .MuiAccordionSummary-root:first-child": {
                // borderTop: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: "0",
                alignItems: "center",
            },
            "& .MuiAccordionDetails-root": {
                padding: "0",
                flexDirection: "column",
                backgroundColor: "transparent",
            },
            "&:before": { content: "none" },
        },
    };
});

function OprTableRow({
    suggestion,
    handleFixSave,
    index,
}: {
    suggestion: {
        url: string;
        suggestions: Array<Suggestion>;
        fix_id?: number | null;
    };
    handleFixSave: (
        type: string,
        _fxs: Suggestion[] | [],
        index: number,
    ) => Promise<any>;
    index: number;
}) {
    const [finalSuggestions, setFinalSuggestions] = useState<Array<Suggestion>>(
        [],
    );
    const { lmSuggestionModal } = useLinkManagerMixpanel();
    const [isFixed, setIsFixed] = useState(false);
    const [loading, setLoading] = useState(false);
    const { removeDomainFromURL } = useGscHook();
    useEffect(() => {
        if (!!suggestion.suggestions.length) {
            setIsFixed(!!suggestion.suggestions.find((sg) => sg.fix_id >= 0));
        }
    }, [suggestion]);

    return (
        <tr>
            <td style={{ display: "flex", alignItems: "baseline" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        justifyContent: "flex-start",
                        marginTop: "40px",
                    }}
                >
                    <a
                        href={suggestion.url}
                        style={{
                            color: "rgb(1, 77, 197)",
                            fontWeight: 400,
                            background: "rgba(1,77,197,0.1)",
                            width: "max-content",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            maxWidth: 250,
                            fontSize: 13,
                            marginTop: "0px",
                        }}
                        onClick={(e) => {
                            // @ts-ignore
                            lmSuggestionModal({
                                ctaType: "Suggestion Modal",
                                ctaUsed: "URL Clicked",
                            });
                        }}
                        target="_blank"
                    >
                        {removeDomainFromURL(suggestion.url)}
                    </a>
                    {suggestion.suggestions[0].new_suggestion === 1 && (
                        <span
                            style={{
                                marginLeft: "5px",
                                borderRadius: "3px",
                                backgroundColor: "rgb(31, 76, 190)",
                                color: "white",
                                fontSize: "8px",
                                padding: "3px 5px",
                            }}
                        >
                            New
                        </span>
                    )}
                </div>
            </td>
            <td width={"50%"}>
                <ParagraphGroup
                    isFixed={isFixed}
                    suggestion={suggestion}
                    finalSuggestions={finalSuggestions}
                    setFinalSuggestions={setFinalSuggestions}
                    lmSuggestionModal={lmSuggestionModal}
                />
            </td>
            <td>
                {!suggestion.suggestions.filter((i) => i.status === 1)
                    .length ? (
                    <Button
                        text={isFixed ? "Approved" : "Approve"}
                        Icon={IoCheckmarkCircleOutline}
                        disabled={!finalSuggestions?.length}
                        style={
                            isFixed
                                ? {
                                      color: "#1dc198",
                                      backgroundColor:
                                          "rgba(29, 193, 152, 0.1)",
                                      fontSize: 14,
                                  }
                                : {
                                      color: "#7188aa",
                                      backgroundColor: "#f1f3f6",
                                      fontSize: 14,
                                      border: "1px solid rgb(233, 236, 244)",
                                  }
                        }
                        handler={async () => {
                            setLoading(true);
                            // @ts-ignore
                            lmSuggestionModal({
                                ctaType: "Suggestion Modal",
                                ctaUsed: isFixed ? "Approved" : "Approve",
                                // @ts-ignore
                                approveCount: finalSuggestions?.length || "-",
                            });
                            await handleFixSave(
                                isFixed
                                    ? FIX_SAVE_TYPE.UNSAVE
                                    : FIX_SAVE_TYPE.SAVE,
                                finalSuggestions,
                                index,
                            );
                            setLoading(false);
                        }}
                        width={120}
                        fontSize={12}
                        loading={loading}
                    />
                ) : (
                    <Button
                        width={120}
                        height={36}
                        fontSize={14}
                        text="Published"
                        style={{
                            backgroundColor: "white",
                            color: "#1f4cbe",
                        }}
                    ></Button>
                )}
            </td>
        </tr>
    );
}

export function ParagraphGroup({
    suggestion,
    isFixed,
    finalSuggestions,
    setFinalSuggestions,
    lmSuggestionModal = () => {},
}: {
    suggestion: {
        url: string;
        suggestions: Suggestion[];
        fix_id?: number | null | undefined;
    };
    isFixed: boolean;
    finalSuggestions: Suggestion[];
    lmSuggestionModal: any;
    setFinalSuggestions: Dispatch<SetStateAction<Suggestion[]>>;
}) {
    const [expand, setExpand] = useState(false);
    const classes = useAccordionCompleteStyle();

    const handleChecks: (
        e: ChangeEvent<HTMLInputElement>,
        sgg: Suggestion,
    ) => void = (e, sgg) => {
        if (finalSuggestions.length == 0) {
            setFinalSuggestions([sgg]);
        } else {
            let idx = finalSuggestions.findIndex(
                (fs: Suggestion) => fs.id == sgg.id,
            );
            if (idx >= 0) {
                setFinalSuggestions((ps: Suggestion[]) => {
                    let temp = [...ps];
                    temp.splice(idx, 1);
                    return temp;
                });
            } else {
                setFinalSuggestions((ps: Suggestion[]) => [...ps, sgg]);
            }
        }
    };

    useEffect(() => {
        let temp = suggestion.suggestions.filter(
            (ps: Suggestion) => ps.fix_id >= 1,
        );

        if (!temp.length) {
            setFinalSuggestions([suggestion.suggestions[0]]);
        } else {
            setFinalSuggestions((ps: Suggestion[]) => {
                return temp;
            });
        }
    }, [isFixed]);

    const isInFinalSuggestions: (_id: number) => boolean = (_id) => {
        if (finalSuggestions.length == 0) {
            return false;
        }
        let idx = finalSuggestions.findIndex((fs: Suggestion) => fs.id == _id);
        if (idx >= 0) {
            return true;
        } else {
            return false;
        }
    };

    const suggestions = suggestion.suggestions;

    return (
        <>
            {suggestions.length > 1 && (
                <small>
                    {finalSuggestions.length} paragraph
                    {finalSuggestions.length > 1 && "s"} selected
                </small>
            )}
            {suggestions.slice(0, 1).map((suggestion: Suggestion) => (
                <div
                    style={{
                        marginTop: 5,
                        borderTop: "1px solid rgba(224, 231, 237, 1)",
                    }}
                    className={styles.para}
                >
                    <Checkbox
                        style={{ height: 24, width: 24 }}
                        size="small"
                        onChange={(e) => {
                            // @ts-ignore
                            lmSuggestionModal({
                                ctaType: "Suggestion Modal",
                                ctaUsed: isInFinalSuggestions(suggestion.id)
                                    ? "Unchecked"
                                    : "Checked",
                                approveCount: finalSuggestions?.length || "-",
                            });
                            handleChecks(e, suggestion);
                        }}
                        value={suggestion.id}
                        checked={isInFinalSuggestions(suggestion.id)}
                        disabled={isFixed}
                    />
                    <Paragraph key={suggestion.id} suggestion={suggestion} />
                </div>
            ))}

            {suggestions.length > 1 ? (
                <>
                    <Accordion
                        expanded={expand}
                        onChange={(_, e) => setExpand(e)}
                        classes={classes}
                    >
                        <AccordionSummary>
                            <Button
                                text={
                                    expand
                                        ? "hide"
                                        : `+${
                                              suggestions.length - 1
                                          } more suggestions`
                                }
                                Icon={expand ? FiChevronUp : FiChevronDown}
                                style={{
                                    backgroundColor: "white",
                                    color: "rgba(1, 77, 197, 1)",
                                    fontSize: 13,
                                    width: "max-content",
                                    height: 20,
                                    marginTop: 5,
                                }}
                                handler={() => {
                                    // @ts-ignore
                                    lmSuggestionModal({
                                        ctaType: "Suggestion Modal",
                                        ctaUsed: "View More Suggestion",
                                        suggestionCount:
                                            suggestions?.length || "-",
                                    });
                                    setExpand(!expand);
                                }}
                            ></Button>
                        </AccordionSummary>
                        {expand && (
                            <AccordionDetails>
                                {suggestions
                                    .slice(1)
                                    .map((_suggestion: Suggestion) => (
                                        <div className={styles.para}>
                                            <Checkbox
                                                style={{
                                                    height: 24,
                                                    width: 24,
                                                }}
                                                size="small"
                                                onChange={(e) => {
                                                    handleChecks(
                                                        e,
                                                        _suggestion,
                                                    );
                                                }}
                                                checked={isInFinalSuggestions(
                                                    _suggestion.id,
                                                )}
                                                disabled={isFixed}
                                            />
                                            <Paragraph
                                                suggestion={_suggestion}
                                                key={_suggestion.id}
                                            />
                                        </div>
                                    ))}
                            </AccordionDetails>
                        )}
                    </Accordion>
                </>
            ) : (
                ""
            )}
        </>
    );
}

function useSuggestions(page_id: number) {
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const { postData } = useHelperFunctions();

    const [suggestions, setSuggestions] = useState({
        outboundSuggestionsByPage: null,
        inboundSuggestionsByPage: null,
        pageInfo: null,
    });

    useEffect(() => {
        if (page_id && selectedDomainInfo?.gsc_id)
            (async () => {
                const res = await postData({
                    completeUrl: BASE_URL + endPoints.interlinkSuggestions,
                    payload: { domain_id: selectedDomainInfo?.id, page_id },
                });

                if (res.status == 200) {
                    res.data.outboundSuggestionsByPage = await generateHash(
                        res.data.outboundSuggestionsByPage,
                    );
                    res.data.inboundSuggestionsByPage = await generateHash(
                        res.data.inboundSuggestionsByPage,
                    );
                    setSuggestions(clubPageWise(res.data) as any);
                }
            })();
    }, [page_id]);

    return { ...suggestions, setSuggestions } as unknown as {
        inboundSuggestionsByPage: Array<{
            url: string;
            suggestions: Array<Suggestion>;
        }>;
        outboundSuggestionsByPage: Array<{
            url: string;
            suggestions: Array<Suggestion>;
        }>;
        pageInfo: PageInfo;
        setSuggestions: (
            value: SetStateAction<{
                outboundSuggestionsByPage: any;
                inboundSuggestionsByPage: any;
                pageInfo: any;
            }>,
        ) => void;
    };
}

function OprTableRowsLoading() {
    return new Array(20).fill(1).map(() => (
        <tr>
            <td>
                <Skeleton width={150} style={{ marginTop: 30 }} />
            </td>
            <td width={"50%"}>
                <Skeleton height={80} />
            </td>
            <td>
                <Skeleton height={36} width={120} style={{ marginTop: 20 }} />
            </td>
        </tr>
    ));
}

export function Paragraph({ suggestion }: { suggestion: Suggestion }) {
    const ref = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (ref.current) {
            EmbedLinkInPara(
                ref.current,
                suggestion.keyword,
                suggestion.recommendation,
            );
        }
    }, [ref]);

    return <p ref={ref}>{suggestion.paragraph_text}</p>;
}

function ConnectGscPopup() {
    const { setState, selectedDomainInfo } = useContext(InterlinkingContext);
    const [googleLoading, setGoogleLoading] = useState(false);
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const googleCallback: (response: any) => void = async (response) => {
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    ...(selectedDomainInfo?.id && {
                        domain_id: selectedDomainInfo?.id,
                    }),
                },
            });
            if (res.status == 200) {
                let gscId = res.data?.resp?.userInfo.gsc_id;
                setState((ps: any) => {
                    const _domainList = ps.domainsList.map((domain: any) => {
                        if (domain?.id === selectedDomainInfo?.id) {
                            return {
                                ...domain,
                                gsc_id: gscId,
                            };
                        }
                        return domain;
                    });
                    const _selectedDomainInfo = {
                        ...ps.selectedDomainInfo,
                        gsc_id: gscId,
                    };
                    return {
                        ...ps,
                        selectedDomainInfo: _selectedDomainInfo,
                        domainsList: _domainList,
                    };
                });
                setGoogleLoading(false);
            } else {
                setGoogleLoading(false);

                dispatch(
                    displayAlert({
                        alertType: "error",
                        alertMessage: `${res?.data?.message}`,
                    }),
                );
            }
        }
        setGoogleLoading(false);
    };

    return (
        <div className={styles.connectGscPopupContainer}>
            <div className={styles.connectGscPopup}>
                <PiWarningLight color="rgba(252, 90, 90, 1)" size={30} />
                Connect your Google Search <br /> Console to get your linking
                opportunity.
                <GSCSimpleLogin
                    btnText={"Connect GSC"}
                    height={36}
                    width={"150px"}
                    googleCallback={googleCallback}
                    loading={googleLoading}
                    setLoading={setGoogleLoading}
                />
            </div>
        </div>
    );
}

export default LinkingSuggestionModal;
