import { Skeleton } from "@material-ui/lab";
import React from "react";
import { VscCircle } from "react-icons/vsc";
import styles from "./GenerateHistory.module.css";

function HistoryLoading() {
    return (
        <div style={{ height: "100%" }}>
            <h6 className={styles.timeAgo}>
                <Skeleton width={80} />
            </h6>
            <div className={styles.card1}>
                <VscCircle color="#597ef7" className={styles.cardCircle} />

                <Skeleton height={60} className={styles.cardDummy} />
            </div>
            <div className={styles.card1}>
                <VscCircle color="#597ef7" className={styles.cardCircle} />

                <Skeleton height={60} className={styles.cardDummy} />
            </div>
            <div className={styles.card1}>
                <VscCircle color="#597ef7" className={styles.cardCircle} />

                <Skeleton height={60} className={styles.cardDummy} />
            </div>
            <div className={styles.card1}>
                <VscCircle color="#597ef7" className={styles.cardCircle} />

                <Skeleton height={60} className={styles.cardDummy} />
            </div>
        </div>
    );
}

export default HistoryLoading;
