import React, { useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import useHelperFunctions from "../../helperFunctions";
import styles from "./styles.module.css";
import { CREATE_PAYMENT, UPDATE_PLAN, OTHER_INFO } from "../../api.json";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { displayAlert } from "../../../store/actions/alert";
import { updateOtherInfo } from "../../../store/actions/otherInfo";
import { socket } from "../../../Sockets/useSockets";
import { socketEvents } from "../../../Sockets/constants";
let StripeInvoicePayment = "";
function getPrice(priceInfo) {
    let price = "";

    price = priceInfo.currency == "INR" ? "₹" : "$";
    price = price + (priceInfo.plan_cost || "---");
    return price;
}

function InviteLimit(props) {
    const ref = useRef();
    const subscriptionInfo = useSelector(
        (store) => store.otherInfo?.subscriptionInfo,
    );

    const otherInfo = useSelector((store) => store.otherInfo);
    const userId = useSelector((state) => state.auth.userId);
    const history = useHistory();
    const { postData, getData } = useHelperFunctions();
    const goTo = useNavigation();
    const [planInfo, setPlanInfo] = useState({ plan_id: "" });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const socketRequest = async () => {
        setLoading(true);

        return new Promise((res, rej) => {
            socket.on(socketEvents.USER_FIRST_SUBSCRIPTION, (data) => {
                res();
                StripeInvoicePayment?.close();
                dispatch(
                    updateOtherInfo({
                        ...otherInfo,
                        subscriptionInfo: {
                            ...subscriptionInfo,
                            total_seats: subscriptionInfo.total_seats + 1,
                            purchased_seat_count:
                                subscriptionInfo.purchased_seat_count + 1,
                        },
                    }),
                );
                props.setInviteModal(true);
            });
        });
    };
    const buySeat = async () => {
        setLoading(true);
        let res;

        if (subscriptionInfo.purchased_seat_count == 0) {
            res = await postData({
                url: CREATE_PAYMENT,
                payload: {
                    type: 2,
                    planID: planInfo.plan_id,
                },
            });
        } else {
            res = await postData({
                url: UPDATE_PLAN,
                payload: {
                    from: planInfo.plan_id,
                    planID: planInfo.plan_id,
                    seat_type: 1,
                    quantity: 1,
                },
            });
        }

        StripeInvoicePayment = window.open(
            "",
            "",
            `left=${window.outerWidth / 2 - 300},top=${
                window.outerHeight / 2 - 300
            },width=500,height=500`,
        );

        if (res.status == 200) {
            props.setInviteLimitErr(false);
            StripeInvoicePayment?.close();

            dispatch(
                updateOtherInfo({
                    ...otherInfo,
                    subscriptionInfo: {
                        ...subscriptionInfo,
                        total_seats: subscriptionInfo.total_seats + 1,
                        purchased_seat_count:
                            subscriptionInfo.purchased_seat_count + 1,
                    },
                }),
            );
            props.setInviteModal(true);
        } else if (res.status == 201) {
            StripeInvoicePayment.location = res.data.invoice.hosted_invoice_url;

            await socketRequest();
        }

        setLoading(false);
    };

    useEffect(() => {
        if (props.inviteLimitErr == "buySeat") {
            const getPlan = async () => {
                const res = await getData({
                    url: "/api/pricing/getMemberPlans",
                });

                setPlanInfo(
                    res.data.memberSubsPlan.filter(
                        (item) => item.plan_type == subscriptionInfo.type,
                    )[0],
                );
            };

            getPlan();
        }
    }, [props.inviteLimitErr]);

    const data = {
        trial: {
            text: `You are using a trial version of our product.
      Purchase a subscription plan to add team
      members.`,
            button: (
                <Button
                    text="Buy Plan"
                    height={40}
                    width={140}
                    handler={() => {
                        history.push("plans");
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                    }}
                ></Button>
            ),
            handler: () => {
                history.push("plans");
            },
        },
        expired: {
            text: `Your plan is expired.
      Purchase a subscription plan to add team
      members.`,
            button: (
                <Button
                    text="Buy Plan"
                    height={40}
                    width={140}
                    handler={() => {
                        history.push("plans");
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                    }}
                ></Button>
            ),
            handler: () => {
                history.push("plans");
            },
        },
        upgrade: {
            text: `Additional Team Members are available only on Pro plan. Kindly update plan to add new team members.`,
            button: (
                <Button
                    text="Upgrade Plan"
                    height={40}
                    width={140}
                    handler={() => {
                        history.push("plans");
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                    }}
                ></Button>
            ),
            handler: () => {
                history.push("plans");
            },
        },
        buySeats: {
            text: `Quota exhausted for adding new team members in
      your current billing plan. Contact our support team
      to purchase new seats for your account.`,
            button: (
                <Button
                    text="Contact Support"
                    height={40}
                    width={140}
                    handler={() => {
                        window.userengage && window.userengage("widget.show");
                        window.userengage && window.userengage("widget.open");
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                    }}
                ></Button>
            ),
            handler: () => {
                window.userengage && window.userengage("widget.show");
                window.userengage && window.userengage("widget.open");
            },
        },
        downgrade: {
            text: `Number of user seats exceeds the quota of the
      chosen plan. You need to remove team members in
      your account to continue.`,
            button: (
                <Button
                    text="Go to Account Settings"
                    height={40}
                    width={220}
                    handler={() => {
                        history.push("userProfile");
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                    }}
                ></Button>
            ),
            handler: () => {
                history.push("userProfile");
            },
        },
        buySeat: {
            text: (
                <>
                    Alert! Adding a new member will result in <br />
                    <b>
                        {getPrice(planInfo)} /
                        {subscriptionInfo.type == 1
                            ? " month"
                            : subscriptionInfo.type == 4
                            ? " 6 months"
                            : " year"}
                    </b>{" "}
                    additional charges. Proceed to confirm
                </>
            ),
            button: [
                <Button
                    text="Cancel"
                    height={40}
                    width={180}
                    handler={() => {
                        props.setInviteLimitErr(false);
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#e8e8e8",
                        color: "#171725",
                    }}
                ></Button>,
                <Button
                    text="Proceed"
                    height={40}
                    width={180}
                    handler={() => {
                        buySeat();
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                    }}
                    loading={loading}
                ></Button>,
            ],
            handler: () => {
                history.push("userProfile");
            },
        },
    };

    useEffect(() => {
        const handler = (e) => {
            if (e.key == "Enter") data[props.inviteLimitErr].handler();
        };
        document.addEventListener("keyup", handler);
        return () => document.removeEventListener("keyup", handler);
    }, []);

    return (
        <div className={styles.inviteLimitModal}>
            {/* <CgClose
        className={styles.cross}
        onClick={() => props.setInviteLimitErr(false)}
      /> */}
            <CloseButton setClose={props.setInviteLimitErr} />
            <div className={styles.inviteLimitContent}>
                <img src="/AssetsV2/quota-exceeded-icons.svg" alt="" />

                <p>{data[props.inviteLimitErr].text}</p>
            </div>

            <div className={styles.inviteLimitButtons}>
                {data[props.inviteLimitErr].button}
            </div>
        </div>
    );
}

export default InviteLimit;
