import {
  RESET_SUCCESS,
  RESET_FAIL,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
  CLEAR_MESSAGE,
  PROGRESS,
} from "../actiontypes";

const initialState = {
  message_success: "",
  message_error: "",
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_SUCCESS:
      return {
        ...state,
        message_success: "You Password has been reset successfully.",
        loading: false,
      };

    case RESET_FAIL:
      return {
        ...state,
        message_fail: "Your link is Expired. Kindly Request Again.",
        loading: false,
      };

    case FORGOT_SUCCESS:
      return {
        ...state,
        message_success: "A reset link has been sent to Your Mail.",
        loading: false,
      };

    case FORGOT_FAIL:
      return {
        ...state,
        message_fail: "User not Registered with Us.",
        loading: false,
      };

    case CLEAR_MESSAGE:
      return {
        ...initialState,
      };

    case PROGRESS: {
      return {
        ...state,
        loading: true,
      };
    }

    default:
      return state;
  }
}
