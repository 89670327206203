import { WEBTUNE_DOMAIN } from "../../../utility/config";
import { PageInfo, Suggestion } from "./LinkingSuggestionModal";
import { v4 } from "uuid";
export const BASE_URL = WEBTUNE_DOMAIN;

export const endPoints = {
    addDomain: `/api/interlinking/login`,
    userDomains: `/api/interlinking/get-domain-list`,
    interlinkMetrics: "/api/interlinking/get-interlinking-info",
    interlinkProcess: "/api/interlinking/processing",
    interlinkPages: "/api/interlinking/get-interlinking-pages",
    interlinkSuggestions: "/api/interlinking/get-page-suggestions",
    updateGsc: "/api/analyse/logoutGsc", // to remove only GSC
    updateFix: "/api/interlinking/update-fix",
    publishInterlinking: "/api/interlinking/publish",
    reviewChanges: "/api/interlinking/review-changes",
    logout: `/api/interlinking/logout`, // to remove domains and GSC if required
    getLogs: "/api/interlinking/get-logs",
    // to get all connected Domains
    quickConnectList: "/api/analyse/quick-connection-list",
    verify: `/api/interlinking/verify-domain`,
    issuesCount: "/api/interlinking/issues-count",
    feedbackLM: "/api/interlinking/feedback",
};

export const INTERLINKING_DOMAIN_STATUS = {
    NOT_STARTED: null,
    IN_PROGRESS: 1,
    SUCCESS: 2,
    FAILED: 3,
};

export const WEBTUNE_DOMAIN_STATUS = {
    NOT_STARTED: null,
    IN_PROGRESS: 3,
    SUCCESS: 4,
};

export const PAGE_CATEGORY = {
    ACTION_REQUIRED: 1,
    POTENTIAL_OPPORTUNITY: 2,
    ALL_GOOD: 3,
};

export const TRAIL_INTERLINKING_LIMIT = 100;

export const ROWS_PER_PAGE = 30;

export const PAGES_TYPE = { all: 1, orphan: 2 };

export const clubPageWise = (data: {
    inboundSuggestionsByPage: Array<Suggestion>;
    outboundSuggestionsByPage: Array<Suggestion>;
    pageInfo: PageInfo;
}) => {
    const groupedData = Object.values(
        data.outboundSuggestionsByPage.reduce((acc: any, obj) => {
            const recommendation = obj.recommendation;
            if (!acc[recommendation]) {
                acc[recommendation] = { url: recommendation, suggestions: [] };
            }
            acc[recommendation].suggestions.push(obj);
            return acc;
        }, {}),
    );

    const groupedData2 = Object.values(
        data.inboundSuggestionsByPage.reduce((acc: any, obj) => {
            const id = obj.page_id;
            if (!acc[id]) {
                acc[id] = { url: obj.page_url, suggestions: [], id: v4() };
            }
            acc[id].suggestions.push(obj);
            return acc;
        }, {}),
    );

    console.log({
        inboundSuggestionsByPage: groupedData2,
        outboundSuggestionsByPage: groupedData,
        pageInfo: {
            ...data.pageInfo,
            inboundSuggestionsCount: data.inboundSuggestionsByPage.length,
            outboundSuggestionsCount: data.outboundSuggestionsByPage.length,
        },
    });

    return {
        inboundSuggestionsByPage: groupedData2,
        outboundSuggestionsByPage: groupedData,
        pageInfo: {
            ...data.pageInfo,
            inboundSuggestionsCount: data.inboundSuggestionsByPage.length,
            outboundSuggestionsCount: data.outboundSuggestionsByPage.length,
        },
    };
};

export const clubAllPagesWise = (data: Array<Suggestion>) => {
    const groupedData: any = {};
    data.forEach((item) => {
        const key = `${item.page_url}-${item.recommendation}`;

        if (!groupedData[key]) {
            groupedData[key] = {
                url: item.page_url,
                recommendation: item.recommendation,
                suggestions: [],
                id: v4(),
            };
        }
        groupedData[key].suggestions.push(item);
    });
    return Object.values(groupedData);
};

export const clubLogsPagesWise = (data: any) => {
    const mergedFixes: any = {};
    data.forEach((fix: any) => {
        const key =
            fix.fix.keyword +
            "_" +
            fix.meta_data.page_url +
            "_" +
            Math.floor(+new Date(fix.created_at) / 10000);
        if (!mergedFixes[key]) {
            mergedFixes[key] = {
                ...fix,
                paras: [{ para: fix.meta_data.paragraph, fix_id: fix.id }],
            };
        } else {
            mergedFixes[key].paras.push({
                para: fix.meta_data.paragraph,
                fix_id: fix.id,
            });
        }
    });

    // Convert mergedFixes object back to an array
    return Object.values(mergedFixes);
};

export async function generateHash(arr: Array<Suggestion>) {
    for (let index = 0; index < arr.length; index++) {
        const suggestion = arr[index];
        const encoder = new TextEncoder();
        const data = encoder.encode(suggestion.paragraph_text);
        const hashBuffer = await window.crypto.subtle.digest("SHA-1", data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16)).join("");
        suggestion.paragraph_hash = hashHex;
    }

    return arr;
}

export function EmbedLinkInPara(p: any, keyword: string, link: string) {
    const linkURL = link;
    const regex = new RegExp("\\b(" + keyword + ")\\b", "gi");

    var allTextNodes = getAllTextNodes(p as any);
    try {
        allTextNodes.forEach((node: any) => {
            const newNodes = [] as Array<Node>;
            const str = node.nodeValue;
            let i = 0;
            let match;
            while ((match = regex.exec(str)) !== null) {
                newNodes.push(createNewTextNode(str.slice(i, match.index)));
                newNodes.push(createNewLinkNode(match[0], linkURL));
                i = match.index + match[0].length;
                break;
            }
            if (i > 0) {
                newNodes.push(createNewTextNode(str.slice(i)));
                insertNewNodes(newNodes, node);
                throw "Found";
            }
        });
    } catch (error) {
        console.log(error);
    }
}

function insertNewNodes(newNodes: any, textNode: any) {
    const parent = textNode.parentNode;

    newNodes.forEach((node: any) => {
        parent.insertBefore(node, textNode);
    });

    parent.removeChild(textNode);
}

function createNewTextNode(text: any) {
    return document.createTextNode(text);
}

function createNewLinkNode(text: any, link: any) {
    const a = document.createElement("a");

    a.innerText = text;
    a.href = link;
    a.className = "interlinkingkeyword";
    a.target = "_blanck";
    return a;
}

function getAllTextNodes(node: any) {
    const textNodes: Array<Node> = [];

    function getTextNodes(node: any) {
        if (node.nodeType === Node.TEXT_NODE) {
            textNodes.push(node);
        } else {
            for (let i = 0; i < node.childNodes.length; i++) {
                getTextNodes(node.childNodes[i]);
            }
        }
    }

    getTextNodes(node);
    return textNodes;
}
