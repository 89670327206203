import {
    Checkbox,
    CircularProgress,
    // Select,
    TextField,
    TextareaAutosize,
    debounce,
    makeStyles,
} from "@material-ui/core";
import React, { useEffect, useContext, useRef, useMemo } from "react";
import { useState } from "react";
import { STEPS_META_INFO } from "./cruiseConstant";
import styles from "./stepCard.module.css";
import { CruiseContext, CruiseState, NextStepBtnTexts } from "./StepsWizard";

import { Autocomplete, Skeleton } from "@material-ui/lab";
import Modal from "../../../atoms/Modal/Modal";
import BrandTone from "../../../../components/account/components/BrandTone/BrandTone";
import { CgClose } from "react-icons/cg";
import useHelperFunctions from "../../../helperFunctions";
import { CRUISE_TYPES } from "./useCruiseHook";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";

import SVGIcon from "../../../atoms/SVGIcon";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import VideoModal from "../../../Pages/NewDashboard/VideoModal";
import { MdOutlineRestore, MdTune } from "react-icons/md";
import CompetitionTable from "../../Research/CompetitionTable";
import ReportContext from "../../Research/ReportContext";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import Button from "../../../atoms/Button/Button";
import useGscHook from "../../../Pages/GoogleSearchConsole/useGscHook";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronDownSharp } from "react-icons/io5";
import {
    debounceWrapper,
    getTextWidth,
    parseOutlines,
} from "../../../../utility/helperFun";
import { RiDeleteBin6Line } from "react-icons/ri";
import TableColumnHeading from "../../Research/TableColumnHeading";
import useSortingBy from "../../../CommonHooks/useSortingBy";
import ProgressBar from "../../../atoms/ProgressBar";
import { displayAlert } from "../../../../store/actions/alert";
import {
    autoCustom,
    CustomChip,
} from "../../../molecules/Creator/KeywordInput/KeywordInput";
import MultiInputWithChip from "../../../atoms/MultiInputWithChip/MultiInputWithChip";
import { FaCheck, FaXmark } from "react-icons/fa6";

const HeadColumns = [
    { title: "Terms", isSort: false, isMain: true },
    {
        title: "Frequency",
        isSort: "max_times",
        isMain: false,
    },
    {
        title: "Importance",
        isSort: "importance",
        isMain: false,
    },
    {
        title: "Heading",
        isSort: "heading_presence",
        isMain: false,
    },
];

const inputCustom = makeStyles((theme) => ({
    root: {
        border: "1px solid #cdcdcd",
        borderRadius: 3,
        color: "var(--secondary-color)",
        height: 40,
        fontFamily: "Inter",

        "& .MuiFormControl-root": {
            marginBlock: 5,
        },

        "& input": {
            paddingInline: "10px !important",
            fontWeight: 400,
            fontSize: 14,
            color: "#2c3948",
            fontFamily: "Inter",
        },
        "& .MuiAutocomplete-popupIndicatorOpen": {
            transform: "translateX(-5px) rotate(180deg)",
        },
    },
    option: {
        padding: "0px",
        marginTop: 3,
    },
    listbox: {
        padding: 0,
        maxHeight: "30vh !important",
    },
}));

const selectCustom = makeStyles((theme) => ({
    root: {
        width: "100%",
        color: "#2A4266",
        fontSize: "14px",
        fontWeight: 400,

        "& .MuiSelect-select:focus": {
            background: "none",
        },
    },
}));

const textFieldCustom = makeStyles((theme) => ({
    root: {
        cursor: "not-allowed !important",
        "& input": {
            cursor: "not-allowed !important",
        },
    },
}));

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: "12px !important",
        height: "12px !important",
        color: "#b0b8c3",
        fontSize: "12px",
        padding: "0px",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

let keytermMap = {};

/**
 * @param {{stepsData:initialStepsData}} stepsCard
 *
 */

function ContextStep({ stepsData, handleDataOnBlur, setRealtimeRatings }) {
    const auth = useSelector((state) => state.auth);
    const userId = useSelector((state) => state.auth.userId);
    const [value, setValue] = useState(stepsData[STEPS_META_INFO[0].field]);
    const { competetion, reportInfo, setReportData, keyTerms } =
        useContext(ReportContext);
    const [toneModal, setToneModal] = useState(false);
    const [competitorsModal, setCompetitorsModal] = useState(false);
    const [organicCompList, setOrganicCompList] = useState([]);
    const [competitorsRank, setCompetitorsRank] = useState([]);
    const [heroImageType, setHeroImageType] = useState({
        value: "",
        label: "",
    });
    const [textWidth, setTextWidth] = useState(0);
    const [aiImageTypes, setAiImageTypes] = useState([]);
    const [aiTemplates, setAiTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState({});
    const [showAlertChangeTemplate, setShowAlertChangeTemplate] = useState({
        isShow: false,
        obj: {},
    });
    const [premiumTagSrc, setPremiumTagSrc] = useState(
        "/New UI/SVG/premium-text-small.svg",
    );
    const [premiumTagSrc1, setPremiumTagSrc1] = useState(
        "/New UI/SVG/premium-text-small.svg",
    );
    const classes = inputCustom();
    const classes1 = textFieldCustom();
    const checkboxClasses = checkBoxCustom();
    const [tones, setTones] = useState([]);
    const { getData, postData } = useHelperFunctions();
    const [getLoading, setGetLoading] = useState(true);
    const dispatchStepsData = useContext(CruiseContext);
    const [selectedTone, setSelectedTone] = useState({ brand_name: null });
    const [search, setSearch] = useState("");
    const [showMoreSettings, setShowMoreSettings] = useState(
        userId % 2 == 0 ? true : false,
    );
    const { isFreeForever, isEssential, individualUser } = useUserAccessHook();
    const competitorsAdjustRef = useRef(null);
    const { mpTrackAddNewToneClicked, mpTrackInfoIconClicked, mixpanelTrack } =
        useMixpanelHook();
    const { getTrimmedDomainURL } = useGscHook();
    const [customUrls, setCustomUrls] = useState([]);
    const [CompUrls, setCompUrls] = useState(competetion);
    const [keyTermsModal, setKeyTermsModal] = useState(false);
    const [sortBy, setSortBy] = useState({ colID: null, asc: true });
    const [keytermLoading, setKeytermLoading] = useState(false);
    const [customKeyTerm, setCustomKeyTerm] = useState(false);
    const [customKeytermsLoading, setCustomKeytermsLoading] = useState(false);
    const customKeytermRowRef = useRef(null);
    const [aiDetection, setAiDetection] = useState(
        stepsData.aiContentDetection,
    );
    const [inputKeyTerms, setInputKeyTerms] = useState([]);
    const [alertModal, setAlertModal] = useState({ value: "", status: false });
    let timeoutRef = useRef(null);

    const keyTermsList = useSortingBy({
        items: keyTerms,
        asc: sortBy.asc,
        term: sortBy.colID == null ? "id" : HeadColumns[sortBy.colID].isSort,
    });

    useEffect(() => {
        if (customKeyTerm) {
            customKeytermRowRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [customKeyTerm]);

    useEffect(() => {
        if (!keyTermsModal) {
            setCustomKeyTerm(false);
        }
    }, [keyTermsModal]);

    useEffect(() => {
        setCompUrls([...competetion, ...customUrls]);
    }, [competetion, customUrls]);

    const getCustomUrls = async () => {
        const res = await getData({
            url: "/api/scrape-custom/get?report_id=" + reportInfo.report_id,
        });
        if (res.status == 200) {
            res.data?.data?.map((val) => (val.grade = val.optimiser_score));
            setCustomUrls(res.data?.data);
        }
    };

    const updateContentDetection = () => {
        postData({
            url: "/api/user/update-content-detection",
            payload: {
                is_content_detection: aiDetection,
            },
        });
    };

    const updateRanks = () => {
        postData({
            url: "/api/contentDraft/v2/update",
            payload: {
                ranks: organicCompList,
                field: "paragraph",
                report_id: reportInfo.report_id,
            },
        });
    };

    const updateImageType = (id) => {
        postData({
            url: "/api/contentDraft/v2/update",
            payload: {
                image_type_id: id,
                field: "paragraph",
                report_id: reportInfo.report_id,
            },
        });
    };

    const getAiImages = async () => {
        const res = await getData({ url: "/api/ai-image/types" });
        if (res.status == 200) {
            console.log(res.data, "ressssss");
            let temp = [];
            res.data?.data.forEach((item) => {
                let obj = {};
                obj.value = item.id;
                obj.label = item.name;
                temp.push(obj);
            });
            setAiImageTypes(temp);
            if (stepsData?.image_type?.id) {
                setHeroImageType({
                    value: stepsData?.image_type?.id,
                    label: stepsData?.image_type?.name,
                });
            } else {
                setHeroImageType({
                    value: res.data.data[0]?.id,
                    label: res.data.data[0]?.name,
                });
            }
        }
    };

    const getCruiseTemplates = async () => {
        console.log("active_template_id", stepsData?.active_template_id);

        const res = await getData({ url: "/api/contentDraft/template" });
        if (res.status == 200) {
            console.log("getCruiseTemplates", res.data);
            if (res.data.length > 0) {
                let temp = res.data.map((tmp) => ({
                    ...tmp,
                    value: tmp?.name,
                    label: tmp?.name,
                    isRecommended:
                        stepsData?.recommend_template_id == tmp.id
                            ? true
                            : false,
                }));
                setAiTemplates([...temp]);
                console.log("active_template_id", temp);
                setCurrentTemplate(
                    stepsData?.active_template_id
                        ? temp.filter(
                              (itm) => itm.id == stepsData?.active_template_id,
                          )[0]
                        : temp[0],
                );
            }
        } else {
            setAiTemplates([]);
        }
    };

    const getTones = async () => {
        setGetLoading(true);
        const res = await getData({
            url: "/api/tone/get",
        });
        if (res.status == 200) {
            setTones(res.data.data.reverse());
            setGetLoading(false);
        }
    };

    useEffect(() => {
        dispatchStepsData({
            type: CRUISE_TYPES.AI_CONTENT_DETECTION,
            aiContentDetection: aiDetection,
        });
        debounceWrapper(() => updateContentDetection(), 500, timeoutRef);
    }, [aiDetection]);

    useEffect(() => {
        if (heroImageType?.value) {
            updateImageType(heroImageType?.value);
        }
    }, [heroImageType]);

    useEffect(() => {
        if (
            auth.segment == 3 ||
            auth.segment == 4 ||
            auth.segment == 5 ||
            auth.segment == 6 ||
            reportInfo.is_premium
        ) {
            getAiImages();
        }
        getCustomUrls();
    }, []);

    useEffect(() => {
        if (
            (auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6) &&
            (stepsData.recommend_template_id == null ||
                stepsData.recommend_template_id)
        ) {
            getCruiseTemplates();
        }
    }, [stepsData.recommend_template_id]);

    useEffect(() => {
        if (!competitorsModal) {
            setCompetitorsRank(organicCompList);
        }
    }, [organicCompList]);

    useEffect(() => {
        if (!competitorsModal) {
            setOrganicCompList(competitorsRank);
        }
        setCompUrls(CompUrls.filter((ele) => ele.rank != "custom"));
    }, [competitorsModal]);

    useEffect(() => {
        setTextWidth(getTextWidth(currentTemplate.label, styles.field));
    }, [currentTemplate]);

    useEffect(() => {
        if (stepsData.tone_id) {
            const tone = tones.filter(
                (item) => item.id == stepsData.tone_id,
            )[0];
            setSelectedTone(
                tone || {
                    brand_name: null,
                },
            );
        }
    }, [tones, stepsData.tone_id]);

    useEffect(() => {
        if (!toneModal) getTones();
    }, [toneModal]);

    useEffect(() => {
        if (organicCompList.length > 0) {
            updateRanks();
            dispatchStepsData({
                type: CRUISE_TYPES.ORGANIC_COMPETITORS,
                ranks: organicCompList,
            });
        }
    }, [organicCompList]);

    const { ranks } = useContext(CruiseState);

    function updateKeyterms(id, term, isDeleted) {
        if (keytermMap.hasOwnProperty(id)) {
            keytermMap[id] = { id, term, isDeleted };
        } else {
            keytermMap[id] = { id, term, isDeleted };
        }
    }

    const updateKeyTermsList = async () => {
        const userAddedTerms = inputKeyTerms.map((ele) => {
            return { term: ele };
        });
        if (Object.values(keytermMap)?.length > 0 || userAddedTerms.length) {
            if (userAddedTerms.length > 0) {
                setCustomKeytermsLoading(true);
            }
            setKeytermLoading(true);
            const res = await postData({
                url: "/api/scrape/unwantedNLP",
                payload: {
                    report_id: reportInfo.report_id,
                    terms: Object.values(keytermMap),
                    userAdded: inputKeyTerms.length ? userAddedTerms : [],
                },
            });
            setKeytermLoading(false);
            setCustomKeytermsLoading(false);
            setCustomKeyTerm(false);
            setInputKeyTerms([]);
            if (res.status == 200) {
                keytermMap = {};
                setReportData((ps) => ({
                    ...ps,
                    keyTerms: res.data?.relevantTerms,
                }));
                dispatch(
                    displayAlert({
                        alertMessage:
                            "Keyterm(s) have been added successfully!",
                        alertType: "success",
                    }),
                );
            }
        } else {
            return;
        }
    };

    useEffect(() => {
        if (!ranks.length) {
            const filteredComp = competetion.filter(
                (ele) => ele.word_count >= 200 && ele.word_count <= 3500,
            );
            if (filteredComp.length) {
                const finalComp = filteredComp.filter(
                    (ele) => ele.htags_count >= 3 && ele.htags_count <= 100,
                );
                if (finalComp.length) {
                    setOrganicCompList(
                        finalComp
                            .sort((a, b) => b.grade - a.grade)
                            .slice(0, reportInfo.is_premium ? 3 : 2)
                            .map((i) => i.rank),
                    );
                } else {
                    setOrganicCompList(
                        filteredComp
                            .sort((a, b) => b.grade - a.grade)
                            .slice(0, reportInfo.is_premium ? 3 : 2)
                            .map((i) => i.rank),
                    );
                }
            } else {
                setOrganicCompList(
                    competetion
                        .sort((a, b) => b.grade - a.grade)
                        .slice(0, reportInfo.is_premium ? 3 : 2)
                        .map((i) => i.rank),
                );
            }
        } else {
            setOrganicCompList([...ranks]);
        }
    }, [competetion]);

    const organicCompListRender = (item = [], isTrimmed = true) => {
        let tempOCList = [];
        item.forEach((itm) => {
            let obj = CompUrls.filter((ocItm) => ocItm?.rank == itm)[0];
            if (obj?.url) {
                tempOCList.push(getTrimmedDomainURL(obj?.url));
            }
        });
        let str = tempOCList.join(",");
        if (isTrimmed) {
            return str;
        } else {
            return (
                <ul>
                    {tempOCList.map((item) => (
                        <li
                            style={{
                                padding: "3px",
                                fontSize: "14px",
                                fontWeight: 400,
                            }}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            );
        }
    };
    console.log(reportInfo, "reportInfo.reportLoading");

    return (
        <>
            <h4
                style={{
                    color: "var(--secondary-color)",
                    display: "flex",
                    alignItems: "center",
                    marginBlock: 0,
                    fontWeight: 500,
                    fontSize: "14px",
                }}
            >
                Context
            </h4>
            <div className={styles.contextContaienr}>
                <TextareaAutosize
                    minRows={2}
                    maxRows={10}
                    autoFocus={true}
                    className={styles.contextInput}
                    placeholder={STEPS_META_INFO[0].placeholderText}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onBlur={(e) => {
                        handleDataOnBlur(
                            e.target.value,
                            STEPS_META_INFO[0].field,
                        );
                    }}
                />

                <p className={styles.wordCount}>
                    {stepsData.blog_context.length}/ 500
                </p>

                {value.length ? (
                    <p className={styles.nextNudgeInfo}>
                        To proceed, click on{" "}
                        <span
                            style={{
                                fontWeight: "500",
                            }}
                        >
                            {NextStepBtnTexts[0]}
                        </span>{" "}
                        from the bottom bar
                    </p>
                ) : (
                    ""
                )}
            </div>
            {(auth.segment == 5 || auth.segment == 6) && (
                <div className={styles.antiAiWrapper}>
                    <div className={styles.aiToggle}>
                        <Checkbox
                            checked={aiDetection}
                            onChange={(e) => {
                                if (stepsData.title) {
                                    setAlertModal({
                                        value: e.target.checked,
                                        status: true,
                                    });
                                } else {
                                    setAiDetection(e.target.checked ? 1 : 0);
                                    mixpanelTrack("Anti-AI", {
                                        action: e.target.checked
                                            ? "Check"
                                            : "Un-Check",
                                    });
                                }
                            }}
                            classes={checkboxClasses}
                            size="small"
                            disableRipple={true}
                        />
                        Enable Anti-AI detection
                        <LightTooltip
                            arrow
                            title={
                                "Enabling anti-AI detection reduces the likelihood of content being detected as AI, but it may result in less formal language."
                            }
                        >
                            <span style={{ display: "flex" }}>
                                <SVGIcon
                                    color="#2196f3"
                                    size={14}
                                    src={"/New UI/SVG/info.svg"}
                                />
                            </span>
                        </LightTooltip>
                    </div>
                </div>
            )}

            {(auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6) &&
                !reportInfo.is_premium && (
                    <div style={{ width: "60%" }}>
                        <div
                            className={styles.inputTitle}
                            style={{ gap: "5px" }}
                        >
                            <span>Article Type</span>{" "}
                        </div>
                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={
                                !!!aiTemplates.length &&
                                !reportInfo.is_completed
                                    ? "Please wait, the report is still in progress."
                                    : ""
                            }
                        >
                            <Autocomplete
                                loading={!!!aiTemplates.length}
                                onOpen={() => {
                                    mixpanelTrack("Cruise Template", {
                                        action: "Open",
                                    });
                                }}
                                popupIcon={
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        <SVGIcon
                                            size={14}
                                            src={"/New UI/SVG/chevronDown.svg"}
                                            style={{
                                                cursor: "pointer",
                                                marginRight: 5,
                                                transform: "trans",
                                            }}
                                        />
                                    </div>
                                }
                                classes={classes}
                                onChange={(e, val) => {
                                    // if (val.id == 5) {
                                    //     e.stopPropagation();
                                    //     return;
                                    // } else {
                                    if (val?.id != currentTemplate?.id) {
                                        e.preventDefault();
                                        if (stepsData.title) {
                                            setShowAlertChangeTemplate({
                                                isShow: true,
                                                obj: val,
                                            });
                                            return null;
                                        } else {
                                            mixpanelTrack("Cruise Template", {
                                                action: "Change",
                                                selected_template: val?.label,
                                            });
                                            setCurrentTemplate(val);
                                            dispatchStepsData({
                                                type: CRUISE_TYPES.UPDATE_TEMPLATE,
                                                active_template_id:
                                                    val?.id || -1,
                                            });
                                        }
                                    }
                                    // }
                                }}
                                options={[...aiTemplates]}
                                getOptionLabel={(item) => item.label}
                                filterOptions={() => [...aiTemplates]}
                                value={currentTemplate}
                                renderOption={(item) => (
                                    <div
                                        style={{
                                            fontWeight: 400,
                                            color: "var(--secondary-color)",
                                            fontSize: 14,
                                            padding: "5px 10px",
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                        // className={
                                        //     item.id == 5
                                        //         ? styles.disabledOption
                                        //         : ""
                                        // }
                                        // onClick={(e) => {
                                        //     if (item.id == 5) {
                                        //         e.stopPropagation();
                                        //         return;
                                        //     }
                                        // }}
                                    >
                                        <span>{item.label}</span>
                                        {item.isRecommended && (
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                    marginLeft: "5px",
                                                }}
                                            >
                                                {" (Recommended)"}
                                            </span>
                                        )}
                                        {/* {item.id == 5 && (
                                        <span className={styles.comingSoon}>
                                            Coming soon
                                        </span>
                                    )} */}
                                    </div>
                                )}
                                disableClearable
                                readOnly={true}
                                renderInput={(params) => {
                                    console.log(params, "paramssssss");
                                    return (
                                        <>
                                            {!!!aiTemplates.length ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                        alignItems: "center",
                                                        cursor: "not-allowed",
                                                    }}
                                                >
                                                    <CircularProgress
                                                        className={
                                                            styles.progress
                                                        }
                                                        size={16}
                                                        style={{
                                                            margin: 12,
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <TextField
                                                        {...params}
                                                        className={styles.field}
                                                        margin="normal"
                                                        variant="standard"
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        aria-readonly={true}
                                                        placeholder=""
                                                        // InputProps={{
                                                        //     ...params.InputProps,
                                                        //     endAdornment: (
                                                        //         // <InputAdornment position="start">
                                                        //         <span>Recom</span>
                                                        //         // </InputAdornment>
                                                        //     ),
                                                        // }}
                                                        // style={{ width: "0px" }}
                                                    />
                                                    {currentTemplate.id ==
                                                        stepsData.recommend_template_id &&
                                                    textWidth ? (
                                                        <span
                                                            onClick={(e) => {
                                                                params.inputProps.onMouseDown(
                                                                    e,
                                                                );
                                                                // console.log(params.inputProps)
                                                            }}
                                                            className={
                                                                styles.recommendedText
                                                            }
                                                            style={{
                                                                left: `${
                                                                    textWidth +
                                                                    8
                                                                }px`,
                                                                fontSize:
                                                                    "12px",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            (Recommended)
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            />
                        </LightTooltip>
                    </div>
                )}
            {(!!reportInfo?.is_premium ||
                auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6) && (
                <div>
                    <div className={styles.inputTitle} style={{ gap: "5px" }}>
                        <span>
                            Reference Articles (up to{" "}
                            {reportInfo.is_premium ? "5" : "3"})
                        </span>
                        <div
                            onMouseEnter={() => {
                                setPremiumTagSrc1(
                                    "/New UI/SVG/premium-text-blue.svg",
                                );
                            }}
                            onMouseLeave={() => {
                                setPremiumTagSrc1(
                                    "/New UI/SVG/premium-text-small.svg",
                                );
                            }}
                            style={{ display: "flex" }}
                        >
                            {auth.segment != 3 &&
                                auth.segment != 4 &&
                                auth.segment != 5 &&
                                auth.segment != 6 && (
                                    <SVGIcon src={premiumTagSrc1} />
                                )}
                        </div>
                    </div>
                    <LightTooltip
                        arrow
                        placement={"bottom"}
                        interactive
                        title={
                            organicCompList.length ? (
                                <div
                                    style={{
                                        paddingInline: "20px",
                                        maxHeight: "150px",
                                        overflow: "auto",
                                        marginBlock: "5px",
                                        // fontSize: "12px",
                                    }}
                                >
                                    {" "}
                                    {organicCompListRender(
                                        organicCompList,
                                        false,
                                    )}
                                </div>
                            ) : !reportInfo.is_completed ? (
                                "Please wait, the report is still in progress."
                            ) : (
                                ""
                            )
                        }
                    >
                        <div
                            className={styles.competitorsAdjust}
                            ref={competitorsAdjustRef}
                            onClick={(e) => {
                                if (competetion.length) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setCompetitorsModal(true);
                                    mixpanelTrack("Reference Article", {
                                        action: "Open",
                                    });
                                }
                            }}
                            style={{
                                cursor: organicCompList.length
                                    ? ""
                                    : "not-allowed",
                            }}
                        >
                            {organicCompList.length > 0 ? (
                                <>
                                    <div
                                        style={{
                                            maxWidth: "calc(100% - 30px)",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            marginLeft: "5px",
                                            // fontSize: "12px",
                                        }}
                                    >
                                        {organicCompList.length} Selected{" "}
                                        {/* </span> */}
                                        {"("}
                                        {organicCompListRender(
                                            organicCompList,
                                            true,
                                        )}
                                    </div>
                                    <span>{")"}</span>
                                </>
                            ) : (
                                "Organic Competitors"
                            )}{" "}
                            {competetion.length ? (
                                <div className={styles.compIcon}>
                                    <MdTune
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setCompetitorsModal(true);
                                            mixpanelTrack("Reference Article", {
                                                action: "Open",
                                            });
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className={styles.compIcon}>
                                    <CircularProgress size={16} />
                                </div>
                            )}
                        </div>
                    </LightTooltip>
                </div>
            )}
            {/* {(auth.segment == 3 || auth.segment == 4 || auth.segment == 5) &&
                !reportInfo.is_premium && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            width: "max-content",
                            cursor: "pointer",
                            marginTop: "20px",
                        }}
                        onClick={() => {
                            mixpanelTrack("More Settings", {
                                action: showMoreSettings ? "close" : "open",
                            });
                            setShowMoreSettings(!showMoreSettings);
                        }}
                    >
                        <span
                            style={{
                                whiteSpace: "nowrap",
                                // marginRight: "10px",
                                color: "#2c3948",
                                fontSize: 14,
                                fontWeight: 500,
                            }}
                        >
                            More Settings
                        </span>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                                marginLeft: "5px",
                                transform: showMoreSettings
                                    ? "rotate(180deg)"
                                    : "",
                            }}
                        >
                            <IoChevronDownSharp size={18} color="#2c3948" />
                        </div>
                    </div>
                )} */}
            {auth.segment != 3 ||
            auth.segment != 4 ||
            auth.segment != 5 ||
            auth.segment != 6 ||
            reportInfo.is_premium ? (
                <>
                    {(!!reportInfo?.is_premium ||
                        auth.segment == 3 ||
                        auth.segment == 4 ||
                        auth.segment == 5 ||
                        auth.segment == 6) && (
                        <div style={{ width: "60%" }}>
                            <div
                                className={styles.inputTitle}
                                style={{ gap: "5px" }}
                            >
                                <span>Featured Image Style</span>{" "}
                                <div
                                    onMouseEnter={() => {
                                        setPremiumTagSrc(
                                            "/New UI/SVG/premium-text-blue.svg",
                                        );
                                    }}
                                    onMouseLeave={() => {
                                        setPremiumTagSrc(
                                            "/New UI/SVG/premium-text-small.svg",
                                        );
                                    }}
                                    style={{ display: "flex" }}
                                >
                                    {auth.segment != 3 &&
                                        auth.segment != 4 &&
                                        auth.segment != 5 &&
                                        auth.segment != 6 && (
                                            <SVGIcon src={premiumTagSrc} />
                                        )}
                                </div>
                            </div>
                            <div className={styles.heroImage}>
                                <Autocomplete
                                    loading={!!!aiImageTypes.length}
                                    onOpen={() => {
                                        mixpanelTrack("Featured Image Type", {
                                            action: "Open",
                                        });
                                    }}
                                    popupIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "3px",
                                            }}
                                        >
                                            <SVGIcon
                                                size={14}
                                                src={
                                                    "/New UI/SVG/chevronDown.svg"
                                                }
                                                style={{
                                                    cursor: "pointer",
                                                    marginRight: 5,
                                                    transform: "trans",
                                                }}
                                            />
                                        </div>
                                    }
                                    classes={classes}
                                    onChange={(e, val) => {
                                        mixpanelTrack("Featured Image Type", {
                                            action: "Change",
                                            selected_type: val.label,
                                        });
                                        dispatchStepsData({
                                            type: CRUISE_TYPES.SET_IMAGE_TYPE,
                                            state: {
                                                id: val.value,
                                                name: val.label,
                                            },
                                        });
                                        setHeroImageType(val);
                                    }}
                                    options={[...aiImageTypes]}
                                    getOptionLabel={(item) => item.label}
                                    filterOptions={() => [...aiImageTypes]}
                                    value={heroImageType}
                                    renderOption={(item) => {
                                        console.log(
                                            "renderOption=======",
                                            item,
                                        );
                                        return (
                                            <span
                                                style={{
                                                    fontWeight: 400,
                                                    color: "var(--secondary-color)",
                                                    fontSize: 14,
                                                    padding: "5px 10px",
                                                }}
                                            >
                                                {item.label}
                                            </span>
                                        );
                                    }}
                                    disableClearable
                                    readOnly={true}
                                    renderInput={(params) => (
                                        <>
                                            {!!!aiImageTypes.length ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CircularProgress
                                                        className={
                                                            styles.progress
                                                        }
                                                        size={16}
                                                        style={{
                                                            margin: 12,
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <TextField
                                                    className={styles.field}
                                                    margin="normal"
                                                    variant="standard"
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    placeholder="Select image type"
                                                    {...params}
                                                    // {...((isFreeForever ||
                                                    //     isEssential ||
                                                    //     individualUser) && {
                                                    //     classes: classes1,
                                                    // })}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    {(!!reportInfo?.is_premium ||
                        auth.segment == 3 ||
                        auth.segment == 4 ||
                        auth.segment == 5 ||
                        auth.segment == 6) && (
                        <div>
                            <div
                                className={styles.inputTitle}
                                style={{ gap: "5px" }}
                            >
                                <span>Key Terms List</span>
                            </div>
                            <LightTooltip
                                arrow
                                placement={"bottom"}
                                interactive
                                title={
                                    !reportInfo.is_completed
                                        ? "Please wait, the report is still in progress."
                                        : ""
                                }
                            >
                                <div
                                    className={styles.competitorsAdjust}
                                    onClick={() => {
                                        if (
                                            reportInfo.is_completed &&
                                            !keytermLoading
                                        ) {
                                            setKeyTermsModal(true);
                                            mixpanelTrack(
                                                "Key Terms List(Cruise)",
                                                {
                                                    action: "clicked",
                                                },
                                            );
                                        }
                                    }}
                                    style={{
                                        cursor:
                                            keyTermsList.length &&
                                            !keytermLoading
                                                ? ""
                                                : "not-allowed",
                                    }}
                                >
                                    Manage Key Terms
                                    {keyTermsList.length && !keytermLoading ? (
                                        <div className={styles.compIcon}>
                                            <MdTune onClick={(e) => {}} />
                                        </div>
                                    ) : (
                                        <div className={styles.compIcon}>
                                            <CircularProgress size={16} />
                                        </div>
                                    )}
                                </div>
                            </LightTooltip>
                        </div>
                    )}
                    <div style={{ width: "60%" }}>
                        <div className={styles.inputTitle}>
                            Tone of Voice &nbsp;
                            <LightTooltip
                                title={
                                    <>
                                        Choose the desired tone of voice to
                                        generate customized content tailored to
                                        your preference.
                                        <br /> <br />
                                        You can let Scalenut automatically
                                        determine your brand tone or manually
                                        input your desired tone.
                                        <br />
                                        <br />
                                        <strong>Example</strong> <br /> <br />
                                        Add a tone manually for example-
                                        assertive, encouraging etc. or you can
                                        paste the content's URL or directly
                                        paste the content for our AI to detect.
                                        Please remember to name your tone for
                                        future reference.
                                        <br />
                                        <br />
                                        <VideoModal
                                            link={`https://www.youtube.com/embed/KiP5YzL5BCM?modestbranding=1&rel=0&loop=1`}
                                            zoomVal={1.2}
                                            modal={false}
                                            aspectWidth={380}
                                            aspectHeight={200}
                                            customClass={styles.tooltipVideo}
                                            location="Tone of voice"
                                        />
                                    </>
                                }
                                arrow
                                click
                                tooltipStyle={{
                                    maxWidth: 440,
                                    padding: "30px 30px 30px 30px !important",
                                }}
                                placement="right"
                            >
                                <div
                                    style={{ height: 19 }}
                                    onClick={(e) => {
                                        mpTrackInfoIconClicked({
                                            location: "Tone of Voice",
                                        });
                                    }}
                                >
                                    <SVGIcon
                                        color="#2196f3"
                                        size={18}
                                        src={"/New UI/SVG/info.svg"}
                                    />
                                </div>
                            </LightTooltip>
                        </div>

                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={
                                isFreeForever || isEssential || individualUser
                                    ? "This feature is available on higher plans"
                                    : ""
                            }
                        >
                            <Autocomplete
                                popupIcon={
                                    <SVGIcon
                                        size={14}
                                        src={"/New UI/SVG/chevronDown.svg"}
                                        style={{
                                            cursor: "pointer",
                                            marginRight: 5,
                                            transform: "trans",
                                        }}
                                    />
                                }
                                style={{
                                    cursor:
                                        isFreeForever ||
                                        isEssential ||
                                        individualUser
                                            ? "not-allowed"
                                            : "",
                                }}
                                disabled={
                                    isFreeForever ||
                                    isEssential ||
                                    individualUser
                                }
                                classes={classes}
                                onChange={(e, val) => {
                                    if (val?.brand_name === null) return;
                                    if (val?.brand_name) {
                                        setSelectedTone(val);
                                    }

                                    dispatchStepsData({
                                        type: CRUISE_TYPES.UPDATE_TONE,
                                        tone_id: val?.id || -1,
                                    });
                                }}
                                onOpen={() => setSearch("")}
                                options={[...tones, { brand_name: null }]}
                                value={selectedTone}
                                renderOption={(item) =>
                                    item.brand_name == null ? (
                                        <button
                                            onClick={() => {
                                                mpTrackAddNewToneClicked();
                                                setToneModal(true);
                                            }}
                                            className={styles.addBtn}
                                        >
                                            + Add new
                                        </button>
                                    ) : (
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                color: "var(--secondary-color)",
                                                fontSize: 14,
                                                padding: "5px 10px",
                                            }}
                                        >
                                            {item.brand_name}
                                        </span>
                                    )
                                }
                                getOptionLabel={(opt) => opt.brand_name}
                                filterOptions={() => [
                                    ...tones.filter((item) =>
                                        item.brand_name.includes(search),
                                    ),
                                    { brand_name: null },
                                ]}
                                renderInput={(params) => (
                                    <>
                                        {getLoading ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CircularProgress
                                                    className={styles.progress}
                                                    size={16}
                                                    style={{
                                                        margin: 12,
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <TextField
                                                className={styles.field}
                                                margin="normal"
                                                value={search}
                                                onChange={(e) =>
                                                    setSearch(e.target.value)
                                                }
                                                placeholder="Default - SERP Based"
                                                {...params}
                                                {...((isFreeForever ||
                                                    isEssential ||
                                                    individualUser) && {
                                                    classes: classes1,
                                                })}
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </LightTooltip>
                    </div>
                </>
            ) : (
                <></>
            )}
            {toneModal && (
                <Modal setModal={setToneModal}>
                    <div
                        style={{
                            width: 1000,
                            position: "relative",
                        }}
                    >
                        <CgClose
                            onClick={() => setToneModal(false)}
                            size={14}
                            color="lightgray"
                            strokeWidth={2}
                            className={styles.close}
                            style={{ zIndex: "1" }}
                        />
                        <BrandTone
                            setToneModal={setToneModal}
                            modalType={true}
                            onToneAdd={(tone) => {
                                setSelectedTone(tone);
                                dispatchStepsData({
                                    type: CRUISE_TYPES.UPDATE_TONE,
                                    tone_id: tone?.id || -1,
                                });
                            }}
                            onTonesChange={(tones) => {
                                setTones(tones);
                            }}
                            toneName={search}
                        />
                    </div>
                </Modal>
            )}
            {competitorsModal && (
                <Modal setModal={setCompetitorsModal}>
                    <div className={styles.competetionContainer}>
                        <h2 style={{ marginInline: 10 }}>
                            Select your reference articles for our AI{" "}
                            <Button
                                style={{
                                    background: "rgba(1,77,197,.1)",
                                    color: "#014dc5",
                                    fontSize: "14px",
                                    marginTop: "15px",
                                    fontWeight: 400,
                                }}
                                width={150}
                                height={24}
                                text="+ Add Custom URL"
                                handler={() => {
                                    mixpanelTrack("Custom URL CTA", {
                                        action: "clicked",
                                    });
                                    let custom = CompUrls.filter(
                                        (ele) => ele.rank == "custom",
                                    );
                                    if (custom.length > 0) {
                                        return;
                                    } else {
                                        setCompUrls((ps) => [
                                            { rank: "custom" },
                                            ...ps,
                                        ]);
                                    }
                                }}
                            />
                            <CloseButton
                                style={{
                                    top: 25,
                                    right: 10,
                                    position: "absolute",
                                }}
                                setClose={setCompetitorsModal}
                            />{" "}
                        </h2>
                        <div className={styles.competetionTable}>
                            <CompetitionTable
                                list={CompUrls}
                                setList={setCompUrls}
                                cruiseMode={true}
                                organicCompList={organicCompList}
                                setOrganicCompList={setOrganicCompList}
                                setCompetitorsRank={setCompetitorsRank}
                            />
                        </div>
                        <div className={styles.competetionBtn}>
                            <Button
                                handler={() => {
                                    // updateRanks();
                                    mixpanelTrack("Reference Article", {
                                        action: "Update",
                                        ranks: organicCompList,
                                    });
                                    setCompetitorsRank(organicCompList);
                                    setCompetitorsModal(false);
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.DELETE_ALL_OUTLINES,
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.DELETE_FEATURED_IMAGE,
                                    });
                                    setRealtimeRatings((ps) => {
                                        return {
                                            ...ps,
                                            seoScore: 0,
                                        };
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                        field: "title",
                                        data: "",
                                    });
                                }}
                                width={100}
                                text={"Update"}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {alertModal.status && (
                <Modal
                    setModal={() => {
                        setAlertModal({ value: "", status: false });
                    }}
                >
                    <div className={styles.alertChangeTemplate}>
                        <div className={styles.templateAlert_line1}>
                            Are you sure you want to{" "}
                            {alertModal.value ? "enable" : "disable"} Anti-AI
                            detection?
                        </div>
                        <div className={styles.templateAlert_line2}>
                            Changing this will delete the generated content.
                        </div>
                        <div className={styles.bottomCta}>
                            <Button
                                text={"Back"}
                                // customClass={styles.backcta}
                                secondary={true}
                                style={{
                                    background: "white",
                                    color: "black",
                                }}
                                handler={() => {
                                    setAlertModal({ value: "", status: false });
                                }}
                            />
                            <Button
                                text={"Confirm"}
                                className={styles.confirmCta}
                                handler={() => {
                                    setAiDetection(alertModal.value ? 1 : 0);
                                    mixpanelTrack("Anti-AI", {
                                        action: alertModal.value
                                            ? "Check"
                                            : "Un-Check",
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.DELETE_ALL_OUTLINES,
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.DELETE_FEATURED_IMAGE,
                                    });
                                    setRealtimeRatings((ps) => {
                                        return {
                                            ...ps,
                                            seoScore: 0,
                                        };
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                        field: "title",
                                        data: "",
                                    });
                                    setAlertModal({ value: "", status: false });
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}

            {showAlertChangeTemplate.isShow && (
                <Modal
                    setModal={() => {
                        setShowAlertChangeTemplate({ isShow: false, obj: {} });
                    }}
                >
                    <div className={styles.alertChangeTemplate}>
                        <div className={styles.templateAlert_line1}>
                            Are you sure you want to change the template?
                        </div>
                        <div className={styles.templateAlert_line2}>
                            Changing the template will delete the generated
                            content.
                        </div>
                        <div className={styles.bottomCta}>
                            <Button
                                text={"Back"}
                                // customClass={styles.backcta}
                                secondary={true}
                                style={{
                                    background: "white",
                                    color: "black",
                                }}
                                handler={() => {
                                    setShowAlertChangeTemplate({
                                        isShow: false,
                                        obj: {},
                                    });
                                }}
                            />
                            <Button
                                text={"Confirm"}
                                className={styles.confirmCta}
                                handler={() => {
                                    mixpanelTrack("Cruise Template", {
                                        action: "Change",
                                        selected_template:
                                            showAlertChangeTemplate.obj?.label,
                                    });
                                    setCurrentTemplate(
                                        showAlertChangeTemplate.obj,
                                    );
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.UPDATE_TEMPLATE,
                                        active_template_id:
                                            showAlertChangeTemplate.obj?.id ||
                                            -1,
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.DELETE_ALL_OUTLINES,
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.DELETE_FEATURED_IMAGE,
                                    });
                                    setRealtimeRatings((ps) => {
                                        return {
                                            ...ps,
                                            seoScore: 0,
                                        };
                                    });
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                        field: "title",
                                        data: "",
                                    });
                                    setShowAlertChangeTemplate({
                                        isShow: false,
                                        obj: {},
                                    });
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {keyTermsModal && (
                <Modal setModal={setKeyTermsModal}>
                    <div className={styles.competetionContainer}>
                        <h2
                            style={{
                                marginInline: 10,
                                marginBottom: 10,
                                height: "30px",
                            }}
                        >
                            List of NLP Extracted Key Terms
                            <CloseButton
                                style={{
                                    top: 25,
                                    right: 10,
                                    position: "absolute",
                                }}
                                setClose={setKeyTermsModal}
                            />{" "}
                        </h2>
                        <Button
                            style={{
                                background: "rgba(1,77,197,.1)",
                                color: "#014dc5",
                                fontSize: "14px",
                                marginTop: "5px",
                                fontWeight: 400,
                            }}
                            width={130}
                            height={24}
                            text="+ Add Keyterms"
                            handler={() => {
                                setCustomKeyTerm(true);
                                mixpanelTrack("Add Custom Key Terms CTA", {
                                    action: "clicked",
                                    section: "Cruise Mode",
                                });
                            }}
                        />
                        <div
                            ref={customKeytermRowRef}
                            className={styles.tableContainer}
                        >
                            <table>
                                <thead>
                                    <tr className={styles.tableHead}>
                                        <th style={{ width: "30px" }}></th>
                                        {HeadColumns.map((col, i) => {
                                            return (
                                                <TableColumnHeading
                                                    sortBy={sortBy}
                                                    sortingBy={(colID, asc) =>
                                                        setSortBy({
                                                            colID,
                                                            asc,
                                                        })
                                                    }
                                                    i={i}
                                                    col={col}
                                                />
                                            );
                                        })}
                                    </tr>
                                </thead>

                                <tbody className={styles.tableBody}>
                                    {customKeytermsLoading ? (
                                        <tr className={styles.customDataRow}>
                                            <td></td>
                                            <td>
                                                <div
                                                    style={{
                                                        marginLeft: "25px",
                                                    }}
                                                >
                                                    <Skeleton />
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        marginLeft: "30px",
                                                    }}
                                                >
                                                    <Skeleton width={50} />
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        marginLeft: "15px",
                                                    }}
                                                >
                                                    <Skeleton width={80} />
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        marginLeft: "15px",
                                                    }}
                                                >
                                                    <Skeleton width={50} />
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        customKeyTerm && (
                                            <tr
                                                className={styles.customDataRow}
                                            >
                                                <td></td>
                                                <td colSpan={2}>
                                                    <MultiInputWithChip
                                                        chips={inputKeyTerms}
                                                        setChips={
                                                            setInputKeyTerms
                                                        }
                                                        parentStyle={{
                                                            maxHeight: "70px",
                                                            overflow: "auto",
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "10px",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <Button
                                                            handler={() => {
                                                                updateKeyTermsList();
                                                                mixpanelTrack(
                                                                    "Key Terms List(Cruise)",
                                                                    {
                                                                        action: "Update",
                                                                    },
                                                                );
                                                            }}
                                                            style={{
                                                                background:
                                                                    "#014dc51a",
                                                                color: "#014dc5",
                                                            }}
                                                            text=""
                                                            width={30}
                                                            height={30}
                                                            Icon={FaCheck}
                                                        />
                                                        <Button
                                                            text=""
                                                            width={30}
                                                            height={30}
                                                            Icon={FaXmark}
                                                            style={{
                                                                background:
                                                                    "rgb(255, 243, 242)",
                                                                color: "rgb(252, 16, 36)",
                                                            }}
                                                            handler={() => {
                                                                setCustomKeyTerm(
                                                                    false,
                                                                );
                                                                setInputKeyTerms(
                                                                    [],
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    <KeyTermRow
                                        list={keyTermsList}
                                        updateKeyterms={updateKeyterms}
                                        keytermLoading={keytermLoading}
                                    />
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.competetionBtn}>
                            <Button
                                handler={() => {
                                    updateKeyTermsList();
                                    setKeyTermsModal(false);
                                    mixpanelTrack("Key Terms List(Cruise)", {
                                        action: "Update",
                                    });
                                }}
                                loading={keytermLoading}
                                width={100}
                                text={"Update"}
                                style={{}}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

const KeyTermRow = ({ list, updateKeyterms, keytermLoading }) => {
    const dispatch = useDispatch();
    const { reportInfo } = useContext(ReportContext);
    const [listCopy, setListCopy] = useState(JSON.parse(JSON.stringify(list)));

    const itemStyle = useMemo(
        () =>
            (status, marginLeft = 0) => ({
                display: "flex",
                marginLeft: `${marginLeft}px`,
                opacity: status ? 1 : 0.3,
            }),
        [],
    );

    useEffect(() => {
        setListCopy(JSON.parse(JSON.stringify(list)));
    }, [list]);

    return listCopy.map((item) => (
        <tr className={styles.dataRow}>
            <td>
                <Checkbox
                    checked={item.status}
                    onChange={(e) => {
                        if (reportInfo?.is_sample || keytermLoading) {
                            return;
                        }
                        if (e.target.checked) {
                            setListCopy(
                                listCopy.map((ele) => {
                                    if (ele.id == item.id) {
                                        ele.status = 1;
                                    }
                                    return ele;
                                }),
                            );
                            updateKeyterms(item?.id, item?.keyword, 1);
                        } else {
                            if (
                                listCopy.filter((ele) => ele.status).length <= 5
                            ) {
                                dispatch(
                                    displayAlert({
                                        alertMessage:
                                            "minimum 5 key terms are required",
                                        alertType: "error",
                                    }),
                                );
                                return;
                            }
                            updateKeyterms(item?.id, item?.keyword, 0);
                            setListCopy(
                                listCopy.map((ele) => {
                                    if (ele.id == item.id) {
                                        ele.status = 0;
                                    }
                                    return ele;
                                }),
                            );
                        }
                    }}
                    size="small"
                />
            </td>
            <td>
                <div
                    style={{
                        ...itemStyle(item.status),
                        marginLeft: "25px",
                        textDecoration: item.status ? "" : "line-through",
                        textAlign: "left",
                        maxWidth: "30vw",
                        alignItems: "center",
                    }}
                >
                    {item?.keyword}
                    {!!item.is_user_added && (
                        <LightTooltip arrow title={"User added Key Term"}>
                            <div className={styles.userKeyterm}>U</div>
                        </LightTooltip>
                    )}
                </div>
            </td>
            <td>
                <div style={itemStyle(item.status, 30)}>
                    {`${item.min_times} - ${item.max_times}`}
                </div>
            </td>
            <td>
                <div
                    style={{
                        ...itemStyle(item.status, 40),
                        flexDirection: "column",
                        textAlign: "left",
                        marginLeft: "15px",
                    }}
                >
                    {item?.importance || "0"}/10
                    <ProgressBar
                        className={styles.importanceBar}
                        value={item.importance}
                        total={10}
                    />
                </div>
            </td>
            <td>
                <div
                    style={itemStyle(
                        item.status,
                        item.heading_presence < 1 ? 40 : 20,
                    )}
                >
                    {item.heading_presence > 2 ? (
                        <span className={styles.tagTypeHigh}>HIGH</span>
                    ) : item.heading_presence >= 1 ? (
                        <span className={styles.tagTypeLow}>LOW</span>
                    ) : (
                        <span className={styles.tagTypeNone}>-</span>
                    )}
                </div>
            </td>
        </tr>
    ));
};

export default ContextStep;
