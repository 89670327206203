import React, { useEffect, useState } from "react";
import { FaThumbsUp } from "react-icons/fa";
import { ImSad2 } from "react-icons/im";
import styles from "./AlertMessage.module.css";
import { IoCloseOutline } from "react-icons/io5";
import { displayAlert } from "../../../store/actions/alert";
import { useDispatch } from "react-redux";
import SVGIcon from "../SVGIcon";
export default function AlertMessage({
  text = "Something went wrong",
  coloredText,
  error,
  timeout = 3000,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(displayAlert({ alertMessage: "" }));
    }, timeout);
  }, []);

  return (
    <>
      {
        <div
          className={styles.alert}
          style={{
            borderLeft: error ? "5px solid red" : "5px solid lightgreen",
          }}
        >
          <div
            className={styles.closeIcon}
            onClick={() => dispatch(displayAlert({ alertMessage: "" }))}
          >
            <IoCloseOutline size={20} color={"#ababab"} />
          </div>
          <div>
            {error ? (
              <SVGIcon src="/AssetsV2/sorry-error-icon.svg" size={32} />
            ) : (
              <SVGIcon src="/AssetsV2/success-icon.svg" size={32} />
            )}
          </div>
          <p className={styles.alertMessage}>
            <span className={error ? styles.error : styles.success}>
              {!error ? "Success!" : "Sorry"}
            </span>
            ,&nbsp;
            {text.length > 120
              ? text.includes("Alert")
                ? text
                : "Something went wrong"
              : text}
          </p>
        </div>
      }
    </>
  );
}
