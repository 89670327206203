import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import AiTemplates from "../AiTemplates/AiTemplates";
import UserDocs from "../UserDocs/UserDocs";

import {
    Box,
    ClickAwayListener,
    Tab,
    Tabs,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import SVGIcon from "../../atoms/SVGIcon";
import VideoModal from "../NewDashboard/VideoModal";
import Button from "../../atoms/Button/Button";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { VscClose } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import userDocsHook from "../UserDocs/userDocsHook";
import { useParams } from "react-router-dom";
import Progress from "../../atoms/Progress/Progress";
import ModuleTemplate from "../../templates/ModuleTemplate/ModuleTemplate";

const useStylesTab = makeStyles((theme) => ({
    root: {
        opacity: 1,
        fontWeight: 400,
        minHeight: 30,
        width: 172,
    },

    selected: {
        color: "#014dc5",
        fontWeight: 600,
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "var(--primary-blue)",
    },
    root: {
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        // paddingInline: 20,
        minHeight: 30,
        marginTop: -38,
        width: "max-content",
        overflow: "hidden",
        paddingInline: 10,
        backgroundColor: "#fff",
    },
}));

const tabpanelStyle = {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 0,
};

const tabStyle = {
    minWidth: "unset",
    textTransform: "capitalize",
};

function Copywriter({ newDoc }) {
    let queryParams = new URLSearchParams(location.search);
    const [selected, setSelected] = useState(queryParams.get("docs") ? 1 : 0);
    const userVideo = useSelector((state) => state.video.docs);
    const [initiateClose, setInitiateClose] = useState(false);
    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();
    const [tabWiseStickyHeader, setTabWiseStickyHeader] = useState(<></>);
    const {
        loading,
        createDoc,
        hasMore,
        requestData,
        openDoc,
        docs,
        setLoading,
        setRequestData,
        restoreDoc,
        deleteDoc,
        creatingDoc,
    } = userDocsHook();

    useEffect(() => {
        if (newDoc) {
            const params = window.location.search;
            createDoc(params, "", true);
        }
    }, [newDoc]);

    const _Tabs = (
        <Tabs
            classes={classesTabs}
            value={selected}
            onChange={(_, value) => setSelected(value)}
        >
            <Tab
                classes={classesTab}
                label={
                    <LightTooltip
                        arrow
                        title="Choose from a wide selection of templates"
                    >
                        <div className={styles.tab}>
                            <SVGIcon src="/New UI/SVG/templates2.svg" />
                            Templates
                        </div>
                    </LightTooltip>
                }
                style={tabStyle}
            ></Tab>
            <Tab
                classes={classesTab}
                style={tabStyle}
                label={
                    <LightTooltip
                        arrow
                        title="Use our AI powered Smart Docs to create compelling content"
                    >
                        <div className={styles.tab}>
                            <SVGIcon src="/New UI/SVG/aiDocs2.svg" /> Documents
                        </div>
                    </LightTooltip>
                }
            ></Tab>
        </Tabs>
    );

    return creatingDoc ? (
        <Progress height={"100vh"} />
    ) : (
        <TabContext value={selected}>
            <ModuleTemplate
                sourceWhere={"docs"}
                HeaderChild={<></>}
                initiateClose={initiateClose}
                userVideo={userVideo}
                setInitiateClose={setInitiateClose}
                heading="Marketing Copywriter"
                subHeading=" Create high-quality content on the go with powerful
                AI writing tools"
                stickyHeaderChild={
                    <>
                        {_Tabs} {tabWiseStickyHeader}
                    </>
                }
                content={
                    <>
                        <TabPanel style={tabpanelStyle} value={0}>
                            <AiTemplates
                                setTabWiseStickyHeader={setTabWiseStickyHeader}
                                key={1}
                                bin={false}
                                newDoc={false}
                            />
                        </TabPanel>
                        <TabPanel style={tabpanelStyle} value={1}>
                            <UserDocs
                                {...{
                                    loading,
                                    createDoc,
                                    hasMore,
                                    requestData,
                                    openDoc,
                                    docs,
                                    setLoading,
                                    setRequestData,
                                    restoreDoc,
                                    deleteDoc,
                                    creatingDoc,
                                }}
                                key={2}
                                bin={false}
                                newDoc={false}
                                setTabWiseStickyHeader={setTabWiseStickyHeader}
                            />
                        </TabPanel>
                    </>
                }
            />
        </TabContext>
    );
}

export default Copywriter;
