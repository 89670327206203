import { TextareaAutosize } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { v4 } from "uuid";

import SVGIcon from "../../../atoms/SVGIcon";

import { Outline } from "../GoNuts/classes";
import {
    checkForUUID,
    droppableTypes,
    highlightNode,
} from "../GoNuts/cruiseConstant";
import { CRUISE_TYPES } from "../GoNuts/useCruiseHook";
import styles from "./EB.module.css";
import EBH3 from "./EBH3";
import { BRIEF_TYPES } from "./useEditorBriefHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { VscTrash } from "react-icons/vsc";

const EBH2 = ({
    item,
    index,
    readOnly = false,
    provided,
    dispatchEditorBrief,
    handleH2H3Change,
}) => {
    console.log("EBH2 EBH2", item);
    const [input, setInput] = useState(item?.data ? item?.data : "");

    const [isEditSubData, setIsEditSubData] = useState(false);
    const [showSubData, setShowSubData] = useState(
        item?.subData ? true : false,
    );
    const [subData, setSubData] = useState(item?.subData ? item.subData : "");

    const ref = useRef();

    useEffect(() => {
        if (checkForUUID(item?.id)) {
            highlightNode(ref?.current);
        }
    }, []);

    function handleEdit(text, onEnter = false) {
        if (!text.trim()) {
            deleteHeading();
            return;
        }
        setInput(input.trim());

        const heading = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: text,
            tag: Outline.tags.h2,
            outlineH3s: item.outlineH3s.map((h3) => ({ ...h3, id: v4() })),
        });
        dispatchEditorBrief({
            type: CRUISE_TYPES.EDIT_OUTLINES,
            index: index,
            field: Outline.type.heading,
            data: heading,
            id: item.id,
            tag: Outline.tags.h2,
        });
    }

    const handleH3H2Change = (h3Index) => {
        /**
         * @type {Outline}
         *  */
        const parentH2 = item;
        /**
         * @type {Outline}
         *  */
        const currH3 = item.outlineH3s[h3Index];
        const outlineH3s = [];

        for (let i = h3Index + 1; i < parentH2.outlineH3s.length; i++) {
            outlineH3s.push(
                new Outline({ ...parentH2.outlineH3s[i], id: v4() }),
            );
        }

        const outlineH2 = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: currH3.data,
            tag: Outline.tags.h2,
            outlineH3s: outlineH3s,
        });

        dispatchEditorBrief({
            type: CRUISE_TYPES.OUTLINE_INTER_CHANGE,
            index: h3Index,
            tag: Outline.tags.h3,
            data: outlineH2,
            h2Index: index,
            id: currH3.id,
        });
    };

    const _handleH2H3Change = () => {
        handleH2H3Change(index);
    };

    function deleteHeading() {
        dispatchEditorBrief({
            type: CRUISE_TYPES.DELETE_OUTLINES,
            index: index,
            field: Outline.type.heading,
            id: item.id,
            tag: Outline.tags.h2,
        });
    }

    function editSubData(data) {
        dispatchEditorBrief({
            type: BRIEF_TYPES.EDIT_SUBDATA,
            index: index,
            data: data,
            id: item.id,
            tag: Outline.tags.h2,
        });
    }

    return (
        <div className={styles.headingsParentContainer}>
            <div ref={ref} className={styles.itemMainContainer}>
                <div {...provided.dragHandleProps} className={styles.dragIcon}>
                    <SVGIcon
                        color="lightgray"
                        size={16}
                        src="/New UI/SVG/drag.svg"
                    />
                </div>
                <div
                    className={
                        styles.itemMainBox + " " + styles.itemMainBoxNoBorder
                    }
                >
                    <div className={styles.options}>
                        {!readOnly && (
                            <div className={styles.itemIconsContainer}>
                                <LightTooltip
                                    title={
                                        !readOnly
                                            ? item.subData
                                                ? "Click to edit note"
                                                : "Click to add note"
                                            : ""
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <div className={styles.itemIcon}>
                                        <SVGIcon
                                            size={13.5}
                                            onClick={() => {
                                                setShowSubData(true);
                                                setIsEditSubData(true);
                                            }}
                                            src="/New UI/SVG/edit-note.svg"
                                        />
                                    </div>
                                </LightTooltip>
                                <div className={styles.itemIcon}>
                                    <VscTrash
                                        onClick={() => {
                                            deleteHeading();
                                        }}
                                        color="#f94f2c"
                                        size={16}
                                    />
                                </div>
                            </div>
                        )}

                        <div
                            className={
                                styles.customItemHeading +
                                " " +
                                // styles[item.tag + "head"] +
                                " " +
                                (readOnly ? styles.readOnly : " ")
                            }
                        >
                            {/* {item.tag} */}

                            <div className={styles.tagDropDown}>
                                <div
                                    className={`${styles.tagDropDownItem} ${styles.tagDropDownItemSel}`}
                                >
                                    {Outline.tags.h2}
                                </div>
                                <div
                                    className={`${styles.tagDropDownItem}`}
                                    style={
                                        index == 0
                                            ? { cursor: "not-allowed" }
                                            : {}
                                    }
                                    onClick={() => {
                                        if (index != 0) _handleH2H3Change();
                                    }}
                                >
                                    {Outline.tags.h3}
                                </div>
                            </div>
                        </div>
                    </div>

                    <LightTooltip
                        title={!readOnly ? "Click to Edit" : ""}
                        arrow
                        placement="top"
                    >
                        <div
                            onClick={() => {
                                // if (!readOnly) setEditMainData(true);
                            }}
                            className={styles.itemData}
                        >
                            <TextareaAutosize
                                className={styles.mainDataTextArea}
                                disabled={false}
                                type="text"
                                placeholder={"Add FAQ here"}
                                maxLength={120}
                                value={input}
                                onChange={(e) => {
                                    console.log("inputtt", e);
                                    setInput(e.target.value);
                                }}
                                onBlur={(e) => {
                                    handleEdit(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        e.target.blur();
                                    }
                                }}
                            />
                        </div>
                    </LightTooltip>
                </div>
                {showSubData && (
                    <div
                        className={styles.itemsubDataBox}
                        onClick={() => {
                            // if (!readOnly) setEditsubDataBox(true);
                        }}
                    >
                        <div className={styles.subData}>
                            {!isEditSubData ? (
                                <div onClick={() => setIsEditSubData(true)}>
                                    {subData ? (
                                        subData
                                    ) : (
                                        <div className={styles.placeholder}>
                                            Add your note here
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <TextareaAutosize
                                    value={subData}
                                    autoFocus={true}
                                    placeholder={"Add your note here"}
                                    className={styles.subDataTextArea}
                                    onChange={(e) => setSubData(e.target.value)}
                                    onBlur={(e) => {
                                        setIsEditSubData(false);
                                        if (!e.target.value.trim())
                                            setShowSubData(false);
                                        editSubData(e.target.value.trim());
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key == "Enter") {
                                            e.target.blur();
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Droppable
                type={droppableTypes.outlineH3}
                droppableId={String(`${index}_${item.id}`)}
            >
                {(provided) => (
                    <div
                        className={styles.headingsDiv}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {item?.outlineH3s?.length
                            ? item.outlineH3s.map((h3Item, i) => {
                                  return (
                                      <Draggable
                                          key={h3Item.id}
                                          draggableId={h3Item.id?.toString()}
                                          index={i}
                                      >
                                          {(provided) => (
                                              <div
                                                  className={
                                                      styles.headingItemOuter
                                                  }
                                                  key={h3Item.id}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                              >
                                                  <EBH3
                                                      item={h3Item}
                                                      index={i}
                                                      readOnly={readOnly}
                                                      tag={h3Item.tag}
                                                      provided={provided}
                                                      h2Index={index}
                                                      dispatchEditorBrief={
                                                          dispatchEditorBrief
                                                      }
                                                      handleH3H2Change={
                                                          handleH3H2Change
                                                      }
                                                  />
                                              </div>
                                          )}
                                      </Draggable>
                                  );
                              })
                            : ""}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default EBH2;
