import { ClickAwayListener } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { VscTrash } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { getPastAsExpiredTime } from "../../../utility/validateFunctions";
import Button from "../../atoms/Button/Button";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import SVGIcon from "../../atoms/SVGIcon";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import AllTags from "../../organisms/Account/AllTags";
import { loadingText } from "../../organisms/DocCard/constants";
import {
    InProgressDoc,
    RecentlyDeletedCard,
} from "../../organisms/DocCard/DocCard";
import InProgressTimeline from "../../organisms/DocCard/InProgressTimeline/InProgressTimeline";
import TagLIst from "../../organisms/DocCard/TagLIst";
import { TagsContext } from "../TagsContext";
import styles from "./ClusterDoc.module.css";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { clusterMixpanelEvent } from "./clusterMixpanelEvents";

const deletionState = {
    deleted: "deleted",
    removed: "removed",
    none: "none",
    restore: "restored",
};

const ClusterDocRow = ({ data, openDoc, handleArchive, archived }) => {
    const [docTags, setDocTags] = useState(null);
    const [tagsModal, setTagsModal] = useState(false);
    const { tagsMap, addTag } = useContext(TagsContext);
    const role = useSelector((state) => state.auth.role);
    const { mixpanelTrack } = useMixpanelHook();
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );
    const [status, setStatus] = useState(
        data?.is_completed ? data.is_completed : 0,
    );
    useEffect(() => {
        if (data.is_completed)
            setTimeout(() => {
                setStatus(data.is_completed);
            }, 2000);
    }, [data.is_completed]);
    const [optionsModal, setOptionsModal] = useState(false);
    const [deleted, setDeleted] = useState(deletionState.none);

    useEffect(() => {
        try {
            setDocTags((_tags) => {
                if (!data.tags && !_tags) {
                    return [];
                }
                if (data.tags && !_tags) {
                    const tags = JSON.parse(data.tags).filter(
                        (tag) => tagsMap[tag],
                    );
                    return tags.map((id) => tagsMap[id]);
                } else if (_tags) {
                    return _tags
                        .map((tag) => tagsMap[tag.id])
                        .filter((tag) => tag);
                }
            });
        } catch (error) {}
    }, [tagsMap]);

    useEffect(() => {
        if (archived) {
            if (deleted == deletionState.deleted) {
                handleArchive(data.topic_id, 2);
            }
            if (deleted == deletionState.restore) {
                handleArchive(data.topic_id, 1);
            }
        } else {
            if (deleted == deletionState.deleted) {
                handleArchive(data.topic_id, 1);
            }
            if (deleted == deletionState.restore) {
                handleArchive(data.topic_id, 2);
            }
        }
    }, [deleted]);

    return (
        <tr
            className={styles.clusterDocRow}
            style={deleted == deletionState.removed ? { display: "none" } : {}}
            onClick={(e) => {
                if (
                    deleted != deletionState.deleted &&
                    data.is_completed != 2 &&
                    data.is_completed != 4
                ) {
                    e.stopPropagation();
                    mixpanelTrack(clusterMixpanelEvent.OPEN_REPORT);
                    openDoc(data.topic_id);
                }
            }}
        >
            {deleted == deletionState.deleted ? (
                <RecentlyDeletedCard
                    archived={archived}
                    setDeleted={setDeleted}
                    title={data.search_keyword}
                />
            ) : (
                <>
                    <td className={styles.leftContainer}>
                        <div className={styles.title}>
                            <SVGIcon size={16} src={"/New UI/SVG/seoDoc.svg"} />

                            <LightTooltip
                                arrow
                                title={
                                    data?.search_keyword.length > 30
                                        ? data?.search_keyword
                                        : ""
                                }
                                placement="bottom"
                            >
                                <span
                                    onClick={(e) => {
                                        data.is_completed == 2 ||
                                        data.is_completed == 4
                                            ? e.preventDefault()
                                            : openDoc(data.topic_id);
                                    }}
                                >
                                    {data?.search_keyword.slice(0, 30)}
                                    {data?.search_keyword.length > 30
                                        ? "..."
                                        : ""}
                                </span>
                            </LightTooltip>
                            {data.is_sample && (
                                <span className={styles.sample}>
                                    Sample Report
                                </span>
                            )}
                        </div>
                        {!status && (
                            <InProgressDoc doc={data} isCluster={true} />
                        )}
                        <div className={styles.infoContainer}>
                            {data && (
                                <span
                                    style={{
                                        width: "90%",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "flex",
                                    }}
                                >
                                    <SVGIcon
                                        size={12}
                                        src="/New UI/SVG/locationPin.svg"
                                    ></SVGIcon>

                                    <span className={styles.countryName}>
                                        {" "}
                                        {data?.search_country}
                                    </span>
                                </span>
                            )}
                            {!archived && tagsModal && docTags && (
                                <AllTags
                                    report_id={data.topic_id}
                                    doc_id={""}
                                    _tags={docTags}
                                    setTags={setDocTags}
                                    setTagsModal={setTagsModal}
                                    setReports={() => {}}
                                    cluster={data.topic_id}
                                ></AllTags>
                            )}
                        </div>
                        {!archived &&
                        docTags &&
                        !data.is_sample &&
                        data.is_completed != 0 ? (
                            <TagLIst
                                docId={data.topic_id}
                                seoDoc={data.topic_id}
                                addTag={addTag}
                                setDocTags={setDocTags}
                                setTagsModal={setTagsModal}
                                list={docTags}
                                cluster={data.topic_id}
                            />
                        ) : (
                            <div style={{ height: 18 }}></div>
                        )}
                    </td>
                    <td className={styles.docCreatedAt}>
                        <span className={styles.time}>
                            {data.created_at == "Expired"
                                ? "Just Now"
                                : data.created_at}
                        </span>
                        {/* <SVGIcon src="/New UI/SVG/AiOutlineClockCircle.svg" />{" "}
          {data.created_at} */}
                    </td>
                    <td className={styles.docOption}>
                        {!archived ? (
                            <div>
                                {data.is_completed == 2 ||
                                data.is_completed == 4 ? (
                                    <div className={styles.failed}>
                                        <b>Not Processed</b>
                                        <br />
                                        {data.is_excel_sheet == 1
                                            ? "This can happen if we couldn't find any information for the keywords given"
                                            : "This can happen if your input keyword is too narrow and our engine couldn't find related terms for the primary keyword "}
                                    </div>
                                ) : (
                                    <Button
                                        text={"Open Report"}
                                        width={158}
                                        height={34}
                                        borderRadius={"2px"}
                                        style={{
                                            backgroundColor: "#f6f7fb",
                                            border: "solid 1px #e9ecf4",
                                            color: "#1f4cbe",
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            margin: "auto",
                                        }}
                                        handler={(e) => {
                                            e.stopPropagation();
                                            mixpanelTrack(
                                                clusterMixpanelEvent.OPEN_REPORT,
                                            );
                                            openDoc(data.topic_id);
                                        }}
                                    />
                                )}
                                {/* <div>Last Updated{" "} {data.content_updated_at}</div> */}
                            </div>
                        ) : (
                            <Button
                                text={"Restore"}
                                width={158}
                                height={34}
                                borderRadius={"2px"}
                                style={{
                                    backgroundColor: "#f6f7fb",
                                    border: "solid 1px #e9ecf4",
                                    color: "#1f4cbe",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    margin: "auto",
                                }}
                                handler={(e) => {
                                    e.stopPropagation();
                                    setDeleted(deletionState.deleted);
                                }}
                            />
                        )}
                        <div>
                            {company_user_type != 2 &&
                                !archived &&
                                !data.is_sample && (
                                    <span className={styles.options}>
                                        <SVGIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOptionsModal(true);
                                            }}
                                            color="lightgray"
                                            size={24}
                                            src="/New UI/SVG/dots.svg"
                                        ></SVGIcon>
                                        {optionsModal && (
                                            <ClickAwayListener
                                                onClickAway={() =>
                                                    setOptionsModal(false)
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.optionModal
                                                    }
                                                    onMouseLeave={() =>
                                                        setOptionsModal(false)
                                                    }
                                                >
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setDeleted(
                                                                deletionState.deleted,
                                                            );
                                                        }}
                                                    >
                                                        <VscTrash
                                                            size={16}
                                                            color="red"
                                                        />
                                                        Delete
                                                    </span>
                                                </div>
                                            </ClickAwayListener>
                                        )}
                                    </span>
                                )}
                        </div>
                    </td>
                </>
            )}
        </tr>
    );
};

export default ClusterDocRow;
