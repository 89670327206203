export const FixItAllowed = {
    "Key Terms": {
        isEnabled: true,
        tagline: "Improve SEO score by fixing key terms",
        panelName: "Key Term Assistant",
    },
    "Content Depth": { isEnabled: false, tagline: "" },
    "Keyword Density": { isEnabled: false, tagline: "" },
    "H1 Heading": {
        isEnabled: true,
        tagline: "Improve SEO score by fixing H1 Heading",
        panelName: "H1 Heading",
    },
    "H2-H6 Heading": { isEnabled: false, tagline: "" },
    "Meta Tags": {
        isEnabled: true,
        tagline: "Improve SEO score by fixing Meta Tags",
        panelName: "Meta Tags",
    },
    "Featured Snippet": {
        isEnabled: true,
        tagline: "Improve SEO score by fixing Feature Snippet",
        panelName: "Fix It - Feature Snippet",
    },
    Links: {
        isEnabled: false,
        tagline: "Improve SEO score by fixing Internal Linking",
        panelName: "LINKS",
    },
    URL: {
        isEnabled: true,
        tagline: "Improve SEO score by fixing URL",
        panelName: "URL",
    },
};

export const FixItSections = {
    KEY_TERMS: "Key Term Assistant",
    CONTENT_DEPTH: "",
    KEYWORD_DENSITY: "",
    H1_HEADING: "H1 Heading",
    H2_H6_HEADING: "",
    META_TAGS: "Meta Tags",
    FEATURED_SNIPPET: "Fix It - Feature Snippet",
    LINKS: "LINKS",
    URL: "URL",
};
