import { loadStripe } from "@stripe/stripe-js";

export function customLoadStripe(params) {
    try {
        let a = loadStripe(params);
        return a;
    } catch (error) {
        console.log(error);
        return {};
    }
}
