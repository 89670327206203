import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ScriptableContext,
} from "chart.js";
import { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";

const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
    if (tooltipEl?.length > 0) {
        tooltipEl = tooltipEl[0];
    }
    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#fff";
        tooltipEl.style.borderRadius = "18px";
        tooltipEl.style.color = "black";
        tooltipEl.style.boxShadow = "1px 1px 6px rgba(0, 0, 0, 0.25)";

        tooltipEl.style.opacity = 1;
        tooltipEl.style.width = "max-content";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";

        const table = document.createElement("div");
        table.setAttribute("id", "ctl");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);

const LineChart = ({
    label,
    data1,
    data2,
    noTick = false,
    min = 0,
    max = 25,
    width,
    height,
    noLabels = false,
    noGrid = false,
    noTooltip = false,
    showAllLabels = false,
    lineColor = "",
    gradientValues,
    gradientValues2,
    yType = "",
    pointRadius = 3,
    customTooltip = false,
    tooltipLabel = "Position #",
    gradientRange = 380,
    gradientRange2 = 380,
    lineThickness = 2,
    lineHeight = "2px",
    isReverseY = false,
    dashedBorder = [],
}: {
    label: string[];
    data1: any;
    data2?: any;
    noTick?: boolean;
    noLabels?: boolean;
    noGrid?: boolean;
    noTooltip?: boolean;
    min?: number;
    max?: number;
    width?: any;
    height?: any;
    showAllLabels?: boolean;
    lineColor?: string;
    gradientValues?: { start: string; end: string };
    gradientValues2?: { start: string; end: string };
    yType?: string;
    pointRadius?: number;
    customTooltip?: boolean;
    tooltipLabel?: string;
    gradientRange?: number;
    gradientRange2?: number;
    lineThickness?: number;
    lineHeight?: string;
    isReverseY?: boolean;
    dashedBorder?: number[];
}) => {
    const externalTooltipHandler = (context: any) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        let isDashedData =
            tooltip?.dataPoints[0]?.datasetIndex == 1 ? true : false;
        // Set Text
        if (isDashedData) {
            return;
        }
        console.log("externalTooltipHandler", isDashedData);
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b: any) => b.lines);
            const index = tooltip._tooltipItems.map((a: any) => a.parsed.x + 1);
            console.log(index);
            const tooltipDiv = document.createElement("div");
            titleLines.forEach((title: string, i: number) => {
                const itemRow = document.createElement("div");
                itemRow.style.borderWidth = "0";
                itemRow.style.display = "flex";
                itemRow.style.justifyContent = "center";
                itemRow.style.alignItems = "center";
                itemRow.style.gap = "6px";
                itemRow.style.padding = "2px 6px";

                const meta = document.createElement("div");
                meta.style.borderWidth = "0";
                meta.style.color = "#3F5575";
                meta.style.fontWeight = "600";
                meta.style.fontSize = "12px";
                meta.innerText = tooltipLabel;
                if (isDashedData) {
                    meta.innerText = "No rank data \n for this week";
                } else {
                    if (yType == "%") {
                        meta.innerText = tooltipLabel + index;
                    } else {
                        meta.innerText =
                            tooltipLabel +
                            Math.round((bodyLines[0] / 10) * 100) / 10 +
                            "";
                    }
                }

                const value = document.createElement("div");
                value.style.borderWidth = "0";
                value.style.color = "#014DC5";
                value.style.fontWeight = "600";
                value.style.fontSize = "12px";

                if (yType == "%") {
                    value.innerText =
                        Math.round((bodyLines[0] / 10) * 100) / 10 + "%";
                } else {
                    value.innerText = "";
                }

                itemRow.appendChild(meta);
                itemRow.appendChild(value);
                tooltipDiv.appendChild(itemRow);
            });

            const tableBody = document.createElement("tbody");
            bodyLines.forEach((body: string, i: number) => {
                console.log("body", body);
                console.log("i", i);

                const colors = tooltip.labelColors[i];

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "#fff";
                tr.style.borderWidth = "0";

                const td = document.createElement("td");
                td.style.borderWidth = "0";

                const text = document.createTextNode(body);

                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = document.getElementById("ctl");
            // Remove old children
            while (tableRoot?.firstChild) {
                tableRoot.firstChild.remove();
            }
            // Add new children
            tableRoot?.appendChild(tooltipDiv);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        let left = positionX + tooltip.caretX;
        let right = positionY + (tooltip.caretY - 40);
        const screenSize = window.innerWidth;
        if (yType == "%") {
            if (screenSize <= 1220) {
                if (left > 340) {
                    left = left - (left - 300);
                }
            } else if (screenSize <= 1440) {
                if (left > 340) {
                    left = left - (left - 340);
                }
            } else if (screenSize <= 1636) {
                if (left > 390) {
                    left = left - (left - 400);
                }
            } else if (screenSize <= 1800) {
                if (left > 420) {
                    left = left - (left - 450);
                }
            } else if (screenSize <= 2000) {
                if (left > 500) {
                    left = left - (left - 520);
                }
            }
        }
        tooltipEl.style.left = left + "px";
        tooltipEl.style.top = positionY + (tooltip.caretY - 40) + "px";
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding =
            tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };
    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxis: {
                reverse: isReverseY,
                min: min,
                max: max,
                stepSize: 1,
                grid: {
                    display: noGrid ? false : true,
                    drawBorder: false,
                    color: "#F1F1F1",
                    tickColor: "transparent",
                },
                ticks: {
                    beginAtZero: true,
                    // @ts-ignore
                    callback: function (context) {
                        if (!noLabels) return context + `${yType ? yType : ""}`;
                    },
                    color: "#ACB3BF",
                    major: {
                        enabled: true,
                    },
                    // color: "#29394a",
                    font: {
                        // weight: "500",
                        // size: "12px",
                        // family: "Inter",
                    },
                },
            },
            xAxis: {
                grid: {
                    drawBorder: false,
                    color: "#F1F1F1",
                    tickColor: "transparent",
                    // display: false, // Set to false to remove the vertical grid lines
                },
                ticks: {
                    maxRotation: 0, // Prevent label rotation
                    beginAtZero: true,
                    font: {
                        family: "Inter !important",
                        size: "0px", // Adjust the size as needed
                        lineHeight: lineHeight,
                        style: "Inter !important", // Set the font style to normal
                    },
                    color: "#ACB3BF",
                    ...(!showAllLabels && {
                        //@ts-ignore
                        callback: (value, index) => {
                            let showLabels = [
                                "1",
                                "",
                                "",
                                "4",
                                "",
                                "",
                                "7",
                                "",
                                "",
                                "10",
                                "",
                                "",
                                "13",
                                "",
                                "",
                                "16",
                                "",
                                "",
                                "",
                                "20",
                            ];
                            if (!noLabels)
                                return showLabels.includes(
                                    (value + 1).toString(),
                                )
                                    ? value + 1
                                    : " ";
                        },
                    }),
                },
            },
        },
        plugins: {
            elements: {
                point: {
                    borderWidth: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                    radius: pointRadius,
                },
            },

            tooltip: {
                enabled: customTooltip
                    ? false
                    : noTooltip
                    ? false
                    : !noTick
                    ? false
                    : true,
                position: "nearest",
                ...(customTooltip && { external: externalTooltipHandler }),
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "",
            },
        },
    };
    const data = {
        labels: label,
        datasets: [
            {
                data: [...data1],
                borderColor: lineColor ? lineColor : "#1F4CBE",
                pointHoverRadius: pointRadius ? pointRadius : 0,
                fill: "start",
                pointRadius: pointRadius ? pointRadius : 0,
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(
                        0,
                        0,
                        0,
                        gradientRange,
                    );
                    gradient.addColorStop(
                        0,
                        gradientValues?.hasOwnProperty("start")
                            ? gradientValues.start
                            : "rgba(1, 77, 197, .4)",
                    );
                    gradient.addColorStop(
                        1,
                        gradientValues?.hasOwnProperty("end")
                            ? gradientValues.end
                            : "rgba(1, 77, 197, 0)",
                    );
                    return gradient;
                },
                borderWidth: lineThickness,
                animation: false,
            },
            {
                //@ts-ignore
                ...(data2?.length && {
                    data: [...data2],
                    ...(dashedBorder.length > 0
                        ? { borderDash: dashedBorder }
                        : {}),
                    borderColor:
                        dashedBorder.length > 0
                            ? "#acb3bf"
                            : lineColor
                            ? lineColor
                            : "#1F4CBE",
                    pointHoverRadius: pointRadius ? pointRadius : 0,

                    pointRadius: 0,
                    spanGaps: true,
                    backgroundColor: (context: ScriptableContext<"line">) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(
                            0,
                            0,
                            0,
                            gradientRange2,
                        );
                        gradient.addColorStop(
                            0,
                            gradientValues2?.hasOwnProperty("start")
                                ? gradientValues2.start
                                : "rgba(1, 77, 197, .4)",
                        );
                        gradient.addColorStop(
                            1,
                            gradientValues2?.hasOwnProperty("end")
                                ? gradientValues2.end
                                : "rgba(1, 77, 197, 0)",
                        );
                        return gradient;
                    },
                    borderWidth: lineThickness,
                    animation: false,
                }),
            },
        ],
    };

    return <Line options={options} data={data} height={height ? height : ""} />;
};

export default LineChart;
