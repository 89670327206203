import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import Version from "./Version";
import useVersions from "./useVersions";
import { Skeleton } from "@material-ui/lab";
import Spacer from "../../../atoms/Spacer/Spacer";

function VersionList({}) {
  const [currPage, setCurrPage] = useState(1);
  const [selectedVersion, setSelectedVersion] = useState({
    version: 0,
    index: 0,
  });

  const { hasMore, lists, loading, error } = useVersions(
    currPage,
    selectedVersion
  );
  useEffect(() => {
    if (lists.length > 0) {
      setSelectedVersion({ version: lists[0].version, index: 0 });
    }
  }, [lists]);

  const observer = useRef();
  const lastVersionElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrPage((currPage) => currPage + 1);
          console.log("visible");
        }
      });
      console.log(observer);
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      {loading ? (
        <div>
          <Skeleton variant="rect" height={40} />
          <Spacer spacing="20px" />
          <Skeleton variant="rect" height={40} />
          <Spacer spacing="20px" />
          <Skeleton variant="rect" height={40} />
          <Spacer spacing="20px" />
          <Skeleton variant="rect" height={40} />
        </div>
      ) : (
        <div>
          {lists.map((item, index) => {
            if (lists.length === index + 1) {
              return (
                <div
                  ref={lastVersionElementRef}
                  key={Math.random(134324) * item.version}
                >
                  <Version
                    item={item}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    key={item.version}
                    index={index}
                  />
                </div>
              );
            } else {
              return (
                <Version
                  item={item}
                  selectedVersion={selectedVersion}
                  setSelectedVersion={setSelectedVersion}
                  key={item.version}
                  index={index}
                />
              );
            }
          })}
        </div>
      )}
    </>
  );
}

export default VersionList;
