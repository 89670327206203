export function getDashboardPayload({
    unassigned,
    type,
    search_text = "",
    userKey = "",
    page = 1,
    statuses = [],
    variants = [],
    userId,
    tags,
}) {
    let payload = null;

    if (type == "queue") {
        payload = {
            page,
            search_text,
            statusKey: "1",
            userKey: userId?.id,
        };
    } else if (type == "delivered") {
        payload = {
            page,
            search_text,
            statusKey: "2",
            userKey: userId?.id,
        };
    } else {
        payload = {
            page,
            search_text,
            statusKey: "3",
            userKey: userId?.id,
        };
    }
    if (statuses.length > 0) {
        payload = { ...payload, status: statuses };
    }
    if (variants.length > 0) {
        payload = { ...payload, variantId: variants };
    }
    if (tags.length > 0) {
        payload = { ...payload, tag: tags };
    }
    if (unassigned == true) {
        payload = { ...payload, unassigned: true, tag: [1, 2, 3] };
    }
    return payload;
}

export const scrollToTop = () => {
    if (document.getElementById("scrollableDiv"))
        document.getElementById("scrollableDiv").scrollTop = 0;
};

export const toTitle = (title) => {
    try {
        if (title.length == 1) {
            return title.toUpperCase();
        }

        return title.slice(0, 1).toUpperCase() + title.slice(1, title.length);
    } catch {
        return title;
    }
};

const swapWithAdjacent = (arr) => {
    for (let index = 0; index < arr.length; index += 2) {
        try {
            if (arr[index + 1]) {
                const temp = arr[index];
                arr[index] = arr[index + 1];
                arr[index + 1] = temp;
            }
        } catch (err) {
            console.log(err);
        }
    }
};

export const jumbleArray = (list = []) => {
    let arr = new Array(list.length);

    let randomIndex = Math.random().toFixed(1) * 10;

    for (let index = 0; index < arr.length; index++) {
        arr[randomIndex % arr.length] = list[index];
        randomIndex += 1;
    }

    swapWithAdjacent(arr);

    return arr;
};

export function resetScroll(element) {
    if (!element) return;

    const scrollBehavior = element.style.scrollBehavior;

    element.style.scrollBehavior = "unset";
    element.scrollTop = 0;
    element.style.scrollBehavior = scrollBehavior;
}

export const countWords = (str = "") => {
    const num = str.trim() ? str.trim().split(/[\s,?:|]+/).length : 0;

    return num;
};

export const checkDigit = (val) => {
    const regex = /^(\d|-)?(\d|,)*\.?\d*$/;

    return regex.test(Math.abs(val));
};
