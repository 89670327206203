import React, { useContext, useEffect, useState } from "react";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../atoms/SVGIcon";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import {
    DASHBOARD,
    REFER_CARD,
    getTrailText,
} from "../../Pages/NewDashboard/constants";
import styles from "./ChatWidget.module.css";
import { setChatWidget } from "../../../store/actions/state";
import { useDispatch, useSelector } from "react-redux";

const INVALID_SCREENS = {
    DOC_EDITOR: "/docs/editor",
    CREATOR_REPORT: "/creator/report",
    CREATOR_EDITOR: "/creator/editor",
    AUDIT_EDITOR: "/audit/editor",
    CLUSTER_REPORT: "/cluster/report",
    WEBTUNE: "/webtune",
    HUMANIZER_EDITOR: "/ai-humanizer/editor",
};

const ChatWidget = ({ handler = () => {}, isShow = false, userName = "" }) => {
    const { setState } = useContext(OnBoardingContext);
    const { mpTrackChatWidget, mixpanelTrack } = useMixpanelHook();
    const { isFreeForever } = useUserAccessHook();
    const [isReturn, setIsReturn] = useState(true);
    const goTo = useNavigation();
    const { isTeamMember, planExpiresDate, planTrial } = useUserAccessHook();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isShow) {
            window.userengage && window.userengage("widget.close");
        } else {
            dispatch(setChatWidget(false));
        }
    }, [isShow]);

    const RESOURCE_HUB = [
        {
            imgSrc: "resource_help.svg",
            title: "Help Center",
            url: "https://help.scalenut.com/",
        },
        {
            imgSrc: "resource_bootcamp.svg",
            title: "Bootcamp",
            url: "https://www.scalenut.com/bootcamp/introduction-to-scalenut",
        },
        {
            imgSrc: "resource_community.svg",
            title: "Community",
            url: "https://m.facebook.com/groups/2992138341053969?view=group",
        },
    ];
    const REWARDS = [
        // {
        //     imgSrc: "rewards_refer.svg",
        //     title: "Refer a Friend",
        //     url: async (set, setShow) => {
        //         if (!window.location.pathname.includes("dashboard")) {
        //             await goTo(DASHBOARD);
        //         }
        //         set((ps) => ({ ...ps, focusOn: REFER_CARD }));
        //         setShow(false);
        //     },
        // },
        {
            imgSrc: "rewards_earn.svg",
            title: <>Earn Credits</>,
            url: "https://www.scalenut.com/earn-credits",
        },
        {
            imgSrc: "rewards_affiliate.svg",
            title: "Affiliate Program",
            url: "https://www.scalenut.com/affiliate",
        },
        {
            imgSrc: "rewards_sales.svg",
            title: "1:1 Demo",
            url: "https://www.scalenut.com/schedule-demo",
        },
    ];
    const SUPPORT = [
        {
            imgSrc: "support_chat.svg",
            title: "Chat with us",
            url: (setShow) => {
                setShow(false);
                window.userengage && window.userengage("widget.show");
                window.userengage && window.userengage("widget.open");
            },
        },
        {
            imgSrc: "support_email.svg",
            title: "Email us",
            url: () => {
                window.open("mailto:wecare@scalenut.com", "_blank");
            },
        },
        {
            imgSrc: "support_onboarding.svg",
            title: "Onboarding Support",
            url: "https://calendly.com/d/5gx-7jm-fw2/scalenut-demo?utm_source=product&utm_medium=chatw",
        },
    ];

    useEffect(() => {
        const handleReturn = (path) => {
            console.log(path, "handleReturn");
            if (
                path.includes(INVALID_SCREENS.DOC_EDITOR) ||
                path.includes(INVALID_SCREENS.CREATOR_REPORT) ||
                path.includes(INVALID_SCREENS.CREATOR_EDITOR) ||
                path.includes(INVALID_SCREENS.AUDIT_EDITOR) ||
                path.includes(INVALID_SCREENS.WEBTUNE) ||
                (path.includes(INVALID_SCREENS.HUMANIZER_EDITOR) && !isShow)
            ) {
                setIsReturn(false);
            } else {
                setIsReturn(true);
            }
        };
        handleReturn(window.location.pathname);
    }, [window.location.pathname, isShow]);

    const mpTrackChatWidgetSubEvents = (eventName) => {
        mpTrackChatWidget(
            ("chat widget- " + eventName + " clicked").toLowerCase(),
        );
    };

    useEffect(() => {
        if (isShow) {
            mpTrackChatWidget("chat widget- opened");
        }
    }, [isShow]);

    const { isMobile } = useSelector((state) => state.state);

    return isReturn ? (
        <div
            className={styles.chatWrapper}
            style={{ display: isMobile ? "none" : "" }}
        >
            {isShow ? (
                <div className={styles.container}>
                    <span
                        className={styles.closeChat}
                        onClick={() => {
                            handler(!isShow);
                        }}
                    >
                        <SVGIcon
                            src={"/New UI/SVG/VscClose.svg"}
                            size={20}
                            style={{ color: "inherit" }}
                        />
                    </span>
                    <div className={styles.welcomeUser}>
                        <div className={styles.user_name}>Hey {userName}</div>
                        <div className={styles.user_ask}>
                            How can we help you today?
                        </div>
                    </div>
                    <div className={styles.chatOptions}>
                        <div className={styles.section}>
                            <div className={styles.section_name}>
                                Resource Hub
                            </div>
                            <div className={styles.section_options}>
                                {RESOURCE_HUB.map((resource) => {
                                    return (
                                        <div
                                            className={styles.section_item}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                    resource.url,
                                                    "_blank",
                                                );
                                                if (
                                                    getTrailText(
                                                        planExpiresDate,
                                                    ) == 7 &&
                                                    planTrial
                                                ) {
                                                    mixpanelTrack(
                                                        "Day 0 Dashboard",
                                                        {
                                                            action: `Chat Support - ${resource.title}`,
                                                        },
                                                    );
                                                }
                                                mpTrackChatWidgetSubEvents(
                                                    resource.title,
                                                );
                                            }}
                                        >
                                            <div className={styles.icon}>
                                                <SVGIcon
                                                    src={`/New UI/ChatWidget/${resource.imgSrc}`}
                                                    size={22}
                                                    color={"#014DC5"}
                                                />
                                            </div>
                                            <div className={styles.title}>
                                                {resource.title}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {!isTeamMember ? (
                            <div className={styles.section}>
                                <div className={styles.section_name}>
                                    Rewards
                                </div>
                                <div className={styles.section_options}>
                                    {REWARDS.map((reward) => {
                                        if (
                                            (reward.title == "Sales" &&
                                                !isFreeForever) ||
                                            (reward.title == "Affiliate" &&
                                                isFreeForever)
                                        ) {
                                            return <></>;
                                        } else
                                            return (
                                                <div
                                                    className={
                                                        styles.section_item
                                                    }
                                                    onClick={(e) => {
                                                        if (
                                                            getTrailText(
                                                                planExpiresDate,
                                                            ) == 7 &&
                                                            planTrial
                                                        ) {
                                                            mixpanelTrack(
                                                                "Day 0 Dashboard",
                                                                {
                                                                    action: `Chat Support - ${reward.title}`,
                                                                },
                                                            );
                                                        }
                                                        mpTrackChatWidgetSubEvents(
                                                            reward.title,
                                                        );
                                                        e.preventDefault();
                                                        if (
                                                            typeof reward.url ==
                                                            "string"
                                                        ) {
                                                            window.open(
                                                                reward.url,
                                                                "_blank",
                                                            );
                                                        } else {
                                                            reward.url(
                                                                setState,
                                                                handler,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={styles.icon}
                                                    >
                                                        <SVGIcon
                                                            src={`/New UI/ChatWidget/${reward.imgSrc}`}
                                                            size={22}
                                                            color={"#014DC5"}
                                                        />
                                                    </div>
                                                    <div
                                                        className={styles.title}
                                                    >
                                                        {reward.title}
                                                    </div>
                                                </div>
                                            );
                                    })}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className={styles.section}>
                            <div className={styles.section_name}>Support</div>
                            <div className={styles.support_options}>
                                {SUPPORT.map((support) => {
                                    return (
                                        <div
                                            className={styles.support_item}
                                            onClick={(e) => {
                                                if (
                                                    getTrailText(
                                                        planExpiresDate,
                                                    ) == 7 &&
                                                    planTrial
                                                ) {
                                                    mixpanelTrack(
                                                        "Day 0 Dashboard",
                                                        {
                                                            action: `Chat Support - ${support.title}`,
                                                        },
                                                    );
                                                }
                                                mpTrackChatWidgetSubEvents(
                                                    support.title,
                                                );
                                                e.preventDefault();
                                                if (
                                                    typeof support.url ==
                                                    "string"
                                                ) {
                                                    window.open(
                                                        support.url,
                                                        "_blank",
                                                    );
                                                } else {
                                                    support.url(handler);
                                                }
                                            }}
                                        >
                                            <div className={styles.icon}>
                                                <SVGIcon
                                                    src={`/New UI/ChatWidget/${support.imgSrc}`}
                                                    size={20}
                                                    color={"#014DC5"}
                                                />
                                            </div>
                                            <div className={styles.title}>
                                                {support.title}
                                            </div>
                                            <span className={styles.rightArrow}>
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/ChevronRight.svg"
                                                    }
                                                    color={"#CACACA"}
                                                    size={12}
                                                />
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}

            <div
                className={styles.chatWidget_icon}
                onClick={(e) => {
                    e.stopPropagation();
                    if (getTrailText(planExpiresDate) == 7 && planTrial) {
                        mixpanelTrack("Day 0 Dashboard", {
                            action: `Chat Support Icon`,
                        });
                    }
                    handler(!isShow);
                }}
            >
                <span className={styles.logoFix}>
                    <SVGIcon src={"/New UI/SVG/ScalenutWhite.svg"} size={25} />
                </span>
                <img
                    src={
                        "https://media.user.com/settings/fpdmq0-https-scalenut-com/3_7DtVOrj.png"
                    }
                    alt={"custom chat"}
                    width={"100%"}
                    height={"100%"}
                />
            </div>
        </div>
    ) : (
        <></>
    );
};

export default ChatWidget;
