import React, { useState } from "react";
import styles from "./styles.module.css";
import { FcGoogle } from "react-icons/fc";
import Button from "../../atoms/Button/Button";

const GSCSimpleLogin = ({
    googleCallback = (obj) => {},
    setLoading,
    loading = false,
    screen = "",
    height = 44,
    width = "max-content",
    btnText = "Connect Domain with GSC",
}) => {
    const handleLogin = () => {
        setLoading(true);
        let iLink = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: googleCallback,
            error_callback: () => {
                setLoading(false);
            },
        });
        iLink.requestCode();
    };
    // igornoe
    return (
        <Button
            loading={loading}
            handler={() => {
                handleLogin();
            }}
            height={height}
            Icon={() => (
                <div className={styles.iconWrapper}>
                    <FcGoogle size={`${height - 14}px`} />
                </div>
            )}
            secondary={true}
            text={btnText}
            iconReverse={true}
            width={width}
            customClass={styles.customGoogleLogin}
        />
    );
};

export default GSCSimpleLogin;
