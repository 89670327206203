import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
    if (tooltipEl?.length > 0) {
        tooltipEl = tooltipEl[0];
    }
    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#fff";
        tooltipEl.style.borderRadius = "18px";
        tooltipEl.style.color = "black";
        tooltipEl.style.boxShadow = "1px 1px 6px rgba(0, 0, 0, 0.25)";

        tooltipEl.style.opacity = 1;
        tooltipEl.style.width = "max-content";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";

        const table = document.createElement("div");
        table.setAttribute("id", "ctl");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export function PagesLineGraph({
    dataSet = [],
    labels,
    min,
    max,
    tooltipLabel = "Cannib",
    isReverseY = false,
    stepSize = 5,
}: {
    dataSet: any[];
    labels: string[];
    min?: number;
    max?: number;
    stepSize?: number;
    tooltipLabel?: string;
    isReverseY?: boolean;
}) {
    const externalTooltipHandler = (context: any) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        let lengthTotalPoint = 0;
        let maxPageLength = 0;
        console.log("isDashedData", tooltip?.dataPoints[0]);
        let isDashedData =
            tooltip?.dataPoints[0]?.datasetIndex == 1 ? true : false;
        // Set Text

        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b: any) => b.lines);
            const index = tooltip._tooltipItems.map((a: any) => a.parsed.x + 1);
            const labelColor = tooltip?.labelColors[0].borderColor || {};
            const dataPoints: { tValue: any; tPage: any }[] =
                tooltip.dataPoints.map((b: any) => {
                    if (b?.dataset?.page?.length) {
                        maxPageLength = Math.max(
                            maxPageLength,
                            b?.dataset?.page?.split("/")?.at(-1)?.length,
                        );
                    }

                    return {
                        tValue: b.formattedValue,
                        tPage: b?.dataset?.page
                            ? b?.dataset?.page.includes("/")
                                ? ".../" + b?.dataset?.page?.split("/")?.at(-1)
                                : b?.dataset?.page?.split("/")?.at(-1)
                            : null,
                    };
                });
            const labelColors = tooltip.labelColors.map(
                (b: any) => b.borderColor,
            );
            lengthTotalPoint = dataPoints.length / 4;
            const tooltipDiv = document.createElement("div");
            titleLines.forEach((title: string, i: number) => {
                const itemRow = document.createElement("div");
                itemRow.style.borderWidth = "0";
                itemRow.style.display = "flex";
                itemRow.style.flexDirection = "column";
                itemRow.style.justifyContent = "center";
                itemRow.style.alignItems = "flex-start";
                itemRow.style.gap = "3px";
                itemRow.style.padding = "4px 8px";

                const meta = document.createElement("div");
                meta.style.borderWidth = "0";
                meta.style.color = "#3F5575";
                meta.style.fontWeight = "400";
                meta.style.fontSize = "11px";
                meta.innerText = tooltipLabel;

                meta.innerText = moment(titleLines, "DD/MM/YYYY").format(
                    "ddd, DD MMM",
                );

                const value = document.createElement("div");
                value.style.display = "flex";
                value.style.flexDirection = "column";
                value.style.justifyContent = "space-between";
                value.style.alignItems = "center";
                value.style.padding = "4px 0px";
                value.style.gap = "5px";

                dataPoints.forEach((rowItem, idx) => {
                    if (!rowItem.tPage) {
                        return;
                    }

                    const rowWrapper = document.createElement("div");
                    rowWrapper.style.borderWidth = "0";
                    rowWrapper.style.display = "flex";
                    rowWrapper.style.width = "100%";
                    rowWrapper.style.justifyContent = "space-between";
                    rowWrapper.style.alignItems = "center";
                    rowWrapper.style.gap = "10px";
                    const rPage = document.createElement("div");
                    rPage.style.borderWidth = "0";
                    rPage.style.color = "#014DC5";
                    rPage.style.fontWeight = "400";
                    rPage.style.fontSize = "12px";
                    rPage.innerText = rowItem.tPage;
                    const rVal = document.createElement("div");
                    rVal.style.borderWidth = "0";
                    rVal.style.color = labelColors[idx];
                    rVal.style.fontWeight = "500";
                    rVal.style.fontSize = "12px";
                    rVal.innerText = rowItem.tValue;
                    rowWrapper.appendChild(rPage);
                    rowWrapper.appendChild(rVal);
                    value.appendChild(rowWrapper);
                });

                itemRow.appendChild(meta);
                itemRow.appendChild(value);
                tooltipDiv.appendChild(itemRow);
            });

            const tableBody = document.createElement("tbody");
            bodyLines.forEach((body: string, i: number) => {
                const colors = tooltip.labelColors[i];

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "#fff";
                tr.style.background = "red !important";

                tr.style.borderWidth = "0";

                const td = document.createElement("td");
                td.style.borderWidth = "0";

                const text = document.createTextNode(body);

                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = document.getElementById("ctl");
            // Remove old children
            while (tableRoot?.firstChild) {
                tableRoot.firstChild.remove();
            }
            // Add new children
            tableRoot?.appendChild(tooltipDiv);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
        const getTopDifference: (_length: number) => number = (_length) => {
            let length = Math.round(_length) - 1;
            if (length >= 1) {
                return 60 + 20 * length;
            } else {
                return 60;
            }
        };

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        let left = positionX + tooltip.caretX;
        const adjustLeft: (screenSize: number, _maxPgLen: number) => number = (
            screenSize,
            _maxPgLen,
        ) => {
            let ratio = Math.round(screenSize / tooltip.caretX);
            console.log("adjustLeft _maxPgLen", _maxPgLen);
            if (ratio > 4) {
                return 0;
            } else if (ratio > 1) {
                return 40;
            } else {
                if (_maxPgLen < 25) {
                    return 90;
                } else if (_maxPgLen < 35) {
                    return 130;
                } else {
                    return 170;
                }
            }
        };

        let right = -100;
        tooltipEl.style.left =
            left - adjustLeft(window.innerWidth, maxPageLength) + 55 + "px";
        tooltipEl.style.top =
            positionY +
            (tooltip.caretY - getTopDifference(lengthTotalPoint)) +
            "px";
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding =
            tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };
    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "index",
            intersect: false,
        },
        scales: {
            yAxis: {
                reverse: isReverseY,
                min: min,
                max: max,
                grid: {
                    display: true,
                    drawBorder: false,
                    color: "#F1F1F1",
                    tickColor: "transparent",
                },
                ticks: {
                    stepSize: stepSize || 5,
                    beginAtZero: true,
                    // @ts-ignore
                    // callback: function (context) {
                    //     if (!noLabels) return context + `${yType ? yType : ""}`;
                    // },
                    color: "#ACB3BF",
                    major: {
                        enabled: true,
                    },
                    // color: "#29394a",
                    font: {
                        // weight: "500",
                        // size: "12px",
                        // family: "Inter",
                    },
                },
            },
            xAxis: {
                grid: {
                    display: false,
                    drawBorder: false,
                    color: "#F1F1F1",
                    tickColor: "transparent",
                    // display: false, // Set to false to remove the vertical grid lines
                },
                ticks: {
                    maxRotation: 0, // Prevent label rotation
                    beginAtZero: true,
                    font: {
                        family: "Inter !important",
                        size: "0px", // Adjust the size as needed
                        lineHeight: "15px",
                        style: "Inter !important", // Set the font style to normal
                    },
                    color: "#ACB3BF",
                    // @ts-ignore
                    callback: (value, index) => {
                        if (value == labels.length - 1) {
                            return labels[value];
                        }
                        if (value % 3 == 0) {
                            return labels[value];
                        } else {
                            return null;
                        }
                    },
                },
            },
        },
        plugins: {
            elements: {
                point: {
                    borderWidth: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                    radius: 0,
                },
            },
            tooltip: {
                enabled: false,
                position: "nearest",
                ...(true && { external: externalTooltipHandler }),
                mode: "index",
                intersect: false,
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "",
            },
        },
    };
    const data = {
        labels,
        datasets: [...dataSet],
    };

    return <Line options={options} data={data} />;
}
