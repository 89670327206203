import pluralize from "pluralize";

interface Keyword {
    keyword: string;
}

interface KeyTermsUsageData {
    command: string;
    source: string;
    noOFWords: number;
    count: number;
    list: Keyword[];
}

interface RatingResult {
    readability: number;
    wordCount: number;
    score: number;
    source: string;
    keyTermslist: Keyword[];
}

declare const heavyDutyWorker: Worker;

async function RatingHook(
    str: string,
    keyWords: Keyword[],
    source: string,
): Promise<RatingResult> {
    return new Promise((res, rej) => {
        heavyDutyWorker.postMessage({
            list: keyWords?.map((item) => ({
                ...item,
                singular: pluralize.singular(item.keyword),
                plural: pluralize.plural(item.keyword),
            })),
            text: str,
            source,
            command: "keyTermsUsage",
        });

        const callback = ({ data }: { data: KeyTermsUsageData }) => {
            if (data.command === "keyTermsUsage" && data.source === source) {
                const obj: RatingResult = {
                    readability: 0,
                    wordCount: data.noOFWords,
                    score: data.count,
                    source: data.source,
                    keyTermslist: data.list,
                };

                res(obj);
                heavyDutyWorker.removeEventListener("message", callback);
            }
        };

        heavyDutyWorker.addEventListener("message", callback);
    });
}

export default RatingHook;
