import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { FiFilter } from "react-icons/fi";
import Tab from "../../atoms/Tab/Tab";
import { scrollToTop } from "../../utlities";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import XOverflow from "../../atoms/XOverflow/XOverflow";

function findCats(cats) {
  let _cats = [];

  for (let cat in cats) {
    _cats.push(cat);
  }

  return _cats;
}

export default function ({
  variants,
  setFilterId,
  filterId,
  redirectTo,
  show,
  isVariants,
}) {
  const goTo = useNavigation();

  const variantsCats = useSelector((state) => state.variants.generateData);
  const [cats, setCats] = useState([]);
  const ref = useRef();
  scrollToTop();
  useEffect(() => {
    setCats(findCats(variants ? variants : variantsCats));
  }, [variantsCats, variants]);

  useEffect(() => {
    if (filterId == -1 || filterId == -2) {
      ref.current.scroll(1000, 0);
    }
  }, [filterId]);

  return (
    <div ref={ref} className={styles.container}>
      <XOverflow className={styles.containerLeft}>
        {cats.map((v) => (
          <Tab
            setFilterId={setFilterId}
            name={v}
            enable={filterId == v}
            redirectTo={redirectTo}
          />
        ))}
      </XOverflow>
    </div>
  );
}
