import {
  LOAD_VARIANTS,
  OPEN_VARIANT,
  LOAD_USER_VARIANTS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_VARIANT,
  LOAD_GENERATE_VARIANTS,
} from "../actiontypes";

const initialState = {
  data: [],
  userData: [],
  generateData: [],
  openVariant: {
    id: null,
    state: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_VARIANTS:
      return {
        ...state,
        data: action.data,
      };
    case LOAD_GENERATE_VARIANTS:
      return {
        ...state,
        generateData: action.data,
      };
    case LOAD_USER_VARIANTS:
      return {
        ...state,
        userData: action.data,
      };
    case OPEN_VARIANT:
      return {
        ...state,
        openVariant: action.info,
      };
    case AUTH_LOGOUT_VARIANT:
      return initialState;
    default:
      return { ...state };
  }
}
