import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { debounce } from "@material-ui/core";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { TfiMenuAlt } from "react-icons/tfi";
import { IoMdArrowBack } from "react-icons/io";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

function BlogOutline({ showOutline, setShowOutline }) {
    const [outlineH2s, setOutlineH2s] = useState([]);
    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        const updateOutline = () => {
            const allH2s = document?.quill?.editor?.root?.querySelectorAll(
                "h1, h2, h3, h4, h5, h6",
            );
            const temp = [];

            for (const heading of allH2s) {
                temp.push(heading);
            }

            setOutlineH2s(temp);
        };
        const callback = debounce(updateOutline, 500);
        updateOutline();

        document.quill.editor.on("text-change", callback);

        return () => {
            document.quill.editor.off("text-change", callback);
        };
    }, []);

    return (
        <div className={styles.outlineSection}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "40px",
                }}
            >
                <h2 className={styles.outlineHeading}>Blog Outline</h2>
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={showOutline ? "Hide Outlines" : "Show Outlines"}
                >
                    <div
                        className={styles.outlineBtn}
                        onClick={() => {
                            mixpanelTrack("Editor Outline", {
                                action: "Close",
                            });
                            setShowOutline(false);
                        }}
                    >
                        <IoMdArrowBack />
                    </div>
                </LightTooltip>
            </div>
            <div className={styles.outlineDivider}></div>
            <div className={styles.outlineContents}>
                {outlineH2s.map((heading) => (
                    <div
                        style={{
                            paddingLeft:
                                heading.tagName == "H1"
                                    ? ""
                                    : heading.tagName == "H2"
                                    ? "10px"
                                    : "20px",
                            fontWeight:
                                heading.tagName == "H1"
                                    ? 600
                                    : heading.tagName == "H2"
                                    ? 600
                                    : 400,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            heading.style.borderTop = "1px #6b6b6b dashed";
                            heading.style.borderBottom = "1px #6b6b6b dashed";
                            const container = document.getElementById(
                                "editorBoundsContainer",
                            );
                            setImmediate(() => {
                                container.scrollTo(0, heading?.offsetTop);
                                setTimeout(() => {
                                    heading.style.border = "";
                                }, 1500);
                            });
                        }}
                        className={styles.heading}
                    >
                        {heading.tagName}: {heading.innerText}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BlogOutline;
