import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import CountUp from "react-countup";
import { debounceWrapper } from "../../../../utility/helperFun";

const getBackground = (score) => {
    if (score < 24) {
        return "#ff6255";
    } else if (score < 50) {
        return "#ff9254";
    } else if (score <= 73) {
        return "#ffce5c";
    } else if (score > 73) {
        return "#98c949";
    } else {
        return "#ff6255";
    }
};
// `linear-gradient(to right, #ff6255 0%, #ff9254 24%, #ffce5c 50%, #f2ed6f 73%,#98c949 100%)`;

const rightEdgeCss = (score) => {
    if (score < 85) {
        return `linear-gradient(to bottom, transparent 0%, #98c94922 100%)`;
    } else {
        return `linear-gradient(to bottom, #98c94955 0%, #98c94955 100%)`;
    }
};

const getGradient = (score) => {
    if (score < 28) {
        return `linear-gradient(to right, #ff6255 0%, #ff925433 24%, #ffce5c33 50%, #f2ed6f33 73%,#98c9494d 100%)`;
    } else if (score <= 50) {
        return `linear-gradient(to right, #ff6255 0%, #ff9254 24%, #ffce5c 50%, #f2ed6f33 73%,#98c94933 100%)`;
    } else if (score <= 73) {
        return `linear-gradient(to right, #ff6255 0%, #ff9254 24%, #ffce5c 50%, #f2ed6f33 73%,#98c94933 100%)`;
    } else if (score > 73 && score < 85) {
        return `linear-gradient(to right, #ff6255 0%, #ff9254 24%, #ffce5c 50%, #f2ed6f 73%,#98c94933 100%)`;
    } else if (score >= 85) {
        return `linear-gradient(to right, #ff6255 0%, #ff9254 24%, #ffce5c 50%, #f2ed6f 73%,#98c949 100%)`;
    } else {
        return `linear-gradient(to right, #ff625533 0%, #ff925433 24%, #ffce5c33 50%, #f2ed6f33 73%,#98c9494d 100%)`;
    }
};

function ScoreIndicator({ score, suggestedScore }) {
    // const [angle, setAngle] = useState((score * 180) / 100);
    const [arcLength, setArcLength] = useState(80);
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(74);
    const [startScore, setStartScore] = useState(score);
    let timeoutRef = useRef(null);

    useEffect(() => {
        const { leftPos, topPos } = calcPos(score, arcLength);
        setLeft(leftPos);
        setTop(topPos);
        debounceWrapper(
            () => {
                setStartScore((ps) => {
                    return score;
                });
            },
            200,
            timeoutRef,
        );
    }, [score]);
    const calcPos = (score, arcLength) => {
        const angle = (score * 180) / 100;
        const sr = Math.sin(angle * (Math.PI / 180));
        let y = sr * arcLength;
        let x = Math.sqrt(arcLength * arcLength - y * y);
        return {
            leftPos:
                angle <= 90 ? arcLength - x : arcLength + x - (5 * angle) / 180,
            topPos: y < 10 ? arcLength - y - 6 : arcLength - y,
        };
    };
    return (
        <div className={styles.indicatorContainer}>
            <span className={styles.leftEdge}></span>
            <span
                className={styles.rightEdge}
                style={{ background: rightEdgeCss(score) }}
            ></span>
            <div
                className={styles.indicator}
                style={{
                    background: getGradient(score),
                }}
            >
                {!isNaN(score) && (
                    <div
                        className={styles.gradientIndicator}
                        style={{ rotate: `${(score / 100) * 180}deg` }}
                    ></div>
                )}
                <div className={styles.scoreTextContainer}>
                    <p className={styles.scoreText}>
                        <span className={styles.actualScore}>
                            <CountUp
                                start={startScore}
                                end={isNaN(score) ? 0 : score}
                                duration={4}
                            />
                        </span>
                        /100
                    </p>
                    <p className={styles.suggestedScore}>
                        Suggested: {suggestedScore}+
                    </p>
                </div>
                <span
                    style={{
                        top: top,
                        left: left,
                        backgroundColor: getBackground(score),
                    }}
                    className={styles.oval}
                ></span>
            </div>
        </div>
    );
}

export default ScoreIndicator;
