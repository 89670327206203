import React, { useEffect, useState } from "react";
import styles from "./ClusterReports.module.css";
import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";
import NewUIDropDown, {
    TYPES,
} from "../../../molecules/NewUIDropdown/NewUIDropDown";

const selectClass = makeStyles(() => ({
    root: {
        fontSize: 14,
        width: 180,
        color: "rgb(107, 107, 107)",
    },
}));

const formControlClass = makeStyles(() => ({
    root: {
        "& .MuiInputBase-root": {
            height: 36,

            borderRadius: 3,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(75, 75, 85, 0.16)",
        },
    },
}));

export type RecommendedRanges = {
    default: {
        sv: {
            min: number;
            max: number;
        };
        cpc: {
            min: number;
            max: number;
        };
        rel: {
            min: number;
            max: number;
        };
    };
    highSV: {
        min: number;
        max: number;
    };
    lowCPC: {
        min: number;
        max: number;
    };
    highRel: {
        min: number;
        max: number;
    };
    recommendedScore: {
        min: number;
        max: number;
    };
};

function ClusterCatsFilter({
    catsData,
    setValidRangeState,
    isClusterView,
    validRangeState,
    all,
    search,
}: {
    catsData: {
        clusterView: RecommendedRanges;
        listView: RecommendedRanges;
    };
    setValidRangeState: Function;
    isClusterView: boolean;
    validRangeState: any;
    all: boolean;
    search: string;
}) {
    const [active, setActive] = useState(0);
    const classes = selectClass();
    const classes1 = formControlClass();
    const cats = ["All", "Low CPC", "High Search Vol.", "High Relevance"];

    useEffect(() => {
        if (search.length) {
            setActive(0);
            changeFilters("All");
        }
    }, [search]);

    const changeFilters = (cat: string) => {
        let val: any = isClusterView ? catsData.clusterView : catsData.listView;
        switch (cat) {
            // case "Recommended":
            //     val = { ...val.default, potential_score: val.recommendedScore };
            //     break;
            case "Low CPC":
                val = {
                    ...val.default,
                    cpc: { min: val.lowCPC.min, max: val.lowCPC.max },
                    all: false,
                };
                break;
            case "High Search Vol.":
                val = {
                    ...val.default,
                    search_volume: { min: val.highSV.min, max: val.highSV.max },
                    all: false,
                };
                break;
            case "High Relevance":
                val = {
                    ...val.default,
                    relevance: { min: val.highRel.min, max: val.highRel.max },
                    all: false,
                };
                break;
            default:
                val = { ...val.default };
        }

        setValidRangeState(val);
    };

    useEffect(() => {
        if (isClusterView) {
            setValidRangeState({
                ...catsData.clusterView.default,
            });
        } else {
            setValidRangeState({
                ...catsData.listView.default,
            });
        }
    }, [isClusterView, all]);
    useEffect(() => {
        if (validRangeState.all) {
            setActive(0);
            changeFilters("All");
        }
    }, [validRangeState.all]);

    return (
        <>
            <div className={styles.catsTabs}>
                <FormControl classes={classes1} fullWidth>
                    <Select
                        classes={classes}
                        variant="outlined"
                        value={validRangeState.isCustom ? -1 : active}
                    >
                        <MenuItem value={-1}>
                            <em>Custom</em>
                        </MenuItem>
                        {cats.map((cat, i) => (
                            <MenuItem
                                onClick={() => {
                                    setActive(i);
                                    changeFilters(cat);
                                }}
                                value={i}
                            >
                                {cat}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    );
}

export default ClusterCatsFilter;
