import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import axios from "axios";
import { url } from "../../utility/config";
import { TextField } from "@material-ui/core";
import styles from "./superAdmin.module.css";
let execute = null;
export default function EmailSuggestion({
  email,
  setEmail,
  uidReq,
  setUserId,
  random,
}) {
  const [allDbEmails, setAllDbEmails] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const searchEmails = (key) => {
    if (key.length > 2) {
      axios({
        method: "get",
        url: `${url}/api/project/priority/searchEmail?search_email=${key}`,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setAllDbEmails(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAllDbEmails([]);
    }
  };

  const dbounce = (key) => {
    clearTimeout(execute);
    execute = setTimeout(() => {
      searchEmails(key);
    }, 500);
  };
  return (
    <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
      <label htmlFor="Email">Enter email:&nbsp;</label>
      <Autocomplete
        id="email-suggestions"
        freeSolo
        key={random}
        className={styles.emailField}
        // onSelect={(event, newValue) => { setEmail(newValue) }}
        onChange={(event, newValue) => {
          setEmail(newValue)
          if (
            newValue &&
            setUserId &&
            allDbEmails.filter((item) => item.email === newValue)[0]
          ) {
            setUserId(
              allDbEmails.filter((item) => item.email === newValue)[0].id
            );
          }
        }}
        options={allDbEmails.map((item) => item.email)}
        renderInput={(params) => (
          <TextField
            {...params}
            value={email}
            onChange={(e) => dbounce(e.target.value)}
          />
        )}
      />
    </div>
  );
}
