import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
// import pluralize from "pluralize";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import AlertMessage from "../../../atoms/AlertMessage/AlertMessage";
import SEOEditor from "../../../organisms/TextEditor/SEOEditor";
import Overview from "../../../molecules/Creator/Overview/Overview";
import {
    Link,
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams,
} from "react-router-dom";
import { getTime, parseJSON } from "../../../../utility/validateFunctions";

import ModalGoNuts from "../../../atoms/Modal/ModalGoNuts";
import { IoIosDesktop } from "react-icons/io";
import Button from "../../../atoms/Button/Button";
import Progress from "../../../atoms/Progress/Progress";
import EditorHeader from "../../TextEditor/EditorHeader";
import ReportContext from "../../Research/ReportContext";
import EditorTitle from "./EditorTitle";
import { EditorBriefContextProvider } from "../EditorBrief/EditorBriefContext";
import StepsWizard from "../GoNuts/StepsWizard";
import MenuList from "../../MenuList/MenuList";
import AsideOptions from "./AsideOptions";
import LoaderGoNuts from "../GoNuts/LoaderGoNuts";
import useHelperFunctions from "../../../helperFunctions";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../../components/account/useUserAccessHook";
import screens from "../../../../screens.json";
import { socket } from "../../../../Sockets/useSockets";
import { socketEvents } from "../../../../Sockets/constants";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import { OnBoardingContext } from "../../../Context/OnBoardingContext/OnBoardingContextProvider";
import { parseOutlines } from "../../../../utility/helperFun";

export default function CreatorCore({
    isShared = null,
    setSeoWithCruise = () => {},
    seoWithCruise = "",
    loadingCruise = false,
    setLoadingCruise = () => {},
    auditMode = false,
}) {
    const [reportData, setReportData] = useState({});
    const [detailedReportLoading, setDetailedReportLoading] = useState(true);
    const [goNutsModal, setGoNutsModal] = useState(null);
    const { LockConfigurator } = useUserAccessHook();
    const params = useParams();
    const { getData, putData } = useHelperFunctions();
    const history = useHistory();
    const goTo = useNavigation();
    const [wait10, setWait10] = useState(true);
    const [goNutsModalBtn, setGoNutsModalBtn] = useState(false);
    const [contentModal, setContentModal] = useState(false);
    const [cruiseNudge, setCruiseNudge] = useState(false);
    const [isStatsAllowed, setIsStatsAllowed] = useState(false);
    const [isStatsLoading, setIsStatsLoading] = useState(false);
    const [tempGuideLineData, setTempGuideLineData] = useState({});
    const [wordCountRange, setWordCountRange] = useState("");
    const [metaFixIt, setMetaFixIt] = useState({ section: "", isOn: false });
    const [enableFixItEditor, setEnableFixItEditor] = useState(false);
    const [isLoadingFixIt, setIsLoadingFixIt] = useState(false);
    const [enableFixIt, setEnableFixIt] = useState(false);
    const [isFixItEnabled, setIsFixItEnabled] = useState({
        isOn: false,
        section: {},
        sectionData: {},
    });
    const { isFreeForever } = useUserAccessHook();

    useEffect(() => {
        const updateStatus = async (status) => {
            if (reportData?.reportInfo?.is_sample) {
                return;
            }
            const res = await putData({
                url: "/api/keywords/user-activity",
                payload: {
                    report_id: reportData?.reportInfo?.report_id,
                    activity_status: status,
                },
            });
        };
        if (reportData?.reportInfo?.report_id && !auditMode) {
            let status = goNutsModal === false ? 2 : 1;
            if (reportData?.reportInfo?.activity_status < status) {
                updateStatus(status);
                setReportData((ps) => ({
                    ...ps,
                    reportInfo: { ...ps.reportInfo, activity_status: status },
                }));
            }
        }
    }, [reportData?.reportInfo?.report_id, goNutsModal]);

    useEffect(() => {
        if (
            reportData?.reportInfo?.activity_status <= 1 &&
            params.form !== "report"
        ) {
            isFreeForever || auditMode ? "" : setGoNutsModal(true);
        }
    }, [reportData?.reportInfo?.activity_status, auditMode]);

    useEffect(() => {
        setContentModal(+reportData?.rating?.wordCount > 100 && loadingCruise);
        setCruiseNudge(+reportData?.rating?.wordCount === 0);
    }, [reportData?.rating]);

    // function parseOutlines(arr = []) {
    //     return arr
    //         .sort((a, b) => (a.rank <= b.rank ? -1 : 1))
    //         .map((item, i) => {
    //             item.h_tags =
    //                 typeof item.h_tags == "string"
    //                     ? JSON.parse(item.h_tags)
    //                     : item.h_tags;
    //             item.index = i;
    //             return item;
    //         });
    // }

    const getReportOutlines = useCallback(async (id) => {
        const res = await getData({
            url: `/api/scrape/getReportOtherDetails?report_id=${id}&field=outlines`,
            optionalToken: true,
        });
        if (res.status == 200) {
            setReportData((ps) => ({
                ...ps,
                competetion: parseOutlines(res.data.outlines).map((val) => {
                    val.grade = val.optimiser_score;
                    return val;
                }),
            }));
        }
    }, []);

    const getReportCitations = useCallback(async (id) => {
        const res = await getData({
            url: `/api/scrape/getReportOtherDetails?report_id=${id}&field=citations`,
            optionalToken: true,
        });
        if (res.status == 200) {
            setReportData((ps) => ({
                ...ps,
                citations: res.data.citations,
            }));
        }
    }, []);

    const flattenStats = (stats) => {
        let index = 0;
        const temp = [];

        stats.forEach((item) => {
            const stats = item.stats ? JSON.parse(item.stats) : [];

            stats.forEach((stat) => {
                temp.push({
                    ...stat,
                    id: index,
                    rank: item.rank,
                    url: item.url,
                    url_title: item.url_title,
                });
                index++;
            });
        });

        setReportData((ps) => ({
            ...ps,
            stats: temp,
        }));
    };

    const getReportStats = useCallback(async (id) => {
        setIsStatsLoading(true);
        const res = await getData({
            url: `/api/scrape/getReportOtherDetails?report_id=${id}&field=stats`,
            optionalToken: true,
        });
        if (res.status == 200) {
            flattenStats(res.data.stats);
        }
        setIsStatsLoading(false);
    }, []);

    const getDetailedReport = useCallback(async (id) => {
        const res = await getData({
            url: `/api/scrape/getReportDetails?report_id=${id}`,
            optionalToken: true,
        });

        if (res.status == 200) {
            {
                const data = res.data.data;

                const arr = [];
                for (const iterator of data.relevantTerms) {
                    // let plural = pluralize(iterator.keyword);
                    arr.push({
                        ...iterator,
                        used: 0,
                        // keyword_plural: plural,
                    });
                }

                setIsStatsAllowed(data.isStats);

                setWordCountRange(data?.word_count_range);

                setReportData({
                    overview: data.Overview,
                    relevantTerms: arr,
                    themes: data.themes,
                    peopleQuestions: data.questionsByPeople,
                    competetion: [],
                    reportInfo: {
                        ...data.reportInfo,
                        secondary_keywords:
                            data.reportInfo.secondary_keywords || "[]",
                    },
                    citations: [],
                    stats: [],
                });
                console.log(data.reportInfo, "ddddddddddd");

                setDetailedReportLoading(false);
                getReportOutlines(id);
                getReportCitations(id);
                getReportStats(id);
            }
        }
    });

    const getReportInitialInfo = async () => {
        const res = await getData({
            url: `/api/scrape/getReportInfo?report_id=${params.id}`,
        });

        if (res.status == 200) {
            setReportData({
                reportInfo: {
                    ...res.data.data,
                    title: res.data.data.search_keyword,
                    interlinking_domain: res.data?.data?.interlinking_domain,
                    secondary_keywords:
                        res.data.data.secondary_keywords || "[]",
                },
            });
            return res.data.data;
        } else {
            history.replace(screens.CREATOR);
            setSeoWithCruise(false);
            setGoNutsModal(false);
        }
    };

    useEffect(() => {
        const attachSockets = () => {
            socket.on(socketEvents.REPORT_CREATED, callbackSuccess);
            socket.on(socketEvents.REPORT_FAILED, callbackFailure);
        };
        const removeSockets = () => {
            socket.off(socketEvents.REPORT_CREATED, callbackSuccess);
            socket.off(socketEvents.REPORT_FAILED, callbackFailure);
        };

        const callbackSuccess = (data) => {
            if (data.report_id == params.id) getDetailedReport(params.id);
        };

        const callbackFailure = (data) => {
            if (data.report_id == params.id) {
                goTo("CREATOR", [], "", true);
                setSeoWithCruise(false);
                setGoNutsModal(false);
            }
        };

        setDetailedReportLoading(true);
        async function checkReport() {
            const reportInitialInfo = await getReportInitialInfo();
            if (reportInitialInfo) {
                if (reportInitialInfo?.is_completed === 0) {
                    attachSockets();
                } else {
                    getDetailedReport(params.id);
                }
            }
        }

        checkReport();

        const hideChat = () => {
            setImmediate(() => {
                window.userengage && window.userengage("widget.hide");
            });
        };

        document.addEventListener("USERDOTCOM_LOADED", hideChat);

        window.userengage && window.userengage("widget.hide");

        localStorage.setItem("dismissedKeywords", JSON.stringify({}));

        return () => {
            removeSockets();
            setLoadingCruise && setLoadingCruise(false);
            setSeoWithCruise && setSeoWithCruise(false);
            document.removeEventListener("USERDOTCOM_LOADED", hideChat);
            window.userengage && window.userengage("widget.show");
            localStorage.setItem("dismissedKeywords", JSON.stringify({}));
        };
    }, []);

    useEffect(() => {
        if (
            !detailedReportLoading &&
            (LockConfigurator(FEATURE_TYPES.cruiseModeLock) || isShared)
        ) {
            setGoNutsModal(false);
        }
    }, [detailedReportLoading]);

    useEffect(() => {
        if (goNutsModal || loadingCruise) setCruiseNudge(false);
    }, [goNutsModal]);

    return (
        <div
            className={styles.creatorContainer}
            style={{ backgroundColor: isShared ? "#f6f8fc" : "" }}
        >
            {!auditMode && (
                <div
                    id="creatorOptions"
                    className={styles.creatorOptions}
                ></div>
            )}
            <div className={styles.containerRE}>
                <ReportContext.Provider
                    value={
                        reportData.overview
                            ? {
                                  isStatsAllowed: isStatsAllowed,
                                  isStatsLoading: isStatsLoading,
                                  auditMode: auditMode,
                                  highlight_keyTerm: true,
                                  competetion: reportData.competetion,
                                  peopleQuestions: reportData.peopleQuestions,
                                  reportInfo: {
                                      ...reportData.reportInfo,
                                      report_id: params.id,
                                  },
                                  keyTerms: reportData.relevantTerms,
                                  setGoNutsModal,
                                  overview: reportData.overview,
                                  isShared,
                                  setReportData,
                                  rating: reportData.rating || undefined,
                                  cruiseNudge,
                                  setCruiseNudge,
                                  setGoNutsModalBtn,
                                  ...reportData,
                              }
                            : reportData.reportInfo
                            ? {
                                  reportInfo: {
                                      ...reportData.reportInfo,
                                      report_id: params.id,
                                  },
                                  setReportData,
                                  stats: [],
                                  citations: [],
                                  competetion: [],
                              }
                            : { setReportData }
                    }
                >
                    {detailedReportLoading || !reportData.reportInfo ? (
                        <Progress height={"100vh"} width={"100vw"} />
                    ) : (
                        <>
                            {!auditMode && (
                                <AsideOptions
                                    seoWithCruise={seoWithCruise}
                                    isShared={isShared}
                                    auditMode={auditMode}
                                />
                            )}
                            <EditorHeader
                                left={
                                    <MenuList
                                        isFixItEnabled={isFixItEnabled}
                                        info={{
                                            docName:
                                                reportData.reportInfo.title,
                                            title: reportData.reportInfo.title,
                                            docId: reportData.reportInfo
                                                .report_id,
                                            isShared,
                                            isSample: reportData?.reportInfo
                                                ?.is_sample
                                                ? true
                                                : false,
                                            auditMode,
                                        }}
                                    />
                                }
                                title={
                                    <EditorTitle
                                        access={!isShared || isShared.access}
                                        reportInfo={reportData.reportInfo}
                                        auditMode={auditMode}
                                        isFixItEnabled={isFixItEnabled}
                                        setIsFixItEnabled={setIsFixItEnabled}
                                        setEnableFixItEditor={
                                            setEnableFixItEditor
                                        }
                                        setIsLoadingFixIt={setIsLoadingFixIt}
                                        setEnableFixIt={setEnableFixIt}
                                        setMetaFixIt={setMetaFixIt}
                                    />
                                }
                                auditMode={auditMode}
                                isFixItEnabled={isFixItEnabled}
                            ></EditorHeader>
                            <EditorBriefContextProvider
                                report_id={params.id}
                                isContentBrief={
                                    reportData?.reportInfo?.isContentBrief
                                }
                                goNutsModal={goNutsModal || goNutsModalBtn}
                                isShared={isShared}
                                access={isShared?.access}
                                reportLoading={detailedReportLoading}
                                setTempGuideLineData={setTempGuideLineData}
                                seoWithCruise={seoWithCruise}
                            >
                                {params.form == "report" ? (
                                    <Overview
                                        overview={reportData.overview}
                                        relevantTerms={reportData.relevantTerms}
                                        themes={reportData.themes}
                                        peopleQuestions={
                                            reportData.peopleQuestions
                                        }
                                        competetion={reportData.competetion}
                                        reportInfo={reportData.reportInfo}
                                        citations={reportData.citations}
                                        isSharedReport={isShared}
                                        access={!isShared || isShared.access}
                                        auditMode={auditMode}
                                    />
                                ) : auditMode || params.form == "editor" ? (
                                    <SEOEditor
                                        keyTerms={reportData.relevantTerms}
                                        overview={reportData.overview}
                                        reportInfo={reportData.reportInfo}
                                        isShared={isShared}
                                        wordCountRange={wordCountRange}
                                        isFixItEnabled={isFixItEnabled}
                                        setIsFixItEnabled={setIsFixItEnabled}
                                        enableFixItEditor={enableFixItEditor}
                                        setEnableFixItEditor={
                                            setEnableFixItEditor
                                        }
                                        isLoadingFixIt={isLoadingFixIt}
                                        setIsLoadingFixIt={setIsLoadingFixIt}
                                        metaFixIt={metaFixIt}
                                        setMetaFixIt={setMetaFixIt}
                                        enableFixIt={enableFixIt}
                                        setEnableFixIt={setEnableFixIt}
                                    />
                                ) : (
                                    <Redirect to={"/admin/creator"} />
                                )}
                            </EditorBriefContextProvider>
                        </>
                    )}
                    {!isShared &&
                        (goNutsModal || goNutsModalBtn) &&
                        params.form == "editor" && (
                            <ModalGoNuts
                                isPremium={
                                    !!reportData?.reportInfo?.is_premium ||
                                    false
                                }
                                setModal={() => {}}
                                children={
                                    <StepsWizard
                                        isPremium={
                                            !!reportData?.reportInfo
                                                ?.is_premium || false
                                        }
                                        wordCountRange={wordCountRange}
                                        wait10={wait10}
                                        setWait10={setWait10}
                                        loadingCruise={loadingCruise}
                                        tempGuideLineData={tempGuideLineData}
                                        handleModal={(value) => {
                                            if (detailedReportLoading) {
                                                return () => {};
                                            } else {
                                                setGoNutsModal(value);
                                                setGoNutsModalBtn(value);
                                            }
                                        }}
                                        setSeoWithCruise={setSeoWithCruise}
                                        seoWithCruise={seoWithCruise}
                                        reportInfo={{
                                            ...(reportData.reportInfo
                                                ? reportData.reportInfo
                                                : {}),
                                            report_id: params.id,
                                            search_country:
                                                reportData?.reportInfo
                                                    ?.search_country,
                                            reportLoading:
                                                detailedReportLoading &&
                                                reportData.reportInfo
                                                    .is_completed === 0,

                                            title: reportData?.reportInfo
                                                ?.title,
                                            secondary_keywords:
                                                reportData?.reportInfo
                                                    ?.secondary_keywords,
                                            aiWordLimitSetting:
                                                reportData.aiWordLimitSetting,
                                            setAiWordLimitSetting:
                                                reportData.setAiWordLimitSetting,
                                            relevantTerms:
                                                reportData.relevantTerms,
                                        }}
                                        peopleQuestions={
                                            reportData.peopleQuestions
                                                ? reportData.peopleQuestions
                                                : []
                                        }
                                        setGoNutsModal={(value) => {
                                            if (detailedReportLoading) {
                                                return () => {};
                                            } else {
                                                setGoNutsModal(value);
                                                setGoNutsModalBtn(value);
                                            }
                                        }}
                                        goNutsModalBtn={goNutsModalBtn}
                                        contentModal={contentModal}
                                        setContentModal={setContentModal}
                                        setReportData={setReportData}
                                    />
                                }
                            />
                        )}
                </ReportContext.Provider>
            </div>
        </div>
    );
}
