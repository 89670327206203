import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import mixpanel from "mixpanel-browser";
import Modal from "../../atoms/Modal/Modal";
import Button from "../../atoms/Button/Button";
import SVGIcon from "../../atoms/SVGIcon";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import { BASE_URL, WEBTUNE_DOMAIN_STATUS, endPoints } from "./constants";
import useHelperFunctions from "../../helperFunctions";

const SitemapModal = ({ setModal = () => {}, sitemapModal = {} }) => {
    const { setState } = useContext(InterlinkingContext);
    const { postData } = useHelperFunctions();
    const [sitemapUrl, setSitemapUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isValidSitemapUrl = () => {
        const regex = new RegExp(
            /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        );
        return regex.test(sitemapUrl);
    };

    useEffect(() => {
        if (sitemapModal?.id) {
            setState((ps) => {
                let tempList = ps.domainsList.filter(
                    (ps) => ps.id != sitemapModal?.id,
                );
                return {
                    ...ps,
                    domainsList: tempList,
                    selectedDomainInfo: !!tempList.length ? tempList[0] : null,
                };
            });
        }
    }, [sitemapModal.id, setState]);

    const getDomainFromSitemap = (sitemapUrl) => {
        let domain = sitemapUrl.replace(/^https?:\/\//, "");
        domain = domain.split("/")[0];
        return domain;
    };

    const handleCancel = () => {
        setState((ps) => {
            if (ps.domainsList.length == 0) {
                return {
                    ...ps,
                    domainsList: [],
                    selectedDomainInfo: null,
                };
            } else {
                let tempList = ps.domainsList.filter(
                    (ps) => ps.id != sitemapModal?.id,
                );
                return {
                    ...ps,
                    domainsList: tempList,
                    selectedDomainInfo: !!tempList.length ? tempList[0] : null,
                };
            }
        });
        setModal({ isShow: false });
    };

    const addDomainSitemapRequest = async (
        obj = { isGsc: false, gscId: null },
    ) => {
        let _domainName = sitemapModal.domainName;
        if (!_domainName) {
            return;
        }
        mixpanel.track("Sitemap Required");
        let domainNameFromSiteMap = sitemapUrl.length
            ? getDomainFromSitemap(sitemapUrl)
            : "";
        setIsLoading(true);

        mixpanel.track("Add Domain", {
            Screen: "Internal Linking Login",
            "Multi Domain Allowed": "Yes",
        });
        let payload = {
            domain: domainNameFromSiteMap,
            source: null,
            has_sitemap: true,
            sitemap_url: sitemapUrl,
            is_gsc: sitemapModal.isGsc ? true : false,
            gsc_id: sitemapModal.gscId || null,
        };
        const res = await postData({
            completeUrl: BASE_URL + endPoints.addDomain,
            payload,
        });

        if (res.status == 200) {
            setModal((ps) => ({
                ...ps,
                isShow: false,
                id: res.data.domain_id,
                gsc_id: obj.gscId,
            }));
            setState((ps) => ({
                ...ps,
                selectedDomainInfo: {
                    domain: _domainName,
                    id: res.data.domain_id,
                    status: WEBTUNE_DOMAIN_STATUS.NOT_STARTED,
                    gsc_id: obj.gscId || null,
                },
                domainsList: [
                    ...ps.domainsList,
                    {
                        domain: _domainName,
                        id: res.data.domain_id,
                        status: WEBTUNE_DOMAIN_STATUS.NOT_STARTED,
                        gsc_id: obj.gscId || null,
                    },
                ],
            }));
        } else {
        }
        setIsLoading(false);
    };

    return (
        <Modal
            setModal={() => {
                !isLoading && setModal({ isShow: false });
            }}
        >
            <div className={styles.backdrop}>
                <div className={styles.sitemapModalWrapper}>
                    <div className={styles.titleModal}>Sitemap Required</div>
                    <div className={styles.infoText}>
                        We were unable to retrieve sitemap for{" "}
                        <strong>{sitemapModal?.domainName}</strong>. Please
                        enter the direct link to the sitemap below.
                    </div>
                    <div
                        style={{
                            marginBottom: "20px",
                            width: "100%",
                            marginTop: "25px",
                        }}
                    >
                        <Textfield
                            label=""
                            height={34}
                            placeholder="https://domain.com/sitemap.xml"
                            containerStyle={{ marginBottom: 0 }}
                            value={sitemapUrl}
                            onChange={(e) =>
                                setSitemapUrl(e.target.value.trim())
                            }
                            disabled={isLoading}
                            errMsg={
                                !!sitemapUrl &&
                                (isValidSitemapUrl() ? "" : "Enter valid url")
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.stopPropagation();
                                    addDomainSitemapRequest();
                                }
                            }}
                        />
                    </div>

                    <div className={styles.bottomCtas}>
                        <Button
                            text="Cancel"
                            style={{
                                color: "#ACB3BF",
                                background: "#acb3bf1a",
                            }}
                            width={130}
                            handler={() => {
                                handleCancel();
                            }}
                            loading={isLoading}
                        />
                        <Button
                            text="Submit"
                            handler={addDomainSitemapRequest}
                            width={130}
                            Icon={() => (
                                <SVGIcon
                                    src={"/svg/arrow-head-right.svg"}
                                    style={{ color: "white" }}
                                />
                            )}
                            disabled={!sitemapUrl || !isValidSitemapUrl()}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SitemapModal;
