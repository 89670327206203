import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { userLogout } from "../../../../store/actions/auth";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import Modal from "../../../atoms/Modal/Modal";
import styles from "./styles.module.css";
import {
    AUTH_LOGOUT_DASHBOARD,
    AUTH_LOGOUT_OTHERINFO,
    AUTH_LOGOUT_VARIANT,
} from "../../../../store/actiontypes";

function LoggingOut() {
    const [time, setTime] = useState(3);
    const history = useHistory();
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(userLogout());
        dispatch({ type: AUTH_LOGOUT_VARIANT });
        dispatch({ type: AUTH_LOGOUT_DASHBOARD });
        dispatch({ type: AUTH_LOGOUT_OTHERINFO });
    };

    useEffect(() => {
        if (time <= 0) {
            logout();
        }
    }, [time]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((ps) => ps - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Modal setModal={() => {}}>
            <div className={styles.container}>
                Your account has been deactivated.
                <br />
                Logging out in {time}s<LoadingDots />
            </div>
        </Modal>
    );
}

export default LoggingOut;
