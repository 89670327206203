import React, { useState, useEffect, useRef } from "react";
import styles from "./CompetetionModal.module.css";
import { CircularProgress } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { FcApproval, FcGoogle } from "react-icons/fc";
import { HiKey } from "react-icons/hi";
import { BsCardHeading, BsFileSpreadsheet } from "react-icons/bs";
import { VscSymbolKeyword } from "react-icons/vsc";
import useHelperFunctions from "../../../helperFunctions";
import { openLinkWindow } from "../../../../utility/validateFunctions";

function getEdStatus(readabilityScore) {
    if (readabilityScore <= 0 || readabilityScore >= 100) {
        return "-";
    } else if (readabilityScore <= 20) {
        return "University Graduates";
    } else if (readabilityScore >= 21 && readabilityScore <= 40) {
        return "College Graduates";
    } else if (readabilityScore >= 41 && readabilityScore <= 60) {
        return "High School Students";
    } else if (readabilityScore >= 61 && readabilityScore <= 70) {
        return "Mid School Students";
    } else return "4th Grader Students";
}

const StatItem = (props) => {
    const { icon, heading, textProperty } = props;
    const Icon = icon;
    return (
        <div className={styles.statItem}>
            <div className={styles.statItemIcon}>
                <Icon />
            </div>
            <div className={styles.statItemText}>
                <div className={styles.statItemTextHeading}>{heading}</div>
                <div className={styles.statItemTextProperty}>
                    {textProperty}
                </div>
            </div>
        </div>
    );
};

const BlogPost = (props) => {
    const { title, url, date, image, text } = props;
    return (
        <div className={styles.blogContainer}>
            <div className={styles.blogHeading}>{title}</div>
            <div className={styles.blogUrl}>
                {url} - {date}
            </div>
            <div className={styles.blogImage}>
                <img src={image} />
            </div>
            <div className={styles.blogText}>{text}</div>
        </div>
    );
};
function CompetetionModal(props) {
    const { toggleModal, data } = props;
    const [html, setHtml] = useState(false);
    const [title, setTitle] = useState(false);
    const [image, setImage] = useState(false);

    const { getData } = useHelperFunctions();

    useEffect(() => {
        if (html)
            for (const ele of document
                .getElementById("competitionModalData")
                .getElementsByTagName("a")) {
                ele.setAttribute("target", "_blank");
            }
    }, [html]);

    let postRef = useRef(null);
    useEffect(() => {
        const close = (e) => {
            if (e.key === "Escape") {
                toggleModal(false);
            }
        };
        document.addEventListener("keydown", close);

        (async () => {
            const res = await getData({
                url: "/api/scrape/getPageContent?url_id=" + data.url_id,
            });
            console.log(res);
            if (res.status == 200) {
                if (res.data) {
                    setHtml(res.data.content);
                    setImage(res.data.image);
                    setTitle(res.data.title);
                } else {
                    setHtml(
                        `<h3 style="text-align:center" > Article cannot be loaded!. By clicking above button, You can look for original.  </h3>`,
                    );
                }
            }
        })();

        //listen for link click events at the document level

        return () => {
            document.removeEventListener("keydown", close);
        };
    }, []);

    return (
        <div
            className={styles.modalContainer}
            onClick={(e) => {
                toggleModal(false);
            }}
        >
            <div onClick={(e) => e.stopPropagation()} className={styles.main}>
                <div className={styles.header}>
                    <a
                        onClick={(e) => openLinkWindow(e, data.url)}
                        className={styles.viewMoreTag}
                        target="_blank"
                    >
                        <div className={styles.viewMore}>
                            VIEW ORIGINAL{" "}
                            <span className={styles.source}>
                                <FiExternalLink />
                            </span>
                        </div>
                    </a>
                    <div
                        className={styles.closeIcon}
                        onClick={() => {
                            toggleModal(false);
                        }}
                    >
                        <AiOutlineClose />
                    </div>
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.left}>
                        <div className={styles.statistics}>
                            {data.word_count != null && (
                                <StatItem
                                    icon={VscSymbolKeyword}
                                    heading={data.word_count}
                                    textProperty={"word count"}
                                />
                            )}
                            {data.rank != null && (
                                <StatItem
                                    icon={FcGoogle}
                                    heading={data.rank}
                                    textProperty={"google rank"}
                                />
                            )}
                            {/* {data.readability != null && (
                                <StatItem
                                    icon={BsFileSpreadsheet}
                                    heading={getEdStatus(data.readability)}
                                    textProperty={"readability"}
                                />
                            )} */}
                            {data.percent_present != null && (
                                <StatItem
                                    icon={BsCardHeading}
                                    heading={data.htags_count}
                                    textProperty={"heading count"}
                                />
                            )}
                            {data.keyword_frequency != null && (
                                <StatItem
                                    icon={HiKey}
                                    heading={data.keyword_frequency}
                                    textProperty={
                                        "keyphrase occurrence frequency"
                                    }
                                />
                            )}
                            {data.percent_present != null && (
                                <StatItem
                                    icon={FcApproval}
                                    heading={data.grade}
                                    textProperty={"SEO Score"}
                                />
                            )}
                        </div>
                        <div className={styles.metaData}>
                            {/* <div className={styles.metaDataHeading}>Meta data</div> */}
                            <div className={styles.metaDataTextContainer}>
                                {/* <div>
                  <span className={styles.metaDataSubheading}>Title:</span>{" "}
                  {data.url_title}
                </div> */}
                                <div>
                                    <span className={styles.metaDataSubheading}>
                                        Description:
                                    </span>{" "}
                                    {data.description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ justifyContent: html ? "unset" : "center" }}
                        className={styles.right}
                        id="competitionModalData"
                    >
                        {!html ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <h2>{title}</h2>
                                {image && (
                                    <img
                                        style={{ marginBottom: "20px" }}
                                        width="300"
                                        src={image}
                                    />
                                )}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: html,
                                    }}
                                    className={styles.rightIframe}
                                ></div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompetetionModal;
