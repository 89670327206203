import React, {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";

import useHelperFunctions from "../../../helperFunctions";

import {
    CRUISE_TYPES,
    setOutlinesInPriorityOrder,
} from "../GoNuts/useCruiseHook";

import useCruiseBriefReducers from "../GoNuts/useCruiseBriefReducers";

export const BRIEF_TYPES = {
    SET_BRIEF: "set_loading",
    SET_TITLE: "set_title",
    ADD_TO_BRIEF: "add_to_brief",
    REORDER_BRIEF: "reorder_brief",
    EDIT_IN_BRIEF: "edit_in_brief",
    DELETE_FROM_BRIEF: "delete_from_brief",
    UPDATE_ID: "update_id",
    ADD_REFERENCE: "add_reference",
    DELETE_REFERENCE: "delete_reference",
    ORDER_REFERENCE: "order_reference",
    EDIT_REFERENCE: "edit_reference",
    ADD_ADDITIONAL_KEYWORDS: "add_additional_keywords",
    ADD_ADDITIONAL_KEYWORDS: "edit_additional_keywords",
    ADD_ADDITIONAL_NOTES: "add_additional_notes",
    EDIT_ADDITIONAL_NOTES: "edit_additional_notes",
    EDIT_SUBDATA: "EDIT_SUBDATA",
};

const initialState = {
    title: "",

    additional_keywords: "",

    outlines: {
        headings: [{ data: "", outlineH3s: [{}, {}, {}] }],
        questions: [],
    },

    invalidAction: "",
    additionalNotesEB: "",
    additional_keywords: "",
    guideLineData: {
        word_count: "",
        heading_count: "",
        image_count: "",
    },
    referencesEB: [],
};

export const useEditorBriefHook = ({
    report_id,
    isShared,
    goNutsModal,
    seoWithCruise,
    reportLoading,
}) => {
    const { postData, getData } = useHelperFunctions();
    const { editorBriefReducer } = useCruiseBriefReducers();
    const [editorBrief, dispatchEditorBrief] = useReducer(
        editorBriefReducer,
        initialState,
    );
    let [confirmPopup, setConfirmPopup] = useState({ value: "", state: false });
    let [loaded, setLoaded] = useState(false);
    const [resetStepCalled, setResetStepCalled] = useState(false);
    const isMounted = useRef(false);

    let [successMsg, setSuccessMsg] = useState({
        show: false,
        msg: "",
        type: "success",
    });

    useEffect(() => {
        if (report_id && !goNutsModal && !reportLoading)
            getEditorBrief(report_id);
    }, [report_id, goNutsModal, reportLoading]);

    const getEditorBrief = useCallback(async (report_id) => {
        setLoaded(false);
        const res = await getData({
            url: `/api/creator/content-brief?report_id=${report_id}`,
            optionalToken: isShared ? true : false,
        });

        if (res.status == 200) {
            console.log(res.data);

            const outlines = setOutlinesInPriorityOrder(
                res?.data?.outlines,
                res?.data?.h3Outlines,
                res?.data?.headings_order,
                res?.data?.questions_order,
            );
            dispatchEditorBrief({
                type: CRUISE_TYPES.SET_CRUISE_DATA,
                data: {
                    outlines: {
                        headings: outlines?.headings || [],
                        questions: outlines?.questions || [],
                    },
                    title: res.data.title || "",

                    referencesEB: res.data.refs || [],
                    additionalNotesEB: res.data.notes || "",
                    additional_keywords: res.data.additional_keywords || "",
                    guideLineData: res.data.guideline || {},
                    report_id: report_id,
                    loaded: true,
                    tone: res.data?.tone,
                    ranks: res.data?.ranks ? JSON.parse(res.data?.ranks) : [],
                },
            });
            setResetStepCalled(false);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
    }, []);

    const resetCruiseStepToOutline = async (step) => {
        postData({
            url: "/api/contentDraft/v2/save",
            payload: { step_status: step, report_id: report_id },
        });
    };

    const resetConclusion = () => {
        postData({
            url: "/api/contentDraft/v2/update",
            payload: {
                conclusion: "",
                field: "paragraph",
                report_id: report_id,
            },
        });
    };

    useEffect(() => {
        if (loaded && !resetStepCalled && editorBrief.loaded) {
            if (editorBrief?.title?.trim()) {
                resetCruiseStepToOutline(2);
            } else {
                resetCruiseStepToOutline(1);
            }

            resetConclusion();

            setResetStepCalled(true);
        }
    }, [editorBrief.outlines, editorBrief.title]);

    let changeTitleEB = (value) => {
        setConfirmPopup({ value, state: true });
    };

    return {
        editorBrief,
        dispatchEditorBrief,
        confirmPopup,
        loading: !loaded,
        successMsg,
        setSuccessMsg,
    };
};
