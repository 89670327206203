import React, { useContext, useEffect, useRef, useState } from "react";
import { BsTagFill } from "react-icons/bs";
import { FiCheckSquare } from "react-icons/fi";
import { IoIosSquareOutline } from "react-icons/io";
import Button from "../../atoms/Button/Button";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import { TagsContext } from "../../Pages/TagsContext";
import CheckBox from "./CheckBox";
import styles from "./styles.module.css";
function sortBySelection(tag1, tag2, selectedTags) {
    return (
        (selectedTags.includes(tag2.id) ? 1 : 0) -
        (selectedTags.includes(tag1.id) ? 1 : 0)
    );
}

const manageStyle = {
    position: "absolute",
    top: "9px",
    right: "5px",
    fontSize: "12px",
    fontWeight: " 600",
    color: "#024dc5",
};

function getOptions(tags, selectedTags, setSelectedTags, search) {
    let options = tags
        .filter((tag) => tag.tag_name.includes(search))
        .map((tag) => (
            <CheckBox
                key={tag.id}
                tag={tag}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                term={tag.tag_name}
            ></CheckBox>
        ));

    if (options.length > 0) {
        return options;
    } else {
        return null;
    }
}

export default function AllTags({
    report_id,
    doc_id,
    _tags,
    setTags,
    setTagsModal,
    disabled = false,
    cluster,
}) {
    const [selectedTags, setSelectedTags] = useState([..._tags]);
    const [allTags, setAllTags] = useState([]);
    const [search, setSearch] = useState("");
    const [loding, setLoading] = useState(false);
    const { loading, createTag, addTag, tags, getContainerRef, confirmModal } =
        useContext(TagsContext);
    const ref = useRef();
    const refBox = useRef();
    useOnClickOutside(ref, () => setTagsModal(false));

    useEffect(() => {
        if (refBox.current) {
            getContainerRef(refBox.current, manageStyle);
        }
    }, [refBox]);

    useEffect(() => {
        setAllTags(() =>
            [...tags].sort((tag1, tag2) =>
                sortBySelection(
                    tag1,
                    tag2,
                    selectedTags.map((tag) => tag.id),
                ),
            ),
        );
    }, [tags]);

    useEffect(() => {
        const handler = (e) => {
            if (
                e.key == "Enter" &&
                search.trim() &&
                !tags
                    .map((tag) => tag.tag_name.toLowerCase())
                    .includes(search.trim().toLowerCase())
            ) {
                (async () => {
                    const tag = await createTag(search);
                    if (tag) {
                        setSelectedTags((pre) => [...pre, tag]);
                    }
                })();
            }
        };

        document.addEventListener("keyup", handler);
        return () => document.removeEventListener("keyup", handler);
    }, [search, tags]);

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            ref={ref}
            className={styles.tagsStyle1}
        >
            {!disabled ? (
                <div
                    style={{ overflow: "unset", maxHeight: "300px" }}
                    className={styles.checkboxes}
                >
                    <input
                        autoFocus={true}
                        disabled={loading}
                        value={search}
                        onChange={(e) =>
                            e.target.value.length < 21 &&
                            setSearch(e.target.value)
                        }
                        placeholder={"Search tags or create new (max 20 chars)"}
                        type="text"
                    />
                    <div
                        ref={refBox}
                        style={{
                            overflow: "auto",
                            maxHeight: "150px",
                            position: "relative",
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {
                            <>
                                {" "}
                                {search.trim() &&
                                    !tags
                                        .map((tag) =>
                                            tag.tag_name.toLowerCase(),
                                        )
                                        .includes(
                                            search.trim().toLowerCase(),
                                        ) && (
                                        <div
                                            onClick={() =>
                                                (async () => {
                                                    if (loading) return;

                                                    setLoading(true);
                                                    const tag = await createTag(
                                                        search,
                                                    );
                                                    if (tag) {
                                                        setSelectedTags(
                                                            (pre) => [
                                                                ...pre,
                                                                tag,
                                                            ],
                                                        );
                                                    }
                                                    setLoading(false);
                                                })()
                                            }
                                            className={styles.newTagCreate}
                                        >
                                            <BsTagFill color="#014dc5" />{" "}
                                            <span>
                                                Create new tag : '
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {search}
                                                </span>
                                                '
                                                {loading ? (
                                                    <LoadingDots />
                                                ) : null}
                                            </span>
                                        </div>
                                    )}{" "}
                                {!search && (
                                    <span
                                        style={{
                                            width: "100%",
                                            marginBottom: "5px",
                                            height: "35px",
                                            color: "gray",
                                            borderBottom: "solid lightgray 1px",
                                        }}
                                        className={styles.checkbox}
                                    >
                                        {selectedTags.length != tags.length ? (
                                            <IoIosSquareOutline
                                                onClick={() =>
                                                    setSelectedTags(tags)
                                                }
                                                size={22}
                                            />
                                        ) : (
                                            <FiCheckSquare
                                                onClick={() =>
                                                    setSelectedTags([])
                                                }
                                                color="#014dc5"
                                            />
                                        )}{" "}
                                        All tags
                                    </span>
                                )}
                                {getOptions(
                                    allTags,
                                    selectedTags,
                                    setSelectedTags,
                                    search,
                                )}
                            </>
                        }
                    </div>

                    {!confirmModal && (
                        <div className={styles.buttonRow}>
                            <span
                                style={{
                                    width: "53px",
                                    height: "24px",

                                    padding: "6px 19px",
                                    borderRadius: 3,

                                    fontSize: "10px",
                                    fontWeight: 500,
                                    marginRight: "10px",
                                    letterSpacing: "0.08px",
                                    color: "#ababab",
                                }}
                                onClick={() => setTagsModal(false)}
                            >
                                Cancel{" "}
                            </span>
                            <Button
                                width={80}
                                height={40}
                                text="OK"
                                style={{
                                    width: "60px",
                                    height: "30px",

                                    padding: "6px 19px",
                                    borderRadius: 3,
                                    backgroundColor: "#014dc5",

                                    fontSize: "10px",
                                    fontWeight: 500,

                                    letterSpacing: "0.08px",
                                }}
                                handler={() => {
                                    (async () => {
                                        addTag(
                                            { tags: selectedTags },
                                            report_id || doc_id,
                                            report_id,
                                            doc_id,
                                            cluster,
                                        );
                                        setTags(selectedTags);
                                        setTagsModal(false);
                                    })();
                                }}
                                loading={loading}
                                disabled={loading}
                            ></Button>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div
                        style={{ overflow: "unset", maxHeight: "300px" }}
                        className={styles.checkboxes}
                    >
                        <h3>Tags</h3>
                        <div
                            style={{
                                overflow: "auto",
                                maxHeight: "150px",
                                margin: "15px 0 15px 0",
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {_tags.map((tag) => (
                                <span
                                    style={{
                                        width: "100%",
                                        marginBottom: "5px",
                                        height: "35px",
                                        color: "gray",
                                        borderBottom: "solid lightgray 1px",
                                    }}
                                    className={styles.checkbox}
                                >
                                    {tag.tag_name}
                                </span>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
