import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Routes from "./NewRoutes";
import { askNotificationRequest } from "./firebase";
import AlertMessage from "./componentsV2/atoms/AlertMessage/AlertMessage";
import TagManager from "react-gtm-module";
import { PROD, TALENT_DOMAIN, SMARTLOOK_KEY } from "./utility/config";
import { displayAlert } from "./store/actions/alert";
import OnBoardingContextProvider from "./componentsV2/Context/OnBoardingContext/OnBoardingContextProvider";
import { version } from "./version.json";
import { useState } from "react";
import Progress from "./componentsV2/atoms/Progress/Progress";
import { DeviceUUID } from "device-uuid";
import { loadAppConfig } from "./store/actions/appConfig";
import { scalenutAPIEvent } from "./constants";
import { version as APP_VERSION } from "./../package.json";
import * as Sentry from "@sentry/react";
import Axios from "axios";
import { updateUserInfo } from "./store/actions/auth";
import { ErrorPage } from "./componentsV2/molecules/ErrorBoundary/ErrorBoundary";
import { OverlayVideoContianer } from "./componentsV2/organisms/Overlay/OverlayVideo";
import { userGuidingHelper } from "./utility/userGuidingHelper";
import mixpanel from "mixpanel-browser";
import { getPlanInfo } from "./utility/useMixpanelHook";
import { setIsMobile } from "./store/actions/state";
import { attachInspectLet } from "./loadThirdPartyScripts";
import useNetworkInterceptor from "./utility/useNetworkInterceptor";
import ErrorBoundaryComponent from "./componentsV2/atoms/ErrorBoundaryComponent";
console.log(version, APP_VERSION);
const tagManagerArgs = {
    gtmId: "GTM-PWZP6XQ",
    dataLayer: { js: new Date(), config: "AW-609451118" },
};

if (PROD) {
    TagManager.initialize(tagManagerArgs);
}

try {
    if (PROD) {
        console.log = function () {};
    }
} catch (error) {
    console.log("console true");
}

let inActivityDuration = 0;
export let device_token = {};

function App() {
    const alert = useSelector((state) => state.alert);
    const userInfo = useSelector((state) => state.auth);
    const subscriptionInfo = useSelector(
        (state) => state.otherInfo?.subscriptionInfo,
    );
    const [deviceTokenLoading, setDeviceTokenLoading] = useState(true);
    const {
        loading: configLoading,
        sessionValidityTimeout,
        focus_countries,
    } = useSelector((state) => state.appConfig);
    const dispatch = useDispatch();
    useNetworkInterceptor();
    const urlParams = new URLSearchParams(location.search);

    const queryContext = urlParams.get("context") || null;
    const spinWheel = urlParams.get("spinwheel");

    useEffect(() => {
        sessionStorage.setItem("context", queryContext);
        sessionStorage.setItem("spinwheel", spinWheel || "null");
        return () => {
            sessionStorage.removeItem("exitIntent");
        };
    }, []);

    // User Guide
    useEffect(() => {
        (async () => {
            try {
                const res = await Axios.get("https://ipapi.co/json/");

                dispatch(updateUserInfo("country", res.data.country_name));
            } catch (error) {
                dispatch(updateUserInfo("country", "unknown"));
            }
        })();
    }, []);

    useEffect(() => {
        if (userInfo?.userId && PROD && userInfo.email) {
            userGuidingHelper(userInfo.userId, {
                name: userInfo.user_name,
                email: userInfo.email,
                mode: userInfo.signup_mode,
                country: userInfo.country,
                signup_date: userInfo.created_at,
                user_guiding: userInfo.userGuiding,
            });

            Sentry.setUser({ email: userInfo.email });
        }
        if (userInfo?.userId) {
            if (userInfo?.segment == 3) {
                attachInspectLet(userInfo);
            }
            try {
                window.clarity("identify", userInfo.email, userInfo.userId);
                window.clarity("set", "email", userInfo.email);
            } catch (error) {
                console.log(error);
            }
        }
    }, [userInfo?.userId]);

    useEffect(() => {
        if (userInfo?.userId) {
            //identify
            mixpanel.identify(userInfo.email);
            //register
            mixpanel.register({
                email: userInfo.email,
                "Host Name": window.location.hostname,
                "Plan Type": subscriptionInfo.plan_id
                    ? getPlanInfo({
                          category: subscriptionInfo?.plan_category,
                          name: subscriptionInfo?.plan_name,
                          type: subscriptionInfo?.type,
                      })
                    : "-",
                "Signup Mode": userInfo.signup_mode == 1 ? "Mode 1" : "Mode 2",
                "User Segment": userInfo.segment ? userInfo.segment : "-",
                "FE Segment": userInfo?.userId % 2 == 1 ? "A" : "B",
            });

            //user properties
            mixpanel.people.set({
                $email: userInfo.email,
                $name: userInfo.name,
                "Plan Type": subscriptionInfo.plan_id
                    ? getPlanInfo({
                          category: subscriptionInfo?.plan_category,
                          name: subscriptionInfo?.plan_name,
                          type: subscriptionInfo?.type,
                      })
                    : "-",
                "Signup Mode": userInfo.signup_mode == 1 ? "Mode 1" : "Mode 2",
                "User Segment": userInfo.segment ? userInfo.segment : "-",
            });
        }
    }, [subscriptionInfo, userInfo?.userId]);

    useEffect(() => {
        //Notification permission
        try {
            if (userInfo.token && Notification.permission == "default") {
                askNotificationRequest(userInfo.token);
            }
        } catch (error) {
            console.log(error);
        }
        //Providing Auth to Talent network

        try {
            window.opener.postMessage(
                JSON.stringify({
                    token: userInfo.token,
                    channel: "authFromDashboard",
                }),
                TALENT_DOMAIN,
            );
        } catch (error) {
            console.log(error);
        }

        try {
            const port = chrome.runtime.connect(
                "hihmdflioglieahhkcllmngdmpijboae",
                {
                    name: "scalenut",
                },
            );
            port.postMessage({ token: userInfo.token });
            port.postMessage({ cloud: userInfo });
        } catch (e) {
            console.log(e, "/*******************************************");
        }
    }, [userInfo.token]);

    useEffect(() => {
        const update = () => {
            if (inActivityDuration > sessionValidityTimeout) {
                window.location = window.location;
            } else {
                inActivityDuration = 0;
            }
        };
        window.addEventListener(scalenutAPIEvent, update);

        return () => window.removeEventListener(scalenutAPIEvent, update);
    }, [sessionValidityTimeout]);

    useEffect(() => {
        dispatch(loadAppConfig());
        localStorage.setItem("megaSalePopup", false);
        setInterval(() => {
            inActivityDuration = inActivityDuration + 1;
        }, 1000);

        try {
            const uuid = new DeviceUUID().get();
            device_token.device_token = uuid;
            setDeviceTokenLoading(false);
        } catch (error) {
            console.log(error);
            setDeviceTokenLoading(false);
        }

        window.onstorage = (e) => {
            if (e.key == "token" && localStorage.getItem("token")) {
                window.location = "";
            }
        };

        window.addEventListener("offline", function (e) {
            dispatch(
                displayAlert({
                    alertMessage: "You are Offline",
                    alertType: "error",
                }),
            );
        });

        window.addEventListener("online", function (e) {
            dispatch(
                displayAlert({
                    alertMessage: "You are Online",
                    alertType: "success",
                }),
            );
        });
        try {
            const observer = new ResizeObserver(() => {
                dispatch(
                    setIsMobile(document.body.clientWidth > 600 ? false : true),
                );
            });

            observer.observe(document.getElementById("root"));
        } catch (e) {
            dispatch(
                displayAlert({
                    alertMessage: "Update your browser for better experience",
                    alertType: "error",
                }),
            );
        }
    }, []);

    return (
        <ErrorBoundaryComponent
            fallback={({ error, componentStack }) => (
                <ErrorPage
                    errorInfo={{ error, componentStack }}
                    userInfo={userInfo}
                />
            )}
        >
            <BrowserRouter>
                {alert.alertMessage && (
                    <AlertMessage
                        text={alert.alertMessage}
                        error={alert.alertType == "error" ? true : false}
                        timeout={alert.timeout}
                    />
                )}
                <OnBoardingContextProvider>
                    {deviceTokenLoading || configLoading ? (
                        <Progress height="100vh" />
                    ) : (
                        <Routes />
                    )}
                </OnBoardingContextProvider>
            </BrowserRouter>
            <ErrorBoundaryComponent fallback={() => ""}>
                <OverlayVideoContianer />
            </ErrorBoundaryComponent>
        </ErrorBoundaryComponent>
    );
}

export default App;
