import React, { useEffect, useState } from "react";
import screens from "../../../screens.json";
import SVGIcon from "../../atoms/SVGIcon";
import AccountIcon from "./AccountIcon";
import { ASIDE_OPTIONS_ID } from "./constants";
import { TALENT_DOMAIN } from "../../../utility/config";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import { BsDot } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RiLinksFill } from "react-icons/ri";
import { SiProbot } from "react-icons/si";
export const useAsideOptions = () => {
    const { freeUser, isTeamMember, LockConfigurator } = useUserAccessHook();
    const { subscriptionInfo } = useSelector((state) => state.otherInfo);

    return [
        {
            title: "Dashboard",
            icon: (
                <SVGIcon
                    color="#000000a6"
                    size={22}
                    src="/New UI/aside-section/dashboard.svg"
                />
            ),
            screen: "DASHBOARD",
            paths: [screens.DASHBOARD],
            id: ASIDE_OPTIONS_ID.DASHBOARD,
            gtag: "gt-aside-dashboard",
        },

        {
            title: "Article Writer",
            icon: (
                <SVGIcon
                    color="#000000a6"
                    size={22}
                    src={"/New UI/aside-section/articleWriter.svg"}
                />
            ),
            screen: "CREATOR",
            paths: [screens.CREATOR],
            gtag: "gt-aside-seo-docs",
            superText: true,
            superTextComp: (
                <div
                    className={`${styles.superText} ${styles.superTextUpgrade}`}
                    // onClick={(e) => e.stopPropagation()}
                    // to={screens.BILLING_PLANS}
                >
                    Cruise Mode
                </div>
            ),
            id: ASIDE_OPTIONS_ID.ARTICLE_WRITER,
        },
        {
            title: "Content Optimizer",
            icon: (
                <SVGIcon
                    size={22}
                    color="#000000a6"
                    src={"/New UI/aside-section/optimizer.svg"}
                />
            ),
            screen: "AUDIT",
            superText: LockConfigurator(FEATURE_TYPES.contentAuditLock)
                ? true
                : false,
            superTextComp: <></>,
            paths: [screens.AUDIT],
            gtag: "gt-aside-audit",
            id: ASIDE_OPTIONS_ID.OPTIMIZER,
        },
        {
            title: "Keyword Planner",
            icon: (
                <SVGIcon
                    size={22}
                    color="#000000a6"
                    src={"/New UI/aside-section/keywordPlanner.svg"}
                />
            ),
            screen: "CLUSTER",
            superText: LockConfigurator(FEATURE_TYPES.topicClusterLock)
                ? true
                : false,

            superTextComp: <></>,
            // superText: true,
            // superTextComp: <span className={styles.superText}>Beta</span>,
            paths: [screens.CLUSTER],
            gtag: "gt-aside-topic-clustering",
            id: ASIDE_OPTIONS_ID.CLUSTER,
        },
        {
            title: "Traffic Analyzer",
            icon: (
                <SVGIcon
                    color="#000000a6"
                    size={22}
                    src={"/New UI/aside-section/trafficAna.svg"}
                />
            ),
            screen: "ANALYSIS_INSIGHTS",
            paths: [screens.ANALYSIS_INSIGHTS],
            id: ASIDE_OPTIONS_ID.Traffic_Analyzer,
            superText: true,
            // superTextComp: (
            //     <div
            //         className={`${styles.superText} ${styles.superTextUpgrade}`}
            //         // onClick={(e) => e.stopPropagation()}
            //         // to={screens.BILLING_PLANS}
            //     >
            //         New
            //     </div>
            // ),
        },
        {
            title: "Link Manager",
            icon: <RiLinksFill color="#000000a6" size={22} />,
            screen: "LINK_MANAGER",
            paths: [screens.LINK_MANAGER],
            id: ASIDE_OPTIONS_ID.Link_Manager,
        },
        {
            title: "Detect and Humanize",
            icon: (
                <SVGIcon
                    color="#000000a6"
                    size={24}
                    src={"/New UI/aside-section/humanizer.svg"}
                />
            ),
            screen: "AI_HUMANIZER",
            paths: [screens.AI_HUMANIZER],
            id: ASIDE_OPTIONS_ID.AI_HUMANIZER,
            superText: true,
            // superTextComp: (
            //     <div
            //         className={`${styles.superText} ${styles.superTextUpgrade}`}
            //     >
            //         Beta
            //     </div>
            // ),
        },
        {
            title: "SEO Surge",
            icon: (
                <SVGIcon
                    color="#000000a6"
                    size={22}
                    src="/New UI/aside-section/seoSurge.svg"
                />
            ),
            screen: "SEO_SURGE",
            paths: [screens.SEO_SURGE],
            id: ASIDE_OPTIONS_ID.SEO_SURGE,
            gtag: "gt-aside-seo-surge",
            superText: true,
            superTextComp: (
                <div
                    className={`${styles.superText} ${styles.superTextUpgrade}`}
                    style={{
                        backgroundColor: "#1f4cbe",
                        color: "white",
                    }}
                >
                    New
                </div>
            ),
        },

        // {
        //     title: "Marketing Copywriter",
        //     icon: (
        //         <SVGIcon
        //             color="#000000a6"
        //             size={22}
        //             src={"/New UI/aside-section/copywriter.svg"}
        //         />
        //     ),
        //     screen: "COPYWRITER",

        //     paths: [screens.COPYWRITER],
        //     id: ASIDE_OPTIONS_ID.SMART_EDITOR,
        // },
        // webtune == 1 && {
        //     title: "Webtune AI",
        //     icon: (
        //         <SVGIcon
        //             color="#000000a6"
        //             size={22}
        //             src={"/New UI/SVG/webtuneLogo.svg"}
        //         />
        //     ),
        //     screen: "WEBTUNE",

        //     paths: [screens.WEBTUNE],
        //     id: ASIDE_OPTIONS_ID.webtune,
        // },
        // {
        //   title: "Archive",
        //   icon: <SVGIcon color="#000000a6" src={"/New UI/aside-section/trash.svg"} />,
        //   screen: "CREATOR_BIN",
        //   paths: [screens.CREATOR_BIN, screens.SMART_DOCS_BIN],
        //   separator: true,
        //   id: ASIDE_OPTIONS_ID.ARCHIVE,
        //   gtag: "gt-aside-archive",
        // },
        // {
        //   title: "AI Outputs",
        //   icon: <SVGIcon color="#000000a6" src={"/New UI/aside-section/history.svg"} />,
        //   screen: "AI_OUTPUTS_FAV",
        //   paths: [screens.AI_OUTPUTS_FAV, screens.AI_OUTPUTS_HIS],
        //   separator: true,
        //   id: ASIDE_OPTIONS_ID.AI_OUTPUTS,
        //   gtag: "gt-aside-outputs",
        // },
        // {
        //     marginTop: true,
        //     title: "App Integrations",
        //     icon: <SVGIcon color="#000000a6" src={"/New UI/aside-section/IntegrationHub.svg"} />,
        //     screen: "INTEGRATION_HUB",
        //     superText: LockConfigurator(FEATURE_TYPES.appIntegrationLock)
        //         ? true
        //         : false,
        //     superTextComp: <></>,
        //     paths: [
        //         screens.INTEGRATION_HUB,
        //         window.location.pathname.includes("integration/app") &&
        //             window.location.pathname,
        //     ],
        //     id: ASIDE_OPTIONS_ID.INTEGRATION_HUB,
        //     // gtag: "gt-aside-integration",
        // },
        // {
        //     marginTop: true,
        //     title: "Talent Network",
        //     icon: <SVGIcon color="#000000a6" src="/New UI/aside-section/talent.svg" />,
        //     screen: "TALENT_NETWORK",
        //     redirect: TALENT_DOMAIN,
        //     paths: [],
        //     id: ASIDE_OPTIONS_ID.TALENT,
        //     gtag: "gt-aside-talent-network",
        // },
        // {
        //     marginTop: isTeamMember ? true : false,
        //     icon: <SVGIcon color="#000000a6" src={"/New UI/aside-section/Giftwrappedicon.svg"} />,
        //     title: "Earn Credits",
        //     paths: [],
        //     id: ASIDE_OPTIONS_ID.EARN_CREDITS,
        //     redirect: "https://www.scalenut.com/earn-credits",
        //     // gtag: freeUser ? "gt-aside-upgrade" : "gt-aside-my-account",
        // },

        // {
        //     marginTop: isTeamMember ? true : false,
        //     title: "My Account",
        //     icon: <AccountIcon />,
        //     screen: freeUser ? "BILLING_PLANS" : "ACCOUNT",
        //     paths: [
        //         screens.USER_PROFILE,
        //         screens.BILLING_PLANS,
        //         screens.TRANSACTIONS,
        //     ],
        //     superText: freeUser ? true : false,

        //     superTextComp: (
        //         <Link
        //             className={`${styles.superText} ${styles.superTextUpgrade}`}
        //             onClick={(e) => e.stopPropagation()}
        //             to={screens.BILLING_PLANS}
        //         >
        //             Upgrade
        //         </Link>
        //     ),
        //     id: ASIDE_OPTIONS_ID.MY_ACCOUNT,
        //     gtag: freeUser ? "gt-aside-upgrade" : "gt-aside-my-account",
        // },
    ];
};
