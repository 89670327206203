import React from "react";

const defaultState = {
  aiWordLimitSetting: {},
  writingState: {},
  setWritingState: () => {},
  writeWithAnimation: () => {},
  docId: "",
  docName: "",
  versionData: {},
};

const EditorContext = React.createContext(defaultState);

export default EditorContext;
