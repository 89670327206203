import React, { useState, useEffect } from "react";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import styles from "./planDetails.module.css";
import { TERMINATE_SUBS } from "../../componentsV2/api.json";
import Confirmation from "../../componentsV2/atoms/ConfirmationDialog/confirmation";
import { useDispatch, useSelector } from "react-redux";
const arr = {};
import { LightTooltip } from "../../componentsV2/atoms/Tooltip/tooltip";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import { getShortDateTime } from "../../utility/validateFunctions";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { VscClose } from "react-icons/vsc";
const getRightContent = (used, limit) => {
    if (limit == -1) {
        return `${used}/UNLIMITED`;
    } else {
        return `${used}/${limit}`;
    }
};
import { BsCheckCircle, BsDownload } from "react-icons/bs";
import { CircularProgress, colors } from "@material-ui/core";
import CloseButton from "../../componentsV2/atoms/CloseButton/CloseButton";
import {
    ADD_ONS_TYPE,
    INTERLINKING_STATUS,
    PLANS_CATEGORIES,
} from "../../constants";
import useNavigation from "../../componentsV2/atoms/GoToRoute/useNavigation";
import { updateOtherInfo } from "../../store/actions/otherInfo";

import CancellationFlow from "./CancellationFlow";
import Button from "../../componentsV2/atoms/Button/Button";
import useUserAccessHook from "../account/useUserAccessHook";

const humanizerTerminateText =
    "You want to terminate Detect and Humanize subscription";
const lmTerminatingText =
    "Terminating the Link Manager will remove all the changes made to connected domains";
function PlanDetails({ details, setPlanDetails }) {
    console.log(details);
    const { getData } = useHelperFunctions();
    const { isLTD } = useUserAccessHook();
    const [terminateModal, setTerminateModal] = useState(false);
    const [lmTerminateModal, setLmTerminateModal] = useState(false);
    const [humanizeTerminateModal, setHumanizeTerminateModal] = useState(false);
    const [terminatingLm, setTerminatingLm] = useState(false);
    const [terminatingHumanizer, setTerminatingHumanizer] = useState(false);
    const [update, setUpdate] = useState(false);
    const [loader, setLoader] = useState(false);
    const { postData } = useHelperFunctions();
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [upcominginfo, setUpcomingInfo] = useState({});
    const [credinfo, setCredInfo] = useState({});
    const otherInfo = useSelector((store) => store.otherInfo);
    const goTo = useNavigation();
    const dispatch = useDispatch();
    const InvoiceContainer = ({ setInvoiceModal }) => {
        const [list, setList] = useState([]);
        const [loading, setloading] = useState(true);
        useEffect(() => {
            const fuc = async () => {
                const res1 = await getData({ url: "/api/pricing/invoices" });
                if (res1.status == 200) {
                    setList(res1?.data?.invoices?.data);
                }
                setloading(false);
            };

            fuc();
        }, []);

        return (
            <div className={styles.planDetailsInvoices}>
                <div>
                    {/* <div className={styles.header}>
            <div className={styles.headerLeft}>Balance:</div>
            <div className={styles.headerRight}>{something}</div>
          </div> */}
                    <CloseButton setClose={setInvoiceModal} />
                    <div className={styles.infoDiv}>
                        <div
                            className={styles.infoDivHeader}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <span style={{ flexGrow: 1 }}>
                                Invoice History{" "}
                            </span>{" "}
                            {loading ? (
                                <CircularProgress size={18} />
                            ) : (
                                <VscClose
                                    onClick={() => setInvoiceModal(false)}
                                    style={{
                                        cursor: "pointer",
                                        color: "#6b6b6b",
                                    }}
                                    size={20}
                                />
                            )}
                        </div>
                        <div className={styles.infoDivTermContainer}>
                            {list.map((item) => (
                                <div className={styles.infoDivTerm}>
                                    <div className={styles.infoIn}>
                                        <div className={styles.infoTermText}>
                                            {item.status == "paid" ? (
                                                <BsCheckCircle
                                                    color="green"
                                                    size={16}
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                            ) : (
                                                <AiOutlineCloseCircle
                                                    color="orange"
                                                    size={16}
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                            )}
                                            <div>
                                                <a
                                                    href={
                                                        item.hosted_invoice_url
                                                    }
                                                    target="_blank"
                                                    style={{
                                                        color: "#014dc5",
                                                        textDecoration: "none",
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                >
                                                    {item.billing_reason
                                                        .split("_")
                                                        .join(" ")}
                                                </a>

                                                <div
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    {`${
                                                        item?.currency == "usd"
                                                            ? "$"
                                                            : "₹"
                                                    }${(
                                                        item?.total / 100
                                                    ).toLocaleString(
                                                        item.currency != "usd"
                                                            ? "en-in"
                                                            : "en-us",
                                                    )}${
                                                        item.status == "paid"
                                                            ? " charged on"
                                                            : " failed on"
                                                    } ${new Date(
                                                        (item.status == "paid"
                                                            ? item
                                                                  .status_transitions
                                                                  .paid_at
                                                            : item.created) *
                                                            1000,
                                                    )
                                                        .toDateString()
                                                        .replace(" ", ", ")}`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.infoTermVal}>
                                            <a
                                                href={item.invoice_pdf}
                                                target="_blank"
                                                download
                                            >
                                                <BsDownload
                                                    size={16}
                                                    cursor="pointer"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleTerminate = async (closeDialog) => {
        const res = await postData({
            url: TERMINATE_SUBS,
        });

        if ((res.status = 200)) {
            dispatch(
                updateOtherInfo({
                    ...otherInfo,
                    subscriptionInfo: {
                        ...otherInfo.subscriptionInfo,
                        interlinking_status: 0,
                        humanizer_status: 0,
                    },
                }),
            );
            setUpdate(!update);
            (async () => {
                const res = await getData({
                    url: "/api/pricing/getSubscriptionPlans",
                });
                if ((res.status = 200)) {
                    setPlanDetails(res?.data?.quota);
                }
            })();
        }

        closeDialog();
    };

    useEffect(() => {
        const func = async () => {
            const res1 = await getData({ url: "/api/pricing/upcomingInvoice" });
            if (res1.status == 200) setUpcomingInfo(res1?.data?.upcoming);
        };
        func();

        const func2 = async () => {
            const res1 = await getData({ url: "/api/pricing/transactions" });
            if (res1.status == 200)
                setCredInfo(res1?.data?.transactions?.data[0]);
        };
        func2();
    }, [update]);

    console.log(details);

    // useEffect(() => {
    //   const fun = async () => {
    //     const res = await window.fpr("referral", {
    //       email: userEmail,
    //     });
    //     alert("HELLO REFERED" + userEmail + res);
    //   };

    //   fun();
    // }, []);

    const handleHumanizerTerminate = async () => {
        setTerminatingHumanizer(true);
        let payload = {
            planID: otherInfo?.subscriptionInfo?.humanizer_plan_id,
            add_on: ADD_ONS_TYPE.AI_HUMANIZER,
        };
        const res = await postData({
            url: "/api/pricing/delete-add-on",
            payload,
        });
        if (res.status == 200) {
            dispatch(
                updateOtherInfo({
                    ...otherInfo,
                    subscriptionInfo: {
                        ...otherInfo.subscriptionInfo,
                        humanizer_status: 0,
                    },
                }),
            );
        } else {
        }
        setHumanizeTerminateModal(false);
        setTerminatingHumanizer(false);
    };

    const handleLmTerminate = async () => {
        setTerminatingLm(true);
        let payload = {
            planID: otherInfo?.subscriptionInfo?.interlinking_plan_id,
        };
        const res = await postData({
            url: "/api/pricing/delete-add-on",
            payload,
        });
        if (res.status == 200) {
            dispatch(
                updateOtherInfo({
                    ...otherInfo,
                    subscriptionInfo: {
                        ...otherInfo.subscriptionInfo,
                        interlinking_status: 0,
                    },
                }),
            );
        } else {
        }
        setLmTerminateModal(false);
        setTerminatingLm(false);
    };

    return (
        <>
            {details && details.plan_id != 1 ? (
                <div className={styles.planDetailsDiv}>
                    <div className={styles.mainContainer}>
                        <div className={styles.bottomLinks}>
                            {(credinfo?.ending_balance ? true : false) && (
                                <div
                                    className={styles.bottomLinksTerm}
                                    style={{
                                        cursor: "default",
                                        textDecoration: "none",
                                    }}
                                >
                                    Credit Balance: <br />
                                    <span>
                                        {" "}
                                        {` ${
                                            credinfo?.currency == "usd"
                                                ? "$"
                                                : "₹"
                                        }${(
                                            -credinfo?.ending_balance / 100
                                        ).toLocaleString(
                                            credinfo?.currency != "usd"
                                                ? "en-in"
                                                : "en-us",
                                        )}`}
                                    </span>
                                </div>
                            )}

                            {(upcominginfo?.currency ? true : false) && (
                                <div
                                    className={styles.bottomLinksTerm}
                                    style={{
                                        cursor: "default",
                                        textDecoration: "none",
                                    }}
                                >
                                    {"Next Invoice:"} <br />
                                    <span>
                                        {" "}
                                        {`${
                                            upcominginfo?.currency == "usd"
                                                ? "$"
                                                : "₹"
                                        }${(
                                            upcominginfo?.total / 100
                                        ).toLocaleString(
                                            upcominginfo?.currency != "usd"
                                                ? "en-in"
                                                : "en-us",
                                        )} on ${new Date(
                                            upcominginfo?.next_payment_attempt *
                                                1000,
                                        )
                                            .toDateString()
                                            .replace(" ", ", ")}`}{" "}
                                    </span>
                                </div>
                            )}

                            {/* {otherInfo?.subscriptionInfo?.plan_category ==
                PLANS_CATEGORIES.PRO &&
                otherInfo?.subscriptionInfo?.type != 3 && (
                  <div
                    className={styles.bottomLinksTerm}
                    onClick={() => goTo("USER_PROFILE", [], "members")}
                  >
                    Manage Members
                  </div>
                )} */}

                            {/* <div className={styles.bottomLinksTerm}>
                <a
                  href={
                    "https://invoice.stripe.com/i/acct_1HMpbJJeT6r8ty8a/invst_KC8bvKwCXjayvoSO58dPyoEUyroQg9B"
                  }
                  target="_blank"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  {"Last Invoice"}
                </a>
              </div> */}

                            {(otherInfo?.subscriptionInfo?.type != 3 ||
                                otherInfo?.subscriptionInfo?.plan_category ==
                                    PLANS_CATEGORIES.FREE_FOREVER) && (
                                <div
                                    className={styles.bottomLinksTerm}
                                    onClick={() => setInvoiceModal(true)}
                                >
                                    View Invoices
                                </div>
                            )}
                            <div className={styles.bottomLinksTerm}>
                                <a
                                    target="_blank"
                                    href="https://billing.stripe.com/p/login/4gw4jGf387Pc7cc144"
                                >
                                    Update Card Details
                                </a>
                            </div>
                            {!(
                                details?.terminate == 1 ||
                                otherInfo?.subscriptionInfo?.type == 3
                            ) && (
                                <div
                                    className={`${styles.bottomLinksTerm} ${styles.terminate}`}
                                    onClick={() => setTerminateModal(true)}
                                >
                                    Terminate Subscription
                                </div>
                            )}
                            {/* Terminate  Requirements*/}
                            {!(
                                otherInfo?.subscriptionInfo
                                    ?.interlinking_status ==
                                    INTERLINKING_STATUS.DISCONNECTED ||
                                otherInfo?.subscriptionInfo
                                    ?.interlinking_status ==
                                    INTERLINKING_STATUS.TRIAL
                            ) &&
                                otherInfo?.subscriptionInfo
                                    ?.interlinking_plan_id &&
                                !isLTD && (
                                    <div
                                        className={`${styles.bottomLinksTerm} ${styles.terminate}`}
                                        onClick={() =>
                                            setLmTerminateModal(true)
                                        }
                                    >
                                        Terminate Link Manager
                                    </div>
                                )}
                            {otherInfo?.subscriptionInfo?.humanizer_status ==
                                1 &&
                                !isLTD && (
                                    <div
                                        className={`${styles.bottomLinksTerm} ${styles.terminate}`}
                                        onClick={() =>
                                            setHumanizeTerminateModal(true)
                                        }
                                    >
                                        Terminate Detect and Humanize
                                    </div>
                                )}
                        </div>
                    </div>

                    {terminateModal && (
                        <CancellationFlow
                            setCancellation={setTerminateModal}
                            handle={handleTerminate}
                            planId={otherInfo?.subscriptionInfo?.plan_id}
                        />
                    )}
                    {lmTerminateModal && (
                        <TerminateModal
                            key={"Link_Manager"}
                            setModalClose={setLmTerminateModal}
                            handleTerminate={handleLmTerminate}
                            terminating={terminatingLm}
                            mainText={lmTerminatingText}
                        />
                    )}
                    {humanizeTerminateModal && (
                        <TerminateModal
                            key={"Humanizer"}
                            setModalClose={setHumanizeTerminateModal}
                            handleTerminate={handleHumanizerTerminate}
                            terminating={terminatingHumanizer}
                            mainText={humanizerTerminateText}
                        />
                    )}
                </div>
            ) : (
                <></>
            )}

            {invoiceModal && (
                <Modal setModal={setInvoiceModal}>
                    <div className={styles.modalContainer}>
                        <InvoiceContainer setInvoiceModal={setInvoiceModal} />
                    </div>
                </Modal>
            )}
        </>
    );
}

const TerminateModal = ({
    setModalClose,
    handleTerminate,
    terminating,
    mainText,
}) => {
    return (
        <Modal
            setModal={() => {
                setModalClose(false);
            }}
        >
            <div className={styles.terminateLmWrapper}>
                <CloseButton
                    setClose={setModalClose}
                    style={{ right: 8, top: 8 }}
                />

                <h2 style={{ fontWeight: "600" }}>Are You Sure?</h2>

                <div
                    style={{
                        textAlign: "center",
                        marginTop: "-8px",
                    }}
                >
                    {mainText}
                </div>
                <div className={styles._ctas}>
                    <Button
                        text={"Cancel"}
                        handler={() => setModalClose(false)}
                        style={{
                            background: "transparent",
                            color: "gray",
                            border: "1px solid gray",
                        }}
                        disabled={terminating}
                        width={150}
                    />
                    <Button
                        text={"Confirm"}
                        handler={() => handleTerminate()}
                        width={150}
                        disabled={terminating}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PlanDetails;
