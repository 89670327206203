import { TextareaAutosize, useMediaQuery } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import SVGIcon from "../../../../atoms/SVGIcon";
import styles from "./TalkingPointsStep.module.css";
import TalkPoint from "./TalkPoint";
import TPGenerate from "./TPGenerate";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Outline } from "../classes";
import {
    cruiseMixPanelEvents,
    droppableIds,
    droppableTypes,
} from "../cruiseConstant";
import ReportContext from "../../../Research/ReportContext";
import useHelperFunctions from "../../../../helperFunctions";
import { CruiseContext, CruiseState } from "../StepsWizard";
import SkeletonTPs from "./SkeletonTPs";
import { VscTrash } from "react-icons/vsc";
import { CRUISE_TYPES } from "../useCruiseHook";
import useMixpanelHook from "../../../../../utility/useMixpanelHook";

const TPOutline = React.memo(
    /**
     * @param {{data:Outline,index:number}}
     */
    ({ data, index, h2Index, h2Data, title }) => {
        const [h2Input, setH2Input] = useState(data.data);
        const [active, setActive] = useState(-1);
        const { reportInfo } = useContext(ReportContext);
        const [collapse, setCollapse] = useState(false);
        const { postData } = useHelperFunctions();
        const isMounted = useRef(false);
        const { isLoader } = useContext(CruiseState);
        const ref = useRef();
        const dispatchStepsData = useContext(CruiseContext);
        const { mixpanelTrack } = useMixpanelHook();
        useEffect(() => {
            if (isMounted.current) {
                if (!data.para) {
                    emptyPara();
                }
            }
            isMounted.current = true;
        }, [data.para]);

        function deleteOutline() {
            mixpanelTrack(cruiseMixPanelEvents.delete, {
                "Screen Name": "Writing Points",
                "Deleted Section":
                    data.tag == "h2" ? "H2" : data.tag == "h3" ? "H3" : "FAQ",
            });
            dispatchStepsData({
                type: CRUISE_TYPES.DELETE_OUTLINES,
                index: index,
                field:
                    data.tag == Outline.tags.question
                        ? Outline.type.question
                        : Outline.type.heading,
                id: data.id,
                tag: data.tag,
                h2Index,
                step: 4,
            });
        }

        const emptyPara = async (payload) => {
            const res = await postData({
                url: "/api/contentDraft/v2/update",
                payload: {
                    outline_id: data.id,
                    para: "",
                    tag: data.tag,
                    field: "paragraph",
                    report_id: reportInfo.report_id,
                },
            });
        };

        return (
            <>
                <div
                    ref={ref}
                    className={
                        styles.h2RowBox +
                        " " +
                        (active == index && styles.active)
                    }
                >
                    <div className={styles.h2Row__head}>
                        <span
                            style={{ cursor: "pointer" }}
                            className={styles.expandIcon}
                            onClick={() => {
                                setCollapse(!collapse);
                                setActive(index);
                            }}
                        >
                            {!collapse ? (
                                <SVGIcon
                                    src={"/New UI/SVG/RiArrowDownSFill.svg"}
                                    style={{ color: "#c9e3ff" }}
                                    size={22}
                                />
                            ) : (
                                <SVGIcon
                                    src={"/New UI/SVG/RiArrowRightSFill.svg"}
                                    style={{ color: "#0079e4" }}
                                    size={22}
                                />
                            )}
                        </span>
                        <span className={styles.headingType + " " + styles.h2}>
                            {data.tag == Outline.tags.h2
                                ? "H2"
                                : data.tag == Outline.tags.h3
                                ? "H3"
                                : `Q${index + 1}`}
                        </span>

                        <TextareaAutosize
                            disabled={true}
                            type="text"
                            rowsMin={1}
                            placeholder={"Add Heading here"}
                            value={h2Input}
                            onChange={(e) => setH2Input(e.target.value)}
                            style={{ width: "100%" }}
                            // autoFocus={value == ""}
                            onBlur={(e) => {
                                () => {};
                            }}
                            autoFocus={!h2Input}
                        />
                        <div className={styles.actionsBtn}>
                            <VscTrash
                                onClick={() => deleteOutline()}
                                color="#fc5a5a"
                                cursor={"pointer"}
                            />
                        </div>
                    </div>
                    {!collapse && (
                        <div className={styles.h2Row__body}>
                            {isLoader && !data.wp.length ? (
                                <SkeletonTPs />
                            ) : (
                                <Droppable
                                    droppableId={
                                        data.tag == Outline.tags.h2
                                            ? droppableTypes.outlineH2 +
                                              "_" +
                                              index
                                            : data.tag == Outline.tags.h3
                                            ? droppableTypes.outlineH3 +
                                              "_" +
                                              h2Index +
                                              "_" +
                                              index
                                            : droppableIds.questions +
                                              "_" +
                                              index
                                    }
                                >
                                    {(provided) => (
                                        <div
                                            className={styles.headingsDiv}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {Array.isArray(data.wp) &&
                                                data.wp.map((wp, wpIndex) => (
                                                    <Cache
                                                        wp={wp}
                                                        index={index}
                                                        wpIndex={wpIndex}
                                                        h2Index={h2Index}
                                                        tag={data.tag}
                                                    />
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}

                            <TPGenerate
                                index={index}
                                data={data.data}
                                id={data.id}
                                h2Index={h2Index}
                                noOfWps={data.wp.length}
                                h2Data={h2Data}
                                title={title}
                                {...(data.tag == Outline.tags.h2
                                    ? { isH2: true }
                                    : data.tag == Outline.tags.h3
                                    ? { isH3: true }
                                    : { isFaq: true })}
                            />
                        </div>
                    )}
                </div>

                {data.tag == Outline.tags.h2
                    ? data.outlineH3s.map((dataH3, i) => (
                          <TPOutline
                              h2Index={index}
                              index={i}
                              data={dataH3}
                              h2Data={data.data}
                              title={title}
                          />
                      ))
                    : ""}
            </>
        );
    },
);

const Cache = React.memo(({ wp, index, wpIndex, h2Index, tag }) => {
    return (
        <Draggable key={wp.id} draggableId={String(wp.id)} index={wpIndex}>
            {(provided, snap) => (
                <div
                    className={styles.itemItemOuter}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                        ...provided.draggableProps?.style,

                        backgroundColor: snap.isDragging
                            ? "rgba(232, 237, 248, 0.9)"
                            : "white",
                    }}
                >
                    <TalkPoint
                        wp={wp}
                        index={wpIndex}
                        parentIndex={index}
                        provided={provided}
                        field={
                            tag == Outline.tags.question
                                ? Outline.type.question
                                : Outline.type.heading
                        }
                        h2Index={h2Index}
                        {...(tag == Outline.tags.h2
                            ? { isH2: true }
                            : tag == Outline.tags.h3
                            ? { isH3: true }
                            : { isFaq: true })}
                    />
                </div>
            )}
        </Draggable>
    );
});

export default TPOutline;
