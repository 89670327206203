import React, { useState } from "react";
import { IoMdList } from "react-icons/io";
import styles from "../RequstDetails/RequestDetails.module.css";
import Color from "../../../../../components/profile/lib/color";
import File from "../../../../../components/profile/lib/file";
import { BsThreeDots } from "react-icons/bs";

function truncate(str, n) {
  if (str)
    return str.length > n ? str.substr(0, n - 4) + ".." + str.substr(-4) : str;

  return "";
}

export default function BrandProfileAnswers({ brandProfile }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.requestDetails}>
      <IoMdList style={{ color: "#92929d", fontSize: "1.3rem" }} />
      <div>
        <h2 className={styles.heading}>Brand Profile</h2>
        <div className={styles.question}> * {"Brand Name"}</div>

        <div className={styles.answer}> {brandProfile?.name}</div>

        <div className={styles.question}> * {"Website URL"}</div>

        <div className={styles.answer}>
          {" "}
          <a href={"https://" + brandProfile?.website_url} target="_blank">
            {brandProfile?.website_url}
          </a>
        </div>

        <div className={styles.question}>
          {" "}
          * {"Description & Brand Message"}
        </div>

        <div className={styles.answer}> {brandProfile?.description}</div>

        {!expanded && (
          <span
            className={styles.more}
            onClick={(e) => {
              setExpanded(true);
            }}
          >
            <BsThreeDots style={{ fontSize: "1rem" }} />
            Show Full Description
          </span>
        )}

        {expanded && (
          <>
            <div className={styles.question}> * {"Brand Tone/Voice"}</div>

            <div className={styles.answer}> {brandProfile?.tone}</div>

            <div className={styles.question}> * {"Brand Colors"}</div>

            <div className={styles.answer}>
              {" "}
              {brandProfile?.color.map((v) => (
                <Color v={v} detail={true} />
              ))}
            </div>

            <div className={styles.question}> * {"Brand Fonts"}</div>

            <div className={styles.answer}>
              {brandProfile?.font.map((v, i) => {
                if (v) {
                  return (
                    <div className={styles.font}>
                      &nbsp;{i === 0 ? "" : ","}
                      &nbsp;{v}
                    </div>
                  );
                }
              })}
            </div>

            <div className={styles.question}> * {"Design Guidelines"}</div>

            <div className={styles.answer}>
              {" "}
              {brandProfile?.design.map((v) => (
                <File guide={v} truncate={truncate} />
              ))}
            </div>

            <div className={styles.question}> * {"Other Brand Assets"}</div>

            <div className={styles.answer}>
              {" "}
              {brandProfile?.assets.map((v) => (
                <File guide={v} truncate={truncate} />
              ))}
            </div>

            <div className={styles.question}> * {"Social media links"}</div>

            <div className={styles.answer}>
              <div>
                {" "}
                {brandProfile?.media_links.map((v, i) => (
                  <div>
                    {i === 0 && v && (
                      <a target={"_blank"} href={"https://facebook.com/" + v}>
                        {" "}
                        {"facebook.com/" + v}{" "}
                      </a>
                    )}
                    {i === 1 && v && (
                      <a target={"_blank"} href={"https://twitter.com/" + v}>
                        {" "}
                        {"twitter.com/" + v}{" "}
                      </a>
                    )}
                    {i === 2 && v && (
                      <a target={"_blank"} href={"https://instagram.com/" + v}>
                        {" "}
                        {"instagram.com/" + v}{" "}
                      </a>
                    )}
                    {i === 3 && v && (
                      <a target={"_blank"} href={"https://" + v}>
                        {" "}
                        {v}{" "}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <span className={styles.more} onClick={() => setExpanded(false)}>
              <BsThreeDots style={{ fontSize: "1rem" }} />
              Less
            </span>
          </>
        )}
      </div>
    </div>
  );
}
