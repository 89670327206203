import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Stage from "./Stage.jsx";
import Stage2 from "./Stage2.jsx";
import { useSelector } from "react-redux";

export default function ProjectTimeline({
  listOfFreelancerTypes,
  data,
  _stages,
  setConfirmationAction,
  openDialog,
  setData,
}) {
  const role = useSelector((state) => state.auth.role);
  const [stages, setStages] = useState(_stages);

  const [current_stage, setCurrentStage] = useState(0);
  const [client_stage, setClientStage] = useState(0);

  useEffect(() => {
    setClientStage(getStageIndex(data?.projectDetail?.client_stage));
    setCurrentStage(getStageIndex(data?.projectDetail?.current_stage));
  }, []);

  function getStageIndex(stage) {
    try {
      for (const i in _stages) {
        if (_stages[i].id == stage) {
          console.log(i, "-------------------------");
          return +i + 1;
        }
      }
      return 0;
    } catch {
      return 0;
    }
  }

  return (
    <div className={styles.app}>
      <div className={styles.container}>
        {stages.map((stage, i) =>
          i <= (role != "user" ? current_stage : client_stage) ? (
            <Stage
              key={stage.id}
              listOfFreelancerTypes={listOfFreelancerTypes}
              projectId={data.projectDetail.id}
              lastStage={i == stages.length - 1}
              current_stage={role != "user" ? current_stage : client_stage}
              stage={stage}
              setConfirmationAction={setConfirmationAction}
              openDialog={openDialog}
              setStages={setStages}
              setCurrentStage={setCurrentStage}
              i={i}
              _stages={_stages}
              stages={stages}
              setData={setData}
              data={data}
            />
          ) : (
            <Stage2
              key={stage.id}
              lastStage={i == stages.length - 1}
              data={data}
              stage={stage}
              i={i}
            />
          )
        )}
      </div>
    </div>
  );
}
