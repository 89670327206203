import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import styles from "./FixItEditor.module.css";
import { clickedOnViewOnly } from "../editorConstants";

const FixItEditor = ({ editorRef = null, fixItEditorRef = null }) => {
    const clickDown = useCallback(() => {
        document.dispatchEvent(clickedOnViewOnly);
    }, []);
    const [htmlData, setHtmlData] = useState("<div></div>");
    const [allPara, setAllPara] = useState([]);
    useEffect(() => {
        if (editorRef?.root?.innerHTML) {
            let tempData = editorRef?.root?.cloneNode(true);
            let Ps = tempData.querySelectorAll("p");

            let tempAllPara = [];
            let counter = 1;
            Ps.forEach((p) => {
                let obj = {
                    id: counter,
                    content: p.innerText,
                };
                tempAllPara.push(obj);

                p.setAttribute("id", counter);
                counter++;
            });

            let allH1 = tempData.querySelectorAll("h1");
            allH1.forEach((item) => {
                item.setAttribute("id", counter);
                counter++;
            });

            if (tempAllPara.length > 0) {
                setAllPara([...tempAllPara]);
            }

            setHtmlData(tempData.innerHTML.toString());
        }
    }, [editorRef]);

    useEffect(() => {
        if (fixItEditorRef.current && htmlData.length) {
            fixItEditorRef.current.innerHTML = htmlData;
        }
    }, [htmlData, fixItEditorRef]);
    return (
        <>
            {htmlData ? (
                <div
                    className={"ql-editor" + " " + styles["ql-editor"]}
                    ref={fixItEditorRef}
                    dangerouslySetInnerHTML={{
                        __html: htmlData.toString(),
                    }}
                    onMouseDown={clickDown}
                ></div>
            ) : (
                <></>
            )}
        </>
    );
};

export default FixItEditor;
