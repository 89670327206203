import authReducer from "./reducers/auth";
import fetchReducer from "./reducers/fetch";
import dashboardReducer from "./reducers/dashboard";
import resetPassword from "./reducers/reset";
import variants from "./reducers/variants";
import otherInfo from "./reducers/otherInfo";
import alertReducer from "./reducers/alert";

import { combineReducers } from "redux";
import video from "./reducers/video";
import userGscInfo from "./reducers/userGscInfo";
import appConfigReducer from "./reducers/appConfigReducer";
import state from "./reducers/state";

export default combineReducers({
    auth: authReducer,
    fetch: fetchReducer,
    dash: dashboardReducer,
    reset: resetPassword,
    variants: variants,
    otherInfo,
    alert: alertReducer,
    video: video,
    userGscInfo: userGscInfo,
    appConfig: appConfigReducer,
    state: state,
});
