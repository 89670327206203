import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { LightTooltip } from "../../../../componentsV2/atoms/Tooltip/tooltip";
import useHelperFunctions from "../../../../componentsV2/helperFunctions";
import { PLANS_CATEGORIES } from "../../../../constants";
import PlanDetails from "../../../subsCards/PlanDetails";
import styles from "./userProfile.module.css";
import screens from "../../../../screens.json";
import useUserAccessHook from "../../useUserAccessHook";
import { getTrailText } from "../../../../componentsV2/Pages/NewDashboard/constants";
import Button from "../../../../componentsV2/atoms/Button/Button";

const Usage = () => {
    const { getData } = useHelperFunctions();
    const { individualUser, freeUser, planExpiresDate, isFreeForever } =
        useUserAccessHook();
    const otherInfo = useSelector((store) => store.otherInfo);
    const getCorrectSign = (currency) => {
        return "₹";
        if (currency === "USD") return "$";
        else if (currency === "INR") return "₹";
    };

    const [planType, setPlanType] = useState("");
    const [usage, setUsage] = useState(null);
    console.log(window.location);

    useEffect(() => {
        (async () => {
            const res = await getData({
                url: "/api/pricing/getSubscriptionPlans",
            });
            if ((res.status = 200)) {
                setUsage(res?.data?.quota);
            }
        })();
    }, []);

    useEffect(() => {
        if (usage?.type == 1) {
            setPlanType("/month");
        } else {
            setPlanType("/year");
        }
    }, [usage]);

    return (
        <>
            {usage && (
                <div
                    className={`${styles.planDetailsContainer}   ${
                        otherInfo?.subscriptionInfo?.plan_category ==
                        PLANS_CATEGORIES.TRAIL
                            ? styles.planDetailsContainerTrial
                            : ""
                    }`}
                >
                    <div className={styles.planType}>
                        Plan:{" "}
                        {otherInfo?.subscriptionInfo?.plan_category ==
                        PLANS_CATEGORIES.TRAIL
                            ? "Trial"
                            : usage?.plan_name}{" "}
                        {otherInfo?.subscriptionInfo?.type == 3 &&
                            otherInfo?.subscriptionInfo?.plan_category !=
                                PLANS_CATEGORIES.FREE_FOREVER &&
                            " LIFETIME"}
                        {usage?.plan_name &&
                            otherInfo?.subscriptionInfo?.plan_category != 1 &&
                            otherInfo?.subscriptionInfo?.trial_status == 1 &&
                            usage?.terminate != 1 &&
                            "(Free Trial)*"}
                        {usage?.terminate == 1 && (
                            <>
                                {"("}
                                <LightTooltip
                                    placement="bottom"
                                    title="You have terminated your subscription. Services will continue till the end of the billing period."
                                    arrow
                                >
                                    <span className={styles.terminatedText}>
                                        {"Terminated"}
                                    </span>
                                </LightTooltip>
                                {")"}
                                {otherInfo?.subscriptionInfo?.plan_category !=
                                    1 &&
                                    otherInfo?.subscriptionInfo?.trial_status ==
                                        1 &&
                                    "*"}
                            </>
                        )}
                        {usage.plan_category == PLANS_CATEGORIES.TRAIL ? (
                            <></>
                        ) : (
                            (individualUser || freeUser) && (
                                <NavLink
                                    className={styles.upgradeLink}
                                    to={screens.BILLING_PLANS}
                                >
                                    Upgrade
                                </NavLink>
                            )
                        )}
                    </div>
                    {usage.plan_category != PLANS_CATEGORIES.TRAIL ? (
                        <div className={styles.planDetails}>
                            <div className={styles.planDetails__left}>
                                <div className={styles.details}>
                                    {/* <div className={styles.planPricePer}> */}
                                    {/* <span className={styles.price}>
                  {currencySign}
                  {usage?.plan_cost}
                </span> */}
                                    {/* <span>{planType}</span> */}
                                    {/* </div> */}
                                </div>
                                <div className={styles.planDetails__right}>
                                    <div className={styles.monthUsage}>
                                        <div className={styles.titleRow}>
                                            Usage this Month
                                        </div>
                                        <div className={styles.dataRow}>
                                            <span className={styles.key}>
                                                Article Writer
                                            </span>
                                            <span className={styles.value}>
                                                {usage.reports_created} /{" "}
                                                {usage?.reports_limit == -1
                                                    ? "Unlimited"
                                                    : usage.reports_limit}
                                            </span>
                                        </div>
                                        <div
                                            className={styles.horizontalSpacer}
                                        ></div>
                                        <div className={styles.dataRow}>
                                            <span className={styles.key}>
                                                Content Optimizer
                                            </span>
                                            <span className={styles.value}>
                                                {usage?.content_audit_limit ==
                                                0 ? (
                                                    <NavLink
                                                        className={
                                                            styles.upgradeLink
                                                        }
                                                        to={
                                                            screens.BILLING_PLANS
                                                        }
                                                    >
                                                        Upgrade
                                                    </NavLink>
                                                ) : (
                                                    <>
                                                        {usage.audits_created} /{" "}
                                                        {usage?.content_audit_limit ==
                                                        -1
                                                            ? "Unlimited"
                                                            : usage.content_audit_limit}
                                                    </>
                                                )}
                                            </span>
                                        </div>

                                        <div
                                            className={styles.horizontalSpacer}
                                        ></div>
                                        <div className={styles.dataRow}>
                                            <span className={styles.key}>
                                                Keyword Planner
                                            </span>
                                            <span className={styles.value}>
                                                <>
                                                    {usage.topic_cluster_limit ==
                                                    0 ? (
                                                        <>
                                                            <NavLink
                                                                className={
                                                                    styles.upgradeLink
                                                                }
                                                                to={
                                                                    screens.BILLING_PLANS
                                                                }
                                                            >
                                                                Upgrade
                                                            </NavLink>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                usage.clusters_created
                                                            }{" "}
                                                            /{" "}
                                                            {usage?.topic_cluster_limit ==
                                                            -1
                                                                ? "Unlimited"
                                                                : usage.topic_cluster_limit}
                                                        </>
                                                    )}
                                                </>
                                            </span>
                                        </div>
                                        <div
                                            className={styles.horizontalSpacer}
                                        ></div>
                                        <div className={styles.dataRow}>
                                            <span className={styles.key}>
                                                Traffic Analyzer
                                            </span>
                                            <span className={styles.value}>
                                                <>
                                                    {usage.ta_pages_limit ==
                                                    0 ? (
                                                        <>
                                                            <NavLink
                                                                className={
                                                                    styles.upgradeLink
                                                                }
                                                                to={
                                                                    screens.BILLING_PLANS
                                                                }
                                                            >
                                                                Upgrade
                                                            </NavLink>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {usage.ta_pages_used ||
                                                                0}{" "}
                                                            /{" "}
                                                            {usage?.ta_pages_limit ==
                                                            -1
                                                                ? "Unlimited"
                                                                : usage?.ta_pages_limit ||
                                                                  0}
                                                        </>
                                                    )}
                                                </>
                                            </span>
                                        </div>
                                        <div
                                            className={styles.horizontalSpacer}
                                        ></div>
                                        {!isFreeForever &&
                                            otherInfo?.subscriptionInfo
                                                ?.interlinking_status != 0 && (
                                                <>
                                                    <div
                                                        className={
                                                            styles.dataRow
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.key
                                                            }
                                                        >
                                                            Link Manager
                                                        </span>
                                                        <span
                                                            className={
                                                                styles.value
                                                            }
                                                        >
                                                            {usage.interlinking_pages_used ||
                                                                0}{" "}
                                                            /{" "}
                                                            {usage?.interlinking_purchased_pages ==
                                                            -1
                                                                ? "Unlimited"
                                                                : usage?.interlinking_purchased_pages ||
                                                                  0}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.horizontalSpacer
                                                        }
                                                    ></div>
                                                </>
                                            )}
                                        {!isFreeForever && (
                                            <>
                                                <div className={styles.dataRow}>
                                                    <span
                                                        className={styles.key}
                                                    >
                                                        AI Detection
                                                    </span>
                                                    <span
                                                        className={styles.value}
                                                    >
                                                        {usage.ai_detection_used ||
                                                            0}{" "}
                                                        /{" "}
                                                        {usage?.ai_detection_limit ==
                                                        -1
                                                            ? "Unlimited"
                                                            : usage?.ai_detection_limit ||
                                                              0}
                                                    </span>
                                                </div>
                                                <div
                                                    className={
                                                        styles.horizontalSpacer
                                                    }
                                                ></div>
                                            </>
                                        )}
                                        {!isFreeForever && (
                                            <>
                                                <div className={styles.dataRow}>
                                                    <span
                                                        className={styles.key}
                                                    >
                                                        Humanizer
                                                    </span>
                                                    <span
                                                        className={styles.value}
                                                    >
                                                        {usage.humanizer_used ||
                                                            0}{" "}
                                                        /{" "}
                                                        {usage?.humanizer_limit ==
                                                        -1
                                                            ? "Unlimited"
                                                            : usage?.humanizer_limit ||
                                                              0}
                                                    </span>
                                                </div>
                                                <div
                                                    className={
                                                        styles.horizontalSpacer
                                                    }
                                                ></div>
                                            </>
                                        )}
                                        <div className={styles.dataRow}>
                                            <span className={styles.key}>
                                                AI Words
                                            </span>
                                            <span className={styles.value}>
                                                {usage.short_form_used +
                                                    usage.long_form_used}{" "}
                                                /{" "}
                                                {usage?.short_form_limit == -1
                                                    ? "Unlimited"
                                                    : usage.short_form_limit}
                                            </span>
                                        </div>
                                        <div
                                            className={styles.horizontalSpacer}
                                        ></div>
                                        {otherInfo?.premium_cruise == 1 && (
                                            <>
                                                <div className={styles.dataRow}>
                                                    <span
                                                        className={styles.key}
                                                    >
                                                        Premium Cruise
                                                    </span>
                                                    <span
                                                        className={styles.value}
                                                    >
                                                        {usage?.ai_credits ||
                                                            "0"}
                                                    </span>
                                                </div>
                                                <div
                                                    className={
                                                        styles.horizontalSpacer
                                                    }
                                                ></div>
                                            </>
                                        )}
                                        <div className={styles.planRenew}>
                                            {otherInfo?.subscriptionInfo
                                                ?.plan_category != 1 &&
                                                otherInfo?.subscriptionInfo
                                                    ?.trial_status == 1 && (
                                                    // usage?.terminate != 1 &&
                                                    <span
                                                        className={styles.info}
                                                    >
                                                        *Usage limits apply
                                                        during free trial period
                                                        <br />
                                                    </span>
                                                )}

                                            {!(
                                                usage?.plan_name &&
                                                otherInfo?.subscriptionInfo
                                                    ?.plan_category != 1 &&
                                                otherInfo?.subscriptionInfo
                                                    ?.trial_status == 1 &&
                                                usage?.terminate != 1
                                            ) && (
                                                <>
                                                    {usage.next_quota_refresh_date ? (
                                                        <span
                                                            className={
                                                                styles.info
                                                            }
                                                        >
                                                            Quota Renews On:{" "}
                                                            {moment(
                                                                usage.next_quota_refresh_date,
                                                            ).format(
                                                                "dddd, MMMM Do YYYY, h:mm a",
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className={
                                                                styles.info
                                                            }
                                                        >
                                                            #Quota renews every
                                                            month
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.verticalSpacer}></div>
                            {!!otherInfo?.subscriptionInfo?.type && (
                                <PlanDetails
                                    setPlanDetails={setUsage}
                                    details={usage}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={styles.planDetails}>
                            <div className={styles.planDetails__left}>
                                {planExpiresDate && (
                                    <span
                                        style={{ color: "var(--primary-blue)" }}
                                        className={styles.info}
                                    >
                                        Trial ends in{" "}
                                        {getTrailText(planExpiresDate)} days
                                    </span>
                                )}
                            </div>
                            <NavLink
                                to={screens.BILLING_PLANS}
                                className={styles.subscribeBtn}
                            >
                                <Button
                                    text="End Trial and Subscribe"
                                    width={200}
                                    fontSize={13}
                                    handler={() => {}}
                                />
                            </NavLink>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Usage;
