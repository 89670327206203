import React, { useCallback, useEffect } from "react";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Progress from "../../atoms/Progress/Progress";
import useHelperFunctions from "../../helperFunctions";

function ShopifyAuth() {
    const goTo = useNavigation();
    const { postData } = useHelperFunctions();
    const dataPost = useCallback((data) => {
        (async () => {
            const res = await postData({
                url: "/api/integrations",
                payload: {
                    app_id: 5,
                    query_params: { ...data },
                },
            });
            if (res.status == 200) {
                goTo("INTEGRATION_HUB", "", "5?success");
            } else {
                goTo("INTEGRATION_HUB");
            }
        })();
    }, []);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const data = {};
        urlParams.forEach((value, key) => {
            data[key] = value;
        });
        dataPost(data);
    }, []);

    return <Progress height={"100vh"} />;
}

export default ShopifyAuth;
