import React,{useState,useRef} from 'react';
import styles from "./styles.module.css";
import {FaTrash} from "react-icons/fa";
import { IoMdClose } from "react-icons/io"
import {MdEdit} from "react-icons/md";
function Color({detail,v,colors,index,isEditable,setColors}) {
   
   const [remove,setRemove]=useState(false);
   const [details,showDetail]=useState(false);
   const colorInputRef = useRef(null);
    
   function setColorInArray(color){
     
    colors[index]=color;
     let arr=colors.map((v)=>v
     );

     console.log(arr);
    setColors(arr);


}

   function deleteItem(){
     
      let newArr=[];
      colors.splice(index,1);

      for(v of colors){
          newArr.push(v);
      }
      

      setColors(newArr);
      
   }

 
    return (
        <div  onMouseEnter={()=>showDetail(true)} onMouseLeave={()=>showDetail(false)}  className={styles.color} style={{ backgroundColor: v }}>  {detail&&details&&<div className={styles.colorInfo}>
        {v}
 </div>} {isEditable&&(<div onClick={() =>colorInputRef.current.click()} className={styles.remove}>
            <MdEdit/>
            
           
            </div>  ) }
            <div className={styles.input}>
            <input
              disabled={!isEditable}
              onChange={(e) => {
                setColorInArray(e.target.value);
                
              }}

              
             
              ref={colorInputRef}
              type={"color"}
            ></input>
            </div>
           {/* {<div  className={styles.remove}>
            <FaTrash/>

            
            </div> } */}

{isEditable&&<div onClick={deleteItem} className={styles.removeColor}>
<IoMdClose />


</div>}
             </div>
    )
}

export default Color;
