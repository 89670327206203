import React, { useState } from "react";
import { IoMdList } from "react-icons/io";
import styles from "./RequestDetails.module.css";
import { BsThreeDots } from "react-icons/bs";
import { parseJSON } from "../../../../../utility/validateFunctions";

function getMultiSetData(answer) {
  let element = [];
  for (const property in answer) {
    element.push(
      <span className={styles.answer}>
        <span className={styles.multiSetQuestion}>{property}:</span>{' '}
        {answer[property]}
      </span>
    );
  }
  return element;
}

function createMarkup(ans) {
  return { __html: ans };
}

function getAnswer(answer, type) {
  if (type == 1) {
    return (
      <div
        className={styles.answer}
        dangerouslySetInnerHTML={createMarkup(answer)}
      ></div>
    );
  } else if (type == 8) {
    const parsedAnswer = parseJSON(answer);
    if (Array.isArray(parsedAnswer)) {
      return parsedAnswer.map((a) => <div className={styles.answer}>{a}</div>);
    } else return "";
  } else if (type == 7 || type == 9) {
    const parsedAnswer = parseJSON(answer);
    if (Array.isArray(parsedAnswer)) {
      return (
        <div className={styles.images}>
          {parsedAnswer.map((a) => {
            return (
              <>
                <div key={a.image_name}>
                  <a href={a.image} target="_blank">
                    <div className={styles.image + " " + styles.answer}>
                      <img src={a.image} />
                    </div>
                  </a>
                  <p className={styles.answer}> {a.image_name}</p>
                </div>
              </>
            );
          })}
        </div>
      );
    } else return "";
  } else if (type == 10) {
    const parsedAnswer = parseJSON(answer);
    if (Array.isArray(parsedAnswer)) {
      return parsedAnswer.map((a) => {
        return (
          <div className={styles.multiSetAnswerSet}>{getMultiSetData(a)}</div>
        );
      });
    } else return "";
  } else return <p className={styles.answer}>{answer}</p>;
}

export default function RequestDetails({ answers, wordsToDeliver }) {
  const [listLimit, setListLimit] = useState(3);

  return (
    <div className={styles.requestDetails}>
      <IoMdList style={{ color: "#92929d", fontSize: "1.3rem" }} />
      <div>
        <h2 className={styles.heading}>Request Details</h2>
        {wordsToDeliver && (
          <>
            <p className={styles.question}> * {"Words to deliver"}</p>
            <p className={styles.answer}>{wordsToDeliver} words.</p>
          </>
        )}
        {answers.map((answer, i) => {
          if (i >= listLimit) return;

          if (answer.answer) {
            return (
              <>
                <p className={styles.question}>* {answer.question}</p>
                {getAnswer(answer.answer, answer.answer_type)}
              </>
            );
          }
        })}
        {listLimit < answers.length && (
          <span
            className={styles.more}
            onClick={(e) => {
              console.log("SDasd");
              setListLimit(answers.length);
            }}
          >
            <BsThreeDots style={{ fontSize: "1rem" }} />
            Show Full Description
          </span>
        )}
        {answers.length > 3 && answers.length === listLimit && (
          <span className={styles.more} onClick={() => setListLimit(3)}>
            <BsThreeDots style={{ fontSize: "1rem" }} />
            Less
          </span>
        )}
      </div>
    </div>
  );
}

// {
//   data?.answers?.map(
//     (data, index) =>
//       data?.answer && (
//         <div key={index + 1}>
//           <div className={styles.question}> * {data?.question}</div>
//           {data.answer_type == 7 || data.answer_type == 9 ? (
//             JSON.parse(data.answer).map((a) => {
//               console.log(a);
//               // a.image ? (
//               return (
//                 <div key={a.image_name}>
//                   <a href={a.image} target='_blank'>
//                     <div className={styles.image + ' ' + styles.answer}>
//                       <img src={a.image} />
//                     </div>
//                   </a>
//                   <div className={styles.answer}> {a.image_name}</div>
//                 </div>
//               );
//               // ) : (
//               //   <div className={styles.answer}>
//               //     {' '}
//               //     {data?.answer}
//               //   </div>
//               // );
//             })
//           ) : data.answer_type == 1 ? (
//             <div
//               className={styles.answer}
//               dangerouslySetInnerHTML={createMarkup(data?.answer)}
//             ></div>
//           ) : data.answer_type == 10 ? (
//             JSON.parse(data.answer).map((a) => {
//               return <div>{getMultiSetData(a)}</div>;
//             })
//           ) : (
//             <div className={styles.answer}> {data?.answer}</div>
//           )}
//         </div>
//       )
//   );
// }
