import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenerateFavourite from '../../../../componentsV2/organisms/Generate/GenerateFavourite/GenerateFavourite';
import GenerateHistory from '../../../../componentsV2/organisms/Generate/GenerateHistory/GenerateHistory';
import styles from './UserAiOutputs.module.css';
import Textfield from '../../../../componentsV2/atoms/CustomTextfield/Textfield';
import SVGIcon from '../../../../componentsV2/atoms/SVGIcon';



export default function UserAiOutputs() {
  // const theme = useTheme();
  const [active, setActive] = useState(1)

  function handleActive(val) {
    setActive(val);
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerTop}>
        <div className={styles.sectionTitle}>AI Outputs</div>
        <div className={styles.sectionRight}>

          <div className={styles.btnWrapper}>
            <div className={styles.toggleOutline}><button className={styles.btnHistory + " " + (active == 1 && styles.active)} onClick={() => handleActive(1)}>Favorite</button>
              <button className={styles.btnFav + " " + (active == 2 && styles.active)} onClick={() => handleActive(2)}>History</button></div>
          </div>
        </div>
      </div>
      <div className={styles.activeArea}>
        {active == 1 ? <GenerateFavourite /> : ""}
        {active == 2 ? <GenerateHistory /> : ""}
      </div>
    </div>

  );
}




//  <Tabs
//         value={value}
//         onChange={handleChange}
//         indicatorColor="secondary"
//         textColor="inherit"
//         variant="fullWidth"
//         aria-label="full width tabs example"
//       >
//         <Tab label="History" {...a11yProps(1)} />
//         <Tab label="Faourite" {...a11yProps(0)} />
//       </Tabs>

//       <TabPanel value={value} index={0} >
//         <GenerateHistory setAllUseCases={() => { }} />
//       </TabPanel>
//       <TabPanel value={value} index={1} >
//         <GenerateFavourite setAllUseCases={() => { }} />
//       </TabPanel>