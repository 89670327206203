import React, { useEffect, useState } from "react";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams,
} from "react-router-dom";
import CreatorCore from "../../organisms/Creator/CreatorCore/CreatorCore";
import UserAudit from "../UserAudit/UserAudit";
import UserReports from "../UserReports/UserReports";
export default function Creator() {
    const [seoWithCruise, setSeoWithCruise] = useState(false);
    const [seoReportName, setSeoReportName] = useState(false);
    const [seoReportCountry, setSeoReportCountry] = useState("");
    const [seoSecondaryKeywords, setSeoSecondaryKeywords] = useState("");
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    return (
        <Switch>
            <Route exact path="/admin/creator/:form/:id">
                <CreatorCore
                    seoWithCruise={seoWithCruise}
                    seoReportName={seoReportName}
                    seoSecondaryKeywords={seoSecondaryKeywords}
                    setSeoWithCruise={setSeoWithCruise}
                    setSeoReportCountry={setSeoReportCountry}
                    seoReportCountry={seoReportCountry}
                    loadingCruise={loading}
                    setLoadingCruise={setLoading}
                />
            </Route>

            <Route exact path="/admin/audit/:form/:id">
                <CreatorCore
                    seoWithCruise={false}
                    seoReportName={seoReportName}
                    seoSecondaryKeywords={seoSecondaryKeywords}
                    setSeoWithCruise={() => {}}
                    setSeoReportCountry={setSeoReportCountry}
                    seoReportCountry={seoReportCountry}
                    loadingCruise={loading}
                    setLoadingCruise={setLoading}
                    auditMode={true}
                />
            </Route>

            <Route exact path="/admin/creator">
                <UserReports key={1} bin={false} />
            </Route>
            <Route exact path="/admin/audit">
                <UserAudit key={1} bin={false} />
            </Route>
            {/* <Route exact path="/admin/creator/bin">
        <UserReports key={2} bin={true} />
      </Route> */}

            <Redirect to={"/admin/creator"} />
        </Switch>
    );
}

const RedirectToCreatorCore = ({
    setSeoWithCruise,
    setSeoReportName,
    setSeoReportCountry = () => {},
    setSeoSecondaryKeywords,
    setLoading,
    history,
}) => {
    const { form, id, name, keywords, loadingCruise, country } = useParams();
    const [loaded, setLoaded] = useState(0);

    useEffect(() => {
        setSeoWithCruise(!+loadingCruise);
        setSeoReportName(name);
        setSeoSecondaryKeywords(decodeURIComponent(keywords));
        setLoading(!!+loadingCruise);
        setSeoReportCountry(country);
        setLoaded(1);
    }, []);

    return loaded ? (
        <Redirect
            to={{
                pathname: `/admin/creator/${form}/${id}`,
                state: history.location.state,
            }}
        />
    ) : (
        ""
    );
};
