import React, { useEffect, useRef, useState } from "react";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import SVGIcon from "../../../atoms/SVGIcon";
import TabPanelHeading from "./atoms/TabHeading/TabPanelHeading";
import styles from "./Overview.module.css";
import styles2 from "./StatsOverview.module.css";
import { debounceWrapper, parseForRegex } from "../../../../utility/helperFun";
import { CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

const LightTooltip1 = withStyles((theme) => ({
    arrow: {
        color: "white",
        scale: 1.5,
    },
    tooltip: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: " 2px 1px 12px rgba(14, 14, 15, 0.23)",
        fontSize: 11,
        maxWidth: "400px",
        position: "relative",
        left: "20px",
        border: "solid 1px rgba(172, 179, 191, 0.28)",
        borderRadius: "6px",
    },
}))(Tooltip);

function HoverComponent({ item = {} }) {
    return (
        <LightTooltip1
            placement={"right"}
            interactive
            arrow={true}
            title={
                <div className={styles2.hoverContainer}>
                    <div className={styles2.hoverContainer_head}>Source</div>
                    <div className={styles2.head}>
                        <span className={styles2.outlineRank}>
                            #{item.rank}
                        </span>
                        <div className={styles2.outlineTitle}>
                            <a
                                className={styles2.url}
                                href={item.url}
                                target="_blank"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {item.url}
                            </a>

                            {item.url_title}
                            <div className={styles2.para}>
                                <CustomHighlighter
                                    highlightClassName={styles2.sourceHighlight}
                                    searchWords={[
                                        new RegExp(
                                            parseForRegex(item.statistic),
                                            "g",
                                        ),
                                    ]}
                                    textToHighlight={item.paragraph}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        >
            <div className={styles2.eyeIcon}>
                <SVGIcon
                    style={{ cursor: "pointer" }}
                    size={12}
                    color="#014dc5"
                    src="/New UI/SVG/BsEyeFill.svg"
                />
            </div>
        </LightTooltip1>
    );
}

const StatsOverview = ({ list = [], isLoading = false }) => {
    const [search, setSearch] = useState("");
    const [statsData, setStatsData] = useState([]);
    const { mpTrackStatsOpened } = useMixpanelHook();

    useEffect(() => {
        mpTrackStatsOpened({ screen: "Statistics Tab (Create Brief)" });
    }, []);

    useEffect(() => {
        if (!search.length) {
            setStatsData(list);
        }
        if (search.length) {
            if (search.length) {
                const searchRegEx = new RegExp(
                    parseForRegex(search)
                        .toLocaleLowerCase()
                        .split(" ")
                        .join("|"),
                    "g",
                );
                setStatsData(
                    list.filter((stat) => {
                        if (
                            stat.statistic.toLowerCase().match(searchRegEx)
                                ?.length > 0
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                );
            }
        }
    }, [search.length]);

    useEffect(() => {
        setStatsData(list);
    }, [list]);
    let timeoutRef = useRef(null);

    return (
        <>
            <TabPanelHeading
                heading={
                    "Boost Your Credibility: Statistics from Top Ranking Articles"
                }
                children={
                    <Textfield
                        customClass={styles2.searchOutlines}
                        height={32}
                        label={""}
                        placeholder={"Search Statistics"}
                        containerStyle={{
                            padding: "0px",
                            margin: "0px",
                            background: "white",
                            width: "30%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "transparent",
                        }}
                        props={{
                            onChange: (e) => {
                                const value = e.target.value.trim();
                                debounceWrapper(
                                    () => setSearch(value),
                                    500,
                                    timeoutRef,
                                );
                            },
                        }}
                        Icon={() => (
                            <SVGIcon
                                src={"/New UI/SVG/AiOutlineSearch.svg"}
                                size={14}
                                style={{ color: "#c9e3ff" }}
                            />
                        )}
                        iconStyle={{
                            left: "10px",
                            color: "#c9e3ff",
                            width: "min-content",
                        }}
                    />
                }
            />
            <div className={styles2.parentContainer_body}>
                {statsData.length ? (
                    <div className={styles2.statsContainer}>
                        {statsData.map((statItem, j) => {
                            return (
                                <div
                                    className={styles2.statRow}
                                    key={statItem.id}
                                >
                                    <SVGIcon
                                        src={"/New UI/SVG/RxDotFilled.svg"}
                                        size={16}
                                        color={"#1950BE"}
                                    />
                                    <span className={styles2.Stat__Text}>
                                        <CustomHighlighter
                                            highlightClassName={
                                                search
                                                    ? styles.highlightOutlineSearch
                                                    : styles.highlightOutlineKeyTerm
                                            }
                                            searchWords={[
                                                new RegExp(
                                                    parseForRegex(search)
                                                        .split(" ")
                                                        .sort(
                                                            (a, b) =>
                                                                b.length -
                                                                a.length,
                                                        )
                                                        .join("|"),
                                                    "g",
                                                ),
                                            ]}
                                            textToHighlight={statItem.statistic}
                                        />
                                    </span>
                                    <div className={styles.btnContainer}>
                                        <HoverComponent
                                            item={{
                                                ...statItem,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={styles2.emptyStats}>
                        {isLoading ? (
                            <CircularProgress size={26} />
                        ) : (
                            <>No Statistics Found</>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default StatsOverview;
