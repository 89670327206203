import React from "react";

function useSortingBy({ items = [], asc = true, term = "id" }) {
  items = React.useMemo(
    () =>
      items.sort((a, b) => {
        let first = a[term];
        let second = b[term];
        try {
          if (typeof first != "number") {
            console.log(first, ";;;;;;;;;;;;;;;;;;;;;;;;;;;");
            first = first.charCodeAt(0);
            second = second.charCodeAt(0);
          }
        } catch (error) {}

        return asc ? first - second : second - first;
      }),

    [items, asc, term]
  );

  return items;
}

export default useSortingBy;
