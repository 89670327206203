import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_FAIL,
  UPDATE_VARIANT_FILTER,
  FETCH_SUCCESS_QUES,
} from "../actiontypes";
import allIcon from "../../assets/images/all-icon.svg";

const initialState = {
  data: [],
  filterData: null,
  selCategory: 0,
  selSubCat: 0,
  selCategoryName: "",
  selCategoryIcon: allIcon,
  error: false,
  errorMessage: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        filterData: action.filterData,
        loading: false,
      };
    case FETCH_SUCCESS_QUES:
      return {
        ...state,

        loading: false,
      };

    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case UPDATE_VARIANT_FILTER: {
      return {
        ...state,
        selCategory: action.catId,
        selSubCat: action.subCatId,
        selCategoryName: action.categoryName,
        selCategoryIcon: action.categoryIcon,
      };
    }
    default:
      return state;
  }
}
