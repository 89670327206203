import React from "react";
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import { parsePrice } from "../../componentsV2/templates/Dashboard/usePriceDiscounts";

function HumanizerAddOn({
    checked,
    customClass,
    handleChecked,
    setDefaultAddOnChecked,
    humanizer_status,
    plansType,
    isDeal,
    price,
    isThisCurr,
    getDiscount,
    validHumanizerPlans,
    details,
}) {
    const currency = useSelector((state) => state.auth.currency);
    const currencySymbol = currency == "INR" ? "₹" : "$";
    return (
        <>
            <ul className={customClass.checksList} style={{ marginTop: "5px" }}>
                <li style={{ alignItems: "flex-start" }}>
                    <div
                        className={customClass.check}
                        onClick={() => {
                            if (humanizer_status == 1) {
                                return;
                            }
                            handleChecked(!checked);
                        }}
                    >
                        {checked || humanizer_status == 1 ? (
                            <>
                                <IoIosCheckboxOutline
                                    color={
                                        isThisCurr && !details?.terminate
                                            ? "#fff"
                                            : ""
                                    }
                                    size={17}
                                />
                            </>
                        ) : (
                            <IoIosSquareOutline
                                color={
                                    isThisCurr && !details?.terminate
                                        ? "#fff"
                                        : ""
                                }
                                size={18}
                            />
                        )}
                    </div>
                    {plansType == 2 && isDeal ? (
                        <div>
                            AI Detector & Humanizer
                            <div
                                style={{
                                    color:
                                        isThisCurr && !details?.terminate
                                            ? "whitesmoke"
                                            : "",
                                    marginTop: 5,
                                    fontSize: 12,
                                }}
                            >
                                <div
                                    style={{
                                        marginBottom: "5px",
                                        fontSize: 15,
                                    }}
                                >
                                    <span
                                        style={{
                                            textDecoration: isDeal
                                                ? "line-through"
                                                : "none",
                                        }}
                                    >
                                        {price}
                                    </span>
                                    {isDeal && (
                                        <span>
                                            {" "}
                                            {parsePrice(
                                                validHumanizerPlans.plan_cost,
                                                validHumanizerPlans.currency,
                                                validHumanizerPlans.plan_type,
                                                getDiscount(plansType),
                                            )}
                                        </span>
                                    )}
                                    /mo
                                </div>
                                Unlimited AI detection
                                <br />
                                Humanize 50,000 words
                            </div>
                        </div>
                    ) : (
                        <div>
                            AI Detector & Humanizer
                            <div
                                style={{
                                    color:
                                        isThisCurr && !details?.terminate
                                            ? "whitesmoke"
                                            : "",
                                    marginTop: 5,
                                    fontSize: 12,
                                }}
                            >
                                <div
                                    style={{
                                        marginBottom: "5px",
                                        fontSize: 15,
                                    }}
                                >
                                    <span>{price}</span>
                                    /mo
                                </div>
                                Unlimited AI detection
                                <br />
                                Humanize 50,000 words
                            </div>
                        </div>
                    )}
                </li>
            </ul>
        </>
    );
}

export default HumanizerAddOn;
