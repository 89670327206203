export class Outline {
    static tags = {
        h2: "h2",
        h3: "h3",
        question: "question",
    };

    static type = {
        heading: "heading",
        question: "question",
    };

    constructor({
        tag,
        data = "",
        id,
        outlineH3s = [],
        wp = [],
        wp_order = [],
        para = "",
        h2_id = "",
        subData = "",
    }) {
        if (!id || !tag) {
            throw "Please provide heading";
        }
        this.tag = tag;
        this.wp = wp;
        this.wp_order = wp_order
            ? typeof wp_order == "string"
                ? JSON.parse(wp_order)
                : wp_order
            : [];
        this.type =
            tag == Outline.tags.question
                ? Outline.type.question
                : Outline.type.heading;
        this.data = data;
        this.id = id;
        this.para = para;
        this.subData = subData;
        if (tag == Outline.tags.h2) {
            /**
             * @type {Array<Outline>}
             */
            this.outlineH3s = outlineH3s;
        }
        if (tag == Outline.tags.h3) {
            this.h2_id = h2_id;
        }
    }
}

export class WP {
    static types = {
        fact: 1,
        ai: 2,
        manual: 3,
        stats: 4,
    };

    constructor({ outline_id, tpType, tp, citations = [], id = "" }) {
        if (!id) {
            throw "Please provide wp";
        }
        this.id = id;
        this.outline_id = outline_id;
        this.tpType = tpType;
        this.tp = tp;
        this.citations = citations;
    }
}
