import moment from "moment";
import { PROD } from "../../../utility/config";

export const genScript = (key = "qwerty-asdfgh-zxcvb") => {
    const str = `(function(w,d,k){w.link_manager_id=k;
     var script=d.createElement("script");
     script.src="https://d2ivt1ny4io8b5.cloudfront.net/${
         PROD ? "prod" : "non-prod"
     }/scalenut/script.js";
     d.head.appendChild(script);})(window,document,"${key}")
    `;

    return `<script>${str}</script>`;
};
export const webtuneEndPoints = {
    addDomain: `/api/web-tune/login`,
    findIssues: `/api/web-tune/find-issues`,
    updateFix: `/api/web-tune/update-fix`,
    getLogs: `/api/web-tune/get-logs`,
    getIssues: `/api/web-tune/get-issues`,
    issuesCount: `/api/web-tune/issues-count`,
    userDomains: `/api/web-tune/get-domain-list`,
    publish: `/api/web-tune/publish`,
    logout: `/api/web-tune/logout`,
    verify: `/api/web-tune/verify-domain`,
    generate: "/api/web-tune/ai-generate",
    crawlDomain: "/api/web-tune/crawl-domain",
    webtuneDetails: "/api/web-tune/webtune-details",
};
export const PAGE_LIMIT = 5;

export const issueStatus = {
    approved: 1,
    unApproved: 2,
};

export const fixActions = {
    SAVE: "SAVE",
    UNSAVE: "UNSAVE",
    REVERT: "REVERT",
    EDIT: "EDIT",
};

export const issueTagsMap = {
    MISSING: { title: "Missing", key: "MISSING" },
    EMPTY: { title: "Empty", key: "EMPTY" },
    OUTSIDE_HEAD: { title: "Outside Head", key: "OUTSIDE_HEAD" },
    MULTIPLE: { title: "Multiple Found", key: "MULTIPLE" },
    NOT_IN_NEW_TAB: { title: "Not In New Tab", key: "NOT_IN_NEW_TAB" },
    NONE: { title: "None", key: "NONE" },
};

export const categoryMap = {
    META_TITLE: { title: "Meta Title", key: "META_TITLE", order: 1 },
    META_DESCRIPTION: {
        title: "Meta Description",
        key: "META_DESCRIPTION",
        order: 2,
    },

    EXTERNAL_LINK: { title: "External Links", key: "EXTERNAL_LINK", order: 3 },
    CANONICAL_LINK: { title: "Canonical Tag", key: "CANONICAL_LINK", order: 4 },
};

export const findIssueTags = (pageIssue) => {
    const issueTags = [];
    if (pageIssue.page_issue != "TAG_ISSUE")
        issueTags.push(pageIssue.page_issue || "none");

    pageIssue.selectorsLevel.forEach((selector) => {
        selector?.selector_issues?.forEach((selector_issue) => {
            issueTags.push(selector_issue.selector_issue || "none");
        });
    });

    pageIssue.issueTags = Array.from(new Set(issueTags)).map(
        (i) => issueTagsMap[i],
    );

    return pageIssue;
};

export const dummyData = {
    issues: [
        {
            page_id: 55642,
            page: "https://www.scalenut.com/seo-companies/denver",
            page_hash:
                "03075c2b7ae8551db14a0f5885df5e7824e9879f6066748d60f62208a5c845bc",
            page_issues: [
                {
                    page_issue_id: 3375,
                    page_issue: "MISSING",
                    category: "META_DESCRIPTION",
                    selectorsLevel: [
                        {
                            selector_id: 2507,
                            selector: "HTML > HEAD(0) > META(3)",
                            original_value: "",
                            selector_issues: [
                                {
                                    selector_issue_id: 2375,
                                    selector_issue: "EMPTY",
                                },
                            ],
                        },
                    ],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
                {
                    page_issue_id: 3378,
                    page_issue: "MISSING",
                    category: "META_TITLE",
                    selectorsLevel: [],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
                {
                    page_issue_id: 3376,
                    page_issue: "MISSING",
                    category: "CANONICAL_LINK",
                    selectorsLevel: [],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
                {
                    page_issue_id: 3377,
                    page_issue: "MISSING",
                    category: "EXTERNAL_LINK",
                    selectorsLevel: [],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
            ],
        },
        {
            page_id: 55643,
            page: "https://www.scalenut.com/seo-companies/denver",
            page_hash:
                "03075c2b7ae8551db14a0f5885df5e7824e9879f6066748d60f62208a5c845bc",
            page_issues: [
                {
                    page_issue_id: 33745,
                    page_issue: "MISSING",
                    category: "META_DESCRIPTION",
                    selectorsLevel: [
                        {
                            selector_id: 2507,
                            selector: "HTML > HEAD(0) > META(3)",
                            original_value: "",
                            selector_issues: [
                                {
                                    selector_issue_id: 2375,
                                    selector_issue: "EMPTY",
                                },
                            ],
                        },
                    ],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
                {
                    page_issue_id: 33478,
                    page_issue: "MISSING",
                    category: "META_TITLE",
                    selectorsLevel: [],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
                {
                    page_issue_id: 33476,
                    page_issue: "MISSING",
                    category: "CANONICAL_LINK",
                    selectorsLevel: [],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
                {
                    page_issue_id: 33477,
                    page_issue: "MISSING",
                    category: "EXTERNAL_LINK",
                    selectorsLevel: [],
                    recommendation: [
                        {
                            TEXT: "RECOMMENDED TEXT LOREM IPSUM",
                            ACTION: "ADD",
                        },
                    ],
                    fix_id: null,
                    fix: null,
                    fix_status: null,
                },
            ],
        },
    ],
};

export const getRecommendationsFix = (issue) => {
    if (issue.fix) return;
    const fix = [];
    switch (issue.page_issue) {
        case "MISSING":
            fix.push({
                action: "ADD",
                value:
                    issue.category == categoryMap.CANONICAL_LINK.key
                        ? issue.page
                        : issue.recommendation
                        ? issue.recommendation[0]?.TEXT || ""
                        : "",
            });
            break;
        case "MULTIPLE":
            {
                const options = [];
                for (const selector of issue.selectorsLevel) {
                    try {
                        if (selector.original_value.trim()) {
                            const value = selector.original_value.trim();
                            options.push({ value, id: selector.selector_id });
                        }
                    } catch (error) {}
                }
                if (options.length) {
                    fix.push({
                        action: "CHOOSE_FROM",
                        options,
                        value: options[0].value,
                    });
                } else {
                    fix.push({
                        action: "UPDATE_VALUE",
                        value:
                            issue.category == categoryMap.CANONICAL_LINK.key
                                ? issue.page
                                : issue.recommendation
                                ? issue.recommendation[0]?.TEXT || ""
                                : "",
                    });
                }
                for (const issueTag of issue.issueTags) {
                    if (issueTag.key == "EMPTY") {
                        fix.push({
                            action: "REMOVE_EMPTY",
                        });
                    } else if (issueTag.key == "OUTSIDE_HEAD") {
                        fix.push({
                            action: "INSIDE_HEAD",
                        });
                    }
                }
            }
            break;
        case "TAG_ISSUE":
            for (const issueTag of issue.issueTags) {
                if (issueTag.key == "EMPTY") {
                    fix.push({
                        action: "UPDATE_VALUE",
                        value:
                            issue.category == categoryMap.CANONICAL_LINK.key
                                ? issue.page
                                : issue.recommendation
                                ? issue.recommendation[0]?.TEXT || ""
                                : "",
                    });
                } else if (issueTag.key == "OUTSIDE_HEAD") {
                    let origValue = "";
                    for (const selector of issue.selectorsLevel) {
                        try {
                            origValue = selector.original_value.trim();
                        } catch (error) {}
                    }
                    fix.push({
                        action: "INSIDE_HEAD",
                        value:
                            issue.category == categoryMap.CANONICAL_LINK.key
                                ? issue.page
                                : origValue,
                    });
                } else if (issueTag.key == "NOT_IN_NEW_TAB") {
                    let origValue = "";
                    for (const selector of issue.selectorsLevel) {
                        try {
                            origValue = selector.original_value.trim();
                            fix.push({
                                action: "OPEN_IN_NEW_TAB",
                                value: 1,
                                selector: selector.selector,
                                link: origValue,
                            });
                        } catch (error) {}
                    }
                }
            }
            break;
    }
    return fix;
};

export const statues = [
    { title: "All", value: 0 },
    { title: "Approved", value: issueStatus.approved },
    { title: "Un-Approved", value: issueStatus.unApproved },
];

export const publishStatuses = [
    { title: "All", value: 0 },
    { title: "Published", value: 1 },
    { title: "Reverted", value: 2 },
];

export const categories = [
    "META_DESCRIPTION",
    "META_TITLE",
    "CANONICAL_LINK",
    "EXTERNAL_LINK",
];
export const tags = {
    META: ["MISSING", "EMPTY", "OUTSIDE_HEAD", "MULTIPLE"],
    EXTERNAL_LINK: ["NOT_IN_NEW_TAB"],
};

export const sortWithCategory = (a, b) => {
    return categoryMap[a.key].order - categoryMap[b.key].order;
};

export const issueFilterDefault = {
    search: "",
    status: 0,
    categories: Object.values(categoryMap).map((cat) => ({
        ...cat,
        issueTags: [],
    })),
    startDate: moment().subtract(1, "year").format(),
    get endDate() {
        return moment().format();
    },
};

export const issueLogsFilterDefault = {
    search: "",
    status: 0,
    categories: Object.values(categoryMap).map((cat) => ({
        ...cat,
        issueTags: [],
    })),
    startDate: moment().subtract(1, "year").format(),
    get endDate() {
        return moment().format();
    },
};

export const ConfettiConfig = {
    angle: "180",
    spread: 500,
    startVelocity: 30,
    elementCount: "200",
    dragFriction: "0.07",
    duration: 10000,
    stagger: 2,
    width: "20px",
    height: "20px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

export const DomainVerificationCases = {
    alreadyConnected: "ALREADY_VERIFIED_BY_OTHER_ACCOUNT",
};

export function extractDomain(url) {
    // Regular expression pattern to match domain name with its respective extension
    var pattern = /(?:https?:\/\/)?(?:www\.)?([^./]+)\.([a-z]+)(?:\/.*)?/;
    var match = url.match(pattern);
    if (match) {
        return match[1] + "." + match[2];
    } else {
        return null;
    }
}

export function removeProtocolsFromURL(url) {
    // Regular expression pattern to match domain name with its respective extension and path
    var pattern = /(?:https?:\/\/)?(?:www\.|app\.)?([^./]+)\.([a-z]+)(\/.*)?/;
    var match = url.match(pattern);
    if (match) {
        var domain = match[1] + "." + match[2];
        var path = match[3] ? match[3] : "/";
        return domain + path;
    } else {
        return null;
    }
}
