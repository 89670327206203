import React, { useEffect, useRef, useState } from "react";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import Select from "react-select";
import Button from "../../componentsV2/atoms/Button/Button";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../store/actions/alert";
import styles from "./superAdmin.module.css";

const optionsData = [
    { value: 0, label: "Disable" },
    { value: 1, label: "Active" },
];
export default function PremiumCruise({ setActiveAction }) {
    const [email, setEmail] = useState("");
    const [credits, setCredits] = useState(null);
    const [userId, setUserId] = useState({ id: "", email: "" });
    const [status, setStatus] = useState(optionsData[0]);
    const { putData, getData } = useHelperFunctions();
    const dispatch = useDispatch();
    const selectRef = useRef();

    async function updateStatus() {
        const res = await putData({
            url: "/api/superAdmin/user/premium-cruise",
            payload: {
                userEmail: email,
                status: status.value,
                credits,
            },
        });

        if (res.status == 200) {
            dispatch(
                displayAlert({
                    alertMessage: "Updated successfully",
                    alertType: "success",
                }),
            );
        }
    }

    useEffect(() => {
        if (email)
            (async () => {
                const res = await getData({
                    url: `/api/superAdmin/user/premium-cruise?userEmail=${encodeURIComponent(
                        email,
                    )}`,
                });
                if (res.status == 200) {
                    setCredits(res.data.credits || 0);
                    setStatus(optionsData[res.data.premium_cruise ? 1 : 0]);
                }
            })();
    }, [email]);

    useEffect(() => {
        if (selectRef?.current) selectRef?.current.select.setValue(status);
    }, [status]);
    console.log("dfgfdg");
    return (
        <div style={{ margin: "30px" }}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            <div style={{ display: "flex", alignItems: "end" }}>
                <div style={{ width: "30%", borderBottom: "1px solid black" }}>
                    <AutoComplete
                        email={email}
                        className={styles.emailField}
                        setEmail={setEmail}
                        setUserId={setUserId}
                    />
                </div>
                <div
                    style={{
                        width: "200px",
                        marginLeft: "10px",
                        marginRight: "10px",
                    }}
                >
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(val) => {
                            setStatus(val);
                        }}
                        defaultValue={optionsData[0]}
                        options={optionsData}
                        ref={selectRef}
                    />
                </div>
                <div
                    style={{
                        width: "200px",
                        marginLeft: "10px",
                        marginRight: "10px",
                    }}
                >
                    <input
                        className={styles.creditsInput}
                        type="number"
                        onChange={(e) => setCredits(e.target.value)}
                        value={credits}
                        placeholder="Credits"
                    />
                </div>

                <Button
                    text="Submit"
                    handler={() => updateStatus()}
                    width={200}
                    height={38}
                    disabled={!email}
                />
            </div>
        </div>
    );
}
