import moment from "moment";
import React, { useEffect, useState } from "react";
let interval = null;
function Timer({ threshold, setStatusName }) {
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    interval = setInterval(() => {
      setUpdate((p) => p + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [threshold]);

  const dur = new Date(threshold) - new Date();

  if (threshold && dur < 0) {
    setStatusName(6);
    return null;
  }
  console.log(threshold);
  const duration = moment.duration(dur);

  if (duration.days()) {
    if (duration.days() > 1) return duration.days() + " days left";
    return duration.days() + " day left";
  }
  return (
    duration.hours() +
    "hr" +
    " : " +
    duration.minutes() +
    "m" +
    " : " +
    duration.seconds() +
    "s" +
    " left"
  );
}

export default Timer;
