import React, { useEffect, useState } from "react";
import Textfield from "../../../../componentsV2/atoms/CustomTextfield/Textfield";
import SVGIcon from "../../../../componentsV2/atoms/SVGIcon";
import styles from "./UserArchive.module.css";
import DocCard from "../../../../componentsV2/organisms/DocCard/DocCard";
import { useHistory } from "react-router-dom";
import ReportsSkeleton from "../../../../componentsV2/organisms/DocCard/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import userDocsHook from "../../../../componentsV2/Pages/UserDocs/userDocsHook";
import userReportHooks from "../../../../componentsV2/Pages/UserReports/UserReportsHook";
import ClusterDocRow from "../../../../componentsV2/Pages/ClusterDocs/ClusterDocRow";
import clusterDocsHook from "../../../../componentsV2/Pages/ClusterDocs/ClusterDocsHook";
import NewUIDropDown from "../../../../componentsV2/molecules/NewUIDropdown/NewUIDropDown";
import useAIHumanizerHook from "../../../../componentsV2/Pages/AIHumanizer/useAIHumanizerHook";

const sortByOptions = [
    { title: "created at", id: 1 },
    { title: "updated at", id: 2 },
];

const dropdownOptions = [
    { title: "SEO Articles", active: 1 },
    { title: "Optimizer Reports", active: 4 },
    { title: "Keyword Reports", active: 3 },
    { title: "AI Docs", active: 2 },
    { title: "Humanized Docs", active: 5 },
];

const UserArchives = ({ bin, setActive, active, userArcProp }) => {
    const {
        loading,
        hasMore,
        requestData,
        docs,
        setLoading,
        setRequestData,
        restoreDoc,
        deleteDoc,
    } = userArcProp;

    const [sortBy, setSortBy] = useState(sortByOptions[0]);
    const [filterTagList, setFilterTagsList] = useState([]);
    const [newDocName, SetNewDocName] = useState("");
    const [order, setOrder] = useState(0);
    const history = useHistory();
    const [type, setType] = useState(
        dropdownOptions.find((item) => item.active == active),
    );

    useEffect(() => {
        if (active == 3) {
            setLoading(true);
            setRequestData((ps) => ({
                ...ps,
                page: 1,
                created: sortBy.id == 1 ? true : false,
                updated: sortBy.id != 1 ? true : false,
                is_deleted: true,
                tags: filterTagList.map((tag) => tag.id),
                order,
            }));
        }
        if (active == 2) {
            setLoading(true);

            setRequestData((ps) => ({
                ...ps,
                page: 1,
                created: sortBy.id == 1 ? true : false,
                updated: sortBy.id != 1 ? true : false,
                is_deleted: true,
                tags: filterTagList.map((tag) => tag.id),
                order,
            }));
        }
        if (active == 1) {
            setLoading(true);

            setRequestData((ps) => ({
                ...ps,
                page: 1,
                created: sortBy.id == 1 ? true : false,
                updated: sortBy.id != 1 ? true : false,
                is_deleted: true,
                tags: filterTagList.map((tag) => tag.id),
                order,
            }));
        }
        if (active == 4) {
            setLoading(true);

            setRequestData((ps) => ({
                ...ps,
                page: 1,
                created: sortBy.id == 1 ? true : false,
                updated: sortBy.id != 1 ? true : false,
                is_deleted: true,
                tags: filterTagList.map((tag) => tag.id),
                order,
            }));
        }
        if (active == 5) {
            setLoading(true);

            setRequestData((ps) => ({
                ...ps,
                page: 1,
                created: sortBy.id == 1 ? true : false,
                updated: sortBy.id != 1 ? true : false,
                is_deleted: true,
                order,
            }));
        }
    }, [sortBy, bin, filterTagList, order, active, type]);

    function handleActive(val) {
        setActive(val.active);
        setType(val);
    }

    return (
        <div className={styles.container}>
            <div className={styles.containerTop}>
                <div className={styles.sectionTitle}>Archive</div>
                <div className={styles.sectionRight}>
                    <div className={styles.textSearch}>
                        <Textfield
                            height={38}
                            containerStyle={{
                                marginBottom: "0",
                                borderRadius: 3,
                            }}
                            props={{
                                onChange: (e) => {
                                    const value = e.target.value.trim();
                                    setRequestData((ps) => ({
                                        ...ps,
                                        search: value,
                                        page: 1,
                                    }));
                                },
                                style: {
                                    borderRadius: 3,
                                    border: "solid 1px rgba(75, 75, 85, 0.16)",
                                    backgroundColor: "#fff",
                                    paddingLeft: "40px",
                                    borderRadius: 3,
                                },
                            }}
                            placeholder={
                                active == 1
                                    ? "Search SEO Articles"
                                    : active == 2
                                    ? "Search AI Docs"
                                    : active == 3
                                    ? "Search Keyword Reports"
                                    : active == 4
                                    ? "Search Optimizer Reports"
                                    : "Search Humanized Docs"
                            }
                            label=""
                            Icon={() => (
                                <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />
                            )}
                            iconStyle={{
                                left: "10px",
                                right: "unset",
                                top: "20px",
                            }}
                        />
                    </div>
                    <NewUIDropDown
                        selectedOption={type}
                        handler={(option) => {
                            handleActive(option);
                        }}
                        options={dropdownOptions}
                        style={{
                            zIndex: 11,
                            height: 40,
                        }}
                        label={"Filter by:"}
                    />
                </div>
            </div>
            <div className={styles.docsTableContainer}>
                <div
                    style={{
                        margin: "0 calc(0.4 /14 * 100%)",
                    }}
                >
                    {loading ? (
                        <>
                            <ReportsSkeleton type={true} />
                            <ReportsSkeleton type={true} />
                            <ReportsSkeleton type={true} />
                            <ReportsSkeleton type={true} />
                            <ReportsSkeleton type={true} />
                        </>
                    ) : (
                        <InfiniteScroll
                            dataLength={docs.length}
                            next={() => {
                                setRequestData((ps) => ({
                                    ...ps,
                                    page: requestData.page + 1,
                                }));
                            }}
                            height={"calc(100vh - 142px)"}
                            hasMore={hasMore}
                            loader={
                                <>
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                    <ReportsSkeleton type={true} />
                                </>
                            }
                        >
                            {docs.length > 0 || loading ? (
                                <table
                                    className={
                                        styles.docTable +
                                        " " +
                                        (active == 2 && styles.AiDocsTable)
                                    }
                                >
                                    {active == 5 ? (
                                        <tr className={styles.reportHeader}>
                                            <td>
                                                <span>Name</span>
                                            </td>

                                            <td
                                                style={{
                                                    textAlign: "center",
                                                    width: 150,
                                                }}
                                            >
                                                <span>
                                                    Original Content AI %
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span>
                                                    Humanized Content AI %
                                                </span>
                                            </td>

                                            <td style={{ width: 250 }}></td>
                                        </tr>
                                    ) : active == 2 ? (
                                        <tr className={styles.reportHeader}>
                                            <td
                                                style={{
                                                    textAlign: "left",
                                                }}
                                            >
                                                <span>Name</span>
                                            </td>

                                            <td
                                                style={{
                                                    textAlign: "left",
                                                    width: 150,
                                                }}
                                            >
                                                <span>Created at</span>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span>Word Count</span>
                                            </td>

                                            <td style={{ width: 160 }}></td>
                                        </tr>
                                    ) : (
                                        <tr className={styles.reportHeader}>
                                            <td>
                                                <div>
                                                    <span>Keyword</span> &nbsp;
                                                    <SVGIcon
                                                        color="gray"
                                                        size={12}
                                                        tooltip={
                                                            "Targeted Primary Keyword"
                                                        }
                                                        src={
                                                            "/New UI/SVG/info.svg"
                                                        }
                                                    />
                                                </div>
                                            </td>

                                            <td style={{ textAlign: "left" }}>
                                                <span>Created at</span>
                                            </td>

                                            {active != 4 && (
                                                <td>
                                                    {active != 3 && (
                                                        <div>
                                                            <span>
                                                                {" "}
                                                                Word Count{" "}
                                                            </span>{" "}
                                                            &nbsp;
                                                            <SVGIcon
                                                                color="gray"
                                                                size={12}
                                                                tooltip={
                                                                    "Document Word Count"
                                                                }
                                                                src={
                                                                    "/New UI/SVG/info.svg"
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                            )}

                                            {active != 3 && (
                                                <td>
                                                    <div>
                                                        <span>SEO Score</span>
                                                        &nbsp;
                                                        <SVGIcon
                                                            color="gray"
                                                            size={12}
                                                            tooltip={
                                                                "SEO Score tells you how SEO-friendly your content is and gives you all the actionable insights."
                                                            }
                                                            src={
                                                                "/New UI/SVG/info.svg"
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            )}

                                            {active != 3 && (
                                                <td
                                                    style={{
                                                        width: !bin ? 250 : 150,
                                                    }}
                                                ></td>
                                            )}
                                        </tr>
                                    )}
                                    {docs.map((doc) => {
                                        if (active == 3) {
                                            return (
                                                <ClusterDocRow
                                                    archived={true}
                                                    data={doc}
                                                    key={doc.topic_id}
                                                    handleArchive={deleteDoc}
                                                />
                                            );
                                        } else if (active == 5) {
                                            return (
                                                <DocCard
                                                    openDoc={() =>
                                                        openDoc(doc.scan_id)
                                                    }
                                                    className={styles.doc}
                                                    doc={doc}
                                                    archived={bin}
                                                    restoreDoc={restoreDoc}
                                                    deleteDoc={deleteDoc}
                                                    key={doc.document_id}
                                                    aiHumanizer={true}
                                                />
                                            );
                                        } else {
                                            return (
                                                <DocCard
                                                    openDoc={() => {}}
                                                    className={styles.doc}
                                                    doc={doc}
                                                    seoDoc={
                                                        active == 1 ||
                                                        active == 4
                                                            ? true
                                                            : false
                                                    }
                                                    archived={bin}
                                                    restoreDoc={restoreDoc}
                                                    deleteDoc={deleteDoc}
                                                    auditMode={active == 4}
                                                />
                                            );
                                        }
                                    })}
                                </table>
                            ) : (
                                <>
                                    <div className={styles.noItems}>
                                        <SVGIcon
                                            size={300}
                                            src={
                                                "/New UI/SVG/Emptydocument.svg"
                                            }
                                        />
                                        {active == 1 && (
                                            <h3 style={{ marginTop: -50 }}>
                                                No SEO Articles Found
                                            </h3>
                                        )}
                                        {active == 2 && (
                                            <h3 style={{ marginTop: -50 }}>
                                                No AI Docs Found
                                            </h3>
                                        )}

                                        {active == 3 && (
                                            <h3 style={{ marginTop: -50 }}>
                                                No Keyword Reports Found
                                            </h3>
                                        )}
                                        {active == 4 && (
                                            <h3 style={{ marginTop: -50 }}>
                                                No Optimizer Reports Found
                                            </h3>
                                        )}
                                        {active == 5 && (
                                            <h3 style={{ marginTop: -50 }}>
                                                No Humanized Docs Found
                                            </h3>
                                        )}
                                    </div>
                                </>
                            )}
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

function UserArchiveContainer() {
    const [active, setActive] = useState(1);

    switch (active) {
        case 1:
            return <SeoDocsDel setActive={setActive} active={active} />;

        case 2:
            return <AiDocsDel setActive={setActive} active={active} />;

        case 3:
            return <ClusterDocsDel setActive={setActive} active={active} />;

        case 4:
            return <AuditDocsDel setActive={setActive} active={active} />;

        case 5:
            return <HumanizedDocsDel setActive={setActive} active={active} />;

        default:
            return <></>;
    }
}

function AiDocsDel({ setActive, active }) {
    const {
        loading,
        createDoc,
        hasMore,
        requestData,
        openDoc,
        docs,
        setLoading,
        setRequestData,
        restoreDoc,
        deleteDoc,
        creatingDoc,
    } = userDocsHook({ bin: true });

    const userArcProp = {
        docs,
        loading,
        restoreDoc,
        deleteDoc,
        setRequestData,
        requestData,
        hasMore,
        setLoading,
    };

    return (
        <UserArchives
            bin={true}
            active={active}
            setActive={setActive}
            userArcProp={userArcProp}
        />
    );
}

function SeoDocsDel({ setActive, active }) {
    const {
        loading,
        docs,
        setRequestData,
        requestData,
        hasMore,
        deleteReport: deleteDoc,
        restoreReport: restoreDoc,
        setLoading,
    } = userReportHooks({ bin: true });

    const userArcProp = {
        docs,
        loading,
        restoreDoc,
        deleteDoc,
        setRequestData,
        requestData,
        hasMore,
        setLoading,
    };

    return (
        <UserArchives
            bin={true}
            setActive={setActive}
            active={active}
            userArcProp={userArcProp}
        />
    );
}

function ClusterDocsDel({ setActive, active }) {
    const {
        isLoading: loading,
        docs,
        setRequestData,
        requestData,
        hasMore,
        handleArchive: deleteDoc,
        restoreReport: restoreDoc,
        setIsLoading: setLoading,
    } = clusterDocsHook({ bin: true });

    const userArcProp = {
        docs,
        loading,
        restoreDoc,
        deleteDoc,
        setRequestData,
        requestData,
        hasMore,
        setLoading,
    };

    return (
        <UserArchives
            bin={true}
            setActive={setActive}
            active={active}
            userArcProp={userArcProp}
        />
    );
}

function AuditDocsDel({ setActive, active }) {
    const {
        loading,
        docs,
        setRequestData,
        requestData,
        hasMore,
        deleteReport: deleteDoc,
        restoreReport: restoreDoc,
        setLoading,
    } = userReportHooks({ bin: true, doc_type: 2 });

    const userArcProp = {
        docs,
        loading,
        restoreDoc,
        deleteDoc,
        setRequestData,
        requestData,
        hasMore,
        setLoading,
    };

    return (
        <UserArchives
            bin={true}
            setActive={setActive}
            active={active}
            userArcProp={userArcProp}
        />
    );
}

function HumanizedDocsDel({ setActive, active }) {
    const {
        loading,
        scans,
        setRequestData,
        requestData,
        hasMore,
        deleteReport: deleteDoc,
        restoreReport: restoreDoc,
        setLoading,
    } = useAIHumanizerHook(true);

    const userArcProp = {
        docs: scans,
        loading,
        restoreDoc,
        deleteDoc,
        setRequestData,
        requestData,
        hasMore,
        setLoading,
    };

    return (
        <UserArchives
            bin={true}
            setActive={setActive}
            active={active}
            userArcProp={userArcProp}
        />
    );
}

export default UserArchiveContainer;
