import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
} from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import { DomainVerificationCases, genScript } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import { BiChevronDown, BiChevronUp, BiCopy } from "react-icons/bi";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { VscClose, VscDebugDisconnect } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { GoClock } from "react-icons/go";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoIosRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import mixpanel from "mixpanel-browser";
import Button from "../../atoms/Button/Button";
import Modal from "../../atoms/Modal/Modal";
import SVGIcon from "../../atoms/SVGIcon";
import useHelperFunctions from "../../helperFunctions";
import { BASE_URL, endPoints } from "../InterLinking/constants";
import { displayAlert } from "../../../store/actions/alert";
import useGscHook from "../GoogleSearchConsole/useGscHook";
import { useLinkManagerMixpanel } from "../InterLinking/useLinkManagerMixpanel";
const useAccordionCompleteStyle = makeStyles(() => {
    return {
        root: {
            backgroundColor: "none",
            boxShadow: "none",
            padding: 0,
            margin: "0 !important",
            "& .MuiAccordionSummary-root": {
                paddingInline: 0,
                minHeight: 40,
                borderBottom: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: " 0",
            },
            "& .MuiAccordionDetails-root": {
                padding: "6px 20px 10px 20px",
                fontSize: 14,
            },
        },
    };
});

const DomainVerification = ({
    connectDomainRef,
    domainContext,
    screen = "Link Manager",
}) => {
    const [openStateModal, setOpenStateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useAccordionCompleteStyle();
    const [expanded, setExpanded] = useState(false);
    const [copied, setCopied] = useState(false);
    const { email } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { getTrimmedDomainURL } = useGscHook();
    const { lMVerificationCta } = useLinkManagerMixpanel();
    const {
        domain,
        setDomainVerification,
        setIsDomainConnected,
        verification_key,
        is_verified,
        domain_id,
        getUserDomains,
        user_verification_attempts,
        auto_verification_attempts,
    } = domainContext;
    const { postData } = useHelperFunctions();
    useEffect(() => {
        return () => setExpanded(false);
    }, [domain_id]);

    const verifyReq = async () => {
        setLoading(true);
        const res = await postData({
            completeUrl: BASE_URL + endPoints.verify,
            payload: {
                domain_id: domain_id,
            },
        });
        if (res.status === 200) {
            getUserDomains(false);

            if (res.data.is_verified === 1) {
                setDomainVerification(1);

                setIsDomainConnected(false);
            } else if (res.data.is_verified === 2) {
                setDomainVerification(2);
                setOpenStateModal(true);
            } else {
                setDomainVerification(0);
                dispatch(
                    displayAlert({
                        alertMessage: res.data.message,
                        alertType: "error",
                    }),
                );
                if (res.data.case != DomainVerificationCases.alreadyConnected) {
                    setOpenStateModal(true);
                }
            }

            setLoading(false);
        }
    };

    const ref = useRef(null);

    useEffect(() => {
        if (connectDomainRef?.current)
            connectDomainRef.current = () => {
                setExpanded(true);
                ref.current.scrollIntoView({ behavior: "smooth" });
            };
    }, []);

    return (
        <div ref={ref}>
            <StateModal
                is_verified={is_verified}
                verifyReq={verifyReq}
                setExpanded={setExpanded}
                openStateModal={openStateModal}
                setOpenStateModal={setOpenStateModal}
                user_verification_attempts={user_verification_attempts}
                auto_verification_attempts={auto_verification_attempts}
            />
            <div className={styles.connectDomainContainer}>
                <Accordion
                    onChange={(e, state) => {
                        lMVerificationCta({
                            ctaType: "Connect Domain",
                            ctaUsed: `${
                                state ? "Expand" : "Collapse"
                            } Connect Domain`,
                            screen: screen,
                            domainStatus: is_verified,
                        });
                        setExpanded(state);
                    }}
                    classes={classes}
                    expanded={expanded}
                >
                    <AccordionSummary>
                        <div
                            style={{
                                background: expanded ? "none" : "",
                                paddingTop: expanded ? 20 : "",
                            }}
                            className={`${styles.notConnected} ${
                                is_verified === 2
                                    ? styles.notConnectedPending
                                    : ""
                            }`}
                        >
                            <div
                                style={{
                                    color: "var(----secondary-color3)",
                                    minHeight: "36px",
                                }}
                                className={styles.notConnected_header}
                            >
                                {expanded && (
                                    <div
                                        className={styles.notConnected_heading}
                                    >
                                        {is_verified === 2
                                            ? "Verification in Progress"
                                            : "Connect Domain"}
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        minHeight: expanded ? "auto" : "36px",
                                    }}
                                >
                                    {is_verified === 2 ? (
                                        <>
                                            <FaClock
                                                size={18}
                                                color="rgb(255, 190, 87)"
                                                style={{ marginRight: 5 }}
                                            />
                                            Your domain verification is in
                                            progress. You'll receive a
                                            confirmation email at&nbsp;
                                            <b
                                                style={{
                                                    color: "#1f4cbe",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {email}
                                            </b>
                                            &nbsp;within 24 hours.
                                        </>
                                    ) : (
                                        <>
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/exclamation-circle.svg"
                                                }
                                                size={18}
                                                style={{ marginRight: 5 }}
                                                color="#FC5A5A"
                                            />
                                            Your domain{" "}
                                            <span
                                                style={{ display: "contents" }}
                                                className={styles.blue}
                                            >
                                                {getTrimmedDomainURL(domain)}
                                            </span>{" "}
                                            is not connected to Link Manager.
                                            Please connect domain to push
                                            changes.
                                        </>
                                    )}
                                </div>
                            </div>
                            {!expanded ? (
                                is_verified === 2 ? (
                                    <Button
                                        width={110}
                                        handler={() => {}}
                                        text="View Script"
                                        fontSize={12}
                                        height={34}
                                        style={{
                                            backgroundColor: "#7188AA1A",
                                            color: "#7188AA",
                                            fontWeight: 500,
                                        }}
                                        Icon={() => <BiChevronDown size={20} />}
                                    ></Button>
                                ) : (
                                    <Button
                                        width={150}
                                        handler={() => {}}
                                        text="Connect Domain"
                                        fontSize={14}
                                        height={36}
                                        style={{
                                            fontWeight: 500,
                                        }}
                                    ></Button>
                                )
                            ) : (
                                <Button
                                    width={80}
                                    handler={() => {
                                        setExpanded(false);
                                    }}
                                    text="Hide"
                                    fontSize={10}
                                    height={30}
                                    style={{
                                        backgroundColor: "#7188AA1A",
                                        color: "#7188AA",
                                        fontWeight: 400,
                                    }}
                                    Icon={() => <BiChevronUp size={18} />}
                                ></Button>
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: "100%", color: "#6b6b6b" }}>
                            Below is the Javascript code you should include in
                            the <span className={styles.blue}>{"<head> "}</span>
                            tag of your site. For best results, paste this on
                            every page, or in the site template, right before
                            the closing{" "}
                            <span className={styles.blue}>{"<head/> "}</span>
                            tag.
                            <div className={styles.scriptContainer}>
                                <div className={styles.script}>
                                    {genScript(verification_key)}
                                </div>
                                <Button
                                    width={100}
                                    handler={() => {
                                        lMVerificationCta({
                                            ctaType: "Connect Domain",
                                            ctaUsed: "Copy Code",
                                            screen: screen,
                                            domainStatus: is_verified,
                                        });
                                        navigator.clipboard.writeText(
                                            genScript(verification_key),
                                        );
                                        setCopied(true);
                                    }}
                                    text={copied ? "Copied" : "Copy code"}
                                    fontSize={10}
                                    height={30}
                                    style={
                                        copied
                                            ? {
                                                  backgroundColor:
                                                      "rgb(1 77 197 / 7%)",
                                                  color: "#1f4cbe",
                                              }
                                            : {
                                                  backgroundColor: "#7188AA1A",
                                                  color: "#7188AA",
                                              }
                                    }
                                    Icon={() => <BiCopy size={18} />}
                                    iconReverse
                                ></Button>
                            </div>
                            <div className={styles.checkConnection}>
                                <div
                                    className={styles.unableToVerify}
                                    onClick={(e) => {
                                        lMVerificationCta({
                                            ctaType: "Connect Domain",
                                            ctaUsed: "Unable to Verify",
                                            screen: screen,
                                            domainStatus: is_verified,
                                        });

                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.open(
                                            "https://help.scalenut.com/need-help-to-connect-with-wordpress-to-link-manager/",
                                            "_blank",
                                        );
                                    }}
                                >
                                    Unable to Verify
                                    <IoMdHelpCircleOutline size={16} />
                                </div>

                                {is_verified === 2 ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        You can retry if you have updated the
                                        script
                                        <Button
                                            width={140}
                                            handler={() => {
                                                verifyReq();
                                            }}
                                            text={
                                                loading ? "Checking" : "Retry"
                                            }
                                            fontSize={14}
                                            height={36}
                                            iconReverse
                                            style={{
                                                marginLeft: 20,
                                                fontWeight: 500,
                                                color: "#1f4cbe",
                                                backgroundColor: "#f5f0fb",

                                                fontSize: 14,
                                            }}
                                            loading={loading}
                                            loadingText={loading}
                                            Icon={IoIosRefresh}
                                        ></Button>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        Check connection if done with above
                                        procedure
                                        <Button
                                            width={180}
                                            handler={() => {
                                                lMVerificationCta({
                                                    ctaType: "Connect Domain",
                                                    ctaUsed: "Check Connection",
                                                    screen: screen,
                                                    domainStatus: is_verified,
                                                });

                                                verifyReq();
                                            }}
                                            text={
                                                loading
                                                    ? "Checking"
                                                    : "Check Connection"
                                            }
                                            fontSize={14}
                                            height={34}
                                            iconReverse
                                            Icon={() => (
                                                <VscDebugDisconnect size={20} />
                                            )}
                                            style={{
                                                marginLeft: 20,
                                                fontWeight: 400,
                                            }}
                                            loading={loading}
                                            loadingText={loading}
                                        ></Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

const StateModal = ({
    is_verified,
    verifyReq,
    setExpanded,
    openStateModal,
    setOpenStateModal,
    user_verification_attempts,
    auto_verification_attempts,
}) => {
    const { email } = useSelector((state) => state.auth);

    // useEffect(() => {
    //     if (is_verified === 0 && auto_verification_attempts > 0)
    //         setOpenStateModal(true);
    // }, [is_verified]);

    return openStateModal && is_verified >= 0 ? (
        <Modal setModal={setOpenStateModal}>
            {is_verified === 0 ? (
                <div className={styles.vStateModal}>
                    <div
                        onClick={() => {
                            setOpenStateModal(false);
                        }}
                        className={styles.vStateModal_closeCta}
                    >
                        <VscClose size={20} />
                    </div>
                    <h3 style={{ color: "rgba(252, 90, 90, 1)" }}>
                        <div
                            style={{
                                backgroundColor: "rgba(252, 90, 90, 0.1)",
                            }}
                            className={styles.cClock}
                        >
                            {true ? <IoCloseCircleOutline /> : <GoClock />}
                        </div>
                        Verification failed
                    </h3>
                    <div style={{ fontSize: 15, lineHeight: "24px" }}>
                        We were unable to verify your domain. Please try
                        verifying again or contact chat support for assistance.
                    </div>

                    <div className={styles.footer}>
                        <Button
                            width={150}
                            height={40}
                            style={{
                                color: "#1f4cbe",
                                backgroundColor: "#f5f0fb",
                                fontSize: 14,
                            }}
                            text="Contact Support"
                            handler={() => {
                                window.userengage &&
                                    window.userengage("widget.open");
                            }}
                        ></Button>
                        <Button
                            width={120}
                            height={40}
                            style={{
                                fontSize: 14,
                            }}
                            text="Close"
                            iconReverse={true}
                            handler={() => {
                                setExpanded(true);
                                setOpenStateModal(false);
                            }}
                        ></Button>
                    </div>
                </div>
            ) : (
                <div className={styles.vStateModal}>
                    <div
                        onClick={() => {
                            setOpenStateModal(false);
                        }}
                        className={styles.vStateModal_closeCta}
                    >
                        <VscClose />
                    </div>
                    <h3>
                        <div className={styles.cClock}>
                            <GoClock />
                        </div>
                        Verification in progress
                    </h3>
                    <div style={{ fontSize: 15, lineHeight: "24px" }}>
                        Your domain verification is in progress. You'll receive
                        a confirmation email at{" "}
                        <b
                            style={{
                                color: "#1f4cbe",
                                fontWeight: 600,
                            }}
                        >
                            {email}
                        </b>{" "}
                        within 24 hours.
                    </div>

                    <div
                        style={{
                            backgroundColor: "rgba(255, 190, 87, 0.1)",
                            color: "rgba(190, 140, 60, 1)",
                            padding: 10,
                            fontSize: 14,
                            lineHeight: "22px",
                        }}
                    >
                        <b>Note:</b> verification sometimes takes time due to
                        uncleared cache on your website
                    </div>

                    <div
                        className={styles.footer}
                        style={{ justifyContent: "flex-end" }}
                    >
                        {/* <Link
                            to="https://help.webtune.ai/need-help-to-connect-your-wordpress-website-to-webtune-ai"
                            target="_blank"
                        >
                            Help <AiOutlineQuestionCircle />
                        </Link> */}
                        <Button
                            width={150}
                            height={40}
                            style={{
                                color: "#7188aa",
                                backgroundColor: "#f1f3f6",

                                border: "1px solid rgb(233, 236, 244)",
                                fontSize: 14,
                            }}
                            text="Close"
                            handler={() => setOpenStateModal(false)}
                        ></Button>
                    </div>
                </div>
            )}
        </Modal>
    ) : (
        ""
    );
};

export default DomainVerification;
