import React, { useEffect } from "react";
import style from "./style.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import { TOTAL_STEPS, stepWiseData, userEnvironments } from "./contants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import useHelperFunctions from "../../helperFunctions";
import { Skeleton } from "@material-ui/lab";
import { updateUserInfoV2 } from "../../../store/actions/auth";
import { jumbleArray } from "../../utlities";
import { useMemo } from "react";
import { userGuidingHelper } from "../../../utility/userGuidingHelper";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { setCruiseModePopup } from "../../../store/actions/state";

const scale8 = {
    transform: "scale(0.8)",
    height: 800,
    marginBlock: -140,
    marginBottom: -120,
    marginLeft: -380,
};

const scale5 = {
    transform: "scale(0.55)",
    height: 800,
    marginBlock: -220,
    marginBottom: -205,
    marginLeft: -580,
};

const scale6 = {
    transform: "scale(0.7)",
    height: 800,
    marginBlock: -160,
    marginBottom: -150,
    marginLeft: -460,
};

function OnBoardingFlow() {
    const [stepsCompleted, setStepsCompleted] = useState(0);
    const [hasSkipped, setHasSkipped] = useState(false);
    const [userEnvironment, setUserEnvironment] = useState("");
    const { questions, onBoardingStep, onBoardingStatus } =
        useContext(OnBoardingContext);
    const { postData } = useHelperFunctions();
    const [userResponses, setUserResponses] = useState([]);
    const userId = useSelector((state) => state.auth.userId);
    const signUpMode = useSelector((state) => state.auth.signup_mode);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { mixpanelTrack } = useMixpanelHook();

    const clyModal = signUpMode == 1 && stepsCompleted == 1;

    const { isMobile } = useSelector((state) => state.state);

    useEffect(() => {
        setUserResponses([]);
        if (hasSkipped) {
            dispatch(updateUserInfoV2({ on_boarding: false }));
            dispatch(
                setCruiseModePopup({
                    value: true,
                    isClose: false,
                }),
            );
        }
        if (isMobile) {
            if (stepsCompleted >= 1) {
                dispatch(updateUserInfoV2({ on_boarding: false }));
                dispatch(
                    setCruiseModePopup({
                        value: true,
                        isClose: false,
                    }),
                );
            }
        } else if (signUpMode == 1) {
            if (stepsCompleted >= TOTAL_STEPS) {
                dispatch(updateUserInfoV2({ on_boarding: false }));
                dispatch(
                    setCruiseModePopup({
                        value: true,
                        isClose: false,
                    }),
                );
            }
        } else if (signUpMode == 2) {
            if (stepsCompleted >= TOTAL_STEPS - 1) {
                dispatch(updateUserInfoV2({ on_boarding: false }));
                dispatch(
                    setCruiseModePopup({
                        value: true,
                        isClose: false,
                    }),
                );
            }
        }
    }, [stepsCompleted, hasSkipped]);

    useEffect(() => {
        setStepsCompleted(onBoardingStep);
    }, [onBoardingStep]);

    const saveUserResponse = async (
        question_id = 0,
        answers = [],
        step = 0,
        hasSkip = false,
    ) => {
        const payloadObj = {
            question_id,
            answers: "",
            step,
            status: 2,
            device: isMobile ? 2 : 1,
        };
        const res = await postData({
            url: "/api/onboarding/save",
            payload: hasSkip
                ? payloadObj
                : {
                      question_id,
                      answers: answers
                          ?.map((i) => i?.trim())
                          ?.join(",")
                          ?.trim(),
                      step,
                      status:
                          isMobile && step == 1
                              ? 1
                              : signUpMode == 2 && step == 1
                              ? 1
                              : step == 2
                              ? 1
                              : 0,
                      device: isMobile ? 2 : 1,
                  },
        });

        if (res.status == 200) {
            if (step == 1 && res?.data?.recommended_feature) {
                userGuidingHelper(userId, {
                    recommended_feature: res.data.recommended_feature,
                });
            }
            setStepsCompleted(() => step);
        } else if (res.status == 203) {
            setStepsCompleted(() => res.data.step);
        }
    };

    const renderStep = () => {
        // switch (stepsCompleted) {
        //     case 0:
        //         return (
        //             <Step1
        //                 setUserResponses={setUserResponses}
        //                 isMobile={isMobile}
        //             />
        //         );
        //     case 1:
        //         return (
        //             <Step2
        //                 setUserEnvironment={setUserEnvironment}
        //                 setUserResponses={setUserResponses}
        //                 isMobile={isMobile}
        //             />
        //         );
        //     case 2:
        //         return (
        //             <Step3
        //                 userEnvironment={userEnvironment}
        //                 setUserResponses={setUserResponses}
        //                 isMobile={isMobile}
        //             />
        //         );
        //     case 3:
        //         return clyModal ? (
        //             <SetupCallScreen
        //                 successCallback={() => {
        //                     saveUserResponse(
        //                         stepWiseData[4].questions[0].id,
        //                         ["null"],
        //                         4,
        //                     ),
        //                         setUserResponses([
        //                             {
        //                                 answers: ["null"],
        //                                 question_id:
        //                                     stepWiseData[4].questions[0].id,
        //                                 step: 4,
        //                             },
        //                         ]);
        //                 }}
        //             />
        //         ) : (
        //             <Step4
        //                 userEnvironment={userEnvironment}
        //                 setUserResponses={setUserResponses}
        //                 isMobile={isMobile}
        //             />
        //         );
        // }
        switch (stepsCompleted) {
            case 0:
                return (
                    <Step1
                        setUserResponses={setUserResponses}
                        isMobile={isMobile}
                    />
                );
            case 1:
                return clyModal ? (
                    <SetupCallScreen
                        successCallback={({ isScheduled }) => {
                            saveUserResponse(
                                stepWiseData[4].questions[0].id,
                                [isScheduled ? "Schedule" : "null"],
                                2,
                            ),
                                setUserResponses([
                                    {
                                        answers: [
                                            isScheduled ? "Schedule" : "null",
                                        ],
                                        question_id:
                                            stepWiseData[4].questions[0].id,
                                        step: 2,
                                    },
                                ]);
                            dispatch(updateUserInfoV2({ on_boarding: false }));
                            dispatch(
                                setCruiseModePopup({
                                    value: true,
                                    isClose: false,
                                }),
                            );
                        }}
                    />
                ) : (
                    <></>
                );
        }
    };

    return (
        <div className={style.container}>
            <div className={style.logoM}>
                <SVGIcon src="/New UI/SVG/Scalenut.svg" size={28} />

                <h2>&nbsp;Scalenut</h2>
            </div>
            <div style={{ width: "1024px" }} className={style.innerContainer}>
                {!questions.length ? (
                    <>
                        <Skeleton
                            style={{ borderRadius: 20 }}
                            height={30}
                            width={"40%"}
                            variant="rect"
                        />
                        <h1></h1>{" "}
                        <Skeleton
                            style={{ borderRadius: 20 }}
                            height={30}
                            width={"100%"}
                            variant="rect"
                        />{" "}
                        <h1></h1>{" "}
                        <Skeleton
                            style={{ borderRadius: 20 }}
                            height={30}
                            width={120}
                            variant="rect"
                        />
                        <br />
                        <Skeleton
                            style={{ borderRadius: 20 }}
                            height={30}
                            width={120}
                            variant="rect"
                        />
                        <br />
                        <Skeleton
                            style={{ borderRadius: 20 }}
                            height={30}
                            width={120}
                            variant="rect"
                        />
                    </>
                ) : (
                    <>
                        {!isMobile && signUpMode == 1 ? (
                            <StepsIndicator
                                stepsCompleted={stepsCompleted}
                                isMobile={isMobile}
                            />
                        ) : (
                            <></>
                        )}
                        {renderStep()}
                        {!(clyModal && stepsCompleted == 1) ? (
                            <>
                                {!isMobile && (
                                    <>
                                        <h1></h1>
                                        <br />
                                    </>
                                )}
                                <div
                                    className={
                                        isMobile ? style.mobileBottom : ""
                                    }
                                >
                                    <Button
                                        text="Continue"
                                        disabled={
                                            stepWiseData[stepsCompleted + 1]
                                                ?.questions?.length !=
                                            userResponses.length
                                        }
                                        style={{
                                            background: "#004ed4",
                                            width: 180,
                                            fontWeight: 400,
                                            fontSize: 20,
                                            borderRadius: 8,
                                            height: 50,
                                        }}
                                        handler={() => {
                                            mixpanelTrack("Onboarding New", {
                                                action: "Continue",
                                                device_type: isMobile
                                                    ? "Mobile"
                                                    : "Laptop",
                                            });
                                            userResponses.forEach((res) =>
                                                saveUserResponse(
                                                    res.question_id,
                                                    res.answers,
                                                    res.step,
                                                ),
                                            );
                                        }}
                                        customClass={
                                            window.innerHeight < 600 && isMobile
                                                ? style.continueBtnSmall
                                                : style.continueBtn
                                        }
                                    ></Button>
                                    {isMobile && (
                                        <Button
                                            text="Skip"
                                            handler={() => {
                                                saveUserResponse(
                                                    stepWiseData[
                                                        stepsCompleted + 1
                                                    ]?.questions[0].id,
                                                    "",
                                                    stepsCompleted + 1,
                                                    true,
                                                );
                                                setHasSkipped(true);
                                                mixpanelTrack(
                                                    "Onboarding New",
                                                    { action: "Skip(Mobile)" },
                                                );
                                            }}
                                            style={{
                                                background: "transparent",
                                                color: "#ACB3BF",
                                                width: 180,
                                                fontWeight: 400,
                                                fontSize: 20,
                                                borderRadius: 8,
                                                height: 40,
                                                marginTop: 10,
                                                paddingTop: 0,
                                            }}
                                            customClass={
                                                window.innerHeight < 600 &&
                                                isMobile
                                                    ? style.continueBtnSmall
                                                    : style.continueBtn
                                            }
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

const Step1 = ({ setUserResponses, isMobile }) => {
    const name = useSelector((state) => state.auth.user_name);

    const [select, setSelect] = useState([]);

    useEffect(() => {
        if (select.length)
            setUserResponses([
                {
                    answers: select,
                    question_id: stepWiseData[1].questions[0].id,
                    step: 1,
                },
            ]);
        else {
            setUserResponses([]);
        }
    }, [select]);

    const handler = (option) => {
        if (option)
            setSelect((ps) => {
                const index = ps.findIndex((opt) => opt == option);
                if (index != -1) {
                    ps.splice(index, 1);
                    return [...ps];
                } else {
                    if (ps.length == 2) {
                        ps.shift();
                        ps.push(option);
                        return [...ps];
                    } else {
                        ps.push(option);
                        return [...ps];
                    }
                }
            });
    };
    console.log(select);
    return (
        <>
            <Question text={`${name}${stepWiseData[1].questions[0].text}`} />
            <big style={{ marginBlock: 20, color: "gray", display: "block" }}>
                Select up to 2 options
            </big>
            <Options2
                onClick={handler}
                options={stepWiseData[1].questions[0].options}
                selectedOptions={select}
                jumbleOptions={true}
                hasOther={true}
                isMobile={isMobile}
            />
        </>
    );
};

// const Step2 = ({ setUserResponses, setUserEnvironment, isMobile }) => {
//     const [select, setSelect] = useState([]);
//     const [select1, setSelect1] = useState([]);

//     useEffect(() => {
//         if (select.length && select1.length) {
//             setUserEnvironment(select1);
//             setUserResponses([
//                 {
//                     answers: select1,
//                     question_id: stepWiseData[2].questions[0].id,
//                     step: 2,
//                 },
//                 {
//                     answers: select,
//                     question_id: stepWiseData[2].questions[1].id,
//                     step: 2,
//                 },
//             ]);
//         } else {
//             setUserResponses([]);
//         }
//     }, [select, select1]);

//     const handler1 = (option) => {
//         if (option) setSelect1([option]);
//         else setSelect1([]);
//     };

//     const handler = (option) => {
//         if (option) setSelect([option]);
//         else setSelect([]);
//     };

//     return (
//         <>
//             <Question text={stepWiseData[2].questions[0].text} />
//             <Options1
//                 onClick={handler1}
//                 selectedOptions={select1}
//                 options={stepWiseData[2].questions[0].options}
//                 isMobile={isMobile}
//             />
//             <Question text={stepWiseData[2].questions[1].text} />
//             <Options2
//                 onClick={handler}
//                 selectedOptions={select}
//                 options={stepWiseData[2].questions[1].options}
//                 step={2}
//                 isMobile={isMobile}
//             />
//         </>
//     );
// };

// const Step3 = ({ setUserResponses, userEnvironment, isMobile }) => {
//     const [select, setSelect] = useState([]);
//     console.log(userEnvironment == userEnvironments.selfEmp);
//     useEffect(() => {
//         if (select.length)
//             setUserResponses([
//                 {
//                     answers: select,
//                     question_id: stepWiseData[3].questions[0].id,
//                     step: 3,
//                 },
//             ]);
//         else setUserResponses([]);
//     }, [select]);

//     const handler = (option) => {
//         if (option) setSelect([option]);
//         else setSelect([]);
//     };

//     console.log(select);

//     return (
//         <>
//             <Question text={stepWiseData[3].questions[0].text} />
//             <Options2
//                 options={
//                     stepWiseData[3].questions[0][
//                         userEnvironment == userEnvironments.selfEmp
//                             ? "options"
//                             : userEnvironment == userEnvironments.agency
//                             ? "options1"
//                             : "options2"
//                     ]
//                 }
//                 onClick={handler}
//                 selectedOptions={select}
//                 jumbleOptions={true}
//                 hasOther={true}
//                 isMobile={isMobile}
//             />
//         </>
//     );
// };
// const Step4 = ({ setUserResponses, isMobile }) => {
//     const [select, setSelect] = useState([]);

//     useEffect(() => {
//         if (select.length)
//             setUserResponses([
//                 {
//                     answers: select,
//                     question_id: stepWiseData[4].questions[0].id,
//                     step: 4,
//                 },
//             ]);
//         else setUserResponses([]);
//     }, [select]);

//     const handler = (option) => {
//         if (option) setSelect([option]);
//         else setSelect([]);
//     };

//     console.log(select);

//     return (
//         <>
//             <Question text={stepWiseData[4].questions[0].text} />
//             <Options2
//                 options={stepWiseData[4].questions[0]["options"]}
//                 onClick={handler}
//                 selectedOptions={select}
//                 jumbleOptions={true}
//                 hasOther={true}
//                 isMobile={isMobile}
//             />
//         </>
//     );
// };

const StepsIndicator = ({ stepsCompleted = 0, isMobile }) => {
    let totalSteps = isMobile ? 1 : TOTAL_STEPS;
    return (
        <div className={style.stepBarsContainer}>
            {new Array(totalSteps).fill("").map((_, i) => (
                <div
                    className={`${style.stepBar} ${
                        i <= stepsCompleted ? style.stepBarCompleted : ""
                    }`}
                ></div>
            ))}
        </div>
    );
};

// const Options1 = ({
//     options = [],
//     onClick = () => {},
//     selectedOptions = [],
//     isMobile,
// }) => {
//     return (
//         <>
//             <div className={style.options2Container}>
//                 {options.map((option) => (
//                     <div
//                         onClick={() => onClick(option.text)}
//                         className={`${style.optionCard} ${
//                             selectedOptions.includes(option.text)
//                                 ? style.optionSelected
//                                 : ""
//                         }`}
//                     >
//                         {!isMobile && <img src={option.src} alt="" />}
//                         {!isMobile && <br />}
//                         {option.text}
//                     </div>
//                 ))}
//             </div>
//         </>
//     );
// };

const Options2 = ({
    options = [],
    onClick = () => {},
    selectedOptions = [],
    jumbleOptions,
    hasOther = false,
    step = "",
    isMobile,
}) => {
    if (jumbleOptions) {
        options = useMemo(() => jumbleArray(options), [options]);
    }

    return (
        <>
            <div
                className={
                    step == 2 ? style.options3Container : style.optionsContainer
                }
            >
                {options.map((option) => {
                    let renderOption = option;

                    if (option.includes(":")) {
                        const [prefix, suffix] = option.split(":");
                        renderOption = (
                            <div>
                                <b>{prefix}:</b>
                                {suffix}
                            </div>
                        );
                    }

                    return (
                        <div
                            className={`${
                                window.innerHeight < 600 && isMobile
                                    ? style.optionSmall
                                    : style.option
                            } ${style.option2}  ${
                                selectedOptions.includes(option)
                                    ? style.optionSelected
                                    : ""
                            }`}
                            onClick={() => onClick(option)}
                        >
                            {renderOption}
                        </div>
                    );
                })}
                {hasOther ? (
                    <OtherOption
                        selectedOptions={selectedOptions}
                        onSelect={onClick}
                        isMobile={isMobile}
                    />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

const Question = ({ text = "" }) => {
    return <h1>{text}</h1>;
};

const OtherOption = ({ selectedOptions, onSelect = () => {}, isMobile }) => {
    const [option, setOption] = useState("");

    const [input, setInput] = useState(false);

    return (
        <div
            className={`${
                window.innerHeight < 600 && isMobile
                    ? style.optionSmall
                    : style.option
            } ${style.option2}  ${
                selectedOptions.includes(option) ? style.optionSelected : ""
            }`}
            onClick={() => setInput(true)}
        >
            {input ? (
                <input
                    maxLength={50}
                    autoFocus
                    onBlur={(e) => {
                        setOption(e.target.value);
                        selectedOptions.includes(option) && onSelect(option);
                        onSelect(e.target.value.trim());

                        setInput(false);
                    }}
                    defaultValue={option}
                    placeholder="Other"
                />
            ) : option ? (
                option
            ) : (
                "Other"
            )}
        </div>
    );
};

const SetupCallScreen = ({ successCallback = () => {} }) => {
    const { mixpanelTrack } = useMixpanelHook();
    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => {
            mixpanelTrack("Onboarding New", { action: "date time selected" });
            console.log("onDateAndTimeSelected");
        },
        onEventTypeViewed: () => {
            mixpanelTrack("Onboarding New", { action: "Calendar rendered" });
            console.log("onEventTypeViewed");
        },
        onEventScheduled: (e) => {
            mixpanelTrack("Call Schedule Success");
            mixpanelTrack("Onboarding New", { action: "Demo Scheduled" });
            successCallback({ isScheduled: true });
        },
    });

    return (
        <div>
            <h1 style={{ display: "flex" }}>
                Unlock SEO Success: Free 1-1 Strategy Call
            </h1>
            <InlineWidget
                iframeTitle="Unlock SEO Success: Free 1-1 Strategy Call"
                url="https://calendly.com/d/5gx-7jm-fw2/scalenut-demo?utm_source=product&utm_medium=onboardm1"
                styles={
                    document?.body?.offsetHeight > 900
                        ? scale8
                        : document?.body?.offsetHeight > 700
                        ? scale6
                        : scale5
                }
            />

            <div
                onClick={() => {
                    successCallback({ isScheduled: false });
                    mixpanelTrack("Call Schedule Skipped");
                    mixpanelTrack("Onboarding New", {
                        action: "Call Schedule Skipped",
                    });
                }}
                style={{
                    backgroundColor: "#014dc50f",
                    color: "var(--primary-blue)",
                    fontSize: 18,
                    fontWeight: 400,
                    cursor: "pointer",
                    marginTop: "20px",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    width: "fit-content",
                }}
            >
                Skip Call Scheduling →
            </div>
        </div>
    );
};

export default OnBoardingFlow;
