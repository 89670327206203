import React, { useContext, useEffect, useState } from "react";
import styles from "./ResearchGenerate.module.css";
import { VscClose } from "react-icons/vsc";
import TextArea from "../../../atoms/TextArea/TextArea";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import { GENERATE_CONTENT } from "../../../api.json";
import { CircularProgress } from "@material-ui/core";
import { FiCheckSquare } from "react-icons/fi";
import { RiBallPenFill } from "react-icons/ri";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import CopyText from "../CopyText";
import WriteToEditor from "../../../organisms/TextEditor/Toolbar/WriteToEditor";
import EditorContext from "../../TextEditor/EditorContext";
import ReportContext from "../ReportContext";
import SVGIcon from "../../../atoms/SVGIcon";
import VideoModal from "../../../Pages/NewDashboard/VideoModal";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

function formatAnswers(answers) {
    let str = "";
    answers.forEach((ans) => {
        str = str + `${ans.content}\n`;
    });
    return str;
}

const addToEditor = (ans) => {
    WriteToEditor({
        data: !document.quill.editor.getText(document.quill.editor.cursor, 1)
            ? ans
            : document.quill.editor.getText(document.quill.editor.cursor, 1) !=
              "\n"
            ? "\n" + ans
            : ans,
        type: "text",
        length: ans.length + 1,
        source: "user",
    });
};

function getInfo(cases) {
    if (cases.length < 2) {
        return "Please select minimum 2 Ideas";
    }
    if (cases.length == 5) {
        return "Maximum 5 Ideas can be selected";
    }
}

function Answer({
    ans,
    setErrMsg,
    writingState,
    selectedResults,
    setSelectedResults,
    setWritingState,
    report_id,
    aiWordLimitSetting,
    generateContent,
}) {
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const { isShared } = useContext(ReportContext);
    const { versionData } = useContext(EditorContext);

    useEffect(() => {
        setErrMsg(false);
        if (checked) {
            {
                setSelectedResults((ps) => {
                    return ps.concat([ans]);
                });
            }
        } else {
            setSelectedResults((ps) => {
                return ps.filter((item) => !(item == ans));
            });
        }
    }, [checked]);

    return (
        <div className={styles.bg}>
            <span
                className={styles.checkbox}
                onClick={() => {
                    if (selectedResults.length < 5) {
                        setChecked(!checked);
                    } else {
                        setChecked(false);
                        setErrMsg(true);
                        setTimeout(() => setErrMsg(false), 2000);
                    }
                }}
            >
                {checked ? (
                    <FiCheckSquare color="#5779e6" />
                ) : (
                    <div className={styles.emptyBoxNew} />
                )}
            </span>
            <LightTooltip
                placement="bottom"
                arrow
                title={
                    writingState.state
                        ? ""
                        : isCopied
                        ? "Added"
                        : "Click to add"
                }
            >
                <span
                    onMouseLeave={() => setIsCopied(false)}
                    onClick={() => {
                        if (!writingState.state) {
                            addToEditor(ans);
                            setIsCopied(true);
                        }
                    }}
                    className={styles.Travel_vacation_plan}
                >
                    {ans}
                </span>
            </LightTooltip>
            {loading ? (
                <CircularProgress className={styles.copyIcon} size={18} />
            ) : (
                <LightTooltip
                    title="Write a paragraph about this SERP idea"
                    placement="top"
                    arrow
                >
                    <span>
                        <span>
                            <img
                                src={"/AssetsV2/AI-icon@1.5x.svg"}
                                onClick={() => {
                                    // addToEditor(q.question);
                                    writingState?.state ||
                                    isShared ||
                                    !versionData.current
                                        ? () => {}
                                        : generateContent(ans, setLoading);
                                }}
                                className={styles.copyIcon}
                                style={
                                    writingState?.state ||
                                    isShared ||
                                    !versionData.current
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                }
                            />
                        </span>
                    </span>
                </LightTooltip>
            )}
        </div>
    );
}

export default function ResearchFacts({
    setFactsButton,
    reportInfo,

    aiWordLimitSetting,

    serp,
}) {
    const [description, setDescription] = useState("");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const { postData } = useHelperFunctions();
    const [selectedResults, setSelectedResults] = useState([]);
    const [errMsg, setErrMsg] = useState(false);
    const { mpTrackInfoIconClicked } = useMixpanelHook();
    const { setWritingState, writingState, writeWithAnimation } =
        useContext(EditorContext);

    function getPromt() {
        let str = "";
        selectedResults.forEach((result, index) => {
            if (index + 1 != selectedResults.length)
                str = str + `${index + 1}. ${result}\n`;
            else str = str + `${index + 1}. ${result}`;
        });
        return str;
    }

    async function generateContent(useCase, _setLoading = () => {}) {
        document?.quill?.editor?.enable(false);
        _setLoading(true);
        setWritingState({ state: true, text: "", useCase: -1 });
        try {
            const payload = {
                useCase: useCase ? 60 : 61,
                topic: description,
                input: useCase ? useCase : getPromt(),
                end: "",
                session: "",
                report_id: reportInfo.report_id,
            };

            payload.title = reportInfo.title;

            const res = await postData({
                url: GENERATE_CONTENT,
                payload: payload,
                optionalToken: true,
            });

            if (res.status == 200) {
                let answers = res.data.output;
                await writeWithAnimation(formatAnswers(answers));
            }
        } catch (error) {
            console.log(error);
        }
        document?.quill?.editor?.enable(true);

        setWritingState({ state: false, text: "", useCase: -1 });

        _setLoading(false);
    }

    async function getQues() {
        setLoading(true);
        setSelectedResults([]);
        const res = await postData({
            url: `/api/scrape/facts`,
            payload: { report_id: reportInfo.report_id, query: description },
        });
        setLoading(false);
        if (res.status == 200) {
            setResults(res.data.results.map((fact) => fact.content));
        }
    }

    return (
        <div
            className={` ${styles.researchFacts} ${serp ? "" : styles.hide}  `}
        >
            <div className={styles.heading}>
                <h2>
                    SERP Ideas&nbsp;
                    <LightTooltip
                        title={
                            <>
                                Discover reliable information using Scalenut
                                SERP ideas to enhance the authenticity,
                                engagement, and relevance of your content.
                                <br />
                                <br />
                                <strong>Example</strong> <br /> <br />
                                Add the exact topic for which you want to
                                generate SERP ideas. You want to create SERP
                                ideas for "Benefits of Healthy Lifestyle." type
                                it in the prompt box and click the "Find SERP
                                ideas" button.
                                <br />
                                <br />
                                <VideoModal
                                    link={`https://www.youtube.com/embed/cMb-eRoKfLk?si=QxEM-iJpyUlPM7h1?modestbranding=1&rel=0&loop=1`}
                                    zoomVal={1.2}
                                    modal={false}
                                    aspectWidth={380}
                                    aspectHeight={200}
                                    customClass={styles.videoTooltip}
                                    location="SERP Ideas"
                                />
                            </>
                        }
                        arrow
                        click
                        tooltipStyle={{
                            maxWidth: 440,
                            padding: "30px 30px 30px 30px !important",
                        }}
                    >
                        <div
                            style={{ height: 19 }}
                            onClick={() => {
                                mpTrackInfoIconClicked({
                                    location: "SERP Ideas",
                                });
                            }}
                        >
                            <SVGIcon
                                color="#2196f3"
                                size={18}
                                src={"/New UI/SVG/info.svg"}
                            />
                        </div>
                    </LightTooltip>
                </h2>
                <VscClose
                    onClick={() => setFactsButton(false)}
                    className={styles.closeIcon}
                />
            </div>

            <div className={styles.textAreaCont}>
                <TextArea
                    label="EXTRACT SERP IDEAS FOR:"
                    placeholder="Enter the exact topic for SERP Ideas…"
                    rowsMin="5"
                    maxLength="120"
                    props={{
                        value: description,
                        onChange: (e) => {
                            setDescription(e.target.value);
                        },
                        required: true,
                    }}
                    counter={true}
                    noItalics={true}
                />

                <LightTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    title={
                        "Extract information from the SERP data for the given input"
                    }
                    arrow
                    placement="bottom"
                >
                    <div>
                        <Button
                            text={"Find SERP Ideas"}
                            height="50px"
                            fontSize="14px"
                            handler={(e) =>
                                description.trim().length == 0 ||
                                writingState.state
                                    ? ""
                                    : getQues()
                            }
                            loading={loading}
                            style={
                                !(
                                    description.trim().length == 0 ||
                                    writingState.state
                                )
                                    ? {
                                          backgroundColor: "#014dc5",
                                      }
                                    : {
                                          backgroundColor: "#014dc5",
                                          filter: "opacity(0.5)",
                                          cursor: "default",
                                      }
                            }
                        />
                    </div>
                </LightTooltip>
            </div>

            <div
                style={{ marginBotton: 100 }}
                className={styles.answersContainer}
            >
                {results.map((ans) => (
                    <Answer
                        setErrMsg={setErrMsg}
                        key={ans}
                        selectedResults={selectedResults}
                        setSelectedResults={setSelectedResults}
                        report_id={reportInfo.report_id}
                        aiWordLimitSetting={aiWordLimitSetting}
                        writingState={writingState}
                        setWritingState={setWritingState}
                        generateContent={generateContent}
                        ans={ans}
                    />
                ))}
            </div>

            {results.length > 0 && (
                <div className={styles.btnContainer}>
                    {
                        <span
                            className={`${styles.infoMsg} ${
                                errMsg ? styles.infoMsg2 : ""
                            }`}
                        >
                            {getInfo(selectedResults)}
                        </span>
                    }
                    <LightTooltip
                        title={
                            "Write a paragraph about the selected SERP ideas"
                        }
                        placement="left"
                        arrow
                    >
                        <div>
                            <Button
                                text={"Write about this"}
                                height="50px"
                                fontSize="14px"
                                handler={(e) => {
                                    if (writingState.state) return;
                                    selectedResults.length >= 2 &&
                                    selectedResults.length <= 5
                                        ? generateContent()
                                        : (() => {
                                              setErrMsg(true);
                                              setTimeout(
                                                  () => setErrMsg(false),
                                                  2000,
                                              );
                                          })();
                                }}
                                style={
                                    writingState.state
                                        ? {
                                              backgroundColor:
                                                  "rgb(1, 77, 197,0.2)",
                                              border: "2px solid #014dc5",
                                              color: " #014dc5",
                                              filter: "opacity(0.5)",
                                              cursor: "default",
                                          }
                                        : {
                                              backgroundColor:
                                                  "rgb(1, 77, 197,0.2)",
                                              border: "2px solid #014dc5",
                                              color: " #014dc5",
                                          }
                                }
                                iconReverse={true}
                                Icon={RiBallPenFill}
                            />
                        </div>
                    </LightTooltip>
                </div>
            )}
        </div>
    );
}
