import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import {
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    makeStyles,
} from "@material-ui/core";
import useGscHook, { ColumnsNameObject, DomainObject } from "../useGscHook";
import SVGIcon from "../../../atoms/SVGIcon";
import { debounceWrapper } from "../../../../utility/helperFun";
import { VscClose } from "react-icons/vsc";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { PLANS_CATEGORIES } from "../../../../constants";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";
import { useMixpanelTA } from "../useMixpanelTA";
import { TabsName } from "../TrafficAnalyzer";

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        width: "12px",
        height: "12px",
        margin: "7px",
        color: "#b0b8c3",
    },
    label: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#3f5575",
    },
    input: {
        width: "100px !important",
    },
    disabled: {
        color: "#b0b8c3 !important",
    },
}));

const formLabelCustom = makeStyles((theme) => ({
    label: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#3f5575",
        fontFamily: "Inter",
    },
}));

type Props_InventoryTopOptions = {
    customClass: { readonly [key: string]: string };
    handleSearch: Dispatch<SetStateAction<string>>;
    filterOptions: string[];
    rankFilterOptions: string[];
    columnsList: ColumnsNameObject[];
    dataArr: any[];
    filterType: string;
    setFilterType: Dispatch<SetStateAction<string>>;
    rankFilterType: string;
    setRankFilterType: Dispatch<SetStateAction<string>>;
    handleColumnsList: Dispatch<SetStateAction<ColumnsNameObject[]>>;
    totalPages: number;
    availablePages: number;
    isShimmer: boolean;
    activeDomain: DomainObject | undefined;
};

const InventoryTopOptions = ({
    customClass,
    handleSearch,
    filterOptions,
    rankFilterOptions,
    columnsList,
    handleColumnsList,
    rankFilterType,
    setRankFilterType,
    dataArr,
    filterType,
    setFilterType,
    availablePages,
    totalPages,
    isShimmer,
    activeDomain,
}: Props_InventoryTopOptions) => {
    const { isLTD, plan_category } = useUserAccessHook();
    const [isDropdownColumns, setIsDropdownColumns] = useState<boolean>(false);
    const [isDropdownFilter, setIsDropdownFilter] = useState<boolean>(false);
    const [isDropdownRankFilter, setIsDropdownRankFilter] =
        useState<boolean>(false);
    const checkBoxClasses = checkBoxCustom();
    const FormLabelClasses = formLabelCustom();
    const otherInfo = useSelector((state: any) => state.otherInfo);
    const searchRef = useRef(null);
    const handleCheckedColumns: (index: number) => void = (index) => {
        handleColumnsList((ps) => {
            let temp = [...ps];
            temp[index].isChecked = !temp[index].isChecked;
            return [...temp];
        });
    };
    const { getDomainStatus } = useGscHook();
    const { taTrackFilters } = useMixpanelTA();
    useEffect(() => {
        console.log(totalPages);
    }, [totalPages]);
    useEffect(() => {
        handleSearch("");
    }, [isShimmer]);
    let timeoutRef = useRef(null);

    return (
        <div className={customClass.tableOptions}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <div className={customClass.tableOptions_left}>
                    <Textfield
                        // @ts-ignore
                        customClass={customClass.searchPageUrl}
                        ref={searchRef}
                        height={34}
                        label={""}
                        containerStyle={{
                            marginBottom: "0",
                        }}
                        props={{
                            onChange: (e: any) => {
                                const value = e.target.value.trim();
                                debounceWrapper(
                                    () => handleSearch(value),
                                    500,
                                    timeoutRef,
                                );
                            },
                            style: {
                                borderRadius: 4,
                                border: `solid 1px ${"rgba(75, 75, 85, 0.16)"}`,
                                backgroundColor: "#fff",
                                paddingLeft: "40px",
                            },
                        }}
                        placeholder={"Search URL"}
                        Icon={() => (
                            <SVGIcon src={"/New UI/SVG/searchIcon.svg"} />
                        )}
                        iconStyle={{
                            left: "10px",
                            right: "unset",
                        }}
                    />
                </div>
            </div>

            <div className={customClass.tableOptions_right}>
                {!!dataArr.length && filterType.toLowerCase() != "all pages" ? (
                    <div className={customClass.dataCount_found}>
                        {dataArr.length} {dataArr.length > 1 ? "pages" : "page"}{" "}
                        found
                    </div>
                ) : !isShimmer ? (
                    <LightTooltip
                        interactive
                        title={
                            availablePages == totalPages || isLTD ? (
                                ""
                            ) : (
                                <div className={customClass.upgradeWrapper}>
                                    {[
                                        PLANS_CATEGORIES.TRAIL,
                                        PLANS_CATEGORIES.INDIVIDUAL,
                                        PLANS_CATEGORIES.GROWTH,
                                        PLANS_CATEGORIES.GROWTH_MAX,
                                        PLANS_CATEGORIES.FREE_FOREVER,
                                    ].includes(
                                        otherInfo?.subscriptionInfo
                                            ?.plan_category,
                                    ) ? (
                                        <div
                                            className={
                                                customClass.upgradeWrapper_line1
                                            }
                                        >
                                            Showing top {availablePages} pages
                                            out of {totalPages} available pages,
                                            sorted by traffic in descending.{" "}
                                            <br />
                                            <span
                                                className={
                                                    customClass.upgradeCta
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(
                                                        "/admin/account/plans",
                                                        "_blank",
                                                    );
                                                }}
                                            >
                                                Upgrade
                                            </span>{" "}
                                            your plan to increase limits
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                customClass.upgradeWrapper_line2
                                            }
                                        >
                                            Reach out on chat to upgrade your
                                            limits
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        placement={"bottom"}
                        arrow
                    >
                        <div className={customClass.dataCount_total}>
                            Showing top {availablePages} out of {totalPages}{" "}
                            pages
                        </div>
                    </LightTooltip>
                ) : (
                    <Skeleton width={"200px"} />
                )}

                {totalPages != availablePages &&
                    !isLTD &&
                    !isShimmer &&
                    getDomainStatus(
                        plan_category == PLANS_CATEGORIES.PRO ||
                            plan_category == PLANS_CATEGORIES.PRO_MAX ||
                            isLTD
                            ? true
                            : false,
                        activeDomain?.status,
                        17,
                    )}
                {/* Page Filter type -> lose, new ,all, gainers */}
                {filterOptions.length > 0 && (
                    <ClickAwayListener
                        onClickAway={() => setIsDropdownFilter(false)}
                    >
                        <div
                            className={customClass.tableColumnCheckListWrapper}
                        >
                            <div
                                className={`${customClass.pageFilterWrapper} ${
                                    filterType != filterOptions[0] &&
                                    customClass.acttive
                                }`}
                            >
                                <div
                                    className={
                                        customClass.dropdownCta +
                                        " " +
                                        (filterType.toLowerCase() !=
                                            "all pages" &&
                                            customClass.activeFilter)
                                    }
                                    onClick={() =>
                                        setIsDropdownFilter(!isDropdownFilter)
                                    }
                                >
                                    {filterType}

                                    <div className={customClass.filterIcon}>
                                        {filterType == filterOptions[0] && (
                                            <SVGIcon
                                                src={`/New UI/SVG/${
                                                    isDropdownFilter
                                                        ? "arrow-head-up"
                                                        : "arrow-head-down"
                                                }.svg`}
                                                size={
                                                    isDropdownFilter ? 11 : 11
                                                }
                                                style={{ color: "#3F5575" }}
                                            />
                                        )}
                                    </div>
                                </div>
                                {filterType != filterOptions[0] && (
                                    <div
                                        className={
                                            customClass.activeFilterClearCta
                                        }
                                        onClick={() => {
                                            taTrackFilters({
                                                tabName: TabsName.inventory,
                                                filterType: "Pages",
                                                cta: "Reset",
                                            });
                                            setFilterType(filterOptions[0]);
                                            setIsDropdownFilter(false);
                                        }}
                                    >
                                        <VscClose
                                            size={18}
                                            style={{ color: "#000000" }}
                                        />
                                    </div>
                                )}
                            </div>
                            {isDropdownFilter && (
                                <div
                                    className={
                                        customClass.tableColumnsCheckList
                                    }
                                >
                                    {filterOptions.map((filterOpt, index) => {
                                        return (
                                            <div
                                                className={customClass.option}
                                                onClick={() => {
                                                    taTrackFilters({
                                                        tabName:
                                                            TabsName.inventory,
                                                        filterType: "Pages",
                                                        cta: "Apply",
                                                    });
                                                    setFilterType(filterOpt);
                                                    setIsDropdownFilter(false);
                                                }}
                                            >
                                                {filterOpt}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </ClickAwayListener>
                )}

                {/* Page Filter type -> Rank */}
                {rankFilterOptions.length > 0 && (
                    <ClickAwayListener
                        onClickAway={() => setIsDropdownRankFilter(false)}
                    >
                        <div
                            className={customClass.tableColumnCheckListWrapper}
                        >
                            <div
                                className={`${customClass.pageFilterWrapper} ${
                                    rankFilterType != rankFilterOptions[0] &&
                                    customClass.acttive
                                }`}
                            >
                                <div
                                    className={
                                        customClass.dropdownCta +
                                        " " +
                                        (rankFilterType.toLowerCase() !=
                                            "all" && customClass.activeFilter)
                                    }
                                    onClick={() =>
                                        setIsDropdownRankFilter(
                                            !isDropdownRankFilter,
                                        )
                                    }
                                >
                                    Position: {rankFilterType}
                                    <div className={customClass.filterIcon}>
                                        {rankFilterType ==
                                            rankFilterOptions[0] && (
                                            <SVGIcon
                                                src={`/New UI/SVG/${
                                                    isDropdownRankFilter
                                                        ? "arrow-head-up"
                                                        : "arrow-head-down"
                                                }.svg`}
                                                size={
                                                    isDropdownRankFilter
                                                        ? 11
                                                        : 11
                                                }
                                                style={{ color: "#3F5575" }}
                                            />
                                        )}
                                    </div>
                                </div>
                                {rankFilterType != rankFilterOptions[0] && (
                                    <div
                                        className={
                                            customClass.activeFilterClearCta
                                        }
                                        onClick={() => {
                                            taTrackFilters({
                                                tabName: TabsName.inventory,
                                                filterType: "Position",
                                                cta: "Reset",
                                            });
                                            setRankFilterType(
                                                rankFilterOptions[0],
                                            );
                                            setIsDropdownRankFilter(false);
                                        }}
                                    >
                                        <VscClose
                                            size={18}
                                            style={{ color: "#000000" }}
                                        />
                                    </div>
                                )}
                            </div>
                            {isDropdownRankFilter && (
                                <div
                                    className={
                                        customClass.tableColumnsCheckList
                                    }
                                >
                                    {rankFilterOptions.map(
                                        (filterOpt, index) => {
                                            return (
                                                <div
                                                    className={
                                                        customClass.option
                                                    }
                                                    onClick={() => {
                                                        taTrackFilters({
                                                            tabName:
                                                                TabsName.inventory,
                                                            filterType:
                                                                "Position",
                                                            cta: "Apply",
                                                        });
                                                        setRankFilterType(
                                                            filterOpt,
                                                        );
                                                        setIsDropdownRankFilter(
                                                            false,
                                                        );
                                                    }}
                                                >
                                                    {filterOpt}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            )}
                        </div>
                    </ClickAwayListener>
                )}
                <ClickAwayListener
                    onClickAway={() => setIsDropdownColumns(false)}
                >
                    <div className={customClass.tableColumnCheckListWrapper}>
                        <div
                            className={customClass.dropdownCta}
                            onClick={() =>
                                setIsDropdownColumns(!isDropdownColumns)
                            }
                        >
                            Display Columns{" "}
                            <div className={customClass.filterIconWrapper}>
                                <SVGIcon
                                    src={`/New UI/SVG/${
                                        isDropdownColumns
                                            ? "arrow-head-up"
                                            : "arrow-head-down"
                                    }.svg`}
                                    size={isDropdownColumns ? 11 : 11}
                                    style={{ color: "#3F5575" }}
                                />
                            </div>
                        </div>
                        {isDropdownColumns && (
                            <div className={customClass.tableColumnsCheckList}>
                                {columnsList.map((col, index) => {
                                    if (col.name.length > 0) {
                                        // if (
                                        //     !totalDeltaValues.isTrendsShow &&
                                        //     col.name.toLowerCase() ==
                                        //         "rank trend"
                                        // ) {
                                        // } else
                                        return (
                                            <div
                                                className={
                                                    customClass.checkOptions
                                                }
                                            >
                                                <FormControlLabel
                                                    style={{
                                                        color: true
                                                            ? "#2a4166"
                                                            : "#b0b8c3",
                                                        marginBottom: "-4px",
                                                    }}
                                                    classes={FormLabelClasses}
                                                    control={
                                                        <Checkbox
                                                            color={"default"}
                                                            disabled={
                                                                col.isDisabled
                                                            }
                                                            checked={
                                                                col.isChecked
                                                            }
                                                            classes={
                                                                checkBoxClasses
                                                            }
                                                            style={{
                                                                transform:
                                                                    "scale(.7)",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    "13px",
                                                                color: "#3f5575",
                                                            }}
                                                            value={col.name}
                                                            onChange={(e) => {
                                                                taTrackFilters({
                                                                    tabName:
                                                                        TabsName.inventory,
                                                                    filterType:
                                                                        "Display Columns",
                                                                });
                                                                handleCheckedColumns(
                                                                    index,
                                                                );
                                                            }}
                                                            name="checkedB"
                                                        />
                                                    }
                                                    label={col.name}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default InventoryTopOptions;
