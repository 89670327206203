import { UPDATE_OTHER_INFO, UPDATE_USER_SETTINGS } from "../actiontypes";

export const updateOtherInfo = (data) => {
    return {
        type: UPDATE_OTHER_INFO,
        data,
    };
};

export const updateUserSettings = (data) => {
    return {
        type: UPDATE_USER_SETTINGS,
        data,
    };
};
