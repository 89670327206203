import React, { useState } from "react";
import styles from "./styles.module.css";

export default function ToggleButton({
    label,
    on = false,
    setOn = () => {},
    bg,
    btnStyle,
    labelStyle = {},
    id = "",
}) {
    // const [on, setOn] = useState(on);

    return (
        <div className={styles.container2} id={id}>
            <label className={styles.label2} style={{ ...labelStyle }}>
                {label}
            </label>
            <div
                onClick={() => setOn(!on)}
                className={
                    styles.switch2 +
                    " " +
                    (on ? styles.switchOn2 : styles.switchOff2)
                }
                style={
                    bg ? { zoom: bg.zoom, background: on ? bg.color : "" } : {}
                }
            >
                <span
                    className={on ? styles.button2On : styles.button2Off}
                    style={btnStyle ? { background: btnStyle } : {}}
                ></span>
            </div>
        </div>
    );
}
