import React from "react";
import styles from "./styles.module.css";
import Image from "../Image/Image";
function getClass(type) {
  if (type == "profile") {
    return styles.profile;
  } else if (type == "icon") {
    return styles.icon;
  } else {
    return styles.profile;
  }
}
function getInitials(userName) {
  let initials = "";
  if (userName) {
    initials = userName
      .split(" ")
      .map((x) => {
        if (x !== "") return x[0];
        return;
      })
      .join("");
  }
  return initials.substring(0, 2);
}

function CircleImage({ src, type, name }) {
  return (
    <div className={styles.container + " " + getClass(type)}>
      {src ? <Image src={src} alt="profile pic" /> : getInitials(name)}
    </div>
  );
}

export default CircleImage;
