import {
    SET_OVERLAY_VIDEO,
    SET_USER_VIDEO,
    UPDATE_USER_VIDEO,
} from "../actiontypes";

const initialState = {
    template: {
        link: "https://www.youtube.com/embed/6VOfdDqQ8jI",
        status: 1,
    },
    seo: {
        link: "https://www.youtube.com/embed/krfPAlmZsTo",
        status: 1,
    },
    cluster: {
        link: "https://www.youtube.com/embed/tqPZIOwWOzw",
        status: 1,
    },
    docs: {
        link: "https://www.youtube.com/embed/U6Ippd8IIe0",
        status: 1,
    },
    trafficAnalyser: {
        link: "https://www.youtube.com/embed/ef2jWNyx6K0",
        status: 1,
    },
};

export default function (state = initialState, action) {
    const data = action.data;
    switch (action.type) {
        case UPDATE_USER_VIDEO:
            return {
                ...state,
                [data]: { ...(state[data] || {}), status: 0 },
            };

        case SET_USER_VIDEO:
            return { ...state, ...data };

        case SET_OVERLAY_VIDEO:
            return {
                ...state,
                overlay: data,
            };

        default:
            return state;
    }
}
