import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { OnBoardingContext } from "../componentsV2/Context/OnBoardingContext/OnBoardingContextProvider";
import { url } from "../utility/config";
import { socketEvents } from "./constants";
export const socket = socketIOClient(url, {
    transports: ["websocket"],
    autoConnect: false,
});

function useSockets(isLoggedIn) {
    const userId = useSelector((state) => state.auth.userId);
    const onBoarding = useContext(OnBoardingContext);

    useEffect(() => {
        if (isLoggedIn) {
            socket.connect();

            socket.onAny((event, ...data) => {
                console.log(
                    "::::::::::::::::::SOCKET::::::::::::::",
                    event,
                    data,
                    "INCOMING",
                );
            });

            socket.onAnyOutgoing((event, ...data) => {
                console.log(
                    "::::::::::::::::::SOCKET::::::::::::::",
                    event,
                    data,
                    "OUTGOING",
                );
            });

            socket.on("connect", () => {
                socket.emit(socketEvents.USER_JOIN_ROOM, { user_id: userId });
                console.log(socket, "socket connect");
            });

            socket.on(socketEvents.REPORT_CREATED, (data) => {
                if (data?.achievement_unlocked)
                    onBoarding.setState((ps) => ({
                        ...ps,
                        currentStepCompleted: data?.achievement_unlocked,
                    }));
            });

            return () => {
                console.log("disconnect");
                socket.removeAllListeners();
                socket.offAnyOutgoing();
                socket.offAny();
                socket.disconnect();
            };
        }
    }, [isLoggedIn]);

    return {
        socket,
    };
}

export default useSockets;
