import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { BsArrowDown, BsChevronDown } from "react-icons/bs";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import SVGIcon from "../../atoms/SVGIcon";
import styles from "./styles.module.css";
export const TYPES = {
    SORT: "SORT",
};

function NewUIDropDown({
    options = [{ title: "", id: "" }],
    type = "",
    handler,
    selectedOption,
    setOrder = () => {},
    style = {},
    order = undefined,
    label = undefined,
}) {
    const [open, setOpen] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setOpen(false));
    return (
        <div
            ref={ref}
            style={{ minWidth: order != undefined ? 250 : "unset", ...style }}
            className={styles.container}
        >
            <div
                onClick={() => setOpen(!open)}
                style={order != undefined ? {} : { borderRight: "none" }}
                className={styles.container}
            >
                {open && options.length > 1 && (
                    <div className={styles.optionsContainer}>
                        {options.map((option) => {
                            return (
                                <div
                                    onClick={() => handler(option)}
                                    className={styles.option}
                                >
                                    {option.title}
                                </div>
                            );
                        })}
                    </div>
                )}
                <span style={{ color: "gray", fontWeight: 500 }}>
                    {label != undefined ? label : "Sort by:"}{" "}
                </span>
                <span style={{ margin: "0 5px" }}>{selectedOption.title}</span>
                <span style={{ paddingTop: 5 }}>
                    {options.length > 1 && (
                        <SVGIcon
                            size={14}
                            src={"/New UI/SVG/chevronDown.svg"}
                        />
                    )}
                </span>
            </div>

            {order != undefined && (
                <div
                    style={{
                        width: 36,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {order == 1 ? (
                        <SVGIcon
                            onClick={() => setOrder(0)}
                            size={20}
                            src={"/New UI/SVG/sortA.svg"}
                        />
                    ) : (
                        <SVGIcon
                            onClick={() => setOrder(1)}
                            size={20}
                            src={"/New UI/SVG/sortD.svg"}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default NewUIDropDown;
