import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import StepCard from "./StepCard";
import styles from "./stepsWizard.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import Ratings from "../../../molecules/Creator/Overview/overallRatings";
import useHelperFunctions from "../../../helperFunctions";
import Preview from "./Preview/PreviewView";
import ReportContext from "../../Research/ReportContext";
import RatingHook from "../../../molecules/Creator/Overview/RatingHook";
import LoaderGoNuts from "./LoaderGoNuts";

import useNavigation from "../../../atoms/GoToRoute/useNavigation";

import Modal from "../../../atoms/Modal/Modal";
import Button from "../../../atoms/Button/Button";
import TextShortner from "../../../atoms/TextShortner/TextShortner";
import { socketEvents } from "../../../../Sockets/constants";
import { socket } from "../../../../Sockets/useSockets";
import Morekeywords from "../../../atoms/MoreKeywords/Morekeywords";
import { VscClose } from "react-icons/vsc";
import { AiFillPlayCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import {
    CustomDeltaToHtml,
    CustomDeltaToText,
    isMobile,
} from "../../../../utility/helperFun";
import { CRUISE_TYPES, initialStepsData, useCruiseHook } from "./useCruiseHook";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import moment from "moment";
import Quill from "quill";
const Delta = Quill.import("delta");
import {
    HtmlTagToDelta,
    markdownToDelta,
} from "../../TextEditor/Toolbar/WriteToEditor";
import { ArrowRightSharp, ChevronRight } from "@material-ui/icons";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { debounce } from "@material-ui/core";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import CruiseSkeleton from "./CruiseSkeleton";
import { useSelector } from "react-redux";
import { checkParas } from "./cruiseConstant";
const style = document.createElement("link");
style.rel = "stylesheet";
style.href = "//cdn.quilljs.com/1.3.6/quill.bubble.css";

const dispatchStepsDataDefault = (
    action = {
        type: "",
        data: {},
        isWp: false,
        index: 0,
        parent_id: "",
        id: "",
        field: "",
    },
) => {};

export const CruiseContext = React.createContext(dispatchStepsDataDefault);

export const CruiseState = React.createContext({
    ...initialStepsData,
});

function makeCruiseToDelta(stepsData, is_premium) {
    let data = new Delta();

    function addNewLine(_data) {
        try {
            if (!_data?.ops[data?.ops.length - 1]?.insert.endsWith("\n")) {
                console.log(_data?.ops[data?.ops.length], "ooooooooooooo");
                data = _data.concat(new Delta([{ insert: "\n" }]));
            }
        } catch (error) {
            console.log(error);
        }
    }

    data = data.concat(
        new Delta(HtmlTagToDelta(stepsData.title ? stepsData.title : "", "h1")),
    );
    if (is_premium) {
        if (stepsData.featured_image?.url) {
            data = data.concat(
                new Delta(
                    HtmlTagToDelta(
                        "",
                        "img",
                        {},
                        stepsData.featured_image?.altText,
                        stepsData.featured_image?.url,
                    ),
                ),
            );
        }

        data = data.concat(
            new Delta(HtmlTagToDelta("Key Highlights", "h2", {}, "", "")),
        );
    }

    data = data.concat(markdownToDelta(stepsData.introduction));
    addNewLine(data);

    if (stepsData?.outlines?.headings?.length > 0) {
        stepsData.outlines.headings.forEach((edx) => {
            if (edx.data) {
                data = data.concat(
                    new Delta(HtmlTagToDelta(edx.data, edx.tag)),
                );

                if (edx.para) data = data.concat(markdownToDelta(edx.para));
                addNewLine(data);

                edx.outlineH3s.forEach((edx) => {
                    if (edx.data) {
                        data = data.concat(
                            new Delta(HtmlTagToDelta(edx.data, edx.tag)),
                        );

                        if (edx.para)
                            data = data.concat(markdownToDelta(edx.para));
                        addNewLine(data);
                    }
                });
            }
        });
    }
    if (stepsData?.outlines?.questions?.length > 0) {
        data = data.concat(
            new Delta(HtmlTagToDelta("Frequently Asked Questions", "h2")),
        );

        stepsData.outlines.questions.forEach((edx) => {
            if (edx.data) {
                data = data.concat(new Delta(HtmlTagToDelta(edx.data, "h3")));
                if (edx.para) data = data.concat(markdownToDelta(edx.para));
                addNewLine(data);
            }
        });
    }
    if (!is_premium) {
        data = data.concat(new Delta(HtmlTagToDelta("Conclusion", "h2")));
        data = data.concat(markdownToDelta(stepsData.conclusion));
    }

    return data;
}

let args = {};
let completed = 0;
let string = "";

export const ConclusionCtaTypes = [
    {
        label: "Get a free trial/demo/consultation",
        tooltipText:
            "Direct the reader to get a free trial of your product/service.",
    },
    {
        label: "Subscribe",
        tooltipText:
            "Direct your reader to subscribe to your newsletter, blog, podcast, etc.",
    },
    {
        label: "Sign up ",
        tooltipText:
            "Direct your reader to sign up for an event, webinar, upcoming product, etc.",
    },
    {
        label: "Download now",
        tooltipText:
            "Direct your reader to download a resource - ebook, pdf, templates, brochure, etc.",
    },
    {
        label: "Get quotes (Commercials)",
        tooltipText: "Direct your reader to your pricing page/brochure.",
    },
    {
        label: "Redirect to another blog",
        tooltipText: "Engage your reader by directing them to other blogs.",
    },
    {
        label: "Comment below",
        tooltipText:
            "Increase your audience engagement by nudging them to comment.",
    },
    {
        label: "Share on social media",
        tooltipText:
            "Nudge the reader to share your blog on their social media",
    },
    {
        label: "Get in touch",
        tooltipText: "Nudge the reader to connect with you",
    },
    {
        label: "No CTA",
        tooltipText: "",
    },
];

export const NextStepBtnTexts = [
    "Create Title",
    "Create Outline",
    "Generate Writing Points",
    "Generate Content",
    "Preview",
];

let ctaUsedToExitTracked = false;
const StepsWizard = ({
    newlyCreatedReport,
    wordCountRange,
    handleModal,
    reportInfo,
    setGoNutsModal,
    seoWithCruise,
    setSeoWithCruise = () => {},
    setWait10,
    wait10,
    loadingCruise,
    contentModal,
    setContentModal,
    goNutsModalBtn = false,
    tempGuideLineData = {},
    isPremium = false,
    setReportData = () => {},
}) => {
    const { postData } = useHelperFunctions();
    const [realtimeRating, setRealtimeRatings] = useState({
        readability: "-",
        quality: 0,
        wordCount: 0,
        wordRange: "0 - 0",
        isUp: true,
        suggestedQuality: 0,
        suggestedReadability: 60,
    });
    const {
        checkEmptyWritingPoint,
        emptyParaAlert,
        dispatchStepsData,
        stepsData,
        topRankedTitles,
        topRankedOutlines,
        aiGenerated,
        setAiGenerated,
        isGenTitleLoaded,
        setIsGenTitleLoaded,
        activeStep,
        setActiveStep,
        setTitleSessionId,
        titleSessionId,

        maxStep,
        generateOutlines,
    } = useCruiseHook({
        tempGuideLineData,
        newlyCreatedReport,
        reportInfo,
        setRealtimeRatings,
    });

    const auth = useSelector((state) => state.auth);

    let lastStep = 4;

    const { isMobile } = useSelector((state) => state.state);
    const continueFunc = useRef(null);
    const [isStepNext, setIsStepNext] = useState([
        { status: false },
        { status: false },
        { status: false },
        { status: false },
        { status: false },
        { status: false },
        { status: false },
    ]);
    const [freqAskQuestion, setFreqAskQuestion] = useState([]);
    const [videoFrame, setVideoFrame] = useState(false);
    const [addFAQ, setAddFAQ] = useState([]);
    const [isPreview, setIsPreview] = useState(false);
    const [additional_keywords, setAdditionalKeywords] = useState([]);
    // const [isLoader, setIsLoader] = useState(!seoWithCruise);
    const [genH2List, setGenH2List] = useState([]);
    const [genFaqList, setGenFaqList] = useState([]);
    const [article, setArticle] = useState("");

    const goTo = useNavigation();
    const [doubleCheckModal, setDoubleCheckModal] = useState(false);
    const [talkingPointAlert, setTalkingPointAlert] = useState(false);
    const [allowFirstDraft, setAllowFirstDraft] = useState(true);

    const [wasReportLoading, setWasReportLoading] = useState(false);
    const [mainTime, setMainTime] = useState();
    const [stepsTime, setStepsTime] = useState();
    const [conclusionCta, setConclusionCta] = useState({});
    const [exportModal, setExportModal] = useState(false);
    const {
        mpTrackCruiseExit,
        mpTrackCruiseWatchDemo,
        mpTrackCruiseNext,
        mpTrackCruisePrevious,
        mpTrackCruiseEntry,
        mpTrackCruiseEntryInProgress,
        mpTrackCruiseExport,
        mpTrackCruiseInitialScreenLoad,
        mpTrackSkipToEditor,
    } = useMixpanelHook();

    const [open, setOpen] = useState(-1);

    const [errList, setErrList] = useState([
        { step: 0, msg: "" },
        { step: 1, msg: "" },
        { step: 2, msg: "" },
    ]);

    const ctaUsedToExit = (ctaUsed = "Navigation") => {
        ctaUsedToExitTracked = true;
        mpTrackCruiseExit({
            reportInfo,
            stepsData,
            activeStep,
            stepName: StepsList[activeStep]?.id,
            realtimeRating,
            maxStep,
            maxTime: moment().diff(mainTime, "seconds"),
            path: goNutsModalBtn
                ? "SEO_EDITOR"
                : history.location?.state?.from?.includes("dashboard")
                ? "DASHBOARD"
                : "CREATOR",
            ctaUsed,
        });
    };

    const {
        getSeoScore,
        keyTerms = [],
        overview,
        competetion,
        setMetaData,
        satisfactionRating,
    } = useContext(ReportContext);

    // useEffect(() => {

    // }, [topRankOutlines]); //added for outline to show

    const exportToEditor = (replace = false) => {
        if (replace) document?.quill?.editor?.setContents("");
        setMetaData({ ...stepsData.meta, fromAPI: false });
        pasteContentToEditor();
        mpTrackCruiseExport(moment().diff(stepsTime, "seconds"));
        setGoNutsModal(false);
        document.trackSeoScoreOnCruiseExport = true;
        ctaUsedToExit("Export");
        if (!satisfactionRating >= 0 && !stepsData.hasSatisfactionScore) {
            setReportData((ps) => ({ ...ps, showRatingComponent: true }));
        }
    };

    const StepsField = [
        "blog_context",
        "title",
        "outlines",
        "talking_points",
        "paragraph",
        "preview",
    ];

    // const {
    //   // writeWithAnimation,
    // } = useEditorMethods({
    //   reportInfo,
    //   // setConnectorCases,
    //   // keyTerms,
    //   // setCurrVersionData,
    //   // guestId,
    //   // isCurrVersion,
    //   // researchWriting,
    //   // setResearchWriting,
    //   // isSharedReport,
    //   // access,
    // });

    //keep track of Time
    useEffect(() => {
        ctaUsedToExitTracked = false;
        setMainTime(moment.now());
        return () => !ctaUsedToExitTracked && ctaUsedToExit();
    }, []);

    useEffect(() => {
        if (aiGenerated.length && activeStep == 0) {
            if (!stepsData.isLoader) setAiGenerated([]);
        }
    }, [stepsData?.blog_context]);

    useEffect(() => {
        if (aiGenerated.length && activeStep == 0) {
            if (!stepsData.isLoader) setAiGenerated([]);
        }
    }, [stepsData?.active_template_id]);

    //empty headings and question when title changes
    useEffect(() => {
        if (genH2List.length) setGenH2List([]);
        if (genFaqList.length) setGenFaqList([]);
    }, [stepsData?.title]);

    //alert modal useEffect
    useEffect(() => {
        if (!stepsData.isLoader && activeStep == 3 && wait10) {
            setAllowFirstDraft(false);
            setTimeout(() => {
                setWait10(false);
                setAllowFirstDraft(true);
            }, 10000);
        }
        if (activeStep != 3) {
            setAllowFirstDraft(true);
        }

        if (activeStep == 5) {
            // setIsPreview(true)
            setActiveStep(4);
        } else {
            // setIsPreview(false);
        }
        setStepsTime(moment.now());
    }, [activeStep, stepsData.isLoader]);

    const _getSeoScore = useCallback(
        debounce((stepsData) => {
            (async () => {
                if (getSeoScore) {
                    const seoScore = await getSeoScore({
                        isMeta: true,
                        meta_data: stepsData.meta,
                        stringData: CustomDeltaToText(
                            makeCruiseToDelta(
                                stepsData,
                                auth.segment == 3 ||
                                    auth.segment == 4 ||
                                    auth.segment == 5 ||
                                    auth.segment == 6
                                    ? true
                                    : isPremium,
                            ),
                        ),
                        html: CustomDeltaToHtml(
                            makeCruiseToDelta(
                                stepsData,
                                auth.segment == 3 ||
                                    auth.segment == 4 ||
                                    auth.segment == 5 ||
                                    auth.segment == 6
                                    ? true
                                    : isPremium,
                            ),
                        ),
                    });

                    setRealtimeRatings((ps) => {
                        return {
                            ...ps,
                            seoScore,
                        };
                    });
                }
            })();
        }, 1000),
        [getSeoScore],
    );

    useEffect(() => {
        (async () => {
            string = CustomDeltaToText(
                makeCruiseToDelta(
                    stepsData,
                    auth.segment == 3 ||
                        auth.segment == 4 ||
                        auth.segment == 5 ||
                        auth.segment == 6
                        ? true
                        : isPremium,
                ),
            );
            if (overview) {
                const rating = await RatingHook(string, keyTerms, "cruise");
                setArticle(string);
                if (overview.overviewRange.firstRange) {
                    const words =
                        Math.round(
                            overview.overviewRange.firstRange.Word_count / 100,
                        ) * 100;
                    const avgQuality =
                        overview.overviewRange.firstRange.Max_grade;
                    const keyword_count =
                        overview.overviewRange.firstRange.Max_keyword_count;
                    const suggestedReadability =
                        overview.overviewRange.firstRange.Readability;
                    const avgSEOScore =
                        overview.overviewRange.firstRange?.Max_score > 95
                            ? overview.overviewRange.firstRange?.Max_score
                            : Math.ceil(
                                  overview.overviewRange.firstRange?.Max_score /
                                      5,
                              ) * 5;

                    if (activeStep == lastStep && !stepsData.isLoader)
                        _getSeoScore(stepsData);

                    setRealtimeRatings((ps) => {
                        return {
                            ...ps,
                            wordRange: wordCountRange,
                            suggestedQuality: avgQuality,
                            suggestedReadability: suggestedReadability,
                            suggestedKeywordCount: keyword_count,
                            readability: rating.readability,
                            wordCount: rating.wordCount,
                            quality: rating.score,
                            fromApi: 0,
                            suggestedSEOScore: avgSEOScore,
                        };
                    });
                }
            }
        })();
    }, [overview, stepsData]);

    const [draftContent, setDraftContent] = useState([]);

    useEffect(() => {
        document.head.appendChild(style);

        return () => {
            style.remove();
            handleContinue(args, true, 1);
        };
    }, []);

    const handleTooltipClose = () => {
        setOpen(-1);
    };

    const handleTooltipOpen = (num) => {
        setTimeout(() => {
            setOpen(num);
        }, 100);
    };

    const [StepsList, setStepsList] = useState([
        {
            name: <span className={styles.item}>Context</span>,
            status: false,
        },
        { name: <span className={styles.item}>Title</span>, status: false },
        { name: <span className={styles.item}>Outline</span>, status: false },
        {
            name: <span className={styles.item}>Writing Points</span>,
            status: false,
        },
        {
            name: <span className={styles.item}>First Draft</span>,
            status: false,
        },
    ]);

    useEffect(() => {
        args = {
            StepsField,
            activeStep,
            stepsData,
            addFAQ,
            additional_keywords,
            aiGenerated,

            completed,
            StepsList,
            realtimeRating,
            reportInfo,
            maxStep,
            mainTime,
            conclusionCta,
        };
    }, [
        StepsField,
        activeStep,
        stepsData,
        addFAQ,
        additional_keywords,
        aiGenerated,

        StepsList,
        realtimeRating,
        reportInfo,
        maxStep,
        mainTime,
        conclusionCta,
    ]);

    const premiumUserSegment =
        auth.segment === 3 ||
        auth.segment == 4 ||
        auth.segment == 5 ||
        auth.segment == 6 ||
        reportInfo.is_premium;

    function getScreenName(urlPathname) {
        if (urlPathname) {
            if (urlPathname.includes("/admin/dashboard")) {
                return "Dashboard";
            } else if (urlPathname.includes("/admin/creator")) {
                return "Article Writer";
            } else if (urlPathname.includes("/admin/cluster")) {
                return "Keyword Planner";
            } else {
                return "-";
            }
        } else {
            return "-";
        }
    }

    useEffect(() => {
        if (reportInfo.reportLoading) {
            setWasReportLoading(true);
            mpTrackCruiseEntryInProgress("Dashboard", reportInfo);
        } else {
            try {
                const history =
                    JSON.parse(localStorage.getItem("history")) || [];
                if (reportInfo.activity_status < 2) {
                    mpTrackCruiseEntry(
                        getScreenName(history[history.length - 2]),
                        reportInfo,
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [reportInfo?.reportLoading]);

    function enablePreview() {
        setIsPreview(true);
    }

    const pasteContentToEditor = () => {
        let data = makeCruiseToDelta(
            stepsData,
            auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6
                ? true
                : isPremium,
        );
        if (document.quill.editor.isEnabled())
            if (document.quill.editor.getContents().ops[0].insert == "\n") {
                document.quill.editor.setContents(new Delta(data));
            } else {
                document.quill.editor.setContents(
                    document.quill.editor.getContents().concat(new Delta(data)),
                );
            }
    };

    useEffect(() => {
        validateSteps();
        resetSteps();
    }, [activeStep, stepsData]);

    useEffect(() => {
        dispatchStepsData({
            type: CRUISE_TYPES.SET_CRUISE_RETURN,
            state: false,
        });
    }, [activeStep]);

    function resetSteps() {
        let temp = [...StepsList];
        temp.forEach((item, i) => {
            if (i < activeStep) {
                item.status = true;
            } else {
                item.status = false;
            }
        });
        setStepsList([...temp]);
    }

    async function validateSteps() {
        let tempDones = isStepNext;
        if (!stepsData.blog_context) tempDones[0].status = false;
        else tempDones[0].status = true;

        if (!stepsData.title) tempDones[1].status = false;
        else tempDones[1].status = true;

        if (
            stepsData?.outlines?.headings?.length > 0 ||
            stepsData?.outlines?.questions?.length > 0
        )
            tempDones[2].status = true;
        else tempDones[2].status = false;

        if (
            stepsData?.outlines?.headings?.length > 0 ||
            stepsData?.outlines?.questions?.length > 0
        )
            tempDones[3].status = true;
        else tempDones[3].status = false;

        if (
            (stepsData?.outlines?.headings?.length > 0 &&
                stepsData?.outlines?.headings[0]?.para?.length > 0) ||
            (stepsData?.outlines?.questions?.length > 0 &&
                stepsData?.outlines?.questions[0]?.para?.length > 0)
        )
            tempDones[4].status = true;
        else tempDones[4].status = false;

        return false;
    }

    const handleContinue = async (
        {
            StepsField,
            activeStep,
            stepsData,
            addFAQ,
            additional_keywords,
            aiGenerated,

            StepsList,
            realtimeRating,
            reportInfo,
            maxStep,
            mainTime,
            conclusionCta,
        },
        unMounted,
        completed = 0,
    ) => {
        let payload = {
            report_id: reportInfo.report_id,
        };
        unMounted
            ? (payload.step_status = activeStep)
            : (payload.step_status = parseInt(activeStep) + 1);

        const res = await postData({
            url: "/api/contentDraft/v2/save",
            payload,
        });
        if (res.status === 201) {
        }
    };

    function handleStepStatus(activeStep, isNext) {
        let temp = [...StepsList];
        if (isNext) {
            temp[activeStep].status = true;
            setStepsList(temp);
        } else {
        }
    }

    function handleAlertNext(next) {
        setWait10(false);
        setTalkingPointAlert(false);
        setAllowFirstDraft(true);
        if (next) {
            handleNextPreviousActions(true);
        }
    }

    function isNextAllowed() {
        console.log("cruise  active", activeStep);
        let temp = [...errList];
        switch (activeStep) {
            case 1:
                if (!stepsData.title.length) {
                    temp[activeStep].msg = "Please choose the title to proceed";
                    console.log("cruise", temp);
                    setErrList([...temp]);
                    return true;
                }
                break;
            case 2:
                if (
                    !stepsData.outlines?.headings?.length &&
                    !stepsData.outlines?.questions?.length
                ) {
                    temp[activeStep].msg = "Please add at least 1 heading";
                    setErrList([...temp]);
                    return true;
                }
                break;

            default:
                return false;
        }
    }

    const handleNextPreviousActions = (isNext) => {
        setSeoWithCruise(false);
        if (isNext && isNextAllowed() && activeStep < 3) return false;
        if (isNext) {
            if (premiumUserSegment && activeStep == 2) {
                setActiveStep(activeStep + 2);
            } else {
                setActiveStep(activeStep + 1);
            }
            mpTrackCruiseNext({
                activeStep,
                maxStep,
                stepName: StepsList[activeStep]?.id,
                realtimeRating,
                stepsData,
                timeTaken: moment().diff(stepsTime, "seconds"),
            });

            handleContinue(args);
        } else {
            if (!isPreview) {
                mpTrackCruisePrevious({
                    activeStep,
                    maxStep,
                    stepName: StepsList[activeStep]?.id,
                    timeTaken: moment().diff(stepsTime, "seconds"),
                });
            }
            isPreview
                ? ""
                : setActiveStep(
                      activeStep == 0
                          ? 0
                          : premiumUserSegment && activeStep == 4
                          ? activeStep - 2
                          : activeStep - 1,
                  );
        }
        handleStepStatus(activeStep, isNext);

        setIsPreview(false);
    };

    const ref = useRef(null);

    const BottomSection = (
        <div className={styles.bottomSection}>
            <div className={styles.bottomSection__left}>
                {!reportInfo.reportLoading &&
                    activeStep > 0 &&
                    (checkParas(stepsData) || stepsData.introduction) && (
                        <>
                            <div className={styles.ratingsWraper}>
                                <Ratings
                                    keyTermsLength={keyTerms?.length}
                                    style={{
                                        position: "unset",
                                        transform: "unset",
                                        marginRight: 20,
                                    }}
                                    realtimeRating={realtimeRating}
                                    isCruiseMode={true}
                                    contentLength={string.trim().length}
                                    content={string}
                                    cruiseActiveStep={activeStep}
                                />
                            </div>
                        </>
                    )}
            </div>

            <div className={styles.rightSection}>
                <div className={styles.stepBtns}>
                    {activeStep >= 0 && (
                        <button
                            className={`${styles.prevStepBtn} ${
                                isPremium && styles.premiumPrevStepBtn
                            }`}
                            disabled={
                                (activeStep == 0 && !isPreview) ||
                                stepsData.isLoader
                            }
                            onClick={() => {
                                setAllowFirstDraft(true);
                                handleNextPreviousActions(false);
                                activeStep > 3 && setWait10(false);
                            }}
                        >
                            <SVGIcon src={"/New UI/SVG/bsarrowleft.svg"} />
                            Previous{" "}
                        </button>
                    )}
                    {}
                    {activeStep != lastStep ? (
                        <span>
                            <button
                                disabled={
                                    reportInfo.reportLoading ||
                                    stepsData.isLoader
                                }
                                className={`${styles.nextStepBtn} ${
                                    isPremium && styles.premiumNextStepBtn
                                }`}
                                onClick={() => {
                                    if (stepsData.isLoader) return;
                                    allowFirstDraft
                                        ? handleNextPreviousActions(true)
                                        : setTalkingPointAlert(true);
                                }}
                            >
                                <LightTooltip
                                    arrow
                                    title={
                                        reportInfo.reportLoading
                                            ? "Please wait, the report is still in progress."
                                            : ""
                                    }
                                >
                                    <div>
                                        {NextStepBtnTexts[activeStep]
                                            ? premiumUserSegment &&
                                              activeStep == 2
                                                ? NextStepBtnTexts[
                                                      activeStep + 1
                                                  ]
                                                : NextStepBtnTexts[activeStep]
                                            : "Next"}
                                        <SVGIcon
                                            src={"/New UI/SVG/bsarrowright.svg"}
                                        />
                                    </div>
                                </LightTooltip>
                            </button>
                        </span>
                    ) : (
                        <button
                            className={`${styles.nextStepBtn} ${
                                isPremium && styles.premiumNextStepBtn
                            }`}
                            disabled={stepsData.isLoader}
                            onClick={() => {
                                if (document?.quill?.editor?.getText().trim())
                                    setExportModal(true);
                                else {
                                    exportToEditor();
                                }
                            }}
                        >
                            <div>Export to Editor</div>
                        </button>
                    )}
                    {reportInfo.reportLoading && (
                        <div className={styles.nextBtnMsg}>
                            <strong>Create Title</strong> button will be
                            activated once the required SEO Data is ready.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const is_report_created = useMemo(() => reportInfo.is_completed, []);

    return (
        <CruiseState.Provider value={stepsData}>
            <CruiseContext.Provider value={dispatchStepsData}>
                <div className={styles.mainContainer} ref={ref}>
                    {!(
                        ((isPremium ||
                            auth.segment == 6 ||
                            (auth.segment == 5 &&
                                stepsData.aiContentDetection)) &&
                            !checkParas(stepsData) &&
                            activeStep === 4) ||
                        (!isPremium &&
                            ((auth.segment === 2 &&
                                activeStep === 4 &&
                                checkParas(stepsData)) ||
                                ((auth.segment === 3 ||
                                    auth.segment == 4 ||
                                    (auth.segment == 5 &&
                                        !stepsData.aiContentDetection)) &&
                                    activeStep === 4 &&
                                    (stepsData.introduction ||
                                        checkParas(stepsData)))))
                    ) &&
                        stepsData.isLoader && (
                            <LoaderGoNuts
                                activeStep={activeStep}
                                text={stepsData?.text || ""}
                            />
                        )}
                    <div className={styles.wizardHeader}>
                        <div className={styles.wizardHeader__left}>
                            <div className={styles.info}>
                                <div className={styles.focusKeyword}>
                                    <Link
                                        onClick={() => {
                                            ctaUsedToExit("All articles");
                                        }}
                                        to="/admin/creator"
                                        // style={{
                                        //     color: isPremium ? "#fff" : "",
                                        // }}
                                    >
                                        All Articles
                                    </Link>
                                    <span
                                        style={{
                                            marginInline: "5px",
                                            // color: isPremium ? "#fff" : "",
                                        }}
                                    >
                                        /
                                    </span>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <LightTooltip
                                            arrow
                                            title={reportInfo.title}
                                            placement="bottom"
                                        >
                                            <span
                                                className={`${
                                                    styles.reportKeyword
                                                } ${
                                                    isPremium &&
                                                    styles.premiumReportKeyword
                                                }`}
                                            >
                                                {reportInfo.title}
                                            </span>
                                        </LightTooltip>
                                        {reportInfo.secondary_keywords.length &&
                                        JSON.parse(
                                            reportInfo.secondary_keywords,
                                        ).length > 0 ? (
                                            <Morekeywords
                                                keywords={
                                                    reportInfo.secondary_keywords
                                                }
                                                moreCount={
                                                    JSON.parse(
                                                        reportInfo?.secondary_keywords,
                                                    )?.length
                                                }
                                                title={reportInfo.title}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {reportInfo?.country_code ? (
                                        <img
                                            loading="lazy"
                                            width="18px"
                                            height="12px"
                                            style={{ marginLeft: "10px" }}
                                            src={`https://flagcdn.com/w20/${
                                                reportInfo?.country_code?.toLowerCase() ===
                                                "uk"
                                                    ? "gb"
                                                    : reportInfo?.country_code?.toLowerCase()
                                            }.png`}
                                            srcSet={`https://flagcdn.com/w40/${reportInfo?.country_code?.toLowerCase()}.png 2x`}
                                            alt=""
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div
                                    className={styles.CruiseTitle__wrapper}
                                    onClick={() => {
                                        setVideoFrame(true);
                                        mpTrackCruiseWatchDemo();
                                    }}
                                >
                                    <LightTooltip
                                        arrow
                                        title={
                                            "Power through blog writing with our carefully created workflow to get your first draft in 5 minutes!"
                                        }
                                        placement={"bottom-start"}
                                    >
                                        <h4
                                            className={`${styles.title} ${
                                                isPremium && styles.premiumTitle
                                            }`}
                                        >
                                            Cruise Mode
                                        </h4>
                                    </LightTooltip>
                                    {/* <LightTooltip
                                        arrow
                                        title={"Watch demo"}
                                        placement={"bottom"}
                                    >
                                        <div style={{ height: 16 }}>
                                            <AiFillPlayCircle color="rgb(96 129 228)" />
                                        </div>
                                    </LightTooltip> */}
                                    {isPremium && (
                                        <div className={styles.premiumTag}>
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/premium-text.svg"
                                                }
                                            />
                                        </div>
                                    )}
                                    <SVGIcon
                                        src={"/New UI/SVG/youtube-icon.svg"}
                                        style={{
                                            color: "#FF0000",
                                            marginLeft: "3px",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Stepbar begins */}
                        <div className={styles.stepbar}>
                            {/* <ClickAwayListener onClickAway={() => handleTooltipClose(-1)}> */}
                            <ul className={styles.stepsList}>
                                {StepsList &&
                                    StepsList.map((step, i) => {
                                        if (premiumUserSegment && i == 3) {
                                            return;
                                        } else {
                                            return (
                                                <>
                                                    <LightTooltip
                                                        arrow
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        tooltipStyle={{
                                                            maxWidth:
                                                                "fit-content",
                                                        }}
                                                        open={i == open}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={`To proceed, please
                                                                complete the current
                                                                active step`}
                                                    >
                                                        <li
                                                            onClick={(e) => {
                                                                if (
                                                                    stepsData?.isLoader
                                                                ) {
                                                                    return;
                                                                }
                                                                if (step.status)
                                                                    setActiveStep(
                                                                        i,
                                                                    );
                                                                else {
                                                                    e.stopPropagation();
                                                                    handleTooltipOpen(
                                                                        i,
                                                                    );
                                                                }
                                                            }}
                                                            onMouseLeave={
                                                                handleTooltipClose
                                                            }
                                                            key={i}
                                                            className={
                                                                styles.stepItem +
                                                                " " +
                                                                (activeStep ==
                                                                    i &&
                                                                    styles.activeStep) +
                                                                " " +
                                                                (step.status &&
                                                                    styles.doneStep)
                                                            }
                                                            style={{
                                                                cursor: stepsData?.isLoader
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                            }}
                                                        >
                                                            <span
                                                                className={`${
                                                                    styles.stepCount
                                                                } ${
                                                                    isPremium &&
                                                                    styles.premiumStepCount
                                                                }`}
                                                            >
                                                                {step.status ? (
                                                                    <SVGIcon
                                                                        src={
                                                                            "/New UI/SVG/IoMdCheckmark.svg"
                                                                        }
                                                                        size={
                                                                            "19"
                                                                        }
                                                                        style={{}}
                                                                    />
                                                                ) : premiumUserSegment &&
                                                                  i == 4 ? (
                                                                    i
                                                                ) : (
                                                                    i + 1
                                                                )}
                                                            </span>

                                                            <span
                                                                className={`${
                                                                    styles.stepName
                                                                } ${
                                                                    isPremium &&
                                                                    styles.premiumStepName
                                                                }`}
                                                                key={step.name}
                                                            >
                                                                {step.name}
                                                            </span>
                                                        </li>
                                                    </LightTooltip>
                                                    {i <
                                                        StepsList.length -
                                                            1 && (
                                                        <li
                                                            style={{
                                                                color: isPremium
                                                                    ? "#014dc5"
                                                                    : "",
                                                            }}
                                                        >
                                                            <ChevronRight />
                                                        </li>
                                                    )}
                                                </>
                                            );
                                        }
                                    })}
                            </ul>
                            {/* </ClickAwayListener> */}
                        </div>
                        {/* Stepbar ends */}

                        <div
                            className={`${styles.cruiseButton} ${
                                isPremium && styles.premiumCruiseButton
                            }`}
                            style={{
                                cursor: stepsData.isLoader
                                    ? "not-allowed"
                                    : "pointer",
                                visibility: reportInfo.reportLoading
                                    ? "hidden"
                                    : "visible",
                            }}
                            onClick={() => {
                                if (
                                    reportInfo.reportLoading ||
                                    stepsData.isLoader
                                )
                                    return;

                                mpTrackSkipToEditor({
                                    currentStep: activeStep,
                                    maxStep:
                                        maxStep < activeStep
                                            ? activeStep
                                            : maxStep,
                                });
                                setGoNutsModal(false);
                                ctaUsedToExit("Skip to editor");
                            }}
                        >
                            <div>Skip to Editor</div>
                            <BsArrowRightShort size={26} />
                        </div>
                    </div>

                    {contentModal && (
                        <Modal setModal={setContentModal}>
                            <div
                                style={{ width: 480 }}
                                className={styles.doubleCheckModal}
                            >
                                <span className={styles.dcmCross}>
                                    <SVGIcon
                                        onClick={() => setContentModal(false)}
                                        src="/New UI/SVG/cross.svg"
                                    ></SVGIcon>
                                </span>
                                <h3 className={styles.dcmTaglines}>
                                    Alert! Content already exists in the Editor.
                                    In case you would like to review it, Go to
                                    the Editor.
                                </h3>
                                <small>
                                    Please Note: Exporting content created in
                                    Cruise Mode appends the new content in
                                    Editor. Nothing gets deleted.
                                </small>
                                <div className={styles.dcmBtns}>
                                    <Button
                                        text={"Go to Editor"}
                                        handler={() => {
                                            ctaUsedToExit("Go to Editor");
                                            handleModal(false);
                                            setContentModal(false);
                                        }}
                                        borderRadius="3"
                                        width={130}
                                        height={35}
                                        style={{
                                            borderRadius: 3,
                                            backgroundColor: "white",
                                            fontSize: 12,
                                        }}
                                        customClass={styles.dcmBtn1}
                                    />
                                    <Button
                                        text={"Continue with Cruise Mode"}
                                        handler={() => {
                                            setContentModal(false);
                                        }}
                                        borderRadius="3"
                                        width={180}
                                        height={35}
                                        style={{
                                            borderRadius: 3,
                                            backgroundColor: "#1f4cbe",
                                            fontSize: 12,
                                        }}
                                        customClass={styles.dcmBtn}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}

                    {doubleCheckModal && (
                        <Modal setModal={setDoubleCheckModal}>
                            <div className={styles.doubleCheckModal}>
                                <span className={styles.dcmCross}>
                                    <SVGIcon
                                        onClick={() =>
                                            setDoubleCheckModal(false)
                                        }
                                        src="/New UI/SVG/cross.svg"
                                    ></SVGIcon>
                                </span>
                                <h3
                                    className={
                                        styles.dcmTaglines +
                                        " " +
                                        (!reportInfo.reportLoading &&
                                            styles.marginTop)
                                    }
                                >
                                    {reportInfo.reportLoading && (
                                        <>
                                            <span className={styles.first_1}>
                                                Gathering SEO data typically
                                                takes 90 seconds..
                                            </span>
                                            <span className={styles.first_2}>
                                                You may always return to Cruise
                                                Mode to resume your work. You
                                                can view the gathered SEO data
                                                for the given keyword in SEO
                                                Articles.
                                            </span>
                                        </>
                                    )}

                                    {!reportInfo.reportLoading && (
                                        <>
                                            <span className={styles.second}>
                                                You may always return to Cruise
                                                Mode from the editor and resume
                                                your work, which will be saved.
                                            </span>
                                        </>
                                    )}
                                </h3>
                                <div className={styles.dcmBtns}>
                                    <Button
                                        text={
                                            reportInfo.reportLoading
                                                ? "Back to Dashboard"
                                                : "Go to Editor"
                                        }
                                        handler={() => {
                                            if (reportInfo.reportLoading) {
                                                ctaUsedToExit(
                                                    "Back to Dashboard",
                                                );
                                                goTo("DASHBOARD");
                                            } else {
                                                handleModal(false);
                                                ctaUsedToExit("Go to Editor");
                                            }
                                        }}
                                        borderRadius="3"
                                        width={130}
                                        height={35}
                                        style={{
                                            borderRadius: 3,
                                            backgroundColor: "white",
                                            fontSize: 12,
                                        }}
                                        customClass={styles.dcmBtn1}
                                    />
                                    <Button
                                        text={"Stay here"}
                                        handler={() => {
                                            setDoubleCheckModal(false);
                                        }}
                                        borderRadius="3"
                                        width={130}
                                        height={35}
                                        style={{
                                            borderRadius: 3,
                                            backgroundColor: "#1f4cbe",
                                            fontSize: 12,
                                        }}
                                        customClass={styles.dcmBtn}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}

                    {talkingPointAlert && (
                        <Modal setModal={() => {}}>
                            <div className={styles.alertModal}>
                                <span className={styles.dcmCross}>
                                    <SVGIcon
                                        onClick={() => handleAlertNext(false)}
                                        src="/New UI/SVG/cross.svg"
                                    ></SVGIcon>
                                </span>
                                <div className={styles.confirmationText}>
                                    <span className={styles.first_1}>
                                        Did you review all the writing points?
                                    </span>
                                    <span className={styles.first_2}>
                                        The quality of writing points will
                                        directly impact the quality of the blog
                                        generated.
                                    </span>
                                </div>
                                <div className={styles.alertModal__btnDiv}>
                                    <Button
                                        text={"Review"}
                                        handler={() => {
                                            handleAlertNext(false);
                                        }}
                                        borderRadius="3"
                                        width={130}
                                        height={35}
                                        style={{
                                            borderRadius: 3,
                                            backgroundColor: "white",
                                            fontSize: 12,
                                        }}
                                        customClass={styles.dcmBtn1}
                                    />
                                    <Button
                                        text={"Next"}
                                        handler={() => {
                                            handleAlertNext(true);
                                        }}
                                        borderRadius="3"
                                        width={130}
                                        height={35}
                                        style={{
                                            borderRadius: 3,
                                            backgroundColor: "#1f4cbe",
                                            fontSize: 12,
                                        }}
                                        Icon={() => (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/bsarrowright.svg"
                                                }
                                            />
                                        )}
                                        customClass={styles.dcmBtn}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}

                    {isMobile && BottomSection}
                    {activeStep != 5 && !isPreview ? (
                        <section className={styles.stepsContainer}>
                            {is_report_created != 0 && !stepsData?.id ? (
                                <CruiseSkeleton />
                            ) : (
                                <StepCard
                                    tempGuideLineData={tempGuideLineData}
                                    stepsData={stepsData}
                                    dispatchStepsData={dispatchStepsData}
                                    setConclusionCta={setConclusionCta}
                                    conclusionCta={conclusionCta}
                                    NextStepBtnTexts={NextStepBtnTexts}
                                    handleNextPreviousActions={
                                        handleNextPreviousActions
                                    }
                                    isGenTitleLoaded={isGenTitleLoaded}
                                    setIsGenTitleLoaded={setIsGenTitleLoaded}
                                    nextStep={setActiveStep}
                                    step={activeStep}
                                    reportInfo={reportInfo}
                                    continueFunc={continueFunc}
                                    aiGenerated={aiGenerated}
                                    setAiGenerated={setAiGenerated}
                                    freqAskQuestion={freqAskQuestion}
                                    topRankOutlines={topRankedOutlines}
                                    topRanked={topRankedTitles}
                                    additional_keywords={additional_keywords}
                                    setAdditionalKeywords={
                                        setAdditionalKeywords
                                    }
                                    addFAQ={addFAQ}
                                    addFaqToOutline={setAddFAQ}
                                    draftContent={draftContent}
                                    setDraftContent={setDraftContent}
                                    setGenH2List={setGenH2List}
                                    setGenFaqList={setGenFaqList}
                                    genFaqList={genFaqList}
                                    genH2List={genH2List}
                                    article={article}
                                    errList={errList}
                                    setErrList={setErrList}
                                    doubleCheckModal={doubleCheckModal}
                                    titleSessionId={titleSessionId}
                                    realtimeRating={realtimeRating}
                                    generateOutlines={generateOutlines}
                                    setRealtimeRatings={setRealtimeRatings}
                                />
                            )}
                        </section>
                    ) : (
                        <div className={styles.stepsContainer}>
                            <Preview
                                draftContent={stepsData}
                                writeToEditor={pasteContentToEditor}
                                makeCruiseToDelta={makeCruiseToDelta}
                                setIsPreview={setIsPreview}
                                setGoNutsModal={setGoNutsModal}
                                mpData={{
                                    activeStep,
                                    stepName: StepsList[activeStep]?.id,
                                    maxStep,
                                }}
                            />
                        </div>
                    )}
                    {!isMobile && BottomSection}
                </div>
                {videoFrame && (
                    <Modal setModal={setVideoFrame}>
                        <div className={styles.videoModalContainer}>
                            <div
                                className={styles.videoModalClose}
                                onClick={() => setVideoFrame(false)}
                            >
                                <VscClose size={30} />
                            </div>
                            <iframe
                                width="1100"
                                height="619"
                                src={
                                    "https://www.youtube.com/embed/2fuDJHZnHgs"
                                }
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </Modal>
                )}
            </CruiseContext.Provider>

            <div
                className={`${styles.actionMsg} ${
                    stepsData.invalidAction ? styles.show : ""
                }  `}
                style={{
                    background: "#fc9999",
                    color: "#000000",
                }}
            >
                <TextShortner
                    tooltip={false}
                    data={stepsData.invalidAction}
                    limit={50}
                />
            </div>

            {exportModal && (
                <Modal setModal={setExportModal}>
                    <div className={styles.doubleCheckModal}>
                        <span className={styles.dcmCross}>
                            <SVGIcon
                                onClick={() => setExportModal(false)}
                                src="/New UI/SVG/cross.svg"
                            ></SVGIcon>
                        </span>
                        <h3
                            className={
                                styles.dcmTaglines +
                                " " +
                                (!reportInfo.reportLoading && styles.marginTop)
                            }
                        >
                            <>
                                <span className={styles.second}>
                                    Would you like to append the new content or
                                    <br />
                                    replace the existing one?
                                </span>
                            </>
                        </h3>
                        <small
                            style={{
                                color: "darkgray",
                                fontSize: 11,
                                textAlign: "center",
                            }}
                        >
                            * Meta data will be replaced irrespectively
                        </small>
                        <div className={styles.dcmBtns}>
                            <Button
                                text={"Append"}
                                handler={() => {
                                    exportToEditor();
                                }}
                                borderRadius="3"
                                width={130}
                                height={35}
                                style={{
                                    borderRadius: 3,
                                    backgroundColor: "white",
                                    fontSize: 12,
                                }}
                                customClass={styles.dcmBtn1}
                            />
                            <Button
                                text={"Replace"}
                                handler={() => {
                                    exportToEditor(true);
                                }}
                                borderRadius="3"
                                width={130}
                                height={35}
                                style={{
                                    borderRadius: 3,
                                    backgroundColor: "#1f4cbe",
                                    fontSize: 12,
                                }}
                                customClass={styles.dcmBtn}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </CruiseState.Provider>
    );
};

export default StepsWizard;
