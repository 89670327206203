import { Chip, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useState } from "react";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import SVGIcon from "../../../atoms/SVGIcon";

const autoCoustom = makeStyles((theme) => ({
    root: {
        border: "solid 2px #e5e5e5",
        margin: "0",
        padding: "0px 0px",
        borderRadius: 3,
        background: "#fff",
    },
    tag: {
        border: "1px solid transparent",
        background: "#e8edf8cc",
        margin: "0",
        padding: "0",
        borderRadius: 3,
        padding: "0px 13px",
    },
}));

const chipCoustom = makeStyles((theme) => ({
    root: {
        border: "1px solid #e5e5e5",
        background: "#e8edf8cc",
        padding: "0",
        borderRadius: 3,
        margin: "3px",
        marginRight: "3px",
        marginLeft: "3px",
    },
    label: {
        color: "#2c3948",
        fontWeight: "600",
    },
    deleteIcon: {
        color: "#3f5575 !important",
    },
}));

const InputBase = makeStyles((theme) => ({
    root: {
        "& .MuiFilledInput-root": {
            background: "transparent",
            border: "none",
            padding: "0px",
            paddingLeft: "0px",
            color: "black",
            fontSize: 14,
            fontFamily: "var(--font1-font)",
        },
        "& .MuiFilledInput-root:before": {
            border: "none",
        },
        "& .MuiFilledInput-root:focus": {
            border: "none",
        },
        "& .MuiFilledInput-root:hover:before": {
            border: "none",
        },
        "& .MuiFilledInput-root:after": {
            border: "none",
        },
        "& .MuiFilledInput-input::placeholder": {
            color: "#6b6b6b",

            opacity: 1,
        },
    },
    focused: {
        background: "transparent",
    },
    input: {
        border: "10px solid black",
    },
}));

function AdditionalKeywords({
    additional_keywords = "",
    handleDataOnBlur = () => {},
}) {
    const [_additional_keywords, setAdditionalKeywords] = useState(
        additional_keywords.split(",").filter((t) => t),
    );

    const autoClasses = autoCoustom();
    const chipClasses = chipCoustom();
    const InputBaseClasses = InputBase();

    const { mpTrackCruiseAdditionalKeywordsAdded } = useMixpanelHook();

    const handleKeyDown = (event) => {
        switch (event.key) {
            case ",":
            case "Enter":
                if (event.target.value?.length > 0) event.preventDefault();
                event.stopPropagation();
                // if (event.target.value.length > 0) {
                setAdditionalKeywords([
                    ..._additional_keywords,
                    event.target.value,
                ]);
                mpTrackCruiseAdditionalKeywordsAdded();
                // }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        handleDataOnBlur(_additional_keywords.join(","), "additional_keywords");
    }, [_additional_keywords]);

    return (
        <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            onChange={(event, value) => {
                setAdditionalKeywords(value);
            }}
            defaultValue={_additional_keywords}
            value={_additional_keywords}
            freeSolo
            classes={autoClasses}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        classes={chipClasses}
                        variant="outlined"
                        deleteIcon={
                            <SVGIcon
                                src={"/New UI/SVG/VscClose.svg"}
                                styles={{
                                    color: "black",
                                }}
                            />
                        }
                        label={option}
                        {...getTagProps({
                            index,
                        })}
                    />
                ))
            }
            renderInput={(params) => {
                params.inputProps.onKeyDown = handleKeyDown;
                return (
                    <TextField
                        {...params}
                        classes={InputBaseClasses}
                        onBlur={(e) => {
                            if (e.target.value.trim())
                                setAdditionalKeywords((ps) => [
                                    ...ps,
                                    e.target.value,
                                ]);
                        }}
                        variant="filled"
                        placeholder={
                            _additional_keywords.length > 0
                                ? ""
                                : "Insert additional keywords using enter ↵ "
                        }
                    />
                );
            }}
        />
    );
}

export default AdditionalKeywords;
