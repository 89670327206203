import React from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "./styles.module.css";
function Progress({ height, width }) {
  return (
    <div style={{ height, width }} className={styles.container}>
      <CircularProgress />
    </div>
  );
}

export default Progress;
