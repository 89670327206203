import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import Button from "../../atoms/Button/Button";
import { BsArrowRight, BsExclamationCircle } from "react-icons/bs";
import { getTrailText } from "../../Pages/NewDashboard/constants";
import { Skeleton } from "@material-ui/lab";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { socketEvents } from "../../../Sockets/constants";
import { socket } from "../../../Sockets/useSockets";
import useHelperFunctions, { getMsg } from "../../helperFunctions";
import { TbExternalLink, TbInfinity } from "react-icons/tb";
import { PLANS_CATEGORIES, PLANS_TYPE } from "../../../constants";
import { kFormatNumber } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../atoms/Modal/Modal";
import SVGIcon from "../../atoms/SVGIcon";
import { VscClose } from "react-icons/vsc";
import styles2 from "../../Pages/NewDashboard/NewDashboard.module.css";
import axios from "axios";
import { displayAlert } from "../../../store/actions/alert";
import useMixpanelHook from "../../../utility/useMixpanelHook";

const PlanWiseSection = ({
    trial,
    freeForever,
    planTrial,
    planExpiresDate,
    subscriptionInfo,
    isOnPremiumPlan,
    quota,
}: {
    trial: boolean;
    freeForever: boolean;
    planTrial: boolean;
    planExpiresDate: string;
    subscriptionInfo: any;
    isOnPremiumPlan: boolean;
    quota: QuotaType;
}) => {
    const otherInfo = useSelector((state: any) => state.otherInfo);
    const limitsSection = (
        <>
            <section>
                {!!quota?.reports_limit && (
                    <div className={styles.item}>
                        <span className={styles.name}>
                            <span>Article</span> Writer
                        </span>
                        <span className={styles.limit}>
                            {quota.reports_created} /{" "}
                            {quota.reports_limit == -1 ? (
                                <TbInfinity
                                    style={{ marginBottom: -1.5 }}
                                    size={12}
                                />
                            ) : (
                                quota.reports_limit
                            )}
                        </span>
                        <span className={styles.unit}>articles/mo</span>
                    </div>
                )}

                {!!quota.topic_cluster_limit && (
                    <div className={styles.item}>
                        <span className={styles.name}>
                            <span>Keyword</span> Planner
                        </span>
                        <span className={styles.limit}>
                            {quota.clusters_created} /{" "}
                            {quota.topic_cluster_limit}
                        </span>
                        <span className={styles.unit}>clusters/mo</span>
                    </div>
                )}
                {!!quota.content_audit_limit && (
                    <div className={styles.item}>
                        <span className={styles.name}>
                            <span>Content</span> Optimizer
                        </span>
                        <span className={styles.limit}>
                            {quota.audits_created} / {quota.content_audit_limit}
                        </span>
                        <span className={styles.unit}>pages/mo</span>
                    </div>
                )}
                {!!quota.content_audit_limit &&
                    otherInfo?.premium_cruise == 1 && (
                        <div className={styles.item}>
                            <span className={styles.name}>
                                <span>Premium</span> Cruise
                            </span>
                            <span className={styles.limit}>
                                {otherInfo?.ai_credits || "0"}
                            </span>
                            <span className={styles.unit}>{"credits"}</span>
                        </div>
                    )}
                {quota.short_form_limit > 0 && (
                    <div className={styles.item}>
                        <span className={styles.name}>AI Words</span>
                        <span className={styles.limit}>
                            {kFormatNumber(
                                quota.long_form_used + quota.short_form_used,
                            )}{" "}
                            / {kFormatNumber(quota.short_form_limit)}
                        </span>
                        <span className={styles.unit}>words/mo</span>
                    </div>
                )}
                {!!(
                    subscriptionInfo.humanizer_plan_id &&
                    subscriptionInfo.humanizer_status
                ) && (
                    <div className={styles.item}>
                        <span className={styles.name}>Humanizer</span>
                        <span className={styles.limit}>
                            {kFormatNumber(quota.humanizer_used)} /{" "}
                            {kFormatNumber(quota.humanizer_limit)}
                        </span>
                        <span className={styles.unit}>words/mo</span>
                    </div>
                )}
                {planTrial && (
                    <small>
                        <BsExclamationCircle size={14} />
                        &nbsp; Your trail ends in{" "}
                        {getTrailText(planExpiresDate)} days
                    </small>
                )}
            </section>
        </>
    );

    if (trial)
        return (
            <>
                <header style={{ justifyContent: "center" }}>
                    Trial Ends in
                </header>
                <section className={styles.trial}>
                    <h1>{getTrailText(planExpiresDate)}</h1>
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        {getTrailText(planExpiresDate) > 1 ? "days" : "day"}
                    </div>
                </section>
            </>
        );
    else if (freeForever)
        return (
            <>
                <header>Free Forever</header>
                {limitsSection}
            </>
        );
    else if (planTrial)
        return (
            <>
                <header>
                    {subscriptionInfo.plan_name} Plan <span>TRIAL</span>
                </header>
                {limitsSection}
            </>
        );
    else
        return (
            <>
                <header>
                    {subscriptionInfo.plan_name}{" "}
                    {subscriptionInfo?.type == 3 &&
                    subscriptionInfo?.plan_category !=
                        PLANS_CATEGORIES.FREE_FOREVER
                        ? "LIFETIME"
                        : "Plan"}
                </header>
                {limitsSection}
            </>
        );
};

const PlanWiseButton = ({
    trial,
    freeForever,
    planTrial,
}: {
    trial: boolean;
    freeForever: boolean;
    planTrial: boolean;
}) => {
    const { planExpiresDate } = useUserAccessHook();
    const { mixpanelTrack } = useMixpanelHook();
    const goTo = useNavigation();
    const [confirmationModal, setConfirmationModal] = useState(false);
    const signUpMode = useSelector((state: any) => state.auth.signup_mode);
    const text = trial || planTrial ? "End Trial & Subscribe" : "Upgrade";
    const handler = () => {
        if (trial || planTrial) {
            if (getTrailText(planExpiresDate) == 7 && planTrial) {
                mixpanelTrack("Day 0 Dashboard", {
                    action: `End Trial & Subscribe`,
                });
            }
        }
        if ((trial || planTrial) && signUpMode == 2) {
            setConfirmationModal(true);
        } else {
            goTo("BILLING_PLANS");
        }
    };

    return (
        <>
            <Button
                style={{
                    backgroundColor: "#CDDCFE",
                    color: "var(--primary-blue)",
                    borderRadius: 8,
                    fontSize: 13,
                }}
                text={text}
                handler={handler}
                height={36}
            ></Button>
            {confirmationModal && (
                // @ts-ignore
                <Modal setModal={setConfirmationModal}>
                    <div className={styles.confirmationWrapper}>
                        <SVGIcon
                            style={{ marginBottom: "20px", marginTop: "10px" }}
                            size={40}
                            src={"/New UI/SVG/Scalenut.svg"}
                        />
                        <h2 className={styles.confirmationHeader}>
                            Are you sure you want to end
                            <br />
                            the trial and make a purchase?
                        </h2>
                        <div className={styles.confirmationSubText}>
                            This will end your trial immediately and shift
                            <br /> you to a paid plan
                        </div>
                        <Button
                            width={270}
                            style={{
                                marginBottom: "10px",
                                fontSize: "14px",
                            }}
                            text="Yes, End Trial and Subscribe"
                            Icon={() => <BsArrowRight />}
                            handler={() => {
                                goTo("BILLING_PLANS");
                                setConfirmationModal(false);
                            }}
                        />
                        <Button
                            width={270}
                            style={{
                                background: "rgb(201, 227, 255)",
                                color: "#014dc5",
                                marginBottom: "20px",
                                fontSize: "14px",
                            }}
                            text="No, I want to continue the free trial"
                            handler={() => setConfirmationModal(false)}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

type QuotaType = {
    long_form_limit: number;
    short_form_limit: number;
    reports_limit: number;
    topic_cluster_limit: number;
    content_audit_limit: number;
    short_form_used: number;
    long_form_used: number;
    reports_created: number;
    audits_created: number;
    clusters_created: number;
    humanizer_used: number;
    humanizer_limit: number;
};

function PlanInfoAside() {
    const {
        subscriptionInfo,
        planExpiresDate,
        isFreeForever,
        trialUser,
        freeUser,
        planTrial,
        isLTD,
    } = useUserAccessHook();

    const [quota, setQuota] = useState({
        long_form_limit: 0,
        short_form_limit: 0,
        reports_limit: 0,
        topic_cluster_limit: 0,
        content_audit_limit: 0,
        short_form_used: 0,
        long_form_used: 0,
        reports_created: 0,
        audits_created: 0,
        clusters_created: 0,
    });

    const { getData } = useHelperFunctions();
    const [isLoaded, setIsloaded] = useState(0);
    const [showWebtuneModal, setShowWebtuneModal] = useState(false);
    const [showAlreadyLoggedInModal, setShowAlreadyLoggedInModal] =
        useState(false);
    const token = useSelector((state: any) => state.auth.token);
    const userId = useSelector((state: any) => state.auth.userId);
    const otherInfo = useSelector((state: any) => state.otherInfo);
    const webTuneLogin = useSelector(
        (state: any) => state.otherInfo?.web_tune_login,
    );
    const [webtuneLoginSuccess, setWebtuneLoginSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const { mixpanelTrack } = useMixpanelHook();
    const dispatch = useDispatch();
    const goTo = useNavigation();

    const webtuneLogin = async () => {
        setLoading(true);
        await axios({
            method: "POST",
            url: `https://api.webtune.ai/api/scalenutLogin`,
            headers: {
                "Content-Type": "application/json",
                "X-Frame-Options": "DENY",
            },
            data: {
                scalenut_auth: token,
            },
        })
            .then((res) => {
                if (res?.status == 200) {
                    mixpanelTrack("Scalenut Webtune", {
                        action: "Webtune User Created",
                    });
                    setWebtuneLoginSuccess(true);
                    setLoading(false);
                    setShowWebtuneModal(false);
                    window.open(
                        `https://app.webtune.ai/scalenut?token=${res?.data?.data?.access_token}`,
                        "_blank",
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response?.data?.message) {
                    dispatch(
                        displayAlert({
                            alertMessage: getMsg(err?.response?.data?.message),
                            alertType: "error",
                        }),
                    );
                }
            });
    };

    useEffect(() => {
        if (!!isLoaded && !isLTD && userId % 3 == 0) {
            if (trialUser && getTrailText(planExpiresDate) <= 4) {
                mixpanelTrack("Scalenut Webtune", {
                    action: "Card Visible(trial)",
                });
                return;
            } else if (!trialUser) {
                mixpanelTrack("Scalenut Webtune", {
                    action: "Card Visible",
                });
            }
        }
    }, [isLoaded]);

    useEffect(() => {
        (async () => {
            const res = await getData({
                url: "/api/pricing/getSubscriptionPlans",
            });
            if (res.status == 200) {
                if (res?.data?.quota) {
                    setQuota(res?.data?.quota);
                }
                setTimeout(() => {
                    setIsloaded(1);
                }, 100);
            }
        })();

        const attachSockets = () => {
            socket.on(socketEvents.SUBSCRIPTION_QUOTA, successCallback);
        };

        const removeSockets = () => {
            socket.off(socketEvents.SUBSCRIPTION_QUOTA, successCallback);
        };

        const successCallback = (data: QuotaType) => {
            setQuota(data);
        };

        attachSockets();

        return () => removeSockets();
    }, []);
    if (!subscriptionInfo) return "";

    const isMaxPlan =
        otherInfo.subscriptionInfo?.plan_category == 10 ||
        otherInfo.subscriptionInfo?.plan_category == 11
            ? true
            : false;

    const isOnPremiumPlan =
        (subscriptionInfo.plan_category == 3 ||
            subscriptionInfo.plan_category == 4 ||
            subscriptionInfo.plan_category == 10 ||
            subscriptionInfo.plan_category == 11) &&
        !planTrial;
    // Sale Revert
    // const isDeal = false;
    const [isDeal, setIsDeal] = useState(false);
    // useEffect(() => {
    //     if (freeUser || trialUser) {
    //         setIsDeal(true);
    //     } else {
    //         setIsDeal(false);
    //     }
    // }, [trialUser, freeUser]);
    const containerRef = useRef(null);

    return (
        <>
            <div ref={containerRef} className={`${styles.planInfo_container}`}>
                {isLoaded ? (
                    <>
                        <PlanWiseSection
                            trial={trialUser}
                            freeForever={isFreeForever}
                            planTrial={planTrial}
                            planExpiresDate={planExpiresDate}
                            subscriptionInfo={subscriptionInfo}
                            isOnPremiumPlan={isOnPremiumPlan}
                            quota={quota}
                        />

                        {isOnPremiumPlan || isLTD ? (
                            <></>
                        ) : (
                            <PlanWiseButton
                                trial={trialUser}
                                freeForever={isFreeForever}
                                planTrial={planTrial}
                                // subscriptionInfo={subscriptionInfo}
                            />
                        )}
                    </>
                ) : (
                    <LoadingSkeleton />
                )}
            </div>
            {showAlreadyLoggedInModal && (
                // @ts-ignore
                <Modal setModal={setShowAlreadyLoggedInModal}>
                    <div className={styles.webtuneModal}>
                        <SVGIcon src="/New UI/SVG/webtuneBlue.svg" />
                        <VscClose
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                cursor: "pointer",
                                color: "#ACB3BF",
                            }}
                            size={24}
                            onClick={() => {
                                mixpanelTrack("Scalenut Webtune", {
                                    action: "Clicked close icon",
                                    "Already Logged in": "Yes",
                                });
                                setShowAlreadyLoggedInModal(false);
                            }}
                        />
                        <div
                            style={{
                                color: "#2C3948",
                                fontSize: "14px",
                                marginTop: "10px",
                            }}
                        >
                            You are already connected to Webtune. Go to Webtune
                            app to continue tuning up your website.
                        </div>
                        <Button
                            Icon={() => <TbExternalLink color="#fff" />}
                            text={"Open Webtune"}
                            style={{
                                background: "#6C2BD9",
                                color: "#fff",
                                marginTop: "20px",
                                fontSize: "14px",
                            }}
                            handler={() => {
                                mixpanelTrack("Scalenut Webtune", {
                                    action: "Clicked Open Webtune",
                                    "Already Logged in": "Yes",
                                });
                                goTo("BILLING_PLANS", [], "/addon");
                            }}
                        />
                    </div>
                </Modal>
            )}
            {showWebtuneModal && (
                // @ts-ignore
                <Modal setModal={setShowWebtuneModal}>
                    <div className={styles2.WebtuneModalWrapper}>
                        <SVGIcon
                            style={{
                                position: "absolute",
                                bottom: "3px",
                                right: "20px",
                                transform: "scale(1.3)",
                            }}
                            src="/New UI/SVG/webtuneBgLogo.svg"
                        />
                        <VscClose
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                cursor: "pointer",
                                color: "#ACB3BF",
                            }}
                            size={24}
                            onClick={() => {
                                mixpanelTrack("Scalenut Webtune", {
                                    action: "Clicked close(Try For Free)",
                                });
                                setShowWebtuneModal(false);
                            }}
                        />
                        <div className={styles2.WebtuneHeaderWrapper}>
                            <div className={styles2.headerSection1}>
                                <div className={styles2.webtuneText}>
                                    <SVGIcon src="/New UI/SVG/webtuneModalText.svg" />
                                </div>
                            </div>
                        </div>
                        <div className={styles2.WebtuneBottomWrapper}>
                            <div className={styles2.premiumBottomHeader}>
                                <div className={styles2.premiumSep2}></div>
                                <div
                                    className={styles2.premiumBottomHeaderText}
                                >
                                    On-page SEO made easy
                                </div>
                                <div className={styles2.premiumSep2}></div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    gap: "15px",
                                    marginBottom: "30px",
                                }}
                            >
                                <div className={styles2.webtuneFeature1}>
                                    <SVGIcon
                                        size={14}
                                        src="/New UI/SVG/webtune_search.svg"
                                    />
                                    <div>Detect</div>
                                </div>
                                <div className={styles2.webtuneFeature2}>
                                    <SVGIcon
                                        size={14}
                                        src="/New UI/SVG/webtune_magic.svg"
                                    />
                                    <div>Resolve</div>
                                </div>
                                <div className={styles2.webtuneFeature3}>
                                    <SVGIcon
                                        size={14}
                                        src="/New UI/SVG/webtune_electric.svg"
                                    />
                                    <div>Deploy</div>
                                </div>
                            </div>
                            <Button
                                handler={() => {
                                    mixpanelTrack("Scalenut Webtune", {
                                        action: "Clicked Try For Free",
                                    });
                                    webtuneLogin();
                                }}
                                Icon={() => (
                                    <BsArrowRight
                                        color="#fff"
                                        strokeWidth={"1px"}
                                        size={22}
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    />
                                )}
                                loading={loading}
                                text={"TRY FOR FREE"}
                                width={220}
                                height={50}
                                style={{
                                    paddingInline: "35px",
                                    borderRadius: "44px",
                                    // marginRight: "10px",
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    background:
                                        "linear-gradient(185deg, rgba(140,74,251,0.78) 0%, rgba(108,43,217,1) 100%)",
                                }}
                            />
                            <div
                                style={{
                                    color: "#7188AA",
                                    fontSize: "14px",
                                    marginTop: "15px",
                                }}
                            >
                                No card required
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

const LoadingSkeleton = () => (
    <>
        <Skeleton style={{ minWidth: 45 }} height={30} width={"50%"} />
        <Skeleton style={{ marginBlock: 5 }} width={"90%"} />
        <Skeleton style={{ marginBlock: 5 }} width={"90%"} />
        <Skeleton style={{ marginBlock: 5 }} width={"90%"} />
    </>
);

const Deal = ({ containerRef }: { containerRef: any }) => {
    const goTo = useNavigation();
    const [min, setMin] = useState(false);
    const { deals } = useSelector((state: any) => state.appConfig);
    const [_deals, setDeals] = useState<any>({});
    useEffect(() => {
        if (deals) {
            setDeals(JSON.parse(deals));
        }
    }, [deals]);

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                const entry = entries[entries.length - 1];

                if (entry.contentRect.width < 60) {
                    setMin(true);
                } else {
                    setMin(false);
                }
            });

            if (containerRef.current.offsetWidth < 60) {
                setMin(true);
            } else {
                setMin(false);
            }

            resizeObserver.observe(containerRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [containerRef.current]);

    return (
        <>
            <div
                style={{
                    fontSize: 25,
                    fontWeight: 600,
                    position: "relative",
                    zIndex: 2,
                }}
            >
                Celebrating
            </div>
            <div
                style={{
                    fontSize: 25,
                    fontWeight: 600,
                    color: "#fff",
                    background:
                        "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}
            >
                4th Anniversary
            </div>
            <div
                style={{
                    background:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.97)), linear-gradient(16.01deg, rgba(1, 1, 1, 0.2) 53.05%, rgba(255, 192, 85, 0.2) 78.87%, rgba(36, 27, 12, 0.2) 100%)",
                    padding: "9px 20px",
                    borderRadius: "7px",
                    marginTop: "30px",
                    marginBottom: "10px",
                    border: "0.8px solid" /* This sets the border width */,
                    borderImageSource:
                        "linear-gradient(90.82deg, rgba(191, 149, 63, 0.4) -2.17%, rgba(252, 246, 186, 0.4) 29.43%, rgba(179, 135, 40, 0.4) 101.5%)",
                }}
            >
                <span
                    style={{
                        background:
                            "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        position: "relative",
                    }}
                >
                    <span
                        style={{
                            background:
                                "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.97)), linear-gradient(16.01deg, rgba(1, 1, 1, 0.2) 53.05%, rgba(255, 192, 85, 0.2) 78.87%, rgba(36, 27, 12, 0.2) 100%)",
                            padding: "5px 10px",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            border: "0.34px solid",
                            borderImageSource:
                                "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                            position: "absolute",
                            top: "-30px",
                            height: "20px",
                            left: "14%",
                            display: "flex",
                            paddingInline: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                background:
                                    "linear-gradient(90.82deg, #BF953F -2.17%, #FCF6BA 29.43%, #B38728 101.5%)",
                                backgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontSize: 9,
                                fontWeight: 400,
                            }}
                        >
                            100 LICENSES ONLY
                        </span>
                    </span>
                    {_deals.maxPlanDiscount}% with Max Plans
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            zIndex: 1000,
                            right: "0px",
                            bottom: "-40px",
                        }}
                    >
                        <SVGIcon src={"/New UI/SVG/underline.svg"} size={80} />
                    </span>
                </span>
            </div>

            {min ? (
                ""
            ) : (
                <Button
                    text="Grab The Deal"
                    style={{
                        borderRadius: 20,
                        background: "#F3C975",
                        color: "#000000",
                        fontWeight: 500,
                        fontSize: 14,
                    }}
                    Icon={() => (
                        <SVGIcon
                            src={"/New UI/SVG/tilt-bold-arrow.svg"}
                            style={{ color: "#00001CE5" }}
                            size={12}
                        />
                    )}
                    height={36}
                    width={150}
                    handler={() => goTo("BILLING_PLANS")}
                />
            )}
            {/* <div
                style={{
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: 12,
                    fontStyle: "italic",
                    marginTop: 5,
                }}
            >
                Only {_deals?.slotsLeft || 250} Slots
            </div> */}
        </>
    );
};

export default PlanInfoAside;
