import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from "react";

import styles from "../WebTune/styles.module.css";

import { BiCheck, BiSearch } from "react-icons/bi";

import { IoChevronDown, IoClose, IoReturnUpBack } from "react-icons/io5";

import moment from "moment";

import { MdClose } from "react-icons/md";
import mixpanel from "mixpanel-browser";
import {
    BASE_URL,
    clubLogsPagesWise,
    endPoints,
} from "../InterLinking/constants";
import Button from "../../atoms/Button/Button";
import SVGIcon from "../../atoms/SVGIcon";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import LocalPagination from "../../atoms/LocalPagination/LocalPagination";
import Modal from "../../atoms/Modal/Modal";
import { FilterMenu, IssueTag } from "../WebTune/WebtuneDashboard";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import { VerifiedStatus } from "../WebTune/ManageDomainsWrapper";
import useHelperFunctions from "../../helperFunctions";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    ClickAwayListener,
    Input,
    InputAdornment,
    debounce,
    makeStyles,
} from "@material-ui/core";
import {
    categoryMap,
    fixActions,
    issueLogsFilterDefault,
} from "../WebTune/constants";
import { Skeleton } from "@material-ui/lab";
import Progress from "../../atoms/Progress/Progress";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import { Paragraph } from "./LinkingSuggestionModal";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles2 from "./NoActiveLMSubs.module.css";
import { VscClose } from "react-icons/vsc";
const ITEMS_PER_PAGE = 50;

const dummyTabs = [
    {
        name: "Link Manager",
        value: 2,
        key: "Link_Manager",
    },
];

const useAccordionCompleteStyle = makeStyles(() => {
    return {
        root: {
            backgroundColor: "none",
            boxShadow: "none",
            padding: 0,
            margin: "0 !important",
            "& .MuiAccordionSummary-root": {
                padding: "2px 10px",
                minHeight: 40,
                border: "none",
                marginTop: "-5px",
            },
            "& .MuiAccordionSummary-root:first-child": {
                // borderTop: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: "0",
                alignItems: "center",
            },
            "& .MuiAccordionDetails-root": {
                padding: "0",
                flexDirection: "column",
            },
            "&:before": { content: "none" },
        },
    };
});

export const NoResultFound = () => (
    <div className={styles.noResults}>
        <SVGIcon size={180} src={"/New UI/SVG/Emptydocument.svg"} />
        No Results found
    </div>
);

function HistoryWindow({ isModal = false, closeModal }) {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [currentUrlPage, setCurrentUrlPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [numberOfUrlPages, setNumberOfUrlPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [dataLoadShimmer, setDataLoadShimmer] = useState(false);
    const [reverting, setReverting] = useState(false);
    const [domainsList, setDomainsList] = useState([]);
    const [showMoreOpt, setShowMoreOpt] = useState(false);
    const [domainInfo, setDomainInfo] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: issueLogsFilterDefault?.startDate,
        endDate: issueLogsFilterDefault?.endDate,
    });
    console.log(issueLogsFilterDefault, "q34q234234");
    const [togglerStart, setTogglerStart] = useState(false);
    const [togglerEnd, setTogglerEnd] = useState(false);
    const [addOnTerminated, setAddOnTerminated] = useState(false);
    const [isDomainConnected, setIsDomainConnected] = useState(false);
    const setDateRangeRef = useRef(null);
    const [filters, setFilters] = useState({
        ...issueLogsFilterDefault,
        ...dateRange,
    });
    const [search, setSearch] = useState("");
    const [revertModal, setRevertModal] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [activeTab, setActiveTab] = useState(dummyTabs[0]);
    const { postData, getData } = useHelperFunctions();
    console.log("daet Filetes", filters);
    useEffect(() => {
        setFilters((ps) => ({ ...ps, ...dateRange }));
    }, [dateRange]);
    const getUserDomains = async (showLoader = true) => {
        setLoading(showLoader);
        const res = await getData({
            completeUrl: BASE_URL + endPoints.userDomains,
        });
        if (res.status === 200) {
            setAddOnTerminated(false);

            if (!!res.data.length) {
                setIsDomainConnected(true);
                let activeDomain = res.data.find((dm) => dm?.is_active);
                setDomainsList([...res.data]);

                setDomainInfo((ps) => ({
                    ...ps,
                    ...activeDomain,
                    domain_id: activeDomain?.id,
                }));
                setLoading(false);
            } else {
                setIsDomainConnected(false);
            }
            setLoading(false);
        } else {
            if (res.response.data.message.includes("Terminated")) {
                setAddOnTerminated(true);
            }
        }
        setLoading(false);
    };

    // get user domains on load
    useEffect(() => {
        getUserDomains();
    }, []);

    useEffect(() => {
        //
        let _filters = filters.categories.filter((ftm) =>
            ftm?.issueTags.length > 0 ? true : false,
        );
        if (!!_filters.length) {
            setIsFilterActive(true);
        } else {
            setIsFilterActive(false);
        }
    }, [filters]);

    const handleUrlPageChange = (e, obj = {}) => {
        setCurrentUrlPage(e);
        historyRequest({ showLoader: true, pageNumber: e, filters, activeTab });
    };

    const revertRequest = async () => {
        setReverting(true);
        const arr = [];
        data?.forEach((item) => {
            item?.fixes?.forEach((fix) => {
                fix?.checked && arr.push(fix.fix_id);
            });
        });
        const res = await postData({
            url: endPoints.updateFix,
            payload: {
                action: fixActions.REVERT,
                domain_id: domainInfo?.domain_id,
                fix_id: arr,
            },
        });
        if (res.status === 200) {
            historyRequest({ pageNumber: 1, filters, activeTab });
            setReverting(false);
            setRevertModal(false);
        }
    };
    const revertRequestLinking = async () => {
        setReverting(true);
        const arr = [];
        data?.forEach((item) => {
            item?.fixes?.forEach((fix) => {
                if (fix?.checked) {
                    fix.paras.map((p) => {
                        arr.push(p.fix_id);
                    });
                }
            });
        });

        console.log("revertRequestLinking", arr);
        const res = await postData({
            completeUrl: BASE_URL + endPoints.updateFix,
            payload: {
                action: fixActions.REVERT,
                domain_id: domainInfo?.domain_id,
                target: activeTab.key,
                fix_id: arr,
            },
        });
        if (res.status === 200) {
            historyRequest({
                showLoader: true,
                pageNumber: 1,
                filters,
                activeTab,
            });
            setReverting(false);
            setRevertModal(false);
        }
    };

    const historyRequest = async ({
        showLoader = true,
        pageNumber = 1,
        filters = {},
        activeTab = {},
    }) => {
        setLoading(showLoader);
        setDataLoadShimmer(true);
        setData([]);
        setAllData([]);
        let payload = {
            domain: domainInfo?.domain,
            domain_id: domainInfo?.domain_id,
            pageNumber,
            tabName: activeTab.key || "",
            search: filters?.search || "",
            categories:
                filters?.categories.filter((itm) =>
                    itm?.issueTags?.length > 0 ? true : false,
                ) || [],

            limit: ITEMS_PER_PAGE,
            dateRange: {
                startDate: filters?.startDate
                    ? filters?.startDate
                    : moment().subtract(1, "year").format(),
                ...(filters?.hasOwnProperty("endDate") && {
                    endDate: filters?.endDate
                        ? filters?.endDate
                        : moment().format(),
                }),
            },
        };
        console.log("historyRequest =====", payload);
        setLoading(showLoader);
        setDataLoadShimmer(true);

        const res = await postData({
            completeUrl: BASE_URL + endPoints.getLogs,
            payload,
        });
        setLoading(false);
        if (res.status === 200) {
            setDataLoadShimmer(false);

            if (res.data?.data?.length > 0) {
                let parsedData = dateWiseCategory(res.data.data);
                setTotalData(
                    res?.data?.totalLength || res?.data?.data?.length > 0 || 0,
                );
                let tempArr = [];
                parsedData.forEach((pd, idx) => {
                    let mrgFixes = clubLogsPagesWise(pd.fixes);
                    tempArr.push({ ...parsedData[idx], fixes: mrgFixes });
                });
                parsedData = [...tempArr];
                setData(parsedData);
                setAllData(res.data?.data?.filter((log) => log.status));
                setLoading(false);
            } else {
                setAllData([]);
                setData([]);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (totalData > 0) {
            setNumberOfUrlPages(Math.ceil(totalData / ITEMS_PER_PAGE));
        } else {
            setNumberOfUrlPages(1);
        }
    }, [totalData]);

    const dateWiseCategory = (arr) => {
        const newArr = [];
        arr.forEach((log) => {
            if (log.status === 0) return;
            if (newArr.length) {
                if (
                    newArr[newArr.length - 1].date ===
                    moment(log.created_at).format("l")
                )
                    newArr[newArr.length - 1].fixes.push(log);
                else {
                    newArr.push({
                        date: moment(log.created_at).format("l"),
                        fixes: [log],
                    });
                }
            } else {
                newArr.push({
                    date: moment(log.created_at).format("l"),
                    fixes: [log],
                });
            }
        });
        return newArr;
    };
    useEffect(() => {
        if (activeTab.name && domainInfo?.domain_id) {
            setCurrentUrlPage(1);
            historyRequest({
                showLoader: false,
                pageNumber: currentUrlPage,
                filters,
                activeTab,
            });
        }
    }, [filters, activeTab, domainInfo?.domain_id]);

    const debouncedSetFilters = useCallback(
        debounce((e) => {
            const value = e?.target?.value?.trim();
            if (!value) {
                // setFoundIssues(null);
            }
            setSearch(value);
            mixpanel.track("Filters", {
                "Filter Type": "Search",
                Screen: "History",
            });
        }, 500),
        [],
    );

    const getSelectedFixes = () => {
        let count = 0;
        data?.forEach((item) => {
            item?.fixes?.forEach((fix) => {
                fix?.checked && ++count;
            });
        });
        return count;
    };

    const filterBySearch = (item) => {
        const _search = search?.toLowerCase() || "";
        if (_search) {
            if (
                item.fix.recommendation?.toLowerCase().includes(_search) ||
                item.meta_data.page_url?.toLowerCase().includes(_search)
            ) {
                return true;
            }
        } else {
            return true;
        }
    };

    useEffect(() => {
        const _tempAllData = [...allData.filter(filterBySearch)];
        let parsedData = dateWiseCategory(_tempAllData);

        let tempArr = [];
        parsedData.forEach((pd, idx) => {
            let mrgFixes = clubLogsPagesWise(pd.fixes);
            tempArr.push({ ...parsedData[idx], fixes: mrgFixes });
        });
        parsedData = [...tempArr];
        setData(parsedData);
    }, [search]);

    return (
        <div
            className={styles.historyContainerWrapper}
            style={{ height: isDomainConnected ? "88vh" : "56vh" }}
        >
            <div className={`${styles.historyContainer}`}>
                <div className={styles.headerWrapper}>
                    <header>
                        <h3 style={{ fontWeight: 600 }}>Change Logs</h3>{" "}
                        {isModal && <CloseButton setClose={closeModal} />}
                    </header>
                </div>
                {loading ? (
                    <Progress width={"100%"} height={"40vh"} />
                ) : isDomainConnected ? (
                    <>
                        <div className={styles.domains_date_wrapper}>
                            <ClickAwayListener
                                onClickAway={() => {
                                    setShowMoreOpt(false);
                                }}
                            >
                                <div
                                    className={styles.connectedDomain}
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "10px",
                                        paddingLeft: 0,
                                    }}
                                >
                                    <div
                                        className={styles.subTabHeader}
                                        style={{
                                            paddingLeft: 0,
                                        }}
                                    >
                                        Domain
                                    </div>

                                    <div
                                        className={styles.currentDomainWrapper}
                                        onClick={() => {
                                            mixpanel.track(
                                                "Domains List Toggler",
                                                {
                                                    Screen: "Change Logs",
                                                },
                                            );
                                            setShowMoreOpt(!showMoreOpt);
                                        }}
                                    >
                                        <img
                                            src={`https://s2.googleusercontent.com/s2/favicons?domain=${domainInfo?.domain}&sz=32`}
                                            alt=""
                                        />
                                        <div className={styles.domainName}>
                                            <span
                                                className={styles.blue}
                                                style={{
                                                    color: "var(--link-color1)",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {domainInfo?.domain}
                                            </span>
                                            &nbsp;&nbsp;
                                            <VerifiedStatus
                                                is_verified={
                                                    domainInfo?.is_verified
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.moreActionsWrapper
                                            }
                                        >
                                            <div
                                                className={styles.moreActions}
                                                style={{
                                                    visibility: showMoreOpt
                                                        ? "hidden"
                                                        : "visible",
                                                }}
                                            >
                                                <SVGIcon
                                                    src={`/New UI/SVG/arrow-head-${
                                                        showMoreOpt
                                                            ? "up"
                                                            : "down"
                                                    }.svg`}
                                                    style={{
                                                        color: "inherit",
                                                    }}
                                                />
                                            </div>
                                            {showMoreOpt && (
                                                <div
                                                    className={
                                                        styles.domainList
                                                    }
                                                >
                                                    {!!domainsList?.length ? (
                                                        domainsList?.map(
                                                            (dm, idx) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            styles.domainItem
                                                                        }
                                                                        onClick={(
                                                                            e,
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            if (
                                                                                dm?.id !=
                                                                                domainInfo?.domain_id
                                                                            ) {
                                                                                mixpanel.track(
                                                                                    "Domain Switch",
                                                                                    {
                                                                                        "Verification Staus":
                                                                                            dm?.is_verified ||
                                                                                            "-",
                                                                                        Screen: "Change Logs",
                                                                                        Type: "Domains List Toggler",
                                                                                        "Verification Status":
                                                                                            dm.is_verified,
                                                                                        "Pages Crawled":
                                                                                            dm?.stable_version_total_pages,
                                                                                        "Total Pages":
                                                                                            dm?.stable_version_total_crawled_pages,
                                                                                    },
                                                                                );

                                                                                setDomainInfo(
                                                                                    {
                                                                                        ...dm,
                                                                                        domain_id:
                                                                                            dm?.id,
                                                                                    },
                                                                                );
                                                                                setDomainsList(
                                                                                    (
                                                                                        ps,
                                                                                    ) => {
                                                                                        let temp =
                                                                                            [
                                                                                                ...ps,
                                                                                            ];
                                                                                        temp[
                                                                                            idx
                                                                                        ] =
                                                                                            {
                                                                                                ...temp[
                                                                                                    idx
                                                                                                ],
                                                                                                is_active: 1,
                                                                                            };
                                                                                        return temp;
                                                                                    },
                                                                                );
                                                                            }

                                                                            setShowMoreOpt(
                                                                                false,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.dmIcon
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={`https://s2.googleusercontent.com/s2/favicons?domain=${dm?.domain}&sz=32`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                styles.dmName
                                                                            }
                                                                        >
                                                                            {
                                                                                dm.domain
                                                                            }
                                                                        </div>

                                                                        <VerifiedStatus
                                                                            is_verified={
                                                                                dm?.is_verified
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            },
                                                        )
                                                    ) : (
                                                        <div>
                                                            {domainInfo?.domain}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ClickAwayListener>
                            <div className={styles.logsDateWrapper}>
                                <div className={styles.subTabHeader}>Date</div>
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setTogglerStart(false);
                                    }}
                                >
                                    <div className={styles.dateSelectWrapper}>
                                        <div className={styles.dateLabel}>
                                            From
                                        </div>
                                        <div
                                            className={styles._date}
                                            onClick={() => {
                                                setTogglerStart(true);
                                            }}
                                        >
                                            <CustomDatePicker
                                                date={dateRange?.startDate}
                                                dateFormat={"dd MMMM yyyy"}
                                                setDate={(d) => {
                                                    mixpanel.track("Filters", {
                                                        Type: "Start Date",
                                                        Screen: "Account",
                                                    });
                                                    setDateRange((ps) => ({
                                                        ...ps,
                                                        startDate: d,
                                                    }));
                                                }}
                                                label={"From"}
                                                setToggler={setTogglerStart}
                                                toggler={togglerStart}
                                                readOnly={true}
                                            />
                                        </div>
                                        <span
                                            style={{
                                                marginLeft: -10,
                                                zIndex: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            onClick={() => {
                                                setTogglerStart(true);
                                            }}
                                        >
                                            <SVGIcon
                                                src={"/svg/calendar-icon.svg"}
                                                size={18}
                                                style={{
                                                    marginLeft: -5,
                                                    zIndex: 10,
                                                }}
                                            />
                                        </span>
                                    </div>
                                </ClickAwayListener>
                                <span style={{ fontWeight: 500, fontSize: 18 }}>
                                    -
                                </span>
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setTogglerEnd(false);
                                    }}
                                >
                                    <div className={styles.dateSelectWrapper}>
                                        <div className={styles.dateLabel}>
                                            To
                                        </div>

                                        <div
                                            className={styles._date}
                                            onClick={() => {
                                                setTogglerEnd(true);
                                            }}
                                        >
                                            <CustomDatePicker
                                                date={dateRange?.endDate}
                                                dateFormat={"dd MMMM yyyy"}
                                                setDate={(d) => {
                                                    mixpanel.track("Filters", {
                                                        Type: "Start Date",
                                                        Screen: "Account",
                                                    });
                                                    setDateRange((ps) => ({
                                                        ...ps,
                                                        endDate: d,
                                                    }));
                                                }}
                                                label={"To"}
                                                setToggler={setTogglerEnd}
                                                toggler={togglerEnd}
                                                readOnly={true}
                                            />
                                            <span
                                                style={{
                                                    marginLeft: -10,
                                                    zIndex: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    setTogglerEnd(true);
                                                }}
                                            >
                                                <SVGIcon
                                                    src={
                                                        "/svg/calendar-icon.svg"
                                                    }
                                                    size={18}
                                                    style={{
                                                        marginLeft: -5,
                                                        zIndex: 10,
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>
                        <div className={styles.filters_tabs_wrapper}>
                            <div className={styles.filters}>
                                <Input
                                    type="search"
                                    disableUnderline
                                    className={styles.searchBar}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <BiSearch
                                                color="#ACB3BF"
                                                size={20}
                                            />
                                        </InputAdornment>
                                    }
                                    placeholder="Search page URL"
                                    onChange={(e) => debouncedSetFilters(e)}
                                />
                            </div>
                        </div>
                        {loading || dataLoadShimmer ? (
                            <HistoryLoader />
                        ) : allData?.length ? (
                            <>
                                <div className={styles.logsTableHeader}>
                                    <div className={styles.headCheck}></div>
                                    <div className={styles.headSourceUrl}>
                                        Source URL
                                    </div>
                                    <div className={styles.headAnchor}>
                                        Anchor text content
                                    </div>
                                    <div className={styles.headTargetUrl}>
                                        Target URL
                                    </div>
                                    <div className={styles.headDate}>Date</div>
                                    <div className={styles.headIssueTag}></div>
                                </div>
                                <div className={styles.logsDataWrapper}>
                                    {data?.length ? (
                                        data.map((item, i) => (
                                            <>
                                                <div
                                                    className={
                                                        styles.rowsWrapper
                                                    }
                                                >
                                                    {item.fixes?.map(
                                                        (fix, index) => {
                                                            return (
                                                                <LinkingRow
                                                                    fix={fix}
                                                                    key={
                                                                        fix.fix_id
                                                                    }
                                                                    fixId={
                                                                        fix.fix_id
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                    setData={
                                                                        setData
                                                                    }
                                                                    categoryIndex={
                                                                        i
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </>
                                        ))
                                    ) : (
                                        <NoResultFound />
                                    )}
                                    {numberOfUrlPages > 1 && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                margin: "10px 0px",
                                            }}
                                        >
                                            <LocalPagination
                                                pageCount={numberOfUrlPages}
                                                currentPage={currentUrlPage}
                                                onPageChange={
                                                    handleUrlPageChange
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className={styles.logsEmpty}>
                                <SVGIcon
                                    src={"/New UI/SVG/webtuneLogsEmpty.svg"}
                                    style={{ margin: "auto" }}
                                />
                                <p>
                                    No logs found for{" "}
                                    <span className={styles.blue}>
                                        {" "}
                                        {domainInfo?.domain}
                                    </span>
                                    . You will see all your historical fixes on
                                    this screen once you start pushing them.
                                </p>
                            </div>
                        )}
                        <div className={styles.historyBottom}>
                            <span className={styles.bottomText}>
                                {getSelectedFixes()} changes selected
                            </span>
                            <Button
                                Icon={() => (
                                    <IoReturnUpBack
                                        style={{ marginRight: "10px" }}
                                        color="#fff"
                                        size={18}
                                    />
                                )}
                                style={{
                                    fontWeight: 400,
                                }}
                                iconReverse={true}
                                width={160}
                                text="Revert"
                                handler={() => {
                                    setRevertModal(true);
                                    // revertRequest();
                                }}
                                loading={reverting}
                                disabled={!getSelectedFixes()}
                            />
                        </div>
                        {revertModal && (
                            <Modal setModal={setRevertModal}>
                                <div className={styles.logoutContainer}>
                                    <h2 style={{ fontWeight: "600" }}>
                                        Are You Sure?
                                    </h2>
                                    <div>
                                        Reverting changes will remove the
                                        selected changes from your domain. Are
                                        you sure?
                                    </div>
                                    <div className={styles.logoutBtns}>
                                        <Button
                                            handler={() =>
                                                setRevertModal(false)
                                            }
                                            text="Cancel"
                                            width={150}
                                            secondary={true}
                                            style={{
                                                fontWeight:
                                                    "var(--font-weight3)",
                                            }}
                                        />
                                        <Button
                                            handler={() => {
                                                revertRequestLinking();
                                            }}
                                            text="Revert"
                                            width={150}
                                            loading={reverting}
                                            style={{
                                                fontWeight:
                                                    "var(--font-weight3)",
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </>
                ) : addOnTerminated ? (
                    <div className={styles2.noSubsModal}>
                        <LightTooltip placement="right" arrow title="Logout">
                            <div
                                className={styles2.closeCta}
                                onClick={() => {
                                    goTo("DASHBOARD");
                                }}
                            >
                                <VscClose size={20} color="#6b6b6b" />
                            </div>
                        </LightTooltip>
                        <div className={styles2.noSubsModalTitle}>
                            Addon Terminated
                        </div>
                        <SVGIcon src={"/New UI/SVG/warning_big.svg"} />
                        <div className={styles2.noSubsMeta}>
                            <div className={styles2.noSubsMeta_line1}>
                                Your Addon has been terminated!
                            </div>
                            <div className={styles2.noSubsMeta_line2}>
                                No Action is allowed at this time.
                            </div>
                        </div>
                        <div className={styles2.noSubsBottom}>
                            <div className={styles2.noSubsCtaTitle}>
                                To Reactivate
                            </div>
                            <Button
                                text={"Buy Add-on"}
                                height={34}
                                style={{
                                    borderRadius: 20,
                                    padding: "2px 14px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                                handler={(e) => {
                                    e.preventDefault();
                                    goTo("BILLING_PLANS", [], "/addon");
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "80%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "20px",
                            fontWeight: 500,
                        }}
                    >
                        No Domain Connected
                    </div>
                )}
            </div>
        </div>
    );
}

const Row = ({ fix, setData, index, categoryIndex }) => {
    const [open, setOpen] = useState(false);

    const getUI = (action) => {
        switch (action.action) {
            case "ADD":
                return (
                    <>
                        <li>
                            <BiCheck size={16} /> &nbsp; Add new tag
                        </li>
                        <div>{action.value}</div>
                    </>
                );

            case "UPDATE_VALUE":
                return (
                    <>
                        <li>
                            <BiCheck size={16} /> &nbsp; Update value to
                        </li>
                        <div>{action.value}</div>
                    </>
                );
            case "OPEN_IN_NEW_TAB":
                return (
                    <>
                        <div>
                            {action.value ? (
                                <BiCheck style={{ minWidth: 20 }} size={20} />
                            ) : (
                                <MdClose style={{ minWidth: 18 }} size={18} />
                            )}
                            &nbsp;{action.link ? action.link : ""}
                            <Frequency frequency={action.frequency} />
                        </div>
                    </>
                );
            case "CHOOSE_FROM":
                return (
                    <>
                        <li>
                            <BiCheck size={16} /> &nbsp; Update value to
                        </li>

                        {action.options.map((opt) => (
                            <div key={opt.value}>
                                <input
                                    type="radio"
                                    defaultChecked={action.value == opt.value}
                                    disabled={true}
                                />
                                {opt.value}
                            </div>
                        ))}
                    </>
                );
            case "INSIDE_HEAD":
                return (
                    <li>
                        <BiCheck color="#014dc5" size={20} />
                        &nbsp; Put inside head tag
                    </li>
                );
            case "ALT_MISSING":
                return (
                    <li>
                        <BiCheck color="#014dc5" size={20} />
                        &nbsp; Generated Alt Text
                    </li>
                );
            default:
                break;
        }
    };

    const classes = useAccordionCompleteStyle();
    return (
        <Accordion
            onChange={(e, expanded) => setOpen(expanded)}
            classes={classes}
        >
            <AccordionSummary
                style={{ cursor: "pointer" }}
                expandIcon={<IoChevronDown size={16} />}
            >
                <div
                    style={{ opacity: fix.status === 2 ? 0.7 : 1 }}
                    className={styles.row}
                >
                    <div>
                        <Checkbox
                            // color={"primary"}
                            style={{ height: 20, width: 20 }}
                            size="small"
                            checked={fix.checked}
                            disabled={fix.status === 2}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (e.target.checked)
                                    setData((ps) => {
                                        const newArr = [...ps];

                                        newArr[categoryIndex].fixes[index] = {
                                            ...fix,
                                            checked: true,
                                        };

                                        return newArr;
                                    });
                                else {
                                    setData((ps) => {
                                        const newArr = [...ps];

                                        newArr[categoryIndex].fixes[index] = {
                                            ...fix,
                                            checked: false,
                                        };

                                        return newArr;
                                    });
                                }
                            }}
                        />
                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={fix?.meta_data?.page}
                        >
                            <a
                                style={{
                                    background: "rgba(1, 77, 197, 0.1)",
                                    padding: "4px 10px",
                                    borderRadius: "4px",
                                }}
                                target="_blank"
                                href={new URL(fix?.meta_data?.page)}
                                rel="noreferrer"
                            >
                                {new URL(fix?.meta_data?.page).pathname.length >
                                25
                                    ? `${new URL(
                                          fix?.meta_data?.page,
                                      ).pathname.slice(0, 25)}...`
                                    : new URL(fix?.meta_data?.page).pathname ||
                                      "-"}
                            </a>
                        </LightTooltip>
                    </div>
                    <span>{categoryMap[fix.meta_data.category].title}</span>
                    {fix.status !== 2 ? (
                        <div style={{ color: "#ACB3BF" }}>
                            <IssueTag tag={fix.meta_data.issueTags[0]} />
                            {fix.meta_data.issueTags.length > 1
                                ? `+ ${fix.meta_data.issueTags.length - 1} more`
                                : ""}
                        </div>
                    ) : (
                        <div style={{ color: "#ACB3BF" }}>
                            <IssueTag tag={{ title: "Reverted" }} />
                        </div>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.detailRow}>
                    <span>Date - Time:</span>

                    <div className={styles.dateTime}>
                        <span>
                            {moment(fix.created_at).format("DD/MM/YYYY")}
                        </span>

                        <span>{moment(fix.created_at).format("hh:mm A")}</span>
                    </div>
                </div>

                <div className={styles.detailRow}>
                    <span>Issues:</span>

                    <div>
                        {fix.meta_data.issueTags.map((tag) => (
                            <IssueTag key={tag.key} tag={tag} />
                        ))}
                    </div>
                </div>
                <div className={styles.detailRow}>
                    <span>Action Taken</span>

                    <ul>
                        {fix.fix.map((action, i) => {
                            return (
                                <>
                                    {action.action === "OPEN_IN_NEW_TAB" &&
                                        i === 0 && (
                                            <li>
                                                <BiCheck size={20} />
                                                &nbsp; Enable link to open in
                                                new tab
                                            </li>
                                        )}
                                    {action.action === "ALT_MISSING" &&
                                        i === 0 && (
                                            <li>
                                                <BiCheck size={20} />
                                                &nbsp; Generated alt text for{" "}
                                                {fix?.fix?.length || 1}{" "}
                                                {fix?.fix?.length > 1
                                                    ? "images"
                                                    : "image"}
                                            </li>
                                        )}
                                    {action.action !== "ALT_MISSING" &&
                                        getUI(action)}
                                </>
                            );
                        })}
                    </ul>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

const LinkingRow = ({ fix, fixId, categoryIndex, setData, index }) => {
    const [open, setOpen] = useState(false);
    const classes = useAccordionCompleteStyle();
    const [expand, setExpand] = useState(false);

    return (
        <div
            style={{ opacity: fix.status === 2 ? 0.7 : 1 }}
            className={styles.row}
        >
            <div className={styles.rowCheck}>
                <Checkbox
                    // color={"primary"}
                    style={{ height: 20, width: 20 }}
                    size="small"
                    checked={fix.checked}
                    disabled={fix.status === 2}
                    onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked)
                            setData((ps) => {
                                const newArr = [...ps];

                                newArr[categoryIndex].fixes[index] = {
                                    ...fix,
                                    checked: true,
                                };

                                return newArr;
                            });
                        else {
                            setData((ps) => {
                                const newArr = [...ps];

                                newArr[categoryIndex].fixes[index] = {
                                    ...fix,
                                    checked: false,
                                };

                                return newArr;
                            });
                        }
                    }}
                />
            </div>
            <div className={styles.rowSourceUrl}>
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={fix?.meta_data?.page_url}
                >
                    <a
                        style={{
                            background: "rgba(1, 77, 197, 0.1)",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            display: "inline-block",
                            maxWidth: "100%",
                            width: "max-content",
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                        target="_blank"
                        href={new URL(fix?.meta_data?.page_url)}
                        rel="noreferrer"
                    >
                        {new URL(fix?.meta_data?.page_url).pathname || "-"}
                    </a>
                </LightTooltip>
            </div>
            <div className={styles.rowAnchor}>
                {!!fix.paras.length && (
                    <>
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <BiCheck color="#014dc5" size={20} />
                            &nbsp; Selected Paragraph
                        </span>
                        <div className={styles.recommendationCheck}>
                            <Paragraph
                                suggestion={{
                                    ...fix.paras[0],
                                    paragraph_text: fix.paras[0].para,
                                    keyword: fix.fix.keyword,
                                    recommendation: fix.fix.recommendation,
                                }}
                                key={fix.paras[0].fix_id}
                            />
                        </div>
                        {fix.paras.length > 1 ? (
                            <>
                                <Accordion
                                    expanded={expand}
                                    onChange={(_, e) => setExpand(e)}
                                    classes={classes}
                                >
                                    <AccordionSummary>
                                        <Button
                                            text={
                                                expand
                                                    ? "hide"
                                                    : `+${
                                                          fix.paras.length - 1
                                                      } more suggestions`
                                            }
                                            Icon={
                                                expand
                                                    ? FiChevronUp
                                                    : FiChevronDown
                                            }
                                            style={{
                                                color: "rgba(1, 77, 197, 1)",
                                                background: "transparent",
                                                fontSize: 13,
                                                width: "max-content",
                                                height: 20,
                                                marginLeft: "10px",
                                            }}
                                            handler={() => setExpand(!expand)}
                                        ></Button>
                                    </AccordionSummary>
                                    {expand && (
                                        <AccordionDetails>
                                            {fix.paras.slice(1).map((p) => (
                                                <div
                                                    className={
                                                        styles.recommendationCheck
                                                    }
                                                >
                                                    <Paragraph
                                                        suggestion={{
                                                            ...p,
                                                            paragraph_text:
                                                                p.para,
                                                            keyword:
                                                                fix.fix.keyword,
                                                            recommendation:
                                                                fix.fix
                                                                    .recommendation,
                                                        }}
                                                        key={p.fix_id}
                                                    />
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    )}
                                </Accordion>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </div>
            <div className={styles.rowTargetUrl}>
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={fix.fix.recommendation}
                >
                    <a
                        style={{
                            background: "rgba(1, 77, 197, 0.1)",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            display: "inline-block",
                            maxWidth: "100%",
                            width: "max-content",
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                        target="_blank"
                        href={fix.fix.recommendation}
                        rel="noreferrer"
                    >
                        {new URL(fix.fix.recommendation)?.pathname == "/"
                            ? new URL(fix.fix.recommendation)?.hostname
                            : new URL(fix.fix.recommendation)?.pathname || "-"}
                    </a>
                </LightTooltip>
            </div>
            <div className={styles.rowDate}>
                <div className={styles.dateTime}>
                    <span>{moment(fix.created_at).format("DD/MM/YYYY")}</span>
                    {/* <span>{moment(fix.created_at).format("hh:mm A")}</span> */}
                </div>
            </div>
            <div className={styles.rowIssueTag}>
                {fix.status !== 2 ? (
                    <></>
                ) : (
                    <div style={{ color: "#ACB3BF" }}>
                        <IssueTag tag={{ title: "Reverted" }} />
                    </div>
                )}
            </div>
        </div>
    );
};

const HistoryLoader = () => {
    return (
        <>
            {[1, 1, 1, 1, 1, 1].map((i, idx) => (
                <Skeleton key={i + idx} height={50} />
            ))}
        </>
    );
};

export default HistoryWindow;
