import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../atoms/Button/Button";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import Label from "../../atoms/Label/Label";
import Modal from "../../atoms/Modal/Modal";
import TextArea from "../../atoms/TextArea/TextArea";
import useHelperFunctions from "../../helperFunctions";
import styles from "./styles.module.css";
const Questions = [
  {
    ques: "Why did you uninstall the extension?",
    opts: [
      "The chrome extension got in the way",
      "The outputs were not good",
      "It was too confusing to use",
      "To use app.scalenut.com instead",
      "There was a technical issue (bug or glitch)",
      "The chrome extension slowed down my browser",
      "Not of any use for me",
      "Other",
    ],
    type: "radio",
  },
  {
    ques: "How can we improve the Scalenut chrome extension?",
    opts: [],
    type: "text",
  },
];

function ChromeExtFeedbackPopup() {
  const [other, setOther] = useState(false);
  const [visible, setVisible] = useState(false);
  const { postData } = useHelperFunctions();
  const goTo = useNavigation();

  const sendFeedback = (data) => {
    postData({
      url: "/api/subscriptionChurn/createChurnInfo",
      payload: data,
    });

    setVisible(false);
  };

  useEffect(() => {
    if (window.location.href.includes("extension_feedback")) {
      setVisible(true);
      goTo("DASHBOARD", [], "", true);
    }
  }, []);

  return visible ? (
    <Modal setModal={() => {}}>
      <div className={styles.chromeModal}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const form = new FormData(e.target);

            const data = { type: 2, plan_id: -1 };
            const formDataObj = {};
            for (const [key, value] of form) {
              formDataObj[key] = value;
            }

            if (other) {
              data.options = `other - ${formDataObj.other}`;
            } else {
              data.options = formDataObj.options;
            }

            data.feedback = formDataObj.feedback;

            sendFeedback(data);
          }}
          className={styles.questions}
        >
          {Questions.map((question) =>
            question.type == "radio" ? (
              <>
                <ul>
                  <h3>{question.ques}</h3>

                  {question.opts.map((opt) => (
                    <li>
                      <input
                        onChange={(e) => {
                          e.target.value == "Other" && e.target.checked
                            ? setOther(true)
                            : setOther(false);
                        }}
                        value={opt}
                        required
                        type="radio"
                        name={"options"}
                      />{" "}
                      <Label color={"#3f5575"} size={14} label={opt}></Label>
                    </li>
                  ))}
                </ul>
                {other ? (
                  <TextArea
                    props={{ required: true, name: "other" }}
                    label={""}
                    type="text"
                    showLabel={false}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <h3>{question.ques}</h3>
                <TextArea
                  showLabel={false}
                  props={{ required: false, name: "feedback" }}
                  label={""}
                  type="text"
                />
              </>
            )
          )}
          <br />
          <br />
          <Button handler={() => {}} text="Submit"></Button>
        </form>
      </div>
    </Modal>
  ) : (
    <></>
  );
}

export default ChromeExtFeedbackPopup;
