import { Input, TextareaAutosize } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "../../componentsV2/atoms/Button/Button";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import CopyText from "../../componentsV2/organisms/Research/CopyText";
import { getShortDateTime } from "../../utility/validateFunctions";
import styles from "./SurveyForm.module.css";
import Modal from "../../componentsV2/atoms/Modal/Modal";

function SurveyForm({ setActiveAction }) {
    const [formName, setFormName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [jsonData, setJsonData] = useState("");
    const { postData } = useHelperFunctions();
    const [currentState, setCurrentState] = useState(1);
    const [surveyLink, setSurveyLink] = useState("");
    const [description, setDescription] = useState("");
    const [history, setHistory] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [detailedResponse, setDetailedResponse] = useState({});
    const { getData } = useHelperFunctions();
    const onchangeFileInput = function (event) {
        let input = event.target;
        let reader = new FileReader();
        reader.onload = function () {
            let fileData = reader.result;
            let wb = XLSX.read(fileData, { type: "binary" });

            wb.SheetNames.forEach(function (sheetName) {
                let rowObj = XLSX.utils.sheet_to_row_object_array(
                    wb.Sheets[sheetName],
                );
                let jsonObj = JSON.stringify(rowObj);
                setJsonData(jsonObj);
            });
        };
        reader.readAsBinaryString(input.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const res = await postData({
            url: "/api/survey/create-form",
            payload: {
                surveyName: formName,
                surveyInfo: JSON.parse(jsonData),
                description,
            },
        });
        setCurrentState(2);
        setJsonData("");
        setFormName("");
        setSurveyLink(`https://survey.scalenut.com/form/${res?.data.form_id}`);
    };

    const againNewForm = () => {
        setCurrentState(1);
    };

    const DetailsModal = () => {
        const [filterTerm, setFilterTerm] = useState("");
        const isInFilter = (item, value) => {
            if (value == "") return true;
            if (item.trim().toLowerCase().includes(value.trim().toLowerCase()))
                return true;
            return false;
        };
        return (
            <div
                style={{
                    backgroundColor: "white",
                    padding: 20,
                    borderRadius: 10,
                    width: 700,
                    height: 600,
                    position: "relative",
                    overflowY: "auto",
                }}
            >
                <Input
                    type="text"
                    placeholder="Search by email"
                    onChange={(e) => {
                        setFilterTerm(e.target.value);
                    }}
                    id="input"
                    style={{
                        position: "absolute",
                        top: 5,
                        left: 20,
                        width: 300,
                        margin: "5px 10px",
                    }}
                />
                <div
                    style={{
                        backgroundColor: "white",
                        padding: 10,
                        height: 500,
                        overflowY: "auto",
                        marginTop: 30,
                    }}
                >
                    <table
                        style={{ width: "100%", padding: 10 }}
                        className={styles.historyTable}
                    >
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Results</th>
                        </tr>

                        {detailedResponse.data
                            .filter((i) => isInFilter(i.email, filterTerm))
                            .map((item) => (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {item.response_count.map((item) => {
                                            return (
                                                <div>
                                                    <span>
                                                        {item.competitor_name}
                                                    </span>{" "}
                                                    -{item.total_response}
                                                </div>
                                            );
                                        })}
                                    </td>
                                </tr>
                            ))}
                    </table>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const getSurveyHistory = async () => {
            const res = await getData({ url: "/api/survey/form/all-forms" });

            console.log(res.data, "FORMS");
            setHistory(res.data.data);
        };

        getSurveyHistory();
    }, []);
    const getPercentage = (self, arr) => {
        let total = arr.reduce((prev, a) => prev + a.total_response, 0);
        console.log(total, "TOTal");
        if (total == 0) return 0;
        return ((self / total) * 100).toFixed(2);
    };

    const copyLink = (formId) => {};

    const showResponseDetails = async (formId) => {
        const res = await getData({
            url: `/api/survey/form/user-response/${formId}`,
        });
        console.log(res.data.data);
        setDetailedResponse(res.data);
        setShowDetailsModal(true);
    };

    return (
        <div style={{ padding: 20 }}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>
            {currentState == 1 ? (
                <div
                    style={{
                        width: "50vw",
                        margin: "100px auto",
                        border: "1px solid #ababab",
                        borderRadius: 3,
                    }}
                >
                    <form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            padding: 20,
                            alignItems: "flex-start",
                        }}
                        onSubmit={onSubmit}
                    >
                        <Input
                            type="text"
                            value={formName}
                            onChange={(e) => setFormName(e.target.value)}
                            placeholder="Enter survey name"
                            required={true}
                            fullWidth
                        />

                        <TextareaAutosize
                            onChange={(e) => setDescription(e.target.value)}
                            required
                            placeholder="Enter your message to the reviewers"
                            rowsMin={3}
                            className={styles.textarea}
                        />

                        {/* <Input
            type="text"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            placeholder="SuperAdmin Email Id"
          /> */}

                        <Input
                            type="file"
                            placeholder="Please upload information file in .xlxs format"
                            onChange={onchangeFileInput}
                            id="input"
                            required={true}
                            fullWidth
                        />

                        <Button text="Create Survey" />
                    </form>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        padding: 20,
                        alignItems: "center",
                    }}
                >
                    <div>Below is the survey form link:</div>

                    <CopyText text={surveyLink}>
                        <div
                            style={{
                                padding: 10,
                                border: "1px solid #ababab",
                                borderRadius: 3,
                            }}
                        >
                            {surveyLink}
                        </div>
                    </CopyText>

                    <Button
                        handler={againNewForm}
                        text="Create Another Survey Form"
                    />
                </div>
            )}

            {/* <SurveyFormClient formId={1} /> */}
            <table
                style={{ width: "100%", padding: 10 }}
                className={styles.historyTable}
            >
                <tr>
                    <th>S.No.</th>
                    <th>Admin Email</th>
                    <th>Survey Name</th>
                    <th>Created At</th>
                    <th>Results</th>
                    <th>Actions</th>
                </tr>

                {history.map((his) => (
                    <tr key={his.id}>
                        <td>{his.id}</td>
                        <td>{his.created_by}</td>
                        <td>{his.survey_name}</td>
                        <td>
                            {/* {new Date(his.created_at).toLocaleString()}{" "} */}
                            {getShortDateTime(his.created_at)}
                        </td>
                        <td>
                            {his.response_count.map((item) => {
                                return (
                                    <div>
                                        <span>{item.competitor_name} - </span>
                                        {getPercentage(
                                            item.total_response,
                                            his.response_count,
                                        )}
                                        %<span> ({item.total_response})</span>
                                    </div>
                                );
                            })}

                            <div>
                                <span>Total - </span>
                                {his.response_count.reduce(
                                    (prev, a) => prev + a.total_response,
                                    0,
                                )}
                            </div>
                        </td>
                        <td>
                            <div
                                style={{
                                    display: "flex",
                                    padding: 10,
                                    gap: 10,
                                    flexDirection: "column",
                                }}
                            >
                                <CopyText
                                    text={`https://survey.scalenut.com/form/${his.form_id}`}
                                >
                                    <div
                                        className={styles.copyButton}
                                        onClick={copyLink}
                                    >
                                        Copy Link
                                    </div>
                                </CopyText>
                                <div
                                    className={styles.detailsButton}
                                    onClick={() =>
                                        showResponseDetails(his.form_id)
                                    }
                                >
                                    Response Details
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>

            {showDetailsModal && (
                <Modal setModal={setShowDetailsModal}>
                    <DetailsModal />
                </Modal>
            )}
        </div>
    );
}

export default SurveyForm;
