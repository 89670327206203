import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./AIHumanizer.module.css";
import SmartEditor from "../SmartEditor/SmartEditor";
import Quill from "quill";

import BetterTable from "../../organisms/TextEditor/QuillBetterTable/quill-better-table";
import "../../organisms/TextEditor/TextEditorSnow.css";
import Button from "../../atoms/Button/Button";
import { GoCodescanCheckmark } from "react-icons/go";
import { BsChevronRight, BsRobot } from "react-icons/bs";
import EditorHeader from "../../organisms/TextEditor/EditorHeader";
import EditorTitle from "../../organisms/Creator/CreatorCore/EditorTitle";
import MenuList from "../../organisms/MenuList/MenuList";
import { MdContentPaste } from "react-icons/md";
import SVGIcon from "../../atoms/SVGIcon";
import useAIHumanizerHook from "./useAIHumanizerHook";
import { useParams } from "react-router-dom";
import useHelperFunctions from "../../helperFunctions";
import qs from "qs";
import { Skeleton } from "@material-ui/lab";
import Progress from "../../atoms/Progress/Progress";
import Modal from "../../atoms/Modal/Modal";
import ReportContext from "../../organisms/Research/ReportContext";
import RatingHook from "../../molecules/Creator/Overview/RatingHook";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { UPDATE_PLAN } from "../../api.json";
import AIHumanizerMeter from "./AIHumanizerMeter";
import { EditorWordCounter } from "../../organisms/TextEditor/ReportEditor";
import { debounce } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import { BiCopy, BiSupport } from "react-icons/bi";
import CopyText from "../../organisms/Research/CopyText";
import { copyHtmlContent } from "../../../utility/helperFun";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import Showdown from "../../../lib/showdownmin";
import UserFeedback from "../../organisms/UserFeedback/UserFeedback";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import UpgradeModal from "./UpgradeModal";
import { HumanizerBuyMore } from "../../../components/subsCards/LmAddOnCard";
import { useDispatch, useSelector } from "react-redux";
import usePriceDiscounts from "../../templates/Dashboard/usePriceDiscounts";
import { paymentPopUpDimension } from "../../../components/subsCards/PriceCardBF";
import { ADD_ONS_TYPE, PLANS_CATEGORIES } from "../../../constants";
import { updateUserInfo } from "../../../store/actions/auth";
import useNavigation from "../../atoms/GoToRoute/useNavigation";

const Delta = Quill.import("delta");

Quill.register(
    {
        "modules/better-table": BetterTable,
    },
    true,
);

function AIHumanizerEditor({ scan_id, report_id }) {
    const {
        createScan,
        creatingScan,
        getHumanizeData,
        getAiScore,
        isScanning,
        isHumanizerScanning,
        openScan,
        updateName,
        errorModal,
        setErrorModal,
        showFeedback,
        setShowFeedback,
        buyMoreModal,
        setBuyMoreModal,
    } = useAIHumanizerHook(false);
    console.log(buyMoreModal, "buyMore");
    const [scanInfo, setScanInfo] = useState({});
    const [textEditor1, setTextEditor1] = useState(null);
    const [textEditor2, setTextEditor2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editor1Disable, setEditor1Disable] = useState(false);
    const [editor2Disable, setEditor2Disable] = useState(false);
    const [isToolbar1, setIsToolbar1] = useState(true);
    const [isToolbar2, setIsToolbar2] = useState(true);
    const [aiScore, setAiScore] = useState(0);
    const [humanizerAiScore, setHumanizerAiScore] = useState(0);
    const [isScan, setIsScan] = useState(false);
    const [isHumanizeScan, setIsHumanizeScan] = useState(false);
    const [humanizeLoading, setHumanizeLoading] = useState(false);
    const [newScanModal, setNewScanModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [userFeedback, setUserFeedback] = useState(true);
    const [upgradeModal, setUpgradeModal] = useState(false);
    const editorRef1 = useRef();
    const editorRef2 = useRef();
    const { documentId } = useParams();
    const { setReportData } = useContext(ReportContext);
    const { getData, postData } = useHelperFunctions();
    const [saving, setSaving] = useState(false);
    const { mixpanelTrack } = useMixpanelHook();
    const {
        isFreeForever,
        trialUser,
        subscriptionInfo,
        plan_type,
        isLTD,
        isTeamMember,
    } = useUserAccessHook();
    const currency = useSelector((state) => state.auth.currency);
    const { getDiscount, plans } = usePriceDiscounts();
    const dispatch = useDispatch();
    const goTo = useNavigation();

    const startHumanizer = async (skipUpdate = false) => {
        const res = await postData({ url: "/api/user/disable-humanizer" });
        if (res.status === 200) {
            if (!skipUpdate) {
                dispatch(updateUserInfo("show_humanizer_landing", 0));
            }
        }
    };

    async function createHumanizerStripeSession(quantity = 2) {
        let humanizerPlanId = plans.filter(
            (ele) => ele.plan_id == subscriptionInfo.plan_id,
        );
        if (!humanizerPlanId.length > 0) {
            humanizerPlanId = plans.filter(
                (ele) =>
                    ele.plan_category == subscriptionInfo.plan_category &&
                    ele.plan_type == subscriptionInfo.type,
            );
        }
        document.StripeInvoicePayment = window.open(
            "",
            "",
            paymentPopUpDimension,
        );

        document.StripeInvoicePayment.document.write(
            "<html><head></head><body>Please wait while we redirect you</body></html>",
        );
        const res = await postData({
            url: UPDATE_PLAN,
            payload: {
                planID: subscriptionInfo.plan_id,
                type: 1,
                from: subscriptionInfo.plan_id,
                quantity: 1,
                add_on_info: [
                    {
                        humanizer_purchased_quantity:
                            subscriptionInfo.plan_category ==
                                PLANS_CATEGORIES.PRO_MAX ||
                            subscriptionInfo.plan_category ==
                                PLANS_CATEGORIES.GROWTH_MAX
                                ? quantity - 2
                                : quantity,
                        humanizer_plan_id: JSON.parse(
                            humanizerPlanId[0]?.add_on_plan_ids,
                        )?.humanizer,
                        prev_humanizer_plan_id:
                            subscriptionInfo?.humanizer_plan_id || null,
                        add_on: ADD_ONS_TYPE.AI_HUMANIZER,
                    },
                ],
            },
        });
        if (res.status == 200) {
            document?.StripeInvoicePayment?.close();
            startHumanizer();
        } else if (res.status == 201) {
            dispatch(updateUserInfo("planDowngraded", false));
            startHumanizer();
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            goTo("DUMMY_TRANSACTION");
        } else {
            document?.StripeInvoicePayment?.close();
        }
    }

    const exportToEditor = (replace = false) => {
        if (replace) {
            document?.quill?.editor?.setContents(
                document.quill?.editor2?.getContents(),
            );
        } else {
            document?.quill?.editor?.setContents(
                document.quill?.editor
                    ?.getContents()
                    .concat(document.quill?.editor2?.getContents()),
            );
        }
        setReportData((ps) => ({
            ...ps,
            reportInfo: { ...ps.reportInfo, isHumanizer: false },
        }));
    };

    const onNameUpdate = (newName) => {
        setScanInfo((ps) => ({ ...ps, scan_name: newName }));
    };

    const setNewName = () => {
        if (scanInfo.scan_name === "Untitled") {
            const newName = textEditor1.getText().trim().substring(0, 50);
            if (newName.length > 10) {
                updateName({ id: documentId, name: newName + "..." });
                setScanInfo((ps) => ({ ...ps, scan_name: newName }));
            }
        }
    };

    const updateEditorData = async ({
        isHumanizer = false,
        createdDocId = null,
    }) => {
        if (!editorRef1.current || !editorRef2.current) return;

        if (!scan_id) {
            const editor1State = document.quill.editor1.isEnabled();
            const editor2State = document.quill.editor2.isEnabled();

            if (
                !createdDocId &&
                ((!isHumanizer && !editor1State) ||
                    (isHumanizer && !editor2State))
            ) {
                return;
            }
        }

        let str = "";
        let html = "";
        if (isHumanizer) {
            str = document.quill.editor2?.getText();
            html = document.quill.editor2?.getContents();
        } else {
            str = document.quill.editor1?.getText();
            html = document.quill.editor1?.getContents();
        }
        if (str.trim().length === 0) {
            setIsScan(false);
        }
        str = str?.replace(
            /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
            "",
        );
        const rating = await RatingHook(str, [], "seoScore");
        await postData({
            url: "/api/ai-humanizer/update",
            payload: {
                scan_id: createdDocId || scan_id || documentId,
                ...(isHumanizer && {
                    humanizer: {
                        text_content: str,
                        html_content: JSON.stringify(html),
                        word_count: rating.wordCount,
                    },
                }),
                ...(!isHumanizer && {
                    raw: {
                        text_content: str,
                        html_content: JSON.stringify(html),
                        word_count: rating.wordCount,
                    },
                }),
            },
        });
    };

    const getScanInfo = async () => {
        setLoading(true);

        const res = await getData({
            url: `/api/ai-humanizer/get-scan-info?scan_id=${
                scan_id ? scan_id : documentId
            }`,
        });
        setEditor2Disable(true);
        if (res.status == 200) {
            setScanInfo(res.data?.data);
            if (res.data?.data?.raw?.html_content) {
                let content = res.data?.data?.raw?.html_content;
                let data;
                if (content.startsWith("ops")) {
                    data = qs.parse(content).ops;

                    if (!Array.isArray(data)) {
                        data.length = Object.keys(data).length;
                        data = Array.from(data);
                    }
                } else {
                    data = JSON.parse(content).ops;
                }

                document.quill?.editor1?.setContents(new Delta(data), "silent");
                document.quill?.editor1?.history?.clear();
                if (
                    document.quill?.editor1?.getText().trim().length &&
                    res.data?.data?.raw?.ai_score !== null
                ) {
                    setAiScore(parseInt(res.data?.data?.raw?.ai_score * 100));
                    setIsScan(true);
                }
            } else {
                if (scan_id) {
                    document.quill?.editor1?.setContents(
                        document.quill?.editor?.getContents(),
                    );
                    updateEditorData({ isHumanizer: false });
                }
            }
            if (res.data?.data?.is_humanized) {
                if (res.data?.data?.humanized?.humanizer_html_content) {
                    setHumanizerAiScore(
                        parseInt(
                            res.data?.data?.humanized?.humanizer_ai_score * 100,
                        ),
                    );
                    setIsHumanizeScan(true);
                    let content =
                        res.data?.data?.humanized?.humanizer_html_content;
                    let data;
                    if (content.startsWith("ops")) {
                        data = qs.parse(content).ops;

                        if (!Array.isArray(data)) {
                            data.length = Object.keys(data).length;
                            data = Array.from(data);
                        }
                    } else {
                        data = JSON.parse(content).ops;
                    }
                    setEditor1Disable(true);
                    setEditor2Disable(false);

                    document.quill?.editor2?.setContents(
                        new Delta(data),
                        "silent",
                    );
                    document.quill?.editor2?.history?.clear();
                }
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        window.userengage && window.userengage("widget.hide");
        getScanInfo();
        return () => {
            window.userengage && window.userengage("widget.show");
        };
    }, []);

    useEffect(() => {
        if (showFeedback) {
            setTimeout(() => {
                setUserFeedback(false);
            }, 4000);
        }
    }, [showFeedback]);

    useEffect(() => {
        if (!editor1Disable) {
            if (isToolbar1) {
                document.getElementById("toolbar1").style.display = "flex";
            } else {
                document.getElementById("toolbar1").style.display = "none";
            }
        }
    }, [isToolbar1, editor1Disable]);

    useEffect(() => {
        if (!loading) {
            if (isToolbar2) {
                document.getElementById("toolbar2").style.display = "flex";
            } else {
                document.getElementById("toolbar2").style.display = "none";
            }
        }
    }, [isToolbar2, loading]);

    useEffect(() => {
        if (document.quill?.editor1) {
            if (editor1Disable || scan_id) {
                document.quill?.editor1?.disable();
            } else {
                document.quill?.editor1?.enable();
            }
        }
    }, [editor1Disable, textEditor1, scan_id]);

    useEffect(() => {
        if (document.quill?.editor2) {
            if (editor2Disable) {
                document.quill?.editor2?.disable();
                document.quill.editor2.root.style.display = "none";
            } else {
                document.quill?.editor2?.enable();
                document.quill.editor2.root.style.display = "block";
            }
        }
    }, [editor2Disable, textEditor2]);

    useEffect(() => {
        if (textEditor1) {
            textEditor1?.root?.setAttribute(
                "data-placeholder",
                "Please enter the text to scan or humanize here",
            );
            const toolbar1 = document.getElementsByClassName("ql-toolbar")[0];
            document.getElementById("toolbar1")?.appendChild(toolbar1);
        }
    }, [textEditor1]);

    useEffect(() => {
        // textEditor2?.root?.setAttribute(
        //     "data-placeholder",
        //     "Humanized content...",
        // );
        if (textEditor2) {
            const toolbar2 = document.getElementsByClassName("ql-toolbar")[1];
            document.getElementById("toolbar2")?.appendChild(toolbar2);
        }
    }, [textEditor2]);

    useEffect(() => {
        if (saving) setNewName();
    }, [saving]);

    useEffect(() => {
        if (editorRef1.current) {
            const updateData = debounce(async () => {
                setSaving(true);
                await updateEditorData({ isHumanizer: false });
                setSaving(false);
            }, 2000);

            const quill = new Quill(editorRef1.current, {
                theme: "snow",
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                    ],
                    table: false,
                    "better-table": {
                        tools: false,
                    },
                    keyboard: {
                        bindings: BetterTable.keyboardBindings,
                    },
                },
            });

            const tooltip = quill.theme.tooltip;
            const input = tooltip.root.querySelector("input[data-link]");
            input.dataset.link = "https://scalenut.com";
            document.quill = { ...document.quill, editor1: quill };
            setTextEditor1(quill);
            if (!editor1Disable) quill.on("editor-change", updateData);
        }
    }, [editorRef1]);
    useEffect(() => {
        if (editorRef2.current) {
            const updateData = debounce(() => {
                updateEditorData({ isHumanizer: true });
            }, 2000);

            const quill = new Quill(editorRef2.current, {
                theme: "snow",
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                    ],
                    table: false,
                    "better-table": {
                        tools: false,
                    },
                    keyboard: {
                        bindings: BetterTable.keyboardBindings,
                    },
                },
            });

            const tooltip = quill.theme.tooltip;
            const input = tooltip.root.querySelector("input[data-link]");
            input.dataset.link = "https://scalenut.com";
            document.quill = { ...document.quill, editor2: quill };
            setTextEditor2(quill);
            if (!editor2Disable) quill.on("editor-change", updateData);
        }
    }, [editorRef2]);

    const createDuplicateScan = async () => {
        const id = await createScan({
            name: "",
        });

        await updateEditorData({ isHumanizer: false, createdDocId: id });
        setNewScanModal(false);
        openScan(id);
    };

    return (
        <div className={styles.wrapper}>
            {loading && <Progress height={"100vh"} width={"100vw"} />}
            {!loading && !scan_id && (
                <>
                    <EditorHeader
                        left={
                            <MenuList
                                info={{
                                    attachedTo: "AI_HUMANIZER",
                                    docName: scanInfo.scan_name,
                                    title: scanInfo.scan_name,
                                    docId: scanInfo.scan_id,
                                    isShared: false,
                                    isSample: false,
                                }}
                                aiHumanizer={true}
                                editor1Disable={editor1Disable}
                            />
                        }
                        title={
                            <EditorTitle
                                access={true}
                                reportInfo={scanInfo}
                                // auditMode={auditMode}
                                isFixItEnabled={{}}
                                // setIsFixItEnabled={setIsFixItEnabled}
                                // setEnableFixItEditor={
                                //     setEnableFixItEditor
                                // }
                                // setEnableFixIt={setEnableFixIt}
                                // setMetaFixIt={setMetaFixIt}
                                aiHumanizer={true}
                                onUpdate={onNameUpdate}
                            />
                        }
                        aiHumanizer={true}
                        // auditMode={auditMode}
                        // isFixItEnabled={isFixItEnabled}
                    ></EditorHeader>
                    <div style={{ height: 10 }} />
                </>
            )}
            <div className={styles.editorContainer} id="snow-editor">
                <div className={styles.editorWrapper}>
                    <div
                        style={{
                            opacity:
                                editor1Disable || (scan_id && !editor2Disable)
                                    ? 0.7
                                    : 1,
                        }}
                        className={styles.editorName}
                    >
                        Original Text
                    </div>
                    <div
                        style={{
                            border:
                                editor1Disable || scan_id
                                    ? "2px solid lightgrey"
                                    : "2px solid #0F67EA33",
                            opacity: editor1Disable || scan_id ? 0.7 : 1,
                        }}
                        className={styles.editor1}
                        onClick={() => {
                            if (
                                (editor1Disable || scan_id) &&
                                !humanizeLoading
                            ) {
                                setNewScanModal(true);
                            }
                        }}
                    >
                        <div className={styles.editorScroll}>
                            <div className={styles.editorToolbar}>
                                <div id="toolbar1"></div>
                            </div>
                            <div ref={editorRef1}></div>
                        </div>
                        <_WordCounter editor={textEditor1} source={"editor1"} />
                    </div>
                    <div className={styles.actionBar}>
                        {isScan ? (
                            <>
                                <AIHumanizerMeter
                                    value={aiScore}
                                    loading={isScanning}
                                />
                            </>
                        ) : (
                            <div></div>
                        )}
                        {
                            <div style={{ display: "flex", gap: "10px" }}>
                                <LightTooltip
                                    arrow
                                    title={
                                        editor1Disable && !isScanning
                                            ? "You have already humanized the content. Please create a new document to scan again"
                                            : ""
                                    }
                                >
                                    <div>
                                        <Button
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                background: "#ffffff",
                                                border: "1px #014dc5 solid",
                                                color: "#014dc5",
                                                cursor:
                                                    humanizeLoading ||
                                                    editor1Disable
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                            height={40}
                                            width={100}
                                            loading={isScanning}
                                            text="Scan"
                                            disabled={
                                                editor1Disable ||
                                                humanizeLoading
                                            }
                                            handler={(e) => {
                                                e.stopPropagation();
                                                if (isFreeForever) {
                                                    setUpgradeModal(true);
                                                    return;
                                                }
                                                if (humanizeLoading) return;
                                                getAiScore(
                                                    scan_id
                                                        ? scan_id
                                                        : documentId,
                                                    false,
                                                    setAiScore,
                                                    setIsScan,
                                                );
                                                mixpanelTrack("AI Humanizer", {
                                                    action: `Scan(Original Text)`,
                                                });
                                            }}
                                        />
                                    </div>
                                </LightTooltip>
                                <LightTooltip
                                    arrow
                                    title={
                                        editor1Disable && !humanizeLoading
                                            ? "You have already humanized the content. Please create a new document to humanize again"
                                            : ""
                                    }
                                >
                                    <div>
                                        <Button
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                background: "#014dc5",
                                                color: "#ffffff",
                                                cursor: editor1Disable
                                                    ? "not-allowed"
                                                    : "pointer",
                                            }}
                                            loading={humanizeLoading}
                                            height={40}
                                            width={120}
                                            text="Humanize"
                                            disabled={
                                                editor1Disable || isScanning
                                            }
                                            handler={(e) => {
                                                e.stopPropagation();
                                                if (isFreeForever) {
                                                    setUpgradeModal(true);
                                                    return;
                                                }
                                                getHumanizeData(
                                                    scan_id
                                                        ? scan_id
                                                        : documentId,
                                                    setEditor1Disable,
                                                    setEditor2Disable,
                                                    setHumanizeLoading,
                                                    setHumanizerAiScore,
                                                    setIsHumanizeScan,
                                                    report_id,
                                                );
                                                mixpanelTrack("AI Humanizer", {
                                                    action: `Humanize`,
                                                });
                                            }}
                                        />
                                    </div>
                                </LightTooltip>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.editorWrapper}>
                    <div
                        style={{
                            opacity: editor2Disable ? 0.7 : 1,
                        }}
                        className={styles.editorName}
                    >
                        Humanized Text
                    </div>
                    <div
                        style={{
                            border: editor2Disable
                                ? "2px solid lightgrey"
                                : "2px solid #0F67EA33",
                            opacity: editor2Disable ? 0.7 : 1,
                        }}
                        className={styles.editor2}
                    >
                        {editorRef2 && (loading || humanizeLoading) && (
                            <div
                                style={{
                                    width: "calc(100% - 4px)",
                                    position: "absolute",
                                    top: 30,
                                    zIndex: 10,
                                    height: "calc(100% - 120px)",
                                    background: "#fff",
                                    borderRadius: "8px",
                                    paddingInline: "10px",
                                }}
                            >
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                            </div>
                        )}
                        <div className={styles.editorScroll}>
                            <div
                                className={styles.editorToolbar}
                                style={{
                                    justifyContent: "space-between",
                                    marginRight: 20,
                                }}
                            >
                                <div
                                    className={styles.formatBtn}
                                    style={{
                                        padding: isToolbar2 ? "0px" : "9px",
                                        gap: isToolbar2 ? "0px" : "10px",
                                        cursor: editor2Disable
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                >
                                    <div id="toolbar2"></div>
                                </div>
                                {!editor2Disable && (
                                    <CopyText
                                        onCopy={() => {
                                            copyHtmlContent(textEditor2.root);
                                            mixpanelTrack("AI Humanizer", {
                                                action: "Click to copy(Humanizer)",
                                            });
                                        }}
                                    >
                                        <SVGIcon src="/New UI/SVG/copy.svg" />
                                    </CopyText>
                                )}
                            </div>
                            <div ref={editorRef2}></div>
                        </div>
                        {!editor2Disable && (
                            <_WordCounter
                                editor={textEditor2}
                                source={"editor2"}
                            />
                        )}
                    </div>
                    <div className={styles.actionBar}>
                        {!editor2Disable && isHumanizeScan ? (
                            <AIHumanizerMeter
                                value={humanizerAiScore}
                                loading={isHumanizerScanning}
                            />
                        ) : (
                            <div></div>
                        )}
                        {!editor2Disable && (
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Button
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        background: "#ffffff",
                                        border: "1px #014dc5 solid",
                                        color: "#014dc5",
                                    }}
                                    height={40}
                                    width={100}
                                    text="Scan"
                                    loading={isHumanizerScanning}
                                    handler={() => {
                                        if (isFreeForever) {
                                            setUpgradeModal(true);
                                            return;
                                        }
                                        getAiScore(
                                            scan_id ? scan_id : documentId,
                                            true,
                                            setHumanizerAiScore,
                                            setIsHumanizeScan,
                                        );
                                        mixpanelTrack("AI Humanizer", {
                                            action: `Scan(Humanize Text)`,
                                        });
                                    }}
                                />
                                {scan_id && (
                                    <Button
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            // background: "#014dc51a",
                                            // color: "#014dc5",
                                        }}
                                        height={40}
                                        width={150}
                                        text="Export to Editor"
                                        handler={() => {
                                            setExportModal(true);
                                            mixpanelTrack("AI Humanizer", {
                                                action: `Export to Editor`,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {exportModal && (
                <Modal setModal={setExportModal}>
                    <div className={styles.doubleCheckModal}>
                        <span className={styles.dcmCross}>
                            <SVGIcon
                                onClick={() => setExportModal(false)}
                                src="/New UI/SVG/cross.svg"
                            ></SVGIcon>
                        </span>
                        <h3 className={styles.dcmTaglines}>
                            <>
                                <span className={styles.second}>
                                    Would you like to append the humanized
                                    content or
                                    <br />
                                    replace the existing one?
                                </span>
                            </>
                        </h3>
                        <div className={styles.dcmBtns}>
                            <Button
                                text={"Append"}
                                handler={() => {
                                    exportToEditor();
                                    mixpanelTrack("AI Humanizer", {
                                        action: `Append(export to editor)`,
                                    });
                                }}
                                borderRadius="3"
                                width={130}
                                height={35}
                                style={{
                                    borderRadius: 3,
                                    backgroundColor: "white",
                                    fontSize: 12,
                                }}
                                customClass={styles.dcmBtn1}
                            />
                            <Button
                                text={"Replace"}
                                handler={() => {
                                    exportToEditor(true);
                                    mixpanelTrack("AI Humanizer", {
                                        action: `Replace(export to editor)`,
                                    });
                                }}
                                borderRadius="3"
                                width={130}
                                height={35}
                                style={{
                                    borderRadius: 3,
                                    backgroundColor: "#1f4cbe",
                                    fontSize: 12,
                                }}
                                customClass={styles.dcmBtn}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {newScanModal && (
                <Modal setModal={setNewScanModal}>
                    <div
                        style={scan_id ? { width: "450px" } : {}}
                        className={styles.newScanModal}
                    >
                        <h2>
                            {scan_id
                                ? "Editing Original Content"
                                : "New Document"}
                        </h2>
                        <div style={{ marginBottom: "20px" }}>
                            {scan_id
                                ? "Editing the original content is not allowed in Detect and Humanize mode. Use the main editor to make changes."
                                : "Editing original content will create a new scan document"}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "10px",
                            }}
                        >
                            <Button
                                handler={() => setNewScanModal(false)}
                                secondary={true}
                                text="Cancel"
                            />
                            <Button
                                handler={() => {
                                    scan_id
                                        ? setReportData((ps) => ({
                                              ...ps,
                                              reportInfo: {
                                                  ...ps.reportInfo,
                                                  isHumanizer: false,
                                              },
                                          }))
                                        : createDuplicateScan();
                                }}
                                loading={creatingScan}
                                text={
                                    scan_id ? "Back to Editor" : "Yes Proceed"
                                }
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {errorModal && (
                <Modal setModal={setErrorModal}>
                    <div className={styles.errorModalWrapper}>
                        <VscClose
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                            }}
                            size={16}
                            onClick={() => {
                                setErrorModal(false);
                            }}
                        />
                        <div>
                            Sorry, we couldn’t humanize the content. Content may
                            already be human-like or too narrow. Please try
                            adding more content.
                            <br />
                            <br />
                            <span style={{ fontWeight: 500 }}>
                                No credits have been deducted for this attempt.
                            </span>
                        </div>
                        <Button
                            handler={() => {
                                setErrorModal(false);
                            }}
                            width={100}
                            text="Okay"
                        />
                    </div>
                </Modal>
            )}
            {!userFeedback && (
                <UserFeedback
                    setClose={setUserFeedback}
                    section={"aiHumanizer"}
                    topic_id={scan_id || documentId}
                />
            )}
            {upgradeModal && <UpgradeModal setUpgradeModal={setUpgradeModal} />}
            {buyMoreModal && (
                <>
                    {isLTD || isTeamMember ? (
                        <Modal>
                            <div className={styles.buyMoreModalWrapper}>
                                <VscClose
                                    style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setBuyMoreModal(false)}
                                    size={16}
                                />
                                <img
                                    src="/AssetsV2/plan-exceeded-icon.svg"
                                    alt=""
                                />
                                <div>
                                    Your monthly quota for Detect and Humanize
                                    <br />
                                    has been exhausted!
                                    {isTeamMember
                                        ? " Please contact your admin."
                                        : ""}
                                </div>
                                <Button
                                    text="Talk to Support"
                                    handler={() => {
                                        window.userengage &&
                                            window.userengage("widget.show");
                                        window.userengage &&
                                            window.userengage("widget.open");
                                        setBuyMoreModal(false);
                                    }}
                                    Icon={BiSupport}
                                    iconReverse={true}
                                    style={{
                                        width: "170px",
                                        height: "40px",
                                        borderRadius: 3,
                                        backgroundColor:
                                            "rgba(1, 77, 197, 0.1)",
                                        color: "#014dc5",
                                    }}
                                ></Button>
                            </div>
                        </Modal>
                    ) : trialUser || isFreeForever ? (
                        <Modal>
                            <div className={styles.buyMoreModalWrapper}>
                                <div>
                                    Your monthly free quota has been exhausted.
                                    <br />
                                    Buy a plan to get extra Detect and Humanize
                                    credits
                                </div>
                                <VscClose
                                    style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setBuyMoreModal(false)}
                                    size={16}
                                />
                                <Button
                                    width={120}
                                    handler={() => {
                                        goTo("BILLING_PLANS");
                                    }}
                                    text={"Upgrade"}
                                />
                            </div>
                        </Modal>
                    ) : (
                        <HumanizerBuyMore
                            setModal={setBuyMoreModal}
                            currency={currency}
                            plansToggler={plan_type}
                            getDiscount={getDiscount}
                            allPlans={plans}
                            buyMoreAPI={createHumanizerStripeSession}
                            subscriptionInfo={subscriptionInfo}
                        />
                    )}
                </>
            )}
        </div>
    );
}

const _WordCounter = ({ editor, source }) => {
    const [words, setWords] = useState(0);

    useEffect(() => {
        if (editor) {
            editor?.on("editor-change", async () => {
                const res = await RatingHook(editor.getText(), [], source);
                setWords(res.wordCount);
            });

            (async () => {
                const res = await RatingHook(editor.getText(), [], source);
                setWords(res.wordCount);
            })();
        }

        return () => {};
    }, [editor]);

    return <EditorWordCounter wordCount={words} />;
};

export default AIHumanizerEditor;
