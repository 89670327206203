import React, { useEffect, useState } from "react";
import styles from "./WordpressApp.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import ReactDOM from "react-dom";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import "./dropdown.css";
import SetTime from "./SetTime";

export default function Calendar({
    scheduleTime,
    setScheduleTime,
    disable = false,
    immediate,
    setImmediate,
    period,
    setPeriod,
    index,
    hours,
    setHours,
    minutes,
    setMinutes,
    minDate,
}) {
    const [domReady, setDomReady] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const resetToImmediate = () => {
        setScheduleTime(new Date());
        setHours(new Date().getHours() % 12);
        setMinutes(new Date().getMinutes());
        setImmediate(true);
        setCalendar(false);
    };

    useEffect(() => {
        setDomReady(true);
    }, []);

    useEffect(() => {
        if (
            (immediate || disable) &&
            document.getElementsByClassName(
                "react-datetime-picker__inputGroup",
            )[index]
        ) {
            let leadingZero = document.getElementsByClassName(
                "react-datetime-picker__inputGroup__leadingZero",
            );
            Array.from(leadingZero).forEach(function (ele) {
                ele.style.display = "none";
            });
            let input = document.getElementsByClassName(
                "react-datetime-picker__inputGroup__input",
            );
            Array.from(input).forEach(function (ele) {
                ele.style.display = "none";
            });
            let dividers = document.getElementsByClassName(
                "react-datetime-picker__inputGroup__divider",
            );
            Array.from(dividers).forEach(function (ele) {
                ele.style.display = "none";
            });
            let para = document.getElementsByClassName("immediateText");
            Array.from(para).forEach(function (ele) {
                ele.style.display = "flex";
            });
        }
        if (
            !immediate &&
            document.getElementsByClassName(
                "react-datetime-picker__inputGroup",
            )[index]
        ) {
            let leadingZero = document.getElementsByClassName(
                "react-datetime-picker__inputGroup__leadingZero",
            );
            Array.from(leadingZero).forEach(function (ele) {
                ele.style.display = "initial";
            });
            let input = document.getElementsByClassName(
                "react-datetime-picker__inputGroup__input",
            );
            Array.from(input).forEach(function (ele) {
                ele.style.display = "initial";
            });
            let dividers = document.getElementsByClassName(
                "react-datetime-picker__inputGroup__divider",
            );
            Array.from(dividers).forEach(function (ele) {
                ele.style.display = "initial";
            });
            let para = document.getElementsByClassName("immediateText");
            Array.from(para).forEach(function (ele) {
                ele.style.display = "none";
            });
        }
    }, [immediate]);
    return (
        <div className="calendarCss">
            <DateTimePicker
                value={scheduleTime}
                onCalendarOpen={() => {
                    setCalendar(true);
                }}
                onCalendarClose={() => {
                    setCalendar(false);
                }}
                disabled={disable}
                isCalendarOpen={calendar}
                // minDate={new Date(minDate)}
                onChange={(value) => {
                    setScheduleTime(value);
                    setImmediate(false);
                }}
                closeWidgets={false}
                prevLabel={
                    <div style={{ transform: "rotate(90deg)" }}>
                        <SVGIcon
                            size={12}
                            src={"/New UI/SVG/chevronDown.svg"}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </div>
                }
                nextLabel={
                    <div style={{ transform: "rotate(-90deg)" }}>
                        <SVGIcon
                            size={12}
                            src={"/New UI/SVG/chevronDown.svg"}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </div>
                }
                format={"dd/MM/y hh:mm"}
                calendarIcon={
                    <SVGIcon
                        size={20}
                        src={"/New UI/SVG/dropdown.svg"}
                        style={{
                            cursor: "pointer",
                        }}
                    />
                }
            />
            {minDate - new Date(scheduleTime).getTime() >= 0 && (
                <div
                    style={{
                        position: "absolute",
                        fontSize: "12px",
                        color: "red",
                    }}
                >
                    Please select a valid date
                </div>
            )}
            {domReady &&
                document.getElementsByClassName(
                    "react-datetime-picker__inputGroup",
                )[index] &&
                ReactDOM.createPortal(
                    <>
                        <p
                            onClick={() => (disable ? "" : setCalendar(true))}
                            className="immediateText"
                            style={{
                                display: immediate ? "flex" : "none",
                                cursor: "pointer",
                                color: disable ? "#014dc580" : "",
                            }}
                        >
                            {"Immediately"}
                        </p>
                    </>,
                    document.getElementsByClassName(
                        "react-datetime-picker__inputGroup",
                    )[index],
                )}
            {domReady &&
                document.getElementsByClassName("react-calendar")[index] &&
                ReactDOM.createPortal(
                    <SetTime
                        hours={hours}
                        setHours={setHours}
                        minutes={minutes}
                        setMinutes={setMinutes}
                        period={period}
                        setPeriod={setPeriod}
                        clickHandler={resetToImmediate}
                        scheduleTime={scheduleTime}
                        setScheduleTime={setScheduleTime}
                    />,
                    document.getElementsByClassName("react-calendar")[index],
                )}

            {domReady &&
                !immediate &&
                document.getElementsByClassName(
                    "react-datetime-picker__inputGroup",
                )[index] &&
                ReactDOM.createPortal(
                    <>
                        <input
                            style={{ width: "24px" }}
                            value={period ? "AM" : "PM"}
                            class="react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__amPm"
                            name="amPm"
                        />
                    </>,
                    document.getElementsByClassName(
                        "react-datetime-picker__inputGroup",
                    )[index],
                )}
        </div>
    );
}
