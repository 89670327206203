import { TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import SVGIcon from "../../../atoms/SVGIcon";

import styles from "./EB.module.css";
import { EditorBriefContext } from "./EditorBriefContext";
import { BRIEF_TYPES } from "./useEditorBriefHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { VscTrash } from "react-icons/vsc";

const EBReferences = ({ item, index, readOnly = false }) => {
    console.log("EBReferences EBReferences", item);
    const { dispatchEditorBrief } = useContext(EditorBriefContext);
    const [input, setInput] = useState(item?.data ? item?.data : "");
    const [isEditSubData, setIsEditSubData] = useState(false);
    const [showSubData, setShowSubData] = useState(
        item?.subData ? true : false,
    );
    const [subData, setSubData] = useState(item?.subData ? item.subData : "");

    const handleEdit = (value) => {
        if (value?.trim())
            dispatchEditorBrief({
                type: BRIEF_TYPES.EDIT_REFERENCE,
                data: value,
                index: index,
            });
        else {
            handleEdit();
        }
    };

    const handleDelete = () => {
        dispatchEditorBrief({
            type: BRIEF_TYPES.DELETE_REFERENCE,
            index: index,
        });
    };

    return (
        <div
            className={`${styles.itemMainContainer} ${styles.additionalContainer}`}
        >
            <div className={styles.dragIcon}>
                <SVGIcon
                    color="lightgray"
                    size={16}
                    src="/New UI/SVG/drag.svg"
                />
            </div>
            <div className={styles.itemMainBox}>
                <div className={styles.options}>
                    {!readOnly && (
                        <div className={styles.itemIconsContainer}>
                            <LightTooltip
                                title={
                                    !readOnly
                                        ? item.subData
                                            ? "Click to edit note"
                                            : "Click to add note"
                                        : ""
                                }
                                arrow
                                placement="top"
                            >
                                <div className={styles.itemIcon}>
                                    <SVGIcon
                                        size={13.5}
                                        onClick={() => {
                                            setShowSubData(true);
                                            setIsEditSubData(true);
                                        }}
                                        src="/New UI/SVG/edit-note.svg"
                                    />
                                </div>
                            </LightTooltip>
                            <div
                                className={styles.itemIcon}
                                onClick={() => {
                                    handleDelete();
                                }}
                            >
                                <VscTrash onClick={() => {}} color="#f94f2c" />
                            </div>
                        </div>
                    )}
                </div>

                <LightTooltip
                    title={!readOnly ? "Click to Edit" : ""}
                    arrow
                    placement="top"
                >
                    <div
                        onClick={() => {
                            // if (!readOnly) setEditMainData(true);
                        }}
                        className={styles.itemData}
                    >
                        <TextareaAutosize
                            className={styles.mainDataTextArea}
                            disabled={false}
                            type="text"
                            placeholder={"Add your text here"}
                            maxLength={120}
                            value={input}
                            onChange={(e) => {
                                console.log("inputtt", e);
                                setInput(e.target.value);
                            }}
                            onBlur={(e) => {
                                handleEdit(e.target.value);
                            }}
                        />
                    </div>
                </LightTooltip>
            </div>
            {showSubData && (
                <div
                    className={styles.itemsubDataBox}
                    onClick={() => {
                        // if (!readOnly) setEditsubDataBox(true);
                    }}
                >
                    <div className={styles.subData}>
                        {!isEditSubData ? (
                            <div onClick={() => setIsEditSubData(true)}>
                                {subData ? (
                                    subData
                                ) : (
                                    <div className={styles.placeholder}>
                                        Add your note here
                                    </div>
                                )}
                            </div>
                        ) : (
                            <TextareaAutosize
                                value={subData}
                                autoFocus={true}
                                placeholder={"Add your note here"}
                                className={styles.subDataTextArea}
                                onChange={(e) => setSubData(e.target.value)}
                                onBlur={() => {
                                    setIsEditSubData(false);
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EBReferences;
