import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import useMixpanelHook from "../../../utility/useMixpanelHook";

const TransactionFailed = () => {
    const { mpTrackPaymentStatus } = useMixpanelHook();
    useEffect(() => {
        mpTrackPaymentStatus({ campaign: "BF Deals", status: "Failed" });
    }, []);
    return <Redirect to={"/admin/account/plans"} />;
};

export default TransactionFailed;
