import React from "react";
import styles from "./styles.module.css";

function Label({
    label = "Label",
    colorLabel = "",
    color = "",
    size = 12,
    required = false,
    textTransform = "",
    style = {},
}) {
    return (
        <label
            style={{ fontSize: size, textTransform, ...style }}
            className={styles.label}
        >
            {label} <span className={styles.power}>{required ? "*" : ""}</span>
            {colorLabel ? <span style={{ color }}>{colorLabel}</span> : null}
        </label>
    );
}

export default Label;
