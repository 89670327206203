import React from "react";
import Button from "../../../atoms/Button/Button";
import Image from "../../../atoms/Image/Image";
import { HiArrowNarrowRight } from "react-icons/hi";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import styles from "./NoFavourite.module.css";
function NoFavourite({ setAllUseCases }) {
  const goTo = useNavigation();
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image src="/assets/images/empty-graphic.svg" />
      </div>
      <div className={styles.text}>
        <div>
          Currently you have not saved any favorites <br />
          Start by generating a content and click on the favorite star
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text={"Generate Content"}
          Icon={HiArrowNarrowRight}
          height="50px"
          width="340px"
          fontSize="16px"
          handler={(e) => {
            goTo("NEW_SMART_DOCS")
          }}
        ></Button>
        <div>Come back here to view your favorites</div>
      </div>
    </div>
  );
}

export default NoFavourite;
