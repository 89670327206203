import React from 'react';
import styles from './Spacer.module.css';

export default function Spacer({ spacing, backGround, borderBottom = 'none' }) {
  return (
    <div
      className={styles.spacer}
      style={{
        paddingBottom: spacing,
        backgroundColor: backGround,
        borderBottom,
      }}
    ></div>
  );
}
