import React, { useEffect, useContext } from "react";
import { useState } from "react";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    ClickAwayListener,
    makeStyles,
    Tooltip,
    withStyles,
    Checkbox,
    Link,
} from "@material-ui/core";
import { MdKeyboardArrowDown } from "react-icons/md";
import styles from "./styles.module.css";
import "./styles.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import ScoreIndicator from "./ScoreIndicator";
import ReportContext from "../../Research/ReportContext";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import WriteToEditor from "../Toolbar/WriteToEditor";

import { FiExternalLink } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoTriangleSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
import { GrPrevious, GrNext } from "react-icons/gr";
import { VscClose } from "react-icons/vsc";
import { useRef } from "react";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import { SingleQuestion } from "../../Research/PeopleQuestions/PeopleQuestions";
import { platformIcon } from "../../../molecules/Creator/Overview/PeopleQuestionsOverview";
import EditorContext from "../EditorContext";
import TextHighlighter from "../TextHighlighter";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { FixItAllowed } from "./fixitConstants";
import { FixItSections } from "./fixitConstants";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";
import LockedTextComponent from "../LockedTextComponent";
import VideoModal from "../../../Pages/NewDashboard/VideoModal";
import Quill from "quill";
const Delta = Quill.import("delta");
IoTriangleSharp;

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        overflow: "hidden",
        borderBottom: "none",
    },
    expanded: {
        borderBottom: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    },
    content: {
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    disabled: {
        backgroundColor: "white !important",
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        padding: "0",
        margin: "0px",
        borderBottom: "none !important",
    },
    expanded: {
        borderBottom: "none",
    },
}));

const customAccordion = makeStyles((theme) => ({
    root: {
        boxShadow: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        overflow: "hidden",
        borderBottom: "none",
        padding: "0",
    },
    expanded: {
        borderBottom: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    },
    content: {
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    disabled: {
        backgroundColor: "white !important",
    },
}));

const customAccordion1 = makeStyles((theme) => ({
    root: {
        padding: "0",
        margin: "0px",
        height: "30px",
        minHeight: "30px !important",
        borderBottom: "1px solid #e1e4ed !important",
        color: "#292929",
    },
    expanded: {
        borderBottom: "none !important",
    },
    content: {
        margin: "0",
    },
}));

function OptimiserPro({
    reportInfo,
    realtimeRating,
    seoScore,
    optimiserData,
    optimiserLoading,
    writingState,
    isOptimiserEnable,
    handleFixItSection = () => {},
    setIsFixItEnabled = () => {},
}) {
    const { postData } = useHelperFunctions();
    const { getSeoScore, isShared, peopleQuestions } =
        useContext(ReportContext);
    const { mpTrackRefreshSEOScore, mpTrackFixItCTA, mpTrackInfoIconClicked } =
        useMixpanelHook();

    const getSeoScore_optimiser = async (pos) => {
        mpTrackRefreshSEOScore({ position: pos });

        await getSeoScore();
    };

    const [highlightKeyterm, setHighlightKeyterm] = useState({
        enable: false,
        data: "",
        key: "",
    });

    return (
        <div className={styles.optimiserProSection}>
            {highlightKeyterm.enable && (
                <TextHighlighter
                    keyTerms={[{ keyword: highlightKeyterm.key }]}
                />
            )}
            <div className={styles.scoreSection}>
                <div className={styles.topPart}>
                    <div className={styles.seoScoreText}>
                        <div>SEO Score</div>&nbsp;
                        <LightTooltip
                            title={
                                <>
                                    The SEO score offers a comprehensive
                                    analysis of the key factors influencing
                                    content ranking. It ensures your content is
                                    optimised for SEO and provides a comparison
                                    against your leading competitors.
                                    <br />
                                    <br />
                                    <strong>Example</strong> <br /> <br />
                                    The parameters that determine the SEO Score
                                    are- key terms,meta tags, URL, featured
                                    snippet, H1,H2-H6 headings, content depth,
                                    keyword density and links Based on these
                                    parameters you can view the current score of
                                    your article, avg score and the top score.
                                    Further using "Fix It" you get
                                    recommendations to optimise your content.
                                    <br />
                                    <br />
                                    <VideoModal
                                        link={`https://www.youtube.com/embed/13u2yQasxRA?si=JrSudLwyb-Yx6YGD`}
                                        zoomVal={1.2}
                                        modal={false}
                                        aspectWidth={380}
                                        aspectHeight={200}
                                        customClass={styles.videoStyle}
                                        location="SEO Score"
                                    />
                                </>
                            }
                            arrow
                            click
                            tooltipStyle={{
                                maxWidth: 440,
                                padding: "30px 30px 30px 30px !important",
                            }}
                        >
                            <div
                                style={{ height: 19 }}
                                onClick={() => {
                                    mpTrackInfoIconClicked({
                                        location: "SEO Score",
                                    });
                                }}
                            >
                                <SVGIcon
                                    color="#2196f3"
                                    size={18}
                                    src={"/New UI/SVG/info.svg"}
                                />
                            </div>
                        </LightTooltip>
                    </div>
                    <Button
                        handler={() => getSeoScore_optimiser("Right Pane")}
                        disabled={optimiserLoading}
                        width="122px"
                        height={"32px"}
                        text="Refresh Score"
                        style={{
                            fontSize: "11px",
                            fontWeight: "500",
                            padding: "12px",
                            backgroundColor: "#C9E3FF",
                            color: "#014DC5",
                        }}
                        Icon={() => (
                            <SVGIcon
                                className={
                                    optimiserLoading ? styles.rotate : ""
                                }
                                size={16}
                                color={"#014DC5"}
                                src={"/New UI/SVG/refresh-icon.svg"}
                            />
                        )}
                    />
                </div>
                <div className={styles.left}>
                    <ScoreIndicator
                        score={parseInt(seoScore, 10)}
                        suggestedScore={realtimeRating.suggestedSEOScore}
                    />
                </div>
                <div className={styles.bottomPart}>
                    <LightTooltip
                        arrow
                        placement="bottom"
                        title="Average SEO Score among competitors"
                    >
                        <p style={{ marginRight: "16px" }}>
                            Avg. Score:{" "}
                            <span style={{ fontWeight: "600" }}>
                                {realtimeRating.avgScore}
                            </span>
                        </p>
                    </LightTooltip>
                    <div className={styles.separator2}></div>

                    <LightTooltip
                        arrow
                        placement="bottom"
                        title="Top SEO Score among competitors"
                    >
                        <p style={{ marginLeft: "16px" }}>
                            Top Score:{" "}
                            <span style={{ fontWeight: "600" }}>
                                {realtimeRating.topScore}
                            </span>
                        </p>
                    </LightTooltip>
                </div>
            </div>
            <div className={styles.sectionScoreText}>
                <div style={{ marginRight: "8px" }}>Section Score</div>
                <LightTooltip
                    arrow
                    title="This section provides all the important factors and whether each of them  is present in your content. Expand all sections to view the optimization points. "
                >
                    <span style={{ display: "flex" }}>
                        <SVGIcon src={"/New UI/SVG/info_optimiser.svg"} />
                    </span>
                </LightTooltip>
            </div>
            {optimiserData?.map((data, index) => (
                <Section
                    key={index}
                    seoScore={seoScore}
                    sectionTitle={data.key}
                    sectionData={data.value}
                    writingState={writingState}
                    reportInfo={reportInfo}
                    realtimeRating={realtimeRating}
                    peopleQuestions={peopleQuestions}
                    isOptimiserEnable={isOptimiserEnable}
                    highlightKeyterm={highlightKeyterm}
                    setHighlightKeyterm={setHighlightKeyterm}
                    isShared={isShared}
                    handleFixItSection={handleFixItSection}
                />
            ))}
        </div>
    );
}

const Section = ({
    seoScore,
    sectionTitle,
    sectionData,
    writingState,
    reportInfo,
    realtimeRating,
    peopleQuestions,
    isOptimiserEnable,
    highlightKeyterm,
    setHighlightKeyterm,
    isShared,
    handleFixItSection = () => {},
}) => {
    const [expanded, setExpanded] = useState(false);
    const [paaSection, setPaaSection] = useState(false);
    const [questionsSection, setQuestionsSection] = useState(false);
    const [keyTermsNumber, setKeytermsNumber] = useState(10);
    const [h2ToH6keyTermsNumber, setH2ToH6KeytermsNumber] = useState(10);
    const [citationsNumber, setCitationsNumber] = useState(5);
    const [clickToAdd, setClickToAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [keyTermData, setKeytermData] = useState({
        usage: [],
        keyTermRef: null,
    });
    const classes = useStyles();
    const { mpTrackRefreshSEOScore, mpTrackFixItCTA } = useMixpanelHook();
    const CitationsClasses = customAccordion();
    const CitationsClasses1 = customAccordion1();
    const classes1 = useStyles1();
    const [expandedKeyterm, setExpandedKeyterm] = useState(true);
    const { isFreeForever, isEssential, individualUser } = useUserAccessHook();

    console.log("Allowed Fix It for", sectionTitle);

    const loadMoreKeyterms = () => {
        if (sectionData?.data[0] && sectionData?.data[0]?.unusedKeyterms) {
            if (
                keyTermsNumber + 10 >
                sectionData?.data[0]?.unusedKeyterms?.length
            ) {
                setKeytermsNumber(sectionData?.data[0]?.unusedKeyterms?.length);
            } else {
                setKeytermsNumber((prevState) => prevState + 10);
            }
        }
    };

    const loadMoreH2ToH6Keyterms = () => {
        if (sectionData?.data[0] && sectionData?.data[0]?.keyTerms) {
            if (
                h2ToH6keyTermsNumber + 10 >
                sectionData?.data[0]?.keyTerms?.length
            ) {
                setH2ToH6KeytermsNumber(sectionData?.data[0]?.keyTerms?.length);
            } else {
                setH2ToH6KeytermsNumber((prevState) => prevState + 10);
            }
        }
    };

    const loadMoreCitations = () => {
        if (sectionData?.data[1] && sectionData?.data[1]?.unusedCitations) {
            if (
                citationsNumber + 5 >
                sectionData?.data[1]?.unusedCitations?.length
            ) {
                setCitationsNumber(
                    sectionData?.data[1]?.unusedCitations?.length,
                );
            } else {
                setCitationsNumber((prevState) => prevState + 5);
            }
        }
    };

    const handleChange = (panel) => (event, isExpanded) => {
        if (
            event?.currentTarget?.innerText
                ?.toLowerCase()
                ?.includes("key terms")
        ) {
            setExpandedKeyterm(isExpanded ? false : true);
        }
        setExpanded(isExpanded ? panel : false);
    };

    const addToEditor = (str, isH2) => {
        if (writingState?.state) return;
        if (document.quill.editor.isEnabled() && isH2) {
            document.quill.editor.updateContents(
                new Delta()
                    .retain(document.quill.editor.cursor + 1)
                    .delete(0)
                    .insert("\n", { header: 2 }),
            );
            document.quill.editor.updateContents(
                new Delta()
                    .retain(document.quill.editor.cursor + 1)
                    .insert(str),
            );
            document.quill.editor.cursor =
                document.quill.editor.cursor + str?.length + 1;
            document.quill.editor.setSelection(document.quill.editor.cursor);
        } else {
            WriteToEditor({
                data: !document.quill.editor.getText(
                    document.quill.editor.cursor - 1,
                    1,
                )
                    ? str
                    : document.quill.editor.getText(
                          document.quill.editor.cursor - 1,
                          1,
                      ) != "\n"
                    ? "\n" + str
                    : str,
                type: "text",
                length: str.length + 1,
                source: "user",
            });
            setClickToAdd(true);
        }
    };
    const addLinkToEditor = (text, url) => {
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor + 1)
                .delete(0)
                .insert("\n"),
            "user",
        );
        document.quill.editor.cursor = document.quill.editor.cursor + 1;
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor)
                .delete(0)
                .insert(text, { link: url }),
            "user",
        );

        document.quill.editor.cursor =
            document.quill.editor.cursor + text.length;
    };

    const checkFixItStatus = (tempSecData) => {
        if (tempSecData?.status?.GREEN) {
            return false;
        } else {
            return true;
        }
    };

    const isKeytermFixItDisabled =
        +realtimeRating?.wordCount <
            +realtimeRating?.wordRange?.split("+")[0] / 2 &&
        FixItAllowed[sectionTitle].panelName == FixItSections.KEY_TERMS
            ? true
            : false;

    return (
        <div class={styles.sections}>
            <Accordion
                classes={{
                    root: classes.root,
                    expanded: classes.expanded,
                }}
                onChange={handleChange(1)}
            >
                <AccordionSummary
                    expandIcon={<MdKeyboardArrowDown size={14} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        borderBottom: "1px solid red",
                        borderRadius: 3,
                    }}
                    classes={{
                        root: classes1.root,
                        content: classes.content,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {
                            <div className={styles.sectionHeading}>
                                <div>{sectionTitle} </div>

                                {sectionData?.status?.issues > 0 ? (
                                    <div className={styles.issues}>
                                        {sectionData?.status?.issues}{" "}
                                        {sectionData?.status?.issues > 1
                                            ? " issues found"
                                            : " issue found"}
                                    </div>
                                ) : sectionData?.status?.issues == 0 ? (
                                    <div className={styles.allGood}>
                                        All good
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        }
                        {!expanded &&
                        !isShared &&
                        FixItAllowed[sectionTitle].isEnabled &&
                        sectionData?.status?.issues > 0 &&
                        !isEssential &&
                        !individualUser &&
                        checkFixItStatus(sectionData) ? (
                            <LightTooltip
                                arrow
                                placement={"bottom"}
                                title={
                                    isKeytermFixItDisabled
                                        ? "Please provide at least 50% of the suggested word count to enable Fix It for Key Terms"
                                        : FixItAllowed[sectionTitle]?.tagline
                                }
                            >
                                <div
                                    onClick={() => {
                                        if (isKeytermFixItDisabled) {
                                            return;
                                        }
                                        mpTrackFixItCTA({
                                            panelName:
                                                FixItAllowed[sectionTitle]
                                                    .panelName,
                                        });
                                        handleFixItSection(
                                            FixItAllowed[sectionTitle],
                                            sectionData,
                                        );
                                    }}
                                    style={{
                                        opacity: isKeytermFixItDisabled
                                            ? "0.5"
                                            : "1",
                                    }}
                                    className={styles.fixIt_cta__head}
                                >
                                    <span style={{ marginRight: "4px" }}>
                                        <SVGIcon
                                            src={"/New UI/SVG/fixit_wand.svg"}
                                            style={{ color: "#014dc5" }}
                                        />
                                    </span>
                                    <span>Fix It</span>
                                </div>
                            </LightTooltip>
                        ) : (
                            ""
                        )}
                    </div>
                </AccordionSummary>

                {expanded && (
                    <AccordionDetails classes={{ root: classes1.root }}>
                        <div className={styles.details}>
                            {keyTermData?.usage?.length > 0 && (
                                <KeyTermUsage
                                    setKeytermData={setKeytermData}
                                    keyTermData={keyTermData}
                                    loading={loading}
                                    reportInfo={reportInfo}
                                />
                            )}
                            {FixItAllowed[sectionTitle].isEnabled &&
                            !isShared &&
                            checkFixItStatus(sectionData) ? (
                                <div className={styles.fixItWrapper}>
                                    <span className={styles.tagline}>
                                        {FixItAllowed[sectionTitle]?.tagline +
                                            " "}
                                    </span>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* <div className={styles.newTag}>New</div> */}
                                        <LightTooltip
                                            arrow
                                            placement="bottom"
                                            title={
                                                isEssential ||
                                                individualUser ? (
                                                    <LockedTextComponent />
                                                ) : isKeytermFixItDisabled ? (
                                                    "Please provide at least 50% of the suggested word count to enable Fix It for Key Terms"
                                                ) : (
                                                    ""
                                                )
                                            }
                                            interactive={
                                                isEssential || individualUser
                                            }
                                        >
                                            <div>
                                                <Button
                                                    width={"70px"}
                                                    height={"30px"}
                                                    text={"Fix It"}
                                                    Icon={() => (
                                                        <SVGIcon
                                                            src={
                                                                "/New UI/SVG/fixit_wand.svg"
                                                            }
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            size={14}
                                                        />
                                                    )}
                                                    disabled={
                                                        isKeytermFixItDisabled ||
                                                        isEssential ||
                                                        individualUser
                                                    }
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                    iconReverse={true}
                                                    handler={() =>
                                                        handleFixItSection(
                                                            FixItAllowed[
                                                                sectionTitle
                                                            ],
                                                            sectionData,
                                                        )
                                                    }
                                                    customClass={
                                                        styles.fixIt_cta
                                                    }
                                                />
                                            </div>
                                        </LightTooltip>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {sectionData?.data?.map((para, i) => (
                                <>
                                    <div className={styles.statements}>
                                        {para.status == "RED" ? (
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={"Major issue"}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                >
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/red-cross.svg"
                                                        }
                                                    />
                                                </div>
                                            </LightTooltip>
                                        ) : para.status == "YELLOW" ? (
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={"Minor issue"}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                >
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/yellow-tick.svg"
                                                        }
                                                    />
                                                </div>
                                            </LightTooltip>
                                        ) : (
                                            <LightTooltip
                                                arrow
                                                placement="bottom"
                                                title={"All good"}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                >
                                                    <SVGIcon
                                                        src={
                                                            "/New UI/SVG/green-tick.svg"
                                                        }
                                                    />
                                                </div>
                                            </LightTooltip>
                                        )}
                                        <div>
                                            <span
                                                className={styles.infoStatement}
                                                style={{ margin: "0" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: para?.statement,
                                                }}
                                            ></span>
                                            {para?.tooltip && (
                                                <LightTooltip
                                                    arrow
                                                    placement="top"
                                                    title={para?.tooltip}
                                                >
                                                    <span>
                                                        <AiOutlineInfoCircle
                                                            className={
                                                                styles.infoIcon
                                                            }
                                                            fill={"#a3a3a3"}
                                                        />
                                                    </span>
                                                </LightTooltip>
                                            )}{" "}
                                        </div>
                                    </div>
                                    {sectionTitle == "Featured Snippet" &&
                                        i == 0 &&
                                        para.status == "RED" &&
                                        questionsSection && (
                                            <div
                                                className={`${styles.keytermUsage}  ${styles.paaContainer} `}
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                {" "}
                                                <VscClose
                                                    onClick={() =>
                                                        setQuestionsSection(
                                                            false,
                                                        )
                                                    }
                                                />
                                                <h2>Questions</h2>
                                                <div
                                                    className={
                                                        styles.questionContainer
                                                    }
                                                >
                                                    {peopleQuestions.map((q) =>
                                                        q.platform !=
                                                        "Google" ? (
                                                            <PaaHeading
                                                                q={q}
                                                                reportInfo={
                                                                    reportInfo
                                                                }
                                                            />
                                                        ) : (
                                                            <></>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {sectionTitle == "Featured Snippet" &&
                                        i == 1 &&
                                        para.status == "RED" &&
                                        paaSection && (
                                            <div
                                                className={`${styles.keytermUsage}  ${styles.paaContainer} `}
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                {" "}
                                                <VscClose
                                                    onClick={() =>
                                                        setPaaSection(false)
                                                    }
                                                />
                                                <h2>People Also Ask</h2>
                                                <div
                                                    className={
                                                        styles.questionContainer
                                                    }
                                                >
                                                    {peopleQuestions.map((q) =>
                                                        q.platform ==
                                                        "Google" ? (
                                                            <PaaHeading
                                                                q={q}
                                                                reportInfo={
                                                                    reportInfo
                                                                }
                                                            />
                                                        ) : (
                                                            <></>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {para?.usedCitations?.length > 0 && (
                                        <div className={styles.citations}>
                                            {para?.usedCitations.map((link) => (
                                                <a>{link?.citation_link}</a>
                                            ))}
                                        </div>
                                    )}
                                    {para?.unusedCitations?.length > 0 ? (
                                        <div className={styles.citations}>
                                            {para?.unusedCitations
                                                ?.filter(
                                                    (ele, i) =>
                                                        i < citationsNumber,
                                                )
                                                .map((link) => (
                                                    <div className="citationsNew">
                                                        <Accordion
                                                            classes={{
                                                                root: CitationsClasses.root,
                                                                expanded:
                                                                    CitationsClasses.expanded,
                                                                disabled:
                                                                    CitationsClasses.disabled,
                                                            }}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={
                                                                    <MdKeyboardArrowDown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                }
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                sx={{
                                                                    borderBottom:
                                                                        "1px solid red",
                                                                    borderRadius:
                                                                        "4px",
                                                                }}
                                                                classes={{
                                                                    root: CitationsClasses1.root,
                                                                    expanded:
                                                                        CitationsClasses1.expanded,
                                                                    content:
                                                                        CitationsClasses1.content,
                                                                }}
                                                            >
                                                                <div>
                                                                    <LightTooltip
                                                                        arrow
                                                                        placement="bottom"
                                                                        title={
                                                                            "Click to view URLs for this domain"
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                styles.actualLink
                                                                            }
                                                                        >
                                                                            {
                                                                                link?.host
                                                                            }
                                                                        </span>
                                                                    </LightTooltip>
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails
                                                                classes={{
                                                                    root: CitationsClasses.root,
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.hostLinksWrapper
                                                                    }
                                                                >
                                                                    {link?.links.map(
                                                                        (
                                                                            item_link,
                                                                            index,
                                                                        ) => {
                                                                            return (
                                                                                <>
                                                                                    <LightTooltip
                                                                                        arrow
                                                                                        placement="bottom"
                                                                                        title={
                                                                                            "Click to add"
                                                                                        }
                                                                                    >
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                addLinkToEditor(
                                                                                                    item_link,
                                                                                                    item_link,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                className={
                                                                                                    styles.actualLink
                                                                                                }
                                                                                            >
                                                                                                {isShared
                                                                                                    ? item_link.slice(
                                                                                                          0,
                                                                                                          70,
                                                                                                      )
                                                                                                    : item_link}
                                                                                            </span>
                                                                                            <span
                                                                                                className={
                                                                                                    styles.FiExternalLink
                                                                                                }
                                                                                            >
                                                                                                <FiExternalLink
                                                                                                    onClick={() =>
                                                                                                        window.open(
                                                                                                            item_link,
                                                                                                        )
                                                                                                    }
                                                                                                    size={
                                                                                                        14
                                                                                                    }
                                                                                                    color={
                                                                                                        "#29394a"
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </a>
                                                                                    </LightTooltip>

                                                                                    {index <
                                                                                    link
                                                                                        ?.links
                                                                                        ?.length -
                                                                                        1 ? (
                                                                                        <div
                                                                                            className={
                                                                                                styles.citation_link_separator
                                                                                            }
                                                                                        ></div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        },
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                ))}
                                            {citationsNumber <
                                                para?.unusedCitations
                                                    ?.length && (
                                                <p
                                                    onClick={() =>
                                                        loadMoreCitations()
                                                    }
                                                    className={styles.showMore}
                                                    style={{
                                                        marginLeft: "0",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    View more
                                                </p>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        style={{
                                            display: "flex",
                                            color: "black",
                                            fontSize: "14px",
                                            flexWrap: "wrap",
                                            marginLeft: "24px",
                                        }}
                                    >
                                        {para?.keywords
                                            ?.slice(0, 10)
                                            .map((term) => (
                                                <KeywordDensity
                                                    term={term}
                                                    highlightKeyterm={
                                                        highlightKeyterm
                                                    }
                                                    setHighlightKeyterm={
                                                        setHighlightKeyterm
                                                    }
                                                    key={term?.keyword}
                                                    placeholderText={`Keyword is used ${term?.count} time(s). Please keep its usage in suggested range.`}
                                                    isRed={true}
                                                />
                                            ))}
                                        {para?.primaryKeywords
                                            ?.slice(0, 10)
                                            .map((terms) => (
                                                <KeywordDensity
                                                    term={terms}
                                                    highlightKeyterm={
                                                        highlightKeyterm
                                                    }
                                                    setHighlightKeyterm={
                                                        setHighlightKeyterm
                                                    }
                                                    key={terms?.keyword}
                                                    placeholderText={
                                                        terms?.isPrimary
                                                            ? terms?.percentage >=
                                                                  0.5 &&
                                                              terms?.percentage <=
                                                                  2.0
                                                                ? "Good job! Keyword is in suggested range."
                                                                : `Keyword is used ${terms?.count} time(s). Please keep its usage in suggested range.`
                                                            : terms?.count >
                                                                  0 &&
                                                              terms?.percentage <=
                                                                  2.0
                                                            ? "Good job! Keyword is in suggested range."
                                                            : `Keyword is used ${terms?.count} time(s). Please keep its usage in suggested range.`
                                                    }
                                                    isRed={
                                                        terms?.isPrimary
                                                            ? terms?.percentage >=
                                                                  0.5 &&
                                                              terms?.percentage <=
                                                                  2.0
                                                                ? false
                                                                : true
                                                            : terms?.count >
                                                                  0 &&
                                                              terms?.percentage <=
                                                                  2.0
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            ))}
                                        {para?.primaryOrSecondaryKeywords?.map(
                                            (terms) => (
                                                <LightTooltip
                                                    arrow
                                                    title={
                                                        terms?.status
                                                            ? "Keyword is present in H2-H6 headings. Click to add again"
                                                            : "Keyword is not present in your H2-H6 headings. Click to add"
                                                    }
                                                    placement="top"
                                                >
                                                    <div
                                                        style={{
                                                            position:
                                                                "relative",
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                styles.keyTerms
                                                            }
                                                            style={{
                                                                paddingRight:
                                                                    terms?.status
                                                                        ? "18px"
                                                                        : "12px",
                                                            }}
                                                            onClick={() =>
                                                                addToEditor(
                                                                    terms?.keyterm,
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            {terms?.keyterm}
                                                        </p>
                                                        {terms?.status == 1 && (
                                                            <span
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    top: "-5px",
                                                                    right: "12px",
                                                                }}
                                                            >
                                                                <IoCheckmarkCircleSharp
                                                                    fill={
                                                                        "#3F5575"
                                                                    }
                                                                    size={12}
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                </LightTooltip>
                                            ),
                                        )}
                                        {para?.keyTerms?.length > 0 && (
                                            <>
                                                {para?.keyTerms
                                                    ?.slice(
                                                        0,
                                                        h2ToH6keyTermsNumber,
                                                    )
                                                    .map((term, index) => (
                                                        <Keyterm
                                                            keyTerm={
                                                                term?.keyterm
                                                            }
                                                            reportInfo={
                                                                reportInfo
                                                            }
                                                            isHeading={true}
                                                            key={index + 1}
                                                            loading={loading}
                                                            setLoading={
                                                                setLoading
                                                            }
                                                            keyTermData={
                                                                keyTermData
                                                            }
                                                            status={
                                                                term?.status
                                                            }
                                                            setKeytermData={
                                                                setKeytermData
                                                            }
                                                            styleClass={
                                                                styles.keyTerms
                                                            }
                                                            tooltip={
                                                                term?.status
                                                                    ? "Keyword is present in H2-H6 headings. Click to view example usage"
                                                                    : "Keyword is not present in your H2-H6 headings. Click to view example usage"
                                                            }
                                                        />
                                                    ))}
                                                {h2ToH6keyTermsNumber <
                                                    para?.keyTerms?.length && (
                                                    <p
                                                        onClick={() =>
                                                            loadMoreH2ToH6Keyterms()
                                                        }
                                                        className={
                                                            styles.loadMore
                                                        }
                                                    >
                                                        View more
                                                    </p>
                                                )}
                                            </>
                                        )}
                                        {para?.unusedKeyterms?.length > 0 && (
                                            <>
                                                {para?.unusedKeyterms
                                                    ?.slice(0, keyTermsNumber)
                                                    .map((terms, index) => (
                                                        <Keyterm
                                                            keyTerm={terms}
                                                            key={index + 1}
                                                            reportInfo={
                                                                reportInfo
                                                            }
                                                            loading={loading}
                                                            setLoading={
                                                                setLoading
                                                            }
                                                            keyTermData={
                                                                keyTermData
                                                            }
                                                            setKeytermData={
                                                                setKeytermData
                                                            }
                                                            tooltip={
                                                                "Click to view usage"
                                                            }
                                                        />
                                                    ))}
                                                {keyTermsNumber <
                                                    para?.unusedKeyterms
                                                        ?.length && (
                                                    <p
                                                        onClick={() =>
                                                            loadMoreKeyterms()
                                                        }
                                                        className={
                                                            styles.loadMore
                                                        }
                                                    >
                                                        View more
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </>
                            ))}
                        </div>
                    </AccordionDetails>
                )}
            </Accordion>
            {sectionTitle != "Keyword Density" && (
                <div className={styles.separator}></div>
            )}
        </div>
    );
};

const Keyterm = ({
    keyTerm,
    reportInfo,
    keyTermData,
    setKeytermData,
    styleClass,
    tooltip,
    isHeading = false,
    loading,
    setLoading,
    status = 0,
}) => {
    const [isUsageOpen, setIsUsageOpen] = useState(false);
    const { postData } = useHelperFunctions();
    const [dots, setDots] = useState(false);
    const keyTermRef = useRef();

    const getKeytermsData = async () => {
        setLoading(true);
        setDots(true);
        const res = await postData({
            url: "/api/scrape/getKeywordUsage",
            payload: {
                keyword: keyTerm,
                report_id: reportInfo.report_id,
            },
        });
        setLoading(false);
        setDots(false);
        if (res.status == 200) {
            setIsUsageOpen(true);
            isHeading
                ? setKeytermData({
                      usage: res.data?.data?.keywordHeadingUsage,
                      keyTermRef: keyTermRef.current,
                      keyTerm: keyTerm,
                      isHeading: true,
                  })
                : setKeytermData({
                      usage: res.data?.data?.keywordTextUsage,
                      keyTermRef: keyTermRef.current,
                      keyTerm: keyTerm,
                      isHeading: false,
                  });
        }
    };

    return (
        <div ref={keyTermRef} style={{ position: "relative" }}>
            <LightTooltip arrow placement="top" title={tooltip}>
                <div
                    className={
                        styles.keyTerms +
                        " " +
                        (keyTermRef.current &&
                        keyTermData.keyTermRef &&
                        keyTermRef.current == keyTermData.keyTermRef
                            ? styles.keyTermSelected
                            : "")
                    }
                    style={{ paddingRight: status ? "18px" : "12px" }}
                    onClick={() => getKeytermsData(keyTerm)}
                >
                    {dots ? (
                        <div>
                            {keyTerm} <LoadingDots />
                        </div>
                    ) : (
                        <div>{keyTerm}</div>
                    )}
                </div>
            </LightTooltip>
            {status == 1 && (
                <span
                    style={{
                        position: "absolute",
                        top: "-5px",
                        right: "12px",
                    }}
                >
                    <IoCheckmarkCircleSharp fill={"#3F5575"} size={12} />
                </span>
            )}
        </div>
        // </LightTooltip>
    );
};

const KeyTermUsage = ({ keyTermData, setKeytermData, reportInfo, loading }) => {
    const [style, setStyle] = useState({});
    const [tipStyle, setTipStyle] = useState({});
    const [usagePoint, setUsagePoint] = useState(0);
    const [generating, setGenerating] = useState(false);
    const { postData } = useHelperFunctions();
    const editorData = useContext(EditorContext);
    const ref = useRef();

    useEffect(() => {
        const callback = () => {
            setStyle({
                top:
                    keyTermData.keyTermRef.offsetTop +
                    keyTermData.keyTermRef.offsetHeight -
                    2,
            });
            setTipStyle({
                top:
                    keyTermData.keyTermRef.offsetTop +
                    keyTermData.keyTermRef.offsetHeight -
                    12,
                left:
                    keyTermData.keyTermRef.offsetLeft +
                    keyTermData.keyTermRef.offsetWidth / 2 -
                    5,
            });
            if (ref.current && ref.current?.parentNode) {
                if (
                    ref.current.parentNode.offsetHeight <
                    keyTermData.keyTermRef.offsetTop +
                        keyTermData.keyTermRef.offsetHeight +
                        5 +
                        ref.current.offsetHeight +
                        10
                ) {
                    ref.current.parentNode.style.height =
                        keyTermData.keyTermRef.offsetTop +
                        keyTermData.keyTermRef.offsetHeight +
                        5 +
                        ref.current.offsetHeight +
                        10 +
                        "px";
                }
            }
        };
        const observer = new ResizeObserver(callback);
        observer.observe(ref.current);
        if (keyTermData.keyTermRef) callback();
        return () => {
            observer.disconnect();
        };
    }, [keyTermData.keyTermRef, ref.current]);

    useEffect(() => {
        const parent = ref?.current?.parentNode;
        return () => {
            if (parent) {
                parent.style.height = "auto";
            }
        };
    }, []);

    const generateContent = async () => {
        document?.quill?.editor?.enable(false);
        setGenerating(true);
        editorData.setWritingState({ state: true, text: "", useCase: -1 });
        try {
            const resHeadings = await postData({
                url: "/api/scrape/getKeywordUsage",
                payload: {
                    report_id: reportInfo.report_id,
                    keyword: keyTermData.keyTerm,
                },
            });
            const headingArr = resHeadings?.data?.data?.keywordTextUsage;
            let content = [];
            for (let i = 0; i < headingArr.length && i <= 2; i++) {
                content.push(headingArr[i].textUsage);
            }

            const res = await postData({
                url: "/api/generate",
                payload: {
                    Term: keyTermData.keyTerm,
                    Keyword: `${reportInfo.title}, ${keyTermData.keyTerm}`,
                    Context: content,
                    useCase: 56,
                    report_id: reportInfo.report_id,
                },
            });
            let answer = `\n${res?.data?.output[0].content.trim()}`;
            if (res.status == 200) {
                await editorData.writeWithAnimation(answer);
            }
        } catch (error) {
            console.log(error);
        }
        setGenerating(false);
        document?.quill?.editor?.enable(true);
        editorData.setWritingState({ state: false, text: "", useCase: -1 });
    };

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    setKeytermData({ usage: [], keyTermRef: null });
                }}
            >
                <div
                    ref={ref}
                    className={styles.keytermUsage}
                    style={{ ...style }}
                >
                    <VscClose
                        className={styles.closeUsage}
                        onClick={() =>
                            setKeytermData({ usage: [], keyTermRef: null })
                        }
                    />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                        }}
                    >
                        <h2>
                            {keyTermData?.isHeading
                                ? "Heading examples"
                                : "Usage examples"}
                        </h2>
                    </div>
                    {keyTermData.isHeading ? (
                        <>
                            <div className={styles.headingParentContainer}>
                                {keyTermData?.usage
                                    ?.slice(0, 10)
                                    .map((heading, index) => (
                                        <Heading
                                            key={index + 1}
                                            heading={heading}
                                            generating={generating}
                                            keyTermData={keyTermData}
                                            reportInfo={reportInfo}
                                        />
                                    ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <a
                                href={keyTermData.usage[usagePoint]?.url}
                                target="_blank"
                            >
                                {keyTermData.usage[usagePoint]?.url}
                            </a>
                            <p>
                                <CustomHighlighter
                                    textToHighlight={
                                        keyTermData.isHeading
                                            ? keyTermData.usage[usagePoint]
                                                  ?.headingUsage
                                            : keyTermData.usage[usagePoint]
                                                  ?.textUsage
                                    }
                                    searchWords={[
                                        new RegExp(
                                            `\\b${keyTermData.keyTerm}\\b`,
                                            "g",
                                        ),
                                    ]}
                                    highlightClassName={styles.bold}
                                />
                            </p>
                            <div className={styles.handlers}>
                                <LightTooltip
                                    arrow
                                    placement="bottom"
                                    title={"Generate Content for this Key Term"}
                                >
                                    <div>
                                        <Button
                                            handler={() => generateContent()}
                                            loading={generating}
                                            text="Generate Content"
                                            props={{
                                                style: {
                                                    width: "140px",
                                                    height: "22px",
                                                    fontSize: "11px",
                                                    padding: "5px",
                                                    paddingInline: "10px",
                                                    fontWeight: "300",
                                                    borderRadius: 3,
                                                    marginRight: "10px",
                                                    backgroundColor:
                                                        "rgb(201, 227, 255)",
                                                    color: "rgb(1,77,197)",
                                                },
                                            }}
                                            Icon={() => (
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/Ai-icon-no-bg.svg"
                                                    }
                                                    size={20}
                                                />
                                            )}
                                        />
                                    </div>
                                </LightTooltip>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        onClick={() =>
                                            usagePoint == 0
                                                ? setUsagePoint(
                                                      keyTermData.usage
                                                          ?.length - 1,
                                                  )
                                                : setUsagePoint(usagePoint - 1)
                                        }
                                        className={styles.prevNext}
                                    >
                                        <GrPrevious />
                                    </span>
                                    <span className={styles.usageCounter}>
                                        {usagePoint + 1}/
                                        {keyTermData.usage?.length}
                                    </span>
                                    <span
                                        onClick={() =>
                                            setUsagePoint(
                                                (usagePoint + 1) %
                                                    keyTermData.usage?.length,
                                            )
                                        }
                                        className={styles.prevNext}
                                    >
                                        <GrNext />
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </ClickAwayListener>
            <IoTriangleSharp
                fill="#fff"
                style={{ ...tipStyle, position: "absolute", zIndex: 13 }}
            />
        </>
    );
};

const Heading = ({ heading, generating, keyTermData, reportInfo }) => {
    const [generateLoading, setGenerateLoading] = useState(false);
    const { postData } = useHelperFunctions();
    const editorData = useContext(EditorContext);

    const generateHeadingContent = async (headingData) => {
        document?.quill?.editor?.enable(false);
        setGenerateLoading(true);
        editorData.setWritingState({ state: true, text: "", useCase: -1 });
        const res = await postData({
            url: "/api/generate",
            payload: {
                Keyword: keyTermData.keyTerm,
                SubHeading: headingData,
                report_id: reportInfo.report_id,
                title: reportInfo.title,
                useCase: 57,
            },
        });
        if (res.status == 200) {
            document.quill.editor.updateContents(
                new Delta()
                    .retain(document.quill.editor.cursor + 1)
                    .delete(0)
                    .insert("\n", { header: 2 }),
            );
            document.quill.editor.cursor = document.quill.editor.cursor + 1;
            await editorData.writeWithAnimation(headingData);
            document.quill.editor.updateContents(
                new Delta()
                    .retain(document.quill.editor.cursor + 1)
                    .delete(0)
                    .insert("\n", { header: 0 }),
            );
            document.quill.editor.cursor = document.quill.editor.cursor + 1;
            await editorData.writeWithAnimation(
                res?.data?.output[0]?.content?.trim(),
            );
        }

        document?.quill?.editor?.enable(true);
        setGenerateLoading(false);
        editorData.setWritingState({ state: false, text: "", useCase: -1 });
    };

    const addToEditor = (text) => {
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor + 1)
                .delete(0)
                .insert("\n", { header: 2 }),
            "user",
        );
        document.quill.editor.cursor = document.quill.editor.cursor + 1;
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor)
                .delete(0)
                .insert(text),
            "user",
        );

        document.quill.editor.cursor =
            document.quill.editor.cursor + text.length;
    };

    return (
        <>
            <div className={styles.headingContainer}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <img
                        src="/AssetsV2/serp.svg"
                        style={{
                            height: "18px",
                            width: "18px",
                            marginRight: "8px",
                        }}
                    />
                    <LightTooltip
                        arrow
                        placement="bottom"
                        title={"Click to add"}
                    >
                        <p
                            onClick={() => addToEditor(heading?.headingUsage)}
                            className={styles.keyTermHeading}
                        >
                            <CustomHighlighter
                                textToHighlight={heading?.headingUsage}
                                searchWords={[
                                    new RegExp(
                                        `\\b${keyTermData.keyTerm}\\b`,
                                        "g",
                                    ),
                                ]}
                                highlightClassName={styles.bold}
                            />
                        </p>
                    </LightTooltip>
                </div>
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={"Generate content for this heading"}
                >
                    {generateLoading ? (
                        <div
                            style={{
                                height: 20,
                                width: 20,
                            }}
                        >
                            <CircularProgress size={15} />
                        </div>
                    ) : (
                        <img
                            onClick={() =>
                                generateHeadingContent(heading?.headingUsage)
                            }
                            src="/AssetsV2/AI-icon@1.5x.svg"
                            className={styles.AiIcon}
                        />
                    )}
                </LightTooltip>
            </div>
        </>
    );
};

const PaaHeading = ({ q, reportInfo }) => {
    const [loading, setLoading] = useState(false);
    const { postData } = useHelperFunctions();
    const editorData = useContext(EditorContext);

    const generateContent = async (desc) => {
        document?.quill?.editor?.enable(false);
        setLoading(true);
        editorData.setWritingState({ state: true, text: "", useCase: -1 });
        const res = await postData({
            url: "/api/generate",
            payload: {
                description: desc,
                report_id: reportInfo.report_id,
                useCase: 32,
            },
        });
        if (res.status == 200) {
            let editor = document.quill.editor;
            for (
                let index = editor.cursor;
                index < editor.getLength();
                index++
            ) {
                if (editor.getText(index, 1) == "\n") break;
                editor.cursor++;
            }
            editor.updateContents(
                new Delta()
                    .retain(editor.cursor + 1)
                    .delete(0)
                    .insert("\n", { header: 2 }),
            );
            editor.cursor = editor.cursor + 1;
            await editorData.writeWithAnimation(desc);
            editor.updateContents(
                new Delta()
                    .retain(editor.cursor + 1)
                    .delete(0)
                    .insert("\n", { header: 0 }),
            );
            editor.cursor = editor.cursor + 1;
            await editorData.writeWithAnimation(
                res?.data?.output[0]?.content?.trim(),
            );
        }

        document?.quill?.editor?.enable(true);
        setLoading(false);
        editorData.setWritingState({ state: false, text: "", useCase: -1 });
    };

    const addToEditor = (text) => {
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor + 1)
                .delete(0)
                .insert("\n", { header: 2 }),
            "user",
        );
        document.quill.editor.cursor = document.quill.editor.cursor + 1;
        document.quill.editor.updateContents(
            new Delta()
                .retain(document.quill.editor.cursor)
                .delete(0)
                .insert(text),
            "user",
        );

        document.quill.editor.cursor =
            document.quill.editor.cursor + text.length;
    };

    return (
        <div className={styles.paaChildContainer}>
            <LightTooltip arrow placement="bottom" title={"Click to add"}>
                <div
                    onClick={() => addToEditor(q?.question)}
                    style={{ display: "flex", cursor: "pointer" }}
                >
                    {platformIcon(q?.platform)} {q?.question}
                </div>
            </LightTooltip>
            <LightTooltip
                arrow
                placement="bottom"
                title={"Generate content for this question"}
            >
                {loading ? (
                    <div
                        style={{
                            height: 20,
                            width: 20,
                        }}
                    >
                        <CircularProgress size={15} />
                    </div>
                ) : (
                    <img
                        onClick={() => generateContent(q?.question)}
                        src="/AssetsV2/AI-icon@1.5x.svg"
                        className={styles.AiIcon}
                    />
                )}
            </LightTooltip>
        </div>
    );
};

const KeywordDensity = ({
    term,
    highlightKeyterm,
    setHighlightKeyterm,
    placeholderText,
    isRed,
}) => {
    useEffect(() => {
        if (highlightKeyterm.key == term?.keyword && highlightKeyterm.enable) {
            return () => {
                setHighlightKeyterm({
                    enable: false,
                    key: "",
                    data: "",
                });
            };
        }
    }, [highlightKeyterm.enable]);
    return (
        <p
            className={isRed ? styles.keyTermsRed : styles.keyTermsGreen}
            style={{
                cursor: "default",
            }}
        >
            <LightTooltip arrow placement="top" title={placeholderText}>
                <span>
                    {term?.keyword} |{"  "}
                    {term?.percentage}
                    {"%"}
                </span>
            </LightTooltip>
            <LightTooltip arrow placement="top" title="Highlight keyword">
                <input
                    className={styles.check}
                    type="checkbox"
                    checked={
                        highlightKeyterm.enable &&
                        term?.keyword == highlightKeyterm.key
                    }
                    onChange={(e) =>
                        setHighlightKeyterm({
                            enable: e.target.checked,
                            key: term?.keyword,
                            data: "",
                        })
                    }
                />
            </LightTooltip>
        </p>
    );
};

export default OptimiserPro;
