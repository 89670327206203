import React from "react";

export const badges = [
    "/New UI/g2Icons/hp_f_22.svg",
    "/New UI/g2Icons/leader_winter_23.svg",
    "/New UI/g2Icons/love_us.svg",
    "/New UI/g2Icons/leader_spring_23.svg",
    "/New UI/g2Icons/hp_s_23.svg",
];

export const ratings = [
    {
        tag: "Ease of Use",
        src: "https://storage.googleapis.com/scalenut/webflow assests/6219de945d7d6027c1a056eb_9.3%20orange.jpg",
        avg: "9.4",
    },
    {
        tag: "Quality of Support",
        src: "https://storage.googleapis.com/scalenut/webflow assests/6219de862de7e6e5d64d1287_9.5%20purple.jpg",
        avg: "9.2",
    },
    {
        tag: "Ease of Setup",
        src: "https://storage.googleapis.com/scalenut/webflow assests/6219de943be510e5637f6a75_9.4%20blue.jpg",
        avg: "9.3",
    },
];

export const mode2Faqs = [
    {
        ques: "How does Free trial work?",
        ans: "You can try our 7-day free trial to evaluate our AI tools and see how they can benefit your business.",
    },

    {
        ques: "Can I change my plan for the Trial period?",
        ans: "Yes, you can switch between plans at any time during the trial period. On the 8th day, you will be automatically subscribed to the latest selected plan.",
    },
    {
        ques: "Will I be charged during the free trial?",
        ans: `You will not be charged during your free trial. On the 8th day, after the trial period ends, you will be charged for the selected plan.
        `,
    },
    {
        ques: "Can I cancel my subscription at any time? ",
        ans: "Yes, you are free to cancel your subscription at any time. Upon cancellation, you will still have access to our services until the end of the billing cycle.",
    },

    {
        ques: "What does the renewal process look like?",
        ans: `Paid subscriptions renew automatically for the same subscription period prior to your renewal date, unless you change your plan.`,
    },
    {
        ques: "What payment methods do you accept?",
        ans: `Scalenut accepts all major credit and debit cards. We use Stripe for secure payment processing, including 3D secure authentication for your privacy and protection.`,
    },
];

export const otherTestimonials = [
    {
        mainText: "You should go for a cruise!",
        subText:
            "I was looking for a tool that could help me with content writing and Scalenut delivered. The UI is amazing and the output is the best I have seen. It's easy to use and the results are always amazing. I love how Scalenut can automatically generate content for me. It's a great tool for anyone who wants to improve their writing skills.",
        avatar: "https://storage.googleapis.com/scalenut/webflow assests/62a72998959096284cdebac9_nelson%20w%201.png",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Nelson W",
        post: "Business Owner",
    },
    {
        mainText:
            "Scalenut has truly helped scale my Travel Blog faster I definitely recommend It",
        subText:
            "Scalenut has revolutionized the way I write Travel Blog posts. it is fast, reliable, and SEO Friendly. it helps me to write ranking content way faster than I could do on my own! I Highly Recommend this Software!",
        avatar: "https://storage.googleapis.com/scalenut/webflow assests/62a72a80fc80c5c958007633_thumb_square_8925b299ceea80e50d7dedd2498a6f1a.png",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Austin P",
        post: "YouTube Content Creator",
    },
    {
        mainText: "Simple and Powerful AI writing Tool",
        subText:
            "Scalenut Ai writer is a company that offers AI writing opportunities and also provides the best SEO research tools. Scalenut's professional writers are available 24/7 to provide you with quality content for your website or blog, as well as help you achieve your marketing objectives. Scalenut's AI-driven technology will create personalized articles based on keywords that you provide and give them human.",
        avatar: "https://storage.googleapis.com/scalenut/webflow assests/62a72b5fd6a47f9de23ba9ae_dario.png",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Dario L",
        post: "Chief Executive Officer",
    },
    {
        mainText:
            "Scalenut is one the most productive and writer friendly tools out there",
        subText:
            "One of the most appealing features of this tool is its ability to brainstorm different creative ideas to generate reader-friendly content for you. Whether you are writing long-form blog posts or compelling social media copies, it is a complete package for every possible need of a professional writer. I am now using this tool without worrying about hiring new writers every now and then to generate engaging content for me.",
        avatar: "https://storage.googleapis.com/scalenut/webflow assests/62a1dc3befbb548032bca192_Dawood%20A.png",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Dawood A",
        post: "DMCA Specialist",
    },
    {
        mainText: "Simple and Powerful AI writing Tool",
        subText:
            "Scalenut Ai writer is a company that offers AI writing opportunities and also provides the best SEO research tools.Scalenut's professional writers are available 24/7 to provide you with quality content for your website or blog, as well as help you achieve your marketing objectives.this is definitely the best choice!. Scalenut's AI- driven technology will create personalized articles based on keywords that you provide and give them human.",
        avatar: "https://storage.googleapis.com/scalenut/webflow assests/62a1dc3bb5c6a60c9fe97b59_Imran%20K.png",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Imran K",
        post: "Digital Marketer",
    },
    {
        mainText: "Scalenut - Best Ai Writing Assistant",
        subText:
            "I was looking for a tool that could help me create content for my site and social media and I came across this tool. It's very intuitive and easy to use. I also love how affordable it is. If you're looking for an amazing product, this is definitely the best choice!",
        avatar: "https://storage.googleapis.com/scalenut/webflow assests/62a1dc3c32550035cf05af47_Susheel%20Kumar%20B.png",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7f1194927e69793fa_trustpilot.png",
        name: "Susheel Kumar B",
        post: "APM Fellow",
    },
    // non-avatars
    {
        mainText: "Best AI-writer in the market",
        subText:
            'Scalenut stands out for quality. The text generated is profound and factual. I use it mainly for its "quora answer" module and its long-form SEO assistant. I\'m now able to create long-form content in a few minutes.',
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Marco C.",
        post: "",
    },
    {
        mainText: "Making blogs more powerful",
        subText:
            "The easy user-friendly App design made my writing easy. It saved me time and made my life easy.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Showket A",
        post: "",
    },
    {
        mainText: "One of the best AI Writing tools",
        subText:
            "It's been a lot easier and faster to write articles because we are now able to cross reference data from the top SERP results and check our article against them.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Joshua L",
        post: "",
    },

    {
        mainText: "Powerful Long and Short Form AI-Writer",
        subText:
            "This tool has been really fun to use. The UI and UX is well designed and nothing inside Scalenut feels cheap. The output quality of the AI is also great whether you are using the long-form or the short-form generator.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Carlos U",
        post: "",
    },
    {
        mainText: "All In One SEO Writing Tool",
        subText:
            "Because of Scalenut, now I can get articles from a single platform. Competitors are lacking in optimization features and have to use thrid party tools to cross reference SERP data. Scalenut eradicates the nesd of both writing and optimization tool.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Md Billal Hossain S",
        post: "",
    },
    {
        mainText: "Scalenut is a winner",
        subText:
            "Scalenut has become an invaluable SEO tool that enables our team to conduct competition analysis to determine key elements that contribute to high rankings in Google. The SEO functionality along with the AI writing capabilities makes Scalenut a winner!",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fb7dccfe63a1435131e_Capterra.png",
        name: "Gary G",
        post: "",
    },
    {
        mainText: "Superb Long-form SEO Assistant",
        subText:
            "Scalenut has helped me to streamline my content research for the writing of blog articles and producing YouTube videos. I have been able to identify specific topics that my ideal customers are asking through using the SEO assistant.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Rachael Woolley M",
        post: "",
    },
    {
        mainText: "Excellent AI writing assistant",
        subText:
            "I have been using Scalenut as a writing assistant program for a few months now, and I can assure you that it is an indispensable tool if you regularly write and experience writer's block. With it, you can create both long and short-form content.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Punardeep S",
        post: "",
    },
    {
        mainText: "All in one content and SEO tool",
        subText:
            "I  have been using Scalenut since from a month for all my blog content. The most favourite feature is its NLP keyword and SERP facts new feature. I can say that this is the most powerful AI content writing tool with a awesome team.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Shivakumar k N",
        post: "",
    },

    {
        mainText: "All in one content and SEO tool",
        subText:
            "Love the fact that it helps me power up my content research with its AI copywriter & SEO assistant. It helps me understand the user intent by extracting keywords from the content. Also, it helps me to write meaningful and well-optimized content by suggesting you with relevant topics.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Awesome AI Copywriter & SEO Assistant",
        post: "",
    },
    {
        mainText: "Promising new entrant",
        subText:
            "I like the marketplace to hire talent to write content for me. For example, for as little as .05 a word, I can hire someone to write a blog post, website copy or even an entire e-Book for me. Scalenut looks like a promising new entrant into the crowded AI writing software field.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Gary G",
        post: "",
    },
    {
        mainText: "How AI helps me be a pro copywriter",
        subText:
            "Scalenut helps to complete tasks in my life much more easily. Has several features including the AI copywriting commands, when I'm writing blog posts and need some quick inspiration for the next section. The speed at which you can complete projects with Scalenut AI is also much faster than other AI tools on the market right now.",
        avatar: "",
        src: "https://storage.googleapis.com/scalenut/webflow assests/61c42fbb60e088d4bcb6226b_g2.png",
        name: "Md. Kaysar K",
        post: "",
    },
];

export const yearlyDiscount = 60;
export const exitIntentData = [
    `Cruise Mode - Propel your SEO with research backed articles in under 5 min`,
    `SEO Articles - Get powerful insights and create content brief`,
    `Keyword Planner - Topic authority for every keyword you target`,
];
