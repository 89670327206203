import { TextareaAutosize } from "@material-ui/core";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { showCounter } from "../../../atoms/TextArea/TextArea";

const PrimaryInputTextArea = ({
    placeholder = "",
    callback = () => {},
    isFoucs = false,
    maxLength,
    type = "",
}) => {
    const [input, setInput] = useState("");
    const [focus, setFocus] = useState(false);
    const ref = useRef();
    const { mpTrackCruiseAddition } = useMixpanelHook();

    useEffect(() => {
        if (maxLength && focus && input.length === maxLength) {
            ref.current.parentNode.style.borderColor = "rgb(244 98 98 / 73%)";
        } else {
            ref.current.parentNode.style.borderColor = "";
        }
    }, [focus, input]);

    const addOutline = (value) => {
        if (value) {
            setInput("");
            callback(value);
            mpTrackCruiseAddition({
                type: type,
                origin: "Manual",
            });
        }
    };

    return (
        <>
            <TextareaAutosize
                ref={ref}
                disabled={false}
                type="text"
                rowsMin={1}
                value={input}
                placeholder={placeholder}
                maxLength={maxLength}
                autoFocus={isFoucs}
                onChange={(e) => setInput(e.target.value)}
                style={{ width: "100%" }}
                onKeyDown={(e) => {
                    if (e.key == "Enter") {
                        e.preventDefault();
                        addOutline(e.target.value.trim());
                    }
                }}
                onBlur={(e) => {
                    addOutline(e.target.value.trim());

                    setFocus(false);
                }}
                onFocus={() => {
                    setFocus(true);
                }}
            />
            {focus &&
                maxLength &&
                showCounter(false, true, input?.length, maxLength, {
                    paddingRight: "10px",
                    display: "block",
                    position: "relative",
                    right: "unset",
                })}
        </>
    );
};

export default PrimaryInputTextArea;
