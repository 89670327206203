import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import styles from "./FirstDraft.module.css";
import { droppableIds, droppableTypes } from "../cruiseConstant";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { onDragEnd } from "../OutlineBox";
import { CruiseContext, CruiseState } from "../StepsWizard";
import SVGIcon from "../../../../atoms/SVGIcon";
import UserFeedback from "../../../UserFeedback/UserFeedback";
import ReportContext from "../../../Research/ReportContext";
import { useSelector } from "react-redux";
let timeout = null;
const LeftOutline = ({ headings, questions, realtimeRating }) => {
    const dispatchStepsData = useContext(CruiseContext);
    const stepsData = useContext(CruiseState);
    const [close, setClose] = useState(true);
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);
    const { reportInfo, setReportData, showRatingComponent } =
        useContext(ReportContext);

    useEffect(() => {
        if (!stepsData.isLoader) {
            timeout = setTimeout(() => setClose(false), 1000);
        } else {
            clearTimeout(timeout);
        }
    }, [stepsData.isLoader]);

    return (
        <DragDropContext
            onDragEnd={(res) =>
                onDragEnd(res, dispatchStepsData, stepsData, [])
            }
        >
            <div className={styles.outlineContainer}>
                <div className={styles.outlineHead}>
                    <span>Outline</span>
                </div>
                <div className={styles.body}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                            }}
                            className={styles.sectionName}
                        >
                            <span className={styles.fixHeadings}>H1</span>{" "}
                            {stepsData.title}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                fontSize: 12,
                                paddingLeft: 26,
                            }}
                            className={styles.sectionName}
                        >
                            <span className={styles.fixHeadings}>H2</span>{" "}
                            {reportInfo?.is_premium ||
                            auth.segment == 3 ||
                            auth.segment == 4 ||
                            auth.segment == 5 ||
                            auth.segment == 6
                                ? "Key Highlights"
                                : "Introduction"}
                        </div>
                    </div>
                    <div>
                        {/* <div className={styles.sectionName}>Headings</div> */}
                        <div className={styles.headingContainer}>
                            <Droppable
                                type={droppableTypes.outlineH2}
                                droppableId={droppableIds.headings}
                            >
                                {(provided) => (
                                    <div
                                        className={`${styles.headingsDiv} `}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {headings && headings?.length
                                            ? headings.map((item, index) => {
                                                  return (
                                                      <Draggable
                                                          key={item.id}
                                                          draggableId={item.id?.toString()}
                                                          index={index}
                                                      >
                                                          {(provided, snap) => (
                                                              <div
                                                                  key={item.id}
                                                                  ref={
                                                                      provided.innerRef
                                                                  }
                                                                  {...provided.draggableProps}
                                                                  style={{
                                                                      ...provided
                                                                          .draggableProps
                                                                          ?.style,

                                                                      backgroundColor:
                                                                          snap.isDragging
                                                                              ? "rgba(232, 237, 248, 0.9)"
                                                                              : "",
                                                                      cursor: "pointer",
                                                                  }}
                                                                  onClick={() => {
                                                                      document
                                                                          .getElementById(
                                                                              item.id,
                                                                          )
                                                                          ?.scrollIntoView(
                                                                              {
                                                                                  block: "center",
                                                                                  behavior:
                                                                                      "smooth",
                                                                              },
                                                                          );
                                                                  }}
                                                              >
                                                                  <div
                                                                      className={
                                                                          styles.headingRow +
                                                                          " " +
                                                                          (item.tag ==
                                                                              "h3" &&
                                                                              styles.h3Row)
                                                                      }
                                                                  >
                                                                      <div
                                                                          {...provided.dragHandleProps}
                                                                          className={
                                                                              styles.dragIcon
                                                                          }
                                                                      >
                                                                          <SVGIcon
                                                                              color="lightgray"
                                                                              size={
                                                                                  16
                                                                              }
                                                                              src="/New UI/SVG/drag.svg"
                                                                          />
                                                                      </div>
                                                                      <SuccessOutline
                                                                          para={
                                                                              item.para
                                                                          }
                                                                          tag={
                                                                              item.tag
                                                                          }
                                                                      />
                                                                      {/* <span className={styles.headingContent + (item.tag == "h3" ? styles.h2Heading : styles.h3Heading)}>{item.data}</span> */}
                                                                      <span
                                                                          className={
                                                                              item.tag ==
                                                                              "h2"
                                                                                  ? styles.h2Heading
                                                                                  : styles.h3Heading
                                                                          }
                                                                      >
                                                                          {
                                                                              item.data
                                                                          }
                                                                      </span>
                                                                  </div>

                                                                  <Droppable
                                                                      type={
                                                                          droppableTypes.outlineH3
                                                                      }
                                                                      droppableId={String(
                                                                          `${index}_${item.id}`,
                                                                      )}
                                                                  >
                                                                      {(
                                                                          provided,
                                                                      ) => (
                                                                          <div
                                                                              className={`${styles.headingsDiv} `}
                                                                              {...provided.droppableProps}
                                                                              ref={
                                                                                  provided.innerRef
                                                                              }
                                                                              style={{
                                                                                  paddingBottom: 3,
                                                                                  cursor: "pointer",
                                                                              }}
                                                                          >
                                                                              {item.outlineH3s.map(
                                                                                  (
                                                                                      item,
                                                                                      i,
                                                                                  ) => {
                                                                                      return (
                                                                                          <Draggable
                                                                                              key={
                                                                                                  item.id
                                                                                              }
                                                                                              draggableId={String(
                                                                                                  item.id,
                                                                                              )}
                                                                                              index={
                                                                                                  i
                                                                                              }
                                                                                          >
                                                                                              {(
                                                                                                  provided,
                                                                                                  snap,
                                                                                              ) => (
                                                                                                  <div
                                                                                                      className={
                                                                                                          styles.headingRow +
                                                                                                          " " +
                                                                                                          (item.tag ==
                                                                                                              "h3" &&
                                                                                                              styles.h3Row)
                                                                                                      }
                                                                                                      key={
                                                                                                          item.id
                                                                                                      }
                                                                                                      ref={
                                                                                                          provided.innerRef
                                                                                                      }
                                                                                                      {...provided.draggableProps}
                                                                                                      style={{
                                                                                                          ...provided
                                                                                                              .draggableProps
                                                                                                              ?.style,

                                                                                                          backgroundColor:
                                                                                                              snap.isDragging
                                                                                                                  ? "rgba(232, 237, 248, 0.9)"
                                                                                                                  : "",
                                                                                                          cursor: "pointer",
                                                                                                      }}
                                                                                                      onClick={() => {
                                                                                                          document
                                                                                                              .getElementById(
                                                                                                                  item.id,
                                                                                                              )
                                                                                                              ?.scrollIntoView(
                                                                                                                  {
                                                                                                                      block: "center",
                                                                                                                      behavior:
                                                                                                                          "smooth",
                                                                                                                  },
                                                                                                              );
                                                                                                      }}
                                                                                                  >
                                                                                                      <div
                                                                                                          {...provided.dragHandleProps}
                                                                                                          className={
                                                                                                              styles.dragIcon
                                                                                                          }
                                                                                                      >
                                                                                                          <SVGIcon
                                                                                                              color="lightgray"
                                                                                                              size={
                                                                                                                  16
                                                                                                              }
                                                                                                              src="/New UI/SVG/drag.svg"
                                                                                                          />
                                                                                                      </div>
                                                                                                      <SuccessOutline
                                                                                                          para={
                                                                                                              item.para
                                                                                                          }
                                                                                                          tag={
                                                                                                              item.tag
                                                                                                          }
                                                                                                      />
                                                                                                      {/* <span className={styles.headingContent + (item.tag == "h3" ? styles.h2Heading : styles.h3Heading)}>{item.data}</span> */}
                                                                                                      <span
                                                                                                          className={
                                                                                                              item.tag ==
                                                                                                              "h2"
                                                                                                                  ? styles.h2Heading
                                                                                                                  : styles.h3Heading
                                                                                                          }
                                                                                                      >
                                                                                                          {
                                                                                                              item.data
                                                                                                          }
                                                                                                      </span>
                                                                                                  </div>
                                                                                              )}
                                                                                          </Draggable>
                                                                                      );
                                                                                  },
                                                                              )}
                                                                              {
                                                                                  provided.placeholder
                                                                              }
                                                                          </div>
                                                                      )}
                                                                  </Droppable>
                                                              </div>
                                                          )}
                                                      </Draggable>
                                                  );
                                              })
                                            : ""}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                    <Droppable
                        type={droppableTypes.outlineH2}
                        droppableId={droppableIds.questions}
                    >
                        {(provided) => (
                            <div
                                className={styles.headingListings}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {questions?.length > 0 && (
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 8,
                                                paddingLeft: 26,
                                                fontSize: 12,
                                            }}
                                            className={styles.sectionName}
                                        >
                                            <span
                                                className={styles.fixHeadings}
                                            >
                                                H2
                                            </span>{" "}
                                            Frequently Asked Questions
                                        </div>
                                        <div
                                            style={{ paddingLeft: "25px" }}
                                            className={styles.headingContainer}
                                        >
                                            {questions?.length
                                                ? questions.map((item, i) => {
                                                      return (
                                                          <Draggable
                                                              key={item?.id}
                                                              draggableId={String(
                                                                  item?.id,
                                                              )}
                                                              index={i}
                                                          >
                                                              {(
                                                                  provided,
                                                                  snap,
                                                              ) => (
                                                                  <div
                                                                      className={
                                                                          styles.headingRow
                                                                      }
                                                                      key={
                                                                          item.id
                                                                      }
                                                                      ref={
                                                                          provided.innerRef
                                                                      }
                                                                      {...provided.draggableProps}
                                                                      style={{
                                                                          ...provided
                                                                              .draggableProps
                                                                              ?.style,

                                                                          backgroundColor:
                                                                              snap.isDragging
                                                                                  ? "rgba(232, 237, 248, 0.9)"
                                                                                  : "",
                                                                          cursor: "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                          document
                                                                              .getElementById(
                                                                                  item.id,
                                                                              )
                                                                              ?.scrollIntoView(
                                                                                  {
                                                                                      block: "center",
                                                                                      behavior:
                                                                                          "smooth",
                                                                                  },
                                                                              );
                                                                      }}
                                                                  >
                                                                      <div
                                                                          {...provided.dragHandleProps}
                                                                          className={
                                                                              styles.dragIcon
                                                                          }
                                                                      >
                                                                          <SVGIcon
                                                                              color="lightgray"
                                                                              size={
                                                                                  16
                                                                              }
                                                                              src="/New UI/SVG/drag.svg"
                                                                          />
                                                                      </div>
                                                                      <SuccessOutline
                                                                          para={
                                                                              item.para
                                                                          }
                                                                          tag={
                                                                              "H3"
                                                                          }
                                                                      />
                                                                      <span
                                                                          className={
                                                                              styles.h3Heading
                                                                          }
                                                                      >
                                                                          {
                                                                              item.data
                                                                          }
                                                                      </span>
                                                                  </div>
                                                              )}
                                                          </Draggable>
                                                      );
                                                  })
                                                : ""}
                                        </div>
                                    </div>
                                )}{" "}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    {!reportInfo?.is_premium &&
                        auth.segment != 3 &&
                        auth.segment != 4 &&
                        auth.segment != 5 &&
                        auth.segment != 6 && (
                            <div>
                                <div className={styles.sectionName}>
                                    Conclusion
                                </div>
                            </div>
                        )}
                </div>
            </div>
            {!close && !stepsData.hasSatisfactionScore && (
                <UserFeedback
                    setClose={setClose}
                    realtimeRating={realtimeRating}
                    section="cruiseMode"
                    setReportData={setReportData}
                />
            )}
        </DragDropContext>
    );
};

const SuccessOutline = ({ para, tag }) => {
    const [show, setShow] = useState(false);
    const _para = useMemo(() => para, []);
    useEffect(() => {
        if (!_para && para) {
            setShow(true);
            setTimeout(() => {
                setShow(false);
            }, 2000);
        }
    }, [para]);
    return (
        <span className={para ? styles.headingTypePara : styles.headingType}>
            {show ? (
                <SVGIcon color="#000" size={14} src={"/New UI/SVG/check.svg"} />
            ) : (
                tag
            )}
        </span>
    );
};

export default LeftOutline;
