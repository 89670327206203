import React, { useContext, useEffect, useMemo, useState } from "react";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import styles from "./styles.module.css";
// import { SiMailDotru } from "react-icons/si";
import { CgClose } from "react-icons/cg";
import Button from "../../atoms/Button/Button";
import { BsFillPersonCheckFill, BsPerson, BsPersonPlus } from "react-icons/bs";
import { emailValidatorS } from "../../../utility/validateFunctions";
import TagsField from "./TagsField";
import { HiOutlineCheck } from "react-icons/hi";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import { TagsContext } from "../../Pages/TagsContext";
import DomainsAccess from "./DomainsAccess";

function InviteMembers({
    sendInvite,
    invited,
    loading,
    setInviteSuccessModal,
    setInvited,
    createTag,
    inviteErr,
    setInviteErr,
    members,
    setInviteModal,
    setEmail,
    email,
    tags,
    editAccessModal,
    editAccessInfo,
    addTag,
    setEditAccessMember,
    name,
    setName,
    allDomains,
    setAllDomains,
}) {
    const [selectedTags, setSelectedTags] = useState(
        editAccessModal ? editAccessInfo.tags : tags,
    );
    const [assignedDomains, setAssignedDomains] = useState(
        editAccessModal ? editAccessInfo.domain_list : allDomains,
    );
    const [updated, setUpdated] = useState(false);
    const { tagsMap } = useContext(TagsContext);
    useEffect(() => {
        setUpdated(false);
    }, [selectedTags.length]);

    useEffect(() => {
        setSelectedTags((ptags) => {
            return ptags
                .filter((tag) => tagsMap[tag.id])
                .map((tag) => tagsMap[tag.id]);
        });
    }, [tagsMap]);
    useEffect(() => {
        console.log("assignedDomains im", assignedDomains);
    }, [allDomains]);

    useEffect(() => {
        return () => {
            if (setEditAccessMember) setEditAccessMember(null);
        };
    }, []);

    return (
        <>
            <div className={styles.inviteContainer}>
                {/* <CgClose
          onClick={() => setInviteModal(false)}
          className={styles.cross}
        /> */}
                <CloseButton setClose={setInviteModal} />
                <div className={styles.inviteHeader}>
                    <div className={styles.personDp}>
                        <BsPerson color="white" />
                    </div>
                    <div>
                        {!editAccessModal ? (
                            "Invite New Team Member"
                        ) : (
                            <>
                                Edit access for
                                <span style={{ color: "#014dc5" }}>
                                    {" " + editAccessInfo.name}
                                </span>
                            </>
                        )}
                    </div>
                </div>
                {!editAccessModal && (
                    <div className={styles.inputs}>
                        <Textfield
                            Icon={BsPerson}
                            props={{
                                onChange: (e) => {
                                    setName(e.target.value);
                                },
                                value: name,
                                name: "name",
                            }}
                            height={44}
                            placeholder={"Enter member's name"}
                            label="Name"
                        />
                        <Textfield
                            Icon={""}
                            props={{
                                onChange: (e) => {
                                    setEmail(e.target.value);
                                    setInvited(false);
                                    setInviteErr(false);
                                },
                                value: email,
                                name: "email",
                            }}
                            height={44}
                            placeholder={"Enter member's email"}
                            label="Email Address"
                            errMsg={
                                emailValidatorS(email ? email.trim() : "") ||
                                email.length == 0
                                    ? null
                                    : "Enter a valid email"
                            }
                        />
                    </div>
                )}
                <TagsField
                    createTag={createTag}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    tags={tags}
                    isDomainsAvailable={allDomains.length > 0 ? true : false}
                ></TagsField>
                {allDomains.length > 0 && (
                    <DomainsAccess
                        assignedDomains={assignedDomains}
                        setAssignedDomains={setAssignedDomains}
                        allDomains={allDomains}
                        setAllDomains={setAllDomains}
                    />
                )}
                <div className={styles.bottomButton}>
                    <div className={styles.separator}></div>
                    <div className={styles.bottomFlex}>
                        {!editAccessModal &&
                            members
                                .map((member) => member.email)
                                .includes(email) && (
                                <div className={styles.error}>
                                    Member already Invited with the email ID
                                    provided, try using a different email ID or{" "}
                                    <span
                                        style={{
                                            color: "blue",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            window.userengage &&
                                                window.userengage(
                                                    "widget.show",
                                                );
                                            window.userengage &&
                                                window.userengage(
                                                    "widget.open",
                                                );
                                        }}
                                    >
                                        contact support
                                    </span>{" "}
                                    via Chat.
                                </div>
                            )}
                        {inviteErr && !editAccessModal && (
                            <div className={styles.error}>
                                Account already exists with the email ID
                                provided, try using a different email ID or{" "}
                                <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() => {
                                        window.userengage &&
                                            window.userengage("widget.show");
                                        window.userengage &&
                                            window.userengage("widget.open");
                                    }}
                                >
                                    contact support
                                </span>{" "}
                                via Chat.
                            </div>
                        )}

                        {selectedTags.length == tags.length &&
                            !inviteErr &&
                            !(
                                !editAccessModal &&
                                members
                                    .map((member) => member.email)
                                    .includes(email)
                            ) && (
                                <div className={styles.msg}>
                                    *This is Global Access, team member will
                                    have access to all reports in this
                                    organization.
                                </div>
                            )}

                        <Button
                            disabled={
                                editAccessModal
                                    ? false
                                    : name.length == 0 ||
                                      !emailValidatorS(
                                          email ? email.trim() : "",
                                      ) ||
                                      email.length == 0 ||
                                      members
                                          .map((member) => member.email)
                                          .includes(email)
                            }
                            Icon={
                                editAccessModal
                                    ? updated
                                        ? () => (
                                              <HiOutlineCheck
                                                  size="20px"
                                                  color="white"
                                              />
                                          )
                                        : () => <></>
                                    : BsPersonPlus
                            }
                            iconReverse={true}
                            text={
                                editAccessModal
                                    ? updated
                                        ? "Updated"
                                        : "Update"
                                    : "Invite"
                            }
                            handler={async () => {
                                if (editAccessModal) {
                                    await addTag(
                                        {
                                            tags: selectedTags,
                                            _domains: assignedDomains,
                                        },
                                        editAccessInfo.user_id,
                                    );
                                    return setUpdated(true);
                                }

                                if (
                                    await sendInvite(
                                        email,
                                        name,
                                        selectedTags,
                                        assignedDomains,
                                    )
                                ) {
                                    setInviteSuccessModal(true);
                                }
                            }}
                            height={40}
                            width={120}
                            loading={loading}
                            style={{
                                backgroundColor: "#014dc5",
                                marginLeft: "auto",
                            }}
                        ></Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InviteMembers;
