import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import React from "react";
import { getInitials } from "../../../components/account/SettingsSidebar";

type Props_AvatarListing = {
    max?: number;
    list: {
        domain_name: string;
        email: string;
        gsc_id: number;
        status: 1;
    }[];
    reverseStack?: boolean;
};

const ColorPalette = [
    "#1B86CE",
    "#EB996E",
    "#1DC198",
    "#FF6F61",
    "#9370DB",
    "#32CD32",
    "#FF00FF",
    "#6A5ACD",
    "#8B008B",
    "#FFA07A",
    "#00FA9A",
    "#FF1493",
    "#00BFFF",
    "#FFA500",
    "#40E0D0",
    "#FF69B4",
];

const emailColorCode: (emailId: string) => string = (emailId) => {
    const hash = stringToHash(emailId);
    const positiveHash = Math.abs(hash) % ColorPalette.length;
    const colorCode = ColorPalette[positiveHash];
    return colorCode;
};

const stringToHash: (str: string) => number = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
    }
    return hash;
};

const AvatarListing = ({
    max = 3,
    list = [],
    reverseStack = false,
}: Props_AvatarListing) => {
    return (
        <div>
            <AvatarGroup max={max}>
                {list.length > 0 ? (
                    list.map((nm, idx) => {
                        return (
                            <Avatar
                                alt={nm.email}
                                style={{
                                    zIndex: reverseStack
                                        ? idx
                                        : list.length - idx,
                                    background: emailColorCode(nm.email),
                                    textTransform: "uppercase",
                                }}
                            >
                                {getInitials(nm.email)}
                            </Avatar>
                        );
                    })
                ) : (
                    <></>
                )}
            </AvatarGroup>
        </div>
    );
};

export default AvatarListing;
