import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./transactions.module.css";

import {
  getType,
  getTransactionData,
  truncateFun,
  addSign,
  getCurrency,
} from "./TransactionListItem";
import { thousands_separators } from "../../../../utility/validateFunctions";
import { openProject } from "../../../../store/actions/dashboard";

const dateOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const timeOptions = {
  hour: "numeric",
  minute: "2-digit",
};

export default function MobileTransactionCard({
  transaction,
  userCurrency,
  userId,
}) {
  const rowData = getTransactionData(getType(transaction.request_type));
  const role = useSelector((state) => state.auth.role);
  const currency = useSelector((state) => state.auth.currency);
  const dispatch = useDispatch();
  const date = new Date(transaction.created_at);

  return (
    <div className={styles.mobileCard}>
      <div className={styles.mobileCardTop}>
        <div
          className={styles.rowIcon}
          style={{ backgroundColor: rowData.color }}
        >
          {rowData.icon}
        </div>
        <div>
          <p className={styles.mobileCardHeading}>
            {transaction.message
              ? truncateFun(transaction.message)
              : "No message"}
          </p>
          {transaction.request_id && (
            <span
              onClick={() => {
                dispatch(
                  openProject({
                    openProject: true,
                    projectId: transaction.request_id,
                  })
                );
              }}
              className={styles.mobileCardId}
            >
              #{transaction.request_id}
            </span>
          )}
        </div>
      </div>
      <div className={styles.mobileCardBottom}>
        <div>
          <p className={styles.mobileCardAmount}>
            {addSign(
              transaction.amount_payed,
              transaction.used_balance,
              transaction.request_type,
              getCurrency(role, userCurrency, currency, userId)
            )}
          </p>
          <p className={styles.closingBalanceMobile}>
            Closing balance:{" "}
            {transaction.user_credits === null ? (
              "---"
            ) : (
              <>
                <span style={{ fontFamily: "Inter" }}>
                  {transaction.user_credits
                    ? transaction.user_credits >= 0
                      ? `${getCurrency(
                          role,
                          userCurrency,
                          currency,
                          userId
                        )} ${thousands_separators(+transaction.user_credits)}`
                      : `- ${getCurrency(
                          role,
                          userCurrency,
                          currency,
                          userId
                        )}${thousands_separators(
                          +Math.abs(transaction.user_credits)
                        )}`
                    : `${getCurrency(role, userCurrency, currency, userId)} 0`}
                </span>
              </>
            )}
          </p>
          <span className={styles.mobileCardDate}>
            {date.toLocaleDateString("en-US", dateOptions)}
          </span>
          <span className={styles.mobileCardTime}>
            {date.toLocaleTimeString("en-US", timeOptions)}
          </span>
        </div>
        <div
          className={styles.rowType}
          style={{ backgroundColor: rowData.pillColor }}
        >
          <span style={{ color: rowData.color }}>{rowData.type}</span>
        </div>
      </div>
    </div>
  );
}
