export const emitEvent = (event = "", email = "", mode, segment) =>
    window.userengage &&
    window.userengage(`event.${event}`, {
        email: email,
        ...(mode ? { mode } : {}),
        ...(segment ? { segment } : {}),
    });

export const pageHit = (obj = {}) => {
    window?.UE?.pageHit(obj);
};
