import React, { useCallback, useEffect, useRef, useState } from "react";
import GscLogout from "../../../../componentsV2/Pages/NewDashboard/GscLogout";
import { Box, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import { BiUserCircle } from "react-icons/bi";
import styles from "./DomainsTab.module.css";
import ManageDomainsWrapper, {
    DomainChangeCases,
} from "../../../../componentsV2/Pages/WebTune/ManageDomainsWrapper";
import ManageDomains from "../../../../componentsV2/Pages/WebTune/ManageDomains";
import useHelperFunctions from "../../../../componentsV2/helperFunctions";
import {
    BASE_URL,
    endPoints,
} from "../../../../componentsV2/Pages/InterLinking/constants";
import SVGIcon from "../../../../componentsV2/atoms/SVGIcon";
import Button from "../../../../componentsV2/atoms/Button/Button";
import useNavigation from "../../../../componentsV2/atoms/GoToRoute/useNavigation";
import Modal from "../../../../componentsV2/atoms/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import BuyMore from "../../../../componentsV2/Pages/WebTune/BuyMore";
import UpgradeModal from "../../../../componentsV2/Pages/WebTune/UpgradeModal";
import useUserAccessHook from "../../useUserAccessHook";
import { useLinkManagerMixpanel } from "../../../../componentsV2/Pages/InterLinking/useLinkManagerMixpanel";

const useStylesTab = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        color: "#3F5575",
        opacity: 1,
        height: 33,
        minHeight: 40,
        fontSize: 14,
        fontWeight: 500,
        textDecoration: "none",
        textTransform: "none",
        borderBottom: "2px solid transparent",
        padding: 0,
        paddingRight: 4,
        flexGrow: 1,
        display: "flex",
        marginRight: "32px",
        minWidth: "max-content",
        maxWidth: "110px",
        overflow: "visible",
        "&.Mui-disabled": {
            opacity: 1,
            color: "#3F5575",
        },
    },
    selected: {
        color: "#014dc5",
        fontWeight: 500,
        backgroundColor: "#fff",
        borderBottom: "4px solid #014dc5",
    },
    disabled: {
        color: "green",
        opacity: "none !important",
        pointerEvents: "none",
        "&::Mui-disabled": {
            opacity: "none !important",
        },
    },
    indicator: {
        display: "none",
    },
}));

const useStylesTabs = makeStyles((theme) => ({
    root: {
        "& .MuiTabScrollButton-root.Mui-disabled": {
            display: "none",
        },
        width: "50%",
        overflow: "auto",
        backgroundColor: "transparent",
        marginBottom: "-3px",
    },
    indicator: {
        display: "none",
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        value: index,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={
                value == index
                    ? {
                          position: "relative",
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          padding: index == 2 ? "0px" : "0px",
                      }
                    : { position: "relative" }
            }
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

const DomainsTab = () => {
    const { lMAddDomain, lMUpgradePlan, lMBuyPages } = useLinkManagerMixpanel();
    const [value, setValue] = useState(0);
    const classesTab = useStylesTab();
    const classesTabs = useStylesTabs();
    const handleChange = (event, newValue) => {
        console.log("newVal", newValue);
        setValue(newValue);
        console.log("newVal", event);
    };
    const [state, setState] = useState({
        domainsList: [],
        selectedDomainInfo: null,
        loading: true,
    });
    const max_domain_connection = useSelector(
        (state) => state.otherInfo?.subscriptionInfo?.multi_domain,
    );
    const { interlinking_status } = useSelector(
        (state) => state.otherInfo?.subscriptionInfo,
    );
    const { isLTD, freeUser } = useUserAccessHook();
    const [taDomainsLength, setTaDomainsLength] = useState(0);
    const [buyPagesModal, setBuyPagesModal] = useState(false);
    const [addFromUrl, setAddFromUrl] = useState(false);
    const [logoutModal, setLogoutModal] = useState({
        isShow: false,
        id: null,
    });
    const [upgradeModal, setUpgradeModal] = useState({ isShow: false });
    const [logoutLoading, setLogoutLoading] = useState(false);
    const goto = useNavigation();
    const dispatch = useDispatch();
    const { getData, postData } = useHelperFunctions();
    const getUserDomains = async () => {
        setState((ps) => ({
            ...ps,
            loading: true,
        }));
        const res = await getData({
            completeUrl: BASE_URL + endPoints.userDomains,
        });

        if (res.status === 200) {
            const validDomains = res.data.filter((domain) => domain);

            setState((ps) => ({
                ...ps,
                domainsList: validDomains,
                selectedDomainInfo: validDomains[0] || null,
            }));
        }
        setState((ps) => ({
            ...ps,
            loading: false,
        }));
    };

    useEffect(() => {
        getUserDomains();
    }, []);

    const logoutReq = async (domainId, gsc_id = null) => {
        setLogoutLoading(true);
        const res = await postData({
            completeUrl: BASE_URL + endPoints.logout,
            payload: {
                domain_id: domainId,
                ...(gsc_id && { gsc_id: gsc_id }),
            },
        });
        if (res.status === 200) {
            getUserDomains();
        }
        setLogoutLoading(false);
    };

    const updateGscConnect = async (
        domainName = "",
        gscId = null,
        logout = false,
        id,
    ) => {
        if (!domainName && gscId) {
            setState((ps) => {
                const _domainList = ps.domainsList.map((domain) => {
                    if (domain?.id === id) {
                        return {
                            ...domain,
                            gsc_id: gscId,
                        };
                    }
                    return domain;
                });

                const _selectedDomainInfo = {
                    ...ps.selectedDomainInfo,
                    gsc_id: gscId,
                };

                return {
                    ...ps,
                    selectedDomainInfo: _selectedDomainInfo,
                    domainsList: _domainList,
                };
            });
            return;
        }
        const res = await postData({
            url: endPoints.updateGsc,
            payload: {
                domain: domainName,
                gsc_id: gscId,
                ...(logout ? { logout: true } : { update_domain: true }),
                webtune_domain_id: id,
            },
        });
        if (res.status == 200) {
            dispatch(
                displayAlert({
                    alertType: "success",
                    alertMessage: `GSC ${
                        logout ? "Disconnected " : "Connected "
                    }Successfully`,
                }),
            );

            setState((ps) => {
                const _domainList = ps.domainsList.map((domain) => {
                    if (domain?.id === id) {
                        return {
                            ...domain,
                            // gsc_connected: logout ? 0 : 1,
                            gsc_id: logout ? null : gscId,
                        };
                    }
                    return domain;
                });
                const _selectedDomainInfo = {
                    ...ps.selectedDomainInfo,
                    // gsc_connected: logout ? 0 : 1,
                    gsc_id: logout ? null : gscId,
                };

                return {
                    ...ps,
                    selectedDomainInfo:
                        id == state.selectedDomainInfo.id
                            ? _selectedDomainInfo
                            : ps.selectedDomainInfo,
                    domainsList: _domainList,
                };
            });
        }
    };

    const handleDomainChanges = async (
        obj = {
            type: "",
            cId: null,
            dName: "",
            status: null,
            setLoader: () => {},
            gscId: null,
            domain,
        },
    ) => {
        switch (obj.type) {
            case DomainChangeCases.removeDomain:
                obj.setLoader(true);
                await logoutReq(obj.cId, obj?.gscId);
                obj.setLoader(false);
                return;
            case DomainChangeCases.removeGsc:
                await updateGscConnect(obj.dName, obj.gscId, true, obj.id);
                break;
            case DomainChangeCases.addGsc:
                updateGscConnect("", obj.gscId, false, obj.id);
                break;
            case DomainChangeCases.setCurrentDomain:
                setState((ps) => ({
                    ...ps,
                    selectedDomainInfo: obj.domain,
                }));
                break;
            default:
                return;
        }
    };
    const addTaDomainRef = useRef(null);

    return (
        <>
            <div className={styles.domainsTabWrapper}>
                <div className={styles.containerTop}>
                    <div className={styles.sectionTitle}>Manage Domains</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexGrow: 1,
                        width: "100%",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        <Tabs
                            value={value}
                            classes={classesTabs}
                            key={"Domains Tabs"}
                            onChange={handleChange}
                        >
                            <Tab
                                id={0}
                                classes={classesTab}
                                label={<>Traffic Analyzer</>}
                                {...a11yProps(0)}
                            />
                            <Tab
                                id={1}
                                classes={classesTab}
                                label={"Link Manager"}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </div>

                    <Button
                        height={30}
                        width={"100%"}
                        handler={() => {
                            if (value == 0) {
                                addTaDomainRef.current();
                            } else if (value == 1) {
                                if (!isLTD && !!state.domainsList?.length) {
                                    if (freeUser && interlinking_status == 2) {
                                        lMAddDomain({
                                            screen: "My Account Domains Tab",
                                            ctaUsed: "Add Domain",
                                            popUp: "Upgrade Plan",
                                        });
                                        setUpgradeModal({ isShow: true });
                                        return;
                                    } else if (interlinking_status == 2) {
                                        lMAddDomain({
                                            screen: "My Account Domains Tab",
                                            ctaUsed: "Add Domain",
                                            popUp: "Buy Pages",
                                        });
                                        setBuyPagesModal({ isShow: true });
                                        return;
                                    }
                                }
                                lMAddDomain({
                                    screen: "My Account Domains Tab",
                                    ctaUsed: "Add Domain",
                                });
                                goto(
                                    "LINK_MANAGER",
                                    !!state.domainsList.length &&
                                        interlinking_status != 0
                                        ? ["add=true"]
                                        : [],
                                );
                            }
                        }}
                        style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            padding: "4px 16px",
                            width: "max-content",
                            borderRadius: "6px",
                            marginRight: "2px",
                        }}
                        text={
                            value == 0
                                ? `Add new Domain (${
                                      taDomainsLength || 0
                                  }/${max_domain_connection})`
                                : "Add New Domain"
                        }
                        Icon={() => (
                            <SVGIcon
                                src={"/New UI/SVG/globePlus.svg"}
                                style={{ color: "inherit" }}
                                size={18}
                            />
                        )}
                    />
                </div>
                <TabPanel value={value} index={0}>
                    <GscLogout
                        addTaDomainRef={addTaDomainRef}
                        setTaDomainsLength={setTaDomainsLength}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {state.domainsList.length > 0 ? (
                        <ManageDomains
                            insideLM={false}
                            arr={state.domainsList}
                            setModal={() => {}}
                            setRemoveDomain={(id, gsc_id = null) => {
                                setLogoutModal({
                                    isShow: true,
                                    id: id,
                                    gscId: gsc_id,
                                });
                            }}
                            screen={"My Account Domains Tab"}
                            fetchingDomain={state.loading}
                            setBuyPagesModal={setBuyPagesModal}
                            // addFromUrl={addFromUrl}
                            onChange={handleDomainChanges}
                            // domain_id={domain_id}
                            isAdding={false}
                        />
                    ) : (
                        <div className={styles.noLmDomains}>
                            No Domains Found
                        </div>
                    )}
                </TabPanel>
            </div>
            {logoutModal.isShow && (
                <Modal setModal={setLogoutModal}>
                    <div className={styles.logoutContainer}>
                        <h2 style={{ fontWeight: "600" }}>Are You Sure?</h2>
                        <div>
                            Disconnecting the domain will remove all the fixes
                            you have done with Link Manager. Are you sure?
                        </div>
                        <div className={styles.logoutBtns}>
                            <Button
                                handler={() =>
                                    setLogoutModal({
                                        isShow: false,
                                        id: null,
                                    })
                                }
                                text="Cancel"
                                width={150}
                                secondary={true}
                                style={{
                                    fontWeight: "var(--font-weight3)",
                                }}
                            />
                            <Button
                                handler={async () => {
                                    await handleDomainChanges({
                                        type: DomainChangeCases.removeDomain,
                                        cId: logoutModal.id,
                                        gscId: logoutModal?.gscId,
                                        setLoader: setLogoutLoading,
                                    });
                                    setLogoutModal({
                                        isShow: false,
                                        id: null,
                                    });
                                }}
                                text="Disconnect"
                                width={150}
                                loading={logoutLoading}
                                style={{
                                    fontWeight: "var(--font-weight3)",
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {buyPagesModal?.isShow && (
                <BuyMore
                    setBuyPagesModal={setBuyPagesModal}
                    domainId={buyPagesModal?.domainId}
                    screen={"My Account Domains Tab"}
                />
            )}
            {upgradeModal?.isShow && (
                <UpgradeModal
                    setModal={setUpgradeModal}
                    screen={"My Account Domains Tab"}
                />
            )}
        </>
    );
};

export default DomainsTab;
