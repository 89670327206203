import React, { createContext, useEffect } from "react";
import Confirmation from "../../../atoms/ConfirmationDialog/confirmation";

import { useEditorBriefHook } from "./useEditorBriefHook";

const EditorBriefContext = createContext({
    dispatchEditorBrief: () => {},

    guideLineData: {
        word_count: 0,
        heading_count: 0,
        image_count: 0,
    },
    title: "",
    referencesEB: [],
    additionalNotesEB: "",
    additional_keywords: "",
    tone: "",
    ranks: [],

    successMsg: {},
    outlines: {
        headings: [],
        quesions: [],
    },
});
let id = null;
function EditorBriefContextProvider({
    children,
    report_id,
    isContentBrief,
    isShared,
    access,
    goNutsModal,
    reportLoading,
    setTempGuideLineData = () => {},
    seoWithCruise,
}) {
    const {
        editorBrief,
        dispatchEditorBrief,
        confirmPopup,
        loading,
        setSuccessMsg,
        successMsg,
    } = useEditorBriefHook({
        children,
        report_id,
        isShared,
        access,
        reportLoading,
        goNutsModal,
        seoWithCruise,
    });
    console.log("Editor brief", editorBrief);

    useEffect(() => {
        if (editorBrief.guideLineData)
            setTempGuideLineData(editorBrief.guideLineData);
    }, [editorBrief.guideLineData]);

    return (
        <EditorBriefContext.Provider
            value={
                isShared && !access
                    ? {
                          ...editorBrief,
                          successMsg,
                          briefLoading: loading,
                          dispatchEditorBrief: () => {},
                      }
                    : {
                          ...editorBrief,
                          successMsg,
                          briefLoading: loading,
                          dispatchEditorBrief,
                          setSuccessMsg,
                      }
            }
        >
            {children}
            {confirmPopup.state && (
                <Confirmation
                    modalHeading={"Are you sure you want to change Title?"}
                    setDialog={() =>
                        setConfirmPopup({ value: "", state: false })
                    }
                    handler={(closeDialog) => {
                        closeDialog();
                        setTitleEB(confirmPopup.value);
                    }}
                />
            )}
        </EditorBriefContext.Provider>
    );
}

export { EditorBriefContext, EditorBriefContextProvider };
