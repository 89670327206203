import Axios from "axios";

import React, { useEffect } from "react";
import { FcSynchronize } from "react-icons/fc";
import Button from "../../atoms/Button/Button";
import styles from "./styles.module.css";
import version from "../../../version.json";
import { PROD, url } from "../../../utility/config";
import * as Sentry from "@sentry/react";

const BASE_URL = url;

let timeout = null;
export const debounceWrapper = (func, time) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
        func();
    }, time);
};

export function ErrorPage({ userInfo, errorInfo }) {
    const { error } = errorInfo;

    useEffect(() => {
        Sentry.captureException(error);

        const callback = async () => {
            try {
                if (process.env.NODE_ENV == "development") return;

                const payload = `
                *PRE-PROD*
                |
                ${error?.stack || error}
                |
                *Email* : ${userInfo.email}
                *URL* : ${location.href}
                *Version* : ${version.version}`;

                Axios.post(
                    BASE_URL +
                        "/api/slackProxy/" +
                        encodeURIComponent(
                            "T072G0TG5E3/B078YGC3ER1/2eC5PlwVU555TOjGAzJDoFmB",
                        ),
                    { text: payload },
                    { headers: { content: "application/json" } },
                );
            } catch (error) {
                console.log(error);
            }
        };

        callback();

        document.getElementById("emailwecare")?.setAttribute("skip", true);
    }, []);

    return (
        <>
            <div className={styles.container}></div>
            <div className={styles.container1}>
                <h1> It’s not you, it’s us! </h1>
                <h3>
                    Please refresh the page using the button below to continue
                    using Scalenut.
                </h3>
                <p>
                    If the problem persists, let us know using Chat Support or
                    write us at&nbsp;
                    <a id="emailwecare" href="mailto: wecare@scalenut.com">
                        wecare@scalenut.com
                    </a>
                </p>

                <Button
                    handler={() => {
                        location.reload();
                    }}
                    text="Refresh"
                    Icon={FcSynchronize}
                    tertiary
                    width="200px"
                ></Button>
            </div>
        </>
    );
}
