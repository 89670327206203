import React, { useContext, useEffect, useRef, useState } from "react";
import {
    RiDeleteBin6Line,
    RiDownloadLine,
    RiDragMove2Fill,
} from "react-icons/ri";
import styles from "./EditorBrief.module.css";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextShortner from "../../../atoms/TextShortner/TextShortner";
import { useSelector } from "react-redux";
import GuideTileInd from "../../GuideTile/GuideTileInd";
import { v4 } from "uuid";

import useOnClickOutside from "../../../atoms/Modal/useOnClickOutside";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    debounce,
    makeStyles,
    TextareaAutosize,
    Typography,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { debounceWrapper } from "../../../../utility/helperFun";
import SVGIcon from "../../../atoms/SVGIcon";
import Button from "../../../atoms/Button/Button";
import ReportContext from "../../../organisms/Research/ReportContext";
import { HtmlTagToDelta } from "../../../organisms/TextEditor/Toolbar/WriteToEditor";

import { MdKeyboardArrowDown } from "react-icons/md";

import EBQ from "../../../organisms/Creator/EditorBrief/EBQ";
import EBH2 from "../../../organisms/Creator/EditorBrief/EBH2";
import EBReferences from "../../../organisms/Creator/EditorBrief/EBReferences";
import { BRIEF_TYPES } from "../../../organisms/Creator/EditorBrief/useEditorBriefHook";
import { EditorBriefContext } from "../../../organisms/Creator/EditorBrief/EditorBriefContext";
import {
    droppableIds,
    droppableTypes,
} from "../../../organisms/Creator/GoNuts/cruiseConstant";
import { CRUISE_TYPES } from "../../../organisms/Creator/GoNuts/useCruiseHook";
import { Outline } from "../../../organisms/Creator/GoNuts/classes";
import AdditionalKeywords from "../../../organisms/Creator/GoNuts/AdditionalKeywords";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import Quill from "quill";
import { BsCheck2Circle, BsDownload } from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";
import EditorContext from "../../../organisms/TextEditor/EditorContext";
import RatingHook from "./RatingHook";
import pluralize from "pluralize";
import {
    CopyOutline,
    getIframeDocument,
} from "../../../organisms/TextEditor/Options/utils";
import useHelperFunctions from "../../../helperFunctions";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
const Delta = Quill.import("delta");
let tagArr = ["h2", "h3"];

let guideLineFields = [
    { title: "Images", id: "image_count", text: "Suggested no. of images" },
    { title: "Word count", id: "word_count", text: "Suggested word count " },
    {
        title: "Headings",
        id: "heading_count",
        text: "Suggested no. of headings",
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        height: "max-content !important",
        minHeight: "45px !important",
        padding: "0 16px",
    },
    expanded: {
        height: "max-content !important",
        minHeight: "max-content !important",
        border: "none !important",
    },
    expandIcon: {
        padding: "0px 12px",
    },
    content: {
        margin: "12px 0 !important",
    },
    ["&:hover"]: {
        backgroundColor: "#f0eeeea1",
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        borderRadius: 3,
        border: "none",
        margin: "5px 0",
        boxShadow: "none",
    },
    expanded: {
        borderRadius: 3,
        border: "none !important",
        margin: "5px 0 !important",
    },
}));

function makeBriefToDelta(questions, headings, title, referencesEB) {
    const data = [];

    data.push(...HtmlTagToDelta(`Title: `, "text", { underline: true }));

    data.push(...HtmlTagToDelta(title, "h1"));

    data.push(...HtmlTagToDelta("", "br"));
    data.push(...HtmlTagToDelta("", "br"));

    data.push(...HtmlTagToDelta("Headings:", "h2", { underline: true }));

    headings.map((edx) => {
        if (edx.data) {
            data.push(...HtmlTagToDelta(edx.data, edx.tag));

            if (edx.subData)
                data.push(...HtmlTagToDelta(`Note: ${edx.subData}`, "p"));
            else data.push(...HtmlTagToDelta("", "br"));
            edx.outlineH3s.forEach((edx) => {
                if (edx.data) {
                    data.push(...HtmlTagToDelta(edx.data, edx.tag));
                    if (edx.subData)
                        data.push(...HtmlTagToDelta(`${edx.subData}`, "p"));
                }
            });
        }
    });

    data.push(...HtmlTagToDelta("", "br"));

    data.push(...HtmlTagToDelta("Questions:", "h2", { underline: true }));

    questions.map((edx) => {
        if (edx.data) {
            data.push(...HtmlTagToDelta(edx.data, "h2"));
            if (edx.subData)
                data.push(...HtmlTagToDelta(`Note: ${edx.subData}`, "p"));
            else data.push(...HtmlTagToDelta("", "br"));
        }
    });
    data.push(...HtmlTagToDelta("", "br"));

    data.push(...HtmlTagToDelta("References:", "h2", { underline: true }));

    referencesEB.map((edx) => {
        if (edx.data) {
            data.push(...HtmlTagToDelta(edx.data, "a"));
            if (edx.subData)
                data.push(...HtmlTagToDelta(`Note: ${edx.subData}`, "p"));
            else data.push(...HtmlTagToDelta("", "br"));
        }
    });

    return data;
}

const insertItem = (item, pos, list) => {
    const arr = [...list.slice(0, pos), item, ...list.slice(pos, list.length)];

    return arr;
};

const CustomInput = ({
    inputValue,
    setInputValue = () => {},
    autoFocus = false,
    onBlurHandler,
    style = {},
    placeholder = "Add your text here",
}) => {
    let [value, setValue] = useState(inputValue);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);
    const { isShared } = useContext(ReportContext);

    return (
        <TextareaAutosize
            className={styles.customInput}
            disabled={isShared && !isShared.access}
            rowsMin={1}
            rowsMax={4}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => {
                onBlurHandler(e);
                setInputValue(value);
            }}
            autoFocus={!value || autoFocus}
            style={{ ...style }}
            placeholder={placeholder}
            spellCheck={false}
        />
    );
};

const Title = ({ title, setTitleEB = () => {} }) => {
    const { isShared } = useContext(ReportContext);
    const [editTitle, setEditTitle] = useState(false);

    return (
        <>
            <div className={styles.guideLineField1}>
                <div style={{ display: "flex" }}>
                    <div className={styles.commonHeading}>
                        <span style={{ whiteSpace: "nowrap" }}> H1 Title:</span>
                        {"  "}
                        {!editTitle && (
                            <span style={{ color: "#3F5575" }}>{title}</span>
                        )}
                    </div>
                </div>
                {!isShared && (
                    <LightTooltip title={"Click to Edit"} arrow placement="top">
                        <SVGIcon
                            tooltipPlacement
                            size={16}
                            onClick={() => setEditTitle(true)}
                            src="/New UI/SVG/pencil.svg"
                        />
                    </LightTooltip>
                )}
            </div>
            {editTitle && (
                <CustomInput
                    inputValue={title}
                    setInputValue={setTitleEB}
                    onBlurHandler={() => {
                        setEditTitle(false);
                    }}
                    autoFocus={true}
                />
            )}
        </>
    );
};

const GuideLineField = ({
    item,
    guideLineData,
    setGuideLineData = () => {},
}) => {
    return (
        <div className={styles.guideLineField}>
            <div className={styles.fieldName}>
                {item.title}
                <SVGIcon
                    tooltip={item.text}
                    size={18}
                    color={"#c3c7cb"}
                    src={"/New UI/SVG/info.svg"}
                />
            </div>

            <input
                disabled={true}
                value={guideLineData[item?.id]}
                onChange={(e) => {
                    // setGuideLineData((ps) => ({
                    //     ...ps,
                    //     [item.id]: e.target.value,
                    // }));
                }}
                type="text"
                style={{ textAlign: "center" }}
            />
        </div>
    );
};

function EditorBrief({ list, editor }) {
    const {
        dispatchEditorBrief,
        title,
        outlines,
        setQuestionsEB,
        referencesEB,
        additionalNotesEB,
        successMsg,
        setSuccessMsg = () => {},
        briefLoading,
        additional_keywords,
        guideLineData,
        invalidAction,
        tone,
        ranks,
    } = useContext(EditorBriefContext);

    const classes = useStyles();
    const classes1 = useStyles1();
    const [expandGuidelines, setExpandGuidelines] = useState(true);
    const [expandOutline, setExpandOutline] = useState(false);
    const [expandKeyTerms, setExpandKeyterms] = useState(false);
    const [editKeywords, setEditKeywords] = useState(false);
    const [showReferences, setShowReferences] = useState(false);
    const [editNotes, setEditNotes] = useState(false);
    const [addNew, setAddNew] = useState(null);
    const { keyTerms, reportInfo, competetion } = useContext(ReportContext);
    const { saving } = useContext(EditorContext);
    const [_list, setList] = useState(keyTerms);
    const [isOutlineCopied, setIsOutlineCopied] = useState(false);
    const [isKeytermsCopied, setIsKeytermsCopied] = useState(false);
    const { getData } = useHelperFunctions();
    const [customUrls, setCustomUrls] = useState([]);
    const { mixpanelTrack } = useMixpanelHook();

    const getCustomUrls = async () => {
        const res = await getData({
            url: "/api/scrape-custom/get?report_id=" + reportInfo.report_id,
        });
        if (res.status == 200) {
            res.data?.data?.map((val) => (val.grade = val.optimiser_score));
            setCustomUrls(res.data?.data);
        }
    };
    useEffect(() => {
        if (ranks?.length) {
            const check = (ele) => ele < 0;
            if (ranks?.some(check)) {
                getCustomUrls();
            }
        }
    }, [ranks]);

    useEffect(() => {
        setEditKeywords(additional_keywords?.trim()?.length);
    }, [additional_keywords]);

    useEffect(() => {
        setEditNotes(additionalNotesEB?.trim()?.length);
    }, [additionalNotesEB]);

    useEffect(() => {
        setShowReferences(referencesEB.length);
    }, [referencesEB]);

    useEffect(() => {
        if (invalidAction)
            setTimeout(() => {
                dispatchEditorBrief({
                    type: CRUISE_TYPES.SET_INVALID_ACTION,
                    data: "",
                });
            }, 3000);
    }, [invalidAction]);

    const addItem = (field) => {
        setAddNew(field);

        // let outline = new Outline({tag:tag,id:v4()})

        // dispatchEditorBrief
    };

    useEffect(() => {
        if (successMsg.show) {
            setTimeout(
                () => {
                    setSuccessMsg((ps) => ({
                        show: false,
                        msg: ps.msg,
                        type: ps.type,
                    }));
                },
                !briefLoading ? 2000 : 0,
            );
        }
    }, [successMsg]);

    useEffect(() => {
        if (referencesEB?.length && !showReferences) {
            setShowReferences(true);
        } else if (referencesEB.length == 0 && showReferences) {
            setShowReferences(false);
        }
    }, [referencesEB.length]);

    const getStyleForHeadingItemOuter = (tag) => {
        switch (tag) {
            case "h2":
                return { marginLeft: 20 };
            case "h3":
                return { marginLeft: 40 };
            case "h4":
                return { marginLeft: 60 };
            case "h5":
                return { marginLeft: 60 };
            case "h6":
                return { marginLeft: 60 };

            default:
                return {};
        }
    };

    /**
     * @param {import("react-beautiful-dnd").DropResult} res */
    const onDragEnd = (res) => {
        if (!res.source || !res.destination) {
            return;
        }

        switch (res.type) {
            case droppableTypes.outlineH2:
                {
                    if (res.source.droppableId == res.destination.droppableId) {
                        if (
                            res.source.droppableId.includes(
                                droppableIds.topRanked,
                            )
                        ) {
                            return;
                        }
                        if (res.source.droppableId == droppableIds.headings) {
                            dispatchEditorBrief({
                                type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                                field: Outline.type.heading,
                                oldIndex: res.source.index,
                                newIndex: res.destination.index,
                                tag: Outline.tags.h2,
                            });
                        } else {
                            dispatchEditorBrief({
                                type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                                field: Outline.type.question,
                                oldIndex: res.source.index,
                                newIndex: res.destination.index,
                            });
                        }
                    } else if (
                        res.source.droppableId.includes(droppableIds.topRanked)
                    ) {
                        const rankIndex = res.source.droppableId.split("_")[1];
                        const hIndex = res.source.index;

                        const item =
                            topRankOutlines[rankIndex].h_tags.h[hIndex];

                        const outline = new Outline({
                            tag: Outline.tags.h2,
                            data: item.text,
                            id: v4(),
                            type: Outline.type.heading,
                        });

                        if (
                            res.destination.droppableId == droppableIds.headings
                        ) {
                            dispatchEditorBrief({
                                type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                                field: Outline.type.heading,
                                data: outline,
                                index: res.destination.index,
                            });
                        } else if (
                            res.destination.droppableId ==
                            droppableIds.questions
                        ) {
                            outline.tag = null;
                            outline.type = Outline.type.question;
                            dispatchEditorBrief({
                                type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                                field: Outline.type.question,
                                index: res.destination.index,
                                data: outline,
                            });
                        }
                    }
                }

                break;

            case droppableTypes.outlineH3: {
                if (res.source.droppableId == res.destination.droppableId) {
                    const [h2Index, h2Id] =
                        res.destination.droppableId.split("_");

                    dispatchEditorBrief({
                        type: CRUISE_TYPES.HANDLE_DRAG_OUTLINE,
                        field: Outline.type.heading,
                        oldIndex: res.source.index,
                        newIndex: res.destination.index,
                        tag: Outline.tags.h3,
                        h2Index: h2Index,
                        h2_id: h2Id,
                    });
                } else if (
                    res.source.droppableId.includes(droppableIds.topRanked)
                ) {
                    const [h2Index, h2Id] =
                        res.destination.droppableId.split("_");
                    const rankIndex = res.source.droppableId.split("_")[1];
                    const hIndex = res.source.index;

                    const item = topRankOutlines[rankIndex].h_tags.h[hIndex];

                    const outline = new Outline({
                        tag: Outline.tags.h3,
                        data: item.text,
                        id: v4(),
                        type: Outline.type.heading,
                    });

                    dispatchEditorBrief({
                        type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                        field: Outline.type.heading,
                        data: outline,
                        index: res.destination.index,
                        h2Index,
                        h2_id: h2Id,
                    });
                } else {
                    const [destinationH2Index, destinationH2Id] =
                        res.destination.droppableId.split("_");
                    const [sourceH2Index, sourceH2Id] =
                        res.source.droppableId.split("_");
                    const sourceH3Index = +res.source.index;
                    const destinationH3Index = +res.destination.index;

                    dispatchEditorBrief({
                        type: CRUISE_TYPES.DELETE_OUTLINES,
                        field: Outline.type.heading,
                        tag: Outline.tags.h3,
                        h2Index: sourceH2Index,
                        index: sourceH3Index,
                        id: outlines.headings[sourceH2Index].outlineH3s[
                            sourceH3Index
                        ].id,
                    });

                    const outlineH3 = new Outline({
                        tag: Outline.tags.h3,
                        data: outlines.headings[sourceH2Index].outlineH3s[
                            sourceH3Index
                        ].data,
                        id: v4(),
                        type: Outline.type.heading,
                    });

                    dispatchEditorBrief({
                        type: CRUISE_TYPES.ADD_ON_INDEX_OUTLINES,
                        field: Outline.type.heading,
                        h2Index: destinationH2Index,
                        index: destinationH3Index,
                        data: outlineH3,
                        h2_id: destinationH2Id,
                    });
                }
            }
        }
    };

    /**
     * @param {import("react-beautiful-dnd").DropResult} res */

    const onDropReferences = (res) => {
        if (!res.destination.droppableId || !res.source.droppableId) return;
        else {
            dispatchEditorBrief({
                type: BRIEF_TYPES.ORDER_REFERENCE,
                pickIndex: res.source.index,
                dropIndex: res.destination.index,
            });
        }
    };

    const handleH2H3Change = (h2Index) => {
        /**
             *@type {Outline} 
             
              */
        const preH2 = outlines.headings[h2Index - 1];
        /**
         *@type {Outline}
         *   */
        const currH2 = outlines.headings[h2Index];

        const outlineH3 = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: currH2.data,
            tag: Outline.tags.h3,
        });

        const outlineH3s = [];

        preH2.outlineH3s.forEach(
            /**
             * @param {Outline} element
             *  */
            (element) => {
                outlineH3s.push(new Outline(element));
            },
        );
        outlineH3s.push(outlineH3);

        currH2.outlineH3s.forEach(
            /**
             * @param {Outline} element
             *  */
            (element) => {
                outlineH3s.push(new Outline({ ...element, id: v4() }));
            },
        );

        const outlineH2 = new Outline({
            type: Outline.type.heading,
            id: preH2.id,
            data: preH2.data,
            tag: Outline.tags.h2,
            outlineH3s,
        });

        dispatchEditorBrief({
            type: CRUISE_TYPES.OUTLINE_INTER_CHANGE,
            index: h2Index,
            tag: Outline.tags.h2,
            data: outlineH2,
            id: currH2.id,
        });
    };

    useEffect(() => {
        setExpandOutline(true);
    }, [outlines, title]);

    useEffect(() => {
        (async () => {
            const rating = await RatingHook(
                document.quill?.editor?.getText(),
                keyTerms.map((item) => ({
                    ...item,
                    singular: pluralize.singular(item?.keyword),
                    plural: pluralize.plural(item?.keyword),
                })),
                "keyTerms",
            );

            setList(rating.keyTermslist);
        })();
    }, [saving, keyTerms]);

    const copyKeyTerms = () => {
        let text = "";
        list.map((term) => {
            text += `${term.keyword}\n`;
        });
        navigator.clipboard.writeText(text);
    };

    const pasteContentBrief = () => {
        let data = makeBriefToDelta(
            outlines.questions,
            outlines.headings,
            title,
            referencesEB,
        );

        if (document.quill.editor.isEnabled())
            document.quill.editor.setContents(
                new Delta()
                    .concat(
                        document.quill.editor
                            .getContents()
                            .slice(0, document.quill.editor.cursor),
                    )
                    .concat(new Delta(data))
                    .concat(
                        document.quill.editor
                            .getContents()
                            .slice(document.quill.editor.cursor),
                    ),
            );
    };
    const refArtcles = competetion.filter((ele) => ranks?.includes(ele?.rank));
    const customRef = customUrls.filter((ele) => ranks?.includes(ele?.rank));
    const urls = [...refArtcles, ...customRef];
    const getBriefFormat = ({
        guideLineData,
        references,
        notes,
        outlines,
        additional_keywords,
    }) => {
        const container = document.createElement("div");
        const guidelinesSection = document.createElement("div");
        const referenceArticles = urls
            ?.map(
                (ele) =>
                    `<ul><li><strong>Title: ${ele.url_title}</strong></li>
                <li><strong>Url: <a href="${ele.url}" target="_blank">${ele.url}</a></strong></li></ul>`,
            )
            .join("");
        const referencesList = references
            ?.map((ele) => `<li>${ele.data}</li>`)
            .join("");
        guidelinesSection.innerHTML = `
        <p><strong>1. General Instructions</strong></p>
        <p><br /></p>
        <p><strong>A. Word Count: ${guideLineData.word_count}</strong></p>
        <p><strong>B. Image Count: ${guideLineData.image_count}</strong></p>
        <p><strong>C. No. of Headings: ${
            guideLineData.heading_count
        }</strong></p>
        <p><strong>D. Tone: ${tone ? tone : "-"}</strong></p>
        <p><strong>E. Additional Keywords: ${additional_keywords}</strong></p>
        <p><strong>F. Additional References: </strong></p>
        <strong><ol>${referencesList}</ol></strong>
        <p><strong>G. Additional Notes: ${notes}</strong></p>
        <p><strong>H. Reference Articles:</strong></p>
        ${referenceArticles}
        <p><br/></p>
        `;
        container.appendChild(guidelinesSection);

        const outlinesSection = document.createElement("div");
        let text = "";
        for (const h2 of outlines.headings) {
            text += `<p><strong>H2</strong>: ${h2.data}</p>`;
            for (const h3 of h2.outlineH3s) {
                text += `<p><strong>H3</strong>: ${h3.data}</p>`;
            }
        }
        let questions = "";
        for (const question of outlines.questions) {
            questions += `<p><strong>H3</strong>: ${question.data}</p>`;
        }
        outlinesSection.innerHTML = `
        <p><strong>2. Outlines</strong></p>
        <p><br /></p>
        <p><strong>H1 Title</strong>: ${title}</p>
        <p><br /></p>
        <p><strong>Headings:</strong></p>
        <p>${text}</p>
        <p><strong>Questions:</strong></p>
        <p>${questions}</p>
        `;

        container.appendChild(outlinesSection);

        const keyTermsSection = document.createElement("div");
        let keytermsMap = {};

        keyTerms.forEach((term) => {
            const { keyword, importance } = term;
            if (!keytermsMap[importance]) {
                keytermsMap[importance] = [];
            }
            keytermsMap[importance].push(keyword);
        });
        const sortedImportances = Object.keys(keytermsMap).sort(
            (a, b) => b - a,
        );
        let keytermsRows = "";
        sortedImportances.forEach((importance) => {
            const terms = keytermsMap[importance].join(", ");
            keytermsRows += `<tr><td style="border:1px solid; border-collapse:collapse">${terms}</td><td style="border:1px solid; border-collapse:collapse">${importance}</td></tr>`;
        });

        keyTermsSection.innerHTML = `
        <p><strong>3. Key terms to include:</strong></p>
        <p><br /></p>
        <table style="border:1px solid; border-collapse:collapse">
            <thead>
                <tr>
                    <td style="border:1px solid; border-collapse:collapse"><strong>Terms</strong></td>
                    <td style="border:1px solid; border-collapse:collapse"><strong>Importance</strong></td>
                </tr>
            </thead>
            <tbody>
                ${keytermsRows}
            </tbody>
        </table>
        `;

        container.appendChild(keyTermsSection);
        return container;
    };

    const downloadBriefAsPDF = ({
        guideLineData,
        references,
        notes,
        outlines,
        additional_keywords,
    }) => {
        const data = getBriefFormat({
            guideLineData,
            references,
            notes,
            outlines,
            additional_keywords,
        });
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        const printDocument = getIframeDocument(iframe);

        const text = data.innerHTML;

        printDocument.write(
            `<html>
      <head>
      <style>
        body{
          font-family:sans-serif;
        }
        </style>
        </head>
        <body>
        ${text}
        </body>
        </html>`,
        );

        iframe.focus();

        try {
            const printed = iframe.contentWindow.document.execCommand(
                "print",
                false,
                null,
            );
            if (printed === false) iframe.contentWindow.print();
        } catch (e) {
            console.log(e);
        } finally {
            iframe?.remove && iframe?.remove();
        }
    };

    const downloadBriefAsDoc = ({
        guideLineData,
        references,
        notes,
        outlines,
        additional_keywords,
    }) => {
        try {
            let filename = reportInfo.title + "_general_guidelines.doc";
            const data = getBriefFormat({
                guideLineData,
                references,
                notes,
                outlines,
                additional_keywords,
            });
            var preHtml = `<!DOCTYPE html>
            <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40">
            <head>
            <meta charset="UTF-8">
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
            <meta name="ProgId" content="Word.Document">
            </head>
            <body>`;
            var postHtml = "</body></html>";
            var html = preHtml + data?.innerHTML + postHtml;

            // const blob = await HTMLtoDOCX(html)
            // const url = URL.createObjectURL(blob)

            // for doc
            var blob = new Blob(["\ufeff", html], {
                type: "application/msword",
            });
            // Specify link url
            var url =
                "data:application/vnd.ms-word;charset=utf-8," +
                encodeURIComponent(html);

            // Specify file name

            // Create download link element
            var downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = url;
                downloadLink.setAttribute("skip", true);

                // Setting the file name
                downloadLink.download = filename;

                //triggering the function
                downloadLink.click();
            }

            document.body.removeChild(downloadLink);
        } catch (error) {
            console.log(error);
        }
    };

    const GuideLines = () => {
        const { isShared } = useContext(ReportContext);

        return (
            <Accordion
                classes={classes1}
                defaultExpanded
                expanded={expandGuidelines}
            >
                <AccordionSummary
                    onClick={() => {
                        setExpandGuidelines(!expandGuidelines);
                    }}
                    expandIcon={
                        <MdKeyboardArrowDown size={25} color={"#3F5575"} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={classes}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <h4 style={{ margin: 0, fontSize: "16px" }}>
                            General Instructions
                        </h4>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{ padding: "0 16px", marginBottom: "20px" }}
                >
                    <div className={styles.dataContainer}>
                        {
                            <>
                                <div className={styles.guideLineFields}>
                                    {guideLineFields.map((item) => (
                                        <GuideLineField
                                            guideLineData={guideLineData}
                                            setGuideLineData={() => {}}
                                            item={item}
                                        />
                                    ))}
                                </div>

                                <div className={styles.commonContainer}>
                                    {!editKeywords ? (
                                        <div
                                            className={styles.sectionEdit}
                                            onClick={() => {
                                                if (!isShared) {
                                                    setEditKeywords(true);
                                                }
                                            }}
                                        >
                                            Add Additional Keywords
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className={
                                                    styles.guideLineField1
                                                }
                                            >
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <div
                                                        className={
                                                            styles.commonHeading
                                                        }
                                                    >
                                                        Additional Keywords:
                                                    </div>
                                                </div>
                                            </div>

                                            <AdditionalKeywords
                                                additional_keywords={
                                                    additional_keywords
                                                }
                                                handleDataOnBlur={(
                                                    value,
                                                    field,
                                                ) => {
                                                    if (!isShared) {
                                                        dispatchEditorBrief({
                                                            type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                                            data: value,
                                                            field,
                                                        });
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </div>

                                <div className={styles.commonContainer}>
                                    {!showReferences ? (
                                        <div
                                            className={styles.sectionEdit}
                                            onClick={() => {
                                                if (!isShared) {
                                                    setShowReferences(true);
                                                    addItem("references");
                                                }
                                            }}
                                        >
                                            Add References
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className={
                                                    styles.guideLineField1
                                                }
                                            >
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <div
                                                        className={
                                                            styles.commonHeading
                                                        }
                                                    >
                                                        References:
                                                    </div>
                                                </div>
                                                {!isShared && (
                                                    <SVGIcon
                                                        onClick={() =>
                                                            addItem(
                                                                "references",
                                                            )
                                                        }
                                                        size={16}
                                                        src="/New UI/SVG/pencil.svg"
                                                    />
                                                )}
                                            </div>
                                            <DragDropContext
                                                onDragEnd={onDropReferences}
                                            >
                                                <Droppable droppableId="droppable">
                                                    {(provided) => (
                                                        <div
                                                            className={
                                                                styles.headingsDiv
                                                            }
                                                            {...provided.droppableProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                        >
                                                            {referencesEB.length >
                                                                0 &&
                                                                referencesEB.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <Draggable
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            draggableId={item.id?.toString()}
                                                                            index={
                                                                                index
                                                                            }
                                                                        >
                                                                            {(
                                                                                provided,
                                                                            ) => (
                                                                                <div
                                                                                    className={
                                                                                        styles.headingItemOuter
                                                                                    }
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                    ref={
                                                                                        provided.innerRef
                                                                                    }
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={{
                                                                                        ...provided
                                                                                            .draggableProps
                                                                                            ?.style,
                                                                                        ...getStyleForHeadingItemOuter(
                                                                                            item.tag,
                                                                                        ),
                                                                                    }}
                                                                                >
                                                                                    <EBReferences
                                                                                        item={
                                                                                            item
                                                                                        }
                                                                                        index={
                                                                                            index
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ),
                                                                )}
                                                            {
                                                                provided.placeholder
                                                            }
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            {addNew == "references" && (
                                                <CustomInput
                                                    inputValue={""}
                                                    onBlurHandler={(e) => {
                                                        if (
                                                            e?.target?.value.trim()
                                                        ) {
                                                            const v =
                                                                e?.target
                                                                    ?.value;
                                                            dispatchEditorBrief(
                                                                {
                                                                    type: BRIEF_TYPES.ADD_REFERENCE,
                                                                    data: {
                                                                        id: v4(),
                                                                        data: v,
                                                                        subData:
                                                                            "",
                                                                    },
                                                                },
                                                            );
                                                        }
                                                        setAddNew(null);
                                                    }}
                                                    placeholder={" "}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className={styles.commonContainer}>
                                    {!editNotes ? (
                                        <div
                                            className={styles.sectionEdit}
                                            onClick={() => {
                                                if (!isShared) {
                                                    setEditNotes(true);
                                                }
                                            }}
                                        >
                                            Add Additional Notes
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className={
                                                    styles.guideLineField1
                                                }
                                            >
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <div
                                                        className={
                                                            styles.commonHeading
                                                        }
                                                    >
                                                        Additional Notes:
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <CustomInput
                                                    inputValue={
                                                        additionalNotesEB
                                                    }
                                                    onBlurHandler={(e) => {
                                                        if (!isShared) {
                                                            // if (
                                                            //     e?.target?.value.trim()
                                                            // ) {
                                                            const v =
                                                                e?.target
                                                                    ?.value ||
                                                                "";
                                                            dispatchEditorBrief(
                                                                {
                                                                    type: BRIEF_TYPES.ADD_ADDITIONAL_NOTES,
                                                                    data: v,
                                                                },
                                                            );
                                                            // }
                                                            setEditNotes(false);
                                                        }
                                                    }}
                                                    placeholder={" "}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const OutlineJSX = () => {
        const { isShared } = useContext(ReportContext);

        return (
            <Accordion
                defaultExpanded
                classes={classes1}
                expanded={expandOutline}
            >
                <AccordionSummary
                    classes={classes}
                    onClick={() => {
                        setExpandOutline(!expandOutline);
                    }}
                    expandIcon={
                        <MdKeyboardArrowDown size={25} color={"#3F5575"} />
                    }
                >
                    <h4 style={{ margin: 0, fontSize: "16px" }}>
                        Outline
                        {editor && (
                            <LightTooltip
                                arrow
                                title={`${
                                    isOutlineCopied ? "Copied" : "Copy"
                                } to Clipboard`}
                            >
                                <div
                                    style={{
                                        width: "30px",
                                        height: "26px",
                                        borderRadius: "3px",
                                        background: "#edf1f9",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // pasteContentBrief();
                                        CopyOutline(
                                            outlines,
                                            title || reportInfo.title,
                                            true,
                                        );
                                        setIsOutlineCopied(true);
                                        setTimeout(() => {
                                            setIsOutlineCopied(false);
                                        }, 2000);
                                        mixpanelTrack("Copy Outline(Brief)");
                                    }}
                                >
                                    {isOutlineCopied ? (
                                        <BsCheck2Circle
                                            size={14}
                                            color="green"
                                        />
                                    ) : (
                                        <AiOutlineCopy
                                            size={14}
                                            color="var(--primary-blue)"
                                            fillOpacity={0.8}
                                        />
                                    )}
                                </div>
                            </LightTooltip>
                        )}
                    </h4>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0 16px" }}>
                    <div className={styles.dataContainer}>
                        <div className={styles.guideLineField1}>
                            Headings{" "}
                            {!isShared && (
                                <SVGIcon
                                    size={16}
                                    onClick={() => addItem("headings")}
                                    src="/New UI/SVG/pencil.svg"
                                />
                            )}
                        </div>
                        <Title
                            title={title}
                            setTitleEB={(value) => {
                                if (!isShared) {
                                    dispatchEditorBrief({
                                        type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
                                        data: value,
                                        field: "title",
                                    });
                                }
                            }}
                        ></Title>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                                type={droppableTypes.outlineH2}
                                droppableId={droppableIds.headings}
                            >
                                {(provided) => (
                                    <div
                                        className={styles.headingsDiv}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {outlines.headings.map(
                                            (item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id?.toString()}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            className={
                                                                styles.headingItemOuter
                                                            }
                                                            key={item.id}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                        >
                                                            <EBH2
                                                                item={item}
                                                                index={index}
                                                                isHeading={true}
                                                                provided={
                                                                    provided
                                                                }
                                                                dispatchEditorBrief={
                                                                    dispatchEditorBrief
                                                                }
                                                                handleH2H3Change={
                                                                    handleH2H3Change
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ),
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            {addNew == "headings" && (
                                <CustomInput
                                    inputValue={""}
                                    onBlurHandler={(e) => {
                                        if (e?.target?.value.trim()) {
                                            const v = e?.target?.value;
                                            dispatchEditorBrief({
                                                type: CRUISE_TYPES.ADD_OUTLINES,
                                                data: new Outline({
                                                    tag: Outline.tags.h2,
                                                    id: v4(),
                                                    data: v,
                                                    field: Outline.type.heading,
                                                }),
                                            });
                                        }
                                        setAddNew(null);
                                    }}
                                    placeholder={" "}
                                />
                            )}
                            <div className={styles.guideLineField1}>
                                Frequently Asked Questions{" "}
                                {!isShared && (
                                    <SVGIcon
                                        size={16}
                                        onClick={() => addItem("questions")}
                                        src="/New UI/SVG/pencil.svg"
                                    />
                                )}
                            </div>
                            <Droppable
                                type={droppableTypes.outlineH2}
                                droppableId={droppableIds.questions}
                            >
                                {(provided) => (
                                    <div
                                        className={styles.headingsDiv}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {outlines.questions.map(
                                            (item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id?.toString()}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            className={
                                                                styles.headingItemOuter
                                                            }
                                                            key={item.id}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                        >
                                                            <EBQ
                                                                item={item}
                                                                index={index}
                                                                provided={
                                                                    provided
                                                                }
                                                                dispatchEditorBrief={
                                                                    dispatchEditorBrief
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ),
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            {addNew == "questions" && (
                                <CustomInput
                                    inputValue={""}
                                    onBlurHandler={(e) => {
                                        if (e?.target?.value.trim()) {
                                            const v = e?.target?.value;
                                            dispatchEditorBrief({
                                                type: CRUISE_TYPES.ADD_OUTLINES,
                                                data: new Outline({
                                                    tag: Outline.tags.question,
                                                    id: v4(),
                                                    data: v,
                                                }),
                                                field: Outline.type.question,
                                            });
                                        }
                                        setAddNew(null);
                                    }}
                                    placeholder={" "}
                                />
                            )}
                        </DragDropContext>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const KeyTermsSection = (
        <Accordion defaultExpanded classes={classes1} expanded={expandKeyTerms}>
            <AccordionSummary
                classes={classes}
                onClick={() => {
                    setExpandKeyterms(!expandKeyTerms);
                }}
                expandIcon={<MdKeyboardArrowDown size={25} color={"#3F5575"} />}
            >
                <h4 style={{ margin: 0, fontSize: "16px" }}>
                    Key Terms
                    {editor && (
                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={`${
                                isKeytermsCopied ? "Copied" : "Copy"
                            } to Clipboard`}
                        >
                            <div
                                style={{
                                    width: "30px",
                                    height: "26px",
                                    borderRadius: "3px",
                                    background: "#edf1f9",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    copyKeyTerms();
                                    setIsKeytermsCopied(true);
                                    setTimeout(() => {
                                        setIsKeytermsCopied(false);
                                    }, 2000);
                                    mixpanelTrack("Copy Key Terms(Brief)");
                                }}
                            >
                                {isKeytermsCopied ? (
                                    <BsCheck2Circle size={14} color="green" />
                                ) : (
                                    <AiOutlineCopy
                                        size={14}
                                        color="var(--primary-blue)"
                                        fillOpacity={0.8}
                                    />
                                )}
                            </div>
                        </LightTooltip>
                    )}
                </h4>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0 16px" }}>
                <div className={styles.keyTermsWrapper}>
                    {_list?.map((term) => (
                        <div
                            style={
                                term.keywordUsage
                                    ? {
                                          textDecoration: "line-through",
                                          background: "#e1e4ed",
                                          color: "#6b6b6b",
                                          opacity: 0.6,
                                      }
                                    : {}
                            }
                            className={styles.keyTermContainer}
                        >
                            {term?.keyword}
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );

    console.log(_list, "hhhhhhh");

    return (
        <>
            <div
                className={styles.mainContainer}
                style={{ height: `calc(100vh - ${editor ? 150 : 82}px)` }}
            >
                {!editor && (
                    <div className={styles.topBar}>
                        <div className={styles.topBarTitleDiv}>
                            {/* <FiFileText size={13.5} /> */}
                            Content Brief
                            {/* {tour.enable && !seo_tour && nudgeStep == 1 && (
                      <GuideTileInd
                        tipStyle={{ left: "0", bottom: "0" }}
                        boxStyle={{ left: "-5px", top: "40px" }}
                        nextButton={true}
                        heading={"Your Content Brief"}
                        text={
                          "Your SEO Article is ready, you can use 'Outlines' and 'Questions' section to populate Content Brief seamlessly."
                        }
                        nextButtonText={"Next"}
                        nextButtonHandler={() => setNudgeStep(2)}
                      />
                    )} */}
                        </div>
                    </div>
                )}

                {briefLoading ? (
                    <div
                        style={{ margin: "30px" }}
                        className={styles.dataContainer}
                    >
                        <Skeleton width={"80%"} />
                        <Skeleton width={"40%"} />
                        <Skeleton width={"80%"} />
                        <Skeleton width={"40%"} />
                        <Skeleton width={"80%"} />
                        <Skeleton width={"40%"} />
                        <Skeleton width={"80%"} />
                        <Skeleton width={"40%"} />
                    </div>
                ) : (
                    <>
                        <div className={styles.briefHeader}>
                            <LightTooltip
                                interactive
                                tooltipStyle={{
                                    border: "none",
                                    margin: 0,
                                    padding: 0,
                                }}
                                placement="bottom-start"
                                title={
                                    <div
                                        className={styles.downloadOptionWrapper}
                                    >
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                downloadBriefAsDoc({
                                                    guideLineData:
                                                        guideLineData,
                                                    references: referencesEB,
                                                    notes: additionalNotesEB,
                                                    outlines: outlines,
                                                    additional_keywords:
                                                        additional_keywords,
                                                });
                                                mixpanelTrack(
                                                    "Download Content Guidelines",
                                                    { action: "Doc" },
                                                );
                                            }}
                                            className={styles.downloadOption}
                                        >
                                            <SVGIcon src="/New UI/SVG/Doc-icon.svg" />
                                            <div>Download as Doc</div>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                downloadBriefAsPDF({
                                                    guideLineData:
                                                        guideLineData,
                                                    references: referencesEB,
                                                    notes: additionalNotesEB,
                                                    outlines: outlines,
                                                    additional_keywords:
                                                        additional_keywords,
                                                });
                                                mixpanelTrack(
                                                    "Download Content Guidelines",
                                                    { action: "PDF" },
                                                );
                                            }}
                                            className={styles.downloadOption}
                                        >
                                            <SVGIcon src="/New UI/SVG/PDF-icon.svg" />
                                            <div>Download as PDF</div>
                                        </div>
                                    </div>
                                }
                            >
                                <div className={styles.downloadBtnWrapper}>
                                    {/* <div>Download</div> */}
                                    <div className={styles.downloadIcon}>
                                        <RiDownloadLine
                                            color="#014dc5"
                                            size={"15"}
                                            style={{ marginRight: "6px" }}
                                        />
                                        Download Guidelines
                                    </div>
                                </div>
                            </LightTooltip>
                        </div>

                        <GuideLines />
                        <div className={styles.separator}></div>
                        <OutlineJSX />
                        <div className={styles.separator}></div>
                        {KeyTermsSection}
                    </>
                )}
            </div>

            <>
                {
                    <div
                        className={`${styles.errorMsg}  ${
                            invalidAction ? styles.show : ""
                        } `}
                    >
                        <TextShortner
                            tooltip={false}
                            data={invalidAction}
                            limit={50}
                        />
                    </div>
                }
            </>
        </>
    );
}

export default EditorBrief;
