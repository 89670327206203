import React, { useEffect, useRef, useState } from "react";
import SVGIcon from "../../../../atoms/SVGIcon";
import ParagraphBox, { ParagraphRegens } from "./ParagraphBox";
import styles from "./FirstDraft.module.css";

import useMixpanelHook from "../../../../../utility/useMixpanelHook";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    ClickAwayListener,
    makeStyles,
} from "@material-ui/core";
import { useCallback } from "react";
import { CruiseContext, CruiseState } from "../StepsWizard";
import { useContext } from "react";
import { CRUISE_TYPES } from "../useCruiseHook";
import { Outline } from "../classes";
import { cruiseMixPanelEvents, droppableTypes } from "../cruiseConstant";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ReportContext from "../../../Research/ReportContext";
import { RegenBtn } from "./FirstDraft";
import { VscTrash } from "react-icons/vsc";
const useStyles1 = makeStyles((theme) => ({
    root: {
        borderRadius: 3,
        border: "none",
        margin: "0",
        boxShadow: "none",
        padding: 0,
        minHeight: "unset",
        cursor: "unset !important",
    },
    expanded: {
        borderRadius: 3,
        border: "none !important",
        margin: "0px 0 !important",
        minHeight: "unset !important",
    },
    content: {
        margin: 0,
    },
    expandIcon: {
        padding: 2.5,
        marginRight: 0,
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
    },
}));

const useStyles3 = makeStyles((theme) => ({
    root: {
        backgroundColor: "transparent",
    },
}));
/**
 *@param {{item:Outline}}  */

const HeadingBox = ({
    item,
    index,
    provided,
    questions,
    reGenerateParagraph,
    isLoader,
    h2Index,
}) => {
    const paraRef = useRef(null);
    const { mpTrackCruiseRegenerate, mixpanelTrack } = useMixpanelHook();
    const [regenHistory, setRegenHitsory] = useState([]);
    const [expandHistory, setExpandHistroy] = useState(false);
    const { reportInfo } = useContext(ReportContext);
    const dispatchStepsData = useContext(CruiseContext);

    const [isGenerating, setIsGenerating] = useState(false);

    const handleGenerater = useCallback(
        async (callback, isCustom, customInput) => {
            setIsGenerating(true);

            // await generateParagraph(item?.tag ? item?.tag : "question", index);
            setRegenHitsory((ps) => [{ text: item.para }, ...ps]);
            const text = await (callback && !isCustom
                ? callback(item.para, item, h2Index)
                : reGenerateParagraph(
                      { ...item, h2Index, index },
                      isCustom,
                      customInput,
                  ));
            if (text) {
                _setHeadingGenPara(text);
            } else {
                setRegenHitsory((ps) => ps.slice(1, ps.length));
            }
            // if (text) _setHeadingGenPara(text, index);**
            setIsGenerating(false);
            // if (text?.length) setIsGenerating(false)
        },
        [item, index],
    );

    const _setHeadingGenPara = useCallback(
        (text, _index) => {
            dispatchStepsData({
                type: CRUISE_TYPES.EDIT_PARA,
                index: _index == undefined ? index : _index,
                parentTag: questions ? Outline.tags.question : item.tag,
                para: text,
                h2Index,
            });
        },

        [],
    );

    useEffect(() => {
        if (isGenerating) {
            setExpandHistroy(true);
        }
    }, [isGenerating]);

    function deleteOutline() {
        mixpanelTrack(cruiseMixPanelEvents.delete, {
            "Screen Name": "First Draft",
            "Deleted Section":
                item.tag == "h2" ? "H2" : item.tag == "h3" ? "H3" : "FAQ",
        });
        dispatchStepsData({
            type: CRUISE_TYPES.DELETE_OUTLINES,
            index: index,
            field: questions ? Outline.type.question : Outline.type.heading,
            id: item.id,
            tag: item.tag,
            h2Index,
            step: 5,
        });
    }

    return (
        <div
            className={styles.headingBox}
            ref={item?.para?.length ? paraRef : null}
            id={item?.id}
        >
            <ClickAwayListener onClickAway={() => setExpandHistroy(false)}>
                <div>
                    <CachedHeadingBox
                        handleGenerater={handleGenerater}
                        provided={provided}
                        item={item}
                        isGenerating={isGenerating}
                        questions={questions}
                        index={index}
                        setHeadingGenPara={_setHeadingGenPara}
                        reGenerateParagraph={reGenerateParagraph}
                        isLoader={isLoader}
                        regenHistory={regenHistory}
                        expandHistory={expandHistory}
                        setExpandHistroy={setExpandHistroy}
                        setRegenHitsory={setRegenHitsory}
                        deleteOutline={deleteOutline}
                    />
                </div>
            </ClickAwayListener>
        </div>
    );
};

const CachedHeadingBox = React.memo(
    /**
     *@param {{item:Outline}}  */
    ({
        handleGenerater,
        provided,
        item,
        isGenerating,
        questions,
        index,
        setHeadingGenPara = () => {},
        reGenerateParagraph,
        isLoader,
        regenHistory,
        expandHistory,
        setExpandHistroy,
        setRegenHitsory,
        deleteOutline,
    }) => {
        const classes = useStyles1();
        const classes2 = useStyles();
        const classes3 = useStyles3();
        console.log("123456question");
        const [open, setOpen] = useState(true);

        const ref = useRef();

        // useEffect(() => {
        //     if (isLoader && item?.para?.length) {
        //     }
        // }, [isLoader, item?.para?.length]);
        return (
            <Accordion
                defaultExpanded
                style={{ boxShadow: "none", margin: "0" }}
                expanded={open}
                classes={classes3}
            >
                <AccordionSummary
                    classes={classes}
                    expandIcon={
                        <div onClick={() => setOpen(!open)}>
                            <SVGIcon
                                src={"/New UI/SVG/RiArrowDownSFill.svg"}
                                size={24}
                                style={{
                                    fontWeight: "700",
                                    color: "#c9e3ff",
                                }}
                            />
                        </div>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        margin: "0 !important",
                        backgroundColor: expandHistory ? "#F1F4FD" : "white",
                    }}
                >
                    <div ref={ref} className={styles.h2HeadingRow}>
                        <div className={styles.h2Left}>
                            <div className={styles.h2CollapseIcon}>
                                {provided && (
                                    <div
                                        className={styles.dragIcon}
                                        {...provided.dragHandleProps}
                                    >
                                        <SVGIcon
                                            color="lightgray"
                                            size={20}
                                            src="/New UI/SVG/drag.svg"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.h2Type}>
                                {questions ? `Q${index + 1}` : item.tag}
                            </div>
                            <div
                                className={styles.h2Text}
                                style={{
                                    fontWeight: item?.tag == "h3" ? 400 : "",
                                }}
                            >
                                {item?.data}
                            </div>
                        </div>
                        <div className={styles.deleteIconWrap}>
                            {item?.para?.length ? (
                                <div className={styles.paraWordCount}>
                                    {item?.para?.trim()?.length > 0
                                        ? item?.para.split(" ").length
                                        : "0"}{" "}
                                    Words
                                </div>
                            ) : (
                                ""
                            )}

                            <RegenBtn
                                loading={isGenerating}
                                regenMethod={handleGenerater}
                                expandHistory={expandHistory}
                                tag={item?.tag}
                            />

                            {regenHistory?.length && !expandHistory ? (
                                <div
                                    onClick={() => setExpandHistroy(true)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={styles.regenHistoryIcon}
                                >
                                    <SVGIcon
                                        color="darkgray"
                                        src="/New UI/SVG/regenrate Icon.svg"
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={styles.deleteIcon}>
                            <VscTrash
                                onClick={() => deleteOutline()}
                                color="#fc5a5a"
                                cursor={"pointer"}
                            />
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        backgroundColor: expandHistory ? "#F1F4FD" : "white",
                    }}
                    classes={classes2}
                >
                    <ParagraphBox
                        text={item?.para}
                        tag={item?.tag}
                        index={index}
                        setPara={setHeadingGenPara}
                        isShimmer={
                            isGenerating ||
                            (isLoader && item?.para.length == 0) ||
                            (isLoader && !item.para)
                        }
                    />
                    {expandHistory && (
                        <div>
                            <ParagraphRegens
                                arr={regenHistory}
                                setHeadingGenPara={(text, regenIndex) => {
                                    setHeadingGenPara(text);
                                    setRegenHitsory((ps) => {
                                        if (item?.para)
                                            ps[regenIndex].text = item?.para;
                                        else {
                                            ps.splice(regenIndex, 1);
                                        }
                                        return [...ps];
                                    });
                                }}
                            />
                        </div>
                    )}
                    <>
                        {questions || item.tag == Outline.tags.h3 ? (
                            <></>
                        ) : (
                            <div className={styles.headingSection}>
                                <Droppable
                                    type={droppableTypes.outlineH3}
                                    droppableId={String(`${index}_${item.id}`)}
                                >
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{ padding: 1.5 }}
                                        >
                                            {item.outlineH3s.map((item, i) => {
                                                return (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={String(
                                                            item.id,
                                                        )}
                                                        index={i}
                                                    >
                                                        {(provided, snap) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                style={{
                                                                    ...provided
                                                                        .draggableProps
                                                                        .style,
                                                                    border: snap.isDragging
                                                                        ? "2px solid var(--primary-blue)"
                                                                        : "2px solid transparent",
                                                                }}
                                                            >
                                                                <HeadingBox
                                                                    item={item}
                                                                    index={i}
                                                                    provided={
                                                                        provided
                                                                    }
                                                                    h2Index={
                                                                        index
                                                                    }
                                                                    reGenerateParagraph={
                                                                        reGenerateParagraph
                                                                    }
                                                                    isLoader={
                                                                        isLoader
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        )}
                    </>
                </AccordionDetails>
            </Accordion>
        );
    },
);

export default HeadingBox;
