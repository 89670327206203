import React, { useEffect, useState } from "react";

function RenderInChucks({ list = [], children }) {
    const [chunkSize, setChunkSize] = useState(4);
    const [listLength, setListLength] = useState(list.length);

    useEffect(() => {
        if (listLength >= chunkSize)
            setTimeout(() => {
                setChunkSize((ps) => {
                    return ps + 4;
                });
            }, 250);
    }, [chunkSize, listLength]);

    useEffect(() => {
        setListLength(list.length);
    }, [list.length]);

    return <>{list.filter((v, i) => i < chunkSize).map(children)}</>;
}

export default RenderInChucks;
