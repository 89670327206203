import { Modal, StylesProvider } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "./modal.module.css";
import styles2 from "../../dashboard.module.css";
import Activity from "./activity/Activity";
import Details from "./details/index";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoMdRefresh } from "react-icons/io";
import { url } from "../../../../utility/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProjectDetailModalSkeleton from "../../../../componentsV2/atoms/ProjectDetailModalSkeleton/ProjectDetailModalSkeleton";
import { addProjects } from "../../../../store/actions/dashboard";
import useHelperFunction from "../../../../componentsV2/helperFunctions";
import {
  PROJECT_DESCRIPTION,
  BRAND_PROFILE,
  GET_HISTORY,
} from "../../../../componentsV2/api.json";

function getStageIndex(id, _stages) {
  try {
    for (const i in _stages) {
      if (_stages[i].stage_assinee_id == id) {
        console.log(i, "-------------------------");
        return +i;
      }
    }
    return 0;
  } catch {
    return 0;
  }
}

export default function ProjectDetailsModal({
  isModalOpen,
  id,
  variant_name,
  icon,
  setIsModalOpen,
  setProjectName,
  statusName,
  setStatusName,
  projectName,
}) {
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = useState(null);
  const [timelineData, setTimelineData] = useState(null);
  const ref = useRef();
  const [_variant_name, setVariantName] = useState("");
  const [_icon, setIcon] = useState("");
  const [brandProfile, setProfile] = useState(null);
  const [activeTab, setActiveTab] = useState("freelancer");
  const [didAssign, setDidAssign] = useState(false);
  const [noFreelancerAssignedError, setNoFreelancerAssignedError] = useState(
    false
  );
  const [tabSwitchLoading, setTabSwitchLoading] = useState(false);
  const [doc_link, setDocLInk] = useState(null);
  const [words, setWords] = useState(null);
  const [c_doc_link, setCDocLInk] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const dispatch = useDispatch();
  // let doc_link=null;

  const role = useSelector((state) => state.auth.role);

  const userID = useSelector((state) => state.auth.userId);

  const [tempComments, setTempComments] = useState([]);

  const { getData } = useHelperFunction();
  function sentenceCase(str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    return "";
  }

  useEffect(() => {
    let userId = 0;
    if (data) setData(null);
    setTimeout(() => {
      (async () => {
        const response = await getData({ url: PROJECT_DESCRIPTION + id });

        if (response?.status == 200) {
          setData(response.data.data);

          dispatch(addProjects(response?.data?.data?.projectDetail));
          setDocLInk(response?.data?.data?.projectDetail?.freelancer_doc_file);

          projectName = response?.data?.data?.projectDetail?.name;
          statusName = response?.data?.data?.projectDetail?.status;
          setVariantName(response?.data?.data?.projectDetail?.variant_name);
          setIcon(response?.data?.data?.projectDetail?.icons);
          userId = response?.data?.data?.projectDetail?.user_id;
          if (
            role == "admin" ||
            role == "account_manager" ||
            role == "free_lancer"
          ) {
            const res = await getData({
              url: BRAND_PROFILE + "?userId=" + userId,
            });
            if (res?.status == 200) {
              setProfile(res.data.data);
            } else {
              setProfile(null);
            }
          }
        } else {
          setIsModalOpen(false);
        }
      })();
    }, 500);
  }, [refresh]);

  useEffect(() => {
    setTimelineData([]);
    setTempComments([]);
    setTabSwitchLoading(true);

    setTimeout(async () => {
      (async () => {
        const res = await getData({
          url:
            GET_HISTORY +
            `?projectID=${id}&isFreelancer=${
              role == "user" ||
              ((role == "admin" || role == "account_manager") &&
                activeTab === "client")
                ? false
                : true
            }`,
        });

        if (res?.status == 200) {
          const reversedData = res.data.data.reverse();
          setTimelineData(reversedData);
          setTabSwitchLoading(false);
        } else {
          setTabSwitchLoading(false);
        }
      })();
    }, 500);

    // }
  }, [activeTab]);

  useEffect(() => {
    try {
      let currentStage = {};

      if (role == "free_lancer" && data && data.stages) {
        setStatusName(
          data?.stages
            ? data?.stages[getStageIndex(userID, data.stages)]
                ?.stage_assinee_status
            : data.projectDetail.request_status
        );
      }

      let arr = [];
      if (data && data.stages) {
        arr.push({ id: data.stages[0].id, name: "Client" });
        for (const i in data.stages) {
          if (data.stages[i].id == data.projectDetail.current_stage) {
            console.log("+++++++++++++", i);
            currentStage = data.stages[+i + 1];
          }

          if (data.stages[i].stage_assinee_id) {
            arr.push({
              id: data.stages[i].id,
              name: data.stages[i].stage_assinee_name,
            });
          }
        }

        setUserOptions(arr);
      }

      console.log(currentStage);
      let arr2 = [];

      if (data && data.stages) {
        for (const stage of data.stages) {
          console.log(stage, data.current_stage, "+++++++++++++++++++++++++");

          if (currentStage.freelancer_type == 2) {
            if (
              stage.freelancer_type == 4 &&
              stage.stage_assinee_name &&
              stage.id <= data.projectDetail.current_stage
            ) {
              arr2.unshift({ id: stage.id, name: stage.name });
            }
          } else if (
            stage.stage_assinee_name &&
            stage.id <= data.projectDetail.current_stage
          ) {
            arr2.unshift({ id: stage.id, name: stage.name });
          }
        }

        setStageOptions(arr2);
      }
    } catch (error) {
      console.log(error, "*******************");
    }
  }, [data]);

  if (isModalOpen) {
    if (!(data && timelineData)) {
      return (
        <div ref={ref} className={styles.projectDetailsModalContent}>
          <div className={styles.HeadingContainer}>
            <h1 className={styles.RequestDetailsHeading}>Manage Request</h1>

            <span
              onClick={() => setIsModalOpen(false)}
              className={styles.cross}
            >
              &times;
            </span>
          </div>
          <ProjectDetailModalSkeleton />
        </div>
      );
    }

    return (
      <div ref={ref} className={styles.projectDetailsModalContent}>
        <div className={styles.HeadingContainer}>
          <h1 className={styles.RequestDetailsHeading}>Manage Request</h1>
          {role == "admin" && (
            <div
              className={styles.refresh}
              onClick={() => {
                setRefresh((v) => v + 1);
              }}
            >
              <IoMdRefresh />
            </div>
          )}
          <span onClick={() => setIsModalOpen(false)} className={styles.cross}>
            &times;
          </span>
        </div>
        {data && (
          <Details
            c_doc_link={c_doc_link}
            stageOptions={stageOptions}
            doc_link={doc_link}
            brandProfile={brandProfile}
            setTempComments={setTempComments}
            tempComments={tempComments}
            data={data}
            setProjectName={setProjectName}
            statusName={
              role == "free_lancer" ? statusName : data.projectDetail.status
            }
            setStatusName={setStatusName}
            projectName={sentenceCase(data.projectDetail.name)}
            variant_name={_variant_name}
            icon={_icon}
            setIsModalOpen={setIsModalOpen}
            timelineData={timelineData}
            projectID={id}
            userID={userID}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabSwitchLoading={tabSwitchLoading}
            didAssign={didAssign}
            setDidAssign={setDidAssign}
            setNoFreelancerAssignedError={setNoFreelancerAssignedError}
            setData={setData}
          />
        )}
        <Activity
          setTempComments={setTempComments}
          tempComments={tempComments}
          projectID={id}
          timelineData={timelineData}
          activeTab={activeTab}
          freelancerStatus={data.projectDetail.request_status}
          didAssign={didAssign}
          setNoFreelancerAssignedError={setNoFreelancerAssignedError}
          data={data}
          userOptions={userOptions}
        />
      </div>
    );
  } else {
    return null;
  }
}
