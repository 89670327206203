import Quill from "quill";
import {
    PLANS_ACTION,
    PLANS_CATEGORIES,
    PLANS_CATEGORIES_COST_SORT,
    PLANS_TYPE,
    TERMINATED,
} from "../constants";

import pluralize from "pluralize";

export const textShortner = (data, limit) => {
    if (data.length <= limit) {
        return data;
    } else {
        return data.substring(0, limit) + "...";
    }
};

export const specifyPlans = (plans, quota) => {
    if (!quota) {
        const _plans = plans.map((plan) => {
            if (plan.plan_category == PLANS_CATEGORIES.FREE_FOREVER) {
                plan.action = PLANS_ACTION.PLANTOFREE;
            } else {
                plan.action = PLANS_ACTION.CHOOSE;
            }
            return plan;
        });
        return _plans;
    }

    if (plans?.length > 0) {
        if (quota.plan_category == PLANS_CATEGORIES.TRAIL) {
            const _plans = plans.map((plan) => {
                if (plan.plan_category == PLANS_CATEGORIES.FREE_FOREVER) {
                    plan.action = PLANS_ACTION.TRAILTOFREE;
                } else {
                    plan.action = PLANS_ACTION.CHOOSE;
                }
                return plan;
            });
            return _plans;
        } else if (quota.plan_category == PLANS_CATEGORIES.FREE_FOREVER) {
            const _plans = plans.map((plan) => {
                if (plan.plan_id == quota.plan_id) {
                    plan.action = PLANS_ACTION.CURRENT;
                } else {
                    plan.action = PLANS_ACTION.UPGRADE;
                }
                return plan;
            });
            return _plans;
        } else if (quota.type == PLANS_TYPE.LIFETIME) {
            return [];
        } else if (quota.terminate == TERMINATED) {
            const _plans = plans.map((plan) => {
                if (plan.plan_category == PLANS_CATEGORIES.FREE_FOREVER) {
                    plan.action = PLANS_ACTION.PLANTOFREE;
                } else {
                    plan.action = PLANS_ACTION.CHOOSE;
                }
                return plan;
            });
            return _plans;
        } else {
            const __plans = plans.map((plan) => {
                if (
                    plan.plan_type == quota.type &&
                    plan.plan_category == quota.plan_category
                ) {
                    plan.action = PLANS_ACTION.CURRENT;
                } else {
                    plan.action = getPlanAction(plan, quota);
                }
                return plan;
            });
            return __plans;
        }
    }
};

const getPlanAction = (cardPlan, userPlan) => {
    if (cardPlan.plan_category == PLANS_CATEGORIES.FREE_FOREVER) {
        return PLANS_ACTION.DOWNGRADE;
    } else if (
        PLANS_CATEGORIES_COST_SORT[cardPlan.plan_category] <
        PLANS_CATEGORIES_COST_SORT[userPlan.plan_category]
    ) {
        return PLANS_ACTION.DOWNGRADE;
    } else if (
        PLANS_CATEGORIES_COST_SORT[cardPlan.plan_category] >
        PLANS_CATEGORIES_COST_SORT[userPlan.plan_category]
    ) {
        return PLANS_ACTION.UPGRADE;
    } else if (cardPlan.plan_cost > userPlan.plan_cost) {
        return PLANS_ACTION.UPGRADE;
    }
    return PLANS_ACTION.DOWNGRADE;
};

export const debounceWrapper = (func, time, timeoutRef) => {
    if (timeoutRef) {
        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            func();
        }, time);
    }
};

export function grade(quality, wordCount, seoScore = 0) {
    if (quality >= 7 && quality <= 10) {
        return "A";
    } else if (quality >= 5 && quality <= 6.5) {
        return "B";
    } else if (quality >= 3.5 && quality <= 4.5) {
        return "C";
    } else if (quality >= 2.5 && quality <= 3) {
        return "D";
    } else if (quality >= 1.5 && quality <= 2) {
        return "E";
    } else if (quality >= 0 && quality <= 1 && wordCount && wordCount != 0) {
        return "F";
    } else if (seoScore > 0) {
        return seoScore;
    } else {
        return "-";
    }
}

export function numberWithCommas(num) {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}

export function mobileViewportReset() {
    if (window.metaTagViewport) {
        const initialScale = 0.25 + Math.random() * 0.01;
        if (window.metaTagViewport.isConnected)
            document.head.removeChild(window.metaTagViewport);
        window.metaTagViewport.setAttribute(
            "content",
            `width=1500,initial-scale=${initialScale},maximum-scale=0.25,minimum-scale=0.25`,
        );
        document.head.appendChild(window.metaTagViewport);
        document.head.removeChild(window.metaTagViewport);

        window.metaTagViewport.setAttribute(
            "content",
            `width=1500,initial-scale=${initialScale},maximum-scale=0.5,minimum-scale=0.2`,
        );
        document.head.appendChild(window.metaTagViewport);
        document.scrollingElement.scrollTop = 0;
    }
}

export const isMobile = () => {
    return navigator.userAgent.includes("Mobile");
};

export const isAppVersionOutdated = ({
    currVersion = "2.1.0",
    minVersion = "2.0.0",
}) => {
    const current = currVersion.split(".");
    const minimum = minVersion.split(".");

    for (let index = 0; index < current.length; index++) {
        if (+current[index] < +minimum[index]) return true;
        else if (+current[index] > +minimum[index]) return false;
    }

    return false;
};

export const parseForRegex = (str = "") => {
    str = str || "";
    const specialChars = /\[|\\|\^|\$|\.|\||\?|\*|\+|\(|\)/;
    let parsedStr = "";
    for (const char of str) {
        if (specialChars.test(char)) {
            parsedStr = `${parsedStr}\\${char}`;
        } else {
            parsedStr = `${parsedStr}${char}`;
        }
    }

    return parsedStr;
};

export const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
        "^(http(s)?:\\/\\/)?" + // validate protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.,~+]*)*" + // validate port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
            "(\\#[-a-z\\d_]*)?$",
        "i",
    );
    return !!urlPattern.test(urlString);
};

export const formatURL = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
    } else {
        return "https://" + url;
    }
};

export function getKeytermVariations(keyTerms) {
    return keyTerms
        .map((keyTerm) => pluralize.plural(keyTerm.keyword))
        .concat(keyTerms.map((k) => k.keyterm_variations))
        .concat(keyTerms.map((keyTerm) => pluralize.singular(keyTerm.keyword)));
}

export const CustomDeltaToHtml = (delta) => {
    const div = document.createElement("div");
    div.style.display = "none";
    document.body.appendChild(div);
    const quill = new Quill(div, { modules: { table: true } });

    quill.setContents(delta);

    return quill.getSemanticHTML();
};

export const CustomDeltaToText = (delta) => {
    const div = document.createElement("div");
    div.style.display = "none";
    document.body.appendChild(div);
    const quill = new Quill(div, { modules: { table: true } });

    quill.setContents(delta);

    return quill.getText();
};

export const getTextWidth = (text, className) => {
    // Create a temporary element
    let tempElement = document.createElement("span");
    tempElement.classList.add(className);

    // Set the text content to the input value
    tempElement.textContent = text;

    // Append the temporary element to the body (make it invisible)
    document.body.appendChild(tempElement);

    // Get the width of the text inside the temporary element in pixels
    var textWidth = tempElement.getBoundingClientRect().width;

    // Remove the temporary element
    document.body.removeChild(tempElement);

    // Log the width to the console (you can use it as needed)
    return textWidth;
};

export function escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function parseOutlines(arr = []) {
    return arr
        .sort((a, b) => (a.rank <= b.rank ? -1 : 1))
        .map((item, i) => {
            item.h_tags =
                typeof item.h_tags == "string" && item.h_tags
                    ? JSON.parse(item.h_tags)
                    : item.h_tags;
            item.index = i;
            return item;
        });
}

export const copyHtmlContent = (element) => {
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
        // Execute the copy command
        document.execCommand("copy");
    } catch (err) {
        console.error("Failed to copy: ", err);
    }
    selection.removeAllRanges();
};

export function cssStringToObject(cssString) {
    const tempElement = document.createElement("div");

    tempElement.style.cssText = cssString;

    return tempElement.style;
}
