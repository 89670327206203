import React, { useEffect } from "react";
import DateTimePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import styles from "./styles.module.css";
import { GoClock, GoTriangleRight } from "react-icons/go";
import { FcPlanner } from "react-icons/fc";
import Button from "../../atoms/Button/Button";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import Label from "../../atoms/Label/Label";
import Spacer from "../../atoms/Spacer/Spacer";
import { useState } from "react";
import useHelperFunctions from "../../helperFunctions";
import { updateProjects } from "../../../store/actions/dashboard";
import { useDispatch } from "react-redux";
import { BiTime } from "react-icons/bi";

import Confirmation from "../../atoms/ConfirmationDialog/confirmation";
import { PROJECT_DESCRIPTION } from "../../api.json";

function getFreelancerStatus(status) {
  switch (status) {
    case 1:
      return "waiting for the freelancer to accept";
    case 2:
      return "freelancer accepted";
    case 3:
      return "freelancer submitted";
    case 4:
      return "freelancer revising";

    case 6:
      return "freelancer rejected";
    case 7:
      return "deadline passed";
    case 8:
      return "waiting for the freelancer to accept";

    default:
      return "unknown status";
  }
}

function getStageIndex(stage, _stages) {
  try {
    for (const i in _stages) {
      if (_stages[i].id == stage) {
        return +i + 1;
      }
    }
    return 0;
  } catch {
    return 0;
  }
}
const listThreshold = [
  { id: 30, name: "30 Mins" },
  { id: 60, name: "01 Hr" },
  { id: 120, name: "02 Hrs" },
  { id: 240, name: "04 Hrs" },
  { id: 360, name: "06 Hrs" },
  { id: 480, name: "08 Hrs" },
  { id: 720, name: "12 Hrs" },
];

function timeConvert(n) {
  if (typeof n == "string") {
    return n;
  }

  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + " hr(s) : " + rminutes + " min(s).";
}

export default function StageInfo({
  projectId,
  listOfFreelancerTypes = [],
  stage,
  setConfirmationAction,
  openDialog,
  setStages,
  setCurrentStage,
  current_stage,
  i,
  _stages,
  setData,
  data,
  stages,
}) {
  const [loading, setLoading] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const [deadline, setDeadline] = useState(new Date());
  const [threshold, setThreshold] = useState(30);
  const [jumpStage, setJumpStage] = useState(null);
  const [stageOptions, setStageOptions] = useState([]);
  const [submit_loading, submit_setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    heading: "Click OK to Confirm",
    textType: true,
    handler: () => {},
  });
  const [revision, setRevision] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [type, setType] = useState(stage?.freelancer_type);
  const [freelancerID, setFreelancerID] = useState(null);
  const { postData, getData, putData } = useHelperFunctions();
  const [listFreelancer, setListFreelancer] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const deadline = new Date();
    deadline.setDate(deadline.getDate() + 1);
    setDeadline(deadline);

    (async () => {
      if (type) {
        setLoading(true);
        const res = await getData({
          url: `/api/freeLancer/getList?type=${type}`,
        });
        if (res?.status == 200) {
          setListFreelancer(res.data.data.freeLancers);
          setLoading(false);
        } else {
          console.log("error");
        }

        // console.log(res.data);
      }
    })();
  }, []);

  useEffect(() => {
    let arr2 = [];

    if (stages) {
      for (const _stage of stages) {
        if (_stage.stage_assinee_name && _stage.id < stage.id) {
          arr2.unshift({ id: _stage.id, name: _stage.name });
        }
      }
      setStageOptions(arr2);
      setJumpStage(arr2[0]?.id);
    }
  }, [stages]);

  async function assignToFreelancer() {
    if (listFreelancer.length > 0 || freelancerID) {
      setLoading(true);

      const freelancerName = (() => {
        try {
          for (const freeLancer of listFreelancer) {
            if (
              freeLancer.id ==
              (freelancerID ? freelancerID : listFreelancer[0].id)
            ) {
              return freeLancer.name;
            }
          }
          return "undefined";
        } catch {
          return "undefined";
        }
      })();
      const thresholdDate = new Date();
      thresholdDate.setMinutes(thresholdDate.getMinutes() + Number(threshold));
      const res = await postData({
        url: "/api/freeLancer/assign",
        payload: {
          assignee: freelancerID ? freelancerID : listFreelancer[0].id,
          project_id: projectId,
          threshold: thresholdDate,
          deadline: deadline,
          threshold_text: threshold,
          stage: stage.id,
          freelancer_doc_file: data.projectDetail.freelancer_doc_file,
          stage_name: stage.name,
          assignee_name: freelancerName,
        },
      });

      if (res?.status == 200) {
        dispatch(
          updateProjects({
            id: projectId,
            data: { status: "IN PROGRESS" },
          })
        );
        setStages((state) => {
          state[i].stage_assinee_name = freelancerName;
          state[i].stage_assinee_id = freelancerID
            ? freelancerID
            : listFreelancer[0].id;
          state[i].stage_assinee_threshold = new Date(
            +new Date() + threshold * 59000
          );
          state[i].stage_assinee_status = 1;

          return state;
        });
        setThreshold(30);
        setFreelancerID(null);

        setLoading(false);
      } else {
        updateProjectInfo();
        setLoading(false);
      }
    }
  }

  function updateProjectInfo() {
    setData(null);
    (async () => {
      const response = await getData({ url: PROJECT_DESCRIPTION + projectId });

      if (response?.status == 200) {
        setData(response?.data?.data);
      }
    })();
  }

  const freelancerSubmitForReview = async () => {
    submit_setLoading(true);

    const changeStatusObject = {
      project_id: projectId,
      status: 3,
      isFreelancer: true,
      stage: stage.id,
    };

    const response = await postData({
      url: "/api/freeLancer/action",
      payload: changeStatusObject,
    });

    if (response?.status == 200) {
      setSubmit(true);
      setStages((state) => {
        state[i].stage_assinee_status = 3;
        state[i].stage_assinee_deadline = deadline;

        return state;
      });
      setCurrentStage(getStageIndex(response.data.data.current_stage, _stages));

      submit_setLoading(false);
    } else {
      updateProjectInfo();
      _setLoading(false);
    }
  };

  async function submitForReview() {
    setLoading(true);

    const changeStatusObject = {
      projectId: projectId,
      status: "SUBMITTED FOR REVIEW",
      freelancer_doc_file: data.projectDetail.freelancer_doc_file,
    };

    const response = await putData({
      url: "/api/project/updateStatus",
      payload: changeStatusObject,
    });

    if (response?.status == 200) {
      updateProjectInfo();
      dispatch(
        updateProjects({
          id: projectId,
          data: { status: changeStatusObject.status },
        })
      );
      setCurrentStage(i + 1);
      setLoading(false);
    } else {
      updateProjectInfo();
      setLoading(false);
    }
  }

  async function _requestForRevision(closeDialog, message) {
    _setLoading(true);

    const changeStatusObject = {
      project_id: projectId,
      status: 4,
      isFreelancer: false,
      stage: stage.id,
      new_stage: jumpStage,
    };
    if (message) {
      changeStatusObject.message = message;
    }

    const response = await postData({
      url: "/api/freeLancer/action",
      payload: changeStatusObject,
    });

    if (response?.status == 200) {
      setRevision(true);
      setStages((state) => {
        for (const i in state) {
          if (state[i].id == jumpStage) {
            state[i].stage_assinee_status = 4;
            break;
          }
        }

        return state;
      });
      setCurrentStage(getStageIndex(response.data.data.current_stage, _stages));
      closeDialog(true);

      _setLoading(false);
    } else {
      updateProjectInfo();
      _setLoading(false);
      closeDialog(true);
    }
  }

  function handleFreelancerChange(e) {
    setFreelancerID(e);
  }

  async function _pullFromFreelancer(closeDialog) {
    setLoading(true);

    const changeStatusObject = {
      project_id: projectId,
      status: 7,
      isFreelancer: true,
      stage: stage.id,
      stage_name: stage.name,
      assignee_name: stage.stage_assinee_name,
    };

    const response = await postData({
      url: "/api/freeLancer/action",
      payload: changeStatusObject,
    });

    if (response?.status == 200) {
      setStages((state) => {
        state[i].stage_assinee_name = null;
        state[i].stage_assinee_id = null;
        state[i].stage_assinee_threshold = null;
        return state;
      });
      setLoading(false);
      closeDialog(true);
    } else {
      updateProjectInfo();
      setLoading(false);
      closeDialog(true);
    }
  }

  function getThreshold(time) {
    try {
      const threshold = new Date(time) - new Date();
      if ((threshold % 60000) / 1000 < 0) {
        return "Exceeded";
      }
      return +(threshold / 59000).toFixed(0);
    } catch {
      return "--:--";
    }
  }

  function pullFromFreelancer() {
    setDialogConfig({
      textType: false,
      handler: _pullFromFreelancer,
      heading: "Are you sure you want to pullback?",
    });
    setDialog(true);
  }

  function requestForRevision() {
    setDialogConfig({
      textType: true,
      handler: _requestForRevision,
      heading: "Please enter your query and submit",
    });
    setDialog(true);
  }

  function getType(list) {
    try {
      for (const t of list) {
        if (type == t.type) {
          return t;
        }
      }
    } catch {
      return {};
    }
  }

  function getUi(key) {
    switch (key) {
      case 1:
        return (
          <>
            {" "}
            {!stage.stage_assinee_id ? (
              <>
                {" "}
                <div className={styles.flex}>
                  <Label label="TYPE" />
                  <div className={styles.type}>
                    {getType(listOfFreelancerTypes).name}
                  </div>
                </div>
                <Dropdown
                  options={listFreelancer}
                  handler={(id) => handleFreelancerChange(id)}
                  width="190px"
                  label="FREELANCER"
                />
                <div className={styles.flex}>
                  <Label label="DEADLINE" />

                  <DateTimePicker
                    strictParsing
                    closeOnClickOutside
                    onChange={(date) => {
                      if (date._d) {
                        if (new Date(date._d) - new Date() > 60000 * 60 * 2)
                          setDeadline(new Date(date._d));
                        else
                          setDeadline(new Date(+new Date() + 60000 * 60 * 2));
                      }
                    }}
                    value={deadline}
                    isValidDate={(c, s) => {
                      return true;
                    }}
                  />
                  <FcPlanner className={styles.iconTime} />
                </div>
                <Dropdown
                  options={listThreshold}
                  handler={(id) => setThreshold(id)}
                  width="190px"
                  label="THRESHOLD"
                />
                <div>
                  <Spacer spacing="25px"></Spacer>
                  <Button
                    loading={loading}
                    disabled={listFreelancer?.length == 0}
                    width="108px"
                    fontSize="12px"
                    text="ASSIGN"
                    handler={() => assignToFreelancer()}
                  ></Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className={styles.f_name}>
                    <span className={styles.f_assignTo}>Assigned To: </span>
                    {stage.stage_assinee_name}
                  </div>
                  <div
                    style={status == 6 || status == 7 ? { color: "red" } : {}}
                    className={styles.f_action}
                  >
                    {getFreelancerStatus(stage.stage_assinee_status)}
                  </div>
                </div>
                {stage.stage_assinee_status == 1 ? (
                  <div className={styles.f_threshold}>
                    <BiTime
                      style={{
                        fontSize: "20px",
                        marginBottom: "-4px",
                      }}
                    ></BiTime>{" "}
                    Threshold:{" "}
                    <span>
                      {" "}
                      {timeConvert(getThreshold(stage.stage_assinee_threshold))}
                    </span>{" "}
                  </div>
                ) : (
                  stage.stage_assinee_status && (
                    <div className={styles.f_threshold}>
                      <BiTime
                        style={{
                          fontSize: "20px",
                          marginBottom: "-4px",
                        }}
                      ></BiTime>{" "}
                      Deliver By: &nbsp;
                      <span>
                        {new Date(stage.stage_assinee_deadline).toDateString()}
                        &nbsp;{" "}
                        {new Date(
                          stage.stage_assinee_deadline
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>{" "}
                    </div>
                  )
                )}
                <div>
                  <Button
                    loading={loading}
                    width="108px"
                    fontSize="12px"
                    text="PULLBACK"
                    handler={() => pullFromFreelancer()}
                  ></Button>
                </div>
              </>
            )}
          </>
        );

      case 2:
        return (
          <>
            <div>
              <div className={styles.f_name}>
                <span className={styles.f_assignTo}>
                  Send Request for Review.
                </span>
              </div>
              {/* <div className={styles.f_action}>
                waiting for the freelancer to accept
              </div> */}
            </div>
            {/* <div className={styles.f_threshold}>
              <BiTime
                style={{
                  fontSize: "20px",
                  marginBottom: "-4px",
                }}
              ></BiTime>{" "}
              Threshold:{" "}
              <span> {getThreshold(stage.stage_assinee_threshold)}</span>{" "}
            </div> */}
            <div>
              <Button
                loading={loading}
                width="150px"
                fontSize="12px"
                text={current_stage > i ? "SUBMITTED" : "SUBMIT TO CLIENT"}
                handler={() => submitForReview()}
                disabled={current_stage > i}
              ></Button>
            </div>
          </>
        );

      default:
        return null;
    }
  }

  if (stage.status == 0) {
    return null;
  }

  if (i < current_stage) {
    if (!stage.stage_assinee_status) return null;
    return (
      <div className={styles.stageInfoContainer}>
        <div className={styles.stageinfo}>
          <div>
            <div className={styles.f_name}>
              <span className={styles.f_assignTo}>Assigned To: </span>
              {stage.stage_assinee_name}
            </div>
            <div
              style={status == 6 || status == 7 ? { color: "red" } : {}}
              className={styles.f_action}
            >
              {getFreelancerStatus(stage.stage_assinee_status)}
            </div>
          </div>
          {stage.stage_assinee_status == 1 ? (
            <div className={styles.f_threshold}>
              <BiTime
                style={{
                  fontSize: "20px",
                  marginBottom: "-4px",
                }}
              ></BiTime>{" "}
              Threshold:{" "}
              <span>
                {" "}
                {timeConvert(getThreshold(stage.stage_assinee_threshold))}
              </span>{" "}
            </div>
          ) : (
            stage.stage_assinee_status && (
              <div className={styles.f_threshold}>
                <BiTime
                  style={{
                    fontSize: "20px",
                    marginBottom: "-4px",
                  }}
                ></BiTime>{" "}
                Deliver By:{" "}
                <span>
                  {new Date(stage.stage_assinee_deadline).toDateString()}
                  &nbsp;{" "}
                  {new Date(stage.stage_assinee_deadline).toLocaleTimeString(
                    [],
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                </span>{" "}
              </div>
            )
          )}
          <GoTriangleRight className={styles.icon} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.stageInfoContainer}>
      {dialog && (
        <Confirmation
          setDialog={setDialog}
          submit={dialogConfig.textType}
          handler={dialogConfig.handler}
          modalHeading={dialogConfig.heading}
        ></Confirmation>
      )}
      <div className={styles.stageinfo}>
        {/* <TextField width="190px" label="ACTIONS"></TextField> */}
        {stage.name != "REVISION IN PROGRESS" && getUi(stage.status)}
        {stage.name == "REVISION IN PROGRESS" && (
          <div className={styles.f_name}>
            <span className={styles.f_assignTo}>
              Send Request for Revision.
            </span>
          </div>
        )}

        <div>
          {!stage.stage_assinee_id ? null : (
            <Button
              loading={submit_loading}
              width="150px"
              fontSize="12px"
              text={!submit ? "MARK COMPLETE" : "MARKED COMPLETE"}
              handler={() => freelancerSubmitForReview()}
              secondary
              disabled={submit}
            ></Button>
          )}
        </div>
        {stage.can_request_revision ? (
          <div className={styles.row}>
            <>
              <Dropdown
                label="ASSIGN TO PREVIOUS"
                options={stageOptions}
                handler={setJumpStage}
                width="190px"
                disabled={stageOptions.length <= 1}
              />

              <Button
                loading={_loading}
                width="108px"
                fontSize="12px"
                text={!revision ? "ASSIGN" : "ASSIGNED"}
                handler={() => requestForRevision()}
                secondary
                disabled={revision}
              ></Button>
            </>
          </div>
        ) : null}
        <GoTriangleRight className={styles.icon} />
      </div>
    </div>
  );
}
