import React from "react";
import styles from "./styles.module.css";
import { CgRadioCheck } from "react-icons/cg";
import StageInfo from "./StageInfo";

export default function Stage({ data, lastStage, stage }) {
  function getRevisionStage() {
    console.log(stage);
    if (data.projectDetail.current_stage == data.projectDetail.client_stage) {
      return "";
    } else if (stage.id == data.projectDetail.client_stage + 1) {
      return styles.revision;
    }
    return "";
  }
  return (
    <>
      <div className={styles.stage + " " + styles.stage2}>
        <div className={styles.check + " " + getRevisionStage()}>
          <CgRadioCheck />
        </div>

        <div className={styles.name}>{stage.name}</div>
      </div>
      {!lastStage && <div className={styles.path}></div>}
    </>
  );
}
