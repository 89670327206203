import React from "react";
import styles from "./Favourite.module.css";
import { Skeleton } from "@material-ui/lab";
const loading = (
  <div className={styles.cardList}>
    <div className={styles.blurEnd}></div>
    <Skeleton
      style={{
        minWidth: 262,
        minHeight: 130,
        margin: "0 20px 20px 0 ",
      }}
      width={262}
      height={130}
    />
    <Skeleton
      style={{
        minWidth: 262,
        minHeight: 130,
        margin: "0 20px 20px 0 ",
      }}
      width={262}
      height={130}
    />
    <Skeleton
      style={{
        minWidth: 262,
        minHeight: 130,
        margin: "0 20px 20px 0 ",
      }}
      width={262}
      height={130}
    />
  </div>
);

function FavouriteLoading() {
  return (
    <div className={styles.container}>
      <>
        <h2 className={styles.heading}>
          <Skeleton width={300} height={60} />
        </h2>
        <h6 className={styles.subHeading}>
          <Skeleton width={200} height={20} />
        </h6>

        <>
          <h3 className={styles.cardListHeader}>
            <Skeleton width={150} height={40} />
          </h3>
          <div className={styles.cardListContainer}>
            {[loading, loading, loading]}
          </div>
        </>
      </>
    </div>
  );
}

export default FavouriteLoading;
