import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";
import { ImDownload } from "react-icons/im";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiCheck } from "react-icons/bi";
import { BsCloudDownload } from "react-icons/bs";
import { FaFilePdf } from "react-icons/fa";
import { GrDocumentWord, GrDocumentTxt } from "react-icons/gr";
import { RiExternalLinkLine, RiVideoFill } from "react-icons/ri";
import { MdAudiotrack } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";

function putIcon(icon) {
    if (icon.match(/.*\.pdf$/)) return <FaFilePdf />;
    else if (icon.match(/\.(?:doc|docx)$/i)) return <GrDocumentWord />;
    else if (icon.match(/.*\.txt$/)) return <GrDocumentTxt />;
    else if (icon.match(/\.(?:wav|mp3)$/i)) return <MdAudiotrack />;
    else if (icon.match(/\.(?:webm|mp4|mkv|wmv)$/i)) return <RiVideoFill />;
    else if (icon.match(/\.(?:jpeg|png|jpg|gif|svg|webp)$/i))
        return <img src={icon}></img>;
    else return <FaFileAlt />;
}

function File1({
    icon,
    name,
    date,
    userName,
    checkedFiles,
    setCheckedFiles,
    imageName,
    origImg,
}) {
    const user = useSelector((state) => state.auth.user_name);
    const role = useSelector((state) => state.auth.role);
    const [checked, setChecked] = useState(false);
    let options = { year: "numeric", month: "long", day: "numeric" };
    let dateToShow = new Date(date);

    function handleCheck() {
        setChecked((prevState) => !prevState);
    }

    useEffect(() => {
        const newCheckedFiles = [...checkedFiles];
        if (!checked) {
            newCheckedFiles.map((file, i) => {
                if (file.created_at === date) {
                    newCheckedFiles.splice(i, 1);
                }
            });
        } else {
            newCheckedFiles.push({
                attachmentName: imageName,
                fileName: name,
                created_at: date,
            });
        }
        setCheckedFiles(newCheckedFiles);
    }, [checked]);

    return (
        <div className={styles.file}>
            <div className={styles.left}>
                <div className={styles.checkAndThumb}>
                    {role == "admin" || role == "account_manager" ? (
                        <div
                            className={styles.filecheckmark}
                            style={{
                                backgroundColor: checked ? "#3dd598" : "white",
                            }}
                            onClick={handleCheck}
                        >
                            {checked ? (
                                <BiCheck
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        color: "white",
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    ) : null}
                    <div className={styles.fileThumb}>
                        <a href={origImg} target={"_blank"} download={true}>
                            {putIcon(icon)}
                        </a>
                    </div>
                </div>
                <div className={styles.fileName}>
                    <a href={origImg} target={"_blank"} download={true}>
                        {name} <RiExternalLinkLine />
                    </a>
                </div>
            </div>

            {/* <div className={styles.fileDetails}> */}
            {/* <div className={styles.fileUser}>{userName}</div> */}

            <div className={styles.dateAndIcons}>
                <div className={styles.fileDate}>
                    {dateToShow.toLocaleDateString("en-US", options)}
                </div>

                <div className={styles.fileSrc}>
                    <a href={origImg} target={"_blank"}>
                        <RiExternalLinkLine className={styles.fileFuncIcons} />
                    </a>
                    <a href={origImg} target={"_blank"} download={true}>
                        <BsCloudDownload className={styles.fileFuncIcons} />
                    </a>
                </div>
            </div>

            {/* </div> */}
        </div>
    );
}

export default File1;
