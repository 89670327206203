import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoCheck } from "react-icons/go";
import TextField from "../../../atoms/CustomTextfield/Textfield";
import Button from "../../../atoms/Button/Button";

import styles from "../styles.module.css";
import {
  resetPassword,
  clearMessage,
} from "../../../../store/actions/resetPassword";
import { useParams } from "react-router-dom";
import ValidationError from "../../../../components/common/validationError";
import ValidationSuccess from "../../../../components/common/validationSuccess";
import Spacer from "../../../atoms/Spacer/Spacer";

function ResetPassword() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.reset.message_fail);
  const message = useSelector((state) => state.reset.message_success);
  const loading = useSelector((state) => state.reset.loading);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matched, setMatched] = useState(true);
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  const token = useParams("resetPasswordToken");
  const isMatched = () => {
    if (password === confirmPassword) {
      setMatched(true);
      return true;
    } else {
      setValidationError("Both the passwords must match");
      return false;
    }
  };

  const validatePass = () => {
    if (password.length > 5) {
      return true;
    } else {
      setValidationError("New password must be of at least 6 characters");

      return false;
    }
  };

  const resetPass = (e) => {
    e.preventDefault();
    dispatch(clearMessage());

    if (isMatched(password, confirmPassword) && validatePass(password)) {
      setValidationError(null);
      dispatch(resetPassword({ password, confirmPassword, token }));
    }
  };

  return (
    <>
      {validationError ? <ValidationError msg={validationError} /> : null}
      {message ? <ValidationSuccess msg={message} /> : null}
      {error ? <ValidationError msg={error} /> : null}

      {error || validationError || message ? <Spacer spacing={15} /> : null}

      <TextField
        props={{
          value: password,
          onChange: (x) => setPassword(x.target.value.trim()),
          disabled: message,
          name: "new password",
        }}
        type="password"
        height={44}
        label="Enter new password"
        placeholder={"Enter new password"}
      />

      <Spacer spacing={10} />
      <TextField
        props={{
          value: confirmPassword,
          onChange: (x) => setConfirmPassword(x.target.value.trim()),
          disabled: message,
          name: "confirm password",
        }}
        type="password"
        height={44}
        label="Confirm new password"
        placeholder={"Confirm new password"}
      />
      <Spacer spacing={15} />

      <Button
        loading={loading}
        handler={resetPass}
        height={44}
        text={message ? null : "Reset"}
        Icon={message ? GoCheck : null}
        disabled={message}
      />
      <Spacer spacing={20} />
    </>
  );
}

export default ResetPassword;
