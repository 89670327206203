import React from "react";
import { grade } from "../../../utility/helperFun";
import HighLighter from "../../atoms/HightLighter/HighLighter";
import styles from "./styles.module.css";

function DocScore({ score, words, seoScore }) {
    return (
        <HighLighter
            quality={grade(score, words, seoScore)}
            style={{
                height: 44,
                width: 44,
                borderRadius: 3,
                maxWidth: "unset",
            }}
        />
    );
}

export default DocScore;
