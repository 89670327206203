import { Outline } from "./classes";

export const OUTLINE_EVENTS_TYPES = {
    HEADINGS: "headings",
    QUESTIONS: "questions",
    INTRODUCTION: "introduction",
    CONCLUSION: "conclusion",
};

export const SAVE_OPERATIONS = {
    ADD: 3,
    EDIT: 1,
    DELETE: 2,
    ORDER: 4,
};

export const getSaveOutlinePayload = (_payload, type, operation, report_id) => {
    let payload = {
        report_id: report_id,
        operation,
        field: "outlines",
    };
    switch (operation) {
        case SAVE_OPERATIONS.ADD:
            payload.data = _payload.data;
            payload.tag = _payload.tag;
            break;
        case SAVE_OPERATIONS.DELETE:
            payload.outline_id = _payload.id;
            break;
        case SAVE_OPERATIONS.EDIT:
            payload.tag = _payload.tag;
            payload.data = _payload.data;
            payload.outline_id = _payload.id;
            break;
        case SAVE_OPERATIONS.ORDER:
            payload.order = [];
            payload.type = type == "heading" ? "heading" : "question";
            break;
        default:
    }
    return payload;
};

export const HEADINGS_EVENT_TYPES = {
    MANUAL: "manual",
    ON_CLICK: "on_click",
    ON_DROP: "on_drop",
    DELETE: "delete",
    EDIT: "edit",
};

export const QUESTIONS_EVENT_TYPES = {
    MANUAL: "manual",
    ON_CLICK: "on_click",
    ON_DROP: "on_drop",
    DELETE: "delete",
    EDIT: "edit",
};

export const STEPS_META_INFO = [
    {
        field: "blog_context",
        title: "Article Configuration",
        info: "Giving a brief description of your blog context will help us in generating great outputs",
        type: "1",
        placeholderText: `Write a small description of your blog topic.`,
    },
    {
        field: "title",
        title: "Enter the Blog Title (H1)",
        info: "Pick a suitable title for your blog.",
        keywords: [],
        type: "1",

        placeholderText:
            "Pick a title from some of the best AI-generated suggestions, take inspiration from the top-ranking ones, or enter your own title.",
    },
    {
        field: "outlines",
        title: "Build Outline",
        desc: "Generate heading ideas through AI or simply select headings from the outlines of top ranked on the right. You can also add a heading manually by clicking on the Add H2/H3 buttons.",
        type: "2",
        placeholderText: "Enter your blog title",
        info: "Generate heading ideas through AI or simply select headings from the outlines of top ranked on the right. You can also add a heading manually by clicking on the Add H2/H3 buttons.",
    },

    {
        field: "talking_points",
        title: "Writing Points",
        desc: "We have generated quality writing points with our AI and if you don't like the first few results , simply hit regenerate and pick your best fits. You can add/edit/delete writing points as you like",
        type: "2",
        placeholderText: "Enter your Introduction Paragraph",
        info: "We have generated quality writing points with our AI and if you don't like the first few results , simply hit regenerate and pick your best fits. You can add/edit/delete writing points as you like",
    },

    {
        field: "paragraph",
        title: "First Draft",
        desc: "Here is your blog's first draft! Make changes as you like.",
        type: "2",
        placeholderText: "Enter your Introduction Paragraph",
        info: "Your first draft is ready! Review content for every heading.",
    },
];
export const PREMIUM_STEPS_META_INFO = [
    {
        field: "blog_context",
        title: "Article Configuration",
        info: "Giving a brief description of your blog context will help us in generating great outputs",
        type: "1",
        placeholderText: `Write a small description of your blog topic. 

For example: This blog is for interior designers and home decorating
enthusiasts. It contains information about the 10 best interior designing software tools.`,
    },
    {
        field: "title",
        title: "Enter the Blog Title (H1)",
        info: "Pick a suitable title for your blog.",
        keywords: [],
        type: "1",

        placeholderText:
            "Pick a title from some of the best AI-generated suggestions, take inspiration from the top-ranking ones, or enter your own title.",
    },
    {
        field: "outlines",
        title: "Build Outline",
        desc: "Generate heading ideas through AI or simply select headings from the outlines of top ranked on the right. You can also add a heading manually by clicking on the Add H2/H3 buttons.",
        type: "2",
        placeholderText: "Enter your blog title",
        info: "Generate heading ideas through AI or simply select headings from the outlines of top ranked on the right. You can also add a heading manually by clicking on the Add H2/H3 buttons.",
    },

    {
        field: "paragraph",
        title: "First Draft",
        desc: "Here is your blog's first draft! Make changes as you like.",
        type: "2",
        placeholderText: "Enter your Introduction Paragraph",
        info: "Your first draft is ready! Review content for every heading.",
    },
];

export const getOutlineObj = ({ text = "", tag = "h2" }) => {
    return {
        data: text,
        tag: tag,
        wp_order: [],
        para: "",
    };
};

export const getH3Index = (h2Index, headings) => {
    for (let index = h2Index + 1; index < headings.length; index++) {
        if (headings[index].tag == Outline.tags.h2) {
            return index;
        }
    }

    return headings.length;
};

export const droppableIds = {
    questions: "questionsDroppable",
    headings: "headingsDroppable",
    topRanked: "topRankedDroppable",
    stats: "stats",
};

export const droppableTypes = {
    outlineH3: "outlineH3",
    outlineH2: "outlineH2",
};

export const getOrder = (arr) => {
    const order = [];
    arr.forEach((element) => {
        order.push(element.id);
    });
    return order;
};

export function checkForUUID(id) {
    id = String(id);

    if (id.length > 20) {
        return true;
    } else false;
}

export const checkParas = (stepsData) => {
    // if (stepsData.introduction) {
    //     return true;
    // }
    for (let i = 0; i < stepsData?.outlines?.headings?.length; i++) {
        if (stepsData?.outlines?.headings[i].para) {
            return true;
        }
    }
    return false;
};

/**
 * @param {HTMLElement} node
 */
export const highlightNode = (node) => {
    if (!node) return;
    const _border = node.style.border;
    node.scrollIntoView({ block: "center" });
    node.style.border = "2px solid var(--primary-blue)";
    setTimeout(() => {
        node.style.border = _border;
    }, 1000);
};

export const cruiseMixPanelEvents = {
    generatedOutlines: "generatedOutlines",
    generatedWordCount: "generatedWordCount",
    generatedKeyTerms: "generatedKeyTerms",
    generatedSeoScore: "generatedSeoScore",
    regenerateOnFirstDraft: "regenerateOnFirstDraft",
    generateMoreTitles: "generateMoreTitles",
    deleteAllOutlines: "deleteAllOutlines",
    delete: "Delete",
};
