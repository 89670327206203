import React, { useEffect, useState, Suspense } from "react";
import {
    Route,
    Switch,
    Redirect,
    useParams,
    useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import screens from "./screens.json";

import Progress from "./componentsV2/atoms/Progress/Progress";
import Auth from "./componentsV2/templates/Auth/Auth";
import DashboardTemplate from "./componentsV2/templates/Dashboard/Dashboard";
import IndividualReport from "./componentsV2/organisms/Creator/IndividualReport/IndividualReport";
import Maintenance from "./componentsV2/Pages/Maintenance";
import Logout from "./components/authentication/logout";
import { TALENT_DOMAIN } from "./utility/config";
import IndividualReportRedirect from "./componentsV2/organisms/Creator/IndividualReport/IndividualReportRedirect";
import CCUser from "./componentsV2/templates/Dashboard/CCUser";
import Verify from "./componentsV2/templates/Auth/Verify";
import useSockets from "./Sockets/useSockets";
import OnBoardingFlow from "./componentsV2/organisms/OnBoadingFlow/OnBoardingFlow";
import { v4 as uuidv4 } from "uuid";
import useNavigation from "./componentsV2/atoms/GoToRoute/useNavigation";
import {
    appScopes,
    clientId,
} from "./componentsV2/Pages/IntegrationHub/shopifyConstants";
import SeoSurge from "./componentsV2/Pages/SeoSurge/SeoSurge";
const Routes = () => {
    const [loading, setLoading] = useState(true);
    const [talentNetwork, setTalentNetwork] = useState(false);
    const { isLoggedIn, user_name, profilePicture } = useSelector(
        (state) => state.auth,
    );
    const role = useSelector((state) => state.auth.role);
    const signUpMode = useSelector((state) => state.auth.signup_mode);
    const first_subscription_status = useSelector(
        (state) => state.auth.first_subscription_status,
    );

    const on_boarding = useSelector((state) => state.auth.on_boarding);

    const logout = Logout({
        render: (logoutHandler) => {
            return logoutHandler;
        },
    });
    const location = useLocation();
    const goTo = useNavigation();

    if (location.pathname.includes("logout")) {
        localStorage.setItem("token", null);
        logout(true);
    }

    useEffect(() => {
        if (location.pathname.includes("talent_network")) {
            setTalentNetwork(true);
        }
    }, []);

    Verify({ setLoading });
    useSockets(isLoggedIn);

    useEffect(() => {
        if (window.metaTagViewport) {
            if (
                location.pathname == "/admin/dashboard" ||
                location.pathname.includes("onboarding") ||
                location.pathname.includes("auth")
            ) {
                window.metaTagViewport.setAttribute(
                    "content",
                    "width=device-width, user-scalable=no",
                );
                document.head.appendChild(window.metaTagViewport);
            } else {
                try {
                    document.head.removeChild(window.metaTagViewport);
                } catch (e) {
                    console.log(e);
                }

                window.metaTagViewport.setAttribute("content", "width=1500");
                document.head.appendChild(window.metaTagViewport);
            }
        }
    }, [location]);

    useEffect(() => {
        if (isLoggedIn) {
            document.cookie =
                "ScalenutSyncToken=" +
                JSON.stringify({
                    profileUrl: profilePicture
                        ? profilePicture
                        : "https://storage.googleapis.com/scalenut/webflow assests/6360de0aaf347dd3995cfeb3_syncUser.png",
                    name: user_name,
                }) +
                ";domain=.scalenut.com;path=/";
        }
        window?.google?.accounts?.id?.cancel();
    }, [isLoggedIn]);

    if (!loading && CheckShopifyRef(isLoggedIn, goTo)) {
        return <Progress height="100vh" />;
    }

    if (isLoggedIn && on_boarding && location.pathname != screens.ON_BOARDING) {
        return <Redirect to={screens.ON_BOARDING} />;
    }

    if (loading) return <Progress height="100vh" />;
    else if (!isLoggedIn)
        return (
            <Switch>
                <Route path="/maintenance">
                    <Maintenance />
                </Route>

                <Route exact path="/creator/:id/:access">
                    <IndividualReportRedirect />
                </Route>
                <Route exact path="/audit/:id/:access">
                    <IndividualReportRedirect auditMode={true} />
                </Route>

                <Route exact path="/creator/:form/:id/:access">
                    <IndividualReport />
                </Route>
                <Route exact path="/audit/:form/:id/:access">
                    <IndividualReport auditMode={true} />
                </Route>
                <Route path="/auth">
                    <Auth />
                </Route>
                <Route path="/">
                    <Redirect to="/auth" />
                </Route>
            </Switch>
        );
    else {
        if (talentNetwork) {
            window.location = TALENT_DOMAIN;
        }

        // window.clarity &&
        //   window.clarity("identify", email, userId, window.location.pathname);
        // window.clarity && window.clarity("set", "email", email);

        return (
            <Switch>
                <Route path="/maintenance">
                    <Maintenance />
                </Route>
                {on_boarding && (
                    <Route path="/admin/onboarding">
                        <OnBoardingFlow />
                    </Route>
                )}
                <Route exact path="/creator/:id/:access">
                    <IndividualReportRedirect />
                </Route>

                <Route exact path="/creator/:form/:id/:access">
                    <IndividualReport />
                </Route>
                <Route exact path="/audit/:id/:access">
                    <IndividualReport auditMode={true} />
                </Route>

                {signUpMode == 2 && first_subscription_status == 0 && (
                    <Route exact path="/admin/dashboard/plans">
                        <CCUser />
                    </Route>
                )}

                <Route path="/admin">
                    <DashboardTemplate />
                </Route>

                <Route path="/">
                    {role == "free_lancer" ? (
                        (() => {
                            window.location = TALENT_DOMAIN;
                            return null;
                        })()
                    ) : signUpMode == 2 && first_subscription_status == 0 ? (
                        <Redirect to="/admin/dashboard/plans" />
                    ) : (
                        <Redirect to="/admin/dashboard" />
                    )}
                </Route>
            </Switch>
        );
    }
};

export default Routes;

const CheckShopifyRef = (isLoggedIn, goTo) => {
    const urlParams = new URLSearchParams(window.location.search);
    const data = {};
    urlParams.forEach((value, key) => {
        data[key] = value;
    });
    const shopifyRedirect = sessionStorage.getItem("shopifyRedirect");
    const [scopes, shop, client_id, redirect_uri, nonce] = [
        appScopes,
        data.shop,
        clientId,
        `${window.location.origin}/admin/shopify/auth`,
        uuidv4(),
    ];
    if (location.pathname === "/" && data.hmac && data.shop) {
        window.location = `https://${shop}/admin/oauth/authorize?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
        return true;
    } else if (
        !isLoggedIn &&
        location.pathname === "/admin/shopify/auth" &&
        data.hmac &&
        data.shop
    ) {
        sessionStorage.setItem("shopifyRedirect", window.location.search);
        return false;
    } else if (isLoggedIn && shopifyRedirect) {
        sessionStorage.removeItem("shopifyRedirect");
        goTo(null, null, null, null, `/admin/shopify/auth${shopifyRedirect}`);
        return true;
    }
    return false;
};
