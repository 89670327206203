import mixpanel from "mixpanel-browser";
import React from "react";

export const useLinkManagerMixpanel = () => {
    const lMLanding = ({ ctaType = "", source = "", ctaUsed = "" }) => {
        let obj = {
            "CTA Type": ctaType || "-",
            "CTA Used": ctaUsed || "-",
            "CTA Source": source || "-",
        };
        mixpanel.track("LM Landing", obj);
    };
    const lMAddDomain = ({ screen = "", ctaUsed = "", popUp = "" }) => {
        let obj = {
            "CTA Used": ctaUsed || "-",
            Screen: screen || "-",
            "Pop Up": popUp || "-",
        };
        mixpanel.track("LM Add Domain", obj);
    };

    const lMUpgradePlan = ({ screen = "", ctaUsed = "" }) => {
        let obj = {
            "CTA Used": ctaUsed || "-",
            Screen: screen || "-",
        };

        mixpanel.track("LM Upgrade", obj);
    };

    const lMBuyPages = ({ screen = "", ctaUsed = "" }) => {
        let obj = {
            "CTA Used": ctaUsed || "-",
            Screen: screen || "-",
        };
        mixpanel.track("LM Buy Pages", obj);
    };

    const lMManageDomainCta = ({
        screen = "",
        ctaUsed = "",
        ctaType = "",
        pCrawled = "",
        pTotal = "",
        domainStatus = "",
    }) => {
        let obj = {
            "CTA Used": ctaUsed || "-",
            Screen: screen || "-",
            "CTA Type": ctaType || "-",
            "Pages Crawled": pCrawled || "-",
            "Total Pages": pTotal || "-",
            "Domain Status": domainStatus || "",
        };
        mixpanel.track("LM Manage Domains", obj);
    };

    const lMVerificationCta = ({
        screen = "",
        ctaUsed = "",
        ctaType = "",
        domainStatus = "",
    }) => {
        let obj = {
            "CTA Used": ctaUsed || "-",
            Screen: screen || "-",
            "CTA Type": ctaType || "-",
            "Domain Status": domainStatus || "",
        };
        mixpanel.track("LM Domain Verification", obj);
    };
    const lmSuggestionModal = ({
        ctaType = "",
        ctaUsed = "",
        tabName = "",
        issueCount = "",
        approveCount = "",
        suggestionCount = "",
    }) => {
        let obj = {
            "CTA Type": ctaType || "-",
            "CTA Used": ctaUsed || "-",
            "Tab Name": tabName || "-",
            "Issues Count": issueCount || "-",
            "Checked Count": approveCount || "-",
            "Suggestion Count": suggestionCount || "-",
        };
        mixpanel.track("LM Suggestion Modal", obj);
    };

    const lmManager = ({
        ctaType = "",
        ctaUsed = "",
        tabName = "",
        issueCount = "",
    }) => {
        let obj = {
            "CTA Type": ctaType || "-",
            "CTA Used": ctaUsed || "-",
            "Tab Name": tabName || "-",
            "Issues Count": issueCount || "-",
        };
        mixpanel.track("LM Report Actions", obj);
    };

    const lmManagerReport = ({
        source = "",
        mailType = "",
        typeStatus = "",
        dmName = "",
        dmId,
    }) => {
        let obj = {
            "From Mail": source || "-",
            "Mail Type": mailType || "-",
            "Type Status": typeStatus || "-",
            "Domain Name": dmName || "-",
            "Domain Id": dmId || "-",
        };
        console.log("*** lmManagerReport", obj);
        mixpanel.track("LM Report Landed", obj);
    };

    const lmReview = ({ ctaType = "", ctaUsed = "" }) => {
        let obj = {
            "CTA Type": ctaType || "-",
            "CTA Used": ctaUsed || "-",
        };
        mixpanel.track("LM Review", obj);
    };

    const lmPublish = ({ ctaType = "", ctaUsed = "" }) => {
        let obj = {
            "CTA Type": ctaType || "-",
            "CTA Used": ctaUsed || "-",
        };
        mixpanel.track("LM Publish", obj);
    };
    return {
        lMLanding,
        lMAddDomain,
        lMUpgradePlan,
        lMBuyPages,
        lMManageDomainCta,
        lMVerificationCta,
        lmSuggestionModal,
        lmManager,
        lmReview,
        lmPublish,
        lmManagerReport,
    };
};
