import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../store/actions/alert";
import useHelperFunctions from "../../helperFunctions";

function useTeam({ onlyTags = false, container }) {
    console.log("container", container);
    const { postData, getData } = useHelperFunctions();
    const [members, setMembers] = useState([]);
    const [tags, setTags] = useState([]);
    const [allDomains, setAllDomains] = useState([]);
    const [invited, setInvited] = useState(false);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [inviteErr, setInviteErr] = useState(false);
    const [inviteLimitErr, setInviteLimitErr] = useState(false);
    const [isManage, setIsManage] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (onlyTags) {
            getTags();
        } else {
            getMembers();
        }
    }, []);

    function showSuccess(msg) {
        dispatch(
            displayAlert({
                alertMessage: msg,
                alertType: "",
            }),
        );
    }

    async function getMembers() {
        setLoading(true);
        const res = await getData({ url: "/api/invite/getTeamList" });

        if (res.status == 200) {
            if (res.data.teamList) {
                const tags = await getTags();
                const members = [];
                for (const member of res.data.teamList) {
                    if (member.tags) {
                        member.tags = tags.filter((tag) =>
                            JSON.parse(member.tags).includes(tag.id),
                        );
                    } else {
                        member.tags = [];
                    }
                    members.push(member);
                }
                setMembers(members);
            }
        }
        setLoading(false);
    }

    async function removeMember(id) {
        setLoading(true);
        const res = await postData({
            url: "/api/invite/remove",
            payload: { userId: id },
        });
        setLoading(false);
        if (res.status == 200) {
            getMembers();
            showSuccess("Successfully Removed");
            return true;
        } else {
            return false;
        }
    }

    async function sendInvite(email1, name, selectedTags, _domains) {
        setLoading(true);
        const res = await postData({
            url: "/api/invite/send",
            payload: {
                inviteEmail: email1,
                name: name,
                ...(_domains.length > 0 && { domain_list: _domains }),
                tags:
                    tags.length == selectedTags.length
                        ? [-1]
                        : selectedTags.map((tag) => tag.id),
            },
            showError: false,
        });
        setLoading(false);
        if (res.status == 200) {
            getMembers();
            setInvited(true);
            return true;
        } else {
            if (res?.response?.data?.message.includes("more")) {
                setInviteLimitErr("upgrade");
            } else {
                setInviteErr(true);
            }

            return false;
        }
    }

    async function getTags() {
        setLoading(true);
        const res = await getData({
            url: "/api/invite/listTags",
        });
        setLoading(false);
        if (res.status == 200) {
            setAllDomains(
                res.data.data?.domainList?.length > 0
                    ? res.data.data?.domainList
                    : [],
            );
            if (res.data.data.list.length == 0) setTags(res.data.data.list);
            else {
                setTags(
                    res.data.data.list.map((tag) => {
                        return {
                            id: tag.id,
                            tag_name: tag.tag_name,
                        };
                    }),
                );
            }
            return res.data.data.list;
        }
    }

    async function createTag(tagName) {
        setLoading(true);
        const res = await postData({
            url: "/api/invite/addTag",
            payload: { tag_name: tagName },
        });
        setLoading(false);
        if (res.status == 200) {
            await getTags();

            showSuccess("Successfully Created tag - " + tagName);
            return { id: res.data.data.tag_id, tag_name: tagName };
        }
    }

    async function addTag(data, id, report, smartDoc, cluster) {
        setLoading(true);
        console.log("addTag", data);
        let payload = report
            ? {
                  report_id: id,
                  tags: data.tags.map((tag) => tag.id),
              }
            : smartDoc
            ? {
                  doc_id: id,
                  tags: data.tags.map((tag) => tag.id),
              }
            : {
                  invited_user_id: id,
                  tags:
                      tags.length == data.tags.length
                          ? [-1]
                          : data.tags.map((tag) => tag.id),
                  domain_list: data?._domains?.length > 0 ? data?._domains : [],
              };
        if (cluster) {
            payload = {
                topic_id: cluster,
                tags: data.tags.map((tag) => tag.id),
            };
        }

        const res = await postData({
            url: "/api/invite/attachTagToUser",
            payload: payload,
        });

        if (res.status == 200) {
            if (!(report || smartDoc)) await getMembers();
        }
        setLoading(false);
    }

    async function removeTag() {
        setLoading(true);
        const res = await postData({
            url: "/api/invite/send",
            payload: { inviteEmail: email },
        });
        setLoading(false);
        if (res.status == 200) {
            getTags();
        }
    }

    async function editTag(id = 0, name = "Tag", remove = false) {
        if (remove) {
            const res = await postData({
                url: "/api/invite/deleteTag",
                payload: { tag: id },
            });
            if (res.status == 200) {
                setTags((pt) => pt.filter((tag) => tag.id != id));
                showSuccess("Successfully Deleted tag - " + name);
            }
        } else {
            const res = await postData({
                url: "/api/invite/renameTag",
                payload: { tag: id, name },
            });
            if (res.status == 200) {
                const i = tags.findIndex((tag) => tag.id == id);
                const arr = [...tags];
                arr[i] = { ...arr[i], tag_name: name };
                setTags(arr);
                showSuccess("Successfully Renamed tag - " + name);
            } else {
                return "error";
            }
        }
    }
    return {
        members,
        sendInvite,
        setInvited,
        invited,
        loading,
        email,
        setEmail,
        removeMember,
        removeTag,
        createTag,
        addTag,
        tags,
        inviteErr,
        setInviteErr,
        inviteLimitErr,
        setInviteLimitErr,
        getTags,
        editTag,
        isManage,
        setIsManage,
        allDomains,
        setAllDomains,
    };
}

export default useTeam;
