import React, { useContext, useEffect, useMemo, useRef } from "react";
import styles from "./NewDashboard.module.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useHelperFunctions from "../../helperFunctions";
import screens from "../../../screens.json";
import Modal from "../../atoms/Modal/Modal";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import { OnBoardingContext } from "../../Context/OnBoardingContext/OnBoardingContextProvider";
import KeywordInput from "../../molecules/Creator/KeywordInput/KeywordInput";
import KeywordInputSingle from "../../molecules/Creator/KeywordInput/KeywordInputSingle";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";

import ModuleCard from "./ModuleCard";
import Header from "../../organisms/Header/Header";
import { FEATURES } from "./constants";
import { FcGoogle } from "react-icons/fc";
import { VscClose } from "react-icons/vsc";
import GscLogin from "../GoogleSearchConsole/GscLogin";
import GoogleSearchConsole from "../GoogleSearchConsole/GoogleSearchConsole";
import GscLoginBar from "./GscLoginBar";
import { ExitIntentModal } from "../../templates/Dashboard/CCUser";
import { formatURL } from "../../../utility/helperFun";
import VideoModal from "./VideoModal";
import { BsArrowRight } from "react-icons/bs";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { setCruiseModePopup } from "../../../store/actions/state";
import SpinWheel from "../../atoms/SpinWheel/SpinWheel";
import { Skeleton } from "@material-ui/lab";
import confetti from "./confetti.gif";
import InterLinkingExitIntent from "../InterLinking/InterLinkingExitIntent";
import { PLANS_CATEGORIES } from "../../../constants";
import LmDashboardBar from "./LmDashboardBar";

export const makeClusterReport = async ({
    cluster_keywords,
    inputValue,
    country,
    setLoading,
    goTo,
    postData,
    id,
    gscConnectedDomain,
    competitorsDomain = [],
}) => {
    setLoading(true);
    let _competitorsDomain = competitorsDomain
        ?.map((item) => (item?.length ? formatURL(item) : false))
        ?.filter((itm) => (itm?.length ? true : false));
    const res = await postData({
        url: `/api/clustering/create`,

        payload: {
            keyword: inputValue,
            country: country.label,
            country_code: country.country_code,
            ...(!!gscConnectedDomain?.length && {
                domain_name: formatURL(gscConnectedDomain),
            }),
            ...(!!_competitorsDomain?.length && {
                competitor_domain: _competitorsDomain,
            }),
            ...(cluster_keywords?.length && {
                cluster_keywords: cluster_keywords,
                is_excel_sheet: true,
            }),
        },
    });
    if (res.status == 200) goTo("CLUSTER");
    setLoading(false);
};

export const makeReport = async ({
    keywords,
    inputValue,
    country,
    setLoading,
    goTo,
    postData,
    id,
    url,
    audit = false,
    competitors_count,
    is_premium,
    dispatch,
}) => {
    let createKeywords;
    if (keywords && keywords.length > 0) createKeywords = keywords;
    else if (typeof inputValue == "string") {
        if (!inputValue.trim()) return false;
        else createKeywords = [inputValue];
    } else return;
    let payload = {
        keyword: createKeywords,
        country: country.label,
        ...(audit && {
            url:
                url.indexOf("https://") == 0 || url.indexOf("http://") == 0
                    ? url
                    : url.length > 0
                    ? `https://${url}`
                    : "",
        }),
        ...(audit && { doc_type: 2 }),
        competitors_count,
        is_premium,
    };
    setLoading(true);
    let temp = {
        created_at: Date(),
        content_updated_at: Date(),
    };

    const res = await postData({
        url: `/api/keywords/create`,
        payload,
    });

    if (res.status == 200) {
        if (id == -1) {
            goTo("CREATOR");
        } else if (id == -5) {
            goTo("AUDIT");
        } else {
            goTo("SEO_EDITOR", [], res.data.report_id);
        }
        dispatch(
            setCruiseModePopup({
                value: false,
                isClose: true,
            }),
        );
    }
    setLoading(false);
};

export const SeoWithCruiseModal = ({
    modal,
    setModal,
    sampleClusters,
    sampleReports,
    sampleAudits,
    dashboardInfo,
}) => {
    const goTo = useNavigation();
    const { LockConfigurator } = useUserAccessHook();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { postData } = useHelperFunctions();

    const getFeatureWiseModalHead = (modalId) => {
        switch (modalId) {
            case -1:
                return (
                    <>
                        You're just a step away from crafting a piece of ranking
                        content.
                    </>
                );
                break;

            case -2:
                if (LockConfigurator(FEATURE_TYPES.cruiseModeLock))
                    return (
                        <>
                            Cruise Mode is not available on your current plan.
                            Either upgrade to use this feature or else create a
                            SEO Article to use AI features.
                        </>
                    );
                else {
                    return (
                        <>
                            You're just a step away from crafting a piece of
                            ranking content.
                        </>
                    );
                }

                break;
            case -3:
                if (LockConfigurator(FEATURE_TYPES.topicClusterLock))
                    return (
                        <>
                            Keyword Planner is not available on your current
                            plan. Please upgrade to use this feature.
                        </>
                    );
                else {
                    return (
                        <>
                            Create content ideas for keywords you would like to
                            rank for
                        </>
                    );
                }

                break;

            case -4:
                if (LockConfigurator(FEATURE_TYPES.appIntegrationLock))
                    return (
                        <>
                            App Integrations is not available on your current
                            plan. Please upgrade to use this feature.
                        </>
                    );
                break;

            case -5:
                if (LockConfigurator(FEATURE_TYPES.contentAuditLock))
                    return (
                        <>
                            Content Optimizer is not available on your current
                            plan. Please upgrade to use this feature.
                        </>
                    );
                else {
                    return (
                        <>
                            Optimize your content for search engine success with
                            our SEO score.
                        </>
                    );
                }
                break;

            default:
                break;
        }
    };

    const getFeatureWiseModalBottom = (modalId) => {
        switch (modalId) {
            case -1:
                return (
                    <KeywordInput
                        isDash={true}
                        cruiseMode={true}
                        docs={[]}
                        loadingCreation={loading}
                        sampleReports={sampleReports}
                        makeReport={(params) => {
                            makeReport({
                                ...params,
                                setLoading,
                                goTo,
                                postData,
                                id: modal.id,
                                dispatch,
                            });
                        }}
                        buttonText="Start"
                        buttonIcon={
                            <SVGIcon
                                size={20}
                                src={"/New UI/SVG/bsarrowright.svg"}
                            />
                        }
                    />
                );
            case -2:
                if (LockConfigurator(FEATURE_TYPES.cruiseModeLock))
                    return (
                        <>
                            <>
                                <div className={styles.btnRow}>
                                    {modal.id == -2 && (
                                        <Button
                                            handler={() => {
                                                setModal({
                                                    open: true,
                                                    id: -1,
                                                });
                                            }}
                                            text={"Create SEO Article"}
                                            width="40%"
                                            style={{
                                                backgroundColor: "white",
                                                color: "#1f4cbe",
                                                border: "1px #1f4cbe solid",
                                                fontSize: 14,
                                            }}
                                        ></Button>
                                    )}
                                    <Button
                                        width="40%"
                                        handler={() => {
                                            goTo("BILLING_PLANS");
                                        }}
                                        text="Upgrade Plan"
                                        style={{
                                            fontSize: 14,
                                            backgroundColor: "#1f4cbe",
                                        }}
                                    ></Button>
                                </div>
                            </>
                        </>
                    );
                else {
                    return (
                        <>
                            <KeywordInput
                                isDash={true}
                                cruiseMode={true}
                                isCruiseOpen={true}
                                apiCalls={1}
                                docs={dashboardInfo?.dashboard_cards[0]?.docs}
                                loadingCreation={loading}
                                sampleReports={sampleReports}
                                makeReport={(params) => {
                                    makeReport({
                                        ...params,
                                        setLoading,
                                        goTo,
                                        postData,
                                        id: modal.id,
                                        dispatch,
                                    });
                                }}
                                buttonText="Start"
                                buttonIcon={
                                    <SVGIcon
                                        size={20}
                                        src={"/New UI/SVG/bsarrowright.svg"}
                                    />
                                }
                            />
                        </>
                    );
                }

                break;
            case -3:
                if (LockConfigurator(FEATURE_TYPES.topicClusterLock))
                    return (
                        <>
                            <div className={styles.btnRow}>
                                <Button
                                    width="40%"
                                    handler={() => {
                                        goTo("BILLING_PLANS");
                                    }}
                                    text="Upgrade Plan"
                                    style={{
                                        fontSize: 14,
                                        backgroundColor: "#1f4cbe",
                                    }}
                                ></Button>
                            </div>
                        </>
                    );
                else {
                    return (
                        <>
                            <KeywordInputSingle
                                isDash={true}
                                apiCalls={1}
                                cruiseMode={true}
                                docs={dashboardInfo?.dashboard_cards[2]?.docs}
                                sampleClusters={sampleClusters}
                                loadingCreation={loading}
                                makeReport={(params) => {
                                    console.log(params, "Cluster......");

                                    makeClusterReport({
                                        ...params,
                                        setLoading,
                                        goTo,
                                        postData,
                                        id: modal.id,
                                    });
                                }}
                                placeholderText="Enter keyword to find related keywords"
                                buttonText="Start"
                                buttonIcon={
                                    <SVGIcon
                                        size={20}
                                        src={"/New UI/SVG/bsarrowright.svg"}
                                    />
                                }
                                hasUsedKeywordGap={
                                    dashboardInfo.hasUsedKeywordGap
                                }
                            />
                        </>
                    );
                }

                break;

            case -4:
                if (LockConfigurator(FEATURE_TYPES.appIntegrationLock))
                    return (
                        <>
                            <div className={styles.btnRow}>
                                <Button
                                    width="40%"
                                    handler={() => {
                                        goTo("BILLING_PLANS");
                                    }}
                                    text="Upgrade Plan"
                                    style={{
                                        fontSize: 14,
                                        backgroundColor: "#1f4cbe",
                                    }}
                                ></Button>
                            </div>
                        </>
                    );
                break;

            case -5:
                if (LockConfigurator(FEATURE_TYPES.contentAuditLock))
                    return (
                        <>
                            <div className={styles.btnRow}>
                                <Button
                                    width="40%"
                                    handler={() => {
                                        goTo("BILLING_PLANS");
                                    }}
                                    text="Upgrade Plan"
                                    style={{
                                        fontSize: 14,
                                        backgroundColor: "#1f4cbe",
                                    }}
                                ></Button>
                            </div>
                        </>
                    );
                else {
                    return (
                        <>
                            <KeywordInput
                                isDash={true}
                                isImportUrl={true}
                                audit={true}
                                cruiseMode={true}
                                isCruiseOpen={modal.id == -5 ? false : true}
                                docs={dashboardInfo?.dashboard_cards[1]?.docs}
                                apiCalls={1}
                                loadingCreation={loading}
                                sampleReports={sampleAudits}
                                makeReport={(params) => {
                                    makeReport({
                                        ...params,
                                        setLoading,
                                        goTo,
                                        postData,
                                        id: modal.id,
                                        dispatch,
                                        audit: true,
                                    });
                                }}
                                buttonText="Start"
                                buttonIcon={
                                    <SVGIcon
                                        size={20}
                                        src={"/New UI/SVG/bsarrowright.svg"}
                                    />
                                }
                            />
                        </>
                    );
                }
                break;

            default:
                break;
        }
    };

    return (
        <Modal setModal={() => setModal({ open: false, id: -2 })}>
            <div className={styles.seoModal}>
                <span className={styles.seoModalCross}>
                    <SVGIcon
                        onClick={() => setModal({ open: false, id: -2 })}
                        src="/New UI/SVG/cross.svg"
                    ></SVGIcon>
                </span>
                <h3>{getFeatureWiseModalHead(modal.id)}</h3>
                {getFeatureWiseModalBottom(modal.id)}
            </div>
        </Modal>
    );
};

const NewDashboard = ({ setShowChatWidget = () => {} }) => {
    const userId = useSelector((state) => state.auth.userId);
    const otherInfo = useSelector((state) => state.otherInfo);
    const { getData, postData } = useHelperFunctions();
    const user_name = useSelector((state) => state.auth.user_name);
    const [modal, setModal] = useState({ open: false, id: -1 });
    const onBoarding = useContext(OnBoardingContext);
    const sampleReports = onBoarding.sampleData.seo;
    const sampleClusters = onBoarding.sampleData.clusters;
    const sampleAudits = onBoarding.sampleData.audit;
    const { audit, seo, cluster } = useSelector((state) => state.video);
    const { cruiseModePopup } = useSelector((state) => state.state);
    const { LockConfigurator, isLoaded, freeUser, trialUser, isLTD } =
        useUserAccessHook();
    const [removeGscBar, setRemoveGscBar] = useState(false);
    const [barShimmer, setBarShimmer] = useState(true);
    const [isLmConnected, setIsLmConnected] = useState(null);
    const [gscConnectStatus, setGscConnectStatus] = useState(null);
    const [premiumCruisePromotion, setPremiumCruisePromotion] = useState(false);
    const [webtunePromotion, setWebtunePromotion] = useState(false);
    const [openCalendly, setOpenCalendly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [spinWheelModal, setSpinWheelModal] = useState(false);
    const [spin, setSpin] = useState(false);
    const [isRewardDone, setIsRewardDone] = useState(false);
    const [finalRewardClaim, setFinalRewardClaim] = useState(false);
    const [reward, setReward] = useState("");
    const { mixpanelTrack } = useMixpanelHook();
    const dispatch = useDispatch();
    const goTo = useNavigation();

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            updatePromotion("webtune");
        },
    });

    useEffect(() => {
        const val = sessionStorage.getItem("spinwheel");
        if (val != "null" && otherInfo?.spinInfo?.status) {
            setSpinWheelModal(true);
            mixpanelTrack("Spin Wheel", { action: "Show Modal" });
        }
        return () => {
            if (sessionStorage.getItem("spinwheel") != "null") {
                sessionStorage.setItem("spinwheel", "null");
            }
        };
    }, [otherInfo?.spinInfo?.status]);

    useEffect(() => {
        if (isLmConnected != null && gscConnectStatus != null) {
            setBarShimmer(false);
        }
    }, [isLmConnected, gscConnectStatus]);

    useEffect(() => {
        if (isRewardDone) {
            setTimeout(() => {
                setFinalRewardClaim(true);
            }, 3000);
        }
    }, [isRewardDone]);

    const updatePromotion = async (moduleName) => {
        const res = await postData({
            url: "/api/promotions/update",
            payload: { type: moduleName },
        });
    };

    const dashboard_cards_init = [
        {
            title: <>Article Writer</>,
            vdLink: seo?.link,
            url: screens.CREATOR,
            id: FEATURES.cruise_mode,
            docs: [],
            docUrlPrefix: function (id) {
                return "/admin/creator/editor/" + id;
            },
            createHandler: () => {
                setModal({ open: true, id: -2 });
            },
            cruiseAccess: !LockConfigurator(FEATURE_TYPES.cruiseModeLock),
            ctaText: "Create",
            subText: "Create an optimized article in 5 min",
        },
        {
            title: <>Content Optimizer</>,
            vdLink: audit?.link,
            url: screens.AUDIT,
            id: FEATURES.optimizer,
            docs: [],
            docUrlPrefix: (id) => "/admin/audit/editor/" + id,
            createHandler: () => {
                setModal({ open: true, id: -5 });
            },
            ctaText: "Optimize",
            subText: "Optimize any page for SERP",
        },
        {
            title: <>Keyword Planner</>,
            vdLink: cluster?.link,
            url: screens.CLUSTER,
            id: FEATURES.keyword_planner,
            docs: [],
            docUrlPrefix: (id) => "/admin/cluster/report/" + id,
            createHandler: () => {
                setModal({ open: true, id: -3 });
            },
            ctaText: "Plan",
            subText: "Build targeted keyword strategy",
        },
    ];
    const [dashboardInfo, setDashboardInfo] = useState({
        dashboard_cards: dashboard_cards_init,
        recommended: 0,
        hasUsedKeywordGap: true,
    });

    useEffect(() => {
        //trial user
        //has 0 reports
        //recommended feature is cruise mode
        if (
            trialUser &&
            dashboardInfo.recommended == 1 &&
            dashboardInfo.dashboard_cards[0]?.docs[0]?.is_sample &&
            dashboardInfo.dashboard_cards[1]?.docs[0]?.is_sample &&
            dashboardInfo.dashboard_cards[2]?.docs[0]?.is_sample &&
            userId % 10 < 4
        ) {
            if (dashboardInfo.dashboard_cards[0]?.docs[0]?.is_sample) {
                if (!cruiseModePopup?.isClose) {
                    dispatch(
                        setCruiseModePopup({
                            value: true,
                            isClose: false,
                        }),
                    );
                }
            }
        }
    }, [dashboardInfo]);

    useEffect(() => {
        const getDashboardInfo = async () => {
            const res = await getData({ url: "/api/dashboard/dashboardInfo" });
            if (res.status == 200) {
                setPremiumCruisePromotion(
                    res.data?.promotions?.show_premium_cruise_popup,
                );
                setWebtunePromotion(res.data?.promotions?.show_webtune_popup);
                setDashboardInfo((ps) => {
                    const newState = {
                        dashboard_cards: dashboard_cards_init,
                        recommended:
                            res.data?.recommend?.recommended_feature_id || 1,
                        hasUsedKeywordGap: res?.data?.competitorDomain
                            ? true
                            : false,
                    };
                    newState.dashboard_cards[0].docs = res.data.data.seoDocs;
                    newState.dashboard_cards[1].docs =
                        res.data.data.optimiserDocs;
                    newState.dashboard_cards[2].docs = res.data.data.clusters;
                    return newState;
                });
            }
        };
        if (isLoaded) getDashboardInfo();
    }, [isLoaded]);

    useEffect(() => {
        return () => {
            setShowChatWidget(false);
        };
    }, []);

    useEffect(() => {
        console.log(removeGscBar, "removeGsc");
    }, [removeGscBar]);

    return (
        <>
            <div className={styles.dashboard}>
                <PopupModal
                    open={openCalendly}
                    onModalClose={() => setOpenCalendly(false)}
                    url="https://calendly.com/d/5gx-7jm-fw2/scalenut-demo"
                    rootElement={
                        document.getElementById("root") || document.body
                    }
                />
                {webtunePromotion ? (
                    <Modal>
                        <div className={styles.WebtuneModalWrapper}>
                            <VscClose
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "20px",
                                    cursor: "pointer",
                                    color: "#ffffff94",
                                }}
                                size={24}
                                onClick={() => {
                                    updatePromotion("webtune");
                                    setWebtunePromotion(false);
                                    mixpanelTrack("Webtune Promotion", {
                                        action: "Close",
                                    });
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    left: "25px",
                                    top: "25px",
                                }}
                            >
                                <SVGIcon src="/New UI/SVG/webtune_logo.svg" />
                            </div>
                            <div className={styles.WebtuneHeaderWrapper}>
                                <div className={styles.headerSection1}>
                                    <div className={styles.webtuneText}>
                                        <SVGIcon src="/New UI/SVG/webtuneText.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.WebtuneBottomWrapper}>
                                <div className={styles.premiumBottomHeader}>
                                    <div className={styles.premiumSep2}></div>
                                    <div
                                        className={
                                            styles.premiumBottomHeaderText
                                        }
                                    >
                                        On-page SEO made easy
                                    </div>
                                    <div className={styles.premiumSep2}></div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: "30px",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        gap: "15px",
                                        marginBottom: "50px",
                                    }}
                                >
                                    <div className={styles.webtuneFeature1}>
                                        <SVGIcon
                                            size={14}
                                            src="/New UI/SVG/webtune_search.svg"
                                        />
                                        <div>Detect</div>
                                    </div>
                                    <div className={styles.webtuneFeature2}>
                                        <SVGIcon
                                            size={14}
                                            src="/New UI/SVG/webtune_magic.svg"
                                        />
                                        <div>Resolve</div>
                                    </div>
                                    <div className={styles.webtuneFeature3}>
                                        <SVGIcon
                                            size={14}
                                            src="/New UI/SVG/webtune_electric.svg"
                                        />
                                        <div>Deploy</div>
                                    </div>
                                </div>
                                <Button
                                    handler={() => {
                                        mixpanelTrack("Webtune Promotion", {
                                            action: "Schedule",
                                        });
                                        setOpenCalendly(true);
                                        setWebtunePromotion(false);
                                    }}
                                    Icon={() => (
                                        <BsArrowRight
                                            color="#5c0ac7"
                                            strokeWidth={"1px"}
                                        />
                                    )}
                                    text={"Schedule Demo"}
                                    width={"fit-content"}
                                    height={50}
                                    style={{
                                        paddingInline: "25px",
                                        borderRadius: "88px",
                                        color: "#000",
                                        fontSize: "22px",
                                        fontWeight: 500,
                                        backgroundImage:
                                            "linear-gradient(to bottom, rgba(255, 255, 255, 0) 44%, #8333ea 100%), linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #024cc5, #024cc5)",
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                ) : (
                    <></>
                )}
                <header>
                    <Header />
                </header>

                {!isLTD &&
                otherInfo.promotionalModal == 1 &&
                !(
                    (otherInfo?.subscriptionInfo?.plan_category ==
                        PLANS_CATEGORIES.PRO ||
                        otherInfo?.subscriptionInfo?.plan_category ==
                            PLANS_CATEGORIES.PRO_MAX) &&
                    otherInfo?.subscriptionInfo?.type == 2
                ) &&
                !trialUser &&
                !freeUser &&
                otherInfo?.subscriptionInfo
                    ?.interlinking_purchased_pages_quantity == 0 ? (
                    <InterLinkingExitIntent
                        handler={() => {
                            goTo("LINK_MANAGER");
                        }}
                    />
                ) : (
                    <></>
                )}
                <div className={styles.welcomeHeader}>
                    <h2 className={styles.username}>Hi {user_name}</h2>
                    <div className={styles.text}>
                        What are we scaling today?
                    </div>
                </div>
                <div
                    style={{
                        position: "relative",
                        padding: "0px 20px",
                        margin: "auto",
                        minHeight: barShimmer ? "60px" : "0px",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: "0px",
                            left: "20px",
                            right: "20px",
                            bottom: "0px",
                            display: barShimmer ? "grid" : "none",
                            gridTemplateColumns: "80% 1% 19%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                borderRadius: "8px",
                            }}
                        >
                            {Array(4)
                                .fill(1)
                                .map((it) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "10px",
                                            background: "white",
                                            width: "100%",
                                        }}
                                    >
                                        <Skeleton
                                            width={"30%"}
                                            height={"20px"}
                                        />
                                        <Skeleton
                                            width={"50%"}
                                            height={"30px"}
                                        />
                                    </div>
                                ))}
                        </div>
                        <div></div>
                        <div
                            style={{
                                display: "flex",
                                padding: "10px",
                                background: "white",
                                width: "100%",
                                alignItems: "flex-start",
                                borderRadius: "8px",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <Skeleton
                                width={"40%"}
                                height={"50px"}
                                style={{ marginTop: "-5px" }}
                            />
                            <Skeleton width={"40%"} height={"30px"} />
                        </div>
                    </div>

                    <div
                        style={{
                            visibility: barShimmer ? "hidden" : "visible",
                        }}
                    >
                        {!gscConnectStatus && (
                            <LmDashboardBar
                                gscConnectStatus={false}
                                setIsLmConnected={setIsLmConnected}
                                isLmConnected={isLmConnected}
                                setBarShimmer={setBarShimmer}
                            />
                        )}
                        {!removeGscBar && (
                            <GscLoginBar
                                setRemoveGscBar={setRemoveGscBar}
                                removeGscBar={removeGscBar}
                                setIsLmConnected={setIsLmConnected}
                                isLmConnected={isLmConnected}
                                setGscConnectStatus={setGscConnectStatus}
                                setBarShimmer={setBarShimmer}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.cards}>
                    {dashboardInfo.dashboard_cards.map((card, i) => {
                        return (
                            <ModuleCard
                                handler={card.createHandler}
                                firstCard={i == 0}
                                data={card}
                                recommended={
                                    dashboardInfo.recommended == card.id
                                }
                                isSecondary={
                                    dashboardInfo.recommended ==
                                        FEATURES.cruise_mode &&
                                    card.id != FEATURES.cruise_mode
                                }
                                i={i}
                            ></ModuleCard>
                        );
                    })}
                </div>

                {modal.open && (
                    <SeoWithCruiseModal
                        modal={modal}
                        setModal={setModal}
                        sampleClusters={sampleClusters}
                        sampleReports={sampleReports}
                        sampleAudits={sampleAudits}
                        dashboardInfo={dashboardInfo}
                    />
                )}
                {cruiseModePopup.value &&
                    trialUser &&
                    dashboardInfo.recommended == 1 && (
                        <Modal
                            setModal={() => {
                                mixpanelTrack("Cruise Mode Popup", {
                                    action: "Cross icon",
                                });
                                dispatch(
                                    setCruiseModePopup({
                                        value: false,
                                        isClose: true,
                                    }),
                                );
                            }}
                            props={{
                                style: { background: "rgba(0,0,0,.4)" },
                            }}
                        >
                            <div className={styles.cruiseModeModal}>
                                <div className={styles.cruiseModeModalHeading}>
                                    Let’s craft your first article ✨
                                </div>
                                <div className={styles.cruiseModeModalSubText}>
                                    {`It's just `}
                                    <span style={{ color: "#7188AA" }}>
                                        {`Click, Click & Done!`}
                                    </span>
                                    <span
                                        style={{
                                            position: "absolute",
                                            bottom: -17,
                                            right: 140,
                                        }}
                                    >
                                        <svg
                                            width="158"
                                            height="20"
                                            viewBox="0 0 158 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.5"
                                                d="M2.23665 4.72542C20.3181 2.91635 112.641 -1.25275 155.076 7.149"
                                                stroke="url(#paint0_linear_12191_11501)"
                                                stroke-width="4"
                                                stroke-linecap="round"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_12191_11501"
                                                    x1="2.23683"
                                                    y1="4.51562"
                                                    x2="155.078"
                                                    y2="4.64897"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stop-color="#10CFFC" />
                                                    <stop
                                                        offset="1"
                                                        stop-color="#1DC198"
                                                    />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                                <div
                                    className={styles.tag}
                                    style={{
                                        borderTopLeftRadius: "8px",
                                        borderTopRightRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                        borderBottomRightRadius: "8px",
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        fontSize: "12px",
                                    }}
                                >
                                    Recommended
                                </div>
                                <VscClose
                                    onClick={() => {
                                        mixpanelTrack("Cruise Mode Popup", {
                                            action: "Cross icon",
                                        });
                                        dispatch(
                                            setCruiseModePopup({
                                                value: false,
                                                isClose: true,
                                            }),
                                        );
                                    }}
                                    size={18}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "10px",
                                        cursor: "pointer",
                                    }}
                                />
                                <KeywordInput
                                    isDash={true}
                                    cruiseMode={true}
                                    firstTimeUser={true}
                                    docs={
                                        dashboardInfo?.dashboard_cards[0]?.docs
                                    }
                                    apiCalls={1}
                                    loadingCreation={loading}
                                    sampleReports={sampleReports}
                                    isCruiseOpen={true}
                                    makeReport={(params) => {
                                        makeReport({
                                            ...params,
                                            setLoading,
                                            goTo,
                                            postData,
                                            id: -2,
                                            dispatch,
                                        });
                                    }}
                                    buttonText="Let's Create"
                                    buttonIcon={
                                        <SVGIcon
                                            size={20}
                                            src={"/New UI/SVG/bsarrowright.svg"}
                                        />
                                    }
                                />
                                <div
                                    style={{
                                        color: "#ACB3BF",
                                        fontSize: "12px",
                                        marginTop: "15px",
                                        marginBottom: "-5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    Powered by{" "}
                                    <span
                                        style={{
                                            color: "rgb(11 143 137)",
                                            background:
                                                "linear-gradient(90deg, rgba(16,207,252,0.5) 0%, rgba(29,193,152,0.5) 100%)",
                                            padding: "3px 8px",
                                            marginLeft: "5px",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        Cruise Mode
                                    </span>
                                </div>
                            </div>
                        </Modal>
                    )}

                {spinWheelModal &&
                    !otherInfo?.spinInfo?.rewardId &&
                    otherInfo?.spinInfo?.rewards?.length && (
                        <Modal setModal={setSpinWheelModal}>
                            <div
                                style={{
                                    height: isRewardDone ? "250px" : "500px",
                                    backgroundImage: reward.reward_id
                                        ? `url(${confetti})`
                                        : "none",
                                    backgroundSize: reward.reward_id
                                        ? "cover"
                                        : "",
                                }}
                                className={styles.spinWrapper}
                            >
                                <VscClose
                                    size={24}
                                    color="#d9d9d9"
                                    style={{
                                        position: "absolute",
                                        right: 20,
                                        top: 20,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setSpinWheelModal(false);
                                        mixpanelTrack("Spin Wheel", {
                                            action: "Close Icon",
                                        });
                                    }}
                                />
                                {isRewardDone ? (
                                    finalRewardClaim ? (
                                        <>
                                            <div
                                                style={{ opacity: 1 }}
                                                className={
                                                    styles.rewardClaimBox
                                                }
                                            >
                                                <div
                                                    className={styles.line1Text}
                                                >
                                                    Steps to claim your reward
                                                </div>
                                                <div
                                                    className={styles.line2Text}
                                                >
                                                    1. Choose either Growth
                                                    Annual Plan or Pro Annual
                                                    Plan
                                                    <br />
                                                    2. Within 24 hours, your
                                                    reward will be added to your
                                                    account
                                                </div>
                                                <Button
                                                    width={180}
                                                    style={{
                                                        background: "#0950FF",
                                                        color: "#fff",
                                                        borderRadius: "20px",
                                                        marginTop: "20px",
                                                        letterSpacing: "1px",
                                                    }}
                                                    text={"CLAIM REWARD"}
                                                    handler={() => {
                                                        goTo("BILLING_PLANS");
                                                        mixpanelTrack(
                                                            "Spin Wheel",
                                                            {
                                                                action: "Cliam Reward",
                                                            },
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className={
                                                styles.congratulationsBox
                                            }
                                        >
                                            <div className={styles.line1Text}>
                                                Congratulations, {user_name}
                                            </div>
                                            <div className={styles.line2Text}>
                                                You have won{" "}
                                                {reward?.rewardDescription}
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                width: "40%",
                                                position: "relative",
                                            }}
                                        >
                                            <div className={styles.spinHeading}>
                                                Spin and Win
                                            </div>
                                            <div>
                                                Get a guaranteed reward! A
                                                powerful add-on, that stays for
                                                life.
                                            </div>
                                            <Button
                                                width={90}
                                                style={{
                                                    background: "#0950FF",
                                                    color: "#fff",
                                                    borderRadius: "20px",
                                                    marginTop: "30px",
                                                    letterSpacing: "1px",
                                                }}
                                                handler={() => {
                                                    setSpin(true);
                                                    sessionStorage.setItem(
                                                        "spinwheel",
                                                        null,
                                                    );
                                                    mixpanelTrack(
                                                        "Spin Wheel",
                                                        {
                                                            action: "Spin",
                                                        },
                                                    );
                                                }}
                                                disabled={spin}
                                                customClass={styles.spinButton}
                                                text="SPIN"
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: -100,
                                                    fontSize: "12px",
                                                }}
                                            >
                                                * Limited-time offer
                                                <br />* Applicable on Growth &
                                                Pro Annual Plans
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <SpinWheel
                                                reward={reward}
                                                setReward={setReward}
                                                setIsRewardDone={
                                                    setIsRewardDone
                                                }
                                                spin={spin}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Modal>
                    )}
            </div>
        </>
    );
};

export default NewDashboard;
