import { BsFunnel } from "react-icons/bs";
import styles from "./ClusterReports.module.css";
import Button from "../../../atoms/Button/Button";
import { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { checkDigit } from "../../../utlities";
import { RecommendedRanges } from "./ClusterCatsFilter";
export const initialState = {
    cpc: { min: 0, max: 5 },
    search_volume: { min: 0, max: 10000 },
    relevance: { min: 0, max: 100 },
    potential_score: {
        min: 0,
        max: 100,
    },
    all: true,
};
const isInvalidRange = (range: { min: number; max: number }) =>
    +range.min > +range.max ? true : false;

function ClusterFilter({
    validState = initialState,
    setValidState,
    catsData,
    isClusterView,
}: {
    validState: any;
    setValidState: Function;
    catsData: {
        clusterView: RecommendedRanges;
        listView: RecommendedRanges;
    };
    isClusterView: boolean;
}) {
    const [open, setOpen] = useState(false);

    const [state, setState] = useState(validState);

    const applyState = (_state?: any) => {
        const newState = _state ? _state : state;
        if (
            !(
                isInvalidRange(newState.cpc) ||
                isInvalidRange(newState.relevance) ||
                isInvalidRange(newState.search_volume)
            )
        ) {
            setValidState({
                ...newState,
                ...(!_state ? { isCustom: true, all: false } : {}),
            });
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            setState(validState);
        }
    }, [open]);

    return (
        <div
            className={`${styles.filerContainer} ${
                validState.isCustom ? styles.filerContainerActive : ""
            }`}
        >
            <button
                onClick={() => {
                    setOpen(!open);
                }}
                className={styles.filerBtn}
            >
                <BsFunnel />
            </button>
            {open ? (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className={styles.filerModal}>
                        <FilterOption
                            title="Search volume"
                            id="search_volume"
                            value={state.search_volume}
                            setState={setState}
                        />
                        <FilterOption
                            value={state.cpc}
                            title={isClusterView ? "Weighted Avg. CPC" : "CPC"}
                            id="cpc"
                            setState={setState}
                        />
                        <FilterOption
                            value={state.relevance}
                            title="Relevance"
                            id="relevance"
                            setState={setState}
                        />
                        <div className={styles.actionBtns}>
                            <Button
                                style={{
                                    color: "black",
                                    backgroundColor: "#efefef",
                                    width: "47%",
                                    fontSize: 11,
                                }}
                                height={26}
                                text={"Reset"}
                                handler={() => {
                                    let newState;
                                    if (isClusterView) {
                                        newState = {
                                            ...catsData.clusterView.default,
                                        };
                                    } else {
                                        newState = {
                                            ...catsData.listView.default,
                                        };
                                    }

                                    setState(newState);
                                    applyState(newState);
                                }}
                                disabled={
                                    JSON.stringify(state) ==
                                    JSON.stringify(
                                        isClusterView
                                            ? catsData.clusterView.default
                                            : catsData.listView.default,
                                    )
                                }
                            />
                            <Button
                                style={{
                                    width: "47%",
                                    fontSize: 11,
                                }}
                                text={"Apply"}
                                height={26}
                                handler={() => {
                                    applyState();
                                }}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            ) : (
                ""
            )}
        </div>
    );
}

function FilterOption({
    title,
    id,
    value,
    setState,
}: {
    title: string;
    id: string;
    value: { min: number; max: number };
    setState: Function;
}) {
    const [range, setRange] = useState(value);

    const handleMin = (e: any) => {
        if (checkDigit(e.target.value) || !e.target.value)
            setRange((ps) => ({ min: e.target.value, max: ps.max }));
    };

    const handleMax = (e: any) => {
        if (checkDigit(e.target.value) || !e.target.value)
            setRange((ps) => ({ max: e.target.value, min: ps.min }));
    };

    const handleMinBlur = (e: any) => {
        if (!e.target.value) setRange((ps) => ({ min: 0, max: ps.max }));
    };

    const handleMaxBlur = (e: any) => {
        if (!e.target.value) setRange((ps) => ({ max: 0, min: ps.min }));
    };

    useEffect(() => {
        setRange(value);
    }, [value]);

    useEffect(() => {
        setState((ps: object) => ({ ...ps, [id]: range }));
    }, [range]);

    return (
        <div className={styles.filterOption}>
            <header>{title}</header>
            <div
                className={`${styles.rangeContainer}  ${
                    isInvalidRange(range) ? styles.invalidRange : ""
                }`}
            >
                <div>
                    <label>MIN</label>
                    <input
                        onBlur={handleMinBlur}
                        value={range.min}
                        onChange={handleMin}
                    />
                </div>

                <div>
                    <label>MAX</label>
                    <input
                        onBlur={handleMaxBlur}
                        value={range.max}
                        onChange={handleMax}
                    />
                </div>
            </div>
        </div>
    );
}

export default ClusterFilter;
