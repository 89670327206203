import React, { useEffect, useState } from "react";
import Button from "../../componentsV2/atoms/Button/Button";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import styles from "./superAdmin.module.css";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import { displayAlert } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import axios from "axios";
import { url } from "../../utility/config";

const VerifyUserEmail = ({ setActiveAction }) => {
  const [userEmail, setUserEmail] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState({ id: "", email: "" });
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();

  const { postData, getData } = useHelperFunctions();
  useEffect(() => {
    if (userId.id) getInfo();
  }, [userId]);

  useEffect(() => {
    console.log("verrifu userEmail", userEmail);
  }, [userEmail]);

  useEffect(() => {
    console.log("verrifu userId.email", userId.email);
  }, [userId.email]);

  const getInfo = async () => {
    setIsLoading(true);
    const res = await getData({
      url: "/api/user/getUserInfoAdmin?userId=" + userId.id,
    });
    console.log(res);
    if (res.data) {
      setUserData(res.data);
    }
    setIsLoading(false);
  };

  async function verifyEmail(email) {
    setIsVerifying(true);
    if (!email.length) {
      console.log("Email can't be empty");
    } else {
      await axios({
        method: "post",
        url: `${url}/api/user/checkEmail`,
        data: { email: email },
      })
        .then((res) => {
          verifyUserBackend(email);
        })
        .catch((err) => {
          dispatch(
            displayAlert({
              alertMessage: "User Doesn't Exist",
              alertType: "error",
            })
          );
        });

      setIsVerifying(false);
    }
  }

  async function verifyUserBackend(email) {
    let payload = {
      userEmail: email,
    };
    const res = await postData({ url: `/api/verifyUserEmail`, payload });
    if (res.status == 200) {
      dispatch(
        displayAlert({
          alertMessage: "Updated successfully",
          alertType: "success",
        })
      );

      setUserEmail("");
      setUserId({ id: "", email: "" });
    } else if (res.status == 400) {
      dispatch(
        displayAlert({
          alertMessage: "User Already Verified",
          alertType: "success",
        })
      );
    }
    setUserEmail("");
    setUserId({ id: "", email: "" });
  }

  return (
    <div className={styles.verifyUserContainer}>
      <p
        style={{
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
          display: "inline-block",
          marginRight: "auto",
        }}
        onClick={() => setActiveAction(null)}
      >
        {"< Go back"}
      </p>
      <div className={styles.verifyUserWrap}>
        <div className={styles.userUsageInput}>
          <AutoComplete
            email={userEmail}
            setEmail={setUserEmail}
            setUserId={setUserId}
          />
        </div>
        <div className={styles.btnWrap}>
          <Button
            disabled={!userId.email.length}
            text={isVerifying ? "Verifying" : "Verify"}
            handler={() => verifyEmail(userEmail)}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyUserEmail;
