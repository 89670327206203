import React, { useEffect, useState } from "react";
import useTeam from "../organisms/Account/useTeam";
import ReactDOM from "react-dom";
import Button from "../atoms/Button/Button";
import styles from "./tags.module.css";
import useHelperFunctions from "../helperFunctions";

export const TagsContext = React.createContext({
    tags: [],
    addTag: async () => {},
    createTag: async () => {},
    loading: false,
    tagsMap: {},
    editTag: (id = 0, name = "", remove = false) => {},
});

export const TagsContextProvider = ({ children }) => {
    const [tagsMap, setTagsMap] = useState({});
    const [container, setContainer] = useState(null);
    const [stylesManage, setStylesManage] = useState();
    const [tagId, setTagId] = useState();
    const [isTagField, setIsTagField] = useState();
    const [confirmModal, setConfirmModal] = useState(false);
    const {
        tags,
        loading,
        createTag,
        addTag,
        editTag,
        isManage,
        setIsManage,
        allDomains,
        setAllDomains,
    } = useTeam({
        onlyTags: true,
        container,
    });
    const { postData } = useHelperFunctions();
    const getContainerRef = (
        containerRef,
        manageStyles,
        isTagField = false,
    ) => {
        setContainer(containerRef);
        setStylesManage(manageStyles);
        setIsTagField(isTagField);
    };

    const getTagId = (id) => {
        setTagId(id);
    };

    useEffect(() => {
        setIsManage(false);
        setConfirmModal(false);
        if (container) {
            container.style.paddingTop = "30px";
        }
    }, [container]);

    useEffect(() => {
        if (confirmModal && container) {
            container.style.minHeight = "165px";
            container.style.height = "165px";
            if (!isTagField) container.parentNode.style.overflow = "hidden";
            container.style.overflow = "hidden";
        }
        if (!confirmModal && container) {
            container.style.minHeight = "unset";
            if (!isTagField) container.parentNode.style.overflow = "auto";

            container.style.overflow = "auto";
        }
    }, [confirmModal]);

    useEffect(() => {
        const map = new Object();

        for (const tag of tags) {
            map[tag.id] = tag;
        }
        setTagsMap(map);
    }, [tags]);

    async function handleDelete() {
        if (tagId) {
            editTag(tagId, tagsMap[tagId]?.tag_name, true);
            setConfirmModal(false);
        }
        return false;
    }

    function ManageHead() {
        return (
            <div
                style={{ ...stylesManage, cursor: "pointer" }}
                onClick={() => setIsManage(!isManage)}
            >
                {isManage ? "Done" : "Manage"}
            </div>
        );
    }

    function DeleteConfirm() {
        return (
            <div
                className={styles.modalWrap}
                onClick={() => {}}
                style={{ top: container.scrollTop }}
            >
                <div className={styles.line1}>Are you sure ?</div>
                <div className={styles.line2}>
                    There is no undo. This will remove this tag from all the
                    reports and destroy its history.
                </div>
                <div className={styles.modal_btns}>
                    <Button
                        text={"Cancel"}
                        handler={() => {
                            setConfirmModal(false);
                        }}
                        width={"40%"}
                        height={"30px"}
                        style={{
                            backgroundColor: "white",
                            border: "none",
                            color: "#d3d3d3",
                            fontSize: "12px",
                        }}
                    />
                    <Button
                        text={"Confirm"}
                        handler={() => handleDelete()}
                        width={"40%"}
                        height={"30px"}
                        style={{
                            fontSize: "12px",
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <TagsContext.Provider
            value={{
                tags,
                loading,
                createTag,
                addTag,
                tagsMap,
                editTag,
                getContainerRef,
                getTagId,
                isManage,
                setIsManage,
                confirmModal,
                setConfirmModal,
            }}
        >
            {container &&
                ReactDOM.createPortal(
                    confirmModal ? <DeleteConfirm /> : <ManageHead />,
                    container,
                )}
            {children}
        </TagsContext.Provider>
    );
};
