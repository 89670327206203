import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import {
    BASE_URL,
    clubAllPagesWise,
    clubPageWise,
    endPoints,
    generateHash,
} from "./constants";
import {
    FIX_SAVE_TYPE,
    PageInfo,
    ParagraphGroup,
    Suggestion,
} from "./LinkingSuggestionModal";
import { BiCheck, BiCloset } from "react-icons/bi";
import { HiPencil } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GoInfo } from "react-icons/go";
import update from "immutability-helper";
import useHelperFunctions from "../../helperFunctions";
import { Skeleton } from "@material-ui/lab";
import { debounce } from "@material-ui/core";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import Button from "../../atoms/Button/Button";
import SearchBar from "../../atoms/SearchBar/SearchBar";
import useGscHook from "../GoogleSearchConsole/useGscHook";
import SVGIcon from "../../atoms/SVGIcon";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";

function ReviewTable({
    getIssueCount,
}: {
    getIssueCount: () => Promise<void>;
}) {
    const { lmPublish, lmSuggestionModal } = useLinkManagerMixpanel();
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const [search, setSearch] = useState("");
    const { suggestions, setSuggestions, handleFixSave } = useChanges();
    const handleFixSaveProxy = async (...args) => {
        await handleFixSave(...args);
        getIssueCount();
    };
    const filterBySearch = (arr: any) => {
        if (!arr) return null;
        return arr.filter((item: any) => item.url.includes(search));
    };
    const handleSearchChange = useCallback(
        debounce((t) => {
            lmPublish({
                ctaType: "Publish",
                ctaUsed: "Search",
            });
            setSearch(t);
        }, 300),
        [],
    );
    const _suggestions = filterBySearch(suggestions);
    return (
        <div className={`${styles.section} ${styles.reviewChangesContainer}`}>
            <div className={styles.filtersContainer}>
                <div className={styles.filters}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 20,
                            }}
                        >
                            <h2 style={{ margin: 0, fontWeight: 600 }}>
                                Review Final Changes
                            </h2>

                            <div className={styles.count}>
                                {suggestions?.length}
                            </div>
                        </div>

                        <h3
                            style={{
                                fontWeight: 500,
                                fontSize: 16,
                                marginBottom: 5,
                                marginTop: 5,
                            }}
                        >
                            Please review your changes before making them live
                            on&nbsp;
                            <span style={{ color: "#1f4cbe" }}>
                                {selectedDomainInfo?.domain}
                            </span>
                        </h3>
                    </div>

                    <SearchBar
                        onChange={(e: any) => {
                            handleSearchChange(e.target.value);
                        }}
                        minimal={true}
                        loading={false}
                    />
                </div>
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <th>Source URL</th>
                        <th
                            className={styles.head_inbound}
                            style={{ minWidth: 135 }}
                        >
                            Anchor text content
                        </th>
                        <th
                            className={styles.head_outbound}
                            style={{ minWidth: 145 }}
                        >
                            Target URL
                        </th>
                        <th style={{ width: 250 }}></th>
                    </thead>

                    {_suggestions ? (
                        <tbody>
                            {_suggestions.length ? (
                                _suggestions.map(
                                    (suggestion: Suggestion, i: number) => (
                                        <TableRow
                                            suggestion={suggestion}
                                            handleFixSave={handleFixSaveProxy}
                                            index={i}
                                            lmPublish={lmPublish}
                                            lmSuggestionModal={
                                                lmSuggestionModal
                                            }
                                        />
                                    ),
                                )
                            ) : (
                                <tbody style={{ height: 300 }}>
                                    <div className={styles.noResult}>
                                        <SVGIcon
                                            src={
                                                "/New UI/SVG/webtuneLogsEmpty.svg"
                                            }
                                            style={{ margin: "auto" }}
                                        />
                                        No Results Found
                                    </div>
                                </tbody>
                            )}
                        </tbody>
                    ) : (
                        <OprTableRowsLoading />
                    )}
                </table>
            </div>
        </div>
    );
}

function TableRow({
    suggestion,
    handleFixSave,
    index,
    lmPublish,
    lmSuggestionModal,
}: {
    suggestion: any;
    handleFixSave: any;
    index: number;
    lmPublish: any;
    lmSuggestionModal: any;
}) {
    const [finalSuggestions, setFinalSuggestions] = useState<Array<Suggestion>>(
        [],
    );
    const { removeDomainFromURL } = useGscHook();

    const [isFixed, setIsFixed] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(null as any);
    useEffect(() => {
        if (!!suggestion.suggestions.length) {
            setIsFixed(
                !!suggestion.suggestions.find(
                    (sg: Suggestion) => sg.fix_id >= 0,
                ),
            );
        }
    }, [suggestion]);

    useEffect(() => {
        if (!isFixed) setEdit(true);
    }, [isFixed]);

    return (
        <tr>
            <td style={{ width: "25%" }}>
                <a
                    href={suggestion.url}
                    style={{
                        color: "rgb(1, 77, 197)",
                        fontWeight: 400,
                        background: "rgba(1,77,197,0.1)",
                        padding: "4px 10px",
                        borderRadius: "4px",
                    }}
                    onClick={() => {
                        lmPublish({
                            ctaType: "Publish",
                            ctaUsed: "URL Clicked",
                        });
                    }}
                    target="_blank"
                >
                    {removeDomainFromURL(suggestion.url)}
                </a>
            </td>
            <td style={{ width: "40%" }}>
                <ParagraphGroup
                    suggestion={suggestion}
                    isFixed={isFixed}
                    finalSuggestions={finalSuggestions}
                    setFinalSuggestions={setFinalSuggestions}
                    lmSuggestionModal={lmSuggestionModal}
                />
            </td>
            <td style={{ width: "25%" }}>
                <a
                    href={suggestion.recommendation}
                    target={"_blank"}
                    onClick={() => {
                        lmPublish({
                            ctaType: "Publish",
                            ctaUsed: "URL Clicked",
                        });
                    }}
                    style={{ color: "rgb(1, 77, 197)" }}
                >
                    {suggestion.recommendation}
                </a>
            </td>
            <td style={{ width: "10%" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    {!isFixed && edit ? (
                        <>
                            <LightTooltip
                                title={
                                    !finalSuggestions.length
                                        ? "Select at least one"
                                        : ""
                                }
                                arrow
                            >
                                <div>
                                    <Button
                                        Icon={() => <BiCheck size={18} />}
                                        iconReverse
                                        style={{
                                            color: "#014DC5",
                                            backgroundColor: "#014DC51A",
                                            fontWeight: 400,
                                            opacity: !finalSuggestions.length
                                                ? 0.5
                                                : 1,
                                        }}
                                        width={80}
                                        height={32}
                                        fontSize={13}
                                        handler={async () => {
                                            if (!finalSuggestions.length)
                                                return;
                                            setLoading("done");
                                            lmPublish({
                                                ctaType: "Publish",
                                                ctaUsed: "Done",
                                            });
                                            await handleFixSave(
                                                FIX_SAVE_TYPE.SAVE,
                                                finalSuggestions,
                                                index,
                                            );
                                            setLoading(null);
                                        }}
                                        text="Done"
                                        loading={loading == "done"}
                                    ></Button>
                                </div>
                            </LightTooltip>
                        </>
                    ) : (
                        <>
                            <Button
                                Icon={() => <HiPencil />}
                                iconReverse
                                style={{
                                    color: "#C0C7DB",
                                    backgroundColor: "#C0C7DB33",
                                    marginRight: 10,
                                }}
                                width={32}
                                height={32}
                                handler={async () => {
                                    setLoading("edit");
                                    lmPublish({
                                        ctaType: "Publish",
                                        ctaUsed: "Edit",
                                    });
                                    await handleFixSave(
                                        FIX_SAVE_TYPE.UNSAVE,
                                        finalSuggestions,
                                        index,
                                    );
                                    setIsFixed(false);
                                    setLoading(null);
                                }}
                                text=""
                                loading={loading == "edit"}
                            ></Button>
                            <Button
                                text=""
                                Icon={() => (
                                    <RiDeleteBin6Line color="#FC5A5A" />
                                )}
                                iconReverse
                                style={{
                                    color: "#FC5A5A",
                                    backgroundColor: "#FC5A5A1A",
                                }}
                                width={32}
                                height={32}
                                handler={async () => {
                                    setLoading("delete");
                                    lmPublish({
                                        ctaType: "Publish",
                                        ctaUsed: "Delete",
                                    });
                                    await handleFixSave(
                                        FIX_SAVE_TYPE.UNSAVE,
                                        suggestion.suggestions,
                                        index,
                                        true,
                                    );
                                    setLoading(null);
                                }}
                                loading={loading == "delete"}
                            ></Button>
                        </>
                    )}
                </div>
            </td>
        </tr>
    );
}

function useChanges() {
    const { selectedDomainInfo } = useContext(InterlinkingContext);
    const [suggestions, setSuggestions] = useState<any>(null);
    const { postData } = useHelperFunctions();
    const handleFixSave = async (
        type = "",
        _fs: Array<Suggestion>,
        index: number,
        isDelete?: Boolean,
    ) => {
        if (!type) {
            return null;
        }
        let tempSIds: Array<number> = [];
        _fs.map((sid: Suggestion) => {
            tempSIds.push(sid.id);
        });

        let payload: any = {
            action: type,
            isAll: 0,
            domain_id: selectedDomainInfo?.id || "-",
        };

        if (type == FIX_SAVE_TYPE.SAVE) {
            payload.page_hash = _fs.map((ps: Suggestion) => ps?.page_hash);
            payload.suggestionIds = [...tempSIds];
            payload.fix = _fs.map((ps: Suggestion) => ({
                keyword: ps.keyword,
                recommendation: ps.recommendation,
                paragraph_hash: ps.paragraph_hash,
            }));
            payload.meta_data = _fs.map((ps) => ({
                page_url: ps.page_url,
                paragraph: ps.paragraph_text.substring(0, 100),
            }));
        } else {
            payload.fix_id = _fs
                .filter((ps) => ps.fix_id >= 0)
                .map((ps) => ps.fix_id);
        }

        const res = await postData({
            completeUrl: BASE_URL + endPoints.updateFix,
            payload,
        });
        if (res.status == 200) {
            if (type == FIX_SAVE_TYPE.SAVE) {
                setSuggestions((ps: any) => {
                    let a = ps;
                    if (ps) {
                        ps[index].suggestions.forEach((ol: Suggestion) => {
                            ol.fix_id = -1;
                        });
                        _fs.map((f: Suggestion, i: number) => {
                            let idx = ps[index].suggestions.findIndex(
                                (ol: Suggestion) => ol.id == f.id,
                            );
                            if (idx >= 0) {
                                a = update(a, {
                                    [index]: {
                                        suggestions: {
                                            [idx]: {
                                                fix_id: {
                                                    $set: res.data.insertIds[i],
                                                },
                                            },
                                        },
                                    },
                                });
                            }
                        });
                        return a;
                    }
                });
            } else {
                if (isDelete)
                    setSuggestions((ps: any) => {
                        ps.splice(index, 1);

                        return [...ps];
                    });
            }
        }
    };

    useEffect(() => {
        (async () => {
            const res = await postData({
                completeUrl: BASE_URL + endPoints.reviewChanges,
                payload: { domain_id: selectedDomainInfo?.id },
            });

            if (res.status == 200) {
                res.data = await generateHash(
                    res.data.filteredOutboundSuggestions,
                );

                setSuggestions(clubAllPagesWise(res.data) as any);
            }
        })();
    }, []);

    return { suggestions, setSuggestions, handleFixSave } as any;
}

function OprTableRowsLoading() {
    return (
        <>
            {new Array(20).fill(1).map(() => (
                <tr>
                    <td>
                        <Skeleton width={150} style={{ marginTop: 30 }} />
                    </td>
                    <td style={{ width: "40%" }}>
                        <Skeleton height={80} />
                    </td>
                    <td>
                        <Skeleton width={120} style={{ marginTop: 30 }} />
                    </td>
                    <td>
                        <Skeleton height={36} width={80} />
                    </td>
                </tr>
            ))}
        </>
    );
}

export default ReviewTable;
