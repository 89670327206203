import React from "react";
import { useSelector } from "react-redux";
import SVGIcon from "../../componentsV2/atoms/SVGIcon";
import styles from "./styles.module.css";
import { badges } from "../../componentsV2/templates/Dashboard/constants";

const DealTop = ({ isCC }) => {
    return (
        <div className={styles.bf_banner__top} style={{ marginTop: 20 }}>
            <div style={{ marginBottom: 30 }} className={styles.headerSection1}>
                Loved by <span className={styles.mark}>100000+</span> users
                across <span className={styles.mark}>50+</span> countries
            </div>
            <div className={styles.headerSection1_child}>
                <div className={styles.headerSection2}>
                    <div className={styles.gradeCards}>
                        {badges.map((badge) => (
                            <img
                                style={{
                                    margin: "0 5px",
                                    zoom: "1.3",
                                }}
                                height={80}
                                width={60}
                                src={badge}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DealTop;
