import React, { useEffect, useState } from "react";
import SVGIcon from "../../atoms/SVGIcon";
import styles from "./AIHumanizerLandingPage.module.css";
import Button from "../../atoms/Button/Button";
import { FaCheck } from "react-icons/fa";
import { UPDATE_PLAN } from "../../../componentsV2/api.json";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import useHelperFunctions from "../../helperFunctions";
import { GoChevronDown } from "react-icons/go";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { paymentPopUpDimension } from "../../../components/subsCards/PriceCardBF";
import usePriceDiscounts, {
    parsePrice,
} from "../../templates/Dashboard/usePriceDiscounts";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../../store/actions/auth";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { ADD_ONS_TYPE, PLANS_CATEGORIES } from "../../../constants";
import { FiArrowUpRight } from "react-icons/fi";
import { MdCheck } from "react-icons/md";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";

const allPlans = [
    {
        type: "free",
        cardHeading: "Free",
        price: 0,
        buttonText: "Get For Free",
        features: [
            "Advanced detection",
            "Accurate humanization",
            "Works with all AI models",
        ],
    },
    {
        type: "premium",
        cardHeading: "Premium",
        price: 4.99,
        buttonText: "Buy Now",
        features: [
            "Advanced detection",
            "Accurate humanization",
            "Works with all AI models",
            "Buy additional credits ",
        ],
    },
    {
        type: "enterprise",
        cardHeading: "Enterprise",
        price: "Custom",
        buttonText: "Talk To Us",
        features: [
            "Advanced detection",
            "Accurate humanization",
            "Works with all AI models",
        ],
    },
];

function getDiscountedPrice(price, discount) {
    return (price - (price * discount) / 100).toFixed(1);
}

function AIHumanizerLandingPage() {
    const { mixpanelTrack } = useMixpanelHook();
    // const [loading, setLoading] = useState(false);
    // const [humanizerPlanCost, setHumanizerPlanCost] = useState(null);
    const { isFreeForever, isLTD, subscriptionInfo, trialUser, isTeamMember } =
        useUserAccessHook();
    const { plans } = usePriceDiscounts();
    const { postData } = useHelperFunctions();
    const dispatch = useDispatch();
    const goTo = useNavigation();
    // const currency = useSelector((state) => state.auth.currency);

    const startHumanizer = async (skipUpdate = false) => {
        // setLoading(true);
        const res = await postData({ url: "/api/user/disable-humanizer" });
        if (res.status === 200) {
            if (!skipUpdate) {
                dispatch(updateUserInfo("show_humanizer_landing", 0));
            }
        }
        // setLoading(false);
    };

    const buyHumanizer = () => {
        if (isFreeForever) {
            goTo("BILLING_PLANS");
            return;
        }
        if (!isLTD) {
            // setLoading(true);
            createHumanizerStripeSession(subscriptionInfo.plan_id);
        }
    };
    // useEffect(() => {
    //     if (plans.length) {
    //         let tempPlan = plans.find(
    //             (vp) =>
    //                 vp?.plan_cost > 0 &&
    //                 vp?.plan_type == plansType &&
    //                 vp.plan_category == PLANS_CATEGORIES.HUMANIZER,
    //         );
    //         setHumanizerPlanCost(tempPlan?.plan_cost || {});
    //     }
    // }, [plans]);

    async function createHumanizerStripeSession(planId) {
        let humanizerPlanId = plans.filter((ele) => ele.plan_id == planId);
        if (!humanizerPlanId.length > 0) {
            humanizerPlanId = plans.filter(
                (ele) =>
                    ele.plan_category == subscriptionInfo.plan_category &&
                    ele.plan_type == subscriptionInfo.type,
            );
        }
        document.StripeInvoicePayment = window.open(
            "",
            "",
            paymentPopUpDimension,
        );
        // @ts-ignore

        document.StripeInvoicePayment.document.write(
            "<html><head></head><body>Please wait while we redirect you</body></html>",
        );
        const res = await postData({
            url: UPDATE_PLAN,
            payload: {
                planID: planId,
                type: 1,
                from: planId,
                quantity: 1,
                add_on_info: [
                    {
                        humanizer_purchased_quantity: 2,
                        humanizer_plan_id: JSON.parse(
                            humanizerPlanId[0]?.add_on_plan_ids,
                        )?.humanizer,
                        prev_humanizer_plan_id:
                            subscriptionInfo?.humanizer_plan_id || null,
                        add_on: ADD_ONS_TYPE.AI_HUMANIZER,
                    },
                ],
            },
        });
        if (res.status == 200) {
            // @ts-ignore
            document?.StripeInvoicePayment?.close();
            // startLM();
            // return setTimeout(() => goTo("LINK_MANAGER", [], "", true), 5000);
        }
        if (res.status == 201) {
            dispatch(updateUserInfo("planDowngraded", false));
            // startLM();

            // @ts-ignore
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            goTo("DUMMY_TRANSACTION");
        }
        // setLoading(false);
    }
    return (
        <>
            <div className={styles.landingHeader}>
                <div className={styles.innerHeader}>
                    <p>AI Content Detector & AI Content Humanizer</p>
                    <LightTooltip
                        arrow
                        title={
                            isTeamMember
                                ? "Please ask your admin to activate this feature."
                                : ""
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 10,
                                color: "#fff",
                                textAlign: "center",
                            }}
                        >
                            <Button
                                text={
                                    trialUser
                                        ? "Try For Free"
                                        : isFreeForever
                                        ? "Buy Plan To Activate"
                                        : "Get For Free"
                                }
                                handler={() => {
                                    if (isFreeForever) {
                                        buyHumanizer();
                                    } else {
                                        startHumanizer();
                                    }
                                }}
                                disabled={isTeamMember}
                                height={45}
                                // loading={loading}
                                Icon={() => <FiArrowUpRight size={20} />}
                                style={{
                                    background: "#fff",
                                    color: "#014dc5",
                                    borderRadius: 25,
                                    fontSize: "16px",
                                    padding: 20,
                                }}
                            />
                        </div>
                    </LightTooltip>
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            marginTop: "10px",
                        }}
                    >
                        {isFreeForever
                            ? "Buy any Scalenut plan to get started"
                            : "Detect 20k words/mo and humanize 5k words/mo for Free"}
                    </div>
                </div>
            </div>
            <div className={styles.middleSection}>
                {featureArray.map((ele) => (
                    <FeatureBox {...ele} />
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h2
                    style={{
                        fontSize: 24,
                        color: "#1B1C1E",
                        fontWeight: 500,
                        margin: 0,
                        marginLeft: 40,
                        width: 970,
                    }}
                >
                    Pricing
                </h2>
                <div className={styles.middleSection}>
                    {allPlans.map((plan) => (
                        <PlanCard
                            buyNow={createHumanizerStripeSession}
                            startHumanizer={startHumanizer}
                            key={plan.type}
                            {...plan}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

const PlanCard = ({
    type,
    cardHeading,
    price,
    originalPrice,
    buttonText,
    features,
    startHumanizer,
    buyNow,
}) => {
    const currency = useSelector((state) => state.auth.currency);
    const {
        isFreeForever,
        trialUser,
        isLTD,
        isTeamMember,
        subscriptionInfo,
        plan_type,
    } = useUserAccessHook();
    const currencySymbol = currency == "INR" ? "₹" : "$";
    const { plans, getDiscount } = usePriceDiscounts();
    const [premiumPrice, setPremiumPrice] = useState("");
    const goTo = useNavigation();

    useEffect(() => {
        if (plans.length) {
            const tempPlan = plans.find(
                (vp) =>
                    vp?.plan_cost > 0 &&
                    vp?.plan_type == 1 &&
                    vp.plan_category == PLANS_CATEGORIES.HUMANIZER,
            );
            setPremiumPrice(tempPlan?.plan_cost || "");
        }
    }, [plans]);

    return (
        <div
            style={
                type == "premium"
                    ? { background: "#1F2FBE", color: "#fff" }
                    : {}
            }
            className={styles.planCardWrapper}
        >
            <h2 className={styles.cardHeading}>{cardHeading}</h2>
            <div
                style={type == "premium" ? { color: "#fff" } : {}}
                className={styles.priceHeading}
            >
                {type == "enterprise" ? "" : currencySymbol}
                {type == "premium"
                    ? getDiscountedPrice(premiumPrice, getDiscount(2))
                    : price}
                {type == "enterprise" ? (
                    ""
                ) : (
                    <span style={{ fontSize: "20px", fontWeight: 500 }}>
                        /mo
                    </span>
                )}
                {type == "premium" && (
                    <div
                        style={{
                            fontSize: 12,
                            fontWeight: 300,
                            color: "rgba(255, 255, 255, 0.8)",
                        }}
                    >
                        Billed annualy or {currencySymbol}
                        {premiumPrice}
                        /mo
                    </div>
                )}
            </div>
            <LightTooltip
                arrow
                title={
                    isTeamMember
                        ? "Please ask your admin to activate this feature."
                        : ""
                }
            >
                <div>
                    <Button
                        handler={() => {
                            if (type === "enterprise") {
                                if (window.userengage) {
                                    window.userengage("widget.show");
                                    window.userengage("widget.open");
                                }
                                return;
                            }

                            if (
                                isFreeForever ||
                                (trialUser && type == "premium")
                            ) {
                                goTo("BILLING_PLANS");
                                return;
                            }

                            if (
                                trialUser ||
                                (type === "free" && !isLTD && !isTeamMember)
                            ) {
                                startHumanizer();
                            } else if (
                                !isLTD &&
                                !isTeamMember &&
                                type === "premium"
                            ) {
                                buyNow(subscriptionInfo.plan_id);
                            }
                        }}
                        disabled={isTeamMember}
                        style={{
                            width: "fit-content",
                            background: type == "premium" ? "#fff" : "#1F2FBE",
                            color: type == "premium" ? "#1F2FBE" : "",
                            paddingInline: "20px",
                            borderRadius: "25px",
                            marginBottom: "15px",
                        }}
                        Icon={() => <FiArrowUpRight size={20} />}
                        height={45}
                        text={
                            isFreeForever && type !== "enterprise"
                                ? "Buy Plan To Activate"
                                : trialUser && type == "premium"
                                ? "Buy Now"
                                : buttonText
                        }
                    />
                </div>
            </LightTooltip>
            <div className={styles.SubText}>
                <MdCheck size={20} />
                <div>
                    <div>AI Detection</div>
                    <div
                        style={type == "premium" ? { color: "#ffffffcc" } : {}}
                        className={styles.smallText}
                    >{`(${
                        type == "free" ? "20k" : "Unlimited"
                    } words/mo)`}</div>
                </div>
            </div>
            <div className={styles.SubText}>
                <MdCheck size={20} />
                <div>
                    <div>AI Humanization</div>
                    <div
                        style={type == "premium" ? { color: "#ffffffcc" } : {}}
                        className={styles.smallText}
                    >{`(${
                        type == "free"
                            ? "5k"
                            : type == "premium"
                            ? "50k"
                            : "Unlimited"
                    } words/mo)`}</div>
                </div>
            </div>
            <div
                style={type == "premium" ? { background: "#FFFFFF33" } : {}}
                className={styles.divider}
            ></div>
            {features.map((ele) => (
                <div
                    style={type == "premium" ? { color: "#ffffffcc" } : {}}
                    className={styles.featureText}
                >
                    <MdCheck size={12} /> {ele}
                </div>
            ))}
        </div>
    );
};

const featureArray = [
    {
        icon: "/New UI/SVG/humanizer/unlimited.svg",
        heading: "Unlimited AI Scans",
        subHeading:
            "With unlimited AI detection, transform your SEO content at scale. ",
    },
    {
        icon: "/New UI/SVG/humanizer/oneClick.svg",
        heading: "One-Click Humanizer",
        subHeading:
            "Infuse empathy with a single click, effortlessly humanizing your content.",
    },
    {
        icon: "/New UI/SVG/humanizer/accuracy.svg",
        heading: "High Accuracy",
        subHeading:
            "The most evolved AI technology that you can trust every single time.",
    },
];

const FeatureBox = ({ icon, heading, subHeading }) => {
    return (
        <div className={styles.featureBoxWrapper}>
            <SVGIcon src={icon} />
            <h2>{heading}</h2>
            <div>{subHeading}</div>
        </div>
    );
};

export default AIHumanizerLandingPage;
