import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { InterlinkingContext } from "./InterLinkingContextProvider";
import ManageDomainsWrapper, {
    DomainChangeCases,
} from "../WebTune/ManageDomainsWrapper";
import { BASE_URL, endPoints } from "./constants";
import { useDomains } from "./Interlinking";
import { INTERLINKING_DOMAIN_STATUS } from "./constants";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import useHelperFunctions from "../../helperFunctions";
import { displayAlert } from "../../../store/actions/alert";
import BuyMore from "../WebTune/BuyMore";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import UpgradeModal from "../WebTune/UpgradeModal";

function Header({ setSitemapModal = () => {} }) {
    const [manageDomain, setManageDomain] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [addingFailText, setAddingFailText] = useState("");
    const [quickList, setQuickList] = useState({});
    const { domainsList, selectedDomainInfo, setState } =
        useContext(InterlinkingContext);
    const { plan_category, isLTD, trialUser } = useUserAccessHook();

    // const { subscriptionInfo } = useSelector(
    //     (state) => state.otherInfo.otherInfo,
    // );
    const [buyPagesModal, setBuyPagesModal] = useState(false);
    const [upgradeModal, setUpgradeModal] = useState(false);

    const { postData, getData } = useHelperFunctions();
    const [logoutLoading, setLogoutLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const logoutReq = useCallback(
        async (domainId, gsc_id = null) => {
            setLogoutLoading(true);
            const res = await postData({
                completeUrl: BASE_URL + endPoints.logout,
                payload: {
                    domain_id: domainId,
                    ...(gsc_id && { gsc_id: gsc_id }),
                },
            });
            if (res.status === 200) {
                try {
                    let temp = [...domainsList];
                    let delIdx = temp.findIndex((del) => del.id == domainId);
                    let delObj = temp[delIdx];
                    console.log(temp[delIdx]);
                    if (temp.length == 0 || temp.length == 1) {
                        setState((ps) => ({
                            ...ps,
                            domainsList: [],
                            selectedDomainInfo: null,
                        }));
                    } else {
                        if (delIdx == 0 && delObj?.is_active == 1) {
                            temp = [
                                {
                                    ...temp[1],
                                    is_active: 1,
                                },
                            ];
                            setState((ps) => ({
                                ...ps,
                                selectedDomainInfo: { ...temp[0] },
                                domainsList: [...temp],
                            }));
                        } else if (delObj?.is_active == 1) {
                            temp.splice(delIdx, 1);
                            temp[0] = {
                                ...temp[0],
                                is_active: 1,
                            };
                            setState((ps) => ({
                                ...ps,
                                selectedDomainInfo: { ...temp[0] },
                                domainsList: [...temp],
                            }));
                        } else {
                            temp.splice(delIdx, 1);
                            setState((ps) => ({
                                ...ps,
                                selectedDomainInfo: { ...temp[0] },
                                domainsList: [...temp],
                            }));
                        }
                    }
                } catch (err) {}
            }
            setLogoutLoading(false);
        },
        [domainsList],
    );
    const addDomainRequest = async (
        domainName = "",
        sitemap = "",
        obj = { isGsc: false, gscId: null },
    ) => {
        let _domainName = domainName;
        if (!_domainName) {
            return;
        }
        setIsAdding(true);
        setIsLoading(true);

        mixpanel.track("Add Domain", {
            Screen: "Internal Linking Login",
            "Multi Domain Allowed": "Yes",
        });
        const res = await postData({
            completeUrl: BASE_URL + endPoints.addDomain,
            payload: {
                domain: _domainName,
                source: null,
                is_gsc: obj.isGsc ? true : false,
                ...(obj.gscId && { gsc_id: obj.gscId || null }),
            },
        });
        if (res.status == 200) {
            setSitemapModal({
                domainName: _domainName,
                is_gsc: obj.isGsc ? true : false,
                ...(obj.gscId && { gsc_id: obj.gscId || null }),
                id: res.data.domain_id,
            });
            setState((ps) => ({
                ...ps,
                selectedDomainInfo: {
                    domain: _domainName,
                    id: res.data.domain_id,
                    gsc_id: obj.gscId || null,
                    interlinking_status: INTERLINKING_DOMAIN_STATUS.NOT_STARTED,
                },
                domainsList: [
                    ...ps.domainsList,
                    {
                        domain: _domainName,
                        status: obj.status,
                        gsc_id: obj.gscId || null,
                        id: res.data.domain_id,
                    },
                ],
            }));
            setIsAdding(false);
        } else {
            if (res.response.data.message.includes("balance is zero")) {
                if (trialUser && !isLTD) {
                    setUpgradeModal({
                        isShow: true,
                    });
                } else if (!isLTD) {
                    setBuyPagesModal({
                        isShow: true,
                        domainId: null,
                    });
                }
            } else if (res.response.data.message.includes("more than one ")) {
                if (trialUser) {
                    setUpgradeModal({
                        isShow: true,
                    });
                }
            }
            setIsAdding(false);
        }
        setIsLoading(false);
    };

    const updateGscConnect = async (
        domainName = "",
        gscId = null,
        logout = false,
        id,
    ) => {
        if (!gscId || !id) {
            return;
        }
        setState((ps) => {
            const _domainList = ps.domainsList.map((domain) => {
                if (domain?.id === id) {
                    return {
                        ...domain,
                        re_auth: 0,
                    };
                }
                return domain;
            });
            const _selectedDomainInfo = {
                ...ps.selectedDomainInfo,
                re_auth: 0,
            };

            return {
                ...ps,
                selectedDomainInfo: _selectedDomainInfo,
                domainsList: _domainList,
            };
        });
        return;
    };

    const getQuickConnectList = async () => {
        setIsLoading(true);
        const res = await getData({ url: endPoints.quickConnectList });
        if (res.status == 200) {
            console.log("Combined List -======", res.data);
            setQuickList(res.data);
        } else {
            setQuickList({});
        }
        setIsLoading(false);
    };
    const handleDomainChanges = async (
        obj = {
            type: "",
            cId: null,
            dName: "",
            status: null,
            setLoader: () => {},
            gscId: null,
            domain,
        },
    ) => {
        switch (obj.type) {
            case DomainChangeCases.addDomain:
                if (obj.source == "gsc") {
                    addDomainRequest(obj.dName, "", {
                        isGsc: true,
                        gscId: obj.gscId,
                    });
                } else {
                    addDomainRequest(obj.dName);
                }
                break;
            case DomainChangeCases.removeDomain:
                obj.setLoader(true);
                await logoutReq(obj.cId, obj?.gscId);
                obj.setLoader(false);
                return;
            case DomainChangeCases.removeGsc:
                await updateGscConnect(obj.dName, obj.gscId, true, obj.id);
                break;
            case DomainChangeCases.addGsc:
                updateGscConnect("", obj.gscId, false, obj.id);
                break;
            case DomainChangeCases.setCurrentDomain:
                setState((ps) => ({ ...ps, selectedDomainInfo: obj.domain }));
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        getQuickConnectList();
    }, []);

    return (
        <div className={styles.header}>
            <div className={styles.headLeft}>
                <h2>Link Manager</h2>
                <p>Your one stop solution for internal linking</p>
            </div>
            <div className={styles.rightOptions}>
                <div
                    style={{
                        // background: "rgba(113, 136, 170, 0.1)",
                        padding: "5px 10px",
                        color: "rgba(113, 136, 170, 1)",
                        width: "max-content",
                        fontSize: 12,
                        fontWeight: 500,
                        marginLeft: "30px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    Last Refreshed{" "}
                    {moment(selectedDomainInfo.last_crawl_date).fromNow()}
                    &nbsp;
                    <LightTooltip arrow title={`Refresh every 14 days`}>
                        <span style={{ height: 14 }}>
                            <AiOutlineInfoCircle size={14} color="inherit" />
                        </span>
                    </LightTooltip>
                </div>

                <ManageDomainsWrapper
                    cDomain={selectedDomainInfo?.domain}
                    list={domainsList}
                    onChange={handleDomainChanges}
                    is_verified={selectedDomainInfo?.is_verified}
                    gsc_id={selectedDomainInfo?.gsc_id}
                    onScreen={"link-manager"}
                    manageDomain={manageDomain}
                    setManageDomain={setManageDomain}
                    domain_id={selectedDomainInfo?.id}
                    loading={isLoading || isAdding}
                    quickList={quickList}
                />
            </div>
            {buyPagesModal?.isShow && (
                <BuyMore
                    setBuyPagesModal={setBuyPagesModal}
                    domainId={buyPagesModal?.domainId}
                    screen={"Link Manager Header"}
                />
            )}
            {upgradeModal?.isShow && (
                <UpgradeModal
                    setModal={setUpgradeModal}
                    forMulti={upgradeModal?.isElite}
                    screen={"Link Manager Header"}
                />
            )}
        </div>
    );
}

export default Header;
