import { useCallback, useEffect, useState, useRef } from "react";
import { getPastAsExpiredTime } from "../../../utility/validateFunctions";
import useHelperFunctions from "../../helperFunctions";
import {
    CLUSTER_REPORT_CREATE,
    CLUSTER_REPORT_GET,
} from "../../../componentsV2/api.json";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { socketEvents } from "../../../Sockets/constants";
import { socket } from "../../../Sockets/useSockets";
import { debounceWrapper, formatURL } from "../../../utility/helperFun";
import { is_completed } from "../../organisms/DocCard/constants";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { clusterMixpanelEvent } from "./clusterMixpanelEvents";
let apiCalls = 0;

const initialLoad = {
    page: 1,
    created: true,
    tags: [],
    allTags: false,
    search: "",
    is_deleted: false,
    order: 0,
    updated: false,
};

function clusterDocsHook({ bin = false }) {
    const [isLoading, setIsLoading] = useState(false);
    const goto = useNavigation();
    const [requestData, setRequestData] = useState(initialLoad);
    const [hasMore, setHasMore] = useState(true);
    const [hasUsedKeywordGap, setHasUsedKeywordGap] = useState(false);

    const [loadingCreation, setLoadingCreation] = useState(false);
    useEffect(() => {}, [hasUsedKeywordGap]);

    const [docs, setDocs] = useState([]);
    const { getData, postData } = useHelperFunctions();
    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        const successCallback = (data) => {
            console.log("event sockets success data ====>", data);

            setDocs((docs) => {
                console.log("EVent soc success", data);
                let updatedDocs = docs.map((doc) => {
                    if (data.topic_id == doc.topic_id) {
                        console.log("socket soc success data", doc);
                        doc.status = 1;
                        doc.is_completed = 1;
                    }
                    setLoadingCreation(false);

                    return doc;
                });

                return updatedDocs;
            });
            console.log("ReportStatus Success end");
        };
        const failedCallback = (data) => {
            console.log("ReportStatus Failed Start");
            setDocs((docs) => {
                let updatedDocs = docs.map((doc) => {
                    if (data?.topic_id == doc?.topic_id) {
                        doc.status = 1;
                        doc.is_completed = 2;
                    }
                    setLoadingCreation(false);

                    return doc;
                });

                return updatedDocs;
            });
            console.log("ReportStatus Failed end");
        };

        const attachSockets = () => {
            socket.on(
                socketEvents.TOPIC_CLUSTER_CREATION_SUCCESS,
                successCallback,
            );
            socket.on(
                socketEvents.TOPIC_CLUSTER_CREATION_FAILED,
                failedCallback,
            );
        };

        const removeSockets = () => {
            socket.off(
                socketEvents.TOPIC_CLUSTER_CREATION_SUCCESS,
                successCallback,
            );
            socket.off(
                socketEvents.TOPIC_CLUSTER_CREATION_FAILED,
                failedCallback,
            );
        };

        attachSockets();

        return () => {
            apiCalls = 0;
            removeSockets();
        };
    }, []);

    const makeReport = async ({
        e,
        cluster_keywords,
        inputValue,
        country,
        setInputValue,
        gscConnectedDomain = "",
        competitorsDomain = [],
    }) => {
        let _competitorsDomain = competitorsDomain
            .map((item) => (item?.length ? formatURL(item) : false))
            .filter((itm) => (itm?.length ? true : false));
        // console.log("competitorsDomain", _competitorsDomain);
        // return;
        if (!inputValue.trim() || !country.label) {
            return;
        }
        setLoadingCreation(true);

        e.preventDefault();
        const theDate = new Date();
        const dummyReport = {
            search_keyword: inputValue,
            is_completed: 0,
            content_updated_at: getPastAsExpiredTime(theDate),
            created_at: getPastAsExpiredTime(theDate),
            search_country: country.label,
            country_code: country.country_code,
            dummyReport: true,
            ...(gscConnectedDomain?.length && {
                domain_name: formatURL(gscConnectedDomain),
            }),
            ...(competitorsDomain?.length && {
                competitor_domain: JSON.stringify(_competitorsDomain),
            }),
        };
        mixpanelTrack(clusterMixpanelEvent.CREATE_REPORT, {
            keyword: inputValue,
            country: country.label,
            country_code: country.country_code,
        });
        const res = await postData({
            url: CLUSTER_REPORT_CREATE,
            payload: {
                keyword: inputValue,
                country: country.label,
                country_code: country.country_code,
                ...(gscConnectedDomain?.length && {
                    domain_name: formatURL(gscConnectedDomain),
                }),
                ...(competitorsDomain?.length && {
                    competitor_domain: _competitorsDomain,
                }),
                ...(cluster_keywords?.length && {
                    cluster_keywords: cluster_keywords,
                    is_excel_sheet: true,
                }),
            },
        });

        if (res.status == 200) {
            setDocs((docs) => [
                {
                    ...dummyReport,
                    is_completed: 0,
                    topic_id: res?.data?.topic_id,
                },
                ...docs,
            ]);
        }
        if (res.status != 200) {
            setLoadingCreation(false);
        }
    };

    const getReports = useCallback(async (requestData) => {
        const { page, created, tags, allTags, search, is_deleted, order } =
            requestData;

        if (page == 1) {
            setIsLoading(true);
        }
        apiCalls += 1;

        const res = await getData({
            url: `${CLUSTER_REPORT_GET}?page=${page}&order=${order}&created_at=${
                created ? 1 : 0
            }&tags=${JSON.stringify(tags)}&search_text=${search}&all_tags=${
                allTags ? 1 : 0
            }&is_deleted=${bin ? 1 : 0}`,
        });
        if (res.status == 200) {
            console.log("resdata", res.data.clusters.length);
            setHasMore(true);
            setHasUsedKeywordGap(res?.data?.competitorDomain);
            if (res.data.clusters.length < 15) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }

            console.log("cluster get", res.data.clusters);
            if (page == 1) {
                setDocs(res.data.clusters);
                if (
                    res.data.clusters[0] &&
                    res.data.clusters[0].is_completed == is_completed.inProgress
                ) {
                    setLoadingCreation(true);
                }
            } else {
                setDocs((ps) => ps.concat(res.data.clusters));
            }
            setIsLoading(false);
        } else {
            setHasMore(false);
        }
        setIsLoading(false);
    }, []);
    let timeoutRef = useRef(null);

    useEffect(() => {
        function req() {
            getReports(requestData);
        }

        debounceWrapper(req, 400, timeoutRef);
    }, [requestData]);

    function openDoc(id) {
        goto("CLUSTER_REPORT", [], id);
    }

    async function handleArchive(id, type) {
        const res = await postData({
            url: "/api/clustering/updateTopicCluster",
            payload: {
                topic_id: id,
                type,
            },
        });

        if (res.status == 200) {
            console.log("dele success");
        }
    }

    return {
        isLoading,
        makeReport,
        docs,
        setIsLoading,
        getReports,
        openDoc,
        setRequestData,
        requestData,
        hasMore,
        apiCalls,
        loadingCreation,
        handleArchive,
        bin,
        hasUsedKeywordGap,
        setHasUsedKeywordGap,
    };
}

export default clusterDocsHook;
