import React, { useState, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import { LuFilePlus } from "react-icons/lu";
import Button from "../../../atoms/Button/Button";
// @ts-ignore
import styles from "./KeywordInput.module.css";

interface UploadFileProps {
    onUploadComplete?: (
        fileName: string,
        uploadedKeywords: FormattedData[],
    ) => void;
}

interface FormattedData {
    [keyword: string]: {
        cpc: number | null;
        search_volume: number | null;
        kd: number | null;
        intent: string[];
    };
}

interface InstructionItemProps {
    text: string;
    linkText?: string;
    linkHref?: string;
    download?: string;
}

export const UploadFile: React.FC<UploadFileProps> = ({ onUploadComplete }) => {
    const [fileData, setFileData] = useState<FormattedData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(
        null,
    );
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [isPreview, setIsPreview] = useState(false);
    const heading = isPreview ? "Preview" : "Upload Excel or CSV File";

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile && isValidFileType(selectedFile.type)) {
            setIsLoading(true);
            setUploadedFileName(selectedFile.name);
            parseFile(selectedFile);
            setError(null);
        } else {
            setError("Please upload a valid CSV or Excel file.");
        }
    };

    const isValidFileType = (type: string) =>
        [
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(type);

    const parseFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target?.result) {
                try {
                    const data = new Uint8Array(e.target.result as ArrayBuffer);
                    const XLSX = (window as any).XLSX;
                    const workbook = XLSX.read(data, { type: "array" });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    const formattedData = formatData(json);

                    if (
                        formattedData.length < 10 ||
                        formattedData.length > 200
                    ) {
                        setError(
                            "Please upload a valid CSV or Excel file with at least 10 rows and at most 200 rows.",
                        );
                    } else {
                        setFileData(formattedData.slice(0, 200));
                        setIsPreview(true);
                        setError(null);
                    }
                } catch (error) {
                    setError("Error parsing file data.");
                } finally {
                    setIsLoading(false);
                }
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const formatData = (data: any[]): FormattedData[] => {
        return data
            .map((entry) => {
                const normalizedEntry = Object.keys(entry).reduce(
                    (acc, key) => {
                        acc[key.toLowerCase().replace(/ /g, "")] = entry[key];
                        return acc;
                    },
                    {} as any,
                );
                return normalizedEntry;
            })
            .filter((item) => item.keyword)
            .map((entry) => {
                const keyword = entry.keyword || "";
                const cpc =
                    entry["cpc"] !== undefined
                        ? parseFloat(entry["cpc"]) || 0
                        : null;
                const searchVolume =
                    entry["searchvolume"] !== undefined
                        ? parseInt(entry["searchvolume"], 10) || 0
                        : null;
                const kd =
                    entry["keyworddifficulty"] !== undefined
                        ? parseInt(entry["keyworddifficulty"], 10) || 0
                        : null;
                const intent = entry.intent
                    ? entry.intent.split(",").map((item: string) => item.trim())
                    : [];

                return {
                    [keyword]: {
                        cpc,
                        search_volume: searchVolume,
                        kd,
                        intent,
                    },
                };
            });
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && isValidFileType(droppedFile.type)) {
            setIsLoading(true);
            setUploadedFileName(droppedFile.name);
            parseFile(droppedFile);
            setError(null);
        } else {
            setError("Please upload a valid CSV or Excel file.");
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleBackClick = () => {
        setIsPreview(false);
        setFileData([]);
    };

    const handleSubmit = () => {
        if (uploadedFileName) {
            onUploadComplete?.(uploadedFileName, fileData);
        }
    };

    return (
        <div className={styles.uploadContainer}>
            <VscClose
                className={styles.close}
                onClick={() => onUploadComplete?.("", [])}
            />

            <h1 className={styles.heading}>{heading}</h1>
            {!isPreview ? (
                <>
                    <div className={styles.instructions}>
                        <InstructionItem
                            text="Please upload a CSV/Excel file containing keywords and other columns, "
                            linkText="Download Sample File."
                            linkHref="https://storage.googleapis.com/scalenut/sample_file.xlsx"
                            download="sample_file.xlsx"
                        />
                        <InstructionItem text="Min Keywords: 10, Max Keywords: 200" />
                    </div>

                    <div
                        className={styles.fileUploadContainer}
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <div className={styles.dropArea}>
                            <p
                                style={{
                                    color: "#666666",
                                    fontSize: "0.875rem",
                                }}
                            >
                                Click or drag and drop your file here to upload
                            </p>
                            <Button
                                text={isLoading ? "Uploading..." : "Upload"}
                                Icon={() => (isLoading ? null : <LuFilePlus />)}
                                disabled={isLoading}
                                iconReverse={true}
                                handler={handleUploadClick}
                                width={120}
                            />
                            <input
                                type="file"
                                accept=".csv, .xls, .xlsx"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{ display: "none" }}
                            />
                            {error && (
                                <div className={styles.error}>{error}</div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <table className={styles.previewTable}>
                        <thead>
                            <tr>
                                <th>Keyword</th>
                                <th>CPC</th>
                                <th>Search Volume</th>
                                <th>KD</th>
                                <th>Intent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileData.slice(0, 5).map((item, index) => {
                                const keyword = Object.keys(item)[0];
                                const data = item[keyword];
                                return (
                                    <tr key={index}>
                                        <td>{keyword || "-"}</td>
                                        <td>
                                            {data.cpc !== null ? data.cpc : "-"}
                                        </td>
                                        <td>
                                            {data.search_volume !== null
                                                ? data.search_volume
                                                : "-"}
                                        </td>
                                        <td>
                                            {data.kd !== null ? data.kd : "-"}
                                        </td>
                                        <td>
                                            {data.intent.length
                                                ? data.intent.join(", ")
                                                : "-"}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {fileData.length > 5 && (
                        <p className={styles.previewTableFooter}>
                            +{fileData.length - 5} more rows
                        </p>
                    )}

                    <div className={styles.previewFooter}>
                        <div className={styles.buttonGroup}>
                            <Button
                                text="Back"
                                handler={handleBackClick}
                                width={100}
                                style={{
                                    background: "#014dc51a",
                                    color: "#014dc5",
                                }}
                            />
                            <Button
                                text="Submit"
                                handler={handleSubmit}
                                width={100}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const InstructionItem: React.FC<InstructionItemProps> = ({
    text,
    linkText,
    linkHref,
    download,
}) => (
    <div className={styles.instructionItem}>
        <span className={styles.tickIcon}>✔</span>
        {linkText ? (
            <span>
                {text}
                <a href={linkHref} download={download} className={styles.link}>
                    {linkText}
                </a>
            </span>
        ) : (
            text
        )}
    </div>
);
