import React, { useState, useEffect, useContext } from "react";
import styles from "./Overview.module.css";
import NewDropDown from "./NewDropDown";
import { SiReddit } from "react-icons/si";
import { FcGoogle, FcServices } from "react-icons/fc";
import { FaQuora } from "react-icons/fa";
import { GiGears } from "react-icons/gi";
import { FiExternalLink } from "react-icons/fi";
import { openLinkWindow } from "../../../../utility/validateFunctions";

import { v4 as uuidv4, v4 } from "uuid";
import SVGIcon from "../../../atoms/SVGIcon";
import TabPanelHeading from "./atoms/TabHeading/TabPanelHeading";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { Outline } from "../../../organisms/Creator/GoNuts/classes";

import { EditorBriefContext } from "../../../organisms/Creator/EditorBrief/EditorBriefContext";
import { BRIEF_TYPES } from "../../../organisms/Creator/EditorBrief/useEditorBriefHook";
import { CRUISE_TYPES } from "../../../organisms/Creator/GoNuts/useCruiseHook";
import { Link } from "@material-ui/core";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const START_WITH = 7;

const platformOrder = {
    Google: 0,
    Quora: 1,
    Reddit: 2,
    Generate: 3,
};

export const questionsPlatformArr = [
    {
        value: "All",
        text: "All Questions",
        icon: <span className={styles.Icon + " " + styles.AllIcon}>All</span>,
    },
    {
        value: "Google",
        text: "Questions frequently asked on Google",
        icon: platformIcon("Google"),
    },
    {
        value: "Serp",
        text: "SERP Questions",
        icon: platformIcon("Serp"),
    },
    {
        value: "Quora",
        text: "Quora",
        icon: platformIcon("Quora"),
    },
    {
        value: "Reddit",
        text: "Reddit",
        icon: platformIcon("Reddit"),
    },
    {
        value: "Generate",
        text: "AI Suggestions",
        icon: platformIcon("Generate"),
    },
];

export function platformIcon(platform, isFixit = false) {
    switch (platform) {
        case "Google":
            return (
                <LightTooltip arrow placement="bottom" title={"Google PAA"}>
                    <span>
                        <FcGoogle
                            className={styles.Icon + " " + styles.googleIcon}
                        />
                    </span>
                </LightTooltip>
            );
        case "Generate":
            return (
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={"Generated with AI"}
                >
                    <span
                        style={{ marginLeft: "3px" }}
                        className={`${styles.Icon} ${
                            isFixit ? styles.GenIconFixIt : styles.GenIcon
                        }`}
                    >
                        AI
                    </span>
                </LightTooltip>
            );
        case "Serp":
            return (
                <SVGIcon
                    tooltip={"SERP Question"}
                    size={24}
                    src="/New UI/SVG/QuestionSources/serp.svg"
                    style={{
                        color: isFixit ? "#fff" : "#014dc5",
                    }}
                    // className={styles.Icon}
                />
            );
        case "Reddit":
            return (
                <LightTooltip arrow placement="bottom" title={"Reddit"}>
                    <span>
                        <SiReddit
                            size={16}
                            className={styles.Icon + " " + styles.RedditIcon}
                        />
                    </span>
                </LightTooltip>
            );
        default:
            return (
                <LightTooltip arrow placement="bottom" title={"Quora"}>
                    <div style={{ display: "flex" }}>
                        <SVGIcon
                            // tooltip={"Quora"}
                            size={18}
                            src="/New UI/SVG/quora-svgrepo-com.svg"
                            className={styles.Icon + " " + styles.QuoraIcon}
                        />
                    </div>
                </LightTooltip>
            );
    }
}

export default function PeopleQuestionsOverview({
    list = [],
    setQuestionModal,
    startWith,
    contentBrief,
    isSharedReport,
    access,
    auditMode,
}) {
    const [data, setData] = useState(list);
    const [sortType, setSortType] = useState("All");
    useEffect(() => {
        let sorted = list
            .sort(
                (x, y) => platformOrder[x.platform] - platformOrder[y.platform],
            )
            .filter((item) => {
                if (sortType == "All") {
                    return true;
                }

                return item.platform == sortType;
            });
        if (sortType == "All") {
            let serpArr = sorted.filter(
                (item) => item.platform == questionsPlatformArr[2].value,
            );
            let googleArr = sorted.filter(
                (item) => item.platform == questionsPlatformArr[1].value,
            );
            sorted = sorted.filter(
                (item) => item.platform != questionsPlatformArr[1].value,
            );
            sorted = sorted.filter(
                (item) => item.platform != questionsPlatformArr[2].value,
            );
            sorted = [...googleArr, ...serpArr, ...sorted];
        }

        setData(sorted);
    }, [sortType]);

    const [recordsPerPage, setRecordsPerPage] = useState(
        startWith ? startWith : START_WITH,
    );
    const [numberOfPages, setNumberOfPages] = useState(
        Math.ceil(data.length / START_WITH),
    );

    const { dispatchEditorBrief, setSuccessMsg } =
        useContext(EditorBriefContext);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setCurrentPage(1);
    }, [sortType]);

    useEffect(() => {}, [sortType]);

    const nextPage = () => {
        if (currentPage === numberOfPages || numberOfPages === 0) return;

        setCurrentPage((currPage) => currPage + 1);
    };

    const previousPage = () => {
        if (currentPage === 1) return;
        setCurrentPage((currPage) => currPage - 1);
    };
    return (
        <>
            <TabPanelHeading
                heading={
                    <div style={{ display: "flex" }}>
                        Social Listening: Top Questions across channels
                    </div>
                }
                children={
                    <NewDropDown
                        listIcon={questionsPlatformArr}
                        sortType={sortType}
                        setSortType={setSortType}
                        title="CHANNEL:"
                    />
                }
            />
            <div
                style={{ boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.05)" }}
                className={styles.peepContainer}
            >
                {data.length > 0 ? (
                    <div className={styles.peepQuesContainer}>
                        {data
                            // .slice(
                            //   (currentPage - 1) * recordsPerPage,
                            //   (currentPage - 1) * recordsPerPage + recordsPerPage
                            // )
                            .map((q, i) => (
                                <div
                                    className={styles.peepQues}
                                    key={q.question + i}
                                >
                                    <div className={styles.peepQuesLeft}>
                                        {platformIcon(q.platform)}
                                        <p className={styles.question}>
                                            {q.question}
                                        </p>
                                        {!!q.url ? (
                                            <a
                                                href={q.url}
                                                target="_blank"
                                                className={styles.source}
                                            >
                                                <FiExternalLink size="0.8rem" />
                                            </a>
                                        ) : (
                                            <span
                                                style={{ width: "0.8rem" }}
                                            ></span>
                                        )}
                                    </div>

                                    {!!q.url && !auditMode && (
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatchEditorBrief({
                                                    type: BRIEF_TYPES.ADD_REFERENCE,
                                                    data: {
                                                        id: uuidv4(),
                                                        data: q.url,
                                                        subData: "",
                                                    },
                                                });
                                                setSuccessMsg({
                                                    show: true,
                                                    msg: q.url,
                                                });
                                            }}
                                            className={styles.mainDataUrl}
                                        >
                                            {"Insert as a Reference ->"}
                                        </a>
                                    )}
                                    {!auditMode && (
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                    contentBrief &&
                                                    (!isSharedReport ||
                                                        (isSharedReport &&
                                                            access))
                                                ) {
                                                    dispatchEditorBrief({
                                                        type: CRUISE_TYPES.ADD_OUTLINES,
                                                        field: Outline.type
                                                            .question,

                                                        data: new Outline({
                                                            tag: Outline.tags
                                                                .question,
                                                            data: q.question,
                                                            id: v4(),
                                                        }),
                                                    });
                                                    setSuccessMsg({
                                                        show: true,
                                                        msg: q.question,
                                                    });
                                                }
                                            }}
                                            className={styles.mainDataUrl}
                                        >
                                            {"Insert as a FAQ ->"}
                                        </a>
                                    )}
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className={styles.peepQuesContainer1}>
                        No Question Found
                    </div>
                )}
            </div>
        </>
    );
}
