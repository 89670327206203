import React, { useContext, useEffect, useRef, useState } from "react";
import { OnBoardingContext } from "../Context/OnBoardingContext/OnBoardingContextProvider";
import styles from "./styles.module.css";
let _highlight = null;
function HighlightComponent({ children, highlight = false, style = {} }) {
    const ref = useRef();

    const [pos, setPos] = useState({ top: 0, left: 0, height: 0, width: 0 });
    const onBoarding = useContext(OnBoardingContext);

    useEffect(() => {
        const removeHighLight = () => {
            if (highlight)
                onBoarding.setState((ps) => {
                    return {
                        ...ps,
                        focusOn: null,
                    };
                });
        };

        document.addEventListener("click", removeHighLight);
        return () => document.removeEventListener("click", removeHighLight);
    }, [highlight]);

    _highlight = highlight;

    if (!highlight) return children;

    return (
        <>
            <div
                // style={
                //   pos.height
                //     ? {
                //         top: pos.top + "px",
                //         left: pos.left + "px",
                //         width: pos.width + "px",
                //         height: pos.height + "px",
                //         position: "fixed",
                //       }
                //     : {}
                // }
                ref={ref}
                className={styles.focus1}
                onClick={() =>
                    onBoarding.setState((ps) => {
                        return {
                            ...ps,
                            focusOn: null,
                        };
                    })
                }
                style={style}
            >
                {children}
            </div>
            <div className={styles.focus}></div>
        </>
    );
}

export default HighlightComponent;
