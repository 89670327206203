import React from "react";
import { GrUpgrade } from "react-icons/gr";
import Button from "../../atoms/Button/Button";
import styles from "./styles.module.css";

function AppUpdateRequired() {
    return (
        <div class={styles.container}>
            <img src="/New UI/SVG/Update-graphic.svg" alt="" />
            <h3 style={{ fontWeight: 500, marginBottom: 5 }}>
                Your Application Version is Out of Date
            </h3>
            <small style={{ color: "gray" }}>
                Please close any other scalenut tabs that are currently open.
            </small>

            <Button
                Icon={() => <img height={25} src="/New UI/Images/update.png" />}
                text="Update"
                iconReverse={true}
                height={40}
                width={120}
                style={{
                    background: "var(--primary-blue)",
                    color: "white",
                    marginTop: 20,
                }}
                handler={() => {
                    window.location = "";
                }}
            ></Button>
        </div>
    );
}

export default AppUpdateRequired;
