import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import useGscHook, {
    ColumnsNameObject,
    CountryObject,
    DomainObject,
    InventoryV2Object,
} from "../useGscHook";
import InventoryTopOptions from "./InventoryTopOptions";
import { useSelector } from "react-redux";
import { PLANS_CATEGORIES } from "../../../../constants";
import styles from "../TrafficAnalyzer.module.css";
import { CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import SVGIcon from "../../../atoms/SVGIcon";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { Skeleton } from "@material-ui/lab";
import RenderInChucks from "../../../atoms/RenderInChucks";
import AnalyzerSubCards from "./AnalyzerSubCards";
import LineChart from "../../../atoms/Chart/LineChart";
import InventoryBottomOptions from "./InventoryBottomOptions";
import useUserAccessHook from "../../../../components/account/useUserAccessHook";

type Props_ItemRow = {
    item: InventoryV2Object;
    index: number;
    filterType: string;
    columnsName: ColumnsNameObject[];
    scroll: boolean;
    createOptimizerReport: Props_InventoryV2["createOptimizerReport"];
    isDummy?: boolean;
    search: string;
    setExpandIdx: Dispatch<SetStateAction<number>>;
    expandIdx: number;
};

type Props_InventoryV2 = {
    isLoading: boolean;
    recommendationDataV2: InventoryV2Object[];
    nonProcessedPages?: string[];
    isShimmer: boolean;
    totalPage: number;
    createOptimizerReport: (page: string, keyword: string) => void;
    activeCountry: CountryObject | undefined;
    isProcessingV2: boolean;
    activeDomain: DomainObject | undefined;
    isOpenDefault: boolean;
    setIsOpenDefault: Dispatch<SetStateAction<boolean>>;
};

export const LightTooltipGraph = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #F1F1F1",
        },
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: "white",
        color: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        fontSize: 11,
        position: "relative",
        maxWidth: "700px",
        border: "1px solid #F1F1F1",
        borderRadius: "4px",
        padding: "10px",
        paddingBottom: "0px",
    },
}))(Tooltip);

const SortByOptions: { title: string; id: number }[] = [
    {
        title: "page url",
        id: 1,
    },
    {
        title: "keyword count",
        id: 2,
    },
    {
        title: "traffic",
        id: 3,
    },
    {
        title: "traffic share",
        id: 4,
    },
    {
        title: "impression",
        id: 5,
    },
    {
        title: "rank",
        id: 6,
    },
    {
        title: "average rank",
        id: 7,
    },
    {
        title: "rank change",
        id: 8,
    },
    {
        title: "total volume",
        id: 9,
    },
    {
        title: "avg kd",
        id: 10,
    },
];

const FilterOptions: string[] = [
    "All Pages",
    "Gainers",
    "Losers ",
    "New Pages",
];

const RankFilterOptions: string[] = [
    "All",
    "#1-3",
    "#4-10",
    "#11-20",
    "#21-50",
    "#51-100",
];

const DefaultRow = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
];

const ItemRowShimmer = () => {
    return (
        <div style={{ width: "100%" }}>
            {DefaultRow.map((i) => (
                <div className={styles.rowShimmer}>
                    <Skeleton width={"99%"} height={"40px"} key={i + 1} />
                </div>
            ))}
        </div>
    );
};

const ItemRow = ({
    item,
    index = -1,
    filterType,
    columnsName,
    scroll = false,
    createOptimizerReport,
    isDummy = false,
    search = "",
    expandIdx,
    setExpandIdx,
}: Props_ItemRow) => {
    const [removeClass, setRemoveClass] = useState<string>();
    const [copied, setCopied] = useState(false);
    const rowRef = useRef<HTMLDivElement>(null);

    const {
        getMappedStringTA,
        getValueWithUnit,
        getLineColor,
        trendsSD,
        getGradientValues,
        lastNonZeroValue,
        firstNonZeroValue,
        getKDColorTooltip,
        removeDomainFromURL,
    } = useGscHook();
    const handleCopy: (page: string) => void = (page) => {
        setCopied(true);
        window.navigator.clipboard.writeText(page);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    const handleExpand: (idx: number) => void = (idx) => {
        setExpandIdx((ps) => (ps == idx ? -1 : idx));
        if (expandIdx != idx && rowRef?.current) {
            rowRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
            });
        }
    };

    return (
        <>
            <div
                ref={rowRef}
                className={`${isDummy && styles.LockedRow}
                    ${styles.itemRow}  ${
                    expandIdx == index && styles.expandedItemRow
                } ${scroll && styles.scrollTrue}`}
                onClick={(e) => {
                    if (!isDummy) {
                        handleExpand(index);
                    }
                }}
            >
                {columnsName[0].isChecked && (
                    <div className={styles.itemRow_title}>
                        <div className={styles.data}>
                            <LightTooltip
                                title={item.page}
                                placement={"bottom"}
                                arrow
                                // PopperProps={{ disablePortal: true }}
                                onOpen={(e: any) => {
                                    if (
                                        e.target.scrollWidth <=
                                        e.target.offsetWidth
                                    ) {
                                        setImmediate(() => {
                                            document
                                                .querySelectorAll(
                                                    "[role=tooltip]",
                                                )
                                                .forEach(
                                                    (e: any) =>
                                                        (e.style.display =
                                                            "none"),
                                                );
                                        });
                                    }
                                }}
                            >
                                <span
                                    className={styles.pageUrl}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(item.page, "_blank");
                                    }}
                                >
                                    {removeDomainFromURL(item.page)}
                                </span>
                            </LightTooltip>
                            <LightTooltip
                                title={copied ? "Copied!" : "Click to Copy"}
                                placement={"bottom"}
                                arrow
                            >
                                <span
                                    className={styles.externalLink}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        handleCopy(item.page);
                                    }}
                                >
                                    <SVGIcon
                                        src={"/New UI/SVG/copy.svg"}
                                        size={"14"}
                                    />
                                </span>
                            </LightTooltip>
                        </div>
                    </div>
                )}
                {columnsName[1].isChecked && (
                    <div
                        className={`${styles.itemRow_keyword} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        <div className={styles.queryBox}>
                            {item.total_keywords_count}
                            <div
                                className={`${styles.row_change} ${
                                    item?.delta_keywords_count == 0 &&
                                    styles.nullBox
                                } ${
                                    item.delta_keywords_count < 0
                                        ? styles.lose
                                        : styles.gain
                                }`}
                            >
                                <div className={`${styles._value} `}>
                                    <SVGIcon
                                        src="/New UI/SVG/arrowUp.svg"
                                        size={9}
                                        style={
                                            item.delta_keywords_count < 0
                                                ? {
                                                      transform:
                                                          "rotate(90deg)",
                                                      zIndex: 1,
                                                  }
                                                : {}
                                        }
                                    />
                                    {getValueWithUnit(
                                        Math.abs(item.delta_keywords_count),
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {columnsName[2].isChecked && (
                    <div
                        className={`${styles.itemRow_traffic} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        <div
                            className={`${styles._data} ${
                                item?.delta_traffic == 0 && styles.nullBox
                            }`}
                        >
                            {getValueWithUnit(Math.abs(item.total_traffic))}
                            <div
                                className={`${styles.row_change} ${
                                    item.delta_traffic < 0
                                        ? styles.lose
                                        : styles.gain
                                }`}
                            >
                                <div className={styles._value}>
                                    <SVGIcon
                                        src="/New UI/SVG/arrowUp.svg"
                                        size={9}
                                        style={
                                            item.delta_traffic < 0
                                                ? {
                                                      transform:
                                                          "rotate(90deg)",
                                                      zIndex: 1,
                                                  }
                                                : {}
                                        }
                                    />
                                    {getValueWithUnit(
                                        Math.abs(item.delta_traffic),
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {columnsName[3].isChecked && (
                    <div
                        className={`${styles.itemRow_trafficShare} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        {Math.round(item.traffic_share * 10) / 10 + "%"}
                    </div>
                )}
                {columnsName[4].isChecked && (
                    <div
                        className={`${styles.itemRow_Impression} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        <div
                            className={`${styles._data} ${
                                item?.delta_traffic == 0 && styles.nullBox
                            }`}
                        >
                            {getValueWithUnit(item.total_impressions)}
                            <div
                                className={`${styles.row_change} ${
                                    item?.delta_impressions == 0 &&
                                    styles.nullBox
                                } ${
                                    item.delta_impressions < 0
                                        ? styles.lose
                                        : styles.gain
                                }`}
                            >
                                <div className={`${styles._value} `}>
                                    <SVGIcon
                                        src="/New UI/SVG/arrowUp.svg"
                                        size={9}
                                        style={
                                            item.delta_impressions < 0
                                                ? {
                                                      transform:
                                                          "rotate(90deg)",
                                                      zIndex: 1,
                                                  }
                                                : {}
                                        }
                                    />
                                    {getValueWithUnit(
                                        Math.abs(item.delta_impressions),
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {columnsName[5].isChecked && (
                    <div
                        className={`${styles.itemRow_avgRank} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        <div className={styles.dataBox}>
                            {Math.round(item.avg_position)}
                        </div>
                    </div>
                )}
                {columnsName[6].isChecked && (
                    <div className={styles.itemRow_rank}>
                        <LightTooltipGraph
                            interactive
                            title={
                                isDummy ? (
                                    ""
                                ) : (
                                    <div
                                        className={styles.tooltipGraphWrapper}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className={styles.tooltipGraph}>
                                            <div
                                                className={
                                                    styles.tooltipGraph_title
                                                }
                                            >
                                                Rank Trend
                                            </div>
                                            <div
                                                className={
                                                    styles.tooltipGraph_value
                                                }
                                                style={{
                                                    background: getLineColor(
                                                        +item.color,
                                                    ),
                                                }}
                                            >
                                                {firstNonZeroValue(
                                                    item.avg_position_trend,
                                                )}
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/arrowRight.svg"
                                                    }
                                                />{" "}
                                                {lastNonZeroValue(
                                                    item.avg_position_trend,
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.graphWrapper}>
                                            <div
                                                className={
                                                    styles.graphLegend_left
                                                }
                                            >
                                                Rank
                                            </div>
                                            <div
                                                className={
                                                    styles.graphWrapperSub
                                                }
                                            >
                                                <LineChart
                                                    isReverseY={true}
                                                    data1={
                                                        item.avg_position_trend
                                                    }
                                                    data2={item.dashedPosition}
                                                    label={item.trendsDateLabel}
                                                    min={0}
                                                    max={trendsSD(
                                                        item.avg_position_trend,
                                                    )}
                                                    noTick={true}
                                                    customTooltip={true}
                                                    showAllLabels={true}
                                                    tooltipLabel={"Rank #"}
                                                    lineHeight={"15px"}
                                                    dashedBorder={[6, 6]}
                                                    pointRadius={2}
                                                    lineColor={getLineColor(
                                                        +item.color,
                                                    )}
                                                    lineThickness={2}
                                                    gradientRange={200}
                                                    gradientValues={getGradientValues(
                                                        +item.color,
                                                    )}
                                                    gradientRange2={200}
                                                    gradientValues2={getGradientValues(
                                                        +item.color,
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles.graphLegend_bottom
                                            }
                                        >
                                            Date
                                        </div>
                                    </div>
                                )
                            }
                            placement={"bottom"}
                            arrow
                        >
                            <div
                                className={`${styles.row_rankTrendGraph} ${
                                    isDummy && styles.blurData
                                }`}
                            >
                                <LineChart
                                    isReverseY={true}
                                    data1={item.avg_position_trend}
                                    data2={item.dashedPosition}
                                    label={item.trendsDateLabel}
                                    margin-left={"5px"}
                                    dashedBorder={[4, 5]}
                                    min={0}
                                    max={trendsSD(item.avg_position_trend)}
                                    noTick={true}
                                    noLabels={true}
                                    pointRadius={0}
                                    noGrid={true}
                                    noTooltip={true}
                                    customTooltip={false}
                                    tooltipLabel={""}
                                    lineColor={getLineColor(+item.color)}
                                    lineThickness={2}
                                    gradientRange={40}
                                    gradientValues={getGradientValues(
                                        +item.color,
                                    )}
                                    gradientRange2={40}
                                    gradientValues2={getGradientValues(
                                        +item.color,
                                    )}
                                />
                            </div>
                        </LightTooltipGraph>
                    </div>
                )}
                {columnsName[7].isChecked && (
                    <div
                        className={`${styles.itemRow_deltaRank} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        <div
                            style={{
                                padding: "5px",
                                borderRadius: "3px",
                                minWidth: "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            className={`${styles._value} ${
                                Number(
                                    firstNonZeroValue(item.avg_position_trend),
                                ) -
                                    Number(
                                        lastNonZeroValue(
                                            item.avg_position_trend,
                                        ),
                                    ) <
                                0
                                    ? styles.lose
                                    : Number(
                                          firstNonZeroValue(
                                              item.avg_position_trend,
                                          ),
                                      ) -
                                          Number(
                                              lastNonZeroValue(
                                                  item.avg_position_trend,
                                              ),
                                          ) >
                                      0
                                    ? styles.gain
                                    : styles.neutral
                            }`}
                        >
                            {Number(
                                firstNonZeroValue(item.avg_position_trend),
                            ) -
                                Number(
                                    lastNonZeroValue(item.avg_position_trend),
                                ) !=
                                0 && (
                                <SVGIcon
                                    src="/New UI/SVG/arrowUp.svg"
                                    size={12}
                                    style={
                                        Number(
                                            firstNonZeroValue(
                                                item.avg_position_trend,
                                            ),
                                        ) -
                                            Number(
                                                lastNonZeroValue(
                                                    item.avg_position_trend,
                                                ),
                                            ) <
                                        0
                                            ? {
                                                  transform: "rotate(90deg)",
                                                  zIndex: 1,
                                              }
                                            : {}
                                    }
                                />
                            )}
                            {Math.abs(
                                Number(
                                    firstNonZeroValue(item.avg_position_trend),
                                ) -
                                    Number(
                                        lastNonZeroValue(
                                            item.avg_position_trend,
                                        ),
                                    ),
                            )}
                        </div>
                    </div>
                )}
                {columnsName[8].isChecked && (
                    <div
                        className={`${styles.itemRow_totalVolume} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        {getValueWithUnit(item.volume)}
                    </div>
                )}
                {columnsName[9].isChecked && (
                    <div
                        className={`${styles.itemRow_avgKD} ${
                            isDummy && styles.blurData
                        }`}
                    >
                        {item.avg_difficulty == null ? (
                            "-"
                        ) : (
                            <div className={`${styles.row_font_kd} `}>
                                <LightTooltip
                                    arrow
                                    title={
                                        getKDColorTooltip(item.avg_difficulty)
                                            .tooltip
                                    }
                                    placement={"left"}
                                >
                                    <div
                                        style={{
                                            width: "6px",
                                            height: "6px",
                                            backgroundColor: getKDColorTooltip(
                                                item.avg_difficulty,
                                            ).color,
                                            borderRadius: "50%",
                                        }}
                                    ></div>
                                </LightTooltip>{" "}
                                {Math.round(item.avg_difficulty)}
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.itemRow_actions}>
                    {!isDummy && (
                        <LightTooltip
                            title={expandIdx == index ? "Collapse" : "Expand"}
                            placement={"bottom"}
                            arrow
                        >
                            <div
                                className={styles.cta_expand}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleExpand(index);
                                }}
                            >
                                <SVGIcon
                                    src={`/New UI/SVG/arrow-head-${
                                        expandIdx == index ? "up" : "down"
                                    }.svg`}
                                    size={15}
                                    style={{ color: "inherit" }}
                                />
                            </div>
                        </LightTooltip>
                    )}
                </div>
            </div>

            {expandIdx == index && (
                <div className={`${styles.expandedSuggestions} ${removeClass}`}>
                    <AnalyzerSubCards
                        item={item}
                        parentId={item.page}
                        createOptimizerReport={createOptimizerReport}
                    />
                </div>
            )}
        </>
    );
};

const InventoryV2 = ({
    isLoading,
    recommendationDataV2,
    nonProcessedPages = [],
    createOptimizerReport,
    isShimmer,
    activeCountry,
    isProcessingV2 = false,
    totalPage = 0,
    activeDomain,
    isOpenDefault,
    setIsOpenDefault,
}: Props_InventoryV2) => {
    const [expandIdx, setExpandIdx] = useState<number>(-1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterType, setFilterType] = useState<string>(FilterOptions[0]);
    const [rankFilterType, setRankFilterType] = useState<string>(
        RankFilterOptions[0],
    );
    const { isLTD } = useUserAccessHook();
    const [isNonProcessed, setIsNonProcessed] = useState(false);
    const [isShowNextRefresh, setIsShowNextRefresh] = useState<boolean>(false);
    const [order, setOrder] = useState<0 | 1>(1); //0 = asec, 1= desc
    const plan_category = useSelector(
        (state: any) => state.otherInfo.subscriptionInfo,
    );
    const [isUserPro, setIsUserPro] = useState(false);

    useEffect(() => {
        if (!isOpenDefault) {
            // setExpandIdx(0);
            setIsOpenDefault(true);
        }
    }, [isOpenDefault]);

    useEffect(() => {
        if (plan_category?.plan_category) {
            if (
                [
                    PLANS_CATEGORIES.TRAIL,
                    PLANS_CATEGORIES.INDIVIDUAL,
                    PLANS_CATEGORIES.GROWTH,
                    PLANS_CATEGORIES.GROWTH_MAX,
                    PLANS_CATEGORIES.FREE_FOREVER,
                ].includes(plan_category.plan_category)
            ) {
                setIsUserPro(false);
            } else {
                setIsUserPro(true);
            }
        }
    }, [plan_category]);
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState<{
        id: number;
        title: string | object;
    }>(SortByOptions[2]);
    const [columnsName, setColumnsName] = useState<ColumnsNameObject[]>([
        { name: "Page URL", isChecked: true, isDisabled: true },
        { name: "Keyword Count", isChecked: true, isDisabled: false },
        { name: "Traffic", isChecked: true, isDisabled: false },
        { name: "Traffic Share", isChecked: true, isDisabled: false },
        { name: "Impressions", isChecked: true, isDisabled: false },
        { name: "Current Rank", isChecked: true, isDisabled: false },
        { name: "Rank Trend", isChecked: true, isDisabled: false },
        { name: "Rank Change", isChecked: true, isDisabled: false },
        { name: "Total Volume", isChecked: true, isDisabled: false },
        { name: "Avg. KD", isChecked: true, isDisabled: false },
        {
            name: "",
            isChecked: true,
            isDisabled: false,
        },
    ]);
    const overlayUpgradeRef = useRef<HTMLDivElement>(null);
    const ctaWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (overlayUpgradeRef.current && ctaWrapperRef.current) {
            const allDummyRow = document.getElementsByClassName(
                styles.LockedRow,
            );
            let topDummyRow = null;
            if (allDummyRow?.length > 0) {
                topDummyRow = allDummyRow[0];
                // // @ts-ignore
                // overlayUpgradeRef.current.style.top =
                //     // @ts-ignore
                //     topDummyRow?.offsetTop + 8 + "px";
            } else {
                return;
            }
        }
    }, [overlayUpgradeRef, search, ctaWrapperRef]);

    const { inventory_pages_limit } = useSelector(
        (state: any) => state.otherInfo?.subscriptionInfo,
    );

    useEffect(() => {
        setCurrentPage(0);
    }, [filterType, sortBy?.id, order]);

    const divRef = useRef<any>(null);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

    useEffect(() => {
        let handleResize: any, handleMutation: any, observer: any;
        // Initial check
        if (divRef.current) {
            handleResize = () => {
                const divElement = divRef?.current;

                if (divElement) {
                    setIsOverflowing(
                        divElement?.scrollWidth - 10 > divElement?.clientWidth,
                    );
                }
            };

            handleMutation = () => {
                setIsOverflowing(
                    divRef?.current?.scrollWidth - 10 >
                        divRef?.current?.clientWidth,
                );
            };

            observer = new MutationObserver(handleMutation);
            observer?.observe(divRef.current, {
                childList: true,
                subtree: true,
            });
            window.addEventListener("resize", handleResize);
            handleResize();
        }
        return () => {
            observer?.disconnect();
            window.removeEventListener("resize", handleResize);
        };
    }, [divRef.current]);

    const sortData: (a: InventoryV2Object, b: InventoryV2Object) => number = (
        a,
        b,
    ) => {
        if (order === 0) {
            // Swap a and b when sorting in ascending order
            const temp = b;
            b = a;
            a = temp;
        }

        const lastNonZeroValue = (arr: number[]): string => {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (arr[i] !== 0 && arr[i] !== null) {
                    return Math.round(arr[i]).toString();
                }
            }
            return "-";
        };

        const firstNonZeroValue = (arr: any) => {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] !== 0 && arr[i] !== null) {
                    return Math.round(arr[i]).toString();
                }
            }
            return "-";
        };

        switch (sortBy.id) {
            case 1:
                return a.page?.localeCompare(b.page);
            case 2:
                return +b.total_keywords_count - +a.total_keywords_count;
            case 3:
                return +b.total_traffic - +a.total_traffic;
            case 4:
                return +b.traffic_share - +a.traffic_share;
            case 5:
                return +b.total_impressions - +a.total_impressions;
            case 7:
                return +b.avg_position - +a.avg_position;
            case 6:
                const classificationOrder = [11, 12, 13];
                const classificationA = classificationOrder.indexOf(+a.color);
                const classificationB = classificationOrder.indexOf(+b.color);

                if (classificationA === classificationB) {
                    // Same classification, sort by trendsStatus
                    if (a.trendsStatus === b.trendsStatus) {
                        return 0; // Same trendsStatus, maintain original order
                    } else {
                        if (classificationA == 0 && classificationB == 0) {
                            return (
                                Math.abs(b.trendsStatus) -
                                Math.abs(a.trendsStatus)
                            );
                        } else {
                            return (
                                Math.abs(a.trendsStatus) -
                                Math.abs(b.trendsStatus)
                            );
                        }
                    }
                } else {
                    // Different classification, sort by classification order
                    return classificationA - classificationB;
                }
            case 8:
                return (
                    Number(firstNonZeroValue(b.avg_position_trend)) -
                    Number(lastNonZeroValue(b.avg_position_trend)) -
                    (Number(firstNonZeroValue(a.avg_position_trend)) -
                        Number(lastNonZeroValue(a.avg_position_trend)))
                );
            case 9:
                return +b.volume - +a.volume;
            case 10:
                return +b.avg_difficulty - +a.avg_difficulty;
            default:
                // By default, sort by impressions in descending order
                return +b.total_impressions - +a.total_impressions;
        }
    };

    const filterData: (item: InventoryV2Object) => boolean = (item) => {
        if (filterType == FilterOptions[0]) {
            return true;
        } else if (filterType == FilterOptions[1] && item.page_filter == 2) {
            return true;
        } else if (filterType == FilterOptions[2] && item.page_filter == 3) {
            return true;
        } else if (filterType == FilterOptions[3] && item.page_filter == 1) {
            return true;
        } else {
            return false;
        }
    };

    const filterDataByRankGroup: (item: InventoryV2Object) => boolean = (
        item,
    ) => {
        if (rankFilterType == RankFilterOptions[0]) {
            return true;
        } else if (
            rankFilterType == RankFilterOptions[1] &&
            Math.round(item.avg_position) >= 1 &&
            Math.round(item.avg_position) <= 3
        ) {
            return true;
        } else if (
            rankFilterType == RankFilterOptions[2] &&
            Math.round(item.avg_position) >= 4 &&
            Math.round(item.avg_position) <= 10
        ) {
            return true;
        } else if (
            rankFilterType == RankFilterOptions[3] &&
            Math.round(item.avg_position) >= 11 &&
            Math.round(item.avg_position) <= 20
        ) {
            return true;
        } else if (
            rankFilterType == RankFilterOptions[4] &&
            Math.round(item.avg_position) >= 21 &&
            Math.round(item.avg_position) <= 50
        ) {
            return true;
        } else if (
            rankFilterType == RankFilterOptions[5] &&
            Math.round(item.avg_position) >= 51 &&
            Math.round(item.avg_position) <= 100
        ) {
            return true;
        } else {
            return false;
        }
    };

    const filterBySearch: (item: InventoryV2Object) => boolean = (item) => {
        if (
            search.length &&
            item.page.toLowerCase().includes(search.toLowerCase())
        ) {
            return true;
        } else if (!search.length) {
            return true;
        } else {
            return false;
        }
    };

    const filterSubData = (item: any) => {
        if (search.length) {
            if (item?.page?.toLowerCase()?.includes(search.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const filterSubDataDummy = (item: any) => {
        if (isLTD) {
            return false;
        }
        if (search.length && isNonProcessed) {
            return true;
        } else {
            return false;
        }
    };

    const data = [
        ...recommendationDataV2
            .filter(filterDataByRankGroup)
            .filter(filterData)
            .filter(filterBySearch)
            .sort(sortData),
        ...[
            {
                dummy: true,
                avg_clicks_trend: [],
                avg_difficulty: 60,
                keywords: [],
                total_keywords_count: 409,
                delta_keywords_count: -16,
                avg_position: 10,
                page: "https://www.scalenut.com/pricing",
                total_traffic: 44,
                traffic_share: 20,
                impressions: [],
                volume: 4381,
                avg_rank: [],
                id: null,
                total_impressions: 1066,
                delta_traffic: -45,
                page_filter: null,
                avg_position_trend: [4, 4, 3, 3, 3, 3, 4, 4],
                dashedPosition: [],
                color: 11,
                trendsStatus: null,
                trendsDateLabel: ["1,", "2", "3", "4", "5,", "6", "7", "8"],
            },
        ].filter(filterSubDataDummy),
    ];

    const subData = [...nonProcessedPages.filter(filterSubData)];
    useEffect(() => {
        if (subData.length > 0) {
            setIsNonProcessed(true);
        } else {
            setIsNonProcessed(false);
        }
    }, [subData]);
    useEffect(() => {
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [data]);

    const handleSortByColumns: (index: number) => void = (index) => {
        setSortBy((ps) => {
            if (ps?.id == SortByOptions[index].id) {
                setOrder((ps) => (ps == 0 ? 1 : 0));
                return SortByOptions[index];
            } else {
                setOrder(1);
                return SortByOptions[index];
            }
        });
    };

    return isLoading ? (
        <div className={`${styles.inventoryWrapper} ${styles.loading}`}>
            <CircularProgress />
        </div>
    ) : (
        <div
            className={`${styles.inventoryWrapper} ${
                isProcessingV2 && styles.backdropOnPanel
            }`}
        >
            {isProcessingV2 && (
                <div className={styles.backdrop}>
                    <div className={styles.processWrapper}>
                        <lottie-player
                            src="https://assets1.lottiefiles.com/packages/lf20_enrpmvws.json"
                            background="transparent"
                            speed="1.5"
                            style={{
                                width: `${180}px`,
                                height: `${180}px`,
                            }}
                            autoplay
                            loop
                        ></lottie-player>
                        <div className={styles.processTitle}>
                            Processing Data
                        </div>
                        <div className={styles.processTitle_subText}>
                            Please wait while we are analysing your search
                            console data {activeCountry?.label && "for"}
                        </div>
                        {activeCountry?.label && (
                            <div className={styles.processTitle_country}>
                                {activeCountry?.label}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <InventoryTopOptions
                customClass={styles}
                handleSearch={setSearch}
                columnsList={columnsName}
                filterOptions={FilterOptions}
                rankFilterOptions={RankFilterOptions}
                dataArr={data}
                filterType={filterType}
                setFilterType={setFilterType}
                rankFilterType={rankFilterType}
                setRankFilterType={setRankFilterType}
                handleColumnsList={setColumnsName}
                availablePages={recommendationDataV2.length}
                totalPages={totalPage}
                isShimmer={isShimmer}
                activeDomain={activeDomain}
            />
            <div className={styles.tableWrapper}>
                <div
                    className={styles.recommendationTableContainer}
                    ref={divRef}
                >
                    <div className={styles.tableHead}>
                        {columnsName[0].isChecked && (
                            <div
                                className={
                                    styles.tableHead_title +
                                    " " +
                                    (sortBy.id == SortByOptions[0].id
                                        ? styles.enable
                                        : "")
                                }
                                onClick={() => {
                                    handleSortByColumns(0);
                                }}
                            >
                                {columnsName[0].name}

                                <SVGIcon
                                    size={20}
                                    style={{
                                        cursor: "pointer",
                                        color: "#2c3948c2",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                    }}
                                    src={`/New UI/SVG/sort${
                                        order == 0 &&
                                        SortByOptions[1].id == sortBy.id
                                            ? "A"
                                            : "D"
                                    }.svg`}
                                />
                            </div>
                        )}
                        {columnsName[1].isChecked && (
                            <LightTooltip
                                title={
                                    "Total number of keywords in past 28 days V/S change in total number of keywords in previous 28 days."
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_keyword +
                                        " " +
                                        (sortBy.id == SortByOptions[1].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(1);
                                    }}
                                >
                                    {columnsName[1].name}

                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[1].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[2].isChecked && (
                            <LightTooltip
                                title={
                                    "Compare the website impressions in the last 28 days to the traffic change from the previous month."
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_traffic +
                                        " " +
                                        (sortBy.id == SortByOptions[2].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(2);
                                    }}
                                >
                                    {columnsName[2].name}

                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[2].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[3].isChecked && (
                            <LightTooltip
                                title={
                                    "The measure of a page's traffic percentage within overall website visits."
                                }
                                placement={"top"}
                                arrow
                            >
                                <div
                                    className={
                                        styles.tableHead_trafficShare +
                                        " " +
                                        (sortBy.id == SortByOptions[3].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(3);
                                    }}
                                >
                                    {columnsName[3].name}

                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[3].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[4].isChecked && (
                            <LightTooltip
                                title={
                                    "Impressions refer to the number of times a web page is displayed to users."
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_Impression +
                                        " " +
                                        (sortBy.id == SortByOptions[4].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(4);
                                    }}
                                >
                                    {columnsName[4].name}
                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[4].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}

                        {columnsName[5].isChecked && (
                            <LightTooltip
                                title={"The present rank of the page."}
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_avgRank +
                                        " " +
                                        (sortBy.id == SortByOptions[6].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(6);
                                    }}
                                >
                                    {columnsName[5].name}
                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[6].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[6].isChecked && (
                            <LightTooltip
                                title={
                                    "The page’s overall ranking movement over 8 weeks."
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_rank +
                                        " " +
                                        (sortBy.id == SortByOptions[5].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(5);
                                    }}
                                >
                                    {columnsName[6].name}
                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[5].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[7].isChecked && (
                            <LightTooltip
                                title={
                                    "Change in rank in last 28 days compared to previous 28 days"
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_deltaRank +
                                        " " +
                                        (sortBy.id == SortByOptions[7].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(7);
                                    }}
                                >
                                    {columnsName[7].name}
                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[7].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[8].isChecked && (
                            <LightTooltip
                                title={
                                    "Total search volumes for the top 10 keywords."
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_totalVolume +
                                        " " +
                                        (sortBy.id == SortByOptions[8].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(8);
                                    }}
                                >
                                    {columnsName[8].name}
                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[8].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        {columnsName[9].isChecked && (
                            <LightTooltip
                                title={
                                    "Average Keyword difficulty of top 10 keywords for this page."
                                }
                                arrow
                                placement={"top"}
                            >
                                <div
                                    className={
                                        styles.tableHead_avgKD +
                                        " " +
                                        (sortBy.id == SortByOptions[9].id
                                            ? styles.enable
                                            : "")
                                    }
                                    onClick={() => {
                                        handleSortByColumns(9);
                                    }}
                                >
                                    {columnsName[9].name}
                                    <SVGIcon
                                        size={20}
                                        style={{
                                            cursor: "pointer",
                                            color: "#2c3948c2",
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                        }}
                                        src={`/New UI/SVG/sort${
                                            order == 0 &&
                                            SortByOptions[9].id == sortBy.id
                                                ? "A"
                                                : "D"
                                        }.svg`}
                                    />
                                </div>
                            </LightTooltip>
                        )}
                        <div
                            className={`${styles.tableHead_actions} ${
                                isOverflowing && styles.scrollTrue
                            }`}
                        ></div>
                    </div>

                    {isShimmer ? (
                        <ItemRowShimmer />
                    ) : data.length > 0 ? (
                        <RenderInChucks
                            list={data.slice(
                                currentPage * itemsPerPage,
                                currentPage * itemsPerPage + itemsPerPage,
                            )}
                        >
                            {(item: InventoryV2Object, index: number) => {
                                return (
                                    <ItemRow
                                        key={index + 2}
                                        item={item}
                                        index={index}
                                        expandIdx={expandIdx}
                                        setExpandIdx={setExpandIdx}
                                        filterType={filterType}
                                        columnsName={columnsName}
                                        scroll={isOverflowing}
                                        createOptimizerReport={
                                            createOptimizerReport
                                        }
                                        isDummy={item?.dummy}
                                        search={search}
                                    />
                                );
                            }}
                        </RenderInChucks>
                    ) : (
                        <>
                            <div className={styles.noItems}>
                                <SVGIcon
                                    size={280}
                                    src={"/New UI/SVG/Emptydocument.svg"}
                                />
                                <h3 className={styles.text}>No Pages Found</h3>
                            </div>
                        </>
                    )}
                    {search.length > 0 &&
                        !isLTD &&
                        subData.length > 0 &&
                        pageCount == currentPage + 1 && (
                            <div
                                className={styles.overlayUpgrade}
                                ref={overlayUpgradeRef}
                            >
                                <div className={styles.adjustable}>
                                    <div>
                                        <div
                                            style={{
                                                color: "#3F5575",
                                                textAlign: "center",
                                                fontSize: "18px",
                                                fontWeight: 500,
                                                lineHeight: "28px" /* 175% */,
                                            }}
                                        >
                                            Found {subData.length} more{" "}
                                            {subData?.length > 1
                                                ? "pages "
                                                : "page "}
                                            for the search query
                                        </div>
                                        <div
                                            style={{
                                                color: "#6B6B6B",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "28px" /* 175% */,
                                            }}
                                        >
                                            Upgrade your plan to see all pages
                                        </div>
                                    </div>
                                    <div
                                        className={styles.ctaWrapper}
                                        ref={ctaWrapperRef}
                                        onClick={(e) => {
                                            if (isUserPro) {
                                                window.open(
                                                    "https://calendly.com/scalenut/call",
                                                    "_blank",
                                                );
                                            } else {
                                                e.stopPropagation();
                                                window.open(
                                                    "/admin/account/plans",
                                                    "_blank",
                                                );
                                            }
                                        }}
                                    >
                                        {isUserPro ? (
                                            <>
                                                <SVGIcon
                                                    src={
                                                        "/New UI/SVG/phone.svg"
                                                    }
                                                />{" "}
                                                Schedule a Call
                                            </>
                                        ) : (
                                            "Upgrade Now"
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            <InventoryBottomOptions
                customClass={styles}
                pageCount={pageCount}
                currentPage={currentPage}
                handleCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
};

export default InventoryV2;
