import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { clearError, validationError } from "../../../../store/actions/auth.js";
import ValidationError from "../../../../components/common/validationError";
import { emailValidator } from "../../../../utility/validateFunctions";
import { googleLogin, continueWithEmail } from "../../../../store/actions/auth";
import Button from "../../../atoms/Button/Button";
import TextField from "../../../atoms/CustomTextfield/Textfield";
import Spacer from "../../../atoms/Spacer/Spacer";
import styles from "../styles.module.css";
import axios from "axios";
import { url } from "../../../../utility/config";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const queryEmail = urlParams.get("email");
const queryContext = urlParams.get("context");
const queryUtmSource = urlParams.get("utm_source");
const queryUtmMedium = urlParams.get("utm_medium");
const queryUtmCampaign = urlParams.get("utm_campaign");

function SignIn({ responseGoogle, queryEntries }) {
    const [email, setEmail] = useState("");
    const _googleLogin = useSelector((state) => state.auth.googleLogin);
    const token = useSelector((state) => state.auth.token);
    const loading = useSelector((state) => state.auth.loading);
    const error = useSelector((state) => state.auth.error);
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const [emailValid, setEmailValid] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearError());

        if (queryEmail) dispatch(continueWithEmail(queryEmail, queryEntries));
    }, []);

    const handleContinueWithEmail = (e) => {
        e.preventDefault();
        const [isValid, errorMsg] = emailValidator(email);

        if (isValid) {
            setEmailValid("");
            dispatch(continueWithEmail(email, queryEntries));
        } else {
            setEmailValid(errorMsg);
        }
    };

    return (
        <>
            {error ? <ValidationError msg={errorMessage} /> : null}
            <Spacer spacing={15} />
            <GoogleLogin
                clientId="466601860416-thij5f8b08j5nslathiom9t0fneoe8t4.apps.googleusercontent.com"
                render={(renderProps) => (
                    <Button
                        props={{
                            id: "google-auth-button",
                        }}
                        loading={loading && _googleLogin}
                        handler={renderProps.onClick}
                        disabled={renderProps.disabled}
                        height={44}
                        Icon={FcGoogle}
                        secondary={true}
                        text={"Continue with Google"}
                        iconReverse={true}
                    />
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
            />

            <Spacer spacing={15} />
            <span className={styles.link1}>OR</span>

            <form id="email_form" onSubmit={(e) => e.preventDefault()}>
                <TextField
                    props={{
                        value: email,
                        onChange: (x) => setEmail(x.target.value),
                        id: "email",
                    }}
                    height={44}
                    placeholder={"Enter your email"}
                    label="Email"
                    errMsg={emailValid}
                    autoFocus
                />
                <Spacer spacing={10} />

                <Spacer spacing={15} />

                <Button
                    props={{
                        id: "verify-email-button",
                    }}
                    loading={loading && !_googleLogin}
                    handler={handleContinueWithEmail}
                    height={44}
                    text={"Continue"}
                />
                <Spacer spacing={20} />
            </form>
        </>
    );
}

export default SignIn;
