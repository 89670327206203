import React, { useState, useEffect, useRef, useContext } from "react";
import { IoIosSquareOutline } from "react-icons/io";
import { BsPlus, BsTagFill } from "react-icons/bs";
import { FiCheckSquare } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { MdClose } from "react-icons/md";
import styles from "./styles.module.css";
import CheckBox from "./CheckBox";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import { TagsContext } from "../../Pages/TagsContext";

const manageStyle = {
    position: "absolute",
    top: "20px",
    right: "15px",
    fontSize: "12px",
    fontWeight: " 600",
    color: "#024dc5",
};

function TagsField({
    tags,
    createTag,
    selectedTags,
    setSelectedTags,
    isDomainsAvailable = false,
}) {
    const [all, setAll] = useState(false);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [searchDomain, setSearchDomain] = useState("");
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    const refBox = useRef(null);
    const { getContainerRef } = useContext(TagsContext);

    useEffect(() => {
        if (refBox.current) {
            getContainerRef(refBox.current, manageStyle, true);
        }

        console.log("container tags", refBox);
    }, [refBox, open]);

    function getOptions(tags) {
        let options = tags
            .filter((tag) => tag.tag_name.includes(search))
            .map((tag) => (
                <CheckBox
                    key={tag.id}
                    tag={tag}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    term={tag.tag_name}
                ></CheckBox>
            ));

        if (options.length > 0) {
            return options;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (search.trim()) {
            setOpen(true);
        }
    }, [search]);

    useEffect(() => {
        const handler = (e) => {
            if (
                e.key == "Enter" &&
                search.trim() &&
                !tags
                    .map((tag) => tag.tag_name.toLowerCase())
                    .includes(search.trim().toLowerCase())
            ) {
                (async () => {
                    const tag = await createTag(search);
                    setSelectedTags((pre) => [...pre, tag]);
                })();
            }
        };

        document.addEventListener("keyup", handler);
        return () => document.removeEventListener("keyup", handler);
    }, [search, tags]);

    useEffect(() => {
        if (selectedTags.length == 0 || selectedTags.length == tags.length) {
            // setOpen(true);
        }
    }, []);

    useOnClickOutside(ref, () => selectedTags.length > 0 && setOpen(false));

    return (
        <div style={{ marginTop: "10px" }}>
            <div>Which tags access do you want to give?</div>
            <div ref={ref}>
                <div className={styles.tagsStyle}>
                    <input
                        autoFocus={true}
                        disabled={loading}
                        value={search}
                        onChange={(e) =>
                            e.target.value.length < 21 &&
                            setSearch(e.target.value)
                        }
                        placeholder={"Search tags or create new (max 20 chars)"}
                        type="text"
                        // onFocus={() => setOpen(true)}
                    />
                    {
                        <span className={styles.allTags}>
                            {!open ? (
                                <CgChevronDown
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    size={20}
                                />
                            ) : (
                                <CgChevronUp
                                    onClick={() => {
                                        setOpen(!true);
                                    }}
                                    size={20}
                                />
                            )}{" "}
                        </span>
                    }

                    {open && (
                        <div
                            onClick={(e) => e.stopPropagation()}
                            className={styles.checkboxes}
                            ref={refBox}
                        >
                            {
                                <>
                                    {" "}
                                    {search.trim() &&
                                        !tags
                                            .map((tag) =>
                                                tag.tag_name.toLowerCase(),
                                            )
                                            .includes(
                                                search.trim().toLowerCase(),
                                            ) && (
                                            <div
                                                onClick={() =>
                                                    (async () => {
                                                        if (loading) return;

                                                        setLoading(true);
                                                        const tag =
                                                            await createTag(
                                                                search,
                                                            );
                                                        setSelectedTags(
                                                            (pre) => [
                                                                ...pre,
                                                                tag,
                                                            ],
                                                        );
                                                        setLoading(false);
                                                    })()
                                                }
                                                className={styles.newTagCreate}
                                            >
                                                <BsTagFill color="#014dc5" />{" "}
                                                <span>
                                                    Create new tag : '
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {search}
                                                    </span>
                                                    '
                                                    {loading ? (
                                                        <LoadingDots />
                                                    ) : null}
                                                </span>
                                            </div>
                                        )}{" "}
                                    {!search && (
                                        <span
                                            style={{
                                                width: "100%",
                                                marginBottom: "5px",
                                                height: "35px",
                                                color: "gray",
                                                borderBottom:
                                                    "solid lightgray 1px",
                                            }}
                                            className={styles.checkbox}
                                        >
                                            {selectedTags.length !=
                                            tags.length ? (
                                                <IoIosSquareOutline
                                                    onClick={() =>
                                                        setSelectedTags(tags)
                                                    }
                                                    size={22}
                                                />
                                            ) : (
                                                <FiCheckSquare
                                                    onClick={() =>
                                                        setSelectedTags([])
                                                    }
                                                    color="#014dc5"
                                                />
                                            )}{" "}
                                            All tags
                                        </span>
                                    )}
                                    {getOptions(tags)}
                                </>
                            }
                        </div>
                    )}
                </div>
            </div>

            <div
                className={styles.tags}
                style={{ minHeight: isDomainsAvailable ? "70px" : "200px" }}
            >
                {selectedTags.map((tag) => (
                    <span className={styles.tag}>
                        {tag?.tag_name}{" "}
                        <MdClose
                            onClick={() =>
                                setSelectedTags((pre) =>
                                    pre.filter((item) => item.id != tag.id),
                                )
                            }
                        />
                    </span>
                ))}
            </div>
        </div>
    );
}

export default TagsField;
