import Showdown from "../../../../lib/showdownmin";
import { SMART_EDITOR } from "../editorConstants";

export const saveAsPDF = (type, name, html, metaData) => {
    let iframe;
    try {
        const options = {};

        const contentsHTML = html;
        if (type == "HTML") {
            let exportFilename = name ? name + ".html" : "Report.html";
            let html = `<!DOCTYPE html>
      <html lang="en">
      <head>
        <style>
        
        body{
          font-family:sans-serif;

        }
        table td{
            border: 1px solid #000;
            padding:2px 5px;
          }
          table{
            border-collapse: collapse;
          }

        </style>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="description" content="${metaData?.description}">
        <link href="https://storage.googleapis.com/scalenut/webflow assests/61153269082539deef9b78ea_favicon.png" rel="shortcut icon" type="image/x-icon">
        <link href="https://storage.googleapis.com/scalenut/webflow assests/6115324ef9133b53291ff381_webclip.png" rel="apple-touch-icon">
        <title>${metaData?.title || exportFilename}</title>
      </head>
      <body>
        ${contentsHTML}
      </body>
      </html>`;
            let blob = new Blob([html], { type: "text/html;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportFilename);
            } else {
                let link = document.createElement("a");
                if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("skip", true);
                    link.setAttribute("download", exportFilename);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }

            return;
        }
        iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        const printDocument = getIframeDocument(iframe);

        printDocument.write(
            `<html>
      <head>
      <style>
        body{
          font-family:sans-serif;
         
        }
        table td{
            border: 1px solid #000;
            padding:2px 5px;
          }
          table{
            border-collapse: collapse;
          }
        </style>
        </head>
        <body>
        <p><strong>Meta Title:</strong> ${metaData?.title}</p>
        <p><strong>Meta Description:</strong> ${metaData?.description}</p>
        <p><strong>URL Slug:</strong> ${metaData?.url}</p>
        ${contentsHTML}
        </body>
        </html>`,
        );

        iframe.focus();
        // IE or Edge

        const images = iframe.contentDocument.getElementsByTagName("img");

        if (images.length) {
            const inter = setInterval(() => {
                try {
                    let complete = false;

                    for (let index = 0; index < images.length; index++) {
                        const image = images[index];
                        complete = image.complete;
                        if (!complete) {
                            break;
                        }
                    }

                    if (complete) {
                        const printed =
                            iframe.contentWindow.document.execCommand(
                                "print",
                                false,
                                null,
                            );
                        if (printed === false) iframe.contentWindow.print();
                        clearInterval(inter);
                        iframe?.remove && iframe?.remove();
                    }
                } catch (e) {
                    clearInterval(inter);
                    iframe?.remove && iframe?.remove();
                    console.log(e);
                }
            }, 200);
        } else {
            try {
                const printed = iframe.contentWindow.document.execCommand(
                    "print",
                    false,
                    null,
                );
                if (printed === false) iframe.contentWindow.print();
            } catch (e) {
                console.log(e);
            } finally {
                iframe?.remove && iframe?.remove();
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
    }
};

export async function Export2Markdown(filename = "", innerHTML) {
    const converter = new Showdown.Converter({
        tables: true,
        simpleLineBreaks: true,
    });
    const element = document.createElement("a");
    const file = new Blob([converter.makeMarkdown(innerHTML || "")], {
        type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${filename}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export async function Export2Word(element, filename = "", innerHTML, metaData) {
    try {
        var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${filename}</title><style>body{font-family:sans-serif}</style></head><body>`;
        var postHtml = "</body></html>";
        var metaHtml = `<p><strong>Meta Title:</strong> ${metaData?.title}</p><p></p><p><strong>Meta Description:</strong> ${metaData?.description}</p><p></p><p><strong>URL Slug:</strong> ${metaData?.url}</p><p></p>`;
        var html = preHtml + metaHtml + innerHTML + postHtml;

        // const blob = await HTMLtoDOCX(html)
        // const url = URL.createObjectURL(blob)

        // for doc
        var blob = new Blob(["\ufeff", html], {
            type: "application/msword",
        });
        // Specify link url
        var url =
            "data:application/vnd.ms-word;charset=utf-8," +
            encodeURIComponent(html);

        // Specify file name
        filename = filename ? filename + ".doc" : "document.doc";

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url;
            downloadLink.setAttribute("skip", true);

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    } catch (error) {
        console.log(error);
    }
}

export const getRecentDocs = (attachedTo, userId) => {
    try {
        let docsData = localStorage.getItem("docsData");
        console.log("dd", docsData);
        if (!docsData) {
            return [];
        }
        docsData = JSON.parse(docsData);
        console.log("dd", docsData);
        const userData = docsData[userId];

        if (!userData) {
            return [];
        }

        const list =
            userData[
                attachedTo == SMART_EDITOR
                    ? "recentAIDocs"
                    : attachedTo == "AI_HUMANIZER"
                    ? "recentHumanizerScans"
                    : "recentSEODocs"
            ];

        if (typeof list == "object" && list.length) return list;
        else return [];
    } catch (error) {
        console.log(error);
        return [];
    }
};

export const saveRecentDocs = (attachedTo, userId, doc) => {
    let docsData = localStorage.getItem("docsData");

    console.log("????????????");
    try {
        if (!docsData) {
            docsData = {
                [userId]: {
                    recentAIDocs: [],
                    recentSEODocs: [],
                    recentHumanizerScans: [],
                },
            };
        } else {
            docsData = JSON.parse(docsData);

            const userData = docsData[userId];

            if (!userData) {
                docsData[userId] = {
                    recentAIDocs: [],
                    recentSEODocs: [],
                    recentHumanizerScans: [],
                };
            }
        }
        let list =
            docsData[userId][
                attachedTo == SMART_EDITOR
                    ? "recentAIDocs"
                    : attachedTo == "AI_HUMANIZER"
                    ? "recentHumanizerScans"
                    : "recentSEODocs"
            ];

        if (list.length > 10) {
            list.pop();
        }

        list = list.filter((item) => item.id != doc.id);

        list.unshift({
            ...doc,
            openedAt: new Date().toGMTString(),
        });

        docsData[userId][
            attachedTo == SMART_EDITOR
                ? "recentAIDocs"
                : attachedTo == "AI_HUMANIZER"
                ? "recentHumanizerScans"
                : "recentSEODocs"
        ] = list;

        localStorage.setItem("docsData", JSON.stringify(docsData));
    } catch (error) {
        localStorage.setItem(
            "docsData",
            JSON.stringify({
                [userId]: {
                    recentAIDocs: [],
                    recentSEODocs: [],
                    recentHumanizerScans: [],
                },
            }),
        );
        console.log("************", error);
    }
};

export const saveOutlineAsPDF = (outline = [], title = "") => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const printDocument = getIframeDocument(iframe);

    const text = CreateOulineHtml(outline, title).innerHTML;

    printDocument.write(
        `<html>
  <head>
  <style>
    body{
      font-family:sans-serif;
    }
    </style>
    </head>
    <body>
    ${text}
    </body>
    </html>`,
    );

    iframe.focus();

    try {
        const printed = iframe.contentWindow.document.execCommand(
            "print",
            false,
            null,
        );
        if (printed === false) iframe.contentWindow.print();
    } catch (e) {
        console.log(e);
    } finally {
        iframe?.remove && iframe?.remove();
    }
};

export const ExportOutline2Word = (outline = [], title = "", filename = "") => {
    try {
        var preHtml = `
            <html xmlns:o='urn:schemas-microsoft-com:office:office' 
                  xmlns:w='urn:schemas-microsoft-com:office:word' 
                  xmlns='http://www.w3.org/TR/REC-html40'>
                <head>
                    <meta charset='utf-8'>
                    <title>${filename}</title>
                    <style>
                        body { font-family: sans-serif; font-weight: normal; }
                        h1, h2, h3, h4, h5, h6 { font-weight: normal; }
                        p { font-weight: normal; }
                        li { font-weight: normal; }
                    </style>
                </head>
                <body>`;
        var postHtml = "</body></html>";

        var html =
            preHtml + CreateOulineHtml(outline, title).innerHTML + postHtml;

        // for doc
        var blob = new Blob(["\ufeff", html], {
            type: "application/msword",
        });

        // Specify link url
        var url =
            "data:application/vnd.ms-word;charset=utf-8," +
            encodeURIComponent(html);

        // Specify file name
        filename = filename ? filename + ".doc" : "document.doc";

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url;
            downloadLink.setAttribute("skip", true);

            // Setting the file name
            downloadLink.download = filename;

            // Triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    } catch (error) {
        console.log(error);
    }
};

export const CopyOutline = (outline = [], title = "", isBrief = false) => {
    const text = createOutline(outline, title, isBrief);
    navigator.clipboard.writeText(text);
};

const CreateOulineHtml = (outline = [], title = "") => {
    const container = document.createElement("div");

    const titleTag = document.createElement("H1");

    titleTag.innerText = "Title: " + title;

    container.appendChild(titleTag);

    const headingsHead = document.createElement("H1");

    container.appendChild(headingsHead);

    for (const h2 of outline.headings) {
        const H2 = document.createElement("H2");

        H2.innerText = h2.data;

        container.appendChild(H2);

        for (const h3 of h2.outlineH3s) {
            const H3 = document.createElement("H3");

            H3.innerText = `\t` + h3.data;

            container.appendChild(H3);
        }
    }
    if (outline.questions.length) {
        const headingsHead = document.createElement("H1");

        headingsHead.innerText = "Questions:";

        container.appendChild(headingsHead);
    }

    for (const question of outline.questions) {
        const H2 = document.createElement("H2");

        H2.innerText = question.data;

        container.appendChild(H2);
    }

    return container;
};

const createOutline = (outline = [], title = "", isBrief = false) => {
    let text = `H1: ${title}\n\n`;

    if (!isBrief) {
        text += `H2: Headings:\n\n`;
    }

    for (const h2 of outline.headings) {
        text += `H2: ${h2.data}`;
        text += `\n`;
        for (const h3 of h2.outlineH3s) {
            text += `\t` + `H3: ${h3.data}`;
            text += `\n`;
        }
    }
    if (outline.questions.length) text += `\nH2: Questions:\n\n`;

    for (const question of outline.questions) {
        text += `H3: ${question.data}`;
        text += `\n`;
    }
    return text;
};

export const getIframeDocument = function (iframe) {
    let wDocument = iframe.contentWindow || iframe.contentDocument;
    if (wDocument.document) wDocument = wDocument.document;
    return wDocument;
};
