import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTable, useSortBy } from "react-table";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import TextField from "../../componentsV2/atoms/CustomTextfield/Textfield";
import Button from "../../componentsV2/atoms/Button/Button";
import {
    AiOutlineDesktop,
    AiOutlineMobile,
    AiOutlineSearch,
    AiOutlineZoomIn,
    AiOutlineZoomOut,
} from "react-icons/ai";

import styles from "./superAdmin.module.css";
import AutoComplete from "../../componentsV2/atoms/AutoComplete/AutoComplete";
import DateTimePicker from "react-datetime";
import Label from "../../componentsV2/atoms/Label/Label";
import { download } from "./csvCoverter";
import { BsCloudDownload } from "react-icons/bs";

let fileName = "FE_Logs";
let mouseDownHandler = null;

function FrontendLogs({ setActiveAction }) {
    const { getData } = useHelperFunctions();
    const [data1, setData] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [email, setEmail] = useState(null);
    const [userId, setUserId] = useState({});
    const [loading, setLoading] = useState(false);
    const [zoom, setZoom] = useState(0.4);
    const ref = useRef();
    const [range, setRange] = useState({
        from: new Date(+new Date() - 1000 * 60 * 60 * 24 * 7),
        to: new Date(),
    });

    fileName =
        "FE_logs" +
        "_" +
        range.from.toDateString() +
        "_to_" +
        range.to.toDateString();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await getData({
                url: `/api/dbApi/getFrontendLogs?from=${range.from.toISOString()}&to=${range.to.toISOString()}`,
            });
            setLoading(false);

            if (res.status == 200) {
                res.data = res.data.map((item, i) => {
                    item.sno = i + 1;
                    item.created_at = `${new Date(
                        item.created_at,
                    ).toLocaleDateString()} : ${new Date(
                        item.created_at,
                    ).toLocaleTimeString()}`;
                    if (item.device_info) {
                        if (item.device_info.includes("Desktop")) {
                            item.device_info = (
                                <>
                                    <AiOutlineDesktop />{" "}
                                    {item.device_info.replace("Desktop", "")}{" "}
                                </>
                            );
                        } else {
                            item.device_info = (
                                <>
                                    <AiOutlineMobile />{" "}
                                    {item.device_info.replace("Mobile", "")}{" "}
                                </>
                            );
                        }
                    }
                    return item;
                });
                setData(res.data);
            } else {
                setData([
                    {
                        _id: "61aaea8b22675a4b07bc028a",
                        error_message:
                            "Cannot set properties of undefined (setting 'cursor')\n    in je\n    in div\n    in Ae\n    in div\n    in div\n    in j\n    in Wi\n    in t\n    in t\n    in div\n    in div\n    in Unknown\n    in t\n    in t\n    in I\n    in Suspense\n    in t\n    in t\n    in Unknown\n    in n\n    in z\n    in s",
                        ip_address: "103.66.73.127",
                        frontend_path:
                            "https://admin-3a4ef.web.app/admin/creator",
                        browser:
                            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36",
                        os: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36",
                        email: "as1@as.com",
                        user_id: "684",
                        country: "",
                        time_zone: "Asia/Calcutta",
                        path: "/api/dbApi/saveFrontendLogs",
                        created_at: "2021-12-04T04:11:55.779Z",
                        updated_at: "2021-12-04T04:11:55.779Z",
                        __v: 0,
                    },
                ]);
            }
        })();
    }, [refresh]);

    useEffect(() => {
        if (ref.current) {
            const ele = document.getElementById(styles.tableContainer);
            ele.scrollTop = 100;
            ele.scrollLeft = 150;
            let pos = { top: 0, left: 0, x: 0, y: 0 };

            mouseDownHandler = function (e) {
                ele.style.cursor = "grabbing";
                ele.style.userSelect = "none";
                pos = {
                    // The current scroll
                    left: ele.scrollLeft,
                    top: ele.scrollTop,
                    // Get the current mouse position
                    x: e.clientX,
                    y: e.clientY,
                };

                document.addEventListener("mousemove", mouseMoveHandler);
                document.addEventListener("mouseup", mouseUpHandler);
            };

            const mouseMoveHandler = function (e) {
                // How far the mouse has been moved
                const dx = e.clientX - pos.x;
                const dy = e.clientY - pos.y;

                // Scroll the element
                ele.scrollTop = pos.top - dy;
                ele.scrollLeft = pos.left - dx;
            };

            const mouseUpHandler = function () {
                document.removeEventListener("mousemove", mouseMoveHandler);
                document.removeEventListener("mouseup", mouseUpHandler);

                ele.style.cursor = "grab";
                ele.style.removeProperty("user-select");
            };
        }
    }, [ref]);

    const data = React.useMemo(() => data1);

    const columns = React.useMemo(
        () => [
            {
                Header: "SN",
                accessor: "sno",
            },
            {
                Header: "FE version",
                accessor: "fe_version",
            },
            {
                Header: "Error message",
                accessor: "error_message",
            },
            {
                Header: "IP address",
                accessor: "ip_address",
            },
            {
                Header: "Frontend path",
                accessor: "frontend_path",
            },
            {
                Header: "Browser",
                accessor: "browser",
            },
            {
                Header: "OS",
                accessor: "os",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "User id",
                accessor: "user_id",
            },
            {
                Header: "Country",
                accessor: "country",
            },
            {
                Header: "Time zone",
                accessor: "time_zone",
            },

            {
                Header: "Date",
                accessor: "created_at",
            },

            {
                Header: "Device info",
                accessor: "device_info",
            },
        ],
        [],
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy);

    return (
        <div className={styles.worklogContainer}>
            <p
                style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "auto",
                }}
                onClick={() => setActiveAction(null)}
            >
                {"< Go back"}
            </p>

            {
                <div className={styles.row2}>
                    <div>
                        <Label label="From"></Label>
                        <DateTimePicker
                            strictParsing
                            closeOnClickOutside
                            closeOnSelect
                            onChange={(date) => {
                                if (date._d) {
                                    setRange((p) => {
                                        return {
                                            from: new Date(date._d),
                                            to: p.to,
                                        };
                                    });
                                }
                            }}
                            value={range.from}
                            isValidDate={(c, s) => {
                                return true;
                            }}
                        />
                    </div>

                    <div>
                        <Label label="To"></Label>
                        <DateTimePicker
                            strictParsing
                            closeOnSelect
                            closeOnClickOutside
                            onChange={(date) => {
                                if (date._d && range.from < date._d) {
                                    setRange((p) => {
                                        return {
                                            from: p.from,
                                            to: new Date(date._d),
                                        };
                                    });
                                }
                            }}
                            value={range.to}
                            isValidDate={(c, s) => {
                                return true;
                            }}
                        />
                    </div>

                    <Button
                        loading={loading}
                        disabled={loading}
                        handler={() => {
                            setRefresh((a) => 1 + a);
                        }}
                        text=""
                        width={"100%"}
                        Icon={AiOutlineSearch}
                    />
                </div>
            }

            {data1.length > 0 && (
                <div className={styles.row}>
                    <AiOutlineZoomIn onClick={() => setZoom((z) => z + 0.1)} />
                    <AiOutlineZoomOut onClick={() => setZoom((z) => z - 0.1)} />
                </div>
            )}
            <div
                style={{ zoom }}
                ref={ref}
                onMouseDown={mouseDownHandler}
                id={styles.tableContainer}
                className={styles.tableContainer}
            >
                {data1.length > 0 && (
                    <>
                        Drag to scroll
                        <table
                            className={styles.table}
                            {...getTableProps()}
                            style={{ border: "solid 1px blue" }}
                        >
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps(),
                                                )}
                                                style={{
                                                    borderBottom:
                                                        "solid 3px red",
                                                    background: "aliceblue",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    textTransform: "capitalize",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {column.render("Header")}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? "🔽"
                                                            : " 🔼"
                                                        : ""}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            padding: "10px",
                                                            border: "solid 1px gray",
                                                            background:
                                                                "papayawhip",
                                                        }}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </div>
    );
}

export default FrontendLogs;
