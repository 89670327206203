import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { url } from "../../../utility/config";
import { device_token } from "../../../App";
import Quill from "quill";
import { ImageDrop } from "quill-image-drop-module";
import "./TextEditorSnow.css";
import { Emoji } from "../../Pages/TextEditor/EmojiMart";
import styles from "./styles.module.css";
import SVGIcon from "../../atoms/SVGIcon";
import MiniTool from "./MiniToolBar/MiniTool";
import Modal from "../../atoms/Modal/Modal";
import { UPLOAD_FILE } from "../../api.json";
import {
    getPosValueH,
    getPosValueV,
    link,
    scrapeImageSrc,
    SMART_EDITOR,
} from "./editorConstants";
import useHelperFunctions from "../../helperFunctions";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import BlotFormatter, { allowedMarginStyles } from "./quill-blot.js";
import { v4 as uuidv4 } from "uuid";
import AltTextImage from "./AltTextImage";
import WriteToEditor from "./Toolbar/WriteToEditor";
import FixItEditor from "./EditorAssistant/FixItEditor";
import "./quill-better-table.css";
import { TableChart } from "@material-ui/icons";
import { ImTable, ImTable2 } from "react-icons/im";
import { ClickAwayListener } from "@material-ui/core";
import BetterTable from "./QuillBetterTable/quill-better-table";
import ImageHandler from "./ImageModal/ImageModal";
import ReportContext from "../Research/ReportContext";
import mixpanel from "mixpanel-browser";
import { cssStringToObject } from "../../../utility/helperFun.js";
import imageLoading from "./image_upload.gif";

Quill.prototype.getText = function (index, length) {
    return this.getContents(index, length)
        .filter(
            (op) =>
                typeof op.insert === "string" ||
                op.insert.image ||
                op.insert.video,
        )
        .map((op) => (op.insert.image || op.insert.video ? "\n" : op.insert))
        .join("");
};

Quill.register(
    {
        "modules/better-table": BetterTable,
    },
    true,
);
var icons = Quill.import("ui/icons");
icons["bold"] = `<span
        style="
            color: #000624;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
        "
    >
        B
    </span>`;

icons[
    "underline"
] = `<span style="color: #000624; font-size: 14px; font-weight: 400; border-bottom: 1px solid #000624;">U</span>`;
// icons["ol"] =
//     '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17.061 11.22A4.46 4.46 0 0 0 18 8.5C18 6.019 15.981 4 13.5 4H6v15h8c2.481 0 4.5-2.019 4.5-4.5a4.48 4.48 0 0 0-1.439-3.28zM13.5 7c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5H9V7h4.5zm.5 9H9v-3h5c.827 0 1.5.673 1.5 1.5S14.827 16 14 16z"></path></svg>';

const event = new Event("editorSelectionChange");

Quill.register("modules/blotFormatter", BlotFormatter);

Quill.register("modules/imageDrop", ImageDrop);

let BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

const imageUploadPost = async ({
    method = "post",
    endpoint,
    headers = { "Content-Type": "application/json", "X-Frame-Options": "DENY" },
    payload,
    optionalToken = false,
    showError = true,
    node,
}) => {
    node.src = imageLoading;
    node.classList.add(styles.imageLoading);
    return await axios({
        // signal: controller.signal,
        url: url + endpoint,
        method,
        headers: optionalToken
            ? { ...headers }
            : {
                  ...device_token,
                  Authorization: localStorage.token,
                  ...headers,
              },
        data: payload,
    })
        .then((res) => {
            node.src = res.data?.data?.attachment;
            node.onload = () => {
                node.classList.remove(styles.imageLoading);
            };
        })
        .catch((err) => {
            node.src = scrapeImageSrc;
            node.onload = () => {
                node.classList.remove(styles.imageLoading);
            };
            return err;
        });
};

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }

    static create(value) {
        //for s3 migration we need to handle the aws s3 url to GCS url.
        let newValue = value?.replace(
            "scalenut.s3.dualstack.us-east-2.amazonaws.com",
            "storage.googleapis.com/scalenut",
        );
        newValue = newValue.replace(
            "scalenut-nonprod-article-images.s3.dualstack.us-east-1.amazonaws.com",
            "storage.googleapis.com/scalenut-nonprod-article-images",
        );
        newValue = newValue.replace(
            "scalenut-prod-article-images.s3.dualstack.us-east-1.amazonaws.com",
            "storage.googleapis.com/scalenut-prod-article-images",
        );
        ///////// End here
        let node = "";
        var BASE64_MARKER = ";base64,";

        if (newValue.indexOf(BASE64_MARKER) == -1) {
            node = super.create(newValue);
        } else {
            const blob = dataURLToBlob(newValue);
            const file = new File([blob], `${+new Date()}.${blob.type}`, {
                type: blob.type,
            });

            const formdata = new FormData();
            formdata.append("image", file);
            node = super.create("");

            const res = imageUploadPost({
                endpoint: UPLOAD_FILE,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                payload: formdata,
                node: node,
            });
        }

        node.setAttribute("data-id", uuidv4());

        node.onerror = (e) => {
            e.target.src = scrapeImageSrc;
        };
        return node;
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                if (name === ImageFormatAttributesList[3]) {
                    const styles = cssStringToObject(value);
                    const margin = styles.margin;
                    this.domNode.removeAttribute(name);
                    if (allowedMarginStyles.includes(margin)) {
                        this.domNode.style.margin = margin;
                    } else {
                        this.domNode.style.margin = allowedMarginStyles[0];
                    }
                    this.domNode.style.display = "block";
                } else {
                    this.domNode.setAttribute(name, value);
                }
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}
//extending image drop method

const dataURLToBlob = function (dataURL) {
    var BASE64_MARKER = ";base64,";
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(",");
        var contentType = parts[0].split(":")[1];
        var raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw?.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
};

Quill.register(ImageFormat, true);

let Link = Quill.import("formats/link");
// let toolasda = Quill.import("modules/toolbar");
// console.log(Link, toolasda);
let originalSanitisation = Link.sanitize;

Link.sanitize = function (value) {
    let val = value;
    console.log(this);

    // do nothing, since this implies user's already using a custom protocol
    if (/^\w+:/.test(val));
    else if (!/^https?:/.test(val)) val = "https://" + val;

    return originalSanitisation.call(this, val); // retain the built-in lo
};

const Inline = Quill.import("blots/inline");

class PremiumCruiseBlot extends Inline {
    static create(value) {
        const node = super.create();
        node.setAttribute("class", "premiumCruise");
        node.innerText = value;
        return node;
    }
}
PremiumCruiseBlot.blotName = "premiumCruise";
PremiumCruiseBlot.tagName = "span";
Quill.register(PremiumCruiseBlot);

var toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons

    // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    //[{ direction: "rtl" }], // text direction
    // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["link", "image", "video", "emoji", "better-table"],
    ["clean"], // remove formatting button,
];

var Font = Quill.import("formats/font");

// We do not add Aref Ruqaa since it is the default
Font.whitelist = [
    "open-sans",
    "noto-sans",
    "inter",
    "poppins",
    "roboto",
    "gluten",
    "fleur-de-leah",
    "serif",
    "sans-serif",
];

Quill.register(Font, true);

var SizeStyle = Quill.import("attributors/style/size");

SizeStyle.whitelist = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "36px",
    "48px",
    "72px",
];

Quill.register(SizeStyle, true);
let timeout = null;
let timeout1 = null;
const QuillCustom = ({
    containerId,
    saveData,
    writeForMe,
    setCanReWrite,
    attachedTo,
    versionData,
    setEditorRef,
    saveEditorData,
    editorRef,
    fixItEditorRef,
    enableFixItEditor,
    auditMode,
    showOutline,
}) => {
    const { postData } = useHelperFunctions();
    const [analyzing, setAnalyzing] = useState(false);
    const [editorChange, setEditorChange] = useState(0);

    useEffect(() => {
        const uploadImage = async (image) => {
            const formdata = new FormData();
            formdata.append("image", image);
            setAnalyzing(true);
            const res = await postData({
                url: UPLOAD_FILE,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                payload: formdata,
            });
            setAnalyzing(false);
            return res;
        };

        ImageDrop.prototype.insert = async function (url) {
            try {
                const blob = dataURLToBlob(url);
                const file = new File([blob], `${+new Date()}.${blob.type}`, {
                    type: blob.type,
                });

                const res = await uploadImage(file);

                if (res.status == 200) {
                    document.quill.editor.insertText(
                        document.quill.editor.cursor,

                        "\n",
                        "user",
                    );
                    document.quill.editor.insertEmbed(
                        document.quill.editor.cursor + 1,
                        "image",
                        res.data.data.attachment,
                        "user",
                    );
                    document.quill.editor.insertText(
                        document.quill.editor.cursor + 2,

                        "\n",
                        "user",
                    );
                }
            } catch (error) {
                console.log(error);
            }
        };

        const editorArea = document.quill.editor.root;
        const container = document.quill.editor.container;
        const toolbar = document.getElementsByClassName("ql-toolbar")[0];
        if (attachedTo == SMART_EDITOR && toolbar && editorArea) {
            // toolbar.classList.add("px92");
            // container.classList.add("px62");
            // editorArea.classList.add("noBeforeTag");
            // editorArea.classList.add("leftPadding");
        }
    }, []);

    const [emoji, setEmoji] = useState(false);

    const [toolPos, setToolPos] = useState({ top: 0, left: 0, height: 0 });
    const [openTableMenu, setOpenTableMenu] = useState(false);
    const emojiIconRef = useRef();

    const [selection, setSelection] = useState(null);
    const [miniTool, setMiniTool] = useState(false);
    const { reportInfo } = useContext(ReportContext);

    useEffect(() => {
        console.log(auditMode, "infoinfo");
        document.quill.editor.root.setAttribute(
            "data-placeholder",
            versionData.current
                ? attachedTo == SMART_EDITOR
                    ? "Add outputs from AI templates (right pane) to enhance further"
                    : auditMode && !reportInfo.audit_url
                    ? "Kindly paste the content for optimization here..."
                    : "Write here..."
                : "",
        );
    }, [editorRef]);

    useEffect(() => {
        if (selection && document.quill.editor.isEnabled()) {
            document.quill.editor.cursor = selection.index;

            if (selection && selection.length > 0) {
                setMiniTool(true);
                const pos = document.quill.editor?.getBounds(
                    selection?.index + selection?.length,
                );
                setToolPos(pos);
            } else {
                setMiniTool(false);
            }
        }
    }, [selection]);

    return (
        <>
            {!!editorRef &&
                !!document.getElementsByClassName("ql-emoji")[0] &&
                ReactDOM.createPortal(
                    <SVGIcon
                        outerRef={emojiIconRef}
                        src="/New UI/SVG/emoji.svg"
                    />,
                    document.getElementsByClassName("ql-emoji")[0],
                    "emojiIcon",
                )}
            {ReactDOM.createPortal(
                emoji ? (
                    <Emoji setEmoji={setEmoji} emojiIconRef={emojiIconRef} />
                ) : (
                    <></>
                ),
                document.getElementById("root"),
                "emojiMart",
            )}
            {!!editorRef &&
                !!document.getElementsByClassName("ql-better-table")[0] &&
                ReactDOM.createPortal(
                    <TableInsertMenu
                        openTableMenu={openTableMenu}
                        setOpenTableMenu={setOpenTableMenu}
                    />,
                    document.getElementsByClassName("ql-better-table")[0],
                )}
            {!!editorRef &&
                ReactDOM.createPortal(
                    analyzing ? (
                        <div className={styles.uploading}>
                            Uploading... <LoadingDots />
                        </div>
                    ) : (
                        <></>
                    ),
                    document.getElementById("editor-bound").parentNode,
                )}
            {miniTool &&
                versionData.current &&
                !!editorRef &&
                ReactDOM.createPortal(
                    <MiniTool
                        selection={selection}
                        setMiniTool={setMiniTool}
                        quill={document.quill.editor}
                        toolPos={toolPos}
                        writer={writeForMe}
                        reverse={getPosValueV(toolPos.top).reverse}
                        style={{
                            top: getPosValueV(toolPos.top).top,
                            left:
                                (attachedTo == SMART_EDITOR ? 60 : 0) +
                                getPosValueH(toolPos.left).left,
                            position: "absolute",
                            right: getPosValueH(toolPos.right).right,
                        }}
                    />,
                    document.quill.editor.container,
                )}

            {enableFixItEditor &&
                !!editorRef &&
                versionData.current &&
                ReactDOM.createPortal(
                    <FixItEditor
                        editorRef={editorRef}
                        fixItEditorRef={fixItEditorRef}
                    />,
                    document.quill.editor.container,
                )}

            {versionData.current &&
                !!editorRef &&
                ReactDOM.createPortal(
                    <AltTextImage
                        attachedTo={attachedTo}
                        editorChange={editorChange}
                        showOutline={showOutline}
                    />,
                    document.quill.editor.container,
                )}

            <QuillReact
                setSelection={setSelection}
                containerId={containerId}
                setEditorChange={setEditorChange}
                saveData={saveData}
                setEmoji={setEmoji}
                link={link}
                toolbarOptions={toolbarOptions}
                setCanReWrite={setCanReWrite}
                setEditorRef={setEditorRef}
                saveEditorData={saveEditorData}
                setOpenTableMenu={setOpenTableMenu}
            />
        </>
    );
};

const QuillReact = ({
    setSelection,
    setOpenTableMenu,
    setEditorChange,
    saveData,
    setEmoji,

    setCanReWrite,
    setEditorRef,
    saveEditorData = { saveEditorData },
}) => {
    useEffect(() => {
        return () => {
            saveEditorData();
        };
    }, []);

    return (
        <ReactQuill
            setEditorRef={setEditorRef}
            preserveWhitespace={false}
            onChangeSelection={(e, range, f) => {
                if (e == "selection-change" && range) {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        if (range.length > 0) {
                            //Global editor selection Reference
                            document.editorSelection = range;
                        } else {
                            document.editorSelection = null;
                        }

                        setSelection(range);
                    }, 100);
                }
            }}
            onChange={(e, f, src, editor) => {
                if (src) {
                    clearTimeout(timeout1);
                    timeout1 = setTimeout(() => {
                        if (src != "api") {
                            setCanReWrite(false);
                            console.log("rewrite called");
                        }
                        setEditorChange((ps) => ps + 1);
                    }, 500);
                }
                document.dispatchEvent(event);
                if (src != "silent") saveData();
            }}
            setEmoji={setEmoji}
            setOpenTableMenu={setOpenTableMenu}
        />
    );
};

const ReactQuill = ({
    setEditorRef,
    onChangeSelection,
    onChange,
    setEmoji,

    setOpenTableMenu,
}) => {
    var toolbarOptions = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons

        // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        //[{ direction: "rtl" }], // text direction
        // custom dropdown
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["link", "image", "video", "emoji", "better-table"],
        ["clean"], // remove formatting button,
    ];

    const quillModules = {
        table: false,
        "better-table": {
            operationMenu: {
                items: {
                    unmergeCells: false,
                    mergeCells: false,
                },
                color: {
                    colors: ["green", "red", "yellow", "blue", "white"],
                    text: "Background Colors:",
                },
            },
            tools: true,
        },
        imageDrop: true,

        toolbar: {
            container: toolbarOptions,

            handlers: {
                emoji: (e, f, g) => {
                    setEmoji((ps) => !ps);
                },
                link: link,
                "better-table": () => {
                    mixpanel.track("Editor Toolbar", {
                        option: "Table",
                        cta: "Clicked",
                    });
                    setOpenTableMenu(true);
                },
            },
        },

        blotFormatter: {},

        history: {
            delay: 2000,
            maxStack: 500,
        },
        keyboard: {
            bindings: BetterTable.keyboardBindings,
        },
    };

    useEffect(() => {
        const quill = new Quill("#editor-bound", {
            theme: "snow",
            modules: quillModules,
            scrollingContainer: "#editorBoundsContainer",
            bounds: "#editorBoundsContainer",
            preserveWhitespace: false,
        });
        document.quill = { editor: quill };
        quill.on("editor-change", onChange);
        quill.on("editor-change", onChangeSelection);
        setEditorRef(quill);
    }, []);

    return <div className={styles.editorBounds} id="editor-bound"></div>;
};

const TableInsertMenu = ({ setOpenTableMenu, openTableMenu }) => {
    const [limit, setLimit] = useState({ x: 5, y: 10 });
    const [state, setState] = useState({ x: 0, y: 0 });

    const tableModule = useMemo(
        () => document.quill.editor.getModule("better-table"),
        [],
    );

    useEffect(() => {
        setState({ x: 0, y: 0 });
        setLimit({ x: 5, y: 10 });
    }, [openTableMenu]);

    useEffect(() => {
        console.log(state);
        if (state.x > 3 && state.x <= 19) {
            setLimit((ps) => ({ x: state.x + 1, y: ps.y }));
        }

        if (state.y > 8 && state.y <= 19) {
            setLimit((ps) => ({ y: state.y + 1, x: ps.x }));
        }
    }, [state]);

    return (
        <div style={{ position: "relative" }}>
            <ImTable2 />

            {openTableMenu && (
                <ClickAwayListener onClickAway={() => setOpenTableMenu(false)}>
                    <div className={styles.tableMenuContainer}>
                        {state.y} x {state.x}
                        {new Array(limit.x).fill(1).map((_, i) => {
                            return (
                                <div className={styles.tableMenuRow}>
                                    {new Array(limit.y).fill(1).map((_, j) => (
                                        <div
                                            onClick={() => {
                                                mixpanel.track(
                                                    "Editor Toolbar",
                                                    {
                                                        option: "Table",
                                                        cta: "Inserted",
                                                    },
                                                );

                                                tableModule.insertTable(
                                                    i + 1,
                                                    j + 1,
                                                );
                                                setOpenTableMenu(false);
                                            }}
                                            onMouseEnter={() =>
                                                setState({ x: i + 1, y: j + 1 })
                                            }
                                            className={`${
                                                styles.tableMenuOption
                                            } ${
                                                state.x > i &&
                                                state.y > j &&
                                                styles.tableMenuOptionSelected
                                            }`}
                                        ></div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default QuillCustom;
