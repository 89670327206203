import React, { useContext, useEffect, useState } from "react";
import { FiCheckSquare } from "react-icons/fi";
import { IoIosSquareOutline } from "react-icons/io";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import SVGIcon from "../../atoms/SVGIcon";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import { TagsContext } from "../../Pages/TagsContext";
import styles from "./styles.module.css";
import { VscTrash } from "react-icons/vsc";
import { GiPencil } from "react-icons/gi";
import useHelperFunctions from "../../helperFunctions";

function CheckBox({ term, tag, setSelectedTags, selectedTags }) {
    const [checked, setChecked] = useState(false);
    const [edit, setEdit] = useState(false);
    const [label, setLabel] = useState("");
    const [_label, _setLabel] = useState("");
    const { postData } = useHelperFunctions();
    const {
        editTag,
        isManage,
        setIsManage,
        setDeleteConfirm,
        confirmModal,
        setConfirmModal,
        getTagId,
    } = useContext(TagsContext);

    const renameTag = async (e) => {
        const value = e?.target?.value;

        if (tag?.id && value) {
            const res = await editTag(tag.id, value, false);
            if (res == "error") {
                setLabel(_label);
            } else {
                console.log(value, "????????");
                setLabel(value);
                _setLabel(value);
            }
        }
        setEdit(false);
    };
    const removeMember = (id) => {
        getTagId(id);

        setConfirmModal(true);
    };
    useEffect(() => {
        setLabel(term);
        _setLabel(term);
        if (selectedTags.map((tag) => tag?.id).includes(tag?.id))
            setChecked(true);
        else setChecked(false);
    }, [selectedTags]);

    return (
        <span
            className={styles.checkbox + " " + (!checked ? "" : styles.checked)}
        >
            {!checked ? (
                <IoIosSquareOutline
                    color="#abababa8"
                    onClick={() => {
                        setChecked(!false);
                        setSelectedTags((pre) => [...pre, tag]);
                    }}
                    size={22}
                />
            ) : (
                <FiCheckSquare
                    onClick={() => {
                        setChecked(false);
                        setSelectedTags((pre) =>
                            pre.filter((item) => item.id != tag.id),
                        );
                    }}
                    color="#014dc5"
                />
            )}{" "}
            {edit ? (
                ""
            ) : (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (checked) {
                            setChecked(false);
                            setSelectedTags((pre) =>
                                pre.filter((item) => item.id != tag.id),
                            );
                        } else {
                            setChecked(!false);
                            setSelectedTags((pre) => [...pre, tag]);
                        }
                    }}
                >
                    {label}
                </span>
            )}
            {!edit ? (
                <>
                    {isManage && (
                        <div className={styles.actionsTag}>
                            <span
                                onClick={() => setEdit(true)}
                                style={{
                                    marginTop: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <SVGIcon
                                    size={14}
                                    color="gray"
                                    src="/New UI/SVG/pencil.svg"
                                ></SVGIcon>
                            </span>

                            <span
                                onClick={() => removeMember(tag.id)}
                                style={{
                                    marginTop: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <SVGIcon
                                    size={14}
                                    src="/New UI/SVG/VscTrash.svg"
                                    color="#ff0000ab"
                                ></SVGIcon>
                            </span>
                        </div>
                    )}
                </>
            ) : (
                <Textfield
                    label=""
                    height={22}
                    autoFocus={true}
                    containerStyle={{ marginBottom: "0px" }}
                    customClass={styles.checkboxTextFiled}
                    props={{
                        value: label,
                        onChange: (e) => {
                            setLabel(e.target.value);
                        },
                        onBlur: (e) => {
                            if (e.target.value) {
                                renameTag(e);
                            } else {
                                e.target.focus();
                            }
                        },
                    }}
                    onKeyPress={(e) => {
                        if (e.key == "Enter") {
                            e.target.blur();
                        }
                    }}
                />
            )}
        </span>
    );
}

export default CheckBox;
