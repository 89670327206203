import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import ManageDomains from "./ManageDomains";
import mixpanel from "mixpanel-browser";
import { extractDomain } from "./constants";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import SVGIcon from "../../atoms/SVGIcon";
import Modal from "../../atoms/Modal/Modal";
import Button from "../../atoms/Button/Button";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { ClickAwayListener } from "@material-ui/core";
import Progress from "../../atoms/Progress/Progress";
import BuyMore from "./BuyMore";
import { getTrimmedDomainURL } from "../InterLinking/LinkingOnBoardFlow";
import { useLinkManagerMixpanel } from "../InterLinking/useLinkManagerMixpanel";

export const DomainChangeCases = {
    addDomain: "add",
    removeDomain: "remove",
    addGsc: "add_gsc",
    removeGsc: "remove_gsc",
    removeGscEverywhere: "remove_gsc_everywhere",
    updateDomain: "update_domain",
    setCurrentDomain: "set_current",
};

export const VerifiedStatus = ({ is_verified = 4, gsc_id = null }) => {
    return (
        <LightTooltip
            placement={"top"}
            title={
                is_verified == 1
                    ? "Domain connected"
                    : is_verified == 2
                    ? "Verification in progress"
                    : "Domain not connected"
            }
            arrow
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {is_verified == 1 ? (
                    <SVGIcon
                        src={"/New UI/SVG/check-circle-fill.svg"}
                        size={12}
                        style={{}}
                        color="#2fc5a0"
                    />
                ) : is_verified === 2 ? (
                    <FaClock size={12} color="rgb(255, 190, 87)" />
                ) : (
                    <BsExclamationCircleFill size={12} color="#FC5A5A" />
                )}
            </div>
        </LightTooltip>
    );
};

const ManageDomainsWrapper = ({
    cDomain = "",
    is_verified,
    gsc_id = null,
    list = [],
    onChange = () => {},
    onScreen = "",
    manageDomain = false,
    setManageDomain = () => {},
    domain_id = null,
    loading,
    quickList = {},
}) => {
    const { lMManageDomainCta } = useLinkManagerMixpanel();
    const [showMoreOpt, setShowMoreOpt] = useState(false);
    const [manageTooltipText, setManageTooltipText] =
        useState("Manage Domains");
    const [buyPagesModal, setBuyPagesModal] = useState(false);
    const [addFromUrl, setAddFromUrl] = useState(false);
    const [logoutModal, setLogoutModal] = useState({
        isShow: false,
        id: null,
    });
    const [logoutLoading, setLogoutLoading] = useState(false);
    const goto = useNavigation();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const add = !!queryParams.get("add");
        setManageDomain(add);
        if (add && onScreen) goto(onScreen, { replace: true });
    }, []);

    return (
        <>
            {manageDomain && (
                <Modal
                    setModal={() => {
                        if (!loading) setManageDomain(false);
                    }}
                >
                    <ManageDomains
                        arr={list}
                        setModal={setManageDomain}
                        setRemoveDomain={(id, gsc_id = null) => {
                            setLogoutModal({
                                isShow: true,
                                id: id,
                                gscId: gsc_id,
                            });
                        }}
                        setBuyPagesModal={setBuyPagesModal}
                        addFromUrl={addFromUrl}
                        onChange={onChange}
                        domain_id={domain_id}
                        isAdding={loading}
                        otherDomains={quickList?.TrafficAnalyser || []}
                        insideLM={true}
                        screen={"Manage Domains Modal"}
                    />
                </Modal>
            )}
            {logoutModal.isShow && (
                <Modal setModal={setLogoutModal}>
                    <div className={styles.logoutContainer}>
                        <h2 style={{ fontWeight: "600" }}>Are You Sure?</h2>
                        <div>
                            Disconnecting the domain will remove all the fixes
                            you have done with Link Manager. Are you sure?
                        </div>
                        <div className={styles.logoutBtns}>
                            <Button
                                handler={() =>
                                    setLogoutModal({
                                        isShow: false,
                                        id: null,
                                    })
                                }
                                text="Cancel"
                                width={150}
                                secondary={true}
                                style={{
                                    fontWeight: "var(--font-weight3)",
                                }}
                            />
                            <Button
                                handler={async () => {
                                    await onChange({
                                        type: DomainChangeCases.removeDomain,
                                        cId: logoutModal.id,
                                        gscId: logoutModal?.gscId,
                                        setLoader: setLogoutLoading,
                                    });
                                    setLogoutModal({
                                        isShow: false,
                                        id: null,
                                    });
                                }}
                                text="Disconnect"
                                width={150}
                                loading={logoutLoading}
                                style={{
                                    fontWeight: "var(--font-weight3)",
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {buyPagesModal?.isShow && (
                <BuyMore
                    setBuyPagesModal={setBuyPagesModal}
                    domainId={buyPagesModal?.domainId}
                    screen={"Manage Domains"}
                />
            )}

            <ClickAwayListener
                onClickAway={() => {
                    setShowMoreOpt(false);
                }}
            >
                <div
                    className={styles.currentDomainWrapper}
                    onClick={() => {
                        lMManageDomainCta({
                            ctaType: "Domains List Toggler",
                            screen: "Link Manager",
                            ctaUsed: "Domains List Toggler",
                        });
                        setShowMoreOpt(!showMoreOpt);
                        setManageTooltipText("");
                    }}
                >
                    <span
                        style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "#6b6b6b",
                        }}
                    >
                        Domain:{" "}
                    </span>
                    <img
                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${getTrimmedDomainURL(
                            cDomain,
                        )}&sz=32`}
                        alt=""
                    />
                    <div className={styles.domainName}>
                        <span
                            className={styles.blue}
                            style={{
                                color: "var(--link-color1)",
                                fontWeight: 500,
                            }}
                        >
                            {getTrimmedDomainURL(cDomain)}
                        </span>
                        &nbsp;&nbsp;
                        <VerifiedStatus
                            is_verified={is_verified}
                            gsc_id={gsc_id}
                        />
                    </div>
                    <div className={styles.moreActionsWrapper}>
                        <LightTooltip
                            arrow
                            title={manageTooltipText}
                            placement={"top"}
                        >
                            <div
                                className={styles.moreActions}
                                style={{
                                    visibility: showMoreOpt
                                        ? "hidden"
                                        : "visible",
                                }}
                                onMouseEnter={() => {
                                    setManageTooltipText("Manage Domains");
                                }}
                            >
                                <SVGIcon
                                    src={`/New UI/SVG/arrow-head-${
                                        showMoreOpt ? "up" : "down"
                                    }.svg`}
                                    style={{
                                        color: "inherit",
                                    }}
                                />
                            </div>
                        </LightTooltip>
                        {showMoreOpt && (
                            <div
                                className={styles.domainList}
                                style={{ right: "0px", left: "unset" }}
                            >
                                {!!list?.length ? (
                                    list?.map((dm, idx) => {
                                        return (
                                            <div
                                                className={styles.domainItem}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (dm?.id != domain_id) {
                                                        lMManageDomainCta({
                                                            ctaType:
                                                                "Domain Switch",
                                                            screen: "Link Manager",
                                                            ctaUsed:
                                                                "Domains List Toggler",
                                                            domainStatus:
                                                                dm?.is_verified ||
                                                                "-",
                                                            pCrawled:
                                                                dm?.stable_version_total_pages,
                                                            pTotal: dm?.stable_version_total_crawled_pages,
                                                        });

                                                        onChange({
                                                            type: DomainChangeCases.setCurrentDomain,
                                                            domain: {
                                                                ...dm,
                                                                domain_id:
                                                                    dm.id,
                                                            },
                                                        });
                                                    }
                                                    setShowMoreOpt(false);
                                                }}
                                            >
                                                <div className={styles.dmIcon}>
                                                    <img
                                                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${getTrimmedDomainURL(
                                                            dm.domain,
                                                        )}&sz=32`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    className={styles.dmName}
                                                    style={{ fontWeight: 500 }}
                                                >
                                                    {getTrimmedDomainURL(
                                                        dm.domain,
                                                    )}
                                                </div>

                                                <VerifiedStatus
                                                    is_verified={
                                                        dm?.is_verified
                                                    }
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>{cDomain}</div>
                                )}
                                <Button
                                    text={"Manage Domains"}
                                    handler={(e) => {
                                        lMManageDomainCta({
                                            ctaType: "Manage Domains",
                                            screen: "Link Manager",
                                            ctaUsed: "Manage Domains",
                                        });

                                        setShowMoreOpt(false);
                                        setManageDomain(true);
                                    }}
                                    height={32}
                                    width={160}
                                    style={{
                                        margin: "auto",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ClickAwayListener>
        </>
    );
};

export default ManageDomainsWrapper;
