import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const useHookReferalConstant = ({ referCode = "" }) => {
    const currency = useSelector((state) => state.auth.currency);

    useEffect(() => {}, [currency]);

    const REFERR_EARN_INR = "1500";
    const REFERR_EARN_USD = "19";
    const REFERR_EARN_VALUE =
        currency == "INR" ? "₹" + REFERR_EARN_INR : "$" + REFERR_EARN_USD;

    const REFERR_SHARE_URL = `https://www.scalenut.com?refer=${referCode}`;

    const REFERR_SHARE_TEXT = `Hello, I'd like to recommend Scalenut to you. If you have not heard of Scalenut yet, it is time you take notice. Scalenut is one of the most loved AI-powered SEO and Content Marketing platform in the market right now.
 
    If you sign up through this link, you will receive ${REFERR_EARN_VALUE} into your account post your first subscription. To claim now, go to ${REFERR_SHARE_URL}`;

    return {
        REFERR_EARN_VALUE,
        REFERR_SHARE_URL,
        REFERR_SHARE_TEXT,
    };
};

export default useHookReferalConstant;
