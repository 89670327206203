import React from "react";
import { ImArrowLeft } from "react-icons/im";
import { MdClose } from "react-icons/md";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import GenerateResults from "../GenerateResults/GenerateResults";
import styles from "./GenerateHistory.module.css";

function HistoryModal({ item, setLocalData, text, setModalData }) {
  console.log(item?.text);
  return (
    <div className={styles.modal}>
      <MdClose
        onClick={(e) => {
          setModalData(null);
        }}
        color={"#383e55"}
        className={styles.closeIcon}
      />

      <div className={styles.headingContainer}>
        <div>
          <h3>
            {" "}
            {item?.count} outputs generated for{" "}
            <span className={styles.cardKeyword}>
              {item?.useCaseName.split("_").join(" ").toLowerCase()}
            </span>{" "}
            {/* <span className={styles.cardKeyword}> {item?.brand}</span> */}
          </h3>
          <h5> Hint: Click on the card to edit copy</h5>
        </div>
      </div>
      <div className={styles.content}>
        {text.map((text) => (
          <GenerateResults
            key={text.uniqueId}
            result={text.text}
            favIcon={true}
            uniqueId={text.uniqueId}
            setLocalData={setLocalData}
            isFav={text.isFavorite}
            history={true}
          />
        ))}
      </div>
    </div>
  );
}

export default HistoryModal;
