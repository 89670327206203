import React, { Dispatch, SetStateAction, useState } from "react";
import LocalPagination from "../../../atoms/LocalPagination/LocalPagination";
import { ClickAwayListener } from "@material-ui/core";
import SVGIcon from "../../../atoms/SVGIcon";
import { CannibalizationRequestPayload } from "../useGscHook";
import { updateCannibalizationFilters } from "../../../../store/actions/userGscInfo";
import { useDispatch } from "react-redux";
const RowsPerPage = [10, 25, 50, 100];

type Props_InventoryBottomOptions = {
    customClass: { readonly [key: string]: string };
    pageCount: number;
    currentPage: number;
    handleCurrentPage: (value: SetStateAction<number>) => void;
    itemsPerPage: number;
    setItemsPerPage: Dispatch<SetStateAction<number>>;
};

const InventoryBottomOptions = ({
    customClass,
    handleCurrentPage,
    pageCount,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
}: Props_InventoryBottomOptions) => {
    const [isDropdownRows, setIsDropdownRows] = useState<boolean>(false);
    const [isDropdownColumns, setIsDropdownColumns] = useState<boolean>(false);
    const dispatch = useDispatch();
    const onChangePageHandler = (e: any) => {
        handleCurrentPage(e.selected);
        dispatch(
            updateCannibalizationFilters({
                currentPage: e.selected,
            }),
        );
    };

    return (
        <div className={customClass.bottomPagination}>
            <LocalPagination
                pageCount={pageCount}
                currentPage={currentPage}
                onPageChange={onChangePageHandler}
                styles2={customClass}
            />
            <div className={customClass.itemsPerPage}>
                <span className={customClass.itemsPerPage_text}>Show : </span>
                <ClickAwayListener onClickAway={() => setIsDropdownRows(false)}>
                    <div className={customClass.rowsPerPageWrapper}>
                        <div
                            className={customClass.dropdownCta_row}
                            onClick={() => setIsDropdownRows(!isDropdownRows)}
                        >
                            {itemsPerPage} rows
                            <SVGIcon
                                src={`/New UI/SVG/${
                                    isDropdownColumns
                                        ? "arrow-head-up"
                                        : "arrow-head-down"
                                }.svg`}
                                size={isDropdownColumns ? 12 : 11}
                                style={{ color: "#3F5575" }}
                            />
                        </div>
                        {isDropdownRows && (
                            <div className={customClass.rows}>
                                {RowsPerPage.map((rowVal) => {
                                    return (
                                        <div
                                            className={
                                                customClass.row +
                                                " " +
                                                (itemsPerPage == rowVal &&
                                                    customClass.selectedRow)
                                            }
                                            onClick={() => {
                                                handleCurrentPage(0);
                                                setItemsPerPage(rowVal);
                                                dispatch(
                                                    updateCannibalizationFilters(
                                                        {
                                                            itemsPerPage:
                                                                rowVal,
                                                        },
                                                    ),
                                                );
                                                setIsDropdownRows(false);
                                            }}
                                        >
                                            {rowVal + " rows"}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default InventoryBottomOptions;
