import React, { useState, useEffect, useRef, useContext } from "react";
import { IoIosSquareOutline } from "react-icons/io";
import { BsPlus, BsTagFill } from "react-icons/bs";
import { FiCheckSquare } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { MdClose } from "react-icons/md";
import styles from "./styles.module.css";
import CheckBox from "./CheckBox";
import useOnClickOutside from "../../atoms/Modal/useOnClickOutside";
import { TagsContext } from "../../Pages/TagsContext";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import useGscHook, {
    DomainObject,
} from "../../Pages/GoogleSearchConsole/useGscHook";
import ToggleButton from "../../atoms/CustomToggle/ToggleButtonModified";

type Props_DomainAccess = {
    allDomains: DomainObject[];
    setAllDomains: any;
    selectedTags: any;
    assignedDomains: DomainObject[];
    setAssignedDomains: React.Dispatch<DomainObject[]>;
};

const manageStyle = {
    position: "absolute",
    top: "20px",
    right: "15px",
    fontSize: "12px",
    fontWeight: " 600",
    color: "#024dc5",
};

function DomainsAccess({
    allDomains,
    setAllDomains,
    assignedDomains,
    setAssignedDomains,
}: Props_DomainAccess) {
    const [all, setAll] = useState(false);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [domainsMap, setDomainsMap] = useState<Object>();
    const ref = useRef(null);
    const refBox = useRef(null);
    const { getTrimmedDomainURL } = useGscHook();
    // const { getContainerRef } = useContext(TagsContext);

    // useEffect(() => {
    //     if (refBox.current) {
    //         getContainerRef(refBox.current, manageStyle, true);
    //     }

    //     console.log("container tags", refBox);
    // }, [refBox, open]);

    useEffect(() => {
        if (assignedDomains.length > 0) {
            const _map = new Object();
            assignedDomains.map((itm: DomainObject) => {
                // @ts-ignore
                _map[itm?.domain_name] = itm;
            });
            setDomainsMap(_map);
        }
    }, [assignedDomains]);
    useEffect(() => {
        if (search.trim()) {
            setOpen(true);
        }
    }, [search]);

    useEffect(() => {}, [search, allDomains]);

    const filterBySearch: (dm: DomainObject) => boolean = (dm) => {
        if (!!search?.length) {
            if (
                dm?.domain_name?.toLowerCase()?.includes(search.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const _allDomains = [...allDomains.filter(filterBySearch)];

    const handleDomainAccess: (dmObj: DomainObject, isOn: boolean) => void = (
        dmObj,
        isOn,
    ) => {
        // @ts-ignore
        setAssignedDomains((ps: DomainObject[]) => {
            let temp: DomainObject[] = [...ps];
            let idx = temp.findIndex(
                (obj) => dmObj.domain_name == obj.domain_name,
            );
            if (idx == -1) {
                temp.push({ ...dmObj, status: 1 });
            } else {
                temp[idx].status = isOn ? 0 : 1;
            }

            return temp;
        });
    };

    return (
        <div className={styles.domainsAccessWrapper}>
            <div>Which domains access do you want to give?</div>
            <div ref={ref} className={styles.domainsStyleWrapper}>
                <div className={styles.domainsStyle}>
                    <input
                        autoFocus={true}
                        disabled={loading}
                        value={search}
                        onChange={(e) =>
                            e.target.value.length < 21 &&
                            setSearch(e.target.value)
                        }
                        placeholder={"Search domain"}
                        type="text"
                        onFocus={() => setOpen(true)}
                    />
                </div>
                <div className={styles.domainsList}>
                    {!!_allDomains?.length &&
                        _allDomains?.map((dm) => {
                            console.log("domainObject", dm);
                            return (
                                <div className={styles.domainListItem}>
                                    <div className={styles.domainName}>
                                        <img
                                            src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                dm?.domain_name?.split(":")[1]
                                            }&sz=32`}
                                            width={"24px"}
                                        />
                                        {getTrimmedDomainURL(dm?.domain_name)}
                                    </div>
                                    <div className={styles.domainStatus}>
                                        <ToggleButton
                                            label={""}
                                            on={
                                                !!domainsMap?.hasOwnProperty(
                                                    dm?.domain_name,
                                                ) &&
                                                // @ts-ignore
                                                !!domainsMap[dm?.domain_name]
                                                    ?.status
                                            }
                                            setOn={() => {
                                                handleDomainAccess(
                                                    dm,
                                                    !!domainsMap?.hasOwnProperty(
                                                        dm?.domain_name,
                                                    ) &&
                                                        // @ts-ignore
                                                        !!domainsMap[
                                                            dm?.domain_name
                                                        ]?.status,
                                                );
                                            }}
                                            bg={{
                                                color: "rgba(1, 77, 197,0.4)",
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default DomainsAccess;
