import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useContext, useRef, useState, useCallback } from "react";
import { useEffect } from "react";
import SVGIcon from "../../../../atoms/SVGIcon";
import styles from "./FirstDraft.module.css";
import { LightTooltip } from "../../../../atoms/Tooltip/tooltip";
import { BiChevronDown } from "react-icons/bi";
import { CruiseContext, CruiseState } from "../StepsWizard";
import { CRUISE_TYPES } from "../useCruiseHook";
import { useSelector } from "react-redux";
import ReportContext from "../../../Research/ReportContext";
import Quill from "quill";

const Delta = Quill.import("delta");
import BetterTable from "../../../../organisms/TextEditor/QuillBetterTable/quill-better-table";
import Showdown from "../../../../../lib/showdownmin";

Quill.register(
    {
        "modules/better-table": BetterTable,
    },
    true,
);

const markdownToHtml = (text) => {
    const converter = new Showdown.Converter({
        tables: true,
        simpleLineBreaks: true,
    });
    const html = converter.makeHtml(text || "");
    return html;
};

const htmlToMarkdown = (html) => {
    const converter = new Showdown.Converter({
        tables: true,
        simpleLineBreaks: true,
    });
    return converter.makeMarkdown(html || "");
};
const ParagraphBox = ({
    tag,
    generateParagraph = () => {},
    setPara,
    text,
    index,
    isShimmer = false,
    disabled,
}) => {
    const ref = useRef();
    const ref1 = useRef();
    const textareaRef = useRef();
    const stepsData = useContext(CruiseState);
    const { reportInfo } = useContext(ReportContext);
    const dispatchStepsData = useContext(CruiseContext);
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);
    const [textEditor, setTextEditor] = useState(null);
    const indexRef = useRef(index);
    indexRef.current = index;

    useEffect(() => {
        if (textEditor) {
            textEditor.setContents(
                textEditor.clipboard.convert({ html: markdownToHtml(text) }),
            );
        }
    }, [textEditor, text]);

    useEffect(() => {
        if (
            text &&
            ref.current &&
            stepsData.isScroll &&
            (auth.segment == 2 ||
                auth.segment == 3 ||
                auth.segment == 4 ||
                auth.segment == 5 ||
                auth.segment == 6) &&
            !reportInfo?.is_premium
        ) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    }, [text, stepsData?.isScroll]);

    useEffect(() => {
        if (text && textareaRef.current) {
            textareaRef.current.style.backgroundColor =
                "rgba(1, 77, 197, 0.07)";
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.style.backgroundColor = "";
                }
            }, 2000);
        }
    }, [text]);

    useEffect(() => {
        if (ref1.current) {
            const updateData = (...e) => {
                if (quill && !quill.hasFocus()) {
                    setPara(
                        htmlToMarkdown(quill.getSemanticHTML()),
                        indexRef.current,
                    );
                }
            };

            const quill = new Quill(ref1.current, {
                theme: "bubble",
                modules: {
                    toolbar: [["bold"], ["link"], ["clean"]],
                    table: false,
                    "better-table": {
                        tools: false,
                    },
                    keyboard: {
                        bindings: BetterTable.keyboardBindings,
                    },
                },

                readOnly: disabled,
            });
            quill.clipboard.addMatcher(
                "h1,h2,h3,h4,h5,h6",
                function (node, delta) {
                    return delta.compose(
                        new Delta().retain(delta.length(), { header: false }),
                    );
                },
            );
            const tooltip = quill.theme.tooltip;
            const input = tooltip.root.querySelector("input[data-link]");
            input.dataset.link = "https://scalenut.com";
            setTextEditor(quill);
            if (!disabled) quill.on("selection-change", updateData);
        }
    }, [ref1]);

    return (
        <div ref={ref} className={styles.paraBox}>
            <div className={styles.paraWraper}>
                <div
                    style={isShimmer ? { display: "none" } : {}}
                    ref={ref1}
                ></div>
                {!isShimmer ? (
                    <>
                        {/* <TextareaAutosize
                            ref={textareaRef}
                            type="text"
                            placeholder={""}
                            className={styles.paratext}
                            value={input}
                            onBlur={(e) =>
                                e.target.value.trim() != text
                                    ? setPara(e.target.value.trim(), index)
                                    : ""
                            }
                            onChange={(e) => setInput(e.target.value)}
                            style={{ width: "100%", lineHeight: "1.6" }}
                            readOnly={disabled}
                            onFocus={() => {
                                if (stepsData.isScroll) {
                                    dispatchStepsData({
                                        type: CRUISE_TYPES.SET_SCROLL,
                                        state: false,
                                    });
                                }
                            }}
                            // autoFocus={value == ""}
                        /> */}
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
};

const Loading = () => (
    <>
        <Skeleton animation="wave" width={"100%"} />
        <Skeleton animation="wave" width={"80%"} />
        <Skeleton animation="wave" width={"60%"} />
        <Skeleton animation="wave" width={"90%"} />
    </>
);

export const ParagraphRegens = ({
    arr = [],
    isGenerating,
    setHeadingGenPara,
}) => {
    return (
        <div
            style={{
                borderRadius: 10,
                marginLeft: 35,
            }}
        >
            {isGenerating ? (
                <div className={styles.regenPara}>
                    <ParagraphBox isShimmer={true} text={""} disabled={true} />
                </div>
            ) : (
                ""
            )}
            {arr.map((para, i) => (
                <LightTooltip title="Click to replace" arrow placement="bottom">
                    <div
                        onClick={() => setHeadingGenPara(para.text, i)}
                        className={styles.regenPara}
                    >
                        <SVGIcon
                            src="/New UI/SVG/regenrate Icon.svg"
                            color="var(--primary-blue)"
                        />

                        <ParagraphBox
                            isShimmer={para.loading}
                            text={para.text}
                            disabled={true}
                        />
                    </div>
                </LightTooltip>
            ))}
        </div>
    );
};

export default ParagraphBox;
