import React, { useEffect, useRef, useState, useMemo } from "react";
import styles from "./stepCard.module.css";
import useHelperFunctions from "../../../helperFunctions";
import OutlineBuilder from "./OutlineBuilder";
import GeneratedContent from "./GeneratedContent";
import FirstDraft from "./FirstDraft/FirstDraft";
import { Autocomplete } from "@material-ui/lab";
import {
    Chip,
    LinearProgress,
    makeStyles,
    TextareaAutosize,
    TextField,
} from "@material-ui/core";
import SVGIcon from "../../../atoms/SVGIcon";
import { v4 as uuidv4 } from "uuid";
import TalkingPointsStep from "./TalkingPointsSteps/TalkingPointsStep";
import LeftOutline from "./FirstDraft/LeftOutline";
import TextShortner from "../../../atoms/TextShortner/TextShortner";
import { useSelector } from "react-redux";
import { isMobile } from "../../../../utility/helperFun";
import { useCallback } from "react";
import { NLPConfigure } from "../../TextEditor/Configure";
import ToggleButton from "../../../atoms/CustomToggle/ToggleButtonModified";
import { useContext } from "react";
import ReportContext from "../../Research/ReportContext";
import { CRUISE_TYPES, useCruiseHook } from "./useCruiseHook";
import {
    OUTLINE_EVENTS_TYPES,
    STEPS_META_INFO,
    PREMIUM_STEPS_META_INFO,
} from "./cruiseConstant";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import ContextStep from "./ContextStep";
import TitleStep from "./TitleStep";
import AdditionalKeywords from "./AdditionalKeywords";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import VideoModal from "../../../Pages/NewDashboard/VideoModal";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";

let temp = null;

const StepCard = ({
    stepsData = {},
    dispatchStepsData = () => {},
    step,
    reportInfo,
    aiGenerated = [],
    setAiGenerated,
    topRankOutlines,
    topRanked,
    addFAQ,
    setGenH2List,
    setGenFaqList,
    genFaqList,
    genH2List,

    generateOutlines,
    handleNextPreviousActions = () => {},
    conclusionCta = {},
    setConclusionCta = () => {},
    loaderFWP = false,
    isGenTitleLoaded = false,
    setIsGenTitleLoaded = () => {},
    titleSessionId = -1,
    tempGuideLineData = "",
    realtimeRating,
    setRealtimeRatings,
}) => {
    const auth = useSelector((state) => state.auth);

    let lastStep = 4;

    const [nlpToggle, setNlpToggle] = useState(false);
    const [switchConfigure, setSwitchConfigure] = useState(false);
    const [addHeading, setAddHeading] = useState({ text: "", type: "" });
    const [metaInfo, setMetaInfo] = useState(STEPS_META_INFO);
    const is_report_created = useMemo(() => reportInfo.is_completed, []);
    const NLPConfigureContainer = useRef();

    const firstDraftRef = useRef(null);

    const [isStatsShow, setIsStatsShow] = useState(false);

    function handleGenTitle(data) {
        //set title from GeneratedContent (Titles)
        dispatchStepsData({
            type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
            field: "title",
            data,
        });
    }

    async function handleDataOnBlur(data, field) {
        dispatchStepsData({
            type: CRUISE_TYPES.EDIT_CONTEXT_TITLE,
            field,
            data,
        });
    }

    return (
        <div className={`${styles.mainContainer}`}>
            <div
                className={styles.leftContainer}
                style={{
                    width: step == 2 ? "50%" : "",
                    maxWidth: step == 0 ? "50%" : "",
                    margin: step == 3 ? "0px" : "",
                }}
            >
                {metaInfo.map((stepCard, i) => {
                    if (i == step)
                        return (
                            <div
                                className={
                                    styles.container +
                                    " " +
                                    (i == step && styles.activeStep) +
                                    " " +
                                    (i < 2 && styles.container__override) +
                                    " " +
                                    (reportInfo?.is_premium &&
                                        styles.premiumActiveStep)
                                }
                                id={i}
                            >
                                <div
                                    style={step == 3 ? { width: "54%" } : {}}
                                    className={styles.stepCard__head}
                                >
                                    <h3 className={styles.stepTitle}>
                                        {stepCard.title}

                                        {step == 1 && (
                                            <>
                                                <span
                                                    className={styles.required}
                                                >
                                                    *
                                                </span>

                                                <LightTooltip
                                                    title={metaInfo[step].info}
                                                    arrow
                                                >
                                                    <div>
                                                        <SVGIcon
                                                            color="#2196f3"
                                                            size={18}
                                                            src={
                                                                "/New UI/SVG/info.svg"
                                                            }
                                                        />
                                                    </div>
                                                </LightTooltip>
                                            </>
                                        )}
                                        {step >= 2 && (
                                            <LightTooltip
                                                title={metaInfo[step].info}
                                                arrow
                                            >
                                                <div>
                                                    <SVGIcon
                                                        color="#2196f3"
                                                        size={18}
                                                        src={
                                                            "/New UI/SVG/info.svg"
                                                        }
                                                    />
                                                </div>
                                            </LightTooltip>
                                        )}

                                        {step == 2 && <HighlightKeyTerm />}
                                    </h3>
                                </div>
                                {step == 0 ? (
                                    <>
                                        <ContextStep
                                            handleDataOnBlur={handleDataOnBlur}
                                            stepsData={stepsData}
                                            setRealtimeRatings={
                                                setRealtimeRatings
                                            }
                                        />
                                    </>
                                ) : step == 1 ? (
                                    <TitleStep
                                        handleDataOnBlur={handleDataOnBlur}
                                        stepsData={stepsData}
                                        aiGenerated={aiGenerated}
                                    />
                                ) : step == 2 ? (
                                    <OutlineBuilder
                                        tempGuideLineData={tempGuideLineData}
                                        stepsData={stepsData}
                                        dispatchStepsData={dispatchStepsData}
                                        addHeading={addHeading}
                                        setAddHeading={setAddHeading}
                                        addFAQ={addFAQ}
                                        setGenH2List={setGenH2List}
                                        genH2List={genH2List}
                                        setGenFaqList={setGenFaqList}
                                        genFaqList={genFaqList}
                                        writeH2FAQ={() => {}}
                                        removeH2Faq={() => {}}
                                        title={stepsData.title}
                                        topRankOutlines={topRankOutlines}
                                        nlpToggle={nlpToggle}
                                        setConclusionCta={setConclusionCta}
                                        conclusionCta={conclusionCta}
                                        generateOutlines={generateOutlines}
                                    />
                                ) : !reportInfo?.is_premium &&
                                  auth.segment != 3 &&
                                  auth.segment != 4 &&
                                  auth.segment != 5 &&
                                  auth.segment != 6 &&
                                  step == 3 ? (
                                    <>
                                        <TalkingPointsStep
                                            isStatsShow={isStatsShow}
                                            headings={
                                                stepsData.outlines.headings
                                            }
                                            questions={
                                                stepsData.outlines.questions
                                            }
                                            report_title={
                                                stepsData.title
                                                    ? stepsData.title
                                                    : reportInfo.title
                                            }
                                            updateHeadings={() => {}}
                                            title={stepsData.title}
                                            updateQuestions={() => {}}
                                        />
                                    </>
                                ) : (
                                    <LeftOutline
                                        headings={stepsData.outlines.headings}
                                        questions={stepsData.outlines.questions}
                                        realtimeRating={realtimeRating}
                                    />
                                )}
                            </div>
                        );
                })}
            </div>
            {step == 0 && !switchConfigure && (
                <div
                    className={`${styles.rightContainerAnimation} ${styles.premiumRightContainerAnimation}`}
                    // style={{ background: "white" }}
                >
                    <>
                        <div className={styles.animationBox}>
                            {reportInfo.reportLoading ? (
                                <ReportLoadingUI />
                            ) : is_report_created === 0 ? (
                                <>
                                    <div className={styles.reportLoaded}>
                                        <div
                                            className={styles.animationBoxDone}
                                        >
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/IoMdCheckmark.svg"
                                                }
                                                size={50}
                                                style={{}}
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.reportLoaded__Text
                                            }
                                        >
                                            <div>Your SEO Report is Ready!</div>
                                            <div>
                                                Click on{" "}
                                                <span
                                                    onClick={() =>
                                                        handleNextPreviousActions(
                                                            true,
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration:
                                                            "underline",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Create Title
                                                </span>{" "}
                                                to proceed
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </>
                </div>
            )}

            {step == 0 && !reportInfo.reportLoading && switchConfigure && (
                <RightContainer NLPConfigureContainer={NLPConfigureContainer} />
            )}

            {step > 0 && step < 2 && (
                <div
                    className={`${styles.rightContainer} ${
                        reportInfo?.is_premium && styles.premiumRightContainer
                    }`}
                >
                    {step != 0 && (
                        <GeneratedContent
                            topRanked={topRanked}
                            aiGenerated={aiGenerated}
                            handleGenTitle={handleGenTitle}
                            step={step}
                            setAiGenerated={setAiGenerated}
                            stepsData={stepsData}
                            isGenTitleLoaded={isGenTitleLoaded}
                            setIsGenTitleLoaded={setIsGenTitleLoaded}
                            titleSessionId={titleSessionId}
                        />
                    )}
                </div>
            )}

            {step == lastStep && (
                <div
                    onWheel={(e) => {
                        if (stepsData.isScroll) {
                            dispatchStepsData({
                                type: CRUISE_TYPES.SET_SCROLL,
                                state: false,
                            });
                        }
                    }}
                    className={styles.FirstDraft__right}
                    ref={firstDraftRef}
                >
                    <FirstDraft
                        realtimeRating={realtimeRating}
                        tempGuideLineData={tempGuideLineData}
                    />
                </div>
            )}
        </div>
    );
};

const RightContainer = ({ NLPConfigureContainer }) => {
    return (
        <div
            className={styles.rightContainer}
            style={{
                paddingTop: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
            }}
            ref={NLPConfigureContainer}
        ></div>
    );
};

const HighlightKeyTerm = () => {
    const { setReportData, highlight_keyTerm } = useContext(ReportContext);
    const [toggle, setToggle] = useState(highlight_keyTerm);
    const { mpTrackCruiseHighlightKeyTerms } = useMixpanelHook();

    useEffect(() => {
        setReportData((ps) => ({ ...ps, highlight_keyTerm: toggle }));
    }, [toggle]);

    function handleToggle(e) {
        mpTrackCruiseHighlightKeyTerms({ status: !toggle });
        setToggle(!toggle);
    }

    return (
        <LightTooltip
            title="The terms with a green background contain NLP-powered key terms; prioritizing these terms will result in an increase in SEO score."
            arrow
        >
            <div className={styles.highlight_container}>
                Highlight Key Terms
                <ToggleButton
                    label={""}
                    on={toggle}
                    setOn={handleToggle}
                    // bg={{ color: "rgb(241, 244, 253)" }}
                    btnStyle={"#014dc5"}
                />
            </div>
        </LightTooltip>
    );
};

export default StepCard;

const ReportLoadingUI = () => {
    const { reportInfo } = useContext(ReportContext);

    const TAKES_TIME =
        reportInfo.competitors_count == 10
            ? 30
            : reportInfo.competitors_count == 20
            ? 45
            : 60;
    const [time, setTIme] = useState(TAKES_TIME);
    const loadingText = [
        "Generating Competition Overview",
        "Fetching Outlines",
        "Extracting Related Key Terms",
        "Fetching Frequently Asked Questions",
        "Packaging",
    ];

    useEffect(() => {
        if (time > 1)
            setTimeout(() => {
                setTIme(time - 1);
            }, 1000);
    }, [time]);
    return (
        <>
            <div className={styles.loadingInfoText__right}>
                <div className={styles.stepTitle}>
                    Building Your SEO Article...
                </div>
                <div className={styles.loadingInfoText__right__line2}>
                    While we collect SEO information, you can provide more
                    context (on the left) to AI about your article.
                </div>
                <div className={styles.loadingInfoText__right__line3}>
                    Remaining time:{" "}
                    <span
                        style={{
                            color: "var(--primary-blue)",
                        }}
                    >
                        {time != 1 ? <>{time} seconds</> : "Almost Done!"}
                    </span>
                </div>
            </div>
            <div className={styles.animationGrf}>
                <img height={200} width={200} src="/New UI/Images/PH-T1.gif" />
                <div>
                    {
                        loadingText[
                            loadingText.length -
                                Math.ceil(time / (TAKES_TIME / 5))
                        ]
                    }
                    ...
                </div>
            </div>

            <VideoModal
                buttonForm={true}
                link={"https://www.youtube.com/embed/JIvN82iEmlE"}
                modal={true}
                buttonProps={{
                    text: "Learn how to use Cruise Mode",
                    style: {
                        backgroundColor: "#eaecee",
                        color: "#2a4266",
                        fontSize: 13,
                        marginLeft: "auto",
                    },
                    width: "230px",
                    height: 26,
                }}
            />
        </>
    );
};
