import React from "react";
import styles from "./styles.module.css";

function BackLink({ text, style, handler }) {
  return (
    <div onClick={handler} style={{ ...style }} className={styles.container}>
      {text}
    </div>
  );
}

export default BackLink;
