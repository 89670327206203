import React, { useState, useEffect, memo, useRef } from "react";
import styles from "./FixItModule.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
import { platformIcon } from "../../../molecules/Creator/Overview/PeopleQuestionsOverview";
import { Skeleton } from "@material-ui/lab";
import { debounceWrapper } from "../../../../utility/helperFun";
const FeaturedSnippetFixIt = memo(
    ({
        q,
        reportInfo,
        answer = "",
        setAnswer = () => {},
        currentHeadingIndex,
    }) => {
        const [loading, setLoading] = useState(false);
        const { postData } = useHelperFunctions();
        let timeoutRef = useRef(null);

        useEffect(() => {
            if (q?.question && !answer[currentHeadingIndex])
                debounceWrapper(
                    () => generateContent(q?.question),
                    500,
                    timeoutRef,
                );
        }, [q?.question]);

        const generateContent = async (desc) => {
            setLoading(true);
            let h1 = document?.quill?.editor?.root?.getElementsByTagName("h1");
            const res = await postData({
                url: "/api/generate",
                payload: {
                    question: desc,
                    title: h1.length > 0 ? h1[0].innerText : "",
                    keyword: reportInfo.title,
                    report_id: reportInfo.report_id,
                    useCase: 168,
                },
            });
            if (res.status == 200) {
                let newArr = [...answer];
                newArr[currentHeadingIndex] =
                    res?.data?.output[0]?.content?.trim();
                setAnswer(newArr);
            }

            setLoading(false);
        };

        return (
            <div className={styles.paaChildContainer}>
                <div>{platformIcon(q?.platform, true)}</div>
                <div className={styles.paaChildContainer_queAns}>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                        }}
                        className={styles.que}
                    >
                        {q?.question}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                        }}
                        className={styles.ans}
                    >
                        {loading ? (
                            <div style={{ width: "100%" }}>
                                <div>
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        height="25px"
                                        style={{
                                            marginBottom: "-5px",
                                            background:
                                                "linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.64) 47.58%, rgba(255, 255, 255, 0.16) 100%)",
                                        }}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        variant="text"
                                        width="90%"
                                        height="25px"
                                        style={{
                                            marginBottom: "-5px",
                                            background:
                                                "linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.64) 47.58%, rgba(255, 255, 255, 0.16) 100%)",
                                        }}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        variant="text"
                                        width="95%"
                                        height="25px"
                                        style={{
                                            marginBottom: "-5px",
                                            background:
                                                "linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.64) 47.58%, rgba(255, 255, 255, 0.16) 100%)",
                                        }}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        variant="text"
                                        width="92%"
                                        height="25px"
                                        style={{
                                            background:
                                                "linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.64) 47.58%, rgba(255, 255, 255, 0.16) 100%)",
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            answer[currentHeadingIndex]
                        )}
                    </div>
                    <Button
                        handler={() => generateContent(q?.question)}
                        disabled={loading}
                        text={"Regenerate ans. "}
                        iconReverse={true}
                        height={24}
                        width={135}
                        Icon={() => (
                            <SVGIcon
                                src={"/New UI/SVG/new-refresh.svg"}
                                color={"#ffffffa3"}
                            />
                        )}
                        customClass={styles.regenAns}
                    />
                </div>
            </div>
        );
    },
);

export default FeaturedSnippetFixIt;
