import {
    AUTH_LOGOUT,
    AUTH_LOGOUT_OTHERINFO,
    UPDATE_OTHER_INFO,
    UPDATE_USER_SETTINGS,
} from "../actiontypes";

const initialState = {
    balance: 0,
    discount: 0,
    free_trial_status: "already expired",
    isContent: 0,
    isGenerate: 0,
    isSocial: 0,
    plan: 1,
    stripe_id: "",
    isCreator: null,
    subscriptionInfo: {},
    userIntegrationInfo: [],
    isLoaded: 0,
    userSettings: {},
    webtune: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_OTHER_INFO:
            return {
                ...state,
                ...action.data,
            };

        case UPDATE_USER_SETTINGS:
            return {
                ...state,
                userSettings: action.data,
            };

        case AUTH_LOGOUT_OTHERINFO:
            return initialState;
        default:
            return state;
    }
}
