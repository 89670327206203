import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import styles from "./TalkingPointsStep.module.css";

import useMixpanelHook from "../../../../../utility/useMixpanelHook";

import RenderInChucks from "../../../../atoms/RenderInChucks";
import StatsFact from "../StatsFact";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import ReportContext from "../../../Research/ReportContext";
import { CruiseContext, CruiseState } from "../StepsWizard";
import TPOutline from "./TPOutline";
import { CRUISE_TYPES } from "../useCruiseHook";
import { Outline, WP } from "../classes";
import { droppableIds, droppableTypes } from "../cruiseConstant";
import { v4 } from "uuid";
import useHelperFunctions from "../../../../helperFunctions";

const TalkingPointsStep = ({ report_title, title, isStatsShow = false }) => {
    const { stats, reportInfo, isStatsAllowed } = useContext(ReportContext);
    const dispatchStepsData = useContext(CruiseContext);
    const stepsData = useContext(CruiseState);
    const [toggleDropType, setToggleDropType] = useState("");
    const { mpTrackCruiseAddition } = useMixpanelHook();
    const { getData } = useHelperFunctions();
    const ref = useRef(null);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (stats.length) setData(stats);
    }, [stats]);

    const generateWPs = useCallback(async () => {
        const res = await getData({
            url: `/api/contentDraft/v2/generate-writing-points?report_id=${reportInfo.report_id}`,
        });

        if (res.status == 200) {
            /** @param {Outline} outline */
            const successCallback = (outline) => {
                const arr = [];

                outline.writingPoints.forEach((item) => {
                    const wp = new WP({
                        id: item.id,
                        outline_id: outline.outline_id,
                        ...item,
                        citations: item?.citations || [],
                    });
                    arr.push(wp);
                });

                /**
                 * @type {Array<Outline>}
                 */

                dispatchStepsData({
                    type: CRUISE_TYPES.SET_WPS,
                    parentTag: outline.tag,
                    wp: arr,
                    wp_order: outline.wp_order,
                    outline_id: outline.outline_id,
                });
            };

            res.data.forEach(successCallback);
        }

        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: false,
        });
    }, []);

    useEffect(() => {
        dispatchStepsData({
            type: CRUISE_TYPES.SET_LOADING,
            state: true,
        });
        setTimeout(() => {
            setImmediate(generateWPs);
        }, 500);
    }, []);

    /**
     * @param {import("react-beautiful-dnd").DropResult} res */
    const onDragEnd = (res) => {
        if (!res.source || !res.destination) {
            return;
        }
        if (res.source.droppableId == res.destination.droppableId) {
            if (res.source.droppableId == droppableIds.stats) {
                return;
            } else if (
                res.source.droppableId.includes(droppableTypes.outlineH2)
            ) {
                const parentIndex = res.source.droppableId.split("_")[1];
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_DRAG_WP,
                    field: Outline.type.heading,
                    oldIndex: res.source.index,
                    newIndex: res.destination.index,
                    parentIndex: parentIndex,
                    parentTag: Outline.tags.h2,
                });
            } else if (
                res.source.droppableId.includes(droppableTypes.outlineH3)
            ) {
                const [, h2Index, parentIndex] =
                    res.source.droppableId.split("_");
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_DRAG_WP,
                    field: Outline.type.heading,
                    oldIndex: res.source.index,
                    newIndex: res.destination.index,
                    parentIndex: parentIndex,
                    h2Index,
                    parentTag: Outline.tags.h3,
                });
            } else {
                const parentIndex = res.source.droppableId.split("_")[1];
                dispatchStepsData({
                    type: CRUISE_TYPES.HANDLE_DRAG_WP,
                    field: Outline.type.question,
                    oldIndex: res.source.index,
                    newIndex: res.destination.index,
                    parentIndex: parentIndex,
                });
            }
        } else if (res.source.droppableId == droppableIds.stats) {
            const stat = data[res.source.index];

            if (
                res.destination.droppableId.includes(droppableTypes.outlineH2)
            ) {
                const parentIndex = res.destination.droppableId.split("_")[1];
                const outline_id = stepsData.outlines.headings[parentIndex].id;

                const wp = new WP({
                    outline_id: outline_id,
                    id: v4(),
                    tp: stat.statistic,
                    tpType: WP.types.stats,
                    citations: [stat.url],
                });
                dispatchStepsData({
                    type: CRUISE_TYPES.ADD_ON_INDEX_WP,
                    field: Outline.type.heading,
                    data: wp,
                    index: res.destination.index,
                    parentIndex: parentIndex,
                    parent_id: outline_id,
                    parentTag: Outline.tags.h2,
                });
            } else if (
                res.destination.droppableId.includes(droppableTypes.outlineH3)
            ) {
                const [, h2Index, parentIndex] =
                    res.destination.droppableId.split("_");
                const outline_id =
                    stepsData.outlines.headings[h2Index].outlineH3s[parentIndex]
                        .id;
                const wp = new WP({
                    outline_id: outline_id,
                    id: v4(),
                    tp: stat.statistic,
                    tpType: WP.types.stats,
                    citations: [stat.url],
                });

                dispatchStepsData({
                    type: CRUISE_TYPES.ADD_ON_INDEX_WP,
                    field: Outline.type.heading,
                    data: wp,
                    index: res.destination.index,
                    parentIndex: parentIndex,
                    parent_id: outline_id,
                    h2Index,
                    parentTag: Outline.tags.h3,
                });
            } else if (
                res.destination.droppableId.includes(droppableIds.questions)
            ) {
                const parentIndex = res.destination.droppableId.split("_")[1];
                const outline_id = stepsData.outlines.questions[parentIndex].id;

                const wp = new WP({
                    outline_id: outline_id,
                    id: v4(),
                    tp: stat.statistic,
                    tpType: WP.types.stats,
                    citations: [stat.url],
                });
                dispatchStepsData({
                    type: CRUISE_TYPES.ADD_ON_INDEX_WP,
                    field: Outline.type.question,
                    index: res.destination.index,
                    data: wp,
                    parentIndex: parentIndex,
                    parent_id: outline_id,
                    parentTag: Outline.tags.question,
                });
            }
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div ref={ref} className={styles.talkingPointsContainer}>
                <div className={styles.talkingPointsSection}>
                    <div className={styles.sectionHeading}>
                        <div
                            className={styles.sectionName}
                            style={{ color: "#2a4266c4" }}
                        >
                            {title}
                        </div>
                    </div>
                </div>

                <div className={styles.talkingPointsSection}>
                    {
                        <div className={styles.sectionBody}>
                            <RenderInChucks list={stepsData.outlines.headings}>
                                {(heading, index) => (
                                    <TPOutline
                                        index={index}
                                        data={heading}
                                        key={heading.id}
                                        title={stepsData.title}
                                    />
                                )}
                            </RenderInChucks>
                        </div>
                    }
                </div>

                <div className={styles.talkingPointsSection}>
                    <div
                        className={styles.sectionName}
                        style={{ color: "#2a4266c4" }}
                    >
                        Frequently Asked Questions
                    </div>

                    <div className={styles.sectionBody}>
                        <RenderInChucks list={stepsData.outlines.questions}>
                            {(question, index) => {
                                return (
                                    <TPOutline
                                        key={question.id}
                                        index={index}
                                        data={question}
                                        title={stepsData.title}
                                    />
                                );
                            }}
                        </RenderInChucks>
                    </div>
                </div>
            </div>

            {isStatsAllowed ? (
                <StatsFact
                    setToggleDropType={setToggleDropType}
                    toggleDropType={toggleDropType}
                    stepsData={stepsData}
                    {...{ data, setData }}
                />
            ) : (
                ""
            )}
        </DragDropContext>
    );
};

export default TalkingPointsStep;
