import React from "react";
import styles from "./account.module.css";

import Header from "../../componentsV2/organisms/Header/Header";

const NavHeader = ({
    title,
    userDropDown,
}: {
    title?: string;
    userDropDown?: boolean;
}) => {
    return (
        <div className={styles.navHeaderBar}>
            <h2>
                {title
                    ? title
                    : window.location.pathname.includes("/account/plans")
                    ? "Subscriptions"
                    : window.location.pathname.includes("/integration")
                    ? "App Integrations"
                    : "My Account"}
            </h2>
            {userDropDown && <Header />}
        </div>
    );
};

export default NavHeader;
