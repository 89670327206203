import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

import "./style.css";
const ValidationSuccess = ({ msg }) => {
  return (
    <div>
      {msg && msg.toLowerCase().includes("successfully") ? (
        <Alert severity="success">
          {msg} Please{" "}
          <Link to="/auth/login">
            {" "}
            <u> LOGIN </u>
          </Link>
          to continue
        </Alert>
      ) : (
        <Alert severity="success">{msg}</Alert>
      )}
    </div>
  );
};

export default ValidationSuccess;
