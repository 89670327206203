import Showdown from "../../../lib/showdownmin";

Showdown.subParser(
    "makeMarkdown.skip",
    function (node, options, globals, spansOnly) {
        "use strict";

        var txt = "";
        if (node.hasChildNodes()) {
            var children = node.childNodes,
                childrenLength = children.length;
            for (var i = 0; i < childrenLength; ++i) {
                txt += Showdown.subParser("makeMarkdown.node")(
                    children[i],
                    options,
                    globals,
                    spansOnly,
                );
            }
        }

        // some text normalization
        txt = txt.trim();

        return txt;
    },
);

Showdown.subParser(
    "makeMarkdown.node",
    function (node, options, globals, spansOnly) {
        "use strict";
        spansOnly = spansOnly || false;
        var txt = "";
        // edge case of text without wrapper paragraph
        if (node.nodeType === 3) {
            return Showdown.subParser("makeMarkdown.txt")(
                node,
                options,
                globals,
            );
        }
        // HTML comment
        if (node.nodeType === 8) {
            return "<!--" + node.data + "-->\n\n";
        }
        // process only node elements
        if (node.nodeType !== 1) {
            return "";
        }
        var tagName = node.tagName.toLowerCase();
        switch (tagName) {
            //
            // BLOCKS
            //
            case "h1":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.header")(
                            node,

                            options,
                            globals,
                            1,
                        ) + "\n\n";
                }
                break;
            case "h2":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.header")(
                            node,

                            options,
                            globals,
                            2,
                        ) + "\n\n";
                }
                break;
            case "h3":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.header")(
                            node,

                            options,
                            globals,
                            3,
                        ) + "\n\n";
                }
                break;
            case "h4":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.header")(
                            node,

                            options,
                            globals,
                            4,
                        ) + "\n\n";
                }
                break;
            case "h5":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.header")(
                            node,

                            options,
                            globals,
                            5,
                        ) + "\n\n";
                }
                break;
            case "h6":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.header")(
                            node,

                            options,
                            globals,
                            6,
                        ) + "\n\n";
                }
                break;
            case "p":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.paragraph")(
                            node,

                            options,
                            globals,
                        ) + "\n\n";
                } else {
                    txt = Showdown.subParser("makeMarkdown.skip")(
                        node,
                        options,
                        globals,
                        spansOnly,
                    );
                }
                break;
            case "blockquote":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.blockquote")(
                            node,

                            options,
                            globals,
                        ) + "\n\n";
                }
                break;
            case "hr":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.hr")(
                            node,

                            options,
                            globals,
                        ) + "\n\n";
                }
                break;
            case "ol":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.list")(
                            node,

                            options,
                            globals,
                            "ol",
                        ) + "\n\n";
                }
                break;
            case "ul":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.list")(
                            node,

                            options,
                            globals,
                            "ul",
                        ) + "\n\n";
                }
                break;
            case "precode":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.codeBlock")(
                            node,

                            options,
                            globals,
                        ) + "\n\n";
                }
                break;
            case "pre":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.pre")(
                            node,

                            options,
                            globals,
                        ) + "\n\n";
                }
                break;
            case "table":
                if (!spansOnly) {
                    txt =
                        Showdown.subParser("makeMarkdown.table")(
                            node,

                            options,
                            globals,
                        ) + "\n\n";
                }
                break;
            //
            // SPANS
            //
            case "code":
                txt = Showdown.subParser("makeMarkdown.codeSpan")(
                    node,

                    options,
                    globals,
                );
                break;
            case "em":
            case "i":
                txt = Showdown.subParser("makeMarkdown.emphasis")(
                    node,

                    options,
                    globals,
                );
                break;
            case "strong":
            case "b":
                txt = Showdown.subParser("makeMarkdown.strong")(
                    node,

                    options,
                    globals,
                );
                break;
            case "del":
                txt = Showdown.subParser("makeMarkdown.strikethrough")(
                    node,

                    options,
                    globals,
                );
                break;
            case "a":
                txt = Showdown.subParser("makeMarkdown.links")(
                    node,

                    options,
                    globals,
                );
                break;
            case "img":
                txt = Showdown.subParser("makeMarkdown.image")(
                    node,

                    options,
                    globals,
                );
                break;
            case "span":
                txt = Showdown.subParser("makeMarkdown.skip")(
                    node,
                    options,
                    globals,
                    spansOnly,
                );
                break;
            case "div":
                txt =
                    Showdown.subParser("makeMarkdown.skip")(
                        node,
                        options,
                        globals,
                        spansOnly,
                    ) + "\n\n";
                break;
            case "br":
                txt = Showdown.subParser("makeMarkdown.break")(
                    node,

                    options,
                    globals,
                );
                break;
            case "input":
                txt = Showdown.subParser("makeMarkdown.input")(
                    node,

                    options,
                    globals,
                );
                break;
            default:
                txt = node.outerHTML + "\n\n";
        }
        // common normalization
        // TODO eventually
        return txt;
    },
);
