const Testimonies = [
    {
        ques: "Why You Should Choose Scalenut For Your SEO Long Form AI Needs",
        ans: "When generating reports it breaks it down in NLP terms (natural language processing), top ten competition, writing themes & Citations from all the competitor's sites. Scalenut reports also provide commonly asked questions from Quora, Reddit, Google's frequently asked questions and provide ai suggestions.Now for the fun stuff!. This is great for setting up the outline for long-form content.",
        user: {
            name: "Terencio W",
            pos: "Founder",
            profile:
                "https://storage.googleapis.com/scalenut/webflow assests/62a1dc3cd1e51cf2654102c3_Terencio%20W.png",
            source: "/AssetsV2/socialcommuntiyicon/Capterra-icon@2x.png",
        },
    },
    {
        ques: "You should go for a cruise!",
        ans: "I was looking for a tool that could help me with content writing and Scalenut delivered. The UI is amazing and the output is the best I have seen. It's easy to use and the results are always amazing. I love how Scalenut can automatically generate content for me. It's a great tool for anyone who wants to improve their writing skills.",
        user: {
            name: "Nelson W",
            pos: "Business Owner",
            profile:
                "https://storage.googleapis.com/scalenut/webflow assests/62a72998959096284cdebac9_nelson%20w%201.png",
            source: "/AssetsV2/socialcommuntiyicon/Capterra-icon@2x.png",
        },
    },
    {
        ques: "Why You Should Choose Scalenut For Your SEO Long Form AI Needs",
        ans: "When generating reports it breaks it down in NLP terms (natural language processing), top ten competition, writing themes & Citations from all the competitor's sites. Scalenut reports also provide commonly asked questions from Quora, Reddit, Google's frequently asked questions and provide ai suggestions.Now for the fun stuff!. This is great for setting up the outline for long-form content.",
        user: {
            name: "Austin P",
            pos: "YouTube Content Creator",
            profile:
                "https://storage.googleapis.com/scalenut/webflow assests/62a1dc3cd1e51cf2654102c3_Terencio%20W.png",
            source: "/AssetsV2/socialcommuntiyicon/Capterra-icon@2x.png",
        },
    },
];

const planCatInfo = (plansType = 2, isDeal = false, dealsLimit = 1) => ({
    5: {
        checks: [
            "2000 AI Words",
            "2 SEO Articles",
            "40+ AI tools",
            "Write",
            "Content Grading",
            "Competitive Research",
            "Social Listening",
            "Content Brief",
            "Chrome Extension",
        ],
        includes: "none",
        subTitle: "Keep creating content free forever.",
    },
    2: {
        checks: [
            "100000 AI Words",
            "5 SEO Articles",
            "24x7 email and chat support",
        ],
        includes: "Everything in Free Forever +",
        subTitle: (
            <div
                style={{
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 400,
                }}
            >
                Perfect for
                <span style={{ fontWeight: 500 }}>
                    {" "}
                    individual creators{" "}
                </span>{" "}
                and <span style={{ fontWeight: 500 }}>consultants</span>
            </div>
        ),
    },
    3: {
        checks: [
            "Unlimited AI Words *",
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Create Humanized SEO Articles
                            </span>{" "}
                            <br /> (
                            <span style={{ textDecoration: "line-through" }}>
                                30
                            </span>{" "}
                            <strong>{30 * dealsLimit}</strong> Articles/mo)
                        </div>
                    ) : (
                        <div>
                            Create Humanized SEO Articles <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (30 Articles/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Audit & Optimize Pages
                            </span>{" "}
                            <br /> (
                            <span style={{ textDecoration: "line-through" }}>
                                30
                            </span>{" "}
                            <strong>{30 * dealsLimit}</strong> Pages/mo)
                        </div>
                    ) : (
                        <div>
                            Audit & Optimize Pages <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (30 Pages/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text: (
                    <div>
                        Detect AI Words
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (20,000 words/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Humanize AI Words
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (5,000 words/mo)
                        </span>
                    </div>
                ),
            },

            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Build Keyword Clusters
                            </span>{" "}
                            <br /> (
                            <span style={{ textDecoration: "line-through" }}>
                                30
                            </span>{" "}
                            <strong>{30 * dealsLimit}</strong> Clusters/mo)
                        </div>
                    ) : (
                        <div>
                            Build Keyword Clusters <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (30 Clusters/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div>
                            Analyze Web Pages <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (200 Pages/mo, 1 Domain)
                            </span>
                        </div>
                    ) : (
                        <div>
                            Analyze Web Pages <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (200 Pages/mo, 1 Domain)
                            </span>
                        </div>
                    ),
            },
            "Unlimited Tone of Voice",
            "Fix-it (Auto-optimizer)",
            "1-click WordPress Publish",
            "Additional SaaS Credits",
            "Integrations",
            {
                text: <div>Topic Gap </div>,
            },
            {
                text: <div>New Topic Ideas </div>,
            },
        ],
        includes: "Everything in Essential +",
        subTitle: (
            <div
                style={{
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 400,
                }}
            >
                Ideal for
                <span style={{ fontWeight: 500 }}>
                    {" "}
                    budding startups{" "}
                </span> and{" "}
                <span style={{ fontWeight: 500 }}>growing businesses</span>
            </div>
        ),
    },
    4: {
        checks: [
            "Unlimited AI Words*",
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Create Humanized SEO Articles
                            </span>{" "}
                            <br />(
                            <span style={{ textDecoration: "line-through" }}>
                                75
                            </span>{" "}
                            <strong>{75 * dealsLimit}</strong> Articles/mo)
                        </div>
                    ) : (
                        <div>
                            Create Humanized SEO Articles <br />
                            <span style={{ fontSize: "12px" }}>
                                (75 Articles/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Audit & Optimize Pages
                            </span>
                            <br /> (
                            <span style={{ textDecoration: "line-through" }}>
                                75
                            </span>{" "}
                            <strong>{75 * dealsLimit}</strong> Pages/mo)
                        </div>
                    ) : (
                        <div>
                            Audit & Optimize Pages
                            <br />
                            <span style={{ fontSize: "12px" }}>
                                (75 Pages/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text: (
                    <div>
                        Detect AI Words
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (20,000 words/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Humanize AI Words
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (5,000 words/mo)
                        </span>
                    </div>
                ),
            },
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Build Keyword Clusters
                            </span>{" "}
                            <br />(
                            <span style={{ textDecoration: "line-through" }}>
                                75
                            </span>{" "}
                            <strong>{75 * dealsLimit}</strong> Clusters/mo)
                        </div>
                    ) : (
                        <div>
                            Build Keyword Clusters <br />
                            <span style={{ fontSize: "12px" }}>
                                (75 Clusters/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div>
                            Analyze Web Pages <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (500 Pages/mo, 5 Domains)
                            </span>
                        </div>
                    ) : (
                        <div>
                            Analyze Web Pages <br />{" "}
                            <span style={{ fontSize: "12px" }}>
                                (500 Pages/mo, 5 Domains)
                            </span>
                        </div>
                    ),
            },
            {
                text: <div>Internal Linking </div>,
            },
            {
                text: <div>Cannibalization </div>,
            },
            {
                text: (
                    <div>
                        Advanced Keyword
                        <br /> Planner{" "}
                    </div>
                ),
            },
            "Dedicated CS Manager",
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div>
                            <span style={{ textDecoration: "line-through" }}>
                                1
                            </span>{" "}
                            2 additional user
                        </div>
                    ) : (
                        <div>1 additional user</div>
                    ),
            },
        ],
        includes: "Everything in Growth +",
        subTitle: (
            <div
                style={{
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 400,
                }}
            >
                Ideal for
                <span style={{ fontWeight: 500 }}>
                    {" "}
                    large teams, businesses{" "}
                </span>{" "}
                and <span style={{ fontWeight: 500 }}>agencies</span>
            </div>
        ),
    },
    7: {
        checks: [
            "100000 AI Words",
            {
                text:
                    plansType == 2 && isDeal ? (
                        <div style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "14px" }}>
                                Create Humanized SEO Articles
                            </span>{" "}
                            <br />(
                            <span
                                style={{
                                    textDecoration: "line-through",
                                }}
                            >
                                5
                            </span>{" "}
                            <strong>{5 * dealsLimit}</strong> Articles/mo)
                        </div>
                    ) : (
                        <div>
                            Create Humanized SEO Articles <br />
                            <span style={{ fontSize: "12px" }}>
                                (5 Articles/mo)
                            </span>
                        </div>
                    ),
            },
            {
                text: (
                    <div>
                        Detect AI Words
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (20,000 words/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Humanize AI Words
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (5,000 words/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: <div>SEO Article Templates </div>,
            },
            {
                text: <div>AI Images </div>,
            },
            {
                text: <div>Factual Content </div>,
            },
            "Get Traffic Insights",
            "Weekly Trends Digest",
            "40+ AI Copywriting Templates",
            "SERP Analysis",
            "NLP Key Terms",
            {
                text: (
                    <div>
                        SEO Editor (Better than <br /> Google Docs for SEO)
                    </div>
                ),
            },
            "Document Sharing",
            "Email Support",
            "Live Chat Support",
        ],
        includes: "none",
        subTitle: "Perfect for individual creators and consultants.",
    },
    10: {
        checks: [
            "Unlimited AI Words",
            {
                text: (
                    <div>
                        Create Humanized SEO Articles <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                            (90 Articles/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Audit & Optimize Pages <br />{" "}
                        <span style={{ fontSize: "12px" }}>(90 Pages/mo)</span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Build Keyword Clusters <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                            (90 Clusters/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Analyze Web Pages <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                            (600 Pages/mo, 1 Domain)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        AI Link Manager
                        <span
                            style={{
                                borderRadius: "16px",
                                padding: "3px 8px",
                                fontSize: "10px",
                                fontWeight: 500,
                                marginLeft: "10px",
                                color: "rgb(0, 0, 0)",
                                background: "#FFE866",
                            }}
                        >
                            Free
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (1000 Pages/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        AI Detector & Humanizer
                        <span
                            style={{
                                borderRadius: "16px",
                                padding: "3px 8px",
                                fontSize: "10px",
                                fontWeight: 500,
                                marginLeft: "10px",
                                color: "rgb(0, 0, 0)",
                                background: "#FFE866",
                            }}
                        >
                            Free
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (Unlimited AI Detection, Humanize 50,000 words/mo)
                        </span>
                    </div>
                ),
            },
        ],
        sectionHeading: "Key Features",
        keyFeatures: {
            firstRow: [
                "AI Images",
                "Factual content",
                "Traffic Insights",
                "NLP Key Terms",
                "AI-SEO Editor",
                "Document Sharing",
                "Auto-optimization",
            ],
            secondRow: [
                "Topic gaps",
                "New topic ideas",
                "Unlimited tone of voice",
                "Integration",
                "Email Support",
                "Chat Support",
            ],
        },
        subTitle: (
            <div
                style={{
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 400,
                }}
            >
                Ideal for
                <span style={{ fontWeight: 500 }}>
                    {" "}
                    budding startups{" "}
                </span> and{" "}
                <span style={{ fontWeight: 500 }}>growing businesses</span>
            </div>
        ),
    },
    11: {
        checks: [
            "Unlimited AI Words",
            {
                text: (
                    <div>
                        Create Humanized SEO Articles <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                            (225 Articles/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Audit & Optimize Pages <br />{" "}
                        <span style={{ fontSize: "12px" }}>(225 Pages/mo)</span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Build Keyword Clusters <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                            (225 Clusters/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        Analyze Web Pages <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                            (1500 Pages/mo, 5 Domain)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        AI Link Manager
                        <span
                            style={{
                                borderRadius: "16px",
                                padding: "3px 8px",
                                fontSize: "10px",
                                fontWeight: 500,
                                marginLeft: "10px",
                                color: "rgb(0, 0, 0)",
                                background: "#FFE866",
                            }}
                        >
                            Free
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (1000 Pages/mo)
                        </span>
                    </div>
                ),
            },
            {
                text: (
                    <div>
                        AI Detector & Humanizer
                        <span
                            style={{
                                borderRadius: "16px",
                                padding: "3px 8px",
                                fontSize: "10px",
                                fontWeight: 500,
                                marginLeft: "10px",
                                color: "rgb(0, 0, 0)",
                                background: "#FFE866",
                            }}
                        >
                            Free
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                            (Unlimited AI Detection, Humanize 50,000 words/mo)
                        </span>
                    </div>
                ),
            },
        ],
        sectionHeading: "All Growth Max features +",
        keyFeatures: {
            firstRow: [
                "Internal linking",
                "Cannibalization",
                "Advanced keyword planner",
                "3 additional user seats",
            ],
        },
        subTitle: (
            <div
                style={{
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 400,
                }}
            >
                Ideal for
                <span style={{ fontWeight: 500 }}>
                    {" "}
                    large teams, businesses{" "}
                </span>{" "}
                and <span style={{ fontWeight: 500 }}>agencies</span>
            </div>
        ),
    },
});

const WhyScalenut = [
    {
        imgSrc: "/New UI/SVG/cpu.svg",
        heading: "Powerful AI Technology",
        text: "Scalenut s AI Writer uses a deep learning model to write like humans.The tool knows words and sentences after reading through hundreds of billions of words.",
    },

    {
        imgSrc: "https://storage.googleapis.com/scalenut/webflow assests/61123d97e9d2e7edeaadfea1_meter--alt.svg",
        heading: "Quality content that gives results",
        text: "Get a real-time quality score that has very high correlation with rankings on search engines.Power up your blog with the auto use of NLP Key Terms and boost your rankings.",
    },
    {
        imgSrc: "https://storage.googleapis.com/scalenut/webflow assests/610aa5a4cbf75edd71c4d0c1_fi-rs-search-alt.svg",
        heading: "Comprehensive Research",
        text: "Consider AI, NLP driven 100+ data points when you re planning the content including competition, top customer questions, backlink data, keyword frequency, use of images or video..",
    },
    {
        imgSrc: "https://storage.googleapis.com/scalenut/webflow assests/611ab9ae2f7669a6f25e4e9f_document--export.svg",
        heading: "Ready to use First Draft",
        text: "Once you ve filled out the blog context, title, outline and writing points Cruise Mode puts it all together to give us a ready- to - use first draft. You can then modify it manually, export it to Editor or if you think it's ready to go, just download it!",
    },
];

const reviewImages = [
    "https://storage.googleapis.com/scalenut/webflow assests/61c425106325976e602b38e4_capterra%20reviews.jpg",
    "https://storage.googleapis.com/scalenut/webflow assests/61c42510c18f637579ee89d3_g2%20reviews.jpg",
    "https://storage.googleapis.com/scalenut/webflow assests/61c4251393dada5a16d2f21b_trustpilot%20reviews.jpg",
    "https://storage.googleapis.com/scalenut/webflow assests/61c467b373a8224035147a3a_50%20plus%20countries.jpg",
    "https://storage.googleapis.com/scalenut/webflow assests/62482f55d892522e0870fe83_Words%20Generated.png",
];

const partnerImages = [
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/64c26f921e25c5e76ac2b56d_Commscope_1.svg",
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/64c26f9183b8befeb554af08_Envato_1.svg",
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/64c26f913bb25d70683f6c5e_Telia_1.svg",
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/64c26f91621394fc8c33b82e_Comcast_1.svg",
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/64c26f928dfa2db687c3cdd6_Virgin%20Media_1.svg",
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/6454f5669b6df4e5c9632455_ZOHO.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f4820daa9e615ec6aac_Adobe.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f499d37fd151d8b477f_Amazon.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/6129ddaf75843d6800da94fa_Airtel.webp",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f4996fd6be5006d7d56_microsoft.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f48eec2827a15caf9af_NVIDIA.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/6378812cf3d6aa5b3dfc8386_WPP_plc-Logo.wine.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f46eec2824456caf9aa_Reliance.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/6129ddb0f55a8360a4139923_PharmEasy.webp",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f48d6ddb068c70575ed_OYO.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/637881daeec28248cccb1eec_Akorse%20Labs.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f479d37fd69c88b4773_upGrad.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f45e86a452d842b2f28_Razorpay.svg",
    "https://storage.googleapis.com/scalenut/webflow assests/6284b3f3887c409e565b8fe1_Snapdeal.webp",
    "https://storage.googleapis.com/scalenut/webflow assests/611fa0469c83d1023ef59745_1MG.webp",
    "https://storage.googleapis.com/scalenut/webflow assests/63787f48b322096d9a21eee2_Sprinklr.svg",
    "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/64b13127af9d3ea057b1ce2d_Wework.svg",
];

const features = [
    {
        imgSrc: "https://storage.googleapis.com/scalenut/webflow assests/611aaefc24320edb2f005297_cpu-line-bars-white.svg",
        text: `POWERFUL
    AI TECHNOLOGY`,
    },
    {
        imgSrc: "https://storage.googleapis.com/scalenut/webflow assests/611b41bc2597172202edc121_timer-flash-line-white.svg",
        text: `CRUISE MODE`,
    },
    {
        imgSrc: "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d9f44eb6f714697252e867_keyword%20planner.svg",
        text: "KEYWORD PLANNER",
    },
    {
        imgSrc: "https://storage.googleapis.com/scalenut/webflow assests/svgvieweroutput.svg",
        text: "TRAFFIC ANALYZER",
    },
    {
        imgSrc: "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d9f44ebe1160d92158d377_Content%20Optimizer.svg",
        text: `AUTO OPTIMIZER`,
    },
    {
        imgSrc: "https://global-uploads.webflow.com/60ef088dd8fef919efabb412/63d9f44e26c7fb9947684e67_Serp%20stats.svg",
        text: `SERP STATISTICS`,
    },
];

export {
    Testimonies,
    planCatInfo,
    WhyScalenut,
    partnerImages,
    reviewImages,
    features,
};
