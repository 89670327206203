import { ClickAwayListener, TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import useHelperFunctions from "../../../helperFunctions";
import ReportContext from "../../Research/ReportContext";
import styles from "./OutlineBuilder.module.css";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { showCounter } from "../../../atoms/TextArea/TextArea";
import { useRef } from "react";
import H2H3dropdown from "./H2H3dropdown";
import useMixpanelHook from "../../../../utility/useMixpanelHook";

import { GenerateIdea, GenerateOutline } from "./OutlineBox";
import { useDispatch } from "react-redux";
import { displayAlert } from "../../../../store/actions/alert";
import PrimaryInputTextArea from "./PrimaryInputTextArea";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import moment from "moment";
import {
    cruiseMixPanelEvents,
    droppableIds,
    droppableTypes,
    HEADINGS_EVENT_TYPES,
} from "./cruiseConstant";
import { CruiseContext } from "./StepsWizard";
import { Outline } from "./classes";
import { CRUISE_TYPES } from "./useCruiseHook";
import { v4 } from "uuid";
import OutlineH3 from "./OutlineH3";
import { VscTrash } from "react-icons/vsc";
import { getKeytermVariations } from "../../../../utility/helperFun";

/**
 * @param {{item:Outline}}
 *  */
const maxLength = 120;
const OutlineH2 = ({
    provided,
    index,
    item,
    handleH2H3Change,
    onPressEnterInOutline,
}) => {
    const { postData, getData } = useHelperFunctions();
    const [addH3, setAddH3] = useState(false);
    const [h2Input, setH2Input] = useState(item.data);
    const dispatchStepsData = useContext(CruiseContext);
    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { reportInfo, keyTerms, highlight_keyTerm } =
        useContext(ReportContext);
    const [focus, setFocus] = useState(item.data?.length ? false : true);
    const { mixpanelTrack } = useMixpanelHook();

    const ref = useRef();

    function handleEdit(text, onEnter = false) {
        if (onEnter) {
            onPressEnterInOutline({
                data: "",
                tag: Outline.tags.h2,
                index,
            });
        }

        if (!text.trim()) {
            deleteHeading();
            return;
        }
        if (item.data.trim() == text.trim()) return;
        setH2Input(h2Input.trim());
        setFocus(false);

        const heading = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: text,
            tag: Outline.tags.h2,
            outlineH3s: item.outlineH3s.map((h3) => ({ ...h3, id: v4() })),
        });
        dispatchStepsData({
            type: CRUISE_TYPES.EDIT_OUTLINES,
            index: index,
            field: Outline.type.heading,
            data: heading,
            id: item.id,
            tag: Outline.tags.h2,
        });
    }

    const handleH3H2Change = (h3Index) => {
        /**
         * @type {Outline}
         *  */
        const parentH2 = item;
        /**
         * @type {Outline}
         *  */
        const currH3 = item.outlineH3s[h3Index];
        const outlineH3s = [];

        for (let i = h3Index + 1; i < parentH2.outlineH3s.length; i++) {
            outlineH3s.push(
                new Outline({ ...parentH2.outlineH3s[i], id: v4() }),
            );
        }

        const outlineH2 = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: currH3.data,
            tag: Outline.tags.h2,
            outlineH3s: outlineH3s,
        });

        dispatchStepsData({
            type: CRUISE_TYPES.OUTLINE_INTER_CHANGE,
            index: h3Index,
            tag: Outline.tags.h3,
            data: outlineH2,
            h2Index: index,
            id: currH3.id,
        });
    };

    const _handleH2H3Change = () => {
        handleH2H3Change(index);
    };

    function deleteHeading() {
        dispatchStepsData({
            type: CRUISE_TYPES.DELETE_OUTLINES,
            index: index,
            field: Outline.type.heading,
            id: item.id,
            tag: Outline.tags.h2,
        });
    }

    const h3Callback = (text, onEnter) => {
        if (!text) return;
        const heading = new Outline({
            type: Outline.type.heading,
            id: v4(),
            data: text,
            tag: Outline.tags.h3,
        });

        dispatchStepsData({
            type: CRUISE_TYPES.ADD_OUTLINES,
            field: Outline.type.heading,
            data: heading,
            h2Index: index,
            tag: Outline.tags.h3,
            h2_id: item.id,
        });
    };

    return (
        <>
            <div
                className={styles.h2Row + " " + (open && styles.noBorder)}
                ref={ref}
            >
                <div
                    className={styles.headingRow}
                    style={{ position: "relative" }}
                >
                    <div
                        {...provided.dragHandleProps}
                        className={styles.dragIcon}
                    >
                        <SVGIcon
                            color="lightgray"
                            size={16}
                            src="/New UI/SVG/drag.svg"
                        />
                    </div>

                    <div
                        className={
                            styles.highlightWrapper +
                            " " +
                            (focus && styles.highlightWrapper__active)
                        }
                    >
                        <H2H3dropdown
                            handler={_handleH2H3Change}
                            index={index}
                            type={item.tag}
                        />
                        {/* {!isEdit ? <span className={styles.headingText} onDoubleClick={() => setIsEdit(true)}>{h2.data}</span> : */}

                        {!focus ? (
                            <div
                                className={styles.text}
                                onClick={() => setFocus(true)}
                            >
                                {h2Input}
                            </div>
                        ) : (
                            <TextareaAutosize
                                disabled={false}
                                type="text"
                                rowsMin={1}
                                placeholder={"Add Heading here"}
                                maxLength={maxLength}
                                autoFocus={true}
                                value={h2Input}
                                onChange={(e) => setH2Input(e.target.value)}
                                onFocus={(e) =>
                                    e.target.setSelectionRange(
                                        e.target.value.length,
                                        e.target.value.length,
                                    )
                                }
                                style={{
                                    width: "100%",
                                    ...(h2Input.length >= maxLength
                                        ? {
                                              borderColor:
                                                  "rgb(244 98 98 / 73%)",
                                          }
                                        : {}),
                                }}
                                // autoFocus={value == ""}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleEdit(e.target.value, true);
                                        return;
                                    }
                                    if (e.shiftKey && e.key == "Tab") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }

                                    if (!e.shiftKey && e.key == "Tab") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (index == 0) {
                                            dispatch(
                                                displayAlert({
                                                    alertMessage:
                                                        "You cannot convert the first H2 to H3",
                                                    alertType: "error",
                                                }),
                                            );
                                            return;
                                        }
                                        _handleH2H3Change();
                                    }
                                }}
                                onBlur={(e) => {
                                    handleEdit(e.target.value);
                                    setFocus(false);
                                }}
                            />
                        )}
                    </div>

                    {focus &&
                        showCounter(false, true, h2Input?.length, maxLength, {
                            textAlign: "right",
                            display: "block",
                            position: "absolute",
                            right: "125px",
                        })}
                    <div className={styles.actionsBtn}>
                        {/* {!isEdit && <span className={styles.addNote}><SVGIcon src={"/New UI/SVG/MdOutlineSpeakerNotes.svg"} /></span>} */}

                        {Outline.tags.h2 == item.tag ? (
                            <button
                                className={styles.addH3Btn}
                                onClick={() => {
                                    setAddH3(true);
                                }}
                            >
                                Add H3
                            </button>
                        ) : (
                            ""
                        )}

                        <VscTrash
                            onClick={(e) => {
                                e.stopPropagation();
                                mixpanelTrack(cruiseMixPanelEvents.delete, {
                                    "Screen Name": "Outline",
                                    "Deleted Section": "H2",
                                });
                                deleteHeading();
                            }}
                            color="#f94f2c"
                        />
                    </div>
                </div>
            </div>

            <Droppable
                type={droppableTypes.outlineH3}
                droppableId={String(`${index}_${item.id}`)}
            >
                {(provided) => (
                    <div
                        className={styles.headingsDiv + " " + styles.h3Row}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {item.outlineH3s.map((heading, i) => {
                            return (
                                <Draggable
                                    key={heading.id}
                                    draggableId={String(heading.id)}
                                    index={i}
                                >
                                    {(provided, snap) => (
                                        <div
                                            className={styles.headingItemOuter}
                                            key={heading.id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={{
                                                ...provided.draggableProps
                                                    ?.style,

                                                backgroundColor: snap.isDragging
                                                    ? "rgba(232, 237, 248, 0.9)"
                                                    : "",
                                            }}
                                        >
                                            <OutlineH3
                                                key={heading.id}
                                                provided={provided}
                                                index={i}
                                                item={heading}
                                                setAddH3={setAddH3}
                                                h2Index={index}
                                                handleH3H2Change={
                                                    handleH3H2Change
                                                }
                                                onPressEnterInOutline={
                                                    onPressEnterInOutline
                                                }
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                        {addH3 && (
                            <ClickAwayListener
                                onClickAway={() => setAddH3(false)}
                            >
                                <div className={styles.addH3Btns}>
                                    <GenerateOutline
                                        isH3={true}
                                        h3Callback={h3Callback}
                                        h2Data={item.data}
                                    />
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                )}
            </Droppable>
        </>
    );
};

export default OutlineH2;
