import React from "react";
import Label from "../Label/Label";
import styles from "./styles.module.css";

function CheckBox({ label, props = {}, colorLabel, color, size, handler }) {
    return (
        <div className={styles.container}>
            <input onClick={handler} type="checkbox" {...props} />
            <Label
                size={size}
                colorLabel={colorLabel}
                color={color}
                label={label}
                textTransform={"none"}
                style={{
                    margin: 0,
                }}
            ></Label>
        </div>
    );
}

export default CheckBox;
