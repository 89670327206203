import React, { useMemo, useState } from "react";
import DataRow from "./DataRow";
import HeadRow from "./HeadRow";
import styles from "./competitors.module.css";
import RepotsGraph from "../report-graph";
import useSortingBy from "../../../CommonHooks/useSortingBy";

const CompetitionTable = ({
    list,
    setList,
    overview,
    cruiseMode,
    organicCompList,
    setOrganicCompList,
    setCompetitorsRank,
    auditMode,
}) => {
    const [top, setTop] = useState(33);
    const [graphHeight, setGraphHeight] = useState(0);
    const [selectedRow, setSelectedRow] = useState({ url_id: -1 });
    const [sortBy, setSortBy] = useState({ colID: 0, asc: true });

    const HeadColumns = [
        { title: "Rank", isSort: "rank", isMain: false },
        { title: "Competitor URLs", isSort: false, isMain: true },
        { title: "Words", isSort: "word_count", isMain: false },
        { title: "SEO Score", isSort: "grade", isMain: false },
        { title: "H-Tags", isSort: "htags_count", isMain: false },
        { title: "Images", isSort: "no_of_images", isMain: false },
        ...(cruiseMode ? [{ title: "", isSort: "", isMain: false }] : []),
    ];

    const graphList = list;

    list = useSortingBy({
        items: list.slice(0, top),
        asc: sortBy.asc,
        term: HeadColumns[sortBy.colID].isSort,
    });

    const Table = (
        <div className={styles.tableContainer}>
            <table>
                <thead className={styles.tableHead}>
                    <HeadRow
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        columns={HeadColumns}
                        cruiseMode={cruiseMode}
                    />
                </thead>

                <tbody className={styles.tableBody}>
                    <tr />
                    <DataRow
                        setSelectedRow={setSelectedRow}
                        selectedRow={selectedRow}
                        list={list.slice(0, top)}
                        setList={setList}
                        cruiseMode={cruiseMode}
                        organicCompList={organicCompList}
                        setOrganicCompList={setOrganicCompList}
                        setCompetitorsRank={setCompetitorsRank}
                        auditMode={auditMode}
                    />
                </tbody>
            </table>
        </div>
    );

    return cruiseMode ? (
        Table
    ) : (
        <>
            <div id="reactchart-tooltip"> </div>
            <RepotsGraph
                selectedRow={selectedRow}
                overview={overview}
                list={graphList}
                top={top}
                setTop={setTop}
                setGraphHeight={setGraphHeight}
            />

            <div
                style={{ height: `calc(100vh - ${graphHeight + 200}px)` }}
                className={styles.competitionContainer}
            >
                {Table}
            </div>
        </>
    );
};

export default CompetitionTable;
