import { Tab, Tabs, TextareaAutosize, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SVGIcon from "../../../../atoms/SVGIcon";
import styles from "../OutlineBuilder.module.css";
import useHelperFunctions from "../../../../helperFunctions";
import { HEADINGS, USE_CASES } from "../constants";

import useMixpanelHook from "../../../../../utility/useMixpanelHook";
import { GenerateIdea } from "../OutlineBox";
import PrimaryInputTextArea from "../PrimaryInputTextArea";
import ReportContext from "../../../Research/ReportContext";
import { useContext } from "react";
import moment from "moment";
import { CruiseContext, CruiseState } from "../StepsWizard";
import { useCallback } from "react";
import { Outline, WP } from "../classes";
import { OUTLINE_EVENTS_TYPES } from "../cruiseConstant";
import { v4 } from "uuid";
import { CRUISE_TYPES } from "../useCruiseHook";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const tabStyles = makeStyles(() => ({
    root: {
        background: "transparent",
        margin: 3,
        border: "none",
        textTransform: "none",
        minHeight: 24,
        padding: "4px 16px",
        fontSize: 12,
        opacity: 1,
    },
    selected: {
        background: "white",
        borderRadius: "5px",
        border: "none",
        color: "black",
        backgroundColor: "#1f4cbe1f",
        color: "var(--primary-blue) !important",
    },
}));

const tabsStyles = makeStyles(() => ({
    root: {
        background: "white",
        borderRadius: "5px",
        overflow: "hidden",
        border: "none",
        margin: "5px 0 10px 0",
        minHeight: 36,
        border: "1px solid #1f4cbe1f",
    },
    indicator: {
        display: "none",
    },
}));

const TPGenerate = ({
    index,
    isFaq,
    isH2,
    isH3,
    data,
    id,
    h2Index,
    noOfWps,
    h2Data,
    title,
}) => {
    const { postData } = useHelperFunctions();

    const [isGen, setIsGen] = useState(false);
    const [noFactual, setNoFactual] = useState(false);
    const [generatedTalkingPoints, setGeneratedTalkingPoints] = useState([]);
    const { mixpanelTrack } = useMixpanelHook();
    const [typeWise, setTypeWise] = useState([]);
    const { reportInfo } = useContext(ReportContext);
    const dispatchStepsData = useContext(CruiseContext);
    const [tpType, setTpType] = useState(WP.types.ai);
    const setType = (tpType) => {
        setTpType(tpType);
        const typeWiseTP = generatedTalkingPoints.filter(
            (tp) => tp.type == tpType,
        );
        setTypeWise(typeWiseTP);
        if (!typeWiseTP.length && !(tpType == WP.types.fact && noFactual)) {
            aiGenTalkingPoints(tpType);
        }
    };

    const inputToOutline = useCallback(
        function (item) {
            const wp = new WP({
                outline_id: id,
                tpType: item?.uniqueId ? WP.types.ai : WP.types.manual,
                tp: item.content,
                citations: item.citations || [],
                id: v4(),
            });

            dispatchStepsData({
                isWp: true,
                field: isFaq ? Outline.type.question : Outline.type.heading,
                parent_id: id,
                data: wp,
                type: CRUISE_TYPES.ADD_WP,
                parentIndex: index,
                h2Index,
                parentTag: isH2
                    ? Outline.tags.h2
                    : isH3
                    ? Outline.tags.h3
                    : Outline.tags.question,
            });
            if (noOfWps >= 6) {
                return false;
            } else {
                return true;
            }
        },
        [noOfWps],
    );

    useEffect(() => {
        const typeWiseTP = generatedTalkingPoints.filter(
            (tp) => tp.type == tpType,
        );

        setTypeWise(() => typeWiseTP);
    }, [tpType, generatedTalkingPoints]);

    async function aiGenTalkingPoints(_tpType) {
        const screenName = "Writing Points";
        const section = isFaq ? "FAQ" : isH2 ? "H2" : "H3";

        if (typeWise?.length > 0) {
            if (_tpType !== WP.types.fact) {
                mixpanelTrack("Generate more clicked", {
                    "Screen Name": screenName,
                    Section: section,
                    isFact: false,
                });
            }
        } else {
            mixpanelTrack("Suggestions Clicked", {
                "Screen Name": screenName,
                Section: section,
                isFact: false,
            });
        }

        if (_tpType === WP.types.fact) {
            mixpanelTrack("Suggestions Clicked", {
                "Screen Name": screenName,
                Section: section,
                isFact: true,
            });
        }

        _tpType = _tpType || tpType;
        setIsGen(_tpType);
        const sDate = moment.now();
        let payload = {
            title,
            end: "",
            session: "",
            report_id: reportInfo.report_id,
            type: _tpType,
        };

        if (isFaq) {
            payload.question = data;
        } else if (isH2) {
            payload.h2 = data;
        } else if (isH3) {
            payload.h2 = h2Data;
            payload.h3 = data;
        }

        if (payload) {
            const res = await postData({
                url: "/api/contentDraft/v2/generate-writing-point",
                payload,
            });
            setIsGen(0);
            if (res.status == 200) {
                // setAiGenerated(res.data.output ? res.data.output : []);
                if (!res?.data?.output?.length && _tpType == WP.types.fact) {
                    setNoFactual(true);
                    return;
                }

                res.data.output = res.data.output.map((tp) => {
                    tp.type = _tpType;
                    return tp;
                });

                setGeneratedTalkingPoints(
                    res.data.output
                        ? [...generatedTalkingPoints, ...res.data.output]
                        : [...generatedTalkingPoints],
                );
                const timeTaken = moment().diff(sDate, "seconds");
                mixpanelTrack("Writing Points", {
                    timeTaken,
                    type: _tpType == WP.types.fact ? "facts" : "ai",
                });
            } else {
                console.log("error", res);
                // setGenTalkingPoints(res.data);
            }
        }
    }

    const TPSwitch = (
        <>
            <Tabs classes={tabsStyles()} variant="fullWidth" value={tpType}>
                <Tab
                    onClick={() => {
                        setType(WP.types.ai);
                    }}
                    label="AI Suggestions"
                    {...a11yProps(0)}
                    value={WP.types.ai}
                    classes={tabStyles()}
                />
                <Tab
                    onClick={() => {
                        setType(WP.types.fact);
                    }}
                    label="Facts"
                    {...a11yProps(1)}
                    value={WP.types.fact}
                    classes={tabStyles()}
                />
            </Tabs>
            {!typeWise.length && tpType == WP.types.fact && noFactual && (
                <h3 style={{ paddingLeft: 10 }}>No Facts Found</h3>
            )}
        </>
    );

    return (
        <>
            <div className={styles.addH3Btns}>
                <PrimaryInputTextArea
                    placeholder={"Add Writing Point here"}
                    callback={(value) => {
                        if (value.trim()) {
                            inputToOutline({ content: value });
                        }
                    }}
                    maxLength={150}
                    type={
                        isFaq
                            ? "Writing Points(FAQ)"
                            : isH2
                            ? "Writing Points(H2)"
                            : "Writing Points(H3)"
                    }
                />
                <GenerateIdea
                    inputToOutline={inputToOutline}
                    type={tpType == WP.types.ai ? "AI" : "FACT"}
                    additionType={
                        isFaq
                            ? "Writing Points(FAQ)"
                            : isH2
                            ? "Writing Points(H2)"
                            : "Writing Points(H3)"
                    }
                    generate={() => {
                        aiGenTalkingPoints();
                    }}
                    list={typeWise}
                    setList={setGeneratedTalkingPoints}
                    isGenerating={isGen == tpType}
                    TPSwitch={TPSwitch}
                    allowGenMore={!(tpType == WP.types.fact)}
                    open={(isGen && tpType == WP.types.fact) || noFactual}
                    highlightKeyTerms={false}
                />
            </div>
        </>
    );
};

export default TPGenerate;
