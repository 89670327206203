import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PLANS_TYPE } from "../../../constants";
import { specifyPlans } from "../../../utility/helperFun";
import useHelperFunctions from "../../helperFunctions";

export const parsePrice = (price, currency, type, discount, isNum = false) => {
    const CalcPrice = (
        (discount
            ? discount
                ? price - (price * discount) / 100
                : price
            : price) / getMonths(type)
    ).toFixed(0);
    if (isNum) {
        return CalcPrice;
    } else {
        return `${currency == "USD" ? "$" : "₹"} ${CalcPrice}`;
    }
};

export const getYearSave = (price, currency, type, discount) => {
    const mo = parsePrice(price, currency, type).split(" ")[1];
    const ye = parsePrice(price, currency, type, discount).split(" ")[1];
    return `${currency == "USD" ? "$" : "₹"} ${
        (parseInt(mo) - parseInt(ye)) * 12
    }`;
};

export const getMonths = (type) => {
    switch (type) {
        case 1:
            return 1;

        case 2:
            return 12;

        case 4:
            return 6;

        case 3:
            return 1;
    }
};

function usePriceDiscounts() {
    const { deals } = useSelector((state) => state.appConfig);

    const { getData } = useHelperFunctions();
    const [planTypesWithDiscount, setPlanTypesWithDiscount] = useState([]);
    const [plans, setPlans] = useState([]);
    const [plansType, setPlansType] = useState(1);
    const [typesOfPlans, setTypesOfPlans] = useState([]);
    const [planDetails, setPlanDetails] = useState(null);
    const [isOpenChat, setIsOpenChat] = useState(false);
    const userInfo = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(true);
    const getLabel = (type) => {
        switch (type) {
            case 1:
                return "Monthly";

            case 2:
                return "Annually";

            case 4:
                return "Semi Annually";

            case 3:
                return "Lifetime";
        }
    };

    const getDiscount = (type) => {
        const parsedDeals = JSON.parse(deals);
        switch (type) {
            case 1:
                return 0;

            case 2:
                return parsedDeals?.annualPlanDiscount;

            case 4:
                return parsedDeals?.semiAnnualPlanDiscount;

            case 3:
                return 0;
            case 5:
                return parsedDeals?.maxPlanDiscount;
        }
    };

    useEffect(() => {
        const getPlans = async () => {
            setIsLoading(true);
            const res = await getData({
                url: "/api/pricing/getSubscriptionPlans",
            });
            if (res.status == 200) {
                res.data.data = specifyPlans(res.data.data, res.data?.quota);
                setPlanDetails(res.data?.quota);
                setIsOpenChat(res.data?.openChat ? res.data?.openChat : false);
                setPlans([
                    ...res.data.data.filter(
                        (p) =>
                            p.plan_category != 2 &&
                            p.plan_category != 6 &&
                            p.plan_category != 5,
                    ),
                ]);
            }
            setIsLoading(false);
        };

        getPlans();
    }, []);

    useEffect(() => {
        if (plans.length) {
            const plansType = planDetails?.type == 4 ? [1, 4, 2] : [1, 2];

            setPlanTypesWithDiscount(
                plansType.map((plan_type) => ({
                    value: plan_type,
                    discount: getDiscount(plan_type),
                    label: getLabel(plan_type),
                })),
            );
            setTypesOfPlans(plansType);
        }
    }, [deals, planDetails, plans]);

    return {
        planTypesWithDiscount,
        plans,
        plansType,
        setPlansType,
        getDiscount,
        typesOfPlans,
        planDetails,
        isOpenChat,
        isLoading,
        setIsLoading,
    };
}

export default usePriceDiscounts;
