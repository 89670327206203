import React from "react";

export default function Image({ src, alt, width, height }) {
  if (!(typeof src === "string"))
    return (
      <img
        height="100%"
        width="100%"
        style={{ borderRadius: "50%", filter: "opacity(0.25)" }}
        src="/AssetsV2/image-placeholder.jpg"
      />
    );
  return (
    <img
      height={height ? height : "100%"}
      width={width ? width : "100%"}
      alt={alt}
      src={src}
    ></img>
  );
}
