export const loadingText = (status, isCluster) =>
    !isCluster
        ? [
              {
                  text: "Generating Competition Overview",
                  subText: [
                      "Use this section to determine the ideal no. of words, H-tags, Images, etc.",
                  ],
                  icon: "Competition-Overview-icon.svg",
              },
              {
                  text: "Creating Outlines",
                  subText: [
                      "Use this section to create your customized blog outlines basis your competition.",
                  ],
                  icon: "Outline-icon.svg",
              },
              {
                  text: "Fetching Questions",
                  subText: [
                      "Use this section to check Frequently Asked questions across different channels",
                  ],
                  icon: "Fetching-Questions-icon.svg",
              },
              {
                  text: "Extracting Citations",
                  subText: [
                      "Use this section to check most cited URLs in the top ranking competition.",
                  ],
                  icon: "Citations-icon.svg",
              },
              {
                  text: "Extracting Related Key Terms",
                  subText: [
                      "Add these terms in your blog to increase your content grade",
                  ],
                  icon: "Key-Terms-icon.svg",
              },
              {
                  text:
                      status == 0
                          ? "Packaging Data"
                          : status == 1
                          ? "Done"
                          : "Failed",
                  icon:
                      status == 0
                          ? "packaging.svg"
                          : status == 1
                          ? "check.svg"
                          : "cross.svg",
                  subText: [
                      "Combining competitive insights, outlines, queries, and NLP terms for an eagle-eye analysis.",
                      "Your content seems nice. Pulling in all the information; stay with us.",
                      "And we are almost done...",
                  ],
              },
          ]
        : [
              {
                  text: "Fetching related keywords",
                  subText: [""],
                  icon: "Competition-Overview-icon.svg",
              },
              {
                  text: "Computing Monthly Search Volume",
                  subText: [""],
                  icon: "Outline-icon.svg",
              },
              {
                  text: "Establishing Semantic Similarity",
                  subText: [""],
                  icon: "Fetching-Questions-icon.svg",
              },
              {
                  text: "Clustering keywords",
                  subText: [""],
                  icon: "Citations-icon.svg",
              },
              {
                  text:
                      status == 0
                          ? "Packaging Data"
                          : status == 1
                          ? "Done"
                          : "Failed",
                  icon:
                      status == 0
                          ? "packaging.svg"
                          : status == 1
                          ? "check.svg"
                          : "cross.svg",
                  subText: [""],
              },
          ];

export const is_completed = {
    inProgress: 0,
};
