import React, { useEffect, useRef, useState } from "react";
import RenderInChucks from "../../../atoms/RenderInChucks";
import SVGIcon from "../../../atoms/SVGIcon";
import styles from "./ClusterKeywordPlanner.module.css";
import KeywordPlannerRow from "./KeywordPlannerRow";
import LocalPagination from "../../../atoms/LocalPagination/LocalPagination";
import ClusterReport from "../clusters/ClusterReport";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

const ClusterKeywordPlanner = ({
    _setClusterKeywords = () => {},
    clusterInfo = {},
    keywordsInCluster = [],
    all = true,
    order = 0,
    setOrder = () => {},
    setSortBy = () => {},
    sortByOptions4 = [],
    sortByOptions5 = [],
    sortBy = {},
    makeSingleKeywordReport,
}) => {
    const ref = useRef(null);

    useEffect(() => {}, [order]);
    const itemsPerPage = 40;
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const scrollRef = useRef(null);

    useEffect(() => {
        setSortBy((ps) => {
            if (clusterInfo.source == "semrush") {
                return sortByOptions5[0];
            } else {
                return sortByOptions4[0];
            }
        });
        setOrder(0);
    }, []);

    useEffect(() => {
        setPageCount(Math.ceil(keywordsInCluster.length / itemsPerPage));
    }, [keywordsInCluster.length]);

    const handleColumnSort = (newId) => {
        setCurrentPage(0);
        setSortBy((ps) => {
            if (clusterInfo.source == "semrush") {
                if (ps.id == sortByOptions5[newId].id) setOrder(!order);
                else setOrder(0);
                return sortByOptions5[newId];
            } else {
                if (ps.id == sortByOptions4[newId].id) setOrder(!order);
                else setOrder(0);
                return sortByOptions4[newId];
            }
        });
    };

    useEffect(() => {
        // scrollRef?.current?.scrollIntoView({ block: "end" });
    }, [currentPage]);

    const onChangePageHandler = (e) => {
        setCurrentPage(e.selected);
    };

    return (
        <div className={styles.keywordsWrapper}>
            <span ref={scrollRef}></span>
            {keywordsInCluster.length ? (
                <table className={styles.table}>
                    <thead className={styles.tableHeadRow}>
                        <th className={styles.headKeyword}>Keyword</th>
                        <th
                            className={styles.headVolume}
                            onClick={() => {
                                handleColumnSort(1);
                            }}
                        >
                            <>
                                <LightTooltip
                                    title={
                                        "It indicates the number of queries for a specific keyword on Google over the last 12 months."
                                    }
                                    placement={"bottom"}
                                    arrow
                                >
                                    <div>Search Volume</div>
                                </LightTooltip>
                                {order == 1 && sortBy?.id == 1 ? (
                                    <SVGIcon
                                        size={22}
                                        style={{ cursor: "pointer" }}
                                        src={"/New UI/SVG/sortA.svg"}
                                    />
                                ) : (
                                    <SVGIcon
                                        size={22}
                                        style={{ cursor: "pointer" }}
                                        src={"/New UI/SVG/sortD.svg"}
                                    />
                                )}
                            </>
                        </th>
                        {clusterInfo.is_excel_sheet !== 1 && (
                            <th
                                className={styles.headSimilarity}
                                onClick={() => {
                                    handleColumnSort(0);
                                }}
                            >
                                <>
                                    <LightTooltip
                                        title={
                                            clusterInfo?.source == "semrush"
                                                ? "It refers to the degree of competition for the keyword in paid search on a scale of 0 to 1. The higher the score, the more difficult it will be to stand out in the search results, and vice versa."
                                                : "Semantically related keywords or phrases that have a conceptual relationship to one another."
                                        }
                                        placement={"bottom"}
                                        arrow
                                    >
                                        <div>
                                            {clusterInfo.source == "semrush"
                                                ? "Paid Competition"
                                                : "Relevance"}
                                        </div>
                                    </LightTooltip>

                                    {order == 1 &&
                                    ((clusterInfo?.source == "semrush" &&
                                        sortBy?.id == 4) ||
                                        sortBy?.id == 3) ? (
                                        <SVGIcon
                                            size={22}
                                            style={{ cursor: "pointer" }}
                                            src={"/New UI/SVG/sortA.svg"}
                                        />
                                    ) : (
                                        <SVGIcon
                                            size={22}
                                            style={{ cursor: "pointer" }}
                                            src={"/New UI/SVG/sortD.svg"}
                                        />
                                    )}
                                </>
                            </th>
                        )}
                        <th
                            className={styles.headCpc}
                            onClick={() => {
                                handleColumnSort(2);
                            }}
                        >
                            <>
                                <LightTooltip
                                    title={
                                        "Cost per click is the actual price you pay in USD to bid on keywords for a chance to show ads in Google search results."
                                    }
                                    placement={"bottom"}
                                    arrow
                                >
                                    <div>CPC</div>
                                </LightTooltip>
                                {order == 1 && sortBy?.id == 5 ? (
                                    <SVGIcon
                                        size={22}
                                        style={{ cursor: "pointer" }}
                                        src={"/New UI/SVG/sortA.svg"}
                                    />
                                ) : (
                                    <SVGIcon
                                        size={22}
                                        style={{ cursor: "pointer" }}
                                        src={"/New UI/SVG/sortD.svg"}
                                    />
                                )}
                            </>
                        </th>
                        {/* <th
                            className={styles.headSimilarity}
                            style={{
                                width: "15%",
                            }}
                            onClick={() => {
                                handleColumnSort(3);
                            }}
                        >
                            <>
                                <LightTooltip
                                    title={
                                        "Potential Score recommends keywords to boost your content's relevance and authority,helping you get better search ranking and engagaement."
                                    }
                                    placement={"bottom"}
                                    arrow
                                >
                                    <div>POTENTIAL SCORE</div>
                                </LightTooltip>
                                {order == 1 && sortBy?.id == 6 ? (
                                    <SVGIcon
                                        size={22}
                                        style={{ cursor: "pointer" }}
                                        src={"/New UI/SVG/sortA.svg"}
                                    />
                                ) : (
                                    <SVGIcon
                                        size={22}
                                        style={{ cursor: "pointer" }}
                                        src={"/New UI/SVG/sortD.svg"}
                                    />
                                )}
                            </>
                        </th> */}
                        <th className={styles.headOption}></th>
                    </thead>
                    <tbody className={styles.tableItemRowWrapper} ref={ref}>
                        {keywordsInCluster
                            .slice(
                                currentPage * itemsPerPage,
                                currentPage * itemsPerPage + itemsPerPage,
                            )
                            .map((item, i) => {
                                return (
                                    <KeywordPlannerRow
                                        key={item.id}
                                        item={item}
                                        clusterInfo={clusterInfo}
                                        _setClusterKeywords={
                                            _setClusterKeywords
                                        }
                                        makeSingleKeywordReport={
                                            makeSingleKeywordReport
                                        }
                                    />
                                );
                            })}
                    </tbody>
                    <div className={styles.paginationContainer}>
                        {pageCount > 1 && (
                            <LocalPagination
                                pageCount={pageCount}
                                pageRangeDisplayed={itemsPerPage}
                                currentPage={currentPage}
                                onPageChange={onChangePageHandler}
                            />
                        )}
                    </div>
                </table>
            ) : (
                <div className={styles.noItems}>
                    {
                        <>
                            <SVGIcon
                                style={{ margin: "auto" }}
                                src={"/New UI/SVG/Emptydocument.svg"}
                                size={300}
                            />
                            <h3 style={{ marginTop: -50 }}>
                                No {all ? "Keywords " : "Articles "}Found
                            </h3>
                        </>
                    }
                </div>
            )}
        </div>
    );
};

export default ClusterKeywordPlanner;
