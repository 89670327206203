import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    useCallback,
    useMemo,
} from "react";
import styles from "./Placeholder.module.css";

import SVGIcon from "../../../atoms/SVGIcon";
import ReportContext from "../../Research/ReportContext";
import useHelperFunctions from "../../../helperFunctions";
import { BiMenuAltLeft } from "react-icons/bi";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import { getPara } from "../../Research/Competiton/CompetetionAcc";
import Textfield from "../../../atoms/CustomTextfield/Textfield";
import {
    debounceWrapper,
    getKeytermVariations,
} from "../../../../utility/helperFun";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import CustomHighlighter from "../../../atoms/CustomHighlighter";
import ViewportList from "react-viewport-list";
import {
    droppableIds,
    droppableTypes,
    HEADINGS_EVENT_TYPES,
} from "./cruiseConstant";
import { CruiseContext } from "./StepsWizard";
import { Outline } from "./classes";
import { v4 } from "uuid";
import { CRUISE_TYPES } from "./useCruiseHook";
const useStyles1 = makeStyles((theme) => ({
    root: {
        borderRadius: 3,
        border: "none",
        margin: " 0",
        boxShadow: "none",
        padding: 0,
    },
    expanded: {
        borderRadius: 3,
        border: "none !important",
        margin: "0 !important",
    },
    content: {
        margin: 0,
    },
    expandIcon: {
        padding: 2.5,
        marginRight: 0,
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
    },
}));
let outlineHashMap = new Map();

const PlaceholderOutline = React.memo(
    ({ item, setAddHeading, added, search = "", headings }) => {
        const { reportInfo } = useContext(ReportContext);
        const { postData } = useHelperFunctions();
        const classes = useStyles1();
        const classes2 = useStyles();
        const [data, setData] = useState([]);
        const [rePhrased, setRePhrased] = useState("");
        const [draggingType, setDraggingType] = useState(
            droppableTypes.outlineH3,
        );

        useEffect(() => {
            setData(item.h_tags);
        }, [item]);

        const [arr, setArr] = useState([]);

        useEffect(() => {
            setArr(new Array(data?.h?.length).fill(0));
        }, [data]);

        return (
            <div className={styles.outlinePlaceholder}>
                <Accordion defaultExpanded style={{ boxShadow: "none" }}>
                    <AccordionSummary
                        classes={classes}
                        expandIcon={
                            <SVGIcon
                                src="/New UI/SVG/RiArrowDownSFill.svg"
                                style={{
                                    color: "#c9e3ff",
                                }}
                                size={20}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={styles.head}>
                            <span className={styles.outlineRank}>
                                #{item.rank}
                            </span>
                            <div className={styles.outlineTitle}>
                                {item.url && (
                                    <a
                                        className={styles.url}
                                        href={item.url}
                                        target="_blank"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {new URL(item.url).origin}
                                    </a>
                                )}

                                {item.url_title}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails classes={classes2}>
                        <Droppable
                            isDropDisabled={true}
                            droppableId={
                                droppableIds.topRanked + "_" + item.index
                            }
                            type={draggingType}
                        >
                            {(provided) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={styles.outlineBody}
                                    >
                                        {data?.h?.length > 0 &&
                                            data?.h.map((heading, i) => {
                                                return (
                                                    <Item
                                                        i={i}
                                                        heading={heading}
                                                        item={item}
                                                        search={search}
                                                        headings={headings}
                                                        key={i}
                                                        setDraggingType={
                                                            setDraggingType
                                                        }
                                                    />
                                                );
                                            })}

                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    },
);

const Item = ({
    i,
    heading,
    setDraggingType,

    item,

    search,

    headings = [],
}) => {
    const [added, setAdded] = useState(false);

    const { mpTrackCruiseAddition } = useMixpanelHook();
    const dispatchStepsData = useContext(CruiseContext);
    const reportInfo = useContext(ReportContext);

    const handler = useCallback(() => {
        mpTrackCruiseAddition({
            type: heading.tag_name == "h2" ? "H2" : "H3",
            origin: "Competition",
            platform: "Top Rank Outlines",
        });
        dispatchStepsData({
            type: CRUISE_TYPES.ADD_OUTLINES,
            data: new Outline({
                id: v4(),

                tag:
                    heading.tag_name == "h2"
                        ? Outline.tags.h2
                        : Outline.tags.h3,
                type: Outline.type.heading,
                data: heading.text,
            }),
            field: Outline.type.heading,
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (outlineHashMap.has(heading.text)) {
                setAdded(true);
            } else {
                setAdded(false);
            }
        }, 200);
    }, [headings]);
    return (
        <ItemTextCache
            i={i}
            heading={heading}
            added={added}
            search={search}
            reportInfo={reportInfo}
            handler={handler}
            setDraggingType={setDraggingType}
            rank={item.rank}
        />
    );
};

const ItemTextCache = React.memo(
    ({
        i,
        heading,
        added,
        search,
        reportInfo,
        handler,
        setDraggingType,
        rank,
    }) => {
        return (
            <Draggable
                key={i}
                draggableId={rank + "_" + heading.index}
                index={i}
            >
                {(provided, snap) => (
                    <div
                        {...provided.draggableProps}
                        style={{
                            ...provided.draggableProps?.style,
                            marginTop: snap.isDragging ? 0 : 0,
                            marginLeft: snap.isDragging ? 0 : 0,
                            backgroundColor: snap.isDragging
                                ? "rgba(232, 237, 248, 0.9)"
                                : "",

                            display: heading.tag_name == "h1" ? "none" : "",
                        }}
                        ref={provided.innerRef}
                        onClick={() => {
                            handler();
                        }}
                        className={
                            styles.headingRow +
                            " " +
                            (heading.tag_name != "h2" && styles.h3)
                        }
                        onMouseDown={() =>
                            setDraggingType(
                                heading.tag_name == Outline.tags.h2
                                    ? droppableTypes.outlineH2
                                    : droppableTypes.outlineH3,
                            )
                        }
                    >
                        <div
                            {...provided.dragHandleProps}
                            className={styles.row}
                        >
                            <SVGIcon
                                color="lightgray"
                                size={16}
                                src="/New UI/SVG/drag.svg"
                                style={{ paddingTop: 1 }}
                            />
                            <div className={styles.content}>
                                <div>
                                    <span className={styles.headingType}>
                                        {added ? (
                                            <SVGIcon
                                                size={14}
                                                tooltip={"Added to brief"}
                                                src={"/New UI/SVG/check.svg"}
                                            />
                                        ) : (
                                            heading.tag_name
                                        )}
                                    </span>
                                </div>
                                <span
                                    className={styles.headingText}
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: added
                                            ? "line-through"
                                            : "none",
                                    }}
                                >
                                    {heading.text}
                                </span>
                            </div>
                        </div>
                        {
                            <div className={styles.btnContainer}>
                                <Description text={heading} />
                            </div>
                        }
                    </div>
                )}
            </Draggable>
        );
    },
);

const PlaceholderOutlines = ({
    topRankOutlines = [],
    setAddHeading,

    added,
    headings = [],
    questions = [],
}) => {
    const ref = useRef();
    const [topRankOutlinesOdd, setTopRankOutlinesOdd] = useState([]);
    const [topRankOutlinesEven, setTopRankOutlinesEven] = useState([]);

    const [range, setRange] = React.useState(10);
    const [search, setSearch] = useState("");

    useEffect(() => {
        outlineHashMap = new Map();
        headings.forEach((heading) => {
            heading.outlineH3s.forEach((element) => {
                outlineHashMap.set(element?.data);
            });
            outlineHashMap.set(heading?.data);
        });
    }, [headings]);

    useEffect(() => {
        if (!search.length) {
            setRange(10);
        }
    }, [search]);
    useEffect(() => {
        if (ref.current.parentNode.parentNode.parentNode) {
            ref.current.parentNode.parentNode.parentNode.scrollTop = 0;
            // console.log(ref, "//////");
        }
    }, [range]);

    useEffect(() => {
        if (ref.current.parentNode.parentNode.parentNode && search.trim()) {
            ref.current.parentNode.parentNode.parentNode.scrollTop = 0;
            // console.log(ref, "//////");
        }
    }, [search]);

    useEffect(() => {
        setTopRankOutlinesOdd(
            topRankOutlines.filter((item) => {
                if (
                    item.rank % 2 == 0 ||
                    (!search
                        ? item.rank <= range - 10 || item.rank > range
                        : false)
                ) {
                    return false;
                } else if (!search) return true;
                else {
                    for (const heading of item.h_tags.h) {
                        if (
                            (heading.tag_name == "h3" ||
                                heading.tag_name == "h2") &&
                            heading.text
                                .toLowerCase()
                                .includes(search?.toLowerCase())
                        ) {
                            return true;
                        }
                    }
                    return false;
                }
            }),
        );

        setTopRankOutlinesEven(
            topRankOutlines.filter((item) => {
                if (
                    item.rank % 2 != 0 ||
                    (!search
                        ? item.rank <= range - 10 || item.rank > range
                        : false)
                ) {
                    return false;
                } else {
                    for (const heading of item.h_tags.h) {
                        if (
                            heading.text
                                .toLowerCase()
                                .includes(search?.toLowerCase())
                        ) {
                            return true;
                        }
                    }
                    return false;
                }
            }),
        );
    }, [search, range, topRankOutlines]);

    console.log(topRankOutlinesEven);

    useEffect(() => {
        if (topRankOutlinesOdd.length > 0 && topRankOutlinesEven.length > 0) {
            if (topRankOutlinesOdd[0].rank > topRankOutlinesEven[0].rank) {
                let tempEven = [...topRankOutlinesEven];
                let tempOdd = [...topRankOutlinesOdd];
                setTopRankOutlinesEven(tempOdd);
                setTopRankOutlinesOdd(tempEven);
            }
        }
    }, [topRankOutlinesEven, topRankOutlinesOdd]);

    const { keyTerms, highlight_keyTerm, reportInfo } =
        useContext(ReportContext);
    const obj = useMemo(() => new Mark(ref.current), [ref.current]);
    const highlightKeyTerms = () => {
        if (search) {
            setImmediate(() => {
                obj.mark([search]);
            });
        } else if (highlight_keyTerm)
            setImmediate(() => {
                keyTerms
                    .sort((a, b) => b?.keyword?.length - a?.keyword?.length)
                    .forEach((term) => {
                        setImmediate(() => {
                            obj.mark(term.keyword, {
                                separateWordSearch: false,
                                accuracy: {
                                    value: "exactly",
                                    limiters: [
                                        ",",
                                        "?",
                                        ".",
                                        '"',
                                        "'",
                                        "!",
                                        ")",
                                        "(",
                                        "{",
                                        "}",
                                        "[",
                                        "]",
                                        ":",
                                        ";",
                                        "-",
                                    ],
                                },
                                ignorePunctuation: ["?"],
                            });
                        });
                    });
            });
        else {
            obj.unmark();
        }
    };

    useEffect(() => {
        highlightKeyTerms();
        return () => {
            obj.unmark();
        };
    }, [range, highlight_keyTerm, search]);
    let timeoutRef = useRef(null);

    return (
        <>
            <div className={styles.rangeOptions}>
                <div
                    className={styles.setRange__left}
                    onClick={() => {
                        if (!search.length)
                            setRange(range >= 20 ? range - 10 : 10);
                    }}
                    style={{
                        color: range == 10 ? "rgba(1, 76, 197, 0.4)" : "",
                        background:
                            range == 10 ? "rgba(239, 244, 255, 0.8)" : "",
                    }}
                >
                    <SVGIcon src={"/New UI/SVG/RiArrowLeftSFill.svg"} />
                </div>
                <div className={styles.currentRange}>
                    {search.length ? "1" : range - 10 + 1} -{" "}
                    {search.length ? reportInfo.competitors_count : range}
                </div>
                <div className={styles.totalRange}>
                    of {reportInfo.competitors_count}
                </div>
                <div
                    className={styles.setRange__right}
                    onClick={() => {
                        if (!search.length)
                            setRange(
                                range <= reportInfo.competitors_count - 10
                                    ? range + 10
                                    : reportInfo.competitors_count,
                            );
                    }}
                    style={{
                        color:
                            range == reportInfo.competitors_count ||
                            search.length
                                ? "rgba(1, 76, 197, 0.4)"
                                : "",
                        background:
                            range == reportInfo.competitors_count ||
                            search.length
                                ? "rgba(239, 244, 255, 0.8)"
                                : "",
                    }}
                >
                    <SVGIcon src={"/New UI/SVG/RiArrowRightSFill.svg"} />
                </div>
            </div>
            <div className={styles.searchOutlines_wrapper}>
                <Textfield
                    customClass={styles.searchOutlines}
                    height={32}
                    label={""}
                    placeholder={"Search here..."}
                    containerStyle={{
                        padding: "0px 10px 5px 10px",
                        background: "white",
                    }}
                    props={{
                        onChange: (e) => {
                            const value = e.target.value.trim();
                            debounceWrapper(
                                () => setSearch(value),
                                500,
                                timeoutRef,
                            );
                        },
                    }}
                    Icon={() => (
                        <SVGIcon
                            src={"/New UI/SVG/AiOutlineSearch.svg"}
                            size={14}
                            style={{ color: "#c9e3ff" }}
                        />
                    )}
                    iconStyle={{
                        left: "10px",
                        color: "#c9e3ff",
                        width: "min-content",
                    }}
                />
            </div>

            <div
                ref={ref}
                className={`${styles.outlineContainer} ${
                    search ? styles.outlineContainerSearch : ""
                }`}
            >
                {topRankOutlinesOdd?.length ||
                topRankOutlinesEven?.length > 0 ? (
                    <>
                        <div className={styles.semiLeft}>
                            {topRankOutlinesOdd?.length > 0 ? (
                                <ViewportList
                                    viewportRef={{
                                        current:
                                            ref.current.parentNode.parentNode
                                                .parentNode,
                                    }}
                                    items={topRankOutlinesOdd}
                                    onViewportIndexesChange={() => {
                                        highlightKeyTerms();
                                    }}
                                >
                                    {(item) => {
                                        return (
                                            <PlaceholderOutline
                                                key={item.rank}
                                                setAddHeading={setAddHeading}
                                                item={item}
                                                added={added}
                                                search={search}
                                                headings={headings}
                                            />
                                        );
                                    }}
                                </ViewportList>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className={styles.semiRight}>
                            {topRankOutlinesEven?.length > 0 && (
                                <ViewportList
                                    itemMinSize={100}
                                    viewportRef={{
                                        current:
                                            ref.current.parentNode.parentNode
                                                .parentNode,
                                    }}
                                    items={topRankOutlinesEven}
                                >
                                    {(item) => {
                                        return (
                                            <PlaceholderOutline
                                                setAddHeading={setAddHeading}
                                                item={item}
                                                added={added}
                                                search={search}
                                                headings={headings}
                                                key={item.rank}
                                            />
                                        );
                                    }}
                                </ViewportList>
                            )}
                        </div>
                    </>
                ) : (
                    search && <NoResults />
                )}
            </div>
        </>
    );
};

const CustomTooltip = withStyles((theme) => ({
    arrow: {
        color: "#020249",
    },
    tooltip: {
        backgroundColor: "#020249",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontWeight: "400",
    },
}))(Tooltip);

const Description = ({ text }) => {
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={styles.descriptionIcon}
        >
            <CustomTooltip
                arrow={true}
                interactive
                PopperProps={{
                    disablePortal: true,
                }}
                title={
                    <div
                        style={{
                            maxHeight: 300,
                            overflow: "auto",
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3
                            style={{
                                textAlign: "center",
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#020249",
                                zIndex: 1,
                                padding: 10,
                            }}
                        >
                            {text.text}
                        </h3>
                        <p
                            style={{
                                userSelect: "text",
                                cursor: "text",
                                margin: "0 5px",
                            }}
                        >
                            {text.paragraph
                                ? getPara(text.paragraph)
                                : "No Text Data Found."}
                        </p>
                    </div>
                }
                placement="bottom"
            >
                <div>
                    <SVGIcon
                        style={{ cursor: "pointer" }}
                        size={12}
                        color="#014dc5"
                        src="/New UI/SVG/BsEyeFill.svg"
                    />
                </div>
            </CustomTooltip>
        </div>
    );
};

export const NoResults = () => (
    <p style={{ textAlign: "center", width: "100%" }}>No Results Found</p>
);

export default PlaceholderOutlines;
