import React from "react";
import styles from "./DomainLimitPopUp.module.css";
import Button from "../../atoms/Button/Button";
import useNavigation from "../../atoms/GoToRoute/useNavigation";

const DomainLimitPopUp = ({
    isPro = false,
    show = false,
    setShow = () => {},
}: {
    isPro: boolean;
    show: boolean;
    setShow: any;
}) => {
    const goTo = useNavigation();
    return (
        <div className={styles.inviteLimitModal}>
            <div className={styles.inviteLimitContent}>
                <img src="/AssetsV2/quota-exceeded-icons.svg" alt="" />

                <p>
                    {isPro
                        ? "Unlock more domains and enhance your experience. To buy additional domains contact chat support."
                        : "Unlock more domains with our Pro plan. Upgrade now or contact support for additional domains"}
                </p>
            </div>

            <div className={styles.inviteLimitButtons}>
                <Button
                    text={isPro ? "Talk to Support" : "Upgrade"}
                    handler={() => {
                        if (isPro) {
                            window.userengage &&
                                window.userengage("widget.show");
                            window.userengage &&
                                window.userengage("widget.open");
                        } else {
                            goTo("BILLING_PLANS");
                        }
                        setShow(!show);
                    }}
                    width={isPro ? "140px" : "100px"}
                />
            </div>
        </div>
    );
};

export default DomainLimitPopUp;
