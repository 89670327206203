import {
    FLUSH_USER_GSC_INFO,
    RESET_TO_DEFAULT_CF,
    RESET_USER_GSC_INFO_MONTHLY_INSIGHTS,
    SET_USER_GSC_INFO,
    SET_USER_GSC_INFO_MONTHLY_INSIGHTS,
    UPDATE_CF,
    UPDATE_CONNECTED_DOMAINS,
    UPDATE_DEFAULT_CF,
} from "../actiontypes";

const initialState = {
    active_countries_info: [],
    connectedDomains: [],
    step: 1,
    monthly_insights_info: {},
    loggedOut: 0,
    default_cannibalization_filters: {
        cr: { min: 0.1 },
        impression: { min: 10 },
        avg_pos: { max: 30, min: 0 },
        included_terms: [],
        excluded_terms: [],
        sort: { key: "totalImpression", order: 1 },
        currentPage: 0,
        itemsPerPage: 25,
    },
    cannibalization_filters: {
        cr: { min: 0.1 },
        impression: { min: 10 },
        avg_pos: { max: 30, min: 0 },
        included_terms: [],
        excluded_terms: [],
        sort: { key: "totalImpression", order: 1 },
        currentPage: 0,
        itemsPerPage: 25,
    },
};

export default function (state = initialState, action) {
    const data = action.data;
    switch (action.type) {
        case SET_USER_GSC_INFO:
            return { ...state, ...data };
        case UPDATE_CONNECTED_DOMAINS:
            return {
                ...state,
            };
        case SET_USER_GSC_INFO_MONTHLY_INSIGHTS:
            return {
                ...state,
                monthly_insights_info: {
                    ...state.monthly_insights_info,
                    ...data,
                },
            };
        case RESET_USER_GSC_INFO_MONTHLY_INSIGHTS:
            return {
                ...state,
                monthly_insights_info: {},
            };
        case FLUSH_USER_GSC_INFO:
            return {
                active_countries_info: [],
                domain_name: [],
                step: 1,
                monthly_insights_info: {},
                loggedOut: 1,
                cannibalization_filters: {
                    ...state.default_cannibalization_filters,
                },
            };
        case UPDATE_CF:
            return {
                ...state,
                cannibalization_filters: {
                    ...state.cannibalization_filters,
                    ...data,
                },
            };
        case UPDATE_DEFAULT_CF:
            return {
                ...state,
                default_cannibalization_filters: {
                    ...state.default_cannibalization_filters,
                    ...data,
                },
            };
        case RESET_TO_DEFAULT_CF:
            return {
                ...state,
                cannibalization_filters: {
                    cr: { min: 0.1 },
                    impression: { min: 10 },
                    avg_pos: { max: 30, min: 0 },
                    included_terms: [],
                    excluded_terms: state?.brandKeyword
                        ? [state.brandKeyword]
                        : [],
                    sort: { key: "totalImpression", order: 1 },
                    currentPage: 0,
                    itemsPerPage: 25,
                },
            };
        default:
            return state;
    }
}
