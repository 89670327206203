import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";

import { BsArrowRight } from "react-icons/bs";

import { InterlinkingContext } from "./InterLinkingContextProvider";
import {
    BASE_URL,
    INTERLINKING_DOMAIN_STATUS,
    WEBTUNE_DOMAIN_STATUS,
    endPoints,
} from "./constants";
import GSCSimpleLogin from "../../organisms/GSCSimpleLogin/GSCSimpleLogin";
import Button from "../../atoms/Button/Button";
import VideoModal from "../NewDashboard/VideoModal";
import SVGIcon from "../../atoms/SVGIcon";
import Modal from "../../atoms/Modal/Modal";
import useHelperFunctions from "../../helperFunctions";
import { ClickAwayListener } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useLinkManagerMixpanel } from "./useLinkManagerMixpanel";
import { displayAlert } from "../../../store/actions/alert";

export const getTrimmedDomainURL = (siteUrl = "") => {
    if (!siteUrl || !siteUrl.length) {
        return "";
    } else if (siteUrl?.includes("sc-domain")) {
        return siteUrl.split(":")[1];
    } else {
        try {
            const parsedUrl = new URL(siteUrl);
            return parsedUrl.hostname;
        } catch (err) {
            return siteUrl;
        }
    }
};

export const SelectList = ({
    otherDomains = [],
    selectedDomain,
    setSelectedDomain = () => {},
    label = "",
    setDomainName = () => {},
}) => {
    const [isDropDownEnabled, setIsDropDownEnabled] = useState(false);
    const [searchCountry, setSearchCountry] = useState("");

    useEffect(() => {
        console.log(otherDomains);
        let temp = otherDomains.filter((dmns) => dmns.used == 0);
        if (temp.length > 0) {
            setSelectedDomain(temp[0].siteUrl);
            if (temp) setDomainName(temp[0].siteUrl);
        } else {
        }
    }, [otherDomains]);

    return (
        <>
            <div className={styles.domainsList}>
                <label>{label}</label>
                <div className={styles.dropDownContainer}>
                    <div
                        className={`${styles.selectedDomain}`}
                        onClick={() => setIsDropDownEnabled(!isDropDownEnabled)}
                    >
                        <div className={styles.selectedDomain_left}>
                            {selectedDomain.length > 0 ? (
                                <>
                                    <img
                                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                            selectedDomain?.split(":")[1]
                                        }&sz=32`}
                                        width={"24px"}
                                    />
                                    <span>
                                        {getTrimmedDomainURL(selectedDomain)}
                                    </span>
                                </>
                            ) : (
                                <span
                                    style={{
                                        color: "#3f5575",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                    }}
                                >
                                    Select Domain...
                                </span>
                            )}
                        </div>
                        <div className={styles.selectedDomain_right}>
                            <SVGIcon
                                size={20}
                                src={"/New UI/SVG/arrow-head-down.svg"}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                    {isDropDownEnabled && (
                        <ClickAwayListener
                            onClickAway={() => {
                                setIsDropDownEnabled(false);
                            }}
                        >
                            <div className={styles.dropDownList}>
                                {otherDomains.map((d, index) => {
                                    return (
                                        <div
                                            className={`${styles.otherDomain}`}
                                            onClick={() => {
                                                setSelectedDomain(d.siteUrl);
                                                setDomainName(d.siteUrl);
                                                setIsDropDownEnabled(false);
                                            }}
                                        >
                                            <div
                                                className={
                                                    styles.selectedDomain_left
                                                }
                                            >
                                                <img
                                                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${
                                                        d?.siteUrl?.split(
                                                            ":",
                                                        )[1]
                                                    }&sz=32`}
                                                />

                                                <span>
                                                    {getTrimmedDomainURL(
                                                        d?.siteUrl,
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
            </div>
        </>
    );
};

export const isValidDomain = (domainName) => {
    const regex = new RegExp(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
    );
    return regex.test(domainName);
};

const LinkingOnBoardFlow = ({ setSitemapModal = () => {} }) => {
    const { lMAddDomain } = useLinkManagerMixpanel();
    const [loading, setLoading] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [userGscInfo, setUserGscInfo] = useState({});
    const [gscDomains, setGscDomains] = useState([]);
    const [isDomainsModal, setIsDomainsModal] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState("");
    const { setState } = useContext(InterlinkingContext);
    const { postData, getData } = useHelperFunctions();
    const dispatch = useDispatch();
    const lmVideo = useSelector((state) => state.video.linkManager);
    const isValidSitemapUrl = () => {
        const regex = new RegExp(
            /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        );
        return regex.test(sitemapUrl);
    };

    const getGscDomains = (gscInfo) => {
        return new Promise((res1, rej) => {
            (async () => {
                try {
                    const res = await getData({
                        url: `/api/analyse/getDomainList?gsc_id=${gscInfo?.gsc_id}`,
                    });
                    if (res.status == 200) {
                        const data =
                            res.data.domainList?.map((dm) => ({
                                ...dm,
                                used: 0,
                            })) || [];
                        setGscDomains(data);
                        setIsDomainsModal(true);
                        if (data.length == 0) {
                            dispatch(
                                displayAlert({
                                    alertType: "error",
                                    alertMessage: `No domains detected for this GSC account.`,
                                }),
                            );
                            rej();
                            return;
                        }

                        res1("");
                    } else {
                        setGscDomains([]);
                        setIsDomainsModal(false);
                    }
                } catch (err) {
                    rej();
                } finally {
                    rej();
                }
            })();
        });
    };

    const googleCallback = async (response) => {
        lMAddDomain({
            screen: "Internal Linking Login",
            ctaUsed: "Connect Domain with GSC",
        });
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/loginToGsc",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    webTuneInternLinking: 1,
                },
            });
            if (res.status == 200) {
                setUserGscInfo({
                    ...res.data?.resp?.userInfo,
                });
                const data = await getGscDomains(res.data?.resp?.userInfo);
                setLoading(false);
            } else {
                dispatch(
                    displayAlert({
                        alertType: "error",
                        alertMessage: `${res.response.data.message}`,
                    }),
                );
            }
        }
        setLoading(false);
    };

    const addDomainRequest = async (obj = { isGsc: false, gscId: null }) => {
        let _domainName = domainName;
        if (!_domainName) {
            return;
        }
        setLoading(true);

        lMAddDomain({
            screen: "Internal Linking Login",
            ctaUsed: "Connect Domain with GSC",
        });
        let payload = {
            domain: _domainName,
            source: null,
            is_gsc: obj.isGsc ? true : false,
            ...(obj.gscId && { gsc_id: obj.gscId || null }),
        };

        const res = await postData({
            completeUrl: BASE_URL + endPoints.addDomain,
            payload,
        });

        if (res.status == 200) {
            setSitemapModal({
                domainName: _domainName,
                is_gsc: obj.isGsc ? true : false,
                ...(obj.gscId && { gsc_id: obj.gscId || null }),
                id: res.data.domain_id,
            });
            setState((ps) => ({
                ...ps,
                selectedDomainInfo: {
                    domain: _domainName,
                    id: res.data.domain_id,
                    status: WEBTUNE_DOMAIN_STATUS.NOT_STARTED,
                    gsc_id: obj.gscId || null,
                },
                domainsList: [
                    {
                        domain: _domainName,
                        id: res.data.domain_id,
                        status: WEBTUNE_DOMAIN_STATUS.NOT_STARTED,
                        gsc_id: obj.gscId || null,
                    },
                ],
            }));
        } else {
        }
        setLoading(false);
    };

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.onBoardContainer}>
                <div className={styles.loginCardTitle}>
                    Welcome to Link Manager 👋
                </div>
                <div className={styles.loginCardSubTitle}>
                    Your one stop solution for internal linking
                </div>

                <div
                    className={styles.videoContainer}
                    style={{ justifyContent: "center" }}
                >
                    <VideoModal
                        link={`${
                            lmVideo?.link || lmVideo
                        }?modestbranding=1&rel=0&loop=1`}
                        zoomVal={1.2}
                        modal={false}
                        sourceWhere={"trafficAnalyser"}
                        customClass={styles.video}
                    />
                </div>
                <div className={styles.ctaContainer}>
                    <h4
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontWeight: "500",
                            color: "#2c3948",
                        }}
                    >
                        To get Internal Linking opportunities
                    </h4>
                    <GSCSimpleLogin
                        googleCallback={googleCallback}
                        loading={loading}
                        setLoading={setLoading}
                        screen={"Internal Linking"}
                        width={241}
                    />
                </div>
            </div>
            {isDomainsModal && gscDomains?.length > 0 && (
                <Modal
                    setModal={() => {
                        setIsDomainsModal(false);
                    }}
                >
                    <div className={styles.domainsListWrapper}>
                        <div className={styles.listWrapper}>
                            <SelectList
                                selectedDomain={selectedDomain}
                                setSelectedDomain={setSelectedDomain}
                                label={"Select Domain"}
                                otherDomains={gscDomains}
                                setDomainName={setDomainName}
                            />
                        </div>
                        <Button
                            text={"Get Started"}
                            width={200}
                            style={{ margin: "auto" }}
                            handler={() => {
                                lMAddDomain({
                                    screen: "Internal Linking Login",
                                    ctaUsed: "Get Started",
                                });
                                addDomainRequest({
                                    isGsc: true,
                                    gscId: userGscInfo?.gsc_id,
                                });
                                setTimeout(() => {
                                    setIsDomainsModal(false);
                                }, 1000);
                            }}
                            loading={loading}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default LinkingOnBoardFlow;
