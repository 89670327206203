import React, { useContext, useEffect, useState } from "react";
import { FaQuora } from "react-icons/fa";
import { SiReddit } from "react-icons/si";
import { FcGoogle, FcServices } from "react-icons/fc";
import { GiGears } from "react-icons/gi";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlinePencil } from "react-icons/hi";
import { openLinkWindow } from "../../../../utility/validateFunctions";
import CopyText from "../CopyText";
import styles from "./PeopleQuestions.module.css";
import NewDropDown from "../../../molecules/Creator/Overview/NewDropDown";
import { AiFillRobot } from "react-icons/ai";
import TextShortner from "../../../atoms/TextShortner/TextShortner";
import WriteToEditor from "../../../organisms/TextEditor/Toolbar/WriteToEditor";
import { CircularProgress } from "@material-ui/core";
import { GENERATE_CONTENT } from "../../../api.json";

import useHelperFunctions from "../../../helperFunctions";
import { VscGlobe } from "react-icons/vsc";
import { questionsPlatformArr } from "../../../molecules/Creator/Overview/PeopleQuestionsOverview";
import SVGIcon from "../../../atoms/SVGIcon";
import ReportContext from "../ReportContext";
import EditorContext from "../../TextEditor/EditorContext";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import Quill from "quill";
const Delta = Quill.import("delta");

const icon = (platform) => {
    switch (platform) {
        case "Google":
            return (
                <FcGoogle className={styles.Icon + " " + styles.googleIcon} />
            );
        case "Generate":
            return (
                <span className={styles.Icon + " " + styles.GenIcon}>AI</span>
            );
        case "Reddit":
            return (
                <SiReddit className={styles.Icon + " " + styles.RedditIcon} />
            );
        case "Serp":
            return (
                <SVGIcon
                    tooltip={"SERP Question"}
                    size={18}
                    src="/New UI/SVG/QuestionSources/serp.svg"
                    style={{
                        color: "#014dc5",
                    }}
                    // className={styles.Icon + " " + styles.QuoraIcon}
                />
            );
        default:
            return (
                <SVGIcon
                    size={18}
                    src="/New UI/SVG/quora-svgrepo-com.svg"
                    className={styles.Icon + " " + styles.QuoraIcon}
                />
            );
    }
};

export const SingleQuestion = ({
    q,
    writingState,
    setWritingState,

    reportInfo,
    writeWithAnimation,
}) => {
    const [loading, setLoading] = useState(false);
    const [clickToAdd, setClickToAdd] = useState(false);
    const { postData } = useHelperFunctions();
    const { isShared } = useContext(ReportContext);
    const { versionData } = useContext(EditorContext);

    console.log(reportInfo, "infooooooooooooooo");

    useEffect(() => {
        let timer = null;
        if (clickToAdd) {
            timer = setTimeout(() => {
                setClickToAdd(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [clickToAdd]);

    async function generateContent(useCaseName, text) {
        document?.quill?.editor?.enable(false);
        setLoading(true);
        setWritingState({ state: true, text: "", useCase: -1 });
        try {
            let prompt = getPrompt(useCaseName, text);
            const res = await postData({
                url: GENERATE_CONTENT,
                payload: {
                    ...prompt,
                    report_id: reportInfo.report_id,
                },
            });
            let answer = `${res?.data?.output[0]?.content?.trim()}`;

            if (res.status == 200) {
                for (
                    let index = document.quill.editor.cursor;
                    index < document.quill.editor.getLength();
                    index++
                ) {
                    if (
                        document.quill.editor.getText(index, 1) == " " ||
                        document.quill.editor.getText(index, 1) == "\n"
                    )
                        break;
                    document.quill.editor.cursor++;
                }
                document.quill.editor.cursor += 1;
                document.quill.editor.updateContents(
                    new Delta()
                        .retain(document?.quill?.editor?.cursor)
                        .insert("\n", { header: 2 }),
                    "api",
                );
                document.quill.editor.updateContents(
                    new Delta()
                        .retain(document?.quill?.editor?.cursor)
                        .insert(text),
                    "api",
                );
                document.quill.editor.cursor += text.length;
                document.quill.editor.updateContents(
                    new Delta()
                        .retain(document?.quill?.editor?.cursor + 1)
                        .insert("\n"),
                    "api",
                );
                document.quill.editor.cursor += 1;
                await writeWithAnimation(answer);
            }
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
        document?.quill?.editor?.enable(true);
        setLoading(false);
        setWritingState({ state: false, text: "", useCase: -1 });
    }
    function getPrompt(name, text) {
        switch (name) {
            case "answer":
                return {
                    useCase: 32,
                    description: text,
                };

            default:
                break;
        }
    }

    const addToEditor = (str) => {
        if (writingState.state) return;
        WriteToEditor({
            data: !document.quill.editor.getText(
                document.quill.editor.cursor,
                1,
            )
                ? str
                : document.quill.editor.getText(
                      document.quill.editor.cursor,
                      1,
                  ) != "\n"
                ? "\n" + str
                : str,
            type: "text",
            length: str.length + 1,
            source: "user",
        });
        setClickToAdd(true);
    };

    return (
        <div className={styles.peepQues}>
            <div className={styles.innerFlex}>{icon(q.platform)}</div>
            {/* <CopyText text={q.question} fBasis="75%"> */}
            <LightTooltip
                title={
                    (isShared && isShared.access) || !isShared
                        ? clickToAdd
                            ? "Added!"
                            : "Click to Add"
                        : ""
                }
                placement="top"
                arrow
            >
                <p
                    className={styles.question}
                    onClick={() => addToEditor(q.question)}
                >
                    <TextShortner data={q.question} limit={60} />
                </p>
            </LightTooltip>

            <>
                {!isShared &&
                    (loading ? (
                        <CircularProgress
                            className={styles.copyIconGen}
                            size={18}
                        />
                    ) : (
                        <SVGIcon
                            src="/New UI/SVG/Ai-icon.svg"
                            size={20}
                            onClick={() => {
                                // addToEditor(q.question);
                                writingState?.state ||
                                isShared ||
                                !versionData.current
                                    ? () => {}
                                    : generateContent("answer", q.question);
                            }}
                            className={styles.copyIcon}
                            style={
                                writingState?.state ||
                                isShared ||
                                !versionData.current
                                    ? { cursor: "not-allowed" }
                                    : {}
                            }
                        />
                    ))}
            </>
            {!!q.url && (
                <a
                    onClick={(e) => {
                        openLinkWindow(e, q.url);
                    }}
                    target="_blank"
                    className={styles.source}
                >
                    <FiExternalLink />
                </a>
            )}
        </div>
    );
};

const platformOrder = {
    Google: 0,
    Quora: 1,
    Reddit: 2,
    Generate: 3,
};

export default function PeopleQuestions({
    list = [],
    writingState,
    setWritingState,

    aiWordLimitSetting,
    setAiWordLimitSetting,
    reportInfo,
    writeWithAnimation,
}) {
    const [sortType, setSortType] = useState(questionsPlatformArr[0]);
    const [_list, setList] = useState([]);

    useEffect(() => {
        setList(() => {
            let temp = list
                .sort(
                    (x, y) =>
                        platformOrder[x.platform] - platformOrder[y.platform],
                )
                .filter((item) => {
                    if (sortType.value == "All") {
                        return true;
                    }

                    return item.platform == sortType.value;
                });
            if (sortType.value == "All") {
                let serpArr = temp.filter(
                    (item) => item.platform == questionsPlatformArr[2].value,
                );
                let googleArr = temp.filter(
                    (item) => item.platform == questionsPlatformArr[1].value,
                );
                temp = temp.filter(
                    (item) => item.platform != questionsPlatformArr[1].value,
                );
                temp = temp.filter(
                    (item) => item.platform != questionsPlatformArr[2].value,
                );
                temp = [...googleArr, ...serpArr, ...temp];
            }

            return temp;
        });
    }, [sortType]);

    // console.log(list);
    return (
        <>
            <div className={styles.peepSelectSortContainer}>
                <div className={styles.newPeepTabsContainer}>
                    <div className={styles.questionSource}>
                        {questionsPlatformArr &&
                            questionsPlatformArr.map((item, i) => {
                                return (
                                    <span
                                        className={
                                            item.value == sortType.value
                                                ? styles.sourceSelected
                                                : styles.defaultSource
                                        }
                                        onClick={() => setSortType(item)}
                                    >
                                        {item.icon}
                                    </span>
                                );
                            })}
                    </div>
                </div>
            </div>

            {/* <h2 className={styles.resultHeading}>Questions by people</h2> */}
            <div className={styles.questionContainer}>
                {_list.map((q) => (
                    <SingleQuestion
                        q={q}
                        key={q.keyword}
                        writingState={writingState}
                        setWritingState={setWritingState}
                        reportInfo={reportInfo}
                        writeWithAnimation={writeWithAnimation}
                    />
                ))}
            </div>
        </>
    );
}
