import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useContext,
} from "react";
import Button from "../../atoms/Button/Button";

import styles from "./styles.module.css";

import AiConnector from "./AiConnector";
import AiOperator from "./AiOperator";

import { Tooltip, withStyles } from "@material-ui/core";

import useHelperFunctions from "../../helperFunctions";

import { useDispatch, useSelector } from "react-redux";
import { PLANS_CATEGORIES } from "../../../constants";
import { displayAlert } from "../../../store/actions/alert";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import LockedTextComponent from "./LockedTextComponent";
import { AiFillCaretDown } from "react-icons/ai";
import { GiHexagonalNut } from "react-icons/gi";
import SVGIcon from "../../atoms/SVGIcon";
import ReportContext from "../Research/ReportContext";
import { SMART_EDITOR } from "./editorConstants";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";

const AiBar = ({
    writeForMe,
    connectorCases,
    writingState,
    rewrite,
    canReWrite,
    toggleAISettings,
    setSerp,
    setPlagiarism,
    attachedTo,
    enable,
    editorRef,
}) => {
    const [aiDropdown2, setAiDropdown2] = useState(false);
    const buttonStyle = {
        fontWeight: "300",
        width: "120px",
        height: "26px",
        padding: "8px 9px 7px 11px",
        // borderRadius: "3px",
        fontSize: "12px",
        justifyContent: "flex-start",
        width: "max-content",
        backgroundColor: "#fff",
    };

    const otherInfo = useSelector((state) => state.otherInfo);
    const { LockConfigurator } = useUserAccessHook();

    const dispatch = useDispatch();

    const openUpgradeDialog = () => {
        dispatch(
            displayAlert({
                alertMessage: "",
                alertType: "popup",
                errorData: {
                    status: "upgrade",
                },
            }),
        );
    };

    const operatorCases = [
        "Questions about the content",
        "Topics about the content",
        "Outline about the content",
        "Summarize",
        "Conclude the content",
    ];
    const noComma = [
        "but",
        "while",
        "notwithstanding",
        "neverthless",
        "whereas",
        "after",
        "if",
        "unless",
        "as long as",
        "provided that",
    ];

    // const SettingModalComponent = ({ reportInfo }) => {
    //   return (

    //   );
    // };
    useEffect(() => {
        if (editorRef) {
            const toolbar = document.getElementsByClassName("ql-toolbar")[0];
            document.getElementById("toolbar").appendChild(toolbar);
        }
    }, [editorRef]);

    const reportContext = useContext(ReportContext);

    return enable ? (
        <div
            className={styles.toolbarContainer}
            style={{ paddingLeft: attachedTo != SMART_EDITOR ? "16px" : "3px" }}
        >
            <div
                style={
                    attachedTo == SMART_EDITOR
                        ? { marginLeft: 68, width: "auto" }
                        : {}
                }
                className={` ${styles.toolbar}`}
            >
                <div className={styles.aiButtons}>
                    <div style={{ position: "relative" }}>
                        <LightTooltip
                            title={`Use this for writing ahead with our AI. Keyboard Shortcut: (${
                                !navigator.userAgent.includes("Mac")
                                    ? "Ctrl +"
                                    : "⌘ "
                            } K) `}
                            arrow
                            placement="bottom"
                            disableFocusListener
                            disableTouchListener
                        >
                            <div>
                                <Button
                                    customClass={styles.button}
                                    disabled={
                                        writingState.state &&
                                        writingState.useCase != 64
                                    }
                                    iconType="JSX"
                                    Icon={"/New UI/SVG/create-content.svg"}
                                    iconReverse={true}
                                    text={
                                        writingState.state &&
                                        writingState.useCase == 64
                                            ? `${writingState.text}`
                                            : "Write"
                                    }
                                    style={{
                                        ...buttonStyle,
                                        marginLeft: "4px",
                                        // backgroundColor: "unset",
                                    }}
                                    handler={() => {
                                        writeForMe(64);
                                    }}
                                    loadingText={
                                        writingState.state &&
                                        writingState.useCase == 64
                                    }
                                    lessDots={true}
                                ></Button>
                            </div>
                        </LightTooltip>
                    </div>
                    {/* <div className={styles.separator}></div> */}
                    <div className={styles.separatorNew}></div>
                    <div style={{ position: "relative" }}>
                        <LightTooltip
                            title={
                                LockConfigurator(FEATURE_TYPES.instructLock)
                                    ? ""
                                    : `Use this for giving instructions to our AI. Keyboard Shortcut: (${
                                          !navigator.userAgent.includes("Mac")
                                              ? "Ctrl +"
                                              : "⌘ "
                                      } Enter) `
                            }
                            arrow
                            disableFocusListener
                            disableTouchListener
                            placement="bottom"
                        >
                            <div>
                                <Button
                                    customClass={styles.button}
                                    iconType="JSX"
                                    disabled={
                                        writingState.state &&
                                        writingState.useCase != 75
                                    }
                                    Icon={"/New UI/SVG/instruct-icon.svg"}
                                    iconReverse={true}
                                    text={
                                        writingState.state &&
                                        writingState.useCase == 75
                                            ? `${writingState.text}`
                                            : "Instruct"
                                    }
                                    style={{
                                        ...buttonStyle,
                                        // backgroundColor: "unset",
                                    }}
                                    locked={LockConfigurator(
                                        FEATURE_TYPES.instructLock,
                                    )}
                                    LockedTextComponent={LockedTextComponent}
                                    handler={() => {
                                        writeForMe(75);
                                    }}
                                    loadingText={
                                        writingState.state &&
                                        writingState.useCase == 75
                                    }
                                    lessDots={true}
                                ></Button>
                            </div>
                        </LightTooltip>
                    </div>
                </div>
                <div className={styles.separatorNew}></div>

                <div style={{ position: "relative" }}>
                    <Button
                        customClass={styles.button}
                        disabled={
                            writingState.state && writingState.useCase != 65
                        }
                        Icon={() => (
                            <AiFillCaretDown size={12} fill="#0079E4" />
                        )}
                        text={
                            writingState.state && writingState.useCase == 65
                                ? `${writingState.text}`
                                : "Connectors"
                        }
                        style={buttonStyle}
                        handler={() => {
                            setAiDropdown2(true);
                        }}
                        loadingText={
                            writingState.state && writingState.useCase == 65
                        }
                    ></Button>

                    {aiDropdown2 && (
                        <AiConnector
                            writeForMe={writeForMe}
                            setAiDropdown={setAiDropdown2}
                            list={connectorCases}
                        />
                    )}
                </div>

                {/* <div style={{ position: "relative" }}>
          <Button
            customClass={styles.button}
            disabled={writingState.state && writingState.useCase != 65}
            Icon={GiHexagonalNut}
            iconReverse={true}
            text={"Go Nuts"}
            style={buttonStyle}
            handler={() => {
              reportContext.setGoNutsModal(true);
            }}
            loadingText={writingState.state && writingState.useCase == 65}
          ></Button>
        </div> */}
                <div className={styles.separatorNew}></div>

                <LightTooltip
                    title={
                        LockConfigurator(FEATURE_TYPES.instructLock)
                            ? ""
                            : "AI Settings"
                    }
                    arrow
                    placement="bottom"
                >
                    <div>
                        <Button
                            handler={() => toggleAISettings((e) => !e)}
                            customClass={styles.button}
                            locked={LockConfigurator(
                                FEATURE_TYPES.instructLock,
                            )}
                            LockedTextComponent={LockedTextComponent}
                            text=""
                            Icon={() => (
                                <SVGIcon
                                    size={12}
                                    src={"/New UI/SVG/settings.svg"}
                                />
                            )}
                            style={{ ...buttonStyle, padding: 9 }}
                        />
                    </div>
                </LightTooltip>

                {attachedTo != SMART_EDITOR && (
                    <>
                        <div className={styles.separatorNew}></div>
                        <LightTooltip
                            title={
                                LockConfigurator(FEATURE_TYPES.serpIdeaLock)
                                    ? ""
                                    : "Generate factual content"
                            }
                            placement="bottom"
                            arrow
                        >
                            <div style={{ position: "relative", zIndex: 11 }}>
                                <Button
                                    customClass={styles.button}
                                    Icon={() => (
                                        <SVGIcon
                                            size={18}
                                            src={
                                                "/New UI/SVG/serp-ideas-icon.svg"
                                            }
                                        />
                                    )}
                                    iconReverse={true}
                                    text={"SERP Ideas"}
                                    locked={LockConfigurator(
                                        FEATURE_TYPES.serpIdeaLock,
                                    )}
                                    LockedTextComponent={LockedTextComponent}
                                    style={buttonStyle}
                                    handler={() => {
                                        // setAiDropdown2(true);
                                        setSerp((ps) => !ps);
                                    }}
                                ></Button>
                            </div>
                        </LightTooltip>
                    </>
                )}

                {/* {attachedTo != SMART_EDITOR && !reportContext.auditMode && (
                    <>
                        <div className={styles.separatorNew}></div>

                        <LightTooltip
                            title={
                                LockConfigurator(
                                    FEATURE_TYPES.cruiseModeLock,
                                ) || reportContext.cruiseNudge
                                    ? ""
                                    : "Power through blog writing with our carefully created workflow to get your first draft in 5 minutes!"
                            }
                            arrow
                            placement="bottom"
                        >
                            <div style={{ position: "relative" }}>
                                {reportContext.cruiseNudge && (
                                    <div className={styles.cruiseNudge}>
                                        <span
                                            onClick={() =>
                                                reportContext.setCruiseNudge(
                                                    false,
                                                )
                                            }
                                        >
                                            <SVGIcon
                                                size={10}
                                                src={"/New UI/SVG/cross.svg"}
                                            />{" "}
                                        </span>
                                        Create content at lightning speed using
                                        Cruise Mode
                                    </div>
                                )}

                                <Button
                                    customClass={styles.button2}
                                    id={"seo-open-cruise-mode"}
                                    Icon={() => (
                                        <SVGIcon
                                            color={"#fff"}
                                            src={"/New UI/SVG/quickBlog.svg"}
                                        />
                                    )}
                                    iconReverse={true}
                                    text={"Cruise Mode"}
                                    locked={LockConfigurator(
                                        FEATURE_TYPES.cruiseModeLock,
                                    )}
                                    LockedTextComponent={LockedTextComponent}
                                    style={{
                                        ...buttonStyle,
                                        color: "#fff",
                                        backgroundColor: "#014dc5",
                                        borderRadius: 3,
                                        marginLeft: "12px",
                                        // backgroundImage:
                                        //     "linear-gradient(354deg, rgb(1 77 197 / 47%) -21%, rgb(16 207 252 / 54%) 86%)",
                                    }}
                                    handler={() => {
                                        // setAiDropdown2(true);
                                        // setSerp(true);
                                        reportContext.setGoNutsModalBtn(true);
                                    }}
                                ></Button>
                            </div>
                        </LightTooltip>
                    </>
                )} */}

                {canReWrite && !writingState.state ? (
                    <LightTooltip
                        title={"Click here to regenerate"}
                        arrow
                        placement="bottom"
                    >
                        <div style={{ margin: "0  20px 0 auto " }}>
                            <Button
                                handler={() => rewrite()}
                                height={28}
                                width={28}
                                disabled={writingState.state}
                                customClass={styles.button}
                                text=""
                                style={{
                                    ...buttonStyle,
                                    padding: 9,
                                    margin: "0  20px 0 auto ",
                                }}
                                Icon={() => (
                                    <SVGIcon
                                        size={16}
                                        src={"/New UI/SVG/refresh.svg"}
                                    />
                                )}
                            />
                        </div>
                    </LightTooltip>
                ) : null}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default AiBar;
