import React, { useEffect, useState } from "react";
import TransactionListItem from "./TransactionListItem";
import styles from "./transactions.module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { url } from "../../../../utility/config";
import { CircularProgress } from "@material-ui/core";
import qs from "qs";
import AmountLeft from "./AmountLeft";
import Button from "../../../../componentsV2/atoms/Button/Button";
import MobileTransactionCard from "./MobileTransactionCard";
import MobileFixedBottom from "./MobileFixedBottom";
import { Skeleton } from "@material-ui/lab";
import MobileSkeleton from "./MobileSkeletonCard";
import { transactionData } from "./TransactionData";
import InfiniteScroll from "react-infinite-scroll-component";
import Image from "../../../../componentsV2/atoms/Image/Image";
import NewDropDown from "../../../../componentsV2/molecules/Creator/Overview/NewDropDown";
import { RiArrowRightSLine } from "react-icons/ri";
import { BsArrowDown } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import SVGIcon from "../../../../componentsV2/atoms/SVGIcon";
import NewUIDropDown from "../../../../componentsV2/molecules/NewUIDropdown/NewUIDropDown";
const skeleton = [1, 1, 1, 1, 1, 1, 111, 11, 111, 1, 1, 1, 11, 1, 1, 1, 1, 1];
const skeleton2 = [1, 1, 1, 1, 1, 1];
const promoData = [
    {
        link: "https://www.facebook.com/groups/scalenut",
        title: "Join our Community",
        subTitle: "Get 15K words of credits",
        imgSrc: "/AssetsV2/fb-icon.svg",
    },
    {
        link: "https://twitter.com/Scalenut_AI",
        title: "Follow us on twitter",
        subTitle: "Get 10K words of credits",
        imgSrc: "/AssetsV2/twitter-logo.svg",
    },
    {
        link: "https://www.g2.com/products/scalenut/reviews",
        title: "Review us on G2",
        subTitle: "Get 50 SEO Articles worth of credits",
        imgSrc: "/AssetsV2/g-2-icon.svg",
    },
    {
        link: "https://www.youtube.com/channel/UCrKYX6ymN4mwb-rsyiTlYnA/videos",
        title: "Share video review",
        subTitle: "Get 15K words of credits",
        imgSrc: "/AssetsV2/youtube-icon.svg",
    },
];

export const transactionTypes = [
    "All",
    "Talent Network",
    "Article Writer",
    "",
    "",
    "AI Words",
    "Keyword Planner",
    "Content Optimizer",
    "Humanization",
    "AI Detection",
];

const dropdownOptions = [
    { title: "All" },
    { title: "Talent Network" },
    { title: "Article Writer" },
    { title: "AI Words" },
    { title: "Keyword Planner" },
    { title: "Content Optimizer" },
    { title: "Humanization" },
    { title: "AI Detection" },
];

function Promo({ title, subTitle, imgSrc, link }) {
    return (
        <a
            style={{ textDecoration: "none", color: "#ababab" }}
            target="_blank"
            href={link}
        >
            <div className={styles.promoContainer}>
                <img src={imgSrc} width="28px" height="28px" />
                <section>
                    <heading> {title}</heading>
                    <p>{subTitle}</p>
                </section>
                <RiArrowRightSLine />
            </div>
        </a>
    );
}

export default function Transactions({ setDialog, setIsActive }) {
    const [email, setEmail] = React.useState(null);
    const [userId, setUserId] = React.useState(null);
    const [userCompanyId, setCompanyId] = React.useState(null);
    const [userCurrency, setUserCurrency] = useState("USD");
    const token = useSelector((state) => state.auth.token);
    const role = useSelector((state) => state.auth.role);
    const company_id = useSelector((state) => state.auth.company_id);
    const [transactionsData, setTransactionsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState("");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [type, setType] = useState(transactionTypes[0]);
    const [planExpiredBar, setPlanExpiredPlan] = useState(false);

    useEffect(() => {
        setIsActive("TRANSACTIONS");
        if (!userId)
            axios({
                method: "POST",
                url: `${url}/api/user/getBalance`,
                headers: {
                    Authorization: token,
                },
            }).then((res) => setBalance(res.data));
    }, [userId]);

    useEffect(() => {
        if ((role === "admin" || role === "account_manager") && userId) {
            axios({
                method: "POST",
                url: `${url}/api/user/getBalance`,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: qs.stringify({
                    userId: userId,
                }),
            }).then((res) => {
                setBalance(res.data);
                setUserCurrency(res.data.currency);
            });
        }
    }, [email]);

    useEffect(() => {
        setPage(1);
        setHasMore(true);
        setTransactionsData([]);
        {
            setLoading(true);
            axios({
                method: "POST",
                url: `${url}/api/user/getTransactions`,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: qs.stringify({
                    companyId:
                        role == "user" ? null : userCompanyId || company_id,
                    page: 1,
                    softwareToolType: transactionTypes.indexOf(type) || null,
                }),
            })
                .then((res) => {
                    setLoading(false);
                    if (res.data.data.length < 20) setHasMore(false);
                    setTransactionsData(res.data.data);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }, [email, type]);

    useEffect(() => {
        console.log(page);
        if (page > 1) {
            axios({
                method: "POST",
                url: `${url}/api/user/getTransactions`,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: userId
                    ? qs.stringify({
                          companyId:
                              role == "user"
                                  ? null
                                  : userCompanyId || company_id,
                          page,
                          softwareToolType:
                              transactionTypes.indexOf(type) || null,
                      })
                    : qs.stringify({
                          page,
                      }),
            })
                .then((res) => {
                    if (res.data.data.length < 20) setHasMore(false);
                    setTransactionsData(transactionsData.concat(res.data.data));
                })
                .catch((err) => {});
        }
    }, [page]);

    return (
        <>
            {planExpiredBar && (
                <div className={styles.planExpiredBar}>
                    {" "}
                    Monthly Quota Exhausted. Credits will be used for further
                    platform usage.{" "}
                    <MdClose onClick={() => setPlanExpiredPlan(false)} />{" "}
                </div>
            )}
            <div className={styles.availableBalance}>
                <AmountLeft
                    userCurrency={userCurrency}
                    setUserCurrency={setUserCurrency}
                    userId={userId}
                    email={email}
                    balance={balance}
                    setDialog={setDialog}
                />
            </div>
            <div className={styles.layout}>
                <div className={styles.historyContainer}>
                    <div className={styles.historyHeading}>
                        History
                        <NewUIDropDown
                            title=""
                            selectedOption={{ title: type }}
                            handler={(option) => setType(option.title)}
                            options={dropdownOptions}
                            style={{
                                zIndex: 0,
                                height: 40,
                            }}
                            label={"Filter by:"}
                        />
                    </div>

                    {loading || transactionsData.length > 0 ? (
                        <InfiniteScroll
                            scrollableTarget="accountSettings"
                            dataLength={transactionsData.length}
                            next={() => {
                                setPage((p) => p + 1);
                            }}
                            hasMore={hasMore}
                            loader={
                                <table className={styles.transactionsTable}>
                                    <tr
                                        style={{ height: 1 }}
                                        className={styles.tableTitles}
                                    >
                                        <th style={{ width: 50 }}></th>
                                        <th className={styles.toBeLeftAligned}>
                                            <span
                                                className={styles.RowHeadTitle}
                                            ></span>
                                        </th>
                                        <th
                                            className={styles.transactionType}
                                        ></th>
                                        <th
                                            style={{ width: 150 }}
                                            className={styles.headerWidth}
                                        ></th>
                                        <th className={styles.amountHead}></th>
                                    </tr>
                                    {skeleton2.map(() => (
                                        <>
                                            <tr className={styles.tableTitles}>
                                                <td>
                                                    <Skeleton
                                                        height={30}
                                                        width={30}
                                                        style={{
                                                            margin: "auto",
                                                        }}
                                                        variant={"circle"}
                                                    />
                                                </td>

                                                <td>
                                                    <div>
                                                        <Skeleton
                                                            height={15}
                                                            width={90}
                                                        />
                                                        <Skeleton
                                                            height={10}
                                                            width={40}
                                                        />
                                                    </div>
                                                </td>

                                                <td>
                                                    <Skeleton
                                                        style={{
                                                            margin: "auto",
                                                        }}
                                                        height={20}
                                                        width={80}
                                                    />
                                                </td>

                                                <td style={{ width: 150 }}>
                                                    <Skeleton
                                                        style={{
                                                            margin: "auto",
                                                        }}
                                                        height={20}
                                                        width={80}
                                                    />
                                                </td>

                                                <td>
                                                    <Skeleton
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: 20,
                                                            minWidth: 140,
                                                        }}
                                                        height={20}
                                                        width={60}
                                                    />
                                                </td>
                                            </tr>

                                            <div className={styles.rowM}>
                                                <MobileSkeleton />
                                            </div>
                                        </>
                                    ))}
                                </table>
                            }
                        >
                            <>
                                {transactionsData?.length > 0 && (
                                    <table className={styles.transactionsTable}>
                                        <tr className={styles.tableTitles}>
                                            <th style={{ width: 50 }}></th>
                                            <th
                                                className={
                                                    styles.toBeLeftAligned
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.RowHeadTitle
                                                    }
                                                >
                                                    Title
                                                </span>
                                            </th>
                                            <th
                                                className={
                                                    styles.transactionType
                                                }
                                            >
                                                Transaction Type
                                            </th>
                                            <th
                                                style={{ width: 150 }}
                                                className={styles.headerWidth}
                                            >
                                                {type == "marketplace"
                                                    ? "DATE & TIME"
                                                    : "Date"}
                                            </th>
                                            <th className={styles.amountHead}>
                                                Amount
                                            </th>
                                        </tr>
                                        {transactionsData.map(
                                            (transaction, i) => {
                                                return (
                                                    <TransactionListItem
                                                        type={type}
                                                        userCurrency={
                                                            userCurrency
                                                        }
                                                        key={i}
                                                        transaction={
                                                            transaction
                                                        }
                                                        userId={userId}
                                                        transactionTypes={
                                                            transactionTypes
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </table>
                                )}

                                <div className={styles.mobileContainer}>
                                    {transactionsData &&
                                        transactionsData.map(
                                            (transaction, i) => (
                                                <MobileTransactionCard
                                                    transaction={transaction}
                                                    key={i}
                                                    userCurrency={userCurrency}
                                                    userId={userId}
                                                />
                                            ),
                                        )}
                                    <MobileFixedBottom
                                        userCurrency={userCurrency}
                                        balance={balance.balance}
                                        setDialog={setDialog}
                                        userId={userId}
                                    />
                                </div>
                            </>
                        </InfiniteScroll>
                    ) : (
                        <div className={styles.noTransactionsContainer}>
                            <div className={styles.noTransactionsImg}>
                                <Image src="/AssetsV2/no-transaction-icon.svg" />
                            </div>
                            <p className={styles.noTransactionsBig}>
                                No Transactions
                            </p>
                            <p className={styles.noTransactionsSmall}>
                                You haven’t made any transaction yet
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
