import React from "react";
import { ImArrowLeft } from "react-icons/im";
import { MdClose } from "react-icons/md";
import GenerateResults from "../GenerateResults/GenerateResults";
import styles from "./Favourite.module.css";

function FavouriteModal({ setModal, data, topic, setLocalData }) {
  return (
    <div className={styles.modal}>
      <MdClose
        onClick={(e) => {
          e.stopPropagation();
          setModal(false);
        }}
        color={"#383e55"}
        className={styles.arrowIcon}
      />
      <div className={styles.headingContainer}>
        <img src={data[0].useCaseIcon} alt="" width={40} height={40} />
        <div>
          <h3>
            {" "}
            {data[0].useCaseName.split("_").join(" ")}{" "}
            <span className={styles.cardKeyword}> {topic}</span>
          </h3>
          <h5> Hint: Click on the card to edit copy</h5>
        </div>
      </div>
      <div className={styles.content}>
        {data.map((item) => {
          return (
            <GenerateResults
              uniqueId={item.uniqueId}
              favIcon={true}
              result={item.text}
              isFav={item.isFavorite}
              setLocalData={setLocalData}
              favourite={true}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FavouriteModal;
