import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { FiCheck, FiShare2 } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { RiFileCopyLine } from "react-icons/ri";
import { EDIT_ACCESS_CODE, READ_ACCESS_CODE } from "../../../../utility/config";
import Button from "../../../atoms/Button/Button";
import Modal from "../../../atoms/Modal/Modal";
import SVGIcon from "../../../atoms/SVGIcon";
import useNavigation from "../../../atoms/GoToRoute/useNavigation";
import styles from "./styles.module.css";
import _styles from "../../../molecules/Creator/Overview/Overview.module.css";
import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";
import useHelperFunctions from "../../../helperFunctions";
import { RENAME_DOC } from "../../../api.json";
import Morekeywords from "../../../atoms/MoreKeywords/Morekeywords";
import styles2 from "../../../atoms/MoreKeywords/morekeywords.module.css";
import { countries } from "../../../../dataV2/countryConstant";

import useMixpanelHook from "../../../../utility/useMixpanelHook";
import moment from "moment";
import { getPastAsTime } from "../../../../utility/validateFunctions";
import ReportContext from "../../Research/ReportContext";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { FixItSections } from "../../TextEditor/EditorAssistant/fixitConstants";

function EditorTitle({
    reportInfo,
    access,
    smartDoc,
    auditMode,
    isFixItEnabled,
    setIsFixItEnabled,
    setEnableFixItEditor,
    setMetaFixIt,
    setEnableFixIt,
    aiHumanizer,
    onUpdate = () => {},
    setIsLoadingFixIt,
}) {
    const [shareModal, setShareModal] = useState(false);
    const [refreshModal, setRefreshModal] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [countryName, setCountryName] = useState("");
    const [refreshTime, setRefreshTime] = useState(
        reportInfo.url_last_refreshed
            ? moment(reportInfo.url_last_refreshed)
            : "",
    );
    const { setReportData } = useContext(ReportContext);
    const [editTitle, setEditTitle] = useState(false);
    const [isCompleted, setIsCompleted] = useState(
        reportInfo.activity_status == 3 ? true : false,
    );
    const [title, setTitle] = useState("");
    const { mpTrackBreadCrumb, mpTrackMarkAsComplete } = useMixpanelHook();
    const [isGettingData, setIsGettingData] = useState(false);
    const [isTooltip, setIsTooltip] = useState(false);
    const [viewOnlyTooltip, setViewOnlyTooltip] = useState(false);
    const { postData, putData } = useHelperFunctions();
    const ref = useRef();
    const goto = useNavigation();

    useEffect(() => {
        setTitle(aiHumanizer ? reportInfo.scan_name : reportInfo.title);
    }, [reportInfo.scan_name, reportInfo.title]);

    const markAsComplete = async (isComplete) => {
        mpTrackMarkAsComplete({
            location: "Editor Header",
            isCompleted: isComplete ? true : false,
        });
        const res = await putData({
            url: "/api/keywords/user-activity",
            payload: {
                report_id: reportInfo.report_id,
                activity_status: isComplete ? 3 : 2,
            },
        });
        if (res.status == 200) {
            if (isComplete) {
                setIsCompleted(true);
            } else {
                setIsCompleted(false);
            }
        }
    };

    useEffect(() => {
        if (!smartDoc) {
            let country = countries.filter(
                (data) =>
                    data?.code?.toLowerCase() ==
                    reportInfo?.country_code?.toLowerCase(),
            );
            console.log(country, "kkjjkkjj");
            setCountryName(
                reportInfo?.search_country
                    ? reportInfo?.search_country
                    : country[0]?.label,
            );
            setCountryCode(country[0]?.code);
        }
    }, [reportInfo?.search_country]);

    useEffect(() => {
        if (aiHumanizer) {
            setTitle(reportInfo.scan_name);
        } else {
            setTitle(reportInfo.title);
        }
    }, [reportInfo.title, reportInfo.scan_name]);

    useEffect(() => {
        if (editTitle) {
            ref.current.focus();
            const range = new Range();
            range.selectNodeContents(ref.current);
            const selection = window.getSelection();

            selection.removeAllRanges();
            selection.addRange(range);
        }
    }, [ref, editTitle]);

    function changeTitle(e) {
        const str = e.target.innerText.trim();

        if (str.length > 3 && str.length < 50) {
            if (aiHumanizer) {
                postData({
                    url: "/api/ai-humanizer/update-scan-name",
                    payload: {
                        scan_id: reportInfo.scan_id,
                        scan_name: str,
                    },
                });
            } else {
                postData({
                    url: RENAME_DOC,
                    payload: { title: str, doc_id: reportInfo.docId },
                });
            }
            setTitle(str);

            onUpdate(str);
        }
    }

    const interactive = (
        <div className={styles2.keywordsConainer}>
            <p className={styles2.keywordHeading}>Primary Keyword</p>
            <div className={styles2.keyword}>{title}</div>
            {reportInfo.secondary_keywords &&
                JSON.parse(reportInfo.secondary_keywords).length > 0 && (
                    <>
                        <p className={styles2.keywordHeading}>
                            Secondary Keywords
                        </p>
                        <div className={styles2.secondaryKeywords}>
                            {JSON.parse(reportInfo.secondary_keywords).map(
                                (key) => (
                                    <div className={styles2.keyword}>{key}</div>
                                ),
                            )}
                        </div>
                    </>
                )}

            {auditMode && reportInfo.audit_url && (
                <>
                    <p className={styles2.keywordHeading}>Source URL</p>
                    <div className={styles2.url_wrapper}>
                        <div className={styles2.url_row1}>
                            <div
                                className={`${styles2.locationContainer} ${styles.url}`}
                                onClick={() =>
                                    window.open(reportInfo.audit_url, "_blank")
                                }
                            >
                                <LightTooltip
                                    title={reportInfo.audit_url}
                                    arrow
                                    placement="bottom"
                                >
                                    <span>
                                        {reportInfo.audit_url.slice(0, 30)}
                                        ...
                                    </span>
                                </LightTooltip>
                            </div>

                            {access && (
                                <LightTooltip
                                    title={
                                        "Click to refresh content from the link"
                                    }
                                    arrow
                                    placement="right"
                                >
                                    <div
                                        className={`${styles2.refresh_url} ${
                                            !access && styles2.disabled
                                        } `}
                                        onClick={() => {
                                            if (access) {
                                                setIsTooltip(false);
                                                setRefreshModal(true);
                                            }
                                        }}
                                    >
                                        <SVGIcon
                                            src={"/New UI/SVG/refresh_url.svg"}
                                            size={14}
                                            style={{ color: "inherit" }}
                                        />
                                    </div>
                                </LightTooltip>
                            )}
                        </div>
                        {access && (
                            <div className={styles2.url_row2}>
                                Last refreshed{" "}
                                {refreshTime
                                    ? getPastAsTime(refreshTime)
                                    : reportInfo.created_at}
                            </div>
                        )}
                    </div>
                </>
            )}
            <p className={styles2.keywordHeading}>URLs</p>
            <div
                className={`${styles2.secondaryKeywords} ${styles2.locationContainer}`}
            >
                Top {reportInfo.competitors_count}
            </div>
            <p className={styles2.keywordHeading}>Location</p>
            <div
                className={`${styles2.secondaryKeywords} ${styles2.locationContainer}`}
            >
                <img
                    loading="lazy"
                    width="18px"
                    height="12px"
                    style={{ marginRight: "16px" }}
                    src={`https://flagcdn.com/w20/${
                        countryCode?.toLowerCase() === "uk"
                            ? "gb"
                            : countryCode?.toLowerCase()
                    }.png`}
                    srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
                    alt=""
                />
                {countryName}
            </div>
        </div>
    );

    return (
        <>
            <div className={styles.title}>
                <span
                    className={styles.link}
                    onClick={() => {
                        mpTrackBreadCrumb({
                            type: smartDoc
                                ? "COPYWRITER"
                                : auditMode
                                ? "AUDIT"
                                : aiHumanizer
                                ? "AI_HUMANIZER"
                                : "CREATOR",
                        });
                        goto(
                            smartDoc
                                ? "COPYWRITER"
                                : auditMode
                                ? "AUDIT"
                                : aiHumanizer
                                ? "AI_HUMANIZER"
                                : "CREATOR",
                            smartDoc ? [`docs=true`] : [],
                        );
                    }}
                >
                    {auditMode
                        ? "All Reports"
                        : smartDoc
                        ? "All Docs"
                        : aiHumanizer
                        ? "All Scans"
                        : "All Articles"}
                </span>
                <span className={styles.link2}>/</span>
                {editTitle ? (
                    <span
                        key={1}
                        ref={ref}
                        // onFocus={e=>}
                        onKeyPress={(e) => {
                            e.persist();

                            if (e.nativeEvent.key == "Enter") {
                                e.preventDefault();
                                setEditTitle(false);
                                changeTitle(e);
                            }
                        }}
                        onBlur={(e) => {
                            setEditTitle(false);
                            changeTitle(e);
                        }}
                        contentEditable={true}
                        className={styles.editorTitle}
                        style={{
                            backgroundColor: "white",
                            color: "black",
                            fontSize: 14,
                            padding: 5,
                        }}
                    >
                        {title}
                    </span>
                ) : (
                    <LightTooltip
                        open={isTooltip}
                        tooltipStyle={{
                            backgroundColor: "transparent",
                            padding: 0,
                            border: "none",
                        }}
                        interactive
                        onClose={() => {
                            setIsTooltip(false);
                        }}
                        arrow
                        title={
                            smartDoc || aiHumanizer
                                ? ""
                                : refreshModal
                                ? ""
                                : interactive
                        }
                        PopperProps={{ disablePortal: true }}
                    >
                        <span
                            style={{
                                textOverflow: "ellipsis",
                                cursor: smartDoc ? "pointer" : "default",
                            }}
                            onMouseEnter={() => setIsTooltip(true)}
                            onClick={() => !smartDoc || setEditTitle(true)}
                            key={2}
                            className={styles.editorTitle}
                        >
                            {title}
                        </span>
                    </LightTooltip>
                )}
                {reportInfo.secondary_keywords &&
                JSON.parse(reportInfo.secondary_keywords).length > 0 ? (
                    <Morekeywords
                        keywords={reportInfo.secondary_keywords}
                        moreCount={
                            JSON.parse(reportInfo?.secondary_keywords)?.length
                        }
                        title={title}
                        isEditor={true}
                        countryName={countryName}
                        countryCode={countryCode}
                    />
                ) : (
                    ""
                )}
                {!smartDoc && !aiHumanizer && (
                    <LightTooltip
                        tooltipStyle={{
                            backgroundColor: "transparent",
                            padding: 0,
                            border: "none",
                        }}
                        interactive
                        arrow
                        title={interactive}
                    >
                        <img
                            loading="lazy"
                            width="18px"
                            height="12px"
                            style={{ marginLeft: "16px" }}
                            src={`https://flagcdn.com/w20/${
                                countryCode?.toLowerCase() === "uk"
                                    ? "gb"
                                    : countryCode?.toLowerCase()
                            }.png`}
                            srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
                            alt=""
                        />
                    </LightTooltip>
                )}
                {smartDoc || aiHumanizer ? (
                    <MdEdit
                        onClick={() => setEditTitle(true)}
                        style={{ cursor: "pointer" }}
                        color="#00000066"
                    />
                ) : reportInfo.isHumanizer ? (
                    <div
                        onClick={() => {
                            setReportData((ps) => ({
                                ...ps,
                                reportInfo: {
                                    ...ps.reportInfo,
                                    isHumanizer: false,
                                },
                            }));
                        }}
                        className={styles.viewOnly}
                    >
                        Exit Detect and Humanize Mode
                    </div>
                ) : !isFixItEnabled.isOn ? (
                    reportInfo?.is_sample ? (
                        <></>
                    ) : (
                        <span className={styles.shareIcon}>
                            <SVGIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => setShareModal(true)}
                                src={"/New UI/SVG/share_icon.svg"}
                                color={"#00000066"}
                            />
                        </span>
                    )
                ) : (
                    <LightTooltip
                        arrow
                        placement="bottom"
                        title={`You are currently in view-only mode, kindly exit ${
                            isFixItEnabled?.section?.panelName ==
                            FixItSections.LINKS
                                ? "Internal Linking"
                                : "Fix It"
                        } mode to resume editing.`}
                    >
                        <div
                            onClick={() => {
                                if (reportInfo.isHumanizer) {
                                    setReportData((ps) => ({
                                        ...ps,
                                        reportInfo: {
                                            ...ps.reportInfo,
                                            isHumanizer: false,
                                        },
                                    }));
                                    return;
                                }
                                // if (isFixItEnabled?.isLoading) return;
                                setEnableFixItEditor(false);
                                setIsLoadingFixIt(false);
                                setIsFixItEnabled({
                                    isOn: false,
                                    section: {},
                                    sectionData: {},
                                    isLoading: false,
                                });
                                setEnableFixIt(false);
                                setMetaFixIt({});
                                document?.quill?.editor?.enable();
                            }}
                            className={styles.viewOnly}
                        >
                            {" "}
                            {isFixItEnabled?.section?.panelName ==
                            FixItSections.LINKS
                                ? "Exit Internal Linking Mode"
                                : "Exit Fix It Mode"}
                        </div>
                    </LightTooltip>
                )}
                {!smartDoc &&
                    !auditMode &&
                    !isFixItEnabled.isOn &&
                    !aiHumanizer &&
                    !reportInfo.isHumanizer && (
                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={
                                isCompleted
                                    ? "Unmark as complete"
                                    : "Mark as complete"
                            }
                        >
                            <div
                                className={styles.markAsComplete}
                                onClick={() => markAsComplete(!isCompleted)}
                            >
                                <FiCheck
                                    color={isCompleted ? "#1dc198" : "#2E4263"}
                                    fontWeight={500}
                                    strokeWidth={"2.5px"}
                                />
                            </div>
                        </LightTooltip>
                    )}
            </div>
            {shareModal && (
                <Modal setModal={setShareModal}>
                    <ShareModal
                        setShareModal={setShareModal}
                        access={access}
                        id={reportInfo.report_id}
                        auditMode={auditMode}
                    />
                </Modal>
            )}
            {refreshModal && (
                <Modal setModal={isGettingData ? () => {} : setRefreshModal}>
                    <RefreshModal
                        setRefreshModal={setRefreshModal}
                        access={access}
                        auditUrl={reportInfo.audit_url}
                        id={reportInfo.report_id}
                        setIsGettingData={setIsGettingData}
                        isGettingData={isGettingData}
                        auditMode={auditMode}
                        setRefreshTime={setRefreshTime}
                    />
                </Modal>
            )}
        </>
    );
}

export default EditorTitle;

export const ShareModal = ({ id, setShareModal, access, auditMode }) => {
    const [isCopied, setIsCopied] = useState(false);
    useEffect(() => {
        let timer = null;
        if (isCopied) {
            timer = setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);

    const getShareUrl = (code) => {
        let shareUrl = `${window.location.origin}/${
            auditMode ? "audit" : "creator"
        }/${id}/`;
        if (code === 1) {
            shareUrl += `${READ_ACCESS_CODE}`;
            return shareUrl;
        } else if (code === 2) {
            shareUrl += `${EDIT_ACCESS_CODE}`;
            return shareUrl;
        }
    };
    const copyToClipboard = (shareUrl) => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopied(true);
    };

    return (
        <div className={_styles.shareModal2}>
            <div className={_styles.share1}>
                <img src="/AssetsV2/share-icon-popup.svg" />
                <div>Share Report</div>
                <div
                    className={_styles.crossIcon}
                    onClick={() => setShareModal(false)}
                >
                    <IoClose size={20} />
                </div>
            </div>
            <div className={_styles.share2}>
                <div className={_styles.shareLabel}>Read Only</div>
                <div className={_styles.shareLink}>
                    <div>{getShareUrl(1)} </div>
                    <LightTooltip
                        title={isCopied ? "Copied!" : "Click to copy"}
                        placement="top"
                    >
                        <div onClick={() => copyToClipboard(getShareUrl(1))}>
                            <RiFileCopyLine size={20} cursor="pointer" />
                        </div>
                    </LightTooltip>
                </div>
            </div>
            {access && (
                <div className={_styles.share2}>
                    <div className={_styles.shareLabel}>Edit Access</div>
                    <div className={_styles.shareLink}>
                        <div>{getShareUrl(2)} </div>
                        <LightTooltip
                            title={isCopied ? "Copied!" : "Click to copy"}
                            placement="top"
                        >
                            <div
                                onClick={() => copyToClipboard(getShareUrl(2))}
                            >
                                <RiFileCopyLine size={20} cursor="pointer" />
                            </div>
                        </LightTooltip>
                    </div>
                </div>
            )}
            <div className={_styles.share3}>
                <Button
                    text="OK"
                    width="100px"
                    style={{
                        backgroundColor: "#014dc5",
                    }}
                    handler={() => setShareModal(false)}
                />
            </div>
        </div>
    );
};

export const RefreshModal = ({
    id,
    setRefreshModal,
    access,
    auditUrl = "",
    auditMode,
    isGettingData = false,
    setIsGettingData = () => {},
    setRefreshTime = () => {},
}) => {
    const { getData } = useHelperFunctions();
    const { setMetaData } = useContext(ReportContext);
    const getUpdatedBlog = async (report_id) => {
        setIsGettingData(true);
        const res = await getData({
            url: `/api/scrape/importUrl?url=${auditUrl}&report_id=${report_id}`,
        });
        if (res.status == 200) {
            setRefreshTime(moment.now());
            document.quill.editor.setContents(res.data.data.delta);
            if (res.data?.data?.meta_description) {
                setMetaData({
                    ...res.data?.data?.meta_description,
                    fromAPI: true,
                });
            }
        }
        setIsGettingData(false);
        setRefreshModal(false);
    };
    return (
        <div
            className={
                styles.refreshModalWrapper +
                " " +
                (isGettingData && styles.inProg)
            }
        >
            {!isGettingData ? (
                <>
                    <div className={styles.refreshHeader}>Please Confirm</div>
                    <div
                        className={styles.crossIcon}
                        onClick={() => setRefreshModal(false)}
                    >
                        <IoClose size={20} />
                    </div>
                    <div className={styles.refreshText}>
                        Refreshing content will fetch the current content from
                        the link. Your present content will be removed from the
                        Scalenut editor. Are you sure?
                    </div>
                    <div className={styles.cta_wrapper}>
                        <Button
                            text={"No"}
                            style={{
                                width: "35%",
                                background: "white",
                                border: "1px solid #1f4cbe",
                                color: "#1f4cbe",
                            }}
                            disabled={isGettingData}
                            handler={() => setRefreshModal(false)}
                        />
                        <Button
                            text={
                                !isGettingData ? (
                                    "Yes"
                                ) : (
                                    <>
                                        Refreshing <LoadingDots />
                                    </>
                                )
                            }
                            style={{
                                width: "35%",
                            }}
                            disabled={isGettingData}
                            handler={() => {
                                getUpdatedBlog(id);
                            }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.inProgRefresh}>
                        <span>
                            Please wait while we are refreshing your content...{" "}
                        </span>
                        <LoadingDots
                            customStyle={{
                                height: "24px",
                                width: "24px",
                                color: "inherit",
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
