import React from "react";
import { IoMdClose } from "react-icons/io";
import styles from "./CloseButton.module.css";
function CloseButton({ style, setClose, buttonSize = 18 }) {
  return (
    <div
      className={styles.button}
      onClick={() => setClose(false)}
      style={{ ...style }}
    >
      <IoMdClose size={buttonSize} />
    </div>
  );
}

export default CloseButton;
