import React, { useEffect, use, useState, Suspense, useContext } from "react";
import { useSelector } from "react-redux";
import {
    NavLink,
    Redirect,
    Route,
    Switch,
    useLocation,
} from "react-router-dom";
import styles from "./styles.module.css";
import Aside from "../../organisms/Aside/Aside";
import useVariants from "../../Pages/Variants/hook/useVariants";
import Notifications from "../../../components/notifications";
import AccountSettings from "../../../components/account/AccountSettings";
import useOtherInfo from "../../useOtherInfo";

import GSTPopUp from "../../organisms/Modals/GST/PopUp";
import SuperAdmin from "../../../components/superAdmin/SuperAdmin";
import { scrollToTop } from "../../utlities";
import Creator from "../../Pages/Creator/Creator";
import TransactionProgress from "../../atoms/TransactionProgress/TransactionProgress";
import ExpiryPopup from "./ExpiryPopup";
import LoggingOut from "../../organisms/Modals/LoggingOut/LoggingOut";
import SmartEditor from "../../Pages/SmartEditor/SmartEditor";
import UserDocs from "../../Pages/UserDocs/UserDocs";
import NewDashboard from "../../Pages/NewDashboard/NewDashboard";
import { PROD, url, USER_WIDGET_KEY } from "../../../utility/config";
import AIOutputs from "../../Pages/AIOutputs/AIOutputs";
import { TagsContextProvider } from "../../Pages/TagsContext";
import useUserAccessHook, {
    FEATURE_TYPES,
} from "../../../components/account/useUserAccessHook";
import screens from "../../../screens.json";
import AiTemplates from "../../Pages/AiTemplates/AiTemplates";
import Cluster from "../../Pages/Cluster/Cluster";
import ChromeExtFeedbackPopup from "./ChromeExtFeedbackPopup";
import { pageHit } from "../../../UserDotComEvents";

import { getPlanInfo } from "../../../utility/useMixpanelHook";
import IntegrationHub from "../../Pages/IntegrationHub/IntegrationHub";
import TransactionFailed from "../../atoms/TransactionProgress/TransactionFailed";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import ChatWidget from "../../organisms/ChatWidget/ChatWidget";
import OnBoardingFlow from "../../organisms/OnBoadingFlow/OnBoardingFlow";
import Progress from "../../atoms/Progress/Progress";
import GoogleSearchConsole from "../../Pages/GoogleSearchConsole/GoogleSearchConsole";
import Copywriter from "../../Pages/Copywriter/Copywriter";
import { VscClose } from "react-icons/vsc";
import { mobileViewportReset } from "../../../utility/helperFun";
import Webtune from "../../Pages/WebTune/WebTune";
import ShopifyAuth from "./ShopifyAuth";
import Interlinking from "../../Pages/InterLinking/Interlinking";
import AIHumanizer from "../../Pages/AIHumanizer/AIHumanizer";
import AIHumanizerEditor from "../../Pages/AIHumanizer/AIHumanizerEditor";
import ReportContext from "../../organisms/Research/ReportContext";
import AIHumanizerParent from "../../Pages/AIHumanizer/AIHumanizerParent";
import SeoSurge from "../../Pages/SeoSurge/SeoSurge";

export default function () {
    const email = useSelector((state) => state.auth.email);
    const otherInfo = useSelector((state) => state.otherInfo);

    const phone = useSelector((state) => state.auth.phone);
    const user_id = useSelector((state) => state.auth.userId);
    const location = useLocation();
    const name = useSelector((state) => state.auth.user_name);
    const profileUrl = useSelector((state) => state.auth.profilePicture);
    const currency = useSelector((state) => state.auth.currency);
    const role = useSelector((state) => state.auth.role);
    const gst_no = useSelector((state) => state.auth.gst_no);
    const signUpMode = useSelector((state) => state.auth.signup_mode);
    const segment = useSelector((state) => state.auth.segment);
    const first_subscription_status = useSelector(
        (state) => state.auth.first_subscription_status,
    );
    const [aside, _setAside] = useState(false);
    const [floatingAside, setFloatingAside] = useState(false);
    const [GST, setGST] = useState(false);
    const goTo = useNavigation();
    const alert = useSelector((state) => state.alert);
    const { freeUser, LockConfigurator } = useUserAccessHook();

    const [showChatWidget, setShowChatWidget] = useState(false);
    const { isChatWidgetEnable } = useSelector((state) => state.state);
    const [showNudge, setShowNudge] = useState(true);
    useVariants();
    useOtherInfo();

    useEffect(() => {
        try {
            const history = JSON.parse(localStorage.getItem("history")) || [];

            history.push(location.pathname);

            if (history.length > 10) {
                history.splice(0, history.length - 10);
            }

            localStorage.setItem("history", JSON.stringify(history));
        } catch (error) {
            localStorage.setItem("history", JSON.stringify([]));
        }
    }, [location]);

    function setAside(value, floatAside) {
        if (typeof value == "boolean") _setAside(value);

        if (typeof floatAside == "boolean") setFloatingAside(floatAside);
    }

    useEffect(() => {
        //   <meta
        //   id="viewport-meta"
        //   name="viewport"
        //   content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        // />

        if (PROD) {
            pageHit({
                apiKey: USER_WIDGET_KEY,
                email: email,
                name: name,
                phone_number: phone,
                user_id: user_id,
                onLoad() {
                    document.dispatchEvent(new Event("USERDOTCOM_LOADED"));
                },
                onOpen() {
                    setShowChatWidget && setShowChatWidget(false);
                },
            });
            window.userengage && window.userengage("widget.show");
        }

        if (localStorage.getItem("qazwsxedc") == "109plmokn") {
            setGST(true);
        }
        return () => {
            if (PROD) window?.UE?.resetAuth({ apiKey: USER_WIDGET_KEY });
        };
    }, []);

    const { isMobile } = useSelector((state) => state.state);

    useEffect(() => {
        scrollToTop();
    }, [location]);

    useEffect(() => {
        setShowNudge(true);
    }, [location.pathname]);

    useEffect(() => {
        setShowChatWidget(isChatWidgetEnable);
    }, [isChatWidgetEnable]);

    useEffect(() => {
        if (sessionStorage.getItem("context") == "producthunt") {
            sessionStorage.removeItem("context");
            goTo("BILLING_PLANS");
        }
    }, []);

    if (location.pathname == "/admin/transaction568894547/98797984111237/")
        return <TransactionProgress />;
    if (location.pathname == "/admin/transactions/failed") {
        return <TransactionFailed />;
    }

    if (signUpMode == 2 && first_subscription_status == 0)
        return <Redirect to="/admin/dashboard/plans" />;

    return (
        <div
            style={{ height: isMobile ? window.innerHeight : "100vh" }}
            className={styles.container + " "}
        >
            <Aside aside={aside} setAside={setAside} />

            <div
                // style={{
                //     background: isMobile
                //         ? "linear-gradient(120deg, rgba(0, 46, 168, 0.00) 9.42%, rgba(16, 207, 252, 0.14) 88.59%)"
                //         : "",
                // }}
                id={"scrollableDiv"}
                className={styles.main}
            >
                <TagsContextProvider>
                    <Switch>
                        <Route path="/admin/dashboard">
                            <NewDashboard
                                setShowChatWidget={setShowChatWidget}
                            />
                        </Route>
                        <Route exact path="/admin/aioutputs/:type">
                            <AIOutputs />
                        </Route>
                        <Route exact path="/admin/docs/editor/:documentId">
                            <SmartEditor></SmartEditor>
                        </Route>
                        <Route path="/admin/creator">
                            <Creator />
                        </Route>
                        <Route path="/admin/cluster">
                            <Cluster />
                        </Route>
                        <Route path="/admin/audit">
                            <Creator audit={true} />
                        </Route>
                        <Route path="/admin/analysis">
                            <GoogleSearchConsole
                                tabName={window.location.href}
                            />
                        </Route>
                        <Route path="/admin/link-manager">
                            <Interlinking tabName={window.location.href} />
                        </Route>

                        <Route path="/admin/ai-humanizer">
                            <AIHumanizerParent />
                        </Route>

                        <Route path={"/admin/webtune"}>
                            <Webtune />
                        </Route>

                        <Route exact path="/admin/copywriter">
                            <Copywriter />
                        </Route>

                        <Route exact path="/admin/copywriter/new">
                            <Copywriter newDoc={true} />
                        </Route>
                        <Route path="/admin/integration">
                            <IntegrationHub />
                        </Route>
                        <Route path="/admin/account">
                            <AccountSettings aside={aside} />
                        </Route>
                        <Route path="/admin/shopify/auth">
                            <ShopifyAuth />
                        </Route>
                        <Route path="/admin/notifications">
                            <Notifications />
                        </Route>
                        {role == "admin" && (
                            <Route path="/admin/superadmin">
                                <SuperAdmin />
                            </Route>
                        )}
                        <Route path="/admin/seo-surge">
                            <SeoSurge />
                        </Route>
                        <Route path="/">
                            <Redirect to="/admin/dashboard" />
                        </Route>
                    </Switch>
                </TagsContextProvider>
            </div>

            {GST && gst_no == null && role == "user" && currency == "INR" && (
                <GSTPopUp />
            )}
            {alert.alertType == "popup" && (
                <ExpiryPopup
                    isUpgrade={alert?.isUpgrade ? alert.isUpgrade : false}
                />
            )}
            {otherInfo.status == 0 && <LoggingOut />}

            <ChromeExtFeedbackPopup />
            {navigator.userAgent.includes("Mobile") && showNudge && (
                <div className={styles.mobileNudgeWrapper}>
                    <div className={styles.mobileNudge}>
                        <span>
                            Switch to desktop version for a better experience
                        </span>
                        <VscClose onClick={() => setShowNudge(false)} />
                    </div>
                </div>
            )}
            <ChatWidget
                isShow={showChatWidget}
                handler={setShowChatWidget}
                userName={name}
            />
            {/* <BottomNoti expiryDate={selectedPlanTypeInfo?.expiration_at} /> */}
        </div>
    );
}
