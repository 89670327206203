import mixpanel from "mixpanel-browser";
import React from "react";

export const useMixpanelTA = () => {
    const taTrackGscConnect = ({ screen = "", isReAuth = "" }) => {
        let temp = {
            Screen: screen || "-",
            ReAuthentication: isReAuth || "-",
        };
        mixpanel.track("GSC Connect CTA", temp);
    };

    const taTrackGscLoginFlow = ({
        step = "",
        cta = "",
        isLimitOver = false,
        partialProcess = false,
    }) => {
        let temp = {
            step: step || "-",
            cta: cta || "-",
            "Limit Over": isLimitOver ? "Yes" : "No",
            "Partial Process": partialProcess ? "Yes" : "No",
        };
        mixpanel.track("TA Login Flow", temp);
    };

    const taTrackReAuthFlow = ({ screen = "", cta = "" }) => {
        let temp = {
            cta: cta || "-",
            screen: screen || "-",
        };
        mixpanel.track("TA ReAuth Flow", temp);
    };
    const taTrackDataLoadTime = (obj: any) => {
        console.log(`mpObject ${obj.processedPages}  temp`, obj);

        let pp = 0;
        if (obj.processedPages) {
            pp = obj.processedPages;
        } else {
        }
        let temp = {
            "Last Refresh": obj.lastRefresh || "-",
            "Next Refresh": obj.nextRefresh || "-",
            "Processed Pages": parseInt(obj.processedPages) || 0,
            "Quota Exhausted": obj.qExhausted || "-",
            "Tab Name": obj.tabName || "-",
            "Time Taken (s)": obj.timeTaken || 0,
            "Total Pages": obj.totalPages || 0,
        };
        console.log(`mpObject ${temp["Tab Name"]}  temp`, temp);

        mixpanel.track("TA Data Load Time", temp);
    };

    const taTrackTabSwitch = (obj: any) => {
        mixpanel.track("TA Tab Switch", obj);
    };

    const taTrackAccessed = (obj: any) => {
        let temp = {
            Path: obj.source,
            "From Mail": obj.fromMail ? "Yes" : "No",
            "From FMC": obj.fromMail ? "Yes" : "No",
        };
        mixpanel.track("TA Landed", temp);
    };

    const taTrackInsightSection = (obj: any) => {
        let temp = {
            "Section Name": obj.sectionName || "-",
        };
        mixpanel.track("TA Insights Sections", temp);
    };

    const taTrackInventoryOptimizeCta = (obj: any) => {
        mixpanel.track("TA Inventory Optimize CTA", obj);
    };

    const taTrackFilters = ({ tabName = "", filterType = "", cta = "" }) => {
        let temp = {
            "Tab Name": tabName || "-",
            "Filter Type": filterType || "-",
            "CTA ": cta || "-",
        };
        mixpanel.track("TA Filters ", temp);
    };

    const taTrackDomainsCountry = ({ tabName = "", cta = "", type = "" }) => {
        let temp = {
            Type: type || "-",
            "Tab Name": tabName || "-",
            "CTA ": cta || "-",
        };
        mixpanel.track("TA Domains/Country", temp);
    };

    return {
        taTrackGscConnect,
        taTrackGscLoginFlow,
        taTrackReAuthFlow,
        taTrackDataLoadTime,
        taTrackTabSwitch,
        taTrackAccessed,
        taTrackInsightSection,
        taTrackInventoryOptimizeCta,
        taTrackFilters,
        taTrackDomainsCountry,
    };
};
