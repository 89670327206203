export const socketEvents = {
    REPORT_CREATED: "report_created",
    REPORT_FAILED: "report_failed",
    USER_JOIN_ROOM: "user_room",
    TOPIC_CLUSTER_CREATION_SUCCESS: "topic_cluster_creation_success",
    TOPIC_CLUSTER_CREATION_FAILED: "topic_cluster_creation_failed",
    USER_FIRST_SUBSCRIPTION: "subscription_payment_status",
    PARA_SUCCESS: "paragraph_success",
    INTRO: "intro",
    PARA_FAILED: "para_failed",
    EARLY_BUY_FAILED: "early_buy_payment_failed",
    WRITING_POINT_SUCCESS: "writing_points_success",
    WRITING_POINT_FAILED: "writing_points_failed",
    CONCLUSION_PARAGRAPH: "conclusion_paragraph",
    INTRO_PARAGRAPH: "intro_paragraph",
    PARA_COMPLETED: "paragraph_completed",
    SUBSCRIPTION_QUOTA: "subscription_quota",
    WEBTUNE_SCRAPE_PROCESS: "webtune_scrape_process",
    WEBTUNE_SCRAPE_SUCCESS: "webtune_scrape_success",
    WEBTUNE_SCRAPE_FAIL: "webtune_scrape_fail",
    INTERLINKING_SCRAPE_PROCESS: "interlinking_scrape_process",
    INTERLINKING_SCRAPE_SUCCESS: "interlinking_scrape_success",
    INTERLINKING_SCRAPE_FAIL: "interlinking_scrape_fail",
    PREMIUM_CRUISE: "premium_cruise",
    ADVANCE_CRUISE: "advance_cruise",
    FEATURED_IMAGE: "featured-image",
    SEGMENT5: "segment5",
    SEGMENT6: "segment6",
    BLOG_PROCESSING_STATUS: "blog_processing_status",
};
