import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import SVGIcon from "../../componentsV2/atoms/SVGIcon";
import Button from "../../componentsV2/atoms/Button/Button";
import usePriceDiscounts, {
    getYearSave,
    parsePrice,
} from "../../componentsV2/templates/Dashboard/usePriceDiscounts";
import { ADD_ONS_TYPE, PLANS_CATEGORIES, PLANS_TYPE } from "../../constants";
import useUserAccessHook from "../account/useUserAccessHook";
import { useDispatch, useSelector } from "react-redux";
import useHelperFunctions from "../../componentsV2/helperFunctions";
import { UPDATE_PLAN } from "../../componentsV2/api.json";
import Progress from "../../componentsV2/atoms/Progress/Progress";
import BuyMore from "../../componentsV2/Pages/WebTune/BuyMore";
import { CircularProgress, colors } from "@material-ui/core";
import { OfflinePaymentModal } from "./PriceCard";
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import { paymentPopUpDimension } from "./PriceCardBF";
import useNavigation from "../../componentsV2/atoms/GoToRoute/useNavigation";
import { updateUserInfo } from "../../store/actions/auth";
import Modal from "../../componentsV2/atoms/Modal/Modal";
import { VscClose } from "react-icons/vsc";
import { displayAlert } from "../../store/actions/alert";

const ADDITIONAL_USD_PRICE = 5;
const ADDITIONAL_INR_PRICE = 425;

const KeyFeatures = [
    {
        icon: <SVGIcon src={"/New UI/SVG/gear-blue.svg"} />,
        title: (
            <span>
                Automated
                <br />
                Identification
            </span>
        ),
        subText:
            "Finding internal links is hard. It need not be. Completely automate the process.",
    },
    {
        icon: <SVGIcon src={"/New UI/SVG/paper-plane.svg"} />,
        title: (
            <span>
                One-click
                <br />
                deployment
            </span>
        ),
        subText:
            "Find thousands of relevant linking opportunities across your domain and distribute link equity",
    },
    {
        icon: <SVGIcon src={"/New UI/SVG/graph-rise.svg"} />,
        title: (
            <span>
                CMS
                <br />
                agnostic
            </span>
        ),
        subText:
            "Deploy as many changes as you want in a click. No more spending hours.",
    },
];
const KeyFeatures1 = [
    {
        icon: <SVGIcon src={"/New UI/SVG/unlimited-ai.svg"} />,
        title: (
            <span>
                Unlimited AI
                <br />
                detection
            </span>
        ),
        subText:
            "Finding internal links is hard. It need not be. Completely automate the process.",
    },
    {
        icon: <SVGIcon src={"/New UI/SVG/Humanize-ai.svg"} />,
        title: (
            <span>
                Humanize any
                <br />
                AI content
            </span>
        ),
        subText:
            "Find thousands of relevant linking opportunities across your domain and distribute link equity",
    },
    {
        icon: <SVGIcon src={"/New UI/SVG/for-all.svg"} />,
        title: (
            <span>
                Works for all
                <br />
                AI models
            </span>
        ),
        subText:
            "Deploy as many changes as you want in a click. No more spending hours.",
    },
];

export const LmAddOnCheck = ({
    plansType,
    isDeal = false,
    customClass,
    isThisCurr = false,
    checked = false,
    handleChecked = () => {},
    setDefaultAddOnChecked = () => {},
    price,
    isProAnnual = false,
    plansToggler,
    getDiscount = () => {},
    lmPlan = {},
    details,
}) => {
    const currency = useSelector((state) => state.auth.currency);
    const currencySymbol = currency == "INR" ? "₹" : "$";
    const { interlinking_purchased_pages_quantity, interlinking_status } =
        useSelector((state) => state.otherInfo?.subscriptionInfo);
    return (
        <>
            <div className={styles.lmAddOnCheckTitle}>
                Add-on
                {interlinking_status != 0 && (
                    <div className={styles.newTag}>
                        {interlinking_purchased_pages_quantity == 0
                            ? "New"
                            : "Activated"}
                    </div>
                )}
            </div>
            <ul className={customClass.checksList} style={{ marginTop: "5px" }}>
                <li style={{ alignItems: "flex-start" }}>
                    <div
                        style={{ background: "none" }}
                        className={customClass.check}
                        onClick={() => {
                            // handleChecked(!checked);
                            setDefaultAddOnChecked(!checked);
                        }}
                    >
                        {checked ? (
                            <>
                                <IoIosCheckboxOutline
                                    color={
                                        isThisCurr && !details?.terminate
                                            ? "#fff"
                                            : ""
                                    }
                                    size={17}
                                />
                            </>
                        ) : (
                            <IoIosSquareOutline
                                color={
                                    isThisCurr && !details?.terminate
                                        ? "#fff"
                                        : ""
                                }
                                size={18}
                            />
                        )}
                    </div>
                    {plansType == 2 ? (
                        <div>
                            Link Manager{" "}
                            <span
                                style={{
                                    textDecoration: isDeal
                                        ? "line-through"
                                        : "none",
                                }}
                            >
                                {price}
                            </span>
                            {isDeal && (
                                <span>
                                    {" "}
                                    {parsePrice(
                                        lmPlan.plan_cost,
                                        lmPlan.currency,
                                        lmPlan.plan_type,
                                        getDiscount(plansType),
                                    )}
                                </span>
                            )}
                            /mo
                            <div
                                style={{
                                    color:
                                        isThisCurr && !details?.terminate
                                            ? "whitesmoke"
                                            : "#5d5d5d",
                                    fontSize: 12,
                                }}
                            >
                                {isDeal ? (
                                    <>
                                        <span
                                            style={{
                                                textDecoration: "line-through",
                                            }}
                                        >
                                            1000
                                        </span>{" "}
                                        4000 pages included
                                    </>
                                ) : (
                                    "1000 pages included"
                                )}
                                <br />
                                {currencySymbol}
                                {currency == "USD" ? "5" : "425"}/500 pages
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    color:
                                        isThisCurr && !details?.terminate
                                            ? "whitesmoke"
                                            : "",
                                    fontSize: 12,
                                }}
                            >
                                <div
                                    style={{
                                        marginBottom: "5px",
                                        fontSize: 15,
                                    }}
                                >
                                    Link Manager{" "}
                                    <span
                                    // style={{
                                    //     textDecoration: "line-through",
                                    // }}
                                    >
                                        {price}
                                    </span>
                                    {/* <span
                                        style={{
                                            marginLeft: "5px",
                                            fontWeight: "600",
                                            color: isThisCurr
                                                ? "whitesmoke"
                                                : "#000",
                                        }}
                                    >
                                        {parsePrice(
                                            lmPlan.plan_cost,
                                            lmPlan.currency,
                                            lmPlan.plan_type,
                                            getDiscount(plansType),
                                        )}
                                    </span> */}
                                    /mo
                                </div>
                                1000 pages included
                                <br />
                                {currencySymbol}
                                {currency == "USD" ? "5" : "425"}/500 pages
                            </div>
                        </div>
                    )}
                </li>
            </ul>
        </>
    );
};

const LmAddOnCard = ({
    setDefaultAddOnChecked = () => {},
    plansToggler,
    plans = [],
    allPlans,
    getDiscount = () => {},
    isOpenChat = false,
    isDeal,
    maxPlans,
}) => {
    const { trialUser, isFreeForever, plan_type, subscriptionInfo } =
        useUserAccessHook();
    const { interlinking_purchased_pages_quantity, interlinking_status } =
        useSelector((state) => state.otherInfo?.subscriptionInfo);
    const currency = useSelector((state) => state.auth.currency);
    const show_humanizer_landing = useSelector(
        (state) => state.auth.show_humanizer_landing,
    );
    const currencySymbol = currency == "INR" ? "₹" : "$";
    const [validPlanCost, setValidPlanCost] = useState(1);
    const [humanizerPlanCost, setHumanizerPlanCost] = useState(0);
    // const [plansToggler, setplansToggler] = useState(
    //     plan_type || PLANS_TYPE.YEARLY,
    // );
    const dispatch = useDispatch();
    const goTo = useNavigation();
    const { postData } = useHelperFunctions();
    const [loading, setLoading] = useState(false);
    const [buyPagesModal, setBuyPagesModal] = useState(false);
    const [humanizerBuyMoreModal, setHumanizerBuyMoreModal] = useState(false);
    const [isOfflinePaymentWarning, setIsOfflinePaymentWarning] =
        useState(false);
    useEffect(() => {
        if (plans.length) {
            let tempValidPlan = plans.find(
                (vp) =>
                    vp?.plan_cost > 0 &&
                    vp?.plan_type == plansToggler &&
                    vp.plan_category == PLANS_CATEGORIES.INTER_LINKING,
            );
            setValidPlanCost(tempValidPlan?.plan_cost || {});
        }
        if (plans.length) {
            let tempPlan = plans.find((vp) =>
                vp?.plan_cost > 0 && vp?.plan_type == maxPlans
                    ? 2
                    : plansToggler &&
                      vp.plan_category == PLANS_CATEGORIES.HUMANIZER,
            );
            setHumanizerPlanCost(tempPlan?.plan_cost || {});
        }
    }, [plans, plansToggler, maxPlans]);

    const startHumanizer = async (skipUpdate = false) => {
        const res = await postData({ url: "/api/user/disable-humanizer" });
        if (res.status === 200) {
            if (!skipUpdate) {
                dispatch(updateUserInfo("show_humanizer_landing", 0));
            }
        }
    };
    console.log(allPlans, ";;;;;;;;;");

    async function createHumanizerStripeSession(quantity = 2) {
        let humanizerPlanId = allPlans.filter(
            (ele) => ele.plan_id == subscriptionInfo.plan_id,
        );
        if (!humanizerPlanId.length > 0) {
            humanizerPlanId = allPlans.filter(
                (ele) =>
                    ele.plan_category == subscriptionInfo.plan_category &&
                    ele.plan_type == subscriptionInfo.type,
            );
        }
        document.StripeInvoicePayment = window.open(
            "",
            "",
            paymentPopUpDimension,
        );

        document.StripeInvoicePayment.document.write(
            "<html><head></head><body>Please wait while we redirect you</body></html>",
        );
        const res = await postData({
            url: UPDATE_PLAN,
            payload: {
                planID: subscriptionInfo.plan_id,
                type: 1,
                from: subscriptionInfo.plan_id,
                quantity: 1,
                add_on_info: [
                    {
                        humanizer_purchased_quantity:
                            subscriptionInfo.plan_category ==
                                PLANS_CATEGORIES.PRO_MAX ||
                            subscriptionInfo.plan_category ==
                                PLANS_CATEGORIES.GROWTH_MAX
                                ? quantity - 2
                                : quantity,
                        humanizer_plan_id: JSON.parse(
                            humanizerPlanId[0]?.add_on_plan_ids,
                        )?.humanizer,
                        prev_humanizer_plan_id:
                            subscriptionInfo?.humanizer_plan_id || null,
                        add_on: ADD_ONS_TYPE.AI_HUMANIZER,
                    },
                ],
            },
        });
        if (res.status == 200) {
            // @ts-ignore
            document?.StripeInvoicePayment?.close();
            startHumanizer();
            setHumanizerBuyMoreModal(false);
            // return setTimeout(() => goTo("AI_HUMANIZER", [], "", true), 5000);
        } else if (res.status == 201) {
            dispatch(updateUserInfo("planDowngraded", false));
            startHumanizer();
            document.StripeInvoicePayment.location =
                res.data.invoice.hosted_invoice_url;
            goTo("DUMMY_TRANSACTION");
        } else {
            document?.StripeInvoicePayment?.close();
        }
    }

    const handleHumanizerBuy = () => {
        if (isFreeForever || trialUser) {
            const container = document.getElementById("planContainer");
            container.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        } else {
            setHumanizerBuyMoreModal(true);
        }
    };

    const handleTryDemo = () => {
        if (isOpenChat) {
            setIsOfflinePaymentWarning(true);
            return;
        }
        if (
            ((trialUser || isFreeForever) && interlinking_status != 0) ||
            (interlinking_status == 0 && isFreeForever)
        ) {
            setDefaultAddOnChecked(true);
            const container = document.getElementById("planContainer");
            container.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        } else {
            setBuyPagesModal({
                isShow: true,
                domainId: null,
            });
        }
    };

    return loading ? (
        <Progress height={"300px"} width={"90%"} />
    ) : (
        <div
            style={{
                width: "90%",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                margin: "auto",
            }}
        >
            <div
                style={{
                    background: maxPlans
                        ? "linear-gradient(4.2deg, #FFFFFF 2.38%, #F9E4BB 95.53%)"
                        : "",
                }}
                className={styles.lmAddOnCardWrapper}
            >
                <div className={styles._left}>
                    <div className={styles._top}>
                        <div className={styles.topText}>
                            Free with{" "}
                            <span style={{ fontWeight: "500" }}>
                                {maxPlans ? "Max Plans" : "Annual Pro Plan"}
                            </span>
                        </div>
                        <div className={styles._line2}>Link Manager</div>
                        <div className={styles.dynamicPrices}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        visibility: !getDiscount(plansToggler)
                                            ? "hidden"
                                            : "unset",
                                    }}
                                    className={styles.cardPriceOriginalDis}
                                >
                                    {parsePrice(
                                        validPlanCost,
                                        currency,
                                        plansToggler,
                                    )}
                                </span>
                                {!maxPlans && (
                                    <span
                                        className={styles.offer}
                                        style={{
                                            visibility: !getDiscount(
                                                plansToggler,
                                            )
                                                ? "hidden"
                                                : "unset",
                                            marginLeft: "15px",
                                            // marginBottom: "10px",
                                        }}
                                    >
                                        <strong style={{ fontWeight: 500 }}>
                                            {getDiscount(plansToggler)}% OFF
                                        </strong>{" "}
                                        on Annual Plan
                                    </span>
                                )}
                            </div>

                            <div className={styles._line3Wrapper}>
                                <div>
                                    <div className={styles._line3WrapperPrice}>
                                        {parsePrice(
                                            maxPlans ? 0 : validPlanCost,
                                            currency,
                                            plansToggler,
                                            getDiscount(plansToggler),
                                        )}
                                        <span
                                            className={
                                                styles._line3WrapperPriceMo
                                            }
                                        >
                                            {"/mo"}
                                        </span>
                                    </div>
                                    <div
                                        className={styles.youSave}
                                        style={{
                                            visibility: !getDiscount(
                                                plansToggler,
                                            )
                                                ? "hidden"
                                                : "unset",
                                        }}
                                    >
                                        Billed annually{" "}
                                        <strong>
                                            (Save{" "}
                                            {getYearSave(
                                                validPlanCost,
                                                currency,
                                                plansToggler,
                                                getDiscount(plansToggler),
                                            )}
                                            /Year)
                                        </strong>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "1px",
                                        background: "#cbcbcb",
                                        height: "60px",
                                    }}
                                ></div>
                                <div
                                    className={
                                        styles._line3WrapperPriceBreakdown
                                    }
                                >
                                    {plansToggler == 2 && isDeal ? (
                                        <>
                                            {" "}
                                            <span
                                                style={{
                                                    textDecoration:
                                                        "line-through",
                                                }}
                                            >
                                                1000
                                            </span>{" "}
                                            4000 pages included
                                        </>
                                    ) : (
                                        "1000 pages included"
                                    )}
                                    <br />
                                    {currencySymbol}
                                    {currency == "USD" ? "5" : "425"}/500 pages
                                    thereafter
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        {KeyFeatures.map((ele) => (
                            <div style={{ marginRight: "30px" }}>
                                <div className={styles._left}>
                                    <div
                                        style={{
                                            width: "100%",
                                            marginBottom: "10px",
                                            // display: "flex",
                                            // justifyContent: "flex-start",
                                            // alignItems: "flex-start",
                                        }}
                                    >
                                        {ele.icon}
                                    </div>
                                </div>
                                <div>
                                    <div className={styles._title}>
                                        {ele.title}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {(!maxPlans ||
                        [
                            PLANS_CATEGORIES.PRO_MAX,
                            PLANS_CATEGORIES.GROWTH_MAX,
                        ].includes(subscriptionInfo.plan_category)) && (
                        <div className={styles._med}>
                            <Button
                                text={
                                    interlinking_status == 0 && !isFreeForever
                                        ? "Reactivate"
                                        : trialUser || isFreeForever
                                        ? "Grab The Deal"
                                        : "Buy More Pages"
                                }
                                style={{
                                    borderRadius: "25px",
                                    background: maxPlans ? "#F3C975" : "",
                                    color: maxPlans ? "#000" : "",
                                }}
                                handler={handleTryDemo}
                                width={190}
                                height={44}
                                Icon={() => (
                                    <SVGIcon
                                        src={"/New UI/SVG/arrow-right-up.svg"}
                                        size={16}
                                        style={{ marginLeft: 2 }}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
                {/* <div className={styles._midium}>
                {!!KeyFeatures.length &&
                    KeyFeatures.map((feat) => {
                        return (
                            <div className={styles.lmFeatCard}>
                                <div className={styles._left}>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        {feat.icon}
                                    </div>
                                </div>
                                <div className={styles._right}>
                                    <div className={styles._title}>
                                        {feat.title}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div> */}
                {/* <div className={styles._right}>
                <div className={styles.ssHolder}>
                    <SVGIcon src={"/New UI/SVG/link-manager-ss.svg"} />
                </div>
            </div> */}
                {isOfflinePaymentWarning && (
                    <OfflinePaymentModal
                        setModal={setIsOfflinePaymentWarning}
                    />
                )}
                {buyPagesModal?.isShow && (
                    <BuyMore
                        setBuyPagesModal={setBuyPagesModal}
                        domainId={buyPagesModal?.domainId}
                        screen={"Pricing LM Add-on Card"}
                        maxPlans={maxPlans}
                    />
                )}
                {humanizerBuyMoreModal && (
                    <HumanizerBuyMore
                        buyMoreAPI={createHumanizerStripeSession}
                        currency={currency}
                        plansToggler={
                            plan_type == 1 || plan_type == 2
                                ? plan_type
                                : plansToggler
                        }
                        // plan_type={plan_type==1||plan_type==2 ? plan_type : plansToggler}
                        getDiscount={getDiscount}
                        setModal={setHumanizerBuyMoreModal}
                        allPlans={allPlans}
                        subscriptionInfo={subscriptionInfo}
                        maxPlans={maxPlans}
                    />
                )}
            </div>
            <div
                className={styles.lmAddOnCardWrapper}
                style={{
                    background: maxPlans
                        ? "linear-gradient(4.2deg, #FFFFFF 2.38%, #F9E4BB 95.53%)"
                        : "linear-gradient(4.2deg, rgba(240, 255, 145, 0) 2.38%, rgba(79, 239, 23, 0.2) 95.53%)",
                }}
            >
                <div className={styles._left}>
                    <div className={styles._top}>
                        <div className={styles.topText}>
                            {maxPlans ? (
                                <>
                                    Free with{" "}
                                    <span style={{ fontWeight: "500" }}>
                                        Max Plans
                                    </span>{" "}
                                </>
                            ) : (
                                <>
                                    <span style={{ fontWeight: "500" }}>
                                        Limited words available
                                    </span>{" "}
                                    on all Scalenut plans
                                </>
                            )}
                        </div>
                        <div className={styles._line2}>
                            AI Detector & Humanizer
                        </div>
                        <div className={styles.dynamicPrices}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        visibility: !getDiscount(
                                            maxPlans ? 2 : plansToggler,
                                        )
                                            ? "hidden"
                                            : "unset",
                                    }}
                                    className={styles.cardPriceOriginalDis}
                                >
                                    {parsePrice(
                                        humanizerPlanCost,
                                        currency,
                                        maxPlans ? 2 : plansToggler,
                                    )}
                                </span>
                                {!maxPlans && (
                                    <span
                                        className={styles.offer}
                                        style={{
                                            visibility: !getDiscount(
                                                plansToggler,
                                            )
                                                ? "hidden"
                                                : "unset",
                                            marginLeft: "15px",
                                            // marginBottom: "10px",
                                        }}
                                    >
                                        <strong style={{ fontWeight: 500 }}>
                                            {getDiscount(plansToggler)}% OFF
                                        </strong>{" "}
                                        on Annual Plan
                                    </span>
                                )}
                            </div>

                            <div className={styles._line3Wrapper}>
                                <div>
                                    <div className={styles._line3WrapperPrice}>
                                        {parsePrice(
                                            maxPlans ? 0 : humanizerPlanCost,
                                            currency,
                                            maxPlans ? 2 : plansToggler,
                                            getDiscount(
                                                maxPlans ? 2 : plansToggler,
                                            ),
                                        )}
                                        <span
                                            className={
                                                styles._line3WrapperPriceMo
                                            }
                                        >
                                            {"/mo"}
                                        </span>
                                    </div>
                                    <div
                                        className={styles.youSave}
                                        style={{
                                            visibility: !getDiscount(
                                                maxPlans ? 2 : plansToggler,
                                            )
                                                ? "hidden"
                                                : "unset",
                                        }}
                                    >
                                        Billed annually{" "}
                                        <strong>
                                            (Save{" "}
                                            {getYearSave(
                                                humanizerPlanCost,
                                                currency,
                                                maxPlans ? 2 : plansToggler,
                                                getDiscount(
                                                    maxPlans ? 2 : plansToggler,
                                                ),
                                            )}
                                            /Year)
                                        </strong>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "1px",
                                        background: "#cbcbcb",
                                        height: "60px",
                                    }}
                                ></div>
                                <div
                                    className={
                                        styles._line3WrapperPriceBreakdown
                                    }
                                >
                                    Detect unlimited words <br />
                                    Humanize 50,000 words/mo
                                    <br />
                                    {currencySymbol}
                                    {currency == "USD" ? "5" : "425"}/25,000
                                    words thereafter
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        {KeyFeatures1.map((ele) => (
                            <div style={{ marginRight: "30px" }}>
                                <div className={styles._left}>
                                    <div
                                        style={{
                                            width: "100%",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {ele.icon}
                                    </div>
                                </div>
                                <div>
                                    <div className={styles._title}>
                                        {ele.title}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {(!maxPlans ||
                        [
                            PLANS_CATEGORIES.PRO_MAX,
                            PLANS_CATEGORIES.GROWTH_MAX,
                        ].includes(subscriptionInfo.plan_category)) && (
                        <div className={styles._med}>
                            <Button
                                text={
                                    isFreeForever || trialUser
                                        ? "Grab The Deal"
                                        : "Buy More"
                                }
                                style={{
                                    borderRadius: "25px",
                                    background: maxPlans ? "#F3C975" : "",
                                    color: maxPlans ? "#000" : "",
                                }}
                                handler={() => {
                                    handleHumanizerBuy();
                                }}
                                width={190}
                                height={44}
                                Icon={() => (
                                    <SVGIcon
                                        src={"/New UI/SVG/arrow-right-up.svg"}
                                        size={16}
                                        style={{ marginLeft: 2 }}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export const HumanizerBuyMore = ({
    setModal,
    currency,
    plansToggler,
    getDiscount,
    allPlans,
    buyMoreAPI,
    subscriptionInfo,
    maxPlans,
}) => {
    const [selectedWords, setSelectedWords] = useState(0);
    const [humanizerPlanCost, setHumanizerPlanCost] = useState(0);
    const [updatedPlanCost, setUpdatedPlanCost] = useState(0);
    const [additionalPrice, setAdditionalPrice] = useState(5);
    const [loading, setLoading] = useState(false);
    const [quota, setQuota] = useState({});
    const dispatch = useDispatch();
    const { getData } = useHelperFunctions();

    const getSubscriptionDetails = async () => {
        setLoading(true);
        const res = await getData({
            url: "/api/pricing/getSubscriptionPlans",
        });
        if (res.status == 200) {
            setQuota(res.data?.quota);
        } else {
            setModal(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        getSubscriptionDetails();
    }, []);

    useEffect(() => {
        if (allPlans.length) {
            const tempPlan = allPlans.find(
                (vp) =>
                    vp?.plan_cost > 0 &&
                    vp?.plan_type == plansToggler &&
                    vp.plan_category == PLANS_CATEGORIES.HUMANIZER,
            );
            console.log(tempPlan?.plan_cost, "uppppppptttttttttt");
            setHumanizerPlanCost(tempPlan?.plan_cost || {});

            const isYearlyPlan = plansToggler == PLANS_TYPE.YEARLY;
            setAdditionalPrice(
                currency == "INR"
                    ? ADDITIONAL_INR_PRICE * (isYearlyPlan ? 12 : 1)
                    : ADDITIONAL_USD_PRICE * (isYearlyPlan ? 12 : 1),
            );
        }
    }, [allPlans]);

    useEffect(() => {
        if (selectedWords == 50000) {
            setUpdatedPlanCost(humanizerPlanCost);
        } else {
            const newPrice =
                ((selectedWords -
                    (quota?.humanizer_limit >= 50000
                        ? quota?.humanizer_limit
                        : 0)) /
                    25000) *
                additionalPrice;
            setUpdatedPlanCost(newPrice);
        }
        console.log(selectedWords, "selected");
    }, [selectedWords]);

    console.log(humanizerPlanCost, "uppppppp");

    useEffect(() => {
        setSelectedWords(
            quota?.humanizer_limit >= 50000
                ? quota?.humanizer_limit + 25000
                : 50000,
        );
    }, [quota?.humanizer_limit]);

    // useEffect(() => {
    //     setUpdatedPlanCost(humanizerPlanCost);
    // }, [humanizerPlanCost]);

    const handleChange = (e) => {
        setSelectedWords(Number(e.target.value));
    };
    return (
        <Modal setModal={setModal}>
            <div className={styles.humanizerBuyMore}>
                {loading ? (
                    <CircularProgress
                        style={{ color: "var(--primary-color1)" }}
                    />
                ) : (
                    <>
                        <VscClose
                            style={{
                                position: "absolute",
                                top: 14,
                                right: 15,
                                cursor: "pointer",
                            }}
                            onClick={() => setModal(false)}
                            size={22}
                            color="#6b6b6b"
                        />
                        <div className={styles.buyMoreHeading}>Buy More</div>
                        <div className={styles.buyMorePrice}>
                            {plansToggler == 2 ? (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: !getDiscount(
                                                    maxPlans ? 5 : plansToggler,
                                                )
                                                    ? "none"
                                                    : "unset",
                                            }}
                                            className={
                                                styles.cardPriceOriginalDis
                                            }
                                        >
                                            {parsePrice(
                                                updatedPlanCost,
                                                currency,
                                                plansToggler,
                                            )}
                                        </span>
                                        <span
                                            className={styles.offer}
                                            style={{
                                                display: !getDiscount(
                                                    maxPlans ? 5 : plansToggler,
                                                )
                                                    ? "none"
                                                    : "unset",
                                                marginLeft: "25px",
                                                padding: "7px 15px",
                                                background: "#ffe866",
                                            }}
                                        >
                                            <strong style={{ fontWeight: 500 }}>
                                                {getDiscount(
                                                    maxPlans ? 5 : plansToggler,
                                                )}
                                                % OFF
                                            </strong>{" "}
                                            Launch Offer
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            justifyContent: "center",
                                            color: "#1f4cbe",
                                            fontSize: "2em",
                                            fontWeight: 700,
                                        }}
                                        className={styles._line3WrapperPrice}
                                    >
                                        {parsePrice(
                                            updatedPlanCost,
                                            currency,
                                            plansToggler,
                                            getDiscount(
                                                maxPlans ? 5 : plansToggler,
                                            ),
                                        )}
                                        <span
                                            style={{
                                                fontSize: 26,
                                                color: "#1f4cbe",
                                            }}
                                            className={
                                                styles._line3WrapperPriceMo
                                            }
                                        >
                                            {"/mo"}
                                        </span>
                                    </div>
                                    <div
                                        className={styles.youSave}
                                        style={{
                                            visibility: !getDiscount(
                                                maxPlans ? 5 : plansToggler,
                                            )
                                                ? "hidden"
                                                : "unset",
                                        }}
                                    >
                                        Billed annually{" "}
                                        <strong>
                                            (Save{" "}
                                            {getYearSave(
                                                humanizerPlanCost,
                                                currency,
                                                plansToggler,
                                                getDiscount(
                                                    maxPlans ? 5 : plansToggler,
                                                ),
                                            )}
                                            /Year)
                                        </strong>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            justifyContent: "center",
                                            color: "#1f4cbe",
                                            fontSize: "2em",
                                            fontWeight: 700,
                                        }}
                                        className={styles._line3WrapperPrice}
                                    >
                                        {parsePrice(
                                            updatedPlanCost,
                                            currency,
                                            plansToggler,
                                        )}
                                        <span
                                            style={{
                                                fontSize: 26,
                                                color: "#1f4cbe",
                                            }}
                                            className={
                                                styles._line3WrapperPriceMo
                                            }
                                        >
                                            {"/mo"}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={styles.buyMoreDropdown}>
                            <label for="humanizer-words">
                                Humanizer words/mo:
                            </label>

                            <select
                                onChange={handleChange}
                                value={selectedWords}
                                name="words"
                                id="humanizer-words"
                            >
                                {subscriptionInfo?.humanizer_purchased_words <
                                    50000 && (
                                    <option value={50000}>50000</option>
                                )}
                                {new Array(15).fill(1).map((_, i) => (
                                    <option
                                        value={
                                            (quota?.humanizer_limit >= 50000
                                                ? quota?.humanizer_limit
                                                : 50000) +
                                            25000 * (i + 1)
                                        }
                                    >
                                        {(quota?.humanizer_limit >= 50000
                                            ? quota?.humanizer_limit
                                            : 50000) +
                                            25000 * (i + 1)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.currentStats}>
                            <div className={styles.stat1}>
                                Current Plan Limit:
                                <div>
                                    {quota?.humanizer_limit
                                        ? `${quota?.humanizer_limit} words/mo`
                                        : "-"}
                                </div>
                            </div>
                            <div className={styles.stat1}>
                                Additional Purchased Words:
                                <div>
                                    {selectedWords - quota?.humanizer_limit}{" "}
                                    words/mo
                                </div>
                            </div>
                            <div className={styles.stat1}>
                                Revised Plan Limit:
                                <div>{selectedWords} words/mo</div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "90px",
                                marginTop: "10px",
                                width: "330px",
                            }}
                        >
                            <Button
                                handler={() => setModal(false)}
                                text="Cancel"
                                secondary={true}
                                style={{
                                    fontWeight: 600,
                                    borderRadius: "20px",
                                    padding: "4px 20px",
                                    color: "#09060D",
                                    background: "white",
                                }}
                            />
                            <Button
                                handler={() => {
                                    if (
                                        selectedWords <= quota?.humanizer_limit
                                    ) {
                                        dispatch(
                                            displayAlert({
                                                alertMessage:
                                                    "selected words should be greater than current limit",
                                                alertType: "error",
                                                timeout: 10000,
                                            }),
                                        );
                                        return;
                                    }
                                    buyMoreAPI(selectedWords / 25000);
                                }}
                                text="Buy Now"
                                style={{
                                    fontWeight: 600,
                                    borderRadius: "20px",
                                    padding: "4px 20px",
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default LmAddOnCard;
