import React, {
    createContext,
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    useRef,
} from "react";

export type DomainInfo = {
    auto_verification_attempts: number;
    company_id: number;
    created_at: number;
    directory: null;
    domain: string;
    gsc_id: number;
    id: number;
    input_domain: string;
    interlinking_stable_version: null;
    interlinking_status: null | number;
    is_active: number;
    is_connected: number;
    is_verified: number;
    last_crawl_date: Date;
    last_publish_date: Date;
    last_refresh_date: Date;
    last_scrape_date: Date;
    sitemap_url: null;
    source: number;
    stable_version: number;
    stable_version_total_crawled_pages: number;
    stable_version_total_pages: number;
    status: number;
    total_crawled_pages: number;
    total_pages: number;
    updated_at: string;
    user_id: number;
    user_verification_attempts: number;
    verification_key: string;
    re_auth: number;
    gsc_domain_name: string;
    feedbackShow?: boolean;
} | null;

export type InterlinkingContextType = {
    domainsList: Array<DomainInfo>;
    selectedDomainInfo: DomainInfo;
    loading: boolean;
    setState: Dispatch<SetStateAction<InterlinkingContextType>>;
    updateContext: Function;
};

const defaultState: InterlinkingContextType = {
    domainsList: [],
    selectedDomainInfo: null,
    loading: true,
    setState: () => {},
    updateContext: function () {},
};

export const InterlinkingContext = createContext(defaultState);

function InterLinkingContextProvider({ children }: { children: JSX.Element }) {
    const [state, setState] = useState(defaultState);
    const selectedDomainInfoRef = useRef(null as any);
    selectedDomainInfoRef.current = state.selectedDomainInfo;
    const updateContext = (change: {}, domain_id) => {
        setState((ps: any) => {
            if (domain_id === selectedDomainInfoRef.current?.id) {
                return {
                    ...ps,
                    selectedDomainInfo: {
                        ...ps.selectedDomainInfo,
                        ...change,
                    },
                };
            } else {
                return {
                    ...ps,
                    domainsList: ps.domainsList.map((domain) => {
                        if (domain_id === domain.id) {
                            domain = { ...domain, ...change };
                        }

                        return domain;
                    }),
                };
            }
        });
    };

    useEffect(() => {
        setState((ps) => {
            const _domainList = ps.domainsList.map((domain: DomainInfo) => {
                if (domain && domain.id === state.selectedDomainInfo?.id) {
                    domain = state.selectedDomainInfo;
                }
                return domain;
            });
            return { ...ps, domainsList: _domainList as any };
        });
    }, [state.selectedDomainInfo]);

    return (
        <InterlinkingContext.Provider
            value={{ ...state, setState, updateContext }}
        >
            {children}
        </InterlinkingContext.Provider>
    );
}

export default InterLinkingContextProvider;
