import React from "react";
import styles from "./GenerateVariantCard.module.css";
import { AiOutlineRight } from "react-icons/ai";
import Separator from "../../atoms/Separator/Separator";

export default function GenerateVariantCard({
    title,
    iconSrc,
    handler,
    subText = "Sample subtitle text",
}) {
    return (
        <div className={styles.categoryCard} onClick={handler}>
            <div className={styles.cardTop}>
                <div className={styles.image}>
                    <img
                        height={75}
                        width={75}
                        src={
                            iconSrc
                                ? iconSrc
                                : "https://storage.googleapis.com/ankit-scalenut/025c8fb3-4b11-41fd-9f85-63ece5bd09d3.svg"
                        }
                    />
                </div>
            </div>
            <div className={styles.cardBottom}>
                <h3 className={styles.categoryCardTitle}>{title}</h3>
                <p className={styles.subText}>{subText}</p>
                {/* <Separator /> */}
            </div>
        </div>
    );
}
