import React, { useEffect, useState } from "react";
import styles from "./KeywordCannibalization.module.css";
import SVGIcon from "../../../atoms/SVGIcon";
import useGscHook, { CannibalizationPageObject } from "../useGscHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { IoCheckbox } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { PagesLineGraph } from "./PagesLineGraph";
import moment from "moment";

type Props_KCPageSubTable = {
    _pages: CannibalizationPageObject[];
    parentKeyword: string;
    dateRange: {
        startDate: string;
        endDate: string;
    };
};

type Props_ItemRow = {
    checkColor: string;
    index: number;
    pageItem: CannibalizationPageObject;
    handleCheckedPages: (
        index: number,
        isChecked: any,
    ) => { status: boolean; msg: string };
};

export interface GraphForPageObject {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth: number;
    pointHoverRadius: number;
    pointRadius: number;
    animation: boolean;
    spanGaps?: boolean;
    borderDash?: number[];
    page?: string;
}

const CurrentColorPalette = [
    "#8A2BE2",
    "#FF8C00",
    "#4B0082",
    "#1E90FF",
    "#FF6F61",
    "#9370DB",
    "#32CD32",
    "#FF00FF",
    "#6A5ACD",
    "#8B008B",
    "#FFA07A",
    "#00FA9A",
    "#FF1493",
    "#00BFFF",
    "#FFA500",
    "#40E0D0",
    "#FF69B4",
];

const generateDateLabels: (obj: {
    endDate: string;
    startDate: string;
}) => string[] = ({ startDate, endDate }) => {
    let temp: string[] = [];
    let firstDate = moment(startDate);
    let lastDate = moment(endDate);

    while (firstDate <= lastDate) {
        temp.push(firstDate.format("DD/MM/YYYY"));
        firstDate.add(1, "day");
    }
    return temp;
};

const checkBoxCustom = makeStyles((theme) => ({
    root: {
        marginTop: -9,
    },
}));

const SortByOptions: { title: string; id: number }[] = [
    {
        title: "Rank",
        id: 1,
    },
    {
        title: "impressions",
        id: 2,
    },
    {
        title: "impressions share",
        id: 3,
    },
    {
        title: "traffic",
        id: 4,
    },
    {
        title: "traffic share",
        id: 5,
    },
    {
        title: "ctr",
        id: 6,
    },
];

const ColorPalette = [
    "#8A2BE2",
    "#FF8C00",
    "#4B0082",
    "#1E90FF",
    "#FF6F61",
    "#9370DB",
    "#32CD32",
    "#FF00FF",
    "#6A5ACD",
    "#8B008B",
    "#FFA07A",
    "#00FA9A",
    "#FF1493",
    "#00BFFF",
    "#FFA500",
    "#40E0D0",
    "#FF69B4",
];

const PageItemRow = ({
    checkColor,
    index = 0,
    pageItem,
    handleCheckedPages,
}: Props_ItemRow) => {
    const { getValueWithUnit, removeDomainFromURL } = useGscHook();
    const [isExpandedRow, setIsExpandedRow] = useState(true);
    const [copied, setCopied] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [showWarning, setShowWarning] = useState("");
    const checkBoxClasses = checkBoxCustom();
    const handleCopy: (page: string) => void = (page) => {
        setCopied(true);
        window.navigator.clipboard.writeText(page);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    return (
        <div className={styles.pageItemRowWrapper}>
            <div className={styles.pageItemRow_pageUrl}>
                <div
                    style={{
                        width: "20px",
                        height: "20px",
                    }}
                    onMouseLeave={() => {
                        setShowWarning("");
                    }}
                >
                    <LightTooltip
                        title={showWarning?.length ? showWarning : ""}
                        interactive
                        placement={"top-start"}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={pageItem.isChecked}
                                    classes={checkBoxClasses}
                                    value={checkColor}
                                    onChange={(e) => {
                                        const { status, msg } =
                                            handleCheckedPages(
                                                index,
                                                !pageItem.isChecked,
                                            );
                                        if (!status) {
                                            if (msg.length) {
                                                setShowWarning(msg);
                                                setTimeout(() => {
                                                    setShowWarning("");
                                                }, 3000);
                                            }
                                        }
                                    }}
                                    icon={
                                        <MdCheckBoxOutlineBlank
                                            size={20}
                                            color={checkColor}
                                        />
                                    }
                                    checkedIcon={
                                        <IoCheckbox
                                            size={20}
                                            color={checkColor}
                                        />
                                    }
                                    style={{
                                        transform: "scale(.8)",
                                    }}
                                />
                            }
                            label={""}
                        />
                    </LightTooltip>
                </div>
                <div className={styles.data}>
                    <LightTooltip
                        title={pageItem.page}
                        placement={"bottom"}
                        arrow
                        // PopperProps={{ disablePortal: true }}
                        onOpen={(e: any) => {
                            if (e.target.scrollWidth <= e.target.offsetWidth) {
                                setImmediate(() => {
                                    document
                                        .querySelectorAll("[role=tooltip]")
                                        .forEach(
                                            (e: any) =>
                                                (e.style.display = "none"),
                                        );
                                });
                            }
                        }}
                    >
                        <span
                            className={styles.pageUrl}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(pageItem.page, "_blank");
                            }}
                        >
                            {removeDomainFromURL(pageItem.page)}
                        </span>
                    </LightTooltip>
                    <LightTooltip
                        title={copied ? "Copied!" : "Click to Copy"}
                        placement={"bottom"}
                        arrow
                    >
                        <span
                            className={styles.externalLink}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                handleCopy(pageItem.page);
                            }}
                        >
                            <SVGIcon src={"/New UI/SVG/copy.svg"} size={"14"} />
                        </span>
                    </LightTooltip>
                </div>
            </div>
            <div className={styles.pageItemRow_rank}>
                {pageItem.avg_position != null ? pageItem.avg_position : "-"}
            </div>

            <div className={styles.pageItemRow_impressions}>
                {pageItem.impressions != null
                    ? getValueWithUnit(pageItem.impressions)
                    : "-"}
            </div>
            <div className={styles.pageItemRow_impressionShare}>
                {pageItem.impressionsShare != null
                    ? pageItem.impressionsShare + "%"
                    : "-"}
            </div>
            <div className={styles.pageItemRow_traffic}>
                {pageItem.clicks != null
                    ? getValueWithUnit(pageItem.clicks)
                    : "-"}
            </div>
            <div className={styles.pageItemRow_trafficShare}>
                {pageItem.trafficShare != null
                    ? pageItem.trafficShare + "%"
                    : "-"}
            </div>
            <div className={styles.pageItemRow_ctr}>
                {pageItem.ctr != null ? pageItem.ctr + "%" : "-"}
            </div>
        </div>
    );
};

const KCPageSubTable = ({
    _pages,
    parentKeyword,
    dateRange,
}: Props_KCPageSubTable) => {
    const [pages, setPages] = useState<CannibalizationPageObject[]>([]);
    const [graphDataSet, setGraphDataSet] = useState<GraphForPageObject[]>([]);
    const [order, setOrder] = useState<0 | 1>(0); //0 = asec, 1= desc
    const [dateLabels, setDateLabels] = useState<string[]>([]);
    const [graphMinMax, setGraphMinMax] = useState<{
        min: number;
        max: number;
    }>({ min: 0, max: 0 });
    const [sortBy, setSortBy] = useState<{
        id: number;
        title: string | object;
    }>(SortByOptions[0]);
    const { trendsSD, removeDomainFromURL } = useGscHook();

    useEffect(() => {
        if (_pages.length > 0) {
            setPages([..._pages]);
        } else {
            setPages([]);
        }
    }, [_pages]);

    useEffect(() => {
        if (dateRange?.endDate && dateRange?.startDate) {
            setDateLabels(
                generateDateLabels({
                    startDate: dateRange?.startDate,
                    endDate: dateRange?.endDate,
                }),
            );
        }
    }, [dateRange]);

    const handleSortByColumns: (index: number) => void = (index) => {
        setSortBy((ps) => {
            if (ps?.id == SortByOptions[index].id) {
                setOrder((ps) => (ps == 0 ? 1 : 0));
                return SortByOptions[index];
            } else {
                setOrder(1);
                return SortByOptions[index];
            }
        });
    };

    const sortPages: (
        a: CannibalizationPageObject,
        b: CannibalizationPageObject,
    ) => number = (a, b) => {
        if (order === 0) {
            // Swap a and b when sorting in ascending order
            const temp = b;
            b = a;
            a = temp;
        }

        if (sortBy.id == SortByOptions[0].id) {
            return b.avg_position - a.avg_position;
        } else if (sortBy.id == SortByOptions[1].id) {
            return b.impressions - a.impressions;
        } else if (sortBy.id == SortByOptions[2].id) {
            return b.impressions - a.impressions; //impression share
        } else if (sortBy.id == SortByOptions[3].id) {
            return b.clicks - a.clicks;
        } else if (sortBy.id == SortByOptions[4].id) {
            return b.clicks - a.clicks; // traffic share
        } else if (sortBy.id == SortByOptions[5].id) {
            return b.ctr - a.ctr;
        } else {
            return a.avg_position - b.avg_position;
        }
    };

    const dataRows: CannibalizationPageObject | any[] = [
        ...pages.sort(sortPages),
    ];

    useEffect(() => {
        if (pages.length > 0) {
            let temp: GraphForPageObject[] = [];
            let min = 0,
                max = 0;
            pages.forEach((item: CannibalizationPageObject, index: number) => {
                if (item.isChecked) {
                    max =
                        trendsSD(item.position_list) > max
                            ? trendsSD(item.position_list)
                            : max;
                    temp.push({
                        label: `Dataset ${index + "-1"}`,
                        data: item.filtred_position_list,
                        borderColor: ColorPalette[index % ColorPalette.length],
                        borderWidth: 2,
                        pointHoverRadius: 4,
                        pointRadius: 1,
                        animation: false,
                        spanGaps: false,
                        page: removeDomainFromURL(item.page),
                    });
                    temp.push({
                        label: `Dataset ${index + "-2"}`,
                        data: item.dashed_position_list,
                        borderColor: "gray",
                        borderWidth: 2,
                        pointHoverRadius: 0,
                        pointRadius: 0,
                        animation: false,
                        spanGaps: true,
                        borderDash: [6, 6],
                    });
                }
            });
            setGraphMinMax({
                min: Math.round(min),
                max: Math.round(max),
            });
            setGraphDataSet([...temp]);
        } else {
            setGraphDataSet([]);
        }
    }, [pages]);

    const handleCheckedPages: (
        index: number,
        isChecked: any,
    ) => { status: boolean; msg: string } = (index, isChecked) => {
        if (index == -1 || index > pages?.length) {
            return {
                status: false,
                msg: "",
            };
        }
        if (graphDataSet.length / 2 == 1 && !isChecked) {
            //at least one checkbox should be checked
            return {
                status: false,
                msg: "Please ensure at least one page is checked.",
            };
        }
        if (graphDataSet.length / 2 == 5 && isChecked) {
            //max 5 checkboxes can be checked
            return {
                status: false,
                msg: "You can select up to five pages at a time.",
            };
        }

        setPages((ps) => {
            let temp = [...ps];
            temp[index].isChecked = isChecked;
            return temp;
        });
        return {
            status: true,
            msg: "Success",
        };
    };

    return (
        <div className={styles.expandedRowWrapper}>
            {graphDataSet.length > 0 && (
                <div className={styles.pageStatGraphContainer}>
                    <div className={styles.graphLegend_left}>Rank</div>
                    <div className={styles.graphWrapper}>
                        <PagesLineGraph
                            dataSet={graphDataSet}
                            labels={[...dateLabels]}
                            min={0}
                            max={graphMinMax.max}
                            isReverseY={true}
                            stepSize={
                                graphMinMax.max > 60
                                    ? 15
                                    : graphMinMax.max > 40
                                    ? 10
                                    : 5
                            }
                        />
                    </div>
                </div>
            )}
            <div className={styles.pageSubTable}>
                <div className={styles.pageSubTableTitle}>
                    Affected Pages <span>{dataRows.length}</span>
                </div>
                <div className={styles.pageTableWrapper}>
                    <div className={styles.pageTableHeadRowWrapper}>
                        <div className={styles.pageTableHead_pageUrl}>
                            Page URL
                        </div>
                        <div
                            className={
                                styles.pageTableHead_rank +
                                " " +
                                (sortBy.id == SortByOptions[0].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(0);
                            }}
                        >
                            Rank{" "}
                            <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[0].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.pageTableHead_impressions +
                                " " +
                                (sortBy.id == SortByOptions[1].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(1);
                            }}
                        >
                            Impressions
                            <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[1].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.pageTableHead_impressionShare +
                                " " +
                                (sortBy.id == SortByOptions[2].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(2);
                            }}
                        >
                            Impressions Share
                            <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[2].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.pageTableHead_traffic +
                                " " +
                                (sortBy.id == SortByOptions[3].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(3);
                            }}
                        >
                            Traffic
                            <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[3].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                        <div
                            className={
                                styles.pageTableHead_trafficShare +
                                " " +
                                (sortBy.id == SortByOptions[4].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(4);
                            }}
                        >
                            Traffic Share
                            <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[4].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>

                        <div
                            className={
                                styles.pageTableHead_ctr +
                                " " +
                                (sortBy.id == SortByOptions[5].id
                                    ? styles.enable
                                    : "")
                            }
                            onClick={() => {
                                handleSortByColumns(5);
                            }}
                        >
                            CTR
                            <SVGIcon
                                size={20}
                                style={{
                                    cursor: "pointer",
                                    color: "#2c3948c2",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                                src={`/New UI/SVG/sort${
                                    order == 0 &&
                                    SortByOptions[5].id == sortBy.id
                                        ? "A"
                                        : "D"
                                }.svg`}
                            />
                        </div>
                    </div>
                    <div className={styles.pageItemRowsContainer}>
                        {dataRows.length > 0 ? (
                            dataRows.map(
                                (
                                    pageItem: CannibalizationPageObject,
                                    index,
                                ) => {
                                    return (
                                        <PageItemRow
                                            key={index + 1}
                                            index={index}
                                            pageItem={pageItem}
                                            handleCheckedPages={
                                                handleCheckedPages
                                            }
                                            checkColor={
                                                CurrentColorPalette[
                                                    index %
                                                        CurrentColorPalette.length
                                                ]
                                            }
                                        />
                                    );
                                },
                            )
                        ) : (
                            <>
                                <div className={styles.noItems}>
                                    <SVGIcon
                                        size={280}
                                        src={"/New UI/SVG/Emptydocument.svg"}
                                    />
                                    <h3 className={styles.text}>
                                        No Pages Found
                                    </h3>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KCPageSubTable;
