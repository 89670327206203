import React from "react";
import styles from "./activity.module.css";
import { MdClose } from "react-icons/md";

export default function BigImageModal({
  bigImgModalOpen,
  setBigImgModalOpen,
  imgSrc,
}) {
  if (bigImgModalOpen) {
    return (
      <div className={styles.bigImageModal}>
        <MdClose
          onClick={() => setBigImgModalOpen(false)}
          className={styles.bigImageModalCross}
        />
        <div className={styles.bigImageModalContent}>
          <img id={"img"} src={imgSrc} />
        </div>
      </div>
    );
  } else return null;
}
