import { TextareaAutosize } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { v4 } from "uuid";
import SVGIcon from "../../../atoms/SVGIcon";

import { Outline } from "../GoNuts/classes";
import { checkForUUID, highlightNode } from "../GoNuts/cruiseConstant";
import { CRUISE_TYPES } from "../GoNuts/useCruiseHook";
import styles from "./EB.module.css";
import { BRIEF_TYPES } from "./useEditorBriefHook";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import { VscTrash } from "react-icons/vsc";

const EBQ = ({
    item,
    index,
    readOnly = false,
    provided,
    dispatchEditorBrief,
}) => {
    console.log("EBQ EBQ", item);
    const [input, setInput] = useState(item?.data ? item?.data : "");

    const [isEditSubData, setIsEditSubData] = useState(false);
    const [showSubData, setShowSubData] = useState(
        item?.subData ? true : false,
    );
    const [subData, setSubData] = useState(item?.subData ? item.subData : "");

    function editSubData(data) {
        dispatchEditorBrief({
            type: BRIEF_TYPES.EDIT_SUBDATA,
            index: index,
            data: data,
            id: item.id,
            tag: Outline.tags.question,
        });
    }

    function handleEdit(text, onEnter = false) {
        if (!text.trim()) {
            deleteFaq();
            return;
        }
        setInput(text);

        const newFaq = new Outline({
            type: Outline.type.question,

            id: v4(),
            data: text,
            tag: Outline.tags.question,
        });
        dispatchEditorBrief({
            type: CRUISE_TYPES.EDIT_OUTLINES,
            index: index,
            field: Outline.type.question,
            data: newFaq,
            id: item.id,
        });
    }

    function deleteFaq() {
        dispatchEditorBrief({
            type: CRUISE_TYPES.DELETE_OUTLINES,
            index: index,
            field: Outline.type.question,
            id: item.id,
            tag: Outline.tags.question,
        });
    }

    const ref = useRef();

    useEffect(() => {
        if (checkForUUID(item?.id)) {
            highlightNode(ref?.current);
        }
    }, []);

    return (
        <div ref={ref} className={styles.itemMainContainer}>
            <div {...provided.dragHandleProps} className={styles.dragIcon}>
                <SVGIcon
                    color="lightgray"
                    size={16}
                    src="/New UI/SVG/drag.svg"
                />
            </div>
            <div
                className={
                    styles.itemMainBox + " " + styles.itemMainBoxNoBorder
                }
            >
                <div className={styles.options}>
                    {!readOnly && (
                        <div className={styles.itemIconsContainer}>
                            <LightTooltip
                                title={
                                    !readOnly
                                        ? item.subData
                                            ? "Click to edit note"
                                            : "Click to add note"
                                        : ""
                                }
                                arrow
                                placement="top"
                            >
                                <div className={styles.itemIcon}>
                                    <SVGIcon
                                        size={13.5}
                                        onClick={() => {
                                            setShowSubData(true);
                                            setIsEditSubData(true);
                                        }}
                                        src="/New UI/SVG/edit-note.svg"
                                    />
                                </div>
                            </LightTooltip>
                            <div className={styles.itemIcon}>
                                <VscTrash
                                    size={16}
                                    onClick={() => {
                                        deleteFaq();
                                    }}
                                    color="#f94f2c"
                                />
                            </div>
                        </div>
                    )}

                    <div className={styles.tagDropDownItemSel}>
                        Q{index + 1}
                    </div>
                </div>

                <LightTooltip
                    title={!readOnly ? "Click to Edit" : ""}
                    arrow
                    placement="top"
                >
                    <div
                        onClick={() => {
                            // if (!readOnly) setEditMainData(true);
                        }}
                        className={styles.itemData}
                    >
                        <TextareaAutosize
                            className={styles.mainDataTextArea}
                            disabled={false}
                            type="text"
                            placeholder={"Add FAQ here"}
                            maxLength={120}
                            value={input}
                            onChange={(e) => {
                                console.log("inputtt", e);
                                setInput(e.target.value);
                            }}
                            onBlur={(e) => handleEdit(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    e.target.blur();
                                }
                            }}
                        />
                    </div>
                </LightTooltip>
            </div>
            {showSubData && (
                <div
                    className={styles.itemsubDataBox}
                    onClick={() => {
                        // if (!readOnly) setEditsubDataBox(true);
                    }}
                >
                    <div className={styles.subData}>
                        {!isEditSubData ? (
                            <div onClick={() => setIsEditSubData(true)}>
                                {subData ? (
                                    subData
                                ) : (
                                    <div className={styles.placeholder}>
                                        Add your note here
                                    </div>
                                )}
                            </div>
                        ) : (
                            <TextareaAutosize
                                value={subData}
                                autoFocus={true}
                                placeholder={"Add your note here"}
                                className={styles.subDataTextArea}
                                onChange={(e) => setSubData(e.target.value)}
                                onBlur={(e) => {
                                    setIsEditSubData(false);
                                    if (!e.target.value.trim())
                                        setShowSubData(false);
                                    editSubData(e.target.value.trim());
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        e.target.blur();
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EBQ;
