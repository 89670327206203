import { Skeleton } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./Favourite.module.css";
import FavouriteCard from "./FavouriteCard";
import FavouriteModal from "./FavouriteModal";

function Favourite({
    getFavs,
    data,
    hasMore,
    modal,
    setModal,
    modalData,
    modalTopic,
    setLocalData,
    page,
    setPage,
    setHasMore,
}) {
    useEffect(() => {
        const sliderArray = document.getElementsByClassName(styles.cardList);
        for (const slider of sliderArray) {
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;

                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener("mouseleave", () => {
                isDown = false;
            });
            slider.addEventListener("mouseup", () => {
                isDown = false;
            });
            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    }, []);

    return (
        <div className={styles.container}>
            {modal && (
                <FavouriteModal
                    data={modalData}
                    topic={modalTopic}
                    setModal={setModal}
                    setLocalData={setLocalData}
                />
            )}
            <>
                <h2 className={styles.heading}>
                    All your favorite content copies
                </h2>
                <h6 className={styles.subHeading}>
                    Edit, copy or share it with your team.
                </h6>

                <InfiniteScroll
                    height={"calc(100vh - 160px)"}
                    dataLength={data.length}
                    next={() => {
                        getFavs(page + 1, setHasMore);
                        setPage(page + 1);
                    }}
                    hasMore={hasMore}
                    loader={
                        <>
                            <div className={styles.cardListContainer}>
                                <div className={styles.cardList}>
                                    {/* <div className={styles.blurEnd}></div> */}
                                    <Skeleton
                                        style={{ margin: "0 20px 20px 0 " }}
                                        width={262}
                                        height={130}
                                    />
                                    <Skeleton
                                        style={{ margin: "0 20px 20px 0 " }}
                                        width={262}
                                        height={130}
                                    />
                                    <Skeleton
                                        style={{ margin: "0 20px 20px 0 " }}
                                        width={262}
                                        height={130}
                                    />
                                    <Skeleton
                                        style={{ margin: "0 20px 20px 0 " }}
                                        width={262}
                                        height={130}
                                    />
                                    <Skeleton
                                        style={{ margin: "0 20px 20px 0 " }}
                                        width={262}
                                        height={130}
                                    />
                                </div>
                            </div>
                        </>
                    }
                >
                    {data}
                </InfiniteScroll>
            </>
        </div>
    );
}

export default Favourite;
