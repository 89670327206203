import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    withStyles,
    Tooltip,
    CircularProgress,
} from "@material-ui/core";

import React, { useState, useEffect, useContext } from "react";
import { FiAlignLeft } from "react-icons/fi";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";

import styles from "./Competetion.module.css";
import CopyH2H3 from "./CopyH2H3";
import { WRITE_FOR_ME } from "../../../api.json";
import { getPastAsExpiredTime } from "../../../../utility/validateFunctions";

import WriteToEditor from "../../../organisms/TextEditor/Toolbar/WriteToEditor";
import SVGIcon from "../../../atoms/SVGIcon";
import ReportContext from "../ReportContext";
import EditorContext from "../../TextEditor/EditorContext";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
const useStyles = makeStyles({
    root: {
        display: "block",
        padding: 0,
    },

    accRoot: {
        boxShadow: "none",
    },
    accSummRoot: {
        padding: 0,
        minHeight: "0 !important",
    },
    accSummContent: {
        margin: "0 !important",
    },
    expandedIcon: {
        padding: 0,
    },
});

export const getPara = (text) => {
    let arr = text.split("+++");
    let str = "";
    for (let i in arr) {
        str += arr[i] + "\n\n";
    }

    return str.trim();
};

const AccordionItem = ({
    urlId,
    urlTitle,
    reportTitle,
    index,
    reportId,
    text,
    writingState,
    setWritingState,

    list = [],
    writeWithAnimation,
    expand,
}) => {
    console.log(list, "list Data important");
    const [showSubTopic, setShowSubTopic] = useState(false);
    const [expanding, setExpanding] = useState(false);
    const { postData } = useHelperFunctions();
    const [clickToAdd, setClickToAdd] = useState(false);
    const { isShared } = useContext(ReportContext);
    const { versionData } = useContext(EditorContext);

    useEffect(() => {
        let timer = null;
        if (clickToAdd) {
            timer = setTimeout(() => {
                setClickToAdd(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [clickToAdd]);

    useEffect(() => {
        if (!expand) {
            setShowSubTopic(false);
        }
    }, [expand]);

    const addToEditor = (str) => {
        if (writingState.state) return;
        WriteToEditor({
            data: !document.quill.editor.getText(
                document.quill.editor.cursor,
                1,
            )
                ? str
                : document.quill.editor.getText(
                      document.quill.editor.cursor,
                      1,
                  ) != "\n"
                ? "\n" + str
                : str,
            type: "text",
            length: str.length + 1,
            source: "user",
        });
        setClickToAdd(true);
    };
    const expandHtags = async () => {
        setExpanding(true);
        setWritingState({ state: true, text: "", useCase: -1 });
        document?.quill?.editor?.enable(false);
        // const res = await (async () => {
        //   return new Promise((resolve, reject) => {
        //     setTimeout(resolve, 4000);
        //   });
        // })();

        // let textToSend =
        //   "Complete the below content for creating a blog on " + reportTitle + " ";

        let textToSend = "";
        for (let i = 0; i <= index; i++) {
            textToSend +=
                list[i].paragraph && list[i].paragraph.length > 0
                    ? "\n" + list[i].text + "\n" + list[i].paragraph
                    : "";
        }

        let indexOfParaFromBackend = list[index].index;
        // let heading = list[index].text;
        // let context = getPara(list[index].paragraph);
        const res = await postData({
            url: WRITE_FOR_ME,
            payload: {
                // report_id: reportId,
                // reportTitle: reportTitle,
                // useCase: 63,
                // title: urlTitle,
                // heading: heading,
                // context: context,

                text: "",
                id: 13,
                report_id: reportId,
                isTool: false,
                url_id: urlId,
                index: indexOfParaFromBackend,
                reportTitle: reportTitle,
            },
            optionalToken: true,
        });

        let answer = `\n${res?.data?.text?.trim()}`;
        if (res.status == 200) {
            await writeWithAnimation(answer);
        }
        document?.quill?.editor?.enable(true);

        setWritingState({ state: false, text: "", useCase: -1 });
        setExpanding(false);
    };

    return (
        <div className={styles.subTopicContainer}>
            <div className={styles.subTopic}>
                <div className={styles.simpleRowFlex}>
                    <p
                        style={{ width: "30px", margin: "0px" }}
                        className={styles.h2h3}
                    >
                        {text.tag_name}
                    </p>
                    <LightTooltip
                        title={
                            (isShared && isShared.access) || !isShared
                                ? clickToAdd
                                    ? "Added!"
                                    : "Click to Add"
                                : ""
                        }
                        placement="top"
                    >
                        <p
                            className={styles.compText}
                            onClick={() => addToEditor(text.text)}
                        >
                            {text.text}
                        </p>
                    </LightTooltip>
                </div>
                <div className={styles.simpleRowFlex}>
                    {!isShared &&
                        text.paragraph != null &&
                        (!expanding ? (
                            <div
                                className={
                                    styles.pointerWithHover +
                                    " " +
                                    styles.iconSize
                                }
                            >
                                <LightTooltip
                                    title={"Write about this heading"}
                                    placement="top"
                                    arrow
                                >
                                    <SVGIcon
                                        src="/New UI/SVG/Ai-icon.svg"
                                        size={20}
                                        onClick={() => {
                                            writingState?.state ||
                                            isShared ||
                                            !versionData.current
                                                ? () => {}
                                                : expandHtags();
                                        }}
                                        style={
                                            writingState?.state ||
                                            isShared ||
                                            !versionData.current
                                                ? { cursor: "not-allowed" }
                                                : {}
                                        }
                                    />
                                </LightTooltip>
                            </div>
                        ) : (
                            <CircularProgress size={20} />
                        ))}
                    {text.paragraph != null && (
                        <LightTooltip
                            arrow
                            placement="bottom"
                            title={
                                text.paragraph
                                    ? ""
                                    : "No content found for this heading"
                            }
                        >
                            <div
                                className={
                                    styles.simpleRowFlex +
                                    " " +
                                    styles.pointerWithHover
                                }
                                onClick={() =>
                                    text.paragraph
                                        ? setShowSubTopic((prev) => !prev)
                                        : ""
                                }
                            >
                                {showSubTopic ? (
                                    <MdExpandLess size={20} color="#ababab" />
                                ) : (
                                    <MdExpandMore size={20} color="#ababab" />
                                )}
                            </div>
                        </LightTooltip>
                    )}
                </div>
            </div>
            {showSubTopic && (
                <div
                    className={
                        styles.subTopicDetails +
                        " " +
                        (showSubTopic ? styles.subTopicDetailsEffect : "")
                    }
                >
                    {/* {text.paragraph ? `${text.paragraph}` : "No Text Data Found."} */}
                    {text.paragraph
                        ? getPara(text.paragraph)
                        : "No Text Data Found."}
                </div>
            )}
        </div>
    );
};

function CompetetionAcc({
    reportTitle,
    reportId,
    item,
    writeOutline,
    setArticleLink,
    setData,
    writingState,
    setWritingState,

    writeWithAnimation,
    expand,
    setExpand,
}) {
    const classes = useStyles();
    console.log(item, "itemitemitemitem");
    return (
        <div className={styles.Container} style={{ width: "100%" }}>
            <Accordion
                onChange={(e, expanded) => {
                    setExpand(expanded);
                }}
                classes={{
                    root: classes.accRoot,
                }}
                expanded={expand}
            >
                <LightTooltip title="Click to expand and check all sub headings">
                    <AccordionSummary
                        expandIcon={<></>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{
                            root: classes.accSummRoot,
                            content: classes.accSummContent,
                            expandIcon: classes.expandedIcon,
                        }}
                    ></AccordionSummary>
                </LightTooltip>
                {expand && (
                    <AccordionDetails classes={{ root: classes.root }}>
                        {item.h_tags?.h?.map((text, i) => {
                            // if (
                            //     (text.paragraph == null ||
                            //         (text.paragraph != null &&
                            //             text.paragraph.length != 0)) &&
                            //     text?.text?.length > 0
                            // )
                            return (
                                <AccordionItem
                                    key={text.text + i + text.tag_name}
                                    writeWithAnimation={writeWithAnimation}
                                    text={text}
                                    writingState={writingState}
                                    setWritingState={setWritingState}
                                    reportId={reportId}
                                    reportTitle={reportTitle}
                                    index={i}
                                    list={item?.h_tags?.h || []}
                                    urlId={item.url_id}
                                    expand={expand}
                                />
                            );
                        })}
                        <div className={styles.options}>
                            <LightTooltip
                                placement="bottom"
                                title="View full article"
                                arrow
                            >
                                <span>
                                    <Button
                                        height="34"
                                        width="100"
                                        handler={() => {
                                            setArticleLink(true);
                                            setData(item);
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            width: "100px",
                                        }}
                                        secondary
                                        text="Read Article"
                                    />
                                </span>
                            </LightTooltip>
                            <LightTooltip
                                placement="bottom"
                                title="Outline"
                                arrow
                            >
                                <span>
                                    <Button
                                        height="34"
                                        width="100"
                                        handler={() => {
                                            writeOutline(item);
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            width: "100px",
                                        }}
                                        secondary
                                        text="Paste Outline"
                                    />
                                </span>
                            </LightTooltip>
                        </div>
                    </AccordionDetails>
                )}
            </Accordion>
        </div>
    );
}

export default CompetetionAcc;
