import React, { useEffect } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import SVGIcon from "../../../atoms/SVGIcon";
import TableColumnHeading from "../TableColumnHeading";
import styles from "./styles.module.css";

const HeadRow = ({ columns, setSortBy, sortBy }) => {
    useEffect(() => {
        console.log("Head Rows", columns);
    }, [columns]);
    return (
        <tr className={styles.headRow}>
            <th style={{ width: "20px" }}></th>
            {columns &&
                columns.map((col, i) => {
                    return (
                        <TableColumnHeading
                            sortBy={sortBy}
                            sortingBy={(colID, asc) =>
                                setSortBy({ colID, asc })
                            }
                            i={i}
                            col={col}
                            cruiseMode={true}
                        />
                    );
                })}
        </tr>
    );
};

export default HeadRow;
