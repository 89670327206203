import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./ResearchGenerate.module.css";
import { VscClose } from "react-icons/vsc";
// import TextArea from "../../../atoms/TextArea/TextArea";
import Button from "../../../atoms/Button/Button";
import useHelperFunctions from "../../../helperFunctions";
// import { responseData } from "./response";
// import { CircularProgress } from "@material-ui/core";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
// import CopyText from "../CopyText";
import EditorContext from "../../TextEditor/EditorContext";
// import ReportContext from "../ReportContext";
import Progress from "../../../atoms/Progress/Progress";
import TextHighlighter from "../../TextEditor/TextHighlighter";
import SVGIcon from "../../../atoms/SVGIcon";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import { MdKeyboardArrowDown } from "react-icons/md";
import useOnClickOutside from "../../../atoms/Modal/useOnClickOutside";
import "./AccordionCustomClass.css";
import { FiExternalLink } from "react-icons/fi";
import { debounceWrapper } from "../../../../utility/helperFun";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "2px !important",
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.05)",
        border: "solid 1px #e1e4ed !important",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        marginBottom: "10px",
    },
    expanded: {
        borderRadius: 3,
        boxShadow: "0 4px 4px 0 rgba(1, 77, 197, 0.2) !important",
        border: "solid 1px #014dc5 !important",
        backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    },
    content: {
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        padding: "0",
        margin: "0px 16px",
    },
    expanded: {
        borderBottom: "1px solid #e1e4ed",
    },
}));

export default function PlagiarismCopyscape({
    reportInfo,
    plagiarism,
    setPlagiarism,
    aiWordLimitSetting,
    realtimeRating,
    saving,
}) {
    // const [description, setDescription] = useState("");
    // const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const { postData, getData } = useHelperFunctions();
    // const [selectedResults, setSelectedResults] = useState([]);
    // const [errMsg, setErrMsg] = useState(false);
    // const [editorData, setEditorData] = useState([]);
    const [initialState, setInitialState] = useState(true);
    const [cost, setCost] = useState(0);
    const [balance, setBalance] = useState(false);
    // const [plagiarismPercentage, setPlagiarismPercentage] = useState(
    //   responseData.response.allpercentmatched
    // );
    const [showPercentage, setShowPercentage] = useState(false);
    const [plagiarismPercentage, setPlagiarismPercentage] = useState(0);
    const [plagiarismData, setPlagiarismData] = useState([]);
    // const [plagiarismData, setPlagiarismData] = useState(
    //   responseData.response.result
    // );
    const [selectedTextLength, setSelectedTextLength] = useState(0);
    const [highlightedText, setHighlightedText] = useState("");
    const [textSnippet, setTextSnippet] = useState("");
    const [textChange, setTextChange] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [limitText, setLimitText] = useState("");
    // const { setWritingState, writingState, writeWithAnimation } = useContext(
    //   EditorContext
    // );
    // const selectedText = document?.editorSelection;
    // const classes = useStyles();

    useEffect(() => {
        if (selectedTextLength == 0) {
            let temp = 0.03;
            let n = realtimeRating.wordCount - 200;
            for (let i = n; i > 0; i -= 100) {
                temp += 0.01;
            }
            setCost(temp);
        } else if (selectedTextLength < 200) {
            setCost(0.03);
        } else if (selectedTextLength > 200) {
            let tempCost = 0.03;
            let len = selectedTextLength - 200;
            for (let i = len; i > 0; i -= 100) {
                tempCost += 0.01;
            }
            setCost(tempCost);
        }
    }, [selectedTextLength, realtimeRating.wordCount]);

    useEffect(() => {
        const disable = () => {
            setDisableButton(false);
        };
        // document.quill.editor.on("text-change", disable);
        // console.log("ffffffffffffffffffffffffffff", disableButton);
        // return () => {
        //   document.quill.editor.off("text-change", disable);
        // };
    }, [saving]);

    useEffect(() => {
        getBalance();

        const changeSelectedText = () => {
            let range = document?.editorSelection;
            let d = document?.quill?.editor?.getText(
                range?.index,
                range?.length,
            );

            let selectedLength = range ? d.trim().split(/[\s,?:|]+/).length : 0;
            setSelectedTextLength(selectedLength);
        };
        document.addEventListener("editorSelectionChange", changeSelectedText);

        return () => {
            document.removeEventListener(
                "editorSelectionChange",
                changeSelectedText,
            );
        };
    }, []);

    async function getBalance() {
        const res = await getData({
            url: "/api/integrations/copyscape/balance",
        });
        if (res.status == 200) {
            setBalance(res.data.remaining.value);
        }
    }

    async function scanDocument(e, isFullDocument) {
        e.preventDefault();
        let text = "";
        if (isFullDocument) {
            let data = document?.quill?.editor?.getText();
            text = data;
        } else {
            let data = document?.editorSelection;
            document.quill.editor.setSelection(data);
            let textData = document?.quill?.editor?.getText(
                data?.index,
                data?.length,
            );
            text = textData;
        }
        setInitialState(false);
        setLoading(true);
        const res = await postData({
            method: "post",
            url: "/api/integrations/copyscape/text",
            payload: {
                report_id: reportInfo.report_id,
                text: text,
                isExample: 0,
                cValue: 3,
            },
        });
        setLoading(false);
        if (res.status == 200) {
            setLimitText("");
            setTextChange(false);
            setShowPercentage(true);
            if (isFullDocument) {
                setDisableButton(true);
            }
            setPlagiarismPercentage(res?.data?.response?.allpercentmatched);
            setPlagiarismData(res?.data?.response?.result);
            getBalance();
        } else {
            if (
                res?.response?.data?.message.startsWith(
                    "you have exceeded the limit",
                )
            ) {
                setLimitText(
                    "It seems that " +
                        res?.response?.data?.message.split(". ")[0] +
                        ". For more information, please visit this ",
                );
            }
            setInitialState(true);
            setShowPercentage(false);
            setPlagiarismData([]);
        }
    }

    return (
        <div
            className={` ${styles.researchFacts} ${
                plagiarism ? "" : styles.hide
            }  `}
        >
            <div className={styles.plagiarismHeading}>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "baseline",
                    }}
                >
                    <h2>Plagiarism Checker</h2>
                    <p>Powered by Copyscape</p>
                </div>
                <VscClose
                    onClick={() => setPlagiarism(false)}
                    className={styles.closeIcon}
                />
            </div>
            {/* <div className={styles.sep}></div> */}
            <p className={styles.infoText}>
                Scan your document to ensure that your content is plagiarism
                free and authentic.
            </p>

            <div className={styles.wordCountSection}>
                <div className={styles.totalWords}>
                    <p className={styles.dataText}>
                        {selectedTextLength !== 0
                            ? selectedTextLength
                            : realtimeRating.wordCount}
                    </p>
                    <p
                        className={styles.infoDataText}
                        style={{ marginTop: "0", marginBottom: "1rem" }}
                    >
                        Words
                    </p>
                </div>
                <div className={styles.sep2}></div>
                <div className={styles.selectedWords}>
                    <p className={styles.dataText}>${cost.toFixed(2)}</p>
                    <p
                        className={styles.infoDataText}
                        style={{ marginTop: "0", marginBottom: "1rem" }}
                    >
                        Cost
                    </p>
                </div>
                <div className={styles.sep2}></div>
                <div className={styles.balance}>
                    {balance == null || balance == undefined ? (
                        <p
                            style={{
                                fontWeight: "600",
                                color: "#014dc5",
                                fontSize: "20px",
                            }}
                        >
                            ...
                        </p>
                    ) : (
                        <p className={styles.dataText}>${balance}</p>
                    )}
                    <p
                        className={styles.infoDataText}
                        style={{ marginTop: "0", marginBottom: "1rem" }}
                    >
                        Balance
                    </p>
                </div>
            </div>
            {initialState && (
                <div
                    style={{
                        margin: "30px auto 16px auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ margin: "auto" }}>
                        <SVGIcon
                            size={180}
                            src="/New UI/SVG/keyword_research_bg.svg"
                        />
                    </div>
                    <p className={styles.noDataText}>
                        Scan full document or select text to check plagiarism.
                    </p>
                </div>
            )}
            <div className={styles.scanButtons}>
                <LightTooltip
                    title={
                        disableButton
                            ? "No new changes in editor content identified. Latest plagiarism results available below."
                            : ""
                    }
                    arrow
                    placement="bottom"
                    PopperProps={{ disablePortal: true }}
                >
                    <div>
                        <Button
                            handler={(e) =>
                                disableButton || loading
                                    ? e.preventDefault()
                                    : selectedTextLength === 0
                                    ? scanDocument(e, true)
                                    : scanDocument(e, false)
                            }
                            width="200px"
                            height="44px"
                            disabled={realtimeRating.wordCount == 0}
                            style={{
                                borderRadius: "3px",
                                opacity: loading || disableButton ? 0.5 : 1,
                                cursor:
                                    loading || disableButton
                                        ? "default"
                                        : "pointer",
                            }}
                            text={
                                selectedTextLength === 0
                                    ? "Scan Full Document"
                                    : "Scan Selected Text"
                            }
                        />
                    </div>
                </LightTooltip>
            </div>
            {limitText && (
                <div className={styles.limitError}>
                    {limitText}{" "}
                    <a
                        href="https://help.scalenut.com/how-to-fix-the-issues-that-you-might-be-facing-while-checking-plagiarism/"
                        target="_blank"
                    >
                        help article
                    </a>
                </div>
            )}
            {/* {textChange && <div>Text has been changed</div>} */}
            {plagiarismData?.length > 0 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div className={styles.plagiarised}>
                        <span
                            style={{
                                color: "#fc5a5a",
                                fontWeight: "600",
                                marginRight: "5px",
                            }}
                        >
                            {plagiarismPercentage + "% "}
                        </span>{" "}
                        Plagiarised
                    </div>
                    <p className={styles.totalResult}>
                        {plagiarismData.length} results found
                    </p>
                </div>
            )}
            {showPercentage && plagiarismData.length == 0 && (
                <div className={styles.noPlagiarism}>
                    <SVGIcon src={"/New UI/SVG/congrats.svg"} />
                    <p
                        style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#3f5575",
                            marginBottom: "0",
                        }}
                    >
                        Congrats!
                    </p>
                    <p style={{ fontSize: "14px", color: "#6b6b6b" }}>
                        Your Content is Original
                    </p>
                </div>
            )}
            {loading ? (
                <Progress size={20} />
            ) : plagiarismData.length > 0 ? (
                <div
                    style={{
                        height: "600px",
                        overflow: "auto",
                        marginTop: "10px",
                        borderTop: "1px solid #e2e2e2",
                        paddingTop: "10px",
                    }}
                >
                    <Results
                        setTextSnippet={setTextSnippet}
                        data={plagiarismData}
                    />
                    {/* {plagiarismData &&
            plagiarismData.map((data) => (
              <Results result={data} />
            ))} */}
                </div>
            ) : (
                <></>
            )}
            <TextHighlighter keyTerms={[{ keyword: textSnippet }]} />
        </div>
    );
}

const Results = ({ data, setTextSnippet }) => {
    // const accordionClasses = customAccordion();
    const [showResult, setShowResult] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();
    const classes1 = useStyles1();

    function handleClick(textsnippet) {
        let newTextSnippet = textsnippet.slice(4);
        setTextSnippet(newTextSnippet);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const ref = useRef();
    useOnClickOutside(ref, () => setShowResult(false));
    return (
        <div className={styles.faqItem}>
            {data &&
                data.map((result) => (
                    <Accordion
                        classes={{
                            root: classes.root,
                            expanded: classes.expanded,
                        }}
                        expanded={expanded === result.index}
                        onChange={handleChange(result.index)}
                        onClick={() =>
                            expanded ? handleClick(result.textsnippet) : ""
                        }
                    >
                        <AccordionSummary
                            expandIcon={<MdKeyboardArrowDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                borderBottom: "1px solid red",
                                borderRadius: 3,
                            }}
                            classes={{
                                root: classes1.root,
                                content: classes.content,
                            }}
                        >
                            <div class="resultHeading">{result.title}</div>
                            <a target="_blank" href={result.url} class="url">
                                {result.url}
                            </a>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div class="details">
                                {result.textsnippet.slice(0, 200)}
                                {"... "}
                                <a target="_blank" href={result.viewurl}>
                                    view highlighted link
                                </a>
                                <FiExternalLink />{" "}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
        </div>
    );
};
