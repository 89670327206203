import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.css";
import Card from "./library/card";
import axios from "axios";
import { url } from "../../utility/config";
import InfiniteScroll from "react-infinite-scroll-component";
import ZeroCard from "../../lib/dash/ZeroCard";
import NotyCardSkeleton from "../../componentsV2/atoms/NotyCardSkeleton/NotyCardSkeleton";
import { updateUserInfo } from "../../store/actions/auth";

const skeleton = [1, 1, 1, 1, 1, 1, 111, 11, 111, 1, 1, 1, 11, 1, 1, 1, 1, 1];
// import ProjectDetailsModal from "./ProjectDetailsModal/ProjectDetailsModal";
// import ProjectDetailsModal from'./../'zzx.m,/
function Notifications() {
  const token = useSelector((state) => state.auth.token);
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [moreOption, setMoreOption] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    getNotifications();
    resetNotifications();
  }, []);
  function resetNotifications() {
    let URL = `${url}/api/user/notification/resetNotificationCount`;
    axios({
      method: "post",
      url: URL,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        dispatch(updateUserInfo("notification_count", 0));
      })
      .catch((err) => {});
  }
  function getNotifications() {
    if (page == 1) setLoader(true);
    let URL = `${url}/api/user/notification/userNotification?page=${page}`;
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        // let moked = [{
        //   "id": 33,
        //   "user_id": 631,
        //   "project_title": "test-SEO and BLOG and Design",
        //   "project_status": "QUEUED",
        //   "message": "updated status of",
        //   "user_plan": null,
        //   "notif_string": "updated status of <strong>\"test-SEO and BLOG and Design\"</strong> to QUEUED",
        //   "is_read": 0,
        //   "name": "binay",
        //   "role": "user",
        //   "created_at": "2020-12-12T04:44:50.000Z"
        // },
        // {
        //   "id": 32,
        //   "user_id": 631,
        //   "project_title": "test-SEO and BLOG and Design",
        //   "project_status": "QUEUED",
        //   "message": "updated status of",
        //   "user_plan": null,
        //   "notif_string": "updated status of <strong>\"test-SEO and BLOG and Design\"</strong> to QUEUED",
        //   "is_read": 0,
        //   "name": "binay",
        //   "role": "user",
        //   "created_at": "2020-12-12T03:10:43.000Z"
        // }]
        res.data.data.notifications && res.data.data.notifications.length > 0
          ? setNotifications(notifications.concat(res.data.data.notifications))
          : setHasMore(false);
        setLoader(false);
        let _page = page;
        setPage(++_page);
      })
      .catch((err) => {});
  }
  function updateNotification(id, action) {
    let URL = `${url}/api/user/notification/updateNotification`;
    axios({
      method: "post",
      url: URL,
      headers: {
        Authorization: token,
      },
      data: {
        notificationId: id,
        status: action,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  }

  return (
    <div onClick={() => setMoreOption(false)} className={styles.notifications}>
      <InfiniteScroll
        dataLength={notifications.length}
        next={getNotifications}
        hasMore={hasMore}
        loader={
          <>
            <NotyCardSkeleton
              className={styles.skeleton}
              variant={"rect"}
              animation="wave"
              height={80}
              width={"100%"}
            />
            <NotyCardSkeleton
              className={styles.skeleton}
              variant={"rect"}
              animation="wave"
              height={80}
              width={"100%"}
            />
          </>
        }
        scrollableTarget="scrollableDiv"
      >
        <div className={styles.titleBig}>Notifications</div>
        {loader ? (
          skeleton.map(() => (
            <NotyCardSkeleton
              className={styles.skeleton}
              variant={"rect"}
              animation="wave"
              height={80}
              width={"100%"}
            />
          ))
        ) : (
          <div className={styles.cardsList}>
            {notifications && notifications.length > 0 ? (
              notifications.map((item) => (
                <Card
                  key={item.id}
                  setMoreOption={setMoreOption}
                  moreOption={moreOption}
                  type={item.type ? item.type : 0}
                  created={item.created_at}
                  projectId={item.project_id}
                  name={item.noti_name ? item.noti_name : ""}
                  id={item.id}
                  updateNotification={updateNotification}
                  isRead={item.status}
                  message={item.message}
                  notify_message={item.notif_string}
                  heading={item.notif_string}
                  icon={item.icon}
                  variant_name={item.variant_name}
                ></Card>
              ))
            ) : (
              <ZeroCard text={"Notifications"} />
            )}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default Notifications;
