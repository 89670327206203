import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./preview.module.css";
import ParaSnippet from "./ParaSnippet";
import SVGIcon from "../../../../atoms/SVGIcon";
import { Export2Word, saveAsPDF } from "../../../TextEditor/Options/utils";
import Modal from "../../../../atoms/Modal/Modal";
import { ClickAwayListener, LinearProgress, SvgIcon } from "@material-ui/core";
import useMixpanelHook from "../../../../../utility/useMixpanelHook";

import Progress from "../../../../atoms/Progress/Progress";
import ReportContext from "../../../Research/ReportContext";
import { CustomDeltaToHtml } from "../../../../../utility/helperFun";
import { useSelector } from "react-redux";

const Preview = ({
    draftContent,
    writeToEditor,
    makeCruiseToDelta,
    setIsPreview,
    setGoNutsModal,
    mpData,
}) => {
    // const [isCollasped, setIsCollasped] = useState(true);
    const [isDrop, setIsDrop] = useState(false);
    const [htmlData, setHtmlData] = useState("");
    const [loading, setLoading] = useState(false);
    const { reportInfo } = useContext(ReportContext);
    const otherInfo = useSelector((state) => state.otherInfo);
    const auth = useSelector((state) => state.auth);

    const { mpTrackCruiseDownload, mpTrackCruisePreview } = useMixpanelHook();

    useEffect(() => {
        setLoading(true);
        setHtmlData(
            CustomDeltaToHtml(
                makeCruiseToDelta(
                    draftContent,
                    auth.segment == 3 ||
                        auth.segment == 4 ||
                        auth.segment == 5 ||
                        auth.segment == 6
                        ? true
                        : reportInfo?.is_premium,
                ),
            ),
        );
        setTimeout(() => setLoading(false), 2000);
    }, [draftContent]);

    useEffect(() => {
        mpTrackCruisePreview(mpData);
    }, []);

    function handleDownload(type) {
        mpTrackCruiseDownload(type);
        switch (type) {
            case "PDF":
            case "HTML":
                saveAsPDF(
                    type,
                    "firstDraft",
                    document.getElementById("preview-container"),
                );
                break;
            case "doc":
                Export2Word(
                    "",
                    "scalenutDraft",
                    document.getElementById("preview-container"),
                );
                break;
            default:
                return;
        }
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftContainer}>
                <div className={styles.container + styles.activeStep}>
                    <div
                        className={
                            styles.stepCard__head + " ql-container ql-snow"
                        }
                    >
                        <div className={styles.previewHeader}>
                            <h3 className={styles.stepTitle}>Preview</h3>{" "}
                            <div className={styles.btnContainer}>
                                <div
                                    onMouseEnter={() => setIsDrop(true)}
                                    onMouseLeave={() => setIsDrop(false)}
                                >
                                    <button
                                        className={
                                            styles.previewBtn +
                                            " " +
                                            (isDrop && styles.previewBtnActive)
                                        }
                                    >
                                        Download Draft{"  "}
                                        {isDrop ? (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/RiArrowDownSFill.svg"
                                                }
                                                style={{ color: "#c9e3ff" }}
                                                size={20}
                                            />
                                        ) : (
                                            <SVGIcon
                                                src={
                                                    "/New UI/SVG/RiArrowRightSFill.svg"
                                                }
                                                style={{ color: "#0079e4" }}
                                                size={20}
                                            />
                                        )}{" "}
                                    </button>
                                    {isDrop && (
                                        <ClickAwayListener
                                            onClickAway={() => setIsDrop(false)}
                                        >
                                            <div
                                                className={styles.downloadDrop}
                                                onClick={() => setIsDrop(false)}
                                            >
                                                <div
                                                    className={styles.optionRow}
                                                    onClick={() =>
                                                        handleDownload("PDF")
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.iconContainer
                                                        }
                                                    >
                                                        <SVGIcon src="/New UI/SVG/PDF-icon.svg" />
                                                    </div>
                                                    <div>PDF</div>
                                                </div>
                                                <div
                                                    className={styles.optionRow}
                                                    onClick={() =>
                                                        handleDownload("HTML")
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.iconContainer
                                                        }
                                                    >
                                                        <SVGIcon src="/New UI/SVG/HTML-icon.svg" />
                                                    </div>{" "}
                                                    <div>HTML</div>
                                                </div>
                                                <div
                                                    className={styles.optionRow}
                                                    onClick={() =>
                                                        handleDownload("doc")
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.iconContainer
                                                        }
                                                    >
                                                        <SVGIcon src="/New UI/SVG/Doc-icon.svg" />
                                                    </div>{" "}
                                                    <div>Word Doc</div>
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    )}
                                </div>
                                {/* <button
                                    className={styles.previewBtn}
                                    onClick={() => {
                                        mpTrackCruiseExport();
                                        writeToEditor();
                                        setIsPreview(false);
                                        setGoNutsModal(false);
                                    }}
                                >
                                    Export to Editor{" "}
                                </button> */}
                            </div>
                        </div>
                        {loading ? (
                            <LinearProgress width={"100%"} />
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: htmlData,
                                }}
                                className={"ql-editor"}
                                id="preview-container"
                            ></div>
                        )}
                    </div>
                </div>
                {/* // ); */}
                {/* })} */}
            </div>
        </div>
    );
};

export default Preview;
