import React from "react";
import SVGIcon from "../../../../../atoms/SVGIcon";
import styles from "../styles.module.css";

function ExpandAll({ minimize, setMinimize }) {
    return (
        <div
            style={{ borderRadius: 3 }}
            onClick={() => setMinimize(!minimize)}
            className={styles.expandAll}
        >
            {minimize ? (
                <>
                    Expand All{" "}
                    <SVGIcon
                        tooltip={"Expand"}
                        src={"/New UI/SVG/expand.svg"}
                        size={20}
                    />
                </>
            ) : (
                <>
                    Collapse All{" "}
                    <SVGIcon
                        tooltip={"Minimize"}
                        src={"/New UI/SVG/minimize.svg"}
                        size={20}
                    />
                </>
            )}
        </div>
    );
}

export default ExpandAll;
