import React, { useState, useContext } from "react";
import { useEffect } from "react";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import styles from "./styles.module.css";
import styles3 from "./EditorAssistant/FixItModule.module.css";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import EditorContext from "./EditorContext";
import ReportContext from "../Research/ReportContext";
import {
    ClickAwayListener,
    makeStyles,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import Button from "../../atoms/Button/Button";
import { VscClose } from "react-icons/vsc";
import useHelperFunctions from "../../helperFunctions";
import LoadingDots from "../../atoms/LoadingDots/LoadingDots";
import styles2 from "../../organisms/Creator/GoNuts/OutlineBuilder.module.css";
import { RiArrowDownSFill, RiArrowRightSFill } from "react-icons/ri";
import slugify from "slugify";
import { useRef } from "react";
import { FlashOffOutlined } from "@material-ui/icons";
import { EditorBriefContext } from "../Creator/EditorBrief/EditorBriefContext";
import { Skeleton } from "@material-ui/lab";
import useMixpanelHook from "../../../utility/useMixpanelHook";

let timeout = null;

const slugifyOption = {
    replacement: "-",
    remove: /[*,%&#^;$<>+~.()'"!:@]/g,
};

const LightTooltip1 = withStyles((theme) => ({
    arrow: {
        color: "white",
        scale: 1.5,
    },
    tooltip: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: " 0 4px 20px 0 rgba(0, 0, 0, 0.2)",
        fontSize: 11,
        maxWidth: "400px",
        position: "relative",
        top: "5px",
        border: "solid 1px rgba(172, 179, 191, 0.28)",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        "& input": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            "&:hover": {
                textOverflow: "unset !important",
                border: "1px solid #efefef !important",
                borderRadius: 3,
                border: "none !important",
            },
            "&:focus": {
                border: "1px solid #efefef !important",
                borderRadius: 3,
                border: "none !important",
            },
            "&:focus-visible": {
                outline: "none",
            },
        },
    },
}));

function MetaData({
    metaData,
    setMetaData,
    metaFixIt = {},
    setMetaFixIt = () => {},
    loading = false,
    title,
    isCruise = false,
}) {
    const [localMetaData, setLocalMetaData] = useState(null);
    const { isShared, reportInfo } = useContext(ReportContext);
    const [newMetaData, setNewMetaData] = useState();

    useEffect(() => {
        if (localMetaData) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (localMetaData) {
                    setMetaData(localMetaData);
                }
            }, 500);
        }
    }, [localMetaData]);

    useEffect(() => {
        setLocalMetaData(metaData);
    }, [metaData]);
    return (
        <div className={styles.metadataMainContainer}>
            <div className={styles.metaContainer}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "4px",
                    }}
                >
                    <LightTooltip
                        arrow
                        placement="top"
                        title={
                            "Meta title is an HTML element used to specify the title of a webpage. It appears as the clickable headline for the search result."
                        }
                    >
                        <p className={styles.metaTitle}>Meta Title</p>
                    </LightTooltip>
                    {loading && !localMetaData?.title ? (
                        <Skeleton
                            variant="rect"
                            style={{
                                height: 12,
                                marginBlock: 10,
                                width: "100%",
                            }}
                        />
                    ) : (
                        <InputField
                            placeholder={
                                "Click to write meta title or generate with AI"
                            }
                            value={localMetaData?.title}
                            newValue={metaFixIt?.newMetaTitle || ""}
                            isTitle={true}
                            metaData={metaData}
                            setLocalMetaData={setLocalMetaData}
                            localMetaData={localMetaData}
                            isShared={isShared}
                            reportInfo={reportInfo}
                            isMetaFixItOn={metaFixIt?.isOn}
                            activeFixItMeta={metaFixIt?.section == "title"}
                            title={title}
                            isCruise={isCruise}
                        />
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "4px",
                    }}
                >
                    <LightTooltip
                        arrow
                        placement="bottom"
                        title="Meta description tag is a summary of a page's content that users can see in the search results . It's one of the first things users are likely to see when they are searching for something."
                    >
                        <p className={styles.metaTitle}>Meta Description</p>
                    </LightTooltip>

                    {loading && !localMetaData?.description ? (
                        <Skeleton
                            variant="rect"
                            style={{
                                height: 12,
                                marginBlock: 10,
                                width: "100%",
                            }}
                        />
                    ) : (
                        <InputField
                            placeholder={
                                "Click to write meta description or generate with AI"
                            }
                            value={localMetaData?.description}
                            newValue={metaFixIt?.newMetaDesc || ""}
                            isDesc={true}
                            metaData={metaData}
                            setLocalMetaData={setLocalMetaData}
                            isShared={isShared}
                            reportInfo={reportInfo}
                            localMetaData={localMetaData}
                            isMetaFixItOn={metaFixIt?.isOn}
                            activeFixItMeta={metaFixIt?.section == "desc"}
                            title={title}
                            isCruise={isCruise}
                        />
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <LightTooltip
                        arrow
                        placement="top"
                        title={
                            "Enter the last part of your page URL to optimize it. ( The part in your URL after the last /)"
                        }
                    >
                        <p className={styles.metaTitle}>URL Slug</p>
                    </LightTooltip>

                    {loading && !localMetaData?.url ? (
                        <Skeleton
                            variant="rect"
                            style={{
                                height: 12,
                                marginBlock: 10,
                                width: "100%",
                            }}
                        />
                    ) : (
                        <InputField
                            placeholder={"Click to enter last part of your URL"}
                            value={localMetaData?.url}
                            newValue={metaFixIt?.newMetaUrl}
                            isUrl={true}
                            metaData={metaData}
                            setLocalMetaData={setLocalMetaData}
                            isShared={isShared}
                            reportInfo={reportInfo}
                            localMetaData={localMetaData}
                            isMetaFixItOn={metaFixIt?.isOn}
                            activeFixItMeta={metaFixIt?.section == "url"}
                            title={title}
                            isCruise={isCruise}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const InputField = ({
    placeholder = "",
    value = "",
    newValue = "",
    isUrl = false,
    isDesc = false,
    isTitle = false,
    metaData,
    localMetaData,
    setLocalMetaData,
    reportInfo,
    isShared,
    isMetaFixItOn = false,
    activeFixItMeta = false,
    title = "",
    isCruise,
}) => {
    const classes = useStyles();
    const [isGenCollapse, setIsGenCollapse] = useState(true);
    const [loadingTitle, setLoadingTitle] = useState(false);
    const [suggestedTitles, setSuggestedTitles] = useState([]);
    const [isFocus, setIsFocus] = useState({
        state: false,
        value: 0,
    });
    const ref = useRef();
    const { postData } = useHelperFunctions();
    const [prevItems, setPrevItems] = useState(0);
    const [optimizerTitle, setOptimizerTitle] = useState("");
    const { mixpanelTrack } = useMixpanelHook();

    useEffect(() => {
        if (!loadingTitle) {
            setTimeout(() => {
                ref.current?.scrollIntoView(true);
            }, 100);
        } else {
            setPrevItems(suggestedTitles.length);
        }
    }, [loadingTitle]);

    useEffect(() => {
        if (isGenCollapse) {
            setPrevItems(suggestedTitles.length);
        }
    }, [isGenCollapse]);

    useEffect(() => {
        let titleText = document?.quill?.editor?.root?.querySelectorAll("h1");
        for (let i = 0; i < titleText?.length; i++) {
            if (titleText[i]?.innerText.trim().length > 0) {
                setOptimizerTitle(titleText[i]?.innerText);
                break;
            } else {
                setOptimizerTitle("");
            }
        }
    }, [document.quill?.editor?.root?.querySelectorAll("h1")]);

    const showSuggestedPermalinks = async () => {
        setLoadingTitle(true);
        const res = await postData({
            url: "/api/ai-generate/regenerate/urlPermalink",
            payload: {
                keyword: reportInfo?.title,
                h1: title ? title : optimizerTitle,
                metaTitle: metaData?.title || "",
                report_id: reportInfo?.report_id,
            },
        });
        setLoadingTitle(false);
        if (res.status == 200) {
            setIsGenCollapse(false);
            let urls = [];
            res?.data?.map((data) => {
                urls.push(data.content);
            });
            setSuggestedTitles([...suggestedTitles, ...urls]);
        }
    };

    const createMeta = async (isTitle) => {
        let payloadKeyword = "";
        if (JSON.parse(reportInfo.secondary_keywords)?.length > 0) {
            payloadKeyword =
                reportInfo.title +
                ", " +
                JSON.parse(reportInfo.secondary_keywords).join(", ");
        } else {
            payloadKeyword = reportInfo.title;
        }
        const payloadObj = isTitle
            ? {
                  report_id: reportInfo.report_id,
                  title: title || optimizerTitle || reportInfo.title,
                  keyword: reportInfo.title,
                  useCase: 170,
              }
            : {
                  report_id: reportInfo.report_id,
                  title: title || optimizerTitle || reportInfo.title,
                  keyword: payloadKeyword,
                  useCase: 122,
              };
        setLoadingTitle(true);
        const res = await postData({
            url: "/api/generate",
            payload: payloadObj,
        });
        setLoadingTitle(false);
        if (res.status == 200) {
            setIsGenCollapse(false);
            setSuggestedTitles([...suggestedTitles, ...res.data?.output]);
        }
    };

    function createSlug() {
        let slug = "";
        try {
            let str = new URL(localMetaData?.url);
            slug = slugify(str?.pathname, slugifyOption).toLowerCase();
            return slug;
        } catch (err) {
            slug = slugify(
                localMetaData?.url ? localMetaData?.url : "",
                slugifyOption,
            ).toLowerCase();
            return slug;
        }
    }
    return (
        <div
            className={`${
                !isMetaFixItOn
                    ? styles.inputFieldContainer
                    : styles.metaFixItContainer
            } ${
                !isGenCollapse || loadingTitle || isFocus.state
                    ? styles.focusContainer
                    : ""
            }`}
        >
            {" "}
            {!isMetaFixItOn ? (
                <>
                    <Textfield
                        placeholder={placeholder}
                        height={32}
                        width={"calc(100% - 190px)"}
                        containerStyle={{ marginBottom: "0px" }}
                        label=""
                        props={{
                            value: value,
                            onChange: (e) => {
                                setIsFocus({
                                    state: true,
                                    value: e.target?.value?.length,
                                });
                                setLocalMetaData({
                                    ...localMetaData,
                                    ...(isUrl && { url: e.target.value }),
                                    ...(isDesc && {
                                        description: e.target.value,
                                    }),
                                    ...(isTitle && { title: e.target.value }),
                                    fromAPI: false,
                                });
                            },
                            onFocus: (e) => {
                                setIsFocus({
                                    state: true,
                                    value: e.target?.value?.length,
                                });
                            },
                            onBlur: (e) => {
                                setIsFocus({ state: false, value: 0 });
                                if (localMetaData) {
                                    setLocalMetaData({
                                        ...localMetaData,
                                        ...{
                                            url: createSlug(),
                                        },
                                        fromAPI: false,
                                    });
                                }
                            },
                            style: {
                                background: "#FFF",
                                color: "#2C3948",
                                fontWeight: "400",
                                lineHeight: "1.4",
                                border: "none",
                                boxShadow: "none",
                                fontSize: "12px",
                                marginLeft: "2px",
                            },
                            disabled: !(!isShared || isShared.access),
                        }}
                        customClass={classes.root}
                        disabled={isMetaFixItOn}
                    />
                    {isFocus?.state ? (
                        <div className={styles.currentCharCounter}>
                            {isFocus?.value ? isFocus?.value : 0} chars
                        </div>
                    ) : (
                        <></>
                    )}

                    <LightTooltip1
                        placement="bottom-end"
                        style={{ boxShadow: "0 0 4px 0 lightgrey" }}
                        interactive
                        arrow
                        open={true}
                        title={
                            !isGenCollapse && suggestedTitles?.length > 0 ? (
                                <ClickAwayListener
                                    onClickAway={() => setIsGenCollapse(true)}
                                >
                                    <div className={styles2.genResultContainer}>
                                        <h3>
                                            {isUrl
                                                ? "Suggested URL Slugs"
                                                : "AI Generated Ideas"}
                                        </h3>
                                        <div>
                                            {
                                                <div
                                                    className={
                                                        styles2.genH3ListContainer +
                                                        " " +
                                                        (isGenCollapse &&
                                                            styles2.H2dropEmpty)
                                                    }
                                                >
                                                    <VscClose
                                                        className={
                                                            styles2.closeGen
                                                        }
                                                        onClick={() =>
                                                            setIsGenCollapse(
                                                                true,
                                                            )
                                                        }
                                                    />
                                                    {suggestedTitles.map(
                                                        (item, i) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        styles2.addH3Row +
                                                                        " " +
                                                                        (prevItems -
                                                                            1 <
                                                                        i
                                                                            ? styles.highlightData
                                                                            : "")
                                                                    }
                                                                    onClick={() => {
                                                                        setLocalMetaData(
                                                                            {
                                                                                ...metaData,
                                                                                ...(isTitle && {
                                                                                    title: item.content,
                                                                                }),
                                                                                ...(isDesc && {
                                                                                    description:
                                                                                        item.content,
                                                                                }),
                                                                                ...(isUrl && {
                                                                                    url: item,
                                                                                }),
                                                                                fromAPI: false,
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles2.addH3__left +
                                                                            " " +
                                                                            styles.aigenerated
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                styles.addH3Text
                                                                            }
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                // width: "100%",
                                                                            }}
                                                                        >
                                                                            {isUrl
                                                                                ? item
                                                                                : item.content}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                    <span ref={ref}></span>
                                                </div>
                                            }
                                        </div>

                                        <button
                                            className={styles2.addH3Btn}
                                            onClick={() => {
                                                mixpanelTrack(
                                                    "Generate more clicked",
                                                    {
                                                        "Screen Name": isCruise
                                                            ? "First Draft"
                                                            : "Editor",
                                                        Section: isTitle
                                                            ? "Meta Tile"
                                                            : isUrl
                                                            ? "URL Permalink"
                                                            : "Meta Description",
                                                    },
                                                );
                                                isTitle
                                                    ? createMeta(true)
                                                    : isUrl
                                                    ? showSuggestedPermalinks()
                                                    : createMeta(false);
                                            }}
                                            style={{
                                                fontSize: "11px",
                                                height: "22px",
                                                borderRadius: 3,
                                                backgroundColor:
                                                    "rgba(201,227,255,0.6)",
                                                color: "rgb(1,77,197)",
                                                fontWeight: "400",
                                                border: "none",
                                            }}
                                        >
                                            {loadingTitle ? (
                                                <>
                                                    Generating <LoadingDots />
                                                </>
                                            ) : (
                                                "Generate more..."
                                            )}
                                        </button>
                                    </div>
                                </ClickAwayListener>
                            ) : (
                                ""
                            )
                        }
                    >
                        <button
                            className={
                                styles.aiSuggestionBtn +
                                " " +
                                ((loadingTitle || isFocus.state) &&
                                    styles.aiSuggestionBtn_Generating)
                            }
                            onClick={() => {
                                mixpanelTrack("Suggestions Clicked", {
                                    "Screen Name": isCruise
                                        ? "First Draft"
                                        : "Editor",
                                    Section: isTitle
                                        ? "Meta Title"
                                        : isUrl
                                        ? "URL Permalink"
                                        : "Meta Description",
                                });
                                if (isUrl) {
                                    suggestedTitles.length > 0
                                        ? setIsGenCollapse(false)
                                        : showSuggestedPermalinks();
                                    return;
                                }
                                isTitle
                                    ? suggestedTitles.length > 0
                                        ? setIsGenCollapse(false)
                                        : createMeta(true)
                                    : suggestedTitles.length > 0
                                    ? setIsGenCollapse(false)
                                    : createMeta(false);
                            }}
                            style={{
                                fontSize: "11px",
                                height: "22px",
                                width: "110px",
                                borderRadius: 3,
                                marginRight: "4px",
                                backgroundColor: "rgba(201,227,255,0.6)",
                                color: "rgb(1,77,197)",
                                fontWeight: "400",
                                border: "none",
                                textAlign: "center",
                                marginLeft: "10px",
                                justifyContent: "center",
                                cursor: "pointer",
                                display: !isGenCollapse ? "flex" : "",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {loadingTitle ? (
                                <>
                                    Generating <LoadingDots />{" "}
                                </>
                            ) : suggestedTitles.length > 0 ? (
                                <>
                                    {!isGenCollapse ? (
                                        <>
                                            <RiArrowDownSFill
                                                style={{
                                                    color: "inherit",
                                                }}
                                                size={16}
                                            />{" "}
                                            AI suggestions
                                        </>
                                    ) : (
                                        <>
                                            <RiArrowRightSFill
                                                style={{
                                                    color: "inherit",
                                                }}
                                                size={16}
                                            />
                                            AI suggestions
                                        </>
                                    )}
                                </>
                            ) : (
                                <>+ AI suggestions</>
                            )}
                        </button>
                    </LightTooltip1>
                </>
            ) : (
                <div
                    className={
                        styles.metaTempInput +
                        " " +
                        (activeFixItMeta && styles.fixItMeta)
                    }
                    style={{
                        width: "100%",
                        height: "100%",
                        color: "#2C3948",
                        fontWeight: "400",
                        lineHeight: "1.4",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "1px !important",
                        minHeight: "32px",
                    }}
                >
                    {activeFixItMeta && newValue ? (
                        <div>
                            <div
                                style={{
                                    color: "red",
                                    textDecoration: "line-through",
                                    paddingRight: "4px",
                                }}
                            >
                                {value}
                            </div>
                            <div style={{ color: "#014dc5" }}> {newValue}</div>
                        </div>
                    ) : (
                        value
                    )}
                    {/* {!activeFixItMeta && value} */}
                </div>
            )}
        </div>
    );
};

export default MetaData;
