import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Textfield from "../../atoms/CustomTextfield/Textfield";
import { BiSearch } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import VariantsCatTabs from "../../molecules/VariantsCatTabs/VariantsCatTabs";
import { useSelector } from "react-redux";
import GenerateVariants from "../../organisms/Generate/GenerateVariants/GenerateVariants";
import VideoModal from "../NewDashboard/VideoModal";
import { VscClose } from "react-icons/vsc";
import { ClickAwayListener } from "@material-ui/core";
import { updateUserVideo } from "../../../store/actions/video";

const sortByOptions = [
    { title: "created at", id: 1 },
    { title: "updated at", id: 2 },
];

function AiTemplates({ bin, newDoc, setTabWiseStickyHeader }) {
    const [filterTerm, setFilterTerm] = useState("");
    const [filterId, setFilterId] = useState("all");
    const mainVariants = useSelector((state) => state.variants.generateData);
    const [variants, setVariants] = useState({});

    useEffect(() => {
        if (mainVariants.all) {
            setVariants((as) => {
                let obj = { ...mainVariants };
                delete obj.SEO;
                obj.all = obj.all.filter((item) => item.cat_name != "SEO");
                return obj;
            });
        }
    }, [mainVariants]);
    useEffect(() => {
        setTabWiseStickyHeader(
            <div className={styles.categories}>
                <Textfield
                    props={{
                        onInputCapture: (e) => {
                            setFilterTerm(e.target.value);
                        },
                        style: {
                            paddingLeft: "35px",
                            borderRadius: "3px",
                            border: "1px solid rgba(75, 75, 85, 0.16)",
                        },
                    }}
                    width={"30%"}
                    height={"40px"}
                    Icon={() => <BiSearch size={20} />}
                    placeholder={
                        "Search for a template, for example: Product Description"
                    }
                    label={false}
                    maxLength={100}
                    noItalics
                    // border="border: solid 0px #e9e9e9"
                    iconStyle={{
                        right: "unset",
                        left: "10px",
                        top: "54%",
                    }}
                    style={{
                        paddingLeft: "40px",
                    }}
                    containerStyle={{
                        marginRight: 20,
                        marginBottom: 0,
                    }}
                />
                <VariantsCatTabs
                    filterId={filterId}
                    setFilterId={setFilterId}
                    variants={variants}
                    redirectTo="GENERATE"
                    show={0}
                />
            </div>,
        );
    }, [filterId, variants]);

    return (
        <div className={styles.templatesContainer}>
            <div className={styles.templateSection}>
                <div className={styles.templateCards}>
                    {variants[filterId] && (
                        <GenerateVariants
                            // setSeoModal={setSeoModal}
                            setFilterId={setFilterId}
                            filterId={filterId}
                            generateVariants={variants[filterId].filter(
                                (v) =>
                                    v.name
                                        .trim()
                                        .toLowerCase()
                                        .includes(
                                            filterTerm.trim().toLowerCase(),
                                        ) ||
                                    v.cat_name
                                        .trim()
                                        .toLowerCase()
                                        .includes(
                                            filterTerm.trim().toLowerCase(),
                                        ),
                            )}
                            filterTerm={filterTerm}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default AiTemplates;
