import React, { useState, useEffect } from "react";
import styles from "./ModalComponent.module.css";
import { AiOutlineClose } from "react-icons/ai";
import DataComponent from "./DataComponent";
import Axios from "axios";
import { Skeleton } from "@material-ui/lab";
import useHelperFunctions from "../../../helperFunctions";
import Spacer from "../../../atoms/Spacer/Spacer";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";

const tabs = ["Headings", "Main Content"];

function ModalComponent({
    keyWord,
    keyWordVariation,
    report_info,
    onEditorAssistant,
}) {
    const [selectedTab, setSelectedTab] = useState(tabs[1]);
    const [data, setData] = useState({
        keywordHeadingUsage: [],
        keywordTextUsage: [],
    });
    const { keywordHeadingUsage: headingData, keywordTextUsage: textData } =
        data;
    const { postData } = useHelperFunctions();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getDataAcctoKeyWord = async () => {
            setLoading(true);
            const res = await postData({
                url: "/api/scrape/getKeywordUsage",
                payload: {
                    report_id: report_info.report_id,
                    keyword: keyWord,
                },
            });

            if (res?.status == 200) {
                setData(res.data.data);
            }

            setLoading(false);
        };
        if (keyWord.length > 0) {
            getDataAcctoKeyWord();
        }
    }, [keyWord]);

    useEffect(() => {
        setSelectedTab(textData.length > 0 ? tabs[1] : tabs[0]);
    }, [data]);

    const getCorrectTab = () => {
        if (selectedTab === "Main Content") {
            return (
                <DataComponent
                    data={textData}
                    keyWord={keyWord}
                    keyWordVariation={keyWordVariation}
                    heading={false}
                />
            );
        } else {
            return (
                <DataComponent
                    data={headingData}
                    keyWord={keyWord}
                    keyWordVariation={keyWordVariation}
                    heading={true}
                />
            );
        }
    };
    return (
        <div
            className={`${styles.modalContainer}  ${
                onEditorAssistant ? styles.smallModalContainer : ""
            }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={`${styles.main} ${styles.open}`}
            >
                {loading ? (
                    <div
                        style={{
                            border: "solid 1px #ebecf0",
                            padding: 20,
                        }}
                    >
                        <Skeleton variant="rect" height={15} width={100} />
                        <Skeleton
                            variant="rect"
                            height={15}
                            width={100}
                            style={{ marginTop: 40 }}
                        />
                        <Spacer spacing="20px" />
                        <Skeleton variant="rect" height={30} />
                        <Spacer spacing="10px" />
                        <Skeleton variant="rect" height={30} />
                        <Spacer spacing="10px" />
                        <Skeleton variant="rect" height={30} />
                        <Skeleton
                            variant="rect"
                            height={15}
                            width={100}
                            style={{ marginTop: 30 }}
                        />
                        <Spacer spacing="20px" />
                        <Skeleton variant="rect" height={30} />
                        <Spacer spacing="10px" />
                        <Skeleton variant="rect" height={30} />
                        <Spacer spacing="10px" />
                        <Skeleton variant="rect" height={30} />
                        <Skeleton
                            variant="rect"
                            height={15}
                            width={100}
                            style={{ marginTop: 30 }}
                        />
                        <Spacer spacing="20px" />
                        <Skeleton variant="rect" height={30} />
                        <Spacer spacing="10px" />
                        <Skeleton variant="rect" height={30} />
                        <Spacer spacing="10px" />
                        <Skeleton variant="rect" height={30} />
                    </div>
                ) : (
                    <>
                        {onEditorAssistant ? (
                            <div className={styles.header}></div>
                        ) : (
                            <>
                                <div style={{ padding: "12px 20px 0 20px" }}>
                                    Usage Examples of
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: "var(--primary-blue)",
                                        }}
                                    >
                                        {" "}
                                        {keyWord}
                                    </span>
                                </div>
                                <div className={styles.header}>
                                    <div>
                                        <div className={styles.tabs}>
                                            {tabs.map((tab, i) => {
                                                let disable = false;

                                                if (i == 0)
                                                    disable =
                                                        headingData.length == 0
                                                            ? true
                                                            : false;
                                                else
                                                    disable =
                                                        textData.length == 0
                                                            ? true
                                                            : false;
                                                console.log(
                                                    textData,
                                                    headingData,
                                                    disable,
                                                    "?????????",
                                                );
                                                return (
                                                    <LightTooltip
                                                        arrow
                                                        title={
                                                            disable
                                                                ? "Not Found"
                                                                : ""
                                                        }
                                                    >
                                                        <div
                                                            key={tab}
                                                            onClick={() =>
                                                                disable ||
                                                                setSelectedTab(
                                                                    tab,
                                                                )
                                                            }
                                                            className={`${
                                                                styles.tab
                                                            } ${
                                                                selectedTab ===
                                                                tab
                                                                    ? styles.activeTab
                                                                    : ""
                                                            }  ${
                                                                disable
                                                                    ? styles.disable
                                                                    : ""
                                                            } `}
                                                        >
                                                            {tab}
                                                        </div>
                                                    </LightTooltip>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={styles.tabContainer}>
                            {getCorrectTab()}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ModalComponent;
