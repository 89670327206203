import React, { useState } from "react";
import styles from "./styles.module.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "../Button/Button";
import CloseButton from "../CloseButton/CloseButton";

function Confirmation({
  buttonText,
  setDialog,
  handler,
  submit,
  modalHeading,
}) {
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  function closeDialog() {
    setDialog(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.dialog}>
        <CloseButton setClose={setDialog} style={{ right: 8, top: 8 }} />
        {submit ? (
          <div className={styles.textArea}>
            <div className={styles.message}>
              Please enter your query and submit
            </div>
            <TextareaAutosize
              onChange={(e) => setMessage(e.target.value)}
              aria-label="minimum height"
              rowsMin={5}
              placeholder="Type here...."
              autoFocus={true}
            />
          </div>
        ) : (
          <div className={styles.message}>{modalHeading}</div>
        )}

        <div className={styles.buttons}>
          <Button fontSize={12} text="Cancel" handler={closeDialog} secondary />

          {submit ? (
            <Button
              handler={() => {
                setLoader(true);
                handler(closeDialog, message);
              }}
              text="Submit"
              loading={loader}
              disabled={!message}
              fontSize={12}
            />
          ) : (
            <Button
              handler={() => {
                setLoader(true);
                handler(closeDialog);
              }}
              text={buttonText || "Confirm"}
              loading={loader}
              fontSize={12}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
