import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MultiSelectList, SelectList } from "./GscLoginFlow";
import GscLogout, {
    ConnectionObject,
    ReAuthObject,
} from "../NewDashboard/GscLogout";
import styles from "./GoogleSearchConsole.module.css";
import { useDispatch } from "react-redux";
import useHelperFunctions from "../../helperFunctions";
import useGscHook, { SelectedDomain } from "./useGscHook";
import { displayAlert } from "../../../store/actions/alert";
import SVGIcon from "../../atoms/SVGIcon";
import Button from "../../atoms/Button/Button";
import { GSC_Countries } from "./GscCountries";
import { CircularProgress } from "@material-ui/core";
import { getDeadline } from "../../../utility/validateFunctions";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import Modal from "../../atoms/Modal/Modal";
import { VscClose } from "react-icons/vsc";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { useMixpanelTA } from "./useMixpanelTA";

type GscReAuthProps = {
    getDetails: () => Promise<void>;
};

const GscReAuth = ({ getDetails }: GscReAuthProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [reAuthDomains, setReAuthDomains] = useState<ConnectionObject[] | []>(
        [],
    );
    const { postData, getData } = useHelperFunctions();
    const { getTrimmedDomainURL } = useGscHook();
    const [reAuthBegin, setReAuthBegin] = useState(false);
    const [showOtherDomains, setShowOtherDomains] = useState(false);
    const dispatch = useDispatch();
    const { taTrackReAuthFlow } = useMixpanelTA();
    useEffect(() => {
        getGscDataForAccount();
    }, []);

    const getGscDataForAccount: () => void = async () => {
        setIsLoading(true);
        const res = await getData({
            url: "/api/analyse/gsc-account-info",
        });
        if (res.status == 200) {
            if (res.data?.length > 0) {
                let temp: ConnectionObject[] = [];
                res.data.map((obj: ConnectionObject) => {
                    if (obj?.re_auth == 1 || obj?.is_disconnect == 1) {
                        let arrCountry = obj.country.map((cn) => {
                            let idx = GSC_Countries.findIndex(
                                (item) => cn == item.code,
                            );
                            return { ...GSC_Countries[idx] };
                        });
                        temp.push({
                            ...obj,
                            country: [...arrCountry],
                        });
                        setReAuthDomains(temp);
                    }
                });
            }
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const responseGoogleReAuth: (
        response: any,
        connectionInfo: ReAuthObject,
    ) => void = async (response, connectionInfo) => {
        if (response.code) {
            const res = await postData({
                url: "/api/analyse/re-authenticate",
                payload: {
                    code: response.code,
                    redirectUri: window?.location?.origin,
                    gsc_id: connectionInfo?.gscId,
                    domain_name: connectionInfo?.domainName,
                },
            });
            if (res.status == 200) {
                getDetails();
            } else {
                setReAuthBegin(false);
                dispatch(
                    displayAlert({
                        alertMessage: `${res.response.data.message}`,
                        alertType: "error",
                        timeout: 5000,
                    }),
                );
            }
        }
    };

    const handleReAuth: (info: ReAuthObject) => void = (info = {}) => {
        setReAuthBegin(true);
        let gsc = window?.google?.accounts?.oauth2?.initCodeClient({
            client_id:
                "768385066407-vrtkaritsejedf6g0g115u3qhq05m86m.apps.googleusercontent.com",
            scope: "profile email openid https://www.googleapis.com/auth/webmasters.readonly",
            access_type: "offline",
            include_granted_scopes: true,
            callback: (rs: any) => {
                responseGoogleReAuth(rs, info);
            },
            error_callback: () => {
                setReAuthBegin(false);
            },
        });
        gsc.requestCode();
    };

    const handleDisconnect: (
        domainName: string,
        gscId: number,
    ) => void = async (domainName, gscId) => {
        setReAuthBegin(true);
        const res = await postData({
            url: "/api/analyse/logoutGsc",
            payload: {
                domain_name: domainName,
                gsc_id: gscId,
            },
        });
        if (res.status == 200) {
            dispatch(
                displayAlert({
                    alertMessage: "Domain Successfully Deleted",
                    alertType: "success",
                }),
            );
            location.reload();
        }
        setReAuthBegin(false);
    };

    const { isTeamMember } = useUserAccessHook();

    return isLoading ? (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <div className={styles.reConnectWrapper}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "100px",
                }}
            >
                <div className={styles.loadingContainer}>
                    {!isTeamMember && (
                        <div className={styles.title}>
                            {reAuthDomains?.length > 1 ? "Domains" : "Domain"}
                        </div>
                    )}
                    {!isTeamMember && (
                        <div className={styles.selectedInfo}>
                            <div className={styles.selectedInfo_domain}>
                                <span>
                                    <img
                                        src={`https://s2.googleusercontent.com/s2/favicons?domain=${getTrimmedDomainURL(
                                            reAuthDomains[0]?.domain_name,
                                        )}&sz=32`}
                                        alt={getTrimmedDomainURL(
                                            reAuthDomains[0]?.domain_name,
                                        )}
                                    />
                                </span>
                                <span>
                                    {getTrimmedDomainURL(
                                        reAuthDomains[0]?.domain_name,
                                    )}
                                </span>
                                {reAuthDomains?.length > 1 && (
                                    <LightTooltip
                                        placement="right"
                                        arrow
                                        title={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: "4px 8px",
                                                    gap: "5px",
                                                }}
                                            >
                                                {reAuthDomains.map((itm) => {
                                                    return (
                                                        <div
                                                            className={
                                                                styles.selectedInfo_domain
                                                            }
                                                        >
                                                            <span>
                                                                <img
                                                                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${getTrimmedDomainURL(
                                                                        itm?.domain_name,
                                                                    )}&sz=32`}
                                                                    alt={getTrimmedDomainURL(
                                                                        itm?.domain_name,
                                                                    )}
                                                                />
                                                            </span>
                                                            <span>
                                                                {getTrimmedDomainURL(
                                                                    reAuthDomains[0]
                                                                        ?.domain_name,
                                                                )}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        }
                                    >
                                        <span>
                                            +{reAuthDomains?.length - 1} more
                                        </span>
                                    </LightTooltip>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={styles.loadingLogo}>
                        <div className={styles._failedLogo}>
                            <SVGIcon
                                src={
                                    "/New UI/SVG/triangle_exclamation-fill.svg"
                                }
                                size={40}
                                style={{ color: "#FC5A5A" }}
                            />
                        </div>
                    </div>
                    <div className={styles.failedText}>
                        <div className={styles._line1}>
                            Your Google Search Console has been disconnected.
                            {isTeamMember
                                ? " Please reach out to your administrator to  reconnect your domain(s) to access Traffic Analyzer."
                                : " Reconnect now for seamless access to Traffic Analyzer."}
                        </div>
                        <div
                            className={styles._line2}
                            style={{
                                marginBottom: isTeamMember ? "24px" : "0px",
                            }}
                        >
                            Please contact{" "}
                            <span
                                onClick={() => {
                                    window.userengage &&
                                        window.userengage("widget.show");
                                    window.userengage &&
                                        window.userengage("widget.open");
                                }}
                            >
                                chat support
                            </span>{" "}
                            for further help.
                        </div>
                    </div>
                    {!isTeamMember && (
                        <div className={styles.bottomCtas}>
                            <Button
                                handler={() => {
                                    taTrackReAuthFlow({
                                        screen: "Traffic Analyzer (Landing Page)",
                                        cta: "Reconnect",
                                    });
                                    if (reAuthDomains.length == 1) {
                                        if (
                                            reAuthDomains[0]?.is_disconnect == 1
                                        ) {
                                            handleDisconnect(
                                                reAuthDomains[0]?.domain_name,
                                                reAuthDomains[0]?.gsc_id,
                                            );
                                        } else {
                                            handleReAuth({
                                                reAuth: true,
                                                domainName:
                                                    reAuthDomains[0]
                                                        ?.domain_name,
                                                countryArr:
                                                    reAuthDomains[0]?.country,
                                                gscId: reAuthDomains[0]?.gsc_id,
                                            });
                                        }
                                    } else if (reAuthDomains.length > 1) {
                                        setShowOtherDomains(true);
                                    }
                                }}
                                loading={reAuthBegin}
                                text={
                                    reAuthDomains[0]?.is_disconnect == 1
                                        ? "Re-Authorize"
                                        : "Reconnect"
                                }
                                style={{
                                    width: "max-content",
                                    minWidth: "122px",
                                    padding: "10px 20px",
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {showOtherDomains && (
                <Modal
                    setModal={() => {
                        setShowOtherDomains(false);
                    }}
                >
                    <div className={styles.manageCountryModal}>
                        <div
                            className={styles.closeModal}
                            onClick={() => setShowOtherDomains(false)}
                        >
                            <VscClose size={20} />
                        </div>
                        <GscLogout
                            screen={"Traffic Analyzer (Modal)"}
                            reAuthSuccess={() => {
                                setShowOtherDomains(false);
                                getDetails();
                                setIsLoading(true);
                            }}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default GscReAuth;
