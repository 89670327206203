import React, { useCallback, useEffect } from "react";
import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";

/**
 * @param {import("@material-ui/core").TooltipProps} props */

export const LightTooltip = (props) => {
    const [open, setOpen] = React.useState(false);

    // useEffect(() => {
    //     let ref = null;
    //     if (open) {
    //         ref = setTimeout(() => setOpen(false), 3000);
    //     }

    //     return () => clearTimeout(ref);
    // }, [open]);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const _ToolTip = useCallback(
        withStyles((theme) => ({
            arrow: {
                color: "#fcfcfc",
                "&::before": {
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                },
                ...(props.arrowStyle ? props.arrowStyle : {}),
            },
            tooltip: {
                borderRadius: 8,
                backgroundColor:
                    props.tooltipStyle?.backgroundColor || "#fcfcfc",
                color: "#202020",
                fontFamily: "inter",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                fontSize: 12,
                fontWeight: 400,
                ...(props.tooltipStyle ? props.tooltipStyle : {}),
            },
        }))(Tooltip),
        [],
    );

    if (props.click)
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <_ToolTip
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        {...props}
                        interactive
                    >
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={handleTooltipOpen}
                        >
                            {props.children}
                        </div>
                    </_ToolTip>
                </div>
            </ClickAwayListener>
        );

    return <_ToolTip {...props} />;
};
