import React, { useEffect, useState } from "react";
import ModuleTemplate from "../../templates/ModuleTemplate/ModuleTemplate";
import { useDispatch, useSelector } from "react-redux";
import useAIHumanizerHook from "./useAIHumanizerHook";
import AIHumanizerLandingPage from "./AIHumanizerLandingPage";
import Progress from "../../atoms/Progress/Progress";
import ScanDocs from "./ScanDocs.jsx";
import { updateUserInfo } from "../../../store/actions/auth.js";

function AIHumanizer() {
    const otherInfo = useSelector((state) => state.otherInfo);
    const userVideo = useSelector((state) => state.video.humanizer);
    const show_humanizer_landing = useSelector(
        (state) => state.auth.show_humanizer_landing,
    );
    const [tabWiseStickyHeader, setTabWiseStickyHeader] = useState(<></>);
    const [initiateClose, setInitiateClose] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (otherInfo?.subscriptionInfo?.humanizer_plan_id) {
            dispatch(updateUserInfo("show_humanizer_landing", 0));
        }
    }, [otherInfo?.subscriptionInfo?.humanizer_plan_id]);
    const {
        createScan,
        openScan,
        creatingScan,
        loading,
        setLoading,
        scans,
        hasMore,
        requestData,
        setRequestData,
        deleteDocument,
        restoreDocument,
    } = useAIHumanizerHook();

    return show_humanizer_landing === undefined ? (
        <Progress height={"100%"} />
    ) : show_humanizer_landing == 0 ? (
        <>
            <ModuleTemplate
                sourceWhere={"humanizer"}
                HeaderChild={<></>}
                initiateClose={initiateClose}
                userVideo={userVideo}
                setInitiateClose={setInitiateClose}
                heading="Detect and Humanize"
                subHeading="AI Detection and Undetectable Humanization at Your Fingertips"
                stickyHeaderChild={<>{tabWiseStickyHeader}</>}
                content={
                    <ScanDocs
                        {...{
                            loading,
                            createDoc: createScan,
                            hasMore,
                            requestData,
                            openDoc: openScan,
                            docs: scans,
                            setLoading,
                            setRequestData,
                            restoreDoc: restoreDocument,
                            deleteDoc: deleteDocument,
                            creatingDoc: creatingScan,
                        }}
                        key={2}
                        bin={false}
                        newDoc={false}
                        setTabWiseStickyHeader={setTabWiseStickyHeader}
                        aiHumanizer={true}
                    />
                }
            />
        </>
    ) : (
        <>
            <AIHumanizerLandingPage />
        </>
    );
}

export default AIHumanizer;
