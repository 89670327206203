import React, { useState, useEffect } from "react";

export const getValuePercent = (value, total, setProgress = null) => {
    if (setProgress) {
        setProgress((value / total) * 100);
    } else return (value / total) * 100;
};

function ProgressBar({
    value = 5,
    total = 10,
    color = "",
    className = "",
    progressStyles = {},
    backgroundColor = "",
}) {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        getValuePercent(value, total, setProgress);
    }, [value]);

    return (
        <span
            className={className}
            style={{
                background: `linear-gradient(
            90deg
            ,${color ? color : "#014dc5"} ${progress}%,${
                    backgroundColor ? backgroundColor : "#e5e5e5"
                }  ${progress}%)`,
                ...progressStyles,
            }}
        ></span>
    );
}

export default ProgressBar;
