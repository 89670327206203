import React, { useEffect, useRef } from "react";
import ReportEditor from "../../organisms/TextEditor/ReportEditor";
import useEditorMethods from "../../organisms/TextEditor/EditorMethods";
import styles from "./styles.module.css";
import { SMART_EDITOR } from "../../organisms/TextEditor/editorConstants";
import { useState } from "react";
import { useParams } from "react-router-dom";
import EditorAssistant from "../../organisms/TextEditor/EditorAssistant/EditorAssistant";
import EditorHeader from "../../organisms/TextEditor/EditorHeader";
import EditorTitle from "../../organisms/Creator/CreatorCore/EditorTitle";
import MenuList from "../../organisms/MenuList/MenuList";
import EditorContext from "../../organisms/TextEditor/EditorContext";
import Progress from "../../atoms/Progress/Progress";
const menuList = [<span>File</span>, <span>Edit</span>, <span>Help</span>];
function SmartEditor() {
    const [connectorCases, setConnectorCases] = useState([]);
    const [versionData, setVersionData] = useState({ current: true, html: "" });
    const params = useParams();

    const {
        getEditorData,
        saveEditorData,
        saved,
        saving,
        writingState,
        writeForMe,
        aiRepeatModal,
        noContentAiModal,
        setAiRepeatModal,
        setNoContentAiModal,
        canReWrite,
        lastAIcase,
        setCanReWrite,
        docInfo,
        realtimeRating,
        aiWordLimitSetting,
        setAiWordLimitSetting,
        setRealtimeRatings,
        getDataFromUrl,
    } = useEditorMethods({
        attachedTo: SMART_EDITOR,
        reportInfo: { report_id: -2 },
        docId: params.documentId,
        setConnectorCases: setConnectorCases,
    });

    useEffect(() => {
        window.userengage && window.userengage("widget.hide");
        return () => {
            window.userengage && window.userengage("widget.show");
        };
    }, []);

    function rewrite() {
        if (!canReWrite) return false;

        document.quill.editor.cursor = document.quill.editor.lastCursor;
        document.quill.editor.deleteText(
            document.quill.editor.lastCursor,
            document.quill.editor.lastLength,
            "api",
        );
        writeForMe(lastAIcase.id, lastAIcase.useCase, lastAIcase.isTool);
    }

    return (
        <>
            <EditorContext.Provider
                value={{
                    versionData,
                    setVersionData,
                    docInfo: {
                        reportId: params.documentId,
                        title: docInfo.title,
                        attachedTo: SMART_EDITOR,
                    },
                    attachedTo: SMART_EDITOR,
                }}
            >
                <EditorHeader
                    left={
                        <MenuList
                            info={{
                                docName: docInfo.title,
                                attachedTo: SMART_EDITOR,
                                title: docInfo.title,
                                docId: params.documentId,
                                isSmaple: false,
                            }}
                            getDataFromUrl={getDataFromUrl}
                        />
                    }
                    title={
                        <EditorTitle
                            access={true}
                            reportInfo={{
                                url: window.location.href,
                                title: docInfo.title,
                                docId: params.documentId,
                            }}
                            smartDoc={true}
                        />
                    }
                    smartDoc={true}
                ></EditorHeader>

                <ReportEditor
                    writeForMe={writeForMe}
                    connectorCases={connectorCases}
                    saving={saving}
                    saveEditorData={saveEditorData}
                    writingState={writingState}
                    getEditorData={getEditorData}
                    rewrite={rewrite}
                    canReWrite={canReWrite}
                    saved={saved}
                    aiWordLimitSetting={aiWordLimitSetting}
                    setAiWordLimitSetting={setAiWordLimitSetting}
                    reportInfo={{}}
                    docInfo={docInfo}
                    editorText={"123"}
                    keyTerms={[]}
                    aiRepeatModal={aiRepeatModal}
                    noContentAiModal={noContentAiModal}
                    setAiRepeatModal={setAiRepeatModal}
                    setNoContentAiModal={setNoContentAiModal}
                    setCanReWrite={setCanReWrite}
                    attachedTo={SMART_EDITOR}
                    realtimeRating={realtimeRating}
                    setRealtimeRatings={setRealtimeRatings}
                />
            </EditorContext.Provider>
        </>
    );
}

export default SmartEditor;
