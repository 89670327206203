import React, { useEffect } from "react";
import TableColumnHeading from "../TableColumnHeading";
import styles from "./competitors.module.css";

const HeadRow = ({ columns, sortBy, setSortBy, cruiseMode }) => {
    useEffect(() => {
        console.log("Head Rows", columns);
    }, [columns]);
    return (
        <tr className={styles.headRow}>
            {columns &&
                columns.map((col, i) => {
                    return (
                        <TableColumnHeading
                            sortBy={sortBy}
                            sortingBy={(colID, asc) =>
                                setSortBy({ colID, asc })
                            }
                            i={i}
                            col={col}
                            cruiseMode={cruiseMode}
                        />
                    );
                })}
        </tr>
    );
};

export default HeadRow;
