import { Skeleton } from "@material-ui/lab";
import React from "react";
import styles from "./transactions.module.css";

function MobileSkeletonCard() {
  return (
    <div className={styles.mobileCard}>
      <div className={styles.mobileCardTop}>
        <div className={styles.rowIcon}>
          <Skeleton height={40} width={40} variant="circle" />
        </div>
        <div>
          <p className={styles.mobileCardHeading}>
            <Skeleton width={80} />
          </p>
          <span className={styles.mobileCardId}>
            <Skeleton variant={"text"} width={50} />
          </span>
        </div>
      </div>
      <div className={styles.mobileCardBottom}>
        <div>
          <span className={styles.mobileCardDate}>
            <Skeleton width={100} variant="text" />
          </span>
          <span className={styles.mobileCardTime}>
            <Skeleton width={100} variant="text" />
          </span>
        </div>
        <div className={styles.rowType}>
          <span>
            <Skeleton height={50} width={80} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default MobileSkeletonCard;
