import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { HiOutlineCheck } from "react-icons/hi";
import { useSelector } from "react-redux";
import Button from "../../atoms/Button/Button";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import styles from "./styles.module.css";

function InviteSuccess(props) {
    const company = useSelector((state) => state.auth.company_name);

    useEffect(() => {
        return () => {
            props.setEmail("");
            props.setName("");
        };
    }, []);

    return (
        <div className={styles.inviteSuccessModal}>
            {/* <CgClose
        className={styles.cross}
        onClick={() => props.setInviteSuccessModal(false)}
      /> */}
            <CloseButton setClose={props.setInviteSuccessModal} />
            <div className={styles.check}>
                <HiOutlineCheck color="white" size={60} />
            </div>

            <div className={styles.inviteSuccessContentHeader}>
                Invitation Sent
            </div>

            <div
                style={{ textTransform: "capitalize" }}
                className={styles.inviteSuccessContent1}
            >
                {props.name}
            </div>

            <div className={styles.inviteSuccessContent2}>
                <span>{props.email}</span>
            </div>

            <div className={styles.inviteSuccessLabel}>
                Successfully invited as a team member.
            </div>
            <div className={styles.separator}></div>

            <div className={styles.inviteSuccessButton}>
                <Button
                    text="OK"
                    height={50}
                    width={140}
                    handler={() => props.setInviteSuccessModal(false)}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#014dc5",
                        margin: "auto",
                    }}
                ></Button>
            </div>
        </div>
    );
}

export default InviteSuccess;
