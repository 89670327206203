import React, { useEffect, useState } from "react";
import SVGIcon from "../../../atoms/SVGIcon";
import { loadingText } from "../constants";
import styles from "./styles.module.css";

function InProgressTimeline({
  style,
  step,
  duration = 6,
  status = 0,
  failed = false,
  isCluster,
}) {
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    setProgress(false);
    setTimeout(() => {
      setProgress(true);
    }, 2000);
  }, [step]);

  return (
    <div
      style={style}
      className={`${styles.container} ${
        status == 1 ? styles.completed : status == 2 ? styles.failed : ""
      }`}
    >
      {loadingText(status, isCluster)
        .slice(0, loadingText(status, isCluster).length)
        .map((text, i) => (
          <>
            <div
              className={`${styles.dot} ${
                step == i
                  ? styles.circle
                  : step > i
                  ? styles.blueDot
                  : styles.innerDot
              }`}
            >
              <div>
                <SVGIcon color="white" src={`/New UI/SVG/${text.icon}`} />
              </div>
            </div>
            <div
              className={`${styles.bar}   ${step > i ? styles.blueBar1 : ""}`}
            >
              {step == i && progress ? (
                <div
                  style={{ animationDuration: `${duration - 2}s` }}
                  className={styles.blueBar}
                ></div>
              ) : null}
            </div>
          </>
        ))}
    </div>
  );
}

export default InProgressTimeline;
