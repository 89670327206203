import React from "react";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import styles from "./styles.module.css";
const LockedTextComponent = () => {
  const goTo = useNavigation();

  const { isLTD } = useUserAccessHook();

  return isLTD ? (
    <div className={styles.hoverText}>
      This feature is only available on higher plans.
    </div>
  ) : (
    <div className={styles.hoverText}>
      This feature is only available on higher plans. Kindly{" "}
      <span onClick={() => goTo("BILLING_PLANS")} className={styles.link}>
        Upgrade
      </span>{" "}
      to use this feature.
    </div>
  );
};

export default LockedTextComponent;
