import { ClickAwayListener } from "@material-ui/core";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { VscClose } from "react-icons/vsc";

function Tag({ tag, removeTag }) {
    if (!tag) return null;

    return (
        <div className={styles.tag}>
            {tag.tag_name}

            <span
                className={styles.crossTag}
                onClick={(e) => {
                    e.stopPropagation();
                    removeTag(tag.id);
                }}
            >
                x
            </span>
        </div>
    );
}

function TagLIst({
    list,
    addTag,
    setDocTags,
    docId,
    seoDoc,
    setTagsModal,
    cluster,
}) {
    const [showAll, setShowAll] = useState(false);

    const removeTag = (id) => {
        setDocTags((ps) => {
            const arr = ps.filter((tag) => tag.id != id);
            addTag({ tags: arr }, docId, seoDoc, !seoDoc, cluster);
            return arr;
        });
    };

    return (
        <div onClick={(e) => e.stopPropagation()} className={styles.tagList}>
            {showAll && (
                <ClickAwayListener onClickAway={() => setShowAll(false)}>
                    <div className={styles.allTags}>
                        <VscClose onClick={() => setShowAll(false)} />
                        <h3>All Tags </h3>
                        <div>
                            {list.map((tag) => (
                                <Tag tag={tag} removeTag={removeTag} />
                            ))}
                        </div>
                    </div>
                </ClickAwayListener>
            )}

            {list.slice(0, 4).map((tag) => (
                <Tag tag={tag} removeTag={removeTag} />
            ))}

            {list.length > 4 ? (
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowAll(true);
                    }}
                    className={styles.moreTags}
                >
                    +{list.length - 4} more...
                </span>
            ) : (
                ""
            )}

            <div
                onClick={(e) => {
                    e.stopPropagation();
                    setTagsModal(true);
                }}
                className={styles.tag + " " + styles.add}
            >
                {list.length == 0 ? "Add Tag +" : "+"}
            </div>
        </div>
    );
}

export default TagLIst;
