import React, { useState, useRef } from 'react';

export const RecordingContext = React.createContext();

function RecordingProvider({ children }) {
  const [isRecording, setIsRecording] = useState({
    audio: false,
    video: false,
    on: false,
    audioData: null,
    videoData: null,
    projectID: null,
  });
  const recTimer = useRef(0);

  const tempMedia = useRef({ media: null, recorder: null, timerID: null, media1: null });

  async function screenRecord(pID, setTimer) {
    if (isRecording.on) return;
    const stream1 = await navigator.mediaDevices.getDisplayMedia({
      audio: false,
      video: true,
    });
    const stream2 = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    tempMedia.current.media = stream2;
    tempMedia.current.media1 = stream1;


    stream1.oninactive = (e) => {
      stopRecording();
    };

    const vid = new MediaStream([
      stream1.getTracks()[0],
      stream2.getTracks()[0],
    ]);

    setTimer(3);
    const interval2 = setInterval(() => setTimer((e) => e - 1), 1000)

    setTimeout(() => {
      clearInterval(interval2);
      const record = new MediaRecorder(vid);
      tempMedia.current.recorder = record;
      record.start();
      setIsRecording({
        audio: false,
        on: true,
        video: true,
        videoData: null,
        audioData: null,
        projectID: pID,
      });
      console.log(record);
      const chunks = [];

      record.ondataavailable = function (e) {
        chunks.push(e.data);
        // console.log(e);
      };

      record.onstop = function (e) {
        const blob = new Blob(chunks, { type: 'video/mp4' });

        setIsRecording({
          ...isRecording,
          projectID: pID,
          video: false,
          videoData: blob,
          audioData: null,
          on: false
        });
      };

    }, 3000)

  }

  function audioRecord(pID, setRecTimer) {
    if (isRecording.on) return;

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((aud) => {
        tempMedia.current.media = aud;
        const record = new MediaRecorder(aud);
        tempMedia.current.recorder = record;
        record.start();
        tempMedia.current.timerId = setInterval(() => {
          recTimer.current++;
          setRecTimer(recTimer.current);
        }, 1000);
        setIsRecording({
          video: false,
          on: true,
          audio: true,
          projectID: pID,
          audioData: null,
          videoData: null,
        });

        console.log(record);
        const chunks = [];

        record.ondataavailable = function (e) {
          chunks.push(e.data);
          console.log(e);
        };

        record.onstop = function (e) {
          const blob = new Blob(chunks, { type: 'audio/mp3' });

          recTimer.current = 0;
          setRecTimer(0);

          setIsRecording({
            ...isRecording,
            projectID: pID,
            audio: false,
            audioData: blob,
            videoData: null,
            on: false
          });
        };
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function stopRecording() {


    if (tempMedia.current.recorder.active == true)
      tempMedia.current.recorder.stop();

    if (tempMedia.current.media1 != null) {
      tempMedia.current.media1.getTracks().map(function (val) {
        val.stop();
      });
    }

    if (tempMedia.current.media != null) {
      tempMedia.current.media.getTracks().map(function (val) {
        val.stop();
      });
    }
    if (tempMedia.current.timerId) {
      clearTimeout(tempMedia.current.timerId);
    }
    // setRecTimer(0);
    setIsRecording({
      ...isRecording,
      audio: false,
      video: false,
      on: false,
    });
  }

  return (
    <RecordingContext.Provider
      value={{
        isRecording,
        audioRecord,
        setIsRecording,
        screenRecord,
        stopRecording,
        recTimer,
      }}
    >
      {children}
    </RecordingContext.Provider>
  );
}

export default RecordingProvider;
