import React, { useCallback, useRef, useState } from "react";
import styles from "./styles.module.css";
import TextField from "../../../atoms/CustomTextfield/Textfield";
import Button from "../../../atoms/Button/Button";
import { useEffect } from "react";
import { FcSearch } from "react-icons/fc";
import useHelperFunctions from "../../../helperFunctions";
import { Skeleton } from "@material-ui/lab";
import Image from "./Image";
import { BorderLeft } from "@material-ui/icons";
import { MdClose } from "react-icons/md";
import getStaticData from "../staticData";
import WriteToEditor from "../Toolbar/WriteToEditor";
import CloseButton from "../../../atoms/CloseButton/CloseButton";
import { UPLOAD_FILE } from "../../../api.json";
import { displayAlert } from "../../../../store/actions/alert";
import { useDispatch } from "react-redux";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import LocalPagination from "../../../atoms/LocalPagination/LocalPagination";
import Modal from "../../../atoms/Modal/Modal";
import { ClickAwayListener } from "@material-ui/core";
import mixpanel from "mixpanel-browser";

const tabs = ["Image from Computer", "Stock Images"];
const skeletonArray = new Array(12).fill({ dummy: true });

function ImageModal({ setUnsplash }) {
    const imageHandler = () => {
        ref2.current.click();
    };

    const [search, setSearch] = useState("ai seo");
    const [file, setFile] = useState(null);
    const [data, setData] = useState(getStaticData());
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(2);
    const ref = useRef();
    const ref2 = useRef();
    const { getData, postData } = useHelperFunctions();
    const dispatch = useDispatch();

    const [analyzing, setAnalyzing] = useState(false);

    const uploadImage = useCallback(async (image) => {
        const formdata = new FormData();
        formdata.append("image", image);
        const res = await postData({
            url: UPLOAD_FILE,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            payload: formdata,
        });
        return res;
    }, []);

    const sendImage = async (image, raw) => {
        if (raw) {
            if (
                !image.type.match(
                    /^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i,
                )
            ) {
                return;
            }
            setAnalyzing(true);
            const res = await uploadImage(image);
            setAnalyzing(false);
            if (res.status == 200) {
                WriteToEditor({
                    type: "image",
                    data: res.data.data.attachment,
                    length: 1,
                    source: "user",
                });
                return true;
            } else {
                return false;
            }
        } else {
            WriteToEditor({
                type: "image",
                data: image,
                length: 1,
                source: "user",
            });
        }
        mixpanel.track("Editor Toolbar", {
            option: "Image",
            cta: "Inserted",
        });
        dispatch(
            displayAlert({
                alertMessage: "Image Added",
                alertType: "success",
            }),
        );
    };

    useEffect(() => {
        setPage(0);
    }, [search]);

    useEffect(() => {
        if (loading) {
            setData(skeletonArray);
        }
    }, [loading]);

    useEffect(() => {
        getImages();
    }, [page]);

    const getImages = async (e) => {
        setLoading(true);
        const res = await getData({
            url: `/api/creator/unsplash?query=${e ? e : search}&page=${
                page ? page + 1 : 1
            }&per_page=${20}`,
        });
        if (res.status == 200) {
            setData(res.data.results);
            setPages(res.data.total_pages);
        }
        setLoading(false);
    };

    const getView = () => {
        switch (tab) {
            case 1:
                return (
                    <>
                        <div className={styles.btnContainer}>
                            <input
                                ref={ref2}
                                onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        const success = await sendImage(
                                            e.target.files[0],
                                            true,
                                        );
                                        if (success) setUnsplash(false);
                                    }
                                }}
                                hidden
                                type="file"
                                id="img"
                                name="img"
                                accept="image/*"
                            />
                            Directly Drag & Drop to Editor <br />
                            or
                            <br />
                            <Button
                                handler={imageHandler}
                                style={{
                                    border: "1px solid #597ef7",
                                    height: "44px",
                                    width: "120px",
                                    backgroundColor: "#597ef71a",
                                    color: "#597ef7",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                }}
                                text="Select Image"
                            ></Button>
                            <div className={styles.analyzing}>
                                {analyzing ? (
                                    <>
                                        Uploading... <LoadingDots />
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </>
                );
            case 2:
            case 3:
                return (
                    <>
                        <div className={styles.row}>
                            <TextField
                                props={{
                                    ref: ref,
                                    style: {
                                        border: "1px solid rgb(204, 204, 204)",
                                        borderRadius: 3,
                                        borderRight: "none",
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                    },
                                }}
                                width="200px"
                                label=""
                                onKeyUp={(e) => {
                                    if (e.code == "Enter") {
                                        if (ref.current.value) {
                                            setSearch(ref.current.value);
                                            getImages(ref.current.value);
                                        }
                                    }
                                }}
                            ></TextField>

                            <Button
                                text=""
                                secondary
                                width={50}
                                Icon={FcSearch}
                                style={{
                                    border: "1px solid rgb(204, 204, 204)",
                                    borderRadius: 3,
                                    borderLeft: "none",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                }}
                                handler={() => {
                                    if (ref.current.value) {
                                        setSearch(ref.current.value);
                                        getImages(ref.current.value);
                                    }
                                }}
                            ></Button>
                        </div>

                        {data.length ? (
                            <div className={styles.imagesCol}>
                                {new Array(4).fill(1).map((i, index) => (
                                    <Images
                                        col={index}
                                        data={data}
                                        sendImage={sendImage}
                                    />
                                ))}
                            </div>
                        ) : (
                            <h3
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                No items found.
                            </h3>
                        )}

                        <LocalPagination
                            pageCount={pages}
                            currentPage={page}
                            onPageChange={({ selected }) => setPage(selected)}
                        />
                    </>
                );
        }
    };

    return (
        <ClickAwayListener onClickAway={() => setUnsplash(false)}>
            <div className={styles.container}>
                <CloseButton
                    style={{ right: 10, top: 10 }}
                    setClose={setUnsplash}
                />
                <h3>Select Image to Upload</h3>
                <div className={styles.tabs}>
                    {tabs.map((item, i) => (
                        <span
                            onClick={() => setTab(i + 1)}
                            className={tab == i + 1 ? styles.selected : ""}
                        >
                            {item}
                            {/* {tab == i + 1 && <hr />} */}
                        </span>
                    ))}
                </div>
                <div
                    style={{
                        backgroundColor: "#ababab4b",
                        height: "1px",
                        width: "100%",
                    }}
                ></div>
                <div style={{ marginTop: "5px", height: "5px" }}></div>
                {getView()}
            </div>
        </ClickAwayListener>
    );
}

const Images = ({ col, data, sendImage }) => {
    const x = data.length / 4;
    return (
        <div className={styles.images}>
            {data.slice(col * x, col * x + x).map((image) => {
                return !image.dummy ? (
                    <Image image={image} sendImage={sendImage} />
                ) : (
                    <Skeleton
                        height={125 * (Math.random() + 1)}
                        width={200}
                        style={{ margin: "5px 2.5px", transform: "none" }}
                    />
                );
            })}
        </div>
    );
};

const ImageHandler = () => {
    const [unsplash, setUnsplash] = useState(false);

    useEffect(() => {
        const toolbar = document.quill.editor.getModule("toolbar");
        toolbar.addHandler("image", () => {
            mixpanel.track("Editor Toolbar", {
                option: "Image",
                cta: "Clicked",
            });
            setUnsplash(true);
        });
    }, []);

    return (
        <>
            {unsplash && (
                <div className={styles.imageModalContainer}>
                    <ImageModal setUnsplash={setUnsplash} />
                </div>
            )}
        </>
    );
};

export default ImageHandler;
