import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useParams } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import styles from "./styles.module.css";
import { BsArrowLeftShort } from "react-icons/bs";
import ReportContext from "../Research/ReportContext";
import useUserAccessHook from "../../../components/account/useUserAccessHook";
import { displayAlert } from "../../../store/actions/alert";
import { LightTooltip } from "../../atoms/Tooltip/tooltip";
import LockedTextComponent from "./LockedTextComponent";
import { Link } from "@material-ui/core";
import { AiOutlineQuestionCircle } from "react-icons/ai";

function EditorHeader({
    title,
    left,
    link,
    smartDoc,
    auditMode,
    isFixItEnabled,
    aiHumanizer,
}) {
    const goTo = useNavigation();
    const params = useParams();
    const reportContext = useContext(ReportContext);
    const { isFreeForever } = useUserAccessHook();
    const dispatch = useDispatch();
    const [showNudge, setShowNudge] = useState(false);

    useEffect(() => {
        if (isFreeForever) {
            setTimeout(() => setShowNudge(true), 2000);
        }
    }, []);

    useEffect(() => {
        if (showNudge) {
            setTimeout(() => setShowNudge(false), 2000);
        }
    }, [showNudge]);

    return (
        <div
            // style={smartDoc || auditMode ? { paddingLeft: 70 } : {}}
            className={styles.editorHeader}
        >
            <div>
                <div className={styles.titleBar}>{title}</div>
                <div className={styles.menuList}>{left}</div>
            </div>

            {!smartDoc && !isFixItEnabled?.isOn && !aiHumanizer && (
                <LightTooltip
                    arrow
                    placement="bottom"
                    title={
                        reportContext.reportInfo?.is_sample ? (
                            <div>
                                This is a sample Report. Please generate a new
                                report to test this feature.{" "}
                                <span
                                    style={{
                                        fontWeight: "500",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        color: "#014dc5",
                                    }}
                                    onClick={() => goTo("CREATOR")}
                                >
                                    Try now!
                                </span>
                            </div>
                        ) : isFreeForever ? (
                            <LockedTextComponent />
                        ) : (
                            ""
                        )
                    }
                    interactive={true}
                    {...(showNudge && { open: true })}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            className={styles.cruiseButton}
                            onClick={() => {
                                if (reportContext.showRatingComponent) {
                                    reportContext.setReportData((ps) => ({
                                        ...ps,
                                        showRatingComponent: false,
                                    }));
                                }
                                if (reportContext.reportInfo?.isHumanizer) {
                                    reportContext.setReportData((ps) => ({
                                        ...ps,
                                        reportInfo: {
                                            ...ps.reportInfo,
                                            isHumanizer: false,
                                        },
                                    }));
                                } else {
                                    if (auditMode) {
                                        if (
                                            window.location.pathname.includes(
                                                "audit/report",
                                            )
                                        ) {
                                            goTo(
                                                reportContext.isShared
                                                    ? "AUDIT_EDITOR_SHARED"
                                                    : "AUDIT_EDITOR",
                                                [],
                                                `${
                                                    reportContext.reportInfo
                                                        .report_id
                                                }/${
                                                    reportContext.isShared
                                                        ? params.access
                                                        : ""
                                                }`,
                                                false,
                                            );
                                        }
                                    } else {
                                        window.location.pathname.includes(
                                            "creator/report",
                                        )
                                            ? goTo(
                                                  reportContext.isShared
                                                      ? "SEO_EDITOR_SHARED"
                                                      : "SEO_EDITOR",
                                                  [],
                                                  `${
                                                      reportContext.reportInfo
                                                          .report_id
                                                  }/${
                                                      reportContext.isShared
                                                          ? params.access
                                                          : ""
                                                  }`,
                                                  false,
                                              )
                                            : isFreeForever ||
                                              reportContext.reportInfo
                                                  ?.is_sample
                                            ? {}
                                            : reportContext.setGoNutsModal(
                                                  true,
                                              );
                                    }
                                }
                            }}
                        >
                            {/* {!auditMode
                                ? window.location.pathname.includes(
                                      "/report",
                                  ) && <BsArrowLeftShort size={26} />
                                : window.location.pathname.includes(
                                      "/editor",
                                  ) &&
                                  !reportContext.isShared &&
                                  !reportContext.reportInfo?.is_sample && (
                                      <BsArrowLeftShort size={26} />
                                  )} */}
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {window.location.pathname.includes(
                                    "/report",
                                ) ? (
                                    <>
                                        <BsArrowLeftShort size={26} />
                                        Back to Editor
                                    </>
                                ) : reportContext.isShared ? (
                                    ""
                                ) : reportContext.reportInfo?.isHumanizer ? (
                                    <>
                                        <BsArrowLeftShort size={26} />
                                        Back to Editor
                                    </>
                                ) : !auditMode ? (
                                    <>
                                        <BsArrowLeftShort size={26} /> Cruise
                                        Mode
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </LightTooltip>
            )}
        </div>
    );
}

export default EditorHeader;
