import React from "react";
const defaultVal = {
    reportLoaded: false,
    competetion: [],
    peopleQuestions: [],
    reportInfo: {},
    keyTerms: [],
    isCurrVersion: true,
    isShared: false,
    isOwner: true,
    rating: {},
    highlight_keyTerm: true,
    setReportData: () => {},
    getSeoScore: () => {},
    setMetaData: () => {},
    metaData: {},
    seoScore: 0,
    auditMode: false,
    satisfactionRating: null,
    showRatingComponent: false,
    contentGapData: [],
    contentGapRating: true,
};

const ReportContext = React.createContext(defaultVal);

export default ReportContext;
