import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { updateOtherInfo } from "../../../store/actions/otherInfo";
import Button from "../../atoms/Button/Button";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import styles from "./styles.module.css";

function DeleteMember(props) {
    const [text, setText] = useState("");
    const goTo = useNavigation();
    const subscriptionInfo = useSelector(
        (store) => store.otherInfo.subscriptionInfo,
    );

    const otherInfo = useSelector((store) => store.otherInfo);
    const dispatch = useDispatch();

    return (
        <div className={styles.deleteModal}>
            {/* <CgClose
        className={styles.cross}
        onClick={() => props.setdeleteModal(false)}
      /> */}
            <CloseButton setClose={props.setdeleteModal} />
            <div className={styles.deleteContentHeader}>Remove Team Member</div>

            <div className={styles.deleteContent1}>
                Are you sure you want to delete?
            </div>

            <div className={styles.deleteContent2}>
                {props.nameToBeRemoved} - <span>{props.emailToBeRemoved}</span>
            </div>

            <div className={styles.deleteLabel}>
                Please enter the text DELETE in the text box below to confirm.
            </div>

            <input
                onChange={(e) => {
                    setText(e?.target?.value?.trim());
                }}
                className={styles.editableDiv}
            />

            <div className={styles.deleteNote}>
                Also note, all reports created by the user will be moved to your
                account.
            </div>
            <div className={styles.separator}></div>
            <div className={styles.inviteLimitButtons}>
                <Button
                    text="Cancel"
                    height={50}
                    width={140}
                    handler={() => {
                        props.setdeleteModal(false);
                    }}
                    style={{
                        borderRadius: 3,
                    }}
                    secondary={true}
                    disabled={props.loading}
                ></Button>

                <Button
                    disabled={text.toLowerCase() != "delete"}
                    text="Remove"
                    height={50}
                    width={140}
                    handler={async () => {
                        const res = await props.removeMember();
                        if (res) {
                            dispatch(
                                updateOtherInfo({
                                    ...otherInfo,
                                    subscriptionInfo: {
                                        ...subscriptionInfo,
                                        total_seats:
                                            subscriptionInfo.purchased_seat_count >
                                            0
                                                ? subscriptionInfo.total_seats -
                                                  1
                                                : subscriptionInfo.total_seats,
                                        purchased_seat_count:
                                            subscriptionInfo.purchased_seat_count >
                                            0
                                                ? subscriptionInfo.purchased_seat_count -
                                                  1
                                                : subscriptionInfo.purchased_seat_count,
                                    },
                                }),
                            );
                        }
                        props.setdeleteModal(false);
                    }}
                    style={{
                        borderRadius: 3,
                        backgroundColor: "#f94f2c",
                    }}
                    loading={props.loading}
                ></Button>
            </div>
        </div>
    );
}

export default DeleteMember;
