import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import SVGIcon from "../../atoms/SVGIcon";
import { SMART_EDITOR } from "../TextEditor/editorConstants";
import {
    Export2Markdown,
    Export2Word,
    getRecentDocs,
    saveAsPDF,
    saveRecentDocs,
} from "../TextEditor/Options/utils";
import styles from "./styles.module.css";
import screens from "../../../screens.json";
import { debounceWrapper } from "../../../utility/helperFun";
import moment from "moment";
import { useLocation } from "react-router-dom";
import URL from "../TextEditor/Options/URL";
import Modal from "../../atoms/Modal/Modal";
import ProTips from "./ProTips";
import { useContext } from "react";
import ReportContext from "../Research/ReportContext";
import IntegrationHub from "../../Pages/IntegrationHub/IntegrationHub";
import { IoMdClose } from "react-icons/io";
import VideoGuide from "./VideoGuide";
import useMixpanelHook from "../../../utility/useMixpanelHook";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import { setChatWidget } from "../../../store/actions/state";
import useHelperFunctions from "../../helperFunctions";
import slugify from "slugify";

const plagiarismEvent = new Event("plagiarismON");
const wordpressEvent = new Event("wordpressON");
const shopifyEvent = new Event("shopifyON");

const slugifyOption = {
    replacement: "-",
    remove: /[*%&#^;$<>+~.()'"!:@↳•]/g,
};

function undo(aiHumanizer, editor1Disable) {
    if (aiHumanizer) {
        if (editor1Disable) {
            document.quill.editor2.history.undo();
        } else {
            document.quill.editor1.history.undo();
        }
    } else {
        document.quill.editor.history.undo();
    }
}
function redo(aiHumanizer, editor1Disable) {
    if (aiHumanizer) {
        if (editor1Disable) {
            document.quill.editor2.history.redo();
        } else {
            document.quill.editor1.history.redo();
        }
    } else {
        document.quill.editor.history.redo();
    }
}

const ImportFromUrlModal = ({ setImportFromUrlModal, getDataFromUrl }) => {
    const [isLoadingTextFromUrl, setIsLoadingTextFromUrl] = useState(false);

    return (
        <Modal
            setModal={isLoadingTextFromUrl ? () => {} : setImportFromUrlModal}
        >
            <URL
                getDataFromUrl={getDataFromUrl}
                setImportFromUrlModal={setImportFromUrlModal}
                isLoadingTextFromUrl={isLoadingTextFromUrl}
                setIsLoadingTextFromUrl={setIsLoadingTextFromUrl}
            />
        </Modal>
    );
};

function MenuList({
    info,
    isFixItEnabled,
    aiHumanizer,
    editor1Disable,
    getDataFromUrl,
}) {
    const goTo = useNavigation();
    const userId = useSelector((state) => state.auth.userId);
    const company_user_type = useSelector(
        (state) => state.auth.company_user_type,
    );
    const role = useSelector((state) => state.auth.role);
    const [recentDocs, setRecentDocs] = useState([]);
    const [urlImport, setImportFromUrlModal] = useState(false);
    const [ProTipsModal, setProTips] = useState(false);
    const [videoGuide, setVideoGuide] = useState(false);
    const [moreApps, setMoreApps] = useState(false);
    const otherInfo = useSelector((store) => store.otherInfo);
    const [plagiarismMenu, setPlagiarismMenu] = useState(false);
    const [plagiarismChecker, setPlagiarismChecker] = useState(false);
    const [wordpress, setWordpress] = useState(false);
    const [isShopifyAvailable, setIsShopifyAvailable] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const { mixpanelTrack } = useMixpanelHook();
    const { metaData, reportInfo } = useContext(ReportContext);
    const dispatch = useDispatch();
    const { postData, getData } = useHelperFunctions();

    useEffect(() => {
        plagiarismChecker ? setPlagiarismMenu(true) : setPlagiarismMenu(false);
        // wordpress ? setWordpress(true) : setWordpress(false);
    }, [plagiarismChecker]);

    useEffect(() => {
        if (otherInfo.userIntegrationInfo.length > 0) {
            for (let i = 0; i < otherInfo.userIntegrationInfo.length; i++) {
                if (otherInfo.userIntegrationInfo[i].app_id === 1) {
                    if (otherInfo.userIntegrationInfo[i].app_linked === 1) {
                        setPlagiarismMenu(true);
                    }
                } else if (otherInfo.userIntegrationInfo[i].app_id === 4) {
                    if (otherInfo.userIntegrationInfo[i].app_linked === 1) {
                        setWordpress(true);
                    }
                } else if (otherInfo.userIntegrationInfo[i].app_id === 5) {
                    if (otherInfo.userIntegrationInfo[i].app_linked === 1) {
                        setIsShopifyAvailable(true);
                    }
                }
            }
        }
    }, [otherInfo]);

    useEffect(() => {
        function plg() {
            setPlagiarismChecker(false);
        }
        function wordpressDisconnect() {
            setWordpress(false);
        }
        document.addEventListener("PlagiarismConnected", setPlagiarismChecker);
        document.addEventListener("PlagiarismDisconnected", plg);

        document.addEventListener("wordpressConnected", setWordpress);
        document.addEventListener("wordpressDisconnected", wordpressDisconnect);

        return () => {
            document.removeEventListener(
                "PlagiarismConnected",
                setPlagiarismChecker,
            );
            document.removeEventListener("PlagiarismDisconnected", plg);

            document.removeEventListener("wordpressConnected", setWordpress);
            document.removeEventListener(
                "wordpressDisconnected",
                wordpressDisconnect,
            );
        };
    }, []);

    useEffect(() => {
        if (!info?.isShared)
            saveRecentDocs(info.attachedTo, userId, {
                title: info.title,
                id: info.docId,
            });
        setRecentDocs(getRecentDocs(info.attachedTo, userId));
    }, []);

    const deleteFile = async (fileName) => {
        postData({
            url: "/api/delete-doc-s3",
            payload: {
                fileName,
            },
        });
    };

    async function downloadFile(url, fileName) {
        try {
            const a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.download = "document.docx"; // Customize the file name if needed
            document.body.appendChild(a);
            a.style.display = "none";
            a.click();
            document.body.removeChild(a);
            setTimeout(() => {
                deleteFile(fileName);
            }, 5000);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
        setExportLoading(false);
    }

    const exportToWord = async (html_content) => {
        let html = `<html><head><style>body{font-weight:normal;}table td{border: 1px solid #000;padding:2px 5px;}table{border-collapse: collapse;}</style></head><body><p><strong>Meta Title:</strong> ${metaData?.title}</p><p><strong>Meta Description:</strong> ${metaData?.description}</p><p><strong>URL Slug:</strong> ${metaData?.url}</p>${html_content}</body></html>`;
        setExportLoading(true);
        const res = await postData({
            url: "/api/export/docx",
            payload: {
                html_content: html,
                name: slugify(reportInfo.title, slugifyOption),
            },
        });
        if (res.status == 200) {
            downloadFile(res?.data?.fileUrl, res?.data?.fileName);
        } else {
            setExportLoading(false);
        }
    };

    const getMenus = (info = {}) => [
        {
            title: "File",
            options: [
                {
                    title: "New",

                    method: () =>
                        info.attachedTo == SMART_EDITOR
                            ? goTo("NEW_SMART_DOCS")
                            : aiHumanizer
                            ? goTo("AI_HUMANIZER")
                            : goTo("CREATOR"),
                },
                {
                    title: "Open Recent",
                    disabled: recentDocs.length < 2,
                    shortcut: null,
                    subOptions: recentDocs.slice(1).map((doc) => ({
                        title: doc.title,
                        shortcut: null,

                        method: () =>
                            window.open(
                                window.location.origin +
                                    (info.attachedTo == SMART_EDITOR
                                        ? screens.SMART_EDITOR
                                        : info.attachedTo == "AI_HUMANIZER"
                                        ? screens.AI_HUMANIZER_EDITOR
                                        : screens.CREATOR_EDITOR) +
                                    "/" +
                                    doc.id,
                                "_blank",
                            ),
                        openedAt: doc.openedAt,
                    })),
                },
                {
                    disabled:
                        !location.pathname.includes("editor") || aiHumanizer,
                    title: aiHumanizer ? (
                        <>
                            Export{" "}
                            <small>
                                <b>(Coming soon)</b>
                            </small>
                        </>
                    ) : (
                        "Export"
                    ),
                    shortcut: null,
                    subOptions: [
                        {
                            title: "Export as PDF",

                            method: () => {
                                mixpanelTrack("Editor Export as PDF");
                                saveAsPDF(
                                    "PDF",
                                    info?.docName,
                                    aiHumanizer
                                        ? editor1Disable
                                            ? document.quill.editor2.getSemanticHTML()
                                            : document.quill.editor1.getSemanticHTML()
                                        : document.quill.editor.getSemanticHTML(),
                                    metaData,
                                );
                            },
                        },
                        {
                            title: exportLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Exporting...
                                    <SVGIcon
                                        className={styles.gear}
                                        src={"/New UI/SVG/settings.svg"}
                                    />
                                </div>
                            ) : (
                                "Export as Doc"
                            ),

                            method: () => {
                                if (!exportLoading) {
                                    exportToWord(
                                        document.quill?.editor?.getSemanticHTML(),
                                    );
                                    mixpanelTrack("Editor Export as Doc");
                                }
                            },
                        },
                        {
                            title: "Export as HTML",

                            method: () => {
                                mixpanelTrack("Editor Export as HTML");
                                saveAsPDF(
                                    "HTML",
                                    info?.docName,
                                    aiHumanizer
                                        ? editor1Disable
                                            ? document.quill.editor2.getSemanticHTML()
                                            : document.quill.editor1.getSemanticHTML()
                                        : document.quill.editor.getSemanticHTML(),
                                    metaData,
                                );
                            },
                        },
                        {
                            title: "Export as Markdown",

                            method: () => {
                                mixpanelTrack("Editor Export as Markdown");
                                Export2Markdown(
                                    info?.docName,
                                    aiHumanizer
                                        ? editor1Disable
                                            ? document.quill.editor2.getSemanticHTML()
                                            : document.quill.editor1.getSemanticHTML()
                                        : document.quill.editor.getSemanticHTML(),
                                );
                            },
                        },
                    ],
                },

                {
                    disabled: !location.pathname.includes("editor"),
                    title: "Import from URL",
                    method: () => setImportFromUrlModal(true),
                    hidden: info.attachedTo !== SMART_EDITOR,
                },
            ],
        },
        {
            title: "Edit",
            options: [
                {
                    disabled:
                        !location.pathname.includes("editor") ||
                        isFixItEnabled?.isOn,
                    title: "Undo",
                    shortcut: navigator.userAgent.includes("Mac")
                        ? "⌘+ z"
                        : "Ctrl+ z",
                    method: () => undo(aiHumanizer, editor1Disable),
                },
                {
                    disabled:
                        !location.pathname.includes("editor") ||
                        isFixItEnabled?.isOn,
                    title: "Redo",
                    shortcut: navigator.userAgent.includes("Mac")
                        ? "⌘+ Shift+ z"
                        : "Ctrl+ y",
                    method: () => redo(aiHumanizer, editor1Disable),
                },
            ],
        },
        !info?.isShared &&
            !aiHumanizer && {
                title: "Apps",
                options:
                    company_user_type != 2 && role != "free_lancer"
                        ? [
                              ...(plagiarismMenu
                                  ? [
                                        {
                                            disabled:
                                                !location.pathname.includes(
                                                    "editor",
                                                ) || isFixItEnabled?.isOn,
                                            title: "Plagiarism Checker",
                                            shortcut: null,
                                            method: () =>
                                                document.dispatchEvent(
                                                    plagiarismEvent,
                                                ),
                                        },
                                    ]
                                  : []),
                              ...(wordpress && !info.isSample
                                  ? [
                                        {
                                            disabled:
                                                !location.pathname.includes(
                                                    "editor",
                                                ) || isFixItEnabled?.isOn,
                                            title: "WordPress Publisher",
                                            shortcut: null,
                                            method: () =>
                                                document.dispatchEvent(
                                                    wordpressEvent,
                                                ),
                                        },
                                    ]
                                  : []),
                              ...(isShopifyAvailable && !info.isSample
                                  ? [
                                        {
                                            disabled:
                                                !location.pathname.includes(
                                                    "editor",
                                                ) || isFixItEnabled?.isOn,
                                            title: "Shopify Publisher",
                                            shortcut: null,
                                            method: () => {
                                                document.dispatchEvent(
                                                    shopifyEvent,
                                                );
                                                mixpanelTrack("Shopify App", {
                                                    action: "Open Shopify",
                                                });
                                            },
                                        },
                                    ]
                                  : []),
                              {
                                  disabled:
                                      !location.pathname.includes("editor") ||
                                      isFixItEnabled?.isOn,
                                  title: "Get More Apps",
                                  shortcut: null,
                                  method: () => setMoreApps(true),
                              },
                          ]
                        : plagiarismMenu || wordpress
                        ? [
                              ...(plagiarismMenu
                                  ? [
                                        {
                                            disabled:
                                                !location.pathname.includes(
                                                    "editor",
                                                ) || isFixItEnabled?.isOn,
                                            title: "Plagiarism Checker",
                                            shortcut: null,
                                            method: () =>
                                                document.dispatchEvent(
                                                    plagiarismEvent,
                                                ),
                                        },
                                    ]
                                  : []),
                              ...(wordpress && !info.isSample
                                  ? [
                                        {
                                            disabled:
                                                !location.pathname.includes(
                                                    "editor",
                                                ) || isFixItEnabled?.isOn,
                                            title: "WordPress Publisher",
                                            shortcut: null,
                                            method: () =>
                                                document.dispatchEvent(
                                                    wordpressEvent,
                                                ),
                                        },
                                    ]
                                  : []),
                              ...(wordpress && !info.isSample
                                  ? [
                                        {
                                            disabled:
                                                !location.pathname.includes(
                                                    "editor",
                                                ) || isFixItEnabled?.isOn,
                                            title: "Shopify Publisher",
                                            shortcut: null,
                                            method: () =>
                                                document.dispatchEvent(
                                                    shopifyEvent,
                                                ),
                                        },
                                    ]
                                  : []),
                          ]
                        : [
                              {
                                  disabled: true,
                                  title: "Contact your account admin to add app integrations.",
                                  shortcut: null,
                                  method: () => {},
                              },
                          ],
            },
        {
            title: "Help",
            options: aiHumanizer
                ? [
                      {
                          title: "Contact Support",
                          method: () => dispatch(setChatWidget(true)),
                      },
                  ]
                : [
                      {
                          title: "Help center",
                          method: () =>
                              window.open(
                                  "https://help.scalenut.com/en",
                                  "_blank",
                              ),
                      },
                      { title: "Pro tips", method: () => setProTips(true) },
                      {
                          title: "Tutorial Videos",
                          method: () => setVideoGuide(true),
                      },
                  ],
        },
    ];
    return reportInfo.isHumanizer ? (
        <></>
    ) : (
        <>
            {ProTipsModal && <ProTips setShowInfoModal={setProTips} />}
            {videoGuide && <VideoGuide setShowInfoModal={setVideoGuide} />}
            {urlImport && (
                <ImportFromUrlModal
                    getDataFromUrl={getDataFromUrl}
                    setImportFromUrlModal={setImportFromUrlModal}
                />
            )}
            {getMenus(info).map((option) => {
                return !option ? (
                    <></>
                ) : (
                    <div className={styles.menuTitle}>
                        {option.title}{" "}
                        {option.options && <Menu options={option.options} />}
                    </div>
                );
            })}
            {moreApps && (
                <Modal setModal={setMoreApps}>
                    <div className={styles.integrationModal}>
                        <CloseButton setClose={setMoreApps} />
                        <IntegrationHub moreApps={moreApps} />
                    </div>
                </Modal>
            )}
        </>
    );
}

export default MenuList;

function Menu({ options = [], info }) {
    const location = useLocation();

    return (
        <div className={styles.menu}>
            {options.map((option) => {
                if (option.hidden) {
                    return <></>;
                }
                const disabled = option.disabled;
                return (
                    <div
                        onClick={!disabled && option?.method}
                        className={`${styles.option} ${
                            disabled ? styles.disabledOption : ""
                        } `}
                    >
                        <span>{option.title}</span>
                        <span className={styles.shortcut}>
                            {option.shortcut ? option.shortcut : ""}

                            {!!option?.subOptions && (
                                <SVGIcon
                                    size={12}
                                    src="/New UI/SVG/ChevronRight.svg"
                                />
                            )}
                        </span>
                        {option?.subOptions && (
                            <div className={styles.subMenu}>
                                {option?.subOptions?.map((option, i) => (
                                    <div
                                        onClick={option?.method}
                                        className={styles.option}
                                    >
                                        <span>
                                            {option.title || `Doc ${i + 1}`}
                                        </span>{" "}
                                        <span className={styles.shortcut}>
                                            {option.shortcut}
                                        </span>
                                        {!!option.openedAt && (
                                            <span className={styles.time}>
                                                {moment(
                                                    option.openedAt,
                                                ).fromNow()}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
