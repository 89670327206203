import React, { useState } from "react";
import ChooseAction from "./ChooseAction";
import bcrypt from "bcryptjs";
import styles from "./superAdmin.module.css";

const salt = bcrypt.genSaltSync(10);

export default function SuperAdmin() {
    const [password, setPassword] = useState("");
    const [canAccess, setCanAccess] = useState(false);
    const [error, setError] = useState(false);

    const passwordSubmit = (e) => {
        e.preventDefault();
        if (
            bcrypt.compareSync(
                password,
                "$2a$12$ymsttgq1YEDijnS1qgwLoudflow0dscFKcX7f0/fdJGlVbkc/jFry",
            )
        )
            setCanAccess(true);
        else {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
    };

    if (!canAccess) {
        return (
            <>
                <label htmlFor="pass">Enter Password:</label>
                {error && (
                    <div className={styles.errorMessage}>Wrong Password</div>
                )}
                <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="pass"
                    name="pass"
                    type="password"
                />
                <button style={{ cursor: "pointer" }} onClick={passwordSubmit}>
                    Enter
                </button>
            </>
        );
    }

    if (canAccess) {
        return <ChooseAction />;
    }
}
