const userGuiding = function (g, u, i, d, e, s) {
    g[e] = g[e] || [];
    var f = u.getElementsByTagName(i)[0];
    var k = u.createElement(i);
    k.async = true;
    k.src =
        "https://static.userguiding.com/media/user-guiding-" +
        s +
        "-embedded.js";
    g["userGuidingScript"] = k;
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    var ug = (g[d] = {
        q: [],
    });
    ug.c = function (n) {
        return function () {
            ug.q.push([n, arguments]);
        };
    };
    var m = [
        "previewGuide",
        "finishPreview",
        "track",
        "identify",
        "triggerNps",
        "hideChecklist",
        "launchChecklist",
    ];
    for (var j = 0; j < m.length; j += 1) {
        ug[m[j]] = ug.c(m[j]);
    }
};

const getReditus = function (w, d, s, p, t) {
    w.gr =
        w.gr ||
        function () {
            w.gr.q = w.gr.q || [];
            w.gr.q.push(arguments);
        };
    p = d.getElementsByTagName(s)[0];
    t = d.createElement(s);
    t.async = true;
    t.src = "https://app.getreditus.com/gr.js?_ce=60";
    p.parentNode.insertBefore(t, p);
    gr("track", "pageview");
};

function _fpr() {
    (function (w) {
        w.fpr =
            w.fpr ||
            function () {
                w.fpr.q = w.fpr.q || [];
                w.fpr.q[arguments[0] == "set" ? "unshift" : "push"](arguments);
            };
    })(window);
    fpr("init", {
        cid: "8um5tkvy",
    });
    fpr("click");
}

function facebookPageView() {
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js",
    );
    fbq("init", "3539489179480801");
    fbq("track", "PageView");
}

const diffUser = () => {
    (function (e, t, o, n, p, r, i) {
        e.visitorGlobalObjectAlias = n;
        e[e.visitorGlobalObjectAlias] =
            e[e.visitorGlobalObjectAlias] ||
            function () {
                (e[e.visitorGlobalObjectAlias].q =
                    e[e.visitorGlobalObjectAlias].q || []).push(arguments);
            };
        e[e.visitorGlobalObjectAlias].l = new Date().getTime();
        r = t.createElement("script");
        r.src = o;
        r.async = true;
        i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
    })(
        window,
        document,
        "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js",
        "vgo",
    );
    vgo("setAccount", "90915964");
    vgo("setTrackByDefault", true);

    vgo("process");
};

const scriptsUrl = [
    "https://cdn.firstpromoter.com/fpr.js",
    "https://accounts.google.com/gsi/client",
    "https://scalenut.user.com/widget.js",
    "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.8.0/jszip.js",
    "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.full.min.js",
    "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js",
    "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v14.0",
    "https://platform.linkedin.com/in.js",
    "https://cdnjs.cloudflare.com/ajax/libs/mark.js/8.11.1/mark.min.js",
    "https://www.youtube.com/iframe_api",
];

function loadThirdPartyScripts() {
    scriptsUrl.forEach((url) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.append(script);
    });

    userGuiding(
        window,
        document,
        "script",
        "userGuiding",
        "userGuidingLayer",
        "453859438ID",
    );

    getReditus(window, document, "script");

    _fpr();
    facebookPageView();
    diffUser();
}

window.addEventListener("DOMContentLoaded", (event) => {
    loadThirdPartyScripts();
});

export function attachInspectLet(userInfo) {
    window.__insp = window.__insp || [];
    __insp.push(["wid", 1459820920]);
    __insp.push(["identify", userInfo?.email]);
    var ldinsp = function () {
        if (typeof window.__inspld != "undefined") return;
        window.__inspld = 1;
        var insp = document.createElement("script");
        insp.type = "text/javascript";
        insp.async = true;
        insp.id = "inspsync";
        insp.src =
            ("https:" == document.location.protocol ? "https" : "http") +
            "://cdn.inspectlet.com/inspectlet.js?wid=1459820920&r=" +
            Math.floor(new Date().getTime() / 3600000);
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(insp, x);
    };
    setTimeout(ldinsp, 0);
}
