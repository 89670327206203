import React, { useEffect, useState } from "react";
import useHelperFunctions from "../../../helperFunctions";
import styles from "./ClusterKeywordPlanner.module.css";
import Button from "../../../atoms/Button/Button";
import { Link } from "react-router-dom";
import screens from "../../../../screens.json";
import SVGIcon from "../../../atoms/SVGIcon";
import InProgressTimeline from "../../../organisms/DocCard/InProgressTimeline/InProgressTimeline";
import { loadingText } from "../../../organisms/DocCard/constants";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";

import CustomCircularProgress from "../../../atoms/CircularProgress/CustomCircularProgress";
import Morekeywords from "../../../atoms/MoreKeywords/Morekeywords";
import { LightTooltip } from "../../../atoms/Tooltip/tooltip";
import useMixpanelHook from "../../../../utility/useMixpanelHook";
import { clusterMixpanelEvent } from "../clusterMixpanelEvents";
import { CopyToClipboard } from "../../../atoms/CopyToClipboard/CopyToClipboard";

export const RecommendedTag = () => (
    <div className={styles.recommendedTag}>Recommended</div>
);

let _step;
const KeywordReportCreationAnimation = ({
    item,
    initialStep = 0,
    status = 0,
}) => {
    const [step, setStep] = useState(initialStep);
    const [subTextStep, setSubTextStep] = useState(0);
    _step = step;
    useEffect(() => {
        const interval = setInterval(() => {
            if (_step == loadingText(0, false).length - 1) {
                setSubTextStep((ps) => {
                    if (ps < loadingText(0, false)[_step].subText.length - 1)
                        return ps + 1;
                    return ps;
                });
            }

            setStep((s) => {
                if (s < loadingText(0, false).length - 1) return s + 1;
                else {
                    return s;
                }
            });
        }, 6000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.reportCreationAnimationRow}>
            <div className={styles.animationContainer}>
                <InProgressTimeline
                    step={step}
                    duration={4}
                    status={status}
                    style={{ width: "100%" }}
                />
            </div>
        </div>
    );
};

const KeywordPlannerRow = ({
    item = {},
    clusterInfo = {},
    _setClusterKeywords = () => {},
    makeSingleKeywordReport,
}) => {
    console.log("keyword Roe", item);

    const [load, setLoad] = useState(item?.report_id == 0 ? true : false);
    const { postData, getData } = useHelperFunctions();
    const [initialStep, setInitialStep] = useState(0);
    const [status, setStatus] = useState(-1);
    const [completed, setCompleted] = useState(-1);
    const { mixpanelTrack } = useMixpanelHook();
    useEffect(() => {
        if (status > 0) {
            setTimeout(() => {
                setCompleted(1);
            }, 2000);
        }
    }, [status]);

    useEffect(() => {
        if (item?.is_cluster) {
            if (item?.report_id && item.report_id != -1) {
                setTimeout(() => {
                    setStatus(1);
                }, 2000);
            } else if (item.report_id == -1) {
                setStatus(2);
            } else if (item?.report_id == 0) {
                setLoad(true);
                setInitialStep(2);
                setStatus(0);
                setCompleted(0);
            }
            return;
        }

        if (item?.report_id && item.report_id != -1) {
            setTimeout(() => {
                setStatus(1);
            }, 2000);
        } else if (item.report_id == -1) {
            setStatus(2);
        } else if (item?.report_id == 0) {
            setLoad(true);
            setInitialStep(2);
            setStatus(0);
            setCompleted(0);
            document.loadingKeywordId = item.id;
        }
    }, [item?.report_id]);

    function secondaryKeywords(arr) {
        let arrTemp = [];
        arr.map((item, i) => {
            if (i > 0 && item?.is_selected) arrTemp.push(item.keyword);
        });

        return arrTemp;
    }

    function getAvg(field = "", arr = []) {
        let count = 0;
        let length = 0;
        switch (field) {
            case "volume":
                arr.map((item, i) => {
                    if (item?.is_selected) {
                        count += item.volume || 0;
                        length++;
                    }
                });
                return count;
                break;
            case "cpc":
                arr.map((item, i) => {
                    if (item?.is_selected) {
                        count += item.cpc || 0;
                        length++;
                    }
                });
                break;
            case "similarity":
                arr.map((item, i) => {
                    if (item?.is_selected) {
                        count += item.similarity || 0;
                        length++;
                    }
                });
                break;
            default:
                return;
        }
        if (count == 0 || length == 0) return 0;
        else return count / length;
    }

    const makeReport = async (item) => {
        mixpanelTrack(clusterMixpanelEvent.CREATE_SEO_ARTICLE, {
            Keyword: item.keyword,
            "Search Volume": item.volume,
            "Total Keywords": 1,
            "Selected Keywords": 1,
            CPC: parseFloat(item?.cpc).toFixed(2),
            ...(clusterInfo.source === "semrush" && {
                Competition: Math.round(item?.competition),
            }),
            ...(clusterInfo.source !== "semrush" && {
                Relevance: parseInt(item?.similarity),
            }),
        });
        setLoad(true);
        const res = await makeSingleKeywordReport(
            item.id,
            item.keyword,
            item?.topic_id,
        );
        if (res.status == 200) {
            setStatus(0);
            setCompleted(0);
        } else {
            setLoad(false);
        }
    };

    return (
        <>
            <tr
                className={
                    styles.tableItemRow +
                    " " +
                    (completed == 0 && styles.creationAnimation)
                }
            >
                <td className={styles.keyword}>
                    <div
                        className={styles.keywordInner}
                        style={{ fontWeight: item?.report_id ? "500" : "" }}
                    >
                        <span>{item?.keyword}</span>
                        <span className={styles.moreCount}>
                            {item.is_cluster ? (
                                <Morekeywords
                                    keywords={JSON.stringify(
                                        secondaryKeywords(item.data),
                                    )}
                                    moreCount={
                                        secondaryKeywords(item.data).length
                                    }
                                    title={item.keyword}
                                />
                            ) : (
                                ""
                            )}
                        </span>
                    </div>
                    <div className={styles.copyIconContainer}>
                        <CopyToClipboard textToCopy={item?.keyword} />
                    </div>
                    {item.is_recommended_keyword == 1 ? <RecommendedTag /> : ""}
                </td>
                <td className={styles.volume}>
                    {item?.volume != undefined
                        ? item?.is_cluster
                            ? parseInt(getAvg("volume", item.data))
                            : item.volume
                        : "-"}
                </td>
                {clusterInfo.is_excel_sheet !== 1 && (
                    <td
                        style={{ padding: "0px 20px 0 0" }}
                        className={styles.similarity}
                    >
                        {clusterInfo.source === "semrush" ? (
                            <>
                                {item.competition != null
                                    ? Math.round(item.competition)
                                    : "-"}
                            </>
                        ) : item?.similarity != null ? (
                            <>
                                {item.is_cluster
                                    ? parseInt(getAvg("similarity", item.data))
                                    : parseInt(item?.similarity)}
                                &nbsp;<span style={{ fontSize: 16 }}>%</span>
                            </>
                        ) : (
                            "-"
                        )}
                    </td>
                )}
                <td style={{ padding: "0 10px 0 0" }} className={styles.cpc}>
                    {item?.cpc != undefined
                        ? item.is_cluster
                            ? parseFloat(getAvg("cpc", item.data)).toFixed(2)
                            : parseFloat(item?.cpc).toFixed(2)
                        : "-"}
                </td>
                {/* <td
                    style={{ padding: "0px 20px 0 0" }}
                    className={styles.similarity}
                >
                    <CustomCircularProgress
                        strokeWidth={0.2}
                        fullArcFraction={5 / 7}
                        progress={item.potential_score}
                        width={40}
                        height={40}
                    />
                </td> */}
                <td className={styles.option}>
                    <>
                        {completed == 0 ? (
                            <KeywordReportCreationAnimation
                                item={item}
                                initialStep={initialStep}
                                status={status}
                            />
                        ) : item?.report_id == 0 ||
                          item.report_id == -1 ||
                          !item.report_id ? (
                            <LightTooltip
                                arrow
                                title={
                                    clusterInfo.is_sample
                                        ? "This is a sample cluster report. Please create a new Keyword Report to enable this feature."
                                        : ""
                                }
                            >
                                <div style={{ width: "max-content" }}>
                                    <Button
                                        text={
                                            item?.report_id == 0 || load
                                                ? "Creating SEO Article..."
                                                : item?.report_id == -1
                                                ? "Failed"
                                                : "Create SEO Article"
                                        }
                                        disabled={clusterInfo.is_sample}
                                        height={"34px"}
                                        width={"170px"}
                                        borderRadius={"2px"}
                                        handler={() => {
                                            makeReport(item);
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            backgroundColor: "#014dc5",
                                            color: "#fff",
                                            border: "solid 1px #014dc5",
                                            borderRadius: "2px",
                                            boxShadow:
                                                "0 2px 2px 0 rgba(0, 0, 0, 0.15)",
                                        }}
                                        iconReverse
                                        Icon={() => (
                                            <SVGIcon
                                                size={"16"}
                                                style={{
                                                    color: "#fff",
                                                }}
                                                color={"#fff"}
                                                src={
                                                    "/New UI/SVG/create_seo_doc.svg"
                                                }
                                            />
                                        )}
                                        lessDots={true}
                                    />
                                </div>
                            </LightTooltip>
                        ) : clusterInfo?.is_sample ? (
                            ""
                        ) : (
                            <Link
                                target={"_blank"}
                                className={styles.openLink}
                                to={screens.SEO_EDITOR + "/" + item.report_id}
                            >
                                <SVGIcon
                                    size={"13"}
                                    style={{
                                        color: "var(--primary-blue)",
                                    }}
                                    src={"/New UI/SVG/Open-Article.svg"}
                                />
                                &nbsp; Open Article
                            </Link>
                        )}
                    </>
                </td>
                {/* <div className={styles.bottomSpacer}></div> */}
            </tr>
        </>
    );
};

export default KeywordPlannerRow;
