import React, {
    ChangeEvent,
    CSSProperties,
    KeyboardEvent,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.css";
import { VscClose } from "react-icons/vsc";
import { IoMdClose } from "react-icons/io";

interface ChipInputProps {
    chips: string[];
    setChips: React.Dispatch<React.SetStateAction<string[]>>;
    parentStyle?: CSSProperties;
}

const MultiInputWithChip: React.FC<ChipInputProps> = ({
    chips,
    setChips,
    parentStyle,
}) => {
    const [inputValue, setInputValue] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.includes(",")) {
            const valuesArray = value
                .split(",")
                .map((val) => val.trim())
                .filter((val) => val !== "");
            addChips(valuesArray);
        } else {
            setInputValue(value);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addChips([inputValue.trim()]);
        } else if (e.key === "Backspace" && inputValue === "") {
            if (chips.length > 0) {
                e.preventDefault();
                setInputValue(chips[chips.length - 1]);
                setChips(chips.slice(0, -1));
            }
        }
    };

    const addChips = (newChips: string[]) => {
        const uniqueNewChips = newChips.filter(
            (newChip) => newChip && !chips.includes(newChip),
        );
        if (uniqueNewChips.length > 0) {
            setChips([...chips, ...uniqueNewChips]);
        }
        setInputValue("");
    };

    const removeChip = (indexToRemove: number) => {
        setChips(chips.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div className={styles.chipInputContainer} style={parentStyle}>
            {chips.map((chip, index) => (
                <div className={styles.chip} key={index}>
                    {chip}
                    <IoMdClose
                        className={styles.chipClose}
                        onClick={() => removeChip(index)}
                    />
                </div>
            ))}
            <input
                className={styles.chipInput}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={() => {
                    addChips([inputValue.trim()]);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Type and press enter..."
            />
        </div>
    );
};

export default MultiInputWithChip;
