import React, { useState, useEffect } from "react";
import styles from "./FixItModule.module.css";
import { RxCross2 } from "react-icons/rx";
import Button from "../../../atoms/Button/Button";
import { BsCheck2 } from "react-icons/bs";

const CardActions = ({ handleFunction, firstButtontext, dismissFunction }) => {
    return (
        <div className={styles.actions_right}>
            <Button
                handler={handleFunction}
                text={firstButtontext}
                customClass={styles.customBtn_accept}
                height={34}
                Icon={() => <BsCheck2 size={14} strokeWidth={1} />}
                iconReverse
            />
            <Button
                handler={dismissFunction}
                text={"Dismiss"}
                customClass={styles.customBtn_dismiss}
                height={34}
                style={{
                    background: "#acb3bf1a",
                    color: "rgba(255, 255, 255, 0.64) !important",
                    borderRadius: "5px",
                    border: "1px solid rgba(255, 255, 255, 0.64)",
                }}
                Icon={() => <RxCross2 size={14} strokeWidth={1} />}
                iconReverse
            />
        </div>
    );
};

export default CardActions;
