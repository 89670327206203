import React, { useEffect, useRef, useState } from "react";
import useHelperFunctions from "../../helperFunctions";
import useNavigation from "../../atoms/GoToRoute/useNavigation";
import { debounceWrapper } from "../../../utility/helperFun";

import { useDispatch } from "react-redux";
import { displayAlert } from "../../../store/actions/alert";
import Showdown from "../../../lib/showdownmin";

const initialLoad = {
    page: 1,
    updated: false,
    created: true,
    tags: [],
    allTags: false,
    search: "",
    is_deleted: false,
    order: 0,
    ai_score: 0,
    word_count: 0,
};

const allowedTags = ["H1", "H2", "H3", "H4", "H5", "H6"];

function createUL(node) {
    const ulEle = document.createElement("ul");
    node.querySelectorAll("li").forEach((li) => {
        ulEle.appendChild(li.cloneNode(true));
    });
    return ulEle;
}

function useAIHumanizerHook(docs = true) {
    const [loading, setLoading] = useState(false);
    const [creatingScan, setCreatingScan] = useState(false);
    const [scans, setScans] = useState([]);
    const [requestData, setRequestData] = useState(initialLoad);
    const [hasMore, setHasMore] = useState(true);
    const [isScanning, setIsScanning] = useState(false);
    const [isHumanizerScanning, setIsHumanizerScanning] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [buyMoreModal, setBuyMoreModal] = useState(false);

    const goto = useNavigation();
    const dispatch = useDispatch();
    const { postData, getData } = useHelperFunctions();
    let timeoutRef = useRef(null);

    useEffect(() => {
        function req() {
            getScans(requestData);
        }
        if (docs) debounceWrapper(req, 400, timeoutRef);
    }, [requestData]);

    const createScan = async function ({ name, openDoc = false }) {
        setCreatingScan(true);
        const res = await postData({
            url: "/api/ai-humanizer/create-scan",
            payload: {
                scan_name: name ? name : "Untitled",
            },
        });
        if (res.status == 200) {
            if (openDoc) openScan(res.data?.data?.scan_id);
            return res.data?.data?.scan_id;
        }
    };

    const openScan = (id) => {
        goto("AI_HUMANIZER_EDITOR", [], `${id}`);
        setCreatingScan(false);
    };

    const getAiScore = async (
        scan_id,
        isHumanizer = false,
        setAiScore,
        setIsScan,
    ) => {
        if (isHumanizer && document.quill?.editor2?.getText()?.length < 250) {
            dispatch(
                displayAlert({
                    alertMessage:
                        "Please provide at least 250 characters to use this feature.",
                    alertType: "error",
                }),
            );
            return;
        }
        if (!isHumanizer && document.quill?.editor1?.getText()?.length < 250) {
            dispatch(
                displayAlert({
                    alertMessage:
                        "Please provide at least 250 characters to use this feature.",
                    alertType: "error",
                }),
            );
            return;
        }
        isHumanizer ? setIsHumanizerScanning(true) : setIsScanning(true);
        setAiScore(0);
        const res = await postData({
            url: "/api/ai-humanizer/ai-detection-score",
            payload: {
                scan_id: scan_id,
                ...(isHumanizer && {
                    humanizer: {
                        text_content: document.quill?.editor2?.getText(),
                    },
                }),
                ...(!isHumanizer && {
                    raw: {
                        text_content: document.quill?.editor1?.getText(),
                    },
                }),
            },
        });
        if (res.status == 200) {
            setAiScore(
                parseInt(
                    (isHumanizer
                        ? res.data?.data?.humanizer_ai_score
                        : res.data?.data?.ai_score) * 100,
                ),
            );
        } else {
            if (res?.response?.data?.message == "Monthly quota exhausted") {
                setBuyMoreModal(true);
            }
        }
        isHumanizer ? setIsHumanizerScanning(false) : setIsScanning(false);
        setIsScan(true);
    };

    const getHumanizeData = async (
        scan_id,
        setEditor1Disable,
        setEditor2Disable,
        setHumanizeLoading,
        setAiScore,
        setIsScan,
        report_id,
    ) => {
        if (document?.quill?.editor1?.getText()?.length < 250) {
            dispatch(
                displayAlert({
                    alertMessage:
                        "Please provide at least 250 characters to use this feature.",
                    alertType: "error",
                }),
            );
            return;
        }
        setEditor1Disable(true);
        setIsHumanizerScanning(true);
        setHumanizeLoading(true);
        const converter = new Showdown.Converter({
            tables: true,
            simpleLineBreaks: true,
        });
        const payloadContent = [];
        const editorData = document?.quill?.editor1?.root;
        let count = 0;

        for (const ele of editorData?.childNodes) {
            count++;
            ele.id = count;

            if (ele.innerText?.length > 50) {
                const temp = {
                    tag: ele.tagName,
                    text:
                        ele.tagName === "UL" || ele.tagName === "OL"
                            ? converter.makeMarkdown(ele.outerHTML || "")
                            : ele.innerText,
                    priority: ele.id,
                };
                //If unordered list then first convert this in UL tag.
                if (
                    ele.firstChild &&
                    ele.tagName == "OL" &&
                    ele.firstChild?.getAttribute("data-list") == "bullet"
                ) {
                    const newUlEle = createUL(ele);
                    temp.text = converter.makeMarkdown(
                        newUlEle.outerHTML || "",
                    );
                }

                if (
                    ["UL", "OL", "P"].includes(ele.tagName) ||
                    (allowedTags.includes(ele.tagName) && !report_id)
                ) {
                    payloadContent.push(temp);
                }
            }
        }
        console.log(payloadContent, "ppppp");
        const res = await postData({
            url: "/api/ai-humanizer/humanize",
            payload: {
                scan_id: scan_id,
                text_content: payloadContent,
                text: document?.quill?.editor1?.getText(),
                html_content: editorData.innerHTML,
                ...(report_id && { report_id: report_id }),
            },
        });
        if (res.status == 200) {
            setEditor2Disable(false);
            let humanizeEditordata =
                document?.quill?.editor1?.root?.cloneNode(true);
            for (const obj of res.data?.data?.content) {
                const nodeEle = humanizeEditordata.querySelector(
                    `[id='${obj.priority}']`,
                );
                if (obj.tag == "UL" || obj.tag == "OL") {
                    if (nodeEle) {
                        if (obj.humanized_text) {
                            nodeEle.outerHTML = converter.makeHtml(
                                obj.humanized_text || "",
                            );
                        } else {
                            nodeEle.outerHTML = converter.makeHtml(obj.text);
                        }
                    }
                } else {
                    if (nodeEle) {
                        if (obj.tag == "P") {
                            if (obj.humanized_text) {
                                nodeEle.outerHTML = converter.makeHtml(
                                    obj.humanized_text || "",
                                );
                            } else {
                                nodeEle.innerText = obj.text;
                            }
                        } else {
                            nodeEle.innerText = obj.humanized_text || obj.text;
                        }
                    }
                }
            }
            document.quill?.editor2?.setContents(
                document.quill?.editor2?.clipboard?.convert({
                    html: humanizeEditordata.innerHTML,
                }),
            );
            setAiScore(
                parseInt(res.data?.data?.score?.humanizer_ai_score * 100),
            );
            setShowFeedback(true);
        } else {
            if (res?.response?.data?.message == "Monthly quota exhausted") {
                setBuyMoreModal(true);
            }
            if (res?.response?.data?.err_code == "COUDNT_HUMANIZE") {
                setErrorModal(true);
            }
            setEditor1Disable(false);
        }
        setIsHumanizerScanning(false);
        setIsScan(true);
        setHumanizeLoading(false);
    };

    async function deleteDocument(id) {
        let res = await postData({
            url: "/api/ai-humanizer/delete-scan",
            payload: {
                scan_id: id,
            },
        });
        if (res.status == 200) {
            return true;
        } else {
            return false;
        }
    }

    async function restoreDocument(id) {
        await postData({
            url: "/api/ai-humanizer/restore-scan",
            payload: {
                scan_id: id,
            },
        });
    }

    const getScans = React.useCallback(async function (requestData) {
        const {
            page,
            updated,
            created,
            allTags,
            search,
            order,
            is_deleted,
            ai_score,
            humanizer_ai_score,
        } = requestData;
        if (requestData.page == 1) setLoading(true);
        const res = await getData({
            url: `/api/ai-humanizer/get-scan?page=${page}&created_at=${
                created ? 1 : 0
            }&search_text=${search}&updated_at=${updated ? 1 : 0}&all_tags=${
                allTags ? 1 : 0
            }&is_deleted=${is_deleted ? 1 : 0}&order=${order}&ai_score=${
                ai_score ? 1 : 0
            }&humanizer_ai_score=${humanizer_ai_score ? 1 : 0}`,
        });

        if (res.status == 200) {
            if (res.data.data.length < 20) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
            if (requestData.page == 1) setScans([...res.data.data]);
            else setScans((ps) => ps.concat([...res.data.data]));
            setLoading(false);
        } else {
            setHasMore(false);
            setLoading(false);
        }
    }, []);

    const updateName = async ({ name, id }) => {
        postData({
            url: "/api/ai-humanizer/update-scan-name",
            payload: {
                scan_name: name,
                scan_id: id,
            },
        });
    };

    return {
        loading,
        setLoading,
        createScan,
        openScan,
        creatingScan,
        hasMore,
        requestData,
        setRequestData,
        scans,
        getHumanizeData,
        getAiScore,
        isScanning,
        isHumanizerScanning,
        deleteDocument,
        restoreDocument,
        updateName,
        errorModal,
        setErrorModal,
        showFeedback,
        setShowFeedback,
        buyMoreModal,
        setBuyMoreModal,
    };
}

export default useAIHumanizerHook;
