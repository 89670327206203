import firebase from "firebase";
import axios from "axios";
import { url } from "./utility/config";

const config = {
    apiKey: "AIzaSyDuHi9fFm54_mrvQNZxYP3jySswDPyPkXk",
    authDomain: "scalenut-efdb9.firebaseapp.com",
    databaseURL: "https://scalenut-efdb9.firebaseio.com",
    projectId: "scalenut-efdb9",
    storageBucket: "scalenut-efdb9.appspot.com",
    messagingSenderId: "198270812079",
    appId: "1:198270812079:web:744f0a951c832c0593f95a",
    measurementId: "G-6E5WQ926XZ",
};
// const AuthToken = localStorage.getItem("token");

export const askNotificationRequest = (AuthToken) => {
    try {
        firebase.initializeApp(config);

        const messaging = firebase.messaging();

        Notification.requestPermission()
            .then((e) => {
                return messaging.getToken({
                    vapidKey:
                        "BJszRs0lVY6br9I6U8b-diVwTzAYtVXO4IJEfH-s56d9qtfTmc-l8velocIzHrEEAsa5h9s8Zt2ZoApFdK39f5I",
                });
            })
            .then((token) => {
                console.log(token, "firebase msg");
                registerDevice(token);
            })
            .catch((err) => {
                console.log(
                    err,
                    "**************************************456325654637657856754375472******",
                );
            });

        messaging.onMessage((payload) => {
            console.log(payload);

            const notification = new Notification(
                payload.notification.body ? payload.notification.body : "",
                {
                    image: "/xyz.png",
                    icon: "https://storage.googleapis.com/scalenut/FE/favicon1.ico",
                },
            );

            notification.onclick = function (event) {
                event.preventDefault();
                window.open("/admin/notifications", "blank");
            };

            notification.onshow = function (event) {
                event.preventDefault();
                const audioObj = new Audio("/tone.mp3");
                const events = new Event("build");
                const func = (event) => {
                    audioObj.play();
                };
                audioObj.addEventListener("build", func);

                audioObj.dispatchEvent(events);
                audioObj.removeEventListener("build", func);
            };
        });

        function registerDevice(token) {
            let body = {
                deviceToken: token,
            };
            axios({
                method: "post",
                url: `${url}/api/user/notification/createFcmToken`,
                data: body,
                headers: {
                    Authorization: AuthToken,
                },
            }).catch((err) => {});
        }
    } catch (err) {
        console.log(err, "ULTIMATER ERRORRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
    }
};
